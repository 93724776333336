import { FilterCommon } from "domain/common/filter";
import { RequestOptions } from "services/common/request-options";

export class BatchPaymentFilter extends FilterCommon {
  jobId?: number;
  taskId?: number;
  fileName?: string;
  step?: number;
  round?: number;
  jobStatus?: string;
  labeler?: string;
  payment?: number;

  static toRequestOption(filter: BatchPaymentFilter): RequestOptions {
    const options = FilterCommon.toRequestOption(filter);

    if (filter.jobId) options["id.equals"] = filter.jobId.toString();
    if (filter.taskId) options["taskId.equals"] = filter.taskId.toString();
    if (filter.fileName) options["fileName.contains"] = filter.fileName;
    if (filter.step) options["steps"] = filter.step.toString();
    if (filter.round) options["rounds"] = filter.round.toString();
    if (filter.jobStatus) options["statusList"] = filter.jobStatus;
    if (filter.labeler) options["labelers"] = filter.labeler;
    if (filter.payment) options["payment.equals"] = filter.payment.toString();

    return options;
  }
}

export interface BatchPaymentTableRow {
  jobId: number;
  taskId: number;
  fileName: string;
  step: number;
  round: number;
  jobStatus: string;
  labeler: string;
  payment: number;
}