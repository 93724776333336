/*
 * File: workflow.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 16th August 2021 9:12:54 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { isHTTPStatusOK } from "services/common/http-status";
import {
  StepConditionService,
  WorkflowService,
  WorkflowServiceV2,
} from "services/label-service";
import {
  WorkflowDTO,
  WorkflowInstructionDTO,
} from "services/label-service/dtos";
import { WorkflowDTOV2 } from "services/label-service/dtos/workflow-v2.dto";
import { RootState } from "store";
import {
  addEntity,
  removeEntity,
  requestFulfilled,
  requestPending,
  requestRejected,
  setEntites,
  updateEntity,
} from "store/base/base.reducer";
import { selectCurrentWorkspaceId } from "store/common/user-workspace/user-workspace.selectors";
import { WorkflowState } from "./workflow.state";

export enum WorkflowThunk {
  LOAD_WORKFLOWS = "workflow/loadWorkflowsAsync",
  LOAD_STEP_CONDITIONS = "workflow/loadStepConditionsAsync",
  LOAD_WORKFLOW = "workflow/loadWorkflowAsync",
  CREATE_WORKLOW = "workflow/createWorkflowAsync",
  CREATE_WORKLOW_INSTRUCTIONS = "workflow/createWorkflowInstructionsAsync",
  UPDATE_WORKFLOW = "workflow/updateWorkflowAsync",
  DELETE_WORKFLOW = "workflow/deleteWorkflowAsync",
}

export const loadWorkflowsAsync = createAsyncThunk(
  WorkflowThunk.LOAD_WORKFLOWS,
  async () => {
    const response = await WorkflowService.getItems({sort: "createdDate,desc"});
    return response.data;
  }
);

export const loadWorkflowAsync = createAsyncThunk(
  WorkflowThunk.LOAD_WORKFLOW,
  async (workflowId: number) => {
    const response = await WorkflowService.getItem(workflowId);
    return response.data;
  }
);

export const createWorkflowAsync = createAsyncThunk(
  WorkflowThunk.CREATE_WORKLOW,
  async (data: Partial<WorkflowDTO>) => {
    const response = await WorkflowService.createItem(data);
    return response.data;
  }
);

export const createWorkflowInstructionsAsync = createAsyncThunk(
  WorkflowThunk.CREATE_WORKLOW_INSTRUCTIONS,
  async (
    {
      workflow,
      instructions,
    }: {
      workflow: Partial<WorkflowDTO>;
      instructions: Partial<WorkflowInstructionDTO>[];
    },
    { getState }
  ) => {
    const workspaceId = selectCurrentWorkspaceId(getState() as RootState);
    const workInstructions = instructions.map((instruction) => ({
      ...instruction,
      workspaceId
    }) as WorkflowInstructionDTO);

    const payload: Partial<WorkflowDTOV2> = {
      ...workflow,
      workInstructions
    }
    const response = await WorkflowServiceV2.createItem(payload)
    return response.data;
  }
);

export const updateWorkflowAsync = createAsyncThunk(
  WorkflowThunk.UPDATE_WORKFLOW,
  async (workflow: WorkflowDTO) => {
    const response = await WorkflowService.updateItem(workflow);
    return response.data;
  }
);

export const deleteWorkflowAsync = createAsyncThunk(
  WorkflowThunk.DELETE_WORKFLOW,
  async (workflowId: number) => {
    const response = await WorkflowService.deleteItem(workflowId);
    if (isHTTPStatusOK(response.status)) {
      return workflowId;
    }
    return -1;
  }
);

export const loadStepConditionsAsync = createAsyncThunk(
  WorkflowThunk.LOAD_STEP_CONDITIONS,
  async () => {
    const response = await StepConditionService.getItems({});
    return response.data;
  }
);

export const workflowReducerBuilder = (
  builder: ActionReducerMapBuilder<WorkflowState>
): ActionReducerMapBuilder<WorkflowState> => {
  return builder
    .addCase(loadWorkflowsAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(loadWorkflowsAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(loadWorkflowsAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      setEntites(state, action.payload);
    })

    .addCase(loadWorkflowAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(loadWorkflowAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(loadWorkflowAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      addEntity(state, action.payload);
      state.id = action.payload.id;
    })

    .addCase(createWorkflowAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(createWorkflowAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(createWorkflowAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      addEntity(state, action.payload);
    })

    .addCase(createWorkflowInstructionsAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(createWorkflowInstructionsAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(createWorkflowInstructionsAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      addEntity(state, action.payload);
    })

    .addCase(updateWorkflowAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(updateWorkflowAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(updateWorkflowAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      updateEntity(state, action.payload);
    })

    .addCase(deleteWorkflowAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(deleteWorkflowAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(deleteWorkflowAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      removeEntity(state, action.payload);
    })
    .addCase(loadStepConditionsAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(loadStepConditionsAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      state.stepConditions = action.payload;
    });
};
