/*
 * File: labeler-distribution.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 26th August 2021 6:21:13 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { useLabelerDistribution } from "hooks/batch/use-labeler-distribution.hook";
import { useAppSelector } from "hooks/use-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { RequestStatus } from "store/base/base.state";
import { selectBatchLabelers } from "store/customer/batch/batch.selectors";
import { BatchLabelerPerformance } from "./batch-labeler-performance.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { SingleValue } from "react-select";

type RouteParams = { batchId: string };
export const LabelerDistribution = () => {
  const { t } = useTranslation();
  const { batchId } = useParams<RouteParams>();
  const { requestStatus } = useLabelerDistribution(parseInt(batchId));
  const batchLabelers = useAppSelector(selectBatchLabelers);
  const [selectedOption, setSelectedOption] = useState("");
  const labelerOptions = useMemo(() => {
    const labelers = [];
    for (let batchLabeler of batchLabelers) {
      if (labelers.indexOf(batchLabeler.email) === -1) {
        labelers.push(batchLabeler.email);
      }
    }
    return [
      {
        value: "",
        label: t("project:batchDetails.labelerDistribution.textAll"),
      },
      ...labelers.map((email) => ({ value: email, label: email })),
    ];
  }, [batchLabelers, t]);
  const [labelers, setLabelers] = useState<string[]>([]);
  const [expandedLabelers, setExpandedLabelers] = useState<string[]>([]);

  function handleExpandChange(labeler: string, expand: boolean) {
    if (!expand) {
      setExpandedLabelers(expandedLabelers.filter((lb) => lb !== labeler));
    } else if (expand && !expandedLabelers.includes(labeler)) {
      setExpandedLabelers([...expandedLabelers, labeler]);
    }
  }

  function handleChange(event: SingleValue<any>) {
    setSelectedOption(event.value as string);
  }

  useEffect(() => {
    const selectedLabelers = [
      ...labelerOptions
        .filter((option) => option.value)
        .filter((option) => !selectedOption || selectedOption === option.value)
        .map((option) => option.value),
    ];
    setLabelers(selectedLabelers);
    if (selectedLabelers.length > 0) setExpandedLabelers([selectedLabelers[0]]);
  }, [selectedOption, labelerOptions]);

  if (requestStatus === RequestStatus.LOADING) return <VBInlineLoading />;

  return (
    <div>
      <div className="flex flex-wrap items-center gap-4 mt-4">
        <div className="flex items-center gap-4">
          <span>Labeler(s): </span>
          <VBSelectComponent
            size="sm"
            className="w-64"
            value={labelerOptions.find(
              (option) => option.value === selectedOption
            )}
            options={labelerOptions}
            onChange={handleChange}
            menuPortalTarget={document.body}
          />
        </div>
        <div className="h-6 mx-4 border-r"></div>
        <div className="flex items-center gap-4">
          <span>
            {t("project:batchDetails.labelerDistribution.textOrders")}
          </span>
          <VBSelectComponent
            size="sm"
            className="w-32"
            value={{
              value: "",
              label: t("project:batchDetails.labelerDistribution.textF1Score"),
            }}
            options={[
              {
                value: "",
                label: t(
                  "project:batchDetails.labelerDistribution.textF1Score"
                ),
              },
            ]}
            menuPortalTarget={document.body}
          />

          <VBSelectComponent
            size="sm"
            className="w-32"
            value={{
              value: "",
              label: t(
                "project:batchDetails.labelerDistribution.optionAscending"
              ),
            }}
            options={[
              {
                value: "",
                label: t(
                  "project:batchDetails.labelerDistribution.optionAscending"
                ),
              },
            ]}
            menuPortalTarget={document.body}
          />
        </div>
        <button>
          <i className="mr-2 text-lg uir-document text-primary"></i>
          <span className="underline text-primary">
            {t("project:batchDetails.labelerDistribution.buttonDownloadReport")}
          </span>
        </button>
      </div>

      <div>
        {labelers.map((labeler) => {
          if (!labeler) return null;
          return (
            <BatchLabelerPerformance
              batchId={parseInt(batchId)}
              key={labeler}
              labeler={{ email: labeler }}
              expanded={expandedLabelers.includes(labeler)}
              onExpandChange={(expand: boolean) =>
                handleExpandChange(labeler, expand)
              }
            />
          );
        })}
      </div>
    </div>
  );
};
