/*
 * File: job-label-list.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 6th August 2021 9:32:28 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppDispatch, useAppSelector } from "hooks/use-redux";

import {
  selectImageLabelingLabels,
  selectIsImageLabelingReadonly,
  selectSelectedObservationId,
} from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { ObservationList } from "./observation-list.component";
import { useMount } from "ahooks";
import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";
import { AnnotateType } from "constants/annotation.constant";
import { smartLabelingEnabled } from "store/labeler/image-workspace/smart-labeling/smart-labeling.slice";
import { selectSmartLabelingEnabled } from "store/labeler/image-workspace/smart-labeling/smart-labeling.selectors";
import { setActiveCornerstoneTool } from "store/labeler/image-workspace/dicom-editor/dicom-editor.slice";
import {
  setSelectedObservationId,
  setObservationVisible,
  setObservationSelected,
  observationUpdated,
} from "store/labeler/image-workspace/image-workspace.slice";
import { Label } from "domain/image-labeling";
import { imageClassificationLabelSelectedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-classification-label-selected.thunk";
import { selectImageLabelingActiveJobId } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { useEffect } from "react";
import { imageJobSelectedAsync } from "store/labeler/image-workspace/image-labeling/thunks/image-job-selected.thunk";
import { jobObservationEdited } from "store/labeler/image-workspace/batch-labeling/batch-labeling.slice";

interface Props {
  header?: string;
}
export const JobLabelList = ({ header = "Findings" }: Props) => {
  const dispatch = useAppDispatch();
  const selectedObservationId = useAppSelector(selectSelectedObservationId);
  const labels = useAppSelector(selectImageLabelingLabels);
  const smartLabelingEnable = useAppSelector(selectSmartLabelingEnabled);
  const readonly = useAppSelector(selectIsImageLabelingReadonly);
  const jobId = useAppSelector(selectImageLabelingActiveJobId);
  useMount(() => {
    if (selectedObservationId === -1) return;
    const label = labels.find((b) => b.id === selectedObservationId);
    if (label) {
      selectLabel(label);
      setTimeout(() => selectLabel(label), 200);
    }
  });

  function handleSelect(label: Label) {
    if (selectedObservationId === label.id) {
      return unselectLabel(label);
    }
    selectLabel(label);
  }

  function selectLabel(label: Label) {
    autoSelectAnnotationTool(label);
    dispatch(setSelectedObservationId(label.id));
    if (label.annotateType === AnnotateType.CLASSIFICATION) {
      dispatch(jobObservationEdited(jobId));
    }
  }

  function unselectLabel(label: Label) {
    const tool = label.annotateType;
    if (tool === AnnotateType.POLYGON || tool === AnnotateType.BOUNDING_BOX) {
      dispatch(setActiveCornerstoneTool(ToolName.Wwwc));
      dispatch(smartLabelingEnabled(false));
    }
    dispatch(setSelectedObservationId(-1));
  }

  function autoSelectAnnotationTool(label: Label) {
    if (readonly) return;
    const annotateType = label.annotateType;
    if (smartLabelingEnable) {
      dispatch(setActiveCornerstoneTool(ToolName.RectangleRoiExtend));
    } else if (annotateType === AnnotateType.POLYGON) {
      dispatch(setActiveCornerstoneTool(ToolName.FreehandRoiExtend));
    } else if (annotateType === AnnotateType.BOUNDING_BOX) {
      dispatch(setActiveCornerstoneTool(ToolName.RectangleRoiExtend));
    }
  }

  function handleRemove(label: Label) {
    console.log("handleRemove", label);
  }

  function handleLabelChange(label: Label) {
    dispatch(observationUpdated({ label }));
  }

  function handleVisibileChanged(label: Label, visible: boolean) {
    const payload = { label, visible };
    dispatch(setObservationVisible(payload));
  }
  function handleSelectedChange(label: Label, selected: boolean) {
    if (readonly) return;
    const payload = { label, selected };

    dispatch(setObservationSelected(payload));
    if (!selected) {
      selectLabel(label);
    }
    if (label.annotateType === AnnotateType.CLASSIFICATION) {
      dispatch(
        imageClassificationLabelSelectedAsync({
          labelId: label.id,
          jobId: jobId,
          selected: !label.selected,
        })
      );
    }
  }

  useEffect(() => {
    dispatch(imageJobSelectedAsync({ jobId }));
  }, [jobId, dispatch]);

  return (
    <div className="flex-auto">
      {header && (
        <div className="p-4 text-xs font-bold text-gray-400">{header}</div>
      )}
      {labels.map((label) => {
        return (
          <ObservationList
            deep={0}
            key={label.id}
            label={label}
            activeObservationId={selectedObservationId}
            onSelect={handleSelect}
            onRemove={handleRemove}
            onChange={handleLabelChange}
            onVisibleChange={handleVisibileChanged}
            onSelectedChange={handleSelectedChange}
            readonly={readonly}
          />
        );
      })}
    </div>
  );
};
