/*
 * File: delete-file.api.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 28th June 2021 2:49:55 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { storageAPIdelete } from "../storage-httpclient";

export const enum DELETE_FILE_OPTIONS {
  ARCHIVE = "archive",
  DELETE_FILE = "delete_file",
  DELETE_ALL = "delete_all",
};

export function deleteFile(fileId: number, option: string = DELETE_FILE_OPTIONS.ARCHIVE) {
  return storageAPIdelete(`api/v1/file-infos/${fileId}?option=${option}`);
}

