import { Backdrop, Checkbox } from "@material-ui/core";
import { usePrevious } from "ahooks";
import { IconCloseCircle } from "components/common/vb-icon.component";
import { MatModal } from "components/material/mat-modal.component";
import { useBuildObservationImportModelTree } from "hooks/use-observation-tree";
import { useAppSelector } from "hooks/use-redux";
import {
  TreeTableLayout,
  TreeTableLayoutColDef,
  TreeTableNameCell,
  TreeTableShowChildrenCell,
} from "pages/customer/projects/components/tree-table-layout.component";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BatchObservationCreationModel,
  BatchObservationDTO,
  ObservationDTO,
} from "services/label-service/dtos";
import { ObservationTreeNodeModel } from "services/label-service/model/observation-tree-node.model";
import { selectIsMdiSegmentation } from "store/customer/project/project.selectors";

export interface ObservationImportModel {
  observation: ObservationDTO;
  alreadyImported: boolean;
  selected: boolean;
  labelRequired: boolean;
  probabilityRequired: boolean;
  visible: boolean;
  showChildren: boolean;
}

export interface ImportLabelTreeModalProps {
  open: boolean;
  projectObservations: ObservationDTO[];
  currentBatchObservations:
    | BatchObservationDTO[]
    | BatchObservationCreationModel[];
  onClose?: () => void;
  onAdd?: (importObservations: ObservationImportModel[]) => void;
}
export const ImportLabelTreeModal = ({
  open,
  projectObservations,
  currentBatchObservations,
  onClose,
  onAdd,
}: ImportLabelTreeModalProps) => {
  const { t } = useTranslation();
  const isMdiProject = useAppSelector(selectIsMdiSegmentation);

  const [observationOptions, setObservationOptions] = useState<
    ObservationImportModel[]
  >([]);

  const {
    nodesList,
    setShowChildren,
    findAllNodeChildrenValues,
    findAllNodeParentValues,
    updatedVisibleNodes,
  } = useBuildObservationImportModelTree(observationOptions);
  const previousUpdatedVisibleNodes = usePrevious(updatedVisibleNodes);

  const setSelectedInList = (
    selected: boolean,
    list: (ObservationImportModel | null)[]
  ) => {
    setObservationOptions(
      observationOptions.map((ob) => {
        if (list.find((o) => o && o.observation.id === ob.observation.id)) {
          return {
            ...ob,
            selected: selected,
          };
        }
        return ob;
      })
    );
  };

  const handleAddChange = (
    node: ObservationTreeNodeModel<ObservationImportModel> | null,
    add: boolean
  ) => {
    if (!node || !node.value) return;
    const parentValues = findAllNodeParentValues(node);
    const childrenValues = findAllNodeChildrenValues(node.id);
    if (add) {
      setSelectedInList(true, [...parentValues, node.value]);
      setObservationOptions(
        observationOptions.map((ob) => {
          // update parents labelRequired, probabilityRequired to false
          if (
            parentValues.find(
              (o) => o && o.observation.id === ob.observation.id
            )
          ) {
            return {
              ...ob,
              labelRequired: false,
              probabilityRequired: false,
              selected: true,
            };
          }
          if (ob.observation.id === node.value?.observation.id) {
            return {
              ...ob,
              labelRequired: true,
              probabilityRequired: true,
              selected: true,
            };
          }
          return ob;
        })
      );
    } else {
      setSelectedInList(false, [...childrenValues, node.value]);
    }
  };

  useEffect(() => {
    if (
      updatedVisibleNodes.length <= 0 ||
      updatedVisibleNodes === previousUpdatedVisibleNodes
    )
      return;
    const newLabels = observationOptions.map((label) => {
      const node = updatedVisibleNodes.find(
        (n) => n.value?.observation.id === label.observation.id
      );
      if (node) {
        return {
          ...label,
          visible: node.visible,
          showChildren: node.showChildren,
        };
      } else {
        return label;
      }
    });
    setObservationOptions(newLabels);
  }, [updatedVisibleNodes, previousUpdatedVisibleNodes, observationOptions]);

  useEffect(() => {
    const newLabels = projectObservations.map((obs) => {
      let batchObs = undefined;
      for (let bo of currentBatchObservations) {
        if (bo.observation.id === obs.id) {
          batchObs = bo;
        }
      }
      const alreadyImported = !!batchObs;
      return {
        observation: obs,
        alreadyImported: alreadyImported,
        selected: alreadyImported,
        labelRequired: batchObs ? batchObs.labelRequired : true,
        probabilityRequired: batchObs ? batchObs.probabilityRequired : true,
        visible: true,
        showChildren: true,
      };
    });
    setObservationOptions(newLabels);
  }, [currentBatchObservations, projectObservations]);

  const closeDialog = () => {
    onClose && onClose();
  };

  const handleAdd = async () => {
    onAdd && onAdd(observationOptions);
  };

  const COLUMNS: TreeTableLayoutColDef[] = [
    {
      header: t("project:batchDetails.batchLabels.table.headerName"),
      headerClassName: "",
      flex: 1,
      render: (node: ObservationTreeNodeModel<ObservationImportModel>) => {
        return (
          <div className="truncate">
            {node.value && (
              <TreeTableNameCell
                name={node.value.observation?.name}
                nodeHeight={node.height}
                paddingScaler={4}
              />
            )}
          </div>
        );
      },
    },

    {
      header: "",
      flex: 0.2,
      render: (node: ObservationTreeNodeModel<ObservationImportModel>) => {
        const isLeaf = ObservationTreeNodeModel.isLeaf(node);
        return (
          <TreeTableShowChildrenCell
            isLeaf={isLeaf}
            showChildren={node.showChildren}
            nodeId={node.id}
            setShowChildren={setShowChildren}
          />
        );
      },
    },

    {
      header: isMdiProject ? t("project:batchDetails.batchLabels.table.headerMaskId") : "",
      flex: isMdiProject ? 0.3 : 0,
      render: (node: ObservationTreeNodeModel<ObservationImportModel>) => {
        return (
          <div>{node.value?.observation.observationSetting.maskId || "-"}</div>
        );
      },
    },

    {
      header: t("project:batchDetails.batchLabels.table.headerColor"),
      flex: 0.5,
      render: (node: ObservationTreeNodeModel<ObservationImportModel>) => {
        return (
          <div
            className="w-6 h-4"
            style={{ backgroundColor: node?.value?.observation?.observationSetting.color }}
          />
        );
      },
    },

    {
      header: t("project:batchDetails.batchLabels.table.headerAnnotationType"),
      flex: 0.5,
      render: (node: ObservationTreeNodeModel<ObservationImportModel>) => (
        <div>{node.value && node.value.observation.observationSetting.annotationType}</div>
      ),
    },
    {
      header: t("project:batchDetails.batchLabels.table.headerAdd"),
      flex: 0.3,
      render: (node: ObservationTreeNodeModel<ObservationImportModel>) => {
        return (
          <div>
            {node.value &&
              !node.value?.observation.observationSetting?.systemAttribute && (
                <Checkbox
                  disabled={
                    node.value.alreadyImported ||
                    !node.value.observation.active ||
                    !node.value.observation.active
                  }
                  color="primary"
                  checked={node.value.selected as boolean}
                  onChange={(e) => handleAddChange(node, e.target.checked)}
                />
              )}
          </div>
        );
      },
    },
  ];

  const nodesToDisplay = useMemo(() => {
    return nodesList.filter(
      (node) => !node.value?.observation.observationSetting.systemAttribute
    );
  }, [nodesList]);

  return (
    <MatModal
      disableBackdropClick
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={closeDialog}
    >
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div
          className="flex flex-col w-full h-full p-6 bg-white rounded"
          style={{
            maxWidth: "60rem",
            maxHeight: "35rem",
          }}
        >
          <div className="flex justify-between w-full mb-2">
            <div>
              <h1 className="text-lg font-bold text-primary">
                {t("project:batchDetails.batchLabels.import.title")}
              </h1>
              <p className="text-background-500">
                {t("project:batchDetails.batchLabels.import.description")}
              </p>
            </div>

            <button onClick={closeDialog} className="self-start hover:bg-white">
              <IconCloseCircle className="w-6 h-6" />
            </button>
          </div>
          <div
            className="flex justify-between flex-1 w-full overflow-y-auto text-sm"
            style={{
              maxHeight: "36rem",
            }}
          >
            <TreeTableLayout columns={COLUMNS} nodes={nodesToDisplay} />
          </div>
          <div className="flex justify-end w-full gap-4 mt-4">
            <button onClick={onClose} className="button-text-secondary">
              <span>{t("common:buttonCancel")}</span>
            </button>
            <button onClick={handleAdd} className="button-text-primary">
              <span>{t("common:buttonAdd")}</span>
            </button>
          </div>
        </div>
      </div>
    </MatModal>
  );
};
