/*
 * File: workflow.state.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 14th August 2021 2:29:23 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { StepConditionDTO, WorkflowDTO } from "services/label-service/dtos";
import { BaseState, RequestStatus } from "store/base/base.state";

export interface WorkflowState extends BaseState<WorkflowDTO> {
  stepConditions: StepConditionDTO[];
}

export const INITIAL_WORKFLOW_STATE: WorkflowState = {
  entities: {},
  ids: [],
  id: -1,
  status: RequestStatus.IDLE,
  error: null,
  stepConditions: [],
};
