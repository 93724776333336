/*
 * File: labeling-utils.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 17th April 2023 4:06:14 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { StorageFileDTO } from "models/dataset/storage-file.model";
import { isFileDicom } from "utilities/dicom/dicom.utils";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";

const parseUltrasoundData = async (file: StorageFileDTO) => {
  if (!isFileDicom(file)) return undefined;
  try {
    const downloadUrls = file.downloadUrls ?? [];
    const imageUrl = downloadUrls.length > 0 ? downloadUrls[0] : "";
    const dataSet =
      await cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.load(
        imageUrl,
        cornerstoneWADOImageLoader.internal.xhrRequest
      );
    const numFrames = dataSet.intString("x00280008");
    const frameRate = 1000 / dataSet.floatString("x00181063");
    return { numFrames, frameRate };
  } catch (error) {}
  return undefined;
};

const parseDicomData = async (file: StorageFileDTO) => {
  if (!isFileDicom(file)) return undefined;
  return file.additionalProperties?.metadata?.metadata;
};

export const labelingUtils = {
  parseUltrasoundData,
  parseDicomData,
};
