/*
 * File: user-workspace.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 31st July 2021 1:53:12 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { UserService } from "services/user-service";
import { GroupDTO } from "services/user-service/dtos/group.dto";
import { RequestStatus } from "store/base/base.state";
import { UserWorkspaceState } from "./user-workspace.state";

const SLICE_NAME = "user_workspace";

export const loadWorkspacesAsync = createAsyncThunk(
  `${SLICE_NAME}/loadWorkspacesAsync`,
  async () => {
    const response = await UserService.getAllGroups();
    return response.data;
  }
);

export const createWorkspaceAsync = createAsyncThunk(
  `${SLICE_NAME}/createWorkspaceAsync`,
  async (payload: GroupDTO) => {
    const response = await UserService.createGroup(payload);
    return response.data;
  }
);

export const deleteWorkspaceAsync = createAsyncThunk(
  `${SLICE_NAME}/deleteWorkspaceAsync`,
  async (payload: string) => {
    await UserService.deleteGroup(payload);
    return payload;
  }
);

export const userWorkspaceReducerBuilder = (
  builder: ActionReducerMapBuilder<UserWorkspaceState>
): ActionReducerMapBuilder<UserWorkspaceState> => {
  return builder
    .addCase(loadWorkspacesAsync.pending, (state) => {
      state.loading = true;
      state.requestStatus = RequestStatus.LOADING;
    })
    .addCase(loadWorkspacesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoaded = true;
      state.workspaces = action.payload;
      state.workspaces.sort((a, b) => {
        const lastSelectA = a.lastSelect ? a.lastSelect : 0;
        const lastSelectB = b.lastSelect ? b.lastSelect : 0;
        return lastSelectB - lastSelectA;
      });
      state.requestStatus = RequestStatus.SUCCESS;
    })
    .addCase(loadWorkspacesAsync.rejected, (state) => {
      state.loading = false;
      state.requestStatus = RequestStatus.FAILURE;
    })
    .addCase(createWorkspaceAsync.pending, (state) => {
      state.loading = true;
      state.requestStatus = RequestStatus.LOADING;
    })
    .addCase(createWorkspaceAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.workspaces = [action.payload, ...state.workspaces];
      state.requestStatus = RequestStatus.SUCCESS;
    })

    .addCase(createWorkspaceAsync.rejected, (state) => {
      state.loading = false;
      state.requestStatus = RequestStatus.FAILURE;
    })
    .addCase(deleteWorkspaceAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.workspaces = state.workspaces.filter(
        (workspace) => workspace.groupId !== action.payload
      );
      state.requestStatus = RequestStatus.SUCCESS;
    });
};
