
export const COLOR_OPTIONS = [
    "#ff0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#00FFFF",
    "#800080",
    "#808000",
    "#C0C0C0",
    "#000000",
    "#FFFFFF",
];

export const getRandomColor = () => {
    return COLOR_OPTIONS[Math.floor(Math.random()*COLOR_OPTIONS.length)];
}