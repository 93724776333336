/*
 * File: user-service.httpclient.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { setupAuthInterceptor } from "services/common/auth-interceptor";
import { ENV_CONFIG } from "../../../configs/env.config";

const _defaultHeaderConfig: AxiosRequestConfig = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: false,
};

let _axiosInstance: AxiosInstance | undefined = undefined;

function getAxiosInstance() {
  if (_axiosInstance) return _axiosInstance;
  _axiosInstance = setupAuthInterceptor(
    axios.create({
      baseURL: ENV_CONFIG.USER_SERVICE_URL,
    })
  );
  return _axiosInstance;
}

function APIGet(url: string, config = _defaultHeaderConfig) {
  return getAxiosInstance().get(url, config);
}

function APIPost(url: string, data = {}, config = _defaultHeaderConfig) {
  return getAxiosInstance().post(url, data, config);
}

function APIDelete(url: string, config = _defaultHeaderConfig) {
  return getAxiosInstance().delete(url, config);
}

function APIPut(url: string, date = {}, config = _defaultHeaderConfig) {
  return getAxiosInstance().put(url, date, config);
}

function APIPatch(url: string, date = {}, config = _defaultHeaderConfig) {
  return getAxiosInstance().patch(url, date, config);
}

export const UserAPI = {
  get: APIGet,
  post: APIPost,
  put: APIPut,
  patch: APIPatch,
  delete: APIDelete,
};
