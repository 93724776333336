/*
 * File: ai-result-preview-empty.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 22nd February 2022 2:00:34 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { IconGallary } from "components/common/vb-icon.component";

export const AIResultPreviewEmpty = () => {
  return (
    <div className="flex flex-col items-center justify-center flex-none w-40 h-40 gap-4 border border-dashed rounded xl:w-60 xl:h-60 border-background-300 bg-white dark:bg-white dark:text-background-700">
      <IconGallary className="flex-none w-6 h-6 xl:w-8 xl:h-8 text-background-500" />
      <div className="px-4 space-x-1 text-sm text-center">
        <span>Result will display here</span>
      </div>
    </div>
  );
};
