import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { VBButton } from "components/common/vb-button.component";

interface ButtonSaveProps {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const ButtonSave = ({
  className,
  disabled = false,
  onClick,
}: ButtonSaveProps) => {
  const { t } = useTranslation();
  return (
    <div className={classnames(disabled && "cursor-not-allowed")}>
      <VBButton
        className={classnames(
          className,
          "w-28 text-white justify-center",
          disabled
            ? "bg-gray-200 pointer-events-none "
            : "bg-primary-500 hover:bg-primary-700"
        )}
        disabled={disabled}
        onClick={onClick}
      >
        <div className="flex">
          <i className="mr-2 uir-disk" />
          <div>{t("common:buttonSave")}</div>
        </div>
      </VBButton>
    </div>
  );
};
