import { usePrevious } from "ahooks";
import { Label } from "domain/text-labeling";
import { ObservationStatsRowModel } from "models/common/observations-stats";
import { ObservationImportModel } from "pages/customer/projects/project-batch/batch-detail/pages/labels/components/import-label-tree.modal";
import { SpeechToTextLabelUIModel } from "pages/labeler/speech-to-text-labeling/context/speech-to-text-labeling.state";
import { useEffect, useState } from "react";
import { BatchObservationCreationModel, BatchObservationDTO, ObservationDTO } from "services/label-service/dtos";
import { ObservationTreeNodeModel, TreeNodeVisibleData, TreeNodeVisibleDataMap } from "services/label-service/model/observation-tree-node.model";
import { TaskReviewBatchObservationModel } from "store/customer/tasks-review/tasks-review.state";


export function useBuildTree<T extends any>(
  values: T[],
  getChildrenValues: (value: T | null, values: T[]) => T[],
  getNodeVisibleData?: (value: T | null) => TreeNodeVisibleData | null,
  cloneTreeAfterSetVisibleChildren = true,
  defaultVisible = false,
){
  const [rootNode, setRootNode] = useState<ObservationTreeNodeModel<T>>(
    new ObservationTreeNodeModel<T>(null, null, 0)
  );
  const previousValues = usePrevious(values);
  const [updatedVisibleNodes, setUpdatedVisibleNodes] = useState<ObservationTreeNodeModel<T>[]>([]);
  const [nodesList, setNodesList] = useState<ObservationTreeNodeModel<T>[]>([]);
  const cloneNewTree = (): any => {
    // clone new tree in order to rerender UI
    const cloneTree = (oldNode: ObservationTreeNodeModel<T>, newNode: ObservationTreeNodeModel<T>) => {
      for (let i=0; i<oldNode.children.length; i++){
        const oldChild = oldNode.children[i];
        const newChild = {
          ...oldChild,
          parent: newNode,
          children: [],
        }
        newNode.children.push(newChild);
        cloneTree(oldChild, newChild);
      }
    }

    const newRootNode = {
      ...rootNode,
      children: [],
    };
    cloneTree(rootNode, newRootNode);
    setRootNode(newRootNode);
    const newNodeList = getAllNodeList(newRootNode);
    setNodesList(newNodeList);
    return newNodeList;
  }

  const findAllNodeChildren = (nodeId: number): ObservationTreeNodeModel<T>[] => {
    const nodes: ObservationTreeNodeModel<T>[]  = [];

    const travel = (node: ObservationTreeNodeModel<T>) => {
      for (let i=0; i<node.children.length; i++){
        const childNode = node.children[i];
        nodes.push(childNode);
        travel(childNode);
      }
    }

    const node = findNodeById(nodeId);
    if (node){
      travel(node);
    }

    return nodes;
  }

  const findAllNodeChildrenValues = (nodeId: number): (T | null)[] => {
    const nodes = findAllNodeChildren(nodeId);
    return nodes.map(n => n.value);
  }

  const findAllNodeParents = (node: ObservationTreeNodeModel<T>, excludeRoot = true): ObservationTreeNodeModel<T>[] => {
    const nodes: ObservationTreeNodeModel<T>[]  = [];
    let currentParent = node.parent;
    while (currentParent){
      if (excludeRoot && !currentParent.parent){
        break;
      }
      nodes.push(currentParent);
      currentParent = currentParent.parent;
    }
    return nodes.reverse();
  }

  const findAllNodeParentValues = (node: ObservationTreeNodeModel<T>, excludeRoot = true): (T | null)[] => {
    const nodes = findAllNodeParents(node, excludeRoot);
    return nodes.map(n => n.value);
  }

  const findNodeById = (nodeId: number): ObservationTreeNodeModel<T> | null => {
    const travelAndFind = (node: ObservationTreeNodeModel<T>): ObservationTreeNodeModel<T> | null => {
      if (node.id === nodeId) {
        return node;
      };
      for (let i=0; i<node.children.length; i++){
        let resNode = travelAndFind(node.children[i]);
        if (resNode) return resNode;
      }
      return null;
    }
    return travelAndFind(rootNode);
  }

  const setShowChildren = (nodeId: number, showChildren: boolean) => {
    const updatedNodes: ObservationTreeNodeModel<T>[] = [];
    const travelAndUpdate = (node: ObservationTreeNodeModel<T>, needUpdateVisible = false, visible = true) => {
      if (needUpdateVisible){
        node.visible = visible;
        updatedNodes.push(node);
      }

      for (let i=0; i<node.children.length; i++){
        const childNode = node.children[i];
        if (node.id === nodeId || needUpdateVisible){
          node.showChildren = showChildren;
          updatedNodes.push(node);
          travelAndUpdate(childNode, true, node.showChildren);
        } else {
          travelAndUpdate(childNode);
        }
      }
    }
    travelAndUpdate(rootNode);
    setUpdatedVisibleNodes(updatedNodes);
    if (cloneTreeAfterSetVisibleChildren){
      cloneNewTree();
    }

  }

  // hanlde for drag and drop case (change children order)
  const updateNodeChildrenOrder = (nodeId: number, orderedItemIds: number[]) => {
    const node = findNodeById(nodeId);
    if (node){
      const mapOrderScore = {} as any;
      for (let i=0; i<orderedItemIds.length; i++){
        mapOrderScore[orderedItemIds[i]] = i;
      }
      node.children = node.children.sort((c1, c2) => mapOrderScore[c1.id] - mapOrderScore[c2.id]);
      return cloneNewTree();
    }
    return null;
  }

  const getAllNodeList = (rootNode: ObservationTreeNodeModel<T>): ObservationTreeNodeModel<T>[] => {
    const nodes: ObservationTreeNodeModel<T>[] = [];

    const travel = (node: ObservationTreeNodeModel<T>) => {
      if (!ObservationTreeNodeModel.isRoot(node)){
        nodes.push(node);
      }
      for (let childNode of node.children){
        travel(childNode);
      }
    }
    travel(rootNode);

    return nodes;
  }

  useEffect(() => {
    if (!values || values.length <= 0 || values === previousValues) {
      return;
    }
    let id = 1;
    const nodes: ObservationTreeNodeModel<T>[] = [];
    const buildTree = (node: ObservationTreeNodeModel<T>) => {
      const childrenValues = getChildrenValues(node.value, values);
      for (let i=0; i<childrenValues.length; i++){
        const childValue = childrenValues[i];
        const childNode = new ObservationTreeNodeModel<T>(
          childValue, node, node.height + 1, defaultVisible,
        );
        childNode.id = id;
        id++;

        if (getNodeVisibleData){
          const visibleData = getNodeVisibleData(childValue);
          if (visibleData){
            childNode.visible = visibleData.visible;
            childNode.showChildren = visibleData.showChildren;
          }
        }

        node.children.push(childNode);
        nodes.push(childNode);
        buildTree(childNode);
      }
    }

    let rootNode = new ObservationTreeNodeModel<T>(null, null, 0);
    buildTree(rootNode);
    setRootNode(rootNode);
    setNodesList(nodes);
  }, [values,
      previousValues,
      getNodeVisibleData,
      getChildrenValues,
      defaultVisible]);

  return {
    rootNode,
    updatedVisibleNodes,
    setShowChildren,
    updateNodeChildrenOrder,
    findAllNodeChildrenValues,
    findAllNodeParentValues,
    getAllNodeList,
    nodesList,
  };
}

export function useBuildObservationTree(values: ObservationDTO[]){
  function getChildrenValues(
    value: ObservationDTO | null,
    values: ObservationDTO[]
  ) : ObservationDTO[]{
    return values.filter(v => {
      return value ? v.parent?.id === value.id : !v.parent;
    });
  }
  return useBuildTree<ObservationDTO>(values, getChildrenValues);
}

export function useBuildBatchObservationTree(values: BatchObservationDTO[]){
  function getChildrenValues(
    value: BatchObservationDTO | null,
    values: BatchObservationDTO[]
  ) : BatchObservationDTO[]{
    return values.filter(v => {
      return value ? v.observation.parent?.id === value.observation.id : !v.observation.parent;
    });
  }
  return useBuildTree<BatchObservationDTO>(values, getChildrenValues);
}

export function useBuildBatchObservationTreeWithVisibleData(
  values: BatchObservationDTO[],
  visibleData: TreeNodeVisibleDataMap,
){
  function getChildrenValues(
    value: BatchObservationDTO | null,
    values: BatchObservationDTO[]
  ) : BatchObservationDTO[]{
    return values.filter(v => {
      return value ? v.observation.parent?.id === value.observation.id : !v.observation.parent;
    });
  }

  function getNodeVisibleData(value: BatchObservationDTO | null): TreeNodeVisibleData | null{
    if (value && visibleData.hasOwnProperty(value.observation.id)){
      return {
        visible: visibleData[value.observation.id].visible,
        showChildren: visibleData[value.observation.id].showChildren,
      }
    }
    return null;
  }

  return useBuildTree<BatchObservationDTO>(
    values,
    getChildrenValues,
    getNodeVisibleData,
  );
}

export function useBuildPartialBatchObservationTree(values: Partial<BatchObservationDTO>[]){
  function getChildrenValues(
    value: Partial<BatchObservationDTO> | null,
    values: Partial<BatchObservationDTO>[]
  ) : Partial<BatchObservationDTO>[]{
    return values.filter(v => {
      return value ? v.observation?.parent?.id === value.observation?.id : !v.observation?.parent;
    });
  }
  return useBuildTree<Partial<BatchObservationDTO>>(values, getChildrenValues);
}

export function useBuildBatchObservationCreationModelTree(
  values: BatchObservationCreationModel[],
  cloneTreeAfterSetVisibleChildren = true,
) {
  function getChildrenValues(
    value: BatchObservationCreationModel | null,
    values: BatchObservationCreationModel[]
  ) : BatchObservationCreationModel[]{
    return values.filter(v => {
      return value ? v.observation?.parent?.id === value.observation?.id : !v.observation?.parent;
    });
  }

  function getNodeVisibleData(value: BatchObservationCreationModel | null): TreeNodeVisibleData | null{
    if (value){
      return {
        visible: value.visible,
        showChildren: value.showChildren,
      }
    }
    return null;
  }

  return useBuildTree<BatchObservationCreationModel>(
    values,
    getChildrenValues,
    getNodeVisibleData,
    cloneTreeAfterSetVisibleChildren,
  );
}

export function useBuildTaskReviewBatchObservationTree(values: TaskReviewBatchObservationModel[]){
  function getChildrenValues(
    value: TaskReviewBatchObservationModel | null,
    values: TaskReviewBatchObservationModel[]
  ) : TaskReviewBatchObservationModel[]{
    return values.filter(v => {
      return value ? v.batchObservation.observation.parent?.id === value.batchObservation.observation.id :
                     !v.batchObservation.observation.parent;
    });
  }
  return useBuildTree<TaskReviewBatchObservationModel>(values, getChildrenValues, undefined, true, true);
}

export function useBuildObservationStatsRowModelTree<T extends ObservationStatsRowModel>(values:T[]){
  function getChildrenValues(
    value: T | null,
    values: T[]
  ) : T[]{
    return values.filter(v => {
      return value ? v.observation.parent?.id === value.observation.id : !v.observation.parent;
    });
  }
  return useBuildTree<T>(values, getChildrenValues);
}

export function useBuildObservationImportModelTree(
  values: ObservationImportModel[],
  cloneTreeAfterSetVisibleChildren = true,
) {
  function getChildrenValues(
    value: ObservationImportModel | null,
    values: ObservationImportModel[]
  ) : ObservationImportModel[]{
    return values.filter(v => {
      return value ? v.observation?.parent?.id === value.observation?.id : !v.observation?.parent;
    });
  }

  function getNodeVisibleData(value: ObservationImportModel | null): TreeNodeVisibleData | null{
    if (value){
      return {
        visible: value.visible,
        showChildren: value.showChildren,
      }
    }
    return null;
  }

  return useBuildTree<ObservationImportModel>(
    values,
    getChildrenValues,
    getNodeVisibleData,
    cloneTreeAfterSetVisibleChildren,
  );
}

export function useBuildSTTLabelTree(
  values: SpeechToTextLabelUIModel[],
  cloneTreeAfterSetVisibleChildren = true,
) {
  function getChildrenValues(
    value: SpeechToTextLabelUIModel | null,
    values: SpeechToTextLabelUIModel[]
  ) : SpeechToTextLabelUIModel[]{
    return values.filter(v => {
      return value ? v.observation?.parent?.id === value.observation?.id : !v.observation?.parent;
    });
  }

  function getNodeVisibleData(value: SpeechToTextLabelUIModel | null): TreeNodeVisibleData | null{
    if (value){
      return {
        visible: value.visible,
        showChildren: value.showChildren,
      }
    }
    return null;
  }

  return useBuildTree<SpeechToTextLabelUIModel>(
    values,
    getChildrenValues,
    getNodeVisibleData,
    cloneTreeAfterSetVisibleChildren,
  );
}

export function useBuildTextLabelTree(values: Label[]){
  function getChildrenValues(
    value: Label | null,
    values: Label[]
  ) : Label[]{
    return values.filter(v => {
      return value ? v.observation.parent?.id === value.observation.id : !v.observation.parent;
    });
  }
  return useBuildTree<Label>(values, getChildrenValues, undefined, true, true);
}