/*
 * File: text-issues.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 11th July 2022 1:57:26 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { TextWorkspaceState } from "../text-workspace.state";
import { addTextIssueCommentBuilder } from "./thunks/add-comment.thunk";
import { addTextIssueReducerBuidler } from "./thunks/add-issue.thunk";
import { loadTextIssueBuilder } from "./thunks/load-text-issues.thunk";
import { resolveTextIssueBuilder } from "./thunks/resolve-issue.thunk";

export const textIssuesReducerBuilder = (
  builder: ActionReducerMapBuilder<TextWorkspaceState>
) => {
  addTextIssueReducerBuidler(builder);
  addTextIssueCommentBuilder(builder);
  resolveTextIssueBuilder(builder);
  loadTextIssueBuilder(builder);
};
