import { Tooltip } from "@material-ui/core";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useState } from "react";
import { ProjectTypeNames, ProjectTypes } from "services/label-service/dtos";
import { classnames } from "utilities/classes";
import { formatNumber } from "utilities/formatter/number-formatter.utils";
import { getPercentage } from "utilities/misc/misc.utils";
import { useDashboardContext } from "../context/dashboard.context";
import { QueryNameStatistic } from "../context/dashboard.state";
import { useTableSort } from "../hooks/use-table-sort.hook";
import { DashboardTableCommon } from "./dashboard-table-common.component";

export interface RowDataTableProject {
  id: number;
  name: string;
  numBatch: number;
  numBatchInitial: number;
  numBatchWorking: number;
  numBatchCompleted: number;
  numCompletedTask: number;
  numLabelingHours: number;
}

interface ProjectStatisticTableProps {
  data: RowDataTableProject[];
}
export const ProjectStatisticTable = ({ data }: ProjectStatisticTableProps) => {
  const { rows, sort, handleSelectSort } =
    useTableSort<RowDataTableProject>(data);

  const maxData: RowDataTableProject = {
    id: -1,
    name: "",
    numBatch: 0,
    numBatchInitial: 0,
    numBatchWorking: 0,
    numBatchCompleted: 0,
    numCompletedTask: 0,
    numLabelingHours: 0,
  };
  data.forEach((itemData) => {
    maxData.numBatch = Math.max(maxData.numBatch, itemData.numBatch);
    maxData.numBatchInitial = Math.max(
      maxData.numBatchInitial,
      itemData.numBatchInitial
    );
    maxData.numBatchWorking = Math.max(
      maxData.numBatchWorking,
      itemData.numBatchWorking
    );
    maxData.numBatchCompleted = Math.max(
      maxData.numBatchCompleted,
      itemData.numBatchCompleted
    );
    maxData.numCompletedTask = Math.max(
      maxData.numCompletedTask,
      itemData.numCompletedTask
    );
    maxData.numLabelingHours = Math.max(
      maxData.numLabelingHours,
      itemData.numLabelingHours
    );
  });

  const COLOR_INITIAL = "#3241FF";
  const COLOR_WORKING = "#FFBE6F";
  const COLOR_COMPLETED = "#3990FF";

  const [columns] = useState<
    {
      field: keyof RowDataTableProject;
      header: string;
      color?: string;
      renderCell: (row: RowDataTableProject) => JSX.Element;
    }[]
  >([
    {
      field: "id",
      header: "ID",
      renderCell: (row: RowDataTableProject) => {
        return <span className="text-xs text-primary">{row.id}</span>;
      },
    },
    {
      field: "name",
      header: "Name",
      renderCell: (row: RowDataTableProject) => {
        return <span className="text-xs text-background-700">{row.name}</span>;
      },
    },
    {
      field: "numBatch",
      header: "Number of Batch",
      renderCell: (row: RowDataTableProject) => {
        const percentInitial = getPercentage(
          row.numBatchInitial,
          maxData.numBatch
        );
        const percentWorking = getPercentage(
          row.numBatchWorking,
          maxData.numBatch
        );
        const percentCompleted = parseFloat(
          (100 - percentInitial - percentWorking).toFixed(2)
        );
        const spanData: {
          value: number;
          percent: number;
          color: string;
          textColor: string;
        }[] = [
          {
            value: row.numBatchInitial,
            percent: percentInitial,
            color: COLOR_INITIAL,
            textColor: "white",
          },
          {
            value: row.numBatchWorking,
            percent: percentWorking,
            color: COLOR_WORKING,
            textColor: "#53616D",
          },
          {
            value: row.numBatchCompleted,
            percent: percentCompleted,
            color: COLOR_COMPLETED,
            textColor: "white",
          },
        ];

        return (
          <div
            className="flex w-full h-4 gap-1"
            style={{
              minWidth: "300px",
            }}
          >
            <div className="flex flex-1">
              {spanData.map((item) => {
                return (
                  <Tooltip
                    title={`${item.percent}% (${item.value})`}
                    placement="top"
                    key={item.color}
                  >
                    <div
                      className="relative flex items-center overflow-hidden"
                      style={{ width: `${item.percent}%` }}
                    >
                      <span
                        className="w-full h-4"
                        style={{
                          background: item.color,
                        }}
                      />
                      <span
                        className="absolute left-1.5 top-0 bottom-0"
                        style={{ color: item.textColor }}
                      >
                        {item.percent > 30 &&
                          `${item.percent}% (${item.value})`}
                        {item.percent <= 30 && `${item.percent}%`}
                      </span>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
            <div>{row.numBatch}</div>
          </div>
        );
      },
    },
    {
      field: "numCompletedTask",
      header: "Completed task",
      renderCell: (row: RowDataTableProject) => {
        const percent = getPercentage(
          row.numCompletedTask,
          maxData.numCompletedTask
        );
        return (
          <div className="relative flex items-center w-full h-4 gap-1">
            <span
              className="h-4"
              style={{ width: `${percent}%`, backgroundColor: "#D1DBDF" }}
            />
            <span
              className={classnames({
                "absolute left-1.5 top-0 bottom-0 text-white": percent > 20,
              })}
            >
              {formatNumber(row.numCompletedTask)}
            </span>
          </div>
        );
      },
    },
    {
      field: "numLabelingHours",
      header: "Labeling hours",
      renderCell: (row: RowDataTableProject) => {
        const percent = getPercentage(
          row.numLabelingHours,
          maxData.numLabelingHours
        );
        return (
          <div className="relative flex items-center w-full h-4 gap-1">
            <span
              className="h-4"
              style={{ width: `${percent}%`, backgroundColor: "#D1DBDF" }}
            />
            <span
              className={classnames({
                "absolute left-1.5 top-0 bottom-0 text-white": percent > 20,
              })}
            >
              {formatNumber(row.numLabelingHours)}
            </span>
          </div>
        );
      },
    },
  ]);

  const colorLegends: {
    name: string;
    color: string;
  }[] = [
    {
      name: "Initial",
      color: COLOR_INITIAL,
    },
    {
      name: "Working",
      color: COLOR_WORKING,
    },
    {
      name: "Completed",
      color: COLOR_COMPLETED,
    },
  ];

  const projectTypeOptions = [
    {
      label: ProjectTypeNames.medical_image,
      value: ProjectTypes.MEDICAL_IMAGE,
    },
    {
      label: ProjectTypeNames.image,
      value: ProjectTypes.GENERAL_IMAGE,
    },
    {
      label: ProjectTypeNames.text,
      value: ProjectTypes.TEXT,
    },
    {
      label: ProjectTypeNames.audio,
      value: ProjectTypes.AUDIO,
    },
  ];

  const {
    requestData,
    setUseCache,
    fromDate,
    toDate,
    taskCompletedFilter,
    tableProjectType,
    setTableProjectType,
  } = useDashboardContext();

  const handleSelectProjectType = (option: any) => {
    const newProjectType = option ? option.value : undefined;
    setTableProjectType(newProjectType);
    if (fromDate && toDate) {
      setUseCache(false);
      requestData(
        fromDate,
        toDate,
        true,
        [QueryNameStatistic.ProjectProgressStatistic],
        taskCompletedFilter,
        newProjectType ? [newProjectType] : undefined
      );
    }
  };

  return (
    <div>
      <div className="flex w-full px-4 pt-4">
        <div className="flex items-center w-1/2 gap-3">
          <span>Project type</span>
          <div className="w-36">
            <VBSelectComponent
              header=""
              placeholder="All"
              value={projectTypeOptions.find(
                (option) =>
                  tableProjectType && option.value === tableProjectType
              )}
              onChange={handleSelectProjectType}
              options={projectTypeOptions}
              isClearable
              menuPortalTarget={document.body}
            />
          </div>
        </div>
        <div className="flex flex-row-reverse w-1/2 gap-6">
          {colorLegends.reverse().map((legend) => {
            return (
              <div className="flex items-center gap-2" key={legend.color}>
                <div
                  className="w-4 h-4"
                  style={{ background: legend.color }}
                ></div>
                <span>{legend.name}</span>
              </div>
            );
          })}
        </div>
      </div>
      <DashboardTableCommon
        rows={rows}
        columns={columns}
        sort={sort}
        handleSelectSort={handleSelectSort}
      />
    </div>
  );
};
