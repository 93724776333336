/*
 * File: dicom-tools.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 5th August 2021 10:19:15 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { IconBook } from "components/common/vb-icon.component";
import { LabelingMode } from "domain/labeling/labeling-mode";
import { useAppSelector } from "hooks/use-redux";
import { useBatchLabelingContext } from "pages/labeler/batch-labeling/context/batch-labeling.context";
import { useImageLabelingContext } from "pages/labeler/image-labeling/context/image-labeling.context";
import {
  selectWorkingBatch,
  selectWorkingProject,
} from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { selectSmartLabelingEnabled } from "store/labeler/image-workspace/smart-labeling/smart-labeling.selectors";
import { BatchInfoButton } from "./batch-info-button.component";
import { CornerstoneTool, SubType, ToolName } from "./dicom-tools.model";
import { SmartLabelingTools } from "./smart-labeling-tools.component";
import { ToolButton } from "./tool-button.component";
import { ToolGroup } from "./tool-group.component";

interface DicomToolsProps {
  activeTool: ToolName;
  annotateTools: CornerstoneTool[];
  tools: CornerstoneTool[];
  resetTools: CornerstoneTool[];
  otherTools: CornerstoneTool[];
  readonly?: boolean;
  onSelect?: (tool: CornerstoneTool) => void;
  onSelectSubType?: (tool: CornerstoneTool, type: SubType) => void;
  onSelectViews?(views: number): void;
}
export const DicomTools = ({
  tools,
  annotateTools,
  resetTools,
  otherTools,
  activeTool,
  onSelect,
  onSelectSubType,
  onSelectViews,
}: DicomToolsProps) => {
  const { mode } = useBatchLabelingContext();
  const { showLabelDetail, setShowLabelDetail } = useImageLabelingContext();
  const batch = useAppSelector(selectWorkingBatch);
  const project = useAppSelector(selectWorkingProject);
  const smartLabelingEnable = useAppSelector(selectSmartLabelingEnabled);

  return (
    <div className="relative z-10 flex flex-col flex-none h-full overflow-x-hidden overflow-y-auto border-l divide-y divide-gray-600 border-blueGray-600 bg-blueGray-900 divide-dashed dark-scrollbar">
      {mode !== LabelingMode.CUSTOMER && <SmartLabelingTools />}
      {mode !== LabelingMode.CUSTOMER && (
        <ToolGroup
          name="ANNOTATE"
          tools={annotateTools}
          activeTool={smartLabelingEnable ? undefined : activeTool}
          onSelect={onSelect}
          onSelectSubType={onSelectSubType}
        />
      )}
      <ToolGroup
        name="COMMON"
        tools={tools}
        activeTool={activeTool}
        onSelect={onSelect}
        onSelectViews={onSelectViews}
      />
      <ToolGroup
        name="RESET"
        tools={resetTools}
        activeTool={activeTool}
        onSelect={onSelect}
      />
      <ToolGroup
        name="OTHERS"
        tools={otherTools}
        activeTool={activeTool}
        onSelect={onSelect}
      />

      {mode !== LabelingMode.CUSTOMER && (
        <div className="flex flex-col flex-1">
          <div className="flex-1"></div>
          <div className="grid grid-cols-2 gap-2 px-2 my-4">
            <BatchInfoButton batch={batch} project={project} />
            <ToolButton
              icon={<IconBook className="w-6 h-6 text-white" />}
              toolTip={
                showLabelDetail
                  ? "Hide label definition"
                  : "Show label definition"
              }
              active={showLabelDetail}
              onClick={() => setShowLabelDetail(!showLabelDetail)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
