/*
 * File: project-progress-tab.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 13th September 2021 9:46:11 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBSpinner } from "components/common/vb-spinner/vb-spinner.component";
import { useProjectStatistic } from "hooks/project/use-project-statistic.hook";
import { useAppSelector } from "hooks/use-redux";
import { selectCurrentProject } from "store/customer/project/project.selectors";
import { ProjectProgressChart } from "./project-progress-chart.component";

export const ProjectProgress = () => {
  const { t } = useTranslation();
  const project = useAppSelector(selectCurrentProject);
  const { statistic } = useProjectStatistic(project?.id || -1);

  if (!statistic)
    return (
      <div className="flex items-center justify-center flex-1 p-10">
        <VBSpinner />
      </div>
    );

  return (
    <div className="mt-4">
      <div className="flex items-center w-full gap-20 my-4">
        <div className="bg-white rounded border-2">
          <ProjectProgressChart statistic={statistic.batchStatistic} />
        </div>
        <div className="text-xl">
          <li className="my-4">
            {t("project:overview.progressTab.numBatches", {
              num: statistic.totalBatches,
            })}
          </li>
          <li className="my-4">
            {t("project:overview.progressTab.numLabelers", {
              num: statistic.totalLabelers,
            })}
          </li>
          <li className="my-4">
            {statistic.startDate
              ? t("project:overview.progressTab.startingDate", {
                  date: new Date(statistic.startDate),
                })
              : ""}
          </li>
        </div>
      </div>
    </div>
  );
};
