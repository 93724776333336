/*
 * File: upload-file.reducer.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th April 2022 4:46:54 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export enum UploadStatus {
  DEFAULT = "default",
  UPLOADING = "uploading",
  UPLOADED = "uploaded",
  UPLOADED_WITH_WARNING = "warning",
  FAILED = "failed",
}

export interface UploadingFile {
  file: File;
  datasetId: number;
  progress: number;
  status: UploadStatus;
  error: Error | null;
  tagIds: number[];
}

export interface UploadState {
  files: UploadingFile[];
  uploadingFile: UploadingFile | null;
}

export enum UploadAction {
  REMOVE_FILES = "REMOVE_FILES",
  ADD_FILES_TO_UPLOAD = "ADD_FILES_TO_UPLOAD",
  UPDATE_FILE = "UPDATE_FILE",
  SET_UPLOADING_FILE = "SET_UPLOADING_FILE",
}

export function uploadReducer(
  state: UploadState,
  action: { type: UploadAction; payload: any }
) {
  switch (action.type) {
    case UploadAction.REMOVE_FILES:
      return {
        ...state,
        files: [],
      };

    case UploadAction.SET_UPLOADING_FILE:
      return {
        ...state,
        uploadingFile: action.payload,
      };
    case UploadAction.ADD_FILES_TO_UPLOAD:
      return {
        ...state,
        files: [...state.files, ...action.payload],
      };
    case UploadAction.UPDATE_FILE:
      return {
        ...state,
        files: [
          ...state.files.map((f) => {
            if (f.file.name === action.payload.file.name) {
              return {
                ...f,
                ...action.payload,
              };
            }
            return f;
          }),
        ],
      };
    default:
      return state;
  }
}
