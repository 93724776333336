import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { collectionUtils } from "domain/common";
import { ImageLabelingData } from "domain/labeling/labeling-data";
import { RootState } from "store";
import { RequestStatus } from "store/base/base.state";
import { selectActiveJobId } from "../../batch-labeling/batch-labeling.selectors";
import { batchLabelingUtils } from "../../batch-labeling/batch-labeling.util";
import { imageAnnotationUtils } from "../../image-annotations/image-annotations.util";
import { ImageWorkspaceState } from "../../image-workspace.state";
import { convertFileCleaningDataToMaskAnnotation } from "../image-labeling.utils";

const THUNK_NAME = "imageWorkspace/initImageLabelingAsync";

interface InitImageLabelingPayload {
  jobId: number;
}

export const initImageLabelingAsync = createAsyncThunk(
  THUNK_NAME,
  async ({ jobId }: InitImageLabelingPayload, { getState }) => {
    const state = getState() as RootState;
    const activeJobId = selectActiveJobId(state);
    if (jobId !== activeJobId) return null;
    const { imageLabelingJobs } = state.batchLabeling;
    const imageLabelingJob = collectionUtils.getOne(imageLabelingJobs, jobId);
    return { imageLabelingJob };
  }
);

export function initImageLabelingBuilder(
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) {
  return builder
    .addCase(initImageLabelingAsync.pending, ({ imageLabeling }) => {
      imageLabeling.requestStatus = RequestStatus.LOADING;
    })
    .addCase(initImageLabelingAsync.fulfilled, ({ imageLabeling }, action) => {
      if (!action.payload || !action.payload.imageLabelingJob) return;
      const { imageLabelingJob } = action.payload;
      const { task, job, file, batch, project, labels, data } =
        imageLabelingJob;
      imageLabeling.requestStatus = RequestStatus.SUCCESS;
      imageLabeling.task = task || null;
      imageLabeling.job = job || null;
      imageLabeling.file = file || null;
      imageLabeling.batch = batch || null;
      imageLabeling.project = project?.dto || null;
      imageLabeling.viewMediaAgreement = false;
      imageLabeling.selectedObservationId = -1;
      imageLabeling.agreements = [];
      imageLabeling.findingLabelers = [];
      imageLabeling.maskAnnotations = [];
      imageLabeling.labels = labels;

      if (file) {
        imageLabeling.maskAnnotations =
          convertFileCleaningDataToMaskAnnotation(file);
      }

      const imageLabelingDatas = data?.previousJobData || [];
      const previousJobAnnotations = imageLabelingDatas.map((data) =>
        batchLabelingUtils.labelingDataToJobAnnotation(
          data as ImageLabelingData
        )
      );
      
      imageLabeling.findingLabelers = imageAnnotationUtils.getFindingLabelers(
        previousJobAnnotations,
        imageLabeling.labels
      );

      imageLabeling.agreements = imageAnnotationUtils.getFindingAgreements(
        previousJobAnnotations
      );

      if (data?.jobData) {
        const imageLabelingData = data?.jobData as ImageLabelingData;
        if (imageLabelingData) {
          imageLabeling.labels = labels.map((label) => {
            const selected = !!imageLabelingData.annotations.find(
              (anno) => anno.labelId === label.id
            );
            return {
              ...label,
              selected,
            };
          });
        }
      }
    })
    .addCase(initImageLabelingAsync.rejected, ({ imageLabeling }) => {
      imageLabeling.requestStatus = RequestStatus.FAILURE;
    });
}
