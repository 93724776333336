/*
 * File: button-view-mode.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 13th July 2023 10:17:30 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VscCheck, VscChevronDown, VscListSelection } from "react-icons/vsc";
import { BsGrid } from "react-icons/bs";
import { useState } from "react";
import { useFileBrowseContext } from "../../context/file-browse.context";
import { ViewModeEnum } from "domain/common";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

export function ButtonViewMode() {
  const { viewMode, updateViewMode } = useFileBrowseContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function selectViewMode(mode: ViewModeEnum) {
    updateViewMode(mode);
    handleClose();
  }
  return (
    <div className="flex-none">
      <button
        className="px-3 py-1.5 flex items-center gap-1 rounded hover:bg-background-200"
        onClick={handleClick}
      >
        {viewMode === ViewModeEnum.GRID && <BsGrid size={18} />}
        {viewMode === ViewModeEnum.LIST && <VscListSelection size={18} />}
        <VscChevronDown size={16} />
      </button>

      <Menu
        id="view-mode-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "view-mode-menu" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={() => selectViewMode(ViewModeEnum.GRID)}>
          <div className="w-8 text-center">
            {viewMode === ViewModeEnum.GRID && <VscCheck size={18} />}
          </div>
          <div className="w-8 text-center">
            <BsGrid size={18} />
          </div>
          <ListItemText className="pr-4">Grid Layout</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => selectViewMode(ViewModeEnum.LIST)}>
          <div className="w-8 text-center">
            {viewMode === ViewModeEnum.LIST && <VscCheck size={18} />}
          </div>
          <div className="w-8 text-center">
            <VscListSelection size={18} />
          </div>
          <ListItemText className="pr-4">List Layout</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ButtonViewMode;
