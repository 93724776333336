/* tslint:disable */
/* eslint-disable */
/**
 * label API
 * label API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActiveLearningConfig
 */
export interface ActiveLearningConfig {
    /**
     * 
     * @type {boolean}
     * @memberof ActiveLearningConfig
     */
    'enable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ActiveLearningConfig
     */
    'ratio'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ActiveLearningConfig
     */
    'adaptiveTestSet'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActiveLearningConfig
     */
    'dataSelectionMode'?: ActiveLearningConfigDataSelectionModeEnum;
}

export const ActiveLearningConfigDataSelectionModeEnum = {
    Random: 'RANDOM',
    Top: 'TOP'
} as const;

export type ActiveLearningConfigDataSelectionModeEnum = typeof ActiveLearningConfigDataSelectionModeEnum[keyof typeof ActiveLearningConfigDataSelectionModeEnum];

/**
 * 
 * @export
 * @interface AnnotationAgreementDTO
 */
export interface AnnotationAgreementDTO {
    /**
     * 
     * @type {number}
     * @memberof AnnotationAgreementDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationAgreementDTO
     */
    'jobId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationAgreementDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationAgreementDTO
     */
    'score'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationAgreementDTO
     */
    'entityScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationAgreementDTO
     */
    'relationScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationAgreementDTO
     */
    'observationId'?: number;
}
/**
 * 
 * @export
 * @interface AnnotationAttribute
 */
export interface AnnotationAttribute {
    /**
     * 
     * @type {Array<AnnotationAttributeItem>}
     * @memberof AnnotationAttribute
     */
    'attributes'?: Array<AnnotationAttributeItem>;
}
/**
 * 
 * @export
 * @interface AnnotationAttributeItem
 */
export interface AnnotationAttributeItem {
    /**
     * 
     * @type {number}
     * @memberof AnnotationAttributeItem
     */
    'id'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnnotationAttributeItem
     */
    'value'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AnnotationAugmentationResponse
 */
export interface AnnotationAugmentationResponse {
    /**
     * 
     * @type {number}
     * @memberof AnnotationAugmentationResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationAugmentationResponse
     */
    'asyncJobId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationAugmentationResponse
     */
    'entityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationAugmentationResponse
     */
    'entityName'?: string;
    /**
     * 
     * @type {ThreeDAnnotationSmoothingPayloadResponse}
     * @memberof AnnotationAugmentationResponse
     */
    'payload'?: ThreeDAnnotationSmoothingPayloadResponse;
    /**
     * 
     * @type {string}
     * @memberof AnnotationAugmentationResponse
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface AnnotationCriteria
 */
export interface AnnotationCriteria {
    /**
     * 
     * @type {number}
     * @memberof AnnotationCriteria
     */
    'id.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCriteria
     */
    'id.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationCriteria
     */
    'batchId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCriteria
     */
    'batchId.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationCriteria
     */
    'mediaId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCriteria
     */
    'mediaId.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationCriteria
     */
    'jobId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCriteria
     */
    'jobId.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationCriteria
     */
    'categoryId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCriteria
     */
    'source.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCriteria
     */
    'source.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCriteria
     */
    'source.in'?: string;
}
/**
 * 
 * @export
 * @interface AnnotationDTO
 */
export interface AnnotationDTO {
    /**
     * 
     * @type {string}
     * @memberof AnnotationDTO
     */
    'assignee'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationDTO
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationDTO
     */
    'id'?: number;
    /**
     * 
     * @type {{ [key: string]: any | undefined; }}
     * @memberof AnnotationDTO
     */
    'annotation'?: { [key: string]: any | undefined; };
    /**
     * 
     * @type {string}
     * @memberof AnnotationDTO
     */
    'annotationType'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationDTO
     */
    'jobId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationDTO
     */
    'mediaId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationDTO
     */
    'categoryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationDTO
     */
    'trainingJobId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationDTO
     */
    'modelVersion'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AnnotationItemResponseType = {
    Image: 'image',
    ImageCoco: 'image-coco',
    Text: 'text',
    AudioStt: 'audio_stt',
    TextTts: 'text_tts'
} as const;

export type AnnotationItemResponseType = typeof AnnotationItemResponseType[keyof typeof AnnotationItemResponseType];


/**
 * 
 * @export
 * @enum {string}
 */

export const AnnotationItemType = {
    Generic: 'generic',
    Image: 'image',
    Text: 'text',
    AudioStt: 'audio_stt',
    TextTts: 'text_tts',
    Mdi: 'mdi'
} as const;

export type AnnotationItemType = typeof AnnotationItemType[keyof typeof AnnotationItemType];


/**
 * 
 * @export
 * @interface AnnotationJobItem
 */
export interface AnnotationJobItem {
    /**
     * 
     * @type {number}
     * @memberof AnnotationJobItem
     */
    'modelId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationJobItem
     */
    'modelVersionId'?: number;
    /**
     * 
     * @type {Array<LabelPairItem>}
     * @memberof AnnotationJobItem
     */
    'labelPairs'?: Array<LabelPairItem>;
    /**
     * 
     * @type {string}
     * @memberof AnnotationJobItem
     */
    'storageResourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationJobItem
     */
    'format'?: AnnotationJobItemFormatEnum;
}

export const AnnotationJobItemFormatEnum = {
    Tts: 'TTS',
    Stt: 'STT',
    TextJson: 'TEXT_JSON',
    TextTsv: 'TEXT_TSV',
    Scaler: 'SCALER',
    Coco: 'COCO',
    Yolo: 'YOLO',
    Ocr: 'OCR',
    Mdi: 'MDI',
    Geojson: 'GEOJSON'
} as const;

export type AnnotationJobItemFormatEnum = typeof AnnotationJobItemFormatEnum[keyof typeof AnnotationJobItemFormatEnum];

/**
 * 
 * @export
 * @interface AnnotationJobRequest
 */
export interface AnnotationJobRequest {
    /**
     * 
     * @type {number}
     * @memberof AnnotationJobRequest
     */
    'batchId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationJobRequest
     */
    'inferenceEngineId'?: string;
    /**
     * 
     * @type {AnnotationJobItem}
     * @memberof AnnotationJobRequest
     */
    'item'?: AnnotationJobItem;
    /**
     * 
     * @type {string}
     * @memberof AnnotationJobRequest
     */
    'type'?: AnnotationJobRequestTypeEnum;
}

export const AnnotationJobRequestTypeEnum = {
    Generate: 'ANNOTATION_GENERATE',
    Import: 'ANNOTATION_IMPORT'
} as const;

export type AnnotationJobRequestTypeEnum = typeof AnnotationJobRequestTypeEnum[keyof typeof AnnotationJobRequestTypeEnum];

/**
 * 
 * @export
 * @interface AnnotationJobRequestDTO
 */
export interface AnnotationJobRequestDTO {
    /**
     * 
     * @type {Array<AnnotationRequestDTO>}
     * @memberof AnnotationJobRequestDTO
     */
    'annotations': Array<AnnotationRequestDTO>;
    /**
     * 
     * @type {Array<AnnotationRelationRequestDTO>}
     * @memberof AnnotationJobRequestDTO
     */
    'annotationRelations'?: Array<AnnotationRelationRequestDTO>;
}
/**
 * 
 * @export
 * @interface AnnotationJobResponse
 */
export interface AnnotationJobResponse {
    /**
     * 
     * @type {string}
     * @memberof AnnotationJobResponse
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationJobResponse
     */
    'jobId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationJobResponse
     */
    'status'?: AnnotationJobResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AnnotationJobResponse
     */
    'percentComplete'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationJobResponse
     */
    'createdDate'?: string;
}

export const AnnotationJobResponseStatusEnum = {
    Initial: 'INITIAL',
    Running: 'RUNNING',
    Succeeded: 'SUCCEEDED',
    Failed: 'FAILED',
    Canceled: 'CANCELED'
} as const;

export type AnnotationJobResponseStatusEnum = typeof AnnotationJobResponseStatusEnum[keyof typeof AnnotationJobResponseStatusEnum];

/**
 * 
 * @export
 * @interface AnnotationLabelerCriteria
 */
export interface AnnotationLabelerCriteria {
    /**
     * 
     * @type {number}
     * @memberof AnnotationLabelerCriteria
     */
    'batchId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLabelerCriteria
     */
    'batchId.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationLabelerCriteria
     */
    'mediaId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLabelerCriteria
     */
    'mediaId.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationLabelerCriteria
     */
    'jobId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLabelerCriteria
     */
    'jobId.in'?: string;
}
/**
 * 
 * @export
 * @interface AnnotationLabelerCriteriaV3
 */
export interface AnnotationLabelerCriteriaV3 {
    /**
     * 
     * @type {number}
     * @memberof AnnotationLabelerCriteriaV3
     */
    'batchId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLabelerCriteriaV3
     */
    'batchId.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationLabelerCriteriaV3
     */
    'mediaId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLabelerCriteriaV3
     */
    'mediaId.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationLabelerCriteriaV3
     */
    'jobId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLabelerCriteriaV3
     */
    'jobId.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLabelerCriteriaV3
     */
    'source.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLabelerCriteriaV3
     */
    'source.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLabelerCriteriaV3
     */
    'source.in'?: string;
}
/**
 * 
 * @export
 * @interface AnnotationLabelerReport
 */
export interface AnnotationLabelerReport {
    /**
     * 
     * @type {number}
     * @memberof AnnotationLabelerReport
     */
    'observationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationLabelerReport
     */
    'annotationType'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationLabelerReport
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface AnnotationRelationRequestDTO
 */
export interface AnnotationRelationRequestDTO {
    /**
     * 
     * @type {boolean}
     * @memberof AnnotationRelationRequestDTO
     */
    'directed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnotationRelationRequestDTO
     */
    'firstLocalId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationRelationRequestDTO
     */
    'secondLocalId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationRelationRequestDTO
     */
    'localGroup'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationRelationRequestDTO
     */
    'observationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationRelationRequestDTO
     */
    'observationAttributeId'?: number;
}
/**
 * 
 * @export
 * @interface AnnotationRelationResponseDTO
 */
export interface AnnotationRelationResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof AnnotationRelationResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnotationRelationResponseDTO
     */
    'directed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnotationRelationResponseDTO
     */
    'first'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationRelationResponseDTO
     */
    'second'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationRelationResponseDTO
     */
    'localGroup'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationRelationResponseDTO
     */
    'observationId'?: number;
}
/**
 * 
 * @export
 * @interface AnnotationRequest
 */
export interface AnnotationRequest {
    /**
     * 
     * @type {Array<RawAnnotationItemV2>}
     * @memberof AnnotationRequest
     */
    'annotations': Array<RawAnnotationItemV2>;
    /**
     * 
     * @type {number}
     * @memberof AnnotationRequest
     */
    'jobId'?: number;
}
/**
 * 
 * @export
 * @interface AnnotationRequestDTO
 */
export interface AnnotationRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof AnnotationRequestDTO
     */
    'localId'?: number;
    /**
     * 
     * @type {RawAnnotationItem}
     * @memberof AnnotationRequestDTO
     */
    'annotation'?: RawAnnotationItem;
    /**
     * 
     * @type {number}
     * @memberof AnnotationRequestDTO
     */
    'observationId'?: number;
}
/**
 * 
 * @export
 * @interface AnnotationResponseDTO
 */
export interface AnnotationResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof AnnotationResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {RawAnnotationItem}
     * @memberof AnnotationResponseDTO
     */
    'annotation'?: RawAnnotationItem;
    /**
     * 
     * @type {number}
     * @memberof AnnotationResponseDTO
     */
    'observationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationResponseDTO
     */
    'mediaId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationResponseDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationResponseDTO
     */
    'assignee'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationResponseDTO
     */
    'jobId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationResponseDTO
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationResponseDTO
     */
    'annotationType'?: string;
}
/**
 * 
 * @export
 * @interface AnnotationVoteItem
 */
export interface AnnotationVoteItem {
    /**
     * 
     * @type {number}
     * @memberof AnnotationVoteItem
     */
    'annotationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationVoteItem
     */
    'status'?: AnnotationVoteItemStatusEnum;
}

export const AnnotationVoteItemStatusEnum = {
    Up: 'UP',
    Down: 'DOWN'
} as const;

export type AnnotationVoteItemStatusEnum = typeof AnnotationVoteItemStatusEnum[keyof typeof AnnotationVoteItemStatusEnum];

/**
 * 
 * @export
 * @interface AnnotationVoteLabelerRequest
 */
export interface AnnotationVoteLabelerRequest {
    /**
     * 
     * @type {number}
     * @memberof AnnotationVoteLabelerRequest
     */
    'jobId'?: number;
    /**
     * 
     * @type {Array<AnnotationVoteItem>}
     * @memberof AnnotationVoteLabelerRequest
     */
    'annotationVotes'?: Array<AnnotationVoteItem>;
}
/**
 * 
 * @export
 * @interface AnnotationVoteResponse
 */
export interface AnnotationVoteResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AnnotationVoteResponse
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiRunMetric
 */
export interface ApiRunMetric {
    /**
     * 
     * @type {string}
     * @memberof ApiRunMetric
     */
    'format'?: ApiRunMetricFormatEnum;
    /**
     * Required. The user defined name of the metric. It must between 1 and 63 characters long and must conform to the following regular expression: `[a-z]([-a-z0-9]*[a-z0-9])?`.
     * @type {string}
     * @memberof ApiRunMetric
     */
    'name'?: string;
    /**
     * Required. The runtime node ID which reports the metric. The node ID can be found in the RunDetail.workflow.Status. Metric with same (node_id, name) are considerd as duplicate. Only the first reporting will be recorded. Max length is 128.
     * @type {string}
     * @memberof ApiRunMetric
     */
    'node_id'?: string;
    /**
     * The number value of the metric.
     * @type {number}
     * @memberof ApiRunMetric
     */
    'number_value'?: number;
}

export const ApiRunMetricFormatEnum = {
    Percentage: 'PERCENTAGE',
    Raw: 'RAW',
    Unspecified: 'UNSPECIFIED'
} as const;

export type ApiRunMetricFormatEnum = typeof ApiRunMetricFormatEnum[keyof typeof ApiRunMetricFormatEnum];

/**
 * 
 * @export
 * @interface AudioIssueData
 */
export interface AudioIssueData {
    /**
     * time in millisecond (ms)
     * @type {number}
     * @memberof AudioIssueData
     */
    'time'?: number;
}
/**
 * 
 * @export
 * @interface BBox
 */
export interface BBox {
    /**
     * 
     * @type {number}
     * @memberof BBox
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof BBox
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof BBox
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof BBox
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface BatchAnnotatingRequestDTO
 */
export interface BatchAnnotatingRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof BatchAnnotatingRequestDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchAnnotatingRequestDTO
     */
    'modelId'?: string;
    /**
     * 
     * @type {Array<BatchAnnotatingRequestDTOLabelMappingInner>}
     * @memberof BatchAnnotatingRequestDTO
     */
    'labelMapping'?: Array<BatchAnnotatingRequestDTOLabelMappingInner>;
}
/**
 * 
 * @export
 * @interface BatchAnnotatingRequestDTOLabelMappingInner
 */
export interface BatchAnnotatingRequestDTOLabelMappingInner {
    /**
     * 
     * @type {string}
     * @memberof BatchAnnotatingRequestDTOLabelMappingInner
     */
    'name'?: string;
    /**
     * 
     * @type {BatchAnnotatingRequestDTOLabelMappingInnerLabel}
     * @memberof BatchAnnotatingRequestDTOLabelMappingInner
     */
    'label'?: BatchAnnotatingRequestDTOLabelMappingInnerLabel;
}
/**
 * 
 * @export
 * @interface BatchAnnotatingRequestDTOLabelMappingInnerLabel
 */
export interface BatchAnnotatingRequestDTOLabelMappingInnerLabel {
    /**
     * 
     * @type {string}
     * @memberof BatchAnnotatingRequestDTOLabelMappingInnerLabel
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchAnnotatingRequestDTOLabelMappingInnerLabel
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface BatchAnnotatingResponseDTO
 */
export interface BatchAnnotatingResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof BatchAnnotatingResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchAnnotatingResponseDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchAnnotatingResponseDTO
     */
    'modelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchAnnotatingResponseDTO
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface BatchCriteria
 */
export interface BatchCriteria {
    /**
     * 
     * @type {number}
     * @memberof BatchCriteria
     */
    'id.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchCriteria
     */
    'id.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchCriteria
     */
    'projectId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchCriteria
     */
    'projectId.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchCriteria
     */
    'createdDate.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchCriteria
     */
    'createdDate.greaterThan'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchCriteria
     */
    'createdDate.lessThan'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchCriteria
     */
    'name.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchCriteria
     */
    'name.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchCriteria
     */
    'name.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchCriteria
     */
    'status.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchCriteria
     */
    'status.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchCriteria
     */
    'status.in'?: string;
}
/**
 * 
 * @export
 * @interface BatchData
 */
export interface BatchData {
    /**
     * 
     * @type {string}
     * @memberof BatchData
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchData
     */
    'type'?: BatchDataTypeEnum;
    /**
     * 
     * @type {BatchSetting}
     * @memberof BatchData
     */
    'batchSetting'?: BatchSetting;
    /**
     * 
     * @type {string}
     * @memberof BatchData
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchData
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchData
     */
    'dueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchData
     */
    'instruction'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchData
     */
    'embeddedContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchData
     */
    'embeddedType'?: BatchDataEmbeddedTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BatchData
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchData
     */
    'priority'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchData
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchData
     */
    'preAssignedTask'?: number;
}

export const BatchDataTypeEnum = {
    Normal: 'NORMAL',
    Evaluation: 'EVALUATION'
} as const;

export type BatchDataTypeEnum = typeof BatchDataTypeEnum[keyof typeof BatchDataTypeEnum];
export const BatchDataEmbeddedTypeEnum = {
    Html: 'HTML',
    Pdf: 'PDF'
} as const;

export type BatchDataEmbeddedTypeEnum = typeof BatchDataEmbeddedTypeEnum[keyof typeof BatchDataEmbeddedTypeEnum];

/**
 * 
 * @export
 * @interface BatchEssentialResponseDTO
 */
export interface BatchEssentialResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof BatchEssentialResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchEssentialResponseDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchEssentialResponseDTO
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchEssentialResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchEssentialResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchEssentialResponseDTO
     */
    'lastModifiedDate'?: string;
}
/**
 * 
 * @export
 * @interface BatchInfo
 */
export interface BatchInfo {
    /**
     * 
     * @type {BatchResponseDTO}
     * @memberof BatchInfo
     */
    'batch'?: BatchResponseDTO;
    /**
     * 
     * @type {Array<BatchObservationDTO>}
     * @memberof BatchInfo
     */
    'batchObservation'?: Array<BatchObservationDTO>;
    /**
     * 
     * @type {Array<WorkManagementDTO>}
     * @memberof BatchInfo
     */
    'workManagement'?: Array<WorkManagementDTO>;
    /**
     * 
     * @type {WorkflowBatchDTO}
     * @memberof BatchInfo
     */
    'workflowBatch'?: WorkflowBatchDTO;
    /**
     * 
     * @type {PricingResponseDTO}
     * @memberof BatchInfo
     */
    'pricing'?: PricingResponseDTO;
}
/**
 * 
 * @export
 * @interface BatchInfoRequest
 */
export interface BatchInfoRequest {
    /**
     * 
     * @type {boolean}
     * @memberof BatchInfoRequest
     */
    'autoCreateTask'?: boolean;
    /**
     * 
     * @type {BatchData}
     * @memberof BatchInfoRequest
     */
    'batch'?: BatchData;
    /**
     * 
     * @type {Array<BatchObservationData>}
     * @memberof BatchInfoRequest
     */
    'batchObservation'?: Array<BatchObservationData>;
    /**
     * 
     * @type {DatasetCriteria}
     * @memberof BatchInfoRequest
     */
    'datasetCriteria'?: DatasetCriteria;
    /**
     * 
     * @type {Array<WorkManagementData>}
     * @memberof BatchInfoRequest
     */
    'workManagement'?: Array<WorkManagementData>;
    /**
     * 
     * @type {number}
     * @memberof BatchInfoRequest
     */
    'workflowId'?: number;
    /**
     * 
     * @type {PricingRequestDTO}
     * @memberof BatchInfoRequest
     */
    'pricing'?: PricingRequestDTO;
}
/**
 * 
 * @export
 * @interface BatchLabelerStatistic
 */
export interface BatchLabelerStatistic {
    /**
     * 
     * @type {string}
     * @memberof BatchLabelerStatistic
     */
    'email'?: string;
    /**
     * 
     * @type {F1Detail}
     * @memberof BatchLabelerStatistic
     */
    'f1Detail'?: F1Detail;
    /**
     * 
     * @type {Summary}
     * @memberof BatchLabelerStatistic
     */
    'summary'?: Summary;
}
/**
 * 
 * @export
 * @interface BatchObservationDTO
 */
export interface BatchObservationDTO {
    /**
     * 
     * @type {number}
     * @memberof BatchObservationDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchObservationDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchObservationDTO
     */
    'iou'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchObservationDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {ObservationDTO}
     * @memberof BatchObservationDTO
     */
    'observation'?: ObservationDTO;
    /**
     * 
     * @type {number}
     * @memberof BatchObservationDTO
     */
    'priority'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchObservationDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchObservationDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BatchObservationDTO
     */
    'attributeAgreement'?: Array<number>;
}
/**
 * 
 * @export
 * @interface BatchObservationData
 */
export interface BatchObservationData {
    /**
     * 
     * @type {number}
     * @memberof BatchObservationData
     */
    'iou'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BatchObservationData
     */
    'labelRequired'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BatchObservationData
     */
    'observationId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof BatchObservationData
     */
    'attributeAgreement'?: Array<number>;
}
/**
 * 
 * @export
 * @interface BatchProgress
 */
export interface BatchProgress {
    /**
     * 
     * @type {string}
     * @memberof BatchProgress
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchProgress
     */
    'startDate'?: string;
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof BatchProgress
     */
    'taskStatistic'?: { [key: string]: number | undefined; };
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof BatchProgress
     */
    'reviewStatistic'?: { [key: string]: number | undefined; };
    /**
     * 
     * @type {number}
     * @memberof BatchProgress
     */
    'totalLabeler'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchProgress
     */
    'totalTask'?: number;
    /**
     * 
     * @type {Array<UserStatistic>}
     * @memberof BatchProgress
     */
    'userStatistic'?: Array<UserStatistic>;
}
/**
 * 
 * @export
 * @interface BatchRequestDTO
 */
export interface BatchRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof BatchRequestDTO
     */
    'code'?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchRequestDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchRequestDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchRequestDTO
     */
    'dueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchRequestDTO
     */
    'instruction'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchRequestDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchRequestDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchRequestDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchRequestDTO
     */
    'priority'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchRequestDTO
     */
    'embeddedContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchRequestDTO
     */
    'embeddedType'?: string;
}
/**
 * 
 * @export
 * @interface BatchResponseDTO
 */
export interface BatchResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTO
     */
    'code'?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchResponseDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTO
     */
    'dueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTO
     */
    'instruction'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchResponseDTO
     */
    'priority'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTO
     */
    'embeddedContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTO
     */
    'embeddedType'?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchResponseDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {ProjectResponseDTO}
     * @memberof BatchResponseDTO
     */
    'project'?: ProjectResponseDTO;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTO
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTO
     */
    'startDate'?: string;
    /**
     * 
     * @type {BatchSetting}
     * @memberof BatchResponseDTO
     */
    'batchSetting'?: BatchSetting;
}
/**
 * 
 * @export
 * @interface BatchResponseDTOAllOf
 */
export interface BatchResponseDTOAllOf {
    /**
     * 
     * @type {number}
     * @memberof BatchResponseDTOAllOf
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchResponseDTOAllOf
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTOAllOf
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {ProjectResponseDTO}
     * @memberof BatchResponseDTOAllOf
     */
    'project'?: ProjectResponseDTO;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTOAllOf
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTOAllOf
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTOAllOf
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchResponseDTOAllOf
     */
    'startDate'?: string;
    /**
     * 
     * @type {BatchSetting}
     * @memberof BatchResponseDTOAllOf
     */
    'batchSetting'?: BatchSetting;
}
/**
 * 
 * @export
 * @interface BatchSetting
 */
export interface BatchSetting {
    /**
     * 
     * @type {number}
     * @memberof BatchSetting
     */
    'activityInterval'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchSetting
     */
    'timeLimitSecond'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchSetting
     */
    'consensusRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchSetting
     */
    'consensusEntity'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchSetting
     */
    'consensusRelationship'?: number;
    /**
     * 
     * @type {ActiveLearningConfig}
     * @memberof BatchSetting
     */
    'activeLearning'?: ActiveLearningConfig;
    /**
     * 
     * @type {boolean}
     * @memberof BatchSetting
     */
    'balanceJobAssign'?: boolean;
}
/**
 * 
 * @export
 * @interface CloudDTO
 */
export interface CloudDTO {
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'cloudType'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CloudDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CloudDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'lastModifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'sasUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'status'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CloudDTO
     */
    'tagIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CloudDTO
     */
    'totalFile'?: number;
    /**
     * 
     * @type {number}
     * @memberof CloudDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface CodeValuePair
 */
export interface CodeValuePair {
    /**
     * 
     * @type {string}
     * @memberof CodeValuePair
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeValuePair
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface Constants
 */
export interface Constants {
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'projectType'?: ConstantsProjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'projectSubType'?: ConstantsProjectSubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'exportType'?: ConstantsExportTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'projectStatus'?: ConstantsProjectStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'jobStatus'?: ConstantsJobStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'taskStatus'?: ConstantsTaskStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'taskReviewStatus'?: ConstantsTaskReviewStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'taskIssueStatus'?: ConstantsTaskIssueStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'batchStatus'?: ConstantsBatchStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'datasetType'?: ConstantsDatasetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'exportDatasetVersionStatus'?: ConstantsExportDatasetVersionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'projectImageSubType'?: ConstantsProjectImageSubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'projectTextSubType'?: ConstantsProjectTextSubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'projectAudioSubType'?: ConstantsProjectAudioSubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'stepType'?: ConstantsStepTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Constants
     */
    'reviewPickType'?: ConstantsReviewPickTypeEnum;
}

export const ConstantsProjectTypeEnum = {
    TEXT: 'Text',
    MEDICAL_IMAGE: 'Medical_Image',
    IMAGE: 'Image',
    VIDEO: 'Video',
    AUDIO: 'Audio',
    WSI: 'Wsi'
} as const;

export type ConstantsProjectTypeEnum = typeof ConstantsProjectTypeEnum[keyof typeof ConstantsProjectTypeEnum];
export const ConstantsProjectSubTypeEnum = {
    IMAGE_CLASSIFICATION: 'Image Classification',
    IMAGE_DETECTION: 'Object Detection',
    IMAGE_SEGMENTATION: 'Image Segmentation',
    IMAGE_OCR: 'Image Ocr',
    MED_XRAY_DETECTION: 'Xray Detection',
    MED_XRAY_SEGMENTATION: 'Xray Segmentation',
    MED_XRAY_CLASSIFICATION: 'Xray Classification',
    TEXT_NER_RE: 'Text Named Entity Recognition and Relation',
    TEXT_NER: 'Text Named Entity Recognition',
    TEXT_COR: 'Text Co-reference',
    TEXT_TTS: 'Text to speech',
    AUDIO_STT: 'Speech to Text',
    MED_MDI_SEGMENTATION: 'Multi-Dimensional Image Segmentation',
    MED_MAM_SEGMENTATION: 'Mammography Segmentation',
    VIDEO_CLASSIFICATION: 'Video Classification',
    VIDEO_FRAME_OBJECT_DETECTION: 'Video Frame Object Detection',
    VIDEO_FRAME_OBJECT_TRACKING: 'Video Frame Object Tracking',
    WSI_DETECTION: 'Whole Slide Image Object Detection',
    WSI_SEGMENTATION: 'Whole Slide Image Segmentation'
} as const;

export type ConstantsProjectSubTypeEnum = typeof ConstantsProjectSubTypeEnum[keyof typeof ConstantsProjectSubTypeEnum];
export const ConstantsExportTypeEnum = {
    Yolo: 'yolo',
    Coco: 'coco',
    Scaler: 'scaler',
    Ocr: 'ocr',
    T2s: 't2s',
    S2t: 's2t',
    Textcsv: 'textcsv',
    Textjson: 'textjson',
    Mdi: 'mdi'
} as const;

export type ConstantsExportTypeEnum = typeof ConstantsExportTypeEnum[keyof typeof ConstantsExportTypeEnum];
export const ConstantsProjectStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type ConstantsProjectStatusEnum = typeof ConstantsProjectStatusEnum[keyof typeof ConstantsProjectStatusEnum];
export const ConstantsJobStatusEnum = {
    Initial: 'initial',
    Waiting: 'waiting',
    Available: 'available',
    Working: 'working',
    Completed: 'completed',
    Skipped: 'skipped',
    Removed: 'removed',
    Saving: 'saving'
} as const;

export type ConstantsJobStatusEnum = typeof ConstantsJobStatusEnum[keyof typeof ConstantsJobStatusEnum];
export const ConstantsTaskStatusEnum = {
    Initial: 'initial',
    Creating: 'creating',
    OnHold: 'on-hold',
    Working: 'working',
    Completed: 'completed',
    Skipped: 'skipped'
} as const;

export type ConstantsTaskStatusEnum = typeof ConstantsTaskStatusEnum[keyof typeof ConstantsTaskStatusEnum];
export const ConstantsTaskReviewStatusEnum = {
    None: 'none',
    Accepted: 'accepted',
    Rejected: 'rejected',
    Approved: 'approved',
    Reopened: 'reopened',
    Resolved: 'resolved'
} as const;

export type ConstantsTaskReviewStatusEnum = typeof ConstantsTaskReviewStatusEnum[keyof typeof ConstantsTaskReviewStatusEnum];
export const ConstantsTaskIssueStatusEnum = {
    None: 'none',
    Opened: 'opened',
    Fixed: 'fixed',
    Resolved: 'resolved'
} as const;

export type ConstantsTaskIssueStatusEnum = typeof ConstantsTaskIssueStatusEnum[keyof typeof ConstantsTaskIssueStatusEnum];
export const ConstantsBatchStatusEnum = {
    Initial: 'initial',
    Creating: 'creating',
    Failed: 'failed',
    Working: 'working',
    Completed: 'completed',
    OnHold: 'on-hold',
    Delivered: 'delivered',
    Deleted: 'deleted',
    InReview: 'in-review',
    Accepted: 'accepted',
    Rejected: 'rejected',
    Approved: 'approved'
} as const;

export type ConstantsBatchStatusEnum = typeof ConstantsBatchStatusEnum[keyof typeof ConstantsBatchStatusEnum];
export const ConstantsDatasetTypeEnum = {
    Normal: 'normal',
    Batch: 'batch',
    Exported: 'exported'
} as const;

export type ConstantsDatasetTypeEnum = typeof ConstantsDatasetTypeEnum[keyof typeof ConstantsDatasetTypeEnum];
export const ConstantsExportDatasetVersionStatusEnum = {
    Exporting: 'exporting',
    Exported: 'exported',
    Failed: 'failed'
} as const;

export type ConstantsExportDatasetVersionStatusEnum = typeof ConstantsExportDatasetVersionStatusEnum[keyof typeof ConstantsExportDatasetVersionStatusEnum];
export const ConstantsProjectImageSubTypeEnum = {
    Segmentation: 'image_segmentation',
    Classification: 'image_classification'
} as const;

export type ConstantsProjectImageSubTypeEnum = typeof ConstantsProjectImageSubTypeEnum[keyof typeof ConstantsProjectImageSubTypeEnum];
export const ConstantsProjectTextSubTypeEnum = {
    Cor: 'cor',
    Ner: 'ner'
} as const;

export type ConstantsProjectTextSubTypeEnum = typeof ConstantsProjectTextSubTypeEnum[keyof typeof ConstantsProjectTextSubTypeEnum];
export const ConstantsProjectAudioSubTypeEnum = {
    Speech2text: 'speech2text',
    Text2speech: 'text2speech'
} as const;

export type ConstantsProjectAudioSubTypeEnum = typeof ConstantsProjectAudioSubTypeEnum[keyof typeof ConstantsProjectAudioSubTypeEnum];
export const ConstantsStepTypeEnum = {
    Normal: 'NORMAL',
    Review: 'REVIEW',
    Acceptance: 'ACCEPTANCE'
} as const;

export type ConstantsStepTypeEnum = typeof ConstantsStepTypeEnum[keyof typeof ConstantsStepTypeEnum];
export const ConstantsReviewPickTypeEnum = {
    All: 'ALL',
    Labeler: 'LABELER'
} as const;

export type ConstantsReviewPickTypeEnum = typeof ConstantsReviewPickTypeEnum[keyof typeof ConstantsReviewPickTypeEnum];

/**
 * 
 * @export
 * @interface CurrencyResponseDTO
 */
export interface CurrencyResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof CurrencyResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponseDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponseDTO
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponseDTO
     */
    'symbol'?: string;
}
/**
 * 
 * @export
 * @interface DatasetAttributeAdditionalDTO
 */
export interface DatasetAttributeAdditionalDTO {
    /**
     * 
     * @type {{ [key: string]: InfoSentence | undefined; }}
     * @memberof DatasetAttributeAdditionalDTO
     */
    'infoSentenceMap'?: { [key: string]: InfoSentence | undefined; };
    /**
     * 
     * @type {number}
     * @memberof DatasetAttributeAdditionalDTO
     */
    'numSentence'?: number;
}
/**
 * 
 * @export
 * @interface DatasetAttributeDTO
 */
export interface DatasetAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTO
     */
    'attribute'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof DatasetAttributeDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DatasetAttributeDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTO
     */
    'lastModifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTO
     */
    'type'?: DatasetAttributeDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTO
     */
    'value'?: string;
    /**
     * 
     * @type {number}
     * @memberof DatasetAttributeDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTO
     */
    'workspaceId'?: string;
}

export const DatasetAttributeDTOTypeEnum = {
    Double: 'DOUBLE',
    Long: 'LONG',
    Text: 'TEXT'
} as const;

export type DatasetAttributeDTOTypeEnum = typeof DatasetAttributeDTOTypeEnum[keyof typeof DatasetAttributeDTOTypeEnum];

/**
 * 
 * @export
 * @interface DatasetCriteria
 */
export interface DatasetCriteria {
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'action'?: DatasetCriteriaActionEnum;
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'createdBy'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DatasetCriteria
     */
    'datasetIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof DatasetCriteria
     */
    'fromTimeMs'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DatasetCriteria
     */
    'mediaIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DatasetCriteria
     */
    'mediaIgnoreIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DatasetCriteria
     */
    'scopeId'?: number;
    /**
     * 
     * @type {Array<MetadataDTO>}
     * @memberof DatasetCriteria
     */
    'metadata'?: Array<MetadataDTO>;
    /**
     * 
     * @type {number}
     * @memberof DatasetCriteria
     */
    'toTimeMs'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'type'?: DatasetCriteriaTypeEnum;
}

export const DatasetCriteriaActionEnum = {
    Add: 'ADD',
    Delete: 'DELETE',
    Update: 'UPDATE'
} as const;

export type DatasetCriteriaActionEnum = typeof DatasetCriteriaActionEnum[keyof typeof DatasetCriteriaActionEnum];
export const DatasetCriteriaTypeEnum = {
    Hard: 'HARD',
    Soft: 'SOFT'
} as const;

export type DatasetCriteriaTypeEnum = typeof DatasetCriteriaTypeEnum[keyof typeof DatasetCriteriaTypeEnum];

/**
 * 
 * @export
 * @interface DatasetDTO
 */
export interface DatasetDTO {
    /**
     * 
     * @type {Array<DatasetAttributeDTO>}
     * @memberof DatasetDTO
     */
    'attributes'?: Array<DatasetAttributeDTO>;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof DatasetDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'lastModifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'name'?: string;
    /**
     * 
     * @type {FileInfoDTO}
     * @memberof DatasetDTO
     */
    'representative'?: FileInfoDTO;
    /**
     * 
     * @type {number}
     * @memberof DatasetDTO
     */
    'totalFile'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DatasetDTO
     */
    'version'?: number;
    /**
     * 
     * @type {Array<DatasetVersionDTO>}
     * @memberof DatasetDTO
     */
    'versions'?: Array<DatasetVersionDTO>;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface DatasetVersionDTO
 */
export interface DatasetVersionDTO {
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'databricksDatasetVersion'?: string;
    /**
     * 
     * @type {number}
     * @memberof DatasetVersionDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'datasetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'datasetVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof DatasetVersionDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'lastModifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof DatasetVersionDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface DicomMetaDataDTO
 */
export interface DicomMetaDataDTO {
    /**
     * 
     * @type {number}
     * @memberof DicomMetaDataDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DicomMetaDataDTO
     */
    'instanceId': string;
    /**
     * 
     * @type {string}
     * @memberof DicomMetaDataDTO
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof DicomMetaDataDTO
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof DicomMetaDataDTO
     */
    'retrieveId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DicomMetaDataDTO
     */
    'seriesId': string;
    /**
     * 
     * @type {string}
     * @memberof DicomMetaDataDTO
     */
    'studyId': string;
}
/**
 * 
 * @export
 * @interface Distribution
 */
export interface Distribution {
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof Distribution
     */
    'distributions'?: { [key: string]: number | undefined; };
}
/**
 * 
 * @export
 * @interface DownloadDatasetRequestDTO
 */
export interface DownloadDatasetRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof DownloadDatasetRequestDTO
     */
    'jobDownloadType'?: DownloadDatasetRequestDTOJobDownloadTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof DownloadDatasetRequestDTO
     */
    'taskId'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DownloadDatasetRequestDTO
     */
    'annotationId'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DownloadDatasetRequestDTO
     */
    'assignee'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DownloadDatasetRequestDTO
     */
    'source'?: Array<DownloadDatasetRequestDTOSourceEnum>;
    /**
     * 
     * @type {string}
     * @memberof DownloadDatasetRequestDTO
     */
    'format'?: DownloadDatasetRequestDTOFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof DownloadDatasetRequestDTO
     */
    'fileName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadDatasetRequestDTO
     */
    'saveImage'?: boolean;
}

export const DownloadDatasetRequestDTOJobDownloadTypeEnum = {
    Agreed: 'AGREED',
    Completed: 'COMPLETED',
    Vote: 'VOTE'
} as const;

export type DownloadDatasetRequestDTOJobDownloadTypeEnum = typeof DownloadDatasetRequestDTOJobDownloadTypeEnum[keyof typeof DownloadDatasetRequestDTOJobDownloadTypeEnum];
export const DownloadDatasetRequestDTOSourceEnum = {
    Client: 'Client',
    Import: 'Import',
    Model: 'Model'
} as const;

export type DownloadDatasetRequestDTOSourceEnum = typeof DownloadDatasetRequestDTOSourceEnum[keyof typeof DownloadDatasetRequestDTOSourceEnum];
export const DownloadDatasetRequestDTOFormatEnum = {
    Yolo: 'YOLO',
    Coco: 'COCO',
    Scaler: 'SCALER',
    Ocr: 'OCR',
    TextJson: 'TEXT_JSON',
    TextTsv: 'TEXT_TSV',
    Tts: 'TTS',
    Stt: 'STT',
    Mdi: 'MDI',
    Geojson: 'GEOJSON',
    MdiCsvReport: 'MDI_CSV_REPORT',
    GeneralCsvReport: 'GENERAL_CSV_REPORT',
    BatchStatisticReport: 'BATCH_STATISTIC_REPORT'
} as const;

export type DownloadDatasetRequestDTOFormatEnum = typeof DownloadDatasetRequestDTOFormatEnum[keyof typeof DownloadDatasetRequestDTOFormatEnum];

/**
 * 
 * @export
 * @interface DownloadDatasetResponse
 */
export interface DownloadDatasetResponse {
    /**
     * 
     * @type {number}
     * @memberof DownloadDatasetResponse
     */
    'job_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DownloadDatasetResponse
     */
    'status'?: string;
    /**
     * 
     * @type {DownloadDatasetRequestDTO}
     * @memberof DownloadDatasetResponse
     */
    'inputs'?: DownloadDatasetRequestDTO;
}
/**
 * 
 * @export
 * @interface ExportRequest
 */
export interface ExportRequest {
    /**
     * 
     * @type {string}
     * @memberof ExportRequest
     */
    'action'?: ExportRequestActionEnum;
    /**
     * 
     * @type {string}
     * @memberof ExportRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExportRequest
     */
    'sourceDatasetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportRequest
     */
    'targetDatasetVersionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportRequest
     */
    'exportType'?: ExportRequestExportTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExportRequest
     */
    'format'?: ExportRequestFormatEnum;
}

export const ExportRequestActionEnum = {
    Merge: 'MERGE',
    Overwrite: 'OVERWRITE'
} as const;

export type ExportRequestActionEnum = typeof ExportRequestActionEnum[keyof typeof ExportRequestActionEnum];
export const ExportRequestExportTypeEnum = {
    Agreed: 'AGREED',
    Completed: 'COMPLETED',
    Vote: 'VOTE'
} as const;

export type ExportRequestExportTypeEnum = typeof ExportRequestExportTypeEnum[keyof typeof ExportRequestExportTypeEnum];
export const ExportRequestFormatEnum = {
    Coco: 'COCO'
} as const;

export type ExportRequestFormatEnum = typeof ExportRequestFormatEnum[keyof typeof ExportRequestFormatEnum];

/**
 * 
 * @export
 * @interface ExportType
 */
export interface ExportType {
    /**
     * 
     * @type {string}
     * @memberof ExportType
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportType
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface F1
 */
export interface F1 {
    /**
     * 
     * @type {string}
     * @memberof F1
     */
    'assignee'?: string;
    /**
     * 
     * @type {number}
     * @memberof F1
     */
    'f1'?: number;
    /**
     * 
     * @type {number}
     * @memberof F1
     */
    'fn'?: number;
    /**
     * 
     * @type {number}
     * @memberof F1
     */
    'fp'?: number;
    /**
     * 
     * @type {string}
     * @memberof F1
     */
    'observation'?: string;
    /**
     * 
     * @type {number}
     * @memberof F1
     */
    'precision'?: number;
    /**
     * 
     * @type {number}
     * @memberof F1
     */
    'sensitivity'?: number;
    /**
     * 
     * @type {number}
     * @memberof F1
     */
    'specificity'?: number;
    /**
     * 
     * @type {number}
     * @memberof F1
     */
    'tn'?: number;
    /**
     * 
     * @type {number}
     * @memberof F1
     */
    'tp'?: number;
}
/**
 * 
 * @export
 * @interface F1Detail
 */
export interface F1Detail {
    /**
     * 
     * @type {Array<F1>}
     * @memberof F1Detail
     */
    'data'?: Array<F1>;
}
/**
 * 
 * @export
 * @interface FileInfoDTO
 */
export interface FileInfoDTO {
    /**
     * 
     * @type {CloudDTO}
     * @memberof FileInfoDTO
     */
    'cloud'?: CloudDTO;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {DicomMetaDataDTO}
     * @memberof FileInfoDTO
     */
    'dicomMetaData'?: DicomMetaDataDTO;
    /**
     * 
     * @type {FileInfoDTO}
     * @memberof FileInfoDTO
     */
    'duplicatedRef'?: FileInfoDTO;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'hash'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'lastModifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTO
     */
    'scopeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'storeType'?: string;
    /**
     * 
     * @type {Array<TagDTO>}
     * @memberof FileInfoDTO
     */
    'tags'?: Array<TagDTO>;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GenerateAnnotationJobItem
 */
export interface GenerateAnnotationJobItem {
    /**
     * 
     * @type {number}
     * @memberof GenerateAnnotationJobItem
     */
    'modelId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateAnnotationJobItem
     */
    'modelVersionId'?: number;
    /**
     * 
     * @type {Array<LabelPairItem>}
     * @memberof GenerateAnnotationJobItem
     */
    'labelPairs'?: Array<LabelPairItem>;
}
/**
 * 
 * @export
 * @interface GenerateAnnotationRequest
 */
export interface GenerateAnnotationRequest {
    /**
     * 
     * @type {number}
     * @memberof GenerateAnnotationRequest
     */
    'batchId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateAnnotationRequest
     */
    'modelId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateAnnotationRequest
     */
    'modelVersionId'?: number;
    /**
     * 
     * @type {Array<LabelPairItem>}
     * @memberof GenerateAnnotationRequest
     */
    'labelPairs'?: Array<LabelPairItem>;
}
/**
 * 
 * @export
 * @interface GenerateAnnotationResponse
 */
export interface GenerateAnnotationResponse {
    /**
     * 
     * @type {number}
     * @memberof GenerateAnnotationResponse
     */
    'jobId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GenerateAnnotationResponse
     */
    'status'?: GenerateAnnotationResponseStatusEnum;
}

export const GenerateAnnotationResponseStatusEnum = {
    Initial: 'INITIAL',
    Running: 'RUNNING',
    Succeeded: 'SUCCEEDED',
    Failed: 'FAILED',
    Canceled: 'CANCELED'
} as const;

export type GenerateAnnotationResponseStatusEnum = typeof GenerateAnnotationResponseStatusEnum[keyof typeof GenerateAnnotationResponseStatusEnum];

/**
 * 
 * @export
 * @interface HealthGetResponse
 */
export interface HealthGetResponse {
    /**
     * 
     * @type {string}
     * @memberof HealthGetResponse
     */
    'status'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HealthGetResponse
     */
    'groups'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Ids
 */
export interface Ids {
    /**
     * 
     * @type {Array<number>}
     * @memberof Ids
     */
    'ids'?: Array<number>;
}
/**
 * 
 * @export
 * @interface IllustrationFile
 */
export interface IllustrationFile {
    /**
     * 
     * @type {boolean}
     * @memberof IllustrationFile
     */
    'activated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IllustrationFile
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof IllustrationFile
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IllustrationFile
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof IllustrationFile
     */
    'observationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof IllustrationFile
     */
    'projectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IllustrationFile
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof IllustrationFile
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof IllustrationFile
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface IllustrationItem
 */
export interface IllustrationItem {
    /**
     * 
     * @type {string}
     * @memberof IllustrationItem
     */
    'resourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IllustrationItem
     */
    'active'?: string;
    /**
     * 
     * @type {string}
     * @memberof IllustrationItem
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof IllustrationItem
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface ImageIssueData
 */
export interface ImageIssueData {
    /**
     * 
     * @type {number}
     * @memberof ImageIssueData
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageIssueData
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageIssueData
     */
    'frameId'?: number;
}
/**
 * 
 * @export
 * @interface ImportAnnotationJobItem
 */
export interface ImportAnnotationJobItem {
    /**
     * 
     * @type {string}
     * @memberof ImportAnnotationJobItem
     */
    'storageResourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportAnnotationJobItem
     */
    'format'?: ImportAnnotationJobItemFormatEnum;
}

export const ImportAnnotationJobItemFormatEnum = {
    Tts: 'TTS',
    Stt: 'STT',
    TextJson: 'TEXT_JSON',
    TextTsv: 'TEXT_TSV',
    Scaler: 'SCALER',
    Coco: 'COCO',
    Yolo: 'YOLO',
    Ocr: 'OCR',
    Mdi: 'MDI',
    Geojson: 'GEOJSON'
} as const;

export type ImportAnnotationJobItemFormatEnum = typeof ImportAnnotationJobItemFormatEnum[keyof typeof ImportAnnotationJobItemFormatEnum];

/**
 * 
 * @export
 * @interface ImportRequestDTO
 */
export interface ImportRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof ImportRequestDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportRequestDTO
     */
    'batchName'?: string;
}
/**
 * 
 * @export
 * @interface InfoImportResponse
 */
export interface InfoImportResponse {
    /**
     * 
     * @type {AnnotationDTO}
     * @memberof InfoImportResponse
     */
    'annotation'?: AnnotationDTO;
    /**
     * 
     * @type {DatasetAttributeAdditionalDTO}
     * @memberof InfoImportResponse
     */
    'datasetAttributeAdditional'?: DatasetAttributeAdditionalDTO;
    /**
     * 
     * @type {FileInfoDTO}
     * @memberof InfoImportResponse
     */
    'fileInfo'?: FileInfoDTO;
}
/**
 * 
 * @export
 * @interface InfoSentence
 */
export interface InfoSentence {
    /**
     * 
     * @type {number}
     * @memberof InfoSentence
     */
    'endIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof InfoSentence
     */
    'sentence'?: string;
    /**
     * 
     * @type {number}
     * @memberof InfoSentence
     */
    'sentenceIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof InfoSentence
     */
    'startIndex'?: number;
}
/**
 * 
 * @export
 * @interface IssueCommentRequestDTO
 */
export interface IssueCommentRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof IssueCommentRequestDTO
     */
    'issueId'?: number;
    /**
     * To mention a people by email, use this template {{user@email.com}} 
     * @type {string}
     * @memberof IssueCommentRequestDTO
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface IssueCommentResponseDTO
 */
export interface IssueCommentResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof IssueCommentResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof IssueCommentResponseDTO
     */
    'issueId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IssueCommentResponseDTO
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueCommentResponseDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueCommentResponseDTO
     */
    'createdDate'?: string;
}
/**
 * 
 * @export
 * @interface IssueCustomerRequestDTO
 */
export interface IssueCustomerRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof IssueCustomerRequestDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof IssueCustomerRequestDTO
     */
    'jobId'?: number;
    /**
     * 
     * @type {IssueData}
     * @memberof IssueCustomerRequestDTO
     */
    'data'?: IssueData;
    /**
     * id of observation that this issue refer to (if needed)
     * @type {number}
     * @memberof IssueCustomerRequestDTO
     */
    'referenceId'?: number;
}
/**
 * 
 * @export
 * @interface IssueCustomerResponseDTO
 */
export interface IssueCustomerResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof IssueCustomerResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IssueCustomerResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueCustomerResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof IssueCustomerResponseDTO
     */
    'taskId'?: number;
    /**
     * 
     * @type {number}
     * @memberof IssueCustomerResponseDTO
     */
    'jobId'?: number;
    /**
     * 
     * @type {IssueData}
     * @memberof IssueCustomerResponseDTO
     */
    'data'?: IssueData;
    /**
     * 
     * @type {Array<IssueCommentResponseDTO>}
     * @memberof IssueCustomerResponseDTO
     */
    'comments'?: Array<IssueCommentResponseDTO>;
    /**
     * 
     * @type {string}
     * @memberof IssueCustomerResponseDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueCustomerResponseDTO
     */
    'createdBy'?: string;
}
/**
 * 
 * @export
 * @interface IssueData
 */
export interface IssueData {
    /**
     * 
     * @type {object}
     * @memberof IssueData
     */
    'metadata'?: object;
    /**
     * 
     * @type {number}
     * @memberof IssueData
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof IssueData
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof IssueData
     */
    'frameId'?: number;
    /**
     * time in millisecond (ms)
     * @type {number}
     * @memberof IssueData
     */
    'time'?: number;
    /**
     * start index value
     * @type {number}
     * @memberof IssueData
     */
    'startIndex'?: number;
    /**
     * end index value
     * @type {number}
     * @memberof IssueData
     */
    'endIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof IssueData
     */
    'annotationId'?: number;
}
/**
 * 
 * @export
 * @interface IssueLabelerResponseDTO
 */
export interface IssueLabelerResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof IssueLabelerResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IssueLabelerResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueLabelerResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof IssueLabelerResponseDTO
     */
    'taskId'?: number;
    /**
     * 
     * @type {number}
     * @memberof IssueLabelerResponseDTO
     */
    'jobId'?: number;
    /**
     * 
     * @type {IssueData}
     * @memberof IssueLabelerResponseDTO
     */
    'data'?: IssueData;
    /**
     * 
     * @type {Array<IssueCommentResponseDTO>}
     * @memberof IssueLabelerResponseDTO
     */
    'comments'?: Array<IssueCommentResponseDTO>;
    /**
     * 
     * @type {string}
     * @memberof IssueLabelerResponseDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueLabelerResponseDTO
     */
    'createdBy'?: string;
}
/**
 * 
 * @export
 * @interface IssueReviewerRequestDTO
 */
export interface IssueReviewerRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof IssueReviewerRequestDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof IssueReviewerRequestDTO
     */
    'jobId'?: number;
    /**
     * 
     * @type {IssueData}
     * @memberof IssueReviewerRequestDTO
     */
    'data'?: IssueData;
    /**
     * id of observation that this issue refer to (if needed)
     * @type {number}
     * @memberof IssueReviewerRequestDTO
     */
    'referenceId'?: number;
}
/**
 * 
 * @export
 * @interface JobCriteria
 */
export interface JobCriteria {
    /**
     * 
     * @type {number}
     * @memberof JobCriteria
     */
    'id.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'id.in'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobCriteria
     */
    'agree.equals'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'assignee.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'assignee.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'assignee.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobCriteria
     */
    'batchId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'batchId.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'createdBy.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'createdBy.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'createdDate.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'createdDate.greaterThan'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'createdDate.lessThan'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'lastModifiedDate.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'lastModifiedDate.greaterThan'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'lastModifiedDate.lessThan'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'status.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'status.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'status.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobCriteria
     */
    'step.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'step.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobCriteria
     */
    'taskId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'taskId.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobCriteria
     */
    'version.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'version.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'workType.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'workType.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'workType.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'workspaceId.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'workspaceId.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'jobType.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'jobType.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'jobType.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'projectType.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'projectType.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCriteria
     */
    'projectType.contains'?: string;
}
/**
 * 
 * @export
 * @interface JobRequestDTO
 */
export interface JobRequestDTO {
    /**
     * 
     * @type {boolean}
     * @memberof JobRequestDTO
     */
    'agree'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobRequestDTO
     */
    'assignee'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobRequestDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobRequestDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRequestDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRequestDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRequestDTO
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobRequestDTO
     */
    'step'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobRequestDTO
     */
    'taskId'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobRequestDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobRequestDTO
     */
    'workType'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRequestDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRequestDTO
     */
    'jobType'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRequestDTO
     */
    'projectType'?: string;
}
/**
 * 
 * @export
 * @interface JobResponseDTO
 */
export interface JobResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof JobResponseDTO
     */
    'assignee'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobResponseDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobResponseDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {WorkInstructionResponseDTO}
     * @memberof JobResponseDTO
     */
    'workInstruction'?: WorkInstructionResponseDTO;
    /**
     * 
     * @type {TaskResponseDTO}
     * @memberof JobResponseDTO
     */
    'task'?: TaskResponseDTO;
    /**
     * 
     * @type {string}
     * @memberof JobResponseDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobResponseDTO
     */
    'projectType'?: string;
}
/**
 * 
 * @export
 * @interface JobStatistic
 */
export interface JobStatistic {
    /**
     * 
     * @type {number}
     * @memberof JobStatistic
     */
    'numLabeledByMoreThanTwoLabeler'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatistic
     */
    'numLabeledByOneLabeler'?: number;
}
/**
 * 
 * @export
 * @interface JobStatusResponse
 */
export interface JobStatusResponse {
    /**
     * 
     * @type {number}
     * @memberof JobStatusResponse
     */
    'jobId'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobStatusResponse
     */
    'status'?: JobStatusResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JobStatusResponse
     */
    'percentComplete'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobStatusResponse
     */
    'createdDate'?: string;
}

export const JobStatusResponseStatusEnum = {
    Initial: 'INITIAL',
    Running: 'RUNNING',
    Succeeded: 'SUCCEEDED',
    Failed: 'FAILED',
    Canceled: 'CANCELED'
} as const;

export type JobStatusResponseStatusEnum = typeof JobStatusResponseStatusEnum[keyof typeof JobStatusResponseStatusEnum];

/**
 * 
 * @export
 * @interface LabelDistribution
 */
export interface LabelDistribution {
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof LabelDistribution
     */
    'agreeClassification'?: { [key: string]: number | undefined; };
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof LabelDistribution
     */
    'agreeSegmentation'?: { [key: string]: number | undefined; };
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof LabelDistribution
     */
    'avgIou'?: { [key: string]: number | undefined; };
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof LabelDistribution
     */
    'avgProbability'?: { [key: string]: number | undefined; };
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof LabelDistribution
     */
    'oneLabeler'?: { [key: string]: number | undefined; };
    /**
     * 
     * @type {number}
     * @memberof LabelDistribution
     */
    'step'?: number;
}
/**
 * 
 * @export
 * @interface LabelPairItem
 */
export interface LabelPairItem {
    /**
     * 
     * @type {number}
     * @memberof LabelPairItem
     */
    'aiLabelId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LabelPairItem
     */
    'userLabelId'?: number;
}
/**
 * 
 * @export
 * @interface LabelerAnnotationByBatch
 */
export interface LabelerAnnotationByBatch {
    /**
     * 
     * @type {Array<AnnotationLabelerReport>}
     * @memberof LabelerAnnotationByBatch
     */
    'annotations'?: Array<AnnotationLabelerReport>;
}
/**
 * 
 * @export
 * @interface LabelerStatistic
 */
export interface LabelerStatistic {
    /**
     * 
     * @type {string}
     * @memberof LabelerStatistic
     */
    'fromDate'?: string;
    /**
     * 
     * @type {Array<Record>}
     * @memberof LabelerStatistic
     */
    'records'?: Array<Record>;
    /**
     * 
     * @type {string}
     * @memberof LabelerStatistic
     */
    'toDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof LabelerStatistic
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface LabelerStatisticByBatch
 */
export interface LabelerStatisticByBatch {
    /**
     * 
     * @type {number}
     * @memberof LabelerStatisticByBatch
     */
    'completedTaskCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof LabelerStatisticByBatch
     */
    'completedAnnotationCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof LabelerStatisticByBatch
     */
    'batchId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LabelerStatisticByBatch
     */
    'batchName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabelerStatisticByBatch
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof LabelerStatisticByBatch
     */
    'f1Score'?: number;
    /**
     * 
     * @type {number}
     * @memberof LabelerStatisticByBatch
     */
    'finished'?: number;
    /**
     * 
     * @type {number}
     * @memberof LabelerStatisticByBatch
     */
    'iou'?: number;
    /**
     * 
     * @type {number}
     * @memberof LabelerStatisticByBatch
     */
    'message'?: number;
    /**
     * 
     * @type {number}
     * @memberof LabelerStatisticByBatch
     */
    'noResult'?: number;
    /**
     * 
     * @type {string}
     * @memberof LabelerStatisticByBatch
     */
    'projectName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabelerStatisticByBatch
     */
    'projectType'?: string;
    /**
     * 
     * @type {number}
     * @memberof LabelerStatisticByBatch
     */
    'sensitivity'?: number;
    /**
     * 
     * @type {number}
     * @memberof LabelerStatisticByBatch
     */
    'specificity'?: number;
    /**
     * 
     * @type {string}
     * @memberof LabelerStatisticByBatch
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface ManualAssignJobCsvRequest
 */
export interface ManualAssignJobCsvRequest {
    /**
     * 
     * @type {string}
     * @memberof ManualAssignJobCsvRequest
     */
    'storageResourceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManualAssignJobCsvRequest
     */
    'batchId'?: number;
}
/**
 * 
 * @export
 * @interface ManualAssignJobRequest
 */
export interface ManualAssignJobRequest {
    /**
     * 
     * @type {string}
     * @memberof ManualAssignJobRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManualAssignJobRequest
     */
    'jobId'?: number;
}
/**
 * 
 * @export
 * @interface ManualAssignJobResponse
 */
export interface ManualAssignJobResponse {
    /**
     * 
     * @type {string}
     * @memberof ManualAssignJobResponse
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManualAssignJobResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof ManualAssignJobResponse
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof ManualAssignJobResponse
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ManualAssignJobResponse
     */
    'payload'?: object;
}
/**
 * 
 * @export
 * @interface ManualAssignJobResponseAllOf
 */
export interface ManualAssignJobResponseAllOf {
    /**
     * 
     * @type {object}
     * @memberof ManualAssignJobResponseAllOf
     */
    'payload'?: object;
}
/**
 * 
 * @export
 * @interface MedMdiRawAnnotation
 */
export interface MedMdiRawAnnotation {
    /**
     * 
     * @type {string}
     * @memberof MedMdiRawAnnotation
     */
    'annotationTypePrefix'?: MedMdiRawAnnotationAnnotationTypePrefixEnum;
    /**
     * 
     * @type {Array<{ [key: string]: any | undefined; }>}
     * @memberof MedMdiRawAnnotation
     */
    'attributes'?: Array<{ [key: string]: any | undefined; }>;
    /**
     * 
     * @type {string}
     * @memberof MedMdiRawAnnotation
     */
    'resourceId'?: string;
    /**
     * 
     * @type {Array<MedMdiRawAnnotationMaskInner>}
     * @memberof MedMdiRawAnnotation
     */
    'mask'?: Array<MedMdiRawAnnotationMaskInner>;
}

export const MedMdiRawAnnotationAnnotationTypePrefixEnum = {
    MedMdi: 'med_mdi'
} as const;

export type MedMdiRawAnnotationAnnotationTypePrefixEnum = typeof MedMdiRawAnnotationAnnotationTypePrefixEnum[keyof typeof MedMdiRawAnnotationAnnotationTypePrefixEnum];

/**
 * 
 * @export
 * @interface MedMdiRawAnnotationMaskInner
 */
export interface MedMdiRawAnnotationMaskInner {
    /**
     * 
     * @type {number}
     * @memberof MedMdiRawAnnotationMaskInner
     */
    'maskValue'?: number;
    /**
     * 
     * @type {Array<AnnotationAttributeItem>}
     * @memberof MedMdiRawAnnotationMaskInner
     */
    'attributes'?: Array<AnnotationAttributeItem>;
    /**
     * 
     * @type {number}
     * @memberof MedMdiRawAnnotationMaskInner
     */
    'observationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedMdiRawAnnotationMaskInner
     */
    'estimatedSize'?: number;
}
/**
 * 
 * @export
 * @interface MetadataDTO
 */
export interface MetadataDTO {
    /**
     * 
     * @type {number}
     * @memberof MetadataDTO
     */
    'metadataKeyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MetadataDTO
     */
    'metadataValueId'?: number;
}
/**
 * 
 * @export
 * @interface Metric
 */
export interface Metric {
    /**
     * 
     * @type {Array<number>}
     * @memberof Metric
     */
    'annotationIds'?: Array<number>;
    /**
     * 
     * @type {MetricMetric}
     * @memberof Metric
     */
    'metric'?: MetricMetric;
}
/**
 * 
 * @export
 * @interface MetricMetric
 */
export interface MetricMetric {
    /**
     * 
     * @type {string}
     * @memberof MetricMetric
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MetricMetric
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface MetricResponse
 */
export interface MetricResponse {
    /**
     * 
     * @type {string}
     * @memberof MetricResponse
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof MetricResponse
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof MetricResponse
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<Metric>}
     * @memberof MetricResponse
     */
    'payload'?: Array<Metric>;
}
/**
 * 
 * @export
 * @interface MetricResponseAllOf
 */
export interface MetricResponseAllOf {
    /**
     * 
     * @type {Array<Metric>}
     * @memberof MetricResponseAllOf
     */
    'payload'?: Array<Metric>;
}
/**
 * 
 * @export
 * @interface ObservationAttributeDTO
 */
export interface ObservationAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof ObservationAttributeDTO
     */
    'type'?: ObservationAttributeDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ObservationAttributeDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObservationAttributeDTO
     */
    'defaultValue'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObservationAttributeDTO
     */
    'values'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ObservationAttributeDTO
     */
    'ranges'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof ObservationAttributeDTO
     */
    'required'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ObservationAttributeDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ObservationAttributeDTO
     */
    'createdDate'?: string;
}

export const ObservationAttributeDTOTypeEnum = {
    Radio: 'RADIO',
    Select: 'SELECT',
    Text: 'TEXT',
    Number: 'NUMBER',
    Link: 'LINK'
} as const;

export type ObservationAttributeDTOTypeEnum = typeof ObservationAttributeDTOTypeEnum[keyof typeof ObservationAttributeDTOTypeEnum];

/**
 * 
 * @export
 * @interface ObservationDTO
 */
export interface ObservationDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ObservationDTO
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ObservationDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ObservationDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ObservationDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObservationDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObservationDTO
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObservationDTO
     */
    'observationGroup'?: string;
    /**
     * 
     * @type {ObservationSetting}
     * @memberof ObservationDTO
     */
    'observationSetting'?: ObservationSetting;
    /**
     * 
     * @type {number}
     * @memberof ObservationDTO
     */
    'observationVersion'?: number;
    /**
     * 
     * @type {ObservationDTO}
     * @memberof ObservationDTO
     */
    'parent'?: ObservationDTO;
    /**
     * 
     * @type {number}
     * @memberof ObservationDTO
     */
    'priority'?: number;
    /**
     * 
     * @type {number}
     * @memberof ObservationDTO
     */
    'projectId'?: number;
    /**
     * 
     * @type {Array<ObservationAttributeDTO>}
     * @memberof ObservationDTO
     */
    'attributes'?: Array<ObservationAttributeDTO>;
}
/**
 * 
 * @export
 * @interface ObservationRequest
 */
export interface ObservationRequest {
    /**
     * 
     * @type {Array<TaskObservationDTO>}
     * @memberof ObservationRequest
     */
    'observations'?: Array<TaskObservationDTO>;
}
/**
 * 
 * @export
 * @interface ObservationSetting
 */
export interface ObservationSetting {
    /**
     * 
     * @type {string}
     * @memberof ObservationSetting
     */
    'annotationType'?: ObservationSettingAnnotationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ObservationSetting
     */
    'numberOfPoint'?: number;
    /**
     * 
     * @type {string}
     * @memberof ObservationSetting
     */
    'caveats'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObservationSetting
     */
    'color'?: string;
    /**
     * 
     * @type {Array<IllustrationItem>}
     * @memberof ObservationSetting
     */
    'illustrations'?: Array<IllustrationItem>;
    /**
     * 
     * @type {boolean}
     * @memberof ObservationSetting
     */
    'systemAttribute'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ObservationSetting
     */
    'source'?: string;
    /**
     * 
     * @type {number}
     * @memberof ObservationSetting
     */
    'maskId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ObservationSetting
     */
    'selectable'?: boolean;
}

export const ObservationSettingAnnotationTypeEnum = {
    Classification: 'CLASSIFICATION',
    Bbox: 'BBOX',
    Polygon: 'POLYGON',
    Point: 'POINT',
    Cuboid: 'CUBOID',
    Entity: 'ENTITY',
    Relation: 'RELATION',
    General: 'GENERAL',
    Mask: 'MASK',
    Metadata: 'METADATA'
} as const;

export type ObservationSettingAnnotationTypeEnum = typeof ObservationSettingAnnotationTypeEnum[keyof typeof ObservationSettingAnnotationTypeEnum];

/**
 * 
 * @export
 * @interface OpenIssueCustomerRequestDTO
 */
export interface OpenIssueCustomerRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof OpenIssueCustomerRequestDTO
     */
    'issueId'?: number;
}
/**
 * 
 * @export
 * @interface OpenIssueReviewerRequestDTO
 */
export interface OpenIssueReviewerRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof OpenIssueReviewerRequestDTO
     */
    'issueId'?: number;
}
/**
 * minimal Pageable query parameters
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'totalItem'?: number;
}
/**
 * 
 * @export
 * @interface Parameter
 */
export interface Parameter {
    /**
     * 
     * @type {number}
     * @memberof Parameter
     */
    'GaussianStandardDeviationMm'?: number;
    /**
     * 
     * @type {number}
     * @memberof Parameter
     */
    'KernelSizeMm'?: number;
    /**
     * 
     * @type {number}
     * @memberof Parameter
     */
    'JointTaubinSmoothingFactor'?: number;
}
/**
 * 
 * @export
 * @interface Pipeline
 */
export interface Pipeline {
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface Polygon
 */
export interface Polygon {
    /**
     * 
     * @type {number}
     * @memberof Polygon
     */
    'layer'?: number;
    /**
     * 0 is add, -1 is subtract
     * @type {number}
     * @memberof Polygon
     */
    'type'?: number;
    /**
     * 
     * @type {Array<Point>}
     * @memberof Polygon
     */
    'points'?: Array<Point>;
}
/**
 * 
 * @export
 * @interface PricingCalculationRound
 */
export interface PricingCalculationRound {
    /**
     * 
     * @type {number}
     * @memberof PricingCalculationRound
     */
    'roundNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingCalculationRound
     */
    'perJob'?: number;
}
/**
 * 
 * @export
 * @interface PricingCalculationStep
 */
export interface PricingCalculationStep {
    /**
     * 
     * @type {Array<PricingCalculationRound>}
     * @memberof PricingCalculationStep
     */
    'rounds'?: Array<PricingCalculationRound>;
    /**
     * 
     * @type {number}
     * @memberof PricingCalculationStep
     */
    'stepNumber'?: number;
}
/**
 * 
 * @export
 * @interface PricingRequestDTO
 */
export interface PricingRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof PricingRequestDTO
     */
    'type'?: PricingRequestDTOTypeEnum;
    /**
     * 
     * @type {Array<PricingCalculationStep>}
     * @memberof PricingRequestDTO
     */
    'steps'?: Array<PricingCalculationStep>;
    /**
     * 
     * @type {number}
     * @memberof PricingRequestDTO
     */
    'currencyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingRequestDTO
     */
    'perTask'?: number;
}

export const PricingRequestDTOTypeEnum = {
    ByJob: 'ByJob',
    ByTask: 'ByTask'
} as const;

export type PricingRequestDTOTypeEnum = typeof PricingRequestDTOTypeEnum[keyof typeof PricingRequestDTOTypeEnum];

/**
 * 
 * @export
 * @interface PricingResponseDTO
 */
export interface PricingResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof PricingResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PricingResponseDTO
     */
    'type'?: string;
    /**
     * 
     * @type {{ [key: string]: any | undefined; }}
     * @memberof PricingResponseDTO
     */
    'payload'?: { [key: string]: any | undefined; };
    /**
     * 
     * @type {string}
     * @memberof PricingResponseDTO
     */
    'formulaVersion'?: string;
    /**
     * 
     * @type {number}
     * @memberof PricingResponseDTO
     */
    'serviceFeeRatio'?: number;
    /**
     * 
     * @type {CurrencyResponseDTO}
     * @memberof PricingResponseDTO
     */
    'currency'?: CurrencyResponseDTO;
}
/**
 * 
 * @export
 * @interface ProjectCriteria
 */
export interface ProjectCriteria {
    /**
     * 
     * @type {number}
     * @memberof ProjectCriteria
     */
    'id.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'id.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'name.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'name.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'name.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'createdDate.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'createdDate.greaterThan'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'createdDate.lessThan'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'type.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'type.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'type.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'description.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'description.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'description.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'createdBy.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'createdBy.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'createdBy.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'lastModifiedBy.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'lastModifiedBy.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'lastModifiedBy.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'lastModifiedDate.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'lastModifiedDate.greaterThan'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCriteria
     */
    'lastModifiedDate.lessThan'?: string;
}
/**
 * 
 * @export
 * @interface ProjectEssentialResponseDTO
 */
export interface ProjectEssentialResponseDTO {
    /**
     * 
     * @type {Array<BatchEssentialResponseDTO>}
     * @memberof ProjectEssentialResponseDTO
     */
    'batches'?: Array<BatchEssentialResponseDTO>;
    /**
     * 
     * @type {number}
     * @memberof ProjectEssentialResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectEssentialResponseDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectEssentialResponseDTO
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectEssentialResponseDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectEssentialResponseDTO
     */
    'thumbnailUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectEssentialResponseDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectEssentialResponseDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectEssentialResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectEssentialResponseDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {ProjectSettingDTO}
     * @memberof ProjectEssentialResponseDTO
     */
    'settings'?: ProjectSettingDTO;
}
/**
 * 
 * @export
 * @interface ProjectProgress
 */
export interface ProjectProgress {
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof ProjectProgress
     */
    'batchStatistic'?: { [key: string]: number | undefined; };
    /**
     * 
     * @type {string}
     * @memberof ProjectProgress
     */
    'startDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectProgress
     */
    'totalBatches'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectProgress
     */
    'totalLabelers'?: number;
}
/**
 * 
 * @export
 * @interface ProjectRequestDTO
 */
export interface ProjectRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDTO
     */
    'group'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDTO
     */
    'totalBatch'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDTO
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {Array<ExportType>}
     * @memberof ProjectRequestDTO
     */
    'exportTypes'?: Array<ExportType>;
    /**
     * 
     * @type {ProjectSettingDTO}
     * @memberof ProjectRequestDTO
     */
    'settings'?: ProjectSettingDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDTO
     */
    'thumbnailUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDTO
     */
    'order'?: number;
}
/**
 * 
 * @export
 * @interface ProjectResponseDTO
 */
export interface ProjectResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDTO
     */
    'group'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDTO
     */
    'thumbnailUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponseDTO
     */
    'totalBatch'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDTO
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponseDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {Array<ExportType>}
     * @memberof ProjectResponseDTO
     */
    'exportTypes'?: Array<ExportType>;
    /**
     * 
     * @type {ProjectSettingDTO}
     * @memberof ProjectResponseDTO
     */
    'settings'?: ProjectSettingDTO;
}
/**
 * 
 * @export
 * @interface ProjectSettingDTO
 */
export interface ProjectSettingDTO {
    /**
     * 
     * @type {number}
     * @memberof ProjectSettingDTO
     */
    'autoSaveInSecond'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingDTO
     */
    'pollStrategy'?: ProjectSettingDTOPollStrategyEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingDTO
     */
    'historyPermission'?: ProjectSettingDTOHistoryPermissionEnum;
}

export const ProjectSettingDTOPollStrategyEnum = {
    Single: 'Single',
    Group: 'Group'
} as const;

export type ProjectSettingDTOPollStrategyEnum = typeof ProjectSettingDTOPollStrategyEnum[keyof typeof ProjectSettingDTOPollStrategyEnum];
export const ProjectSettingDTOHistoryPermissionEnum = {
    ReadOnly: 'ReadOnly',
    Edit: 'Edit'
} as const;

export type ProjectSettingDTOHistoryPermissionEnum = typeof ProjectSettingDTOHistoryPermissionEnum[keyof typeof ProjectSettingDTOHistoryPermissionEnum];

/**
 * 
 * @export
 * @interface ProjectStatistic
 */
export interface ProjectStatistic {
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof ProjectStatistic
     */
    'category'?: { [key: string]: number | undefined; };
    /**
     * 
     * @type {number}
     * @memberof ProjectStatistic
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface ProjectSubTypeDTO
 */
export interface ProjectSubTypeDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectSubTypeDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSubTypeDTO
     */
    'code'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSubTypeDTO
     */
    'dataSourceTypes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProjectTemplateConfig
 */
export interface ProjectTemplateConfig {
    /**
     * 
     * @type {ProjectRequestDTO}
     * @memberof ProjectTemplateConfig
     */
    'projectInfo'?: ProjectRequestDTO;
    /**
     * 
     * @type {Array<ObservationDTO>}
     * @memberof ProjectTemplateConfig
     */
    'observtionInfo'?: Array<ObservationDTO>;
}
/**
 * 
 * @export
 * @interface ProjectTemplateDTO
 */
export interface ProjectTemplateDTO {
    /**
     * 
     * @type {number}
     * @memberof ProjectTemplateDTO
     */
    'id'?: number;
    /**
     * 
     * @type {ProjectTemplateConfig}
     * @memberof ProjectTemplateDTO
     */
    'config'?: ProjectTemplateConfig;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateDTO
     */
    'thumbnailUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateDTO
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ProjectTemplateDefaultLabel
 */
export interface ProjectTemplateDefaultLabel {
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateDefaultLabel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateDefaultLabel
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateDefaultLabel
     */
    'color'?: string;
    /**
     * 
     * @type {Array<ProjectTemplateDefaultLabelAttribute>}
     * @memberof ProjectTemplateDefaultLabel
     */
    'attributes'?: Array<ProjectTemplateDefaultLabelAttribute>;
}
/**
 * 
 * @export
 * @interface ProjectTemplateDefaultLabelAttribute
 */
export interface ProjectTemplateDefaultLabelAttribute {
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateDefaultLabelAttribute
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateDefaultLabelAttribute
     */
    'type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTemplateDefaultLabelAttribute
     */
    'value'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProjectTemplateResponseDTO
 */
export interface ProjectTemplateResponseDTO {
    /**
     * 
     * @type {ProjectRequestDTO}
     * @memberof ProjectTemplateResponseDTO
     */
    'projectInfo'?: ProjectRequestDTO;
    /**
     * 
     * @type {Array<ObservationDTO>}
     * @memberof ProjectTemplateResponseDTO
     */
    'observationInfo'?: Array<ObservationDTO>;
    /**
     * 
     * @type {WorkflowDTO}
     * @memberof ProjectTemplateResponseDTO
     */
    'workflowInfo'?: WorkflowDTO;
    /**
     * 
     * @type {Array<WorkInstructionResponseDTO>}
     * @memberof ProjectTemplateResponseDTO
     */
    'workInstructionInfo'?: Array<WorkInstructionResponseDTO>;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateResponseDTO
     */
    'thumbnailUrl'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTemplateResponseDTO
     */
    'suggestedAnnotationTypes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProjectTypeDTO
 */
export interface ProjectTypeDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeDTO
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeDTO
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ProjectSubTypeDTO>}
     * @memberof ProjectTypeDTO
     */
    'subTypes'?: Array<ProjectSubTypeDTO>;
}
/**
 * 
 * @export
 * @interface ProjectV2RequestDTO
 */
export interface ProjectV2RequestDTO {
    /**
     * 
     * @type {ProjectRequestDTO}
     * @memberof ProjectV2RequestDTO
     */
    'projectInfo'?: ProjectRequestDTO;
    /**
     * 
     * @type {Array<ObservationDTO>}
     * @memberof ProjectV2RequestDTO
     */
    'observationInfo'?: Array<ObservationDTO>;
    /**
     * 
     * @type {Array<WorkManagementData>}
     * @memberof ProjectV2RequestDTO
     */
    'workManagementInfo'?: Array<WorkManagementData>;
    /**
     * 
     * @type {WorkflowDTO}
     * @memberof ProjectV2RequestDTO
     */
    'workflowInfo'?: WorkflowDTO;
    /**
     * 
     * @type {Array<WorkInstructionRequestDTO>}
     * @memberof ProjectV2RequestDTO
     */
    'workInstructionInfo'?: Array<WorkInstructionRequestDTO>;
}
/**
 * Depends on annotationItemType, this could be RawImageAnnotationItem or TextAnnotationItem or TtsAnnotationItem or SttAnnotationItem or SliceAnnotationItem or VideoAnnotationItem
 * @export
 * @interface RawAnnotationItem
 */
export interface RawAnnotationItem {
    /**
     * 
     * @type {Array<AnnotationAttributeItem>}
     * @memberof RawAnnotationItem
     */
    'attributes'?: Array<AnnotationAttributeItem>;
    /**
     * 
     * @type {number}
     * @memberof RawAnnotationItem
     */
    'frameId'?: number;
    /**
     * uuid generated by client-side, scope by job
     * @type {string}
     * @memberof RawAnnotationItem
     */
    'referenceId'?: string;
    /**
     * 
     * @type {BBox}
     * @memberof RawAnnotationItem
     */
    'bbox'?: BBox;
    /**
     * 
     * @type {Array<Polygon>}
     * @memberof RawAnnotationItem
     */
    'polygons'?: Array<Polygon>;
    /**
     * 
     * @type {number}
     * @memberof RawAnnotationItem
     */
    'startIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof RawAnnotationItem
     */
    'endIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof RawAnnotationItem
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RawAnnotationItem
     */
    'observationId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RawAnnotationItem
     */
    'matched'?: boolean;
    /**
     * id of audio resource (StorageResource on storage-service)
     * @type {string}
     * @memberof RawAnnotationItem
     */
    'audioResourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawAnnotationItem
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawAnnotationItem
     */
    'note'?: string;
    /**
     * 
     * @type {Array<SttTextLabel>}
     * @memberof RawAnnotationItem
     */
    'textLabels'?: Array<SttTextLabel>;
    /**
     * id of audio resource (StorageResource on storage-service)
     * @type {string}
     * @memberof RawAnnotationItem
     */
    'resourceId'?: string;
    /**
     * 
     * @type {Array<SliceAnnotationItemAllOfMask>}
     * @memberof RawAnnotationItem
     */
    'mask'?: Array<SliceAnnotationItemAllOfMask>;
    /**
     * 
     * @type {number}
     * @memberof RawAnnotationItem
     */
    'startFrameId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RawAnnotationItem
     */
    'endFrameId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RawAnnotationItem
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RawAnnotationItem
     */
    'type'?: RawAnnotationItemTypeEnum;
    /**
     * 
     * @type {Array<Point>}
     * @memberof RawAnnotationItem
     */
    'points'?: Array<Point>;
}

export const RawAnnotationItemTypeEnum = {
    Polygon: 'polygon',
    Bbox: 'bbox',
    Polyline: 'polyline',
    Line: 'line',
    Ellipse: 'ellipse',
    Points: 'points'
} as const;

export type RawAnnotationItemTypeEnum = typeof RawAnnotationItemTypeEnum[keyof typeof RawAnnotationItemTypeEnum];

/**
 * Depends on annotationItemType, this could be RawImageAnnotationItem or TextAnnotationItem or AudioTTSAnnotationItem or AudioSTTAnnotationItem 
 * @export
 * @interface RawAnnotationItemV2
 */
export interface RawAnnotationItemV2 {
    /**
     * 
     * @type {Array<AnnotationAttributeItem>}
     * @memberof RawAnnotationItemV2
     */
    'attributes'?: Array<AnnotationAttributeItem>;
    /**
     * 
     * @type {Array<TextLabel>}
     * @memberof RawAnnotationItemV2
     */
    'labels'?: Array<TextLabel>;
    /**
     * 
     * @type {Array<TextRelation>}
     * @memberof RawAnnotationItemV2
     */
    'relations'?: Array<TextRelation>;
    /**
     * 
     * @type {number}
     * @memberof RawAnnotationItemV2
     */
    'observationId'?: number;
    /**
     * 
     * @type {Array<TextConflictDTO>}
     * @memberof RawAnnotationItemV2
     */
    'conflictInfos'?: Array<TextConflictDTO>;
    /**
     * id of audio resource (StorageResource on storage-service)
     * @type {string}
     * @memberof RawAnnotationItemV2
     */
    'audioResourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawAnnotationItemV2
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawAnnotationItemV2
     */
    'note'?: string;
    /**
     * 
     * @type {Array<SttTextLabel>}
     * @memberof RawAnnotationItemV2
     */
    'textLabels'?: Array<SttTextLabel>;
}
/**
 * 
 * @export
 * @interface RawImageAnnotationItem
 */
export interface RawImageAnnotationItem {
    /**
     * 
     * @type {Array<AnnotationAttributeItem>}
     * @memberof RawImageAnnotationItem
     */
    'attributes'?: Array<AnnotationAttributeItem>;
    /**
     * 
     * @type {number}
     * @memberof RawImageAnnotationItem
     */
    'frameId'?: number;
    /**
     * uuid generated by client-side, scope by job
     * @type {string}
     * @memberof RawImageAnnotationItem
     */
    'referenceId'?: string;
    /**
     * 
     * @type {BBox}
     * @memberof RawImageAnnotationItem
     */
    'bbox'?: BBox;
    /**
     * 
     * @type {Array<Polygon>}
     * @memberof RawImageAnnotationItem
     */
    'polygons'?: Array<Polygon>;
}
/**
 * 
 * @export
 * @interface RawImageAnnotationItemAllOf
 */
export interface RawImageAnnotationItemAllOf {
    /**
     * 
     * @type {BBox}
     * @memberof RawImageAnnotationItemAllOf
     */
    'bbox'?: BBox;
    /**
     * 
     * @type {Array<Polygon>}
     * @memberof RawImageAnnotationItemAllOf
     */
    'polygons'?: Array<Polygon>;
}
/**
 * 
 * @export
 * @interface RawMetaAnnotationItem
 */
export interface RawMetaAnnotationItem {
    /**
     * 
     * @type {number}
     * @memberof RawMetaAnnotationItem
     */
    'frameId'?: number;
    /**
     * uuid generated by client-side, scope by job
     * @type {string}
     * @memberof RawMetaAnnotationItem
     */
    'referenceId'?: string;
}
/**
 * 
 * @export
 * @interface Record
 */
export interface Record {
    /**
     * 
     * @type {number}
     * @memberof Record
     */
    'completed'?: number;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    'daily'?: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    'ending'?: string;
    /**
     * 
     * @type {number}
     * @memberof Record
     */
    'skipped'?: number;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    'starting'?: string;
    /**
     * 
     * @type {number}
     * @memberof Record
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof Record
     */
    'totalCase'?: number;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    'group'?: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    'projectType'?: string;
}
/**
 * 
 * @export
 * @interface ReopenJobsRequest
 */
export interface ReopenJobsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReopenJobsRequest
     */
    'jobIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ReopenJobsRequest
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface ReopenTasksRequest
 */
export interface ReopenTasksRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReopenTasksRequest
     */
    'taskIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ReopenTasksRequest
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface ResolveIssueCustomerRequestDTO
 */
export interface ResolveIssueCustomerRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof ResolveIssueCustomerRequestDTO
     */
    'issueId'?: number;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'description'?: string;
    /**
     * 
     * @type {File}
     * @memberof Resource
     */
    'file'?: File;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'filename'?: string;
    /**
     * 
     * @type {object}
     * @memberof Resource
     */
    'inputStream'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    'open'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    'readable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface ResourceRes
 */
export interface ResourceRes {
    /**
     * 
     * @type {string}
     * @memberof ResourceRes
     */
    'description'?: string;
    /**
     * 
     * @type {File}
     * @memberof ResourceRes
     */
    'file'?: File;
    /**
     * 
     * @type {string}
     * @memberof ResourceRes
     */
    'filename'?: string;
    /**
     * 
     * @type {object}
     * @memberof ResourceRes
     */
    'inputStream'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceRes
     */
    'open'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceRes
     */
    'readable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceRes
     */
    'uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceRes
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface RestResponse
 */
export interface RestResponse {
    /**
     * 
     * @type {string}
     * @memberof RestResponse
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponse
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponse
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseImportAnnotation
 */
export interface RestResponseImportAnnotation {
    /**
     * 
     * @type {string}
     * @memberof RestResponseImportAnnotation
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseImportAnnotation
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseImportAnnotation
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseImportAnnotation
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {ImportRequestDTO}
     * @memberof RestResponseImportAnnotation
     */
    'payload'?: ImportRequestDTO;
}
/**
 * 
 * @export
 * @interface RestResponseImportAnnotationAllOf
 */
export interface RestResponseImportAnnotationAllOf {
    /**
     * 
     * @type {ImportRequestDTO}
     * @memberof RestResponseImportAnnotationAllOf
     */
    'payload'?: ImportRequestDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfAnnotationAugmentationResponse
 */
export interface RestResponseOfAnnotationAugmentationResponse {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfAnnotationAugmentationResponse
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfAnnotationAugmentationResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfAnnotationAugmentationResponse
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfAnnotationAugmentationResponse
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {AnnotationAugmentationResponse}
     * @memberof RestResponseOfAnnotationAugmentationResponse
     */
    'payload'?: AnnotationAugmentationResponse;
}
/**
 * 
 * @export
 * @interface RestResponseOfAnnotationAugmentationResponseAllOf
 */
export interface RestResponseOfAnnotationAugmentationResponseAllOf {
    /**
     * 
     * @type {AnnotationAugmentationResponse}
     * @memberof RestResponseOfAnnotationAugmentationResponseAllOf
     */
    'payload'?: AnnotationAugmentationResponse;
}
/**
 * 
 * @export
 * @interface RestResponseOfAnnotationDTO
 */
export interface RestResponseOfAnnotationDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfAnnotationDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfAnnotationDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfAnnotationDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfAnnotationDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {AnnotationDTO}
     * @memberof RestResponseOfAnnotationDTO
     */
    'payload'?: AnnotationDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfAnnotationDTOAllOf
 */
export interface RestResponseOfAnnotationDTOAllOf {
    /**
     * 
     * @type {AnnotationDTO}
     * @memberof RestResponseOfAnnotationDTOAllOf
     */
    'payload'?: AnnotationDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfAnnotationJobResponse
 */
export interface RestResponseOfAnnotationJobResponse {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfAnnotationJobResponse
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfAnnotationJobResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfAnnotationJobResponse
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfAnnotationJobResponse
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {AnnotationJobResponse}
     * @memberof RestResponseOfAnnotationJobResponse
     */
    'payload'?: AnnotationJobResponse;
}
/**
 * 
 * @export
 * @interface RestResponseOfAnnotationJobResponseAllOf
 */
export interface RestResponseOfAnnotationJobResponseAllOf {
    /**
     * 
     * @type {AnnotationJobResponse}
     * @memberof RestResponseOfAnnotationJobResponseAllOf
     */
    'payload'?: AnnotationJobResponse;
}
/**
 * 
 * @export
 * @interface RestResponseOfAttributeType
 */
export interface RestResponseOfAttributeType {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfAttributeType
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfAttributeType
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfAttributeType
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfAttributeType
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RestResponseOfAttributeType
     */
    'payload'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RestResponseOfAttributeTypeAllOf
 */
export interface RestResponseOfAttributeTypeAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof RestResponseOfAttributeTypeAllOf
     */
    'payload'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RestResponseOfBatchAnnotatingResponseDTO
 */
export interface RestResponseOfBatchAnnotatingResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfBatchAnnotatingResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfBatchAnnotatingResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfBatchAnnotatingResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {BatchAnnotatingResponseDTO}
     * @memberof RestResponseOfBatchAnnotatingResponseDTO
     */
    'payload'?: BatchAnnotatingResponseDTO;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfBatchAnnotatingResponseDTO
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseOfBatchInfo
 */
export interface RestResponseOfBatchInfo {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfBatchInfo
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfBatchInfo
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfBatchInfo
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfBatchInfo
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {BatchInfo}
     * @memberof RestResponseOfBatchInfo
     */
    'payload'?: BatchInfo;
}
/**
 * 
 * @export
 * @interface RestResponseOfBatchInfoAllOf
 */
export interface RestResponseOfBatchInfoAllOf {
    /**
     * 
     * @type {BatchInfo}
     * @memberof RestResponseOfBatchInfoAllOf
     */
    'payload'?: BatchInfo;
}
/**
 * 
 * @export
 * @interface RestResponseOfBatchObservationDTO
 */
export interface RestResponseOfBatchObservationDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfBatchObservationDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfBatchObservationDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfBatchObservationDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfBatchObservationDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {BatchObservationDTO}
     * @memberof RestResponseOfBatchObservationDTO
     */
    'payload'?: BatchObservationDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfBatchObservationDTOAllOf
 */
export interface RestResponseOfBatchObservationDTOAllOf {
    /**
     * 
     * @type {BatchObservationDTO}
     * @memberof RestResponseOfBatchObservationDTOAllOf
     */
    'payload'?: BatchObservationDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfBatchProgress
 */
export interface RestResponseOfBatchProgress {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfBatchProgress
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfBatchProgress
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfBatchProgress
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfBatchProgress
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {BatchProgress}
     * @memberof RestResponseOfBatchProgress
     */
    'payload'?: BatchProgress;
}
/**
 * 
 * @export
 * @interface RestResponseOfBatchProgressAllOf
 */
export interface RestResponseOfBatchProgressAllOf {
    /**
     * 
     * @type {BatchProgress}
     * @memberof RestResponseOfBatchProgressAllOf
     */
    'payload'?: BatchProgress;
}
/**
 * 
 * @export
 * @interface RestResponseOfBatchResponseDTO
 */
export interface RestResponseOfBatchResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfBatchResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfBatchResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfBatchResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfBatchResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {BatchResponseDTO}
     * @memberof RestResponseOfBatchResponseDTO
     */
    'payload'?: BatchResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfBatchResponseDTOAllOf
 */
export interface RestResponseOfBatchResponseDTOAllOf {
    /**
     * 
     * @type {BatchResponseDTO}
     * @memberof RestResponseOfBatchResponseDTOAllOf
     */
    'payload'?: BatchResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfBoolean
 */
export interface RestResponseOfBoolean {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfBoolean
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfBoolean
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfBoolean
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfBoolean
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfBoolean
     */
    'payload'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseOfBooleanAllOf
 */
export interface RestResponseOfBooleanAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfBooleanAllOf
     */
    'payload'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseOfCreateProjectV2ResponseDTO
 */
export interface RestResponseOfCreateProjectV2ResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfCreateProjectV2ResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfCreateProjectV2ResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfCreateProjectV2ResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfCreateProjectV2ResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {RestResponseOfCreateProjectV2ResponseDTOAllOfPayload}
     * @memberof RestResponseOfCreateProjectV2ResponseDTO
     */
    'payload'?: RestResponseOfCreateProjectV2ResponseDTOAllOfPayload;
}
/**
 * 
 * @export
 * @interface RestResponseOfCreateProjectV2ResponseDTOAllOf
 */
export interface RestResponseOfCreateProjectV2ResponseDTOAllOf {
    /**
     * 
     * @type {RestResponseOfCreateProjectV2ResponseDTOAllOfPayload}
     * @memberof RestResponseOfCreateProjectV2ResponseDTOAllOf
     */
    'payload'?: RestResponseOfCreateProjectV2ResponseDTOAllOfPayload;
}
/**
 * 
 * @export
 * @interface RestResponseOfCreateProjectV2ResponseDTOAllOfPayload
 */
export interface RestResponseOfCreateProjectV2ResponseDTOAllOfPayload {
    /**
     * 
     * @type {ProjectResponseDTO}
     * @memberof RestResponseOfCreateProjectV2ResponseDTOAllOfPayload
     */
    'project'?: ProjectResponseDTO;
    /**
     * 
     * @type {WorkflowDTO}
     * @memberof RestResponseOfCreateProjectV2ResponseDTOAllOfPayload
     */
    'workflow'?: WorkflowDTO;
    /**
     * 
     * @type {Array<WorkInstructionResponseDTO>}
     * @memberof RestResponseOfCreateProjectV2ResponseDTOAllOfPayload
     */
    'workInstruction'?: Array<WorkInstructionResponseDTO>;
    /**
     * 
     * @type {Array<ObservationDTO>}
     * @memberof RestResponseOfCreateProjectV2ResponseDTOAllOfPayload
     */
    'observation'?: Array<ObservationDTO>;
    /**
     * 
     * @type {Array<WorkManagementDTO>}
     * @memberof RestResponseOfCreateProjectV2ResponseDTOAllOfPayload
     */
    'workManagement'?: Array<WorkManagementDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfDatasetVersionDTO
 */
export interface RestResponseOfDatasetVersionDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDatasetVersionDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDatasetVersionDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfDatasetVersionDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfDatasetVersionDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {DatasetVersionDTO}
     * @memberof RestResponseOfDatasetVersionDTO
     */
    'payload'?: DatasetVersionDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfDatasetVersionDTOAllOf
 */
export interface RestResponseOfDatasetVersionDTOAllOf {
    /**
     * 
     * @type {DatasetVersionDTO}
     * @memberof RestResponseOfDatasetVersionDTOAllOf
     */
    'payload'?: DatasetVersionDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfGenerateAnnotationResponse
 */
export interface RestResponseOfGenerateAnnotationResponse {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfGenerateAnnotationResponse
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfGenerateAnnotationResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfGenerateAnnotationResponse
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfGenerateAnnotationResponse
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {GenerateAnnotationResponse}
     * @memberof RestResponseOfGenerateAnnotationResponse
     */
    'payload'?: GenerateAnnotationResponse;
}
/**
 * 
 * @export
 * @interface RestResponseOfGenerateAnnotationResponseAllOf
 */
export interface RestResponseOfGenerateAnnotationResponseAllOf {
    /**
     * 
     * @type {GenerateAnnotationResponse}
     * @memberof RestResponseOfGenerateAnnotationResponseAllOf
     */
    'payload'?: GenerateAnnotationResponse;
}
/**
 * 
 * @export
 * @interface RestResponseOfInfoImportResponse
 */
export interface RestResponseOfInfoImportResponse {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfInfoImportResponse
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfInfoImportResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfInfoImportResponse
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfInfoImportResponse
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {InfoImportResponse}
     * @memberof RestResponseOfInfoImportResponse
     */
    'payload'?: InfoImportResponse;
}
/**
 * 
 * @export
 * @interface RestResponseOfInfoImportResponseAllOf
 */
export interface RestResponseOfInfoImportResponseAllOf {
    /**
     * 
     * @type {InfoImportResponse}
     * @memberof RestResponseOfInfoImportResponseAllOf
     */
    'payload'?: InfoImportResponse;
}
/**
 * 
 * @export
 * @interface RestResponseOfIssueCommentResponseDTO
 */
export interface RestResponseOfIssueCommentResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfIssueCommentResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfIssueCommentResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfIssueCommentResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfIssueCommentResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {IssueCommentResponseDTO}
     * @memberof RestResponseOfIssueCommentResponseDTO
     */
    'payload'?: IssueCommentResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfIssueCommentResponseDTOAllOf
 */
export interface RestResponseOfIssueCommentResponseDTOAllOf {
    /**
     * 
     * @type {IssueCommentResponseDTO}
     * @memberof RestResponseOfIssueCommentResponseDTOAllOf
     */
    'payload'?: IssueCommentResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfIssueCustomerResponseDTO
 */
export interface RestResponseOfIssueCustomerResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfIssueCustomerResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfIssueCustomerResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfIssueCustomerResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfIssueCustomerResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {IssueCustomerResponseDTO}
     * @memberof RestResponseOfIssueCustomerResponseDTO
     */
    'payload'?: IssueCustomerResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfIssueCustomerResponseDTOAllOf
 */
export interface RestResponseOfIssueCustomerResponseDTOAllOf {
    /**
     * 
     * @type {IssueCustomerResponseDTO}
     * @memberof RestResponseOfIssueCustomerResponseDTOAllOf
     */
    'payload'?: IssueCustomerResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfIssueLabelerResponseDTO
 */
export interface RestResponseOfIssueLabelerResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfIssueLabelerResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfIssueLabelerResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfIssueLabelerResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfIssueLabelerResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {IssueLabelerResponseDTO}
     * @memberof RestResponseOfIssueLabelerResponseDTO
     */
    'payload'?: IssueLabelerResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfIssueLabelerResponseDTOAllOf
 */
export interface RestResponseOfIssueLabelerResponseDTOAllOf {
    /**
     * 
     * @type {IssueLabelerResponseDTO}
     * @memberof RestResponseOfIssueLabelerResponseDTOAllOf
     */
    'payload'?: IssueLabelerResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfJobResponseDTO
 */
export interface RestResponseOfJobResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfJobResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfJobResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfJobResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {JobResponseDTO}
     * @memberof RestResponseOfJobResponseDTO
     */
    'payload'?: JobResponseDTO;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfJobResponseDTO
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseOfJobStatistic
 */
export interface RestResponseOfJobStatistic {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfJobStatistic
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfJobStatistic
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfJobStatistic
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {JobStatistic}
     * @memberof RestResponseOfJobStatistic
     */
    'payload'?: JobStatistic;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfJobStatistic
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseOfJobStatusResponse
 */
export interface RestResponseOfJobStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfJobStatusResponse
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfJobStatusResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfJobStatusResponse
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfJobStatusResponse
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {JobStatusResponse}
     * @memberof RestResponseOfJobStatusResponse
     */
    'payload'?: JobStatusResponse;
}
/**
 * 
 * @export
 * @interface RestResponseOfJobStatusResponseAllOf
 */
export interface RestResponseOfJobStatusResponseAllOf {
    /**
     * 
     * @type {JobStatusResponse}
     * @memberof RestResponseOfJobStatusResponseAllOf
     */
    'payload'?: JobStatusResponse;
}
/**
 * 
 * @export
 * @interface RestResponseOfLabelDistribution
 */
export interface RestResponseOfLabelDistribution {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfLabelDistribution
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfLabelDistribution
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfLabelDistribution
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfLabelDistribution
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {LabelDistribution}
     * @memberof RestResponseOfLabelDistribution
     */
    'payload'?: LabelDistribution;
}
/**
 * 
 * @export
 * @interface RestResponseOfLabelDistributionAllOf
 */
export interface RestResponseOfLabelDistributionAllOf {
    /**
     * 
     * @type {LabelDistribution}
     * @memberof RestResponseOfLabelDistributionAllOf
     */
    'payload'?: LabelDistribution;
}
/**
 * 
 * @export
 * @interface RestResponseOfLabelerStatistic
 */
export interface RestResponseOfLabelerStatistic {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfLabelerStatistic
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfLabelerStatistic
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfLabelerStatistic
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfLabelerStatistic
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {LabelerStatistic}
     * @memberof RestResponseOfLabelerStatistic
     */
    'payload'?: LabelerStatistic;
}
/**
 * 
 * @export
 * @interface RestResponseOfLabelerStatisticAllOf
 */
export interface RestResponseOfLabelerStatisticAllOf {
    /**
     * 
     * @type {LabelerStatistic}
     * @memberof RestResponseOfLabelerStatisticAllOf
     */
    'payload'?: LabelerStatistic;
}
/**
 * 
 * @export
 * @interface RestResponseOfListAnnotationJobResponse
 */
export interface RestResponseOfListAnnotationJobResponse {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListAnnotationJobResponse
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListAnnotationJobResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListAnnotationJobResponse
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListAnnotationJobResponse
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<AnnotationJobResponse>}
     * @memberof RestResponseOfListAnnotationJobResponse
     */
    'payload'?: Array<AnnotationJobResponse>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListAnnotationJobResponseAllOf
 */
export interface RestResponseOfListAnnotationJobResponseAllOf {
    /**
     * 
     * @type {Array<AnnotationJobResponse>}
     * @memberof RestResponseOfListAnnotationJobResponseAllOf
     */
    'payload'?: Array<AnnotationJobResponse>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListGenerateAnnotationStatusResponse
 */
export interface RestResponseOfListGenerateAnnotationStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListGenerateAnnotationStatusResponse
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListGenerateAnnotationStatusResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListGenerateAnnotationStatusResponse
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListGenerateAnnotationStatusResponse
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<JobStatusResponse>}
     * @memberof RestResponseOfListGenerateAnnotationStatusResponse
     */
    'payload'?: Array<JobStatusResponse>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListGenerateAnnotationStatusResponseAllOf
 */
export interface RestResponseOfListGenerateAnnotationStatusResponseAllOf {
    /**
     * 
     * @type {Array<JobStatusResponse>}
     * @memberof RestResponseOfListGenerateAnnotationStatusResponseAllOf
     */
    'payload'?: Array<JobStatusResponse>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListMediaIds
 */
export interface RestResponseOfListMediaIds {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListMediaIds
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListMediaIds
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListMediaIds
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListMediaIds
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<RestResponseOfListMediaIdsAllOfPayload>}
     * @memberof RestResponseOfListMediaIds
     */
    'payload'?: Array<RestResponseOfListMediaIdsAllOfPayload>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListMediaIdsAllOf
 */
export interface RestResponseOfListMediaIdsAllOf {
    /**
     * 
     * @type {Array<RestResponseOfListMediaIdsAllOfPayload>}
     * @memberof RestResponseOfListMediaIdsAllOf
     */
    'payload'?: Array<RestResponseOfListMediaIdsAllOfPayload>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListMediaIdsAllOfPayload
 */
export interface RestResponseOfListMediaIdsAllOfPayload {
    /**
     * 
     * @type {number}
     * @memberof RestResponseOfListMediaIdsAllOfPayload
     */
    'mediaId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof RestResponseOfListMediaIdsAllOfPayload
     */
    'annotationId'?: Array<number>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfAnnotationDTO
 */
export interface RestResponseOfListOfAnnotationDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfAnnotationDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfAnnotationDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfAnnotationDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfAnnotationDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<AnnotationDTO>}
     * @memberof RestResponseOfListOfAnnotationDTO
     */
    'payload'?: Array<AnnotationDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfAnnotationDTOAllOf
 */
export interface RestResponseOfListOfAnnotationDTOAllOf {
    /**
     * 
     * @type {Array<AnnotationDTO>}
     * @memberof RestResponseOfListOfAnnotationDTOAllOf
     */
    'payload'?: Array<AnnotationDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfAnnotationResponseDTO
 */
export interface RestResponseOfListOfAnnotationResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfAnnotationResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfAnnotationResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfAnnotationResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfAnnotationResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {RestResponseOfListOfAnnotationResponseDTOAllOfPayload}
     * @memberof RestResponseOfListOfAnnotationResponseDTO
     */
    'payload'?: RestResponseOfListOfAnnotationResponseDTOAllOfPayload;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfAnnotationResponseDTOAllOf
 */
export interface RestResponseOfListOfAnnotationResponseDTOAllOf {
    /**
     * 
     * @type {RestResponseOfListOfAnnotationResponseDTOAllOfPayload}
     * @memberof RestResponseOfListOfAnnotationResponseDTOAllOf
     */
    'payload'?: RestResponseOfListOfAnnotationResponseDTOAllOfPayload;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfAnnotationResponseDTOAllOfPayload
 */
export interface RestResponseOfListOfAnnotationResponseDTOAllOfPayload {
    /**
     * 
     * @type {Array<AnnotationResponseDTO>}
     * @memberof RestResponseOfListOfAnnotationResponseDTOAllOfPayload
     */
    'annotations'?: Array<AnnotationResponseDTO>;
    /**
     * 
     * @type {Array<AnnotationRelationResponseDTO>}
     * @memberof RestResponseOfListOfAnnotationResponseDTOAllOfPayload
     */
    'annotationRelations'?: Array<AnnotationRelationResponseDTO>;
    /**
     * 
     * @type {Array<AnnotationVoteItem>}
     * @memberof RestResponseOfListOfAnnotationResponseDTOAllOfPayload
     */
    'annotationVotes'?: Array<AnnotationVoteItem>;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof RestResponseOfListOfAnnotationResponseDTOAllOfPayload
     */
    'annotationVoteRelations'?: Array<Array<number>>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfBatchAnnotatingResponseDTO
 */
export interface RestResponseOfListOfBatchAnnotatingResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfBatchAnnotatingResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfBatchAnnotatingResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfBatchAnnotatingResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {Array<BatchAnnotatingResponseDTO>}
     * @memberof RestResponseOfListOfBatchAnnotatingResponseDTO
     */
    'payload'?: Array<BatchAnnotatingResponseDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfBatchAnnotatingResponseDTO
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfBatchLabelerStatistic
 */
export interface RestResponseOfListOfBatchLabelerStatistic {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfBatchLabelerStatistic
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfBatchLabelerStatistic
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfBatchLabelerStatistic
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfBatchLabelerStatistic
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<BatchLabelerStatistic>}
     * @memberof RestResponseOfListOfBatchLabelerStatistic
     */
    'payload'?: Array<BatchLabelerStatistic>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfBatchLabelerStatisticAllOf
 */
export interface RestResponseOfListOfBatchLabelerStatisticAllOf {
    /**
     * 
     * @type {Array<BatchLabelerStatistic>}
     * @memberof RestResponseOfListOfBatchLabelerStatisticAllOf
     */
    'payload'?: Array<BatchLabelerStatistic>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfBatchObservationDTO
 */
export interface RestResponseOfListOfBatchObservationDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfBatchObservationDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfBatchObservationDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfBatchObservationDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfBatchObservationDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<BatchObservationDTO>}
     * @memberof RestResponseOfListOfBatchObservationDTO
     */
    'payload'?: Array<BatchObservationDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfBatchObservationDTOAllOf
 */
export interface RestResponseOfListOfBatchObservationDTOAllOf {
    /**
     * 
     * @type {Array<BatchObservationDTO>}
     * @memberof RestResponseOfListOfBatchObservationDTOAllOf
     */
    'payload'?: Array<BatchObservationDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfBatchResponseDTO
 */
export interface RestResponseOfListOfBatchResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfBatchResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfBatchResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfBatchResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfBatchResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<BatchResponseDTO>}
     * @memberof RestResponseOfListOfBatchResponseDTO
     */
    'payload'?: Array<BatchResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfBatchResponseDTOAllOf
 */
export interface RestResponseOfListOfBatchResponseDTOAllOf {
    /**
     * 
     * @type {Array<BatchResponseDTO>}
     * @memberof RestResponseOfListOfBatchResponseDTOAllOf
     */
    'payload'?: Array<BatchResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfIssueCommentResponseDTO
 */
export interface RestResponseOfListOfIssueCommentResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfIssueCommentResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfIssueCommentResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfIssueCommentResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfIssueCommentResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<IssueCommentResponseDTO>}
     * @memberof RestResponseOfListOfIssueCommentResponseDTO
     */
    'payload'?: Array<IssueCommentResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfIssueCommentResponseDTOAllOf
 */
export interface RestResponseOfListOfIssueCommentResponseDTOAllOf {
    /**
     * 
     * @type {Array<IssueCommentResponseDTO>}
     * @memberof RestResponseOfListOfIssueCommentResponseDTOAllOf
     */
    'payload'?: Array<IssueCommentResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfIssueCustomerResponseDTO
 */
export interface RestResponseOfListOfIssueCustomerResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfIssueCustomerResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfIssueCustomerResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfIssueCustomerResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfIssueCustomerResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<IssueCustomerResponseDTO>}
     * @memberof RestResponseOfListOfIssueCustomerResponseDTO
     */
    'payload'?: Array<IssueCustomerResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfIssueCustomerResponseDTOAllOf
 */
export interface RestResponseOfListOfIssueCustomerResponseDTOAllOf {
    /**
     * 
     * @type {Array<IssueCustomerResponseDTO>}
     * @memberof RestResponseOfListOfIssueCustomerResponseDTOAllOf
     */
    'payload'?: Array<IssueCustomerResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfIssueLabelerResponseDTO
 */
export interface RestResponseOfListOfIssueLabelerResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfIssueLabelerResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfIssueLabelerResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfIssueLabelerResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfIssueLabelerResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<IssueLabelerResponseDTO>}
     * @memberof RestResponseOfListOfIssueLabelerResponseDTO
     */
    'payload'?: Array<IssueLabelerResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfIssueLabelerResponseDTOAllOf
 */
export interface RestResponseOfListOfIssueLabelerResponseDTOAllOf {
    /**
     * 
     * @type {Array<IssueLabelerResponseDTO>}
     * @memberof RestResponseOfListOfIssueLabelerResponseDTOAllOf
     */
    'payload'?: Array<IssueLabelerResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfJobResponseDTO
 */
export interface RestResponseOfListOfJobResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfJobResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfJobResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfJobResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {Array<JobResponseDTO>}
     * @memberof RestResponseOfListOfJobResponseDTO
     */
    'payload'?: Array<JobResponseDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfJobResponseDTO
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfLabelDistribution
 */
export interface RestResponseOfListOfLabelDistribution {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfLabelDistribution
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfLabelDistribution
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfLabelDistribution
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfLabelDistribution
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<LabelDistribution>}
     * @memberof RestResponseOfListOfLabelDistribution
     */
    'payload'?: Array<LabelDistribution>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfLabelDistributionAllOf
 */
export interface RestResponseOfListOfLabelDistributionAllOf {
    /**
     * 
     * @type {Array<LabelDistribution>}
     * @memberof RestResponseOfListOfLabelDistributionAllOf
     */
    'payload'?: Array<LabelDistribution>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfLabelerAnnotationByBatch
 */
export interface RestResponseOfListOfLabelerAnnotationByBatch {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfLabelerAnnotationByBatch
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfLabelerAnnotationByBatch
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfLabelerAnnotationByBatch
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfLabelerAnnotationByBatch
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<AnnotationLabelerReport>}
     * @memberof RestResponseOfListOfLabelerAnnotationByBatch
     */
    'payload'?: Array<AnnotationLabelerReport>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfLabelerAnnotationByBatchAllOf
 */
export interface RestResponseOfListOfLabelerAnnotationByBatchAllOf {
    /**
     * 
     * @type {Array<AnnotationLabelerReport>}
     * @memberof RestResponseOfListOfLabelerAnnotationByBatchAllOf
     */
    'payload'?: Array<AnnotationLabelerReport>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfLabelerStatisticByBatch
 */
export interface RestResponseOfListOfLabelerStatisticByBatch {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfLabelerStatisticByBatch
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfLabelerStatisticByBatch
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfLabelerStatisticByBatch
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfLabelerStatisticByBatch
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<LabelerStatisticByBatch>}
     * @memberof RestResponseOfListOfLabelerStatisticByBatch
     */
    'payload'?: Array<LabelerStatisticByBatch>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfLabelerStatisticByBatchAllOf
 */
export interface RestResponseOfListOfLabelerStatisticByBatchAllOf {
    /**
     * 
     * @type {Array<LabelerStatisticByBatch>}
     * @memberof RestResponseOfListOfLabelerStatisticByBatchAllOf
     */
    'payload'?: Array<LabelerStatisticByBatch>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfMapOfStringAndObject
 */
export interface RestResponseOfListOfMapOfStringAndObject {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfMapOfStringAndObject
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfMapOfStringAndObject
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfMapOfStringAndObject
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfMapOfStringAndObject
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<{ [key: string]: object | undefined; }>}
     * @memberof RestResponseOfListOfMapOfStringAndObject
     */
    'payload'?: Array<{ [key: string]: object | undefined; }>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfMapOfStringAndObjectAllOf
 */
export interface RestResponseOfListOfMapOfStringAndObjectAllOf {
    /**
     * 
     * @type {Array<{ [key: string]: object | undefined; }>}
     * @memberof RestResponseOfListOfMapOfStringAndObjectAllOf
     */
    'payload'?: Array<{ [key: string]: object | undefined; }>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfObservationDTO
 */
export interface RestResponseOfListOfObservationDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfObservationDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfObservationDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfObservationDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfObservationDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<ObservationDTO>}
     * @memberof RestResponseOfListOfObservationDTO
     */
    'payload'?: Array<ObservationDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfObservationDTOAllOf
 */
export interface RestResponseOfListOfObservationDTOAllOf {
    /**
     * 
     * @type {Array<ObservationDTO>}
     * @memberof RestResponseOfListOfObservationDTOAllOf
     */
    'payload'?: Array<ObservationDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfProjectEssentialResponseDTO
 */
export interface RestResponseOfListOfProjectEssentialResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfProjectEssentialResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfProjectEssentialResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfProjectEssentialResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfProjectEssentialResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<ProjectEssentialResponseDTO>}
     * @memberof RestResponseOfListOfProjectEssentialResponseDTO
     */
    'payload'?: Array<ProjectEssentialResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfProjectEssentialResponseDTOAllOf
 */
export interface RestResponseOfListOfProjectEssentialResponseDTOAllOf {
    /**
     * 
     * @type {Array<ProjectEssentialResponseDTO>}
     * @memberof RestResponseOfListOfProjectEssentialResponseDTOAllOf
     */
    'payload'?: Array<ProjectEssentialResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfProjectResponseDTO
 */
export interface RestResponseOfListOfProjectResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfProjectResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfProjectResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfProjectResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfProjectResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<ProjectResponseDTO>}
     * @memberof RestResponseOfListOfProjectResponseDTO
     */
    'payload'?: Array<ProjectResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfProjectResponseDTOAllOf
 */
export interface RestResponseOfListOfProjectResponseDTOAllOf {
    /**
     * 
     * @type {Array<ProjectResponseDTO>}
     * @memberof RestResponseOfListOfProjectResponseDTOAllOf
     */
    'payload'?: Array<ProjectResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfRunInfo
 */
export interface RestResponseOfListOfRunInfo {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfRunInfo
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfRunInfo
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfRunInfo
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfRunInfo
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<RunInfoResponse>}
     * @memberof RestResponseOfListOfRunInfo
     */
    'payload'?: Array<RunInfoResponse>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfRunInfoAllOf
 */
export interface RestResponseOfListOfRunInfoAllOf {
    /**
     * 
     * @type {Array<RunInfoResponse>}
     * @memberof RestResponseOfListOfRunInfoAllOf
     */
    'payload'?: Array<RunInfoResponse>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfStepConditionDTO
 */
export interface RestResponseOfListOfStepConditionDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfStepConditionDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfStepConditionDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfStepConditionDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfStepConditionDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<StepConditionDTO>}
     * @memberof RestResponseOfListOfStepConditionDTO
     */
    'payload'?: Array<StepConditionDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfStepConditionDTOAllOf
 */
export interface RestResponseOfListOfStepConditionDTOAllOf {
    /**
     * 
     * @type {Array<StepConditionDTO>}
     * @memberof RestResponseOfListOfStepConditionDTOAllOf
     */
    'payload'?: Array<StepConditionDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfTaskObservationDTO
 */
export interface RestResponseOfListOfTaskObservationDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfTaskObservationDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfTaskObservationDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfTaskObservationDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfTaskObservationDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<TaskObservationDTO>}
     * @memberof RestResponseOfListOfTaskObservationDTO
     */
    'payload'?: Array<TaskObservationDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfTaskObservationDTOAllOf
 */
export interface RestResponseOfListOfTaskObservationDTOAllOf {
    /**
     * 
     * @type {Array<TaskObservationDTO>}
     * @memberof RestResponseOfListOfTaskObservationDTOAllOf
     */
    'payload'?: Array<TaskObservationDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfTaskObservationDTOV2
 */
export interface RestResponseOfListOfTaskObservationDTOV2 {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfTaskObservationDTOV2
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfTaskObservationDTOV2
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfTaskObservationDTOV2
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfTaskObservationDTOV2
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<TaskObservationDTOV2>}
     * @memberof RestResponseOfListOfTaskObservationDTOV2
     */
    'payload'?: Array<TaskObservationDTOV2>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfTaskObservationDTOV2AllOf
 */
export interface RestResponseOfListOfTaskObservationDTOV2AllOf {
    /**
     * 
     * @type {Array<TaskObservationDTOV2>}
     * @memberof RestResponseOfListOfTaskObservationDTOV2AllOf
     */
    'payload'?: Array<TaskObservationDTOV2>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfTaskObservationResponseDTO
 */
export interface RestResponseOfListOfTaskObservationResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfTaskObservationResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfTaskObservationResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfTaskObservationResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfTaskObservationResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<TaskObservationResponseDTO>}
     * @memberof RestResponseOfListOfTaskObservationResponseDTO
     */
    'payload'?: Array<TaskObservationResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfTaskObservationResponseDTOAllOf
 */
export interface RestResponseOfListOfTaskObservationResponseDTOAllOf {
    /**
     * 
     * @type {Array<TaskObservationResponseDTO>}
     * @memberof RestResponseOfListOfTaskObservationResponseDTOAllOf
     */
    'payload'?: Array<TaskObservationResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfTaskResponseDTO
 */
export interface RestResponseOfListOfTaskResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfTaskResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfTaskResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfTaskResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfTaskResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<TaskResponseDTO>}
     * @memberof RestResponseOfListOfTaskResponseDTO
     */
    'payload'?: Array<TaskResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfTaskResponseDTOAllOf
 */
export interface RestResponseOfListOfTaskResponseDTOAllOf {
    /**
     * 
     * @type {Array<TaskResponseDTO>}
     * @memberof RestResponseOfListOfTaskResponseDTOAllOf
     */
    'payload'?: Array<TaskResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfTrainingJobDTO
 */
export interface RestResponseOfListOfTrainingJobDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfTrainingJobDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfTrainingJobDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfTrainingJobDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfTrainingJobDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<TrainingJobDTO>}
     * @memberof RestResponseOfListOfTrainingJobDTO
     */
    'payload'?: Array<TrainingJobDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfTrainingJobDTOAllOf
 */
export interface RestResponseOfListOfTrainingJobDTOAllOf {
    /**
     * 
     * @type {Array<TrainingJobDTO>}
     * @memberof RestResponseOfListOfTrainingJobDTOAllOf
     */
    'payload'?: Array<TrainingJobDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfWorkInstructionResponseDTO
 */
export interface RestResponseOfListOfWorkInstructionResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfWorkInstructionResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfWorkInstructionResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfWorkInstructionResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfWorkInstructionResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<WorkInstructionResponseDTO>}
     * @memberof RestResponseOfListOfWorkInstructionResponseDTO
     */
    'payload'?: Array<WorkInstructionResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfWorkInstructionResponseDTOAllOf
 */
export interface RestResponseOfListOfWorkInstructionResponseDTOAllOf {
    /**
     * 
     * @type {Array<WorkInstructionResponseDTO>}
     * @memberof RestResponseOfListOfWorkInstructionResponseDTOAllOf
     */
    'payload'?: Array<WorkInstructionResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfWorkManagementDTO
 */
export interface RestResponseOfListOfWorkManagementDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfWorkManagementDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfWorkManagementDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfWorkManagementDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfWorkManagementDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<WorkManagementDTO>}
     * @memberof RestResponseOfListOfWorkManagementDTO
     */
    'payload'?: Array<WorkManagementDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfWorkManagementDTOAllOf
 */
export interface RestResponseOfListOfWorkManagementDTOAllOf {
    /**
     * 
     * @type {Array<WorkManagementDTO>}
     * @memberof RestResponseOfListOfWorkManagementDTOAllOf
     */
    'payload'?: Array<WorkManagementDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfWorkflowDTO
 */
export interface RestResponseOfListOfWorkflowDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfWorkflowDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfWorkflowDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfWorkflowDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfWorkflowDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<WorkflowDTO>}
     * @memberof RestResponseOfListOfWorkflowDTO
     */
    'payload'?: Array<WorkflowDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfWorkflowDTOAllOf
 */
export interface RestResponseOfListOfWorkflowDTOAllOf {
    /**
     * 
     * @type {Array<WorkflowDTO>}
     * @memberof RestResponseOfListOfWorkflowDTOAllOf
     */
    'payload'?: Array<WorkflowDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfstring
 */
export interface RestResponseOfListOfstring {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfstring
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfstring
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfstring
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfstring
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RestResponseOfListOfstring
     */
    'payload'?: Array<RestResponseOfListOfstringPayloadEnum>;
}

export const RestResponseOfListOfstringPayloadEnum = {
    audio: 'Audio',
    image: 'Image',
    medical_image: 'Medial Image',
    text: 'Text',
    video: 'Video'
} as const;

export type RestResponseOfListOfstringPayloadEnum = typeof RestResponseOfListOfstringPayloadEnum[keyof typeof RestResponseOfListOfstringPayloadEnum];

/**
 * 
 * @export
 * @interface RestResponseOfListOfstringAllOf
 */
export interface RestResponseOfListOfstringAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof RestResponseOfListOfstringAllOf
     */
    'payload'?: Array<RestResponseOfListOfstringAllOfPayloadEnum>;
}

export const RestResponseOfListOfstringAllOfPayloadEnum = {
    audio: 'Audio',
    image: 'Image',
    medical_image: 'Medial Image',
    text: 'Text',
    video: 'Video'
} as const;

export type RestResponseOfListOfstringAllOfPayloadEnum = typeof RestResponseOfListOfstringAllOfPayloadEnum[keyof typeof RestResponseOfListOfstringAllOfPayloadEnum];

/**
 * 
 * @export
 * @interface RestResponseOfLong
 */
export interface RestResponseOfLong {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfLong
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfLong
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfLong
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfLong
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RestResponseOfLong
     */
    'payload'?: number;
}
/**
 * 
 * @export
 * @interface RestResponseOfLongAllOf
 */
export interface RestResponseOfLongAllOf {
    /**
     * 
     * @type {number}
     * @memberof RestResponseOfLongAllOf
     */
    'payload'?: number;
}
/**
 * 
 * @export
 * @interface RestResponseOfManualAssignJobCsvResponse
 */
export interface RestResponseOfManualAssignJobCsvResponse {
    /**
     * 
     * @type {number}
     * @memberof RestResponseOfManualAssignJobCsvResponse
     */
    'jobId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfManualAssignJobCsvResponse
     */
    'status'?: RestResponseOfManualAssignJobCsvResponseStatusEnum;
}

export const RestResponseOfManualAssignJobCsvResponseStatusEnum = {
    Initial: 'INITIAL',
    Running: 'RUNNING',
    Succeeded: 'SUCCEEDED',
    Failed: 'FAILED',
    Canceled: 'CANCELED'
} as const;

export type RestResponseOfManualAssignJobCsvResponseStatusEnum = typeof RestResponseOfManualAssignJobCsvResponseStatusEnum[keyof typeof RestResponseOfManualAssignJobCsvResponseStatusEnum];

/**
 * 
 * @export
 * @interface RestResponseOfManualAssignJobResponse
 */
export interface RestResponseOfManualAssignJobResponse {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfManualAssignJobResponse
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfManualAssignJobResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {RestResponseOfManualAssignJobResponsePayload}
     * @memberof RestResponseOfManualAssignJobResponse
     */
    'payload'?: RestResponseOfManualAssignJobResponsePayload;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfManualAssignJobResponse
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseOfManualAssignJobResponsePayload
 */
export interface RestResponseOfManualAssignJobResponsePayload {
    /**
     * 
     * @type {object}
     * @memberof RestResponseOfManualAssignJobResponsePayload
     */
    'payload'?: object;
}
/**
 * 
 * @export
 * @interface RestResponseOfObject
 */
export interface RestResponseOfObject {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfObject
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfObject
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfObject
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfObject
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof RestResponseOfObject
     */
    'payload'?: object;
}
/**
 * 
 * @export
 * @interface RestResponseOfObservationDTO
 */
export interface RestResponseOfObservationDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfObservationDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfObservationDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfObservationDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfObservationDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {ObservationDTO}
     * @memberof RestResponseOfObservationDTO
     */
    'payload'?: ObservationDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfObservationDTOAllOf
 */
export interface RestResponseOfObservationDTOAllOf {
    /**
     * 
     * @type {ObservationDTO}
     * @memberof RestResponseOfObservationDTOAllOf
     */
    'payload'?: ObservationDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfObservationRequest
 */
export interface RestResponseOfObservationRequest {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfObservationRequest
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfObservationRequest
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfObservationRequest
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfObservationRequest
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {ObservationRequest}
     * @memberof RestResponseOfObservationRequest
     */
    'payload'?: ObservationRequest;
}
/**
 * 
 * @export
 * @interface RestResponseOfObservationRequestAllOf
 */
export interface RestResponseOfObservationRequestAllOf {
    /**
     * 
     * @type {ObservationRequest}
     * @memberof RestResponseOfObservationRequestAllOf
     */
    'payload'?: ObservationRequest;
}
/**
 * 
 * @export
 * @interface RestResponseOfProjectProgress
 */
export interface RestResponseOfProjectProgress {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfProjectProgress
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfProjectProgress
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfProjectProgress
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfProjectProgress
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {ProjectProgress}
     * @memberof RestResponseOfProjectProgress
     */
    'payload'?: ProjectProgress;
}
/**
 * 
 * @export
 * @interface RestResponseOfProjectProgressAllOf
 */
export interface RestResponseOfProjectProgressAllOf {
    /**
     * 
     * @type {ProjectProgress}
     * @memberof RestResponseOfProjectProgressAllOf
     */
    'payload'?: ProjectProgress;
}
/**
 * 
 * @export
 * @interface RestResponseOfProjectResponseDTO
 */
export interface RestResponseOfProjectResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfProjectResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfProjectResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfProjectResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfProjectResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {ProjectResponseDTO}
     * @memberof RestResponseOfProjectResponseDTO
     */
    'payload'?: ProjectResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfProjectResponseDTOAllOf
 */
export interface RestResponseOfProjectResponseDTOAllOf {
    /**
     * 
     * @type {ProjectResponseDTO}
     * @memberof RestResponseOfProjectResponseDTOAllOf
     */
    'payload'?: ProjectResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfProjectStatistic
 */
export interface RestResponseOfProjectStatistic {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfProjectStatistic
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfProjectStatistic
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfProjectStatistic
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfProjectStatistic
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {ProjectStatistic}
     * @memberof RestResponseOfProjectStatistic
     */
    'payload'?: ProjectStatistic;
}
/**
 * 
 * @export
 * @interface RestResponseOfProjectStatisticAllOf
 */
export interface RestResponseOfProjectStatisticAllOf {
    /**
     * 
     * @type {ProjectStatistic}
     * @memberof RestResponseOfProjectStatisticAllOf
     */
    'payload'?: ProjectStatistic;
}
/**
 * 
 * @export
 * @interface RestResponseOfProjectTemplateResponseDTO
 */
export interface RestResponseOfProjectTemplateResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfProjectTemplateResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfProjectTemplateResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfProjectTemplateResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfProjectTemplateResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {ProjectTemplateResponseDTO}
     * @memberof RestResponseOfProjectTemplateResponseDTO
     */
    'payload'?: ProjectTemplateResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfProjectTemplateResponseDTOAllOf
 */
export interface RestResponseOfProjectTemplateResponseDTOAllOf {
    /**
     * 
     * @type {ProjectTemplateResponseDTO}
     * @memberof RestResponseOfProjectTemplateResponseDTOAllOf
     */
    'payload'?: ProjectTemplateResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfProjectTemplateResponseDTOS
 */
export interface RestResponseOfProjectTemplateResponseDTOS {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfProjectTemplateResponseDTOS
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfProjectTemplateResponseDTOS
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfProjectTemplateResponseDTOS
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfProjectTemplateResponseDTOS
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<ProjectTemplateResponseDTO>}
     * @memberof RestResponseOfProjectTemplateResponseDTOS
     */
    'payload'?: Array<ProjectTemplateResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfProjectTemplateResponseDTOSAllOf
 */
export interface RestResponseOfProjectTemplateResponseDTOSAllOf {
    /**
     * 
     * @type {Array<ProjectTemplateResponseDTO>}
     * @memberof RestResponseOfProjectTemplateResponseDTOSAllOf
     */
    'payload'?: Array<ProjectTemplateResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfProjectType
 */
export interface RestResponseOfProjectType {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfProjectType
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfProjectType
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfProjectType
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfProjectType
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<ProjectTypeDTO>}
     * @memberof RestResponseOfProjectType
     */
    'payload'?: Array<ProjectTypeDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfProjectTypeAllOf
 */
export interface RestResponseOfProjectTypeAllOf {
    /**
     * 
     * @type {Array<ProjectTypeDTO>}
     * @memberof RestResponseOfProjectTypeAllOf
     */
    'payload'?: Array<ProjectTypeDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfRunInfo
 */
export interface RestResponseOfRunInfo {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfRunInfo
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfRunInfo
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfRunInfo
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfRunInfo
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {RunInfoResponse}
     * @memberof RestResponseOfRunInfo
     */
    'payload'?: RunInfoResponse;
}
/**
 * 
 * @export
 * @interface RestResponseOfRunInfoAllOf
 */
export interface RestResponseOfRunInfoAllOf {
    /**
     * 
     * @type {RunInfoResponse}
     * @memberof RestResponseOfRunInfoAllOf
     */
    'payload'?: RunInfoResponse;
}
/**
 * 
 * @export
 * @interface RestResponseOfStepConditionDTO
 */
export interface RestResponseOfStepConditionDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfStepConditionDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfStepConditionDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfStepConditionDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfStepConditionDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {StepConditionDTO}
     * @memberof RestResponseOfStepConditionDTO
     */
    'payload'?: StepConditionDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfStepConditionDTOAllOf
 */
export interface RestResponseOfStepConditionDTOAllOf {
    /**
     * 
     * @type {StepConditionDTO}
     * @memberof RestResponseOfStepConditionDTOAllOf
     */
    'payload'?: StepConditionDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfTaskObservationDTO
 */
export interface RestResponseOfTaskObservationDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfTaskObservationDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfTaskObservationDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfTaskObservationDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfTaskObservationDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {TaskObservationDTO}
     * @memberof RestResponseOfTaskObservationDTO
     */
    'payload'?: TaskObservationDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfTaskObservationDTOAllOf
 */
export interface RestResponseOfTaskObservationDTOAllOf {
    /**
     * 
     * @type {TaskObservationDTO}
     * @memberof RestResponseOfTaskObservationDTOAllOf
     */
    'payload'?: TaskObservationDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfTaskResponseDTO
 */
export interface RestResponseOfTaskResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfTaskResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfTaskResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfTaskResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfTaskResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {TaskResponseDTO}
     * @memberof RestResponseOfTaskResponseDTO
     */
    'payload'?: TaskResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfTaskResponseDTOAllOf
 */
export interface RestResponseOfTaskResponseDTOAllOf {
    /**
     * 
     * @type {TaskResponseDTO}
     * @memberof RestResponseOfTaskResponseDTOAllOf
     */
    'payload'?: TaskResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfTaskStatistic
 */
export interface RestResponseOfTaskStatistic {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfTaskStatistic
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfTaskStatistic
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfTaskStatistic
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfTaskStatistic
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {TaskStatistic}
     * @memberof RestResponseOfTaskStatistic
     */
    'payload'?: TaskStatistic;
}
/**
 * 
 * @export
 * @interface RestResponseOfTaskStatisticAllOf
 */
export interface RestResponseOfTaskStatisticAllOf {
    /**
     * 
     * @type {TaskStatistic}
     * @memberof RestResponseOfTaskStatisticAllOf
     */
    'payload'?: TaskStatistic;
}
/**
 * 
 * @export
 * @interface RestResponseOfThreeDSmoothingResponse
 */
export interface RestResponseOfThreeDSmoothingResponse {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfThreeDSmoothingResponse
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfThreeDSmoothingResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfThreeDSmoothingResponse
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfThreeDSmoothingResponse
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {ThreeDSmoothingResponse}
     * @memberof RestResponseOfThreeDSmoothingResponse
     */
    'payload'?: ThreeDSmoothingResponse;
}
/**
 * 
 * @export
 * @interface RestResponseOfThreeDSmoothingResponseAllOf
 */
export interface RestResponseOfThreeDSmoothingResponseAllOf {
    /**
     * 
     * @type {ThreeDSmoothingResponse}
     * @memberof RestResponseOfThreeDSmoothingResponseAllOf
     */
    'payload'?: ThreeDSmoothingResponse;
}
/**
 * 
 * @export
 * @interface RestResponseOfTrainingJobDTO
 */
export interface RestResponseOfTrainingJobDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfTrainingJobDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfTrainingJobDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfTrainingJobDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfTrainingJobDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {TrainingJobDTO}
     * @memberof RestResponseOfTrainingJobDTO
     */
    'payload'?: TrainingJobDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfTrainingJobDTOAllOf
 */
export interface RestResponseOfTrainingJobDTOAllOf {
    /**
     * 
     * @type {TrainingJobDTO}
     * @memberof RestResponseOfTrainingJobDTOAllOf
     */
    'payload'?: TrainingJobDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfWorkInstructionResponseDTO
 */
export interface RestResponseOfWorkInstructionResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfWorkInstructionResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfWorkInstructionResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfWorkInstructionResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfWorkInstructionResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {WorkInstructionResponseDTO}
     * @memberof RestResponseOfWorkInstructionResponseDTO
     */
    'payload'?: WorkInstructionResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfWorkInstructionResponseDTOAllOf
 */
export interface RestResponseOfWorkInstructionResponseDTOAllOf {
    /**
     * 
     * @type {WorkInstructionResponseDTO}
     * @memberof RestResponseOfWorkInstructionResponseDTOAllOf
     */
    'payload'?: WorkInstructionResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfWorkManagementDTO
 */
export interface RestResponseOfWorkManagementDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfWorkManagementDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfWorkManagementDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfWorkManagementDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfWorkManagementDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {WorkManagementDTO}
     * @memberof RestResponseOfWorkManagementDTO
     */
    'payload'?: WorkManagementDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfWorkManagementDTOAllOf
 */
export interface RestResponseOfWorkManagementDTOAllOf {
    /**
     * 
     * @type {WorkManagementDTO}
     * @memberof RestResponseOfWorkManagementDTOAllOf
     */
    'payload'?: WorkManagementDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfWorkflowDTO
 */
export interface RestResponseOfWorkflowDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfWorkflowDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfWorkflowDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfWorkflowDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfWorkflowDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {WorkflowDTO}
     * @memberof RestResponseOfWorkflowDTO
     */
    'payload'?: WorkflowDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfWorkflowDTOAllOf
 */
export interface RestResponseOfWorkflowDTOAllOf {
    /**
     * 
     * @type {WorkflowDTO}
     * @memberof RestResponseOfWorkflowDTOAllOf
     */
    'payload'?: WorkflowDTO;
}
/**
 * 
 * @export
 * @interface RunInfoResponse
 */
export interface RunInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof RunInfoResponse
     */
    'run_id'?: string;
    /**
     * 
     * @type {Array<RunMetric>}
     * @memberof RunInfoResponse
     */
    'metrics'?: Array<RunMetric>;
    /**
     * 
     * @type {string}
     * @memberof RunInfoResponse
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof RunInfoResponse
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof RunInfoResponse
     */
    'platform'?: string;
    /**
     * 
     * @type {Pipeline}
     * @memberof RunInfoResponse
     */
    'pipeline'?: Pipeline;
    /**
     * 
     * @type {Array<RunInput>}
     * @memberof RunInfoResponse
     */
    'run_inputs'?: Array<RunInput>;
}
/**
 * 
 * @export
 * @interface RunInput
 */
export interface RunInput {
    /**
     * 
     * @type {string}
     * @memberof RunInput
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RunInput
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface RunMetric
 */
export interface RunMetric {
    /**
     * 
     * @type {string}
     * @memberof RunMetric
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RunMetric
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface RunRequest
 */
export interface RunRequest {
    /**
     * 
     * @type {string}
     * @memberof RunRequest
     */
    'pipelineId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RunRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RunRequest
     */
    'priority'?: RunRequestPriorityEnum;
    /**
     * 
     * @type {Array<RunInput>}
     * @memberof RunRequest
     */
    'input'?: Array<RunInput>;
}

export const RunRequestPriorityEnum = {
    Low: 'low',
    High: 'high'
} as const;

export type RunRequestPriorityEnum = typeof RunRequestPriorityEnum[keyof typeof RunRequestPriorityEnum];

/**
 * 
 * @export
 * @interface SkipTasksRequest
 */
export interface SkipTasksRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof SkipTasksRequest
     */
    'taskIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface SliceAnnotationItem
 */
export interface SliceAnnotationItem {
    /**
     * 
     * @type {Array<AnnotationAttributeItem>}
     * @memberof SliceAnnotationItem
     */
    'attributes'?: Array<AnnotationAttributeItem>;
    /**
     * 
     * @type {string}
     * @memberof SliceAnnotationItem
     */
    'note'?: string;
    /**
     * id of audio resource (StorageResource on storage-service)
     * @type {string}
     * @memberof SliceAnnotationItem
     */
    'resourceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SliceAnnotationItem
     */
    'observationId'?: number;
    /**
     * 
     * @type {Array<SliceAnnotationItemAllOfMask>}
     * @memberof SliceAnnotationItem
     */
    'mask'?: Array<SliceAnnotationItemAllOfMask>;
}
/**
 * 
 * @export
 * @interface SliceAnnotationItemAllOf
 */
export interface SliceAnnotationItemAllOf {
    /**
     * 
     * @type {string}
     * @memberof SliceAnnotationItemAllOf
     */
    'note'?: string;
    /**
     * id of audio resource (StorageResource on storage-service)
     * @type {string}
     * @memberof SliceAnnotationItemAllOf
     */
    'resourceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SliceAnnotationItemAllOf
     */
    'observationId'?: number;
    /**
     * 
     * @type {Array<SliceAnnotationItemAllOfMask>}
     * @memberof SliceAnnotationItemAllOf
     */
    'mask'?: Array<SliceAnnotationItemAllOfMask>;
}
/**
 * 
 * @export
 * @interface SliceAnnotationItemAllOfMask
 */
export interface SliceAnnotationItemAllOfMask {
    /**
     * 
     * @type {number}
     * @memberof SliceAnnotationItemAllOfMask
     */
    'estimatedSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof SliceAnnotationItemAllOfMask
     */
    'maskValue'?: number;
    /**
     * 
     * @type {Array<AnnotationAttributeItem>}
     * @memberof SliceAnnotationItemAllOfMask
     */
    'attributes'?: Array<AnnotationAttributeItem>;
    /**
     * 
     * @type {number}
     * @memberof SliceAnnotationItemAllOfMask
     */
    'observationId'?: number;
}
/**
 * 
 * @export
 * @interface StepConditionDTO
 */
export interface StepConditionDTO {
    /**
     * 
     * @type {string}
     * @memberof StepConditionDTO
     */
    'code'?: string;
    /**
     * 
     * @type {number}
     * @memberof StepConditionDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof StepConditionDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof StepConditionDTO
     */
    'requireConfig'?: string;
    /**
     * 
     * @type {string}
     * @memberof StepConditionDTO
     */
    'projectType'?: string;
}
/**
 * 
 * @export
 * @interface SttAnnotationItem
 */
export interface SttAnnotationItem {
    /**
     * 
     * @type {Array<AnnotationAttributeItem>}
     * @memberof SttAnnotationItem
     */
    'attributes'?: Array<AnnotationAttributeItem>;
    /**
     * 
     * @type {string}
     * @memberof SttAnnotationItem
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof SttAnnotationItem
     */
    'note'?: string;
    /**
     * 
     * @type {Array<SttTextLabel>}
     * @memberof SttAnnotationItem
     */
    'textLabels'?: Array<SttTextLabel>;
    /**
     * 
     * @type {number}
     * @memberof SttAnnotationItem
     */
    'observationId'?: number;
}
/**
 * 
 * @export
 * @interface SttAnnotationItemAllOf
 */
export interface SttAnnotationItemAllOf {
    /**
     * 
     * @type {string}
     * @memberof SttAnnotationItemAllOf
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof SttAnnotationItemAllOf
     */
    'note'?: string;
    /**
     * 
     * @type {Array<SttTextLabel>}
     * @memberof SttAnnotationItemAllOf
     */
    'textLabels'?: Array<SttTextLabel>;
    /**
     * 
     * @type {number}
     * @memberof SttAnnotationItemAllOf
     */
    'observationId'?: number;
}
/**
 * 
 * @export
 * @interface SttTextLabel
 */
export interface SttTextLabel {
    /**
     * 
     * @type {number}
     * @memberof SttTextLabel
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof SttTextLabel
     */
    'end'?: number;
    /**
     * 
     * @type {number}
     * @memberof SttTextLabel
     */
    'observationId'?: number;
}
/**
 * 
 * @export
 * @interface Summary
 */
export interface Summary {
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof Summary
     */
    'avgIou'?: { [key: string]: number | undefined; };
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof Summary
     */
    'avgProbability'?: { [key: string]: number | undefined; };
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof Summary
     */
    'classificationAgree'?: { [key: string]: number | undefined; };
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof Summary
     */
    'f1score'?: { [key: string]: number | undefined; };
    /**
     * 
     * @type {number}
     * @memberof Summary
     */
    'finishedTask'?: number;
    /**
     * 
     * @type {string}
     * @memberof Summary
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof Summary
     */
    'segmentationAgree'?: { [key: string]: number | undefined; };
    /**
     * 
     * @type {string}
     * @memberof Summary
     */
    'startDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Summary
     */
    'taskPerHour'?: number;
}
/**
 * 
 * @export
 * @interface TagDTO
 */
export interface TagDTO {
    /**
     * 
     * @type {string}
     * @memberof TagDTO
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TagDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagDTO
     */
    'lastModifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof TagDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagDTO
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface TaskCriteria
 */
export interface TaskCriteria {
    /**
     * 
     * @type {number}
     * @memberof TaskCriteria
     */
    'id.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'id.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskCriteria
     */
    'batchId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'batchId.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'createdDate.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'createdDate.greaterThan'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'createdDate.lessThan'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskCriteria
     */
    'taskReference.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'taskReference.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'fileName.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'fileName.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'fileName.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'assignee.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'assignee.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'assignee.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'stepType.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'stepType.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'stepType.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'status.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'status.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'status.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'reviewStatus.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'reviewStatus.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'reviewStatus.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'issueStatus.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'issueStatus.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'issueStatus.contains'?: string;
}
/**
 * 
 * @export
 * @interface TaskJobStatisticDTO
 */
export interface TaskJobStatisticDTO {
    /**
     * 
     * @type {number}
     * @memberof TaskJobStatisticDTO
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskJobStatisticDTO
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface TaskObservationCriteria
 */
export interface TaskObservationCriteria {
    /**
     * 
     * @type {number}
     * @memberof TaskObservationCriteria
     */
    'id.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskObservationCriteria
     */
    'id.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskObservationCriteria
     */
    'taskId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskObservationCriteria
     */
    'taskId.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskObservationCriteria
     */
    'jobId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskObservationCriteria
     */
    'jobId.in'?: string;
}
/**
 * 
 * @export
 * @interface TaskObservationDTO
 */
export interface TaskObservationDTO {
    /**
     * 
     * @type {Array<AnnotationAgreementDTO>}
     * @memberof TaskObservationDTO
     */
    'annotationAgreementDTOS'?: Array<AnnotationAgreementDTO>;
    /**
     * 
     * @type {Array<RawImageAnnotationItem>}
     * @memberof TaskObservationDTO
     */
    'annotations'?: Array<RawImageAnnotationItem>;
    /**
     * 
     * @type {string}
     * @memberof TaskObservationDTO
     */
    'assignee'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskObservationDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskObservationDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskObservationDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskObservationDTO
     */
    'jobId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskObservationDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskObservationDTO
     */
    'observationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskObservationDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskObservationDTO
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface TaskObservationDTOV2
 */
export interface TaskObservationDTOV2 {
    /**
     * 
     * @type {Array<AnnotationAgreementDTO>}
     * @memberof TaskObservationDTOV2
     */
    'annotationAgreementDTOS'?: Array<AnnotationAgreementDTO>;
    /**
     * 
     * @type {Array<RawAnnotationItemV2>}
     * @memberof TaskObservationDTOV2
     */
    'annotations'?: Array<RawAnnotationItemV2>;
    /**
     * 
     * @type {string}
     * @memberof TaskObservationDTOV2
     */
    'annotationType'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskObservationDTOV2
     */
    'jobId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskObservationDTOV2
     */
    'assignee'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskObservationDTOV2
     */
    'taskId'?: number;
}
/**
 * 
 * @export
 * @interface TaskObservationRequestDTO
 */
export interface TaskObservationRequestDTO {
    /**
     * 
     * @type {Array<AnnotationRequestDTO>}
     * @memberof TaskObservationRequestDTO
     */
    'annotations': Array<AnnotationRequestDTO>;
    /**
     * 
     * @type {Array<AnnotationRelationRequestDTO>}
     * @memberof TaskObservationRequestDTO
     */
    'annotationRelations'?: Array<AnnotationRelationRequestDTO>;
    /**
     * 
     * @type {number}
     * @memberof TaskObservationRequestDTO
     */
    'jobId'?: number;
}
/**
 * 
 * @export
 * @interface TaskObservationResponseDTO
 */
export interface TaskObservationResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof TaskObservationResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {Array<AnnotationAgreementDTO>}
     * @memberof TaskObservationResponseDTO
     */
    'annotationAgreementDTOS'?: Array<AnnotationAgreementDTO>;
    /**
     * 
     * @type {Array<AnnotationResponseDTO>}
     * @memberof TaskObservationResponseDTO
     */
    'annotations'?: Array<AnnotationResponseDTO>;
    /**
     * 
     * @type {Array<AnnotationRelationResponseDTO>}
     * @memberof TaskObservationResponseDTO
     */
    'annotationRelations'?: Array<AnnotationRelationResponseDTO>;
    /**
     * 
     * @type {number}
     * @memberof TaskObservationResponseDTO
     */
    'jobId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskObservationResponseDTO
     */
    'assignee'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskObservationResponseDTO
     */
    'taskId'?: number;
}
/**
 * 
 * @export
 * @interface TaskRequestDTO
 */
export interface TaskRequestDTO {
    /**
     * 
     * @type {BatchResponseDTO}
     * @memberof TaskRequestDTO
     */
    'batch'?: BatchResponseDTO;
    /**
     * 
     * @type {string}
     * @memberof TaskRequestDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRequestDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRequestDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskRequestDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskRequestDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRequestDTO
     */
    'storeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRequestDTO
     */
    'taskReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRequestDTO
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRequestDTO
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskRequestDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskRequestDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskRequestDTO
     */
    'assignee'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TaskRequestDTO
     */
    'reviewStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRequestDTO
     */
    'issueStatus'?: string;
    /**
     * 
     * @type {TaskResponseDTOIssueStat}
     * @memberof TaskRequestDTO
     */
    'issueStat'?: TaskResponseDTOIssueStat;
}
/**
 * 
 * @export
 * @interface TaskResponseDTO
 */
export interface TaskResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof TaskResponseDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'storeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'taskReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskResponseDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskResponseDTO
     */
    'assignee'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'reviewStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'issueStatus'?: string;
    /**
     * 
     * @type {TaskResponseDTOIssueStat}
     * @memberof TaskResponseDTO
     */
    'issueStat'?: TaskResponseDTOIssueStat;
}
/**
 * 
 * @export
 * @interface TaskResponseDTOIssueStat
 */
export interface TaskResponseDTOIssueStat {
    /**
     * 
     * @type {number}
     * @memberof TaskResponseDTOIssueStat
     */
    'opened'?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskResponseDTOIssueStat
     */
    'fixed'?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskResponseDTOIssueStat
     */
    'resolved'?: number;
}
/**
 * 
 * @export
 * @interface TaskRevokeDTO
 */
export interface TaskRevokeDTO {
    /**
     * 
     * @type {number}
     * @memberof TaskRevokeDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof TaskRevokeDTO
     */
    'taskIds'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskRevokeDTO
     */
    'assignees'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TaskStatistic
 */
export interface TaskStatistic {
    /**
     * 
     * @type {string}
     * @memberof TaskStatistic
     */
    'lastUpdate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskStatistic
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface TextAnnotationItem
 */
export interface TextAnnotationItem {
    /**
     * 
     * @type {Array<AnnotationAttributeItem>}
     * @memberof TextAnnotationItem
     */
    'attributes'?: Array<AnnotationAttributeItem>;
    /**
     * 
     * @type {Array<TextLabel>}
     * @memberof TextAnnotationItem
     */
    'labels'?: Array<TextLabel>;
    /**
     * 
     * @type {Array<TextRelation>}
     * @memberof TextAnnotationItem
     */
    'relations'?: Array<TextRelation>;
    /**
     * 
     * @type {number}
     * @memberof TextAnnotationItem
     */
    'observationId'?: number;
    /**
     * 
     * @type {Array<TextConflictDTO>}
     * @memberof TextAnnotationItem
     */
    'conflictInfos'?: Array<TextConflictDTO>;
}
/**
 * 
 * @export
 * @interface TextAnnotationItemAllOf
 */
export interface TextAnnotationItemAllOf {
    /**
     * 
     * @type {Array<TextLabel>}
     * @memberof TextAnnotationItemAllOf
     */
    'labels'?: Array<TextLabel>;
    /**
     * 
     * @type {Array<TextRelation>}
     * @memberof TextAnnotationItemAllOf
     */
    'relations'?: Array<TextRelation>;
    /**
     * 
     * @type {number}
     * @memberof TextAnnotationItemAllOf
     */
    'observationId'?: number;
    /**
     * 
     * @type {Array<TextConflictDTO>}
     * @memberof TextAnnotationItemAllOf
     */
    'conflictInfos'?: Array<TextConflictDTO>;
}
/**
 * 
 * @export
 * @interface TextAnnotationItemV2
 */
export interface TextAnnotationItemV2 {
    /**
     * 
     * @type {Array<AnnotationAttributeItem>}
     * @memberof TextAnnotationItemV2
     */
    'attributes'?: Array<AnnotationAttributeItem>;
    /**
     * 
     * @type {number}
     * @memberof TextAnnotationItemV2
     */
    'startIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextAnnotationItemV2
     */
    'endIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextAnnotationItemV2
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextAnnotationItemV2
     */
    'observationId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TextAnnotationItemV2
     */
    'matched'?: boolean;
}
/**
 * 
 * @export
 * @interface TextConflictDTO
 */
export interface TextConflictDTO {
    /**
     * 
     * @type {number}
     * @memberof TextConflictDTO
     */
    'jobId'?: number;
    /**
     * 
     * @type {Array<TextLabelConflictCause>}
     * @memberof TextConflictDTO
     */
    'labels'?: Array<TextLabelConflictCause>;
    /**
     * 
     * @type {Array<TextRelationConflictCause>}
     * @memberof TextConflictDTO
     */
    'relations'?: Array<TextRelationConflictCause>;
}
/**
 * 
 * @export
 * @interface TextIssueData
 */
export interface TextIssueData {
    /**
     * start index value
     * @type {number}
     * @memberof TextIssueData
     */
    'startIndex'?: number;
    /**
     * end index value
     * @type {number}
     * @memberof TextIssueData
     */
    'endIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextIssueData
     */
    'annotationId'?: number;
}
/**
 * 
 * @export
 * @interface TextLabel
 */
export interface TextLabel {
    /**
     * 
     * @type {number}
     * @memberof TextLabel
     */
    'startIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextLabel
     */
    'endIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextLabel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextLabel
     */
    'observationId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TextLabel
     */
    'matched'?: boolean;
}
/**
 * 
 * @export
 * @interface TextLabelConflictCause
 */
export interface TextLabelConflictCause {
    /**
     * 
     * @type {Array<TextLabelConflictSlice>}
     * @memberof TextLabelConflictCause
     */
    'slices'?: Array<TextLabelConflictSlice>;
    /**
     * 
     * @type {string}
     * @memberof TextLabelConflictCause
     */
    'type'?: TextLabelConflictCauseTypeEnum;
}

export const TextLabelConflictCauseTypeEnum = {
    LabelDisagree: 'label_disagree',
    IntersectLabelDisagree: 'intersect_label_disagree',
    IntersectLabelAgree: 'intersect_label_agree',
    NonIntersect: 'non_intersect'
} as const;

export type TextLabelConflictCauseTypeEnum = typeof TextLabelConflictCauseTypeEnum[keyof typeof TextLabelConflictCauseTypeEnum];

/**
 * 
 * @export
 * @interface TextLabelConflictSlice
 */
export interface TextLabelConflictSlice {
    /**
     * 
     * @type {number}
     * @memberof TextLabelConflictSlice
     */
    'startIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextLabelConflictSlice
     */
    'endIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextLabelConflictSlice
     */
    'observationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TextLabelConflictSlice
     */
    'side'?: TextLabelConflictSliceSideEnum;
}

export const TextLabelConflictSliceSideEnum = {
    Base: 'base',
    Other: 'other'
} as const;

export type TextLabelConflictSliceSideEnum = typeof TextLabelConflictSliceSideEnum[keyof typeof TextLabelConflictSliceSideEnum];

/**
 * 
 * @export
 * @interface TextObservationRequest
 */
export interface TextObservationRequest {
    /**
     * 
     * @type {number}
     * @memberof TextObservationRequest
     */
    'jobId'?: number;
    /**
     * 
     * @type {TextAnnotationItem}
     * @memberof TextObservationRequest
     */
    'annotation'?: TextAnnotationItem;
}
/**
 * 
 * @export
 * @interface TextRelation
 */
export interface TextRelation {
    /**
     * 
     * @type {number}
     * @memberof TextRelation
     */
    'fromId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextRelation
     */
    'toId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextRelation
     */
    'observationId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TextRelation
     */
    'matched'?: boolean;
}
/**
 * 
 * @export
 * @interface TextRelationConflictCause
 */
export interface TextRelationConflictCause {
    /**
     * 
     * @type {TextLabel}
     * @memberof TextRelationConflictCause
     */
    'firstLabel'?: TextLabel;
    /**
     * 
     * @type {TextLabel}
     * @memberof TextRelationConflictCause
     */
    'secondLabel'?: TextLabel;
    /**
     * 
     * @type {number}
     * @memberof TextRelationConflictCause
     */
    'observationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TextRelationConflictCause
     */
    'side'?: TextRelationConflictCauseSideEnum;
    /**
     * 
     * @type {string}
     * @memberof TextRelationConflictCause
     */
    'type'?: TextRelationConflictCauseTypeEnum;
}

export const TextRelationConflictCauseSideEnum = {
    Base: 'base',
    Other: 'other'
} as const;

export type TextRelationConflictCauseSideEnum = typeof TextRelationConflictCauseSideEnum[keyof typeof TextRelationConflictCauseSideEnum];
export const TextRelationConflictCauseTypeEnum = {
    Observation: 'observation',
    NotAppear: 'not_appear'
} as const;

export type TextRelationConflictCauseTypeEnum = typeof TextRelationConflictCauseTypeEnum[keyof typeof TextRelationConflictCauseTypeEnum];

/**
 * 
 * @export
 * @interface ThreeDAnnotationSmoothingPayloadResponse
 */
export interface ThreeDAnnotationSmoothingPayloadResponse {
    /**
     * 
     * @type {string}
     * @memberof ThreeDAnnotationSmoothingPayloadResponse
     */
    'task'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDAnnotationSmoothingPayloadResponse
     */
    'method'?: string;
    /**
     * 
     * @type {object}
     * @memberof ThreeDAnnotationSmoothingPayloadResponse
     */
    'parameters'?: object;
    /**
     * 
     * @type {string}
     * @memberof ThreeDAnnotationSmoothingPayloadResponse
     */
    'sourceStorageResourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDAnnotationSmoothingPayloadResponse
     */
    'outputUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDAnnotationSmoothingPayloadResponse
     */
    'blobName'?: string;
}
/**
 * 
 * @export
 * @interface ThreeDSmoothingGaussianParam
 */
export interface ThreeDSmoothingGaussianParam {
    /**
     * 
     * @type {number}
     * @memberof ThreeDSmoothingGaussianParam
     */
    'GaussianStandardDeviationMm'?: number;
}
/**
 * 
 * @export
 * @interface ThreeDSmoothingJointTaubinParam
 */
export interface ThreeDSmoothingJointTaubinParam {
    /**
     * 
     * @type {number}
     * @memberof ThreeDSmoothingJointTaubinParam
     */
    'JointTaubinSmoothingFactor'?: number;
}
/**
 * 
 * @export
 * @interface ThreeDSmoothingMedianParam
 */
export interface ThreeDSmoothingMedianParam {
    /**
     * 
     * @type {number}
     * @memberof ThreeDSmoothingMedianParam
     */
    'KernelSizeMm'?: number;
}
/**
 * 
 * @export
 * @interface ThreeDSmoothingRequest
 */
export interface ThreeDSmoothingRequest {
    /**
     * 
     * @type {number}
     * @memberof ThreeDSmoothingRequest
     */
    'jobId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ThreeDSmoothingRequest
     */
    'storageResourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDSmoothingRequest
     */
    'method'?: ThreeDSmoothingRequestMethodEnum;
    /**
     * 
     * @type {Parameter}
     * @memberof ThreeDSmoothingRequest
     */
    'parameters'?: Parameter;
}

export const ThreeDSmoothingRequestMethodEnum = {
    Gaussian: 'GAUSSIAN',
    Median: 'MEDIAN',
    JointTaubin: 'JOINT_TAUBIN'
} as const;

export type ThreeDSmoothingRequestMethodEnum = typeof ThreeDSmoothingRequestMethodEnum[keyof typeof ThreeDSmoothingRequestMethodEnum];

/**
 * 
 * @export
 * @interface ThreeDSmoothingResponse
 */
export interface ThreeDSmoothingResponse {
    /**
     * 
     * @type {number}
     * @memberof ThreeDSmoothingResponse
     */
    'asyncJobId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ThreeDSmoothingResponse
     */
    'status'?: ThreeDSmoothingResponseStatusEnum;
}

export const ThreeDSmoothingResponseStatusEnum = {
    Initial: 'INITIAL',
    Running: 'RUNNING',
    Succeeded: 'SUCCEEDED',
    Failed: 'FAILED',
    Canceled: 'CANCELED'
} as const;

export type ThreeDSmoothingResponseStatusEnum = typeof ThreeDSmoothingResponseStatusEnum[keyof typeof ThreeDSmoothingResponseStatusEnum];

/**
 * 
 * @export
 * @interface TrainingJobDTO
 */
export interface TrainingJobDTO {
    /**
     * 
     * @type {string}
     * @memberof TrainingJobDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingJobDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingJobDTO
     */
    'description'?: string;
    /**
     * 
     * @type {Pipeline}
     * @memberof TrainingJobDTO
     */
    'pipeline'?: Pipeline;
    /**
     * 
     * @type {Array<RunInput>}
     * @memberof TrainingJobDTO
     */
    'runInputs'?: Array<RunInput>;
    /**
     * 
     * @type {string}
     * @memberof TrainingJobDTO
     */
    'id'?: string;
    /**
     * 
     * @type {Array<RunMetric>}
     * @memberof TrainingJobDTO
     */
    'metrics'?: Array<RunMetric>;
    /**
     * url of archived file of visualization (image, html or markdown)
     * @type {string}
     * @memberof TrainingJobDTO
     */
    'visualization'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingJobDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingJobDTO
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface TrainingJobParameter
 */
export interface TrainingJobParameter {
    /**
     * 
     * @type {number}
     * @memberof TrainingJobParameter
     */
    'dataGenerationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingJobParameter
     */
    'epochs'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingJobParameter
     */
    'modelName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingJobParameter
     */
    'modelVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingJobParameter
     */
    'pipelineId'?: string;
}
/**
 * 
 * @export
 * @interface TtsAnnotationItem
 */
export interface TtsAnnotationItem {
    /**
     * 
     * @type {Array<AnnotationAttributeItem>}
     * @memberof TtsAnnotationItem
     */
    'attributes'?: Array<AnnotationAttributeItem>;
    /**
     * id of audio resource (StorageResource on storage-service)
     * @type {string}
     * @memberof TtsAnnotationItem
     */
    'audioResourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TtsAnnotationItem
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof TtsAnnotationItem
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof TtsAnnotationItem
     */
    'observationId'?: number;
}
/**
 * 
 * @export
 * @interface TtsAnnotationItemAllOf
 */
export interface TtsAnnotationItemAllOf {
    /**
     * id of audio resource (StorageResource on storage-service)
     * @type {string}
     * @memberof TtsAnnotationItemAllOf
     */
    'audioResourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TtsAnnotationItemAllOf
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof TtsAnnotationItemAllOf
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof TtsAnnotationItemAllOf
     */
    'observationId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateIssueCustomerRequestDTO
 */
export interface UpdateIssueCustomerRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateIssueCustomerRequestDTO
     */
    'description'?: string;
    /**
     * 
     * @type {IssueData}
     * @memberof UpdateIssueCustomerRequestDTO
     */
    'data'?: IssueData;
    /**
     * id of observation that this issue refer to (if needed)
     * @type {number}
     * @memberof UpdateIssueCustomerRequestDTO
     */
    'referenceId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateIssueReviewerRequestDTO
 */
export interface UpdateIssueReviewerRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateIssueReviewerRequestDTO
     */
    'description'?: string;
    /**
     * 
     * @type {IssueData}
     * @memberof UpdateIssueReviewerRequestDTO
     */
    'data'?: IssueData;
    /**
     * id of annotation that this issue refer to (if needed)
     * @type {number}
     * @memberof UpdateIssueReviewerRequestDTO
     */
    'referenceId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateStatusBatchRequest
 */
export interface UpdateStatusBatchRequest {
    /**
     * value of batchStatus, refer at Constants.batchStatus
     * @type {string}
     * @memberof UpdateStatusBatchRequest
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface UserStatistic
 */
export interface UserStatistic {
    /**
     * 
     * @type {string}
     * @memberof UserStatistic
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserStatistic
     */
    'totalAnnotationTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserStatistic
     */
    'totalIssue'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserStatistic
     */
    'avgTimePerAnnotation'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserStatistic
     */
    'avgTimePerJob'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserStatistic
     */
    'avgAnnotationPerJob'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserStatistic
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserStatistic
     */
    'startDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserStatistic
     */
    'totalJob'?: number;
    /**
     * 
     * @type {Array<UserStatisticGroup>}
     * @memberof UserStatistic
     */
    'groups'?: Array<UserStatisticGroup>;
    /**
     * 
     * @type {string}
     * @memberof UserStatistic
     */
    'projectType'?: string;
}
/**
 * 
 * @export
 * @interface UserStatisticGroup
 */
export interface UserStatisticGroup {
    /**
     * 
     * @type {string}
     * @memberof UserStatisticGroup
     */
    'group'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserStatisticGroup
     */
    'totalCase'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserStatisticGroup
     */
    'totalRelation'?: number;
}
/**
 * 
 * @export
 * @interface VideoAnnotationItem
 */
export interface VideoAnnotationItem {
    /**
     * 
     * @type {number}
     * @memberof VideoAnnotationItem
     */
    'frameId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoAnnotationItem
     */
    'startFrameId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoAnnotationItem
     */
    'endFrameId'?: number;
    /**
     * 
     * @type {Array<AnnotationAttributeItem>}
     * @memberof VideoAnnotationItem
     */
    'attributes'?: Array<AnnotationAttributeItem>;
    /**
     * uuid generated by client-side, scope by job
     * @type {string}
     * @memberof VideoAnnotationItem
     */
    'referenceId'?: string;
    /**
     * 
     * @type {BBox}
     * @memberof VideoAnnotationItem
     */
    'bbox'?: BBox;
    /**
     * 
     * @type {Array<Polygon>}
     * @memberof VideoAnnotationItem
     */
    'polygons'?: Array<Polygon>;
}
/**
 * use frameId for a specific frame or (startFrameId, endFrameId) inclusive to specify the range of frames to be annotated 
 * @export
 * @interface VideoAnnotationItemAllOf
 */
export interface VideoAnnotationItemAllOf {
    /**
     * 
     * @type {number}
     * @memberof VideoAnnotationItemAllOf
     */
    'frameId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoAnnotationItemAllOf
     */
    'startFrameId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoAnnotationItemAllOf
     */
    'endFrameId'?: number;
}
/**
 * 
 * @export
 * @interface WorkInstructionRequestDTO
 */
export interface WorkInstructionRequestDTO {
    /**
     * 
     * @type {boolean}
     * @memberof WorkInstructionRequestDTO
     */
    'canViewPreviousStepResult'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkInstructionRequestDTO
     */
    'scoreThreshold'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionRequestDTO
     */
    'condition'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionRequestDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionRequestDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionRequestDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkInstructionRequestDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionRequestDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionRequestDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkInstructionRequestDTO
     */
    'roundNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkInstructionRequestDTO
     */
    'step'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkInstructionRequestDTO
     */
    'version'?: number;
    /**
     * 
     * @type {WorkflowDTO}
     * @memberof WorkInstructionRequestDTO
     */
    'workflow'?: WorkflowDTO;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionRequestDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionRequestDTO
     */
    'stepType'?: string;
}
/**
 * 
 * @export
 * @interface WorkInstructionResponseDTO
 */
export interface WorkInstructionResponseDTO {
    /**
     * 
     * @type {boolean}
     * @memberof WorkInstructionResponseDTO
     */
    'canViewPreviousStepResult'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionResponseDTO
     */
    'condition'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionResponseDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionResponseDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkInstructionResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionResponseDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionResponseDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkInstructionResponseDTO
     */
    'roundNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkInstructionResponseDTO
     */
    'step'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkInstructionResponseDTO
     */
    'version'?: number;
    /**
     * 
     * @type {WorkflowDTO}
     * @memberof WorkInstructionResponseDTO
     */
    'workflow'?: WorkflowDTO;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionResponseDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkInstructionResponseDTO
     */
    'stepType'?: string;
}
/**
 * 
 * @export
 * @interface WorkManagementDTO
 */
export interface WorkManagementDTO {
    /**
     * 
     * @type {boolean}
     * @memberof WorkManagementDTO
     */
    'active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementDTO
     */
    'projectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkManagementDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkManagementDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementDTO
     */
    'dailyLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementDTO
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementDTO
     */
    'assignTask'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkManagementDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {Array<ObservationDTO>}
     * @memberof WorkManagementDTO
     */
    'observations'?: Array<ObservationDTO>;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementDTO
     */
    'priority'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkManagementDTO
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkManagementDTO
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementDTO
     */
    'version'?: number;
    /**
     * 
     * @type {WorkInstructionResponseDTO}
     * @memberof WorkManagementDTO
     */
    'workInstruction'?: WorkInstructionResponseDTO;
    /**
     * 
     * @type {string}
     * @memberof WorkManagementDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementDTO
     */
    'percentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkManagementDTO
     */
    'reviewPickType'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementDTO
     */
    'acceptPercentage'?: number;
}
/**
 * 
 * @export
 * @interface WorkManagementData
 */
export interface WorkManagementData {
    /**
     * 
     * @type {number}
     * @memberof WorkManagementData
     */
    'workInstructionStep'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementData
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementData
     */
    'dailyLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkManagementData
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementData
     */
    'workInstructionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementData
     */
    'percentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkManagementData
     */
    'reviewPickType'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkManagementData
     */
    'acceptPercentage'?: number;
}
/**
 * 
 * @export
 * @interface WorkflowBatchDTO
 */
export interface WorkflowBatchDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkflowBatchDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowBatchDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowBatchDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowBatchDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowBatchDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowBatchDTO
     */
    'version'?: number;
    /**
     * 
     * @type {WorkflowDTO}
     * @memberof WorkflowBatchDTO
     */
    'workflow'?: WorkflowDTO;
    /**
     * 
     * @type {string}
     * @memberof WorkflowBatchDTO
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface WorkflowCriteria
 */
export interface WorkflowCriteria {
    /**
     * 
     * @type {number}
     * @memberof WorkflowCriteria
     */
    'id.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCriteria
     */
    'id.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCriteria
     */
    'name.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCriteria
     */
    'name.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCriteria
     */
    'name.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCriteria
     */
    'createdDate.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCriteria
     */
    'createdDate.greaterThan'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCriteria
     */
    'createdDate.lessThan'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCriteria
     */
    'projectType.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCriteria
     */
    'projectType.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCriteria
     */
    'projectType.contains'?: string;
}
/**
 * 
 * @export
 * @interface WorkflowDTO
 */
export interface WorkflowDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkflowDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowDTO
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDTO
     */
    'projectType'?: WorkflowDTOProjectTypeEnum;
}

export const WorkflowDTOProjectTypeEnum = {
    Text: 'text',
    MedicalImage: 'medical_image',
    GeneralImage: 'general_image',
    Video: 'video',
    Audio: 'audio'
} as const;

export type WorkflowDTOProjectTypeEnum = typeof WorkflowDTOProjectTypeEnum[keyof typeof WorkflowDTOProjectTypeEnum];

/**
 * 
 * @export
 * @interface WorkflowDTOV2
 */
export interface WorkflowDTOV2 {
    /**
     * 
     * @type {string}
     * @memberof WorkflowDTOV2
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDTOV2
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDTOV2
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowDTOV2
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDTOV2
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDTOV2
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowDTOV2
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDTOV2
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDTOV2
     */
    'projectType'?: WorkflowDTOV2ProjectTypeEnum;
    /**
     * 
     * @type {Array<WorkInstructionRequestDTO>}
     * @memberof WorkflowDTOV2
     */
    'workInstructions'?: Array<WorkInstructionRequestDTO>;
}

export const WorkflowDTOV2ProjectTypeEnum = {
    Text: 'text',
    MedicalImage: 'medical_image',
    GeneralImage: 'general_image',
    Video: 'video',
    Audio: 'audio'
} as const;

export type WorkflowDTOV2ProjectTypeEnum = typeof WorkflowDTOV2ProjectTypeEnum[keyof typeof WorkflowDTOV2ProjectTypeEnum];

/**
 * 
 * @export
 * @interface WsiAnnotationItem
 */
export interface WsiAnnotationItem {
    /**
     * 
     * @type {number}
     * @memberof WsiAnnotationItem
     */
    'frameId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WsiAnnotationItem
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WsiAnnotationItem
     */
    'type'?: WsiAnnotationItemTypeEnum;
    /**
     * 
     * @type {Array<Point>}
     * @memberof WsiAnnotationItem
     */
    'points'?: Array<Point>;
}

export const WsiAnnotationItemTypeEnum = {
    Polygon: 'polygon',
    Bbox: 'bbox',
    Polyline: 'polyline',
    Line: 'line',
    Ellipse: 'ellipse',
    Points: 'points'
} as const;

export type WsiAnnotationItemTypeEnum = typeof WsiAnnotationItemTypeEnum[keyof typeof WsiAnnotationItemTypeEnum];

/**
 * 
 * @export
 * @interface WsiAnnotationItemAllOf
 */
export interface WsiAnnotationItemAllOf {
    /**
     * 
     * @type {number}
     * @memberof WsiAnnotationItemAllOf
     */
    'frameId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WsiAnnotationItemAllOf
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WsiAnnotationItemAllOf
     */
    'type'?: WsiAnnotationItemAllOfTypeEnum;
    /**
     * 
     * @type {Array<Point>}
     * @memberof WsiAnnotationItemAllOf
     */
    'points'?: Array<Point>;
}

export const WsiAnnotationItemAllOfTypeEnum = {
    Polygon: 'polygon',
    Bbox: 'bbox',
    Polyline: 'polyline',
    Line: 'line',
    Ellipse: 'ellipse',
    Points: 'points'
} as const;

export type WsiAnnotationItemAllOfTypeEnum = typeof WsiAnnotationItemAllOfTypeEnum[keyof typeof WsiAnnotationItemAllOfTypeEnum];


/**
 * ActuatorApi - axios parameter creator
 * @export
 */
export const ActuatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get health status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get liveness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthLivenessGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/health/liveness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get readiness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthReadinessGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/health/readiness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActuatorApi - functional programming interface
 * @export
 */
export const ActuatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActuatorApiAxiosParamCreator(configuration)
    return {
        /**
         * Get health status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementHealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementHealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get liveness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementHealthLivenessGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementHealthLivenessGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get readiness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementHealthReadinessGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementHealthReadinessGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActuatorApi - factory interface
 * @export
 */
export const ActuatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActuatorApiFp(configuration)
    return {
        /**
         * Get health status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthGet(options?: any): AxiosPromise<object> {
            return localVarFp.managementHealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get liveness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthLivenessGet(options?: any): AxiosPromise<object> {
            return localVarFp.managementHealthLivenessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get readiness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthReadinessGet(options?: any): AxiosPromise<object> {
            return localVarFp.managementHealthReadinessGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActuatorApi - object-oriented interface
 * @export
 * @class ActuatorApi
 * @extends {BaseAPI}
 */
export class ActuatorApi extends BaseAPI {
    /**
     * Get health status of backend service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public managementHealthGet(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).managementHealthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get liveness status of backend service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public managementHealthLivenessGet(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).managementHealthLivenessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get readiness status of backend service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public managementHealthReadinessGet(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).managementHealthReadinessGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnotationResourceApi - axios parameter creator
 * @export
 */
export const AnnotationResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [batchId] batchId
         * @param {GenerateAnnotationRequest} [generateAnnotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAnnotationFromAIModel: async (batchId?: number, generateAnnotationRequest?: GenerateAnnotationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/annotations/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateAnnotationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [batchId] batchId
         * @param {GenerateAnnotationRequest} [generateAnnotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAnnotationLung: async (batchId?: number, generateAnnotationRequest?: GenerateAnnotationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/annotations/lung/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateAnnotationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId taskId
         * @param {string} [metric] iouscore, f1, f2, fn, dicescore...
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAnnotationsMetricByTaskUsingGET: async (taskId: number, metric?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getAllAnnotationsMetricByTaskUsingGET', 'taskId', taskId)
            const localVarPath = `/api/v1/annotations/metrics/task/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (metric !== undefined) {
                localVarQueryParameter['metric'] = metric;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use v2 instead
         * @summary getAllAnnotations
         * @param {number} [jobId] jobId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllAnnotationsUsingGET: async (jobId?: number, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/annotations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAnnotationByTaskId
         * @param {number} id id
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAnnotationByTaskIdUsingGET: async (id: number, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnnotationByTaskIdUsingGET', 'id', id)
            const localVarPath = `/api/v1/annotations/by-task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAnnotation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAnnotationUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnnotationUsingGET', 'id', id)
            const localVarPath = `/api/v1/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [batchId] batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenerateAnnotationJobStatus: async (batchId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/annotations/generate/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} jobId jobId
         * @param {string} [metric] iouscore, f1, f2, fn, dicescore...
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricOfLabelerByJobUsingGET: async (jobId: number, metric?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('getMetricOfLabelerByJobUsingGET', 'jobId', jobId)
            const localVarPath = `/api/v1/labeler/annotations/metrics/{jobId}`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (metric !== undefined) {
                localVarQueryParameter['metric'] = metric;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ThreeDSmoothingRequest} [threeDSmoothingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        threeDSmoothing: async (threeDSmoothingRequest?: ThreeDSmoothingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/annotations/threed/smoothing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(threeDSmoothingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [jobId] jobId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        threeDSmoothingStatus: async (jobId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/annotations/threed/smoothing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<AnnotationVoteItem>} [annotationVoteItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voteAnnotationsByCustomer: async (annotationVoteItem?: Array<AnnotationVoteItem>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/annotations/vote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationVoteItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AnnotationVoteLabelerRequest} [annotationVoteLabelerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voteAnnotationsByLabeler: async (annotationVoteLabelerRequest?: AnnotationVoteLabelerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/labeler/annotations/vote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationVoteLabelerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnotationResourceApi - functional programming interface
 * @export
 */
export const AnnotationResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnotationResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [batchId] batchId
         * @param {GenerateAnnotationRequest} [generateAnnotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateAnnotationFromAIModel(batchId?: number, generateAnnotationRequest?: GenerateAnnotationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfGenerateAnnotationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateAnnotationFromAIModel(batchId, generateAnnotationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [batchId] batchId
         * @param {GenerateAnnotationRequest} [generateAnnotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateAnnotationLung(batchId?: number, generateAnnotationRequest?: GenerateAnnotationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfGenerateAnnotationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateAnnotationLung(batchId, generateAnnotationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskId taskId
         * @param {string} [metric] iouscore, f1, f2, fn, dicescore...
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAnnotationsMetricByTaskUsingGET(taskId: number, metric?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAnnotationsMetricByTaskUsingGET(taskId, metric, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use v2 instead
         * @summary getAllAnnotations
         * @param {number} [jobId] jobId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAllAnnotationsUsingGET(jobId?: number, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfAnnotationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAnnotationsUsingGET(jobId, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAnnotationByTaskId
         * @param {number} id id
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAnnotationByTaskIdUsingGET(id: number, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfAnnotationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotationByTaskIdUsingGET(id, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAnnotation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAnnotationUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfAnnotationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotationUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [batchId] batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGenerateAnnotationJobStatus(batchId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfJobStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGenerateAnnotationJobStatus(batchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} jobId jobId
         * @param {string} [metric] iouscore, f1, f2, fn, dicescore...
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetricOfLabelerByJobUsingGET(jobId: number, metric?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetricOfLabelerByJobUsingGET(jobId, metric, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ThreeDSmoothingRequest} [threeDSmoothingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async threeDSmoothing(threeDSmoothingRequest?: ThreeDSmoothingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfThreeDSmoothingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.threeDSmoothing(threeDSmoothingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [jobId] jobId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async threeDSmoothingStatus(jobId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfAnnotationAugmentationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.threeDSmoothingStatus(jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<AnnotationVoteItem>} [annotationVoteItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async voteAnnotationsByCustomer(annotationVoteItem?: Array<AnnotationVoteItem>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationVoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.voteAnnotationsByCustomer(annotationVoteItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AnnotationVoteLabelerRequest} [annotationVoteLabelerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async voteAnnotationsByLabeler(annotationVoteLabelerRequest?: AnnotationVoteLabelerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationVoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.voteAnnotationsByLabeler(annotationVoteLabelerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnotationResourceApi - factory interface
 * @export
 */
export const AnnotationResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnotationResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [batchId] batchId
         * @param {GenerateAnnotationRequest} [generateAnnotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAnnotationFromAIModel(batchId?: number, generateAnnotationRequest?: GenerateAnnotationRequest, options?: any): AxiosPromise<RestResponseOfGenerateAnnotationResponse> {
            return localVarFp.generateAnnotationFromAIModel(batchId, generateAnnotationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [batchId] batchId
         * @param {GenerateAnnotationRequest} [generateAnnotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAnnotationLung(batchId?: number, generateAnnotationRequest?: GenerateAnnotationRequest, options?: any): AxiosPromise<RestResponseOfGenerateAnnotationResponse> {
            return localVarFp.generateAnnotationLung(batchId, generateAnnotationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskId taskId
         * @param {string} [metric] iouscore, f1, f2, fn, dicescore...
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAnnotationsMetricByTaskUsingGET(taskId: number, metric?: string, options?: any): AxiosPromise<MetricResponse> {
            return localVarFp.getAllAnnotationsMetricByTaskUsingGET(taskId, metric, options).then((request) => request(axios, basePath));
        },
        /**
         * Use v2 instead
         * @summary getAllAnnotations
         * @param {number} [jobId] jobId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllAnnotationsUsingGET(jobId?: number, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfAnnotationDTO> {
            return localVarFp.getAllAnnotationsUsingGET(jobId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAnnotationByTaskId
         * @param {number} id id
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAnnotationByTaskIdUsingGET(id: number, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfAnnotationDTO> {
            return localVarFp.getAnnotationByTaskIdUsingGET(id, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAnnotation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAnnotationUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfAnnotationDTO> {
            return localVarFp.getAnnotationUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [batchId] batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenerateAnnotationJobStatus(batchId?: number, options?: any): AxiosPromise<RestResponseOfJobStatusResponse> {
            return localVarFp.getGenerateAnnotationJobStatus(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} jobId jobId
         * @param {string} [metric] iouscore, f1, f2, fn, dicescore...
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricOfLabelerByJobUsingGET(jobId: number, metric?: string, options?: any): AxiosPromise<MetricResponse> {
            return localVarFp.getMetricOfLabelerByJobUsingGET(jobId, metric, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ThreeDSmoothingRequest} [threeDSmoothingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        threeDSmoothing(threeDSmoothingRequest?: ThreeDSmoothingRequest, options?: any): AxiosPromise<RestResponseOfThreeDSmoothingResponse> {
            return localVarFp.threeDSmoothing(threeDSmoothingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [jobId] jobId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        threeDSmoothingStatus(jobId?: number, options?: any): AxiosPromise<RestResponseOfAnnotationAugmentationResponse> {
            return localVarFp.threeDSmoothingStatus(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<AnnotationVoteItem>} [annotationVoteItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voteAnnotationsByCustomer(annotationVoteItem?: Array<AnnotationVoteItem>, options?: any): AxiosPromise<AnnotationVoteResponse> {
            return localVarFp.voteAnnotationsByCustomer(annotationVoteItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AnnotationVoteLabelerRequest} [annotationVoteLabelerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voteAnnotationsByLabeler(annotationVoteLabelerRequest?: AnnotationVoteLabelerRequest, options?: any): AxiosPromise<AnnotationVoteResponse> {
            return localVarFp.voteAnnotationsByLabeler(annotationVoteLabelerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for generateAnnotationFromAIModel operation in AnnotationResourceApi.
 * @export
 * @interface AnnotationResourceApiGenerateAnnotationFromAIModelRequest
 */
export interface AnnotationResourceApiGenerateAnnotationFromAIModelRequest {
    /**
     * batchId
     * @type {number}
     * @memberof AnnotationResourceApiGenerateAnnotationFromAIModel
     */
    readonly batchId?: number

    /**
     * 
     * @type {GenerateAnnotationRequest}
     * @memberof AnnotationResourceApiGenerateAnnotationFromAIModel
     */
    readonly generateAnnotationRequest?: GenerateAnnotationRequest
}

/**
 * Request parameters for generateAnnotationLung operation in AnnotationResourceApi.
 * @export
 * @interface AnnotationResourceApiGenerateAnnotationLungRequest
 */
export interface AnnotationResourceApiGenerateAnnotationLungRequest {
    /**
     * batchId
     * @type {number}
     * @memberof AnnotationResourceApiGenerateAnnotationLung
     */
    readonly batchId?: number

    /**
     * 
     * @type {GenerateAnnotationRequest}
     * @memberof AnnotationResourceApiGenerateAnnotationLung
     */
    readonly generateAnnotationRequest?: GenerateAnnotationRequest
}

/**
 * Request parameters for getAllAnnotationsMetricByTaskUsingGET operation in AnnotationResourceApi.
 * @export
 * @interface AnnotationResourceApiGetAllAnnotationsMetricByTaskUsingGETRequest
 */
export interface AnnotationResourceApiGetAllAnnotationsMetricByTaskUsingGETRequest {
    /**
     * taskId
     * @type {number}
     * @memberof AnnotationResourceApiGetAllAnnotationsMetricByTaskUsingGET
     */
    readonly taskId: number

    /**
     * iouscore, f1, f2, fn, dicescore...
     * @type {string}
     * @memberof AnnotationResourceApiGetAllAnnotationsMetricByTaskUsingGET
     */
    readonly metric?: string
}

/**
 * Request parameters for getAllAnnotationsUsingGET operation in AnnotationResourceApi.
 * @export
 * @interface AnnotationResourceApiGetAllAnnotationsUsingGETRequest
 */
export interface AnnotationResourceApiGetAllAnnotationsUsingGETRequest {
    /**
     * jobId
     * @type {number}
     * @memberof AnnotationResourceApiGetAllAnnotationsUsingGET
     */
    readonly jobId?: number

    /**
     * pageable
     * @type {Pageable}
     * @memberof AnnotationResourceApiGetAllAnnotationsUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getAnnotationByTaskIdUsingGET operation in AnnotationResourceApi.
 * @export
 * @interface AnnotationResourceApiGetAnnotationByTaskIdUsingGETRequest
 */
export interface AnnotationResourceApiGetAnnotationByTaskIdUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof AnnotationResourceApiGetAnnotationByTaskIdUsingGET
     */
    readonly id: number

    /**
     * pageable
     * @type {Pageable}
     * @memberof AnnotationResourceApiGetAnnotationByTaskIdUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getAnnotationUsingGET operation in AnnotationResourceApi.
 * @export
 * @interface AnnotationResourceApiGetAnnotationUsingGETRequest
 */
export interface AnnotationResourceApiGetAnnotationUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof AnnotationResourceApiGetAnnotationUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for getGenerateAnnotationJobStatus operation in AnnotationResourceApi.
 * @export
 * @interface AnnotationResourceApiGetGenerateAnnotationJobStatusRequest
 */
export interface AnnotationResourceApiGetGenerateAnnotationJobStatusRequest {
    /**
     * batchId
     * @type {number}
     * @memberof AnnotationResourceApiGetGenerateAnnotationJobStatus
     */
    readonly batchId?: number
}

/**
 * Request parameters for getMetricOfLabelerByJobUsingGET operation in AnnotationResourceApi.
 * @export
 * @interface AnnotationResourceApiGetMetricOfLabelerByJobUsingGETRequest
 */
export interface AnnotationResourceApiGetMetricOfLabelerByJobUsingGETRequest {
    /**
     * jobId
     * @type {number}
     * @memberof AnnotationResourceApiGetMetricOfLabelerByJobUsingGET
     */
    readonly jobId: number

    /**
     * iouscore, f1, f2, fn, dicescore...
     * @type {string}
     * @memberof AnnotationResourceApiGetMetricOfLabelerByJobUsingGET
     */
    readonly metric?: string
}

/**
 * Request parameters for threeDSmoothing operation in AnnotationResourceApi.
 * @export
 * @interface AnnotationResourceApiThreeDSmoothingRequest
 */
export interface AnnotationResourceApiThreeDSmoothingRequest {
    /**
     * 
     * @type {ThreeDSmoothingRequest}
     * @memberof AnnotationResourceApiThreeDSmoothing
     */
    readonly threeDSmoothingRequest?: ThreeDSmoothingRequest
}

/**
 * Request parameters for threeDSmoothingStatus operation in AnnotationResourceApi.
 * @export
 * @interface AnnotationResourceApiThreeDSmoothingStatusRequest
 */
export interface AnnotationResourceApiThreeDSmoothingStatusRequest {
    /**
     * jobId
     * @type {number}
     * @memberof AnnotationResourceApiThreeDSmoothingStatus
     */
    readonly jobId?: number
}

/**
 * Request parameters for voteAnnotationsByCustomer operation in AnnotationResourceApi.
 * @export
 * @interface AnnotationResourceApiVoteAnnotationsByCustomerRequest
 */
export interface AnnotationResourceApiVoteAnnotationsByCustomerRequest {
    /**
     * 
     * @type {Array<AnnotationVoteItem>}
     * @memberof AnnotationResourceApiVoteAnnotationsByCustomer
     */
    readonly annotationVoteItem?: Array<AnnotationVoteItem>
}

/**
 * Request parameters for voteAnnotationsByLabeler operation in AnnotationResourceApi.
 * @export
 * @interface AnnotationResourceApiVoteAnnotationsByLabelerRequest
 */
export interface AnnotationResourceApiVoteAnnotationsByLabelerRequest {
    /**
     * 
     * @type {AnnotationVoteLabelerRequest}
     * @memberof AnnotationResourceApiVoteAnnotationsByLabeler
     */
    readonly annotationVoteLabelerRequest?: AnnotationVoteLabelerRequest
}

/**
 * AnnotationResourceApi - object-oriented interface
 * @export
 * @class AnnotationResourceApi
 * @extends {BaseAPI}
 */
export class AnnotationResourceApi extends BaseAPI {
    /**
     * 
     * @param {AnnotationResourceApiGenerateAnnotationFromAIModelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationResourceApi
     */
    public generateAnnotationFromAIModel(requestParameters: AnnotationResourceApiGenerateAnnotationFromAIModelRequest = {}, options?: AxiosRequestConfig) {
        return AnnotationResourceApiFp(this.configuration).generateAnnotationFromAIModel(requestParameters.batchId, requestParameters.generateAnnotationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnotationResourceApiGenerateAnnotationLungRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationResourceApi
     */
    public generateAnnotationLung(requestParameters: AnnotationResourceApiGenerateAnnotationLungRequest = {}, options?: AxiosRequestConfig) {
        return AnnotationResourceApiFp(this.configuration).generateAnnotationLung(requestParameters.batchId, requestParameters.generateAnnotationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnotationResourceApiGetAllAnnotationsMetricByTaskUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationResourceApi
     */
    public getAllAnnotationsMetricByTaskUsingGET(requestParameters: AnnotationResourceApiGetAllAnnotationsMetricByTaskUsingGETRequest, options?: AxiosRequestConfig) {
        return AnnotationResourceApiFp(this.configuration).getAllAnnotationsMetricByTaskUsingGET(requestParameters.taskId, requestParameters.metric, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use v2 instead
     * @summary getAllAnnotations
     * @param {AnnotationResourceApiGetAllAnnotationsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AnnotationResourceApi
     */
    public getAllAnnotationsUsingGET(requestParameters: AnnotationResourceApiGetAllAnnotationsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return AnnotationResourceApiFp(this.configuration).getAllAnnotationsUsingGET(requestParameters.jobId, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAnnotationByTaskId
     * @param {AnnotationResourceApiGetAnnotationByTaskIdUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AnnotationResourceApi
     */
    public getAnnotationByTaskIdUsingGET(requestParameters: AnnotationResourceApiGetAnnotationByTaskIdUsingGETRequest, options?: AxiosRequestConfig) {
        return AnnotationResourceApiFp(this.configuration).getAnnotationByTaskIdUsingGET(requestParameters.id, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAnnotation
     * @param {AnnotationResourceApiGetAnnotationUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AnnotationResourceApi
     */
    public getAnnotationUsingGET(requestParameters: AnnotationResourceApiGetAnnotationUsingGETRequest, options?: AxiosRequestConfig) {
        return AnnotationResourceApiFp(this.configuration).getAnnotationUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnotationResourceApiGetGenerateAnnotationJobStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationResourceApi
     */
    public getGenerateAnnotationJobStatus(requestParameters: AnnotationResourceApiGetGenerateAnnotationJobStatusRequest = {}, options?: AxiosRequestConfig) {
        return AnnotationResourceApiFp(this.configuration).getGenerateAnnotationJobStatus(requestParameters.batchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnotationResourceApiGetMetricOfLabelerByJobUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationResourceApi
     */
    public getMetricOfLabelerByJobUsingGET(requestParameters: AnnotationResourceApiGetMetricOfLabelerByJobUsingGETRequest, options?: AxiosRequestConfig) {
        return AnnotationResourceApiFp(this.configuration).getMetricOfLabelerByJobUsingGET(requestParameters.jobId, requestParameters.metric, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnotationResourceApiThreeDSmoothingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationResourceApi
     */
    public threeDSmoothing(requestParameters: AnnotationResourceApiThreeDSmoothingRequest = {}, options?: AxiosRequestConfig) {
        return AnnotationResourceApiFp(this.configuration).threeDSmoothing(requestParameters.threeDSmoothingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnotationResourceApiThreeDSmoothingStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationResourceApi
     */
    public threeDSmoothingStatus(requestParameters: AnnotationResourceApiThreeDSmoothingStatusRequest = {}, options?: AxiosRequestConfig) {
        return AnnotationResourceApiFp(this.configuration).threeDSmoothingStatus(requestParameters.jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnotationResourceApiVoteAnnotationsByCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationResourceApi
     */
    public voteAnnotationsByCustomer(requestParameters: AnnotationResourceApiVoteAnnotationsByCustomerRequest = {}, options?: AxiosRequestConfig) {
        return AnnotationResourceApiFp(this.configuration).voteAnnotationsByCustomer(requestParameters.annotationVoteItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnotationResourceApiVoteAnnotationsByLabelerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationResourceApi
     */
    public voteAnnotationsByLabeler(requestParameters: AnnotationResourceApiVoteAnnotationsByLabelerRequest = {}, options?: AxiosRequestConfig) {
        return AnnotationResourceApiFp(this.configuration).voteAnnotationsByLabeler(requestParameters.annotationVoteLabelerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnotationResourceV2Api - axios parameter creator
 * @export
 */
export const AnnotationResourceV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createImportAnnotationJob
         * @param {AnnotationJobRequest} [annotationJobRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createJob: async (annotationJobRequest?: AnnotationJobRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/annotations/job`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationJobRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllAnnotationsV2
         * @param {AnnotationCriteria} [criteria] criteria for annotation
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllAnnotationsV2UsingCriteria: async (criteria?: AnnotationCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/annotations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllLabelerAnnotationsV2
         * @param {AnnotationLabelerCriteria} [criteria] criteria for annotation
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllLabelerAnnotationsV2UsingCriteria: async (criteria?: AnnotationLabelerCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/labeler/annotations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getImportAnnotationJob
         * @param {number} [batchId] batchId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAnnotationJob: async (batchId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/annotations/job`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnotationResourceV2Api - functional programming interface
 * @export
 */
export const AnnotationResourceV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnotationResourceV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createImportAnnotationJob
         * @param {AnnotationJobRequest} [annotationJobRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createJob(annotationJobRequest?: AnnotationJobRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfAnnotationJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createJob(annotationJobRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllAnnotationsV2
         * @param {AnnotationCriteria} [criteria] criteria for annotation
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAllAnnotationsV2UsingCriteria(criteria?: AnnotationCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfAnnotationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAnnotationsV2UsingCriteria(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllLabelerAnnotationsV2
         * @param {AnnotationLabelerCriteria} [criteria] criteria for annotation
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAllLabelerAnnotationsV2UsingCriteria(criteria?: AnnotationLabelerCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfAnnotationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLabelerAnnotationsV2UsingCriteria(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getImportAnnotationJob
         * @param {number} [batchId] batchId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAnnotationJob(batchId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListAnnotationJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotationJob(batchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnotationResourceV2Api - factory interface
 * @export
 */
export const AnnotationResourceV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnotationResourceV2ApiFp(configuration)
    return {
        /**
         * 
         * @summary createImportAnnotationJob
         * @param {AnnotationJobRequest} [annotationJobRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createJob(annotationJobRequest?: AnnotationJobRequest, options?: any): AxiosPromise<RestResponseOfAnnotationJobResponse> {
            return localVarFp.createJob(annotationJobRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllAnnotationsV2
         * @param {AnnotationCriteria} [criteria] criteria for annotation
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllAnnotationsV2UsingCriteria(criteria?: AnnotationCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfAnnotationDTO> {
            return localVarFp.getAllAnnotationsV2UsingCriteria(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllLabelerAnnotationsV2
         * @param {AnnotationLabelerCriteria} [criteria] criteria for annotation
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllLabelerAnnotationsV2UsingCriteria(criteria?: AnnotationLabelerCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfAnnotationDTO> {
            return localVarFp.getAllLabelerAnnotationsV2UsingCriteria(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getImportAnnotationJob
         * @param {number} [batchId] batchId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAnnotationJob(batchId?: number, options?: any): AxiosPromise<RestResponseOfListAnnotationJobResponse> {
            return localVarFp.getAnnotationJob(batchId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createJob operation in AnnotationResourceV2Api.
 * @export
 * @interface AnnotationResourceV2ApiCreateJobRequest
 */
export interface AnnotationResourceV2ApiCreateJobRequest {
    /**
     * 
     * @type {AnnotationJobRequest}
     * @memberof AnnotationResourceV2ApiCreateJob
     */
    readonly annotationJobRequest?: AnnotationJobRequest
}

/**
 * Request parameters for getAllAnnotationsV2UsingCriteria operation in AnnotationResourceV2Api.
 * @export
 * @interface AnnotationResourceV2ApiGetAllAnnotationsV2UsingCriteriaRequest
 */
export interface AnnotationResourceV2ApiGetAllAnnotationsV2UsingCriteriaRequest {
    /**
     * criteria for annotation
     * @type {AnnotationCriteria}
     * @memberof AnnotationResourceV2ApiGetAllAnnotationsV2UsingCriteria
     */
    readonly criteria?: AnnotationCriteria

    /**
     * pageable
     * @type {Pageable}
     * @memberof AnnotationResourceV2ApiGetAllAnnotationsV2UsingCriteria
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getAllLabelerAnnotationsV2UsingCriteria operation in AnnotationResourceV2Api.
 * @export
 * @interface AnnotationResourceV2ApiGetAllLabelerAnnotationsV2UsingCriteriaRequest
 */
export interface AnnotationResourceV2ApiGetAllLabelerAnnotationsV2UsingCriteriaRequest {
    /**
     * criteria for annotation
     * @type {AnnotationLabelerCriteria}
     * @memberof AnnotationResourceV2ApiGetAllLabelerAnnotationsV2UsingCriteria
     */
    readonly criteria?: AnnotationLabelerCriteria

    /**
     * pageable
     * @type {Pageable}
     * @memberof AnnotationResourceV2ApiGetAllLabelerAnnotationsV2UsingCriteria
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getAnnotationJob operation in AnnotationResourceV2Api.
 * @export
 * @interface AnnotationResourceV2ApiGetAnnotationJobRequest
 */
export interface AnnotationResourceV2ApiGetAnnotationJobRequest {
    /**
     * batchId
     * @type {number}
     * @memberof AnnotationResourceV2ApiGetAnnotationJob
     */
    readonly batchId?: number
}

/**
 * AnnotationResourceV2Api - object-oriented interface
 * @export
 * @class AnnotationResourceV2Api
 * @extends {BaseAPI}
 */
export class AnnotationResourceV2Api extends BaseAPI {
    /**
     * 
     * @summary createImportAnnotationJob
     * @param {AnnotationResourceV2ApiCreateJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AnnotationResourceV2Api
     */
    public createJob(requestParameters: AnnotationResourceV2ApiCreateJobRequest = {}, options?: AxiosRequestConfig) {
        return AnnotationResourceV2ApiFp(this.configuration).createJob(requestParameters.annotationJobRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllAnnotationsV2
     * @param {AnnotationResourceV2ApiGetAllAnnotationsV2UsingCriteriaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AnnotationResourceV2Api
     */
    public getAllAnnotationsV2UsingCriteria(requestParameters: AnnotationResourceV2ApiGetAllAnnotationsV2UsingCriteriaRequest = {}, options?: AxiosRequestConfig) {
        return AnnotationResourceV2ApiFp(this.configuration).getAllAnnotationsV2UsingCriteria(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllLabelerAnnotationsV2
     * @param {AnnotationResourceV2ApiGetAllLabelerAnnotationsV2UsingCriteriaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AnnotationResourceV2Api
     */
    public getAllLabelerAnnotationsV2UsingCriteria(requestParameters: AnnotationResourceV2ApiGetAllLabelerAnnotationsV2UsingCriteriaRequest = {}, options?: AxiosRequestConfig) {
        return AnnotationResourceV2ApiFp(this.configuration).getAllLabelerAnnotationsV2UsingCriteria(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getImportAnnotationJob
     * @param {AnnotationResourceV2ApiGetAnnotationJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AnnotationResourceV2Api
     */
    public getAnnotationJob(requestParameters: AnnotationResourceV2ApiGetAnnotationJobRequest = {}, options?: AxiosRequestConfig) {
        return AnnotationResourceV2ApiFp(this.configuration).getAnnotationJob(requestParameters.batchId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnotationResourceV3Api - axios parameter creator
 * @export
 */
export const AnnotationResourceV3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} runId runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAnnotationsByDatasetRunUsingGET: async (runId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('getAllAnnotationsByDatasetRunUsingGET', 'runId', runId)
            const localVarPath = `/api/v3/annotations/dataset/run`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (runId !== undefined) {
                localVarQueryParameter['runId'] = runId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get ground truth annotations for datasetVersionId
         * @param {number} datasetVersionId datasetVersionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAnnotationsByDatasetUsingGET: async (datasetVersionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetVersionId' is not null or undefined
            assertParamExists('getAllAnnotationsByDatasetUsingGET', 'datasetVersionId', datasetVersionId)
            const localVarPath = `/api/v3/annotations/dataset/{datasetVersionId}`
                .replace(`{${"datasetVersionId"}}`, encodeURIComponent(String(datasetVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAnnotationsByTaskUsingGET: async (taskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getAllAnnotationsByTaskUsingGET', 'taskId', taskId)
            const localVarPath = `/api/v3/annotations/task/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AnnotationCriteria} [criteria] criteria for annotation
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllAnnotationsV3UsingCriteria: async (criteria?: AnnotationCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/annotations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} jobId jobId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLabelerAnnotationsByJobUsingGET: async (jobId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('getAllLabelerAnnotationsByJobUsingGET', 'jobId', jobId)
            const localVarPath = `/api/v3/labeler/annotations/job/{jobId}`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AnnotationLabelerCriteriaV3} [criteria] criteria for annotation
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllLabelerAnnotationsV3UsingCriteria: async (criteria?: AnnotationLabelerCriteriaV3, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/labeler/annotations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} jobId jobId
         * @param {AnnotationJobRequestDTO} [annotationJobRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLabelerAnnotationsByJobUsingPOST: async (jobId: number, annotationJobRequestDTO?: AnnotationJobRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('saveLabelerAnnotationsByJobUsingPOST', 'jobId', jobId)
            const localVarPath = `/api/v3/labeler/annotations/job/{jobId}`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationJobRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnotationResourceV3Api - functional programming interface
 * @export
 */
export const AnnotationResourceV3ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnotationResourceV3ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} runId runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAnnotationsByDatasetRunUsingGET(runId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfAnnotationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAnnotationsByDatasetRunUsingGET(runId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get ground truth annotations for datasetVersionId
         * @param {number} datasetVersionId datasetVersionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAnnotationsByDatasetUsingGET(datasetVersionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfAnnotationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAnnotationsByDatasetUsingGET(datasetVersionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskId taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAnnotationsByTaskUsingGET(taskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfAnnotationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAnnotationsByTaskUsingGET(taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AnnotationCriteria} [criteria] criteria for annotation
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAllAnnotationsV3UsingCriteria(criteria?: AnnotationCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfAnnotationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAnnotationsV3UsingCriteria(criteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} jobId jobId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLabelerAnnotationsByJobUsingGET(jobId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfAnnotationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLabelerAnnotationsByJobUsingGET(jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AnnotationLabelerCriteriaV3} [criteria] criteria for annotation
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAllLabelerAnnotationsV3UsingCriteria(criteria?: AnnotationLabelerCriteriaV3, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfAnnotationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLabelerAnnotationsV3UsingCriteria(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} jobId jobId
         * @param {AnnotationJobRequestDTO} [annotationJobRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveLabelerAnnotationsByJobUsingPOST(jobId: number, annotationJobRequestDTO?: AnnotationJobRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfAnnotationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveLabelerAnnotationsByJobUsingPOST(jobId, annotationJobRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnotationResourceV3Api - factory interface
 * @export
 */
export const AnnotationResourceV3ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnotationResourceV3ApiFp(configuration)
    return {
        /**
         * 
         * @param {string} runId runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAnnotationsByDatasetRunUsingGET(runId: string, options?: any): AxiosPromise<RestResponseOfListOfAnnotationResponseDTO> {
            return localVarFp.getAllAnnotationsByDatasetRunUsingGET(runId, options).then((request) => request(axios, basePath));
        },
        /**
         * get ground truth annotations for datasetVersionId
         * @param {number} datasetVersionId datasetVersionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAnnotationsByDatasetUsingGET(datasetVersionId: number, options?: any): AxiosPromise<RestResponseOfListOfAnnotationResponseDTO> {
            return localVarFp.getAllAnnotationsByDatasetUsingGET(datasetVersionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskId taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAnnotationsByTaskUsingGET(taskId: number, options?: any): AxiosPromise<RestResponseOfListOfAnnotationResponseDTO> {
            return localVarFp.getAllAnnotationsByTaskUsingGET(taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AnnotationCriteria} [criteria] criteria for annotation
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllAnnotationsV3UsingCriteria(criteria?: AnnotationCriteria, options?: any): AxiosPromise<RestResponseOfListOfAnnotationResponseDTO> {
            return localVarFp.getAllAnnotationsV3UsingCriteria(criteria, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} jobId jobId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLabelerAnnotationsByJobUsingGET(jobId: number, options?: any): AxiosPromise<RestResponseOfListOfAnnotationResponseDTO> {
            return localVarFp.getAllLabelerAnnotationsByJobUsingGET(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AnnotationLabelerCriteriaV3} [criteria] criteria for annotation
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllLabelerAnnotationsV3UsingCriteria(criteria?: AnnotationLabelerCriteriaV3, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfAnnotationResponseDTO> {
            return localVarFp.getAllLabelerAnnotationsV3UsingCriteria(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} jobId jobId
         * @param {AnnotationJobRequestDTO} [annotationJobRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLabelerAnnotationsByJobUsingPOST(jobId: number, annotationJobRequestDTO?: AnnotationJobRequestDTO, options?: any): AxiosPromise<RestResponseOfListOfAnnotationResponseDTO> {
            return localVarFp.saveLabelerAnnotationsByJobUsingPOST(jobId, annotationJobRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAllAnnotationsByDatasetRunUsingGET operation in AnnotationResourceV3Api.
 * @export
 * @interface AnnotationResourceV3ApiGetAllAnnotationsByDatasetRunUsingGETRequest
 */
export interface AnnotationResourceV3ApiGetAllAnnotationsByDatasetRunUsingGETRequest {
    /**
     * runId
     * @type {string}
     * @memberof AnnotationResourceV3ApiGetAllAnnotationsByDatasetRunUsingGET
     */
    readonly runId: string
}

/**
 * Request parameters for getAllAnnotationsByDatasetUsingGET operation in AnnotationResourceV3Api.
 * @export
 * @interface AnnotationResourceV3ApiGetAllAnnotationsByDatasetUsingGETRequest
 */
export interface AnnotationResourceV3ApiGetAllAnnotationsByDatasetUsingGETRequest {
    /**
     * datasetVersionId
     * @type {number}
     * @memberof AnnotationResourceV3ApiGetAllAnnotationsByDatasetUsingGET
     */
    readonly datasetVersionId: number
}

/**
 * Request parameters for getAllAnnotationsByTaskUsingGET operation in AnnotationResourceV3Api.
 * @export
 * @interface AnnotationResourceV3ApiGetAllAnnotationsByTaskUsingGETRequest
 */
export interface AnnotationResourceV3ApiGetAllAnnotationsByTaskUsingGETRequest {
    /**
     * taskId
     * @type {number}
     * @memberof AnnotationResourceV3ApiGetAllAnnotationsByTaskUsingGET
     */
    readonly taskId: number
}

/**
 * Request parameters for getAllAnnotationsV3UsingCriteria operation in AnnotationResourceV3Api.
 * @export
 * @interface AnnotationResourceV3ApiGetAllAnnotationsV3UsingCriteriaRequest
 */
export interface AnnotationResourceV3ApiGetAllAnnotationsV3UsingCriteriaRequest {
    /**
     * criteria for annotation
     * @type {AnnotationCriteria}
     * @memberof AnnotationResourceV3ApiGetAllAnnotationsV3UsingCriteria
     */
    readonly criteria?: AnnotationCriteria
}

/**
 * Request parameters for getAllLabelerAnnotationsByJobUsingGET operation in AnnotationResourceV3Api.
 * @export
 * @interface AnnotationResourceV3ApiGetAllLabelerAnnotationsByJobUsingGETRequest
 */
export interface AnnotationResourceV3ApiGetAllLabelerAnnotationsByJobUsingGETRequest {
    /**
     * jobId
     * @type {number}
     * @memberof AnnotationResourceV3ApiGetAllLabelerAnnotationsByJobUsingGET
     */
    readonly jobId: number
}

/**
 * Request parameters for getAllLabelerAnnotationsV3UsingCriteria operation in AnnotationResourceV3Api.
 * @export
 * @interface AnnotationResourceV3ApiGetAllLabelerAnnotationsV3UsingCriteriaRequest
 */
export interface AnnotationResourceV3ApiGetAllLabelerAnnotationsV3UsingCriteriaRequest {
    /**
     * criteria for annotation
     * @type {AnnotationLabelerCriteriaV3}
     * @memberof AnnotationResourceV3ApiGetAllLabelerAnnotationsV3UsingCriteria
     */
    readonly criteria?: AnnotationLabelerCriteriaV3

    /**
     * pageable
     * @type {Pageable}
     * @memberof AnnotationResourceV3ApiGetAllLabelerAnnotationsV3UsingCriteria
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for saveLabelerAnnotationsByJobUsingPOST operation in AnnotationResourceV3Api.
 * @export
 * @interface AnnotationResourceV3ApiSaveLabelerAnnotationsByJobUsingPOSTRequest
 */
export interface AnnotationResourceV3ApiSaveLabelerAnnotationsByJobUsingPOSTRequest {
    /**
     * jobId
     * @type {number}
     * @memberof AnnotationResourceV3ApiSaveLabelerAnnotationsByJobUsingPOST
     */
    readonly jobId: number

    /**
     * 
     * @type {AnnotationJobRequestDTO}
     * @memberof AnnotationResourceV3ApiSaveLabelerAnnotationsByJobUsingPOST
     */
    readonly annotationJobRequestDTO?: AnnotationJobRequestDTO
}

/**
 * AnnotationResourceV3Api - object-oriented interface
 * @export
 * @class AnnotationResourceV3Api
 * @extends {BaseAPI}
 */
export class AnnotationResourceV3Api extends BaseAPI {
    /**
     * 
     * @param {AnnotationResourceV3ApiGetAllAnnotationsByDatasetRunUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationResourceV3Api
     */
    public getAllAnnotationsByDatasetRunUsingGET(requestParameters: AnnotationResourceV3ApiGetAllAnnotationsByDatasetRunUsingGETRequest, options?: AxiosRequestConfig) {
        return AnnotationResourceV3ApiFp(this.configuration).getAllAnnotationsByDatasetRunUsingGET(requestParameters.runId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get ground truth annotations for datasetVersionId
     * @param {AnnotationResourceV3ApiGetAllAnnotationsByDatasetUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationResourceV3Api
     */
    public getAllAnnotationsByDatasetUsingGET(requestParameters: AnnotationResourceV3ApiGetAllAnnotationsByDatasetUsingGETRequest, options?: AxiosRequestConfig) {
        return AnnotationResourceV3ApiFp(this.configuration).getAllAnnotationsByDatasetUsingGET(requestParameters.datasetVersionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnotationResourceV3ApiGetAllAnnotationsByTaskUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationResourceV3Api
     */
    public getAllAnnotationsByTaskUsingGET(requestParameters: AnnotationResourceV3ApiGetAllAnnotationsByTaskUsingGETRequest, options?: AxiosRequestConfig) {
        return AnnotationResourceV3ApiFp(this.configuration).getAllAnnotationsByTaskUsingGET(requestParameters.taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnotationResourceV3ApiGetAllAnnotationsV3UsingCriteriaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AnnotationResourceV3Api
     */
    public getAllAnnotationsV3UsingCriteria(requestParameters: AnnotationResourceV3ApiGetAllAnnotationsV3UsingCriteriaRequest = {}, options?: AxiosRequestConfig) {
        return AnnotationResourceV3ApiFp(this.configuration).getAllAnnotationsV3UsingCriteria(requestParameters.criteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnotationResourceV3ApiGetAllLabelerAnnotationsByJobUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationResourceV3Api
     */
    public getAllLabelerAnnotationsByJobUsingGET(requestParameters: AnnotationResourceV3ApiGetAllLabelerAnnotationsByJobUsingGETRequest, options?: AxiosRequestConfig) {
        return AnnotationResourceV3ApiFp(this.configuration).getAllLabelerAnnotationsByJobUsingGET(requestParameters.jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnotationResourceV3ApiGetAllLabelerAnnotationsV3UsingCriteriaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AnnotationResourceV3Api
     */
    public getAllLabelerAnnotationsV3UsingCriteria(requestParameters: AnnotationResourceV3ApiGetAllLabelerAnnotationsV3UsingCriteriaRequest = {}, options?: AxiosRequestConfig) {
        return AnnotationResourceV3ApiFp(this.configuration).getAllLabelerAnnotationsV3UsingCriteria(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnotationResourceV3ApiSaveLabelerAnnotationsByJobUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationResourceV3Api
     */
    public saveLabelerAnnotationsByJobUsingPOST(requestParameters: AnnotationResourceV3ApiSaveLabelerAnnotationsByJobUsingPOSTRequest, options?: AxiosRequestConfig) {
        return AnnotationResourceV3ApiFp(this.configuration).saveLabelerAnnotationsByJobUsingPOST(requestParameters.jobId, requestParameters.annotationJobRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BatchObservationResourceApi - axios parameter creator
 * @export
 */
export const BatchObservationResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary countBatchObservations
         * @param {number} [batchId] batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countBatchObservationsUsingGET: async (batchId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/batch-observations/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteBatchObservation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatchObservationUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBatchObservationUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/batch-observations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllBatchObservations
         * @param {number} batchId batchId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBatchObservationsUsingGET: async (batchId: number, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('getAllBatchObservationsUsingGET', 'batchId', batchId)
            const localVarPath = `/api/v1/batch-observations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getBatchObservation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchObservationUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBatchObservationUsingGET', 'id', id)
            const localVarPath = `/api/v1/batch-observations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BatchObservationResourceApi - functional programming interface
 * @export
 */
export const BatchObservationResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BatchObservationResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary countBatchObservations
         * @param {number} [batchId] batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countBatchObservationsUsingGET(batchId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfLong>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countBatchObservationsUsingGET(batchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteBatchObservation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBatchObservationUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBatchObservationUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllBatchObservations
         * @param {number} batchId batchId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBatchObservationsUsingGET(batchId: number, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfBatchObservationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBatchObservationsUsingGET(batchId, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getBatchObservation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatchObservationUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfBatchObservationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatchObservationUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BatchObservationResourceApi - factory interface
 * @export
 */
export const BatchObservationResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BatchObservationResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary countBatchObservations
         * @param {number} [batchId] batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countBatchObservationsUsingGET(batchId?: number, options?: any): AxiosPromise<RestResponseOfLong> {
            return localVarFp.countBatchObservationsUsingGET(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteBatchObservation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatchObservationUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteBatchObservationUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllBatchObservations
         * @param {number} batchId batchId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBatchObservationsUsingGET(batchId: number, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfBatchObservationDTO> {
            return localVarFp.getAllBatchObservationsUsingGET(batchId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getBatchObservation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchObservationUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfBatchObservationDTO> {
            return localVarFp.getBatchObservationUsingGET(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countBatchObservationsUsingGET operation in BatchObservationResourceApi.
 * @export
 * @interface BatchObservationResourceApiCountBatchObservationsUsingGETRequest
 */
export interface BatchObservationResourceApiCountBatchObservationsUsingGETRequest {
    /**
     * batchId
     * @type {number}
     * @memberof BatchObservationResourceApiCountBatchObservationsUsingGET
     */
    readonly batchId?: number
}

/**
 * Request parameters for deleteBatchObservationUsingDELETE operation in BatchObservationResourceApi.
 * @export
 * @interface BatchObservationResourceApiDeleteBatchObservationUsingDELETERequest
 */
export interface BatchObservationResourceApiDeleteBatchObservationUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof BatchObservationResourceApiDeleteBatchObservationUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for getAllBatchObservationsUsingGET operation in BatchObservationResourceApi.
 * @export
 * @interface BatchObservationResourceApiGetAllBatchObservationsUsingGETRequest
 */
export interface BatchObservationResourceApiGetAllBatchObservationsUsingGETRequest {
    /**
     * batchId
     * @type {number}
     * @memberof BatchObservationResourceApiGetAllBatchObservationsUsingGET
     */
    readonly batchId: number

    /**
     * pageable
     * @type {Pageable}
     * @memberof BatchObservationResourceApiGetAllBatchObservationsUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getBatchObservationUsingGET operation in BatchObservationResourceApi.
 * @export
 * @interface BatchObservationResourceApiGetBatchObservationUsingGETRequest
 */
export interface BatchObservationResourceApiGetBatchObservationUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof BatchObservationResourceApiGetBatchObservationUsingGET
     */
    readonly id: number
}

/**
 * BatchObservationResourceApi - object-oriented interface
 * @export
 * @class BatchObservationResourceApi
 * @extends {BaseAPI}
 */
export class BatchObservationResourceApi extends BaseAPI {
    /**
     * 
     * @summary countBatchObservations
     * @param {BatchObservationResourceApiCountBatchObservationsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchObservationResourceApi
     */
    public countBatchObservationsUsingGET(requestParameters: BatchObservationResourceApiCountBatchObservationsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return BatchObservationResourceApiFp(this.configuration).countBatchObservationsUsingGET(requestParameters.batchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteBatchObservation
     * @param {BatchObservationResourceApiDeleteBatchObservationUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchObservationResourceApi
     */
    public deleteBatchObservationUsingDELETE(requestParameters: BatchObservationResourceApiDeleteBatchObservationUsingDELETERequest, options?: AxiosRequestConfig) {
        return BatchObservationResourceApiFp(this.configuration).deleteBatchObservationUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllBatchObservations
     * @param {BatchObservationResourceApiGetAllBatchObservationsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchObservationResourceApi
     */
    public getAllBatchObservationsUsingGET(requestParameters: BatchObservationResourceApiGetAllBatchObservationsUsingGETRequest, options?: AxiosRequestConfig) {
        return BatchObservationResourceApiFp(this.configuration).getAllBatchObservationsUsingGET(requestParameters.batchId, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getBatchObservation
     * @param {BatchObservationResourceApiGetBatchObservationUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchObservationResourceApi
     */
    public getBatchObservationUsingGET(requestParameters: BatchObservationResourceApiGetBatchObservationUsingGETRequest, options?: AxiosRequestConfig) {
        return BatchObservationResourceApiFp(this.configuration).getBatchObservationUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BatchResourceApi - axios parameter creator
 * @export
 */
export const BatchResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary countBatches
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countBatchesUsingGET: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('countBatchesUsingGET', 'projectId', projectId)
            const localVarPath = `/api/v1/batches/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id batchId
         * @param {BatchAnnotatingRequestDTO} [batchAnnotatingRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotatingBatchUsingPOST: async (id: number, batchAnnotatingRequestDTO?: BatchAnnotatingRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createAnnotatingBatchUsingPOST', 'id', id)
            const localVarPath = `/api/v1/batches/{id}/annotating`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchAnnotatingRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createBatchAsync
         * @param {BatchInfoRequest} [batchInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatchAsyncUsingPOST: async (batchInfoRequest?: BatchInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createBatch
         * @param {BatchInfoRequest} [batchInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatchUsingPOST: async (batchInfoRequest?: BatchInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createExportedDataset
         * @param {number} [projectId] id
         * @param {number} [batchId] id
         * @param {ExportRequest} [exportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExportedDatasetUsingPOST: async (projectId?: number, batchId?: number, exportRequest?: ExportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/batches/export-dataset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteBatch
         * @param {number} id batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatch: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBatch', 'id', id)
            const localVarPath = `/api/v1/batches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary distributeDataset
         * @param {number} id id
         * @param {Distribution} [distribution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributeDatasetUsingPOST: async (id: number, distribution?: Distribution, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('distributeDatasetUsingPOST', 'id', id)
            const localVarPath = `/api/v1/batches/{id}/distribute`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(distribution, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadAsyncDataSetUsingPOST
         * @param {number} id id
         * @param {DownloadDatasetRequestDTO} [downloadDatasetRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAsyncDataSetUsingPOST: async (id: number, downloadDatasetRequestDTO?: DownloadDatasetRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadAsyncDataSetUsingPOST', 'id', id)
            const localVarPath = `/api/v2/batches/{id}/download-dataset`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadDatasetRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadDataSet
         * @param {number} id id
         * @param {DownloadDatasetRequestDTO} [downloadDatasetRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDataSetUsingPOST: async (id: number, downloadDatasetRequestDTO?: DownloadDatasetRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadDataSetUsingPOST', 'id', id)
            const localVarPath = `/api/v1/batches/{id}/download-dataset`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadDatasetRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllBatches
         * @param {BatchCriteria} [criteria] criteria
         * @param {Pageable} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBatchesUsingGET: async (criteria?: BatchCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotatingBatchUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnnotatingBatchUsingGET', 'id', id)
            const localVarPath = `/api/v1/batches/{id}/annotating`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getBatchInfo
         * @param {number} id batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchInfoUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBatchInfoUsingGET', 'id', id)
            const localVarPath = `/api/v1/batches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getF1Statistic
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getF1StatisticUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getF1StatisticUsingGET', 'id', id)
            const localVarPath = `/api/v1/batches/{id}/f1`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getLabelDistribution
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelDistributionUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLabelDistributionUsingGET', 'id', id)
            const localVarPath = `/api/v1/batches/{id}/label-distribution`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getStatistic
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatisticUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStatisticUsingGET', 'id', id)
            const localVarPath = `/api/v1/batches/{id}/statistic`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateBatch
         * @param {number} id batchId
         * @param {BatchInfoRequest} [batchInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBatchInfoUsingPATCH: async (id: number, batchInfoRequest?: BatchInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBatchInfoUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/batches/{id}/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateBatch
         * @param {number} id batchId
         * @param {BatchRequestDTO} [batchRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBatchUsingPATCH: async (id: number, batchRequestDTO?: BatchRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBatchUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/batches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateStatusBatch
         * @param {number} id batchId
         * @param {UpdateStatusBatchRequest} [updateStatusBatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusBatchUsingPOST: async (id: number, updateStatusBatchRequest?: UpdateStatusBatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateStatusBatchUsingPOST', 'id', id)
            const localVarPath = `/api/v1/batches/{id}/update-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStatusBatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BatchResourceApi - functional programming interface
 * @export
 */
export const BatchResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BatchResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary countBatches
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countBatchesUsingGET(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfLong>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countBatchesUsingGET(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id batchId
         * @param {BatchAnnotatingRequestDTO} [batchAnnotatingRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnnotatingBatchUsingPOST(id: number, batchAnnotatingRequestDTO?: BatchAnnotatingRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfBatchAnnotatingResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnnotatingBatchUsingPOST(id, batchAnnotatingRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createBatchAsync
         * @param {BatchInfoRequest} [batchInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBatchAsyncUsingPOST(batchInfoRequest?: BatchInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfBatchInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBatchAsyncUsingPOST(batchInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createBatch
         * @param {BatchInfoRequest} [batchInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBatchUsingPOST(batchInfoRequest?: BatchInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfBatchInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBatchUsingPOST(batchInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createExportedDataset
         * @param {number} [projectId] id
         * @param {number} [batchId] id
         * @param {ExportRequest} [exportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExportedDatasetUsingPOST(projectId?: number, batchId?: number, exportRequest?: ExportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetVersionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExportedDatasetUsingPOST(projectId, batchId, exportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteBatch
         * @param {number} id batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBatch(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBatch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary distributeDataset
         * @param {number} id id
         * @param {Distribution} [distribution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributeDatasetUsingPOST(id: number, distribution?: Distribution, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributeDatasetUsingPOST(id, distribution, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary downloadAsyncDataSetUsingPOST
         * @param {number} id id
         * @param {DownloadDatasetRequestDTO} [downloadDatasetRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAsyncDataSetUsingPOST(id: number, downloadDatasetRequestDTO?: DownloadDatasetRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadDatasetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAsyncDataSetUsingPOST(id, downloadDatasetRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary downloadDataSet
         * @param {number} id id
         * @param {DownloadDatasetRequestDTO} [downloadDatasetRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDataSetUsingPOST(id: number, downloadDatasetRequestDTO?: DownloadDatasetRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDataSetUsingPOST(id, downloadDatasetRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllBatches
         * @param {BatchCriteria} [criteria] criteria
         * @param {Pageable} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBatchesUsingGET(criteria?: BatchCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfBatchResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBatchesUsingGET(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnotatingBatchUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfBatchAnnotatingResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotatingBatchUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getBatchInfo
         * @param {number} id batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatchInfoUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfBatchInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatchInfoUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getF1Statistic
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getF1StatisticUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfBatchLabelerStatistic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getF1StatisticUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getLabelDistribution
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLabelDistributionUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfLabelDistribution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLabelDistributionUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getStatistic
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatisticUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfBatchProgress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatisticUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateBatch
         * @param {number} id batchId
         * @param {BatchInfoRequest} [batchInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBatchInfoUsingPATCH(id: number, batchInfoRequest?: BatchInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfBatchInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBatchInfoUsingPATCH(id, batchInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateBatch
         * @param {number} id batchId
         * @param {BatchRequestDTO} [batchRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBatchUsingPATCH(id: number, batchRequestDTO?: BatchRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfBatchResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBatchUsingPATCH(id, batchRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateStatusBatch
         * @param {number} id batchId
         * @param {UpdateStatusBatchRequest} [updateStatusBatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStatusBatchUsingPOST(id: number, updateStatusBatchRequest?: UpdateStatusBatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStatusBatchUsingPOST(id, updateStatusBatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BatchResourceApi - factory interface
 * @export
 */
export const BatchResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BatchResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary countBatches
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countBatchesUsingGET(projectId: number, options?: any): AxiosPromise<RestResponseOfLong> {
            return localVarFp.countBatchesUsingGET(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id batchId
         * @param {BatchAnnotatingRequestDTO} [batchAnnotatingRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotatingBatchUsingPOST(id: number, batchAnnotatingRequestDTO?: BatchAnnotatingRequestDTO, options?: any): AxiosPromise<RestResponseOfBatchAnnotatingResponseDTO> {
            return localVarFp.createAnnotatingBatchUsingPOST(id, batchAnnotatingRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createBatchAsync
         * @param {BatchInfoRequest} [batchInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatchAsyncUsingPOST(batchInfoRequest?: BatchInfoRequest, options?: any): AxiosPromise<RestResponseOfBatchInfo> {
            return localVarFp.createBatchAsyncUsingPOST(batchInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createBatch
         * @param {BatchInfoRequest} [batchInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatchUsingPOST(batchInfoRequest?: BatchInfoRequest, options?: any): AxiosPromise<RestResponseOfBatchInfo> {
            return localVarFp.createBatchUsingPOST(batchInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createExportedDataset
         * @param {number} [projectId] id
         * @param {number} [batchId] id
         * @param {ExportRequest} [exportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExportedDatasetUsingPOST(projectId?: number, batchId?: number, exportRequest?: ExportRequest, options?: any): AxiosPromise<RestResponseOfDatasetVersionDTO> {
            return localVarFp.createExportedDatasetUsingPOST(projectId, batchId, exportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteBatch
         * @param {number} id batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatch(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteBatch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary distributeDataset
         * @param {number} id id
         * @param {Distribution} [distribution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributeDatasetUsingPOST(id: number, distribution?: Distribution, options?: any): AxiosPromise<RestResponseOfBoolean> {
            return localVarFp.distributeDatasetUsingPOST(id, distribution, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary downloadAsyncDataSetUsingPOST
         * @param {number} id id
         * @param {DownloadDatasetRequestDTO} [downloadDatasetRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAsyncDataSetUsingPOST(id: number, downloadDatasetRequestDTO?: DownloadDatasetRequestDTO, options?: any): AxiosPromise<DownloadDatasetResponse> {
            return localVarFp.downloadAsyncDataSetUsingPOST(id, downloadDatasetRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary downloadDataSet
         * @param {number} id id
         * @param {DownloadDatasetRequestDTO} [downloadDatasetRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDataSetUsingPOST(id: number, downloadDatasetRequestDTO?: DownloadDatasetRequestDTO, options?: any): AxiosPromise<string> {
            return localVarFp.downloadDataSetUsingPOST(id, downloadDatasetRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllBatches
         * @param {BatchCriteria} [criteria] criteria
         * @param {Pageable} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBatchesUsingGET(criteria?: BatchCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfBatchResponseDTO> {
            return localVarFp.getAllBatchesUsingGET(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotatingBatchUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfListOfBatchAnnotatingResponseDTO> {
            return localVarFp.getAnnotatingBatchUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getBatchInfo
         * @param {number} id batchId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchInfoUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfBatchInfo> {
            return localVarFp.getBatchInfoUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getF1Statistic
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getF1StatisticUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfListOfBatchLabelerStatistic> {
            return localVarFp.getF1StatisticUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getLabelDistribution
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelDistributionUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfListOfLabelDistribution> {
            return localVarFp.getLabelDistributionUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getStatistic
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatisticUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfBatchProgress> {
            return localVarFp.getStatisticUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateBatch
         * @param {number} id batchId
         * @param {BatchInfoRequest} [batchInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBatchInfoUsingPATCH(id: number, batchInfoRequest?: BatchInfoRequest, options?: any): AxiosPromise<RestResponseOfBatchInfo> {
            return localVarFp.updateBatchInfoUsingPATCH(id, batchInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateBatch
         * @param {number} id batchId
         * @param {BatchRequestDTO} [batchRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBatchUsingPATCH(id: number, batchRequestDTO?: BatchRequestDTO, options?: any): AxiosPromise<RestResponseOfBatchResponseDTO> {
            return localVarFp.updateBatchUsingPATCH(id, batchRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateStatusBatch
         * @param {number} id batchId
         * @param {UpdateStatusBatchRequest} [updateStatusBatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusBatchUsingPOST(id: number, updateStatusBatchRequest?: UpdateStatusBatchRequest, options?: any): AxiosPromise<RestResponseOfBoolean> {
            return localVarFp.updateStatusBatchUsingPOST(id, updateStatusBatchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countBatchesUsingGET operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiCountBatchesUsingGETRequest
 */
export interface BatchResourceApiCountBatchesUsingGETRequest {
    /**
     * 
     * @type {number}
     * @memberof BatchResourceApiCountBatchesUsingGET
     */
    readonly projectId: number
}

/**
 * Request parameters for createAnnotatingBatchUsingPOST operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiCreateAnnotatingBatchUsingPOSTRequest
 */
export interface BatchResourceApiCreateAnnotatingBatchUsingPOSTRequest {
    /**
     * batchId
     * @type {number}
     * @memberof BatchResourceApiCreateAnnotatingBatchUsingPOST
     */
    readonly id: number

    /**
     * 
     * @type {BatchAnnotatingRequestDTO}
     * @memberof BatchResourceApiCreateAnnotatingBatchUsingPOST
     */
    readonly batchAnnotatingRequestDTO?: BatchAnnotatingRequestDTO
}

/**
 * Request parameters for createBatchAsyncUsingPOST operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiCreateBatchAsyncUsingPOSTRequest
 */
export interface BatchResourceApiCreateBatchAsyncUsingPOSTRequest {
    /**
     * 
     * @type {BatchInfoRequest}
     * @memberof BatchResourceApiCreateBatchAsyncUsingPOST
     */
    readonly batchInfoRequest?: BatchInfoRequest
}

/**
 * Request parameters for createBatchUsingPOST operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiCreateBatchUsingPOSTRequest
 */
export interface BatchResourceApiCreateBatchUsingPOSTRequest {
    /**
     * 
     * @type {BatchInfoRequest}
     * @memberof BatchResourceApiCreateBatchUsingPOST
     */
    readonly batchInfoRequest?: BatchInfoRequest
}

/**
 * Request parameters for createExportedDatasetUsingPOST operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiCreateExportedDatasetUsingPOSTRequest
 */
export interface BatchResourceApiCreateExportedDatasetUsingPOSTRequest {
    /**
     * id
     * @type {number}
     * @memberof BatchResourceApiCreateExportedDatasetUsingPOST
     */
    readonly projectId?: number

    /**
     * id
     * @type {number}
     * @memberof BatchResourceApiCreateExportedDatasetUsingPOST
     */
    readonly batchId?: number

    /**
     * 
     * @type {ExportRequest}
     * @memberof BatchResourceApiCreateExportedDatasetUsingPOST
     */
    readonly exportRequest?: ExportRequest
}

/**
 * Request parameters for deleteBatch operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiDeleteBatchRequest
 */
export interface BatchResourceApiDeleteBatchRequest {
    /**
     * batchId
     * @type {number}
     * @memberof BatchResourceApiDeleteBatch
     */
    readonly id: number
}

/**
 * Request parameters for distributeDatasetUsingPOST operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiDistributeDatasetUsingPOSTRequest
 */
export interface BatchResourceApiDistributeDatasetUsingPOSTRequest {
    /**
     * id
     * @type {number}
     * @memberof BatchResourceApiDistributeDatasetUsingPOST
     */
    readonly id: number

    /**
     * 
     * @type {Distribution}
     * @memberof BatchResourceApiDistributeDatasetUsingPOST
     */
    readonly distribution?: Distribution
}

/**
 * Request parameters for downloadAsyncDataSetUsingPOST operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiDownloadAsyncDataSetUsingPOSTRequest
 */
export interface BatchResourceApiDownloadAsyncDataSetUsingPOSTRequest {
    /**
     * id
     * @type {number}
     * @memberof BatchResourceApiDownloadAsyncDataSetUsingPOST
     */
    readonly id: number

    /**
     * 
     * @type {DownloadDatasetRequestDTO}
     * @memberof BatchResourceApiDownloadAsyncDataSetUsingPOST
     */
    readonly downloadDatasetRequestDTO?: DownloadDatasetRequestDTO
}

/**
 * Request parameters for downloadDataSetUsingPOST operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiDownloadDataSetUsingPOSTRequest
 */
export interface BatchResourceApiDownloadDataSetUsingPOSTRequest {
    /**
     * id
     * @type {number}
     * @memberof BatchResourceApiDownloadDataSetUsingPOST
     */
    readonly id: number

    /**
     * 
     * @type {DownloadDatasetRequestDTO}
     * @memberof BatchResourceApiDownloadDataSetUsingPOST
     */
    readonly downloadDatasetRequestDTO?: DownloadDatasetRequestDTO
}

/**
 * Request parameters for getAllBatchesUsingGET operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiGetAllBatchesUsingGETRequest
 */
export interface BatchResourceApiGetAllBatchesUsingGETRequest {
    /**
     * criteria
     * @type {BatchCriteria}
     * @memberof BatchResourceApiGetAllBatchesUsingGET
     */
    readonly criteria?: BatchCriteria

    /**
     * 
     * @type {Pageable}
     * @memberof BatchResourceApiGetAllBatchesUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getAnnotatingBatchUsingGET operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiGetAnnotatingBatchUsingGETRequest
 */
export interface BatchResourceApiGetAnnotatingBatchUsingGETRequest {
    /**
     * batchId
     * @type {number}
     * @memberof BatchResourceApiGetAnnotatingBatchUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for getBatchInfoUsingGET operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiGetBatchInfoUsingGETRequest
 */
export interface BatchResourceApiGetBatchInfoUsingGETRequest {
    /**
     * batchId
     * @type {number}
     * @memberof BatchResourceApiGetBatchInfoUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for getF1StatisticUsingGET operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiGetF1StatisticUsingGETRequest
 */
export interface BatchResourceApiGetF1StatisticUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof BatchResourceApiGetF1StatisticUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for getLabelDistributionUsingGET operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiGetLabelDistributionUsingGETRequest
 */
export interface BatchResourceApiGetLabelDistributionUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof BatchResourceApiGetLabelDistributionUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for getStatisticUsingGET operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiGetStatisticUsingGETRequest
 */
export interface BatchResourceApiGetStatisticUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof BatchResourceApiGetStatisticUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for updateBatchInfoUsingPATCH operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiUpdateBatchInfoUsingPATCHRequest
 */
export interface BatchResourceApiUpdateBatchInfoUsingPATCHRequest {
    /**
     * batchId
     * @type {number}
     * @memberof BatchResourceApiUpdateBatchInfoUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {BatchInfoRequest}
     * @memberof BatchResourceApiUpdateBatchInfoUsingPATCH
     */
    readonly batchInfoRequest?: BatchInfoRequest
}

/**
 * Request parameters for updateBatchUsingPATCH operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiUpdateBatchUsingPATCHRequest
 */
export interface BatchResourceApiUpdateBatchUsingPATCHRequest {
    /**
     * batchId
     * @type {number}
     * @memberof BatchResourceApiUpdateBatchUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {BatchRequestDTO}
     * @memberof BatchResourceApiUpdateBatchUsingPATCH
     */
    readonly batchRequestDTO?: BatchRequestDTO
}

/**
 * Request parameters for updateStatusBatchUsingPOST operation in BatchResourceApi.
 * @export
 * @interface BatchResourceApiUpdateStatusBatchUsingPOSTRequest
 */
export interface BatchResourceApiUpdateStatusBatchUsingPOSTRequest {
    /**
     * batchId
     * @type {number}
     * @memberof BatchResourceApiUpdateStatusBatchUsingPOST
     */
    readonly id: number

    /**
     * 
     * @type {UpdateStatusBatchRequest}
     * @memberof BatchResourceApiUpdateStatusBatchUsingPOST
     */
    readonly updateStatusBatchRequest?: UpdateStatusBatchRequest
}

/**
 * BatchResourceApi - object-oriented interface
 * @export
 * @class BatchResourceApi
 * @extends {BaseAPI}
 */
export class BatchResourceApi extends BaseAPI {
    /**
     * 
     * @summary countBatches
     * @param {BatchResourceApiCountBatchesUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public countBatchesUsingGET(requestParameters: BatchResourceApiCountBatchesUsingGETRequest, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).countBatchesUsingGET(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchResourceApiCreateAnnotatingBatchUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public createAnnotatingBatchUsingPOST(requestParameters: BatchResourceApiCreateAnnotatingBatchUsingPOSTRequest, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).createAnnotatingBatchUsingPOST(requestParameters.id, requestParameters.batchAnnotatingRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createBatchAsync
     * @param {BatchResourceApiCreateBatchAsyncUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public createBatchAsyncUsingPOST(requestParameters: BatchResourceApiCreateBatchAsyncUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).createBatchAsyncUsingPOST(requestParameters.batchInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createBatch
     * @param {BatchResourceApiCreateBatchUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public createBatchUsingPOST(requestParameters: BatchResourceApiCreateBatchUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).createBatchUsingPOST(requestParameters.batchInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createExportedDataset
     * @param {BatchResourceApiCreateExportedDatasetUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public createExportedDatasetUsingPOST(requestParameters: BatchResourceApiCreateExportedDatasetUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).createExportedDatasetUsingPOST(requestParameters.projectId, requestParameters.batchId, requestParameters.exportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteBatch
     * @param {BatchResourceApiDeleteBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public deleteBatch(requestParameters: BatchResourceApiDeleteBatchRequest, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).deleteBatch(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary distributeDataset
     * @param {BatchResourceApiDistributeDatasetUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public distributeDatasetUsingPOST(requestParameters: BatchResourceApiDistributeDatasetUsingPOSTRequest, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).distributeDatasetUsingPOST(requestParameters.id, requestParameters.distribution, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary downloadAsyncDataSetUsingPOST
     * @param {BatchResourceApiDownloadAsyncDataSetUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public downloadAsyncDataSetUsingPOST(requestParameters: BatchResourceApiDownloadAsyncDataSetUsingPOSTRequest, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).downloadAsyncDataSetUsingPOST(requestParameters.id, requestParameters.downloadDatasetRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary downloadDataSet
     * @param {BatchResourceApiDownloadDataSetUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public downloadDataSetUsingPOST(requestParameters: BatchResourceApiDownloadDataSetUsingPOSTRequest, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).downloadDataSetUsingPOST(requestParameters.id, requestParameters.downloadDatasetRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllBatches
     * @param {BatchResourceApiGetAllBatchesUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public getAllBatchesUsingGET(requestParameters: BatchResourceApiGetAllBatchesUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).getAllBatchesUsingGET(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchResourceApiGetAnnotatingBatchUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public getAnnotatingBatchUsingGET(requestParameters: BatchResourceApiGetAnnotatingBatchUsingGETRequest, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).getAnnotatingBatchUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getBatchInfo
     * @param {BatchResourceApiGetBatchInfoUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public getBatchInfoUsingGET(requestParameters: BatchResourceApiGetBatchInfoUsingGETRequest, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).getBatchInfoUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getF1Statistic
     * @param {BatchResourceApiGetF1StatisticUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public getF1StatisticUsingGET(requestParameters: BatchResourceApiGetF1StatisticUsingGETRequest, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).getF1StatisticUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getLabelDistribution
     * @param {BatchResourceApiGetLabelDistributionUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public getLabelDistributionUsingGET(requestParameters: BatchResourceApiGetLabelDistributionUsingGETRequest, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).getLabelDistributionUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getStatistic
     * @param {BatchResourceApiGetStatisticUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public getStatisticUsingGET(requestParameters: BatchResourceApiGetStatisticUsingGETRequest, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).getStatisticUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateBatch
     * @param {BatchResourceApiUpdateBatchInfoUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public updateBatchInfoUsingPATCH(requestParameters: BatchResourceApiUpdateBatchInfoUsingPATCHRequest, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).updateBatchInfoUsingPATCH(requestParameters.id, requestParameters.batchInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateBatch
     * @param {BatchResourceApiUpdateBatchUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public updateBatchUsingPATCH(requestParameters: BatchResourceApiUpdateBatchUsingPATCHRequest, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).updateBatchUsingPATCH(requestParameters.id, requestParameters.batchRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateStatusBatch
     * @param {BatchResourceApiUpdateStatusBatchUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchResourceApi
     */
    public updateStatusBatchUsingPOST(requestParameters: BatchResourceApiUpdateStatusBatchUsingPOSTRequest, options?: AxiosRequestConfig) {
        return BatchResourceApiFp(this.configuration).updateStatusBatchUsingPOST(requestParameters.id, requestParameters.updateStatusBatchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DatasetVersionAnnotationResourceApi - axios parameter creator
 * @export
 */
export const DatasetVersionAnnotationResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get list mediaId by dataset version id
         * @param {number} id 
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMediaIdsByDatasetVersionId: async (id: number, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAllMediaIdsByDatasetVersionId', 'id', id)
            const localVarPath = `/api/v1/annotations/dataset-version/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetVersionAnnotationResourceApi - functional programming interface
 * @export
 */
export const DatasetVersionAnnotationResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetVersionAnnotationResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * Get list mediaId by dataset version id
         * @param {number} id 
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMediaIdsByDatasetVersionId(id: number, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListMediaIds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMediaIdsByDatasetVersionId(id, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DatasetVersionAnnotationResourceApi - factory interface
 * @export
 */
export const DatasetVersionAnnotationResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetVersionAnnotationResourceApiFp(configuration)
    return {
        /**
         * Get list mediaId by dataset version id
         * @param {number} id 
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMediaIdsByDatasetVersionId(id: number, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListMediaIds> {
            return localVarFp.getAllMediaIdsByDatasetVersionId(id, pageable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAllMediaIdsByDatasetVersionId operation in DatasetVersionAnnotationResourceApi.
 * @export
 * @interface DatasetVersionAnnotationResourceApiGetAllMediaIdsByDatasetVersionIdRequest
 */
export interface DatasetVersionAnnotationResourceApiGetAllMediaIdsByDatasetVersionIdRequest {
    /**
     * 
     * @type {number}
     * @memberof DatasetVersionAnnotationResourceApiGetAllMediaIdsByDatasetVersionId
     */
    readonly id: number

    /**
     * pageable
     * @type {Pageable}
     * @memberof DatasetVersionAnnotationResourceApiGetAllMediaIdsByDatasetVersionId
     */
    readonly pageable?: Pageable
}

/**
 * DatasetVersionAnnotationResourceApi - object-oriented interface
 * @export
 * @class DatasetVersionAnnotationResourceApi
 * @extends {BaseAPI}
 */
export class DatasetVersionAnnotationResourceApi extends BaseAPI {
    /**
     * Get list mediaId by dataset version id
     * @param {DatasetVersionAnnotationResourceApiGetAllMediaIdsByDatasetVersionIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetVersionAnnotationResourceApi
     */
    public getAllMediaIdsByDatasetVersionId(requestParameters: DatasetVersionAnnotationResourceApiGetAllMediaIdsByDatasetVersionIdRequest, options?: AxiosRequestConfig) {
        return DatasetVersionAnnotationResourceApiFp(this.configuration).getAllMediaIdsByDatasetVersionId(requestParameters.id, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileTsvResourceApi - axios parameter creator
 * @export
 */
export const FileTsvResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary exportFileTSV
         * @param {number} fileId fileId
         * @param {TextAnnotationItem} [textAnnotationItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFileTSVUsingPOST: async (fileId: number, textAnnotationItem?: TextAnnotationItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('exportFileTSVUsingPOST', 'fileId', fileId)
            const localVarPath = `/api/v1/data/text/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(textAnnotationItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary importFileTSV
         * @param {File} uploadedFile uploadedFile
         * @param {number} [datasetId] datasetId
         * @param {Array<number>} [tagIds] tagIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFileTSVUsingPOST: async (uploadedFile: File, datasetId?: number, tagIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadedFile' is not null or undefined
            assertParamExists('importFileTSVUsingPOST', 'uploadedFile', uploadedFile)
            const localVarPath = `/api/v1/data/text`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (datasetId !== undefined) { 
                localVarFormParams.append('datasetId', datasetId as any);
            }
                if (tagIds) {
                localVarFormParams.append('tagIds', tagIds.join(COLLECTION_FORMATS.csv));
            }

    
            if (uploadedFile !== undefined) { 
                localVarFormParams.append('uploadedFile', uploadedFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileTsvResourceApi - functional programming interface
 * @export
 */
export const FileTsvResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileTsvResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary exportFileTSV
         * @param {number} fileId fileId
         * @param {TextAnnotationItem} [textAnnotationItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportFileTSVUsingPOST(fileId: number, textAnnotationItem?: TextAnnotationItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportFileTSVUsingPOST(fileId, textAnnotationItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary importFileTSV
         * @param {File} uploadedFile uploadedFile
         * @param {number} [datasetId] datasetId
         * @param {Array<number>} [tagIds] tagIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importFileTSVUsingPOST(uploadedFile: File, datasetId?: number, tagIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfInfoImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importFileTSVUsingPOST(uploadedFile, datasetId, tagIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileTsvResourceApi - factory interface
 * @export
 */
export const FileTsvResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileTsvResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary exportFileTSV
         * @param {number} fileId fileId
         * @param {TextAnnotationItem} [textAnnotationItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFileTSVUsingPOST(fileId: number, textAnnotationItem?: TextAnnotationItem, options?: any): AxiosPromise<File> {
            return localVarFp.exportFileTSVUsingPOST(fileId, textAnnotationItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary importFileTSV
         * @param {File} uploadedFile uploadedFile
         * @param {number} [datasetId] datasetId
         * @param {Array<number>} [tagIds] tagIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFileTSVUsingPOST(uploadedFile: File, datasetId?: number, tagIds?: Array<number>, options?: any): AxiosPromise<RestResponseOfInfoImportResponse> {
            return localVarFp.importFileTSVUsingPOST(uploadedFile, datasetId, tagIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for exportFileTSVUsingPOST operation in FileTsvResourceApi.
 * @export
 * @interface FileTsvResourceApiExportFileTSVUsingPOSTRequest
 */
export interface FileTsvResourceApiExportFileTSVUsingPOSTRequest {
    /**
     * fileId
     * @type {number}
     * @memberof FileTsvResourceApiExportFileTSVUsingPOST
     */
    readonly fileId: number

    /**
     * 
     * @type {TextAnnotationItem}
     * @memberof FileTsvResourceApiExportFileTSVUsingPOST
     */
    readonly textAnnotationItem?: TextAnnotationItem
}

/**
 * Request parameters for importFileTSVUsingPOST operation in FileTsvResourceApi.
 * @export
 * @interface FileTsvResourceApiImportFileTSVUsingPOSTRequest
 */
export interface FileTsvResourceApiImportFileTSVUsingPOSTRequest {
    /**
     * uploadedFile
     * @type {File}
     * @memberof FileTsvResourceApiImportFileTSVUsingPOST
     */
    readonly uploadedFile: File

    /**
     * datasetId
     * @type {number}
     * @memberof FileTsvResourceApiImportFileTSVUsingPOST
     */
    readonly datasetId?: number

    /**
     * tagIds
     * @type {Array<number>}
     * @memberof FileTsvResourceApiImportFileTSVUsingPOST
     */
    readonly tagIds?: Array<number>
}

/**
 * FileTsvResourceApi - object-oriented interface
 * @export
 * @class FileTsvResourceApi
 * @extends {BaseAPI}
 */
export class FileTsvResourceApi extends BaseAPI {
    /**
     * 
     * @summary exportFileTSV
     * @param {FileTsvResourceApiExportFileTSVUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileTsvResourceApi
     */
    public exportFileTSVUsingPOST(requestParameters: FileTsvResourceApiExportFileTSVUsingPOSTRequest, options?: AxiosRequestConfig) {
        return FileTsvResourceApiFp(this.configuration).exportFileTSVUsingPOST(requestParameters.fileId, requestParameters.textAnnotationItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary importFileTSV
     * @param {FileTsvResourceApiImportFileTSVUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileTsvResourceApi
     */
    public importFileTSVUsingPOST(requestParameters: FileTsvResourceApiImportFileTSVUsingPOSTRequest, options?: AxiosRequestConfig) {
        return FileTsvResourceApiFp(this.configuration).importFileTSVUsingPOST(requestParameters.uploadedFile, requestParameters.datasetId, requestParameters.tagIds, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IndividualResourceApi - axios parameter creator
 * @export
 */
export const IndividualResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary annotationBatchReport
         * @param {number} batchId batchId
         * @param {boolean} [searchAllTime] searchAllTime
         * @param {string} [startTime] startTime
         * @param {string} [endTime] endTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationBatchReportUsingGET: async (batchId: number, searchAllTime?: boolean, startTime?: string, endTime?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('annotationBatchReportUsingGET', 'batchId', batchId)
            const localVarPath = `/api/v1/individual/annotation-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            if (searchAllTime !== undefined) {
                localVarQueryParameter['searchAllTime'] = searchAllTime;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary batchReport
         * @param {boolean} [searchAllTime] searchAllTime
         * @param {string} [startTime] startTime
         * @param {string} [endTime] endTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReportUsingGET: async (searchAllTime?: boolean, startTime?: string, endTime?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/individual/batch-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchAllTime !== undefined) {
                localVarQueryParameter['searchAllTime'] = searchAllTime;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary labelerReport
         * @param {string} [fromDate] fromDate
         * @param {Pageable} [pageable] pageable
         * @param {string} [toDate] toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labelerReportUsingGET: async (fromDate?: string, pageable?: Pageable, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/individual/labeler-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndividualResourceApi - functional programming interface
 * @export
 */
export const IndividualResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IndividualResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary annotationBatchReport
         * @param {number} batchId batchId
         * @param {boolean} [searchAllTime] searchAllTime
         * @param {string} [startTime] startTime
         * @param {string} [endTime] endTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationBatchReportUsingGET(batchId: number, searchAllTime?: boolean, startTime?: string, endTime?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfLabelerAnnotationByBatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationBatchReportUsingGET(batchId, searchAllTime, startTime, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary batchReport
         * @param {boolean} [searchAllTime] searchAllTime
         * @param {string} [startTime] startTime
         * @param {string} [endTime] endTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchReportUsingGET(searchAllTime?: boolean, startTime?: string, endTime?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfLabelerStatisticByBatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchReportUsingGET(searchAllTime, startTime, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary labelerReport
         * @param {string} [fromDate] fromDate
         * @param {Pageable} [pageable] pageable
         * @param {string} [toDate] toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labelerReportUsingGET(fromDate?: string, pageable?: Pageable, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfLabelerStatistic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labelerReportUsingGET(fromDate, pageable, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IndividualResourceApi - factory interface
 * @export
 */
export const IndividualResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IndividualResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary annotationBatchReport
         * @param {number} batchId batchId
         * @param {boolean} [searchAllTime] searchAllTime
         * @param {string} [startTime] startTime
         * @param {string} [endTime] endTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationBatchReportUsingGET(batchId: number, searchAllTime?: boolean, startTime?: string, endTime?: string, options?: any): AxiosPromise<RestResponseOfListOfLabelerAnnotationByBatch> {
            return localVarFp.annotationBatchReportUsingGET(batchId, searchAllTime, startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary batchReport
         * @param {boolean} [searchAllTime] searchAllTime
         * @param {string} [startTime] startTime
         * @param {string} [endTime] endTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReportUsingGET(searchAllTime?: boolean, startTime?: string, endTime?: string, options?: any): AxiosPromise<RestResponseOfListOfLabelerStatisticByBatch> {
            return localVarFp.batchReportUsingGET(searchAllTime, startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary labelerReport
         * @param {string} [fromDate] fromDate
         * @param {Pageable} [pageable] pageable
         * @param {string} [toDate] toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labelerReportUsingGET(fromDate?: string, pageable?: Pageable, toDate?: string, options?: any): AxiosPromise<RestResponseOfLabelerStatistic> {
            return localVarFp.labelerReportUsingGET(fromDate, pageable, toDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for annotationBatchReportUsingGET operation in IndividualResourceApi.
 * @export
 * @interface IndividualResourceApiAnnotationBatchReportUsingGETRequest
 */
export interface IndividualResourceApiAnnotationBatchReportUsingGETRequest {
    /**
     * batchId
     * @type {number}
     * @memberof IndividualResourceApiAnnotationBatchReportUsingGET
     */
    readonly batchId: number

    /**
     * searchAllTime
     * @type {boolean}
     * @memberof IndividualResourceApiAnnotationBatchReportUsingGET
     */
    readonly searchAllTime?: boolean

    /**
     * startTime
     * @type {string}
     * @memberof IndividualResourceApiAnnotationBatchReportUsingGET
     */
    readonly startTime?: string

    /**
     * endTime
     * @type {string}
     * @memberof IndividualResourceApiAnnotationBatchReportUsingGET
     */
    readonly endTime?: string
}

/**
 * Request parameters for batchReportUsingGET operation in IndividualResourceApi.
 * @export
 * @interface IndividualResourceApiBatchReportUsingGETRequest
 */
export interface IndividualResourceApiBatchReportUsingGETRequest {
    /**
     * searchAllTime
     * @type {boolean}
     * @memberof IndividualResourceApiBatchReportUsingGET
     */
    readonly searchAllTime?: boolean

    /**
     * startTime
     * @type {string}
     * @memberof IndividualResourceApiBatchReportUsingGET
     */
    readonly startTime?: string

    /**
     * endTime
     * @type {string}
     * @memberof IndividualResourceApiBatchReportUsingGET
     */
    readonly endTime?: string
}

/**
 * Request parameters for labelerReportUsingGET operation in IndividualResourceApi.
 * @export
 * @interface IndividualResourceApiLabelerReportUsingGETRequest
 */
export interface IndividualResourceApiLabelerReportUsingGETRequest {
    /**
     * fromDate
     * @type {string}
     * @memberof IndividualResourceApiLabelerReportUsingGET
     */
    readonly fromDate?: string

    /**
     * pageable
     * @type {Pageable}
     * @memberof IndividualResourceApiLabelerReportUsingGET
     */
    readonly pageable?: Pageable

    /**
     * toDate
     * @type {string}
     * @memberof IndividualResourceApiLabelerReportUsingGET
     */
    readonly toDate?: string
}

/**
 * IndividualResourceApi - object-oriented interface
 * @export
 * @class IndividualResourceApi
 * @extends {BaseAPI}
 */
export class IndividualResourceApi extends BaseAPI {
    /**
     * 
     * @summary annotationBatchReport
     * @param {IndividualResourceApiAnnotationBatchReportUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualResourceApi
     */
    public annotationBatchReportUsingGET(requestParameters: IndividualResourceApiAnnotationBatchReportUsingGETRequest, options?: AxiosRequestConfig) {
        return IndividualResourceApiFp(this.configuration).annotationBatchReportUsingGET(requestParameters.batchId, requestParameters.searchAllTime, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary batchReport
     * @param {IndividualResourceApiBatchReportUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualResourceApi
     */
    public batchReportUsingGET(requestParameters: IndividualResourceApiBatchReportUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return IndividualResourceApiFp(this.configuration).batchReportUsingGET(requestParameters.searchAllTime, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary labelerReport
     * @param {IndividualResourceApiLabelerReportUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualResourceApi
     */
    public labelerReportUsingGET(requestParameters: IndividualResourceApiLabelerReportUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return IndividualResourceApiFp(this.configuration).labelerReportUsingGET(requestParameters.fromDate, requestParameters.pageable, requestParameters.toDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IssueResourceApi - axios parameter creator
 * @export
 */
export const IssueResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IssueCommentRequestDTO} [issueCommentRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIssueCommentByUsingPOST: async (issueCommentRequestDTO?: IssueCommentRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/issues/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueCommentRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IssueCommentRequestDTO} [issueCommentRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIssueCommentForLabelerByUsingPOST: async (issueCommentRequestDTO?: IssueCommentRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/labeler/issues/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueCommentRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IssueCustomerRequestDTO} [issueCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIssueForCustomerByUsingPOST: async (issueCustomerRequestDTO?: IssueCustomerRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/issues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueCustomerRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IssueReviewerRequestDTO} [issueReviewerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIssueForReviewerByUsingPOST: async (issueReviewerRequestDTO?: IssueReviewerRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/labeler/issues/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueReviewerRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getIssue
         * @param {number} [taskId] taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueByUsingGET: async (taskId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/issues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (taskId !== undefined) {
                localVarQueryParameter['taskId'] = taskId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getIssueCommentForCustomer
         * @param {Array<number>} [issueIds] issueIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueCommentForCustomerByUsingGET: async (issueIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/issues/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (issueIds) {
                localVarQueryParameter['issueIds'] = issueIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getIssueCommentForLabeler
         * @param {Array<number>} [issueIds] issueIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueCommentForLabelerByUsingGET: async (issueIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/labeler/issues/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (issueIds) {
                localVarQueryParameter['issueIds'] = issueIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getIssueForLabeler
         * @param {number} [jobId] jobId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueForLabelerByUsingGET: async (jobId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/labeler/issues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getIssueForReviewer
         * @param {number} [taskId] taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueForReviewerByUsingGET: async (taskId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/labeler/issues/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (taskId !== undefined) {
                localVarQueryParameter['taskId'] = taskId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OpenIssueCustomerRequestDTO} [openIssueCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openIssueForCustomerByUsingPOST: async (openIssueCustomerRequestDTO?: OpenIssueCustomerRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/issues/open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(openIssueCustomerRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OpenIssueReviewerRequestDTO} [openIssueReviewerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openIssueForReviewerByUsingPOST: async (openIssueReviewerRequestDTO?: OpenIssueReviewerRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/labeler/issues/open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(openIssueReviewerRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResolveIssueCustomerRequestDTO} [resolveIssueCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolveIssueForCustomerByUsingPOST: async (resolveIssueCustomerRequestDTO?: ResolveIssueCustomerRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/issues/resolve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resolveIssueCustomerRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [issueId] issueId
         * @param {UpdateIssueCustomerRequestDTO} [updateIssueCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssueForCustomerByUsingPOST: async (issueId?: number, updateIssueCustomerRequestDTO?: UpdateIssueCustomerRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/issues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (issueId !== undefined) {
                localVarQueryParameter['issueId'] = issueId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIssueCustomerRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [issueId] issueId
         * @param {UpdateIssueReviewerRequestDTO} [updateIssueReviewerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssueForReviewerByUsingPOST: async (issueId?: number, updateIssueReviewerRequestDTO?: UpdateIssueReviewerRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/labeler/issues/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (issueId !== undefined) {
                localVarQueryParameter['issueId'] = issueId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIssueReviewerRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IssueResourceApi - functional programming interface
 * @export
 */
export const IssueResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IssueResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {IssueCommentRequestDTO} [issueCommentRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIssueCommentByUsingPOST(issueCommentRequestDTO?: IssueCommentRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfIssueCommentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIssueCommentByUsingPOST(issueCommentRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IssueCommentRequestDTO} [issueCommentRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIssueCommentForLabelerByUsingPOST(issueCommentRequestDTO?: IssueCommentRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfIssueCommentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIssueCommentForLabelerByUsingPOST(issueCommentRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IssueCustomerRequestDTO} [issueCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIssueForCustomerByUsingPOST(issueCustomerRequestDTO?: IssueCustomerRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfIssueCustomerResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIssueForCustomerByUsingPOST(issueCustomerRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IssueReviewerRequestDTO} [issueReviewerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIssueForReviewerByUsingPOST(issueReviewerRequestDTO?: IssueReviewerRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfIssueLabelerResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIssueForReviewerByUsingPOST(issueReviewerRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getIssue
         * @param {number} [taskId] taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIssueByUsingGET(taskId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfIssueCustomerResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIssueByUsingGET(taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getIssueCommentForCustomer
         * @param {Array<number>} [issueIds] issueIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIssueCommentForCustomerByUsingGET(issueIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfIssueCommentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIssueCommentForCustomerByUsingGET(issueIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getIssueCommentForLabeler
         * @param {Array<number>} [issueIds] issueIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIssueCommentForLabelerByUsingGET(issueIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfIssueCommentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIssueCommentForLabelerByUsingGET(issueIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getIssueForLabeler
         * @param {number} [jobId] jobId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIssueForLabelerByUsingGET(jobId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfIssueLabelerResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIssueForLabelerByUsingGET(jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getIssueForReviewer
         * @param {number} [taskId] taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIssueForReviewerByUsingGET(taskId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfIssueLabelerResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIssueForReviewerByUsingGET(taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OpenIssueCustomerRequestDTO} [openIssueCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openIssueForCustomerByUsingPOST(openIssueCustomerRequestDTO?: OpenIssueCustomerRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfIssueCustomerResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openIssueForCustomerByUsingPOST(openIssueCustomerRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OpenIssueReviewerRequestDTO} [openIssueReviewerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openIssueForReviewerByUsingPOST(openIssueReviewerRequestDTO?: OpenIssueReviewerRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfIssueLabelerResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openIssueForReviewerByUsingPOST(openIssueReviewerRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResolveIssueCustomerRequestDTO} [resolveIssueCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resolveIssueForCustomerByUsingPOST(resolveIssueCustomerRequestDTO?: ResolveIssueCustomerRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfIssueCustomerResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resolveIssueForCustomerByUsingPOST(resolveIssueCustomerRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [issueId] issueId
         * @param {UpdateIssueCustomerRequestDTO} [updateIssueCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIssueForCustomerByUsingPOST(issueId?: number, updateIssueCustomerRequestDTO?: UpdateIssueCustomerRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfIssueCustomerResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIssueForCustomerByUsingPOST(issueId, updateIssueCustomerRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [issueId] issueId
         * @param {UpdateIssueReviewerRequestDTO} [updateIssueReviewerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIssueForReviewerByUsingPOST(issueId?: number, updateIssueReviewerRequestDTO?: UpdateIssueReviewerRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfIssueLabelerResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIssueForReviewerByUsingPOST(issueId, updateIssueReviewerRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IssueResourceApi - factory interface
 * @export
 */
export const IssueResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IssueResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {IssueCommentRequestDTO} [issueCommentRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIssueCommentByUsingPOST(issueCommentRequestDTO?: IssueCommentRequestDTO, options?: any): AxiosPromise<RestResponseOfIssueCommentResponseDTO> {
            return localVarFp.createIssueCommentByUsingPOST(issueCommentRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IssueCommentRequestDTO} [issueCommentRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIssueCommentForLabelerByUsingPOST(issueCommentRequestDTO?: IssueCommentRequestDTO, options?: any): AxiosPromise<RestResponseOfIssueCommentResponseDTO> {
            return localVarFp.createIssueCommentForLabelerByUsingPOST(issueCommentRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IssueCustomerRequestDTO} [issueCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIssueForCustomerByUsingPOST(issueCustomerRequestDTO?: IssueCustomerRequestDTO, options?: any): AxiosPromise<RestResponseOfIssueCustomerResponseDTO> {
            return localVarFp.createIssueForCustomerByUsingPOST(issueCustomerRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IssueReviewerRequestDTO} [issueReviewerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIssueForReviewerByUsingPOST(issueReviewerRequestDTO?: IssueReviewerRequestDTO, options?: any): AxiosPromise<RestResponseOfIssueLabelerResponseDTO> {
            return localVarFp.createIssueForReviewerByUsingPOST(issueReviewerRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getIssue
         * @param {number} [taskId] taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueByUsingGET(taskId?: number, options?: any): AxiosPromise<RestResponseOfListOfIssueCustomerResponseDTO> {
            return localVarFp.getIssueByUsingGET(taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getIssueCommentForCustomer
         * @param {Array<number>} [issueIds] issueIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueCommentForCustomerByUsingGET(issueIds?: Array<number>, options?: any): AxiosPromise<RestResponseOfListOfIssueCommentResponseDTO> {
            return localVarFp.getIssueCommentForCustomerByUsingGET(issueIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getIssueCommentForLabeler
         * @param {Array<number>} [issueIds] issueIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueCommentForLabelerByUsingGET(issueIds?: Array<number>, options?: any): AxiosPromise<RestResponseOfListOfIssueCommentResponseDTO> {
            return localVarFp.getIssueCommentForLabelerByUsingGET(issueIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getIssueForLabeler
         * @param {number} [jobId] jobId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueForLabelerByUsingGET(jobId?: number, options?: any): AxiosPromise<RestResponseOfListOfIssueLabelerResponseDTO> {
            return localVarFp.getIssueForLabelerByUsingGET(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getIssueForReviewer
         * @param {number} [taskId] taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueForReviewerByUsingGET(taskId?: number, options?: any): AxiosPromise<RestResponseOfListOfIssueLabelerResponseDTO> {
            return localVarFp.getIssueForReviewerByUsingGET(taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OpenIssueCustomerRequestDTO} [openIssueCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openIssueForCustomerByUsingPOST(openIssueCustomerRequestDTO?: OpenIssueCustomerRequestDTO, options?: any): AxiosPromise<RestResponseOfIssueCustomerResponseDTO> {
            return localVarFp.openIssueForCustomerByUsingPOST(openIssueCustomerRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OpenIssueReviewerRequestDTO} [openIssueReviewerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openIssueForReviewerByUsingPOST(openIssueReviewerRequestDTO?: OpenIssueReviewerRequestDTO, options?: any): AxiosPromise<RestResponseOfIssueLabelerResponseDTO> {
            return localVarFp.openIssueForReviewerByUsingPOST(openIssueReviewerRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResolveIssueCustomerRequestDTO} [resolveIssueCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolveIssueForCustomerByUsingPOST(resolveIssueCustomerRequestDTO?: ResolveIssueCustomerRequestDTO, options?: any): AxiosPromise<RestResponseOfIssueCustomerResponseDTO> {
            return localVarFp.resolveIssueForCustomerByUsingPOST(resolveIssueCustomerRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [issueId] issueId
         * @param {UpdateIssueCustomerRequestDTO} [updateIssueCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssueForCustomerByUsingPOST(issueId?: number, updateIssueCustomerRequestDTO?: UpdateIssueCustomerRequestDTO, options?: any): AxiosPromise<RestResponseOfIssueCustomerResponseDTO> {
            return localVarFp.updateIssueForCustomerByUsingPOST(issueId, updateIssueCustomerRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [issueId] issueId
         * @param {UpdateIssueReviewerRequestDTO} [updateIssueReviewerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssueForReviewerByUsingPOST(issueId?: number, updateIssueReviewerRequestDTO?: UpdateIssueReviewerRequestDTO, options?: any): AxiosPromise<RestResponseOfIssueLabelerResponseDTO> {
            return localVarFp.updateIssueForReviewerByUsingPOST(issueId, updateIssueReviewerRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createIssueCommentByUsingPOST operation in IssueResourceApi.
 * @export
 * @interface IssueResourceApiCreateIssueCommentByUsingPOSTRequest
 */
export interface IssueResourceApiCreateIssueCommentByUsingPOSTRequest {
    /**
     * 
     * @type {IssueCommentRequestDTO}
     * @memberof IssueResourceApiCreateIssueCommentByUsingPOST
     */
    readonly issueCommentRequestDTO?: IssueCommentRequestDTO
}

/**
 * Request parameters for createIssueCommentForLabelerByUsingPOST operation in IssueResourceApi.
 * @export
 * @interface IssueResourceApiCreateIssueCommentForLabelerByUsingPOSTRequest
 */
export interface IssueResourceApiCreateIssueCommentForLabelerByUsingPOSTRequest {
    /**
     * 
     * @type {IssueCommentRequestDTO}
     * @memberof IssueResourceApiCreateIssueCommentForLabelerByUsingPOST
     */
    readonly issueCommentRequestDTO?: IssueCommentRequestDTO
}

/**
 * Request parameters for createIssueForCustomerByUsingPOST operation in IssueResourceApi.
 * @export
 * @interface IssueResourceApiCreateIssueForCustomerByUsingPOSTRequest
 */
export interface IssueResourceApiCreateIssueForCustomerByUsingPOSTRequest {
    /**
     * 
     * @type {IssueCustomerRequestDTO}
     * @memberof IssueResourceApiCreateIssueForCustomerByUsingPOST
     */
    readonly issueCustomerRequestDTO?: IssueCustomerRequestDTO
}

/**
 * Request parameters for createIssueForReviewerByUsingPOST operation in IssueResourceApi.
 * @export
 * @interface IssueResourceApiCreateIssueForReviewerByUsingPOSTRequest
 */
export interface IssueResourceApiCreateIssueForReviewerByUsingPOSTRequest {
    /**
     * 
     * @type {IssueReviewerRequestDTO}
     * @memberof IssueResourceApiCreateIssueForReviewerByUsingPOST
     */
    readonly issueReviewerRequestDTO?: IssueReviewerRequestDTO
}

/**
 * Request parameters for getIssueByUsingGET operation in IssueResourceApi.
 * @export
 * @interface IssueResourceApiGetIssueByUsingGETRequest
 */
export interface IssueResourceApiGetIssueByUsingGETRequest {
    /**
     * taskId
     * @type {number}
     * @memberof IssueResourceApiGetIssueByUsingGET
     */
    readonly taskId?: number
}

/**
 * Request parameters for getIssueCommentForCustomerByUsingGET operation in IssueResourceApi.
 * @export
 * @interface IssueResourceApiGetIssueCommentForCustomerByUsingGETRequest
 */
export interface IssueResourceApiGetIssueCommentForCustomerByUsingGETRequest {
    /**
     * issueIds
     * @type {Array<number>}
     * @memberof IssueResourceApiGetIssueCommentForCustomerByUsingGET
     */
    readonly issueIds?: Array<number>
}

/**
 * Request parameters for getIssueCommentForLabelerByUsingGET operation in IssueResourceApi.
 * @export
 * @interface IssueResourceApiGetIssueCommentForLabelerByUsingGETRequest
 */
export interface IssueResourceApiGetIssueCommentForLabelerByUsingGETRequest {
    /**
     * issueIds
     * @type {Array<number>}
     * @memberof IssueResourceApiGetIssueCommentForLabelerByUsingGET
     */
    readonly issueIds?: Array<number>
}

/**
 * Request parameters for getIssueForLabelerByUsingGET operation in IssueResourceApi.
 * @export
 * @interface IssueResourceApiGetIssueForLabelerByUsingGETRequest
 */
export interface IssueResourceApiGetIssueForLabelerByUsingGETRequest {
    /**
     * jobId
     * @type {number}
     * @memberof IssueResourceApiGetIssueForLabelerByUsingGET
     */
    readonly jobId?: number
}

/**
 * Request parameters for getIssueForReviewerByUsingGET operation in IssueResourceApi.
 * @export
 * @interface IssueResourceApiGetIssueForReviewerByUsingGETRequest
 */
export interface IssueResourceApiGetIssueForReviewerByUsingGETRequest {
    /**
     * taskId
     * @type {number}
     * @memberof IssueResourceApiGetIssueForReviewerByUsingGET
     */
    readonly taskId?: number
}

/**
 * Request parameters for openIssueForCustomerByUsingPOST operation in IssueResourceApi.
 * @export
 * @interface IssueResourceApiOpenIssueForCustomerByUsingPOSTRequest
 */
export interface IssueResourceApiOpenIssueForCustomerByUsingPOSTRequest {
    /**
     * 
     * @type {OpenIssueCustomerRequestDTO}
     * @memberof IssueResourceApiOpenIssueForCustomerByUsingPOST
     */
    readonly openIssueCustomerRequestDTO?: OpenIssueCustomerRequestDTO
}

/**
 * Request parameters for openIssueForReviewerByUsingPOST operation in IssueResourceApi.
 * @export
 * @interface IssueResourceApiOpenIssueForReviewerByUsingPOSTRequest
 */
export interface IssueResourceApiOpenIssueForReviewerByUsingPOSTRequest {
    /**
     * 
     * @type {OpenIssueReviewerRequestDTO}
     * @memberof IssueResourceApiOpenIssueForReviewerByUsingPOST
     */
    readonly openIssueReviewerRequestDTO?: OpenIssueReviewerRequestDTO
}

/**
 * Request parameters for resolveIssueForCustomerByUsingPOST operation in IssueResourceApi.
 * @export
 * @interface IssueResourceApiResolveIssueForCustomerByUsingPOSTRequest
 */
export interface IssueResourceApiResolveIssueForCustomerByUsingPOSTRequest {
    /**
     * 
     * @type {ResolveIssueCustomerRequestDTO}
     * @memberof IssueResourceApiResolveIssueForCustomerByUsingPOST
     */
    readonly resolveIssueCustomerRequestDTO?: ResolveIssueCustomerRequestDTO
}

/**
 * Request parameters for updateIssueForCustomerByUsingPOST operation in IssueResourceApi.
 * @export
 * @interface IssueResourceApiUpdateIssueForCustomerByUsingPOSTRequest
 */
export interface IssueResourceApiUpdateIssueForCustomerByUsingPOSTRequest {
    /**
     * issueId
     * @type {number}
     * @memberof IssueResourceApiUpdateIssueForCustomerByUsingPOST
     */
    readonly issueId?: number

    /**
     * 
     * @type {UpdateIssueCustomerRequestDTO}
     * @memberof IssueResourceApiUpdateIssueForCustomerByUsingPOST
     */
    readonly updateIssueCustomerRequestDTO?: UpdateIssueCustomerRequestDTO
}

/**
 * Request parameters for updateIssueForReviewerByUsingPOST operation in IssueResourceApi.
 * @export
 * @interface IssueResourceApiUpdateIssueForReviewerByUsingPOSTRequest
 */
export interface IssueResourceApiUpdateIssueForReviewerByUsingPOSTRequest {
    /**
     * issueId
     * @type {number}
     * @memberof IssueResourceApiUpdateIssueForReviewerByUsingPOST
     */
    readonly issueId?: number

    /**
     * 
     * @type {UpdateIssueReviewerRequestDTO}
     * @memberof IssueResourceApiUpdateIssueForReviewerByUsingPOST
     */
    readonly updateIssueReviewerRequestDTO?: UpdateIssueReviewerRequestDTO
}

/**
 * IssueResourceApi - object-oriented interface
 * @export
 * @class IssueResourceApi
 * @extends {BaseAPI}
 */
export class IssueResourceApi extends BaseAPI {
    /**
     * 
     * @param {IssueResourceApiCreateIssueCommentByUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueResourceApi
     */
    public createIssueCommentByUsingPOST(requestParameters: IssueResourceApiCreateIssueCommentByUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return IssueResourceApiFp(this.configuration).createIssueCommentByUsingPOST(requestParameters.issueCommentRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IssueResourceApiCreateIssueCommentForLabelerByUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueResourceApi
     */
    public createIssueCommentForLabelerByUsingPOST(requestParameters: IssueResourceApiCreateIssueCommentForLabelerByUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return IssueResourceApiFp(this.configuration).createIssueCommentForLabelerByUsingPOST(requestParameters.issueCommentRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IssueResourceApiCreateIssueForCustomerByUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueResourceApi
     */
    public createIssueForCustomerByUsingPOST(requestParameters: IssueResourceApiCreateIssueForCustomerByUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return IssueResourceApiFp(this.configuration).createIssueForCustomerByUsingPOST(requestParameters.issueCustomerRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IssueResourceApiCreateIssueForReviewerByUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueResourceApi
     */
    public createIssueForReviewerByUsingPOST(requestParameters: IssueResourceApiCreateIssueForReviewerByUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return IssueResourceApiFp(this.configuration).createIssueForReviewerByUsingPOST(requestParameters.issueReviewerRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getIssue
     * @param {IssueResourceApiGetIssueByUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueResourceApi
     */
    public getIssueByUsingGET(requestParameters: IssueResourceApiGetIssueByUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return IssueResourceApiFp(this.configuration).getIssueByUsingGET(requestParameters.taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getIssueCommentForCustomer
     * @param {IssueResourceApiGetIssueCommentForCustomerByUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueResourceApi
     */
    public getIssueCommentForCustomerByUsingGET(requestParameters: IssueResourceApiGetIssueCommentForCustomerByUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return IssueResourceApiFp(this.configuration).getIssueCommentForCustomerByUsingGET(requestParameters.issueIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getIssueCommentForLabeler
     * @param {IssueResourceApiGetIssueCommentForLabelerByUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueResourceApi
     */
    public getIssueCommentForLabelerByUsingGET(requestParameters: IssueResourceApiGetIssueCommentForLabelerByUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return IssueResourceApiFp(this.configuration).getIssueCommentForLabelerByUsingGET(requestParameters.issueIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getIssueForLabeler
     * @param {IssueResourceApiGetIssueForLabelerByUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueResourceApi
     */
    public getIssueForLabelerByUsingGET(requestParameters: IssueResourceApiGetIssueForLabelerByUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return IssueResourceApiFp(this.configuration).getIssueForLabelerByUsingGET(requestParameters.jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getIssueForReviewer
     * @param {IssueResourceApiGetIssueForReviewerByUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueResourceApi
     */
    public getIssueForReviewerByUsingGET(requestParameters: IssueResourceApiGetIssueForReviewerByUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return IssueResourceApiFp(this.configuration).getIssueForReviewerByUsingGET(requestParameters.taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IssueResourceApiOpenIssueForCustomerByUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueResourceApi
     */
    public openIssueForCustomerByUsingPOST(requestParameters: IssueResourceApiOpenIssueForCustomerByUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return IssueResourceApiFp(this.configuration).openIssueForCustomerByUsingPOST(requestParameters.openIssueCustomerRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IssueResourceApiOpenIssueForReviewerByUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueResourceApi
     */
    public openIssueForReviewerByUsingPOST(requestParameters: IssueResourceApiOpenIssueForReviewerByUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return IssueResourceApiFp(this.configuration).openIssueForReviewerByUsingPOST(requestParameters.openIssueReviewerRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IssueResourceApiResolveIssueForCustomerByUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueResourceApi
     */
    public resolveIssueForCustomerByUsingPOST(requestParameters: IssueResourceApiResolveIssueForCustomerByUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return IssueResourceApiFp(this.configuration).resolveIssueForCustomerByUsingPOST(requestParameters.resolveIssueCustomerRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IssueResourceApiUpdateIssueForCustomerByUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueResourceApi
     */
    public updateIssueForCustomerByUsingPOST(requestParameters: IssueResourceApiUpdateIssueForCustomerByUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return IssueResourceApiFp(this.configuration).updateIssueForCustomerByUsingPOST(requestParameters.issueId, requestParameters.updateIssueCustomerRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IssueResourceApiUpdateIssueForReviewerByUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueResourceApi
     */
    public updateIssueForReviewerByUsingPOST(requestParameters: IssueResourceApiUpdateIssueForReviewerByUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return IssueResourceApiFp(this.configuration).updateIssueForReviewerByUsingPOST(requestParameters.issueId, requestParameters.updateIssueReviewerRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobResourceApi - axios parameter creator
 * @export
 */
export const JobResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteJobUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary fetchJobByCriteria
         * @param {JobCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchJobByCriteria: async (criteria?: JobCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jobs/fetch-by-criteria`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary fetchJob
         * @param {number} projectId projectId
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchJobUsingPOST: async (projectId: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('fetchJobUsingPOST', 'projectId', projectId)
            const localVarPath = `/api/v1/jobs/fetch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary finishJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        finishJobUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('finishJobUsingPOST', 'id', id)
            const localVarPath = `/api/v1/jobs/finish/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllJobsByIdUsingPOST
         * @param {Ids} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJobsByIdUsingPOST: async (ids?: Ids, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jobs/get-by-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ids, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllJobsByProjectIdAndStatusUsingPOST
         * @param {number} projectId project Id
         * @param {'available' | 'completed' | 'initial' | 'saving' | 'skipped' | 'waiting' | 'working'} jobStatus job status
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJobsByProjectIdAndStatusUsingPOST: async (projectId: number, jobStatus: 'available' | 'completed' | 'initial' | 'saving' | 'skipped' | 'waiting' | 'working', pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getAllJobsByProjectIdAndStatusUsingPOST', 'projectId', projectId)
            // verify required parameter 'jobStatus' is not null or undefined
            assertParamExists('getAllJobsByProjectIdAndStatusUsingPOST', 'jobStatus', jobStatus)
            const localVarPath = `/api/v1/jobs/fetch-by-project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (jobStatus !== undefined) {
                localVarQueryParameter['jobStatus'] = jobStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllJobs
         * @param {Pageable} [pageable] pageable
         * @param {number} [taskId] taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJobsUsingGET: async (pageable?: Pageable, taskId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['taskId'] = taskId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getJobForReview
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobForReviewUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getJobForReviewUsingGET', 'id', id)
            const localVarPath = `/api/v1/jobs/{id}/review`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getStatistic
         * @param {boolean} [searchAllTime] searchAllTime
         * @param {string} [startTime] startTime
         * @param {string} [endTime] endTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobStatisticUsingGET: async (searchAllTime?: boolean, startTime?: string, endTime?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jobs/statistic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchAllTime !== undefined) {
                localVarQueryParameter['searchAllTime'] = searchAllTime;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getJobUsingGET', 'id', id)
            const localVarPath = `/api/v1/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getTaskStatistic
         * @param {boolean} [byUser] byUser
         * @param {number} [projectId] projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskStatisticUsingGET: async (byUser?: boolean, projectId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jobs/task-completed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (byUser !== undefined) {
                localVarQueryParameter['byUser'] = byUser;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary manual assign job
         * @param {ManualAssignJobRequest} [manualAssignJobRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualAssignJob: async (manualAssignJobRequest?: ManualAssignJobRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jobs/assign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manualAssignJobRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary manual assign job using csv
         * @param {ManualAssignJobCsvRequest} [manualAssignJobCsvRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualAssignJobCsv: async (manualAssignJobCsvRequest?: ManualAssignJobCsvRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jobs/assign-csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manualAssignJobCsvRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateJob
         * @param {number} id id
         * @param {JobRequestDTO} [jobRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateJobUsingPATCH: async (id: number, jobRequestDTO?: JobRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateJobUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jobRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId projectId
         * @param {number} [limit] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollJobUsingPOST: async (projectId: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('pollJobUsingPOST', 'projectId', projectId)
            const localVarPath = `/api/v1/jobs/poll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reopen Jobs
         * @param {ReopenJobsRequest} [reopenJobsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reopenJobs: async (reopenJobsRequest?: ReopenJobsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jobs/reopen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reopenJobsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary skipJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipJobUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('skipJobUsingPOST', 'id', id)
            const localVarPath = `/api/v1/jobs/skip/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateJob
         * @param {number} id id
         * @param {JobRequestDTO} [jobRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJobUsingPUT: async (id: number, jobRequestDTO?: JobRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateJobUsingPUT', 'id', id)
            const localVarPath = `/api/v1/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jobRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobResourceApi - functional programming interface
 * @export
 */
export const JobResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary deleteJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJobUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJobUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary fetchJobByCriteria
         * @param {JobCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchJobByCriteria(criteria?: JobCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfJobResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchJobByCriteria(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary fetchJob
         * @param {number} projectId projectId
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchJobUsingPOST(projectId: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfJobResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchJobUsingPOST(projectId, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary finishJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async finishJobUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfJobResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishJobUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllJobsByIdUsingPOST
         * @param {Ids} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllJobsByIdUsingPOST(ids?: Ids, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfJobResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllJobsByIdUsingPOST(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllJobsByProjectIdAndStatusUsingPOST
         * @param {number} projectId project Id
         * @param {'available' | 'completed' | 'initial' | 'saving' | 'skipped' | 'waiting' | 'working'} jobStatus job status
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllJobsByProjectIdAndStatusUsingPOST(projectId: number, jobStatus: 'available' | 'completed' | 'initial' | 'saving' | 'skipped' | 'waiting' | 'working', pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfJobResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllJobsByProjectIdAndStatusUsingPOST(projectId, jobStatus, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllJobs
         * @param {Pageable} [pageable] pageable
         * @param {number} [taskId] taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllJobsUsingGET(pageable?: Pageable, taskId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfJobResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllJobsUsingGET(pageable, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getJobForReview
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobForReviewUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfJobResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobForReviewUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getStatistic
         * @param {boolean} [searchAllTime] searchAllTime
         * @param {string} [startTime] startTime
         * @param {string} [endTime] endTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobStatisticUsingGET(searchAllTime?: boolean, startTime?: string, endTime?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfJobStatistic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobStatisticUsingGET(searchAllTime, startTime, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfJobResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getTaskStatistic
         * @param {boolean} [byUser] byUser
         * @param {number} [projectId] projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskStatisticUsingGET(byUser?: boolean, projectId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfTaskStatistic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskStatisticUsingGET(byUser, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary manual assign job
         * @param {ManualAssignJobRequest} [manualAssignJobRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualAssignJob(manualAssignJobRequest?: ManualAssignJobRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfManualAssignJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualAssignJob(manualAssignJobRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary manual assign job using csv
         * @param {ManualAssignJobCsvRequest} [manualAssignJobCsvRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualAssignJobCsv(manualAssignJobCsvRequest?: ManualAssignJobCsvRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfManualAssignJobCsvResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualAssignJobCsv(manualAssignJobCsvRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateJob
         * @param {number} id id
         * @param {JobRequestDTO} [jobRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateJobUsingPATCH(id: number, jobRequestDTO?: JobRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfJobResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateJobUsingPATCH(id, jobRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId projectId
         * @param {number} [limit] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollJobUsingPOST(projectId: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfJobResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pollJobUsingPOST(projectId, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary reopen Jobs
         * @param {ReopenJobsRequest} [reopenJobsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reopenJobs(reopenJobsRequest?: ReopenJobsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfJobResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reopenJobs(reopenJobsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary skipJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async skipJobUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfJobResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.skipJobUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateJob
         * @param {number} id id
         * @param {JobRequestDTO} [jobRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateJobUsingPUT(id: number, jobRequestDTO?: JobRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfJobResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJobUsingPUT(id, jobRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobResourceApi - factory interface
 * @export
 */
export const JobResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary deleteJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteJobUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary fetchJobByCriteria
         * @param {JobCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchJobByCriteria(criteria?: JobCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfJobResponseDTO> {
            return localVarFp.fetchJobByCriteria(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary fetchJob
         * @param {number} projectId projectId
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchJobUsingPOST(projectId: number, limit?: number, options?: any): AxiosPromise<RestResponseOfListOfJobResponseDTO> {
            return localVarFp.fetchJobUsingPOST(projectId, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary finishJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        finishJobUsingPOST(id: number, options?: any): AxiosPromise<RestResponseOfJobResponseDTO> {
            return localVarFp.finishJobUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllJobsByIdUsingPOST
         * @param {Ids} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJobsByIdUsingPOST(ids?: Ids, options?: any): AxiosPromise<RestResponseOfListOfJobResponseDTO> {
            return localVarFp.getAllJobsByIdUsingPOST(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllJobsByProjectIdAndStatusUsingPOST
         * @param {number} projectId project Id
         * @param {'available' | 'completed' | 'initial' | 'saving' | 'skipped' | 'waiting' | 'working'} jobStatus job status
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJobsByProjectIdAndStatusUsingPOST(projectId: number, jobStatus: 'available' | 'completed' | 'initial' | 'saving' | 'skipped' | 'waiting' | 'working', pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfJobResponseDTO> {
            return localVarFp.getAllJobsByProjectIdAndStatusUsingPOST(projectId, jobStatus, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllJobs
         * @param {Pageable} [pageable] pageable
         * @param {number} [taskId] taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJobsUsingGET(pageable?: Pageable, taskId?: number, options?: any): AxiosPromise<RestResponseOfListOfJobResponseDTO> {
            return localVarFp.getAllJobsUsingGET(pageable, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getJobForReview
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobForReviewUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfJobResponseDTO> {
            return localVarFp.getJobForReviewUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getStatistic
         * @param {boolean} [searchAllTime] searchAllTime
         * @param {string} [startTime] startTime
         * @param {string} [endTime] endTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobStatisticUsingGET(searchAllTime?: boolean, startTime?: string, endTime?: string, options?: any): AxiosPromise<RestResponseOfJobStatistic> {
            return localVarFp.getJobStatisticUsingGET(searchAllTime, startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfJobResponseDTO> {
            return localVarFp.getJobUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getTaskStatistic
         * @param {boolean} [byUser] byUser
         * @param {number} [projectId] projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskStatisticUsingGET(byUser?: boolean, projectId?: number, options?: any): AxiosPromise<RestResponseOfTaskStatistic> {
            return localVarFp.getTaskStatisticUsingGET(byUser, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary manual assign job
         * @param {ManualAssignJobRequest} [manualAssignJobRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualAssignJob(manualAssignJobRequest?: ManualAssignJobRequest, options?: any): AxiosPromise<RestResponseOfManualAssignJobResponse> {
            return localVarFp.manualAssignJob(manualAssignJobRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary manual assign job using csv
         * @param {ManualAssignJobCsvRequest} [manualAssignJobCsvRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualAssignJobCsv(manualAssignJobCsvRequest?: ManualAssignJobCsvRequest, options?: any): AxiosPromise<RestResponseOfManualAssignJobCsvResponse> {
            return localVarFp.manualAssignJobCsv(manualAssignJobCsvRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateJob
         * @param {number} id id
         * @param {JobRequestDTO} [jobRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateJobUsingPATCH(id: number, jobRequestDTO?: JobRequestDTO, options?: any): AxiosPromise<RestResponseOfJobResponseDTO> {
            return localVarFp.partialUpdateJobUsingPATCH(id, jobRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId projectId
         * @param {number} [limit] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollJobUsingPOST(projectId: number, limit?: number, options?: any): AxiosPromise<RestResponseOfListOfJobResponseDTO> {
            return localVarFp.pollJobUsingPOST(projectId, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reopen Jobs
         * @param {ReopenJobsRequest} [reopenJobsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reopenJobs(reopenJobsRequest?: ReopenJobsRequest, options?: any): AxiosPromise<RestResponseOfListOfJobResponseDTO> {
            return localVarFp.reopenJobs(reopenJobsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary skipJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipJobUsingPOST(id: number, options?: any): AxiosPromise<RestResponseOfJobResponseDTO> {
            return localVarFp.skipJobUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateJob
         * @param {number} id id
         * @param {JobRequestDTO} [jobRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJobUsingPUT(id: number, jobRequestDTO?: JobRequestDTO, options?: any): AxiosPromise<RestResponseOfJobResponseDTO> {
            return localVarFp.updateJobUsingPUT(id, jobRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteJobUsingDELETE operation in JobResourceApi.
 * @export
 * @interface JobResourceApiDeleteJobUsingDELETERequest
 */
export interface JobResourceApiDeleteJobUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof JobResourceApiDeleteJobUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for fetchJobByCriteria operation in JobResourceApi.
 * @export
 * @interface JobResourceApiFetchJobByCriteriaRequest
 */
export interface JobResourceApiFetchJobByCriteriaRequest {
    /**
     * criteria
     * @type {JobCriteria}
     * @memberof JobResourceApiFetchJobByCriteria
     */
    readonly criteria?: JobCriteria

    /**
     * pageable
     * @type {Pageable}
     * @memberof JobResourceApiFetchJobByCriteria
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for fetchJobUsingPOST operation in JobResourceApi.
 * @export
 * @interface JobResourceApiFetchJobUsingPOSTRequest
 */
export interface JobResourceApiFetchJobUsingPOSTRequest {
    /**
     * projectId
     * @type {number}
     * @memberof JobResourceApiFetchJobUsingPOST
     */
    readonly projectId: number

    /**
     * limit
     * @type {number}
     * @memberof JobResourceApiFetchJobUsingPOST
     */
    readonly limit?: number
}

/**
 * Request parameters for finishJobUsingPOST operation in JobResourceApi.
 * @export
 * @interface JobResourceApiFinishJobUsingPOSTRequest
 */
export interface JobResourceApiFinishJobUsingPOSTRequest {
    /**
     * id
     * @type {number}
     * @memberof JobResourceApiFinishJobUsingPOST
     */
    readonly id: number
}

/**
 * Request parameters for getAllJobsByIdUsingPOST operation in JobResourceApi.
 * @export
 * @interface JobResourceApiGetAllJobsByIdUsingPOSTRequest
 */
export interface JobResourceApiGetAllJobsByIdUsingPOSTRequest {
    /**
     * 
     * @type {Ids}
     * @memberof JobResourceApiGetAllJobsByIdUsingPOST
     */
    readonly ids?: Ids
}

/**
 * Request parameters for getAllJobsByProjectIdAndStatusUsingPOST operation in JobResourceApi.
 * @export
 * @interface JobResourceApiGetAllJobsByProjectIdAndStatusUsingPOSTRequest
 */
export interface JobResourceApiGetAllJobsByProjectIdAndStatusUsingPOSTRequest {
    /**
     * project Id
     * @type {number}
     * @memberof JobResourceApiGetAllJobsByProjectIdAndStatusUsingPOST
     */
    readonly projectId: number

    /**
     * job status
     * @type {'available' | 'completed' | 'initial' | 'saving' | 'skipped' | 'waiting' | 'working'}
     * @memberof JobResourceApiGetAllJobsByProjectIdAndStatusUsingPOST
     */
    readonly jobStatus: 'available' | 'completed' | 'initial' | 'saving' | 'skipped' | 'waiting' | 'working'

    /**
     * pageable
     * @type {Pageable}
     * @memberof JobResourceApiGetAllJobsByProjectIdAndStatusUsingPOST
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getAllJobsUsingGET operation in JobResourceApi.
 * @export
 * @interface JobResourceApiGetAllJobsUsingGETRequest
 */
export interface JobResourceApiGetAllJobsUsingGETRequest {
    /**
     * pageable
     * @type {Pageable}
     * @memberof JobResourceApiGetAllJobsUsingGET
     */
    readonly pageable?: Pageable

    /**
     * taskId
     * @type {number}
     * @memberof JobResourceApiGetAllJobsUsingGET
     */
    readonly taskId?: number
}

/**
 * Request parameters for getJobForReviewUsingGET operation in JobResourceApi.
 * @export
 * @interface JobResourceApiGetJobForReviewUsingGETRequest
 */
export interface JobResourceApiGetJobForReviewUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof JobResourceApiGetJobForReviewUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for getJobStatisticUsingGET operation in JobResourceApi.
 * @export
 * @interface JobResourceApiGetJobStatisticUsingGETRequest
 */
export interface JobResourceApiGetJobStatisticUsingGETRequest {
    /**
     * searchAllTime
     * @type {boolean}
     * @memberof JobResourceApiGetJobStatisticUsingGET
     */
    readonly searchAllTime?: boolean

    /**
     * startTime
     * @type {string}
     * @memberof JobResourceApiGetJobStatisticUsingGET
     */
    readonly startTime?: string

    /**
     * endTime
     * @type {string}
     * @memberof JobResourceApiGetJobStatisticUsingGET
     */
    readonly endTime?: string
}

/**
 * Request parameters for getJobUsingGET operation in JobResourceApi.
 * @export
 * @interface JobResourceApiGetJobUsingGETRequest
 */
export interface JobResourceApiGetJobUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof JobResourceApiGetJobUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for getTaskStatisticUsingGET operation in JobResourceApi.
 * @export
 * @interface JobResourceApiGetTaskStatisticUsingGETRequest
 */
export interface JobResourceApiGetTaskStatisticUsingGETRequest {
    /**
     * byUser
     * @type {boolean}
     * @memberof JobResourceApiGetTaskStatisticUsingGET
     */
    readonly byUser?: boolean

    /**
     * projectId
     * @type {number}
     * @memberof JobResourceApiGetTaskStatisticUsingGET
     */
    readonly projectId?: number
}

/**
 * Request parameters for manualAssignJob operation in JobResourceApi.
 * @export
 * @interface JobResourceApiManualAssignJobRequest
 */
export interface JobResourceApiManualAssignJobRequest {
    /**
     * 
     * @type {ManualAssignJobRequest}
     * @memberof JobResourceApiManualAssignJob
     */
    readonly manualAssignJobRequest?: ManualAssignJobRequest
}

/**
 * Request parameters for manualAssignJobCsv operation in JobResourceApi.
 * @export
 * @interface JobResourceApiManualAssignJobCsvRequest
 */
export interface JobResourceApiManualAssignJobCsvRequest {
    /**
     * 
     * @type {ManualAssignJobCsvRequest}
     * @memberof JobResourceApiManualAssignJobCsv
     */
    readonly manualAssignJobCsvRequest?: ManualAssignJobCsvRequest
}

/**
 * Request parameters for partialUpdateJobUsingPATCH operation in JobResourceApi.
 * @export
 * @interface JobResourceApiPartialUpdateJobUsingPATCHRequest
 */
export interface JobResourceApiPartialUpdateJobUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof JobResourceApiPartialUpdateJobUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {JobRequestDTO}
     * @memberof JobResourceApiPartialUpdateJobUsingPATCH
     */
    readonly jobRequestDTO?: JobRequestDTO
}

/**
 * Request parameters for pollJobUsingPOST operation in JobResourceApi.
 * @export
 * @interface JobResourceApiPollJobUsingPOSTRequest
 */
export interface JobResourceApiPollJobUsingPOSTRequest {
    /**
     * projectId
     * @type {number}
     * @memberof JobResourceApiPollJobUsingPOST
     */
    readonly projectId: number

    /**
     * type
     * @type {number}
     * @memberof JobResourceApiPollJobUsingPOST
     */
    readonly limit?: number
}

/**
 * Request parameters for reopenJobs operation in JobResourceApi.
 * @export
 * @interface JobResourceApiReopenJobsRequest
 */
export interface JobResourceApiReopenJobsRequest {
    /**
     * 
     * @type {ReopenJobsRequest}
     * @memberof JobResourceApiReopenJobs
     */
    readonly reopenJobsRequest?: ReopenJobsRequest
}

/**
 * Request parameters for skipJobUsingPOST operation in JobResourceApi.
 * @export
 * @interface JobResourceApiSkipJobUsingPOSTRequest
 */
export interface JobResourceApiSkipJobUsingPOSTRequest {
    /**
     * id
     * @type {number}
     * @memberof JobResourceApiSkipJobUsingPOST
     */
    readonly id: number
}

/**
 * Request parameters for updateJobUsingPUT operation in JobResourceApi.
 * @export
 * @interface JobResourceApiUpdateJobUsingPUTRequest
 */
export interface JobResourceApiUpdateJobUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof JobResourceApiUpdateJobUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {JobRequestDTO}
     * @memberof JobResourceApiUpdateJobUsingPUT
     */
    readonly jobRequestDTO?: JobRequestDTO
}

/**
 * JobResourceApi - object-oriented interface
 * @export
 * @class JobResourceApi
 * @extends {BaseAPI}
 */
export class JobResourceApi extends BaseAPI {
    /**
     * 
     * @summary deleteJob
     * @param {JobResourceApiDeleteJobUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public deleteJobUsingDELETE(requestParameters: JobResourceApiDeleteJobUsingDELETERequest, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).deleteJobUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary fetchJobByCriteria
     * @param {JobResourceApiFetchJobByCriteriaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public fetchJobByCriteria(requestParameters: JobResourceApiFetchJobByCriteriaRequest = {}, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).fetchJobByCriteria(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary fetchJob
     * @param {JobResourceApiFetchJobUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public fetchJobUsingPOST(requestParameters: JobResourceApiFetchJobUsingPOSTRequest, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).fetchJobUsingPOST(requestParameters.projectId, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary finishJob
     * @param {JobResourceApiFinishJobUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public finishJobUsingPOST(requestParameters: JobResourceApiFinishJobUsingPOSTRequest, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).finishJobUsingPOST(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllJobsByIdUsingPOST
     * @param {JobResourceApiGetAllJobsByIdUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public getAllJobsByIdUsingPOST(requestParameters: JobResourceApiGetAllJobsByIdUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).getAllJobsByIdUsingPOST(requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllJobsByProjectIdAndStatusUsingPOST
     * @param {JobResourceApiGetAllJobsByProjectIdAndStatusUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public getAllJobsByProjectIdAndStatusUsingPOST(requestParameters: JobResourceApiGetAllJobsByProjectIdAndStatusUsingPOSTRequest, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).getAllJobsByProjectIdAndStatusUsingPOST(requestParameters.projectId, requestParameters.jobStatus, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllJobs
     * @param {JobResourceApiGetAllJobsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public getAllJobsUsingGET(requestParameters: JobResourceApiGetAllJobsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).getAllJobsUsingGET(requestParameters.pageable, requestParameters.taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getJobForReview
     * @param {JobResourceApiGetJobForReviewUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public getJobForReviewUsingGET(requestParameters: JobResourceApiGetJobForReviewUsingGETRequest, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).getJobForReviewUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getStatistic
     * @param {JobResourceApiGetJobStatisticUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public getJobStatisticUsingGET(requestParameters: JobResourceApiGetJobStatisticUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).getJobStatisticUsingGET(requestParameters.searchAllTime, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getJob
     * @param {JobResourceApiGetJobUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public getJobUsingGET(requestParameters: JobResourceApiGetJobUsingGETRequest, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).getJobUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getTaskStatistic
     * @param {JobResourceApiGetTaskStatisticUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public getTaskStatisticUsingGET(requestParameters: JobResourceApiGetTaskStatisticUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).getTaskStatisticUsingGET(requestParameters.byUser, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary manual assign job
     * @param {JobResourceApiManualAssignJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public manualAssignJob(requestParameters: JobResourceApiManualAssignJobRequest = {}, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).manualAssignJob(requestParameters.manualAssignJobRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary manual assign job using csv
     * @param {JobResourceApiManualAssignJobCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public manualAssignJobCsv(requestParameters: JobResourceApiManualAssignJobCsvRequest = {}, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).manualAssignJobCsv(requestParameters.manualAssignJobCsvRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateJob
     * @param {JobResourceApiPartialUpdateJobUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public partialUpdateJobUsingPATCH(requestParameters: JobResourceApiPartialUpdateJobUsingPATCHRequest, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).partialUpdateJobUsingPATCH(requestParameters.id, requestParameters.jobRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {JobResourceApiPollJobUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public pollJobUsingPOST(requestParameters: JobResourceApiPollJobUsingPOSTRequest, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).pollJobUsingPOST(requestParameters.projectId, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reopen Jobs
     * @param {JobResourceApiReopenJobsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public reopenJobs(requestParameters: JobResourceApiReopenJobsRequest = {}, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).reopenJobs(requestParameters.reopenJobsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary skipJob
     * @param {JobResourceApiSkipJobUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public skipJobUsingPOST(requestParameters: JobResourceApiSkipJobUsingPOSTRequest, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).skipJobUsingPOST(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateJob
     * @param {JobResourceApiUpdateJobUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceApi
     */
    public updateJobUsingPUT(requestParameters: JobResourceApiUpdateJobUsingPUTRequest, options?: AxiosRequestConfig) {
        return JobResourceApiFp(this.configuration).updateJobUsingPUT(requestParameters.id, requestParameters.jobRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobResourceV2Api - axios parameter creator
 * @export
 */
export const JobResourceV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary finishJobBulk
         * @param {Array<AnnotationRequest>} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        finishJobV2UsingPOST: async (annotationRequest?: Array<AnnotationRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/jobs/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobResourceV2Api - functional programming interface
 * @export
 */
export const JobResourceV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobResourceV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary finishJobBulk
         * @param {Array<AnnotationRequest>} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async finishJobV2UsingPOST(annotationRequest?: Array<AnnotationRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishJobV2UsingPOST(annotationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobResourceV2Api - factory interface
 * @export
 */
export const JobResourceV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobResourceV2ApiFp(configuration)
    return {
        /**
         * 
         * @summary finishJobBulk
         * @param {Array<AnnotationRequest>} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        finishJobV2UsingPOST(annotationRequest?: Array<AnnotationRequest>, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.finishJobV2UsingPOST(annotationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for finishJobV2UsingPOST operation in JobResourceV2Api.
 * @export
 * @interface JobResourceV2ApiFinishJobV2UsingPOSTRequest
 */
export interface JobResourceV2ApiFinishJobV2UsingPOSTRequest {
    /**
     * 
     * @type {Array<AnnotationRequest>}
     * @memberof JobResourceV2ApiFinishJobV2UsingPOST
     */
    readonly annotationRequest?: Array<AnnotationRequest>
}

/**
 * JobResourceV2Api - object-oriented interface
 * @export
 * @class JobResourceV2Api
 * @extends {BaseAPI}
 */
export class JobResourceV2Api extends BaseAPI {
    /**
     * 
     * @summary finishJobBulk
     * @param {JobResourceV2ApiFinishJobV2UsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof JobResourceV2Api
     */
    public finishJobV2UsingPOST(requestParameters: JobResourceV2ApiFinishJobV2UsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return JobResourceV2ApiFp(this.configuration).finishJobV2UsingPOST(requestParameters.annotationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobResourceV3Api - axios parameter creator
 * @export
 */
export const JobResourceV3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} jobId jobId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishJobLabelerUsingPOST: async (jobId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('finishJobLabelerUsingPOST', 'jobId', jobId)
            const localVarPath = `/api/v3/labeler/jobs/finish/{jobId}`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<TaskObservationRequestDTO>} [taskObservationRequestDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        finishJobV3UsingPOST: async (taskObservationRequestDTO?: Array<TaskObservationRequestDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/jobs/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskObservationRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobResourceV3Api - functional programming interface
 * @export
 */
export const JobResourceV3ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobResourceV3ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} jobId jobId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishJobLabelerUsingPOST(jobId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishJobLabelerUsingPOST(jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<TaskObservationRequestDTO>} [taskObservationRequestDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async finishJobV3UsingPOST(taskObservationRequestDTO?: Array<TaskObservationRequestDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishJobV3UsingPOST(taskObservationRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobResourceV3Api - factory interface
 * @export
 */
export const JobResourceV3ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobResourceV3ApiFp(configuration)
    return {
        /**
         * 
         * @param {number} jobId jobId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishJobLabelerUsingPOST(jobId: number, options?: any): AxiosPromise<RestResponseOfBoolean> {
            return localVarFp.finishJobLabelerUsingPOST(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<TaskObservationRequestDTO>} [taskObservationRequestDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        finishJobV3UsingPOST(taskObservationRequestDTO?: Array<TaskObservationRequestDTO>, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.finishJobV3UsingPOST(taskObservationRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for finishJobLabelerUsingPOST operation in JobResourceV3Api.
 * @export
 * @interface JobResourceV3ApiFinishJobLabelerUsingPOSTRequest
 */
export interface JobResourceV3ApiFinishJobLabelerUsingPOSTRequest {
    /**
     * jobId
     * @type {number}
     * @memberof JobResourceV3ApiFinishJobLabelerUsingPOST
     */
    readonly jobId: number
}

/**
 * Request parameters for finishJobV3UsingPOST operation in JobResourceV3Api.
 * @export
 * @interface JobResourceV3ApiFinishJobV3UsingPOSTRequest
 */
export interface JobResourceV3ApiFinishJobV3UsingPOSTRequest {
    /**
     * 
     * @type {Array<TaskObservationRequestDTO>}
     * @memberof JobResourceV3ApiFinishJobV3UsingPOST
     */
    readonly taskObservationRequestDTO?: Array<TaskObservationRequestDTO>
}

/**
 * JobResourceV3Api - object-oriented interface
 * @export
 * @class JobResourceV3Api
 * @extends {BaseAPI}
 */
export class JobResourceV3Api extends BaseAPI {
    /**
     * 
     * @param {JobResourceV3ApiFinishJobLabelerUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobResourceV3Api
     */
    public finishJobLabelerUsingPOST(requestParameters: JobResourceV3ApiFinishJobLabelerUsingPOSTRequest, options?: AxiosRequestConfig) {
        return JobResourceV3ApiFp(this.configuration).finishJobLabelerUsingPOST(requestParameters.jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {JobResourceV3ApiFinishJobV3UsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof JobResourceV3Api
     */
    public finishJobV3UsingPOST(requestParameters: JobResourceV3ApiFinishJobV3UsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return JobResourceV3ApiFp(this.configuration).finishJobV3UsingPOST(requestParameters.taskObservationRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ObservationResourceApi - axios parameter creator
 * @export
 */
export const ObservationResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createObservation
         * @param {ObservationDTO} [observationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createObservationUsingPOST: async (observationDTO?: ObservationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/observations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(observationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteObservation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteObservationUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteObservationUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/observations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [projectId] projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportObservationUsingGET: async (projectId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/observations/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get Observation annotation type base on project
         * @param {number} [projectId] id
         * @param {string} [projectType] projectType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObservationAnnotationType: async (projectId?: number, projectType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/observations/annotationType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (projectType !== undefined) {
                localVarQueryParameter['projectType'] = projectType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getObservation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObservationUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getObservationUsingGET', 'id', id)
            const localVarPath = `/api/v1/observations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {number} [projectId] projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importObservationUsingPOST: async (file: File, projectId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('importObservationUsingPOST', 'file', file)
            const localVarPath = `/api/v1/observations/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listObservation
         * @param {number} [projectId] projectId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listObservationUsingGET: async (projectId?: number, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/observations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateObservation
         * @param {number} id id
         * @param {ObservationDTO} [observationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateObservationUsingPATCH: async (id: number, observationDTO?: ObservationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateObservationUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/observations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(observationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateObservation
         * @param {Array<ObservationDTO>} [observationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateObservationsUsingPATCH: async (observationDTO?: Array<ObservationDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/observations/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(observationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateObservation
         * @param {number} id id
         * @param {'CREATE' | 'OVERRIDE' | 'OVERRIDE_AND_REOPEN'} [strategy] strategy
         * @param {ObservationDTO} [observationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateObservationUsingPUT: async (id: number, strategy?: 'CREATE' | 'OVERRIDE' | 'OVERRIDE_AND_REOPEN', observationDTO?: ObservationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateObservationUsingPUT', 'id', id)
            const localVarPath = `/api/v1/observations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (strategy !== undefined) {
                localVarQueryParameter['strategy'] = strategy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(observationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ObservationResourceApi - functional programming interface
 * @export
 */
export const ObservationResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ObservationResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createObservation
         * @param {ObservationDTO} [observationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createObservationUsingPOST(observationDTO?: ObservationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObservationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createObservationUsingPOST(observationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteObservation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteObservationUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteObservationUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [projectId] projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportObservationUsingGET(projectId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportObservationUsingGET(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get Observation annotation type base on project
         * @param {number} [projectId] id
         * @param {string} [projectType] projectType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObservationAnnotationType(projectId?: number, projectType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfAttributeType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObservationAnnotationType(projectId, projectType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getObservation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObservationUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObservationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObservationUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {number} [projectId] projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importObservationUsingPOST(file: File, projectId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfObservationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importObservationUsingPOST(file, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary listObservation
         * @param {number} [projectId] projectId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listObservationUsingGET(projectId?: number, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfObservationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listObservationUsingGET(projectId, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateObservation
         * @param {number} id id
         * @param {ObservationDTO} [observationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateObservationUsingPATCH(id: number, observationDTO?: ObservationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObservationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateObservationUsingPATCH(id, observationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateObservation
         * @param {Array<ObservationDTO>} [observationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateObservationsUsingPATCH(observationDTO?: Array<ObservationDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateObservationsUsingPATCH(observationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateObservation
         * @param {number} id id
         * @param {'CREATE' | 'OVERRIDE' | 'OVERRIDE_AND_REOPEN'} [strategy] strategy
         * @param {ObservationDTO} [observationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateObservationUsingPUT(id: number, strategy?: 'CREATE' | 'OVERRIDE' | 'OVERRIDE_AND_REOPEN', observationDTO?: ObservationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObservationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateObservationUsingPUT(id, strategy, observationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ObservationResourceApi - factory interface
 * @export
 */
export const ObservationResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObservationResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary createObservation
         * @param {ObservationDTO} [observationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createObservationUsingPOST(observationDTO?: ObservationDTO, options?: any): AxiosPromise<RestResponseOfObservationDTO> {
            return localVarFp.createObservationUsingPOST(observationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteObservation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteObservationUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteObservationUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [projectId] projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportObservationUsingGET(projectId?: number, options?: any): AxiosPromise<File> {
            return localVarFp.exportObservationUsingGET(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get Observation annotation type base on project
         * @param {number} [projectId] id
         * @param {string} [projectType] projectType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObservationAnnotationType(projectId?: number, projectType?: string, options?: any): AxiosPromise<RestResponseOfAttributeType> {
            return localVarFp.getObservationAnnotationType(projectId, projectType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getObservation
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObservationUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfObservationDTO> {
            return localVarFp.getObservationUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {number} [projectId] projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importObservationUsingPOST(file: File, projectId?: number, options?: any): AxiosPromise<RestResponseOfListOfObservationDTO> {
            return localVarFp.importObservationUsingPOST(file, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary listObservation
         * @param {number} [projectId] projectId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listObservationUsingGET(projectId?: number, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfObservationDTO> {
            return localVarFp.listObservationUsingGET(projectId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateObservation
         * @param {number} id id
         * @param {ObservationDTO} [observationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateObservationUsingPATCH(id: number, observationDTO?: ObservationDTO, options?: any): AxiosPromise<RestResponseOfObservationDTO> {
            return localVarFp.partialUpdateObservationUsingPATCH(id, observationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateObservation
         * @param {Array<ObservationDTO>} [observationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateObservationsUsingPATCH(observationDTO?: Array<ObservationDTO>, options?: any): AxiosPromise<void> {
            return localVarFp.partialUpdateObservationsUsingPATCH(observationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateObservation
         * @param {number} id id
         * @param {'CREATE' | 'OVERRIDE' | 'OVERRIDE_AND_REOPEN'} [strategy] strategy
         * @param {ObservationDTO} [observationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateObservationUsingPUT(id: number, strategy?: 'CREATE' | 'OVERRIDE' | 'OVERRIDE_AND_REOPEN', observationDTO?: ObservationDTO, options?: any): AxiosPromise<RestResponseOfObservationDTO> {
            return localVarFp.updateObservationUsingPUT(id, strategy, observationDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createObservationUsingPOST operation in ObservationResourceApi.
 * @export
 * @interface ObservationResourceApiCreateObservationUsingPOSTRequest
 */
export interface ObservationResourceApiCreateObservationUsingPOSTRequest {
    /**
     * 
     * @type {ObservationDTO}
     * @memberof ObservationResourceApiCreateObservationUsingPOST
     */
    readonly observationDTO?: ObservationDTO
}

/**
 * Request parameters for deleteObservationUsingDELETE operation in ObservationResourceApi.
 * @export
 * @interface ObservationResourceApiDeleteObservationUsingDELETERequest
 */
export interface ObservationResourceApiDeleteObservationUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof ObservationResourceApiDeleteObservationUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for exportObservationUsingGET operation in ObservationResourceApi.
 * @export
 * @interface ObservationResourceApiExportObservationUsingGETRequest
 */
export interface ObservationResourceApiExportObservationUsingGETRequest {
    /**
     * projectId
     * @type {number}
     * @memberof ObservationResourceApiExportObservationUsingGET
     */
    readonly projectId?: number
}

/**
 * Request parameters for getObservationAnnotationType operation in ObservationResourceApi.
 * @export
 * @interface ObservationResourceApiGetObservationAnnotationTypeRequest
 */
export interface ObservationResourceApiGetObservationAnnotationTypeRequest {
    /**
     * id
     * @type {number}
     * @memberof ObservationResourceApiGetObservationAnnotationType
     */
    readonly projectId?: number

    /**
     * projectType
     * @type {string}
     * @memberof ObservationResourceApiGetObservationAnnotationType
     */
    readonly projectType?: string
}

/**
 * Request parameters for getObservationUsingGET operation in ObservationResourceApi.
 * @export
 * @interface ObservationResourceApiGetObservationUsingGETRequest
 */
export interface ObservationResourceApiGetObservationUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof ObservationResourceApiGetObservationUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for importObservationUsingPOST operation in ObservationResourceApi.
 * @export
 * @interface ObservationResourceApiImportObservationUsingPOSTRequest
 */
export interface ObservationResourceApiImportObservationUsingPOSTRequest {
    /**
     * 
     * @type {File}
     * @memberof ObservationResourceApiImportObservationUsingPOST
     */
    readonly file: File

    /**
     * projectId
     * @type {number}
     * @memberof ObservationResourceApiImportObservationUsingPOST
     */
    readonly projectId?: number
}

/**
 * Request parameters for listObservationUsingGET operation in ObservationResourceApi.
 * @export
 * @interface ObservationResourceApiListObservationUsingGETRequest
 */
export interface ObservationResourceApiListObservationUsingGETRequest {
    /**
     * projectId
     * @type {number}
     * @memberof ObservationResourceApiListObservationUsingGET
     */
    readonly projectId?: number

    /**
     * pageable
     * @type {Pageable}
     * @memberof ObservationResourceApiListObservationUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for partialUpdateObservationUsingPATCH operation in ObservationResourceApi.
 * @export
 * @interface ObservationResourceApiPartialUpdateObservationUsingPATCHRequest
 */
export interface ObservationResourceApiPartialUpdateObservationUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof ObservationResourceApiPartialUpdateObservationUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {ObservationDTO}
     * @memberof ObservationResourceApiPartialUpdateObservationUsingPATCH
     */
    readonly observationDTO?: ObservationDTO
}

/**
 * Request parameters for partialUpdateObservationsUsingPATCH operation in ObservationResourceApi.
 * @export
 * @interface ObservationResourceApiPartialUpdateObservationsUsingPATCHRequest
 */
export interface ObservationResourceApiPartialUpdateObservationsUsingPATCHRequest {
    /**
     * 
     * @type {Array<ObservationDTO>}
     * @memberof ObservationResourceApiPartialUpdateObservationsUsingPATCH
     */
    readonly observationDTO?: Array<ObservationDTO>
}

/**
 * Request parameters for updateObservationUsingPUT operation in ObservationResourceApi.
 * @export
 * @interface ObservationResourceApiUpdateObservationUsingPUTRequest
 */
export interface ObservationResourceApiUpdateObservationUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof ObservationResourceApiUpdateObservationUsingPUT
     */
    readonly id: number

    /**
     * strategy
     * @type {'CREATE' | 'OVERRIDE' | 'OVERRIDE_AND_REOPEN'}
     * @memberof ObservationResourceApiUpdateObservationUsingPUT
     */
    readonly strategy?: 'CREATE' | 'OVERRIDE' | 'OVERRIDE_AND_REOPEN'

    /**
     * 
     * @type {ObservationDTO}
     * @memberof ObservationResourceApiUpdateObservationUsingPUT
     */
    readonly observationDTO?: ObservationDTO
}

/**
 * ObservationResourceApi - object-oriented interface
 * @export
 * @class ObservationResourceApi
 * @extends {BaseAPI}
 */
export class ObservationResourceApi extends BaseAPI {
    /**
     * 
     * @summary createObservation
     * @param {ObservationResourceApiCreateObservationUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservationResourceApi
     */
    public createObservationUsingPOST(requestParameters: ObservationResourceApiCreateObservationUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return ObservationResourceApiFp(this.configuration).createObservationUsingPOST(requestParameters.observationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteObservation
     * @param {ObservationResourceApiDeleteObservationUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservationResourceApi
     */
    public deleteObservationUsingDELETE(requestParameters: ObservationResourceApiDeleteObservationUsingDELETERequest, options?: AxiosRequestConfig) {
        return ObservationResourceApiFp(this.configuration).deleteObservationUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ObservationResourceApiExportObservationUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservationResourceApi
     */
    public exportObservationUsingGET(requestParameters: ObservationResourceApiExportObservationUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return ObservationResourceApiFp(this.configuration).exportObservationUsingGET(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get Observation annotation type base on project
     * @param {ObservationResourceApiGetObservationAnnotationTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservationResourceApi
     */
    public getObservationAnnotationType(requestParameters: ObservationResourceApiGetObservationAnnotationTypeRequest = {}, options?: AxiosRequestConfig) {
        return ObservationResourceApiFp(this.configuration).getObservationAnnotationType(requestParameters.projectId, requestParameters.projectType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getObservation
     * @param {ObservationResourceApiGetObservationUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservationResourceApi
     */
    public getObservationUsingGET(requestParameters: ObservationResourceApiGetObservationUsingGETRequest, options?: AxiosRequestConfig) {
        return ObservationResourceApiFp(this.configuration).getObservationUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ObservationResourceApiImportObservationUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservationResourceApi
     */
    public importObservationUsingPOST(requestParameters: ObservationResourceApiImportObservationUsingPOSTRequest, options?: AxiosRequestConfig) {
        return ObservationResourceApiFp(this.configuration).importObservationUsingPOST(requestParameters.file, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary listObservation
     * @param {ObservationResourceApiListObservationUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservationResourceApi
     */
    public listObservationUsingGET(requestParameters: ObservationResourceApiListObservationUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return ObservationResourceApiFp(this.configuration).listObservationUsingGET(requestParameters.projectId, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateObservation
     * @param {ObservationResourceApiPartialUpdateObservationUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservationResourceApi
     */
    public partialUpdateObservationUsingPATCH(requestParameters: ObservationResourceApiPartialUpdateObservationUsingPATCHRequest, options?: AxiosRequestConfig) {
        return ObservationResourceApiFp(this.configuration).partialUpdateObservationUsingPATCH(requestParameters.id, requestParameters.observationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateObservation
     * @param {ObservationResourceApiPartialUpdateObservationsUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservationResourceApi
     */
    public partialUpdateObservationsUsingPATCH(requestParameters: ObservationResourceApiPartialUpdateObservationsUsingPATCHRequest = {}, options?: AxiosRequestConfig) {
        return ObservationResourceApiFp(this.configuration).partialUpdateObservationsUsingPATCH(requestParameters.observationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateObservation
     * @param {ObservationResourceApiUpdateObservationUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservationResourceApi
     */
    public updateObservationUsingPUT(requestParameters: ObservationResourceApiUpdateObservationUsingPUTRequest, options?: AxiosRequestConfig) {
        return ObservationResourceApiFp(this.configuration).updateObservationUsingPUT(requestParameters.id, requestParameters.strategy, requestParameters.observationDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectResourceApi - axios parameter creator
 * @export
 */
export const ProjectResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createProject
         * @param {ProjectRequestDTO} [projectRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectUsingPOST: async (projectRequestDTO?: ProjectRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createProjectV2
         * @param {ProjectV2RequestDTO} [projectV2RequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectV2UsingPOST: async (projectV2RequestDTO?: ProjectV2RequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectV2RequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteProject
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProjectUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllAvailableProjectsForLabeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAvailableProjectsForLabelerUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/labeler/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllProjectTemplates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectTemplatesUsingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/project-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllProjects
         * @param {ProjectCriteria} [criteria] criteria
         * @param {Pageable} [pageable] Page number of the requested page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectsUsingGET: async (criteria?: ProjectCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getF1Statistic
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getF1StatisticUsingGET1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getF1StatisticUsingGET1', 'id', id)
            const localVarPath = `/api/v1/projects/{id}/f1`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getLabelDistribution
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelDistributionUsingGET1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLabelDistributionUsingGET1', 'id', id)
            const localVarPath = `/api/v1/projects/{id}/label-distribution`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProjectTemplatesById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTemplatesById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectTemplatesById', 'id', id)
            const localVarPath = `/api/v1/project-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProjectType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTypeUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projects/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProject
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectUsingGET', 'id', id)
            const localVarPath = `/api/v1/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProjectV2
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectV2UsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectV2UsingGET', 'id', id)
            const localVarPath = `/api/v2/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProjectsByJobCompleted
         * @param {ProjectCriteria} [criteria] criteria
         * @param {Pageable} [pageable] Page number of the requested page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByJobCompletedUsingGET: async (criteria?: ProjectCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projects/by-task-completed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProjectsStatistic
         * @param {boolean} [searchAllTime] searchAllTime
         * @param {string} [startTime] startTime
         * @param {string} [endTime] endTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsStatisticUsingGET: async (searchAllTime?: boolean, startTime?: string, endTime?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projects/statistic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchAllTime !== undefined) {
                localVarQueryParameter['searchAllTime'] = searchAllTime;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getStatistic
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatisticUsingGET1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStatisticUsingGET1', 'id', id)
            const localVarPath = `/api/v1/projects/{id}/statistic`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateProject
         * @param {number} id id
         * @param {ProjectRequestDTO} [projectRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateProjectUsingPATCH: async (id: number, projectRequestDTO?: ProjectRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateProjectUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateProject
         * @param {number} id id
         * @param {ProjectRequestDTO} [projectRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectUsingPUT: async (id: number, projectRequestDTO?: ProjectRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProjectUsingPUT', 'id', id)
            const localVarPath = `/api/v1/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectResourceApi - functional programming interface
 * @export
 */
export const ProjectResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createProject
         * @param {ProjectRequestDTO} [projectRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectUsingPOST(projectRequestDTO?: ProjectRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfProjectResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectUsingPOST(projectRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createProjectV2
         * @param {ProjectV2RequestDTO} [projectV2RequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectV2UsingPOST(projectV2RequestDTO?: ProjectV2RequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfCreateProjectV2ResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectV2UsingPOST(projectV2RequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteProject
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllAvailableProjectsForLabeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAvailableProjectsForLabelerUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfProjectResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAvailableProjectsForLabelerUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllProjectTemplates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectTemplatesUsingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfProjectTemplateResponseDTOS>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProjectTemplatesUsingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllProjects
         * @param {ProjectCriteria} [criteria] criteria
         * @param {Pageable} [pageable] Page number of the requested page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectsUsingGET(criteria?: ProjectCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfProjectResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProjectsUsingGET(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getF1Statistic
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getF1StatisticUsingGET1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfBatchLabelerStatistic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getF1StatisticUsingGET1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getLabelDistribution
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLabelDistributionUsingGET1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfLabelDistribution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLabelDistributionUsingGET1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getProjectTemplatesById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectTemplatesById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfProjectTemplateResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectTemplatesById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getProjectType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectTypeUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfProjectType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectTypeUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getProject
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfProjectResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getProjectV2
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectV2UsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfCreateProjectV2ResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectV2UsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getProjectsByJobCompleted
         * @param {ProjectCriteria} [criteria] criteria
         * @param {Pageable} [pageable] Page number of the requested page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsByJobCompletedUsingGET(criteria?: ProjectCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfProjectEssentialResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsByJobCompletedUsingGET(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getProjectsStatistic
         * @param {boolean} [searchAllTime] searchAllTime
         * @param {string} [startTime] startTime
         * @param {string} [endTime] endTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsStatisticUsingGET(searchAllTime?: boolean, startTime?: string, endTime?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfProjectStatistic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsStatisticUsingGET(searchAllTime, startTime, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getStatistic
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatisticUsingGET1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfProjectProgress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatisticUsingGET1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateProject
         * @param {number} id id
         * @param {ProjectRequestDTO} [projectRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateProjectUsingPATCH(id: number, projectRequestDTO?: ProjectRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfProjectResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateProjectUsingPATCH(id, projectRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateProject
         * @param {number} id id
         * @param {ProjectRequestDTO} [projectRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectUsingPUT(id: number, projectRequestDTO?: ProjectRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfProjectResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectUsingPUT(id, projectRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectResourceApi - factory interface
 * @export
 */
export const ProjectResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary createProject
         * @param {ProjectRequestDTO} [projectRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectUsingPOST(projectRequestDTO?: ProjectRequestDTO, options?: any): AxiosPromise<RestResponseOfProjectResponseDTO> {
            return localVarFp.createProjectUsingPOST(projectRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createProjectV2
         * @param {ProjectV2RequestDTO} [projectV2RequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectV2UsingPOST(projectV2RequestDTO?: ProjectV2RequestDTO, options?: any): AxiosPromise<RestResponseOfCreateProjectV2ResponseDTO> {
            return localVarFp.createProjectV2UsingPOST(projectV2RequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteProject
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteProjectUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllAvailableProjectsForLabeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAvailableProjectsForLabelerUsingGET(options?: any): AxiosPromise<RestResponseOfListOfProjectResponseDTO> {
            return localVarFp.getAllAvailableProjectsForLabelerUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllProjectTemplates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectTemplatesUsingGet(options?: any): AxiosPromise<RestResponseOfProjectTemplateResponseDTOS> {
            return localVarFp.getAllProjectTemplatesUsingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllProjects
         * @param {ProjectCriteria} [criteria] criteria
         * @param {Pageable} [pageable] Page number of the requested page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectsUsingGET(criteria?: ProjectCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfProjectResponseDTO> {
            return localVarFp.getAllProjectsUsingGET(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getF1Statistic
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getF1StatisticUsingGET1(id: number, options?: any): AxiosPromise<RestResponseOfListOfBatchLabelerStatistic> {
            return localVarFp.getF1StatisticUsingGET1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getLabelDistribution
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelDistributionUsingGET1(id: number, options?: any): AxiosPromise<RestResponseOfLabelDistribution> {
            return localVarFp.getLabelDistributionUsingGET1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getProjectTemplatesById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTemplatesById(id: number, options?: any): AxiosPromise<RestResponseOfProjectTemplateResponseDTO> {
            return localVarFp.getProjectTemplatesById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getProjectType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTypeUsingGET(options?: any): AxiosPromise<RestResponseOfProjectType> {
            return localVarFp.getProjectTypeUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getProject
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfProjectResponseDTO> {
            return localVarFp.getProjectUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getProjectV2
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectV2UsingGET(id: number, options?: any): AxiosPromise<RestResponseOfCreateProjectV2ResponseDTO> {
            return localVarFp.getProjectV2UsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getProjectsByJobCompleted
         * @param {ProjectCriteria} [criteria] criteria
         * @param {Pageable} [pageable] Page number of the requested page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByJobCompletedUsingGET(criteria?: ProjectCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfProjectEssentialResponseDTO> {
            return localVarFp.getProjectsByJobCompletedUsingGET(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getProjectsStatistic
         * @param {boolean} [searchAllTime] searchAllTime
         * @param {string} [startTime] startTime
         * @param {string} [endTime] endTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsStatisticUsingGET(searchAllTime?: boolean, startTime?: string, endTime?: string, options?: any): AxiosPromise<RestResponseOfProjectStatistic> {
            return localVarFp.getProjectsStatisticUsingGET(searchAllTime, startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getStatistic
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatisticUsingGET1(id: number, options?: any): AxiosPromise<RestResponseOfProjectProgress> {
            return localVarFp.getStatisticUsingGET1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateProject
         * @param {number} id id
         * @param {ProjectRequestDTO} [projectRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateProjectUsingPATCH(id: number, projectRequestDTO?: ProjectRequestDTO, options?: any): AxiosPromise<RestResponseOfProjectResponseDTO> {
            return localVarFp.partialUpdateProjectUsingPATCH(id, projectRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateProject
         * @param {number} id id
         * @param {ProjectRequestDTO} [projectRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectUsingPUT(id: number, projectRequestDTO?: ProjectRequestDTO, options?: any): AxiosPromise<RestResponseOfProjectResponseDTO> {
            return localVarFp.updateProjectUsingPUT(id, projectRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createProjectUsingPOST operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiCreateProjectUsingPOSTRequest
 */
export interface ProjectResourceApiCreateProjectUsingPOSTRequest {
    /**
     * 
     * @type {ProjectRequestDTO}
     * @memberof ProjectResourceApiCreateProjectUsingPOST
     */
    readonly projectRequestDTO?: ProjectRequestDTO
}

/**
 * Request parameters for createProjectV2UsingPOST operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiCreateProjectV2UsingPOSTRequest
 */
export interface ProjectResourceApiCreateProjectV2UsingPOSTRequest {
    /**
     * 
     * @type {ProjectV2RequestDTO}
     * @memberof ProjectResourceApiCreateProjectV2UsingPOST
     */
    readonly projectV2RequestDTO?: ProjectV2RequestDTO
}

/**
 * Request parameters for deleteProjectUsingDELETE operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiDeleteProjectUsingDELETERequest
 */
export interface ProjectResourceApiDeleteProjectUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof ProjectResourceApiDeleteProjectUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for getAllProjectsUsingGET operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiGetAllProjectsUsingGETRequest
 */
export interface ProjectResourceApiGetAllProjectsUsingGETRequest {
    /**
     * criteria
     * @type {ProjectCriteria}
     * @memberof ProjectResourceApiGetAllProjectsUsingGET
     */
    readonly criteria?: ProjectCriteria

    /**
     * Page number of the requested page
     * @type {Pageable}
     * @memberof ProjectResourceApiGetAllProjectsUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getF1StatisticUsingGET1 operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiGetF1StatisticUsingGET1Request
 */
export interface ProjectResourceApiGetF1StatisticUsingGET1Request {
    /**
     * id
     * @type {number}
     * @memberof ProjectResourceApiGetF1StatisticUsingGET1
     */
    readonly id: number
}

/**
 * Request parameters for getLabelDistributionUsingGET1 operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiGetLabelDistributionUsingGET1Request
 */
export interface ProjectResourceApiGetLabelDistributionUsingGET1Request {
    /**
     * id
     * @type {number}
     * @memberof ProjectResourceApiGetLabelDistributionUsingGET1
     */
    readonly id: number
}

/**
 * Request parameters for getProjectTemplatesById operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiGetProjectTemplatesByIdRequest
 */
export interface ProjectResourceApiGetProjectTemplatesByIdRequest {
    /**
     * id
     * @type {number}
     * @memberof ProjectResourceApiGetProjectTemplatesById
     */
    readonly id: number
}

/**
 * Request parameters for getProjectUsingGET operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiGetProjectUsingGETRequest
 */
export interface ProjectResourceApiGetProjectUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof ProjectResourceApiGetProjectUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for getProjectV2UsingGET operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiGetProjectV2UsingGETRequest
 */
export interface ProjectResourceApiGetProjectV2UsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof ProjectResourceApiGetProjectV2UsingGET
     */
    readonly id: number
}

/**
 * Request parameters for getProjectsByJobCompletedUsingGET operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiGetProjectsByJobCompletedUsingGETRequest
 */
export interface ProjectResourceApiGetProjectsByJobCompletedUsingGETRequest {
    /**
     * criteria
     * @type {ProjectCriteria}
     * @memberof ProjectResourceApiGetProjectsByJobCompletedUsingGET
     */
    readonly criteria?: ProjectCriteria

    /**
     * Page number of the requested page
     * @type {Pageable}
     * @memberof ProjectResourceApiGetProjectsByJobCompletedUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getProjectsStatisticUsingGET operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiGetProjectsStatisticUsingGETRequest
 */
export interface ProjectResourceApiGetProjectsStatisticUsingGETRequest {
    /**
     * searchAllTime
     * @type {boolean}
     * @memberof ProjectResourceApiGetProjectsStatisticUsingGET
     */
    readonly searchAllTime?: boolean

    /**
     * startTime
     * @type {string}
     * @memberof ProjectResourceApiGetProjectsStatisticUsingGET
     */
    readonly startTime?: string

    /**
     * endTime
     * @type {string}
     * @memberof ProjectResourceApiGetProjectsStatisticUsingGET
     */
    readonly endTime?: string
}

/**
 * Request parameters for getStatisticUsingGET1 operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiGetStatisticUsingGET1Request
 */
export interface ProjectResourceApiGetStatisticUsingGET1Request {
    /**
     * id
     * @type {number}
     * @memberof ProjectResourceApiGetStatisticUsingGET1
     */
    readonly id: number
}

/**
 * Request parameters for partialUpdateProjectUsingPATCH operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiPartialUpdateProjectUsingPATCHRequest
 */
export interface ProjectResourceApiPartialUpdateProjectUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof ProjectResourceApiPartialUpdateProjectUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {ProjectRequestDTO}
     * @memberof ProjectResourceApiPartialUpdateProjectUsingPATCH
     */
    readonly projectRequestDTO?: ProjectRequestDTO
}

/**
 * Request parameters for updateProjectUsingPUT operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiUpdateProjectUsingPUTRequest
 */
export interface ProjectResourceApiUpdateProjectUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof ProjectResourceApiUpdateProjectUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {ProjectRequestDTO}
     * @memberof ProjectResourceApiUpdateProjectUsingPUT
     */
    readonly projectRequestDTO?: ProjectRequestDTO
}

/**
 * ProjectResourceApi - object-oriented interface
 * @export
 * @class ProjectResourceApi
 * @extends {BaseAPI}
 */
export class ProjectResourceApi extends BaseAPI {
    /**
     * 
     * @summary createProject
     * @param {ProjectResourceApiCreateProjectUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public createProjectUsingPOST(requestParameters: ProjectResourceApiCreateProjectUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).createProjectUsingPOST(requestParameters.projectRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createProjectV2
     * @param {ProjectResourceApiCreateProjectV2UsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public createProjectV2UsingPOST(requestParameters: ProjectResourceApiCreateProjectV2UsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).createProjectV2UsingPOST(requestParameters.projectV2RequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteProject
     * @param {ProjectResourceApiDeleteProjectUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public deleteProjectUsingDELETE(requestParameters: ProjectResourceApiDeleteProjectUsingDELETERequest, options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).deleteProjectUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllAvailableProjectsForLabeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public getAllAvailableProjectsForLabelerUsingGET(options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).getAllAvailableProjectsForLabelerUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllProjectTemplates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public getAllProjectTemplatesUsingGet(options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).getAllProjectTemplatesUsingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllProjects
     * @param {ProjectResourceApiGetAllProjectsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public getAllProjectsUsingGET(requestParameters: ProjectResourceApiGetAllProjectsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).getAllProjectsUsingGET(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getF1Statistic
     * @param {ProjectResourceApiGetF1StatisticUsingGET1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public getF1StatisticUsingGET1(requestParameters: ProjectResourceApiGetF1StatisticUsingGET1Request, options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).getF1StatisticUsingGET1(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getLabelDistribution
     * @param {ProjectResourceApiGetLabelDistributionUsingGET1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public getLabelDistributionUsingGET1(requestParameters: ProjectResourceApiGetLabelDistributionUsingGET1Request, options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).getLabelDistributionUsingGET1(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getProjectTemplatesById
     * @param {ProjectResourceApiGetProjectTemplatesByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public getProjectTemplatesById(requestParameters: ProjectResourceApiGetProjectTemplatesByIdRequest, options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).getProjectTemplatesById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getProjectType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public getProjectTypeUsingGET(options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).getProjectTypeUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getProject
     * @param {ProjectResourceApiGetProjectUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public getProjectUsingGET(requestParameters: ProjectResourceApiGetProjectUsingGETRequest, options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).getProjectUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getProjectV2
     * @param {ProjectResourceApiGetProjectV2UsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public getProjectV2UsingGET(requestParameters: ProjectResourceApiGetProjectV2UsingGETRequest, options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).getProjectV2UsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getProjectsByJobCompleted
     * @param {ProjectResourceApiGetProjectsByJobCompletedUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public getProjectsByJobCompletedUsingGET(requestParameters: ProjectResourceApiGetProjectsByJobCompletedUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).getProjectsByJobCompletedUsingGET(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getProjectsStatistic
     * @param {ProjectResourceApiGetProjectsStatisticUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public getProjectsStatisticUsingGET(requestParameters: ProjectResourceApiGetProjectsStatisticUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).getProjectsStatisticUsingGET(requestParameters.searchAllTime, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getStatistic
     * @param {ProjectResourceApiGetStatisticUsingGET1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public getStatisticUsingGET1(requestParameters: ProjectResourceApiGetStatisticUsingGET1Request, options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).getStatisticUsingGET1(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateProject
     * @param {ProjectResourceApiPartialUpdateProjectUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public partialUpdateProjectUsingPATCH(requestParameters: ProjectResourceApiPartialUpdateProjectUsingPATCHRequest, options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).partialUpdateProjectUsingPATCH(requestParameters.id, requestParameters.projectRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateProject
     * @param {ProjectResourceApiUpdateProjectUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectResourceApi
     */
    public updateProjectUsingPUT(requestParameters: ProjectResourceApiUpdateProjectUsingPUTRequest, options?: AxiosRequestConfig) {
        return ProjectResourceApiFp(this.configuration).updateProjectUsingPUT(requestParameters.id, requestParameters.projectRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StepConditionResourceApi - axios parameter creator
 * @export
 */
export const StepConditionResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAllStepConditions
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStepConditionsUsingGET: async (pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/step-conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getStepCondition
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStepConditionUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStepConditionUsingGET', 'id', id)
            const localVarPath = `/api/v1/step-conditions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StepConditionResourceApi - functional programming interface
 * @export
 */
export const StepConditionResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StepConditionResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getAllStepConditions
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllStepConditionsUsingGET(pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfStepConditionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllStepConditionsUsingGET(pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getStepCondition
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStepConditionUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfStepConditionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStepConditionUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StepConditionResourceApi - factory interface
 * @export
 */
export const StepConditionResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StepConditionResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary getAllStepConditions
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStepConditionsUsingGET(pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfStepConditionDTO> {
            return localVarFp.getAllStepConditionsUsingGET(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getStepCondition
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStepConditionUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfStepConditionDTO> {
            return localVarFp.getStepConditionUsingGET(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAllStepConditionsUsingGET operation in StepConditionResourceApi.
 * @export
 * @interface StepConditionResourceApiGetAllStepConditionsUsingGETRequest
 */
export interface StepConditionResourceApiGetAllStepConditionsUsingGETRequest {
    /**
     * pageable
     * @type {Pageable}
     * @memberof StepConditionResourceApiGetAllStepConditionsUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getStepConditionUsingGET operation in StepConditionResourceApi.
 * @export
 * @interface StepConditionResourceApiGetStepConditionUsingGETRequest
 */
export interface StepConditionResourceApiGetStepConditionUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof StepConditionResourceApiGetStepConditionUsingGET
     */
    readonly id: number
}

/**
 * StepConditionResourceApi - object-oriented interface
 * @export
 * @class StepConditionResourceApi
 * @extends {BaseAPI}
 */
export class StepConditionResourceApi extends BaseAPI {
    /**
     * 
     * @summary getAllStepConditions
     * @param {StepConditionResourceApiGetAllStepConditionsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepConditionResourceApi
     */
    public getAllStepConditionsUsingGET(requestParameters: StepConditionResourceApiGetAllStepConditionsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return StepConditionResourceApiFp(this.configuration).getAllStepConditionsUsingGET(requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getStepCondition
     * @param {StepConditionResourceApiGetStepConditionUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepConditionResourceApi
     */
    public getStepConditionUsingGET(requestParameters: StepConditionResourceApiGetStepConditionUsingGETRequest, options?: AxiosRequestConfig) {
        return StepConditionResourceApiFp(this.configuration).getStepConditionUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaskResourceApi - axios parameter creator
 * @export
 */
export const TaskResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createTask
         * @param {Array<TaskRequestDTO>} [taskRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskUsingPOST: async (taskRequestDTO?: Array<TaskRequestDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tasks/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteTask
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTaskUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllTasks
         * @param {TaskCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTasksUsingGET: async (criteria?: TaskCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getTaskInGroup
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskInGroupUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTaskInGroupUsingGET', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}/group`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getTask
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTaskUsingGET', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateTask
         * @param {number} id id
         * @param {TaskRequestDTO} [taskRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateTaskUsingPATCH: async (id: number, taskRequestDTO?: TaskRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateTaskUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reopen Tasks
         * @param {ReopenTasksRequest} [reopenTasksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reopenTasks: async (reopenTasksRequest?: ReopenTasksRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tasks/reopen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reopenTasksRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Release available job for task
         * @param {TaskRevokeDTO} [taskRevokeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeTask: async (taskRevokeDTO?: TaskRevokeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tasks/revoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskRevokeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary skip tasks
         * @param {SkipTasksRequest} [skipTasksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipTask: async (skipTasksRequest?: SkipTasksRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tasks/skip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(skipTasksRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateTask
         * @param {number} id id
         * @param {TaskRequestDTO} [taskRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskUsingPUT: async (id: number, taskRequestDTO?: TaskRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTaskUsingPUT', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskResourceApi - functional programming interface
 * @export
 */
export const TaskResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createTask
         * @param {Array<TaskRequestDTO>} [taskRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTaskUsingPOST(taskRequestDTO?: Array<TaskRequestDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfTaskResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTaskUsingPOST(taskRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteTask
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTaskUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTaskUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllTasks
         * @param {TaskCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTasksUsingGET(criteria?: TaskCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfTaskResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTasksUsingGET(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getTaskInGroup
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskInGroupUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfTaskResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskInGroupUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getTask
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfTaskResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateTask
         * @param {number} id id
         * @param {TaskRequestDTO} [taskRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateTaskUsingPATCH(id: number, taskRequestDTO?: TaskRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfTaskResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateTaskUsingPATCH(id, taskRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary reopen Tasks
         * @param {ReopenTasksRequest} [reopenTasksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reopenTasks(reopenTasksRequest?: ReopenTasksRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfTaskResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reopenTasks(reopenTasksRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Release available job for task
         * @param {TaskRevokeDTO} [taskRevokeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeTask(taskRevokeDTO?: TaskRevokeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeTask(taskRevokeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary skip tasks
         * @param {SkipTasksRequest} [skipTasksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async skipTask(skipTasksRequest?: SkipTasksRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfTaskResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.skipTask(skipTasksRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateTask
         * @param {number} id id
         * @param {TaskRequestDTO} [taskRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskUsingPUT(id: number, taskRequestDTO?: TaskRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfTaskResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskUsingPUT(id, taskRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskResourceApi - factory interface
 * @export
 */
export const TaskResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary createTask
         * @param {Array<TaskRequestDTO>} [taskRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskUsingPOST(taskRequestDTO?: Array<TaskRequestDTO>, options?: any): AxiosPromise<RestResponseOfListOfTaskResponseDTO> {
            return localVarFp.createTaskUsingPOST(taskRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteTask
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteTaskUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllTasks
         * @param {TaskCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTasksUsingGET(criteria?: TaskCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfTaskResponseDTO> {
            return localVarFp.getAllTasksUsingGET(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getTaskInGroup
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskInGroupUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfListOfTaskResponseDTO> {
            return localVarFp.getTaskInGroupUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getTask
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfTaskResponseDTO> {
            return localVarFp.getTaskUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateTask
         * @param {number} id id
         * @param {TaskRequestDTO} [taskRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateTaskUsingPATCH(id: number, taskRequestDTO?: TaskRequestDTO, options?: any): AxiosPromise<RestResponseOfTaskResponseDTO> {
            return localVarFp.partialUpdateTaskUsingPATCH(id, taskRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reopen Tasks
         * @param {ReopenTasksRequest} [reopenTasksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reopenTasks(reopenTasksRequest?: ReopenTasksRequest, options?: any): AxiosPromise<RestResponseOfListOfTaskResponseDTO> {
            return localVarFp.reopenTasks(reopenTasksRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Release available job for task
         * @param {TaskRevokeDTO} [taskRevokeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeTask(taskRevokeDTO?: TaskRevokeDTO, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.revokeTask(taskRevokeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary skip tasks
         * @param {SkipTasksRequest} [skipTasksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipTask(skipTasksRequest?: SkipTasksRequest, options?: any): AxiosPromise<RestResponseOfListOfTaskResponseDTO> {
            return localVarFp.skipTask(skipTasksRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateTask
         * @param {number} id id
         * @param {TaskRequestDTO} [taskRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskUsingPUT(id: number, taskRequestDTO?: TaskRequestDTO, options?: any): AxiosPromise<RestResponseOfTaskResponseDTO> {
            return localVarFp.updateTaskUsingPUT(id, taskRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTaskUsingPOST operation in TaskResourceApi.
 * @export
 * @interface TaskResourceApiCreateTaskUsingPOSTRequest
 */
export interface TaskResourceApiCreateTaskUsingPOSTRequest {
    /**
     * 
     * @type {Array<TaskRequestDTO>}
     * @memberof TaskResourceApiCreateTaskUsingPOST
     */
    readonly taskRequestDTO?: Array<TaskRequestDTO>
}

/**
 * Request parameters for deleteTaskUsingDELETE operation in TaskResourceApi.
 * @export
 * @interface TaskResourceApiDeleteTaskUsingDELETERequest
 */
export interface TaskResourceApiDeleteTaskUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof TaskResourceApiDeleteTaskUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for getAllTasksUsingGET operation in TaskResourceApi.
 * @export
 * @interface TaskResourceApiGetAllTasksUsingGETRequest
 */
export interface TaskResourceApiGetAllTasksUsingGETRequest {
    /**
     * criteria
     * @type {TaskCriteria}
     * @memberof TaskResourceApiGetAllTasksUsingGET
     */
    readonly criteria?: TaskCriteria

    /**
     * pageable
     * @type {Pageable}
     * @memberof TaskResourceApiGetAllTasksUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getTaskInGroupUsingGET operation in TaskResourceApi.
 * @export
 * @interface TaskResourceApiGetTaskInGroupUsingGETRequest
 */
export interface TaskResourceApiGetTaskInGroupUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof TaskResourceApiGetTaskInGroupUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for getTaskUsingGET operation in TaskResourceApi.
 * @export
 * @interface TaskResourceApiGetTaskUsingGETRequest
 */
export interface TaskResourceApiGetTaskUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof TaskResourceApiGetTaskUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for partialUpdateTaskUsingPATCH operation in TaskResourceApi.
 * @export
 * @interface TaskResourceApiPartialUpdateTaskUsingPATCHRequest
 */
export interface TaskResourceApiPartialUpdateTaskUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof TaskResourceApiPartialUpdateTaskUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {TaskRequestDTO}
     * @memberof TaskResourceApiPartialUpdateTaskUsingPATCH
     */
    readonly taskRequestDTO?: TaskRequestDTO
}

/**
 * Request parameters for reopenTasks operation in TaskResourceApi.
 * @export
 * @interface TaskResourceApiReopenTasksRequest
 */
export interface TaskResourceApiReopenTasksRequest {
    /**
     * 
     * @type {ReopenTasksRequest}
     * @memberof TaskResourceApiReopenTasks
     */
    readonly reopenTasksRequest?: ReopenTasksRequest
}

/**
 * Request parameters for revokeTask operation in TaskResourceApi.
 * @export
 * @interface TaskResourceApiRevokeTaskRequest
 */
export interface TaskResourceApiRevokeTaskRequest {
    /**
     * 
     * @type {TaskRevokeDTO}
     * @memberof TaskResourceApiRevokeTask
     */
    readonly taskRevokeDTO?: TaskRevokeDTO
}

/**
 * Request parameters for skipTask operation in TaskResourceApi.
 * @export
 * @interface TaskResourceApiSkipTaskRequest
 */
export interface TaskResourceApiSkipTaskRequest {
    /**
     * 
     * @type {SkipTasksRequest}
     * @memberof TaskResourceApiSkipTask
     */
    readonly skipTasksRequest?: SkipTasksRequest
}

/**
 * Request parameters for updateTaskUsingPUT operation in TaskResourceApi.
 * @export
 * @interface TaskResourceApiUpdateTaskUsingPUTRequest
 */
export interface TaskResourceApiUpdateTaskUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof TaskResourceApiUpdateTaskUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {TaskRequestDTO}
     * @memberof TaskResourceApiUpdateTaskUsingPUT
     */
    readonly taskRequestDTO?: TaskRequestDTO
}

/**
 * TaskResourceApi - object-oriented interface
 * @export
 * @class TaskResourceApi
 * @extends {BaseAPI}
 */
export class TaskResourceApi extends BaseAPI {
    /**
     * 
     * @summary createTask
     * @param {TaskResourceApiCreateTaskUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskResourceApi
     */
    public createTaskUsingPOST(requestParameters: TaskResourceApiCreateTaskUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return TaskResourceApiFp(this.configuration).createTaskUsingPOST(requestParameters.taskRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteTask
     * @param {TaskResourceApiDeleteTaskUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskResourceApi
     */
    public deleteTaskUsingDELETE(requestParameters: TaskResourceApiDeleteTaskUsingDELETERequest, options?: AxiosRequestConfig) {
        return TaskResourceApiFp(this.configuration).deleteTaskUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllTasks
     * @param {TaskResourceApiGetAllTasksUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskResourceApi
     */
    public getAllTasksUsingGET(requestParameters: TaskResourceApiGetAllTasksUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return TaskResourceApiFp(this.configuration).getAllTasksUsingGET(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getTaskInGroup
     * @param {TaskResourceApiGetTaskInGroupUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskResourceApi
     */
    public getTaskInGroupUsingGET(requestParameters: TaskResourceApiGetTaskInGroupUsingGETRequest, options?: AxiosRequestConfig) {
        return TaskResourceApiFp(this.configuration).getTaskInGroupUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getTask
     * @param {TaskResourceApiGetTaskUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskResourceApi
     */
    public getTaskUsingGET(requestParameters: TaskResourceApiGetTaskUsingGETRequest, options?: AxiosRequestConfig) {
        return TaskResourceApiFp(this.configuration).getTaskUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateTask
     * @param {TaskResourceApiPartialUpdateTaskUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskResourceApi
     */
    public partialUpdateTaskUsingPATCH(requestParameters: TaskResourceApiPartialUpdateTaskUsingPATCHRequest, options?: AxiosRequestConfig) {
        return TaskResourceApiFp(this.configuration).partialUpdateTaskUsingPATCH(requestParameters.id, requestParameters.taskRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reopen Tasks
     * @param {TaskResourceApiReopenTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskResourceApi
     */
    public reopenTasks(requestParameters: TaskResourceApiReopenTasksRequest = {}, options?: AxiosRequestConfig) {
        return TaskResourceApiFp(this.configuration).reopenTasks(requestParameters.reopenTasksRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Release available job for task
     * @param {TaskResourceApiRevokeTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskResourceApi
     */
    public revokeTask(requestParameters: TaskResourceApiRevokeTaskRequest = {}, options?: AxiosRequestConfig) {
        return TaskResourceApiFp(this.configuration).revokeTask(requestParameters.taskRevokeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary skip tasks
     * @param {TaskResourceApiSkipTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskResourceApi
     */
    public skipTask(requestParameters: TaskResourceApiSkipTaskRequest = {}, options?: AxiosRequestConfig) {
        return TaskResourceApiFp(this.configuration).skipTask(requestParameters.skipTasksRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateTask
     * @param {TaskResourceApiUpdateTaskUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskResourceApi
     */
    public updateTaskUsingPUT(requestParameters: TaskResourceApiUpdateTaskUsingPUTRequest, options?: AxiosRequestConfig) {
        return TaskResourceApiFp(this.configuration).updateTaskUsingPUT(requestParameters.id, requestParameters.taskRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrainingJobResourceApi - axios parameter creator
 * @export
 */
export const TrainingJobResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createTrainingJob
         * @param {RunRequest} [runRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrainingJobUsingPOST: async (runRequest?: RunRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/training-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(runRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadModel
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadModelUsingGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadModelUsingGET', 'id', id)
            const localVarPath = `/api/v1/training-jobs/{id}/model-download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAlltrainingJobs
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlltrainingJobsUsingGET: async (pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/training-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getTrainingJob
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingJobUsingGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTrainingJobUsingGET', 'id', id)
            const localVarPath = `/api/v1/training-jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainingJobResourceApi - functional programming interface
 * @export
 */
export const TrainingJobResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrainingJobResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createTrainingJob
         * @param {RunRequest} [runRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTrainingJobUsingPOST(runRequest?: RunRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfRunInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTrainingJobUsingPOST(runRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary downloadModel
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadModelUsingGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadModelUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAlltrainingJobs
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlltrainingJobsUsingGET(pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfRunInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlltrainingJobsUsingGET(pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getTrainingJob
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrainingJobUsingGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfRunInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingJobUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrainingJobResourceApi - factory interface
 * @export
 */
export const TrainingJobResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrainingJobResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary createTrainingJob
         * @param {RunRequest} [runRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrainingJobUsingPOST(runRequest?: RunRequest, options?: any): AxiosPromise<RestResponseOfRunInfo> {
            return localVarFp.createTrainingJobUsingPOST(runRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary downloadModel
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadModelUsingGET(id: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadModelUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAlltrainingJobs
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlltrainingJobsUsingGET(pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfRunInfo> {
            return localVarFp.getAlltrainingJobsUsingGET(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getTrainingJob
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingJobUsingGET(id: string, options?: any): AxiosPromise<RestResponseOfRunInfo> {
            return localVarFp.getTrainingJobUsingGET(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTrainingJobUsingPOST operation in TrainingJobResourceApi.
 * @export
 * @interface TrainingJobResourceApiCreateTrainingJobUsingPOSTRequest
 */
export interface TrainingJobResourceApiCreateTrainingJobUsingPOSTRequest {
    /**
     * 
     * @type {RunRequest}
     * @memberof TrainingJobResourceApiCreateTrainingJobUsingPOST
     */
    readonly runRequest?: RunRequest
}

/**
 * Request parameters for downloadModelUsingGET operation in TrainingJobResourceApi.
 * @export
 * @interface TrainingJobResourceApiDownloadModelUsingGETRequest
 */
export interface TrainingJobResourceApiDownloadModelUsingGETRequest {
    /**
     * id
     * @type {string}
     * @memberof TrainingJobResourceApiDownloadModelUsingGET
     */
    readonly id: string
}

/**
 * Request parameters for getAlltrainingJobsUsingGET operation in TrainingJobResourceApi.
 * @export
 * @interface TrainingJobResourceApiGetAlltrainingJobsUsingGETRequest
 */
export interface TrainingJobResourceApiGetAlltrainingJobsUsingGETRequest {
    /**
     * pageable
     * @type {Pageable}
     * @memberof TrainingJobResourceApiGetAlltrainingJobsUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getTrainingJobUsingGET operation in TrainingJobResourceApi.
 * @export
 * @interface TrainingJobResourceApiGetTrainingJobUsingGETRequest
 */
export interface TrainingJobResourceApiGetTrainingJobUsingGETRequest {
    /**
     * id
     * @type {string}
     * @memberof TrainingJobResourceApiGetTrainingJobUsingGET
     */
    readonly id: string
}

/**
 * TrainingJobResourceApi - object-oriented interface
 * @export
 * @class TrainingJobResourceApi
 * @extends {BaseAPI}
 */
export class TrainingJobResourceApi extends BaseAPI {
    /**
     * 
     * @summary createTrainingJob
     * @param {TrainingJobResourceApiCreateTrainingJobUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingJobResourceApi
     */
    public createTrainingJobUsingPOST(requestParameters: TrainingJobResourceApiCreateTrainingJobUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return TrainingJobResourceApiFp(this.configuration).createTrainingJobUsingPOST(requestParameters.runRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary downloadModel
     * @param {TrainingJobResourceApiDownloadModelUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingJobResourceApi
     */
    public downloadModelUsingGET(requestParameters: TrainingJobResourceApiDownloadModelUsingGETRequest, options?: AxiosRequestConfig) {
        return TrainingJobResourceApiFp(this.configuration).downloadModelUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAlltrainingJobs
     * @param {TrainingJobResourceApiGetAlltrainingJobsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingJobResourceApi
     */
    public getAlltrainingJobsUsingGET(requestParameters: TrainingJobResourceApiGetAlltrainingJobsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return TrainingJobResourceApiFp(this.configuration).getAlltrainingJobsUsingGET(requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getTrainingJob
     * @param {TrainingJobResourceApiGetTrainingJobUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingJobResourceApi
     */
    public getTrainingJobUsingGET(requestParameters: TrainingJobResourceApiGetTrainingJobUsingGETRequest, options?: AxiosRequestConfig) {
        return TrainingJobResourceApiFp(this.configuration).getTrainingJobUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkInstructionResourceApi - axios parameter creator
 * @export
 */
export const WorkInstructionResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createWorkInstruction
         * @param {WorkInstructionRequestDTO} [workInstructionRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkInstructionUsingPOST: async (workInstructionRequestDTO?: WorkInstructionRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/work-instructions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workInstructionRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteWorkInstruction
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkInstructionUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWorkInstructionUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/work-instructions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllWorkInstructions
         * @param {Pageable} [pageable] pageable
         * @param {number} [workflowId] workflowId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkInstructionsUsingGET: async (pageable?: Pageable, workflowId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/work-instructions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }

            if (workflowId !== undefined) {
                localVarQueryParameter['workflowId'] = workflowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getWorkInstruction
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkInstructionUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWorkInstructionUsingGET', 'id', id)
            const localVarPath = `/api/v1/work-instructions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateWorkInstruction
         * @param {number} id id
         * @param {WorkInstructionRequestDTO} [workInstructionRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateWorkInstructionUsingPATCH: async (id: number, workInstructionRequestDTO?: WorkInstructionRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateWorkInstructionUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/work-instructions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workInstructionRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateWorkInstruction
         * @param {number} id id
         * @param {WorkInstructionRequestDTO} [workInstructionRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkInstructionUsingPUT: async (id: number, workInstructionRequestDTO?: WorkInstructionRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWorkInstructionUsingPUT', 'id', id)
            const localVarPath = `/api/v1/work-instructions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workInstructionRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkInstructionResourceApi - functional programming interface
 * @export
 */
export const WorkInstructionResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkInstructionResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createWorkInstruction
         * @param {WorkInstructionRequestDTO} [workInstructionRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkInstructionUsingPOST(workInstructionRequestDTO?: WorkInstructionRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfWorkInstructionResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkInstructionUsingPOST(workInstructionRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteWorkInstruction
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkInstructionUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkInstructionUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllWorkInstructions
         * @param {Pageable} [pageable] pageable
         * @param {number} [workflowId] workflowId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWorkInstructionsUsingGET(pageable?: Pageable, workflowId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfWorkInstructionResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWorkInstructionsUsingGET(pageable, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getWorkInstruction
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkInstructionUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfWorkInstructionResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkInstructionUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateWorkInstruction
         * @param {number} id id
         * @param {WorkInstructionRequestDTO} [workInstructionRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateWorkInstructionUsingPATCH(id: number, workInstructionRequestDTO?: WorkInstructionRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfWorkInstructionResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateWorkInstructionUsingPATCH(id, workInstructionRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateWorkInstruction
         * @param {number} id id
         * @param {WorkInstructionRequestDTO} [workInstructionRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkInstructionUsingPUT(id: number, workInstructionRequestDTO?: WorkInstructionRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfWorkInstructionResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkInstructionUsingPUT(id, workInstructionRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkInstructionResourceApi - factory interface
 * @export
 */
export const WorkInstructionResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkInstructionResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary createWorkInstruction
         * @param {WorkInstructionRequestDTO} [workInstructionRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkInstructionUsingPOST(workInstructionRequestDTO?: WorkInstructionRequestDTO, options?: any): AxiosPromise<RestResponseOfWorkInstructionResponseDTO> {
            return localVarFp.createWorkInstructionUsingPOST(workInstructionRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteWorkInstruction
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkInstructionUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteWorkInstructionUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllWorkInstructions
         * @param {Pageable} [pageable] pageable
         * @param {number} [workflowId] workflowId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkInstructionsUsingGET(pageable?: Pageable, workflowId?: number, options?: any): AxiosPromise<RestResponseOfListOfWorkInstructionResponseDTO> {
            return localVarFp.getAllWorkInstructionsUsingGET(pageable, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getWorkInstruction
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkInstructionUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfWorkInstructionResponseDTO> {
            return localVarFp.getWorkInstructionUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateWorkInstruction
         * @param {number} id id
         * @param {WorkInstructionRequestDTO} [workInstructionRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateWorkInstructionUsingPATCH(id: number, workInstructionRequestDTO?: WorkInstructionRequestDTO, options?: any): AxiosPromise<RestResponseOfWorkInstructionResponseDTO> {
            return localVarFp.partialUpdateWorkInstructionUsingPATCH(id, workInstructionRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateWorkInstruction
         * @param {number} id id
         * @param {WorkInstructionRequestDTO} [workInstructionRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkInstructionUsingPUT(id: number, workInstructionRequestDTO?: WorkInstructionRequestDTO, options?: any): AxiosPromise<RestResponseOfWorkInstructionResponseDTO> {
            return localVarFp.updateWorkInstructionUsingPUT(id, workInstructionRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createWorkInstructionUsingPOST operation in WorkInstructionResourceApi.
 * @export
 * @interface WorkInstructionResourceApiCreateWorkInstructionUsingPOSTRequest
 */
export interface WorkInstructionResourceApiCreateWorkInstructionUsingPOSTRequest {
    /**
     * 
     * @type {WorkInstructionRequestDTO}
     * @memberof WorkInstructionResourceApiCreateWorkInstructionUsingPOST
     */
    readonly workInstructionRequestDTO?: WorkInstructionRequestDTO
}

/**
 * Request parameters for deleteWorkInstructionUsingDELETE operation in WorkInstructionResourceApi.
 * @export
 * @interface WorkInstructionResourceApiDeleteWorkInstructionUsingDELETERequest
 */
export interface WorkInstructionResourceApiDeleteWorkInstructionUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof WorkInstructionResourceApiDeleteWorkInstructionUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for getAllWorkInstructionsUsingGET operation in WorkInstructionResourceApi.
 * @export
 * @interface WorkInstructionResourceApiGetAllWorkInstructionsUsingGETRequest
 */
export interface WorkInstructionResourceApiGetAllWorkInstructionsUsingGETRequest {
    /**
     * pageable
     * @type {Pageable}
     * @memberof WorkInstructionResourceApiGetAllWorkInstructionsUsingGET
     */
    readonly pageable?: Pageable

    /**
     * workflowId
     * @type {number}
     * @memberof WorkInstructionResourceApiGetAllWorkInstructionsUsingGET
     */
    readonly workflowId?: number
}

/**
 * Request parameters for getWorkInstructionUsingGET operation in WorkInstructionResourceApi.
 * @export
 * @interface WorkInstructionResourceApiGetWorkInstructionUsingGETRequest
 */
export interface WorkInstructionResourceApiGetWorkInstructionUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof WorkInstructionResourceApiGetWorkInstructionUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for partialUpdateWorkInstructionUsingPATCH operation in WorkInstructionResourceApi.
 * @export
 * @interface WorkInstructionResourceApiPartialUpdateWorkInstructionUsingPATCHRequest
 */
export interface WorkInstructionResourceApiPartialUpdateWorkInstructionUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof WorkInstructionResourceApiPartialUpdateWorkInstructionUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {WorkInstructionRequestDTO}
     * @memberof WorkInstructionResourceApiPartialUpdateWorkInstructionUsingPATCH
     */
    readonly workInstructionRequestDTO?: WorkInstructionRequestDTO
}

/**
 * Request parameters for updateWorkInstructionUsingPUT operation in WorkInstructionResourceApi.
 * @export
 * @interface WorkInstructionResourceApiUpdateWorkInstructionUsingPUTRequest
 */
export interface WorkInstructionResourceApiUpdateWorkInstructionUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof WorkInstructionResourceApiUpdateWorkInstructionUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {WorkInstructionRequestDTO}
     * @memberof WorkInstructionResourceApiUpdateWorkInstructionUsingPUT
     */
    readonly workInstructionRequestDTO?: WorkInstructionRequestDTO
}

/**
 * WorkInstructionResourceApi - object-oriented interface
 * @export
 * @class WorkInstructionResourceApi
 * @extends {BaseAPI}
 */
export class WorkInstructionResourceApi extends BaseAPI {
    /**
     * 
     * @summary createWorkInstruction
     * @param {WorkInstructionResourceApiCreateWorkInstructionUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkInstructionResourceApi
     */
    public createWorkInstructionUsingPOST(requestParameters: WorkInstructionResourceApiCreateWorkInstructionUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return WorkInstructionResourceApiFp(this.configuration).createWorkInstructionUsingPOST(requestParameters.workInstructionRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteWorkInstruction
     * @param {WorkInstructionResourceApiDeleteWorkInstructionUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkInstructionResourceApi
     */
    public deleteWorkInstructionUsingDELETE(requestParameters: WorkInstructionResourceApiDeleteWorkInstructionUsingDELETERequest, options?: AxiosRequestConfig) {
        return WorkInstructionResourceApiFp(this.configuration).deleteWorkInstructionUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllWorkInstructions
     * @param {WorkInstructionResourceApiGetAllWorkInstructionsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkInstructionResourceApi
     */
    public getAllWorkInstructionsUsingGET(requestParameters: WorkInstructionResourceApiGetAllWorkInstructionsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return WorkInstructionResourceApiFp(this.configuration).getAllWorkInstructionsUsingGET(requestParameters.pageable, requestParameters.workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getWorkInstruction
     * @param {WorkInstructionResourceApiGetWorkInstructionUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkInstructionResourceApi
     */
    public getWorkInstructionUsingGET(requestParameters: WorkInstructionResourceApiGetWorkInstructionUsingGETRequest, options?: AxiosRequestConfig) {
        return WorkInstructionResourceApiFp(this.configuration).getWorkInstructionUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateWorkInstruction
     * @param {WorkInstructionResourceApiPartialUpdateWorkInstructionUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkInstructionResourceApi
     */
    public partialUpdateWorkInstructionUsingPATCH(requestParameters: WorkInstructionResourceApiPartialUpdateWorkInstructionUsingPATCHRequest, options?: AxiosRequestConfig) {
        return WorkInstructionResourceApiFp(this.configuration).partialUpdateWorkInstructionUsingPATCH(requestParameters.id, requestParameters.workInstructionRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateWorkInstruction
     * @param {WorkInstructionResourceApiUpdateWorkInstructionUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkInstructionResourceApi
     */
    public updateWorkInstructionUsingPUT(requestParameters: WorkInstructionResourceApiUpdateWorkInstructionUsingPUTRequest, options?: AxiosRequestConfig) {
        return WorkInstructionResourceApiFp(this.configuration).updateWorkInstructionUsingPUT(requestParameters.id, requestParameters.workInstructionRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkManagementResourceApi - axios parameter creator
 * @export
 */
export const WorkManagementResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAllWorkManagements
         * @param {number} [batchId] batchId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkManagementsUsingGET: async (batchId?: number, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/work-managements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getWorkManagement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkManagementUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWorkManagementUsingGET', 'id', id)
            const localVarPath = `/api/v1/work-managements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkManagementResourceApi - functional programming interface
 * @export
 */
export const WorkManagementResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkManagementResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getAllWorkManagements
         * @param {number} [batchId] batchId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWorkManagementsUsingGET(batchId?: number, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfWorkManagementDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWorkManagementsUsingGET(batchId, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getWorkManagement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkManagementUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfWorkManagementDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkManagementUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkManagementResourceApi - factory interface
 * @export
 */
export const WorkManagementResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkManagementResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary getAllWorkManagements
         * @param {number} [batchId] batchId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkManagementsUsingGET(batchId?: number, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfWorkManagementDTO> {
            return localVarFp.getAllWorkManagementsUsingGET(batchId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getWorkManagement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkManagementUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfWorkManagementDTO> {
            return localVarFp.getWorkManagementUsingGET(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAllWorkManagementsUsingGET operation in WorkManagementResourceApi.
 * @export
 * @interface WorkManagementResourceApiGetAllWorkManagementsUsingGETRequest
 */
export interface WorkManagementResourceApiGetAllWorkManagementsUsingGETRequest {
    /**
     * batchId
     * @type {number}
     * @memberof WorkManagementResourceApiGetAllWorkManagementsUsingGET
     */
    readonly batchId?: number

    /**
     * pageable
     * @type {Pageable}
     * @memberof WorkManagementResourceApiGetAllWorkManagementsUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getWorkManagementUsingGET operation in WorkManagementResourceApi.
 * @export
 * @interface WorkManagementResourceApiGetWorkManagementUsingGETRequest
 */
export interface WorkManagementResourceApiGetWorkManagementUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof WorkManagementResourceApiGetWorkManagementUsingGET
     */
    readonly id: number
}

/**
 * WorkManagementResourceApi - object-oriented interface
 * @export
 * @class WorkManagementResourceApi
 * @extends {BaseAPI}
 */
export class WorkManagementResourceApi extends BaseAPI {
    /**
     * 
     * @summary getAllWorkManagements
     * @param {WorkManagementResourceApiGetAllWorkManagementsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkManagementResourceApi
     */
    public getAllWorkManagementsUsingGET(requestParameters: WorkManagementResourceApiGetAllWorkManagementsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return WorkManagementResourceApiFp(this.configuration).getAllWorkManagementsUsingGET(requestParameters.batchId, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getWorkManagement
     * @param {WorkManagementResourceApiGetWorkManagementUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkManagementResourceApi
     */
    public getWorkManagementUsingGET(requestParameters: WorkManagementResourceApiGetWorkManagementUsingGETRequest, options?: AxiosRequestConfig) {
        return WorkManagementResourceApiFp(this.configuration).getWorkManagementUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkflowResourceApi - axios parameter creator
 * @export
 */
export const WorkflowResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createWorkflow
         * @param {WorkflowDTO} [workflowDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowUsingPOST: async (workflowDTO?: WorkflowDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workflowDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createWorkflow
         * @param {WorkflowDTOV2} [workflowDTOV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowV2UsingPOST: async (workflowDTOV2?: WorkflowDTOV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workflowDTOV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteWorkflow
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWorkflowUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllWorkflows
         * @param {WorkflowCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkflowsUsingGET: async (criteria?: WorkflowCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getWorkflow
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWorkflowUsingGET', 'id', id)
            const localVarPath = `/api/v1/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateWorkflow
         * @param {number} id id
         * @param {WorkflowDTO} [workflowDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateWorkflowUsingPATCH: async (id: number, workflowDTO?: WorkflowDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateWorkflowUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workflowDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateWorkflow
         * @param {number} id id
         * @param {WorkflowDTO} [workflowDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowUsingPUT: async (id: number, workflowDTO?: WorkflowDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWorkflowUsingPUT', 'id', id)
            const localVarPath = `/api/v1/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workflowDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowResourceApi - functional programming interface
 * @export
 */
export const WorkflowResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkflowResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createWorkflow
         * @param {WorkflowDTO} [workflowDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflowUsingPOST(workflowDTO?: WorkflowDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfWorkflowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflowUsingPOST(workflowDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createWorkflow
         * @param {WorkflowDTOV2} [workflowDTOV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflowV2UsingPOST(workflowDTOV2?: WorkflowDTOV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowDTOV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflowV2UsingPOST(workflowDTOV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteWorkflow
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkflowUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkflowUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllWorkflows
         * @param {WorkflowCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWorkflowsUsingGET(criteria?: WorkflowCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfWorkflowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWorkflowsUsingGET(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getWorkflow
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfWorkflowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateWorkflow
         * @param {number} id id
         * @param {WorkflowDTO} [workflowDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateWorkflowUsingPATCH(id: number, workflowDTO?: WorkflowDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfWorkflowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateWorkflowUsingPATCH(id, workflowDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateWorkflow
         * @param {number} id id
         * @param {WorkflowDTO} [workflowDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkflowUsingPUT(id: number, workflowDTO?: WorkflowDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfWorkflowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkflowUsingPUT(id, workflowDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkflowResourceApi - factory interface
 * @export
 */
export const WorkflowResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkflowResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary createWorkflow
         * @param {WorkflowDTO} [workflowDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowUsingPOST(workflowDTO?: WorkflowDTO, options?: any): AxiosPromise<RestResponseOfWorkflowDTO> {
            return localVarFp.createWorkflowUsingPOST(workflowDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createWorkflow
         * @param {WorkflowDTOV2} [workflowDTOV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowV2UsingPOST(workflowDTOV2?: WorkflowDTOV2, options?: any): AxiosPromise<WorkflowDTOV2> {
            return localVarFp.createWorkflowV2UsingPOST(workflowDTOV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteWorkflow
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteWorkflowUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllWorkflows
         * @param {WorkflowCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkflowsUsingGET(criteria?: WorkflowCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfWorkflowDTO> {
            return localVarFp.getAllWorkflowsUsingGET(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getWorkflow
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfWorkflowDTO> {
            return localVarFp.getWorkflowUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateWorkflow
         * @param {number} id id
         * @param {WorkflowDTO} [workflowDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateWorkflowUsingPATCH(id: number, workflowDTO?: WorkflowDTO, options?: any): AxiosPromise<RestResponseOfWorkflowDTO> {
            return localVarFp.partialUpdateWorkflowUsingPATCH(id, workflowDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateWorkflow
         * @param {number} id id
         * @param {WorkflowDTO} [workflowDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowUsingPUT(id: number, workflowDTO?: WorkflowDTO, options?: any): AxiosPromise<RestResponseOfWorkflowDTO> {
            return localVarFp.updateWorkflowUsingPUT(id, workflowDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createWorkflowUsingPOST operation in WorkflowResourceApi.
 * @export
 * @interface WorkflowResourceApiCreateWorkflowUsingPOSTRequest
 */
export interface WorkflowResourceApiCreateWorkflowUsingPOSTRequest {
    /**
     * 
     * @type {WorkflowDTO}
     * @memberof WorkflowResourceApiCreateWorkflowUsingPOST
     */
    readonly workflowDTO?: WorkflowDTO
}

/**
 * Request parameters for createWorkflowV2UsingPOST operation in WorkflowResourceApi.
 * @export
 * @interface WorkflowResourceApiCreateWorkflowV2UsingPOSTRequest
 */
export interface WorkflowResourceApiCreateWorkflowV2UsingPOSTRequest {
    /**
     * 
     * @type {WorkflowDTOV2}
     * @memberof WorkflowResourceApiCreateWorkflowV2UsingPOST
     */
    readonly workflowDTOV2?: WorkflowDTOV2
}

/**
 * Request parameters for deleteWorkflowUsingDELETE operation in WorkflowResourceApi.
 * @export
 * @interface WorkflowResourceApiDeleteWorkflowUsingDELETERequest
 */
export interface WorkflowResourceApiDeleteWorkflowUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof WorkflowResourceApiDeleteWorkflowUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for getAllWorkflowsUsingGET operation in WorkflowResourceApi.
 * @export
 * @interface WorkflowResourceApiGetAllWorkflowsUsingGETRequest
 */
export interface WorkflowResourceApiGetAllWorkflowsUsingGETRequest {
    /**
     * criteria
     * @type {WorkflowCriteria}
     * @memberof WorkflowResourceApiGetAllWorkflowsUsingGET
     */
    readonly criteria?: WorkflowCriteria

    /**
     * pageable
     * @type {Pageable}
     * @memberof WorkflowResourceApiGetAllWorkflowsUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getWorkflowUsingGET operation in WorkflowResourceApi.
 * @export
 * @interface WorkflowResourceApiGetWorkflowUsingGETRequest
 */
export interface WorkflowResourceApiGetWorkflowUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof WorkflowResourceApiGetWorkflowUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for partialUpdateWorkflowUsingPATCH operation in WorkflowResourceApi.
 * @export
 * @interface WorkflowResourceApiPartialUpdateWorkflowUsingPATCHRequest
 */
export interface WorkflowResourceApiPartialUpdateWorkflowUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof WorkflowResourceApiPartialUpdateWorkflowUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {WorkflowDTO}
     * @memberof WorkflowResourceApiPartialUpdateWorkflowUsingPATCH
     */
    readonly workflowDTO?: WorkflowDTO
}

/**
 * Request parameters for updateWorkflowUsingPUT operation in WorkflowResourceApi.
 * @export
 * @interface WorkflowResourceApiUpdateWorkflowUsingPUTRequest
 */
export interface WorkflowResourceApiUpdateWorkflowUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof WorkflowResourceApiUpdateWorkflowUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {WorkflowDTO}
     * @memberof WorkflowResourceApiUpdateWorkflowUsingPUT
     */
    readonly workflowDTO?: WorkflowDTO
}

/**
 * WorkflowResourceApi - object-oriented interface
 * @export
 * @class WorkflowResourceApi
 * @extends {BaseAPI}
 */
export class WorkflowResourceApi extends BaseAPI {
    /**
     * 
     * @summary createWorkflow
     * @param {WorkflowResourceApiCreateWorkflowUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowResourceApi
     */
    public createWorkflowUsingPOST(requestParameters: WorkflowResourceApiCreateWorkflowUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return WorkflowResourceApiFp(this.configuration).createWorkflowUsingPOST(requestParameters.workflowDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createWorkflow
     * @param {WorkflowResourceApiCreateWorkflowV2UsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowResourceApi
     */
    public createWorkflowV2UsingPOST(requestParameters: WorkflowResourceApiCreateWorkflowV2UsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return WorkflowResourceApiFp(this.configuration).createWorkflowV2UsingPOST(requestParameters.workflowDTOV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteWorkflow
     * @param {WorkflowResourceApiDeleteWorkflowUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowResourceApi
     */
    public deleteWorkflowUsingDELETE(requestParameters: WorkflowResourceApiDeleteWorkflowUsingDELETERequest, options?: AxiosRequestConfig) {
        return WorkflowResourceApiFp(this.configuration).deleteWorkflowUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllWorkflows
     * @param {WorkflowResourceApiGetAllWorkflowsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowResourceApi
     */
    public getAllWorkflowsUsingGET(requestParameters: WorkflowResourceApiGetAllWorkflowsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return WorkflowResourceApiFp(this.configuration).getAllWorkflowsUsingGET(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getWorkflow
     * @param {WorkflowResourceApiGetWorkflowUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowResourceApi
     */
    public getWorkflowUsingGET(requestParameters: WorkflowResourceApiGetWorkflowUsingGETRequest, options?: AxiosRequestConfig) {
        return WorkflowResourceApiFp(this.configuration).getWorkflowUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateWorkflow
     * @param {WorkflowResourceApiPartialUpdateWorkflowUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowResourceApi
     */
    public partialUpdateWorkflowUsingPATCH(requestParameters: WorkflowResourceApiPartialUpdateWorkflowUsingPATCHRequest, options?: AxiosRequestConfig) {
        return WorkflowResourceApiFp(this.configuration).partialUpdateWorkflowUsingPATCH(requestParameters.id, requestParameters.workflowDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateWorkflow
     * @param {WorkflowResourceApiUpdateWorkflowUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowResourceApi
     */
    public updateWorkflowUsingPUT(requestParameters: WorkflowResourceApiUpdateWorkflowUsingPUTRequest, options?: AxiosRequestConfig) {
        return WorkflowResourceApiFp(this.configuration).updateWorkflowUsingPUT(requestParameters.id, requestParameters.workflowDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


