import { UserAPIConfig } from "../config/user-api.config";
import { UserInfoDTO } from "../dtos/user-info.dto";
import { UserAPI } from "../http/user-service.httpclient";

export interface ChangePasswordPayload {
  currentPassword: string;
  newPassword: string;
}

export class UserInfoAPI {
  getUserInfos() {
    return UserAPI.get(UserAPIConfig.paths.userInfo);
  }

  updateUserInfo(userInfo: Partial<UserInfoDTO>) {
    return UserAPI.put(
      `${UserAPIConfig.paths.userInfo}/${userInfo.id}`,
      userInfo
    );
  }

  changePassword(payload: ChangePasswordPayload) {
    return UserAPI.post(
      `${UserAPIConfig.paths.userInfo}/change-password`,
      payload
    );
  }
}
