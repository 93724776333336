import { useMemo } from "react";
import { useDatasetDetailContext } from "../../../dataset-detail.context";
import { FileInfoPanel } from "./file-info-panel.component";
import { FileInfoTextPanel } from "./file-info-text-panel.component";


export const FileInfoSelect = () => {
  const { selectedFiles, files, hoveredFile, selecting } = useDatasetDetailContext();
  const file = useMemo(() => {
    if (selecting || selectedFiles.length === 0)
      return files.find((f) => f.id === hoveredFile?.id) || null;
    return files.find((f) => f.id === selectedFiles[0]) || null;
  }, [selectedFiles, files, selecting, hoveredFile]);


  const isTextFile = useMemo(() => {
    const textExtentions = [".txt", ".csv"];
    for (let extention of textExtentions){
      if (file?.fileName.toLowerCase().endsWith(extention)) return true;
    }
    return false;
  }, [file]);

  const getPannel = () => {
    if (!file) return null;
    if (isTextFile) return <FileInfoTextPanel file={file} />;
    return <FileInfoPanel file={file} />;
  }

  return (
    <>
      {getPannel()}
    </>
  )
}