/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 5th January 2022 11:00:13 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { ENV_CONFIG } from "configs/env.config";
import { setupAuthInterceptor } from "services/common/auth-interceptor";

let _axiosInstance: AxiosInstance | undefined = undefined;

function getAxiosInstance() {
  if (_axiosInstance) return _axiosInstance;
  _axiosInstance = setupAuthInterceptor(
    axios.create({
      baseURL: ENV_CONFIG.ANALYTICS_SERVICE_URL,
    })
  );
  return _axiosInstance;
}

export interface AnalyticsQueryResponse {
  data: {
    rows: any;
    columns: { name: string; alias: string; type: string }[] | null;
  };
}

export interface AnalyticsQueryParams {
  workspaceId: string;
  startDate: string;
  endDate: string;
  separator?: string;
  projectTypes?: string[];
}
export interface AnalyticsQueryPayload {
  queryName: string;
  params: AnalyticsQueryParams;
}

function getAnalyticsQuery(
  payload: AnalyticsQueryPayload,
  config: AxiosRequestConfig | undefined = undefined
): Promise<AxiosResponse<AnalyticsQueryResponse>> {
  return getAxiosInstance().post("/api/v1/analytics/query", payload, config);
}

export const AnalyticsService = {
  getAnalyticsQuery,
};
