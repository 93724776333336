/*
 * File: web-builder.constant.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 26th February 2022 10:38:09 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */
import { Option } from "domain/common";
import { MLModelType } from "domain/web-builder";

export const THEME_OPTIONS: Option[] = [
  { label: "Light", value: "light" },
  { label: "Dark", value: "dark" },
];

export const COLOR_OPTIONS: Option[] = [
  { label: "model-web-theme-1", value: "#3E7DF8" },
  { label: "model-web-theme-2", value: "#1CC3D6" },
  { label: "model-web-theme-3", value: "#FF5630" },
  { label: "model-web-theme-4", value: "#EA7E00" },
  { label: "model-web-theme-5", value: "#1AC774" },
];

export const MODEL_TYPE_OPTIONS: Option[] = [
  { label: "Image classification", value: MLModelType.IMAGE_CLASSIFICATION },
  { label: "Image segementation", value: MLModelType.IMAGE_SEGMENTATION },
  { label: "Object detection", value: MLModelType.OBJECT_DETECTION },
];

export const TEMPLATE_OPTIONS: Option[] = [
  { label: "Grid", value: "grid" },
  { label: "List", value: "list" },
];
