/*
 * File: text-workspace.state.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 14th October 2021 10:39:52 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { CORAnnotation, NERAnnotation } from "domain/text-labeling";
import { Label } from "domain/text-labeling";
import { Sentence } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { Point } from "utilities/math/point";

export interface SelectedTokens {
  tokenIds: string[];
  position: Point;
}

export interface TextWorkspaceState {
  isLoading?: boolean;
  isLoadingJob?: boolean;
  tokenizer: string;
  labels: Label[];
  sentences: Sentence[];
  labelEntities: Record<string, Label>;
  observations: NERAnnotation[];
  connections: CORAnnotation[];
  isShowAnnotationSources: boolean;
  setShowAnnotationSources(visible: boolean): void;
  handleConfirmExit(): void;
}

export const defaultTextWorkspaceState = {} as TextWorkspaceState;
