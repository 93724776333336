/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 29th July 2021 3:34:21 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

const AuthData = {
  token: "",
  workspace: "",
  scope: "",
};

function getAccessToken() {  
  return AuthData.token;
}

function setAccessToken(token: string = "") {
  AuthData.token = token;
}

function getUserWorkspace() {
  return AuthData.workspace;
}

function setUserWorkspace(workspace: string) {
  AuthData.workspace = workspace;
}

function getUserScope() {
  return AuthData.scope;
}

function setUserScope(scope: string) {
  AuthData.scope = scope;
}

export const AuthService = {
  setAccessToken,
  getAccessToken,
  setUserScope,
  getUserScope,
  setUserWorkspace,
  getUserWorkspace,
};
