import classNames from "classnames";
import { IconButton } from "@material-ui/core";
import { IconArrowRight } from "components/common/vb-icon.component";
import { LightCheckbox } from "components/common/vb-light-checkbox.component";
import { Eye, EyeSlash } from "iconsax-react";
import { useState } from "react";
import { classnames } from "utilities/classes";

export interface ITreeItem {
  id: string;
  text: string;
  children: ITreeItem[];
  uuid: string;
  hidden?: boolean;
  locked?: boolean;
  selected?: boolean;
  checked?: boolean;
  depth?: number;
}

interface Props {
  item: ITreeItem;
  onSelect(item: ITreeItem, action?: string): void;
}
export function TreeItem({ item, onSelect }: Props) {
  const hasChildren = item.children.length > 0;
  const [open, setOpen] = useState(true);

  function onClickToggleVisibility() {
    onSelect(item, "TOGGLE_ITEM_VISIBILITY");
  }

  function onClickToggleChecked() {
    onSelect(item, "TOGGLE_ITEM_CHECKED");
  }

  return (
    <div>
      <div
        className={classNames("flex items-center gap-1 ", {
          "bg-blueGray-900": item.selected,
          "hover:bg-blueGray-800": !item.selected,
        })}
      >
        <span style={{ width: (item.depth ?? 0) * 16 }} />
        {hasChildren && (
          <IconButton onClick={() => setOpen(!open)}>
            <IconArrowRight
              className={classnames("w-3.5 h-3.5 text-white", {
                "transform rotate-90": open,
              })}
            />
          </IconButton>
        )}
        {!hasChildren && (
          <LightCheckbox
            checked={!!item.checked}
            onClick={onClickToggleChecked}
          />
        )}
        <div className="flex-auto whitespace-nowrap truncate">{item.text}</div>
        {item.depth !== 0 && (
          <IconButton onClick={onClickToggleVisibility}>
            {item.hidden && <EyeSlash size={18} color="#fff" />}
            {!item.hidden && <Eye size={18} color="#fff" />}
          </IconButton>
        )}
      </div>

      {open && hasChildren && (
        <div>
          {item.children.map((child) => {
            return <TreeItem item={child} key={child.id} onSelect={onSelect} />;
          })}
        </div>
      )}
    </div>
  );
}
