/*
 * File: batch-export.page.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 4:52:13 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBSectionMessage } from "components/common/vb-section-message/vb-section-message.component";
import { VBTabs } from "components/common/vb-tabs/vb-tabs.component";
import { useDatasetScope } from "hooks/scope/use-dataset-scope.hook";
import { useScope } from "hooks/scope/use-scope.hook";
import { useDetectChange } from "hooks/use-detect-change";
import { useAppDispatch } from "hooks/use-redux";
import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { DatasetDetailProvider } from "pages/customer/datasets/dataset-detail/dataset-detail.context";
import { useEffect } from "react";
import { useState } from "react";
import { BatchService } from "services/label-service";
import { RequestStatus } from "store/base/base.state";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { AutoSplitModal, ScopePercentage } from "./components/auto-split.modal";
import { DatasetModels } from "./components/dataset-models.component";
import { ExportDataset } from "./components/export-dataset.component";
import { ExportDatasetModal } from "./components/export-dataset.modal";
import { useDatasetFilesCount } from "hooks/datasets/use-dataset-files-count.hook";
import { ExportDatasetPayload } from "services/label-service/apis/batch.api";
import { ExportActions } from "./components/export-actions.component";
import { useBatchDetailContext } from "../../context/batch-detail.context";
import { BatchStatus } from "services/label-service/dtos";
import { useDownloadDatasetContext } from "contexts/download-dataset/download-dataset.context";
import { AnnotationGenerationStatus } from "./components/annotation-generation-status";
import { loadBatchGenAnnoAsyncJobStatusAsync } from "store/customer/batch/batch.thunk";
import * as Sentry from "@sentry/react";

export const BatchExportPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [exportDataset, setExportDataset] = useState(false);
  const [autoSplit, setAutoSplit] = useState(false);
  const { scopeTabs, scopes } = useScope();
  const [activeTab, setActiveTab] = useState("1");
  const { batch } = useBatchDetailContext();
  const { downloadingBatch } = useDownloadDatasetContext();
  const { setScope, requestData } = useDatasetContext();
  const { scopeFiles, requestData: requestScopeFiles } = useDatasetScope(
    batch?.datasetId || -1
  );
  const { totalFiles } = useDatasetFilesCount(batch?.datasetId || -1);
  const activeTabChanged = useDetectChange([activeTab]);
  const [autoSplitStatus, setAutoSplitStatus] = useState(RequestStatus.IDLE);
  const [autoSplitError, setAutoSplitError] = useState("");
  const [exportStatus, setExportStatus] = useState(RequestStatus.IDLE);

  function onMovedFiles() {
    requestData();
    requestScopeFiles(batch?.datasetId || -1);
  }

  async function handleExportDataset(payload: ExportDatasetPayload) {
    if (!batch) return;
    try {
      setExportStatus(RequestStatus.LOADING);
      const response = await BatchService.createExportedDataset(
        batch.id,
        payload
      );
      handleThunkRejected(response);
      setExportDataset(false);
      setExportStatus(RequestStatus.SUCCESS);
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || t("common:textFailed");
      dispatch(enqueueErrorNotification(errMessage));
      setExportStatus(RequestStatus.FAILURE);
    }
  }

  async function handleAutoSplit(scopes: ScopePercentage[]) {
    if (!batch) return;
    try {
      setAutoSplit(false);
      setAutoSplitStatus(RequestStatus.LOADING);
      setAutoSplitError("");
      const distributions: Record<string, number> = {};
      for (const scope of scopes) {
        const key = `${scope.id}`;
        distributions[key] = scope.percentage / 100;
      }
      const response = await BatchService.distributeDataset(batch.id, {
        distributions,
      });
      handleThunkRejected(response);
      await requestScopeFiles(batch?.datasetId || -1);
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      setAutoSplitStatus(RequestStatus.SUCCESS);
      requestData();
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || t("common:textFailed");
      dispatch(enqueueErrorNotification(errMessage));
      setAutoSplitStatus(RequestStatus.FAILURE);
      setAutoSplitError(errMessage);
    }
  }

  useEffect(() => {
    if (activeTabChanged) setScope(activeTab);
  }, [activeTabChanged, activeTab, setScope]);

  useEffect(() => {
    dispatch(loadBatchGenAnnoAsyncJobStatusAsync(batch.id));
  }, [batch, dispatch]);

  return (
    <DatasetDetailProvider>
      {batch && (
        <ExportActions
          batchStatus={batch.status as BatchStatus}
          onAutoSplit={() => setAutoSplit(true)}
          onExportDataset={() => setExportDataset(true)}
          disableDownload={!!downloadingBatch}
          hideDownload
          hideGenerateAnnotation
        />
      )}

      <AnnotationGenerationStatus status={RequestStatus.IDLE} />

      <div className="mt-4">
        {autoSplitStatus === RequestStatus.LOADING && (
          <VBSectionMessage
            appearance="info"
            title={t("project:batchDetails.batchexport.loading.title")}
            description={t(
              "project:batchDetails.batchexport.loading.description"
            )}
          />
        )}
        {autoSplitStatus === RequestStatus.SUCCESS && (
          <VBSectionMessage
            appearance="success"
            title={t("project:batchDetails.batchexport.success.title")}
            description={
              t("project:batchDetails.batchexport.success.description") +
              " " +
              scopes
                .map(
                  (scope) =>
                    `${scope.name}: ${
                      scopeFiles.hasOwnProperty(scope.id)
                        ? scopeFiles[scope.id]
                        : 0
                    }`
                )
                .join(", ")
            }
            actions={[
              {
                text: t("common:textDismiss"),
                onClick: () => {
                  setAutoSplitStatus(RequestStatus.IDLE);
                  setAutoSplitError("");
                },
              },
            ]}
          />
        )}
        {autoSplitStatus === RequestStatus.FAILURE && (
          <VBSectionMessage
            appearance="error"
            title={t("common:textFailed")}
            description={autoSplitError}
            actions={[
              {
                text: t("common:textDismiss"),
                onClick: () => {
                  setAutoSplitStatus(RequestStatus.IDLE);
                  setAutoSplitError("");
                },
              },
            ]}
          />
        )}
      </div>

      <DatasetModels scopes={scopes} scopeFiles={scopeFiles}>
        <span className="px-2 text-gray-400">
          ({t("project:batchDetails.batchexport.textManualAssignment")})
        </span>
      </DatasetModels>
      <VBTabs tabs={scopeTabs} activeTab={activeTab} onSelect={setActiveTab} />

      <ExportDataset onMovedFiles={onMovedFiles} />

      {autoSplit && (
        <AutoSplitModal
          total={totalFiles}
          onClose={() => setAutoSplit(false)}
          onSubmit={handleAutoSplit}
        />
      )}

      {exportDataset && (
        <ExportDatasetModal
          datasetId={batch?.datasetId.toString() || ""}
          scopes={scopes}
          scopeFiles={scopeFiles}
          requestStatus={exportStatus}
          onClose={() => setExportDataset(false)}
          onSubmit={handleExportDataset}
        />
      )}
    </DatasetDetailProvider>
  );
};
