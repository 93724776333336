import { IconTrash } from "components/common/vb-icon.component";
import { useState } from "react";
import { classnames } from "utilities/classes";



interface Props{
  rows: string[];
  onClickDelete?: (row: string) => void;
  containerClass?: string;
}
export const LabelersList = ({
  rows,
  onClickDelete,
  containerClass = "",
}: Props) => {
  const [hoveringRow, setHoveringRow] = useState<string>();

  if (rows.length <= 0) return null;

  return (
    <div
      className={classnames(
        containerClass,
        "flex flex-col max-h-60 border rounded overflow-y-auto"
      )}
      onMouseOut={_ => setHoveringRow(undefined)}
    >
      {
        rows.map((row, index) => {
          const isLast = index === rows.length - 1;
          return (
            <div
              key={row}
              className={classnames(
                "flex items-center justify-between px-2 py-2 truncate",
                {"border-b": !isLast}
              )}
              onMouseOver={_ => setHoveringRow(row)}
            >
              <span>{row}</span>
              {
                <IconTrash
                  className={classnames(
                    "right-2 w-4 h-4 text-error-300 cursor-pointer opacity-0",
                    {"opacity-100" : hoveringRow === row}
                  )}
                  onClick={_ => onClickDelete && onClickDelete(row)}
                />
              }
            </div>
          )
        })
      }
    </div>
  )
}