/*
 * File: use-labeler-tasks-filter.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 22nd May 2023 2:17:00 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ILabelerTaskFilter } from "../types";
import { SearchParams } from "models/common/search-params";

const DEFAULT_PAGE = 0;
const DEFAULT_SIZE = 10;

export function useLabelerTasksFilter() {
  const location = useLocation();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const filter = useMemo<ILabelerTaskFilter>(() => {
    const urlSearchParams = new URLSearchParams(location.search ?? "");
    function getQueryParam(key: string, defaultValue: string) {
      const value = urlSearchParams.get(key);
      return value ?? defaultValue;
    }
    function parseSort(sortStr?: string) {
      if (sortStr) {
        const values = sortStr.split(":");
        if (values && values.length === 2) {
          return {
            field: values[0],
            order: values[1],
          };
        }
      }
      return undefined;
    }
    function getQueryFilters() {
      const queries = [];
      urlSearchParams.values();
      const keys = Array.from(urlSearchParams.keys());
      for (const key of keys) {
        if (!key.startsWith("filter.")) continue;
        const items = key.split(".");
        if (items && items.length === 2) {
          queries.push({
            field: items[1],
            operation: "$eq",
            value: getQueryParam(key, ""),
          });
        } else if (items && items.length === 3) {
          queries.push({
            field: items[1],
            operation: items[2],
            value: getQueryParam(key, ""),
          });
        }
      }
      return queries;
    }

    const page = getQueryParam(SearchParams.PAGE, DEFAULT_PAGE.toString());
    const size = getQueryParam(SearchParams.PAGE_SIZE, DEFAULT_SIZE.toString());
    const search = getQueryParam(SearchParams.SEARCH, "");
    const sortStr = getQueryParam(SearchParams.SORT, "");
    const queryFilters = getQueryFilters();

    return {
      page: parseInt(page),
      size: parseInt(size),
      search,
      sort: parseSort(sortStr),
      queries: queryFilters,
      projectId,
    };
  }, [location.search, projectId]);

  function updateFilter(value: Partial<ILabelerTaskFilter>) {
    const newFilter: ILabelerTaskFilter = {
      ...filter,
      ...value,
    };

    const searchParams = new URLSearchParams();
    if (newFilter.page !== DEFAULT_PAGE) {
      searchParams.set(SearchParams.PAGE, newFilter.page.toString());
    }
    if (newFilter.page !== DEFAULT_SIZE) {
      searchParams.set(SearchParams.PAGE_SIZE, newFilter.size.toString());
    }
    if (newFilter.search) {
      searchParams.set(SearchParams.SEARCH, newFilter.search);
    }
    if (newFilter.sort) {
      searchParams.set(
        SearchParams.SORT,
        `${newFilter.sort.field}:${newFilter.sort.order}`
      );
    }
    if (newFilter.queries) {
      for (const query of newFilter.queries) {
        if (!query.value) continue;
        const key = `filter.${query.field}.${query.operator ?? "$eq"}`;
        searchParams.set(key, query.value);
      }
    }

    const newLocation = {
      ...location,
      search: searchParams.toString(),
      hash: "",
    };
    history.push(newLocation);
  }

  function setFilterParams(
    items: { query: string; value: string }[],
    exact = false
  ) {
    const searchParams = exact ? "" : location.search;
    const urlSearchParams = new URLSearchParams(searchParams);

    for (const pair of items) {
      const { query, value } = pair;
      if (typeof value === "object" && Array.isArray(value)) {
        urlSearchParams.delete(query);
        const arrStr: string[] = value;
        for (const val of arrStr) {
          urlSearchParams.append(query, val);
        }
        continue;
      }

      if (value) {
        if (urlSearchParams.has(query)) {
          urlSearchParams.set(query, value);
        } else {
          urlSearchParams.append(query, value);
        }
      } else {
        urlSearchParams.delete(query);
      }
    }

    const newLocation = {
      ...location,
      search: urlSearchParams.toString(),
      hash: "",
    };
    history.push(newLocation);
  }

  function setFilterParam(
    key: string,
    value: string,
    exact = false,
    addIfExists = false
  ) {
    const searchParams = exact ? "" : location.search;
    const urlSearchParams = new URLSearchParams(searchParams);
    if (value) {
      if (addIfExists) {
        urlSearchParams.append(key, value);
      } else {
        urlSearchParams.set(key, value);
      }
    } else {
      urlSearchParams.delete(key);
    }
    const newLocation = { ...location, search: urlSearchParams.toString() };
    history.push(newLocation);
  }
  return { filter, updateFilter, setFilterParam, setFilterParams };
}
