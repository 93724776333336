/*
 * File: vb-user-avatar.component.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 31st July 2021 1:53:12 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useMemo } from "react";
import { normalizeVietnamese } from "utilities/string/string.normalize";

const COLORS = [
  "#00AA55",
  "#009FD4",
  "#B381B3",
  "#939393",
  "#E3BC00",
  "#D47500",
  "#DC2A2A",
];

export const UserAvatar = ({
  name,
  className = "w-16 h-16 text-xl",
}: {
  name: string;
  className?: string;
}) => {
  function numberFromText(text: string) {
    const charCodes = text
      .split("")
      .map((char) => char.charCodeAt(0))
      .join("");
    return parseInt(charCodes, 10);
  }
  const color = useMemo(() => {
    if (!name || name.length === 0) return COLORS[0];
    return COLORS[numberFromText(name) % COLORS.length];
  }, [name]);

  const shortName = useMemo(() => {
    if (!name || name.length === 0) return "";
    const normalizeStr = normalizeVietnamese(name);
    if (!normalizeStr) return "NA";
    const letters =
      normalizeStr
        .split(" ")
        .map((s) => {
          if (s && s.length > 0) return s.charAt(0);
          return "";
        })
        .join("") + normalizeStr.replace(normalizeStr.charAt(0), "");
    return letters.substr(0, 2).toUpperCase();
  }, [name]);

  return (
    <div
      className={`flex items-center justify-center text-white rounded-full ${className}`}
      style={{ backgroundColor: color }}
    >
      {shortName}
    </div>
  );
};
