import { TicketSupportForm } from "components/ticket-management/ticket-support-form.component";
import React from "react";
import * as Sentry from "@sentry/react";

export class ErrorBoundary extends React.Component {
  state = { hasError: false, values: {} };

  componentDidCatch(error: unknown) {
    // report the error to your favorite Error Tracking tool (ex: Sentry, Bugsnag)
    Sentry.captureException(error);
    console.error(error);
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  handleSendReport = () => {
    if (this.state.hasError) {
      console.log(this.state.values)
    }
  }
  render() {
    if (this.state.hasError) {
      return (
        <section className="w-1/2 p-10 mx-auto space-y-12">
          <TicketSupportForm
            values={this.state.values}
            setValues={(values) => this.setState({ values })}
          />
          <section className="flex justify-center gap-4">
            <a href="/" className="px-4 py-2 text-gray-500 border-2 rounded cursor-pointer">
              Back to Home
            </a>
            <button className="px-4 py-2 text-white bg-blue-500 rounded" onClick={this.handleSendReport}>
              Send Report
            </button>
          </section>
        </section>
      );
    }

    return this.props.children;
  }
}
