/*
 * File: load-text-job.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 11th July 2022 11:03:52 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { TextFile } from "domain/labeling/file";
import { TextLabelingData } from "domain/labeling/labeling-data";
import { RootState } from "store";
import { RequestStatus } from "store/base/base.state";
import { Sentence } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { textUtils } from "store/labeler/text-workspace/utils/text-labeling.utils";
import {
  LabelingBatchState,
  WorkingStatus,
} from "../text-labeling-batch.state";

const THUNK_NAME = "textLabelingBatch/textLoadJobAsync";

export const textLoadJobAsync = createAsyncThunk(
  THUNK_NAME,
  async ({ jobId }: { jobId: number }, { getState }) => {
    const state = getState() as RootState;
    const { jobs, jobLoadingStatus } = state.textLabelingBatch;
    const isValidJob = jobs.allIds.includes(jobId);
    if (!isValidJob) throw new Error("Invalid Job");
    const labelingJob = jobs.entities[jobId];
    const isLoaded = RequestStatus.SUCCESS === jobLoadingStatus[jobId];
    const hasLabelingData = !!labelingJob.data;
    if (isLoaded || hasLabelingData) return;

    const { job, file, labels } = labelingJob;
    let sentences: Sentence[] = (file as TextFile).sentences;
    const hasSentences = sentences?.length > 0;
    if (!hasSentences) {
      sentences = await textUtils.getSentencesFromFile(file.id);
    }
    const labelingData = await textUtils.labelerGetTextAnnotationsByJob(
      job,
      file,
      labels
    );
    return { labelingData, sentences };
  }
);

export function textLoadJobBuilder(
  builder: ActionReducerMapBuilder<LabelingBatchState>
) {
  return builder
    .addCase(textLoadJobAsync.pending, (state, action) => {
      const jobId = action.meta.arg.jobId;
      state.jobLoadingStatus[jobId] = RequestStatus.LOADING;
    })
    .addCase(textLoadJobAsync.fulfilled, (state, action) => {
      const jobId = action.meta.arg.jobId;
      state.selectedJobId = jobId;
      state.jobLoadingStatus[jobId] = RequestStatus.SUCCESS;
      if (!action.payload) return;
      const { labelingData, sentences } = action.payload;
      const labelingJob = state.jobs.entities[jobId];
      labelingJob.data = labelingData;
      (labelingJob.file as TextFile).sentences = sentences;
      const textLabelingData = labelingData.jobData as TextLabelingData;
      if (textLabelingData?.annotations?.length > 0) {
        state.jobWorkingStatus[jobId] = WorkingStatus.SAVED;
      }
    })
    .addCase(textLoadJobAsync.rejected, (state, action) => {
      const jobId = action.meta.arg.jobId;
      state.jobLoadingStatus[jobId] = RequestStatus.FAILURE;
    });
}
