/*
 * File: file-thumbnail.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th June 2023 3:25:41 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */
import classNames from "classnames";
import { QueryApi } from "data-access/impl/query";
import { EntityAction } from "domain/common/entity-action";
import { useMemo } from "react";
import { VscFile } from "react-icons/vsc";

interface Props {
  file: QueryApi.IFile;
  selected?: boolean;
  onSelect?(fileId: number, action: string): void;
}
export function FileThumbnail({ file, selected, onSelect }: Props) {
  const thumbnailUrl = useMemo(() => {
    return file.additionalFileInfo?.addition?.thumbnailUrl ?? "";
  }, [file]);

  const extension = useMemo(() => {
    return file.additionalFileInfo?.extend ?? "";
  }, [file]);

  return (
    <div
      id={`file-thumbnail-${file.id}`}
      data-test-id={`file-thumbnail-${file.id}`}
      onClick={() => {
        onSelect && onSelect(file.id, EntityAction.SELECT);
      }}
      className={classNames(
        "aspect-w-4 aspect-h-4 bg-warmGray-100 cursor-pointer",
        { "ring ring-primary": selected }
      )}
    >
      {thumbnailUrl && (
        <img
          alt="thumbnail"
          src={thumbnailUrl}
          className="object-contain max-w-full max-h-full"
        />
      )}
      {extension === "txt" && (
        <div className="flex items-center justify-center w-full h-full">
          <VscFile size={32} />
        </div>
      )}
    </div>
  );
}
