/*
 * File: dicom-editor.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";
import { annotateTypeMapper } from "constants/annotation.constant";
import { PollStrategy } from "services/label-service/dtos";
import { RootState } from "store";
import {
  selectBatchObservations,
  selectCurrentObservation,
} from "../image-labeling/image-labeling.selectors";

export const selectActiveCornerstoneTool = (state: RootState) => {
  return state.dicomEditor.activeTool;
};

export const selectAnnotationTools = (state: RootState) => {
  const readonly = state.imageWorkspace.imageLabeling.readonly;
  const observations = selectBatchObservations(state);
  const tools = observations
    .map((observation) => annotateTypeMapper(observation.annotateType))
    .filter((tool) => !!tool);
  const observation = selectCurrentObservation(state);
  const availableTools = state.dicomEditor.annotateTools.filter(
    (annotateTool) => {
      if(annotateTool.type === ToolName.SAM) return true;
      return tools.find((tool) => tool === annotateTool.type);
    }
  );
  if (
    availableTools.find(
      (tool) =>
        tool.type === ToolName.RectangleRoiExtend ||
        tool.type === ToolName.FreehandRoiExtend
    )
  ) {
    const arrowTool = state.dicomEditor.annotateTools.find(
      (tool) => tool.type === ToolName.MultiArrowConnection
    );
    if (arrowTool) availableTools.push(arrowTool);
  }
  return availableTools.map((tool) => {
    let enable =
      tool.type === ToolName.MultiArrowConnection ||
      tool.type === ToolName.SAM ||
      (observation &&
        tool.type === annotateTypeMapper(observation.annotateType));
    return {
      ...tool,
      disabled: readonly || !enable,
    };
  });
};

export const selectActiveAnnotationTool = (state: RootState) => {
  const tools = selectAnnotationTools(state);
  return tools.find((tool) => tool.type === state.dicomEditor.activeTool);
};

export const selectAnnotationToolByName =
  (tool: ToolName) => (state: RootState) => {
    return state.dicomEditor.annotateTools.find(
      (annotateTool) => annotateTool.type === tool
    );
  };

export const selectBaseTools = (state: RootState) => {
  return state.dicomEditor.baseTools
    .filter((tool) => {
      if (tool.name === ToolName.Synchronizer) {
        return PollStrategy.GROUP === state.batchLabeling.pollStrategy;
      }
      return true;
    })
    .map((t) => {
      if (t.type === ToolName.Synchronizer) {
        return { ...t, active: state.cornerstone.synchronize };
      }
      if (t.type === ToolName.HeartRuler) {
        return { ...t, active: state.cornerstone.heartRuler };
      }
      return t;
    });
};

export const selectResetTools = (state: RootState) => {
  return state.dicomEditor.resetTools;
};

export const selectOtherTools = (state: RootState) => {
  return state.dicomEditor.otherTools;
};

export const selectFreehandStrategy = (state: RootState) => {
  return state.dicomEditor.freehandStrategy;
};
