import { Backdrop, CircularProgress } from "@material-ui/core";
import { IconCloseCircle } from "components/common/vb-icon.component";
import initCornerstone from "components/dicom/cornerstone/cornerstone.config";
import { CornerstoneTool } from "components/dicom/dicom-tools/dicom-tools.model";
import { ImageViewer } from "components/dicom/image-viewer/image-viewer";
import { MatModal } from "components/material/mat-modal.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BatchDTO,
  BatchObservationDTO,
  TaskDTO,
} from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import {
  selectLoadTasksReviewRequestStatus,
  selectTasksReviewAnnotations,
  selectTasksReviewBatchObservations,
  selectTasksReviewFile,
  selectTasksReviewJobOptions,
  selectTasksReviewMetadata,
  selectTasksReviewRelationAnnotations,
} from "store/customer/tasks-review/tasks-review.selectors";
import { resetTasksReview } from "store/customer/tasks-review/tasks-review.slice";
import { loadTasksReviewDataAsync } from "store/customer/tasks-review/tasks-review.thunk";
import { JobInfo } from "./job-info.component";
import { TasksReviewCornerstoneHandler } from "./tasks-review-cornerstone-handler";
import { TasksReviewToolComponent } from "./tasks-review-tool.component";
import { AnnotationToolbar } from "./annotation-toolbar/annotation-toolbar.component";
import { useAnnotationsDisplay } from "../hooks/use-annotation-display.hook";
import { IssueManagementProvider } from "./issue/issue-management.provider";
import { TaskReviewReopenButtons } from "./task-review-reopen-buttons.component";
import { AddLabelDefinitionDialog } from "./add-label-definition.dialog";
import { TaskReviewActionPanel } from "./task-review-action-panel/index";
import { taskReviewIouAnnotationsLoadedAsync } from "store/customer/tasks-review/task-review-iou/task-review-iou.thunk";
import ImageStacksIndicator from "components/dicom/image-stacks-indicator/image-stacks-indicator";
import { useImageStacks } from "components/dicom/image-stacks-indicator/use-image-stacks";
import { CornerstoneToolsEvents } from "components/dicom/cornerstone/models/cornerstone-tools-events.model";

initCornerstone();

interface TasksReviewComponentProps {
  visible: boolean;
  task: TaskDTO;
  batch: BatchDTO;
  batchObservations?: BatchObservationDTO[];
  jobIdsIncludedOnly?: number[];
  onClose?: () => void;
  onNext?: () => void;
  title?: string;
  showReopenButtons?: boolean;
  showIssues?: boolean;
}
export const TasksReviewComponent = ({
  visible,
  task,
  batch,
  batchObservations = [],
  jobIdsIncludedOnly,
  onClose,
  onNext,
  title,
  showReopenButtons,
  showIssues = true,
}: TasksReviewComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(selectLoadTasksReviewRequestStatus);
  const reviewingFile = useAppSelector(selectTasksReviewFile);
  const jobOptions = useAppSelector(selectTasksReviewJobOptions);
  const taskReviewBatchObservations = useAppSelector(
    selectTasksReviewBatchObservations
  );
  const annotations = useAppSelector(selectTasksReviewAnnotations);
  const relationAnnotations = useAppSelector(
    selectTasksReviewRelationAnnotations
  );
  const metadata = useAppSelector(selectTasksReviewMetadata);

  const elementRef = useRef<HTMLDivElement>(null);
  const nextButtonRef = useRef<any>();
  const handler = useRef<TasksReviewCornerstoneHandler>();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const isImageLoadedRef = useRef(false);
  useAnnotationsDisplay(handler.current);

  const [showAddLabelDefinition, setShowAddLabelDefinition] = useState(false);
  const [annoVisibleMap, setAnnoVisibleMap] = useState<Record<number, boolean>>(
    {}
  );

  const {
    isMultipleFrames,
    frame,
    numFrames,
    onScroll,
    onChange: onChangeFrame,
    onNext: onNextFrame,
    onPrevious: onPreviousFrame,
  } = useImageStacks(metadata?.numFrames, handler);

  const taskInfo = useMemo(() => {
    const taskId = task.id;
    const batchId = batch.id;
    const projectId = batch.project.id;
    return { taskId, batchId, projectId };
  }, [task, batch]);

  useEffect(() => {
    if (!isImageLoadedRef.current || !handler.current) return;
    handler.current.updateVisibleFromAnnotations(annotations);
  }, [annotations]);

  useEffect(() => {
    if (!isImageLoadedRef.current || !handler.current) return;
    handler.current.updateVisibleFromRelationAnnotations(relationAnnotations);
  }, [relationAnnotations]);

  const onStackScroll = async (event: any) => {
    onScroll(event.detail.newImageIdIndex);
  };

  function handleImageLoaded(imageId?: string) {
    handler.current = new TasksReviewCornerstoneHandler(elementRef.current);
    handler.current.setImageId(imageId);
    handler.current.init({
      file: reviewingFile,
      numFrames: metadata?.numFrames,
    });
    handler.current.importAnnotations(annotations, relationAnnotations);
    setIsImageLoaded(true);
    isImageLoadedRef.current = true;
    elementRef.current?.addEventListener(
      CornerstoneToolsEvents.STACK_SCROLL,
      onStackScroll
    );
  }

  function handleImageLoadError(error: any) {}

  function haldeClickAddToLabelDefinition() {
    setAnnoVisibleMap(handler.current?.getAnnotationVisibleMap() || {});
    handler.current?.setAllAnnotationVisible(false);
    setShowAddLabelDefinition(true);
  }

  function handleSubmitAddToLabelDefinition() {
    setShowAddLabelDefinition(false);
  }

  function handleCloseAddToLabelDefinition() {
    setShowAddLabelDefinition(false);
  }

  function handleToolSelect(tool: CornerstoneTool) {
    if (tool.active) {
      handler.current?.setToolActive(tool.type);
    } else {
      handler.current?.setToolDisabled(tool.type);
    }
  }

  function handleClose() {
    handler.current?.dispose();
    isImageLoadedRef.current = false;
    dispatch(resetTasksReview());
    onClose && onClose();
  }

  function handleNext() {
    if (requestStatus === RequestStatus.IDLE) return;
    setIsImageLoaded(false);
    handler.current?.dispose();
    isImageLoadedRef.current = false;
    dispatch(resetTasksReview());
    onNext && onNext();
  }

  useEffect(() => {
    if (requestStatus !== RequestStatus.IDLE) return;
    const payload = {
      task,
      batchObservations,
      jobIdsIncludedOnly,
    };
    async function loadDataAsync() {
      await dispatch(taskReviewIouAnnotationsLoadedAsync({ taskId: task.id }));
      dispatch(loadTasksReviewDataAsync(payload));
    }
    loadDataAsync();
  }, [dispatch, task, requestStatus, batchObservations, jobIdsIncludedOnly]);

  return (
    <>
      <MatModal
        disableBackdropClick
        open={visible}
        closeAfterTransition
        BackdropComponent={Backdrop}
        onClose={() => handleClose && handleClose()}
      >
        <div className="relative flex items-center justify-center w-full h-full p-10">
          <div
            className="relative w-full h-full overflow-hidden bg-white rounded"
            style={{ maxWidth: "76rem", maxHeight: "90vh" }}
          >
            <div className="flex flex-col w-full h-full px-6 overflow-y-auto">
              <div className="flex items-center justify-between flex-none w-full my-6">
                <h1 className="text-xl font-bold text-primary">
                  {title ? title : t("project:taskReview.title")}
                </h1>
                <button onClick={handleClose}>
                  <IconCloseCircle className="w-6 h-6 text-background-900" />
                </button>
              </div>
              <div className="flex items-start flex-auto w-full gap-4 h-3/4">
                <div className="relative flex-auto h-full overflow-hidden rounded">
                  <div className="relative w-full h-full">
                    {reviewingFile && (
                      <ImageViewer
                        file={reviewingFile}
                        ref={elementRef}
                        onLoaded={handleImageLoaded}
                        onError={handleImageLoadError}
                      />
                    )}
                    {isImageLoaded && showIssues && (
                      <IssueManagementProvider
                        frameId={frame}
                        taskId={task.id}
                        jobOptions={jobOptions}
                        cornerstoneElement={elementRef.current}
                      />
                    )}
                    <div className="absolute bottom-0 left-0 right-0 z-10 w-full">
                      <AnnotationToolbar />
                    </div>

                    {isImageLoaded && isMultipleFrames && (
                      <ImageStacksIndicator
                        frame={frame}
                        numFrames={numFrames}
                        onChange={onChangeFrame}
                        onNext={onNextFrame}
                        onPrevious={onPreviousFrame}
                      />
                    )}
                  </div>
                  <TasksReviewToolComponent onSelect={handleToolSelect} />
                  {taskInfo && <JobInfo {...taskInfo} />}
                </div>

                <div className="flex-none h-full overflow-y-auto border-2 rounded-md text-background-700 w-60 lg:w-80">
                  {/* <TasksReviewPanel
                    onAssigneeChange={handleAssigneeChanged}
                    taskId={task.id}
                    taskReviewBatchObservations={taskReviewBatchObservations}
                    jobs={jobOptions}
                    onTaskReviewBatchObservationSelected={
                      handleTaskReviewBatchObservationSelected
                    }
                  /> */}
                  {annotations && annotations.length > 0 && (
                    <TaskReviewActionPanel
                      taskId={task.id}
                      jobs={jobOptions}
                      annotations={annotations}
                    />
                  )}
                </div>
              </div>
              <div className="z-10 flex items-center flex-none w-full gap-4 my-6">
                {showReopenButtons && (
                  <TaskReviewReopenButtons
                    batch={batch}
                    task={task}
                    jobs={jobOptions}
                    onApproved={onClose}
                    onRejected={onClose}
                    onClickAddToLabelDefinition={haldeClickAddToLabelDefinition}
                  />
                )}

                {!showReopenButtons && <div className="flex-1"></div>}

                <button
                  ref={nextButtonRef}
                  onClick={handleNext}
                  disabled={!isImageLoaded}
                  className="button-text-primary disabled:opacity-60"
                >
                  <span>{t("common:buttonNext")}</span>
                </button>
              </div>
            </div>
            {(requestStatus === RequestStatus.LOADING || !isImageLoaded) && (
              <div className="absolute top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full bg-white">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </MatModal>
      {handler.current && (
        <AddLabelDefinitionDialog
          taskReviewBatchObservations={taskReviewBatchObservations}
          handler={handler.current}
          open={showAddLabelDefinition}
          handleClose={handleCloseAddToLabelDefinition}
          handleSubmit={handleSubmitAddToLabelDefinition}
          originalAnnoVisibles={annoVisibleMap}
        />
      )}
    </>
  );
};
