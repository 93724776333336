/*
 * File: vb-workspace-avatar.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 2nd August 2021 11:27:32 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { useMemo } from "react";

const COLORS = [
  "#00AA55",
  "#009FD4",
  "#B381B3",
  "#939393",
  "#E3BC00",
  "#D47500",
  "#DC2A2A",
];

export const WorkspaceAvatar = ({
  name,
  className = "w-10 h-10",
}: {
  name: string;
  className?: string;
}) => {
  function numberFromText(text: string) {
    const charCodes = text
      .split("")
      .map((char) => char.charCodeAt(0))
      .join("");
    return parseInt(charCodes, 10);
  }
  const color = useMemo(() => {
    return COLORS[numberFromText(name) % COLORS.length];
  }, [name]);

  return (
    <div
      className={`flex items-center justify-center text-white rounded-lg ${className}`}
      style={{ backgroundColor: color }}
    >
      {name.substr(0, 1).toUpperCase()}
    </div>
  );
};
