import { useClickAway } from "ahooks";
import { VBCheckbox } from "components/design-system/checkbox/checkbox.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useCallback, useMemo, useRef } from "react";
import { StepType } from "services/label-service/dtos";
import { useStepConfigReview } from "../../hooks/use-step-config-review.hook";
import { useStepAssigness } from "../../hooks/use-step-assignees.hook";
import { StepAssignee, StepData, StepReviewConfig } from "../../project.type";
import { ProjectWorkflowAssignLabeler } from "./project-workflow-assign-labeler.component";
import { ProjectWorkflowReviewConfig } from "./project-workflow-review-config.component";
import { ProjectWorkflowStepIcon } from "./project-workflow-step-icon.component";
import { VBRichTextEditor } from "components/common/vb-rich-text/vb-rich-text.component";
import { useStepConditions } from "hooks/workflow/use-step-conditions.hook";
import { useTranslation } from "react-i18next";

interface ProjectWorkflowStepEditProps {
  disabled?: boolean;
  selectedStep: StepData;
  onChange?: (step: Partial<StepData>) => void;
}

export const ProjectWorkflowStepEdit = ({
  disabled = false,
  selectedStep,
  onChange,
}: ProjectWorkflowStepEditProps) => {
  const { t } = useTranslation();
  const numberOfRoundsInputRef = useRef<HTMLInputElement>(null);
  const scoreThresholdRef = useRef<HTMLInputElement>(null);
  const { stepConditionOptions } = useStepConditions(true);
  const { config: reviewConfig, onChange: onChangeReviewConfig } =
    useStepConfigReview(selectedStep);
  const { allEmails, selectedEmails } = useStepAssigness(selectedStep);
  useClickAway(() => {
    if (!numberOfRoundsInputRef) return;
    const value = numberOfRoundsInputRef.current?.value;
    if (!value || Number(value) <= 0) {
      onChange && onChange({ numberOfRounds: 1 });
    }
  }, numberOfRoundsInputRef);
  useClickAway(() => {
    if (!scoreThresholdRef) return;
    const value = scoreThresholdRef.current?.value;
    if (!value || Number(value) < 0 || Number(value) > 1) {
      onChange && onChange({ scoreThreshold: 0 });
    }
  }, scoreThresholdRef);

  const isFirstStep = useMemo(() => {
    return selectedStep.position === 1;
  }, [selectedStep?.position]);

  const isReviewStep = useMemo(() => {
    return selectedStep.type === StepType.ACCEPTANCE;
  }, [selectedStep?.type]);

  const conditionValue = useMemo(() => {
    if (!selectedStep.condition || selectedStep.condition === "none") {
      return stepConditionOptions[0];
    }
    return stepConditionOptions.find(
      (option) => option.value === selectedStep.condition
    );
  }, [selectedStep.condition, stepConditionOptions]);

  const handleChangeReviewConfig = useCallback(
    (config: StepReviewConfig) => {
      if (!selectedStep || !selectedStep.assignees) return;
      const assignees: StepAssignee[] = selectedStep.assignees.map(
        (assignee) => ({
          ...assignee,
          ...config,
        })
      );

      onChangeReviewConfig(config);
      onChange && onChange({ assignees });
    },
    [onChange, onChangeReviewConfig, selectedStep]
  );

  const handleChangeAssignees = useCallback(
    (emails: string[]) => {
      const assignees: StepAssignee[] = emails.map((email) => {
        const existedAssignee = selectedStep.assignees?.find(
          (assignee) => assignee.userId === email
        );

        return (
          existedAssignee || {
            userId: email,
            workInstructionStep: selectedStep.position,
            ...(isReviewStep && { ...reviewConfig }),
          }
        );
      });

      onChange && onChange({ assignees });
    },
    [
      isReviewStep,
      onChange,
      reviewConfig,
      selectedStep.assignees,
      selectedStep.position,
    ]
  );

  return (
    <div>
      <div className="flex items-center h-32 gap-4 px-4 bg-white border-b">
        <div className="flex justify-start w-full gap-4 xl:w-1/2">
          <div className="flex items-center justify-start w-1/2 gap-4">
            <ProjectWorkflowStepIcon type={selectedStep.type} />
            <div className="w-full">
              <input
                value={selectedStep.name}
                placeholder="Enter name"
                className="w-full px-2 py-1 text-sm font-bold border rounded bg-background-50 border-background-300"
                onChange={(e) => onChange && onChange({ name: e.target.value })}
                disabled={disabled}
              />
            </div>
          </div>

          <div className="w-1/2">
            <VBRichTextEditor
              key={selectedStep.position}
              defaultValue={selectedStep.description || ""}
              containerClass="w-full text-sm text-background-500 px-2 py-1 border rounded bg-background-50 border-background-300"
              onChange={(value) => {
                onChange && onChange({ description: value || "" });
              }}
              config={{ placeholder: "Enter description" }}
              disabled={disabled}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between gap-4">
        <div className="w-1/2 p-4 space-y-2">
          <div className="flex items-center justify-start gap-4">
            <h3 className="text-base font-semibold"> Step details</h3>
          </div>
          <div className="space-y-6">
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <span className="text-sm">
                  {t("workflow:instructionmodal.textNumOfRounds")}
                </span>
                <input
                  ref={numberOfRoundsInputRef}
                  value={selectedStep.numberOfRounds}
                  type="number"
                  className="w-12 p-1 text-sm border rounded bg-background-50 border-background-300"
                  onChange={(e) =>
                    onChange &&
                    onChange({
                      numberOfRounds: e.target.value
                        ? Number(e.target.value)
                        : undefined,
                    })
                  }
                  disabled={disabled}
                />
              </div>

              {!isFirstStep && (
                <div className="flex items-center gap-2">
                  <VBCheckbox
                    value={!!selectedStep.readonly}
                    onChange={() =>
                      onChange && onChange({ readonly: !selectedStep.readonly })
                    }
                    disabled={disabled}
                  />
                  <span className="text-sm">Read-Only</span>
                </div>
              )}

              {!isFirstStep && (
                <div className="flex items-center gap-2">
                  <VBCheckbox
                    value={!!selectedStep.canViewPreviousStepResult}
                    onChange={() =>
                      onChange &&
                      onChange({
                        canViewPreviousStepResult:
                          !selectedStep.canViewPreviousStepResult,
                      })
                    }
                    disabled={disabled}
                  />
                  <span className="text-sm">
                    Show result from previous steps
                  </span>
                </div>
              )}
              <div className="flex items-center gap-2">
                <span className="text-sm">
                  {t("workflow:instructionmodal.textScoreThreshold")}
                </span>
                <input
                  ref={scoreThresholdRef}
                  type="number"
                  value={selectedStep.scoreThreshold}
                  min={0}
                  max={1}
                  onChange={(e) =>
                    onChange &&
                    onChange({
                      scoreThreshold: e.target.value
                        ? Number(e.target.value)
                        : undefined,
                    })
                  }
                  className="w-12 p-1 text-sm border rounded bg-background-50 border-background-300"
                />
              </div>
              {!isFirstStep && selectedStep.type !== StepType.ACCEPTANCE && (
                <div className="flex items-center gap-2">
                  <span className="text-sm">Conditions:</span>
                  <div>
                    <VBSelectComponent
                      className="w-auto"
                      defaultValue={conditionValue}
                      options={stepConditionOptions}
                      onChange={(option: any) => {
                        onChange && onChange({ condition: option.value });
                      }}
                      menuPortalTarget={document.body}
                      size="sm"
                      isDisabled={disabled}
                    />
                  </div>
                </div>
              )}
            </div>
            {isReviewStep &&
              selectedStep.assignees &&
              selectedStep.assignees.length > 0 && (
                <>
                  <div className="w-full h-px bg-gray-300" />
                  <ProjectWorkflowReviewConfig
                    config={reviewConfig}
                    onChange={handleChangeReviewConfig}
                  />
                </>
              )}
          </div>
        </div>
        <div className="w-1/2 p-4 space-y-2">
          <h3 className="text-base font-semibold">
            Define on annotation workflow
          </h3>

          <div className="text-sm text-background-500">
            The steps each image will go through when being annotated. The same
            annotator or reviewer will not be assigned an image twice unless no
            one else is available.
          </div>

          <ProjectWorkflowAssignLabeler
            emails={allEmails}
            selectedEmails={selectedEmails}
            max={selectedStep.numberOfRounds}
            onSelect={handleChangeAssignees}
          />
        </div>
      </div>
    </div>
  );
};
