import { AnalyticsQueryResponse } from "services/analytics-service";
import { TaskCompletedItemDataModel } from "../components/task-statistic-chart.component";

const COL_DATE = "date";
const COL_TOTAL = "total";

export const taskCompletedMapper = (response: AnalyticsQueryResponse): TaskCompletedItemDataModel[] => {
  const rows = response.data.rows;
  if (rows && rows.length > 0) {
    return rows.map((row: any) => {
      const resRow: TaskCompletedItemDataModel = {
        name: row[COL_DATE],
        completed: row[COL_TOTAL],
      };
      return resRow;
    });
  }
  return [];
}