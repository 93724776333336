import { VBSwitch } from "components/design-system/switch/switch.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { VBInput } from "pages/labeler/labeler-profile/components/vb-input.component";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_TIME_LIMIT_SECOND,
  MAX_TIME_LIMIT_SECOND,
  MIN_TIME_LIMIT_SECOND,
} from "services/label-service/dtos";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import {
  loadBatchDataAsync,
  updateBatchManagementAsync,
  UpdateBatchPayload,
} from "store/customer/batch/batch.thunk";
import { selectActiveLearningVisible } from "store/customer/project/project.selectors";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { useBatchDetailContext } from "../../context/batch-detail.context";
import * as Sentry from "@sentry/react";

export const BatchSettingsPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { batch } = useBatchDetailContext();
  const [isUpdating, setIsUpdating] = useState(false);
  const [timeLimitSecond, setTimeLimitSecond] = useState(
    DEFAULT_TIME_LIMIT_SECOND
  );
  const [error, setError] = useState("");
  const hasError = useMemo(() => !!error, [error]);
  const [changed, setChanged] = useState(false);
  const [activeLearningChecked, setActiveLearningChecked] = useState(
    batch?.batchSetting?.activeLearning?.enable || false
  );
  const [balanceJobAssignChecked, setBalanceJobAssignChecked] = useState(
    batch?.batchSetting?.balanceJobAssign || false
  );

  useEffect(() => {
    setActiveLearningChecked(
      batch?.batchSetting?.activeLearning?.enable || false
    );
    setBalanceJobAssignChecked(batch?.batchSetting?.balanceJobAssign || false);
  }, [batch]);

  const activeLearningVisible = useAppSelector(selectActiveLearningVisible);

  useEffect(() => {
    if (!batch) return;

    setTimeLimitSecond(
      batch.batchSetting
        ? batch.batchSetting.timeLimitSecond
        : DEFAULT_TIME_LIMIT_SECOND
    );
  }, [batch]);

  const handleTimeLimtSecondChanged = (newValue: string) => {
    let newIntValue = parseInt(newValue);

    // validate
    const minValue = MIN_TIME_LIMIT_SECOND;
    const maxValue = MAX_TIME_LIMIT_SECOND;
    if (
      newIntValue < minValue ||
      newIntValue > maxValue ||
      isNaN(newIntValue)
    ) {
      setError(t("common:rangeError", { min: minValue, max: maxValue }));
    } else {
      setError("");
    }

    setTimeLimitSecond(newIntValue);
    setChanged(true);
  };

  const handleChangeActiveLearning = (checked: boolean) => {
    setActiveLearningChecked(checked);
    setChanged(true);
  };
  const handleChangeBalanceJobAssign = (checked: boolean) => {
    setBalanceJobAssignChecked(checked);
    setChanged(true);
  };

  const handleUpdate = async () => {
    if (!batch || isUpdating) return;

    try {
      setIsUpdating(true);

      let timeLimitSecondValid = timeLimitSecond;

      if (
        timeLimitSecond < 0 ||
        isNaN(timeLimitSecond) ||
        !batch.batchSetting
      ) {
        timeLimitSecondValid = DEFAULT_TIME_LIMIT_SECOND;
      }

      const updateBatchData: UpdateBatchPayload = {
        batchId: batch.id,
        payload: {
          batch: {
            batchSetting: {
              consensusRate: batch.batchSetting
                ? batch.batchSetting.consensusRate
                : 1,
              timeLimitSecond: timeLimitSecondValid,
              activeLearning: {
                enable: activeLearningChecked,
              },
              balanceJobAssign: balanceJobAssignChecked,
            },
          },
        },
      };

      const response = await dispatch(
        updateBatchManagementAsync(updateBatchData)
      );
      handleThunkRejected(response);
      const fetchResponse = await dispatch(
        loadBatchDataAsync(batch.id.toString())
      );
      handleThunkRejected(fetchResponse);
      dispatch(enqueueSuccessNotification(t("common:textUpdatedSuccess")));
    } catch (err: any) {
      Sentry.captureException(err);
      const errMessage = err.message || t("common:textUpdatedFailed");
      dispatch(enqueueErrorNotification(errMessage));
    } finally {
      setIsUpdating(false);
      setChanged(false);
    }
  };

  return (
    <div className="py-6">
      <div className="flex justify-between w-full">
        <div>
          {activeLearningVisible && (
            <div className="flex items-center mb-4">
              <div>
                {t("project:batchDetails.batchSettings.activeLearning")}
              </div>
              <VBSwitch
                checked={activeLearningChecked}
                onChange={handleChangeActiveLearning}
              />
            </div>
          )}

          <div className="flex items-center mb-4">
            <div>
              {t("project:batchDetails.batchSettings.balanceJobAssign")}
            </div>
            <VBSwitch
              checked={balanceJobAssignChecked}
              onChange={handleChangeBalanceJobAssign}
            />
          </div>

          <VBInput
            label={t("project:batchDetails.batchSettings.inputTimeLimit")}
            value={timeLimitSecond}
            type="number"
            min={MIN_TIME_LIMIT_SECOND}
            max={MAX_TIME_LIMIT_SECOND}
            onValueChanged={handleTimeLimtSecondChanged}
            hint={error}
          />
        </div>

        <button
          className="button-text-secondary disabled:opacity-50"
          onClick={handleUpdate}
          disabled={!changed || hasError || isUpdating}
        >
          {t("common:buttonUpdate")}
        </button>
      </div>
    </div>
  );
};
