/*
 * File: batch-progress.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 20th August 2021 2:07:46 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { useBatchStatistic } from "hooks/batch/use-batch-statistic.hook";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useParams } from "react-router-dom";
import {
  selectBatchInfo,
  selectBatchInstructionRounds,
  selectDoesBatchHasStepReview,
} from "store/customer/batch/batch.selectors";
import { LabelerStatistic } from "./labeler-statistic.component";
import { BatchProgressChart } from "./progress-chart.component";
import { useState } from "react";
import { updateBatchManagementAsync } from "store/customer/batch/batch.thunk";
import DatePicker from "react-datepicker";
import { formatDate } from "utilities/formatter/date-formatter.utils";
import { isTextProject } from "services/label-service/dtos";

export const BatchProgress = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { batchId } = useParams<{ batchId: string }>();
  const batch = useAppSelector(selectBatchInfo);
  const hasStepReview = useAppSelector(selectDoesBatchHasStepReview);
  const batchRounds = useAppSelector(selectBatchInstructionRounds);
  const { statistic, labelersStatistic } = useBatchStatistic(parseInt(batchId));
  const [dueDate, setDueDate] = useState(() =>
    batch?.dueDate ? new Date(batch.dueDate) : undefined
  );
  const isOverdue = false;

  if (!statistic)
    return (
      <VBInlineLoading className="flex items-center justify-center p-12" />
    );

  const handleDueDateChange = (newDueDate: Date) => {
    if (!batch) return;
    if (!newDueDate) return;
    setDueDate(newDueDate);
    const updateBatchData = {
      batchId: batch.id,
      payload: {
        batch: {
          dueDate: newDueDate.toISOString(),
        },
      },
    };
    dispatch(updateBatchManagementAsync(updateBatchData));
  };

  return (
    <div>
      <div className="flex flex-wrap gap-4 my-4">
        <div className="flex-shrink-0 bg-white">
          <BatchProgressChart statistic={statistic.taskStatistic} />
        </div>
        <div className="flex-1 gap-8 px-4">
          <div>
            <div className="py-2">
              {t("project:batchDetails.labelerDistribution.textNumOfTasks")}:{" "}
              <span className="font-bold">{statistic.totalTask}</span>
            </div>
            <div className="py-2">
              {t("project:batchDetails.labelerDistribution.textNumOfLabelers")}:{" "}
              <span className="font-bold">{statistic.totalLabeler}</span>
            </div>
            <div className="py-2">
              <span>
                {t("project:batchDetails.labelerDistribution.textMumOfRounds")}:{" "}
              </span>
              <span className="font-bold">{batchRounds}</span>
            </div>
          </div>
          {hasStepReview && (
            <div>
              <div className="py-2">
                {t(
                  "project:batchDetails.labelerDistribution.textNumOfReviewLeftTasks"
                )}
                :{" "}
                <span className="font-bold">
                  {statistic.reviewStatistic
                    ? statistic.reviewStatistic["Left"] || 0
                    : 0}
                </span>
              </div>
              <div className="py-2">
                {t(
                  "project:batchDetails.labelerDistribution.textNumOfAcceptedTasks"
                )}
                :{" "}
                <span className="font-bold">
                  {statistic.reviewStatistic
                    ? statistic.reviewStatistic["Accept"] || 0
                    : 0}
                </span>
              </div>
              <div className="py-2">
                <span>
                  {t(
                    "project:batchDetails.labelerDistribution.textMumOfRejectedTasks"
                  )}
                  :{" "}
                </span>
                <span className="font-bold">
                  {statistic.reviewStatistic
                    ? statistic.reviewStatistic["Reject"] || 0
                    : 0}
                </span>
              </div>
            </div>
          )}
          <div className="col-span-2">
            <div className="py-2">
              <span>
                {t("project:batchDetails.labelerDistribution.textStartingDate")}
                :{" "}
              </span>
              <span className="font-bold">
                {statistic.startDate
                  ? formatDate(new Date(statistic.startDate))
                  : "-- / --"}
              </span>
            </div>

            <div className="flex items-center py-2">
              <div>
                {t(
                  "project:batchDetails.labelerDistribution.textExpectedFinishDate"
                )}
                :{" "}
              </div>
              <span className="mx-4 font-bold">
                <DatePicker
                  className="px-2 py-1 font-bold border rounded"
                  selected={dueDate}
                  onChange={handleDueDateChange}
                  dateFormat="dd/LLL/yyyy"
                  minDate={new Date()}
                />
              </span>
              {isOverdue && (
                <span className="px-2 py-1 mx-2 text-xs font-bold text-white bg-red-500 rounded">
                  {t("project:batchDetails.labelerDistribution.textOverdue")}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="py-4">
        {batch && (
          <LabelerStatistic
            batch={batch}
            data={labelersStatistic}
            isTextProject={!!isTextProject(batch?.project?.type)}
          />
        )}
      </div>
    </div>
  );
};
