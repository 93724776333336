/*
 * File: vb-empty-content.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

interface Props {
  visible: boolean;
  iconClass: string;
  title: string;
  message: string;
  ctaText?: string;
  onCTAClick?(): void;
  createIfNotExist?: boolean;
}

export const VBEmptyContent = ({
  visible,
  iconClass: iconUrl,
  title,
  message,
  ctaText = "Create New",
  createIfNotExist,
  onCTAClick,
}: Props) => {
  if (!visible) return null;
  return (
    <div className="flex flex-col items-center p-10 animate-fade-in-up">
      <i
        className={`m-8 p-16 rounded-full text-6xl bg-gray-300 text-white ${iconUrl}`}
      ></i>
      <h2 className="m-2 text-3xl font-bold">{title}</h2>
      <p className="m-2 text-lg text-gray-500">{message}</p>
      {createIfNotExist && (
        <button
          className="button-text-primary my-4"
          onClick={onCTAClick}
        >
          {ctaText}
        </button>
      )}
    </div>
  );
};
