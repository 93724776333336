/*
 * File: error-dialog.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 13th December 2021 11:30:45 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Backdrop, Grow } from "@material-ui/core";
import { IconWarning } from "components/common/vb-icon.component";
import { MatModal } from "components/material/mat-modal.component";
import { useTranslation } from "react-i18next";

interface Props {
  visible: boolean;
  onClose(): void;
  message?: string;
}

export const ErrorDialog = ({ visible, onClose, message }: Props) => {
  const { t } = useTranslation();
  return (
    <MatModal
      disableBackdropClick
      open={visible}
      closeAfterTransition
      BackdropComponent={Backdrop}
      className="flex items-start justify-center"
    >
      <Grow in={visible}>
        <div
          className="relative flex flex-col w-full px-4 py-6 mt-40 space-y-4 overflow-hidden bg-white rounded shadow"
          style={{ maxHeight: "40rem", maxWidth: "32rem" }}
        >
          <div className="flex items-center gap-2 px-4">
            <IconWarning className="text-warning-500" />
            <span className="text-xl font-bold">
              {t("labelerworkspace:errors.fetchJobError")}
            </span>
          </div>
          <p className="px-4 leading-normal text-gray-600">
            {t("labelerworkspace:errors.errorUnknowMessage")}
          </p>
          <p className="px-4">Error: {message}</p>
          <div className="flex items-center justify-end gap-4">
            <button onClick={onClose} className="button-text-secondary">
              {t("common:buttonExit")}
            </button>
          </div>
        </div>
      </Grow>
    </MatModal>
  );
};
