/*
 * File: get-step-distribution-data.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 3:37:51 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { StepDistributionDTO } from "store/customer/project-data/label-distribution/label-distribution.state";


export const getStepDistributionData = (items: StepDistributionDTO[]) => {
  const stepDistributionData: Record<number, StepDistributionDTO> = {};
  const averageStep: StepDistributionDTO = {
    agreeClassification: {},
    agreeSegmentation: {},
    avgIou: {},
    oneLabeler: {},
    avgProbability: {},
    step: 0,
  };
  type AverageCount = Record<string, { value: number; count: number }>;
  const agreeClassification: AverageCount = {};
  const agreeSegmentation: AverageCount = {};
  const avgIou: AverageCount = {};
  const avgProbability: AverageCount = {};
  const oneLabeler: AverageCount = {};

  for (let item of items) {
    if (!item.agreeClassification) {
      item.agreeClassification = {};
    }
    for (let key of Object.keys(item.agreeClassification)) {
      if (!agreeClassification.hasOwnProperty(key)) {
        agreeClassification[key] = {
          value: 0,
          count: 0,
        };
      }
      if (item.agreeClassification[key] !== null) {
        agreeClassification[key].value += item.agreeClassification[key];
        agreeClassification[key].count += 1;
      }
    }

    if (!item.agreeSegmentation) {
      item.agreeSegmentation = {};
    }
    for (let key of Object.keys(item.agreeSegmentation)) {
      if (!agreeSegmentation.hasOwnProperty(key)) {
        agreeSegmentation[key] = {
          value: 0,
          count: 0,
        };
      }
      if (item.agreeSegmentation[key] !== null) {
        agreeSegmentation[key].value += item.agreeSegmentation[key];
        agreeSegmentation[key].count += 1;
      }
    }

    if (!item.avgIou) {
      item.avgIou = {};
    }
    for (let key of Object.keys(item.avgIou)) {
      if (!avgIou.hasOwnProperty(key)) {
        avgIou[key] = {
          value: 0,
          count: 0,
        };
      }
      if (item.avgIou[key] !== null) {
        avgIou[key].value += item.avgIou[key];
        avgIou[key].count += 1;
      }
    }

    if (!item.avgProbability) {
      item.avgProbability = {};
    }
    for (let key of Object.keys(item.avgProbability)) {
      if (!avgProbability.hasOwnProperty(key)) {
        avgProbability[key] = {
          value: 0,
          count: 0,
        };
      }
      if (item.avgProbability[key] !== null) {
        avgProbability[key].value += item.avgProbability[key];
        avgProbability[key].count += 1;
      }
    }

    if (!item.oneLabeler) {
      item.oneLabeler = {};
    }
    for (let key of Object.keys(item.oneLabeler)) {
      if (!oneLabeler.hasOwnProperty(key)) {
        oneLabeler[key] = {
          value: 0,
          count: 0,
        };
      }
      if (item.oneLabeler[key] !== null) {
        oneLabeler[key].value += item.oneLabeler[key];
        oneLabeler[key].count += 1;
      }
    }

    stepDistributionData[item.step === undefined ? 0 : item.step] = item;
  }

  for (let key of Object.keys(agreeClassification)) {
    averageStep.agreeClassification[key] =
      agreeClassification[key].count === 0
        ? 0
        : agreeClassification[key].value / agreeClassification[key].count;
  }

  for (let key of Object.keys(agreeSegmentation)) {
    if (!averageStep.agreeSegmentation) {
      averageStep.agreeSegmentation = {};
    }
    averageStep.agreeSegmentation[key] =
      agreeSegmentation[key].count === 0
        ? 0
        : agreeSegmentation[key].value / agreeSegmentation[key].count;
  }

  for (let key of Object.keys(avgIou)) {
    if (!averageStep.avgIou) {
      averageStep.avgIou = {};
    }
    averageStep.avgIou[key] =
      avgIou[key].count === 0 ? 0 : avgIou[key].value / avgIou[key].count;
  }

  for (let key of Object.keys(avgProbability)) {
    if (!averageStep.avgProbability) {
      averageStep.avgProbability = {};
    }
    averageStep.avgProbability[key] =
    avgProbability[key].count === 0 ? 0 : avgProbability[key].value / avgProbability[key].count;
  }

  for (let key of Object.keys(oneLabeler)) {
    if (!averageStep.oneLabeler) {
      averageStep.oneLabeler = {};
    }
    averageStep.oneLabeler[key] =
      oneLabeler[key].count === 0
        ? 0
        : oneLabeler[key].value / oneLabeler[key].count;
  }

  stepDistributionData[averageStep.step] = averageStep;
  return stepDistributionData;
};
