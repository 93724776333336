/*
 * File: auto-split.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 5:54:28 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Backdrop, Grow, Slider } from "@material-ui/core";
import { MatModal } from 'components/material/mat-modal.component';
import { useScope } from "hooks/scope/use-scope.hook";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { ScopeDTO } from "services/storage-service/dto/scope.dto";

export interface ScopePercentage extends ScopeDTO {
  percentage: number;
  records: number;
}

interface Props {
  total: number;
  visible?: boolean;
  onClose(): void;
  onSubmit?(values: ScopePercentage[]): void;
}
export const AutoSplitModal = ({
  total,
  visible = true,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const { scopes } = useScope();
  const [scopePercentages, setScopePercentages] = useState<ScopePercentage[]>(
    []
  );
  const [processing, setProcessing] = useState(false);

  function handleSubmit() {
    if (processing) return;
    setProcessing(true);
    onSubmit && onSubmit(scopePercentages);
    setProcessing(false);
  }

  function convertToSliderValues(scopePercentages: ScopePercentage[]) {
    let accumulatedPercentage = 0;
    return scopePercentages.map((p) => {
      accumulatedPercentage += p.percentage;
      return accumulatedPercentage;
    });
  }

  function sliderValueLabelFormat(index: number) {
    const { percentage } = scopePercentages[index];
    return `${percentage}%`;
  }

  function handleSliderChange(values: number[]) {
    values[values.length - 1] = 100;
    const newScopeP = scopePercentages.map((scope, index) => {
      const prev = index === 0 ? 0 : values[index - 1];
      return {
        ...scope,
        percentage: values[index] - prev,
      };
    });
    calculateRecords(newScopeP, total);
    setScopePercentages(newScopeP);
  }

  useEffect(() => {
    let percentages = scopes.map((scope, index) => {
      return {
        ...scope,
        percentage: index === 0 ? 100 - 10 * (scopes.length - 1) : 10,
        records: 0,
      };
    });
    calculateRecords(percentages, total);
    setScopePercentages(percentages);
  }, [scopes, total]);

  return (
    <MatModal
      open={visible}
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={onClose}
      disableBackdropClick
      className="flex items-center justify-center"
    >
      <Grow in={visible}>
        <div
          className="relative flex flex-col w-full overflow-hidden bg-white rounded shadow mb-80"
          style={{ maxWidth: "50rem" }}
        >
          <div className="px-8 py-4">
            <div className="my-4 text-2xl">{t('project:batchDetails.autoSplit.title')}</div>
            <p className="my-4 text-red-500">
              {t('project:batchDetails.autoSplit.textWarn')}
            </p>
            <div className="p-2">
              <Slider
                className="mt-8"
                track={false}
                aria-labelledby="track-false-slider"
                value={convertToSliderValues(scopePercentages)}
                valueLabelDisplay="on"
                valueLabelFormat={(_, index) => sliderValueLabelFormat(index)}
                onChange={(_, values) => handleSliderChange(values as number[])}
              />
              <SliderLabels
                value={scopePercentages.map((scope) => scope.percentage)}
                labels={scopePercentages.map((scope) => scope.name)}
              />
            </div>
            {scopePercentages.map((scope) => {
              return (
                <ModelSet
                  key={scope.id}
                  title={scope.name}
                  percentage={scope.percentage}
                  records={scope.records}
                />
              );
            })}

            <div className="flex items-center justify-end gap-4 pt-8">
              <button className="px-4 py-2" onClick={onClose}>
                {t('common:buttonCancel')}
              </button>
              <button
                onClick={handleSubmit}
                className="px-4 py-2 text-white rounded bg-primary disabled:opacity-50"
                style={{ minWidth: "6rem" }}
                disabled={processing}
              >
                {t('common:buttonRun')}
              </button>
            </div>
          </div>
        </div>
      </Grow>
    </MatModal>
  );
};

function calculateRecords(scopePercentages: ScopePercentage[], total: number) {
  let index = 0;
  for (const scope of scopePercentages) {
    const bound =
      index + Math.round(total * scope.percentage * 0.01) < total
        ? index + Math.round(total * scope.percentage * 0.01)
        : total;
    scope.records = bound - index;
    index = bound;
  }
}

interface SliderLabelsProps {
  value: number[];
  labels: string[];
}

const SliderLabels = ({ value, labels }: SliderLabelsProps) => {
  return (
    <div className="flex w-full overflow-visible">
      {value.map((percent, index) => (
        <div
          key={labels[index]}
          style={{ width: `${percent}%` }}
          className="flex justify-end"
        >
          <div className="flex justify-center w-0">
            <div className="w-32 text-center">{labels[index]}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

interface ModelSetProps {
  title: string;
  percentage: number;
  records: number;
  onChange?(value: number): void;
}
const ModelSet = ({ title, records, percentage, onChange }: ModelSetProps) => {
  const { t } = useTranslation();
  function handleInputChange(event: ChangeEvent<{ value: unknown }>) {
    onChange && onChange(parseInt(event.target.value as string));
  }
  return (
    <div className="flex items-center gap-4 mt-4">
      <span style={{ minWidth: "6rem" }}>{title}</span>
      <span>
        <input
          className="w-24 px-2 py-1 border"
          type="number"
          value={percentage}
          min={0}
          max={100}
          onChange={handleInputChange}
        />
      </span>
      <span> % = </span>
      <span className="px-3 py-1 border" style={{ minWidth: "5rem" }}>
        {records}
      </span>
      <span>{t('project:batchDetails.autoSplit.textRecords')}</span>
    </div>
  );
};
