/*
 * File: use-labeler-distribution.hook.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 11:35:59 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect } from "react";
import { RequestStatus } from "store/base/base.state";
import {
  selectLabelDistributionRequestError,
  selectLabelDistributionRequestStatus,
} from "store/customer/batch-data/label-distribution/label-distribution.selectors";
import { loadLabelDistributionAsync } from "store/customer/batch-data/label-distribution/label-distribution.thunk";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { usePrevious } from "ahooks";
import * as Sentry from "@sentry/react";

export const useBatchLabelDistribution = (
  batchId: number,
  reloadIfLoaded = false
) => {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(selectLabelDistributionRequestStatus);
  const requestError = useAppSelector(selectLabelDistributionRequestError);
  const previousBatchId = usePrevious(batchId);

  const requestData = useCallback(
    async (force = false) => {
      if (!force && requestStatus === RequestStatus.LOADING) return;
      if (!reloadIfLoaded && !force && requestStatus !== RequestStatus.IDLE) return;
      try {
        const response = await dispatch(loadLabelDistributionAsync(batchId));
        handleThunkRejected(response);
      } catch (error: any) {
        Sentry.captureException(error);
        const errMessage =
          error.message || "Failed to load label distribution data";
        dispatch(enqueueErrorNotification(errMessage));
        Logger.log(error);
      }
    },
    [dispatch, batchId, requestStatus, reloadIfLoaded]
  );

  useEffect(() => {
    if (batchId && batchId >= 0 && batchId !== previousBatchId){
      requestData(true);
    } else {
      requestData();
    }
  }, [dispatch, batchId, previousBatchId, requestData]);

  return { requestData, requestStatus, requestError };
};
