

export interface TextToSpeechTextProps {
  text: string
}

export const TextToSpeechText = ({
  text,
}: TextToSpeechTextProps) => {
  return (
    <div className="w-full bg-white p-4 rounded text-center overflow-y-auto"
      style={{
        height: "12rem",
        fontSize: "1.4rem"
      }}
    >
      {text}
    </div>
  )
}