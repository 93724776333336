import { useExportedDatasets } from "hooks/datasets/use-exported-datasets.hook";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { MLModelsRoutes } from "../ml-models.route";
import { ExportedDatasetsV3Table } from "./exported-datasets-v3.table";
import { exportedDatasetsMapper } from "./exported-datasets.mappers";
import { MlModelsExportedDatasetVersionUIModel } from "./exported-datasets.models";

export const ExportedDatasetsV2Page = () => {
  const history = useHistory();
  const { exportedDatasets, loading } = useExportedDatasets(true);

  const datasetVersions = useMemo(() => {
    let versions: MlModelsExportedDatasetVersionUIModel[] = [];

    for (const dataset of exportedDatasets) {
      versions = versions.concat(exportedDatasetsMapper.dtoToVersion(dataset));
    }

    return versions.sort((a, b) => {
      return b.versionId - a.versionId;
    });
  }, [exportedDatasets]);

  function handleRowAction(
    row: MlModelsExportedDatasetVersionUIModel,
    action: "delete" | "view"
  ) {
    if (action === "view") {
      const url =
        Routes.MLMODELS +
        MLModelsRoutes.EXPORTED_DATASET_DETAIL.replace(
          ":datasetId",
          row.datasetId.toString()
        ) +
        `?versionId=${row.versionId}`;
      history.push(url);
    }
  }

  if (loading) {
    return <div className="p-10 mt-20 text-center">Loading...</div>;
  }
  return (
    <div className="flex flex-col h-full gap-4 animate-fade-in-up">
      <h3 className="text-lg font-bold">Exported datasets</h3>

      <ExportedDatasetsV3Table
        isLoading={loading}
        rows={datasetVersions}
        onRowAction={handleRowAction}
      />
    </div>
  );
};
