/*
 * File: image-annotation-sources.state.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 18th October 2022 2:20:02 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { CornerstoneHandler } from "components/dicom/cornerstone/cornerstone-handler/cornerstone-handler";
import { CornerstoneViewportEditor } from "components/dicom/cornerstone/viewport-editor/viewport-editor";
import { Annotation, Label } from "domain/image-labeling";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";
import { ImageLabelingData } from "domain/labeling/labeling-data";
import { MutableRefObject } from "react";
import { SelectableAnnotator } from "../reducer/annotations-reducer.hook";

export interface AnnotationMenuItem {
  id: number;
  name: string;
  hidden?: boolean;
  selected?: boolean;
  children: AnnotationMenuItem[];
  data?: any;
  type: string;
}

export interface ImageAnnotationSourcesState {
  imageUrl: string;
  labelingDatas: ImageLabelingData[];
  labels: Label[];
  annotationMenuItems: AnnotationMenuItem[];
  annotators: SelectableAnnotator[];
  imageAnnotations: {
    annotations: Annotation[];
    relationAnnotations: RelationAnnotation[];
  };
  onClose?(): void;
  imageLoaded: boolean;
  viewportEditor: MutableRefObject<CornerstoneViewportEditor | undefined>;
  cornerstoneHandler: MutableRefObject<CornerstoneHandler | undefined>;
  selectAnnotator(annotatorId: number): void;
  selectLabel(labelId: number, action?: string): void;
  selectAnnotation(annotationId: number, action?: string): void;
  setImageLoaded(loaded: boolean): void;
  onSubmit(): void;
}

export const IMAGE_ANNOTATION_SOURCES_INITIAL_STATE =
  {} as ImageAnnotationSourcesState;
