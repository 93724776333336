/*
 * File: task-message.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 1st December 2022 10:01:23 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { TaskRunner, TaskRunnerType } from "domain/customer/task-runner";
import { useAppDispatch } from "hooks/use-redux";
import { taskRunnerRemoved } from "store/customer/task-runner/task-runner.slice";
import { DownloadDatasetMessage } from "./components/download-dataset/download-dataset-message.component";
import { GenerateAnnotationMessage } from "./components/generate-annotations/generate-annotations-message.component";
import { ImportAnnotationsMessage } from "./components/import-annotations/import-annotations-message.component";
import { ImportModelMessage } from "./components/import-model/import-model-message.component";
import { CreateBatchMessage } from "./components/create-batch-message/create-batch-message.component";
import { SyncCloudMessage } from "./components/sync-cloud/sync-cloud-message.component";
import { UploadZipMessage } from "./components/upload-zip/upload-zip-message.component";
import { TrainModelMessage } from "./components/train-model-message/train-model-message.component";
import { DownloadModelMessage } from "./components/download-model-message/download-model-message.component";
import { AssignJobUsingCSVMessage } from "./components/assign-job-using-csv-message/assign-job-using-csv-message";
import { DownloadBatchReportMessage } from "./components/download-batch-report-message/download-batch-report-message.component";

interface TaskMessageProps {
  task: TaskRunner;
  index: number;
}
export default function TaskMessage({ task, index }: TaskMessageProps) {
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(taskRunnerRemoved(task));
  };
  const messages: Record<string, JSX.Element> = {
    [TaskRunnerType.ANNOTATION_IMPORT]: (
      <ImportAnnotationsMessage
        index={index}
        key={task.id}
        task={task}
        onClose={handleClose}
      />
    ),
    [TaskRunnerType.ANNOTATION_GENERATE]: (
      <GenerateAnnotationMessage
        index={index}
        key={task.id}
        task={task}
        onClose={handleClose}
      />
    ),
    [TaskRunnerType.BATCH_ANNOTATE]: (
      <GenerateAnnotationMessage
        index={index}
        key={task.id}
        task={task}
        onClose={handleClose}
      />
    ),
    [TaskRunnerType.CLOUD_SYNC]: (
      <SyncCloudMessage
        index={index}
        key={task.id}
        task={task}
        onClose={handleClose}
      />
    ),
    [TaskRunnerType.ARCHIVE_UPLOAD]: (
      <UploadZipMessage
        index={index}
        key={task.id}
        task={task}
        onClose={handleClose}
      />
    ),
    [TaskRunnerType.DOWNLOAD_DATASET]: (
      <DownloadDatasetMessage
        index={index}
        key={task.id}
        task={task}
        onClose={handleClose}
      />
    ),
    [TaskRunnerType.BATCH_STATISTIC_REPORT]: (
      <DownloadBatchReportMessage
        index={index}
        key={task.id}
        task={task}
        onClose={handleClose}
      />
    ),
    [TaskRunnerType.IMPORT_MODEL]: (
      <ImportModelMessage
        index={index}
        key={task.id}
        task={task}
        onClose={handleClose}
      />
    ),
    [TaskRunnerType.IMPORT_CUSTOM_MODEL]: (
      <ImportModelMessage
        index={index}
        key={task.id}
        task={task}
        onClose={handleClose}
      />
    ),
    [TaskRunnerType.CREATE_BATCH]: (
      <CreateBatchMessage
        index={index}
        key={task.id}
        task={task}
        onClose={handleClose}
      />
    ),
    [TaskRunnerType.TRAIN_MODEL]: (
      <TrainModelMessage
        index={index}
        key={task.id}
        task={task}
        onClose={handleClose}
      />
    ),
    [TaskRunnerType.DOWNLOAD_MODEL]: (
      <DownloadModelMessage
        index={index}
        key={task.id}
        task={task}
        onClose={handleClose}
      />
    ),
    [TaskRunnerType.BATCH_MANUAL_ASSIGN_JOB]: (
      <AssignJobUsingCSVMessage
        index={index}
        key={task.id}
        task={task}
        onClose={handleClose}
      />
    ),
  };

  if (messages.hasOwnProperty(task.jobType)) return messages[task.jobType];

  return null;
}
