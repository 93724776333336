import { useLoadComplexJob } from "pages/labeler/speech-to-text-labeling/hooks/use-load-complex-job.hook";
import { ConfirmExitDialog } from "pages/labeler/text-labeling/components/confirm-exit-dialog.component";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { StorageService } from "services/storage";
import { StorageResource } from "services/storage/dto/resource.dto";
import { RequestStatus } from "store/base/base.state";
import { AppError } from "utilities/errors/errors";
import { TextToSpeechLabelingContext } from "./text-to-speech-labeling.context";
import {
  DEFAULT_UI_JOB,
  TextToSpeechJobUIModel,
} from "./text-to-speech-labeling.state";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useTranslation } from "react-i18next";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { Backdrop, LinearProgress, Modal } from "@material-ui/core";
import { usePreviousBatch } from "pages/labeler/image-labeling/hooks/use-previous-batch.hook";
import { BatchDetailDialog } from "pages/labeler/image-labeling/components/batch-detail-dialog/batch-detail-dialog.component";
import { selectIssueManagementDoesHaveWorkingIssues } from "store/common/issue-management/issue-management.selectors";
import { ComplexJobProviderProps } from "pages/labeler/speech-to-text-labeling/context/speech-to-text-labeling.provider";
import { LoadCombplexJobResponse } from "store/labeler/complex-jobs/complex-jobs.thunk";
import {
  deleteComplexJobsJobInBatch,
  setComplexJobWorkingStatus,
  setComplexJobWorkingStatusByJobId,
} from "store/labeler/complex-jobs/complex-jobs.slice";
import { AnnotationsService } from "services/label-service";
import { jobMapper } from "services/label-service/mappers/job.mapper";
import {
  AnnotationAttributeItem,
  AnnotationJobRequestDTO,
  TtsAnnotationItem,
} from "services/label-service/dtos/annotations.dto";
import { WorkingStatus } from "store/labeler/complex-jobs/complex-jobs.state";
import * as Sentry from "@sentry/react";
import { BatchObservationDTO } from "services/label-service/dtos";

interface Props extends ComplexJobProviderProps {}

export const TextToSpeechLabelingProvider = ({
  children,
  isLoadFromBatch = false,
  jobInBatch,
  isTaskReview,
  taskToReview,
  jobIdsIncludedOnly,
  isFromJobId,
  jobIdParam,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const history = useHistory();
  const [confirmExit, setShowConfirmExit] = useState(false);
  const handleConfirmExit = () => setShowConfirmExit(true);

  const [uiJob, setUIJob] = useState<TextToSpeechJobUIModel>();
  const { showBatchInstruction, setShowBatchInstruction } = usePreviousBatch(
    uiJob?.job ? jobMapper.fromDTO(uiJob.job) : undefined
  );
  const [hasChanged, setHasChanged] = useState(false);

  const hasWorkingIssues = useAppSelector(
    selectIssueManagementDoesHaveWorkingIssues(
      uiJob?.task?.id || -1,
      undefined,
      uiJob?.job?.id
    )
  );

  const loadJobFromComplexRes = useCallback(
    async (jobComplexRes: LoadCombplexJobResponse, uiJobData?: any) => {
      if (uiJobData) {
        setUIJob({ ...uiJobData });
        return;
      }

      const {
        job,
        isStepReviewJob,
        canViewPreviousStepResult,
        file,
        task,
        batch,
        annotations,
        previousJobsAnnotations,
        jobOptions,
        acceptBatchObservation,
        rejectBatchObservation,
        batchObservations,
        reopenReason,
        isTaskReview,
        isFromBatch,
        isFromJobId,
        error,
      } = jobComplexRes;

      if (error) {
        throw error;
      }

      if (!file || !task || !batch) {
        throw new AppError(
          "unknow",
          "Unknow error after calling loadJobComplex function"
        );
      }

      // get text from file
      let text: string = "";
      const res = await StorageService.getTextFileContent(file.url);
      text = res.data;

      // check resource if have saved data
      const hasSavedData = annotations.length > 0;

      function getDefaultAttributes(
        allBatchObservations: BatchObservationDTO[],
        assignee?: string
      ) {
        const defaultAttributes: AnnotationAttributeItem[] = [];

        for (const batchObservation of allBatchObservations) {
          for (const attribute of batchObservation.observation.attributes) {
            const isSelect = attribute.type.toLowerCase() === "select";
            const isAudio = attribute.type.toLowerCase() === "radio";
            if (!(isSelect || isAudio)) continue;
            try {
              const key = `${assignee}:attribute:${attribute.id}`;
              const str = localStorage.getItem(key);
              const value = str?.split(",").map((item) => item.trim()) ?? [];
              defaultAttributes.push({ id: attribute.id, value });
            } catch (error) {}
          }
        }

        return defaultAttributes;
      }

      const attributes = getDefaultAttributes(batchObservations, job?.assignee);

      let uiJobCommon: TextToSpeechJobUIModel = {
        ...DEFAULT_UI_JOB,
        text,
        job,
        isStepReviewJob,
        canViewPreviousStepResult,
        task,
        batch,
        project: batch.project,
        countDownSecond: 0,
        previousJobsAnnotations,
        jobOptions,
        acceptBatchObservation,
        rejectBatchObservation,
        batchObservations,
        reopenReason,
        isTaskReview,
        isFromBatch,
        isFromJobId,
        attributes,
      };

      if (hasSavedData) {
        const savedAnno: TtsAnnotationItem = annotations[0]
          .annotation as TtsAnnotationItem;
        // get resource
        let resource = undefined;
        let audioUrl = undefined;
        if (savedAnno.audioResourceId && !isStepReviewJob) {
          let res = await StorageService.getStorageResource(
            savedAnno.audioResourceId
          );
          resource = res.data as StorageResource;
          const urlToUse = resource.path ? resource.path : resource.downloadUrl;
          audioUrl = await StorageService.getUrlWithWorkspaceSasToken(
            urlToUse,
            batch.project.workspaceId
          );
        }
        uiJobCommon = {
          ...uiJobCommon,
          audioUrl,
          resource,
          note: savedAnno.note || "",
          observationId: savedAnno.observationId,
          probability: savedAnno.observationId,
          attributes: savedAnno.attributes,
        };
      }
      setUIJob(uiJobCommon);
    },
    []
  );

  const {
    isLoadingJob,
    savingJobStatus,
    setSavingJobStatus,
    isSavingJob,
    isJobLoaded,
    error,
    setError,
    skipJob,
  } = useLoadComplexJob({
    providerPayload: {
      isLoadFromBatch,
      jobInBatch,
      isTaskReview,
      taskToReview,
      jobIdsIncludedOnly,
      isFromJobId,
      jobIdParam,
    },
    loadJobFromComplexResCallback: loadJobFromComplexRes,
  });

  const validateUIJob = useCallback((data: TextToSpeechJobUIModel) => {
    const errors: { field: string; message: string }[] = [];
    const hasSavedResource = !!data.resource?.resourceId;
    const hasRecordingBlob = !!data.recordedBlob;

    if (!(hasSavedResource || hasRecordingBlob)) {
      errors.push({
        field: "recording",
        message: "Recorded audio is required!",
      });
    }

    const { batchObservations, attributes } = data;

    for (const batchObservation of batchObservations) {
      for (const attr of batchObservation.observation.attributes) {
        if (!attr.required) continue;
        const savedAttribute = attributes?.find((item) => item.id === attr.id);
        if (savedAttribute && savedAttribute.value.length > 0) continue;
        const err = { field: attr.name, message: `${attr.name} is required!` };
        errors.push(err);
      }
    }

    return { errors };
  }, []);
  const saveJob = useCallback(
    async (
      force = false,
      finish = false,
      accept = false,
      reject = false,
      jobId = 0
    ) => {
      if (savingJobStatus === RequestStatus.LOADING) return;
      if (savingJobStatus !== RequestStatus.IDLE && !force) return;
      if (!uiJob) return;

      const { errors } = validateUIJob(uiJob);

      if (errors.length > 0) {
        const message = errors[0].message;
        dispatch(enqueueErrorNotification(message));
        return;
      }

      const id = jobId || uiJob.job?.id;
      setSavingJobStatus(RequestStatus.LOADING);
      try {
        let resource = uiJob.resource;
        if (uiJob.recordedBlob && !uiJob.isStepReviewJob) {
          // have recorded audio
          // get resource
          let res = await StorageService.createStorageResource(
            "Labeling",
            undefined,
            uiJob.project?.workspaceId
          );
          resource = res.data as StorageResource;

          // update to Azure blob
          await StorageService.uploadToAzureBlob(
            uiJob.recordedBlob,
            resource.path
          );

          // process resouce
          await StorageService.processStorageResource(resource.resourceId);
        }

        if ((!resource && !uiJob.isStepReviewJob) || !uiJob.job) {
          dispatch(enqueueErrorNotification(t("common:textSavedFailed")));
          return;
        }

        // save job
        const payload: AnnotationJobRequestDTO = serializeUIJob(
          uiJob,
          resource?.resourceId,
          accept,
          reject
        );

        if (finish) {
          if (accept && uiJob.isStepReviewJob && hasWorkingIssues) {
            throw new AppError(
              "have_working_issues",
              t("labelerworkspace:tts.errorHaveWorkingIssues")
            );
          }

          await AnnotationsService.saveAnnotationsForJobId(id, payload);
          await AnnotationsService.finishJob(id);

          if (uiJob.job && uiJob.isFromBatch) {
            dispatch(deleteComplexJobsJobInBatch(id));
          } else if (uiJob.isFromJobId) {
            history.push(Routes.LABELER_HOME);
          }
        } else {
          await AnnotationsService.saveAnnotationsForJobId(id, payload);
        }

        setSavingJobStatus(RequestStatus.SUCCESS);
        setHasChanged(false);
        dispatch(
          setComplexJobWorkingStatusByJobId({
            jobId: id,
            status: WorkingStatus.SAVED,
          })
        );
        if (finish) {
          dispatch(enqueueSuccessNotification(t("common:textCompleted")));
        } else {
          dispatch(enqueueSuccessNotification(t("common:textSaved")));
        }
      } catch (error: any) {
        Sentry.captureException(error);
        setSavingJobStatus(RequestStatus.FAILURE);
        console.log(error);
        if (error instanceof AppError) {
          dispatch(enqueueErrorNotification(error.message));
          return;
        }
        if (finish) {
          dispatch(enqueueErrorNotification(t("common:textFailed")));
        } else {
          dispatch(enqueueErrorNotification(t("common:textSavedFailed")));
        }
      }
    },
    [
      savingJobStatus,
      uiJob,
      dispatch,
      t,
      hasWorkingIssues,
      history,
      setSavingJobStatus,
      validateUIJob,
    ]
  );

  const serializeUIJob = (
    uiJob: TextToSpeechJobUIModel,
    resourceId: string | undefined,
    accept = false,
    reject = false
  ): AnnotationJobRequestDTO => {
    const annoItem: TtsAnnotationItem = {
      text: uiJob.isStepReviewJob ? "" : uiJob.text,
      note: uiJob.isStepReviewJob ? "" : uiJob.note,
      probability: 1,
      attributes: uiJob.attributes,
    };

    if (resourceId && !uiJob.isStepReviewJob) {
      annoItem.audioResourceId = resourceId;
    }

    if (accept && uiJob.acceptBatchObservation) {
      annoItem.observationId = uiJob.acceptBatchObservation.observation.id;
    } else if (reject && uiJob.rejectBatchObservation) {
      annoItem.observationId = uiJob.rejectBatchObservation.observation.id;
    }

    const requestPayload: AnnotationJobRequestDTO = {
      annotations: [
        {
          observationId:
            !!annoItem.observationId && annoItem.observationId > 0
              ? annoItem.observationId
              : undefined,
          annotation: annoItem,
        },
      ],
    };

    return requestPayload;
  };

  const overwriteUIJob = (
    audioUrl: string | undefined,
    recordedBlob: any | undefined,
    note: string,
    attributes?: AnnotationAttributeItem[]
  ) => {
    if (!uiJob) return;
    if (audioUrl && recordedBlob) {
      setUIJob({
        ...uiJob,
        audioUrl,
        recordedBlob,
        note,
        attributes,
      });
      setHasChanged(true);
      dispatch(setComplexJobWorkingStatus(WorkingStatus.WORKING));
    } else {
      setUIJob({
        ...uiJob,
        audioUrl: undefined,
        recordedBlob: undefined,
        note,
        attributes,
      });
      setHasChanged(false);
    }
  };

  const state = {
    uiJob,
    setUIJob,
    isLoadingJob,
    isSavingJob,
    saveJob,
    skipJob,
    handleConfirmExit,
    error,
    setError,
    hasChanged,
    setHasChanged,
    overwriteUIJob,
  };

  return (
    <TextToSpeechLabelingContext.Provider value={state}>
      {children}
      {(isLoadingJob || isSavingJob) && <MaskLoading />}
      {confirmExit && (
        <ConfirmExitDialog
          visible
          onClose={() => setShowConfirmExit(false)}
          onSubmit={() => history.push(Routes.LABELER_HOME)}
        />
      )}
      {isJobLoaded && showBatchInstruction && uiJob?.batch && uiJob.project && (
        <BatchDetailDialog
          batch={uiJob.batch}
          project={uiJob.project}
          onClose={() => setShowBatchInstruction(false)}
        />
      )}
    </TextToSpeechLabelingContext.Provider>
  );
};

const MaskLoading = () => {
  return (
    <Modal
      open
      closeAfterTransition
      BackdropComponent={Backdrop}
      className="flex items-start justify-center"
    >
      <div className="w-screen h-screen">
        <LinearProgress />
        <div className="flex items-center justify-center w-full h-full"></div>
      </div>
    </Modal>
  );
};
