/*
 * File: text-viewer-menu.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 11th August 2022 2:21:48 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { IconMenu } from "components/common/vb-icon.component";
import { useState, useRef } from "react";
import { useClickAway } from "ahooks";
import { useAppDispatch } from "hooks/use-redux";
import {
  textAnnotationsCleared,
  textAnnotationsReseted,
  textApplyAIAnnotations,
  textApplyImportedAnnotations,
} from "store/labeler/text-workspace/text-workspace.slice";
import { useTextWorkspaceContext } from "pages/labeler/text-labeling/context/text-workspace/text-workspace.context";

export const TextViewerMenu = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const popoverContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLButtonElement>(null);
  const { setShowAnnotationSources } = useTextWorkspaceContext();
  function toggleOpen() {
    setOpen(!open);
  }

  function selectAnnotationsFromOtherSources() {
    setShowAnnotationSources(true);
  }

  function applyImportedAnnotations() {
    dispatch(textApplyImportedAnnotations());
  }

  function applyAIAnnotations() {
    dispatch(textApplyAIAnnotations());
  }

  function resetAllAnnotations() {
    dispatch(textAnnotationsReseted());
  }
  function clearAllAnnotations() {
    dispatch(textAnnotationsCleared());
  }

  const options = [
    {
      text: "Select annotations from other sources",
      onClick: selectAnnotationsFromOtherSources,
    },
    { text: "Apply imported annotations", onClick: applyImportedAnnotations },
    { text: "Apply AI annotations", onClick: applyAIAnnotations },
    { text: "Reset all annotations", onClick: resetAllAnnotations },
    { text: "Clear all annotations", onClick: clearAllAnnotations },
  ];

  useClickAway(() => setOpen(false), [containerRef]);

  return (
    <div>
      <div className="relative z-50" style={{ lineHeight: 0 }}>
        <button ref={containerRef} onClick={toggleOpen}>
          <IconMenu />
        </button>

        {open && (
          <div
            className={`absolute bg-white rounded shadow-lg top-full animate-fade-in-up right-0 leading-normal`}
            ref={popoverContainerRef}
          >
            <div className="py-2">
              {options.map((option) => {
                return (
                  <button
                    key={option.text}
                    className="w-full px-4 py-2 text-left hover:bg-background-100 whitespace-nowrap"
                    onClick={option.onClick}
                  >
                    {option.text}
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
