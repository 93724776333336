import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AnnotationSource } from "domain/labeling/annotation-source";
import { RootState } from "store";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { newUniqueId } from "utilities/number/id-generator";
import { v4 } from "uuid";
import { ImageWorkspaceState } from "../../image-workspace.state";

const THUNK_NAME = "imageWorkspace/acceptVisibleAnnotationsAsync";

interface Payload {
  jobId: number;
}

export const acceptVisibleAnnotationsAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: Payload, { getState }) => {
    const { jobId } = payload;
    const state = getState() as RootState;
    const currentUser = selectCurrentUser(state);
    if (!currentUser) {
      return { annotations: [], relationAnnotations: [] };
    }

    const annotations = state.imageWorkspace.imageAnnotations.annotations;
    const relations = state.imageWorkspace.imageAnnotations.relationAnnotations;

    const newAnnotations = annotations
      .filter(
        (annotation) => annotation.checked && annotation.nextJobId === jobId
      )
      .map((anno) => {
        const uuid = v4();
        return {
          ...anno,
          id: newUniqueId(),
          jobId: jobId,
          uuid: uuid,
          annotator: currentUser.email,
          source: AnnotationSource.CLIENT,
          visible: true,
          locked: false,
          nextJobId: undefined,
          checked: false,
          annotationData: anno.annotationData.map((data) => {
            return { ...data, uuid };
          }),
        };
      });

    const record: { [key: string]: string } = {};

    for (const anno of newAnnotations) {
      record[anno.uuid] = anno.uuid;
    }

    const newRelations = relations
      .filter((anno) => anno.visible && anno.jobId !== jobId)
      .map((item) => {
        const uuid = v4();
        return {
          ...item,
          id: newUniqueId(),
          jobId,
          uuid,
          visible: true,
          locked: false,
          nextJobId: undefined,
          checked: false,
          annotationData: {
            ...item.annotationData,
            uuid,
            measurementIds: item.annotationData.measurementIds.map(
              (measurementId) => record[measurementId]
            ),
          },
          annotator: currentUser.email,
          source: AnnotationSource.CLIENT,
        };
      });

    return {
      jobId,
      annotations: newAnnotations,
      relationAnnotations: newRelations,
    };
  }
);

export function acceptVisibleAnnotationsBuilder(
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) {
  builder.addCase(
    acceptVisibleAnnotationsAsync.fulfilled,
    ({ imageAnnotations }, action) => {
      if (!action.payload) return;
      const { jobId, annotations, relationAnnotations } = action.payload;
      if (annotations && annotations.length > 0) {
        imageAnnotations.annotations =
          imageAnnotations.annotations.concat(annotations);
      }
      if (relationAnnotations && relationAnnotations.length > 0) {
        imageAnnotations.relationAnnotations =
          imageAnnotations.relationAnnotations.concat(relationAnnotations);
      }

      imageAnnotations.annotations = imageAnnotations.annotations
        .filter((anno) => !anno.nextJobId)
        .map((anno) => {
          if (anno.jobId === jobId) {
            return { ...anno, visible: true, locked: false };
          }
          return anno;
        });

      imageAnnotations.relationAnnotations =
        imageAnnotations.relationAnnotations
          .filter((anno) => !anno.nextJobId)
          .map((anno) => {
            if (anno.jobId === jobId) {
              return { ...anno, visible: true, locked: false };
            }
            return anno;
          });
    }
  );
}
