import { Checkbox } from "@material-ui/core";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useDataCleanerTypes } from "hooks/data-cleaning/data-cleaner-types.hook";
import { useAppDispatch } from "hooks/use-redux";
import { VBInput } from "pages/labeler/labeler-profile/components/vb-input.component";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BatchDTO } from "services/label-service/dtos";
import { createDataCleaningJob } from "services/storage/apis/data-cleaning";
import { DataCleaningDTO } from "services/storage/dto/data-cleaning.dto";
import { enqueueErrorNotification, enqueueSuccessNotification } from "store/common/notification/notification.actions";
import * as Sentry from "@sentry/react";

export interface BatchDataCleaningCreateModalProps {
  batch: BatchDTO | null | undefined;
  open: boolean;
  onClose?: () => void;
  onCreated?: (dataCleaningJob: DataCleaningDTO) => void;
}

export const BatchDataCleaningCreateModal = ({
  batch,
  open,
  onClose,
  onCreated,
}: BatchDataCleaningCreateModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {cleanerTypes} = useDataCleanerTypes();
  const pipelineOptions = useMemo(() => {
    return cleanerTypes.map(type => ({
      label: type,
      value: type,
    }));
  }, [cleanerTypes]);
  const [selectedPipeLine, setSelectedPipeLine] = useState("");
  const [name, setName] = useState("");

  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (cleanerTypes.length > 0){
      setSelectedPipeLine(cleanerTypes[0]);
    }
  }, [cleanerTypes])

  if (!batch) return null;

  const handleCreateModalClose = () => {
    onClose && onClose();
  }

  const handleCreateModalSubmit = async () => {
    try {
      if (!batch) return;
      setIsProcessing(true);
      const payload = {
        batchId: batch.id,
        name: name,
        type: selectedPipeLine,
      }
      const res = await createDataCleaningJob(payload);

      onCreated && onCreated(res.data);

      dispatch(enqueueSuccessNotification(t("common:textCreatedSuccess")));
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textCreatedFailed")));
    } finally {
      setIsProcessing(false);
      onClose && onClose();
    }
  }

  return (
    <VBModal
      title={t("project:batchDetails.batchDataCleaning.modalAddTitle")}
      open={open}
      onClose={handleCreateModalClose}
      textSubmit={t("common:buttonRun")}
      onSubmit={handleCreateModalSubmit}
      disableSubmit={isProcessing || !name}
    >
      <div className="flex flex-col gap-2 w-full">
        <VBInput
          label={t("project:batchDetails.batchDataCleaning.createModal.labelName")}
          placeholder={t("project:batchDetails.batchDataCleaning.createModal.labelName")}
          className="focus:ring-primary focus:border-primary"
          value={name}
          onValueChanged={setName}
        />
        <VBSelectComponent
          header={t("project:batchDetails.batchDataCleaning.createModal.labelPipeline")}
          size="xl"
          containerClassName=""
          menuPortalTarget={document.body}
          value={{
            label: selectedPipeLine,
            value: selectedPipeLine,
          }}
          options={pipelineOptions}
          onChange={(option: any) => setSelectedPipeLine(option.value as string)}
        />
        <div>
          <Checkbox
            color="primary"
          />
          <span>
          {t("project:batchDetails.batchDataCleaning.createModal.checkboxConvert")}
          </span>
        </div>
      </div>
    </VBModal>
  )
}