/*
 * File: dataset-grid.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Link, useRouteMatch } from "react-router-dom";
import { DatasetDTO } from "services/label-service/dtos";
import { DatasetCard } from "./dataset-card.component";

interface Props {
  selectedDatasetIds?: number[];
  datasets: DatasetDTO[];
  onSelectInfo?: (dataset: DatasetDTO) => void;
  onDelete?: (dataset: DatasetDTO) => void;
  onSelect?: (dataset: DatasetDTO) => void;
}

export const DatasetGrid = ({
  selectedDatasetIds = [],
  datasets,
  onSelectInfo,
  onDelete,
  onSelect,
}: Props) => {
  const { path } = useRouteMatch();

  return (
    <div className="grid grid-cols-1 gap-6 p-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
      {datasets.map((dataset) => {
        return (
          <Link key={dataset.id} to={`${path}/${dataset.id}`}>
            <DatasetCard
              selected={selectedDatasetIds.includes(dataset.id)}
              dataset={dataset}
              onSelectInfo={onSelectInfo}
              onDelete={onDelete}
              onSelect={onSelect}
            />
          </Link>
        );
      })}
    </div>
  );
};
