import * as cornerstone from "cornerstone-core";
import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { useAppDispatch } from "hooks/use-redux";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Point } from "recharts/types/shape/Curve";
import { createIssueAsync } from "store/common/issue-management/issue-management.thunk";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { classnames } from "utilities/classes";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { IssueCommentModel, IssueModel } from "./issue-management.models";
import { JobOption } from "domain/common/models";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useAppPrevious } from "hooks/use-app-previous";
import { IssueSubmitButton } from "./issue-submit.button";
import * as Sentry from "@sentry/react";


interface Props {
  taskId: string | number,
  isReviewer?: boolean,
  jobOptions: JobOption[],
  newIssue: Partial<IssueModel>;
  width?: number;
  inputWidth?: number;
  onValueChanged?: (v: Partial<IssueModel>) => void;
  onCreated?: () => void;
  cornerstoneElement?: HTMLElement | null;
}
export const IssueCreate = React.forwardRef<any, any>(({
  taskId,
  isReviewer,
  jobOptions,
  newIssue,
  width = 36,
  inputWidth = 200,
  onValueChanged,
  onCreated,
  cornerstoneElement,
}: Props, ref) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [text, setText] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const divRef = useRef<any>();
  const [displayPos , setDisplayPos] = useState<Point>({x: 0, y: 0});
  const inputDisplayPos = useMemo(() => {
    if (!divRef.current) {
      return {x: 0, y: 0};
    }

    let directionX = 50 - displayPos.x;
    directionX /= Math.abs(directionX);

    const parent = divRef.current.parentElement;
    const rect = parent.getBoundingClientRect();
    const gap = 20;
    let offsetX = (gap + width) * directionX / rect.width * 100;
    if (directionX < 0) {
      offsetX = -(inputWidth + gap);
    } else {
      offsetX = gap + width;
    }
    offsetX = offsetX / rect.width * 100;

    const offsetY = -width / rect.height * 100;

    let x = displayPos.x + offsetX;
    let y = displayPos.y + offsetY;

    const maxY = (rect.height - inputWidth - 100) / rect.height * 100;
    if (y > maxY) y = maxY;

    return {x, y};
  }, [displayPos, width, inputWidth]);

  const assigneeOptions = useMemo(() => {
    return jobOptions.map(jobOption => ({
      label: jobOption.assignee,
      value: jobOption,
    }))
  }, [jobOptions]);
  const [selectedJobOption, setSelectedJoboption] = useState<JobOption>();
  const selectJobRef = useRef<any>();
  const previousJobOptions = useAppPrevious(jobOptions);

  const setDisplayPosFromCanvasPos = useCallback((canvasPos: any) => {
    const parent = divRef.current.parentElement;
    const rect = parent.getBoundingClientRect();
    const displayPosX = canvasPos.x / rect.width * 100;
    const displayPosY = canvasPos.y / rect.height * 100;
    setDisplayPos({x: displayPosX, y: displayPosY});
  }, []);

  useEffect(() => {
    if (jobOptions.length === 1 && !previousJobOptions){
      setSelectedJoboption(jobOptions[0]);
      onValueChanged && onValueChanged({...newIssue, jobId: jobOptions[0].jobId});
    }
  }, [jobOptions, newIssue, onValueChanged, previousJobOptions]);
 
  useEffect(() => {
    if (!newIssue.posX || !newIssue.posY) return;

    if (cornerstoneElement) {
      const canvasPos = cornerstone.pixelToCanvas(
        cornerstoneElement, {x: newIssue.posX, y: newIssue.posY}
      );
      setDisplayPosFromCanvasPos(canvasPos);
    } else {
      setDisplayPos({x: newIssue.posX, y: newIssue.posY});
    }

  }, [
    newIssue.posX,
    newIssue.posY,
    cornerstoneElement,
    setDisplayPosFromCanvasPos,
  ]);

  const handleCommentChanged = (v: string) => {
    if (newIssue.comments && newIssue.comments.length > 0) {
      const newComment: IssueCommentModel = {
        ...newIssue.comments[0],
        content: v,
      }

      const newValue: Partial<IssueModel> = {
        ...newIssue,
        comments: [newComment],
      }
      setText(v);
      onValueChanged && onValueChanged(newValue);
    }
  }

  const submit = async () => {
    try {
      if (!selectedJobOption) {
        (selectJobRef.current as any).style.animation = "shake 0.5s";
        return;
      }

      if (isProcessing) return;
      setIsProcessing(true);
      const res = await dispatch(createIssueAsync(newIssue));
      handleThunkRejected(res);
      onCreated && onCreated();
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsProcessing(false);
    }
  }

  const handleKeydown = (e: any) => {
    if (e.key === KeyboardKey.Enter && !e.shiftKey) {
      e.preventDefault();
      submit();
    }
  }

  const handleAnimationEnded = (e: any) => {
    e.target.style.animation = "";
  }

  return (
    <Fragment>
      <div
        ref={divRef}
        className={classnames(
          "absolute shadow-lg flex items-center justify-center transform -translate-y-full",
          {"bg-primary": !isProcessing},
          {"bg-white": isProcessing},
        )}
        style={{
          zIndex: "101",
          left: `${displayPos.x}%`,
          top: `${displayPos.y}%`,
          borderRadius: "100% 100% 100% 0",
          width: `${width}px`,
          height: `${width}px`,
        }}
      >
        {
          isProcessing &&
          <VBInlineLoading spinnerClass="text-primary" />
        }
      </div>
      {
        !isProcessing &&
        <div
          ref={ref}  
          className={classnames(
            "absolute bg-white rounded border-0 flex flex-col justify-center text-black",
            {"p-2": !!text}
          )}
          style={{
            zIndex: "101",
            left: `${inputDisplayPos.x}%`,
            top: `${inputDisplayPos.y}%`,
          }}
          onAnimationEnd={handleAnimationEnded}
        >
          <div className="relative flex">
            <textarea
              className={classnames(
                "rounded p-2 text-sm resize-none",
                {"border border-background-300": !!text}
              )}
              placeholder="Add a comment"
              rows={text ? 3 : 1}
              value={text}
              onChange={(e) => handleCommentChanged(e.target.value as string)}
              onKeyDown={handleKeydown}
              style={{
                width: `${inputWidth}px`,
              }}
            />
            <IssueSubmitButton
              isActive={!!text}
              onSubmit={submit}
            />
          </div>
          {
            text &&
            <div
              ref={selectJobRef}
              className="flex flex-col w-full gap-2 mt-2 text-sm"
              onAnimationEnd={handleAnimationEnded}
              style={{
                maxWidth: `${inputWidth}px`,
              }}
            >
              <span>Assign to:</span>
              <VBSelectComponent
                options={assigneeOptions}
                value={assigneeOptions.find(op => op.value.jobId === selectedJobOption?.jobId)}
                onChange={(option: any) => {
                  if (option) {
                    setSelectedJoboption(option.value);
                    onValueChanged && onValueChanged({...newIssue, jobId: option.value.jobId});
                  } else {
                    setSelectedJoboption(undefined);
                  }
                }}
                menuPortalTarget={document.body}
                onKeyDown={handleKeydown}
              />
            </div>
          }
        </div>
      }
    </Fragment>
  );
})