/*
 * File: editor-settings.component.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 16th April 2022 11:58:52 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Switch } from "@material-ui/core";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { selectBatchLabelingIsPollByGroupProject } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import {
  selectIsAutoSaveConfig,
  selectIsCompleteJobsInBatch,
  selectIsCompleteJobsInView,
  selectIsShowAnnotationToolbar,
  selectIsShowBBoxRuer,
  selectIsShowTaskInfo,
  selectIsShowWarningPopup,
} from "store/labeler/image-workspace/editor-setting/editor-setting.selectors";
import {
  annotationToolbarDisplayToggled,
  autoSaveTogged,
  bboxRulerDisplayToggled,
  completeJobsInBatchToggled,
  completeJobsInViewToggled,
  setCompleteJobsInBatch,
  taskInfoDisplayToggled,
  warningPopupToggled,
} from "store/labeler/image-workspace/editor-setting/editor-setting.slice";
interface Props {
  open: boolean;
  onClose(): void;
}
export function EditorSettingsModal({ onClose }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isPollByGroupProject = useAppSelector(
    selectBatchLabelingIsPollByGroupProject
  );

  const isAutoSaveConfig = useAppSelector(selectIsAutoSaveConfig);
  const isShowBBoxRuler = useAppSelector(selectIsShowBBoxRuer);
  const isShowTaskInfo = useAppSelector(selectIsShowTaskInfo);
  const isShowAnnotationTollbar = useAppSelector(selectIsShowAnnotationToolbar);
  const isCompleteJobsInView = useAppSelector(selectIsCompleteJobsInView);
  const isCompleteJobsInBatch = useAppSelector(selectIsCompleteJobsInBatch);
  const isShowWarningPopup = useAppSelector(selectIsShowWarningPopup);

  function toggleShowBBoxRuler() {
    dispatch(bboxRulerDisplayToggled());
  }

  function toggleShowTaskInfo() {
    dispatch(taskInfoDisplayToggled());
  }

  function toggleAnnotationToolbar() {
    dispatch(annotationToolbarDisplayToggled());
  }

  function toggleCompleteJobsInView() {
    dispatch(completeJobsInViewToggled());
  }

  function toggleCompleteJobsInBatch() {
    dispatch(completeJobsInBatchToggled());
  }

  function toggleWarningPopup() {
    dispatch(warningPopupToggled())
  }

  function toggleAutoSaveConfig() {
    dispatch(autoSaveTogged());
  }

  useEffect(() => {
    if (isPollByGroupProject) {
      dispatch(setCompleteJobsInBatch(true));
    } else {
      dispatch(setCompleteJobsInBatch(false));
    }
  }, [isPollByGroupProject, dispatch]);

  return (
    <VBModal
      open
      onClose={onClose}
      title={"Editor settings"}
      footerHidden={true}
      width="40rem"
    >
      <div style={{ maxHeight: "60vh" }}>
        <div className="flex items-center gap-4">
          <div className="flex-auto py-2">
            {t("labelerworkspace:editorSetting.autoSave")}
          </div>
          <Switch
            color="primary"
            checked={isAutoSaveConfig}
            onClick={toggleAutoSaveConfig}
          />
        </div>
        <div className="flex items-center gap-4">
          <div className="flex-auto py-2">
            {t("labelerworkspace:editorSetting.darkTheme")}
          </div>
          <Switch color="primary" checked />
        </div>
        <div className="flex items-center gap-4">
          <div className="flex-auto py-2">
            {t(
              "labelerworkspace:editorSetting.showRulerWhenUsingBoundingBoxTool"
            )}
          </div>
          <Switch
            color="primary"
            checked={isShowBBoxRuler}
            onClick={toggleShowBBoxRuler}
          />
        </div>
        <div className="flex items-center gap-4">
          <div className="flex-auto py-2">
            {t("labelerworkspace:editorSetting.showTaskInfoOnImage")}
          </div>
          <Switch
            color="primary"
            checked={isShowTaskInfo}
            onClick={toggleShowTaskInfo}
          />
        </div>
        <div className="flex items-center gap-4">
          <div className="flex-auto py-2">
            {t("labelerworkspace:editorSetting.showAnnotationToolbarOnImage")}
          </div>
          <Switch
            color="primary"
            checked={isShowAnnotationTollbar}
            onClick={toggleAnnotationToolbar}
          />
        </div>
        <div className="flex items-center gap-4">
          <div className="flex-auto py-2">
            {t(
              "labelerworkspace:editorSetting.showWarningPopup"
            )}
          </div>
          <Switch
            color="primary"
            checked={isShowWarningPopup}
            onClick={toggleWarningPopup}
          />
        </div>
        <div className="flex items-center gap-4">
          <div className="flex-auto py-2">
            {t("labelerworkspace:editorSetting.completeAllJobsInView")}
          </div>
          <Switch
            color="primary"
            checked={isCompleteJobsInView}
            onClick={() => toggleCompleteJobsInView()}
            disabled
          />
        </div>
        <div className="flex items-center gap-4">
          <div className="flex-auto py-2">
            {t("labelerworkspace:editorSetting.completeAllJobsInMicroBatch")}
          </div>
          <Switch
            color="primary"
            checked={isCompleteJobsInBatch}
            onClick={() => toggleCompleteJobsInBatch()}
            disabled
          />
        </div>
      </div>
    </VBModal>
  );
}
