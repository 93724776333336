import {
  AnnotationContextMenuAction,
  ContextMenuItemData,
  ImageContextMenuAction,
} from "components/common/context-menu/context-menu-item.component";
import { AnnotateType } from "constants/annotation.constant";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { LabelOption } from "pages/labeler/image-labeling/components/annotation-class-menu/annotation-class-menu.component";
import { useImageLabelingContext } from "pages/labeler/image-labeling/context/image-labeling.context";
import { useImageEditorContext } from "pages/labeler/image-labeling/image-editor-context/image-editor.context";
import { MouseEvent } from "react";
import { selectImageLabelingContextMenuByJobId } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import {
  imageLabelingContextMenuClosed,
  imageLabelingContextMenuOpened,
} from "store/labeler/image-workspace/batch-labeling/batch-labeling.slice";
import { selectActiveCornerstoneTool } from "store/labeler/image-workspace/dicom-editor/dicom-editor.selectors";
import {
  setActiveCornerstoneTool,
  freehandStrategyChanged,
} from "store/labeler/image-workspace/dicom-editor/dicom-editor.slice";
import {
  selectImageAnnotationAttributeMenu,
  selectImageAnnotationClassMenu,
  selectImageAnnotationContextMenu,
} from "store/labeler/image-workspace/image-annotations/image-annotations.selectors";
import { imageAnnotationDownVotedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-annotation-downvoted.thunk";
import { imageAnnotationVotedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-annotation-voted.thunk";
import { imageJobAnnotationsResetedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-job-annotations-reseted.thunk";
import { selectImageLabelingLabels } from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import {
  imageAnnotationAttributeMenuOpened,
  imageAnnotationClassChanged,
  imageAnnotationClassMenuClosed,
  imageAnnotationClassMenuOpened,
  imageAnnotationContextMenuClosed,
  imageAnnotationContextMenuOpened,
  observationAnnotationSelected,
} from "store/labeler/image-workspace/image-workspace.slice";
import { ToolName } from "../dicom-tools/dicom-tools.model";

export default function useAnnotationContextMenu(jobId: number) {
  const dispatch = useAppDispatch();
  const { setShowPreviousJobAnnotations } = useImageLabelingContext();
  const { cornerstoneHandler } = useImageEditorContext();
  const activeTool = useAppSelector(selectActiveCornerstoneTool);
  const annotationContextMenu = useAppSelector(
    selectImageAnnotationContextMenu
  );
  const annotationClassMenu = useAppSelector(selectImageAnnotationClassMenu);
  const annotationAttributeMenu = useAppSelector(
    selectImageAnnotationAttributeMenu
  );
  const imageContextMenu = useAppSelector(
    selectImageLabelingContextMenuByJobId(jobId)
  );
  const labels = useAppSelector(selectImageLabelingLabels);

  function onViewportContextMenu(event: MouseEvent) {
    if (activeTool === ToolName.MultiArrowConnection) return;
    if (event.ctrlKey || event.altKey || event.button !== 2) return;
    const annotation = cornerstoneHandler.current?.getActiveMeasurement();
    if (ToolName.MultiArrowConnection === annotation?.type) return;
    if (annotation) {
      dispatch(imageAnnotationContextMenuOpened(annotation.uuid));
    } else {
      imageContextMenuOpened(event);
    }
    event.stopPropagation();
    event.preventDefault();
  }

  function handleCloseImageContextMenu() {
    if (imageContextMenu) dispatch(imageLabelingContextMenuClosed());
  }

  function imageContextMenuOpened(event: MouseEvent) {
    const viewport = getViewPortElement(event);
    if (!viewport) return;
    const bounding = viewport.getBoundingClientRect();
    dispatch(
      imageLabelingContextMenuOpened({
        jobId,
        position: {
          x: event.clientX - bounding.left,
          y: event.clientY - bounding.top,
        },
      })
    );
  }

  function getViewPortElement(event: MouseEvent) {
    let parent = event.target as HTMLElement;
    while (parent && !parent.classList.contains("viewport-element")) {
      parent = parent.parentElement as HTMLElement;
    }
    if (parent && parent.classList.contains("viewport-element")) {
      return parent;
    }
    return null;
  }

  function handleCloseAnnotationContextMenu() {
    dispatch(imageAnnotationContextMenuClosed());
  }

  function handleCloseAnnotationClassMenu() {
    dispatch(imageAnnotationClassMenuClosed());
  }

  function imageContextMenuSelected(option: ContextMenuItemData) {
    if (option.value === ImageContextMenuAction.CLEAR_ANNOTATIONS) {
      dispatch(imageJobAnnotationsResetedAsync({ jobId }));
    } else if (option.value === ImageContextMenuAction.PICK_ANNOTATIONS) {
      setShowPreviousJobAnnotations(true);
    }
    handleCloseImageContextMenu();
  }

  function imageAnnotationContextMenuSelected(option: ContextMenuItemData) {
    if (!annotationContextMenu) return;
    const uuid = annotationContextMenu.annotation.uuid;
    if (option.value === AnnotationContextMenuAction.ADD_POLYGON) {
      const annotation = annotationContextMenu.annotation;
      dispatch(observationAnnotationSelected(annotation.labelId));
      if (annotation.annotationData[0].type === AnnotateType.BOUNDING_BOX) {
        dispatch(setActiveCornerstoneTool(ToolName.RectangleRoiExtend));
      } else if (annotation.annotationData[0].type === AnnotateType.POLYGON) {
        dispatch(setActiveCornerstoneTool(ToolName.FreehandRoiExtend));
      }
      dispatch(freehandStrategyChanged(option.value));
      cornerstoneHandler.current?.modifyPolygon(uuid, "add");
    } else if (option.value === AnnotationContextMenuAction.SUBTRACT_POLYGON) {
      const annotation = annotationContextMenu.annotation;
      dispatch(observationAnnotationSelected(annotation.labelId));
      if (annotation.annotationData[0].type === AnnotateType.BOUNDING_BOX) {
        dispatch(setActiveCornerstoneTool(ToolName.RectangleRoiExtend));
      } else if (annotation.annotationData[0].type === AnnotateType.POLYGON) {
        dispatch(setActiveCornerstoneTool(ToolName.FreehandRoiExtend));
      }
      dispatch(freehandStrategyChanged(option.value));
      cornerstoneHandler.current?.modifyPolygon(uuid, "subtract");
    } else if (option.value === AnnotationContextMenuAction.DEFAULT_PEN) {
      dispatch(freehandStrategyChanged(""));
      cornerstoneHandler.current?.modifyPolygon("", "");
    } else if (option.value === AnnotationContextMenuAction.CHANGE_ATTRIBUTES) {
      dispatch(imageAnnotationAttributeMenuOpened(uuid));
    } else if (option.value === AnnotationContextMenuAction.CHANGE_CLASS) {
      dispatch(imageAnnotationClassMenuOpened(uuid));
    } else if (option.value === AnnotationContextMenuAction.DELETE) {
      cornerstoneHandler.current?.deleteActiveAnnotation();
    } else if (option.value === AnnotationContextMenuAction.VOTE) {
      dispatch(imageAnnotationVotedAsync({ annotationId: uuid }));
    } else if (option.value === AnnotationContextMenuAction.DOWN_VOTE) {
      dispatch(imageAnnotationDownVotedAsync({ annotationId: uuid }));
    }
    dispatch(imageAnnotationContextMenuClosed());
  }

  function imageAnnotationClassMenuSelected(option: LabelOption) {
    if (!annotationClassMenu) return;
    const fromLabel = labels.find(
      (lb) => lb.id === annotationClassMenu.annotation.labelId
    );
    const toLabel = labels.find((lb) => lb.id === option.id);
    if (!fromLabel || !toLabel) return;
    const payload = {
      annotationUUID: annotationClassMenu.annotation.uuid,
      fromLabel,
      toLabel,
    };
    dispatch(imageAnnotationClassChanged(payload));
  }

  return {
    annotationAttributeMenu,
    annotationContextMenu,
    annotationClassMenu,
    imageContextMenu,

    onViewportContextMenu,
    handleCloseImageContextMenu,
    handleCloseAnnotationClassMenu,
    handleCloseAnnotationContextMenu,
    imageContextMenuSelected,
    imageAnnotationContextMenuSelected,
    imageAnnotationClassMenuSelected,
  };
}
