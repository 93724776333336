import { isValidRectangle } from "./fabric-object.utils";
import { FabricObjectToolType } from "./fabric.models";
import * as jsts from "libs/jsts@1.6.1";

const geometryFactory = new jsts.geom.GeometryFactory();

export const fabricObjectToJstsGeometry = (object) => {
  let geometry = undefined;

  if (object.type === FabricObjectToolType.DRAWN_POLYGON) {
    if (!object.points || object.points.length < 3) return undefined;

    const coordinates = object.points.map(
      (p) => new jsts.geom.Coordinate(p.x, p.y)
    );
    const firstCoordinate = coordinates[0];
    // to make sure a closed ring
    coordinates.push(
      new jsts.geom.Coordinate(firstCoordinate.x, firstCoordinate.y)
    );
    geometry = geometryFactory.createPolygon(coordinates);
  }

  if (object.type === FabricObjectToolType.DRAWN_RECTANGLE) {
    if (!isValidRectangle(object)) return undefined;
    const left = object.left;
    const top = object.top;
    const w = object.width * object.scaleX;
    const h = object.height * object.scaleY;

    const coordinates = [
      new jsts.geom.Coordinate(left, top),
      new jsts.geom.Coordinate(left + w, top),
      new jsts.geom.Coordinate(left + w, top + h),
      new jsts.geom.Coordinate(left, top + h),
      new jsts.geom.Coordinate(left, top),
    ];

    geometry = geometryFactory.createPolygon(coordinates);
  }

  if (geometry) {
    geometry.setUserData({ objectRef: object });
    object.data.geometryRef = geometry;
  }

  return geometry;
};
