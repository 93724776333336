/*
 * File: drag-cursor.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 19th September 2022 9:22:32 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useMemo } from "react";
import { Point } from "utilities/math/point";

const WIDTH = 2;
const HEIGHT = 30;
const COLOR = "#328fff";
interface Props {
  position: Point;
  from: string;
  to: string;
  direction: "left" | "right";
}
export default function DragCursor({ position, from, to, direction }: Props) {
  const id = useMemo(() => {
    return direction === "left" ? "left-cursor" : "right-cursor";
  }, [direction]);

  const offset = useMemo(() => {
    if (direction === "left") {
      return { x: -2, y: -6 };
    }
    return { x: 0, y: 0 };
  }, [direction]);

  const cursorOffset = useMemo(() => {
    if (direction === "left") {
      return { x: -2, y: -6 };
    }
    return { x: 0, y: HEIGHT };
  }, [direction]);


  return (
    <g transform={`translate(${position.x},${position.y})`} className="z-50">
      <circle
        id={id}
        data-from={from}
        data-to={to}
        className="z-50 cursor-pointer"
        cx={cursorOffset.x + WIDTH / 2}
        cy={cursorOffset.y + WIDTH / 2}
        r={2 * WIDTH}
        fill={COLOR}
      />
      <rect
        className="cursor-pointer pointer-events-none"
        x={offset.x}
        y={offset.y}
        width={WIDTH}
        height={HEIGHT}
        fill={COLOR}
      />
    </g>
  );
}
