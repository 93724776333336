/*
 * File: mammography-task.state.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 21st September 2022 4:21:42 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export interface MammographyTaskState {
  isFullScreen: boolean;
  toggleDisplayFullScreen: () => void;
  onClose(): void;
}

export const defaultMammographyTaskState: MammographyTaskState = {
  isFullScreen: false,
  toggleDisplayFullScreen: () => {},
  onClose: () => {},
};
