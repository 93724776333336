import { ColumnHeaderWrapper } from "components/common/vb-grid/column-wrapper.component";
import { SearchableColumnHeader } from "components/common/vb-grid/searchable-column-header.component";
import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { LabelingType } from "constants/labeling.constant";
import { FilterCommon } from "domain/common/filter"
import { Fragment, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BatchObservationService, IndividualService } from "services/label-service";
import { BatchObservationDTO } from "services/label-service/dtos";
import { AnnotationLabelerReport } from "services/label-service/dtos/individual.dto";
import { classnames } from "utilities/classes";
import { LabelerReportByProjectRow } from "../models/labeler-report.models"
import * as Sentry from "@sentry/react";

interface Props {
  rows: LabelerReportByProjectRow[];
  filter: FilterCommon;
  setFilterField: (field: keyof FilterCommon, value: any) => void;
  isLoading?: boolean;
  fromDate: Date;
  toDate: Date;
}

export const LabelerReportByProjectTable = ({
  rows,
  filter,
  setFilterField,
  isLoading = false,
  fromDate,
  toDate,
}: Props) => {
  const { t } = useTranslation();
  const columnNamesMap: Record<string, string> = {
    projectType: t("labelerhome:individualReport.table.headerTyoe"),
    projectName: t("labelerhome:individualReport.table.headerProject"),
    batchName: t("labelerhome:individualReport.table.headerBatch"),
    startDate: t("labelerhome:individualReport.table.headerStartDate"),
    endDate: t("labelerhome:individualReport.table.headerFinishDate"),
    completedTaskCount: t("labelerhome:individualReport.table.headerCompletedTaskCount"),
    completedAnnotationCount: t("labelerhome:individualReport.table.headerCompletedAnnotationCount"),
    finished: t("labelerhome:individualReport.table.headerFinished"),
    noResult: t("labelerhome:individualReport.table.headerNoResult"),
    f1Score: t("labelerhome:individualReport.table.headerF1Score"),
    specificity: t("labelerhome:individualReport.table.headerSpecificity"),
    sensitivity: t("labelerhome:individualReport.table.headerSensitivity"),
    iou: t("labelerhome:individualReport.table.headerAvgIoU"),
  };
  const columns: string[] = Object.keys(columnNamesMap);

  const [viewRow, setViewRow] = useState<LabelerReportByProjectRow>();

  const handleClickRow = (row: LabelerReportByProjectRow) => {
    setViewRow(row);
  }

  return (
    <Fragment>
      <div className="w-0" style={{ flexBasis: "0" }}>
        <table className="min-w-full text-sm vb-table">
          <thead>
            <tr>
              {
                columns.map(col => {
                  return (
                    <ColumnHeaderWrapper
                      key={col}
                      minWidth={150}
                      first={col === "projectType"}
                      last={col === "iou"}
                    >
                      <SearchableColumnHeader
                        containerClassName={classnames(
                          "w-full py-2 text-sm",
                          { "pl-4": col !== "projectType" }
                        )}
                        clearInput
                        searchIcon={false}
                        placeholder={""}
                        header={columnNamesMap[col]}
                        containerWidth={"100%"}
                        type="text"
                        showSearch={false}
                        sortable
                        onSortChange={(sortType) =>
                          setFilterField(
                            "sort",
                            FilterCommon.getSortValue(sortType, col)
                          )
                        }
                        sortType={FilterCommon.getSortType(filter.sort, col)}
                      />
                    </ColumnHeaderWrapper>
                  )
                })
              }
            </tr>
          </thead>
          <tbody className="relative">
            {!isLoading && rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="relative h-10 hover:bg-secondary-50 parent cursor-pointer"
                  onClick={_ => handleClickRow(row)}
                >
                  {
                    columns.map(col => {
                      const format = (field: string, value: any) => {
                        const type = typeof value;
                        if (type === "number") return value.toFixed(2);
                        if (["startDate", "endDate"].includes(field)) {
                          return t("common:formattedDate", { date: value as Date });
                        }
                        return value;
                      }

                      return (
                        <td key={col} className="pl-4 pr-4" style={{ minWidth: 150 }}>{format(col, (row as any)[col])}</td>
                      )
                    })
                  }
                </tr>
              );
            })}
            {
              isLoading &&
              <tr>
                <td colSpan={columns.length} className="relative">
                  <div className="absolute w-screen">
                    <VBInlineLoading />
                  </div>
                </td>
              </tr>
            }

            <tr className="absolute top-0 left-0 w-full h-full border rounded pointer-events-none border-background-300"></tr>
          </tbody>
        </table>
      </div>

      {
        viewRow && viewRow.projectType === LabelingType.MED_MDI_SEGMENTATION &&
        <LabelerReportMdiSegmentationAnnotationsReport
          fromDate={fromDate}
          toDate={toDate}
          batchId={viewRow.batchId}
          batchName={viewRow.batchName}
          projectName={viewRow.projectName}
          projectType={viewRow.projectType}
          onClose={() => setViewRow(undefined)}
        />
      }
    </Fragment>
  )
}

// List all mask and mask (tumor) size in a batch
interface LabelerReportMdiSegmentationAnnotationsReportRow {
  labelName: string;
  size: number;
}
interface LabelerReportMdiSegmentationAnnotationsReportProps {
  fromDate: Date;
  toDate: Date;
  batchId: number;
  batchName: string;
  projectName: string;
  projectType: string;
  onClose?: () => void;
}
export const LabelerReportMdiSegmentationAnnotationsReport = ({
  fromDate,
  toDate,
  batchId,
  batchName,
  projectName,
  projectType,
  onClose,
}: LabelerReportMdiSegmentationAnnotationsReportProps) => {

  const [rows, setRows] = useState<LabelerReportMdiSegmentationAnnotationsReportRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    const loadData = async () => {
      setIsLoading(true);
      try {
        let res = await BatchObservationService.getItems({
          batchId: batchId.toString(),
        });
        const labels = (res.data as BatchObservationDTO[]).map(bo => ({
          id: bo.observation.id,
          name: bo.observation.name,
        }));

        res = await IndividualService.getAnnotationReport(
          fromDate,
          toDate,
          batchId,
        );
        const newRows: LabelerReportMdiSegmentationAnnotationsReportRow[] = (res.data as AnnotationLabelerReport[]).map(annoReport => {
          const label = labels.find(l => l.id === annoReport.observationId);
          return {
            labelName: label ? label.name : "",
            size: annoReport.size,
          }
        });
        setRows(newRows);
      } catch (error: any) {
        Sentry.captureException(error);
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    loadData();

  }, [batchId, fromDate, toDate]);

  return (
    <VBModal
      width="40rem"
      open={true}
      title={`Project: ${projectName} - Batch: ${batchName}`}
      footerHidden
      onClose={onClose}
    >
      <div className="max-h-80 flex flex-col gap-2 overflow-y-auto">
        {
          isLoading && <span>Loading...</span>
        }
        {
          !isLoading &&
          <Fragment>
            <span className="font-bold">Total: {rows.length}</span>
            {
              rows.length === 0 && <span>No row!</span>
            }
            {
              rows.length > 0 &&
              <table>
                <thead>
                  <tr className="font-bold">
                    <td>Index</td>
                    <td>Label</td>
                    <td>Estimated size (mm)</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    rows.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{row.labelName}</td>
                          <td>{`${row.size.toFixed(2)}`}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>


            }
          </Fragment>
        }
      </div>
    </VBModal>
  )
}
