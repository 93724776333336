/*
 * File: use-exported-dataset.hook.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 1st December 2022 10:35:33 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useEffect, useState } from "react";
import { QueryService } from "services/query/query.service";
import { countScopeFiles } from "services/storage/apis/count-scope-files";
import useDeepCompareEffect from "use-deep-compare-effect";
import { ImageWithAnnotationItem } from "../../experiment-detail/run-info-image-items.component";
import useExportedDatasetFilter from "./use-exported-dataset-filter.hook";
import * as Sentry from "@sentry/react";

export default function useExportedDataset(datasetId: number) {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<ImageWithAnnotationItem[]>([]);
  const [totalItem, setTotalItem] = useState(0);
  const { filter, setFilterParam, setFilterParams } =
    useExportedDatasetFilter();
  const [scopeFiles, setScopeFiles] = useState<Record<string, number>>({});
  useDeepCompareEffect(() => {
    async function loadFiles() {
      if (!datasetId) return;
      try {
        setLoading(true);
        const params: Record<string, string> = {
          datasetId: datasetId.toString(),
          page: (filter.page - 1).toString(),
          size: filter.size.toString(),
          sort: "createdDate,desc",
          scopeId: filter.scope.toString(),
        };
        if (filter.scope === -1) {
          delete params["scopeId"];
        }
        const res = await QueryService.getAllFiles(params);
        setTotalItem(res.totalCount);
        const items: ImageWithAnnotationItem[] = [];
        for (const fileInfo of res.items) {
          if (
            fileInfo.additionalFileInfo &&
            fileInfo.additionalFileInfo.addition
          ) {
            const addition = fileInfo.additionalFileInfo.addition;
            const image = {
              width: addition.width,
              height: addition.height,
              thumbnailUrl: addition.thumbnailUrl,
              url: fileInfo.url,
            };
            const item: ImageWithAnnotationItem = {
              name: fileInfo.fileName,
              mediaId: fileInfo.id,
              taskData: {
                image,
                annotations: [],
              },
            };
            items.push(item);
          }
        }
        setItems(items);
        setLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        setLoading(false);
      }
    }
    loadFiles();
  }, [datasetId, filter]);

  useEffect(() => {
    async function loadScopeFiles() {
      try {
        const response = await countScopeFiles(datasetId);
        setScopeFiles(response.data);
      } catch (error) {Sentry.captureException(error);}
    }
    loadScopeFiles();
  }, [datasetId]);

  return {
    scopeFiles,
    loading,
    totalItem,
    items,
    filter,
    setFilterParam,
    setFilterParams,
  };
}
