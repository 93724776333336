/*
 * File: vb-modal.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 24th November 2021 3:51:32 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Backdrop, CircularProgress, Grow } from "@material-ui/core";
import { MatModal } from "components/material/mat-modal.component";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { classnames } from "utilities/classes";
import { IconCloseCircle } from "../vb-icon.component";

export interface AdditionalButtonOption {
  label: string;
  value: string;
}
export interface VBModalProps {
  title: string;
  headerActions?: ReactNode;
  caption?: string;
  open: boolean;
  width?: string;
  onClose?: () => void;
  onSubmit?: (payload?: any) => void;
  children: JSX.Element;
  textClose?: string;
  textSubmit?: string;
  disableSubmit?: boolean;
  footerHidden?: boolean;
  className?: string;
  containerClassName?: string;
  disableCloseButton?: boolean;
  blockUI?: boolean;
  processingIndicator?: boolean;
  additionalButtons?: AdditionalButtonOption[];
  onAdditionalButtonClick?: (option: AdditionalButtonOption) => void;
  disabledAdditionalButtons?: boolean;
}

export const VBModal = ({
  width = "26.5rem",
  open,
  title,
  headerActions,
  caption,
  onClose,
  onSubmit,
  children,
  textClose,
  textSubmit,
  disableCloseButton,
  className = "",
  containerClassName = "",
  footerHidden,
  disableSubmit = false,
  blockUI = false,
  processingIndicator = false,
  additionalButtons = [],
  onAdditionalButtonClick,
  disabledAdditionalButtons = false,
}: VBModalProps) => {
  const { t } = useTranslation();
  textSubmit = textSubmit || t("common:buttonCreate");
  textClose = textClose || t("common:buttonCancel");

  return (
    <MatModal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={onClose}
      disableBackdropClick
      className={classnames(
        `flex items-center justify-center outline-none ${className}`
      )}
    >
      <Grow in={open}>
        <div
          style={{ maxWidth: width, maxHeight: "100vh" }}
          className={classnames(
            "flex flex-col w-full gap-6 py-6 bg-white rounded outline-none",
            { "pointer-events-none": !!blockUI },
            containerClassName
          )}
        >
          <div className="flex items-center flex-none gap-1 px-6">
            <div className="flex-auto">
              <div className="flex items-center gap-4">
                <h2 className="text-lg font-bold text-primary">{title}</h2>
                {headerActions}
              </div>
              {caption && (
                <p className="mt-1 text-background-500 text-caption-large">
                  {caption}
                </p>
              )}
            </div>
            <button onClick={onClose} className="flex-none">
              <IconCloseCircle className="w-6 h-6" />
            </button>
          </div>
          <main className="flex-auto px-6 overflow-y-auto">{children}</main>

          {!footerHidden && (
            <div className="flex items-center justify-end flex-none gap-4 px-6">
              {additionalButtons.map((option) => (
                <button
                  key={option.value}
                  className="button-text-secondary"
                  onClick={() =>
                    onAdditionalButtonClick && onAdditionalButtonClick(option)
                  }
                  disabled={disabledAdditionalButtons}
                >
                  {option.label}
                </button>
              ))}
              {!disableCloseButton && (
                <button onClick={onClose} className="button-text-secondary">
                  {textClose}
                </button>
              )}
              <button
                disabled={disableSubmit}
                onClick={onSubmit}
                className={classnames("button-text-primary relative", {
                  "cursor-not-allowed opacity-60":
                    disableSubmit && !processingIndicator,
                })}
              >
                {!processingIndicator && textSubmit}
                {processingIndicator && (
                  <div className="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full">
                    <CircularProgress size={18} color="inherit" />
                  </div>
                )}
              </button>
            </div>
          )}
        </div>
      </Grow>
    </MatModal>
  );
};
