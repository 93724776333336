/*
 * File: use-reopen-task.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 2nd June 2023 10:47:56 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useAppDispatch } from "hooks/use-redux";
import { useCallback, useState } from "react";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { reopenTaskAsync } from "store/customer/batch/batch.thunk";
import { getErrMessage } from "utilities/errors/errors";
import { handleThunkRejected } from "utilities/redux/redux.utils";

interface TaskActionPayload {
  taskId: number;
  jobIds: number[];
  reason: string;
}

export function useReopenTask() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const reopenTask = useCallback(
    async (payload: TaskActionPayload) => {
      try {
        if (loading) return;
        setLoading(true);
        const response = await dispatch(reopenTaskAsync(payload));
        handleThunkRejected(response);
        setLoading(false);
        dispatch(enqueueSuccessNotification("Success"));
      } catch (err: any) {
        const message = getErrMessage(err, "Failed to reopen task");
        dispatch(enqueueErrorNotification(message));
        setLoading(false);
      }
    },
    [dispatch, loading]
  );

  return { loading, reopenTask };
}

export default useReopenTask;
