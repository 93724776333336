/*
 * File: main-layout.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:12 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { HeaderComponent } from "../components/header/header.component";
import { useKeycloakContext } from "contexts/keycloak/keycloak.context";
import { useAppSelector } from "hooks/use-redux";
import { Routes } from "routers/config/routes";
import { selectUserInfo, selectUserRole } from "store/auth/auth.selectors";
import { UserRole } from "store/auth/auth.state";
import { selectNumberOfUnreadNotifications } from "store/common/user-notification/user-notification.selectors";
import { useLayoutContext } from "contexts/layout";
import { Sidebar } from "components/design-system/sidebar/sidebar.component";
import { makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { useSidebar } from "hooks/use-sidebar";
import { useMemo } from "react";
import { routeMatch } from "hooks/use-route-match.hook";
import { useHistory } from "hooks/use-history";
import { IconMessageQuestion } from "components/common/vb-icon.component";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";

interface MainLayoutProps {
  children: JSX.Element;
}

const useStyles = makeStyles((theme) => ({
  toolbar: { ...theme.mixins.toolbar },
}));

export function MainLayout({ children }: MainLayoutProps) {
  const history = useHistory();
  const userInfo = useAppSelector(selectUserInfo);
  const currentRole = useAppSelector(selectUserRole);
  const unreadNotifications = useAppSelector(selectNumberOfUnreadNotifications);
  const { signOut, switchRole } = useKeycloakContext();
  const { showNavigationBar } = useLayoutContext();
  const { isProjectDetailPath, isBatchDetailPath, sidebarItems } = useSidebar();
  const handleViewProfile = () => {
    history.push(Routes.PROFILE);
  };
  const { openModal } = useModalContext();
  const classes = useStyles();

  const titleBack = useMemo(() => {
    if (isBatchDetailPath) return "Back To Batch List";
    if (isProjectDetailPath) return "Back To Project List";
    return "Back To Home";
  }, [isBatchDetailPath, isProjectDetailPath]);

  const handleBack = () => {
    if (isBatchDetailPath) {
      const { params } = routeMatch(
        history.pathname,
        Routes.PROJECT + Routes.BATCHES
      );
      const keys = Object.keys(params);
      if (keys.length > 0) {
        const pathname = Routes.PROJECT.replace(`:${keys[0]}`, params[keys[0]]);
        history.push(pathname + Routes.BATCHES);
      }
    } else if (isProjectDetailPath) history.push(Routes.PROJECTS);
    else history.push(Routes.ROOT);
  };

  const handleSwitchRole = () => {
    switchRole(
      currentRole === UserRole.CUSTOMER ? UserRole.LABELER : UserRole.CUSTOMER
    );
  };

  const handleSignOut = () => {
    signOut();
  };

  const handleSelectNotification = () => {
    history.push(Routes.NOTIFICATIONS);
  };

  return (
    <div className="flex w-screen h-screen overflow-hidden bg-background-50">
      <header className="fixed w-full shadow" style={{ zIndex: 1299 }}>
        <HeaderComponent
          userName={userInfo?.name || ""}
          hasNewNotifications={unreadNotifications > 0}
          onSwitchRole={handleSwitchRole}
          onLogout={handleSignOut}
          onViewProfile={handleViewProfile}
          onSelectNotification={handleSelectNotification}
        />
      </header>

      <nav
        className={classnames(
          "bg-white z-40 flex-shrink-1 transition-all overflow-hidden border-r",
          {
            "w-60": showNavigationBar && sidebarItems.length > 0,
            "w-0": !showNavigationBar,
          }
        )}
      >
        <div className={classes.toolbar} />
        {showNavigationBar && (
          <Sidebar
            items={sidebarItems}
            collapsed={false}
            titleBack={titleBack}
            onBack={handleBack}
          />
        )}
      </nav>
      <main
        className={classnames("w-full flex-grow-1 overflow-auto flex flex-col")}
      >
        <div className={classes.toolbar} />
        <section className="relative flex-1 overflow-y-auto">
          {children}
        </section>
      </main>

      <button
        className="fixed bottom-2 right-2 rounded-full bg-blue-500 flex items-center justify-center w-12 h-12"
        onClick={() =>
          openModal(ModalTypes.TICKET_SUPPORT, {
            title: "Submit Ticket",
            titleContent: null,
          })
        }
      >
        <IconMessageQuestion color="white" className="flex-none w-6 h-6" />
      </button>
    </div>
  );
}
