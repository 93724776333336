import moment from "moment";
import { ExportedDatasetDTO } from "services/storage-service/dto/exported-dataset.dto";
import { MlModelsExportedDatasetVersionUIModel } from "./exported-datasets.models";

export const exportedDatasetsMapper = {
  dtoToVersion: (
    dto: ExportedDatasetDTO
  ): MlModelsExportedDatasetVersionUIModel[] => {
    const versions: MlModelsExportedDatasetVersionUIModel[] = [];

    for (const datasetVersion of dto.versions || []) {
      versions.push({
        datasetId: dto.id,
        datasetName: dto.name,
        datasetDescription: dto.description || "",
        versionId: datasetVersion.id,
        versionDate: moment(new Date(datasetVersion.createdDate))
          .local()
          .toDate(),
        versionStatus: datasetVersion.status,
        owner: datasetVersion.createdBy,
      });
    }

    return versions;
  },
};
