/*
 * File: app.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 4:31:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppSetting } from "hooks/use-app-setting.hook";
import React, { useReducer } from "react";
import { useNotifications } from "../../hooks/use-notifications";
import { AppContext } from "./app.context";
import { AppActionTypes, appReducer } from "./app.reducer";
import { defaultAppState } from "./app.state";

interface AppProviderProps {
  children: React.ReactNode | React.ReactNode[] | null;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const [state, dispatch] = useReducer(appReducer, defaultAppState);

  useNotifications();
  const { appConfig } = useAppSetting();

  function setLoading(loading: boolean) {
    return dispatch({
      type: AppActionTypes.SET_LOADING,
      payload: loading,
    });
  }

  function setError(error: Error | null) {
    return dispatch({
      type: AppActionTypes.SET_ERROR,
      payload: error,
    });
  }

  const value = {
    ...state,
    appConfig,
    setLoading,
    setError,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
