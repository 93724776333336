/*
 * File: dataset-files-explorer.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 24th August 2021 9:46:50 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { GridColDef } from "@material-ui/data-grid";
import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import { useAppSelector } from "hooks/use-redux";
import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { selectTotalDatasetFiles } from "store/customer/dataset/dataset.selectors";
import { useDatasetDetailContext } from "../../dataset-detail.context";
import { FileInfoSelect } from "./file-info-panel/file-info-select.component";
import { StorageGrid } from "./storage-table/storage-grid";
import { storageGridcolumns } from "./storage-table/storage-grid-col-def";

interface Props {
  columns?: GridColDef[];
  actions?: string[];
  onAction?: (action: string, row: any) => void;
  isTextPannel?: boolean;
}

export const DatasetFilesGrid = ({
  columns = storageGridcolumns,
  actions,
  onAction,
}: Props) => {
  const { showFileInfo } = useDatasetDetailContext();
  const { filter, setPage, setPageSize } = useDatasetContext();
  const totalCount = useAppSelector(selectTotalDatasetFiles);
  return (
    <div className="relative flex flex-col h-full gap-4 px-4">
      <div className="flex-1">
        <StorageGrid columns={columns} actions={actions} onAction={onAction} />
      </div>
      {filter && (
        <div className="flex-shrink-0">
          <GridPagination
            page={filter.page}
            pageSize={filter.size}
            pageSizeOptions={[10, 20, 25, 50, 75, 100]}
            totalItems={totalCount}
            onPageSizeChange={setPageSize}
            onPageChange={setPage}
          />
        </div>
      )}
      {showFileInfo && (
        <div className="absolute top-0 bottom-0 right-0 z-50 h-full w-96">
          <FileInfoSelect />
        </div>
      )}
    </div>
  );
};
