import { IconCheckedCircle } from "components/common/vb-icon.component"
import { useMemo, useState } from "react";
import { BatchObservationDTO } from "services/label-service/dtos";
import { AnnotationResponseDTO, SttAnnotationItem } from "services/label-service/dtos/annotations.dto";
import { classnames } from "utilities/classes";

interface SpeechToTextAnnotationRowModel {
  annotation: AnnotationResponseDTO | undefined,
  labeler: string;
  text: string;
}

interface Props {
  annotations: AnnotationResponseDTO[];
  onSelected?: (annotation: AnnotationResponseDTO | undefined) => void;
  acceptBatchObservation?: BatchObservationDTO;
  rejectBatchObservation?: BatchObservationDTO;
}

export const SpeechToTextListAnnotations = ({
  annotations,
  onSelected,
  acceptBatchObservation,
  rejectBatchObservation,
}: Props) => {
  const [selectedAnnotation, setSelectedAnnotation] = useState<AnnotationResponseDTO>();

  const rows: SpeechToTextAnnotationRowModel[] = useMemo(() => {
    let index = 0;
    return annotations.map(anno => {
      index++;
      const annoItem = anno.annotation as SttAnnotationItem;
      let reviewObs;
      if (annoItem.observationId && annoItem.observationId === acceptBatchObservation?.observation.id) {
        reviewObs = acceptBatchObservation.observation;
      }
      if (annoItem.observationId && annoItem.observationId === rejectBatchObservation?.observation.id) {
        reviewObs = rejectBatchObservation.observation;
      }

      let labelerDisplay = `Labler ${index}: ${anno.assignee || "example@gmail.com"}`;
      if (reviewObs) {
        labelerDisplay = `${labelerDisplay} (${reviewObs.name})`;
      }

      return {
        annotation: anno,
        text: `${annoItem.text}`,
        labeler: labelerDisplay,
        
      }
    }).filter(row => !!row.annotation);
  }, [annotations, acceptBatchObservation, rejectBatchObservation]);

  const handleAnnotationClicked = (annotation: AnnotationResponseDTO | undefined) => {
    if (!annotation) return;
    if (!selectedAnnotation || selectedAnnotation.jobId !== annotation.jobId){
      setSelectedAnnotation(annotation);
      onSelected && onSelected(annotation);
    } else {
      setSelectedAnnotation(undefined);
      onSelected && onSelected(undefined);
    }
  }

  return (
    <div className="w-full px-4 py-2">
      <div className="w-full flex flex-col gap-4 text-white bg-background-800 p-4 rounded border border-background-700">
        <p className="text-lg">Labeling Result (click to choose)</p>
        <div className="flex flex-col gap-4">
          {
            rows.map(row => {
              // It is safe to use jobId here since 1 job only have 1 task observation
              const isSelected = selectedAnnotation && row.annotation?.jobId === selectedAnnotation.jobId;

              return (
                <div
                  key={row.labeler}
                  className={classnames(
                    "cursor-pointer p-4 rounded border border-background-700",
                    {
                      "bg-background-900": !!!isSelected,
                    },
                    {
                      "bg-warning-500": !!isSelected,
                    }
                  )}
                  onClick={_ => handleAnnotationClicked(row.annotation)}
                >
                  <div className="w-full flex justify-between mb-2">
                    <span>{row.labeler}</span>
                    {
                      isSelected &&
                      <IconCheckedCircle style={{
                        width: "20px",
                        height: "20px"
                      }}/>
                    }
                    {
                      !isSelected &&
                      <div className="rounded-full border-2 border-warning-500"
                        style={{
                          width: "20px",
                          height: "20px"
                        }}
                      >
                      </div>
                    }
                  </div>
                  <div className="line-clamp-2 pr-8">
                    {row.text}
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}