/*
 * File: three-d-editor-label-attribute-menu.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 26th October 2022 11:21:04 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Popover, PopoverPosition } from "@material-ui/core";
import VBRadioInputComponent from "components/design-system/radio/radio.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { useAppDispatch } from "hooks/use-redux";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { mathUtils } from "utilities/math";
import { LabelOption } from "./three-d-editor-label-item.component";
import {
  EditorLabel,
  EditorLabelAttribute,
  EditorLabelOption,
} from "./three-d-editor.models";

interface Props {
  anchorElement: Element | null;
  item: EditorLabel;
  labelOption: EditorLabelOption;
  labelOptions: LabelOption[];
  onClose(): void;
  onOpacityChange(value: number): void;
  onColorChange(value: string): void;
  onSubmit(label: Partial<EditorLabel>): void;
}
export const EditorLabelAttributeMenu = ({
  item,
  labelOptions,
  anchorElement,
  onClose,
  onSubmit,
  onOpacityChange,
  onColorChange,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const containerRef = useRef<HTMLInputElement>(null);
  const colorInputRef = useRef<HTMLInputElement>(null);
  const [attributes, setAttributes] = useState<EditorLabelAttribute[]>(() => {
    return item.attributes || [];
  });
  const [labelOptionId, setLabelOptionId] = useState(item.labelOptionId);
  const [opacity, setOpacity] = useState(item.opacity);
  const [color, setColor] = useState(item.color);
  const [livePreview, setLivePreview] = useState(true);

  const labelOption = useMemo(() => {
    return labelOptions.find(({ value }) => value.id === labelOptionId)?.value;
  }, [labelOptions, labelOptionId]);

  const autoFocusIndex = useMemo(() => {
    if (labelOption?.attributes) {
      return labelOption.attributes.find(
        (attr) => attr.type === "number" || attr.type === "string"
      );
    }
    return -1;
  }, [labelOption]);

  const [anchorPosition, setAnchorPosition] = useState<
    PopoverPosition | undefined
  >(undefined);

  function handleLabelOptionChange(value: number) {
    setLabelOptionId(value);
    if (value !== item.labelOptionId) {
      setAttributes([]);
    } else {
      setAttributes(item.attributes || []);
    }
  }

  function handleClose() {
    onClose();
  }

  function handleSubmit() {
    const error = validateAttributeValues(attributes);
    if (error?.message) {
      dispatch(enqueueErrorNotification(error.message));
      return;
    }
    onSubmit({ labelOptionId, opacity, color, attributes });
  }

  function validateAttributeValues(values: EditorLabelAttribute[]) {
    if (!labelOption || !labelOption.attributes) return;
    for (const attribute of labelOption.attributes) {
      if (!attribute.required) continue;
      const hasValue = !!values.find((val) => val.id === attribute.id);
      if (!hasValue) {
        const params = { name: labelOption.name, attribute: attribute.name };
        const message = t("error:labeling.labelAttributeRequired", params);
        return { message };
      }
    }
  }

  function handleValueChanges(id: number, newValues: string[]) {
    let newVals = [];
    if (attributes.find((val) => val.id === id)) {
      newVals = [
        ...attributes.map((value) => {
          if (value.id === id) {
            return {
              ...value,
              value: newValues,
            };
          }
          return value;
        }),
      ];
    } else {
      newVals = [...attributes, { id, value: newValues }];
    }
    setAttributes(newVals.filter((item) => item.value.length > 0));
  }

  function getDefaultValues(attributeId: number): string[] {
    return (
      item.attributes?.find((attr) => attr.id === attributeId)?.value || []
    );
  }

  function getDefaultValue(attributeId: number): string | undefined {
    const values =
      item.attributes?.find((attr) => attr.id === attributeId)?.value || [];

    if (!values || values.length === 0) return undefined;
    return values[0];
  }

  const handleChangeInputColor = (e: ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;
    setColor(value);
    if (livePreview) onColorChange(value);
  };

  useEffect(() => {
    if (!anchorElement) return;
    const anchorBBox = anchorElement.getBoundingClientRect();
    const top = anchorBBox.y + anchorBBox.height + 20;
    const left = anchorBBox.left + anchorBBox.width - 40;
    setAnchorPosition({ left, top });
  }, [anchorElement]);

  if (anchorPosition === undefined) return null;

  return (
    <Popover
      open
      className="w-screen h-screen overflow-hidden"
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div
        className="z-50 min-w-full text-sm bg-white rounded shadow-lg outline-none resize-x text-background-700"
        style={{ minWidth: 240, maxWidth: 360 }}
        ref={containerRef}
      >
        <h2 className="flex items-center px-4 mt-4 text-base font-semibold h-9">
          Update Annotation
        </h2>
        <div className="p-4 space-y-4 overflow-y-auto max-h-96">
          <VBSelectComponent
            containerClassName="text-sm"
            size="sm"
            header="Change observation"
            value={labelOptions.find((o) => o.value.id === labelOptionId)}
            options={labelOptions}
            onChange={(option: any) => {
              handleLabelOptionChange(option.value.id);
            }}
            menuPortalTarget={document.body}
          />

          <div className="space-y-2">
            <div className="flex items-center gap-1 text-sm">
              <input
                id="input-preview"
                type="checkbox"
                checked={livePreview}
                onChange={(e) => setLivePreview(e.target.checked)}
              />
              <label htmlFor="input-preview">Live preview</label>
            </div>
            <div className="flex items-center justify-between gap-2">
              <div className="w-16">Opacity:</div>
              <input
                className="px-2 text-sm border rounded border-background-500 focus:border-primary"
                type="number"
                value={opacity}
                min={0}
                max={100}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  const clampValue = mathUtils.clamp(value, 0, 100);
                  setOpacity(clampValue);
                  if (livePreview) onOpacityChange(clampValue);
                }}
              />

              <input
                className="flex-auto"
                type="range"
                value={opacity}
                min={0}
                max={100}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  setOpacity(value);
                  if (livePreview) onOpacityChange(value);
                }}
              />
            </div>

            <div className="flex items-center gap-2">
              <div className="w-16">Color:</div>
              <div
                style={{
                  width: 16,
                  height: 16,
                  backgroundColor: color,
                  opacity: parseFloat(opacity.toString()) / 100,
                  border: `solid 2px ${color}`,
                }}
              >
                <input
                  ref={colorInputRef}
                  type="color"
                  className="w-4 h-4 p-0 text-black border-none opacity-0"
                  value={color}
                  onChange={handleChangeInputColor}
                />
              </div>
              <span>{color}</span>
            </div>
          </div>

          {labelOption?.attributes?.map((attribute, idx) => {
            return (
              <div key={attribute.name}>
                {attribute.type.toLowerCase() === "text" && (
                  <VBTextInputComponent
                    autoFocus={autoFocusIndex === idx}
                    header={attribute.name}
                    require={attribute.required}
                    type="text"
                    className="w-full"
                    hideInputIcon
                    defaultValue={getDefaultValue(attribute.id)}
                    onInputSubmit={(val: string) =>
                      handleValueChanges(attribute.id, [val])
                    }
                    onInputChange={(val: string) =>
                      handleValueChanges(attribute.id, [val])
                    }
                  />
                )}
                {attribute.type.toLowerCase() === "number" && (
                  <VBTextInputComponent
                    header={attribute.name}
                    require={attribute.required}
                    type="number"
                    className="w-full"
                    hideInputIcon
                    autoFocus={autoFocusIndex === idx}
                    defaultValue={getDefaultValue(attribute.id)}
                    min={
                      attribute.ranges && attribute.ranges.length > 0
                        ? parseInt(attribute.ranges[0].toString())
                        : undefined
                    }
                    max={
                      attribute.ranges && attribute.ranges.length > 1
                        ? parseInt(attribute.ranges[1].toString())
                        : undefined
                    }
                    onInputChange={(val: string) =>
                      handleValueChanges(attribute.id, [val])
                    }
                  />
                )}

                {attribute.type.toLowerCase() === "select" && (
                  <VBRadioInputComponent
                    multiple
                    useDropdown
                    require={attribute.required}
                    header={attribute.name}
                    options={attribute.options || []}
                    optionClassName="whitespace-nowrap"
                    defaultValues={getDefaultValues(attribute.id)}
                    onValueChange={(values) =>
                      handleValueChanges(attribute.id, values)
                    }
                  />
                )}

                {attribute.type.toLowerCase() === "radio" && (
                  <VBRadioInputComponent
                    useDropdown
                    require={attribute.required}
                    header={attribute.name}
                    options={attribute.options || []}
                    optionClassName="whitespace-nowrap"
                    defaultValues={getDefaultValues(attribute.id)}
                    onValueChange={(values) =>
                      handleValueChanges(attribute.id, values)
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="flex items-center justify-end gap-2 p-4">
          <button
            onClick={handleClose}
            className="flex items-center justify-center gap-1.5 px-3 h-9 rounded text-background-700"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="flex items-center justify-center gap-1.5 px-3 h-9 rounded bg-warning-500 text-white border border-warning-500"
          >
            Update
          </button>
        </div>
      </div>
    </Popover>
  );
};
