/*
 * File: step-distribution.component.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 3:39:54 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppSelector } from "hooks/use-redux";
import { ObservationDTO } from "services/label-service/dtos";
import { selectStepDistributionData } from "store/customer/batch-data/label-distribution/label-distribution.selectors";
import { useMemo } from 'react';
import { convertStepDistributionToRowsModel } from 'models/common/observations-stats';
import { LABEL_DIST_COLUMNS, LABEL_DIST_COLUMNS_TEXT, ObservationDistTree } from 'pages/customer/projects/components/observation-dist-tree.component';
import { selectIsTextProject } from "store/customer/project/project.selectors";
interface Props {
  batchId: number;
  step: number;
  observations?: ObservationDTO[];
}
export const StepDistribution = ({
  batchId,
  step,
  observations = [],
}: Props) => {
  const isTextProject = useAppSelector(selectIsTextProject);
  const data = useAppSelector(selectStepDistributionData(batchId, step));
  const labelDistRows = useMemo(() => {
    return convertStepDistributionToRowsModel(data, observations);
  }, [data, observations]);
  if (!data) return null;
  return (
    <ObservationDistTree
      rows={labelDistRows}
      columns={isTextProject ? LABEL_DIST_COLUMNS_TEXT : LABEL_DIST_COLUMNS}
    />
  );
};
