/*
 * File: currency-formatter.utils.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 10th March 2022 1:42:36 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

const formatters: { [key: string]: Intl.NumberFormat } = {
  default: new Intl.NumberFormat("es-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  }),
  usd: new Intl.NumberFormat("es-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  }),
  vnd: new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
    maximumFractionDigits: 2,
  }),
};

export function formatCurrency(value: number, currency = "USD") {
  let key = currency.toLowerCase();
  if (!formatters.hasOwnProperty(key)) {
    key = "default";
  }
  return formatters[key].format(value);
}
