/*
 * File: dataset-card.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { DatasetDTO } from "services/storage-service/dto/dataset.dto";
import { MouseEvent } from "react";
import {
  IconInformationCircleOutline,
  IconTrash,
} from "components/common/vb-icon.component";
import { formatDate } from "utilities/formatter/date-formatter.utils";
import AudioThumbnail from "assets/images/thumbnails/thumbnail_audio.png";
import TextThumbnail from "assets/images/thumbnails/thumbnail_text.png";
import DicomThumbnail from "assets/images/thumbnails/thumbnail_dicom.png";
import NiiThumbnail from "assets/images/thumbnails/thumbnail_nii.png";
import { DataType } from "domain/customer";
import VBImage from "components/common/vb-image/vb-image.component";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

interface DatasetCardProps {
  dataset: DatasetDTO;
  onSelect?: (dataset: DatasetDTO) => void;
  onSelectInfo?: (dataset: DatasetDTO) => void;
  onDelete?: (dataset: DatasetDTO) => void;
  selected?: boolean;
}

export const DatasetCard = ({
  selected = false,
  dataset,
  onSelectInfo,
  onDelete,
  onSelect,
}: DatasetCardProps) => {
  const { t } = useTranslation();
  function handleClickInfo(event: MouseEvent) {
    event.preventDefault();
    onSelectInfo && onSelectInfo(dataset);
  }

  function handleClickDelete(event: MouseEvent) {
    event.preventDefault();
    onDelete && onDelete(dataset);
  }

  return (
    <div
      className={classNames(
        "relative h-full overflow-hidden transition-transform transform bg-white rounded shadow cursor-pointer parent hover:shadow-xl hover:-translate-y-1 group",
        {
          "ring-2 ring-blue-500": selected,
        }
      )}
    >
      <div className="relative">
        <div className="bg-gray-300 aspect-w-4 aspect-h-2">
          <DatasetThumbnail dataset={dataset} />
        </div>
        <div className="absolute top-0 left-0 hidden w-full h-full bg-black bg-opacity-20 group-hover:block"></div>
      </div>

      <div className="flex flex-col gap-6 p-4">
        <div className="flex flex-col gap-2">
          <div>{dataset.name}</div>
          <div className="text-xs text-primary">
            {t("dataset:datasetCard.textTotalFile", {
              num: dataset.totalFile || 0,
            })}
          </div>
        </div>
        <div className="text-xs text-background-500">
          <span>
            {t("dataset:datasetCard.createdDate", {
              date: formatDate(new Date(dataset.createdDate)),
            })}
          </span>
        </div>
      </div>

      <div className="absolute left-0 right-0 z-10 flex items-center justify-end gap-2 px-4 bottom-4 child-on-hover">
        <button
          className="flex items-center justify-center w-4 h-4 rounded cursor-pointer select-none text-primary"
          onClick={handleClickInfo}
        >
          <IconInformationCircleOutline className="w-4 h-4" />
        </button>
        <button
          className="flex items-center justify-center w-4 h-4 rounded cursor-pointer select-none text-primary"
          onClick={handleClickDelete}
        >
          <IconTrash className="w-4 h-4" />
        </button>
      </div>

      <div
        className={classNames(
          "absolute left-2 z-10 flex items-center justify-end gap-2 top-2",
          {
            "child-on-hover": !selected,
          }
        )}
      >
        <Tooltip title="Toggle select file" arrow>
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onSelect && onSelect(dataset);
            }}
            className={classNames({
              "bg-white rounded overflow-hidden": !!selected,
            })}
          >
            {!selected && <MdCheckBoxOutlineBlank color="#fff" size={24} />}
            {selected && <MdCheckBox size={24} color="blue" />}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

const thumbnailMap: Record<string, string> = {
  [DataType.AUDIO]: AudioThumbnail,
  [DataType.TEXT]: TextThumbnail,
  [DataType.DICOM]: DicomThumbnail,
  [DataType.MDI]: NiiThumbnail,
};
interface DatasetThumbnailProps {
  dataset: DatasetDTO;
}

const DatasetThumbnail = ({ dataset }: DatasetThumbnailProps) => {
  if (!dataset.thumbnailUrl) return null;
  const url = dataset.thumbnailUrl;

  return (
    <div className="flex items-center justify-center w-full h-full bg-black animate-fade-in">
      {url && dataset.category === DataType.IMAGE && (
        <VBImage
          className="object-cover w-full h-full"
          src={url}
          alt={dataset.name}
        />
      )}
      {dataset.category && dataset.category !== DataType.IMAGE && (
        <div className="flex items-center justify-center w-full h-full bg-white border-b">
          <img
            src={thumbnailMap[dataset.category]}
            className="max-w-full max-h-full p-6 bg-white"
            alt=""
          />
        </div>
      )}
    </div>
  );
};
