/*
 * File: column-header.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 23rd May 2023 9:31:52 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { IconButton } from "@material-ui/core";
import { Entity } from "domain/common";
import { ITableColumn } from "domain/common/table";
import { ArrowUp2, ArrowDown2 } from "iconsax-react";

interface ColumnHeaderProps<T extends Entity> {
  column: ITableColumn<T>;
  onClick(colum: ITableColumn<T>): void;
  sortOrder?: string;
}
export function ColumnHeader<T extends Entity>({
  column,
  sortOrder,
  onClick,
}: ColumnHeaderProps<T>) {
  return (
    <div className="flex items-center gap-1">
      {!column.sortable && (
        <span className="font-semibold whitespace-nowrap">{column.label}</span>
      )}
      {column.sortable && (
        <button
          className="font-semibold whitespace-nowrap"
          onClick={() => onClick && onClick(column)}
        >
          {column.label}
        </button>
      )}
      {column.sortable && sortOrder && (
        <IconButton onClick={() => onClick && onClick(column)}>
          {sortOrder === "asc" && <ArrowUp2 size={16} variant="Bold" />}
          {sortOrder === "desc" && <ArrowDown2 size={16} variant="Bold" />}
        </IconButton>
      )}
    </div>
  );
}

export default ColumnHeader;
