/*
 * File: project.state.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  BatchDTO,
  BatchWorkflowDTO,
  ObservationDTO,
  ProjectDTO,
  WorkflowDTO,
  WorkflowInstructionDTO,
} from "services/label-service/dtos";
import { ProjectInfoDTOV2 } from "services/label-service/dtos/project-v2.dto";
import { WorkManagementDTO } from "services/label-service/dtos/work-management.dto";

export interface ProjectState {
  requesting: boolean;
  
  project: ProjectDTO | null;
  projectForCreatingBatch: ProjectInfoDTOV2 | null;
  batches: BatchDTO[];
  batchWorkflows: BatchWorkflowDTO[];
  workflowInstructions: WorkflowInstructionDTO[];

  observations: ObservationDTO[];
  observationAnnotationTypes: string[];
  workflow: WorkflowDTO | null
  workManagements: WorkManagementDTO[];
}

export const INITIAL_PROJECT_STATE: ProjectState = {
  requesting: true,
  project: null,
  projectForCreatingBatch: null,
  batches: [],

  observations: [],
  batchWorkflows: [],
  workflowInstructions: [],
  observationAnnotationTypes: [],
  workflow: null,
  workManagements: []
};
