/*
 * File: batch-workflow-loading.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 19th August 2021 4:37:36 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { VBSpinner } from "components/common/vb-spinner/vb-spinner.component";

export const BatchWorkflowLoading = () => {
  return (
    <div className="p-4">
      <div className="flex justify-center py-8">
        <VBSpinner />
      </div>
    </div>
  );
};
