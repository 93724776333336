/*
 * File: project-notification-settings.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 10th September 2021 3:36:41 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { OptionGroup } from "./components/project-option-group.component";
import { TaskReportOptions } from "./components/project-task-report-options.component";
import { useNotificationSetting } from "./use-notification-setting.hook";
import { ButtonSave } from "../components/button-save.component";

export const ProjectNotificationSettingsPage = () => {
  const { t } = useTranslation();
  const { isChanged, isLoading, settings, onSave, setIsChanged, setSettings } =
    useNotificationSetting();

  if (!settings) return null;

  return (
    <div className="p-4">
      <OptionGroup
        groupLabel={t("project:settings.notification.groupLabelApplication")}
        locked
        defaultActive
      >
        <TaskReportOptions
          options={settings.notifications}
          users={settings.users}
          setIsChanged={setIsChanged}
          setSettings={setSettings}
        />
      </OptionGroup>
      <ButtonSave disabled={!isChanged || isLoading} onClick={onSave} />
    </div>
  );
};
