/*
 * File: project-data.slice.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 9:28:20 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice } from "@reduxjs/toolkit";
import { labelDistributionReducerBuilder } from "./label-distribution/label-distribution.thunk";
import { labelerDistributionReducerBuilder } from "./labeler-distribution/labeler-distribution.thunk";
import {
  ProjectDataState,
  INITIAL_PROJECT_DATA_STATE,
} from "./project-data.state";
import { projectStatisticReducers } from "./statistic/project-statistic.slice";
import { projectStatisticReducerBuilder } from "./statistic/project-statistic.thunk";

const SLICE_NAME: string = "batchData";
const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_PROJECT_DATA_STATE,
  reducers: {
    resetProjectData(state: ProjectDataState) {
      Object.assign(state, INITIAL_PROJECT_DATA_STATE);
    },
    ...projectStatisticReducers,
  },
  extraReducers: (builder) => {
    projectStatisticReducerBuilder(builder);
    labelDistributionReducerBuilder(builder);
    labelerDistributionReducerBuilder(builder);
  },
});

export const {
  resetProjectData,
  removeStatistic,
  setBatchStatistics,
  addStatistic,
} = slice.actions;

export const projectDataReducer = slice.reducer;
