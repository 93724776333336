/*
 * File: annotation-generation-status.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 17th February 2022 3:05:10 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { VBSectionMessage } from "components/common/vb-section-message/vb-section-message.component";
import { useTranslation } from "react-i18next";
import { RequestStatus } from "store/base/base.state";

interface Props {
  status: RequestStatus;
}

export const AnnotationGenerationStatus = ({ status }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {status === RequestStatus.LOADING && (
        <VBSectionMessage
          appearance="info"
          title={t(
            "project:batchDetails.batchexport.annotationGenerationStatus.busyTitle"
          )}
          description={t(
            "project:batchDetails.batchexport.annotationGenerationStatus.busyDesc"
          )}
        />
      )}
      {status === RequestStatus.SUCCESS && (
        <VBSectionMessage
          appearance="success"
          title={t(
            "project:batchDetails.batchexport.annotationGenerationStatus.successTitle"
          )}
          description={t(
            "project:batchDetails.batchexport.annotationGenerationStatus.successDesc"
          )}
        />
      )}
      {status === RequestStatus.FAILURE && (
        <VBSectionMessage
          appearance="error"
          title={t(
            "project:batchDetails.batchexport.annotationGenerationStatus.errorTitle"
          )}
          description={t(
            "project:batchDetails.batchexport.annotationGenerationStatus.errorDesc"
          )}
        />
      )}
    </>
  );
};
