export enum ModalTypes {
  UPDATE_LABEL = "update_label",
  CREATE_WORKSPACE = "create_workspace",
  DELETE_WORKSPACE = "delete_workspace",
  CREATE_DATASOURCE = "create_datasource",
  DELETE_DATASOURCES = "DELETE_DATASOURCES",
  MERGE_DATASOURCES = "MERGE_DATASOURCES",
  DUPLICATE_DATASOURCE = "DUPLICATE_DATASOURCE",
  TICKET_SUPPORT = "ticket_support",
  TICKET_SUPPORT_SETTING = "ticket_support_setting",
  TICKET_LIST = "ticket_list",
  TICKET_DETAIL = "ticket_detail",
  THREE_D_SELECT_LAYOUT = "THREE_D_SELECT_LAYOUT",
  REOPEN_TASK = "REOPEN_TASK",
  SKIP_TASK = "SKIP_TASK",
  MANAGE_FILE_METADATA = "MANAGE_FILE_METADATA",
  REMOVE_FILE_METADATA = "REMOVE_FILE_METADATA",
  DELETE_FILES = "DELETE_FILES",
  MOVE_FILES_TO_NEW_FOLDER = "MOVE_FILES_TO_NEW_FOLDER",
  MOVE_FILES_TO_FOLDER = "MOVE_FILES_TO_FOLDER",
}

export type ModalState = {
  openModal: (modalType: ModalTypes, modalProps?: any) => void;
  closeModal: () => void;
};

export const defaultModalState: ModalState = {
  openModal: () => {},
  closeModal: () => {},
};
