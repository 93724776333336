import { useEffect, useMemo, useState } from "react";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { useTranslation } from "react-i18next";
import { ObservationDTO } from "services/label-service/dtos";
import { VBNumberTextInputComponent } from "components/design-system/number-text-input/number-text-input.component";
import { VBRichTextEditor } from "components/common/vb-rich-text/vb-rich-text.component";
import { useAppDispatch } from "hooks/use-redux";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import {
  IllustrationItemUIModel,
  LabelIllustrations,
} from "./label-illustrations.component";
import { v4 } from "uuid";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { StorageService } from "services/storage";
import { LabelEditMinimized } from "./label-edit-minimized.component";
import { VBSwitch } from "components/design-system/switch/switch.component";
import * as Sentry from "@sentry/react";

interface Props {
  open: boolean;
  value: Partial<ObservationDTO>;
  onClose?: () => void;
  onSubmit?: (v: Partial<ObservationDTO>) => void;
  onHasErrorChanged?: (v: boolean) => void;
  observations?: ObservationDTO[];
  projectObsAnnotationTypes?: string[];
}
export const AdvancedLabelComponent = ({
  open,
  value,
  onClose,
  onSubmit,
  onHasErrorChanged,
  observations = [],
  projectObsAnnotationTypes = [],
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [localValue, setLocalValue] = useState<Partial<ObservationDTO>>({
    ...value,
  });
  const [illustrationItems, setIllustrationItems] = useState<
    IllustrationItemUIModel[]
  >([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const parentOptions = useMemo(() => {
    return observations
      .filter((o) => o.id !== localValue.id)
      .map((o) => ({ value: o, label: o.name }));
  }, [observations, localValue]);

  const setLocalValueField = (field: keyof ObservationDTO, v: any) => {
    setLocalValue({ ...localValue, [field]: v });
  };

  const setLocalSettingField = (field: string, v: any) => {
    setLocalValueField("observationSetting", {
      ...localValue.observationSetting,
      [field]: v,
    });
  };

  useEffect(() => {
    setLocalValue({ ...value });
    setIllustrationItems(
      (value.observationSetting?.illustrations || []).map((item) => ({
        ...item,
        id: v4(),
      }))
    );
  }, [value]);

  const handleSubmit = async () => {
    if (isProcessing) return;
    try {
      setIsProcessing(true);
      const newImageFilesIndecies: number[] = [];
      const filesToUpload: File[] = [];

      for (let i = 0; i < illustrationItems.length; i++) {
        const item = illustrationItems[i];
        if (item.file) {
          newImageFilesIndecies.push(i);
          filesToUpload.push(item.file);
        }
      }

      let newIllustrationItems = illustrationItems.map((item) => ({ ...item }));
      if (newImageFilesIndecies.length > 0) {
        const promises: Promise<string>[] = [];
        for (const file of filesToUpload) {
          promises.push(StorageService.uploadFile(file));
        }
        const urls = await Promise.all(promises);
        for (let i = 0; i < urls.length; i++) {
          const illIndex = newImageFilesIndecies[i];
          newIllustrationItems[illIndex].url = urls[i];
          newIllustrationItems[illIndex].file = undefined;
          newIllustrationItems[illIndex].error = undefined;
        }
      }

      let newValue = { ...localValue };
      if (newValue.observationSetting) {
        const newIllustrations = newIllustrationItems.map((item) => {
          const itemObj: any = { ...item };
          delete itemObj["id"];
          return itemObj;
        });
        newValue = {
          ...newValue,
          observationSetting: {
            ...newValue.observationSetting,
            illustrations: newIllustrations,
          },
        };
      }

      if (newImageFilesIndecies.length > 0) {
        dispatch(
          enqueueSuccessNotification("Uploaded new files successfully!")
        );
      }

      onSubmit && onSubmit(newValue);
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
      dispatch(enqueueErrorNotification("Failed to upload images!"));
    } finally {
      setIsProcessing(false);
    }
  };

  const handleClose = () => {
    setLocalValue({ ...value });
    onClose && onClose();
  };

  return (
    <VBModal
      width="52rem"
      title="Advanced settings"
      open={open}
      onClose={handleClose}
      textSubmit={t("common:buttonUpdate")}
      onSubmit={handleSubmit}
      disableSubmit={isProcessing}
    >
      <div className="flex flex-col gap-4">
        <LabelEditMinimized
          value={localValue}
          onChanged={(v) => setLocalValue(v)}
          onHasErrorChanged={onHasErrorChanged}
          observations={observations}
          projectObsAnnotationTypes={projectObsAnnotationTypes}
        />
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td className="align-top" width="120px">
                Code
              </td>
              <td className="pb-2">
                <VBNumberTextInputComponent
                  height="33px"
                  type="text"
                  placeholder="Code"
                  defaultValue={localValue.code}
                  listenChangedDefault
                  onValueChanged={(v) => setLocalValueField("code", v)}
                />
              </td>
            </tr>
            <tr>
              <td className="align-top">Description</td>
              <td className="pb-2">
                <VBRichTextEditor
                  defaultValue={localValue.description || ""}
                  onChange={(v) => setLocalValueField("description", v)}
                />
              </td>
            </tr>
            <tr>
              <td className="align-top">Caveats</td>
              <td className="pb-2">
                <VBRichTextEditor
                  defaultValue={localValue.observationSetting?.caveats || ""}
                  onChange={(v) => setLocalSettingField("caveats", v)}
                />
              </td>
            </tr>
            <tr>
              <td className="align-top">Group</td>
              <td className="pb-2">
                <VBNumberTextInputComponent
                  height="33px"
                  type="text"
                  placeholder="Group"
                  defaultValue={localValue.observationGroup}
                  listenChangedDefault
                  onValueChanged={(v) =>
                    setLocalValueField("observationGroup", v)
                  }
                />
              </td>
            </tr>
            <tr>
              <td className="align-top">Parent</td>
              <td className="pb-2">
                <VBSelectComponent
                  size="lg"
                  value={parentOptions.find(
                    (option: any) => option.value.id === localValue.parent?.id
                  )}
                  options={parentOptions}
                  onChange={(option: any) => {
                    if (option) {
                      setLocalValueField("parent", option.value);
                    } else {
                      setLocalValueField("parent", null);
                    }
                  }}
                  menuPortalTarget={document.body}
                  isClearable
                />
              </td>
            </tr>
            <tr>
              <td className="align-top">Num. point</td>
              <td className="pb-2">
                <VBNumberTextInputComponent
                  height="33px"
                  type="text"
                  placeholder="Num. point"
                  defaultValue={localValue.observationSetting?.numberOfPoint}
                  listenChangedDefault
                  onValueChanged={(v) =>
                    setLocalSettingField("numberOfPoint", v)
                  }
                />
              </td>
            </tr>

            <tr>
              <td className="align-top">Selectable</td>
              <td className="pb-2">
                <VBSwitch
                  checked={!!localValue.observationSetting?.selectable}
                  onChange={(v) => setLocalSettingField("selectable", v)}
                />
              </td>
            </tr>
            <tr>
              <td className="align-top">Illustrations</td>
              <td className="pb-2">
                <LabelIllustrations
                  items={illustrationItems}
                  onChanged={(newItems) => setIllustrationItems(newItems)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </VBModal>
  );
};
