/*
 * File: create-label.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 2nd August 2021 2:47:02 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useKeyPress } from "ahooks";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { useAppPrevious } from "hooks/use-app-previous";
import { useAppDispatch } from "hooks/use-redux";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ObservationAnnotationType,
  ObservationAttributeType,
  ObservationDTO,
  ProjectDTO,
} from "services/label-service/dtos";
import { snakeCase } from "snake-case";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import {
  createProjectLabelAsync,
  updateProjectObservationAsync,
} from "store/customer/project/project.slice";
import { generateAnyColor } from "utilities/color/color.utils";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { Logger } from "utilities/logger";
import { isValidNumber } from "utilities/number/handle-invalid-number";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { AdvancedLabelComponent } from "./advanced-label.component";
import { LabelEditMinimized } from "./label-edit-minimized.component";
import { OverwriteLabelDialog } from "./overwrite-label.dialog";
import { useRemainLabelColors } from "./remain-label-colors.hook";
import * as Sentry from "@sentry/react";

interface Props {
  visible: boolean;
  onClose(): void;
  onSubmitted?: (v: Partial<ObservationDTO>) => void;
  isEdit?: boolean;
  observationToEdit?: ObservationDTO | null;
  observations?: ObservationDTO[];
  project?: ProjectDTO | null;
  projectObsAnnotationTypes?: string[];
  createEditToServer?: boolean;
}

export const CreateEditLabelModal = ({
  visible,
  onClose,
  onSubmitted,
  isEdit = false,
  observationToEdit,
  observations = [],
  project,
  projectObsAnnotationTypes = [],
  createEditToServer = true,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const prevProjectObsAnnotationTypes = useAppPrevious(
    projectObsAnnotationTypes
  );

  const remainLabelColors = useRemainLabelColors(observations);
  const nextLabelColor = useMemo(() => {
    if (!remainLabelColors || !remainLabelColors.length) {
      return generateAnyColor();
    }
    return remainLabelColors[0];
  }, [remainLabelColors]);
  const prevNextLabelColor = useAppPrevious(nextLabelColor);

  const [hasErrors, setHasErrors] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const defaultObservation: Partial<ObservationDTO> = {
    projectId: project?.id || undefined,
    name: "",
    active: true,
    code: "",
    description: "",
    observationGroup: "",
    parent: null,
    observationSetting: {
      color: nextLabelColor,
      annotationType:
        projectObsAnnotationTypes && projectObsAnnotationTypes.length > 0
          ? projectObsAnnotationTypes[0]
          : ObservationAnnotationType.GENERAL,
      illustrations: [],
      numberOfPoint: 0,
      caveats: "",
      systemAttribute: false,
      selectable: true,
    },
    attributes: [],
  };

  const [value, setValue] = useState<Partial<ObservationDTO>>({
    ...defaultObservation,
  });

  const [processing, setProcessing] = useState(false);
  const [showOverwriteDialog, setShowOverwriteDialog] = useState(false);
  const [showAdvancedDialog, setShowAdvancedDialog] = useState(false);

  const canSubmit = useMemo(() => {
    if (
      processing ||
      hasErrors ||
      !value.name ||
      !value.attributes ||
      !value.observationSetting?.annotationType
    )
      return false;
    for (const attribute of value.attributes) {
      if (!attribute.name || !attribute.type) return false;
      if (
        attribute.type === ObservationAttributeType.RADIO ||
        attribute.type === ObservationAttributeType.SELECT
      ) {
        if (attribute.values.length <= 0) return false;
      }
      if (attribute.type === ObservationAttributeType.NUMBER) {
        for (const range of attribute.ranges) {
          if (!isValidNumber(range)) return false;
        }
      }
    }

    const isExisted = observations.find(
      (obs) =>
        obs.name.toLowerCase() === value.name?.toLowerCase() &&
        obs.id !== value.id
    );
    if (isExisted) return false;
    return true;
  }, [value, processing, observations, hasErrors]);

  useEffect(() => {
    if (
      isEdit ||
      prevProjectObsAnnotationTypes === projectObsAnnotationTypes ||
      !projectObsAnnotationTypes.length
    ) {
      return;
    }
    const clonedValue = { ...value };
    clonedValue.observationSetting &&
      (clonedValue.observationSetting.annotationType =
        projectObsAnnotationTypes[0]);
    setValue(clonedValue);
  }, [isEdit, value, prevProjectObsAnnotationTypes, projectObsAnnotationTypes]);

  useEffect(() => {
    if (!isEdit && (nextLabelColor !== prevNextLabelColor || isSubmitted)) {
      const clonedValue = { ...value };
      clonedValue.observationSetting &&
        (clonedValue.observationSetting.color = nextLabelColor);
      setValue(clonedValue);
      setIsSubmitted(false);
    }
  }, [isEdit, nextLabelColor, prevNextLabelColor, value, isSubmitted]);

  useEffect(() => {
    if (isEdit && observationToEdit) {
      setValue({ ...observationToEdit });
    }
  }, [isEdit, observationToEdit]);

  async function submit(updateOption?: string) {
    try {
      if (processing) return;
      setProcessing(true);

      const payload = structuredClone(value);

      if (!payload.code) {
        payload.code = snakeCase(payload.name || "");
      }
      if (
        payload.observationSetting &&
        !isValidNumber(payload.observationSetting.numberOfPoint)
      ) {
        payload.observationSetting.numberOfPoint = 0;
      }

      if (createEditToServer) {
        const response = isEdit
          ? await dispatch(
              updateProjectObservationAsync({ payload, strategy: updateOption })
            )
          : await dispatch(createProjectLabelAsync(payload));
        handleThunkRejected(response);
        dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      }

      if (!isEdit) {
        const newValue = { ...defaultObservation };
        if (newValue.observationSetting && value.observationSetting) {
          newValue.observationSetting.annotationType =
            value.observationSetting.annotationType;
          newValue.observationSetting.color = value.observationSetting.color;
        }
        setValue(newValue);
      }
      setIsSubmitted(true);
      setProcessing(false);
      setShowOverwriteDialog(false);
      onSubmitted && onSubmitted(payload);
    } catch (err: any) {
      Sentry.captureException(err);
      Logger.log(err);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
      setProcessing(false);
    }
  }

  const handleSubmit = () => {
    if (!createEditToServer) {
      submit();
      isEdit && onClose && onClose();
      return;
    }
    if (!isEdit) {
      submit();
    } else {
      setShowOverwriteDialog(true);
    }
  };

  const handleHasErrorsChanged = (v: boolean) => {
    setHasErrors(v);
  };

  useKeyPress(KeyboardKey.Enter, (event) => {
    if (!visible || !canSubmit) return;
    // handleSubmit();
  });

  const handleAdvancedSubmit = (v: Partial<ObservationDTO>) => {
    setValue({ ...v });
    setShowAdvancedDialog(false);
  };

  const handleChangeColor = (observation: Partial<ObservationDTO>) => {
    setValue(observation);
    setIsSubmitted(false);
  };

  return (
    <VBModal
      width="50rem"
      title={isEdit ? "Edit label" : "Create label"}
      open={visible}
      onClose={onClose}
      onSubmit={handleSubmit}
      disableSubmit={!canSubmit}
      textSubmit={isEdit ? t("common:buttonSave") : t("common:buttonCreate")}
      additionalButtons={[
        {
          label: "Advanced",
          value: "advanced",
        },
      ]}
      disabledAdditionalButtons={processing}
      onAdditionalButtonClick={() => setShowAdvancedDialog(true)}
    >
      <div className="flex flex-col gap-4">
        <LabelEditMinimized
          value={value}
          onChanged={handleChangeColor}
          onHasErrorChanged={handleHasErrorsChanged}
          observations={observations}
          projectObsAnnotationTypes={projectObsAnnotationTypes}
        />
        <OverwriteLabelDialog
          open={showOverwriteDialog}
          onClose={() => setShowOverwriteDialog(false)}
          onSubmit={(option) => submit(option)}
          disabledSubmit={processing}
        />
        <AdvancedLabelComponent
          open={showAdvancedDialog}
          value={value}
          onClose={() => setShowAdvancedDialog(false)}
          onSubmit={handleAdvancedSubmit}
          observations={observations}
          projectObsAnnotationTypes={projectObsAnnotationTypes}
        />
      </div>
    </VBModal>
  );
};
