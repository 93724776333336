/*
 * File: label-filter.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd July 2023 2:50:08 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { Filter, Option } from "domain/common";
import { useState } from "react";
import { QueryApi } from "data-access/impl/query";
import useLabelOptions from "../../../hooks/use-label-options";

interface Props {
  filter: Filter;
  onFilterChange(filter: Filter): void;
}

export function LabelFilter({ filter, onFilterChange }: Props) {
  const [labelFilter] = useState({ page: 0, size: 10000 });

  const {
    groupOptions: groupLabelOptions,
    option: selectedLabels,
    onValueChange: onLabelsChange,
  } = useLabelOptions(labelFilter);

  return (
    <div className="px-4">
      <VBSelectComponent
        header="Label"
        placeholder="Select Label"
        value={selectedLabels}
        options={groupLabelOptions}
        isClearable
        menuPortalTarget={document.body}
        isMulti
        size="none"
        formatGroupLabel={(data) => {
          return (
            <div key={data.label} className="flex items-center justify-between">
              <span>{data.label}</span>
              <span>{data.options.length}</span>
            </div>
          );
        }}
        onChange={(newOptions) => {
          let labelIds: number[] = [];
          if (newOptions) {
            labelIds = (newOptions as Option[]).map((option) =>
              parseInt(option.value)
            );
          }
          onLabelsChange(labelIds);
          const fileFilter = new QueryApi.FileFilter(filter);
          fileFilter.setLabelIds(labelIds);
          onFilterChange(fileFilter.toFilter());
        }}
      />
    </div>
  );
}
