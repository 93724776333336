/*
 * File: batch-labeling.slice.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th December 2021 4:27:35 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { collectionUtils } from "domain/common";
import { isMedicalMamoProject } from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import { getBreastDicomTagsDataForSortingViews } from "utilities/dicom/dicom.utils";
import { Point } from "utilities/math/point";
import {
  BatchLabelingState,
  INITIAL_BATCH_LABELING_STATE,
  JobStatus,
} from "./batch-labeling.state";
import { batchLabelingReducerBuilder } from "./batch-labeling.thunk";

const SLICE_NAME: string = "batch-labeling";

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_BATCH_LABELING_STATE,
  reducers: {
    resetBatchLabelingState(state: BatchLabelingState) {
      Object.assign(state, INITIAL_BATCH_LABELING_STATE);
    },
    imageLabelingContextMenuOpened: (
      state: BatchLabelingState,
      action: PayloadAction<{
        jobId: number;
        position: Point;
      }>
    ) => {
      state.contextMenu = action.payload;
    },
    imageLabelingContextMenuClosed: (state: BatchLabelingState) => {
      state.contextMenu = undefined;
    },
    imageLabelingViewsSelected: (
      state: BatchLabelingState,
      action: PayloadAction<number>
    ) => {
      const jobIds = state.imageLabelingJobs.allIds as number[];
      if (jobIds.length === 0) return;
      state.imageViews = Array.from({ length: action.payload }, (_, i) => -1);
      for (let i = 0; i < Math.min(action.payload, jobIds.length); i++) {
        state.imageViews[i] = -1;
      }
      state.imageViews[0] = jobIds[0];
      state.activeViewIndex = 0;
      state.currentJobId = jobIds[0];
    },
    imageLabelingViewActivated: (
      state: BatchLabelingState,
      action: PayloadAction<number>
    ) => {
      state.activeViewIndex = action.payload;
      state.currentJobId = state.imageViews[state.activeViewIndex];
    },
    imageLabelingViewDragEnd: (
      state: BatchLabelingState,
      action: PayloadAction<{
        jobId: number;
        index: number;
      }>
    ) => {
      state.imageViews[action.payload.index] = action.payload.jobId;
    },
    jobSelected: (state: BatchLabelingState, action: PayloadAction<number>) => {
      state.currentJobId = action.payload;
      if (!state.jobRequestStatuses.hasOwnProperty(action.payload)) {
        state.jobRequestStatuses[action.payload] = RequestStatus.LOADING;
      }
      let index = state.imageViews.findIndex((id) => id === action.payload);
      if (index !== -1) {
        state.activeViewIndex = index;
      } else {
        state.activeViewIndex = 0;
        state.imageViews[0] = state.currentJobId;
      }
    },
    jobObservationEdited: (
      state: BatchLabelingState,
      action: PayloadAction<number>
    ) => {
      state.jobStatuses[action.payload] = JobStatus.WORKING;
    },

    jobObservationSaved: (
      state: BatchLabelingState,
      action: PayloadAction<number>
    ) => {
      state.jobStatuses[action.payload] = JobStatus.SAVED;
    },
    jobCompleted: (
      state: BatchLabelingState,
      action: PayloadAction<number>
    ) => {
      const jobId = action.payload;
      collectionUtils.removeOne(state.imageLabelingJobs, jobId);
      delete state.jobRequestStatuses[jobId];
      delete state.jobStatuses[jobId];
      if (state.imageLabelingJobs.allIds.length === 0) {
        Object.assign(state, INITIAL_BATCH_LABELING_STATE);
        return;
      }
      state.imageViews = state.imageViews.map((id) => {
        if (id === jobId) return -1;
        return id;
      });
      const idx = state.imageViews.findIndex((id) => id !== -1);
      if (idx !== -1) {
        state.activeViewIndex = idx;
        state.currentJobId = state.imageViews[idx];
      } else {
        state.currentJobId = state.imageLabelingJobs.allIds[0] as number;
        state.imageViews = [state.currentJobId];
        state.activeViewIndex = 0;
        if (!state.jobRequestStatuses.hasOwnProperty(state.currentJobId)) {
          state.jobRequestStatuses[state.currentJobId] = RequestStatus.LOADING;
        }
      }
    },
    sortJobsInViewIfMedicalMamoProjectAndLastJobImageLoaded: (
      state: BatchLabelingState,
      action: PayloadAction<number>
    ) => {
      if (
        !isMedicalMamoProject(state.project?.type, state.project?.pollStrategy)
      )
        return;

      const imageLabelingJobs = state.imageLabelingJobs;
      const jobIds = imageLabelingJobs.allIds;
      const lastJobIdInBatch = jobIds[jobIds.length - 1];
      const checkJobId = action.payload;

      if (
        lastJobIdInBatch !== checkJobId ||
        !jobIds.includes(checkJobId) ||
        state.isSortedLayoutFirstTime
      )
        return;

      const imageViews = state.imageViews.map((id) => id);

      // use this map for sorting layout based on
      // ViewPosition_Laterality
      // smaller means having higher priority
      let mapPriorities: Record<string, number> = {};
      // layout when having 2 images
      // MLO alwasy before CC
      if (imageViews.length < 4) {
        mapPriorities = {
          MLO_R: -4,
          MLO_L: -3,
          CC_R: -4,
          CC_L: -3,
          MLO: -4, // in case can't read L/R
          CC: -3,
        };
      }
      // layout when having 4 images
      if (imageViews.length >= 4) {
        mapPriorities = {
          MLO_R: -4,
          MLO_L: -3,
          CC_R: -2,
          CC_L: -1,
          MLO: -4, // in case can't read L/R
          CC: -3,
        };
      }
      // sort by priorities map
      imageViews.sort((jobId1: number, jobId2: number) => {
        const tagsData1 = getBreastDicomTagsDataForSortingViews(
          (imageLabelingJobs.entities as any)[jobId1].dicomData
        );
        const tagsData2 = getBreastDicomTagsDataForSortingViews(
          (imageLabelingJobs.entities as any)[jobId2].dicomData
        );
        const key1 = tagsData1.join("_");
        const key2 = tagsData2.join("_");
        const priority1 = !!mapPriorities[key1] ? mapPriorities[key1] : 0;
        const priority2 = !!mapPriorities[key2] ? mapPriorities[key2] : 0;

        return priority1 - priority2;
      });

      state.imageViews = imageViews;
      state.isSortedLayoutFirstTime = true;
    },

    countdownStopped(
      _: BatchLabelingState,
      __: PayloadAction<{ jobId: number; remainingSeconds: number }>
    ) {
      // TODO
    },
  },
  extraReducers: (builder) => {
    batchLabelingReducerBuilder(builder);
  },
});

export const {
  resetBatchLabelingState,
  jobSelected,
  imageLabelingContextMenuOpened,
  imageLabelingContextMenuClosed,
  jobObservationEdited,
  jobObservationSaved,
  jobCompleted,
  countdownStopped,
  imageLabelingViewsSelected,
  imageLabelingViewDragEnd,
  imageLabelingViewActivated,
  sortJobsInViewIfMedicalMamoProjectAndLastJobImageLoaded,
} = slice.actions;

export const batchLabelingReducer = slice.reducer;
