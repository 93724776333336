/*
 * File: use-batch.hook.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 27th August 2021 9:22:50 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useMemo } from "react";
import { RequestStatus } from "store/base/base.state";
import {
  selectBatchInfo,
  selectBatchWorkflow,
  selectBatchLabels,
  selectBatchStepLabelers,
  selectBatchRequestStatus,
  selectBatchRequestError,
  selectBatchInstructions,
  selectBatchWorkManagements,
  selectBatchPricing,
} from "store/customer/batch/batch.selectors";
import { loadBatchDataAsync } from "store/customer/batch/batch.thunk";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import * as Sentry from "@sentry/react";

export const useBatch = (batchId: string) => {
  const dispatch = useAppDispatch();
  const batch = useAppSelector(selectBatchInfo);
  const workflow = useAppSelector(selectBatchWorkflow);
  const workManagements = useAppSelector(selectBatchWorkManagements);
  const pricing = useAppSelector(selectBatchPricing);
  const labels = useAppSelector(selectBatchLabels);
  const batchInstructions = useAppSelector(selectBatchInstructions);
  const stepLabelers = useAppSelector(selectBatchStepLabelers);
  const requestStatus = useAppSelector(selectBatchRequestStatus);
  const requestError = useAppSelector(selectBatchRequestError);
  const labelers = useMemo(() => {
    return workManagements.map((workManagement) => ({
      email: workManagement.userId,
      dailyLimit: workManagement.dailyLimit,
    }));
  }, [workManagements]);

  useEffect(() => {
    async function loadBatchData(id: string) {
      try {
        const response = await dispatch(loadBatchDataAsync(id));
        handleThunkRejected(response);
      } catch (error: any) {
        Sentry.captureException(error);
        const errMessaage = error.message || "Failed to load batch data";
        dispatch(enqueueErrorNotification(errMessaage));
        Logger.log(error);
      }
    }
    if (requestStatus !== RequestStatus.IDLE) return;
    loadBatchData(batchId);
  }, [dispatch, batchId, requestStatus]);

  return {
    batch,
    batchInstructions,
    workflow,
    workManagements,
    pricing,
    labels,
    labelers,
    stepLabelers,
    requestStatus,
    requestError,
  };
};
