import { VBMaskRequesting } from "components/common/vb-mask-requesting/vb-mask-requesting.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useState } from "react";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { selectSelectedTextJobId } from "store/labeler/text-labeling-batch/text-labeling-batch.selectors";
import { textCompleteJobAsync } from "store/labeler/text-labeling-batch/thunks/text-complete-job.thunk";
import { saveTextJobAsync } from "store/labeler/text-labeling-batch/thunks/text-save-job.thunk";
import { validateTextJobAsync } from "store/labeler/text-labeling-batch/thunks/text-validate-job.thunk";
import {
  selectIsShowEditorSettings,
  selectIsShowKeyboardShortcuts,
} from "store/labeler/text-workspace/text-editor-setting/text-editor-setting.selectors";
import {
  editorSettingsClosed,
  keyboardShortcutsClosed,
} from "store/labeler/text-workspace/text-editor-setting/text-editor-setting.slice";
import { TextEditorSettingModal } from "../../components/text-editor-settings/text-editor-settings.component";
import { TextKeyboardShortcutsModal } from "../../components/text-keyboard-shortcuts/text-keyboard-shortcuts.component";
import { TextLabelingBatchContext } from "./text-labeling-batch.context";
import * as Sentry from "@sentry/react";

interface Props {
  children: JSX.Element;
}

export const TextLabelingBatchProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [isSavingJob, setIsSavingJob] = useState(false);
  const [isCompletingJob, setIsCompletingJob] = useState(false);
  const jobId = useAppSelector(selectSelectedTextJobId);
  const showKeyboardShortcuts = useAppSelector(selectIsShowKeyboardShortcuts);
  const showEditorSettings = useAppSelector(selectIsShowEditorSettings);

  function closeKeyboardShortcuts() {
    dispatch(keyboardShortcutsClosed());
  }

  function closeEditorSettings() {
    dispatch(editorSettingsClosed());
  }

  async function saveWorkingJob() {
    if (jobId === -1) return;
    if (isSavingJob) return;
    try {
      setIsSavingJob(true);
      const validateResult = await dispatch(validateTextJobAsync({ jobId }));
      if (validateResult.payload) throw validateResult.payload;
      await dispatch(saveTextJobAsync({ jobId }));
      setIsSavingJob(false);
    } catch (error: any) {
      Sentry.captureException(error);
      const errorMessage = "Failed to save this job";
      const message = error.message || errorMessage;
      dispatch(enqueueErrorNotification(message));
      setIsSavingJob(false);
    }
  }

  async function completeWorkingJob() {
    if (jobId === -1) return;
    if (isCompletingJob) return;
    try {
      setIsCompletingJob(true);
      const validateResult = await dispatch(validateTextJobAsync({ jobId }));
      if (validateResult.payload) throw validateResult.payload;
      await dispatch(saveTextJobAsync({ jobId }));
      await dispatch(textCompleteJobAsync({ jobId }));
      setIsCompletingJob(false);
    } catch (error: any) {
      Sentry.captureException(error);
      const errorMessage = "Failed to complete this job";
      const message = error.message || errorMessage;
      dispatch(enqueueErrorNotification(message));
      setIsCompletingJob(false);
    }
  }

  const state = {
    isSavingJob,
    isCompletingJob,
    saveWorkingJob,
    completeWorkingJob,
  };

  return (
    <TextLabelingBatchContext.Provider value={state}>
      {children}
      {isSavingJob && <VBMaskRequesting />}
      {isCompletingJob && <VBMaskRequesting />}
      {showKeyboardShortcuts && (
        <TextKeyboardShortcutsModal open onClose={closeKeyboardShortcuts} />
      )}
      {showEditorSettings && (
        <TextEditorSettingModal open onClose={closeEditorSettings} />
      )}
    </TextLabelingBatchContext.Provider>
  );
};
