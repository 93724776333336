export interface ServingWebRequestDTO {
  name: string;
  description: string;
  modelMappingId: number;
  data: any;
}

export enum ServingWebStatus {
  UP = "UP",
  DOWN = "DOWN",
}
export interface ServingWebHealthResponseDTO {
  status: ServingWebStatus;
}

export interface ServingWebResponseDTO {
  id: string;
  name: string;
  type: string;
  description: string;
  modelMappingId: number;
  data: any;
  lastActivity: string;
  status: string;
  additionalInfo: any;
  createdBy: string;
}

export interface PublishWebResponseDTO {
  succeeded: boolean;
}
