/*
 * File: text-conflict.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 9th August 2022 10:15:06 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { collectionUtils } from "domain/common";
import { RootState } from "store";

export const selectTextConflictAnnotators = (state: RootState) => {
  return state.textWorkspace.textConflict.labelingDatas.map(
    (data) => data.annotator
  );
};

export const selectTextConflictSelectedAnnotators = (state: RootState) => {
  return state.textWorkspace.textConflict.selectedAnnotators;
};

export const selectTextConflictLabelingData = (state: RootState) => {
  const {
    annotations,
    relationAnnotations,
    systemAnnotations,
    selectedAnnotators,
  } = state.textWorkspace.textConflict;
  const filteredAnnotations = annotations.allIds
    .map((id) => annotations.entities[id])
    .filter((anno) =>
      anno.annotators?.find((a) => selectedAnnotators?.includes(a))
    );
  const filteredRelations = relationAnnotations.allIds
    .map((id) => relationAnnotations.entities[id])
    .filter((anno) =>
      anno.annotators?.find((a) => selectedAnnotators?.includes(a))
    );
  return {
    annotations: filteredAnnotations,
    relationAnnotations: filteredRelations,
    systemAnnotations,
  };
};

export const selectTextConflictSelectedAnnotation = (state: RootState) => {
  const { selectedAnnotationId, annotations, relationAnnotations } =
    state.textWorkspace.textConflict;
  if (!selectedAnnotationId) return null;
  if (collectionUtils.hasOne(annotations, selectedAnnotationId)) {
    return collectionUtils.getOne(annotations, selectedAnnotationId);
  }
  if (collectionUtils.hasOne(relationAnnotations, selectedAnnotationId)) {
    return collectionUtils.getOne(relationAnnotations, selectedAnnotationId);
  }
  return null;
};
