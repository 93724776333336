/*
 * File: storage-col-def-compact.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 31st August 2021 11:49:34 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { VBDatagridCellHoverDisplay } from "../../../../../../../components/common/vb-datagrid/vb-datagrid-cell-hover-display";
import { GridColDef } from "@material-ui/data-grid";
import { CreatedByHeader } from "./headers/created-by-header";
import { CreatedDateHeader } from "./headers/created-date-header";
import { FileNameHeader } from "./headers/file-name-header";
import { TagsHeader } from "./headers/tags-header";
import { ActionsHeader } from "./headers/actions-header";
import { VBDatagridCellActions } from "components/common/vb-datagrid/vb-datagrid-cell-actions";

export const columns: GridColDef[] = [
  {
    field: "fileName",
    filterable: false,
    flex: 2,
    renderHeader: FileNameHeader,
    renderCell: VBDatagridCellHoverDisplay,
  },
  {
    field: "createdDate",
    filterable: false,
    flex: 2,
    renderHeader: CreatedDateHeader,
    renderCell: VBDatagridCellHoverDisplay,
  },
  {
    field: "createdBy",
    filterable: false,
    flex: 2,
    renderHeader: CreatedByHeader,
    renderCell: VBDatagridCellHoverDisplay,
  },
  {
    field: "tags",
    filterable: false,
    sortable: false,
    flex: 2,
    renderHeader: TagsHeader,
    renderCell: VBDatagridCellHoverDisplay,
  },
  {
    field: "actions",
    filterable: false,
    sortable: false,
    flex: 2,
    renderHeader: ActionsHeader,
    renderCell: VBDatagridCellActions,
  },
];
