/*
 * File: workspace-users.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 15th November 2021 6:50:13 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useMount } from "ahooks";
import { VBMaskRequesting } from "components/common/vb-mask-requesting/vb-mask-requesting.component";
import { useQuery } from "hooks/use-query";
import { useQueryParams } from "hooks/use-query-params.hook";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useWorkspaces } from "hooks/workspace/use-workspace.hook";
import { SearchParams } from "models/common/search-params";
import { useState } from "react";
import {
  selectTotalUsers,
  selectUsers,
} from "store/customer/users/users.selectors";
import { userFilterChanged } from "store/customer/users/users.slice";
import { loadUsersAsync } from "store/customer/users/users.thunk";
import useDeepCompareEffect from "use-deep-compare-effect";
import { WorkspaceUsersContext } from "./workspace-users.context";
import { UserFilter } from "./workspace-users.state";

interface Props {
  children: JSX.Element;
}

export const WorkspaceUsersProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [requesting, setRequesting] = useState(false);
  const { workspace } = useWorkspaces();
  const { navigateByQuery, navigateByParams } = useQuery();
  const filter = useQueryParams({
    email: "",
    name: "",
    role: "",
    lastUpdate: "",
    lastLogin: "",
    status: "",
    page: "1",
    pageSize: "15",
    sort: "",
  });
  const totalUsers = useAppSelector(selectTotalUsers);
  const filteredUsers = useAppSelector(selectUsers);

  useMount(() => {
    dispatch(loadUsersAsync());
  });

  useDeepCompareEffect(() => {
    dispatch(userFilterChanged(filter as UserFilter));
  }, [dispatch, filter]);

  const setFilter = (userFilter: UserFilter) => {
    navigateByParams(userFilter);
  };

  const setPage = (page: number) => {
    navigateByQuery(SearchParams.PAGE, page.toString());
  };

  const setPageSize = (size: number) => {
    navigateByParams({
      [SearchParams.PAGE]: "1",
      [SearchParams.PAGE_SIZE]: size.toString(),
    });
  };

  const setSort = (field: string, sort?: string) => {
    navigateByQuery(SearchParams.SORT, `${field}${sort ? `,${sort}` : ""}`);
  };

  const setName = (name: string) => {
    navigateByQuery(SearchParams.NAME, name);
  };

  const setEmail = (email: string) => {
    navigateByQuery(SearchParams.EMAIL, email);
  };

  const setRole = (role: string) => {
    navigateByQuery(SearchParams.ROLE, role);
  };

  const setStatus = (status: string) => {
    navigateByQuery(SearchParams.STATUS, status);
  };

  const setLastUpdate = (lastUpdate: string) => {
    navigateByQuery(SearchParams.LAST_UPDATE, lastUpdate);
  };

  const setLastLogin = (lastLogin: string) => {
    navigateByQuery(SearchParams.LAST_LOGIN, lastLogin);
  };

  const state = {
    users: filteredUsers,
    totalUsers: totalUsers,
    filter: filter as UserFilter,
    setFilter,
    workspace,
    requesting,
    setRequesting,
    setPage,
    setPageSize,
    setSort,
    setName,
    setEmail,
    setRole,
    setStatus,
    setLastUpdate,
    setLastLogin,
  };

  return (
    <WorkspaceUsersContext.Provider value={state}>
      {children}
      {requesting && <VBMaskRequesting />}
    </WorkspaceUsersContext.Provider>
  );
};
