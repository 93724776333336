/*
 * File: upload-ai-model.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 3rd November 2022 5:39:19 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import classNames from "classnames";
import { IconClose } from "components/common/vb-icon.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBFileInputComponent } from "components/design-system/file-input/file-input.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { VBTextAreaComponent } from "components/design-system/text-area/text-area.component";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import ConfigService from "configs/app-config";
import { useAppContext } from "contexts/app/app.context";
import fileSize from "filesize";
import { useFormik } from "formik";
import { useMemo } from "react";
import { mathUtils } from "utilities/math";
import { useAIModelsContext } from "../context/ai-models.context";
import { uploadAIModelValidationSchema } from "../validation/upload-ai-model-validation.schema";

interface UploadAIModelProps {
  onClose(): void;
}
export default function UploadAIModel({ onClose }: UploadAIModelProps) {
  const { appConfig } = useAppContext();
  const inferenceOptions = useMemo(() => {
    return ConfigService.getInferenceOptions(appConfig);
  }, [appConfig]);
  const { uploadProgress, isImporting, startImportAIModel } =
    useAIModelsContext();
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      inferenceType: "",
      file: null,
    },
    validationSchema: uploadAIModelValidationSchema,
    onSubmit: startImportAIModel,
  });
  const progressInPercent = mathUtils.clamp(uploadProgress * 100, 0, 100);
  return (
    <VBModal
      open
      title="Upload a new model"
      textSubmit="Upload"
      textClose="Cancel"
      onClose={onClose}
      footerHidden
    >
      <div
        className={classNames("w-full space-y-6", {
          "pointer-events-none": isImporting,
        })}
      >
        <form className="w-full space-y-6" onSubmit={formik.handleSubmit}>
          <VBTextInputComponent
            id="name"
            name="name"
            require
            clearInput
            headerClassName=""
            header="Name"
            placeholder="Enter model name"
            onInputSubmit={(value: string) => {
              formik.setFieldValue("name", value);
            }}
            inputIcon={<></>}
            hint={formik.touched.name ? formik.errors.name : undefined}
          />
          <VBTextAreaComponent
            id="description"
            name="description"
            header="Description"
            placeholder="Enter model description"
            onInputChange={(value: string) => {
              formik.setFieldValue("description", value);
            }}
            hint={
              formik.touched.description ? formik.errors.description : undefined
            }
          />

          <VBSelectComponent
            header="Inference Type"
            required
            id="inferenceType"
            name="inferenceType"
            options={inferenceOptions}
            value={inferenceOptions.find(
              (option) => option.value === formik.values.inferenceType
            )}
            onChange={(option: any) => {
              formik.setFieldValue("inferenceType", option?.value ?? "");
            }}
            hint={
              formik.touched.inferenceType
                ? formik.errors.inferenceType
                : undefined
            }
          />

          {(!formik.values.file || formik.errors.file) && (
            <VBFileInputComponent
              header="Zip file"
              require
              accept=".zip"
              onFileChange={(value: File | null) =>
                formik.setFieldValue("file", value)
              }
              hint={formik.touched.file ? formik.errors.file : undefined}
            />
          )}

          {formik.values.file && !formik.errors.file && (
            <div>
              <div className="flex items-start w-full gap-2 overflow-hidden">
                <div className="flex-auto">
                  <div className="w-full truncate" style={{ maxWidth: 340 }}>
                    {(formik.values.file as File).name}
                  </div>
                  <p>{fileSize((formik.values.file as File).size)}</p>
                </div>
                <button
                  className="flex-none p-1"
                  onClick={() => formik.setFieldValue("file", null)}
                >
                  <IconClose className="flex-none w-5 h-5" />
                </button>
              </div>
              {isImporting && (
                <div className="py-4 space-y-2">
                  <div>
                    {progressInPercent >= 100
                      ? "Processing your files. Please wait a few moments..."
                      : `Uploading (${Math.floor(progressInPercent)}%)`}
                  </div>
                  {progressInPercent < 100 && (
                    <div className="w-full h-2 rounded-full bg-background-300">
                      <div
                        className="h-2 rounded-full bg-primary"
                        style={{ width: `${Math.floor(progressInPercent)}%` }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          <div className="flex items-center justify-end gap-4">
            <button onClick={onClose} className="button-text-secondary">
              Cancel
            </button>
            <button
              type="submit"
              className={classNames("button-text-primary", {
                "opacity-80": isImporting,
              })}
              disabled={isImporting}
            >
              {isImporting ? "Importing..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </VBModal>
  );
}
