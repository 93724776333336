/*
 * File: batch-labeling.state.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th December 2021 4:22:56 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Collection } from "domain/common";
import { Label } from "domain/image-labeling";
import { Job } from "domain/labeling/job";
import { LabelingJobData } from "domain/labeling/labeling-job";
import { Project } from "domain/labeling/project";
import { Task } from "domain/labeling/task";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { BatchDTO } from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import { Point } from "utilities/math/point";

export interface ImageLabelingJob {
  id: number;
  job: Job;
  project?: Project;
  batch?: BatchDTO;
  task?: Task;
  file?: StorageFileDTO;
  data?: LabelingJobData;
  labels: Label[];
  dicomData?: any;
  metadata?: ImageLabelingJobMetadata;
}

export interface ImageLabelingJobMetadata {
  numFrames: number;
  frameRate?: number;
}

export interface BatchLabelingState {
  pollStrategy: string | undefined;
  project?: Project;
  imageLabelingJobs: Collection<ImageLabelingJob>;
  jobRequestStatuses: { [key: number]: RequestStatus };
  jobStatuses: { [key: number]: JobStatus };
  currentJobId: number;
  requestStatus: RequestStatus;
  activeViewIndex: number;
  imageViews: number[];
  contextMenu?: { jobId: number; position: Point };
  labels: Collection<Label>;
  isSortedLayoutFirstTime: boolean;
}

export enum JobStatus {
  NEW = "new",
  WORKING = "working",
  SAVED = "saved",
}

export const INITIAL_BATCH_LABELING_STATE: BatchLabelingState = {
  pollStrategy: undefined,
  project: undefined,
  imageLabelingJobs: { allIds: [], entities: {} },
  labels: { allIds: [], entities: {} },
  currentJobId: -1,
  contextMenu: undefined,
  requestStatus: RequestStatus.IDLE,
  jobRequestStatuses: {},
  jobStatuses: {},
  activeViewIndex: 0,
  imageViews: [-1],
  isSortedLayoutFirstTime: false,
};
