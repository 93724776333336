import { DataGrid, GridColDef, GridSortModel } from "@material-ui/data-grid";
import { VBDatagridCellHoverDisplay } from "components/common/vb-datagrid/vb-datagrid-cell-hover-display";
import { IconAdd } from "components/common/vb-icon.component";
import { useDataCleaningJob } from "hooks/data-cleaning/data-cleaning.hook";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataCleaningDTO } from "services/storage/dto/data-cleaning.dto";
import { BatchDataCleaningCreateModal } from "./batch-data-cleaning-create.modal";
import { BatchDataCleaningDeleteModal } from "./batch-data-cleaning-delete.modal";
import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import { RequestStatus } from "store/base/base.state";
import { BatchStatus } from "services/label-service/dtos";
import { VBSpacer } from "components/common/vb-spacer.component";
import { useBatchDetailContext } from "../../context/batch-detail.context";

export const BatchDataCleaningPage = () => {
  const { t } = useTranslation();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { batch } = useBatchDetailContext();
  const isCompletedBatch = useMemo(() => {
    return batch?.status === BatchStatus.COMPLETED;
  }, [batch]);

  const {
    dataCleaningJobs,
    setDataCleaningJobs,
    requestStatus,
    page,
    setPage,
    size,
    setSize,
    setSort,
    total,
  } = useDataCleaningJob(batch?.id, batch?.datasetId);

  const rows = useMemo(() => {
    return dataCleaningJobs.map((job) => ({
      id: job.id,
      name: job.name,
      type: job.type,
      status: job.status,
    }));
  }, [dataCleaningJobs]);

  if (!batch) return null;

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("project:batchDetails.batchDatacleaning.table.headerId"),
      flex: 1,
      renderCell: VBDatagridCellHoverDisplay,
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: t("project:batchDetails.batchDatacleaning.table.headerName"),
      flex: 2,
      renderCell: VBDatagridCellHoverDisplay,
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      field: "type",
      headerName: t("project:batchDetails.batchDatacleaning.table.headerType"),
      flex: 2,
      renderCell: VBDatagridCellHoverDisplay,
      minWidth: 200,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: t(
        "project:batchDetails.batchDatacleaning.table.headerStatus"
      ),
      flex: 2,
      renderCell: VBDatagridCellHoverDisplay,
      minWidth: 200,
      disableColumnMenu: true,
    },
  ];

  const handleCreateModalClose = () => {
    setOpenCreateModal(false);
  };

  const handleCreateModalSubmit = (dataCleaningJob: DataCleaningDTO) => {
    setDataCleaningJobs([dataCleaningJob, ...dataCleaningJobs]);
  };

  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
  };

  const handleSortChanged = (model: GridSortModel) => {
    if (model && model.length > 0) {
      const { field, sort } = model[0];
      setSort(`${field},${sort}`);
    }
  };

  return (
    <div className="flex flex-col w-full h-full px-4">
      <div className="flex justify-between w-full py-2 mt-4">
        <VBSpacer />
        <button
          className="button-primary disabled:opacity-50"
          onClick={(_) => setOpenCreateModal(true)}
          disabled={isCompletedBatch}
        >
          <IconAdd className="w-5 h-5" />
          <span>
            {t("project:batchDetails.batchDataCleaning.buttonAddJob")}
          </span>
        </button>
      </div>
      <div className="flex-1 w-full h-full mt-4">
        <DataGrid
          className="bg-white"
          loading={requestStatus === RequestStatus.LOADING}
          rows={rows}
          columns={columns}
          onSortModelChange={handleSortChanged}
          sortingMode="server"
          filterMode="server"
          headerHeight={80}
          hideFooter
          hideFooterPagination
        />
      </div>

      <BatchDataCleaningCreateModal
        batch={batch}
        open={openCreateModal}
        onClose={handleCreateModalClose}
        onCreated={handleCreateModalSubmit}
      />

      <BatchDataCleaningDeleteModal
        batch={batch}
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
      />

      <div className="flex-shrink-0 my-4">
        <GridPagination
          page={page}
          pageSize={size}
          pageSizeOptions={[10, 20, 25, 50, 75, 100]}
          onPageSizeChange={setSize}
          onPageChange={setPage}
          totalItems={total}
        />
      </div>
    </div>
  );
};
