import { AxiosResponse } from "axios";
import { JobDTO, TaskObservationDTO } from "../dtos";
import {
  AnnotationRequest,
  TextAnnotationsResponse,
} from "../dtos/task-observation-v2.dto";
import { BaseAPI } from "./base.api";

export class TaskObservationV2API extends BaseAPI<any> {
  saveTextJobAnnotations(payload: any) {
    return this.post(this.getPath(), payload);
  }

  saveJobAnnotations(payload: AnnotationRequest[]) {
    return this.post(this.getPath(), payload);
  }

  getPreviousStepJobsTaskObservations(jobId: number) {
    return this.get(`${this.getPath()}/jobs/${jobId}`);
  }

  getTextJobAnnotations(
    jobId: number
  ): Promise<AxiosResponse<TextAnnotationsResponse[]>> {
    const params = new URLSearchParams({ size: "10000" });
    return this.get(`${this.getPath()}/jobs/${jobId}?${params.toString()}`);
  }

  saveTaskAnnotations(payload: {
    job: JobDTO;
    observations: Partial<TaskObservationDTO>[];
  }) {
    return this.post(this.getPath(), payload);
  }

  getJobAnnotations(
    jobId: number
  ): Promise<AxiosResponse<TaskObservationDTO[]>> {
    const params = new URLSearchParams({ size: "10000" });
    return this.get(`${this.getPath()}/jobs/${jobId}?${params.toString()}`);
  }

  saveTextAnnotations(payload: any) {
    return this.post(`${this.getPath()}/text`, payload);
  }
}
