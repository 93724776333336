/* tslint:disable */
/* eslint-disable */
/**
 * query-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdditionalFileInfoDTO
 */
export interface AdditionalFileInfoDTO {
    /**
     * 
     * @type {number}
     * @memberof AdditionalFileInfoDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdditionalFileInfoDTO
     */
    'fileInfoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdditionalFileInfoDTO
     */
    'extend'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalFileInfoDTO
     */
    'contentType'?: string;
    /**
     * 
     * @type {{ [key: string]: any | undefined; }}
     * @memberof AdditionalFileInfoDTO
     */
    'addition'?: { [key: string]: any | undefined; };
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalFileInfoDTO
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface AnnotationAttributeResponseDTO
 */
export interface AnnotationAttributeResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof AnnotationAttributeResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationAttributeResponseDTO
     */
    'annotationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationAttributeResponseDTO
     */
    'attributeId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnnotationAttributeResponseDTO
     */
    'values'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AnnotationCriteria
 */
export interface AnnotationCriteria {
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnotationCriteria
     */
    'batchIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnotationCriteria
     */
    'mediaIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnotationCriteria
     */
    'jobIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnotationCriteria
     */
    'taskObservationIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnotationCriteria
     */
    'categoryIds'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnnotationCriteria
     */
    'sources'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AnnotationRelationResponseDTO
 */
export interface AnnotationRelationResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof AnnotationRelationResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnotationRelationResponseDTO
     */
    'directed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnotationRelationResponseDTO
     */
    'first'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationRelationResponseDTO
     */
    'second'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationRelationResponseDTO
     */
    'localGroup'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnotationRelationResponseDTO
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface AnnotationResponseDTO
 */
export interface AnnotationResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof AnnotationResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnotationResponseDTO
     */
    'latest'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnotationResponseDTO
     */
    'mediaId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationResponseDTO
     */
    'categoryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationResponseDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationResponseDTO
     */
    'assignee'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationResponseDTO
     */
    'jobId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationResponseDTO
     */
    'taskObservationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationResponseDTO
     */
    'annotationType'?: string;
    /**
     * 
     * @type {{ [key: string]: any | undefined; }}
     * @memberof AnnotationResponseDTO
     */
    'annotation'?: { [key: string]: any | undefined; };
    /**
     * 
     * @type {string}
     * @memberof AnnotationResponseDTO
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationResponseDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationResponseDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: any | undefined; }>}
     * @memberof AnnotationResponseDTO
     */
    'conflictInfos'?: Array<{ [key: string]: any | undefined; }>;
    /**
     * 
     * @type {boolean}
     * @memberof AnnotationResponseDTO
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface AnnotationSearchRequestDTO
 */
export interface AnnotationSearchRequestDTO {
    /**
     * 
     * @type {Roi}
     * @memberof AnnotationSearchRequestDTO
     */
    'roi'?: Roi;
}
/**
 * 
 * @export
 * @interface BBox
 */
export interface BBox {
    /**
     * 
     * @type {number}
     * @memberof BBox
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof BBox
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof BBox
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof BBox
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface FileInfoAggregatedCriteria
 */
export interface FileInfoAggregatedCriteria {
    /**
     * 
     * @type {Array<number>}
     * @memberof FileInfoAggregatedCriteria
     */
    'ids'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof FileInfoAggregatedCriteria
     */
    'batchId': number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoAggregatedCriteria
     */
    'taskId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoAggregatedCriteria
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoAggregatedCriteria
     */
    'labelingStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoAggregatedCriteria
     */
    'metadataKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoAggregatedCriteria
     */
    'metadataValue'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FileInfoAggregatedCriteria
     */
    'labelers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FileInfoAggregatedCriteria
     */
    'datasourceName'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoAggregatedCriteria
     */
    'observationId'?: number;
    /**
     * unix timestamp (milliseconds) in UTC for a date
     * @type {number}
     * @memberof FileInfoAggregatedCriteria
     */
    'createdDate'?: number;
    /**
     * unix timestamp (milliseconds) in UTC for a date
     * @type {number}
     * @memberof FileInfoAggregatedCriteria
     */
    'lastModifiedDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoAggregatedCriteria
     */
    'issueStatus'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoAggregatedCriteria
     */
    'consensusScoreGt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoAggregatedCriteria
     */
    'consensusScoreLt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoAggregatedCriteria
     */
    'workInstructionId'?: number;
}
/**
 * 
 * @export
 * @interface FileInfoAggregatedResponseDTO
 */
export interface FileInfoAggregatedResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'processStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'referenceUrl'?: string;
    /**
     * 
     * @type {Array<MetadataResponseDTO>}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'metadata'?: Array<MetadataResponseDTO>;
    /**
     * 
     * @type {AdditionalFileInfoDTO}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'additionalFileInfo'?: AdditionalFileInfoDTO;
    /**
     * 
     * @type {Array<IssueResponseDTO>}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'issues'?: Array<IssueResponseDTO>;
    /**
     * 
     * @type {Array<AnnotationResponseDTO>}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'annotations'?: Array<AnnotationResponseDTO>;
    /**
     * 
     * @type {Array<AnnotationRelationResponseDTO>}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'annotationRelations'?: Array<AnnotationRelationResponseDTO>;
    /**
     * 
     * @type {Array<TaskResponseDTO>}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'tasks'?: Array<TaskResponseDTO>;
    /**
     * 
     * @type {Array<JobResponseDTO>}
     * @memberof FileInfoAggregatedResponseDTO
     */
    'jobs'?: Array<JobResponseDTO>;
}
/**
 * 
 * @export
 * @interface FileInfoCriteria
 */
export interface FileInfoCriteria {
    /**
     * 
     * @type {number}
     * @memberof FileInfoCriteria
     */
    'datasetId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof FileInfoCriteria
     */
    'ids'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'fileName'?: string;
    /**
     * unix timestamp (milliseconds) in UTC for a date
     * @type {number}
     * @memberof FileInfoCriteria
     */
    'createdDate'?: number;
    /**
     * unix timestamp (milliseconds) in UTC for a date
     * @type {number}
     * @memberof FileInfoCriteria
     */
    'lastModifiedDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'createdBy'?: string;
    /**
     * 
     * @type {Array<MetadataRequestDTO>}
     * @memberof FileInfoCriteria
     */
    'metadata'?: Array<MetadataRequestDTO>;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'storeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'scopeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'datasetCategory'?: FileInfoCriteriaDatasetCategoryEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof FileInfoCriteria
     */
    'observationId'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof FileInfoCriteria
     */
    'attributeId'?: number;
}

export const FileInfoCriteriaDatasetCategoryEnum = {
    Image: 'image',
    Dicom: 'dicom',
    Text: 'text',
    Audio: 'audio',
    Mdi: 'mdi',
    Video: 'video',
    Wsi: 'wsi'
} as const;

export type FileInfoCriteriaDatasetCategoryEnum = typeof FileInfoCriteriaDatasetCategoryEnum[keyof typeof FileInfoCriteriaDatasetCategoryEnum];

/**
 * 
 * @export
 * @interface FileInfoResponseDTO
 */
export interface FileInfoResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof FileInfoResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoResponseDTO
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoResponseDTO
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoResponseDTO
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoResponseDTO
     */
    'fileSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoResponseDTO
     */
    'processStatus'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoResponseDTO
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoResponseDTO
     */
    'storeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoResponseDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoResponseDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoResponseDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {Array<MetadataResponseDTO>}
     * @memberof FileInfoResponseDTO
     */
    'metadata'?: Array<MetadataResponseDTO>;
    /**
     * 
     * @type {string}
     * @memberof FileInfoResponseDTO
     */
    'referenceUrl'?: string;
    /**
     * 
     * @type {AdditionalFileInfoDTO}
     * @memberof FileInfoResponseDTO
     */
    'additionalFileInfo'?: AdditionalFileInfoDTO;
}
/**
 * 
 * @export
 * @interface HealthGetResponse
 */
export interface HealthGetResponse {
    /**
     * 
     * @type {string}
     * @memberof HealthGetResponse
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface IssueResponseDTO
 */
export interface IssueResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof IssueResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IssueResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueResponseDTO
     */
    'taskId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueResponseDTO
     */
    'jobId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueResponseDTO
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueResponseDTO
     */
    'referenceId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IssueResponseDTO
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface JobResponseDTO
 */
export interface JobResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof JobResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobResponseDTO
     */
    'assignee'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobResponseDTO
     */
    'task_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobResponseDTO
     */
    'batch_id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JobResponseDTO
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface JobStatisticBatchCriteria
 */
export interface JobStatisticBatchCriteria {
    /**
     * 
     * @type {Array<string>}
     * @memberof JobStatisticBatchCriteria
     */
    'statusList'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobStatisticBatchCriteria
     */
    'labelers'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof JobStatisticBatchCriteria
     */
    'steps'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof JobStatisticBatchCriteria
     */
    'rounds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticBatchCriteria
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticBatchCriteria
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface JobStatisticBatchResponseDTO
 */
export interface JobStatisticBatchResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof JobStatisticBatchResponseDTO
     */
    'totalPayment'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticBatchResponseDTO
     */
    'totalTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticBatchResponseDTO
     */
    'totalJob'?: number;
    /**
     * 
     * @type {Array<JobStatisticBatchRowResponseDTO>}
     * @memberof JobStatisticBatchResponseDTO
     */
    'data'?: Array<JobStatisticBatchRowResponseDTO>;
}
/**
 * 
 * @export
 * @interface JobStatisticBatchRowResponseDTO
 */
export interface JobStatisticBatchRowResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof JobStatisticBatchRowResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticBatchRowResponseDTO
     */
    'taskId'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticBatchRowResponseDTO
     */
    'mediaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobStatisticBatchRowResponseDTO
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticBatchRowResponseDTO
     */
    'step'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticBatchRowResponseDTO
     */
    'round'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobStatisticBatchRowResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatisticBatchRowResponseDTO
     */
    'labeler'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticBatchRowResponseDTO
     */
    'time'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticBatchRowResponseDTO
     */
    'payment'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticBatchRowResponseDTO
     */
    'currencyId'?: number;
}
/**
 * 
 * @export
 * @interface JobStatisticLabelerBatchCriteria
 */
export interface JobStatisticLabelerBatchCriteria {
    /**
     * 
     * @type {Array<string>}
     * @memberof JobStatisticLabelerBatchCriteria
     */
    'statusList'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof JobStatisticLabelerBatchCriteria
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatisticLabelerBatchCriteria
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerBatchCriteria
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerBatchCriteria
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface JobStatisticLabelerBatchResponseDTO
 */
export interface JobStatisticLabelerBatchResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerBatchResponseDTO
     */
    'jobCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerBatchResponseDTO
     */
    'batchCount'?: number;
    /**
     * 
     * @type {Array<JobStatisticLabelerBatchRowResponseDTO>}
     * @memberof JobStatisticLabelerBatchResponseDTO
     */
    'data'?: Array<JobStatisticLabelerBatchRowResponseDTO>;
}
/**
 * 
 * @export
 * @interface JobStatisticLabelerBatchRowResponseDTO
 */
export interface JobStatisticLabelerBatchRowResponseDTO {
    /**
     * job count
     * @type {number}
     * @memberof JobStatisticLabelerBatchRowResponseDTO
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobStatisticLabelerBatchRowResponseDTO
     */
    'assignee'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerBatchRowResponseDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobStatisticLabelerBatchRowResponseDTO
     */
    'batchName'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatisticLabelerBatchRowResponseDTO
     */
    'approvedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerBatchRowResponseDTO
     */
    'payment'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerBatchRowResponseDTO
     */
    'currencyId'?: number;
}
/**
 * 
 * @export
 * @interface JobStatisticLabelerCriteria
 */
export interface JobStatisticLabelerCriteria {
    /**
     * 
     * @type {Array<string>}
     * @memberof JobStatisticLabelerCriteria
     */
    'statusList'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof JobStatisticLabelerCriteria
     */
    'projectIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof JobStatisticLabelerCriteria
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatisticLabelerCriteria
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerCriteria
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerCriteria
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface JobStatisticLabelerResponseDTO
 */
export interface JobStatisticLabelerResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerResponseDTO
     */
    'totalPayment'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerResponseDTO
     */
    'totalTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerResponseDTO
     */
    'totalJob'?: number;
    /**
     * 
     * @type {Array<JobStatisticLabelerRowResponseDTO>}
     * @memberof JobStatisticLabelerResponseDTO
     */
    'data'?: Array<JobStatisticLabelerRowResponseDTO>;
}
/**
 * 
 * @export
 * @interface JobStatisticLabelerRowResponseDTO
 */
export interface JobStatisticLabelerRowResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerRowResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerRowResponseDTO
     */
    'taskId'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerRowResponseDTO
     */
    'mediaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobStatisticLabelerRowResponseDTO
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatisticLabelerRowResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerRowResponseDTO
     */
    'payment'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatisticLabelerRowResponseDTO
     */
    'currencyId'?: number;
}
/**
 * 
 * @export
 * @interface MetadataRequestDTO
 */
export interface MetadataRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof MetadataRequestDTO
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataRequestDTO
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface MetadataResponseDTO
 */
export interface MetadataResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof MetadataResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MetadataResponseDTO
     */
    'key_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataResponseDTO
     */
    'key_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataResponseDTO
     */
    'key_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataResponseDTO
     */
    'value_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataResponseDTO
     */
    'value_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataResponseDTO
     */
    'value_code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataResponseDTO
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'totalItem'?: number;
}
/**
 * 
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface Polygon
 */
export interface Polygon {
    /**
     * 
     * @type {number}
     * @memberof Polygon
     */
    'layer'?: number;
    /**
     * 0 is add, -1 is subtract
     * @type {number}
     * @memberof Polygon
     */
    'type'?: number;
    /**
     * 
     * @type {Array<Point>}
     * @memberof Polygon
     */
    'points'?: Array<Point>;
}
/**
 * 
 * @export
 * @interface RawImageAnnotationItem
 */
export interface RawImageAnnotationItem {
    /**
     * 
     * @type {BBox}
     * @memberof RawImageAnnotationItem
     */
    'bbox'?: BBox;
    /**
     * 
     * @type {Array<Polygon>}
     * @memberof RawImageAnnotationItem
     */
    'polygons'?: Array<Polygon>;
}
/**
 * 
 * @export
 * @interface RawImageAnnotationItemAllOf
 */
export interface RawImageAnnotationItemAllOf {
    /**
     * 
     * @type {BBox}
     * @memberof RawImageAnnotationItemAllOf
     */
    'bbox'?: BBox;
    /**
     * 
     * @type {Array<Polygon>}
     * @memberof RawImageAnnotationItemAllOf
     */
    'polygons'?: Array<Polygon>;
}
/**
 * 
 * @export
 * @interface RestResponse
 */
export interface RestResponse {
    /**
     * 
     * @type {string}
     * @memberof RestResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponse
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponse
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfAnnotationResponseDTO
 */
export interface RestResponseOfListOfAnnotationResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfAnnotationResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfAnnotationResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfAnnotationResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {RestResponseOfListOfAnnotationResponseDTOAllOfPayload}
     * @memberof RestResponseOfListOfAnnotationResponseDTO
     */
    'payload'?: RestResponseOfListOfAnnotationResponseDTOAllOfPayload;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfAnnotationResponseDTOAllOf
 */
export interface RestResponseOfListOfAnnotationResponseDTOAllOf {
    /**
     * 
     * @type {RestResponseOfListOfAnnotationResponseDTOAllOfPayload}
     * @memberof RestResponseOfListOfAnnotationResponseDTOAllOf
     */
    'payload'?: RestResponseOfListOfAnnotationResponseDTOAllOfPayload;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfAnnotationResponseDTOAllOfPayload
 */
export interface RestResponseOfListOfAnnotationResponseDTOAllOfPayload {
    /**
     * 
     * @type {Array<AnnotationResponseDTO>}
     * @memberof RestResponseOfListOfAnnotationResponseDTOAllOfPayload
     */
    'annotations'?: Array<AnnotationResponseDTO>;
    /**
     * 
     * @type {Array<AnnotationRelationResponseDTO>}
     * @memberof RestResponseOfListOfAnnotationResponseDTOAllOfPayload
     */
    'annotationRelations'?: Array<AnnotationRelationResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfFileInfoAggregatedResponseDTO
 */
export interface RestResponseOfListOfFileInfoAggregatedResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfFileInfoAggregatedResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfFileInfoAggregatedResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfFileInfoAggregatedResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<FileInfoAggregatedResponseDTO>}
     * @memberof RestResponseOfListOfFileInfoAggregatedResponseDTO
     */
    'payload'?: Array<FileInfoAggregatedResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfFileInfoAggregatedResponseDTOAllOf
 */
export interface RestResponseOfListOfFileInfoAggregatedResponseDTOAllOf {
    /**
     * 
     * @type {Array<FileInfoAggregatedResponseDTO>}
     * @memberof RestResponseOfListOfFileInfoAggregatedResponseDTOAllOf
     */
    'payload'?: Array<FileInfoAggregatedResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfFileInfoResponseDTO
 */
export interface RestResponseOfListOfFileInfoResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfFileInfoResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfFileInfoResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfFileInfoResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<FileInfoResponseDTO>}
     * @memberof RestResponseOfListOfFileInfoResponseDTO
     */
    'payload'?: Array<FileInfoResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfFileInfoResponseDTOAllOf
 */
export interface RestResponseOfListOfFileInfoResponseDTOAllOf {
    /**
     * 
     * @type {Array<FileInfoResponseDTO>}
     * @memberof RestResponseOfListOfFileInfoResponseDTOAllOf
     */
    'payload'?: Array<FileInfoResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfJobStatisticBatchResponseDTO
 */
export interface RestResponseOfListOfJobStatisticBatchResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfJobStatisticBatchResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfJobStatisticBatchResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfJobStatisticBatchResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {JobStatisticBatchResponseDTO}
     * @memberof RestResponseOfListOfJobStatisticBatchResponseDTO
     */
    'payload'?: JobStatisticBatchResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfJobStatisticBatchResponseDTOAllOf
 */
export interface RestResponseOfListOfJobStatisticBatchResponseDTOAllOf {
    /**
     * 
     * @type {JobStatisticBatchResponseDTO}
     * @memberof RestResponseOfListOfJobStatisticBatchResponseDTOAllOf
     */
    'payload'?: JobStatisticBatchResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfJobStatisticLabelerBatchResponseDTO
 */
export interface RestResponseOfListOfJobStatisticLabelerBatchResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfJobStatisticLabelerBatchResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfJobStatisticLabelerBatchResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfJobStatisticLabelerBatchResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {JobStatisticLabelerBatchResponseDTO}
     * @memberof RestResponseOfListOfJobStatisticLabelerBatchResponseDTO
     */
    'payload'?: JobStatisticLabelerBatchResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfJobStatisticLabelerBatchResponseDTOAllOf
 */
export interface RestResponseOfListOfJobStatisticLabelerBatchResponseDTOAllOf {
    /**
     * 
     * @type {JobStatisticLabelerBatchResponseDTO}
     * @memberof RestResponseOfListOfJobStatisticLabelerBatchResponseDTOAllOf
     */
    'payload'?: JobStatisticLabelerBatchResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfJobStatisticLabelerResponseDTO
 */
export interface RestResponseOfListOfJobStatisticLabelerResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfJobStatisticLabelerResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfJobStatisticLabelerResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfJobStatisticLabelerResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {JobStatisticLabelerResponseDTO}
     * @memberof RestResponseOfListOfJobStatisticLabelerResponseDTO
     */
    'payload'?: JobStatisticLabelerResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfJobStatisticLabelerResponseDTOAllOf
 */
export interface RestResponseOfListOfJobStatisticLabelerResponseDTOAllOf {
    /**
     * 
     * @type {JobStatisticLabelerResponseDTO}
     * @memberof RestResponseOfListOfJobStatisticLabelerResponseDTOAllOf
     */
    'payload'?: JobStatisticLabelerResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfTaskResponseDTO
 */
export interface RestResponseOfListOfTaskResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfTaskResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfTaskResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfTaskResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<TaskResponseDTO>}
     * @memberof RestResponseOfListOfTaskResponseDTO
     */
    'payload'?: Array<TaskResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfTaskResponseDTOAllOf
 */
export interface RestResponseOfListOfTaskResponseDTOAllOf {
    /**
     * 
     * @type {Array<TaskResponseDTO>}
     * @memberof RestResponseOfListOfTaskResponseDTOAllOf
     */
    'payload'?: Array<TaskResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfUserInfoResponseDTO
 */
export interface RestResponseOfListOfUserInfoResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfUserInfoResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfUserInfoResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfUserInfoResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<UserInfoResponseDTO>}
     * @memberof RestResponseOfListOfUserInfoResponseDTO
     */
    'payload'?: Array<UserInfoResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfUserInfoResponseDTOAllOf
 */
export interface RestResponseOfListOfUserInfoResponseDTOAllOf {
    /**
     * 
     * @type {Array<UserInfoResponseDTO>}
     * @memberof RestResponseOfListOfUserInfoResponseDTOAllOf
     */
    'payload'?: Array<UserInfoResponseDTO>;
}
/**
 * 
 * @export
 * @interface Roi
 */
export interface Roi {
    /**
     * 
     * @type {number}
     * @memberof Roi
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roi
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roi
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof Roi
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface TaskCriteria
 */
export interface TaskCriteria {
    /**
     * 
     * @type {Array<number>}
     * @memberof TaskCriteria
     */
    'ids'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof TaskCriteria
     */
    'batchId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskCriteria
     */
    'fileName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskCriteria
     */
    'statusList'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskCriteria
     */
    'issueStatusList'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TaskResponseDTO
 */
export interface TaskResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof TaskResponseDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'taskReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskResponseDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'reviewStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'issueStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskResponseDTO
     */
    'assignee'?: Array<string>;
    /**
     * 
     * @type {TaskResponseDTOIssueStat}
     * @memberof TaskResponseDTO
     */
    'issueStat'?: TaskResponseDTOIssueStat;
    /**
     * 
     * @type {boolean}
     * @memberof TaskResponseDTO
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface TaskResponseDTOIssueStat
 */
export interface TaskResponseDTOIssueStat {
    /**
     * 
     * @type {number}
     * @memberof TaskResponseDTOIssueStat
     */
    'opened'?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskResponseDTOIssueStat
     */
    'fixed'?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskResponseDTOIssueStat
     */
    'resolved'?: number;
}
/**
 * 
 * @export
 * @interface UserInfoResponseDTO
 */
export interface UserInfoResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponseDTO
     */
    'email'?: string;
}

/**
 * ActuatorApi - axios parameter creator
 * @export
 */
export const ActuatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get health status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actuatorHealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActuatorApi - functional programming interface
 * @export
 */
export const ActuatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActuatorApiAxiosParamCreator(configuration)
    return {
        /**
         * Get health status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actuatorHealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actuatorHealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActuatorApi - factory interface
 * @export
 */
export const ActuatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActuatorApiFp(configuration)
    return {
        /**
         * Get health status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actuatorHealthGet(options?: any): AxiosPromise<HealthGetResponse> {
            return localVarFp.actuatorHealthGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActuatorApi - object-oriented interface
 * @export
 * @class ActuatorApi
 * @extends {BaseAPI}
 */
export class ActuatorApi extends BaseAPI {
    /**
     * Get health status of backend service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public actuatorHealthGet(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).actuatorHealthGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobStatisticsResourceApi - axios parameter creator
 * @export
 */
export const JobStatisticsResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get list of payment for labeler group by batch
         * @summary getAllPaymentLabelerBatch
         * @param {JobStatisticLabelerBatchCriteria} [criteria] criteria for labeler payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPaymentLabelerBatchUsingGET: async (criteria?: JobStatisticLabelerBatchCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/job-statistics/labeler-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of payment for labeler
         * @summary getAllPaymentLabeler
         * @param {JobStatisticLabelerCriteria} [criteria] criteria for labeler payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPaymentLabelerUsingGET: async (criteria?: JobStatisticLabelerCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/job-statistics/labeler`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of payment
         * @summary getAllPayment
         * @param {number} batchId 
         * @param {JobStatisticBatchCriteria} [criteria] criteria for batch payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPaymentUsingGET: async (batchId: number, criteria?: JobStatisticBatchCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('getAllPaymentUsingGET', 'batchId', batchId)
            const localVarPath = `/api/v1/job-statistics/batch/{batchId}`
                .replace(`{${"batchId"}}`, encodeURIComponent(String(batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobStatisticsResourceApi - functional programming interface
 * @export
 */
export const JobStatisticsResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobStatisticsResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * Get list of payment for labeler group by batch
         * @summary getAllPaymentLabelerBatch
         * @param {JobStatisticLabelerBatchCriteria} [criteria] criteria for labeler payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPaymentLabelerBatchUsingGET(criteria?: JobStatisticLabelerBatchCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfJobStatisticLabelerBatchResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPaymentLabelerBatchUsingGET(criteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of payment for labeler
         * @summary getAllPaymentLabeler
         * @param {JobStatisticLabelerCriteria} [criteria] criteria for labeler payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPaymentLabelerUsingGET(criteria?: JobStatisticLabelerCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfJobStatisticLabelerResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPaymentLabelerUsingGET(criteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of payment
         * @summary getAllPayment
         * @param {number} batchId 
         * @param {JobStatisticBatchCriteria} [criteria] criteria for batch payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPaymentUsingGET(batchId: number, criteria?: JobStatisticBatchCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfJobStatisticBatchResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPaymentUsingGET(batchId, criteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobStatisticsResourceApi - factory interface
 * @export
 */
export const JobStatisticsResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobStatisticsResourceApiFp(configuration)
    return {
        /**
         * Get list of payment for labeler group by batch
         * @summary getAllPaymentLabelerBatch
         * @param {JobStatisticLabelerBatchCriteria} [criteria] criteria for labeler payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPaymentLabelerBatchUsingGET(criteria?: JobStatisticLabelerBatchCriteria, options?: any): AxiosPromise<RestResponseOfListOfJobStatisticLabelerBatchResponseDTO> {
            return localVarFp.getAllPaymentLabelerBatchUsingGET(criteria, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of payment for labeler
         * @summary getAllPaymentLabeler
         * @param {JobStatisticLabelerCriteria} [criteria] criteria for labeler payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPaymentLabelerUsingGET(criteria?: JobStatisticLabelerCriteria, options?: any): AxiosPromise<RestResponseOfListOfJobStatisticLabelerResponseDTO> {
            return localVarFp.getAllPaymentLabelerUsingGET(criteria, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of payment
         * @summary getAllPayment
         * @param {number} batchId 
         * @param {JobStatisticBatchCriteria} [criteria] criteria for batch payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPaymentUsingGET(batchId: number, criteria?: JobStatisticBatchCriteria, options?: any): AxiosPromise<RestResponseOfListOfJobStatisticBatchResponseDTO> {
            return localVarFp.getAllPaymentUsingGET(batchId, criteria, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAllPaymentLabelerBatchUsingGET operation in JobStatisticsResourceApi.
 * @export
 * @interface JobStatisticsResourceApiGetAllPaymentLabelerBatchUsingGETRequest
 */
export interface JobStatisticsResourceApiGetAllPaymentLabelerBatchUsingGETRequest {
    /**
     * criteria for labeler payment
     * @type {JobStatisticLabelerBatchCriteria}
     * @memberof JobStatisticsResourceApiGetAllPaymentLabelerBatchUsingGET
     */
    readonly criteria?: JobStatisticLabelerBatchCriteria
}

/**
 * Request parameters for getAllPaymentLabelerUsingGET operation in JobStatisticsResourceApi.
 * @export
 * @interface JobStatisticsResourceApiGetAllPaymentLabelerUsingGETRequest
 */
export interface JobStatisticsResourceApiGetAllPaymentLabelerUsingGETRequest {
    /**
     * criteria for labeler payment
     * @type {JobStatisticLabelerCriteria}
     * @memberof JobStatisticsResourceApiGetAllPaymentLabelerUsingGET
     */
    readonly criteria?: JobStatisticLabelerCriteria
}

/**
 * Request parameters for getAllPaymentUsingGET operation in JobStatisticsResourceApi.
 * @export
 * @interface JobStatisticsResourceApiGetAllPaymentUsingGETRequest
 */
export interface JobStatisticsResourceApiGetAllPaymentUsingGETRequest {
    /**
     * 
     * @type {number}
     * @memberof JobStatisticsResourceApiGetAllPaymentUsingGET
     */
    readonly batchId: number

    /**
     * criteria for batch payment
     * @type {JobStatisticBatchCriteria}
     * @memberof JobStatisticsResourceApiGetAllPaymentUsingGET
     */
    readonly criteria?: JobStatisticBatchCriteria
}

/**
 * JobStatisticsResourceApi - object-oriented interface
 * @export
 * @class JobStatisticsResourceApi
 * @extends {BaseAPI}
 */
export class JobStatisticsResourceApi extends BaseAPI {
    /**
     * Get list of payment for labeler group by batch
     * @summary getAllPaymentLabelerBatch
     * @param {JobStatisticsResourceApiGetAllPaymentLabelerBatchUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobStatisticsResourceApi
     */
    public getAllPaymentLabelerBatchUsingGET(requestParameters: JobStatisticsResourceApiGetAllPaymentLabelerBatchUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return JobStatisticsResourceApiFp(this.configuration).getAllPaymentLabelerBatchUsingGET(requestParameters.criteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of payment for labeler
     * @summary getAllPaymentLabeler
     * @param {JobStatisticsResourceApiGetAllPaymentLabelerUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobStatisticsResourceApi
     */
    public getAllPaymentLabelerUsingGET(requestParameters: JobStatisticsResourceApiGetAllPaymentLabelerUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return JobStatisticsResourceApiFp(this.configuration).getAllPaymentLabelerUsingGET(requestParameters.criteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of payment
     * @summary getAllPayment
     * @param {JobStatisticsResourceApiGetAllPaymentUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobStatisticsResourceApi
     */
    public getAllPaymentUsingGET(requestParameters: JobStatisticsResourceApiGetAllPaymentUsingGETRequest, options?: AxiosRequestConfig) {
        return JobStatisticsResourceApiFp(this.configuration).getAllPaymentUsingGET(requestParameters.batchId, requestParameters.criteria, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get list of annotation by criteria
         * @param {AnnotationCriteria} [criteria] 
         * @param {Pageable} [pageable] pageable
         * @param {AnnotationSearchRequestDTO} [annotationSearchRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAnnotation: async (criteria?: AnnotationCriteria, pageable?: Pageable, annotationSearchRequestDTO?: AnnotationSearchRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/search/annotation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationSearchRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of file info by criteria
         * @param {FileInfoAggregatedCriteria} [criteria] 
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFileInfoAggregated: async (criteria?: FileInfoAggregatedCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/search/file_info_aggregated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of file info by criteria
         * @param {Pageable} [pageable] pageable
         * @param {FileInfoCriteria} [fileInfoCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFileInfoUsingPost: async (pageable?: Pageable, fileInfoCriteria?: FileInfoCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/search/file_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileInfoCriteria, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of task by criteria
         * @param {TaskCriteria} [criteria] 
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTask: async (criteria?: TaskCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/search/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * Get list of annotation by criteria
         * @param {AnnotationCriteria} [criteria] 
         * @param {Pageable} [pageable] pageable
         * @param {AnnotationSearchRequestDTO} [annotationSearchRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAnnotation(criteria?: AnnotationCriteria, pageable?: Pageable, annotationSearchRequestDTO?: AnnotationSearchRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfAnnotationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAnnotation(criteria, pageable, annotationSearchRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of file info by criteria
         * @param {FileInfoAggregatedCriteria} [criteria] 
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFileInfoAggregated(criteria?: FileInfoAggregatedCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfFileInfoAggregatedResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFileInfoAggregated(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of file info by criteria
         * @param {Pageable} [pageable] pageable
         * @param {FileInfoCriteria} [fileInfoCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFileInfoUsingPost(pageable?: Pageable, fileInfoCriteria?: FileInfoCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfFileInfoResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFileInfoUsingPost(pageable, fileInfoCriteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of task by criteria
         * @param {TaskCriteria} [criteria] 
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTask(criteria?: TaskCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfTaskResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTask(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * Get list of annotation by criteria
         * @param {AnnotationCriteria} [criteria] 
         * @param {Pageable} [pageable] pageable
         * @param {AnnotationSearchRequestDTO} [annotationSearchRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAnnotation(criteria?: AnnotationCriteria, pageable?: Pageable, annotationSearchRequestDTO?: AnnotationSearchRequestDTO, options?: any): AxiosPromise<RestResponseOfListOfAnnotationResponseDTO> {
            return localVarFp.searchAnnotation(criteria, pageable, annotationSearchRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of file info by criteria
         * @param {FileInfoAggregatedCriteria} [criteria] 
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFileInfoAggregated(criteria?: FileInfoAggregatedCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfFileInfoAggregatedResponseDTO> {
            return localVarFp.searchFileInfoAggregated(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of file info by criteria
         * @param {Pageable} [pageable] pageable
         * @param {FileInfoCriteria} [fileInfoCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFileInfoUsingPost(pageable?: Pageable, fileInfoCriteria?: FileInfoCriteria, options?: any): AxiosPromise<RestResponseOfListOfFileInfoResponseDTO> {
            return localVarFp.searchFileInfoUsingPost(pageable, fileInfoCriteria, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of task by criteria
         * @param {TaskCriteria} [criteria] 
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTask(criteria?: TaskCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfTaskResponseDTO> {
            return localVarFp.searchTask(criteria, pageable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for searchAnnotation operation in SearchApi.
 * @export
 * @interface SearchApiSearchAnnotationRequest
 */
export interface SearchApiSearchAnnotationRequest {
    /**
     * 
     * @type {AnnotationCriteria}
     * @memberof SearchApiSearchAnnotation
     */
    readonly criteria?: AnnotationCriteria

    /**
     * pageable
     * @type {Pageable}
     * @memberof SearchApiSearchAnnotation
     */
    readonly pageable?: Pageable

    /**
     * 
     * @type {AnnotationSearchRequestDTO}
     * @memberof SearchApiSearchAnnotation
     */
    readonly annotationSearchRequestDTO?: AnnotationSearchRequestDTO
}

/**
 * Request parameters for searchFileInfoAggregated operation in SearchApi.
 * @export
 * @interface SearchApiSearchFileInfoAggregatedRequest
 */
export interface SearchApiSearchFileInfoAggregatedRequest {
    /**
     * 
     * @type {FileInfoAggregatedCriteria}
     * @memberof SearchApiSearchFileInfoAggregated
     */
    readonly criteria?: FileInfoAggregatedCriteria

    /**
     * pageable
     * @type {Pageable}
     * @memberof SearchApiSearchFileInfoAggregated
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for searchFileInfoUsingPost operation in SearchApi.
 * @export
 * @interface SearchApiSearchFileInfoUsingPostRequest
 */
export interface SearchApiSearchFileInfoUsingPostRequest {
    /**
     * pageable
     * @type {Pageable}
     * @memberof SearchApiSearchFileInfoUsingPost
     */
    readonly pageable?: Pageable

    /**
     * 
     * @type {FileInfoCriteria}
     * @memberof SearchApiSearchFileInfoUsingPost
     */
    readonly fileInfoCriteria?: FileInfoCriteria
}

/**
 * Request parameters for searchTask operation in SearchApi.
 * @export
 * @interface SearchApiSearchTaskRequest
 */
export interface SearchApiSearchTaskRequest {
    /**
     * 
     * @type {TaskCriteria}
     * @memberof SearchApiSearchTask
     */
    readonly criteria?: TaskCriteria

    /**
     * pageable
     * @type {Pageable}
     * @memberof SearchApiSearchTask
     */
    readonly pageable?: Pageable
}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * Get list of annotation by criteria
     * @param {SearchApiSearchAnnotationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchAnnotation(requestParameters: SearchApiSearchAnnotationRequest = {}, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).searchAnnotation(requestParameters.criteria, requestParameters.pageable, requestParameters.annotationSearchRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of file info by criteria
     * @param {SearchApiSearchFileInfoAggregatedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchFileInfoAggregated(requestParameters: SearchApiSearchFileInfoAggregatedRequest = {}, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).searchFileInfoAggregated(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of file info by criteria
     * @param {SearchApiSearchFileInfoUsingPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchFileInfoUsingPost(requestParameters: SearchApiSearchFileInfoUsingPostRequest = {}, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).searchFileInfoUsingPost(requestParameters.pageable, requestParameters.fileInfoCriteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of task by criteria
     * @param {SearchApiSearchTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchTask(requestParameters: SearchApiSearchTaskRequest = {}, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).searchTask(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserInfoResourceApi - axios parameter creator
 * @export
 */
export const UserInfoResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get list of user info
         * @summary getAllUserInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserInfoUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user info of current user
         * @summary getUserInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-info/whoami`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInfoResourceApi - functional programming interface
 * @export
 */
export const UserInfoResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserInfoResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * Get list of user info
         * @summary getAllUserInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUserInfoUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfUserInfoResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUserInfoUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user info of current user
         * @summary getUserInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfoUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfoUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserInfoResourceApi - factory interface
 * @export
 */
export const UserInfoResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserInfoResourceApiFp(configuration)
    return {
        /**
         * Get list of user info
         * @summary getAllUserInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserInfoUsingGET(options?: any): AxiosPromise<RestResponseOfListOfUserInfoResponseDTO> {
            return localVarFp.getAllUserInfoUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * Get user info of current user
         * @summary getUserInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoUsingGET(options?: any): AxiosPromise<UserInfoResponseDTO> {
            return localVarFp.getUserInfoUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserInfoResourceApi - object-oriented interface
 * @export
 * @class UserInfoResourceApi
 * @extends {BaseAPI}
 */
export class UserInfoResourceApi extends BaseAPI {
    /**
     * Get list of user info
     * @summary getAllUserInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoResourceApi
     */
    public getAllUserInfoUsingGET(options?: AxiosRequestConfig) {
        return UserInfoResourceApiFp(this.configuration).getAllUserInfoUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user info of current user
     * @summary getUserInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoResourceApi
     */
    public getUserInfoUsingGET(options?: AxiosRequestConfig) {
        return UserInfoResourceApiFp(this.configuration).getUserInfoUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


