/*
 * File: use-select-observation.hook.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 16th April 2022 3:24:33 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";
import { AnnotateType } from "constants/annotation.constant";
import { Label } from "domain/image-labeling";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { selectImageLabelingActiveJobId } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { jobObservationEdited } from "store/labeler/image-workspace/batch-labeling/batch-labeling.slice";
import { setActiveCornerstoneTool } from "store/labeler/image-workspace/dicom-editor/dicom-editor.slice";
import {
  selectBatchObservations,
  selectImageLabelingLabels,
  selectIsImageLabelingReadonly,
  selectSelectedObservationId,
} from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { setSelectedObservationId } from "store/labeler/image-workspace/image-workspace.slice";
import { selectSmartLabelingEnabled } from "store/labeler/image-workspace/smart-labeling/smart-labeling.selectors";

export function useSelectObservation() {
  const dispatch = useAppDispatch();
  const labels = useAppSelector(selectImageLabelingLabels);
  const readonly = useAppSelector(selectIsImageLabelingReadonly);
  const observations = useAppSelector(selectBatchObservations);
  const smartLabelingEnable = useAppSelector(selectSmartLabelingEnabled);
  const selectedObservationId = useAppSelector(selectSelectedObservationId);
  const jobId = useAppSelector(selectImageLabelingActiveJobId);

  function selectCornerstoneTool(type: ToolName) {
    dispatch(setActiveCornerstoneTool(type));
  }

  function selectLabelById(id: number) {
    const label = labels.find((lb) => lb.id === id);
    if (label) selectLabel(label);
  }

  function selectLabelByIndex(index: number) {
    if (index < 0 || index >= labels.length) {
      deselectLabel();
      return;
    }
    const observationId = labels[index].id;
    const observation = observations.find((ob) => ob.id === observationId);
    if (!observation) return;
    selectLabel(observation);
  }

  function selectNextLabel() {
    let idx = labels.findIndex((label) => selectedObservationId === label.id);
    idx = (idx + 1) % labels.length;
    return selectLabelByIndex(idx);
  }

  function selectPreviousLabel() {
    let idx = labels.findIndex((label) => selectedObservationId === label.id);
    idx = (idx - 1) % labels.length;
    if (idx < 0) idx += labels.length;
    return selectLabelByIndex(idx);
  }

  function selectLabel(label: Label) {
    autoSelectAnnotationTool(label);
    dispatch(setSelectedObservationId(label.id));
    if (label.annotateType === AnnotateType.CLASSIFICATION) {
      dispatch(jobObservationEdited(jobId));
    }
  }

  function deselectLabel() {
    selectCornerstoneTool(ToolName.Wwwc);
    dispatch(setSelectedObservationId(-1));
  }

  function autoSelectAnnotationTool(label: Label) {
    if (readonly) return;
    const annotateType = label.annotateType;
    if (smartLabelingEnable) {
      selectCornerstoneTool(ToolName.RectangleRoiExtend);
    } else if (annotateType === AnnotateType.POLYGON) {
      selectCornerstoneTool(ToolName.FreehandRoiExtend);
    } else if (annotateType === AnnotateType.BOUNDING_BOX) {
      selectCornerstoneTool(ToolName.RectangleRoiExtend);
    }
  }

  return {
    selectLabelById,
    selectLabelByIndex,
    selectNextLabel,
    selectPreviousLabel,
  };
}
