/*
 * File: dataset-filter.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 23rd November 2021 3:18:43 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useClickAway } from "ahooks";
import {
  IconFilter,
  IconFilterActive,
} from "components/common/vb-icon.component";
import { DateInputComponent } from "components/design-system/date-input/date-input.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { useAppSelector } from "hooks/use-redux";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { selectSupportedDatasourceTypes } from "store/common/app-setting/app-setting.selectors";
import { classnames } from "utilities/classes";
import { DatasourceFilter } from "../../hooks/use-datasource-filter.hook";

interface Props {
  active?: boolean;
  filter: DatasourceFilter;
  onChange?(filter: DatasourceFilter): void;
}

export const DatasourceFilterComponent = ({
  active,
  filter,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(filter);
  const popoverRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLButtonElement>(null);
  const datasourceTypes = useAppSelector(selectSupportedDatasourceTypes(t));

  const handleDateChange = (date: Date) => {
    setCurrentFilter({
      ...currentFilter,
      createdDate: date ? date.toDateString() : "",
    });
  };

  const handleClickOpen = () => setOpen(!open);

  const setName = (name: string) => {
    setCurrentFilter({ ...currentFilter, name: name });
  };
  const setCategory = (category: string) => {
    setCurrentFilter({ ...currentFilter, category: category });
  };
  const setOwner = (owner: string) => {
    setCurrentFilter({ ...currentFilter, createdBy: owner });
  };
  const handleApply = () => {
    onChange && onChange(currentFilter);
    setOpen(false);
  };

  const handleClear = () => {
    setCurrentFilter({});
    onChange && onChange({});
    setOpen(false);
  };

  useEffect(() => {
    setCurrentFilter({ ...filter });
  }, [filter]);

  useClickAway((event) => {
    const element = event.target as HTMLElement;
    if (!element || element.id !== "datasource-filter-button") {
      setOpen(false);
    }
  }, popoverRef);

  return (
    <div className="relative z-50">
      <button
        id="datasource-filter-button"
        onClick={handleClickOpen}
        ref={containerRef}
        className={classnames(
          "flex items-center justify-center px-3 h-9 gap-1.5 rounded border",
          {
            "bg-primary text-white": !!active,
            "bg-white text-primary border-primary": !active,
          }
        )}
      >
        <span className="pointer-events-none">Filter</span>
        {active ? (
          <IconFilterActive className="flex-none w-5 h-5 pointer-events-none" />
        ) : (
          <IconFilter className="flex-none w-5 h-5 pointer-events-none" />
        )}
      </button>

      {open && (
        <div
          className="absolute right-0 mt-2 bg-white rounded shadow-lg top-full animate-fade-in-up"
          ref={popoverRef}
          style={{ width: "17rem" }}
        >
          <div className="flex flex-col gap-4 p-4">
            <VBTextInputComponent
              header="Name"
              placeholder="Search"
              className="text-sm"
              clearInput
              defaultValue={currentFilter.name}
              onInputChange={setName}
            />

            <DateInputComponent
              containerClassName="relative"
              placeholderText="01/Jan/2021"
              header="Create date"
              maxDate={new Date()}
              defaultValue={
                currentFilter.createdDate
                  ? new Date(currentFilter.createdDate)
                  : null
              }
              clearable
              onChange={handleDateChange}
            />

            <VBTextInputComponent
              header="Owner"
              placeholder="Search"
              className="text-sm"
              clearInput
              defaultValue={currentFilter.createdBy}
              onInputChange={setOwner}
            />

            <VBSelectComponent
              header="Category"
              defaultValue={datasourceTypes.find(
                (option) => option.value === currentFilter.category
              )}
              options={datasourceTypes}
              onChange={(val: SingleValue<any>) => setCategory(val.value)}
            />

            <div className="flex flex-col gap-4 py-2">
              <button className="button-primary" onClick={handleApply}>
                Confirm
              </button>
              <button className="button-tertiary" onClick={handleClear}>
                Clear filter
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
