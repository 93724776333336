/*
 * File: project-general-setting.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 26th April 2022 10:22:22 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { VBTextAreaComponent } from "components/design-system/text-area/text-area.component";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { FormEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectCurrentProject } from "store/customer/project/project.selectors";
import { selectProjectTypeNames } from "store/common/app-setting/app-setting.selectors";
import { SingleValue } from "react-select";
import { HistoryPermission, PollStrategy } from "services/label-service/dtos";
import { updateProjectAsync } from "store/customer/projects/projects.slice";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { Logger } from "utilities/logger";
import { ButtonSave } from "../components/button-save.component";
import { VBNumberTextInputComponent } from "components/design-system/number-text-input/number-text-input.component";
import { DEFAULT_AUTOSAVE_IN_SECOND } from "constants/setting.constant";
import * as Sentry from "@sentry/react";
import DOMPurify from "dompurify";

export function ProjectGeneralSettings() {
  const dispatch = useAppDispatch();
  const project = useAppSelector(selectCurrentProject);
  const names = useAppSelector(selectProjectTypeNames);
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState(project?.name || "");
  const [description, setDescription] = useState(project?.description || "");

  const typeName = useMemo(() => {
    if (!project) return "";
    if (names.hasOwnProperty(project.subType)) {
      return names[project.subType];
    }
    if (names.hasOwnProperty(project.type)) {
      return names[project.type];
    }
    return "";
  }, [names, project]);

  const typeOptions = useMemo(() => {
    return [{ label: typeName, value: typeName }];
  }, [typeName]);

  const [pollStrategy, setPollStrategy] = useState(
    project?.settings?.pollStrategy || PollStrategy.SINGLE
  );

  const [historyPermission, setHistoryPermission] = useState(
    project?.settings?.historyPermission || HistoryPermission.READ_ONLY
  );

  const [autoSaveInSecond, setAutoSaveInSecond] = useState(
    project?.settings.autoSaveInSecond || DEFAULT_AUTOSAVE_IN_SECOND
  );

  const pollOptions = [
    { value: PollStrategy.SINGLE, label: PollStrategy.SINGLE },
    { value: PollStrategy.GROUP, label: PollStrategy.GROUP },
  ];

  const historyPermissionOptions = [
    { value: HistoryPermission.READ_ONLY, label: HistoryPermission.READ_ONLY },
    { value: HistoryPermission.EDIT, label: HistoryPermission.EDIT },
  ];

  const hasPendingChanges = useMemo(() => {
    if (!project) return false;
    return (
      project.name !== name.trim() ||
      project.description !== description.trim() ||
      pollStrategy !== project.settings.pollStrategy ||
      historyPermission !== project.settings.historyPermission ||
      autoSaveInSecond !== project.settings.autoSaveInSecond
    );
  }, [
    project,
    name,
    description,
    pollStrategy,
    historyPermission,
    autoSaveInSecond,
  ]);

  function handlePollStrategyChange(val: SingleValue<any>) {
    setPollStrategy(val?.value as PollStrategy);
  }

  function handleHistoryPermissionChange(val: SingleValue<any>) {
    setHistoryPermission(val?.value as HistoryPermission);
  }

  function handleChange(event: FormEvent<HTMLInputElement>) {
    setName(DOMPurify.sanitize(event.currentTarget.value));
  }

  function handleDescriptionChange(event: FormEvent<HTMLTextAreaElement>) {
    setDescription(DOMPurify.sanitize(event.currentTarget.value));
  }

  async function handleSubmit() {
    if (!project || submitting) return;
    try {
      setSubmitting(true);
      const updatedProject = {
        id: project.id,
        name: DOMPurify.sanitize(name),
        description: DOMPurify.sanitize(description),
        settings: {
          pollStrategy: pollStrategy,
          autoSaveInSecond,
          historyPermission,
        },
      };
      const response = await dispatch(updateProjectAsync(updatedProject));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textUpdatedSuccess")));
    } catch (err: any) {
      Sentry.captureException(err);
      const errMessage = err.message || t("common:textUpdatedFailed");
      dispatch(enqueueErrorNotification(errMessage));
      Logger.log(err);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="pb-6">
      <div className="max-w-md space-y-6">
        <VBTextInputComponent
          placeholder="Enter project's name"
          header={t("project:createDialog.labelName")}
          className="text-sm"
          autoFocus
          clearInput
          onChange={(evt) => handleChange(evt)}
          defaultValue={project?.name}
          onClear={() => setName("")}
          inputIcon={<></>}
        />

        <VBSelectComponent
          isDisabled
          header={t("project:createDialog.labelType")}
          value={typeOptions[0]}
          options={typeOptions}
          menuPortalTarget={document.body}
        />

        <VBTextAreaComponent
          placeholder="Enter project's description"
          header={t("project:createDialog.labelDescription")}
          className="text-sm"
          defaultValue={project?.description}
          onChange={handleDescriptionChange}
        />

        <VBSelectComponent
          header={t("project:createDialog.labelPollStrategy")}
          value={pollOptions.find((option) => option.value === pollStrategy)}
          options={pollOptions}
          menuPortalTarget={document.body}
          onChange={handlePollStrategyChange}
        />

        <VBSelectComponent
          header={t("project:createDialog.labelHistoryPermission")}
          value={historyPermissionOptions.find(
            (option) => option.value === historyPermission
          )}
          options={historyPermissionOptions}
          menuPortalTarget={document.body}
          onChange={handleHistoryPermissionChange}
        />

        <div>
          <p className="pb-2 overflow-hidden text-sm font-semibold whitespace-nowrap overflow-ellipsis">
            {t("project:createDialog.labelAutoSaveInSecond")}
          </p>
          <VBNumberTextInputComponent
            placeholder="Enter auto save in seconds"
            defaultValue={autoSaveInSecond}
            onValueChanged={(v) => {
              setAutoSaveInSecond(v as number);
            }}
            min={0}
          />
        </div>

        <ButtonSave
          disabled={!hasPendingChanges || submitting}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
