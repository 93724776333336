import { StepType, WorkflowInstructionDTO } from "services/label-service/dtos";

export const getNextStepTypes = (instructions: WorkflowInstructionDTO[] | Partial<WorkflowInstructionDTO>[]) => {
  if (!instructions || !instructions.length) {
    return Object.values(StepType);
  }
  
  const hasAcceptanceStep = instructions.find(instruction => instruction.stepType === StepType.ACCEPTANCE);
  if (hasAcceptanceStep) {
    return []
  }

  const hasReviewStep = instructions.find(instruction => instruction.stepType === StepType.REVIEW);
  if (hasReviewStep) {
    return [StepType.ACCEPTANCE]
  }

  return Object.values(StepType);
}