/*
 * File: use-ml-models-breadcrumbs.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 30th August 2021 5:57:57 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { Breadcrumb } from "components/common/vb-breadcrumb/breadcrumb.model";
import { useWorkspaces } from "hooks/workspace/use-workspace.hook";
import { MODELS_MENU_ITEMS } from "pages/customer/ml-models/ml-models-menu.component";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "hooks/use-redux";
import { selecteExportedDatasets } from "store/customer/exported-datasets/exported-datasets.selectors";

export const useMLModelBreadcrumbs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const exportedDatasets = useAppSelector(selecteExportedDatasets);
  const idMappers = useMemo<Record<string, string>>(() => {
    const ids: Record<string, string> = {};
    for (let menuItem of MODELS_MENU_ITEMS) {
      ids[menuItem.link.replace("/", "")] = t(menuItem.name);
    }
    for (const exportedDataset of exportedDatasets) {
      ids[exportedDataset.id] = exportedDataset.name;
    }
    return ids;
  }, [t, exportedDatasets]);
  const { workspace } = useWorkspaces();

  useEffect(() => {
    const currentPath = location.pathname;
    const items = currentPath
      .split("/")
      .filter((p) => p.trim().length > 0)
      .map((path) => {
        return {
          url: `${currentPath.substring(
            0,
            currentPath.indexOf(path) + path.length
          )}`,
          text: idMappers[path] || path,
          disabled: false,
        };
      });
    const breadcrumbs = [
      { url: "/", text: workspace?.groupName || "", disabled: false },
      ...items,
    ];
    if (breadcrumbs.length > 0)
      breadcrumbs[breadcrumbs.length - 1].disabled = true;
    setBreadcrumbs(breadcrumbs);
  }, [location.pathname, idMappers, workspace]);

  return { breadcrumbs, setBreadcrumbs };
};
