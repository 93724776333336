/*
 * File: notification-events.api.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 16th September 2021 8:50:39 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BaseAPI } from "services/base-service/api/base.api";
import { setupLabelInterceptor } from "services/common/label-interceptor";
import { NotificationDTO } from "../dto/notification.dto";

export class NotificationEventsAPI extends BaseAPI<NotificationDTO> {
  constructor(serviceUrl: string, path: string) {
    super(serviceUrl, path);
    this.axiosInstance = setupLabelInterceptor(this.axiosInstance);
  }

  countUnread(unread: boolean = true) {
    const searchParams = new URLSearchParams({ unread: `${unread}` });
    return this.get(`${this.getPath()}/count?${searchParams.toString()}`);
  }

  removeById(id: number | string) {
    return this.post(`${this.getPath()}/${id}/remove`);
  }
}
