/*
 * File: project.router.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 20th July 2021 10:34:05 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Switch, Route, useRouteMatch } from "react-router-dom";
import { CreateProjectTemplateProvider } from "./components/project-templates/context/create-project-template.provider";
import CreateProjectFromTemplatePage from "./components/project-templates/create-from-template";
import ProjectTemplatesPage from "./components/project-templates/project-templates.page";
import { ProjectDetailPage } from "./project-detail/project-detail.page";
import { ProjectProvider } from "./project.context";
import { ProjectsPage } from "./projects.page";
export const ProjectRouter = () => {
  const { path } = useRouteMatch();
  return (
    <ProjectProvider>
      <Switch>
        <Route
          exact
          path={`${path}/template/:templateId`}
          component={() => (
            <CreateProjectTemplateProvider>
              <CreateProjectFromTemplatePage />
            </CreateProjectTemplateProvider>
          )}
        />
        <Route
          path={`${path}/template`}
          exact
          component={ProjectTemplatesPage}
        />
        <Route exact path={path} component={ProjectsPage} />
        <Route path={`${path}/:projectId`} component={ProjectDetailPage} />
      </Switch>
    </ProjectProvider>
  );
};
