/*
 * File: labeler-navigation.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 16th November 2021 5:24:41 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { IconLogoRounded } from "components/common/vb-icon.component";
import { useDebouncedClickHandler } from "hooks/use-navigation-debounce";
export interface MenuItem {
  id: string;
  name: string;
  activeIcon: JSX.Element;
  icon: JSX.Element;
}

interface Props {
  menuItems: MenuItem[];
  activeMenuItemId: string;
  onSelect?(menuItemId: string): void;
}

export const LabelerNavigation = ({
  menuItems,
  activeMenuItemId,
  onSelect,
}: Props) => {
  return (
    <div className="flex flex-col items-center w-20 h-screen bg-warning-500">
      <button
        className="flex items-center justify-center w-12 h-12 mx-auto my-4 bg-white rounded"
        onClick={() =>
          onSelect && onSelect(menuItems.length > 0 ? menuItems[0].id : "")
        }
      >
        <IconLogoRounded className="w-8 h-8" />
      </button>
      <div className="w-full">
        <div className="w-12 mx-auto border border-warning-400"></div>
        {menuItems.map((menuItem) => {
          return (
            <MenuItemComponent
              key={menuItem.id}
              id={menuItem.id}
              name={menuItem.name}
              icon={menuItem.icon}
              activeIcon={menuItem.activeIcon}
              active={menuItem.id === activeMenuItemId}
              onSelect={onSelect}
            />
          );
        })}
      </div>
    </div>
  );
};

interface MenuItemComponentProps {
  id: string;
  active?: boolean;
  name: string;
  icon: JSX.Element;
  activeIcon: JSX.Element;
  onSelect?(menuItemId: string): void;
}
const MenuItemComponent = ({
  id,
  active,
  icon,
  name,
  activeIcon,
  onSelect,
}: MenuItemComponentProps) => {
  const handleClickItem = useDebouncedClickHandler(2, 2000);

  const handleClick = () => {
    handleClickItem(id, active, () => {
      if (onSelect) {
        onSelect(id);
      }
    });
  };
  return (
    <button
      onClick={handleClick}
      className={`h-20 w-full flex flex-col items-center justify-center gap-2 ${
        active ? "bg-background-50 text-warning-500" : "text-white"
      }`}
    >
      <span>{active ? activeIcon : icon}</span>
      <span className="text-xs font-medium">{name}</span>
    </button>
  );
};
