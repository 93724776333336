import { getEnabledElement } from "cornerstone-core";
import * as csTools from "cornerstone-tools";
import {
  addToolState,
  clearToolState,
  getToolState,
  toolStyle,
  toolColors,
} from "cornerstone-tools";
import moveAnnotation from "./HeartRulerTool_utils/moveAnnotation";

const EVENTS = csTools.EVENTS;
const draw = csTools.importInternal("drawing/draw");
const getNewContext = csTools.importInternal("drawing/getNewContext");
const drawLine = csTools.importInternal("drawing/drawLine");
const drawTextBox = csTools.importInternal("drawing/drawTextBox");

export default class HeartRulerTool extends csTools.LengthTool {
  constructor() {
    super({
      name: "HeartRuler",
      configuration: {
        drawHandles: false,
      },
    });

    this.mouseDragCallback = this.mouseDragCallback.bind(this);
    this.initData = this.initData.bind(this);

    this.deviation = 0;
    this.canvasWidth = 0;
    this.imageWidth = 0;
  }

  // cornerstoneTools will call this function for near any tool detection
  pointNearTool(element, data, coords) {
    const validParameters =
      data && data.handles && data.handles.start && data.handles.end;

    if (!validParameters) {
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    return lineSegDistance(data.handles.start, data.handles.end, coords) < 25;
  }

  /**
   * Callback when mounted as passive
   */
  passiveCallback(element) {
    element.addEventListener(EVENTS.MOUSE_DRAG, this.mouseDragCallback);

    const toolData = getToolState(element, this.name);

    if (!toolData) {
      this.initData();
    }
  }

  initData() {
    clearToolState(this.element, this.name);

    // init numbers
    const elementData = getEnabledElement(this.element);
    const imageData = elementData.canvas;

    // store current image data for further use
    this.canvasWidth = imageData.width;
    this.imageWidth = getImageWidth(elementData);

    const width = imageData.width;
    const height = imageData.height;
    const midHeight = Math.floor(height / 2);
    const unit = Math.floor(width / 7);

    const chestLines = [unit, 6 * unit];
    const heartLines = [3 * unit, 5 * unit];

    // add chest lines
    chestLines.forEach((xBegin) => {
      addToolState(
        this.element,
        this.name,
        generateToolState(xBegin, 0, xBegin, height, "orange", "ruler")
      );
    });

    // add heart lines
    heartLines.forEach((xBegin) => {
      addToolState(
        this.element,
        this.name,
        generateToolState(xBegin, 0, xBegin, height, "red", "ruler")
      );
    });
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { handleRadius, drawHandlesOnHover } = this.configuration;
    const toolData = getToolState(evt.currentTarget, this.name);

    if (
      !toolData ||
      !toolData.visible ||
      !toolData.data ||
      toolData.data.length === 0
    ) {
      return;
    }

    // if the canvas height is changed, reset the tool
    if (toolData.data[0].handles.end.y !== evt.target.lastChild.height) {
      const elementData = getEnabledElement(this.element);
      const imageData = elementData.canvas;

      const newToolState = scaleHeartRuler(
        this.canvasWidth,
        this.imageWidth,
        imageData.width,
        getImageWidth(elementData),
        toolData,
        this.deviation,
        evt.target.lastChild.height
      );

      clearToolState(this.element, this.name);

      newToolState.forEach((line) => {
        addToolState(this.element, this.name, line);
      });

      this.canvasWidth = imageData.width;
      this.imageWidth = getImageWidth(elementData);

      csTools.external.cornerstone.updateImage(this.element);

      return;
    }

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    const { image, element } = eventData;

    const lineWidth = toolStyle.getToolWidth();

    for (let i = 0; i < 4; i++) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }

      draw(context, (context) => {
        context.setLineDash([15, 3, 3, 3]);

        const color = toolColors.getColorIfActive(data);

        // Draw the ruler line
        drawLine(
          context,
          element,
          data.handles.start,
          data.handles.end,
          {
            color,
          },
          "canvas"
        );
      });
    }

    draw(context, (context) => {
      const ratio = getRatio(toolData);

      // draw ratio text box background
      context.font = "15px";
      context.fillStyle = "#00F";
      const drawPoint = {
        x: evt.target.lastChild.width / 2 - 30,
        y: evt.target.lastChild.height / 2 - 30,
      };
      context.fillRect(drawPoint.x, drawPoint.y, 60, 25);

      drawTextBox(context, ratio, drawPoint.x, drawPoint.y, "yellow", {});
    });
  }

  handleSelectedCallback() {
    // disable moving handle
  }

  toolSelectedCallback(evt, annotation, interactionType = "mouse") {
    if (evt.detail.buttons !== 1) {
      return;
    }

    moveAnnotation(evt, this, annotation, interactionType);
  }

  mouseDragCallback(evt) {
    if (evt.detail.buttons !== 2) {
      return;
    }

    // allow deviation to 1x canvas width
    const delta = evt.detail.deltaPoints.canvas.x;
    const canvasWidth = evt.target.lastChild.width;
    if (
      this.deviation + delta > canvasWidth ||
      this.deviation + delta < -canvasWidth
    ) {
      return;
    }

    this.deviation += delta;

    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    for (let i = 0; i < 4; i++) {
      const data = toolData.data[i];
      data.handles.start.x += evt.detail.deltaPoints.canvas.x;
      data.handles.end.x -= evt.detail.deltaPoints.canvas.x;
    }

    csTools.external.cornerstone.updateImage(evt.detail.element);
  }

  mouseMoveCallback(evt) {
    const { element, currentPoints } = evt.detail;
    const coords = currentPoints.canvas;
    const toolState = getToolState(element, this.name);

    let imageNeedsUpdate = false;

    let nearTool = false;
    let nearToolType = "";

    for (let d = 0; d < toolState.data.length; d++) {
      const data = toolState.data[d];

      if (this.pointNearTool(element, data, coords, "mouse")) {
        nearTool = true;
        nearToolType = nearToolType ? nearToolType : data.type;
      }

      const nearToolAndNotMarkedActive =
        this.pointNearTool(element, data, coords, "mouse") && !data.active;
      const notNearToolAndMarkedActive =
        !this.pointNearTool(element, data, coords, "mouse") && data.active;

      if (nearToolAndNotMarkedActive || notNearToolAndMarkedActive) {
        data.active = !data.active;
        imageNeedsUpdate = true;
      }
    }

    if (nearTool) {
      evt.target.style.cursor =
        nearToolType === "ruler" ? "ew-resize" : "ns-resize";
    } else {
      evt.target.style.cursor = "auto";
    }

    return imageNeedsUpdate;
  }
}

function generateToolState(xBegin, yBegin, xEnd, yEnd, color, type) {
  return {
    visible: true,
    active: true,
    color,
    invalidated: true,
    allowedOutsideImage: true,
    handles: {
      start: {
        x: xBegin,
        y: yBegin,
        highlight: true,
        active: false,
      },
      end: {
        x: xEnd,
        y: yEnd,
        highlight: true,
        active: true,
      },
      textBox: {
        active: false,
        hasMoved: false,
        movesIndependently: false,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
      },
    },
    type,
  };
}

function getRatio(toolData) {
  const chestWidth = Math.abs(
    toolData.data[1].handles.start.x - toolData.data[0].handles.start.x
  );
  const heartWidth = Math.abs(
    toolData.data[3].handles.start.x - toolData.data[2].handles.start.x
  );
  return ((heartWidth / chestWidth) * 100).toFixed(2) + "%";
}

function lineSegDistance(start, end, coords) {
  const cornerstone = csTools.external.cornerstone;

  const lineSegment = { start, end };

  return csTools.external.cornerstoneMath.lineSegment.distanceToPoint(
    lineSegment,
    coords
  );
}

function getImageWidth(elementData) {
  const canvasWidth = elementData.canvas.width;
  const imageOriginalWidth = elementData.image.width;
  const imageScale = elementData.viewport.scale;

  return imageOriginalWidth * imageScale;
}

function scaleHeartRuler(
  oldCanvasWidth,
  oldImageWidth,
  newCanvasWidth,
  newImageWidth,
  toolState,
  deviation,
  newImageY
) {
  const newToolState = [];
  const scale = newImageWidth / oldImageWidth;
  const oldCenterX = oldCanvasWidth / 2;
  const newCenterX = newCanvasWidth / 2;
  const newDeviation = deviation * scale;

  for (let i = 0; i < 4; i++) {
    const data = toolState.data[i];
    const start = data.handles.start;
    const end = data.handles.end;
    const oldDeltaX = (start.x + end.x) / 2 - oldCenterX;
    let newX = newCenterX + oldDeltaX * scale;

    const startX = newX + newDeviation;
    const startY = 0;
    const endX = newX - newDeviation;

    newToolState.push(
      generateToolState(
        startX,
        startY,
        endX,
        newImageY,
        i < 2 ? "orange" : "red",
        "ruler"
      )
    );
  }

  return newToolState;
}
