/*
 * File: image-viewer.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 22nd February 2022 3:03:22 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */
import * as cornerstone from "cornerstone-core";
import { forwardRef, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";

interface Props {
  imageUrl: string;
  onLoaded?: (imageUrl?: string) => void;
  onError?: (error: Error) => void;
}

export const ImageViewer = forwardRef<HTMLDivElement, Props>(
  ({ imageUrl, onLoaded, onError }: Props, ref) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
      const loadData = async function () {
        try {
          const element = (ref as any).current;
          cornerstone.enable(element);
          const image = await cornerstone.loadAndCacheImage(imageUrl);
          cornerstone.displayImage(element, image);
          cornerstone.resize(element, true);
          cornerstone.reset(element);
          onLoaded && onLoaded(imageUrl);
          setIsLoaded(true);
        } catch (err: any) {
          Sentry.captureException(err);
          console.log("load failed", err);
          const errMessage = err?.message || "Failed to load image";
          onError && onError(errMessage);
        }
      };
      if (!isLoaded && ref && imageUrl) loadData();
    }, [isLoaded, imageUrl, onError, onLoaded, ref]);

    useEffect(() => {
      canvasRef.current?.getContext("2d", { willReadFrequently: true });
    }, [canvasRef]);

    return (
      <div className="relative w-full h-full" ref={ref}>
        <canvas ref={canvasRef} className="cornerstone-canvas" />
      </div>
    );
  }
);
