/*
 * File: smart-labeling.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 18th December 2021 8:53:08 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AnnotateType } from "constants/annotation.constant";
import {
  AIAssistanceService,
  AutoAnnotateType,
} from "services/ai-assistance-service";
import { RootState } from "store";
import { RequestStatus } from "store/base/base.state";
import { selectBatchObservations } from "../image-labeling/image-labeling.selectors";
import {
  selectSmartLabelingData,
  selectSmartLabelingJobId,
  selectSmartLabelingStatus,
} from "./smart-labeling.selectors";
import {
  AutoAnnotatePair,
  ObjectDetectionClass,
  SmartLabelingState,
} from "./smart-labeling.state";
import { selectWorkingFile } from "../image-labeling/image-labeling.selectors";

export enum SmartLabelingThunk {
  INIT_SMART_LABELING_ASYNC = "smart-labeling/initSmartLabelingAsync",
  AUTO_ANNOTATE_ASYNC = "smart-labeling/autoAnnotateAsync",
}

export const initSmartLabelingAsync = createAsyncThunk(
  SmartLabelingThunk.INIT_SMART_LABELING_ASYNC,
  async (jobId: number, { getState }) => {
    const root = getState() as RootState;
    const status = selectSmartLabelingStatus(root);
    const smartLabelingJobId = selectSmartLabelingJobId(root);
    if (jobId === smartLabelingJobId && status !== RequestStatus.IDLE) {
      // already loaded, does nothing.
      return null;
    }

    const observations = selectBatchObservations(root);
    const parentObservationIds: Map<number, boolean> = new Map();
    observations.forEach((batchObservation) => {
      if (!batchObservation.parentId) return;
      parentObservationIds.set(batchObservation.parentId, true);
    });

    const labels: { [key: string]: ObjectDetectionClass } = {};
    observations.forEach((label) => {
      if (parentObservationIds.has(label.id)) return;
      if (label.isSystemLabel) return;
      const id = label.id.toString();
      labels[id] = {
        id: id,
        name: label.name,
        supportedAnnotationTypes: [label.annotateType as AnnotateType],
        model: "",
      };
    });

    const avaiableClasses: {
      [key: string]: ObjectDetectionClass;
    } = await _loadCocoClassList();

    return {
      jobId,
      labels,
      avaiableClasses,
    };
  }
);

const _loadCocoClassList = async (): Promise<{
  [key: string]: ObjectDetectionClass;
}> => {
  const classListData = await AIAssistanceService.getModelClassList();
  if (!classListData.data || classListData.data.length === 0) return {};
  const annotateTypeMappers = (types: string[]) => {
    let items = [];
    for (let type of types) {
      if (type === AutoAnnotateType.BBOX) items.push(AnnotateType.BOUNDING_BOX);
      else if (type === AutoAnnotateType.POLYGON)
        items.push(AnnotateType.POLYGON);
    }
    return items;
  };
  const classes = classListData.data.map((item) => {
    return {
      supportedAnnotationTypes: annotateTypeMappers(item.type),
      id: `${item.name}-${item.model}`,
      name: item.name,
      model: item.model,
    };
  });
  classes.sort((a, b) => a.name.localeCompare(b.name));
  const avaiableClasses: { [key: string]: ObjectDetectionClass } = {};
  classes.forEach((c) => {
    avaiableClasses[c.id] = c;
  });
  return avaiableClasses;
};

export const autoAnnotateAsync = createAsyncThunk(
  SmartLabelingThunk.AUTO_ANNOTATE_ASYNC,
  async (pairs: AutoAnnotatePair[], { getState }) => {
    const root = getState() as RootState;
    const smartLabelingData = selectSmartLabelingData(root);
    const selectedPairs = pairs.filter((p) => p.selected);
    const file = selectWorkingFile(root);
    const foundAnnotations = await AIAssistanceService.autoDetectObjectsOnImage(
      file.originalUrl || file.url,
      selectedPairs,
      smartLabelingData.avaiableClasses,
      smartLabelingData.labels
    );
    return foundAnnotations;
  }
);

export const smartLabelingReducerBuilder = (
  builder: ActionReducerMapBuilder<SmartLabelingState>
) => {
  return builder
    .addCase(initSmartLabelingAsync.pending, (state) => {
      state.status = RequestStatus.LOADING;
    })
    .addCase(initSmartLabelingAsync.rejected, (state, action) => {
      state.status = RequestStatus.FAILURE;
    })
    .addCase(initSmartLabelingAsync.fulfilled, (state, action) => {
      if (action.payload) {
        let isSameBatch = true;
        for (let key of Object.keys(state.labels)) {
          if (!action.payload.labels.hasOwnProperty(key)) {
            isSameBatch = false;
            break;
          }
        }

        state.jobId = action.payload.jobId;
        state.labels = action.payload.labels;
        state.avaiableClasses = action.payload.avaiableClasses;
        state.status = RequestStatus.SUCCESS;
        if (!isSameBatch) state.autoAnnotatePairs = [];
        state.smartLabelingEnable = false;
        state.autoAnnotateEnable = false;
      }
    });
};
