/*
 * File: image-annotation-downvoted.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 8th September 2022 4:01:38 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store";
import { ImageWorkspaceState } from "../../image-workspace.state";
import { selectIsReviewJob } from "../../image-labeling/image-labeling.selectors";

const THUNK_NAME = "imageWorkspace/imageAnnotationDownVotedAsync";

export const imageAnnotationDownVotedAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: { annotationId: string }, { getState }) => {
    const root = getState() as RootState;
    const isReviewJob = selectIsReviewJob(root);
    if (!isReviewJob) return null;
    return payload;
  }
);

export const imageAnnotationDownVotedBuilder = (
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) => {
  return builder.addCase(
    imageAnnotationDownVotedAsync.fulfilled,
    ({ imageAnnotations }, action) => {
      if (!action.payload) return;
      const { annotationId } = action.payload;
      const annotation = imageAnnotations.annotations.find(
        (anno) => anno.uuid === annotationId
      );
      if (annotation) annotation.vote = -1;
      const relation = imageAnnotations.relationAnnotations.find(
        (rela) => rela.uuid === annotationId
      );
      if (relation) relation.vote = -1;
    }
  );
};
