import { IconCloseCircle } from "components/common/vb-icon.component";
import { Label } from "domain/text-labeling";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  label: Label;
  onClose(): void;
}
export const LabelInformation = ({ label, onClose }: Props) => {
  const { t } = useTranslation();
  const {description, observationSetting} = label.observation;
  const {caveats, illustrations} = observationSetting;

  const activedIllustrations = useMemo(() => {
    if (!illustrations) return [];
    return illustrations.filter(item => item.active === "true");
  }, [illustrations]);
  
  const [currentIllusIndex, setCurrentIllusIndex] = useState(0);
  const currentIllus = useMemo(() => {
    if (!activedIllustrations || activedIllustrations.length < 0) return null;
    return activedIllustrations[currentIllusIndex];
  }, [currentIllusIndex, activedIllustrations]);
  const [showTools, setShowTools] = useState(false);

  const handleNextIllus = (direction: number = 1) => {
    setCurrentIllusIndex((currentIllusIndex + direction + activedIllustrations.length) % activedIllustrations.length);
  }

  return (
    <div className="flex-shrink-0 h-full py-4 overflow-y-auto text-white border-l bg-blueGray-900 border-blueGray-600 w-96">
      <div className="flex items-center justify-between gap-4 px-4">
        <div className="py-2 text-xl">
        {t("labelerworkspace:panel.imageLabelInfo.labelName", {name: label.observation.name})}
        </div>
        <button className="w-6 h-6" onClick={onClose}>
          <IconCloseCircle className="w-6 h-6" />
        </button>
      </div>
      {
        description &&
        <div className="px-4 mt-4">
          <p className="mb-2">{t("labelerworkspace:panel.imageLabelInfo.labelDescription")}</p>
          <p className="p-4 border rounded-md bg-blueGray-800 border-blueGray-800">{description}</p>
        </div>
      }
      
      {
        caveats &&
        <div className="px-4 mt-4">
          <p className="mb-2">{t("labelerworkspace:panel.imageLabelInfo.labelCaveats")}</p>
          <p className="p-4 border rounded-md bg-blueGray-800 border-blueGray-800">{caveats}</p>
        </div>
      }
      
      <div className="px-4 mt-4">
        <p className="mb-2">{t("labelerworkspace:panel.imageLabelInfo.labelIllustration")}</p>
        {
          currentIllus &&
          <div>
            {
              currentIllus.url &&
              <div className="relative w-full mb-4 rounded-md h-80 bg-blueGray-800"
                style={{
                  backgroundImage: `url(${currentIllus.url})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
                onMouseEnter={() => setShowTools(true)}
                onMouseLeave={() => setShowTools(false)}
              >
                {
                  showTools &&
                  <div className="absolute left-0 flex items-center justify-between w-full h-20"
                    style={{top: "40%"}}
                  >
                    <i className="w-10 h-10 text-3xl cursor-pointer uir-arrow-left" onClick={_ => handleNextIllus()}></i>
                    <i className="w-10 h-10 text-3xl cursor-pointer uir-arrow-right" onClick={_ => handleNextIllus(-1)}></i>
                  </div>
                }
              </div>
            }
            {
              currentIllus.note &&
              <div>
                <p className="mb-2">{t("labelerworkspace:panel.imageLabelInfo.labelImageExplain")}</p>
                <p className="p-4 border rounded-md bg-blueGray-800 border-blueGray-800">{currentIllus.note}</p>
              </div>
            }
          </div>
        }
      </div>
    </div>
  );
};
