/*
 * File: text-issues.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 1st July 2022 3:03:32 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { IconClose } from "components/common/vb-icon.component";
import { Issue, IssueStatus } from "domain/common/issue";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useTranslation } from "react-i18next";
import { enqueueSuccessNotification } from "store/common/notification/notification.actions";
import {
  selectSelectedTextIssueId,
  selectTextIssues,
} from "store/labeler/text-workspace/text-issues/text-issues.selector";
import { selectTextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { resolveTextIssueAsync } from "store/labeler/text-workspace/text-issues/thunks/resolve-issue.thunk";
import {
  textIssueIdSelected,
  toggleDisplayTextIssues,
} from "store/labeler/text-workspace/text-workspace.slice";
import { IssueList } from "./issue-list/issue-list.component";
import { VBTabs } from "components/common/vb-tabs/vb-tabs.component";
import { useMemo, useState } from "react";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import * as Sentry from "@sentry/react";

export const TextIssuesComponent = () => {
  const dispatch = useAppDispatch();
  const textIssues = useAppSelector(selectTextIssues);
  const selectedId = useAppSelector(selectSelectedTextIssueId);
  const mode = useAppSelector(selectTextLabelingMode);
  const [activeTab, setActiveTab] = useState("all");
  const { t } = useTranslation();
  const filteredIssues = useMemo(() => {
    if (activeTab === "all") return textIssues;
    return textIssues.filter((issue) => issue.status === activeTab);
  }, [textIssues, activeTab]);

  function handleSelectIssue(issue: Issue) {
    dispatch(textIssueIdSelected(issue.id));
  }

  function handleClickClose() {
    dispatch(toggleDisplayTextIssues());
  }

  async function handleResolveIssue(issue: Issue) {
    try {
      await dispatch(resolveTextIssueAsync({ issue }));
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
    } catch (error) {
      Sentry.captureException(error);
      console.log("ERROR", error);
    }
  }

  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 z-10 w-full h-full bg-white">
      <div className="relative h-full py-4 overflow-y-auto">
        <div className="flex items-center justify-between p-4">
          <span className="flex-auto text-lg font-bold">Text Issues</span>

          <button onClick={handleClickClose}>
            <IconClose className="flex-none w-5 h-5" />
          </button>
        </div>
        {mode === TextLabelingMode.LABELER && filteredIssues.length === 0 && <div className="p-4 text-center">There is no open issues!</div>}
        {mode === TextLabelingMode.CUSTOMER && (
          <VBTabs
            className="mx-4 text-sm"
            activeClass="border-primary text-primary px-2 py-1"
            inActiveClass="border-gray-100 border-opacity-0 px-2 py-1"
            activeTab={activeTab}
            onSelect={setActiveTab}
            tabs={[
              { id: "all", name: "All" },
              { id: IssueStatus.OPENED, name: IssueStatus.OPENED },
              { id: IssueStatus.FIXED, name: IssueStatus.FIXED },
              { id: IssueStatus.RESOLVED, name: IssueStatus.RESOLVED },
            ]}
          />
        )}
        <div>
          <IssueList
            mode={mode}
            data={filteredIssues}
            selectedIssueId={selectedId}
            onSelectItem={handleSelectIssue}
            onClickResolve={handleResolveIssue}
          />
        </div>
      </div>
    </div>
  );
};
