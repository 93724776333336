/*
 * File: use-websocket.hook.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 22nd March 2022 4:50:23 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect, useRef } from "react";
import { selectUserRole } from "store/auth/auth.selectors";
import { UserRole } from "store/auth/auth.state";
import { selectCurrentWorkspace } from "store/common/user-workspace/user-workspace.selectors";
import { AIScalerWebSocket, WebSocketMessageHandler } from ".";

export const useWebsocket = (onMessage: WebSocketMessageHandler) => {
  const client = useRef(new AIScalerWebSocket(onMessage));
  const workspace = useAppSelector(selectCurrentWorkspace);
  const role = useAppSelector(selectUserRole);

  const connectWebsocketAsync = useCallback(async () => {
    await client.current.disconnect();
    if (!workspace || role !== UserRole.CUSTOMER) return;
    client.current.init();
    client.current.connect(workspace.groupId);
  }, [role, workspace, client]);

  useEffect(() => {
    connectWebsocketAsync();
  }, [connectWebsocketAsync]);
};
