import { useEffect, useMemo, useState } from "react";
import { useTextToSpeechLabelingContext } from "./context/text-to-speech-labeling.context";
import { TextToSpeechText } from "./components/text-to-speech-text.component";
import { TextToSpeechRecorder } from "./components/text-to-speech-recorder.component";
import { SpeechToTextHeaderBar } from "../speech-to-text-labeling/components/speech-to-text-header-bar.component";
import { SpeechToTextNote } from "../speech-to-text-labeling/components/speech-to-text-note.component";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { ErrorDialog } from "../batch-labeling/components/error-dialog.component";
import { WorkspaceEmpty } from "../image-labeling/components/workspace-empty/workspace-empty.component";
import { ImageLabelingErrorDialog } from "../image-labeling/components/error-dialog/error-dialog.component";
import { TextToSpeechAnnotations } from "./components/text-to-speech-list-annotations.component";
import { ReopenDialog } from "../image-review/components/reopen-dialog.component";
import { IssueManagementProvider } from "pages/customer/projects/project-batch/batch-detail/pages/tasks/components/issue/issue-management.provider";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  deleteComplexJobsJobInBatch,
  setComplexJobsJobData,
  setComplexJobWorkingStatus,
} from "store/labeler/complex-jobs/complex-jobs.slice";
import { WorkingStatus } from "store/labeler/complex-jobs/complex-jobs.state";
import { useAutoSave } from "hooks/use-auto-save.hook";
import {
  selectComplexJobIdsByStatus,
  selectComplexJobsAutoSaveInSecond,
} from "store/labeler/complex-jobs/complex-jobs.selectors";
import { selectIsAutoSaveConfig } from "store/labeler/complex-jobs/complex-jobs-editor-setting/complex-jobs-editor-setting.selector";
import TextToSpeechLabels from "./components/text-to-speech-labels.component";
import { AnnotationAttributeItem } from "services/label-service/dtos/annotations.dto";

interface Props {
  showMenuIssue?: boolean;
  fixedMenuIssue?: boolean;
  showIssues?: boolean;
}
export const TextToSpeechLabelingDetails = ({
  showMenuIssue = true,
  fixedMenuIssue = false,
  showIssues = true,
}: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showReopenReason, setShowReopenReason] = useState(true);

  const workingJobIds = useAppSelector(
    selectComplexJobIdsByStatus(WorkingStatus.WORKING)
  );
  const isAutoSaveConfig = useAppSelector(selectIsAutoSaveConfig);
  const autoSaveInSecond = useAppSelector(selectComplexJobsAutoSaveInSecond);

  const {
    error,
    setError,
    uiJob,
    setUIJob,
    isLoadingJob,
    isSavingJob,
    saveJob,
    skipJob,
    handleConfirmExit,
    hasChanged,
    setHasChanged,
    overwriteUIJob,
  } = useTextToSpeechLabelingContext();

  const autoSave = useAutoSave({
    enabled: isAutoSaveConfig && workingJobIds.length > 0,
    onSave: async () => {
      const promises = workingJobIds.map((id) =>
        saveJob(true, false, false, false, id)
      );
      await Promise.all(promises);
    },
    duration: autoSaveInSecond && autoSaveInSecond * 1000,
  });

  const disabledComplete = useMemo(() => {
    if (!uiJob) return true;
    return (
      isLoadingJob ||
      isSavingJob ||
      (uiJob.recordedBlob === undefined &&
        uiJob.resource === undefined &&
        !uiJob.isStepReviewJob)
    );
  }, [uiJob, isLoadingJob, isSavingJob]);

  useEffect(() => {
    if (uiJob && uiJob.job && uiJob.isFromBatch) {
      dispatch(
        setComplexJobsJobData({
          id: uiJob.job.id,
          data: uiJob,
        })
      );
    }
  }, [uiJob, dispatch]);

  const handleErrorDialogClose = () => {
    history.push(Routes.LABELER_HOME);
  };

  const handleSkip = async () => {
    if (!error || !error.data) return;
    await skipJob(error.data as number);
    setError(undefined);
    if (uiJob && uiJob.job && uiJob.isFromBatch) {
      dispatch(deleteComplexJobsJobInBatch(uiJob.job.id));
    }
  };

  if (error) {
    const errorType = error.type;
    if (errorType === "no_job") return <WorkspaceEmpty />;
    if (errorType === "unknow")
      return (
        <ErrorDialog
          visible
          onClose={handleErrorDialogClose}
          message={error.message}
        />
      );
    if (errorType === "skippable")
      return (
        <ImageLabelingErrorDialog
          visible
          error={error.message}
          onClose={handleErrorDialogClose}
          onSubmit={handleSkip}
        />
      );
  }

  if (!uiJob) return null;

  const handleSave = async () => {
    await saveJob(true);
  };

  const handleComplete = async (accept = false, reject = false) => {
    await saveJob(true, true, accept, reject);
  };

  const setUIJobField = (field: string, value: any) => {
    setUIJob({ ...uiJob, [field]: value });
    setHasChanged(true);
    dispatch(setComplexJobWorkingStatus(WorkingStatus.WORKING));
  };

  const handleClickBack = () => {
    handleConfirmExit();
  };

  const handleOnMicStopped = (blob: any, localUrl: string) => {
    setUIJob({ ...uiJob, audioUrl: localUrl, recordedBlob: blob });
    setHasChanged(true);
    dispatch(setComplexJobWorkingStatus(WorkingStatus.WORKING));
  };

  const handleOnAttributesChange = (attributes?: AnnotationAttributeItem[]) => {
    setUIJob({ ...uiJob, attributes });
    setHasChanged(true);
    dispatch(setComplexJobWorkingStatus(WorkingStatus.WORKING));
  };

  const handleClickDelete = () => {
    setUIJob({
      ...uiJob,
      audioUrl: undefined,
      recordedBlob: undefined,
      resource: undefined,
    });
    setHasChanged(false);
    dispatch(setComplexJobWorkingStatus(WorkingStatus.WORKING));
  };

  return (
    <div className="relative flex flex-col min-h-screen gap-6 p-6 bg-background-900">
      {uiJob && uiJob.batch && uiJob.task && (
        <SpeechToTextHeaderBar
          containerClasses="p-0"
          isReviewStepJob={uiJob.isStepReviewJob}
          batch={uiJob.batch}
          task={uiJob.task}
          project={uiJob.project}
          disabledComplete={disabledComplete}
          disabledSave={isLoadingJob || isSavingJob || !hasChanged}
          countDownSecond={uiJob.countDownSecond}
          isReady={!isLoadingJob}
          onClickBack={handleClickBack}
          onClickSave={handleSave}
          onClickComplete={handleComplete}
          showButtons={!uiJob.isTaskReview}
          autoSave={autoSave}
        />
      )}

      <div className="grid w-full grid-cols-12 mt-2">
        <div className="col-span-12">
          <TextToSpeechText text={uiJob.text} />
        </div>
      </div>

      <div className="grid w-full grid-cols-12">
        <div className="col-span-12">
          <TextToSpeechRecorder
            disabledRecord={uiJob.isStepReviewJob || uiJob.isTaskReview}
            url={uiJob.audioUrl}
            onMicStopped={handleOnMicStopped}
            onClickDelete={handleClickDelete}
          />
        </div>
      </div>

      <div className="grid w-full grid-cols-12">
        <div className="col-span-12">
          <SpeechToTextNote
            disabled={uiJob.isStepReviewJob || uiJob.isTaskReview}
            note={uiJob.note}
            onNoteChanged={(v) => setUIJobField("note", v)}
          />
        </div>
      </div>

      {uiJob.canViewPreviousStepResult && (
        <div>
          <TextToSpeechAnnotations
            currentJobId={uiJob.job?.id}
            annotations={uiJob.previousJobsAnnotations}
            onSelected={overwriteUIJob}
            acceptBatchObservation={uiJob.acceptBatchObservation}
            rejectBatchObservation={uiJob.rejectBatchObservation}
            workspaceId={uiJob.project?.workspaceId}
          />
        </div>
      )}

      <TextToSpeechLabels uiJob={uiJob} onChange={handleOnAttributesChange} />

      {uiJob.reopenReason && showReopenReason && (
        <ReopenDialog
          open={showReopenReason}
          message={uiJob.reopenReason}
          onClose={() => setShowReopenReason(false)}
          onSubmit={() => setShowReopenReason(false)}
        />
      )}
      {uiJob.task && showIssues && (
        <IssueManagementProvider
          menuPosition="bottomLeft"
          taskId={uiJob.task.id}
          jobId={uiJob.job?.id}
          isReviewer={!!uiJob.job && uiJob.isStepReviewJob}
          jobOptions={uiJob.jobOptions}
          fixedMenu={fixedMenuIssue}
          showMenu={showMenuIssue}
        />
      )}
    </div>
  );
};
