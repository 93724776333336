/*
 * File: image-labeling.utils.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 14th August 2021 9:12:03 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { StorageFileDTO } from "models/dataset/storage-file.model";
import { TaskObservationDTO } from "services/label-service/dtos";
import { Rectangle } from "utilities/math/rectangle";

export function getUserNameFromEmail(email: string) {
  return email.substring(0, email.indexOf("@"));
}

export function convertFileCleaningDataToMaskAnnotation(file: StorageFileDTO): Rectangle[] {
  const maskAnnotations: Rectangle[] = [];

  // convert fileInfo.dataCleaningResult to maskAnnotations
  if (file.dataCleaningResult && file.dataCleaningResult.length > 0){
    for (const dataCleaningResultItem of file.dataCleaningResult) {
      if (dataCleaningResultItem && dataCleaningResultItem.cleaningResult) {
        const { text_regions } = dataCleaningResultItem.cleaningResult;
        for (const textRegion of text_regions) {
          if (textRegion && textRegion.length >= 2) {
            const topLeft = textRegion[0];
            const bottomRight = textRegion[1];
            const width = Math.abs(bottomRight.x - topLeft.x);
            const height = Math.abs(bottomRight.y - topLeft.y);
            maskAnnotations.push({
              x: topLeft.x,
              y: topLeft.y,
              width,
              height,
            });
          }
        }
      }
    }
  }

  return maskAnnotations;
}

export function taskOvservationMapper(data: any): Partial<TaskObservationDTO[]>{
  return []
}
