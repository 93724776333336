/*
 * File: text-labeling-batch.slice.ts
 * Project: app-aiscaler-web
 * File Created: Sunday, 10th July 2022 10:05:24 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "store/base/base.state";
import {
  defaultLabelingBatchState,
  LabelingBatchState,
  WorkingStatus,
} from "./text-labeling-batch.state";
import { labeingBatchReducerBuidler } from "./text-labeling-batch.thunk";

const SLICE_NAME: string = "labeling-batch";

const slice = createSlice({
  name: SLICE_NAME,
  initialState: defaultLabelingBatchState,
  reducers: {
    resetLabelingBatchState(state: LabelingBatchState) {
      Object.assign(state, defaultLabelingBatchState);
    },

    textTaskChanged: (state: LabelingBatchState) => {
      state.pollJobStatus = RequestStatus.IDLE;
    },

    jobSelected: (state: LabelingBatchState, action: PayloadAction<number>) => {
      state.selectedJobId = action.payload;
    },

    textPreviousJobSelected: (state: LabelingBatchState) => {
      const { selectedJobId } = state;
      const { allIds } = state.jobs;
      const idx = allIds.indexOf(selectedJobId);
      const previousIdx = (idx - 1 + allIds.length) % allIds.length;
      state.selectedJobId = allIds[previousIdx] as number;
    },

    textNexJobSelected: (state: LabelingBatchState) => {
      const { selectedJobId } = state;
      const { allIds } = state.jobs;
      const idx = allIds.indexOf(selectedJobId);
      const nextIndex = (idx + 1) % allIds.length;
      state.selectedJobId = allIds[nextIndex] as number;
    },

    jobLoaded: (state: LabelingBatchState, action: PayloadAction<number>) => {
      state.selectedJobId = action.payload;
    },

    textLabelingJobEdited: (
      state: LabelingBatchState,
      action: PayloadAction<number>
    ) => {
      state.jobWorkingStatus[action.payload] = WorkingStatus.WORKING;
    },
  },
  extraReducers: (builder) => {
    labeingBatchReducerBuidler(builder);
  },
});

export const {
  resetLabelingBatchState,
  jobSelected,
  jobLoaded,
  textPreviousJobSelected,
  textNexJobSelected,
  textLabelingJobEdited,
  textTaskChanged
} = slice.actions;

export const textLabelingBatchReducer = slice.reducer;
