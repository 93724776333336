import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { IssueStatus } from "domain/common/issue";
import { useEffect, useMemo, useState } from "react";
import { TaskStatus } from "services/label-service/dtos";
import { selectBatchStepLabelerOptions } from "store/customer/batch/batch.selectors";
import { classnames } from "utilities/classes";
import { TaskFilter } from "../../tasks/batch-tasks.context";
import { useAppSelector } from "hooks/use-redux";
import { useTranslation } from "react-i18next";
import { useBatchDetailContext } from "../../../context/batch-detail.context";
import { useDatasetTag } from "hooks/datasets/use-dataset-tag.hook";
import DatePicker from "react-datepicker";
import { createPortal } from "react-dom";
import * as Sentry from "@sentry/react";
import useConsensusOptions from "./use-consensus-options";

interface Props {
  visible: boolean;
  onClear?: () => void;
  onConfirm?: (v: TaskFilter) => void;
  defaultFilter?: TaskFilter | null;
}
export const TaskFiltersComponent = ({
  visible,
  onClear,
  onConfirm,
  defaultFilter,
}: Props) => {
  const { t } = useTranslation();
  const { labels, batch, project } = useBatchDetailContext();
  const [filter, setFilter] = useState<TaskFilter>({ batchId: batch.id });
  const { tags } = useDatasetTag();
  const [createdDate, setCreatedDate] = useState<Date | null>(() => {
    if (defaultFilter?.createdDate) {
      try {
        return new Date(defaultFilter.createdDate);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
    return null;
  });
  const labelersOptions = useAppSelector(
    selectBatchStepLabelerOptions(true, false)
  );

  const labelOptions = useMemo(() => {
    return labels.map(({ observation }) => {
      return { label: observation.name, value: observation.id.toString() };
    });
  }, [labels]);

  const tagOptions = useMemo(
    () => tags.map((tag) => ({ label: tag.name, value: tag.name })),
    [tags]
  );

  const statusOptions = [
    {
      label: t(`enum:taskStatus.${TaskStatus.COMPLETED.toLowerCase()}`),
      value: TaskStatus.COMPLETED,
    },
    {
      label: t(`enum:taskStatus.${TaskStatus.WORKING.toLowerCase()}`),
      value: TaskStatus.WORKING,
    },
    {
      label: t(`enum:taskStatus.${TaskStatus.INITIAL.toLowerCase()}`),
      value: TaskStatus.INITIAL,
    },
  ];

  const issueStatusOptions = [
    {
      label: t(`enum:issueStatus.${IssueStatus.OPENED.toLowerCase()}`),
      value: IssueStatus.OPENED.toLowerCase(),
    },
    {
      label: t(`enum:issueStatus.${IssueStatus.FIXED.toLowerCase()}`),
      value: IssueStatus.FIXED.toLowerCase(),
    },
    {
      label: t(`enum:issueStatus.${IssueStatus.RESOLVED.toLowerCase()}`),
      value: IssueStatus.RESOLVED.toLowerCase(),
    },
  ];
  const {
    options: consensusOptions,
    option: consensusOption,
    onChange: onConsensusChange,
  } = useConsensusOptions(project?.type, filter.consensusOperator);

  function handleDateChange(date: Date | null) {
    setCreatedDate(date);
  }

  function setFilterField(field: keyof TaskFilter, value: any) {
    setFilter({ ...filter, [field]: value });
  }

  function handleClickClearFilter() {
    onClear && onClear();
  }

  function handleClickConfirm() {
    if (createdDate) {
      filter.createdDate = createdDate.getTime();
    }
    onConfirm && onConfirm(filter);
  }

  useEffect(() => {
    if (defaultFilter) setFilter(defaultFilter);
  }, [defaultFilter]);

  return (
    <div
      className={classnames(
        "absolute left-0 top-14 p-4 z-40 bg-white rounded border overflow-y-auto",
        { hidden: !visible }
      )}
      style={{ maxHeight: "40rem" }}
    >
      <div
        className="grid grid-cols-2 gap-x-6 gap-y-3"
        style={{ width: "30rem" }}
      >
        <VBTextInputComponent
          header="File name"
          placeholder="Search"
          className="text-sm"
          containerClassName="col-span-2"
          clearInput
          defaultValue={filter?.fileName}
          onInputChange={(v) => setFilterField("fileName", v)}
        />

        <VBTextInputComponent
          type="number"
          header="Task ID"
          placeholder="Search"
          className="text-sm"
          clearInput
          hideInputIcon
          defaultValue={filter?.taskId}
          onInputChange={(v) => setFilterField("taskId", v)}
        />

        <VBSelectComponent
          className="leading-normal"
          placeholder="All"
          header="Labeling status"
          menuPortalTarget={document.body}
          value={
            statusOptions.find(
              (option) => option.value === filter?.labelingStatus
            ) || ""
          }
          options={statusOptions}
          onChange={(option: any) =>
            setFilterField("labelingStatus", option?.value || "")
          }
          isClearable
        />

        <VBSelectComponent
          className="leading-normal"
          placeholder="All"
          header="Labelers"
          menuPortalTarget={document.body}
          isMulti
          size="none"
          value={
            labelersOptions.filter((option) =>
              filter?.labelers?.includes(option.value)
            ) || []
          }
          options={labelersOptions}
          onChange={(option: any) => {
            setFilterField(
              "labelers",
              option?.map((item: any) => item.value) || []
            );
          }}
          isClearable
        />

        <VBSelectComponent
          className="leading-normal"
          placeholder="Labels"
          header="Labels"
          menuPortalTarget={document.body}
          value={
            labelOptions.find(
              (option) => option.value === filter?.observationId?.toString()
            ) || ""
          }
          options={labelOptions}
          onChange={(option: any) =>
            setFilterField("observationId", option?.value || "")
          }
          isClearable
        />

        <VBSelectComponent
          className="leading-normal"
          placeholder="Tag"
          header="Tag"
          menuPortalTarget={document.body}
          isMulti
          value={
            tagOptions.filter((option) =>
              filter?.tags?.includes(option.value)
            ) || []
          }
          size="none"
          options={tagOptions}
          onChange={(option: any) => {
            const values = option?.map((item: any) => item.value) || [];
            setFilterField("tags", values);
          }}
          isClearable
        />

        <VBSelectComponent
          className="leading-normal"
          placeholder="All"
          header="Issue status"
          menuPortalTarget={document.body}
          value={
            issueStatusOptions.find(
              (option) => option.value === filter?.issueStatus
            ) || ""
          }
          options={issueStatusOptions}
          onChange={(option: any) =>
            setFilterField("issueStatus", option?.value || "")
          }
          isClearable
        />

        <VBSelectComponent
          className="leading-normal"
          placeholder="Select condition"
          header="Disagreement"
          menuPortalTarget={document.body}
          value={consensusOption}
          options={consensusOptions}
          onChange={(newOption: any) => {
            onConsensusChange(newOption);
            if (newOption) {
              setFilterField("consensusOperator", newOption.value);
            } else {
              setFilterField("consensusOperator", "");
            }
          }}
          isClearable
        />

        {consensusOption && (
          <VBTextInputComponent
            type="number"
            header="Threshold"
            placeholder="Enter threshold"
            className="text-sm"
            min={0}
            max={1}
            step={0.01}
            clearInput
            hideInputIcon
            defaultValue={filter?.consensusScore}
            onInputChange={(v) => setFilterField("consensusScore", v)}
          />
        )}

        <div className="space-y-2 text-sm">
          <div className="overflow-hidden font-semibold whitespace-nowrap overflow-ellipsis">
            Create Date
          </div>
          <DatePicker
            className="z-50 px-4 py-1.5 border border-gray-300 rounded focus:outline-none"
            selected={createdDate}
            onChange={handleDateChange}
            placeholderText={"Select Date"}
            dateFormat="dd/LLL/yyyy"
            maxDate={new Date()}
            popperClassName="z-50"
            popperContainer={({ children }) =>
              createPortal(children, document.body)
            }
            closeOnScroll
          />
        </div>
      </div>

      <div className="flex items-center justify-end gap-4 mt-6">
        <button
          className="button-secondary w-28"
          onClick={handleClickClearFilter}
        >
          {t("common:buttonClearFilter")}
        </button>
        <button className="button-primary w-28" onClick={handleClickConfirm}>
          {t("common:buttonConfirm")}
        </button>
      </div>
    </div>
  );
};
