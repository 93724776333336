import { VBModal } from "components/common/vb-modal/vb-modal.component"
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UpdateObservationStrategy } from "services/label-service/dtos";

interface Props {
  open: boolean;
  onClose?: () => void;
  onSubmit?: (option: string) => void;
  disabledSubmit?: boolean;
}

export const OverwriteLabelDialog = ({
  open,
  onClose,
  onSubmit,
  disabledSubmit,
}: Props) => {
  const { t } = useTranslation();
  const updateOptions = [
    {label: "Overwrite", value: UpdateObservationStrategy.OVERRIDE},
    {label: "New version", value: UpdateObservationStrategy.CREATE},
    {label: "Overwrite and reopen", value: UpdateObservationStrategy.OVERRIDE_AND_REOPEN},
  ];
  const optionExplain: Record<UpdateObservationStrategy, string> = {
    [UpdateObservationStrategy.CREATE]: "Create new version (apply only for new batches)",
    [UpdateObservationStrategy.OVERRIDE]: "Update current version (apply for current batches)",
    [UpdateObservationStrategy.OVERRIDE_AND_REOPEN]: "Update current version and reopen (apply for current batches and reopen tasks in working batches)",
  }
  const [selectedOption, setSelectedOption] = useState<UpdateObservationStrategy>(UpdateObservationStrategy.OVERRIDE);

  const handleSubmit = () => {
    onSubmit && onSubmit(selectedOption);
  }

  return (
    <VBModal
      title={"Update option"}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      textSubmit={t("common:buttonUpdate")}
      disableSubmit={disabledSubmit}
    >
      <div className="felx flex-col gap-4">
        <VBSelectComponent
          value={updateOptions.find(op => op.value === selectedOption)}
          options={updateOptions}
          onChange={(option: any) => setSelectedOption(option.value)}
          size="lg"
          menuPortalTarget={document.body}
        />
        <p className="mt-4 text-sm pl-1">{optionExplain[selectedOption]}</p>
      </div>
    </VBModal>
  )
}