/*
 * File: use-workspace-filter.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd April 2023 4:32:58 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useQuery } from "hooks/use-query";
import { useQueryParams } from "hooks/use-query-params.hook";
import { SearchParams } from "models/common/search-params";

export interface WorkspaceFilter {
  name?: string;
  type?: string;
}

export const useWorkspaceFilters = () => {
  const { navigateByQuery, navigateByParams } = useQuery();

  const filter = useQueryParams({
    name: "",
    type: "",
  });

  const setFilter = (filter: WorkspaceFilter) => {
    navigateByParams({ ...filter });
  };

  const setName = (name: string) => {
    navigateByQuery(SearchParams.NAME, name);
  };

  const setType = (type: string) => {
    navigateByQuery(SearchParams.TYPE, type);
  };

  return {
    filter,
    setFilter,
    setName,
    setType,
  };
};
