/*
 * File: reopen-menu.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 28th January 2022 2:38:40 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  IconCheckbox,
  IconCheckboxChecked,
} from "components/common/vb-icon.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { JobOption } from "domain/common/models";
import { useRef, useState } from "react";
import { TaskDTO } from "services/label-service/dtos";
import { classnames } from "utilities/classes";

interface Props {
  open?: boolean;
  task: TaskDTO;
  jobs: JobOption[];
  onClose(): void;
  onSubmit(task: TaskDTO, jobs: JobOption[], reason: string): void;
}
export const ReopenTaskMenu = ({
  open,
  jobs,
  onClose,
  onSubmit,
  task,
}: Props) => {
  const reasonRef = useRef<HTMLTextAreaElement>(null);
  const [selectedJobIds, setSelectedJobIds] = useState<number[]>([]);

  function selectJob(job: JobOption) {
    if (selectedJobIds.includes(job.jobId)) {
      setSelectedJobIds(selectedJobIds.filter((jobId) => jobId !== job.jobId));
    } else {
      setSelectedJobIds([...selectedJobIds, job.jobId]);
    }
  }
  function handleSubmit() {
    const reason = (reasonRef.current?.value as string).trim();
    const selectedJobs = jobs.filter((job) =>
      selectedJobIds.includes(job.jobId)
    );
    onSubmit(task, selectedJobs, reason);
  }

  return (
    <VBModal
      open={!!open}
      title="Reopen jobs?"
      textSubmit="Reopen"
      onSubmit={handleSubmit}
      onClose={onClose}
      disableSubmit={selectedJobIds.length === 0}
    >
      <div>
        <h2 className="text-sm font-semibold">Select labelers</h2>
        <div className="py-4 space-y-4">
          {jobs.map((job) => {
            const selected = selectedJobIds.includes(job.jobId);
            return (
              <button
                onClick={() => selectJob(job)}
                key={job.jobId}
                className={classnames("flex items-center gap-1", {
                  "text-primary": selected,
                })}
              >
                {selected && (
                  <IconCheckboxChecked className="flex-none w-5 h-5" />
                )}
                {!selected && <IconCheckbox className="flex-none w-5 h-5" />}
                <div className="max-w-xs truncate">{job.assignee}</div>
              </button>
            );
          })}
        </div>
        <div className="space-y-3">
          <h2 className="text-sm font-semibold">Reopen reason</h2>
          <textarea
            ref={reasonRef}
            id="reason"
            className="w-full px-4 py-2 border rounded border-background-300"
            placeholder="Reason for reopen task"
          />
        </div>
      </div>
    </VBModal>
  );
};
