/*
 * File: cornerstone-events.model.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 26th July 2021 11:22:15 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

export enum CornerstoneEvents {
  ACTIVE_LAYER_CHANGED = "cornerstoneactivelayerchanged",
  ELEMENT_DISABLED = "cornerstoneelementdisabled",
  ELEMENT_ENABLED = "cornerstoneelementenabled",
  ELEMENT_RESIZED = "cornerstoneelementresized",
  IMAGE_CACHE_CHANGED = "cornerstoneimagecachechanged",
  IMAGE_CACHE_FULL = "cornerstoneimagecachefull",
  IMAGE_CACHE_MAXIMUM_SIZE_CHANGED = "cornerstoneimagecachemaximumsizechanged",
  IMAGE_CACHE_PROMISE_REMOVED = "cornerstoneimagecachepromiseremoved",
  IMAGE_LOADED = "cornerstoneimageloaded",
  IMAGE_LOAD_FAILED = "cornerstoneimageloadfailed",
  IMAGE_RENDERED = "cornerstoneimagerendered",
  INVALIDATED = "cornerstoneinvalidated",
  LAYER_ADDED = "cornerstonelayeradded",
  LAYER_REMOVED = "cornerstonelayerremoved",
  NEW_IMAGE = "cornerstonenewimage",
  PRE_RENDER = "cornerstoneprerender",
  WEBGL_TEXTURE_CACHE_FULL = "cornerstonewebgltexturecachefull",
  WEBGL_TEXTURE_REMOVED = "cornerstonewebgltextureremoved"
}
