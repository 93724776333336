import { createAsyncThunk } from "@reduxjs/toolkit";
import { AnnotationData } from "domain/image-labeling";
import { RootState } from "store";
import { selectAllAnnotations } from "../image-annotations.selectors";

const THUNK_NAME = "imageWorkspace/imageAnnotationSelectedAsync";

interface ImageAnotationPayload<T> {
  jobId: number;
  data: T;
  annotationId: number;
}

export const imageAnnotationSelectedAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: ImageAnotationPayload<AnnotationData[]>, { getState }) => {
    const state = getState() as RootState;
    const { jobId, data, annotationId } = payload;
    const annotations = selectAllAnnotations(state);
    const annotation = annotations.find(
      (anno) =>
        anno.id === annotationId ||
        (anno.jobId === jobId && anno.uuid === data[0].uuid)
    );
    return annotation;
  }
);
