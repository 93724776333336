import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { useState } from "react";

export interface TasksReopenDialogPros {
  open: boolean;
  onClose?: () => void;
  onSubbmit?: (reason: string) => void;
}

export const TasksReopenDialog = ({
  open,
  onClose,
  onSubbmit,
}: TasksReopenDialogPros) => {
  const [reason, setReason] = useState("");

  return (
    <VBModal
      title={"Reopen"}
      open={open}
      onClose={onClose}
      textSubmit={"Reopen"}
      disableSubmit={false}
      onSubmit={(_) => onSubbmit && onSubbmit(reason)}
    >
      <div className="flex flex-col gap-2">
        <p>Reason</p>
        <textarea
          className="w-full p-2 border-2 rounded border-background-300"
          rows={4}
          value={reason}
          placeholder="Enter reopen reason"
          onChange={(e) => setReason(e.target.value as string)}
        />
      </div>
    </VBModal>
  );
};
