import { VBResizer } from "components/common/vb-resizer.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { StepData } from "pages/customer/projects/project.type";
import { useCallback } from "react";
import { ProjectWorkflowStepEdit } from "../../project-workflow/project-workflow-step-edit.component";
import { ProjectWorkflowSteps } from "../../project-workflow/project-workflow-steps.component";
import { useCreateProjectTemplateContext } from "../context/create-project-template.context";
import { useProjectWorkflowTab } from "../hooks/use-project-workflow-tab.hook";

export default function ProjectWorkflowTab() {
  const { steps, onProjectAdvancedChanged, setDefaultSteps } =
    useCreateProjectTemplateContext();
  const {
    isDisabledWorkflow,
    selectedStep,
    selectedStepIndex,
    selectedWorkflowId,
    setSelectedStepIndex,
    workflowOptions,
    handleChangeWorkflow,
    onChangeStep,
  } = useProjectWorkflowTab();

  const handleInitSteps = useCallback(
    (steps: StepData[]) => {
      setDefaultSteps(steps);
      onProjectAdvancedChanged({ steps });
    },
    [onProjectAdvancedChanged, setDefaultSteps]
  );

  const handleChangeSteps = useCallback(
    (steps: StepData[]) => {
      onProjectAdvancedChanged({ steps });
    },
    [onProjectAdvancedChanged]
  );

  return (
    <div className="flex flex-col h-full rounded shadow bg-background-100">
      <div className="z-10 flex items-center justify-start flex-initial w-full gap-1 px-4 bg-white border-b-2 h-14">
        <p className="text-sm text-background-500">Workflows /</p>
        <VBSelectComponent
          defaultValue={workflowOptions[0]}
          options={workflowOptions}
          value={workflowOptions.find(
            (item) => item.value === selectedWorkflowId
          )}
          bordered={false}
          onChange={handleChangeWorkflow}
        />
      </div>
      <div className="relative flex flex-auto overflow-auto">
        <div className="h-full p-6 overflow-auto" style={{ width: "40rem", minWidth: "40rem" }}>
          {selectedWorkflowId && (
            <ProjectWorkflowSteps
              disabled={isDisabledWorkflow}
              steps={steps}
              selectedStepIndex={selectedStepIndex}
              setSelectedStepIndex={setSelectedStepIndex}
              workflowId={selectedWorkflowId}
              onInit={handleInitSteps}
              onChange={handleChangeSteps}
              onChangeStep={onChangeStep}
            />
          )}
        </div>
        <VBResizer />
        <div
          className="flex-1 h-full overflow-auto bg-white border-l"
          style={{ minWidth: "40rem" }}
        >
          {selectedStep && (
            <ProjectWorkflowStepEdit
              selectedStep={selectedStep}
              onChange={onChangeStep}
            />
          )}
        </div>
      </div>
    </div>
  );
}
