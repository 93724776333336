/*
 * File: storage-keys.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 2nd August 2021 1:36:52 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export enum StorageKeys {
  KEYCLOAK_CLIENT_ID = "_KEYCLOAK_CLIENT_ID_",
  USER_SCOPE = "_USER_SCOPE_",
  WORKSPACE_ID = "_WORKSPACE_ID_",
  CURRENT_USER = "_CURRENT_USER_",
  _WORKING_BATCH_ID_ = "_WORKING_BATCH_ID_",
  _IMAGE_LABELING_TYPE_ = "_IMAGE_LABELING_TYPE_",
  _USE_AI_TRANSCRIBE_ = "_USE_AI_TRANSCRIBE_",
  DASH_BOARDH_LATEST_TIME_LOADED = "DASH_BOARD_LATEST_TIME_LOADED",
  DASH_BOARDH_FROM_DATE = "DASH_BOARDH_FROM_DATE",
  DASH_BOARDH_TO_DATE = "DASH_BOARDH_TO_DATE",
  DASH_BOARDH_DATE_RANGE = "DASH_BOARDH_DATE_RANGE",
  DASH_BOARDH_COMPLETED_TASK_FILTER = "DASH_BOARDH_COMPLETED_TASK_FILTER",
  DASH_BOARDH_TABLE_PROJECT_TYPE = "DASH_BOARDH_TABLE_PROJECT_TYPE",
  DISMISS_GEN_ANNO_BATCH_PREFIX = "DISMISS_GEN_ANNO_BATCH_",
  LAST_ACTIVITY_TIME = "LAST_ACTIVITY_TIME",
  TRACKING_BUG = "TRACKING_BUG"
}
