/*
 * File: text-labeling-batch.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 11th July 2022 4:19:31 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { UniqueId } from "domain/common";
import { TextFile } from "domain/labeling/file";
import { CORAnnotation, NERAnnotation } from "domain/text-labeling";
import { TextAnnotation } from "services/label-service/dtos/text-observation.dto";
import { RootState } from "store";
import { RequestStatus } from "store/base/base.state";
import { Sentence } from "../text-workspace/text-labeling/text-labeling.state";
import { WorkingStatus } from "./text-labeling-batch.state";

export const selectSelectedTextJobId = (state: RootState) => {
  return state.textLabelingBatch.selectedJobId;
};

export const selectTextJobs = (state: RootState) => {
  const { allIds, entities } = state.textLabelingBatch.jobs;
  return allIds.map((id: UniqueId) => entities[id]);
};

export const selectTextJob = (state: RootState) => {
  const { jobs, selectedJobId } = state.textLabelingBatch;
  if (jobs.allIds.includes(selectedJobId)) {
    return jobs.entities[selectedJobId];
  }
  return null;
};

export const selectedTextPollJobStatus = (state: RootState) => {
  return state.textLabelingBatch.pollJobStatus;
};

export const selectTextJobsWorkingStatus = (state: RootState) => {
  return state.textLabelingBatch.jobWorkingStatus;
};

export const selectedTextJobStatus = (state: RootState) => {
  const { selectedJobId, jobLoadingStatus } = state.textLabelingBatch;
  if (jobLoadingStatus.hasOwnProperty(selectedJobId)) {
    return jobLoadingStatus[selectedJobId];
  }
  return RequestStatus.IDLE;
};

export const selectedTextJobIdsByWorkingStatus =
  (status: WorkingStatus) => (state: RootState) => {
    const { jobs, jobWorkingStatus } = state.textLabelingBatch;
    return jobs.allIds.filter(
      (jobId) => jobWorkingStatus[jobId as number] === status
    );
  };

export const selectTextLabelingJobById =
  (jobId: number) => (state: RootState) => {
    const { jobs } = state.textLabelingBatch;
    if (jobs.allIds.includes(jobId)) {
      return jobs.entities[jobId];
    }
    return null;
  };

export const selectTextAnnotationsForSaving =
  (jobId: number) => (state: RootState) => {
    const { allIds, entities } = state.textLabelingBatch.jobs;
    const isValidJob = jobId && allIds.includes(jobId);
    if (!isValidJob) return null;
    const { selectedSystemObservationId, observations, relations } =
      state.textWorkspace.textEditor;
    const { labels } = state.textWorkspace.textLabeling;
    const systemLabel = labels.find(
      (lb) =>
        lb.observation.id === selectedSystemObservationId && lb.isSystemLabel
    );
    const labelingJob = entities[jobId];
    const sentences = (labelingJob.file as TextFile).sentences;
    const data = parseTextAnnotations(observations, relations, sentences);

    if (!!systemLabel) {
      const labelId = systemLabel.observation.id;
      const systemAnnotation = { observationId: labelId };
      const annotations = [systemAnnotation];
      return {
        annotations,
        annotationRelations: [],
      };
    }

    const annotations = [];
    const annotationRelations = [];
    for (const label of data.labels) {
      annotations.push({
        observationId: label.observationId,
        localId: label.id,
        annotation: {
          startIndex: label.startIndex,
          endIndex: label.endIndex,
        },
      });
    }

    for (const relation of data.relations) {
      annotationRelations.push({
        observationId: relation.observationId,
        directed: true,
        firstLocalId: relation.fromId,
        secondLocalId: relation.toId,
      });
    }

    return {
      annotations,
      annotationRelations,
    };
  };

export const selectTextProject = (state: RootState) => {
  return state.textLabelingBatch.project;
};

export const selectTextAutoSaveInSecond = (state: RootState) => {
  return state.textLabelingBatch.project?.autoSaveInSecond;
};

function parseTextAnnotations(
  annotations: NERAnnotation[],
  relationAnnotations: CORAnnotation[],
  sentences: Sentence[]
): TextAnnotation {
  const textObservationLabels = [];
  let idx = 0;
  const observationMap: { [key: string]: number } = {};
  for (let observation of annotations) {
    const textObservationLabel = {
      endIndex: observation.endIndex,
      observationId: parseInt(observation.observationId),
      sentenceIndex: 0,
      id: idx,
      startIndex: observation.startIndex,
    };
    textObservationLabels.push(textObservationLabel);
    observationMap[
      `${textObservationLabel.startIndex}:${textObservationLabel.endIndex}`
    ] = idx;
    idx++;
  }
  const textObservationRelations = [];
  for (let relation of relationAnnotations) {
    const fromTokenIds = relation.from.split(":");
    const toTokenIds = relation.to.split(":");
    if (
      (relation.text && !relation.observationId) ||
      fromTokenIds.length !== 3 ||
      toTokenIds.length !== 3
    )
      continue;
    let sentenceIndex = 0;
    const idx = sentences.findIndex(
      (sentence) => sentence.startIndex > parseInt(fromTokenIds[2])
    );
    if (idx > 0) sentenceIndex = idx - 1;

    const textObservationRelation = {
      fromId: observationMap[`${fromTokenIds[1]}:${fromTokenIds[2]}`],
      observationId: parseInt(relation.observationId),
      sentenceIndex: sentences[sentenceIndex].sentenceIndex,
      toId: observationMap[`${toTokenIds[1]}:${toTokenIds[2]}`],
    };
    textObservationRelations.push(textObservationRelation);
  }
  const payload: TextAnnotation = {
    labels: textObservationLabels,
    relations: textObservationRelations,
  };
  return payload;
}
