/*
 * File: issue-list.component.tsx
 * Project: design-system
 * File Created: Friday, 1st July 2022 11:34:38 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */
import { Issue } from "domain/common/issue";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { IssueListItem } from "../issue-list-item/issue-list-item.component";

interface Props {
  data: Issue[];
  mode: TextLabelingMode;
  selectedIssueId?: number;
  onSelectItem?(issue: Issue): void;
  onClickResolve?(issue: Issue): void;
}
export const IssueList = (props: Props) => {
  const { data, mode, selectedIssueId, onSelectItem, onClickResolve } = props;
  return (
    <div className="divide-y">
      {data.map((item) => {
        return (
          <IssueListItem
            key={item.id}
            mode={mode}
            data={item}
            selected={item.id === selectedIssueId}
            onSelect={onSelectItem}
            onClickResolve={onClickResolve}
          />
        );
      })}
    </div>
  );
};
