/*
 * File: use-previous-job-annotators.hook.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 9th August 2022 9:29:44 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  selectTextConflictAnnotators,
  selectTextConflictLabelingData,
  selectTextConflictSelectedAnnotators,
} from "store/labeler/text-workspace/text-conflict/text-conflict.selectors";
import { textConflictAnnotatorSelected } from "store/labeler/text-workspace/text-workspace.slice";

export default function useTextConflict() {
  const dispatch = useAppDispatch();
  const annotators = useAppSelector(selectTextConflictAnnotators);
  const selectedAnnotators = useAppSelector(
    selectTextConflictSelectedAnnotators
  );

  const labelingData = useAppSelector(selectTextConflictLabelingData);

  function handleSelectAnnotator(annotator: string) {
    dispatch(textConflictAnnotatorSelected(annotator));
  }

  return {
    annotators,
    selectedAnnotators,
    handleSelectAnnotator,
    labelingData,
  };
}
