/*
 * File: label-distribution.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 10:05:19 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { BatchService } from "services/label-service";
import { RootState } from "store";
import {
  addEntity,
  requestFulfilled,
  requestPending,
  requestRejected,
} from "store/base/base.reducer";
import { LabelDistributionState, StepDistributionDTO } from "store/customer/project-data/label-distribution/label-distribution.state";
import { getStepDistributionData } from "utilities/label-distribution/get-step-distribution-data";

export enum BatchDistributionThunk {
  LOAD_LABEL_DISTRIBUTION = "batchData/loadLabelDistributionAsync",
}

export const loadLabelDistributionAsync = createAsyncThunk(
  BatchDistributionThunk.LOAD_LABEL_DISTRIBUTION,
  async (batchId: number, { getState }) => {
    const state = getState() as RootState;
    if (state.batchData.labelDistribution.ids.includes(batchId)) {
      return state.batchData.labelDistribution.entities[batchId];
    }
    const response = await BatchService.getDistribution(batchId);
    const items: StepDistributionDTO[] = response.data;

    return { id: batchId, steps: getStepDistributionData(items) };
  }
);

export const labelDistributionReducerBuilder = <
  S extends { labelDistribution: LabelDistributionState }
>(
  builder: ActionReducerMapBuilder<S>
): ActionReducerMapBuilder<S> => {
  return builder
    .addCase(loadLabelDistributionAsync.pending, (state) => {
      requestPending(state.labelDistribution);
    })
    .addCase(loadLabelDistributionAsync.rejected, (state, action) => {
      requestRejected(state.labelDistribution, action.error);
    })
    .addCase(loadLabelDistributionAsync.fulfilled, (state, action) => {
      requestFulfilled(state.labelDistribution);
      if (action.payload) {
        addEntity(state.labelDistribution, action.payload);
      }
    });
};
