/*
 * File: use-file-filter.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 4:53:07 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Filter } from "domain/common";
import { useState } from "react";

export function useFileFilter() {
  const [filter, setFilter] = useState<Filter>({
    page: 0,
    size: 20,
  });

  return { filter, setFilter };
}
