/*
 * File: delete-file.dialog.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { useAppDispatch } from "hooks/use-redux";
import { useState } from "react";
import { deleteStorageFileAsync } from "store/customer/dataset/dataset.slice";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { useDatasetDetailContext } from "../../dataset-detail.context";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { DELETE_FILE_OPTIONS } from "services/storage/apis/delete-file.api";

export const DeleteFileDialog = () => {
  const { t } = useTranslation();
  const { requestData } = useDatasetContext();
  const { deletingItemIds, setDeletingItemIds } = useDatasetDetailContext();
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);

  function handleClose() {
    setDeletingItemIds([]);
  }

  async function handleDeleteAllFile() {
    if (processing) return;
    setProcessing(true);
    if (deletingItemIds.length === 0) return;
    let succeedCount = 0;
    let failedCount = 0;
    for (const id of deletingItemIds) {
      try {
        const res = await dispatch(
          deleteStorageFileAsync({
            itemId: id,
            option: DELETE_FILE_OPTIONS.DELETE_ALL.toString(),
          })
        );
        if (res.meta.requestStatus === "rejected") throw Error();
        succeedCount++;
      } catch {
        failedCount++;
      }
    }

    if (succeedCount > 0)
      dispatch(
        enqueueSuccessNotification(
          t("dataset:deletefiles.textDeletedSuccess", { count: succeedCount })
        )
      );
    if (failedCount > 0)
      dispatch(
        enqueueErrorNotification(
          t("dataset:deletefiles.textDeletedFailed", { count: failedCount })
        )
      );
    requestData();
    handleClose();
    setProcessing(false);
  }

  return (
    <VBModal
      open={deletingItemIds.length > 0}
      title={t("dataset:deletefiles.title")}
      onClose={handleClose}
      textSubmit={t("common:buttonOk")}
      onSubmit={handleDeleteAllFile}
      disableSubmit={processing}
    >
      <div className="flex flex-col gap-3">
        <p>
          {deletingItemIds.length === 1 &&
            t("dataset:deletefiles.singleFileMessage")}
          {deletingItemIds.length > 1 &&
            t("dataset:deletefiles.multiFilesMessage")}
        </p>
      </div>
    </VBModal>
  );
};
