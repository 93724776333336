/*
 * File: image-annotations-added.thunk copy.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 20th October 2022 9:17:09 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { Annotation } from "domain/image-labeling";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";
import { RootState } from "store";
import { selectImageLabelingJobById } from "../../batch-labeling/batch-labeling.selectors";
import { ImageWorkspaceState } from "../../image-workspace.state";

const THUNK_NAME = "imageWorkspace/imageAnnotationsAddedAsync";

interface ImageAnotationPayload<T> {
  jobId: number;
  data: T;
}

export const imageAnnotationsAddedAsync = createAsyncThunk(
  THUNK_NAME,
  async (
    payload: ImageAnotationPayload<{
      annotations: Annotation[];
      relations: RelationAnnotation[];
    }>,
    { getState }
  ) => {
    const state = getState() as RootState;
    const labelingJob = selectImageLabelingJobById(payload.jobId)(state);
    return {
      labelingJob,
      ...payload.data,
    };
  }
);

export const imageAnnotationsAddedBuidler = (
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) => {
  return builder.addCase(
    imageAnnotationsAddedAsync.fulfilled,
    ({ imageAnnotations }, action) => {
      if (!action?.payload) return;
      const { annotations, relations, labelingJob } = action.payload;
      if (!labelingJob) return;
      for (const annotation of annotations) {
        imageAnnotations.annotations.push({
          ...annotation,
          jobId: labelingJob.job.id,
          previousJobId: annotation.jobId,
          annotator: labelingJob.job.assignee,
        });
      }

      for (const annotation of relations) {
        imageAnnotations.relationAnnotations.push({
          ...annotation,
          jobId: labelingJob.job.id,
          annotator: labelingJob.job.assignee,
        });
      }
    }
  );
};
