import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AnnotationAttribute } from "domain/image-labeling";
import { RootState } from "store";
import { ImageWorkspaceState } from "../../image-workspace.state";
import { imageAnnotationUtils } from "../image-annotations.util";
import { selectImageLabelingJobs } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
const THUNK_NAME = "image-annotations/imageClassificationAttributeUpdatedAsync";

interface ImageClassificationAttributeUpdatedPayload {
  attributes: AnnotationAttribute[];
  labelId: number;
  jobId: number;
}

export const imageClassificationAttributeUpdatedAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: ImageClassificationAttributeUpdatedPayload, { getState }) => {
    const state = getState() as RootState;
    const labelingJobs = selectImageLabelingJobs(state);
    return { ...payload, labelingJobs };
  }
);

export const imageClassificationAttributeUpdatedBuilder = (
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) => {
  return builder.addCase(
    imageClassificationAttributeUpdatedAsync.fulfilled,
    ({ imageAnnotations, imageLabeling }, action) => {
      const { jobId, labelId, attributes } = action.payload;
      // remove all existing label for current job.
      imageAnnotations.annotations = imageAnnotations.annotations.filter(
        (anno) => !(anno.labelId === labelId && anno.jobId === jobId)
      );
      if (attributes.length === 0) return;
      const annotator = imageLabeling.job?.assignee || "";
      const annotation = imageAnnotationUtils.createNewClassificationAnnotation(
        jobId,
        labelId,
        annotator,
        attributes
      );
      imageAnnotations.annotations.push(annotation);
    }
  );
};
// export const imageClassificationAttributeUpdatedBuilder = (
//   builder: ActionReducerMapBuilder<ImageWorkspaceState>
// ) => {
//   return builder.addCase(
//     imageClassificationAttributeUpdatedAsync.fulfilled,
//     ({ imageAnnotations, imageLabeling }, action) => {
//       const { jobId, labelId, attributes, labelingJobs } =
//         action.payload;
//       const jobIds = labelingJobs.allIds as number[];
//       // remove all existing label for current job.
//       imageAnnotations.annotations = imageAnnotations.annotations.filter(
//         (anno) => !(jobIds.includes(anno.jobId) && labelId === anno.labelId)
//       );
//       if (attributes.length === 0) return;
//       const labelingJob = collectionUtils.getOne(labelingJobs, jobId);
//       const laterality = getLaterality(labelingJob?.dicomData);
//       const annotator = imageLabeling.job?.assignee || "";

//       for (const id of jobIds) {
//         const lbJob = collectionUtils.getOne(labelingJobs, id);
//         const lbLaterality = getLaterality(lbJob?.dicomData);
//         if (lbLaterality !== laterality) continue;
//         const annotation =
//           imageAnnotationUtils.createNewClassificationAnnotation(
//             id,
//             labelId,
//             annotator,
//             attributes
//           );
//         imageAnnotations.annotations.push(annotation);
//       }
//     }
//   );
// };
