/*
 * File: dataset-models.component.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 6:07:01 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { HTMLProps } from "react";
import { ScopeDTO } from "services/storage-service/dto/scope.dto";

interface Props extends HTMLProps<HTMLDivElement> {
  scopes: ScopeDTO[];
  scopeFiles: Record<string, number>;
  children?: JSX.Element;
}

export const DatasetModels = ({
  scopes,
  scopeFiles,
  children,
  className = "flex flex-wrap items-center my-4",
  ...rest
}: Props) => {
  return (
    <div className={className} {...rest}>
      {scopes.map((scope, index) => {
        const count = scopeFiles.hasOwnProperty(scope.id)
          ? scopeFiles[scope.id]
          : 0;
        return (
          <div key={scope.id}>
            {index > 0 ? "|" : ""} <span>{scope.name} :</span>
            <span className="px-2 font-bold">{count}</span>
          </div>
        );
      })}
      {children}
    </div>
  );
};
