import { SearchParams } from "models/common/search-params";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RequestOptions } from "services/common/request-options";
import { selectProjectBatches } from "store/customer/project/project.selectors";
import { loadBatchesAsync } from "store/customer/project/project.slice";
import { useAppDispatch, useAppSelector } from "../use-redux";
import * as Sentry from "@sentry/react";


export const useBatches = (projectId: number) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const batches = useAppSelector(selectProjectBatches);

  useEffect(() => {
    function getQueryParam(
      urlSearchParams: URLSearchParams,
      key: string,
      defaultValue: string
    ): string {
      const value = urlSearchParams.get(key);
      if (value) return value;
      return defaultValue;
    }

    try {
      const urlParams = new URLSearchParams(location.search);
      const search = getQueryParam(urlParams, SearchParams.SEARCH, "");
      const type = getQueryParam(urlParams, SearchParams.TYPE, "");
      const page = parseInt(getQueryParam(urlParams, SearchParams.PAGE, "1"));
      const pageSize = getQueryParam(urlParams, SearchParams.SIZE, "200");
      const sort = getQueryParam(
        urlParams,
        SearchParams.SORT,
        "createdDate,desc",
      );

      const options: RequestOptions = {
        "projectId.equals": projectId.toString(),
      };
      if (page) options[SearchParams.PAGE] = (page - 1).toString();
      if (pageSize) options[SearchParams.SIZE] = pageSize.toString();
      if (search) options["name.contains"] = search;
      if (type) options[`${SearchParams.TYPE}`] = type;
      if (sort) options[SearchParams.SORT] = sort;

      dispatch(loadBatchesAsync(options));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [dispatch, location.search, projectId]);

  return { batches };
};
