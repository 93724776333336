export function printOpenCv() {
  console.log(cv);
}

export function computeMaskSize(labelMap, mask) {
  const data = labelMap.getPointData().getScalars().getData();
  const extend = labelMap.getExtent();
  const spacing = labelMap.getSpacing();

  const ni = extend[1];
  const nj = extend[3];
  const nk = extend[5];
  const increments = labelMap.computeIncrements(extend);

  let maxIk = -1;
  let maxSumK = 0;

  // find k index which has the maximum mask sum
  const ijk = [0, 0, 0];
  for (let ik = 0; ik <= nk; ik++) {
    let sum = 0;
    for (let ii = 0; ii <= ni; ii++) {
      for (let ij = 0; ij <= nj; ij++) {
        ijk[0] = ii;
        ijk[1] = ij;
        ijk[2] = ik;
        // const flatindex = labelMap.computeOffsetIndex(ijk);
        const flatIndex =
          ii * increments[0] + ij * increments[1] + ik * increments[2];
        if (data[flatIndex] !== mask) continue;
        sum += data[flatIndex];
        if (sum > maxSumK) {
          maxSumK = sum;
          maxIk = ik;
        }
      }
    }
  }

  if (maxIk === -1) return 0;

  // get the maximum slice mask
  const mat = cv.Mat.zeros(ni, nj, cv.CV_8U);
  for (let ii = 0; ii <= ni; ii++) {
    for (let ij = 0; ij <= nj; ij++) {
      const flatIndex =
        ii * increments[0] + ij * increments[1] + maxIk * increments[2];
      mat.ucharPtr(ii, ij)[0] = data[flatIndex] === mask ? 1 : 0;
    }
  }

  // find contours
  let contours = new cv.MatVector();
  let hierarchy = new cv.Mat();
  // you can try more different parameters
  cv.findContours(
    mat,
    contours,
    hierarchy,
    cv.RETR_EXTERNAL,
    cv.CHAIN_APPROX_SIMPLE
  );

  const release = () => {
    contours.delete();
    hierarchy.delete();
    mat.delete();
  };

  // get the circle radius of the first contour
  if (contours.size() > 0) {
    const { radius } = cv.minEnclosingCircle(contours.get(0));
    release();
    return 2 * radius * spacing[0];
  }

  release();
  return 0;
}
