/*
 * File: project-template.service.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 29th July 2022 10:42:42 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ProjectTemplate } from "domain/labeling/project-template";
import { LabelAPI } from "services/label-service/http/label-service.httpclient";
import { ProjectTemplateDTO } from "./project-template.dto";
import { projectTemplateMapper } from "./project-template.mapper";

async function getProjectTemplates(): Promise<ProjectTemplate[]> {
  const res = await LabelAPI.get("/api/v1/project-templates");
  return res.data.map(projectTemplateMapper.toEntity);
}

async function getProjectTemplateById(
  templateId: number
): Promise<ProjectTemplate> {
  const res = await LabelAPI.get("/api/v1/project-templates/" + templateId);
  return projectTemplateMapper.toEntity(res.data);
}

async function createProjectUsingTemplate(data: ProjectTemplateDTO) {
  return LabelAPI.post("/api/v2/projects", data);
}

export const projectTemplateService = {
  getProjectTemplates,
  getProjectTemplateById,
  createProjectUsingTemplate,
};
