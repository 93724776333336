import TableContainer from "@material-ui/core/TableContainer";
import classnames from "classnames";

import { TableWrapperProps } from "./types";

export const TableWrapper = ({
  children,
  component = "div",
  classNames,
}: TableWrapperProps) => {
  return (
    <div className={classnames("overflow-hidden relative", classNames?.root)}>
      <TableContainer
        className={classnames(classNames?.container || "")}
        component={component}
      >
        {children}
      </TableContainer>
    </div>
  );
};
