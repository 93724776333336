/*
 * File: text-review.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 26th July 2022 2:28:51 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import ConfigService from "configs/app-config";
import { Collection, collectionUtils } from "domain/common";
import { TextFile } from "domain/labeling/file";
import {
  Token,
  NERAnnotationData,
  CORAnnotationData,
} from "domain/text-labeling";
import { SystemAnnotationData } from "domain/text-labeling/system-annotation";
import {
  buildAnnotationTokenIds,
  buildTokens,
} from "pages/labeler/text-labeling/components/text-viewer/text-viewer.utils";
import { RootState } from "store";
import { selectAppConfig } from "store/common/app-setting/app-setting.selectors";
import { TextWorkspaceState } from "../text-workspace.state";

enum TextReviewThunk {
  SET_TASK_REVIEW_ASYNC = "textReview/startTextTaskReviewAsync",
}

interface SetTaskReviewPayload {
  taskId: number;
  update?: boolean;
}

export const startTextTaskReviewAsync = createAsyncThunk(
  TextReviewThunk.SET_TASK_REVIEW_ASYNC,
  async ({ update = false }: SetTaskReviewPayload, { getState }) => {
    const state = getState() as RootState;
    const { labelingDatas, file } = state.textLabelingTask;
    const sentences = (file as TextFile).sentences;
    const appConfig = selectAppConfig(state);
    const projectType = state.textLabelingTask.batch?.dto?.project?.type;
    const tokenizer = ConfigService.getTextTokenizer(appConfig, projectType);
    const { tokenEntities, tokenIds } = buildTokens(sentences, tokenizer);
    const tokenCollection: Collection<Token> = {
      entities: tokenEntities,
      allIds: tokenIds,
    };
    const tokens = collectionUtils.values(tokenCollection);
    const annotations: NERAnnotationData[] = [];
    const relationAnnotations: CORAnnotationData[] = [];
    const systemAnnotations: SystemAnnotationData[] = [];

    let annotators: string[] = [];
    const step1Jobs = state.textLabelingTask.jobs
      .filter((job) => !!job.assignee)
      .filter((job) => job.step === 1);
    for (const job of step1Jobs) {
      if (!annotators.includes(job.assignee)) {
        annotators.push(job.assignee);
      }
    }

    if (update) {
      annotators = state.textWorkspace.textReview.selectedAnnotators;
    }

    for (const data of labelingDatas) {
      if (!data.annotator) continue;
      if (!annotators.includes(data.annotator)) continue;
      for (const annotation of data.annotations) {
        const existedAnnotation = annotations.find(
          (anno) => anno.id === annotation.id
        );
        if (existedAnnotation) {
          existedAnnotation.annotators?.push(data.annotator);
          existedAnnotation.selected = true;
        } else {
          const { startIndex, endIndex } = annotation;
          const content = getTextContent(tokens, startIndex, endIndex + 1);
          annotations.push({
            ...annotation,
            annotators: [data.annotator],
            content: content,
            tokenIds: buildAnnotationTokenIds(annotation, tokenCollection),
          });
        }
      }

      for (const relation of data.relationAnnotations) {
        const existedAnnotation = relationAnnotations.find(
          (anno) =>
            anno.from === relation.from &&
            anno.to === relation.to &&
            anno.observationId === relation.observationId
        );
        if (existedAnnotation) {
          existedAnnotation.annotators?.push(data.annotator);
          existedAnnotation.selected = true;
        } else {
          relationAnnotations.push({
            ...relation,
            annotators: [data.annotator],
          });
        }
      }

      const observationId = data.systemObservationId?.toString();
      if (observationId) {
        const existedAnnotation = systemAnnotations.find(
          (anno) => anno.id === observationId
        );

        if (existedAnnotation) {
          existedAnnotation.annotators?.push(data.annotator);
          existedAnnotation.selected = true;
        } else {
          systemAnnotations.push({
            id: observationId,
            observationId,
            active: false,
            annotators: [data.annotator],
          });
        }
      }
    }

    for (const relation of relationAnnotations) {
      const from = annotations.find((anno) => anno.id === relation.from);
      const to = annotations.find((anno) => anno.id === relation.to);
      if (from && to) {
        relation.content =
          (from.content || from.id) + " -> " + (to.content || to.id);
      }
    }

    return {
      annotations: annotations,
      relationAnnotations: relationAnnotations,
      systemAnnotations: systemAnnotations,
      labelingDatas: labelingDatas.filter((data) => !!data.annotator),
      annotators,
    };
  }
);

function getTextContent(
  tokenEntities: Token[],
  startIndex: number,
  endIndex: number
) {
  let str = "";
  for (const token of tokenEntities) {
    if (token.startIndex >= startIndex && token.endIndex <= endIndex) {
      str += (str.length > 0 ? " " : "") + token.text;
    }
  }
  return str;
}

export const taskReviewReducerBuilder = (
  builder: ActionReducerMapBuilder<TextWorkspaceState>
): ActionReducerMapBuilder<TextWorkspaceState> => {
  return builder.addCase(
    startTextTaskReviewAsync.fulfilled,
    (state, action) => {
      const {
        annotations,
        relationAnnotations,
        systemAnnotations,
        labelingDatas,
        annotators,
      } = action.payload;
      state.textReview.reviewMode = true;
      state.textReview.annotations = annotations;
      state.textReview.relationAnnotations = relationAnnotations;
      state.textReview.systemAnnotations = systemAnnotations;
      state.textReview.labelingDatas = labelingDatas;
      state.textReview.selectedAnnotators = annotators;

      state.textReview.selectedAnnotationIds = annotations
        .map((anno) => anno.id)
        .concat(relationAnnotations.map((anno) => anno.id))
        .concat(systemAnnotations.map((anno) => anno.id));
    }
  );
};
