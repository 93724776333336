import { createAsyncThunk } from "@reduxjs/toolkit";
import { JobService } from "services/label-service";
import { JobDTO } from "services/label-service/dtos";

const THUNK_NAME = "imageWorkspace/skipTaskObservationsAsync";
export const skipTaskObservationsAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: Partial<JobDTO>) => {
    const response = await JobService.skipJob(payload);
    return response.data;
  }
);
