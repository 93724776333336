/*
 * File: notification.reducer.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 28th June 2021 2:49:55 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  NotificationAction,
  NotificationActionTypes,
} from "./notification.actions";
import {
  INITIAL_NOTIFICATION_STATE,
  NotificationState,
} from "./notification.state";

export function notificationReducer(
  state: NotificationState = INITIAL_NOTIFICATION_STATE,
  action: NotificationAction
): NotificationState {
  switch (action.type) {
    case NotificationActionTypes.ENQUEUE_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };

    case NotificationActionTypes.DEQUEUE_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications.filter(
            (noti) => noti.key !== action.payload.key
          ),
        ],
      };

    case NotificationActionTypes.DISMISS_ALL_NOTIFICATION:
      return {
        ...state,
        notifications: [],
      };
    default:
      return state;
  }
}
