/*
 * File: image-load-labeling-job.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 18th August 2022 5:18:03 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestStatus } from "store/base/base.state";
import {
  BatchLabelingState,
  ImageLabelingJobMetadata,
} from "../batch-labeling.state";
import { collectionUtils } from "domain/common";
import { batchLabelingUtils } from "../batch-labeling.util";
import { RootState } from "store";
import { StorageService } from "services/storage";
import { isFileDicom } from "utilities/dicom/dicom.utils";
import { LabelingJobData } from "domain/labeling/labeling-job";
import { fileMapper } from "services/label-service/mappers/file.mapper";
import { LabelingMode } from "domain/labeling/labeling-mode";
import { labelingUtils } from "utilities/annotations/labeling-utils";

const THUNK_NAME = "batch-labeling/imageLoadJobAsync";

export const imageLoadJobAsync = createAsyncThunk(
  THUNK_NAME,
  async (
    { jobId, mode }: { jobId: number; mode?: LabelingMode },
    { getState }
  ) => {
    const state = getState() as RootState;
    const { imageLabelingJobs } = state.batchLabeling;
    const labelingJob = collectionUtils.getOne(imageLabelingJobs, jobId);
    if (!labelingJob) throw new Error("Job is not exist " + jobId);
    const data = labelingJob.data as LabelingJobData;

    const job = labelingJob.job;
    let task = labelingJob.task;
    let file = labelingJob.file;
    let batch = labelingJob.batch;
    let project = labelingJob.project;
    let dicomData;
    let labelingData;
    let metadata: ImageLabelingJobMetadata | undefined = undefined;

    if (!task) task = await batchLabelingUtils.getTaskById(job.taskId);
    if (!batch) {
      batch = (await batchLabelingUtils.getBatchById(job.batchId)).dto;
      if (!project && batch?.project) project = batch.project;
    }
    if (!file) {
      const fileId = task.fileId.toString();
      const { data } = await StorageService.getFileInfoDetails(fileId);
      file = await StorageService.cacheFileToLocalUrlAndAddMineType(
        data,
        project?.workspaceId
      );
      if (isFileDicom(file)) {
        dicomData = await labelingUtils.parseDicomData(file);
      }
      metadata = await labelingUtils.parseUltrasoundData(file);
    }

    if (mode !== LabelingMode.CUSTOMER && !data && file) {
      const labelingFile = fileMapper.fromDTO(file);
      labelingData = await batchLabelingUtils.getLabelingJobData(
        labelingJob.job,
        labelingFile,
        labelingJob.labels
      );
    }
    return { task, file, batch, project, dicomData, labelingData, metadata };
  }
);

export const imageLoadLabelingJobReducerBuilder = (
  builder: ActionReducerMapBuilder<BatchLabelingState>
) => {
  return builder
    .addCase(imageLoadJobAsync.fulfilled, (state, action) => {
      const jobId = action.meta.arg.jobId;
      if (!action.payload) return;
      const labelingJob = collectionUtils.getOne(
        state.imageLabelingJobs,
        jobId
      );

      if (labelingJob) {
        const {
          task,
          file,
          batch,
          project,
          dicomData,
          labelingData,
          metadata,
        } = action.payload;
        labelingJob.data = labelingData;
        labelingJob.task = task;
        labelingJob.file = file;
        labelingJob.batch = batch;
        labelingJob.project = project;
        labelingJob.dicomData = dicomData;
        labelingJob.metadata = metadata;
      }

      if (state.jobRequestStatuses.hasOwnProperty(jobId)) {
        state.jobRequestStatuses[jobId] = RequestStatus.SUCCESS;
      }
    })
    .addCase(imageLoadJobAsync.pending, (state, action) => {
      const jobId = action.meta.arg.jobId;
      if (state.jobRequestStatuses.hasOwnProperty(jobId)) {
        state.jobRequestStatuses[jobId] = RequestStatus.LOADING;
      }
    })
    .addCase(imageLoadJobAsync.rejected, (state, action) => {
      const jobId = action.meta.arg.jobId;
      if (state.jobRequestStatuses.hasOwnProperty(jobId)) {
        state.jobRequestStatuses[jobId] = RequestStatus.FAILURE;
      }
    });
};
