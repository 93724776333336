/*
 * File: user-workspace.slice.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 29th July 2021 3:34:21 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_USER_WORKSPACE_STATE } from "./user-workspace.state";
import { userWorkspaceReducerBuilder } from "./user-workspace.thunk";

const SLICE_NAME = "user_workspace";

export const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_USER_WORKSPACE_STATE,
  reducers: {},
  extraReducers: (builder) => {
    userWorkspaceReducerBuilder(builder);
  },
});

export const userWorkspaceReducer = slice.reducer;
