/*
 * File: notification.actions.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 28th June 2021 2:49:55 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { SnackbarOrigin } from "notistack";
import { v4 } from "uuid";
import { Notification } from "./notification.state";

export enum NotificationActionTypes {
  ENQUEUE_NOTIFICATION = "ENQUEUE_NOTIFICATION",
  DEQUEUE_NOTIFICATION = "DEQUEUE_NOTIFICATION",
  DISMISS_ALL_NOTIFICATION = "DISMISS_ALL_NOTIFICATION",
}

export type EnqueueNotificationAction = {
  type: NotificationActionTypes.ENQUEUE_NOTIFICATION;
  payload: Notification;
};

export const enqueueNotification = (
  notification: Notification
): EnqueueNotificationAction => ({
  type: NotificationActionTypes.ENQUEUE_NOTIFICATION,
  payload: notification,
});

export const enqueueWarningNotification = (
  message: string,
  duration: number = 2000,
  anchorOrigin: SnackbarOrigin = {
    vertical: "top",
    horizontal: "right",
  }
): EnqueueNotificationAction => ({
  type: NotificationActionTypes.ENQUEUE_NOTIFICATION,
  payload: {
    key: v4(),
    message: message,
    options: {
      anchorOrigin: anchorOrigin,
      autoHideDuration: duration,
      variant: "warning",
    },
  },
});

export const enqueueErrorNotification = (
  message: string,
  duration: number = 2000,
  anchorOrigin: SnackbarOrigin = {
    vertical: "top",
    horizontal: "right",
  }
): EnqueueNotificationAction => ({
  type: NotificationActionTypes.ENQUEUE_NOTIFICATION,
  payload: {
    key: v4(),
    message: message,
    options: {
      anchorOrigin: anchorOrigin,
      autoHideDuration: duration,
      variant: "error",
    },
  },
});

export const enqueueSuccessNotification = (
  message: string,
  duration: number = 2000
): EnqueueNotificationAction => ({
  type: NotificationActionTypes.ENQUEUE_NOTIFICATION,
  payload: {
    key: v4(),
    message: message,
    options: {
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      autoHideDuration: duration,
      variant: "success",
    },
  },
});

export type DequeueNotificationAction = {
  type: NotificationActionTypes.DEQUEUE_NOTIFICATION;
  payload: {
    key: string;
  };
};

export const dequeueNotification = (
  key: string
): DequeueNotificationAction => ({
  type: NotificationActionTypes.DEQUEUE_NOTIFICATION,
  payload: {
    key: key,
  },
});

export type DismissAllNotification = {
  type: NotificationActionTypes.DISMISS_ALL_NOTIFICATION;
};

export const dismissAllNotifications = (): DismissAllNotification => ({
  type: NotificationActionTypes.DISMISS_ALL_NOTIFICATION,
});

export type NotificationAction =
  | EnqueueNotificationAction
  | DequeueNotificationAction
  | DismissAllNotification;
