/*
 * File: pipeline.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 6th September 2021 2:22:07 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { PipelineSevice } from "services/label-service";
import { pipelineMapper } from "services/label-service/mappers/pipeline.mapper";
import { RequestStatus } from "store/base/base.state";
import { PipelineState } from "./pipeline.state";

export enum PipelineThunk {
  LOAD_PIPELINES_ASYNC = "pipeline/loadPipelinesAsync",
  DELETE_PIPELINE = "pipeline/deletePipelineAsync",
}

export const loadPipelinesAsync = createAsyncThunk(
  PipelineThunk.LOAD_PIPELINES_ASYNC,
  async () => {
    const response = await PipelineSevice.getPipelines();
    if (response) return response.map(pipelineMapper.toEntity);
    return [];
  }
);

export const deletePipelineAsync = createAsyncThunk(
  PipelineThunk.DELETE_PIPELINE,
  async (pipelineId: string) => {
    await PipelineSevice.deleteItem(pipelineId);
    return pipelineId;
  }
);

export const pipelineReducerBuilder = (
  builder: ActionReducerMapBuilder<PipelineState>
): ActionReducerMapBuilder<PipelineState> => {
  return builder
    .addCase(loadPipelinesAsync.pending, (state) => {
      state.error = null;
      state.status = RequestStatus.LOADING;
    })
    .addCase(loadPipelinesAsync.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.status = RequestStatus.FAILURE;
    })
    .addCase(loadPipelinesAsync.fulfilled, (state, action) => {
      state.error = null;
      state.status = RequestStatus.SUCCESS;
      state.entities = {};
      state.ids = [];
      for (let entity of action.payload) {
        state.entities[entity.id] = entity;
        state.ids.push(entity.id);
      }
    })

    .addCase(deletePipelineAsync.pending, (state) => {
      state.error = null;
      state.status = RequestStatus.LOADING;
    })
    .addCase(deletePipelineAsync.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.status = RequestStatus.FAILURE;
    })
    .addCase(deletePipelineAsync.fulfilled, (state, action) => {
      state.error = null;
      state.status = RequestStatus.SUCCESS;
      delete state.entities[action.payload];
      state.ids = state.ids.filter((id) => id !== action.payload);
    });
};
