/*
 * File: batch-tasks.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 3:39:49 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { VBComponentRequesting } from "components/common/vb-component-requesting/vb-component-requesting.component";
import { useQuery } from "hooks/use-query";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { SearchParams } from "models/common/search-params";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TaskService } from "services/label-service";
import {
  BatchDTO,
  BatchStatus,
  isGeneralImageProject,
  TaskCustomerReviewStatus,
  TaskDTO,
  TaskStatus,
} from "services/label-service/dtos";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import {
  selectBatchCanReopenTasks,
  selectBatchHasSelectedTasks,
  selectBatchIsSelectedAllTasks,
  selectBatchTasks,
  selectDoesBatchHasStepReview,
  selectIsLoadingBatchTasks,
} from "store/customer/batch/batch.selectors";
import {
  allBatchTaskSelected,
  batchTaskSelected,
} from "store/customer/batch/batch.slice";
import {
  reopenTaskAndUpdateTaskAsync,
  reopenJobsAndUpdateTaskAsync,
  patchTask,
  loadTasksAsync,
} from "store/customer/batch/batch.thunk";
import { taskReviewReopened } from "store/customer/tasks-review/tasks-review.slice";
import useDeepCompareEffect from "use-deep-compare-effect";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { useBatchDetailContext } from "../../context/batch-detail.context";
import {
  BatchTaskContext,
  BatchTaskContextState,
  buildRequestOptions,
  TaskFilter,
  TaskRow,
  TaskViewMode,
} from "./batch-tasks.context";
import { batchTasksUtil } from "./utils/batch-tasks.util";
import * as Sentry from "@sentry/react";

const DEFAULT_PAGE = 1;
const DEFAULT_SIZE = 10;

const pageQueryDefault = [
  {
    query: SearchParams.PAGE,
    value: DEFAULT_PAGE.toString(),
  },
  {
    query: SearchParams.SIZE,
    value: DEFAULT_SIZE.toString(),
  },
];

interface BatchTasksProviderProps {
  children: React.ReactNode | React.ReactNode[] | null;
}

export const BatchTasksProvider = ({ children }: BatchTasksProviderProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { batch } = useBatchDetailContext();
  const { navigateByQuery, navigateByQueries } = useQuery();
  const filter = useMemo(
    () => batchTasksUtil.taskFilterMapper(location.search, batch.id),
    [location.search, batch]
  );
  const isReviewStep = useAppSelector(selectDoesBatchHasStepReview);
  const allowReopen = useAppSelector(selectBatchCanReopenTasks);
  const isLoadingTasks = useAppSelector(selectIsLoadingBatchTasks);
  const tasks = useAppSelector(selectBatchTasks);
  const selectedAll = useAppSelector(selectBatchIsSelectedAllTasks);
  const hasSelectedTasks = useAppSelector(selectBatchHasSelectedTasks);
  const [showSelect, setShowSelect] = useState(true);
  const [changeStatusRow, setChangeStatusRow] = useState<TaskRow>();
  const [requesting, setRequesting] = useState(false);

  const [openReopenDialog, setOpenReopenDialog] = useState(false);
  const canReopenTasks = useMemo(() => {
    const { status } = batch;
    if (status === BatchStatus.INREVIEW || status === BatchStatus.ON_HOLD) {
      return false;
    }
    return tasks.some((task) => {
      return task.selected && task.status === TaskStatus.COMPLETED;
    });
  }, [tasks, batch]);
  const [openReleaseDialog, setOpenReleaseDialog] = useState(false);

  const [taskFiltersVisible, setTaskFiltersVisible] = useState(false);
  const isShowHasFilter = useMemo(() => {
    if (!filter) return false;

    for (const key of Object.keys(filter)) {
      if (["page", "size", "sort", "batchId"].includes(key)) continue;
      const value = (filter as any)[key];
      if (!!value) return true;
    }

    return false;
  }, [filter]);
  // view mode
  const [viewMode, setViewMode] = useState<TaskViewMode>(() => {
    try {
      if (isGeneralImageProject(batch?.project?.type)) {
        return TaskViewMode.GRID;
      } else {
        return TaskViewMode.LIST;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
    return TaskViewMode.LIST;
  });

  const isBatchCompleted = useMemo(() => {
    if (!batch) return false;
    if (batch.status === BatchStatus.INITIAL) return false;
    if (batch.status === BatchStatus.WORKING) return false;
    if (batch.status === BatchStatus.ON_HOLD) return false;
    return true;
  }, [batch]);

  const canAssignJobsToTask = useCallback(
    (batch: BatchDTO | undefined | null, task: TaskDTO) => {
      return (
        batch?.status === BatchStatus.WORKING &&
        task.status !== TaskStatus.LOCKED &&
        task.status !== TaskStatus.REMOVED &&
        task.status !== TaskStatus.COMPLETED
      );
    },
    []
  );

  function setPage(page: number) {
    navigateByQuery(SearchParams.PAGE, page.toString());
  }

  function setPageSize(pageSize: number) {
    navigateByQuery(SearchParams.SIZE, pageSize.toString());
  }

  function setSort(field: string, sortType: string) {
    const sortValue = sortType ? `${field},${sortType}` : "";
    navigateByQuery(SearchParams.SORT, sortValue);
  }

  function setId(id: string) {
    navigateByQuery(SearchParams.ID, id);
  }

  function setFileName(fileName: string) {
    navigateByQueries([
      {
        query: SearchParams.FILE_NAME,
        value: fileName,
      },
      ...pageQueryDefault,
    ]);
  }

  function setStatus(status: string) {
    navigateByQueries([
      {
        query: SearchParams.STATUS,
        value: status,
      },
      ...pageQueryDefault,
    ]);
  }

  function setReviewStatus(reviewStatus: string) {
    navigateByQueries([
      {
        query: SearchParams.REVIEW_STATUS,
        value: reviewStatus,
      },
      ...pageQueryDefault,
    ]);
  }

  function setIssueStatus(issueStatus: string) {
    navigateByQueries([
      {
        query: SearchParams.ISSUE_STATUS,
        value: issueStatus,
      },
      ...pageQueryDefault,
    ]);
  }

  function setCustomerReviewStatus(customerReviewStatus: string) {
    navigateByQueries([
      {
        query: SearchParams.CUSTOMER_REVIEW_STATUS,
        value: customerReviewStatus,
      },
      ...pageQueryDefault,
    ]);
  }

  function setNumAccepted(num: string) {
    navigateByQueries([
      {
        query: SearchParams.NUM_ACCEPTED,
        value: num,
      },
      ...pageQueryDefault,
    ]);
  }

  function setAssignee(assignee: string) {
    navigateByQueries([
      {
        query: SearchParams.ASSIGNEE,
        value: assignee,
      },
      ...pageQueryDefault,
    ]);
  }

  function applyTaskFilters(taskFilter: TaskFilter) {
    const navQueries = [];

    if (!taskFilter.page) taskFilter.page = DEFAULT_PAGE;
    if (!taskFilter.size) taskFilter.size = DEFAULT_SIZE;

    for (const key of Object.keys(taskFilter)) {
      const value = (taskFilter as any)[key];
      navQueries.push({ query: key, value: value });
    }

    navigateByQueries(navQueries, true);
  }

  const setTaskRowSelect = (selectTaskRow: TaskRow, selected: boolean) => {
    dispatch(batchTaskSelected(selectTaskRow.id));
  };

  const setTaskRowSelectedAll = (selected: boolean) => {
    dispatch(allBatchTaskSelected(selected));
  };

  const reopenTasks = async (reason: string) => {
    for (const taskRow of tasks) {
      if (!taskRow.selected) continue;
      if (taskRow.status !== TaskStatus.COMPLETED) continue;
      if (
        taskRow.customerReviewStatus === TaskCustomerReviewStatus.APPROVED ||
        taskRow.customerReviewStatus === TaskCustomerReviewStatus.REJECTED
      ) {
        continue;
      }
      try {
        const payload = { taskId: taskRow.id, reason };
        await dispatch(reopenTaskAndUpdateTaskAsync(payload));
      } catch (error) {
        Sentry.captureException(error);
        Logger.log(error);
      }
    }
  };

  async function reopenJobs(taskId: number, jobIds: number[], reason: string) {
    if (jobIds.length === 0) return reopenTask(taskId, reason);
    if (requesting) return;
    try {
      setRequesting(true);
      const payload = { batchId: batch.id.toString(), taskId, jobIds, reason };
      const response = await dispatch(reopenJobsAndUpdateTaskAsync(payload));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification("Success!"));
      dispatch(taskReviewReopened(taskId));
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || "Failed to reopen jobs";
      dispatch(enqueueErrorNotification(errMessage));
    } finally {
      setRequesting(false);
    }
  }

  async function reopenTask(taskId: number, reason: string) {
    if (requesting) return;
    try {
      setRequesting(true);
      const payload = { batchId: batch.id.toString(), taskId, reason };
      const response = await dispatch(reopenTaskAndUpdateTaskAsync(payload));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification("Success!"));
      dispatch(taskReviewReopened(taskId));
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || "Failed to reopen jobs";
      dispatch(enqueueErrorNotification(errMessage));
    } finally {
      setRequesting(false);
    }
  }

  async function approveTask(taskId: number) {
    if (requesting) return;
    try {
      setRequesting(true);
      const status = TaskCustomerReviewStatus.APPROVED;
      const payload = { customerReviewStatus: status };
      const response = await dispatch(patchTask({ taskId, payload }));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || "Failed to approve this task";
      dispatch(enqueueErrorNotification(errMessage));
    } finally {
      setRequesting(false);
    }
  }

  async function rejectTask(taskId: number) {
    if (requesting) return;
    try {
      setRequesting(true);
      const status = TaskCustomerReviewStatus.REJECTED;
      const payload = { customerReviewStatus: status };
      const response = await dispatch(patchTask({ taskId, payload }));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || "Failed to reject this task";
      dispatch(enqueueErrorNotification(errMessage));
    } finally {
      setRequesting(false);
    }
  }

  async function releaseTasks(
    batchId: number | string,
    selectedLabelers?: string[]
  ) {
    if (!batchId) return;
    const taskIds = tasks
      .filter((task) => task.selected && task.status === TaskStatus.WORKING)
      .map((task) => task.id);
    try {
      if (selectedLabelers && selectedLabelers.length > 0) {
        await TaskService.releaseTasks(batchId, undefined, selectedLabelers);
      } else {
        await TaskService.releaseTasks(batchId, taskIds, undefined);
      }
      const options = buildRequestOptions(filter);
      if (options) {
        const payload = { options };
        await dispatch(loadTasksAsync(payload));
      }

      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
    } catch (error) {
      Sentry.captureException(error);
      Logger.log(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    }
  }

  useDeepCompareEffect(() => {
    const requestData = async () => {
      try {
        const options = buildRequestOptions(filter);
        if (!options) return;
        const payload = { options };
        await dispatch(loadTasksAsync(payload));
      } catch (err) {
        Sentry.captureException(err);
        Logger.log(err);
      }
    };
    if (filter?.batchId && !isLoadingTasks) requestData();
  }, [filter, dispatch]);

  const reloadTaskAsync = async () => {
    const options = buildRequestOptions(filter);
    if (!options) return;
    const payload = { options, isReload: true };
    await dispatch(loadTasksAsync(payload));
  };

  const value: BatchTaskContextState = {
    taskRows: tasks,
    hasSelectedTasks,
    selectedAll,
    setTaskRowSelectedAll,
    setTaskRowSelect,
    showSelect,
    setShowSelect,
    reopenTasks,
    releaseTasks,
    changeStatusRow,
    setChangeStatusRow,
    filter,
    setPage,
    setPageSize,
    setSort,
    setId,
    setFileName,
    setStatus,
    setReviewStatus,
    setIssueStatus,
    setCustomerReviewStatus,
    setNumAccepted,
    setAssignee,
    reopenJobs,
    isReviewStep,
    allowReopen,
    approveTask,
    rejectTask,
    reloadTaskAsync,
    isLoadingTasks,
    openReopenDialog,
    setOpenReopenDialog,
    canReopenTasks,
    openReleaseDialog,
    setOpenReleaseDialog,
    applyTaskFilters,
    taskFiltersVisible,
    setTaskFiltersVisible,
    isShowHasFilter,
    viewMode,
    setViewMode,
    isBatchCompleted,
    canAssignJobsToTask,
  };
  return (
    <BatchTaskContext.Provider value={value}>
      {children}
      {requesting && <VBComponentRequesting />}
    </BatchTaskContext.Provider>
  );
};
