/*
 * File: notification.api.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 16th September 2021 8:49:10 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BaseAPI } from "services/base-service/api/base.api";
import { setupLabelInterceptor } from "services/common/label-interceptor";
import { NotificationDTO } from "../dto/notification.dto";

export class NotificationAPI extends BaseAPI<NotificationDTO> {
  constructor(serviceUrl: string, path: string) {
    super(serviceUrl, path);
    this.axiosInstance = setupLabelInterceptor(this.axiosInstance);
  }

  subscribeToken(registrationToken: string) {
    return this.post(`${this.getPath()}/subscribe`, {
      registrationToken: registrationToken,
    });
  }
}
