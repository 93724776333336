/*
 * File: skip-task.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 2nd June 2023 10:17:08 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { VBTextAreaComponent } from "components/design-system/text-area/text-area.component";
import { TaskRow } from "pages/customer/projects/project-batch/batch-detail/pages/tasks/batch-tasks.context";
import { useMemo, useState } from "react";
import useSkipTask from "./use-skip-task";

interface Props {
  task: TaskRow;
  open: boolean;
  onClose: (reload?: boolean) => void;
}

export function SkipTaskModal({ task, open, onClose }: Props) {
  const { skipTask } = useSkipTask();
  const [reason, setReason] = useState("");
  const jobOptions = useMemo(() => {
    return [
      { label: "All jobs", value: "" },
      ...task.jobs.map((job) => {
        return {
          label: job.assignee,
          value: job.id.toString(),
        };
      }),
    ];
  }, [task]);

  const [selectedJob, setSelectedJob] = useState("");

  async function handleSumit() {
    const jobIds = selectedJob ? [parseInt(selectedJob)] : [];
    const taskId = task.id;
    try {
      await skipTask({ taskId, jobIds, reason });
      onClose(true);
    } catch (error) {
      console.log("Error");
    }
  }

  function handleSelectJob(val: any) {
    setSelectedJob(val.value);
  }

  return (
    <VBModal
      title="Skip Task"
      open={open}
      onClose={onClose}
      textSubmit="Apply"
      onSubmit={handleSumit}
    >
      <div className="space-y-4">
        <VBSelectComponent
          header="Select Job"
          options={jobOptions}
          value={jobOptions.find((option) => option.value === selectedJob)}
          onChange={handleSelectJob}
          menuPortalTarget={document.body}
        />
        <VBTextAreaComponent
          header="Reason"
          placeholder="Enter skip reason"
          onInputChange={(value: string) => {
            setReason(value);
          }}
        />
      </div>
    </VBModal>
  );
}

export default SkipTaskModal;
