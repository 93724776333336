/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 18th June 2021 12:21:54 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import Keycloak, { KeycloakConfig, KeycloakInstance } from "keycloak-js";

export function createKeycloak(config: KeycloakConfig) {
  return Keycloak(config);
}

export function initKeycloak(instance: KeycloakInstance) {
  return instance.init({
    onLoad: "check-sso",
    silentCheckSsoRedirectUri:
      window.location.origin + "/silent-check-sso.html",
    pkceMethod: "S256",
  });
}
