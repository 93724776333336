/*
 * File: notification.context.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 15th September 2021 2:55:49 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { defaultNotificationState } from "./notificaiton.state";

export const NotificationContext = createContext(defaultNotificationState);

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};
