/*
 * File: vb-mask-requesting.component.tsx
 * Project: app-aiscaler-web
 * File Created: Sunday, 2nd January 2022 10:01:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Backdrop, LinearProgress } from "@material-ui/core";
import { MatModal } from "components/material/mat-modal.component";

interface Props {
  className?: string;
  showProgress?: boolean;
  hideBackdrop?: boolean;
}
export const VBMaskRequesting = ({
  className,
  showProgress = true,
  hideBackdrop = false,
}: Props) => {
  return (
    <MatModal
      open
      closeAfterTransition
      hideBackdrop={hideBackdrop}
      BackdropComponent={Backdrop}
      className="flex items-start justify-center"
    >
      <div className={`w-screen h-screen ${className ? className : ""}`}>
        {showProgress && <LinearProgress />}
        <div className="flex items-center justify-center w-full h-full"></div>
      </div>
    </MatModal>
  );
};
