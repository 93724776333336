import { Tooltip } from "@material-ui/core";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { Fragment, useEffect, useMemo } from "react";
import { FabricObjectToolType } from "./fabric/fabric.models";
import {
  EDITOR_TOOLS,
  PathologyEditorLabel,
  PathologyEditorTool,
  TOOL_LINE,
  TOOL_MOVE,
  TOOL_POLYGON,
  TOOL_RECTANGLE,
  TOOL_SELECT,
} from "./pathology-editor.models";
import { usePathologyEditorContext } from "./pathology-editor.provider";
import { VscMove, VscRemove } from "react-icons/vsc";
import { GrSelect } from "react-icons/gr";
import { TbPolygon } from "react-icons/tb";
import { BsBoundingBox } from "react-icons/bs";
import classnames from "classnames";

const ICONS_MAP = {
  [TOOL_MOVE.id]: <VscMove size={16}/>,
  [TOOL_SELECT.id]: <GrSelect className="grIcon" size={16}/>,
  [TOOL_POLYGON.id]: <TbPolygon size={16}/>,
  [TOOL_RECTANGLE.id]: <BsBoundingBox size={14}/>,
  [TOOL_LINE.id]: <VscRemove size={16}/>,
};

export const PathologyEditorToolsComponent = () => {
  const { labels, activeTool, setActiveTool, selectedLabel, setSelectedLabel } =
    usePathologyEditorContext();

  const labelOptions = useMemo(() => {
    let selectLabels: PathologyEditorLabel[] = labels;

    if (activeTool.id === TOOL_POLYGON.id) {
      selectLabels = labels.filter(
        (label) => label.annotationType === FabricObjectToolType.DRAWN_POLYGON
      );
    }
    if (activeTool.id === TOOL_RECTANGLE.id) {
      selectLabels = labels.filter(
        (label) => label.annotationType === FabricObjectToolType.DRAWN_RECTANGLE
      );
    }

    return selectLabels.map((label) => ({
      label: label.name,
      value: label,
    }));
  }, [activeTool, labels]);

  useEffect(() => {
    if (labelOptions.length > 0) {
      setSelectedLabel(labelOptions[0].value);
    }
  }, [labelOptions, setSelectedLabel]);

  const handleSelectLabel = (option: any) => {
    if (option) {
      setSelectedLabel(option.value);
    } else {
      setSelectedLabel(undefined);
    }
  };

  const handleClickTool = (tool: PathologyEditorTool) => {
    if (activeTool.id !== tool.id) {
      setActiveTool(tool);
    }
  };

  return (
    <Fragment>
      <h1 className="font-bold">Tools</h1>
      <div className="flex flex-wrap items-center gap-1">
        {EDITOR_TOOLS.map((tool) => {
          const isActive = tool.id === activeTool.id;
          if (tool.hidden) return null;

          return (
            <Fragment key={tool.id}>
              <Tooltip
                title={`${tool.name} (${tool.shortcut.toUpperCase()})`}
                placement="bottom"
              >
                {/* <IconButton
                  style={{
                    backgroundColor: isActive ? "orange" : "gray",
                    color: "white",
                  }}
                  onClick={(_) => handleClickTool(tool)}
                >
                  {ICONS_MAP[tool.id] && ICONS_MAP[tool.id]}
                  {!ICONS_MAP[tool.id] && (
                    <span>{tool.name.charAt(0).toUpperCase()}</span>
                  )}
                </IconButton> */}

                <button
                  className={classnames({
                    "button-warn-secondary": !isActive,
                    "button-warn": isActive,
                  })}
                  onClick={(_) => handleClickTool(tool)}
                >
                  {ICONS_MAP[tool.id] && ICONS_MAP[tool.id]}
                  {!ICONS_MAP[tool.id] && (
                    <span>{tool.name.charAt(0).toUpperCase()}</span>
                  )}
                </button>
              </Tooltip>
            </Fragment>
          );
        })}
      </div>
      {activeTool?.isDrawing && (
        <div className="mt-2 text-black">
          <VBSelectComponent
            options={labelOptions}
            value={labelOptions.find((o) => o.value.id === selectedLabel?.id)}
            onChange={handleSelectLabel}
          />
        </div>
      )}
    </Fragment>
  );
};
