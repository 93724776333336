import { ActiveLearningSetting, BatchDTO } from "services/label-service/dtos";
import { ProjectInfoDTOV2 } from "services/label-service/dtos/project-v2.dto";


export interface CreateBatchV2StepDataModel {
  id: number;
  name: string;
}

export const STEP_GENERAL_INFO: CreateBatchV2StepDataModel = {
  id: 1,
  name: "General Info.",
};

export const STEP_DATASOURCE: CreateBatchV2StepDataModel = {
  id: 2,
  name: "Datasource",
};

export const STEPS_DATA: CreateBatchV2StepDataModel[] = [
  STEP_GENERAL_INFO,
  STEP_DATASOURCE,
];


export interface CreateBatchV2PageInfoModel
  extends Pick<
    BatchDTO,
    "name" | "description" | "instruction" | "embeddedType" | "embeddedContent"
  > {
  activeLearning: ActiveLearningSetting;
  balanceJobAssign?: boolean;
}

export interface CreateBatchV2State {
  projectForCreatingBatch: ProjectInfoDTOV2,
  batchInfo: any,
  setBatchInfo: (v: any) => void;
  batchDatasource: any,
  setBatchDatasource: (v: any) => void;
  currentStep: CreateBatchV2StepDataModel,
  setCurrentStep: (v: CreateBatchV2StepDataModel) => void;
}
