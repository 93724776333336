/*
 * File: dicom-service.util.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 3rd July 2021 5:28:39 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { ENV_CONFIG } from "configs/env.config";
import { DicomData } from "models/dicom/dicom-data.model";
import { extractDicomDataFromUrl } from "utilities/dicom/dicom.utils";

const baseURL = ENV_CONFIG.DICOM_SERVICE_URL;

export enum ThumbnailType {
  INSTANCE = "instance",
  SERIES = "series",
  STUDY = "study",
}

function buildStudyThumbnail(StudyInstanceUID: string) {
  return `${baseURL}/studies/${StudyInstanceUID}/thumbnail`;
}
function buildSeriesThumbnail(
  StudyInstanceUID: string,
  SeriesInstanceUID: string
) {
  return `${baseURL}/studies/${StudyInstanceUID}/series/${SeriesInstanceUID}/thumbnail`;
}
function buildInstancesThumbnail(
  StudyInstanceUID: string,
  SeriesInstanceUID: string,
  SOPInstanceUID: string
) {
  return `${baseURL}/studies/${StudyInstanceUID}/series/${SeriesInstanceUID}/instances/${SOPInstanceUID}/thumbnail`;
}

function buildDicomThumbnail(
  type: ThumbnailType,
  StudyInstanceUID: string,
  SeriesInstanceUID: string,
  SOPInstanceUID: string
) {
  if (type === ThumbnailType.STUDY)
    return buildStudyThumbnail(StudyInstanceUID);
  if (type === ThumbnailType.SERIES)
    return buildSeriesThumbnail(StudyInstanceUID, SeriesInstanceUID);
  if (type === ThumbnailType.INSTANCE)
    return buildInstancesThumbnail(
      StudyInstanceUID,
      SeriesInstanceUID,
      SOPInstanceUID
    );
  throw new Error("Unsupported thumbnail type");
}

function buildBuckDataURI(buckDataURI: string) {
  const data = extractDicomDataFromUrl(buckDataURI);
  if (!data) return null;
  const { StudyInstanceUID, SeriesInstanceUID, SOPInstanceUID } = data;
  return `${baseURL}/studies/${StudyInstanceUID}/series/${SeriesInstanceUID}/instances/${SOPInstanceUID}`;
}

function downloadDicomInstanceImage(dicom: DicomData, fileName: string) {
  return downloadInstanceImage(
    dicom.StudyInstanceUID,
    dicom.SeriesInstanceUID,
    dicom.SOPInstanceUID,
    fileName
  );
}

function downloadInstanceImage(
  StudyInstanceUID: string,
  SeriesInstanceUID: string,
  SOPInstanceUID: string,
  fileName: string
) {
  const url = `${baseURL}/studies/${StudyInstanceUID}/series/${SeriesInstanceUID}/instances/${SOPInstanceUID}/frames/1/rendered?accept=image/png`;
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = () => {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(xhr.response);
    const tag = document.createElement("a");
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
}

export const DicomServiceUtil = {
  buildStudyThumbnail,
  buildSeriesThumbnail,
  buildInstancesThumbnail,
  buildDicomThumbnail,
  buildBuckDataURI,
  downloadInstanceImage,
  downloadDicomInstanceImage,
};
