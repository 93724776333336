/*
 * File: delete-files.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th June 2023 11:41:54 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/use-redux";
import { useState } from "react";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { DELETE_FILE_OPTIONS } from "services/storage/apis/delete-file.api";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { StorageApi } from "data-access/impl/storage";

interface Props {
  open: boolean;
  fileIds: number[];
  onClose: (reload?: boolean) => void;
}

export const DeleteFilesModal = ({ open, fileIds, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [deleteOption, setDeleteOption] = useState(
    DELETE_FILE_OPTIONS.ARCHIVE.toString()
  );
  const [processing, setProcessing] = useState(false);

  const DELETE_OPTIONS = [
    {
      label: t("dataset:deletefiles.deleteOptionArchiveLabel"),
      value: DELETE_FILE_OPTIONS.ARCHIVE.toString(),
    },
    {
      label: t("dataset:deletefiles.deleteOptionDeleteFileLabel"),
      value: DELETE_FILE_OPTIONS.DELETE_FILE.toString(),
    },
    {
      label: t("dataset:deletefiles.deleteOptionDeleteAllLabel"),
      value: DELETE_FILE_OPTIONS.DELETE_ALL.toString(),
    },
  ];

  const DELETE_OPTION_EXPLAINS: any = {
    [DELETE_FILE_OPTIONS.ARCHIVE]: t(
      "dataset:deletefiles.deleteOptionArchiveExplain"
    ),
    [DELETE_FILE_OPTIONS.DELETE_FILE]: t(
      "dataset:deletefiles.deleteOptionDeleteFileExplain"
    ),
    [DELETE_FILE_OPTIONS.DELETE_ALL]: t(
      "dataset:deletefiles.deleteOptionDeleteAllExplain"
    ),
  };

  async function handleDeleteFile() {
    if (processing) return;
    setProcessing(true);
    if (fileIds.length === 0) return;
    let succeedCount = 0;
    let failedCount = 0;
    for (const id of fileIds) {
      try {
        await StorageApi.deleteFile(id, deleteOption as any);
        succeedCount++;
      } catch {
        failedCount++;
      }
    }

    if (succeedCount > 0)
      dispatch(
        enqueueSuccessNotification(
          t("dataset:deletefiles.textDeletedSuccess", { count: succeedCount })
        )
      );
    if (failedCount > 0)
      dispatch(
        enqueueErrorNotification(
          t("dataset:deletefiles.textDeletedFailed", { count: failedCount })
        )
      );
    setProcessing(false);
    onClose(true);
  }
  return (
    <VBModal
      open={open}
      title={t("dataset:deletefiles.title")}
      onClose={() => onClose(false)}
      textSubmit={t("common:buttonOk")}
      onSubmit={handleDeleteFile}
      disableSubmit={processing}
    >
      <div className="flex flex-col gap-3">
        <p>
          {fileIds.length === 1 && t("dataset:deletefiles.singleFileMessage")}
          {fileIds.length > 1 && t("dataset:deletefiles.multiFilesMessage")}
        </p>

        <div className="py-2">
          <VBSelectComponent
            header={t("dataset:deletefiles.selectTitle")}
            size="xl"
            containerClassName=""
            value={DELETE_OPTIONS.find((o) => o.value === deleteOption)}
            options={DELETE_OPTIONS}
            onChange={(option: any) => setDeleteOption(option.value as string)}
            menuPortalTarget={document.body}
          />
          <p className="pt-4">{DELETE_OPTION_EXPLAINS[deleteOption]}</p>
        </div>
      </div>
    </VBModal>
  );
};
