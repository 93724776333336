/*
 * File: select-role.page.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 19th October 2021 10:56:49 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Radio } from "@material-ui/core";
import { IconLogoFull } from "components/common/vb-icon.component";
import { LanguageSelector } from "components/common/vb-language-selector.component";
import { useKeycloakContext } from "contexts/keycloak/keycloak.context";
import { useAppSelector } from "hooks/use-redux";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Routes } from "routers/config/routes";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { UserRole } from "store/auth/auth.state";

export const SelectRolePage = () => {
  const history = useHistory();
  const { signOut, selectRole } = useKeycloakContext();
  const { t } = useTranslation();
  const [scope, setScope] = useState(UserRole.CUSTOMER);
  const currentUser = useAppSelector(selectCurrentUser);

  const activeRoles = useMemo(() => {
    const OPTIONS = [
      {
        title: t("authorization:signin.titleLoginAsLabeler"),
        description: t("authorization:signin.descriptionLoginAsLabeler"),
        id: UserRole.LABELER,
      },
      {
        title: t("authorization:signin.titleLoginAsCustomer"),
        description: t("authorization:signin.descriptionLoginAsCustomer"),
        id: UserRole.CUSTOMER,
      },
    ];
    if (!currentUser) return [...OPTIONS];
    return [
      ...OPTIONS.filter((option) => {
        return currentUser.workspace.hasOwnProperty(option.id);
      }),
    ];
  }, [t, currentUser]);

  function handleSignOut() {
    signOut();
  }

  function handleSignIn() {
    selectRole(scope);
    const isCustomer = scope === UserRole.CUSTOMER;
    history.replace(isCustomer ? Routes.ROOT : Routes.LABELER_HOME);
  }

  useEffect(() => {
    if (!currentUser) return;
    const keys = Object.keys(currentUser.workspace);
    if (!keys || keys.length !== 1) return;
    const userRole = keys[0] as UserRole;
    if (userRole) {
      selectRole(userRole);
      const isCustomer = userRole === UserRole.CUSTOMER;
      history.replace(isCustomer ? Routes.ROOT : Routes.LABELER_HOME);
    }
  });

  return (
    <div className="w-screen h-screen bg-blueGray-100">
      <div
        className="flex flex-col w-full h-full px-4 mx-auto"
        style={{ maxWidth: "28.25rem" }}
      >
        <div className="flex items-center justify-center flex-1 w-full">
          <div className="w-full">
            <div className="relative flex items-center justify-end py-4">
              <div className="absolute left-0 w-full text-2xl font-bold text-center text-primary">
                {t("authorization:signin.title")}
              </div>
              <div className="z-1">
                <LanguageSelector />
              </div>
            </div>
            <div className="flex flex-col items-center w-full p-6 bg-white rounded shadow-lg">
              <IconLogoFull />
              <div className="mt-3 text-gray-400">
                {t("authorization:signin.textChooseRole")}
              </div>
              <div className="flex flex-col w-full gap-4 mt-9">
                {activeRoles.map(({ id, title, description }) => {
                  const isActive = id === scope;
                  return (
                    <button
                      key={id}
                      className={`flex items-center px-4 py-2 rounded border ${
                        isActive ? "border-primary" : "border-gray-200"
                      }`}
                      onClick={() => setScope(id)}
                    >
                      <div className="flex-1 pr-2 text-left">
                        <div
                          className={isActive ? "text-primary" : "text-black"}
                        >
                          {title}
                        </div>
                        <p
                          className={
                            isActive
                              ? "text-sm text-primary-400"
                              : "text-sm text-background-500"
                          }
                        >
                          {description}
                        </p>
                      </div>

                      <Radio
                        color="primary"
                        checked={id === scope}
                        onChange={() => setScope(id)}
                      />
                    </button>
                  );
                })}
              </div>
              <button
                className="w-full h-12 mt-12 text-lg text-white rounded bg-primary"
                onClick={handleSignIn}
              >
                {t("authorization:signin.buttonSelect")}
              </button>
              <button
                className="mt-4 text-primary hover:underline"
                onClick={handleSignOut}
              >
                {t("authorization:signin.buttonSignOut")}
              </button>
            </div>
          </div>
        </div>
        <p className="p-4 text-xs text-center text-gray-400">
          {t("authorization:signin.copyright", {year: new Date().getFullYear()})}
          
        </p>
      </div>
    </div>
  );
};
