import { BatchEstimationPriceUIModel } from "pages/customer/projects/project-batch/batch-detail/pages/price/components/batch-estimatation-price.component";
import { formatCurrency } from "utilities/formatter/currency-formatter.utils";
import { PricingCalculationResponseDTO } from "./payment.dto";

export const convertFromCalculationResponseToBatchEstimationPriceUIModel = (
  response: PricingCalculationResponseDTO,
  currencyCode: string
): BatchEstimationPriceUIModel => {
  const {
    minJobCount,
    maxJobCount,
    minCost,
    maxCost,
    serviceFeeRatio,
    minServiceFee,
    maxServiceFee,
    minTotalCost,
    maxTotalCost,
  } = response;
  let totalJob = `${minJobCount} - ${maxJobCount}`;
  let estimateCost = `${formatCurrency(
    minCost,
    currencyCode
  )} - ${formatCurrency(maxCost, currencyCode)}`;
  let fees = `${formatCurrency(
    minServiceFee,
    currencyCode
  )} - ${formatCurrency(maxServiceFee, currencyCode)}`;
  let feeRatio = `${(serviceFeeRatio * 100).toFixed(1)}`;
  let total = `${formatCurrency(
    minTotalCost,
    currencyCode
  )} - ${formatCurrency(maxTotalCost, currencyCode)}`;
  
  if (minJobCount === maxJobCount) {
    totalJob = formatCurrency(minJobCount, currencyCode);
  }
  if (minCost === maxCost) {
    estimateCost = formatCurrency(minCost, currencyCode);
  }
  if (minServiceFee === maxServiceFee) {
    fees = formatCurrency(minServiceFee, currencyCode);
  }
  if (minTotalCost === maxTotalCost) {
    total = formatCurrency(minTotalCost, currencyCode);
  }

  return {
    totalJob,
    estimateCost,
    fees,
    feeRatio,
    total,
    currencyCode,
  };
};
