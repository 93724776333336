/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 16th September 2021 8:41:19 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ENV_CONFIG } from "configs/env.config";
import { NotificationEventsAPI } from "./api/notification-events.api";
import { NotificationSettingsAPI } from "./api/notification-settings.api";
import { NotificationAPI } from "./api/notification.api";
import { NotificationAPIPaths } from "./config/notification-api.config";

let _NotificationService: NotificationAPI | undefined = undefined;
let _EventsService: NotificationEventsAPI | undefined = undefined;
let _SettingsService: NotificationSettingsAPI | undefined = undefined;

function getNotificationService() {
  if (!_NotificationService) {
    _NotificationService = new NotificationAPI(
      ENV_CONFIG.NOTIFICATION_SERVICE_URL,
      NotificationAPIPaths.notification
    );
  }
  return _NotificationService;
}

function getEventsService() {
  if (!_EventsService) {
    _EventsService = new NotificationEventsAPI(
      ENV_CONFIG.NOTIFICATION_SERVICE_URL,
      NotificationAPIPaths.notificationEvents
    );
  }
  return _EventsService;
}

function getSettingsService() {
  if (!_SettingsService) {
    _SettingsService = new NotificationSettingsAPI(
      ENV_CONFIG.NOTIFICATION_SERVICE_URL,
      NotificationAPIPaths.notificationSettings
    );
  }
  return _SettingsService;
}

export const NotificationServiceImpl = {
  getNotificationService,
  getEventsService,
  getSettingsService,
};
