/*
 * File: batch-label-toolbar.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 26th August 2021 2:02:17 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useMemo, useState } from "react";
import {
  selectBatchLabels,
  selectLabelConfigChanged,
} from "store/customer/batch/batch.selectors";
import { BatchLabelAddTreeDialog } from "./batch-label-add-tree.dialog";
import { setLabelConfigChanged } from "store/customer/batch/batch.slice";
import { BatchDTO } from "services/label-service/dtos";
import { updateBatchManagementAsync } from "store/customer/batch/batch.thunk";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { classnames } from "utilities/classes";
import * as Sentry from "@sentry/react";

export interface BatchLabelToolbarProps {
  batch: BatchDTO;
  disabled?: boolean;
}

export const BatchLabelToolbar = ({
  batch,
  disabled = false,
}: BatchLabelToolbarProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const batchLabels = useAppSelector(selectBatchLabels);
  const [addingLabels, setAddingLabels] = useState(false);
  const labelConfigChanged = useAppSelector(selectLabelConfigChanged);

  const handleAdd = () => {
    setAddingLabels(true);
  };

  const handleSaveChanges = async () => {
    try {
      dispatch(setLabelConfigChanged(false));
      const payload = {
        batchId: batch.id,
        payload: {
          batchObservation: batchLabels.map((bo) => {
            return {
              iou: bo.iou,
              labelRequired: bo.labelRequired,
              probabilityRequired: bo.probabilityRequired,
              observationId: bo.observation.id,
            };
          }),
        },
      };

      const res = await dispatch(updateBatchManagementAsync(payload));
      handleThunkRejected(res);
      dispatch(enqueueSuccessNotification(t("common:textUpdatedSuccess")));
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textUpdatedFailed")));
    }
  };

  const numDisplayLabels = useMemo(() => {
    return batchLabels.filter(
      (bo) => !bo.observation.observationSetting.systemAttribute
    ).length;
  }, [batchLabels]);

  return (
    <div>
      <div className="flex w-full mt-2">
        <div className="truncate">
          {t("project:batchDetails.batchLabels.toolbar.title", {
            count: numDisplayLabels,
          })}
        </div>
        <div className="flex-1"></div>
        <div className="flex gap-4">
          <button
            disabled={disabled}
            className={classnames("button-tertiary", {
              "opacity-60 cursor-not-allowed": disabled,
            })}
            onClick={handleAdd}
          >
            {t("project:batchDetails.batchLabels.toolbar.buttonImport")}
          </button>

          <button
            disabled={!labelConfigChanged}
            className={classnames("button-secondary", {
              "opacity-60 cursor-not-allowed": !labelConfigChanged,
            })}
            onClick={handleSaveChanges}
          >
            {t("common:buttonSaveChanges")}
          </button>
        </div>
      </div>
      <BatchLabelAddTreeDialog
        open={addingLabels}
        handleClose={() => setAddingLabels(false)}
      />
    </div>
  );
};
