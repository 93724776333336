/*
 * File: dataset.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 4th April 2022 10:36:17 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export interface Dataset {
  id: number;
  name: string;
  description: string;
  totalFiles: number;
  previewImageUrl: string;
  createdBy: string;
  createdAt: Date;
}

export enum DataType {
  IMAGE = "image",
  DICOM = "dicom",
  TEXT = "text",
  AUDIO = "audio",
  MDI = "mdi", // multi dimensional image
  WSI = "wsi", // whole slide image
}
