import { createAsyncThunk } from "@reduxjs/toolkit";
import { AnnotateType } from "constants/annotation.constant";
import {
  AutoAnnotateType,
  AutoAnnotateImagePayload,
  AIAssistanceService,
} from "services/ai-assistance-service";
import { RootState } from "store";
import { Point } from "utilities/math/point";
import { selectSmartLabelingEnabled } from "../../smart-labeling/smart-labeling.selectors";
import {
  selectCurrentObservation,
  selectWorkingFile,
} from "../image-labeling.selectors";

const THUNK_NAME = "imageWorkspace/autoAnnotateMeasurementAsync";

export const autoAnnotateMeasurementAsync = createAsyncThunk(
  THUNK_NAME,
  async ({ points }: { points: Point[] }, { getState }) => {
    const state = getState() as RootState;
    const isSmartLabelingEnabled = selectSmartLabelingEnabled(state);
    if (!isSmartLabelingEnabled) return { points };
    const currentObservation = selectCurrentObservation(state);
    if (!currentObservation) return { points: [] };
    points.sort((a, b) => a.y - b.y);
    const file = selectWorkingFile(state);
    const annotationType = currentObservation.annotateType;
    const isBBox = annotationType === AnnotateType.BOUNDING_BOX;
    const type = isBBox ? AutoAnnotateType.BBOX : AutoAnnotateType.POLYGON;
    const payload: AutoAnnotateImagePayload = {
      imageUrl: file.originalUrl || file.url,
      type: type,
      points: points,
      classes: [],
    };
    const response = await AIAssistanceService.autoAnnotateImage(payload);
    return {
      ...response.data,
      type: payload.type,
      labelId: currentObservation.id.toString(),
    };
  }
);
