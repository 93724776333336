import { UserAvatar } from "components/common/vb-user-avatar.component"
import { useAppSelector } from "hooks/use-redux";
import { Fragment } from "react"
import { selectUserInfo } from "store/auth/auth.selectors";


export const ThreeDUserAvatar = () => {
  const userInfo = useAppSelector(selectUserInfo);

  return (
    <Fragment>
      <div className="flex items-center gap-2">
        <UserAvatar 
          className="text-sm w-9 h-9 cursor-pointer"
          name={userInfo?.name || ""}
        />
      </div>
    </Fragment>
  )

}
