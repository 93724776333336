/*
 * File: mammography-labeling-task.state.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 22nd September 2022 2:39:26 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Collection } from "domain/common";
import { ImageLabelingTask, Label } from "domain/image-labeling";
import { Batch } from "domain/labeling/batch";
import { Project } from "domain/labeling/project";
import { Task } from "domain/labeling/task";
import { RequestStatus } from "store/base/base.state";

export interface MammographyLabelingTaskState {
  loadingStatus: RequestStatus;
  labelingTasks: Collection<ImageLabelingTask>;
  project?: Project;
  batch: Batch | null;
  tasks: Collection<Task>;
  annotators: string[];
  selectedAnnotators: string[];
  labels: Label[];
}

export const defaultMammographyLabelingTaskState: MammographyLabelingTaskState =
  {
    loadingStatus: RequestStatus.IDLE,
    labelingTasks: { allIds: [], entities: {} },
    project: undefined,
    batch: null,
    tasks: { allIds: [], entities: {} },
    annotators: [],
    selectedAnnotators: [],
    labels: [],
  };
