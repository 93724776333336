/*
 * File: keyboard-shortcuts.compoent.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 16th April 2022 11:58:05 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { VBModal } from "components/common/vb-modal/vb-modal.component";

interface Props {
  open: boolean;
  onClose(): void;
}
export function TextKeyboardShortcutsModal({ open, onClose }: Props) {
  const keyboardShortcuts = [
    {
      name: "General",
      shortcuts: [
        { keys: ["Ctrl", "S"], name: "Save" },
        { keys: ["Ctrl", "Shift", "Space"], name: "Submit" },
        {
          keys: ["Shift", "Arrow Down"],
          name: "Navigate to next file",
        },
        {
          keys: ["Shift", "Arrow Up"],
          name: "Navigate to previous file",
        },
      ],
    },
    {
      name: "Annotations",
      shortcuts: [
        { keys: ["Del"], name: "Delete an entity or a relation." },
        { keys: ["N"], name: "Toggle display relation name" },
      ],
    },
    {
      name: "Review Annotations",
      shortcuts: [
        { keys: ["A"], name: "Review: Accept current annotation" },
        { keys: ["R"], name: "Review: Reject current annotation" },
      ],
    },
  ];
  return (
    <VBModal
      open
      onClose={onClose}
      title={"Keyboard shortcuts"}
      footerHidden={true}
      width="40rem"
    >
      <div className="space-y-8" style={{ maxHeight: "60vh" }}>
        {keyboardShortcuts.map(({ name, shortcuts }) => {
          return (
            <div key={name} className="space-y-2">
              <h2 className="text-background-500">{name}</h2>
              <div className="space-y-2">
                {shortcuts.map((shortcut) => {
                  return (
                    <div
                      key={shortcut.name}
                      className="flex items-center gap-4"
                    >
                      <div className="flex-auto text-sm">{shortcut.name}</div>
                      <div
                        className="flex items-center flex-none gap-2"
                        style={{ width: "12rem" }}
                      >
                        {shortcut.keys.map((keyboardKey) => {
                          return (
                            <div
                              key={keyboardKey}
                              className="px-2 py-1 text-xs font-semibold uppercase rounded bg-background-200 text-background-700"
                            >
                              {keyboardKey}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </VBModal>
  );
}
