/*
 * File: layout.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:12 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useCallback, useState } from "react";
import {
  IconClose,
  IconInformationCircle,
} from "components/common/vb-icon.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { BatchService, BatchServiceV2 } from "services/label-service";
import { DownloadDatasetPayload } from "services/label-service/apis/batch.api";
import { BatchDTO } from "services/label-service/dtos";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { DownloadDatasetContext } from "./download-dataset.context";
import { useWebsocket } from "services/websocket/use-websocket.hook";
import { TaskRunner } from "domain/customer/task-runner";
import { taskRunnerUpdated } from "store/customer/task-runner/task-runner.slice";
import { selectRunningTasks } from "store/customer/task-runner/task-runner.selectors";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { DownloadDatasetRequestDTO } from "services/label-service/apis/batch-v2.api";
import { useTranslation } from "react-i18next";
import TaskMessage from "./task-message.component";
import * as Sentry from "@sentry/react";

interface Props {
  children: JSX.Element;
}
export const DownloadDatasetProvider = ({ children }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const [downloadingBatch, setDownloadingBatch] = useState<BatchDTO | null>(
    null
  );
  const tasks = useAppSelector(selectRunningTasks);
  async function startDownload(
    batch: BatchDTO,
    payload: DownloadDatasetPayload
  ) {
    try {
      setDownloadingBatch(batch);
      const res = await BatchService.downloadDataset(batch.id, payload);
      const url: string = res.data;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${payload.fileName}.zip`);
      document.body.appendChild(link);
      link.click();
      setDownloadingBatch(null);
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || "Failed to download dataset";
      dispatch(enqueueErrorNotification(errMessage));
      setDownloadingBatch(null);
    }
  }

  const startDownloadAsync = async (
    batch: BatchDTO,
    payload: DownloadDatasetRequestDTO
  ) => {
    try {
      await BatchServiceV2.downloadDatasetAsync(batch.id, payload);
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    }
  };

  const onMessage = useCallback(
    (payload: TaskRunner) => {
      if (!payload) return;
      if (payload?.createdBy !== currentUser?.email) return;
      dispatch(taskRunnerUpdated(payload));
    },
    [dispatch, currentUser]
  );

  useWebsocket(onMessage);

  const state = {
    downloadingBatch,
    startDownload,
    startDownloadAsync,
  };

  return (
    <DownloadDatasetContext.Provider value={state}>
      {children}
      {downloadingBatch && (
        <div
          className="fixed top-0 left-0 right-0 w-full bg-green-500"
          style={{ zIndex: 9999999 }}
        >
          <div
            className="flex items-center gap-4 px-6 py-4 mx-auto text-white"
            style={{ maxWidth: 1200 }}
          >
            <IconInformationCircle className="flex-none w-5 h-5" />
            <span className="flex-auto">
              Dataset export was started for batch {downloadingBatch.name}.
              Download will start automatically as soon as the dataset is ready
            </span>

            <button
              className="flex items-center justify-center w-6 h-6"
              onClick={() => setDownloadingBatch(null)}
            >
              <IconClose className="w-4 h-4" />
            </button>
          </div>
        </div>
      )}
      {tasks.map((task, index) => {
        return <TaskMessage index={index} task={task} key={task.id} />;
      })}
    </DownloadDatasetContext.Provider>
  );
};
