import { Tooltip } from "@material-ui/core";
import { ReactNode } from "react";
import { classnames } from "utilities/classes";

export interface TreeTableLayoutColDef {
  header: string;
  renderHeader?: () => JSX.Element;
  headerClassName?: string;
  flex?: number | string;
  width?: number | string;
  render: (node: any) => ReactNode;
  overflowNotHidden?: boolean;
}

export interface TreeTableLayoutProps {
  columns: TreeTableLayoutColDef[];
  nodes: any[];
  rowHeight?: string;
  rowWidth?: string;
  containerClasses?: string;
  rowClasses?: string;
  headerBg?: string;
  rowsContainerClass?: string;
}

export const TreeTableLayout = ({
  columns,
  nodes,
  rowHeight = "2.5rem",
  rowWidth = "100%",
  containerClasses = "py-4",
  rowClasses = "border-b gap-2",
  headerBg = "bg-background-200",
  rowsContainerClass = "border-2",
}: TreeTableLayoutProps) => {
  return (
    <div className={classnames("flex flex-col w-full", containerClasses)}>
      <div
        className={classnames("flex items-center rounded h-10 mb-2 pl-2", rowClasses, headerBg)}
        style={{ width: rowWidth }}
      >
        {columns.map((col) => (
          <div
            key={col.header.toString()}
            className={classnames(
              `overflow-hidden break-words py-2 font-semibold ${col.headerClassName}`,
              {
                "overflow-hidden": !!!col.overflowNotHidden,
              }
            )}
            style={{ flex: col.flex, width: col.width }}
          >
            {col.renderHeader ? col.renderHeader() : col.header}
          </div>
        ))}
      </div>
      <div className={classnames(rowsContainerClass)}>
        {nodes.map((node) => (
          <div key={node.id}>
            {node && node.visible && (
              <div
                className={classnames("flex items-center", rowClasses)}
                style={{ width: rowWidth, height: rowHeight }}
              >
                {columns.map((col) => (
                  <div
                    key={col.header.toString()}
                    className={classnames("truncate")}
                    style={{ flex: col.flex, width: col.width }}
                  >
                    {col.render(node)}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export interface TreeTableNameCellProps {
  name: string | undefined;
  nodeHeight: number;
  paddingScaler?: number;
}
export const TreeTableNameCell = ({
  name,
  nodeHeight,
  paddingScaler = 1,
}: TreeTableNameCellProps) => {
  return (
    <Tooltip title={name ? name : ""}>
      <span
        className="pl-2"
        style={{
          marginLeft: `${((nodeHeight - 1) * 2 * paddingScaler) / 4}rem`,
        }}
      >
        {name}
      </span>
    </Tooltip>
  );
};

export interface TreeTableShowChildrenCellProps {
  isLeaf: boolean;
  showChildren: boolean;
  nodeId: number;
  setShowChildren: any;
}
export const TreeTableShowChildrenCell = ({
  isLeaf,
  showChildren,
  nodeId,
  setShowChildren,
}: TreeTableShowChildrenCellProps) => {
  return (
    <div>
      {!isLeaf && !showChildren && (
        <button
          onClick={(_) => setShowChildren(nodeId, true)}
          className="flex items-center justify-center w-8 h-8"
        >
          <i className="uir-angle-small-right" />
        </button>
      )}
      {!isLeaf && showChildren && (
        <button
          onClick={(_) => setShowChildren(nodeId, false)}
          className="flex items-center justify-center w-8 h-8"
        >
          <i className="uir-angle-small-down" />
        </button>
      )}
    </div>
  );
};
