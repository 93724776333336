import macro from '@kitware/vtk.js/macros.js';
import widgetBehavior from './SplineWidgetCustom/behavior.js';
import vtkSplineWidget from '@kitware/vtk.js/Widgets/Widgets3D/SplineWidget';


function vtkSplineWidgetCustom(publicAPI, model) {
  model.classHierarchy.push('vtkSplineWidgetCustom');
}

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  
  initialValues = {
    ...initialValues,
    defaultCursor: 'default',
    behavior: widgetBehavior,
  }
  
  Object.assign(model, initialValues);
  
  vtkSplineWidget.extend(publicAPI, model, initialValues);

  vtkSplineWidgetCustom(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkSplineWidgetCustom'); // ----------------------------------------------------------------------------

export default { extend, newInstance };

// NOTE: this file is copied from vtk
// we only change the defaultCursor, behavior properties
// so we should check this file when updating the vtk lib
