/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  getAllGroups,
  createGroup,
  deleteGroup,
  selectWorkspace,
} from "./apis/group.api";
import {
  getGroupUsers,
  addUserToWorkspace,
  removeUserFromWorkspace,
} from "./apis/user-group.api";
import { UserInfoAPI } from "./apis/user-info.api";
import {
  exportWorkspaceUsersToCSV,
  importUsersToWorkspaceFromCSV,
} from "./apis/user.api";

export const UserService = {
  getAllGroups,
  createGroup,
  deleteGroup,
  getGroupUsers,
  addUserToWorkspace,
  removeUserFromWorkspace,
  selectWorkspace,
  exportWorkspaceUsersToCSV,
  importUsersToWorkspaceFromCSV,
};

export const UserInfoService = new UserInfoAPI();
