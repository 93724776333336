/*
 * File: text-labeling.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 5:05:30 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import ConfigService from "configs/app-config";
import { Collection } from "domain/common";
import { TextFile } from "domain/labeling/file";
import {
  NERAnnotationData,
  CORAnnotationData,
  Token,
} from "domain/text-labeling";
import { SystemAnnotationData } from "domain/text-labeling/system-annotation";
import {
  buildAnnotationTokenIds,
  buildTokens,
} from "pages/labeler/text-labeling/components/text-viewer/text-viewer.utils";
import { RootState } from "store";
import { RequestStatus } from "store/base/base.state";
import { selectAppConfig } from "store/common/app-setting/app-setting.selectors";
import { TextWorkspaceState } from "../text-workspace.state";
import { TextLabelingMode } from "./text-labeling.state";
import { setTextLabelingJobBuilder } from "./thunks/text-set-job.thunk";

export enum TextLabelingThunk {
  SET_TEXT_LABELING_TASK_ASYNC = "textLabeling/setTextLabelingTaskAsync",
}

export const setTextLabelingTaskAsync = createAsyncThunk(
  TextLabelingThunk.SET_TEXT_LABELING_TASK_ASYNC,
  async ({ assignees }: { assignees: string[] }, { getState }) => {
    const state = getState() as RootState;
    const { file, batch, task, labels, labelingDatas } = state.textLabelingTask;
    const appConfig = selectAppConfig(state);
    const tokenizer = ConfigService.getTextTokenizer(
      appConfig,
      batch?.dto?.project?.type
    );
    return {
      tokenizer,
      file,
      batch,
      task,
      labels,
      job: null,
      datas: labelingDatas.filter((item) => assignees.includes(item.annotator)),
    };
  }
);

export const textLabelingReducerBuilder = (
  builder: ActionReducerMapBuilder<TextWorkspaceState>
): ActionReducerMapBuilder<TextWorkspaceState> => {
  setTextLabelingJobBuilder(builder);
  return builder
    .addCase(setTextLabelingTaskAsync.pending, (state) => {
      state.textLabeling.requestStatus = RequestStatus.LOADING;
    })
    .addCase(setTextLabelingTaskAsync.fulfilled, (state, action) => {
      const {
        tokenizer,
        batch,
        task,
        file,
        labels,
        job,
        datas: labelingDatas,
      } = action.payload;
      const sentences = (file as TextFile).sentences;
      state.textLabeling.batch = batch?.dto;
      state.textLabeling.task = task?.dto;
      state.textLabeling.job = job;
      state.textLabeling.readonly = true;
      state.textLabeling.labels = labels;
      state.textLabeling.requestStatus = RequestStatus.SUCCESS;
      state.textLabeling.selectedObservationId = -1;
      state.textLabeling.sentences = sentences;
      state.textLabeling.mode = TextLabelingMode.CUSTOMER;
      const { tokenIds, tokenEntities } = buildTokens(sentences, tokenizer);
      state.textLabeling.tokenIds = tokenIds;
      state.textLabeling.tokenEntities = tokenEntities;
      state.textEditor.selectedSystemObservationId = -1;
      state.textEditor.observations = [];
      state.textEditor.relations = [];
      const textContent = sentences.map((s) => s.sentence).join(" ");
      const tokenCollection: Collection<Token> = {
        entities: tokenEntities,
        allIds: tokenIds,
      };
      const annotations: NERAnnotationData[] = [];
      const relationAnnotations: CORAnnotationData[] = [];
      const systemAnnotations: SystemAnnotationData[] = [];
      if (labelingDatas) {
        for (const data of labelingDatas) {
          if (!data.annotator) continue;
          for (const annotation of data.annotations) {
            const existedAnnotation = annotations.find(
              (anno) => anno.id === annotation.id
            );
            if (existedAnnotation) {
              existedAnnotation.annotators?.push(data.annotator);
              existedAnnotation.selected = true;
            } else {
              const { startIndex, endIndex } = annotation;
              const content = textContent.substring(startIndex, endIndex + 1);
              annotations.push({
                ...annotation,
                annotators: [data.annotator],
                content: content,
                tokenIds: buildAnnotationTokenIds(annotation, tokenCollection),
              });
            }
          }

          for (const relation of data.relationAnnotations) {
            const existedAnnotation = relationAnnotations.find(
              (anno) =>
                anno.from === relation.from &&
                anno.to === relation.to &&
                anno.observationId === relation.observationId
            );
            if (existedAnnotation) {
              existedAnnotation.annotators?.push(data.annotator);
              existedAnnotation.selected = true;
            } else {
              relationAnnotations.push({
                ...relation,
                annotators: [data.annotator],
              });
            }
          }

          const observationId = data.systemObservationId?.toString();
          if (observationId) {
            const existedAnnotation = systemAnnotations.find(
              (anno) => anno.id === observationId
            );

            if (existedAnnotation) {
              existedAnnotation.annotators?.push(data.annotator);
              existedAnnotation.selected = true;
            } else {
              systemAnnotations.push({
                id: observationId,
                observationId,
                active: false,
                annotators: [data.annotator],
              });
            }
          }
        }

        for (const relation of relationAnnotations) {
          const from = annotations.find((anno) => anno.id === relation.from);
          const to = annotations.find((anno) => anno.id === relation.to);
          if (from && to) {
            relation.content =
              (from.content || from.id) + " -> " + (to.content || to.id);
          }
        }

        state.textEditor.observations = annotations;
        state.textEditor.relations = relationAnnotations;
        state.textEditor.selectedSystemObservationId = -1;
      }
    });
};
