import { IconLabelBBox, IconLabelPolygon } from "components/common/vb-icon.component"
import { AnnotateType } from "constants/annotation.constant"

interface ActionPanelLabelIconProps {
  type: AnnotateType | string
}

export const ActionPanelLabelIcon = ({ type }: ActionPanelLabelIconProps) => {
  if (type === AnnotateType.BOUNDING_BOX) return <IconLabelBBox fill="white" />
  if (type === AnnotateType.POLYGON) return <IconLabelPolygon fill="white" />
  return null
}