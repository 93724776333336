/*
 * File: training-jobs.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 6th September 2021 2:51:20 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { TrainingJobService } from "services/label-service";
import { CreateTrainingJobPayload } from "services/label-service/apis/training-jobs.api";
import { RootState } from "store";
import {
  requestPending,
  requestRejected,
  requestFulfilled,
  setEntites,
  removeEntity,
} from "store/base/base.reducer";
import { selectCurrentWorkspaceId } from "store/common/user-workspace/user-workspace.selectors";
import { TrainingJobState } from "./training-jobs.state";

export enum TrainingJobThunk {
  LOAD_TRAINING_JOBS_ASYNC = "trainingJobs/loadTrainingJobsAsync",
  CREATE_TRAINING_JOB_ASYNC = "trainingJobs/createTrainingJobAsync",
  DELETE_TRAINING_JOB_ASYNC = "trainingJobs/deleteTrainingJobAsync",
}

export const loadTrainingJobsAsync = createAsyncThunk(
  TrainingJobThunk.LOAD_TRAINING_JOBS_ASYNC,
  async () => {
    const items = await TrainingJobService.getTrainingJobs();
    return items;
  }
);

export const createTrainingJobAsync = createAsyncThunk(
  TrainingJobThunk.CREATE_TRAINING_JOB_ASYNC,
  async (payload: CreateTrainingJobPayload, { getState }) => {
    const rootState = getState() as RootState;
    const workspaceId = selectCurrentWorkspaceId(rootState);
    payload.workspaceId = workspaceId || "";
    const response = await TrainingJobService.createTrainingJob(payload);
    return response.data;
  }
);

export const deleteTrainingJobAsync = createAsyncThunk(
  TrainingJobThunk.DELETE_TRAINING_JOB_ASYNC,
  async (jobId: number) => {
    await TrainingJobService.deleteItem(jobId);
    return jobId;
  }
);

export const trainingJobReducerBuilder = (
  builder: ActionReducerMapBuilder<TrainingJobState>
): ActionReducerMapBuilder<TrainingJobState> => {
  return builder
    .addCase(loadTrainingJobsAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(loadTrainingJobsAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(loadTrainingJobsAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      if (action.payload) {
        setEntites(state, action.payload);
      }
    })

    .addCase(createTrainingJobAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(createTrainingJobAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(createTrainingJobAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      // if (action.payload) addEntity(state, action.payload);
    })
    .addCase(deleteTrainingJobAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(deleteTrainingJobAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(deleteTrainingJobAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      removeEntity(state, action.payload);
      // if (action.payload) addEntity(state, action.payload);
    });
};
