import axios, { AxiosInstance, AxiosResponse } from "axios";
import { ENV_CONFIG } from "configs/env.config";
import { setupLabelInterceptor } from "services/common/label-interceptor";
import {
  CreateActivityPayload,
  CreateActivityResponseDTO,
} from "./activity.dto";

let _axiosInstance: AxiosInstance | undefined = undefined;

function getAxiosInstance() {
  if (_axiosInstance) return _axiosInstance;
  _axiosInstance = setupLabelInterceptor(
    axios.create({
      baseURL: ENV_CONFIG.ACTIVITY_SERVICE_URL,
    })
  );
  return _axiosInstance;
}

const createActivity = (
  payload: CreateActivityPayload
): Promise<AxiosResponse<CreateActivityResponseDTO>> => {
  return getAxiosInstance().post(`/api/v1/activities`, payload);
};

export const ActivityService = {
  createActivity,
};
