/*
 * File: mammography-task-review.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 21st September 2022 4:24:38 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useEffect } from "react";
import { Backdrop } from "@material-ui/core";
import { MatModal } from "components/material/mat-modal.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { classnames } from "utilities/classes";
import { useMammographyTaskContext } from "../mammography-task-context/mammography-task.context";
import {
  selectMammographyLabelingTasks,
  selectMammographyLabelingTaskLoadingStatus,
} from "store/labeler/mammography-labeling-task/mammography-labeling-task.selectors";
import { RequestStatus } from "store/base/base.state";
import { mammographyLoadTaskAsync } from "store/labeler/mammography-labeling-task/mammography-labeling-task.thunk";
import { BatchLabelingProvider } from "pages/labeler/batch-labeling/context/batch-labeling.provider";
import { BatchLabelingPage } from "pages/labeler/batch-labeling/batch-labeling.page";
import { imageSetTasksAsync } from "store/labeler/image-workspace/batch-labeling/thunks/image-set-tasks.thunk";
import useDeepCompareEffect from "use-deep-compare-effect";
import { resetImageWorkspaceState } from "store/labeler/image-workspace/image-workspace.slice";
import { LabelingMode } from "domain/labeling/labeling-mode";

interface Props {
  taskId: number;
  allTaskIds: number[];
}

export const MammographyTaskReviewComponent = ({ taskId }: Props) => {
  const dispatch = useAppDispatch();
  const loadingStatus = useAppSelector(
    selectMammographyLabelingTaskLoadingStatus
  );
  const isLoading =
    loadingStatus === RequestStatus.IDLE ||
    loadingStatus === RequestStatus.LOADING;
  const { isFullScreen, onClose } = useMammographyTaskContext();
  const labelingTasks = useAppSelector(selectMammographyLabelingTasks);

  useEffect(() => {
    if (loadingStatus !== RequestStatus.IDLE) return;
    async function loadData() {
      await dispatch(mammographyLoadTaskAsync({ taskId }));
    }
    loadData();
  }, [dispatch, loadingStatus, taskId]);

  useDeepCompareEffect(() => {
    if (!labelingTasks || labelingTasks.length === 0) return;
    dispatch(resetImageWorkspaceState());
    dispatch(imageSetTasksAsync());
  }, [labelingTasks]);

  const containerStyle = isFullScreen
    ? {}
    : { maxWidth: "90vw", maxHeight: "90vh" };
  return (
    <MatModal
      open
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={onClose}
    >
      <div className="relative flex items-center justify-center w-full h-full">
        <div
          className={classnames(
            "relative w-full h-full overflow-hidden bg-white",
            { rounded: !isFullScreen }
          )}
          style={containerStyle}
        >
          {isLoading && (
            <div className="flex items-center justify-center w-full h-full bg-blueGray-900">
              <span>Loading...</span>{" "}
            </div>
          )}
          {!isLoading && (
            <BatchLabelingProvider
              mode={LabelingMode.CUSTOMER}
              onClose={onClose}
            >
              <BatchLabelingPage />
            </BatchLabelingProvider>
          )}
        </div>
      </div>
    </MatModal>
  );
};
