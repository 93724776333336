import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AnnotationService } from "services/label-service";
import { TasksReviewState } from "../tasks-review.state";

enum TaskReviewIouThunk {
  IOU_ANNOTATIONS_LOADED = "tasksReview/taskReviewIouAnnotationsLoadedAsync"
}

interface Payload {
  taskId: number
}

export const taskReviewIouAnnotationsLoadedAsync = createAsyncThunk(
  TaskReviewIouThunk.IOU_ANNOTATIONS_LOADED,
  async (payload: Payload) => {
    const response = await AnnotationService.getIouAnnotationsByTask(payload.taskId);
    return response.data
  }
);

export function taskReviewIouReducerBuilder(
  builder: ActionReducerMapBuilder<TasksReviewState>
) {
  return builder.addCase(
    taskReviewIouAnnotationsLoadedAsync.fulfilled,
    ({ taskReviewIou }, action) => {
      taskReviewIou.iouAnnotations = action.payload
    }
  );
}
