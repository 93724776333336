/*
 * File: labeler-distribution.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 4:53:09 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "store";

export const selectProjectLabelerDistributionData =
  (batchId: number, email: string) => (state: RootState) => {
    const data = state.projectData.labelerDistribution.entities[batchId];
    if (data && data.labelers.hasOwnProperty(email)) {
      return data.labelers[email];
    }
    return null;
  };

export const selectProjectLabelerDistributionRequestStatus = (state: RootState) => {
  return state.projectData.labelerDistribution.status;
};

export const selectProjectLabelerDistributionRequestError = (state: RootState) => {
  return state.projectData.labelerDistribution.error;
};
