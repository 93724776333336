import {
  ComplexJobUIModel,
  ComplexLabelingState,
} from "pages/labeler/speech-to-text-labeling/context/speech-to-text-labeling.state";
import { BatchObservationDTO } from "services/label-service/dtos";
import { AnnotationAttributeItem } from "services/label-service/dtos/annotations.dto";
import { StorageResource } from "services/storage/dto/resource.dto";

export interface TextToSpeechJobUIModel extends ComplexJobUIModel {
  audioUrl: string | undefined;
  recordedBlob: any | undefined;
  text: string;
  note: string;
  resource: StorageResource | undefined;
  attributes?: AnnotationAttributeItem[];
  batchObservations: BatchObservationDTO[];
}

export interface TextToSpeechLabelingState extends ComplexLabelingState {
  uiJob: TextToSpeechJobUIModel | undefined;
  setUIJob: (job: TextToSpeechJobUIModel) => void;
  overwriteUIJob: (
    audioUrl: string | undefined,
    recordedBlob: any | undefined,
    note: string,
    attributes?: AnnotationAttributeItem[]
  ) => void;
}

export const DEFAULT_UI_JOB: TextToSpeechJobUIModel = {
  audioUrl: undefined,
  recordedBlob: undefined,
  text: "",
  note: "",
  probability: 1,
  resource: undefined,
  isTaskReview: false,
  job: undefined,
  isStepReviewJob: false,
  canViewPreviousStepResult: false,
  acceptBatchObservation: undefined,
  rejectBatchObservation: undefined,
  batchObservations:[],
  task: undefined,
  batch: undefined,
  project: undefined,
  countDownSecond: 0,
  previousJobsTaskObservations: [],
  previousJobsAnnotations: [],
  jobOptions: [],
  attributes: undefined
};

export const defaultTextToSpeechLabelingState = {} as TextToSpeechLabelingState;
