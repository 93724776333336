/*
 * File: web-preview.page.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 21st February 2022 9:22:20 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AIResultPreviewEmpty } from "pages/customer/web-builder/components/ai-result-preview/ai-result-preview-empty.component";
import { ModelPreview } from "pages/customer/web-builder/components/model-preview/model-preview.component";
import { useModelWebContext } from "./context/model-web.context";

export const ModelWebPage = () => {
  const { templateMetadata } = useModelWebContext();
  return (
    <div
      className={`flex-auto h-screen ${templateMetadata.theme} ${templateMetadata.color}`}
    >
      <div className="h-full p-4 overflow-y-auto lg:p-6 xl:p-10 dark:bg-black dark:text-white">
        <div className="container mx-auto space-y-10">
          <div className="flex flex-wrap gap-6">
            <ModelPreview />
            <div
              className="flex flex-col space-y-6"
              style={{ minHeight: "15rem" }}
            >
              <div className="flex-auto space-y-4">
                <h1
                  className="max-w-2xl text-xl font-bold lg:text-2xl xl:text-4xl"
                  contentEditable={false}
                  suppressContentEditableWarning
                >
                  {templateMetadata.title}
                </h1>
                <div
                  className="leading-normal"
                  contentEditable={false}
                  suppressContentEditableWarning
                >
                  {templateMetadata.description.split("\n").map((s, index) => (
                    <div key={s + index}>{s}</div>
                  ))}
                </div>
              </div>
              <div className="flex flex-wrap flex-none gap-4">
                <button
                  style={{ minWidth: "114px" }}
                  className="flex items-center justify-center gap-1.5 px-3 h-9 rounded bg-primary text-white border border-primary"
                >
                  <span
                    contentEditable={false}
                    suppressContentEditableWarning
                    className="focus:outline-none"
                  >
                    {templateMetadata.txtImportData}
                  </span>
                </button>
                <button
                  style={{ minWidth: "114px" }}
                  className="flex items-center justify-center gap-1.5 px-3 h-9 rounded bg-primary text-white border border-primary"
                >
                  <span
                    contentEditable={false}
                    suppressContentEditableWarning
                    className="focus:outline-none"
                  >
                    {templateMetadata.txtRunModel}
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">
              {templateMetadata.txtCheckResult}
            </h3>
            <div className="flex flex-wrap gap-4">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((idx) => {
                return <AIResultPreviewEmpty key={idx} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
