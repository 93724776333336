import moment from "moment";
import { NotificationDTO } from "services/notification-service/dto/notification.dto";
import { NotificationRow } from "./notifications.models";


export const mapperNotificationRow = {
  fromEntity: (resRow: NotificationDTO): NotificationRow => {
    const row: NotificationRow = {
      id: resRow.id,
      type: resRow.type,
      level: resRow.level,
      time: moment(new Date(resRow.createdDate)).local().toDate(),
      info: "",
      content: resRow.message,
    };
    return row;
  }
};