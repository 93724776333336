/*
 * File: image-labeling.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 14th April 2022 5:17:47 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import classNames from "classnames";
import { DicomEditor } from "components/dicom/dicom-editor/dicom-editor.component";
import { DicomTools } from "components/dicom/dicom-tools/dicom-tools.component";
import {
  CornerstoneTool,
  SubType,
} from "components/dicom/dicom-tools/dicom-tools.model";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { DragEvent, MouseEvent } from "react";
import {
  selectAutoSaveInSecond,
  selectImageLabelingTaskViews,
  selectImageLabelingViewActiveIndex,
  selectImageLabelingViews,
  selectJobIds,
  selectJobIdsByStatus,
} from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import {
  imageLabelingViewActivated,
  imageLabelingViewDragEnd,
  imageLabelingViewsSelected,
} from "store/labeler/image-workspace/batch-labeling/batch-labeling.slice";
import {
  selectBaseTools,
  selectOtherTools,
  selectResetTools,
  selectAnnotationTools,
  selectActiveCornerstoneTool,
} from "store/labeler/image-workspace/dicom-editor/dicom-editor.selectors";
import { cornerstoneSubTypeSelected } from "store/labeler/image-workspace/dicom-editor/dicom-editor.slice";
import {
  selectIsAutoSaveConfig,
  selectIsShowAnnotationToolbar,
} from "store/labeler/image-workspace/editor-setting/editor-setting.selectors";
import { annotationAttributeMenuClosed } from "store/labeler/image-workspace/image-workspace.slice";
import useAlertSaveWork from "../../hooks/use-alert-save-work.hook";
import { useCornerstoneTools } from "../../hooks/use-conerstone-tools";
import { ImageEditorProvider } from "../../image-editor-context/image-editor.provider";
import { AnnotationToolbar } from "../annotation-toolbar/annotation-toolbar.component";
import { ImageLabelInformation } from "../workspace-panel/image-label-information.component";
import { WorkspacePanel } from "../workspace-panel/workspace-panel.component";
import { sortJobsInViewIfMedicalMamoProjectAndLastJobImageLoaded } from "store/labeler/image-workspace/batch-labeling/batch-labeling.slice";
import { useAutoSave } from "hooks/use-auto-save.hook";
import { JobStatus } from "store/labeler/image-workspace/batch-labeling/batch-labeling.state";
import { useSavingJob } from "../../hooks/use-saving-job.hook";
import { useBatchLabelingContext } from "pages/labeler/batch-labeling/context/batch-labeling.context";
import { LabelingMode } from "domain/labeling/labeling-mode";
import { MammographyReviewPanel } from "../../../../../components/mammography-labeling/mammography-task-review/mamography-review-panel.component";
import { ImageTaskViewer } from "components/dicom/dicom-editor/image-task-viewer.component";
import {
  selectHasSelectedSystemLabel,
  selectIsImageLabelingReadonly,
} from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import ImageAnnotationsPicker from "../annotation-sources/image-annotations-picker.component";
import { Annotation } from "domain/image-labeling";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";
import { imageAnnotationsAddedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-annotations-added.thunk";
import { useImageLabelingContext } from "../../context/image-labeling.context";
import { OnnixProvider } from "modules/onnix/context/onnix-provider";

export default function ImageLabeling() {
  const dispatch = useAppDispatch();
  const autoSaveInSecond = useAppSelector(selectAutoSaveInSecond);
  const baseTools = useAppSelector(selectBaseTools);
  const otherTools = useAppSelector(selectOtherTools);
  const resetTools = useAppSelector(selectResetTools);
  const annotateTools = useAppSelector(selectAnnotationTools);
  const activeTool = useAppSelector(selectActiveCornerstoneTool);
  const readonly = useAppSelector(selectIsImageLabelingReadonly);
  const imageViews = useAppSelector(selectImageLabelingViews);
  const imageTaskViews = useAppSelector(selectImageLabelingTaskViews);
  const activeIndex = useAppSelector(selectImageLabelingViewActiveIndex);
  const imageJobIds = useAppSelector(selectJobIds);
  const isShowToolbar = useAppSelector(selectIsShowAnnotationToolbar);
  const isAutoSaveConfig = useAppSelector(selectIsAutoSaveConfig);
  const hasSelectedSystemLabel = useAppSelector(selectHasSelectedSystemLabel);
  const { showPreviousJobAnnotations, setShowPreviousJobAnnotations } =
    useImageLabelingContext();
  const workingJobIds = useAppSelector(
    selectJobIdsByStatus(JobStatus.WORKING)
  ) as number[];
  const { mode } = useBatchLabelingContext();

  const { onSaveJobs } = useSavingJob({
    imageLoaded: true,
    setRequesting: () => {},
  });

  const autoSave = useAutoSave({
    enabled:
      isAutoSaveConfig && (workingJobIds.length > 0 || hasSelectedSystemLabel),
    onSave: async () => {
      await onSaveJobs(workingJobIds);
    },
    duration: autoSaveInSecond && autoSaveInSecond * 1000,
  });

  const { handleSelectCornerstoneTool } = useCornerstoneTools();
  useAlertSaveWork();

  function handleSubCornerstoneTool(tool: CornerstoneTool, type: SubType) {
    dispatch(cornerstoneSubTypeSelected({ tool, type }));
  }

  function handleSelectViews(views: number) {
    dispatch(imageLabelingViewsSelected(views));
  }

  function handleSubmitAnnotations(
    annotations: Annotation[],
    relations: RelationAnnotation[]
  ) {
    dispatch(
      imageAnnotationsAddedAsync({
        jobId: imageViews[activeIndex],
        data: { annotations, relations },
      })
    );
  }

  const classes: Record<string, string> = {
    "1": "grid grid-col-1 w-full h-full",
    "2": "p-2 gap-1 grid flex-auto w-full h-full grid-cols-2",
    "3": "p-2 gap-1 grid flex-auto w-full h-full grid-cols-2",
    "4": "p-2 gap-1 grid flex-auto w-full h-full grid-cols-2",
    "5": "p-2 gap-1 grid flex-auto w-full h-full grid-cols-3",
    "6": "p-2 gap-1 grid flex-auto w-full h-full grid-cols-3",
    "7": "p-2 gap-1 grid flex-auto w-full h-full grid-cols-3",
    "8": "p-2 gap-1 grid flex-auto w-full h-full grid-cols-3",
    "9": "p-2 gap-1 grid flex-auto w-full h-full grid-cols-3",
  };

  function handleDrop(event: any, index: number) {
    event.preventDefault();
    const jobId = event.dataTransfer.getData("jobId");
    const payload = { jobId: parseInt(jobId), index };
    dispatch(imageLabelingViewDragEnd(payload));
  }

  function handleDragOver(event: DragEvent) {
    event.preventDefault();
  }

  function handleClickJob(event: MouseEvent, jobId: number, index: number) {
    if (jobId !== -1 && activeIndex !== index) {
      event.preventDefault();
      event.stopPropagation();
      dispatch(annotationAttributeMenuClosed());
      dispatch(imageLabelingViewActivated(index));
    }
  }

  function handleImageLoaded(jobId: number) {
    dispatch(sortJobsInViewIfMedicalMamoProjectAndLastJobImageLoaded(jobId));
  }

  return (
    <div className="relative flex flex-row w-full h-full overflow-hidden flex-nowrap">
      <div className="relative z-50 flex-auto h-full">
        <div className={classes[imageViews.length]}>
          {imageViews.map((viewId, index) => {
            const active = index === activeIndex;
            const jobIndex = imageJobIds.findIndex((id) => id === viewId) + 1;
            const key = `${viewId}-${index}`;
            const zIndex = 10 - index;
            const isViewActive = imageViews.length > 1 && active;
            return (
              <div
                style={{ zIndex }}
                className={classNames(
                  "w-full h-full relative bg-blueGray-800",
                  {
                    "border-2 border-warning-500": isViewActive,
                    "border-2 border-black cursor-pointer": !isViewActive,
                  }
                )}
                key={key}
                onDrop={(event) => handleDrop(event, index)}
                onDragOver={handleDragOver}
                onClick={(event) => handleClickJob(event, viewId, index)}
              >
                {mode !== LabelingMode.CUSTOMER && viewId !== -1 && (
                  <ImageEditorProvider>
                    <OnnixProvider jobId={viewId}>
                      <DicomEditor
                        jobId={viewId}
                        active={active}
                        jobIndex={jobIndex}
                        onImageLoaded={handleImageLoaded}
                      />
                    </OnnixProvider>
                  </ImageEditorProvider>
                )}
                {mode === LabelingMode.CUSTOMER && viewId !== -1 && (
                  <ImageEditorProvider>
                    <ImageTaskViewer
                      taskId={imageTaskViews[index]}
                      active={active}
                      jobIndex={jobIndex}
                      onImageLoaded={() => handleImageLoaded(viewId)}
                    />
                  </ImageEditorProvider>
                )}
              </div>
            );
          })}
        </div>
        {isShowToolbar && (
          <div className="absolute bottom-0 right-0 z-10">
            <AnnotationToolbar />
          </div>
        )}
      </div>
      <DicomTools
        activeTool={activeTool}
        annotateTools={annotateTools}
        tools={baseTools}
        resetTools={resetTools}
        otherTools={otherTools}
        readonly={readonly}
        onSelect={handleSelectCornerstoneTool}
        onSelectSubType={handleSubCornerstoneTool}
        onSelectViews={handleSelectViews}
      />

      {mode !== LabelingMode.CUSTOMER && <WorkspacePanel autoSave={autoSave} />}
      {mode === LabelingMode.CUSTOMER && <MammographyReviewPanel />}
      {showPreviousJobAnnotations && (
        <ImageAnnotationsPicker
          jobId={imageViews[activeIndex]}
          onClose={() => setShowPreviousJobAnnotations(false)}
          onSubmit={handleSubmitAnnotations}
        />
      )}
      <ImageLabelInformation />
    </div>
  );
}
