/*
 * File: sortable-column.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd January 2022 8:16:16 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

interface Props {
  field: string;
  header: string;
}
export const ColumnHeader = ({ header }: Props) => {
  return (
    <div className="flex items-center justify-between w-full h-full gap-4 px-4 select-none">
      <span className="text-sm font-semibold whitespace-nowrap">{header}</span>
    </div>
  );
};
