/*
 * File: dicom-editor.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 9th August 2021 11:04:40 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useRef, useEffect, useMemo } from "react";
import * as cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import initCornerstone from "components/dicom/cornerstone/cornerstone.config";
import { ToolName } from "../dicom-tools/dicom-tools.model";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  selectActiveCornerstoneTool,
  selectAnnotationTools,
} from "store/labeler/image-workspace/dicom-editor/dicom-editor.selectors";
import { selectCurrentUser } from "store/auth/auth.selectors";
import FreehandRoiToolExtend from "components/dicom/cornerstone/tools/FreehandRoiExtend";
import RectangleRoiExtendTool from "components/dicom/cornerstone/tools/RectangleRoiExtend";
import HeartRuler from "components/dicom/cornerstone/tools/HeartRuler";
import EraserTool from "../cornerstone/tools/EraserToolExtend";
import { useImageLabelingContext } from "pages/labeler/image-labeling/context/image-labeling.context";
import {
  selectCurrentObservation,
  selectMaskedAnnotations,
} from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { CornerstoneViewportEditor } from "../cornerstone/viewport-editor/viewport-editor";
import { CornerstoneHandler } from "../cornerstone/cornerstone-handler/cornerstone-handler";
import { CornerstoneToolsEvents } from "../cornerstone/models/cornerstone-tools-events.model";
import { selectBaseTools } from "store/labeler/image-workspace/cornerstone/cornerstone.selectors";
import { User } from "store/auth/auth.state";
import { AnnotateType, AppEvents } from "constants/annotation.constant";
import { Point } from "pages/labeler/text-labeling/models/text-viewer.models";
import MaskTool from "../cornerstone/tools/MaskTool";
import { ImageJobInfo } from "./image-job-info.component";
import { ImageViewer } from "../image-viewer/image-viewer";
import { useSize } from "ahooks";
import { IssueManagementProvider } from "pages/customer/projects/project-batch/batch-detail/pages/tasks/components/issue/issue-management.provider";
import { selectBatchLabelingIsPollByGroupProject } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { StepType } from "services/label-service/dtos";
import { jobObservationEdited } from "store/labeler/image-workspace/batch-labeling/batch-labeling.slice";
import { annotationUtils } from "utilities/annotations/annotation-util";
import { useImageEditorContext } from "pages/labeler/image-labeling/image-editor-context/image-editor.context";
import { useCornerstoneHandler } from "pages/labeler/image-labeling/hooks/use-cornerstone-handler";
import { useKeyboardShortcuts } from "pages/labeler/image-labeling/hooks/use-keyboard-shortcuts.hook";
import { Annotation } from "domain/image-labeling";
import { classnames } from "utilities/classes";
import { selectIsShowTaskInfo } from "store/labeler/image-workspace/editor-setting/editor-setting.selectors";
import { AnnotationAttributeMenu } from "pages/labeler/image-labeling/components/annotation-attribute-menu/annotation-attribute-menu.component";
import { measurementPoints } from "../cornerstone/utils/cornerstone-utils";
import { useBatchLabelingContext } from "pages/labeler/batch-labeling/context/batch-labeling.context";
import { setActiveCornerstoneTool } from "store/labeler/image-workspace/dicom-editor/dicom-editor.slice";
import MultiArrowConnectionTool from "../cornerstone/tools/MultiArrowConnectionTool";
import { CornerstoneEvents } from "../cornerstone/models/cornerstone-events.model";
import {
  getBreastDicomTagsDataForDisplay,
  isFileDicom,
} from "utilities/dicom/dicom.utils";
import { AnnotationContextMenu } from "pages/labeler/image-labeling/components/annotation-context-menu/annotation-context-menu.component";
import { AnnotationClassMenu } from "pages/labeler/image-labeling/components/annotation-class-menu/annotation-class-menu.component";
import { useSynchronizingTool } from "pages/labeler/image-labeling/hooks/use-synchronize-tool";
import { imageAnnotationUtils } from "store/labeler/image-workspace/image-annotations/image-annotations.util";
import { autoAnnotateMeasurementAsync } from "store/labeler/image-workspace/image-labeling/thunks/auto-annotate-measurement.thunk";
import {
  setImageLabelingError,
  observationAnnotationSelected,
} from "store/labeler/image-workspace/image-workspace.slice";
import { createAnnotationAsync } from "store/labeler/image-workspace/image-labeling/thunks/create-annotation.thunk";
import { imageAnnotationSelectedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-annotation-selected.thunk";
import { imageAnnotationUpdatedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-annotation-updated.thunk";
import { imageAnnotationRemovedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-annotation-removed.thunk";
import { imageAnnotationAddedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-annotation-added.thunk";
import { imageRelationAnnotationRemovedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-relation-annotation-removed.thunk";
import { imageRelationAnnotationCompletedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-relation-annotation-completed.thunk";
import { imageAnnotationCompletedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-annotation-completed.thunk";
import { imageRelationAnnotationUpdatedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-relation-annotation-updated.thunk";
import useImageAnnotations from "./use-image-annotations.hook";
import useAnnotationContextMenu from "./use-annotation-context-menu.hook";
import useAnnotationVoting from "./use-annotation-voting.hook";
import { IconDownVote, IconVote } from "components/common/vb-icon.component";
import { ImageContextMenu } from "pages/labeler/image-labeling/components/image-context-menu/image-context-menu.component";
import { useImageStacks } from "components/dicom/image-stacks-indicator/use-image-stacks";
import ImageStacksIndicator from "../image-stacks-indicator/image-stacks-indicator";
import WandTool from "../cornerstone/tools/SAMTool";
import { SAMToolHeader } from "./sam-tool-header";
import { SAMToolLoading } from "./sam-tool-loading";
initCornerstone();

interface Props {
  jobId: number;
  active?: boolean;
  jobIndex: number;
  onImageLoaded?: (jobId: number) => void;
}
export const DicomEditor = ({
  jobId,
  active,
  jobIndex,
  onImageLoaded,
}: Props) => {
  const dispatch = useAppDispatch();
  const {
    setImageLoaded: setImageEditorLoaded,
    viewportEditor,
    cornerstoneHandler,
  } = useImageEditorContext();
  const { currentViewportEditor } = useBatchLabelingContext();
  const { imageLoaded, setImageLoaded } = useImageLabelingContext();
  const activeTool = useAppSelector(selectActiveCornerstoneTool);
  const elementRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const viewportWrapperRef = useRef<HTMLDivElement>(null);
  const currentUser = useAppSelector(selectCurrentUser) as User;
  const isPollByGroupProject = useAppSelector(
    selectBatchLabelingIsPollByGroupProject
  );
  const initialized = useRef(false);
  const observation = useAppSelector(selectCurrentObservation);
  const baseTools = useAppSelector(selectBaseTools);
  const annotateTools = useAppSelector(selectAnnotationTools);
  const maskedAnnotations = useAppSelector(selectMaskedAnnotations);
  const isShowTaskInfo = useAppSelector(selectIsShowTaskInfo);
  const containerSize = useSize(containerRef);
  useCornerstoneHandler(active);
  useSynchronizingTool(active);
  const { labelingJob, annotations, relations } = useImageAnnotations(
    jobId,
    imageLoaded
  );
  const isReviewJob = labelingJob.job.stepType === StepType.REVIEW;
  const {
    annotationVoting,
    handleMouseMove,
    handleVoteAnnotation,
    handleDownVoteAnnotation,
  } = useAnnotationVoting(isReviewJob && active);

  const {
    annotationAttributeMenu,
    annotationContextMenu,
    annotationClassMenu,
    imageContextMenu,
    onViewportContextMenu,
    handleCloseImageContextMenu,
    handleCloseAnnotationClassMenu,
    handleCloseAnnotationContextMenu,
    imageContextMenuSelected,
    imageAnnotationContextMenuSelected,
    imageAnnotationClassMenuSelected,
  } = useAnnotationContextMenu(jobId);

  const isSamActive = useMemo(() => {
    return active && activeTool === ToolName.SAM;
  }, [active, activeTool]);

  const {
    isMultipleFrames,
    frame,
    numFrames,
    onChange,
    onNext,
    onPrevious,
    onScroll,
  } = useImageStacks(labelingJob.metadata?.numFrames, cornerstoneHandler);

  const frameRef = useRef<number | undefined>(undefined);

  function handleImageLoaded(imageId?: string) {
    cornerstoneHandler.current?.setCurrentImageId(imageId);
    initCornerstoneTools(imageId);
    initCornerstoneEvents();
    initHeartRuler();
    setImageLoaded(true);
    setImageEditorLoaded(true);
    onImageLoaded && onImageLoaded(jobId);
  }

  function handleImageLoadError(error: any) {
    const errMessage = error?.message || "Failed to load image";
    dispatch(setImageLabelingError(errMessage));
    setImageLoaded(true);
    setImageEditorLoaded(true);
  }

  function initCornerstoneTools(imageId?: string) {
    if (!elementRef.current) return;
    clearAllToolState();
    if (initialized.current) return;
    cornerstoneTools.init({ showSVGCursors: true });
    addToolsForElement();
    const container = elementRef.current;
    const viewport = cornerstone.getViewport(elementRef.current);

    viewportEditor.current = new CornerstoneViewportEditor(container, viewport);
    if (active) currentViewportEditor.current = viewportEditor.current;
    cornerstoneHandler.current = new CornerstoneHandler(container, viewport);
    cornerstoneHandler.current.init({
      userId: currentUser.email,
      jobId: jobId.toString(),
      observation: observation,
      imageIds: getImageIds(),
    });
    initialized.current = true;
    initToolState();
    cornerstoneHandler.current.setToolActive(activeTool);
  }

  function clearAllToolState() {
    const container = elementRef.current;
    if (!container) return;
    for (let tool of baseTools) {
      const csTool = cornerstoneTools[`${tool.type}Tool`];
      cornerstoneTools.clearToolState(container, csTool);
    }
    cornerstoneTools.clearToolState(container, ToolName.FreehandRoiExtend);
    cornerstoneTools.clearToolState(container, ToolName.RectangleRoiExtend);
    cornerstoneTools.clearToolState(container, ToolName.MultiArrowConnection);
    cornerstoneTools.clearToolState(container, ToolName.Mask);
    cornerstoneTools.clearToolState(container, ToolName.StackScrollMouseWheel);
  }

  function addToolsForElement() {
    const container = elementRef.current;
    if (!container) return;
    const tools = baseTools.concat(annotateTools);
    cornerstoneTools.addToolForElement(container, MaskTool);
    cornerstoneTools.addToolForElement(
      container,
      cornerstoneTools["ZoomMouseWheelTool"],
      {
        name: "ZoomMouseWheel",
        supportedInteractionTypes: ["MouseWheel"],
        configuration: {
          minScale: 0.1,
          maxScale: 20.0,
          invert: false,
        },
      }
    );
    for (let tool of tools) {
      if (tool.type === ToolName.MultiArrowConnection) {
        cornerstoneTools.addToolForElement(container, MultiArrowConnectionTool);
      } else if (tool.type === ToolName.FreehandRoiExtend) {
        cornerstoneTools.addToolForElement(container, FreehandRoiToolExtend);
      } else if (tool.type === ToolName.RectangleRoiExtend) {
        cornerstoneTools.addToolForElement(container, RectangleRoiExtendTool);
      } else if (tool.type === ToolName.SAM) {
        cornerstoneTools.addToolForElement(container, WandTool);
      } else if (tool.type === ToolName.HeartRuler) {
        cornerstoneTools.addToolForElement(container, HeartRuler);
      } else if (tool.type === ToolName.Eraser) {
        cornerstoneTools.addToolForElement(container, EraserTool);
      } else if (tool.type === ToolName.StackScrollMouseWheel) {
        cornerstoneTools.addToolForElement(
          container,
          cornerstoneTools.StackScrollMouseWheelTool,
          {
            configuration: {
              loop: true,
              allowSkipping: true,
            },
          }
        );
      } else {
        const csTool = cornerstoneTools[`${tool.type}Tool`];
        cornerstoneTools.addToolForElement(container, csTool);
      }
    }
  }

  function getImageIds() {
    if (!labelingJob.file?.url) return undefined;
    if (!isFileDicom(labelingJob.file)) return undefined;
    const baseUrl = `wadouri:${labelingJob.file.url}`;
    const numberOfFrames = labelingJob.metadata?.numFrames ?? 0;
    if (numberOfFrames) {
      const imageIds = [];
      for (let i = 0; i < numberOfFrames; i++) {
        const imageId = i === 0 ? baseUrl : baseUrl + "?frame=" + i;
        imageIds.push(imageId);
      }
      return imageIds;
    }
    return undefined;
  }

  function initToolState() {
    const container = elementRef.current;
    if (!container || !cornerstoneHandler.current) return;
    const imageIds = getImageIds();
    if (imageIds) {
      const stack = {
        currentImageIdIndex: 0,
        imageIds: imageIds,
      };
      cornerstoneTools.addStackStateManager(container, ["stack"]);
      cornerstoneTools.addToolState(container, "stack", stack);
    }
    cornerstoneHandler.current?.setMaskedAnnotations(maskedAnnotations);
    cornerstoneHandler.current?.setImageAnnotations(annotations);
    cornerstoneHandler.current?.setImageRelationAnnotations(relations);
  }

  async function handleMeasurementAdded(measurementData: any) {
    if (!measurementData || !measurementData.handles || measurementData.frameId)
      return;
    const data = annotationUtils.measurementToAnnotationData(measurementData);
    const res = await dispatch(
      imageAnnotationAddedAsync({ jobId, data, frameId: frameRef.current })
    );
    if (res && (res.payload as Annotation)) {
      const annotation = res.payload as Annotation;
      annotationUtils.updateMeasurement(measurementData, annotation);
      cornerstoneHandler.current?.addMeasurement(measurementData);
    }
  }

  async function handleMeasurementRemoved(measurementData: any) {
    if (!measurementData) return;
    if (ToolName.MultiArrowConnection === measurementData.type) {
      const data =
        annotationUtils.measurementToRelationAnnotationData(measurementData);
      if (data) {
        await dispatch(imageRelationAnnotationRemovedAsync({ jobId, data }));
      }
      return;
    }
    const data = annotationUtils.measurementToAnnotationData(measurementData);
    dispatch(imageAnnotationRemovedAsync({ jobId, data }));
    dispatch(jobObservationEdited(jobId));
    cornerstoneHandler.current?.onMeasurementRemoved(measurementData);
  }

  async function handleMeasurementCompleted(measurementData: any) {
    if (!measurementData) return;

    if (ToolName.MultiArrowConnection === measurementData.type) {
      const data =
        annotationUtils.measurementToRelationAnnotationData(measurementData);
      if (data) {
        await dispatch(imageRelationAnnotationCompletedAsync({ jobId, data }));
      }
      return;
    }
    let data = annotationUtils.measurementToAnnotationData(measurementData);
    const smartLabelingAnnotation = await smartLabelingAsync(measurementData);
    if (smartLabelingAnnotation) {
      data = smartLabelingAnnotation.annotationData;
    }
    const res = await dispatch(
      imageAnnotationCompletedAsync({ jobId, data, frameId: frameRef.current })
    );
    if (res?.payload as Annotation) {
      const annotation = res.payload as Annotation;
      const isValid = imageAnnotationUtils.isValidPolygon(annotation);
      if (annotation && !isValid) {
        cornerstoneHandler.current?.removeMeasurement(measurementData);
        return;
      }
    }
    dispatch(jobObservationEdited(jobId));
  }

  async function handleMeasurementUpdated(measurementData: any) {
    if (!measurementData) return;
    if (ToolName.MultiArrowConnection === measurementData.type) {
      const data =
        annotationUtils.measurementToRelationAnnotationData(measurementData);
      if (data) {
        await dispatch(imageRelationAnnotationUpdatedAsync({ jobId, data }));
      }
      return;
    }
    const data = annotationUtils.measurementToAnnotationData(measurementData);
    dispatch(imageAnnotationUpdatedAsync({ jobId, data }));
    dispatch(jobObservationEdited(jobId));
    cornerstoneHandler.current?.onMeasurementUpdated(measurementData);
  }

  async function handleMeasurementModified(measurementData: any) {
    if (!measurementData) return;
    if (ToolName.MultiArrowConnection === measurementData.type) return;
    cornerstoneHandler.current?.onMeasurementModified(measurementData);
  }

  async function handleMeasurementSelected(measurementData: any) {
    if (isReviewJob) return;
    if (!measurementData) return;
    if (ToolName.MultiArrowConnection === measurementData.type) {
      dispatch(setActiveCornerstoneTool(ToolName.MultiArrowConnection));
      return;
    }
    const data = annotationUtils.measurementToAnnotationData(measurementData);
    const payload = { jobId, data, annotationId: measurementData.id };
    const res = await dispatch(imageAnnotationSelectedAsync(payload));
    if (!res || !(res.payload as Annotation)) return;
    const annotation = res.payload as Annotation;

    dispatch(observationAnnotationSelected(annotation.labelId));
    if (annotation.annotationData[0].type === AnnotateType.BOUNDING_BOX) {
      dispatch(setActiveCornerstoneTool(ToolName.RectangleRoiExtend));
    } else if (annotation.annotationData[0].type === AnnotateType.POLYGON) {
      dispatch(setActiveCornerstoneTool(ToolName.FreehandRoiExtend));
    }
  }

  async function smartLabelingAsync(measurementData: any) {
    const data = { ...measurementData };
    let autoAnnotateAnnotation: Annotation | null = null;
    if (!data || !data.handles) return;
    const { start, end } = data.handles;
    if (!start || !end) return;
    const payload = { points: measurementPoints(measurementData) };
    const response = await dispatch(autoAnnotateMeasurementAsync(payload));
    if (!response) return;
    if (!response.payload) return;
    if (!response.payload.points) return;
    if (response.payload.points.length === 0) return;

    const { points } = response.payload as {
      points: Point[];
      labelId: string;
    };

    if (points && points.length === 2) {
      measurementData.handles.start.x = points[0].x;
      measurementData.handles.start.y = points[0].y;
      measurementData.handles.end.x = points[1].x;
      measurementData.handles.end.y = points[1].y;
    } else if (points && points.length > 2) {
      const payload = {
        type: AnnotateType.POLYGON,
        points: points,
        probability: 1,
        labelId: response.payload.labelId,
      };
      const newAnnotation = await dispatch(createAnnotationAsync(payload));
      if (newAnnotation && (newAnnotation.payload as Annotation)) {
        autoAnnotateAnnotation = newAnnotation.payload as Annotation;
      }
    }
    return autoAnnotateAnnotation;
  }

  function initCornerstoneEvents() {
    const onMeasurementAdded = async (event: any) => {
      const measurementData = event["detail"]["measurementData"];
      if (measurementData?.ignore) return;
      if (ToolName.SAM === measurementData?.type) {
        const customEvent = new CustomEvent("SAM_MEASUREMENT_ADDED", {
          detail: measurementData,
        });
        window.dispatchEvent(customEvent);
        return;
      }
      if (
        "ruler" === measurementData?.type ||
        ToolName.MultiArrowConnection === measurementData?.type ||
        ToolName.StackScrollMouseWheel === measurementData?.type
      )
        return;
      handleMeasurementAdded(measurementData);
    };

    const onMeasurementRemoved = (event: any) => {
      const measurementData = event["detail"]["measurementData"];
      if (measurementData?.ignore) return;
      if ("ruler" === measurementData?.type) return;
      if (ToolName.SAM === measurementData?.type) {
        const customEvent = new CustomEvent("SAM_MEASUREMENT_REMOVED", {
          detail: measurementData,
        });
        window.dispatchEvent(customEvent);
        return;
      }
      handleMeasurementRemoved(measurementData);
    };

    const onMeasurementModified = (event: any) => {
      const measurementData = event["detail"]["measurementData"];
      if (measurementData?.ignore) return;
      if (
        "ruler" === measurementData?.type ||
        ToolName.MultiArrowConnection === measurementData?.type ||
        ToolName.StackScrollMouseWheel === measurementData?.type
      ) {
        return;
      }
      if (ToolName.SAM === measurementData?.type) {
        return;
      }
      handleMeasurementModified(measurementData);
    };

    const onMeasurementUpdated = (event: any) => {
      const measurementData = event["detail"]["measurementData"];
      if (measurementData?.ignore) return;
      if ("ruler" === measurementData?.type) return;
      if (ToolName.SAM === measurementData?.type) {
        const customEvent = new CustomEvent("SAM_MEASUREMENT_UPDATED", {
          detail: measurementData,
        });
        window.dispatchEvent(customEvent);
        return;
      }
      handleMeasurementUpdated(measurementData);
    };

    const onMeasurementCompleted = async (event: any) => {
      let measurementData = event["detail"]["measurementData"];
      if (measurementData?.ignore) return;
      if ("ruler" === measurementData?.type) return;
      if (ToolName.SAM === measurementData?.type) {
        const customEvent = new CustomEvent("SAM_MEASUREMENT_COMPLETED", {
          detail: measurementData,
        });
        window.dispatchEvent(customEvent);
        return;
      }
      handleMeasurementCompleted(measurementData);
    };

    const onMeasurementSelected = async (event: any) => {
      let measurementData = event["detail"]["measurementData"];
      if (measurementData?.ignore) return;
      if ("ruler" === measurementData?.type) return;
      handleMeasurementSelected(measurementData);
    };

    const onStackScroll = async (event: any) => {
      onScroll(event.detail.newImageIdIndex);
    };

    const onImageRender = async (event: any) => {
      if (!labelingJob.file) return;
      const customEvent = new CustomEvent(AppEvents.IMAGE_RENDERED_BY_FILE, {
        detail: {
          fileId: labelingJob.file.id,
          canvasElement: elementRef.current?.firstChild,
        },
      });
      document.dispatchEvent(customEvent);
    };

    elementRef.current?.addEventListener(
      CornerstoneToolsEvents.STACK_SCROLL,
      onStackScroll
    );
    elementRef.current?.addEventListener(
      CornerstoneToolsEvents.MEASUREMENT_ADDED,
      onMeasurementAdded
    );
    elementRef.current?.addEventListener(
      CornerstoneToolsEvents.MEASUREMENT_MODIFIED,
      onMeasurementModified
    );
    elementRef.current?.addEventListener(
      CornerstoneToolsEvents.MEASUREMENT_UPDATED,
      onMeasurementUpdated
    );
    elementRef.current?.addEventListener(
      CornerstoneToolsEvents.MEASUREMENT_REMOVED,
      onMeasurementRemoved
    );
    elementRef.current?.addEventListener(
      CornerstoneToolsEvents.MEASUREMENT_COMPLETED,
      onMeasurementCompleted
    );
    elementRef.current?.addEventListener(
      CornerstoneToolsEvents.MEASUREMENT_SELECTED,
      onMeasurementSelected
    );

    elementRef.current?.addEventListener(
      CornerstoneEvents.IMAGE_RENDERED,
      onImageRender
    );
  }

  function initHeartRuler() {
    if (!elementRef.current) return;
    const heartRuler = cornerstoneHandler.current?.getTool(ToolName.HeartRuler);
    if (!heartRuler) return;
    heartRuler.initData();
    cornerstoneHandler.current?.updateImage();
  }

  useEffect(() => {
    cornerstoneHandler.current?.resize(false);
  }, [containerSize, cornerstoneHandler]);

  useEffect(() => {
    cornerstoneHandler.current?.setCurrentObservation(observation);
  }, [cornerstoneHandler, observation]);

  useEffect(() => {
    if (active) {
      currentViewportEditor.current = viewportEditor.current;
    }
  }, [active, currentViewportEditor, viewportEditor]);

  useEffect(() => {
    frameRef.current = frame;
  }, [frameRef, frame]);

  return (
    <div
      ref={containerRef}
      className={classnames(
        "relative flex-auto w-full h-full animate-fade-in",
        { "pointer-events-none": !active }
      )}
    >
      <div
        ref={viewportWrapperRef}
        onContextMenu={onViewportContextMenu}
        className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
        onMouseMove={handleMouseMove}
      >
        <ImageViewer
          tags={getBreastDicomTagsDataForDisplay(labelingJob.dicomData)}
          ref={elementRef}
          file={labelingJob.file}
          active={active}
          onLoaded={handleImageLoaded}
          onError={handleImageLoadError}
        />
      </div>
      {labelingJob.task && imageLoaded && !isPollByGroupProject && (
        <IssueManagementProvider
          frameId={frame}
          taskId={labelingJob.task.id}
          jobId={jobId}
          isReviewer={labelingJob.job.stepType === StepType.ACCEPTANCE}
          cornerstoneElement={elementRef.current}
          menuPosition="topRight"
          menuPaddingTop={isSamActive ? 48 : 0}
          jobOptions={labelingJob.data?.previousJobData?.map((j) => ({
            jobId: j.jobId,
            assignee: j.annotator,
          }))}
        />
      )}
      {active && annotationAttributeMenu && (
        <AnnotationAttributeMenu annotation={annotationAttributeMenu} />
      )}
      {active && annotationContextMenu && (
        <AnnotationContextMenu
          annotation={annotationContextMenu.annotation}
          options={annotationContextMenu.options}
          onClose={handleCloseAnnotationContextMenu}
          onSelect={imageAnnotationContextMenuSelected}
        />
      )}
      {active && annotationClassMenu && (
        <AnnotationClassMenu
          annotation={annotationClassMenu.annotation}
          onClose={handleCloseAnnotationClassMenu}
          labels={annotationClassMenu.availableClasses}
          onSelect={imageAnnotationClassMenuSelected}
        />
      )}
      {active && imageContextMenu && (
        <ImageContextMenu
          onClose={handleCloseImageContextMenu}
          position={imageContextMenu.position}
          options={imageContextMenu.options}
          onSelect={imageContextMenuSelected}
        />
      )}

      {labelingJob && (
        <ImageJobInfo
          taskId={labelingJob.job.taskId}
          batchId={labelingJob.batch?.id || -1}
          projectId={labelingJob.project?.id || -1}
          showTaskInfo={isShowTaskInfo}
          showDicomdata={true}
          dicomTagsData={getBreastDicomTagsDataForDisplay(
            labelingJob.dicomData
          )}
          dicomTagsDataClasses="text-lg"
        />
      )}

      {isSamActive && <SAMToolHeader />}
      {isSamActive && <SAMToolLoading />}
      {active && <KeyboardShortcuts />}
      {jobIndex && <JobTag jobIndex={jobIndex} />}
      {annotationVoting && (
        <div
          className="absolute z-50 flex items-center justify-center gap-4 transform -translate-x-1/2 -translate-y-1/2"
          style={{
            left: annotationVoting.bbox.x + annotationVoting.bbox.width / 2,
            top: annotationVoting.bbox.y + +annotationVoting.bbox.height / 2,
          }}
        >
          {(!annotationVoting.vote || annotationVoting.vote < 0) && (
            <button onClick={handleVoteAnnotation}>
              <IconVote className="flex-none w-9 h-9" />
            </button>
          )}
          {(!annotationVoting.vote || annotationVoting.vote > 0) && (
            <button onClick={handleDownVoteAnnotation}>
              <IconDownVote className="flex-none w-9 h-9" />
            </button>
          )}
        </div>
      )}

      {active && isMultipleFrames && (
        <ImageStacksIndicator
          frame={frame}
          numFrames={numFrames}
          onChange={onChange}
          onNext={onNext}
          onPrevious={onPrevious}
        />
      )}
    </div>
  );
};

function KeyboardShortcuts() {
  useKeyboardShortcuts();
  return null;
}

function JobTag({ jobIndex }: { jobIndex: number }) {
  return (
    <span className="absolute text-sm font-semibold left-1 bottom-3 text-warning-500">
      [{jobIndex}]
    </span>
  );
}
