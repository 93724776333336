/*
 * File: view-file-info-switch.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 16th August 2021 9:35:06 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { classnames } from "utilities/classes";
import { useDatasetDetailContext } from "../../dataset-detail.context";

export const ViewFileInfoSwitch = () => {
  const { t } = useTranslation();
  const { showFileInfo, toggleShowFileInfo } = useDatasetDetailContext();
  return (
    <button
      className={classnames({
        "button-secondary": !showFileInfo,
        "button-primary": showFileInfo,
      })}
      onClick={toggleShowFileInfo}
    >
      <i className="uir-info"></i>
      <div className="truncate">{t("dataset:buttonViewFileInfo")}</div>
    </button>
  );
};
