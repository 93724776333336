import {
  IconAnnotationPolygon,
  IconAnnotationRectangle,
  IconFilter,
  IconFilterActive,
} from "components/common/vb-icon.component";
import { useMemo, useState } from "react";
import { classnames } from "utilities/classes";
import { FabricObjectToolType } from "./fabric/fabric.models";
import { PathologyEditorAnnotationFilterComponent } from "./filter/pathology-editor-annotation-filter.component";
import {
  PathologyAnnotation,
  PathologyAnnotationAdditionalData,
  TOOL_SELECT,
} from "./pathology-editor.models";
import { usePathologyEditorContext } from "./pathology-editor.provider";

export const ICONS_MAP_ANNOTATION = {
  [FabricObjectToolType.DRAWN_POLYGON.toString()]: (
    <IconAnnotationPolygon className="w-6 h-6" />
  ),
  [FabricObjectToolType.DRAWN_RECTANGLE.toString()]: (
    <IconAnnotationRectangle className="w-6 h-6" />
  ),
  [FabricObjectToolType.DRAWN_LINE.toString()]: "L",
};

export const PathologyEditorAnnotationsComponent = () => {
  const {
    annotations,
    annotationFilter,
    activeTool,
    selectObjects,
    textLabelVisibility,
    setTextLabelVisibility,
    textLabelerVisibility,
    setTextLabelerVisibility,
    utilButtonVisibility,
    setUtilButtonVisibility,
    fillObjects,
    setFillObjects,
  } = usePathologyEditorContext();

  const [showFilters, setShowFilters] = useState(false);

  const hasAnyFilter = useMemo(() => {
    for (const key of Object.keys(annotationFilter)) {
      if ((annotationFilter as any)[key].length > 0) return true;
    }
    return false;
  }, [annotationFilter]);

  const handleSelectAnnotation = (
    annotation: PathologyAnnotation,
    selected: boolean
  ) => {
    if (!annotation.fabricObjectRef) return;
    if (activeTool.id !== TOOL_SELECT.id) return;

    const selectedObjects = annotations
      .filter(
        (anno) =>
          anno.fabricObjectRef?.data?.selected && annotation.id !== anno.id
      )
      .map((anno) => anno.fabricObjectRef as fabric.Object);
    if (selected) {
      selectedObjects.push(annotation.fabricObjectRef);
    }
    selectObjects(selectedObjects);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between relative">
        <span>Total: {annotations.length}</span>
        {!hasAnyFilter && (
          <IconFilter
            className="flex-none w-5 h-5 cursor-pointer"
            onClick={(_) => setShowFilters(!showFilters)}
          />
        )}
        {hasAnyFilter && (
          <IconFilterActive
            className="flex-none w-5 h-5 cursor-pointer"
            onClick={(_) => setShowFilters(!showFilters)}
          />
        )}
        {showFilters && (
          <PathologyEditorAnnotationFilterComponent
            onClose={() => setShowFilters(false)}
          />
        )}
      </div>

      <div className="overflow-y-auto" style={{ maxHeight: 300 }}>
        {annotations.map((anno) => {
          const annoData = anno.fabricObjectRef
            ?.data as PathologyAnnotationAdditionalData;
          if (!anno.fabricObjectRef?.visible) return null;
          const selected = !!annoData.selected;

          return (
            <div
              key={anno.id}
              className={classnames("flex items-center gap-2 cursor-pointer", {
                "bg-background-600": selected,
              })}
              onClick={(_) => handleSelectAnnotation(anno, !selected)}
            >
              <div
                style={{
                  color: annoData.labelColor,
                }}
              >
                {ICONS_MAP_ANNOTATION[anno.type as string]}
              </div>
              <span>{annoData.labelName}</span>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={textLabelVisibility}
            onChange={(e) => setTextLabelVisibility(e.target.checked)}
          />
          <span>Show label</span>
        </div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={textLabelerVisibility}
            onChange={(e) => setTextLabelerVisibility(e.target.checked)}
          />
          <span>Show labeler</span>
        </div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={utilButtonVisibility}
            onChange={(e) => setUtilButtonVisibility(e.target.checked)}
          />
          <span>Show util button</span>
        </div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={fillObjects}
            onChange={(e) => setFillObjects(e.target.checked)}
          />
          <span>Fill</span>
        </div>
      </div>
    </div>
  );
};
