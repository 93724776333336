/*
 * File: workflow.slice.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 14th August 2021 2:56:08 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WorkflowDTO } from "services/label-service/dtos";
import { INITIAL_WORKFLOW_STATE, WorkflowState } from "./workflow.state";
import { AtLeast } from "types/common";
import {
  addEntity,
  removeEntity,
  setEntites,
  updateEntity,
} from "store/base/base.reducer";
import { workflowReducerBuilder } from "./workflow.thunk";

const SLICE_NAME: string = "workflow";
const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_WORKFLOW_STATE,
  reducers: {
    resetWorkflows(state: WorkflowState) {
      Object.assign(state, INITIAL_WORKFLOW_STATE);
    },

    setWorkflows(state: WorkflowState, action: PayloadAction<WorkflowDTO[]>) {
      setEntites(state, action.payload);
    },

    addWorkflow(state: WorkflowState, action: PayloadAction<WorkflowDTO>) {
      addEntity(state, action.payload);
    },

    removeWorkflow(state: WorkflowState, action: PayloadAction<number>) {
      removeEntity(state, action.payload);
    },

    updateWorkflow(
      state: WorkflowState,
      action: PayloadAction<AtLeast<WorkflowDTO, "id">>
    ) {
      updateEntity(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    workflowReducerBuilder(builder);
  },
});

export const {
  setWorkflows,
  addWorkflow,
  removeWorkflow,
  updateWorkflow,
  resetWorkflows,
} = slice.actions;

export const workflowReducer = slice.reducer;
