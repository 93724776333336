/*
 * File: use-project-template.hook.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 25th July 2022 11:27:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useQuery } from "react-query";
import { projectTemplateService } from "../service/project-template.service";

export default function useProjectTemplate(templateId: number) {
  const requestOptions = {
    refetchOnWindowFocus: false,
  };

  function requestData() {
    return projectTemplateService.getProjectTemplateById(templateId);
  }

  const { data: template, isLoading } = useQuery(
    "projectTemplate",
    requestData,
    requestOptions
  );

  return { template, isLoading };
}
