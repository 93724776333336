/*
 * File: file-upload.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 24th June 2022 9:24:20 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Snackbar } from "@material-ui/core";
import { AppEvents } from "constants/annotation.constant";
import { DataType } from "domain/customer";
import { useDetectChange } from "hooks/use-detect-change";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  UploadFileOptions,
  UploadFilesDialog,
} from "pages/customer/datasets/dataset-detail/components/upload/upload-dialog.component";
import {
  UploadAction,
  UploadingFile,
  uploadReducer,
  UploadStatus,
} from "pages/customer/datasets/dataset-detail/components/upload/upload-file.reducer";
import { useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { HTTPStatusCode } from "services/common/http-status";
import { RequestOptions } from "services/common/request-options";
import { StorageService } from "services/storage";
import { uploadZipFile } from "services/storage/apis/resource.api";
import { TYPE_OF_TIME } from "services/storage/apis/upload-files.api";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { selectCurrentDataset } from "store/customer/dataset/dataset.selectors";
import { classnames } from "utilities/classes";
import { mathUtils } from "utilities/math";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { FileUploadContext, useFileUploadContext } from "./file-upload.context";
import * as Sentry from "@sentry/react";

interface UploadFilesProps {
  datasetId: number;
  files: File[];
  tagIds: number[];
  isUploadZip?: boolean;
  isUploadSeries?: boolean;
}

interface Props {
  children: JSX.Element;
}
export const FileUploadProvider = ({ children }: Props) => {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const currentDataset = useAppSelector(selectCurrentDataset);
  const [uploadStatusVisible, setUploadStatusVisible] = useState(false);
  const [uploadFileVisible, setUploadFileVisible] = useState(false);
  const [state, dispatch] = useReducer(uploadReducer, {
    files: [],
    uploadingFile: null,
  });
  const [isUploadingDicomSeries, setIsUploadingDicomSeries] = useState(false);
  const [uploadDicomProgress, setUploadDicomProgress] = useState(0);

  function requestData() {
    const customEvent = new CustomEvent(AppEvents.DATASET_FILES_UPLOADED, {
      detail: {},
    });
    document.dispatchEvent(customEvent);
  }

  const { files, uploadingFile } = state;
  const filesChanged = useDetectChange([files]);
  const uploadingFileChanged = useDetectChange([uploadingFile]);
  const isUploading = useMemo(() => {
    if (uploadingFile) return true;
    return files.find((file) => file.status === UploadStatus.DEFAULT);
  }, [uploadingFile, files]);

  const [uploadOptions, setUploadOptions] = useState<UploadFileOptions>(() => ({
    numberOfTime: 0,
    typeOfTime: TYPE_OF_TIME.SECOND,
    numberOfSentence: 0,
  }));

  async function startUpload() {
    if (uploadingFile || files.length === 0) return;

    const fileToUpload = files.find(
      (f) => f.status === UploadStatus.DEFAULT
    ) as UploadingFile;
    const hasFailedOrDuplicateFile = files.find(
      (f) =>
        f.status === UploadStatus.FAILED ||
        f.status === UploadStatus.UPLOADED_WITH_WARNING
    );

    if (!fileToUpload) {
      requestData();
      if (!hasFailedOrDuplicateFile) {
        setTimeout(() => {
          closeUploadStatus(false);
        }, 2000);
      }
      return;
    }

    try {
      fileToUpload.status = UploadStatus.UPLOADING;
      const payload = { ...fileToUpload };
      dispatch({ type: UploadAction.UPDATE_FILE, payload });
      dispatch({ type: UploadAction.SET_UPLOADING_FILE, payload });

      const paramOptions: RequestOptions = {};
      for (const key of Object.keys(uploadOptions)) {
        paramOptions[key.toString()] = (uploadOptions as any)[key].toString();
      }

      const onProgress = (progressEvent: any) => {
        const progress = progressEvent.loaded / progressEvent.total;
        dispatch({
          type: UploadAction.UPDATE_FILE,
          payload: {
            ...fileToUpload,
            progress: progress,
          },
        });
      };

      const fileName: string = fileToUpload.file.name;
      const isZipFile = fileName.endsWith(".zip");
      if (isZipFile) {
        const storageResource = await uploadZipFile(
          fileToUpload.file,
          onProgress
        );
        await StorageService.uploadZip(
          fileToUpload.datasetId,
          storageResource.resourceId
        );
        dispatch({
          type: UploadAction.UPDATE_FILE,
          payload: {
            ...fileToUpload,
            status: UploadStatus.UPLOADED,
            progress: 1,
          },
        });
      } else {
        const params = {
          datasetId: fileToUpload.datasetId,
          files: [fileToUpload.file],
          onProgress,
          tagIds: fileToUpload.tagIds,
          paramOptions,
        };
        const response = await StorageService.uploadFiles(params);
        if (response.status === HTTPStatusCode.OK) {
          dispatch({
            type: UploadAction.UPDATE_FILE,
            payload: {
              ...fileToUpload,
              status:
                response.data[0].status === "duplicated"
                  ? UploadStatus.UPLOADED_WITH_WARNING
                  : UploadStatus.UPLOADED,
              progress: 1,
            },
          });
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      const payload = { ...fileToUpload, status: UploadStatus.FAILED, error };
      dispatch({ type: UploadAction.UPDATE_FILE, payload });
    } finally {
      dispatch({ type: UploadAction.SET_UPLOADING_FILE, payload: null });
    }
  }

  function onClickUpload() {
    if (isUploading) {
      setUploadStatusVisible(true);
    } else {
      setUploadFileVisible(true);
    }
  }

  function closeUploadStatus(reloadData = true) {
    setUploadStatusVisible(false);
    const action = { type: UploadAction.REMOVE_FILES, payload: [] };
    if (!uploadingFile) dispatch(action);
    if (reloadData) requestData();
  }

  function onProgress(event: any) {
    if (!event || !event.loaded || !event.total) return;
    const progress = event.loaded / event.total;
    setUploadDicomProgress(progress);
  }

  async function uploadMDIDicomSeries({ files, tagIds }: UploadFilesProps) {
    if (!currentDataset || isUploadingDicomSeries) return;
    setIsUploadingDicomSeries(true);
    try {
      setUploadStatusVisible(true);
      setUploadFileVisible(false);

      const params = {
        datasetId: currentDataset.id,
        files,
        tagIds,
        onProgress,
      };
      const res = await StorageService.uploadDicomSeries(params);
      handleThunkRejected(res);
      appDispatch(enqueueSuccessNotification(t("common:textSuccess")));
      setUploadStatusVisible(false);
      requestData();
      setTimeout(() => requestData(), 1000);
    } catch (err: any) {
      Sentry.captureException(err);
      const errMessage = err?.message || t("common:textFailed");
      appDispatch(enqueueErrorNotification(errMessage));
    } finally {
      setIsUploadingDicomSeries(false);
    }
  }

  function handleUploadFiles(payload: UploadFilesProps) {
    if (currentDataset?.category === DataType.MDI && !payload.isUploadZip && payload.isUploadSeries) {
      uploadMDIDicomSeries(payload);
      return;
    }

    dispatch({
      type: UploadAction.ADD_FILES_TO_UPLOAD,
      payload: payload.files.map((file) => ({
        file: file,
        datasetId: payload.datasetId,
        progress: 0,
        status: UploadStatus.DEFAULT,
        error: null,
        tagIds: payload.tagIds,
      })),
    });
    startUpload();
    setUploadStatusVisible(true);
    setUploadFileVisible(false);
  }

  const hanldeUploadOptionsChanged = (options: UploadFileOptions) => {
    setUploadOptions({ ...options });
  };

  useEffect(() => {
    if (!filesChanged) return;
    startUpload();
  });

  useEffect(() => {
    if (!uploadingFileChanged) return;
    if (!uploadingFile) {
      startUpload();
    }
  });

  const contextState = {
    files: files,
    uploadingFile: uploadingFile,
    onClickUpload,
  };

  return (
    <FileUploadContext.Provider value={contextState}>
      {children}
      {uploadFileVisible && (
        <UploadFilesDialog
          visible={uploadFileVisible}
          onClose={() => setUploadFileVisible(false)}
          onSubmit={handleUploadFiles}
          onUploadOptionsChanged={hanldeUploadOptionsChanged}
          disabled={isUploadingDicomSeries}
        />
      )}
      <FileUploadPopup
        progress={uploadDicomProgress}
        open={uploadStatusVisible}
        isMDI={isUploadingDicomSeries}
        onClose={() => setUploadStatusVisible(false)}
      />
    </FileUploadContext.Provider>
  );
};

interface FileUploadPopupProps {
  progress?: number;
  isMDI?: boolean;
  open: boolean;
  onClose(): void;
}
function FileUploadPopup({
  progress = 0,
  open,
  isMDI,
  onClose,
}: FileUploadPopupProps) {
  const { t } = useTranslation();
  const { files, uploadingFile } = useFileUploadContext();
  const uploadStats = useMemo(() => {
    const total = files.length;
    const uploadedCount = files.reduce((totalCount, { status }) => {
      if (status === UploadStatus.UPLOADED) return totalCount + 1;
      if (status === UploadStatus.UPLOADED_WITH_WARNING) return totalCount + 1;
      return totalCount;
    }, 0);
    return { uploaded: uploadedCount, total };
  }, [files]);

  const isUploading = useMemo(
    () =>
      uploadingFile ||
      !!files.find((file) => file.status === UploadStatus.DEFAULT),
    [uploadingFile, files]
  );
  const progressInPercent = mathUtils.clamp(progress * 100, 0, 100);
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      key="_UPLOAD_LOCAL_FILE_"
    >
      <div className="overflow-hidden bg-white rounded-lg shadow w-96">
        <div className="flex items-center text-white bg-primary">
          <div className="flex-1 px-4 py-2">Upload files</div>
          <div
            className="flex items-center justify-center w-8 h-8 m-2 rounded cursor-pointer hover:bg-white hover:bg-opacity-20"
            onClick={onClose}
          >
            <i className="uir-cross-small"></i>
          </div>
        </div>
        <div className="pb-2">
          {isMDI && (
            <div className="p-4 space-y-2">
              <div>
                {progressInPercent >= 100
                  ? "Processing your files. Please wait a few moments..."
                  : t("common:textUploadingDiconFiles", {
                    progress: Math.floor(progressInPercent),
                  })}
              </div>
              {progressInPercent < 100 && (
                <div className="w-full h-2 rounded-full bg-background-300">
                  <div
                    className="h-2 rounded-full bg-primary"
                    style={{ width: `${Math.floor(progressInPercent)}%` }}
                  />
                </div>
              )}
            </div>
          )}
          {isUploading && (
            <div className="px-4 py-2 text-sm text-black bg-gray-100">
              {t("common:textUploading", uploadStats)}
            </div>
          )}
          <div className="w-full overflow-x-hidden overflow-y-auto max-h-60">
            {files.map(({ file, status, progress }) => {
              return (
                <FileUploadStatus
                  key={file.name}
                  name={file.name}
                  progress={progress}
                  status={status}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Snackbar>
  );
}

interface FileUploadStatusProps {
  status: UploadStatus;
  name: string;
  progress: number;
}
function FileUploadStatus({ status, name, progress }: FileUploadStatusProps) {
  const isFileUploading = [
    UploadStatus.DEFAULT,
    UploadStatus.UPLOADING,
  ].includes(status);

  return (
    <div
      key={name}
      className={classnames("px-4 py-2 border-t flex items-center", {
        "text-gray-400": isFileUploading,
      })}
    >
      <div className="relative flex-1 min-w-0 mr-2">
        <div className="truncate">{name}</div>
        <div className="absolute w-full h-1 bg-gray-200 rounded-full" />
        <div
          className="absolute h-1 bg-green-500 rounded-full"
          style={{ width: `${Math.floor(progress * 100)}%` }}
        />
      </div>
      <FieUploadStatusIcon status={status} />
    </div>
  );
}

interface FileUploadStatusIconProps {
  status: UploadStatus;
}
function FieUploadStatusIcon({ status }: FileUploadStatusIconProps) {
  if (status === UploadStatus.UPLOADED) {
    return (
      <div
        className="flex items-center justify-center w-5 h-5 text-xs text-white bg-green-500 rounded-full"
        title="File uploaded"
      >
        <i className="uir-check"></i>
      </div>
    );
  }

  if (status === UploadStatus.FAILED) {
    return (
      <div
        className="flex items-center justify-center w-5 h-5 text-xs text-white bg-red-500 rounded-full"
        title="Failed to upload this file"
      >
        <i className="uir-exclamation"></i>
      </div>
    );
  }

  if (status === UploadStatus.UPLOADED_WITH_WARNING) {
    <div
      className="flex items-center justify-center w-5 h-5 text-xs text-white bg-yellow-500 rounded-full"
      title="This file might be duplicated"
    >
      <i className="uir-exclamation"></i>
    </div>;
  }

  return (
    <div className="flex items-center justify-center w-5 h-5 border-2 rounded-full"></div>
  );
}
