import { RequestOptions } from "services/common/request-options";
import { SearchParams } from "models/common/search-params";

export type FilterSortType = "" | "asc" | "desc";
export class FilterCommon {
  page?: number;
  pageSize?: number;
  sort?: string;
  type?: string;
  status?: string;

  static toRequestOption(filter: FilterCommon): RequestOptions {
    const options: RequestOptions = {};

    if (filter.page)
      options[SearchParams.PAGE] = `${Math.max(filter.page, 1) - 1}`;
    if (filter.pageSize)
      options[SearchParams.SIZE] = filter.pageSize.toString();
    if (filter.sort) options[SearchParams.SORT] = filter.sort;
    if (filter.type) options[`${SearchParams.TYPE}.equals`] = filter.type;
    if (filter.status) options[`${SearchParams.STATUS}.equals`] = filter.status;

    return options;
  }

  static getSortValue(sortType: FilterSortType, fieldName: string) {
    return sortType ? `${fieldName},${sortType}` : "";
  }

  static getSortType(
    sortValue: string | undefined | null,
    fieldName: string
  ): FilterSortType {
    if (!sortValue) return "";
    const parts = sortValue.split(",");
    if (parts.length !== 2) return "";
    const sortField = parts[0];
    const sortType = parts[1] as FilterSortType;
    if (sortField === fieldName) return sortType;
    return "";
  }

  static getSortFieldAndType(sort: string) {
    let sortField: string = "";
    let sortType: FilterSortType = "";

    const parts = sort.split(",");
    if (parts.length === 2) {
      sortField = parts[0];
      sortType = parts[1] as FilterSortType;
    }

    return {
      sortField,
      sortType,
    };
  }

  static applyFilterToRows(rows: any[], filter: FilterCommon): any[] {
    // sort
    if (filter.sort) {
      const { sortField, sortType } = FilterCommon.getSortFieldAndType(
        filter.sort
      );
      if (sortField && sortType) {
        rows = rows.sort((row1: any, row2: any) => {
          if (sortType === "asc")
            return row1[sortField] < row2[sortField] ? -1 : 1;
          if (sortType === "desc")
            return row1[sortField] > row2[sortField] ? -1 : 1;
          return 0;
        });
      }
    }

    // paging
    const pageIndex = filter.page || 1;
    const pageSize = filter.pageSize || 0;
    const start = (pageIndex - 1) * pageSize;
    const end = start + pageSize;
    rows = rows.slice(start, end);

    return rows;
  }
}

export function getSortOrder<T extends Filter>(filter: T, field: string) {
  return filter.sort?.field === field
    ? filter.sort?.order?.toLowerCase()
    : undefined;
}

export interface Filter {
  page: number;
  size: number;
  search?: string;
  sort?: FilterSort;
  queries?: FilterQuery[];
}

export interface FilterSort {
  field: string;
  order: string /** asc, desc */;
}

export interface FilterQuery {
  field: string;
  operator?: string /** $eq, $ne, $co, $sw, $ew, $pr, $gt, $ge, $lt, $le */;
  value?: string;
}

export function getFilterQuery<T extends Filter>(filter: T, key: string) {
  return filter.queries?.find((q) => q.field === key);
}
