/*
 * File: onnix-context.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 25th April 2023 1:44:48 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { defaultOnnixState } from "./onnix-state";

export const OnnixContext = createContext(defaultOnnixState);

export function useOnnixContext() {
  return useContext(OnnixContext);
}
