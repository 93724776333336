/*
 * File: model-selector.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 22nd February 2022 9:11:05 am
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { IconEdit } from "components/common/vb-icon.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { MLModel, MLModelType, MLModelVersion } from "domain/web-builder";
import { useAIModels } from "hooks/ai-model/use-ai-models.hooks";
import { useMemo } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ModelSelectorRow, ModelSelectorTable } from "./model-selector-table";

interface Props {
  type: MLModelType;
  model: MLModel | null;
  modelVersion: MLModelVersion | null;
  setModel: (model: MLModel) => void;
  setModelVersion: (modelVersion: MLModelVersion) => void;
}

export const ModelSelector = ({
  type,
  model,
  modelVersion,
  setModel,
  setModelVersion,
}: Props) => {
  const { t } = useTranslation();
  const { aiModels: models } = useAIModels();
  const [openSelection, setOpenSelection] = useState(false);
  const [selectedOption, setSelectedOption] = useState<ModelSelectorRow | null>(
    null
  );
  const modelOptions: ModelSelectorRow[] = useMemo(() => {
    const options: ModelSelectorRow[] = [];
    models.forEach((model) => {
      model.versions.forEach((version) => {
        options.push({
          model: model,
          version: version,
        });
      });
    });
    return options;
  }, [models]);

  const handleSubmit = () => {
    setOpenSelection(false);
    if (selectedOption) {
      setModel(selectedOption.model);
      setModelVersion(selectedOption.version);
    }
  };

  return (
    <div className="px-4">
      <div className="text-sm font-semibold">Model</div>
      {model && modelVersion && (
        <div className="flex items-center py-2 space-x-4">
          <div className="flex items-center w-full">
            <VBTextInputComponent
              value={model.name + " v" + modelVersion.name}
              disabled
              containerClassName="w-full box-border"
              inputIcon={<></>}
              trailingElement={
                <IconEdit
                  className="w-5 h-5 cursor-pointer text-primary"
                  onClick={() => setOpenSelection(true)}
                />
              }
            />
          </div>
        </div>
      )}
      {(!model || !modelVersion) && (
        <div className="flex items-center w-full py-2 space-x-4">
          <button
            className="w-full button-secondary"
            onClick={() => setOpenSelection(true)}
          >
            Choose a model
          </button>
        </div>
      )}
      <VBModal
        width="85rem"
        open={openSelection}
        onClose={() => {
          setOpenSelection(false);
          setSelectedOption(null);
        }}
        onSubmit={handleSubmit}
        title="Choose a model"
        textSubmit={t("common:buttonSelect")}
        disableSubmit={!selectedOption}
      >
        <div>
          <ModelSelectorTable
            rows={modelOptions}
            setSelectedRow={setSelectedOption}
            selectedRow={selectedOption}
          />
        </div>
      </VBModal>
    </div>
  );
};
