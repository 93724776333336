import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useMemo } from "react";
import { selectPreviousAnnotationGroups } from "store/labeler/image-workspace/image-annotations/image-annotations.selectors";
import { imageAnnotationUpdated } from "store/labeler/image-workspace/image-workspace.slice";
import { truncateEmail } from "utilities/string/truncate-email";
import { ITreeItem, TreeItem } from "../image-iou/tree-item";

export function PreviousJobAnnotationTree() {
  const dispatch = useAppDispatch();
  const annotationGroups = useAppSelector(selectPreviousAnnotationGroups);
  const treeItems = useMemo(() => {
    const items: ITreeItem[] = [];
    for (const group of annotationGroups) {
      const item: ITreeItem = {
        id: group.groupId.toString(),
        text: group.groupName,
        uuid: "",
        depth: 0,
        children: group.annotations.map((anno) => {
          return {
            id: anno.id.toString(),
            uuid: anno.uuid,
            text: truncateEmail(anno.annotator),
            depth: 1,
            children: [],
            checked: anno.checked,
            hidden: !anno.visible,
          };
        }),
      };
      items.push(item);
    }
    return items;
  }, [annotationGroups]);

  function handleSelectItem(item: ITreeItem, action?: string) {
    if (!item.uuid) return;
    if (action === "TOGGLE_ITEM_VISIBILITY") {
      const payload = { uuid: item.uuid, visible: !!item.hidden };
      dispatch(imageAnnotationUpdated(payload));
    } else if (action === "TOGGLE_ITEM_CHECKED") {
      const payload = { uuid: item.uuid, checked: !item.checked };
      dispatch(imageAnnotationUpdated(payload));
    }
  }

  return (
    <ul>
      {treeItems.map((item) => {
        return (
          <TreeItem key={item.id} item={item} onSelect={handleSelectItem} />
        );
      })}
    </ul>
  );
}
