/*
 * File: batch-observation.dto.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BaseDTO } from "./base.dto";
import { ObservationDTO } from "./observation.dto";

export interface BatchObservationDTO extends BaseDTO {
  batchId: number;
  observation: ObservationDTO;
  labelRequired: boolean;
  iou?: number;
  priority: number;
  probabilityRequired: boolean;
  attributeAgreement?: number[]
}

// client side only for creating batch observation with tree
export interface BatchObservationCreationModel {
  observation: ObservationDTO;
  labelRequired: boolean;
  iou?: number;
  probabilityRequired: boolean;
  visible: boolean;
  showChildren: boolean;
  attributeAgreement?: number[]
}

export const DEFAULT_IOU = 0.4;

export const generateBatchObservationWithPriorities = (
  batchObservations: (BatchObservationDTO | null)[]
) => {
  let crrPriority = 0;
  const newBatchObs: BatchObservationDTO[] = [];
  for (let bo of batchObservations) {
    if (!bo) continue;
    let priority = crrPriority;
    if (bo.observation.observationSetting.systemAttribute) {
      priority = 0;
    } else {
      crrPriority++;
      priority = crrPriority;
    }
    newBatchObs.push({
      ...bo,
      priority,
    });
  }
  return newBatchObs;
};
