import { JobOption } from "domain/common/models";
import { Fragment, useMemo, useState } from "react";
import {
  BatchDTO,
  BatchStatus,
  JobStatus,
  TaskCustomerReviewStatus,
  TaskDTO,
  TaskStatus,
} from "services/label-service/dtos";
import { useBatchTaskContext } from "../batch-tasks.context";
import { ReopenTaskMenu } from "./reopen-menu.component";
import { IconButton, Tooltip } from "@material-ui/core";
import { People } from "iconsax-react";

interface Props {
  batch: BatchDTO;
  task: TaskDTO;
  jobs?: JobOption[];
  onApproved?: () => void;
  onRejected?: () => void;
  onClickAddToLabelDefinition?: () => void;
}

export const TaskReviewReopenButtons = ({ batch, task, jobs }: Props) => {
  const { reopenJobs } = useBatchTaskContext();

  const jobsToReopen = useMemo<JobOption[]>(() => {
    if (jobs) return jobs;
    if (task)
      return task.jobs
        .filter((job) => job.status === JobStatus.COMPLETED)
        .map((job) => {
          return {
            assignee: job.assignee,
            jobId: job.id,
          };
        });
    return [];
  }, [jobs, task]);

  const canReopen = useMemo(() => {
    if (BatchStatus.INREVIEW === batch.status) return false;
    if (BatchStatus.ON_HOLD === batch.status) return false;
    if (task.status === TaskStatus.INITIAL) return false;
    if (task.status === TaskStatus.WORKING) {
      return task.jobs.some((job) => job.status === JobStatus.COMPLETED);
    }
    if (task.customerReviewStatus === TaskCustomerReviewStatus.APPROVED) {
      return false;
    }
    return true;
  }, [task, batch]);

  const [open, setOpen] = useState(false);

  async function handleReopenTask(
    task: TaskDTO,
    jobs: JobOption[],
    reason: string
  ) {
    const jobIds = jobs.map((job) => job.jobId);
    await reopenJobs(task.id, jobIds, reason);
    setOpen(false);
  }

  return (
    <Fragment>
      <Tooltip title="Reopen jobs">
        <IconButton
          onClick={() => setOpen((pre) => !pre)}
          disabled={!canReopen}
        >
          <People size={20} />
        </IconButton>
      </Tooltip>
      <ReopenTaskMenu
        open={open}
        task={task}
        onClose={() => setOpen(false)}
        onSubmit={handleReopenTask}
        jobs={jobsToReopen || []}
      />
    </Fragment>
  );
};
