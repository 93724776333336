/*
 * File: text-set-job.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 18th July 2022 11:59:19 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import ConfigService from "configs/app-config";
import { TextFile } from "domain/labeling/file";
import { JobType } from "domain/labeling/job";
import { TextLabelingData } from "domain/labeling/labeling-data";
import { buildTokens } from "pages/labeler/text-labeling/components/text-viewer/text-viewer.utils";
import { StepType } from "services/label-service/dtos";
import { RootState } from "store";
import { RequestStatus } from "store/base/base.state";
import { selectAppConfig } from "store/common/app-setting/app-setting.selectors";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { textUtils } from "store/labeler/text-workspace/utils/text-labeling.utils";
import { INITIAL_TEXT_CONFLICT_STATE } from "../../text-conflict/text-conflict.state";
import { INITIAL_TEXT_ISSUES_STATE } from "../../text-issues/text-issues.state";
import { INITIAL_TEXT_REVIEW_STATE } from "../../text-review/text-review.state";
import { TextWorkspaceState } from "../../text-workspace.state";

const THUNK_NAME = "textLabeling/setTextLabelingJobAsync";

export const setTextLabelingJobAsync = createAsyncThunk(
  THUNK_NAME,
  async (
    { jobId, readonly = false }: { jobId: number; readonly?: boolean },
    { getState }
  ) => {
    const state = getState() as RootState;
    const labelingJob = state.textLabelingBatch.jobs.entities[jobId];
    if (!labelingJob.data) {
      const { job, file, labels } = labelingJob;
      labelingJob.data = await textUtils.labelerGetTextAnnotationsByJob(
        job,
        file,
        labels
      );
    }
    const appConfig = selectAppConfig(state);
    const tokenizer = ConfigService.getTextTokenizer(
      appConfig,
      labelingJob.batch.dto?.project?.type
    );
    return { labelingJob, readonly, tokenizer };
  }
);

export function setTextLabelingJobBuilder(
  builder: ActionReducerMapBuilder<TextWorkspaceState>
) {
  return builder
    .addCase(setTextLabelingJobAsync.pending, (state) => {
      state.textLabeling.requestStatus = RequestStatus.LOADING;
    })

    .addCase(setTextLabelingJobAsync.fulfilled, (state, action) => {
      const { labelingJob, readonly, tokenizer } = action.payload;
      state.textLabeling.labelingJob = labelingJob;
      if (!labelingJob) return;
      const sentences = (labelingJob.file as TextFile).sentences;
      state.textLabeling.readonly = readonly;
      state.textLabeling.job = labelingJob.job;
      state.textLabeling.batch = labelingJob.batch.dto;
      state.textLabeling.labels = labelingJob.labels;
      state.textLabeling.requestStatus = RequestStatus.SUCCESS;
      state.textLabeling.selectedObservationId = -1;
      state.textLabeling.sentences = sentences;
      const type = labelingJob.job.type;
      if (readonly) {
        state.textLabeling.mode = TextLabelingMode.CUSTOMER;
      } else if (type === JobType.LABELER) {
        state.textLabeling.mode = TextLabelingMode.LABELER;
      } else if (type === JobType.REVIEWER) {
        if (labelingJob.job.stepType === StepType.ACCEPTANCE) {
          state.textLabeling.mode = TextLabelingMode.ACCEPTANCE;
        } else {
          state.textLabeling.mode = TextLabelingMode.REVIEWER;
        }
      } else if (type === JobType.SUPERVISOR) {
        state.textLabeling.mode = TextLabelingMode.SUPERVISOR;
      }
      const { tokenIds, tokenEntities } = buildTokens(sentences, tokenizer);
      state.textLabeling.tokenIds = tokenIds;
      state.textLabeling.tokenEntities = tokenEntities;
      state.textEditor.selectedSystemObservationId = -1;
      state.textEditor.observations = [];
      state.textEditor.relations = [];
      const textData = labelingJob.data?.jobData as TextLabelingData;
      if (textData) {
        const { annotations, relationAnnotations, systemObservationId } =
          textData;
        state.textEditor.observations = annotations;
        state.textEditor.relations = relationAnnotations;
        state.textEditor.selectedSystemObservationId =
          systemObservationId || -1;
      }

      const isSupervisorOrReviewer =
        state.textLabeling.mode === TextLabelingMode.SUPERVISOR ||
        state.textLabeling.mode === TextLabelingMode.REVIEWER;

      state.textEditor.showNavBar = !isSupervisorOrReviewer;
      state.textEditor.showSideBar = !isSupervisorOrReviewer;
      state.textEditor.showPreviousJobs = isSupervisorOrReviewer;

      Object.assign(state.textReview, INITIAL_TEXT_REVIEW_STATE);
      Object.assign(state.textIssues, INITIAL_TEXT_ISSUES_STATE);
      Object.assign(state.textConflict, INITIAL_TEXT_CONFLICT_STATE);
      const labelingDatas = labelingJob.data?.previousJobData || [];
      state.textConflict.labelingDatas = labelingDatas as TextLabelingData[];
      state.textConflict.selectedAnnotators = labelingDatas.map(
        (data) => data.annotator
      );
    });
}
