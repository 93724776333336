/*
 * File: use-annotation-voting.hook.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 9th September 2022 2:20:13 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useAppDispatch } from "hooks/use-redux";
import { useImageEditorContext } from "pages/labeler/image-labeling/image-editor-context/image-editor.context";
import { useState } from "react";
import { imageAnnotationDownVotedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-annotation-downvoted.thunk";
import { imageAnnotationVotedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-annotation-voted.thunk";
import { annotationUtils } from "utilities/annotations/annotation-util";
import { Rectangle } from "utilities/math/rectangle";

export default function useAnnotationVoting(active = false) {
  const dispatch = useAppDispatch();
  const { cornerstoneHandler } = useImageEditorContext();
  const [annotationVoting, setAnnotationVoting] = useState<{
    annotationId: string;
    bbox: Rectangle;
    vote?: number;
  } | null>(null);

  function handleMouseMove() {
    if (!active) return;
    const measurement = cornerstoneHandler.current?.getActiveMeasurement();
    if (!measurement) {
      if (annotationVoting !== null) setAnnotationVoting(null);
      return;
    }
    const bbox = annotationUtils.measurementBoundingBox(measurement);
    if (!bbox) {
      if (annotationVoting !== null) setAnnotationVoting(null);
      return;
    }

    if (measurement.uuid !== annotationVoting?.annotationId) {
      const rect = cornerstoneHandler.current?.canvasRectToPage(bbox);
      setAnnotationVoting({
        annotationId: measurement.uuid,
        vote: measurement.vote,
        bbox: rect || bbox,
      });
    }
  }

  async function handleVoteAnnotation() {
    if (!annotationVoting) return;
    const payload = { annotationId: annotationVoting.annotationId };
    dispatch(imageAnnotationVotedAsync(payload));
    setAnnotationVoting({
      ...annotationVoting,
      vote: 1,
    });
  }

  async function handleDownVoteAnnotation() {
    if (!annotationVoting) return;
    const payload = { annotationId: annotationVoting.annotationId };
    dispatch(imageAnnotationDownVotedAsync(payload));
    setAnnotationVoting({
      ...annotationVoting,
      vote: -1,
    });
  }

  return {
    annotationVoting,
    handleMouseMove,
    handleDownVoteAnnotation,
    handleVoteAnnotation,
  };
}
