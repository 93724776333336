/*
 * File: firebase.config.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 15th September 2021 2:38:26 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

export const FIREBASE_CONFIG: FirebaseConfig = {
  apiKey: getProcessEnv("REACT_APP_FIREBASE_API_KEY"),
  authDomain: getProcessEnv("REACT_APP_FIREBASE_AUTH_DOMAIN"),
  projectId: getProcessEnv("REACT_APP_FIREBASE_PROJECT_ID"),
  storageBucket: getProcessEnv("REACT_APP_FIREBASE_STORAGE_BUCKET"),
  messagingSenderId: getProcessEnv("REACT_APP_FIREBASE_MESSAGING_SENDER_ID"),
  appId: getProcessEnv("REACT_APP_FIREBASE_APP_ID"),
};

export const FIREBASE_MESSAGING_CERTIFICATE = getProcessEnv(
  "REACT_APP_FIREBASE_WEB_PUSH"
);

function getProcessEnv(key: string, defaultValue: string = "") {
  return process.env[key] || defaultValue;
}
