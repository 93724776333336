/*
 * File: axios-cache.api.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 5th October 2022 2:27:41 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { setup } from "axios-cache-adapter";
import { AxiosInstance } from "axios";

let axiosCacheInstance: AxiosInstance | null = null;

export async function configureAxiosCacheInstance() {
  if (axiosCacheInstance) return axiosCacheInstance;

  // Create `axios` instance with pre-configured `axios-cache-adapter` using a `localforage` store
  axiosCacheInstance = setup({
    // `axios-cache-adapter` options
    cache: {
      maxAge: 24 * 60 * 60 * 1000,
    },
  });
  return axiosCacheInstance;
}
