/*
 * File: labeler-tasks.api.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 22nd May 2023 1:51:14 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { JobService } from "services/label-service";
import { labelerTasksMapper } from "./labeler-tasks.mapper";
import {
  IPagination,
  IPaginationResponse,
  paginationMapper,
} from "domain/common";
import { ILabelerTaskEntity, ILabelerTaskFilter } from "../types";

async function getLabelerTasks(
  filter: ILabelerTaskFilter
): Promise<IPaginationResponse<ILabelerTaskEntity>> {
  const projectId = filter.projectId;
  if (!projectId) {
    const pagination = { page: 1, size: 100, totalCount: 0 };
    return { data: [], pagination };
  }
  const params = labelerTasksMapper.toListRequestParams(filter);
  const response = await JobService.getProjectJobs(params);
  const pagination: IPagination = paginationMapper.fromResponse(response);
  const data = response.data
    .map(labelerTasksMapper.fromDto)
    .map((item: ILabelerTaskEntity) => {
      return { ...item, projectId: parseInt(projectId) };
    });
  return { data, pagination };
}

export const labelerTasksApi = { getLabelerTasks };
