/*
 * File: help-topic.constants.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 22nd November 2021 2:47:50 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export const HELP_TOPICS = [
  {
    topic: "Hiring Process",
    contents: [
      { id: "Registration", name: "Registration" },
      { id: "Entry Test", name: "Entry Test" },
      { id: "Evaluation methods", name: "Evaluation methods" },
    ],
  },

  {
    topic: "Labeling Process",
    contents: [
      { id: "Registration", name: "Registration" },
      { id: "Assignment", name: "Assignment" },
      { id: "Scenarios", name: "Scenarios" },
      { id: "Evaluation", name: "Evaluation" },
      { id: "Payment", name: "Payment" },
    ],
  },

  {
    topic: "Labeling tool",
    contents: [
      { id: "Registration", name: "Registration" },
      { id: "Features", name: "Features" },
      { id: "Feedback", name: "Feedback" },
    ],
  },

  {
    topic: "Atlas",
    contents: [
      {
        id: "Findings/Diseases definition",
        name: "Findings/Diseases definition",
      },
    ],
  },
];
