/*
 * File: auth-interceptor.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { isEnableErrorTracking } from "configs/env.config";
import { AppEvents } from "constants/event.constant";
import { Logger } from "utilities/logger";
import { AuthService } from "../auth";

const DEBUG_ENABLE = false;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers.Authorization = `Bearer ${AuthService.getAccessToken()}`;
  config.headers.Scope = AuthService.getUserScope();
  config.headers.Workspace = AuthService.getUserWorkspace();
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  if (DEBUG_ENABLE) Logger.info(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse) => {
  if (DEBUG_ENABLE) Logger.info(`[response] [${JSON.stringify(response)}]`);
  const headers = { ...response.headers };
  if (response.data.pagination) {
    const { pageNumber, pageSize, totalItem } = response.data.pagination;
    headers["x-total-count"] = totalItem;
    headers["x-page"] = pageNumber;
    headers["x-page-size"] = pageSize;
  }
  return {
    ...response,
    headers,
    data: response.data.payload ? response.data.payload : response.data,
  };
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  if (DEBUG_ENABLE) Logger.info(`[response error] [${JSON.stringify(error)}]`);
  if (isEnableErrorTracking()) {
    const customEvent = new CustomEvent(AppEvents.GLOBAL_ERROR_ADDED, {
      detail: { message: JSON.stringify(error) },
    });
    document.dispatchEvent(customEvent);
  }
  return Promise.reject(error);
};

export function setupAuthInterceptor(
  axiosInstance: AxiosInstance
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
