/*
 * File: params-key.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

export enum ParamsKey {
  DATASET_ID = "datasetId",
  UPLOADED_FILE  = "uploadedFile",
  FILE  = "file",
  FILES  = "files",
  TAG_IDS  = "tagIds"
}
