/*
 * File: exported-datasets.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 1st September 2021 3:07:46 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "store";
import {
  ExportedDatasetDTO,
  ExportedDatasetVersionDTO,
} from "services/storage-service/dto/exported-dataset.dto";

export const selecteExportedDatasets = (
  state: RootState
): ExportedDatasetDTO[] => {
  return state.exportedDatasets.ids.map(
    (id) => state.exportedDatasets.entities[id]
  );
};

export const selectExportedDatasetVersions = (
  state: RootState
): ExportedDatasetVersionDTO[] => {
  const versions = [] as ExportedDatasetVersionDTO[];
  const exportedDatasets = selecteExportedDatasets(state);
  exportedDatasets.forEach((dataset: ExportedDatasetDTO) => {
    if (dataset.versions) {
      dataset.versions.forEach((version: ExportedDatasetVersionDTO) =>
        versions.push(version)
      );
    }
  });
  return versions;
};

export const selectExportedDatasetsRequestStatus = (state: RootState) => {
  return state.exportedDatasets.status;
};

export const selectExportedDatasetsRequestError = (state: RootState) => {
  return state.exportedDatasets.error;
};
