/*
 * File: image-system-label-selected.thunk copy.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 9th November 2022 10:21:25 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ImageWorkspaceState } from "../../image-workspace.state";
import { imageAnnotationUtils } from "../image-annotations.util";

const THUNK_NAME = "image-annotations/imageClassificationLabelSelectedAsync";

interface Payload {
  jobId: number;
  labelId: number;
  selected: boolean;
}

export const imageClassificationLabelSelectedAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: Payload) => {
    return payload;
  }
);

export const imageClassificationLabelSelectedBuilder = (
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) => {
  return builder.addCase(
    imageClassificationLabelSelectedAsync.fulfilled,
    ({ imageAnnotations, imageLabeling }, action) => {
      const { labelId, jobId, selected } = action.payload;
      imageAnnotations.annotations = imageAnnotations.annotations.filter(
        (anno) => {
          return !(anno.jobId === jobId && anno.labelId === labelId);
        }
      );
      if (labelId === -1 || !selected) return;
      // add new system label
      const annotator = imageLabeling.job?.assignee || "";
      const annotation = imageAnnotationUtils.createNewClassificationAnnotation(
        jobId,
        labelId,
        annotator,
        []
      );
      imageAnnotations.annotations.push(annotation);
    }
  );
};
