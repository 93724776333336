/*
 * File: projects.state.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 29th July 2021 3:34:21 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { ProjectDTO } from "services/label-service/dtos";

export interface ProjectsState {
  projects: ProjectDTO[];
  isLoaded: boolean;
  loading: boolean;
  requesting: boolean;
}

export const INITIAL_PROJECTS_STATE: ProjectsState = {
  loading: true,
  requesting: true,
  isLoaded: false,
  projects: [],
};
