import { GridCellParams } from "@material-ui/data-grid";
import { VBButton } from "../vb-button.component";

const ACTION_ICON_MAP: any = {
  "review": "uir-edit",
}

export const VBDatagridCellActions = (params: GridCellParams) => {
  const actions: string[] = params.row.actions;
  const onAction: any = params.row.onAction;
  return (
    <div>
      <div className="flex items-center justify-end">
        {
          actions.map((action) => (
            <VBButton
              key={action}
              onClick={() => onAction && onAction(action, params.row)}
              className="hover:bg-white hover:text-red-500"
            >
              <i className={ACTION_ICON_MAP[action]}></i>
            </VBButton>
          ))
        }

      </div>
    </div>
  )
};
