/*
 * File: create-workspace.page.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:12 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBCreateButton } from "components/common/vb-create-button.component";
import { useKeycloakContext } from "contexts/keycloak/keycloak.context";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { FormEvent, useRef } from "react";
import { GroupDTO } from "services/user-service/dtos/group.dto";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { selectUserWorkspaceLoading } from "store/common/user-workspace/user-workspace.selectors";
import { createWorkspaceAsync } from "store/common/user-workspace/user-workspace.thunk";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { v4 } from "uuid";
import { UserService } from "services/user-service";
import * as Sentry from "@sentry/react";

export const CreateWorkspacePage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const loading = useAppSelector(selectUserWorkspaceLoading);
  const { refreshToken, signOut } = useKeycloakContext();

  const handleSubmit = (e: FormEvent) => {
    if (loading) return;
    e.preventDefault();
    const name = inputRef.current?.value as string;
    if (!name) return;
    createWorkspace(name);
  };

  async function createWorkspace(name: string) {
    try {
      const workspacePayload: GroupDTO = {
        groupId: v4(),
        groupName: name,
        lastSelect: Date.now(),
      };
      const response = await dispatch(createWorkspaceAsync(workspacePayload));
      handleThunkRejected(response);
      try {
        const newCreatedWorkspace: GroupDTO = response.payload;
        if (newCreatedWorkspace) {
          await UserService.selectWorkspace(newCreatedWorkspace.groupId);
        }
      } catch (error) {
        Sentry.captureException(error);
        Logger.log(error);
      }

      dispatch(enqueueSuccessNotification(t("common:textCreatedSuccess")));
      refreshToken();
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || t("common:textCreatedFailed");
      dispatch(enqueueErrorNotification(errMessage));
      Logger.log(errMessage);
    } finally {
    }
  }
  function handleSignOut(event: FormEvent) {
    event.preventDefault();
    signOut();
  }

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen bg-gray-100">
      <div className="w-2/3 p-8 bg-white rounded-lg shadow-xl md:w-1/2 lg:w-1/3 xl:w-1/4">
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <h2 className="my-4 text-2xl font-bold text-center">
            {t("authorization:workspace.textCreateWorkspace")}
          </h2>
          <p className="my-2 text-gray-500">
            {t("authorization:workspace.createWorkspaceContinueMessage")}
          </p>
          <input
            autoFocus
            ref={inputRef}
            required
            type="text"
            placeholder={t("authorization:workspace.inputPlaceholder")}
            className="w-full p-2 my-2 text-lg bg-gray-100 rounded focus:outline-none focus:ring-primary focus:ring-1"
          />
          <VBCreateButton
            text={
              loading
                ? t("common:textPleaseWait")
                : t("authorization:workspace.textCreateWorkspace")
            }
            className="my-2"
          />

          <div className="p-4 text-center text-gray-400">{t("Or")}</div>
          <button onClick={handleSignOut} className="hover:underline">
            {t("authorization:workspace.textSignInDiffAccount")}
          </button>
        </form>
      </div>
    </div>
  );
};
