interface BaseTreeItem {
  id: string;
  parentId?: string;
  children?: BaseTreeItem[];
}
export function buildTree<T extends BaseTreeItem>(
  entities: Record<string, T>
): T[] {
  return Object.keys(entities)
    .filter((key) => !entities[key].parentId)
    .map((key) => buildTreeItem(entities[key], entities));
}

export function buildTreeItem<T extends BaseTreeItem>(
  item: T,
  entities: Record<string, T>
): T {
  const keys = Object.keys(entities);
  const children = [];
  for (const key of keys) {
    const entity = entities[key];
    if (item.id !== entity.parentId) continue;
    const child = buildTreeItem(entities[key], entities);
    children.push(child);
  }
  item.children = children;
  return item;
}

export function buildEntities<T extends BaseTreeItem>(
  items: T[]
): Record<string, T> {
  const entities: Record<string, T> = {};
  for (const item of items) {
    entities[item.id] = item;
  }
  return entities;
}
