/*
 * File: dicom.utils.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 3rd July 2021 5:28:39 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { DicomService } from "../../services/dicom/dicom-service";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import { DicomServiceUtil } from "services/dicom/dicom-service.util";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import * as Sentry from "@sentry/react";

export const extractDicomDataFromUrl = (url: string) => {
  const regex = /(.*)\/studies\/(.*)\/series\/(.*)\/instances\/(.*)/;
  try {
    const matches = regex.exec(url);
    if (matches) {
      const [
        ,
        DicomServiceURL,
        StudyInstanceUID,
        SeriesInstanceUID,
        SOPInstanceUID,
      ] = matches;
      return {
        DicomServiceURL,
        StudyInstanceUID,
        SeriesInstanceUID,
        SOPInstanceUID,
      };
    }
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
};

export const buildFrameURL = (
  StudyInstanceUID: string,
  SeriesInstanceUID: string,
  SOPInstanceUID: string
) => {
  return `wadors:/studies/${StudyInstanceUID}/series/${SeriesInstanceUID}/instances/${SOPInstanceUID}/frames/1`;
};

export function getImageFrameURI(metadataURI: string, metadata: any) {
  // Use the BulkDataURI if present int the metadata
  if (metadata["7FE00010"] && metadata["7FE00010"].BulkDataURI) {
    const url = DicomServiceUtil.buildBuckDataURI(
      metadata["7FE00010"].BulkDataURI
    );
    if (url) return url;
  }

  // fall back to using frame #1
  return metadataURI + "/frames/1";
}

export async function buildDicomImageId(
  StudyInstanceUID: string,
  SeriesInstanceUID: string,
  SOPInstanceUID: string
) {
  const response =
    await DicomService.retrieveMetadataOfASingleInstanceWithinASeriesOfAStudy(
      StudyInstanceUID,
      SeriesInstanceUID,
      SOPInstanceUID
    );
  const metadata = response.data[0];
  const instanceURI = `/studies/${StudyInstanceUID}/series/${SeriesInstanceUID}/instances/${SOPInstanceUID}`;
  const imageFrameURI = getImageFrameURI(instanceURI, metadata);
  const imageId = `wadors:${imageFrameURI}`;
  cornerstoneWADOImageLoader.wadors.metaDataManager.add(imageId, metadata);
  return imageId;
}

export enum DicomDataTag {
  PatientName = "00100010",
  PatientID = "00100020",
  StudyDate = "00080020",
  SeriesDate = "00080021",
  SeriesDescription = "0008103E",
  AcquisitionDate = "00080022",
  StudyTime = "00080030",
  SeriesTime = "00080031",
  Modality = "00080060",
  InstitutionName = "00080080",
  SOPClassUID = "00080016",
  SOPInstanceUID = "00080018",
  StudyInstanceUID = "0020000D",
  SeriesInstanceUID = "0020000E",
  SeriesNumber = "00200011",
  InstanceNumber = "00200013",
  PixelData = "7FE00010",
  ViewPosition = "00185101", // CC, MLO
  Laterality = "00200060", // L(Left), R(Right)
  ImageLaterality = "00200062", // L(Left), R(Right)
}

export const getDicomData = (
  metadata: any,
  tag: DicomDataTag,
  defaultValue: string = ""
) => {
  if (metadata && metadata.hasOwnProperty(tag)) {
    const item = metadata[tag];
    if (item && item.hasOwnProperty("Value") && item["Value"].length > 0) {
      return item["Value"][0];
    }
  }
  return defaultValue;
};

export const getLaterality = (dicomData: any): string => {
  try {
    const values = getDicomTagsData(dicomData, [
      [DicomDataTag.ImageLaterality, DicomDataTag.Laterality],
    ]);
    if (values && values.length > 0) return values[0];
  } catch (error) {Sentry.captureException(error);}
  return "";
};

export const getDicomTagsData = (
  dicomData: any,
  fieldTags: string[][],
  preFix: any = {}
) => {
  try {
    if (!dicomData) return [];
    let tagsData = dicomData;

    if (typeof dicomData === "string") {
      tagsData = JSON.parse(dicomData);
    }

    const res: string[] = [];
    for (const tags of fieldTags) {
      for (const tag of tags) {
        const value = tagsData[tag]?.value[0];
        if (value) {
          const returnedValue = `${!!preFix[tag] ? preFix[tag] : ""}${value}`;
          res.push(returnedValue);
          // just need to get the first tag value for the field
          break;
        }
      }
    }
    return res;
  } catch (error: any) {
    Sentry.captureException(error);
    console.log(error);
    return [];
  }
};

// HARD CODE read tags for breast >.<
export const getBreastDicomTagsDataForSortingViews = (dicomData: any) => {
  return getDicomTagsData(dicomData, [
    [DicomDataTag.ViewPosition],
    [DicomDataTag.Laterality, DicomDataTag.ImageLaterality],
  ]);
};
export const getBreastDicomTagsDataForDisplay = (dicomData: any) => {
  return getDicomTagsData(
    dicomData,
    [
      [DicomDataTag.ViewPosition],
      [DicomDataTag.Laterality, DicomDataTag.ImageLaterality],
      [DicomDataTag.PatientID],
    ],
    {
      [DicomDataTag.PatientID]: "PID: ",
    }
  );
};

export const get3DCTTagsData = (dicomData: any) => {
  return getDicomTagsData(
    dicomData,
    [
      [DicomDataTag.PatientID],
      [DicomDataTag.SeriesDate, DicomDataTag.AcquisitionDate],
      [DicomDataTag.SeriesDescription],
    ],
    {
      [DicomDataTag.PatientID]: "PID: ",
      [DicomDataTag.SeriesDate]: "Date: ",
      [DicomDataTag.AcquisitionDate]: "Date: ",
      [DicomDataTag.SeriesDescription]: "Des: ",
    }
  );
};

export const isFileDicom = (file?: StorageFileDTO) => {
  return file?.mineType === "application/dicom";
};
