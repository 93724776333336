/*
 * File: labeler-projects.api.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 26th April 2022 11:17:38 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { LabelerProject } from "domain/image-labeling";
import { LabelAPI } from "../http/label-service.httpclient";
import { labelerProjectMapper } from "../mappers/labeler-project.mapper";

async function getProjects(): Promise<LabelerProject[]> {
  const res = await LabelAPI.get("/api/v1/labeler/projects");
  return res.data.map(labelerProjectMapper.toEntity);
}

export const labelerProjectsApi = {
  getProjects,
};
