/*
 * File: base.reducer.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 14th August 2021 3:06:41 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { SerializedError } from "@reduxjs/toolkit";
import { AtLeast } from "types/common";
import { BaseEntity, BaseState, RequestStatus } from "./base.state";

export function setEntites<T extends BaseEntity, S extends BaseState<T>>(
  state: S,
  payload: T[]
) {
  state.entities = {};
  state.ids = [];
  for (let entity of payload) {
    state.entities[entity.id] = entity;
    state.ids.push(entity.id);
  }
}

export function addEntity<T extends BaseEntity, S extends BaseState<T>>(
  state: S,
  payload: T,
  addToFront = true,
) {
  if (state.ids.includes(payload.id)) return;
  if (addToFront){
    state.ids.unshift(payload.id);
  } else {
    state.ids.push(payload.id);
  }
  state.entities[payload.id] = payload;
}

export function removeEntity<T extends BaseEntity, S extends BaseState<T>>(
  state: S,
  payload: number
) {
  delete state.entities[payload];
  state.ids = state.ids.filter((id) => id !== payload);
}

export function updateEntity<T extends BaseEntity, S extends BaseState<T>>(
  state: S,
  payload: AtLeast<T, "id">
) {
  const existingEntity = state.entities[payload.id];
  if (!existingEntity) return;
  state.entities[payload.id] = {
    ...existingEntity,
    ...payload,
  };
}

export function requestPending<T extends BaseEntity, S extends BaseState<T>>(
  state: S
) {
  state.error = null;
  state.status = RequestStatus.LOADING;
}

export function requestFulfilled<T extends BaseEntity, S extends BaseState<T>>(
  state: S
) {
  state.error = null;
  state.status = RequestStatus.SUCCESS;
}

export function requestRejected<T extends BaseEntity, S extends BaseState<T>>(
  state: S,
  error: SerializedError
) {
  state.error = error.message || null;
  state.status = RequestStatus.FAILURE;
}
