/*
 * File: base-editor.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 26th July 2021 11:22:15 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  deepCloneViewport,
  Viewport,
} from "../viewport-editor/viewport-editor.models";

export interface BaseEditorInterface {
  getContainer(): HTMLElement;
  getDefaultViewport(): Viewport;
}

export class BaseEditor {
  protected _container: HTMLElement;
  protected _defaultViewport: Viewport;
  constructor(element: HTMLElement, viewport: Viewport) {
    this._container = element;
    this._defaultViewport = deepCloneViewport(viewport);
  }

  getContainer() {
    return this._container;
  }

  getDefaultViewport() {
    return this._defaultViewport;
  }
}
