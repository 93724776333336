/*
 * File: delete-files.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th June 2023 11:41:54 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/use-redux";
import { useState } from "react";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { StorageApi } from "data-access/impl/storage";

interface Props {
  open: boolean;
  datasetIds: number[];
  onClose: (reload?: boolean) => void;
}

export const DeleteDatasourcesModal = ({
  open,
  datasetIds,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [processing, setProcessing] = useState(false);

  async function handleSubmit() {
    if (processing) return;
    setProcessing(true);
    if (datasetIds.length === 0) return;
    let succeedCount = 0;
    let failedCount = 0;
    for (const id of datasetIds) {
      try {
        await StorageApi.deleteDataset(id);
        succeedCount++;
      } catch {
        failedCount++;
      }
    }

    if (succeedCount > 0)
      dispatch(
        enqueueSuccessNotification(
          t("dataset:deletefiles.textDeletedSuccess", { count: succeedCount })
        )
      );
    if (failedCount > 0)
      dispatch(
        enqueueErrorNotification(
          t("dataset:deletefiles.textDeletedFailed", { count: failedCount })
        )
      );
    setProcessing(false);
    onClose(true);
  }
  return (
    <VBModal
      open={open}
      title={t("dataset:deletefiles.title")}
      onClose={() => onClose(false)}
      textSubmit={t("common:buttonOk")}
      onSubmit={handleSubmit}
      disableSubmit={processing}
    >
      <div className="flex flex-col gap-3">
        <p>
          This action can not be undo. Are you sure you want to delete these
          datasources?.
        </p>
      </div>
    </VBModal>
  );
};
