/*
 * File: image-annotation-sources.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 18th October 2022 2:26:14 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useMount } from "ahooks";
import { CornerstoneHandler } from "components/dicom/cornerstone/cornerstone-handler/cornerstone-handler";
import { CornerstoneViewportEditor } from "components/dicom/cornerstone/viewport-editor/viewport-editor";
import { Collection, collectionUtils } from "domain/common";
import { Annotation, Label } from "domain/image-labeling";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";
import { ImageLabelingData } from "domain/labeling/labeling-data";
import { useMemo, useReducer, useRef, useState } from "react";
import annotationsReducer, {
  AnnotationsActionType,
  defaultAnnotationSourcesState,
} from "../reducer/annotations-reducer.hook";
import { ImageAnnotationSourcesContext } from "./image-annotation-sources.context";
import { AnnotationMenuItem } from "./image-annotation-sources.state";

interface Props {
  children: JSX.Element;
  onClose?(): void;
  onSubmit?(annotations: Annotation[], realtions: RelationAnnotation[]): void;
  imageUrl: string;
  labels?: Label[];
  labelingDatas?: ImageLabelingData[];
}

export const ImageAnnotationSourcesProvider = ({
  children,
  imageUrl,
  labels = [],
  labelingDatas = [],
  onClose,
  onSubmit,
}: Props) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const viewportEditor = useRef<CornerstoneViewportEditor>();
  const cornerstoneHandler = useRef<CornerstoneHandler>();
  const [annotationsState, dispatch] = useReducer(
    annotationsReducer,
    defaultAnnotationSourcesState
  );

  useMount(() => {
    dispatch({
      type: AnnotationsActionType.INIT,
      payload: { labels, labelingDatas },
    });
  });

  const imageAnnotations = useMemo(() => {
    const annotations: Annotation[] = [];
    const relationAnnotations: RelationAnnotation[] = [];

    for (const annotationId of annotationsState.annotations.allIds) {
      const annotation = collectionUtils.getOne(
        annotationsState.annotations,
        annotationId
      );
      if (!annotation || !annotation.selected) continue;
      annotations.push(annotation);
    }
    for (const relationId of annotationsState.relations.allIds) {
      const relation = collectionUtils.getOne(
        annotationsState.relations,
        relationId
      );
      if (!relation || !relation.selected) continue;
      relationAnnotations.push(relation);
    }

    return { annotations, relationAnnotations };
  }, [annotationsState]);

  const annotationMenuItems: AnnotationMenuItem[] = useMemo(() => {
    const labels = collectionUtils.values(annotationsState.labels);
    const itemsCollection: Collection<AnnotationMenuItem> = {
      allIds: [],
      entities: {},
    };
    for (const label of labels) {
      const item: AnnotationMenuItem = {
        id: label.id,
        name: label.name,
        hidden: label.hidden,
        selected: label.selected,
        type: "LABEL",
        children: [],
      };
      collectionUtils.addOne(itemsCollection, item);
    }

    for (const annotationId of annotationsState.annotations.allIds) {
      const annotation = collectionUtils.getOne(
        annotationsState.annotations,
        annotationId
      );
      if (!annotation) continue;
      const label = collectionUtils.getOne(itemsCollection, annotation.labelId);
      if (!label) continue;
      label.children?.push({
        id: annotation.id,
        name: annotation.uuid,
        selected: annotation.selected,
        hidden: annotation.hidden,
        children: [],
        type: "ANNOTATION",
      });
    }

    for (const relationId of annotationsState.relations.allIds) {
      const relation = collectionUtils.getOne(
        annotationsState.relations,
        relationId
      );
      if (!relation) continue;
      const label = collectionUtils.getOne(itemsCollection, relation.labelId);
      if (!label) continue;
      label.children?.push({
        id: relation.id,
        name: relation.uuid,
        selected: relation.selected,
        hidden: relation.hidden,
        children: [],
        type: "RELATION",
      });
    }

    return collectionUtils.values(itemsCollection);
  }, [annotationsState]);

  function selectAnnotator(annotatorId: number) {
    dispatch({
      type: AnnotationsActionType.SELECT_ANNOTATOR,
      payload: annotatorId,
    });
  }

  function selectLabel(labelId: number, action?: string) {
    dispatch({
      type: AnnotationsActionType.SELECT_LABEL,
      payload: { id: labelId, action },
    });
  }

  function selectAnnotation(annotationId: number, action?: string) {
    dispatch({
      type: AnnotationsActionType.SELECT_ANNOTATION,
      payload: { id: annotationId, action },
    });
  }

  function handleSubmit() {
    const annotations = [];
    const relations = [];
    for (const id of annotationsState.annotations.allIds) {
      const entity = collectionUtils.getOne(annotationsState.annotations, id);
      if (entity?.selected) annotations.push(entity);
    }
    for (const id of annotationsState.relations.allIds) {
      const entity = collectionUtils.getOne(annotationsState.relations, id);
      if (entity?.selected) relations.push(entity);
    }
    onSubmit && onSubmit(annotations, relations);
    onClose && onClose();
  }

  function handleClose() {
    onClose && onClose();
  }
  const state = {
    imageUrl,
    labels,
    labelingDatas,
    annotators: collectionUtils.values(annotationsState.annotators),
    imageAnnotations,
    annotationMenuItems,
    imageLoaded,
    viewportEditor,
    cornerstoneHandler,
    setImageLoaded,
    onClose: handleClose,
    selectAnnotator,
    selectLabel,
    selectAnnotation,
    onSubmit: handleSubmit,
  };

  return (
    <ImageAnnotationSourcesContext.Provider value={state}>
      {children}
    </ImageAnnotationSourcesContext.Provider>
  );
};
