import { ColumnHeaderWrapper } from "components/common/vb-grid/column-wrapper.component";
import { DateColumnHeader } from "components/common/vb-grid/date-column-header.component";
import { SearchableColumnHeader } from "components/common/vb-grid/searchable-column-header.component";
import { SelectableColumnHeader } from "components/common/vb-grid/selectable-column-header.component";
import { IconArrowDown } from "components/common/vb-icon.component";
import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { VBNumberTextInputComponent } from "components/design-system/number-text-input/number-text-input.component";
import { useAppSelector } from "hooks/use-redux";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectPaymentCurrencySelectOptions } from "store/common/payment/payment.selectors";
import { classnames } from "utilities/classes";
import { formatCurrency } from "utilities/formatter/currency-formatter.utils";
import {
  BillDetailManualLabelingPriceRow,
  BillType,
  ProfileBillFilter,
  ProfileBillRow,
} from "./profile-bills.models";

interface Props {
  rows: ProfileBillRow[];
  filter: ProfileBillFilter;
  setFilterField: (field: keyof ProfileBillFilter, value: any) => void;
  setFilterFields: (values: Partial<ProfileBillFilter>) => void;
  isLoading?: boolean;
}

export const ProfileBillsTable = ({
  rows,
  filter,
  setFilterField,
  setFilterFields,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const currencyOptions = useAppSelector(selectPaymentCurrencySelectOptions);
  const [selectedRow, setSelectedRow] = useState<ProfileBillRow>();

  const serviceNameOptions = [
    {label: "AIScaler fees", value: "AIScaler fees"},
    {label: "Manual labeling", value: "Manual labeling"},
  ];

  const handleClickDropDown = (row: ProfileBillRow) => {
    if (row.id === selectedRow?.id) {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(row);
    }
  };

  return (
    <div className="min-w-full">
      <table className="min-w-full text-sm vb-table">
        <thead>
          <tr>
            <ColumnHeaderWrapper first maxWidth={100}>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={false}
                placeholder={""}
                header={"Id"}
                containerWidth={"100%"}
                defaultValue={filter.id || ""}
                type="text"
                onInputChange={(v) => setFilterField("id", v)}
                sortable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <DateColumnHeader
                containerClassName="w-full py-2"
                header={"Issue date"}
                placeholderText={t("common:formattedDate", {
                  date: new Date(),
                })}
                containerWidth="100%"
                fromDate={filter?.fromDate}
                toDate={filter?.toDate}
                onDateRangeChanged={(fromDate: Date | undefined, toDate: Date | undefined) =>
                  setFilterFields({fromDate, toDate})
                }
                clearable
                selectRange
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper minWidth={150}>
              <SelectableColumnHeader
                className="leading-normal"
                placeholder="All"
                header="Service name"
                containerWidth="100%"
                menuPortalTarget={document.body}
                defaultValue={serviceNameOptions.find(
                  (option) => option.value === filter?.serviceName
                )}
                options={serviceNameOptions}
                onChange={(option: any) => setFilterField("serviceName", option?.value)}
                isClearable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={false}
                placeholder={""}
                header={"Description"}
                containerWidth={"100%"}
                defaultValue={filter.description || ""}
                type="text"
                onInputChange={(v) => setFilterField("description", v)}
                sortable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={false}
                placeholder={""}
                header={"Total price"}
                containerWidth={"100%"}
                defaultValue={filter.totalPrice || ""}
                type="text"
                onInputChange={(v) => setFilterField("totalPrice", v)}
                sortable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper last>
              <SelectableColumnHeader
                className="leading-normal"
                placeholder="All"
                header="Currency"
                containerWidth="100%"
                menuPortalTarget={document.body}
                defaultValue={currencyOptions.find(
                  (option) => option.value === filter?.currencyId
                )}
                options={currencyOptions}
                onChange={(option: any) => {
                  if (option) {
                    setFilterField("currencyId", option.value);
                  } else {
                    setFilterField("currencyId", undefined);
                  }
                }}
                isClearable
              />
            </ColumnHeaderWrapper>
          </tr>
        </thead>
        <tbody className="relative">
          {!isLoading && rows.map((row) => {
            const billDetail = row.detail;

            return (
              <Fragment key={row.id}>
                <tr className="relative h-10 hover:bg-secondary-50 parent">
                  <td className="pl-4 pr-4" style={{ maxWidth: 100 }}>
                    {row.id}
                  </td>
                  <td className="pr-4" style={{ minWidth: 100 }}>
                    {t("common:formattedDate", { date: row.issueDate })}
                  </td>
                  <td className="pr-4" style={{ minWidth: 100 }}>
                    {row.serviceName}
                  </td>
                  <td className="pr-4" style={{ minWidth: 200 }}>
                    {row.description}
                  </td>
                  <td className="pr-4" style={{ minWidth: 200 }}>
                    {formatCurrency(row.totalPrice, row.currencyCode)}
                  </td>
                  <td className="pr-4" style={{ minWidth: 200 }}>
                    {row.currencyCode}
                  </td>
                  <td
                    className="absolute h-full transform -translate-x-full -translate-y-1/2 top-1/2 child-on-hover"
                    style={{ zIndex: "999" }}
                  >
                    <div className="flex flex-row items-center h-full gap-3 px-3 text-primary bg-secondary-50">
                      <button>
                        <IconArrowDown
                          onClick={(_) => handleClickDropDown(row)}
                          className={classnames("w-5 h-5 rotate-45", {
                            "transform rotate-180": selectedRow?.id === row.id,
                          })}
                        />
                      </button>
                    </div>
                  </td>
                </tr>
                {selectedRow?.id === row.id && (
                  <tr>
                    <td colSpan={6} className="p-4">
                      {
                        <div className="flex flex-col gap-2">
                          <div className="flex gap-4">
                            <div className="flex gap-2">
                              <span>Batch id: </span>
                              <span>{billDetail.batchId}</span>
                            </div>
                            <div className="flex gap-2">
                              <span>Batch name: </span>
                              <span>{billDetail.batchName}</span>
                            </div>
                          </div>
                          <div className="flex gap-4">
                            <div className="flex gap-2">
                              <span>Start date: </span>
                              <span>
                                {t("common:formattedDate", {
                                  date: billDetail.startDate,
                                })}
                              </span>
                            </div>
                            <div className="flex gap-2">
                              <span>End date: </span>
                              <span>
                                {t("common:formattedDate", {
                                  date: billDetail.endDate,
                                })}
                              </span>
                            </div>
                          </div>
                          <div className="flex gap-2">
                            <span>Total price: </span>
                            <span className="font-bold">
                              {formatCurrency(
                                row.totalPrice,
                                row.currencyCode
                              )}
                            </span>
                          </div>
                        </div>
                      }
                      {row.type === BillType.FEE && (
                        <div className="flex gap-2 mt-2">
                          <span>AIScaler fee: </span>
                          <span className="font-bold">
                            {formatCurrency(
                              billDetail.fee || 0,
                              row.currencyCode
                            )}
                          </span>
                        </div>
                      )}
                      {row.type === BillType.MANUAL_LABELING && (
                        <div className="flex flex-col gap-2 mt-2">
                          <div className="overflow-x-scroll">
                            <p>Price units:</p>
                            <PriceRows
                              priceRows={billDetail.priceRows || []}
                              currencyId={row.currencyId}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
          {
            isLoading &&
            <tr>
              <td colSpan={6}>
                <VBInlineLoading />
              </td>
            </tr>
          }
          <tr className="absolute top-0 left-0 w-full h-full border rounded pointer-events-none border-background-300"></tr>
        </tbody>
      </table>
    </div>
  );
};

interface PriceRowsProps {
  priceRows: BillDetailManualLabelingPriceRow[];
  currencyId: number;
}
export const PriceRows = ({
  priceRows,
  currencyId,
}: PriceRowsProps) => {
  const currencyOptions = useAppSelector(selectPaymentCurrencySelectOptions);
  return (
    <div className="flex flex-col gap-2 mt-2">
      {
        priceRows.map((priceRow, index) => {
          return (
              <div className="flex gap-4" key={index}>
                <div className="flex-none w-20">
                  <VBNumberTextInputComponent
                    label="Step"
                    defaultValue={index + 1}
                    disabled
                  />
                </div>
                <div className="flex-none w-20">
                  <VBNumberTextInputComponent
                    label="Total"
                    defaultValue={priceRow.numUnit}
                    disabled
                  />
                </div>
                <div className="flex-none w-52">
                  <VBNumberTextInputComponent
                    label="Per unit"
                    defaultValue={priceRow.perUnit}
                    selectedUnit={currencyOptions.find(
                      (option) => option.value === currencyId
                    )}
                    unitOptions={currencyOptions}
                    min={0}
                    disabled
                  />
                </div>
            </div>
          )
        })
      }
    </div>
  )
}