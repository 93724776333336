/*
 * File: three-d-apply-confirm-modal.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 10th March 2023 10:58:10 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VBModal } from "components/common/vb-modal/vb-modal.component";

interface ThreeDApplyConfirmModalProps {
  onClose(): void;
  onSubmit(): void;
}
export function ThreeDApplyConfirmModal({
  onClose,
  onSubmit,
}: ThreeDApplyConfirmModalProps) {
  return (
    <VBModal
      open
      title="Apply annotations"
      onClose={onClose}
      textSubmit="Apply"
      onSubmit={onSubmit}
    >
      <p>
        You are selecting "previous result" that mean your current results will
        be deleted. Are you sure?
      </p>
    </VBModal>
  );
}
