import { useAppSelector } from "hooks/use-redux";
import { Fragment } from "react";
import { selectComplexJobsBatchJobs } from "store/labeler/complex-jobs/complex-jobs.selectors";
import { classnames } from "utilities/classes";
import { JobInBatchComponent } from "../speech-to-text-labeling/components/job-in-batch.component";


export const PathologyJobsNav = () => {
  const batchJobs = useAppSelector(selectComplexJobsBatchJobs);

  return (
    <Fragment>
      <div
        className={classnames(
          "h-full gap-2 overflow-y-auto border-r border-gray-600 bg-background-900 relative",
        )}
      >
        <div className="p-2">
          {batchJobs.map((batchJob, index) => {
            return (
              <JobInBatchComponent
                key={batchJob.id}
                index={index + 1}
                jobId={batchJob.id}
                status={batchJob.status}
                fileName={batchJob.loadJobComplexRes?.file?.fileName || ""}
              />
            );
          })}
        </div>
      </div>
      
    </Fragment>
  );
};
