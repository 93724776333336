/*
 * File: use-notification-token.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 16th September 2021 9:02:32 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppSelector } from "hooks/use-redux";
import { useEffect } from "react";
import { NotificationServiceImpl } from "services/notification-service";
import { selectCurrentWorkspaceId } from "store/common/user-workspace/user-workspace.selectors";

export const useNotificationToken = (token: string) => {
  const workspaceId = useAppSelector(selectCurrentWorkspaceId);
  useEffect(() => {
    const loadData = async () => {
      if (token && workspaceId) {
        await NotificationServiceImpl.getNotificationService().subscribeToken(
          token
        );
      }
    };
    loadData();
  }, [token, workspaceId]);
};
