/*
 * File: project-label.page.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBCreateButton } from "components/common/vb-create-button.component";
import { VBPageTitle } from "components/common/vb-page-title.component";
import { VBSpacer } from "components/common/vb-spacer.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { ObservationDTO } from "services/label-service/dtos";
import {
  selectCurrentProject,
  selectProjectObservations,
  selectObservations,
  selectProjectObservationAnnotationTypes,
} from "store/customer/project/project.selectors";
import {
  loadProjectObservationsAsync,
} from "store/customer/project/project.slice";
import { ArchiveLabelModal } from "./components/archive-label.modal";
import { CreateEditLabelModal } from "./components/create-edit-label.modal";
import { ReactiveLabelModal } from "./components/reactive-label.modal";
import { ProjectLabelTree } from "./components/project-label-tree.component";
import { useBuildObservationTree } from "hooks/use-observation-tree";
import { usePrevious } from "ahooks";
import { ObservationService } from "services/label-service";
import { ObservationTreeNodeModel } from "services/label-service/model/observation-tree-node.model";
import { downloadObjectAsJsonFile } from "services/common/common.apis";
import { enqueueErrorNotification, enqueueSuccessNotification } from "store/common/notification/notification.actions";
import { Fragment } from "react";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { IconDirectDownload, IconDirectUpload, IconTrash } from "components/common/vb-icon.component";
import * as Sentry from "@sentry/react";

export const ProjectLabelPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const observations = useAppSelector(selectProjectObservations);
  const observationsWithoutSystem = useAppSelector(selectObservations(true));
  const {nodesList, findAllNodeChildrenValues} = useBuildObservationTree(observations);
  const previousNodeList = usePrevious(nodesList);
  const project = useAppSelector(selectCurrentProject);
  const [currentObservation, setCurrentObservation] = useState<ObservationDTO | null>(null);
  const [archivingNode, setArchivingNode] = useState<ObservationTreeNodeModel<ObservationDTO>  | null>(null);
  const archivingObsChildren = useMemo(() => {
    if (!archivingNode){
      return [];
    } else {
      return findAllNodeChildrenValues(archivingNode.id);
    }
  }, [archivingNode, findAllNodeChildrenValues]);
  const [reactiveObservation, setReactiveObservation] = useState<ObservationDTO | null>(null);
  const projectObsAnnotationTypes = useAppSelector(
    selectProjectObservationAnnotationTypes
  );

  const [isShowImportDialog, setIsShowImportDialog] = useState(false);
  const [fileToImport, setFileToImport] = useState<File>();
  const [isProcessing, setIsProcessing] = useState(false);

  function onClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  function handleSelect(observation: ObservationDTO, node: ObservationTreeNodeModel<ObservationDTO>, action?: string) {
    if (action === "active") {
      return setReactiveObservation(observation);
    }
    if (action === "archive") {
      return setArchivingNode(node);
    }
    if (action === "edit") {
      return setCurrentObservation(observation);
    }
  }

  function handleEditLabelClose() {
    setCurrentObservation(null);
  }

  function handleEditLabelSaved() {
    if (project){
      dispatch(loadProjectObservationsAsync(project.id));
    }
  }

  async function handleArchived() {
    if (!project) return;
    await dispatch(loadProjectObservationsAsync(project.id));
  }

  useEffect(() => {
    if (!nodesList || !previousNodeList || previousNodeList.length <= 0) return;
    if (nodesList.length === previousNodeList?.length) return;
    // new observation created case
    // update order to the server
    const obs: ObservationDTO[] = [];
    for (let node of nodesList){
      if (node.value){
        obs.push(node.value);
      }
    }
    // no need to await here, we async update priority
    ObservationService.partialUpdateBulkWithGenPriority(obs);
  }, [nodesList, previousNodeList])

  useEffect(() => {
    const _loadData = async (id: number) => {
      try {
        await dispatch(loadProjectObservationsAsync(id));
      } catch (err) {
        Sentry.captureException(err);
        console.log(err);
      }
    };
    if (project) _loadData(project.id);
  }, [dispatch, project]);

  const handleExportLabels = async () => {
    if (isProcessing || !project) return;
    try {
      setIsProcessing(true);

      const res = await ObservationService.exportObservations(project.id);
      downloadObjectAsJsonFile("labels.json", res.data);

    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsProcessing(false);
    }
  }

  const handleSubmitImport = async () => {
    if (isProcessing || !project || !fileToImport) return;
    try {
      setIsProcessing(true);

      await ObservationService.importObservations(project.id, fileToImport);
      
      dispatch(loadProjectObservationsAsync(project.id));
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      setIsShowImportDialog(false);
      setFileToImport(undefined);
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsProcessing(false);
    }
  }

  const handleClickImport = () => {
    setIsShowImportDialog(true);
  }

  const hanldeImportFileChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    const newFiles: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const newFile = fileList.item(i);
      if (!newFile || newFile?.size > 1024 * 1024 * 5) {
        dispatch(enqueueErrorNotification(t("common:textErrorFileLarge")));
        return;
      }
      newFiles.push(newFile);
    }

    if (newFiles.length <= 0) return;
    setFileToImport(newFiles[0]);
  }

  return (
    <Fragment>
      <div className="flex flex-col h-full">
        <div className="flex items-center my-4 gap-2">
          <VBPageTitle text={t("project:label.pageTitle")} />
          <VBSpacer />

          <button
            className="button-secondary disabled:opacity-50"
            onClick={handleClickImport}
            disabled={isProcessing}
          >
            <span>{t("project:label.buttonImportLabel")}</span>
            <IconDirectUpload className="flex-none w-5 h-5" />
          </button>

          <button
            className="button-secondary disabled:opacity-50"
            onClick={handleExportLabels}
            disabled={isProcessing}
          >
            <span>{t("project:label.buttonExportLabel")}</span>
            <IconDirectDownload className="flex-none w-5 h-5" />
          </button>

          <VBCreateButton
            text={t("project:label.buttonCreateLabel")}
            onClick={onClickOpen}
          />
        </div>

        <ProjectLabelTree labels={observations} onSelect={handleSelect} />

        <CreateEditLabelModal
          visible={open}
          onClose={handleClose}
          observations={observationsWithoutSystem}
          project={project}
          projectObsAnnotationTypes={projectObsAnnotationTypes}
        />

        <CreateEditLabelModal
          isEdit
          visible={!!currentObservation}
          observationToEdit={currentObservation}
          onClose={handleEditLabelClose}
          onSubmitted={handleEditLabelSaved}
          observations={observationsWithoutSystem}
          project={project}
          projectObsAnnotationTypes={projectObsAnnotationTypes}
        />
        
        <ArchiveLabelModal
          archivingNode={archivingNode}
          childrenValues={archivingObsChildren}
          onClose={() => setArchivingNode(null)}
          onArchived={handleArchived}
        />

        <ReactiveLabelModal
          observation={reactiveObservation}
          onClose={() => setReactiveObservation(null)}
        />
      </div>

      <VBModal
        open={isShowImportDialog}
        title={"Import labels"}
        onClose={() => setIsShowImportDialog(false)}
        textSubmit={t("common:buttonImport")}
        onSubmit={() => handleSubmitImport()}
        disableSubmit={!fileToImport || isProcessing}
      >
        <Fragment>
          {
            fileToImport &&
            <div className="flex gap-2 items-center">
              <span className="text-primary">{fileToImport.name}</span>
              <IconTrash
                className="text-error-300 cursor-pointer"
                onClick={() => setFileToImport(undefined)}
              />
            </div>
          }
          {
            !fileToImport &&
            <div className="h-40 border border-dotted rounded relative flex items-center justify-center">
              <span className="text-primary cursor-pointer">Choose file</span>
              <input
                className="w-full h-full opacity-0 absolute cursor-pointer"
                type="file"
                accept="application/json"
                onChange={hanldeImportFileChanged}
                onClick={(e: any) => {
                  e.target.value = null;
                }}
              />
            </div>
          }
        </Fragment>
      </VBModal>
    </Fragment>
  );
};
