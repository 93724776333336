/*
 * File: generate-annotation-modal.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 16th February 2022 5:29:09 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  IconArrowRightLinear,
  IconCloseCircle,
} from "components/common/vb-icon.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  selectBatchInfo,
  selectBatchLabels,
} from "store/customer/batch/batch.selectors";
import { randomInt } from "utilities/formatter/number-formatter.utils";
import { GenerateAnnotationRequest } from "services/label-service/dtos/annotation.dto";
import { BatchService } from "services/label-service";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { loadBatchGenAnnoAsyncJobStatusAsync } from "store/customer/batch/batch.thunk";
import { StorageKeys } from "hooks/storage/storage-keys";
import useAIModels from "pages/customer/ai-models/hooks/use-ai-models.hook";
import { AIModel } from "domain/customer/ai-model";
import { useAppContext } from "contexts/app/app.context";
import { useBatchDetailContext } from "../../../context/batch-detail.context";
import ConfigService from "configs/app-config";
import * as Sentry from "@sentry/react";

interface Props {
  onClose(): void;
}

export function GenerateAnnotationModal({ onClose }: Props) {
  const { appConfig } = useAppContext();
  const { project } = useBatchDetailContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { aiModels } = useAIModels(
    (model) => model.status?.toLowerCase() === "successed"
  );
  const models = useMemo(() => {
    const types = ConfigService.getSupportedInferenceTypes(
      appConfig,
      project?.type
    );
    return aiModels.filter((item) => types.includes(item.inferenceType));
  }, [aiModels, appConfig, project]);

  const [selectedModel, setSelectedModel] = useState<AIModel | null>(() => {
    if (models.length > 0) return models[0];
    return null;
  });

  const batchLabels = useAppSelector(selectBatchLabels);
  const batchInfo = useAppSelector(selectBatchInfo);
  const [labelPairs, setLabelPairs] = useState<any>([]);

  const [isProcessing, setIsProcessing] = useState(false);
  const enabled = useMemo(() => {
    if (labelPairs.length === 0) return false;
    if (!labelPairs.find((pair: any) => !!pair.labelAI && !!pair.labelUser)) {
      return false;
    }
    return !isProcessing && !!selectedModel;
  }, [labelPairs, isProcessing, selectedModel]);

  const aiModelsOptions = useMemo(
    () =>
      models.map((aiModel) => ({
        label: aiModel.name,
        value: aiModel.id,
        metadata: aiModel,
      })),
    [models]
  );

  const aiLabelsOptions = useMemo(() => {
    if (!selectedModel?.metadata) return [];
    const options = selectedModel.metadata.classes.map((item) => {
      return {
        label: item.name,
        value: item.id,
        metadata: item,
      };
    });

    return options.filter((option: any) => {
      return labelPairs.every(
        (pair: any) => !pair.labelAI || pair.labelAI.id !== option.value
      );
    });
  }, [selectedModel, labelPairs]);

  const batchLabelsOptions = useMemo(() => {
    return batchLabels
      .filter((label) => {
        return !label.observation.observationSetting.systemAttribute;
      })
      .map((label) => ({
        label: label.observation.name,
        value: label,
      }));
  }, [batchLabels]);

  async function handleGenerate() {
    if (!batchInfo || !selectedModel) return;
    try {
      setIsProcessing(true);

      const payload: GenerateAnnotationRequest = {
        modelId: selectedModel.id.toString(),
        batchId: batchInfo.id,
        labelMapping: labelPairs.map((pair: any) => ({
          name: pair.labelAI.name,
          label: {
            id: pair.labelUser.observation.id,
            name: pair.labelUser.observation.name,
          },
        })),
      };
      const res = await BatchService.annotateBatch(batchInfo.id, payload);

      handleThunkRejected(res);
      const storageKey = `${StorageKeys.DISMISS_GEN_ANNO_BATCH_PREFIX}${batchInfo.id}`;
      localStorage.removeItem(storageKey);
      dispatch(loadBatchGenAnnoAsyncJobStatusAsync(batchInfo.id));
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      onClose && onClose();
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsProcessing(false);
    }
  }

  function handleAddPairLabel() {
    setLabelPairs((labelPairs: any) => [
      ...labelPairs,
      {
        id: randomInt(0, 1000000),
        labelAI: null,
        labelUser: null,
      },
    ]);
  }

  function removeLabelPair(id: number) {
    setLabelPairs((labelPairs: any) =>
      labelPairs.filter((labelPair: any) => labelPair.id !== id)
    );
  }

  function handleAILabelChange(aiLabel: any, labelPairId: number) {
    setLabelPairs((labelPairs: any) =>
      labelPairs.map((labelPair: any) => {
        if (labelPair.id === labelPairId) {
          return {
            ...labelPair,
            labelAI: aiLabel,
          };
        }
        return labelPair;
      })
    );
  }

  function handleUserLabelChange(batchLabel: any, labelPairId: number) {
    setLabelPairs((labelPairs: any) =>
      labelPairs.map((labelPair: any) => {
        if (labelPair.id === labelPairId) {
          return {
            ...labelPair,
            labelUser: batchLabel,
          };
        }
        return labelPair;
      })
    );
  }

  return (
    <VBModal
      width="44.5rem"
      open
      title={t("project:batchDetails.batchexport.generate.title")}
      textSubmit={t("project:batchDetails.batchexport.generate.generate")}
      onClose={onClose}
      onSubmit={handleGenerate}
      disableSubmit={!enabled}
    >
      <div className="flex flex-col w-full gap-4">
        <div className="flex flex-row justify-center flex-1 w-full gap-8">
          <div className="flex-1">
            <VBSelectComponent
              required
              header={t("project:batchDetails.batchexport.generate.modelName")}
              className="flex-1"
              onChange={(option: any) => setSelectedModel(option.metadata)}
              options={aiModelsOptions}
              menuPortalTarget={document.body}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 p-4 bg-background-50">
          <div className="text-lg">
            {t("project:batchDetails.batchexport.generate.addPairLabel")}
          </div>
          {labelPairs.map((labelPair: any) => (
            <PairLabel
              key={labelPair.id}
              batchLabelsOptions={batchLabelsOptions}
              aiLabelsOptions={aiLabelsOptions}
              onAILabelChange={(aiLabel) =>
                handleAILabelChange(aiLabel, labelPair.id)
              }
              onUserLabelChange={(batchLabel) =>
                handleUserLabelChange(batchLabel, labelPair.id)
              }
              onClose={() => removeLabelPair(labelPair.id)}
            />
          ))}
          <div className="w-full">
            <button
              className="w-full h-12 p-4 border-dashed button-secondary"
              onClick={handleAddPairLabel}
            >
              {t("project:batchDetails.batchexport.generate.addMorePairLabel")}
            </button>
          </div>
        </div>
      </div>
    </VBModal>
  );
}

interface PairLabelProps {
  batchLabelsOptions: any;
  aiLabelsOptions: any;
  onAILabelChange?(aiLabel: any): void;
  onUserLabelChange?(batchLabel: any): void;
  onClose?(): void;
}

const PairLabel = ({
  batchLabelsOptions,
  aiLabelsOptions,
  onAILabelChange,
  onUserLabelChange,
  onClose,
}: PairLabelProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row justify-center flex-1 w-full gap-4">
      <div className="flex-1">
        <VBSelectComponent
          size="xl"
          options={aiLabelsOptions}
          header={t("project:batchDetails.batchexport.generate.AILabel")}
          className="flex-1"
          onChange={(option: any) => onAILabelChange?.(option.metadata)}
          menuPortalTarget={document.body}
        />
      </div>
      <div className="flex items-end justify-center p-4 w-14">
        <IconArrowRightLinear className="w-6 h-6 text-primary" />
      </div>
      <div className="flex-1">
        <VBSelectComponent
          size="xl"
          options={batchLabelsOptions}
          header={t("project:batchDetails.batchexport.generate.yourLabel")}
          className="flex-1"
          onChange={(option: any) => onUserLabelChange?.(option.value)}
          menuPortalTarget={document.body}
        />
      </div>
      <div className="flex items-end justify-center p-4 w-14">
        <IconCloseCircle
          className="w-6 h-6 cursor-pointer text-error-500"
          onClick={() => onClose && onClose()}
        />
      </div>
    </div>
  );
};
