export const VTK_OBJECT_TAG_EDITOR = "1_editor";
export const VTK_OBJECT_TAG_IMAGE_DATA = "2_imageData";


let vtkObjects = {};
// In case we don't want to release the tags immediately 
// we can put them in to this queue for later releasing to make sure the release ordering
// For example: When finishing jobs we want to release imageData after the editor objects
let tagsToReleseQueue = [];


export const addToVTKObjects = (objects, isMap = false, tag=VTK_OBJECT_TAG_EDITOR) => {
  if (!vtkObjects[tag]){
    vtkObjects[tag] = [];
  }
  const objectList = vtkObjects[tag];
  if (isMap) {
    for (const key of Object.keys(objects)){
      const object = objects[key];
      objectList.unshift(object);
    }
    return;
  }
  if (objects instanceof Array){
    vtkObjects[tag] = objects.concat(objectList);
  } else {
    objectList.unshift(objects);
  }
}

export const releaseVTKObjects = (tag=VTK_OBJECT_TAG_EDITOR) => {
  if (!vtkObjects[tag]) return;
  let deleteCount = 0;
  for (const object of vtkObjects[tag]) {
    if (object && !object.isDeleted()){
      object.delete();
      deleteCount++;
    }
  }
  console.log(`Release ${deleteCount} vtk objects with tag ${tag}.`)
  delete vtkObjects[tag];
}

export const releaseAllVTKObjects = () => {
  const tags = Object.keys(vtkObjects);
  tags.sort();
  for (const tag of tags) {
    releaseVTKObjects(tag);
  }
}

export const addTagToReleaseQueue = (tag) => {
  tagsToReleseQueue.push(tag);
}

export const releaseVTKObjectsInTagsToReleaseQueue = () => {
  for (const tag of tagsToReleseQueue) {
    releaseVTKObjects(tag);
  }
  tagsToReleseQueue = [];
}
