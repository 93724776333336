import { Tooltip } from "@material-ui/core";
import { useMount } from "ahooks";
import classNames from "classnames";
import {
  IconFilter,
  IconFilterActive,
  IconGrid,
  IconMenu,
} from "components/common/vb-icon.component";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { BatchStatus } from "services/label-service/dtos";
import { classnames } from "utilities/classes";
import { useBatchDetailContext } from "../../context/batch-detail.context";
import { DownloadDatasetModal } from "../export/components/download-dataset.component";
import { ExportActions } from "../export/components/export-actions.component";
import { GenerateAnnotationModal } from "../export/components/generate-annotation-modal.component";
import { ImportAnnotationModal } from "../export/components/import-annotation-modal.component";
import {
  TaskFilter,
  TaskViewMode,
  TASK_VIEW_MODES,
  useBatchTaskContext,
} from "../tasks/batch-tasks.context";
import { BatchTasksPage } from "../tasks/batch-tasks.page";
import { useBatchDataContext } from "./batch-data.provider";
import { TaskFiltersComponent } from "./components/task-filters.component";

export const BatchDataPage = () => {
  const { t } = useTranslation();
  const { batch } = useBatchDetailContext();
  const {
    filter,
    showSelect,
    allowReopen,
    canReopenTasks,
    setOpenReopenDialog,
    setOpenReleaseDialog,
    applyTaskFilters,
    taskFiltersVisible,
    setTaskFiltersVisible,
    isShowHasFilter,
    viewMode,
    setViewMode,
    hasSelectedTasks,
    setTaskRowSelectedAll,
    reloadTaskAsync,
  } = useBatchTaskContext();

  const viewModeIcons = {
    [TaskViewMode.GRID]: (active?: boolean) => {
      return (
        <Tooltip title="Grid">
          <button
            className={classNames("flex items-center gap-2 px-1", {
              "text-primary": active,
            })}
            onClick={() => setViewMode(TaskViewMode.GRID)}
          >
            <IconGrid className="flex-none w-4 h-4" />
          </button>
        </Tooltip>
      );
    },
    [TaskViewMode.LIST]: (active?: boolean) => {
      return (
        <Tooltip title="List">
          <button
            className={classNames("flex items-center gap-2 px-1", {
              "text-primary": active,
            })}
            onClick={() => setViewMode(TaskViewMode.LIST)}
          >
            <IconMenu className="flex-none w-4 h-4" />
          </button>
        </Tooltip>
      );
    },
  };

  const {
    downloadingBatch,
    downloadDataset,
    setExportDataset,
    setDownloadDataset,
    importAnnotationOption,
    setImportAnnotationOption,

    handleSelectImportAnnotation,
    handleDownloadDataset,
  } = useBatchDataContext();

  const handleClickTaskFilters = () => {
    setTaskFiltersVisible(!taskFiltersVisible);
  };

  const handleFilterClear = () => {
    applyTaskFilters({ batchId: batch.id });
    setTaskFiltersVisible(false);
  };

  const handleFilterConfirm = (filter: TaskFilter) => {
    applyTaskFilters(filter);
    setTaskFiltersVisible(false);
  };

  useMount(() => {
    reloadTaskAsync();
  });

  return (
    <div className="flex flex-col w-full h-full gap-8">
      <div className="relative flex items-center gap-4 py-0">
        <button
          className={classnames(
            { "button-secondary": !isShowHasFilter },
            { "button-primary": isShowHasFilter }
          )}
          onClick={handleClickTaskFilters}
        >
          <span className="text-sm font-semibold">{t("common:buttonFilter")}</span>
          {isShowHasFilter && (
            <IconFilterActive className="flex-none w-5 h-5" />
          )}
          {!isShowHasFilter && <IconFilter className="flex-none w-5 h-5" />}
        </button>

        <TaskFiltersComponent
          visible={taskFiltersVisible}
          onClear={handleFilterClear}
          onConfirm={handleFilterConfirm}
          defaultFilter={filter}
        />

        <ExportActions
          batchStatus={batch.status as BatchStatus}
          onExportDataset={() => setExportDataset(true)}
          onDownloadDataset={() => setDownloadDataset(true)}
          onImportAnnotation={handleSelectImportAnnotation}
          disableDownload={!!downloadingBatch}
          disabledExport
        />

        <div className="flex-auto"></div>

        {showSelect && allowReopen && (
          <button
            className={classnames("button-secondary disabled:opacity-60", {
              "cursor-not-allowed": !canReopenTasks,
            })}
            disabled={!canReopenTasks}
            onClick={(_) => setOpenReopenDialog(true)}
          >
            <span className="text-sm font-semibold">{t("common:buttonReopen")}</span>
          </button>
        )}
        {showSelect && (
          <button
            className="button-secondary disabled:opacity-60"
            onClick={(_) => setOpenReleaseDialog(true)}
          >
            <span className="text-sm font-semibold">{t("common:buttonRelease")}</span>
          </button>
        )}

        {!hasSelectedTasks && (
          <button
            className="button-secondary w-28"
            onClick={(_) => setTaskRowSelectedAll(true)}
          >
            <span className="text-sm font-semibold">{t("common:buttonSelectAll")}</span>
          </button>
        )}
        {hasSelectedTasks && (
          <button
            className="button-secondary w-28"
            onClick={(_) => setTaskRowSelectedAll(false)}
          >
            <span className="text-sm font-semibold"> {t("common:buttonDeselectAll")}</span>
          </button>
        )}

        <div className="flex items-center px-2 border rounded border-primary h-9">
          {TASK_VIEW_MODES.map((vm) => {
            return (
              <Fragment key={vm}>
                {vm === viewMode && viewModeIcons[vm](true)}
                {vm !== viewMode && viewModeIcons[vm](false)}
              </Fragment>
            );
          })}
        </div>
      </div>

      {downloadDataset && (
        <DownloadDatasetModal
          onSubmit={handleDownloadDataset}
          onClose={() => setDownloadDataset(false)}
        />
      )}
      {importAnnotationOption && importAnnotationOption !== "MODEL" && (
        <ImportAnnotationModal
          onClose={() => setImportAnnotationOption("")}
          option={importAnnotationOption}
        />
      )}
      {importAnnotationOption && importAnnotationOption === "MODEL" && (
        <GenerateAnnotationModal
          onClose={() => setImportAnnotationOption("")}
        />
      )}

      <div className="flex-auto">
        <BatchTasksPage />
      </div>
    </div>
  );
};
