import {
  ObservationAnnotationType,
  ObservationDTO,
} from "services/label-service/dtos";
import {
  AnnotationRelationResponseDTO,
  AnnotationResponseDTO,
  WsiAnnotationItem,
  WsiAnnotationItemType,
} from "services/label-service/dtos/annotations.dto";
import {
  PathologyAnnotationAdditionalData,
  PathologyAnnotationEdge,
  PathologyEditorLabel,
} from "../components/pathology-editor.models";
import { fabric } from "fabric";
import {
  createLineFromPoints,
  createPolygonFromPoints,
  createRectangle,
  updateObjectAdditionalData,
} from "../components/fabric/fabric-object.utils";
import { FabricObjectToolType } from "../components/fabric/fabric.models";

export const obsAnnoTypeToFabricObjectToolType = (
  type: string
): FabricObjectToolType => {
  if (type === ObservationAnnotationType.POLYGON) {
    return FabricObjectToolType.DRAWN_POLYGON;
  }
  if (type === ObservationAnnotationType.POLYGON) {
    return FabricObjectToolType.DRAWN_POLYGON;
  }
  if (type === ObservationAnnotationType.BBOX) {
    return FabricObjectToolType.DRAWN_RECTANGLE;
  }
  if (type === ObservationAnnotationType.LINE) {
    return FabricObjectToolType.DRAWN_LINE;
  }

  return FabricObjectToolType.NONE;
};

export const observationToPathologyEditorLabel = (
  observation: ObservationDTO
): PathologyEditorLabel => {
  return {
    id: observation.id,
    name: observation.name,
    color: observation.observationSetting.color,
    annotationType: obsAnnoTypeToFabricObjectToolType(
      observation.observationSetting.annotationType
    ),
  };
};

export const annoResponseToFabricObject = (
  annoResponse: AnnotationResponseDTO,
  observations: ObservationDTO[]
): fabric.Object | undefined => {
  const labelId = annoResponse.observationId;
  if (!labelId) return undefined;
  const label = observations.find((obs) => obs.id === labelId);
  if (!label) return undefined;

  const anno = annoResponse.annotation as WsiAnnotationItem;
  // TODO: support source later
  const addData: PathologyAnnotationAdditionalData = {
    labelId: label.id,
    labelName: label.name,
    labelColor: label.observationSetting.color,
    labeler: annoResponse.assignee,
    serverId: annoResponse.id, // useful when building the hierarchy tree
  };

  if (anno.type === WsiAnnotationItemType.polygon) {
    if (!anno.points || anno.points.length < 3) return undefined;
    // We will transform to fabric absolute coordinate system later
    const fabricPolygon: fabric.Polygon = createPolygonFromPoints({
      points: anno.points.map((p) => new fabric.Point(p.x, p.y)),
      otherProps: { selectable: false },
    });
    updateObjectAdditionalData(fabricPolygon, addData);
    return fabricPolygon;
  }

  if (anno.type === WsiAnnotationItemType.bbox) {
    if (!anno.points || anno.points.length < 4) return undefined;
    // We will transform to fabric absolute coordinate system later
    const fabricRectangle: fabric.Rect = createRectangle({
      left: anno.points[0].x,
      top: anno.points[0].y,
      width: anno.points[2].x - anno.points[0].x,
      height: anno.points[2].y - anno.points[0].y,
      selectable: false,
    });
    updateObjectAdditionalData(fabricRectangle, addData);
    return fabricRectangle;
  }

  if (anno.type === WsiAnnotationItemType.line) {
    if (!anno.points || anno.points.length < 2) return undefined;
    const fabricLine: fabric.Line = createLineFromPoints({
      points: anno.points,
      isCreateDistance: true,
      createUtilLabel: true,
    });
    updateObjectAdditionalData(fabricLine, addData);
    return fabricLine;
  }

  return undefined;
};

export const annoRelationToPathologyEdge = (
  annoRelation: AnnotationRelationResponseDTO
): PathologyAnnotationEdge | undefined => {
  if (!annoRelation.first || !annoRelation.second) return undefined;
  return {
    parentServerId: annoRelation.first,
    childServerId: annoRelation.second,
  };
};
