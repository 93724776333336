/*
 * File: searchable-column-header.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 24th November 2021 4:34:53 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useKeyPress } from "ahooks";
import { HTMLProps, useEffect, useRef, useState } from "react";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { IconClose, IconFilterList, IconSearch } from "../vb-icon.component";
import DOMPurify from "dompurify";
export type SortType = "" | "asc" | "desc";
interface Props extends HTMLProps<HTMLInputElement> {
  header: string;
  containerWidth: number | string;
  clearInput?: boolean;
  sortable?: boolean;
  searchIcon?: boolean;
  containerClassName?: string;
  headerClassName?: string;
  onInputChange?(value: string): void;
  sortType?: SortType;
  onSortChange?(value: SortType): void;
  showSearch?: boolean;
}
export const SearchableColumnHeader = ({
  containerClassName = "",
  headerClassName = "flex-auto",
  header,
  containerWidth,
  clearInput,
  className,
  sortable,
  searchIcon,
  onInputChange,
  sortType,
  onSortChange,
  showSearch = true,
  ...rest
}: Props) => {
  const { defaultValue } = rest;
  const [value, setValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue?.toString());
    } else {
      setValue("");
    }
  }, [defaultValue]);

  const handleInput = () => {
    if (inputRef.current) {
      setValue(inputRef.current.value);
    }
  };
  const handleClearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
      setValue("");
      onInputChange && onInputChange("");
    }
  };

  const handleSubmit = () => {
    if (!inputRef.current) return;
    const value = DOMPurify.sanitize(inputRef.current.value.trim());
    onInputChange && onInputChange(value);
  };

  useKeyPress(KeyboardKey.Enter, handleSubmit, { target: inputRef.current });
  useKeyPress(KeyboardKey.Escape, handleClearInput, {
    target: inputRef.current,
  });

  const handleClickSort = () => {
    if (sortType === "") {
      onSortChange && onSortChange("asc");
      return;
    }
    if (sortType === "asc") {
      onSortChange && onSortChange("desc");
      return;
    }
    if (sortType === "desc") {
      onSortChange && onSortChange("");
      return;
    }
  };

  return (
    <div
      className={`flex flex-col flex-shrink-0 gap-2 ${containerClassName}`}
      style={{ width: containerWidth }}
    >
      <div className="flex items-center gap-2 text-sm">
        <span className={headerClassName}>{header}</span>
        {sortable && (
          <button
            onClick={handleClickSort}
            className="flex items-center justify-center w-5 h-5"
          >
            {sortType === "" && <IconFilterList className="w-4 h-4" />}
            {sortType === "asc" && (
              <IconFilterList className="w-4 h-4 text-primary" />
            )}
            {sortType === "desc" && (
              <IconFilterList className="w-4 h-4 transform rotate-180 text-primary" />
            )}
          </button>
        )}
      </div>
      {showSearch && (
        <div className="flex items-center w-full gap-2 px-4 overflow-hidden bg-white border border-gray-300 rounded h-9 focus-within:border-primary">
          {searchIcon && <IconSearch className="flex-none w-4 h-4" />}
          <div className="flex-1">
            <input
              onBlur={handleSubmit}
              ref={inputRef}
              onInput={handleInput}
              className={`w-full bg-opacity-0 outline-none ${className}`}
              value={value}
              {...rest}
            />
          </div>
          {clearInput && value.trim() && (
            <button
              onClick={handleClearInput}
              className="flex-shrink-0 w-4 h-4"
            >
              <IconClose className="w-4 h-4" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};
