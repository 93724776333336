/*
 * File: use-data-type-options.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd July 2023 10:47:51 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { selectSupportedDatasourceTypes } from "store/common/app-setting/app-setting.selectors";
import { useAppSelector } from "hooks/use-redux";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export function useDataTypeOptions(defaultValue = "") {
  const { t } = useTranslation();
  const options = useAppSelector(selectSupportedDatasourceTypes(t));

  const [value, setValue] = useState(defaultValue);

  const option = useMemo(() => {
    return options.find((item) => item.value === value);
  }, [options, value]);

  function onChange(newOption?: any) {
    if (newOption && newOption.hasOwnProperty("value")) {
      setValue(newOption["value"]);
    } else {
      setValue("");
    }
  }

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return { options, value, setValue, option, onChange };
}

export default useDataTypeOptions;
