import { useCallback, useEffect, useState } from "react";
import { useImageEditorContext } from "../image-editor-context/image-editor.context";
import { Point } from "utilities/math/point";
import { useUnmount } from "ahooks";
import { useAppSelector } from "hooks/use-redux";
import { selectActiveCornerstoneTool } from "store/labeler/image-workspace/dicom-editor/dicom-editor.selectors";
import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";
import {
  SAMBBox,
  SAMData,
  SAMPolygon,
} from "modules/onnix/context/onnix-state";

export function useSAMTool() {
  const [data, setData] = useState<SAMData | undefined>(undefined);
  const { cornerstoneHandler } = useImageEditorContext();
  const activeTool = useAppSelector(selectActiveCornerstoneTool);

  const handleMeasurementAdded = useCallback(
    (event: any) => {
      const measument = event.detail;
      cornerstoneHandler.current?.removeSAMMeasurements([measument.uuid]);
      const { start, end } = measument.handles;
      const x = Math.min(start.x, end.x);
      const y = Math.min(start.y, end.y);
      const width = Math.abs(end.x - start.x);
      const height = Math.abs(end.y - start.y);
      const bbox = { x, y, width, height };
      const uuid = measument.uuid;
      const includePoints: Point[] = [];
      const excludePoints: Point[] = [];
      const bboxes: SAMBBox[] = [];
      const polygons: SAMPolygon[] = [];
      setData({ uuid, bbox, includePoints, excludePoints, bboxes, polygons });
    },
    [cornerstoneHandler]
  );

  const handleMeasurementCompleted = useCallback((event: any) => {
    const measument = event.detail;
    const { start, end } = measument.handles;
    const x = Math.min(start.x, end.x);
    const y = Math.min(start.y, end.y);
    const width = Math.abs(end.x - start.x);
    const height = Math.abs(end.y - start.y);
    const bbox = { x, y, width, height };
    const uuid = measument.uuid;
    const includePoints: Point[] = [];
    const excludePoints: Point[] = [];
    const bboxes: SAMBBox[] = [];
    const polygons: SAMPolygon[] = [];
    setData({
      uuid,
      bbox,
      bboxes,
      polygons,
      includePoints,
      excludePoints,
      dirty: true,
    });
  }, []);

  const handleMeasurementUpdated = useCallback(
    (event: any) => {
      const measument = event.detail;
      if (measument.uuid !== data?.uuid) return;
      const { start, end } = measument.handles;
      const x = Math.min(start.x, end.x);
      const y = Math.min(start.y, end.y);
      const width = Math.abs(end.x - start.x);
      const height = Math.abs(end.y - start.y);
      const bbox = { x, y, width, height };
      const uuid = measument.uuid;
      const includePoints = measument.includePoints;
      const excludePoints = measument.excludePoints;
      const bboxes = measument.bboxes;
      const polygons = measument.polygons;

      setData({
        uuid,
        bbox,
        bboxes,
        polygons,
        includePoints,
        excludePoints,
        dirty: true,
      });
    },
    [data]
  );

  const handleMeasurementRemoved = useCallback(
    (event: any) => {
      const measument = event.detail;
      if (measument.uuid === data?.uuid) {
        setData(undefined);
      }
    },
    [data]
  );

  useEffect(() => {
    window.addEventListener("SAM_MEASUREMENT_ADDED", handleMeasurementAdded);
    window.addEventListener(
      "SAM_MEASUREMENT_COMPLETED",
      handleMeasurementCompleted
    );
    window.addEventListener(
      "SAM_MEASUREMENT_REMOVED",
      handleMeasurementRemoved
    );
    window.addEventListener(
      "SAM_MEASUREMENT_UPDATED",
      handleMeasurementUpdated
    );
    return () => {
      window.removeEventListener(
        "SAM_MEASUREMENT_ADDED",
        handleMeasurementAdded
      );
      window.removeEventListener(
        "SAM_MEASUREMENT_COMPLETED",
        handleMeasurementCompleted
      );
      window.removeEventListener(
        "SAM_MEASUREMENT_REMOVED",
        handleMeasurementRemoved
      );
      window.removeEventListener(
        "SAM_MEASUREMENT_UPDATED",
        handleMeasurementUpdated
      );
    };
  }, [
    cornerstoneHandler,
    handleMeasurementAdded,
    handleMeasurementCompleted,
    handleMeasurementUpdated,
    handleMeasurementRemoved,
  ]);

  useEffect(() => {
    if (activeTool !== ToolName.SAM) {
      cornerstoneHandler.current?.removeSAMMeasurements([]);
    }
  }, [activeTool, cornerstoneHandler]);

  useUnmount(() => cornerstoneHandler.current?.removeSAMMeasurements([]));
  return { data, setData };
}
