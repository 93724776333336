import { AnalyticsQueryResponse } from "services/analytics-service";
import { DatasetCategories, ProjectTypeNames, ProjectTypes } from "services/label-service/dtos";
import { CardRowDataModel } from "../context/dashboard.state";

const COL_CATEGORY = "category";
const COL_SIZE_IN_BYTE = "size_in_byte";

export const DEFAULT_DATA: CardRowDataModel[] = [
  {
    name: ProjectTypeNames[ProjectTypes.MEDICAL_IMAGE],
    total: 0,
    postFix: "GB",
  },
  {
    name: ProjectTypeNames[ProjectTypes.GENERAL_IMAGE],
    total: 0,
    postFix: "GB",
  },
  {
    name: ProjectTypeNames[ProjectTypes.TEXT],
    total: 0,
    postFix: "GB",
  },
  {
    name: ProjectTypeNames[ProjectTypes.AUDIO],
    total: 0,
    postFix: "GB",
  },
  {
    name: "Exported Dataset",
    total: 0,
    postFix: "GB",
  },
  {
    name: "Others",
    total: 0,
    postFix: "GB",
  },
];

const BYTES_PER_GB = 1024 * 1024 * 1024;

export const cardRowStorageUsageMapper = (response: AnalyticsQueryResponse): CardRowDataModel[] => {
  const rows = response.data.rows;
  if (rows && rows.length > 0) {
    const resRows = DEFAULT_DATA.map(item => ({...item}));
    const medicalRow: any = rows.find((r: any) => r[COL_CATEGORY] === DatasetCategories.MEDICAL_IMAGE);
    const generalRow: any = rows.find((r: any) => r[COL_CATEGORY] === DatasetCategories.GENERAL_IMAGE);
    const nullRow: any = rows.find((r: any) => r[COL_CATEGORY] === null);
    const corRow: any = rows.find((r: any) => r[COL_CATEGORY] === DatasetCategories.NLP_COR);
    const speech2TextRow: any = rows.find((r: any) => r[COL_CATEGORY] === DatasetCategories.SPEECH_TO_TEXT);
    const textToSpeechRow: any = rows.find((r: any) => r[COL_CATEGORY] === DatasetCategories.TEXT_TO_SPEECH);
    
    if (medicalRow) resRows[0].total += medicalRow[COL_SIZE_IN_BYTE] || 0;
    if (generalRow) resRows[1].total += generalRow[COL_SIZE_IN_BYTE] || 0;
    if (nullRow) resRows[1].total = nullRow[COL_SIZE_IN_BYTE] || 0;

    if (corRow) resRows[2].total = corRow[COL_SIZE_IN_BYTE] || 0;

    if (speech2TextRow) resRows[3].total += speech2TextRow[COL_SIZE_IN_BYTE] || 0;
    if (textToSpeechRow) resRows[3].total += textToSpeechRow[COL_SIZE_IN_BYTE] || 0;

    for (let i=0; i<resRows.length; i++) {
      resRows[i].total = resRows[i].total / BYTES_PER_GB;
    }
    return resRows;
  }
  return DEFAULT_DATA;
}