/*
 * File: text-review.actions.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 29th October 2021 11:13:03 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { Collection } from "domain/common";
import { TextFile } from "domain/labeling/file";
import { TextLabelingData } from "domain/labeling/labeling-data";
import {
  CORAnnotationData,
  NERAnnotationData,
  Token,
} from "domain/text-labeling";
import { SystemAnnotationData } from "domain/text-labeling/system-annotation";
import { buildAnnotationTokenIds } from "pages/labeler/text-labeling/components/text-viewer/text-viewer.utils";
import { TextWorkspaceState } from "../text-workspace.state";
import { textUtils } from "../utils/text-labeling.utils";
import { INITIAL_TEXT_REVIEW_STATE } from "./text-review.state";

export const textReviewActions = {
  resetTextReviewState({ textReview }: TextWorkspaceState) {
    Object.assign(textReview, INITIAL_TEXT_REVIEW_STATE);
  },

  setReviewMode: (
    { textReview }: TextWorkspaceState,
    action: PayloadAction<boolean>
  ) => {
    textReview.reviewMode = action.payload;
  },

  startReview: (state: TextWorkspaceState) => {
    state.textReview.reviewMode = true;
    const { labelingJob } = state.textLabeling;
    if (!labelingJob) return;

    state.textReview.labelingDatas = (labelingJob.data?.previousJobData ||
      []) as TextLabelingData[];
    state.textReview.annotations = [];
    state.textReview.relationAnnotations = [];
    state.textReview.systemAnnotations = [];

    const textContent = (labelingJob.file as TextFile).sentences
      .map((s) => s.sentence)
      .join(" ");

    const tokenCollection: Collection<Token> = {
      entities: state.textLabeling.tokenEntities,
      allIds: state.textLabeling.tokenIds,
    };
    const annotations: NERAnnotationData[] = [];
    const relationAnnotations: CORAnnotationData[] = [];
    const systemAnnotations: SystemAnnotationData[] = [];
    for (const data of state.textReview.labelingDatas) {
      for (const annotation of data.annotations) {
        const existedAnnotation = annotations.find(
          (anno) => anno.id === annotation.id
        );
        if (existedAnnotation) {
          existedAnnotation.annotators?.push(data.annotator);
          existedAnnotation.selected = true;
        } else {
          const { startIndex, endIndex } = annotation;
          const content = textContent.substring(startIndex, endIndex + 1);
          annotations.push({
            ...annotation,
            annotators: [data.annotator],
            content: content,
            tokenIds: buildAnnotationTokenIds(annotation, tokenCollection),
          });
        }
      }

      for (const relation of data.relationAnnotations) {
        const existedAnnotation = relationAnnotations.find(
          (anno) =>
            anno.from === relation.from &&
            anno.to === relation.to &&
            anno.observationId === relation.observationId
        );
        if (existedAnnotation) {
          existedAnnotation.annotators?.push(data.annotator);
          existedAnnotation.selected = true;
        } else {
          relationAnnotations.push({
            ...relation,
            annotators: [data.annotator],
          });
        }
      }

      const observationId = data.systemObservationId?.toString();
      if (observationId) {
        const existedAnnotation = systemAnnotations.find(
          (anno) => anno.id === observationId
        );

        if (existedAnnotation) {
          existedAnnotation.annotators?.push(data.annotator);
          existedAnnotation.selected = true;
        } else {
          systemAnnotations.push({
            id: observationId,
            observationId,
            active: false,
            annotators: [data.annotator],
          });
        }
      }
    }

    for (const relation of relationAnnotations) {
      const from = annotations.find((anno) => anno.id === relation.from);
      const to = annotations.find((anno) => anno.id === relation.to);
      if (from && to) {
        relation.content =
          (from.content || from.id) + " -> " + (to.content || to.id);
      }
    }

    state.textReview.annotations = annotations;
    state.textReview.relationAnnotations = relationAnnotations;
    state.textReview.systemAnnotations = systemAnnotations;
    state.textReview.selectedAnnotators = [];
    state.textReview.selectedAnnotationIds = annotations
      .map((anno) => anno.id)
      .concat(relationAnnotations.map((anno) => anno.id))
      .concat(systemAnnotations.map((anno) => anno.id));
  },

  textReviewAnnotatorSelected: (
    state: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    const {
      selectedAnnotators,
      selectedAnnotationIds,
      annotations,
      relationAnnotations,
      systemAnnotations,
    } = state.textReview;
    const annotator = action.payload;
    if (selectedAnnotators.includes(annotator)) {
      state.textReview.selectedAnnotators = selectedAnnotators.filter(
        (anno) => anno !== annotator
      );

      state.textReview.selectedAnnotationIds = selectedAnnotationIds.filter(
        (annotationId) => {
          type AnnotationDataType = { id: string; annotators?: string[] };
          const annotationFinder = ({ id, annotators }: AnnotationDataType) =>
            id === annotationId && annotators?.includes(annotator);
          const annotation = annotations.find(annotationFinder);
          const relation = relationAnnotations.find(annotationFinder);
          const systemAnnotation = systemAnnotations.find(annotationFinder);
          return !annotation && !relation && !systemAnnotation;
        }
      );
    } else {
      state.textReview.selectedAnnotators.push(annotator);

      for (const anno of annotations) {
        if (!anno.annotators?.includes(annotator)) continue;
        if (state.textReview.selectedAnnotationIds.includes(anno.id)) continue;
        state.textReview.selectedAnnotationIds.push(anno.id);
      }

      for (const anno of relationAnnotations) {
        if (!anno.annotators?.includes(annotator)) continue;
        if (state.textReview.selectedAnnotationIds.includes(anno.id)) continue;
        state.textReview.selectedAnnotationIds.push(anno.id);
      }

      for (const anno of systemAnnotations) {
        if (!anno.annotators?.includes(annotator)) continue;
        if (state.textReview.selectedAnnotationIds.includes(anno.id)) continue;
        state.textReview.selectedAnnotationIds.push(anno.id);
      }
    }
  },
  textReviewActiveTabChanged: (
    state: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.textReview.activeTab = action.payload;
  },

  textReviewAnnotationSelected: (
    state: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    if (state.textReview.selectedAnnotationIds.includes(action.payload)) {
      const ids = [action.payload];
      for (const relation of state.textReview.relationAnnotations) {
        if (
          relation.from === action.payload ||
          relation.to === action.payload
        ) {
          if (!ids.includes(relation.id)) {
            ids.push(relation.id);
          }
        }
      }
      state.textReview.selectedAnnotationIds =
        state.textReview.selectedAnnotationIds.filter(
          (id) => !ids.includes(id)
        );
    } else {
      const relation = state.textReview.relationAnnotations.find(
        (relation) => relation.id === action.payload
      );
      if (relation) {
        if (!state.textReview.selectedAnnotationIds.includes(relation.from)) {
          state.textReview.selectedAnnotationIds.push(relation.from);
        }
        if (!state.textReview.selectedAnnotationIds.includes(relation.to)) {
          state.textReview.selectedAnnotationIds.push(relation.to);
        }
      }
      state.textReview.selectedAnnotationIds.push(action.payload);
    }
  },

  textReviewLabelSelected: (
    state: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    const observationId = action.payload;
    const {
      selectedAnnotationIds,
      selectedAnnotators,
      annotations,
      relationAnnotations,
      systemAnnotations,
      activeTab,
    } = state.textReview;

    const isShowConflicts = selectedAnnotators.length > 1;
    const isFilterByAgree = isShowConflicts && activeTab === "agree";
    const isFilterByConflict = isShowConflicts && activeTab === "conflict";

    const annotationIds: string[] = [];

    for (const anno of annotations) {
      if (
        !anno.annotators?.find((annotator) =>
          selectedAnnotators.includes(annotator)
        )
      )
        continue;

      if (
        isFilterByAgree &&
        textUtils.isConflict(anno.annotators || [], selectedAnnotators)
      ) {
        continue;
      }

      if (
        isFilterByConflict &&
        !textUtils.isConflict(anno.annotators || [], selectedAnnotators)
      ) {
        continue;
      }
      if (anno.observationId === observationId) {
        annotationIds.push(anno.id);
      }
    }
    for (const anno of relationAnnotations) {
      if (
        isFilterByAgree &&
        textUtils.isConflict(anno.annotators || [], selectedAnnotators)
      ) {
        continue;
      }

      if (
        isFilterByConflict &&
        !textUtils.isConflict(anno.annotators || [], selectedAnnotators)
      ) {
        continue;
      }
      if (
        !anno.annotators?.find((annotator) =>
          selectedAnnotators.includes(annotator)
        )
      )
        continue;
      if (anno.observationId === observationId) {
        annotationIds.push(anno.id);
      }
    }
    for (const anno of systemAnnotations) {
      if (
        !anno.annotators?.find((annotator) =>
          selectedAnnotators.includes(annotator)
        )
      )
        continue;
      if (anno.observationId === observationId) {
        annotationIds.push(anno.id);
      }
    }

    if (annotationIds.length === 0) return;

    const nonExistedAnnotation = annotationIds.find(
      (id) => !selectedAnnotationIds.includes(id)
    );

    if (!nonExistedAnnotation) {
      state.textReview.selectedAnnotationIds =
        state.textReview.selectedAnnotationIds.filter((id) => {
          return !annotationIds.includes(id);
        });
    } else {
      for (const id of annotationIds) {
        if (!state.textReview.selectedAnnotationIds.includes(id)) {
          state.textReview.selectedAnnotationIds.push(id);

          const relation = state.textReview.relationAnnotations.find(
            (relation) => relation.id === id
          );
          if (relation) {
            if (
              !state.textReview.selectedAnnotationIds.includes(relation.from)
            ) {
              state.textReview.selectedAnnotationIds.push(relation.from);
            }
            if (!state.textReview.selectedAnnotationIds.includes(relation.to)) {
              state.textReview.selectedAnnotationIds.push(relation.to);
            }
          }
        }
      }
    }
  },
};
