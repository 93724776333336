import { useThreeDEditorContext } from "./three-d-editor.provider"


export const ThreeDEditor3DVisibilityComponent = () => {

  const {
    volume3dVisibility,
    setVolume3dVisibility,
    slices3dVisibility,
    setSlices3dVisibility,
    label3dVisibility,
    setLabel3dVisibility,
  } = useThreeDEditorContext();

  return (
    <div>
      <p className="font-bold">3D</p>
      <div className="flex flex-col gap-1">
        <div className="flex gap-2 items-center">
          <input
            type="checkbox"
            checked={volume3dVisibility}
            onChange={e => setVolume3dVisibility(e.target.checked)}
          />
          <span>Show 3D volume</span>
        </div>
        <div className="flex gap-2 items-center">
          <input
            type="checkbox"
            checked={slices3dVisibility}
            onChange={e => setSlices3dVisibility(e.target.checked)}
          />
          <span>Show 3D slices</span>
        </div>
        <div className="flex gap-2 items-center">
          <input
            type="checkbox"
            checked={label3dVisibility}
            onChange={e => setLabel3dVisibility(e.target.checked)}
          />
          <span>Show 3D labels</span>
        </div>
      </div>
    </div>
  )
}
