import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { TaskObservationDTO } from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import { Rectangle } from "utilities/math/rectangle";
import { ImageLabelingJob } from "../../batch-labeling/batch-labeling.state";
import { ImageWorkspaceState } from "../../image-workspace.state";
import {
  JobObservationModel,
  FindingAgreement,
  FindingLabeler,
} from "../image-labeling.state";
import { convertFileCleaningDataToMaskAnnotation } from "../image-labeling.utils";
import * as Sentry from "@sentry/react";

const THUNK_NAME = "imageWorkspace/setImageLabelingAsync";

export interface SetImageLabelingPayload {
  jobData: ImageLabelingJob;
  jobObservations: TaskObservationDTO[];
  previousJobObservations: JobObservationModel[];
}
export const setImageLabelingAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: SetImageLabelingPayload) => {
    const { jobData, jobObservations, previousJobObservations } = payload;
    let job = { ...jobData.job };
    let task = jobData.task;
    let file = jobData.file;
    let batch = jobData.batch;
    let project = jobData.project;
    let labels = jobData.labels;
    let agreements: FindingAgreement[] = [];
    let findingLabelers: FindingLabeler[] = [];
    let maskAnnotations: Rectangle[] = [];
    let error = "";
    let selectedObservationId = -1;
    let currentJobObservations = [...jobObservations];
    try {
      for (const jobObservation of previousJobObservations) {
        for (const taskObservation of jobObservation.observations) {
          const annotationAgreements = taskObservation.annotationAgreementDTOS;
          if (!annotationAgreements) continue;
          for (const annotationAgreement of annotationAgreements) {
            const agreement: FindingAgreement = {
              labelId: taskObservation.observationId.toString(),
              user1: jobObservation.assignee,
              user2: annotationAgreement.name,
              score: annotationAgreement.score,
            };
            agreements.push(agreement);
          }
        }
      }

      for (const label of labels) {
        const labelId = label.id.toString();
        const labelName = label.name;
        let users = [];
        for (let jobObservation of previousJobObservations) {
          for (let observation of jobObservation.observations) {
            if (observation.observationId === label.id) {
              users.push({
                jobId: jobObservation.jobId.toString(),
                userId: jobObservation.assignee,
                selected: false,
              });
            }
          }
        }
        findingLabelers.push({
          labelId: labelId,
          labelName: labelName,
          users: users,
        });
      }

      if (file) {
        maskAnnotations = convertFileCleaningDataToMaskAnnotation(
          file as StorageFileDTO
        );
      }
    } catch (err: any) {
      Sentry.captureException(err);
      console.log(err);
      error = err.message || "Failed to fetch job data";
    }
    return {
      job,
      task,
      file,
      batch,
      project,
      error,
      currentJobObservations,
      previousJobObservations,
      agreements,
      findingLabelers,
      labels,
      maskAnnotations,
      selectedObservationId,
    };
  }
);

export function setImageLabelingBuilder(
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) {
  return builder

    .addCase(setImageLabelingAsync.pending, ({ imageLabeling }) => {
      imageLabeling.requestStatus = RequestStatus.LOADING;
    })
    .addCase(setImageLabelingAsync.fulfilled, ({ imageLabeling }, action) => {
      imageLabeling.task = action.payload.task || null;
      imageLabeling.job = action.payload.job;
      imageLabeling.file = action.payload.file || null;
      imageLabeling.batch = action.payload.batch || null;
      imageLabeling.project = action.payload.project?.dto || null;
      imageLabeling.labels = action.payload.labels;
      imageLabeling.currentJobObservations =
        action.payload.currentJobObservations;
      imageLabeling.previousJobObservations =
        action.payload.previousJobObservations;
      imageLabeling.agreements = action.payload.agreements;
      imageLabeling.findingLabelers = action.payload.findingLabelers;
      imageLabeling.error = action.payload.error;
      imageLabeling.viewMediaAgreement = false;
      imageLabeling.selectedObservationId =
        action.payload.selectedObservationId;
      imageLabeling.maskAnnotations = action.payload.maskAnnotations;
      imageLabeling.requestStatus = RequestStatus.SUCCESS;
    })
    .addCase(setImageLabelingAsync.rejected, ({ imageLabeling }) => {
      imageLabeling.requestStatus = RequestStatus.FAILURE;
    });
}
