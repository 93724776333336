/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 5th January 2022 11:00:13 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import axios, { AxiosInstance, AxiosResponse } from "axios";
import { ENV_CONFIG } from "configs/env.config";
import { setupLabelInterceptor } from "services/common/label-interceptor";
import { RequestOptions } from "services/common/request-options";
import { PricingRequestDTO } from "services/label-service/dtos/batch-pricing.dto";
import { BillingResponseDTO, CurrencyResponseDTO, PricingCalculationResponseDTO } from "./payment.dto";

let _axiosInstance: AxiosInstance | undefined = undefined;

function getAxiosInstance() {
  if (_axiosInstance) return _axiosInstance;
  _axiosInstance = setupLabelInterceptor(
    axios.create({
      baseURL: ENV_CONFIG.PAYMENT_SERVICE_URL,
    })
  );
  return _axiosInstance;
}


export function getAllCurrency(): Promise<AxiosResponse<CurrencyResponseDTO[]>> {
  return getAxiosInstance().get("/api/v1/currency");
}

export function calculate(payload: PricingRequestDTO): Promise<AxiosResponse<PricingCalculationResponseDTO>> {
  return getAxiosInstance().post("/api/v1/pricing/calculate", payload);
}

export function getBillings(options: RequestOptions): Promise<AxiosResponse<BillingResponseDTO[]>> {
  const params = new URLSearchParams(options);
  return getAxiosInstance().get(`/api/v1/billings?${params}`);
}

export function getBilling(id: string | number): Promise<AxiosResponse<BillingResponseDTO>> {
  return getAxiosInstance().get(`/api/v1/billings/${id}`);
}

export const PaymentService = {
  getAllCurrency,
  calculate,
  getBillings,
  getBilling,
};
