/*
 * File: use-files-query.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 5:07:19 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { QueryApi } from "data-access/impl/query";
import { Filter } from "domain/common";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";

const QUERY_KEY = "FILE_BROWSE";

export function useFilesQuery(filter: Filter) {
  const client = useQueryClient();
  const [increment, setIncrement] = useState(1);

  function requestData() {
    return QueryApi.searchFileInfo(filter);
  }

  const { data, isLoading } = useQuery(
    [QUERY_KEY, filter, increment],
    requestData,
    {}
  );

  function reloadData() {
    setIncrement((pre) => pre + 1);
    client.invalidateQueries([QUERY_KEY]);
  }

  return {
    data,
    isLoading,
    reloadData,
  };
}
