/*
 * File: use-exported-dataset-annotations.hook.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 1st December 2022 11:38:38 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Annotation, Label } from "domain/image-labeling";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AnnotationsService,
  BatchObservationService,
} from "services/label-service";
import { BatchObservationDTO } from "services/label-service/dtos";
import { imageLabelMapper } from "services/label-service/mappers/image-label.mapper";
import { annotationUtils } from "utilities/annotations/annotation-util";
import * as Sentry from "@sentry/react";

export default function useExportedDatasetAnnotations() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [labels, setLabels] = useState<BatchObservationDTO[]>([]);
  const [annotations, setAnnotations] = useState<Record<number, Annotation[]>>(
    {}
  );
  const datasetVersionId = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const versionId = urlParams.get("versionId");
    if (!versionId) return undefined;
    return parseInt(versionId);
  }, [location.search]);

  useEffect(() => {
    async function loadAnnotations() {
      if (!datasetVersionId) return;
      try {
        const response =
          await AnnotationsService.getAnnotationsByDatasetVersionId(
            datasetVersionId
          );

        let batchId = -1;
        for (const anno of response.data.annotations) {
          if (anno.batchId) batchId = anno.batchId;
          break;
        }

        let labels = [];

        if (batchId !== -1) {
          const res = await BatchObservationService.getItems({
            batchId: batchId.toString(),
          });
          labels = res.data;
        }

        if (labels.length > 0) {
          const labelEntities = labels.map(imageLabelMapper.toLabel);
          const labelCollection: Record<number, Label> = {};
          for (const label of labelEntities) {
            labelCollection[label.id] = label;
          }
          const data = annotationUtils.annotationJobResponseDTOToAnnotations(
            response.data,
            labelCollection
          );

          const annotationsMap: Record<number, Annotation[]> = {};

          for (const anno of data.annotations) {
            if (!anno.mediaId) continue;
            if (!annotationsMap.hasOwnProperty(anno.mediaId)) {
              annotationsMap[anno.mediaId] = [];
            }
            annotationsMap[anno.mediaId].push(anno);
          }

          setAnnotations(annotationsMap);
          setLabels(labels);
          setLoading(false);
        }
      } catch (error) {Sentry.captureException(error);}
    }
    if (datasetVersionId) loadAnnotations();
  }, [datasetVersionId]);

  return { loading, annotations, labels };
}
