/*
 * File: pipeline.api.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 6th September 2021 1:53:32 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { PipelineDTO } from "../dtos/pipeline.dto";
import { BaseAPI } from "./base.api";

export class PipelineAPI extends BaseAPI<PipelineDTO> {
  async getPipelines() {
    const res = await this.getItems({});
    return res.data;
  }
}
