import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { VBSpinner } from "components/common/vb-spinner/vb-spinner.component";
import { useDetectChange } from "hooks/use-detect-change";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { StorageTableCompact } from "pages/customer/datasets/dataset-detail/components/explorer/storage-table/storage-table-compact";
import { DatasetDetailDeselectProvider } from "pages/customer/datasets/dataset-detail/dataset-detail.context";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DatasetDTO } from "services/label-service/dtos";
import { selectTotalDatasetFiles } from "store/customer/dataset/dataset.selectors";
import {
  removeCurrentDatasetData,
  setDatasetAsync,
} from "store/customer/dataset/dataset.slice";
import { Logger } from "utilities/logger";
import { StorageService } from "services/storage";
import { Tag } from "models/dataset/tag.model";
import { DatasetProviderWithoutNav } from "pages/customer/datasets/context/dataset-provider-without-nav";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { SingleValue } from "react-select";
import { useMount } from "ahooks";
import { selectSupportedDatasourceTypesForProject } from "store/common/app-setting/app-setting.selectors";
import { useCreateBatchV2Context } from "../create-batch-v2.provider";
import { useDatasources } from "../../create-batch/hooks/use-datasources.hook";
import { ImportIds } from "../../create-batch/import-ids.component";
import * as Sentry from "@sentry/react";


export const CreateBatchV2PageDatasource = () => {
  const { t } = useTranslation();
  const {
    projectForCreatingBatch,
    batchDatasource,
    setBatchDatasource
  } = useCreateBatchV2Context();
  const supportedDatasourceTypes = useAppSelector(
    selectSupportedDatasourceTypesForProject(projectForCreatingBatch.project.type)
  );
  const { loaded, datasources: datasets } = useDatasources({
    "category.in": supportedDatasourceTypes.join(","),
  });

  const datasetFileCount = useAppSelector(selectTotalDatasetFiles);
  const [datasetId, setDatasetId] = useState(() => {
    if (batchDatasource.id) return batchDatasource.id;
    return -1;
  });
  const [autoCreateTasks, setAutoCreateTasks] = useState(true);
  const [datasetCriteria, setDatasetCriteria] = useState<any>({});
  const [mediaIgnoreIds, setMediaIgnoreIds] = useState<number[]>([]);
  const [isImporting, setIsImporting] = useState(false);
  const shouldRefreshIgnores = useDetectChange([
    datasetCriteria.fileName,
    datasetCriteria.createdBy,
    datasetCriteria.tagIds,
    datasetCriteria.fromTimeMs,
    datasetCriteria.toTimeMs,
  ]);
  const shouldSubmit = useDetectChange([
    datasets,
    datasetId,
    datasetCriteria,
    autoCreateTasks,
    mediaIgnoreIds,
  ]);

  const handleSelectDataset = (dataset: DatasetDTO | null) => {
    setDatasetId(dataset?.id || -1);
  };

  const handleImportStarted = () => {
    setIsImporting(true);
  };

  const handleImportEnded = (datasetId: number | null) => {
    setIsImporting(false);
  };

  useEffect(() => {
    if (!shouldRefreshIgnores) return;
    setMediaIgnoreIds([]);
  }, [shouldRefreshIgnores, datasetCriteria]);

  useEffect(() => {
    if (!shouldSubmit) return;
    if (
      datasets.length === 0 ||
      datasetId === -1 ||
      !datasetCriteria?.datasetIds?.length
    ) {
      setBatchDatasource({});
      return;
    }
    const data = {
      autoCreateTasks,
      datasetCriteria: {
        ...datasetCriteria,
        mediaIgnoreIds,
      },
      datasetFileCount,
      selectedFileCount: datasetFileCount - mediaIgnoreIds.length,
    };
    setBatchDatasource(data);
  }, [
    shouldSubmit,
    datasets,
    datasetId,
    datasetCriteria,
    autoCreateTasks,
    mediaIgnoreIds,
    datasetFileCount,
    setBatchDatasource,
  ]);

  return (
    <div>
      <div className="mb-6 text-xl font-bold">
        {t("project:batch.instruction.step2.textSelectDatasource")}
      </div>
      {!loaded && (
        <div className="flex items-center justify-center px-4 py-8">
          <VBSpinner className="w-4 h-4" />
          <div className="mx-4">{t("common:textPleaseWait")}</div>
        </div>
      )}
      {loaded && datasets.length === 0 && (
        <div className="px-4 py-8 text-center">
          <h2 className="py-4 text-xl font-bold">
            {t("project:batch.instruction.step2.textNoDataset")}!
          </h2>
          <p>{t("project:batch.instruction.step2.textDatasetRequire")}.</p>
        </div>
      )}
      {loaded && datasets.length > 0 && (
        <>
          <DatasetProviderWithoutNav>
            <div className="flex items-center gap-4 pb-2">
              <DatasetSelect
                datasets={datasets}
                onSelect={handleSelectDataset}
              />
              <span className="self-center">Or</span>
              <ImportIds
                disabled={isImporting}
                datasetId={datasetId}
                onStartImport={handleImportStarted}
                onEndImport={handleImportEnded}
              />
            </div>
            <DatasetCriteriaBuilder onUpdateCriteria={setDatasetCriteria} />
            <DatasetDetailDeselectProvider
              ignoreFiles={mediaIgnoreIds}
              setIgnoredFiles={setMediaIgnoreIds}
              onIgnoreFilesChange={setMediaIgnoreIds}
            >
              <div className="flex my-2" style={{ height: "450px" }}>
                <StorageTableCompact hideFooterSelectedRowCount />
              </div>
            </DatasetDetailDeselectProvider>
          </DatasetProviderWithoutNav>
          <div className="flex items-center justify-between">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={autoCreateTasks}
                  onChange={(_, checked) => setAutoCreateTasks(checked)}
                />
              }
              label={t(
                "project:batch.instruction.step2.textAutoDistributeTask"
              )}
            />
            <div>
              {t("project:batch.instruction.step2.textSelected")}{" "}
              {datasetFileCount - mediaIgnoreIds.length}{" "}
              {t("project:batch.instruction.step2.textFiles")}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

interface DatasetCriteriaBuilderProps {
  onUpdateCriteria: (datasetCriteria: object) => void;
}

const DatasetCriteriaBuilder = ({
  onUpdateCriteria,
}: DatasetCriteriaBuilderProps) => {
  const { filter } = useDatasetContext();

  const getTagIds = useCallback(async (tagNames: string | undefined) => {
    if (!tagNames) return undefined;
    const res = await StorageService.getAllTagsMatches(tagNames);
    return res.data.map((tag: Tag) => tag.id);
  }, []);

  const convertDateToTimes = useCallback((date: string | undefined) => {
    if (!date) return undefined;
    const curDate = new Date(date);
    const nextDay = new Date(curDate.getTime() + 24 * 3600 * 1000);
    return {
      fromTimeMs: curDate.getTime(),
      toTimeMs: nextDay.getTime(),
    };
  }, []);

  useEffect(() => {
    if (!filter) return;
    const constructCriteria = async () => {
      const datasetCriteria = {
        action: "ADD",
        createdBy: filter.createdBy || undefined,
        datasetIds: [filter.datasetId],
        fileName: filter.fileName || undefined,
        tagIds: await getTagIds(filter.tags),
        type: "SOFT",
        ...convertDateToTimes(filter.createdDate),
      };
      onUpdateCriteria(datasetCriteria);
    };
    constructCriteria();
  }, [filter, getTagIds, convertDateToTimes, onUpdateCriteria]);
  return null;
};

interface DatasetSelectProps {
  datasets: DatasetDTO[];
  onSelect?: (dataset: DatasetDTO | null) => void;
}

const DatasetSelect = ({ datasets, onSelect }: DatasetSelectProps) => {
  const dispatch = useAppDispatch();
  const { setCurrentDataset } = useDatasetContext();
  const onChange = (dataset: DatasetDTO | null) => {
    onSelect && onSelect(dataset);
    if (!dataset) {
      dispatch(removeCurrentDatasetData());
      setCurrentDataset("");
      return;
    }
    const setDataAsync = async (id: number) => {
      try {
        await dispatch(setDatasetAsync(id));
      } catch (err) {
        Sentry.captureException(err);
        Logger.log(err);
      }
    };
    setDataAsync(dataset.id);
    setCurrentDataset(dataset.id.toString());
  };
  const labeleddDatasets = useMemo(() => {
    return datasets.map((dataset) => ({
      value: dataset,
      label: dataset.name,
    }));
  }, [datasets]);

  const handleChangeDataset = (val: SingleValue<any>) => {
    onChange(val?.value);
  };

  useMount(() => {
    if (labeleddDatasets.length > 0) {
      onChange(labeleddDatasets[0].value);
    }
  });
  return (
    <div className="w-full">
      <VBSelectComponent
        options={labeleddDatasets}
        onChange={handleChangeDataset}
        defaultValue={
          labeleddDatasets.length === 0
            ? {
                value: null,
                label: "",
              }
            : labeleddDatasets[0]
        }
      />
    </div>
  );
};