import { useAppSelector } from "hooks/use-redux";
import { useCallback, useMemo, useRef, useState } from "react";
import { RequestOptions } from "services/common/request-options";
import { JobStatisticLabelerResponseDTO } from "services/query/query.dto";
import { QueryService } from "services/query/query.service";
import { RequestStatus } from "store/base/base.state";
import { selectPaymentCurrencySelectOptions } from "store/common/payment/payment.selectors";
import useDeepCompareEffect from "use-deep-compare-effect";
import { mapperLabelerReportByJobRow } from "../mappers/labeler-report-by-job.mapper";
import { LabelerReportByJobFilter, LabelerReportByJobRow } from "../models/labeler-report.models";
import * as Sentry from "@sentry/react";


export const useLabelerReportByJob = (startDate: Date, endDate: Date) => {
  const [totalRow, setTotalRow] = useState(0);
  const [rows, setRows] = useState<LabelerReportByJobRow[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.IDLE);
  const isLoading = useMemo(() => requestStatus === RequestStatus.LOADING, [requestStatus]);
  const forceRequest = useRef(false);
  const currencyOptions = useAppSelector(selectPaymentCurrencySelectOptions);

  const [filter, setFilter] = useState<LabelerReportByJobFilter>({
    page: 1,
    pageSize: 10,
    startDate,
    endDate,
  });

  const setFilterField = (field: keyof LabelerReportByJobFilter, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
    forceRequest.current = true;
  }

  const setFilterFields = (sub: Partial<LabelerReportByJobFilter>) => {
    setFilter({
      ...filter,
      ...sub,
    });
    forceRequest.current = true;
  }

  const requestData = useCallback(async (
    options: RequestOptions,
  ) => {
    try {
      if (requestStatus !== RequestStatus.IDLE && !forceRequest.current) return;
      setRequestStatus(RequestStatus.LOADING);

      const res = await QueryService.getJobStatisticsLabeler(options);
      const data = res.data as JobStatisticLabelerResponseDTO;
      const rows = data.data.map(resRow => mapperLabelerReportByJobRow.fromEntity(currencyOptions, resRow));
      setRows(rows);
      setTotalRow(data.totalJob);

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      Sentry.captureException(error);
      setRequestStatus(RequestStatus.FAILURE);
      console.log(error)
    } finally {
      forceRequest.current = false;
    }
  }, [requestStatus, currencyOptions]);

  useDeepCompareEffect(() => {
    if (!filter) return;
    const options = LabelerReportByJobFilter.toRequestOption(filter);
    requestData(options);

  }, [requestData, filter]);

  return {
    isLoading,
    totalRow,
    rows,
    filter,
    setFilterField,
    setFilterFields,
  }

}