/*
 * File: profile.page.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBTabs } from "components/common/vb-tabs/vb-tabs.component";
import { useEffect, useState } from "react";
import { ProfileGeneralInfo } from "./components/profile-general-info.component";
import { ProfileBills } from "./bills/profile-bills.component";
import { ProfileSettings } from "./components/profile-settings.component";
import { IconEdit } from "components/common/vb-icon.component";
import { UserInfoDTO } from "services/user-service/dtos/user-info.dto";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { enqueueErrorNotification, enqueueSuccessNotification } from "store/common/notification/notification.actions";
import { updateUserInfoAsync } from "store/auth/auth.slice";
import { useLocation } from "react-router-dom";
import { useQuery } from "hooks/use-query";
import { selectUserInfo } from "store/auth/auth.selectors";
import * as Sentry from "@sentry/react";


export const ProfilePage = () => {
  const { t } = useTranslation();

  const profileTabs = [
    {
      id: "general_info",
      name: t("authorization:profile.tabGeneralInfo"),
    },
    {
      id: "bills",
      name: t("authorization:profile.tabBilss"),
    },
    {
      id: "settings",
      name: t("authorization:profile.tabSettings"),
    },
  ];

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { navigateByQuery } = useQuery();
  const [activeTab, setActiveTab] = useState(profileTabs[0].id);
  const userInfo = useAppSelector(selectUserInfo);
  const [localUserInfo, setLocalUserInfo] = useState<Partial<UserInfoDTO> | null>(() => {
    if (!userInfo) return null;
    return { ...userInfo };
  });
  const [isProcessing, setIsProcessing] = useState(false);

  const [isEditing, setEditing] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (!search) return;

    const params = new URLSearchParams(search);
    const tab = params.get("tab");
    if (tab) setActiveTab(tab);

  }, [search]);

  const handleUserInfoChanged = (value: Partial<UserInfoDTO> | null) => {
    setLocalUserInfo(value);
    setIsChanged(true);
  }

  const handleSaveChanges = async () => {
    try {
      if (!localUserInfo) return;

      setIsProcessing(true);
      const payload = {
        ...localUserInfo,
        name: `${localUserInfo.firstName} ${localUserInfo.lastName}`,
      }

      await dispatch(updateUserInfoAsync(payload));
      dispatch(enqueueSuccessNotification(t("common:textUpdatedSuccess")));
    } catch (err: any) {
      Sentry.captureException(err);
      dispatch(enqueueErrorNotification(t("common:textUpdatedSuccess")));
    } finally {
      setIsProcessing(false);
      setIsChanged(false);
      setEditing(false);
    }
  }

  const handleCancelEdit = () => {
    setLocalUserInfo({ ...userInfo });
    setEditing(false);
  }

  return (
    <div className="p-6 h-full flex flex-col">
      <div className="flex justify-between">
        <span className="font-bold text-primary text-lg">
          {t("authorization:profile.title")}
        </span>
        {!isEditing && (
          <button
            onClick={() => setEditing(true)}
            className="h-9 button-secondary"
          >
            <span>{t("common:buttonEditProfile")}</span>
            <IconEdit className="w-5 h-5" />
          </button>
        )}

        {isEditing && (
          <div className="flex items-center gap-4">
            <button
              onClick={handleCancelEdit}
              className="h-9  button-secondary"
            >
              <span>{t("common:buttonCancel")}</span>
            </button>

            <button
              onClick={handleSaveChanges}
              className="h-9  button-primary"
              disabled={!isChanged}
            >
              <span>{t("common:buttonSaveChanges")}</span>
            </button>
          </div>
        )}
      </div>

      <div className="my-6">
        <VBTabs
          tabs={profileTabs}
          activeTab={activeTab}
          onSelect={v => navigateByQuery("tab", v)}
          activeClass="border-primary text-primary"
        />
      </div>

      {
        activeTab === "general_info" &&
        <ProfileGeneralInfo
          value={localUserInfo}
          onUserInfoChanged={handleUserInfoChanged}
          isEditing={!isProcessing && isEditing}
        />
      }
      {
        activeTab === "bills" &&
        <ProfileBills />
      }
      {
        activeTab === "settings" &&
        <ProfileSettings />
      }
    </div>
  );
};
