import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useState, MouseEvent } from "react";
import { createPortal } from "react-dom";
import { selectCurrentJob } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import {
  imageAnnotationBringToBack,
  imageAnnotationClassMenuOpened,
  imageAnnotationRemoved,
  imageLabelAnnotationsRemoved,
} from "store/labeler/image-workspace/image-workspace.slice";
import { TreeItem } from "./use-object-tree";

function getMenuItems(item: TreeItem) {
  if (item.description?.includes("Frame: ")) {
    return [
      {
        title: "Go to frame",
        action: "GO_TO_FRAME",
      },
      {
        title: "Delete",
        action: "DELETE",
      },
    ];
  }

  if (item.objectType === "label") {
    return [
      {
        title: "Edit",
        action: "EDIT",
      },
      {
        title: "Delete",
        action: "DELETE",
      },
    ];
  }
  return [
    // {
    //   title: "Change class",
    //   action: "CHANGE_CLASS",
    // },
    {
      title: "Delete",
      action: "DELETE",
    },
  ];
}

export default function ObjectMenu() {
  const job = useAppSelector(selectCurrentJob);
  const [selectedItem, setItem] = useState<TreeItem | undefined>(undefined);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const dispatch = useAppDispatch();

  function handleAction(action: string) {
    if (!selectedItem) return;
    if (action === "DELETE") {
      deleteItem(selectedItem);
    }
    if (action === "EDIT") {
      editItem(selectedItem);
    } else if (action === "BRING_TO_BACK") {
      bringToBack(selectedItem);
    } else if (action === "BRING_TO_FRONT") {
      bringToFront(selectedItem);
    } else if (action === "GO_TO_FRAME") {
      goToFrame(selectedItem);
    } else if (action === "GO_TO_FRAME") {
      changeClass(selectedItem);
    }
    setItem(undefined);
  }

  function editItem(item: TreeItem) {
    if (item.objectType !== "label") return;
    
  }
  
  function changeClass(item: TreeItem) {
    dispatch(imageAnnotationClassMenuOpened(item.id));
  }

  function goToFrame(item: TreeItem) {
    if (!job) return;
    try {
      if (item.description?.includes("Frame: ")) {
        const items = item.description.split(" ");
        const frameStr = items[1];
        const frame = parseInt(frameStr);
        const payload = {
          detail: {
            action: "IMAGE_GO_TO_FRAME",
            frame: frame - 1,
          },
        };
        const customEvent = new CustomEvent("UI_EVENT", payload);
        window.dispatchEvent(customEvent);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function deleteItem(item: TreeItem) {
    if (!job) return;
    if (item.objectType === "annotation") {
      const payload = { uuid: item.name };
      dispatch(imageAnnotationRemoved(payload));
    } else if (item.objectType === "label") {
      const payload = { jobId: job.job.id, labelId: parseInt(item.id) };
      dispatch(imageLabelAnnotationsRemoved(payload));
    }
  }

  function bringToBack(item: TreeItem) {
    if (!job) return;
    if (item.objectType === "annotation") {
      const payload = { uuid: item.name };
      dispatch(imageAnnotationBringToBack(payload));
    }
  }

  function bringToFront(item: TreeItem) {
    if (!job) return;
    if (item.objectType === "annotation") {
      const payload = { uuid: item.name };
      dispatch(imageAnnotationBringToBack(payload));
    }
  }

  useEffect(() => {
    const handleUIButtonClick = (clickEvent: any) => {
      const { event, action, item } = clickEvent.detail;
      event?.preventDefault();
      if (action === "TREE_ITEM_SELECT_MENU") {
        setItem(item);
        setPosition({ x: event.clientX, y: event.clientY });
      }
    };
    window.addEventListener("UI_BUTTON_CLICK", handleUIButtonClick);

    return () => {
      window.removeEventListener("UI_BUTTON_CLICK", handleUIButtonClick);
    };
  }, []);

  function handleClose(event: MouseEvent) {
    const payload = {
      detail: {
        event,
        action: "TREE_ITEM_SELECT_MENU",
        id: "",
        item: undefined,
      },
    };
    const customEvent = new CustomEvent("UI_BUTTON_CLICK", payload);
    window.dispatchEvent(customEvent);
  }

  if (!selectedItem) return null;

  return createPortal(
    <div className="fixed top-0 left-0 z-50 w-screen h-screen">
      <div className="relative w-full h-full">
        <div
          className="absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-20"
          onClick={handleClose}
        ></div>
        <div
          className="absolute z-10"
          style={{ left: position.x, top: position.y }}
        >
          <div className="relative">
            <div className="absolute right-2 top-4">
              <div
                className="py-2 bg-white rounded shadow"
                style={{ minWidth: 160 }}
              >
                {getMenuItems(selectedItem).map((item) => {
                  return (
                    <button
                      key={item.action}
                      onClick={() => handleAction(item.action)}
                      className="w-full px-6 py-2 text-left hover:bg-background-100"
                    >
                      {item.title}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
}
