/*
 * File: text-labeling-navigation.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 19th October 2021 2:50:12 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { LabelingJob } from "domain/labeling/labeling-job";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  selectSelectedTextJobId,
  selectTextJobs,
  selectTextJobsWorkingStatus,
} from "store/labeler/text-labeling-batch/text-labeling-batch.selectors";
import { WorkingStatus } from "store/labeler/text-labeling-batch/text-labeling-batch.state";
import { textSelectJobAsync } from "store/labeler/text-labeling-batch/thunks/text-select-job.thunk";
import { selectTextLabelingTaskFile } from "store/labeler/text-labeling-task/text-labeling-task.selectors";
import {
  selectTextLabelingMode,
  selectTextLabelingSentences,
} from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { classnames } from "utilities/classes";
import { useTextWorkspaceContext } from "../../context/text-workspace/text-workspace.context";

export const TextLabelingNavigation = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useTextWorkspaceContext();
  const sentences = useAppSelector(selectTextLabelingSentences);
  const mode = useAppSelector(selectTextLabelingMode);
  const textTaskFile = useAppSelector(selectTextLabelingTaskFile);
  const isCustomer = mode === TextLabelingMode.CUSTOMER;
  const labelingJobs = useAppSelector(selectTextJobs);
  const textJobId = useAppSelector(selectSelectedTextJobId);
  const workingStatuses = useAppSelector(selectTextJobsWorkingStatus);

  function handleSelectJob(labelingJob: LabelingJob) {
    dispatch(textSelectJobAsync({ jobId: labelingJob.id }));
  }
  if (isLoading) return <TextLabelingNavigationSkeleton />;
  return (
    <div className="relative h-full py-4 pb-20 overflow-hidden overflow-y-auto bg-white w-60">
      <div className="px-4 py-2 font-bold uppercase">Working file</div>
      {isCustomer && (
        <div className="w-full px-4 py-2 overflow-hidden text-left truncate overflow-ellipsis">
          {textTaskFile?.name}
        </div>
      )}
      {labelingJobs.map((labelingJob) => {
        const workingStatus = workingStatuses.hasOwnProperty(labelingJob.id)
          ? workingStatuses[labelingJob.id]
          : WorkingStatus.NONE;
        const isWorking = WorkingStatus.WORKING === workingStatus;
        const isSaved = WorkingStatus.SAVED === workingStatus;
        const isActive = textJobId === labelingJob.id;
        return (
          <button
            key={labelingJob.id}
            disabled={isActive}
            onClick={() => handleSelectJob(labelingJob)}
            className={classnames(
              "flex items-center w-full px-4 py-2 text-left gap-4",
              {
                "bg-primary-100 cursor-default": isActive,
                "cursor-pointer hover:bg-coolGray-100":
                  textJobId !== labelingJob.id,
              }
            )}
          >
            <div className="w-full">
              <div className="w-full overflow-hidden truncate overflow-ellipsis">
                <span className={classnames({ "font-semibold": isActive })}>
                  {labelingJob.file.name}
                </span>
              </div>
              <p>
                {isWorking && (
                  <span className="text-xs text-yellow-500">working</span>
                )}
              </p>
              <p>
                {isSaved && (
                  <span className="text-xs text-green-500">saved</span>
                )}
              </p>
            </div>
          </button>
        );
      })}

      <hr className="my-2" />

      <div className="px-4 py-2 font-bold uppercase">File information</div>
      <div className="px-4 py-2">
        <span>Words: </span>
        {sentences.reduce((total, sentence) => {
          return (total += sentence.sentence.length);
        }, 0)}
      </div>
      <div className="px-4 py-2">Lines: {sentences.length}</div>
      <div className="px-4 py-2">
        <span>Tokens: </span>
        {sentences.reduce((total, sentence) => {
          return (total += sentence.sentence.split(" ").length);
        }, 0)}
      </div>
    </div>
  );
};

function TextLabelingNavigationSkeleton() {
  return (
    <div className="relative flex-none h-full py-4 pb-20 overflow-hidden bg-white w-60">
      <div className="px-4 py-4 space-y-6">
        <div className="w-3/6 h-4 bg-background-300 animate-pulse"></div>
        <div className="space-y-4">
          <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
        </div>
      </div>
      <hr />

      <div className="px-4 py-6 space-y-6">
        <div className="w-3/6 h-4 bg-background-300 animate-pulse"></div>
        <div className="space-y-4">
          <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
          <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
          <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
        </div>
      </div>
    </div>
  );
}
