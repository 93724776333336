/*
 * File: file-thumbnail.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th June 2023 3:25:41 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */
import { Tooltip } from "@material-ui/core";
import { QueryApi } from "data-access/impl/query";
import { VscTrash } from "react-icons/vsc";

interface Props {
  file: QueryApi.IFile;
  onRemoveMetadata(file: QueryApi.IFile, metadata: QueryApi.IMetadata): void;
}
export function FileMetadata({ file, onRemoveMetadata }: Props) {
  return (
    <div className="px-4">
      {file.metadata?.map((metadata: QueryApi.IMetadata) => {
        return (
          <div
            className="relative flex items-center gap-2 py-1 group"
            key={metadata.id}
          >
            <span className="flex-none">{metadata.key_name}:</span>
            <span className="flex-auto">{metadata.value_name}</span>
            <Tooltip title="Remove tag" arrow>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onRemoveMetadata(file, metadata);
                }}
                className="absolute z-10 hidden transform -translate-y-1/2 top-1/2 right-2 group-hover:block"
              >
                <VscTrash size={16} />
              </button>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
}

export default FileMetadata;
