/*
 * File: mammography-labeling-task.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 22nd September 2022 3:16:44 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AnnotateType } from "constants/annotation.constant";
import { collectionUtils } from "domain/common";
import { Annotation, Label } from "domain/image-labeling";
import { RootState } from "store";
import { truncateEmail } from "utilities/string/truncate-email";
import { selectImageLabelingActiveTaskId } from "../image-workspace/batch-labeling/batch-labeling.selectors";

export const selectMammographyLabelingTaskLoadingStatus = (
  state: RootState
) => {
  return state.mammographyLabelingTask.loadingStatus;
};

export const selectMammographyLabelingTaskSelectedAnnotators = (
  state: RootState
) => {
  return state.mammographyLabelingTask.selectedAnnotators;
};
export const selectMammographyLabelingTaskSelectedJobIds = (
  state: RootState
) => {
  const annotators = selectMammographyLabelingTaskSelectedAnnotators(state);
  const tasks = selectMammographyLabelingTasks(state);
  const jobIds: number[] = [];
  for (const task of tasks) {
    if (!task?.jobs) continue;
    for (const job of task.jobs) {
      if (!annotators.includes(job.assignee)) continue;
      if (jobIds.includes(job.id)) continue;
      jobIds.push(job.id);
    }
  }
  return jobIds;
};

export const selectMammographyLabelingTaskAnnotators = (state: RootState) => {
  return state.mammographyLabelingTask.annotators;
};

export const selectMammographyLabelingTasks = (state: RootState) => {
  const { labelingTasks } = state.mammographyLabelingTask;
  const taskItems = collectionUtils.values(labelingTasks);
  return taskItems;
};

export const selectMammographyTask = (taskId: number) => (state: RootState) => {
  const labelingTask = collectionUtils.getOne(
    state.mammographyLabelingTask.labelingTasks,
    taskId
  );
  return labelingTask;
};

export const selectMammographyTaskAnnotations =
  (taskId: number) => (state: RootState) => {
    const showLabel = state.cornerstone.annotationVisible;
    const fillShape = state.cornerstone.annotationFill;
    const displayTextValue = state.cornerstone.displayTextValue;
    const labelEntities = state.imageWorkspace.imageLabeling.labels;
    const labels = collectionUtils.fromEntities(labelEntities);

    const annotations = [];
    const labelingTask = collectionUtils.getOne(
      state.mammographyLabelingTask.labelingTasks,
      taskId
    );
    if (!labelingTask || !labelingTask.data) return [];
    const annotators = state.mammographyLabelingTask.selectedAnnotators;

    for (const labelingData of labelingTask.data) {
      if (!annotators.includes(labelingData.annotator)) continue;
      for (const annotation of labelingData.annotations) {
        annotations.push(annotation);
      }
    }
    return annotations
      .filter((anno) => {
        return !collectionUtils.getOne(labels, anno.labelId)?.isSystemLabel;
      })
      .map((annotation) => {
        const label = collectionUtils.getOne(
          labels,
          annotation.labelId
        ) as Label;
        const labelName = `${label.name} (${truncateEmail(
          annotation.annotator
        )})`;

        const visible = !label.hidden;

        return {
          ...annotation,
          locked: true,
          showLabel,
          fillShape,
          displayTextValue,
          label: labelName,
          color: label.color || "",
          visible,
          showActionVote: true,
        };
      });
  };

export const selectMammographyTaskRelations =
  (taskId: number) => (state: RootState) => {
    const visible = state.cornerstone.relationAnnotationVisible;
    const relations = [];
    const labelingTask = collectionUtils.getOne(
      state.mammographyLabelingTask.labelingTasks,
      taskId
    );
    if (!labelingTask || !labelingTask.data) return [];
    const annotators = state.mammographyLabelingTask.selectedAnnotators;

    for (const labelingData of labelingTask.data) {
      if (!annotators.includes(labelingData.annotator)) continue;
      for (const relation of labelingData.relationAnnotations) {
        relations.push(relation);
      }
    }
    return relations.map((relation) => {
      return { ...relation, visible };
    });
  };

export const selectMammographyTaskClassification = (state: RootState) => {
  const activeTaskId = selectImageLabelingActiveTaskId(state);
  const labelEntities = state.imageWorkspace.imageLabeling.labels;
  const labels = collectionUtils.fromEntities(labelEntities);
  const labelAnnotations: Record<number, Annotation[]> = {};
  const labelingTasks = selectMammographyLabelingTasks(state);

  for (const labelingTask of labelingTasks) {
    if (!labelingTask || !labelingTask.data) continue;
    const annotators = state.mammographyLabelingTask.selectedAnnotators;
    for (const labelingData of labelingTask.data) {
      if (labelingData.taskId !== activeTaskId) continue;
      if (!annotators.includes(labelingData.annotator)) continue;
      for (const annotation of labelingData.annotations) {
        const label = collectionUtils.getOne(labels, annotation.labelId);
        if (
          !(
            label?.annotateType === AnnotateType.CLASSIFICATION ||
            label?.annotateType === AnnotateType.SYSTEM
          )
        )
          continue;
        if (!labelAnnotations.hasOwnProperty(label.id)) {
          labelAnnotations[label.id] = [];
        }
        if (
          !labelAnnotations[label.id].find(
            (anno) => anno.annotator === annotation.annotator
          )
        ) {
          labelAnnotations[label.id].push(annotation);
        }
      }
    }
  }

  return labelAnnotations;
};
