/*
 * File: text-workspace.context.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 14th October 2021 10:39:52 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { defaultTextWorkspaceState } from "./text-workspace.state";

export const TextWorkspaceContext = createContext(defaultTextWorkspaceState);

export const useTextWorkspaceContext = () => {
  return useContext(TextWorkspaceContext);
};
