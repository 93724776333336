/*
 * File: types.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd January 2022 8:12:18 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Workflow, WorkflowStatus } from "domain/customer";

export type UserStatus = "invited" | "active" | "inactive" | "available";

export interface RowData {
  name: string;
  description: string;
  numberOfSteps: number;
  createdAt: Date;
  status: WorkflowStatus;
  data: Workflow;
}

export const workflowRowDataMapper = (workflow: Workflow): RowData => {
  return {
    ...workflow,
    data: workflow,
  };
};

export interface ColDef<T extends RowData> {
  field: keyof T;
  header: string;
  renderCell: (row: T, rowIndex: number, columnIndex: number) => JSX.Element;
  renderColumnHeader: (column: ColDef<T>, columnIndex: number) => JSX.Element;
}
