/*
 * File: web-builder-preview.context.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 17th February 2022 4:15:42 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { WebBuilderPreviewContextState } from "./web-builder-preview.state";

export const WebBuilderPreviewContext = createContext(
  {} as WebBuilderPreviewContextState
);

export const useWebBuilderPreviewContext = () => {
  return useContext(WebBuilderPreviewContext);
};
