import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import moment from "moment";
import { useEffect, useState } from "react";
import { selectDashBoardDateRange, selectDashBoardFromDate, selectDashBoardToDate } from "store/customer/dashboard/dashboard.selectors";
import { setDashBoardDateRange, setDashBoardFromDate, setDashBoardToDate } from "store/customer/dashboard/dashboard.slice";
import { DateRange } from "utilities/date-time/date-range";

export interface DateRangeOption {
  label: string;
  value: DateRange;
}

export const useDashboardDateFilters = () => {
  const dispatch = useAppDispatch();
  const fromDate = useAppSelector(selectDashBoardFromDate);
  const toDate = useAppSelector(selectDashBoardToDate);
  const dateRange = useAppSelector(selectDashBoardDateRange);
  const [dateRanges] = useState<DateRangeOption[]>([
    { label: "Day", value: DateRange.TODAY },
    { label: "Week", value: DateRange.THIS_WEEK },
    { label: "Month", value: DateRange.THIS_MONTH },
  ]);

  const setFromDate = (value: Date) => {
    dispatch(setDashBoardFromDate(value));
  }

  const setToDate = (value: Date) => {
    dispatch(setDashBoardToDate(value));
  }

  const setDateRange = (value: DateRange) => {
    dispatch(setDashBoardDateRange(value));
  }

  useEffect(() => {
    if (dateRange === DateRange.CUSTOM) return;
    let fDate = new Date();
    let tDate = new Date();
    switch (dateRange) {
      case DateRange.TODAY:
        break;
      case DateRange.YESTERDAY:
        fDate = moment(new Date()).subtract(1, "d").startOf("date").toDate();
        tDate = moment(new Date()).subtract(1, "d").endOf("date").toDate();
        break;
      case DateRange.THIS_WEEK:
        fDate = moment(new Date()).startOf("week").toDate();
        break;
      case DateRange.LAST_WEEK:
        fDate = moment(new Date()).subtract(7, "d").startOf("week").toDate();
        tDate = moment(new Date()).subtract(7, "d").endOf("week").toDate();
        break;
      case DateRange.THIS_MONTH:
        fDate = moment(new Date()).startOf("month").toDate();
        break;
      case DateRange.LAST_MONTH:
        fDate = moment(new Date()).subtract(1, "M").startOf("month").toDate();
        tDate = moment(new Date()).subtract(1, "M").endOf("month").toDate();
        break;
      case DateRange.THIS_QUARTER:
        fDate = moment(new Date()).startOf("quarter").toDate();
        break;
      case DateRange.LAST_QUARTER:
        fDate = moment(new Date())
          .subtract(1, "quarter")
          .startOf("quarter")
          .toDate();
        fDate = moment(new Date())
          .subtract(1, "quarter")
          .endOf("quarter")
          .toDate();
        break;
      case DateRange.THIS_YEAR:
        fDate = moment(new Date()).startOf("year").toDate();
        break;
      default:
    }
    dispatch(setDashBoardFromDate(fDate));
    // setFromDate(new Date("1/14/2021"));
    dispatch(setDashBoardToDate(tDate));
  }, [dateRange, dispatch]);

  return {
    fromDate,
    toDate,
    dateRanges,
    dateRange,
    setFromDate,
    setToDate,
    setDateRange,
  };
};
