/*
 * File: use-keyboard-shortcuts.hook.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 21st April 2022 1:59:20 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useKeyPress } from "ahooks";
import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";
import { useAppDispatch } from "hooks/use-redux";
import { useBatchLabelingContext } from "pages/labeler/batch-labeling/context/batch-labeling.context";
import {
  toggleAnnotationLabel,
  toggleAnnotationFill,
  toggleDisplayTextValue,
  toggleSynchronizerTool,
} from "store/labeler/image-workspace/cornerstone/cornerstone.slice";
import { bboxRulerDisplayToggled } from "store/labeler/image-workspace/editor-setting/editor-setting.slice";
import { imageAnnotationDownVotedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-annotation-downvoted.thunk";
import { imageAnnotationVotedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-annotation-voted.thunk";
import {
  removeAllAnnotations,
  imageAnnotationClassMenuOpened,
  imageAnnotationAttributeMenuOpened,
} from "store/labeler/image-workspace/image-workspace.slice";

import { useImageLabelingContext } from "../context/image-labeling.context";
import { useImageEditorContext } from "../image-editor-context/image-editor.context";

export function useKeyboardShortcuts() {
  const dispatch = useAppDispatch();
  const { selectNextJob, selectPreviousJob } = useBatchLabelingContext();
  const {
    selectLabelByIndex,
    selectNextLabel,
    selectPreviousLabel,
    selectCornerstoneTool,
    saveCurrentJob,
    submitCurrentJob,
  } = useImageLabelingContext();
  const { viewportEditor, cornerstoneHandler } = useImageEditorContext();

  function shouldIgnoreEvent(event: KeyboardEvent) {
    let ignore = false;
    if (event.target && (event.target as HTMLElement)) {
      const tagName = (event.target as HTMLElement).tagName;
      if (tagName === "TEXTAREA" || tagName === "INPUT") {
        ignore = true;
      }
    }
    if (!ignore) {
      event.stopPropagation();
      event.preventDefault();
      event.stopImmediatePropagation();
      return false;
    }
    return ignore;
  }

  function handleNext(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    selectNextJob();
  }

  function handleBack(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    selectPreviousJob();
  }

  function handleUndo(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
  }

  function handleRedo(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
  }

  async function handleSave(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    await saveCurrentJob();
  }

  async function handleSubmit(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    await submitCurrentJob();
  }

  function handleDeleteAllRegions(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    dispatch(removeAllAnnotations());
  }

  function handleDuplicateSelectedRegion(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    const annotation = cornerstoneHandler.current?.getActiveMeasurement();
    if (!annotation) return;
    // const observation = annotationToObservation(annotation);
    // const newAnnotation = observationToAnnotation(observation);
    // cornerstoneHandler.current?.addAnnotation(newAnnotation);
  }

  function handleDeleteSelectedAnnotation(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    cornerstoneHandler.current?.deleteActiveAnnotation();
  }

  function handleUpVoteSelectedAnnotation(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    const measurement = cornerstoneHandler.current?.getActiveMeasurement();
    if (!measurement || measurement.type === ToolName.MultiArrowConnection) {
      return;
    }
    dispatch(imageAnnotationVotedAsync({ annotationId: measurement.uuid }));
  }

  function handleDownVoteSelectedAnnotation(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    const measurement = cornerstoneHandler.current?.getActiveMeasurement();
    if (!measurement || measurement.type === ToolName.MultiArrowConnection) {
      return;
    }
    dispatch(imageAnnotationDownVotedAsync({ annotationId: measurement.uuid }));
  }


  function handleChangeSelectedAnnotationClass(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    const activeMeasurement =
      cornerstoneHandler.current?.getActiveMeasurement();
    if (
      !activeMeasurement ||
      activeMeasurement.type === ToolName.MultiArrowConnection
    )
      return;
    dispatch(imageAnnotationClassMenuOpened(activeMeasurement.uuid));
  }

  // function handleAddPolygon(event: KeyboardEvent) {
  //   if (shouldIgnoreEvent(event)) return;
  //   const measurement = cornerstoneHandler.current?.getActiveMeasurement();
  //   if (measurement?.type !== AnnotateType.POLYGON) return;
  //   cornerstoneHandler.current?.modifyPolygon(measurement.uuid, "add");
  //   dispatch(setActiveCornerstoneTool(ToolName.FreehandRoiExtend));
  //   if (measurement.labelId) {
  //     dispatch(observationAnnotationSelected(measurement.labelId));
  //   }
  // }

  // function handleSubtractPolygon(event: KeyboardEvent) {
  //   if (shouldIgnoreEvent(event)) return;
  //   const measurement = cornerstoneHandler.current?.getActiveMeasurement();
  //   if (measurement?.type !== AnnotateType.POLYGON) return;
  //   cornerstoneHandler.current?.modifyPolygon(measurement.uuid, "subtract");
  //   dispatch(setActiveCornerstoneTool(ToolName.FreehandRoiExtend));
  //   if (measurement.labelId) {
  //     dispatch(observationAnnotationSelected(measurement.labelId));
  //   }
  // }

  function handleSelectTool(event: KeyboardEvent, tool: ToolName) {
    if (shouldIgnoreEvent(event)) return;
    selectCornerstoneTool(tool);
  }

  function handleZoomIn(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    viewportEditor.current?.zoomIn();
  }

  function handleZoomOut(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    viewportEditor.current?.zoomOut();
  }

  function handleIncreaseContrast(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    viewportEditor.current?.increaseContrast();
  }

  function handleDecreaseContrast(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    viewportEditor.current?.decreaseContrast();
  }

  function handleResetAll(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    viewportEditor.current?.resetAll();
  }

  function handleToggleObjectTitle(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    dispatch(toggleAnnotationLabel());
  }

  function handleToggleObjectShape(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    dispatch(toggleAnnotationFill());
  }

  function handleToggleDisplayTextValue(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    dispatch(toggleDisplayTextValue());
  }

  function handleCancelCurrentAction(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    cornerstoneHandler.current?.cancelDrawing();
  }

  function handleCompleteCurrentAction(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    cornerstoneHandler.current?.completeDrawing();
  }

  function handleToggleBBoxRuler(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    dispatch(bboxRulerDisplayToggled());
  }

  function handleSelectLabel(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    const key = parseInt(event.key);
    if (!key || key < 1 || key > 9) return;
    selectLabelByIndex(key - 1);
  }

  function handleSelectNextLabel(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    selectNextLabel();
  }

  function handleSelectPreviousLabel(event: KeyboardEvent) {
    if (shouldIgnoreEvent(event)) return;
    selectPreviousLabel();
  }

  function handleSelectAnnotation() {
    const annotation = cornerstoneHandler.current?.getActiveMeasurement();
    if (!annotation) return;
    if (ToolName.MultiArrowConnection === annotation?.type) return;
    dispatch(imageAnnotationAttributeMenuOpened(annotation.uuid));
  }

  function handleSelectSynchronizerTool() {
    dispatch(toggleSynchronizerTool());
  }

  const option = { target: document };

  useKeyPress(["ctrl.shift.z", "meta.shift.z"], handleRedo, option);
  useKeyPress(["ctrl.z", "meta.z"], handleUndo, option);
  useKeyPress(["ctrl.s", "meta.s"], handleSave, option);
  useKeyPress(["ctrl.shift.space", "meta.shift.space"], handleSubmit, option);
  useKeyPress(["ctrl.-", "meta.-"], handleZoomIn, option);
  useKeyPress(["ctrl.=", "meta.="], handleZoomOut, option);
  useKeyPress(["=", "shift.+"], handleIncreaseContrast, option);
  useKeyPress(["-", "shift._"], handleDecreaseContrast, option);
  // useKeyPress(["="], handleAddPolygon, option);
  // useKeyPress(["-"], handleSubtractPolygon, option);
  useKeyPress(["ctrl.Backspace"], handleDeleteAllRegions, option);
  useKeyPress("C", handleChangeSelectedAnnotationClass, option);
  useKeyPress("Delete", handleDeleteSelectedAnnotation, option);
  useKeyPress("U", handleUpVoteSelectedAnnotation, option);
  useKeyPress("D", handleDownVoteSelectedAnnotation, option);
  useKeyPress("ArrowLeft", handleBack, option);
  useKeyPress("ArrowRight", handleNext, option);
  useKeyPress(
    ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    handleSelectLabel,
    option
  );
  useKeyPress(["ArrowUp"], handleSelectPreviousLabel, option);
  useKeyPress(["ArrowDown"], handleSelectNextLabel, option);
  useKeyPress(["shift.r"], handleResetAll, option);
  useKeyPress(["meta.d"], handleDuplicateSelectedRegion, option);
  useKeyPress(",", handleToggleObjectShape, option);
  useKeyPress("T", handleToggleDisplayTextValue, option);
  useKeyPress("escape", handleCancelCurrentAction, option);
  useKeyPress("enter", handleCompleteCurrentAction, option);
  useKeyPress("space", handleSelectAnnotation, option);
  useKeyPress("H", handleToggleBBoxRuler, option);
  useKeyPress("S", handleSelectSynchronizerTool, option);
  useKeyPress("P", (evt) => handleSelectTool(evt, ToolName.Pan), option);
  useKeyPress("E", (evt) => handleSelectTool(evt, ToolName.Eraser), option);
  useKeyPress("M", (evt) => handleSelectTool(evt, ToolName.Magnify), option);
  useKeyPress("W", (evt) => handleSelectTool(evt, ToolName.Wwwc), option);
  useKeyPress("L", (evt) => handleSelectTool(evt, ToolName.Ruler), option);
  useKeyPress("Z", (evt) => handleSelectTool(evt, ToolName.Zoom), option);
  useKeyPress((event) => event.key === ".", handleToggleObjectTitle, option);
}
