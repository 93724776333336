/*
 * File: web-builder-preview.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 17th February 2022 4:16:55 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useMount } from "ahooks";
import {
  IconClose,
  IconInformationCircle,
} from "components/common/vb-icon.component";
import { VBMaskRequesting } from "components/common/vb-mask-requesting/vb-mask-requesting.component";
import { AnnotationV1, Label } from "domain/image-labeling";
import { AIImage, AIResultStatus, ServingWebStatus } from "domain/web-builder";
import { NotFoundPage } from "pages/authorization/not-found/not-found.page";
import { AIImageModal } from "pages/customer/web-builder/components/ai-image-modal/ai-image-modal.component";
import { useWebBuilder } from "pages/customer/web-builder/hooks/use-web-builder.hook";
import {
  useWebHealthCheck,
  WebStatus,
} from "pages/customer/web-builder/hooks/use-web-health-check.hook";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";
import { ServingWebService } from "services/label-service/apis/serving-web.api";
import { classnames } from "utilities/classes";
import { WebBuilderPreviewContext } from "./web-builder-preview.context";
import * as Sentry from "@sentry/react";

interface Props {
  children: JSX.Element;
  publicAccess?: boolean;
}
export const WebBuilderPreviewProvider = ({
  children,
  publicAccess = false,
}: Props) => {
  const [processing, setProcessing] = useState(false);
  const { modelId } = useParams<{ modelId: string }>();
  const [template] = useState("default_general_image");
  const { web, metadata, loading } = useWebBuilder(modelId, publicAccess);
  const { status } = useWebHealthCheck(modelId);
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [images, setImages] = useState<AIImage[]>([]);
  const canRunModel = useMemo(() => {
    if (status !== WebStatus.PUBLISHED) return false;
    if (processing) return false;
    return !!images.find((image) => image.status === AIResultStatus.INITIAL);
  }, [processing, images, status]);
  const [selectedLabelIds, setSelectedLabelIds] = useState<number[]>([]);
  const [showAlertMessage, setShowAlertMessage] = useState(true);
  const [showTextValue, setShowTextValue] = useState(false);
  const [showAnnotationLabel, setShowAnnotationLabel] = useState(false);
  const [fillAnnotationShape, setFillAnnotationShape] = useState(false);

  function toggleTextValue() {
    setShowTextValue(!showTextValue);
  }

  function toggleAnnotationLabel() {
    setShowAnnotationLabel(!showAnnotationLabel);
  }

  function toggleFillAnnotationShape() {
    setFillAnnotationShape(!fillAnnotationShape);
  }

  function selectLabel(labelId: number) {
    if (!selectedLabelIds.includes(labelId)) {
      setSelectedLabelIds([...selectedLabelIds, labelId]);
    } else {
      setSelectedLabelIds(selectedLabelIds.filter((id) => id !== labelId));
    }
  }

  const isPublishing = useMemo(() => {
    return (
      !loading &&
      status === WebStatus.PUBLISHING &&
      ServingWebStatus.Activated === web?.status
    );
  }, [status, web, loading]);

  const isNotPublished = useMemo(() => {
    return !loading && ServingWebStatus.Deactivated === web?.status;
  }, [web, loading]);

  const isNotAvailable = useMemo(() => {
    return publicAccess && !loading && !web;
  }, [publicAccess, loading, web]);

  const startPredict = useCallback(async () => {
    const processingImage = images.find(
      (image) => image.status === AIResultStatus.PROCESSING
    );
    if (processingImage) return;

    const item = images.find(
      (image) => image.status === AIResultStatus.INITIAL
    );

    if (!item) {
      setProcessing(false);
      return;
    }
    setImages([
      ...images.map((image) => {
        if (image.imageUrl === item.imageUrl) {
          return { ...item, status: AIResultStatus.PROCESSING };
        }
        return image;
      }),
    ]);
    let annotations: AnnotationV1[] = [];
    let labels: Label[] = [];
    let isFailedToDetect = false;
    try {
      if (item.file) {
        const response = await ServingWebService.predictOnImage(
          modelId,
          item.file
        );
        for (let item of response.data.payload) {
          for (let annotation of item.annotations) {
            annotations.push({
              id: -1,
              assignee: "AI",
              source: "AI",
              mediaId: -1,
              categoryId: item.classId,
              annotation: {
                type: annotation.type,
                points: annotation.points,
              },
              annotationType: annotation.type,
              workspaceId: "",
            });
          }
        }

        labels = response.data.payload.map((item) => {
          return {
            id: item.classId,
            name: item.className,
            code: item.className,
          };
        });
        if (annotations.length === 0) isFailedToDetect = true;
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      isFailedToDetect = true;
    }
    setImages([
      ...images.map((image) => {
        if (image.imageUrl === item.imageUrl) {
          return {
            ...item,
            status: isFailedToDetect
              ? AIResultStatus.NOTFOUND
              : AIResultStatus.COMPLETED,
            annotations: annotations,
            labels,
          };
        }
        return image;
      }),
    ]);
  }, [images, modelId]);

  function onFilesSelected(files: File[]) {
    const items = [];
    for (let file of files) {
      const url = URL.createObjectURL(file);
      if (images.find((image) => file.name === image.file?.name)) continue;
      items.push({
        file: file,
        imageUrl: url,
        status: AIResultStatus.INITIAL,
        annotations: [],
        labels: [],
      });
    }
    setImages([...items, ...images]);
  }

  function removeFile(url: string) {
    setImages([...images.filter((image) => image.imageUrl !== url)]);
  }

  function selectFile(url: string) {
    const index = images.findIndex((image) => image.imageUrl === url);
    setSelectedImageIndex(index);
  }

  function selectNext() {
    setSelectedImageIndex((selectedImageIndex + 1) % images.length);
  }

  function selectPrevious() {
    const nextIndex = selectedImageIndex - 1 + images.length;
    setSelectedImageIndex(nextIndex % images.length);
  }

  function handleClose() {
    setSelectedImageIndex(-1);
  }

  function runModel() {
    setProcessing(true);
  }

  function handleCloseAlert() {
    setShowAlertMessage(false);
  }

  useEffect(() => {
    if (processing) startPredict();
  }, [processing, startPredict]);

  const state = {
    loading,
    processing,
    template,
    templateMetadata: metadata,
    images,
    selectedImageIndex,
    canRunModel,
    showTextValue,
    showAnnotationLabel,
    fillAnnotationShape,
    selectedLabelIds,
    selectLabel,
    toggleTextValue,
    toggleAnnotationLabel,
    toggleFillAnnotationShape,
    onFilesSelected,
    removeFile,
    selectFile,
    runModel,
    selectNext,
    selectPrevious,
  };

  return (
    <WebBuilderPreviewContext.Provider value={state}>
      {!isNotAvailable && (
        <>
          {!loading && children}
          {loading && <VBMaskRequesting />}
          {selectedImageIndex !== -1 && (
            <AIImageModal
              onClose={handleClose}
              onNext={selectNext}
              onPrevious={selectPrevious}
              image={images[selectedImageIndex]}
            />
          )}
          {showAlertMessage && isPublishing && (
            <AIModelPublishingMessage onClose={handleCloseAlert} />
          )}
          {showAlertMessage && isNotPublished && (
            <AIModelNotPublishMessage onClose={handleCloseAlert} />
          )}
        </>
      )}

      {isNotAvailable && <NotFoundPage />}
    </WebBuilderPreviewContext.Provider>
  );
};

interface AIModelPublishingMessageProps {
  onClose?(): void;
}
const AIModelPublishingMessage = ({
  onClose,
}: AIModelPublishingMessageProps) => {
  const [mounted, setMounted] = useState(false);
  useMount(() => setTimeout(() => setMounted(true), 500));
  return createPortal(
    <div
      className={classnames(
        "fixed p-4 bg-white rounded shadow-xl bottom-10 left-8 text-background-700 transform transition-all",
        {
          "opacity-100 translate-y-0": mounted,
          "translate-y-4 opacity-0 pointer-events-none": !mounted,
        }
      )}
      style={{ zIndex: 99999 }}
    >
      <div className="flex gap-4 py-2">
        <div className="p-0.5">
          <IconInformationCircle className="w-6 h-6 text-primary" />
        </div>
        <div className="flex-auto space-y-2">
          <div className="text-lg font-semibold">
            The webpage is publishing...
          </div>
          <p className="max-w-xs">
            This action will take several minutes. Some functions might not be
            working as expected.
          </p>
        </div>
        <button
          onClick={onClose}
          className="flex items-center justify-center flex-none w-6 h-6"
        >
          <IconClose className="flex-none w-5 h-5" />
        </button>
      </div>
    </div>,
    document.body
  );
};

interface AIModelNotPublishMessageProps {
  onClose?(): void;
}
const AIModelNotPublishMessage = ({
  onClose,
}: AIModelNotPublishMessageProps) => {
  const [mounted, setMounted] = useState(false);
  useMount(() => setTimeout(() => setMounted(true), 500));
  return createPortal(
    <div
      className={classnames(
        "fixed p-4 bg-white rounded shadow-xl bottom-10 left-8 text-background-700 transform transition-all",
        {
          "opacity-100 translate-y-0": mounted,
          "translate-y-4 opacity-0 pointer-events-none": !mounted,
        }
      )}
      style={{ zIndex: 99999 }}
    >
      <div className="flex gap-4 py-2">
        <div className="p-0.5">
          <IconInformationCircle className="w-6 h-6 text-primary" />
        </div>
        <div className="flex-auto space-y-2">
          <div className="text-lg font-semibold">Preview mode</div>
          <p className="max-w-xs">Your webpage is not published.</p>
        </div>
        <button
          onClick={onClose}
          className="flex items-center justify-center flex-none w-6 h-6"
        >
          <IconClose className="flex-none w-5 h-5" />
        </button>
      </div>
    </div>,
    document.body
  );
};
