/*
 * File: notificaiton.state.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 15th September 2021 2:55:36 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export interface NotificationState {
  registrationToken: string;
}

export const defaultNotificationState: NotificationState = {
  registrationToken: "",
};
