/*
 * File: use-web-builder.hook.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 25th February 2022 11:21:13 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useMount } from "ahooks";
import { MLWeb } from "domain/web-builder";
import { useAppDispatch } from "hooks/use-redux";
import { useState } from "react";
import { ServingWebService } from "services/label-service/apis/serving-web.api";
import { mlWebMapper } from "pages/customer/ml-models/ml-web/mapper/ml-web.mapper";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import * as Sentry from "@sentry/react";

export const useWebBuilder = (pageId: string, publicAccess = false) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [web, setWeb] = useState<MLWeb | null>(null);
  const [metadata, setMetadata] = useState<Record<string, string>>({
    title: "Full service for AI Builders",
    description:
      `Model: Detect cat in images\n
    Version: 1.0.0\n
    Type: Object detection\n
    Labels: Cat, dog, animals\n
    Published: ` + new Date().toDateString(),
    txtImportData: "Import data",
    txtRunModel: "Run model",
    txtCheckResult: "Check the result",
    previewImageUrl: "",
  });

  async function loadDataAsync() {
    if (loading) return;
    try {
      setLoading(true);
      const response = publicAccess
        ? await ServingWebService.getServingWebInfo(pageId)
        : await ServingWebService.getServingWebById(pageId);
      const data = response.data;

      if (
        data &&
        data.hasOwnProperty("succeeded") &&
        (data as any)["succeeded"] === false
      ) {
        throw new Error();
      }
      const webData = mlWebMapper.toEntity(data);
      setWeb(webData);
      if (Object.keys(webData.data).length > 0) {
        setMetadata(webData.data);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log("Failed to load web data", error);
    } finally {
      setLoading(false);
    }
  }

  async function saveMetadataAsync(
    metadata: Record<string, string>,
    modelId?: number
  ) {
    try {
      if (!modelId) throw new Error("AI Model is required");
      const payload = {
        modelMappingId: modelId,
        data: metadata,
      };
      const response = await ServingWebService.patchServingWeb(pageId, payload);
      const data = response.data;
      const webData = mlWebMapper.toEntity(data);
      setWeb(webData);
    } catch (error: any) {
      Sentry.captureException(error);
      const message = error.message || "Failed to saving model data";
      dispatch(enqueueErrorNotification(message));
    }
  }

  async function publishWebAsync(
    metadata: Record<string, string>,
    modelId?: number
  ) {
    try {
      if (!modelId) throw new Error("AI Model is required");
      const payload = {
        modelMappingId: modelId,
        data: metadata,
      };
      await ServingWebService.patchServingWeb(pageId, payload);
      const response = await ServingWebService.publishServingWeb(pageId);
      if (response.data.succeeded === true) {
        await loadDataAsync();
        dispatch(enqueueSuccessNotification("Successed"));
      } else if (response.data.succeeded === false) {
        dispatch(enqueueErrorNotification("Failed to publish this web"));
      }
    } catch (error: any) {
      Sentry.captureException(error);
      const message = error.message || "Failed to publish this web";
      dispatch(enqueueErrorNotification(message));
    }
  }

  async function depublishWebAsync() {
    try {
      const response = await ServingWebService.depublishServingWeb(pageId);
      if (response.data.succeeded) {
        await loadDataAsync();
        dispatch(enqueueSuccessNotification("Successed"));
      }
    } catch (error: any) {
      Sentry.captureException(error);
      const message = error.message || "Failed to deactive this web";
      dispatch(enqueueErrorNotification(message));
    }
  }

  useMount(loadDataAsync);

  return {
    loading,
    web,
    metadata,
    setMetadata,
    saveMetadataAsync,
    publishWebAsync,
    depublishWebAsync,
    loadDataAsync,
  };
};
