/*
 * File: task.api.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 20th July 2021 3:14:35 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AxiosResponse } from "axios";
import { BatchDTO } from "../dtos";
import { TaskDTO } from "../dtos/task.dto";
import { BaseAPI } from "./base.api";

export interface CreateTaskPayload {
  batch: Partial<BatchDTO>;
  status: string;
  storeType: string;
  taskReference: string;
  url: string | null;
}

export interface ReopenTasksRequest {
  taskIds: number[];
  reason?: string;
}
export class TaskAPI extends BaseAPI<TaskDTO> {
  createTasks(items: CreateTaskPayload[]) {
    const data = JSON.stringify(items);
    return this.post(`${this.getPath()}/list`, data);
  }

  getTaskById(taskId: number): Promise<AxiosResponse<TaskDTO>> {
    return this.getItem(taskId);
  }

  getGroupTasksById(taskId: number): Promise<AxiosResponse<TaskDTO[]>> {
    return this.get(`${this.getPath()}/${taskId}/group`);
  }

  releaseTasks(
    batchId: string | number,
    taskIds?: number[],
    assignees?: string[]
  ) {
    const payload = { batchId, taskIds, assignees };
    return this.post(`${this.getPath()}/revoke`, payload);
  }

  reopenTasks(payload: ReopenTasksRequest) {
    return this.post(`${this.getPath()}/reopen`, payload);
  }
  skipTasks(payload: ReopenTasksRequest) {
    return this.post(`${this.getPath()}/skip`, payload);
  }
}
