/*
 * File: keycloak.state.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 2nd August 2021 12:20:58 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { UserRole } from "store/auth/auth.state";

export interface KeycloakAction {
  signOut(): void;
  refreshToken(): void;
  switchRole(role: UserRole): void;
  selectRole(role: UserRole): void;
  selectWorkspace(workspaceId: string): Promise<void>;
}

const defaultKeycloakAction = {} as KeycloakAction;

export interface KeycloakState extends KeycloakAction {}

export const defaultKeycloakState: KeycloakState = {
  ...defaultKeycloakAction,
};
