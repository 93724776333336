import { PayloadAction } from "@reduxjs/toolkit";
import { Label } from "domain/image-labeling";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { imageLabelMapper } from "services/label-service/mappers/image-label.mapper";
import { ImageWorkspaceState } from "../image-workspace.state";
import {
  INITIAL_IMAGE_LABELING_STATE,
  JobObservationModel,
  SelectedAnnotation,
} from "./image-labeling.state";

export const imageLabelingActions = {
  resetImageLabelingState({ imageLabeling }: ImageWorkspaceState) {
    // const selectedSystemObservationId =
    //   imageLabeling.selectedSystemObservationId;
    const selectedObservationId = imageLabeling.selectedObservationId;
    Object.assign(imageLabeling, INITIAL_IMAGE_LABELING_STATE);
    imageLabeling.selectedObservationId = selectedObservationId;
    // imageLabeling.selectedSystemObservationId = selectedSystemObservationId;
  },
  ocrAnnotationSelected: (
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<SelectedAnnotation | null>
  ) => {
    imageLabeling.selectedAnnotation = action.payload;
  },
  setWorkingFile: (
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<StorageFileDTO>
  ) => {
    imageLabeling.file = action.payload;
  },
  setSelectedSystemObservationId: (
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<number>
  ) => {
    // imageLabeling.selectedSystemObservationId = action.payload;
  },
  setSelectedLabels: (
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<string[]>
  ) => {
    const selectedIds = action.payload;

    imageLabeling.labels = imageLabeling.labels.map((b) => {
      const selected = selectedIds.indexOf(b.id.toString()) !== -1;
      return {
        ...b,
        selected,
      };
    });
  },
  autoAnnotatedLabels: (
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<string[]>
  ) => {
    const selectedIds = action.payload;
    imageLabeling.labels = imageLabeling.labels.map((b) => {
      const selected =
        b.selected || selectedIds.indexOf(b.id.toString()) !== -1;
      return {
        ...b,
        selected,
      };
    });
  },
  setFindingLabelerSelected: (
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<{
      labelId: string;
      userId: string;
      selected: boolean;
    }>
  ) => {
    imageLabeling.findingLabelers = imageLabeling.findingLabelers.map(
      (finding) => {
        if (finding.labelId === action.payload.labelId) {
          return {
            ...finding,
            users: [
              ...finding.users.map((u) => {
                if (u.userId === action.payload.userId) {
                  return {
                    ...u,
                    selected: action.payload.selected,
                  };
                }
                return u;
              }),
            ],
          };
        }
        return finding;
      }
    );
  },
  setSelectedObservationId: (
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<number>
  ) => {
    imageLabeling.selectedObservationId = action.payload;
    // imageLabeling.selectedSystemObservationId = -1;
  },

  labelIndexSelected: (
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<number>
  ) => {
    const index = action.payload;
    const { labels } = imageLabeling;
    const flatLabels: Label[] = imageLabelMapper.flattenLabels(labels, []);
    if (index >= 0 && index < flatLabels.length) {
      const { batchObservationId } = flatLabels[index];
      if (batchObservationId) {
        imageLabeling.selectedObservationId = batchObservationId;
      }
    }
  },
  observationAnnotationSelected: (
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<number>
  ) => {
    const label = imageLabeling.labels.find((b) => b.id === action.payload);
    if (label) imageLabeling.selectedObservationId = label.id;
  },

  toggleObservationVisible(
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<{
      labelId: number;
    }>
  ) {
    const { labelId } = action.payload;
    imageLabeling.labels = imageLabeling.labels.map((item) => {
      if (item.id === labelId) {
        return {
          ...item,
          hidden: !item.hidden,
        };
      }
      return item;
    });
  },
  observationUpdated(
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<{
      label: Label;
    }>
  ) {
    const { label } = action.payload;
    imageLabeling.labels = imageLabeling.labels.map((item) => {
      if (item.id === label.id) {
        return {
          ...label,
        };
      }
      return item;
    });
  },
  setObservationVisible(
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<{
      label: Label;
      visible: boolean;
    }>
  ) {
    const { label, visible } = action.payload;
    imageLabeling.labels = imageLabeling.labels.map((item) => {
      if (item.id === label.id) {
        return {
          ...item,
          hidden: !visible,
        };
      }
      return item;
    });
  },
  setObservationSelected(
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<{
      label: Label;
      selected: boolean;
    }>
  ) {
    const { label, selected } = action.payload;

    imageLabeling.labels = imageLabeling.labels.map((item) => {
      if (item.id === label.id) {
        return { ...item, selected: !selected };
      }
      return item;
    });
  },
  fetchNewJob: ({ imageLabeling }: ImageWorkspaceState) => {
    Object.assign(imageLabeling, INITIAL_IMAGE_LABELING_STATE);
  },

  setJobObservationSelected(
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<{
      jobObservation: JobObservationModel;
      selected: boolean;
    }>
  ) {
    imageLabeling.previousJobObservations =
      imageLabeling.previousJobObservations.map((item) => {
        if (item.jobId === action.payload.jobObservation.jobId) {
          return {
            ...item,
            selected: action.payload.selected,
          };
        }
        return item;
      });
  },
  setViewMediaAgreement(
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<boolean>
  ) {
    imageLabeling.viewMediaAgreement = action.payload;
  },
  resetSelectedUsers({ imageLabeling }: ImageWorkspaceState) {
    imageLabeling.findingLabelers = imageLabeling.findingLabelers.map(
      (findingLabeler) => {
        return {
          ...findingLabeler,
          users: findingLabeler.users.map((user) => {
            return {
              ...user,
              selected: false,
            };
          }),
        };
      }
    );
  },
  setImageLabelingError(
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<string>
  ) {
    imageLabeling.error = action.payload;
  },

  annotationAdded(
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<number>
  ) {
    imageLabeling.labels = imageLabeling.labels.map((label) => {
      if (label.id === action.payload && !label.selected) {
        return { ...label, selected: true };
      }
      return label;
    });
  },
  allAnnotationsRemoved(
    { imageLabeling }: ImageWorkspaceState,
    action: PayloadAction<number>
  ) {
    imageLabeling.labels = imageLabeling.labels.map((label) => {
      if (label.id === action.payload && label.selected) {
        return { ...label, selected: false };
      }
      return label;
    });
  },
};
