/*
 * File: error-dialog.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 18th October 2021 1:58:52 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { useTranslation } from "react-i18next";

interface Props {
  error: string;
  visible: boolean;
  onClose(): void;
  onSubmit?(): void;
}

export const ImageLabelingErrorDialog = ({
  error,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  return (
    <VBModal
      open
      title="Oops!"
      onClose={onClose}
      textSubmit={t("common:buttonSkip")}
      textClose={t("common:buttonExit")}
      onSubmit={onSubmit}
      className="pb-40"
    >
      <div className="flex flex-col gap-4 mb-4">
        <div className="text-xl font-bold">{error}</div>
        <p className="leading-normal text-gray-600">
          {t("labelerworkspace:errors.errorSkippableMessage")}
        </p>
      </div>
    </VBModal>
  );
};
