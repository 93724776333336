/*
 * File: create-workspace-dialog.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { createWorkspaceAsync } from "store/common/user-workspace/user-workspace.thunk";
import { useRef, useState } from "react";
import { GroupDTO } from "services/user-service/dtos/group.dto";
import { v4 } from "uuid";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { useKeycloakContext } from "contexts/keycloak/keycloak.context";
import { useKeyPress } from "ahooks";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { Logger } from "utilities/logger";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import * as Sentry from "@sentry/react";
import { selectUserWorkspaces } from "store/common/user-workspace/user-workspace.selectors";

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

export const CreateWorkspaceDialog = ({ open, onClose }: DialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [processing, setProcessing] = useState(false);
  const { selectWorkspace, refreshToken } = useKeycloakContext();
  const [isValidInput, setIsValidInput] = useState(false);
  const workspaces = useAppSelector(selectUserWorkspaces);
  const [errMessage, setErrMessage] = useState("");

  async function createWorkspace(name: string) {
    if (processing) return;
    setProcessing(true);
    try {
      const workspacePayload: GroupDTO = {
        groupId: v4(),
        groupName: name,
        lastSelect: Date.now(),
      };
      const response = await dispatch(createWorkspaceAsync(workspacePayload));
      handleThunkRejected(response);
      refreshToken();
      try {
        const newWorkspace: GroupDTO = response.payload;
        if (newWorkspace) selectWorkspace(newWorkspace.groupId);
      } catch (error) {
        Sentry.captureException(error);
        Logger.log(error);
      }
      dispatch(enqueueSuccessNotification(t("common:textCreatedSuccess")));
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(error));
    } finally {
      setProcessing(false);
    }
  }

  function handleInput() {
    const value = inputRef.current?.value.trim() ?? "";
    const isExisted = workspaces.some(
      (w) => w.groupName.toLowerCase() === value.toLowerCase()
    );
    const valid = !!value && !isExisted;
    setIsValidInput(valid);
    setErrMessage(isExisted ? "This workspace as been existed" : "");
  }

  async function handleSubmit() {
    try {
      const name = inputRef.current?.value;
      if (!name) return;
      await createWorkspace(name);
      onClose();
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || t("common:textCreatedFailed");
      dispatch(enqueueErrorNotification(errMessage));
    }
  }

  useKeyPress(KeyboardKey.Enter, handleSubmit, {
    target: containerRef.current,
  });

  return (
    <VBModal
      title={t("workspace:createDialog.title")}
      open={open}
      onClose={onClose}
      textSubmit={t("common:buttonCreate")}
      onSubmit={handleSubmit}
      disableSubmit={processing || !isValidInput}
      blockUI={processing}
      processingIndicator={processing}
    >
      <div className="w-full py-4" ref={containerRef}>
        <input
          autoFocus
          disabled={processing}
          ref={inputRef}
          type="text"
          className="w-full h-10 px-4 bg-white border rounded border-background-300 focus:border-primary"
          required
          onInput={handleInput}
          placeholder={t("workspace:createDialog.labelName")}
        />
        {errMessage && (
          <p className="mt-2 text-sm text-red-500">{errMessage}</p>
        )}
      </div>
    </VBModal>
  );
};
