/*
 * File: labeling.constant.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 14th April 2022 3:01:08 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Routes } from "routers/config/routes";

export enum LabelingType {
  IMAGE_DETECTION = "image_detection",
  IMAGE_SEGMENTATION = "image_segmentation",
  IMAGE_CLASSIFICATION = "image_classification",
  IMAGE_OCR = "image_ocr",
  TEXT_NER_RE = "text_ner_re",
  TEXT_NER = "text_ner",
  TEXT_TTS = "text_tts",
  TEXT_COR = "text_cor",
  AUDIO_STT = "audio_stt",

  MED_XRAY_DETECTION = "med_xray_detection",
  MED_XRAY_SEGMENTATION = "med_xray_segmentation",
  MED_XRAY_CLASSIFICATION = "med_xray_classification",

  MED_MDI_SEGMENTATION = "med_mdi_segmentation", // 3d
  MED_MAM_SEGMENTATION = "med_mam_segmentation", // breasts

  // wsi
  WSI_SEGMENTATION = "wsi_segmentation",
  WSI_DETECTION = "wsi_detection",

  // remove later replaced with MED_MDI_SEGMENTATION
  // just keep it for now for backward compatible
  MDI_SEGMENTATION = "mdi_segmentation",
}

const routesMap: Record<string, string> = {
  [LabelingType.IMAGE_SEGMENTATION]: Routes.IMAGE_REVIEW,
  [LabelingType.IMAGE_DETECTION]: Routes.IMAGE_REVIEW,
  [LabelingType.IMAGE_CLASSIFICATION]: Routes.IMAGE_REVIEW,
  [LabelingType.IMAGE_OCR]: Routes.IMAGE_REVIEW,
  [LabelingType.MED_XRAY_CLASSIFICATION]: Routes.IMAGE_REVIEW,
  [LabelingType.MED_XRAY_DETECTION]: Routes.IMAGE_REVIEW,
  [LabelingType.MED_XRAY_SEGMENTATION]: Routes.IMAGE_REVIEW,
  [LabelingType.MED_MAM_SEGMENTATION]: Routes.IMAGE_REVIEW,
  [LabelingType.TEXT_NER_RE]: Routes.TEXT_REVIEW,
  [LabelingType.TEXT_NER]: Routes.TEXT_REVIEW,
  [LabelingType.TEXT_COR]: Routes.TEXT_REVIEW,
  [LabelingType.AUDIO_STT]: Routes.SPEECH_TO_TEXT_REVIEW,
  [LabelingType.TEXT_TTS]: Routes.TEXT_TO_SPEECH_REVIEW,
  [LabelingType.MDI_SEGMENTATION]: Routes.THREE_D_REVIEW,
  [LabelingType.MED_MDI_SEGMENTATION]: Routes.THREE_D_REVIEW,
  [LabelingType.WSI_SEGMENTATION]: Routes.PATHOLOGY_REVIEW,
  [LabelingType.WSI_DETECTION]: Routes.PATHOLOGY_REVIEW,
};

export function getReviewRoute(type: string) {
  const projectType = type;
  if (routesMap.hasOwnProperty(projectType)) {
    return routesMap[projectType];
  }
  return Routes.LABELER_HOME;
}
