/*
 * File: ml-web.context.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 18th February 2022 11:08:59 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { MLWebContextState } from "./ml-web.state";

export const MLWebContext = createContext({} as MLWebContextState);

export const useMLWebContext = () => {
  return useContext(MLWebContext);
};
