import axios, { AxiosInstance, AxiosResponse } from "axios";
import { ENV_CONFIG } from "configs/env.config";
import { setupLabelInterceptor } from "services/common/label-interceptor";
import { TicketRequestDTO, TicketResponseDTO } from "./customer.dto";

let _axiosInstance: AxiosInstance | undefined = undefined;

function getAxiosInstance() {
  if (_axiosInstance) return _axiosInstance;
  _axiosInstance = setupLabelInterceptor(
    axios.create({
      baseURL: ENV_CONFIG.CUSTOMER_SERVICE_URL,
    })
  );
  return _axiosInstance;
}

const getAllTickets = (): Promise<AxiosResponse<TicketResponseDTO[]>> => {
  return getAxiosInstance().get("/api/v1/ticket");
};

const createTicket = (payload: TicketRequestDTO) => {
  return getAxiosInstance().post("/api/v1/ticket", payload);
};

export const CustomerService = {
  getAllTickets,
  createTicket,
};
