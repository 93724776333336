import axios, { AxiosResponse } from "axios";
import { fromBlob } from "file-type/browser";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { AuthService } from "services/auth";
import { persistenceImageLoader } from "utilities/image-loader/indexeddb-cache";
import {
  ResourceCredentialResponse,
  SasRequestDTO,
  StorageResource,
} from "../dto/resource.dto";
import { storageAPIget, storageAPIpost } from "../storage-httpclient";
import { configureAxiosCacheInstance } from "./axios-cache.api";
import * as Sentry from "@sentry/react";

let currentWorkspaceSasToken: ResourceCredentialResponse | undefined =
  undefined;
let currentWorkspaceSasTokensMap: Record<string, ResourceCredentialResponse> =
  {};

export async function getResourceCredential(source: string = "Labeling") {
  return storageAPIget(`/api/v1/storage/resource/credential?source=${source}`);
}

export async function createStorageResource(
  source: string = "Labeling",
  extension: string = "",
  workspaceId?: string
) {
  let url = `/api/v1/storage/resource?source=${source}`;
  if (extension) {
    url = `${url}&extension=${extension}`;
  }

  // If pass the workspaceId
  // we create the resource at a specific workspace
  let previousUserWorkspace = AuthService.getUserWorkspace();
  if (workspaceId) {
    // Because the interceptor get the workspace from AuthService.getUserWorkspace()
    // to add to the header request, so we temporally set the workspace to workspaceId.
    AuthService.setUserWorkspace(workspaceId);
  }

  try {
    const res = await storageAPIpost(url);
    return res;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  } finally {
    // Set it back to the previous workspace
    AuthService.setUserWorkspace(previousUserWorkspace);
  }
}

export async function getStorageResource(resourceId: string) {
  return storageAPIget(`/api/v1/storage/resource/${resourceId}`);
}

export async function processStorageResource(resourceId: string) {
  return storageAPIpost(`/api/v1/storage/resource/${resourceId}/process`);
}

export async function getWorkspaceSasToken(workspace?: string) {
  try {
    const durationInHour = 12;
    let hourDiff = 12;
    if (currentWorkspaceSasToken && currentWorkspaceSasToken.exipredDate) {
      const now = new Date();
      hourDiff =
        (now.getTime() - currentWorkspaceSasToken.exipredDate.getTime()) /
        3.6e6;
    }
    // Get tokkens for all workspaces for the current user.
    if (!currentWorkspaceSasToken || hourDiff < 6) {
      const payload: SasRequestDTO = {
        duration: durationInHour,
      };
      const res = await storageAPIpost(`/api/v1/storage/resource/sas`, payload);

      currentWorkspaceSasTokensMap = {};
      const newTokens = res.data as ResourceCredentialResponse[];
      for (const newToken of newTokens) {
        newToken.exipredDate = new Date(newToken.expiredTime);
        if (newToken.workspaceId) {
          currentWorkspaceSasTokensMap[newToken.workspaceId] = newToken;
        }
      }

      currentWorkspaceSasToken = newTokens[0];
    }
  } catch (err: any) {
    Sentry.captureException(err);
    console.log(err);
  }

  // Ff not pass specific workspace then we get the
  // current workspace that the user currently in.
  if (!workspace) {
    workspace = AuthService.getUserWorkspace();
  }

  return currentWorkspaceSasTokensMap[workspace].token;
}

async function getUrlWithSasToken(url: string, workspace?: string): Promise<string> {
  const sasToken = await getWorkspaceSasToken(workspace);
  if (sasToken) {
    // remove any current URL params
    url = url.split("?")[0];
    return `${url}?${sasToken}`;
  }
  return url;
}

export async function getUrlWithWorkspaceSasToken(
  url: string,
  workspace?: string
) {
  return getUrlWithSasToken(url, workspace);
}

export async function getSasUrl(
  resource: StorageFileDTO,
  workspace?: string
) {
  if (resource.isRefer) {
    return resource.url;
  }
  return getUrlWithSasToken(resource.url, workspace);
}


export async function getPublicFileContentAsBlob(
  fileUrl: string,
  // concat sas token for the workspace id if this field is presented
  sasWorkspaceId?: string
): Promise<AxiosResponse<any>> {
  if (sasWorkspaceId) {
    fileUrl = await getUrlWithWorkspaceSasToken(fileUrl, sasWorkspaceId);
  }
  const client = await configureAxiosCacheInstance();
  return client.get(fileUrl, { responseType: "blob" });
}

export async function cacheFileToLocalUrlAndAddMineType(
  file: StorageFileDTO,
  // concat sas token for the workspace id if this field is presented
  sasWorkspaceId?: string
) {
  const urlWithSas = await getSasUrl(file, sasWorkspaceId);
  let blob;
  try {
    blob = await persistenceImageLoader(urlWithSas);
  } catch (error) {
    Sentry.captureException(error);
    const res = await getPublicFileContentAsBlob(urlWithSas);
    blob = res.data as Blob;
  }
  const localUrl = URL.createObjectURL(blob);
  const fileType = await fromBlob(blob);
  return {
    ...file,
    url: localUrl,
    mineType: fileType?.mime || "",
    originalUrl: urlWithSas,
    dicomMetaData: undefined, // dont use this field for now
  };
}

export async function uploadFile(file: File): Promise<string> {
  // get resource
  let res = await createStorageResource("Uploading");
  let resource = res.data as StorageResource;

  // upload to Azure blob
  await uploadToAzureBlob(file, resource.path);

  // process resource
  res = await processStorageResource(resource.resourceId);
  resource = res.data as StorageResource;

  return resource.downloadUrl;
}

export async function uploadZipFile(
  file: File,
  onProgress?: any
): Promise<StorageResource> {
  // get resource
  let res = await createStorageResource("Cache");
  let resource = res.data as StorageResource;

  // upload to Azure blob
  // const anonymousCredential = new AnonymousCredential();
  // const blob = new BlockBlobClient(resource.path, anonymousCredential);
  // await blob.uploadData(file, { onProgress });
  await uploadToAzureBlob(file, resource.path, onProgress);

  // process resource
  res = await processStorageResource(resource.resourceId);
  resource = res.data as StorageResource;

  return resource;
}

export async function uploadResource(file: File): Promise<StorageResource> {
  // get resource
  let res = await createStorageResource("Uploading");
  let resource = res.data as StorageResource;

  // upload to Azure blob
  await uploadToAzureBlob(file, resource.path);

  // process resource
  res = await processStorageResource(resource.resourceId);
  resource = res.data as StorageResource;

  return resource;
}

export async function uploadToAzureBlob(
  blob: Blob,
  path: string,
  onProgress?: any
) {
  return axios.put(path, blob, {
    headers: {
      Expect: "100-continue",
      "Content-Type": "application/octet-stream",
      "x-ms-blob-type": "BlockBlob",
      "x-ms-version": "2021-04-10",
    },
    onUploadProgress: onProgress,
  });
}
