import { Tooltip } from "@material-ui/core";
import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  selectComplexJobsCurrentJobId,
  selectComplexJobsWorkingStatus,
} from "store/labeler/complex-jobs/complex-jobs.selectors";
import { setComplexJobsCurrentJobId } from "store/labeler/complex-jobs/complex-jobs.slice";
import {
  ComplexJobInBatchStatus,
  WorkingStatus,
} from "store/labeler/complex-jobs/complex-jobs.state";
import { classnames } from "utilities/classes";


export const APP_EVENT_CLICK_OTHER_JOB = "app_click_other_job";
interface Props {
  index?: number;
  jobId: number;
  fileName?: string;
  tagsData?: string[];
  status: ComplexJobInBatchStatus;
}
export const JobInBatchComponent = ({
  index = 1,
  jobId,
  fileName = "",
  tagsData = undefined,
  status,
}: Props) => {
  const dispatch = useAppDispatch();
  const currentJobId = useAppSelector(selectComplexJobsCurrentJobId);
  const jobWorkingStatus = useAppSelector(selectComplexJobsWorkingStatus);

  const isWorking = jobWorkingStatus[jobId] === WorkingStatus.WORKING;
  const isSaved = jobWorkingStatus[jobId] === WorkingStatus.SAVED;

  const handleBatchJobClick = () => {
    if (status === ComplexJobInBatchStatus.LOADING) return;
    if (currentJobId !== jobId) {
      document.dispatchEvent(new CustomEvent(APP_EVENT_CLICK_OTHER_JOB));
      dispatch(setComplexJobsCurrentJobId(jobId));
    }
  };

  return (
    <div
      key={jobId}
      className={classnames(
        "border border-gray-600 rounded h-28 cursor-pointer mb-2 p-2 text-sm",
        { "border-warning-500": jobId === currentJobId }
      )}
      onClick={handleBatchJobClick}
    >
      <div className="flex flex-col w-full h-full gap-1">
        <div className="flex items-center justify-between">
          <Tooltip title={`Job id: ${jobId}`} placement="right">
            <span>#{index}</span>
          </Tooltip>
          <p>
            {isWorking && (
              <span className="text-xs text-yellow-500">working</span>
            )}
            {isSaved && <span className="text-xs text-green-500">saved</span>}
          </p>
        </div>
        <div className="flex items-center justify-center flex-1">
          {status === ComplexJobInBatchStatus.LOADING && (
            <VBInlineLoading className="flex items-center justify-center p-0" />
          )}
          {status !== ComplexJobInBatchStatus.LOADING && (
            <>
              {tagsData && (
                <div className="w-full h-full">
                  {tagsData.map((tagData) => (
                    <Tooltip title={tagData} placement="top" key={tagData}>
                      <div className="truncate">{tagData}</div>
                    </Tooltip>
                  ))}
                </div>
              )}
              {!tagsData && (
                <Tooltip title={fileName || ""} placement="top">
                  <span className="truncate">{fileName}</span>
                </Tooltip>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
