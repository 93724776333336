/*
 * File: job-thumbnail.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 7th June 2022 4:31:23 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { StorageFileDTO } from "models/dataset/storage-file.model";
import { JobStatus } from "store/labeler/image-workspace/batch-labeling/batch-labeling.state";
import { classnames } from "utilities/classes";
import { FileThumbnail } from "./file-thumbnail.component";
import { DragEvent } from "react";
import { Job } from "domain/labeling/job";

const statusClasses = {
  [JobStatus.NEW]: "hidden",
  [JobStatus.WORKING]:
    "absolute px-2 py-1 text-xs font-semibold text-warning-500 rounded-full right-2 bottom-2 bg-warning-50 capitalize",
  [JobStatus.SAVED]:
    "absolute px-2 py-1 text-xs font-semibold text-success-500 rounded-full right-2 bottom-2 bg-success-50 capitalize",
};

interface JobThumbnailProps {
  selected?: boolean;
  index: number;
  job: Job;
  status: JobStatus;
  file?: StorageFileDTO;
  load: boolean;
  onSelect?(job: Job): void;
  onLoaded?(): void;
  onLoadError?(): void;
}
export const JobThumbnail = ({
  index,
  file,
  selected,
  job,
  status,
  load,
  onSelect,
  onLoaded,
  onLoadError,
}: JobThumbnailProps) => {
  
  function handleClick() {
    onSelect && onSelect(job);
  }
  
  function handleDragStart(event: DragEvent<HTMLElement>) {
    event.dataTransfer.setData("jobId", job.id.toString());
  }

  return (
    <button
      onClick={handleClick}
      key={job.id}
      id={`${job.id}`}
      draggable
      onDragStart={handleDragStart}
      className={classnames("overflow-hidden rounded-lg relative flex-none", {
        "border-2 border-warning-500": !!selected,
        "border-2 border-blueGray-900": !selected,
      })}
    >
      <FileThumbnail
        load={load}
        file={file}
        onLoaded={onLoaded}
        onError={onLoadError}
      />
      <span className="absolute text-sm font-semibold left-2 bottom-2 text-warning-500">
        [{index}]
      </span>
      <span className={statusClasses[status]}>{status}</span>
    </button>
  );
};
