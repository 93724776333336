/*
 * File: dashboard.page.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { VBPageTitle } from "components/common/vb-page-title.component";
import { VBSpacer } from "components/common/vb-spacer.component";
import { forwardRef, HTMLProps, useMemo, useState } from "react";
import { classnames } from "utilities/classes";
import { formatNumber } from "utilities/formatter/number-formatter.utils";
import { VBSegmentTabs } from "components/common/vb-segment-tabs/vb-segment-tabs.component";
import { IconCalendar, IconRefresh } from "components/common/vb-icon.component";
import { TaskStatisticChart } from "./components/task-statistic-chart.component";
import { LabelLegend } from "./components/label-legend.component";
import { StatisticTable } from "./components/statistic-table.component";
import { ProjectBatchStatistic } from "./components/project-batch-statistic.component";
import moment from "moment";
import { createPortal } from "react-dom";
import { useDashboardContext } from "./context/dashboard.context";
import { CardRowDataModel, QueryNameStatistic } from "./context/dashboard.state";
import { LinearProgress } from "@material-ui/core";
import { useAppSelector } from "hooks/use-redux";
import { selectDashBoardIsQueryNameLoading, selectDashBoardLatestTimeLoaded } from "store/customer/dashboard/dashboard.selectors";
import { DateRange } from "utilities/date-time/date-range";
import { useDateToAgoString } from "utilities/date-time/date-to-ago-string";

export const DashboardPage = () => {
  const {
    isLoading,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    dateRange,
    setDateRange,
    dateRanges,
    requestData,
    setUseCache,
    taskCompletedFilter,
    tableProjectType,
    cardRowsDatasources,
    cardRowsProjects,
    cardRowsLabelers,
    cardRowsModels,
    cardRowsStorageUsage,
  } = useDashboardContext();
  const { t } = useTranslation();
  const handleTabChanged = (tabId: string) => {
    setUseCache(false);
    setDateRange(tabId as DateRange);
  };

  function handleDateChanged(
    dates: any,
    _: React.SyntheticEvent<any> | undefined
  ) {
    if (dates && dates.length > 1) {
      const newFromDate = dates[0];
      const newToDate = dates[1];
      if (newFromDate && newToDate) {
        setUseCache(false);
      }
      setFromDate(newFromDate);
      setToDate(newToDate);
      setDateRange(DateRange.CUSTOM);
    }
  }

  function handleRefresh() {
    if (fromDate && toDate) {
      setUseCache(false);
      requestData(
        fromDate,
        toDate,
        true,
        undefined,
        taskCompletedFilter,
        tableProjectType ? [tableProjectType] : undefined,
      );
    }
  }

  const dashboardLatestTimeLoaded = useAppSelector(selectDashBoardLatestTimeLoaded);
  const latestTime = useMemo(() => {
    if (!dashboardLatestTimeLoaded) return undefined;
    return moment(new Date(dashboardLatestTimeLoaded)).local().toDate();
  }, [dashboardLatestTimeLoaded]);
  const agoString = useDateToAgoString(latestTime, new Date());

  const DateInput = forwardRef<HTMLButtonElement>(
    ({ onClick }: HTMLProps<HTMLButtonElement>, ref) => (
      <button onClick={onClick} ref={ref} className="button-secondary">
        <span className="whitespace-nowrap">
          {moment(fromDate).format("DD/MM/YY")} -{" "}
          {moment(toDate).format("DD/MM/YY")}
        </span>
        <IconCalendar className="flex-none w-5 h-5" />
      </button>
    )
  );

  return (
    <div className="flex flex-col w-full min-h-full">
      <div className="flex items-center flex-shrink-0 h-16 px-6 py-4">
        <VBPageTitle text={t("dashboard:pageTitle")} />
        <VBSpacer />
        {
          (latestTime && !isLoading) &&
          <div className="flex mr-4 text-background-400">
            {agoString}
          </div>
        }
        <div className="flex items-center gap-4 z-10">
          <button
            className="flex items-center justify-center flex-none w-6 h-6"
            onClick={handleRefresh}
          >
            <IconRefresh className="w-6 h-6 text-primary" />
          </button>
          <VBSegmentTabs
            selectedOptionId={dateRange}
            onChange={handleTabChanged}
            options={dateRanges}
          />

          <DatePicker
            selected={fromDate}
            onChange={handleDateChanged}
            startDate={fromDate}
            endDate={toDate}
            selectsRange
            popperContainer={({ children }) =>
              createPortal(children, document.body)
            }
            customInput={<DateInput />}
          />

          <button className="flex-none button-secondary">
            <span>View activity</span>
          </button>
        </div>
      </div>
      <div className="flex flex-row gap-1 p-1 mx-6 overflow-x-auto bg-white rounded shadow">
        <StatisticCard
          index={0}
          title="Datasources"
          data={[cardRowsDatasources]}
          isLoading={useAppSelector(selectDashBoardIsQueryNameLoading(QueryNameStatistic.DatasourceStatistic))}
        />
        <StatisticCard
          index={1}
          title="Projects"
          data={[cardRowsProjects]}
          isLoading={useAppSelector(selectDashBoardIsQueryNameLoading(QueryNameStatistic.ProjectStatistic))}
        />
        <StatisticCard
          index={2}
          title="Labelers"
          data={[cardRowsLabelers]}
          isLoading={useAppSelector(selectDashBoardIsQueryNameLoading(QueryNameStatistic.LabelerStatistic))}
        />
        <StatisticCard
          index={3}
          title="Models"
          data={[cardRowsModels]}
        />
        <StatisticCard
          index={4}
          title="Storage Usage"
          data={[
            [...cardRowsStorageUsage.slice(0, 4)],
            [...cardRowsStorageUsage.slice(4)],
          ]}
          isLoading={useAppSelector(selectDashBoardIsQueryNameLoading(QueryNameStatistic.StorageStatistic))}
        />
      </div>

      <div className="flex w-full gap-6 p-6">
        <div className="flex-none">
          <TaskStatisticChart />
        </div>
        <div className="flex-auto">
          <StatisticTable />
          <div className="h-6" />
          <ProjectBatchStatistic />
        </div>
      </div>
    </div>
  );
};

export interface StatisticCardProps {
  title: string;
  data: CardRowDataModel[][];
  index?: number;
  isLoading?: boolean;
}
const StatisticCard = ({ title, data, index, isLoading = false }: StatisticCardProps) => {
  const statisticDatas = useMemo(() => {
    const colorIndexMap: Record<number, string> = {
      0: "#3241FF",
      1: "#3990FF",
      2: "#47E1FF",
      3: "#14FBE3",
    }
    return data.map((statisticData, index) => {
      const total = statisticData.reduce(
        (total, current) => total + current.total,
        0
      );
      return {
        id: index + "",
        total: total,
        items: statisticData.map((item, i) => {
          return {
            ...item,
            percent:
              total === 0 ? total : Math.round((100 * item.total) / total),
            color: colorIndexMap[i],
            postFix: item.postFix,
          };
        }),
      };
    });
  }, [data]);
  const [activeItem, setActiveItem] = useState("");
  function handleMouseEnter(itemName: string) {
    setActiveItem(itemName);
  }
  function handleMouseLeave(itemName: string) {
    if (itemName === activeItem) {
      setActiveItem("");
    }
  }

  return (
    <div
      className={classnames("relative p-6 flex-none", {
        "bg-background-50": !!(index && index % 2 === 1),
        "bg-secondary-50": !(index && index % 2 === 1),
      })}
      style={{
        minWidth: "268px",
      }}
    >
      {
        isLoading &&
        <div className="absolute top-0 left-0 w-full h-2 z-0">
          <LinearProgress />
        </div>
      }
      <h2 className="font-semibold">{title}</h2>
      <div className="flex flex-row gap-12">
        {statisticDatas.map(({ id, total, items }) => {
          return (
            <div key={id}>
              <p className="mt-2 text-3xl font-bold text-background-700">
                {formatNumber(total)}
              </p>
              <div className="relative flex w-full h-3 mt-3">
                {items.map((item) => {
                  return (
                    <div
                      style={{
                        backgroundColor: item.color,
                        width: `${item.percent}%`,
                      }}
                      className={classnames(
                        "h-3 transition-transform transform",
                        {
                          "scale-y-150": item.name === activeItem,
                        }
                      )}
                      onMouseEnter={() => handleMouseEnter(item.name)}
                      onMouseLeave={() => handleMouseLeave(item.name)}
                      key={item.name}
                    />
                  );
                })}
              </div>
              <div className="flex flex-col gap-3 mt-6">
                {items.map(({ name, total, color, percent, postFix }) => {
                  return (
                    <LabelLegend
                      key={name}
                      name={name}
                      color={color || ""}
                      percent={percent}
                      value={total}
                      postFix={postFix}
                      active={name === activeItem}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
