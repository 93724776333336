/*
 * File: sortable-column.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd January 2022 8:16:16 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { IconFilterList } from "components/common/vb-icon.component";
import { classnames } from "utilities/classes";

interface SortableColumnHeaderProps {
  field: string;
  header: string;
  sortable?: boolean;
  sortMode?: string;
  onSelect?(field: string): void;
}
export const SortableColumnHeader = ({
  header,
  field,
  sortable,
  sortMode,
  onSelect,
}: SortableColumnHeaderProps) => {
  return (
    <button
      disabled={!sortable}
      onClick={() => onSelect && onSelect(field)}
      className={classnames(
        "flex items-center justify-between w-full h-full gap-4 select-none",
        { "cursor-default": !sortable }
      )}
    >
      <span className="text-sm font-semibold whitespace-nowrap">{header}</span>
      <SortableIcon sortMode={sortMode} sortable={sortable} />
    </button>
  );
};

interface SortableIconProps {
  sortable?: boolean;
  sortMode?: string;
}
const SortableIcon = ({ sortable, sortMode }: SortableIconProps) => {
  if (!sortable) return null;
  if (sortMode === "asc")
    return <IconFilterList className="flex-none w-4 h-4 text-primary" />;
  if (sortMode === "desc")
    return (
      <IconFilterList className="flex-none w-4 h-4 transform rotate-180 text-primary" />
    );
  return <IconFilterList className="flex-none w-4 h-4" />;
};
