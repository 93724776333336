/*
 * File: labeler-layout.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 19th November 2021 9:53:43 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useKeycloakContext } from "contexts/keycloak/keycloak.context";
import { useAppSelector } from "hooks/use-redux";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import { Routes } from "routers/config/routes";
import { selectUserInfo, selectUserRole } from "store/auth/auth.selectors";
import { UserRole } from "store/auth/auth.state";
import { LabelerHeaderComponent } from "../labeler-header/labeler-header.component";
import { LabelerNavigation } from "../labeler-navigation/labeler-navigation.component";
import { LABELER_NAVIGATION_MENU_ITEMS } from "../labeler-navigation/labeler-navigation.constant";

interface Props {
  children: JSX.Element;
}
export const LabelerLayoutComponent = ({ children }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const userInfo = useAppSelector(selectUserInfo);
  const currentRole = useAppSelector(selectUserRole);
  const { signOut, switchRole } = useKeycloakContext();

  const handleViewProfile = () => {
    history.push(Routes.LABELER_PROFILE);
  };
  
  const handleSwitchRole = () => {
    switchRole(
      currentRole === UserRole.CUSTOMER ? UserRole.LABELER : UserRole.CUSTOMER
    );
  };
  const handleSignOut = () => {
    signOut();
  };

  const currentMenuItem = useMemo(() => {
    const path = location.pathname;
    if (path === Routes.LABELER_HOME) return Routes.LABELER_HOME.toString();
    const item = LABELER_NAVIGATION_MENU_ITEMS.find(
      (item) => item.id !== Routes.LABELER_HOME && path.startsWith(item.id)
    );
    if (item) return item.id;
    return path;
  }, [location.pathname]);

  const handleSelectItem = (route: string) => {
    history.push(route);
  };

  return (
    <div className="flex items-center w-screen h-screen bg-background-50">
      <nav className="z-50 flex-shrink-0">
        <LabelerNavigation
          menuItems={LABELER_NAVIGATION_MENU_ITEMS}
          activeMenuItemId={currentMenuItem}
          onSelect={handleSelectItem}
        />
      </nav>
      <main className="flex flex-col flex-1 h-screen">
        <LabelerHeaderComponent
          userName={userInfo?.name || ""}
          onSwitchRole={handleSwitchRole}
          onLogout={handleSignOut}
          onViewProfile={handleViewProfile}
        />
        <section className="flex-1 overflow-y-auto">{children}</section>
      </main>
    </div>
  );
};
