/*
 * File: env.config.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 29th July 2021 3:34:21 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export enum Environment {
  DEVELOPMENT = "development",
  PRODUCTION = "production",
  STAGING = "staging",
  TEST = "test",
}

export interface EnvConfig {
  ACTIVITY_SERVICE_URL: string;
  AI_ASSISTANCE_SERVICE_URL: string;
  AI_AUDIO_ASSISTANCE_SERVICE_URL: string;
  ML_SERVICE_URL: string;
  PAYMENT_SERVICE_URL: string;
  QUERY_SERVICE_URL: string;
  ANALYTICS_SERVICE_URL: string;
  DICOM_SERVICE_URL: string;
  KEYCLOAK_SERVER_URL: string;
  STORAGE_SERVICE_URL: string;
  LABEL_SERVICE_URL: string;
  USER_SERVICE_URL: string;
  NOTIFICATION_SERVICE_URL: string;
  WEBSOCKET_URL: string;
  ENVIRONMENT: string;
  SETTING_SERVICE_URL: string;
  ENABLED_RELATED_ES_APIS: boolean;
  ENABLE_ERROR_TRACKING: boolean;
  CUSTOMER_SERVICE_URL: string;
  USE_RUNTIME_CONFIG: boolean;
  RUNTIME_CONFIG_URL: string;

  KEYCLOAK_AUTH_SERVICE_URL: string;
  KEYCLOAK_REALM: string;
  KEYCLOAK_RESOURCE: string;
  SENTRY_DSN: string;
}

export const ENV_CONFIG: EnvConfig = {
  ACTIVITY_SERVICE_URL: process.env.REACT_APP_ACTIVITY_SERVICE_URL || "",
  AI_ASSISTANCE_SERVICE_URL:
    process.env.REACT_APP_AI_ASSISTANCE_SERVICE_URL || "",
  AI_AUDIO_ASSISTANCE_SERVICE_URL:
    process.env.REACT_APP_AI_AUDIO_ASSISTANCE_SERVICE_URL || "",
  ML_SERVICE_URL: process.env.REACT_APP_ML_SERVICE_URL || "",
  PAYMENT_SERVICE_URL: process.env.REACT_APP_PAYMENT_SERVICE_URL || "",
  QUERY_SERVICE_URL: process.env.REACT_APP_QUERY_SERVICE_URL || "",
  ANALYTICS_SERVICE_URL: process.env.REACT_APP_ANALYTICS_SERVICE_URL || "",
  STORAGE_SERVICE_URL: process.env.REACT_APP_STORAGE_SERVICE_URL || "",
  LABEL_SERVICE_URL: process.env.REACT_APP_LABEL_SERVICE_URL || "",
  DICOM_SERVICE_URL: process.env.REACT_APP_DICOM_SERVICE_URL || "",
  KEYCLOAK_SERVER_URL: process.env.REACT_APP_KEYCLOAK_SERVER || "",
  USER_SERVICE_URL: process.env.REACT_APP_USER_SERVICE_URL || "",
  NOTIFICATION_SERVICE_URL:
    process.env.REACT_APP_NOTIFICATION_SERVICE_URL || "",
  WEBSOCKET_URL: process.env.REACT_APP_WEBSOCKET_URL || "",
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || "development",
  SETTING_SERVICE_URL: process.env.REACT_APP_SETTING_SERVICE_URL || "",
  ENABLED_RELATED_ES_APIS: getEnvVarBool(
    process.env.REACT_APP_ENABLED_RELATED_ES_APIS || "",
    true
  ),
  ENABLE_ERROR_TRACKING: getEnvVarBool(
    process.env.REACT_APP_ENABLE_ERROR_TRACKING || "",
    false
  ),
  USE_RUNTIME_CONFIG: getEnvVarBool(
    process.env.REACT_APP_USE_RUNTIME_CONFIG || "",
    false
  ),
  CUSTOMER_SERVICE_URL: process.env.REACT_APP_CUSTOMER_SERVICE_URL || "",
  RUNTIME_CONFIG_URL: process.env.REACT_APP_RUNTIME_CONFIG_URL || "",
  KEYCLOAK_AUTH_SERVICE_URL:
    process.env.REACT_APP_KEYCLOAK_AUTH_SERVICE_URL || "",
  KEYCLOAK_REALM: process.env.REACT_APP_KEYCLOAK_REALM || "",
  KEYCLOAK_RESOURCE: process.env.REACT_APP_KEYCLOAK_RESOURCE || "",
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || "",
};

export function isProduction() {
  return ENV_CONFIG.ENVIRONMENT === Environment.PRODUCTION;
}

export function isDevelopment() {
  return ENV_CONFIG.ENVIRONMENT === Environment.DEVELOPMENT;
}

export function isLocal() {
  return Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
}

function getEnvVarBool(strValue: string, defaultValue: boolean): boolean {
  return !!strValue ? parseInt(strValue) === 1 : defaultValue;
}

export function isEnableErrorTracking() {
  return ENV_CONFIG.ENABLE_ERROR_TRACKING === true;
}

export function isUseRuntimeConfig() {
  return ENV_CONFIG.USE_RUNTIME_CONFIG === true;
}
