/*
 * File: select-job-async.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 12th July 2022 3:47:33 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { TextLabelingData } from "domain/labeling/labeling-data";
import { RootState } from "store";
import { LabelingBatchState } from "../text-labeling-batch.state";

const THUNK_NAME = "textLabelingBatch/textSelectJobAsync";

interface TextSelectJobPayload {
  jobId?: number;
  offset?: number;
}
export const textSelectJobAsync = createAsyncThunk(
  THUNK_NAME,
  async ({ jobId, offset }: TextSelectJobPayload, { getState }) => {
    const state = getState() as RootState;
    const { selectedJobId } = state.textLabelingBatch;
    const { observations, relations, selectedSystemObservationId } =
      state.textWorkspace.textEditor;
    return {
      annotations: observations,
      relationAnnotations: relations,
      selectedSystemObservationId,
      selectedJobId,
      jobId,
      offset,
    };
  }
);

export function textSelectJobBuilder(
  builder: ActionReducerMapBuilder<LabelingBatchState>
) {
  return builder.addCase(textSelectJobAsync.fulfilled, (state, action) => {
    const { allIds, entities } = state.jobs;
    const { annotations, relationAnnotations, selectedJobId, jobId, offset } =
      action.payload;
    if (allIds.includes(selectedJobId)) {
      const labelingJob = entities[selectedJobId];
      if (labelingJob && labelingJob.data) {
        const jobData: TextLabelingData = {
          id: labelingJob.job.id,
          jobId: labelingJob.job.id,
          taskId: labelingJob.task.id,
          fileId: labelingJob.file.id,
          annotator: labelingJob.job.assignee,
          annotations: annotations,
          relationAnnotations: relationAnnotations,
        };
        labelingJob.data = { ...labelingJob.data, jobData };
      }
    }
    if (jobId) {
      state.selectedJobId = jobId;
    } else if (offset) {
      const idx = allIds.indexOf(selectedJobId);
      const nextIndex = (idx + offset + allIds.length) % allIds.length;
      state.selectedJobId = allIds[nextIndex] as number;
    }
  });
}
