/*
 * File: editor-setting.slice.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 30th March 2022 5:20:46 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  defaultEditorSettingState,
  EditorSettingState,
} from "./editor-setting.state";

const SLICE_NAME: string = "editor-setting";

const slice = createSlice({
  name: SLICE_NAME,
  initialState: defaultEditorSettingState,
  reducers: {
    resetEditorSettingState: (state: EditorSettingState) => {
      Object.assign(state, defaultEditorSettingState);
    },
    bboxRulerDisplayToggled: (state: EditorSettingState) => {
      const v = !state.showBBoxRuler;
      state.showBBoxRuler = v;
      setEditorSettingToLocalStorage("showBBoxRuler", v);
    },
    taskInfoDisplayToggled: (state: EditorSettingState) => {
      const v = !state.showTaskInfo;
      state.showTaskInfo = v;
      setEditorSettingToLocalStorage("showTaskInfo", v);
    },
    annotationToolbarDisplayToggled: (state: EditorSettingState) => {
      const v = !state.showAnnotationToolbar;
      state.showAnnotationToolbar = v;
      setEditorSettingToLocalStorage("showAnnotationToolbar", v);
    },
    autoSaveTogged: (state: EditorSettingState) => {
      const v = !state.autoSave;
      state.autoSave = v;
      setEditorSettingToLocalStorage("autoSave", v);
    },
    completeJobsInViewToggled: (state: EditorSettingState) => {
      const v = !state.completeJobsInView;
      state.completeJobsInView = v;
      setEditorSettingToLocalStorage("completeJobsInView", v);
    },
    completeJobsInBatchToggled: (state: EditorSettingState) => {
      const v = !state.completeJobsInBatch;
      state.completeJobsInBatch = v;
      setEditorSettingToLocalStorage("completeJobsInBatch", v);
    },
    warningPopupToggled: (state: EditorSettingState) => {
      const v = !state.showWarningPopup;
      state.showWarningPopup = v;
      setEditorSettingToLocalStorage("showWarningPopup", v);
    },
    setCompleteJobsInView: (
      state: EditorSettingState,
      action: PayloadAction<boolean>
    ) => {
      state.completeJobsInView = action.payload;
      setEditorSettingToLocalStorage("completeJobsInView", action.payload);
    },
    setCompleteJobsInBatch: (
      state: EditorSettingState,
      action: PayloadAction<boolean>
    ) => {
      state.completeJobsInBatch = action.payload;
      setEditorSettingToLocalStorage("completeJobsInBatch", action.payload);
    },
    keyboardShortcutsSelected: (state: EditorSettingState) => {
      state.showKeyboardShortcuts = !state.showKeyboardShortcuts;
    },
    keyboardShortcutsClosed: (state: EditorSettingState) => {
      state.showKeyboardShortcuts = false;
    },
    editorSettingsSelected: (state: EditorSettingState) => {
      state.showEditorSettings = !state.showEditorSettings;
    },
    editorSettingsClosed: (state: EditorSettingState) => {
      state.showEditorSettings = false;
    },
    loadImageEditorSettingsFromLocalStorage: (state: EditorSettingState) => {
      state.autoSave = getEditorSettingFromLocalStorage(
        "autoSave",
        defaultEditorSettingState.autoSave
      );
      state.showBBoxRuler = getEditorSettingFromLocalStorage(
        "showBBoxRuler",
        defaultEditorSettingState.showBBoxRuler
      );
      state.showTaskInfo = getEditorSettingFromLocalStorage(
        "showTaskInfo",
        defaultEditorSettingState.showTaskInfo
      );
      state.showAnnotationToolbar = getEditorSettingFromLocalStorage(
        "showAnnotationToolbar",
        defaultEditorSettingState.showAnnotationToolbar
      );
      state.showKeyboardShortcuts = getEditorSettingFromLocalStorage(
        "showKeyboardShortcuts",
        defaultEditorSettingState.showKeyboardShortcuts
      );
      state.showWarningPopup = getEditorSettingFromLocalStorage(
        "showWarningPopup",
        defaultEditorSettingState.showWarningPopup
      );
      state.completeJobsInView = getEditorSettingFromLocalStorage(
        "completeJobsInView",
        defaultEditorSettingState.completeJobsInView
      );
      state.completeJobsInBatch = getEditorSettingFromLocalStorage(
        "completeJobsInBatch",
        defaultEditorSettingState.completeJobsInBatch
      );
    },
  },
});

const getEditorSettingFromLocalStorage = (
  key: string,
  defaultValue: boolean
) => {
  const preFixKey = `IMAGE_EDITOR_SETTINGS_${key}`;
  const v = localStorage.getItem(preFixKey);
  if (v) {
    return v === "1";
  } else {
    setEditorSettingToLocalStorage(key, defaultValue);
    return defaultValue;
  }
};

const setEditorSettingToLocalStorage = (key: string, value: boolean) => {
  localStorage.setItem(`IMAGE_EDITOR_SETTINGS_${key}`, value ? "1" : "0");
};

export const {
  autoSaveTogged,
  bboxRulerDisplayToggled,
  resetEditorSettingState,
  keyboardShortcutsSelected,
  keyboardShortcutsClosed,
  editorSettingsSelected,
  editorSettingsClosed,
  taskInfoDisplayToggled,
  annotationToolbarDisplayToggled,
  completeJobsInViewToggled,
  completeJobsInBatchToggled,
  setCompleteJobsInView,
  setCompleteJobsInBatch,
  loadImageEditorSettingsFromLocalStorage,
  warningPopupToggled
} = slice.actions;

export const editorSettingReducer = slice.reducer;
