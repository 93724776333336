/*
 * File: file-upload.context.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 24th June 2022 9:23:32 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { defaultFileUploadState } from "./file-upload.state";

export const FileUploadContext = createContext(defaultFileUploadState);

export const useFileUploadContext = () => {
  return useContext(FileUploadContext);
};
