/*
 * File: model-ai-versions-api.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 22nd February 2022 3:18:08 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AIModelVersionDTO } from "services/label-service/dtos/ai-model.dto";
import { BaseAPI } from "./base.api";

export class ModelAIVersionsAPI extends BaseAPI<AIModelVersionDTO> {
  getModelAIs(payload = { size: "500" }): Promise<AIModelVersionDTO[]> {
    return this.getItems(payload).then((res) => res.data);
  }

  deleteModelAI(id: number) {
    return this.deleteItem(id);
  }

  createModelAI(payload: Partial<AIModelVersionDTO>) {
    return this.createItem(payload);
  }
}
