/*
 * File: file-info-detail.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 13th July 2023 9:49:49 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { IconButton } from "@material-ui/core";
import fileSize from "filesize";
import { VscClose, VscAdd } from "react-icons/vsc";
import { formatDateTime } from "utilities/formatter/date-formatter.utils";
import FileInfoItem from "../file-info-item/file-info-item";
import { FileInfoSection } from "../file-info-section/file-info-section";
import FileMetadata from "../file-metadata/file-metadata";
import { QueryApi } from "data-access/impl/query";
import { useMemo } from "react";
import { useFileBrowseContext } from "../../context/file-browse.context";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";

interface Props {
  file: QueryApi.IFile;
  onClose(): void;
}
export function FileInfoDetail({ file, onClose }: Props) {
  const { selectFile, reloadFiles } = useFileBrowseContext();
  const { openModal, closeModal } = useModalContext();

  const fileSizeInKb = useMemo(() => {
    return file?.additionalFileInfo?.addition?.fileSize ?? 0;
  }, [file]);

  function handleCloseModal(reload?: boolean) {
    closeModal();
    if (!reload) return;
    reloadFiles();
    selectFile([]);
  }
  function addMetadata() {
    if (!file) return;
    const params = { open: true, files: [file], onClose: handleCloseModal };
    openModal(ModalTypes.MANAGE_FILE_METADATA, params);
  }
  function removeMetadata(file: QueryApi.IFile, metadata: QueryApi.IMetadata) {
    const params = {
      open: true,
      files: [file],
      metadatas: [metadata],
      onClose: handleCloseModal,
    };
    openModal(ModalTypes.REMOVE_FILE_METADATA, params);
  }

  return (
    <div className="flex-none h-full bg-white border-l w-96">
      <div className="flex items-center gap-4 px-4 py-4">
        <h2 className="flex-auto text-lg font-semibold">{file.fileName}</h2>
        <IconButton size="small" onClick={onClose}>
          <VscClose size={20} />
        </IconButton>
      </div>
      <div className="w-full h-1 bg-background-100" />
      <FileInfoSection title="Information">
        <div className="px-4">
          <FileInfoItem label="Name" value={file.fileName} />
          <FileInfoItem label="Id" value={file.id.toString()} />
          <FileInfoItem
            label="Size"
            value={fileSizeInKb ? fileSize(fileSizeInKb) : ""}
          />
          <FileInfoItem
            label="Extension"
            value={file.additionalFileInfo?.extend ?? ""}
          />
          <FileInfoItem label="Store Type" value={file.storeType ?? ""} />
          <FileInfoItem label="Status" value={file.status ?? ""} />
          {file.additionalFileInfo?.addition?.width && (
            <FileInfoItem
              label="Resolution"
              value={`${file.additionalFileInfo?.addition?.width} x ${file.additionalFileInfo?.addition?.height}`}
            />
          )}
          <FileInfoItem label="Created by" value={file.createdBy ?? ""} />
          {file.createdDate && (
            <FileInfoItem
              label="Created date"
              value={formatDateTime(new Date(file.createdDate))}
            />
          )}
        </div>
      </FileInfoSection>
      <div className="w-full h-1 mt-4 bg-background-100" />
      <FileInfoSection
        title="Metadata"
        actions={
          <IconButton size="small" onClick={addMetadata}>
            <VscAdd size={18} />
          </IconButton>
        }
      >
        <FileMetadata file={file} onRemoveMetadata={removeMetadata} />
      </FileInfoSection>
    </div>
  );
}

export default FileInfoDetail;
