/*
 * File: project-task-report-options.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 10th September 2021 4:53:40 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Checkbox, FormControlLabel } from "@material-ui/core";
import { ProjectInputEmails } from "./project-input-emails.component";

interface TaskReportOptionsProps {
  options: any[];
  users: string;
  setIsChanged?: (isChanged: boolean) => void
  setSettings?: (settings: any) => void
}

export const TaskReportOptions = ({ options, users, setIsChanged, setSettings }: TaskReportOptionsProps) => {
  function handleChangeCheckbox(optionId: number, checked: boolean) {
    setIsChanged && setIsChanged(true);
    setSettings && setSettings((settings: any) => {
      return {
        ...settings,
        notifications: settings.notifications.map((noti: any) => ({
          ...noti,
          checked: noti.id === optionId ? checked : noti.checked,
        })),
      };
    });
  }

  function handleChangeEmails(users: string) {
    setIsChanged && setIsChanged(true);
    setSettings && setSettings((settings: any) => {
      return {
        ...settings,
        users,
      };
    });
  }

  if(!options) return null;

  return (
    <div className="grid grid-cols-4">
      <div className="flex flex-col truncate">
        {options.map((option) => (
          <FormControlLabel
            control={<Checkbox color="primary" checked={option.checked} />}
            label={option.name}
            key={option.id}
            onChange={(_, checked) => handleChangeCheckbox(option.id, checked)}
          />
        ))}
      </div>
      <div className="col-span-3 flex items-center justify-center">
        <ProjectInputEmails
          text={users}
          onChange={handleChangeEmails}
        />
      </div>
    </div>
  );
};
