import { LabelingMode } from "domain/labeling/labeling-mode";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { RequestStatus } from "store/base/base.state";
import { selectBatchLabelingRequestStatus } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { imagePollJobsAsync } from "store/labeler/image-workspace/batch-labeling/thunks/image-poll-jobs.thunk";
import { resetDicomEditorState } from "store/labeler/image-workspace/dicom-editor/dicom-editor.slice";
import { loadImageEditorSettingsFromLocalStorage } from "store/labeler/image-workspace/editor-setting/editor-setting.slice";
import { resetImageWorkspaceState } from "store/labeler/image-workspace/image-workspace.slice";

interface Props {
  mode?: LabelingMode;
}
export default function usePollJobs({ mode }: Props) {
  const { projectId } = useParams<{ projectId: string }>();
  const { jobId } = useParams<{ jobId: string }>();
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(selectBatchLabelingRequestStatus);

  useEffect(() => {
    async function loadJobsAsync() {
      dispatch(resetDicomEditorState())
      dispatch(resetImageWorkspaceState());
      dispatch(loadImageEditorSettingsFromLocalStorage());
      await dispatch(imagePollJobsAsync({ projectId, jobId }));
    }
    if (mode === LabelingMode.LABELER && requestStatus === RequestStatus.IDLE) {
      loadJobsAsync();
    }
  }, [dispatch, requestStatus, projectId, jobId, mode]);
}
