/*
 * File: training-jobs.state.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 6th September 2021 2:50:40 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { TrainingJobDTO } from "services/label-service/dtos";
import { BaseState, RequestStatus } from "store/base/base.state";

export interface TrainingJobState extends BaseState<TrainingJobDTO> {}

export const INITIAL_TRAINING_JOB_STATE: TrainingJobState = {
  entities: {},
  ids: [],
  id: -1,
  status: RequestStatus.IDLE,
  error: null,
};
