/*
 * File: mammography-task.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 21st September 2022 4:21:55 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useLocalStorageState, useUnmount } from "ahooks";
import { useAppDispatch } from "hooks/use-redux";
import { batchTaskActionSelected } from "store/customer/batch/batch.slice";
import { resetMammographyLabelingTaskState } from "store/labeler/mammography-labeling-task/mammography-labeling-task.slice";
import { MammographyTaskContext } from "./mammography-task.context";

interface Props {
  children: JSX.Element;
  onClose(): void;
}

export const MammographyTaskProvider = ({ children, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [isFullScreen, setIsFullScreen] = useLocalStorageState(
    "MAMMO_TASK_REVIEW_FULLSCREEN",
    true
  );

  function toggleDisplayFullScreen() {
    setIsFullScreen(!isFullScreen);
  }

  const state = {
    onClose,
    isFullScreen,
    toggleDisplayFullScreen,
  };

  useUnmount(() => {
    dispatch(batchTaskActionSelected({ taskId: -1, action: "" }));
    dispatch(resetMammographyLabelingTaskState());
  });

  return (
    <MammographyTaskContext.Provider value={state}>
      {children}
    </MammographyTaskContext.Provider>
  );
};
