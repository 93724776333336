/*
 * File: label.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 10:10:06 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { ENV_CONFIG } from "configs/env.config";
import {
  ProjectResourceApi,
  Configuration,
  ProjectCriteria,
  ProjectResponseDTO,
  ObservationResourceApi,
  ObservationDTO,
  Pagination,
} from "data-access/generated/label";
import {
  Entity,
  Filter,
  IPagination,
  IPaginationResponse,
} from "domain/common";
import { AuthService } from "services/auth";
import { BaseFilter } from "./types";

export module LabelApi {
  function getConfiguration() {
    const Scope = AuthService.getUserScope();
    const Workspace = AuthService.getUserWorkspace();
    const Authorization = `Bearer ${AuthService.getAccessToken()}`;
    return new Configuration({
      basePath: ENV_CONFIG.LABEL_SERVICE_URL,
      baseOptions: { headers: { Authorization, Scope, Workspace } },
    });
  }

  function getPagination(
    pagination?: Pagination,
    filter?: Filter
  ): IPagination {
    return {
      page: pagination?.pageNumber ?? filter?.page ?? 0,
      size: pagination?.pageSize ?? filter?.size ?? 20,
      totalCount: pagination?.totalItem ?? 0,
    };
  }

  export class ProjectFilter extends BaseFilter {
    setId(id?: number) {
      return this.setQuery("id", id?.toString());
    }
    setName(name?: string) {
      return this.setQuery("name", name?.toString());
    }
    setCreatedDate(createdDate?: string) {
      return this.setQuery("createdDate", createdDate?.toString());
    }
    setType(type?: string) {
      return this.setQuery("type", type?.toString());
    }
    setDescription(description?: string) {
      return this.setQuery("description", description?.toString());
    }
    setCreatedBy(createdBy?: string) {
      return this.setQuery("createdBy", createdBy?.toString());
    }
    setLastModifiedBy(lastModifiedBy?: string) {
      return this.setQuery("lastModifiedBy", lastModifiedBy?.toString());
    }
    setLastModifiedDate(lastModifiedDate?: string) {
      return this.setQuery("lastModifiedDate", lastModifiedDate?.toString());
    }

    getId(): number | undefined {
      return this.getNumberField("id");
    }
    getName(): string | undefined {
      return this.getStringField("name");
    }
    getCreatedDate(): string | undefined {
      return this.getStringField("createdDate");
    }
    getType(): string | undefined {
      return this.getStringField("type");
    }
    getDescription(): string | undefined {
      return this.getStringField("description");
    }
    getCreatedBy(): string | undefined {
      return this.getStringField("createdBy");
    }
    getLastModifiedBy(): string | undefined {
      return this.getStringField("lastModifiedBy");
    }
    getLastModifiedDate(): string | undefined {
      return this.getStringField("lastModifiedDate");
    }

    toCriteria(): ProjectCriteria {
      return {
        "id.equals": this.getId(),
        "name.equals": this.getName(),
        "createdDate.equals": this.getCreatedDate(),
        "type.equals": this.getType(),
        "description.equals": this.getDescription(),
        "createdBy.equals": this.getCreatedBy(),
        "lastModifiedBy.equals": this.getLastModifiedBy(),
        "lastModifiedDate.equals": this.getLastModifiedDate(),
      };
    }
  }

  export interface IProject extends Entity {
    id: number;
    group?: string;
    createdBy?: string;
    createdDate?: string;
    description?: string;
    lastModifiedDate?: string;
    thumbnailUrl?: string;
    name?: string;
    totalBatch?: number;
    type?: string;
    version?: number;
    workspaceId?: string;
    exportTypes?: IExportType[];
    settings?: IProjectSetting;
  }

  export interface IExportType {
    code?: string;
    name?: string;
  }

  export interface IProjectSetting {
    autoSaveInSecond?: number;
    pollStrategy?: PollStrategyEnum;
    historyPermission?: HistoryPermissionEnum;
  }

  export enum PollStrategyEnum {
    Single = "Single",
    Group = "Group",
  }

  export enum HistoryPermissionEnum {
    ReadOnly = "ReadOnly",
    Edit = "Edit",
  }

  export interface IProjectMapper {
    fromDTO(dto: ProjectResponseDTO): IProject;
  }
  export class ProjectMapper implements IProjectMapper {
    fromDTO(dto: ProjectResponseDTO): IProject {
      if (!dto.id) throw new Error("Invalid DTO Object");
      return {
        id: dto.id,
        group: dto.group,
        createdBy: dto.createdBy,
        createdDate: dto.createdDate,
        description: dto.description,
        lastModifiedDate: dto.lastModifiedDate,
        thumbnailUrl: dto.thumbnailUrl,
        name: dto.name,
        totalBatch: dto.totalBatch,
        type: dto.type,
        version: dto.version,
        workspaceId: dto.workspaceId,
        exportTypes: dto.exportTypes,
        settings: {
          autoSaveInSecond: dto.settings?.autoSaveInSecond,
          pollStrategy: dto.settings?.pollStrategy as PollStrategyEnum,
          historyPermission: dto.settings
            ?.historyPermission as HistoryPermissionEnum,
        },
      };
    }
  }

  export async function getProjects(
    filter: Filter
  ): Promise<IPaginationResponse<IProject>> {
    const configuration = getConfiguration();
    const api = new ProjectResourceApi(configuration);
    const projectFiler = new ProjectFilter(filter);
    const criteria = projectFiler.toCriteria();
    const pageable = projectFiler.toPageable();
    const response = await api.getAllProjectsUsingGET({ criteria, pageable });
    const mapper = new ProjectMapper();
    const items = (response.data.payload ?? []).map(mapper.fromDTO);
    const pagination: IPagination = {
      page: response.data.pagination?.pageNumber ?? filter.page,
      size: response.data.pagination?.pageSize ?? filter.size,
      totalCount: response.data.pagination?.totalItem ?? 0,
    };
    return { data: items, pagination };
  }

  export interface ILabel extends Entity {
    id: number;
    active?: boolean;
    description?: string;
    lastModifiedDate?: string;
    name: string;
    code?: string;
    observationGroup?: string;
    observationSetting?: ILabelSetting;
    observationVersion?: number;
    parent?: ILabel;
    priority?: number;
    projectId?: number;
    attributes?: ILabelAttribute[];
  }

  export interface ILabelSetting {
    annotationType?: string;
    numberOfPoint?: number;
    caveats?: string;
    color?: string;
    illustrations?: ILabelIllustrationItem[];
    systemAttribute?: boolean;
    source?: string;
    maskId?: number;
    selectable?: boolean;
  }

  export interface ILabelIllustrationItem {
    resourceId?: string;
    active?: string;
    note?: string;
    url?: string;
  }

  export interface ILabelAttribute extends Entity {
    id: number;
    type?: string;
    name?: string;
    defaultValue?: string;
    values?: string[];
    ranges?: number[];
    required?: boolean;
    createdDate?: string;
  }
  export interface ILabelMapper {
    fromDto(dto: ObservationDTO): ILabel;
  }
  export interface ILabelFilter {
    getProjectId(): number | undefined;
    setProjectId(projectId: number | undefined): ILabelFilter;
  }

  export class LabelMapper implements ILabelMapper {
    fromDto(dto: ObservationDTO): ILabel {
      if (!dto.id) throw new Error("Invalid DTO Object");
      const label: ILabel = {
        id: dto.id,
        active: dto.active,
        description: dto.description,
        lastModifiedDate: dto.lastModifiedDate,
        name: dto.name ?? "",
        code: dto.code,
        observationGroup: dto.observationGroup,
        observationSetting: {
          annotationType: dto.observationSetting?.annotationType,
          numberOfPoint: dto.observationSetting?.numberOfPoint,
          caveats: dto.observationSetting?.caveats,
          color: dto.observationSetting?.color,
          illustrations: dto.observationSetting?.illustrations,
          systemAttribute: dto.observationSetting?.systemAttribute,
          source: dto.observationSetting?.source,
          maskId: dto.observationSetting?.maskId,
          selectable: dto.observationSetting?.selectable,
        },
        observationVersion: dto.observationVersion,
        parent: undefined,
        priority: dto.priority,
        projectId: dto.projectId,
        attributes: dto.attributes?.map((attr) => {
          return {
            id: attr.id ?? -1,
            type: attr.type,
            name: attr.name,
            defaultValue: attr.defaultValue,
            values: attr.values,
            ranges: attr.ranges,
            required: attr.required,
            createdDate: attr.createdDate,
          };
        }),
      };
      if (dto.parent) {
        label.parent = new LabelMapper().fromDto(dto.parent);
      }
      return label;
    }
  }

  export class LabelFilter extends BaseFilter implements ILabelFilter {
    getProjectId(): number | undefined {
      return this.getNumberField("projectId");
    }
    setProjectId(projectId: number | undefined): ILabelFilter {
      return this.setQuery("projectId", projectId?.toString());
    }
  }

  export async function getLabels(
    filter: Filter
  ): Promise<IPaginationResponse<ILabel>> {
    const configuration = getConfiguration();
    const api = new ObservationResourceApi(configuration);
    const labelFilter = new LabelFilter(filter);
    const projectId = labelFilter.getProjectId();
    const pageable = labelFilter.toPageable();
    const params = { projectId, pageable };
    const response = await api.listObservationUsingGET(params);
    const mapper = new LabelMapper();
    const items = (response.data.payload ?? [])?.map(mapper.fromDto);
    const pagination = getPagination(response.data.pagination, filter);
    return { pagination, data: items };
  }
}
