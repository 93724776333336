/*
 * File: loading-skeleton.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 17th February 2022 4:19:07 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export const WebBuilderLoadingSkeleton = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      Loading...
    </div>
  );
};
