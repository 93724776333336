/*
 * File: button-save-job.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th July 2022 2:01:38 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import classnames from "classnames";
import { CircularProgress } from "@material-ui/core";
import { useAppSelector } from "hooks/use-redux";
import { useTextLabelingBatchContext } from "pages/labeler/text-labeling/context/text-labeling-batch/text-labeling-batch.context";
import { selectedTextJobIdsByWorkingStatus } from "store/labeler/text-labeling-batch/text-labeling-batch.selectors";
import { WorkingStatus } from "store/labeler/text-labeling-batch/text-labeling-batch.state";
import { UseAutoSaveReturnValue } from "hooks/use-auto-save.hook";

interface ButtonSaveJobProps {
  autoSave: UseAutoSaveReturnValue;
}

export const ButtonSaveJob = ({ autoSave }: ButtonSaveJobProps) => {
  const { isSavingJob, saveWorkingJob } = useTextLabelingBatchContext();
  const workingJobIds = useAppSelector(
    selectedTextJobIdsByWorkingStatus(WorkingStatus.WORKING)
  ) as number[];

  return (
    <button
      onClick={saveWorkingJob}
      className={classnames(
        "relative button-text-secondary",
        workingJobIds.length === 0 && "opacity-50 cursor-not-allowed"
      )}
      disabled={autoSave.enabled}
    >
      {!isSavingJob && (
        <span>Save {autoSave.seconds > 0 && `(${autoSave.seconds}s)`}</span>
      )}
      {isSavingJob && <CircularProgress color="inherit" size={20} />}
    </button>
  );
};
