/*
 * File: text-viewer-handle.ts
 * Project: aiscaler-web
 * File Created: Tuesday, 26th October 2021 10:45:10 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Collection, collectionUtils } from "domain/common";
import { CORAnnotation, NERAnnotation, Token } from "domain/text-labeling";
import { Label } from "domain/text-labeling";
import { Rectangle } from "utilities/math/rectangle";
import {
  BoundingBox,
  AnnotationData,
  TokenData,
  DEFAULT_BOUNDING_BOX,
  Point,
} from "../../models/text-viewer.models";
import { findLargestLine } from "./text-viewer.utils";

const PADDING_RIGHT = 10;
const PADDING_LEFT = 60;
const LINE_HEIGHT = 40;
const LETTER_SPACING = 4.39;
const SENTENCE_HEIGHT = 20;

interface AnnotationInfo {
  id: string;
  leftIds: string[];
  rightIds: string[];
}

interface TextToken extends Token {
  annotationIds?: string[];
  relationIds?: string[];
}

interface NERAnnotationData extends NERAnnotation {
  tokenIds?: string[];
  relationIds?: string[];
  stackIndex?: number;
  originStackIndex?: number;
  lineIndex?: number;
  locked?: boolean;
}

interface CORAnnotationData extends CORAnnotation {
  tokenIds?: string[];
  stackIndex?: number;
  startIndex: number;
  endIndex: number;
  locked?: boolean;
  lines: { [key: number]: CORAnnotationLine };
}

interface CORAnnotationLine {
  startIndex: number;
  endIndex: number;
  stackIndex: number;
  lineIndex: number;
  tokenIds: string[];
}

interface Line {
  sentenceIndex: number;
  lineIndex: number;
  startIndex: number;
  endIndex: number;
  tokenIds: string[];
  offset: number;
}

interface TokenBBox extends BoundingBox {
  id: string;
  left: number;
  right: number;
  locked: boolean;
}

export interface RelationLine {
  relationId: string;
  lines: { points: Point[] }[];
}

export class TextViewerController {
  tokens: Collection<TextToken>;
  labels: Collection<Label>;
  tokenDatas: Collection<TokenData>;
  tokenBBoxes: Collection<TokenBBox>;
  maxBBoxes: Collection<TokenBBox>;
  boundingBoxes: Record<string, BoundingBox>;
  annotations: Collection<NERAnnotationData>;
  relations: Collection<CORAnnotationData>;

  tokenObservationStacks: Record<string, number[]> = {};
  tokenObservationOffsets: Record<string, number> = {};
  constructor(
    tokenIds: string[] = [],
    tokenEntities: Record<string, Token> = {},
    boundingBoxes: Record<string, BoundingBox> = {},
    labels: Collection<Label> = { allIds: [], entities: {} },
    annotations: NERAnnotationData[] = [],
    relationAnnotations: CORAnnotation[] = []
  ) {
    this.boundingBoxes = boundingBoxes;
    this.labels = labels;
    this.annotations = collectionUtils.fromEntities(
      annotations
        .map((anno) => ({ ...anno, tokenIds: [] }))
        .sort((a, b) => a.startIndex - b.startIndex)
    );
    this.relations = collectionUtils.fromEntities(
      relationAnnotations
        .map((anno) => {
          const start = this.getAnnotation(anno.from);
          const end = this.getAnnotation(anno.to);
          const startIndex = Math.min(start.startIndex, end.startIndex);
          const endIndex = Math.max(start.endIndex, end.endIndex);
          return {
            ...anno,
            tokenIds: [],
            startIndex,
            endIndex,
            lines: {},
          };
        })
        .sort((a, b) => {
          const startDiff: number = a.startIndex - b.startIndex;
          if (startDiff === 0) return b.endIndex - a.endIndex;
          return startDiff;
        })
    );
    this.tokens = { allIds: tokenIds, entities: tokenEntities };
    this.tokenDatas = { allIds: [], entities: {} };
    this.tokenBBoxes = { allIds: [], entities: {} };
    this.maxBBoxes = { allIds: [], entities: {} };

    this.init();
  }

  init() {
    this._resetTokens();
    this.tokenObservationOffsets = {};
    this.tokenObservationStacks = {};
    // Init Tokens
    for (const tokenId of this.tokens.allIds) {
      const tokenBBox = this.getBoundingBox(tokenId as string);
      if (!tokenBBox) continue;
      collectionUtils.addOne(this.tokenBBoxes, {
        id: tokenId,
        x: tokenBBox.x,
        y: tokenBBox.y,
        width: tokenBBox.width,
        height: tokenBBox.height,
        left: 0,
        right: 0,
        locked: false,
      });
    }

    // Init annotation tokenIds
    for (const annotationId of this.annotations.allIds) {
      const annotation = this.getAnnotation(annotationId as string);
      const { startIndex, endIndex } = annotation;
      const tokenIds = this.getTokenIdsBetweenIndex(startIndex, endIndex);
      annotation.tokenIds = tokenIds;
    }

    // Init relation and populate relation to corresponding annotations
    for (const relationId of this.relations.allIds) {
      const relation = this.getRelation(relationId as string);
      const { startIndex, endIndex } = relation;
      const tokenIds = this.getTokenIdsBetweenIndex(startIndex, endIndex);
      relation.tokenIds = tokenIds;
    }

    this.annotations.allIds.sort((a, b) => {
      const annotationA = this.getAnnotation(a as string);
      const annotationB = this.getAnnotation(b as string);
      if (!annotationA.tokenIds || !annotationB.tokenIds) return 0;
      const aTokenIds = annotationA.tokenIds?.length || 0;
      const bTokenIds = annotationB.tokenIds?.length || 0;
      const diff = aTokenIds - bTokenIds;
      if (diff !== 0) return diff;
      const aStart = this.tokens.allIds.indexOf(annotationA.tokenIds[0]);
      const bStart = this.tokens.allIds.indexOf(annotationB.tokenIds[0]);
      return aStart - bStart;
    });

    for (const annotationId of this.annotations.allIds) {
      const annotation = this.getAnnotation(annotationId as string);
      const labelBBox = this.getBoundingBox(annotation.observationId);
      const labelWidth = labelBBox.width;
      const tokenIds = annotation.tokenIds || [];
      let width = 0;
      for (let idx = 0; idx < tokenIds.length; idx++) {
        const tokenId = tokenIds[idx];
        const tokenBBox = this.getTokenBoundingBox(tokenId);
        const tokenWidth = tokenBBox.width + tokenBBox.left + tokenBBox.right;
        width += tokenWidth + (idx > 0 ? LETTER_SPACING : 0);
        if (idx === tokenIds.length - 1) width -= tokenBBox.right;
        if (idx === 0) width -= tokenBBox.left;
      }
      if (labelWidth > width) {
        const offset = (labelWidth - width) / 2;
        this.getTokenBoundingBox(tokenIds[0]).left += offset;
        const endTokenId = tokenIds[tokenIds.length - 1];
        const endTokenBBox = this.getTokenBoundingBox(endTokenId);
        endTokenBBox.right = Math.max(endTokenBBox.right, offset);
      }
    }

    for (const annotationId of this.annotations.allIds) {
      const annotation = this.getAnnotation(annotationId as string);
      const tokenIds = annotation.tokenIds || [];
      let stackIndex = 0;
      for (const tokenId of tokenIds) {
        const token = this.getToken(tokenId);
        const tokenAnnotationIds = token.annotationIds || [];
        const maxStack = Math.max(
          ...tokenAnnotationIds.map(
            (id) => this.getAnnotation(id).stackIndex || 0
          )
        );
        stackIndex = Math.max(
          stackIndex,
          tokenAnnotationIds.length,
          tokenAnnotationIds.length > 0 ? maxStack + 1 : 0
        );
        if (!token || token.annotationIds?.includes(annotation.id)) continue;
        if (!token.annotationIds) {
          token.annotationIds = [annotation.id];
        } else {
          token.annotationIds.push(annotation.id);
        }
      }
      annotation.stackIndex = stackIndex;
      annotation.originStackIndex = stackIndex;
    }

    for (const relationId of this.relations.allIds) {
      const relation = this.getRelation(relationId as string);
      const annotationFrom = this.getAnnotation(relation.from);
      const annotationTo = this.getAnnotation(relation.to);
      if (!annotationFrom.relationIds) annotationFrom.relationIds = [];
      if (!annotationTo.relationIds) annotationTo.relationIds = [];
      if (!annotationFrom.relationIds.includes(relationId as string)) {
        annotationFrom.relationIds.push(relationId as string);
      }
      if (!annotationTo.relationIds.includes(relationId as string)) {
        annotationTo.relationIds.push(relationId as string);
      }
    }
  }

  private _resetTokens() {
    for (const tokenId of this.tokens.allIds) {
      const token = collectionUtils.getOne(this.tokens, tokenId);
      if (token) {
        token.annotationIds = [];
        token.relationIds = [];
      }
    }
    for (const annotationId of this.annotations.allIds) {
      let annotation = collectionUtils.getOne(this.annotations, annotationId);
      if (annotation) annotation.tokenIds = [];
    }
    this.tokenBBoxes = { allIds: [], entities: {} };
    this.maxBBoxes = { allIds: [], entities: {} };
  }

  setObservations(annotations: NERAnnotationData[]) {
    this.annotations = collectionUtils.fromEntities(
      annotations
        .map((anno) => ({ ...anno, tokenIds: [] }))
        .sort((a, b) => a.startIndex - b.startIndex)
    );
    this.init();
  }

  setAnnotationsData(
    annotations: NERAnnotationData[],
    relations: CORAnnotation[]
  ) {
    this.annotations = collectionUtils.fromEntities(
      annotations
        .map((anno) => ({ ...anno, tokenIds: [] }))
        .sort((a, b) => a.startIndex - b.startIndex)
    );
    this.relations = collectionUtils.fromEntities(
      relations
        .map((anno) => {
          const start = this.getAnnotation(anno.from);
          const end = this.getAnnotation(anno.to);
          const startIndex = Math.min(start.startIndex, end.startIndex);
          const endIndex = Math.max(start.endIndex, end.endIndex);
          return {
            ...anno,
            tokenIds: [],
            startIndex,
            endIndex,
            lines: {},
          };
        })
        .sort((a, b) => {
          const startDiff = a.startIndex - b.startIndex;
          if (startDiff === 0) return b.endIndex - a.endIndex;
          return startDiff;
        })
    );

    this.init();
  }

  getBoundingBox(entityId: string): BoundingBox {
    return this.boundingBoxes[entityId];
  }

  getTokenBoundingBox(entityId: string): TokenBBox {
    return this.tokenBBoxes.entities[entityId];
  }

  getToken(tokenId: string): TextToken {
    return collectionUtils.getOne(this.tokens, tokenId) as Token;
  }

  getAnnotation(annotationId: string): NERAnnotationData {
    return collectionUtils.getOne(
      this.annotations,
      annotationId
    ) as NERAnnotationData;
  }
  getRelation(relationId: string): CORAnnotationData {
    return collectionUtils.getOne(
      this.relations,
      relationId
    ) as CORAnnotationData;
  }

  getTokenData(tokenId: string): TokenData {
    return collectionUtils.getOne(this.tokenDatas, tokenId) as TokenData;
  }

  findBoundingWidth(tokenIds: string[]) {
    let width = 0;
    for (let tokenId of tokenIds) {
      const box = this.getTokenBoundingBox(tokenId);
      width += box.width + (width > 0 ? LETTER_SPACING : 0);
    }
    return width;
  }

  update(maxWidth: number) {
    const { items, sentences } = this._updateTokenPosition(maxWidth);
    const annotations = this._updateTokenObservationPosition();
    const relationLines = this._updateRelationLines(annotations);
    const relationStack: { [key: string]: number } = {};
    for (const relationId of this.relations.allIds) {
      const relation = this.getRelation(relationId as string);
      relationStack[relationId] = relation.stackIndex || 0;
    }
    return {
      tokens: items,
      annotations,
      relationOffsets: relationStack,
      relationLines,
      sentences,
    };
  }

  getContainerHeight() {
    const tokenId = this.getTokenId(this.tokens.allIds.length - 1);
    return this.getTokenBoundingBox(tokenId).y + LINE_HEIGHT;
  }

  _updateTokenPosition(maxWidth: number) {
    let idx = 0;
    let currentX = PADDING_LEFT;
    let currentY = SENTENCE_HEIGHT;
    this.tokenDatas = { allIds: [], entities: {} };
    let lineTokenIds: string[] = [];
    let lineIndex = 1;
    const lines: Line[] = [];
    let relationOffsets: { [key: string]: number } = {};

    while (idx < this.tokens.allIds.length) {
      const tokenId = this.getTokenId(idx);
      const token = this.getToken(tokenId);
      const isNewSentence = this._isNewSentence(idx);
      const tokenBBox = this.getTokenBoundingBox(tokenId);
      const tokenWidth = tokenBBox.width + tokenBBox.left + tokenBBox.right;

      if (isNewSentence) {
        const line = this.newLine(
          lineTokenIds,
          lineIndex,
          token.sentenceIndex - 1
        );
        if (line) {
          this.updateLineStackIndexes(line);
          this.adjustLineOffset(line);
          lines.push(line);
          lineIndex++;
          lineTokenIds = [];
          currentX = PADDING_LEFT;
          currentY += LINE_HEIGHT + line.offset * SENTENCE_HEIGHT;
        }
      }

      if (currentX + tokenWidth > maxWidth - PADDING_RIGHT - PADDING_LEFT) {
        const line = this.newLine(lineTokenIds, lineIndex, token.sentenceIndex);
        if (line) {
          this.updateLineStackIndexes(line);
          this.adjustLineOffset(line);
          lines.push(line);
          lineIndex++;
          lineTokenIds = [];
          currentX = PADDING_LEFT;
          currentY += LINE_HEIGHT + line.offset * SENTENCE_HEIGHT;
        }
        continue;
      }

      tokenBBox.x = currentX + tokenBBox.left;
      tokenBBox.y = currentY;
      currentX += tokenWidth + LETTER_SPACING;
      const tokenData = { id: token.id, model: token, box: tokenBBox };
      collectionUtils.addOne(this.tokenDatas, tokenData);
      lineTokenIds.push(tokenId);
      idx++;
    }

    let sentenceIndex = -1;
    if (lineTokenIds.length > 0) {
      const token = this.getToken(lineTokenIds[lineTokenIds.length - 1]);
      sentenceIndex = token.sentenceIndex;
    }
    const line = this.newLine(lineTokenIds, lineIndex, sentenceIndex);
    if (line) {
      this.updateLineStackIndexes(line);
      this.adjustLineOffset(line);
      lines.push(line);
    }

    const sentences: {
      sentenceIndex: number;
      bbox: Rectangle;
      indicatorBBox: Rectangle;
    }[] = [];
    for (const line of lines) {
      if (line.tokenIds.length === 0) continue;
      const { sentenceIndex } = line;
      if (!sentences.find((s) => s.sentenceIndex === sentenceIndex)) {
        const previousSentence = sentences.find(
          (s) => s.sentenceIndex === sentenceIndex - 1
        );
        let y = 0;
        if (previousSentence) {
          y = previousSentence.bbox.y + previousSentence.bbox.height;
        }
        sentences.push({
          sentenceIndex,
          bbox: { x: 0, y: y, width: 0, height: 0 },
          indicatorBBox: { x: 0, y: y, width: 0, height: 0 },
        });
      }

      const sentence = sentences.find((s) => s.sentenceIndex === sentenceIndex);
      if (!sentence) continue;
      const startTokenId = line.tokenIds[0];
      const endTokenId = line.tokenIds[line.tokenIds.length - 1];

      const startBBox = this.getTokenBoundingBox(startTokenId);
      const endBBox = this.getTokenBoundingBox(endTokenId);
      sentence.bbox.x = 0;
      sentence.bbox.width = maxWidth;
      sentence.bbox.height =
        Math.max(startBBox.y + startBBox.height, endBBox.y + endBBox.height) -
        sentence.bbox.y +
        (LINE_HEIGHT - SENTENCE_HEIGHT) / 4;

      if (sentence.indicatorBBox.width === 0) {
        sentence.indicatorBBox.y = startBBox.y;
        sentence.indicatorBBox.height = startBBox.height;
        sentence.indicatorBBox.width = 40;
      }
    }

    return {
      items: collectionUtils.values(this.tokenDatas),
      relationOffsets,
      sentences,
    };
  }
  newLine(
    tokenIds: string[],
    lineIndex: number,
    sentenceIndex: number
  ): Line | null {
    if (!tokenIds || tokenIds.length === 0) return null;
    return {
      lineIndex,
      sentenceIndex,
      tokenIds: tokenIds,
      startIndex: this.getToken(tokenIds[0]).startIndex,
      endIndex: this.getToken(tokenIds[tokenIds.length - 1]).endIndex,
      offset: 0,
    };
  }
  _updateTokenObservationPosition() {
    const annotationDatas: AnnotationData[] = [];

    for (const annotationId of this.annotations.allIds) {
      const annotation = collectionUtils.getOne(this.annotations, annotationId);
      if (!annotation) continue;
      const tokenIds = annotation.tokenIds || [];
      const relationIds = annotation.relationIds || [];
      const leftRelationIds = [];
      const rightRelationIds = [];
      const mid = (annotation.startIndex + annotation.endIndex) * 0.5;
      for (const relationId of relationIds) {
        const relation = this.getRelation(relationId);
        const id = relation.from === annotationId ? relation.to : relation.from;
        const anno = this.getAnnotation(id);
        const annoMid = (anno.startIndex + anno.endIndex) * 0.5;
        if (annoMid < mid) {
          leftRelationIds.push(relationId);
        } else {
          rightRelationIds.push(relationId);
        }
      }

      leftRelationIds.sort((a, b) => {
        const relationA = this.getRelation(a);
        const relationB = this.getRelation(b);
        const stackA = relationA.stackIndex || 0;
        const stackB = relationB.stackIndex || 0;
        return stackA - stackB;
      });
      rightRelationIds.sort((a, b) => {
        const relationA = this.getRelation(a);
        const relationB = this.getRelation(b);
        const stackA = relationA.stackIndex || 0;
        const stackB = relationB.stackIndex || 0;
        return stackB - stackA;
      });

      const tokenObservation: AnnotationData = {
        annotationId: annotation.id,
        observationId: annotation.observationId,
        tokenIds: tokenIds,
        lines: [],
        boundingBox: { ...DEFAULT_BOUNDING_BOX },
        annotation: annotation,
        relationIds: [...leftRelationIds, ...rightRelationIds],
        lineIndex: annotation.lineIndex,
      };

      annotationDatas.push(tokenObservation);
    }

    for (let tokenObservation of annotationDatas) {
      const { tokenIds, lines, boundingBox, annotationId } = tokenObservation;
      if (tokenIds.length === 0) continue;
      const annotation = this.getAnnotation(annotationId);
      const offsetY = (annotation.stackIndex || 0) * (SENTENCE_HEIGHT + 5);

      if (tokenIds.length === 1) {
        const tokenBoundingBox = this.getTokenBoundingBox(tokenIds[0]);
        lines.push({
          start: {
            x: tokenBoundingBox.x,
            y: tokenBoundingBox.y - SENTENCE_HEIGHT - offsetY,
          },
          end: {
            x: tokenBoundingBox.x + tokenBoundingBox.width,
            y: tokenBoundingBox.y - SENTENCE_HEIGHT - offsetY,
          },
        });
      }
      let idx = 0;
      let line = {
        start: { x: -1, y: -1 },
        end: { x: -1, y: -1 },
      };
      while (idx < tokenIds.length) {
        const tokenBoundingBox = this.getTokenBoundingBox(tokenIds[idx]);
        if (line.start.x === -1 && line.start.y === -1) {
          line.start.x = tokenBoundingBox.x;
          line.start.y = tokenBoundingBox.y - SENTENCE_HEIGHT - offsetY;
        } else if (
          line.end.x === -1 &&
          line.end.y === -1 &&
          line.start.y !== tokenBoundingBox.y - SENTENCE_HEIGHT - offsetY
        ) {
          line.end.y = line.start.y;
          line.end.x =
            line.start.x + this.getTokenBoundingBox(tokenIds[idx - 1]).width;
          lines.push({ ...line });
          line = {
            start: {
              x: tokenBoundingBox.x,
              y: tokenBoundingBox.y - SENTENCE_HEIGHT - offsetY,
            },
            end: { x: -1, y: -1 },
          };
        } else if (line.end.x === -1 && line.end.y === -1) {
          line.end.x = tokenBoundingBox.x + tokenBoundingBox.width;
          line.end.y = tokenBoundingBox.y - SENTENCE_HEIGHT - offsetY;
        } else if (
          line.end.y ===
          tokenBoundingBox.y - SENTENCE_HEIGHT - offsetY
        ) {
          line.end.x = tokenBoundingBox.x + tokenBoundingBox.width;
          line.end.y = tokenBoundingBox.y - SENTENCE_HEIGHT - offsetY;
        } else {
          lines.push({ ...line });
          line = {
            start: {
              x: tokenBoundingBox.x,
              y: tokenBoundingBox.y - SENTENCE_HEIGHT - offsetY,
            },
            end: { x: -1, y: -1 },
          };
        }
        idx++;
      }
      if (line.end.x !== -1 && line.end.y !== -1) lines.push(line);
      else if (line.start.y !== -1 && line.start.y !== -1) {
        const tokenBoundingBox = this.getTokenBoundingBox(
          tokenIds[tokenIds.length - 1]
        );
        line.end.x = tokenBoundingBox.x + tokenBoundingBox.width;
        line.end.y = tokenBoundingBox.y - SENTENCE_HEIGHT - offsetY;
        lines.push(line);
      }
      const largestLine = findLargestLine(lines);
      const box = this.getBoundingBox(tokenObservation.observationId);
      const x = (largestLine.start.x + largestLine.end.x - box.width) / 2;
      const y = largestLine.start.y - 10;
      boundingBox.x = x;
      boundingBox.y = y;
      boundingBox.width = box.width;
      boundingBox.height = box.height;
    }
    return annotationDatas;
  }

  _updateRelationLines(annotations: AnnotationData[]) {
    const collection = collectionUtils.fromEntities(
      annotations.map((anno) => ({ ...anno, id: anno.annotationId }))
    );
    const relationLines: { [key: string]: RelationLine } = {};
    for (const relationId of this.relations.allIds) {
      const relation = this.getRelation(relationId as string);
      const from = collection.entities[relation.from];
      const to = collection.entities[relation.to];
      const fromBBox = from.boundingBox;
      const toBBox = to.boundingBox;
      const stackIndex = relation.stackIndex || 0;
      let startOffset, endOffset;
      const startRelationIds = from.relationIds;
      const endRelationIds = to.relationIds;
      const id = relationId as string;
      const lineIds = Object.keys(relation.lines)
        .map((key) => parseInt(key))
        .sort((a, b) => a - b);

      const startRelationIndex = startRelationIds.indexOf(id);
      const endRelationIndex = endRelationIds.indexOf(id);
      if (startRelationIds.length < 2 || startRelationIndex === -1) {
        startOffset = 0;
      } else {
        const offset = startRelationIndex + 1 - startRelationIds.length / 2;
        startOffset = offset * 5;
      }
      if (endRelationIds.length < 2 || endRelationIndex === -1) {
        endOffset = 0;
      } else {
        const offset = endRelationIndex + 1 - endRelationIds.length / 2;
        endOffset = offset * 5;
      }

      const reverse =
        from.annotation &&
        to.annotation &&
        from.annotation.startIndex > to.annotation.startIndex;
      if (reverse) lineIds.reverse();

      if (lineIds.length === 1) {
        const y = Math.min(
          fromBBox.y - 30 - stackIndex * 20,
          toBBox.y - 30 - stackIndex * 20
        );
        const line = {
          points: [
            {
              x: fromBBox.x + fromBBox.width / 2 + startOffset,
              y: fromBBox.y - 20,
            },
            { x: fromBBox.x + fromBBox.width / 2 + startOffset, y },
            { x: toBBox.x + toBBox.width / 2 + endOffset, y },
            { x: toBBox.x + toBBox.width / 2 + endOffset, y: toBBox.y - 20 },
          ],
        };
        relationLines[relationId] = {
          relationId: relationId as string,
          lines: [line],
        };
      } else {
        relationLines[relationId] = {
          relationId: relationId as string,
          lines: [],
        };

        for (let i = 0; i < lineIds.length; i++) {
          const lineId = lineIds[i];
          const line = relation.lines[lineId];

          if (i === 0) {
            const tokenId =
              line.tokenIds[reverse ? 0 : line.tokenIds.length - 1];
            const startBBox = fromBBox;
            const endBBox = this.getTokenBoundingBox(tokenId);
            if (!endBBox) continue;
            const y = startBBox.y - 30 - line.stackIndex * 20;
            const relationLine = {
              points: [
                {
                  x: startBBox.x + startBBox.width / 2 + startOffset,
                  y: startBBox.y - 20,
                },
                { x: startBBox.x + startBBox.width / 2 + startOffset, y },
                { x: endBBox.x + (reverse ? 0 : endBBox.width), y },
              ],
            };
            relationLines[relationId].lines.push(relationLine);
            continue;
          }

          if (i === lineIds.length - 1) {
            const tokenId =
              line.tokenIds[reverse ? line.tokenIds.length - 1 : 0];
            const startBBox = this.getTokenBoundingBox(tokenId);
            if (!startBBox) continue;
            const endBBox = toBBox;
            const y = endBBox.y - 30 - line.stackIndex * 20;
            const relationLine = {
              points: [
                { x: startBBox.x + (reverse ? startBBox.width : 0), y },
                { x: endBBox.x + endBBox.width / 2 + endOffset, y },
                {
                  x: endBBox.x + endBBox.width / 2 + endOffset,
                  y: endBBox.y - 20,
                },
              ],
            };
            relationLines[relationId].lines.push(relationLine);
            continue;
          }

          const startTokenBBox = this.getTokenBoundingBox(line.tokenIds[0]);
          const endTokenBBox = this.getTokenBoundingBox(
            line.tokenIds[line.tokenIds.length - 1]
          );
          const y = Math.min(
            startTokenBBox.y - 30 - stackIndex * 20,
            endTokenBBox.y - 30 - stackIndex * 20
          );
          const relationLine = {
            points: [
              {
                x: startTokenBBox.x + startTokenBBox.width / 2,
                y: startTokenBBox.y - 20,
              },
              { x: startTokenBBox.x + startTokenBBox.width / 2, y },
              { x: endTokenBBox.x + endTokenBBox.width / 2, y },
              {
                x: endTokenBBox.x + endTokenBBox.width / 2,
                y: endTokenBBox.y - 20,
              },
            ],
          };
          relationLines[relationId].lines.push(relationLine);
        }
      }
    }
    return relationLines;
  }

  findAnnotationStackIndex(annotationId: string) {
    const annotation = this.getAnnotation(annotationId);
    const tokenIds = annotation.tokenIds || [];
    let stackIndex = 0;

    for (const tokenId of tokenIds) {
      const token = this.getToken(tokenId);
      const annotationIds = token.annotationIds || [];
      const idx = annotationIds.indexOf(annotationId);
      stackIndex = Math.max(stackIndex, idx);
    }

    let idx = 0;
    while (idx <= stackIndex) {
      let found = false;
      for (const tokenId of tokenIds) {
        if (!this.tokenObservationStacks.hasOwnProperty(tokenId)) continue;
        const observationStack = this.tokenObservationStacks[tokenId];
        if (observationStack.includes(idx)) {
          found = true;
          break;
        }
      }
      if (!found) {
        return idx;
      }
      idx++;
    }

    return idx;
  }

  _isNewSentence(idx: number) {
    if (idx === 0 || idx >= this.tokens.allIds.length) return false;
    const token = this.getToken(this.getTokenId(idx));
    const previous = this.getToken(this.getTokenId(idx - 1));
    return token.sentenceIndex > previous.sentenceIndex;
  }

  sortLineAnnotationIds(ids: string[], line: Line) {
    const relationIds: string[] = [];
    const annotationIds: string[] = [];
    for (let i = 0; i < ids.length; i++) {
      const annotationId = ids[i];
      const annotation = this.getAnnotation(annotationId);
      const relaIds = annotation.relationIds || [];
      annotationIds.push(annotationId);
      for (const relationId of relaIds) {
        if (relationIds.includes(relationId)) continue;
        relationIds.push(relationId);
        const relation = this.getRelation(relationId);
        if (!relation.tokenIds || relation.tokenIds.length === 0) continue;
        const isFrom = annotationId === relation.from;
        const otherId = isFrom ? relation.to : relation.from;
        const otherAnnotation = this.getAnnotation(otherId);
        if (relation.startIndex < line.startIndex) {
          relation.lines[line.lineIndex] = {
            lineIndex: line.lineIndex,
            startIndex: line.startIndex,
            endIndex: annotation.endIndex,
            tokenIds: line.tokenIds.slice(
              0,
              line.tokenIds.indexOf(
                relation.tokenIds[relation.tokenIds?.length - 1]
              ) + 1
            ),
            stackIndex: annotation.originStackIndex || 0,
          };
          if (!annotationIds.includes(otherId)) annotationIds.push(otherId);
          continue;
        }
        if (relation.endIndex > line.endIndex) {
          relation.lines[line.lineIndex] = {
            lineIndex: line.lineIndex,
            startIndex: annotation.startIndex,
            endIndex: line.endIndex,
            tokenIds: line.tokenIds.slice(
              line.tokenIds.indexOf(relation.tokenIds[0]) + 1,
              line.tokenIds.length
            ),
            stackIndex: annotation.originStackIndex || 0,
          };
          if (!annotationIds.includes(otherId)) annotationIds.push(otherId);
          continue;
        }

        const otherStack = otherAnnotation.originStackIndex || 0;
        const annotationStack = annotation.originStackIndex || 0;

        if (annotationStack > otherStack && !annotationIds.includes(otherId)) {
          annotationIds.push(otherId);
        }

        if (
          relation.startIndex >= line.startIndex &&
          relation.endIndex <= line.endIndex
        ) {
          relation.lines[line.lineIndex] = {
            lineIndex: line.lineIndex,
            startIndex: relation.startIndex,
            endIndex: relation.endIndex,
            tokenIds: relation.tokenIds,
            stackIndex: annotation.originStackIndex || 0,
          };
        }
      }
    }

    annotationIds.sort((a, b) => {
      const annotationA = this.getAnnotation(a);
      const annotationB = this.getAnnotation(b);
      const midA = (annotationA.startIndex + annotationA.endIndex) * 0.5;
      const midB = (annotationB.startIndex + annotationB.endIndex) * 0.5;
      return midA - midB;
    });

    const data: Collection<AnnotationInfo> = { allIds: [], entities: {} };
    const annotationMap: { [key: string]: number } = {};

    for (let i = 0; i < annotationIds.length; i++) {
      const annotationId = annotationIds[i];
      annotationMap[annotationId] = i;
      collectionUtils.addOne(data, {
        id: annotationId,
        leftIds: [],
        rightIds: [],
      });
    }

    relationIds.sort((a, b) => {
      const relationA = this.getRelation(a);
      const aFromIdx = annotationMap[relationA.from];
      const aToIdx = annotationMap[relationA.to];
      const distanceA = Math.abs(aFromIdx - aToIdx);

      const relationB = this.getRelation(b);
      const bFromIdx = annotationMap[relationB.from];
      const bToIdx = annotationMap[relationB.to];
      const distanceB = Math.abs(bFromIdx - bToIdx);

      return distanceA - distanceB;
    });

    let maxStackIndex = 0;

    for (const relationId of relationIds) {
      const relation = this.getRelation(relationId);
      const from = this.getAnnotation(relation.from);
      const to = this.getAnnotation(relation.to);
      const isCorrectOrder = from.startIndex < to.startIndex;
      const fromId = isCorrectOrder ? relation.from : relation.to;
      const toId = isCorrectOrder ? relation.to : relation.from;
      const annotationFrom = collectionUtils.getOne(data, fromId);
      const annotationTo = collectionUtils.getOne(data, toId);
      if (!annotationFrom || !annotationTo) continue;

      relation.stackIndex = Math.max(
        annotationFrom.rightIds.length,
        annotationTo.leftIds.length
      );

      const startIdx = annotationMap[fromId];
      const endIdx = annotationMap[toId];

      let childMaxStack = 0;
      for (let i = startIdx + 1; i < endIdx; i++) {
        const annotationId = annotationIds[i];
        const info = collectionUtils.getOne(data, annotationId);
        if (!info) continue;
        for (const childId of info.rightIds) {
          const childRelation = this.getRelation(childId);
          const childStack = childRelation.stackIndex || 0;
          childMaxStack = Math.max(childMaxStack, childStack + 1);
        }
        for (const childId of info.leftIds) {
          const childRelation = this.getRelation(childId);
          const childStack = childRelation.stackIndex || 0;
          childMaxStack = Math.max(childMaxStack, childStack + 1);
        }
        info.rightIds.push(relationId);
        info.leftIds.push(relationId);
      }
      relation.stackIndex = Math.max(relation.stackIndex || 0, childMaxStack);
      annotationFrom.rightIds.push(relationId);
      annotationTo.leftIds.push(relationId);
      if (relation.lines.hasOwnProperty(line.lineIndex)) {
        relation.lines[line.lineIndex].stackIndex = relation.stackIndex || 0;
      }
      maxStackIndex = Math.max(maxStackIndex, (relation.stackIndex || 0) + 1);
    }
    return maxStackIndex;
  }

  updateLineStackIndexes(line: Line) {
    const { tokenIds } = line;
    const annotationIds: string[] = [];
    for (const tokenId of tokenIds) {
      const token = this.getToken(tokenId);
      const annoIds = token.annotationIds || [];
      for (const annotationId of annoIds) {
        if (!annotationIds.includes(annotationId)) {
          annotationIds.push(annotationId);
        }
      }
    }
    let idx = 0;
    let maxStack = 0;
    while (idx >= 0) {
      const stackIndex = idx;
      const ids = annotationIds.filter((id) => {
        const annotation = this.getAnnotation(id);
        if (annotation.locked) return false;
        return (annotation.originStackIndex || 0) === stackIndex;
      });
      if (ids.length === 0) break;
      const maxStackIndex = this.sortLineAnnotationIds([...ids], line);
      for (const annotationId of ids) {
        const annotation = this.getAnnotation(annotationId);
        annotation.stackIndex = maxStack;
        annotation.locked = true;
      }
      maxStack += maxStackIndex + 1;
      idx++;
    }
    line.offset = maxStack + 1;
  }

  getRelationRange(from: NERAnnotationData, to: NERAnnotationData) {
    const start = Math.min(from.startIndex, to.startIndex);
    const end = Math.max(from.endIndex, to.endIndex);
    return { start, end };
  }

  adjustLineOffset(line: Line) {
    const { tokenIds, offset } = line;
    if (offset === 0) return;
    const height = offset * SENTENCE_HEIGHT;
    for (let tokenId of tokenIds) {
      const box = this.getTokenBoundingBox(tokenId);
      box.y += height;
    }
  }

  getSelectedBoundingBoxes(tokenIds: string[]) {
    let idx = 0;
    let start: Point | null = null;
    let end: Point | null = null;
    const rects: BoundingBox[] = [];
    while (idx < tokenIds.length) {
      const box = this.getTokenBoundingBox(tokenIds[idx]);
      if (!start || !end) {
        start = {
          x: box.x,
          y: box.y,
        };
        end = {
          x: box.x + box.width,
          y: box.y,
        };
      } else if (box.y === end.y) {
        end = {
          x: box.x + box.width,
          y: box.y,
        };
      } else if (box.y !== end.y) {
        rects.push({
          x: start.x,
          y: start.y,
          width: end.x - start.x,
          height: SENTENCE_HEIGHT,
        });
        start = {
          x: box.x,
          y: box.y,
        };
        end = {
          x: box.x + box.width,
          y: box.y,
        };
      }
      idx++;
    }
    if (start && end) {
      rects.push({
        x: start.x,
        y: start.y,
        width: end.x - start.x,
        height: SENTENCE_HEIGHT,
      });
    }
    return rects.map((rect) => {
      return {
        ...rect,
        y: rect.y - SENTENCE_HEIGHT + 3,
        height: rect.height + 4,
      };
    });
  }

  getTokenIds(from: number, to: number) {
    return this.tokens.allIds.slice(
      Math.min(from, to),
      Math.max(from, to) + 1
    ) as string[];
  }

  getTokenIdsBetweenIndex(startIndex: number, endIndex: number) {
    return this.tokens.allIds.filter((tokenId) => {
      const token = this.getToken(tokenId as string);
      return token.startIndex >= startIndex && token.endIndex <= endIndex;
    }) as string[];
  }

  getTokenId(index: number) {
    return this.tokens.allIds[index] as string;
  }

  getTokenObservationData(annotationId: string) {
    const annotation = collectionUtils.getOne(this.annotations, annotationId);
    if (!annotation) return null;
    const { observationId } = annotation;
    const tokenIds = annotation.tokenIds || [];
    const observation = collectionUtils.getOne(this.labels, observationId);
    return { tokenIds, observation };
  }

  getSelectedTokenIds({
    position,
    anchorId,
  }: {
    position: Point;
    anchorId: string;
  }) {
    let startId = "";
    for (const tokenId of this.tokens.allIds) {
      const tokenBBox = this.getTokenBoundingBox(tokenId as string);
      const { x, y, width, height } = tokenBBox;
      const overlap = !(
        position.x < x ||
        position.x > x + width ||
        position.y < y - 2 * height ||
        position.y > y + height / 2
      );
      if (overlap) {
        startId = tokenId as string;
        break;
      }
    }

    if (!startId) return null;

    const startIdx = this.tokens.allIds.indexOf(startId);
    const endIdx = this.tokens.allIds.indexOf(anchorId);
    const start = Math.min(startIdx, endIdx);
    const end = Math.max(startIdx, endIdx);
    const tokenIds = [];
    for (let i = start; i <= end; i++) {
      const tokenId = this.tokens.allIds[i] as string;
      if (!tokenId) continue;
      tokenIds.push(tokenId);
    }
    const bboxes = this.getSelectedBoundingBoxes(tokenIds);
    return {
      bboxes,
      tokenIds,
    };
  }
}
