/*
 * File: base.httpclient.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 31st August 2021 4:38:34 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export class BaseHTTPClient {
  _defaultHeaderConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: false,
  };

  _defaultPatchHeaderConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
    withCredentials: false,
  };

  axiosInstance: AxiosInstance;

  constructor(serviceUrl: string) {
    this.axiosInstance = axios.create({ baseURL: serviceUrl });
  }

  APIGet(url: string, config = this._defaultHeaderConfig) {
    return this.axiosInstance.get(url, config);
  }

  APIPost(url: string, data = {}, config = this._defaultHeaderConfig) {
    return this.axiosInstance.post(url, data, config);
  }

  APIDelete(url: string, config = this._defaultHeaderConfig) {
    return this.axiosInstance.delete(url, config);
  }

  APIUpload(url: string, data: FormData, config = this._defaultHeaderConfig) {
    return this.axiosInstance.post(url, data, config);
  }

  APIPut(url: string, data = {}, config = this._defaultHeaderConfig) {
    return this.axiosInstance.put(url, data, config);
  }

  APIPatch(url: string, data = {}, config = this._defaultPatchHeaderConfig) {
    return this.axiosInstance.patch(url, data, config);
  }
}
