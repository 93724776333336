import { collectionUtils } from "domain/common";
import { useAppSelector } from "hooks/use-redux";
import { selectBatchLabelingLabels } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { selectIoUPairs } from "store/labeler/image-workspace/image-iou/image-iou.selectors";
import { IoUPairItem } from "./iou-pair-item";

export function IoUPairs() {
  const annotationPairs = useAppSelector(selectIoUPairs);
  const labels = useAppSelector(selectBatchLabelingLabels);

  return (
    <ul>
      {annotationPairs.map((pair) => {
        const label = collectionUtils.getOne(labels, pair.labelId);
        if (!label) return null;
        return <IoUPairItem key={pair.id} pair={pair} label={label} />;
      })}
    </ul>
  );
}
