/*
 * File: sam-tool-header.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 24th April 2023 11:09:08 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useOnnixContext } from "modules/onnix/context/onnix-context";

export function SAMToolLoading() {
  const { isInitialized } = useOnnixContext();

  if (isInitialized) return null;

  return (
    <div className="absolute top-0 left-0 z-40 flex items-center w-full h-full justify-center px-4 bg-blueGray-900 bg-opacity-40 text-background-300">
      <span>Extracting image...</span>
    </div>
  );
}
