/*
 * File: exported-datasets.slice.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 1st September 2021 3:02:51 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice } from "@reduxjs/toolkit";
import {
  ExportedDatasetState,
  INITIAL_EXPORTED_DATASETS_STATE,
} from "./exported-datasets.state";
import { exportedDatasetsReducerBuilder } from "./exported-datasets.thunk";

const SLICE_NAME: string = "exportedDatasets";
const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_EXPORTED_DATASETS_STATE,
  reducers: {
    resetExportedDatasets(state: ExportedDatasetState) {
      Object.assign(state, INITIAL_EXPORTED_DATASETS_STATE);
    },
  },
  extraReducers: (builder) => {
    exportedDatasetsReducerBuilder(builder);
  },
});

export const { resetExportedDatasets } = slice.actions;

export const exportedDatasetsReducer = slice.reducer;
