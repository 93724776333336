/*
 * File: project-label-tab.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 25th July 2022 2:47:02 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Fragment, useState } from "react";
import { useCreateProjectTemplateContext } from "../context/create-project-template.context";
import { ObservationDTO } from "services/label-service/dtos";
import {
  IconAdd,
  IconEdit,
  IconTrash,
} from "components/common/vb-icon.component";
import { LabelEditMinimized } from "pages/customer/projects/project-label/components/label-edit-minimized.component";
import { snakeCase } from "snake-case";
import { CreateEditLabelModal } from "pages/customer/projects/project-label/components/create-edit-label.modal";
import { useTranslation } from "react-i18next";

export default function ProjectLabelTab() {
  const { t } = useTranslation();
  const { template, infoData, labelData, onProjectLabelChanged } =
    useCreateProjectTemplateContext();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editingLabel, setEditingLabel] =
    useState<Partial<ObservationDTO> | null>(null);

  function handleEditLabel(label: Partial<ObservationDTO>) {
    setEditingLabel(label);
  }

  const notifyALabelChanged = (v: Partial<ObservationDTO>) => {
    if (!labelData) return;
    const newLabels = labelData?.labels.map((label) => {
      if (v.id === label.id) {
        return {
          ...v,
          code: snakeCase(v.name || ""),
        };
      }
      return label;
    });
    onProjectLabelChanged({ labels: newLabels });
  };

  const handleAddLabel = (v: Partial<ObservationDTO>) => {
    if (!labelData) return;
    v.id = labelData.labels.length;
    v.code = snakeCase(v.name || "");
    const newLabels = [...labelData.labels, v];
    onProjectLabelChanged({ labels: newLabels });
  };

  function handleRemoveLabel(label: Partial<ObservationDTO>) {
    onProjectLabelChanged({
      labels: (labelData?.labels || []).filter((lb) => {
        return lb.id !== label.id;
      }) as Partial<ObservationDTO>[],
    });
  }

  return (
    <Fragment>
      <div className="h-full bg-white rounded shadow">
        <div className="p-4 text-sm text-background-500 capitalize">
          {template?.type
            ? `${t(`enum:projectSubType.${template?.type}`)} / `
            : ""}
          {infoData?.name}
        </div>

        <div className="p-6">
          <div className="space-y-4">
            {labelData?.labels.map((label, idx) => {
              return (
                <div key={label.id} className="flex items-center gap-4">
                  <span className="flex items-center justify-center w-6 h-6 rounded bg-background-300">
                    {idx + 1}
                  </span>

                  <LabelEditMinimized
                    value={label}
                    onChanged={notifyALabelChanged}
                    observations={(labelData?.labels as ObservationDTO[]) || []}
                    projectObsAnnotationTypes={
                      template?.suggestedAnnotationTypes
                    }
                    showAttributes={false}
                  />

                  <button onClick={() => handleEditLabel(label)}>
                    <IconEdit className="w-5 h-5 flex-none text-primary" />
                  </button>
                  <button onClick={() => handleRemoveLabel(label)}>
                    <IconTrash className="w-5 h-5 flex-none text-red-500" />
                  </button>
                </div>
              );
            })}

            <div className="py-2">
              <button
                className="button-tertiary"
                onClick={() => setShowCreateModal(true)}
              >
                <IconAdd />
                <span>Add new label</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCreateModal && (
        <CreateEditLabelModal
          visible={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          observations={(labelData?.labels as ObservationDTO[]) || []}
          project={null}
          projectObsAnnotationTypes={template?.suggestedAnnotationTypes}
          createEditToServer={false}
          onSubmitted={handleAddLabel}
        />
      )}
      {!!editingLabel && (
        <CreateEditLabelModal
          isEdit
          visible={!!editingLabel}
          observationToEdit={editingLabel as ObservationDTO}
          onClose={() => setEditingLabel(null)}
          onSubmitted={notifyALabelChanged}
          observations={(labelData?.labels as ObservationDTO[]) || []}
          project={null}
          projectObsAnnotationTypes={template?.suggestedAnnotationTypes}
          createEditToServer={false}
        />
      )}
    </Fragment>
  );
}
