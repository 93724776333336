/*
 * File: auth.state.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 31st July 2021 1:53:12 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { UserInfoDTO } from "services/user-service/dtos/user-info.dto";

export interface User {
  email: string;
  displayName: string;
  photoURL: string;
  uid: string;
  workspace: Record<UserRole, string[]>;
  globalRoles?: string[];
}

export enum GlobalRole{
  GLOBAL_ADMIN = "GLOBAL_ADMIN"
}
export enum UserRole {
  CUSTOMER = "customer",
  LABELER = "labeler",
}

export interface AuthState {
  currentUser: User | null;
  userInfo: Partial<UserInfoDTO> | null;
  error: Error | null;
  userRole?: UserRole;
  workspace?: string;
}

export const INITIAL_AUTH_STATE: AuthState = {
  currentUser: null,
  userInfo: null,
  error: null,
};
