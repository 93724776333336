/*
 * File: sync-cloud.drawer.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Drawer } from "@material-ui/core";
import { VBButton } from "../../../../../../components/common/vb-button.component";
import { SyncCloudForm } from "./sync-cloud-form.component";
import { DatasetActivity } from "pages/customer/datasets/components/dataset-drawer/dataset-activity.component";
import { classnames } from "utilities/classes";
import { useAppSelector } from "hooks/use-redux";
import {
  selectNumberSyncingClouds,
  selectSyncingCloud,
} from "store/customer/dataset/dataset.selectors";
import { DatasetDTO } from "services/label-service/dtos";

enum ViewMode {
  SYNC = "sync",
  HISTORY = "history",
}

interface Props {
  open: boolean;
  dataset: DatasetDTO;
  handleClose?: () => void;
}

export const SyncCloudDrawer = ({ dataset, open, handleClose }: Props) => {
  const { t } = useTranslation();
  const numberSyncingClouds = useAppSelector(selectNumberSyncingClouds);
  const [mode, setMode] = useState<ViewMode>(
    numberSyncingClouds === 0 ? ViewMode.SYNC : ViewMode.HISTORY
  );

  const syncingCloud = useAppSelector(selectSyncingCloud);
  
  useEffect(() => {
    if (syncingCloud) setMode(ViewMode.HISTORY);
  }, [syncingCloud]);

  return (
    <Drawer open={open} anchor="right">
      <div className="flex flex-col h-screen overflow-hidden w-96">
        <div className="flex items-center">
          <div className="flex-1 p-4 text-2xl font-bold">
            {t("dataset:details.drawer.buttonSyncCloud")}
          </div>
          <VBButton className="mx-2" onClick={handleClose}>
            <i className="uir-cross-small"></i>
          </VBButton>
        </div>

        <div className="grid grid-cols-2 gap-4 mx-4 mt-4">
          <div
            onClick={() => setMode(ViewMode.SYNC)}
            className={classnames(
              "cursor-pointer text-center py-2 border-b-2",
              {
                "text-primary border-primary": mode === ViewMode.SYNC,
                "text-black  border-none": mode !== ViewMode.SYNC,
              }
            )}
          >
            {t("dataset:details.drawer.textnew")}
          </div>
          <div
            onClick={() => setMode(ViewMode.HISTORY)}
            className={classnames(
              "cursor-pointer text-center py-2 border-b-2",
              {
                "text-primary border-primary": mode === ViewMode.HISTORY,
                "text-black  border-none": mode !== ViewMode.HISTORY,
              }
            )}
          >
            {t("dataset:details.drawer.textHistory")}
          </div>
        </div>
        <hr />
        <div className="h-4"></div>
        <div className="flex-1 overflow-y-auto">
          {mode === ViewMode.SYNC && (
            <SyncCloudForm dataset={dataset} onClose={handleClose} />
          )}
          {mode === ViewMode.HISTORY && <DatasetActivity dataset={dataset} />}
        </div>
      </div>
    </Drawer>
  );
};
