/*
 * File: layout.state.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:12 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export enum LayoutMode {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

export interface LayoutState {
  contextMenuVisible: boolean;
  layoutMode: LayoutMode;
  showNavigationBar: boolean;
  toggleNavigationBar: () => void;
  toggleContextMenu: () => void;
  setContextMenuVisible: (visible: boolean) => void;
  setLayoutMode: (mode: LayoutMode) => void;
}

export const defaultLayoutState: LayoutState = {
  contextMenuVisible: false,
  layoutMode: LayoutMode.VERTICAL,
  showNavigationBar: true,
  toggleNavigationBar: () => {},
  toggleContextMenu: () => {},
  setContextMenuVisible: (_) => {},
  setLayoutMode: (_) => {},
};
