import { IconLabelBBox, IconLabelPolygon } from "components/common/vb-icon.component"
import { AnnotateType } from "constants/annotation.constant"

interface TaskReviewActionPanelLabelIconProps {
  type: AnnotateType | string
}

export const TaskReviewActionPanelLabelIcon = ({ type }: TaskReviewActionPanelLabelIconProps) => {
  if (type === AnnotateType.BOUNDING_BOX) return <IconLabelBBox fill="black" />
  if (type === AnnotateType.POLYGON) return <IconLabelPolygon fill="black" />
  return null
}