/*
 * File: use-created-by-options.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th June 2023 9:05:22 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { UsersApi } from "data-access/impl/user";
import { Filter, Option } from "domain/common";
import { useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

const QUERY_KEY = "CREATED_BY_OPTIONS";

export function useCreatedByOptions(filter: Filter, defaultValue = "") {
  const client = useQueryClient();

  function requestData() {
    return UsersApi.getAllUsers(filter);
  }

  const { data } = useQuery([QUERY_KEY, filter], requestData, {});
  const options = useMemo<Option[]>(() => {
    if (!data?.data) return [];
    return data.data
      .filter((item) => item.role?.includes("customer"))
      .map((item) => {
        return {
          label: item.email,
          value: item.email,
          metadata: item,
        };
      });
  }, [data]);

  const [value, setValue] = useState(defaultValue);

  const option = useMemo(() => {
    return options.find((item) => item.value === value);
  }, [options, value]);

  function onChange(newOption?: any) {
    if (newOption && newOption.hasOwnProperty("value")) {
      setValue(newOption["value"]);
    } else {
      setValue("");
    }
  }

  function reloadData() {
    client.refetchQueries([QUERY_KEY]);
  }

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return { options, value, setValue, option, onChange, reloadData };
}

export default useCreatedByOptions;
