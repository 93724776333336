/*
 * File: template-selector.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 21st February 2022 10:19:37 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  IconElement3, IconTask,
  IconWarning
} from "components/common/vb-icon.component";
import { Option } from "domain/common";
import { classnames } from "utilities/classes";

interface TemplateSelectorProps {
  options: Option[];
  value: string;
  onChange?(value: string): void;
}
export const TemplateSelector = ({
  options,
  value,
  onChange,
}: TemplateSelectorProps) => {
  return (
    <div className="px-4">
      <div className="text-sm font-semibold">Layout</div>
      <div className="flex items-center py-2 space-x-4">
        {options.map((option) => {
          const Icon = mapOptionToIcon(option.value);
          return (
            <button
              key={option.value}
              onClick={() => onChange && onChange(option.value)}
              className={classnames("flex-1", {
                "button-primary": value === option.value,
                "button-secondary": value !== option.value,
              })}
            >
              <Icon className="flex-none w-6 h-6" />
            </button>
          );
        })}
      </div>
    </div>
  );
};

const mapOptionToIcon = (value: string) => {
  if (value === "grid") return IconElement3;
  if (value === "list") return IconTask;
  return IconWarning;
};
