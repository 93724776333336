/* tslint:disable */
/* eslint-disable */
/**
 * sam_image_encoder.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface GetImageEmbeddingRequest
 */
export interface GetImageEmbeddingRequest {
    /**
     * 
     * @type {string}
     * @memberof GetImageEmbeddingRequest
     */
    'inputUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetImageEmbeddingRequest
     */
    'sasToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetImageEmbeddingRequest
     */
    'base64Image'?: string;
}
/**
 * 
 * @export
 * @interface GetImageEmbeddingResponse
 */
export interface GetImageEmbeddingResponse {
    /**
     * 
     * @type {string}
     * @memberof GetImageEmbeddingResponse
     */
    'size'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetImageEmbeddingResponse
     */
    'outputUrls'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetImageEmbeddingResponse
     */
    'base64_embedding'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProtobufAny
 */
export interface ProtobufAny {
    /**
     * 
     * @type {string}
     * @memberof ProtobufAny
     */
    'type_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtobufAny
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface RpcStatus
 */
export interface RpcStatus {
    /**
     * 
     * @type {number}
     * @memberof RpcStatus
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof RpcStatus
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ProtobufAny>}
     * @memberof RpcStatus
     */
    'details'?: Array<ProtobufAny>;
}

/**
 * SAMImageEncoderApi - axios parameter creator
 * @export
 */
export const SAMImageEncoderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetImageEmbeddingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sAMImageEncoderGetImageEmbedding: async (body: GetImageEmbeddingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('sAMImageEncoderGetImageEmbedding', 'body', body)
            const localVarPath = `/SAMImageEncoder/getImageEmbedding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SAMImageEncoderApi - functional programming interface
 * @export
 */
export const SAMImageEncoderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SAMImageEncoderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetImageEmbeddingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sAMImageEncoderGetImageEmbedding(body: GetImageEmbeddingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetImageEmbeddingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sAMImageEncoderGetImageEmbedding(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SAMImageEncoderApi - factory interface
 * @export
 */
export const SAMImageEncoderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SAMImageEncoderApiFp(configuration)
    return {
        /**
         * 
         * @param {GetImageEmbeddingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sAMImageEncoderGetImageEmbedding(body: GetImageEmbeddingRequest, options?: any): AxiosPromise<GetImageEmbeddingResponse> {
            return localVarFp.sAMImageEncoderGetImageEmbedding(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sAMImageEncoderGetImageEmbedding operation in SAMImageEncoderApi.
 * @export
 * @interface SAMImageEncoderApiSAMImageEncoderGetImageEmbeddingRequest
 */
export interface SAMImageEncoderApiSAMImageEncoderGetImageEmbeddingRequest {
    /**
     * 
     * @type {GetImageEmbeddingRequest}
     * @memberof SAMImageEncoderApiSAMImageEncoderGetImageEmbedding
     */
    readonly body: GetImageEmbeddingRequest
}

/**
 * SAMImageEncoderApi - object-oriented interface
 * @export
 * @class SAMImageEncoderApi
 * @extends {BaseAPI}
 */
export class SAMImageEncoderApi extends BaseAPI {
    /**
     * 
     * @param {SAMImageEncoderApiSAMImageEncoderGetImageEmbeddingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SAMImageEncoderApi
     */
    public sAMImageEncoderGetImageEmbedding(requestParameters: SAMImageEncoderApiSAMImageEncoderGetImageEmbeddingRequest, options?: AxiosRequestConfig) {
        return SAMImageEncoderApiFp(this.configuration).sAMImageEncoderGetImageEmbedding(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


