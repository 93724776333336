/*
 * File: projects.page.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { ProjectDrawer } from "./components/project-drawer/project-drawer.component";
import { DeleteProjectDialog } from "./components/delete-project/delete-project.dialog";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { Logger } from "utilities/logger";
import { CreateProjectDialog } from "./components/create-project/create-project-dialog.component";
import { VBPageTitle } from "components/common/vb-page-title.component";
import { VBSpacer } from "components/common/vb-spacer.component";
import { ProjectDTO } from "services/label-service/dtos";
import { VBSearchFilter } from "components/common/vb-search-filter.component";
import { useProjects } from "hooks/project/use-projects";
import { LinearProgress } from "@material-ui/core";
import { createProjectAsync } from "store/customer/projects/projects.slice";
import {
  selectIsProjectsLoaded,
  selectProjectsRequesting,
} from "store/customer/projects/projects.selector";
import {
  ProjectAction,
  ProjectTable,
} from "./components/project-table.component";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import NoProject from "./components/no-project";
import * as Sentry from "@sentry/react";

export const ProjectsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const [project, setProject] = useState<ProjectDTO | null>(null);
  const [deletingtItem, setDeletingItem] = useState<ProjectDTO | null>(null);
  const [createProjectVisible, setCreateProjectVisible] = useState(false);
  const [creatingProject, setCreatingProject] = useState(false);
  const { projects } = useProjects();
  const requesting = useAppSelector(selectProjectsRequesting);
  const isLoaded = useAppSelector(selectIsProjectsLoaded);

  const onClickCreateProject = () => {
    setCreateProjectVisible(true);
  };

  function handleSelect(project: ProjectDTO, action?: ProjectAction) {
    if (action === ProjectAction.VIEW) {
      history.push(`${path}/${project.id}/overview/progress`);
      return;
    } else if (action === ProjectAction.EDIT) {
      setProject(project);
      return;
    } else if (action === ProjectAction.DELETE) {
      setDeletingItem(project);
      return;
    }
    if (!action) history.push(`${path}/${project.id}/overview/progress`);
  }

  const handleCreateProject = async (payload: Partial<ProjectDTO>) => {
    if (!currentUser || creatingProject) return;
    try {
      setCreatingProject(true);
      const response = await dispatch(createProjectAsync(payload));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textCreatedSuccess")));
      setCreateProjectVisible(false);
    } catch (err) {
      Sentry.captureException(err);
      Logger.log("err", err);
      dispatch(enqueueErrorNotification(t("common:textCreatedFailed")));
    } finally {
      setCreatingProject(false);
    }
  };

  const isEmpty = isLoaded && !requesting && !projects.length;
  const totalProjects = projects.length > 0 ? `(${projects.length})` : "";
  return (
    <div className="relative flex flex-col h-full p-4">
      <div className="absolute top-0 left-0 right-0 z-10">
        {requesting && <LinearProgress />}
      </div>
      <div className="flex items-center h-16 gap-4 p-4">
        <VBPageTitle text={t("project:pageTitle", { total: totalProjects })} />
        <VBSpacer />
        {!isEmpty && (
          <>
            <VBSearchFilter placeholder={t("common:placeholderSearch")} />
            <button className="button-primary" onClick={onClickCreateProject}>
              {t("project:buttonCreateProject")}
            </button>
          </>
        )}
      </div>
      {!isEmpty && <ProjectTable projects={projects} onSelect={handleSelect} />}
      {isEmpty && <NoProject onCreate={() => setCreateProjectVisible(true)} />}
      {/* <VBEmptyContent
        visible={isEmpty}
        iconClass="uir-folder"
        title={t("project:empty.title")}
        message={t("project:empty.message")}
        ctaText={t("project:empty.textCreateProject")}
        onCTAClick={() => setCreateProjectVisible(true)}
        createIfNotExist
      /> */}
      {deletingtItem && (
        <DeleteProjectDialog
          project={deletingtItem}
          handleClose={() => setDeletingItem(null)}
        />
      )}
      <ProjectDrawer
        open={project !== null}
        project={project}
        handleClose={() => setProject(null)}
      />
      {createProjectVisible && (
        <CreateProjectDialog
          onSubmit={handleCreateProject}
          onClose={() => setCreateProjectVisible(false)}
          processing={creatingProject}
        />
      )}
    </div>
  );
};
