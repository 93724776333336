/*
 * File: use-projects.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { SearchParams } from "models/common/search-params";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RequestOptions } from "services/common/request-options";
import { selectProjects } from "store/customer/projects/projects.selector";
import { loadProjectsAsync } from "store/customer/projects/projects.slice";
import { useAppDispatch, useAppSelector } from "../use-redux";
import * as Sentry from "@sentry/react";

export const useProjects = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const projects = useAppSelector(selectProjects);

  useEffect(() => {
    function getQueryParam(
      urlSearchParams: URLSearchParams,
      key: string,
      defaultValue: string
    ): string {
      const value = urlSearchParams.get(key);
      if (value) return value;
      return defaultValue;
    }

    try {
      const urlParams = new URLSearchParams(location.search);
      const search = getQueryParam(urlParams, SearchParams.SEARCH, "");
      const type = getQueryParam(urlParams, SearchParams.TYPE, "");
      const page = parseInt(getQueryParam(urlParams, SearchParams.PAGE, "1"));
      const pageSize = getQueryParam(urlParams, SearchParams.SIZE, "200");
      const sort = getQueryParam(
        urlParams,
        SearchParams.SORT,
        "createdDate,desc"
      );

      const options: RequestOptions = {};
      if (page) options[SearchParams.PAGE] = (page - 1).toString();
      if (pageSize) options[SearchParams.SIZE] = pageSize.toString();
      if (search) options["name.contains"] = search;
      if (type) options[`${SearchParams.TYPE}`] = type;
      if (sort) options[SearchParams.SORT] = sort;

      dispatch(loadProjectsAsync(options));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [dispatch, location.search]);

  return { projects };
};
