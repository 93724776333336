/*
 * File: labeler-profile.page.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 22nd November 2021 4:01:19 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { IconEdit } from "components/common/vb-icon.component";
import { UserAvatar } from "components/common/vb-user-avatar.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserInfoDTO } from "services/user-service/dtos/user-info.dto";
import { selectCurrentUser, selectUserInfo } from "store/auth/auth.selectors";
import { updateUserInfoAsync } from "store/auth/auth.slice";
import { enqueueErrorNotification, enqueueSuccessNotification } from "store/common/notification/notification.actions";
import { ChangePasswordModal } from "./components/change-password.modal";
import { VBInput } from "./components/vb-input.component";
import * as Sentry from "@sentry/react";

export const LabelerProfilePage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const userInfo = useAppSelector(selectUserInfo);
  const [isEditing, setEditing] = useState(false);
  const [localUserInfo, setLocalUserInfo] = useState<Partial<UserInfoDTO> | null>(() => {
    if (!userInfo) return null;
    return {...userInfo};
  });
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const hasErrors = useMemo(() => {
    return Object.keys(errors).length > 0;
  }, [errors]);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (!userInfo) return;
    setLocalUserInfo({...userInfo});
  }, [userInfo])

  if (!currentUser || !userInfo || !localUserInfo) return null;

  const setUserInfoField = (field: string, value: string, validate = false) => {
    if (validate){
      if (!value){
        setErrors({
          ...errors,
          [field]: t("common:errors.empty"),
        })
      } else {
        if (errors.hasOwnProperty(field)){
          let newError = {...errors};
          delete newError[field];
          setErrors(newError);
        }
      }
    }
    
    setLocalUserInfo({...localUserInfo, [field]: value});
    setIsChanged(true);
  }

  const handleCancelEdit = () => {
    setLocalUserInfo({...userInfo});
    setEditing(false);
    setErrors({});
  }

  const handleSaveChanges = async () => {
    try {
      setIsChanged(false);
      const payload = {
        ...localUserInfo,
        name: `${localUserInfo.firstName} ${localUserInfo.lastName}`,
      }

      await dispatch(updateUserInfoAsync(payload));
      dispatch(enqueueSuccessNotification(t("common:textUpdatedSuccess")));
    } catch (err: any){
      Sentry.captureException(err);
      dispatch(enqueueErrorNotification(t("common:textUpdatedSuccess")));
    } finally {
      setEditing(false);
    }
  }

  return (
    <div className="w-full mb-8">
      <div className="flex items-center gap-1 px-6 py-6">
        <h2 className="flex-1 text-lg font-bold text-warning-500">{t("labelerhome:profile.title")}</h2>
        {!isEditing && (
          <button
            onClick={() => setEditing(true)}
            className="h-9 flex items-center rounded border border-warning-500 text-warning-500 bg-white px-3 gap-1.5"
          >
            <span>{t("common:buttonEditProfile")}</span>
            <IconEdit className="w-5 h-5" />
          </button>
        )}

        {isEditing && (
          <div className="flex items-center gap-4">
            <button
              onClick={handleCancelEdit}
              className="h-9 flex items-center rounded border border-warning-500 text-warning-500 bg-white px-3 gap-1.5"
            >
              <span>{t("common:buttonCancel")}</span>
            </button>

            <button
              onClick={handleSaveChanges}
              className="h-9 flex items-center rounded border border-warning-500 bg-warning-500 text-white px-3 gap-1.5 disabled:opacity-50"
              disabled={!isChanged || hasErrors}
            >
              <span>{t("common:buttonSaveChanges")}</span>
            </button>
          </div>
        )}
      </div>

      <div className="flex items-center gap-6 p-6 mx-6 bg-white border border-gray-300 rounded">
        <div className="flex-shrink-0">
          <UserAvatar
            className="w-24 h-24 text-3xl"
            name={userInfo.name || ""}
          />
        </div>
        <div className="grid flex-1 grid-cols-2">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <div className="text-sm text-background-500">{t("labelerhome:profile.textAccountName")}</div>
              <div className="font-semibold text-background-700">
                {userInfo.name}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="text-sm text-background-500">{t("labelerhome:profile.textEmail")}</div>
              <div className="font-semibold text-background-700">
                {currentUser.email}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <div className="text-sm text-background-500">{t("labelerhome:profile.textHospital")}</div>
              <div className="font-semibold text-background-700">--</div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="text-sm text-background-500">{t("labelerhome:profile.textAccountStatus")}</div>
              <div className="font-semibold text-warning-500">{t("labelerhome:profile.textActivated")}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-6 mx-6 my-6">
        <VBInput
          disabled={!isEditing}
          required
          label="First name"
          value={localUserInfo.firstName}
          onValueChanged={value => setUserInfoField("firstName", value, true)}
          hint={errors["firstName"]}
          placeholder="First name"
        />
        <VBInput
          disabled={!isEditing}
          required
          label="Last name"
          value={localUserInfo.lastName}
          onValueChanged={value => setUserInfoField("lastName", value, true)}
          hint={errors["lastName"]}
          placeholder="Last name"
        />
        <VBInput
          disabled={!isEditing}
          required
          label="Phone number"
          value={localUserInfo.phoneNumber}
          onValueChanged={value => setUserInfoField("phoneNumber", value)}
          placeholder="Phone number"
        />
        <VBInput
          disabled={!isEditing}
          label="Unit"
          placeholder="Unit"
        />
        <div></div>
        <div></div>
        <div className="flex justify-between">
          <div className="flex-grow relative">
            <VBInput
              disabled={true}
              required
              value="*************"
              label="Password"
              type="password"
            />
            {
              isEditing &&
              <div className="w-20 h-12 self-end flex justify-center absolute right-0 bottom-0 text-warning-500 bg-background-100 border-t border-r border-b cursor-pointer">
                <button
                  disabled={!isEditing}
                  onClick={_ => setShowChangePassword(true)}
                >
                  {t("common:buttonChange")}
                </button>
              </div>
            }
          </div>
        </div>
      </div>

      <ChangePasswordModal
        visible={showChangePassword}
        onClose={() => setShowChangePassword(false)}
      />
    </div>
  );
};
