/*
 * File: labeler-performance.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 27th August 2021 10:34:49 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { UserDTO } from "services/user-service/dtos/user.dto";
import { AtLeast } from "types/common";
import { ExpandButton } from "../../project-batch/batch-detail/pages/overview/components/performance/labeler-distribution/expand-button.component";
import { LabelDistributionDTO } from "store/customer/project-data/labeler-distribution/labeler-distribution.state";
import { ObservationDTO } from "services/label-service/dtos";
import {
  LABELER_DIST_COLUMNS,
  LABELER_DIST_COLUMNS_TEXT,
  ObservationDistTree,
} from "../../components/observation-dist-tree.component";
import { convertLabelerDistributionToRowsModel } from "models/common/observations-stats";
import { useMemo } from "react";
import { useAppSelector } from "hooks/use-redux";
import { selectIsTextProject } from "store/customer/project/project.selectors";

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    root: {
      border: "none",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
    },
    summary: {
      padding: 0,
      margin: 0,
    },
    details: {
      display: "block",
      padding: 0,
      margin: 0,
    },
  })
);

interface Props {
  distributionData: LabelDistributionDTO | null;
  labeler: AtLeast<UserDTO, "email">;
  labels: ObservationDTO[];
  expanded?: boolean;
  onExpandChange?(expand: boolean): void;
}

export const LabelerPerformance = ({
  distributionData,
  labeler,
  labels,
  expanded,
  onExpandChange,
}: Props) => {
  const { t } = useTranslation();
  const isTextProject = useAppSelector(selectIsTextProject);
  const distRowsData = useMemo(() => {
    if (!distributionData || !labels) return [];
    return convertLabelerDistributionToRowsModel(distributionData, labels);
  }, [distributionData, labels]);
  const classes = useStyles();

  function handleChange(_: any, isExpanded: boolean) {
    onExpandChange && onExpandChange(isExpanded);
  }

  if (!distributionData) return null;

  return (
    <div className="mt-4">
      <Accordion
        classes={{ root: classes.root }}
        expanded={expanded}
        onChange={handleChange}
      >
        <AccordionSummary classes={{ root: classes.summary }}>
          <div className="flex items-center">
            <ExpandButton expanded={expanded} />
            <span className="pr-2">Labeler:</span>{" "}
            <strong>{labeler.email}</strong>
          </div>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.details }}>
          <div>
            <div>
              <div className="py-2 px-2">
                {t(
                  "project:batchDetails.labelerDistribution.textNumFinishedTask",
                  { count: distributionData.summary.finishedTask }
                )}{" "}
                <span className="px-4">|</span>{" "}
                {t("project:batchDetails.labelerDistribution.textStartingDate")}
                :{" "}
                {t("common:formattedDate", {
                  date: new Date(distributionData.summary.startDate),
                })}{" "}
                <span className="px-4">|</span>{" "}
                {t("project:batchDetails.labelerDistribution.textLastUpdate")}:{" "}
                {t("common:formattedDate", {
                  date: new Date(distributionData.summary.lastModifiedDate),
                })}{" "}
                <span className="px-4">|</span>{" "}
                {t(
                  "project:batchDetails.labelerDistribution.textHourAverageSpeed"
                )}
                : {distributionData.summary.taskPerHour}
              </div>

              <ObservationDistTree
                rows={distRowsData}
                columns={
                  isTextProject
                    ? LABELER_DIST_COLUMNS_TEXT
                    : LABELER_DIST_COLUMNS
                }
                nameFlex={3}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
