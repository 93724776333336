/*
 * File: use-dataset-tag.hook.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 30th September 2021 4:23:02 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { selectTags } from "store/customer/dataset/dataset.selectors";
import { loadTagsAsync } from "store/customer/dataset/dataset.slice";
import { RequestStatus } from "store/base/base.state";
import * as Sentry from "@sentry/react";

export const useDatasetTag = () => {
  const dispatch = useAppDispatch();
  const tags = useAppSelector(selectTags);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const [requestError, setRequestError] = useState<string | null>(null);

  const requestData = useCallback(async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);
      await dispatch(loadTagsAsync());
      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      Sentry.captureException(error);
      setRequestError(error.message || "Failed to get tags");
      setRequestStatus(RequestStatus.FAILURE);
    }
    return;
  }, [dispatch]);

  useEffect(() => {
    if (tags.length === 0 && requestStatus === RequestStatus.IDLE) {
      requestData();
    }
  }, [tags, requestStatus, requestData]);

  return {
    tags,
    requestStatus,
    requestError,
    requestData,
  };
};
