import { AnalyticsQueryResponse } from "services/analytics-service";
import { ProjectTypeNames, ProjectTypes } from "services/label-service/dtos";
import { CardRowDataModel } from "../context/dashboard.state";

const COL_TYPE = "type";
const COL_COUNT = "count";

export const DEFAULT_DATA: CardRowDataModel[] = [
  {
    name: ProjectTypeNames[ProjectTypes.MEDICAL_IMAGE],
    total: 0,
  },
  {
    name: ProjectTypeNames[ProjectTypes.GENERAL_IMAGE],
    total: 0,
  },
  {
    name: ProjectTypeNames[ProjectTypes.TEXT],
    total: 0,
  },
  {
    name: ProjectTypeNames[ProjectTypes.AUDIO],
    total: 0,
  },
];

export const cardRowProjectMapper = (response: AnalyticsQueryResponse): CardRowDataModel[] => {
  const rows = response.data.rows;
  if (rows && rows.length > 0) {
    const resRows = DEFAULT_DATA.map(item => ({...item}));
    const medicalRow = rows.find((r: any) => r[COL_TYPE] === ProjectTypes.MEDICAL_IMAGE);
    const generalRow = rows.find((r: any) => r[COL_TYPE] === ProjectTypes.GENERAL_IMAGE);
    const textRow = rows.find((r: any) => r[COL_TYPE] === ProjectTypes.TEXT);
    const audioRow = rows.find((r: any) => r[COL_TYPE] === ProjectTypes.AUDIO);
    
    if (medicalRow) resRows[0].total = medicalRow[COL_COUNT] || 0;
    if (generalRow) resRows[1].total = generalRow[COL_COUNT] || 0;
    if (textRow) resRows[2].total = textRow[COL_COUNT] || 0;
    if (audioRow) resRows[3].total = audioRow[COL_COUNT] || 0;

    return resRows;
  }
  return DEFAULT_DATA;
}