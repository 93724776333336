/*
 * File: storage-httpclient.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { setupLabelInterceptor } from "services/common/label-interceptor";
import { ENV_CONFIG } from "../../configs/env.config";

const _defaultHeaderConfig: AxiosRequestConfig = {
  headers: {
    Accept: "application/json",
  },
  withCredentials: false,
};

let _axiosInstance: AxiosInstance | undefined = undefined;

function getAxiosInstance() {
  if (_axiosInstance) return _axiosInstance;
  _axiosInstance = setupLabelInterceptor(
    axios.create({
      baseURL: ENV_CONFIG.STORAGE_SERVICE_URL,
    })
  );
  return _axiosInstance;
}

export function storageAPIget(url: string, config = _defaultHeaderConfig) {
  return getAxiosInstance().get(url, config);
}

export function storageAPIpost(
  url: string,
  data = {},
  config = _defaultHeaderConfig
) {
  return getAxiosInstance().post(url, data, config);
}
export function storageAPIdelete(url: string, config = _defaultHeaderConfig) {
  return getAxiosInstance().delete(url, config);
}

export function storageAPIUpload(
  url: string,
  data: FormData,
  config = _defaultHeaderConfig
) {
  return getAxiosInstance().post(url, data, config);
}

export function storageAPIput(url: string, config = _defaultHeaderConfig) {
  return getAxiosInstance().put(url, config);
}
