/*
 * File: scope.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 31st August 2021 5:20:16 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ScopeDTO } from "services/storage-service/dto/scope.dto";
import { RootState } from "store";

export const selectScopes = (state: RootState): ScopeDTO[] => {
  return state.scope.ids.map((id) => state.scope.entities[id]);
};

export const selectScopeOptions = (
  state: RootState
): { id: string; name: string }[] => {
  const scopes: ScopeDTO[] = state.scope.ids.map(
    (id) => state.scope.entities[id]
  );
  return [
    { id: "", name: "ALL" },
    ...scopes.map((scope) => ({ name: scope.name, id: scope.code })),
  ];
};

export const selectScopeRequestStatus = (state: RootState) => {
  return state.scope.status;
};

export const selectScopeRequestError = (state: RootState) => {
  return state.scope.error;
};
