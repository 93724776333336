/*
 * File: scope.slice.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 31st August 2021 5:16:16 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_SCOPE_STATE, ScopeState } from "./scope.state";
import { scopeReducerBuilder } from "./scope.thunk";

const SLICE_NAME: string = "scope";
const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_SCOPE_STATE,
  reducers: {
    resetScope(state: ScopeState) {
      Object.assign(state, INITIAL_SCOPE_STATE);
    },
  },
  extraReducers: (builder) => {
    scopeReducerBuilder(builder);
  },
});

export const { resetScope } = slice.actions;

export const scopeReducer = slice.reducer;
