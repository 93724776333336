/*
 * File: text-labeling-review.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 8th August 2022 5:09:27 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import classnames from "classnames";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { selectTextConflictTokenIds } from "store/labeler/text-workspace/text-review/text-review.selectors";
import {
  relationHovered,
  textConflictAnnotationSelected,
} from "store/labeler/text-workspace/text-workspace.slice";
import { useTextWorkspaceContext } from "../../context/text-workspace/text-workspace.context";
import useTextConflict from "../../hooks/use-text-conflict.hook";
import { TokenData } from "../../models/text-viewer.models";
import { TextViewer } from "../text-viewer/text-viewer.component";

export const TextLabelingReview = () => {
  const dispatch = useAppDispatch();
  const { tokenizer, labels, sentences } = useTextWorkspaceContext();
  const { labelingData } = useTextConflict();
  const conflictTokenIds = useAppSelector(selectTextConflictTokenIds);

  function handleSelectToken(token: TokenData) {
    // TODO
  }

  function handleSelectAnnotation(annotationId: string) {
    dispatch(textConflictAnnotationSelected(annotationId));
  }

  function handleSelectRelation(relationId: string) {
    dispatch(textConflictAnnotationSelected(relationId));
  }

  function handleMouseEnterRelation(relationId: string) {
    dispatch(relationHovered(relationId));
  }

  function handleMouseLeaveRelation(_relationId: string) {
    dispatch(relationHovered(""));
  }

  return (
    <div className="h-full">
      <div
        className={classnames(
          "flex flex-col h-full bg-white rounded relative overflow-hidden"
        )}
      >
        <div
          className={`relative w-full flex-auto overflow-y-auto`}
          style={{ scrollPaddingTop: "160px" }}
        >
          <div
            className="absolute w-1 h-1 top-80 right-6"
            id="end"
            style={{ zIndex: -1 }}
          />
          <div
            className={classnames(
              "relative flex flex-wrap items-end leading-loose gap-x-2 gap-y-8"
            )}
          >
            <TextViewer
              tokenizer={tokenizer}
              readonly
              labels={labels}
              sentences={sentences}
              observations={labelingData.annotations}
              connections={labelingData.relationAnnotations}
              conflictTokenIds={conflictTokenIds}
              onSelectToken={handleSelectToken}
              onSelectAnnotation={handleSelectAnnotation}
              onSelectRelation={handleSelectRelation}
              onMouseEnterRelation={handleMouseEnterRelation}
              onMouseLeaveRelation={handleMouseLeaveRelation}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
