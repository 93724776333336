import { Tooltip } from "@material-ui/core";
import { useState } from "react";
import { classnames } from "utilities/classes";
import { formatNumber } from "utilities/formatter/number-formatter.utils";
import { getPercentage } from "utilities/misc/misc.utils";
import { useTableSort } from "../hooks/use-table-sort.hook";
import { DashboardTableCommon } from "./dashboard-table-common.component";

export interface RowDataTableBatch {
  id: number;
  name: string;
  numTask: number;
  numTaskAvailable: number;
  numTaskWorking: number;
  numTaskCompleted: number;
  totalHours: number;
  hoursPerTask: number;
}

interface ProjectStatisticTableProps {
  data: RowDataTableBatch[];
}
export const BatchStatisticTable = ({ data }: ProjectStatisticTableProps) => {
  const {
    rows,
    sort,
    handleSelectSort,
  } = useTableSort<RowDataTableBatch>(data);

  const maxData: RowDataTableBatch = {
    id: -1,
    name: "",
    numTask: 0,
    numTaskAvailable: 0,
    numTaskWorking: 0,
    numTaskCompleted: 0,
    totalHours: 0,
    hoursPerTask: 0,
  };
  data.forEach((itemData) => {
    maxData.numTask = Math.max(maxData.numTask, itemData.numTask);
    maxData.numTaskAvailable = Math.max(maxData.numTaskAvailable, itemData.numTaskAvailable);
    maxData.numTaskWorking = Math.max(maxData.numTaskWorking, itemData.numTaskWorking);
    maxData.numTaskCompleted = Math.max(maxData.numTaskCompleted, itemData.numTaskCompleted);
    maxData.totalHours = Math.max(maxData.totalHours, itemData.totalHours);
    maxData.hoursPerTask = Math.max(maxData.hoursPerTask, itemData.hoursPerTask);
  });

  const COLOR_AVAILABLE = "#3241FF";
  const COLOR_WORKING = "#FFBE6F";
  const COLOR_COMPLETED = "#3990FF";

  const [columns] = useState<
    {
      field: keyof RowDataTableBatch;
      header: string;
      color?: string;
      renderCell: (row: RowDataTableBatch) => JSX.Element;
    }[]
  >([
    {
      field: "id",
      header: "ID",
      renderCell: (row: RowDataTableBatch) => {
        return <span className="text-xs text-primary">{row.id}</span>;
      },
    },
    {
      field: "name",
      header: "Name",
      renderCell: (row: RowDataTableBatch) => {
        return <span className="text-xs text-background-700">{row.name}</span>;
      },
    },
    {
      field: "numTask",
      header: "Number of Tasks",
      renderCell: (row: RowDataTableBatch) => {
        const percentAvailable = getPercentage(row.numTaskAvailable, maxData.numTaskAvailable);
        const percentWorking = getPercentage(row.numTaskWorking, maxData.numTaskWorking);
        const percentCompleted = parseFloat((100 - percentAvailable - percentWorking).toFixed(2));
        const spanData: {
          value: number;
          percent: number;
          color: string;
          textColor: string;
        }[] = [
          {
            value: row.numTaskAvailable,
            percent: percentAvailable,
            color: COLOR_AVAILABLE,
            textColor: "white",
          },
          {
            value: row.numTaskWorking,
            percent: percentWorking,
            color: COLOR_WORKING,
            textColor: "#53616D",
          },
          {
            value: row.numTaskCompleted,
            percent: percentCompleted,
            color: COLOR_COMPLETED,
            textColor: "white",
          },
        ];
        return (
          <div className="flex w-full h-4 gap-1"
            style={{
              minWidth: "300px"
            }}
          >
            <div className="flex flex-1">
              {
                spanData.map(item => {
                  return (
                    <Tooltip
                      title={`${item.percent}% (${item.value})`}
                      placement="top"
                      key={item.color}
                    >
                      <div
                        className="relative flex items-center overflow-hidden"
                        style={{ width: `${item.percent}%` }}
                      >
                        <span
                          className="w-full h-4"
                          style={{
                            background: item.color,
                          }}
                        />
                        <span
                          className="absolute left-1.5 top-0 bottom-0"
                          style={{ color: item.textColor}}
                        >
                          {item.percent > 30 && `${item.percent}% (${item.value})`}
                          {item.percent <= 30 && `${item.percent}%`}
                        </span>
                      </div>
                    </Tooltip>
                  );
                })
              }
            </div>
            <div>
              {row.numTask}
            </div>
          </div>
        );
      },
    },
    {
      field: "totalHours",
      header: "Total hours",
      renderCell: (row: RowDataTableBatch) => {
        const percent = getPercentage(row.totalHours, maxData.totalHours);
        return (
          <div className="relative flex items-center w-full h-4 gap-1">
            <span
              className="h-4"
              style={{ width: `${percent}%`, backgroundColor: "#D1DBDF" }}
            />
            <span
              className={classnames({
                "absolute left-1.5 top-0 bottom-0 text-white": percent > 20,
              })}
            >
              {formatNumber(row.totalHours)}
            </span>
          </div>
        );
      },
    },
    {
      field: "hoursPerTask",
      header: "Hours per Task",
      renderCell: (row: RowDataTableBatch) => {
        const percent = getPercentage(row.hoursPerTask, maxData.hoursPerTask);
        return (
          <div className="relative flex items-center w-full h-4 gap-1">
            <span
              className="h-4"
              style={{ width: `${percent}%`, backgroundColor: "#D1DBDF" }}
            />
            <span
              className={classnames({
                "absolute left-1.5 top-0 bottom-0 text-white": percent > 20,
              })}
            >
              {formatNumber(row.hoursPerTask)}
            </span>
          </div>
        );
      },
    },
  ]);

  const colorLegends: {
    name: string;
    color: string;
  }[] = [
    {
      name: "Available",
      color: COLOR_AVAILABLE,
    },
    {
      name: "Working",
      color: COLOR_WORKING,
    },
    {
      name: "Completed",
      color: COLOR_COMPLETED,
    },
  ];

  return (
    <div>
      <div className="flex w-full px-4 pt-4">
        <div className="flex w-1/2">        
        </div>
        <div className="flex flex-row-reverse w-1/2 gap-6">
          {
            colorLegends.reverse().map(legend => {
              return (
                <div className="flex items-center gap-2" key={legend.color}>
                  <div className="w-4 h-4"
                    style={{ background: legend.color }}
                  >
                  </div>
                  <span>{legend.name}</span>
                </div>
              );
            })
          }
        </div>
      </div>
      <DashboardTableCommon
        rows={rows}
        columns={columns}
        sort={sort}
        handleSelectSort={handleSelectSort}
      />
    </div>
  );
};
