


export const PathologyEditorImageComponent = () => {

  return (
    <div>
      Image info
    </div>
  )
}
