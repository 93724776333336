/*
 * File: file.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 11th July 2022 11:17:34 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { LabelingFile } from "domain/labeling/file";
import { StorageFileDTO } from "models/dataset/storage-file.model";

function fromDTO(dto: StorageFileDTO): LabelingFile {
  let name = dto.fileName;
  if (!name && dto?.additionalProperties?.metadata?.fileName) {
    name = dto?.additionalProperties?.metadata?.fileName;
  }
  return {
    id: dto.id,
    name: name,
    url: dto.originalUrl || dto.url,
  };
}

function toDTO(entity: LabelingFile): StorageFileDTO {
  throw new Error("Method not implemented");
}

export const fileMapper = {
  fromDTO,
  toDTO,
};
