
export const isValidNumber = (n: number | undefined) => {
  if (n === undefined || isNaN(n)) return false;
  return true;
}

export const handleInvalidNumber = (n: number, m: number) => {
  if (!isValidNumber(n)) return m;
  return n;
}
