import { AnalyticsQueryResponse } from "services/analytics-service";
import { RowDataTableBatch } from "../components/batch-table.component";

const COL_ID = "id";
const COL_NAME = "name";
const COL_TASK_COUNT_AVAILABLE = "taskcountavailable";
const COL_TASK_COUNT_WORKING = "taskcountworking";
const COL_TASK_COUNT_COMPLETED = "taskcountcompleted";
const COL_TOTAL_HOURS = "totalhours";

export const rowTableBatchMapper = (response: AnalyticsQueryResponse): RowDataTableBatch[] => {
  const rows = response.data.rows;
  if (rows && rows.length > 0) {
    return rows.map((row: any) => {
      const numTaskAvailable = row[COL_TASK_COUNT_AVAILABLE] || 0;
      const numTaskWorking = row[COL_TASK_COUNT_WORKING] || 0;
      const numTaskCompleted = row[COL_TASK_COUNT_COMPLETED] || 0;
      const numTask = numTaskAvailable + numTaskWorking + numTaskCompleted;
      const totalHours = row[COL_TOTAL_HOURS] || 0;

      const tableRow: RowDataTableBatch = {
        id: row[COL_ID],
        name: row[COL_NAME],
        numTask: numTask,
        numTaskAvailable: numTaskAvailable,
        numTaskWorking: numTaskWorking,
        numTaskCompleted: numTaskCompleted,
        totalHours: totalHours,
        hoursPerTask: numTask ? parseFloat((totalHours / numTask).toFixed(2)) : 0,
      };
      return tableRow;
    });
  }
  return [];
}