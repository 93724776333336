/*
 * File: text-label-item.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 23rd December 2021 2:05:16 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  IconCheckbox,
  IconCheckboxChecked,
} from "components/common/vb-icon.component";
import { Label } from "domain/text-labeling";
import { classnames } from "utilities/classes";

interface Props {
  label: Label;
  selected?: boolean;
  checked?: boolean;
  hidden?: boolean;
  isLeafNodeItem?: boolean;
  paddingLeft?: number;
  disabled?: boolean;
  onSelect(labelId: string): void;
}
export const TextLabelItem = ({
  label,
  selected,
  checked,
  isLeafNodeItem = true,
  paddingLeft = 0,
  disabled,
  onSelect,
}: Props) => {
  const handleSelect = () => {
    if (disabled) return;
    isLeafNodeItem && onSelect(label.id);
  };

  return (
    <div
      className={classnames("relative parent leading-none w-full py-2", {
        "bg-blue-100": !!selected,
        "hover:bg-gray-100": isLeafNodeItem && !disabled && !selected,
      })}
    >
      <div
        className={classnames("flex items-center w-full gap-2 px-4", {
          "cursor-pointer": isLeafNodeItem,
          "pointer-events-none": !!disabled,
        })}
        style={{ paddingLeft: `${paddingLeft + 1}rem` }}
        onClick={handleSelect}
      >
        {isLeafNodeItem && (
          <div>
            {checked && (
              <IconCheckboxChecked
                className="w-5 h-5"
                style={{ color: label.color }}
              />
            )}
            {!checked && (
              <IconCheckbox
                className="w-5 h-5"
                style={{ color: label.color }}
              />
            )}
          </div>
        )}
        <div
          className={classnames(
            "relative flex-1 overflow-hidden overflow-ellipsis parent",
            {
              "text-background-500": !!disabled,
            }
          )}
        >
          {label.name}
        </div>
      </div>
    </div>
  );
};
