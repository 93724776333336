/*
 * File: create-workspace-card.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 4th August 2021 2:35:55 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

interface Props {
  handleClick(): void;
}

export const CreateWorkspaceCard = ({ handleClick }: Props) => {
  return (
    <div
      className="relative flex items-center justify-center h-40 duration-200 bg-white rounded-lg shadow cursor-pointer hover:shadow-lg"
      onClick={handleClick}
    >
      <div className="flex items-center justify-center w-10 h-10 text-xl text-gray-300 rounded-full">
        <i className="uir-plus"></i>
      </div>
    </div>
  );
};
