import { useCallback, useMemo, useRef, useState } from "react";
import { RequestOptions } from "services/common/request-options";
import { JobStatisticBatchResponseDTO } from "services/query/query.dto";
import { QueryService } from "services/query/query.service";
import { RequestStatus } from "store/base/base.state";
import useDeepCompareEffect from "use-deep-compare-effect";
import { mapperBatchPaymentRow } from "../mapper/batch-payment.mapper";
import {
  BatchPaymentFilter,
  BatchPaymentTableRow,
} from "../model/batch-payment.models";
import * as Sentry from "@sentry/react";

export const useBatchPaymentHook = (batchId: number | string) => {
  const [totalCost, setTotalCost] = useState(0);
  const [totalJob, setTotalJob] = useState(0);
  const [totalPaymentRow, setTotalPaymentRow] = useState(30);
  const [paymentRows, setPaymentRows] = useState<BatchPaymentTableRow[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.IDLE
  );
  const isLoading = useMemo(
    () => requestStatus === RequestStatus.LOADING,
    [requestStatus]
  );
  const forceRequest = useRef(false);

  const [filter, setFilter] = useState<BatchPaymentFilter>({
    page: 1,
    pageSize: 10,
  });

  const setFilterField = (field: keyof BatchPaymentFilter, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
    forceRequest.current = true;
  };

  const requestData = useCallback(
    async (batchId: string | number, options: RequestOptions) => {
      try {
        if (requestStatus !== RequestStatus.IDLE && !forceRequest.current)
          return;

        const res = await QueryService.getJobStatisticsBatch(batchId, options);
        const data = res.data as JobStatisticBatchResponseDTO;
        setTotalCost(data.totalPayment);
        setTotalJob(data.totalJob);
        setTotalPaymentRow(data.totalJob);
        const tableRows = data.data.map(mapperBatchPaymentRow.fromEntity);
        setPaymentRows(tableRows);

        setRequestStatus(RequestStatus.SUCCESS);
      } catch (error: any) {
        Sentry.captureException(error);
        setRequestStatus(RequestStatus.FAILURE);
        console.log(error);
      } finally {
        forceRequest.current = false;
      }
    },
    [requestStatus]
  );

  useDeepCompareEffect(() => {
    if (!batchId || !filter) return;
    const options = BatchPaymentFilter.toRequestOption(filter);
    requestData(batchId, options);
  }, [requestData, batchId, filter]);

  return {
    isLoading,
    totalCost,
    totalJob,
    totalPaymentRow,
    paymentRows,
    filter,
    setFilterField,
  };
};
