/*
 * File: batch-tasks.util.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 28th September 2022 10:52:26 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { SearchParams } from "models/common/search-params";
import { TaskFilter } from "../batch-tasks.context";

const DEFAULT_PAGE = 1;
const DEFAULT_SIZE = 10;

const getQueryParam = (
  urlSearchParams: URLSearchParams,
  key: string,
  defaultValue: string
): string => {
  return urlSearchParams.get(key) || defaultValue;
};

const getQueryParams = (
  urlSearchParams: URLSearchParams,
  key: string,
  defaultValue: string[]
): string[] => {
  return urlSearchParams.getAll(key) || defaultValue;
};

const taskFilterMapper = (locationSearch: string, batchId: number) => {
  if (!batchId) return null;
  const urlParams = new URLSearchParams(locationSearch);
  const filter: TaskFilter = {
    batchId: batchId,
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
  };
  const keys = [
    "batchId",
    "taskId",
    "fileName",
    "labelingStatus",
    "tags",
    "labelers",
    "observationId",
    "createdDate",
    "issueStatus",
    "size",
    "page",
    "sort",
    "consensusOperator",
    "consensusScore",
  ];
  for (const key of keys) {
    if (!urlParams.has(key)) continue;
    if (key === SearchParams.PAGE) {
      filter.page = parseInt(getQueryParam(urlParams, SearchParams.PAGE, "1"));
    } else if (key === SearchParams.SIZE) {
      filter.size = parseInt(getQueryParam(urlParams, SearchParams.SIZE, "10"));
    } else if (key === "consensusScore") {
      filter.consensusScore = parseFloat(
        getQueryParam(urlParams, "consensusScore", "0")
      );
    } else if (key === SearchParams.CREATED_DATE) {
      filter.createdDate = parseInt(
        getQueryParam(
          urlParams,
          SearchParams.CREATED_DATE,
          new Date().getTime().toString()
        )
      );
    } else if (key === SearchParams.TAGS) {
      (filter as any)[key] = getQueryParams(urlParams, key, []);
    } else if (key === SearchParams.LABELERS) {
      (filter as any)[key] = getQueryParams(urlParams, key, []);
    } else {
      const value = getQueryParam(urlParams, key, "");
      (filter as any)[key] = value;
    }
  }
  return filter;
};

export const batchTasksUtil = {
  taskFilterMapper,
};
