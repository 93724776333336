/*
 * File: vb-create-button.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ButtonHTMLAttributes } from "react";
import { classnames } from "utilities/classes";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}
export const VBCreateButton = ({
  text,
  className,
  disabled,
  ...rest
}: Props) => {
  return (
    <button
      className={classnames(
        `px-3 h-9 flex items-center justify-center bg-primary text-white rounded ${className}`,
        {
          "opacity-40 cursor-not-allowed": !!disabled,
          "cursor-pointer": !disabled,
        }
      )}
      disabled={disabled}
      {...rest}
    >
      {text}
    </button>
  );
};
