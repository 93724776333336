import { VBTabs } from "components/common/vb-tabs/vb-tabs.component";
import ObjectTree from "./object-tree";
import useSidebarTabs from "./use-sidebar-tabs.hook";
import { JobLabelList } from "../../workspace-panel/job-label-list.component";

export function ImageLabelingSidebar() {
  const { tabs, activeTab, selectTab } = useSidebarTabs();
  return (
    <div className="flex flex-col h-full overflow-hidden border-l border-background-700">
      <VBTabs
        className="flex-none border-background-700"
        activeClass="px-4 py-2 border-warning-500 text-warning-500"
        inActiveClass="px-4 py-2 border-gray-100 border-opacity-0 text-background-300"
        tabs={tabs}
        onSelect={selectTab}
        activeTab={activeTab}
      />
      <div className="flex-auto w-full overflow-hidden">
        {activeTab === "CLASSES" && (
          <div className="w-full h-full overflow-y-auto dark-scrollbar">
            <JobLabelList header="" />
          </div>
        )}
        {activeTab === "OBJECTS" && (
          <div className="w-full h-full overflow-y-auto dark-scrollbar">
            <ObjectTree />
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageLabelingSidebar;
