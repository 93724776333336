/*
 * File: keycloak.utils.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 31st July 2021 1:53:12 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import jwtDecode from "jwt-decode";
import { User, UserRole } from "../../store/auth/auth.state";

interface DecodedToken {
  email: string;
  name: string;
  sub: string;
  workspace: Record<UserRole, string[]>;
  global_roles?: string[];
}

export function userTokenMapper(token: string): any {
  const decodedToken = jwtDecode(token) as DecodedToken;
  const { email, name, sub, workspace, global_roles } = decodedToken;
  const defaultWorkspaces = {
    [UserRole.CUSTOMER]: [],
    [UserRole.LABELER]: [],
  };
  const userWorkspaces = workspace ? workspace : defaultWorkspaces;
  const user: User = {
    uid: sub,
    displayName: name,
    email: email,
    photoURL: "",
    workspace: userWorkspaces,
    globalRoles: global_roles,
  };
  return [user, decodedToken];
}
