import { IEvent } from "fabric/fabric-impl";
import { FabricTool } from "./fabric-tool";


export class FabricToolBrush extends FabricTool {

  handleMouseDown(fabricAbsX: number, fabricAbsY: number, e: IEvent) {
    alert("Not implement yet!");
  }

  handleMouseMove(fabricAbsX: number, fabricAbsY: number, e: IEvent) {
  }

  handleMouseUp(fabricAbsX: number, fabricAbsY: number, e: IEvent) {
  }
}
