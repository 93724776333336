import { Chip, TablePagination } from "@material-ui/core";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { TableBase } from "components/design-system/table/table-base.component";
import { TableColumn } from "components/design-system/table/types";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  TicketPriority,
  TicketResponseDTO,
} from "services/customer-service/customer.dto";
import { selectCustomerSupportTickets } from "store/common/customer-support/customer-support.selector";
import { loadTicketsAsync } from "store/common/customer-support/customer-support.slice";

interface TicketListModalProps {
  open: boolean;
  onClose: () => void;
  rowsPerPage?: number;
}

export const TicketListModal = ({
  open,
  onClose,
  rowsPerPage = 5,
}: TicketListModalProps) => {
  const dispatch = useAppDispatch();
  const { openModal } = useModalContext();

  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const tickets = useAppSelector(selectCustomerSupportTickets);

  const records = useMemo(() => {
    return tickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [tickets, page, rowsPerPage]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await dispatch(loadTicketsAsync());
      setIsLoading(false);
    })();
  }, [dispatch]);

  const getPriority = useCallback((priority: TicketPriority) => {
    switch (priority) {
      case TicketPriority.Medium:
        return {
          text: "Medium",
          color: "orange",
        };
      case TicketPriority.High:
        return {
          text: "High",
          color: "red",
        };
      case TicketPriority.Urgent: {
        return {
          text: "Urgent",
          color: "red",
        };
      }
      default:
        return {
          text: "Low",
          color: "green",
        };
    }
  }, []);

  const columns: TableColumn[] = [
    {
      key: "id",
      title: "id",
      dataIndex: "info.id",
    },
    {
      key: "title",
      title: "Title",
      dataIndex: "info.title",
      render: (record: TicketResponseDTO) => {
        return (
          <p
            className="cursor-pointer text-blue-500"
            onClick={() =>
              openModal(ModalTypes.TICKET_DETAIL, {
                ticket: record.info,
              })
            }
          >
            {record.info.title}
          </p>
        );
      },
    },
    {
      key: "state",
      title: "State",
      dataIndex: "info.state",
    },
    {
      key: "priority",
      title: "Priority",
      render: (record: TicketResponseDTO) => {
        const { text, color } = getPriority(record.info.priority);
        return (
          <Chip
            label={text}
            style={{ backgroundColor: color, color: "white" }}
          />
        );
      },
    },
    {
      key: "activity-date",
      title: "Activity Date",
      render: (record: TicketResponseDTO) => {
        return (
          <p>
            {new Date(
              record.info.lastModifiedDate || record.info.createdDate || ""
            ).toDateString()}
          </p>
        );
      },
    },
  ];

  return (
    <>
      <VBModal
        width="50%"
        open={open}
        onClose={onClose}
        title="Ticket Management"
        footerHidden
      >
        <>
          <TableBase columns={columns} data={records} loading={isLoading} />
          <TablePagination
            component="div"
            count={tickets.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
          />
        </>
      </VBModal>
    </>
  );
};
