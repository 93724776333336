
export const convertSecondToString = (second: number, excludeHourZero = false) => {
  second = Math.floor(second);
  let hour: number = Math.floor(second / 3600);
  let minute: number = Math.floor((second % 3600) / 60);
  let remainSecond: number = (second % 3600) % 60;

  let hourStr: string = hour.toString();
  let minuteStr: string = minute.toString();
  let secondStr: string = remainSecond.toString();
  if (hour < 10) hourStr = `0${hourStr}`;
  if (minute < 10) minuteStr = `0${minuteStr}`;
  if (remainSecond < 10) secondStr = `0${remainSecond}`;

  if (hour === 0 && excludeHourZero) {
    return `${minuteStr}:${secondStr}`;
  }

  return `${hourStr}:${minuteStr}:${secondStr}`;
}