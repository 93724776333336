import { useDownloadDatasetContext } from "contexts/download-dataset/download-dataset.context";
import { useAppDispatch } from "hooks/use-redux";
import { createContext, useContext, useEffect, useState } from "react";
import { DownloadDatasetPayload } from "services/label-service/apis/batch.api";
import { BatchDTO, isImageProject } from "services/label-service/dtos";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { loadBatchGenAnnoAsyncJobStatusAsync } from "store/customer/batch/batch.thunk";
import { useBatchDetailContext } from "../../context/batch-detail.context";
import * as Sentry from "@sentry/react";

export const BatchDataContext = createContext({} as BatchDataState);

export const useBatchDataContext = () => {
  return useContext(BatchDataContext);
};

export interface DownloadDataSetProps {
  formatType: string;
  filterType: string;
  name: string;
  isSaveImages: boolean;
  labelers: string[];
  taskIds: number[];
  sources: string[];
}

export interface BatchDataState {
  exportDataset: boolean;
  setExportDataset: (v: boolean) => void;
  downloadDataset: boolean;
  setDownloadDataset: (v: boolean) => void;
  importAnnotationOption: string;
  setImportAnnotationOption: (v: string) => void;
  showExportMessage: boolean;
  setShowExportMessage: (v: boolean) => void;

  downloadingBatch: BatchDTO | null;
  startDownloadAsync: (
    batch: BatchDTO,
    payload: DownloadDatasetPayload
  ) => Promise<void>;

  handleSelectImportAnnotation: (v: string) => void;
  handleDownloadDataset: ({
    formatType,
    filterType,
    name,
    isSaveImages,
  }: DownloadDataSetProps) => void;
}

interface BatchDataProviderProps {
  children: React.ReactNode | React.ReactNode[] | null;
}
export const BatchDataProvider = ({ children }: BatchDataProviderProps) => {
  const dispatch = useAppDispatch();
  const { batch, project } = useBatchDetailContext();
  const [exportDataset, setExportDataset] = useState(false);
  const [downloadDataset, setDownloadDataset] = useState(false);
  const [importAnnotationOption, setImportAnnotationOption] = useState("");
  const [showExportMessage, setShowExportMessage] = useState(false);

  const { downloadingBatch, startDownloadAsync } = useDownloadDatasetContext();

  async function handleDownloadDataset({
    formatType,
    filterType,
    name,
    isSaveImages,
    labelers,
    taskIds,
    sources,
  }: DownloadDataSetProps) {
    if (!project || !batch || downloadingBatch) return;
    try {
      const payload: DownloadDatasetPayload = {
        jobDownloadType: filterType || "COMPLETED",
        fileName: name,
        format: formatType,
        assignee: labelers,
        source: sources,
        taskId: taskIds,
      };
      if (!payload.taskId || payload.taskId.length === 0) {
        delete payload["taskId"];
      }
      if (!payload.source || payload.source.length === 0) {
        delete payload["source"];
      }
      if (!payload.assignee || payload.assignee.length === 0) {
        delete payload["assignee"];
      }
      if (isImageProject(project.type)) {
        payload.saveImage = isSaveImages;
      }

      startDownloadAsync(batch, payload);
      setDownloadDataset(false);
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || "Failed to download dataset";
      dispatch(enqueueErrorNotification(errMessage));
    }
  }

  async function handleSelectImportAnnotation(option: string) {
    setImportAnnotationOption(option);
  }

  useEffect(() => {
    dispatch(loadBatchGenAnnoAsyncJobStatusAsync(batch.id));
  }, [batch, dispatch]);

  const value: BatchDataState = {
    exportDataset,
    setExportDataset,
    downloadDataset,
    setDownloadDataset,
    importAnnotationOption,
    setImportAnnotationOption,
    showExportMessage,
    setShowExportMessage,

    downloadingBatch,
    startDownloadAsync,

    handleSelectImportAnnotation,
    handleDownloadDataset,
  };

  return (
    <BatchDataContext.Provider value={value}>
      {children}
    </BatchDataContext.Provider>
  );
};
