

export const PathologyLabelingReview = () => {

  return (
    <div>
      Pathology labeling review
    </div>
  )
}
