import moment from "moment";
import {
  LabelerReportRecordDTO,
  LabelerStatisticByBatch,
} from "services/label-service/dtos";
import { JobStatisticLabelerRowResponseDTO } from "services/query/query.dto";
import { CurrencySelectOption } from "store/common/payment/payment.state";
import { v4 } from "uuid";
import {
  LabelerReportByDateRow,
  LabelerReportByJobRow,
  LabelerReportByProjectRow,
} from "../models/labeler-report.models";

export const mapperLabelerReportByJobRow = {
  fromEntity: (
    currencyOptions: CurrencySelectOption[],
    resRow: JobStatisticLabelerRowResponseDTO
  ): LabelerReportByJobRow => {
    const row: LabelerReportByJobRow = {
      jobId: resRow.id,
      taskId: resRow.taskId,
      fileName: resRow.fileName,
      jobStatus: resRow.status,
      payment: resRow.payment,
      currencyCode:
        currencyOptions.find((op) => op.value === resRow.currencyId)?.label ||
        "",
    };
    return row;
  },
};

export const mapperLabelerReportByProjectRow = {
  fromEntity: (resRow: LabelerStatisticByBatch): LabelerReportByProjectRow => {
    const row: LabelerReportByProjectRow = {
      ...resRow,
      id: v4(),
      startDate: moment(new Date(resRow.startDate)).local().toDate(),
      endDate: moment(new Date(resRow.endDate)).local().toDate(),
    };
    return row;
  },
};

export const mapperLabelerReportByDateRow = {
  fromEntity: (resRow: LabelerReportRecordDTO): LabelerReportByDateRow => {
    const row: LabelerReportByDateRow = {
      ...resRow,
      id: v4(),
      date: moment(new Date(resRow.daily)).local().toDate(),
      startTime: moment(resRow.starting).format("HH:mm").toString(),
      endTime: moment(resRow.starting).format("HH:mm").toString(),
    };
    return row;
  },
};
