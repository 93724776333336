/*
 * File: token-observation.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 27th October 2021 2:34:30 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Issue } from "domain/common/issue";
import { Label, NERAnnotationData } from "domain/text-labeling";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useMemo, useState } from "react";
import { selectDraggingObservationId } from "store/labeler/text-workspace/text-editor/text-editor.selector";
import {
  setActiveTokenObservationId,
  setContextMenuTokenId,
} from "store/labeler/text-workspace/text-workspace.slice";
import { AnnotationData } from "../../models/text-viewer.models";

const SPACING = 5;

interface Props {
  active?: boolean;
  label: Label;
  issue?: Issue;
  tokenObservation: AnnotationData;
  reviewStatus?: string;
  conflict?: string;
  annotation?: NERAnnotationData;
  relationIds?: string[];
  lineIndex?: number;
  onSelect?(annotationId: string): void;
}
export const AnnotationComponent = ({
  tokenObservation,
  label,
  active,
  issue,
  annotation,
  relationIds,
  lineIndex = 0,
  onSelect,
}: Props) => {
  const { boundingBox, annotationId, lines } = tokenObservation;

  const color = useMemo(() => {
    if (annotation?.reviewStatus) return "#8e9aab";
    return label.color;
  }, [label, annotation]);
  const [allowSameLabel] = useState(false);
  const draggingObservationId = useAppSelector(selectDraggingObservationId);
  const dispatch = useAppDispatch();
  const getObservationId = (tokenObservationId: string) => {
    const items = tokenObservationId.split(":");
    if (items.length > 0) return items[0];
    return "";
  };

  const handleMouseEnter = () => {
    const observationId = getObservationId(draggingObservationId);
    if (allowSameLabel && tokenObservation.observationId === observationId) {
      return;
    }
    if (draggingObservationId && !label.allowConnection) return;

    dispatch(setActiveTokenObservationId(annotationId));
  };

  const handleMouseLeave = () => {
    if (active) dispatch(setActiveTokenObservationId(""));
  };

  const handleOnContextMenu = () => {
    dispatch(setContextMenuTokenId(annotationId));
  };

  function handleClick() {
    onSelect && onSelect(annotationId);
  }

  const x = boundingBox.x - SPACING;
  const y = boundingBox.y - boundingBox.height;
  return (
    <>
      {active && (
        <rect
          x={x}
          y={y}
          width={boundingBox.width + 2 * SPACING}
          height={boundingBox.height + 2 * SPACING}
          fill={color}
          fillOpacity={0.2}
        />
      )}
      {issue && (
        <polygon
          points={`${x},${y} ${x + 2 * SPACING},${y} ${x},${y + 2 * SPACING}`}
          fill="#FFFF00"
          fillOpacity={0.8}
        />
      )}
      {lines.map((line, index) => {
        return (
          <line
            key={index}
            x1={line.start.x}
            y1={line.start.y}
            x2={line.end.x}
            y2={line.end.y}
            style={{ stroke: color, strokeWidth: 1 }}
          />
        );
      })}
      <text
        id={annotationId}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onContextMenu={handleOnContextMenu}
        className="cursor-pointer token-observation"
        data-observation-id={annotationId}
        data-relation-ids={relationIds}
        data-line-index={lineIndex}
        x={boundingBox.x}
        y={boundingBox.y}
        textLength={boundingBox.width}
        fill={color}
        fontSize={12}
      >
        {label.name}
      </text>
    </>
  );
};
