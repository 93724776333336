import { useEffect, useState } from "react";
import { getDataCleanerTypes } from "services/storage/apis/data-cleaning";
import { RequestStatus } from "store/base/base.state";
import * as Sentry from "@sentry/react";


export const useDataCleanerTypes = () => {
  const [cleanerTypes, setCleanerTypes] = useState<string[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.IDLE);

  useEffect(() => {
    if (requestStatus !== RequestStatus.IDLE) return;

    const loadData = async () => {
      try {
        const res = await getDataCleanerTypes();
        setCleanerTypes(res.data);
        setRequestStatus(RequestStatus.SUCCESS);
      } catch (err: any) {
        Sentry.captureException(err);
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    loadData();
  }, [requestStatus]);

  return {
    cleanerTypes,
  }
}