import { ColumnHeaderWrapper } from "components/common/vb-grid/column-wrapper.component";
import { SearchableColumnHeader } from "components/common/vb-grid/searchable-column-header.component";
import { SelectableColumnHeader } from "components/common/vb-grid/selectable-column-header.component";
import { useAppSelector } from "hooks/use-redux";
import { JobStatus } from "services/label-service/dtos";
import { BatchPaymentFilter, BatchPaymentTableRow } from "../model/batch-payment.models";
import { selectBatchStepLabelerOptions } from "store/customer/batch/batch.selectors";
import { IconEye } from "components/common/vb-icon.component";


interface Props {
  rows: BatchPaymentTableRow[],
  filter: BatchPaymentFilter,
  setFilterField: (field: keyof BatchPaymentFilter, value: any) => void;
  onRowAction: (row: BatchPaymentTableRow, action: "view") => void;
  currencyCode?: string;
}

export const BatchPaymentTable = ({
  rows,
  filter,
  setFilterField,
  onRowAction,
  currencyCode = "",
}: Props) => {
  const statusOptions = [
    { label: "Completed", value: JobStatus.COMPLETED },
    { label: "Available", value: JobStatus.AVAILABLE },
    { label: "Working", value: JobStatus.WORKING },
  ];

  const labelersOptions = useAppSelector(
    selectBatchStepLabelerOptions(true, false, false)
  );

  return (
    <div className="min-w-full">
      <table className="min-w-full text-sm vb-table">
        <thead>
          <tr>
            <ColumnHeaderWrapper first>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={false}
                placeholder={""}
                header={"Job ID"}
                containerWidth={"100%"}
                defaultValue={filter.jobId || ""}
                type="text"
                onInputChange={(v) => setFilterField("jobId", v)}
                sortable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={false}
                placeholder={""}
                header={"Task ID"}
                containerWidth={"100%"}
                defaultValue={filter.taskId || ""}
                type="text"
                onInputChange={(v) => setFilterField("taskId", v)}
                sortable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={false}
                placeholder={""}
                header={"File name"}
                containerWidth={"100%"}
                defaultValue={filter.fileName || ""}
                type="text"
                onInputChange={(v) => setFilterField("fileName", v)}
                sortable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={false}
                placeholder={""}
                header={"Step"}
                containerWidth={"100%"}
                defaultValue={filter.step || ""}
                type="number"
                onInputChange={(v) => setFilterField("step", v)}
                sortable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={false}
                placeholder={""}
                header={"Round"}
                containerWidth={"100%"}
                defaultValue={filter.round || ""}
                type="number"
                onInputChange={(v) => setFilterField("round", v)}
                sortable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <SelectableColumnHeader
                className="leading-normal"
                placeholder="All"
                header="Status"
                containerWidth="100%"
                menuPortalTarget={document.body}
                defaultValue={statusOptions.find(
                  (option) => option.value === filter?.jobStatus
                )}
                options={statusOptions}
                onChange={(option: any) => {
                  if (option) {
                    setFilterField("jobStatus", option.value);
                  } else {
                    setFilterField("jobStatus", undefined);
                  }
                }}
                isClearable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <SelectableColumnHeader
                className="leading-normal"
                placeholder="All"
                header="Labeler"
                containerWidth="100%"
                menuPortalTarget={document.body}
                defaultValue={labelersOptions.find(
                  (option) => option.value === filter?.labeler
                )}
                options={labelersOptions}
                onChange={(option: any) => {
                  if (option) {
                    setFilterField("labeler", option.value);
                  } else {
                    setFilterField("labeler", undefined);
                  }
                }}
                isClearable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper last>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={false}
                placeholder={currencyCode}
                header={"Payment"}
                containerWidth={"100%"}
                defaultValue={filter.payment || ""}
                type="number"
                onInputChange={(v) => setFilterField("payment", v)}
              />
            </ColumnHeaderWrapper>
          </tr>
        </thead>
        <tbody className="relative">
          {rows.map((row) => {
            return (
              <tr
                key={row.jobId}
                className="relative h-10 hover:bg-secondary-50 parent"
              >
                <td className="pl-4 pr-4" style={{ minWidth: 100 }}>{row.jobId}</td>
                <td className="pr-4" style={{ minWidth: 100 }}>{row.taskId}</td>
                <td className="pr-4" style={{ minWidth: 200 }}>{row.fileName}</td>
                <td className="pr-4" style={{ minWidth: 100 }}>{row.step}</td>
                <td className="pr-4" style={{ minWidth: 100 }}>{row.round}</td>
                <td className="pr-4" style={{ minWidth: 200 }}>
                  {statusOptions.find(op => op.value === row.jobStatus)?.label}
                </td>
                <td className="pr-4" style={{ minWidth: 200 }}>{row.labeler}</td>
                <td className="pr-4" style={{ minWidth: 120 }}>{row.payment || "-"}</td>
                <td
                  className="absolute h-full transform -translate-x-full -translate-y-1/2 top-1/2 child-on-hover"
                  style={{ zIndex: "999" }}
                >
                  <div className="flex flex-row items-center h-full gap-3 px-3 text-primary bg-secondary-50">
                    <button>
                      <IconEye
                        onClick={(_) =>
                          onRowAction &&
                          onRowAction(row, "view")
                        }
                        className="w-4 h-4"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
          <tr className="absolute top-0 left-0 w-full h-full border rounded pointer-events-none border-background-300"></tr>
        </tbody>
      </table>
    </div>
  )
}