/*
 * File: image-poll-jobs.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 5th August 2022 9:12:39 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { BatchObservationService } from "services/label-service";
import {
  BatchObservationDTO,
  isMedicalMamoProject,
  ObservationDTO,
} from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import { BatchLabelingState, ImageLabelingJob } from "../batch-labeling.state";
import { Collection, collectionUtils } from "domain/common";
import { batchLabelingUtils } from "../batch-labeling.util";
import { imageLabelMapper } from "services/label-service/mappers/image-label.mapper";
import { projectMapper } from "services/label-service/mappers/project.mapper";
const THUNK_NAME = "batch-labeling/imagePollJobsAsync";

export const imagePollJobsAsync = createAsyncThunk(
  THUNK_NAME,
  async ({ projectId, jobId }: { projectId: string; jobId: string }) => {
    const jobs = await batchLabelingUtils.pollJobs(projectId, jobId);
    let project;
    let batch;
    if (projectId) {
      project = await batchLabelingUtils.getProjectById(parseInt(projectId));
    } else {
      batch = await batchLabelingUtils.getBatchById(jobs[0].batchId);
      project = projectMapper.fromDTO(batch.dto.project);
    }

    const imageLabelingJobs: Collection<ImageLabelingJob> = {
      allIds: [],
      entities: {},
    };
    if (jobs.length === 0) {
      return { imageLabelingJobs: imageLabelingJobs, labels: [] };
    }
    const data = await _fetchBatchObservations(jobs[0].batchId);
    const { allBatchObservations } = data;
    const labels = imageLabelMapper.toLabels(allBatchObservations);
    labels.sort((a, b) => {
      const aPriority = a.priority || 0;
      const bPriority = b.priority || 0;
      return aPriority - bPriority;
    });
    for (let job of jobs) {
      const imageLabelingJob = {
        id: job.id,
        job,
        labels,
      };
      collectionUtils.addOne(imageLabelingJobs, imageLabelingJob);
    }

    return { imageLabelingJobs, labels, project };
  }
);

const _fetchBatchObservations = async (batchId: number) => {
  const observationEntities: { [key: number]: ObservationDTO } = {};
  const batchObservations: BatchObservationDTO[] = [];
  const systemObservations: BatchObservationDTO[] = [];
  const payload = { batchId: batchId.toString(), size: "500" };
  const response = await BatchObservationService.getItems(payload);
  const allBatchObservations: BatchObservationDTO[] = response.data;
  for (const batchObservation of allBatchObservations) {
    const { observation } = batchObservation;
    observationEntities[observation.id] = observation;
    if (observation.observationSetting?.systemAttribute) {
      systemObservations.push(batchObservation);
    }
    batchObservations.push(batchObservation);
  }
  return {
    observationEntities,
    batchObservations,
    systemObservations,
    allBatchObservations,
  };
};

export const imagePollJobsReducerBuilder = (
  builder: ActionReducerMapBuilder<BatchLabelingState>
) => {
  return builder
    .addCase(imagePollJobsAsync.pending, (state) => {
      state.requestStatus = RequestStatus.LOADING;
    })
    .addCase(imagePollJobsAsync.fulfilled, (state, action) => {
      const { imageLabelingJobs, labels, project } = action.payload;
      state.requestStatus = RequestStatus.SUCCESS;
      if (imageLabelingJobs.allIds.length === 0) return;
      state.project = project;
      state.pollStrategy = project?.pollStrategy;
      state.imageLabelingJobs = imageLabelingJobs;
      state.currentJobId = imageLabelingJobs.allIds[0] as number;
      state.jobRequestStatuses = {};
      state.labels = collectionUtils.fromEntities(labels);
      for (let jobId of imageLabelingJobs.allIds) {
        state.jobRequestStatuses[jobId as number] = RequestStatus.NONE;
      }

      // For breasts mamo project
      // set default layout with 4 windows
      if (isMedicalMamoProject(project?.type, project?.pollStrategy)) {
        const { allIds } = imageLabelingJobs;
        const imageViews = allIds.slice(0, 4) as number[];
        state.imageViews = imageViews;
      } else {
        state.imageViews = [state.currentJobId];
      }
    })
    .addCase(imagePollJobsAsync.rejected, (state) => {
      state.requestStatus = RequestStatus.FAILURE;
    });
};
