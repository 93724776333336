import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { SubType } from "components/dicom/dicom-tools/dicom-tools.model";
import { AnnotationData } from "domain/image-labeling";
import { RootState } from "store";
import { v4 } from "uuid";
import { selectBatchLabelingJobIdsInBatch } from "../../batch-labeling/batch-labeling.selectors";
import { selectActiveAnnotationTool } from "../../dicom-editor/dicom-editor.selectors";
import { ImageWorkspaceState } from "../../image-workspace.state";
import { imageAnnotationUtils } from "../image-annotations.util";

const THUNK_NAME = "image-annotations/imageAnnotationCompletedAsync";

interface ImageAnotationPayload<T> {
  jobId: number;
  data: T;
  frameId?: number;
  labelId?: number;
}

export const imageAnnotationCompletedAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: ImageAnotationPayload<AnnotationData[]>, { getState }) => {
    const state = getState() as RootState;
    const { jobId, data: annotationData, frameId, labelId } = payload;
    const annotation = imageAnnotationUtils.createNewAnnotation(
      jobId,
      annotationData,
      state,
      frameId,
      labelId
    );

    if (!annotation) return [];
    const annotations = [annotation];
    const activeTool = selectActiveAnnotationTool(state);
    const isTracking = SubType.TRACK === activeTool?.subType;
    if (isTracking) {
      const trackingId = v4();
      annotation.objectTrackingId = trackingId;
      const jobIds = selectBatchLabelingJobIdsInBatch(state);
      const index = jobIds.indexOf(jobId);
      if (index !== -1) {
        for (let i = index + 1; i < jobIds.length; i++) {
          const nextJobId = jobIds[i];
          const data = annotationData.map((data) => ({ ...data, uuid: v4() }));
          const trackingAnnotation = imageAnnotationUtils.createNewAnnotation(
            nextJobId,
            data,
            state
          );
          if (!trackingAnnotation) continue;
          trackingAnnotation.objectTrackingId = trackingId;
          annotations.push(trackingAnnotation);
        }
      }
    }

    return annotations;
  }
);

export const imageAnnotationCompletedBuilder = (
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) => {
  return builder.addCase(
    imageAnnotationCompletedAsync.fulfilled,
    ({ imageAnnotations }, action) => {
      if (!action || !action.payload || action.payload.length === 0) return;

      for (const annotation of action.payload) {
        const { uuid } = annotation;
        if (!imageAnnotationUtils.isValidPolygon(annotation)) {
          imageAnnotations.invalidAnnotation = annotation;
          continue;
        }
        const existedAnnotation = imageAnnotations.annotations.find(
          (anno) => anno.uuid === uuid
        );
        if (existedAnnotation) continue;
        imageAnnotations.annotations.push(annotation);
      }
      imageAnnotations.selectedAnnotation = {
        annotationUUID: action.payload[0].uuid,
        attributeMenu: true,
        classMenu: false,
        contextMenu: false,
      };
    }
  );
};
