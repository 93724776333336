/*
 * File: delete-files.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th June 2023 11:41:54 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { ChangeEvent, useState } from "react";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { SelectableInput } from "components/common/vb-input/selectable-input.component";
import useMergeDatasourceOptions from "pages/customer/datasets/hooks/use-merge-datasource-options";
import { StorageApi } from "data-access/impl/storage";
import { useAppDispatch } from "hooks/use-redux";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";

interface Props {
  open: boolean;
  datasetIds: number[];
  onClose: (reload?: boolean) => void;
}

export const MergeDatasourcesModal = ({ open, datasetIds, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { options, option, onChange } = useMergeDatasourceOptions("merge");
  const [processing, setProcessing] = useState(false);
  const [name, setName] = useState("");

  function handleNameChange(event: ChangeEvent<any>) {
    setName(event.target.value);
  }

  async function handleSubmit() {
    if (processing) return;
    try {
      setProcessing(true);
      await StorageApi.mergeDatasets({
        datasetIds,
        name,
        options: {
          deleteSource: option?.value === "merge_and_delete",
        },
      });
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      setProcessing(false);
      onClose(true);
    } catch (error) {
      dispatch(enqueueErrorNotification(t("common:textFailed")));
      setProcessing(false);
    }
  }
  return (
    <VBModal
      open={open}
      title="Merge datasources"
      onClose={() => onClose(false)}
      textSubmit={t("common:buttonSubmit")}
      onSubmit={handleSubmit}
      disableSubmit={processing}
    >
      <div className="flex flex-col gap-3">
        <p>Merge datasource into a new datasource</p>
        <div className="flex flex-col gap-2">
          <div>{t("dataset:createDialog.textDatasourceName")}</div>
          <input
            autoFocus
            type="text"
            className="h-12 px-4 bg-white border rounded border-background-300 focus:border-primary"
            required
            onChange={handleNameChange}
            placeholder={t("dataset:createDialog.placeholder")}
          />
        </div>
        <SelectableInput
          required
          value={option}
          onChange={onChange}
          header="Merge option"
          options={options}
          menuPortalTarget={document.body}
        />
      </div>
    </VBModal>
  );
};
