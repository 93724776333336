/*
 * File: use-sidebar-tabs.hook.ts
 * Project: aiscaler-web-monorepo
 * File Created: Wednesday, 16th November 2022 2:58:13 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useState } from "react";

export default function useSidebarTabs() {
  const tabs = [
    { id: "CLASSES", name: "Classes" },
    { id: "OBJECTS", name: "Objects" },
    // { id: "ISSUES", name: "Issues" },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  return { tabs, activeTab, selectTab: setActiveTab };
}
