/*
 * File: use-edit-text-selection.hook.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 9th September 2022 4:19:53 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useAppDispatch } from "hooks/use-redux";
import { useLayoutEffect, useRef, useState } from "react";
import {
  setSelectedTokenIds,
  textAnnotationEdited,
} from "store/labeler/text-workspace/text-workspace.slice";
import { Rectangle } from "utilities/math/rectangle";
import { TextViewerController } from "./text-viewer-handle";

export const useEditTextSelection = (
  controller: TextViewerController,
  element: HTMLElement | null
) => {
  const dispatch = useAppDispatch();
  const [selectedTokens, setSelectedTokens] = useState<{
    tokenIds: string[];
    bboxes: Rectangle[];
  } | null>(null);
  const dragState = useRef({
    root: { x: 0, y: 0 },
    position: { x: 0, y: 0 },
    anchorId: "",
    drag: false,
    direction: "",
  });
  useLayoutEffect(() => {
    const handleMouseDown = (event: any) => {
      if (!dragState.current || !element) return;
      const target = event.target as Element;
      var dragElement = target.parentElement;
      var isDrag = false;
      if (target?.id?.endsWith("-cursor")) {
        element.style.userSelect = "none";
        dragState.current.anchorId = target.getAttribute("data-to") || "";
        isDrag = true;
        const bbox = element.getBoundingClientRect();
        dragState.current.root = { x: bbox.x, y: bbox.y };
        dragState.current.position.x = event.clientX - dragState.current.root.x;
        dragState.current.position.y = event.clientY - dragState.current.root.y;
        dragState.current.direction = target?.id?.startsWith("left")
          ? "left"
          : "right";
        const offsetY = dragState.current.direction === "left" ? 6 : -30;

        element.addEventListener("mousemove", (evt) => {
          if (!isDrag) return;
          document.body.style.cursor = "pointer";
          dragState.current.position.x = evt.clientX - dragState.current.root.x;
          dragState.current.position.y = evt.clientY - dragState.current.root.y;
          dragElement?.setAttribute(
            "transform",
            `translate(${dragState.current.position.x},${
              dragState.current.position.y + offsetY
            })`
          );
          const tokenData = controller.getSelectedTokenIds(dragState.current);
          if (tokenData) setSelectedTokens(tokenData);
        });

        element.addEventListener("mouseup", () => {
          document.body.style.cursor = "";
          element.style.userSelect = "";
          isDrag = false;
          const tokenData = controller.getSelectedTokenIds(dragState.current);
          if (tokenData) {
            dispatch(setSelectedTokenIds(tokenData.tokenIds));
            dispatch(textAnnotationEdited());
          }
          dragState.current = {
            root: { x: 0, y: 0 },
            position: { x: 0, y: 0 },
            anchorId: "",
            drag: false,
            direction: "",
          };
          setSelectedTokens(null);
        });
      }
    };

    element?.addEventListener("mousedown", handleMouseDown);
    return () => {
      element?.removeEventListener("mousedown", handleMouseDown);
    };
  }, [dispatch, element, controller]);

  return { selectedTokens };
};
