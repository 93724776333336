/*
 * File: file-browse.page.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 4:56:39 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import FileGrid from "./components/file-grid/file-grid";
import FileNav from "./components/file-nav/file-nav";
import FilePageHeader from "./components/file-page-header/file-page-header";
import { useFileBrowseContext } from "./context/file-browse.context";
import { QueryApi } from "data-access/impl/query";
import { EntityAction } from "domain/common/entity-action";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";
import { FileThumbnail } from "./components/file-thumbnail/file-thumbnail";
import { useEffect, useMemo } from "react";
import { FilePreview } from "./components/file-preview/file-preview";
import { IconButton } from "@material-ui/core";
import { VscArrowLeft, VscArrowRight, VscClose } from "react-icons/vsc";
import { useKeyPress } from "ahooks";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import FileInfoDetail from "./components/file-info-detail/file-info-detail";
import { ViewModeEnum } from "domain/common";

export function FileBrowsePage() {
  const {
    files,
    fileId,
    selectFile,
    selectedFileIds,
    filter,
    updateFilter,
    loading,
    pagination,
    viewMode,
    reloadFiles,
    openFile,
  } = useFileBrowseContext();

  const { openModal, closeModal } = useModalContext();

  const file = useMemo(() => {
    return files.find((item) => item.id === fileId);
  }, [files, fileId]);

  function handleSelectFile(fileId: number, action: string) {
    if (action === EntityAction.SELECT) {
      if (selectedFileIds.includes(fileId)) {
        selectFile(selectedFileIds.filter((id) => id !== fileId));
      } else {
        selectFile([...selectedFileIds, fileId]);
      }
    } else if (action === EntityAction.VIEW) {
      openFile(fileId);
    } else if (action === EntityAction.DELETE) {
      openModal(ModalTypes.DELETE_FILES, {
        open: true,
        onClose: (reload?: boolean) => {
          closeModal();
          if (reload) {
            reloadFiles();
            selectFile(selectedFileIds.filter((id) => id !== fileId));
          }
        },
        fileIds: [fileId],
      });
    }
  }

  function handleSelectFilesAction(action: string) {
    if (action === EntityAction.DELETE) {
      openModal(ModalTypes.DELETE_FILES, {
        open: true,
        onClose: (reload?: boolean) => {
          closeModal();
          if (reload) {
            reloadFiles();
            selectFile([]);
          }
        },
        fileIds: selectedFileIds,
      });
    } else if (action === "manage_metadata") {
      openModal(ModalTypes.MANAGE_FILE_METADATA, {
        open: true,
        files: files.filter((file) => selectedFileIds.includes(file.id)),
        onClose: (reload?: boolean) => {
          closeModal();
          if (reload) {
            reloadFiles();
            selectFile([]);
          }
        },
      });
    } else if (action === "clear_selection") {
      selectFile([]);
    }
  }

  function openNextFile() {
    if (files.length === 0 || fileId < 0) return;
    const currentIdx = files.findIndex((file) => file.id === fileId);
    const nextIdx = (currentIdx + 1) % files.length;
    openFile(files[nextIdx].id);
  }

  function openPreviousFile() {
    if (files.length === 0 || fileId < 0) return;
    const currentIdx = files.findIndex((file) => file.id === fileId);
    const nextIdx = (currentIdx - 1 + files.length) % files.length;
    openFile(files[nextIdx].id);
  }

  function closeFile() {
    openFile(-1);
  }

  useKeyPress(KeyboardKey.ArrowLeft, openPreviousFile);
  useKeyPress(KeyboardKey.ArrowRight, openNextFile);
  useKeyPress(KeyboardKey.Escape, closeFile);

  useEffect(() => {
    const elementId = `file-thumbnail-${fileId}`;
    const element = document.getElementById(elementId);
    element?.scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "center",
    });
  }, [fileId]);
  return (
    <div className="flex h-full gap-4 px-10 py-4 overflow-y-hidden">
      <FileNav />
      {!file && (
        <div className="flex flex-col flex-auto h-full overflow-hidden">
          <div className="flex-auto h-full overflow-hidden">
            {!loading && (
              <FilePageHeader
                totalFile={pagination.totalCount}
                totalSelected={selectedFileIds.length}
                onSelect={handleSelectFilesAction}
              />
            )}
            {loading && <div className="p-20 text-center">Loading...</div>}
            <div className="flex-auto h-full pb-6 mt-4 overflow-y-auto">
              {viewMode === ViewModeEnum.GRID && (
                <FileGrid
                  files={files}
                  selectedFileIds={selectedFileIds}
                  onSelect={handleSelectFile}
                />
              )}
            </div>
          </div>
          <div className="flex-none py-1">
            <GridPagination
              page={pagination.page + 1}
              pageSize={pagination.size}
              pageSizeOptions={[10, 20, 25, 50, 75, 100]}
              totalItems={pagination.totalCount}
              onPageSizeChange={(pageSize) => {
                const fileFilter = new QueryApi.FileFilter(filter);
                fileFilter.setPageSize(pageSize);
                updateFilter(fileFilter);
              }}
              onPageChange={(page) => {
                const fileFilter = new QueryApi.FileFilter(filter);
                fileFilter.setPage(page - 1);
                updateFilter(fileFilter);
              }}
            />
          </div>
        </div>
      )}

      {file && (
        <div className="flex flex-auto h-full overflow-hidden border">
          <div className="flex flex-col flex-auto h-full overflow-hidden">
            <div className="relative flex-auto overflow-hidden group">
              <FilePreview key={file.id} file={file} />
              <div className="absolute z-20 hidden p-1 transform -translate-y-1/2 bg-black rounded-full bg-opacity-40 left-4 top-1/2 hover:bg-opacity-60 group-hover:block">
                <IconButton size="small" onClick={openPreviousFile}>
                  <VscArrowLeft size={20} color="#fff" />
                </IconButton>
              </div>

              <div className="absolute z-20 hidden p-1 transform -translate-y-1/2 bg-black rounded-full bg-opacity-40 right-4 top-1/2 hover:bg-opacity-60 group-hover:block">
                <IconButton size="small" onClick={openNextFile}>
                  <VscArrowRight size={20} color="#fff" />
                </IconButton>
              </div>
              <div className="absolute z-20 hidden p-1 bg-black rounded-full bg-opacity-40 right-4 top-4 hover:bg-opacity-60 group-hover:block">
                <IconButton size="small" onClick={closeFile}>
                  <VscClose size={20} color="#fff" />
                </IconButton>
              </div>
            </div>
            <div className="flex items-center flex-none w-full h-24 gap-4 px-4 overflow-x-auto bg-white border-t flex-nowrap">
              {files.map((item) => {
                return (
                  <div key={item.id} className="flex-none w-16 h-16">
                    <FileThumbnail
                      key={item.id}
                      file={item}
                      selected={item.id === fileId}
                      onSelect={openFile}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <FileInfoDetail file={file} onClose={closeFile} />
        </div>
      )}
    </div>
  );
}

export default FileBrowsePage;
