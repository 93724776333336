import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { useTranslation } from "react-i18next";
import { BatchDTO } from "services/label-service/dtos";

export interface BatchDataCleaningDeleteModalProps {
  batch: BatchDTO | null | undefined;
  open: boolean;
  onClose?: () => void;
}

export const BatchDataCleaningDeleteModal = ({
  batch,
  open,
  onClose,
}: BatchDataCleaningDeleteModalProps) => {
  const { t } = useTranslation();

  if (!batch) return null;

  const handleClose = () => {
    onClose && onClose();
  }

  const handleSubmit = () => {
    onClose && onClose();
  }

  return (
    <VBModal
      title={t("project:batchDetails.batchDataCleaning.modalDeleteTitle")}
      open={open}
      onClose={handleClose}
      textSubmit={t("common:buttonDelete")}
      onSubmit={handleSubmit}
      disableSubmit={false}
    >
      <div>
        {t("project:batchDetails.batchDataCleaning.deleteMessage")}
      </div>
    </VBModal>
  )
}