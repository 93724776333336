/*
 * File: three-d-task-issues.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 10th April 2023 5:38:50 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { IconButton, Tooltip } from "@material-ui/core";
import { CreateTaskIssueInput, useTaskIssues } from "hooks/issues";
import { Add, ArrowDown2, ArrowRight2 } from "iconsax-react";
import { useMemo, useState } from "react";
import { CreateIssueModal } from "./create-issue";
import { useThreeDLabelingContext } from "../../context/three-d-labeling.context";
import { SlicingMode } from "../../vtk_import";
import { useAppDispatch } from "hooks/use-redux";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { IssueList } from "./issue-list.component";

interface Props {
  taskId: number;
}

export function ThreeDTaskIssues({ taskId }: Props) {
  const dispatch = useAppDispatch();
  const { uiJobRef } = useThreeDLabelingContext();
  const { data, isLoading, createNewIssue, resolveIssue, selectIssue } =
    useTaskIssues(taskId);
  const [adding, setAdding] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const assigneeOptions = useMemo(() => {
    return (
      uiJobRef.current?.jobOptions
        .filter((option) => !!option.jobId)
        .map((item) => {
          return {
            label: item.assignee,
            value: item.assignee,
          };
        }) ?? []
    );
  }, [uiJobRef]);

  const [axisOptions] = useState([
    { label: "All axises", value: SlicingMode.NONE.toString() },
    {
      value: SlicingMode.I.toString(),
      label: "Sagittal (LR)",
    },
    {
      value: SlicingMode.J.toString(),
      label: "Coronal (AP)",
    },
    {
      value: SlicingMode.K.toString(),
      label: "Axial (SI)",
    },
  ]);

  const jobOptions = useMemo(() => {
    if (!uiJobRef.current) return [];
    const { jobOptions, job } = uiJobRef.current;

    const options = jobOptions
      .filter((option) => !!option.jobId)
      .map((item) => ({ label: item.assignee, value: item.jobId }));

    if (job && !options.find((option) => job && option.value === job.id)) {
      options.push({ label: job.assignee, value: job.id });
    }

    return options.map((item) => ({
      label: item.label,
      value: item.value.toString(),
    }));
  }, [uiJobRef]);
  async function handleSubmit(data: {
    annotator: string;
    comment: string;
    slice: number;
    axis: number;
    labelId?: number;
  }) {
    try {
      const jobOption = uiJobRef.current?.jobOptions.find(
        (option) => option.assignee === data.annotator
      );
      const jobId = jobOption?.jobId ?? -1;
      if (jobId === -1) return;
      const payload: CreateTaskIssueInput = {
        taskId: taskId,
        jobId: jobId,
        slice: data.slice,
        axis: data.axis,
        comment: data.comment,
      };
      await createNewIssue(payload);
      setAdding(false);
      dispatch(enqueueSuccessNotification("Success"));
    } catch (error) {
      dispatch(enqueueErrorNotification("Failed"));
    }
  }

  return (
    <section>
      <div className="flex items-center">
        <Tooltip title="Toggle expand">
          <IconButton onClick={() => setExpanded((pre) => !pre)}>
            {!expanded && <ArrowRight2 size={16} color="#fff" />}
            {expanded && <ArrowDown2 size={16} color="#fff" />}
          </IconButton>
        </Tooltip>
        <h2 className="flex-auto font-semibold truncate">
          Task Issues {data && data?.length > 0 ? `(${data.length})` : ""}
        </h2>
        <Tooltip title="Add issue">
          <IconButton onClick={() => setAdding(true)}>
            <Add size={20} color="#fff" />
          </IconButton>
        </Tooltip>
      </div>

      {expanded && !isLoading && data && (
        <IssueList
          onSelectItem={selectIssue}
          onClickResolve={resolveIssue}
          data={data ?? []}
          jobOptions={jobOptions}
          mode={TextLabelingMode.CUSTOMER}
        />
      )}

      {adding && (
        <CreateIssueModal
          assigneeOptions={assigneeOptions}
          axisOptions={axisOptions}
          onClose={() => setAdding(false)}
          onSubmit={handleSubmit}
        />
      )}
      {/* {adding && (
        <div className="p-4 border rounded">
          <VBTextInputComponent
            className="text-black"
            hideInputIcon
            header="Slice"
            type="number"
          />
          <VBTextAreaComponent className="text-black" header="Description" />
        </div>
      )} */}
    </section>
  );
}
