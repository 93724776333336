/*
 * File: vb-resizer.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 30th August 2021 5:03:15 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useRef, MouseEvent, HTMLProps } from "react";

interface Props extends HTMLProps<HTMLDivElement> {
  direction?: "left" | "right";
  onResize?(width: number): void;
}
export const VBResizer = ({
  direction = "left",
  className = "flex-shrink-0 w-1 h-full hover:bg-primary",
  onResize,
  ...rest
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  function handleMouseDown(event: MouseEvent) {
    if (!containerRef.current) return;
    let container = containerRef.current;
    const isResizeLeft = direction === "left";
    let resizeElement = (
      isResizeLeft
        ? container.previousElementSibling
        : container.nextElementSibling
    ) as HTMLElement;

    if (!resizeElement) return;
    let x = event.clientX;
    document.body.style.setProperty("cursor", "col-resize");
    document.body.style.setProperty("user-select", "none");
    const mouseMoveHandler = (event: any) => {
      const dx = event.clientX - x;
      const elementWidth = resizeElement.getBoundingClientRect().width;
      const newWidth = elementWidth + (isResizeLeft ? dx : -dx);
      resizeElement.style.width = `${newWidth}px`;
      x = event.clientX;
      onResize && onResize(newWidth);
    };

    const mouseUpHandler = function () {
      if (!container) return;
      document.body.style.removeProperty("cursor");
      document.body.style.removeProperty("user-select");
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
    };

    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
  }
  return (
    <div
      className={className}
      style={{ cursor: "col-resize" }}
      ref={containerRef}
      {...rest}
      onMouseDown={handleMouseDown}
    ></div>
  );
};
