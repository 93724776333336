import { Tooltip } from "@material-ui/core";
import {
  IconEye,
  IconEyeSlash,
  IconVoteDownActive,
  IconVoteUpActive,
} from "components/common/vb-icon.component";
import { Annotation } from "domain/image-labeling";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect, useState } from "react";
import {
  selectedTaskReviewActiveAnnotations,
  selectedTaskReviewLabelById,
} from "store/customer/tasks-review/tasks-review.selectors";
import { updateActiveAnnotation } from "store/customer/tasks-review/tasks-review.slice";
import { ActiveAnnotation } from "store/customer/tasks-review/tasks-review.state";
import { truncateEmail } from "utilities/string/truncate-email";

interface TaskReviewActionPanelAnnotationProps {
  annotation: Annotation;
}

export const TaskReviewActionPanelAnnotation = ({
  annotation,
}: TaskReviewActionPanelAnnotationProps) => {
  const dispatch = useAppDispatch();
  const activeAnnotations = useAppSelector(selectedTaskReviewActiveAnnotations);
  const label = useAppSelector(selectedTaskReviewLabelById(annotation.labelId));

  const [isHovering, setIsHovering] = useState(false);
  const [isVisibleAnnotation, setIsVisibleAnnotation] = useState<boolean>();
  const [isVisibleAllIcons, setIsVisibleAllIcons] = useState<boolean>();

  useEffect(() => {
    const currentActiveAnnotation = activeAnnotations.find(
      (activeAnnotation) => activeAnnotation.annotationId === annotation.id
    );
    if (!currentActiveAnnotation) return setIsVisibleAnnotation(false);
    setIsVisibleAnnotation(currentActiveAnnotation.selected || false);
  }, [activeAnnotations, annotation.id]);

  useEffect(() => {
    if (isHovering) {
      setIsVisibleAllIcons(true);
    } else {
      if (annotation.vote !== 0 || !isVisibleAnnotation) {
        setIsVisibleAllIcons(true);
      } else {
        setIsVisibleAllIcons(false);
      }
    }
  }, [annotation.vote, isHovering, isVisibleAnnotation]);

  const handleChangeAnnotation = useCallback(
    ({ selected, hovering }: { selected?: boolean; hovering?: boolean }) => {
      const activeAnnotation: ActiveAnnotation = {
        annotationId: annotation.id,
        ...(selected !== undefined && { selected }),
        ...(hovering !== undefined && { hovering }),
      };
      dispatch(updateActiveAnnotation(activeAnnotation));
    },
    [annotation.id, dispatch]
  );

  const handleDisplayAnnotation = () => {
    setIsVisibleAnnotation(!isVisibleAnnotation);
    handleChangeAnnotation({ selected: !isVisibleAnnotation, hovering: false });
  };

  const handleHoveringAnnotation = useCallback(
    (isHovering: boolean) => {
      setIsHovering(isHovering);
      handleChangeAnnotation({ hovering: isHovering });
    },
    [handleChangeAnnotation]
  );

  return (
    <div>
      <div
        className="flex items-center justify-between"
        onMouseEnter={() => handleHoveringAnnotation(true)}
        onMouseLeave={() => handleHoveringAnnotation(false)}
      >
        <div className="space-y-1">
          <p className="text-base font-medium">Id: {annotation.id}</p>
          <p className="flex justify-between w-full gap-1 text-xs font-normal">
            <Tooltip title={truncateEmail(annotation.annotator)}>
              <span className="overflow-hidden whitespace-nowrap overflow-ellipsis">
                Labeler: {truncateEmail(annotation.annotator)}
              </span>
            </Tooltip>
          </p>
        </div>
        {isVisibleAllIcons && (
          <div className="flex items-center justify-end gap-4 text-xs">
            <div className="flex items-center justify-start gap-2">
              {annotation.vote && annotation.vote > 0 ? (
                <IconVoteUpActive style={{ cursor: "not-allowed" }} />
              ) : null}
              {annotation.vote && annotation.vote < 0 ? (
                <IconVoteDownActive style={{ cursor: "not-allowed" }} />
              ) : null}
            </div>

            {isVisibleAnnotation ? (
              <IconEye
                width={20}
                height={20}
                stroke="#000"
                onClick={handleDisplayAnnotation}
              />
            ) : (
              <IconEyeSlash
                width={20}
                height={20}
                stroke="#000"
                onClick={handleDisplayAnnotation}
              />
            )}
          </div>
        )}
      </div>
      {label?.attributes && label.attributes.length > 0 && (
        <div className="w-full py-1 space-y-1 overflow-hidden text-sm">
          {label.attributes.map((attr) => {
            const val = annotation.annotationData[0].attributes?.find(
              (item) => item.id === attr.id
            )?.value;
            if (!val) return null;
            return (
              <div key={attr.id} className="w-full text-gray-500">
                <div className="text-sm">{attr.name}</div>
                <div className="w-full text-xs whitespace-normal">
                  {val.join(", ")}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
