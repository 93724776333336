import classnames from "classnames";
import Switch, { SwitchProps } from "@material-ui/core/Switch";
import { forwardRef } from "react";

interface VBSwitchProps extends Omit<SwitchProps, "onChange"> {
  onChange?: (checked: boolean) => void;
}

export const VBSwitch = forwardRef<HTMLInputElement, VBSwitchProps>((props, ref) => {
  const {
    className,
    color = "primary",
    size = "medium",
    onChange,
    ...switchProps
  } = props;

  const baseClassName = classnames("vb-switch", className);
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.checked);
    }
  }

  return (
    <Switch
      {...switchProps}
      inputRef={ref}
      color={color}
      size={size}
      onChange={handleChange}
      className={baseClassName}
    />
  );
})
