/*
 * File: job.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 11th July 2022 9:59:36 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Job, JobType } from "domain/labeling/job";
import { JobDTO, StepType } from "../dtos";

function fromDTO(dto: JobDTO): Job {
  let type = JobType.LABELER;
  const step = dto.workInstruction.step;
  const stepType =
    (dto.workInstruction?.stepType as StepType) || StepType.NORMAL;
  const canViewPreviousJob = dto.workInstruction.canViewPreviousStepResult;
  if (stepType === StepType.ACCEPTANCE) {
    type = JobType.REVIEWER;
  } else if (step > 1 && canViewPreviousJob) {
    type = JobType.SUPERVISOR;
  }

  return {
    id: dto.id,
    batchId: dto.batchId,
    taskId: dto.task?.id ?? -1,
    assignee: dto.assignee,
    status: dto.status,
    step: step,
    workspaceId: dto.workspaceId,
    type: type,
    stepType: stepType,
    canViewPreviousJob: canViewPreviousJob,
    dto,
  };
}

function toDTO(entity: Job): JobDTO {
  throw new Error("Method not implemented");
}

export const jobMapper = {
  fromDTO,
  toDTO,
};
