/*
 * File: exported-datasets-v2.table copy.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 30th November 2022 2:19:59 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */
import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import { MouseEvent, useState } from "react";
import { MlModelsExportedDatasetVersionUIModel } from "./exported-datasets.models";
import classNames from "classnames";
import { IconEye } from "components/common/vb-icon.component";

interface Props {
  rows: MlModelsExportedDatasetVersionUIModel[];
  isLoading?: boolean;
  onRowAction?: (
    row: MlModelsExportedDatasetVersionUIModel,
    action: "delete" | "view"
  ) => void;
}
export const ExportedDatasetsV3Table = ({
  rows,
  isLoading = false,
  onRowAction,
}: Props) => {
  const columns: GridColDef[] = [
    {
      field: "datasetId",
      filterable: false,
      renderHeader: () => <GridColumnHeader header="ID" />,
      renderCell: (params: GridCellParams) => {
        return (
          <GridRowLink
            onClick={() =>
              onRowAction &&
              onRowAction(
                params.row as MlModelsExportedDatasetVersionUIModel,
                "view"
              )
            }
            params={params}
          />
        );
      },
      minWidth: 60,
    },
    {
      field: "datasetName",
      filterable: false,
      flex: 1,
      renderHeader: () => <GridColumnHeader header="Name" />,
      renderCell: (params: GridCellParams) => {
        return <GridRowItem params={params} />;
      },
    },
    {
      field: "datasetDescription",
      filterable: false,
      flex: 2,
      renderHeader: () => <GridColumnHeader header="Description" />,
      renderCell: (params: GridCellParams) => {
        return <GridRowItem params={params} />;
      },
    },
    {
      field: "versionId",
      filterable: false,
      flex: 1,
      renderHeader: () => <GridColumnHeader header="Version ID" />,
      renderCell: (params: GridCellParams) => {
        return <GridRowItem params={params} />;
      },
    },
    {
      field: "versionStatus",
      filterable: false,
      flex: 1,
      renderHeader: () => <GridColumnHeader header="status" />,
      renderCell: (params: GridCellParams) => {
        return <GridRowStatus params={params} />;
      },
    },
    {
      field: "versionDate",
      filterable: false,
      flex: 1,
      renderHeader: () => <GridColumnHeader header="Created Date" />,
      renderCell: (params: GridCellParams) => {
        if (!params.value) return "";
        const value = params.value.toString();
        // const value = formatDateTime(params.value as Date);
        return (
          <div className="w-full px-2 truncate" title={value}>
            {value}
          </div>
        );
      },
    },

    {
      field: "id",
      filterable: false,
      minWidth: 120,
      renderHeader: () => <GridColumnHeader header="Actions" />,
      renderCell: (params: GridCellParams) => {
        function handleClick(event: MouseEvent) {
          event.preventDefault();
          event.stopPropagation();
          onRowAction &&
            onRowAction(
              params.row as MlModelsExportedDatasetVersionUIModel,
              "view"
            );
        }
        return (
          <div className="flex items-center w-full gap-2 px-2 truncate">
            <button onClick={handleClick}>
              <IconEye className="flex-none w-4 h-4" />
            </button>
          </div>
        );
      },
    },
  ];
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  return (
    <div className="w-full h-full">
      <DataGrid
        className="bg-white"
        loading={isLoading}
        rows={rows.map((row) => {
          return { ...row, id: row.versionId };
        })}
        columns={columns}
        selectionModel={selectedIds}
        checkboxSelection
        onSelectionModelChange={(model) => setSelectedIds(model as number[])}
        headerHeight={60}
      />
    </div>
  );
};

interface GridColumnHeaderProps {
  header: string;
  className?: string;
}

function GridColumnHeader({
  header,
  className = "py-2 px-0.5 font-semibold capitalize",
}: GridColumnHeaderProps) {
  return <div className={className}>{header}</div>;
}

function GridRowLink({
  params,
  onClick,
}: {
  onClick(): void;
  params: GridCellParams;
}) {
  return (
    <div
      className="px-2 truncate cursor-pointer text-primary"
      title={params.value?.toString()}
      onClick={onClick}
    >
      {params.value}
    </div>
  );
}
function GridRowItem({ params }: { params: GridCellParams }) {
  return (
    <div className="px-2 truncate" title={params.value?.toString()}>
      {params.value}
    </div>
  );
}

function GridRowStatus({ params }: { params: GridCellParams }) {
  const statusClassNames = {
    initial: "bg-background-100 text-background-500",
    importing: "bg-primary-100 text-primary-500",
    running: "bg-primary-100 text-primary-500",
    exporting: "bg-primary-100 text-primary-500",
    success: "bg-green-100 text-green-500",
    successed: "bg-green-100 text-green-500",
    exported: "bg-green-100 text-green-500",
    failed: "bg-red-100 text-red-500",
    fail: "bg-red-100 text-red-500",
    error: "bg-red-100 text-red-500",
  };
  const value = params.value?.toString() || "";
  const key = value.toLowerCase();
  const statusClass = statusClassNames.hasOwnProperty(key)
    ? (statusClassNames as any)[key]
    : "";
  return (
    <div className="px-2 truncate">
      <span
        className={classNames(
          "rounded-sm text-caption-small px-1.5 py-0.5 uppercase",
          statusClass
        )}
      >
        {params.value}
      </span>
    </div>
  );
}
