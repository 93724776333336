/*
 * File: assign-job-using-csv-message.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 2nd February 2023 9:38:14 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useMount } from "ahooks";
import {
  IconInformationCircle,
  IconCheckedCircle,
  IconError,
  IconClose,
} from "components/common/vb-icon.component";
import { TaskRunner, TaskRunnerStatus } from "domain/customer/task-runner";
import { useAppDispatch } from "hooks/use-redux";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import { BatchService } from "services/label-service";
import { BatchDTO } from "services/label-service/dtos";
import { loadBatchDataAsync } from "store/customer/batch/batch.thunk";
import { taskRunnerRemoved } from "store/customer/task-runner/task-runner.slice";
import { classnames } from "utilities/classes";
import { TaskProgresss } from "../task-progress/task-progress";

interface AssignJobUsingCSVMessageProps {
  index?: number;
  task: TaskRunner;
  onClose(): void;
}
export const AssignJobUsingCSVMessage = ({
  index = 0,
  task,
  onClose,
}: AssignJobUsingCSVMessageProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [mounted, setMounted] = useState(false);
  const [batch, setBatch] = useState<BatchDTO | null>(null);
  useMount(() => setTimeout(() => setMounted(true), 500));

  const isCompleted = task.status === TaskRunnerStatus.SUCCEEDED;
  const isFailed = task.status === TaskRunnerStatus.FAILED;
  const isRunning = task.status === TaskRunnerStatus.RUNNING;
  const isInitialized = task.status === TaskRunnerStatus.INITIAL;
  const isDone =
    task.status === TaskRunnerStatus.SUCCEEDED ||
    task.status === TaskRunnerStatus.FAILED;

  function handleViewDetail() {
    if (batch) {
      const url = `/projects/${batch.project.id}/batches/${batch.id}`;
      history.push(url);
    }
    onClose();
  }

  function handleDismiss() {
    dispatch(taskRunnerRemoved(task));
  }

  useMount(async () => {
    const item = JSON.parse(task.payload);
    if (item && item.hasOwnProperty("batchId")) {
      const batchId: number = item["batchId"];
      const response = await BatchService.getItem(batchId);
      if (response && response.data && response.data.batch) {
        setBatch(response.data.batch);
      }
    }
  });

  useEffect(() => {
    if (task.status !== TaskRunnerStatus.SUCCEEDED) return;
    if (!batch) return;
    setTimeout(() => {
      if (batch) dispatch(loadBatchDataAsync(batch.id.toString()));
    }, 1000);
  }, [task, batch, dispatch]);

  const title = {
    [TaskRunnerStatus.INITIAL]: "Assign Jobs",
    [TaskRunnerStatus.RUNNING]: "Start assign jobs using csv",
    [TaskRunnerStatus.SUCCEEDED]: "Assign jobs successfully",
    [TaskRunnerStatus.FAILED]: "Failed to assign jobs.",
    [TaskRunnerStatus.CANCELED]: "Assign jobs failed",
  };
  const description = {
    [TaskRunnerStatus.INITIAL]: "",
    [TaskRunnerStatus.RUNNING]:
      "Large batch might take long time. Please wait...",
    [TaskRunnerStatus.SUCCEEDED]: "",
    [TaskRunnerStatus.FAILED]: task.error,
    [TaskRunnerStatus.CANCELED]: "",
  };

  return createPortal(
    <div
      className={classnames(
        "fixed p-4  rounded shadow-2xl bottom-10 right-8 text-background-700 transform transition-all",
        {
          "bg-white": isRunning || isInitialized,
          "bg-success-50": isCompleted,
          "bg-red-50": isFailed,
          "opacity-100": mounted,
          "translate-y-4 opacity-0 pointer-events-none": !mounted,
        }
      )}
      style={{
        zIndex: 99999 - index,
        width: 400,
        transform: mounted ? `translateY(-${16 + 180 * index}px)` : "",
      }}
    >
      <div className="flex gap-4 py-2">
        <div className="p-0.5">
          {task.status === TaskRunnerStatus.RUNNING && (
            <IconInformationCircle className="w-6 h-6 text-primary" />
          )}
          {task.status === TaskRunnerStatus.SUCCEEDED && (
            <IconCheckedCircle className="w-6 h-6 text-success-500" />
          )}
          {task.status === TaskRunnerStatus.FAILED && (
            <IconError className="w-6 h-6 text-red-500" />
          )}
        </div>
        <div className="flex-auto space-y-2">
          <div className="text-lg font-semibold">
            <div dangerouslySetInnerHTML={{ __html: title[task.status] }} />
          </div>
          <div className="w-full">
            <div
              dangerouslySetInnerHTML={{ __html: description[task.status] }}
            />
          </div>

          <div className="w-full">
            <TaskProgresss value={task.percentComplete} />
          </div>

          <div className="flex items-center gap-2">
            {isDone && (
              <>
                <button
                  className="text-primary hover:underline"
                  onClick={handleViewDetail}
                >
                  View detail
                </button>
                <div className="w-1 h-1 rounded-full bg-background-300" />
              </>
            )}
            <button
              className="text-primary hover:underline"
              onClick={handleDismiss}
            >
              Dismiss
            </button>
          </div>
        </div>
        <button
          onClick={onClose}
          className="flex items-center justify-center flex-none w-8 h-8 rounded-full"
        >
          <IconClose className="flex-none w-6 h-6" />
        </button>
      </div>
    </div>,
    document.body
  );
};
