import { BaseAPI } from "./base.api";
import { BatchDTO } from "../dtos";
import { CreateBatchPayload } from "./batch.api";


export interface DownloadDatasetRequestDTO {
  jobDownloadType: string;
  format?: string;
  fileName: string;
  saveImage?: boolean;
}

export interface DownloadDatasetResponse {
  job_id: number;
  status: string;
  inputs: DownloadDatasetRequestDTO;
}


export class BatchAPIV2 extends BaseAPI<BatchDTO> {
  downloadDatasetAsync(batchId: number | string, payload: DownloadDatasetRequestDTO) {
    return this.post(`${this.getPath()}/${batchId}/download-dataset`, payload);
  }

  createBatch(item: CreateBatchPayload) {
    const data = JSON.stringify(item);
    return this.post(this.getPath(), data);
  }
}
