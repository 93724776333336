/*
 * File: onnix-state.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 25th April 2023 1:44:03 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { AnnotateType } from "constants/annotation.constant";
import { Point } from "utilities/math/point";
import { Rectangle } from "utilities/math/rectangle";

export enum SAMMode {
  DRAW = "DRAW",
  ADD_MASK = "ADD_MASK",
  REMOVE_AREA = "REMOVE_AREA",
}

export interface SAMBBox {
  color: string;
  labelId: number;
  data: Rectangle;
}

export interface SAMPolygon {
  color: string;
  labelId: number;
  data: Point[];
}

export interface SAMData {
  bbox: Rectangle;
  uuid: string;
  includePoints: Point[];
  excludePoints: Point[];
  bboxes: SAMBBox[];
  polygons: SAMPolygon[];
  dirty?: boolean;
}

export interface LabelOption {
  label: string;
  value: string;
  color: string;
  type: AnnotateType;
}

export interface OnnixState {
  isInitialized: boolean;
  error: any;
  loading: boolean;
  data?: SAMData;
  selectedLabel?: LabelOption;
  labelOptions?: LabelOption[];
  selectLabel(newLabelId: string): void;
  mode: SAMMode;
  selectMode(newMode: SAMMode): void;
  handleClose(): void;
  handleApply(): void;
  handleReRun(): void;
}

export const defaultOnnixState = {} as OnnixState;
