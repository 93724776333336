/*
 * File: invalid-polygon.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 23rd June 2022 5:48:25 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { Annotation } from "domain/image-labeling";
import { useTranslation } from "react-i18next";

interface Props {
  data: Annotation;
  onClose(): void;
}

export const InvalidPolygonModal = ({ data, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <VBModal
      title={"Invalid polygon"}
      open={!!data}
      onClose={onClose}
      textSubmit={t("common:buttonOk")}
      onSubmit={onClose}
      disableCloseButton
    >
      <>
        {!!data.numberOfPoint && (
          <p
            dangerouslySetInnerHTML={{
              __html: t("error:labeling.polygonShouldMatchPoints", {
                numberOfPoint: data.numberOfPoint,
              }),
            }}
          />
        )}

        {!data.numberOfPoint && (
          <p>{t("error:labeling.polygonRequiresAtleast3Points")}</p>
        )}
      </>
    </VBModal>
  );
};
