/*
 * File: batch-detail-dialog.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 25th October 2021 10:45:01 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Backdrop, Grow } from "@material-ui/core";
import {
  IconCloseCircle,
  IconMobile,
  IconMonitor,
  IconTickCircle,
} from "components/common/vb-icon.component";
import { MatModal } from "components/material/mat-modal.component";
import { useEffect, useState } from "react";
import {
  BatchDTO,
  EmbeddedType,
  getProjectTypeName,
  isTextProject,
  ProjectDTO,
} from "services/label-service/dtos";
import { StorageService } from "services/storage";

interface Props {
  onClose(): void;
  batch: BatchDTO;
  project: ProjectDTO;
}

export const BatchDetailDialog = ({ onClose, batch, project }: Props) => {
  return (
    <MatModal
      open
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={onClose}
      className="flex items-start justify-center"
    >
      <Grow in>
        <div
          className="relative flex flex-col w-full mt-40 bg-white rounded shadow outline-none overflow-hiddenx"
          style={{ maxHeight: "40rem", maxWidth: "32rem" }}
        >
          <BatchDetailContent
            batch={batch}
            project={project}
            onClose={onClose}
          />
        </div>
      </Grow>
    </MatModal>
  );
};

export const BatchDetailContent = ({ batch, project, onClose }: Props) => {
  const [instructionLink, setInstructionLink] = useState("");
  useEffect(() => {
    if (!batch.embeddedType || !batch.embeddedContent) return;
    const getLocalPDFUrl = async () => {
      if (!batch.embeddedContent) return;

      const res = await StorageService.getPublicFileContentAsBlob(
        batch.embeddedContent,
        batch.workspaceId,
      );
      const resBlob = res.data as Blob;
      // change the blob's mine type so the browser can regconize it as a pdf file
      const blob = resBlob.slice(0, resBlob.size, "application/pdf");
      const localURL = URL.createObjectURL(blob);
      setInstructionLink(localURL);
    };

    if (batch.embeddedType === EmbeddedType.PDF) {
      getLocalPDFUrl();
    } else if (batch.embeddedType === EmbeddedType.HTML) {
      setInstructionLink(batch.embeddedContent);
    }
  }, [batch]);

  return (
    <div
      style={{ maxHeight: "40rem", maxWidth: "32rem" }}
      className="overflow-y-auto"
    >
      <div className="flex items-center h-16">
        <h2 className="flex-1 px-4 text-xl font-bold lg:px-6 text-background-900">
          {batch.name}
        </h2>
        <button className="w-6 h-6 mx-4 lg:mx-6" onClick={onClose}>
          <IconCloseCircle className="w-6 h-6 text-background-900" />
        </button>
      </div>

      {!isTextProject(project?.type) && (
        <>
          <div className="px-4 text-base lg:px-6 text-background-600">
            Type: {getProjectTypeName(project?.type)} | Classification &
            Segmentation
          </div>

          <div className="flex items-center gap-2 px-4 py-2 text-base lg:px-6 text-background-600">
            <span>Supported devices: </span>
            <IconMonitor className="w-4 h-4 ml-2 text-success-500" />
            <IconTickCircle className="w-4 h-4 text-success-500" />

            <IconMobile className="w-4 h-4 ml-2 text-error-500" />
            <IconCloseCircle className="w-4 h-4 text-error-500" />
          </div>
        </>
      )}

      <div className="px-4 mt-4 text-background-900 lg:px-6">
        <div>Description</div>
        <p className="px-4 py-3 mt-3 mb-4 text-base rounded lg:px-6 pg:py-4 bg-background-100">
          {batch.description || "There is no description for this batch"}
        </p>
      </div>

      <div className="px-4 text-background-900 lg:px-6">
        <div className="flex items-center gap-2 ">
          <div>Instruction</div>
          {instructionLink && (
            <a
              href={instructionLink}
              target="_blank"
              rel="noreferrer"
              className="font-bold text-primary"
            >
              {batch.embeddedType === EmbeddedType.PDF && <span>PDF</span>}
              {batch.embeddedType === EmbeddedType.HTML && <span>LINK</span>}
            </a>
          )}
        </div>
        <p className="px-4 py-3 mt-3 mb-4 text-base rounded lg:px-6 pg:py-4 bg-background-100">
          {batch.instruction || "There is no instruction for this batch"}
        </p>
      </div>
      <div className="h-4" />
    </div>
  );
};
