/*
 * File: remove-member.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 14th September 2021 3:31:51 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/use-redux";
import { useWorkspaces } from "hooks/workspace/use-workspace.hook";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { useWorkspaceUsersContext } from "../context/workspace-users.context";
import {
  loadUsersAsync,
  removeUserAsync,
} from "store/customer/users/users.thunk";
import { User } from "domain/customer";
import * as Sentry from "@sentry/react";

interface Props {
  member: User | null;
  onClose(): void;
}

export const RemoveMemberDialog = ({ member, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { workspace } = useWorkspaces();
  const { requesting, setRequesting } = useWorkspaceUsersContext();

  async function handleRemoveMember() {
    if (requesting) return;
    if (!workspace || !member) return;
    const payload = {
      group: workspace,
      user: {
        ...member,
        userName: member.name,
        role: member.role.replace(/[[\],]/g, "").split(" ")[0],
        group: workspace,
      },
    };
    try {
      setRequesting(true);
      const response = await dispatch(removeUserAsync(payload));
      handleThunkRejected(response);
      await dispatch(loadUsersAsync());
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      onClose();
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || t("member:textInviteFailed");
      dispatch(enqueueErrorNotification(errMessage));
    } finally {
      setRequesting(false);
    }
  }
  function getTranslationText() {
    const text = t("member:removeDialog.description", {
      userName: "||||",
    });
    return text.replace("||||", `<strong>${member?.name}</strong>`);
  }
  if (!member) return null;
  return (
    <VBModal
      open
      onClose={onClose}
      title={t("member:removeDialog.title")}
      textClose={t("common:buttonCancel")}
      textSubmit={t("common:buttonRemove")}
      onSubmit={handleRemoveMember}
      disableSubmit={requesting}
    >
      <div dangerouslySetInnerHTML={{ __html: getTranslationText() }}></div>
    </VBModal>
  );
};
