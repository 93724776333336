/*
 * File: tags-header.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 19th August 2021 2:56:43 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { useDatasetTag } from "hooks/datasets/use-dataset-tag.hook";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useMemo } from "react";
import { SingleValue } from "react-select";

export const TagsHeader = () => {
  const { filter, setTags } = useDatasetContext();
  const { tags } = useDatasetTag();
  const options = useMemo(() => {
    return tags.map((tag) => {
      return {
        label: tag.name,
        value: tag.id.toString(),
      };
    });
  }, [tags]);
  const tag = useMemo(() => {
    return options.find(
      (option) => option.label.toLowerCase() === filter?.tags?.toLowerCase()
    );
  }, [options, filter]);

  function handleTagChange(option: SingleValue<any>) {
    if (option && option.label) {
      setTags(option.label);
    } else {
      setTags("");
    }
  }
  return (
    <div className="w-full mr-2 leading-4">
      <p className="h-6">Tags</p>
      <VBSelectComponent
        options={options}
        value={tag}
        onChange={handleTagChange}
        menuPortalTarget={document.body}
        isClearable
      />
    </div>
  );
};
