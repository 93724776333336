/*
 * File: job.api.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AxiosResponse } from "axios";
import { RequestOptions } from "services/common/request-options";
import { ProjectTypes } from "../dtos";
import { JobDTO } from "../dtos/job.dto";
import { BaseAPI } from "./base.api";

export class JobAPI extends BaseAPI<JobDTO> {
  pollJob(options: RequestOptions = {}) {
    const params = new URLSearchParams(options);
    return this.get(`${this.getPath()}/poll?${params.toString()}`);
  }
  pollJobByProject(projectId: number | string, limit = 10) {
    const params = new URLSearchParams({
      projectId: projectId.toString(),
      limit: limit.toString(),
    });
    return this.post(`${this.getPath()}/poll?${params.toString()}`);
  }
  fetchJob(type: ProjectTypes = ProjectTypes.GENERAL_IMAGE, limit: number = 4) {
    const params = new URLSearchParams({ limit: limit.toString(), type: type });
    return this.get(`${this.getPath()}/fetch?${params.toString()}`);
  }
  finishJob(jobId: number) {
    return this.post(`${this.getPath()}/finish/${jobId}`);
  }
  skipJob(jobDTO: Partial<JobDTO>) {
    return this.post(`${this.getPath()}/skip/${jobDTO.id}`);
  }
  reopenJobs(jobIds: number[], reason: string) {
    return this.post(`${this.getPath()}/reopen`, { jobIds, reason });
  }
  getReviewJob(jobId: number): Promise<AxiosResponse<JobDTO>> {
    return this.get(`${this.getPath()}/${jobId}`);
  }
  assignJob(jobId: number, userId?: string) {
    return this.post(`${this.getPath()}/assign`, {
      jobId,
      ...(userId && { userId }),
    });
  }
  assignJobUsingCSV(batchId: number, storageResourceId: string) {
    return this.post(`${this.getPath()}/assign-csv`, {
      batchId,
      storageResourceId,
    });
  }
  getProjectJobs(params: URLSearchParams) {
    return this.get(`${this.getPath()}/fetch-by-project?${params.toString()}`);
  }
}
