/*
 * File: use-previous-batch.hook.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 2nd November 2021 10:52:24 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Job } from "domain/labeling/job";
import { StorageKeys } from "hooks/storage/storage-keys";
import { useEffect, useState } from "react";

export const usePreviousBatch = (workingJob: Job | undefined) => {
  const [showBatchInstruction, setShowBatchInstruction] = useState(false);

  useEffect(() => {
    if (workingJob) {
      const currentBatchId = workingJob.batchId?.toString() || "";
      const lastBatchId = localStorage.getItem(StorageKeys._WORKING_BATCH_ID_);
      const show = !!currentBatchId && currentBatchId !== lastBatchId;
      setShowBatchInstruction(show);
      if (show) {
        localStorage.setItem(StorageKeys._WORKING_BATCH_ID_, currentBatchId);
      }
    }
  }, [workingJob]);

  return { showBatchInstruction, setShowBatchInstruction };
};
