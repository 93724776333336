/*
 * File: labeling-mode.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd September 2022 10:42:03 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export enum LabelingMode {
  LABELER = "labeler",
  SUPERVISOR = "supervisor",
  REVIEWER = "reviewer",
  CUSTOMER = "customer",
}
