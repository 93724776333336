import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { StorageKeys } from "hooks/storage/storage-keys";
import {
  AnalyticsQueryPayload,
  AnalyticsService,
} from "services/analytics-service";
import { RootState } from "store";
import { RequestStatus } from "store/base/base.state";
import {
  loadDashBoardFiltersFromLocalStorage,
  saveDashBoardFiltersToLocalStorage,
  setDashBoardLatestTimeLoaded,
  setDashBoardQueryNameData,
  updateCancleTokensMap,
  updateQueryNameData,
  updateQueryNameRequestStatus,
} from "./dashboard.slice";
import { DashBoardStoreState } from "./dashboard.state";
import * as Sentry from "@sentry/react";

const SLICE_NAME = "dashboard";

export interface LoadDashBaordDatAsyncPayload {
  queryPayloads: AnalyticsQueryPayload[];
  useCache: boolean;
}

export const loadDashBoardDataAsync = createAsyncThunk(
  `${SLICE_NAME}/loadDashBoardDataAsync`,
  async (
    { queryPayloads, useCache = false }: LoadDashBaordDatAsyncPayload,
    { dispatch, getState }
  ) => {
    let hadCancelRequest = false;

    try {
      const state: RootState = getState() as RootState;
      const userEmail = state.auth.currentUser?.email;
      const workspaceId = state.auth.workspace;
      if (!userEmail || !workspaceId) throw new Error("null email");

      const cacheData = state.dashBoard.cache;
      const cancelTokensMap = state.dashBoard.cancelTokensMap;

      const latestTimeKey = `${userEmail}_${workspaceId}_${StorageKeys.DASH_BOARDH_LATEST_TIME_LOADED}`;
      const localStorageLatestTimeLoaded = localStorage.getItem(latestTimeKey);
      if (
        useCache &&
        localStorageLatestTimeLoaded &&
        !state.dashBoard.latestTimeLoaded
      ) {
        dispatch(
          setDashBoardLatestTimeLoaded({
            key: latestTimeKey,
            value: localStorageLatestTimeLoaded,
          })
        );
      }
      if (useCache) {
        dispatch(
          loadDashBoardFiltersFromLocalStorage(`${userEmail}_${workspaceId}`)
        );
      }

      const promises: Promise<any>[] = [];
      for (const queryPayload of queryPayloads) {
        const queryName = queryPayload.queryName;
        const localStorageQueryNameKey = `${userEmail}_${workspaceId}_${queryName}`;
        if (useCache) {
          // check previous value first which is in memory
          if (cacheData[queryName]) continue;
          // check from local storage
          const localStorageData = localStorage.getItem(
            localStorageQueryNameKey
          );
          if (localStorageData) {
            const fieldData = JSON.parse(localStorageData);
            dispatch(
              setDashBoardQueryNameData({
                queryName,
                fieldData,
                localKey: localStorageQueryNameKey,
              })
            );
            continue;
          }
        }
        if (!useCache && cancelTokensMap[queryPayload.queryName]) {
          cancelTokensMap[queryPayload.queryName]?.cancel();
        }

        const cancelSource = axios.CancelToken.source();
        dispatch(
          updateCancleTokensMap({
            key: queryPayload.queryName,
            value: cancelSource,
          })
        );
        promises.push(
          AnalyticsService.getAnalyticsQuery(queryPayload, {
            cancelToken: cancelSource.token,
          })
            .then((response) => {
              dispatch(
                updateQueryNameData({
                  queryName: queryPayload.queryName,
                  response: response.data,
                  localKey: localStorageQueryNameKey,
                })
              );
              dispatch(
                updateQueryNameRequestStatus({
                  key: queryPayload.queryName,
                  value: RequestStatus.SUCCESS,
                })
              );
            })
            .catch((error) => {
              if (axios.isCancel(error)) {
                return Promise.reject("request_cancel");
              } else {
                dispatch(
                  updateQueryNameRequestStatus({
                    key: queryPayload.queryName,
                    value: RequestStatus.FAILURE,
                  })
                );
              }
            })
        );
        dispatch(
          updateQueryNameRequestStatus({
            key: queryPayload.queryName,
            value: RequestStatus.LOADING,
          })
        );
      }
      const responses = await Promise.allSettled(promises);

      for (const response of responses) {
        if (
          response.status === "rejected" &&
          response.reason === "request_cancel"
        ) {
          hadCancelRequest = true;
          break;
        }
      }

      if (
        (!useCache && !hadCancelRequest && queryPayloads.length >= 2) ||
        (useCache && !localStorageLatestTimeLoaded)
      ) {
        dispatch(
          setDashBoardLatestTimeLoaded({
            key: latestTimeKey,
            value: new Date().toISOString(),
          })
        );
      }
      dispatch(saveDashBoardFiltersToLocalStorage(userEmail));
    } catch (error: any) {Sentry.captureException(error);}

    return {
      hadCancelRequest,
    };
  }
);

export const dashBoardReducerBuilder = (
  builder: ActionReducerMapBuilder<DashBoardStoreState>
): ActionReducerMapBuilder<DashBoardStoreState> => {
  return builder
    .addCase(loadDashBoardDataAsync.pending, (state) => {
      state.loadDataStatus = RequestStatus.LOADING;
    })
    .addCase(loadDashBoardDataAsync.fulfilled, (state, action) => {
      const { hadCancelRequest } = action.payload;
      if (!hadCancelRequest) {
        state.loadDataStatus = RequestStatus.SUCCESS;
      }
    })
    .addCase(loadDashBoardDataAsync.rejected, (state) => {
      state.loadDataStatus = RequestStatus.FAILURE;
    });
};
