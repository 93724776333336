/*
 * File: errors.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 3rd May 2022 6:49:33 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export class AppError extends Error {
  type: string;
  data: any;

  constructor(type: string, message?: string, data?: any) {
    super(message);
    this.type = type;
    this.data = data;
  }
}

export function getErrMessage(err: any, defaultMessage: string) {
  if (err && err.hasOwnProperty("message")) {
    return err.message;
  }
  return defaultMessage;
}
