/*
 * File: ml-web.provider.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 18th February 2022 11:10:04 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { MLWeb } from "domain/web-builder";
import { useEffect, useState } from "react";
import { MLWebContextState, MlWebFilter } from "./ml-web.state";
import { MLWebContext } from "./ml-web.context";
import { useMount } from "ahooks";
import { Routes } from "routers/config/routes";
import { useHistory } from "react-router-dom";
import { MLWebCreateEditModal } from "../components/ml-web-create-edit.modal";
import { useMLWeb } from "../hooks/use-ml-web.hook";

interface Props {
  children: React.ReactNode | React.ReactNode[] | null;
}

export const MLWebProvider = ({ children }: Props) => {
  const history = useHistory();
  const {
    items,
    totalItem,
    isLoading,
    loadServingWebs,
    error,
    setForceRequest,
  } = useMLWeb();

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [renamingItem, setRemainingItem] = useState<MLWeb>();
  const [filter, setFilter] = useState<MlWebFilter>({
    name: "",
    page: 1,
    pageSize: 10,
    sort: "lastActivity,desc",
  });

  function onClickSelect(item: MLWeb) {
    history.push(Routes.WEB_BUILDER.replace(":modelId", item.id));
  }

  function onClickRename(item: MLWeb) {
    setRemainingItem(item);
  }

  function onClickDelete(item: MLWeb) {
    throw new Error("Method not implemented.");
  }

  function onClickCopyPublishUrl(item: MLWeb) {
    throw new Error("Method not implemented.");
  }

  function onClickOpenInNewTab(item: MLWeb) {
    // window.open(Routes.WEB_BUILDER.replace(":modelId", item.id));
    history.push(Routes.WEB_BUILDER.replace(":modelId", item.id));
  }

  function onClickCreateNew() {
    setIsOpenCreate(true);
  }

  function onClickFilter() {
    throw new Error("Method not implemented.");
  }

  const setFilterField = (key: keyof MlWebFilter, value: any) => {
    setFilter({
      ...filter,
      [key]: value,
    });
    setForceRequest(true);
  };

  useMount(async () => {
    // do some thing
  });

  useEffect(() => {
    if (!filter) return;
    loadServingWebs(MlWebFilter.toRequestOption(filter));
  }, [filter, loadServingWebs]);

  const value: MLWebContextState = {
    isLoading,
    items,
    totalItem,
    error,
    loadServingWebs,
    onClickSelect,
    onClickRename,
    onClickDelete,
    onClickCopyPublishUrl,
    onClickOpenInNewTab,
    onClickCreateNew,
    onClickFilter,
    filter,
    setFilter,
    setFilterField,
  };
  return (
    <MLWebContext.Provider value={value}>
      {children}
      {renamingItem && (
        <MLWebCreateEditModal
          open={!!renamingItem}
          item={renamingItem}
          onClose={() => setRemainingItem(undefined)}
          textTitle="Rename web builder"
          textSubmit="Ok"
        />
      )}
      {isOpenCreate && (
        <MLWebCreateEditModal
          open={isOpenCreate}
          item={undefined}
          onClose={() => setIsOpenCreate(false)}
        />
      )}
    </MLWebContext.Provider>
  );
};
