/*
 * File: indexeddb-cache.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 17th October 2022 9:02:27 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { configureAxiosCacheInstance } from "services/storage/apis/axios-cache.api";
import * as Sentry from "@sentry/react";

const DB_NAME = "image-cache";
const STORE_NAME = "entries";
function getWeek(date: Date) {
  // ISO week date weeks start on Monday, so correct the day number
  const nDay = (date.getDay() + 6) % 7;

  // ISO 8601 states that week 1 is the week with the first Thursday of that year
  // Set the target date to the Thursday in the target week
  date.setDate(date.getDate() - nDay + 3);

  // Store the millisecond value of the target date
  const n1stThursday = date.valueOf();

  // Set the target to the first Thursday of the year
  // First, set the target to January 1st
  date.setMonth(0, 1);

  // Not a Thursday? Correct the date to the next Thursday
  if (date.getDay() !== 4) {
    date.setMonth(0, 1 + ((4 - date.getDay() + 7) % 7));
  }

  // The week number is the number of weeks between the first Thursday of the year
  // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
  return 1 + Math.ceil((n1stThursday - date.getTime()) / 604800000);
}

function getDBVersion() {
  const currentDate = new Date();
  const weekNumber = getWeek(currentDate);
  const year = currentDate.getFullYear();
  return year * 100 + weekNumber;
}

async function getCacheStore() {
  const dbVersion = getDBVersion();
  return new Promise((resolve, reject) => {
    let db;
    const dbOpenRequest = indexedDB.open(DB_NAME, dbVersion);
    dbOpenRequest.onerror = (event) => reject(event);
    dbOpenRequest.onsuccess = (event: any) => {
      db = event.target.result;
      try {
        const store = db
          .transaction([STORE_NAME], "readwrite")
          .objectStore(STORE_NAME);
        resolve(store);
      } catch (e) {
        Sentry.captureException(e);
        reject(e);
      }
    };
    dbOpenRequest.onupgradeneeded = (event: any) => {
      db = event.target.result;
      try {
        if (db.objectStoreNames.contains(STORE_NAME)) {
          db.deleteObjectStore(STORE_NAME);
        }
        db.createObjectStore(STORE_NAME);
      } catch (e) {
        Sentry.captureException(e);
        reject(e);
      }
    };
  });
}

async function saveBlobToCache(blob: Blob, key: string) {
  return new Promise(async (resolve, reject) => {
    try {
      const store: any = await getCacheStore();
      if (store) {
        const req = store.put(blob, key);
        req.onerror = (error: any) => reject(error);
        req.onsuccess = (data: any) => resolve(data);
      }
    } catch (error) {
      Sentry.captureException(error);
      reject(error);
    }
  });
}

async function getBlobFromCache(key: string) {
  return new Promise(async (resolve, reject) => {
    try {
      const store: any = await getCacheStore();
      if (store) {
        const req = store.get(key);
        req.onerror = (error: any) => reject(error);
        req.onsuccess = (event: any) => {
          if (event.target.result) resolve(event.target.result);
          else reject();
        };
      }
    } catch (error) {
      Sentry.captureException(error);
      reject(error);
    }
  });
}

function getStaticPath(url: string) {
  if (!url || url.indexOf("?") === -1) return url;
  return url?.substring(0, url.indexOf("?"));
}

export async function persistenceImageLoader(url: string): Promise<Blob> {
  const staticUrl = getStaticPath(url);
  try {
    const cachedBlob = await getBlobFromCache(staticUrl);
    if (cachedBlob) return cachedBlob as Blob;
  } catch (error) {
    Sentry.captureException(error);
    console.log("Failed to get blob in cache", url);
  }
  const client = await configureAxiosCacheInstance();
  const response = await client.get(url, { responseType: "blob" });
  const blob = response.data as Blob;
  try {
    saveBlobToCache(blob, staticUrl);
  } catch (error) {
    Sentry.captureException(error);
    console.log("Failed to save blob to cache", url);
  }
  return blob;
}
