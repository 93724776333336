/*
 * File: dataset-provider-without-nav.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 31st August 2021 4:17:36 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useDetectChange } from "hooks/use-detect-change";
import { useAppDispatch } from "hooks/use-redux";
import { useEffect, useState } from "react";
import {
  loadDatasetByFilterAsync,
  loadTagsAsync,
  removeCurrentDatasetData,
} from "store/customer/dataset/dataset.slice";
import { Logger } from "utilities/logger";
import {
  DatasetContext,
  DatasetContextState,
  DatasetFilter,
} from "./dataset-context";
import * as Sentry from "@sentry/react";

interface Props {
  children: React.ReactNode | React.ReactNode[] | null;
}
export const DatasetProviderWithoutNav = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [datasetId, setCurrentDataset] = useState("");
  const [filter, setDatasetFilter] = useState<DatasetFilter | null>(null);
  const shouldRequestData = useDetectChange([filter]);

  function setPage(page: number) {
    if (filter?.page !== page)
      setDatasetFilter((f) => (f ? { ...f, page } : null));
  }

  function setPageSize(size: number) {
    if (filter?.size !== size)
      setDatasetFilter((f) => (f ? { ...f, size } : null));
  }

  function setStatus(status: string) {
    if (filter?.status !== status)
      setDatasetFilter((f) => (f ? { ...f, status } : null));
  }

  function setCloud(cloud: string) {
    if (filter?.cloud !== cloud)
      setDatasetFilter((f) => (f ? { ...f, cloud } : null));
  }

  function setFileName(fileName: string) {
    if (filter?.fileName !== fileName)
      setDatasetFilter((f) => (f ? { ...f, fileName } : null));
  }

  function setCreatedDate(createdDate: string) {
    if (filter?.createdDate !== createdDate)
      setDatasetFilter((f) => (f ? { ...f, createdDate } : null));
  }

  function setCreatedBy(createdBy: string) {
    if (filter?.createdBy !== createdBy)
      setDatasetFilter((f) => (f ? { ...f, createdBy } : null));
  }

  function setTags(tags: string) {
    if (filter?.tags !== tags)
      setDatasetFilter((f) => (f ? { ...f, tags } : null));
  }

  function setStoreType(storeType: string) {
    if (filter?.storeType !== storeType)
      setDatasetFilter((f) => (f ? { ...f, storeType } : null));
  }

  function setSort(field: string, sort: string) {
    if (!field) setDatasetFilter((f) => (f ? { ...f, sort: undefined } : null));
    else
      setDatasetFilter((f) =>
        f ? { ...f, sort: sort ? `${field},${sort}` : field } : null
      );
  }

  function setScope(scope: string) {
    if (filter?.scope !== scope)
      setDatasetFilter((f) => (f ? { ...f, scope } : null));
  }

  function resetDataset() {
    setCurrentDataset("");
    setDatasetFilter(null);
  }

  async function requestData() {
    try {
      if (!filter || !filter.datasetId) {
        dispatch(removeCurrentDatasetData());
        return;
      }
      await dispatch(loadDatasetByFilterAsync(filter));
      await dispatch(loadTagsAsync());
    } catch (err) {
      Sentry.captureException(err);
      Logger.log(err);
    }
  }

  useEffect(() => {
    if (!shouldRequestData) return;
    if (filter) requestData();
    else dispatch(removeCurrentDatasetData());
  });

  useEffect(() => {
    setDatasetFilter((filter) => ({
      ...filter,
      datasetId,
      size: 10,
    }));
  }, [datasetId]);

  const value: DatasetContextState = {
    filter,
    setPage,
    setPageSize,
    setStatus,
    setCloud,
    setFileName,
    setCreatedDate,
    setCreatedBy,
    setTags,
    setStoreType,
    setSort,
    setScope,
    setCurrentDataset,
    requestData,
    resetDataset,
  };
  return (
    <DatasetContext.Provider value={value}>{children}</DatasetContext.Provider>
  );
};
