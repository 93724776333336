/*
 * File: workflow-instruction.dto.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 29th July 2021 9:33:25 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BaseDTO } from "./base.dto";
import { WorkflowDTO } from "./workflow.dto";

export interface WorkflowInstructionDTO extends BaseDTO {
  canViewPreviousStepResult: boolean;
  condition: string;
  description: string;
  name: string;
  roundNumber: number;
  step: number;
  version: number;
  workflow: WorkflowDTO;
  workspaceId: string;
  stepType?: StepType;
  readonly?: boolean;
  scoreThreshold?: number;
}

export enum StepType {
  NORMAL = "NORMAL",
  REVIEW = "REVIEW",
  ACCEPTANCE = "ACCEPTANCE",
}