/*
 * File: mammography-labeling-task.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 22nd September 2022 2:44:42 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { Collection, collectionUtils } from "domain/common";
import { ImageLabelingTask } from "domain/image-labeling";
import { Job } from "domain/labeling/job";
import { ImageLabelingData } from "domain/labeling/labeling-data";
import { JobService, TaskService } from "services/label-service";
import { jobMapper } from "services/label-service/mappers/job.mapper";
import { projectMapper } from "services/label-service/mappers/project.mapper";
import { taskMapper } from "services/label-service/mappers/task.mapper";
import { RequestStatus } from "store/base/base.state";
import { batchLabelingUtils } from "../image-workspace/batch-labeling/batch-labeling.util";
import { MammographyLabelingTaskState } from "./mammography-labeling-task.state";

export enum MammographyLabelingTaskThunk {
  MAMMOGRAPHY_LOAD_TASK_ASYNC = "mammographyLabelingTask/mammographyLoadTaskAsync",
  MAMMOGRAPHY_ANNOTATOR_SELECTED_ASYNC = "mammographyLabelingTask/mammographyAnnotatorSelectedAsync",
}

export const mammographyLoadTaskAsync = createAsyncThunk(
  MammographyLabelingTaskThunk.MAMMOGRAPHY_LOAD_TASK_ASYNC,
  async ({ taskId }: { taskId: number }) => {
    const task = await batchLabelingUtils.getTaskById(taskId);
    const batch = await batchLabelingUtils.getBatchById(task.batchId);
    const labels = await batchLabelingUtils.getLabels(task.batchId);
    const project = batch.dto.project;
    const tasks = await getGroupTasks(taskId);
    tasks.push(task);
    tasks.sort((a, b) => a.id - b.id);
    const labelingTasks: Collection<ImageLabelingTask> = {
      allIds: [],
      entities: {},
    };
    for (const task of tasks) {
      const labelingTask: ImageLabelingTask = {
        id: task.id,
        labels: labels,
        batch: batch,
        project: projectMapper.fromDTO(project),
        file: undefined,
        dicomData: undefined,
        task: task,
        data: [],
        jobs: [],
      };

      const response = await batchLabelingUtils.customerGetImageAnnotations(
        batch.id,
        task.fileId,
        labels
      );

      const labelingDatas: ImageLabelingData[] = [];
      for (const annotation of response.annotations) {
        const { annotator } = annotation;
        const finder = (item: ImageLabelingData) =>
          item.annotator === annotator;
        if (!labelingDatas.find(finder)) {
          labelingDatas.push({
            id: task.id,
            jobId: annotation.jobId,
            taskId: task.id,
            fileId: task.fileId,
            annotator: annotation.annotator,
            source: annotation.source,
            annotations: [],
            relationAnnotations: [],
          });
        }
        const labelingData = labelingDatas.find(finder);
        if (!labelingData) continue;
        labelingData.annotations.push(annotation);
      }
      for (const relation of response.relationAnnotations) {
        const { annotator } = relation;
        const finder = (item: ImageLabelingData) =>
          item.annotator === annotator;
        if (!labelingDatas.find(finder)) {
          labelingDatas.push({
            id: task.id,
            jobId: relation.jobId,
            taskId: task.id,
            fileId: task.fileId,
            annotator: annotator,
            source: relation.source,
            annotations: [],
            relationAnnotations: [],
          });
        }
        const labelingData = labelingDatas.find(finder);
        if (!labelingData) continue;
        labelingData.relationAnnotations.push(relation);
      }

      labelingTask.data = labelingDatas;

      const jobItems: Job[] = await getJobsByTaskId(task.id);

      labelingTask.jobs = jobItems;

      collectionUtils.addOne(labelingTasks, labelingTask);
    }

    return {
      batch,
      tasks,
      labelingTasks,
      labels,
      project,
    };
  }
);
export const mammographyAnnotatorSelectedAsync = createAsyncThunk(
  MammographyLabelingTaskThunk.MAMMOGRAPHY_ANNOTATOR_SELECTED_ASYNC,
  async (annotators: string[]) => {
    // const { imageLabelingJobs } = state.batchLabeling;
    // const files: Record<number, any> = {};
    // for (const jobId of imageLabelingJobs.allIds) {
    //   const entity = collectionUtils.getOne(imageLabelingJobs, jobId);
    //   if (!entity) continue;
    //   if (entity.file) {
    //     files[entity.file.id] = {
    //       fileId: entity.file.id,
    //       file: entity.file,
    //       dicomData: entity.dicomData,
    //     };
    //   }
    // }
    return { annotators };
  }
);

async function getGroupTasks(taskId: number) {
  const tasksResponse = await TaskService.getGroupTasksById(taskId);
  const tasks = tasksResponse.data.map(taskMapper.fromDTO);
  return tasks;
}

async function getJobsByTaskId(taskId: number) {
  const payload = { taskId: taskId.toString(), size: "1000" };
  const response = await JobService.getItems(payload);
  if (response.data) return response.data.map(jobMapper.fromDTO);
  return [];
}

export function mammographyLabelingTaskReducerBuilder(
  builder: ActionReducerMapBuilder<MammographyLabelingTaskState>
) {
  return builder
    .addCase(mammographyLoadTaskAsync.pending, (state) => {
      state.loadingStatus = RequestStatus.LOADING;
    })
    .addCase(mammographyLoadTaskAsync.fulfilled, (state, action) => {
      state.loadingStatus = RequestStatus.SUCCESS;
      const { labelingTasks } = action.payload;
      state.project = action.payload.project;
      state.batch = action.payload.batch;
      state.labelingTasks = labelingTasks;
      state.labels = action.payload.labels;
      state.tasks = collectionUtils.fromEntities(action.payload.tasks);
      state.annotators = [];
      for (const taskId of labelingTasks.allIds) {
        const labelingTask = collectionUtils.getOne(labelingTasks, taskId);
        if (!labelingTask || !labelingTask.jobs) continue;
        for (const job of labelingTask.jobs) {
          if (!job.assignee) continue;
          if (state.annotators.includes(job.assignee)) continue;
          state.annotators.push(job.assignee);
        }
      }
      state.annotators.push("System");
      state.annotators.push("Model");
      state.selectedAnnotators = [state.annotators[0]];
    })
    .addCase(mammographyAnnotatorSelectedAsync.fulfilled, (state, action) => {
      if (!action.payload) return;
      const { annotators } = action.payload;
      state.selectedAnnotators = annotators;
      // const labelingJobsByFile: Record<number, ImageLabelingJob> = {};
      // for (const jobId of currentLabelingJobs.allIds) {
      //   const labelingJob = collectionUtils.getOne(currentLabelingJobs, jobId);
      //   if (!labelingJob || !labelingJob.file) continue;
      //   labelingJobsByFile[labelingJob.file.id] = labelingJob;
      // }
      // for (const jobId of state.labelingJobs.allIds) {
      //   const labelingJob = collectionUtils.getOne(state.labelingJobs, jobId);
      //   const fileId = labelingJob?.task?.fileId;
      //   if (!fileId) continue;
      //   if (labelingJobsByFile.hasOwnProperty(fileId)) {
      //     const currentLabelingJob = labelingJobsByFile[fileId];
      //     labelingJob.file = currentLabelingJob.file;
      //     labelingJob.dicomData = currentLabelingJob.dicomData;
      //   }
      // }
    })
    .addCase(mammographyLoadTaskAsync.rejected, (state) => {
      state.loadingStatus = RequestStatus.FAILURE;
    });
}
