import { TicketResponseDTO } from "services/customer-service/customer.dto";

export interface CustomerSupportState {
  tickets: TicketResponseDTO[],
  amountBugOnDisplay: number,
}

export const INITIAL_CUSTOMER_SUPPORT_STATE: CustomerSupportState = {
  tickets: [],
  amountBugOnDisplay: 0
};
