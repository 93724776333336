/*
 * File: image-annotations.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 21st April 2022 5:25:16 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  AnnotationContextMenuAction,
  ContextMenuItemData,
} from "components/common/context-menu/context-menu-item.component";
import { AnnotateType } from "constants/annotation.constant";
import { collectionUtils } from "domain/common";
import { Annotation, AnnotationGroup, Label } from "domain/image-labeling";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";
import { ImageLabelingData } from "domain/labeling/labeling-data";
import { RootState } from "store";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { fromSystemObservation } from "utilities/annotations/annotation-util";
import { compact } from "utilities/array/compact";
import { uniq } from "utilities/array/uniq";
import { truncateEmail } from "utilities/string/truncate-email";
import { selectImageLabelingActiveJobId } from "../batch-labeling/batch-labeling.selectors";
import { batchLabelingUtils } from "../batch-labeling/batch-labeling.util";
import {
  selectAnnotationFill,
  selectAnnotationVisible,
  selectDisplayTextValue,
} from "../cornerstone/cornerstone.selectors";
import { selectFreehandStrategy } from "../dicom-editor/dicom-editor.selectors";
import {
  selectIouActiveScore,
  selectIoUPairs,
  selectVisibleAnnotationIdsByIou,
} from "../image-iou/image-iou.selectors";
import {
  selectImageLabelingAllLabels,
  selectIsReviewJob,
  selectIsShowPreviousJobs,
  selectSelectedSystemObservationByJobId,
} from "../image-labeling/image-labeling.selectors";

export const selectImageAnnotationById =
  (uuid: string) => (state: RootState) => {
    return state.imageWorkspace.imageAnnotations.annotations.find(
      (anno) => anno.uuid === uuid
    );
  };

export const selectWorkingJobAnnotationsByLabelId =
  (workJobId: number, labelId: number) => (state: RootState) => {
    return state.imageWorkspace.imageAnnotations.annotations.filter(
      (anno) => anno.labelId === labelId && anno.jobId === workJobId
    );
  };

export const selectReviewJobAnnotations =
  (jobId: number, annotators: string[]) =>
  (state: RootState): Annotation[] => {
    const visibleAnnotationIdsByIou = selectVisibleAnnotationIdsByIou(state);
    const showLabel = selectAnnotationVisible(state);
    const fillShape = selectAnnotationFill(state);
    const displayTextValue = selectDisplayTextValue(state);
    const allLabels = selectImageLabelingAllLabels(state);
    const labels = collectionUtils.fromEntities(allLabels);

    const annotations =
      state.imageWorkspace.imageAnnotations.annotations.filter((annotation) => {
        return (
          annotation.jobId !== jobId &&
          !annotators.includes(annotation.annotator)
        );
      });
    const activeAnnotations =
      state.imageWorkspace.imageAnnotations.activeAnnotations;

    return annotations
      .filter((anno) => {
        return !collectionUtils.getOne(labels, anno.labelId)?.isSystemLabel;
      })
      .map((annotation) => {
        const { id, labelId, annotator } = annotation;
        const label = collectionUtils.getOne(labels, labelId) as Label;
        const labelName = `${label.name} (${truncateEmail(annotator)})`;

        const activeAnnotation = activeAnnotations.find(
          (anno) => anno.annotationId === id
        );

        const visible =
          visibleAnnotationIdsByIou.includes(id) &&
          (activeAnnotation?.selected || activeAnnotation?.hovering || false);

        return {
          ...annotation,
          locked: true,
          showLabel,
          fillShape,
          displayTextValue,
          label: labelName,
          color: label.color || "",
          visible,
          showActionVote: true,
        };
      });
  };

export const selectJobAnnotations =
  (jobId: number, annotators: string[]) =>
  (state: RootState): Annotation[] => {
    const isReviewJob = selectIsReviewJob(state);
    if (isReviewJob) {
      return selectReviewJobAnnotations(jobId, annotators)(state);
    }
    const isShowPreviousJobs = selectIsShowPreviousJobs(state);
    const visibleAnnotationIdsByIou = selectVisibleAnnotationIdsByIou(state);
    const showLabel = selectAnnotationVisible(state);
    const fillShape = selectAnnotationFill(state);
    const displayTextValue = selectDisplayTextValue(state);
    const allLabels = selectImageLabelingAllLabels(state);
    const labels = collectionUtils.fromEntities(allLabels);
    const allAnnotations = state.imageWorkspace.imageAnnotations.annotations;
    let annotations: Annotation[] = allAnnotations.filter(
      (anno) => anno.jobId === jobId && annotators.includes(anno.annotator)
    );

    if (isShowPreviousJobs) {
      annotations = annotations.concat(
        allAnnotations
          .filter((anno) => anno.nextJobId === jobId)
          .map((anno) => ({ ...anno, locked: true }))
      );

      const activeIoU = selectIouActiveScore(state);
      const iouPairs = selectIoUPairs(state);
      const activeIoUPairs = iouPairs.filter((pair) => {
        if (!pair.intersection) return false;
        if (activeIoU === -1) return true;
        return activeIoU <= pair.iouScore;
      });
      for (const pair of activeIoUPairs) {
        if (!pair.intersection) continue;
        annotations.push({
          ...pair.intersection,
          locked: true,
          nextJobId: jobId,
          type: "text",
        });
      }
    }

    const jobAnnotations = annotations
      .filter((anno) => {
        return !collectionUtils.getOne(labels, anno.labelId)?.isSystemLabel;
      })
      .map((anno) => {
        const label = collectionUtils.getOne(labels, anno.labelId) as Label;
        let visible = !label.hidden && anno.visible;
        if (isShowPreviousJobs) {
          visible = visible && visibleAnnotationIdsByIou.includes(anno.id);
        }
        let labelName = label.name;
        if (anno.jobId !== jobId) {
          labelName = `${label.name} (${truncateEmail(anno.annotator)})`;
        }
        return {
          ...anno,
          showLabel,
          fillShape,
          displayTextValue,
          label: labelName,
          color: label.color || "",
          opacity: label.opacity,
          visible,
          vote: undefined,
          showActionVote: true,
        };
      });

    return jobAnnotations;
  };

export const selectJobAnnotationsTree =
  (jobId: number, annotators: string[]) => (state: RootState) => {
    const annotations = selectJobAnnotations(jobId, annotators)(state);
    const labels = selectImageLabelingAllLabels(state);
    return labels
      .filter((lb) => !lb.isSystemLabel)
      .map((label) => {
        return {
          label,
          annotations: annotations.filter((anno) => anno.labelId === label.id),
        };
      });
  };

export const selectJobRelationAnnotations =
  (jobId: number, annotators: string[]) =>
  (state: RootState): RelationAnnotation[] => {
    const isShowPreviousJobs = selectIsShowPreviousJobs(state);
    const visible = state.cornerstone.relationAnnotationVisible;
    const annotations = visible
      ? selectJobAnnotations(jobId, annotators)(state)
      : [];
    const visibleMaps: { [key: string]: boolean } = {};
    for (const anno of annotations) {
      visibleMaps[anno.uuid] = anno.visible;
    }
    let relationAnnotations =
      state.imageWorkspace.imageAnnotations.relationAnnotations.filter(
        (anno) => {
          return anno.jobId === jobId && annotators.includes(anno.annotator);
        }
      );

    if (isShowPreviousJobs) {
      const imageLabelingJob = collectionUtils.getOne(
        state.batchLabeling.imageLabelingJobs,
        jobId
      );
      if (imageLabelingJob && imageLabelingJob.data?.previousJobData) {
        const previousJobAnnotations =
          imageLabelingJob.data.previousJobData.map((data) =>
            batchLabelingUtils.labelingDataToJobAnnotation(
              data as ImageLabelingData
            )
          );

        for (const previousJob of previousJobAnnotations) {
          for (let annoId of previousJob.relationAnnotations.allIds) {
            relationAnnotations.push(
              previousJob.relationAnnotations.entities[annoId]
            );
          }
        }
      }
    }

    relationAnnotations = relationAnnotations.map((anno) => {
      let relationVisible = visible;
      if (relationVisible) {
        for (let measurementId of anno.annotationData.measurementIds) {
          if (
            !visibleMaps.hasOwnProperty(measurementId) ||
            !visibleMaps[measurementId]
          ) {
            relationVisible = false;
            break;
          }
        }
      }
      return {
        ...anno,
        visible: relationVisible,
      };
    });
    return relationAnnotations;
  };

export const selectJobAnnotationsForSaving =
  (jobId: number, annotator: string) =>
  (state: RootState): Annotation[] => {
    const currentUser = selectCurrentUser(state);
    const systemLabel = selectSelectedSystemObservationByJobId(jobId)(state);
    if (!!systemLabel) {
      return [
        fromSystemObservation(
          jobId,
          systemLabel.id,
          currentUser?.email || "",
          systemLabel.color || "",
          systemLabel.name
        ),
      ];
    }
    const labelCollection = collectionUtils.fromEntities(
      state.imageWorkspace.imageLabeling.labels
    );
    const annotations =
      state.imageWorkspace.imageAnnotations.annotations.filter((anno) => {
        if (anno.jobId !== jobId) return false;
        if (anno.annotator !== annotator) return false;
        if (labelCollection.entities[anno.labelId].isSystemLabel) return false;
        return true;
      });

    return annotations.map((anno) => {
      const label = labelCollection.entities[anno.labelId];
      return {
        ...anno,
        label: label.name,
        color: label.color || "",
        opacity: label.opacity,
      };
    });
  };
export const selectJobRelationAnnotationsForSaving =
  (jobId: number, annotator: string) =>
  (state: RootState): RelationAnnotation[] => {
    const labels = state.imageWorkspace.imageLabeling.labels;
    const collection = collectionUtils.fromEntities(labels);
    const annotations =
      state.imageWorkspace.imageAnnotations.relationAnnotations.filter(
        (anno) => {
          if (anno.jobId !== jobId) return false;
          if (anno.annotator !== annotator) return false;
          if (collection.entities[anno.labelId]) return false;
          return true;
        }
      );
    return annotations;
  };

export const selectImageAnnotationAttributeMenu = (state: RootState) => {
  const { annotations, selectedAnnotation } =
    state.imageWorkspace.imageAnnotations;
  if (!selectedAnnotation.attributeMenu || !selectedAnnotation.annotationUUID) {
    return;
  }
  return annotations.find(
    (anno) => anno.uuid === selectedAnnotation.annotationUUID
  );
};

export const selectImageAnnotationContextMenu = (state: RootState) => {
  const { imageAnnotations, imageLabeling } = state.imageWorkspace;
  const { annotations, selectedAnnotation } = imageAnnotations;
  if (!selectedAnnotation.contextMenu || !selectedAnnotation.annotationUUID) {
    return;
  }
  const annotationId = selectedAnnotation.annotationUUID;
  const annotation = annotations.find((anno) => anno.uuid === annotationId);
  if (!annotation) return;
  const isReviewJob = selectIsReviewJob(state);
  const label = imageLabeling.labels.find((lb) => {
    return lb.id === annotation.labelId;
  });

  const options: ContextMenuItemData[] = compact([
    isReviewJob &&
      annotation.vote !== 1 && {
        value: AnnotationContextMenuAction.VOTE,
        label: "Up Vote",
        shortcut: "U",
      },
    isReviewJob &&
      annotation.vote !== -1 && {
        value: AnnotationContextMenuAction.DOWN_VOTE,
        label: "Down Vote",
        shortcut: "D",
      },
    {
      value: AnnotationContextMenuAction.CHANGE_ATTRIBUTES,
      label: "Change Attributes",
      shortcut: "Space",
      disabled: !label?.attributes?.length,
    },
    {
      value: AnnotationContextMenuAction.CHANGE_CLASS,
      label: "Change Class",
      shortcut: "C",
    },
    {
      value: AnnotationContextMenuAction.DELETE,
      label: "Delete",
      shortcut: "Del",
      variant: "danger",
    },
  ]);

  if (label && label.annotateType === AnnotateType.POLYGON) {
    const freehandStrategy = selectFreehandStrategy(state);
    if (!!freehandStrategy) {
      options.unshift({
        value: AnnotationContextMenuAction.DEFAULT_PEN,
        label: "Default pen",
        shortcut: "",
      });
    }
    options.unshift({
      value: AnnotationContextMenuAction.SUBTRACT_POLYGON,
      label: "Subtract Polygons",
      shortcut: "",
      disabled:
        freehandStrategy === AnnotationContextMenuAction.SUBTRACT_POLYGON,
    });
    options.unshift({
      value: AnnotationContextMenuAction.ADD_POLYGON,
      label: "Add Polygons",
      shortcut: "",
      disabled: freehandStrategy === AnnotationContextMenuAction.ADD_POLYGON,
    });
  }
  return {
    annotation,
    options,
  };
};

export const selectImageAnnotationClassMenu = (state: RootState) => {
  const { annotations, selectedAnnotation } =
    state.imageWorkspace.imageAnnotations;
  if (!selectedAnnotation.classMenu || !selectedAnnotation.annotationUUID) {
    return;
  }
  const annotation = annotations.find(
    (anno) => anno.uuid === selectedAnnotation.annotationUUID
  );

  if (!annotation) return;
  const annotationLabel = state.imageWorkspace.imageLabeling.labels.find(
    (lb) => lb.id === annotation.labelId
  );
  const availableClasses = state.imageWorkspace.imageLabeling.labels
    .filter(
      (label) =>
        !label.isSystemLabel &&
        label.annotateType === annotationLabel?.annotateType
    )
    .map((label) => {
      const disabled =
        label.annotateType !== annotationLabel?.annotateType ||
        label.id === annotationLabel?.id;
      return {
        id: label.id,
        name: label.name,
        disabled,
      };
    });
  return {
    annotation,
    availableClasses,
  };
};

export const selectInvalidAnnotation = (state: RootState) => {
  return state.imageWorkspace.imageAnnotations.invalidAnnotation;
};

export const selectActiveAnnotations = (state: RootState) => {
  return state.imageWorkspace.imageAnnotations.activeAnnotations;
};

export const selectAllAnnotations = (state: RootState) => {
  const { annotations } = state.imageWorkspace.imageAnnotations;
  return uniq([...annotations], "id") as Annotation[];
};

export const selectJobAnnotationsByLabelId =
  (jobId: number, labelId: number) => (state: RootState) => {
    return state.imageWorkspace.imageAnnotations.annotations.filter(
      (anno) => anno.labelId === labelId && anno.jobId === jobId
    );
  };

export const selectPreviousAnnotationGroups = (state: RootState) => {
  const activeJobId = selectImageLabelingActiveJobId(state);
  const allLabels = selectImageLabelingAllLabels(state);
  const allAnnotations = state.imageWorkspace.imageAnnotations.annotations;
  const groups: AnnotationGroup[] = [];

  for (const label of allLabels) {
    const annotations = allAnnotations.filter(
      (anno) => anno.labelId === label.id && anno.nextJobId === activeJobId
    );
    if (annotations.length > 0) {
      const group: AnnotationGroup = {
        groupId: label.id,
        groupName: label.name,
        annotations,
      };
      groups.push(group);
    }
  }
  return groups;
};
