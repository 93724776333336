/*
 * File: app.reducer.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 4:31:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { AppState } from "./app.state";

export enum AppActionTypes {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
}

type SetLoading = {
  type: AppActionTypes.SET_LOADING;
  payload: boolean;
};

type SetError = {
  type: AppActionTypes.SET_ERROR;
  payload: Error | null;
};

export type AppAction = SetLoading | SetError;

export function appReducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case AppActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case AppActionTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
