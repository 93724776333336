/*
 * File: common-error.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 3rd May 2022 6:41:16 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export interface CommonError {
  errorCode: number;
  message?: string;
  data?: any;
}

export enum CommonErrorCode {
  ERROR = 1,
  WARNING = 2,
}

export function commonError(
  message: string,
  errorCode = CommonErrorCode.ERROR,
  data?: any
): CommonError {
  return { errorCode, message, data };
}
