
export const delaySecond = (delay: number = 1) => {
  return new Promise<number>(res => setTimeout(_ => res(delay), delay*1000));
}

export const fakeApiCall = (delay: number = 1) => {
  return delaySecond(delay);
}


