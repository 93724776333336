/*
 * File: user-workspace.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 2nd August 2021 11:27:32 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "store";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { UserRole } from "store/auth/auth.state";

export const selectHasUserWorkspace = (state: RootState): boolean => {
  return !!state.auth.workspace;
};

export const selectUserWorkspaces = (state: RootState) => {
  return state.userWorkspace.workspaces;
};

export const selectUserWorkspacesByRole = (role: UserRole) => (
  state: RootState
) => {
  const currentUser = selectCurrentUser(state);
  const workspaceIds: string[] = currentUser?.workspace[role] || [];
  const workspaces = state.userWorkspace.workspaces.filter((workspace) =>
    workspaceIds.includes(workspace.groupId)
  );
  workspaces.sort((a, b) => {
    const lastSelectA = a.lastSelect == null ? 0 : a.lastSelect;
    const lastSelectB = b.lastSelect == null ? 0 : b.lastSelect;
    return lastSelectB - lastSelectA;
  });
  return workspaces;
};

export const selectCurrentWorkspace = (state: RootState) => {
  return state.userWorkspace.workspaces.find(
    (w) => w.groupId === state.auth.workspace
  );
};
export const selectCurrentWorkspaceId = (state: RootState) => {
  return state.auth.workspace;
};

export const selectUserWorkspaceLoading = (state: RootState) => {
  return state.userWorkspace.loading;
};

export const selectUserWorkspaceLoaded = (state: RootState) => {
  return state.userWorkspace.isLoaded;
};

export const selectUserWorkspaceRequestStatus = (state: RootState) => {
  return state.userWorkspace.requestStatus;
};
