import { IOUPair, JobOption } from "domain/common/models";
import { Annotation } from "domain/image-labeling/annotation";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import {
  BatchObservationDTO,
  TaskDTO,
} from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import { SelectedAnnotation } from "store/labeler/image-workspace/image-labeling/image-labeling.state";
import { AppError } from "utilities/errors/errors";
import { INITIAL_TASK_REVIEW_IOU_STATE, TaskReviewIouState } from "./task-review-iou/task-review-iou.state";
import { ImageLabelingJobMetadata } from "store/labeler/image-workspace/batch-labeling/batch-labeling.state";
export interface TaskReviewBatchObservationModel {
  batchObservation: BatchObservationDTO;
  selected: boolean;
  assignees: string[];
  iouPairs: IOUPair[];
  avgIoU: number;
}

export interface ActiveAnnotation {
  annotationId: number;
  selected?: boolean;
  hovering?: boolean;
}

export interface TasksReviewState {
  requestStatus: RequestStatus;
  error: AppError | undefined;
  file: StorageFileDTO | undefined;
  task: TaskDTO | undefined;
  jobOptions: JobOption[];
  taskReviewBatchObservations: TaskReviewBatchObservationModel[];
  selectedAnnotation: SelectedAnnotation | null;
  annotations: Annotation[];
  relationAnnotations: RelationAnnotation[];
  filterAssignee: string;
  activeAnnotations: ActiveAnnotation[];
  taskReviewIou: TaskReviewIouState;
  metadata?: ImageLabelingJobMetadata
}

export const INITIAL_TASKS_REVIEW_STATE: TasksReviewState = {
  requestStatus: RequestStatus.IDLE,
  error: undefined,
  file: undefined,
  task: undefined,
  jobOptions: [],
  taskReviewBatchObservations: [],
  annotations: [],
  relationAnnotations: [],
  selectedAnnotation: null,
  filterAssignee: "", // empty string means all
  
  activeAnnotations: [],
  taskReviewIou: INITIAL_TASK_REVIEW_IOU_STATE
};
