/*
 * File: text-review.state.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 29th October 2021 11:10:56 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { TextLabelingData } from "domain/labeling/labeling-data";
import {
  CORAnnotationData,
  Label,
  NERAnnotationData,
} from "domain/text-labeling";
import { SystemAnnotationData } from "domain/text-labeling/system-annotation";
import { JobTextAnnotationLabel } from "services/label-service/dtos/text-observation.dto";

export interface TextLabelAnnotations {
  label: Label;
  textObservations: JobTextAnnotationLabel[];
}

export interface TextSystemFinding {
  batchObservation: Label;
  assignees: string[];
}
export interface TextReviewState {
  reviewMode: boolean;
  annotations: NERAnnotationData[];
  relationAnnotations: CORAnnotationData[];
  systemAnnotations: SystemAnnotationData[];
  labelingDatas: TextLabelingData[];
  selectedAnnotators: string[];
  selectedAnnotationIds: string[];
  activeTab: string;
}

export const INITIAL_TEXT_REVIEW_STATE: TextReviewState = {
  reviewMode: false,
  annotations: [],
  relationAnnotations: [],
  systemAnnotations: [],
  labelingDatas: [],
  selectedAnnotators: [],
  selectedAnnotationIds: [],
  activeTab: "all",
};
