import OpenSeadragon from "openseadragon";
import { fabric } from "fabric";
import { FabricToolPolygon } from "./fabric-tool-polygon";
import { FabricTool } from "./fabric-tool";
import {
  createPolygonFromPoints,
  DEFAULT_FILL,
  DEFAULT_OBJECT_STROKE_WIDTH,
  DEFAULT_TEXT_LABEL_FONT_SIZE,
  getAllChildren,
  hexToRGBAFabric,
  hideObjectControls,
  isValidRectangle,
  updateDistanceTextPosForObject,
  updateObjectAdditionalData,
  updateObjectDistanceTextVisibilities,
  updateObjectUtilGroupVisibilities,
  updateUtilGroupPositionForObject,
} from "./fabric-object.utils";
import { FabricToolRectangle } from "./fabric-tool-rectangle";
import {
  ANNOTATION_TYPES,
  DEFAULT_FILL_OBJECTS,
  DEFAULT_TEXT_LABELER_VISIBILITY,
  DEFAULT_TEXT_LABEL_VISIBILITY,
  DEFAULT_UTIL_BUTTON_VISIBILITY,
  FabricObjectToolType,
  FabricOverlayEvents,
  ObjectOperationType,
} from "./fabric.models";
import { FabricToolLine } from "./fabric-tool-line";
import { FabricToolEllipse } from "./fabric-tool-ellipse";
import { FabricToolBrush } from "./fabric-tool-brush";
import { FabricToolMagicWand } from "./fabric-tool-magic-wand";
import { fabricObjectToJstsGeometry } from "./fabric-jsts.utils";
import { PathologyAnnotationAdditionalData } from "../pathology-editor.models";

export class FabricOverlay {
  viewer: OpenSeadragon.Viewer;
  containerWidth: number = 0;
  containerHeight: number = 0;

  canvasId: string = "osd-fabric-overlaycanvas";
  canvasDiv: HTMLDivElement;
  canvas: HTMLCanvasElement;
  fabricCanvas: fabric.Canvas;

  eventListeners: any = {};

  enabledPan = true;

  // Tools
  tools: FabricTool[] = [];
  toolPolygon: FabricToolPolygon | undefined;
  toolRectangle: FabricToolRectangle | undefined;
  toolLine: FabricToolLine | undefined;
  toolEllipse: FabricToolEllipse | undefined;
  toolBrush: FabricToolBrush | undefined;
  toolMagicWand: FabricToolMagicWand | undefined;
  // Set this object when you need to add additional data to objects when drawing
  objectAdditionalData: any;
  objectStrokeWidth = DEFAULT_OBJECT_STROKE_WIDTH;
  textLabelFontSize = DEFAULT_TEXT_LABEL_FONT_SIZE;

  labelVisibility = DEFAULT_TEXT_LABEL_VISIBILITY;
  labelerVisibility = DEFAULT_TEXT_LABELER_VISIBILITY;
  utilButtonVisibility = DEFAULT_UTIL_BUTTON_VISIBILITY;
  fillObjects = DEFAULT_FILL_OBJECTS;

  constructor(viewer: OpenSeadragon.Viewer) {
    this.viewer = viewer;

    this.canvasDiv = document.createElement("div");
    this.canvasDiv.style.position = "absolute";
    this.canvasDiv.style.left = "0px";
    this.canvasDiv.style.top = "0px";
    this.canvasDiv.style.width = "100%";
    this.canvasDiv.style.height = "100%";
    this.canvasDiv.setAttribute("id", "fabric-overlay-div");

    this.viewer.canvas.appendChild(this.canvasDiv);

    this.canvas = document.createElement("canvas");
    this.canvas.setAttribute("id", this.canvasId);
    this.canvasDiv.appendChild(this.canvas);
    this.resize();

    this.fabricCanvas = new fabric.Canvas(this.canvas, {
      width: viewer.container.clientWidth,
      height: viewer.container.clientHeight,
      isDrawingMode: false,
      // very important option here
      // this options allows fabric mouse events to be fired first.
      enablePointerEvents: true,
      // Need this to display label and util group stuff when selected
      preserveObjectStacking: true,
    } as any);
    this.fabricCanvas.selection = false;

    this.fabricCanvas.on("mouse:down", (options: any) => {
      const object = options.target as fabric.Object;
      if ((options.target && object.selectable) || !this.enabledPan) {
        options.e.preventDefaultAction = true;
        options.e.preventDefault();
        options.e.stopPropagation();
      }
      for (const tool of this.tools) {
        tool.onMouseDown(options);
      }
    });

    this.fabricCanvas.on("mouse:move", (options: any) => {
      for (const tool of this.tools) {
        tool.onMouseMove(options);
      }
    });

    this.fabricCanvas.on("mouse:up", (options: any) => {
      const object = options.target as fabric.Object;
      if ((options.target && object.selectable) || !this.enabledPan) {
        options.e.preventDefaultAction = true;
        options.e.preventDefault();
        options.e.stopPropagation();
      }
      for (const tool of this.tools) {
        tool.onMouseUp(options);
      }
    });

    this.fabricCanvas.on("selection:created", (e: any) => {
      const selectedObjects = e.selected as fabric.Object[];

      this.handleSelectedGroupObject();
      this.updateSelectedObjects(selectedObjects);
      this.sendEventToListeners(FabricOverlayEvents.SELECTED_OBJECTS_UPDATED);
    });

    this.fabricCanvas.on("selection:updated", (e: any) => {
      const deselectedObjects = e.deselected as fabric.Object[];
      const selectedObjects = e.selected as fabric.Object[];

      this.handleSelectedGroupObject();
      this.updateSelectedObjects(selectedObjects);
      this.updateDeselectedObjects(deselectedObjects);
      this.sendEventToListeners(FabricOverlayEvents.SELECTED_OBJECTS_UPDATED);
    });

    this.fabricCanvas.on("selection:cleared", (e: any) => {
      const deselectedObjects = e.deselected as fabric.Object[];

      this.updateDeselectedObjects(deselectedObjects);
      this.sendEventToListeners(FabricOverlayEvents.SELECTED_OBJECTS_UPDATED);
    });

    this.viewer.addHandler("animation", () => {
      this.resize();
      this.resizeCanvas(true);
      this.render();
    });

    this.viewer.addHandler("open", () => {
      this.resize();
      this.resizeCanvas(false);

      this.sendEventToListeners(FabricOverlayEvents.OPEN);
      this.updateObjectsOnZoom();
    });

    this.viewer.addHandler("resize", () => {
      this.resize();
      this.resizeCanvas(false);
    });
  }

  deselectAllObjects() {
    this.fabricCanvas.discardActiveObject();
    this.fabricCanvas.requestRenderAll();
  }

  selectObjects(objects: fabric.Object[]) {
    this.fabricCanvas.discardActiveObject();
    if (objects.length === 1) {
      this.fabricCanvas.setActiveObject(objects[0]);
    } else if (objects.length > 1) {
      const sel = new fabric.ActiveSelection(objects, {
        canvas: this.fabricCanvas,
      });
      this.fabricCanvas.setActiveObject(sel);
    }
    this.fabricCanvas.requestRenderAll();
  }

  deleteSelectedObjects(deleteChildren = false) {
    const objectsToDelete = this.fabricCanvas.getActiveObjects();
    this.deselectAllObjects();

    if (deleteChildren) {
      for (const object of objectsToDelete) {
        const children = getAllChildren(object);
        for (const child of children) {
          objectsToDelete.push(child);
        }
      }
    }

    for (const object of objectsToDelete) {
      this.removeObject(object);
    }
    this.fabricCanvas.requestRenderAll();
  }

  handleSelectedGroupObject() {
    if (this.fabricCanvas.getActiveObjects().length > 1) {
      const activeObject = this.fabricCanvas.getActiveObject();
      if (activeObject) hideObjectControls(activeObject);
      for (const object of this.fabricCanvas.getActiveObjects()) {
        object.set({ stroke: "green" });
        this.fabricCanvas.bringToFront(object.data?.utilGroupRef);
      }
      this.fabricCanvas.requestRenderAll();
    }
  }

  updateSelectedObjects(objects: fabric.Object[]) {
    for (const object of objects) {
      object.data.selected = true;
    }
  }

  updateDeselectedObjects(objects: fabric.Object[]) {
    if (!objects) return;
    for (const object of objects) {
      object.data.selected = false;
    }
    this.setObjectsToDefaultStroke(objects);
  }

  setObjectsToDefaultStroke(objects: fabric.Object[]) {
    for (const object of objects) {
      object.set({ stroke: object.data?.labelColor || "green" });
    }
    this.fabricCanvas.requestRenderAll();
  }

  sendEventToListeners(eventType: FabricOverlayEvents) {
    if (this.eventListeners[eventType]) {
      for (const callback of this.eventListeners[eventType]) {
        callback();
      }
    }
  }

  removeObject(object: fabric.Object) {
    if (object.data?.utilGroupRef) {
      this.fabricCanvas.remove(object.data.utilGroupRef);
      object.data.utilGroupRef = undefined;
    }
    // remove edges
    const objectParent = object.data?.parent;
    if (object.data?.parent) {
      // remove parent -> object edge
      if (objectParent && objectParent.data.children) {
        objectParent.data.children = objectParent.data.children.filter(
          (c: any) => c !== object
        );
      }
      // remove parent <- object edge
      object.data.parent = undefined;
    }
    // remove object -> child edges
    if (object.data?.children) {
      for (const child of object.data.children) {
        if (child.data?.parent) {
          // change object child's parent to the object's parent
          if (objectParent && objectParent.data.children) {
            child.data.parent = objectParent;
            objectParent.data.children.push(child);
          } else {
            child.data.parent = undefined;
          }
        }
        // Update level
        if (child.data.parent) {
          // if has new parent
          child.data.level = child.data.parent.data.level
            ? child.data.parent.data.level + 1
            : undefined;
        } else {
          child.data.level = undefined;
        }
      }
      object.data.children = undefined;
    }

    if (object.data?.distanceTextRef) {
      this.fabricCanvas.remove(object.data.distanceTextRef);
      object.data.distanceTextRef = undefined;
    }
    this.fabricCanvas.remove(object);
  }

  clear() {
    this.fabricCanvas.clear();
  }

  render() {
    this.fabricCanvas.renderAll();
  }

  destroy() {
    this.sendEventToListeners(FabricOverlayEvents.BEFORE_DESTROY);

    this.toolPolygon?.destroy();
    this.fabricCanvas.dispose();
    this.eventListeners = {};
  }

  /**
   * Set the container dimension
   */
  resize() {
    if (this.containerWidth !== this.viewer.container.clientWidth) {
      this.containerWidth = this.viewer.container.clientWidth;
      this.canvasDiv.style.width = `${this.containerWidth.toString()}px`;
      this.canvas.style.width = `${this.containerWidth.toString()}px`;
    }

    if (this.containerHeight !== this.viewer.container.clientHeight) {
      this.containerHeight = this.viewer.container.clientHeight;
      this.canvasDiv.style.height = `${this.containerHeight.toString()}px`;
      this.canvas.style.height = `${this.containerHeight.toString()}px`;
    }
  }

  /**
   *
   * @param cssOnlyAndBackstoreOnly this function change the fabric.Canvas size, position, zoom, pan
   * corresponding to the OpenSeadargon.Viewer to display to objects correctly related to the viewing image.
   */
  resizeCanvas(cssOnlyAndBackstoreOnly: boolean = true) {
    const viewportZoom = this.viewer.viewport.getZoom(true);
    const image1 = this.viewer.world.getItemAt(0);

    const prevWidth = this.fabricCanvas.getWidth();
    const prevHeight = this.fabricCanvas.getHeight();
    this.fabricCanvas.setDimensions(
      {
        width: this.containerWidth,
        height: this.containerHeight,
      },
      {
        cssOnly: cssOnlyAndBackstoreOnly,
        backstoreOnly: cssOnlyAndBackstoreOnly,
      }
    );
    this.fabricCanvas.setZoom(viewportZoom);

    const origin = new OpenSeadragon.Point(0, 0);
    const image1WindowPoint = image1.imageToWindowCoordinates(origin);
    const x = Math.round(image1WindowPoint.x);
    const y = Math.round(image1WindowPoint.y);
    const canvasOffset = this.canvasDiv.getBoundingClientRect();
    const pageScroll = OpenSeadragon.getPageScroll();
    const panX = canvasOffset.left - x + pageScroll.x;
    const panY = canvasOffset.top - y + pageScroll.y;

    this.fabricCanvas.absolutePan(new fabric.Point(panX, panY));

    if (this.eventListeners[FabricOverlayEvents.CANVAS_RESIZE]) {
      for (const callback of this.eventListeners[
        FabricOverlayEvents.CANVAS_RESIZE
      ]) {
        callback(viewportZoom);
      }
    }
    this.updateObjectsOnZoom();

    if (
      prevWidth !== this.containerWidth ||
      prevHeight !== this.containerHeight
    ) {
      if (this.eventListeners[FabricOverlayEvents.DIMENSION_CHANGED]) {
        for (const callback of this.eventListeners[
          FabricOverlayEvents.DIMENSION_CHANGED
        ]) {
          callback(
            prevWidth,
            prevHeight,
            this.containerWidth,
            this.containerHeight
          );
        }
      }
      // Need to deselect object first
      // or else objects in group not correctly handle
      // TODO: might handle later
      this.fabricCanvas.discardActiveObject();
      this.updateObjectsOnDimensionChanged(
        prevWidth,
        prevHeight,
        this.containerWidth,
        this.containerHeight
      );
    }
  }

  on(type: FabricOverlayEvents, callback: any) {
    if (!this.eventListeners[type]) {
      this.eventListeners[type] = [];
    }
    this.eventListeners[type].push(callback);
  }

  off(type: FabricOverlayEvents, callback: any) {
    if (this.eventListeners[type]) {
      this.eventListeners[type] = this.eventListeners[type].filter(
        (cb: any) => cb !== callback
      );
    }
  }

  setObjectsFill(fill: boolean) {
    this.fillObjects = fill;
    for (const object of this.fabricCanvas.getObjects()) {
      if (
        object.type &&
        !ANNOTATION_TYPES.includes(object.type as FabricObjectToolType)
      )
        continue;
      if (fill) {
        if (object.data?.labelColor) {
          object.set({
            fill: hexToRGBAFabric(object.data.labelColor),
          });
        }
      } else {
        object.set({ fill: DEFAULT_FILL });
      }
    }
    this.fabricCanvas.requestRenderAll();
  }

  setUtilGroupLabelVisibilities(
    labelVisibility: boolean,
    labelerVisibility: boolean,
    utilButtonVisibility: boolean,
    update: boolean = true
  ) {
    this.labelVisibility = labelVisibility;
    this.labelerVisibility = labelerVisibility;
    this.utilButtonVisibility = utilButtonVisibility;
    if (update) this.updateUtilGroupLabelVisibilities();
  }

  updateUtilGroupLabelVisibilities() {
    for (const object of this.fabricCanvas.getObjects(
      FabricObjectToolType.UTIL_LABEL_GROUP
    )) {
      if (object.data?.parentRef) {
        updateObjectUtilGroupVisibilities(object.data.parentRef, {
          labelVisibility: this.labelVisibility,
          labelerVisibility: this.labelerVisibility,
          utilButtonVisibility: this.utilButtonVisibility,
        });
      }
    }

    this.fabricCanvas.requestRenderAll();
  }

  updateDistanceTextLabelVisibilities() {
    for (const object of this.fabricCanvas.getObjects(
      FabricObjectToolType.DISTANCE_TEXT
    )) {
      if (object.data?.parentRef) {
        updateObjectDistanceTextVisibilities(object.data.parentRef);
      }
    }
  }

  setAllObjectsSelectableByValue(
    selectable: boolean,
    includeTypes: string[] = ANNOTATION_TYPES
  ) {
    for (const object of this.fabricCanvas.getObjects()) {
      if (object.type && !includeTypes.includes(object.type)) continue;
      object.set({ selectable });
    }
  }

  setAllObjectsSelectable() {
    this.setAllObjectsSelectableByValue(true);
  }

  setAllObjectsUnSelectable() {
    this.setAllObjectsSelectableByValue(false);
  }

  disableAllTools() {
    this.fabricCanvas.selection = false;
    for (const tool of this.tools) {
      tool.setEnabled(false);
    }
  }

  clonePolygon(polygon: fabric.Polygon, newPoints: fabric.Point[]) {
    const newPolygon = createPolygonFromPoints({
      points: newPoints,
      otherProps: {
        stroke: polygon.stroke,
        data: polygon.data,
        selectable: polygon.selectable,
      },
      strokeWidth: this.objectStrokeWidth / this.fabricCanvas.getZoom(),
      createUtilLabel: false,
    });
    if (newPolygon.data?.utilGroupRef) {
      newPolygon.data.utilGroupRef.data.parentRef = newPolygon;
    }
    updateObjectAdditionalData(newPolygon, polygon.data, {
      labelVisibility: this.labelVisibility,
      labelerVisibility: this.labelerVisibility,
      utilButtonVisibility: this.utilButtonVisibility,
      fill: this.fillObjects,
    });
    return newPolygon;
  }

  changeObjectsAdditionalData(objects: fabric.Object[], addData: any) {
    for (const object of objects) {
      updateObjectAdditionalData(object, addData);
    }
    this.fabricCanvas.requestRenderAll();
  }

  /**
   * Build the hierarchy tree from current objects and edges
   * @param edges
   */
  buildHierarchyTreeFromEdges(edges: any[]) {
    if (!edges || edges.length < 1) return;

    // build lookup table by serverId for easy access
    const serverIdToObject: Record<number, fabric.Object> = {};
    for (const object of this.fabricCanvas.getObjects()) {
      if (
        !object.type ||
        (object.type &&
          !ANNOTATION_TYPES.includes(object.type as FabricObjectToolType))
      )
        continue;
      if (!object.data?.serverId) continue;
      serverIdToObject[object.data.serverId] = object;
    }

    // link objects
    for (const edge of edges) {
      const parentObject = serverIdToObject[edge.parentServerId];
      const childObject = serverIdToObject[edge.childServerId];

      if (!parentObject || !childObject) continue;

      if (!parentObject.data.children) {
        parentObject.data.children = [];
      }
      parentObject.data.children.push(childObject);

      childObject.data.parent = parentObject;
    }
  }

  insertObjectsInHierarchy(objects: fabric.Object[]) {
    if (objects.length < 1) return;
    // NOTE: trade off here, we get the geometry for each object when needed.
    // but we can save the geometry and update the geometry when the object changed but it would be more complicated.
    const geometries = this.fabricCanvas
      .getObjects()
      .map(fabricObjectToJstsGeometry)
      .filter((geo) => !!geo);

    // TODO: optimize later
    for (const object of objects) {
      // for simplicity, if the object already had a parent just ignore it
      if (object.data?.parent) continue;

      const geometry = object.data?.geometryRef;
      if (!geometry) continue;

      const containedGeometries = [];
      // find contained geometries
      for (let j = 0; j < geometries.length; j++) {
        if (geometry === geometries[j]) continue;
        if (geometries[j].contains(geometry)) {
          containedGeometries.push(geometries[j]);
        }
      }

      // TODO: we will choose the parent with the smallest ROI later
      // for now just pick the first one
      if (containedGeometries.length < 1) continue;
      const parentGeometry = containedGeometries[0];

      // set parent/children
      const parentObject = parentGeometry.getUserData().objectRef;
      object.data.parent = parentObject;
      if (!parentObject.data.children) {
        parentObject.data.children = [];
      }
      // Children if not already existed
      const alreadyExisted = parentObject.data.children.find(
        (c: any) => c === object
      );
      if (!alreadyExisted) {
        parentObject.data.children.push(object);
      }
    }
  }

  doObjectsOperation(objects: fabric.Object[], opType: ObjectOperationType) {
    if (!objects || objects.length < 2) return;
    const geometries = objects
      .map(fabricObjectToJstsGeometry)
      .filter((geo) => !!geo);
    if (geometries.length < 2) return;
    const firstObjectData = objects[0]
      .data as PathologyAnnotationAdditionalData;

    const addData: PathologyAnnotationAdditionalData = {
      labelId: firstObjectData.labelId,
      labelName: firstObjectData.labelName,
      labelColor: firstObjectData.labelColor,
      labeler: firstObjectData.labeler,
    };

    if (opType === ObjectOperationType.DIFFERENCE_B) {
      geometries.reverse();
    }

    let currentGeo = geometries[0];
    for (let i = 1; i < geometries.length; i++) {
      if (opType === ObjectOperationType.UNION) {
        currentGeo = currentGeo.union(geometries[i]);
      } else if (opType === ObjectOperationType.INTERSECTION) {
        currentGeo = currentGeo.intersection(geometries[i]);
      } else if (
        opType === ObjectOperationType.DIFFERENCE_A ||
        opType === ObjectOperationType.DIFFERENCE_B
      ) {
        currentGeo = currentGeo.difference(geometries[i]);
      }
    }

    const points = currentGeo
      .getCoordinates()
      .map((c: any) => new fabric.Point(c.x, c.y));
    points.pop();

    const polygon = createPolygonFromPoints({
      points,
      strokeWidth: this.objectStrokeWidth / this.fabricCanvas.getZoom(),
      otherProps: { selectable: true },
    });
    updateObjectAdditionalData(polygon, addData);

    for (const object of objects) {
      this.removeObject(object);
    }

    this.fabricCanvas.add(polygon);
    this.fabricCanvas.add(polygon.data?.utilGroupRef);
    // Set the stroke size correctly
    updateUtilGroupPositionForObject(polygon);

    this.fabricCanvas.requestRenderAll();
  }

  /**
   * Useful when export objects.
   * We need to update object coordinates from fabric absolute to osd image
   */
  transformObjectsCoordinatesToImage() {
    for (const object of this.fabricCanvas.getObjects()) {
      if (object.type === FabricObjectToolType.DRAWN_POLYGON) {
        const polygon = object as fabric.Polygon;
        if (polygon.points) {
          polygon.set({
            points: polygon.points.map((p) => {
              const imagePos = this.fabricAbsoluteToImage(p.x, p.y);
              return new fabric.Point(imagePos.x, imagePos.y);
            }),
          });
        }
      }

      if (object.type === FabricObjectToolType.DRAWN_RECTANGLE) {
        const rectangle = object as fabric.Rect;
        if (!isValidRectangle(rectangle)) continue;

        const imagePos = this.fabricAbsoluteToImage(
          rectangle.left as number,
          rectangle.top as number
        );
        const imageWidthHeight = this.fabricAbsoluteToImage(
          rectangle.width as number,
          rectangle.height as number
        );
        rectangle.set({
          left: imagePos.x,
          top: imagePos.y,
          width: imageWidthHeight.x,
          height: imageWidthHeight.y,
        });
      }

      if (object.type === FabricObjectToolType.DRAWN_LINE) {
        const line = object as fabric.Line;
        if (!line.x1 || !line.x2 || !line.y1 || !line.y2) continue;

        const points = [
          { x: line.x1, y: line.y1 },
          { x: line.x2, y: line.y2 },
        ];
        const formmatedPoints = points.map((p) => {
          const imagePos = this.fabricAbsoluteToImage(p.x, p.y);
          return new fabric.Point(imagePos.x, imagePos.y);
        });
        line.set({
          x1: formmatedPoints[0].x,
          y1: formmatedPoints[0].y,
          x2: formmatedPoints[1].x,
          y2: formmatedPoints[1].y,
        });
      }
    }
  }

  /**
   * Update util group for objects
   * this function should be called after we changed object postions
   * when resizing window, zooming, converting coordinates ...
   */
  updateObjectsUtilGroupPosForObjects() {
    const objects = this.fabricCanvas.getObjects(
      FabricObjectToolType.UTIL_LABEL_GROUP
    );
    for (const object of objects) {
      const utilGroup = object as fabric.Group;
      if (utilGroup.data?.parentRef) {
        updateUtilGroupPositionForObject(utilGroup.data.parentRef);
      }
    }
  }

  updateObjectsDistancePosForObjects() {
    const zoom = this.fabricCanvas.getZoom();
    const objects = this.fabricCanvas.getObjects(
      FabricObjectToolType.DISTANCE_TEXT
    );
    for (const object of objects) {
      const distanceText = object as fabric.Text;
      if (distanceText.data?.parentRef) {
        updateDistanceTextPosForObject(distanceText.data.parentRef, zoom);
      }
    }
  }

  /**
   * Useful when import objects from db.
   * We need to update object coordinates from osd image to fabric absolute
   */
  transformObjectsCoordinatesToFabricAbsolute(sendEvent = false) {
    for (const object of this.fabricCanvas.getObjects()) {
      if (object.type === FabricObjectToolType.DRAWN_POLYGON) {
        const polygon = object as fabric.Polygon;
        if (polygon.points) {
          const newPoints = polygon.points.map((p) =>
            this.imageToFabricAbsolute(p.x, p.y)
          );

          // For polygon you cant not set points directly
          // for it to works properly you have to create a new one.
          const newPolygon = this.clonePolygon(polygon, newPoints);

          this.fabricCanvas.remove(polygon);
          this.fabricCanvas.add(newPolygon);
        }
      }

      if (object.type === FabricObjectToolType.DRAWN_RECTANGLE) {
        const rectangle = object as fabric.Rect;
        if (!isValidRectangle(rectangle)) continue;
        const fabricAbsPos = this.imageToFabricAbsolute(
          rectangle.left as number,
          rectangle.top as number
        );
        const fabricAbsWidthHeight = this.imageToFabricAbsolute(
          rectangle.width as number,
          rectangle.height as number
        );
        rectangle.set({
          left: fabricAbsPos.x,
          top: fabricAbsPos.y,
          width: fabricAbsWidthHeight.x,
          height: fabricAbsWidthHeight.y,
        });
        rectangle.setCoords();
      }

      if (object.type === FabricObjectToolType.DRAWN_LINE) {
        const line = object as fabric.Line;
        if (!line.x1 || !line.x2 || !line.y1 || !line.y2) continue;
        const points = [
          { x: line.x1, y: line.y1 },
          { x: line.x2, y: line.y2 },
        ];
        const newPoints = points.map((p) =>
          this.imageToFabricAbsolute(p.x, p.y)
        );

        line.set({
          x1: newPoints[0].x,
          y1: newPoints[0].y,
          x2: newPoints[1].x,
          y2: newPoints[1].y,
        });
        line.setCoords();
      }
    }

    this.updateObjectsUtilGroupPosForObjects();
    this.updateObjectsDistancePosForObjects();

    if (sendEvent) {
      this.sendEventToListeners(
        FabricOverlayEvents.TRANSFORM_OBJECTS_TO_FABRIC_ABSOLUTE
      );
    }
  }

  /**
   * Update objects when the dimension changed (like user resize window)
   * we need to update polygon points, ...
   * @param prevWidth
   * @param prevHeight
   * @param width
   * @param height
   * @returns {void}
   */
  updateObjectsOnDimensionChanged(
    prevWidth: number,
    prevHeight: number,
    width: number,
    height: number
  ) {
    if (prevWidth === 0 || prevHeight === 0 || width === 0 || height === 0)
      return;
    const ratio = width / prevWidth;
    for (const object of this.fabricCanvas.getObjects()) {
      if (object.type === FabricObjectToolType.DRAWN_POLYGON) {
        const polygon = object as fabric.Polygon;
        if (polygon.points) {
          const newPoints = polygon.points.map((p) => {
            const newX = p.x * ratio;
            const newY = p.y * ratio;
            return new fabric.Point(newX, newY);
          });

          // For polygon you cant not set points directly
          // for it to works properly you have to create a new one.
          const newPolygon = this.clonePolygon(polygon, newPoints);

          this.fabricCanvas.remove(polygon);
          this.fabricCanvas.add(newPolygon);

          newPolygon.setCoords();
        }
      }

      if (object.type === FabricObjectToolType.DRAWN_RECTANGLE) {
        const rectangle = object as fabric.Rect;
        rectangle.set({
          left: (rectangle.left || 0) * ratio,
          top: (rectangle.top || 0) * ratio,
          width: (rectangle.width || 0) * ratio,
          height: (rectangle.height || 0) * ratio,
        });
        rectangle.setCoords();
      }

      if (object.type === FabricObjectToolType.DRAWN_LINE) {
        const line = object as fabric.Line;
        if (!line.x1 || !line.x2 || !line.y1 || !line.y2) {
          continue;
        }
        const points = [
          { x: line.x1, y: line.y1 },
          { x: line.x2, y: line.y2 },
        ];
        const newPoints = points.map((p) => {
          const newX = p.x * ratio;
          const newY = p.y * ratio;
          return new fabric.Point(newX, newY);
        });

        line.set({
          x1: newPoints[0].x,
          y1: newPoints[0].y,
          x2: newPoints[1].x,
          y2: newPoints[1].y,
        });
        line.setCoords();
      }
    }

    this.updateObjectsUtilGroupPosForObjects();
    this.updateObjectsDistancePosForObjects();
  }

  /**
   * Keep the objects stroke size
   * or util group size related to the web page when zooming
   * @param zoom
   */
  updateObjectsOnZoom(zoom?: number) {
    if (!zoom) {
      zoom = this.fabricCanvas.getZoom();
    }
    for (const object of this.fabricCanvas.getObjects()) {
      if (object.type === FabricObjectToolType.UTIL_LABEL_GROUP) {
        const utilGroup = object as fabric.Group;
        utilGroup.set({
          scaleX: 1 / zoom,
          scaleY: 1 / zoom,
        });
        continue;
      }
      if (object.type === FabricObjectToolType.DISTANCE_TEXT) {
        const distanceText = object as fabric.Text;
        distanceText.set({
          scaleX: 1 / zoom,
          scaleY: 1 / zoom,
        });
        continue;
      }

      object.set({ strokeWidth: this.objectStrokeWidth / zoom });

      // if (object.type && ANNOTATION_TYPES.includes(object.type as FabricObjectToolType)) {
      //   updateUtilGroupPositionForObject(object);
      // }
    }

    this.updateObjectsUtilGroupPosForObjects();
    this.updateObjectsDistancePosForObjects();
  }

  /**
   * Convert window (web page) coordinates to fabric coordinates
   * Use this function when adding new objects (annotations) to fabric
   * we convert to osd viewport for consistency and precision
   * because we use width ratio in the osd viewport, so we don't need the height when converting
   * @param x in window (web page) coordinate system
   * @param y in window (web page) coordinate system
   * @returns {fabric.Point} in fabric absolute coordinate system
   */
  windowToFabricAbsolute(x: number, y: number): fabric.Point {
    const viewportPos = this.viewer.viewport.windowToViewportCoordinates(
      new OpenSeadragon.Point(x, y)
    );
    return new fabric.Point(
      viewportPos.x * this.containerWidth,
      viewportPos.y * this.containerWidth
    );
  }

  /**
   * Convert fabric coordinates to wsi image coordinates
   * Use this functions when you want to save fabric objects (annotations) coordinates
   * in the wsi image coordinates system
   * @param x in fabric absolute coordinate system
   * @param y in fabric absolute coordinate system
   * @returns {x, y} in wsi image coordinate system
   */
  fabricAbsoluteToImage(x: number, y: number) {
    const viewportX = x / this.containerWidth;
    const viewportY = y / this.containerWidth;
    const imagePos = this.viewer.viewport.viewportToImageCoordinates(
      new OpenSeadragon.Point(viewportX, viewportY)
    );
    return {
      x: imagePos.x,
      y: imagePos.y,
    };
  }

  /**
   * Convert wsi image coordinates to fabric coordinates
   * Use this functions when you want to load fabric objects (annotations) coordinates
   * from the wsi image coordinates system
   * @param x in wsi image coordinate system
   * @param y in wsi image coordinate system
   * @returns {fabric.Point} in fabric absolute coordinate system
   */
  imageToFabricAbsolute(x: number, y: number): fabric.Point {
    const viewportPos = this.viewer.viewport.imageToViewportCoordinates(
      new OpenSeadragon.Point(x, y)
    );
    return new fabric.Point(
      viewportPos.x * this.containerWidth,
      viewportPos.y * this.containerWidth
    );
  }
}
