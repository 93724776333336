/*
 * File: project-setting.page.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 8th July 2021 3:48:43 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { VBPageTitle } from "components/common/vb-page-title.component";
import { VBSpacer } from "components/common/vb-spacer.component";
import { ProjectAPIEndpointsSettings } from "./api-endpoints/project-api-endpoints-settings.page";
import { ProjectGeneralSettings } from "./general/project-general-setting.page";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { ProjectNotificationSettingsPage } from "./notification/project-notification-setting.page";

export const ProjectSettingPage = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <div className="flex items-center my-4">
        <VBPageTitle text="Settings" />
        <VBSpacer />
      </div>

      <Switch>
        <Route path={`${path}/general`} component={ProjectGeneralSettings} />
        <Route
          path={`${path}/notification`}
          component={ProjectNotificationSettingsPage}
        />
        <Route
          path={`${path}/api-endpoints`}
          component={ProjectAPIEndpointsSettings}
        />
      </Switch>
    </div>
  );
};
