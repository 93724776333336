/*
 * File: guest-router.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 13th August 2021 10:54:25 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */
import { Switch } from "react-router-dom";
import { LoadingPage } from "pages/authorization/loading/loading.page";
import { PublicRoute } from "routers/common/public-route";
import { Routes } from "routers/config/routes";
import { ModelWebPage } from "pages/model-web/model-web.page";

export function GuestRouter() {
  return (
    <Switch>
      <PublicRoute
        exact
        path={Routes.MODEL_WEB}
        component={ModelWebPage}
      />
      <PublicRoute path="*" component={LoadingPage} />
    </Switch>
  );
}
