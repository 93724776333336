import { Tooltip } from "@material-ui/core";
import {
  IconToolAdjust,
  IconToolAngle,
  IconToolCross,
  IconToolDistance,
  IconToolDraw,
  IconToolFbts,
  IconToolPaint,
} from "components/common/vb-icon.component";
import { Magicpen } from "iconsax-react";
import { Fragment } from "react";
import { useEffect } from "react";
import { classnames } from "utilities/classes";
import { mathUtils } from "utilities/math";
import { ThreeDEditorFilterFbts } from "./three-d-editor-filter-fbts.component";
import { ThreeDEditorFilterSmoothing } from "./three-d-editor-filter-smoothing.component";
import {
  EditorTool,
  EDITOR_TOOLS,
  TOOL_ADJUST_WINDOW_COLOR_RECTANGLE,
  TOOL_ANGLE,
  TOOL_DISTANCE,
  TOOL_FILL_BETWEEN_SLICES,
  TOOL_NAVIGATION_CROSS_HAIR,
  TOOL_SEGMENT_BRUSH,
  TOOL_SEGMENT_BRUSH_ERASER,
  TOOL_SEGMENT_POLY,
  TOOL_SEGMENT_POLY_ERASER,
  TOOL_SEGMETATION_SMOOTH_EFFECT,
  TOOL_LAYOUT,
  EDITOR_LAYOUTS,
} from "./three-d-editor.models";
import { useThreeDEditorContext } from "./three-d-editor.provider";
import { VscLayout } from "react-icons/vsc";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";
const MIN_BRUSH_RADIUS = 0.1;
const MAX_BRUSH_RADIUS = 20;
const BRUSH_INPUT_STEP = 0.1;
const TOOL_ICONS_MAP = {
  [TOOL_SEGMENT_BRUSH.id]: <IconToolPaint className="w-5 h-5" />,
  [TOOL_SEGMENT_POLY.id]: <IconToolDraw className="w-5 h-5" />,
  [TOOL_SEGMENT_BRUSH_ERASER.id]: <IconToolPaint className="w-5 h-5" />,
  [TOOL_SEGMENT_POLY_ERASER.id]: <IconToolDraw className="w-5 h-5" />,
  [TOOL_NAVIGATION_CROSS_HAIR.id]: <IconToolCross className="w-5 h-5" />,
  [TOOL_ADJUST_WINDOW_COLOR_RECTANGLE.id]: (
    <IconToolAdjust className="w-5 h-5" />
  ),
  [TOOL_DISTANCE.id]: <IconToolDistance className="w-5 h-5" />,
  [TOOL_ANGLE.id]: <IconToolAngle className="w-5 h-5" />,
  [TOOL_FILL_BETWEEN_SLICES.id]: <IconToolFbts className="w-5 h-5" />,
  [TOOL_SEGMETATION_SMOOTH_EFFECT.id]: <Magicpen className="w-5 h-5" />,
  [TOOL_LAYOUT.id]: <VscLayout className="w-5 h-5" />,
};

export const ThreeDEditorToolsComponent = () => {
  const {
    activeLabel,
    activeTool,
    setActiveTool,
    setCrossHairVisibility,
    brushRadius,
    setBrushRadius,
    setCurrentLayout,
  } = useThreeDEditorContext();
  const { openModal, closeModal } = useModalContext();

  const handleSelectLayout = (type: string) => {
    try {
      const layoutId = parseInt(type);
      const layout = EDITOR_LAYOUTS.find((item) => item.id === layoutId);
      if (layout) setCurrentLayout(layout);
    } catch (error) {}
  };

  const handleToolClick = (tool: EditorTool) => {
    if (tool.id === TOOL_LAYOUT.id) {
      openModal(ModalTypes.THREE_D_SELECT_LAYOUT, {
        open: true,
        onClose: closeModal,
        onSubmit: handleSelectLayout,
      });
      return;
    }
    if (activeTool?.id === tool.id) {
      setActiveTool(undefined);
    } else {
      setActiveTool(tool);
    }
  };

  useEffect(() => {
    if (activeTool?.id === TOOL_NAVIGATION_CROSS_HAIR.id) {
      setCrossHairVisibility(true);
    } else {
      setCrossHairVisibility(false);
    }
  }, [activeTool, setCrossHairVisibility]);

  return (
    <div className="flex flex-col gap-2">
      <p className="font-bold">Tools</p>
      <div className="flex flex-wrap items-center gap-1">
        {EDITOR_TOOLS.map((tool) => {
          const shouldBreak = [
            TOOL_SEGMENT_POLY.id,
            TOOL_SEGMENT_POLY_ERASER.id,
            TOOL_ADJUST_WINDOW_COLOR_RECTANGLE.id,
            TOOL_ANGLE.id,
          ].includes(tool.id);
          const disabled = tool.isDrawing && activeLabel.isNone;

          return (
            <Fragment key={tool.id}>
              <button
                className={classnames(
                  "button-warn-secondary disabled:opacity-50",
                  { "button-warn": activeTool?.id === tool.id }
                )}
                onClick={() => handleToolClick(tool)}
                disabled={disabled}
              >
                <Tooltip
                  title={`${
                    tool.tooltipName ? tool.tooltipName : tool.name
                  } (${tool.keyBind.toUpperCase()})`}
                  placement="top"
                >
                  <div className="flex items-center gap-1">
                    {TOOL_ICONS_MAP[tool.id]}
                    <span>{tool.name}</span>
                  </div>
                </Tooltip>
              </button>
              {shouldBreak && <div className="w-full bg-rose-300" />}
            </Fragment>
          );
        })}
      </div>
      {activeTool?.groupId === TOOL_SEGMENT_BRUSH.groupId && (
        <Fragment>
          <hr className="mt-2" />
          <div className="flex items-center w-full gap-2">
            <span className="flex-auto">Radius:</span>
            <input
              className="flex-none w-12 px-1 bg-white border rounded border-background-500 focus:border-primary text-background-700"
              type="number"
              value={brushRadius}
              min={MIN_BRUSH_RADIUS}
              max={MAX_BRUSH_RADIUS}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                if (!value) setBrushRadius(10);
                const clampValue = mathUtils.clamp(
                  value,
                  MIN_BRUSH_RADIUS,
                  MAX_BRUSH_RADIUS
                );
                setBrushRadius(clampValue);
              }}
            />

            <input
              className="w-28"
              type="range"
              min={MIN_BRUSH_RADIUS}
              max={MAX_BRUSH_RADIUS}
              step={BRUSH_INPUT_STEP}
              value={brushRadius}
              onChange={(e) => setBrushRadius(parseFloat(e.target.value))}
            />
          </div>
        </Fragment>
      )}
      <ThreeDEditorFilterFbts />
      <ThreeDEditorFilterSmoothing />
    </div>
  );
};
