/*
 * File: app-setting.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 1st April 2022 5:38:58 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { TFunction } from "react-i18next";
import { RootState } from "store";

export const selectSupportedDatasourceTypes =
  (t: TFunction<"translation", undefined>) => (state: RootState) => {
    return state.appSetting.supportedDatasourceTypes.map((value) => {
      return {
        value,
        label: t(`enum:datasetType.${value}`),
      };
    });
  };

export const selectSupportedProjectTypes = (state: RootState) => {
  return state.appSetting.supportedProjectTypes.map((type) => {
    return {
      value: type.code,
      label: type.name,
      children: [
        ...type.subTypes.map((subType) => {
          return {
            value: subType.code,
            label: subType.name,
          };
        }),
      ],
    };
  });
};
export const selectSupportedProjectSubTypes =
  (projectType: string) => (state: RootState) => {
    const { supportedProjectTypes } = state.appSetting;
    const type = supportedProjectTypes.find(
      (item) => item.code === projectType
    );
    if (!type) return [];
    return type.subTypes.map((subType) => {
      return {
        value: subType.code,
        label: subType.name,
      };
    });
  };

export const selectProjectTypeNames = (state: RootState) => {
  const { supportedProjectTypes } = state.appSetting;
  const names: Record<string, string> = {};
  for (const type of supportedProjectTypes) {
    names[type.code] = type.name;
    for (const child of type.subTypes) {
      names[child.code] = child.name;
    }
  }
  return names;
};

export const selectSupportedDatasourceTypesForProject =
  (projectType: string) => (state: RootState) => {
    const { supportedProjectTypes } = state.appSetting;
    for (let type of supportedProjectTypes) {
      for (let subType of type.subTypes) {
        if (subType.code === projectType) {
          return subType.dataSourceTypes;
        }
      }
    }
    return [];
  };

export const selectProjectTypeByCode = (code: string) => (state: RootState) => {
  const { supportedProjectTypes } = state.appSetting;
  return supportedProjectTypes.find((item) => item.code === code);
};

export const selectProjectSubTypeByCode =
  (code: string) => (state: RootState) => {
    const { supportedProjectTypes } = state.appSetting;
    for (const projectType of supportedProjectTypes) {
      for (const subType of projectType.subTypes) {
        if (subType.code === code) return subType;
      }
    }
    return undefined;
  };

export const selectSystemColors = (state: RootState) => {
  return state.appSetting.supportedSystemColors;
};

export const selectSystemTrackingTime = (state: RootState) => {
  return state.appSetting.trackingTime;
};

export const selectIsUseElasticSearch = (state: RootState) => {
  return state.appSetting.useElasticSearch;
};

export const selectAppConfig = (state: RootState) => {
  return state.appSetting.appConfig;
};
export const selectAppVersion = (state: RootState) => {
  return state.appSetting.version;
};
