/*
 * File: web-preview.page.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 21st February 2022 9:22:20 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */
import { CircularProgress } from "@material-ui/core";
import { IconImport, IconPlayArrow } from "components/common/vb-icon.component";
import { classnames } from "utilities/classes";
import { AIResultGallery } from "../web-builder/components/ai-result-gallery/ai-result-gallery.component";
import { AIResultPreviewEmpty } from "../web-builder/components/ai-result-preview/ai-result-preview-empty.component";
import { ImportFilesButton } from "../web-builder/components/import-images/import-images.component";
import { ModelPreview } from "../web-builder/components/model-preview/model-preview.component";
import { useWebBuilderPreviewContext } from "./context/web-builder-preview.context";

export const MLWebBuilderPreviewPage = () => {
  const {
    canRunModel,
    images,
    onFilesSelected,
    templateMetadata,
    processing,
    runModel,
  } = useWebBuilderPreviewContext();
  return (
    <div
      className={`flex-auto h-screen ${templateMetadata.theme} ${templateMetadata.color}`}
    >
      <div className="h-full p-4 overflow-y-auto lg:p-6 xl:p-10 bg-background-50 dark:bg-background-900 dark:text-white">
        <div className="container mx-auto space-y-10">
          <div className="flex flex-wrap gap-6">
            <ModelPreview
              disabled
              imageUrl={templateMetadata.previewImageUrl}
            />
            <div
              className="flex flex-col space-y-6"
              style={{ minHeight: "15rem" }}
            >
              <div className="flex-auto space-y-4">
                <h1
                  className="max-w-2xl text-xl font-bold lg:text-2xl xl:text-4xl"
                  contentEditable={false}
                  suppressContentEditableWarning
                >
                  {templateMetadata.title}
                </h1>
                <div
                  className="leading-normal"
                  contentEditable={false}
                  suppressContentEditableWarning
                >
                  {templateMetadata.description &&
                    templateMetadata.description
                      .split("\n")
                      .map((s, index) => <div key={s + index}>{s}</div>)}
                </div>
              </div>
              <div className="flex flex-wrap flex-none gap-4">
                <div
                  style={{ minWidth: "114px" }}
                  className="flex items-center justify-center gap-1.5 px-3 h-9 rounded bg-white text-primary border border-primary relative overflow-hidden cursor-pointer"
                >
                  <span
                    contentEditable={false}
                    suppressContentEditableWarning
                    className="select-none focus:outline-none"
                  >
                    {templateMetadata.txtImportData}
                  </span>
                  <IconImport className="w-4 h-4" />
                  <ImportFilesButton onFilesSelected={onFilesSelected} />
                </div>
                <button
                  style={{ minWidth: "114px" }}
                  className={classnames(
                    "flex items-center justify-center gap-1.5 px-3 h-9 rounded",
                    {
                      "bg-background-300 text-white": images.length === 0,
                      "bg-primary text-white border border-primary":
                        images.length > 0,
                      "cursor-not-allowed": !canRunModel,
                    }
                  )}
                  disabled={!canRunModel}
                  onClick={runModel}
                >
                  {processing && <CircularProgress color="inherit" size={16} />}
                  <span
                    contentEditable={false}
                    suppressContentEditableWarning
                    className="focus:outline-none"
                  >
                    {templateMetadata.txtRunModel}
                  </span>
                  <IconPlayArrow className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">
              {templateMetadata.txtCheckResult}
            </h3>
            {images.length === 0 && (
              <div className="flex flex-wrap gap-4">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((idx) => {
                  return <AIResultPreviewEmpty key={idx} />;
                })}
              </div>
            )}
            {images.length > 0 && <AIResultGallery />}
          </div>
        </div>
      </div>
    </div>
  );
};
