/*
 * File: draw-handles.js
 * Project: app-aiscaler-web
 * File Created: Friday, 3rd December 2021 11:04:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import * as csTools from "cornerstone-tools";
import { toolStyle, toolColors } from "cornerstone-tools";
const external = csTools.external;

/**
 * Draws proivded handles to the provided context
 * @public
 * @method drawHandles
 * @memberof Drawing
 *
 * @param {CanvasRenderingContext2D} context - Target context
 * @param {*} evtDetail - Cornerstone's 'cornerstoneimagerendered' event's `detail`
 * @param {Object} rect - Bounding of the measurement
 * @param {Object} [options={}] - Options object
 * @param {string} [options.color]
 * @param {Boolean} [options.drawHandlesIfActive=false] - Whether the handles should only be drawn if Active (hovered/selected)
 * @param {string} [options.fill]
 * @param {Number} [options.handleRadius=6]
 * @returns {undefined}
 */
export default function (context, evtDetail, rect, options = {}) {
  if (!rect) return;
  const { left, top, width, height } = rect;
  const { width: imageWidth, height: imageHeight } = evtDetail.image;

  const poly1 = [
    { x: 0, y: 0 },
    { x: left, y: top },
    { x: left + width, y: top },
    { x: left + width, y: top + height },
    { x: imageWidth, y: imageHeight },
    { x: imageWidth, y: 0 },
  ];

  const poly2 = [
    { x: 0, y: 0 },
    { x: left, y: top },
    { x: left, y: top + height},
    { x: left + width, y: top + height },
    { x: imageWidth, y: imageHeight },
    { x: 0, y: imageHeight },
  ];

  drawPolygon(context, evtDetail.element, poly1, "black");
  drawPolygon(context, evtDetail.element, poly2, "black");
}


function drawPolygon(context, element, points, color, opacity = 0.4) {
  if (!context || !points || points.length === 0) return;
  let start = points[0];
  start = external.cornerstone.pixelToCanvas(element, start);
  points = points.map((p) => external.cornerstone.pixelToCanvas(element, p));
  context.globalAlpha = opacity;
  context.fillStyle = color;
  context.beginPath();
  for (let i = 0; i < points.length; i++) {
    const point = points[i];
    if (i === 0) {
      context.moveTo(point.x, point.y);
    } else {
      context.lineTo(point.x, point.y);
    }
  }
  context.closePath();
  context.fill();
  context.globalAlpha = 1;
}