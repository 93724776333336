import { Backdrop, LinearProgress } from "@material-ui/core";
import { MatModal } from "components/material/mat-modal.component";
import { useAppDispatch } from "hooks/use-redux";
import { BatchDetailDialog } from "pages/labeler/image-labeling/components/batch-detail-dialog/batch-detail-dialog.component";
import { usePreviousBatch } from "pages/labeler/image-labeling/hooks/use-previous-batch.hook";
import { ComplexJobProviderProps } from "pages/labeler/speech-to-text-labeling/context/speech-to-text-labeling.provider";
import { useLoadComplexJob } from "pages/labeler/speech-to-text-labeling/hooks/use-load-complex-job.hook";
import { ConfirmExitDialog } from "pages/labeler/text-labeling/components/confirm-exit-dialog.component";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { AnnotationsService } from "services/label-service";
import {
  AnnotationJobRequestDTO,
  AnnotationRelationRequestDTO,
  WsiAnnotationItemType,
} from "services/label-service/dtos/annotations.dto";
import { jobMapper } from "services/label-service/mappers/job.mapper";
import { RequestStatus } from "store/base/base.state";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import {
  deleteComplexJobsJobInBatch,
  setComplexJobWorkingStatusByJobId,
} from "store/labeler/complex-jobs/complex-jobs.slice";
import { WorkingStatus } from "store/labeler/complex-jobs/complex-jobs.state";
import { LoadCombplexJobResponse } from "store/labeler/complex-jobs/complex-jobs.thunk";
import { AppError } from "utilities/errors/errors";
import { isValidRectangle } from "../components/fabric/fabric-object.utils";
import {
  ANNOTATION_TYPES,
  FabricObjectToolType,
} from "../components/fabric/fabric.models";
import {
  PathologyAnnotationAdditionalData,
  PathologyAnnotationEdge,
} from "../components/pathology-editor.models";
import { PathologyLabelingContext } from "./pathology-labeling.context";
import {
  annoRelationToPathologyEdge,
  annoResponseToFabricObject,
  observationToPathologyEditorLabel,
} from "./pathology-labeling.mappers";
import {
  DEFAULT_UI_JOB,
  PathologyJobUIModel,
  PathologyLabelingState,
} from "./pathology-labeling.state";
import * as Sentry from "@sentry/react";

interface Props extends ComplexJobProviderProps {}

export const PathologyLabelingProvider = ({
  children,
  isLoadFromBatch = false,
  jobInBatch,
  isTaskReview,
  taskToReview,
  jobIdsIncludedOnly,
  isFromJobId,
  jobIdParam,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const history = useHistory();
  const [confirmExit, setShowConfirmExit] = useState(false);
  const handleConfirmExit = () => setShowConfirmExit(true);

  const [isEditorReady, setIsEditorReady] = useState(false);

  const uiJobRef = useRef<PathologyJobUIModel>();
  const { showBatchInstruction, setShowBatchInstruction } = usePreviousBatch(
    uiJobRef.current?.job ? jobMapper.fromDTO(uiJobRef.current.job) : undefined
  );
  const [hasChanged, setHasChanged] = useState(true);

  const hasWorkingIssues = false;

  const loadJobFromComplexRes = useCallback(
    async (jobComplexRes: LoadCombplexJobResponse, uiJobData?: any) => {
      setIsEditorReady(false);
      if (uiJobData) {
        uiJobRef.current = {
          ...uiJobData,
          isFirstLoad: false,
        };
        setHasChanged(true);
        return;
      }

      const {
        wsiUrl,
        job,
        isStepReviewJob,
        canViewPreviousStepResult,
        file,
        task,
        batch,
        annotations,
        previousJobsAnnotations,
        annotationRelations,
        jobOptions,
        batchObservations,
        acceptBatchObservation,
        rejectBatchObservation,
        reopenReason,
        isTaskReview,
        isFromJobId,
        isFromBatch,
        error,
      } = jobComplexRes;

      if (error) {
        throw error;
      }

      if (!file || !task || !batch || !wsiUrl) {
        throw new AppError(
          "unknow",
          "Unknow error after calling loadJobComplex function"
        );
      }

      // convert observations to editor labels
      const observations = batchObservations.map((bo) => bo.observation);
      const labels = observations.map(observationToPathologyEditorLabel);

      // convert annotations to fabric objects
      const initFabricObjects: fabric.Object[] = [];
      for (const annoResponse of [...previousJobsAnnotations, ...annotations]) {
        const fabricObject = annoResponseToFabricObject(
          annoResponse,
          observations
        );
        if (fabricObject) {
          initFabricObjects.push(fabricObject);
        }
      }

      // convert annotation relations to edges
      const initEdges: PathologyAnnotationEdge[] = annotationRelations
        .map(annoRelationToPathologyEdge)
        .filter((e) => e !== undefined) as PathologyAnnotationEdge[];

      // Convert labeler options to editor labelers
      let editorCurrentLabeler = undefined;
      if (job) {
        editorCurrentLabeler = { id: job.assignee, jobId: job.id };
      }
      const editorOtherLabelers = jobOptions.map((jo) => ({
        id: jo.assignee,
        jobId: jo.jobId,
      }));

      let uiJobCommon: PathologyJobUIModel = {
        ...DEFAULT_UI_JOB,
        wsiUrl,
        originalWsiUrl: wsiUrl,
        labels,
        initFabricObjects,
        initEdges,
        editorCurrentLabeler,
        editorOtherLabelers,

        job,
        isStepReviewJob,
        canViewPreviousStepResult,
        task,
        batch,
        project: batch.project,
        countDownSecond: 0,
        jobOptions,
        acceptBatchObservation,
        rejectBatchObservation,
        reopenReason,
        isTaskReview,
        isFromJobId,
        isFromBatch,
      };

      uiJobRef.current = uiJobCommon;
    },
    []
  );

  const {
    loadJobStatus,
    savingJobStatus,
    setSavingJobStatus,
    isSavingJob,
    isJobLoaded,
    error,
    setError,
    skipJob,
  } = useLoadComplexJob({
    providerPayload: {
      isLoadFromBatch,
      jobInBatch,
      isTaskReview,
      taskToReview,
      jobIdsIncludedOnly,
      isFromJobId,
      jobIdParam,
    },
    loadJobFromComplexResCallback: loadJobFromComplexRes,
  });
  const isLoadingJob = useMemo(
    () => loadJobStatus === RequestStatus.LOADING || !isEditorReady,
    [loadJobStatus, isEditorReady]
  );

  const serializeUIJob = useCallback(
    (
      uiJob: PathologyJobUIModel,
      accept = false,
      reject = false
    ): AnnotationJobRequestDTO | undefined => {
      const fabricOverlay = uiJob.fabricOverlay;
      if (!fabricOverlay) return undefined;

      const payload: AnnotationJobRequestDTO = {
        annotations: [],
      };
      // to save the hierarychy tree edges
      // first node is the parent
      const edges: AnnotationRelationRequestDTO[] = [];

      // Use this to map uuid to unique number
      const uuidToNumber: Record<string, number> = {};
      let nextUuidNumber = 1;
      const getUniqueNumberFromUuid = (uuid: string) => {
        if (uuidToNumber[uuid]) return uuidToNumber[uuid];
        uuidToNumber[uuid] = nextUuidNumber;
        nextUuidNumber++;
        return uuidToNumber[uuid];
      };

      if (accept) {
        payload.annotations.push({
          observationId: uiJob.acceptBatchObservation?.observation.id,
        });
      } else if (reject) {
        payload.annotations.push({
          observationId: uiJob.rejectBatchObservation?.observation.id,
        });
      }
      // No need to save objects when accept or reject
      if (payload.annotations.length > 0) return payload;

      for (const object of fabricOverlay.fabricCanvas.getObjects()) {
        // Only save annotation type object
        if (
          !object.type ||
          (object.type &&
            !ANNOTATION_TYPES.includes(object.type as FabricObjectToolType))
        )
          continue;

        // TODO: support filter by source later
        const objectData = object.data as PathologyAnnotationAdditionalData;
        if (!objectData || !objectData.labelId) continue;

        // We only save objects from the current labeler
        if (uiJob.job && uiJob.job.assignee !== objectData.labeler) continue;

        const observationId = objectData.labelId;
        // We only save edges for object that got saved
        let needAddEdges = false;

        // Convert polygon
        if (object.type === FabricObjectToolType.DRAWN_POLYGON) {
          const polygon = object as fabric.Polygon;
          if (!polygon.points || polygon.points.length < 3) continue;
          payload.annotations.push({
            localId: getUniqueNumberFromUuid(object.data?.uuid),
            observationId,
            annotation: {
              type: WsiAnnotationItemType.polygon,
              points: polygon.points.map((p) =>
                fabricOverlay.fabricAbsoluteToImage(p.x, p.y)
              ),
            },
          });
          needAddEdges = true;
        }
        // Convert rectangle
        if (object.type === FabricObjectToolType.DRAWN_RECTANGLE) {
          const rectangle = object as fabric.Rect;
          if (!isValidRectangle(rectangle)) continue;
          const imagePos = fabricOverlay.fabricAbsoluteToImage(
            rectangle.left as number,
            rectangle.top as number
          );
          const width =
            (rectangle.width as number) * (rectangle.scaleX as number);
          const height =
            (rectangle.height as number) * (rectangle.scaleY as number);
          const imageWidthHeight = fabricOverlay.fabricAbsoluteToImage(
            width,
            height
          );

          payload.annotations.push({
            localId: getUniqueNumberFromUuid(object.data?.uuid),
            observationId,
            annotation: {
              type: WsiAnnotationItemType.bbox,
              points: [
                { x: imagePos.x, y: imagePos.y },
                { x: imagePos.x + imageWidthHeight.x, y: imagePos.y },
                {
                  x: imagePos.x + imageWidthHeight.x,
                  y: imagePos.y + imageWidthHeight.y,
                },
                { x: imagePos.x, y: imagePos.y + imageWidthHeight.y },
              ],
            },
          });
          needAddEdges = true;
        }

        // Add edges if needed
        if (needAddEdges) {
          if (!object.data.children) continue;
          for (const child of object.data.children) {
            edges.push({
              directed: true,
              firstLocalId: getUniqueNumberFromUuid(object.data.uuid),
              secondLocalId: getUniqueNumberFromUuid(child.data.uuid),
            });
          }
        }

        // Convert line
        if (object.type === FabricObjectToolType.DRAWN_LINE) {
          const line = object as fabric.Line;
          if (!line.x1 || !line.x2 || !line.y1 || !line.y2) return;

          const points = [
            { x: line.x1, y: line.y1 },
            { x: line.x2, y: line.y2 },
          ];

          payload.annotations.push({
            observationId,
            annotation: {
              type: WsiAnnotationItemType.line,
              points: points.map((p) =>
                fabricOverlay.fabricAbsoluteToImage(p.x, p.y)
              ),
            },
          });
        }
      }
      payload.annotationRelations = edges;

      return payload;
    },
    []
  );

  const saveJob = useCallback(
    async (
      force = false,
      finish = false,
      accept = false,
      reject = false,
      jobId = 0
    ) => {
      if (savingJobStatus === RequestStatus.LOADING) return;
      if (savingJobStatus !== RequestStatus.IDLE && !force) return;
      if (!uiJobRef.current || !uiJobRef.current.fabricOverlay) return;

      const uiJob = uiJobRef.current;
      const id = jobId || uiJob.job?.id;

      setSavingJobStatus(RequestStatus.LOADING);

      try {
        const payload: AnnotationJobRequestDTO | undefined = serializeUIJob(
          uiJob,
          accept,
          reject
        );
        if (!payload) {
          dispatch(enqueueErrorNotification(t("common:textFailed")));
          setSavingJobStatus(RequestStatus.FAILURE);
        }

        if (finish) {
          if (accept && uiJob.isStepReviewJob && hasWorkingIssues) {
            throw new AppError(
              "have_working_issues",
              t("labelerworkspace:stt.errorHaveWorkingIssues")
            );
          }

          await AnnotationsService.saveAnnotationsForJobId(
            id,
            payload as AnnotationJobRequestDTO
          );
          await AnnotationsService.finishJob(id);

          if (uiJob.job && uiJob.isFromBatch) {
            dispatch(deleteComplexJobsJobInBatch(id));
          } else if (uiJob.isFromJobId) {
            history.push(Routes.LABELER_HOME);
          }
        } else {
          await AnnotationsService.saveAnnotationsForJobId(
            id,
            payload as AnnotationJobRequestDTO
          );
        }

        setSavingJobStatus(RequestStatus.SUCCESS);
        setHasChanged(false);
        dispatch(
          setComplexJobWorkingStatusByJobId({
            jobId: id,
            status: WorkingStatus.SAVED,
          })
        );

        if (finish) {
          dispatch(enqueueSuccessNotification(t("common:textCompleted")));
        } else {
          dispatch(enqueueSuccessNotification(t("common:textSaved")));
        }

        setSavingJobStatus(RequestStatus.SUCCESS);
      } catch (err: any) {
        Sentry.captureException(err);
        setSavingJobStatus(RequestStatus.FAILURE);
        if (err instanceof AppError) {
          dispatch(enqueueErrorNotification(err.message));
        } else {
          if (finish) {
            dispatch(enqueueErrorNotification(t("common:textCompletedFailed")));
          } else {
            dispatch(enqueueErrorNotification(t("common:textSavedFailed")));
          }
        }
      }
    },
    [
      savingJobStatus,
      setSavingJobStatus,
      serializeUIJob,
      dispatch,
      t,
      hasWorkingIssues,
      history,
    ]
  );

  const state: PathologyLabelingState = {
    uiJobRef,
    isLoadingJob,
    isSavingJob,
    saveJob,
    skipJob,
    handleConfirmExit,
    error,
    setError,
    hasChanged,
    setHasChanged,
    setIsEditorReady,
  };

  return (
    <PathologyLabelingContext.Provider value={state}>
      {children}
      {(isLoadingJob || isSavingJob || !isEditorReady) && <MaskLoading />}
      {confirmExit && (
        <ConfirmExitDialog
          visible
          onClose={() => setShowConfirmExit(false)}
          onSubmit={() => history.push(Routes.LABELER_HOME)}
        />
      )}
      {isJobLoaded &&
        showBatchInstruction &&
        !isTaskReview &&
        uiJobRef.current?.batch &&
        uiJobRef.current.project && (
          <BatchDetailDialog
            batch={uiJobRef.current.batch}
            project={uiJobRef.current.project}
            onClose={() => setShowBatchInstruction(false)}
          />
        )}
    </PathologyLabelingContext.Provider>
  );
};

const MaskLoading = () => {
  return (
    <MatModal
      open
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      className="flex items-start justify-center"
    >
      <div className="w-full h-full">
        <LinearProgress />
        <div className="flex items-center justify-center w-full h-full"></div>
      </div>
    </MatModal>
  );
};
