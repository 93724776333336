/*
 * File: workspace-users.state.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 15th November 2021 6:48:11 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { User } from "domain/customer";
import { GroupDTO } from "services/user-service/dtos/group.dto";

export interface WorkspaceUsersState {
  requesting: boolean;
  workspace: GroupDTO | undefined;
  users: User[];
  totalUsers: number;
  filter: UserFilter;
  setFilter(filter: UserFilter): void;
  setPage(page: number): void;
  setPageSize(size: number): void;
  setSort(field: string, sort?: string): void;
  setName(name: string): void;
  setEmail(email: string): void;
  setRole(role: string): void;
  setStatus(status: string): void;
  setLastUpdate(lastUpdate: string): void;
  setLastLogin(lastLogin: string): void;
  setRequesting(requesting: boolean): void;
}

export interface UserFilter extends Record<string, string> {
  email: string;
  name: string;
  role: string;
  lastUpdate: string;
  lastLogin: string;
  status: string;
  page: string;
  pageSize: string;
  sort: string;
}

export const defaultWorkspaceUsersState = {} as WorkspaceUsersState;
