/*
 * File: ai-models.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 18th February 2022 2:59:40 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AIModelsState } from "./ai-models.state";
import {
  requestPending,
  requestRejected,
  requestFulfilled,
  setEntites,
  removeEntity,
} from "store/base/base.reducer";
import { ModelAIsService } from "./../../../services/label-service/index";
import { AIModelDTO } from "./../../../services/label-service/dtos/ai-model.dto";

export enum AIModelsThunk {
  LOAD_AI_MODELS = "aiModels/loadAIModelsAsync",
  DELETE_AI_MODEL = "aiModels/deleteAIModelAsync",
  CREATE_AI_MODEL = "aiModels/createAIModelAsync",
}

export const loadAIModelsAsync = createAsyncThunk(
  AIModelsThunk.LOAD_AI_MODELS,
  async () => {
    const data: AIModelDTO[] =
      (await ModelAIsService.getModelAIs()) as AIModelDTO[];
    return data;
  }
);

export const createAIModelsAsync = createAsyncThunk(
  AIModelsThunk.CREATE_AI_MODEL,
  async (payload: Partial<AIModelDTO>) => {
    const res = await ModelAIsService.createModelAI(payload);
    return res.data;
  }
);

export const deleteAIModelAsync = createAsyncThunk(
  AIModelsThunk.DELETE_AI_MODEL,
  async (aiModelId: number) => {
    await ModelAIsService.deleteModelAI(aiModelId);
    return aiModelId;
  }
);

export const aiModelsReducerBuilder = (
  builder: ActionReducerMapBuilder<AIModelsState>
): ActionReducerMapBuilder<AIModelsState> => {
  return builder
    .addCase(loadAIModelsAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(loadAIModelsAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      setEntites(state, action.payload);
    })
    .addCase(loadAIModelsAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(deleteAIModelAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(deleteAIModelAsync.fulfilled, (state, action) => {
      removeEntity(state, action.payload);
    })
    .addCase(deleteAIModelAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    });
};
