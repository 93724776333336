/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 3rd August 2022 10:43:55 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

function clamp(value: number, min: number, max: number) {
  if (value < min) return min;
  if (value > max) return max;
  return value;
}

function clip(value: number, min: number, max: number) {
  let currentValue = value;
  while (currentValue < min) {
    currentValue += max - min;
  }
  while (currentValue > max) {
    currentValue -= max - min;
  }
  return clamp(currentValue, min, max);
}

export const mathUtils = { clamp, clip };
