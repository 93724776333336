/*
 * File: task-runner.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 23rd March 2022 9:58:54 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { TaskRunnerStatus, TaskRunnerType } from "domain/customer/task-runner";
import { RootState } from "store";
import validator from "validator";
export const selectRunningTasks = (state: RootState) => {
  return state.taskRunner.tasks;
};

export const selectIsUploadedZip =
  (datasetId: string) => (state: RootState) => {
    if (validator.isEmpty(datasetId)) return false;
    const task = state.taskRunner.tasks.find(
      (task) =>
        task.status === TaskRunnerStatus.SUCCEEDED &&
        task.jobType === TaskRunnerType.ARCHIVE_UPLOAD
    );
    if (task) {
      const payload = JSON.parse(task.payload);
      if (payload && payload.hasOwnProperty("datasetId")) {
        const id = parseInt(payload["datasetId"]);
        return id === parseInt(datasetId);
      }
    }
    return false;
  };
