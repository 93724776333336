/*
 * File: image-task-viewer.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 9th August 2021 11:04:40 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useRef, useEffect } from "react";
import * as cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import initCornerstone from "components/dicom/cornerstone/cornerstone.config";
import { ToolName } from "../dicom-tools/dicom-tools.model";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  selectActiveCornerstoneTool,
  selectAnnotationTools,
} from "store/labeler/image-workspace/dicom-editor/dicom-editor.selectors";
import FreehandRoiToolExtend from "components/dicom/cornerstone/tools/FreehandRoiExtend";
import RectangleRoiExtendTool from "components/dicom/cornerstone/tools/RectangleRoiExtend";
import HeartRuler from "components/dicom/cornerstone/tools/HeartRuler";
import EraserTool from "../cornerstone/tools/EraserToolExtend";
import { useImageLabelingContext } from "pages/labeler/image-labeling/context/image-labeling.context";
import {
  selectCurrentObservation,
  selectMaskedAnnotations,
} from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { CornerstoneViewportEditor } from "../cornerstone/viewport-editor/viewport-editor";
import { CornerstoneHandler } from "../cornerstone/cornerstone-handler/cornerstone-handler";
import { selectBaseTools } from "store/labeler/image-workspace/cornerstone/cornerstone.selectors";
import MaskTool from "../cornerstone/tools/MaskTool";
import { ImageJobInfo } from "./image-job-info.component";
import { ImageViewer } from "../image-viewer/image-viewer";
import { useSize } from "ahooks";
import { IssueManagementProvider } from "pages/customer/projects/project-batch/batch-detail/pages/tasks/components/issue/issue-management.provider";
import { useImageEditorContext } from "pages/labeler/image-labeling/image-editor-context/image-editor.context";
import { useCornerstoneHandler } from "pages/labeler/image-labeling/hooks/use-cornerstone-handler";
import { useKeyboardShortcuts } from "pages/labeler/image-labeling/hooks/use-keyboard-shortcuts.hook";
import { classnames } from "utilities/classes";
import { selectIsShowTaskInfo } from "store/labeler/image-workspace/editor-setting/editor-setting.selectors";
import { AnnotationAttributeMenu } from "pages/labeler/image-labeling/components/annotation-attribute-menu/annotation-attribute-menu.component";
import { useBatchLabelingContext } from "pages/labeler/batch-labeling/context/batch-labeling.context";
import MultiArrowConnectionTool from "../cornerstone/tools/MultiArrowConnectionTool";
import { getBreastDicomTagsDataForDisplay } from "utilities/dicom/dicom.utils";
import { AnnotationContextMenu } from "pages/labeler/image-labeling/components/annotation-context-menu/annotation-context-menu.component";
import { AnnotationClassMenu } from "pages/labeler/image-labeling/components/annotation-class-menu/annotation-class-menu.component";
import { useSynchronizingTool } from "pages/labeler/image-labeling/hooks/use-synchronize-tool";
import { setImageLabelingError } from "store/labeler/image-workspace/image-workspace.slice";
import useAnnotationContextMenu from "./use-annotation-context-menu.hook";
import useImageTaskAnnotations from "./use-image-task-annotations.hook";
import { selectTaskFileData } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { AppEvents } from "constants/annotation.constant";
import { CornerstoneEvents } from "../cornerstone/models/cornerstone-events.model";
import { selectMammographyLabelingTaskSelectedJobIds } from "store/labeler/mammography-labeling-task/mammography-labeling-task.selectors";
import WandTool from "../cornerstone/tools/SAMTool";

initCornerstone();

interface Props {
  taskId: number;
  active?: boolean;
  jobIndex: number;
  onImageLoaded?: (jobId: number) => void;
}
export const ImageTaskViewer = ({
  taskId,
  active,
  jobIndex,
  onImageLoaded,
}: Props) => {
  const dispatch = useAppDispatch();
  const { viewportEditor, cornerstoneHandler } = useImageEditorContext();
  const { currentViewportEditor } = useBatchLabelingContext();
  const { imageLoaded, setImageLoaded } = useImageLabelingContext();
  const activeTool = useAppSelector(selectActiveCornerstoneTool);
  const elementRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const viewportWrapperRef = useRef<HTMLDivElement>(null);
  const selectedJobIds = useAppSelector(
    selectMammographyLabelingTaskSelectedJobIds
  );

  const initialized = useRef(false);
  const observation = useAppSelector(selectCurrentObservation);
  const baseTools = useAppSelector(selectBaseTools);
  const annotateTools = useAppSelector(selectAnnotationTools);
  const maskedAnnotations = useAppSelector(selectMaskedAnnotations);
  const isShowTaskInfo = useAppSelector(selectIsShowTaskInfo);
  const containerSize = useSize(containerRef);
  useCornerstoneHandler(active);
  useSynchronizingTool(active);
  const labelingJob = useAppSelector(selectTaskFileData(taskId));
  const { labelingTask, annotations, relations } =
    useImageTaskAnnotations(taskId);
  const {
    annotationAttributeMenu,
    annotationContextMenu,
    annotationClassMenu,
    onViewportContextMenu,
    handleCloseAnnotationClassMenu,
    handleCloseAnnotationContextMenu,
    imageAnnotationContextMenuSelected,
    imageAnnotationClassMenuSelected,
  } = useAnnotationContextMenu(labelingJob?.id || -1);

  function handleImageLoaded(imageId?: string) {
    cornerstoneHandler.current?.setCurrentImageId(imageId);
    initCornerstoneTools();
    initCornerstoneEvents();
    initHeartRuler();
    setImageLoaded(true);
    onImageLoaded && onImageLoaded(taskId);
  }

  function handleImageLoadError(error: any) {
    const errMessage = error?.message || "Failed to load image";
    dispatch(setImageLabelingError(errMessage));
    setImageLoaded(true);
  }

  function initCornerstoneTools() {
    if (!elementRef.current) return;
    clearAllToolState();
    if (initialized.current) return;
    cornerstoneTools.init({ showSVGCursors: true });
    addToolsForElement();
    const container = elementRef.current;
    const viewport = cornerstone.getViewport(elementRef.current);

    viewportEditor.current = new CornerstoneViewportEditor(container, viewport);
    if (active) currentViewportEditor.current = viewportEditor.current;
    cornerstoneHandler.current = new CornerstoneHandler(container, viewport);
    initialized.current = true;
    initToolState();
    cornerstoneHandler.current.setToolActive(activeTool);
  }

  function clearAllToolState() {
    const container = elementRef.current;
    if (!container) return;
    for (let tool of baseTools) {
      const csTool = cornerstoneTools[`${tool.type}Tool`];
      cornerstoneTools.clearToolState(container, csTool);
    }
    cornerstoneTools.clearToolState(container, ToolName.FreehandRoiExtend);
    cornerstoneTools.clearToolState(container, ToolName.RectangleRoiExtend);
    cornerstoneTools.clearToolState(container, ToolName.MultiArrowConnection);
    cornerstoneTools.clearToolState(container, ToolName.Mask);
  }

  function addToolsForElement() {
    const container = elementRef.current;
    if (!container) return;
    const tools = baseTools.concat(annotateTools);
    cornerstoneTools.addToolForElement(container, MaskTool);
    cornerstoneTools.addToolForElement(
      container,
      cornerstoneTools["ZoomMouseWheelTool"],
      {
        name: "ZoomMouseWheel",
        supportedInteractionTypes: ["MouseWheel"],
        configuration: {
          minScale: 0.1,
          maxScale: 20.0,
          invert: false,
        },
      }
    );
    for (let tool of tools) {
      if (tool.type === ToolName.MultiArrowConnection) {
        cornerstoneTools.addToolForElement(container, MultiArrowConnectionTool);
      } else if (tool.type === ToolName.FreehandRoiExtend) {
        cornerstoneTools.addToolForElement(container, FreehandRoiToolExtend);
      } else if (tool.type === ToolName.RectangleRoiExtend) {
        cornerstoneTools.addToolForElement(container, RectangleRoiExtendTool);
      } else if (tool.type === ToolName.SAM) {
        cornerstoneTools.addToolForElement(container, WandTool);
      } else if (tool.type === ToolName.HeartRuler) {
        cornerstoneTools.addToolForElement(container, HeartRuler);
      } else if (tool.type === ToolName.Eraser) {
        cornerstoneTools.addToolForElement(container, EraserTool);
      } else if (tool.type === ToolName.StackScrollMouseWheel) {
        cornerstoneTools.addToolForElement(
          container,
          cornerstoneTools.StackScrollMouseWheelTool,
          {
            configuration: {
              loop: true,
              allowSkipping: true,
            },
          }
        );
      } else {
        const csTool = cornerstoneTools[`${tool.type}Tool`];
        cornerstoneTools.addToolForElement(container, csTool);
      }
    }
  }

  function initToolState() {
    cornerstoneHandler.current?.setMaskedAnnotations(maskedAnnotations);
    cornerstoneHandler.current?.setImageAnnotations(annotations);
    cornerstoneHandler.current?.setImageRelationAnnotations(relations);
  }

  function initCornerstoneEvents() {
    const onImageRender = async (event: any) => {
      if (!labelingJob?.file) return;
      const customEvent = new CustomEvent(AppEvents.IMAGE_RENDERED_BY_FILE, {
        detail: {
          fileId: labelingJob.file.id,
          canvasElement: elementRef.current?.firstChild,
        },
      });
      document.dispatchEvent(customEvent);
    };

    elementRef.current?.addEventListener(
      CornerstoneEvents.IMAGE_RENDERED,
      onImageRender
    );
  }

  function initHeartRuler() {
    if (!elementRef.current) return;
    const heartRuler = cornerstoneHandler.current?.getTool(ToolName.HeartRuler);
    if (!heartRuler) return;
    heartRuler.initData();
    cornerstoneHandler.current?.updateImage();
  }

  useEffect(() => {
    cornerstoneHandler.current?.resize(false);
  }, [containerSize, cornerstoneHandler]);

  useEffect(() => {
    cornerstoneHandler.current?.setCurrentObservation(observation);
  }, [cornerstoneHandler, observation]);

  useEffect(() => {
    if (active) {
      currentViewportEditor.current = viewportEditor.current;
    }
  }, [active, currentViewportEditor, viewportEditor]);

  return (
    <div
      ref={containerRef}
      className={classnames(
        "relative flex-auto w-full h-full animate-fade-in overflow-hidden",
        { "pointer-events-none": !active }
      )}
    >
      <div
        ref={viewportWrapperRef}
        onContextMenu={onViewportContextMenu}
        className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
      >
        {labelingJob && (
          <ImageViewer
            tags={getBreastDicomTagsDataForDisplay(labelingJob?.dicomData)}
            ref={elementRef}
            file={labelingJob?.file}
            active={active}
            onLoaded={handleImageLoaded}
            onError={handleImageLoadError}
          />
        )}
      </div>
      {imageLoaded && labelingJob && labelingTask && (
        <IssueManagementProvider
          taskId={taskId}
          cornerstoneElement={elementRef.current}
          jobOptions={labelingTask?.jobs?.map((j) => ({
            jobId: j.id,
            assignee: j.assignee,
          }))}
          jobIds={selectedJobIds}
        />
      )}
      {active && annotationAttributeMenu && (
        <AnnotationAttributeMenu annotation={annotationAttributeMenu} />
      )}
      {active && annotationContextMenu && (
        <AnnotationContextMenu
          annotation={annotationContextMenu.annotation}
          options={annotationContextMenu.options}
          onClose={handleCloseAnnotationContextMenu}
          onSelect={imageAnnotationContextMenuSelected}
        />
      )}
      {active && annotationClassMenu && (
        <AnnotationClassMenu
          annotation={annotationClassMenu.annotation}
          onClose={handleCloseAnnotationClassMenu}
          labels={annotationClassMenu.availableClasses}
          onSelect={imageAnnotationClassMenuSelected}
        />
      )}
      {labelingTask && (
        <ImageJobInfo
          taskId={taskId}
          batchId={labelingTask.batch?.id || -1}
          projectId={labelingTask.project?.id || -1}
          showTaskInfo={isShowTaskInfo}
          showDicomdata={true}
          dicomTagsData={getBreastDicomTagsDataForDisplay(
            labelingTask.dicomData
          )}
          dicomTagsDataClasses="text-lg"
        />
      )}
      {active && <KeyboardShortcuts />}
      {jobIndex && <JobTag jobIndex={jobIndex} />}
    </div>
  );
};

function KeyboardShortcuts() {
  useKeyboardShortcuts();
  return null;
}

function JobTag({ jobIndex }: { jobIndex: number }) {
  return (
    <span className="absolute text-sm font-semibold left-1 bottom-3 text-warning-500">
      [{jobIndex}]
    </span>
  );
}
