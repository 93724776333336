/*
 * File: batch-detail.state.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 6th January 2022 10:04:57 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  BatchDTO,
  BatchObservationDTO,
  BatchWorkflowDTO,
  ProjectDTO,
  WorkflowInstructionDTO,
} from "services/label-service/dtos";
import { PricingResponseDTO } from "services/label-service/dtos/batch-pricing.dto";
import { WorkManagementDTO } from "services/label-service/dtos/work-management.dto";
import { UserBatchModel } from "services/user-service/dtos/user.dto";
import { AtLeast } from "types/common";

export interface BatchDetailState {
  labels: BatchObservationDTO[];
  batch: BatchDTO;
  project: ProjectDTO | null;
  canStartBatch: boolean;
  startBatch(): void;
  requesting: boolean;
  setRequesting(requesting: boolean): void;
  hasStepReview: boolean;
  onClickEditBatch(): void;

  workflow: BatchWorkflowDTO | null;
  batchInstructions: WorkflowInstructionDTO[];
  pricing: PricingResponseDTO | null;
  stepLabelers: Record<number, AtLeast<UserBatchModel, "email">[]>;
  workManagements: WorkManagementDTO[];
}

export const defaultBatchDetailState = {} as BatchDetailState;
