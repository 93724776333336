/*
 * File: use-labeler-tasks.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 22nd May 2023 1:53:04 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useQuery, useQueryClient } from "react-query";
import { ILabelerTaskFilter } from "../types";
import { labelerTasksApi } from "../api/labeler-tasks.api";

const QUERY_KEY = "LABELER_TASKS";

export function useLabelerTasks(filter: ILabelerTaskFilter) {
  const client = useQueryClient();

  function requestData() {
    return labelerTasksApi.getLabelerTasks(filter);
  }

  const requestOptions = { refetchOnWindowFocus: false };

  const { data, error, isFetching } = useQuery(
    [QUERY_KEY, filter],
    requestData,
    requestOptions
  );

  function reloadData() {
    client.refetchQueries([QUERY_KEY]);
  }

  return {
    data: data?.data ?? [],
    loading: isFetching,
    pagination: data?.pagination ?? {
      page: 1,
      size: 10,
      totalCount: 0,
    },
    error,
    reloadData,
  };
}
