/*
 * File: KeyboardKey.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 3rd July 2021 5:28:39 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export enum KeyboardKey {
  Space = " ",
  Enter = "Enter",
  Escape = "Escape",
  Delete = "Delete",
  Backspace = "Backspace",
  Control = "Control",
  Alt = "Alt",
  Meta = "Meta", // command key on Mac

  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  ArrowDown = "ArrowDown",
  ArrowUp = "ArrowUp",

  Home = "Home",
  End = "End",
  PageUp = "PageUp",
  PageDown = "PageDown",

  Tab = "Tab",

  A = "a",
  P = "p",
  Z = "z",
  V = "v",
  R = "r",
  E = "e",
  W = "w",
  M = "m",
  L = "l",
  S = "s",
}
