/*
 * File: vb-datagrid-input-filter.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th September 2021 11:15:30 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from 'react-i18next';

interface Props {
  onInput(value: string): void;
  defaultValue: string;
}

export const VBDatagridInputFilter = ({ onInput, defaultValue }: Props) => {
  const { t } = useTranslation();
  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      onInput(e.currentTarget.value);
    }
  };
  return (
    <input
      className="w-full h-6 px-2 py-4 border rounded focus:border-primary"
      onClick={(e) => e.stopPropagation()}
      onKeyDown={handleKeyDown}
      defaultValue={defaultValue}
      onBlur={(e) => onInput(e.target.value)}
      placeholder={t('common:placeholderFilter')}
    />
  );
};
