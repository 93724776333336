/*
 * File: text-labeling-batch.state.ts
 * Project: app-aiscaler-web
 * File Created: Sunday, 10th July 2022 10:00:42 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Collection, defaultCollection } from "domain/common";
import { LabelingJob } from "domain/labeling/labeling-job";
import { Project } from "domain/labeling/project";
import { RequestStatus } from "store/base/base.state";

export interface LabelingBatchState {
  project?: Project;
  jobs: Collection<LabelingJob>;
  selectedJobId: number;
  jobLoadingStatus: { [key: number]: RequestStatus };
  jobWorkingStatus: { [key: number]: WorkingStatus };
  pollJobStatus: RequestStatus;
}

export const defaultLabelingBatchState: LabelingBatchState = {
  project: undefined,
  jobs: { ...defaultCollection },
  jobLoadingStatus: {},
  jobWorkingStatus: {},
  selectedJobId: -1,
  pollJobStatus: RequestStatus.IDLE,
};

export enum WorkingStatus {
  NONE = "none",
  WORKING = "working",
  SAVED = "saved",
}
