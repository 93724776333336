/*
 * File: use-template-filter.hook.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 25th July 2022 10:36:28 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ProjectTemplate } from "domain/labeling/project-template";
import { useMemo, useState } from "react";

const GROUPS = ["Image", "Medical Image", "Text", "Audio", "Video"];

export default function useProjectTemplateFilter(
  templates: ProjectTemplate[] = [],
  search: string = ""
) {
  const options = useMemo(() => {
    const searchStr = search.toLowerCase();
    const searchedTemplates = templates.filter((template) => {
      if (!searchStr) return true;
      return template?.title?.toLowerCase().includes(searchStr);
    });
    const groups: string[] = [];
    for (const template of templates) {
      if (!template.group) continue;
      if (groups.includes(template.group)) continue;
      groups.push(template.group);
    }
    groups.sort((a, b) => {
      const aOrder = GROUPS.includes(a) ? GROUPS.indexOf(a) : GROUPS.length + 1;
      const bOrder = GROUPS.includes(b) ? GROUPS.indexOf(b) : GROUPS.length + 1;
      return aOrder - bOrder;
    });
    return [
      { value: "all", label: `All (${searchedTemplates.length})` },
      ...groups.map((group) => {
        return {
          value: group,
          label: `${group} (${
            searchedTemplates.filter((template) => template.group === group)
              .length
          })`,
        };
      }),
    ];
  }, [templates, search]);

  const [type, setType] = useState("all");

  function selectType(value: string) {
    setType(value);
  }
  const filteredTemplates = useMemo(() => {
    const searchStr = search.toLowerCase();
    const searchedTemplates = templates.filter((template) => {
      if (!searchStr) return true;
      return template.title.toLowerCase().includes(searchStr);
    });
    if (!type || type === "all") return [...searchedTemplates];
    return searchedTemplates.filter((template) => template.group === type);
  }, [type, search, templates]);

  return {
    filteredTemplates,
    options,
    type,
    selectType,
  };
}
