/*
 * File: navigation-expander.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 17th December 2021 9:33:57 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  IconArrowRight,
  IconListAlt,
} from "components/common/vb-icon.component";

interface Props {
  showNavigation: boolean;
  onToggle?(): void;
}
export const NavigationExpander = ({ showNavigation, onToggle }: Props) => {
  if (showNavigation) return null;
  return (
    <button
      onClick={onToggle}
      className="absolute flex items-center justify-center h-12 transform -translate-y-1/2 rounded-full left-4 top-1/2 bg-warning-50 text-warning-500"
      style={{ width: "4.25rem", zIndex: 9999 }}
    >
      <IconListAlt className="flex-none w-5 h-5" />
      <IconArrowRight className="flex-none w-5 h-5" />
    </button>
  );
};
