/*
 * File: batch-labeling.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th December 2021 4:25:01 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { BatchLabelingState } from "./batch-labeling.state";
import { imageLoadLabelingJobReducerBuilder } from "./thunks/image-load-job.thunk";
import { imagePollJobsReducerBuilder } from "./thunks/image-poll-jobs.thunk";
import { imageSaveJobReducerBuilder } from "./thunks/image-save-job.thunk";
import { imageSetTasksReducerBuilder } from "./thunks/image-set-tasks.thunk";
import { imageVoteAnnotationReducerBuilder } from "./thunks/image-vote-annotation.thunk";

export const batchLabelingReducerBuilder = (
  builder: ActionReducerMapBuilder<BatchLabelingState>
) => {
  imagePollJobsReducerBuilder(builder);
  imageSaveJobReducerBuilder(builder);
  imageLoadLabelingJobReducerBuilder(builder);
  imageVoteAnnotationReducerBuilder(builder);
  imageSetTasksReducerBuilder(builder);
};
