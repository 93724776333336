/*
 * File: text-labeling.page.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 14th October 2021 10:11:55 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useThrottleFn, useUnmount } from "ahooks";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useTracking } from "hooks/use-tracking";
import { Fragment, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { ActivityStatus } from "services/activity-service/activity.dto";
import { ActivityService } from "services/activity-service/activity.service";
import { RequestStatus } from "store/base/base.state";
import { selectSystemTrackingTime } from "store/common/app-setting/app-setting.selectors";
import {
  selectedTextJobStatus,
  selectedTextPollJobStatus,
  selectTextJobs,
  selectTextJob,
} from "store/labeler/text-labeling-batch/text-labeling-batch.selectors";
import { resetLabelingBatchState } from "store/labeler/text-labeling-batch/text-labeling-batch.slice";
import { textLoadJobAsync } from "store/labeler/text-labeling-batch/thunks/text-load-job.thunk";
import { textPollJobsAsync } from "store/labeler/text-labeling-batch/thunks/text-poll-jobs.thunk";
import { setTextLabelingConflictAsync } from "store/labeler/text-workspace/text-conflict/text-conflict.thunk";
import {
  selectIsLoadingTextJob,
  selectIsNoTextJobAvailable,
} from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { setTextLabelingJobAsync } from "store/labeler/text-workspace/text-labeling/thunks/text-set-job.thunk";
import {
  resetTextLabelingWorkspace,
  textApplyImportedAnnotations,
} from "store/labeler/text-workspace/text-workspace.slice";
import { NoJobAvailable } from "./components/no-job-available.component";
import TextAnnotationSources from "./components/text-annotation-sources/text-annotation-sources.comopnent";
import { TextWorkspace } from "./components/text-workspace/text-workspace.component";
import { TextWorkspaceProvider } from "./context/text-workspace/text-workspace.provider";
import * as Sentry from "@sentry/react";

export const TextLabelingPage = () => {
  const dispatch = useAppDispatch();
  const pollJobStatus = useAppSelector(selectedTextPollJobStatus);
  const isNoJobAvailable = useAppSelector(selectIsNoTextJobAvailable);
  const isLoadingTextJob = useAppSelector(selectIsLoadingTextJob);
  const { projectId, jobId } = useParams<{
    projectId?: string;
    jobId?: string;
  }>();
  const selectedJobStatus = useAppSelector(selectedTextJobStatus);
  const trackingTime = useAppSelector(selectSystemTrackingTime);
  const textJobs = useAppSelector(selectTextJobs);
  const textJob = useAppSelector(selectTextJob);
  const selectedBatchId = textJob?.batch.id;
  const selectedJobId = textJob?.job.id || -1;

  const isPollingJobs =
    pollJobStatus === RequestStatus.IDLE ||
    pollJobStatus === RequestStatus.LOADING;

  const isLoadingJob =
    selectedJobStatus === RequestStatus.IDLE ||
    selectedJobStatus === RequestStatus.LOADING ||
    isLoadingTextJob;

  const isNoJob = useMemo(() => {
    return pollJobStatus === RequestStatus.SUCCESS && textJobs.length === 0;
  }, [textJobs, pollJobStatus]);

  const { run: onActive } = useThrottleFn(
    async () => {
      await ActivityService.createActivity({
        code: ActivityStatus.Active,
        jobId: selectedJobId,
        batchId: selectedBatchId,
      });
    },
    { wait: trackingTime }
  );
  useTracking({ enabled: true, onActive });

  useEffect(() => {
    if (pollJobStatus !== RequestStatus.IDLE) return;
    dispatch(textPollJobsAsync({ projectId, jobId }));
  }, [dispatch, pollJobStatus, projectId, jobId]);

  useEffect(() => {
    if (pollJobStatus !== RequestStatus.SUCCESS) return;
    if (selectedJobId === -1) return;
    if (selectedJobStatus !== RequestStatus.IDLE) return;
    dispatch(textLoadJobAsync({ jobId: selectedJobId }));
  }, [dispatch, pollJobStatus, selectedJobId, selectedJobStatus]);

  useEffect(() => {
    if (pollJobStatus !== RequestStatus.SUCCESS) return;
    if (selectedJobId === -1) return;
    if (selectedJobStatus !== RequestStatus.SUCCESS) return;
    dispatch(setTextLabelingJobAsync({ jobId: selectedJobId })).then((res) => {
      try {
        const data: any = (res.payload as any)?.labelingJob?.data;
        if (!data?.jobData && data?.importedData) {
          dispatch(textApplyImportedAnnotations());
        }
      } catch (error) {
        Sentry.captureException(error);
      }
      dispatch(setTextLabelingConflictAsync());
    });
  }, [dispatch, pollJobStatus, selectedJobId, selectedJobStatus]);

  useUnmount(() => {
    dispatch(resetTextLabelingWorkspace());
    dispatch(resetLabelingBatchState());
  });

  if (isNoJob) return <NoJobAvailable />;

  return (
    <div className="relative w-screen h-screen overflow-hidden">
      {!isLoadingTextJob && isNoJobAvailable && <NoJobAvailable />}
      {!isNoJobAvailable && (
        <TextWorkspaceProvider
          isLoading={isPollingJobs}
          isLoadingJob={isLoadingJob}
        >
          <Fragment>
            <TextWorkspace />
            <TextAnnotationSources />
          </Fragment>
        </TextWorkspaceProvider>
      )}
    </div>
  );
};
