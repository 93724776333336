/*
 * File: redux.utils.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 3rd July 2021 5:28:39 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export function handleThunkRejected(response: any) {
  if (response && response.error) {
    throw response.error;
  }
}
