import csTools from 'cornerstone-tools';
import moveAllHandles from './moveAllHandles';

const { state } = csTools.store;
export default function(evt, tool, annotation, interactionType = 'mouse') {
  annotation.active = true;
  state.isToolLocked = true;

  moveAllHandles(evt.detail, tool.name, annotation, null, tool.options, interactionType, () => {
    annotation.active = false;
    state.isToolLocked = false;
  });

  evt.stopImmediatePropagation();
  evt.stopPropagation();
  evt.preventDefault();
}
