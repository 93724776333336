/*
 * File: three-d-task-issues.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 10th April 2023 5:38:50 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { IconButton, Tooltip } from "@material-ui/core";
import { useJobIssues } from "hooks/issues";
import { ArrowDown2, ArrowRight2 } from "iconsax-react";
import { useMemo, useState } from "react";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { IssueList } from "./issue-list.component";
import { useThreeDLabelingContext } from "../../context/three-d-labeling.context";

interface Props {
  jobId: number;
}

export function ThreeDJobIssues({ jobId }: Props) {
  const { data, isLoading, selectIssue } = useJobIssues(jobId);
  const [expanded, setExpanded] = useState(true);
  const { uiJobRef } = useThreeDLabelingContext();
  const jobOptions = useMemo(() => {
    if (!uiJobRef.current) return [];
    const { jobOptions, job } = uiJobRef.current;

    const options = jobOptions
      .filter((option) => !!option.jobId)
      .map((item) => ({ label: item.assignee, value: item.jobId }));

    if (job && !options.find((option) => job && option.value === job.id)) {
      options.push({ label: job.assignee, value: job.id });
    }

    return options.map((item) => ({
      label: item.label,
      value: item.value.toString(),
    }));
  }, [uiJobRef]);

  if (!data || data.length === 0) return null;

  return (
    <section>
      <div className="flex items-center">
        <Tooltip title="Toggle expand">
          <IconButton onClick={() => setExpanded((pre) => !pre)}>
            {!expanded && <ArrowRight2 size={16} color="#fff" />}
            {expanded && <ArrowDown2 size={16} color="#fff" />}
          </IconButton>
        </Tooltip>
        <h2 className="flex-auto font-semibold truncate">
          My Issues {data && data?.length > 0 ? `(${data.length})` : ""}
        </h2>
      </div>

      {expanded && !isLoading && data && (
        <IssueList
          onSelectItem={selectIssue}
          data={data ?? []}
          jobOptions={jobOptions}
          mode={TextLabelingMode.LABELER}
        />
      )}
    </section>
  );
}

export default ThreeDJobIssues;
