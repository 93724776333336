/*
 * File: date-column-header.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 24th November 2021 5:09:23 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import moment from "moment";
import { forwardRef, HTMLProps } from "react";
import DatePicker from "react-datepicker";
import { createPortal } from "react-dom";
import { IconCalendar, IconClose, IconFilterList } from "../vb-icon.component";
import { SortType } from "./searchable-column-header.component";

interface Props {
  header: string;
  containerWidth: number | string;
  clearable?: boolean;
  sortable?: boolean;
  sortType?: SortType;
  onSortChange?(value: SortType): void;
  containerClassName?: string;
  defaultValue?: Date | null;
  onChange?(date: Date | null): void;
  placeholderText?: string;
  selectRange?: boolean;
  fromDate?: Date;
  toDate?: Date;
  onDateRangeChanged?: (fromDate: Date | undefined, toDate: Date | undefined) => void;
}

export const DateColumnHeader = ({
  containerClassName = "",
  header,
  containerWidth,
  sortable,
  sortType,
  onSortChange,
  defaultValue,
  clearable,
  onChange,
  placeholderText = "Date",
  selectRange,
  fromDate,
  toDate,
  onDateRangeChanged,
}: Props) => {
  const handleDateChange = (date: Date) => onChange && onChange(date);
  function handleDateRangeChanged(
    dates: any,
    _: React.SyntheticEvent<any> | undefined
  ) {
    if (dates && dates.length > 1) {
      const newFromDate = dates[0];
      const newToDate = dates[1];
      onDateRangeChanged && onDateRangeChanged(newFromDate, newToDate);
    }
  }
  const handleClickSort = () => {
    if (sortType === "") {
      onSortChange && onSortChange("asc");
      return;
    }
    if (sortType === "asc") {
      onSortChange && onSortChange("desc");
      return;
    }
    if (sortType === "desc") {
      onSortChange && onSortChange("");
      return;
    }
  };

  const DateInput = forwardRef<HTMLButtonElement>(
    ({ onClick }: HTMLProps<HTMLButtonElement>, ref) => (
      <button onClick={onClick} ref={ref} className="pl-1 w-full text-left">
        <span>
          {moment(fromDate).format("DD/MM/YY")} -{" "}
          {moment(toDate).format("DD/MM/YY")}
        </span>
      </button>
    )
  );

  return (
    <div
      className={`flex flex-col flex-shrink-0 gap-2 ${containerClassName}`}
      style={{ width: containerWidth }}
    >
      <div className="flex items-center justify-between gap-2 text-sm">
        <span>{header}</span>
        {sortable && (
          <button
            onClick={handleClickSort}
            className="flex items-center justify-center w-5 h-5"
          >
            {sortType === "" && <IconFilterList className="w-4 h-4" />}
            {sortType === "asc" && (
              <IconFilterList className="w-4 h-4 text-primary" />
            )}
            {sortType === "desc" && (
              <IconFilterList className="w-4 h-4 transform rotate-180 text-primary" />
            )}
          </button>
        )}
      </div>
      <div className="flex items-center w-full gap-2 px-4 overflow-hidden bg-white border border-gray-300 rounded h-9 focus-within:border-primary">
        <IconCalendar className="flex-shrink-0 w-4 h-4" />
        <div className="flex-1">
          {
            !selectRange &&
            <DatePicker
              className="text-sm focus:outline-none"
              selected={defaultValue}
              onChange={handleDateChange}
              placeholderText={placeholderText}
              dateFormat="dd/LLL/yyyy"
              maxDate={new Date()}
              popperContainer={({ children }) =>
                createPortal(children, document.body)
              }
              closeOnScroll
            />
          }
          {
            selectRange &&
            <DatePicker
              className="text-sm focus:outline-none"
              selected={fromDate}
              onChange={handleDateRangeChanged}
              placeholderText={placeholderText}
              dateFormat="dd/LLL/yyyy"
              startDate={fromDate}
              endDate={toDate}
              selectsRange
              popperContainer={({ children }) =>
                createPortal(children, document.body)
              }
              customInput={<DateInput />}
            />
          }
        </div>
        {clearable && defaultValue && (
          <button
            onClick={() => onChange && onChange(null)}
            className="flex-shrink-0 w-4 h-4"
          >
            <IconClose className="w-4 h-4" />
          </button>
        )}
      </div>
    </div>
  );
};
