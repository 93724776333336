import { Tooltip } from "@material-ui/core";
import { IconKeyboard } from "components/common/vb-icon.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { Fragment, useState } from "react";
import { ThreeDEditorEvents } from "./three-d-editor.models";

export const ThreeDEditorShortcutComponent = () => {
  const [modalVisibility, setModalVisibility] = useState(false);

  const handleKeyboardClick = () => {
    setModalVisibility(true);
  };

  const handleCloseModal = () => {
    setModalVisibility(false);
    // Send self check active event to windows
    document.dispatchEvent(
      new CustomEvent(ThreeDEditorEvents.COMMAND_CHECK_SELF_ACTIVE_WINDOW, {})
    );
  };

  return (
    <Fragment>
      <div className="flex items-center gap-2">
        <p className="font-bold">Shortcuts</p>
        <Tooltip title="Show shortcuts" placement="top">
          <IconKeyboard
            className="w-5 h-5 cursor-pointer"
            onClick={handleKeyboardClick}
          />
        </Tooltip>
      </div>

      <ThreeDEditorShortcutModal
        open={modalVisibility}
        onClose={handleCloseModal}
      />
    </Fragment>
  );
};

interface ThreeDEditorShortcutModalProps {
  open: boolean;
  onClose(): void;
}
export const ThreeDEditorShortcutModal = ({
  open,
  onClose,
}: ThreeDEditorShortcutModalProps) => {
  const keyboardShortcuts = [
    {
      name: "Navigation",
      shortcuts: [
        { keys: ["Shift", "Left mouse"], name: "Pan active window" },
        { keys: ["Shift", "Middle mouse"], name: "Zoom active window" },
        { keys: ["R"], name: "Reset active window camera" },
      ],
    },
    {
      name: "Colors",
      shortcuts: [
        {
          keys: ["Left mouse", "Move"],
          name: "Change window/color level (when no tool selected) when hold left mouse + move mouse",
        },
        { keys: ["Ctrl", "1 -> 9"], name: "Select 1st -> 9th color preset" },
      ],
    },
    {
      name: "Segments",
      shortcuts: [
        { keys: ["1 -> 9"], name: "Select 1st -> 9th segment" },
        {
          keys: ["Alt", "1 -> 9"],
          name: "Toggle 1st -> 9th segment visibility",
        },
        {
          keys: ["Alt", "h"],
          name: "Toggle all segments visibility",
        },
      ],
    },
    {
      name: "Tools",
      shortcuts: [
        { keys: ["P"], name: "Select/deselect paint tool" },
        { keys: ["D"], name: "Select/deselect draw tool" },
        { keys: ["["], name: "Select/deselect paint eraser" },
        { keys: ["]"], name: "Select/deselect draw eraser" },
        { keys: ["C"], name: "Show/hide crosshair tool" },
        {
          keys: ["A"],
          name: "Select/deselect tool adjust window/color level by rectangle region",
        },
        { keys: ["I"], name: "Select/deselect distance tool" },
        { keys: ["G"], name: "Select/deselect angle tool" },
        { keys: ["B"], name: "Select/deselect fill between slices tool" },
      ],
    },
    {
      name: "Layouts",
      shortcuts: [
        { keys: ["F"], name: "On/off one screen layout on active window" },
      ],
    },
    {
      name: "General",
      shortcuts: [
        { keys: ["Ctrl", "Z"], name: "Undo latest segment" },
        { keys: ["Ctrl", "Shift", "Z"], name: "Redo latest segment" },
      ],
    },
  ];
  return (
    <VBModal
      open={open}
      onClose={onClose}
      title={"Keyboard shortcuts"}
      footerHidden={true}
      width="40rem"
    >
      <div className="space-y-6" style={{ maxHeight: "60vh" }}>
        {keyboardShortcuts.map(({ name, shortcuts }) => {
          return (
            <div key={name} className="space-y-2">
              <h2 className="text-background-500">{name}</h2>
              <div className="space-y-2">
                {shortcuts.map((shortcut) => {
                  return (
                    <div
                      key={shortcut.name}
                      className="flex items-center gap-4"
                    >
                      <div className="flex-auto text-sm">{shortcut.name}</div>
                      <div
                        className="flex items-center flex-none gap-2"
                        style={{ width: "12rem" }}
                      >
                        {shortcut.keys.map((keyboardKey) => {
                          return (
                            <div
                              key={keyboardKey}
                              className="px-2 py-1 text-xs font-semibold uppercase rounded bg-background-200 text-background-700"
                            >
                              {keyboardKey}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </VBModal>
  );
};
