import { useTranslation } from "react-i18next";
import { UserAvatar } from "components/common/vb-user-avatar.component";
import { useAppSelector } from "hooks/use-redux";
import { CompanySize, UserInfoDTO } from "services/user-service/dtos/user-info.dto";
import { selectCurrentUser, selectUserInfo } from "store/auth/auth.selectors";
import { useEffect, useMemo, useState } from "react";
import { VBInput } from "pages/labeler/labeler-profile/components/vb-input.component";
import { ChangePasswordModal } from "pages/labeler/labeler-profile/components/change-password.modal";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { IconMedalStar } from "components/common/vb-icon.component";


export interface ProfileGeneralInfoProps {
  value: Partial<UserInfoDTO> | null;
  onUserInfoChanged?: (userInfo: Partial<UserInfoDTO> | null) => void;
  onHasErrors?: (hasErrors: boolean) => void;
  isEditing?: boolean;
}

export const ProfileGeneralInfo = ({
  value,
  onUserInfoChanged,
  onHasErrors,
  isEditing = true,
}: ProfileGeneralInfoProps) => {
  const { t } = useTranslation();

  const currentUser = useAppSelector(selectCurrentUser);
  const userInfo = useAppSelector(selectUserInfo);
  const [localUserInfo, setLocalUserInfo] = useState<Partial<UserInfoDTO> | null>(() => {
    if (!userInfo) return null;
    return { ...userInfo };
  });
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const hasErrors = useMemo(() => {
    return Object.keys(errors).length > 0;
  }, [errors]);

  useEffect(() => {
    setLocalUserInfo({ ...value });
  }, [value]);

  useEffect(() => {
    onHasErrors && onHasErrors(hasErrors);
  }, [hasErrors, errors, onHasErrors])

  if (!currentUser || !userInfo || !localUserInfo) return null;

  const companyOptions = Object.keys(CompanySize).map(item => ({
    value: item,
    label: item,
  }));

  const setUserInfoField = (field: string, value: string, validate = false) => {
    if (validate) {
      if (!value) {
        setErrors({
          ...errors,
          [field]: t("common:errors.empty"),
        })
      } else {
        if (errors.hasOwnProperty(field)) {
          let newError = { ...errors };
          delete newError[field];
          setErrors(newError);
        }
      }
    }

    const newUserInfo = { ...localUserInfo, [field]: value };
    setLocalUserInfo(newUserInfo);
    onUserInfoChanged && onUserInfoChanged(newUserInfo);
  }

  return (
    <div>
      <div className="flex items-center gap-6 p-6 bg-white border border-gray-300 rounded">
        <div className="flex-shrink-0">
          <UserAvatar
            className="w-24 h-24 text-3xl"
            name={userInfo.name || ""}
          />
        </div>
        <div className="grid flex-1 grid-cols-2">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <div className="text-sm text-background-500">{t("labelerhome:profile.textAccountName")}</div>
              <div className="font-semibold text-background-700">
                {userInfo.name}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="text-sm text-background-500">{t("labelerhome:profile.textEmail")}</div>
              <div className="font-semibold text-background-700">
                {currentUser.email}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <div className="text-sm text-background-500">{t("authorization:profile.generalInfo.textGrade")}</div>
              <div className="flex items-center gap-1 text-warning-300 font-semibold">
                <span>{t("authorization:profile.generalInfo.textVIP")}</span>
                <IconMedalStar className="w-5 h-5" />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="text-sm text-background-500">{t("authorization:profile.generalInfo.textMemberSince")}</div>
              <div className="font-semibold text-primary">---</div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-6 mt-6">
        <VBInput
          disabled={!isEditing}
          label="Organization"
          value={localUserInfo.companyName}
          onValueChanged={value => setUserInfoField("companyName", value)}
          hint={errors["companyName"]}
          placeholder="Organization"
          className="focus:ring-primary focus:border-primary"
        />
        <div className="col-span-2">
          <VBInput
            disabled={!isEditing}
            label="Address"
            value={localUserInfo.companyAddress}
            onValueChanged={value => setUserInfoField("companyAddress", value)}
            hint={errors["companyAddress"]}
            placeholder="Address"
            className="focus:ring-primary focus:border-primary"
          />
        </div>
        <VBInput
          disabled={!isEditing}
          label="SMS"
          value={localUserInfo.phoneNumber}
          onValueChanged={value => setUserInfoField("phoneNumber", value)}
          hint={errors["phoneNumber"]}
          placeholder="SMS"
          className="focus:ring-primary focus:border-primary"
        />
        <VBInput
          disabled={!isEditing}
          label="Website"
          value={localUserInfo.website}
          onValueChanged={value => setUserInfoField("website", value)}
          hint={errors["website"]}
          placeholder="Website"
          className="focus:ring-primary focus:border-primary"
        />
        <VBSelectComponent
          header="Organization size"
          size="xl"
          containerClassName=""
          value={{
            label: localUserInfo.companySize,
            value: localUserInfo.companySize,
          }}
          options={companyOptions}
          onChange={(option: any) => setUserInfoField("companySize", option.value as string)}
        />
        <div className="flex justify-between">
          <div className="flex-grow relative">
            <VBInput
              disabled={true}
              value="*************"
              label="Password"
              type="password"
            />
            {
              isEditing &&
              <div className="w-20 h-12 self-end flex justify-center absolute right-0 bottom-0 text-primary bg-background-100 border-t border-r border-b cursor-pointer">
                <button
                  disabled={!isEditing}
                  onClick={_ => setShowChangePassword(true)}
                >
                  {t("common:buttonChange")}
                </button>
              </div>
            }
          </div>
        </div>
      </div>

      <ChangePasswordModal
        visible={showChangePassword}
        onClose={() => setShowChangePassword(false)}
        textColor="text-primary"
        borderColor="border-primary"
        bgColor="bg-primary"
        inputClassName="focus:ring-primary focus:border-primary"
      />
    </div>
  )
}