/*
 * File: text-annotation-sources.state.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 21st October 2022 4:42:51 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { TextLabelingData } from "domain/labeling/labeling-data";
import { CORAnnotation, Label, NERAnnotation } from "domain/text-labeling";
import { Sentence } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import {
  SelectableAnnotator,
  TextAnnotationMenuItem,
} from "../reducer/text-annotations-reducer.hook";

export interface TextAnnotationSourcesState {
  labels: Label[];
  labelingDatas: TextLabelingData[];
  annotationMenuItems: TextAnnotationMenuItem[];
  annotators: SelectableAnnotator[];
  textAnnotations: {
    annotations: NERAnnotation[];
    relationAnnotations: CORAnnotation[];
  };
  sentences: Sentence[];
  onClose?(): void;
  selectAnnotator(annotatorId: string): void;
  selectLabel(labelId: string, action?: string): void;
  selectAnnotation(annotationId: string, action?: string): void;
  onSubmit(): void;
}

export const TEXT_ANNOTATION_SOURCES_INITIAL_STATE =
  {} as TextAnnotationSourcesState;
