/*
 * File: manage-workspace-header.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 4th August 2021 2:35:55 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBPageTitle } from "components/common/vb-page-title.component";
import { VBSpacer } from "components/common/vb-spacer.component";
import { Link } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { useAppSelector } from "hooks/use-redux";
import { selectIsGlobalAdmin } from "store/auth/auth.selectors";

interface Props {
  handleCreateWorkspace: () => void;
}

export const ManageWorkspaceHeader = ({ handleCreateWorkspace }: Props) => {
  const { t } = useTranslation();
  const isGlobalAdmin = useAppSelector(selectIsGlobalAdmin);
  return (
    <div className="flex items-center p-4">
      <Link
        className="flex items-center justify-center w-8 h-8 mr-4 text-xl cursor-pointer"
        to={Routes.ROOT}
        title="Back"
      >
        <i className="uir-arrow-left"></i>
      </Link>
      <VBPageTitle text={t("workspace:title")} />
      <VBSpacer />
      {isGlobalAdmin && (
        <button onClick={handleCreateWorkspace} className="button-primary">
          {t("workspace:buttonCreateWorkspace")}
        </button>
      )}
    </div>
  );
};
