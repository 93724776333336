/*
 * File: text-labeling.state.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 5:02:30 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Job } from "domain/labeling/job";
import { LabelingJob } from "domain/labeling/labeling-job";
import { Label, Token } from "domain/text-labeling";
import { BatchDTO, JobDTO, TaskDTO } from "services/label-service/dtos";
import { TextTaskObservationModel } from "services/label-service/dtos/text-observation.dto";
import { RequestStatus } from "store/base/base.state";

export interface Sentence {
  endIndex: number;
  sentence: string;
  sentenceIndex: number;
  startIndex: number;
}

export enum TextLabelingMode {
  LABELER = "labeler",
  SUPERVISOR = "supervisor",
  REVIEWER = "reviewer",
  ACCEPTANCE = "acceptance",
  CUSTOMER = "customer",
}
export interface TextLabelingState {
  requestStatus: RequestStatus;
  job: Job | null;
  labels: Label[];
  taskObservations: TextTaskObservationModel[];
  selectedObservationId: number;
  batch: BatchDTO | null;
  task: TaskDTO | null;
  jobEntities: { [key: number]: JobDTO };
  sentences: Sentence[];
  tokenIds: string[];
  tokenEntities: Record<string, Token>;
  readonly: boolean;
  showBatchInstruction: boolean;
  mode: TextLabelingMode;
  labelingJob: LabelingJob | null;
}
export interface ObservationState {
  hidden?: boolean;
  totalAnnotations: number;
}

export const INITIAL_TEXT_LABELING_STATE: TextLabelingState = {
  requestStatus: RequestStatus.IDLE,
  job: null,
  batch: null,
  task: null,
  labels: [],
  taskObservations: [],
  selectedObservationId: -1,
  jobEntities: {},
  sentences: [],
  tokenIds: [],
  tokenEntities: {},
  readonly: false,
  showBatchInstruction: false,
  mode: TextLabelingMode.LABELER,
  labelingJob: null,
};
