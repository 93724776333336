import { Radio } from "@material-ui/core";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useAppSelector } from "hooks/use-redux";
import { Fragment, useState } from "react";
import { selectBatchStepLabelerOptions } from "store/customer/batch/batch.selectors";

export interface Props {
  open: boolean;
  onClose?: () => void;
  onSubbmit?: (selectedLabelers?: string[]) => void;
}

export const TasksReleaseDialog = ({
  open,
  onClose,
  onSubbmit,
}: Props) => {
  const [byTask, setByTask] = useState(true);
  
  const labelersOptions = useAppSelector(
    selectBatchStepLabelerOptions(false, false)
  );
  const [selectedLabelers, setSelectedLabelers] = useState<string[]>([]);

  const handleSelectChanged = (options: any) => {
    setSelectedLabelers(options.map((option: any) => option.value));
  }

  const handleSubmit = () => {
    let assignees = undefined;
    if (!byTask) {
      assignees = selectedLabelers;
    }
    onSubbmit && onSubbmit(assignees);
  }

  return (
    <VBModal
      title={"Release tasks"}
      open={open}
      onClose={onClose}
      textSubmit={"Release"}
      disableSubmit={!byTask && selectedLabelers.length <= 0}
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <Radio
              color="primary"
              checked={byTask}
              onChange={(_, v) => setByTask(true)}
            />
            <span>Release selected tasks </span>
          </div>
          <div className="flex items-center gap-2">
            <Radio
              color="primary"
              checked={!byTask}
              onChange={(_, v) => setByTask(false)}
            />
            <span>Release selected labelers </span>
          </div>
        </div>
        
        {
          !byTask &&
          <Fragment>
            <p>Select labelers: </p>
            <VBSelectComponent
              options={labelersOptions}
              isMulti
              onChange={handleSelectChanged}
              menuPortalTarget={document.body}
            />
          </Fragment>
        }
        <p>All related annotations will be removed! Are you sure you want to release these tasks?</p>
      </div>
    </VBModal>
  );
};
