import { useRef, useState } from "react";
import { fileToVTKImage } from "../utils";
import { vtkImageData } from "../vtk_import";
import { useThreeDEditorContext } from "./three-d-editor.provider"


export const ThreeDEditorIOComponent = () => {

  const {
    saveLabelMap,
    loadLabelMap,
  } = useThreeDEditorContext();

  const inputLoadLabelMapRef = useRef<HTMLInputElement>(null);
  const [isProcesing, setIsProcessing] = useState(false);

  const handleSaveLabelClick = async () => {
    if (isProcesing) return;
    setIsProcessing(true);
    await saveLabelMap()
    setIsProcessing(false);
  }

  const handleLoadLabelMapInputChanged = async (e: any) => {
    if (isProcesing) return;
    
    setIsProcessing(true);
    const file: File = e.target.files[0];
    const vtkImage: vtkImageData = await fileToVTKImage(file);
    e.target.value = null; // no need to keep this in the memory
    loadLabelMap(vtkImage);
    setIsProcessing(false);
  }

  return (
    <div className="flex flex-col gap-2">
      <p className="font-bold">IO</p>
      <div className="flex items-center gap-2">
        <button
          className="button-warn-secondary disabled:opacity-50"
          onClick={handleSaveLabelClick}
          disabled={isProcesing}
        >
          Save label
        </button>
        <button
          className="button-warn-secondary disabled:opacity-50"
          onClick={() => inputLoadLabelMapRef.current?.click()}
          disabled={isProcesing}
        >
          Load label
        </button>
        <input
          ref={inputLoadLabelMapRef}
          className="hidden"
          type="file"
          onClick={(e: any) => e.target.value = null}
          onChange={handleLoadLabelMapInputChanged}
        />
      </div>
    </div>
  )
}
