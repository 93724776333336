import { useTranslation } from "react-i18next";


export const ActionsHeader = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full leading-4 mr-2">
      <p className="h-6">{t("dataset:header.textActions")}</p>
    </div>
  );
};
