/*
 * File: task-observation-v3.api copy.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 16th May 2022 11:11:12 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AxiosResponse } from "axios";
import { TaskObservationRequestDTOV3, TaskObservationV3DTO, TextObservationV3DTO } from "../dtos/task-observation-v3.dto";
import { BaseAPI } from "./base.api";

export class TaskObservationV3API extends BaseAPI<any> {
  getTaskObservations(
    jobId: number
  ): Promise<AxiosResponse<TaskObservationV3DTO[]>> {
    const params = new URLSearchParams({ size: "10000" });
    return this.get(`${this.getPath()}/jobs/${jobId}?${params.toString()}`);
  }

  saveTaskObservations(payload: TaskObservationV3DTO[] | TaskObservationRequestDTOV3[]) {
    return this.post(this.getPath(), payload);
  }

  getTextObservations(
    jobId: number
  ): Promise<AxiosResponse<TextObservationV3DTO[]>> {
    const params = new URLSearchParams({ size: "10000" });
    return this.get(`${this.getPath()}/jobs/${jobId}?${params.toString()}`);
  }

  saveTextObservations(payload: TextObservationV3DTO[]) {
    return this.post(this.getPath(), payload);
  }
}
