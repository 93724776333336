/*
 * File: upload-files.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AxiosResponse } from "axios";
import { RequestOptions } from "../../common/request-options";
import { StorageFileDTO } from "../../../models/dataset/storage-file.model";
import { ParamsKey } from "../../common/params-key";
import { storageAPIUpload } from "../storage-httpclient";

interface UploadFilesPayload {
  datasetId: number;
  files: File[];
  onProgress?: (event: any) => void;
  tagIds?: number[];
  paramOptions?: RequestOptions;
}

export function uploadFiles(
  payload: UploadFilesPayload
): Promise<AxiosResponse<StorageFileDTO[]>> {
  const {
    datasetId,
    files,
    onProgress,
    tagIds = [],
    paramOptions = {},
  } = payload;
  const urlParams = { ...paramOptions, datasetId: datasetId.toString() };
  const params = new URLSearchParams(urlParams);
  const formData = new FormData();
  formData.append(ParamsKey.DATASET_ID, `${datasetId}`);
  for (const file of files) formData.append(ParamsKey.UPLOADED_FILE, file);
  formData.append(ParamsKey.TAG_IDS, tagIds.join(","));
  const url = `/api/v1/files/upload?${params.toString()}`;
  const headers = { "Content-Type": "multipart/form-data" };
  const options = { headers, data: formData, onUploadProgress: onProgress };
  return storageAPIUpload(url, formData, options);
}

export enum TYPE_OF_TIME {
  MILLISECOND = "millisecond",
  SECOND = "second",
  MINUTE = "minute",
}

// for support 3D labeling
// BE will convert dicom series to nifty file
export function uploadDicomSeries(
  payload: UploadFilesPayload
): Promise<AxiosResponse<StorageFileDTO[]>> {
  const { datasetId, files, onProgress, paramOptions = {} } = payload;
  const formData = new FormData();
  for (const file of files) formData.append(ParamsKey.FILES, file);
  const urlParams = { ...paramOptions, datasetId: datasetId.toString() };
  const params = new URLSearchParams(urlParams);
  const url = `/api/v1/files/upload-series?${params.toString()}`;
  const headers = { "Content-Type": "multipart/form-data" };
  const options = { headers, data: formData, onUploadProgress: onProgress };
  return storageAPIUpload(url, formData, options);
}
