/*
 * File: tasks-table.component.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 15th January 2022 9:46:15 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Checkbox, Tooltip } from "@material-ui/core";
import { VBComponentRequesting } from "components/common/vb-component-requesting/vb-component-requesting.component";
import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import { SearchableColumnHeader } from "components/common/vb-grid/searchable-column-header.component";
import { SelectableColumnHeader } from "components/common/vb-grid/selectable-column-header.component";
import { IconAddItem } from "components/common/vb-icon.component";
import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { FilterCommon } from "domain/common/filter";
import { useAppSelector } from "hooks/use-redux";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TaskAction,
  TaskReviewStatus,
  TaskStatus,
} from "services/label-service/dtos";
import { selectBatchStepLabelerOptions } from "store/customer/batch/batch.selectors";
import { classnames } from "utilities/classes";
import { truncateEmail } from "utilities/string/truncate-email";
import { useBatchDetailContext } from "../../../context/batch-detail.context";
import {
  TaskRow,
  TaskViewMode,
  useBatchTaskContext,
} from "../batch-tasks.context";
import { IssueStatus } from "./issue/issue-management.models";
import { TasksChangeStatusDialog } from "./tasks-change-status.dialog";
import { TasksGrid } from "./tasks-grid.component";
import { TasksReleaseDialog } from "./tasks-release.dialog";
import { TasksReopenDialog } from "./tasks-reopen.dialog";
import { VscEye, VscReport, VscStopCircle, VscTrash } from "react-icons/vsc";

interface ColDef<T extends TaskRow> {
  hidden?: boolean;
  field: keyof T | string;
  header: string;
  renderCell: (row: T, rowIndex: number, columnIndex: number) => JSX.Element;
  renderColumnHeader: (column: ColDef<T>, columnIndex: number) => JSX.Element;
}

interface Props {
  loading?: boolean;
  totalTask: number;
  onSelect?: (taskId: number, action?: string) => void;
}

export const BatchTasksTable = ({ totalTask, loading, onSelect }: Props) => {
  const { batch } = useBatchDetailContext();
  const {
    taskRows,
    filter,
    setPage,
    setPageSize,
    reopenTasks,
    changeStatusRow,
    releaseTasks,
    isReviewStep,
    isLoadingTasks,
    openReopenDialog,
    openReleaseDialog,
    setOpenReleaseDialog,
    setOpenReopenDialog,
    viewMode,
  } = useBatchTaskContext();

  const handleReopenDialogClose = () => {
    setOpenReopenDialog(false);
  };

  const handleReopenDialogSubmit = (reason: string) => {
    setOpenReopenDialog(false);
    reopenTasks(reason);
  };

  const handleReleaseSubmit = (selectedLabelers?: string[]) => {
    releaseTasks(batch.id, selectedLabelers);
    setOpenReleaseDialog(false);
  };

  const handleRowAction = (action: string, row: TaskRow) => {
    onSelect && onSelect(row.id, action);
  };

  return (
    <div className="relative w-full h-full gap-4">
      <div className="flex flex-col h-full">
        <div>
          {viewMode === TaskViewMode.LIST && (
            <TasksTable
              hideReviewStatus={!isReviewStep}
              rows={taskRows}
              onRowAction={handleRowAction}
              isLoading={isLoadingTasks}
            />
          )}
          {viewMode === TaskViewMode.GRID && (
            <TasksGrid
              rows={taskRows}
              onRowAction={handleRowAction}
              isLoading={isLoadingTasks}
            />
          )}
        </div>
        <div className="flex-auto" />
        {filter && (
          <div className="flex-none my-4">
            <GridPagination
              page={filter.page}
              pageSize={filter.size}
              pageSizeOptions={[10, 15, 20, 25, 50, 75, 100]}
              onPageSizeChange={setPageSize}
              onPageChange={setPage}
              totalItems={totalTask}
            />
          </div>
        )}
      </div>
      {loading && <VBComponentRequesting />}
      {openReopenDialog && (
        <TasksReopenDialog
          open={openReopenDialog}
          onClose={handleReopenDialogClose}
          onSubbmit={handleReopenDialogSubmit}
        />
      )}
      {
        <TasksChangeStatusDialog
          open={!!changeStatusRow}
          taskRow={changeStatusRow}
        />
      }
      {
        <TasksReleaseDialog
          open={openReleaseDialog}
          onClose={() => setOpenReleaseDialog(false)}
          onSubbmit={handleReleaseSubmit}
        />
      }
    </div>
  );
};

interface TasksTableProps {
  rows: TaskRow[];
  onRowAction?(action: string, row: TaskRow): void;
  hideReviewStatus?: boolean;
  isLoading?: boolean;
  showFilters?: boolean;
}
const TasksTable = ({
  rows,
  onRowAction,
  hideReviewStatus,
  isLoading = false,
  showFilters = false,
}: TasksTableProps) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [paddingRight, setPaddingRight] = useState(0);
  const labelersOptions = useAppSelector(
    selectBatchStepLabelerOptions(true, false)
  );
  const reviewersOptions = useAppSelector(
    selectBatchStepLabelerOptions(false, true)
  );

  const {
    filter,
    setSort,
    setId,
    setFileName,
    setStatus,
    setReviewStatus,
    setIssueStatus,
    setAssignee,
    selectedAll,
    setTaskRowSelectedAll,
    setTaskRowSelect,
    showSelect,
    isBatchCompleted,
    canAssignJobsToTask,
  } = useBatchTaskContext();
  const { batch } = useBatchDetailContext();

  const statusOptions = [
    { label: "completed", value: TaskStatus.COMPLETED },
    { label: "working", value: TaskStatus.WORKING },
    { label: "initial", value: TaskStatus.INITIAL },
  ];

  const reviewStatusOptions = [
    { label: "Accepted", value: TaskReviewStatus.ACCEPTED },
    { label: "Rejected", value: TaskReviewStatus.REJECTED },
  ];

  const issueStatusOptions = [
    { label: "Opened", value: IssueStatus.OPENED.toLocaleLowerCase() },
    { label: "Fixed", value: IssueStatus.FIXED.toLocaleLowerCase() },
    { label: "Resolved", value: IssueStatus.RESOLVED.toLocaleLowerCase() },
  ];

  const columns: ColDef<TaskRow>[] = [
    {
      field: "select",
      header: showFilters ? "#" : "",
      hidden: !showSelect,
      renderColumnHeader: (column: ColDef<TaskRow>, columnIndex: number) => {
        return (
          <ColumnHeaderWrapper
            maxWidth={60}
            key={column.field}
            first={columnIndex === 0}
            last={true}
          >
            <div className="text-center" style={{ maxWidth: 60 }}>
              <p>{column.header}</p>
              <Checkbox
                color="primary"
                checked={selectedAll}
                onChange={(_, value) => setTaskRowSelectedAll(value)}
              />
            </div>
          </ColumnHeaderWrapper>
        );
      },
      renderCell: (row: TaskRow) => {
        return (
          <div
            className="flex items-center justify-center"
            style={{ maxWidth: 60 }}
          >
            <Checkbox
              color="primary"
              checked={row.selected}
              onChange={(_, value) => setTaskRowSelect(row, value)}
            />
          </div>
        );
      },
    },
    {
      field: "id",
      header: "TaskID",
      renderColumnHeader: (column: ColDef<TaskRow>, columnIndex: number) => {
        return (
          <ColumnHeaderWrapper
            maxWidth={120}
            key={column.field}
            first={columnIndex === 0}
            last={columnIndex === columns.length - 1}
          >
            <SearchableColumnHeader
              containerClassName="w-full py-2 text-sm"
              clearInput
              searchIcon={false}
              placeholder={t("project:batchDetails.taskTable.search")}
              header={t("project:batchDetails.taskTable.headerId")}
              containerWidth={""}
              defaultValue={filter?.taskId}
              type="number"
              onInputChange={setId}
              showSearch={showFilters}
              sortable
              onSortChange={(sortType) => setSort("id", sortType)}
              sortType={FilterCommon.getSortType(filter?.sort, "id")}
            />
          </ColumnHeaderWrapper>
        );
      },
      renderCell: (row: TaskRow) => {
        return (
          <span
            className="text-sm cursor-pointer text-primary"
            onClick={() => onRowAction && onRowAction("review", row)}
          >
            {row.id}
          </span>
        );
      },
    },
    {
      field: "fileName",
      header: "FileName",
      renderColumnHeader: (column: ColDef<TaskRow>, columnIndex: number) => {
        return (
          <ColumnHeaderWrapper
            key={column.field}
            first={columnIndex === 0}
            last={columnIndex === columns.length - 1}
          >
            <SearchableColumnHeader
              containerClassName="w-full py-2 text-sm"
              clearInput
              placeholder={t("project:batchDetails.taskTable.search")}
              header={t("project:batchDetails.taskTable.headerFileName")}
              containerWidth="100%"
              defaultValue={filter?.fileName}
              onInputChange={setFileName}
              showSearch={showFilters}
              sortable
              onSortChange={(sortType) => setSort("fileName", sortType)}
              sortType={FilterCommon.getSortType(filter?.sort, "fileName")}
            />
          </ColumnHeaderWrapper>
        );
      },
      renderCell: (row: TaskRow) => {
        return <span className="text-sm">{row.fileName}</span>;
      },
    },
    {
      field: "status",
      header: "Status",
      renderColumnHeader: (column: ColDef<TaskRow>, columnIndex: number) => {
        return (
          <ColumnHeaderWrapper
            key={column.field}
            first={columnIndex === 0}
            last={columnIndex === columns.length - 1}
          >
            <SelectableColumnHeader
              className="leading-normal"
              placeholder="All"
              header="Labeling status"
              containerWidth="100%"
              menuPortalTarget={document.body}
              value={
                statusOptions.find(
                  (option) => option.value === filter?.labelingStatus
                ) || ""
              }
              options={statusOptions}
              onChange={(option: any) => setStatus(option?.value || "")}
              isClearable
              showSelect={showFilters}
            />
          </ColumnHeaderWrapper>
        );
      },
      renderCell: (row: TaskRow) => {
        return <span className="text-sm">{row.status}</span>;
      },
    },
    {
      field: "reviewStatus",
      header: "Review Status",
      hidden: hideReviewStatus,

      renderColumnHeader: (column: ColDef<TaskRow>, columnIndex: number) => {
        return (
          <ColumnHeaderWrapper
            key={column.field}
            first={columnIndex === 0}
            last={columnIndex === columns.length - 1}
          >
            <SelectableColumnHeader
              className="leading-normal"
              placeholder="All"
              header="Review status"
              containerWidth="100%"
              menuPortalTarget={document.body}
              // value={
              //   reviewStatusOptions.find(
              //     (option) => option.value === filter?.reviewStatus
              //   ) || ""
              // }
              options={reviewStatusOptions}
              onChange={(option: any) => setReviewStatus(option?.value || "")}
              isClearable
              showSelect={showFilters}
            />
          </ColumnHeaderWrapper>
        );
      },
      renderCell: (row: TaskRow) => {
        return <span className="text-sm">{row.reviewStatus}</span>;
      },
    },
    {
      field: "assignee",
      header: "Labelers",
      renderColumnHeader: (column: ColDef<TaskRow>, columnIndex: number) => {
        return (
          <ColumnHeaderWrapper
            key={column.field}
            first={columnIndex === 0}
            last={columnIndex === columns.length - 1}
          >
            <SelectableColumnHeader
              className="leading-normal"
              placeholder="All"
              header="Labelers"
              containerWidth="100%"
              menuPortalTarget={document.body}
              value={
                labelersOptions.filter((option) =>
                  filter?.labelers?.includes(option.value)
                ) || []
              }
              options={labelersOptions}
              onChange={(option: any) => setAssignee(option?.value || "")}
              isMulti
              isClearable
              showSelect={showFilters}
            />
          </ColumnHeaderWrapper>
        );
      },
      renderCell: (row: TaskRow) => {
        const assignee = row.assignee || [];
        const taskAnnotators = labelersOptions
          .filter(
            (labeler) => !!assignee.find((item) => item === labeler.value)
          )
          .map((item) => truncateEmail(item.value))
          .join(", ");
        return <span className="text-sm">{taskAnnotators}</span>;
      },
    },
    {
      field: "reviewers",
      header: "Reviewers",
      hidden: hideReviewStatus,
      renderColumnHeader: (column: ColDef<TaskRow>, columnIndex: number) => {
        return (
          <ColumnHeaderWrapper
            key={column.field}
            first={columnIndex === 0}
            last={columnIndex === columns.length - 1}
          >
            <SelectableColumnHeader
              className="leading-normal"
              placeholder="All"
              header="Reviewers"
              containerWidth="100%"
              menuPortalTarget={document.body}
              value={
                reviewersOptions.filter((option) =>
                  filter?.labelers?.includes(option.value)
                ) || []
              }
              options={reviewersOptions}
              onChange={(option: any) => setAssignee(option?.value || "")}
              isMulti
              isClearable
              showSelect={showFilters}
            />
          </ColumnHeaderWrapper>
        );
      },
      renderCell: (row: TaskRow) => {
        const assignee = row.assignee || [];
        const taskReviewers = assignee
          .filter((name) => !!reviewersOptions.find((op) => op.value === name))
          .map(truncateEmail)
          .join(", ");
        return <span className="text-sm">{taskReviewers}</span>;
      },
    },
    {
      field: "issueStat",
      header: "Issue status",
      renderColumnHeader: (column: ColDef<TaskRow>, columnIndex: number) => {
        return (
          <ColumnHeaderWrapper
            key={column.field}
            first={columnIndex === 0}
            last={columnIndex === columns.length - 1}
          >
            <SelectableColumnHeader
              className="leading-normal"
              placeholder="All"
              header="Issue status"
              containerWidth="100%"
              menuPortalTarget={document.body}
              value={
                issueStatusOptions.find(
                  (option) => option.value === filter?.issueStatus
                ) || ""
              }
              options={issueStatusOptions}
              onChange={(option: any) => setIssueStatus(option?.value || "")}
              isClearable
              showSelect={showFilters}
            />
          </ColumnHeaderWrapper>
        );
      },
      renderCell: (row: TaskRow) => {
        const strs: string[] = [];
        if (row.issueStat) {
          for (let key in row.issueStat) {
            strs.push(`${key}: ${row.issueStat[key]}`);
          }
        }
        return <span className="text-sm">{strs.join(", ")}</span>;
      },
    },
  ];

  function handleMouseOver() {
    if (!containerRef.current) return;
    const { scrollWidth, clientWidth, scrollLeft } = containerRef.current;
    setPaddingRight(scrollWidth - clientWidth - scrollLeft);
  }

  return (
    <div
      className="relative overflow-x-auto"
      ref={containerRef}
      onMouseOver={handleMouseOver}
    >
      <table className="min-w-full vb-table">
        <thead className="relative thead-no-spacing">
          <tr>
            {columns.map((column, index) => {
              if (column.hidden) return null;
              return column.renderColumnHeader(column, index);
            })}
          </tr>
          <tr className="absolute top-0 left-0 w-full h-full border-t border-l border-r rounded-tl rounded-tr pointer-events-none border-background-300"></tr>
        </thead>
        <tbody className="relative mt-4 text-xs">
          {isLoading && (
            <tr>
              <td colSpan={columns.length}>
                <VBInlineLoading className="flex items-center justify-center p-20" />
              </td>
            </tr>
          )}
          {!isLoading && rows.length === 0 && (
            <tr>
              <td colSpan={columns.length}>
                <div className="flex items-center justify-center p-10">
                  No task found
                </div>
              </td>
            </tr>
          )}
          {!isLoading &&
            rows.map((row, rowIndex) => {
              const canAssignJobs = canAssignJobsToTask(batch, row);
              const actions = [
                {
                  disabled: !canAssignJobs,
                  title: "Assign Jobs",
                  onClick: () => {
                    onRowAction && onRowAction(TaskAction.ASSIGN, row);
                  },
                  icon: (
                    <IconAddItem className="flex-none w-4 h-4 text-background-700 hover:text-primary" />
                  ),
                },
                {
                  title: "Reopen Task/Job",
                  onClick: () => {
                    onRowAction && onRowAction(TaskAction.REOPEN, row);
                  },
                  icon: (
                    <VscReport
                      size={16}
                      className="flex-none w-4 h-4 text-background-700 hover:text-primary"
                    />
                  ),
                },
                {
                  title: "Skip Task/Job",
                  onClick: () => {
                    onRowAction && onRowAction(TaskAction.SKIP, row);
                  },
                  icon: (
                    <VscStopCircle
                      size={16}
                      className="flex-none w-4 h-4 text-background-700 hover:text-primary"
                    />
                  ),
                },
                {
                  title: "Review Task",
                  onClick: () => {
                    onRowAction && onRowAction(TaskAction.REVIEW, row);
                  },
                  icon: (
                    <VscEye
                      size={16}
                      className="flex-none w-4 h-4 text-background-700 hover:text-primary"
                    />
                  ),
                },
                {
                  disabled: isBatchCompleted,
                  title: "Delete Task",
                  onClick: () => {
                    onRowAction && onRowAction(TaskAction.DELETE, row);
                  },
                  icon: (
                    <VscTrash
                      size={16}
                      className="flex-none w-4 h-4 text-background-700 hover:text-primary"
                    />
                  ),
                },
              ];
              return (
                <tr
                  key={rowIndex}
                  className="relative hover:bg-secondary-50 parent"
                >
                  {columns.map((column, columnIndex) => {
                    if (column.hidden) return null;
                    return (
                      <td className="h-10 whitespace-nowrap" key={column.field}>
                        {column.renderCell(row, rowIndex, columnIndex)}
                      </td>
                    );
                  })}

                  <td
                    className="absolute h-full transform -translate-y-1/2 top-1/2 child-on-hover"
                    style={{ right: `${paddingRight}px` }}
                  >
                    <div className="flex flex-row items-center h-full gap-1 px-3 bg-secondary-50">
                      {actions.map((action) => {
                        if (action.disabled) return null;
                        return (
                          <Tooltip title={action.title} key={action.title}>
                            <button
                              className="flex items-center justify-center w-6 h-6"
                              onClick={action.onClick}
                            >
                              {action.icon}
                            </button>
                          </Tooltip>
                        );
                      })}
                    </div>
                  </td>
                </tr>
              );
            })}
          <tr className="absolute top-0 left-0 w-full h-full border rounded-bl rounded-br pointer-events-none border-background-300"></tr>
        </tbody>
      </table>
    </div>
  );
};

interface ColumnHeaderWrapperProps {
  first?: boolean;
  last?: boolean;
  children?: JSX.Element;
  maxWidth?: number;
}
const ColumnHeaderWrapper = ({
  first,
  last,
  children,
  maxWidth,
}: ColumnHeaderWrapperProps) => {
  return (
    <th
      className={classnames(
        "whitespace-nowrap text-background-700 text-left font-normal",
        {
          "rounded-l": !!first,
          "pr-4": !last,
          "rounded-r": !!last,
        }
      )}
      style={{ maxWidth: maxWidth }}
    >
      {children}
    </th>
  );
};
