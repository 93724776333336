/*
 * File: index.js
 * Project: app-aiscaler-web
 * File Created: Sunday, 12th June 2022 4:28:48 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */
import cornerstoneTools from "cornerstone-tools";

export function setToolCursor(element, svgCursor) {
  const cursorBlob = svgCursor.getIconWithPointerSVG();
  const mousePoint = svgCursor.mousePoint;
  const svgCursorUrl = window.URL.createObjectURL(cursorBlob);
  element.style.cursor = `url('${svgCursorUrl}') ${mousePoint}, auto`;
  cornerstoneTools.store.state.svgCursorUrl = svgCursorUrl;
}
