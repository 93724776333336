import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";
import FreehandRoiToolExtend from "components/dicom/cornerstone/tools/FreehandRoiExtend";
import RectangleRoiExtendTool from "components/dicom/cornerstone/tools/RectangleRoiExtend";
import { annotateTypeMapper } from "constants/annotation.constant";
import { annotationUtils } from "utilities/annotations/annotation-util";
import { Annotation } from "domain/image-labeling/annotation";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";
import {
  MeasurementData,
  MeasurementRelationData,
} from "components/dicom/cornerstone/models/measurement.model";
import MultiArrowConnectionTool from "components/dicom/cornerstone/tools/MultiArrowConnectionTool";
import * as Sentry from "@sentry/react";
import { ImageFrameHandler } from "components/dicom/cornerstone/cornerstone-handler/cornerstone-handler";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { isFileDicom } from "utilities/dicom/dicom.utils";
const scrollToIndex = cornerstoneTools.importInternal("util/scrollToIndex");

interface TasksReviewOptions {
  file: StorageFileDTO;
  numFrames?: number;
}

export class TasksReviewCornerstoneHandler implements ImageFrameHandler {
  element: any;

  usedToolNames: ToolName[] = [
    ToolName.FreehandRoiExtend,
    ToolName.RectangleRoiExtend,
    ToolName.Zoom,
    ToolName.StackScrollMouseWheel,
    ToolName.Pan,
    ToolName.Wwwc,
    ToolName.MultiArrowConnection,
  ];
  tool: ToolName | "" = "";
  currentImageId: string | undefined;

  imageAnnotations: Annotation[] = [];
  relationAnnotations: RelationAnnotation[] = [];
  measurements: Record<string, MeasurementData> = {};
  relationMeasurements: Record<string, MeasurementRelationData> = {};
  annoToMeasurements: Record<string, MeasurementData> = {};
  relaToMeasurements: Record<string, MeasurementRelationData> = {};

  showLabel: boolean = true;
  fillPolygon: boolean = true;
  displayTextValue: boolean = true;
  options?: TasksReviewOptions = undefined;
  constructor(element: any) {
    this.element = element;
  }

  frameIdx = 0;
  setFrameIdx(idx: number) {
    this.frameIdx = idx;
    scrollToIndex(this.getContainer(), idx);
  }

  setImageId(imageId: string | undefined) {
    this.currentImageId = imageId;
  }

  clearAllToolStates() {
    for (let toolName of this.usedToolNames) {
      cornerstoneTools.clearToolState(this.element, toolName);
    }
  }

  addTools() {
    const container = this.getContainer();
    cornerstoneTools.addToolForElement(
      container,
      cornerstoneTools["ZoomMouseWheelTool"],
      {
        name: "ZoomMouseWheel",
        supportedInteractionTypes: ["MouseWheel"],
        configuration: {
          minScale: 0.1,
          maxScale: 20.0,
          invert: false,
        },
      }
    );
    for (let tool of this.usedToolNames) {
      if (tool === ToolName.FreehandRoiExtend) {
        cornerstoneTools.addToolForElement(container, FreehandRoiToolExtend);
      } else if (tool === ToolName.RectangleRoiExtend) {
        cornerstoneTools.addToolForElement(container, RectangleRoiExtendTool);
      } else if (tool === ToolName.MultiArrowConnection) {
        cornerstoneTools.addToolForElement(container, MultiArrowConnectionTool);
      } else if (tool === ToolName.StackScrollMouseWheel) {
        cornerstoneTools.addToolForElement(
          container,
          cornerstoneTools.StackScrollMouseWheelTool,
          {
            configuration: {
              loop: true,
              allowSkipping: true,
            },
          }
        );
      } else {
        const csTool = cornerstoneTools[`${tool}Tool`];
        cornerstoneTools.addToolForElement(container, csTool);
      }
    }
  }

  getContainer(): HTMLElement {
    return this.element;
  }

  init(options?: TasksReviewOptions) {
    this.options = options;
    this.clearAllToolStates();
    cornerstoneTools.init({ showSVGCursors: true });
    this.addTools();
    this.addFrames();
  }

  getImageIds() {
    if (!this.options) return undefined;
    if (!this.options?.numFrames) return undefined;
    if (!isFileDicom(this.options.file)) return undefined;
    const baseUrl = `wadouri:${this.options.file.url}`;
    const imageIds = [];
    for (let i = 0; i < this.options.numFrames; i++) {
      const imageId = i === 0 ? baseUrl : baseUrl + "?frame=" + i;
      imageIds.push(imageId);
    }
    return imageIds;
  }

  addFrames() {
    const imageIds = this.getImageIds();
    if (!imageIds) return;
    const container = this.getContainer();
    const stack = {
      currentImageIdIndex: 0,
      imageIds: imageIds,
    };
    cornerstoneTools.addStackStateManager(container, ["stack"]);
    cornerstoneTools.addToolState(container, "stack", stack);
  }

  setToolPassive(tool: ToolName): void {
    return cornerstoneTools.setToolPassiveForElement(this.getContainer(), tool);
  }

  setToolDisabled(tool: ToolName): void {
    cornerstoneTools.setToolDisabledForElement(this.getContainer(), tool, {
      showSVGCursors: true,
    });
    this.tool = "";
  }

  setToolActive(type: ToolName): void {
    if (type === ToolName.ResetAll) {
      cornerstone.reset(this.getContainer());
      if (this.tool) {
        cornerstoneTools.setToolDisabledForElement(
          this.getContainer(),
          this.tool
        );
      }
      this.setToolActive(ToolName.Pan);
      return;
    }
    if (this.tool) this.setToolPassive(this.tool);
    this.tool = type;
    const isPan = type === ToolName.Pan;
    cornerstoneTools.setToolActiveForElement(
      this.getContainer(),
      "ZoomMouseWheel",
      {
        mouseButtonMask: 4,
      }
    );
    cornerstoneTools.setToolActiveForElement(this.getContainer(), type, {
      mouseButtonMask: 1,
    });
    cornerstoneTools.setToolActiveForElement(
      this.getContainer(),
      isPan ? ToolName.Zoom : ToolName.Pan,
      {
        mouseButtonMask: 2,
      }
    );
  }

  updateImage() {
    cornerstone.updateImage(this.element);
  }

  importAnnotations(
    annotations: Annotation[],
    relationAnnotations: RelationAnnotation[]
  ) {
    this.measurements = {};
    this.relationMeasurements = {};
    this.annoToMeasurements = {};
    this.relaToMeasurements = {};
    this.setImageAnnotations(annotations);
    this.setImageRelationAnnotations(relationAnnotations);
    this.setToolActive(ToolName.Pan);

    this.updateImage();
  }

  setImageAnnotations(annotations: Annotation[]): void {
    this.imageAnnotations = annotations;
    cornerstoneTools.clearToolState(
      this.getContainer(),
      ToolName.FreehandRoiExtend
    );
    cornerstoneTools.clearToolState(
      this.getContainer(),
      ToolName.RectangleRoiExtend
    );
    const imageIds = this.getImageIds();
    for (let annotation of annotations) {
      if (annotation.isSystemLabel) continue;
      annotation.locked = true;
      const measurement = annotationUtils.toMeasurement(annotation);
      if (!measurement) continue;
      this.measurements[measurement.uuid] = measurement;
      this.annoToMeasurements[annotation.uuid] = measurement;
      const cornerstoneToolName = annotateTypeMapper(
        annotation.annotationData[0].type
      );
      if (!cornerstoneToolName) continue;
      cornerstoneTools.setToolActiveForElement(
        this.getContainer(),
        cornerstoneToolName,
        { mouseButtonMask: 1 }
      );
      if (!imageIds || imageIds.length === 0) {
        cornerstoneTools.addToolState(
          this.getContainer(),
          cornerstoneToolName,
          measurement
        );
      } else if (!measurement.frameId) {
        const imageId = imageIds[0];
        cornerstoneTools.globalImageIdSpecificToolStateManager.addImageIdToolState(
          imageId,
          cornerstoneToolName,
          measurement
        );
      } else {
        const imageId = imageIds[measurement.frameId];
        cornerstoneTools.globalImageIdSpecificToolStateManager.addImageIdToolState(
          imageId,
          cornerstoneToolName,
          measurement
        );
      }
    }
    this.setToolActive(ToolName.Pan);
  }

  setImageRelationAnnotations(annotations: RelationAnnotation[]): void {
    this.relationAnnotations = annotations;
    cornerstoneTools.clearToolState(
      this.getContainer(),
      ToolName.MultiArrowConnection
    );
    cornerstoneTools.setToolActiveForElement(
      this.getContainer(),
      ToolName.MultiArrowConnection,
      { mouseButtonMask: 1 }
    );
    for (let annotation of annotations) {
      annotation.locked = true;
      const measurement = annotationUtils.toRelationMeasurement(
        annotation,
        this.measurements
      );
      if (!measurement) continue;

      this.relationMeasurements[measurement.uuid] = measurement;
      this.relaToMeasurements[annotation.uuid] = measurement;

      cornerstoneTools.addToolState(
        this.getContainer(),
        ToolName.MultiArrowConnection,
        measurement
      );
    }
  }

  updateVisibleFromAnnotations(annotations: Annotation[]) {
    let needUpdateImage = false;
    for (const anno of annotations) {
      const measurement = this.annoToMeasurements[anno.uuid];
      if (measurement) {
        if (measurement.visible !== anno.visible) {
          measurement.visible = anno.visible;
          needUpdateImage = true;
        }
        if (measurement.iouHidden !== anno.iouHidden) {
          measurement.iouHidden = anno.iouHidden;
          needUpdateImage = true;
        }
      }
    }
    if (needUpdateImage) {
      this.updateImage();
    }
  }

  updateVisibleFromRelationAnnotations(annotations: RelationAnnotation[]) {
    let needUpdateImage = false;
    for (const anno of annotations) {
      const measurement = this.relaToMeasurements[anno.uuid];
      if (measurement) {
        if (measurement.visible !== anno.visible) {
          measurement.visible = anno.visible;
          needUpdateImage = true;
        }
      }
    }
    if (needUpdateImage) {
      this.updateImage();
    }
  }

  getClickedLabelTextboxAnnotation(canvasPosition: {
    x: number;
    y: number;
  }): Annotation | null {
    for (let i = 0; i < this.imageAnnotations.length; i++) {
      // const annotation = this.annotations[i];
      // if (!annotation.measurement.showLabel || !annotation.measurement.visible)
      //   continue;
      // const textBox = annotation.measurement.handles.textBox;
      // if (textBox && textBox.boundingBox) {
      //   if (this.isPointInsideBox(canvasPosition, textBox.boundingBox)) {
      //     return annotation;
      //   }
      // }
    }
    return null;
  }

  isPointInsideBox(
    { x, y }: { x: number; y: number },
    {
      top,
      left,
      height,
      width,
    }: { top: number; left: number; height: number; width: number }
  ): boolean {
    const minX = left;
    const maxX = minX + width;
    const minY = top;
    const maxY = minY + height;
    return minX <= x && x <= maxX && minY <= y && y <= maxY;
  }

  setFillPolygon(fillPolygon: boolean): void {
    this.fillPolygon = fillPolygon;
    for (let annotation of this.imageAnnotations) {
      const measurement = this.annoToMeasurements[annotation.uuid];
      if (measurement) {
        measurement.fillPolygon = fillPolygon;
      }
    }
    this.updateImage();
  }

  setShowLabel(visible: boolean): void {
    this.showLabel = visible;
    for (let annotation of this.imageAnnotations) {
      const measurement = this.annoToMeasurements[annotation.uuid];
      if (measurement) {
        measurement.showLabel = visible;
      }
    }
    this.updateImage();
  }

  setDisplayTextValue(display: boolean): void {
    this.displayTextValue = display;
    this.updateDisplayTextValue();
    this.updateImage();
  }

  updateDisplayTextValue() {
    for (let anno of this.imageAnnotations) {
      const measurement = this.annoToMeasurements[anno.uuid];
      if (measurement) {
        measurement.displayTextValue = this.displayTextValue;
      }
    }
    this.updateImage();
  }

  setAllAnnotationVisible(visible: boolean, updateImage: boolean = true) {
    for (let anno of this.imageAnnotations) {
      const measurement = this.annoToMeasurements[anno.uuid];
      if (measurement) {
        measurement.visible = visible;
      }
    }
    for (let anno of this.relationAnnotations) {
      const measurement = this.relaToMeasurements[anno.uuid];
      if (measurement) {
        measurement.visible = visible;
      }
    }

    if (updateImage) {
      this.updateImage();
    }
  }

  getAnnotationVisibleMap() {
    const res: Record<string, boolean> = {};
    for (const anno of this.imageAnnotations) {
      const measurement = this.annoToMeasurements[anno.uuid];
      if (measurement) {
        res[anno.uuid] = measurement.visible;
      }
    }
    for (const anno of this.relationAnnotations) {
      const measurement = this.relaToMeasurements[anno.uuid];
      if (measurement) {
        res[anno.uuid] = measurement.visible;
      }
    }
    return res;
  }

  setAnnotationVisibleFromMap(visibleMap: Record<string, boolean>) {
    for (const anno of this.imageAnnotations) {
      const measurement = this.annoToMeasurements[anno.uuid];
      if (measurement && visibleMap[anno.uuid]) {
        measurement.visible = visibleMap[anno.uuid];
      }
    }
    for (const anno of this.relationAnnotations) {
      const measurement = this.relaToMeasurements[anno.uuid];
      if (measurement && visibleMap[anno.uuid]) {
        measurement.visible = visibleMap[anno.uuid];
      }
    }

    this.updateImage();
  }

  setAnnotationVisibleByLabelIdAndAssignee(
    labelId: number,
    assignee: string,
    visible: boolean
  ) {
    for (const anno of this.imageAnnotations) {
      const measurement = this.annoToMeasurements[anno.uuid];
      if (
        measurement &&
        anno.annotator === assignee &&
        anno.labelId === labelId
      ) {
        measurement.visible = visible;
      }
    }
    for (const anno of this.relationAnnotations) {
      const measurement = this.relaToMeasurements[anno.uuid];
      if (measurement && anno.annotator === assignee) {
        measurement.visible = visible;
      }
    }

    this.updateImage();
  }

  dispose() {
    this.clearAllToolStates();
    cornerstone.disable(this.element);
    try {
      if (this.currentImageId) {
        cornerstone.imageCache.removeImageLoadObject(this.currentImageId);
      } else cornerstone.imageCache.purgeCache();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }
}
