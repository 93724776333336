/*
 * File: labeler-distribution.state.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 9:57:24 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BaseState, RequestStatus } from "store/base/base.state";

export interface SummaryDTO {
  f1score: Record<string, number>;
  classificationAgree: Record<string, number>;
  segmentationAgree: any;
  finishedTask: number;
  avgIou: any;
  avgProbability: any;
  startDate: string;
  lastModifiedDate: string;
  taskPerHour: number;
}

export interface F1DTO {
  precision: number;
  observation: string;
  assignee: string;
  f1: string;
  fp: string;
  sensitivity: string;
  specificity: string;
  tn: number;
  fn: number;
  tp: number;
}

export interface LabelDistributionDTO {
  email: string;
  summary: SummaryDTO;
  f1Detail: {
    data: F1DTO[];
  };
}

export interface LabelerDistributionDTO {
  /**BatchId */
  id: number;
  labelers: Record<string, LabelDistributionDTO>;
}

export interface LabelerDistributionState
  extends BaseState<LabelerDistributionDTO> {}

export const INITIAL_LABELER_DISTRIBUTION_STATE: LabelerDistributionState = {
  entities: {},
  ids: [],
  id: -1,
  status: RequestStatus.IDLE,
  error: null,
};
