import * as csTools from "cornerstone-tools";
const path = csTools.import("drawing/path");

export default function drawIcon(context, iconPath, x, y) {
  const options = {}
  const image = new Image();
  image.src = iconPath;

  image.onload = loadImage()
  function loadImage() {
    if (!image.complete) return;
    path(context, options, (ctx) => {
      ctx.save();
      ctx.drawImage(image, x, y);
      ctx.restore();
    });
  }
}