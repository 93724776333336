/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:12 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { configureStore, Action, ThunkAction } from "@reduxjs/toolkit";
import { batchReducer } from "./customer/batch/batch.slice";
import { conerstoneReducer } from "./labeler/image-workspace/cornerstone/cornerstone.slice";
import { datasetReducer } from "./customer/dataset/dataset.slice";
import { dicomEditorReducer } from "./labeler/image-workspace/dicom-editor/dicom-editor.slice";
import { notificationReducer } from "./common/notification/notification.reducer";
import { projectReducer } from "./customer/project/project.slice";
import { projectsReducer } from "./customer/projects/projects.slice";
import { userWorkspaceReducer } from "./common/user-workspace/user-workspace.slice";
import { workflowReducer } from "./customer/workflow/workflow.slice";
import { batchDataReducer } from "./customer/batch-data/batch-data.slice";
import { scopeReducer } from "./customer/scope/scope.slice";
import { exportedDatasetsReducer } from "./customer/exported-datasets/exported-datasets.slice";
import { pipelineReducer } from "./customer/pipeline/pipeline.slice";
import { trainingJobReducer } from "./customer/training-jobs/training-jobs.slice";
import { userNotificationReducer } from "./common/user-notification/user-notification.slice";
import { projectDataReducer } from "./customer/project-data/project-data.slice";
import { textWorkspaceReducer } from "./labeler/text-workspace/text-workspace.slice";
import { authReducer } from "./auth/auth.slice";
import { tasksReviewReducer } from "./customer/tasks-review/tasks-review.slice";
import { batchLabelingReducer } from "./labeler/image-workspace/batch-labeling/batch-labeling.slice";
import { smartLabelingReducer } from "./labeler/image-workspace/smart-labeling/smart-labeling.slice";
import { usersReducer } from "./customer/users/users.slice";
import { dashBoardReducer } from "./customer/dashboard/dashboard.slice";
import { paymentReducer } from "./common/payment/payment.slice";
import { aiModelsReducer } from "./customer/ai-models/ai-models.slice";
import { aiModelVersionsReducer } from "./customer/ai-model-versions/ai-model-versions.slice";
import { taskRunnerReducer } from "./customer/task-runner/task-runner.slice";
import { issueManagementReducer } from "./common/issue-management/issue-management.slice";
import { editorSettingReducer } from "./labeler/image-workspace/editor-setting/editor-setting.slice";
import { appSettingReducer } from "./common/app-setting/app-setting.slice";
import { complexJobsReducer } from "./labeler/complex-jobs/complex-jobs.slice";
import { textAIReducer } from "./labeler/text-workspace/text-ai/text-ai.slice";
import { textLabelingBatchReducer } from "./labeler/text-labeling-batch/text-labeling-batch.slice";
import { textLabelingTaskReducer } from "./labeler/text-labeling-task/text-labeling-task.slice";
import { textEditorSettingReducer } from "./labeler/text-workspace/text-editor-setting/text-editor-setting.slice";
import { imageWorkspaceReducer } from "./labeler/image-workspace/image-workspace.slice";
import { mammographyLabelingTaskReducer } from "./labeler/mammography-labeling-task/mammography-labeling-task.slice";
import { customerSupportReducer } from './common/customer-support/customer-support.slice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    notification: notificationReducer,
    payment: paymentReducer,
    issueManagement: issueManagementReducer,
    // customer
    dataset: datasetReducer,
    project: projectReducer,
    projectData: projectDataReducer,
    projects: projectsReducer,
    userWorkspace: userWorkspaceReducer,
    workflow: workflowReducer,
    batch: batchReducer,
    batchData: batchDataReducer,
    scope: scopeReducer,
    exportedDatasets: exportedDatasetsReducer,
    pipeline: pipelineReducer,
    trainingJobs: trainingJobReducer,
    userNotification: userNotificationReducer,
    tasksReview: tasksReviewReducer,
    dashBoard: dashBoardReducer,
    aiModels: aiModelsReducer,
    aiModelVersions: aiModelVersionsReducer,
    // labeler
    batchLabeling: batchLabelingReducer,
    imageWorkspace: imageWorkspaceReducer,
    mammographyLabelingTask: mammographyLabelingTaskReducer,
    complexJobs: complexJobsReducer,
    cornerstone: conerstoneReducer,
    textWorkspace: textWorkspaceReducer,
    textLabelingBatch: textLabelingBatchReducer,
    textLabelingTask: textLabelingTaskReducer,
    textEditorSetting: textEditorSettingReducer,
    dicomEditor: dicomEditorReducer,
    smartLabeling: smartLabelingReducer,
    users: usersReducer,
    taskRunner: taskRunnerReducer,
    editorSetting: editorSettingReducer,
    appSetting: appSettingReducer,
    textAI: textAIReducer,
    customerSupport: customerSupportReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ["dashBoard", "issueManagement", "complexJobs"],
        ignoredActions: [
          "dashboard/updateCancleTokensMap",
          "dashboard/setDashBoardFromDate",
          "dashboard/setDashBoardToDate",
          "issueManagement/loadIssuesAsync",
          "issueManagement/updateIssueAsync/fulfilled",
          "issueManagement/updateIssue",
          "issueManagement/createIssueAsync/fulfilled",
          "issueManagement/resolveIssueAndUpdate/fulfilled",
          "issueManagement/deleteIssueAndUpdate/fulfilled",
          "issueManagement/openIssueAndUpdate/fulfilled",
          "issueManagement/updateIssueComment",
          "issueManagement/deleteIssueComment",
          "complexJobs/pollComplexBatchJobsAsync/fulfilled",
          "complexJobs/pollComplexJobComplexAsync/fulfilled",
          "complexJobs/setComplexJobsError",
          "complexJobs/setComplexJobsJobData",
          "complexJobs/setComplexJobsBatchJobFromComplexRes",
        ],
      },
    }),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
