export interface TaskRunner {
  createdBy: string;
  createdDate: string;
  data: string;
  id: number;
  jobType: TaskRunnerType;
  lastModifiedBy: string;
  lastModifiedDate: string;
  payload: string;
  error: string;
  percentComplete: number;
  status: TaskRunnerStatus;
  workspaceId: string;
}

export enum TaskRunnerStatus {
  INITIAL = "INITIAL",
  RUNNING = "RUNNING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
  CANCELED = "CANCELED",
}

export enum TaskRunnerType {
  ANNOTATION_IMPORT = "ANNOTATION_IMPORT",
  ANNOTATION_GENERATE = "ANNOTATION_GENERATE",
  CLOUD_SYNC = "CLOUD_SYNC",
  ARCHIVE_UPLOAD = "ARCHIVE_UPLOAD",
  DOWNLOAD_DATASET = "DOWNLOAD_DATASET",
  BATCH_STATISTIC_REPORT = "BATCH_STATISTIC_REPORT",
  IMPORT_MODEL = "IMPORT_MODEL",
  IMPORT_CUSTOM_MODEL = "IMPORT_CUSTOM_MODEL",
  BATCH_ANNOTATE = "BATCH_ANNOTATE",
  CREATE_BATCH = "ANNOTATION_JOB_GENERATE",
  TRAIN_MODEL = "PIPELINE",
  DOWNLOAD_MODEL = "DOWNLOAD_MODEL",
  BATCH_MANUAL_ASSIGN_JOB = "BATCH_MANUAL_ASSIGN_JOB",
}
