/*
 * File: ml-models.page.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 30th August 2021 4:30:44 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { MLModelProvider } from "./ml-models.context";
import { MLModelRouter } from "./ml-models.route";

export const MLModelsPage = () => {
  return (
    <MLModelProvider>
      <div className="w-full h-full overflow-y-auto">
        <MLModelRouter />
      </div>
    </MLModelProvider>
  );
};
