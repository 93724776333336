/*
 * File: project-batch.page.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 5th August 2021 2:26:33 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBPageTitle } from "components/common/vb-page-title.component";
import { VBSearchFilter } from "components/common/vb-search-filter.component";
import { VBSpacer } from "components/common/vb-spacer.component";
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { BatchDTO, ProjectDTO } from "services/label-service/dtos";
import { BatchTable } from "./components/batch-table.component";
import { CreateBatch as CreateBatchV1 } from "./components/create-batch.component";
import { CreateBatchProvider } from "./components/create-batch.context";
import { DeleteBatchDialog } from "./components/delete-batch.dialog";
import { UpdateBatchDialog } from "./components/update-batch/update-batch.component";
import { useBatches } from "hooks/batch/use-batches.hook";
import { useAppSelector } from "hooks/use-redux";
import { selectIsProjectFromTemplate } from "store/customer/project/project.selectors";
import { EntityAction } from "domain/common/entity-action";
import { UpdateBatchModal } from "./batch-detail/pages/overview/components/update-batch/update-batch.modal";

interface Props {
  project: ProjectDTO;
}
export const ProjectBatchesPage = ({ project }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { batches } = useBatches(project.id);
  const isProjectFromTemplate = useAppSelector(selectIsProjectFromTemplate);

  const [deletingtItem, setDeletingItem] = useState<BatchDTO | null>(null);
  const [editingBatch, setEditingBatch] = useState<BatchDTO | null>(null);
  const [updatingBatch, setUpdatingBatch] = useState<BatchDTO | null>(null);

  function handleSelect(batch: BatchDTO, action?: EntityAction) {
    if (action === EntityAction.VIEW) {
      history.push(`${url}/${batch.id}`);
    } else if (action === EntityAction.DELETE) {
      setDeletingItem(batch);
    } else if (action === EntityAction.EDIT) {
      setEditingBatch(batch);
    } else if (action === EntityAction.UPDATE_STATUS) {
      setUpdatingBatch(batch);
    } else if (!action) {
      history.push(`${url}/${batch.id}`);
    }
  }

  const hanldeClickCreateBatchV2 = () => {
    const parts = url.split("/");
    parts[parts.length - 1] = "create-batch";
    const redirectUrl = parts.join("/");
    history.push(`${redirectUrl}`);
  };

  return (
    <CreateBatchProvider>
      <div className="h-full">
        <div className="flex items-center my-4">
          <VBPageTitle text={t("project:batch.title")} />
          <VBSpacer />
          <VBSearchFilter placeholder={t("common:placeholderSearch")} />
          {isProjectFromTemplate ? (
            <button
              className="ml-2 button-primary"
              onClick={hanldeClickCreateBatchV2}
            >
              {t("project:batch.buttonCreate")}
            </button>
          ) : (
            <CreateBatchV1 />
          )}
        </div>
        <BatchTable batches={batches} onSelect={handleSelect} />
      </div>

      {deletingtItem && (
        <DeleteBatchDialog
          batch={deletingtItem}
          handleClose={() => setDeletingItem(null)}
        />
      )}
      {updatingBatch && (
        <UpdateBatchDialog
          batch={updatingBatch}
          handleClose={() => setUpdatingBatch(null)}
        />
      )}

      {editingBatch && (
        <UpdateBatchModal
          onClose={() => setEditingBatch(null)}
          batch={editingBatch}
        />
      )}
    </CreateBatchProvider>
  );
};
