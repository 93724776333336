/*
 * File: text-editor.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 27th October 2021 3:01:09 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useSize } from "ahooks";
import { collectionUtils } from "domain/common";
import { CORAnnotation, NERAnnotation, Token } from "domain/text-labeling";
import { Label } from "domain/text-labeling";
import { useAppSelector } from "hooks/use-redux";
import { useRef, useState, useMemo, useEffect, Fragment } from "react";
import {
  selectActiveLabelId,
  selectActiveRelationId,
  selectActiveTokenId,
  selectActiveTokenObservationId,
  selectTextIsShowRelationName,
} from "store/labeler/text-workspace/text-editor/text-editor.selector";
import { selectTextIssueEntities } from "store/labeler/text-workspace/text-issues/text-issues.selector";
import { useTextSelection } from "../../hooks/use-text-selection.hook";
import {
  BoundingBox,
  TokenData,
  AnnotationData,
} from "../../models/text-viewer.models";
import { AnnotationComponent } from "./annotation.component";
import { TokenComponent } from "../token.component";
import { RelationAnnotationComponent } from "./relation-annotation.component";
import { RelationLine, TextViewerController } from "./text-viewer-handle";
import { useEditTextSelection } from "./use-edit-text-selection.hook";
import DragCursor from "./drag-cursor.component";
import { Rectangle } from "utilities/math/rectangle";
import useDeepCompareEffect from "use-deep-compare-effect";
import { IssueStatus } from "domain/common/issue";

interface TextEditorProps {
  tokenIds: string[];
  tokenEntities: Record<string, Token>;
  boundingBoxes: Record<string, BoundingBox>;
  labels: Label[];
  observations: NERAnnotation[];
  activeTokenObservationId?: string;
  connections: CORAnnotation[];
  conflictTokenIds?: Record<string, string>;
  onSelectToken?(token: TokenData): void;
  onSelectRelation?(relationId: string): void;
  onSelectAnnotation?(annotationId: string): void;
  onMouseEnterRelation?(relationId: string): void;
  onMouseLeaveRelation?(relationId: string): void;
  readonly?: boolean;
}
export const TextEditor = ({
  readonly = false,
  tokenIds,
  tokenEntities,
  boundingBoxes,
  labels,
  observations,
  connections,
  conflictTokenIds = {},
  onSelectToken,
  onSelectRelation,
  onSelectAnnotation,
  onMouseEnterRelation,
  onMouseLeaveRelation,
}: TextEditorProps) => {
  const activeLabelId = useAppSelector(selectActiveLabelId);
  const issueEntities = useAppSelector(selectTextIssueEntities);
  const activeTokenOvservationId = useAppSelector(
    selectActiveTokenObservationId
  );
  const activeTokenId = useAppSelector(selectActiveTokenId);
  const showRelationName = useAppSelector(selectTextIsShowRelationName);
  const activeRelationId = useAppSelector(selectActiveRelationId);
  const containerRef = useRef<HTMLDivElement>(null);
  const size = useSize(containerRef);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [hightlightTokens, setHighlightTokens] = useState<
    Record<string, string>
  >({});
  const labelCollection = collectionUtils.fromEntities(labels);

  const connectionEntities = useMemo(() => {
    const entities: Record<string, CORAnnotation> = {};
    for (let connection of connections) {
      entities[connection.id] = connection;
    }
    return entities;
  }, [connections]);

  const controller = useRef(
    new TextViewerController(
      tokenIds,
      tokenEntities,
      boundingBoxes,
      labelCollection,
      observations,
      connections
    )
  );

  const [tokens, setTokens] = useState<TokenData[]>([]);
  const [relationLines, setRelationLines] = useState<{
    [key: string]: RelationLine;
  }>({});
  const [sentencesData, setSentencesData] = useState<
    {
      sentenceIndex: number;
      bbox: Rectangle;
      indicatorBBox: Rectangle;
    }[]
  >([]);
  const [annotations, setAnnotations] = useState<AnnotationData[]>([]);

  const {
    boundingBoxes: selectedBoundingBoxes,
    leftPoint,
    rightPoint,
    selectedTokenIds,
  } = useTextSelection(controller.current, containerRef.current, readonly);
  const { selectedTokens: textSelection } = useEditTextSelection(
    controller.current,
    containerRef.current
  );

  useEffect(() => {
    if (!size?.width) return;
    setWidth(size.width);
  }, [size]);

  useDeepCompareEffect(() => {
    if (width === 0) return;
    controller.current.setAnnotationsData(observations, connections);
    const {
      tokens: items,
      annotations: itemObservations,
      relationLines,
      sentences: sentencesData,
    } = controller.current.update(width);
    const containerHeight = controller.current.getContainerHeight();
    setHeight(containerHeight);
    setTokens(items);
    setAnnotations(itemObservations);
    setRelationLines(relationLines);
    setSentencesData(sentencesData);
  }, [width, observations, connections]);

  useDeepCompareEffect(() => {
    const items: Record<string, string> = {};
    const data = controller.current.getTokenObservationData(
      activeTokenOvservationId
    );
    if (data && data.observation) {
      const { tokenIds, observation } = data;
      for (let tokenId of tokenIds) {
        items[tokenId] = observation.color;
      }
    }
    setHighlightTokens(items);
  }, [activeTokenOvservationId, annotations]);

  return (
    <div
      className="relative w-full h-full text-viewer-container"
      ref={containerRef}
    >
      <svg x={0} y={0} width={width} height={height}>
        <rect
          className="pointer-events-none"
          style={{ zIndex: -1 }}
          x={0}
          y={0}
          width={40}
          height={height}
          fill="#E8E8E8"
        />
        {sentencesData.map((sentence) => {
          return (
            <Fragment key={sentence.sentenceIndex}>
              {sentence.sentenceIndex % 2 === 1 && (
                <rect
                  className="pointer-events-none"
                  style={{ zIndex: -1 }}
                  data-sentence-index={sentence.sentenceIndex + 1}
                  x={sentence.bbox.x}
                  y={sentence.bbox.y}
                  width={sentence.bbox.width}
                  height={sentence.bbox.height}
                  fill="#E8E8E8"
                  opacity={0.4}
                />
              )}
              <text
                x={sentence.indicatorBBox.x + sentence.indicatorBBox.width / 2}
                y={sentence.indicatorBBox.y}
                fill="black"
                textAnchor="middle"
                opacity={0.4}
              >
                {sentence.sentenceIndex + 1}
              </text>
            </Fragment>
          );
        })}
        {selectedBoundingBoxes.map((rect, index) => {
          return (
            <rect
              key={rect.x + ":" + index}
              x={rect.x}
              y={rect.y}
              width={rect.width}
              height={rect.height}
              fill="#B4D5FE"
            />
          );
        })}
        {textSelection?.bboxes.map((rect, index) => {
          return (
            <rect
              className="pointer-events-none select-none"
              key={"edit" + rect.x + ":" + index}
              x={rect.x}
              y={rect.y}
              width={rect.width}
              height={rect.height}
              fill="#B4D5FE"
              opacity={0.4}
            />
          );
        })}

        {tokens.map((token) => {
          const isActive = token.id === activeTokenId;
          const isHightlight = hightlightTokens.hasOwnProperty(token.model.id);
          const color = isHightlight
            ? hightlightTokens[token.model.id]
            : "#000000";
          const isConflict = conflictTokenIds.hasOwnProperty(token.model.id);
          const issueId = `-1:${token.id}`;
          const hasIssue =
            issueEntities.hasOwnProperty(issueId) &&
            issueEntities[issueId].status === IssueStatus.OPENED;

          let background = "";
          if (isConflict) background = "#f8e200";
          if (hasIssue) background = "#f8e200";
          if (isActive) background = "#B4D5FE";

          return (
            <TokenComponent
              onClick={() => onSelectToken && onSelectToken(token)}
              key={token.model.id}
              model={token.model}
              box={token.box}
              color={color}
              background={background}
            />
          );
        })}

        {annotations.map((observation) => {
          let visible = true;
          if (activeLabelId) {
            visible = observation.observationId === activeLabelId;
          }
          let active = activeTokenOvservationId === observation.annotationId;
          if (
            !active &&
            activeRelationId &&
            connectionEntities.hasOwnProperty(activeRelationId)
          ) {
            const activeRelation = connectionEntities[activeRelationId];
            if (activeRelation) {
              active =
                activeRelation.from === observation.annotationId ||
                activeRelation.to === observation.annotationId;
            }
          }

          const issue = issueEntities.hasOwnProperty(observation.annotationId)
            ? issueEntities[observation.annotationId]
            : undefined;

          if (!visible) return null;

          const label = collectionUtils.getOne(
            labelCollection,
            observation.observationId
          );
          if (!label) return null;
          return (
            <AnnotationComponent
              annotation={observation.annotation}
              issue={issue}
              active={active}
              onSelect={onSelectAnnotation}
              label={label}
              tokenObservation={observation}
              key={observation.annotationId}
              relationIds={observation.relationIds}
              lineIndex={observation.lineIndex}
            />
          );
        })}
        {connections.map((relation) => {
          const color =
            relation.hasOwnProperty("reviewStatus") &&
            !!(relation as any)["reviewStatus"]
              ? "#8e9aab"
              : relation.color;
          return (
            <RelationAnnotationComponent
              id={relation.id}
              key={relation.id}
              from={relation.from}
              to={relation.to}
              text={relation.text}
              color={color}
              showRelationName={showRelationName}
              data={relationLines[relation.id]}
              onSelect={onSelectRelation}
              onMouseEnter={onMouseEnterRelation}
              onMouseLeave={onMouseLeaveRelation}
            />
          );
        })}

        {leftPoint && (
          <DragCursor
            direction="left"
            position={leftPoint}
            from={selectedTokenIds[0]}
            to={selectedTokenIds[selectedTokenIds.length - 1]}
          />
        )}
        {rightPoint && (
          <DragCursor
            direction="right"
            position={rightPoint}
            to={selectedTokenIds[0]}
            from={selectedTokenIds[selectedTokenIds.length - 1]}
          />
        )}
      </svg>
    </div>
  );
};
