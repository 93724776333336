import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { collectionUtils } from "domain/common";
import { AnnotationSource } from "domain/labeling/annotation-source";
import { ImageLabelingData } from "domain/labeling/labeling-data";
import { RootState } from "store";
import { ImageWorkspaceState } from "../../image-workspace.state";

const THUNK_NAME = "imageWorkspace/imageLabelingJobLoadedAsync";

interface Payload {
  jobId: number;
}
export const imageLabelingJobLoadedAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: Payload, { getState }) => {
    const { jobId } = payload;
    const state = getState() as RootState;
    const { imageLabelingJobs } = state.batchLabeling;
    const labelingJob = collectionUtils.getOne(imageLabelingJobs, jobId);
    return labelingJob;
  }
);

export function imageLabelingJobLoadedBuilder(
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) {
  builder.addCase(
    imageLabelingJobLoadedAsync.fulfilled,
    ({ imageAnnotations }, action) => {
      const imageLabelingJob = action.payload;
      if (!imageLabelingJob) return;

      // Add anno from import file or AI model
      // we convert all those annos to labeler annos
      const addImportAnnos = (jobData: ImageLabelingData) => {
        const { annotations, relationAnnotations } = jobData;

        for (const annotation of annotations) {
          imageAnnotations.annotations.push({
            ...annotation,
            jobId: imageLabelingJob.job.id,
            annotator: imageLabelingJob.job.assignee,
            source: AnnotationSource.CLIENT,
          });
        }
        for (const relation of relationAnnotations) {
          imageAnnotations.relationAnnotations.push({
            ...relation,
            jobId: imageLabelingJob.job.id,
            annotator: imageLabelingJob.job.assignee,
            source: AnnotationSource.CLIENT,
          });
        }
      }

      if (imageLabelingJob?.data?.jobData) {
        const jobData = imageLabelingJob.data.jobData as ImageLabelingData;
        if (jobData) {
          const { annotations, relationAnnotations } = jobData;
          for (const annotation of annotations) {
            imageAnnotations.annotations.push(annotation);
          }
          for (const relation of relationAnnotations) {
            imageAnnotations.relationAnnotations.push(relation);
          }
        }
      } else if (imageLabelingJob?.data?.importedData) {
        const jobData = imageLabelingJob.data.importedData as ImageLabelingData;
        addImportAnnos(jobData);
      } else if (imageLabelingJob?.data?.generatedData) {
        const jobData = imageLabelingJob.data.generatedData as ImageLabelingData;
        addImportAnnos(jobData);
      }
    }
  );
}
