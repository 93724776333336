/*
 * File: ml-models.context.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 30th August 2021 5:56:06 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Breadcrumb } from "components/common/vb-breadcrumb/breadcrumb.model";
import { useMLModelBreadcrumbs } from "hooks/ml-models/use-ml-models-breadcrumbs";
import { createContext, useContext, useEffect, useState } from "react";
import { MlPipelineResponse } from "services/ml-service/ml-service.dto";
import { mlServiceApis } from "services/ml-service/ml.service";
import { MlModelsPipelineOption, MlModelsRunInfo } from "./ml-models.models";
import * as Sentry from "@sentry/react";

interface MLModelContextState {
  loading: boolean;
  setLoading(loading: boolean): void;
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs(breadcrumbs: Breadcrumb[]): void;
  pipelineOptions: MlModelsPipelineOption[];
  currentRunInfo: MlModelsRunInfo | undefined;
  setCurrentRunInfo: (v: MlModelsRunInfo | undefined) => void;
}

export const MLModelContext = createContext({} as MLModelContextState);

export const useMLModelContext = () => {
  return useContext(MLModelContext);
};

interface MLModelProviderProps {
  children: React.ReactNode | React.ReactNode[] | null;
}

export const MLModelProvider = ({ children }: MLModelProviderProps) => {
  const [loading, setLoading] = useState(true);
  const { breadcrumbs, setBreadcrumbs } = useMLModelBreadcrumbs();
  const [pipelineOptions, setPipelineOptions] = useState<MlModelsPipelineOption[]>([]);
  const [currentRunInfo, setCurrentRunInfo] = useState<MlModelsRunInfo>();

  useEffect(() => {

    const loadData = async () => {
      try {
        const res = await mlServiceApis.getPipelines();
        setPipelineOptions(res.map((v: MlPipelineResponse) => ({
          label: v.name,
          value: v,
        })));
      } catch (error: any) {
        Sentry.captureException(error);
        console.log(error);
      }
    }

    loadData();

  }, []);

  const value: MLModelContextState = {
    loading,
    breadcrumbs,
    setBreadcrumbs,
    setLoading,
    pipelineOptions,
    currentRunInfo,
    setCurrentRunInfo,
  };
  return (
    <MLModelContext.Provider value={value}>{children}</MLModelContext.Provider>
  );
};
