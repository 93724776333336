/*
 * File: draw-handles.js
 * Project: app-aiscaler-web
 * File Created: Friday, 3rd December 2021 11:04:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import * as csTools from "cornerstone-tools";
const drawJoinedLines = csTools.importInternal("drawing/drawJoinedLines");
const external = csTools.external;

/**
 * Draws proivded handles to the provided context
 * @public
 * @method drawHandles
 * @memberof Drawing
 *
 * @param {CanvasRenderingContext2D} context - Target context
 * @param {*} evtDetail - Cornerstone's 'cornerstoneimagerendered' event's `detail`
 * @param {Object} rect - Bounding of the measurement
 * @param {Object} [options={}] - Options object
 * @param {string} [options.color]
 * @param {Boolean} [options.drawHandlesIfActive=false] - Whether the handles should only be drawn if Active (hovered/selected)
 * @param {string} [options.fill]
 * @param {Number} [options.handleRadius=6]
 * @returns {undefined}
 */
export default function (context, evtDetail, polygons, options = {}) {
  const { element } = evtDetail;
  for (const polygon of polygons) {
    const { color, data: points } = polygon;
    if (points.length < 3) continue;
    const options = { color: color };
    drawJoinedLines(
      context,
      element,
      points[points.length - 1],
      points,
      options
    );
    drawPolygon(context, element, points, color);
  }
}

function drawPolygon(context, element, points, color, opacity = 0.2) {
  if (!context || !points || points.length === 0) return;
  let start = points[0];
  start = external.cornerstone.pixelToCanvas(element, start);
  points = points.map((p) => external.cornerstone.pixelToCanvas(element, p));
  context.globalAlpha = opacity;
  context.fillStyle = color;
  context.beginPath();
  for (let i = 0; i < points.length; i++) {
    const point = points[i];
    if (i === 0) {
      context.moveTo(point.x, point.y);
    } else {
      context.lineTo(point.x, point.y);
    }
  }
  context.closePath();
  context.fill();
  context.globalAlpha = 1;
}
