/*
 * File: open-project-list.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 26th April 2022 11:16:16 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useQuery } from "react-query";
import { labelerProjectsApi } from "services/label-service/apis/labeler-projects.api";
import { OpenProject } from "./open-project.component";
import useProjectTemplates from "pages/customer/projects/components/project-templates/hooks/use-project-templates.hook";
import { useMemo } from "react";
interface Props {
  title?: string;
}
export function OpenProjectList({ title = "Open projects" }: Props) {
  const { data, isLoading } = useQuery(
    "labelerProjects",
    labelerProjectsApi.getProjects
  );

  const { templates } = useProjectTemplates();

  const projects = useMemo(() => {
    if (!data) return [];
    const imageMaps: Record<string, string> = {};
    for (const template of templates ?? []) {
      imageMaps[template.type] = template.imageUrl;
    }
    return data.map((item) => {
      if (imageMaps.hasOwnProperty(item.type)) {
        return {
          ...item,
          imageUrl: imageMaps[item.type],
        };
      }
      return { ...item };
    });
  }, [data, templates]);

  if (isLoading) return <div className="p-6">Loading...</div>;
  if (!projects) return null;

  return (
    <div className="w-full px-6">
      <h2 className="py-6 text-lg font-bold text-warning-500">{title}</h2>
      <div className="grid items-center grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        {projects.map((project) => {
          return <OpenProject key={project.name} data={project} />;
        })}
      </div>
    </div>
  );
}
