/*
 * File: vb-mask-requesting.component.tsx
 * Project: app-aiscaler-web
 * File Created: Sunday, 2nd January 2022 10:01:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { LinearProgress } from "@material-ui/core";

export const VBComponentRequesting = () => {
  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 z-50 w-full h-full overflow-hidden">
      <LinearProgress />
      <div className="flex items-center justify-center w-full h-full" />
    </div>
  );
};
