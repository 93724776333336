/*
 * File: model-type-selector.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 21st February 2022 10:21:50 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  IconDriver,
  IconNoteLinear,
  IconRouting,
  IconWarning,
} from "components/common/vb-icon.component";
import { Option } from "domain/common";
import { MLModelType } from "domain/web-builder";
import { classnames } from "utilities/classes";

interface ModelTypeSelectorProps {
  options: Option[];
  value: string;
  onChange?(value: string): void;
}

export const ModelTypeSelector = ({
  options,
  value,
  onChange,
}: ModelTypeSelectorProps) => {
  return (
    <div className="px-4">
      <div className="text-sm font-semibold">Type</div>
      <div className="flex items-center py-2 space-x-4">
        {options.map((option) => {
          const Icon = mapModelTypeToIcon(option.value as MLModelType);
          return (
            <button
              key={option.value}
              onClick={() => onChange && onChange(option.value)}
              className={classnames("flex-1", {
                "button-primary": value === option.value,
                "button-secondary": value !== option.value,
              })}
            >
              <Icon className="w-6 h-6" />
            </button>
          );
        })}
      </div>
    </div>
  );
};

const mapModelTypeToIcon = (type: MLModelType) => {
  if (type === MLModelType.IMAGE_CLASSIFICATION) return IconNoteLinear;
  if (type === MLModelType.IMAGE_SEGMENTATION) return IconRouting;
  if (type === MLModelType.OBJECT_DETECTION) return IconDriver;
  return IconWarning;
};
