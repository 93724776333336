/*
 * File: text-editor.state.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 5:02:30 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { CORAnnotation, NERAnnotation } from "domain/text-labeling";

export interface TextEditorState {
  autoSave: boolean;
  selectedSystemObservationId: number;
  selectedTokenIds: string[];
  showLabelInstruction: boolean;
  activeTokenObservationId: string;
  activeRelationId: string;
  activeLabelId: string;
  selectedLabelId: string;
  hiddenLabelIds: Record<string, boolean>;
  contextMenuTokenId: string;
  contextMenuAnnotationId: string;
  showPreviousJobs: boolean;
  observations: NERAnnotation[];
  relations: CORAnnotation[];
  editingAnnotationId: string;
  draggingObservationId: string;
  showRelation: boolean;
  showRelationName: boolean;
  showNavBar: boolean;
  showSideBar: boolean;
  showEntity: boolean;
  selectedAnnotation: {
    annotationId: string;
    contextMenu: boolean;
    issueMenu: boolean;
  };
  selectedToken: {
    tokenId: string;
    contextMenu: boolean;
    issueMenu: boolean;
  };
}

export const INITIAL_TEXT_EDITOR_STATE: TextEditorState = {
  autoSave: false,
  selectedSystemObservationId: -1,
  selectedTokenIds: [],
  showLabelInstruction: false,
  activeTokenObservationId: "",
  activeLabelId: "",
  activeRelationId: "",
  hiddenLabelIds: {},
  contextMenuTokenId: "",
  selectedLabelId: "",
  observations: [],
  relations: [],
  draggingObservationId: "",
  contextMenuAnnotationId: "",
  editingAnnotationId: "",
  showRelation: false,
  showRelationName: true,
  showNavBar: true,
  showSideBar: true,
  showPreviousJobs: false,
  showEntity: false,
  selectedAnnotation: {
    annotationId: "",
    contextMenu: false,
    issueMenu: false,
  },
  selectedToken: {
    tokenId: "",
    contextMenu: false,
    issueMenu: false,
  },
};
