import {
  IconStepLabeling,
  IconStepReview,
  IconStepAcceptance,
} from "components/common/vb-icon.component";
import { StepType } from "services/label-service/dtos";

interface ProjectWorkflowStepIconProps {
  type: StepType;
}

export const ProjectWorkflowStepIcon = ({
  type,
}: ProjectWorkflowStepIconProps) => {
  return type === StepType.REVIEW ? (
    <IconStepReview />
  ) : type === StepType.ACCEPTANCE ? (
    <IconStepAcceptance />
  ) : (
    <IconStepLabeling />
  );
};
