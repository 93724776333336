/*
 * File: app-setting-default.ts
 * Project: app-aiscaler-web
 * File Created: Sunday, 17th April 2022 2:54:58 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export const defaultSupportedProjectTypes = [
  {
    code: "image",
    name: "image",
    subTypes: [
      {
        name: "image_detection",
        code: "image_detection",
        dataSourceTypes: ["image", "dicom"],
      },
      {
        name: "image_segmentation",
        code: "image_segmentation",
        dataSourceTypes: ["image", "dicom"],
      },
      {
        name: "image_ocr",
        code: "image_ocr",
        dataSourceTypes: ["image"],
      },
      {
        name: "image_classification",
        code: "image_classification",
        dataSourceTypes: ["image"],
      },
    ],
  },
  {
    code: "medical_image",
    name: "medical_image",
    subTypes: [
      {
        name: "image_detection",
        code: "image_detection",
        dataSourceTypes: ["image", "dicom"],
      },
      {
        name: "image_segmentation",
        code: "image_segmentation",
        dataSourceTypes: ["image", "dicom"],
      },
      {
        name: "image_classification",
        code: "image_classification",
        dataSourceTypes: ["image", "dicom"],
      },
    ],
  },
  {
    code: "text",
    name: "text",
    subTypes: [
      {
        name: "text_ner_re",
        code: "text_ner_re",
        dataSourceTypes: ["text"],
      },
      {
        name: "text_tts",
        code: "text_tts",
        dataSourceTypes: ["text"],
      },
    ],
  },
  {
    code: "audio",
    name: "audio",
    subTypes: [
      {
        name: "audio_stt",
        code: "audio_stt",
        dataSourceTypes: ["audio"],
      },
    ],
  },
];
