/*
 * File: image-annotations.state.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 20th April 2022 3:17:12 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Annotation } from "domain/image-labeling";
import { AnnotatedLabel } from "domain/image-labeling/annotated-label";
import { JobAnnotation } from "domain/image-labeling/job-annotation";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";

export interface SelectedAnnotation {
  annotationUUID: string;
  attributeMenu: boolean;
  contextMenu: boolean;
  classMenu: boolean;
}

export interface ActiveAnnotation {
  annotationId: number;
  selected?: boolean;
  hovering?: boolean;
}

export interface ImageAnnotationsState {
  jobAnnotations: Record<number, JobAnnotation>;
  annotations: Annotation[];
  relationAnnotations: RelationAnnotation[];
  selectedAnnotation: SelectedAnnotation;
  invalidAnnotation: Annotation | null;
  isLoaded: boolean;
  activeAnnotatedLabels: AnnotatedLabel[];
  activeAnnotations: ActiveAnnotation[];
}

export const INITIAL_IMAGE_ANNOTATIONS_STATE: ImageAnnotationsState = {
  jobAnnotations: {},
  annotations: [],
  relationAnnotations: [],
  selectedAnnotation: {
    annotationUUID: "",
    attributeMenu: false,
    contextMenu: false,
    classMenu: false,
  },
  isLoaded: false,
  activeAnnotatedLabels: [],
  invalidAnnotation: null,
  activeAnnotations: []
};
