/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 22nd March 2022 4:18:42 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { CompatClient, Stomp } from "@stomp/stompjs";
import { ENV_CONFIG } from "configs/env.config";
import { TaskRunner } from "domain/customer/task-runner";
import { AuthService } from "services/auth";
import SockJS from "sockjs-client";
import * as Sentry from "@sentry/react";

export type WebSocketMessageHandler = (payload: TaskRunner) => void;

export class AIScalerWebSocket {
  _client?: CompatClient;
  _onMessage: WebSocketMessageHandler;
  constructor(handler: WebSocketMessageHandler) {
    this._onMessage = handler;
  }
  init() {
    if (!!this._client) return;
    const url = ENV_CONFIG.WEBSOCKET_URL;
    const socket = new SockJS(url);
    this._client = Stomp.over(socket);
    this._client.debug = function () {};
    this._client.heartbeatIncoming = 4000;
    this._client.heartbeatOutgoing = 4000;
    this._client.heartbeat = { incoming: 4000, outgoing: 4000 };
    this._client.reconnectDelay = 5000;
    this._client.reconnect_delay = 5000;
  }

  handleMessage(message: any) {
    if (message && message.body && message.body) {
      try {
        const payload = JSON.parse(message.body) as TaskRunner;
        this._onMessage(payload);
      } catch (error) {
        Sentry.captureException(error);
        console.log("FAILED to handle message", message.body);
      }
    }
  }

  connect(workspaceId: string) {
    if (!this._client || this._client.connected) return;
    const header = {
      Authorization: `Bearer ${AuthService.getAccessToken()}`,
      Workspace: workspaceId,
      Scope: AuthService.getUserScope(),
    };
    this._client.connect(header, (data: any) => this.onConnected(data));
  }

  onConnected(_: any) {
    const topicUrl = `/topic/workspace/${AuthService.getUserWorkspace()}`;
    this._client?.subscribe(topicUrl, (res) => this.handleMessage(res));
  }

  async disconnect() {
    await this._client?.deactivate();
    this._client = undefined;
  }
}
