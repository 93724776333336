/*
 * File: manage-file-metadata.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 12th June 2023 9:47:07 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBSelectCreatableComponent } from "components/design-system/select-input/select.component";
import { QueryApi } from "data-access/impl/query";
import { StorageApi } from "data-access/impl/storage";
import { useAppDispatch } from "hooks/use-redux";
import useMetadataKeyOptions from "pages/customer/datasets/file-browse/hooks/use-metadata-key-options";
import useMetadataValueOptions from "pages/customer/datasets/file-browse/hooks/use-metadata-value-options";
import { useState } from "react";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";

interface Props {
  files: QueryApi.IFile[];
  open: boolean;
  onClose: (reload?: boolean) => void;
}

export function ManageFileMetadataModal({ files, open, onClose }: Props) {
  const dispatch = useAppDispatch();
  const [requesting, setRequesting] = useState(false);
  const {
    options: metadataKeyOptions,
    option: metadataKey,
    onChange: onMetadataKeyChange,
    createNewKey,
  } = useMetadataKeyOptions({ page: 0, size: 10000 });

  const {
    options: metadataValueOptions,
    option: metadataValue,
    onChange: onMetadataValueChange,
    createNewKeyValue,
  } = useMetadataValueOptions({ page: 0, size: 10000 });

  async function handleSubmit() {
    if (!metadataKey || !metadataValue) return;
    try {
      setRequesting(true);
      const metadataKeyId = parseInt(metadataKey.value);
      const metadataValueId = parseInt(metadataValue.value);
      const action = "ADD";
      await StorageApi.manageFileMetadatas(
        files.map((file) => ({
          fileId: file.id,
          metadataKeyId,
          metadataValueId,
          action,
        }))
      );
      setRequesting(false);
      dispatch(enqueueSuccessNotification("Successed"));
      onClose(true);
    } catch (error) {
      dispatch(enqueueErrorNotification("Failed"));
      setRequesting(false);
    }
  }
  return (
    <VBModal
      title="Add metadata"
      open={open}
      onClose={() => onClose(false)}
      textSubmit="Apply"
      onSubmit={handleSubmit}
      disableSubmit={requesting || !metadataKey || !metadataValue}
      processingIndicator={requesting}
      blockUI={requesting}
    >
      <div className="space-y-4">
        <VBSelectCreatableComponent
          header="Metadata"
          placeholder="Select metadata"
          value={metadataKey}
          options={metadataKeyOptions}
          menuPortalTarget={document.body}
          onCreateOption={(value) => createNewKey(value)}
          onChange={(newOption) => onMetadataKeyChange(newOption)}
        />

        {metadataKey && (
          <VBSelectCreatableComponent
            header="Metadata value"
            placeholder="Select metadata value"
            value={metadataValue}
            options={metadataValueOptions}
            menuPortalTarget={document.body}
            onChange={(newOption) => onMetadataValueChange(newOption)}
            onCreateOption={(value) => createNewKeyValue(value)}
          />
        )}
      </div>
    </VBModal>
  );
}

export default ManageFileMetadataModal;
