/*
 * File: users.state.ts
 * Project: app-aiscaler-web
 * File Created: Sunday, 2nd January 2022 4:50:19 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Collection } from "domain/common";
import { User } from "domain/customer";
import { UserFilter } from "pages/customer/users/context/workspace-users.state";
import { RequestStatus } from "store/base/base.state";

export interface UsersState {
  users: Collection<User>;
  total: number;
  filter: UserFilter | null;
  status: RequestStatus;
}

export const INITIAL_USERS_STATE: UsersState = {
  users: {
    allIds: [],
    entities: {},
  },
  total: 0,
  status: RequestStatus.IDLE,
  filter: null,
};
