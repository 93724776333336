/*
 * File: labeler-faq.page.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 22nd November 2021 11:42:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  IconArrowRight,
  IconFolderFill,
  IconSearch,
  IconTag,
} from "components/common/vb-icon.component";
import { useQuery } from "hooks/use-query";
import { useQueryParams } from "hooks/use-query-params.hook";
import { useMemo, useState } from "react";
import {
  HelpTopic,
  TopicContent,
} from "./components/help-topic/help-topic.component";
import { HELP_TOPICS } from "./components/help-topic/help-topic.constants";
import LabelImagePreivew from "assets/images/illustration/label_preview.jpg";
export const LabelerFAQPage = () => {
  const { navigateByQuery } = useQuery();
  const handleSelectTopic = (topic: TopicContent) => {
    navigateByQuery("topicId", topic.id.toString(), true);
  };
  const { topicId } = useQueryParams({ topicId: "" });

  if (topicId) return <TopicDetail topicId={topicId} />;

  return (
    <div className="w-full">
      <h1 className="px-6 py-6 text-lg font-bold text-warning-500">
        Online help
      </h1>
      <div className="flex items-end gap-6 px-6">
        <div className="flex flex-col gap-2">
          <div className="text-sm font-semibold text-background-700">
            Search document
          </div>
          <input
            type="text"
            placeholder="Text here..."
            className="w-full max-w-md px-4 bg-white border rounded h-9 border-background-300"
          />
        </div>
        <div>
          <button className="flex items-center gap-2 px-3 text-white rounded bg-warning-500 h-9">
            <span>Search</span>
            <IconSearch className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 p-6 md:grid-cols-2 xl:grid-cols-4">
        {HELP_TOPICS.map(({ topic, contents }) => {
          return (
            <HelpTopic
              key={topic}
              contents={contents}
              topic={topic}
              onSelect={handleSelectTopic}
            />
          );
        })}
      </div>
    </div>
  );
};
const labels = [
  { id: "1", name: "No Finding" },
  { id: "2", name: "Airspace Opacity" },
  { id: "3", name: "Atelectasis" },
  { id: "4", name: "Consolidation" },
  { id: "5", name: "Pneumonia" },
  { id: "6", name: "Edema" },
  { id: "7", name: "Cavitation" },
  { id: "8", name: "Fibrosis" },
  { id: "9", name: "Lung Lesion" },
  { id: "10", name: "Cardiomegaly" },
  { id: "11", name: "Enlarged Cardiomediastinum" },
  { id: "12", name: "Widening Mediastinum" },
  { id: "13", name: "Pleural Effusion" },
  { id: "14", name: "Pneumothorax" },
  { id: "15", name: "Pleural Other" },
  { id: "16", name: "Fracture" },
  { id: "17", name: "Medical device" },
  { id: "18", name: "Tuberculosis" },
];

interface Props {
  topicId: string;
}
export const TopicDetail = ({ topicId }: Props) => {
  const { navigateByQuery } = useQuery();
  const onClickOnlineHelp = () => {
    navigateByQuery("topicId", "", true);
  };
  const [selectedLabelId, setSelectedLabelId] = useState("1");

  const selectedLabel = useMemo(() => {
    return labels.find((item) => item.id === selectedLabelId);
  }, [selectedLabelId]);

  const selectedTopic = useMemo(() => {
    for (let helpTopic of HELP_TOPICS) {
      const topic = helpTopic.contents.find((c) => c.id === topicId);
      if (topic) return topic;
    }
    return {
      id: topicId,
      name: "",
    };
  }, [topicId]);
  return (
    <div className="w-full mb-8">
      <div className="flex items-center gap-1 px-6 py-6">
        <span
          className="text-sm capitalize cursor-pointer"
          onClick={onClickOnlineHelp}
        >
          Online help
        </span>
        <IconArrowRight className="flex-shrink-0 w-4 h-4 text-background-500" />
        <h2 className="text-lg font-bold text-warning-500">
          {selectedTopic.name}
        </h2>
      </div>

      <div className="flex flex-row gap-6 px-6">
        <div className="flex-shrink-0">
          <div className="py-2 overflow-hidden bg-white border rounded border-background-300">
            {labels.map((item) => {
              const isSelected = item.id === selectedLabelId;
              return (
                <div
                  key={item.id}
                  onClick={() => setSelectedLabelId(item.id)}
                  className={`flex items-center gap-1 px-4 cursor-pointer h-9 hover:bg-warning-50 ${
                    isSelected
                      ? "bg-warning-500 text-white hover:bg-warning-500"
                      : "text-background-700"
                  }`}
                >
                  <IconTag className="flex-shrink-0 w-5 h-5" />
                  <span className="flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis">
                    {item.name}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex-1">
          <div className="overflow-hidden bg-white border rounded border-background-300">
            <div
              className="flex items-center gap-1 px-4 bg-background-200"
              style={{ height: "3.25rem" }}
            >
              <IconFolderFill className="flex-shrink-0 w-5 h-5 text-background-700" />
              <span className="flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis">
                {selectedLabel?.name}
              </span>
            </div>
            <div className="grid grid-cols-1 gap-6 p-6 lg:grid-cols-3">
              <div className="flex flex-col gap-2 lg:order-2">
                <h2 className="font-semibold">Illustration</h2>
                <div className="grid grid-cols-2 gap-6">
                  <img
                    src={LabelImagePreivew}
                    className="w-full"
                    alt={selectedLabel?.name}
                  />
                  <img
                    src={LabelImagePreivew}
                    className="w-full"
                    alt={selectedLabel?.name}
                  />
                </div>
              </div>

              <div className="flex flex-col col-span-2 gap-6">
                <div className="flex flex-col gap-2">
                  <h2 className="font-semibold">Definition</h2>
                  <p className="font-normal text-background-500">
                    Modi eligendi suscipit ducimus vel. Illum facilis sit
                    facilis dolorem. Velit sed nihil. Blanditiis voluptate vel
                    adipisci. Sit et blanditiis facere eum iusto illo. Accusamus
                    quas voluptates sequi ut cupiditate. Nihil unde nihil. Ut
                    omnis nobis accusamus quis. Voluptas incidunt voluptatem et
                    voluptatem aut vitae aut veniam sint. Veritatis et totam
                    porro qui. Corporis natus animi. Eveniet quaerat sunt quis
                    natus distinctio. Rerum in ut molestiae autem accusantium
                    eveniet ut et. Nemo aliquam iure sit neque dolor qui
                    voluptatem. Accusamus quas voluptates sequi ut cupiditate.
                    Nihil unde
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <h2 className="font-semibold">Caveats</h2>
                  <p className="font-normal text-background-500">
                    Modi eligendi suscipit ducimus vel. Illum facilis sit
                    facilis dolorem. Velit sed nihil. Blanditiis voluptate vel
                    adipisci. Sit et blanditiis facere eum iusto illo. Accusamus
                    quas voluptates sequi ut cupiditate. Nihil unde nihil.
                  </p>
                </div>
                <div />

                <div className="flex flex-col gap-2">
                  <h2 className="font-semibold">References</h2>
                  <p className="font-normal text-background-500">
                    Modi eligendi suscipit ducimus vel. Illum facilis sit
                    facilis dolorem. Velit sed nihil. Blanditiis voluptate vel
                    adipisci. Sit et blanditiis facere eum iusto illo.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
