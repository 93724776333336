/*
 * File: use-query-params.hook.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 15th November 2021 1:10:07 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export interface QueryParams {
  [key: string]: string;
}

export function useQueryParams<T extends QueryParams>(defaultParams: T) {
  const location = useLocation();
  const params = useMemo(() => {
    const searchParams = location.search;
    const urlSearchParams = new URLSearchParams(searchParams);
    const newParams: QueryParams = { ...defaultParams };
    const keys = Object.keys(newParams);
    for (let key of keys) {
      if (key && urlSearchParams.has(key)) {
        const value = urlSearchParams.get(key);
        if (value) newParams[key] = value;
      }
    }
    return newParams;
  }, [location.search, defaultParams]);

  return params;
}
