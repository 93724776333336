/*
 * File: file-name-filter.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd July 2023 2:59:51 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { QueryApi } from "data-access/impl/query";
import { Filter, getFilterQuery } from "domain/common";
import { Fragment } from "react";

interface Props {
  filter: Filter;
  onFilterChange(filter: Filter): void;
}

export function FileIdsFilter({ filter, onFilterChange }: Props) {
  return (
    <div className="px-4">
      <VBTextInputComponent
        header="File IDs"
        placeholder="Enter file ids, split by comma"
        defaultValue={getFilterQuery(filter, "ids")?.value ?? ""}
        onInputChange={(value) => {
          try {
            const fileFilter = new QueryApi.FileFilter(filter);
            let ids: number[] | undefined = undefined;
            if (value && value.trim()) {
              ids = value
                .split(",")
                .map((item) => parseInt(item.trim()))
                .filter((item) => !!item);
            }
            fileFilter.setIds(ids);
            onFilterChange(fileFilter.toFilter());
          } catch (error) {}
        }}
        clearInput
        inputIcon={<Fragment />}
      />
    </div>
  );
}
