/*
 * File: browser-mockup.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 21st February 2022 2:38:06 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  IconDesktopWindows,
  IconPhone,
  IconTablet,
} from "components/common/vb-icon.component";
import { useState } from "react";
import { classnames } from "utilities/classes";

interface Props {
  children: JSX.Element;
}

export const BrowserMockup = ({ children }: Props) => {
  const [screen, setScreen] = useState("desktop");
  return (
    <div
      className="flex flex-col w-full h-full mx-auto overflow-hidden"
      style={{
        maxWidth:
          screen === "mobile"
            ? "320px"
            : screen === "tablet"
            ? "768px"
            : "1920px",
      }}
    >
      <div className="w-full h-11 rounded-t-lg bg-background-700 flex justify-start items-center space-x-1.5 px-3 flex-none">
        <span className="w-3 h-3 bg-error-500 rounded-full"></span>
        <span className="w-3 h-3 bg-warning-200 rounded-full"></span>
        <span className="w-3 h-3 bg-success-300 rounded-full"></span>
        <div className="flex-auto text-center">
          {/* <div className="flex items-center justify-center px-4 mx-auto text-sm text-white border rounded w-28 h-7 border-background-300 bg-background-800">
            index.html
          </div> */}
        </div>
        <DeviceButton
          onClick={() => setScreen("desktop")}
          active={screen === "desktop"}
          title="Desktop"
        >
          <IconDesktopWindows className="w-4 h-4" />
        </DeviceButton>
        <DeviceButton
          onClick={() => setScreen("tablet")}
          active={screen === "tablet"}
          title="Tablet"
        >
          <IconTablet className="w-4 h-4" />
        </DeviceButton>
        <DeviceButton
          onClick={() => setScreen("mobile")}
          active={screen === "mobile"}
          title="Phone"
        >
          <IconPhone className="w-4 h-4" />
        </DeviceButton>
      </div>
      <div className="flex-auto w-full overflow-hidden bg-red-500 border-t-0">
        {children}
      </div>
    </div>
  );
};

interface DeviceButtonProps {
  onClick(): void;
  children: JSX.Element;
  active: boolean;
  title: string;
}

const DeviceButton = ({ onClick, active, children, title }: DeviceButtonProps) => {
  return (
    <button
      className={classnames("flex-none rounded-sm", {
        "text-white": active,
        "text-background-500": !active,
      })}
      onClick={onClick}
      title={title}
    >
      {children}
    </button>
  );
};
