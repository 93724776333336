import vtkImageData from "@kitware/vtk.js/Common/DataModel/ImageData";
import { Vector3 } from "@kitware/vtk.js/types";
import { v4 } from "uuid";
import {
  ViewTypes,
  vtkAngleWidgetCustom,
  vtkDistanceWidgetCustom,
} from "../vtk_import";
import {
  EditorMeasurement,
  EditorMeasurementData,
  EditorMeasurementType,
  ThreeDEditorEvents,
} from "./three-d-editor.models";

export const changeLabelMapMask = (
  labelMap: vtkImageData,
  fromMask: number,
  toMask: number
) => {
  if (!labelMap || !labelMap.getPointData()) return;
  const scalars = labelMap.getPointData().getScalars();
  if (!scalars) return;
  const data = scalars.getData();

  for (let i = 0; i < data.length; i++) {
    if (data[i] === fromMask) {
      data[i] = toMask;
    }
  }

  scalars.setData(data);
};

/**
 * return the first slice that contains the mask value for each axis (ijk)
 * return -1 if not found any
 * */
export function findFirstSlicesForMask(labelMap: vtkImageData, mask: number) {
  const data = labelMap.getPointData().getScalars().getData();

  for (let i = 0; i < data.length; i++) {
    if (data[i] === mask) {
      const worldPos = labelMap.getPoint(i);
      const ijk = labelMap.worldToIndex(worldPos);
      return ijk;
    }
  }

  return [-1, -1, -1];
}

// Measurements
function updateMeasurementProps(viewWidget: any, propsToUpdate: any) {
  const propNames = Object.keys(propsToUpdate);
  for (let i = 0; i < propNames.length; i++) {
    const name = propNames[i];
    const props = viewWidget[`get${name}Props`]();
    viewWidget[`set${name}Props`](Object.assign(props, propsToUpdate[name]));
  }
}
function setupMeasurementViewWidget(
  viewWidget: any,
  type: EditorMeasurementType
) {
  viewWidget.setCircleProps({
    "stroke-width": 3,
    fill: "transparent",
    r: 8,
  });
  viewWidget.setLineProps({
    "stroke-width": 2,
  });
  viewWidget.setTextProps({
    dx: 12,
    dy: -12,
  });
  viewWidget.setText("");
  viewWidget.setHandleVisibility(false); // hide 3d handles
  if (type === EditorMeasurementType.distance) {
    viewWidget.setTextStateIndex(0);
  }
  if (type === EditorMeasurementType.angle) {
    viewWidget.setTextStateIndex(1); // the middle handle
  }
}
function setWidgetColor(viewWidget: any, hex: string) {
  updateMeasurementProps(viewWidget, {
    Text: { fill: hex },
    Circle: { stroke: hex },
    Line: { stroke: hex },
  });
}
function setMeasurementWidgetTextSize(viewWidget: any, size: number) {
  const scaledSize = size * (window.devicePixelRatio || 1);
  updateMeasurementProps(viewWidget, {
    Text: { style: `font-size: ${scaledSize}px` },
  });
}
export function getMeasurementWidgetDataAndUnit(
  widget: any,
  type: EditorMeasurementType
) {
  if (type === EditorMeasurementType.distance) {
    return {
      data: widget.getDistance(),
      unit: "mm",
    };
  }
  if (type === EditorMeasurementType.angle) {
    return {
      data: (widget.getAngle() * 180) / Math.PI,
      unit: "°",
    };
  }
  return undefined;
}
export function getMeasurementWidgetText(
  widget: any,
  type: EditorMeasurementType
) {
  const dataAndUnit = getMeasurementWidgetDataAndUnit(widget, type);
  if (dataAndUnit) {
    return `${dataAndUnit.data.toFixed(2)} ${dataAndUnit.unit}`;
  }
  return "";
}

const measurementTypeToVtkClass = {
  [EditorMeasurementType.distance]: vtkDistanceWidgetCustom,
  [EditorMeasurementType.angle]: vtkAngleWidgetCustom,
};

interface createMeasurementProps {
  type: EditorMeasurementType;
  options?: any;
  windowId: any;
  image: any;
  imageData: any;
  widgetManager: any;
  handlePositions?: number[][];
}
export const createMeasurement = ({
  type,
  options = {},
  windowId,
  image,
  imageData,
  widgetManager,
  handlePositions,
}: createMeasurementProps): EditorMeasurement => {
  const slicingMode = image.mapper.getSlicingMode() % 3;
  const ijk: Vector3 = [0, 0, 0];
  const position: Vector3 = [0, 0, 0];
  const slice = image.mapper.getSlice();
  ijk[slicingMode] = slice;
  imageData.indexToWorld(ijk, position);

  const vtkClass = measurementTypeToVtkClass[type];
  const widget = vtkClass.newInstance(options);
  const viewWidget = widgetManager.addWidget(widget, ViewTypes.SLICE);
  widget.getManipulator().setHandleOrigin(position);

  // add handles if needed (load from save data)
  if (handlePositions) {
    const state = widget.getWidgetState();
    for (const point of handlePositions) {
      const handle = state.addHandle();
      handle.setOrigin(...point);
    }
  }

  setupMeasurementViewWidget(viewWidget, type);
  setWidgetColor(viewWidget, "#ffee00");
  setMeasurementWidgetTextSize(viewWidget, 16);

  // need to set text after setup
  if (handlePositions) {
    const text = getMeasurementWidgetText(widget, type);
    viewWidget.setText(text);
  }

  const isViewWidgetPlaced = (widget: any, type: EditorMeasurementType) => {
    const state = widget.getWidgetState();

    if (type === EditorMeasurementType.distance) {
      return state.getHandleList().length === 2;
    }
    if (type === EditorMeasurementType.angle) {
      return state.getHandleList().length === 3;
    }

    return true;
  };

  viewWidget.onInteractionEvent(() => {
    if (measurement.isFinalized) {
      viewWidget.setText(getMeasurementWidgetText(widget, measurement.type));
    }
  });

  viewWidget.onEndInteractionEvent(() => {
    const isPlaced = isViewWidgetPlaced(widget, measurement.type);

    if (isPlaced && !measurement.isFinalized) {
      measurement.isFinalized = true;
      measurement.slice = image.mapper.getSlice();
      document.dispatchEvent(
        new CustomEvent(ThreeDEditorEvents.MEASUREMENT_FINALIZED, {
          detail: measurement,
        })
      );
      viewWidget.setText(getMeasurementWidgetText(widget, measurement.type));
    }

    document.dispatchEvent(
      new CustomEvent(ThreeDEditorEvents.MEASUREMENT_CHANGED, {
        detail: measurement,
      })
    );
  });

  // NOTE: set priority higer than the isstyle
  viewWidget.setPriority(3);

  const measurement: EditorMeasurement = {
    uuid: v4(),
    type,
    note: "",
    widget,
    viewWidget,
    windowId,
    slice,
    isFinalized: false,
  };

  return measurement;
};

export function serializeMeasurement(
  measurement: EditorMeasurement
): EditorMeasurementData {
  const state = measurement.widget.getWidgetState().getHandleList();

  return {
    type: measurement.type,
    note: measurement.note,
    windowId: measurement.windowId,
    slice: measurement.slice,
    handlePositions: state.map((handle: any) => handle.getOrigin()),
  };
}
