/*
 * File: sync-button.component.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 3rd July 2021 5:28:39 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks/use-redux";
import { ButtonHTMLAttributes } from "react";
import { selectNumberSyncingClouds } from "store/customer/dataset/dataset.selectors";
import { IconCloudFill } from "components/common/vb-icon.component";
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}
export const SyncCloudButton = ({ className = "", ...rest }: Props) => {
  const { t } = useTranslation();
  const numberSyncing = useAppSelector(selectNumberSyncingClouds);
  return (
    <button className={`relative button-primary ${className}`} {...rest}>
      <span className="hidden xl:block">{t("dataset:details.buttonSync")}</span>
      <IconCloudFill className="w-5 h-5" />
      {numberSyncing > 0 && (
        <div className="absolute flex items-center justify-center w-5 h-5 text-xs text-white bg-red-500 border border-white rounded-full -top-2 -right-2">
          {numberSyncing}
        </div>
      )}
    </button>
  );
};
