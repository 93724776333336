/*
 * File: created-date-header.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 19th August 2021 2:21:50 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBDatagridInputDate } from "components/common/vb-datagrid/vb-datagrid-input-date.component";
import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { useState } from "react";

export const CreatedDateHeader = () => {
  const { t } = useTranslation();
  const { filter, setCreatedDate } = useDatasetContext();
  const [date, setDate] = useState(filter?.createdDate);

  function changeDate(date: string) {
    setDate(date);
    setCreatedDate && setCreatedDate(date);
  }

  return (
    <div className="w-full leading-4 mr-2">
      <p className="h-6">{t("dataset:header.textCreatedDate")}</p>
      <VBDatagridInputDate date={date} onDateChange={changeDate} />
    </div>
  );
};
