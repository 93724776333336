/*
 * File: notifications.page.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th September 2021 9:34:01 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import { VBPageTitle } from "components/common/vb-page-title.component";
import { NotificationTable } from "./components/notification-table.component";
import { useNotifications } from "./notifications.hooks";
import { NotificationRow } from "./notifications.models";

export const NotificationsPage = () => {
  const {
    rows,
    totalRow,
    filter,
    setFilterField,
    deleteNotification,
  } = useNotifications();

  const handleRowAction = (row: NotificationRow, action: string) => {
    if (action === "delete") {
      deleteNotification(row);
    }
  }

  return (
    <div className="flex flex-col h-full p-4">
      <div className="flex items-center h-16 py-4">
        <VBPageTitle text="Notifications" />
      </div>
      <div className="flex-auto">
        <NotificationTable
          rows={rows}
          filter={filter}
          setFilterField={setFilterField}
          onRowAction={handleRowAction}
        />
      </div>
      {filter && (
        <div className="flex-none">
          <GridPagination
            page={filter.page}
            pageSize={filter.pageSize}
            pageSizeOptions={[10, 15, 20, 25, 50, 75, 100]}
            onPageSizeChange={v => setFilterField("pageSize", v)}
            onPageChange={v => setFilterField("page", v)}
            totalItems={totalRow}
          />
        </div>
      )}
    </div>
  );
};
