/*
 * File: dataset-detail-header.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 24th August 2021 10:40:09 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBSpacer } from "components/common/vb-spacer.component";
import { useAppSelector } from "hooks/use-redux";
import { DatasetDrawer } from "pages/customer/datasets/components/dataset-drawer/dataset-drawer.component";
import { useEffect, useState } from "react";
import { selectCurrentDataset } from "store/customer/dataset/dataset.selectors";
import { SyncCloudButton } from "../sync-cloud/sync-button.component";
import { SyncCloudDrawer } from "../sync-cloud/sync-cloud.drawer";
import { UploadFilesButton } from "../upload/upload-button.component";
import { VBBreadcrumb } from "components/common/vb-breadcrumb/vb-breadcrumb.component";
import { Routes } from "routers/config/routes";
import { useRouteMatch } from "react-router";
import {
  IconInformationCircle,
  IconInformationCircleOutline,
  IconTag,
  IconTagFill,
  IconTrash,
} from "components/common/vb-icon.component";
import { useDatasetDetailContext } from "../../dataset-detail.context";
import { ModifyTags } from "./modify-tags.component";
import { VscNote } from "react-icons/vsc";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";
import ButtonMoveFiles from "./button-move-files";

export const DatasetDetailHeader = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const [showSyncCloud, setShowSyncloud] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const dataset = useAppSelector(selectCurrentDataset);
  const {
    selectedFiles,
    setDeletingItemIds,
    showFileInfo,
    toggleShowFileInfo,
  } = useDatasetDetailContext();
  const [modifyingTags, setModifyingTags] = useState(false);
  const { openModal, closeModal } = useModalContext();
  // const [editingMetadata, setEditingMetadata] = useState(false);

  const handleClickEditMetadata = () => {
    openModal(ModalTypes.MANAGE_FILE_METADATA, {
      open: true,
      onClose: closeModal,
      onSubmit: closeModal,
    });
    // setEditingMetadata(true);
  };
  const handleClickDelete = () => {
    setDeletingItemIds(selectedFiles);
  };
  const handleClickModifyTag = () => {
    setModifyingTags(!modifyingTags);
  };
  const hasSelectedFiles = selectedFiles.length > 0;
  const breadcrumbs = [
    { url: Routes.DATASETS, text: t("dataset:datasource") },
    { url: path, text: dataset ? dataset.name : "", disabled: true },
  ];

  useEffect(() => {
    if (selectedFiles.length === 0) setModifyingTags(false);
  }, [selectedFiles]);

  return (
    <div className="items-center block gap-4 p-4 md:flex">
      <VBBreadcrumb breadcrumbs={breadcrumbs} />
      <VBSpacer />

      <div className="flex items-center gap-4">
        {hasSelectedFiles && dataset && (
          <ButtonMoveFiles fileIds={selectedFiles} datasetId={dataset.id} />
        )}
        {hasSelectedFiles && (
          <button
            className="button-secondary"
            onClick={handleClickEditMetadata}
          >
            <span className="hidden xl:block">Add metadata</span>
            <VscNote className="w-5 h-5" />
          </button>
        )}

        {hasSelectedFiles && (
          <button className="button-secondary" onClick={handleClickDelete}>
            <span className="hidden xl:block">Delete selection</span>
            <IconTrash className="w-5 h-5" />
          </button>
        )}

        {hasSelectedFiles && (
          <div className="relative z-50">
            <button
              className={modifyingTags ? "button-primary" : "button-secondary"}
              onClick={handleClickModifyTag}
            >
              <span className="hidden xl:block">Tag modify</span>
              {!modifyingTags && <IconTag className="w-5 h-5" />}
              {modifyingTags && <IconTagFill className="w-5 h-5" />}
            </button>
            {modifyingTags && (
              <ModifyTags onClose={() => setModifyingTags(false)} />
            )}
          </div>
        )}

        <button
          className={showFileInfo ? "button-primary" : "button-secondary"}
          onClick={toggleShowFileInfo}
        >
          <span className="hidden xl:block">
            {showFileInfo ? "Hide info" : "Show info."}
          </span>
          {!showFileInfo && (
            <IconInformationCircleOutline className="w-5 h-5" />
          )}
          {showFileInfo && <IconInformationCircle className="w-5 h-5" />}
        </button>

        <UploadFilesButton />

        <SyncCloudButton onClick={() => setShowSyncloud(true)} />
      </div>

      {dataset && (
        <SyncCloudDrawer
          dataset={dataset}
          open={showSyncCloud}
          handleClose={() => setShowSyncloud(false)}
        />
      )}

      <DatasetDrawer
        open={showDetail}
        dataset={dataset}
        handleClose={() => setShowDetail(false)}
      />
    </div>
  );
};
