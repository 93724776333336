/*
 * File: task-runner.state.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 23rd March 2022 9:53:06 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { TaskRunner } from "domain/customer/task-runner";

export interface TaskRunnersState {
  tasks: TaskRunner[];
}
export const INITIAL_TASK_RUNNER_STATE: TaskRunnersState = {
  tasks: [],
};
