/*
 * File: Interfaces.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 25th April 2023 2:06:59 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { AnnotateType } from "constants/annotation.constant";
import { Tensor } from "onnxruntime-web";

export interface OnnxLabel {
  label: string;
  value: string;
  color: string;
  type: AnnotateType;
}

export interface modelInputProps {
  x: number;
  y: number;
  width: null | number;
  height: null | number;
  clickType: number;
}

export enum clickType {
  POSITIVE = 1.0,
  NEGATIVE = 0.0,
  UPPER_LEFT = 2.0,
  BOTTOM_RIGHT = 3.0,
}

export interface modelScaleProps {
  onnxScale: number;
  maskWidth: number;
  maskHeight: number;
  // scale: number;
  // uploadScale: number;
  width: number;
  height: number;
}

export interface modeDataProps {
  clicks?: Array<modelInputProps>;
  tensor: Tensor;
  modelScale: modelScaleProps;
  best_box?: number[];
  point_coords?: Array<number[]>;
  point_labels?: number[];
  last_pred_mask: Tensor | null;
}

export interface ToolProps {
  handleMouseMove: (e: any) => void;
}
