/*
 * File: storage-api.config.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 31st August 2021 4:47:51 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export const StorageAPIPaths = {
  scopes: "/api/v1/scopes",
  datasets: "/api/v1/data-sets",
  exportedDatasetVersions: "/api/v1/exported-dataset-versions",
  datasetVersion: "/api/v1/dataset-versions",
};
