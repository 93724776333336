/*
 * File: workflow-detail.page.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useParams } from "react-router-dom";
import { Logger } from "utilities/logger";
import { VBPageTitle } from "components/common/vb-page-title.component";
import { VBSpacer } from "components/common/vb-spacer.component";
import { VBCreateButton } from "components/common/vb-create-button.component";
import { WorkflowInstructionDTO } from "services/label-service/dtos";
import {
  createWorkflowInstructionAsync,
  deleteWorkflowInstructionAsync,
  loadWorkflowInstructionsAsync,
  updateWorkflowInstructionAsync,
} from "store/customer/project/thunks/workflow-instruction.thunk";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { WorkflowInstructionModal } from "../instructions/workflow-instruction.modal";
import { VBConfirmDialog } from "components/common/vb-delete-dialog/vb-confirm-dialog.component";
import { loadWorkflowAsync } from "store/customer/workflow/workflow.thunk";
import { WorkflowInstructionTable } from "../instructions/workflow-instruction-table.component";
import { StepTransitions } from "../instructions/step-transitions.component";
import { VBBreadcrumb } from "components/common/vb-breadcrumb/vb-breadcrumb.component";
import { Routes } from "routers/config/routes";
import { useStepConditions } from "hooks/workflow/use-step-conditions.hook";
import { selectWorkflow } from "store/customer/workflow/workflow.selectors";
import { selectWorkflowInstructions } from "store/customer/project/selectors/workflow-instruction.selectors";
import { useUnmount } from "ahooks";
import { resetWorkflowInstructions } from "store/customer/project/project.slice";
import { VBMaskRequesting } from "components/common/vb-mask-requesting/vb-mask-requesting.component";
import { getNextStepTypes } from "../workflows.util";
import * as Sentry from "@sentry/react";

export const WorkflowDetailPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { workflowId } = useParams<{ workflowId: string }>();
  const workflow = useAppSelector(selectWorkflow(parseInt(workflowId)));
  const instructions = useAppSelector(selectWorkflowInstructions);

  useStepConditions(true);
  const [loading, setLoading] = useState(false);
  const nextStepTypes = getNextStepTypes(instructions)

  const [open, setOpen] = useState(false);
  const [deletingtItemId, setDeletingItemId] = useState(-1);
  const [currentInstruction, setCurrentInstruction] =
    useState<WorkflowInstructionDTO | null>(null);

  const disabledAddStep = useMemo(() => !nextStepTypes || !nextStepTypes.length, [nextStepTypes]);

  function handleCreate() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setCurrentInstruction(null);
  }

  function handleSelect(instruction: WorkflowInstructionDTO, action?: string) {
    if (action === "delete") {
      return setDeletingItemId(instruction.id);
    }
    if (action === "edit") {
      setCurrentInstruction(instruction);
      setOpen(true);
      return;
    }
  }

  async function handleDeleteItem() {
    if (deletingtItemId === -1) return;
    try {
      const response = await dispatch(
        deleteWorkflowInstructionAsync(deletingtItemId)
      );
      let idx = 1;
      for (let instruction of instructions) {
        if (instruction.id !== deletingtItemId) {
          const updatedInstruction = {
            ...instruction,
            step: idx,
            name: t("workflow:instructionmodal.stepName", { step: idx }),
          };
          if (idx === 0) {
            updatedInstruction.condition = "";
          }
          await dispatch(updateWorkflowInstructionAsync(updatedInstruction));
          idx++;
        }
      }
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textDeletedSuccess")));
    } catch (error: any) {
      Sentry.captureException(error);
      const message = error.message || t("common:textDeletedFailed");
      dispatch(enqueueErrorNotification(message));
    } finally {
      setDeletingItemId(-1);
    }
  }

  async function handleCreateInstruction(
    instruction: Partial<WorkflowInstructionDTO>
  ) {
    if (!instruction) return;
    try {
      if (!instruction.workflow) instruction.workflow = workflow;
      const response = await dispatch(
        createWorkflowInstructionAsync(instruction)
      );
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textCreatedSuccess")));
      setOpen(false);
    } catch (error: any) {
      Sentry.captureException(error);
      const message = error.message || t("common:textCreatedFailed");
      dispatch(enqueueErrorNotification(message));
    }
  }

  async function handleEditInstruction(instruction: WorkflowInstructionDTO) {
    if (!instruction) return;
    try {
      const response = await dispatch(
        updateWorkflowInstructionAsync(instruction)
      );
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textUpdatedSuccess")));
      setOpen(false);
    } catch (error: any) {
      Sentry.captureException(error);
      const message = error.message || t("common:textUpdatedFailed");
      dispatch(enqueueErrorNotification(message));
    }
  }

  useEffect(() => {
    const _loadDataAsync = async (id: number) => {
      try {
        setLoading(true);
        await dispatch(loadWorkflowAsync(id));
        await dispatch(loadWorkflowInstructionsAsync(id));
        setLoading(false);
      } catch (err) {
        Sentry.captureException(err);
        Logger.log(err);
      }
    };
    _loadDataAsync(parseInt(workflowId));
  }, [dispatch, workflowId]);

  useUnmount(() => {
    dispatch(resetWorkflowInstructions());
  });

  if (loading) return <VBMaskRequesting />;

  return (
    <div className="p-4">
      <div className="px-4">
        <VBBreadcrumb
          breadcrumbs={[
            {
              text: t("workflow:details.textBreadcrumb"),
              url: Routes.WORKFLOWS,
            },
            {
              text: workflow ? workflow.name : "",
              url: Routes.WORKFLOWS,
              disabled: true,
            },
          ]}
        />
      </div>
      <div className="flex items-center h-16 p-4">
        <VBPageTitle text={t("workflow:details.pageTitle")} />
        <VBSpacer />
        <VBCreateButton
          text={t("workflow:details.buttonCreate")}
          disabled={disabledAddStep}
          onClick={handleCreate}
        />
      </div>
      <div className="px-4">
        <StepTransitions instructions={instructions} />
        <WorkflowInstructionTable
          hideActions={false}
          workflowInstructions={instructions}
          onSelect={handleSelect}
        />
      </div>

      {open && (
        <WorkflowInstructionModal
          visible
          step={
            currentInstruction
              ? currentInstruction.step
              : instructions.length + 1
          }
          instructions={instructions}
          instruction={currentInstruction}
          onClose={handleClose}
          onSubmit={handleCreateInstruction}
          onEdit={handleEditInstruction}
        />
      )}

      <VBConfirmDialog
        visible={deletingtItemId !== -1}
        title={t("workflow:details.delete.title")}
        description={t("workflow:details.delete.textWarn")}
        onConfirmed={handleDeleteItem}
        onClose={() => setDeletingItemId(-1)}
        confirmText={t("common:buttonDelete")}
        cancelText={t("common:buttonCancel")}
      />
    </div>
  );
};
