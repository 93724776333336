/*
 * File: image-labeling.state.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 14th August 2021 9:12:03 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Annotation, Label } from "domain/image-labeling";
import { Job } from "domain/labeling/job";
import { LabelingMode } from "domain/labeling/labeling-mode";
import { Task } from "domain/labeling/task";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import {
  BatchDTO,
  ProjectDTO,
  TaskObservationDTO,
} from "services/label-service/dtos";
import { JobObservationDTO } from "services/label-service/dtos/job-observation.dto";
import { RequestStatus } from "store/base/base.state";
import { Rectangle } from "utilities/math/rectangle";

export interface JobObservationModel extends JobObservationDTO {
  selected?: boolean;
}

export interface FindingAgreement {
  labelId: string;
  user1: string;
  user2: string;
  score: number;
}

export interface FindingLabeler {
  labelId: string;
  labelName: string;
  users: {
    jobId: string;
    userId: string;
    selected?: boolean;
    annotations?: Annotation[];
  }[];
}

export interface SelectedAnnotation {
  annotationId: string;
  labelId: string;
  text?: string;
}
export interface ImageLabelingState {
  requestStatus: RequestStatus;
  job: Job | null;
  file: StorageFileDTO | null;
  error: string;
  currentJobObservations: TaskObservationDTO[];
  previousJobObservations: JobObservationModel[];
  selectedObservationId: number;
  project: ProjectDTO | null;
  batch: BatchDTO | null;
  task: Task | null;
  agreements: FindingAgreement[];
  findingLabelers: FindingLabeler[];
  viewMediaAgreement: boolean;
  maskAnnotations: Rectangle[];
  selectedAnnotation: SelectedAnnotation | null;
  readonly: boolean;
  labels: Label[];
  annotations: Annotation[];
  mode: LabelingMode;
}

export const INITIAL_IMAGE_LABELING_STATE: ImageLabelingState = {
  requestStatus: RequestStatus.IDLE,
  job: null,
  file: null,
  error: "",
  batch: null,
  project: null,
  task: null,
  currentJobObservations: [],
  previousJobObservations: [],
  selectedObservationId: -1,
  agreements: [],
  viewMediaAgreement: false,
  findingLabelers: [],
  maskAnnotations: [],
  selectedAnnotation: null,
  readonly: false,
  labels: [],
  annotations: [],
  mode: LabelingMode.LABELER,
};
