/*
 * File: vb-confirm-dialog.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { MatDialog } from "components/material/mat-dialog.component";

interface Props {
  visible: boolean;
  title: string;
  description: string;
  cancelText?: string;
  confirmText?: string;
  disable?: boolean;
  onConfirmed(): void;
  onClose(): void;
}

export const VBConfirmDialog = ({
  visible,
  title,
  description,
  onClose,
  onConfirmed,
  cancelText = "Cancel",
  confirmText = "Update",
  disable = false,
}: Props) => {
  return (
    <MatDialog disableBackdropClick open={visible} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className="p-4 w-96">
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {cancelText}
        </Button>
        <Button
          onClick={onConfirmed}
          color="primary"
          variant="contained"
          autoFocus
          disabled={disable}
          className="disabled:opacity-50"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </MatDialog>
  );
};
