/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 22nd July 2021 9:19:29 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { LabelAPIConfig } from "./config/label-api.config";
import { BatchObservationAPI } from "./apis/batch-observation.api";
import { BatchAPI } from "./apis/batch.api";
import { ObservationAPI } from "./apis/observation.api";
import { ProjectAPI } from "./apis/project.api";
import { TaskObservationAPI } from "./apis/task-observation.api";
import { TaskAPI } from "./apis/task.api";
import { WorkflowAPI } from "./apis/workflow.api";
import { WorkflowAPIV2 } from "./apis/workflow-v2.api";
import { BatchWorkflowAPI } from "./apis/batch-workflow.api";
import { WorkflowInstructionAPI } from "./apis/workflow-instruction.api";
import { JobAPI } from "./apis/job.api";
import { StepConditionAPI } from "./apis/step-condition.api";
import { WorkManagementAPI } from "./apis/work-management.api";
import { BatchManagementAPI } from "./apis/batch-management.api";
import { PipelineAPI } from "./apis/pipeline.api";
import { TrainingJobAPI } from "./apis/training-jobs.api";
import { MLModelsAPI } from "./apis/models.api";
import { IndividualAPI } from "./apis/individual.api";
import { AnnotationAPI } from "./apis/annotation.api";
import { TaskObservationV2API } from "./apis/task-observation-v2.api";
import { JobV2API } from "./apis/job-v2.api";
import { JobV3API } from "./apis/job-v3.api";
import { LabelerAnnotationV2API } from "./apis/labeler-annotation-v2.api";
import { ModelAIsAPI } from "./apis/model-ais-api";
import { ModelAIVersionsAPI } from "./apis/model-ai-versions-api";
import { AnnotationV2API } from "./apis/annotation-v2.api";
import {
  IssueCustomerAPI,
  IssueLabelerAPI,
  IssueLabelerReviewAPI,
} from "./apis/issue.api";
import { TaskObservationV3API } from "./apis/task-observation-v3.api";
import { LabelerAnnotationV3API } from "./apis/labeler-annotation-v3.api";
import { AnnotationV3API } from "./apis/annotation-v3.api";
import { BatchAPIV2 } from "./apis/batch-v2.api";
import { ProjectAPIV2 } from "./apis/project-v2.api";
import { LabelerAnnotationAPI } from "./apis/labeler-annotation.api";
import { AnnotationsAPI } from "./apis/annotations.api";
import { LabelerJobV3API } from "./apis/labeler-job-v3.api";

const APIPath = LabelAPIConfig.paths;

export const BatchObservationService = new BatchObservationAPI(
  APIPath.batchObservation
);
export const BatchService = new BatchAPI(APIPath.batch);
export const BatchServiceV2 = new BatchAPIV2(APIPath.batchV2);
export const ObservationService = new ObservationAPI(APIPath.observation);
export const ProjectService = new ProjectAPI(APIPath.project);
export const ProjectServiceV2 = new ProjectAPIV2(APIPath.projectV2);
export const TaskObservationService = new TaskObservationAPI(
  APIPath.taskObservation
);
export const TaskObservationServiceV2 = new TaskObservationV2API(
  APIPath.taskObservationV2
);
export const TaskObservationServiceV3 = new TaskObservationV3API(
  APIPath.taskObservationV3
);
export const TaskService = new TaskAPI(APIPath.task);
export const BatchWorkflowService = new BatchWorkflowAPI(APIPath.batchWorkflow);
export const WorkflowInstructionService = new WorkflowInstructionAPI(
  APIPath.workflowInstruction
);
export const JobService = new JobAPI(APIPath.job);
export const JobServiceV2 = new JobV2API(APIPath.jobV2);
export const JobServiceV3 = new JobV3API(APIPath.jobV3);
export const LabelerJobServiceV3 = new LabelerJobV3API(APIPath.labelerJobV3);
export const StepConditionService = new StepConditionAPI(
  APIPath.stepConditions
);
export const WorkManagementService = new WorkManagementAPI(
  APIPath.workManagements
);
export const BatchManagementService = new BatchManagementAPI(
  APIPath.batchManagements
);
export const PipelineSevice = new PipelineAPI(APIPath.pipelines);
export const TrainingJobService = new TrainingJobAPI(APIPath.trainingJobs);
export const MLModelsService = new MLModelsAPI(APIPath.mlModels);
export const IndividualService = new IndividualAPI(APIPath.individual);
export const AnnotationService = new AnnotationAPI(APIPath.annotations);
export const AnnotationServiceV2 = new AnnotationV2API(APIPath.annotationsV2);
export const AnnotationServiceV3 = new AnnotationV3API(APIPath.annotationsV3);
export const LabelerAnnotationService = new LabelerAnnotationAPI(
  APIPath.labelerAnnotations
);
export const LabelerAnnotationServiceV2 = new LabelerAnnotationV2API(
  APIPath.labelerAnnotationsV2
);
export const LabelerAnnotationServiceV3 = new LabelerAnnotationV3API(
  APIPath.labelerAnnotationsV3
);
export const AnnotationsService = new AnnotationsAPI("/api/v3");
export const ModelAIsService = new ModelAIsAPI(APIPath.modelAIs);
export const ModelAIVersionService = new ModelAIVersionsAPI(
  APIPath.modelAIVerions
);
export const ReopenRequestsService = new AnnotationAPI(APIPath.reopenRequests);
export const issueCustomerService = new IssueCustomerAPI(APIPath.issues);
export const issueLabelerService = new IssueLabelerAPI(APIPath.issues_labeler);
export const issueLabelerReviewService = new IssueLabelerReviewAPI(
  APIPath.issues_labeler_review
);
export const WorkflowService = new WorkflowAPI(APIPath.workflow);
export const WorkflowServiceV2 = new WorkflowAPIV2(APIPath.workflowV2);
