/*
 * File: batch-workflow.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { isHTTPStatusOK } from "services/common/http-status";
import { BatchWorkflowService } from "services/label-service";
import { BatchWorkflowDTO } from "services/label-service/dtos";
import { ProjectState } from "../project.state";

export enum BatchWorkflowThunk {
  LOAD_BATCH_WORKFLOWS = "batchWorkflow/loadBatchWorkflowsAsync",
  CREATE_BATCH_WORKFLOW = "batchWorkflow/createBatchWorkflowAsync",
  UPDATE_BATCH_WORKFLOW = "batchWorkflow/updateBatchWorkflowAsync",
  DELETE_BATCH_WORKFLOW = "batchWorkflow/deleteBatchWorkflowAsync",
}

export const loadBatchWorkflowsAsync = createAsyncThunk(
  BatchWorkflowThunk.LOAD_BATCH_WORKFLOWS,
  async (batchId: number) => {
    const payload = { batchId: batchId.toString() };
    const response = await BatchWorkflowService.getItems(payload);
    return response.data;
  }
);

export const createBatchWorkflowAsync = createAsyncThunk(
  BatchWorkflowThunk.CREATE_BATCH_WORKFLOW,
  async (data: Partial<BatchWorkflowDTO>) => {
    const response = await BatchWorkflowService.createItem(data);
    return response.data;
  }
);

export const updateBatchWorkflowAsync = createAsyncThunk(
  BatchWorkflowThunk.UPDATE_BATCH_WORKFLOW,
  async (batchWorkflow: BatchWorkflowDTO) => {
    const response = await BatchWorkflowService.updateItem(batchWorkflow);
    return response.data;
  }
);

export const deleteBatchWorkflowAsync = createAsyncThunk(
  BatchWorkflowThunk.DELETE_BATCH_WORKFLOW,
  async (batchWorkflowId: number) => {
    const response = await BatchWorkflowService.deleteItem(batchWorkflowId);
    if (isHTTPStatusOK(response.status)) {
      return batchWorkflowId;
    }
    return -1;
  }
);

export const batchWorkflowReducerBuilder = (
  builder: ActionReducerMapBuilder<ProjectState>
): ActionReducerMapBuilder<ProjectState> => {
  return builder
    .addCase(loadBatchWorkflowsAsync.pending, (state) => {
      state.requesting = true;
    })
    .addCase(loadBatchWorkflowsAsync.fulfilled, (state, action) => {
      state.requesting = false;
      state.batchWorkflows = action.payload;
    })
    .addCase(createBatchWorkflowAsync.pending, (state) => {
      state.requesting = true;
    })
    .addCase(createBatchWorkflowAsync.fulfilled, (state, action) => {
      state.requesting = false;
      state.batchWorkflows.unshift(action.payload);
    })
    .addCase(updateBatchWorkflowAsync.pending, (state) => {
      state.requesting = true;
    })
    .addCase(updateBatchWorkflowAsync.fulfilled, (state, action) => {
      state.requesting = false;
      state.batchWorkflows = state.batchWorkflows.map((p) => {
        if (p.id === action.payload.id) {
          return action.payload;
        }
        return p;
      });
    })
    .addCase(deleteBatchWorkflowAsync.pending, (state) => {
      state.requesting = true;
    })
    .addCase(deleteBatchWorkflowAsync.fulfilled, (state, action) => {
      state.requesting = false;
      state.batchWorkflows = state.batchWorkflows.filter((workflow) => {
        return workflow.id !== action.payload;
      });
    });
};
