/*
 * File: use-annotation-menu.hook.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 2nd July 2022 8:08:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  AnnotationContextMenuAction,
  ContextMenuItemData,
} from "components/common/context-menu/context-menu-item.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { MutableRefObject, useCallback, useMemo } from "react";
import { selectSelectedTextToken } from "store/labeler/text-workspace/text-editor/text-editor.selector";
import {
  textTokenContextMenuClosed,
  textTokenContextMenuOpened,
  textTokenIssueMenuOpened,
} from "store/labeler/text-workspace/text-workspace.slice";

export const useTokenContextMenu = (
  containerRef: MutableRefObject<HTMLDivElement | null>
) => {
  const dispatch = useAppDispatch();
  const selectedToken = useAppSelector(selectSelectedTextToken);

  const position = useMemo(() => {
    const { tokenId, contextMenu } = selectedToken;
    if (!tokenId || !contextMenu || !containerRef.current) return null;
    const element = containerRef.current.querySelector(
      `#${CSS.escape(tokenId)}`
    );
    if (element && containerRef.current) {
      const bounding = element.getBoundingClientRect();
      const parentBounding = containerRef.current.getBoundingClientRect();
      return {
        x: bounding.x - parentBounding.x,
        y: bounding.y + bounding.height + 20 - parentBounding.y,
      };
    }
    return null;
  }, [selectedToken, containerRef]);

  const hideTokenContextMenu = useCallback(() => {
    dispatch(textTokenContextMenuClosed());
  }, [dispatch]);

  const showTokenContextMenu = useCallback(
    (id: string) => {
      dispatch(textTokenContextMenuOpened(id));
    },
    [dispatch]
  );

  function handleTokenContextMenuAction(action: ContextMenuItemData) {
    if (action.value === AnnotationContextMenuAction.DELETE) {
    } else if (action.value === AnnotationContextMenuAction.ISSUE) {
      addTokenComment();
    } else if (action.value === AnnotationContextMenuAction.EDIT) {
    }
    hideTokenContextMenu();
  }

  function addTokenComment() {
    dispatch(textTokenIssueMenuOpened(selectedToken.tokenId));
  }

  const state = position ? { position, token: selectedToken } : null;
  return {
    tokenContextMenuState: state,
    showTokenContextMenu,
    hideTokenContextMenu,
    handleTokenContextMenuAction,
  };
};

export default useTokenContextMenu;
