/*
 * File: use-metadata-key-options.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th June 2023 10:14:38 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { StorageApi } from "data-access/impl/storage";
import { Option } from "domain/common";
import { useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import validator from "validator";

const QUERY_KEY = "METADATA_KEY_VALUE_OPTIONS";

export function useMetadataKeyValueOptions(
  keyId: number,
  defaultValue: string[]
) {
  const client = useQueryClient();

  function requestData() {
    return StorageApi.getAllMetadataValuesForKey(keyId);
  }

  const { data } = useQuery([QUERY_KEY, keyId], requestData, {});

  const options = useMemo<Option[]>(() => {
    return [...(data?.data ?? [])]
      .map((item) => {
        return {
          label: item.name ?? "",
          value: item.code ?? "",
          metadata: item,
        };
      })
      .filter((item) => !validator.isEmpty(item.value));
  }, [data]);

  const [values, setValues] = useState<string[]>(defaultValue);

  const selectedOptions = useMemo(() => {
    return options.filter((item) => values.includes(item.value));
  }, [options, values]);

  function onChange(newOption?: any) {
    if (!newOption) {
      setValues([]);
      return;
    }
    setValues((newOption as Option[]).map((option) => option.value));
  }

  function reloadData() {
    client.refetchQueries([QUERY_KEY]);
  }

  return {
    options,
    selectedOptions,
    onChange,
    reloadData,
  };
}

export default useMetadataKeyValueOptions;
