/*
 * File: batch-labeling.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th December 2021 4:42:32 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ImageContextMenuAction } from "components/common/context-menu/context-menu-item.component";
import { collectionUtils } from "domain/common";
import { Job } from "domain/labeling/job";
import { ImageLabelingData } from "domain/labeling/labeling-data";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { ObservationDTO, PollStrategy } from "services/label-service/dtos";
import { RootState } from "store";
import { RequestStatus } from "store/base/base.state";
import { getLaterality } from "utilities/dicom/dicom.utils";
import { ImageLabelingJob, JobStatus } from "./batch-labeling.state";
import { batchLabelingUtils } from "./batch-labeling.util";

export const selectImageLabelingContextMenuByJobId =
  (jobId: number) => (state: RootState) => {
    if (
      jobId === -1 ||
      !state.batchLabeling.contextMenu ||
      jobId !== state.batchLabeling.contextMenu?.jobId
    )
      return null;
    const { contextMenu } = state.batchLabeling;
    const options = [
      {
        value: ImageContextMenuAction.PICK_ANNOTATIONS,
        label: "Select annotations from other sources",
        shortcut: "",
      },
      {
        value: ImageContextMenuAction.CLEAR_ANNOTATIONS,
        label: "Clear annotations",
        shortcut: "",
      },
    ];
    return { ...contextMenu, options };
  };

export const selectBatchLabelingRequestStatus = (state: RootState) => {
  return state.batchLabeling.requestStatus;
};

export const selectIsLoadedBatchData = (state: RootState) => {
  return (
    state.batchLabeling.requestStatus === RequestStatus.SUCCESS ||
    state.batchLabeling.requestStatus === RequestStatus.FAILURE
  );
};

export const selectIsNoAvailableBatch = (state: RootState) => {
  const { requestStatus, imageLabelingJobs } = state.batchLabeling;
  return (
    requestStatus === RequestStatus.SUCCESS &&
    imageLabelingJobs.allIds.length === 0
  );
};

export const selectJobs = (state: RootState) => {
  const { allIds, entities } = state.batchLabeling.imageLabelingJobs;
  return allIds.map((id) => entities[id].job);
};

export const selectJobIds = (state: RootState) => {
  return state.batchLabeling.imageLabelingJobs.allIds as number[];
};

export const selectCurrentJobIndex = (state: RootState) => {
  const { imageLabelingJobs, currentJobId } = state.batchLabeling;
  return imageLabelingJobs.allIds.findIndex((jobId) => jobId === currentJobId);
};

export const selectActiveJobId = (state: RootState) => {
  const { imageViews, activeViewIndex, currentJobId } = state.batchLabeling;
  if (imageViews.length < 2 || activeViewIndex === -1) return currentJobId;
  return imageViews[activeViewIndex];
};

export const selectImageLabelingJobs = (state: RootState) => {
  return state.batchLabeling.imageLabelingJobs;
};

export const selectJobStatuses = (state: RootState) => {
  return state.batchLabeling.jobStatuses;
};

export const selectJobById =
  (jobId: number) =>
  (state: RootState): Job => {
    const { imageLabelingJobs } = state.batchLabeling;
    return imageLabelingJobs.entities[jobId].job;
  };
export const selectJobFileById =
  (jobId: number) =>
  (state: RootState): StorageFileDTO => {
    const { imageLabelingJobs } = state.batchLabeling;
    return imageLabelingJobs.entities[jobId].file as StorageFileDTO;
  };
export const selectImageLabelingJobById =
  (jobId: number) =>
  (state: RootState): ImageLabelingJob => {
    const { imageLabelingJobs } = state.batchLabeling;
    return imageLabelingJobs.entities[jobId];
  };

export const selectCurrentJob = (state: RootState) => {
  const { imageLabelingJobs, currentJobId } = state.batchLabeling;
  if (currentJobId === -1) return null;
  return imageLabelingJobs.entities[currentJobId];
};

export const selectCurrentJobData = (state: RootState) => {
  const { imageLabelingJobs, currentJobId } = state.batchLabeling;
  if (currentJobId === -1) return null;
  return imageLabelingJobs.entities[currentJobId];
};

export const selectAnnotatedObservations = (_: RootState): ObservationDTO[] => {
  return [];
};

export const selectPreviousJobAnnotations =
  (jobId: number) => (state: RootState) => {
    const { imageLabelingJobs } = state.batchLabeling;
    const imageLabelingJob = collectionUtils.getOne(imageLabelingJobs, jobId);
    if (!imageLabelingJob || !imageLabelingJob.data?.previousJobData) return [];
    return imageLabelingJob.data.previousJobData.map((data) =>
      batchLabelingUtils.labelingDataToJobAnnotation(data as ImageLabelingData)
    );
  };

export const selectCurrentJobId = (state: RootState) => {
  return state.batchLabeling.currentJobId;
};

export const selectJobRequestStatus = (state: RootState) => {
  const { jobRequestStatuses } = state.batchLabeling;
  const { imageViews, activeViewIndex } = state.batchLabeling;
  const activeJobId = imageViews[activeViewIndex];
  if (activeJobId !== -1 && jobRequestStatuses.hasOwnProperty(activeJobId)) {
    return jobRequestStatuses[activeJobId];
  }
  return RequestStatus.NONE;
};

export const selectIsLoadedJobData = (jobId: number) => (state: RootState) => {
  const { jobRequestStatuses, imageLabelingJobs } = state.batchLabeling;
  const imageLabelingJob = collectionUtils.getOne(imageLabelingJobs, jobId);
  if (!imageLabelingJob) return false;
  if (!jobRequestStatuses.hasOwnProperty(jobId)) return false;
  const requestStatus = jobRequestStatuses[jobId];
  return (
    requestStatus === RequestStatus.SUCCESS ||
    requestStatus === RequestStatus.FAILURE
  );
};

export const selectHasWorkingJobs = (state: RootState) => {
  const { imageLabelingJobs, jobStatuses } = state.batchLabeling;
  return !!imageLabelingJobs.allIds.find(
    (jobId) => jobStatuses[jobId as number] === JobStatus.WORKING
  );
};

export const selectHasSavedJobs = (state: RootState) => {
  const { imageLabelingJobs, jobStatuses } = state.batchLabeling;
  return !!imageLabelingJobs.allIds.find(
    (jobId) => jobStatuses[jobId as number] === JobStatus.SAVED
  );
};

export const selectImageLabelingViews = (state: RootState) => {
  return state.batchLabeling.imageViews;
};
export const selectImageLabelingTaskViews = (state: RootState) => {
  const { imageViews, imageLabelingJobs } = state.batchLabeling;
  if (imageLabelingJobs.allIds.length === 0) return [];
  return imageViews.map((jobId) => {
    return imageLabelingJobs.entities[jobId]?.task?.id || -1;
  });
};

export const selectImageLabelingViewActiveIndex = (state: RootState) => {
  return state.batchLabeling.activeViewIndex;
};

export const selectImageLabelingActiveJobId = (state: RootState) => {
  const { imageViews, activeViewIndex } = state.batchLabeling;
  return imageViews[activeViewIndex];
};

export const selectImageLabelingActiveTaskId = (state: RootState) => {
  const activeJobId = selectImageLabelingActiveJobId(state);
  const { imageLabelingJobs } = state.batchLabeling;
  const labelingJob = collectionUtils.getOne(imageLabelingJobs, activeJobId);
  return labelingJob?.task?.id || -1;
};

export const selectWaitingJobId = (state: RootState) => {
  const { jobRequestStatuses, imageLabelingJobs, imageViews } =
    state.batchLabeling;

  const loadingJob = imageLabelingJobs.allIds.find((jobId) => {
    if (!jobRequestStatuses.hasOwnProperty(jobId)) return false;
    return jobRequestStatuses[jobId as number] === RequestStatus.LOADING;
  });

  if (loadingJob) return -1;

  for (const jobId of imageViews) {
    if (!jobRequestStatuses.hasOwnProperty(jobId)) continue;
    if (jobRequestStatuses[jobId] !== RequestStatus.NONE) continue;
    return jobId;
  }

  const waitingJob = imageLabelingJobs.allIds.find((jobId) => {
    if (!jobRequestStatuses.hasOwnProperty(jobId)) return false;
    return jobRequestStatuses[jobId as number] === RequestStatus.NONE;
  });
  if (waitingJob) return waitingJob as number;
  return -1;
};

export const selectBatchLabelingIsAllJobsLoaded = (state: RootState) => {
  const jobIds = state.batchLabeling.imageLabelingJobs.allIds;
  if (jobIds.length <= 0) return false;

  for (const jobId of jobIds) {
    if (
      state.batchLabeling.jobRequestStatuses[jobId as number] !==
      RequestStatus.SUCCESS
    ) {
      return false;
    }
  }
  return true;
};

export const selectBatchLabelingJobIdsInView = (state: RootState) => {
  return state.batchLabeling.imageViews.filter(
    (id, index) =>
      id > 0 && state.batchLabeling.imageViews.indexOf(id) === index // for removing duplicate
  );
};

export const selectBatchLabelingJobIdsInBatch = (state: RootState) => {
  return state.batchLabeling.imageLabelingJobs.allIds as number[];
};

export const selectBatchLabelingIsPollByGroupProject = (state: RootState) => {
  return state.batchLabeling.pollStrategy === PollStrategy.GROUP;
};

export const selectBatchLabelingProject = (state: RootState) => {
  return state.batchLabeling.project;
};

export const selectAutoSaveInSecond = (state: RootState) => {
  return state.batchLabeling.project?.autoSaveInSecond;
};

export const selectJobIdsByStatus =
  (status: JobStatus) => (state: RootState) => {
    const { imageLabelingJobs, jobStatuses } = state.batchLabeling;
    return imageLabelingJobs.allIds.filter(
      (jobId) => jobStatuses[jobId as number] === status
    );
  };

export const selectIsCurrentWorkingJob = (state: RootState) => {
  const currentJob = selectCurrentJob(state);
  const jobStatuses = selectJobStatuses(state);
  return jobStatuses[currentJob?.id as number] === JobStatus.WORKING;
};

export const selectIsCurrentSavedJob = (state: RootState) => {
  const currentJob = selectCurrentJob(state);
  const jobStatuses = selectJobStatuses(state);
  return jobStatuses[currentJob?.id as number] === JobStatus.SAVED;
};

export const selectTaskFileData = (taskId: number) => (state: RootState) => {
  const { imageLabelingJobs } = state.batchLabeling;
  for (const jobId of imageLabelingJobs.allIds) {
    const imageLabelingJob = collectionUtils.getOne(imageLabelingJobs, jobId);
    if (!imageLabelingJob) continue;
    if (taskId === imageLabelingJob.task?.id) {
      return imageLabelingJob;
    }
  }
  return null;
};

export const selectImageLabelingActiveLaterality = (state: RootState) => {
  const activeJobId = selectImageLabelingActiveJobId(state);
  const labelingJob = selectImageLabelingJobById(activeJobId)(state);
  const laterality = getLaterality(labelingJob?.dicomData);
  return laterality;
};

export const selectImageLabelingJobIdsSameLaterality =
  (jobId: number) => (state: RootState) => {
    const labelingJob = selectImageLabelingJobById(jobId)(state);
    const laterality = getLaterality(labelingJob?.dicomData);

    if (!laterality) return [jobId];

    const allIds: number[] = selectBatchLabelingJobIdsInBatch(state);

    return allIds.filter((id) => {
      const currentLabelingJob = selectImageLabelingJobById(id)(state);
      const currentLaterality = getLaterality(currentLabelingJob?.dicomData);
      return currentLaterality === laterality;
    });
  };

export const selectBatchLabelingLabels = (state: RootState) => {
  return state.batchLabeling.labels;
};
