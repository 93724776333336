/*
 * File: workspace-users-table.component.tsx
 * Project: app-aiscaler-web
 * File Created: Sunday, 2nd January 2022 10:24:47 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Checkbox } from "@material-ui/core";
import { IconEdit, IconTrash } from "components/common/vb-icon.component";
import { User } from "domain/customer";
import { useMemo, useRef, useState } from "react";
import { UserFilter } from "../../context/workspace-users.state";
import { RowData, userRowDataMapper } from "./types";
import { useUsersTableColumn } from "./users-table-column.component";

interface Props {
  users: User[];
  filter: UserFilter;
  onRowAction?(action: string, row: User): void;
  onSortChanged?(sort: string): void;
}

export const WorkspaceUsersTable = ({
  filter,
  users,
  onRowAction,
  onSortChanged,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [paddingRight, setPaddingRight] = useState(0);
  const rows: RowData[] = useMemo(() => users.map(userRowDataMapper), [users]);
  const { columns } = useUsersTableColumn(filter, onSortChanged);

  function handleMouseOver() {
    if (!containerRef.current) return;
    const { scrollWidth, clientWidth, scrollLeft } = containerRef.current;
    setPaddingRight(scrollWidth - clientWidth - scrollLeft);
  }

  return (
    <div
      className="relative overflow-x-auto"
      ref={containerRef}
      onMouseOver={handleMouseOver}
    >
      <table className="min-w-full vb-table">
        <thead>
          <tr>
            {columns.map((column, index) => {
              return column.renderColumnHeader(column, index);
            })}
          </tr>
        </thead>
        <tbody className="relative mt-4 text-xs">
          {rows.map((row, rowIndex) => {
            return (
              <tr
                key={rowIndex}
                className="relative hover:bg-secondary-50 parent"
              >
                {columns.map((column, columnIndex) => {
                  return (
                    <td
                      className="h-10 px-2 whitespace-nowrap"
                      key={column.field}
                    >
                      {column.renderCell(row, rowIndex, columnIndex)}
                    </td>
                  );
                })}
                <td className="absolute left-0 transform -translate-x-full -translate-y-1/2 top-1/2">
                  <Checkbox className="flex-none w-6 h-6" />
                </td>
                <td
                  className="absolute h-full transform -translate-y-1/2 top-1/2 child-on-hover"
                  style={{ right: `${paddingRight}px` }}
                >
                  <div className="flex flex-row items-center h-full gap-1 px-3 bg-secondary-50">
                    <button
                      className="flex items-center justify-center w-6 h-6"
                      onClick={() =>
                        onRowAction && onRowAction("edit", row.data)
                      }
                    >
                      <IconEdit className="flex-none w-4 h-4 text-primary" />
                    </button>
                    <button
                      className="flex items-center justify-center w-6 h-6"
                      onClick={() =>
                        onRowAction && onRowAction("delete", row.data)
                      }
                    >
                      <IconTrash className="flex-none w-4 h-4 text-error-500" />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
          <tr className="absolute top-0 left-0 w-full h-full border rounded pointer-events-none border-background-300"></tr>
        </tbody>
      </table>
    </div>
  );
};
