/*
 * File: file-nav.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 6:02:46 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useFileBrowseContext } from "../../context/file-browse.context";
import { DataTypeFilter } from "./filters/data-type-filter";
import { DatasetFilter } from "./filters/dataset-filter";
import { LabelFilter } from "./filters/label-filter";
import { CreatedByFilter } from "./filters/created-by-filter";
import { MetadataFilter } from "./filters/metadata-filter";
import { FileNameFilter } from "./filters/file-name-filter";
import { FileIdsFilter } from "./filters/file-ids-filter";

export function FileNav() {
  const { filter, updateFilter } = useFileBrowseContext();

  return (
    <div className="flex-none h-full py-4 space-y-4 bg-white w-80">
      <h2 className="px-4 font-semibold text-warmGray-700">File Explorer</h2>
      <DataTypeFilter filter={filter} onFilterChange={updateFilter} />
      <DatasetFilter filter={filter} onFilterChange={updateFilter} />
      <LabelFilter filter={filter} onFilterChange={updateFilter} />
      <FileNameFilter filter={filter} onFilterChange={updateFilter} />
      <FileIdsFilter filter={filter} onFilterChange={updateFilter} />
      <CreatedByFilter filter={filter} onFilterChange={updateFilter} />
      <MetadataFilter filter={filter} onFilterChange={updateFilter} />
    </div>
  );
}

export default FileNav;
