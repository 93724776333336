/*
 * File: annotation.api.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 19th October 2021 3:50:40 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AxiosResponse } from "axios";
import { AnnotationSource } from "domain/labeling/annotation-source";
import { AnnotationJobRequestDTO } from "../dtos/annotations.dto";
import {
  JobAnnotations,
  TaskObservationV3DTO,
  TextObservationV3DTO,
} from "../dtos/task-observation-v3.dto";
import { BaseAPI } from "./base.api";

export class LabelerAnnotationV3API extends BaseAPI<TaskObservationV3DTO> {
  getAnnotations(
    batchId: number,
    mediaId: number | string,
    source?: string
  ): Promise<AxiosResponse<TaskObservationV3DTO>> {
    const payload: Record<string, string> = {
      "batchId.equals": batchId.toString(),
      "mediaId.equals": mediaId.toString(),
      size: "10000",
    };
    if (source) payload["source.equals"] = source;
    return this.getItems(payload);
  }

  getAnnotationsByJob(jobId: number): Promise<AxiosResponse<JobAnnotations>> {
    return this.getItem(`job/${jobId}`);
  }

  getSystemAnnotations(
    batchId: number,
    mediaId: number | string
  ): Promise<AxiosResponse<TaskObservationV3DTO>> {
    const payload: Record<string, string> = {
      "batchId.equals": batchId.toString(),
      "mediaId.equals": mediaId.toString(),
      "source.in": [AnnotationSource.IMPORT, AnnotationSource.MODEL].join(","),
      size: "10000",
    };
    return this.getItems(payload);
  }

  getTextAnnotations(
    batchId: number,
    mediaId: number | string,
    source?: string
  ): Promise<AxiosResponse<TextObservationV3DTO>> {
    const payload: Record<string, string> = {
      "batchId.equals": batchId.toString(),
      "mediaId.equals": mediaId.toString(),
      size: "10000",
    };
    if (source) payload["source.equals"] = source;
    return this.getItems(payload);
  }

  getSytemTextAnnotations(
    batchId: number,
    mediaId: number | string
  ): Promise<AxiosResponse<TextObservationV3DTO>> {
    const payload: Record<string, string> = {
      "batchId.equals": batchId.toString(),
      "mediaId.equals": mediaId.toString(),
      "source.in": [AnnotationSource.IMPORT, AnnotationSource.MODEL].join(","),
      size: "10000",
    };
    return this.getItems(payload);
  }

  getTextAnnotationsByFile(
    batchId: number,
    mediaId: number | string
  ): Promise<AxiosResponse<TextObservationV3DTO>> {
    const payload: Record<string, string> = {
      "batchId.equals": batchId.toString(),
      "mediaId.equals": mediaId.toString(),
      "source.in": [
        AnnotationSource.CLIENT,
        AnnotationSource.IMPORT,
        AnnotationSource.MODEL,
      ].join(","),
      size: "100000",
    };
    return this.getItems(payload);
  }

  getImageAnnotationsByFile(
    batchId: number,
    mediaId: number | string
  ): Promise<AxiosResponse<TaskObservationV3DTO>> {
    const payload: Record<string, string> = {
      "batchId.equals": batchId.toString(),
      "mediaId.equals": mediaId.toString(),
      "source.in": [
        AnnotationSource.CLIENT,
        AnnotationSource.IMPORT,
        AnnotationSource.MODEL,
      ].join(","),
      size: "100000",
    };
    return this.getItems(payload);
  }

  saveTextAnnotations(
    jobId: string | number,
    payload: AnnotationJobRequestDTO
  ) {
    return this.post(`${this.getPath()}/job/${jobId}`, payload);
  }
}
