/*
 * File: object-tree.tsx
 * Project: aiscaler-web-monorepo
 * File Created: Tuesday, 15th November 2022 3:03:03 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Fragment } from "react";
import ObjectItem from "./object-item";
import ObjectMenu from "./object-menu";
import useObjectTree from "./use-object-tree";

export default function ObjectTree() {
  const { items } = useObjectTree();
  return (
    <Fragment>
      <ul className="w-full py-2 space-y-2">
        {items.map((item) => {
          return <ObjectItem key={item.id} {...item} />;
        })}
      </ul>
      <ObjectMenu />
    </Fragment>
  );
}
