/*
 * File: dataset-files-explorer.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 24th August 2021 9:46:50 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { GridColDef } from "@material-ui/data-grid";
import { useDatasetDetailContext } from "../../dataset-detail.context";
import { FileInfoSelect } from "./file-info-panel/file-info-select.component";
import { StorageTable } from "./storage-table/storage-table";

interface Props {
  height?: string;
  columns?: GridColDef[];
  actions?: string[];
  onAction?: (action: string, row: any) => void;
  isTextPannel?: boolean;
}

export const DatasetFilesExplorer = ({
  height = "666px",
  columns,
  actions,
  onAction,
}: Props) => {
  const { showFileInfo } = useDatasetDetailContext();

  return (
    <div className="p-4">
      <div
        className="relative h-full overflow-y-auto"
        style={{ height: height }}
      >
        <StorageTable columns={columns} actions={actions} onAction={onAction} />
        {showFileInfo && (
          <div className="absolute top-0 bottom-0 right-0 z-50 h-full w-96">
            <FileInfoSelect />
          </div>
        )}
      </div>
    </div>
  );
};
