import { VBTabs } from "components/common/vb-tabs/vb-tabs.component";
import { Annotation } from "domain/image-labeling";
import { JobAnnotation } from "domain/image-labeling/job-annotation";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useCallback, useMemo, useState } from "react";
import {
  selectCurrentJobId,
  selectPreviousJobAnnotations,
} from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { selectAllAnnotations } from "store/labeler/image-workspace/image-annotations/image-annotations.selectors";
import { ActiveAnnotation } from "store/labeler/image-workspace/image-annotations/image-annotations.state";
import {
  imageAnnotationsLoaded,
  setActiveAnnotations,
  updateActiveAnnotation,
} from "store/labeler/image-workspace/image-workspace.slice";
import useDeepCompareEffect from "use-deep-compare-effect";
import { ActionPanelIou } from "./action-panel-iou.component";
import { ActionPanelLabel } from "./action-panel-label.component";
import { ActionPanelLabeler } from "./action-panel-labeler.component";

enum ActionPanelTab {
  All = "all",
  Upvote = "upvote",
  DownVote = "downvote",
  NoteSpecific = "note-specific",
}
export const ActionPanel = () => {
  const dispatch = useAppDispatch();
  const currentJobId = useAppSelector(selectCurrentJobId);
  const previousJobAnnotations = useAppSelector(
    selectPreviousJobAnnotations(currentJobId)
  );
  const annotations = useAppSelector(selectAllAnnotations);

  const [emails, setEmails] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<ActionPanelTab>(
    ActionPanelTab.All
  );

  useDeepCompareEffect(() => {
    const annotations: Annotation[] = [];
    previousJobAnnotations.forEach((jobAnnotation: JobAnnotation) => {
      Object.values(jobAnnotation.annotations.entities).forEach(
        (entityValue) => {
          annotations.push({
            ...entityValue,
            vote: entityValue.vote || 0,
            locked: true,
          });
        }
      );
    });

    const activeAnnotations: ActiveAnnotation[] = annotations.map(
      (annotation) => ({
        annotationId: annotation.id,
        selected: true,
        hovering: false,
      })
    );

    dispatch(imageAnnotationsLoaded(annotations));
    dispatch(setActiveAnnotations(activeAnnotations));
  }, [dispatch, previousJobAnnotations]);

  useDeepCompareEffect(() => {
    annotations.forEach((previousAnnotation) => {
      dispatch(
        updateActiveAnnotation({
          annotationId: previousAnnotation.id,
          selected: emails.includes(previousAnnotation.annotator),
        })
      );
    });
  }, [dispatch, emails]);

  const getAnnotationsByEmail = useCallback(
    (annotations: Annotation[], emails: string[]) => {
      return annotations.filter((previousAnnotation) =>
        emails.includes(previousAnnotation.annotator)
      );
    },
    []
  );

  const getAnnotationsByTab = useCallback(
    (annotations: Annotation[], tab: ActionPanelTab) => {
      if (tab === ActionPanelTab.All) {
        return annotations;
      }
      return annotations.filter((annotation) => {
        if (tab === ActionPanelTab.Upvote)
          return annotation.vote && annotation.vote > 0;
        if (tab === ActionPanelTab.DownVote)
          return annotation.vote && annotation.vote < 0;
        if (tab === ActionPanelTab.NoteSpecific)
          return !annotation.vote || annotation.vote === 0;
        return false;
      });
    },
    []
  );

  const filteredAnnotations = useMemo(() => {
    const annotationsByEmail = getAnnotationsByEmail(annotations, emails);
    const annotationsByTab = getAnnotationsByTab(annotationsByEmail, activeTab);

    return annotationsByTab;
  }, [
    getAnnotationsByEmail,
    annotations,
    emails,
    getAnnotationsByTab,
    activeTab,
  ]);

  const groupedAnnotationsByLabel = useMemo(() => {
    const labelMap = new Map<number, Annotation[]>();

    filteredAnnotations.forEach((annotation: Annotation) => {
      labelMap.set(annotation.labelId, [
        ...(labelMap.get(annotation.labelId) || []),
        annotation,
      ]);
    });
    return Object.fromEntries(labelMap);
  }, [filteredAnnotations]);

  const countAnnotations = useCallback(
    (tab: ActionPanelTab) => {
      const annotationsByEmail = getAnnotationsByEmail(annotations, emails);
      const annotationsByTab = getAnnotationsByTab(annotationsByEmail, tab);
      return annotationsByTab.length;
    },
    [annotations, emails, getAnnotationsByEmail, getAnnotationsByTab]
  );

  const handleSelectTab = (tabId: string) => {
    setActiveTab(tabId as ActionPanelTab);
  };

  return (
    <div className="bg-background-900">
      <div className="p-4">
        <ActionPanelLabeler onChange={setEmails} />
      </div>

      <div className="p-4">
        <ActionPanelIou jobId={currentJobId} />
      </div>

      <div className="bg-background-900">
        <VBTabs
          activeClass="text-white font-bold border-none px-2"
          inActiveClass="text-white opacity-60 font-normal border-none px-2"
          className="w-full pl-3 text-sm border-none"
          activeTab={activeTab}
          onSelect={handleSelectTab}
          tabs={[
            { id: ActionPanelTab.All, name: "All" },
            {
              id: ActionPanelTab.Upvote,
              name: `Upvote(${countAnnotations(ActionPanelTab.Upvote)})`,
            },
            {
              id: ActionPanelTab.DownVote,
              name: `Downvote(${countAnnotations(ActionPanelTab.DownVote)})`,
            },
            {
              id: ActionPanelTab.NoteSpecific,
              name: `None(${countAnnotations(ActionPanelTab.NoteSpecific)})`,
            },
          ]}
        />

        <div className="space-y-2">
          {Object.entries(groupedAnnotationsByLabel).map(([key, value]) => (
            <div className="py-4 bg-background-800" key={key}>
              <ActionPanelLabel
                labelId={+key}
                labelName={value[0].label}
                annotations={value}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
