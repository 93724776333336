/*
 * File: upload-labelers-csv.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 1st February 2023 2:49:15 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Popover } from "@material-ui/core";
import classNames from "classnames";
import { IconMore } from "components/common/vb-icon.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import fileSize from "filesize";
import { useAppDispatch } from "hooks/use-redux";
import { ChangeEvent, useRef, useState } from "react";
import { JobService } from "services/label-service";
import { BatchDTO } from "services/label-service/dtos";
import {
  createStorageResource,
  processStorageResource,
  uploadToAzureBlob,
} from "services/storage/apis/resource.api";
import { StorageResource } from "services/storage/dto/resource.dto";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import * as Sentry from "@sentry/react";

interface UploadLabelersCSVProps {
  batch: BatchDTO;
}
export function UploadLabelersCSV({ batch }: UploadLabelersCSVProps) {
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [open, setOpen] = useState(false);

  function toggleVisibility() {
    setOpenMenu((pre) => !pre);
  }
  function handleClickUpload() {
    setOpen(true);
    setOpenMenu(false);
  }

  function handleClickClose() {
    setOpen(false);
  }

  return (
    <div>
      <button
        ref={anchorEl}
        className="flex items-center focus:outline-none"
        onClick={toggleVisibility}
      >
        <IconMore />
      </button>

      <Popover
        open={openMenu}
        anchorEl={anchorEl.current}
        onClose={toggleVisibility}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="py-2 bg-white rounded">
          <button
            onClick={handleClickUpload}
            className="px-6 py-2 hover:text-primary"
          >
            Manual assign jobs using CSV file
          </button>
        </div>
      </Popover>

      {open && (
        <VBModal
          open
          title="Assign labelers using CSV"
          textSubmit="Upload"
          textClose="Cancel"
          onClose={handleClickClose}
          footerHidden
        >
          <UploadLabelersForm batch={batch} onClose={handleClickClose} />
        </VBModal>
      )}
    </div>
  );
}
const MAX_FILE_SIZE = 1024 * 1024 * 5;
interface UploadLabelersFormProps {
  onClose(): void;
  batch: BatchDTO;
}
function UploadLabelersForm({ batch, onClose }: UploadLabelersFormProps) {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [isValid, setIsValid] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  function handleClickSelect() {
    inputRef.current?.click();
  }

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files.item(0);
      if (!selectedFile) return;
      setFile(selectedFile);
      setIsValid(isValidFile(selectedFile));
    }
  }

  function isValidFile(fileToValidate: File) {
    return fileToValidate.size < MAX_FILE_SIZE;
  }

  async function startUpload() {
    if (loading || !batch || !file || !isValid) return;
    try {
      setLoading(true);
      const resource = await createStorageResource("Cache");
      const resourceData = resource.data as StorageResource;
      const resourceId = resourceData.resourceId;
      await uploadToAzureBlob(file, resourceData.path);
      await processStorageResource(resourceId);
      const response = await JobService.assignJobUsingCSV(batch.id, resourceId);
      handleThunkRejected(response);
      setLoading(false);
      dispatch(enqueueSuccessNotification("Success"));
      onClose();
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error?.message || "Failed to upload file";
      dispatch(enqueueErrorNotification(errMessage));
      setLoading(false);
    }
  }

  return (
    <div>
      <div
        className="relative flex items-center justify-center h-40 p-4 border border-dashed rounded cursor-pointer hover:border-primary"
        onClick={handleClickSelect}
      >
        <input
          ref={inputRef}
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className="absolute top-0 left-0 opacity-0 pointer-events-none"
        />
        {!file && (
          <div className="w-full text-center pointer-events-none">
            <div className="py-2 text-lg font-semibold">
              Select or browse file
            </div>
          </div>
        )}
        {file && (
          <div className="w-full space-y-2 text-center">
            <div>{file.name}</div>
            <div>{fileSize(file.size)}</div>
            {!isValid && <div className="text-red-500">File is too big</div>}
          </div>
        )}
      </div>
      <div className="pt-2">
        Allow CSV, maximum file size is {fileSize(MAX_FILE_SIZE)}
      </div>
      <div className="flex items-center justify-end gap-4 pt-4">
        <button
          onClick={startUpload}
          className={classNames("button-text-primary hover:opacity-90", {
            "cursor-not-allowed grayscale filter pointer-events-none": !isValid,
            "cursor-default pointer-events-none opacity-90": loading,
          })}
        >
          {loading ? "Uploading..." : "Upload"}
        </button>
      </div>
    </div>
  );
}

export default UploadLabelersCSV;
