import { useImageEditorContext } from "pages/labeler/image-labeling/image-editor-context/image-editor.context";
import { selectImageLabelingJobById } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { useAppSelector } from "hooks/use-redux";
import {
  selectJobAnnotations,
  selectJobRelationAnnotations,
} from "store/labeler/image-workspace/image-annotations/image-annotations.selectors";
import useDeepCompareEffect from "use-deep-compare-effect";

export default function useImageAnnotations(
  jobId: number,
  imageLoaded: boolean
) {
  const { cornerstoneHandler } = useImageEditorContext();
  const labelingJob = useAppSelector(selectImageLabelingJobById(jobId));
  const user = labelingJob.job.assignee;
  const annotations = useAppSelector(selectJobAnnotations(jobId, [user]));
  const relations = useAppSelector(selectJobRelationAnnotations(jobId, [user]));

  useDeepCompareEffect(() => {
    if (!imageLoaded) return;
    cornerstoneHandler.current?.syncImageAnnotations(annotations);
  }, [annotations, cornerstoneHandler.current, imageLoaded]);

  useDeepCompareEffect(() => {
    if (!imageLoaded) return;
    cornerstoneHandler.current?.syncImageRelationAnnotations(relations);
  }, [relations, cornerstoneHandler, imageLoaded]);

  return { labelingJob, annotations, relations };
}
