/*
 * File: draw-handles.js
 * Project: app-aiscaler-web
 * File Created: Friday, 3rd December 2021 11:04:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import * as csTools from "cornerstone-tools";
import { toolStyle, toolColors } from "cornerstone-tools";
const external = csTools.external;

/**
 * Draws proivded handles to the provided context
 * @public
 * @method drawHandles
 * @memberof Drawing
 *
 * @param {CanvasRenderingContext2D} context - Target context
 * @param {*} evtDetail - Cornerstone's 'cornerstoneimagerendered' event's `detail`
 * @param {Object[]|Object} handles - An array of handle objects, or an object w/ named handle objects
 * @param {Object} [options={}] - Options object
 * @param {string} [options.color]
 * @param {Boolean} [options.drawHandlesIfActive=false] - Whether the handles should only be drawn if Active (hovered/selected)
 * @param {string} [options.fill]
 * @param {Number} [options.handleRadius=6]
 * @returns {undefined}
 */
export default function (context, evtDetail, handles, options = {}) {
  const element = evtDetail.element;
  const defaultColor = toolColors.getToolColor();

  context.strokeStyle = options.color || defaultColor;

  const handleKeys = Object.keys(handles);

  for (let i = 0; i < handleKeys.length; i++) {
    const handleKey = handleKeys[i];
    const handle = handles[handleKey];

    if (handle.drawnIndependently === true) {
      continue;
    }

    const lineWidth = handle.active
      ? toolStyle.getActiveWidth()
      : toolStyle.getToolWidth();
    const fillStyle = options.fill || options.color;

    const pathOptions = { lineWidth, fillStyle };

    if (options.lineDash) {
      pathOptions.lineDash = options.lineDash;
    }

    const handleCanvasCoords = external.cornerstone.pixelToCanvas(
      element,
      handle
    );
    if (
      handleCanvasCoords &&
      !isNaN(handleCanvasCoords.x) &&
      !isNaN(handleCanvasCoords.y)
    ) {
      const { x, y } = handleCanvasCoords;
      const handleRadius = handle.radius || options.handleRadius || 4;
      context.fillStyle = fillStyle;
      context.beginPath();
      context.moveTo(x - handleRadius, y - handleRadius);
      context.lineTo(x + handleRadius, y - handleRadius);
      context.lineTo(x + handleRadius, y + handleRadius);
      context.lineTo(x - handleRadius, y + handleRadius);
      context.lineTo(x - handleRadius, y - handleRadius);
      context.closePath();
      context.fill();
    }
  }
}
