/*
 * File: ml-web.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 17th February 2022 9:01:24 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { MLModelType } from "./ml-model";

export enum ServingWebStatus {
  Activated = "Activated",
  Deactivated = "Deactivated",
}
export interface MLWeb {
  id: string;
  name: string;
  description: string;
  createdBy: string;
  lastOpened: Date;
  status: ServingWebStatus;
  type: MLModelType;
  data: Record<string, string>;
}
