/*
 * File: create-step.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 8th July 2021 3:48:43 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from 'react-i18next';
import { classnames } from "utilities/classes";
import { StepInfo, StepStatus } from "../create-batch.model";

interface Props {
  step: StepInfo;
  status: StepStatus;
}
export const CreateBatchStep = ({ step: { type, title }, status }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="relative pr-8 text-right">
      <h3
        className={classnames({
          "font-bold": status === StepStatus.CURRENT,
          "text-gray-400": status === StepStatus.DEFAULT,
          "text-black": status !== StepStatus.COMPLETED,
        })}
      >
        {t(title)}
      </h3>
      <div
        className={classnames(
          "absolute -right-3 top-0 w-6 h-6 rounded-full flex items-center justify-center",
          {
            "bg-primary text-white":
              status === StepStatus.COMPLETED || status === StepStatus.CURRENT,
            "bg-gray-100 text-black": status === StepStatus.DEFAULT,
          }
        )}
      >
        {status === StepStatus.COMPLETED ? (
          <i className="uir-check"></i>
        ) : (
          <span>{type + 1}</span>
        )}
      </div>
    </div>
  );
};
