/*
 * File: workflow.router.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { WorkflowsPage } from "pages/customer/workflows/workflows.page";
import { WorkflowDetailPage } from "pages/customer/workflows/workflow-detail/workflow-detail.page";
import { Route, Switch } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { CreateWorkflowPage } from "./create-workflow/create-workflow.page";

export const WorkflowRouter = () => {
  return (
    <Switch>
      <Route exact path={Routes.WORKFLOWS} component={WorkflowsPage} />
      <Route
        exact
        path={Routes.WORKFLOWS_CREATE_NEW}
        component={CreateWorkflowPage}
      />
      <Route
        exact
        path={Routes.WORKFLOWS_DETAIL}
        component={WorkflowDetailPage}
      />
    </Switch>
  );
};
