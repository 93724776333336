/*
 * File: archive-label.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBConfirmDialog } from "components/common/vb-delete-dialog/vb-confirm-dialog.component";
import { useAppDispatch } from "hooks/use-redux";
import { ObservationDTO } from "services/label-service/dtos";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import {
  activeProjectObservationAsync,
  loadProjectObservationsAsync,
} from "store/customer/project/project.slice";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import * as Sentry from "@sentry/react";

interface Props {
  observation: ObservationDTO | null;
  onClose: () => void;
}

export const ReactiveLabelModal = ({ observation, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  async function activeObservation(observation: ObservationDTO | null) {
    if (!observation) return;
    const activeObservation: ObservationDTO = {
      ...observation,
      active: true,
    };
    try {
      const response = await dispatch(
        activeProjectObservationAsync(activeObservation)
      );
      await dispatch(loadProjectObservationsAsync(observation.projectId));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textEnableSuccess")));
    } catch (err: any) {
      Sentry.captureException(err);
      const message = err.message || t("common:textEnableFailed");
      dispatch(enqueueErrorNotification(message));
    } finally {
      onClose();
    }
  }

  const handleConfirmed = () => {
    activeObservation(observation);
  };

  return (
    <VBConfirmDialog
      visible={observation !== null}
      onClose={onClose}
      onConfirmed={handleConfirmed}
      title={t("project:label.activeDialog.title")}
      description={t("project:label.activeDialog.message")}
      cancelText={t("common:buttonCancel")}
      confirmText={t("common:buttonEnable")}
    />
  );
};
