/*
 * File: use-project-template-tabs.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 25th July 2022 3:02:01 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useState } from "react";

export default function useProjectTemplateTabs() {
  const [tabs] = useState([
    { value: "info", label: "Project Info", step: 1 },
    { value: "label", label: "Label Setting", step: 2 },
    { value: "setting", label: "Advanced Setting" },
  ]);

  const [activeTab, setActiveTab] = useState(tabs[0].value);

  function selectTab(tab: string) {
    setActiveTab(tab);
  }

  return { tabs, activeTab, selectTab };
}
