/*
 * File: file-name-filter.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd July 2023 2:59:51 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { QueryApi } from "data-access/impl/query";
import { Filter, getFilterQuery } from "domain/common";
import { VscSearch } from "react-icons/vsc";

interface Props {
  filter: Filter;
  onFilterChange(filter: Filter): void;
}

export function FileNameFilter({ filter, onFilterChange }: Props) {
  return (
    <div className="px-4">
      <VBTextInputComponent
        header="File Name"
        placeholder="File name"
        defaultValue={getFilterQuery(filter, "fileName")?.value ?? ""}
        onInputChange={(value) => {
          const fileFilter = new QueryApi.FileFilter(filter);
          fileFilter.setFileName(value);
          onFilterChange(fileFilter.toFilter());
        }}
        clearInput
        inputIcon={<VscSearch />}
      />
    </div>
  );
}
