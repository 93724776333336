/*
 * File: use-project-statistic.hook.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 12:32:14 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import {
  selectProjectStatistic,
  selectProjectStatisticRequestError,
  selectProjectStatisticRequestStatus,
} from "store/customer/project-data/statistic/project-statistic.selectors";
import { loadProjectStatisticAsync } from "store/customer/project-data/statistic/project-statistic.thunk";
import { removeStatistic } from "store/customer/project-data/project-data.slice";
import * as Sentry from "@sentry/react";

export const useProjectStatistic = (projectId: number) => {
  const dispatch = useAppDispatch();
  const statistic = useAppSelector(selectProjectStatistic(projectId));
  const requestError = useAppSelector(selectProjectStatisticRequestError);
  const requestStatus = useAppSelector(selectProjectStatisticRequestStatus);

  const requestData = async (forceRefresh = false) => {
    if (forceRefresh) dispatch(removeStatistic(projectId));
    return dispatch(loadProjectStatisticAsync(projectId));
  };

  useEffect(() => {
    const _loadProjectAsync = async (id: number) => {
      try {
        dispatch(removeStatistic(id));
        const response = await dispatch(loadProjectStatisticAsync(id));
        handleThunkRejected(response);
      } catch (err: any) {
        Sentry.captureException(err);
        const errMessage = err.message || "Failed to get project overview";
        dispatch(enqueueErrorNotification(errMessage));
        Logger.log(err);
      }
    };
    if (projectId) _loadProjectAsync(projectId);
  }, [dispatch, projectId]);

  return {
    requestData,
    statistic,
    requestError,
    requestStatus,
  };
};
