import { JobStatisticBatchRowResponseDTO } from "services/query/query.dto";
import { BatchPaymentTableRow } from "../model/batch-payment.models";


export const mapperBatchPaymentRow = {
  fromEntity: (resRow: JobStatisticBatchRowResponseDTO): BatchPaymentTableRow => {
    const row: BatchPaymentTableRow = {
      jobId: resRow.id,
      taskId: resRow.taskId,
      fileName: resRow.fileName,
      step: resRow.step,
      round: resRow.round,
      jobStatus: resRow.status,
      labeler: resRow.assignee,
      payment: resRow.payment,
    }
    return row;
  }
}