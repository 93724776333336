/*
 * File: use-annotation-menu.hook.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 2nd July 2022 8:08:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { MutableRefObject, useCallback, useMemo } from "react";
import { selectSelectedTextAnnotation } from "store/labeler/text-workspace/text-editor/text-editor.selector";
import { selectSelectedTextIssue } from "store/labeler/text-workspace/text-issues/text-issues.selector";
import {
  textAnnotationIssueMenuClosed,
  textAnnotationIssueMenuOpened,
} from "store/labeler/text-workspace/text-workspace.slice";
import { useTextWorkspaceContext } from "../context/text-workspace/text-workspace.context";

export const useAnnotationIssueMenu = (
  containerRef: MutableRefObject<HTMLDivElement | null>
) => {
  const dispatch = useAppDispatch();
  const { observations, connections } = useTextWorkspaceContext();
  const selectedAnnotation = useAppSelector(selectSelectedTextAnnotation);
  const issue = useAppSelector(selectSelectedTextIssue);

  const annotation = useMemo(() => {
    const { annotationId, issueMenu } = selectedAnnotation;
    if (!annotationId || !issueMenu) return null;
    let anno = observations.find((ob) => ob.id === annotationId);
    if (anno) return anno;
    let relation = connections.find((c) => c.id === annotationId);
    if (relation) return relation;
    return null;
  }, [selectedAnnotation, observations, connections]);

  const position = useMemo(() => {
    const { annotationId, issueMenu } = selectedAnnotation;
    if (!annotationId || !issueMenu) return null;
    const element = document.getElementById(annotationId);
    if (element && containerRef.current) {
      const bounding = element.getBoundingClientRect();
      const parentBounding = containerRef.current.getBoundingClientRect();
      return {
        x: bounding.x - parentBounding.x - 60,
        y: bounding.y + bounding.height + 32 - parentBounding.y,
      };
    }
    return null;
  }, [selectedAnnotation, containerRef]);

  const hideAnnotationIssueMenu = useCallback(() => {
    dispatch(textAnnotationIssueMenuClosed());
  }, [dispatch]);

  const showAnnotationIssueMenu = useCallback(
    (id: string) => {
      dispatch(textAnnotationIssueMenuOpened(id));
    },
    [dispatch]
  );

  const state = position && annotation ? { position, annotation, issue } : null;
  return {
    annotationIssueMenuState: state,
    hideAnnotationIssueMenu,
    showAnnotationIssueMenu,
  };
};

