import { Tooltip } from "@material-ui/core";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { ReviewPickType } from "services/label-service/dtos/work-management.dto";
import { StepReviewConfig } from "../../project.type";

const pickTypeOptions = [
  {
    value: ReviewPickType.ALL,
    label: "Percentage from all",
  },
  {
    value: ReviewPickType.LABELER,
    label: "Percentage from each labeler",
  },
];

export interface ProjectWorkflowReviewConfigProps {
  config: StepReviewConfig;
  onChange?: (config: StepReviewConfig) => void;
  disabled?: boolean;
}

export const ProjectWorkflowReviewConfig = ({
  config,
  onChange,
  disabled = false,
}: ProjectWorkflowReviewConfigProps) => {
  const handleFieldChanged = (key: string, value: string | number) => {
    if (typeof value === "number") {
      if (value < 0) value = 0;
      if (value > 100) value = 100;
    }
    onChange && onChange({ ...config, [key]: value });
  };

  return (
    <div className="flex flex-col gap-4">
      <p className="text-sm break-all">Random pick job from previous step</p>
      <div className="flex items-center gap-2 max-w-60">
        <Tooltip
          title={
            pickTypeOptions.find((o) => o.value === config.reviewPickType)
              ?.label || ""
          }
          placement="top"
        >
          <div className="w-40">
            <VBSelectComponent
              isDisabled={disabled}
              value={pickTypeOptions.find(
                (o) => o.value === config.reviewPickType
              )}
              options={pickTypeOptions}
              onChange={(option: any) =>
                handleFieldChanged("reviewPickType", option.value as string)
              }
              menuPortalTarget={document.body}
            />
          </div>
        </Tooltip>
        <input
          className="w-20 px-3 border rounded h-9"
          type="number"
          disabled={disabled}
          min={0}
          max={100}
          value={config.percentage}
          onChange={(e) =>
            handleFieldChanged("percentage", e.target.valueAsNumber)
          }
        />
        <span className="self-center">%</span>
      </div>
      <div className="flex items-center gap-2 max-w-60">
        <p className="w-40 text-sm">Accept percentage</p>
        <input
          className="w-20 px-3 border rounded h-9"
          type="number"
          disabled={disabled}
          min={0}
          max={100}
          value={config.acceptPercentage}
          onChange={(e) =>
            handleFieldChanged("acceptPercentage", e.target.valueAsNumber)
          }
        />
        <span className="self-center">%</span>
      </div>
      <div></div>
    </div>
  );
};
