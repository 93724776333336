/*
 * File: label-tooltip.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 15th October 2021 11:35:05 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useClickAway, useMount, useSize, useUnmount } from "ahooks";
import classNames from "classnames";
import { UserAvatar } from "components/common/vb-user-avatar.component";
import { Label, LabelType } from "domain/text-labeling";
import { useAppSelector } from "hooks/use-redux";
import { useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { selectTextLabelingJob } from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { selectTextReviewAnnotationInfo } from "store/labeler/text-workspace/text-review/text-review.selectors";

interface Props {
  labels: Label[];
  annotationId: string;
  onClose(): void;
}

export const LabelToolTip = ({ labels, annotationId, onClose }: Props) => {
  const { t } = useTranslation();
  const job = useAppSelector(selectTextLabelingJob);
  const containerRef = useRef<HTMLDivElement>(null);
  const size = useSize(containerRef);
  const isMounted = useRef(false);
  const position = useMemo(() => {
    const element = document.getElementById(annotationId);
    const container = document.getElementById("tooltip-root");

    if (!element || !container || !size.width || !size.height)
      return { x: -9999, y: -9999 };

    const width = size.width || 0;
    const height = size.height || 0;
    const bbox = element.getBoundingClientRect();

    const mouseX = element?.getAttribute("mouse-x");
    const mouseY = element?.getAttribute("mouse-y");
    if (mouseX !== null && mouseY !== null) {
      let x = parseInt(mouseX) - width / 2;
      let y = parseInt(mouseY) - height - 26;
      x = Math.max(x, 16);
      if (y < 16) {
        y = parseInt(mouseY) + 16;
      }
      return { x, y };
    }

    const x = bbox.left + bbox.width / 2 - width / 2;
    const y = bbox.top - bbox.height / 2 - height - 16;
    return { x, y };
  }, [annotationId, size]);

  const annotationInfo = useAppSelector(
    selectTextReviewAnnotationInfo(annotationId)
  );

  const label = useMemo(() => {
    if (!annotationInfo?.annotation?.observationId) return null;
    return labels.find(
      (label) => label.id === annotationInfo?.annotation?.observationId
    );
  }, [labels, annotationInfo]);

  useClickAway(() => {
    if (isMounted.current) {
      onClose();
    }
  }, [containerRef]);

  const isShowConflict =
    annotationInfo?.selectedAnnotators &&
    annotationInfo?.selectedAnnotators?.length > 1;

  const isConflict =
    annotationInfo?.selectedAnnotators &&
    annotationInfo?.selectedAnnotators?.length > 1 &&
    annotationInfo?.annotators.length !==
      annotationInfo?.selectedAnnotators.length;

  useMount(() => {
    isMounted.current = true;
  });
  useUnmount(() => {
    isMounted.current = false;
    const container = document.getElementById("tooltip-root");
    if (container) container.innerHTML = "";
  });

  if (annotationInfo) {
    const content = (
      <div
        style={{
          top: position.y,
          left: position.x,
          opacity: position.x === -9999 ? 0 : 1,
        }}
        className={classNames(
          "fixed z-10 max-w-md py-2 text-sm text-white rounded shadow-lg bg-blueGray-900 pointer-events-none"
        )}
      >
        <div ref={containerRef}>
          {isShowConflict && (
            <div className="flex items-center gap-2 px-4 py-1">
              <span className="font-bold">
                {t("textLabeling:tooltip.status")}
              </span>
              <span
                className={classNames(
                  "px-3 py-1 text-xs text-white rounded-full",
                  {
                    "bg-red-500": isConflict,
                    "bg-green-500": !isConflict,
                  }
                )}
              >
                {!isConflict
                  ? t("textLabeling:tooltip.agree")
                  : t("textLabeling:tooltip.conflict")}
              </span>
            </div>
          )}
          {label && (
            <div className="px-4 py-1">
              <strong> {t("textLabeling:tooltip.label")}</strong>
              <span>{label.name}</span>
            </div>
          )}

          {label && label.type === LabelType.CONNECTION && (
            <div className="px-4 py-1">
              <strong> {t("textLabeling:tooltip.content")}</strong>
              <span>{annotationInfo.annotation?.content}</span>
            </div>
          )}

          <div className="px-4 py-1 space-y-2">
            <strong> {t("textLabeling:tooltip.labeledBy")}</strong>
            <div className="space-y-2">
              {annotationInfo.annotators.map((annotator: string) => {
                return (
                  <div className="flex items-center gap-2" key={annotator}>
                    <UserAvatar
                      className="flex-none w-6 h-6 text-xs"
                      name={annotator}
                    />
                    <span>{annotator}</span>
                  </div>
                );
              })}
            </div>
          </div>

          {annotationInfo?.annotation?.reviewStatus && (
            <div className="px-4">
              <strong>Review status: </strong>
              <span>{annotationInfo.annotation.reviewStatus}</span>
            </div>
          )}
        </div>
      </div>
    );
    const parent = document.getElementById("tooltip-root");
    if (!parent) return null;
    return createPortal(content, parent);
  }

  const element = (
    <div
      style={{
        top: position.y,
        left: position.x,
        opacity: position.x === -9999 ? 0 : 1,
      }}
      className="fixed z-10 max-w-md py-2 text-sm text-white rounded shadow-lg pointer-events-none bg-blueGray-900"
    >
      <div ref={containerRef} className="px-4 py-1">
        <strong>Labeled by: </strong>
        {job?.assignee}
      </div>
    </div>
  );
  const parent = document.getElementById("tooltip-root");
  if (!parent) return null;
  return createPortal(element, parent);
};
