/*
 * File: workspace.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:12 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */
import { useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect, useState } from "react";
import { StorageService } from "services/storage";
import { selectCurrentWorkspace } from "store/common/user-workspace/user-workspace.selectors";
import { WorkspaceContext } from "./workspace.context";
interface Props {
  children: JSX.Element;
}
export const WorkspaceProvider = ({ children }: Props) => {
  const [sasToken, setSasToken] = useState("");

  const getUrlWithSasToken = useCallback(
    (url: string) => {
      if (sasToken) {
        let newUrl = url.split("?")[0];
        return `${newUrl}?${sasToken}`;
      }
      return url;
    },
    [sasToken]
  );

  const currentWorkspace = useAppSelector(selectCurrentWorkspace);

  useEffect(() => {
    if (!currentWorkspace) {
      setSasToken("");
      return;
    }
    const renewWorkspaceToken = async (workspaceId: string) => {
      const token = await StorageService.getWorkspaceSasToken(workspaceId);
      setSasToken(token || "");
    };
    if (currentWorkspace) renewWorkspaceToken(currentWorkspace.groupId);
  }, [currentWorkspace]);

  const state = {
    workspaceId: currentWorkspace?.groupId || "",
    sasToken,
    getUrlWithSasToken,
  };

  return (
    <WorkspaceContext.Provider value={state}>
      {children}
    </WorkspaceContext.Provider>
  );
};
