/*
 * File: workflow-instruction.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 20th July 2021 10:34:05 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "store";

export const selectWorkflowInstructions = (state: RootState) => {
  return state.project.workflowInstructions;
};
