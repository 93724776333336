import { AnalyticsQueryResponse } from "services/analytics-service";
import { RowDataTableLabel } from "../components/label-table.component";

const COL_NAME = "name";
const COL_CODE = "code";
const COL_TOTAL = "total";
const COL_IOU = "iou";

export const rowTableLabelMapper = (response: AnalyticsQueryResponse): RowDataTableLabel[] => {
  const rows = response.data.rows;
  if (rows && rows.length > 0) {
    const totalAnnotations: number = rows.reduce((sum: number, item: any) => sum + item[COL_TOTAL], 0);
    return rows.map((row: any) => {
      const tableRow: RowDataTableLabel = {
        code: row[COL_CODE] || 0,
        name: row[COL_NAME] || 0,
        annotations: row[COL_TOTAL] || 0,
        averageIou: row[COL_IOU] || 0,
        distribution: totalAnnotations ? parseFloat(((row[COL_TOTAL] || 0) / totalAnnotations * 100).toFixed(2)) : 0,
      };
      return tableRow;
    });
  }
  return [];
}