/*
 * File: storage-grid-col-def.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 1st December 2021 5:31:04 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { GridColDef } from "@material-ui/data-grid";
import { DateColumnHeader } from "components/common/vb-grid/date-column-header.component";
import {
  SearchableColumnHeader,
  SortType,
} from "components/common/vb-grid/searchable-column-header.component";
import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { VBDatagridCellHoverDisplay } from "../../../../../../../components/common/vb-datagrid/vb-datagrid-cell-hover-display";
import { TagsHeader } from "./headers/tags-header";

const FileNameColumnHeader = () => {
  const { t } = useTranslation();
  const { filter, setFileName, setSort } = useDatasetContext();
  const sortType = useMemo(() => {
    if (filter?.sort?.startsWith("fileName")) {
      const items = filter.sort.split(",");
      if (items.length === 2) {
        const val = items[1].trim();
        if (val === "asc" || val === "desc") return val;
      }
    }
    return "";
  }, [filter]);
  return (
    <SearchableColumnHeader
      containerClassName="w-full py-2"
      sortable
      clearInput
      placeholder={t("dataset:header.textFileName")}
      header={t("dataset:header.textFileName")}
      containerWidth={"100%"}
      defaultValue={filter?.fileName}
      onInputChange={setFileName}
      sortType={sortType}
      onSortChange={(sort: SortType) => setSort(sort ? "fileName" : "", sort)}
    />
  );
};

const CreatedByColumnHeader = () => {
  const { t } = useTranslation();
  const { filter, setCreatedBy, setSort } = useDatasetContext();
  const sortType = useMemo(() => {
    if (filter?.sort?.startsWith("createdBy")) {
      const items = filter.sort.split(",");
      if (items.length === 2) {
        const val = items[1].trim();
        if (val === "asc" || val === "desc") return val;
      }
    }
    return "";
  }, [filter]);
  return (
    <SearchableColumnHeader
      containerClassName="w-full py-2"
      sortable
      clearInput
      placeholder={t("dataset:header.textCreatedBy")}
      header={t("dataset:header.textCreatedBy")}
      containerWidth={"100%"}
      defaultValue={filter?.createdBy}
      onInputChange={setCreatedBy}
      sortType={sortType}
      onSortChange={(sort: SortType) => setSort(sort ? "createdBy" : "", sort)}
    />
  );
};

const StoreTypeColumnHeader = () => {
  const { t } = useTranslation();
  const { filter, setSort, setStoreType } = useDatasetContext();
  const sortType = useMemo(() => {
    if (filter?.sort?.startsWith("storeType")) {
      const items = filter.sort.split(",");
      if (items.length === 2) {
        const val = items[1].trim();
        if (val === "asc" || val === "desc") return val;
      }
    }
    return "";
  }, [filter]);
  return (
    <SearchableColumnHeader
      containerClassName="w-full py-2"
      sortable
      clearInput
      placeholder={t("dataset:header.textStoreType")}
      header={t("dataset:header.textStoreType")}
      containerWidth="100%"
      defaultValue={filter?.storeType}
      onInputChange={setStoreType}
      sortType={sortType}
      onSortChange={(sort: SortType) => setSort(sort ? "storeType" : "", sort)}
    />
  );
};

const TagsColumnHeader = () => {
  return <TagsHeader />;
};

export const CreatedDateColumnHeader = () => {
  const { t } = useTranslation();
  const { filter, setCreatedDate } = useDatasetContext();

  return (
    <DateColumnHeader
      containerClassName="w-full py-2"
      header={t("dataset:header.textCreatedDate")}
      containerWidth="100%"
      defaultValue={filter?.createdDate ? new Date(filter?.createdDate) : null}
      onChange={(date) => setCreatedDate(date ? date.toString() : "")}
      clearable
    />
  );
};

export const storageGridcolumns: GridColDef[] = [
  {
    field: "fileName",
    filterable: false,
    flex: 2,
    renderHeader: FileNameColumnHeader,
    renderCell: VBDatagridCellHoverDisplay,
    minWidth: 200,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "createdDate",
    filterable: false,
    flex: 2,
    renderHeader: CreatedDateColumnHeader,
    renderCell: VBDatagridCellHoverDisplay,
    minWidth: 200,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "createdBy",
    filterable: false,
    flex: 2,
    renderHeader: CreatedByColumnHeader,
    renderCell: VBDatagridCellHoverDisplay,
    minWidth: 200,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "tags",
    filterable: false,
    sortable: false,
    flex: 2,
    renderHeader: TagsColumnHeader,
    renderCell: VBDatagridCellHoverDisplay,
    minWidth: 200,
    disableColumnMenu: true,
  },
  {
    field: "storeType",
    filterable: false,
    sortable: false,
    flex: 2,
    renderHeader: StoreTypeColumnHeader,
    renderCell: VBDatagridCellHoverDisplay,
    minWidth: 200,
    disableColumnMenu: true,
  },
];
