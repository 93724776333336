/*
 * File: types.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 3:30:38 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Filter, FilterQuery, FilterSort } from "domain/common";

export class BaseFilter implements Filter {
  page: number;
  size: number;
  search?: string | undefined;
  sort?: FilterSort | undefined;
  queries?: FilterQuery[] | undefined;

  constructor(filter?: Filter) {
    this.page = filter?.page ?? 0;
    this.size = filter?.size ?? 20;
    this.search = filter?.search;
    this.queries = filter?.queries ?? [];
    this.sort = filter?.sort;
  }

  toFilter() {
    return {
      page: this.page,
      size: this.size,
      search: this.search,
      sort: this.sort,
      queries: this.queries,
    };
  }

  getQuery(key: string) {
    return this.queries?.find((q) => q.field === key);
  }
  setQuery(key: string, value?: string, operator: string = "$eq") {
    const queries = (this.queries ?? []).filter((q) => q.field !== key);
    if (value) queries.push({ field: key, value, operator });
    this.queries = queries;
    return this;
  }

  getArrayQuery(key: string): FilterQuery[] {
    return this.queries?.filter((query) => query.field === key) ?? [];
  }

  setArrayQuery(key: string, values?: string[], operator: string = "$eq") {
    const queries = (this.queries ?? []).filter((q) => q.field !== key);
    if (values) {
      for (const value of values) {
        queries.push({ field: key, value, operator });
      }
    }
    this.queries = queries;
    return this;
  }

  getStringField(key: string): string | undefined {
    try {
      const value = this.getQuery(key)?.value;
      return value ? value : undefined;
    } catch (error) {}
    return undefined;
  }

  getArrayStringField(key: string): string[] | undefined {
    try {
      const value = this.getQuery(key)?.value?.split(",");
      return value ? value : undefined;
    } catch (error) {}
    return undefined;
  }

  getNumberField(key: string): number | undefined {
    try {
      const value = this.getQuery(key)?.value;
      return value ? parseInt(value.trim()) : undefined;
    } catch (error) {}
    return undefined;
  }

  getArrayNumberField(key: string): number[] | undefined {
    try {
      const value = this.getQuery(key)
        ?.value?.split(",")
        .map((item) => item.trim())
        .map((item) => parseInt(item))
        .filter((item) => !Number.isNaN(item));
      return value ? value : undefined;
    } catch (error) {}
    return undefined;
  }

  getBooleanField(key: string): boolean | undefined {
    try {
      const value = this.getQuery(key)?.value;
      return value?.toLowerCase() === "true" || value === "1";
    } catch (error) {}
    return undefined;
  }

  getArrayBooleanField(key: string): boolean[] | undefined {
    try {
      const value = this.getQuery(key)?.value;
      return value
        ?.split(",")
        .map((item) => item.trim().toLowerCase())
        .map((item) => item === "true" || value === "1");
    } catch (error) {}
    return undefined;
  }

  getPage() {
    return this.page;
  }

  getPageSize() {
    return this.size;
  }

  setPage(page: number) {
    this.page = page;
    return this;
  }

  setPageSize(size: number) {
    this.size = size;
    return this;
  }

  toPageable() {
    return { page: this.getPage(), size: this.getPageSize() };
  }
}
