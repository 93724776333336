import { Tooltip } from "@material-ui/core";
import { IconAddCircle } from "components/common/vb-icon.component";
import { useCallback, useEffect, useRef } from "react";
import { useMemo } from "react";
import { shouldIgnoreEventKeyup } from "../vtk/utils";
import EditorLabelItem, {
  EditorLabelAction,
} from "./three-d-editor-label-item.component";
import {
  EditorEventGoToSlice,
  EditorLabel,
  EditorLabelOption,
  EditorLabelStatus,
  MAX_NUM_MASK,
  ThreeDEditorEvents,
} from "./three-d-editor.models";
import { useThreeDEditorContext } from "./three-d-editor.provider";
import { findFirstSlicesForMask } from "./three-d-editor.utils";

export const ThreeDEditorLabelsComponent = () => {
  const {
    editorContext,
    labelOptions,
    activeLabel,
    labels,
    viewLabels,
    isShowViewLabels,
    setActiveLabel,
    changeLabelMapMask,
    setCurrentLabels,
    fbtsIsInitialized,
  } = useThreeDEditorContext();

  const labelsToDisplay = useMemo(() => {
    if (isShowViewLabels) {
      return viewLabels;
    }
    return labels;
  }, [isShowViewLabels, viewLabels, labels]);
  const labelsToDisplayRef = useRef<EditorLabel[]>([]);

  const labelSelectOptions = useMemo(() => {
    return labelOptions.map((lo) => ({ label: lo.name, value: lo }));
  }, [labelOptions]);

  function handleSelectLabel(
    label: EditorLabel,
    action: EditorLabelAction,
    value?: string | number | EditorLabelOption
  ) {
    switch (action) {
      case EditorLabelAction.GO_TO_SLICES:
        goToSlices(label);
        break;
      case EditorLabelAction.SELECT:
        selectLabel(label);
        break;
      case EditorLabelAction.EDIT:
        editLabel(label);
        break;
      case EditorLabelAction.CLOSE_EDIT:
        closeEditLabel(label);
        break;
      case EditorLabelAction.DELETE:
        deleteLabel(label);
        break;
      case EditorLabelAction.TOGGLE_VISIBILITY:
        toggleLabelVisibility(label);
        break;
      case EditorLabelAction.SELECT_OPTION:
        selectLabelOption(label, value as EditorLabelOption);
        break;
      case EditorLabelAction.UPDATE_OPACITY:
        updateLabelOpacity(label, value as number);
        break;
      case EditorLabelAction.UPDATE_COLOR:
        updateLabelColor(label, value as string);
        break;
      case EditorLabelAction.UPDATE:
        updateLabel(label, value as Partial<EditorLabel>);
        break;

      default:
        console.log("unsupported action", action);
    }
  }

  const goToSlices = (label: EditorLabel) => {
    if (!editorContext) return;
    const { painter } = editorContext;
    const slices = findFirstSlicesForMask(
      painter.getLabelMap(),
      label.maskValue
    );

    for (let axis = 0; axis <= 2; axis++) {
      if (slices[axis] > -1) {
        const eventData: EditorEventGoToSlice = {
          axis,
          slice: Math.floor(slices[axis]),
        };
        document.dispatchEvent(
          new CustomEvent(ThreeDEditorEvents.COMMAND_GO_TO_SLICE, {
            detail: eventData,
          })
        );
      }
    }
  };

  const selectLabel = (label: EditorLabel) => {
    if (fbtsIsInitialized || label.isNone) return;
    setActiveLabel(label);
  };

  const updateLabelOpacity = (label: EditorLabel, opacity: number) => {
    const key = "3D_LABEL_OPACITY_" + label.labelOptionId;
    localStorage.setItem(key, opacity.toString());

    setCurrentLabels(
      labelsToDisplay.map((labelItem) => {
        if (labelItem.id === label.id) {
          const newLabel = { ...labelItem, opacity };
          if (label.id === activeLabel.id) {
            setActiveLabel(newLabel);
          }
          return newLabel;
        }
        return labelItem;
      })
    );
  };

  const updateLabelColor = (label: EditorLabel, color: string) => {
    const key = "3D_LABEL_COLOR_" + label.labelOptionId;
    localStorage.setItem(key, color);

    setCurrentLabels(
      labelsToDisplay.map((labelItem) => {
        if (labelItem.id === label.id) {
          const newLabel = { ...labelItem, color };
          if (label.id === activeLabel.id) {
            setActiveLabel(newLabel);
          }
          return newLabel;
        }
        return labelItem;
      })
    );
  };

  const addNewLabel = () => {
    if (fbtsIsInitialized) return;

    if (
      !labelOptions ||
      labelOptions.length < 1 ||
      labels.length < 1 ||
      labels.length > MAX_NUM_MASK
    )
      return;
    const lastLabel = labelsToDisplay[labelsToDisplay.length - 1];
    const labelOption = labelOptions[0];
    const newLabel: EditorLabel = {
      id: lastLabel.id + 1,
      labelOptionId: labelOption.id,
      maskValue: lastLabel.maskValue + 1,
      name: labelOption.name,
      color: labelOption.color,
      opacity: 80,
      keyBind: (parseInt(lastLabel.keyBind) + 1).toString(),
      status: EditorLabelStatus.FIXED,
      visibility: true,
    };
    const newLabels = [...labelsToDisplay, newLabel];
    setCurrentLabels(newLabels);
    setActiveLabel(newLabel);
  };

  const updateLabelField = useCallback(
    (labelId: number, field: keyof EditorLabel, value: any) => {
      const newLabels = labelsToDisplay.map((label) => {
        if (label.id === labelId) {
          return { ...label, [field]: value };
        }
        return label;
      });
      setCurrentLabels(newLabels);
      const newLabel = newLabels.find((lb) => lb.id === activeLabel.id);
      if (newLabel) setActiveLabel(newLabel);
    },
    [setCurrentLabels, labelsToDisplay, setActiveLabel, activeLabel]
  );

  const selectLabelOption = (
    label: EditorLabel,
    newOption: EditorLabelOption
  ) => {
    if (fbtsIsInitialized) return;

    const newLables = labelsToDisplay.map((l) => {
      if (l.id === label.id) {
        const newLabel = {
          ...l,
          labelOptionId: newOption.id,
          name: newOption.name,
          color: newOption.color,
          status: EditorLabelStatus.FIXED,
        };

        if (label.id === activeLabel.id) {
          setActiveLabel(newLabel);
        }

        return newLabel;
      } else {
        return l;
      }
    });
    setCurrentLabels(newLables);
  };

  const updateLabel = (label: EditorLabel, value: Partial<EditorLabel>) => {
    if (fbtsIsInitialized) return;

    const newLabel = { ...label, ...value, status: EditorLabelStatus.FIXED };
    const labelOption = labelOptions.find(
      (lb) => lb.id === value.labelOptionId
    );
    if (labelOption) {
      newLabel.name = labelOption.name;
      newLabel.attributes = (newLabel.attributes || []).filter(
        (attr) => !!labelOption.attributes?.find(({ id }) => id === attr.id)
      );
    }
    const newLabels = labelsToDisplay.map((labelItem) => {
      if (labelItem.id === newLabel.id) {
        return newLabel;
      }
      return labelItem;
    });

    if (activeLabel.id === newLabel.id) {
      setActiveLabel(newLabel);
    }

    setCurrentLabels([...newLabels]);

    const colorKey = "3D_LABEL_COLOR_" + label.labelOptionId;
    localStorage.setItem(colorKey, newLabel.color);
    const opacitykey = "3D_LABEL_OPACITY_" + label.labelOptionId;
    localStorage.setItem(opacitykey, newLabel.opacity.toString());
  };
  const editLabel = (label: EditorLabel) => {
    if (fbtsIsInitialized) return;
    updateLabelField(label.id, "status", EditorLabelStatus.EDIT);
  };

  const closeEditLabel = (label: EditorLabel) => {
    if (fbtsIsInitialized) return;

    updateLabelField(label.id, "status", EditorLabelStatus.FIXED);
  };

  const deleteLabel = (label: EditorLabel) => {
    if (fbtsIsInitialized) return;

    if (!editorContext) return;
    const { workingLabelMap } = editorContext;
    changeLabelMapMask(workingLabelMap, label.maskValue, 0);
    const newLabels = labelsToDisplay.filter((l) => l.id !== label.id);
    setCurrentLabels(newLabels);

    if (newLabels.length > 0) {
      setActiveLabel(newLabels[newLabels.length - 1]);
    }
  };

  const toggleAllLabelsVisibility = useCallback(() => {
    const hasVisible = labelsToDisplay.some((lb) => lb.visibility);
    const newLabels = labelsToDisplay.map((label) => {
      const visible = hasVisible ? false : true;
      return { ...label, visibility: visible };
    });
    setCurrentLabels(newLabels);
    const newLabel = newLabels.find((lb) => lb.id === activeLabel.id);
    if (newLabel) setActiveLabel(newLabel);
  }, [setCurrentLabels, labelsToDisplay, setActiveLabel, activeLabel]);

  const toggleLabelVisibility = useCallback(
    (label: EditorLabel) => {
      updateLabelField(label.id, "visibility", !label.visibility);
    },
    [updateLabelField]
  );

  useEffect(() => {
    labelsToDisplayRef.current = labelsToDisplay;
  }, [labelsToDisplay]);

  // Key bind for labels
  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (shouldIgnoreEventKeyup(e)) return;
      if (e.ctrlKey || e.shiftKey) return;
      if (e.altKey && (e.key === "h" || e.key === "˙")) {
        toggleAllLabelsVisibility();
        return;
      }
      if (e.altKey) {
        const windowKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        const macKeys = ["º", "¡", "™", "£", "¢", "∞", "§", "¶", "•", "ª"];
        const windowIdx = windowKeys.indexOf(e.key);
        const macIdx = macKeys.indexOf(e.key);
        const labelIndex = windowIdx >= 0 ? windowIdx : macIdx;
        if (labelIndex >= 0 && labelIndex < labelsToDisplayRef.current.length) {
          const label = labelsToDisplayRef.current[labelIndex];
          toggleLabelVisibility(label);
        }
        return;
      }

      const labelIndex = parseInt(e.key);
      if (labelIndex >= 0 && labelIndex < labelsToDisplayRef.current.length) {
        setActiveLabel(labelsToDisplayRef.current[labelIndex]);
      }
    };
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [setActiveLabel, toggleLabelVisibility, toggleAllLabelsVisibility]);

  return (
    <div
      className="flex flex-col"
      style={{
        maxHeight: "500px",
      }}
    >
      <div className="relative flex items-center gap-4 mb-1">
        <h2 className="font-bold">Segments</h2>
        <Tooltip title="Add segment" placement="top">
          <button onClick={addNewLabel}>
            <IconAddCircle className="flex-none w-4 h-4 text-warning-300" />
          </button>
        </Tooltip>
      </div>
      <div className="flex-auto overflow-y-auto">
        <div>
          {labelsToDisplay
            .filter((lb) => !(lb.isPreview || lb.isNone))
            .map((label, index) => {
              return (
                <EditorLabelItem
                  key={label.id}
                  label={label}
                  index={index}
                  isActive={label.id === activeLabel.id}
                  onSelect={handleSelectLabel}
                  labelOptions={labelSelectOptions}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
