/*
 * File: scope.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 31st August 2021 5:14:41 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { StorageService } from "services/storage-service";
import {
  requestPending,
  requestRejected,
  requestFulfilled,
  setEntites,
} from "store/base/base.reducer";
import { ScopeState } from "./scope.state";

export enum ScopeThunk {
  LOAD_SCOPES = "scope/loadScopesAsync",
}

export const loadScopesAsync = createAsyncThunk(
  ScopeThunk.LOAD_SCOPES,
  async () => {
    const scopes = await StorageService.getScopeService().getScopes();
    return scopes;
  }
);

export const scopeReducerBuilder = (
  builder: ActionReducerMapBuilder<ScopeState>
): ActionReducerMapBuilder<ScopeState> => {
  return builder
    .addCase(loadScopesAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(loadScopesAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(loadScopesAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      if (action.payload) {
        setEntites(state, action.payload);
      }
    });
};
