/*
 * File: editor-setting.state.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 30th March 2022 5:19:49 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export interface TextEditorSettingState {
  showKeyboardShortcuts: boolean;
  showEditorSettings: boolean;
  jobPerTask: number;
}

export const defaultTextEditorSettingState: TextEditorSettingState = {
  showKeyboardShortcuts: false,
  showEditorSettings: false,
  jobPerTask: 10,
};
