/*
 * File: context-menu-item.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 1st July 2022 4:17:06 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export enum AnnotationContextMenuAction {
  CHANGE_ATTRIBUTES = "CHANGE_ATTRIBUTES",
  CHANGE_CLASS = "CHANGE_CLASS",
  DELETE = "DELETE",
  ISSUE = "ISSUE",
  EDIT = "EDIT",
  DEFAULT_PEN = "DEFAULT_PEN",
  SUBTRACT_POLYGON = "SUBTRACT_POLYGON",
  ADD_POLYGON = "ADD_POLYGON",
  VOTE = "VOTE",
  DOWN_VOTE = "DOWN_VOTE",
}

export enum ImageContextMenuAction {
  CLEAR_ANNOTATIONS = "CLEAR_ANNOTATIONS",
  MARK_AS_INVALID = "MARK_AS_INVALID",
  MARK_AS_NOFINDING = "MARK_AS_NOFINDING",
  SAVE = "SAVE",
  PICK_ANNOTATIONS = "PICK_ANNOTATIONS",
}

export interface ContextMenuItemData {
  label: string;
  value: AnnotationContextMenuAction | ImageContextMenuAction;
  shortcut: string;
  disabled?: boolean;
  variant?: "danger" | "warning" | "default";
}

interface Props {
  data: ContextMenuItemData;
  onSelect?(item: ContextMenuItemData): void;
}
export const ContextMenuItem = (props: Props) => {
  const { data, onSelect } = props;
  const { label, shortcut, disabled, variant } = data;
  if (disabled) {
    return (
      <div
        key={label}
        className="flex items-center justify-between w-full gap-4 px-4 py-2 opacity-50 cursor-default flex-nowrap"
      >
        <div
          className={
            "danger" === variant
              ? "text-red-500 whitespace-nowrap"
              : "whitespace-nowrap"
          }
        >
          {label}
        </div>
        {shortcut && (
          <span className="px-2 py-1 rounded bg-primary-100 text-background-700">
            {shortcut}
          </span>
        )}
      </div>
    );
  }
  return (
    <button
      disabled={disabled}
      key={label}
      className="flex items-center justify-between w-full gap-4 px-4 py-2 flex-nowrap hover:bg-primary-50 disabled:opacity-50"
      onClick={() => onSelect && onSelect(data)}
    >
      <div
        className={
          "danger" === variant
            ? "text-red-500 whitespace-nowrap"
            : "whitespace-nowrap"
        }
      >
        {label}
      </div>
      {shortcut && (
        <span className="px-2 py-1 rounded bg-primary-100 text-background-700">
          {shortcut}
        </span>
      )}
    </button>
  );
};
