/*
 * File: experiments.page.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 16th April 2022 9:54:16 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useWorkspaceContext } from "contexts/workspace";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { mlServiceApis } from "services/ml-service/ml.service";
import { CreateExperimentModal } from "./create-experiment.modal";
import { experimentsMappers } from "./experiments.mappers";
import { MlModelsExperimentUIModel } from "./experiments.models";
import { ExperimentsTable } from "./experiments.table";
import * as Sentry from "@sentry/react";

export function ExperimentsPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { workspaceId } = useWorkspaceContext();
  const [isLoading, setIsLoading] = useState(false);
  const [experiments, setExperiments] = useState<MlModelsExperimentUIModel[]>(
    []
  );
  const [createModalVisiblity, setCreateModalVisibility] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        const res = await mlServiceApis.getExperiments(workspaceId);
        const exps: MlModelsExperimentUIModel[] = res.map(
          experimentsMappers.fromEntity
        );
        setExperiments(
          exps.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
        );
      } catch (error: any) {
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [workspaceId]);

  const handleSubmitCreateExperiment = async (v: MlModelsExperimentUIModel) => {
    setExperiments([v, ...experiments]);
    setCreateModalVisibility(false);
    const url = `/models/experiments/${v.id}`;
    history.push(url);
  };

  return (
    <div className="flex flex-col h-full gap-4 animate-fade-in-up">
      <div className="flex items-center justify-between flex-none">
        <p className="text-lg font-bold">{t("mlmodel:mlBuilds.title")}</p>
        <button
          className="button-primary"
          onClick={(_) => setCreateModalVisibility(true)}
        >
          {t("mlmodel:mlBuilds.buttonCreateModel")}
        </button>
      </div>

      <div className="flex-auto">
        {experiments && (
          <ExperimentsTable isLoading={isLoading} rows={experiments} />
        )}
      </div>

      {createModalVisiblity && (
        <CreateExperimentModal
          open={createModalVisiblity}
          onClose={() => setCreateModalVisibility(false)}
          onSubmit={handleSubmitCreateExperiment}
        />
      )}
    </div>
  );
}
