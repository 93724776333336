import { FilterCommon } from "domain/common/filter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IndividualService } from "services/label-service";
import { LabelerStatisticByBatch } from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import { mapperLabelerReportByProjectRow } from "../mappers/labeler-report-by-job.mapper";
import { LabelerReportByProjectRow } from "../models/labeler-report.models";
import * as Sentry from "@sentry/react";


export const useLabelerReportByProject = (fromDate: Date, toDate: Date) => {
  const [totalRow, setTotalRow] = useState(0);
  const [rows, setRows] = useState<LabelerReportByProjectRow[]>([]);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const isLoading = useMemo(() => requestStatus === RequestStatus.LOADING, [requestStatus]);
  const [filter, setFilter] = useState<FilterCommon>({
    page: 1,
    pageSize: 10,
  });
  const displayRows = useMemo(() => {
    // apply client filter to rows
    return FilterCommon.applyFilterToRows(rows, filter);
  }, [rows, filter]);

  const setFilterField = (field: keyof FilterCommon, value: any) => {
    let newFilter = {...filter, [field]: value};
    if (field !== "page" && field !== "pageSize") {
      newFilter = {...newFilter, page: 1, pageSize: 10};
    }
    setFilter(newFilter);
  }

  const requestData = useCallback(async (
    fromDate: Date, toDate: Date
  ) => {
    try {
      setRequestStatus(RequestStatus.LOADING);
      const response = await IndividualService.getBatchReport(
        fromDate,
        toDate
      );
      const rows = (response.data as LabelerStatisticByBatch[])
                    .map(mapperLabelerReportByProjectRow.fromEntity);
      setTotalRow(rows.length);
      setRows(rows);
      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
      setRequestStatus(RequestStatus.FAILURE);
    }
  }, []);

  useEffect(() => {
    requestData(fromDate, toDate);
  }, [requestData, fromDate, toDate]);

  return {
    totalRow,
    rows,
    displayRows,
    isLoading,
    filter,
    setFilterField,
  }
}