/*
 * File: use-step-conditions.hook.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 26th August 2021 9:35:22 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  selectAllStepConditions,
  selectStepConditionOptions,
  selectStepConditions,
} from "store/customer/workflow/workflow.selectors";
import { loadStepConditionsAsync } from "store/customer/workflow/workflow.thunk";

export const useStepConditions = (loadData?: boolean) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepConditions = useAppSelector(selectStepConditions);
  const allStepConditions = useAppSelector(selectAllStepConditions);
  const stepConditionOptions = useAppSelector(selectStepConditionOptions);

  function bindConditions(condition: string) {
    if (!condition || condition === "none") {
      return t("workflow:textNoTransitionCondition");
    }
    return (
      allStepConditions.find(
        (stepCondition) => condition === stepCondition.id.toString()
      )?.name ?? ""
    );
  }

  const requestData = useCallback(async () => {
    return dispatch(loadStepConditionsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (loadData) requestData();
  }, [dispatch, requestData, loadData]);

  return {
    allStepConditions,
    stepConditions,
    stepConditionOptions,
    bindConditions,
    requestData,
  };
};
