

import { RootState } from "store";
import { CurrencySelectOption } from "./payment.state";

export const selectPaymentCurrencies = (state: RootState) => {
  return state.payment.currencies;
};

export const selectPaymentCurrencySelectOptions = (state: RootState): CurrencySelectOption[] => {
  return state.payment.currencies.map(currency => {
    const option: CurrencySelectOption = {
      label: currency.code,
      value: currency.id,
    }
    return option;
  });
}