/*
 * File: get-all-files.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { StorageFileDTO } from "../../../models/dataset/storage-file.model";
import { RequestOptions } from "../../common/request-options";
import { storageAPIget } from "../storage-httpclient";

export interface DatasetFilesResponse {
  items: StorageFileDTO[];
  totalCount: number;
}

export async function getAllFiles(
  options: RequestOptions = {}
): Promise<DatasetFilesResponse> {
  const params = new URLSearchParams(options);
  const response = await storageAPIget(
    `/api/v1/file-infos?${params.toString()}`
  );
  const items = response.data;
  const total = response.headers["x-total-count"];
  return Promise.resolve({
    items: items,
    totalCount: parseInt(total),
  });
}

export async function getCloudFiles(cloudId: number) {
  const params = new URLSearchParams();
  params.append("cloudId.equals", cloudId.toString());
  return storageAPIget(`/api/v1/file-infos?${params.toString()}`);
}
