/*
 * File: keyboard-shortcuts.compoent.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 16th April 2022 11:58:05 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { VBModal } from "components/common/vb-modal/vb-modal.component";

interface Props {
  open: boolean;
  onClose(): void;
}
export function KeyboardShortcutsModal({ open, onClose }: Props) {
  const keyboardShortcuts = [
    {
      name: "General",
      shortcuts: [
        {
          keys: ["Right"],
          name: "Open the next object in the Series panel.",
        },
        {
          keys: ["Left"],
          name: "Open the back object in the Series panel.",
        },
        { keys: ["Ctrl", "Z"], name: "Undo the previous action" },
        { keys: ["Ctrl", "Alt", "Z"], name: "Redo the previous action" },
        { keys: ["Ctrl", "S"], name: "Save" },
        { keys: ["Ctrl", "Shift", "Space"], name: "Submit" },
      ],
    },
    {
      name: "Tools",
      shortcuts: [
        { keys: ["P"], name: "Select Pan tool in the toolbar" },
        { keys: ["E"], name: "Select the eraser option in the toolbar." },
      ],
    },
    {
      name: "View controls",
      shortcuts: [
        { keys: ["Ctrl", "+"], name: "Zoom out the image" },
        { keys: ["Ctrl", "-"], name: "Zoom in the image" },
        { keys: ["+"], name: "Increase the brightness of the image" },
        { keys: ["-"], name: "Decrease the brightness of the image" },
        { keys: ["SHIFT", "R"], name: "Reset all." },
      ],
    },
    {
      name: "Objects",
      shortcuts: [
        { keys: ["Ctrl", "Backspace"], name: "Delete all regions" },
        { keys: ["Ctrl", "D"], name: "Duplicate a selected region." },
        {
          keys: ["Ctrl", "["],
          name: "Move an annotation up the Annotations list.",
        },
        {
          keys: ["Ctrl", "]"],
          name: "Move an annotation down the Annotations list.",
        },
        {
          keys: ["H"],
          name: "Hide all annotations",
        },
        {
          keys: ["."],
          name: "Toggle the annotation names over each annotation on the image",
        },
        {
          keys: [","],
          name: "Toggle the fill of share on each annotation on the image",
        },
      ],
    },
  ];
  return (
    <VBModal
      open
      onClose={onClose}
      title={"Keyboard shortcuts"}
      footerHidden={true}
      width="40rem"
    >
      <div className="space-y-8" style={{ maxHeight: "60vh" }}>
        {keyboardShortcuts.map(({ name, shortcuts }) => {
          return (
            <div key={name} className="space-y-2">
              <h2 className="text-background-500">{name}</h2>
              <div className="space-y-2">
                {shortcuts.map((shortcut) => {
                  return (
                    <div
                      key={shortcut.name}
                      className="flex items-center gap-4"
                    >
                      <div className="flex-auto text-sm">{shortcut.name}</div>
                      <div
                        className="flex items-center flex-none gap-2"
                        style={{ width: "12rem" }}
                      >
                        {shortcut.keys.map((keyboardKey) => {
                          return (
                            <div
                              key={keyboardKey}
                              className="px-2 py-1 text-xs font-semibold uppercase rounded bg-background-200 text-background-700"
                            >
                              {keyboardKey}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </VBModal>
  );
}
