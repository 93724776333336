/*
 * File: download-dataset.state.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 24th June 2022 9:19:18 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { UploadingFile } from "pages/customer/datasets/dataset-detail/components/upload/upload-file.reducer";

export interface FileUploadState {
  files: UploadingFile[];
  uploadingFile: UploadingFile | null;
  onClickUpload(): void;
}

export const defaultFileUploadState: FileUploadState = {
  files: [],
  uploadingFile: null,
  onClickUpload: () => {},
};
