/*
 * File: image-labeling.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 14th August 2021 9:12:03 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ImageWorkspaceState } from "../image-workspace.state";
import { imageJobSelectedBuilder } from "./thunks/image-job-selected.thunk";
import { initImageLabelingBuilder } from "./thunks/init-image-labeling.thunk";
import { setImageLabelingBuilder } from "./thunks/set-image-labeling.thunk";

export function imageLabelingReducerBuilder(
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) {
  initImageLabelingBuilder(builder);
  setImageLabelingBuilder(builder);
  imageJobSelectedBuilder(builder);
}
