/*
 * File: users.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Sunday, 2nd January 2022 5:13:04 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { UserService } from "services/user-service";
import {
  AddUserToWorkspacePayload,
  RemoveUserFromWorkspacePayload,
} from "services/user-service/apis/user-group.api";
import { userMapper } from "services/user-service/mappers/user.mapper";
import { RootState } from "store";
import { RequestStatus } from "store/base/base.state";
import { selectCurrentWorkspaceId } from "store/common/user-workspace/user-workspace.selectors";
import { UsersState } from "./users.state";

export enum UsersThunk {
  LOAD_USERS_ASYNC = "users/loadUsersAsync",
  INVITE_USER_ASYNC = "users/inviteUserAsync",
  REMOVE_USER_ASYNC = "users/removeUserAsync",
}

export const inviteUserAsync = createAsyncThunk(
  UsersThunk.INVITE_USER_ASYNC,
  async (payload: AddUserToWorkspacePayload) => {
    const response = await UserService.addUserToWorkspace(payload);
    return response.data;
  }
);
export const removeUserAsync = createAsyncThunk(
  UsersThunk.REMOVE_USER_ASYNC,
  async (payload: RemoveUserFromWorkspacePayload) => {
    const response = await UserService.removeUserFromWorkspace(payload);
    return response.data;
  }
);

export const loadUsersAsync = createAsyncThunk(
  UsersThunk.LOAD_USERS_ASYNC,
  async (_, { getState }) => {
    const state = getState() as RootState;
    const currentWorkspaceId = selectCurrentWorkspaceId(state);
    if (!currentWorkspaceId) throw new Error();
    const response = await UserService.getGroupUsers(currentWorkspaceId);
    const users = response.data.map(userMapper.toEntity);
    return {
      users: users,
      total: users.length,
    };
  }
);

export const usersReducerBuilder = (
  builder: ActionReducerMapBuilder<UsersState>
): ActionReducerMapBuilder<UsersState> => {
  return builder
    .addCase(loadUsersAsync.pending, (state) => {
      state.status = RequestStatus.LOADING;
    })
    .addCase(loadUsersAsync.rejected, (state) => {
      state.status = RequestStatus.FAILURE;
    })
    .addCase(loadUsersAsync.fulfilled, (state, action) => {
      state.status = RequestStatus.SUCCESS;
      state.total = action.payload.total;
      state.users = {
        entities: {},
        allIds: [],
      };
      for (let user of action.payload.users) {
        state.users.entities[user.userId] = user;
        state.users.allIds.push(user.id);
      }
    });
};
