export const compact = (array: any[]) => {
  let resIndex = 0
  const result: any[] = []

  if (array == null) {
    return result
  }

  for (const value of array) {
    if (value) {
      result[resIndex++] = value
    }
  }
  return result
}