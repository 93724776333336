/*
 * File: batch-datasource.view.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppDispatch } from "hooks/use-redux";
import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { DatasetFilesExplorer } from "pages/customer/datasets/dataset-detail/components/explorer/dataset-files-explorer.component";
import { ViewFileInfoSwitch } from "pages/customer/datasets/dataset-detail/components/toolbar/view-file-info-switch.component";
import { DatasetDetailProvider } from "pages/customer/datasets/dataset-detail/dataset-detail.context";
import { useEffect } from "react";
import { BatchStatus } from "services/label-service/dtos";
import { setDatasetAsync } from "store/customer/dataset/dataset.slice";
import { Logger } from "utilities/logger";
import { useBatchDetailContext } from "../../context/batch-detail.context";
import { CreateTaskButton } from "./components/create-task-button.component";
import { DeleteFilesBatch } from "./components/delete-files-batch.component";
import { DeleteFilesBatchDialog } from "./components/dialogs/delete-files-batch.dialog";
import * as Sentry from "@sentry/react";

export const BatchDatasourcePage = () => {
  const dispatch = useAppDispatch();
  const { setCurrentDataset } = useDatasetContext();
  const { batch } = useBatchDetailContext();
  useEffect(() => {
    const _setDataAsync = async (datasetId: number) => {
      try {
        dispatch(setDatasetAsync(datasetId));
        setCurrentDataset(datasetId.toString());
      } catch (err) {
        Sentry.captureException(err);
        Logger.log(err);
      }
    };
    _setDataAsync(batch.datasetId);
  }, [dispatch, batch, setCurrentDataset]);

  return (
    <DatasetDetailProvider>
      <div className="flex items-center gap-4 px-4 py-2 mt-4">
        <ViewFileInfoSwitch />
        {batch.status !== BatchStatus.COMPLETED && <DeleteFilesBatch />}
        <div className="flex-1"></div>
        <CreateTaskButton />
        <DeleteFilesBatchDialog />
      </div>
      <DatasetFilesExplorer height="600px" />
    </DatasetDetailProvider>
  );
};
