/*
 * File: create-project-dialog.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 2nd August 2021 2:47:02 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useMemo, useRef, useState, FormEvent } from "react";
import { useKeyPress } from "ahooks";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { VBTextAreaComponent } from "components/design-system/text-area/text-area.component";
import { SingleValue } from "react-select";
import validator from "validator";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { selectSupportedProjectTypes } from "store/common/app-setting/app-setting.selectors";
import {
  HistoryPermission,
  PollStrategy,
  ProjectSetting,
} from "services/label-service/dtos";
import { DEFAULT_AUTOSAVE_IN_SECOND } from "constants/setting.constant";
import * as Sentry from "@sentry/react";
import DOMPurify from "dompurify";

export interface CreateProjectPayload {
  name: string;
  description: string;
  type: string;
  settings?: ProjectSetting;
}
interface Props {
  onClose(): void;
  onSubmit(payload: CreateProjectPayload): Promise<void>;
  processing?: boolean;
}

export const CreateProjectDialog = ({
  onClose,
  onSubmit,
  processing,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const supportedProjectTypes = useAppSelector(selectSupportedProjectTypes);
  const [projectType, setProjectType] = useState<string>(() => {
    if (!supportedProjectTypes || supportedProjectTypes.length === 0) {
      return "image";
    }
    return supportedProjectTypes[0].value;
  });

  const supportedProjectSubTypes = useMemo(() => {
    if (!supportedProjectTypes || supportedProjectTypes.length === 0) return [];
    const type = supportedProjectTypes.find(
      (item) => item.value === projectType
    );
    if (!type) return [];
    return type.children;
  }, [supportedProjectTypes, projectType]);

  const [projectSubType, setProjectSubType] = useState<string>(() => {
    if (!supportedProjectSubTypes || supportedProjectSubTypes.length === 0) {
      return "image_detection";
    }
    return supportedProjectSubTypes[0].value;
  });
  const containerRef = useRef<HTMLDivElement>(null);

  async function handleSubmit() {
    if (processing) return;
    try {
      const payload: CreateProjectPayload = {
        name: DOMPurify.sanitize(name),
        description: DOMPurify.sanitize(description),
        type: projectSubType,
        settings: {
          pollStrategy: PollStrategy.SINGLE,
          historyPermission: HistoryPermission.READ_ONLY,
          autoSaveInSecond: DEFAULT_AUTOSAVE_IN_SECOND,
        },
      };
      console.log(payload);
      validateCreateProjectPayload(payload);
      await onSubmit(payload);
    } catch (err: any) {
      Sentry.captureException(err);
      const errMessage = err.message || t("common:textCreatedFailed");
      dispatch(enqueueErrorNotification(errMessage));
    }
  }

  function validateCreateProjectPayload(payload: CreateProjectPayload) {
    if (validator.isEmpty(payload.name)) {
      throw new Error("Project name is required");
    }
  }

  function handleProjectTypeChanged(val: SingleValue<any>) {
    const newProjectType = val?.value;
    if (newProjectType !== projectType) {
      setProjectType(newProjectType);
      let subType = "";
      if (val && val.children && val.children.length > 0) {
        subType = val.children[0].value;
      }
      setProjectSubType(subType);
    }
  }

  function handleProjectSubTypeChanged(val: SingleValue<any>) {
    setProjectSubType(val?.value as string);
  }

  function handleChange(event: FormEvent<HTMLInputElement>) {
    setName(DOMPurify.sanitize(event.currentTarget.value));
  }

  function handleDescriptionChange(event: FormEvent<HTMLTextAreaElement>) {
    setDescription(DOMPurify.sanitize(event.currentTarget.value));
  }

  useKeyPress(KeyboardKey.Enter, handleSubmit, {
    target: containerRef.current,
  });

  return (
    <VBModal
      open
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t("project:createDialog.title")}
      disableSubmit={processing || validator.isEmpty(name.trim())}
      textSubmit={t("common:buttonCreate")}
      blockUI={processing}
      processingIndicator={processing}
      className="outline-none"
    >
      <div className="flex flex-col gap-4" ref={containerRef}>
        <div>{t("project:createDialog.message")}</div>
        <VBTextInputComponent
          placeholder="Enter project's name"
          header={t("project:createDialog.labelName")}
          className="text-sm"
          autoFocus
          clearInput
          onChange={(evt) => handleChange(evt)}
          onClear={() => setName("")}
          inputIcon={<></>}
        />
        <VBSelectComponent
          header={t("project:createDialog.labelType")}
          defaultValue={projectType}
          onChange={handleProjectTypeChanged}
          value={supportedProjectTypes.find(
            (option) => option.value === projectType
          )}
          options={supportedProjectTypes}
          menuPortalTarget={document.body}
        />
        <VBSelectComponent
          header={t("project:createDialog.labelSubType")}
          value={supportedProjectSubTypes.find(
            (option) => option.value === projectSubType
          )}
          options={supportedProjectSubTypes}
          menuPortalTarget={document.body}
          onChange={handleProjectSubTypeChanged}
        />

        <VBTextAreaComponent
          placeholder="Enter project's description"
          header={t("project:createDialog.labelDescription")}
          className="text-sm"
          onChange={handleDescriptionChange}
        />
      </div>
    </VBModal>
  );
};
