import { Point } from "utilities/math/point";


export const getMousePositionRelative = (
  e: React.MouseEvent<any, MouseEvent>,
  offset: Point = {x: 0, y: 0},
): Point => {
  const rect = (e.target as any).getBoundingClientRect();
  const x = e.clientX - rect.left + offset.x;
  const y = e.clientY - rect.top + offset.y;

  return {x, y};
}

export const getMousePositionRelativePercentage = (
  e: React.MouseEvent<any, MouseEvent>,
  offset: Point = {x: 0, y: 0},
): Point => {
  const rect = (e.target as any).getBoundingClientRect();
  const pos = getMousePositionRelative(e, offset);

  return {x: pos.x / rect.width * 100, y: pos.y / rect.height * 100};
}