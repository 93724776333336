/**
 * WARNING:
 * This is a patched version of https://github.com/cornerstonejs/cornerstoneTools/blob/master/src/util/getActiveTool.js
 * Only work with HeartRulerTool
 * Proceed with cautious!
 */
import csTools from "cornerstone-tools";

const BaseAnnotationTool = csTools.importInternal("base/BaseAnnotationTool");
const BaseBrushTool = csTools.importInternal("base/BaseBrushTool");
const { state, getters } = csTools.store;

function getActiveToolsForElement(element, tools, handlerType) {
  return tools.filter(
    (tool) =>
      tool.element === element &&
      tool.mode === "active" &&
      (handlerType === undefined || tool.options[`is${handlerType}Active`])
  );
}

function filterToolsUseableWithMultiPartTools(tools) {
  return tools.filter(
    (tool) =>
      !(
        tool.isMultiPartTool ||
        tool instanceof BaseAnnotationTool ||
        tool instanceof BaseBrushTool
      )
  );
}

export default function (element, buttons, interactionType = "mouse") {
  let tools;

  if (interactionType === "touch") {
    tools = getActiveToolsForElement(element, getters.touchTools());
    tools = tools.filter((tool) => tool.options.isTouchActive);
  } else {
    // Filter out disabled, enabled, and passive
    tools = getActiveToolsForElement(element, getters.mouseTools());
    // Filter out tools that do not match mouseButtonMask
    tools = tools.filter(
      (tool) =>
        Array.isArray(tool.options.mouseButtonMask) &&
        buttons &&
        tool.options.mouseButtonMask.includes(buttons) &&
        tool.options.isMouseActive
    );

    if (state.isMultiPartToolActive) {
      tools = filterToolsUseableWithMultiPartTools(tools);
    }
  }

  if (tools.length === 0) {
    return;
  }

  return tools[0];
}
