/*
 * File: select-or-create-workspace.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 8th December 2021 10:02:50 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { IconAdd } from "components/common/vb-icon.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { useAppSelector } from "hooks/use-redux";
import { useState, useMemo } from "react";
import { SingleValue } from "react-select";
import { UserRole } from "store/auth/auth.state";
import { selectUserWorkspacesByRole } from "store/common/user-workspace/user-workspace.selectors";
import { Logger } from "utilities/logger";
import * as Sentry from "@sentry/react";

interface Props {
  onClose?(): void;
  onSubmit?(workspaceId: string): Promise<void>;
  onCreate?(name: string): Promise<void>;
  role: UserRole;
}

export const SelectOrCreateWorkspace = ({
  onClose,
  role,
  onSubmit,
  onCreate,
}: Props) => {
  const nextRole =
    role === UserRole.CUSTOMER ? UserRole.LABELER : UserRole.CUSTOMER;
  const workspaces = useAppSelector(selectUserWorkspacesByRole(nextRole));
  const createNew = workspaces.length === 0;
  const [processing, setProcessing] = useState(false);
  const [newWorkspaceName, setNewWorkspaceName] = useState("");
  const [workspace, setWorkspace] = useState(() => {
    if (workspaces.length > 0) return workspaces[0].groupId;
    return "";
  });
  const options = useMemo(() => {
    return workspaces.map((workspace) => {
      return {
        label: workspace.groupName,
        value: workspace.groupId,
      };
    });
  }, [workspaces]);

  const handleChange = (event: any) => {
    setNewWorkspaceName(event.target.value);
  };

  const handleSelectWorkspaceChange = (val: SingleValue<any>) => {
    const value = val?.value || "";
    setWorkspace(value);
  };

  const handleSubmit = async () => {
    try {
      setProcessing(true);
      if (createNew) onCreate && (await onCreate(newWorkspaceName));
      else onSubmit && (await onSubmit(workspace));
    } catch (error) {
      Sentry.captureException(error);
      Logger.log(error);
    } finally {
      setProcessing(false);
    }
  };
  
  return (
    <VBModal
      title={createNew ? "Create a new workspace" : "Select workspace"}
      open
      onClose={onClose}
      textSubmit={processing ? "Please wait.." : createNew ? "Create" : "Apply"}
      disableSubmit={!(newWorkspaceName || workspace) || processing}
      onSubmit={handleSubmit}
    >
      <div>
        {createNew && (
          <VBTextInputComponent
            header="Workspace"
            placeholder="workspace"
            defaultValue={newWorkspaceName}
            onInputChange={setNewWorkspaceName}
            onChange={handleChange}
            clearInput
            inputIcon={<IconAdd className="flex-shrink-0 w-4 h-4" />}
          />
        )}
        {!createNew && (
          <VBSelectComponent
            header="Workspace"
            placeholder="Select workspace"
            onChange={handleSelectWorkspaceChange}
            defaultValue={options.find((w) => w.value === workspace)}
            options={options}
            isClearable
            menuPortalTarget={document.body}
          />
        )}
      </div>
    </VBModal>
  );
};
