/*
 * File: labeler-navigation.constant.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 19th November 2021 3:48:05 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  IconHomeFill,
  IconHome,
  IconTagFill,
  IconTag,
  IconNoteFill,
  IconNote,
  IconMessageQuestionFill,
  IconMessageQuestion,
} from "components/common/vb-icon.component";
import { Routes } from "routers/config/routes";

export const LABELER_NAVIGATION_MENU_ITEMS = [
  {
    id: Routes.LABELER_HOME,
    name: "Dashboard",
    activeIcon: <IconHomeFill className="w-6 h-6" />,
    icon: <IconHome className="w-6 h-6" />,
  },
  {
    id: Routes.LABELER_PROJECTS,
    name: "Project",
    activeIcon: <IconTagFill className="w-6 h-6" />,
    icon: <IconTag className="w-6 h-6" />,
  },
  {
    id: Routes.LABELER_REPORT,
    name: "Report",
    activeIcon: <IconNoteFill className="w-6 h-6" />,
    icon: <IconNote className="w-6 h-6" />,
  },
  {
    id: Routes.LABELER_FAQ,
    name: "FAQ",
    activeIcon: <IconMessageQuestionFill className="w-6 h-6" />,
    icon: <IconMessageQuestion className="w-6 h-6" />,
  },
];
