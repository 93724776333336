/*
 * File: use-workspace-users.hook.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 19th August 2021 4:03:39 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { selectCurrentWorkspaceId } from "store/common/user-workspace/user-workspace.selectors";
import {
  selectWorkspaceLabelers,
  selectWorkspaceUsers,
} from "store/customer/users/users.selectors";
import { loadUsersAsync } from "store/customer/users/users.thunk";

export const useWorkspaceUsers = () => {
  const dispatch = useAppDispatch();
  const workspaceId = useAppSelector(selectCurrentWorkspaceId);
  const users = useAppSelector(selectWorkspaceUsers);
  const labelers = useAppSelector(selectWorkspaceLabelers);

  useEffect(() => {
    if (workspaceId) dispatch(loadUsersAsync());
  }, [dispatch, workspaceId]);

  return {
    users,
    labelers,
  };
};
