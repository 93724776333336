/*
 * File: ai-models.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 2nd November 2022 4:17:02 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useWorkspaceContext } from "contexts/workspace";
import { useAppDispatch } from "hooks/use-redux";
import { ReactNode, useState } from "react";
import { useQueryClient } from "react-query";
import { mlServiceApis } from "services/ml-service/ml.service";
import {
  createStorageResource,
  processStorageResource,
  uploadToAzureBlob,
} from "services/storage/apis/resource.api";
import { StorageResource } from "services/storage/dto/resource.dto";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import UploadAIModel from "../components/upload-ai-model.component";
import { validateUploadAIModelPayload } from "../validation/upload-ai-model-validation.schema";
import { AIModelsContext } from "./ai-models.context";
import { UploadAIModelPayload } from "./ai-models.state";
import * as Sentry from "@sentry/react";

interface AIModelsProviderProps {
  children?: ReactNode;
}

export default function AIModelsProvider({ children }: AIModelsProviderProps) {
  const [isLoading] = useState(false);
  const [isImporting, setImporting] = useState(false);
  const [open, setOpen] = useState(false);
  const { workspaceId } = useWorkspaceContext();
  const [uploadProgress, setUploadProgress] = useState(0);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  function onClickUpload() {
    setOpen(true);
  }
  function handleCloseUpload() {
    setOpen(false);
  }
  async function startImportAIModel(payload: UploadAIModelPayload) {
    try {
      setImporting(true);
      const errors: any = await validateUploadAIModelPayload(payload);
      if (errors) throw errors;
      await importAIModelAsync(payload);
      dispatch(enqueueSuccessNotification("Success"));
      setImporting(false);
      setOpen(false);
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error?.message ?? "Failed to import AI model";
      if (errMessage) dispatch(enqueueErrorNotification(errMessage));
      setImporting(false);
    }
  }

  async function importAIModelAsync(payload: UploadAIModelPayload) {
    if (!payload.file) return;
    const resource = await createStorageResource("Cache");
    const resourceData = resource.data as StorageResource;
    await uploadToAzureBlob(
      payload.file,
      resourceData.path,
      (progressEvent: any) => {
        const progress = progressEvent.loaded / progressEvent.total;
        setUploadProgress(progress);
      }
    );
    await processStorageResource(resourceData.resourceId);
    const params = {
      storageResourceId: resourceData.resourceId,
      name: payload.name,
      description: payload.description,
      workspaceId: workspaceId,
      inferenceType: payload.inferenceType,
    };
    const registerNewModel = await mlServiceApis.registerCustomModel(params);
    queryClient.invalidateQueries({ queryKey: ["ai_models"] });
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ["ai_models"] });
    }, 1500);
    return registerNewModel;
  }

  const state = {
    uploadProgress,
    isLoading,
    isImporting,
    startImportAIModel,
    onClickUpload,
  };

  return (
    <AIModelsContext.Provider value={state}>
      {children}
      {open && <UploadAIModel onClose={handleCloseUpload} />}
    </AIModelsContext.Provider>
  );
}
