import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CancelTokenSource } from "axios";
import { StorageKeys } from "hooks/storage/storage-keys";
import moment from "moment";
import { QueryNameStatistic } from "pages/customer/dashboard/context/dashboard.state";
import { cardRowDatasourceMapper } from "pages/customer/dashboard/mapper/card-row-datasource.mapper";
import { cardRowLabelerMapper } from "pages/customer/dashboard/mapper/card-row-labeler.mapper";
import { cardRowProjectMapper } from "pages/customer/dashboard/mapper/card-row-project.mapper";
import { cardRowStorageUsageMapper } from "pages/customer/dashboard/mapper/card-row-storage-usage.mapper";
import { rowTableBatchMapper } from "pages/customer/dashboard/mapper/row-table-batch.mapper";
import { rowTableLabelMapper } from "pages/customer/dashboard/mapper/row-table-label.mapper";
import { rowTableLabelerMapper } from "pages/customer/dashboard/mapper/row-table-labeler.mapper";
import { rowTableProjectMapper } from "pages/customer/dashboard/mapper/row-table-project.mapper";
import { taskCompletedMapper } from "pages/customer/dashboard/mapper/task-completed.mapper";
import { taskProgressMapper } from "pages/customer/dashboard/mapper/task-progress.mapper";
import { AnalyticsQueryResponse } from "services/analytics-service";
import { ProjectTypes } from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import { DateRange } from "utilities/date-time/date-range";
import { DashBoardStoreState, INITIAL_DASH_BOARD_STATE } from "./dashboard.state";
import { dashBoardReducerBuilder } from "./dashboard.thunk";

const SLICE_NAME = "dashboard";

const updateStateQueryNameFieldData = (
  state: DashBoardStoreState,
  queryName: string,
  fieldData: any,
  localKey: string,
) => {
  if (!fieldData) return;

  if (queryName === QueryNameStatistic.DatasourceStatistic) {
    state.cardRowsDatasources = fieldData;
  }
  if (queryName === QueryNameStatistic.ProjectStatistic) {
    state.cardRowsProjects = fieldData;
  }
  if (queryName === QueryNameStatistic.LabelerStatistic) {
    state.cardRowsLabelers = fieldData;
  }
  if (queryName === QueryNameStatistic.StorageStatistic) {
    state.cardRowsStorageUsage = fieldData;
  }

  if (queryName === QueryNameStatistic.TaskProgressStatistic) {
    state.taskProgressData = fieldData;
  }
  if (queryName === QueryNameStatistic.TaskCompleteStatistic) {
    state.taskCompletedData = fieldData;
  }

  if (queryName === QueryNameStatistic.LabelProgressStatistic) {
    state.tableRowsLabels = fieldData;
  }
  if (queryName === QueryNameStatistic.LabelerProgressStatistic) {
    state.tableRowsLabelers = fieldData;
  }
  if (queryName === QueryNameStatistic.ProjectProgressStatistic) {
    state.tableRowsProjects = fieldData;
  }
  if (queryName === QueryNameStatistic.BatchProgressStatistic) {
    state.tableRowsBatches = fieldData;
  }

  state.cache = {
    ...state.cache,
    [queryName]: true,
  };

  // save to local storage
  localStorage.setItem(localKey, JSON.stringify(fieldData));
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_DASH_BOARD_STATE,
  reducers: {
    updateQueryNameData: (
      state: DashBoardStoreState,
      action: PayloadAction<{
        queryName: string,
        response: AnalyticsQueryResponse,
        localKey: string,
      }>,
    ) => {
      const {queryName, response, localKey} = action.payload;

      const mappersMap: Record<string, any> = {
        [QueryNameStatistic.DatasourceStatistic]: cardRowDatasourceMapper,
        [QueryNameStatistic.ProjectStatistic]: cardRowProjectMapper,
        [QueryNameStatistic.LabelerStatistic]: cardRowLabelerMapper,
        [QueryNameStatistic.StorageStatistic]: cardRowStorageUsageMapper,
        
        [QueryNameStatistic.TaskProgressStatistic]: taskProgressMapper,
        [QueryNameStatistic.TaskCompleteStatistic]: taskCompletedMapper,

        [QueryNameStatistic.LabelProgressStatistic]: rowTableLabelMapper,
        [QueryNameStatistic.LabelerProgressStatistic]: rowTableLabelerMapper,
        [QueryNameStatistic.ProjectProgressStatistic]: rowTableProjectMapper,
        [QueryNameStatistic.BatchProgressStatistic]: rowTableBatchMapper,
      };

      const fieldData = (mappersMap[queryName])(response);
      updateStateQueryNameFieldData(state, queryName, fieldData, localKey);
    },

    setDashBoardQueryNameData: (
      state: DashBoardStoreState,
      action: PayloadAction<{
        queryName: string;
        fieldData: any;
        localKey: string;
      }>,
    ) => {
      const {queryName, fieldData, localKey} = action.payload;
      updateStateQueryNameFieldData(state, queryName, fieldData, localKey);
    },

    updateCancleTokensMap: (
      state: DashBoardStoreState,
      action: PayloadAction<{
        key: string;
        value: CancelTokenSource | undefined;
      }>,
    ) => {
      const {key, value} = action.payload;
      state.cancelTokensMap = {
        ...state.cancelTokensMap,
        [key]: value,
      }
    },

    updateQueryNameRequestStatus: (
      state: DashBoardStoreState,
      action: PayloadAction<{
        key: string;
        value: RequestStatus;
      }>,
    ) => {
      const {key, value} = action.payload;
      state.queryNamesRequestStatusMap = {
        ...state.queryNamesRequestStatusMap,
        [key]: value,
      }
    },

    setDashBoardFromDate: (
      state: DashBoardStoreState,
      action: PayloadAction<Date>,
    ) => {
      state.fromDate = action.payload;
    },
    setDashBoardToDate: (
      state: DashBoardStoreState,
      action: PayloadAction<Date>,
    ) => {
      state.toDate = action.payload;
    },
    setDashBoardDateRange: (
      state: DashBoardStoreState,
      action: PayloadAction<DateRange>,
    ) => {
      state.dateRange = action.payload;
    },
    setDashBoardTaskCompletedFilter: (
      state: DashBoardStoreState,
      action: PayloadAction<string>,
    ) => {
      state.taskCompletedFilter = action.payload;
    },
    setDashBoardTableProjectType: (
      state: DashBoardStoreState,
      action: PayloadAction<ProjectTypes | undefined>,
    ) => {
      state.tableProjectType = action.payload;
    },
    setDashBoardLatestTimeLoaded: (
      state: DashBoardStoreState,
      action: PayloadAction<{
        key: string,
        value: string,
      }>,
    ) => {
      const {key, value} = action.payload;
      if (!value) return; 
      state.latestTimeLoaded = value;
      localStorage.setItem(key, value);
    },

    saveDashBoardFiltersToLocalStorage: (
      state: DashBoardStoreState,
      action: PayloadAction<string>,
    ) => {
      const preFixKey = action.payload;
      if (state.fromDate) {
        localStorage.setItem(`${preFixKey}_${StorageKeys.DASH_BOARDH_FROM_DATE}`, state.fromDate.toISOString());
      }
      if (state.toDate) {
        localStorage.setItem(`${preFixKey}_${StorageKeys.DASH_BOARDH_TO_DATE}`, state.toDate.toISOString());
      }
      if (state.dateRange) {
        localStorage.setItem(`${preFixKey}_${StorageKeys.DASH_BOARDH_DATE_RANGE}`, state.dateRange);
      }
      if (state.taskCompletedFilter) {
        localStorage.setItem(`${preFixKey}_${StorageKeys.DASH_BOARDH_COMPLETED_TASK_FILTER}`, state.taskCompletedFilter);
      }
      if (state.tableProjectType) {
        localStorage.setItem(`${preFixKey}_${StorageKeys.DASH_BOARDH_TABLE_PROJECT_TYPE}`, state.tableProjectType);
      }
    },
    loadDashBoardFiltersFromLocalStorage: (
      state: DashBoardStoreState,
      action: PayloadAction<string>,
    ) => {
      const preFixKey = action.payload;
      const localFromDate = localStorage.getItem(`${preFixKey}_${StorageKeys.DASH_BOARDH_FROM_DATE}`);
      const localToDate = localStorage.getItem(`${preFixKey}_${StorageKeys.DASH_BOARDH_TO_DATE}`);
      const localDateRange = localStorage.getItem(`${preFixKey}_${StorageKeys.DASH_BOARDH_DATE_RANGE}`);
      const localTaskCompletedFilter = localStorage.getItem(`${preFixKey}_${StorageKeys.DASH_BOARDH_COMPLETED_TASK_FILTER}`);
      const localTableProjectType = localStorage.getItem(`${preFixKey}_${StorageKeys.DASH_BOARDH_TABLE_PROJECT_TYPE}`);
    
      if (localFromDate) {
        state.fromDate = moment(new Date(localFromDate)).local().toDate();
      }
      if (localToDate) {
        state.toDate = moment(new Date(localToDate)).local().toDate();
      }
      if (localDateRange) {
        state.dateRange = localDateRange as DateRange;
      }
      if (localTaskCompletedFilter) {
        state.taskCompletedFilter = localTaskCompletedFilter;
      }
      if (localTableProjectType) {
        state.tableProjectType = localTableProjectType as ProjectTypes;
      }
    },
  },
  extraReducers: (builder) => {
    dashBoardReducerBuilder(builder);
  },
});

export const {
  updateQueryNameData,
  setDashBoardQueryNameData,
  updateCancleTokensMap,
  updateQueryNameRequestStatus,
  setDashBoardFromDate,
  setDashBoardToDate,
  setDashBoardDateRange,
  setDashBoardTaskCompletedFilter,
  setDashBoardTableProjectType,
  setDashBoardLatestTimeLoaded,
  saveDashBoardFiltersToLocalStorage,
  loadDashBoardFiltersFromLocalStorage,
} = slice.actions;

export const dashBoardReducer = slice.reducer;