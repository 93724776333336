import { FabricObjectToolType } from "./fabric/fabric.models";

// Tools
export interface PathologyEditorTool {
  id: number;
  name: string;
  shortcut: string;
  isDrawing?: boolean;
  hidden?: boolean;
}

export const TOOL_MOVE: PathologyEditorTool = {
  id: 0,
  name: "Move",
  shortcut: "m",
};

export const TOOL_SELECT: PathologyEditorTool = {
  id: 1,
  name: "Select",
  shortcut: "s",
};

export const TOOL_POLYGON: PathologyEditorTool = {
  id: 2,
  name: "Polygon",
  shortcut: "p",
  isDrawing: true,
};

export const TOOL_RECTANGLE: PathologyEditorTool = {
  id: 3,
  name: "Rectangle",
  shortcut: "r",
  isDrawing: true,
};

export const TOOL_LINE: PathologyEditorTool = {
  id: 4,
  name: "Line",
  shortcut: "l",
  isDrawing: true,
};

export const TOOL_ELLIPSE: PathologyEditorTool = {
  id: 5,
  name: "Ellipse",
  shortcut: "e",
  isDrawing: true,
};

export const TOOL_BRUSH: PathologyEditorTool = {
  id: 6,
  name: "Brush",
  shortcut: "b",
  isDrawing: true,
};

export const TOOL_MAGIC_WAND: PathologyEditorTool = {
  id: 7,
  name: "Magic wand",
  shortcut: "o",
  isDrawing: true,
};

export const EDITOR_TOOLS: PathologyEditorTool[] = [
  TOOL_MOVE,
  TOOL_SELECT,
  TOOL_POLYGON,
  TOOL_RECTANGLE,
  TOOL_LINE,
];

export interface PathologyEditorLabel {
  // this should be onbservation id, but we use id here to seperate from domain knowledge
  id: number;
  name: string;
  color: string;
  annotationType: FabricObjectToolType;
}

// Annotations

// We use this interface just for triggering render to Reactjs component from fabric objects.
// all data related to annotations we put in fabric objects themself
// so we don't have to maintain two seperate arrays of annotations
export interface PathologyAnnotation {
  id: string;
  type: FabricObjectToolType;
  fabricObjectRef?: fabric.Polygon | fabric.Rect;
}

// Additaional data attach to a fabric object
// useful for filtering, ...
// TODO: add more fields later like source, jobId ...
export interface PathologyAnnotationAdditionalData {
  labelId: number;
  labelName: string;
  labelColor: string;
  labeler: string;
  selected?: boolean;
  utilGroupRef?: fabric.Group;
  distanceTextRef?: fabric.Text;

  // useful when building the hierarchy tree
  serverId?: number;
  geometryRef?: any;
  parent?: fabric.Object;
  children?: fabric.Object[];
  level?: number;
  // for displaying tree states
  childrenVisibility?: boolean; // undefined = true
  inHierarchyVisibility?: boolean; // undefined = true
}
// useful when import edges to build the hierarchy tree from db
export interface PathologyAnnotationEdge {
  parentServerId: number;
  childServerId: number;
}

// Annotation filter
export interface PathologyAnnotationFilter {
  labelers: string[];
  labelIds: number[];
  types: string[];
  sources: string[];
}

export const DEFAULT_PATHOLOGY_ANNOTATION_FILTER: PathologyAnnotationFilter = {
  labelers: [],
  labelIds: [],
  types: [],
  sources: [],
};

export interface PathologyLabeler {
  id: string; // should be email/assignee
  jobId: number;
}

export enum PathologyEditorEvents {
  CONTEXT_MENU = "PathologyEditorEvents_CONTEXT_MENU",
}

// Tabs
export interface PathologyEditorTab {
  id: number;
  name: string;
}

export const TAB_ANNOTATIONS: PathologyEditorTab = {
  id: 0,
  name: "Annotations",
};

export const TAB_HIERARCHY: PathologyEditorTab = {
  id: 2,
  name: "Hierarchy",
};

export const TAB_IMAGE: PathologyEditorTab = {
  id: 1,
  name: "Image",
};

export const EDITOR_TABS: PathologyEditorTab[] = [
  TAB_ANNOTATIONS,
  TAB_HIERARCHY,
  TAB_IMAGE,
];
