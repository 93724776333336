/*
 * File: use-annotation-menu.hook.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 2nd July 2022 8:08:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { MutableRefObject, useCallback, useMemo } from "react";
import { selectSelectedTextToken } from "store/labeler/text-workspace/text-editor/text-editor.selector";
import { selectSelectedTextIssue } from "store/labeler/text-workspace/text-issues/text-issues.selector";
import {
  textTokenIssueMenuClosed,
  textTokenIssueMenuOpened,
} from "store/labeler/text-workspace/text-workspace.slice";

export const useTokenIssueMenu = (
  containerRef: MutableRefObject<HTMLDivElement | null>
) => {
  const dispatch = useAppDispatch();
  const selectedToken = useAppSelector(selectSelectedTextToken);
  const issue = useAppSelector(selectSelectedTextIssue);

  const position = useMemo(() => {
    const { tokenId, issueMenu } = selectedToken;
    if (!tokenId || !issueMenu || !containerRef.current) return null;
    const element = containerRef.current.querySelector(
      `#${CSS.escape(tokenId)}`
    );
    if (element && containerRef.current) {
      const bounding = element.getBoundingClientRect();
      const parentBounding = containerRef.current.getBoundingClientRect();
      return {
        x: bounding.x - parentBounding.x,
        y: bounding.y + bounding.height + 20 - parentBounding.y,
      };
    }
    return null;
  }, [selectedToken, containerRef]);

  const hideTokenIssueMenu = useCallback(() => {
    dispatch(textTokenIssueMenuClosed());
  }, [dispatch]);

  const showTextIssueMenu = useCallback(
    (id: string) => {
      dispatch(textTokenIssueMenuOpened(id));
    },
    [dispatch]
  );

  const state = useMemo(() => {
    if (!position) return null;
    return { position, issue, tokenId: selectedToken.tokenId };
  }, [position, selectedToken, issue]);

  return {
    tokenIssueMenuState: state,
    hideTokenIssueMenu,
    showTextIssueMenu,
  };
};
