import { Issue, IssueComment, IssueStatus } from "domain/common/issue";
import { newUniqueId } from "utilities/number/id-generator";
import { truncateEmail } from "utilities/string/truncate-email";

function fromThreeDDto(dto: any): Issue {
  const createdBy = dto.createdBy ? dto.createdBy : "noname@gmail.com";
  return {
    id: dto.id,
    jobId: dto.jobId,
    taskId: dto.taskId,
    status: dto.status,
    creator: {
      name: truncateEmail(createdBy),
      email: createdBy,
    },
    content: dto.description,
    comments: (dto.comments || []).map(commentFromDTO),
    createdDate: dto.createdDate || new Date().toISOString(),
    metadata: {
      x: parseInt(dto.data.x),
      y: parseInt(dto.data.y),
    },
  };
}

function fromDto(dto: any): Issue {
  const createdBy = dto.createdBy ? dto.createdBy : "noname@gmail.com";
  return {
    id: dto.id,
    jobId: dto.jobId,
    taskId: dto.taskId,
    status: dto.status,
    creator: {
      name: truncateEmail(createdBy),
      email: createdBy,
    },
    content: dto.description,
    comments: (dto.comments || []).map(commentFromDTO),
    createdDate: dto.createdDate || new Date().toISOString(),
    metadata: {
      startIndex: dto.data.startIndex,
      endIndex: dto.data.endIndex,
      annotationId: `${dto.data.annotationId}:${dto.data.startIndex}:${dto.data.endIndex}`,
    },
  };
}

function commentFromDTO(dto: any): IssueComment {
  return {
    id: dto.id || newUniqueId(),
    content: dto.content,
    issueId: dto.issueId,
    creator: {
      name: truncateEmail(dto.createdBy),
      email: dto.createdBy,
    },
    createdDate: dto.createdDate,
  };
}

function newIssue(
  user: { email: string; displayName: string },
  jobId: number,
  taskId: number,
  content: string,
  annotation: any
): Issue {
  const issue: Issue = {
    id: newUniqueId(),
    jobId: jobId,
    taskId: taskId,
    status: IssueStatus.OPENED,
    creator: {
      email: user.email,
      name: user.displayName,
    },
    content: content,
    comments: [],
    createdDate: new Date().toUTCString(),
    metadata: {
      slice: annotation.slice,
      axis: annotation.axis,
    },
  };
  return issue;
}

export const issueMapepr = {
  fromDto,
  newIssue,
  fromThreeDDto
};
