/*
 * File: own-workspace-section.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { CreateWorkspaceCard } from "../components/create-workspace-card";
import { WorkspaceCard } from "../components/manage-workspace-card";
import { GroupDTO } from "services/user-service/dtos/group.dto";
import { useWorkspaces } from "hooks/workspace/use-workspace.hook";
import { useKeycloakContext } from "contexts/keycloak/keycloak.context";
import { useAppSelector } from "hooks/use-redux";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { useMemo } from "react";
import { UserRole } from "store/auth/auth.state";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";

export const OwnWorkspaceSection = ({
  handleCreateWorkspace,
}: {
  handleCreateWorkspace: () => void;
}) => {
  const { t } = useTranslation();
  const { selectWorkspace } = useKeycloakContext();
  const { workspace, workspaces } = useWorkspaces();
  const currentUser = useAppSelector(selectCurrentUser);
  const history = useHistory();
  const clientWorkspaces = useMemo(() => {
    if (
      !currentUser ||
      !currentUser.workspace.hasOwnProperty(UserRole.CUSTOMER)
    ) {
      return [];
    }
    const userWorkspaces = currentUser.workspace.customer;
    return workspaces.filter((w) => userWorkspaces.includes(w.groupId));
  }, [workspaces, currentUser]);

  const handleSelectWorkspace = (workspace: GroupDTO) => {
    selectWorkspace(workspace.groupId);
    history.replace(Routes.ROOT);
  };
  return (
    <div className="p-4">
      <h2 className="my-8 text-xl font-bold text-gray-700">
        {t("workspace:textYourWorkspace")}
      </h2>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        <CreateWorkspaceCard handleClick={() => handleCreateWorkspace()} />
        {clientWorkspaces.map((w) => (
          <WorkspaceCard
            key={w.groupId}
            workspace={w}
            isCurrent={workspace?.groupId === w.groupId}
            onSelect={handleSelectWorkspace}
          />
        ))}
      </div>
    </div>
  );
};
