import {
  IconCheckbox,
  IconCheckboxChecked,
} from "components/common/vb-icon.component";
import { ReactNode } from "react";
import classnames from "classnames";

interface Props {
  value: boolean;
  onChange?: (v: boolean) => void;
  className?: string;
  activeClassName?: string;
  checkedLabel?: string;
  uncheckedLabel?: string;
  children?: ReactNode;
  disabled?: boolean;
}

export const VBCheckbox = ({
  value,
  onChange,
  className = "w-6 h-6",
  activeClassName = "text-primary",
  checkedLabel = "",
  uncheckedLabel = "",
  children,
  disabled = false,
}: Props) => {
  const disabledClassName =
    "cursor-not-allowed opacity-50 pointer-events-none text-gray-500 ";

  return (
    <div className="flex items-center gap-1">
      {value && (
        <IconCheckboxChecked
          className={classnames(
            "cursor-pointer",
            disabled && disabledClassName,
            className,
            activeClassName
          )}
          onClick={(_) => onChange && onChange(false)}
        />
      )}
      {!value && (
        <IconCheckbox
          className={classnames(
            "cursor-pointer text-background-500",
            disabled && disabledClassName,
            className
          )}
          onClick={(_) => onChange && onChange(true)}
        />
      )}
      {value && checkedLabel && <span>{checkedLabel}</span>}
      {!value && uncheckedLabel && <span>{uncheckedLabel}</span>}
      {children}
    </div>
  );
};
