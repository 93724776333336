import { useCountDown } from "ahooks";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

interface UseAutoSaveProps {
  enabled: boolean;
  duration?: number;
  countDownTime?: number;
  onSave?: () => Promise<void>;
}

const DEFAULT_TIMEOUT = 10000;
const DEFAULT_COUNTDOWN_TIME = 5000;

export interface UseAutoSaveReturnValue {
  enabled: boolean;
  countdown: number;
  seconds: number;
}

export const useAutoSave = ({
  enabled = false,
  duration = DEFAULT_TIMEOUT,
  countDownTime = DEFAULT_COUNTDOWN_TIME,
  onSave,
}: UseAutoSaveProps): UseAutoSaveReturnValue => {
  const [isSaved, setIsSaved] = useState(false);

  useIdleTimer({
    timeout: duration,
    events: ["click", "keydown", "mousewheel", "mousedown"],
    onIdle: function () {
      setIsSaved(enabled);
    },
  });

  const [countdown, setTargetDateAutoSave, { seconds: secondsCountDown }] =
    useCountDown({
      onEnd: async () => {
        if (isSaved) {
          onSave && (await onSave());
          setIsSaved(false);
        }
      },
    });

  useEffect(() => {
    if (isSaved) {
      setTargetDateAutoSave(new Date(Date.now() + countDownTime));
    }
  }, [countDownTime, isSaved, setTargetDateAutoSave]);

  return {
    enabled: isSaved,
    countdown,
    seconds: secondsCountDown,
  };
};
