import {
  CornerstoneTool,
  ToolName,
} from "components/dicom/dicom-tools/dicom-tools.model";
import { ToolButton } from "components/dicom/dicom-tools/tool-button.component";
import { CornerstoneToolIcons } from "components/dicom/dicom-tools/tool-icon.component";
import { useState } from "react";

interface TasksReviewToolComponentProps {
  onSelect?: (tool: CornerstoneTool) => void;
}

export const TasksReviewToolComponent = ({
  onSelect,
}: TasksReviewToolComponentProps) => {
  const tools: CornerstoneTool[] = [
    {
      name: "Zoom",
      type: ToolName.Zoom,
    },
    {
      name: "Wwwc",
      type: ToolName.Wwwc,
    },
    {
      name: "Reset all",
      type: ToolName.ResetAll,
    },
  ];
  const [activeTool, setActiveTool] = useState("");

  const handleSelect = (tool: CornerstoneTool) => {
    if (activeTool !== tool.name) {
      setActiveTool(tool.name);
      onSelect && onSelect({...tool, active: true});
    } else {
      setActiveTool("");
      onSelect && onSelect({...tool, active: false});
    }
  };

  return (
    <div className="absolute flex flex-col p-1.5 gap-3 transform -translate-y-1/2 bg-white rounded right-2 top-1/2 w-9">
      {tools.map((tool) => (
        <ToolButton
          className="bg-opacity-0 text-primary"
          inactiveClass="text-background-300"
          size="sm"
          key={tool.name}
          icon={CornerstoneToolIcons[tool.type]}
          toolTip={tool.name}
          disabled={tool.disabled}
          active={tool.active || activeTool === tool.name}
          onClick={() => handleSelect(tool)}
        />
      ))}
    </div>
  );
};
