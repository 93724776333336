/*
 * File: get-file-info.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { AxiosResponse } from "axios";
import { StorageFileDTO } from "../../../models/dataset/storage-file.model";
import { RequestOptions } from "../../common/request-options";
import { storageAPIget } from "../storage-httpclient";

export async function getFileInfo(
  fileId: string,
  options: RequestOptions = {}
): Promise<AxiosResponse<StorageFileDTO>> {
  const params = new URLSearchParams(options);
  return storageAPIget(`/api/v1/file-infos/${fileId}?${params.toString()}`);
}

export async function getFileInfoDetails(
  fileId: string,
): Promise<AxiosResponse<StorageFileDTO>> {
  const res = await storageAPIget(`/api/v1/file-infos/${fileId}/details`);

  if (res.data.downloadUrls && res.data.downloadUrls.length > 0) {
    res.data.url = res.data.downloadUrls[0];
  }

  return res;
}
