import {
  VBModal,
  VBModalProps,
} from "components/common/vb-modal/vb-modal.component";
import { useTranslation } from "react-i18next";

interface WarningModalProps extends Omit<VBModalProps, "title" | "children"> {
  title?: string;
  content?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  onSkip?: () => void;
}
export const WarningModal = ({
  open,
  title = "Warning",
  content = "",
  onCancel,
  onSubmit,
  onSkip,
  ...vbModalProps
}: WarningModalProps) => {
  const { t } = useTranslation();

  return (
    <VBModal
      open={open}
      width="30rem"
      title={title}
      textSubmit={t("common:buttonContinue")}
      textClose={t("common:buttonCancel")}
      onClose={onCancel}
      onSubmit={onSubmit}
      {...vbModalProps}
    >
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </VBModal>
  );
};
