import { Slider, withStyles } from "@material-ui/core";
import { IconPause, IconPlayArrow } from "components/common/vb-icon.component";
import { useEffect, useRef, useState } from "react";
import { classnames } from "utilities/classes";
import { convertSecondToString } from "utilities/date-time/second-to-string";

const AudioSlider = withStyles({
  root: {
    color: "currentColor",
  },
  thumb: {
    height: 12,
    width: 12,
    border: "2px solid currentColor",
    marginTop: -4,
    marginLeft: -4,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);
export interface TextToSpeechAudioPlayerProps {
  url: string;
  active?: boolean;
}

export const TextToSpeechAudioPlayer = ({
  url,
  active = false,
}: TextToSpeechAudioPlayerProps) => {
  const audioRef = useRef<any>();
  const [sliderValue, setSliderValue] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const onAudioLoaded = (event: any) => {
      if (audioRef.current){
        setDuration(audioRef.current.duration);
        setCurrentTime(audioRef.current.currentTime);
      }
    }

    const onTimeUpdate = () => {
      if (audioRef.current){
        setCurrentTime(audioRef.current.currentTime);
        const newSliderValue = audioRef.current.currentTime / audioRef.current.duration * 100;
        setSliderValue(newSliderValue);
      }
    }

    const onEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
      setSliderValue(0);
    }

    if (audioRef && audioRef.current){
      audioRef.current.pause();
      audioRef.current.load();
      audioRef.current.addEventListener("loadeddata", onAudioLoaded);
      audioRef.current.addEventListener("timeupdate", onTimeUpdate);
      audioRef.current.addEventListener("ended", onEnded);
    }
  }, [url]);

  useEffect(() => {
    if (isPlaying){
      if (audioRef.current){
        audioRef.current.play();
      }
    } else {
      if (audioRef.current){
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);

  const handleSliderValueChanged = (newValue: number) => {
    setSliderValue(newValue);
    audioRef.current.currentTime = newValue / 100 * duration;
  }

  const handleClickPlay = () => {
    setIsPlaying(true);
  }

  const handleClickPause = () => {
    setIsPlaying(false);
  }

  return (
    <div className="cursor-pointer">
      <audio ref={audioRef}>
        <source src={url} />
      </audio>
      <div className={classnames(
        " rounded py-2 px-3",
        {
          "bg-white": active,
        },
        {
          "text-white bg-warning-300": !active,
        }
      )}
        style={{
          height: "40px",
        }}
      >
        <div className="flex items-center w-full gap-4 z-50">
          <div>
            {
              !isPlaying &&
              <IconPlayArrow 
                className={classnames(
                  "w-6 h-6",
                  {"text-warning-500": active}
                )}
                onClick={handleClickPlay}
              />
            }
            {
              isPlaying &&
              <IconPause
                className={classnames(
                  "w-6 h-6",
                  {"text-warning-500": active}
                )}
                onClick={handleClickPause}
              />
            }
          </div>
          <div className="flex justify-between items-center w-full gap-2">
            <span className={active ? "text-background-700" : ""}>
              {convertSecondToString(currentTime, true)}
            </span>
            <div className={classnames(
              "flex items-center",
              {"text-warning-300": active}
            )}
              style={{
                width: "150px",
                height: "20px",
              }}
            >
              <AudioSlider
                step={0.01}
                value={sliderValue}
                onChange={(_, newValue) => handleSliderValueChanged(newValue as number)}
              />
            </div>
            <span className={active ? "text-background-700" : ""}>
              {convertSecondToString(duration, true)}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}