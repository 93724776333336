/*
 * File: batch-workflow.view.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useState } from "react";
import {
  BatchPriceTabsComponent,
  BatchPricingUIModel,
  convertBatchPricingUIModelToPricingCalculationRequestDTO,
  convertBatchPricingUIModelToPricingRequestDTO,
  convertPricingResponseDTOToBatchPricingUIModel,
} from "../../../components/billing/batch-price-tabs.component";
import { PriceByJobStepUIModel } from "../../../components/billing/price-by-job.component";
import {
  PriceByType,
  PricingRequestDTO,
} from "services/label-service/dtos/batch-pricing.dto";
import { useBatchTaskContext } from "../tasks/batch-tasks.context";
import {
  BatchEstimationPriceComponent,
  BatchEstimationPriceUIModel,
} from "./components/batch-estimatation-price.component";
import {
  loadBatchDataAsync,
  updateBatchManagementAsync,
} from "store/customer/batch/batch.thunk";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { PaymentService } from "services/payment";
import { selectPaymentCurrencySelectOptions } from "store/common/payment/payment.selectors";
import { PricingCalculationResponseDTO } from "services/payment/payment.dto";
import { convertFromCalculationResponseToBatchEstimationPriceUIModel } from "services/payment/payment.mappers";
import { selectProjectTotalTasks } from "store/customer/batch/batch.selectors";
import { useBatchDetailContext } from "../../context/batch-detail.context";
import * as Sentry from "@sentry/react";

export const BatchPricePage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { batch, batchInstructions, pricing } = useBatchDetailContext();
  const [batchPriceUIModel, setBatchPricingUIModel] =
    useState<BatchPricingUIModel>();
  useBatchTaskContext();
  const totalTask = useAppSelector(selectProjectTotalTasks);
  const currencyOptions = useAppSelector(selectPaymentCurrencySelectOptions);

  const [actualResult, setActualResult] = useState<BatchEstimationPriceUIModel>(
    () => {
      let currencyCode = "USD";
      if (currencyOptions.length > 0) {
        currencyCode = currencyOptions[0].label;
      }

      return {
        totalJob: "-",
        estimateCost: "-",
        fees: "-",
        feeRatio: "-",
        total: "-",
        currencyCode,
      };
    }
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isChangedCalculating, setIsChangedCalculating] = useState(true);

  useEffect(() => {
    if (currencyOptions.length <= 0) return;
    if (!batchInstructions) return;
    if (!totalTask) return;
    if (batchPriceUIModel) return;

    let defaultBatchPrice: BatchPricingUIModel = {
      priceBy: PriceByType.BY_JOB,
      perTask: 0,
      selectedCurrencyOption: currencyOptions[0],
      byJobSteps: [],
    };

    // fill from batch data
    if (pricing) {
      const res = convertPricingResponseDTOToBatchPricingUIModel(pricing);
      defaultBatchPrice = { ...res };
    }

    const byJobSteps: PriceByJobStepUIModel[] = [];
    let stepIndex = 0;
    for (const instruction of batchInstructions) {
      const { id, name, roundNumber } = instruction;
      if (roundNumber < 1) continue;
      const byJobStep: PriceByJobStepUIModel = {
        stepTitle: name,
        id: id,
        rounds: [],
        required: !!!instruction.condition, // for calculating
      };

      for (let roundIndex = 1; roundIndex <= roundNumber; roundIndex++) {
        let pricePerJob = 0;

        // fill pricePerJob from batch data
        if (pricing && stepIndex < defaultBatchPrice.byJobSteps.length) {
          const byJobStep = defaultBatchPrice.byJobSteps[stepIndex];
          if (roundIndex - 1 < byJobStep.rounds.length) {
            pricePerJob = byJobStep.rounds[roundIndex - 1].pricePerJob;
          }
        }

        byJobStep.rounds.push({
          index: roundIndex,
          pricePerJob,
          totalJob: totalTask,
        });
      }
      byJobSteps.push(byJobStep);
      stepIndex++;
    }
    defaultBatchPrice.byJobSteps = byJobSteps;
    defaultBatchPrice.totalTask = totalTask;

    setBatchPricingUIModel(defaultBatchPrice);
  }, [
    batchInstructions,
    pricing,
    totalTask,
    currencyOptions,
    batchPriceUIModel,
  ]);

  const handleBathcPricingChanged = (v: BatchPricingUIModel) => {
    setBatchPricingUIModel(v);
    setIsChanged(true);
    setIsChangedCalculating(true);
    const currencyCode = v.selectedCurrencyOption.label;
    setActualResult({
      ...actualResult,
      currencyCode,
    });
  };

  const handleSave = async () => {
    if (!batch || isUpdating) return;
    if (!batchPriceUIModel) return;

    try {
      setIsUpdating(true);
      let pricing: PricingRequestDTO =
        convertBatchPricingUIModelToPricingRequestDTO(batchPriceUIModel);

      const updateBatchData = {
        batchId: batch.id,
        payload: { pricing },
      };

      const response = await dispatch(
        updateBatchManagementAsync(updateBatchData)
      );
      handleThunkRejected(response);
      const fetchResponse = await dispatch(
        loadBatchDataAsync(batch.id.toString())
      );
      handleThunkRejected(fetchResponse);
      dispatch(enqueueSuccessNotification(t("common:textUpdatedSuccess")));
    } catch (err: any) {
      Sentry.captureException(err);
      const errMessage = err.message || t("common:textUpdatedFailed");
      dispatch(enqueueErrorNotification(errMessage));
    } finally {
      setIsUpdating(false);
      setIsChanged(false);
    }
  };

  const handleCalculate = async () => {
    if (!batch || isUpdating) return;
    if (!batchPriceUIModel) return;

    try {
      setIsCalculating(true);
      let payload: PricingRequestDTO =
        convertBatchPricingUIModelToPricingCalculationRequestDTO(
          batchPriceUIModel
        );
      const response = await PaymentService.calculate(payload);
      handleThunkRejected(response);

      const data: PricingCalculationResponseDTO = response.data;
      const uiData =
        convertFromCalculationResponseToBatchEstimationPriceUIModel(
          data,
          batchPriceUIModel.selectedCurrencyOption.label
        );
      setActualResult(uiData);

      // dispatch(enqueueSuccessNotification(t("common:textSuccess")));
    } catch (err: any) {
      Sentry.captureException(err);
      const errMessage = err.message || t("common:textFailed");
      dispatch(enqueueErrorNotification(errMessage));
    } finally {
      setIsCalculating(false);
      setIsChangedCalculating(false);
    }
  };

  return (
    <div>
      {batchPriceUIModel && (
        <div className="grid w-full grid-cols-12 gap-6 pt-6">
          <div className="col-span-8 p-4 bg-white border rounded">
            <div className="flex flex-col gap-4">
              <div className="flex flex-row-reverse items-center w-full gap-4">
                <button
                  className="button-primary disabled:opacity-50"
                  onClick={handleCalculate}
                  disabled={isCalculating || !isChangedCalculating}
                >
                  Calculate
                </button>
                <button
                  className="button-primary disabled:opacity-50"
                  onClick={handleSave}
                  disabled={!isChanged || isUpdating}
                >
                  Save
                </button>
              </div>
              <div className="overflow-x-scroll">
                <BatchPriceTabsComponent
                  value={batchPriceUIModel}
                  onValueChanged={handleBathcPricingChanged}
                  selectTarget={document.body}
                />
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <BatchEstimationPriceComponent
              title={"Estimation price"}
              data={actualResult}
            />
          </div>
        </div>
      )}
    </div>
  );
};
