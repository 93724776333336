import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { VBTabs } from "components/common/vb-tabs/vb-tabs.component";
import "react-datepicker/dist/react-datepicker.css";
import { IconCalendar, IconSearch } from "components/common/vb-icon.component";
import {
  DateRange,
  useDateFilters,
} from "../../labeler-projects/hooks/use-date-filters.hook";
import { LabelerReportByProject } from "./labeler-report-by-project.component";
import { LabelerReportByDate } from "./labeler-report-by-date.component";
import { LabelerReportByJob } from "./labeler-report-by-job.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { SingleValue } from "react-select";

export const IndividualReport = () => {
  const { t } = useTranslation();
  const {
    fromDate,
    toDate,
    fromDateSearch,
    toDateSearch,
    setFromDate,
    setToDate,
    setFromDateSearch,
    setToDateSearch,
    dateRange,
    dateRanges,
    setDateRange,
  } = useDateFilters();
  const [showSearch, setShowSearch] = useState(true);

  const individualReportTabs = [
    {
      id: "by_date",
      name: "By Date",
    },
    {
      id: "by_project",
      name: "By Project",
    },
    {
      id: "by_job",
      name: "By Job",
    },
  ];
  const [activeTab, setActiveTab] = useState(individualReportTabs[0].id);

  function handleSelectDateRange(option: SingleValue<any>) {
    setDateRange(option.value as DateRange);
  }

  const handleFromDateChange = (date: Date) => {
    setFromDateSearch(date);
    setDateRange(DateRange.CUSTOM);
  };

  const handleToDateChange = (date: Date) => {
    setToDateSearch(date);
    setDateRange(DateRange.CUSTOM);
  };

  const handleSearch = () => {
    setFromDate(fromDateSearch);
    setToDate(toDateSearch);
    setShowSearch(false);
  };

  useEffect(() => {
    setShowSearch(true);
  }, [fromDateSearch, toDateSearch]);

  return (
    <div className="w-full px-6 pt-6 h-full flex flex-col">
      <h1 className="text-lg font-bold text-warning-500">
        {t("labelerhome:individualReport.title")}
      </h1>
      <div className="flex flex-wrap items-center gap-6 py-6">
        <VBSelectComponent
          header={t("labelerhome:individualReport.textSeletectRange")}
          headerClassName="font-semibold text-base"
          className="w-48 bg-white focus:outline-none"
          onChange={handleSelectDateRange}
          value={dateRanges.find((option) => option.value === dateRange)}
          options={dateRanges}
        />

        <div className="w-48">
          <label className="font-semibold">
            {t("labelerhome:individualReport.textFromDate")}
          </label>
          <div className="flex items-center w-full h-9 gap-2 px-4 mt-2 overflow-hidden bg-white border rounded border-background-300 focus-within:outline-blue">
            <IconCalendar className="flex-none w-5 h-5" />
            <DatePicker
              className="flex-1 focus:outline-none"
              selected={fromDateSearch}
              onChange={handleFromDateChange}
              dateFormat="dd/LLL/yyyy"
              maxDate={toDateSearch}
            />
          </div>
        </div>
        <div className="w-48">
          <label className="font-semibold">
            {t("labelerhome:individualReport.textToDate")}
          </label>
          <div className="flex items-center w-full h-9 gap-2 px-4 mt-2 overflow-hidden bg-white border rounded border-background-300 focus-within:outline-blue">
            <IconCalendar className="flex-none w-5 h-5" />
            <DatePicker
              className="flex-1 focus:outline-none"
              selected={toDateSearch}
              onChange={handleToDateChange}
              dateFormat="dd/LLL/yyyy"
              maxDate={new Date()}
              minDate={fromDateSearch}
            />
          </div>
        </div>
        <div className="self-end">
          <button
            className="flex items-center gap-2 px-3 mt-2 text-white rounded bg-warning-500 h-9 disabled:opacity-50"
            onClick={handleSearch}
            disabled={!showSearch}
          >
            <span>{t("common:buttonSearch")}</span>
            <IconSearch className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div>
        <VBTabs
          tabs={individualReportTabs}
          activeTab={activeTab}
          onSelect={setActiveTab}
          activeClass="px-4 py-2 border-warning-500 text-warning-500"
        />
      </div>
      <div className="py-4 flex-auto">
        {activeTab === "by_date" && (
          <LabelerReportByDate fromDate={fromDate} toDate={toDate} />
        )}
        {activeTab === "by_project" && (
          <LabelerReportByProject fromDate={fromDate} toDate={toDate} />
        )}
        {activeTab === "by_job" && (
          <LabelerReportByJob dateFilter={{ fromDate, toDate }} />
        )}
      </div>
    </div>
  );
};
