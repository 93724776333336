import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import { useProfileBills } from "./profile-bills.hooks"
import { ProfileBillsTable } from "./profile-bills.table";


export const ProfileBills = () => {
  const { 
    isLoading,
    rows,
    totalRow,
    filter,
    setFilterField,
    setFilterFields,
  } = useProfileBills();

  return (
    <div className="w-full h-full flex flex-col gap-4">
      <div className="flex-auto">
        <ProfileBillsTable 
          rows={rows}
          filter={filter}
          setFilterField={setFilterField}
          setFilterFields={setFilterFields}
          isLoading={isLoading}
        />
      </div>

      {filter && (
        <div className="flex-none">
          <GridPagination
            page={filter.page}
            pageSize={filter.pageSize}
            pageSizeOptions={[10, 15, 20, 25, 50, 75, 100]}
            onPageSizeChange={v => setFilterField("pageSize", v)}
            onPageChange={v => setFilterField("page", v)}
            totalItems={totalRow}
          />
        </div>
      )}
    </div>
  )
}