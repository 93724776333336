import { Tooltip } from "@material-ui/core";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useAppDispatch } from "hooks/use-redux";
import { Gps, Maximize1 } from "iconsax-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { runMciFilter } from "../itk_pipelines";
import { SlicingMode } from "../vtk_import";
import {
  EditorLabel,
  EditorLabelStatus,
  PREVIEW_MASK,
  PREVIEW_MASK_OPACITY,
  ThreeDEditorEvents,
  TOOL_FILL_BETWEEN_SLICES,
} from "./three-d-editor.models";
import { useThreeDEditorContext } from "./three-d-editor.provider";
import * as Sentry from "@sentry/react";

const AXIS_OPTIONS = [
  { label: "All axises", value: SlicingMode.NONE.toString() },
  {
    value: SlicingMode.I.toString(),
    label: "Sagittal (LR)",
  },
  {
    value: SlicingMode.J.toString(),
    label: "Coronal (AP)",
  },
  {
    value: SlicingMode.K.toString(),
    label: "Axial (SI)",
  },
];

export const ThreeDEditorFilterFbts = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    currentLayout,
    windowsDataMap,
    editorContext,
    activeLabel,
    activeTool,
    fbtsIsAutoUpdate,
    setFbtsIsAutoUpdate,
    fbtsIsInitialized,
    setFbtsIsInitialized,
    fbtsIsProcessing,
    setFbtsIsProcessing,
    getCurrentLabels,
    setCurrentLabels,
    changeLabelMapMask,
    updateLabelMapMaskFromFillBetweenSlices,
    renderAllWindows,
  } = useThreeDEditorContext();

  const currentLabelsRef = useRef(getCurrentLabels());
  const activeLabelRef = useRef(activeLabel);
  const fbtsIsInitializedRef = useRef(fbtsIsInitialized);
  const fbtsIsAutoUpdateRef = useRef(fbtsIsAutoUpdate);
  const fbtsIsProcessingRef = useRef(fbtsIsProcessing);
  const [selectedAxis, setSelectedAxis] = useState(() => {
    if (currentLayout.numWindow !== 1) {
      return SlicingMode.NONE;
    }
    let id = SlicingMode.NONE;
    const keys = Object.keys(windowsDataMap);
    for (const key of keys) {
      const data = windowsDataMap[parseInt(key)];
      if (data && data.order === 1) {
        id = data.id;
        break;
      }
    }
    return id;
  });
  const startSliceRef = useRef<HTMLInputElement>(null);
  const endSliceRef = useRef<HTMLInputElement>(null);

  function handleAxisChange(option: any) {
    if (option === null || option === undefined) {
      setSelectedAxis(SlicingMode.NONE);
    } else {
      setSelectedAxis(parseInt(option.value));
    }
  }

  useEffect(() => {
    let axis = SlicingMode.NONE;
    if (currentLayout.numWindow !== 1) {
      axis = SlicingMode.NONE;
    } else {
      const keys = Object.keys(windowsDataMap);
      for (const key of keys) {
        const data = windowsDataMap[parseInt(key)];
        if (data && data.order === 1) {
          axis = data.id;
          break;
        }
      }
    }
    setSelectedAxis(axis);
  }, [currentLayout.numWindow, windowsDataMap]);
  useEffect(() => {
    currentLabelsRef.current = getCurrentLabels();
  }, [getCurrentLabels]);

  useEffect(() => {
    activeLabelRef.current = activeLabel;
  }, [activeLabel]);

  useEffect(() => {
    fbtsIsInitializedRef.current = fbtsIsInitialized;
  }, [fbtsIsInitialized]);

  useEffect(() => {
    fbtsIsAutoUpdateRef.current = fbtsIsAutoUpdate;
  }, [fbtsIsAutoUpdate]);

  useEffect(() => {
    fbtsIsProcessingRef.current = fbtsIsProcessing;
  }, [fbtsIsProcessing]);

  const runFillBetweenSlices = useCallback(async () => {
    if (selectedAxis !== -1 && !startSliceRef.current?.value) {
      startSliceRef.current?.focus();
      dispatch(enqueueErrorNotification("Start slice is required"));
      return;
    }
    if (selectedAxis !== -1 && !endSliceRef.current?.value) {
      endSliceRef.current?.focus();
      dispatch(enqueueErrorNotification("End slice is required"));
      return;
    }

    try {
      if (!editorContext || fbtsIsProcessingRef.current) return;
      setFbtsIsProcessing(true);
      const { painter } = editorContext;

      if (activeLabelRef.current.isNone) {
        dispatch(enqueueErrorNotification("No selected segment!"));
        setFbtsIsProcessing(false);
        return;
      }

      // Clear preview mask to 0 mask
      changeLabelMapMask(painter.getLabelMap(), PREVIEW_MASK, 0);

      let startSlice = parseInt(startSliceRef.current?.value ?? "0");
      let endSlice = parseInt(endSliceRef.current?.value ?? "0");

      const outVtkImage = await runMciFilter(
        painter.getLabelMap(),
        activeLabelRef.current.maskValue,
        selectedAxis,
        Math.min(startSlice, endSlice),
        Math.max(startSlice, endSlice)
      );

      updateLabelMapMaskFromFillBetweenSlices(
        painter.getLabelMap(),
        outVtkImage,
        activeLabelRef.current.maskValue,
        PREVIEW_MASK
      );

      painter.modified();

      const currentLabels = currentLabelsRef.current;
      const previewLabelExisted = currentLabels.find(
        (label) => label.maskValue === PREVIEW_MASK
      );

      if (previewLabelExisted) {
        const newLabels = currentLabels.map((label) => {
          if (label.isPreview) {
            return {
              ...label,
              labelOptionId: activeLabelRef.current.labelOptionId,
              name: activeLabelRef.current.name,
              color: activeLabelRef.current.color,
            };
          }
          return label;
        });
        setCurrentLabels(newLabels);
      } else {
        const previewLabel: EditorLabel = {
          id: PREVIEW_MASK,
          labelOptionId: activeLabelRef.current.labelOptionId,
          maskValue: PREVIEW_MASK,
          name: activeLabelRef.current.name,
          color: activeLabelRef.current.color,
          opacity: PREVIEW_MASK_OPACITY,
          keyBind: PREVIEW_MASK.toString(),
          status: EditorLabelStatus.FIXED,
          visibility: true,
          isPreview: true,
        };
        setCurrentLabels([...currentLabels, previewLabel]);
      }

      renderAllWindows();

      setFbtsIsInitialized(true);
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setFbtsIsProcessing(false);
    }
  }, [
    selectedAxis,
    changeLabelMapMask,
    dispatch,
    t,
    editorContext,
    setCurrentLabels,
    renderAllWindows,
    setFbtsIsInitialized,
    setFbtsIsProcessing,
    updateLabelMapMaskFromFillBetweenSlices,
  ]);

  const runFillBetweenSlicesRef = useRef(runFillBetweenSlices);

  useEffect(() => {
    runFillBetweenSlicesRef.current = runFillBetweenSlices;
  }, [runFillBetweenSlices]);

  useEffect(() => {
    if (!editorContext) return;

    const handlePaintEndStroke = async () => {
      if (!fbtsIsAutoUpdateRef.current || !fbtsIsInitializedRef.current) return;
      runFillBetweenSlicesRef.current();
    };

    document.addEventListener(
      ThreeDEditorEvents.PAINT_END_STROKE,
      handlePaintEndStroke
    );

    return () => {
      document.removeEventListener(
        ThreeDEditorEvents.PAINT_END_STROKE,
        handlePaintEndStroke
      );
    };
  }, [editorContext]);

  if (activeTool?.id !== TOOL_FILL_BETWEEN_SLICES.id) return null;

  const handleClickInitialize = async () => {
    await runFillBetweenSlices();
  };

  const handleClickUpdate = async () => {
    await runFillBetweenSlices();
  };

  const updateLabelMapPreviewMask = async (mask: number) => {
    if (!editorContext || fbtsIsProcessing) return;
    setFbtsIsProcessing(true);
    const { painter } = editorContext;

    // Change preview mask to mask
    changeLabelMapMask(painter.getLabelMap(), PREVIEW_MASK, mask);
    painter.modified();

    const labels = getCurrentLabels();
    setCurrentLabels(labels.filter((label) => !!!label.isPreview));

    renderAllWindows();

    setFbtsIsInitialized(false);
    setFbtsIsProcessing(false);
  };

  const handleClickCancel = () => {
    updateLabelMapPreviewMask(0);
  };

  const handleClickApply = () => {
    updateLabelMapPreviewMask(activeLabel.maskValue);
  };

  const goToFromSlice = () => {
    if (!startSliceRef.current) return;
    const slice = parseInt(startSliceRef.current.value);
    if (!isNaN(slice)) goToSlice(slice);
  };

  const setFromSlice = () => {
    if (!startSliceRef.current) return;
    const currentSlice = getCurrentSlice();
    if (currentSlice === -1) return;
    startSliceRef.current.value = currentSlice;
  };

  const goToEndSlice = () => {
    if (!endSliceRef.current) return;
    const slice = parseInt(endSliceRef.current.value);
    if (!isNaN(slice)) goToSlice(slice);
  };

  const setEndSlice = () => {
    if (!endSliceRef.current) return -1;
    const currentSlice = getCurrentSlice();
    if (currentSlice === -1) return;
    endSliceRef.current.value = currentSlice;
  };

  const getCurrentSlice = () => {
    const { windowsSliceArray } = editorContext;
    const sliceArray = windowsSliceArray.find(
      (item: any) => item.axis === selectedAxis
    );
    if (!sliceArray) return -1;
    const { imageSlice } = sliceArray;
    const currentSlice = imageSlice.image.mapper.getSlice();
    return currentSlice;
  };

  const goToSlice = (slice: number) => {
    if (selectedAxis === -1) return;
    const eventData = { axis: selectedAxis, slice };
    const eventDetail = { detail: eventData };
    const event = new CustomEvent(
      ThreeDEditorEvents.COMMAND_GO_TO_SLICE,
      eventDetail
    );
    document.dispatchEvent(event);
  };

  return (
    <div className="flex flex-col gap-2 mt-2">
      <hr />
      <div className="relative flex flex-col gap-2">
        <p className="font-bold">Select Axis</p>
        <VBSelectComponent
          className="text-black"
          options={AXIS_OPTIONS}
          value={AXIS_OPTIONS.find((option) => {
            return option.value === selectedAxis.toString();
          })}
          isClearable
          placeholder="Preset"
          loseFocusAfterSelected
          onChange={handleAxisChange}
        />
        {selectedAxis !== -1 && (
          <div className="flex items-center w-full gap-4 py-1">
            <div className="w-full">
              <div className="flex items-center gap-1 pb-2 text-sm font-bold">
                <span>From slice</span>

                <Tooltip title="Go to slice">
                  <button onClick={goToFromSlice}>
                    <Gps size={16} />
                  </button>
                </Tooltip>

                <Tooltip title="From current slice">
                  <button onClick={setFromSlice}>
                    <Maximize1 size={16} />
                  </button>
                </Tooltip>
              </div>
              <input
                className="w-full px-2 py-1 text-blue-900 rounded"
                ref={startSliceRef}
                type="number"
                min={1}
                max={1000}
                placeholder="Start slice"
              />
            </div>
            <div className="w-full">
              <div className="flex items-center gap-1 pb-2 text-sm font-bold">
                <span>To slice</span>

                <Tooltip title="Go to slice">
                  <button onClick={goToEndSlice}>
                    <Gps size={16} />
                  </button>
                </Tooltip>

                <Tooltip title="From current slice">
                  <button onClick={setEndSlice}>
                    <Maximize1 size={16} />
                  </button>
                </Tooltip>
              </div>
              <input
                className="w-full px-2 py-1 text-blue-900 rounded"
                ref={endSliceRef}
                type="number"
                min={1}
                max={1000}
                placeholder="End slice"
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        {!fbtsIsInitialized && (
          <button
            className="button-secondary disabled:opacity-50"
            onClick={handleClickInitialize}
            disabled={fbtsIsProcessing || activeLabel.isNone}
          >
            Initialize
          </button>
        )}
        {fbtsIsInitialized && (
          <button
            className="button-secondary disabled:opacity-50"
            onClick={handleClickUpdate}
            disabled={fbtsIsProcessing}
          >
            Update
          </button>
        )}
        <div className="flex items-center gap-1">
          <input
            type="checkbox"
            checked={fbtsIsAutoUpdate}
            onChange={(e) => setFbtsIsAutoUpdate(e.target.checked)}
            disabled={fbtsIsProcessing}
          />
          <span>Auto-update</span>
        </div>
      </div>

      <div className="flex items-center w-full gap-4">
        <button
          className="button-text-secondary disabled:opacity-50"
          onClick={handleClickCancel}
          disabled={!fbtsIsInitialized || fbtsIsProcessing}
        >
          Cancel
        </button>

        <button
          className="button-text-secondary disabled:opacity-50"
          onClick={handleClickApply}
          disabled={!fbtsIsInitialized || fbtsIsProcessing}
        >
          Apply
        </button>
      </div>
    </div>
  );
};
