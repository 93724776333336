import { usePrevious } from "ahooks";
import { useAppDispatch } from "hooks/use-redux";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDataCleaningJobs } from "services/storage/apis/data-cleaning";
import { DataCleaningDTO } from "services/storage/dto/data-cleaning.dto";
import { RequestStatus } from "store/base/base.state";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import * as Sentry from "@sentry/react";


export const useDataCleaningJob = (
  batchId: number | null | undefined,
  datasetId: number | null | undefined,
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [dataCleaningJobs, setDataCleaningJobs] = useState<DataCleaningDTO[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [sort, setSort] = useState<string>("id,desc");
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.IDLE);
  const previousPage = usePrevious(page);
  const previousSize = usePrevious(size);
  const previousSort = usePrevious(sort);
  const [total, setTotal] = useState(0);

  const requestData = useCallback(
    async () => {
      const filterChanged = previousPage !== page || previousSize !== size || previousSort !== sort;
      if (!batchId || !datasetId || requestStatus === RequestStatus.LOADING ||
          (requestStatus !== RequestStatus.IDLE && !filterChanged)) return;
      setRequestStatus(RequestStatus.LOADING);
      try {
        let res = await getDataCleaningJobs({
          "batchId.equals": batchId.toString(),
          page: page - 1,
          size,
          sort,
        });
        setDataCleaningJobs(res.data);
        setTotal(res.headers["x-total-count"]);
        setRequestStatus(RequestStatus.SUCCESS);
      } catch (err: any) {
        Sentry.captureException(err);
        setRequestStatus(RequestStatus.FAILURE);
        dispatch(enqueueErrorNotification(t("common:loadDataFailed")));
      }
    },
    [ 
      batchId,
      datasetId,
      page,
      size,
      sort,
      previousPage,
      previousSize,
      previousSort,
      requestStatus,
      dispatch,
      t,
    ],
  )

  useEffect(() => {
    requestData();
  }, [requestData]);

  return {
    dataCleaningJobs,
    setDataCleaningJobs,
    requestStatus,
    page,
    size,
    setPage,
    setSize,
    setSort,
    total,
  }
}