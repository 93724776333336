/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 3rd July 2021 5:28:39 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

const LOG_ENABLE = process.env.NODE_ENV !== "production";

function log(...params: any[]) {
  if (!LOG_ENABLE) return;
  return console.log(...params);
}

function error(...params: any[]) {
  if (!LOG_ENABLE) return;
  return console.error(...params);
}

function info(...params: any[]) {
  if (!LOG_ENABLE) return;
  return console.info(...params);
}

export const Logger = {
  log,
  error,
  info,
};
