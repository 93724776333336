import { IEvent } from "fabric/fabric-impl";
import { FabricOverlay } from "./fabric-overlay";
import { FabricTool } from "./fabric-tool";
import { fabric } from "fabric";
import {
  createDistanceTextForObject,
  createLineFromPoints,
  updateDistanceTextPosForObject,
} from "./fabric-object.utils";

export class FabricToolLine extends FabricTool {
  private currentLine: fabric.Line | undefined;
  private isDrawing: boolean;

  constructor(fabricOverlay: FabricOverlay, osdViewer: OpenSeadragon.Viewer) {
    super(fabricOverlay, osdViewer);

    this.isDrawing = false;
  }

  handleMouseDown(
    fabricAbsX: number,
    fabricAbsY: number,
    e: IEvent<Event>
  ): void {
    const line = createLineFromPoints({
      points: [
        { x: fabricAbsX, y: fabricAbsY },
        { x: fabricAbsX, y: fabricAbsY },
      ],
      isCreateDistance: false,
      createUtilLabel: false,
    });

    line.set({
      strokeWidth:
        this.fabricOverlay.objectStrokeWidth / this.fabricCanvas.getZoom(),
    });
    // Need to add line first in order to render correctly
    this.fabricCanvas.add(line);

    createDistanceTextForObject(line);

    this.updateObjectAdditionalData(line);

    this.currentLine = line;
  }

  handleMouseMove(
    fabricAbsX: number,
    fabricAbsY: number,
    e: IEvent<Event>
  ): void {
    if (!this.currentLine) return;
    this.isDrawing = true;
    this.currentLine.set({
      x2: fabricAbsX,
      y2: fabricAbsY,
    });
    
    updateDistanceTextPosForObject(this.currentLine);
    this.currentLine.setCoords();

    this.fabricCanvas.renderAll();
  }

  handleMouseUp(
    fabricAbsX: number,
    fabricAbsY: number,
    e: IEvent<Event>
  ): void {
    if (!this.currentLine) return;

    if (!this.isDrawing) {
      this.fabricCanvas.remove(this.currentLine);
    } else {
      this.currentLine.bringToFront();
      this.addUtilLabel(this.currentLine);
      this.addDistanceLabel(this.currentLine);
      this.sendObjectAddedEvent(this.currentLine);
    }

    this.currentLine = undefined;
    this.isDrawing = false;
  }
}
