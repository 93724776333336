/*
 * File: use-workspace.hook.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 1st September 2021 2:38:31 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */
import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  selectCurrentWorkspace,
  selectCurrentWorkspaceId,
  selectUserWorkspaceLoaded,
  selectUserWorkspaceLoading,
  selectUserWorkspaceRequestStatus,
  selectUserWorkspaces,
} from "store/common/user-workspace/user-workspace.selectors";
import { loadWorkspacesAsync } from "store/common/user-workspace/user-workspace.thunk";
import { RequestStatus } from "store/base/base.state";

export const useWorkspaces = () => {
  const dispatch = useAppDispatch();
  const workspaces = useAppSelector(selectUserWorkspaces);
  const workspace = useAppSelector(selectCurrentWorkspace);
  const workspaceId = useAppSelector(selectCurrentWorkspaceId);
  const loaded = useAppSelector(selectUserWorkspaceLoaded);
  const loading = useAppSelector(selectUserWorkspaceLoading);
  const requestStatus = useAppSelector(selectUserWorkspaceRequestStatus);

  const requestData = useCallback(async () => {
    return dispatch(loadWorkspacesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (requestStatus === RequestStatus.IDLE) {
      requestData();
    }
  }, [requestData, requestStatus]);

  return { workspaces, workspace, workspaceId, loading, loaded, requestData };
};
