/*
 * File: exported-datasets.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 1st September 2021 3:01:24 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { StorageService } from "services/storage-service";
import {
  requestPending,
  requestRejected,
  requestFulfilled,
  setEntites,
  removeEntity,
} from "store/base/base.reducer";
import { ExportedDatasetState } from "./exported-datasets.state";
import * as Sentry from "@sentry/react";

export enum ExportedDatasetsThunk {
  LOAD_EXPORTED_DATASETS = "exportedDatasets/loadExportedDatasetsAsync",
  DELETE_EXPORTED_DATASET = "exportedDatasets/deleteExportedDatasetAsync",
  DELETE_EXPORTED_DATASET_VERSION = "exportedDatasets/deleteExportedDatasetVersionAsync",
}

export const loadExportedDatasetsAsync = createAsyncThunk(
  ExportedDatasetsThunk.LOAD_EXPORTED_DATASETS,
  async () => {
    const exportedDatasets =
      await StorageService.getDatasetService().getExportedDatasets();

    // join exportdataset versions
    const promises: Promise<any>[] = [];

    for (const dataset of exportedDatasets) {
      promises.push(
        StorageService.getDatasetVersionService().getItems({
          "datasetId.equals": dataset.id.toString(),
        })
      );
    }

    try {
      const res = await Promise.all(promises);
      for (let i = 0; i < exportedDatasets.length; i++) {
        exportedDatasets[i].versions = res[i].data;
      }
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
    }

    return exportedDatasets;
  }
);

export const deleteExportedDatasetAsync = createAsyncThunk(
  ExportedDatasetsThunk.DELETE_EXPORTED_DATASET,
  async (datasetId: number) => {
    await StorageService.getDatasetService().deleteItem(datasetId);
    return datasetId;
  }
);

export const deleteExportedDatasetVersionAsync = createAsyncThunk(
  ExportedDatasetsThunk.DELETE_EXPORTED_DATASET_VERSION,
  async (datasetVersionId: number) => {
    await StorageService.getDatasetVersionService().deleteItem(
      datasetVersionId
    );
    return datasetVersionId;
  }
);

export const exportedDatasetsReducerBuilder = (
  builder: ActionReducerMapBuilder<ExportedDatasetState>
): ActionReducerMapBuilder<ExportedDatasetState> => {
  return builder
    .addCase(loadExportedDatasetsAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(loadExportedDatasetsAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(loadExportedDatasetsAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      if (action.payload) setEntites(state, action.payload);
    })

    .addCase(deleteExportedDatasetAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(deleteExportedDatasetAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(deleteExportedDatasetAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      if (action.payload) removeEntity(state, action.payload);
    })

    .addCase(deleteExportedDatasetVersionAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(deleteExportedDatasetVersionAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(deleteExportedDatasetVersionAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      if (action.payload > 0) {
        for (const id of state.ids) {
          const dataset = state.entities[id];
          dataset.versions = (dataset.versions || []).filter(
            (version) => version.id !== action.payload
          );
        }
      }
    });
};
