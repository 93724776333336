import { SlicingMode } from "../vtk_import";

export interface EditorLabelOptionAttribute {
  type: string;
  id: number;
  name: string;
  defaultValue: string;
  ranges?: number[];
  required: boolean;
  options?: string[];
}
export interface EditorLabelOption {
  id: number; // should be observation id, we use id here to seperate from domain knowledge
  name: string;
  color: string;
  maskId?: number;
  attributes?: EditorLabelOptionAttribute[];
}

export interface EditorLabelAttribute {
  id: number;
  value: string[];
}

export enum EditorLabelStatus {
  FIXED = "FIXED",
  EDIT = "EDIT",
}
export interface EditorLabel {
  id: number;
  labelOptionId: number;
  maskValue: number;
  name: string;
  color: string;
  opacity: number;
  keyBind: string;
  status: string;
  visibility: boolean;
  // preview label (mask) when using fill between slices
  isPreview?: boolean;
  isNone?: boolean;
  attributes?: EditorLabelAttribute[];
  estimatedSize?: number;
}

export const EDITOR_LABEL_NONE: EditorLabel = {
  id: 0,
  labelOptionId: 0,
  name: "None",
  color: "#FFFFFF",
  opacity: 0,
  maskValue: 0,
  keyBind: "0",
  status: EditorLabelStatus.FIXED,
  visibility: true,
  isNone: true,
};

// Tools
export enum EditorMeasurementType {
  distance = "distance",
  angle = "angle",
}
export interface EditorTool {
  id: number;
  groupId?: number;
  isEraser?: boolean;
  isDrawing?: boolean;
  isMeasurement?: boolean;
  measurementType?: EditorMeasurementType;
  name: string;
  tooltipName?: string;
  keyBind: string;
}

export const TOOL_SEGMENT_BRUSH: EditorTool = {
  id: 0,
  name: "Paint",
  keyBind: "p",
  groupId: 1,
  isDrawing: true,
};
export const TOOL_SEGMENT_POLY: EditorTool = {
  id: 1,
  name: "Draw",
  keyBind: "d",
  groupId: 2,
  isDrawing: true,
};

export const TOOL_SEGMENT_BRUSH_ERASER: EditorTool = {
  id: 2,
  name: "Eraser",
  tooltipName: "Paint eraser",
  keyBind: "[",
  groupId: 1,
  isDrawing: true,
  isEraser: true,
};
export const TOOL_SEGMENT_POLY_ERASER: EditorTool = {
  id: 3,
  name: "Eraser",
  tooltipName: "Draw eraser",
  keyBind: "]",
  groupId: 2,
  isDrawing: true,
  isEraser: true,
};

export const TOOL_NAVIGATION_CROSS_HAIR: EditorTool = {
  id: 4,
  name: "Cross",
  keyBind: "c",
};
export const TOOL_ADJUST_WINDOW_COLOR_RECTANGLE: EditorTool = {
  id: 5,
  name: "Adjust",
  keyBind: "a",
};
export const TOOL_FILL_BETWEEN_SLICES: EditorTool = {
  id: 6,
  name: "Fill between slices",
  keyBind: "b",
};
export const TOOL_DISTANCE: EditorTool = {
  id: 7,
  name: "Distance",
  keyBind: "i",
  isMeasurement: true,
  measurementType: EditorMeasurementType.distance,
};
export const TOOL_ANGLE: EditorTool = {
  id: 8,
  name: "Angle",
  keyBind: "g",
  isMeasurement: true,
  measurementType: EditorMeasurementType.angle,
};

export const TOOL_SEGMETATION_SMOOTH_EFFECT: EditorTool = {
  id: 9,
  name: "Smoothing",
  keyBind: "s",
};

export const TOOL_LAYOUT: EditorTool = {
  id: 10,
  name: "Layout",
  keyBind: "l",
};

export const EDITOR_TOOLS: EditorTool[] = [
  TOOL_SEGMENT_BRUSH,
  TOOL_SEGMENT_POLY,
  TOOL_SEGMENT_BRUSH_ERASER,
  TOOL_SEGMENT_POLY_ERASER,
  TOOL_NAVIGATION_CROSS_HAIR,
  TOOL_ADJUST_WINDOW_COLOR_RECTANGLE,
  TOOL_DISTANCE,
  TOOL_ANGLE,
  TOOL_FILL_BETWEEN_SLICES,
  TOOL_SEGMETATION_SMOOTH_EFFECT,
  TOOL_LAYOUT,
];

// For choosing from list of label map (previous results)
export interface LabelMapOption {
  displayName: string;
  // we don"t save the vtkImageData for labelMapOption
  // we only use blob for optimizing memory (trade-off speed vs space) we choose space here
  labelMapBlob: Blob | undefined;
  labels: EditorLabel[];
  metadata: EditorMetadataRowModel[];
}

// Window/color level presets
export const WINDOW_PRESETS = [
  {
    label: "Bone",
    windowWidth: 2000,
    windowLevel: 500,
    shortcutKey: "1",
  },
  {
    label: "Lung",
    windowWidth: 1600,
    windowLevel: -600,
    shortcutKey: "2",
  },
  {
    label: "Abdomen",
    windowWidth: 400,
    windowLevel: 40,
    shortcutKey: "3",
  },
  {
    label: "Brain",
    windowWidth: 70,
    windowLevel: 30,
    shortcutKey: "4",
  },
  {
    label: "Soft tissue",
    windowWidth: 350,
    windowLevel: 50,
    shortcutKey: "5",
  },
  {
    label: "Liver",
    windowWidth: 160,
    windowLevel: 60,
    shortcutKey: "6",
  },
  {
    label: "Mediastinum",
    windowWidth: 500,
    windowLevel: 50,
    shortcutKey: "7",
  },
  {
    label: "Angio",
    windowWidth: 600,
    windowLevel: 170,
    shortcutKey: "8",
  },
];

export const DEFAULT_WL = 350;
export const DEFAULT_CL = 50;

// Layouts
export interface EditorLayout {
  id: number;
  name: string;
  gridTemplateColumns: string;
  gridTemplateRows: string;
  numWindow: number;
}

export const EDITOR_LAYOUT_4: EditorLayout = {
  id: 0,
  name: "4",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridTemplateRows: "50% 50%",
  numWindow: 4,
};

export const EDITOR_LAYOUT_2_UD: EditorLayout = {
  id: 1,
  name: "2 UD",
  gridTemplateColumns: "repeat(1, 1fr)",
  gridTemplateRows: "50% 50%",
  numWindow: 2,
};

export const EDITOR_LAYOUT_2_LR: EditorLayout = {
  id: 2,
  name: "2 LR",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridTemplateRows: "100% 100%",
  numWindow: 2,
};

export const EDITOR_LAYOUT_1: EditorLayout = {
  id: 3,
  name: "1",
  gridTemplateColumns: "repeat(1, 1fr)",
  gridTemplateRows: "100%",
  numWindow: 1,
};

export const EDITOR_LAYOUT_4_1: EditorLayout = {
  id: 4,
  name: "1",
  gridTemplateColumns: "1fr 1fr 1fr",
  gridTemplateRows: "4fr 1fr",
  numWindow: 4,
};

export const EDITOR_LAYOUTS: EditorLayout[] = [
  EDITOR_LAYOUT_4,
  EDITOR_LAYOUT_1,
  EDITOR_LAYOUT_2_UD,
  EDITOR_LAYOUT_2_LR,
  EDITOR_LAYOUT_4_1,
];

export const EDITOR_DEFAULT_LAYOUT_ORDERS: Record<number, number> = {
  [SlicingMode.I]: 1,
  [SlicingMode.J]: 2,
  [SlicingMode.K]: 3,
  3: 4,
};

export interface EditorWindowData {
  id: number;
  name: string;
  order: number;
  visibility: boolean;
  otherWindowOptions: { label: string; value: EditorWindowData }[];
  otherWindowOptionsRef: any;
}

// Events
export enum ThreeDEditorEvents {
  COMMAND_AFTER_SWAP_WINDOW_LEAVE = "ThreeDEditorEvents_COMMAND_AFTER_SWAP_WINDOW_LEAVE",
  COMMAND_AFTER_SWAP_WINDOW_ENTER = "ThreeDEditorEvents_COMMAND_AFTER_SWAP_WINDOW_ENTER",
  COMMAND_CHECK_SELF_ACTIVE_WINDOW = "ThreeDEditorEvents_COMMAND_CHECK_SELF_ACTIVE_WINDOW",
  COMMAND_FORCE_WINDOW_LEAVE = "ThreeDEditorEvents_COMMAND_FORCE_WINDOW_LEAVE",
  COMMAND_GO_TO_SLICE = "ThreeDEditorEvents_COMMAND_GO_TO_SLICE",
  COMMAND_DELETE_MEASUREMENT = "ThreeDEditorEvents_COMMAND_DELETE_MEASUREMENT",
  COMMAND_SAVE_MEASUREMENTS = "ThreeDEditorEvents_COMMAND_SAVE_MEASUREMENTS",
  LAYOUT_CHANGE_SIZE = "ThreeDEditorEvents_LAYOUT_CHANGE_SIZE",
  PAINT_END_STROKE = "ThreeDEditorEvents_PAINT_END_STROKE",
  MEASUREMENT_FINALIZED = "ThreeDEditorEvents_MEASUREMENT_FINALIZED",
  MEASUREMENT_CHANGED = "ThreeDEditorEvents_MEASUREMENT_CHANGED",
}
export interface EditorEventSwapWindow {
  fromWindow: EditorWindowData;
  toWindow: EditorWindowData;
}
export interface EditorEventForceWindowLeave {
  windowToLeave: EditorWindowData;
}
export interface EditorEventGoToSlice {
  windowId?: number;
  axis?: number;
  slice: number;
}
export interface EditorEventSaveMeasurements {
  contextId: number | string;
  measurementsData: EditorMeasurementData[];
}

export const MAX_NUM_MASK = 253;
export const PREVIEW_MASK = 254;
export const PREVIEW_MASK_OPACITY = 30;
export const PREVIEW_SMOOTHING_MASK_OPACITY = 100;

// Metadata
export interface EditorMetadataRowModel {
  id: number; // should be observationId, we use id here to seperate from domain knowledge
  name: string;
  attributesVisibility: boolean;
  attributes: EditorMetadataRowAttributeModel[];
}

export interface EditorMetadataRowAttributeModel {
  id: number; // should be attribute id
  type: string;
  name: string;
  values: string[];
  options: string[];
  required: boolean;
}

export enum EditorMetadataRowAttributeType {
  RADIO = "RADIO",
  SELECT = "SELECT",
  TEXT = "TEXT",
  NUMBER = "NUMBER",
}

// measurements
export interface EditorMeasurement {
  uuid: string;
  type: EditorMeasurementType; // distance, angle, ...
  note: string;
  widget: any; // vtk widget
  viewWidget: any; // vtk view widget
  windowId: number; // the window that contains the measurement
  slice: number; // the slice index that contains the measurement
  isFinalized: boolean; // final means added to the measuremnet list
}
// Use this interface for serialization/deserialization measurements
// in case you want to save/load measurements.
export interface EditorMeasurementData {
  type: EditorMeasurementType;
  note: string;
  slice: number;
  windowId: number;
  handlePositions: number[][];
}
