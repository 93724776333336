/*
 * File: use-alert-save-work.hook.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 18th August 2022 10:15:55 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useEffect } from "react";

export default function useAlertSaveWork() {
  function alertSaveWork(event: BeforeUnloadEvent) {
    event.preventDefault();
    if (event) event.returnValue = "";
    return "";
  }

  useEffect(() => {
    window.addEventListener("beforeunload", alertSaveWork);
    return () => {
      window.removeEventListener("beforeunload", alertSaveWork);
    };
  }, []);
}
