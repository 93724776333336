import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { useAppDispatch } from "hooks/use-redux";
import { useState } from "react";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { useAppSelector } from "hooks/use-redux";
import { selectBatchInfo } from "store/customer/batch/batch.selectors";
import { useDatasetDetailContext } from "pages/customer/datasets/dataset-detail/dataset-detail.context";
import { BatchManagementService } from "services/label-service";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import * as Sentry from "@sentry/react";


export const DeleteFilesBatchDialog = () => {
  const { t } = useTranslation();
  const { requestData } = useDatasetContext();
  const currentBatch = useAppSelector(selectBatchInfo);
  const { deletingItemIds, setDeletingItemIds } = useDatasetDetailContext();
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);

  function handleClose() {
    setDeletingItemIds([]);
  }

  async function handleDeleteFile() {
    if (processing) return;
    setProcessing(true);
    if (!currentBatch) return;
    if(deletingItemIds.length === 0) return;

    try {
      const response = await BatchManagementService.deleteFiles(
        currentBatch,
        deletingItemIds,
      );
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      requestData();
    } catch (error: any){
      Sentry.captureException(error);
      console.log(error);
      dispatch(enqueueErrorNotification(t("common:textDeletedFailed")));
    } finally {
      handleClose();
      setProcessing(false);
    }
  }

  return (
    <Dialog
      open={deletingItemIds.length > 0}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('dataset:deletefiles.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('dataset:deletefiles.textWarn', {numFile: deletingItemIds.length})}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('common:buttonCancel')}
        </Button>
        <Button
          onClick={handleDeleteFile}
          color="primary"
          variant="contained"
          autoFocus
          disabled={processing}
        >
          {t('common:buttonDelete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
