/*
 * File: dataset-provider.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 31st August 2021 5:18:21 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useDetectChange } from "hooks/use-detect-change";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { SearchParams } from "models/common/search-params";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  loadDatasetByFilterAsync,
  loadTagsAsync,
} from "store/customer/dataset/dataset.slice";
import { Logger } from "utilities/logger";
import {
  DatasetContext,
  DatasetContextState,
  DatasetFilter,
} from "./dataset-context";
import { selectIsUploadedZip } from "store/customer/task-runner/task-runner.selectors";
import * as Sentry from "@sentry/react";
interface DatasetProviderProps {
  children: React.ReactNode | React.ReactNode[] | null;
}

export const DatasetProvider = ({ children }: DatasetProviderProps) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [datasetId, setCurrentDataset] = useState("");
  const [filter, setDatasetFilter] = useState<DatasetFilter | null>(null);
  const shouldRequestData = useDetectChange([filter]);
  const isUploadedZip = useAppSelector(selectIsUploadedZip(datasetId));
  function navigateByQuery(
    query: string,
    value: string,
    exact: boolean = false,
    addIfExists: boolean = false
  ) {
    const searchParams = exact ? "" : location.search;
    const urlSearchParams = new URLSearchParams(searchParams);
    if (value) {
      if (addIfExists) {
        urlSearchParams.append(query, value);
      } else {
        urlSearchParams.set(query, value);
      }
    } else {
      urlSearchParams.delete(query);
    }
    const newLocation = { ...location, search: urlSearchParams.toString() };
    history.push(newLocation);
  }

  function setPage(page: number) {
    navigateByQuery(SearchParams.PAGE, page.toString());
  }

  function setPageSize(pageSize: number) {
    navigateByQuery(SearchParams.SIZE, pageSize.toString());
  }

  function setStatus(status: string) {
    navigateByQuery(SearchParams.STATUS, status);
  }

  function setCloud(cloudId: string) {
    navigateByQuery(SearchParams.CLOUD, cloudId);
  }

  function setFileName(fileName: string) {
    navigateByQuery(SearchParams.FILE_NAME, fileName);
  }

  function setCreatedDate(createdDate: string) {
    navigateByQuery(SearchParams.CREATED_DATE, createdDate);
  }

  function setCreatedBy(createdBy: string) {
    navigateByQuery(SearchParams.CREATED_BY, createdBy);
  }

  function setTags(tags: string) {
    navigateByQuery(SearchParams.TAGS, tags);
  }

  function setStoreType(storeType: string) {
    navigateByQuery(SearchParams.STORE_TYPE, storeType);
  }

  function setSort(field: string, sort: string) {
    if (!field) {
      navigateByQuery(SearchParams.SORT, "");
      return;
    }

    navigateByQuery(SearchParams.SORT, sort ? `${field},${sort}` : field);
  }

  function setScope(scopeId: string) {
    navigateByQuery(SearchParams.SCOPE, scopeId);
  }

  function resetDataset() {
    setCurrentDataset("");
    setDatasetFilter(null);
  }

  function getQueryParam(
    urlSearchParams: URLSearchParams,
    key: string,
    defaultValue: string
  ): string {
    const value = urlSearchParams.get(key);
    if (value) return value;
    return defaultValue;
  }

  useEffect(() => {
    function datasetFilterMapper(locationSearch: string) {
      if (!datasetId) return null;
      const urlParams = new URLSearchParams(locationSearch);
      const filter: DatasetFilter = {
        datasetId: datasetId,
        size: 10,
      };
      if (urlParams.has(SearchParams.PAGE)) {
        filter.page = parseInt(
          getQueryParam(urlParams, SearchParams.PAGE, "1")
        );
      }
      if (urlParams.has(SearchParams.SIZE)) {
        filter.size = parseInt(
          getQueryParam(urlParams, SearchParams.SIZE, "12")
        );
      }
      if (urlParams.has(SearchParams.CLOUD)) {
        filter.cloud = getQueryParam(urlParams, SearchParams.CLOUD, "");
      }
      if (urlParams.has(SearchParams.FILE_NAME)) {
        filter.fileName = getQueryParam(urlParams, SearchParams.FILE_NAME, "");
      }
      if (urlParams.has(SearchParams.CREATED_DATE)) {
        filter.createdDate = getQueryParam(
          urlParams,
          SearchParams.CREATED_DATE,
          ""
        );
      }
      if (urlParams.has(SearchParams.TAGS)) {
        filter.tags = getQueryParam(urlParams, SearchParams.TAGS, "");
      }
      if (urlParams.has(SearchParams.CREATED_BY)) {
        filter.createdBy = getQueryParam(
          urlParams,
          SearchParams.CREATED_BY,
          ""
        );
      }
      if (urlParams.has(SearchParams.STORE_TYPE)) {
        filter.storeType = getQueryParam(
          urlParams,
          SearchParams.STORE_TYPE,
          ""
        );
      }
      if (urlParams.has(SearchParams.SORT)) {
        filter.sort = getQueryParam(urlParams, SearchParams.SORT, "");
      }
      if (urlParams.has(SearchParams.STATUS)) {
        filter.status = getQueryParam(urlParams, SearchParams.STATUS, "");
      }
      if (urlParams.has(SearchParams.SCOPE)) {
        filter.scope = getQueryParam(urlParams, SearchParams.SCOPE, "");
      }
      return filter;
    }
    const filter = datasetFilterMapper(location.search);
    setDatasetFilter(filter);
  }, [location.search, datasetId]);

  const requestData = useCallback(async () => {
    try {
      if (!filter || !filter.datasetId) return;
      await dispatch(loadDatasetByFilterAsync(filter));
      await dispatch(loadTagsAsync());
    } catch (err) {
      Sentry.captureException(err);
      Logger.log(err);
    }
  }, [filter, dispatch]);

  useEffect(() => {
    if (isUploadedZip) {
      setTimeout(() => {
        requestData();
      }, 1000);
    }
  }, [isUploadedZip, requestData]);

  useEffect(() => {
    if (!shouldRequestData) return;
    if (filter) requestData();
  });

  const value: DatasetContextState = {
    filter,
    setPage,
    setPageSize,
    setStatus,
    setCloud,
    setFileName,
    setCreatedDate,
    setCreatedBy,
    setTags,
    setStoreType,
    setSort,
    setScope,
    setCurrentDataset,
    requestData,
    resetDataset,
  };
  return (
    <DatasetContext.Provider value={value}>{children}</DatasetContext.Provider>
  );
};
