/*
 * File: upload-ai-model-validation.schema.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 4th November 2022 11:08:56 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import * as Yup from "yup";

export const uploadAIModelValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Model name is required")
    .matches(
      /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){1,18}[a-zA-Z0-9]$/gm,
      "The name of a model must be only letters, numbers, dashes, periods, or underscores, and start with a letter or number"
    ),
  description: Yup.string().max(250, "Too Long!"),
  inferenceType: Yup.string().required("Inference type is required"),
  file: Yup.mixed()
    .nullable()
    .required("Model zip file is required")
    .test("is-big-file", "Max file size is 1GB", checkIfFilesAreTooBig),
});

function checkIfFilesAreTooBig(file?: File | null): boolean {
  let valid = true;
  if (file) {
    const size = file.size / 1024 / 1024;
    valid = size < 1000;
  }
  return valid;
}

export async function validateUploadAIModelPayload(payload: any) {
  return new Promise((resolve) => {
    uploadAIModelValidationSchema
      .validate(payload)
      .then(() => resolve(undefined))
      .catch((err) => resolve(err));
  });
}
