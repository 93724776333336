/*
 * File: tool-icon.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import _RotateIcon from "../../../assets/cornerstone/icons/rotate.svg";
import _ResetWwwcIcon from "../../../assets/cornerstone/icons/wwwc.svg";
import _ResetZoomIcon from "../../../assets/cornerstone/icons/zoom.svg";
import _ResetRotateIcon from "../../../assets/cornerstone/icons/rotate_reset.svg";
import _ResetPanIcon from "../../../assets/cornerstone/icons/reset-pan.svg";
import _ResetAllIcon from "../../../assets/cornerstone/icons/rs.svg";
import _AngleIcon from "../../../assets/cornerstone/icons/angle.svg";
import _CobbAngleIcon from "../../../assets/cornerstone/icons/cobbangle.svg";
import _VFlipIcon from "../../../assets/cornerstone/icons/vflip.svg";
import _HFlipIcon from "../../../assets/cornerstone/icons/hflip.svg";
import _InvertIcon from "../../../assets/cornerstone/icons/invert.svg";
import _MagnifyIcon from "../../../assets/cornerstone/icons/magnify.png";
import _RulerIcon from "../../../assets/cornerstone/icons/length.svg";
import _HeartRulerIcon from "../../../assets/cornerstone/icons/heart_ruler.svg";
import { ReactComponent as RectangleRoiIcon } from "assets/cornerstone/icons/rectangle.svg";

// import _MaskIcon from "../../../assets/cornerstone/icons/mask.svg";
// import _IntensityIcon from "../../../assets/cornerstone/icons/intensity.svg";
// import _BidirectionalRulerIcon from "../../../assets/cornerstone/icons/bidirectional_ruler.svg";
// import _EllipseIcon from "../../../assets/cornerstone/icons/ellipse.svg";

import { ToolName } from "./dicom-tools.model";
import {
  IconFullScreen,
  IconGridEdit,
  IconRouting2,
  IconSynchronizer,
} from "components/common/vb-icon.component";
import { Layer } from "iconsax-react";
import { VscWand } from "react-icons/vsc";
import { BsBoundingBox } from "react-icons/bs";
import { TbPolygon } from "react-icons/tb";

export const CornerstoneToolIcons = {
  [ToolName.SAM]: <VscWand size={20} />,
  [ToolName.Grid]: <IconGridEdit className="w-5 h-5" />,
  [ToolName.Wwwc]: <i className="icon-brightness-contrast"></i>,
  [ToolName.Pan]: <i className="icon-move"></i>,
  [ToolName.StackScrollMouseWheel]: <Layer size={20} />,
  [ToolName.Synchronizer]: <IconSynchronizer className="flex-none" />,
  [ToolName.Zoom]: <i className="uir-zoom-in"></i>,
  [ToolName.Rotate]: <img src={_RotateIcon} alt="rotate icon" />,
  [ToolName.Magnify]: <img src={_MagnifyIcon} alt="magnify icon" />,
  [ToolName.Angle]: <img src={_AngleIcon} alt="angle icon" />,
  [ToolName.CobbAngle]: <img src={_CobbAngleIcon} alt="cobb angle icon" />,
  [ToolName.HeartRuler]: <img src={_HeartRulerIcon} alt="heart angle icon" />,
  [ToolName.Ruler]: <img src={_RulerIcon} alt="rule icon" />,
  [ToolName.RectangleRoi]: <BsBoundingBox size={16} className="flex-none" />,
  [ToolName.RectangleRoiExtend]: (
    <BsBoundingBox size={16} className="flex-none" />
  ),

  [ToolName.MultiArrowConnection]: (
    <IconRouting2 className="flex-none w-5 h-5" />
  ),

  [ToolName.Mask]: <RectangleRoiIcon className="flex-none w-5 h-5" />,
  [ToolName.FreehandRoi]: <TbPolygon className="flex-none w-5 h-5" />,
  [ToolName.FreehandRoiExtend]: <TbPolygon className="flex-none w-5 h-5" />,
  [ToolName.Eraser]: <i className="uir-broom"></i>,
  [ToolName.ResetWwwc]: <img src={_ResetWwwcIcon} alt="contrast icon" />,
  [ToolName.ResetZoom]: <img src={_ResetZoomIcon} alt="reset zoon icon" />,
  [ToolName.ResetPan]: <img src={_ResetPanIcon} alt="reset pan icon" />,
  [ToolName.ResetRotate]: (
    <img src={_ResetRotateIcon} alt="reset rotate icon" />
  ),
  [ToolName.ResetAll]: <img src={_ResetAllIcon} alt="reset all icon" />,
  [ToolName.FlipHorizontal]: (
    <img src={_HFlipIcon} alt="flip horizontal icon" />
  ),
  [ToolName.FlipVertical]: <img src={_VFlipIcon} alt="flip vertical icon" />,
  [ToolName.Invert]: <img src={_InvertIcon} alt="invert icon" />,
  [ToolName.FullScreen]: <IconFullScreen className="flex-none w-5 h-5" />,
};
