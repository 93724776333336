/*
 * File: cornerstone-tools-events.model.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 26th July 2021 11:22:15 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

export enum CornerstoneToolsEvents {
  CLIP_STOPPED = "cornerstonetoolsclipstopped",
  DOUBLE_TAP = "cornerstonetoolsdoubletap",
  KEY_DOWN = "cornerstonetoolskeydown",
  KEY_PRESS = "cornerstonetoolskeypress",
  KEY_UP = "cornerstonetoolskeyup",
  LABELMAP_MODIFIED = "cornersontetoolslabelmapmodified",
  MEASUREMENT_ADDED = "cornerstonetoolsmeasurementadded",
  MEASUREMENT_COMPLETED = "cornerstonetoolsmeasurementcompleted",
  MEASUREMENT_MODIFIED = "cornerstonetoolsmeasurementmodified",
  MEASUREMENT_UPDATED = "cornerstonetoolsmeasurementupdated",
  MEASUREMENT_REMOVED = "cornerstonetoolsmeasurementremoved",
  MEASUREMENT_SELECTED = "cornerstonetoolsmeasurementselected",
  MOUSE_CLICK = "cornerstonetoolsmouseclick",
  MOUSE_DOUBLE_CLICK = "cornerstonetoolsmousedoubleclick",
  MOUSE_DOWN = "cornerstonetoolsmousedown",
  MOUSE_DOWN_ACTIVATE = "cornerstonetoolsmousedownactivate",
  MOUSE_DRAG = "cornerstonetoolsmousedrag",
  MOUSE_MOVE = "cornerstonetoolsmousemove",
  MOUSE_UP = "cornerstonetoolsmouseup",
  MOUSE_WHEEL = "cornerstonetoolsmousewheel",
  MULTI_TOUCH_DRAG = "cornerstonetoolsmultitouchdrag",
  MULTI_TOUCH_START = "cornerstonetoolsmultitouchstart",
  MULTI_TOUCH_START_ACTIVE = "cornerstonetoolsmultitouchstartactive",
  STACK_PREFETCH_DONE = "cornerstonetoolsstackprefetchdone",
  STACK_PREFETCH_IMAGE_LOADED = "cornerstonetoolsstackprefetchimageloaded",
  STACK_SCROLL = "cornerstonetoolsstackscroll",
  TAP = "cornerstonetoolstap",
  TOOL_DEACTIVATED = "cornerstonetoolstooldeactivated",
  TOUCH_DRAG = "cornerstonetoolstouchdrag",
  TOUCH_DRAG_END = "cornerstonetoolstouchdragend",
  TOUCH_END = "cornerstonetoolstouchend",
  TOUCH_PINCH = "cornerstonetoolstouchpinch",
  TOUCH_PRESS = "cornerstonetoolstouchpress",
  TOUCH_ROTATE = "cornerstonetoolstouchrotate",
  TOUCH_START = "cornerstonetoolstouchstart",
  TOUCH_START_ACTIVE = "cornerstonetoolstouchstartactive"
}
