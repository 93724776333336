/*
 * File: labeler-report-by-project.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 15th November 2021 4:07:46 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import { useLabelerReportByProject } from "../hooks/labeler-report-by-project.hook";
import { LabelerReportByProjectTable } from "./labeler-report-by-project-table.component";

interface LabelerReportByProjectProps {
  fromDate: Date;
  toDate: Date;
}
export const LabelerReportByProject = ({
  fromDate,
  toDate,
}: LabelerReportByProjectProps) => {
  const {
    isLoading,
    totalRow,
    displayRows,
    filter,
    setFilterField,
  } = useLabelerReportByProject(fromDate, toDate);

  return (
    <div className="w-full h-full flex flex-col gap-4">
      <div className="flex-auto overflow-auto">
        <LabelerReportByProjectTable
          rows={displayRows}
          filter={filter}
          setFilterField={setFilterField}
          isLoading={isLoading}
          fromDate={fromDate}
          toDate={toDate}
        />
      </div>

      {filter && (
        <div className="flex-none">
          <GridPagination
            page={filter.page}
            pageSize={filter.pageSize}
            pageSizeOptions={[10, 15, 20, 25, 50, 75, 100]}
            onPageSizeChange={v => setFilterField("pageSize", v)}
            onPageChange={v => setFilterField("page", v)}
            totalItems={totalRow}
          />
        </div>
      )}
    </div>
  );
};
