/*
 * File: label-table.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 29th December 2021 11:03:37 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useEffect, useState } from "react";
import { classnames } from "utilities/classes";
import { formatNumber } from "utilities/formatter/number-formatter.utils";
import { getPercentage } from "utilities/misc/misc.utils";
import { useTableSort } from "../hooks/use-table-sort.hook";
import { DashboardTableCommon } from "./dashboard-table-common.component";

export interface RowDataTableLabel {
  code: number;
  name: string;
  annotations: number;
  distribution: number;
  averageIou: number;
}

interface LabelStatisticTableProps {
  data: RowDataTableLabel[];
}
export const LabelStatisticTable = ({ data }: LabelStatisticTableProps) => {
  const {
    rows,
    sort,
    handleSelectSort,
  } = useTableSort<RowDataTableLabel>(data);

  const [maxData, setMaxData] = useState<RowDataTableLabel>({
    code: -1,
    name: "",
    annotations: 0,
    distribution: 0,
    averageIou: 0,
  });
  useEffect(() => {
    const newMaxData: RowDataTableLabel = {
      code: -1,
      name: "",
      annotations: 0,
      distribution: 0,
      averageIou: 0,
    }
    data.forEach((itemData) => {
      newMaxData.annotations = Math.max(newMaxData.annotations, itemData.annotations);
      newMaxData.distribution = Math.max(newMaxData.distribution, itemData.distribution);
      newMaxData.averageIou = Math.max(newMaxData.averageIou, itemData.averageIou);
    });
    setMaxData(newMaxData);
  }, [data]);

  const columns: {
    field: keyof RowDataTableLabel;
    header: string;
    renderCell: (row: RowDataTableLabel) => JSX.Element;
  }[] = [
    {
      field: "code",
      header: "Code",
      renderCell: (row: RowDataTableLabel) => {
        return <span className="text-xs text-primary">{row.code}</span>;
      },
    },
    {
      field: "name",
      header: "Label name",
      renderCell: (row: RowDataTableLabel) => {
        return <span className="text-xs text-background-700">{row.name}</span>;
      },
    },
    {
      field: "annotations",
      header: "Count annotations",
      renderCell: (row: RowDataTableLabel) => {
        const percent = getPercentage(row.annotations, maxData.annotations);

        return (
          <div className="relative flex items-center w-full h-4 gap-1">
            <span
              className="h-4"
              style={{ width: `${percent}%`, background: "#3241FF"}}
            />
            <span
              className={classnames({
                "absolute left-1.5 top-0 bottom-0 text-white": percent > 20,
              })}
            >
              {formatNumber(row.annotations)}
            </span>
          </div>
        );
      },
    },
    {
      field: "distribution",
      header: "Distribution",
      renderCell: (row: RowDataTableLabel) => {
        const percent = getPercentage(row.distribution, maxData.distribution);
        return (
          <div className="relative flex items-center w-full h-4 gap-1">
            <span
              className="h-4"
              style={{ width: `${percent}%`, backgroundColor: "#3990FF" }}
            />
            <span
              className={classnames({
                "absolute left-1.5 top-0 bottom-0 text-white": percent > 40,
              })}
            >
              {row.distribution}%
            </span>
          </div>
        );
      },
    },
    {
      field: "averageIou",
      header: "Ave. IoU",
      renderCell: (row: RowDataTableLabel) => {
        const percent = getPercentage(row.averageIou, maxData.averageIou);
        return (
          <div className="relative flex items-center w-full h-4 gap-1 text-background-700">
            <span
              className="h-4"
              style={{ width: `${percent}%`, backgroundColor: "#47E1FF" }}
            />
            <span
              className={classnames({
                "absolute left-1.5 top-0 bottom-0 text-white": percent > 50,
              })}
            >
              {formatNumber(row.averageIou)}
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <DashboardTableCommon
      rows={rows}
      columns={columns}
      sort={sort}
      handleSelectSort={handleSelectSort}
    />
  );
};
