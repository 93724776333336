import { useWorkspaceContext } from "contexts/workspace";
import { useCallback, useEffect, useState } from "react";
import { mlServiceApis } from "services/ml-service/ml.service";
import { registeredModelMapper } from "./registered-models.mappers";
import { MlModelsRegisteredModelVersionUIModel } from "./registered-models.models";
import { RegisteredModelsTable } from "./registered-models.table";
import * as Sentry from "@sentry/react";


export const RegisteredModelsPage = () => {
  const [registeredModelVersions, setRegisteredModelVersions] = useState<MlModelsRegisteredModelVersionUIModel[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const { workspaceId } = useWorkspaceContext();

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);

      const res = await mlServiceApis.getModelsByWorkspaceId(workspaceId);

      let versions: MlModelsRegisteredModelVersionUIModel[] = [];

      for (const modelRes of res) {
        versions = versions.concat(registeredModelMapper.dtoToVersions(modelRes));
      }

      versions = [
        {
          modelName: "Fake detection 1",
          versionId: 1,
          versionCreatedAt: new Date(),
        },
        {
          modelName: "Fake detection 2",
          versionId: 2,
          versionCreatedAt: new Date(),
        },
        {
          modelName: "Fake detection 3",
          versionId: 3,
          versionCreatedAt: new Date(),
        },
      ];

      setRegisteredModelVersions(versions);

    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [workspaceId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleRowAction = async (row: MlModelsRegisteredModelVersionUIModel, action: string) => {
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between mb-2">
        <p className="text-lg font-bold">
          Registered models
        </p>
      </div>

      <RegisteredModelsTable
        rows={registeredModelVersions}
        isLoading={isLoading}
        onRowAction={handleRowAction}
      />
    </div>
  )
}
