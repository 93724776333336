/*
 * File: cornerstone-utils.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 5th August 2021 10:19:15 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  Annotation,
  AnnotationModel,
} from "store/labeler/image-workspace/dicom-editor/dicom-editor.state";
import cornerstoneTools from "cornerstone-tools";
import { MeasurementData } from "../models/measurement.model";
import { Point } from "utilities/math/point";
import { BatchObservationDTO } from "services/label-service/dtos";
import { Rectangle } from "utilities/math/rectangle";
import { pointInPolygon, pointInRectangle } from "utilities/math/intersect";
import { AnnotateType } from "constants/annotation.constant";
import { v4 } from "uuid";
const freehandUtils = cornerstoneTools.importInternal("util/freehandUtils");

export function annotationToObservation(
  annotation: Annotation
): AnnotationModel {
  let points = [];
  if (annotation.annotateType === AnnotateType.POLYGON) {
    points = annotation.measurement.handles.points.map((p: any) => ({
      x: p.x,
      y: p.y,
    }));
  } else if (annotation.annotateType === AnnotateType.BOUNDING_BOX) {
    const { start, end } = annotation.measurement.handles;
    points = [
      { x: start.x, y: start.y },
      { x: end.x, y: end.y },
    ];
  }
  return {
    observationId: parseInt(annotation.labelId),
    type: annotation.annotateType,
    points: points,
    probability: 100,
    objectTrackingId: annotation.objectTrackingId || "",
    freeTextValue: annotation.measurement.text,
    source: annotation.measurement.renderDashed ? "System" : undefined,
  };
}

export function calculateBoundingBox(points: any[]) {
  if (!points || points.length === 0) {
    return {
      left: 0,
      top: 0,
      width: 0,
      height: 0,
    };
  }
  const bounds = {
    left: points[0].x,
    right: points[0].x,
    bottom: points[0].y,
    top: points[0].x,
  };

  for (let i = 0; i < points.length; i++) {
    bounds.left = Math.min(bounds.left, points[i].x);
    bounds.right = Math.max(bounds.right, points[i].x);
    bounds.bottom = Math.min(bounds.bottom, points[i].y);
    bounds.top = Math.max(bounds.top, points[i].y);
  }

  return {
    left: bounds.left,
    top: bounds.bottom,
    width: Math.abs(bounds.right - bounds.left),
    height: Math.abs(bounds.top - bounds.bottom),
  };
}

export function pointsToHandle(points: Point[]) {
  const handlePoints = points.map(
    (p) =>
      new freehandUtils.FreehandHandleData({
        x: p.x,
        y: p.y,
      })
  );
  return {
    points: points.map((p: any, index: number) => {
      let point = { ...p, lines: p.lines ? p.lines : [] };
      const nextElement = index === handlePoints.length - 1 ? 0 : index + 1;
      if (nextElement !== 0) {
        point.lines.push({
          x: handlePoints[nextElement].x,
          y: handlePoints[nextElement].y,
        });
      } else {
        point.lines.push(handlePoints[0]);
      }

      return point;
    }),
    textBox: {
      drawnIndependently: true,
      hasBoundingBox: true,
      hasMoved: false,
      movesIndependently: false,
    },
  };
}

export function measurementPoints(measurement: MeasurementData): Point[] {
  if (!measurement || !measurement.handles) return [];
  if (measurement.handles.start && measurement.handles.end) {
    const { start, end } = measurement.handles;
    return [
      { x: start.x, y: start.y },
      { x: end.x, y: end.y },
    ];
  }

  if (measurement.handles.points && measurement.handles.points.length > 0) {
    return measurement.handles.points.map((point: any) => {
      return {
        x: point.x,
        y: point.y,
      };
    });
  }

  return [];
}

export function measurementBoundingBox(
  measurement: MeasurementData
): Rectangle {
  if (!measurement.handles) throw new Error("Invalid measurement data");
  if (
    measurement.handles.hasOwnProperty("start") &&
    measurement.handles.hasOwnProperty("end")
  ) {
    const { start, end } = measurement.handles;
    const x = Math.min(start.x, end.x);
    const y = Math.min(start.y, end.y);
    const width = Math.abs(end.x - start.x);
    const height = Math.abs(end.y - start.y);
    return { x, y, width, height };
  }

  if (
    measurement.handles.hasOwnProperty("points") &&
    measurement.handles.points.length > 0
  ) {
    const points: Point[] = measurement.handles.points.map((point: any) => {
      return { x: point.x, y: point.y };
    });
    const minX = Math.min(...points.map(({ x }) => x));
    const maxX = Math.max(...points.map(({ x }) => x));
    const minY = Math.min(...points.map(({ y }) => y));
    const maxY = Math.max(...points.map(({ y }) => y));
    return { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
  }
  throw new Error("Invalid measurement data");
}

export function getAnnotationLabel(batchObservation: BatchObservationDTO) {
  const { observation } = batchObservation;

  const parentName = observation.parent ? `${observation.parent.name}: ` : "";
  const observationName = observation.name;
  if (!batchObservation.probabilityRequired) {
    return `${parentName}: ${observationName}`;
  }
  const probability = observation.probability
    ? observation.probability * 100
    : 100;
  let probabilityStr = probability > 0 ? ` (${probability}%)` : "";
  return `${parentName}: ${observationName}${probabilityStr}`;
}

export function pointInMeasurement(point: Point, measurement: MeasurementData) {
  if (!measurement) return false;
  if (
    measurement.handles.hasOwnProperty("start") &&
    measurement.handles.hasOwnProperty("end")
  ) {
    const start = measurement.handles.start;
    const end = measurement.handles.end;
    const minX = Math.min(start.x, end.x);
    const maxX = Math.max(start.x, end.x);
    const minY = Math.min(start.y, end.y);
    const maxY = Math.max(start.y, end.y);
    return pointInRectangle(point, {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    });
  }
  if (measurement.handles.hasOwnProperty("points")) {
    const points = measurement.handles.points.map((p: any) => ({
      x: p.x,
      y: p.y,
    }));
    return pointInPolygon(point, points);
  }
  return false;
}

export function observationToAnnotation(
  observation: AnnotationModel
): Annotation {
  const uuid = observation.objectTrackingId || v4();
  const annotateType = observation.type;
  if (annotateType === AnnotateType.BOUNDING_BOX) {
    let measurementData = createBoundingBoxMeasurement(observation.points);
    if (measurementData) {
      return {
        labelId: "",
        userId: "",
        jobId: "",
        uuid: uuid,
        annotateType: annotateType,
        objectTrackingId: observation.objectTrackingId,
        measurement: {
          ...measurementData,
          visible: true,
          active: false,
          invalidated: false,
          color: "#FF00FF",
          uuid: uuid,
          cachedStats: null,
          unit: "",
          showLabel: true,
          displayTextValue: true,
          objectTrackingId: observation.objectTrackingId,
          text:
            observation.freeTextValue ||
            (observation.attributes || []).map((attr) => attr.value).toString(),
          renderDashed: false,
        },
        probability: observation.probability
          ? observation.probability * 100
          : 100,
      };
    }
  }
  let measurementData = {
    visible: true,
    active: false,
    invalidated: false,
    color: "#FF00FF",
    handles: pointsToHandle(observation.points),
    polyBoundingBox: calculateBoundingBox(observation.points),
    uuid: uuid,
    cachedStats: null,
    unit: "",
    showLabel: true,
    displayTextValue: true,
    objectTrackingId: observation.objectTrackingId,
    text: observation.freeTextValue,
    renderDashed: false,
  };

  return {
    labelId: "",
    userId: "",
    uuid: uuid,
    jobId: "",
    annotateType: annotateType,
    measurement: measurementData,
    probability: observation.probability ? observation.probability * 100 : 100,
    objectTrackingId: observation.objectTrackingId,
  };
}

function createBoundingBoxMeasurement(points: { x: number; y: number }[]) {
  if (!points || points.length === 0) return null;
  const startIndex = 0;
  const endIndex = points.length === 1 ? 0 : 1;
  const left = points[startIndex].x;
  const top = points[startIndex].y;
  const width = points[endIndex].x - points[startIndex].x;
  const height = points[endIndex].y - points[startIndex].y;

  return {
    visible: true,
    active: true,
    color: undefined,
    invalidated: true,
    handles: {
      start: {
        x: points[startIndex].x,
        y: points[startIndex].y,
        highlight: true,
        active: false,
      },
      end: {
        x: points[endIndex].x,
        y: points[endIndex].y,
        highlight: true,
        active: true,
      },

      left: {
        x: left,
        y: top + height / 2,
        highlight: true,
        active: true,
      },
      top: {
        x: left + width / 2,
        y: top,
        highlight: true,
        active: true,
      },
      right: {
        x: left + width,
        y: top + height / 2,
        highlight: true,
        active: true,
      },
      bottom: {
        x: left + width / 2,
        y: top + height,
        highlight: true,
        active: true,
      },
      topRight: {
        x: left + width,
        y: top,
        highlight: true,
        active: true,
      },
      bottomLeft: {
        x: left,
        y: top + height,
        highlight: true,
        active: true,
      },

      initialRotation: 0,
      textBox: {
        active: false,
        hasMoved: false,
        movesIndependently: false,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
      },
    },
  };
}
