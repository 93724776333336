/*
 * File: web-builder.page.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 17th February 2022 4:14:24 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { IconImport, IconPlayArrow } from "components/common/vb-icon.component";
import { AIResultPreviewEmpty } from "./components/ai-result-preview/ai-result-preview-empty.component";
import { BrowserMockup } from "./components/browser-mockup/browser-mockup.component";
import { BuilderPanel } from "./components/builder-panel/builder-panel.component";
import { LayoutWrapper } from "./components/layout-wrapper/layout-wrapper.component";
import { ModelPreview } from "./components/model-preview/model-preview.component";
import { PageHeader } from "./components/page-header/page-header.component";
import { useWebBuilderContext } from "./context/web-builder.context";

export const WebBuilderPage = () => {
  const {
    color,
    theme,
    colorOptions,
    templateMetadata,
    onTitleChange,
    onDescriptionChange,
    onTxtCheckResultChange,
    onTxtImportChange,
    onTxtRunModelChange,
    onUploadedPreviewImage,
  } = useWebBuilderContext();
  const mainColor = colorOptions.find((c) => c.value === color)?.label || "";

  function onTitleChanged(event: any) {
    const title = event.target.innerText;
    onTitleChange(title);
  }

  function onDescriptionChanged(event: any) {
    const items = event.target.innerText.split("\n");
    const description = items.join("\n");
    onDescriptionChange(description);
  }

  function onTxtImportDataChanged(event: any) {
    const title = event.target.innerText;
    onTxtImportChange(title);
  }

  function onTxtRunModelChanged(event: any) {
    const title = event.target.innerText;
    onTxtRunModelChange(title);
  }

  function onTxtCheckResultChanged(event: any) {
    const title = event.target.innerText;
    onTxtCheckResultChange(title);
  }

  return (
    <LayoutWrapper
      header={<PageHeader key="header" />}
      panel={<BuilderPanel key="panel" />}
    >
      <div
        className={`flex-auto h-full ${theme} ${mainColor} p-6 bg-background-100`}
      >
        <BrowserMockup>
          <div className="h-full p-4 overflow-y-auto bg-background-50 lg:p-6 xl:p-10 dark:bg-background-900 dark:text-white">
            <div className="container space-y-10 bg-background-50 dark:bg-background-900 dark:text-white">
              <div className="flex flex-wrap gap-6">
                <ModelPreview
                  onUploaded={onUploadedPreviewImage}
                  showDelete={true}
                  imageUrl={templateMetadata.previewImageUrl}
                />
                <div
                  className="flex flex-col space-y-6"
                  style={{ minHeight: "15rem" }}
                >
                  <div className="flex-auto space-y-4">
                    <h1
                      className="max-w-2xl text-xl font-bold lg:text-2xl xl:text-4xl"
                      contentEditable
                      onBlur={onTitleChanged}
                      suppressContentEditableWarning
                    >
                      {templateMetadata.title}
                    </h1>
                    <div
                      className="leading-normal"
                      onBlur={onDescriptionChanged}
                      contentEditable
                      suppressContentEditableWarning
                    >
                      {templateMetadata.description &&
                        templateMetadata.description
                          .split("\n")
                          .map((s, index) => <div key={s + index}>{s}</div>)}
                    </div>
                  </div>
                  <div className="flex flex-wrap flex-none gap-4">
                    <button
                      style={{ minWidth: "116px" }}
                      className="flex items-center justify-center gap-1.5 px-3 h-9 rounded bg-white text-primary border border-primary"
                    >
                      <span
                        contentEditable
                        onBlur={onTxtImportDataChanged}
                        suppressContentEditableWarning
                        className="focus:outline-none"
                      >
                        {templateMetadata.txtImportData}
                      </span>
                      <IconImport className="w-4 h-4" />
                    </button>
                    <button
                      style={{ minWidth: "116px" }}
                      className="flex items-center justify-center gap-1.5 px-3 h-9 rounded bg-primary text-white border border-primary"
                    >
                      <span
                        onBlur={onTxtRunModelChanged}
                        contentEditable
                        suppressContentEditableWarning
                        className="focus:outline-none"
                      >
                        {templateMetadata.txtRunModel}
                      </span>
                      <IconPlayArrow className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <h3 className="text-lg font-semibold">
                  <span
                    onBlur={onTxtCheckResultChanged}
                    contentEditable
                    suppressContentEditableWarning
                  >
                    {templateMetadata.txtCheckResult}
                  </span>
                </h3>
                <div className="flex flex-wrap gap-4">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((idx) => {
                    return <AIResultPreviewEmpty key={idx} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </BrowserMockup>
      </div>
    </LayoutWrapper>
  );
};
