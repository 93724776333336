/*
 * File: dataset-detail.page.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 5th August 2021 2:26:33 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { LinearProgress } from "@material-ui/core";
import { useCloudTracking } from "hooks/use-cloud-tracking";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { selectDatasetLoading } from "store/customer/dataset/dataset.selectors";
import {
  removeCurrentDatasetData,
  setDatasetAsync,
} from "store/customer/dataset/dataset.slice";
import { Logger } from "utilities/logger";
import { useDatasetContext } from "../context/dataset-context";
import { DeleteFileDialog } from "./components/dialogs/delete-file.dialog";
import { DatasetDetailProvider } from "./dataset-detail.context";
import { DatasetDetailHeader } from "./components/header/dataset-detail-header";
import { DatasetFilesGrid } from "./components/explorer/dataset-files-grid.component";
import { AppEvents } from "constants/annotation.constant";
import * as Sentry from "@sentry/react";

export const DatasetDetailPage = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectDatasetLoading);
  const { datasetId } = useParams<{ datasetId: string }>();
  const { setCurrentDataset, requestData } = useDatasetContext();
  useCloudTracking();

  useEffect(() => {
    const setDataAsync = async (id: number) => {
      try {
        await dispatch(setDatasetAsync(id));
      } catch (err) {
        Sentry.captureException(err);
        Logger.log(err);
      }
    };
    setDataAsync(parseInt(datasetId));
    setCurrentDataset(datasetId);
  }, [dispatch, setCurrentDataset, datasetId]);

  useEffect(() => {
    return () => {
      dispatch(removeCurrentDatasetData());
    };
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener(AppEvents.DATASET_FILES_UPLOADED, requestData);
    return () => {
      document.removeEventListener(
        AppEvents.DATASET_FILES_UPLOADED,
        requestData
      );
    };
  }, [requestData]);

  return (
    <DatasetDetailProvider>
      <div className="relative flex flex-col h-full p-4">
        <DatasetDetailHeader />
        <div className="flex-auto">
          <DatasetFilesGrid />
        </div>

        <DeleteFileDialog />

        {loading && (
          <div className="absolute top-0 left-0 right-0 z-50">
            <LinearProgress />
          </div>
        )}
      </div>
    </DatasetDetailProvider>
  );
};
