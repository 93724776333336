/*
 * File: ml-web-table.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 17th February 2022 3:14:01 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Snackbar } from "@material-ui/core";
import { DateColumnHeader } from "components/common/vb-grid/date-column-header.component";
import { SearchableColumnHeader } from "components/common/vb-grid/searchable-column-header.component";
import { SelectableColumnHeader } from "components/common/vb-grid/selectable-column-header.component";
import {
  IconEdit,
  IconEye,
  IconLink,
  IconModelTypeClassification,
  IconModelTypeObjectDetection,
  IconModelTypeSegmentation,
  IconSave2,
  IconTrash,
} from "components/common/vb-icon.component";
import { FilterCommon } from "domain/common/filter";
import {
  MLModelType,
  MLModelTypeName,
  MLWeb,
  ServingWebStatus,
} from "domain/web-builder";
import { useAppSelector } from "hooks/use-redux";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { User } from "store/auth/auth.state";
import { classnames } from "utilities/classes";
import { formatDateTime } from "utilities/formatter/date-formatter.utils";
import { useMLWebContext } from "../context/ml-web.context";

export enum MLWebItemAction {
  SELECT = "select",
  RENAME = "rename",
  OPEN_IN_A_NEW_TAB = "open_in_new_tab",
  DELETE = "delete",
  COPY_PUBLISH_URL = "copy_publish_url",
}

interface Props {
  rows: MLWeb[];
  onRowAction?(item: MLWeb, action: MLWebItemAction): void;
}
export const MLWebTable = ({ rows, onRowAction }: Props) => {
  const { t } = useTranslation();
  const { filter, setFilterField } = useMLWebContext();
  const currentUser = useAppSelector(selectCurrentUser) as User;

  const createdByOptions = useMemo(() => {
    return [{ label: currentUser.email, value: currentUser.email }];
  }, [currentUser]);

  const typeOptions = [
    {
      label: MLModelTypeName[MLModelType.IMAGE_CLASSIFICATION],
      value: MLModelType.IMAGE_CLASSIFICATION,
    },
    {
      label: MLModelTypeName[MLModelType.OBJECT_DETECTION],
      value: MLModelType.OBJECT_DETECTION,
    },
    {
      label: MLModelTypeName[MLModelType.IMAGE_SEGMENTATION],
      value: MLModelType.IMAGE_SEGMENTATION,
    },
  ];

  const statusNamesMap = {
    [ServingWebStatus.Activated]: "Published",
    [ServingWebStatus.Deactivated]: "Unpublish",
  };

  const statusOptions = [
    {
      label: statusNamesMap[ServingWebStatus.Activated],
      value: ServingWebStatus.Activated,
    },
    {
      label: statusNamesMap[ServingWebStatus.Deactivated],
      value: ServingWebStatus.Deactivated,
    },
  ];

  const modelTypeIconMap = {
    [MLModelType.IMAGE_CLASSIFICATION]: (
      <IconModelTypeClassification className="w-4 h-4" />
    ),
    [MLModelType.OBJECT_DETECTION]: (
      <IconModelTypeObjectDetection className="w-4 h-4" />
    ),
    [MLModelType.IMAGE_SEGMENTATION]: (
      <IconModelTypeSegmentation className="w-4 h-4" />
    ),
  };

  const [showLinkRow, setShowLinkRow] = useState<MLWeb>();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleClickLinkIcon = (row: MLWeb) => {
    setShowLinkRow(row);
  };

  const handleCopyClipBoard = (url: string) => {
    navigator.clipboard.writeText(url);
    setOpenSnackbar(true);
  };

  return (
    <div className="min-w-full">
      <table className="min-w-full text-sm vb-table">
        <thead>
          <tr>
            <ColumnHeaderWrapper first>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={false}
                placeholder={"Search"}
                header={"Name"}
                containerWidth={"100%"}
                defaultValue={filter.name || ""}
                type="text"
                onInputChange={(v) => setFilterField("name", v)}
                sortable
                onSortChange={(sortType) =>
                  setFilterField(
                    "sort",
                    FilterCommon.getSortValue(sortType, "name")
                  )
                }
                sortType={FilterCommon.getSortType(filter.sort, "name")}
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <SelectableColumnHeader
                className="leading-normal"
                placeholder="All"
                header="Created by"
                containerWidth="100%"
                menuPortalTarget={document.body}
                defaultValue={createdByOptions.find(
                  (option) => option.value === filter?.createdBy
                )}
                options={createdByOptions}
                onChange={(option: any) => {
                  if (option) {
                    setFilterField("createdBy", option.value);
                  } else {
                    setFilterField("createdBy", undefined);
                  }
                }}
                isClearable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <DateColumnHeader
                containerClassName="w-full py-2"
                header={"Last opened"}
                placeholderText={t("common:formattedDate", {
                  date: new Date(),
                })}
                containerWidth="100%"
                defaultValue={filter?.lastActivity}
                onChange={(date) =>
                  date
                    ? setFilterField("lastActivity", date)
                    : setFilterField("lastActivity", undefined)
                }
                clearable
                sortable
                onSortChange={(sortType) =>
                  setFilterField(
                    "sort",
                    FilterCommon.getSortValue(sortType, "lastActivity")
                  )
                }
                sortType={FilterCommon.getSortType(filter.sort, "lastActivity")}
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <SelectableColumnHeader
                className="leading-normal"
                placeholder="All"
                header="Status"
                containerWidth="100%"
                menuPortalTarget={document.body}
                defaultValue={statusOptions.find(
                  (option) => option.value === filter?.type
                )}
                options={statusOptions}
                onChange={(option: any) => {
                  if (option) {
                    setFilterField("status", option.value);
                  } else {
                    setFilterField("status", undefined);
                  }
                }}
                isClearable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper last>
              <SelectableColumnHeader
                className="leading-normal"
                placeholder="All"
                header="Type"
                containerWidth="100%"
                menuPortalTarget={document.body}
                defaultValue={typeOptions.find(
                  (option) => option.value === filter?.type
                )}
                options={typeOptions}
                onChange={(option: any) => {
                  if (option) {
                    setFilterField("type", option.value);
                  } else {
                    setFilterField("type", undefined);
                  }
                }}
                isClearable
              />
            </ColumnHeaderWrapper>
          </tr>
        </thead>
        <tbody className="relative">
          {rows.map((row) => {
            const publishLink = `${window.location.origin}/model/${row.id}`;

            return (
              <tr
                key={row.id}
                className="relative h-10 hover:bg-secondary-50 parent"
              >
                <td className="pl-4 pr-4" style={{ minWidth: 200 }}>
                  {row.name}
                </td>
                <td className="pr-4" style={{ minWidth: 150 }}>
                  {row.createdBy}
                </td>
                <td className="pr-4" style={{ minWidth: 120 }}>
                  {t("common:formattedDate", {
                    date: formatDateTime(row.lastOpened),
                  })}
                </td>
                <td className="pr-4" style={{ minWidth: 120 }}>
                  {statusNamesMap[row.status]}
                </td>
                <td className="relative pr-4" style={{ minWidth: 200 }}>
                  <div className="flex items-center gap-1">
                    <div className="text-primary">
                      {modelTypeIconMap[row.type]}
                    </div>
                    <span>{MLModelTypeName[row.type]}</span>
                  </div>
                </td>
                <td
                  className="absolute h-full transform -translate-x-full -translate-y-1/2 top-1/2 child-on-hover"
                  style={{ zIndex: "999" }}
                >
                  <div className="flex flex-row items-center h-full gap-3 px-3 text-primary bg-secondary-50">
                    <button
                      onClick={(_) =>
                        onRowAction && onRowAction(row, MLWebItemAction.RENAME)
                      }
                    >
                      <IconEdit className="w-4 h-4" />
                    </button>
                    <button>
                      <IconEye
                        onClick={(_) =>
                          onRowAction &&
                          onRowAction(row, MLWebItemAction.OPEN_IN_A_NEW_TAB)
                        }
                        className="w-4 h-4"
                      />
                    </button>
                    <div className="relative flex items-center">
                      <button>
                        <IconLink
                          onClick={(_) => handleClickLinkIcon(row)}
                          className="w-4 h-4"
                        />
                      </button>
                      {showLinkRow?.id === row.id && (
                        <div
                          className="absolute bottom-0 flex items-center gap-4 px-4 py-2 transform -translate-x-full translate-y-full bg-white border rounded border-background-300 left-1/2"
                          onMouseLeave={(_) => setShowLinkRow(undefined)}
                        >
                          <IconSave2
                            className="w-4 h-4 text-black cursor-pointer"
                            onClick={(_) => handleCopyClipBoard(publishLink)}
                          />
                          <div
                            className="overflow-x-auto truncate cursor-pointer text-primary"
                            style={{ maxWidth: "200px" }}
                          >
                            <span onClick={(_) => window.open(publishLink)}>
                              {publishLink}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <button className="text-error-500">
                      <IconTrash className="w-4 h-4" />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
          <tr className="absolute top-0 left-0 w-full h-full border rounded pointer-events-none border-background-300"></tr>
        </tbody>
      </table>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={(_) => setOpenSnackbar(false)}
      >
        <div className="p-2 text-white rounded bg-background-800">Copied!</div>
      </Snackbar>
    </div>
  );
};

interface ColumnHeaderWrapperProps {
  first?: boolean;
  last?: boolean;
  children?: JSX.Element;
  maxWidth?: number;
}
const ColumnHeaderWrapper = ({
  first,
  last,
  children,
  maxWidth,
}: ColumnHeaderWrapperProps) => {
  return (
    <th
      className={classnames(
        "whitespace-nowrap text-background-700 text-left font-normal",
        {
          "rounded-l": !!first,
          "pr-4": !last,
          "rounded-r": !!last,
        }
      )}
      style={{ maxWidth: maxWidth }}
    >
      {children}
    </th>
  );
};
