/*
 * File: all-workspace-section.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 5th August 2021 2:26:33 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { WorkspaceAvatar } from "components/common/vb-workspace-avatar.component";
import { useAppSelector } from "hooks/use-redux";
import { MouseEvent, useMemo, useState } from "react";
import { GroupDTO } from "services/user-service/dtos/group.dto";
import { selectUserWorkspaces } from "store/common/user-workspace/user-workspace.selectors";
import {
  selectCurrentUser,
  selectIsGlobalAdmin,
} from "store/auth/auth.selectors";
import { UserRole } from "store/auth/auth.state";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useWorkspaceFilters } from "./use-workspace-filter";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import classNames from "classnames";
import { useKeycloakContext } from "contexts/keycloak/keycloak.context";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { IconButton, Tooltip } from "@material-ui/core";
import { Trash } from "iconsax-react";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";

export const AllWorkspaceSection = () => {
  const { t } = useTranslation();

  const [filterOptions] = useState([
    { label: t("workspace:filterOption.all"), value: "all" },
    { label: t("workspace:filterOption.customer"), value: "customer" },
    { label: t("workspace:filterOption.labeler"), value: "labeler" },
  ]);
  const { filter, setName, setType } = useWorkspaceFilters();

  const selectedOption = useMemo(() => {
    if (!filter.type) return filterOptions[0];
    return filterOptions.find((op) => op.value === filter.type);
  }, [filterOptions, filter]);

  const workspaces = useAppSelector(selectUserWorkspaces);
  const currentUser = useAppSelector(selectCurrentUser);
  const clientWorkspaces = useMemo(() => {
    if (
      !currentUser ||
      !currentUser.workspace.hasOwnProperty(UserRole.CUSTOMER)
    ) {
      return [];
    }
    return workspaces
      .filter((w) => {
        const { name, type } = filter;
        const searchStr = name.toLowerCase();
        if (searchStr && !w.groupName.toLowerCase().includes(searchStr)) {
          return false;
        }
        const ws = w as any;
        if (type !== "all" && ws.hasOwnProperty(type) && !ws[type] === true) {
          return false;
        }
        return true;
      })
      .sort((a, b) => a.groupName.localeCompare(b.groupName));
  }, [workspaces, filter, currentUser]);

  function onFilterChange(option: any) {
    setType(option.value);
  }

  return (
    <div className="p-4">
      <h2 className="my-8 text-2xl font-bold">
        {t("workspace:textAllWorkspaces")}
      </h2>
      <h3 className="m-2 font-bold text-gray-500">
        {t("workspace:textFilterSearch")}
      </h3>
      <div className="flex items-center gap-4">
        <VBTextInputComponent
          placeholder={t("common:placeholderSearch")}
          onInputSubmit={setName}
        />
        <VBSelectComponent
          className="w-32"
          options={filterOptions}
          value={selectedOption}
          onChange={onFilterChange}
        />
      </div>
      <div className="my-8 overflow-y-auto max-h-96">
        {clientWorkspaces.map((w) => (
          <WorkspaceListItem key={w.groupId} workspace={w} />
        ))}
      </div>
    </div>
  );
};

const WorkspaceListItem = ({ workspace }: { workspace: GroupDTO }) => {
  const history = useHistory();
  const { selectWorkspace } = useKeycloakContext();
  const isGlobalAdmin = useAppSelector(selectIsGlobalAdmin);
  const { openModal, closeModal } = useModalContext();
  const isWorkspaceAdmin = useMemo(() => {
    return (
      (workspace as any).hasOwnProperty("customer") &&
      (workspace as any)["customer"] === true
    );
  }, [workspace]);

  function onClickWorkspace() {
    selectWorkspace(workspace.groupId);
    history.replace(Routes.ROOT);
  }

  function onClickDelelte(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    openModal(ModalTypes.DELETE_WORKSPACE, {
      workspaceId: workspace.groupId,
      workspaceName: workspace.groupName,
      onClose: closeModal,
    });
  }

  return (
    <button
      className={classNames("flex items-center w-full p-1 rounded-md group", {
        "hover:bg-background-200": isWorkspaceAdmin,
        "pointer-events-none": !isWorkspaceAdmin,
      })}
      disabled={!isWorkspaceAdmin}
      onClick={onClickWorkspace}
    >
      <div className="p-2 mr-4">
        <WorkspaceAvatar name={workspace.groupName} />
      </div>
      <div className="text-lg">{workspace.groupName}</div>
      <div className="flex-1"></div>
      {isGlobalAdmin && isWorkspaceAdmin && (
        <Tooltip title="Delete">
          <IconButton
            className="opacity-0 group-hover:opacity-100"
            onClick={onClickDelelte}
          >
            <Trash size={18} />
          </IconButton>
        </Tooltip>
      )}
    </button>
  );
};
