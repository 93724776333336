import moment from "moment";
import { BillingResponseDTO } from "services/payment/payment.dto";
import { CurrencySelectOption } from "store/common/payment/payment.state";
import { BillDetailBatchCommon, BillType, ProfileBillRow } from "./profile-bills.models";


export const mapperProfileBillRow = {
  fromEntity: (
    resRow: BillingResponseDTO,
    currencyOptions: CurrencySelectOption[],
  ): ProfileBillRow => {
    const billType = resRow.serviceName.toLowerCase() === "aiscaler fees" ? BillType.FEE : BillType.MANUAL_LABELING;
    const additionalData = resRow.additionalData;
    const detail: BillDetailBatchCommon = {
      batchId: additionalData.batchId,
      batchName: additionalData.batchName,
      startDate: moment(new Date(additionalData.startDate * 1000)).local().toDate(),
      endDate: moment(new Date(additionalData.endDate * 1000)).local().toDate(),
    };

    if (billType === BillType.FEE) {
      detail.fee = parseFloat(((additionalData.feeRatio || 0) * resRow.totalValue).toFixed(2));
    }

    if (billType === BillType.MANUAL_LABELING) {
      detail.priceRows = (additionalData.priceRows || []).map(row => ({
        numUnit: row.number,
        perUnit: row.perUnit,
      }));
    }

    const row: ProfileBillRow = {
      id: resRow.id,
      type: billType,
      issueDate: moment(new Date(resRow.issuedDate)).local().toDate(),
      serviceName: resRow.serviceName,
      description: resRow.description,
      totalPrice: resRow.totalValue,
      currencyCode: currencyOptions.find(op => op.value === resRow.currencyId)?.label,
      currencyId: resRow.currencyId,
      detail: detail,
    }
    return row;
  }
}