/*
 * File: label-distribution.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 10:50:48 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "store";

export const selectLabelDistributionRequestStatus = (state: RootState) => {
  return state.batchData.labelDistribution.status;
};

export const selectLabelDistributionRequestError = (state: RootState) => {
  return state.batchData.labelDistribution.error;
};

export const selectStepDistributionData =
  (batchId: number, step: number) => (state: RootState) => {
    const data = state.batchData.labelDistribution.entities[batchId];
    if (data && data.steps.hasOwnProperty(step)) {
      return data.steps[step];
    }
    return null;
  };
