/*
 * File: use-task-issues.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 10th April 2023 5:31:12 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useCallback } from "react";
import { useQuery } from "react-query";
import { issueLabelerService } from "services/label-service";
import { IssueResponseDTO } from "services/label-service/dtos/issue.dto";
import { issueMapepr } from "./issue-mapper";
import { Issue, IssueStatus } from "domain/common/issue";
import { ThreeDEditorEvents } from "pages/labeler/three-d-labeling/components/three-d-editor.models";

async function getJobIssues(jobId: number) {
  const response = await issueLabelerService.getIssues(jobId);
  const resIssues = response.data as IssueResponseDTO[];
  const issues = resIssues.map(issueMapepr.fromThreeDDto);
  return issues.filter((issue) => issue.status === IssueStatus.OPENED);
}

export function useJobIssues(jobId: number) {
  function requestData() {
    return getJobIssues(jobId);
  }

  const { data, isLoading } = useQuery(["jobIssues", jobId], requestData, {});

  const selectIssue = useCallback((issue: Issue) => {
    try {
      const axis = parseInt(issue.metadata.x);
      const slice = parseInt(issue.metadata.y);
      if (axis === -1) return;
      const eventData = { axis, slice };
      const eventDetail = { detail: eventData };
      const event = new CustomEvent(
        ThreeDEditorEvents.COMMAND_GO_TO_SLICE,
        eventDetail
      );
      document.dispatchEvent(event);
    } catch (error) {}
  }, []);

  return { selectIssue, data, isLoading };
}
