/*
 * File: workspace-panel.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 1:36:44 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import classnames from "classnames";

import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import {
  DEFAULT_TIME_LIMIT_SECOND,
  STEP_REVIEW_OBSERVATION_CODES,
} from "services/label-service/dtos";
import { useImageLabelingContext } from "pages/labeler/image-labeling/context/image-labeling.context";
import {
  selectCanViewPreviousSteps,
  selectImageSystemLabels,
  selectIsImageLabelingReadonly,
  selectIsAcceptanceJob,
  selectPreviousJobAnnotations,
  selectSelectedSystemObservationId,
  selectIsShowPreviousJobs,
  selectWorkingBatch,
  selectIsReviewJob,
} from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { useMemo, useState } from "react";
import { JobLabelerList } from "./job-labeler-list.component";
import { useEffect } from "react";
import { useCountDown, useUnmount } from "ahooks";
import { LightRadio } from "components/common/vb-light-radio.component";
import { countdownStopped } from "store/labeler/image-workspace/batch-labeling/batch-labeling.slice";
import { ActionPanel } from "../action-panel/action.panel.component";
import { IntersectionOfUnion } from "./intersection-of-union.component";
import { Label } from "domain/image-labeling";
import {
  IconArrowRightCircle,
  IconSave,
} from "components/common/vb-icon.component";

import { UseAutoSaveReturnValue } from "hooks/use-auto-save.hook";
import { imageSystemLabelSelectedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-system-label-selected.thunk";
import { selectImageLabelingActiveJobId } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import ImageLabelingSidebar from "../image-labeling/sidebar";
import { ButtonWithLoading } from "components/common/vb-button-loading.component";

interface WorkspacePanelProps {
  autoSave?: UseAutoSaveReturnValue;
}

export const WorkspacePanel = ({ autoSave }: WorkspacePanelProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { saving, submitting, saveCurrentJob, submitCurrentJob, imageLoaded } =
    useImageLabelingContext();
  const canViewPreviousStep = useAppSelector(selectCanViewPreviousSteps);
  const currentBatch = useAppSelector(selectWorkingBatch);
  const activeJobId = useAppSelector(selectImageLabelingActiveJobId);
  const jobAnnotations = useAppSelector(selectPreviousJobAnnotations);
  const isReviewJob = useAppSelector(selectIsReviewJob);
  const isAcceptanceJob = useAppSelector(selectIsAcceptanceJob);
  const isReadonly = useAppSelector(selectIsImageLabelingReadonly);

  const selectedSystemObservationId = useAppSelector(
    selectSelectedSystemObservationId
  );
  const systemObservations = useAppSelector(selectImageSystemLabels);
  const viewMediaAgreement = useAppSelector(selectIsShowPreviousJobs);

  const [isEnabled, setEnabled] = useState(true);

  const [countdown, setTargetDate] = useCountDown({
    onEnd: handleCountdownEnded,
  });

  const displaySystemLabels = useMemo(() => {
    return systemObservations.filter((bo) => {
      if (isAcceptanceJob) {
        return !!STEP_REVIEW_OBSERVATION_CODES.includes(bo.code);
      } else {
        return !!!STEP_REVIEW_OBSERVATION_CODES.includes(bo.code);
      }
    });
  }, [systemObservations, isAcceptanceJob]);

  function handleCountdownEnded() {
    setEnabled(true);
    const payload = {
      jobId: activeJobId,
      remainingSeconds: 0,
    };
    dispatch(countdownStopped(payload));
  }

  function handleSelectSystemLabel(label: Label) {
    if (isReadonly) {
      const errMessage = "You are not allow to modify this task!";
      return dispatch(enqueueErrorNotification(errMessage));
    }
    const id = selectedSystemObservationId === label.id ? -1 : label.id;
    const payload = { jobId: activeJobId, labelId: id };
    dispatch(imageSystemLabelSelectedAsync(payload));
  }

  useUnmount(() => {
    if (isEnabled) return;
    const payload = {
      jobId: activeJobId,
      remainingSeconds: Math.floor(countdown / 1000),
    };
    dispatch(countdownStopped(payload));
  });

  useEffect(() => {
    if (!imageLoaded || isEnabled) return;
    if (
      currentBatch.batchSetting &&
      currentBatch.batchSetting.timeLimitSecond === 0
    ) {
      setEnabled(true);
    }
    const timeLimitMs =
      (currentBatch.batchSetting
        ? currentBatch.batchSetting?.timeLimitSecond
        : DEFAULT_TIME_LIMIT_SECOND) * 1000;
    setTargetDate(new Date(Date.now() + timeLimitMs));
  }, [imageLoaded, isEnabled, setTargetDate, currentBatch]);

  const seconds = Math.round(countdown / 1000);

  return (
    <div className="relative flex flex-row flex-shrink-0 h-full overflow-x-hidden overflow-y-auto border-l border-blueGray-600 bg-background-800 dark-scrollbar">
      <div className="flex flex-col flex-1 h-full" style={{ width: "20rem" }}>
        <div className="flex-shrink-0">
          <div>
            <div className="p-4 text-xs font-bold text-gray-400">
              {t("labelerworkspace:panel.textActions")}
            </div>
            <div className="flex items-center justify-between gap-2 px-4 mb-4">
              <ButtonWithLoading
                onClick={saveCurrentJob}
                loading={saving}
                disabled={autoSave?.enabled}
                text={t("common:buttonSave")}
                endIcon={
                  <>
                    {autoSave &&
                      autoSave.seconds > 0 &&
                      ` (${autoSave.seconds}s)`}
                    <IconSave />
                  </>
                }
                className={classnames(
                  "w-1/2 py-2 flex items-center justify-center gap-2 text-white rounded m focus:outline-none bg-warning-500 disabled:opacity-50",
                  saving ? "cursor-not-allowed" : "cursor-pointer"
                )}
                debounceDelay={2000}
                clickLimit={2}
                variant="fill"
              />

              <ButtonWithLoading
                onClick={submitCurrentJob}
                loading={submitting}
                disabled={!isEnabled}
                text={t("common:buttonComplete")}
                endIcon={
                  <>
                    {!submitting &&
                      !isEnabled &&
                      imageLoaded &&
                      seconds > 0 &&
                      currentBatch.batchSetting &&
                      currentBatch.batchSetting?.timeLimitSecond > 0 &&
                      ` (${seconds}s)`}
                    <IconArrowRightCircle />
                  </>
                }
                className={`w-1/2 py-2 rounded hover:bg-opacity-80 flex justify-center items-center gap-2 ${
                  !isEnabled
                    ? "bg-gray-400 text-gray-600 cursor-not-allowed"
                    : "bg-warning-50 text-warning-500 cursor-pointer"
                }`}
                debounceDelay={2000}
                clickLimit={2}
                variant={isEnabled ? "fill" : "outline"}
              />
            </div>
          </div>

          {!isReviewJob && (
            <div className="flex flex-wrap items-center mb-4 gap-x-2">
              {displaySystemLabels.map((systemLabel) => {
                return (
                  <button
                    key={systemLabel.id}
                    className="flex items-center pl-1"
                    onClick={() => handleSelectSystemLabel(systemLabel)}
                  >
                    <LightRadio
                      checked={systemLabel.id === selectedSystemObservationId}
                      size="small"
                    />
                    <span>{systemLabel.name}</span>
                  </button>
                );
              })}
            </div>
          )}
        </div>

        {isReviewJob ? (
          <ActionPanel />
        ) : (
          <div className="flex-auto overflow-hidden">
            {canViewPreviousStep && (
              <>
                <div className="border-t border-gray-600 border-dashed" />
                <JobLabelerList
                  labelers={jobAnnotations.map((jobAnnotation) => {
                    return {
                      email: jobAnnotation.annotator,
                      jobId: jobAnnotation.jobId,
                      selected: false,
                    };
                  })}
                />
              </>
            )}
            <div className="border-t border-gray-600 border-dashed" />
            <ImageLabelingSidebar />
          </div>
        )}
      </div>

      {viewMediaAgreement && (
        <div className="absolute top-0 bottom-0 left-0 right-0 z-10 w-full h-full bg-blueGray-900">
          <div className="relative w-full h-full overflow-hidden">
            <IntersectionOfUnion jobId={activeJobId} />
          </div>
        </div>
      )}
    </div>
  );
};
