/*
 * File: use-app-setting.hook.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 28th October 2022 3:02:12 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useLocalStorageState } from "ahooks";
import { useEffect } from "react";
import { selectCurrentUser } from "store/auth/auth.selectors";
import {
  selectAppConfig,
  selectAppVersion,
} from "store/common/app-setting/app-setting.selectors";
import { loadAppSettingAsync } from "store/common/app-setting/app-setting.slice";
import { selectCurrentWorkspaceId } from "store/common/user-workspace/user-workspace.selectors";
import { Logger } from "utilities/logger";
import { useAppDispatch, useAppSelector } from "./use-redux";
import * as Sentry from "@sentry/react";

export function useAppSetting() {
  const dispatch = useAppDispatch();
  const config = useAppSelector(selectAppConfig);
  const loggedInUser = useAppSelector(selectCurrentUser);
  const workspaceId = useAppSelector(selectCurrentWorkspaceId);
  const version = useAppSelector(selectAppVersion);
  const [appConfig, setAppConfig] = useLocalStorageState(version);
  useEffect(() => {
    if (!loggedInUser || !workspaceId) return;
    try {
      dispatch(loadAppSettingAsync());
    } catch (error: any) {
      Sentry.captureException(error);
      Logger.log(error);
    }
  }, [dispatch, loggedInUser, workspaceId]);

  useEffect(() => setAppConfig(config), [config, setAppConfig]);

  return { appConfig };
}
