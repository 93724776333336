import { AnalyticsQueryResponse } from "services/analytics-service";
import { TaskStatus } from "services/label-service/dtos";
import { TaskProgressItemDataModel } from "../components/task-statistic-chart.component";

const COL_STATUS = "status";
const COL_COUNT = "count";

export const DEFAULT_DATA: TaskProgressItemDataModel[] = [
  {
    name: "Completed",
    value: 0,
    color: "#3241FF",
  },
  {
    name: "Available",
    value: 0,
    color: "#47E1FF",
  },
  {
    name: "Working",
    value: 0,
    color: "#FFBE6F",
  },
];

export const taskProgressMapper = (response: AnalyticsQueryResponse): TaskProgressItemDataModel[] => {
  const rows = response.data.rows;
  if (rows && rows.length > 0) {
    const resRows = DEFAULT_DATA.map(item => ({...item}));
    const completedRow: any = rows.find((r: any) => r[COL_STATUS] === TaskStatus.COMPLETED);
    const availableRow: any = rows.find((r: any) => r[COL_STATUS] === TaskStatus.INITIAL);
    const workingRow: any = rows.find((r: any) => r[COL_STATUS] === TaskStatus.WORKING);
    
    if (completedRow) resRows[0].value = completedRow[COL_COUNT] || 0;
    if (availableRow) resRows[1].value = availableRow[COL_COUNT] || 0;
    if (workingRow) resRows[2].value = workingRow[COL_COUNT] || 0;
    
    return resRows;
  }
  return DEFAULT_DATA;
}