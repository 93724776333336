/*
 * File: text-labeling.utils.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 2nd June 2022 2:16:06 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AnnotationSource } from "domain/labeling/annotation-source";
import { Batch } from "domain/labeling/batch";
import { LabelingFile } from "domain/labeling/file";
import { Job } from "domain/labeling/job";
import { TextLabelingData } from "domain/labeling/labeling-data";
import { LabelingJobData } from "domain/labeling/labeling-job";
import { Task } from "domain/labeling/task";
import { CORAnnotation, Label, NERAnnotation } from "domain/text-labeling";
import {
  AnnotationServiceV3,
  BatchObservationService,
  BatchService,
  LabelerAnnotationServiceV3,
  TaskService,
} from "services/label-service";
import { BatchObservationDTO } from "services/label-service/dtos";
import { TextAnnotation } from "services/label-service/dtos/text-observation.dto";
import { batchMapper } from "services/label-service/mappers/batch.mapper";
import { fileMapper } from "services/label-service/mappers/file.mapper";
import { taskMapper } from "services/label-service/mappers/task.mapper";
import { textLabelMapper } from "services/label-service/mappers/text-label.mapper";
import { StorageService } from "services/storage";
import { Sentence } from "../text-labeling/text-labeling.state";
import * as Sentry from "@sentry/react";

function isConflict(annotators: string[], selectedAnnotators: string[]) {
  if (selectedAnnotators.length <= 1) return false;
  return annotators.length !== selectedAnnotators.length;
}

async function getSentencesFromFile(fileId: number) {
  let sentences: Sentence[] = [];
  const textFileInfo = await StorageService.getTextFileInfo(fileId.toString());
  const data = textFileInfo.data.addition as {
    infoSentenceMap: { [key: string]: Sentence };
    numSentence: number;
  };
  if (data && data.infoSentenceMap) {
    for (let key of Object.keys(data.infoSentenceMap)) {
      sentences.push(data.infoSentenceMap[key]);
    }
  }
  return sentences;
}

async function getBatchById(batchId: number): Promise<Batch> {
  const response = await BatchService.getItem(batchId);
  const entity = batchMapper.fromDTO(response.data.batch);
  return entity;
}

async function getTaskById(taskId: number): Promise<Task> {
  const response = await TaskService.getItem(taskId);
  const entity = taskMapper.fromDTO(response.data);
  return entity;
}

async function getFileById(fileId: number): Promise<LabelingFile> {
  const response = await StorageService.getFileInfoDetails(fileId.toString());
  const entity = fileMapper.fromDTO(response.data);
  // const sentences = await getSentencesFromFile(fileId);
  return {
    ...entity,
  };
}

async function getLabels(batchId: number): Promise<Label[]> {
  const payload = { batchId: batchId.toString(), size: "10000" };
  const response = await BatchObservationService.getItems(payload);
  const allBatchObservations: BatchObservationDTO[] = response.data;
  const labels = allBatchObservations.map(textLabelMapper);
  return labels;
}

function buildAnnotationId({
  observationId,
  startIndex,
  endIndex,
}: {
  observationId: number;
  startIndex: number;
  endIndex: number;
}) {
  return `${observationId}:${startIndex}:${endIndex}`;
}

function buildRelationAnnotationId({
  observationId,
  from,
  to,
}: {
  observationId?: number;
  from: string;
  to: string;
}) {
  return `${observationId || ""}-${from}-${to}`.replace(/(^-+|-+$)/gm, "");
}

function parseTextAnnotations(textAnnotation: TextAnnotation, labels: Label[]) {
  const annotations: NERAnnotation[] = [];
  const relationAnnotations: CORAnnotation[] = [];
  const { labels: textLabels, relations, observationId } = textAnnotation;

  for (let label of textLabels) {
    const annotationId = buildAnnotationId(label);
    const observationId = label.observationId.toString();
    annotations.push({
      id: annotationId,
      observationId: observationId,
      startIndex: label.startIndex,
      endIndex: label.endIndex,
    });
  }
  for (let relation of relations) {
    const { fromId, observationId, toId } = relation;
    const fromLabel = textLabels.find((label) => label.id === fromId);
    const toLabel = textLabels.find((label) => label.id === toId);
    if (!fromLabel || !toLabel) continue;
    const observation = labels.find(
      (lb) => lb.observation.id === observationId
    );
    const from = buildAnnotationId(fromLabel);
    const to = buildAnnotationId(toLabel);
    const relationAnnotation: CORAnnotation = {
      observationId: observationId ? observationId.toString() : "",
      color: observation?.color || "#8b91A3",
      text: observation?.name || "",
      from,
      to,
      id: buildRelationAnnotationId({ observationId, from, to }),
    };
    relationAnnotations.push(relationAnnotation);
  }
  return {
    annotations,
    relationAnnotations,
    systemObservationId: observationId,
  };
}

async function labelerGetTextAnnotationsByJob(
  job: Job,
  file: LabelingFile,
  labels: Label[]
): Promise<LabelingJobData> {
  const labelingDatas = await labelerGetTextAnnotationsByFile(
    job.batchId,
    job.taskId,
    file.id,
    labels
  );

  const systemLabelingData = labelingDatas.find(
    (data) => data.source === AnnotationSource.IMPORT
  );

  const aiLabelingData = labelingDatas.find(
    (data) => data.source === AnnotationSource.MODEL
  );

  const data = labelingDatas.find(
    (data) => data.source === AnnotationSource.CLIENT && data.jobId === job.id
  );

  const previousData = labelingDatas.filter(
    (data) => data.source === AnnotationSource.CLIENT && data.jobId !== job.id
  );

  return {
    jobData: data,
    previousJobData: previousData,
    importedData: systemLabelingData,
    generatedData: aiLabelingData,
  };
}

async function labelerGetTextAnnotationsByFile(
  batchId: number,
  taskId: number,
  fileId: number,
  labels: Label[]
): Promise<TextLabelingData[]> {
  const response = await LabelerAnnotationServiceV3.getTextAnnotationsByFile(
    batchId,
    fileId
  );
  const params = { batchId, taskId, fileId, labels };
  return parseTextAnnotationsResponse(response.data, params);
}

async function customerGetTextAnnotationsByFile(
  batchId: number,
  taskId: number,
  fileId: number,
  labels: Label[]
): Promise<TextLabelingData[]> {
  const response = await AnnotationServiceV3.getTextAnnotationsByFile(
    batchId,
    fileId
  );
  const params = { batchId, taskId, fileId, labels };
  return parseTextAnnotationsResponse(response.data, params);
}

function parseTextAnnotationsResponse(
  response: any,
  params: {
    batchId: number;
    taskId: number;
    fileId: number;
    labels: Label[];
  }
): TextLabelingData[] {
  const labelingDatas: TextLabelingData[] = [];
  const anntationEntities: Record<number, NERAnnotation> = {};
  try {
    const { annotations, annotationRelations } = response;
    for (const dto of annotations) {
      const observationId = dto.observationId;
      const labelId = observationId.toString();
      const label = params.labels.find((lb) => lb.id === labelId);
      let labelingData = labelingDatas.find(
        (data) => data.annotator === dto.assignee
      );
      if (!labelingData) {
        labelingData = {
          id: dto.jobId,
          jobId: dto.jobId,
          taskId: params.taskId,
          fileId: dto.mediaId,
          annotator: dto.assignee,
          source: dto.source,
          annotations: [],
          relationAnnotations: [],
          systemObservationId: undefined,
        };
        labelingDatas.push(labelingData);
      }

      if (label?.isSystemLabel) {
        labelingData.systemObservationId = observationId;
        continue;
      }

      const startIndex = dto.annotation.startIndex;
      const endIndex = dto.annotation.endIndex;
      const id = buildAnnotationId({ observationId, startIndex, endIndex });
      const nerAnnotation: NERAnnotation = {
        id,
        observationId: labelId,
        startIndex,
        endIndex,
        annotationId: dto.id,
        annotator: dto.assignee,
      };
      anntationEntities[dto.id] = nerAnnotation;

      if (label && label.isSystemLabel) {
        labelingData.systemObservationId = observationId;
      } else {
        labelingData.annotations.push(nerAnnotation);
      }
    }

    for (const relationDTO of annotationRelations) {
      const { id, first, observationId, second } = relationDTO;
      const fromAnnotation = anntationEntities[first];
      const toAnnotation = anntationEntities[second];
      const labelId = observationId?.toString() || "";
      const label = params.labels.find((lb) => lb.id === labelId);
      if (!fromAnnotation || !toAnnotation) continue;
      const from = fromAnnotation.id;
      const to = toAnnotation.id;
      const relationId = buildRelationAnnotationId({ observationId, from, to });
      const corAnnotation: CORAnnotation = {
        id: relationId,
        text: label?.name || "",
        from,
        to,
        observationId: labelId,
        color: label?.color || "#8b91A3",
        source: fromAnnotation.source,
        annotationId: id,
        annotator: fromAnnotation.annotator,
      };

      const labelingData = labelingDatas.find(
        (data) => data.annotator === fromAnnotation.annotator
      );

      if (!labelingData) continue;
      labelingData.relationAnnotations.push(corAnnotation);
    }
  } catch (error) {
    Sentry.captureException(error);
  }

  return labelingDatas;
}

function isTokenId(id: string) {
  return id?.split(":").length === 2;
}

function isAnnotationId(id: string) {
  return id?.split(":").length === 3;
}

function isRelationId(id: string) {
  return id?.endsWith("-text");
}

export const textUtils = {
  isTokenId,
  isAnnotationId,
  isRelationId,
  getSentencesFromFile,
  getTaskById,
  getFileById,
  getBatchById,
  getLabels,
  labelerGetTextAnnotationsByJob,
  customerGetTextAnnotationsByFile,
  parseTextAnnotations,
  isConflict,
};
