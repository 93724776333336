/*
 * File: create-batch.context.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { isDevelopment } from "configs/env.config";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { CreateEditLabelModal } from "pages/customer/projects/project-label/components/create-edit-label.modal";
import { CreateWorkflowModal } from "pages/customer/workflows/components/create-workflow.modal";
import { useMemo, useCallback, useEffect } from "react";
import { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { CreateBatchPayload } from "services/label-service/apis/batch.api";
import {
  BatchObservationCreationModel,
  BatchType,
  DEFAULT_TIME_LIMIT_SECOND,
  ObservationDTO,
  ProjectDTO,
  StepType,
  WorkflowDTO,
  WorkflowInstructionDTO,
} from "services/label-service/dtos";
import { PricingRequestDTO } from "services/label-service/dtos/batch-pricing.dto";
import {
  DEFAULT_DAILY_LIMIT,
  UserBatchModel,
} from "services/user-service/dtos/user.dto";
import { selectCurrentUser } from "store/auth/auth.selectors";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import {
  selectCurrentProject,
  selectObservations,
  selectProjectObservationAnnotationTypes,
  selectProjectObservations,
} from "store/customer/project/project.selectors";
import { createBatchAsync } from "store/customer/project/project.slice";
import { selectWorkflows } from "store/customer/workflow/workflow.selectors";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import {
  BatchPricingUIModel,
  convertBatchPricingUIModelToPricingRequestDTO,
} from "./billing/batch-price-tabs.component";
import { BatchSteps, DEFAULT_STEPS, StepInfo } from "./create-batch.model";
import { ReviewStepConfigsModel } from "./create-batch/review-step-configs.component";
import { InformationStepDataSubmit } from "./create-batch/step-0-batch-information.component";
import * as Sentry from "@sentry/react";

interface CreateBatchState {
  steps: StepInfo[];
  currentStep: StepInfo | undefined;
  stepIndex: number;
  visible: boolean;
  observations: ObservationDTO[];
  workflows: WorkflowDTO[];
  project: ProjectDTO;
  processing: boolean;
  showCreateBatch(): void;
  nextStep(): void;
  previousStep(): void;
  dismiss(): void;
  submitData(step: BatchSteps, data: any): void;
  handleSubmit(skip?: boolean): void;
  showCreateLabelDialog(): void;
  showCreateWorkflow(): void;
  isCurrentStepCompleted(): boolean;
  createLabelVisibile?: boolean;
  hasError?: boolean;
  setHasError?: any;
}

export const CreateBatchContext = createContext({} as CreateBatchState);

export const useCreateBatchContext = () => {
  return useContext(CreateBatchContext);
};

interface CreateBatchProviderProps {
  children: React.ReactNode | React.ReactNode[] | null;
}

export const CreateBatchProvider = ({ children }: CreateBatchProviderProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentUser = useAppSelector(selectCurrentUser);
  const [visible, setVisible] = useState(false);
  const project = useAppSelector(selectCurrentProject) as ProjectDTO;
  const observations = useAppSelector(selectProjectObservations);
  const observationsWithoutSystem = useAppSelector(selectObservations(true));
  const projectObsAnnotationTypes = useAppSelector(
    selectProjectObservationAnnotationTypes
  );
  const workflows = useAppSelector(selectWorkflows);
  const [defaultSteps] = useState(() => {
    let items = [...DEFAULT_STEPS];
    if (!isDevelopment()) {
      items = items.filter((step) => step.type !== BatchSteps.UNIT_PRICE);
    }
    return items;
  });
  const [steps, setSteps] = useState<StepInfo[]>(() => {
    let items = [...DEFAULT_STEPS];
    if (!isDevelopment()) {
      items = items.filter((step) => step.type !== BatchSteps.UNIT_PRICE);
    }
    return items;
  });
  const [hasError, setHasError] = useState<boolean>(false);

  const [stepIndex, setStepIndex] = useState(0);
  const [createLabelVisibile, setCreateLabelVisible] = useState(false);
  const [createWorkflowVisibile, setCreateWorkflowVisible] = useState(false);
  const [processing, setProcessing] = useState(false);
  const submitData = useCallback(
    (type: BatchSteps, data: any) => {
      setSteps([
        ...steps.map((s) => {
          if (s.type === type) {
            return {
              ...s,
              data: data,
            };
          }
          return s;
        }),
      ]);
    },
    [steps]
  );

  function showCreateLabelDialog() {
    setCreateLabelVisible(true);
  }
  function handleCloseCreateLabel() {
    setCreateLabelVisible(false);
  }
  function showCreateWorkflow() {
    setCreateWorkflowVisible(true);
  }
  function handleCloseCreateWorkflow() {
    setCreateWorkflowVisible(false);
  }

  const currentStep = useMemo(() => {
    return steps.find((s) => s.type === stepIndex);
  }, [steps, stepIndex]);

  const isCurrentStepCompleted = useCallback(() => {
    if (!currentStep) return false;
    if (!currentStep.require) return true;
    return currentStep.data !== null;
  }, [currentStep]);

  function nextStep() {
    setStepIndex((step) => step + 1);
  }

  function previousStep() {
    setSteps([
      ...steps.map((s) => {
        if (s.type === stepIndex) {
          return {
            ...s,
            data: null,
          };
        }
        return s;
      }),
    ]);
    setStepIndex((step) => step - 1);
  }

  function dismiss() {
    setVisible(false);
  }

  function showCreateBatch() {
    setVisible(true);
  }

  async function handleSubmit(skip: boolean = false) {
    if (!currentUser) return;
    if (processing) return;
    setProcessing(true);
    try {
      const batchData: InformationStepDataSubmit & {
        project: ProjectDTO;
      } = steps[0].data;

      batchData.project = project;
      const { workflow, labels } = steps[BatchSteps.WORKFLOW].data;
      const { stepUsers, reviewConfigs } = steps[BatchSteps.MEMBERS].data;
      const batchPricing: BatchPricingUIModel | undefined =
        steps[BatchSteps.UNIT_PRICE]?.data;
      let pricing: PricingRequestDTO | undefined = undefined;
      if (batchPricing && !skip) {
        pricing = convertBatchPricingUIModelToPricingRequestDTO(batchPricing);
      }

      const instructionLabelers = stepUsers as {
        instruction: WorkflowInstructionDTO;
        users: UserBatchModel[];
      }[];
      const batchObservations = labels as BatchObservationCreationModel[];
      const userInstructions = [];
      for (let instructionData of instructionLabelers) {
        let stepReviewConfig: ReviewStepConfigsModel | undefined = undefined;
        if (
          instructionData.instruction.stepType === StepType.ACCEPTANCE &&
          reviewConfigs
        ) {
          stepReviewConfig = reviewConfigs[
            instructionData.instruction.step
          ] as ReviewStepConfigsModel;
        }
        for (let user of instructionData.users) {
          let dailyLimit = user.dailyLimit;
          if (!dailyLimit || dailyLimit < 0 || isNaN(dailyLimit)) {
            dailyLimit = DEFAULT_DAILY_LIMIT;
          }
          let workManagement: any = {
            userId: user.email,
            workInstructionId: instructionData.instruction.id,
            dailyLimit: dailyLimit,
          };
          if (stepReviewConfig) {
            workManagement = {
              ...workManagement,
              percentage: stepReviewConfig.percentage
                ? stepReviewConfig.percentage
                : 0,
              reviewPickType: stepReviewConfig.reviewPickType,
              acceptPercentage: stepReviewConfig.acceptPercentage
                ? stepReviewConfig.acceptPercentage
                : 0,
            };
          }
          userInstructions.push(workManagement);
        }
      }

      let timeLimitSecondValid =
        steps[BatchSteps.WORKFLOW].data.timeLimitSecond;
      if (timeLimitSecondValid < 0 || isNaN(timeLimitSecondValid)) {
        timeLimitSecondValid = DEFAULT_TIME_LIMIT_SECOND;
      }

      const payload: CreateBatchPayload = {
        workManagement: userInstructions,
        workflowId: workflow.id,
        batchObservation: batchObservations.map((label) => {
          return {
            iou: label.iou,
            labelRequired: label.labelRequired,
            probabilityRequired: label.probabilityRequired,
            observationId: label.observation.id,
            attributeAgreement: label.attributeAgreement,
          };
        }),
        autoCreateTask: steps[BatchSteps.DATASOURCE].data.autoCreateTasks,
        datasetCriteria: steps[BatchSteps.DATASOURCE].data.datasetCriteria,
        batch: {
          description: batchData.description,
          instruction: batchData.instruction,
          embeddedType: batchData.embeddedType,
          embeddedContent: batchData.embeddedContent,
          name: batchData.name,
          type: batchData.isEvaluation
            ? BatchType.EVALUATION
            : BatchType.NORMAL,
          projectId: batchData.project.id,
          batchSetting: {
            consensusRate: parseFloat(
              (steps[BatchSteps.WORKFLOW].data.consensusRate / 100).toFixed(2)
            ),
            timeLimitSecond: timeLimitSecondValid,
            consensusEntity: steps[BatchSteps.WORKFLOW].data.consensusEntity,
            consensusRelationship:
              steps[BatchSteps.WORKFLOW].data.consensusRelationship,
            activeLearning: batchData.activeLearning,
            balanceJobAssign: batchData.balanceJobAssign,
          },
        },
        pricing,
      };

      const response = await dispatch(createBatchAsync(payload));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification("Batch created successfuly"));
      dismiss();
      setProcessing(false);
      const url = `${history.location.pathname}/${response.payload.id}`;
      if (response && response.payload && response.payload.id) {
        setTimeout(() => history.push(url), 1000);
      }
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || "Failed to create batch";
      dispatch(enqueueErrorNotification(errMessage));
      setProcessing(false);
    }
  }

  useEffect(() => {
    if (visible) setProcessing(false);
  }, [visible]);

  useEffect(() => {
    setSteps([...defaultSteps]);
    setStepIndex(0);
  }, [visible, defaultSteps]);

  const value: CreateBatchState = {
    project,
    steps,
    stepIndex,
    visible,
    currentStep,
    observations,
    workflows,
    processing,
    nextStep,
    previousStep,
    dismiss,
    submitData,
    showCreateBatch,
    handleSubmit,
    showCreateLabelDialog,
    showCreateWorkflow,
    createLabelVisibile,
    isCurrentStepCompleted,
    hasError,
    setHasError,
  };

  return (
    <CreateBatchContext.Provider value={value}>
      {children}
      <CreateEditLabelModal
        visible={createLabelVisibile}
        onClose={handleCloseCreateLabel}
        projectObsAnnotationTypes={projectObsAnnotationTypes}
        observations={observationsWithoutSystem}
      />
      <CreateWorkflowModal
        visible={createWorkflowVisibile}
        onClose={handleCloseCreateWorkflow}
      />
    </CreateBatchContext.Provider>
  );
};
