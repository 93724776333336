/*
 * File: workspace.state.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";

export interface ImageLabelingState {
  requesting: boolean;
  imageLoaded: boolean;
  showLabelDetail: boolean;
  saving: boolean;
  submitting: boolean;
  showPreviousJobAnnotations: boolean;
  setShowPreviousJobAnnotations(visible: boolean): void;
  selectCornerstoneTool(type: ToolName): void;
  setImageLoaded(loaded: boolean): void;
  setRequesting(requesting: boolean): void;
  setShowLabelDetail(visible: boolean): void;
  selectLabelByIndex(index: number): void;
  selectLabelById(id: number): void;
  selectNextLabel(): void;
  selectPreviousLabel(): void;
  saveCurrentJob(): Promise<void>;
  submitCurrentJob(): Promise<void>;
}

export const defaultImageLabelingState = {} as ImageLabelingState;
