import vtkImageData from "@kitware/vtk.js/Common/DataModel/ImageData";
import vtkDataArray from "@kitware/vtk.js/Common/Core/DataArray.js";
import vtkColorMaps from "@kitware/vtk.js/Rendering/Core/ColorTransferFunction/ColorMaps";
import ImageConstants from "@kitware/vtk.js/Rendering/Core/ImageMapper/Constants";
import vtkInteractorStyleImage from "@kitware/vtk.js/Interaction/Style/InteractorStyleImage";
import vtkInteractorStyleImageCustom from "./vtk/Interaction/InteractorStyleImageCustom";
import { InteractionEventTypes } from "./vtk/Interaction/InteractorStyleImageCustom";
import vtkPiecewiseGaussianWidget from "@kitware/vtk.js/Interaction/Widgets/PiecewiseGaussianWidget";

import vtkWidgetManager from "@kitware/vtk.js/Widgets/Core/WidgetManager";
import vtkPaintWidget from "@kitware/vtk.js/Widgets/Widgets3D/PaintWidget";
import vtkPaintWidgetCustom from "./vtk/Widgets/Widgets3D/PaintWidgetCustom";
import vtkAngleWidget from "@kitware/vtk.js/Widgets/Widgets3D/AngleWidget";
import vtkAngleWidgetCustom from "./vtk/Widgets/Widgets3D/AngleWidgetCustom";
import vtkDistanceWidget from "@kitware/vtk.js/Widgets/Widgets3D/DistanceWidget";
import vtkDistanceWidgetCustom from "./vtk/Widgets/Widgets3D/DistanceWidgetCustom";
import vtkLineWidget from "@kitware/vtk.js/Widgets/Widgets3D/LineWidget";
import vtkLabelWidget from "@kitware/vtk.js/Widgets/Widgets3D/LabelWidget";
import vtkSplineWidget from "@kitware/vtk.js/Widgets/Widgets3D/SplineWidget";
import vtkSplineWidgetCustom from "./vtk/Widgets/Widgets3D/SplineWidgetCustom";
import vtkRectangleWidget from "@kitware/vtk.js/Widgets/Widgets3D/RectangleWidget";
import vtkResliceCursorWidget from "@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget";
import * as resliceCursorHelpers from "@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/helpers";
import { xyzToViewType } from "@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/Constants";

import vtkPaintFilter from "@kitware/vtk.js/Filters/General/PaintFilter";
import vtkImageMarchingCubes from "@kitware/vtk.js/Filters/General/ImageMarchingCubes";
import vtkPaintFilterCustom from "./vtk/Filters/General/PaintFilterCustom";
import {
  ViewTypes,
  CaptureOn,
} from "@kitware/vtk.js/Widgets/Core/WidgetManager/Constants";
import vtkWindowedSincPolyDataFilter from "@kitware/vtk.js/Filters/General/WindowedSincPolyDataFilter";

import vtkBoundingBox from "@kitware/vtk.js/Common/DataModel/BoundingBox";
import {
  c as computeBoundsFromPoints,
  b as roundVector,
  e as clampVector,
} from "@kitware/vtk.js/Common/Core/Math/index.js";

import { fitImageBoundToCamera } from "./vtk/utils";

const { SlicingMode } = ImageConstants;

export {
  vtkImageData,
  vtkDataArray,
  vtkColorMaps,
  ImageConstants,
  vtkInteractorStyleImage,
  vtkInteractorStyleImageCustom,
  vtkWidgetManager,
  vtkPaintWidget,
  vtkPaintWidgetCustom,
  InteractionEventTypes,
  vtkAngleWidget,
  vtkAngleWidgetCustom,
  vtkDistanceWidget,
  vtkDistanceWidgetCustom,
  vtkLineWidget,
  vtkLabelWidget,
  vtkPaintFilter,
  vtkImageMarchingCubes,
  vtkPaintFilterCustom,
  vtkSplineWidget,
  vtkSplineWidgetCustom,
  vtkRectangleWidget,
  vtkResliceCursorWidget,
  resliceCursorHelpers,
  xyzToViewType,
  ViewTypes,
  CaptureOn,
  SlicingMode,
  fitImageBoundToCamera,
  vtkPiecewiseGaussianWidget,
  vtkWindowedSincPolyDataFilter,
  vtkBoundingBox,
  computeBoundsFromPoints,
  roundVector,
  clampVector,
};
