/*
 * File: layout.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:12 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */
import { useState } from "react";
import { LayoutContext } from "./layout.context";
import { LayoutMode } from "./layout.state";
interface LayoutProviderProps {
  children: JSX.Element;
}
export const LayoutProvider = ({ children }: LayoutProviderProps) => {
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [layoutMode, setLayoutMode] = useState(LayoutMode.VERTICAL);
  const [showNavigationBar, setShowNavigationBar] = useState(true);
  const toggleNavigationBar = () => setShowNavigationBar(!showNavigationBar);

  function toggleContextMenu() {
    setContextMenuVisible((pre) => !pre);
  }

  const state = {
    contextMenuVisible,
    layoutMode,
    showNavigationBar,
    toggleNavigationBar,
    setLayoutMode,
    setContextMenuVisible,
    toggleContextMenu,
  };

  return (
    <LayoutContext.Provider value={state}>{children}</LayoutContext.Provider>
  );
};
