/*
 * File: dataset-context.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { featureConfigs } from "configs/feature.config";
import { SearchParams } from "models/common/search-params";
import { createContext, useContext } from "react";
import { RequestOptions } from "services/common/request-options";

export interface DatasetContextState {
  filter: DatasetFilter | null;
  setPage(page: number): void;
  setPageSize(size: number): void;
  setStatus(status: string): void;
  setCloud(cloud: string): void;
  setFileName(fileName: string): void;
  setCreatedDate(createdDate: string): void;
  setCreatedBy(createBy: string): void;
  setTags(tags: string): void;
  setStoreType(storeType: string): void;
  setSort(field: string, sort?: string): void;
  setScope(scopeId: string): void;
  setCurrentDataset(datasetId: string): void;
  requestData(): void;
  resetDataset(): void;
}

export const DatasetContext = createContext({} as DatasetContextState);

export const useDatasetContext = () => {
  return useContext(DatasetContext);
};

export interface DatasetFilter {
  datasetId: string;
  size?: number;
  page?: number;
  status?: string;
  cloud?: string;
  fileName?: string;
  createdDate?: string;
  createdBy?: string;
  tags?: string;
  storeType?: string;
  search?: string;
  sort?: string;
  scope?: string;
}

export async function buildRequestOptions(filter: DatasetFilter | null) {
  if (featureConfigs.isUseESRelatedApis()) {
    return buildRequestOptionsQueryService(filter);
  } else {
    return buildRequestOptionsLabelService(filter);
  }
}

export async function buildRequestOptionsQueryService(filter: DatasetFilter | null) {
  if (!filter || !filter.datasetId) return null;
  const options: RequestOptions = {
    "datasetId": `${filter.datasetId}`,
  };
  if (filter.page) options[SearchParams.PAGE] = (filter.page - 1).toString();
  if (filter.size) options[SearchParams.SIZE] = filter.size.toString();
  if (filter.fileName) options["fileName"] = filter.fileName;
  if (filter.createdDate) await buildDateOptionEqual(filter.createdDate, options);
  if (filter.createdBy) options["createdBy"] = filter.createdBy;
  if (filter.tags) buildTagsOption(filter.tags, options);
  if (filter.storeType) options["storeType"] = filter.storeType;
  if (filter.sort) options[SearchParams.SORT] = filter.sort;
  else options[SearchParams.SORT] = "createdDate,desc";
  if (filter.cloud) options[SearchParams.CLOUD] = filter.cloud;
  if (filter.status) options[`${SearchParams.STATUS}`] = filter.status;
  if (filter.scope) options[`${SearchParams.SCOPE}`] = filter.scope;
  return options;
}

export async function buildDateOptionEqual(date: string, options: RequestOptions) {
  const curDate = new Date(date);
  options["createdDate"] = curDate.getTime().toString();
}

export async function buildDateOption(date: string, options: RequestOptions) {
  const curDate = new Date(date);
  const nextDay = new Date(curDate.getTime() + 24 * 3600 * 1000);
  options["createdDate.greaterThan"] = curDate.toUTCString();
  options["createdDate.lessThan"] = nextDay.toUTCString();
}

export function buildTagsOption(tags: string, options: RequestOptions) {
  if (tags) options["tagName"] = tags;
}

// Old version in LabelSerivce
async function buildRequestOptionsLabelService(filter: DatasetFilter | null) {
  if (!filter || !filter.datasetId) return null;
  const options: RequestOptions = {
    "datasetId.equals": `${filter.datasetId}`,
  };
  if (filter.page) options[SearchParams.PAGE] = (filter.page - 1).toString();
  if (filter.size) options[SearchParams.SIZE] = filter.size.toString();
  if (filter.fileName) options["fileName.contains"] = filter.fileName;
  if (filter.createdDate) await buildDateOption(filter.createdDate, options);
  if (filter.createdBy) options["createdBy.contains"] = filter.createdBy;
  if (filter.tags) buildTagsOption(filter.tags, options);
  if (filter.storeType) options["storeType.contains"] = filter.storeType;
  if (filter.sort) options[SearchParams.SORT] = filter.sort;
  else options[SearchParams.SORT] = "createdDate,desc";
  if (filter.cloud) options[SearchParams.CLOUD] = filter.cloud;
  if (filter.status) options[`${SearchParams.STATUS}.equals`] = filter.status;
  if (filter.scope) options[`${SearchParams.SCOPE}.equals`] = filter.scope;
  return options;
}
