/*
 * File: image-stacks-indicator.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 17th April 2023 4:35:54 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { IconButton } from "@material-ui/core";
import { useEffect, useRef } from "react";
import { VscArrowLeft, VscArrowRight } from "react-icons/vsc";

interface ImageStacksIndicatorProps {
  frame: number;
  numFrames: number;
  onChange(frame: number): void;
  onNext(): void;
  onPrevious(): void;
}

export function ImageStacksIndicator({
  frame,
  numFrames,
  onChange,
  onNext,
  onPrevious,
}: ImageStacksIndicatorProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  function handleOnChange() {
    if (!inputRef.current) return;
    const inputStr = inputRef.current.value.trim();
    if (!inputStr) return;
    let value = Math.min(parseInt(inputStr), numFrames);
    onChange(value - 1);
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = `${frame + 1}`;
    }
  }, [inputRef, frame]);

  return (
    <div className="absolute bottom-0.5 left-6" style={{ zIndex: 9999 }}>
      <div className="flex items-center bg-gray-900 border border-opacity-0 rounded hover:border-opacity-100">
        <IconButton onClick={onPrevious} className="hover:cursor-pointer">
          <VscArrowLeft color="#fff" size={16} />
        </IconButton>
        <input
          defaultValue={frame + 1}
          min={1}
          max={numFrames}
          type="number"
          placeholder="Slice"
          className="rounded bg-gray-700 text-white px-1 py-0.5 w-9 text-sm"
          ref={inputRef}
          step={1}
          onChange={handleOnChange}
        />
        <span className="px-2">/</span>
        <span>{numFrames}</span>
        <IconButton onClick={onNext} className="hover:cursor-pointer">
          <VscArrowRight color="#fff" size={16} />
        </IconButton>
      </div>
    </div>
  );
}

export default ImageStacksIndicator;
