/*
 * File: select-workspace.page.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 3rd November 2021 5:35:33 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppDispatch } from "hooks/use-redux";
import { useWorkspaces } from "hooks/workspace/use-workspace.hook";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser, selectUserRole } from "store/auth/auth.selectors";
import { setUserWorkspace } from "store/auth/auth.slice";
import { LoadingPage } from "../loading/loading.page";

export const SelectWorkspacePage = () => {
  const dispatch = useAppDispatch();
  const { workspaces } = useWorkspaces();
  const currentUser = useSelector(selectCurrentUser);
  const userRole = useSelector(selectUserRole);

  useEffect(() => {
    if (!currentUser || !userRole) return;
    const orderedWorkspaces = [...workspaces].sort((a, b) => {
      const lastSelectA = a.lastSelect == null ? 0 : a.lastSelect;
      const lastSelectB = b.lastSelect == null ? 0 : b.lastSelect;
      return lastSelectB - lastSelectA;
    });
    if (orderedWorkspaces && orderedWorkspaces.length > 0) {
      for (let workspace of orderedWorkspaces) {
        const workspaceId = workspace.groupId;
        if (currentUser.workspace.hasOwnProperty(userRole)) {
          const roleWorkspaces = currentUser.workspace[userRole];
          if (roleWorkspaces.includes(workspaceId)) {
            dispatch(setUserWorkspace(workspaceId));
            return;
          }
        }
      }
    }
  }, [dispatch, workspaces, currentUser, userRole]);

  return <LoadingPage />;
};
