import { JobDTO } from "../dtos/job.dto";
import { TaskObservationRequestDTOV3, TaskObservationV3DTO } from "../dtos/task-observation-v3.dto";
import { BaseAPI } from "./base.api";

export class JobV3API extends BaseAPI<JobDTO> {
  
  finishJob(payload: TaskObservationV3DTO[] | TaskObservationRequestDTOV3[]) {
    return this.post(`${this.getPath()}/finish`, payload);
  }
}
