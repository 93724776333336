/*
 * File: use-saving-job.hook.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 16th April 2022 2:11:49 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { CommonError, CommonErrorCode } from "domain/common/common-error";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { selectIsMedicalMamoProject } from "store/customer/project/project.selectors";
import {
  selectBatchLabelingIsPollByGroupProject,
  selectBatchLabelingJobIdsInBatch,
  selectBatchLabelingJobIdsInView,
  selectImageLabelingActiveJobId,
} from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { jobCompleted } from "store/labeler/image-workspace/batch-labeling/batch-labeling.slice";
import { imageSaveJobAsync } from "store/labeler/image-workspace/batch-labeling/thunks/image-save-job.thunk";
import { imageValidateJobAsync } from "store/labeler/image-workspace/batch-labeling/thunks/image-validate-job.thunk";
import { imageVoteAnnotationAsync } from "store/labeler/image-workspace/batch-labeling/thunks/image-vote-annotation.thunk";
import {
  selectIsCompleteJobsInBatch,
  selectIsCompleteJobsInView,
} from "store/labeler/image-workspace/editor-setting/editor-setting.selectors";
import {
  selectIsImageLabelingReadonly,
  selectIsReviewJob,
} from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import {
  imageAnnotationJobCompleted,
  setSelectedSystemObservationId,
} from "store/labeler/image-workspace/image-workspace.slice";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import * as Sentry from "@sentry/react";

export enum ALERT_ACTION_TYPE {
  SAVE = 1,
  COMPLETE = 2,
}

interface SaveJobError extends CommonError {
  type: number;
}

interface Props {
  imageLoaded: boolean;
  setRequesting(requesting: boolean): void;
}
export const useSavingJob = (props: Props) => {
  const { imageLoaded } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [error, setError] = useState<SaveJobError | undefined>(undefined);
  const [saving, setSaving] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const isReadonly = useAppSelector(selectIsImageLabelingReadonly);
  const activeJobId = useAppSelector(selectImageLabelingActiveJobId);
  const isReviewJob = useAppSelector(selectIsReviewJob);

  // HARD CODE for the breast cancer problem >.<
  const isPollByGroupProject = useAppSelector(
    selectBatchLabelingIsPollByGroupProject
  );
  const isMammographyProject = useAppSelector(selectIsMedicalMamoProject);
  const jobIdsInView = useAppSelector(selectBatchLabelingJobIdsInView);
  const jobIdsInBatch = useAppSelector(selectBatchLabelingJobIdsInBatch);
  const isCompleteJobsInView = useAppSelector(selectIsCompleteJobsInView);
  const isCompleteJobsInBatch = useAppSelector(selectIsCompleteJobsInBatch);

  const jobIdsToSave = useMemo(() => {
    if(isMammographyProject) return jobIdsInBatch;
    // the order is important here
    if (isCompleteJobsInBatch && isPollByGroupProject) return jobIdsInBatch;
    if (isCompleteJobsInView && isPollByGroupProject) return jobIdsInView;
    return jobIdsInView;
  }, [
    isPollByGroupProject,
    jobIdsInBatch,
    jobIdsInView,
    isCompleteJobsInView,
    isCompleteJobsInBatch,
    isMammographyProject
  ]);

  const jobIdsToComplete = useMemo(() => {
    if(isMammographyProject) return jobIdsInBatch;
    // the order is important here
    if (isCompleteJobsInBatch && isPollByGroupProject) return jobIdsInBatch;
    if (isCompleteJobsInView && isPollByGroupProject) return jobIdsInView;
    return [activeJobId];
  }, [
    isPollByGroupProject,
    activeJobId,
    jobIdsInBatch,
    jobIdsInView,
    isCompleteJobsInView,
    isCompleteJobsInBatch,
    isMammographyProject
  ]);

  async function completeJobs(jobIds: number[]) {
    if (isReadonly) {
      const errMessage = "You are not allow to modify this task!";
      dispatch(enqueueErrorNotification(errMessage));
      return;
    }
    if (submitting) return;
    try {
      setSubmitting(true);
      const response = isReviewJob
        ? await dispatch(imageVoteAnnotationAsync({ jobIds, finish: true }))
        : await dispatch(
            imageSaveJobAsync({
              jobIds,
              finish: true,
            })
          );
      handleThunkRejected(response);
      for (const jobId of jobIds) {
        dispatch(jobCompleted(jobId));
        dispatch(imageAnnotationJobCompleted(jobId));
      }
      dispatch(setSelectedSystemObservationId(-1));
      dispatch(enqueueSuccessNotification(t("common:textCompleted")));
      setSubmitting(false);
    } catch (err: any) {
      Sentry.captureException(err);
      const errMessage =
        err.message || t("labelerworkspace:panel.textSubmitFailedWorkMessage");
      dispatch(enqueueErrorNotification(errMessage));
      setSubmitting(false);
    } finally {
      setSubmitting(false);
    }
  }

  async function handleClickSave() {
    if (!imageLoaded || saving || !!error) return;
    // We always save jobs in current view
    for (const jobId of jobIdsToSave) {
      const result = await dispatch(imageValidateJobAsync({ jobId }));
      const error = result.payload || (result as any).error;
      if (error?.errorCode === CommonErrorCode.ERROR) {
        dispatch(enqueueErrorNotification(error.message || ""));
        return;
      }
      if (error?.errorCode === CommonErrorCode.WARNING) {
        setError({ ...error, type: ALERT_ACTION_TYPE.SAVE });
        return;
      } else if (error?.message) {
        const message = error.message;
        dispatch(enqueueErrorNotification(message));
        return;
      }
    }
    return onSaveJobs();
  }

  async function saveJobs(jobIds: number[]) {
    if (!imageLoaded || saving) return;
    try {
      setSaving(true);
      const response = isReviewJob
        ? await dispatch(imageVoteAnnotationAsync({ jobIds }))
        : await dispatch(imageSaveJobAsync({ jobIds }));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textSavedSuccess")));
      setSaving(false);
    } catch (error: any) {
      Sentry.captureException(error);
      setSaving(false);
      const errMessage = error.message || t("common:textSavedFailed");
      dispatch(enqueueErrorNotification(errMessage));
      Logger.log(error);
    }
  }

  async function handleClickComplete() {
    if (submitting || !!error) return;
    for (const jobId of jobIdsToComplete) {
      const result = await dispatch(imageValidateJobAsync({ jobId }));
      if (result?.payload as CommonError) {
        const error = result.payload as CommonError;
        if (error.errorCode === CommonErrorCode.ERROR) {
          dispatch(enqueueErrorNotification(error.message || ""));
          return;
        }
        if (error.errorCode === CommonErrorCode.WARNING) {
          setError({ ...error, type: ALERT_ACTION_TYPE.COMPLETE });
          return;
        }
      }
    }
    return onCompleteJobs();
  }

  async function onSaveJobs(ids?: number[]) {
    if (saving) return;
    await saveJobs(ids || jobIdsToSave);
  }

  async function onCompleteJobs(ids?: string[]) {
    if (submitting) return;
    await completeJobs(jobIdsToComplete || ids);
  }

  return {
    error,
    setError,
    onSaveJobs,
    onCompleteJobs,
    onClickSaveJob: handleClickSave,
    onClickCompleteJob: handleClickComplete,
    saving,
    submitting,
  };
};
