/*
 * File: index.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { NotFoundPage } from "pages/authorization/not-found/not-found.page";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  selectCurrentUser,
  selectUserHasWorkspace,
  selectUserRole,
  selectUserWorkspace,
} from "store/auth/auth.selectors";
import { UserRole } from "store/auth/auth.state";
import { CustomerRouter } from "./customer/customer-router";
import { GuestRouter } from "./guest/guest-router";
import { LabelerRouter } from "./labeler/labeler-router";
import { SelectRolePage } from "pages/authorization/select-role/select-role.page";
import { CreateWorkspacePage } from "pages/authorization/create-workspace/create-workspace.page";
import { SelectWorkspacePage } from "pages/authorization/select-workspace/select-workspace.page";
import { useEffect } from "react";
import { loadPaymentCurrenciesAync } from "store/common/payment/payment.slice";
import { Switch, useLocation } from "react-router-dom";
import { PublicRoute } from "./common/public-route";
import { Routes } from "./config/routes";
import { routeMatch } from "hooks/use-route-match.hook";
import { WebBuilderPreviewProvider } from "pages/customer/web-builder-preview/context/web-builder-preview.provider";
import { MLWebBuilderPreviewPage } from "pages/customer/web-builder-preview/web-builder-preview.page";
import { loadSupportedSystemColorsAsync } from "store/common/app-setting/app-setting.slice";
import * as Sentry from "@sentry/react";

export function AppRouter() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const hasWorkspace = useAppSelector(selectUserHasWorkspace);
  const userRole = useAppSelector(selectUserRole);
  const currentWorkspace = useAppSelector(selectUserWorkspace);
  const location = useLocation();

  useEffect(() => {
    if (!currentUser || !currentWorkspace || !hasWorkspace) return;
    
    try {
      dispatch(loadPaymentCurrenciesAync());
      dispatch(loadSupportedSystemColorsAsync());
    } catch (error) {
      Sentry.captureException(error);
      console.log("error", error);
    }
  }, [currentUser, currentWorkspace, hasWorkspace, dispatch]);

  if (isPublishRoute(location.pathname)) return <PublicRouter />;
  if (!currentUser) return <GuestRouter />;
  if (!hasWorkspace) return <CreateWorkspacePage />;
  if (!userRole) return <SelectRolePage />;
  if (!currentWorkspace) return <SelectWorkspacePage />;
  if (userRole === UserRole.CUSTOMER) return <CustomerRouter />;
  if (userRole === UserRole.LABELER) return <LabelerRouter />;
  return <NotFoundPage />;
}

const publishRoutes = [Routes.MODEL_WEB];

export function isPublishRoute(path: string) {
  for (let route of publishRoutes) {
    const { exact } = routeMatch(path, route);
    if (exact) return true;
  }
  return false;
}

const PublicRouter = () => {
  return (
    <Switch>
      <PublicRoute
        exact
        component={() => (
          <WebBuilderPreviewProvider publicAccess>
            <MLWebBuilderPreviewPage />
          </WebBuilderPreviewProvider>
        )}
        path={Routes.MODEL_WEB}
      />
    </Switch>
  );
};
