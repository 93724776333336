import { Collapse } from "@material-ui/core";
import { IconEye, IconEyeSlash } from "components/common/vb-icon.component";
import { Annotation } from "domain/image-labeling";
import { useAppDispatch } from "hooks/use-redux";
import { useMemo, useState } from "react";
import { updateActiveAnnotation } from "store/labeler/image-workspace/image-workspace.slice";
import { ActionPanelAnnotation } from "./action-panel-annotation.component";
import { ActionPanelLabelIcon } from "./action-panel-label-icon.component";

interface ActionPanelLabelProps {
  labelId: number;
  labelName?: string;
  annotations: Annotation[];
}

export const ActionPanelLabel = ({
  labelId,
  labelName,
  annotations,
}: ActionPanelLabelProps) => {
  const dispatch = useAppDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isVisibleAnnotations, setVisibleAnnotations] = useState(true);

  const labelType = useMemo(() => {
    if (!annotations || !annotations.length) return "";
    const annotationData = annotations[0].annotationData;
    if (!annotationData || !annotationData.length) return "";
    return annotationData[0].type;
  }, [annotations]);

  const handleDisplayAnnotations = () => {
    annotations.forEach((annotation) => {
      if (annotation.labelId !== labelId) return;
      dispatch(
        updateActiveAnnotation({
          annotationId: annotation.id,
          selected: !isVisibleAnnotations,
        })
      );
    });
    setVisibleAnnotations(!isVisibleAnnotations);
  };

  const handleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <div className="bg-background-800">
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start gap-4 pl-4">
          <button onClick={handleCollapse}>
            {!isCollapsed ? (
              <i className="uir-angle-small-down" />
            ) : (
              <i className="uir-angle-small-right" />
            )}
          </button>
          <div className="flex justify-start items-center gap-2">
            <ActionPanelLabelIcon type={labelType} />
            <div className="text-base font-medium">{labelName}</div>
          </div>
        </div>

        <div className="pr-6">
          {isVisibleAnnotations ? (
            <IconEye
              width={20}
              height={20}
              stroke="#fff"
              onClick={handleDisplayAnnotations}
            />
          ) : (
            <IconEyeSlash
              width={20}
              height={20}
              stroke="#fff"
              onClick={handleDisplayAnnotations}
            />
          )}
        </div>
      </div>

      <Collapse in={!isCollapsed} timeout="auto" className="mt-4" unmountOnExit>
        <div className="space-y-2">
          {annotations.map((annotation) => (
            <div
              key={annotation.id}
              className="rounded py-2 pl-12 pr-6 hover:bg-white hover:bg-opacity-5 cursor-pointer"
            >
              <ActionPanelAnnotation annotation={annotation} />
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};
