/*
 * File: text-workspace.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 14th October 2021 10:39:52 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useMount } from "ahooks";
import ConfigService from "configs/app-config";
import { Label } from "domain/text-labeling";
import { useAppSetting } from "hooks/use-app-setting.hook";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { BatchDetailDialog } from "pages/labeler/image-labeling/components/batch-detail-dialog/batch-detail-dialog.component";
import { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Routes } from "routers/config/routes";
import { textLabelingJobEdited } from "store/labeler/text-labeling-batch/text-labeling-batch.slice";
import {
  selectTextObservations,
  selectTextRelations,
} from "store/labeler/text-workspace/text-editor/text-editor.selector";
import {
  selectTextLabelingBatch,
  selectTextLabelingJobId,
  selectTextLabelingProject,
  selectTextLabelingSentences,
  selectTextLabels,
  selectTextShowBatchObservation,
} from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { setShowBatchInstruction } from "store/labeler/text-workspace/text-workspace.slice";
import useDeepCompareEffect from "use-deep-compare-effect";
import { ConfirmExitDialog } from "../../components/confirm-exit-dialog.component";
import { TextWorkspaceContext } from "./text-workspace.context";

interface Props {
  isLoading?: boolean;
  isLoadingJob?: boolean;
  children: JSX.Element;
  onClose?(): void;
}

export const TextWorkspaceProvider = ({
  isLoading,
  isLoadingJob,
  children,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [confirmExit, setShowConfirmExit] = useState(false);
  const textJobId = useAppSelector(selectTextLabelingJobId);
  const labels = useAppSelector(selectTextLabels);
  const sentences = useAppSelector(selectTextLabelingSentences);
  const labelEntities = useMemo(() => {
    const map: Record<string, Label> = {};
    for (let label of labels) {
      map[label.id] = label;
    }
    return map;
  }, [labels]);
  const relations = useAppSelector(selectTextRelations);
  const observations = useAppSelector(selectTextObservations);
  const showBatchInstruction = useAppSelector(selectTextShowBatchObservation);
  const project = useAppSelector(selectTextLabelingProject);
  const batch = useAppSelector(selectTextLabelingBatch);
  const isLoaded = useRef(false);
  const [isShowAnnotationSources, setShowAnnotationSources] = useState(false);
  const { appConfig } = useAppSetting();
  const tokenizer = useMemo(() => {
    return ConfigService.getTextTokenizer(appConfig, project?.type);
  }, [appConfig, project]);
  function onCloseBatchInstruction() {
    dispatch(setShowBatchInstruction(false));
  }

  function handleConfirmExit() {
    if (onClose) {
      onClose();
      return;
    }
    setShowConfirmExit(true);
  }

  const state = {
    isLoading,
    isLoadingJob,
    tokenizer,
    labels: isLoadingJob ? [] : labels,
    labelEntities: isLoadingJob ? {} : labelEntities,
    observations: isLoadingJob ? [] : observations,
    sentences: isLoadingJob ? [] : sentences,
    connections: isLoadingJob ? [] : relations,
    isShowAnnotationSources,
    setShowAnnotationSources,
    handleConfirmExit,
  };

  useDeepCompareEffect(() => {
    if (!isLoaded.current) return;
    if (textJobId) dispatch(textLabelingJobEdited(textJobId));
  }, [dispatch, relations, observations, textJobId]);

  useMount(() => {
    setTimeout(() => (isLoaded.current = true), 1000);
  });

  return (
    <TextWorkspaceContext.Provider value={state}>
      {children}
      {confirmExit && (
        <ConfirmExitDialog
          visible
          onClose={() => setShowConfirmExit(false)}
          onSubmit={() => history.push(Routes.LABELER_HOME)}
        />
      )}

      {showBatchInstruction && (
        <BatchDetailDialog
          batch={batch}
          project={project}
          onClose={onCloseBatchInstruction}
        />
      )}
    </TextWorkspaceContext.Provider>
  );
};
