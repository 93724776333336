import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import TableRow from "@material-ui/core/TableRow";
import classnames from "classnames";
import { getDeep } from "utilities/object/getDeep";

import TableBaseCell from "./table-base-cell.component";
import { TableBaseRowProps, TableColumn } from "./types";

interface TableBaseRowCollapseProps<TData> extends TableBaseRowProps<TData> {
  allowToExpand?: boolean;
  selected?: boolean;
  children?: React.ReactNode;
  defaultExpand?: boolean;
  render?: (columns?: TableColumn[], record?: TData) => React.ReactNode;
}

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export const TableBaseRowCollapse = <T extends object>({
  allowToExpand = false,
  classNames,
  selected = false,
  children,
  defaultExpand = false,
  render,
  dataIndex,
  ...tableBaseRowProps
}: TableBaseRowCollapseProps<T>) => {
  const classes = useRowStyles();
  const [isExpand, setIsExpand] = useState(defaultExpand);
  const { columns, record } = tableBaseRowProps;

  const handleClickExpandedButton = useCallback(() => {
    setIsExpand(!isExpand);
  }, [isExpand]);

  const renderCell = (column: TableColumn) => {
    if (typeof column.render === "function") {
      return column.render(record, dataIndex);
    }

    return (
      <span className="text-background-700">
        {column.dataIndex
          ? getDeep(record, column.dataIndex)
          : column.emptyValue}
      </span>
    );
  };

  return (
    <>
      <TableRow
        {...tableBaseRowProps}
        className={classnames(
          classes.root,
          tableBaseRowProps.className,
          classNames?.row
        )}
        selected={selected}
      >
        <TableBaseCell className="w-8 h-8">
          {allowToExpand && (
            <button
              onClick={handleClickExpandedButton}
              className="flex items-center w-full h-full justify-left"
            >
              {isExpand ? (
                <i className="uir-angle-small-down" />
              ) : (
                <i className="uir-angle-small-right" />
              )}
            </button>
          )}
        </TableBaseCell>
        {columns.map((column) => (
          <TableBaseCell key={column.key} {...column.cellProps}>
            {renderCell(column)}
          </TableBaseCell>
        ))}
      </TableRow>

      <TableRow {...tableBaseRowProps}>
        <TableBaseCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={isExpand} timeout="auto" unmountOnExit>
            <span> {render ? render(columns, record) : children}</span>
          </Collapse>
        </TableBaseCell>
      </TableRow>
    </>
  );
};
