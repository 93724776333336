/*
 * File: image-annotations-picker.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 14th October 2022 2:36:10 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Backdrop } from "@material-ui/core";
import { MatModal } from "components/material/mat-modal.component";
import { Annotation } from "domain/image-labeling";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";
import { ImageLabelingData } from "domain/labeling/labeling-data";
import { useAppSelector } from "hooks/use-redux";
import { useMemo } from "react";
import { isMedicalMamoProject } from "services/label-service/dtos";
import { selectImageLabelingJobById } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { selectImageLabelingAllLabels } from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { isFileDicom } from "utilities/dicom/dicom.utils";
import { ImageAnnotationSourcesProvider } from "./context/image-annotation-sources.provider";
import ImageAnnotationSources from "./image-annotation-sources.component";

interface Props {
  jobId: number;
  onClose(): void;
  onSubmit?(annotations: Annotation[], realtions: RelationAnnotation[]): void;
}
export default function ImageAnnotationsPicker({
  jobId,
  onClose,
  onSubmit,
}: Props) {
  const labels = useAppSelector(selectImageLabelingAllLabels);
  const labelingJob = useAppSelector(selectImageLabelingJobById(jobId));
  const imageUrl = useMemo(() => {
    const url = labelingJob.file?.url || "";
    if (
      isMedicalMamoProject(labelingJob.project?.type) ||
      isFileDicom(labelingJob.file)
    ) {
      return `wadouri:${url}`;
    }
    return url;
  }, [labelingJob]);

  const labelingDatas = useMemo(() => {
    const datas: ImageLabelingData[] = [];
    if (labelingJob?.data?.generatedData) {
      datas.push(labelingJob.data.generatedData as ImageLabelingData);
    }
    if (labelingJob?.data?.importedData) {
      datas.push(labelingJob.data.importedData as ImageLabelingData);
    }
    if (labelingJob?.data?.previousJobData) {
      for (const data of labelingJob.data.previousJobData) {
        datas.push(data as ImageLabelingData);
      }
    }
    return datas;
  }, [labelingJob]);
  if (jobId === -1 || !labelingJob?.data) return null;

  return (
    <ImageAnnotationSourcesProvider
      imageUrl={imageUrl}
      labels={labels}
      labelingDatas={labelingDatas}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <MatModal
        open
        closeAfterTransition
        disableAutoFocus
        BackdropComponent={Backdrop}
        onClose={onClose}
        className="relative flex items-center justify-center outline-none"
      >
        <div className="w-full h-full">
          <ImageAnnotationSources />
        </div>
      </MatModal>
    </ImageAnnotationSourcesProvider>
  );
}
