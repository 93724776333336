/*
 * File: vb-resizable-layout.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 14th October 2021 3:52:35 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { HTMLProps, ReactNode } from "react";
import { VBResizer } from "../vb-resizer.component";

interface VBResizableLayoutProps extends HTMLProps<HTMLDivElement> {
  header?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  children?: ReactNode;
}
export const VBResizableLayout = ({
  header,
  left,
  right,
  children,
  className,
  ...rest
}: VBResizableLayoutProps) => {
  return (
    <div className={`w-full h-full flex flex-col ${className}`} {...rest}>
      {header}
      <div className="flex flex-grow h-1 overflow-hidden">
        {left && (
          <>
            {left}
            <VBResizer />
          </>
        )}
        <div className="flex-grow w-1 h-full overflow-y-auto">{children}</div>
        {right && (
          <>
            <VBResizer direction="right" />
            {right}
          </>
        )}
      </div>
    </div>
  );
};
