import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect } from "react";
import { selectComplexJobsBatchJobs } from "store/labeler/complex-jobs/complex-jobs.selectors";
import { pollComplexBatchJobsAsync } from "store/labeler/complex-jobs/complex-jobs.thunk";
import { JobInBatchComponent } from "./components/job-in-batch.component";

interface Props {
  projectId: string | number;
}
export const AudioJobsNav = ({ projectId }: Props) => {
  const dispatch = useAppDispatch();
  const batchJobs = useAppSelector(selectComplexJobsBatchJobs);

  useEffect(() => {
    const shouldLoad = batchJobs.length === 0 && projectId;

    if (!shouldLoad) return;

    dispatch(
      pollComplexBatchJobsAsync({
        projectId,
        limit: 50,
        loadBatchObservation: true,
      })
    );
  }, [batchJobs, dispatch, projectId]);

  return (
    <div className="h-screen gap-2 p-2 overflow-y-auto border-r border-gray-600 w-44 bg-background-900">
      {batchJobs.map((batchJob, index) => {
        return (
          <JobInBatchComponent
            key={batchJob.id}
            index={index + 1}
            jobId={batchJob.id}
            status={batchJob.status}
            fileName={batchJob.loadJobComplexRes?.file?.fileName || ""}
          />
        );
      })}
    </div>
  );
};
