/*
 * File: label-distribution.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 26th August 2021 5:52:55 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from 'react-i18next';
import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { useBatchLabelDistribution } from "hooks/batch/use-batch-label-distribution.hook";
import { useAppSelector } from "hooks/use-redux";
import { useParams } from "react-router-dom";
import { RequestStatus } from "store/base/base.state";
import {
  selectBatchLabels,
  selectBatchInstructions,
} from "store/customer/batch/batch.selectors";
import { StepDistribution } from "./step-distribution.component";

type RouteParams = { batchId: string };
export const LabelDistribution = () => {
  const { t } = useTranslation();
  const { batchId } = useParams<RouteParams>();
  const batchObservations = useAppSelector(selectBatchLabels);
  const batchInstructions = useAppSelector(selectBatchInstructions);
  const { requestStatus } = useBatchLabelDistribution(parseInt(batchId));
  if (requestStatus === RequestStatus.LOADING) return <VBInlineLoading />;

  return (
    <div>
      <div className="py-4">
        <div className="pb-4 font-bold">{t('project:batchDetails.labelDistribution.textGeneral')}</div>
        <StepDistribution
          batchId={parseInt(batchId)}
          step={0}
          observations={batchObservations.map(
            (batchObservation) => batchObservation.observation
          )}
        />
      </div>
      {batchInstructions.map((instruction) => {
        return (
          <div className="py-4" key={instruction.id}>
            <div className="flex items-center py-4">
              <span className="font-bold">{instruction.name}: </span>
              <span
                className="pl-2"
                dangerouslySetInnerHTML={{
                  __html: instruction.description,
                }}
              ></span>
            </div>
            <StepDistribution
              batchId={parseInt(batchId)}
              step={instruction.step}
              observations={batchObservations.map(
                (batchObservation) => batchObservation.observation
              )}
            />
          </div>
        );
      })}
    </div>
  );
};
