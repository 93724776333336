/*
 * File: text-editor.state.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 5:02:30 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Issue } from "domain/common/issue";

export interface TextIssuesState {
  issues: Issue[];
  selectedTextIssueId: number;
  showIssues: boolean;
}

export const INITIAL_TEXT_ISSUES_STATE: TextIssuesState = {
  issues: [],
  showIssues: false,
  selectedTextIssueId: -1,
};
