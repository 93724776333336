import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { fakeApiCall } from "services/mock";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { updateIssueComment } from "store/common/issue-management/issue-management.slice";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { classnames } from "utilities/classes";
import { useDateToAgoString } from "utilities/date-time/date-to-ago-string";
import { truncateEmail } from "utilities/string/truncate-email";
import { IssueAvatar } from "./issue-avatar.component";
import { IssueCommentModel } from "./issue-management.models";
import * as Sentry from "@sentry/react";


interface Props {
  comment: IssueCommentModel,
  showAvatarOnly?: boolean,
  isLoading?: boolean,
  showMenu?: boolean,
}
export const IssueComment = ({
  comment,
  showAvatarOnly = false,
  isLoading = false,
  showMenu = true,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const timeAgo = useDateToAgoString(comment.createdTime, new Date());

  const [isHovered, setIsHoverd] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(comment.content);

  const [isSaving, setIsSaving] = useState(false);
  // const [isDeleting, setIsDeleting] = useState(false);

  const showEditMenu = useMemo(() => {
    return showMenu && currentUser?.email === comment.user && isHovered;
  }, [showMenu, currentUser, comment, isHovered]);

  const handleSaveComment = async () => {
    try {
      if (isSaving) return;
      setIsSaving(true);
      // TODO: call api later
      await fakeApiCall(0.2);
      console.log("Call update comment successfully!");
      dispatch(updateIssueComment({...comment, content: editedContent}));
      setIsEditing(false);
      setIsSaving(false);
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    }
  }

  const handleCancelEdit = () => {
    setEditedContent(comment.content);
    setIsEditing(false);
  }

  // const handleDeleteComment = async () => {
  //   try {
  //     if (isDeleting) return;
  //     setIsDeleting(true);
  //     // TODO: call api later
  //     await fakeApiCall(0.2);
  //     console.log("Call delete comment successfully!");
  //     dispatch(deleteIssueComment(comment));
  //     setIsDeleting(false);
  //   } catch (error: any) {
  //     dispatch(enqueueErrorNotification(t("common:textFailed")));
  //   }
  // }

  return (
    <div 
      className={classnames(
        "flex gap-2 text-black",
        {"p-1": showAvatarOnly},
        {"p-2": !showAvatarOnly}
      )}
      onMouseEnter={() => setIsHoverd(true)}
      onMouseLeave={() => setIsHoverd(false)}
    >
      <IssueAvatar
        name={comment.user}
        width={"30px"}
        isLoading={isLoading}
      />
      {
        !showAvatarOnly && 
        <div className="flex flex-col gap-1 text-sm"
          style={{
            width: "200px",
          }}
        >
          {
            !isEditing &&
            <Fragment>
              <div className="flex items-center gap-1">
                <span className="font-bold">{truncateEmail(comment.user)}</span>
                <span className="text-background-400">{timeAgo}</span>
                <div className="flex-1" />
                {
                  showEditMenu &&
                  <div className="flex items-center gap-2">
                    {/* <IconEdit
                      className="cursor-pointer w-3 h-3"
                      onClick={() => setIsEditing(true)}
                    />
                    <IconTrash
                      className="cursor-pointer w-3 h-3"
                      onClick={handleDeleteComment}
                    /> */}
                  </div>
                }
                
              </div>
              <span>{comment.content}</span>
            </Fragment>
          }
          {
            isEditing &&
            <div className="w-full flex-col gap-2">
              <textarea
                className="border-0 rounded p-2 text-sm bg-background-100 w-full"
                rows={3}
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value as string)}
              />
              <div className="w-full flex items-center gap-2 bottom-0 right-0">
                <div className="flex-1" /> 
                <button
                  className="button-secondary"
                  disabled={isSaving}
                  onClick={handleCancelEdit}
                >
                  {t("common:buttonCancel")}
                </button>
                <button
                  className="button-secondary"
                  disabled={isSaving || editedContent === comment.content}
                  onClick={handleSaveComment}
                >
                  {t("common:buttonUpdate")}
                </button>
              </div>
            </div>
          }
        </div>
      }
    </div>
  );
}