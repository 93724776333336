/*
 * File: image-label-information.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 2nd November 2021 3:37:52 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppSelector } from "hooks/use-redux";
import { ObservationInformation } from "pages/labeler/components/observation-info/observation-info.component";
import { selectSelectedObservation } from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { useImageLabelingContext } from "../../context/image-labeling.context";

export const ImageLabelInformation = () => {
  const { showLabelDetail, setShowLabelDetail } = useImageLabelingContext();
  const observation = useAppSelector(selectSelectedObservation);
  const handleClose = () => setShowLabelDetail(false);
  if (!showLabelDetail || !observation) return null;
  return <ObservationInformation label={observation} onClose={handleClose} />;
};
