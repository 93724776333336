/*
 * File: project-breadcrumb.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 20th July 2021 10:34:05 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Link } from "react-router-dom";
import { Breadcrumb, useProjectContext } from "../project.context";

export const ProjectBreadcrumb = () => {
  const { breadcrumbs } = useProjectContext();
  if (breadcrumbs.length === 0) return null;
  return (
    <div className="flex items-center py-4 text-gray-400">
      {breadcrumbs.map((breadcrumb) => {
        return <BreadcrumbItem breadcrumb={breadcrumb} key={breadcrumb.url} />;
      })}
    </div>
  );
};

export const BreadcrumbItem = ({
  breadcrumb: { url, text, disabled },
}: {
  breadcrumb: Breadcrumb;
}) => {
  if (disabled) return <div className="text-lg font-bold capitalize text-primary">{text}</div>;
  return (
    <Link to={url}>
      <button className="text-black capitalize focus:outline-none hover:underline">
        {text}
      </button>
      <span className="px-3">/</span>
    </Link>
  );
};
