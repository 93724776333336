/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 3rd December 2021 1:21:26 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import axios, { AxiosInstance, AxiosResponse } from "axios";
import { ENV_CONFIG } from "configs/env.config";
import { AnnotateType } from "constants/annotation.constant";
import { setupAuthInterceptor } from "services/common/auth-interceptor";
import {
  AutoAnnotatePair,
  ObjectDetectionClass,
} from "store/labeler/image-workspace/smart-labeling/smart-labeling.state";
import { TextLabelPair } from "store/labeler/text-workspace/text-ai/text-ai.state";
import { Logger } from "utilities/logger";
import { Point } from "utilities/math/point";
import * as Sentry from "@sentry/react";
import { Tensor } from "onnxruntime-web";

let _axiosInstance: AxiosInstance | undefined = undefined;

function getAxiosInstance() {
  if (_axiosInstance) return _axiosInstance;
  _axiosInstance = setupAuthInterceptor(
    axios.create({
      baseURL: ENV_CONFIG.AI_ASSISTANCE_SERVICE_URL,
    })
  );
  return _axiosInstance;
}

export enum AutoAnnotateType {
  BBOX = "bbox",
  POLYGON = "polygon",
}

export interface AutoAnnotateImagePayload {
  imageUrl: string;
  points: { x: number; y: number }[];
  type: AutoAnnotateType;
  classes: string[];
}

export interface AutoAnnotateObjectsPayload {
  imageUrl: string;
  type: AutoAnnotateType;
  classes: { name: string; model: string }[];
}

export function getHealth() {
  return getAxiosInstance().get("/health");
}

export function getTextClasses(): Promise<
  AxiosResponse<
    {
      id: string;
      name: string;
    }[]
  >
> {
  return getAxiosInstance().get("/ner/getClass");
}

export function getModelClassList(): Promise<
  AxiosResponse<
    {
      metadata: string;
      name: string;
      description: string;
      type: string[];
      model: string;
    }[]
  >
> {
  return getAxiosInstance().get("/classes/metadata");
}

export function initImage(imageUrl: string) {
  return getAxiosInstance().post("/images/init", { imageUrl: imageUrl });
}

export function removeImage(imageUrl: string) {
  return getAxiosInstance().post("/images/destroy", { imageUrl: imageUrl });
}

export function autoAnnotateImage(payload: AutoAnnotateImagePayload) {
  return getAxiosInstance().post("/images/correcting", payload);
}

export function autoAnnotateObjects(
  payload: AutoAnnotateObjectsPayload
): Promise<
  AxiosResponse<{
    modelMetadata: string;
    objects: {
      class: string;
      points: Point[];
      type: string;
    }[];
  }>
> {
  return getAxiosInstance().post("/images/listobjects", payload);
}

export const autoDetectObjectsOnImage = async (
  imageUrl: string,
  pairs: AutoAnnotatePair[],
  avaiableClasses: { [key: string]: ObjectDetectionClass },
  labels: { [key: string]: ObjectDetectionClass }
) => {
  const bboxClasses: { name: string; model: string }[] = [];
  const polygonClasses: { name: string; model: string }[] = [];

  const foundAnnotations: {
    type: AutoAnnotateType;
    labelId: string;
    annotations: { points: Point[] }[];
  }[] = [];

  for (let pair of pairs) {
    const label = labels[pair.labelId];
    if (label.supportedAnnotationTypes.includes(AnnotateType.BOUNDING_BOX)) {
      bboxClasses.push({
        name: avaiableClasses[pair.classId].name,
        model: avaiableClasses[pair.classId].model,
      });
    }
    if (label.supportedAnnotationTypes.includes(AnnotateType.POLYGON)) {
      polygonClasses.push({
        name: avaiableClasses[pair.classId].name,
        model: avaiableClasses[pair.classId].model,
      });
    }
  }

  try {
    if (bboxClasses.length > 0) {
      const response = await autoAnnotateObjects({
        imageUrl: imageUrl,
        type: AutoAnnotateType.BBOX,
        classes: bboxClasses,
      });
      const data = response.data;
      if (data && data.objects && data.objects.length > 0) {
        const foundClasses = bboxClasses.filter(
          (c) => !!data.objects.find((obj) => obj.class === c.name)
        );
        for (let foundClass of foundClasses) {
          const pair = pairs.find(
            (p) => avaiableClasses[p.classId].name === foundClass.name
          );
          if (pair) {
            const classAnnotations = [];
            for (let object of data.objects) {
              if (pair.classId.split("-")[0] === object.class) {
                classAnnotations.push({
                  points: object.points,
                });
              }
            }
            foundAnnotations.push({
              type: AutoAnnotateType.BBOX,
              labelId: pair.labelId,
              annotations: classAnnotations,
            });
          }
        }
      }
    }

    if (polygonClasses.length > 0) {
      const response = await autoAnnotateObjects({
        imageUrl: imageUrl,
        type: AutoAnnotateType.POLYGON,
        classes: polygonClasses,
      });
      const data = response.data;
      if (data && data.objects && data.objects.length > 0) {
        const foundClasses = polygonClasses.filter(
          (c) => !!data.objects.find((obj) => obj.class === c.name)
        );
        for (let foundClass of foundClasses) {
          const pair = pairs.find(
            (p) => avaiableClasses[p.classId].name === foundClass.name
          );
          if (pair) {
            const classAnnotations = [];
            for (let object of data.objects) {
              if (pair.classId.split("-")[0] === object.class) {
                classAnnotations.push({
                  points: object.points,
                });
              }
            }
            foundAnnotations.push({
              type: AutoAnnotateType.POLYGON,
              labelId: pair.labelId,
              annotations: classAnnotations,
            });
          }
        }
      }
    }
  } catch (error) {
    Sentry.captureException(error);
    Logger.log(error);
  }
  return foundAnnotations;
};

export const autoDetectTextOnFile = async (
  fileUrl: string,
  pairs: TextLabelPair[]
) => {
  let annotations: {
    observationId: number;
    startIndex: number;
    endIndex: number;
  }[] = [];
  try {
    const payload = { url: fileUrl };
    const response: AxiosResponse<{
      annotations: { id: string; startIndex: number; endIndex: number }[];
    }> = await getAxiosInstance().post("/ner/predict", payload);
    annotations = response.data?.annotations
      .map((anno) => {
        const aiLabelId = parseInt(anno.id);
        const pair = pairs.find((p) => p.classId === aiLabelId);
        return {
          observationId: pair?.labelId || -1,
          startIndex: anno.startIndex,
          endIndex: anno.endIndex,
        };
      })
      .filter((p) => p.observationId !== -1);
  } catch (error) {
    Sentry.captureException(error);
  }
  return annotations;
};

async function extractEmbeddingImage(imageBase64: string) {
  try {
    const headers = { "Content-Type": "application/octet-stream" };
    const { data } = await axios.get(imageBase64, { responseType: "blob" });
    const url = "/sam/image/extract_embedding";
    const response = await getAxiosInstance().post(url, data, { headers });
    const imageData = response.data[0];
    const binaryString = window.atob(imageData);
    const uint8arr = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      uint8arr[i] = binaryString.charCodeAt(i);
    }
    const float32Arr = new Float32Array(uint8arr.buffer);
    return new Tensor("float32", float32Arr, [1, 256, 64, 64]);
  } catch (error) {
    console.log("ERROR", error);
  }
}

async function getEmbeddingImage(imageUrl: string) {
  try {
    const response = await axios.get(imageUrl, { responseType: "blob" });
    const blob = response.data;
    const arrayBuffer = await blob.arrayBuffer();
    const uint8arr = new Uint8Array(arrayBuffer);
    const float32Arr = new Float32Array(uint8arr.buffer);
    return new Tensor("float32", float32Arr, [1, 256, 64, 64]);
  } catch (error) {
    console.log("ERROR", error);
  }
}

export const AIAssistanceService = {
  getHealth,
  initImage,
  removeImage,
  autoAnnotateImage,
  autoAnnotateObjects,
  getModelClassList,
  autoDetectObjectsOnImage,
  getTextClasses,
  autoDetectTextOnFile,
  extractEmbeddingImage,
  getEmbeddingImage,
};
