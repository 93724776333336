/*
 * File: use-annotation-display.hook.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 11th February 2022 11:23:45 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useAppSelector } from "hooks/use-redux";
import { useEffect } from "react";
import {
  selectAnnotationVisible,
  selectAnnotationFill,
  selectDisplayTextValue,
} from "store/labeler/image-workspace/cornerstone/cornerstone.selectors";
import { TasksReviewCornerstoneHandler } from "../components/tasks-review-cornerstone-handler";

export const useAnnotationsDisplay = (
  handler?: TasksReviewCornerstoneHandler
) => {
  const annotationLabelVisible = useAppSelector(selectAnnotationVisible);
  const annotationFill = useAppSelector(selectAnnotationFill);
  const displayTextValue = useAppSelector(selectDisplayTextValue);

  useEffect(() => {
    handler?.setFillPolygon(annotationFill);
  }, [annotationFill, handler]);

  useEffect(() => {
    handler?.setShowLabel(annotationLabelVisible);
  }, [annotationLabelVisible, handler]);

  useEffect(() => {
    handler?.setDisplayTextValue(displayTextValue);
  }, [displayTextValue, handler]);
};
