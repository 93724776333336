/*
 * File: user-notification.slice.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 16th September 2021 9:38:42 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice } from "@reduxjs/toolkit";
import {
  INITIAL_USER_NOTIFICATION_STATE,
  UserNotificationState,
} from "./user-notification.state";
import { userNotificationReducerBuilder } from "./user-notification.thunk";

const SLICE_NAME: string = "userNotification";
const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_USER_NOTIFICATION_STATE,
  reducers: {
    resetUserNotification(state: UserNotificationState) {
      Object.assign(state, INITIAL_USER_NOTIFICATION_STATE);
    },
  },
  extraReducers: (builder) => {
    userNotificationReducerBuilder(builder);
  },
});

export const { resetUserNotification } = slice.actions;

export const userNotificationReducer = slice.reducer;
