/*
 * File: button-delete-datasets.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 14th July 2023 11:06:53 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VscChevronDown, VscCopy, VscMerge, VscTrash } from "react-icons/vsc";
import { useMemo, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Option } from "domain/common";
import { Icon } from "iconsax-react";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";
interface Props {
  datasetIds: number[];
  reloadData(): void;
}
export function ButtonSelectedDatasets({ datasetIds, reloadData }: Props) {
  const { openModal, closeModal } = useModalContext();
  const moveOptions = useMemo<Option[]>(
    () => [
      {
        label: "Duplicate",
        value: "duplicate",
        metadata: {
          icon: VscCopy,
          disabled: datasetIds.length !== 1,
        },
      },
      {
        label: "Merge datasources",
        value: "merge",
        metadata: {
          icon: VscMerge,
          disabled: datasetIds.length < 2,
        },
      },
      {
        label: "Delete datasources",
        value: "delete",
        metadata: {
          icon: VscTrash,
          disabled: datasetIds.length < 1,
        },
      },
    ],
    [datasetIds]
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function selectOption(option: Option) {
    if (!option) return;
    if (option.value === "duplicate") {
      openModal(ModalTypes.DUPLICATE_DATASOURCE, {
        open: true,
        datasetId: datasetIds[0],
        onClose: (reload?: boolean) => {
          closeModal();
          if (reload) reloadData();
        },
      });
    } else if (option.value === "merge") {
      openModal(ModalTypes.MERGE_DATASOURCES, {
        open: true,
        datasetIds: datasetIds,
        onClose: (reload?: boolean) => {
          closeModal();
          if (reload) reloadData();
        },
      });
    } else if (option.value === "delete") {
      openModal(ModalTypes.DELETE_DATASOURCES, {
        open: true,
        datasetIds: datasetIds,
        onClose: (reload?: boolean) => {
          closeModal();
          if (reload) reloadData();
        },
      });
    }
    handleClose();
  }

  return (
    <div className="flex-none">
      <button className="button-secondary" onClick={handleClick}>
        <span className="hidden px-1 md:block">
          {datasetIds.length} selected
        </span>
        <VscChevronDown size={16} />
      </button>

      <Menu
        id="move-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "sort-menu" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        getContentAnchorEl={null}
      >
        {moveOptions.map((option) => {
          const ItemIcon = option.metadata.icon as Icon;
          return (
            <MenuItem
              disabled={option.metadata.disabled}
              key={option.value}
              onClick={() => selectOption(option)}
            >
              <div className="w-8 text-center">
                {option.metadata && <ItemIcon size={18} />}
              </div>
              <ListItemText className="pr-4">{option.label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

export default ButtonSelectedDatasets;
