import { Tooltip } from "@material-ui/core";
import { IconArrowDown } from "components/common/vb-icon.component";
import { VBNumberTextInputComponent } from "components/design-system/number-text-input/number-text-input.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { Fragment, useMemo } from "react";
import { classnames } from "utilities/classes";
import {
  EditorMetadataRowAttributeModel,
  EditorMetadataRowAttributeType,
  EditorMetadataRowModel,
} from "./three-d-editor.models";
import { useThreeDEditorContext } from "./three-d-editor.provider";

export const ThreeDEditorMetadataComponent = () => {
  const {
    workingMetadata,
    setWorkingMetadata,
    viewMetadata,
    setViewMetadata,
    isShowViewLabels, // viewing previous result
  } = useThreeDEditorContext();

  const metadataToDisplay = useMemo(() => {
    if (isShowViewLabels) {
      return viewMetadata;
    }
    return workingMetadata;
  }, [isShowViewLabels, workingMetadata, viewMetadata]);

  const setCurrentMetadata = (newMetadata: EditorMetadataRowModel[]) => {
    if (isShowViewLabels) {
      setViewMetadata(newMetadata);
    } else {
      setWorkingMetadata(newMetadata);
    }
  };

  const handleAttrVisibility = (
    row: EditorMetadataRowModel,
    attrVisibility: boolean
  ) => {
    const newMetadata = metadataToDisplay.map((m) => {
      if (m.id === row.id) {
        return {
          ...m,
          attributesVisibility: attrVisibility,
        };
      }
      return m;
    });
    setCurrentMetadata(newMetadata);
  };

  const handleValuesChanged = (
    row: EditorMetadataRowModel,
    changedAttr: EditorMetadataRowAttributeModel,
    newOptionOrValue: any
  ) => {
    const newMetadata = metadataToDisplay.map((m) => {
      if (m.id !== row.id) return m;
      return {
        ...m,
        attributes: m.attributes.map((attr) => {
          if (attr.id !== changedAttr.id) return attr;
          let values: any[] = [];
          if (newOptionOrValue) {
            if (attr.type === EditorMetadataRowAttributeType.RADIO) {
              values = [newOptionOrValue.value];
            } else if (attr.type === EditorMetadataRowAttributeType.SELECT) {
              values = newOptionOrValue.map((op: any) => op.value);
            } else {
              values = [newOptionOrValue];
            }
          }
          return {
            ...attr,
            values,
          };
        }),
      };
    });
    setCurrentMetadata(newMetadata);
  };

  if (!metadataToDisplay || metadataToDisplay.length <= 0) return null;

  return (
    <div className="flex flex-col gap-2">
      <p className="font-bold">Metadata</p>
      {metadataToDisplay.map((metadataRow) => {
        return (
          <div key={metadataRow.id} className="flex flex-col gap-1">
            <div className="flex items-center gap-1">
              <span>{metadataRow.name}</span>
              <IconArrowDown
                className={classnames(
                  "w-5 h-5 transform cursor-pointer",
                  { "text-warning-300": metadataRow.attributesVisibility },
                  { "text-white": !metadataRow.attributesVisibility }
                )}
                onClick={(_) =>
                  handleAttrVisibility(
                    metadataRow,
                    !metadataRow.attributesVisibility
                  )
                }
              />
            </div>

            {metadataRow.attributesVisibility && (
              <Fragment>
                {metadataRow.attributes.map((attr) => (
                  <div key={attr.id} className="text-sm">
                    <Tooltip title={attr.name} placement="top">
                      <span className="w-1/4 truncate">{attr.name}</span>
                    </Tooltip>
                    {attr.type === EditorMetadataRowAttributeType.TEXT && (
                      <div className="text-black">
                        <VBNumberTextInputComponent
                          type="text"
                          height="28px"
                          borderColor="border-warning-300"
                          defaultValue={
                            attr.values.length > 0 ? attr.values[0] : ""
                          }
                          listenChangedDefault
                          onValueChanged={(v) =>
                            handleValuesChanged(metadataRow, attr, v.toString())
                          }
                        />
                      </div>
                    )}
                    {attr.type === EditorMetadataRowAttributeType.NUMBER && (
                      <div className="text-black">
                        <VBNumberTextInputComponent
                          type="number"
                          height="28px"
                          borderColor="border-warning-300"
                          defaultValue={
                            attr.values.length > 0 ? attr.values[0] : ""
                          }
                          listenChangedDefault
                          onValueChanged={(v) =>
                            handleValuesChanged(metadataRow, attr, v.toString())
                          }
                        />
                      </div>
                    )}
                    {attr.type === EditorMetadataRowAttributeType.RADIO && (
                      <div className="text-black">
                        <VBSelectComponent
                          size="none"
                          isClearable
                          value={
                            attr.values.length > 0
                              ? {
                                  label: attr.values[0].toString(),
                                  value: attr.values[0],
                                }
                              : ""
                          }
                          options={attr.options.map((v) => ({
                            label: v.toString(),
                            value: v,
                          }))}
                          onChange={(newValue) =>
                            handleValuesChanged(metadataRow, attr, newValue)
                          }
                        />
                      </div>
                    )}
                    {attr.type === EditorMetadataRowAttributeType.SELECT && (
                      <div className="text-black">
                        <VBSelectComponent
                          size="none"
                          isMulti
                          isClearable
                          value={attr.values.map((v) => ({
                            label: v.toString(),
                            value: v,
                          }))}
                          options={attr.options.map((v) => ({
                            label: v.toString(),
                            value: v,
                          }))}
                          onChange={(newValue) =>
                            handleValuesChanged(metadataRow, attr, newValue)
                          }
                        />
                      </div>
                    )}
                  </div>
                ))}
              </Fragment>
            )}
          </div>
        );
      })}
    </div>
  );
};
