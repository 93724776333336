import { IssueStatus } from "pages/customer/projects/project-batch/batch-detail/pages/tasks/components/issue/issue-management.models";
import { RootState } from "store";
import { UserRole } from "store/auth/auth.state";

export const selectIssueManagementIsCreatingIssueMode = (
  state: RootState
): boolean => {
  return state.issueManagement.isCreatingIssueMode;
};

export const selectIssueManagementIssues =
  (taskId: number, jobIds?: number[], jobId?: number) => (state: RootState) => {
    return state.issueManagement.issues.filter((issue) => {
      if (issue.taskId !== taskId) return false;
      if (jobIds && issue.jobId && !jobIds.includes(issue.jobId)) {
        return false;
      }
      if (jobId && issue.jobId !== jobId) return false;
      return true;
    });
  };

export const selectIssueManagementSelectedIssueId = (state: RootState) => {
  return state.issueManagement.selectedIssueId;
};

export const selectIssueManagementNewIssues =
  (taskId: number, jobIds?: number[], jobId?: number) => (state: RootState) => {
    const issues = selectIssueManagementIssues(taskId, jobIds, jobId)(state);
    return issues.filter((issue) => issue.status === IssueStatus.OPENED);
  };

export const selectIssueManagementNewTotal =
  (taskId: number, jobIds?: number[], jobId?: number) => (state: RootState) => {
    return selectIssueManagementNewIssues(taskId, jobIds, jobId)(state).length;
  };

export const selectIssueManagementTotalIssue =
  (taskId: number, jobIds?: number[], jobId?: number) => (state: RootState) => {
    return selectIssueManagementIssues(taskId, jobIds, jobId)(state).length;
  };

export const selectIssueManagementIssueFilter = (state: RootState) => {
  return state.issueManagement.issueFilter;
};

export const selectIssueManagementIssueByFilter =
  (filter: string, taskId: number, jobIds?: number[], jobId?: number) =>
  (state: RootState) => {
    const issues = selectIssueManagementIssues(taskId, jobIds, jobId)(state);
    if (filter === "all") return issues;
    if (filter === "none") return [];
    return issues.filter(
      (issue) => issue.status.toLowerCase() === filter.toLowerCase()
    );
  };

export const selectIssueManagementIsReviewer = (state: RootState) => {
  return state.issueManagement.isReviewer;
};

export const selectIssueManagementDoesHaveEditPermission = (
  state: RootState
) => {
  const role = state.auth.userRole;
  return (
    role === UserRole.CUSTOMER ||
    (role === UserRole.LABELER && state.issueManagement.isReviewer)
  );
};

export const selectIssueManagementDoesHaveWorkingIssues =
  (taskId: number, jobIds?: number[], jobId?: number) => (state: RootState) => {
    const issues = selectIssueManagementIssues(taskId, jobIds, jobId)(state);
    return (
      issues.filter((issue) => issue.status === IssueStatus.OPENED).length > 0
    );
  };

export const selectIssueManagementShouldShowIssuesButton =
  (taskId: number, jobIds?: number[], jobId?: number) => (state: RootState) => {
    const role = state.auth.userRole;
    const issues = selectIssueManagementIssues(taskId, jobIds, jobId)(state);
    if (
      role === UserRole.LABELER &&
      !state.issueManagement.isReviewer &&
      issues.length <= 0
    ) {
      return false;
    }
    return true;
  };
