/*
 * File: connection.component.tsx
 * Project: app-aiscaler-web
 * File Created: Sunday, 26th December 2021 11:33:24 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { MouseEvent, useEffect, useState } from "react";
import { BBOXStore } from "../../utils/bbox-store";
import { RelationLine } from "./text-viewer-handle";

const PADDING = 4;

interface Props {
  id: string;
  from: string;
  to: string;
  text: string;
  color: string;
  offset?: number;
  conflict?: boolean;
  data: RelationLine;
  onSelect?(id: string): void;
  onMouseEnter?(id: string): void;
  onMouseLeave?(id: string): void;
  showRelationName?: boolean;
}
export const RelationAnnotationComponent = ({
  id,
  from,
  to,
  color: defaultColor = "#f59e0b",
  text,
  showRelationName = true,
  conflict,
  data,
  onSelect,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const startElement = document.getElementById(from);
  const endElement = document.getElementById(to);
  const parent = startElement?.parentElement;
  const [color, setColor] = useState(
    showRelationName ? "#8b91A3" : defaultColor
  );
  const [cursor, setCursor] = useState("default");
  const [opacity, setOpacity] = useState(showRelationName ? 1 : 0);

  useEffect(() => {
    setOpacity(showRelationName ? 1 : 0);
    setColor(showRelationName ? "#8b91A3" : defaultColor);
  }, [showRelationName, defaultColor]);

  const handleMouseEnter = (event: MouseEvent) => {
    setColor(defaultColor);
    setCursor("pointer");
    setOpacity(1);
    onMouseEnter && onMouseEnter(id);
    const target = document.getElementById(id);
    if (target) {
      target.setAttribute("mouse-x", event.clientX.toString());
      target.setAttribute("mouse-y", event.clientY.toString());
    }
  };

  const handleMouseLeave = () => {
    setColor(!showRelationName ? defaultColor : "#8b91A3");
    setCursor("default");
    setOpacity(showRelationName ? 1 : 0);
    onMouseLeave && onMouseLeave(id);
  };

  const handleClick = (_: MouseEvent) => {
    onSelect && onSelect(id);
  };

  if (!data || !startElement || !endElement || !parent) return null;
  const line = generateLinePath(data);
  const position = generateTextPosition(data);
  const textBBox = BBOXStore.getBoudingClientRect(text, 12);
  const textWidth = textBBox?.width || 0;
  const textHeight = textBBox?.height || 0;

  return (
    <>
      <defs>
        <marker
          id={`marker-${id}`}
          viewBox="0 0 10, 10"
          refX="5"
          refY="5"
          markerWidth="5"
          markerHeight="5"
          orient="auto-start-reverse"
          markerUnits="userSpaceOnUse"
          strokeWidth="1"
        >
          <path d="M 0 0 L 10 5 L 0 10 Z" stroke={color} fill={color}></path>
        </marker>
      </defs>
      <g
        data-entity="label.arrow"
        data-id="markerId"
        data-direction="-1"
        data-origin-id={from}
        data-target-id={to}
      >
        <path
          d={line}
          fill="none"
          stroke={color}
          strokeWidth="1"
          strokeOpacity={0.4}
          markerEnd={`url(#marker-${id})`}
        />
        <g
          id={id}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
          cursor={cursor}
        >
          <path
            d={line}
            fill="none"
            stroke="transparent"
            strokeWidth="5"
            className="select-none"
          />
          <rect
            x={position.x - textWidth / 2}
            y={position.y - textHeight / 2}
            width={textWidth}
            height={textHeight}
            fill={conflict ? "#f8e200" : "#FFFFFF"}
            opacity={opacity}
          />
          <text
            id={`${id}-text`}
            x={position.x - textWidth / 2}
            y={position.y + 3}
            fill={defaultColor}
            fontSize={12}
            textLength={textWidth}
            opacity={opacity}
          >
            {text}
          </text>
        </g>
      </g>
    </>
  );
};

const generateLinePath = (data: RelationLine) => {
  const paths: string[] = [];
  for (const line of data.lines) {
    const start = line.points[0];
    let lineStr = `M ${start.x} ${start.y}`;
    for (let i = 1; i < line.points.length; i++) {
      const point = line.points[i];
      if (i !== line.points.length - 1) {
        const previous = line.points[i - 1];
        const next = line.points[i + 1];
        let dxPrevious = previous.x - point.x;
        dxPrevious =
          dxPrevious === 0 ? 0 : (PADDING * dxPrevious) / Math.abs(dxPrevious);
        let dyPrevious = previous.y - point.y;
        dyPrevious =
          dyPrevious === 0 ? 0 : (PADDING * dyPrevious) / Math.abs(dyPrevious);

        let dxNext = next.x - point.x;
        dxNext = dxNext === 0 ? 0 : (PADDING * dxNext) / Math.abs(dxNext);
        let dyNext = next.y - point.y;
        dyNext = dyNext === 0 ? 0 : (PADDING * dyNext) / Math.abs(dyNext);
        lineStr += ` L${point.x + dxPrevious} ${point.y + dyPrevious} C ${
          point.x + dxPrevious
        } ${point.y + dyPrevious}, ${point.x} ${point.y}, ${point.x + dxNext} ${
          point.y + dyNext
        } L${point.x + dxNext} ${point.y + dyNext}`;
      } else {
        lineStr += ` L${point.x} ${point.y}`;
      }
    }
    paths.push(lineStr);
  }
  return paths.join(" ");
};
const generateTextPosition = (data: RelationLine) => {
  let left, right;
  let maxX = 0;
  for (const line of data.lines) {
    for (let i = 1; i < line.points.length; i++) {
      const leftPoint = line.points[i - 1];
      const rightPoint = line.points[i];
      const dx = Math.abs(rightPoint.x - leftPoint.x);
      if (dx > maxX) {
        left = leftPoint;
        right = rightPoint;
        maxX = dx;
      }
    }
  }
  if (!left || !right) {
    return { x: -1000, y: -1000 };
  }
  return { x: (left.x + right.x) / 2, y: Math.min(left.y, right.y) };
};
