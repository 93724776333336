/*
 * File: labeler-project.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 26th April 2022 11:19:21 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { LabelerProject } from "domain/image-labeling";
import { ProjectDTO } from "../dtos";

export const labelerProjectMapper = {
  toEntity: (data: ProjectDTO): LabelerProject => {
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      available: true,
      type: data.type,
    };
  },
};
