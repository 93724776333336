/*
 * File: workflow-instruction.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTPStatusCode } from "services/common/http-status";
import { WorkflowInstructionService } from "services/label-service";
import { WorkflowInstructionDTO } from "services/label-service/dtos";
import { ProjectState } from "../project.state";

export enum InstructionThunk {
  LOAD_INSTRUCTIONS = "instruction/loadWorkflowInstructionsAsync",
  CREATE_INSTRUCTION = "instruction/createWorkflowInstructionAsync",
  UPDATE_INSTRUCTION = "instruction/updateWorkflowInstructionAsync",
  DELETE_INSTRUCTION = "instruction/deleteWorkflowInstructionAsync",
}

export const loadWorkflowInstructionsAsync = createAsyncThunk(
  InstructionThunk.LOAD_INSTRUCTIONS,
  async (workflowId: number) => {
    const payload = { workflowId: workflowId.toString() };
    const response = await WorkflowInstructionService.getItems(payload);
    return response.data;
  }
);

export const createWorkflowInstructionAsync = createAsyncThunk(
  InstructionThunk.CREATE_INSTRUCTION,
  async (data: Partial<WorkflowInstructionDTO>) => {
    const response = await WorkflowInstructionService.createItem(data);
    return response.data;
  }
);

export const updateWorkflowInstructionAsync = createAsyncThunk(
  InstructionThunk.UPDATE_INSTRUCTION,
  async (workflowInstruction: WorkflowInstructionDTO) => {
    const response = await WorkflowInstructionService.updateItem(
      workflowInstruction
    );
    return response.data;
  }
);

export const deleteWorkflowInstructionAsync = createAsyncThunk(
  InstructionThunk.DELETE_INSTRUCTION,
  async (workflowInstructionId: number) => {
    const response = await WorkflowInstructionService.deleteItem(
      workflowInstructionId
    );
    if (
      response.status === HTTPStatusCode.OK ||
      response.status === HTTPStatusCode.NoContent
    ) {
      return workflowInstructionId;
    }
    return -1;
  }
);

export const workflowInstructionReducerBuilder = (
  builder: ActionReducerMapBuilder<ProjectState>
): ActionReducerMapBuilder<ProjectState> => {
  return builder
    .addCase(loadWorkflowInstructionsAsync.pending, (state) => {
      state.requesting = true;
    })
    .addCase(loadWorkflowInstructionsAsync.fulfilled, (state, action) => {
      state.requesting = false;
      state.workflowInstructions = action.payload;
    })
    .addCase(createWorkflowInstructionAsync.pending, (state) => {
      state.requesting = true;
    })
    .addCase(createWorkflowInstructionAsync.fulfilled, (state, action) => {
      state.requesting = false;
      state.workflowInstructions.unshift(action.payload);
    })
    .addCase(updateWorkflowInstructionAsync.pending, (state) => {
      state.requesting = true;
    })
    .addCase(updateWorkflowInstructionAsync.fulfilled, (state, action) => {
      state.requesting = false;
      state.workflowInstructions = state.workflowInstructions.map((p) => {
        if (p.id === action.payload.id) {
          return action.payload;
        }
        return p;
      });
    })
    .addCase(deleteWorkflowInstructionAsync.pending, (state) => {
      state.requesting = true;
    })
    .addCase(deleteWorkflowInstructionAsync.fulfilled, (state, action) => {
      state.requesting = false;
      state.workflowInstructions = state.workflowInstructions.filter(
        (workflowInstruction) => {
          return workflowInstruction.id !== action.payload;
        }
      );
    });
};
