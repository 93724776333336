import { RouteData } from "configs/router.config";
import { useCallback, useMemo } from "react";
import { Routes } from "routers/config/routes";
import { selectCustomerMenuRoutes } from "store/auth/auth.selectors";
import { useAppSelector } from "./use-redux";
import { routeMatch } from "./use-route-match.hook";
import { useHistory } from "./use-history";
import { compact } from "utilities/array/compact";
import { selectCurrentProject } from "store/customer/project/project.selectors";
import { selectBatchInfo } from "store/customer/batch/batch.selectors";

export const useSidebar = () => {
  const { pathname } = useHistory();
  const routes: RouteData[] = useAppSelector(selectCustomerMenuRoutes);
  const currentProject = useAppSelector(selectCurrentProject);
  const currentBatch = useAppSelector(selectBatchInfo);

  const isProjectDetailPath =
    currentProject &&
    pathname.includes(`${Routes.PROJECTS}/${currentProject.id}`);
  const isBatchDetailPath =
    currentBatch && pathname.includes(`${Routes.BATCHES}/${currentBatch.id}`);
  const isMlModelsPath = pathname.includes(`${Routes.MLMODELS}`);

  const getChildItemsWithPathname = useCallback(
    (item: RouteData) => {
      if (!item || !item.children) return [];
      return normalizeChildrenItems(item.children, { pathname });
    },
    [pathname]
  );

  const sidebarItems = useMemo(() => {
    if (isProjectDetailPath) {
      const projectItem = routes.find((item) =>
        item.path.includes(Routes.PROJECTS)
      );

      if (isBatchDetailPath) {
        const batchItem = projectItem?.children?.find((item) =>
          item.path.includes(Routes.BATCHES)
        );
        return batchItem ? getChildItemsWithPathname(batchItem) : [];
      }

      return projectItem ? getChildItemsWithPathname(projectItem) : [];
    }

    if (isMlModelsPath) {
      const mlModelsItem = routes.find((item) =>
        item.path.includes(Routes.MLMODELS)
      );
      return mlModelsItem ? getChildItemsWithPathname(mlModelsItem) : [];
    }

    return [];
  }, [
    getChildItemsWithPathname,
    isBatchDetailPath,
    isProjectDetailPath,
    isMlModelsPath,
    routes,
  ]);

  return {
    isProjectDetailPath,
    isBatchDetailPath,
    sidebarItems,
  };
};

function normalizeChildrenItems(
  items?: RouteData[],
  options?: { pathname?: string }
): RouteData[] {
  if (!items || !items.length) return [];

  const pathname = options?.pathname || "";
  const childrenItems = items.map((item) => {
    if (item.disabled) return null;

    const { params } = routeMatch(pathname, item.path);

    let newRoute = item.path;
    for (let key of Object.keys(params)) {
      newRoute = newRoute.replace(`:${key}`, params[key]);
    }

    const children = item.disabledChildren
      ? []
      : normalizeChildrenItems(item.children, { pathname });
    return {
      ...item,
      path: newRoute,
      children,
    };
  });

  return compact(childrenItems);
}
