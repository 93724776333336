/*
 * File: sign-in.page.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 2nd August 2021 12:20:58 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export const SignInPage = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen bg-white md:bg-gray-100"></div>
  );
};
