/*
 * File: vb-input.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 23rd November 2021 11:02:45 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { IconClose } from "components/common/vb-icon.component";
import { HTMLProps, useRef, useState } from "react";
import { classnames } from "utilities/classes";

interface Props extends HTMLProps<HTMLInputElement> {
  label?: string;
  hint?: string;
  required?: boolean;
  labelClass?: string;
  hintClass?: string;
  containerClass?: string;
  onValueChanged?: (value: string) => void;
  error?: string;
}
export const VBInput = ({
  label,
  hint,
  required,
  labelClass,
  containerClass,
  onValueChanged,
  error,
  disabled,
  hintClass,
  className="focus:ring-warning-500 focus:border-warning-500",
  ...rest
}: Props) => {
  const [value, setValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const handleInput = () => {
    if (inputRef.current) {
      setValue(inputRef.current.value);
      onValueChanged && onValueChanged(inputRef.current.value);
    }
  };
  const handleClearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
      setValue("");
      onValueChanged && onValueChanged("");
    }
  };;
  
  return (
    <div className={`flex flex-col gap-2 ${containerClass}`}>
      {label && (
        <div className={labelClass}>
          {label}
          {required && <sup>*</sup>}
          {
            error &&
            <span className="ml-2 text-red-500">{error}</span>
          }
        </div>
      )}
      <div className="relative">
        <input
          disabled={disabled}
          ref={inputRef}
          required={required}
          onInput={handleInput}
          className={classnames(
            `w-full h-12 pl-4 pr-10 bg-white border rounded border-background-300 focus:outline-none ${className}`,
            {"border-red-500": !!error}
          )}
          {...rest}
        />
        {(value.trim() && !disabled) && (
          <button
            onClick={handleClearInput}
            className="absolute transform -translate-y-1/2 right-4 top-1/2 z-1"
          >
            <IconClose className="w-5 h-5" />
          </button>
        )}
      </div>

      {hint && <div className={`text-xs text-red-500 ${hintClass}`}>{hint}</div>}
    </div>
  );
};
