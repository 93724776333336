import { ImageWorkspaceState } from "../image-workspace.state";
import { INITIAL_IMAGE_REVIEW_STATE } from "./image-review.state";

export const imageReviewActions = {
  resetImageReviewState({ imageReview }: ImageWorkspaceState) {
    Object.assign(imageReview, INITIAL_IMAGE_REVIEW_STATE);
  },

  reopenDialogClosed({ imageReview }: ImageWorkspaceState) {
    imageReview.reopenReason = null;
  },
};
