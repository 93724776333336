/*
 * File: smart-labeling.slice.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th December 2021 4:27:35 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { textAIReducerBuilder } from "./text-ai.thunk";
import {
  TextLabelPair,
  INITIAL_TEXT_AI_STATE,
  TextAIState,
} from "./text-ai.state";

const SLICE_NAME: string = "text-ai";

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_TEXT_AI_STATE,
  reducers: {
    resetTextAIState(state: TextAIState) {
      Object.assign(state, INITIAL_TEXT_AI_STATE);
    },
    textAIEnabled(state: TextAIState, action: PayloadAction<boolean>) {
      state.enable = action.payload;
    },
    textLabelPairsEdited(
      state: TextAIState,
      action: PayloadAction<TextLabelPair[]>
    ) {
      state.labelPairs = action.payload;
    },
  },
  extraReducers: (builder) => {
    textAIReducerBuilder(builder);
  },
});

export const { resetTextAIState, textAIEnabled, textLabelPairsEdited } =
  slice.actions;

export const textAIReducer = slice.reducer;
