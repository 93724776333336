/*
 * File: pagination.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 22nd May 2023 1:57:17 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { AxiosResponse } from "axios";

export interface IPagination {
  page: number;
  size: number;
  totalCount: number;
}

function fromResponse(response: AxiosResponse): IPagination {
  return {
    page: response.headers["x-page"],
    size: response.headers["x-page-size"],
    totalCount: response.headers["x-total-count"],
  };
}

export const paginationMapper = {
  fromResponse,
};
