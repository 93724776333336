import classNames from "classnames";
import {
  IconCloseCircle,
  IconRadio,
  IconRadioChecked,
} from "components/common/vb-icon.component";
import { UserAvatar } from "components/common/vb-user-avatar.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { selectTextConflictSelectedAnnotation } from "store/labeler/text-workspace/text-conflict/text-conflict.selectors";
import { updateConflictAnnotationAsync } from "store/labeler/text-workspace/text-conflict/text-conflict.thunk";
import { textConflictAnnotationSelected } from "store/labeler/text-workspace/text-workspace.slice";
import { useTextWorkspaceContext } from "../../context/text-workspace/text-workspace.context";

export const TextConflictDetail = () => {
  const dispatch = useAppDispatch();
  const { labels } = useTextWorkspaceContext();
  const { t } = useTranslation();
  const selectedAnnotation = useAppSelector(
    selectTextConflictSelectedAnnotation
  );
  const label = useMemo(() => {
    if (!selectedAnnotation?.observationId) return null;
    return labels.find(
      (label) => label.id === selectedAnnotation.observationId
    );
  }, [labels, selectedAnnotation]);

  function handleClickReject() {
    if (!selectedAnnotation) return;
    dispatch(
      updateConflictAnnotationAsync({
        annotationId: selectedAnnotation.id,
        reviewStatus:
          selectedAnnotation.reviewStatus !== "rejected"
            ? "rejected"
            : undefined,
      })
    );
  }

  function handleClickResolve() {
    if (!selectedAnnotation) return;
    dispatch(
      updateConflictAnnotationAsync({
        annotationId: selectedAnnotation.id,
        reviewStatus:
          selectedAnnotation.reviewStatus !== "accepted"
            ? "accepted"
            : undefined,
      })
    );
  }

  function handleClickClose() {
    dispatch(textConflictAnnotationSelected(""));
  }
  
  if (!selectedAnnotation) return null;

  return (
    <div className="relative bg-white rounded">
      <div className="flex gap-6 p-4">
        <div>
          <div className="flex items-center gap-2 px-4 py-1">
            <span className="font-bold">
              {t("textLabeling:tooltip.status")}
            </span>
            <span
              className={classNames(
                "px-3 py-1 text-xs text-white rounded-full",
                {
                  "bg-red-500": !!selectedAnnotation?.isConflict,
                  "bg-green-500": !selectedAnnotation?.isConflict,
                }
              )}
            >
              {!selectedAnnotation?.isConflict
                ? t("textLabeling:tooltip.agree")
                : t("textLabeling:tooltip.conflict")}
            </span>
          </div>

          <div className="px-4 py-1 space-y-2">
            <strong> {t("textLabeling:tooltip.labeledBy")}</strong>
            <div className="space-y-2">
              {selectedAnnotation?.annotators?.map((annotator) => {
                return (
                  <div className="flex items-center gap-2" key={annotator}>
                    <UserAvatar
                      className="flex-none w-6 h-6 text-xs"
                      name={annotator}
                    />
                    <span>{annotator}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div>
          {label && (
            <div className="px-4 py-1">
              <strong> {t("textLabeling:tooltip.label")}</strong>
              <span>{label.name}</span>
            </div>
          )}

          {label && (
            <div className="px-4 py-1">
              <strong> {t("textLabeling:tooltip.content")}</strong>
              <span>{selectedAnnotation?.content}</span>
            </div>
          )}
        </div>

        <div className="px-4 space-y-2">
          <strong>Actions: </strong>
          <div>
            <button
              className={classNames("flex items-center gap-2 py-1", {
                "text-primary": selectedAnnotation?.reviewStatus === "accepted",
              })}
              onClick={handleClickResolve}
            >
              {selectedAnnotation?.reviewStatus !== "accepted" && <IconRadio />}
              {selectedAnnotation?.reviewStatus === "accepted" && (
                <IconRadioChecked />
              )}
              <span>Accept</span>
            </button>
            <button
              className={classNames("flex items-center gap-2 py-1", {
                "text-primary": selectedAnnotation?.reviewStatus === "rejected",
              })}
              onClick={handleClickReject}
            >
              {selectedAnnotation?.reviewStatus !== "rejected" && <IconRadio />}
              {selectedAnnotation?.reviewStatus === "rejected" && (
                <IconRadioChecked />
              )}
              <span>Reject</span>
            </button>
          </div>
        </div>
      </div>

      <button
        onClick={handleClickClose}
        className="absolute z-10 flex-none right-6 top-4"
      >
        <IconCloseCircle className="w-6 h-6" />
      </button>
    </div>
  );
};
