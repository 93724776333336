import { Collapse } from "@material-ui/core";
import { IconEye, IconEyeSlash } from "components/common/vb-icon.component";
import { Annotation } from "domain/image-labeling";
import { useAppDispatch } from "hooks/use-redux";
import { useMemo, useState } from "react";
import { updateActiveAnnotation } from "store/customer/tasks-review/tasks-review.slice";
import { TaskReviewActionPanelAnnotation } from "./annotation.component";
import { TaskReviewActionPanelLabelIcon } from "./label-icon.component";

interface TaskReviewActionPanelGroupLabelProps {
  labelName: string;
  annotations: Annotation[];
  className?: string;
  labelId: number;
}

export const TaskReviewActionPanelGroupLabel = ({
  annotations,
  className,
  labelName,
  labelId,
}: TaskReviewActionPanelGroupLabelProps) => {
  const dispatch = useAppDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isVisibleAnnotations, setVisibleAnnotations] = useState(true);

  const labelType = useMemo(() => {
    if (!annotations || !annotations.length) return "";
    const annotationData = annotations[0].annotationData;
    if (!annotationData || !annotationData.length) return "";
    return annotationData[0].type;
  }, [annotations]);

  const handleDisplayAnnotations = () => {
    annotations.forEach((annotation) => {
      if (annotation.labelId !== labelId) return;
      dispatch(
        updateActiveAnnotation({
          annotationId: annotation.id,
          selected: !isVisibleAnnotations,
        })
      );
    });
    setVisibleAnnotations(!isVisibleAnnotations);
  };

  const handleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <div className={className}>
      <div className="flex items-center justify-between px-4 py-2">
        <div className="flex items-center justify-start gap-4">
          <button onClick={handleCollapse}>
            {!isCollapsed ? (
              <i className="uir-angle-small-down" />
            ) : (
              <i className="uir-angle-small-right" />
            )}
          </button>
          <div className="flex items-center justify-start gap-2">
            <TaskReviewActionPanelLabelIcon type={labelType} />
            <div className="text-base font-medium">{labelName}</div>
          </div>
        </div>

        <div className="">
          {isVisibleAnnotations ? (
            <IconEye
              width={20}
              height={20}
              stroke="#000"
              onClick={handleDisplayAnnotations}
            />
          ) : (
            <IconEyeSlash
              width={20}
              height={20}
              stroke="#000"
              onClick={handleDisplayAnnotations}
            />
          )}
        </div>
      </div>
      <Collapse in={!isCollapsed} timeout="auto" className="mt-2" unmountOnExit>
        <div className="space-y-2">
          {annotations.map((annotation) => {
            if (annotation.type === "text") return null;
            return (
              <div
                key={annotation.id}
                className="py-2 pl-12 pr-4 rounded cursor-pointer hover:bg-primary-50"
              >
                <TaskReviewActionPanelAnnotation annotation={annotation} />
              </div>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};
