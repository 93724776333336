/*
 * File: batch-labeling.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 10th December 2021 11:39:24 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useState, useRef } from "react";
import { useUnmount } from "ahooks";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { WorkspaceEmpty } from "pages/labeler/image-labeling/components/workspace-empty/workspace-empty.component";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { LoadingStatus, RequestStatus } from "store/base/base.state";
import {
  selectBatchLabelingRequestStatus,
  selectIsNoAvailableBatch,
  selectJobIds,
  selectCurrentJobIndex,
} from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import {
  jobSelected,
  resetBatchLabelingState,
} from "store/labeler/image-workspace/batch-labeling/batch-labeling.slice";
import {
  selectIsShowEditorSettings,
  selectIsShowKeyboardShortcuts,
} from "store/labeler/image-workspace/editor-setting/editor-setting.selectors";
import {
  editorSettingsClosed,
  keyboardShortcutsClosed,
} from "store/labeler/image-workspace/editor-setting/editor-setting.slice";
import { EditorSettingsModal } from "../components/editor-settings/editor-settings.component";
import { ErrorDialog } from "../components/error-dialog.component";
import { KeyboardShortcutsModal } from "../components/keyboard-shortcuts/keyboard-shortcuts.component";
import { BatchLabelingContext } from "./batch-labeling.context";
import { CornerstoneViewportEditor } from "components/dicom/cornerstone/viewport-editor/viewport-editor";
import { resetImageWorkspaceState } from "store/labeler/image-workspace/image-workspace.slice";
import usePollJobs from "../hooks/use-poll-jobs.hook";
import useLoadJob from "../hooks/use-load-job.hook";
import { LabelingMode } from "domain/labeling/labeling-mode";

interface Props {
  children: JSX.Element;
  mode?: LabelingMode;
  onClose?(): void;
}

export const BatchLabelingProvider = ({
  mode = LabelingMode.LABELER,
  children,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const [loadingStatus] = useState(LoadingStatus.IDLE);
  const jobIds = useAppSelector(selectJobIds);
  const currentJobIndex = useAppSelector(selectCurrentJobIndex);
  const requestStatus = useAppSelector(selectBatchLabelingRequestStatus);
  const isNoAvailableJob = useAppSelector(selectIsNoAvailableBatch);
  const [hideNavigation, setHideNavigation] = useState(false);
  const showKeyboardShortcuts = useAppSelector(selectIsShowKeyboardShortcuts);
  const showEditorSettings = useAppSelector(selectIsShowEditorSettings);
  const history = useHistory();
  const currentViewportEditor = useRef<CornerstoneViewportEditor>();
  usePollJobs({ mode });
  useLoadJob({ mode });

  function closeKeyboardShortcuts() {
    dispatch(keyboardShortcutsClosed());
  }

  function closeEditorSettings() {
    dispatch(editorSettingsClosed());
  }

  function handleExit() {
    if (onClose) {
      onClose();
      return;
    }
    history.replace(Routes.LABELER_HOME);
  }

  function selectJob(jobIndex: number) {
    if (jobIds.length < 2) return;
    let index = jobIndex % jobIds.length;
    if (index < 0) index += jobIds.length;
    dispatch(jobSelected(jobIds[index]));
  }

  useUnmount(() => {
    dispatch(resetBatchLabelingState());
    dispatch(resetImageWorkspaceState());
  });

  const state = {
    mode,
    requestStatus,
    loadingStatus,
    hideNavigation,
    toggleNavigation: () => setHideNavigation(!hideNavigation),
    selectJob,
    selectNextJob: () => selectJob(currentJobIndex + 1),
    selectPreviousJob: () => selectJob(currentJobIndex - 1),
    currentViewportEditor,
    handleExit,
  };

  return (
    <BatchLabelingContext.Provider value={state}>
      {!isNoAvailableJob && children}
      {isNoAvailableJob && <WorkspaceEmpty />}

      {requestStatus === RequestStatus.FAILURE && (
        <ErrorDialog visible onClose={handleExit} />
      )}
      {showKeyboardShortcuts && (
        <KeyboardShortcutsModal open onClose={closeKeyboardShortcuts} />
      )}
      {showEditorSettings && (
        <EditorSettingsModal open onClose={closeEditorSettings} />
      )}
    </BatchLabelingContext.Provider>
  );
};
