/*
 * File: projects.selector.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 29th July 2021 3:34:21 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "store";

export const selectProjectsLoading = (state: RootState) => {
  return state.projects.loading;
};

export const selectProjectsRequesting = (state: RootState) => {
  return state.projects.requesting;
};

export const selectIsProjectsLoaded = (state: RootState) => {
  return state.projects.isLoaded;
};

export const selectProjects = (state: RootState) => {
  return state.projects.projects;
};
