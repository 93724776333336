/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 18th June 2021 12:21:54 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

export type CSSClass = string | { [key: string]: boolean };
export function classnames(...cssClasses: CSSClass[]): string {
  let classes = [];
  for (let cssClass of cssClasses) {
    if (typeof cssClass === "string") {
      classes.push(cssClass);
    } else {
      for (let key of Object.keys(cssClass)) {
        if (cssClass[key]) {
          classes.push(key);
        }
      }
    }
  }
  return classes.join(" ");
}
