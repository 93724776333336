/*
 * File: scope.state.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 31st August 2021 5:13:07 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ScopeDTO } from "services/storage-service/dto/scope.dto";
import { BaseState, RequestStatus } from "store/base/base.state";

export interface ScopeState extends BaseState<ScopeDTO> {}

export const INITIAL_SCOPE_STATE = {
  entities: {},
  ids: [],
  id: -1,
  status: RequestStatus.IDLE,
  error: null,
};
