/*
 * File: button-complete-job.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th July 2022 2:02:45 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { CircularProgress } from "@material-ui/core";
import { useTextLabelingBatchContext } from "pages/labeler/text-labeling/context/text-labeling-batch/text-labeling-batch.context";

export const ButtonCompleteJob = () => {
  const { isCompletingJob, completeWorkingJob } = useTextLabelingBatchContext();
  return (
    <button
      onClick={completeWorkingJob}
      style={{ minWidth: "10rem" }}
      className="relative button-text-primary"
    >
      {!isCompletingJob && <span>Complete</span>}
      {isCompletingJob && <CircularProgress color="inherit" size={20} />}
    </button>
  );
};
