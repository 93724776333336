/*
 * File: count-dataset-files.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 13th July 2021 11:43:29 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { storageAPIget } from "../storage-httpclient";

export function countDatasetFiles(dataSetID: number) {
  const params = new URLSearchParams();
  params.append("datasetId.equals", dataSetID + "");
  return storageAPIget(`api/v1/file-infos/count?${params.toString()}`);
}

export function countExportedDatasetFiles(
  dataSetID: number,
  scopeIds: number[]
) {
  const params = new URLSearchParams();
  params.append("datasetId.equals", dataSetID + "");
  params.append("scopeId.in", scopeIds.join(","));
  return storageAPIget(`api/v1/file-infos/count?${params.toString()}`);
}
