/*
 * File: text-viewer
 * Project: aiscaler-web
 * File Created: Monday, 25th October 2021 10:36:16 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useRef, useLayoutEffect } from "react";
import { Label, Token } from "domain/text-labeling";
import { BoundingBox } from "../../models/text-viewer.models";

interface TextViewerLoaderProps {
  labels: Label[];
  tokens: Token[];
  onLoaded?(boxes: Record<string, BoundingBox>): void;
}
export const TextViewerLoader = ({
  labels,
  tokens,
  onLoaded,
}: TextViewerLoaderProps) => {
  const tokenContainerRef = useRef<HTMLDivElement>(null);
  const labelContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!tokenContainerRef.current || !labelContainerRef.current) return;
    const boxes: Record<string, BoundingBox> = {};

    {
      const elements = tokenContainerRef.current?.getElementsByClassName(
        "token"
      );
      const containerBox = tokenContainerRef.current?.getBoundingClientRect();
      for (let i = 0; i < elements.length; i++) {
        const element = elements.item(i);
        if (element) {
          const box = element.getBoundingClientRect();
          box.x -= containerBox.x;
          box.y -= containerBox.y;
          boxes[element.id] = box;
        }
      }
    }

    {
      const elements = labelContainerRef.current?.getElementsByClassName(
        "token-observation"
      );
      const containerBox = labelContainerRef.current?.getBoundingClientRect();
      for (let i = 0; i < elements.length; i++) {
        const element = elements.item(i);
        if (element) {
          const box = element.getBoundingClientRect();
          box.x -= containerBox.x;
          box.y -= containerBox.y;
          boxes[element.id] = box;
        }
      }
    }
    onLoaded && onLoaded(boxes);
  }, [tokenContainerRef, labelContainerRef, onLoaded]);

  return (
    <div>
      <div ref={labelContainerRef}>
        <svg width={800} height={400}>
          {labels.map((label) => {
            return (
              <text
                key={label.id}
                id={label.id}
                data-label-id={label.id}
                className="select-none token-observation"
                fontSize={12}
              >
                {label.name}
              </text>
            );
          })}
        </svg>
      </div>

      <div ref={tokenContainerRef} style={{ height: 400 }}>
        <svg width={800} height={400}>
          {tokens.map((token) => {
            return (
              <text
                key={token.id}
                id={token.id}
                data-token-id={token.id}
                className="text-center token"
              >
                {token.text}
              </text>
            );
          })}
        </svg>
      </div>
    </div>
  );
};
