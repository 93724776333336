/*
 * File: storage.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 10:10:06 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { ENV_CONFIG } from "configs/env.config";
import {
  MetadataResourceApi,
  Configuration,
  MetadataKeyCriteria,
  MetadataKeyDTO,
  Pagination,
  MetadataValueCriteria,
  MetadataValueDTO,
  FileInfoMetadataRequestDTOActionEnum,
  DataSetResourceApi,
  DatasetCriteria,
  DatasetDTO,
  DatasetVersionDTO,
  DatasetAttributeDTO,
  FileInfoResourceApi,
} from "data-access/generated/storage";
import {
  Entity,
  Filter,
  IPagination,
  IPaginationResponse,
  IResponse,
} from "domain/common";
import { AuthService } from "services/auth";
import { BaseFilter } from "./types";

export module StorageApi {
  function getConfiguration() {
    const Scope = AuthService.getUserScope();
    const Workspace = AuthService.getUserWorkspace();
    const Authorization = `Bearer ${AuthService.getAccessToken()}`;
    return new Configuration({
      basePath: ENV_CONFIG.STORAGE_SERVICE_URL,
      baseOptions: { headers: { Authorization, Scope, Workspace } },
    });
  }

  function getPagination(
    pagination?: Pagination,
    filter?: Filter
  ): IPagination {
    return {
      page: pagination?.pageNumber ?? filter?.page ?? 0,
      size: pagination?.pageSize ?? filter?.size ?? 20,
      totalCount: pagination?.totalItem ?? 0,
    };
  }

  export interface IMetadataKey extends Entity {
    id: number;
    name?: string;
    code?: string;
  }

  class MetadataKeyFilter extends BaseFilter {
    getName() {
      return this.getStringField("name");
    }

    setName(name?: string) {
      return this.setQuery("name", name);
    }

    toCriteria(): MetadataKeyCriteria {
      return {
        "name.equals": this.getName(),
      };
    }
  }

  export interface IMetadataKeyMapper {
    fromDTO(dto: MetadataKeyDTO): IMetadataKey;
  }

  export class MetadataKeyMapper implements IMetadataKeyMapper {
    fromDTO(dto: MetadataKeyDTO): IMetadataKey {
      if (!dto.id) throw new Error("Invalid DTO Object");
      return {
        id: dto.id,
        code: dto.code,
        name: dto.name,
      };
    }
  }

  export async function getAllMetadataKeys(
    filter: Filter
  ): Promise<IPaginationResponse<IMetadataKey>> {
    const configuration = getConfiguration();
    const api = new MetadataResourceApi(configuration);
    const metadataKeyFilter = new MetadataKeyFilter(filter);
    const criteria = metadataKeyFilter.toCriteria();
    const pageable = metadataKeyFilter.toPageable();
    const params = { criteria, pageable };
    const response = await api.getAllMetadataKeysUsingGET(params);
    const mapper = new MetadataKeyMapper();
    const items = (response.data.payload ?? []).map(mapper.fromDTO);
    const pagination = getPagination(response.data.pagination, filter);
    return { data: items, pagination };
  }

  export interface IMetadataValue extends Entity {
    id: number;
    name?: string;
    code?: string;
  }

  class MetadataValueFilter extends BaseFilter {
    getName() {
      return this.getStringField("name");
    }

    setName(name?: string) {
      return this.setQuery("name", name);
    }

    toCriteria(): MetadataValueCriteria {
      return {
        "name.equals": this.getName(),
      };
    }
  }

  export interface IMetadataValueMapper {
    fromDTO(dto: MetadataValueDTO): IMetadataValue;
  }

  export class MetadataValueMapper implements IMetadataValueMapper {
    fromDTO(dto: MetadataValueDTO): IMetadataValue {
      if (!dto.id) throw new Error("Invalid DTO Object");
      return {
        id: dto.id,
        code: dto.code,
        name: dto.name,
      };
    }
  }
  export async function getAllMetadataValuesForKey(
    keyId: number
  ): Promise<IPaginationResponse<IMetadataValue>> {
    const configuration = getConfiguration();
    const api = new MetadataResourceApi(configuration);
    const defaultFilter = { page: 0, size: 10000 };
    const params = { keyId, pageable: defaultFilter };
    const response = await api.getAllMetadataValuesByKeyUsingGET(params);
    const mapper = new MetadataValueMapper();
    const items = (response.data.payload ?? []).map(mapper.fromDTO);
    const pagination = getPagination(response.data.pagination, defaultFilter);
    return { data: items, pagination };
  }

  export async function getAllMetadataValues(
    filter: Filter
  ): Promise<IPaginationResponse<IMetadataValue>> {
    const configuration = getConfiguration();
    const api = new MetadataResourceApi(configuration);
    const metadataValueFilter = new MetadataValueFilter(filter);
    const criteria = metadataValueFilter.toCriteria();
    const pageable = metadataValueFilter.toPageable();
    const params = { criteria, pageable };
    const response = await api.getAllMetadataValuesUsingGET(params);
    const mapper = new MetadataValueMapper();
    const items = (response.data.payload ?? []).map(mapper.fromDTO);
    const pagination = getPagination(response.data.pagination, filter);
    return { data: items, pagination };
  }

  export async function getMetadataKeyById(
    id: number
  ): Promise<IResponse<IMetadataKey>> {
    const configuration = getConfiguration();
    const api = new MetadataResourceApi(configuration);
    const response = await api.getMetadataKeyUsingGET({ id });
    const mapper = new MetadataKeyMapper();
    const item = response.data.payload
      ? mapper.fromDTO(response.data.payload)
      : undefined;
    return { data: item };
  }

  export async function getMetadataValueById(
    id: number
  ): Promise<IResponse<IMetadataValue>> {
    const configuration = getConfiguration();
    const api = new MetadataResourceApi(configuration);
    const response = await api.getMetadataValueUsingGET({ id });
    const mapper = new MetadataValueMapper();
    const item = response.data.payload
      ? mapper.fromDTO(response.data.payload)
      : undefined;
    return { data: item };
  }

  export async function createMetadataKeys(metadataKeys: IMetadataKey[]) {
    const configuration = getConfiguration();
    const api = new MetadataResourceApi(configuration);
    const params = {
      metadataKeyDTO: metadataKeys.map((key) => {
        return { code: key.code, name: key.name };
      }),
    };
    const response = await api.createMetadataKeyPOST(params);
    const mapper = new MetadataKeyMapper();
    const items = (response.data.payload ?? []).map(mapper.fromDTO);
    const pagination = getPagination(response.data.pagination);
    return { data: items, pagination };
  }

  export async function createMetadataValues(metadataValues: IMetadataValue[]) {
    const configuration = getConfiguration();
    const api = new MetadataResourceApi(configuration);
    const params = {
      metadataValueDTO: metadataValues.map((value) => {
        return { code: value.code, name: value.name };
      }),
    };
    const response = await api.createMetadataValuePOST(params);
    const mapper = new MetadataValueMapper();
    const items = (response.data.payload ?? []).map(mapper.fromDTO);
    const pagination = getPagination(response.data.pagination);
    return { data: items, pagination };
  }

  export async function deleteMetadataKey(id: number) {
    const configuration = getConfiguration();
    const api = new MetadataResourceApi(configuration);
    const params = { id };
    const response = await api.deleteMetadataKeyUsingDELETE(params);
    return response.data;
  }

  export async function deleteMetadataValue(id: number) {
    const configuration = getConfiguration();
    const api = new MetadataResourceApi(configuration);
    const params = { id };
    const response = await api.deleteMetadataValueUsingDELETE(params);
    return response.data;
  }

  export async function updateMetadataKey(entity: IMetadataKey) {
    const configuration = getConfiguration();
    const api = new MetadataResourceApi(configuration);
    const params = { id: entity.id, metadataKeyDTO: entity };
    const response = await api.partialUpdateMetadataKeyUsingPATCH(params);
    const mapper = new MetadataKeyMapper();
    const item = response.data.payload
      ? mapper.fromDTO(response.data.payload)
      : undefined;
    return { data: item };
  }

  export async function updateMetadataValue(entity: IMetadataValue) {
    const configuration = getConfiguration();
    const api = new MetadataResourceApi(configuration);
    const params = { id: entity.id, metadataValueDTO: entity };
    const response = await api.partialUpdateMetadataValueUsingPATCH(params);
    const mapper = new MetadataValueMapper();
    const item = response.data.payload
      ? mapper.fromDTO(response.data.payload)
      : undefined;
    return { data: item };
  }

  export interface IManageFileMetadataPayload {
    fileId: number;
    metadataKeyId: number;
    metadataValueId: number;
    action: string;
  }
  export async function manageFileMetadatas(
    payload: IManageFileMetadataPayload[]
  ) {
    const configuration = getConfiguration();
    const api = new MetadataResourceApi(configuration);
    const params = {
      fileInfoMetadataRequestDTO: payload.map((item) => ({
        fileInfoId: item.fileId,
        keyId: item.metadataKeyId,
        valueId: item.metadataValueId,
        action: item.action as FileInfoMetadataRequestDTOActionEnum,
      })),
    };
    const response = await api.manageMetadataUsingPOST(params);
    return response.data;
  }

  export interface IDataset extends Entity {
    createdBy?: string;
    createdDate?: string;
    lastModifiedDate?: string;
    workspaceId?: string;
    id: number;
    name: string;
    description?: string;
    type?: string;
    category?: string;
    versions?: IDatasetVersion[];
    attributes?: IDatasetAttribute[];
    totalFile?: number;
    thumbnailUrl?: string;
  }

  export interface IDatasetMapper {
    fromDTO(dto: DatasetDTO): IDataset;
  }

  export class DatasetMapper implements IDatasetMapper {
    fromDTO(dto: DatasetDTO): IDataset {
      if (!dto.id) throw new Error("Invalid DTO Object");
      const versionMapper = new DatasetVersionMapper();
      const attributeMapper = new DatasetAttributeMapper();
      return {
        createdBy: dto.createdBy,
        createdDate: dto.createdDate,
        lastModifiedDate: dto.lastModifiedDate,
        workspaceId: dto.workspaceId,
        id: dto.id,
        name: dto.name ?? "",
        description: dto.description,
        type: dto.type,
        category: dto.category,
        versions: dto.versions?.map(versionMapper.fromDto),
        attributes: dto.attributes?.map(attributeMapper.fromDto),
        totalFile: dto.totalFile,
        thumbnailUrl: dto.thumbnailUrl,
      };
    }
  }

  export interface IDatasetVersion extends Entity {
    createdBy?: string;
    createdDate?: string;
    lastModifiedDate?: string;
    workspaceId?: string;
    id: number;
    datasetId?: number;
    datasetVersion?: string;
    location?: string;
    description?: string;
    datasetName?: string;
    databricksDatasetVersion?: string;
  }

  export interface IDatasetVerionMapper {
    fromDto(dto: DatasetVersionDTO): IDatasetVersion;
  }

  export class DatasetVersionMapper implements IDatasetVerionMapper {
    fromDto(dto: DatasetVersionDTO): IDatasetVersion {
      if (!dto.id) throw new Error("Invalid DTO Object");
      return {
        createdBy: dto.createdBy,
        createdDate: dto.createdDate,
        lastModifiedDate: dto.lastModifiedDate,
        workspaceId: dto.workspaceId,
        id: dto.id,
        datasetId: dto.datasetId,
        datasetVersion: dto.datasetVersion,
        location: dto.location,
        description: dto.description,
        datasetName: dto.datasetName,
        databricksDatasetVersion: dto.databricksDatasetVersion,
      };
    }
  }

  export interface IDatasetAttribute extends Entity {
    createdBy?: string;
    createdDate?: string;
    lastModifiedDate?: string;
    workspaceId?: string;
    id: number;
    datasetId?: number;
    attribute?: string;
    value?: string;
    type?: string;
  }

  export interface IDatasetAttributeMapper {
    fromDto(dto: DatasetAttributeDTO): IDatasetAttribute;
  }

  export class DatasetAttributeMapper implements IDatasetAttributeMapper {
    fromDto(dto: DatasetAttributeDTO): IDatasetAttribute {
      if (!dto.id) throw new Error("Invalid DTO Object");
      return {
        createdBy: dto.createdBy,
        createdDate: dto.createdDate,
        lastModifiedDate: dto.lastModifiedDate,
        workspaceId: dto.workspaceId,
        id: dto.id,
        datasetId: dto.datasetId,
        attribute: dto.attribute,
        value: dto.value,
        type: dto.type,
      };
    }
  }

  export class DatasetFilter extends BaseFilter {
    getType() {
      return this.getStringField("type");
    }
    getName() {
      return this.getStringField("name");
    }
    getCategory() {
      return this.getStringField("category");
    }
    getCreatedDate() {
      return this.getStringField("createdDate");
    }
    getLabelingType() {
      return this.getStringField("labelingType");
    }

    setType(type?: string) {
      return this.setQuery("type", type);
    }
    setName(name?: string) {
      return this.setQuery("name", name);
    }
    setCategory(category?: string) {
      return this.setQuery("category", category);
    }
    setCreatedDate(createdDate?: string) {
      return this.setQuery("createdDate", createdDate);
    }
    setLabelingType(labelingType?: string) {
      return this.setQuery("labelingType", labelingType);
    }

    toCriteria(): DatasetCriteria {
      return {
        "type.equals": this.getType(),
        "name.equals": this.getName(),
        "category.equals": this.getCategory(),
        "createdDate.equals": this.getCreatedDate(),
      };
    }
  }

  export async function getDatasets(
    filter: Filter
  ): Promise<IPaginationResponse<IDataset>> {
    const configuration = getConfiguration();
    const api = new DataSetResourceApi(configuration);
    const datasetFilter = new DatasetFilter(filter);
    const criteria = datasetFilter.toCriteria();
    const pageable = datasetFilter.toPageable();
    const params = { criteria, pageable };
    const response = await api.getAllDatasetsUsingGET(params);
    const mapper = new DatasetMapper();
    const items = (response.data.payload ?? []).map(mapper.fromDTO);
    const pagination = getPagination(response.data.pagination);
    return { data: items, pagination };
  }

  export async function createDataset(
    data: FormData
  ): Promise<IResponse<IDataset>> {
    const configuration = getConfiguration();
    const api = new DataSetResourceApi(configuration);
    const datasetDTO = {
      createdBy: data.get("createdBy")?.toString(),
      name: data.get("name")?.toString(),
      category: data.get("category")?.toString(),
      description: data.get("description")?.toString(),
    };
    const mapper = new DatasetMapper();
    const response = await api.createDatasetUsingPOST({ datasetDTO });
    if (!response.data.payload) throw new Error("Failed to create dataset");
    return { data: mapper.fromDTO(response.data.payload) };
  }

  export async function deleteDataset(datasetId: number) {
    const configuration = getConfiguration();
    const api = new DataSetResourceApi(configuration);
    const response = await api.deleteDatasetUsingDELETE({ id: datasetId });
    return response.data;
  }

  export async function duplicateDataset(datasetId: number, name: string) {
    const configuration = getConfiguration();
    const api = new DataSetResourceApi(configuration);
    const response = await api.duplicateDatasetsUsingPOST({ id: datasetId });
    return response.data;
  }

  export async function mergeDatasets(options: {
    datasetIds: number[];
    name: string;
    options: { deleteSource: boolean };
  }) {
    const configuration = getConfiguration();
    const api = new DataSetResourceApi(configuration);
    const response = await api.mergeDatasets({
      mergeDatasetRequestDTO: options,
    });
    return response.data;
  }

  export async function deleteFile(
    fileId: number,
    option?: "archive" | "delete_file" | "delete_all"
  ) {
    const configuration = getConfiguration();
    const api = new FileInfoResourceApi(configuration);
    const params = { id: fileId, option };
    const response = await api.deleteFileInfoUsingDELETE(params);
    return response.data;
  }

  export async function moveFiles(
    fileIds: number[],
    fromDatasetId: number,
    toDatasetId: number,
    move?: boolean
  ) {
    const configuration = getConfiguration();
    const api = new DataSetResourceApi(configuration);
    const response = await api.copyDatasetFileInfos({
      copyDatasetRequestDTO: {
        fileInfoIds: fileIds,
        fromDatasetId: fromDatasetId,
        toDatasetId: toDatasetId,
        move: move,
      },
    });
    return response.data;
  }
}
