import "@kitware/vtk.js/Rendering/Profiles/All";
import vtkImageData from "@kitware/vtk.js/Common/DataModel/ImageData";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import { Vector3 } from "@kitware/vtk.js/types";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CaptureOn,
  fitImageBoundToCamera,
  InteractionEventTypes,
  SlicingMode,
  vtkInteractorStyleImageCustom,
} from "../vtk_import";
import { useThreeDEditorContext } from "./three-d-editor.provider";
import {
  EditorEventForceWindowLeave,
  EditorEventSwapWindow,
  EditorMeasurement,
  EditorTool,
  EditorMeasurementType,
  ThreeDEditorEvents,
  TOOL_ADJUST_WINDOW_COLOR_RECTANGLE,
  TOOL_NAVIGATION_CROSS_HAIR,
  TOOL_SEGMENT_BRUSH,
  TOOL_SEGMENT_POLY,
  EditorEventGoToSlice,
} from "./three-d-editor.models";
import { classnames } from "utilities/classes";
import { addToVTKObjects } from "./vtk-objects-manager";
import { useUnmount } from "ahooks";
import { useAppPrevious } from "hooks/use-app-previous";
import { ThreeDEditorWindowSelectComponent } from "./three-d-editor-window-select.component";
import {
  getScalarsImageInBound,
  computeHistogramForArrayScalars,
  computeAutoRange,
} from "../vtk/utils";
import { createMeasurement } from "./three-d-editor.utils";
import * as Sentry from "@sentry/react";

interface Props {
  axis: any;
  windowId: number;
  onLoaded?: () => void;
  onError?: (error: any) => void;
}
export const WindowSlicer = forwardRef(
  ({ axis, windowId, onLoaded, onError }: Props, ref: any) => {
    const [currentSlice, setCurrentSlice] = useState(0);
    const [maxSlice, setMaxSlice] = useState(0);
    const [minSlice, setMinSlice] = useState(0);
    const [spacing, setSpacing] = useState(0);

    const {
      activeWindow,
      setActiveWindow,
      editorContext,
      activeTool,
      setActiveTool,
      activeLabel,
      crossHairVisibility,
      labels,
      viewLabels,
      isShowViewLabels,
      updateObjectColorsAndOpacity,
      sliceColorLevel,
      sliceWindowLevel,
      setSliceColorLevel,
      setSliceWindowLevel,
      setBrushRadius,
      slices3dVisibility,
      label3dVisibility,
      currentLayout,
      windowsDataMap,
      notifyWorkingLabelMapChanged,
      measurements,
      setMeasurements,
    } = useThreeDEditorContext();
    const previousEditorContext = useAppPrevious(editorContext);
    const [vtkContext, setVtkContext] = useState<any>();
    const vktContextRef = useRef();
    const isWindowActive = useMemo(
      () => activeWindow === windowId,
      [activeWindow, windowId]
    );

    const crossHairVisibilityRef = useRef<boolean>(false);
    const [isResliceCursorOnInteraction, setIsResliceCursorOnInteraction] =
      useState(false);

    const isWindowActiveRef = useRef(isWindowActive);
    const activeWindowRef = useRef(activeWindow);

    const axisToTopAxisNameMap: any = {
      [SlicingMode.I]: ["S", "P", "I", "A"], // top right bottom left
      [SlicingMode.J]: ["S", "L", "I", "R"], // top right bottom left
      [SlicingMode.K]: ["A", "L", "P", "R"], // top right bottom left
    };

    const sliceColorLevelRef = useRef(sliceColorLevel);
    const sliceWindowLevelRef = useRef(sliceWindowLevel);
    const slices3dVisibilityRef = useRef(slices3dVisibility);
    const label3dVisibilityRef = useRef(label3dVisibility);

    const isSelfCheckActiveWindow = useRef(false);

    const shouldSendPaintEndStrokeEventRef = useRef(false);

    const shouldAddPointToPainter = useMemo(() => {
      const shouldNot = activeLabel.isNone && activeTool?.isDrawing;
      return !shouldNot;
    }, [activeLabel, activeTool]);
    const shouldAddPointToPainterRef = useRef(shouldAddPointToPainter);

    // measurements
    // measurement that is not added to the list yet.
    const pendingMeasurement = useRef<EditorMeasurement>();
    const measurementsRef = useRef(measurements);

    useEffect(() => {
      shouldAddPointToPainterRef.current = shouldAddPointToPainter;
    }, [shouldAddPointToPainter]);

    useEffect(() => {
      sliceColorLevelRef.current = sliceColorLevel;
    }, [sliceColorLevel]);

    useEffect(() => {
      sliceWindowLevelRef.current = sliceWindowLevel;
    }, [sliceWindowLevel]);

    useEffect(() => {
      slices3dVisibilityRef.current = slices3dVisibility;
    }, [slices3dVisibility]);

    useEffect(() => {
      label3dVisibilityRef.current = label3dVisibility;
    }, [label3dVisibility]);

    useEffect(() => {
      isWindowActiveRef.current = isWindowActive;
    }, [isWindowActive]);

    useEffect(() => {
      activeWindowRef.current = activeWindow;
    }, [activeWindow]);

    useEffect(() => {
      measurementsRef.current = measurements;
    }, [measurements]);

    const update = useCallback((props: any) => {
      const { image, imageData, widgets, handles, labelMap, windowId } = props;
      const slicingMode = image.mapper.getSlicingMode() % 3;
      const ijk: Vector3 = [0, 0, 0];
      const position: Vector3 = [0, 0, 0];
      const slice = image.mapper.getSlice();
      ijk[slicingMode] = slice;
      imageData.indexToWorld(ijk, position);
      setCurrentSlice(slice);

      widgets.paintWidget.getManipulator().setHandleOrigin(position);
      widgets.polygonWidget.getManipulator().setHandleOrigin(position);
      widgets.adjustWindowColorRectangleWidget
        .getManipulator()
        .setHandleOrigin(position);
      if (pendingMeasurement.current) {
        pendingMeasurement.current.widget
          .getManipulator()
          .setHandleOrigin(position);
      }

      handles.paintHandle.updateRepresentationForRender();
      handles.polygonHandle.updateRepresentationForRender();
      handles.adjustWindowColorRectangleHandle.updateRepresentationForRender();
      labelMap.mapper.set(image.mapper.get("slice", "slicingMode"));

      let needRender = false;
      // Update measurements visibility
      for (const measurement of measurementsRef.current) {
        // only check for the windowId
        if (windowId !== measurement.windowId) continue;

        const visible = slice === measurement.slice;
        measurement.viewWidget.setVisibility(visible);
        needRender = true;
      }

      return needRender;
    }, []);

    const moveResliceCursorToImageCenter = useCallback((widgets, imageData) => {
      const imageCenter = imageData.getCenter();
      widgets.resliceCursorWidget.setCenter(imageCenter);
    }, []);

    const moveResliceCursorToSlice = useCallback(
      (axis, widgets: any, imageData: any, image: any) => {
        const widgetState = widgets.resliceCursorWidget.getWidgetState();
        let center = widgetState.getCenter();
        const ijkCenter = imageData.worldToIndex(center);
        let slice = image.mapper.getSlice();
        if (slice < 0) {
          slice = 0;
          image.mapper.setSlice(0);
        }
        ijkCenter[axis] = slice;
        // move center
        center = imageData.indexToWorld(ijkCenter);
        // set cursor center to new position
        widgets.resliceCursorWidget.setCenter(center);
      },
      []
    );

    const moveSliceToResliceCursor = useCallback(
      (axis, widgets: any, image: any, imageData: any) => {
        const widgetState = widgets.resliceCursorWidget.getWidgetState();
        const center = widgetState.getCenter();
        const centerIJK = imageData.worldToIndex(center);
        // In case 87.00000000000001 it will ceil to 88
        // so we just use maximum 5 decimal places to make sure it is still 87
        const truncatedDecimal = parseFloat(centerIJK[axis].toFixed(5));

        // to make the widget display before the slice
        // TODO: snap to direction toward to the camera later
        let snapSlice = Math.ceil(truncatedDecimal);
        if (axis === SlicingMode.I) {
          snapSlice = Math.floor(truncatedDecimal);
        }
        image.mapper.setSlice(snapSlice);
      },
      []
    );

    const updateSlice = useCallback(
      (vtkContext: any, slice: number) => {
        if (!vtkContext) return;

        const { axis, widgets, imageData, image, windowsSliceArray } =
          vtkContext;

        setCurrentSlice(slice);
        image.mapper.setSlice(slice);

        moveResliceCursorToSlice(axis, widgets, imageData, image);

        for (const sliceData of windowsSliceArray) {
          sliceData.windowSlice.renderWindow.getInteractor().render();
        }
      },
      [setCurrentSlice, moveResliceCursorToSlice]
    );
    // Init stuff
    useEffect(() => {
      if (!editorContext || !ref.current) return;
      if (editorContext === previousEditorContext) return;

      try {
        console.log("Init window slicer...");
        const {
          windowVolume,
          imageData,
          painter,
          widgets,
          windowsSliceData,
          windowsSliceArray,
        } = editorContext;
        const { windowSlice, imageSlice, handles } = windowsSliceData[axis];
        const {
          genericRenderWindow,
          renderer,
          renderWindow,
          camera,
          widgetManager,
        } = windowSlice;
        const { image, labelMap } = imageSlice;
        const otherSlicesData = windowsSliceArray.filter(
          (sliceData: any) => sliceData.axis !== axis
        );
        genericRenderWindow.setBackground([0.0, 0.0, 0.0]);
        genericRenderWindow.resize();

        // update panel
        const extent: any = imageData.getExtent();
        const minSlice = extent[axis * 2];
        const maxSlice = extent[axis * 2 + 1];
        setMaxSlice(maxSlice);
        setMinSlice(minSlice);

        // set spacing
        const spacing = imageData.getSpacing();
        setSpacing(spacing[axis]);

        // set 2D view
        const isstyle = vtkInteractorStyleImageCustom.newInstance({
          axis,
          image,
          renderer,
          widgetManager,
          minSlice,
          maxSlice,
        });
        addToVTKObjects(isstyle);
        renderWindow.getInteractor().setInteractorStyle(isstyle);
        camera.setParallelProjection(true);

        isstyle.onInteractionEvent((e: any) => {
          const eventType = e.type;
          if (eventType === InteractionEventTypes.WindowLevel) {
            const { newWindow, newLevel } = e;
            for (const sliceData of otherSlicesData) {
              const property = sliceData.imageSlice.image.actor.getProperty();
              property.setColorWindow(newWindow);
              property.setColorLevel(newLevel);
              sliceData.windowSlice.renderWindow.render();
            }
            setSliceWindowLevel(newWindow);
            setSliceColorLevel(newLevel);
          } else if (eventType === InteractionEventTypes.Slice) {
            if (!crossHairVisibilityRef.current) return;
            moveResliceCursorToSlice(axis, widgets, imageData, image);
            for (const sliceData of otherSlicesData) {
              moveSliceToResliceCursor(
                sliceData.axis,
                widgets,
                sliceData.imageSlice.image,
                imageData
              );
              sliceData.windowSlice.renderWindow.render();
            }
          } else if (eventType === InteractionEventTypes.Undo) {
            if (painter.canUndo()) {
              painter.undo();
            }
          } else if (eventType === InteractionEventTypes.Redo) {
            if (painter.canRedo()) {
              painter.redo();
            }
          } else if (eventType === InteractionEventTypes.IncreaseOrDecrease) {
            const { direction } = e;
            let radius = painter.getRadius();
            radius += direction * 0.5;
            if (radius < 0.1) radius = 0.1;
            if (radius > 20) radius = 20;
            radius = parseFloat(radius.toFixed(1));
            painter.setRadius(radius);
            widgets.paintWidget.setRadius(radius);
            setBrushRadius(radius);
          }
        });

        const setCamera = (
          axis: any,
          renderer: vtkRenderer,
          data: vtkImageData
        ) => {
          const ijk: Vector3 = [0, 0, 0];
          const position: Vector3 = [0, 0, 0];
          const focalPoint: Vector3 = [0, 0, 0];
          data.indexToWorld(ijk, focalPoint);
          ijk[axis] = -1;
          if (axis === SlicingMode.I) {
            ijk[axis] = 1;
          }
          data.indexToWorld(ijk, position);

          // setting up vector
          const upVector: Vector3 = [0, 0, 0];
          switch (axis) {
            case SlicingMode.I: // K as height
            case SlicingMode.J: // K as height
              upVector[2] = 1;
              break;
            case SlicingMode.K: // J as height
              upVector[1] = -1;
              break;
            default:
          }

          // data.indexToWorld(ijk, upVector);
          // const imagePos = data.getOrigin();
          // upVector[0] = upVector[0] - imagePos[0];
          // upVector[1] = upVector[1] - imagePos[1];
          // upVector[2] = upVector[2] - imagePos[2];
          // // need to normalize up vector
          // // some how other features might be broken
          // // like brush tool not display correctly
          // normalize(upVector);

          renderer.getActiveCamera().set({
            position: position,
            focalPoint: focalPoint,
            viewUp: upVector,
          });
          const bounds = image.actor.getBounds();
          renderer.resetCamera(bounds);

          fitImageBoundToCamera(axis, renderer, bounds);
        };

        const ready = () => {
          widgetManager.enablePicking();
        };

        // label map pipeline
        labelMap.mapper.setInputConnection(painter.getOutputPort());
        labelMap.actor.setMapper(labelMap.mapper);
        labelMap.actor.getProperty().setRGBTransferFunction(0, labelMap.cfunc);
        labelMap.actor.getProperty().setScalarOpacity(0, labelMap.ofunc);
        labelMap.actor.getProperty().setUseLookupTableScalarRange(true);
        labelMap.actor.getProperty().setInterpolationTypeToNearest();

        image.actor.setMapper(image.mapper);
        image.actor.getProperty().setColorWindow(sliceWindowLevelRef.current);
        image.actor.getProperty().setColorLevel(sliceColorLevelRef.current);
        image.actor.getProperty().setUseLookupTableScalarRange(false);

        // ----------------------------------------------------------------------------
        // Painting
        // ----------------------------------------------------------------------------
        const initializeHandle = (handle: any) => {
          handle.onStartInteractionEvent(() => {
            painter.startStroke();
          });
          handle.onEndInteractionEvent(() => {
            painter.endStroke();
            shouldSendPaintEndStrokeEventRef.current = true;
            notifyWorkingLabelMapChanged();
          });
        };

        painter.onModified(() => {
          if (shouldSendPaintEndStrokeEventRef.current) {
            document.dispatchEvent(
              new CustomEvent(ThreeDEditorEvents.PAINT_END_STROKE, {})
            );
            shouldSendPaintEndStrokeEventRef.current = false;
          }

          for (const sliceData of otherSlicesData) {
            sliceData.windowSlice.renderWindow.render();
          }
        });

        // paint
        handles.paintHandle.onStartInteractionEvent(() => {
          painter.startStroke();
          if (shouldAddPointToPainterRef.current) {
            painter.addPoint(
              widgets.paintWidget.getWidgetState().getTrueOrigin()
            );
          }
        });
        handles.paintHandle.onInteractionEvent(() => {
          if (shouldAddPointToPainterRef.current) {
            painter.addPoint(
              widgets.paintWidget.getWidgetState().getTrueOrigin()
            );
          }
        });
        initializeHandle(handles.paintHandle);
        // poly
        handles.polygonHandle.onEndInteractionEvent(() => {
          if (shouldAddPointToPainterRef.current) {
            const points = handles.polygonHandle.getPoints();
            painter.paintPolygon(points);
          }
          handles.polygonHandle.updateRepresentationForRender();
        });
        initializeHandle(handles.polygonHandle);

        // adjust window color rectangle
        handles.adjustWindowColorRectangleHandle.onEndInteractionEvent(() => {
          const worldBounds =
            handles.adjustWindowColorRectangleHandle.getBounds();

          const { maximum, minimum, regionScalars } = getScalarsImageInBound(
            imageData,
            worldBounds
          );

          const numberOfBins = 256;
          const histogram = computeHistogramForArrayScalars({
            array: regionScalars,
            min: minimum,
            max: maximum,
            numberOfBins,
            offset: 0,
            step: 1,
          });

          const autoRange = computeAutoRange({
            histogram,
            min: minimum,
            max: maximum,
            numberOfBins,
            total: regionScalars.length,
          });

          const window = autoRange[1] - autoRange[0];
          const level = 0.5 * (autoRange[0] + autoRange[1]);

          for (const sliceData of windowsSliceArray) {
            const property = sliceData.imageSlice.image.actor.getProperty();
            property.setColorWindow(window);
            property.setColorLevel(level);
            if (sliceData.axis !== axis) {
              sliceData.handles.adjustWindowColorRectangleHandle.reset();
            }
            sliceData.windowSlice.renderWindow.render();
          }
          setSliceWindowLevel(window);
          setSliceColorLevel(level);
        });

        handles.polygonHandle.setOutputBorder(true);
        // reslice cursor
        handles.resliceCursorHandle
          .getRepresentations()[0]
          .setScaleInPixels(true);
        widgetManager.setCaptureOn(CaptureOn.MOUSE_MOVE);
        handles.resliceCursorHandle.setVisibility(false);

        handles.resliceCursorHandle.onActivateHandle(() => {
          if (!crossHairVisibilityRef.current) return;
          moveResliceCursorToSlice(axis, widgets, imageData, image);
          for (const sliceData of otherSlicesData) {
            moveSliceToResliceCursor(
              sliceData.axis,
              widgets,
              sliceData.imageSlice.image,
              imageData
            );
            sliceData.windowSlice.renderWindow.render();
          }
        });

        handles.resliceCursorHandle.onStartInteractionEvent(() => {
          setIsResliceCursorOnInteraction(true);
        });

        handles.resliceCursorHandle.onInteractionEvent(
          ({ computeFocalPointOffset, canUpdateFocalPoint }: any) => {
            if (!crossHairVisibilityRef.current) return;
            moveSliceToResliceCursor(axis, widgets, image, imageData);
            for (const sliceData of otherSlicesData) {
              moveSliceToResliceCursor(
                sliceData.axis,
                widgets,
                sliceData.imageSlice.image,
                imageData
              );
            }
          }
        );

        handles.resliceCursorHandle.onEndInteractionEvent(() => {
          setIsResliceCursorOnInteraction(false);
          // handle scroll case
          moveResliceCursorToSlice(axis, widgets, imageData, image);
          for (const sliceData of otherSlicesData) {
            moveSliceToResliceCursor(
              sliceData.axis,
              widgets,
              sliceData.imageSlice.image,
              imageData
            );
          }
        });

        ready();

        genericRenderWindow.onResize(() => {
          setCamera(axis, renderer, imageData);
        });

        // set priorities to disabled scrolling from reslice cursor
        // also prevent using tool when press shift, ctrol, alt
        // pan, dolly, spin always have higer priority than tools
        // we return EVENT_ABORT in vtkInteractorStyleImageCustom
        // so to prevent tool to process event
        // higher value means having higher priority
        isstyle.setPriority(2);
        handles.resliceCursorHandle.setPriority(1);
        handles.paintHandle.setPriority(1);
        handles.polygonHandle.setPriority(1);
        handles.adjustWindowColorRectangleHandle.setPriority(1);

        // set input data
        image.mapper.setInputData(imageData);
        // add actors to renderers
        renderer.addViewProp(image.actor);
        renderer.addViewProp(labelMap.actor);

        // set slicing mode
        const initSlice = Math.floor(maxSlice / 2);
        image.mapper.setSlicingMode(axis);
        image.mapper.setSlice(initSlice);

        image.mapper.onModified(() => {
          const needRender = update({
            image,
            imageData,
            widgets,
            painter,
            handles,
            labelMap,
            windowId,
          });
          if (slices3dVisibilityRef.current) {
            windowVolume.renderWindow.getInteractor().render();
          }
          if (needRender) {
            renderWindow.getInteractor().render();
          }
        });

        // trigger initial update
        update({
          image,
          imageData,
          widgets,
          painter,
          handles,
          labelMap,
          windowId,
        });
        setCamera(axis, renderer, imageData);

        renderWindow.render();

        const value: any = {
          axis,
          imageData,
          widgetManager,
          isstyle,
          widgets,
          labelMap,
          image,
          handles,
          genericRenderWindow,
          renderWindow,
          renderer,
          painter,
          camera,
          windowVolume,
          windowsSliceArray,
          otherSlicesData,
        };
        setVtkContext(value);
        vktContextRef.current = value;
        console.log(`Done init window slice: ${axis}`);

        onLoaded && onLoaded();
      } catch (error: any) {
        Sentry.captureException(error);
        console.log(error);
        onError && onError(error);
      }

      return () => {
        pendingMeasurement.current = undefined;
      };
    }, [
      previousEditorContext,
      editorContext,
      ref,
      axis,
      windowId,
      setMeasurements,
      update,
      moveResliceCursorToSlice,
      moveSliceToResliceCursor,
      setSliceWindowLevel,
      setSliceColorLevel,
      setBrushRadius,
      notifyWorkingLabelMapChanged,
      onLoaded,
      onError,
    ]);

    // enabled/disabled window level based on active tools
    useEffect(() => {
      if (!vtkContext) return;
      const { isstyle } = vtkContext;
      if (!isWindowActive) return;

      if (
        isResliceCursorOnInteraction ||
        (activeTool && activeTool.id !== TOOL_NAVIGATION_CROSS_HAIR.id)
      ) {
        isstyle.setEnabledWindowLevel(false);
      } else {
        isstyle.setEnabledWindowLevel(true);
      }
    }, [vtkContext, isResliceCursorOnInteraction, activeTool, isWindowActive]);

    // update label visibile, opacity when labels changed
    useEffect(() => {
      if (!vtkContext || !labels) return;
      const { labelMap, widgetManager, renderer, imageData, renderWindow } =
        vtkContext;

      if (isShowViewLabels) {
        updateObjectColorsAndOpacity(labelMap, viewLabels);
      } else {
        updateObjectColorsAndOpacity(labelMap, labels);
      }

      if (
        !widgetManager.isDeleted() &&
        !renderer.isDeleted() &&
        !imageData.isDeleted() &&
        !renderWindow.isDeleted() &&
        !renderWindow.getInteractor().isDeleted()
      ) {
        try {
          renderWindow.render();
        } catch (error: any) {Sentry.captureException(error);}
      }
    }, [
      labels,
      viewLabels,
      isShowViewLabels,
      vtkContext,
      updateObjectColorsAndOpacity,
    ]);

    // changing cross hair visibility
    useEffect(() => {
      crossHairVisibilityRef.current = crossHairVisibility;
      if (!vtkContext) return;
      const { axis, handles, renderWindow, widgets, imageData, image } =
        vtkContext;
      handles.resliceCursorHandle.setVisibility(crossHairVisibility);
      handles.resliceCursorHandle.setEnabled(crossHairVisibility);

      if (crossHairVisibility) {
        if (isWindowActiveRef.current) {
          moveResliceCursorToSlice(axis, widgets, imageData, image);
        } else if (activeWindowRef.current === -1) {
          moveSliceToResliceCursor(axis, widgets, image, imageData);
        }
      }

      renderWindow.render();
    }, [
      crossHairVisibility,
      vtkContext,
      moveResliceCursorToImageCenter,
      moveSliceToResliceCursor,
      moveResliceCursorToSlice,
    ]);

    const updateHandlesVisibility = useCallback(
      (
        visible: boolean,
        vtkContext: any,
        activeTool: EditorTool | undefined
      ) => {
        if (!vtkContext) return;
        const { handles, renderWindow } = vtkContext;
        if (activeTool?.groupId === TOOL_SEGMENT_BRUSH.groupId) {
          handles.paintHandle.setVisibility(visible);
        }
        if (activeTool?.groupId === TOOL_SEGMENT_POLY.groupId) {
          // BUG if use this line the cross hair stuff will have problem
          // handles.polygonHandle.setVisibility(visible);
        }

        if (activeTool?.groupId !== TOOL_SEGMENT_BRUSH.groupId) {
          handles.paintHandle.setVisibility(false);
        }
        if (activeTool?.groupId !== TOOL_SEGMENT_POLY.groupId) {
          // BUG if use this line the cross hair stuff will have problem
          // handles.polygonHandle.setVisibility(false);
          handles.polygonHandle.reset();
        }
        if (activeTool?.id !== TOOL_ADJUST_WINDOW_COLOR_RECTANGLE.id) {
          handles.adjustWindowColorRectangleHandle.reset();
        }

        renderWindow.render();
      },
      []
    );

    // window resize
    useEffect(() => {
      if (!vtkContext) return;
      const { genericRenderWindow } = vtkContext;
      genericRenderWindow.resize();
    }, [vtkContext, currentLayout, windowsDataMap]);

    // Create pending measurement by type
    const createPendingMeasurement = useCallback(
      (
        windowId: number,
        vtkContext: any,
        type: EditorMeasurementType,
        grabFocus = true
      ) => {
        if (!vtkContext) return;
        const { image, imageData, widgetManager } = vtkContext;
        pendingMeasurement.current = createMeasurement({
          type,
          windowId,
          image,
          imageData,
          widgetManager,
        });

        if (grabFocus) {
          widgetManager.grabFocus(pendingMeasurement.current.widget);
        }

        widgetManager.renderWidgets();
      },
      []
    );

    // active tool changed
    useEffect(() => {
      if (!vtkContext) return;
      if (activeWindow !== windowId) return;

      const {
        axis,
        image,
        imageData,
        painter,
        widgetManager,
        widgets,
        handles,
        labelMap,
      } = vtkContext;

      widgetManager.releaseFocus();
      if (activeTool) {
        painter.setSlicingMode(axis);
        update({
          image,
          imageData,
          widgets,
          painter,
          handles,
          labelMap,
          windowId,
        });
      }
      if (activeTool?.groupId === TOOL_SEGMENT_BRUSH.groupId) {
        widgetManager.grabFocus(widgets.paintWidget);
      } else if (activeTool?.groupId === TOOL_SEGMENT_POLY.groupId) {
        widgetManager.grabFocus(widgets.polygonWidget);
      } else if (activeTool?.id === TOOL_NAVIGATION_CROSS_HAIR.id) {
        handles.resliceCursorHandle.setDragable(true);
      } else if (activeTool?.id === TOOL_ADJUST_WINDOW_COLOR_RECTANGLE.id) {
        widgetManager.grabFocus(widgets.adjustWindowColorRectangleWidget);
      } else if (activeTool?.isMeasurement && activeTool?.measurementType) {
        if (windowId === activeWindow) {
          if (!pendingMeasurement.current) {
            createPendingMeasurement(
              windowId,
              vtkContext,
              activeTool.measurementType
            );
          } else {
            widgetManager.grabFocus(pendingMeasurement.current.widget);
          }
        }
      } else if (!activeTool) {
        widgetManager.releaseFocus();
        handles.resliceCursorHandle.setDragable(false);
      }

      updateHandlesVisibility(!!activeTool, vtkContext, activeTool);
    }, [
      activeTool,
      vtkContext,
      activeWindow,
      windowId,
      update,
      updateHandlesVisibility,
      createPendingMeasurement,
    ]);

    useUnmount(() => {
      console.log("unmount windowSlicer");
      setVtkContext(undefined);
    });

    const handleSliceChanged = (slice: number) => {
      updateSlice(vtkContext, slice);
    };

    const doWorksOnWindowActive = useCallback(
      (vtkContext, windowId, activeTool) => {
        if (!vtkContext) return;
        setActiveWindow(windowId);

        const {
          axis,
          image,
          imageData,
          isstyle,
          painter,
          widgetManager,
          widgets,
          handles,
          labelMap,
        } = vtkContext;

        isstyle.setIsWindowActive(true);
        if (activeTool) {
          painter.setSlicingMode(axis);
          update({
            image,
            imageData,
            widgets,
            painter,
            handles,
            labelMap,
            windowId,
          });
        }
        if (activeTool?.groupId === TOOL_SEGMENT_BRUSH.groupId) {
          widgetManager.grabFocus(widgets.paintWidget);
        } else if (activeTool?.groupId === TOOL_SEGMENT_POLY.groupId) {
          widgetManager.grabFocus(widgets.polygonWidget);
        } else if (activeTool?.id === TOOL_ADJUST_WINDOW_COLOR_RECTANGLE.id) {
          widgetManager.grabFocus(widgets.adjustWindowColorRectangleWidget);
        } else if (activeTool?.isMeasurement && activeTool?.measurementType) {
          if (windowId === activeWindowRef.current) {
            if (!pendingMeasurement.current) {
              createPendingMeasurement(
                windowId,
                vtkContext,
                activeTool.measurementType
              );
            } else {
              widgetManager.grabFocus(pendingMeasurement.current.widget);
            }
          }
        } else if (activeTool?.id === TOOL_NAVIGATION_CROSS_HAIR.id) {
          handles.resliceCursorHandle.setDragable(true);
        }
        updateHandlesVisibility(true, vtkContext, activeTool);
      },
      [
        setActiveWindow,
        updateHandlesVisibility,
        update,
        createPendingMeasurement,
      ]
    );

    const doWorksOnWindowLeave = useCallback(
      (vtkContext, activeTool) => {
        setActiveWindow(-1);
        if (!vtkContext) return;

        const { isstyle, widgetManager, handles } = vtkContext;
        vtkContext.painter.clearHistory();

        isstyle.setIsWindowActive(false);
        handles.resliceCursorHandle.setDragable(false);
        widgetManager.releaseFocus();
        updateHandlesVisibility(false, vtkContext, activeTool);
      },
      [setActiveWindow, updateHandlesVisibility]
    );

    // listen for window enter, leave command events
    useEffect(() => {
      if (!vtkContext) return;

      const handleCommandAfterSwapWindowLeave = (e: any) => {
        const swapEvent = e.detail as EditorEventSwapWindow;
        if (swapEvent.fromWindow.id === windowId) {
          doWorksOnWindowLeave(vtkContext, activeTool);
          document.dispatchEvent(
            new CustomEvent(
              ThreeDEditorEvents.COMMAND_AFTER_SWAP_WINDOW_ENTER,
              { detail: swapEvent }
            )
          );
        }
      };

      const handleCommandAfterSwapWindowEnter = (e: any) => {
        const swapEvent = e.detail as EditorEventSwapWindow;
        if (swapEvent.toWindow.id === windowId) {
          doWorksOnWindowActive(vtkContext, windowId, activeTool);
        }
      };

      const handleCommandCheckSelfActiveWindow = (e: any) => {
        isSelfCheckActiveWindow.current = true;
      };

      const handleCommandForceWindowLeave = (e: any) => {
        const leaveEvent = e.detail as EditorEventForceWindowLeave;
        if (leaveEvent.windowToLeave.id === windowId) {
          doWorksOnWindowLeave(vtkContext, activeTool);
        }
      };

      // measurements
      const handleMeasurementFinalized = (e: any) => {
        const measurement: EditorMeasurement = e.detail as EditorMeasurement;
        if (measurement.windowId === windowId) {
          setMeasurements([...measurementsRef.current, measurement]);
          if (activeTool?.isMeasurement) {
            setActiveTool(undefined);
            pendingMeasurement.current = undefined;
            // createPendingMeasurement(
            //   windowId,
            //   vtkContext,
            //   EditorMeasurementType.distance
            // );
          }
        }
      };
      const handleGoToSlice = (e: any) => {
        const eventData: EditorEventGoToSlice =
          e.detail as EditorEventGoToSlice;
        if (eventData.windowId === windowId || eventData.axis === axis) {
          updateSlice(vtkContext, eventData.slice);
        }
      };
      const handleCommandDeleteMeasurement = (e: any) => {
        const measurement: EditorMeasurement = e.detail as EditorMeasurement;
        if (measurement.windowId === windowId) {
          const { widgetManager } = vtkContext;
          widgetManager.removeWidget(measurement.widget);
          setMeasurements(
            measurementsRef.current.filter((m) => m.uuid !== measurement.uuid)
          );
        }
      };

      document.addEventListener(
        ThreeDEditorEvents.COMMAND_AFTER_SWAP_WINDOW_LEAVE,
        handleCommandAfterSwapWindowLeave
      );
      document.addEventListener(
        ThreeDEditorEvents.COMMAND_AFTER_SWAP_WINDOW_ENTER,
        handleCommandAfterSwapWindowEnter
      );
      document.addEventListener(
        ThreeDEditorEvents.COMMAND_CHECK_SELF_ACTIVE_WINDOW,
        handleCommandCheckSelfActiveWindow
      );
      document.addEventListener(
        ThreeDEditorEvents.COMMAND_FORCE_WINDOW_LEAVE,
        handleCommandForceWindowLeave
      );
      document.addEventListener(
        ThreeDEditorEvents.MEASUREMENT_FINALIZED,
        handleMeasurementFinalized
      );
      document.addEventListener(
        ThreeDEditorEvents.COMMAND_GO_TO_SLICE,
        handleGoToSlice
      );
      document.addEventListener(
        ThreeDEditorEvents.COMMAND_DELETE_MEASUREMENT,
        handleCommandDeleteMeasurement
      );

      return () => {
        document.removeEventListener(
          ThreeDEditorEvents.COMMAND_AFTER_SWAP_WINDOW_LEAVE,
          handleCommandAfterSwapWindowLeave
        );
        document.removeEventListener(
          ThreeDEditorEvents.COMMAND_AFTER_SWAP_WINDOW_ENTER,
          handleCommandAfterSwapWindowEnter
        );
        document.removeEventListener(
          ThreeDEditorEvents.COMMAND_CHECK_SELF_ACTIVE_WINDOW,
          handleCommandCheckSelfActiveWindow
        );
        document.removeEventListener(
          ThreeDEditorEvents.COMMAND_FORCE_WINDOW_LEAVE,
          handleCommandForceWindowLeave
        );
        document.removeEventListener(
          ThreeDEditorEvents.MEASUREMENT_FINALIZED,
          handleMeasurementFinalized
        );
        document.removeEventListener(
          ThreeDEditorEvents.COMMAND_GO_TO_SLICE,
          handleGoToSlice
        );
        document.removeEventListener(
          ThreeDEditorEvents.COMMAND_DELETE_MEASUREMENT,
          handleCommandDeleteMeasurement
        );
      };
    }, [
      vtkContext,
      axis,
      windowId,
      activeTool,
      doWorksOnWindowLeave,
      doWorksOnWindowActive,
      createPendingMeasurement,
      setMeasurements,
      updateSlice,
      setActiveTool,
    ]);

    const handleContainerOnMouseEnter = () => {
      doWorksOnWindowActive(vtkContext, windowId, activeTool);
    };

    const handleContainerOnMouseLeave = () => {
      doWorksOnWindowLeave(vtkContext, activeTool);
    };

    const handleContainerOnMouseMove = (e: any) => {
      if (isSelfCheckActiveWindow.current) {
        if (!isWindowActive) {
          doWorksOnWindowActive(vtkContext, windowId, activeTool);
        }
        isSelfCheckActiveWindow.current = false;
      }
    };

    return (
      <div
        className={classnames(
          "w-full h-full relative",
          { "border-2 border-background-800": !isWindowActive },
          { "border-2 border-warning-500": isWindowActive }
        )}
        onMouseEnter={handleContainerOnMouseEnter}
        onMouseLeave={handleContainerOnMouseLeave}
        onMouseMove={handleContainerOnMouseMove}
      >
        <div ref={ref} className="relative w-full h-full"></div>
        <div
          className="absolute flex flex-col gap-1 px-1 text-sm text-white top-1 left-1 opacity-60"
          style={{
            width: "45%",
          }}
        >
          <div className="flex items-center w-full gap-2 px-1 border rounded bg-background-900">
            <span>
              Slice: {currentSlice}/{maxSlice}
            </span>
            <input
              className="flex-1"
              type="range"
              min={minSlice}
              max={maxSlice}
              value={currentSlice}
              onKeyDown={(e) => {
                const key = e.key;
                if (key === "ArrowDown" || key === "ArrowUp") {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                handleSliceChanged(parseInt(e.target.value));
              }}
            />
          </div>
          <span>Spacing: {spacing.toFixed(2)} mm</span>
        </div>
        <div className="absolute transform translate-x-1/2 top-1 right-1/2">
          <span className="text-lg text-white">
            {axisToTopAxisNameMap[axis][0]}
          </span>
        </div>
        <div className="absolute transform -translate-y-1/2 right-1 top-1/2">
          <span className="text-lg text-white">
            {axisToTopAxisNameMap[axis][1]}
          </span>
        </div>
        <div className="absolute transform translate-x-1/2 bottom-1 right-1/2">
          <span className="text-lg text-white">
            {axisToTopAxisNameMap[axis][2]}
          </span>
        </div>
        <div className="absolute transform -translate-y-1/2 left-1 top-1/2">
          <span className="text-lg text-white">
            {axisToTopAxisNameMap[axis][3]}
          </span>
        </div>

        <ThreeDEditorWindowSelectComponent id={windowId} />
      </div>
    );
  }
);
