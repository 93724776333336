/*
 * File: invite-member.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { Backdrop, Grow } from "@material-ui/core";
import { useKeyPress } from "ahooks";
import { VBSpinner } from "components/common/vb-spinner/vb-spinner.component";
import { useRef, useState, FormEvent } from "react";
import { UserRole } from "store/auth/auth.state";
import { classnames } from "utilities/classes";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { MatModal } from "components/material/mat-modal.component";

interface InviteMemberDialogProps {
  visible: boolean;
  processing: boolean;
  onClose(): void;
  onSubmit?(email: string, roles: string): void;
}
export const InviteMemberDialog = ({
  visible,
  processing,
  onClose,
  onSubmit,
}: InviteMemberDialogProps) => {
  const { t } = useTranslation();
  const emailRef = useRef<HTMLInputElement>(null);
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const [roles, setRoles] = useState([
    { role: UserRole.LABELER, selected: true },
    { role: UserRole.CUSTOMER, selected: false },
  ]);

  function handleCancel(event: FormEvent) {
    event.preventDefault();
    onClose();
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (!emailRef.current) return;
    const email = emailRef.current.value as string;
    const role = roles.find((r) => r.selected)?.role || "";
    onSubmit && onSubmit(email, role);
  }

  function toggleSelectRole(role: { role: UserRole; selected: boolean }) {
    setRoles(
      roles.map((r) => {
        return {
          ...r,
          selected: r.role === role.role,
        };
      })
    );
  }

  useKeyPress(KeyboardKey.Enter, (e) => {
    e.preventDefault();
    submitBtnRef.current?.click();
  });

  return (
    <MatModal
      open={visible}
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={onClose}
      disableBackdropClick
      className="flex justify-center"
    >
      <Grow in={visible}>
        <div
          className={classnames(
            "relative inline-block w-full max-w-lg mt-32 overflow-hidden bg-white rounded shadow",
            { "pointer-events-none": processing }
          )}
          style={{ height: "max-content" }}
        >
          <div className="flex items-center flex-shrink-0 h-16 px-4 text-xl font-bold border-b">
            {t("member:inviteDialog.title")}
          </div>
          <form className="p-4" onSubmit={handleSubmit}>
            <div className="py-4">{t("member:inviteDialog.textInfo")}.</div>
            <div>{t("member:inviteDialog.textAddWithEmail")}</div>
            <input
              type="email"
              ref={emailRef}
              required
              autoFocus
              className="flex w-full h-12 px-4 mt-2 bg-gray-100 rounded"
              placeholder={t("member:inviteDialog.placeholderEmail")}
            />
            {/* <div className="py-2 text-sm text-gray-400">
                You can add up to 10 users, separate by comma
              </div> */}

            <div className="py-4">
              {t("member:inviteDialog.textUserWithRole")}
            </div>
            <div className="flex items-center">
              {roles.map((role) => {
                return (
                  <div
                    onClick={() => toggleSelectRole(role)}
                    key={role.role}
                    className={`${
                      role.selected
                        ? "text-primary border-primary"
                        : "text-gray-400 border-gray-400"
                    } px-3 py-1 border rounded mr-4 cursor-pointer`}
                  >
                    {role.role}
                  </div>
                );
              })}
            </div>

            <div className="flex justify-end gap-4 mt-4">
              <button
                className="px-4 py-3 rounded-lg focus:outline-none"
                onClick={handleCancel}
              >
                {t("common:buttonCancel")}
              </button>

              <button
                type="submit"
                className="flex justify-center px-4 py-3 text-white rounded-lg w-36 focus:outline-none bg-primary"
                ref={submitBtnRef}
              >
                {processing && <VBSpinner />}
                {!processing && t("member:inviteDialog.buttonSendInvitation")}
              </button>
            </div>
          </form>
        </div>
      </Grow>
    </MatModal>
  );
};
