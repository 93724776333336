import { IEvent } from "fabric/fabric-impl";
import { fabric } from "fabric";
import OpenSeadragon from "openseadragon";
import { FabricOverlay } from "./fabric-overlay";
import { FabricTool } from "./fabric-tool";
import {
  createPolygonFromPoints,
  updatePolygonControls,
} from "./fabric-object.utils";

export class FabricToolPolygon extends FabricTool {
  isDrawing: boolean;
  // dragging means hold mouse and move at the same time
  isDragging: boolean;
  draggingAddPointDistance: number = 0.00005;

  currentPolygon: fabric.Polygon | undefined;

  constructor(fabricOverlay: FabricOverlay, osdViewer: OpenSeadragon.Viewer) {
    super(fabricOverlay, osdViewer);

    this.isDrawing = false;
    this.isDragging = false;
  }

  handleMouseDown(fabricAbsX: number, fabricAbsY: number, e: IEvent) {
    const pos = new fabric.Point(fabricAbsX, fabricAbsY);

    if (!this.isDrawing) {
      this.isDrawing = true;
      // we add 2 points for drawing polygon while moving mouse effect
      this.addPoint(pos);
      this.addPoint(pos);
    } else {
      this.addPoint(pos, true);
    }

    if (this.isDrawing) {
      this.isDragging = true;
    }
  }

  handleMouseUp(fabricAbsX: number, fabricAbsY: number, e: IEvent) {
    if (!this.enabled) return;

    if (this.isDragging) {
      this.isDragging = false;
    }
    this.fabricCanvas.requestRenderAll();
  }

  handleMouseMove(fabricAbsX: number, fabricAbsY: number, e: IEvent) {
    const pos = new fabric.Point(fabricAbsX, fabricAbsY);
    if (this.isDragging) {
      if (
        this.currentPolygon &&
        this.currentPolygon.points &&
        this.currentPolygon.points.length > 0
      ) {
        const lastPoint =
          this.currentPolygon.points[this.currentPolygon.points.length - 1];
        // we only add new point while moving mouse when the distance
        // from the current cursor to the last point is greater thant or equal to a constant
        if (lastPoint.distanceFrom(pos) >= this.draggingAddPointDistance) {
          this.addPoint(pos);
        }
      }
    }

    if (this.isDrawing) {
      this.moveLastPointToPosition(pos);
    }
  }

  addPoint(pos: fabric.Point, ignoreDuplicateLastPoint = false) {
    if (this.currentPolygon && this.currentPolygon.points) {
      const points = this.currentPolygon.points;
      // not add the new point if need to check ignore last point
      if (points.length >= 2 && ignoreDuplicateLastPoint) {
        const lastPoint = points[points.length - 2];
        if (pos.x === lastPoint.x && pos.y === lastPoint.y) {
          this.endDrawing();
          return;
        }
      }
    }

    this.replaceCurrentPolygon(pos, false);

    this.fabricCanvas.requestRenderAll();
  }

  replaceCurrentPolygon(pos: fabric.Point, replaceLastPoint = false) {
    const points: fabric.Point[] = [];
    if (this.currentPolygon) {
      this.currentPolygon.points?.forEach((p) => points.push(p));
    }

    if (replaceLastPoint) {
      if (points.length > 0) {
        const lastPoint = points[points.length - 1];
        lastPoint.setX(pos.x);
        lastPoint.setY(pos.y);
      }
    } else {
      points.push(new fabric.Point(pos.x, pos.y));
    }

    if (this.currentPolygon) {
      this.fabricCanvas.remove(this.currentPolygon);
    }

    const polygon = createPolygonFromPoints({
      points,
      strokeWidth:
        this.fabricOverlay.objectStrokeWidth / this.fabricCanvas.getZoom(),
      updateControls: false,
      otherProps: { selectable: false },
      createUtilLabel: false,
    });
    this.updateObjectAdditionalData(polygon);

    this.currentPolygon = polygon;

    this.fabricCanvas.add(polygon);
  }

  moveLastPointToPosition(pos: fabric.Point) {
    if (
      !this.currentPolygon ||
      !this.currentPolygon.points ||
      this.currentPolygon.points?.length <= 1
    )
      return;

    this.replaceCurrentPolygon(pos, true);

    this.fabricCanvas.requestRenderAll();
  }

  endDrawing(removeLastPoint = true) {
    if (!this.currentPolygon) return;

    this.isDrawing = false;

    if (removeLastPoint) {
      this.currentPolygon.set({
        points: this.currentPolygon.points?.slice(0, -1),
      });
    }

    // const group = new fabric.Group(
    //   [this.currentPolygon],
    //   {
    //     type: "group",
    //     selectable: true,
    //     hoverCursor: "default",
    //   }
    // );
    // this.fabricCanvas.add(group);

    // TODO: support later
    // Update points coordinates when moving, resizing the polygon
    // this.currentPolygon.on("modified", function (e: any) {
    //   const polygon: fabric.Polygon = e.target as fabric.Polygon;
    //   if (!polygon.points) return;

    //   const matrix = polygon.calcTransformMatrix();
    //   const transformedPoints = polygon.points
    //     .map((p) => {
    //       return new fabric.Point(
    //         p.x - polygon.pathOffset.x,
    //         p.y - polygon.pathOffset.y
    //       );
    //     })
    //     .map(function (p) {
    //       return fabric.util.transformPoint(p, matrix);
    //     });
    //     polygon.set({points: transformedPoints});
    // });
    updatePolygonControls(this.currentPolygon);
    this.currentPolygon.bringToFront();
    this.addUtilLabel(this.currentPolygon);
    this.sendObjectAddedEvent(this.currentPolygon);

    this.currentPolygon = undefined;

    this.fabricCanvas.requestRenderAll();
  }

  cancelDrawing() {
    this.isDrawing = false;
    if (this.currentPolygon) {
      this.fabricOverlay.removeObject(this.currentPolygon);
    }
    this.currentPolygon = undefined;
  }
}
