/*
 * File: users-table-column.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd January 2022 8:14:20 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { UserAvatar } from "components/common/vb-user-avatar.component";
import { useCallback, useMemo } from "react";
import { classnames } from "utilities/classes";
import { UserFilter } from "../../context/workspace-users.state";
import { SortableColumnHeader } from "./sortable-column.component";
import { ColDef, RowData } from "./types";

export const useUsersTableColumn = (
  filter: UserFilter,
  onSortChanged?: (sort: string) => void
) => {
  const handleSelectColumn = useCallback(
    (field: keyof RowData) => {
      const getSortModeOnField = (field: string) => {
        if (!filter.sort.startsWith(field)) return "";
        const sortMode = filter.sort.replace(`${field},`, "");
        return sortMode;
      };
      const sortMode = getSortModeOnField(field);
      if (sortMode === "asc") {
        onSortChanged && onSortChanged(`${field},desc`);
      } else if (sortMode === "desc") {
        onSortChanged && onSortChanged(``);
      } else {
        onSortChanged && onSortChanged(`${field},asc`);
      }
    },
    [filter.sort, onSortChanged]
  );

  const columns = useMemo<ColDef<RowData>[]>(() => {
    const getSortModeOnField = (field: string) => {
      if (!filter.sort.startsWith(field)) return "";
      const sortMode = filter.sort.replace(`${field},`, "");
      return sortMode;
    };

    return [
      {
        field: "photoUrl",
        header: "Ava.",
        renderColumnHeader: (column: ColDef<RowData>, columnIndex: number) => {
          const isFirstColumn = columnIndex === 0;
          const isLastColumn = columnIndex === columns.length - 1;
          return (
            <th
              className={classnames(
                "pl-4 pr-2 whitespace-nowrap text-background-700 bg-background-200 h-10 text-center",
                { "rounded-l": isFirstColumn, "rounded-r": isLastColumn }
              )}
              key={column.field}
            >
              <SortableColumnHeader
                header={column.header}
                field={column.field}
                onSelect={handleSelectColumn}
              />
            </th>
          );
        },
        renderCell: (row: RowData) => {
          return (
            <div className="px-2" style={{ fontSize: "10px" }}>
              <UserAvatar name={row.name} className="flex-none w-6 h-6" />
            </div>
          );
        },
      },

      {
        field: "email",
        header: "Account mail",
        renderColumnHeader: (column: ColDef<RowData>, columnIndex: number) => {
          const isFirstColumn = columnIndex === 0;
          const isLastColumn = columnIndex === columns.length - 1;
          return (
            <th
              className={classnames(
                "px-2 whitespace-nowrap text-background-700 bg-background-200 h-10",
                { "rounded-l": isFirstColumn, "rounded-r": isLastColumn }
              )}
              key={column.field}
            >
              <SortableColumnHeader
                sortable
                sortMode={getSortModeOnField(column.field)}
                header={column.header}
                field={column.field}
                onSelect={handleSelectColumn}
              />
            </th>
          );
        },
        renderCell: (row: RowData) => {
          return <span className="text-sm">{row.email}</span>;
        },
      },

      {
        field: "name",
        header: "Name",
        renderColumnHeader: (column: ColDef<RowData>, columnIndex: number) => {
          const isFirstColumn = columnIndex === 0;
          const isLastColumn = columnIndex === columns.length - 1;
          return (
            <th
              className={classnames(
                "px-2 whitespace-nowrap text-background-700 bg-background-200 h-10",
                { "rounded-l": isFirstColumn, "rounded-r": isLastColumn }
              )}
              key={column.field}
            >
              <SortableColumnHeader
                sortable
                sortMode={getSortModeOnField(column.field)}
                header={column.header}
                field={column.field}
                onSelect={handleSelectColumn}
              />
            </th>
          );
        },
        renderCell: (row: RowData) => {
          return <span className="text-sm">{row.name}</span>;
        },
      },

      {
        field: "role",
        header: "Role",
        renderColumnHeader: (column: ColDef<RowData>, columnIndex: number) => {
          const isFirstColumn = columnIndex === 0;
          const isLastColumn = columnIndex === columns.length - 1;
          return (
            <th
              className={classnames(
                "px-2 whitespace-nowrap text-background-700 bg-background-200 h-10",
                { "rounded-l": isFirstColumn, "rounded-r": isLastColumn }
              )}
              key={column.field}
            >
              <SortableColumnHeader
                sortable
                sortMode={getSortModeOnField(column.field)}
                header={column.header}
                field={column.field}
                onSelect={handleSelectColumn}
              />
            </th>
          );
        },
        renderCell: (row: RowData) => {
          return <span className="text-sm capitalize">{row.role}</span>;
        },
      },

      {
        field: "numberOfBatches",
        header: "No. Batches",
        renderColumnHeader: (column: ColDef<RowData>, columnIndex: number) => {
          const isFirstColumn = columnIndex === 0;
          const isLastColumn = columnIndex === columns.length - 1;
          return (
            <th
              className={classnames(
                "px-2 whitespace-nowrap text-background-700 bg-background-200 h-10",
                { "rounded-l": isFirstColumn, "rounded-r": isLastColumn }
              )}
              key={column.field}
            >
              <SortableColumnHeader
                sortable
                sortMode={getSortModeOnField(column.field)}
                header={column.header}
                field={column.field}
                onSelect={handleSelectColumn}
              />
            </th>
          );
        },
        renderCell: (row: RowData) => {
          return <span className="text-sm">{row.numberOfBatches}</span>;
        },
      },

      {
        field: "totalTasksFinished",
        header: "Task finished",
        renderColumnHeader: (column: ColDef<RowData>, columnIndex: number) => {
          const isFirstColumn = columnIndex === 0;
          const isLastColumn = columnIndex === columns.length - 1;
          return (
            <th
              className={classnames(
                "px-2 whitespace-nowrap text-background-700 bg-background-200 h-10",
                { "rounded-l": isFirstColumn, "rounded-r": isLastColumn }
              )}
              key={column.field}
            >
              <SortableColumnHeader
                sortable
                sortMode={getSortModeOnField(column.field)}
                header={column.header}
                field={column.field}
                onSelect={handleSelectColumn}
              />
            </th>
          );
        },
        renderCell: (row: RowData) => {
          return <span className="text-sm">{row.totalTasksFinished}</span>;
        },
      },

      {
        field: "lastUpdate",
        header: "Last update",
        renderColumnHeader: (column: ColDef<RowData>, columnIndex: number) => {
          const isFirstColumn = columnIndex === 0;
          const isLastColumn = columnIndex === columns.length - 1;
          return (
            <th
              className={classnames(
                "px-2 whitespace-nowrap text-background-700 bg-background-200 h-10",
                { "rounded-l": isFirstColumn, "rounded-r": isLastColumn }
              )}
              key={column.field}
            >
              <SortableColumnHeader
                sortable
                sortMode={getSortModeOnField(column.field)}
                header={column.header}
                field={column.field}
                onSelect={handleSelectColumn}
              />
            </th>
          );
        },
        renderCell: (row: RowData) => {
          return (
            <span className="text-sm">
              {row.lastUpdate?.toDateString() || "-"}
            </span>
          );
        },
      },
      {
        field: "lastLogin",
        header: "Last login",
        renderColumnHeader: (column: ColDef<RowData>, columnIndex: number) => {
          const isFirstColumn = columnIndex === 0;
          const isLastColumn = columnIndex === columns.length - 1;
          return (
            <th
              className={classnames(
                "px-2 whitespace-nowrap text-background-700 bg-background-200 h-10",
                { "rounded-l": isFirstColumn, "rounded-r": isLastColumn }
              )}
              key={column.field}
            >
              <SortableColumnHeader
                sortable
                sortMode={getSortModeOnField(column.field)}
                header={column.header}
                field={column.field}
                onSelect={handleSelectColumn}
              />
            </th>
          );
        },
        renderCell: (row: RowData) => {
          return (
            <span className="text-sm">
              {row.lastLogin?.toDateString() || "-"}
            </span>
          );
        },
      },
      {
        field: "status",
        header: "Status",
        renderColumnHeader: (column: ColDef<RowData>, columnIndex: number) => {
          const isFirstColumn = columnIndex === 0;
          const isLastColumn = columnIndex === columns.length - 1;
          return (
            <th
              className={classnames(
                "px-2 whitespace-nowrap text-background-700 bg-background-200 h-10",
                { "rounded-l": isFirstColumn, "rounded-r": isLastColumn }
              )}
              key={column.field}
            >
              <SortableColumnHeader
                sortable
                sortMode={getSortModeOnField(column.field)}
                header={column.header}
                field={column.field}
                onSelect={handleSelectColumn}
              />
            </th>
          );
        },
        renderCell: (row: RowData) => {
          return <span className="text-sm capitalize">{row.status}</span>;
        },
      },
    ];
  }, [filter.sort, handleSelectColumn]);
  return { columns };
};
