/*
 * File: web-builder.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 17th February 2022 4:16:55 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useMount } from "ahooks";
import {
  IconClose,
  IconInformationCircle,
} from "components/common/vb-icon.component";
import { MLModel, MLModelVersion } from "domain/web-builder";
import { useAIModels } from "hooks/ai-model/use-ai-models.hooks";
import { useAppDispatch } from "hooks/use-redux";
import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { classnames } from "utilities/classes";
import { Logger } from "utilities/logger";
import { WebBuilderLoadingSkeleton } from "../components/loading-sekeleton/loading-skeleton.component";
import { useWebBuilder } from "../hooks/use-web-builder.hook";
import {
  THEME_OPTIONS,
  COLOR_OPTIONS,
  TEMPLATE_OPTIONS,
  MODEL_TYPE_OPTIONS,
} from "./web-builder.constant";
import { WebBuilderContext } from "./web-builder.context";
import * as Sentry from "@sentry/react";

interface LayoutProviderProps {
  children: JSX.Element;
}
export const WebBuilderProvider = ({ children }: LayoutProviderProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { modelId } = useParams<{ modelId: string }>();
  const [name, setName] = useState("Untitled Page");
  const [theme, setTheme] = useState(THEME_OPTIONS[0].value);
  const [color, setColor] = useState(COLOR_OPTIONS[0].value);
  const [template, setTemplate] = useState(TEMPLATE_OPTIONS[0].value);
  const [modelType, setModelType] = useState(MODEL_TYPE_OPTIONS[0].value);
  const [model, setModel] = useState<MLModel | null>(null);
  const { loaded, aiModels } = useAIModels();
  const [modelVersion, setModelVersion] = useState<MLModelVersion | null>(null);
  const {
    web,
    loading,
    metadata,
    setMetadata,
    saveMetadataAsync,
    publishWebAsync,
    depublishWebAsync,
  } = useWebBuilder(modelId);

  const hasNoModel = useMemo(() => {
    return !loading && loaded && aiModels.length === 0;
  }, [loading, aiModels, loaded]);

  function requireModelValidation() {
    if (hasNoModel) {
      throw new Error("There is no AI Model was published");
    }
  }

  async function onClickPreview() {
    try {
      requireModelValidation();
      const webMetadata = getWebMetadata();
      await saveMetadataAsync(webMetadata, modelVersion?.id);
      if (!model) return;
      const url = Routes.WEB_BUILDER_PREVIEW.replace(":modelId", modelId);
      window.open(url, "_blank");
    } catch (error: any) {
      Sentry.captureException(error);
      if (error && error.message) {
        const message = error.message;
        dispatch(
          enqueueErrorNotification(message, 2000, {
            horizontal: "right",
            vertical: "bottom",
          })
        );
      }
    }
  }

  async function onClickPublish() {
    try {
      requireModelValidation();
      const webMetadata = getWebMetadata();
      await publishWebAsync(webMetadata, modelVersion?.id);
    } catch (error: any) {
      Sentry.captureException(error);
      if (error && error.message) {
        const message = error.message;
        dispatch(
          enqueueErrorNotification(message, 2000, {
            horizontal: "right",
            vertical: "bottom",
          })
        );
      }
    }
  }

  async function onClickDeactive() {
    return depublishWebAsync();
  }

  async function onClickSave() {
    try {
      requireModelValidation();
      const webMetadata = getWebMetadata();
      await saveMetadataAsync(webMetadata, modelVersion?.id);
    } catch (error: any) {
      Sentry.captureException(error);
      if (error && error.message) {
        const message = error.message;
        dispatch(
          enqueueErrorNotification(message, 2000, {
            horizontal: "right",
            vertical: "bottom",
          })
        );
      }
      Logger.log(error);
    }
  }

  function getWebMetadata() {
    return {
      ...metadata,
      theme,
      modelType,
      template,
      modelId: model?.id.toString() || "",
      modelVersionId: modelVersion?.id.toString() || "",
      color: COLOR_OPTIONS.find((c) => c.value === color)?.label || "",
    };
  }

  function onTitleChange(title: string) {
    setMetadata({ ...metadata, title });
  }

  function onDescriptionChange(description: string) {
    setMetadata({ ...metadata, description });
  }

  function onTxtImportChange(text: string) {
    setMetadata({ ...metadata, txtImportData: text });
  }

  function onTxtRunModelChange(text: string) {
    setMetadata({ ...metadata, txtRunModel: text });
  }

  function onTxtCheckResultChange(text: string) {
    setMetadata({ ...metadata, textCheckResult: text });
  }

  function onUploadedPreviewImage(url: string) {
    setMetadata({ ...metadata, previewImageUrl: url });
  }

  function handleNavigateBack() {
    history.goBack();
  }

  const state = {
    loading,
    web,
    name,
    setName,

    themeOptions: THEME_OPTIONS,
    theme,
    setTheme,

    colorOptions: COLOR_OPTIONS,
    color,
    setColor,

    modelTypeOptions: MODEL_TYPE_OPTIONS,
    modelType,
    setModelType,

    model,
    modelVersion,
    setModel,
    setModelVersion,

    templateOptions: TEMPLATE_OPTIONS,
    template,
    setTemplate,

    templateMetadata: metadata,
    setTemplateMetadata: setMetadata,

    onTitleChange,
    onDescriptionChange,
    onTxtImportChange,
    onTxtRunModelChange,
    onTxtCheckResultChange,
    onClickPreview,
    onClickPublish,
    onClickDeactive,
    onUploadedPreviewImage,
    onClickSave,
  };

  useEffect(() => {
    if (web) {
      if (web.data.hasOwnProperty("modelType")) {
        setModelType(web.data["modelType"] || MODEL_TYPE_OPTIONS[0].value);
      }

      if (web.data.hasOwnProperty("theme")) {
        setTheme(web.data["theme"] || THEME_OPTIONS[0].value);
      }

      if (web.data.hasOwnProperty("color")) {
        const colorLabel = web.data["color"] || COLOR_OPTIONS[0].label;
        const savedColor = COLOR_OPTIONS.find((c) => c.label === colorLabel);
        if (savedColor) setColor(savedColor.value);
      }

      if (web.data.hasOwnProperty("template")) {
        setTemplate(web.data["template"] || TEMPLATE_OPTIONS[0].value);
      }

      if (
        web.data.hasOwnProperty("modelId") &&
        web.data.hasOwnProperty("modelVersionId")
      ) {
        const modelId = parseInt(web.data.modelId);
        const modelVersionId = parseInt(web.data.modelVersionId);
        const savedModel = aiModels.find((aiModel) => aiModel.id === modelId);
        let savedModelVersion;
        if (savedModel) {
          savedModelVersion = savedModel.versions.find(
            (version) => version.id === modelVersionId
          );
        }

        if (savedModel && savedModelVersion) {
          setModel(savedModel);
          setModelVersion(savedModelVersion);
        }
      }
    }
  }, [web, aiModels]);

  return (
    <WebBuilderContext.Provider value={state}>
      {!loading && children}
      {loading && <WebBuilderLoadingSkeleton />}
      {hasNoModel && <AIModelMissingMessage onClose={handleNavigateBack} />}
    </WebBuilderContext.Provider>
  );
};

interface AIModelMissingMessageProps {
  onClose?(): void;
}
const AIModelMissingMessage = ({ onClose }: AIModelMissingMessageProps) => {
  const [mounted, setMounted] = useState(false);
  useMount(() => setTimeout(() => setMounted(true), 500));
  return createPortal(
    <div
      className={classnames(
        "fixed p-4 bg-white rounded shadow-xl bottom-10 left-8 text-background-700 transform transition-all",
        {
          "opacity-100 translate-y-0": mounted,
          "translate-y-4 opacity-0 pointer-events-none": !mounted,
        }
      )}
      style={{ zIndex: 99999 }}
    >
      <div className="flex gap-4 py-2">
        <div className="p-0.5">
          <IconInformationCircle className="w-6 h-6 text-primary" />
        </div>
        <div className="flex-auto space-y-2">
          <div className="text-lg font-semibold">No model published!</div>
          <p className="max-w-xs">
            There is no mode was published. A model is require for running this
            web page.
          </p>
        </div>
        <button
          onClick={onClose}
          className="flex items-center justify-center flex-none w-6 h-6"
        >
          <IconClose className="flex-none w-5 h-5" />
        </button>
      </div>
    </div>,
    document.body
  );
};
