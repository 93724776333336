/*
 * File: text-area.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 6th December 2021 11:28:46 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Tooltip } from "@material-ui/core";
import { useKeyPress } from "ahooks";
import { HTMLProps, useRef, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";

interface Props extends HTMLProps<HTMLTextAreaElement> {
  header?: string;
  clearInput?: boolean;
  require?: boolean;
  containerClassName?: string;
  headerClassName?: string;
  hint?: string;
  disableSubmitOnBlur?: boolean;
  onInputChange?(value: string): void;
  onInputSubmit?(value: string): void;
}
export const VBTextAreaComponent = ({
  headerClassName = "",
  containerClassName = "",
  disableSubmitOnBlur,
  header,
  clearInput,
  className,
  require,
  onInputChange,
  onInputSubmit,
  maxLength,
  hint,
  defaultValue,
  ...rest
}: Props) => {
  const [value, setValue] = useState("");

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    if (!inputRef.current) return;
    setValue(inputRef.current.value);
  };

  const handleSubmit = () => {
    if (!inputRef.current) return;
    const value = inputRef.current.value.trim();
    onInputChange && onInputChange(value);
    onInputSubmit && onInputSubmit(value);
  };

  const handleBlur = () => {
    if (disableSubmitOnBlur) return;
    return handleSubmit();
  };

  useKeyPress(KeyboardKey.Enter, handleSubmit, { target: inputRef.current });

  useDeepCompareEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = defaultValue?.toString() ?? "";
      setValue(defaultValue?.toString() ?? "");
    }
  }, [defaultValue, inputRef]);

  return (
    <div className={`flex flex-col flex-shrink-0 gap-2 ${containerClassName}`}>
      {header && (
        <div className="flex items-center gap-2 text-sm font-semibold">
          <span className={headerClassName}>{header}</span>
          {require && (
            <Tooltip title="This field is required">
              <span className="text-red-500">*</span>
            </Tooltip>
          )}
          <span className="flex-auto"></span>
          {maxLength && (
            <span>
              {value.length}/{maxLength}
            </span>
          )}
        </div>
      )}
      <textarea
        onBlur={handleBlur}
        ref={inputRef}
        maxLength={maxLength}
        onInput={handleInput}
        className={`w-full px-4 py-2 bg-white border border-gray-300 rounded focus-within:border-primary outline-none ${className}`}
        defaultValue={defaultValue}
        {...rest}
      />
      {hint && <div className="text-sm text-error-500">{hint}</div>}
    </div>
  );
};
