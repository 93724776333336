import { IssueCommentRequestDTO, IssueCustomerRequestDTO, IssueLabelerRequestDTO, IssueReviewerRequestDTO, OpenIssueRequestDTO, ResolveIssueCustomerRequestDTO, UpdateIssueCustomerRequestDTO, UpdateIssueReviewerRequestDTO } from "../dtos/issue.dto";
import { LabelAPI } from "../http/label-service.httpclient";

export class IssueCustomerAPI {
  private path: string;
  constructor(path: string) {
    this.path = path;
  }

  async createIssue(payload: IssueCustomerRequestDTO) {
    return LabelAPI.post(`${this.path}`, payload);
  }

  async getIssues(taskId: number | string) {
    return LabelAPI.get(`${this.path}?taskId=${taskId}`);
  }

  async createIssueComment(payload: IssueCommentRequestDTO) {
    return LabelAPI.post(`${this.path}/comment`, payload);
  }

  async getIssueComments(issueId: number | string) {
    return LabelAPI.get(`${this.path}/comment?issueIds=${issueId}`)
  }

  async resolveIssue(payload: ResolveIssueCustomerRequestDTO) {
    return LabelAPI.post(`${this.path}/resolve`, payload);
  }

  async openIssue(payload: OpenIssueRequestDTO) {
    return LabelAPI.post(`${this.path}/open`, payload);
  }

  async patchIssue(issueId: number | string, payload: UpdateIssueCustomerRequestDTO) {
    return LabelAPI.patch(`${this.path}?issueId=${issueId}`, payload);
  }
}

export class IssueLabelerAPI {
  private path: string;
  constructor(path: string) {
    this.path = path;
  }

  async createIssue(payload: IssueLabelerRequestDTO) {
    return LabelAPI.post(`${this.path}`, payload);
  }

  async getIssues(jobId: number | string) {
    return LabelAPI.get(`${this.path}?jobId=${jobId}`);
  }

  async createIssueComment(payload: IssueCommentRequestDTO) {
    return LabelAPI.post(`${this.path}/comment`, payload);
  }

  async getIssueComments(issueId: number | string) {
    return LabelAPI.get(`${this.path}/comment?issueIds=${issueId}`)
  }

  async openIssue(payload: OpenIssueRequestDTO) {
    return LabelAPI.post(`${this.path}/open`, payload);
  }
}

export class IssueLabelerReviewAPI {
  private path: string;
  constructor(path: string) {
    this.path = path;
  }

  async createIssue(payload: IssueReviewerRequestDTO) {
    return LabelAPI.post(`${this.path}`, payload);
  }

  async getIssues(taskId: number | string) {
    return LabelAPI.get(`${this.path}?taskId=${taskId}`);
  }

  async patchIssue(issueId: number | string, payload: UpdateIssueReviewerRequestDTO) {
    return LabelAPI.patch(`${this.path}?issueId=${issueId}`, payload);
  }
}