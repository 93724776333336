/*
 * File: tool-group.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Popover } from "@material-ui/core";
import { IconPenTool, IconSize } from "components/common/vb-icon.component";
import { useState, MouseEvent, useRef } from "react";
import { classnames } from "utilities/classes";
import { CornerstoneTool, SubType, ToolName } from "./dicom-tools.model";
import { ToolButton, ToolButtonProps } from "./tool-button.component";
import { CornerstoneToolIcons } from "./tool-icon.component";
import { ReactComponent as FreehandRoiIcon } from "assets/cornerstone/icons/pen.svg";

interface Props {
  tools: CornerstoneTool[];
  name: string;
  activeTool?: ToolName;
  onSelect?(tool: CornerstoneTool): void;
  onSelectSubType?(tool: CornerstoneTool, type: SubType): void;
  onSelectViews?(views: number): void;
}
export const ToolGroup = ({
  name,
  tools,
  activeTool,
  onSelect,
  onSelectSubType,
  onSelectViews,
}: Props) => {
  const handleSelectTool = (tool: CornerstoneTool) => {
    onSelect && onSelect(tool);
  };

  const handleSelectSubType = (tool: CornerstoneTool, type: SubType) => {
    onSelectSubType && onSelectSubType(tool, type);
  };

  if (tools.length === 0) return <></>;

  return (
    <div>
      <div className="px-2 py-4 text-xs text-center">{name}</div>
      <div className="grid grid-cols-2 gap-1.5 px-1.5 mb-4">
        {tools.map((tool) => {
          const isAnnotateTool =
            tool.name === ToolName.FreehandRoiExtend ||
            tool.name === ToolName.RectangleRoiExtend;

          const isGridTool = tool.name === ToolName.Grid;

          if (isGridTool) {
            return (
              <CommonToolButton
                tool={tool}
                key={tool.name}
                icon={CornerstoneToolIcons[tool.type]}
                toolTip={tool.name}
                disabled={tool.disabled}
                active={tool.active || tool.name === activeTool}
                onClick={() => {}}
                onSelectViews={onSelectViews}
              />
            );
          }

          if (isAnnotateTool) {
            return (
              <AnnotationToolButton
                tool={tool}
                onSelectSubType={handleSelectSubType}
                key={tool.name}
                icon={CornerstoneToolIcons[tool.type]}
                toolTip={tool.name}
                disabled={tool.disabled}
                active={tool.active || tool.name === activeTool}
                onClick={() => handleSelectTool(tool)}
              />
            );
          }
          return (
            <ToolButton
              key={tool.name}
              icon={CornerstoneToolIcons[tool.type]}
              toolTip={tool.name}
              disabled={tool.disabled}
              active={tool.active || tool.name === activeTool}
              onClick={() => handleSelectTool(tool)}
            />
          );
        })}
      </div>
    </div>
  );
};

interface CommonToolButtonProps extends ToolButtonProps {
  tool: CornerstoneTool;
  onSelectViews?(numberOfViews: number): void;
}
const CommonToolButton = ({
  tool,
  onSelectViews,
  ...rest
}: CommonToolButtonProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  function selectViews(numberOfViews: number) {
    onSelectViews && onSelectViews(numberOfViews);
    handlePopoverClose();
  }

  const open = Boolean(anchorEl);

  return (
    <div
      ref={containerRef}
      className="relative"
      onClick={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <ToolButton {...rest} />

      {!tool.disabled && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={containerRef.current}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          onClose={handlePopoverClose}
        >
          <GridToolPopover
            onClose={handlePopoverClose}
            onSelectViews={selectViews}
          />
        </Popover>
      )}
    </div>
  );
};

interface GridToolPopoverProps {
  onClose(): void;
  onSelectViews(numberOfViews: number): void;
}
function GridToolPopover({ onClose, onSelectViews }: GridToolPopoverProps) {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  return (
    <div
      className="grid w-20 grid-cols-3 gap-1 p-2 text-white rounded bg-blueGray-700"
      onMouseLeave={onClose}
    >
      {[...Array.from({ length: 9 }, (_, i) => i)].map((val) => {
        return (
          <div
            className={classnames(
              "w-full aspect-w-4 aspect-h-4 cursor-pointer",
              {
                "bg-warning-500": val <= selectedIndex,
                "bg-coolGray-800": val > selectedIndex,
              }
            )}
            key={val.toString()}
            onMouseEnter={() => setSelectedIndex(val)}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onSelectViews(val + 1);
            }}
          />
        );
      })}
    </div>
  );
}

interface AnnotationToolButtonProps extends ToolButtonProps {
  tool: CornerstoneTool;
  onSelectSubType(tool: CornerstoneTool, subType: SubType): void;
}
const AnnotationToolButton = ({
  tool,
  onSelectSubType,
  ...rest
}: AnnotationToolButtonProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleSelectSubType = (type: SubType) => {
    onSelectSubType && onSelectSubType(tool, type);
    handlePopoverClose();
  };

  const open = Boolean(anchorEl);

  return (
    <div
      ref={containerRef}
      className="relative"
      onContextMenu={handlePopoverOpen}
    >
      <ToolButton {...rest} />

      {!tool.disabled && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={containerRef.current}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div
            className="flex flex-col gap-1 py-2 text-white rounded bg-blueGray-700"
            style={{ minWidth: "6.75rem" }}
            onMouseLeave={handlePopoverClose}
          >
            <button
              className={classnames("flex items-center gap-1 px-3 h-9", {
                "text-warning-500": SubType.DEFAULT === tool.subType,
              })}
              onClick={() => handleSelectSubType(SubType.DEFAULT)}
            >
              <FreehandRoiIcon /> <span>Default</span>
            </button>

            <button
              className={classnames("flex items-center gap-1 px-3 h-9", {
                "text-warning-500": SubType.SHAPE === tool.subType,
              })}
              onClick={() => handleSelectSubType(SubType.SHAPE)}
            >
              <IconSize className="w-4 h-4" /> <span>Shape</span>
            </button>
            <button
              className={classnames("flex items-center gap-1 px-3 h-9", {
                "text-warning-500": SubType.TRACK === tool.subType,
              })}
              onClick={() => handleSelectSubType(SubType.TRACK)}
            >
              <IconPenTool className="w-4 h-4" /> <span>Track</span>
            </button>
          </div>
        </Popover>
      )}
      {tool.subType === SubType.TRACK && (
        <div className="absolute bottom-0 flex items-center justify-center w-5 h-5 right-0.5 pointer-events-none">
          <IconPenTool className="w-3 h-3" />
        </div>
      )}
    </div>
  );
};
