/*
 * File: file-page-header.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 5:40:26 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { EntityAction } from "domain/common/entity-action";
import {
  VscNote,
  VscTrash,
} from "react-icons/vsc";
import { formatNumber } from "utilities/formatter/number-formatter.utils";
import ButtonSort from "./button-sort";
import ButtonViewMode from "./button-view-mode";
import { ToolbarActions } from "./toobar-actions";

export interface Props {
  totalFile: number;
  totalSelected?: number;
  onSelect(action: string): void;
}
export function FilePageHeader(props: Props) {
  const { totalFile, totalSelected, onSelect } = props;
  return (
    <div className="relative flex items-center flex-none gap-4 px-4 py-2 bg-white shadow">
      <h2 className="text-lg font-semibold text-primary">
        {formatNumber(totalFile)} results found
      </h2>
      <span className="flex-auto" />

      {!!totalSelected && totalSelected > 0 && (
        <div className="flex items-center gap-2">
          <strong>{formatNumber(totalSelected)}</strong>
          <span>files selected</span>
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onSelect("manage_metadata");
            }}
            className="button-secondary"
          >
            <VscNote size={16} />
            <span>Add metadata</span>
          </button>
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onSelect(EntityAction.DELETE);
            }}
            className="button-secondary"
          >
            <VscTrash size={16} />
            <span>Delete</span>
          </button>
        </div>
      )}

      <ButtonViewMode />
      <ButtonSort />

      {!!totalSelected && totalSelected > 0 && <ToolbarActions {...props} />}
    </div>
  );
}

export default FilePageHeader;
