import { IconCheckedCircle } from "components/common/vb-icon.component";
import { useAppPrevious } from "hooks/use-app-previous";
import { useCallback, useEffect, useState } from "react";
import { StorageService } from "services/storage";
import { StorageResource } from "services/storage/dto/resource.dto";
import { classnames } from "utilities/classes";
import { TextToSpeechAudioPlayer } from "./text-to-speech-audio-player.component";
import axios from "axios";
import { BatchObservationDTO } from "services/label-service/dtos";
import { TtsAnnotationItem } from "services/label-service/dtos/task-observation-v3.dto";
import {
  AnnotationAttributeItem,
  AnnotationResponseDTO,
} from "services/label-service/dtos/annotations.dto";

export interface TextToSpeechAnnotationRowModel {
  annotation: AnnotationResponseDTO;
  labeler: string;
  audioUrl: string;
  blob: any;
  note: string;
  reviewResutl: string;
  attributes?: AnnotationAttributeItem[];
}

interface Props {
  currentJobId?: number;
  annotations: AnnotationResponseDTO[];
  onSelected?: (
    audioUrl: string | undefined,
    recordedBlob: any | undefined,
    note: string,
    attributes?: AnnotationAttributeItem[]
  ) => void;
  acceptBatchObservation?: BatchObservationDTO;
  rejectBatchObservation?: BatchObservationDTO;
  workspaceId?: string;
}

export const TextToSpeechAnnotations = ({
  currentJobId,
  annotations,
  onSelected,
  acceptBatchObservation,
  rejectBatchObservation,
  workspaceId,
}: Props) => {
  const [selectedAnnotation, setSelectedAnnotation] =
    useState<AnnotationResponseDTO>();
  const [rows, setRows] = useState<TextToSpeechAnnotationRowModel[]>([]);
  const previousJobId = useAppPrevious(currentJobId);

  const updateRows = useCallback(async () => {
    const newRows: TextToSpeechAnnotationRowModel[] = [];
    let index = 0;
    for (let annotation of annotations) {
      index++;
      const annoItem = annotation.annotation as TtsAnnotationItem;
      let audioUrl = "";
      let blob = undefined;
      if (annoItem.audioResourceId) {
        let res = await StorageService.getStorageResource(
          annoItem.audioResourceId
        );
        const resource = res.data as StorageResource;
        const urlToUse = resource.path ? resource.path : resource.downloadUrl;
        const url = await StorageService.getUrlWithWorkspaceSasToken(
          urlToUse,
          workspaceId
        );
        res = await axios.get(url, { responseType: "blob" });
        blob = res.data;
        audioUrl = URL.createObjectURL(blob);
      }

      let reviewObs;
      if (
        annoItem.observationId &&
        annoItem.observationId === acceptBatchObservation?.observation.id
      ) {
        reviewObs = acceptBatchObservation.observation;
      }
      if (
        annoItem.observationId &&
        annoItem.observationId === rejectBatchObservation?.observation.id
      ) {
        reviewObs = rejectBatchObservation.observation;
      }

      let displayLabeler = `Labler ${index}: ${
        annotation.assignee || "example@gmail.com"
      }`;
      if (reviewObs) {
        displayLabeler = `${displayLabeler} (${reviewObs.name})`;
      }

      newRows.push({
        annotation,
        audioUrl: `${audioUrl}`,
        blob,
        note: annoItem.note || "",
        labeler: displayLabeler,
        reviewResutl: reviewObs ? reviewObs.name : "",
        attributes: annotation.annotation.attributes,
      });
    }
    setRows(newRows);
  }, [
    annotations,
    acceptBatchObservation,
    rejectBatchObservation,
    workspaceId,
  ]);

  useEffect(() => {
    if (previousJobId && previousJobId === currentJobId) return;
    if (currentJobId) {
      updateRows();
    }
  }, [updateRows, previousJobId, currentJobId]);

  useEffect(() => {
    if (!annotations) return;
    updateRows();
  }, [updateRows, annotations]);

  const handleAnnotationClicked = (row: TextToSpeechAnnotationRowModel) => {
    if (!row.annotation) return;
    if (
      !selectedAnnotation ||
      selectedAnnotation.jobId !== row.annotation.jobId
    ) {
      setSelectedAnnotation(row.annotation);
      onSelected &&
        onSelected(row.audioUrl, row.blob, row.note, row.attributes);
    } else {
      setSelectedAnnotation(undefined);
      onSelected && onSelected(undefined, undefined, "");
    }
  };

  return (
    <div className="flex flex-col w-full gap-4 p-4 text-white border rounded bg-background-800 border-background-700">
      <p className="text-lg">Labeling Result (click to choose)</p>
      <div className="flex flex-wrap gap-4">
        {rows.map((row) => {
          // It is safe to use jobId here since 1 job only have 1 task observation
          const isSelected =
            selectedAnnotation &&
            row.annotation.jobId === selectedAnnotation.jobId;

          return (
            <div
              key={row.labeler}
              className={classnames(
                "p-4 rounded border border-background-700",
                {
                  "bg-background-900": !!!isSelected,
                },
                {
                  "bg-warning-500": !!isSelected,
                }
              )}
            >
              <div className="flex items-center justify-between w-full gap-4 mb-2">
                <span>{row.labeler}</span>
                {!row.reviewResutl && (
                  <div
                    className="cursor-pointer"
                    onClick={(_) => handleAnnotationClicked(row)}
                  >
                    {isSelected && (
                      <IconCheckedCircle
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    )}
                    {!isSelected && (
                      <div
                        className="border-2 rounded-full border-warning-500"
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      ></div>
                    )}
                  </div>
                )}
              </div>
              {!row.reviewResutl && (
                <div className="z-50">
                  <TextToSpeechAudioPlayer
                    url={row.audioUrl}
                    active={isSelected}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
