/*
 * File: mammography-review-panel.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 1:36:44 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { IconCloseCircle } from "components/common/vb-icon.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  selectMammographyLabelingTaskAnnotators,
  selectMammographyLabelingTaskSelectedAnnotators,
} from "store/labeler/mammography-labeling-task/mammography-labeling-task.selectors";
import { mammographyAnnotatorSelectedAsync } from "store/labeler/mammography-labeling-task/mammography-labeling-task.thunk";
import { useMammographyTaskContext } from "../mammography-task-context/mammography-task.context";
import { MammographyTaskLabels } from "./mammography-task-labels.component";
export const MammographyReviewPanel = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { onClose } = useMammographyTaskContext();
  const annotators = useAppSelector(selectMammographyLabelingTaskAnnotators);
  const selectedAnnotators = useAppSelector(
    selectMammographyLabelingTaskSelectedAnnotators
  );
  function handleSelectAnnotators(val: any) {
    const annos = val?.map((item: any) => item.value);
    dispatch(mammographyAnnotatorSelectedAsync(annos));
  }
  const options = useMemo(() => {
    return annotators.map((anno) => ({ label: anno, value: anno }));
  }, [annotators]);
  return (
    <div className="relative flex flex-row flex-shrink-0 h-full overflow-x-hidden overflow-y-auto border-l border-blueGray-600 bg-background-800 dark-scrollbar">
      <div className="flex flex-col flex-1 h-full" style={{ width: "20rem" }}>
        <div className="flex-shrink-0">
          <div className="flex items-center justify-between gap-2 px-4">
            <span className="py-4 text-xs font-bold text-gray-400">
              {t("labelerworkspace:panel.textActions")}
            </span>
            <button onClick={onClose} className="flex-none">
              <IconCloseCircle className="flex-none w-6 h-6" />
            </button>
          </div>
        </div>

        <div className="border-b border-background-700"></div>
        <div className="p-4">
          <div className="py-2">Show labeling work</div>
          <VBSelectComponent
            onChange={handleSelectAnnotators}
            value={options.filter((option) =>
              selectedAnnotators.includes(option.value)
            )}
            options={options}
            isMulti
            isClearable
            closeMenuOnSelect={false}
            size="none"
            menuPortalTarget={document.body}
          />
        </div>
        <div className="border-b border-background-700"></div>
        <MammographyTaskLabels />
      </div>
    </div>
  );
};
