/*
 * File: work-management.api.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 18th August 2021 5:30:14 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BatchDTO } from "../dtos";
import { BatchManagementDTO } from "../dtos/batch-management.dto";
import { BaseAPI } from "./base.api";

export enum DatasourceFileAction {
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export class BatchManagementAPI extends BaseAPI<BatchManagementDTO> {
  moveFilesScope(batch: BatchDTO, fileIds: number[], targetScopeId: number) {
    return this.patch(`${this.getPath()}/${batch.id}`, {
      batch: batch,
      datasetCriteria: {
        action: DatasourceFileAction.UPDATE,
        mediaIds: fileIds,
        scopeId: targetScopeId,
      },
    });
  }

  deleteFiles(batch: BatchDTO, fileIds: number[]) {
    return this.patch(`${this.getPath()}/${batch.id}`, {
      batch: batch,
      datasetCriteria: {
        action: DatasourceFileAction.DELETE,
        mediaIds: fileIds,
      },
    });
  }
}
