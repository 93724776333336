import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useAppDispatch } from "hooks/use-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TaskCustomerReviewStatus } from "services/label-service/dtos";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { patchTask } from "store/customer/batch/batch.thunk";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { TaskRow, useBatchTaskContext } from "../batch-tasks.context";
import * as Sentry from "@sentry/react";

export interface TasksChangeStatusDialogPros {
  open: boolean;
  taskRow: TaskRow | undefined;
  onClose?: () => void;
  onSubbmit?: (newValue: string) => void;
}

export const TasksChangeStatusDialog = ({
  open,
  taskRow,
  onClose,
  onSubbmit,
}: TasksChangeStatusDialogPros) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedStatus, setSelectedStatus] = useState(
    TaskCustomerReviewStatus.APPROVED.toString()
  );
  const { setChangeStatusRow } = useBatchTaskContext();
  const [processing, setProcessing] = useState(false);
  const options = [
    {
      label: "Approved",
      value: TaskCustomerReviewStatus.APPROVED,
    },
    {
      label: "Rejected",
      value: TaskCustomerReviewStatus.REJECTED,
    },
  ];

  const handleClose = () => {
    setChangeStatusRow(undefined);
  };

  const handleSubmit = async () => {
    if (!taskRow) return;
    try {
      setProcessing(true);
      const response = await dispatch(
        patchTask({
          taskId: taskRow.id,
          payload: {
            customerReviewStatus: selectedStatus,
          },
        })
      );
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      setChangeStatusRow(undefined);
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setProcessing(false);
    }
  };

  return (
    <VBModal
      title={"Change status"}
      open={open}
      onClose={handleClose}
      textSubmit={"Change"}
      disableSubmit={processing}
      onSubmit={handleSubmit}
    >
      <VBSelectComponent
        header={"New status"}
        size="xl"
        containerClassName=""
        value={options.find((o) => o.value === selectedStatus)}
        options={options}
        onChange={(option: any) => setSelectedStatus(option.value as string)}
        menuPortalTarget={document.body}
      />
    </VBModal>
  );
};
