/*
 * File: load-text-issues.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 6th July 2022 3:48:36 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { TextWorkspaceState } from "../../text-workspace.state";
import { textIssueUtils } from "../../utils/text-issue.utils";
import {
  issueCustomerService,
  issueLabelerReviewService,
  issueLabelerService,
} from "services/label-service";
import { RootState } from "store";
import {
  selectTextLabelingJob,
  selectTextLabelingJobId,
  selectTextLabelingMode,
  selectTextLabelingTask,
} from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { TextLabelingMode } from "../../text-labeling/text-labeling.state";
import * as Sentry from "@sentry/react";

const THUNK_NAME = "textLabeling/loadTextIssuesAsync";

export const loadTextIssuesAsync = createAsyncThunk(
  THUNK_NAME,
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const mode = selectTextLabelingMode(state);
      if (mode === TextLabelingMode.CUSTOMER) {
        const task = selectTextLabelingTask(state);
        if (task) {
          const response = await issueCustomerService.getIssues(task.id);
          return response.data.map(textIssueUtils.fromDTO);
        }
      } else if (mode === TextLabelingMode.LABELER) {
        const jobId = selectTextLabelingJobId(state);
        if (jobId) {
          const response = await issueLabelerService.getIssues(jobId);
          return response.data.map(textIssueUtils.fromDTO);
        }
      } else if (mode === TextLabelingMode.ACCEPTANCE) {
        const job = selectTextLabelingJob(state);
        if (job) {
          const response = await issueLabelerReviewService.getIssues(
            job.taskId
          );
          return response.data.map(textIssueUtils.fromDTO);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
    return [];
  }
);

export function loadTextIssueBuilder(
  builder: ActionReducerMapBuilder<TextWorkspaceState>
) {
  return builder
    .addCase(loadTextIssuesAsync.pending, (_state, _action) => {})
    .addCase(loadTextIssuesAsync.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.textIssues.issues = action.payload;
    })
    .addCase(loadTextIssuesAsync.rejected, (_state, _action) => {});
}
