import { AnnotationPair, Label } from "domain/image-labeling";
import { useAppDispatch } from "hooks/use-redux";
import { useMemo } from "react";
import { imageAnnotationUpdated } from "store/labeler/image-workspace/image-workspace.slice";
import { truncateEmail } from "utilities/string/truncate-email";
import { ITreeItem, TreeItem } from "./tree-item";

interface Props {
  pair: AnnotationPair;
  label: Label;
}
export function IoUPairItem({ pair, label }: Props) {
  const dispatch = useAppDispatch();
  const treeItem = useMemo(() => {
    return {
      id: pair.id,
      text: `${label.name} (iou: ${pair.iouScore.toFixed(2)})`,
      uuid: "",
      depth: 0,
      checked: label.selected,
      hidden: label.hidden,
      children: pair.annotations.map((anno) => {
        return {
          id: anno.id.toString(),
          uuid: anno.uuid,
          text: truncateEmail(anno.annotator),
          depth: 1,
          children: [],
          checked: anno.checked,
          hidden: !anno.visible,
        };
      }),
    };
  }, [pair, label]);

  function handleSelectItem(item: ITreeItem, action?: string) {
    if (!item.uuid) return;
    if (action === "TOGGLE_ITEM_VISIBILITY") {
      const payload = { uuid: item.uuid, visible: !!item.hidden };
      dispatch(imageAnnotationUpdated(payload));
    } else if (action === "TOGGLE_ITEM_CHECKED") {
      const payload = { uuid: item.uuid, checked: !item.checked };
      dispatch(imageAnnotationUpdated(payload));
    }
  }

  return (
    <li key={pair.id} className="w-full">
      <TreeItem item={treeItem} onSelect={handleSelectItem} />
    </li>
  );
}
