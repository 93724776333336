/*
 * File: project-info.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useState, useRef, FormEvent } from "react";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { classnames } from "utilities/classes";
import { Logger } from "utilities/logger";
import { getUserName } from "utilities/user/user-cache";
import { ProjectDTO } from "services/label-service/dtos";
import { updateProjectAsync } from "store/customer/projects/projects.slice";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { selectProjectSubTypeByCode } from "store/common/app-setting/app-setting.selectors";
import * as Sentry from "@sentry/react";

interface ProjectInfoProps {
  project: ProjectDTO;
  onUpdated?(data?: any): void;
}
export const ProjectInfo = ({ project, onUpdated }: ProjectInfoProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [hasChanges, setHasChanges] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const projectType = useAppSelector(selectProjectSubTypeByCode(project.type));
  function handleChanged() {
    const name = nameRef.current?.value.trim() || "";
    if (name.length === 0) return setHasChanges(false);
    const description = descriptionRef.current?.value.trim() || "";
    const originDescription = project?.description || "";
    setHasChanges(name !== project?.name || description !== originDescription);
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    if (submitting) return;
    setSubmitting(true);
    event.preventDefault();
    const name = nameRef.current?.value.trim() || "";
    const description = descriptionRef.current?.value.trim() || "";
    saveChanged(name, description);
  }

  async function saveChanged(name: string, description: string) {
    if (!project) return;
    try {
      const updatedProject = {
        id: project.id,
        name: name,
        description: description,
      };
      const response = await dispatch(updateProjectAsync(updatedProject));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textUpdatedSuccess")));
      setHasChanges(false);
      onUpdated && onUpdated(response.payload);
    } catch (err: any) {
      Sentry.captureException(err);
      const errMessage = err.message || t("common:textUpdatedFailed");
      dispatch(enqueueErrorNotification(errMessage));
      Logger.log(err);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="my-4">
      <form onSubmit={handleSubmit}>
        <div className="px-4 py-2">
          <label className="block mb-1 text-sm text-gray-400" htmlFor="name">
            {t("project:info.labelName")}
          </label>
          <input
            className="w-full px-4 py-2 border rounded focus:border-primary focus:outline-none"
            name="name"
            id="name"
            type="text"
            ref={nameRef}
            onChange={handleChanged}
            defaultValue={project.name}
          />
        </div>

        <div className="px-4 py-2">
          <label
            className="block mb-1 text-sm text-gray-400"
            htmlFor="description"
          >
            {t("project:info.labelDescription")}
          </label>
          <textarea
            className="w-full px-4 py-2 border rounded focus:border-primary focus:outline-none"
            name="description"
            id="description"
            placeholder={t("project:info.placeholderAddDescription")}
            rows={3}
            ref={descriptionRef}
            onChange={handleChanged}
            defaultValue={project.description || ""}
          />
        </div>

        <div className="px-4 py-2">
          <div className="block mb-1 text-sm text-gray-400">
            {t("project:info.textType")}
          </div>
          <div>{projectType?.name || ""}</div>
        </div>

        <div className="px-4 py-2">
          <div className="block mb-1 text-sm text-gray-400">
            {t("project:info.textCreatdBy")}
          </div>
          <div>{getUserName(project.createdBy)}</div>
        </div>

        <div className="px-4 py-2">
          <div className="block mb-1 text-sm text-gray-400">
            {t("project:info.textCreatedDate")}
          </div>
          <div>
            {project.createdDate
              ? t("common:formattedDate", {
                  date: new Date(project.createdDate),
                })
              : ""}
          </div>
        </div>

        <div className="px-4 py-2">
          <div className="block mb-1 text-sm text-gray-400">
            {t("project:info.textLastModifiedBy")}
          </div>
          <div>{getUserName(project.lastModifiedBy || "")}</div>
        </div>

        <div className="px-4 py-2">
          <div className="block mb-1 text-sm text-gray-400">
            {t("project:info.textLastModifiedDate")}
          </div>
          <div>
            {project.lastModifiedDate
              ? t("common:formattedDate", {
                  date: new Date(project.lastModifiedDate),
                })
              : ""}
          </div>
        </div>

        <div className="px-4 py-2 mt-4">
          <button
            disabled={!hasChanges || submitting}
            type="submit"
            className={classnames(
              "bg-primary text-white px-4 py-2 rounded focus:outline-none disabled:opacity-50",
              {
                "opacity-20 cursor-not-allowed": !hasChanges,
              }
            )}
          >
            {t("common:buttonSaveChanges")}
          </button>
        </div>
      </form>
    </div>
  );
};
