/*
 * File: image-job-info.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 2nd November 2021 5:49:41 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { classnames } from "utilities/classes";

interface Props {
  projectId: number;
  batchId: number;
  taskId: number;
  showTaskInfo?: boolean;
  showDicomdata?: boolean;
  dicomTagsData?: string[];
  dicomTagsDataClasses?: string;
}
export const ImageJobInfo = ({
  projectId,
  batchId,
  taskId,
  showTaskInfo = false,
  showDicomdata = true,
  dicomTagsData = [],
  dicomTagsDataClasses = ""
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="absolute z-10 pointer-events-none select-none left-4 top-4">
      {
        showTaskInfo &&
        <Fragment>
          <div className="text-sm">
            {t("labelerworkspace:overlay.projectId", {
              projectId: projectId,
            })}
          </div>
          <div className="text-sm">
            {t("labelerworkspace:overlay.batchId", {
              batchId: batchId,
            })}
          </div>
          <div className="text-sm">
            {t("labelerworkspace:overlay.taskId", {
              taskId: taskId,
            })}
          </div>
        </Fragment>
      }
      {
        (showDicomdata && dicomTagsData)  &&
        dicomTagsData.map(rowData => {
          return (
            <div key={rowData} className={classnames(dicomTagsDataClasses)}>
              {rowData}
            </div>
          )
        })
      }
    </div>
  );
};
