import vtkImageData from "@kitware/vtk.js/Common/DataModel/ImageData";
import vtkPiecewiseFunction from "@kitware/vtk.js/Common/DataModel/PiecewiseFunction";
import vtkColorTransferFunction from "@kitware/vtk.js/Rendering/Core/ColorTransferFunction";
import vtkImageMapper from "@kitware/vtk.js/Rendering/Core/ImageMapper";
import vtkImageSlice from "@kitware/vtk.js/Rendering/Core/ImageSlice";
import vtkVolume from "@kitware/vtk.js/Rendering/Core/Volume";
import vtkVolumeMapper from "@kitware/vtk.js/Rendering/Core/VolumeMapper";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import * as Sentry from "@sentry/react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { itkHelper } from "../itk_import";
import { downloadBlob, hexToRgb, itkImageToArrayBuffer } from "../utils";
import {
  SlicingMode,
  ViewTypes,
  vtkColorMaps,
  vtkPaintFilterCustom,
  vtkPaintWidgetCustom,
  vtkPiecewiseGaussianWidget,
  vtkRectangleWidget,
  vtkResliceCursorWidget,
  vtkSplineWidgetCustom,
  vtkWidgetManager,
  xyzToViewType,
} from "../vtk_import";
import {
  DEFAULT_CL,
  DEFAULT_WL,
  EditorLabel,
  EditorLabelOption,
  EditorLayout,
  EditorEventSwapWindow,
  EditorTool,
  EditorWindowData,
  EDITOR_DEFAULT_LAYOUT_ORDERS,
  EDITOR_LAYOUT_4,
  EDITOR_TOOLS,
  LabelMapOption,
  ThreeDEditorEvents,
  EditorMetadataRowModel,
  PREVIEW_MASK,
  EditorMeasurement,
  EditorEventSaveMeasurements,
  EditorMeasurementData,
  EDITOR_LAYOUT_4_1,
} from "./three-d-editor.models";
import { ThreeDEditorNav } from "./three-d-editor-nav.component";
import { WindowSlicer } from "./window-slicer.component";
import { WindowVolume } from "./window-volume.component";
import {
  addToVTKObjects,
  releaseVTKObjects,
  releaseVTKObjectsInTagsToReleaseQueue,
} from "./vtk-objects-manager";
import { shouldIgnoreEventKeyup } from "../vtk/utils";
import { ThreeDEditorState } from "./three-d-editor.state";
import {
  changeLabelMapMask,
  createMeasurement,
  serializeMeasurement,
} from "./three-d-editor.utils";
import { BatchObservationDTO } from "services/label-service/dtos";
import { SliceAnnotationItemMask } from "services/label-service/dtos/annotations.dto";
import { sliceAnnoItemMasksToEditorLabels } from "../context/three-d-labeling.mappers";
import classNames from "classnames";

export const ThreeDEditorContext = createContext({} as ThreeDEditorState);
export const useThreeDEditorContext = () => {
  return useContext(ThreeDEditorContext);
};

interface ThreeDEditorProviderProps {
  annotator?: string;
  contextId?: number | string; // jobId or something else
  imageData?: vtkImageData;
  editorLabelOptions?: EditorLabelOption[];
  editorLabels?: EditorLabel[];
  initMeasurementsData?: EditorMeasurementData[];
  workingLabelMap?: undefined | vtkImageData;
  labelMapOptions?: LabelMapOption[];
  editorMetadata?: EditorMetadataRowModel[];
  editorHeight?: string;
  editorNavWidth?: string;
  batchObservations?: BatchObservationDTO[];
  onLabelMapChanged?: () => void;
  onWorkingLabelsChanged?: (v: EditorLabel[]) => void;
  onWorkingMetadataChanged?: (v: EditorMetadataRowModel[]) => void;
  onLoaded?: () => void;
  onError?: (error: any) => void;
  onRelease?: () => void;
}
export const ThreeDEditorProvider = ({
  annotator,
  contextId,
  imageData,
  editorLabelOptions = [],
  editorLabels = [],
  initMeasurementsData = [],
  workingLabelMap,
  labelMapOptions = [],
  editorMetadata = [],
  editorHeight = "calc(100vh - 60px)",
  editorNavWidth = "290px",
  batchObservations,
  onLabelMapChanged,
  onWorkingLabelsChanged,
  onWorkingMetadataChanged,
  onLoaded,
  onError,
}: ThreeDEditorProviderProps) => {
  const [vtkContext, setVtkContext] = useState<any>();
  const axesRef = useRef([SlicingMode.K, SlicingMode.I, SlicingMode.J]);
  const [activeWindow, setActiveWindow] = useState(-1);
  // 3d Volumes visibility
  const [volume3dVisibility, setVolume3dVisibility] = useState(false);
  const [slices3dVisibility, setSlices3dVisibility] = useState(false);
  const [label3dVisibility, setLabel3dVisibility] = useState(true);

  // Tools
  const [activeTool, setActiveTool] = useState<EditorTool>();
  const [crossHairVisibility, setCrossHairVisibility] = useState(false);
  const [brushRadius, setBrushRadius] = useState(10);

  // Fill between slice
  const [fbtsIsInitialized, setFbtsIsInitialized] = useState(false);
  const [fbtsIsAutoUpdate, setFbtsIsAutoUpdate] = useState(true);
  const [fbtsIsProcessing, setFbtsIsProcessing] = useState(false);

  // Segmentation smoothing effects
  const [smeIsInitialized, setSmeIsInitialized] = useState(false);
  const [smeIsAutoUpdate, setSmeIsAutoUpdate] = useState(true);
  const [smeIsProcessing, setSmeIsProcessing] = useState(false);

  // Color
  const [sliceWindowLevel, setSliceWindowLevel] = useState(DEFAULT_WL);
  const [sliceColorLevel, setSliceColorLevel] = useState(DEFAULT_CL);
  const [presetName, setPresetName] = useState<string>(
    vtkColorMaps.rgbPresetNames[0]
  );

  // Label
  const [labelOptions, setLabelOptions] =
    useState<EditorLabelOption[]>(editorLabelOptions);
  const [labels, setLabels] = useState<EditorLabel[]>(editorLabels);
  const [activeLabel, setActiveLabel] = useState<EditorLabel>(editorLabels[0]);
  // Handle case for view previous results
  const [viewLabels, setViewLabels] = useState<EditorLabel[]>([]);
  const [isShowViewLabels, setIsShowViewLabels] = useState(false);

  // Metadata
  const [workingMetadata, setWorkingMetadata] =
    useState<EditorMetadataRowModel[]>(editorMetadata);
  const [viewMetadata, setViewMetadata] = useState<EditorMetadataRowModel[]>(
    []
  ); // view previous result case

  // Layout
  const [currentLayout, setCurrentLayout] =
    useState<EditorLayout>(EDITOR_LAYOUT_4);

  // Measurements
  const [measurements, setMeasurements] = useState<EditorMeasurement[]>([]);
  const measurementsRef = useRef(measurements);

  // Refs
  const containerRef = useRef<HTMLDivElement>(null);

  const labelsRef = useRef<EditorLabel[]>(editorLabels);
  const workingMetadataRef = useRef<EditorMetadataRowModel[]>(editorMetadata);
  const activeToolRef = useRef<EditorTool>();
  const activeLabelRef = useRef<EditorLabel>(editorLabels[0]);
  const crossHairVisibilityRef = useRef(false);

  const sliceIRef = useRef<HTMLDivElement>();
  const sliceJRef = useRef<HTMLDivElement>();
  const sliceKRef = useRef<HTMLDivElement>();

  const sliceRefs = useRef([sliceIRef, sliceJRef, sliceKRef]);

  // change in editorLabelOptions
  useEffect(() => {
    setLabelOptions(editorLabelOptions);
  }, [editorLabelOptions]);

  // change in editorLabels
  useEffect(() => {
    if (editorLabels !== labelsRef.current) {
      setLabels(editorLabels);
    }
  }, [editorLabels]);

  useEffect(() => {
    const isExist = !!labels.find((lb) => lb.id === activeLabel.id);
    if (!isExist) {
      if (labels.length > 1) {
        setActiveLabel(labels[1]);
      } else {
        setActiveLabel(labels[0]);
      }
    }
  }, [labels, activeLabel]);

  // change in editorMetadata
  useEffect(() => {
    if (editorMetadata !== workingMetadataRef.current) {
      setWorkingMetadata(editorMetadata);
    }
  }, [editorMetadata]);

  // on working metadata changed
  useEffect(() => {
    onWorkingMetadataChanged && onWorkingMetadataChanged(workingMetadata);
  }, [workingMetadata, onWorkingMetadataChanged]);

  // on working labels/segments changed
  useEffect(() => {
    onWorkingLabelsChanged && onWorkingLabelsChanged(labels);
  }, [labels, onWorkingLabelsChanged]);

  useEffect(() => {
    activeToolRef.current = activeTool;
  }, [activeTool]);

  useEffect(() => {
    activeLabelRef.current = activeLabel;
  }, [activeLabel]);

  useEffect(() => {
    labelsRef.current = labels;
  }, [labels]);

  useEffect(() => {
    workingMetadataRef.current = workingMetadata;
  }, [workingMetadata]);

  useEffect(() => {
    crossHairVisibilityRef.current = crossHairVisibility;
  }, [crossHairVisibility]);

  useEffect(() => {
    measurementsRef.current = measurements;
  }, [measurements]);

  // Init stuff
  useEffect(() => {
    if (!imageData) return;

    try {
      console.log("Init editor provider...");
      const createGenericWindow = () => {
        const genericRenderWindow = vtkGenericRenderWindow.newInstance();
        const renderer = genericRenderWindow.getRenderer();
        const renderWindow = genericRenderWindow.getRenderWindow();
        const camera = renderer.getActiveCamera();
        const widgetManager = vtkWidgetManager.newInstance();

        addToVTKObjects([
          widgetManager,
          renderer,
          renderWindow,
          genericRenderWindow,
          genericRenderWindow.getInteractor(),
        ]);

        return {
          genericRenderWindow,
          renderer,
          renderWindow,
          camera,
          widgetManager,
        };
      };

      const painter = vtkPaintFilterCustom.newInstance();
      addToVTKObjects(painter);
      if (workingLabelMap) {
        painter.setLabelMap(workingLabelMap);
      }

      const widgets: any = {
        paintWidget: vtkPaintWidgetCustom.newInstance(),
        polygonWidget: vtkSplineWidgetCustom.newInstance({
          resetAfterPointPlacement: true,
          resolution: 1,
          handleSizeInPixels: 1,
        }),
        resliceCursorWidget: vtkResliceCursorWidget.newInstance(),
        adjustWindowColorRectangleWidget: vtkRectangleWidget.newInstance({
          resetAfterPointPlacement: true,
        }),
      };
      addToVTKObjects(widgets, true);

      // Set cursors
      widgets.polygonWidget.setDefaultCursor("default");

      const resliceCursorWidgetState =
        widgets.resliceCursorWidget.getWidgetState();
      widgets.resliceCursorWidgetState = resliceCursorWidgetState;
      resliceCursorWidgetState.setKeepOrthogonality(true);
      resliceCursorWidgetState.setOpacity(0.4);
      resliceCursorWidgetState.setSphereRadius(10 * window.devicePixelRatio);
      resliceCursorWidgetState.setLineThickness(2);
      resliceCursorWidgetState.setEnableRotation(false);
      widgets.resliceCursorWidget.setImage(imageData);

      painter.setBackgroundImage(imageData);
      const radius = 10;
      painter.setRadius(radius);
      widgets.paintWidget.setRadius(radius);

      // init stuff for window volume
      const windowVolume = createGenericWindow();
      const imageVolume = {
        mapper: vtkVolumeMapper.newInstance({ sampleDistance: 0.9 }),
        actor: vtkVolume.newInstance(),
        cfunc: vtkColorTransferFunction.newInstance(),
        ofunc: vtkPiecewiseFunction.newInstance(),
        gaussianWidget: vtkPiecewiseGaussianWidget.newInstance({
          size: [1000, 400],
        }),
      };
      const labelFilterVolume = {
        // NOTE: use marching cubes later
        // marchingCubes: vtkImageMarchingCubes.newInstance({
        //   contourValue: 1.0,
        //   computeNormals: true,
        //   mergePoints: true,
        // }),
        // mapper: vtkMapper.newInstance(),
        // actor: vtkActor.newInstance(),

        mapper: vtkVolumeMapper.newInstance(),
        actor: vtkVolume.newInstance(),

        cfunc: vtkColorTransferFunction.newInstance(),
        ofunc: vtkPiecewiseFunction.newInstance(),
      };
      addToVTKObjects(imageVolume, true);
      addToVTKObjects(labelFilterVolume, true);

      // init for windows slice
      const windowsSliceData: any = {};
      const windowsSliceArray: any = [];

      for (const axis of axesRef.current) {
        let sliceRef = sliceRefs.current[axis];
        if (!sliceRef?.current) continue;
        const windowSlice = createGenericWindow();

        const container = sliceRef?.current as HTMLDivElement;
        windowSlice.genericRenderWindow.setContainer(container);
        windowSlice.genericRenderWindow.resize();
        windowSlice.widgetManager.setRenderer(windowSlice.renderer);

        // init handle widgets
        const handles = {
          paintHandle: windowSlice.widgetManager.addWidget(
            widgets.paintWidget,
            ViewTypes.SLICE
          ),
          polygonHandle: windowSlice.widgetManager.addWidget(
            widgets.polygonWidget,
            ViewTypes.SLICE
          ),
          resliceCursorHandle: windowSlice.widgetManager.addWidget(
            widgets.resliceCursorWidget,
            xyzToViewType[axis]
          ),
          adjustWindowColorRectangleHandle: windowSlice.widgetManager.addWidget(
            widgets.adjustWindowColorRectangleWidget,
            ViewTypes.SLICE
          ),
        };
        addToVTKObjects(handles, true);
        // set this so the handle circle scale is equal to the tail circles
        // check the CircleContextRepresentationCustom.js file
        // we added setter for the activeScaleFactor parameter
        (
          handles.paintHandle?.getRepresentations()[0] as any
        ).setActiveScaleFactor(1.0);

        const imageSlice = {
          image: {
            mapper: vtkImageMapper.newInstance() as any,
            actor: vtkImageSlice.newInstance() as any,
          },
          labelMap: {
            mapper: vtkImageMapper.newInstance() as any,
            actor: vtkImageSlice.newInstance() as any,
            cfunc: vtkColorTransferFunction.newInstance(),
            ofunc: vtkPiecewiseFunction.newInstance(),
          },
        };
        addToVTKObjects(imageSlice.image, true);
        addToVTKObjects(imageSlice.labelMap, true);
        const data = {
          windowSlice,
          imageSlice,
          handles,
          axis,
        };
        windowsSliceData[axis] = data;
        windowsSliceArray.push(data);
      }

      // load measurements data if has any
      if (initMeasurementsData) {
        const newMeasurements: EditorMeasurement[] = [];

        // for each window
        for (const windowSliceData of windowsSliceArray) {
          const { windowSlice, imageSlice, axis } = windowSliceData;
          const windowId = axis;

          for (const measurementData of initMeasurementsData) {
            if (windowId !== measurementData.windowId) continue;
            const measurement = createMeasurement({
              type: measurementData.type,
              windowId,
              image: imageSlice.image,
              imageData,
              widgetManager: windowSlice.widgetManager,
              handlePositions: measurementData.handlePositions,
            });
            measurement.isFinalized = true;
            measurement.slice = measurementData.slice;
            measurement.note = measurementData.note;
            newMeasurements.push(measurement);
          }
        }

        setMeasurements(newMeasurements);
      }

      const handleKeyUp = (e: any) => {
        if (shouldIgnoreEventKeyup(e)) return;
        const key = e.key;

        for (const tool of EDITOR_TOOLS) {
          if (tool.keyBind === key) {
            if (activeToolRef.current?.id === tool.id) {
              setActiveTool(undefined);
            } else {
              if (tool.isDrawing && activeLabelRef.current.isNone) {
                continue;
              }
              setActiveTool(tool);
            }
          }
        }

        switch (key) {
          case "C":
            setCrossHairVisibility(!crossHairVisibilityRef.current);
            break;
        }
      };

      const handleLayoutChangedSize = () => {
        windowVolume.genericRenderWindow.resize();
        for (const windowSliceData of windowsSliceArray) {
          windowSliceData.windowSlice.genericRenderWindow.resize();
        }
      };

      window.addEventListener("keyup", handleKeyUp);

      document.addEventListener(
        ThreeDEditorEvents.LAYOUT_CHANGE_SIZE,
        handleLayoutChangedSize
      );

      setVtkContext({
        imageData,

        painter,
        widgets,

        windowVolume,
        imageVolume,
        labelFilterVolume,

        windowsSliceData,
        windowsSliceArray,

        workingLabelMap,
        labelMapOptions,
      });

      console.log("Done init editor");

      return () => {
        // send command save measurements
        if (contextId) {
          const measurementsData =
            measurementsRef.current.map(serializeMeasurement);
          if (measurementsData.length > 0) {
            const eventData: EditorEventSaveMeasurements = {
              contextId,
              measurementsData,
            };
            document.dispatchEvent(
              new CustomEvent(ThreeDEditorEvents.COMMAND_SAVE_MEASUREMENTS, {
                detail: eventData,
              })
            );
          }
        }
        // remove all measurement widgets
        for (const m of measurementsRef.current) {
          const wm = m.viewWidget.getWidgetManager();
          wm.removeWidget(m.widget);
        }

        window.removeEventListener("keyup", handleKeyUp);
        document.removeEventListener(
          ThreeDEditorEvents.LAYOUT_CHANGE_SIZE,
          handleLayoutChangedSize
        );

        if (
          imageVolume.gaussianWidget &&
          !imageVolume.gaussianWidget.isDeleted()
        ) {
          imageVolume.gaussianWidget.setContainer(null);
        }

        releaseVTKObjects();
        releaseVTKObjectsInTagsToReleaseQueue();

        setVtkContext(undefined);
        setIsShowViewLabels(false);
        setViewLabels([]);
        setActiveTool(undefined);
        setFbtsIsInitialized(false);
        setFbtsIsProcessing(false);
        setMeasurements([]);
        if (workingLabelMap) {
          changeLabelMapMask(workingLabelMap, PREVIEW_MASK, 0);
        }

        console.log("Editor released");
      };
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
      onError && onError(error);
    }
  }, [
    contextId,
    initMeasurementsData,
    imageData,
    workingLabelMap,
    labelMapOptions,
    onLoaded,
    onError,
  ]);

  useEffect(() => {
    return () => {
      console.log("unmount editor provider");
      releaseVTKObjects();
      setVtkContext(undefined);
    };
  }, []);

  useEffect(() => {
    if (!vtkContext) return;

    if (activeTool?.isEraser) {
      vtkContext.painter.setIsErasingMode(true);
    } else {
      vtkContext.painter.setIsErasingMode(false);
    }

    vtkContext.painter.setLabel(activeLabel.maskValue);
  }, [activeLabel, activeTool, vtkContext]);

  const renderAllWindows = () => {
    if (!vtkContext) return;
    const { windowVolume, windowsSliceArray } = vtkContext;

    windowVolume.renderWindow.render();

    for (const windowSliceData of windowsSliceArray) {
      windowSliceData.windowSlice.renderWindow.render();
    }
  };

  useEffect(() => {
    if (!vtkContext) return;
    const { painter, widgets } = vtkContext;
    painter.setRadius(brushRadius);
    widgets.paintWidget.setRadius(brushRadius);
  }, [brushRadius, vtkContext]);

  const copyLabelMap = (
    fromLabelMap: vtkImageData,
    toLabelMap: vtkImageData
  ) => {
    // from label map
    const fromScalars = fromLabelMap.getPointData().getScalars();
    const fromData = fromScalars.getData();

    // to label map
    const toScalars = toLabelMap.getPointData().getScalars();
    const toData = toScalars.getData();

    for (let i = 0; i < toData.length; i++) {
      toData[i] = fromData[i];
    }

    toScalars.setData(toData);
  };

  const updateLabelMapMaskFromFillBetweenSlices = (
    labelMap: vtkImageData,
    fbtsLabelMap: vtkImageData,
    interpolateMask: number,
    previewMask: number
  ) => {
    // label map to change mask
    const scalars = labelMap.getPointData().getScalars();
    const data = scalars.getData();

    // fbts label map
    const fbtsData = fbtsLabelMap.getPointData().getScalars().getData();

    // we fill interpolated pixel to preview mask
    for (let i = 0; i < fbtsData.length; i++) {
      if (fbtsData[i] === interpolateMask && data[i] === 0) {
        data[i] = previewMask;
      }
    }

    scalars.setData(data);
  };

  const saveLabelMap = async () => {
    if (!vtkContext) return;
    const labelMap = vtkContext.painter.getLabelMap();
    const itkImage = itkHelper.convertVtkToItkImage(labelMap, {}); // we pass empty object in order to copy data so no dettach error.
    const buffer = await itkImageToArrayBuffer(itkImage);
    const blob = new Blob([buffer]);
    downloadBlob(blob, "labelMap.nii.gz");
  };

  const loadLabelMap = async (vtkImage: vtkImageData) => {
    if (!vtkContext) return;
    const { painter, workingLabelMap } = vtkContext;
    try {
      const sliceMasks: SliceAnnotationItemMask[] = [];
      const ids: Record<string, number> = {};
      const scalars = vtkImage.getPointData().getScalars();
      const data = scalars.getData();

      for (let i = 0; i < data.length; i++) {
        if (data[i] !== 0) {
          ids[data[i]] = data[i];
        }
      }

      for (const key of Object.keys(ids)) {
        const maskId = ids[key];
        const labelId = labelOptions.find((lb) => lb.maskId === maskId)?.id;
        if (!labelId) continue;
        const label = labels.find((lb) => lb.labelOptionId === labelId);
        if (!label) continue;
        sliceMasks.push({
          maskValue: label.maskValue,
          observationId: labelId,
        });
      }

      if (sliceMasks.length > 0) {
        const observations = batchObservations?.map((b) => b.observation) ?? [];
        const labels = sliceAnnoItemMasksToEditorLabels(
          sliceMasks,
          observations
        );
        setLabels(labels);
      }
    } catch (error) {}
    copyLabelMap(vtkImage, workingLabelMap);
    painter.setLabelMap(workingLabelMap);
    notifyWorkingLabelMapChanged();
    renderAllWindows();
  };

  const handleLoadError = (error: any) => {
    onError && onError(error);
  };

  const selectWindowIRef = useRef<HTMLDivElement>();
  const selectWindowJRef = useRef<HTMLDivElement>();
  const selectWindowKRef = useRef<HTMLDivElement>();
  const selectWindowVolumeRef = useRef<HTMLDivElement>();
  const windowsDataRef = useRef<EditorWindowData[]>([
    {
      id: SlicingMode.I,
      name: "Sagittal (LR)",
      order: EDITOR_DEFAULT_LAYOUT_ORDERS[SlicingMode.I],
      visibility: true,
      otherWindowOptions: [],
      otherWindowOptionsRef: selectWindowIRef,
    },
    {
      id: SlicingMode.J,
      name: "Coronal (AP)",
      order: EDITOR_DEFAULT_LAYOUT_ORDERS[SlicingMode.J],
      visibility: true,
      otherWindowOptions: [],
      otherWindowOptionsRef: selectWindowJRef,
    },
    {
      id: SlicingMode.K,
      name: "Axial (SI)",
      order: EDITOR_DEFAULT_LAYOUT_ORDERS[SlicingMode.K],
      visibility: true,
      otherWindowOptions: [],
      otherWindowOptionsRef: selectWindowKRef,
    },
    {
      id: 3,
      name: "3D",
      order: EDITOR_DEFAULT_LAYOUT_ORDERS[3],
      visibility: true,
      otherWindowOptions: [],
      otherWindowOptionsRef: selectWindowVolumeRef,
    },
  ]);

  const [windowsDataMap, setWindowsDataMap] = useState<
    Record<number, EditorWindowData>
  >(() => {
    const map: Record<number, EditorWindowData> = {};
    for (const data of windowsDataRef.current) {
      map[data.id] = data;
      map[data.id].otherWindowOptions = windowsDataRef.current
        .filter((other) => other.id !== data.id)
        .map((other) => ({
          label: other.name,
          value: other,
        }));
    }
    return map;
  });

  const swapWindow = (
    window1: EditorWindowData,
    window2: EditorWindowData,
    sendEvent: boolean = true
  ) => {
    const window1Order = window1.order;
    window1.order = window2.order;
    window2.order = window1Order;
    setWindowsDataMap({ ...windowsDataMap });
    window1.otherWindowOptionsRef.current.blur();

    if (sendEvent) {
      const swapEvent: EditorEventSwapWindow = {
        fromWindow: window1,
        toWindow: window2,
      };
      document.dispatchEvent(
        new CustomEvent(ThreeDEditorEvents.COMMAND_AFTER_SWAP_WINDOW_LEAVE, {
          detail: swapEvent,
        })
      );
    }
  };

  const setCurrentLabels = (newLabels: EditorLabel[]) => {
    if (isShowViewLabels) {
      setViewLabels(newLabels);
    } else {
      setLabels(newLabels);
    }
  };

  const getCurrentLabels = (): EditorLabel[] => {
    if (isShowViewLabels) {
      return viewLabels;
    } else {
      return labels;
    }
  };

  const updateObjectColorsAndOpacity = useCallback(
    (object: any, labels: EditorLabel[], lowerPreviewOpacity = false) => {
      // because we release vtk objects in the parent commponent
      // so we need to check this case
      if (object.cfunc.isDeleted() || object.ofunc.isDeleted()) return;

      object.cfunc.removeAllPoints();
      object.ofunc.removeAllPoints();
      for (const label of labels) {
        const rgb = hexToRgb(label.color);
        object.cfunc.addRGBPointLong(label.maskValue, rgb[0], rgb[1], rgb[2]);
        let opacity = 0;
        if (label.visibility) {
          opacity = label.opacity / 100;
          if (lowerPreviewOpacity && label.isPreview) {
            opacity = opacity / 3;
          }
        }
        object.ofunc.addPoint(label.maskValue, opacity);
      }
    },
    []
  );

  const notifyWorkingLabelMapChanged = useCallback(() => {
    onLabelMapChanged && onLabelMapChanged();
  }, [onLabelMapChanged]);

  const value: ThreeDEditorState = {
    annotator,
    editorContext: vtkContext,
    renderAllWindows,
    activeWindow,
    setActiveWindow,

    sliceWindowLevel,
    setSliceColorLevel,
    sliceColorLevel,
    setSliceWindowLevel,
    presetName,
    setPresetName,

    activeTool,
    setActiveTool,
    crossHairVisibility,
    setCrossHairVisibility,
    brushRadius,
    setBrushRadius,

    fbtsIsInitialized,
    setFbtsIsInitialized,
    fbtsIsAutoUpdate,
    setFbtsIsAutoUpdate,
    fbtsIsProcessing,
    setFbtsIsProcessing,

    smeIsInitialized,
    setSmeIsInitialized,
    smeIsAutoUpdate,
    setSmeIsAutoUpdate,
    smeIsProcessing,
    setSmeIsProcessing,

    labelOptions,
    activeLabel,
    setActiveLabel,
    labels,
    setLabels,
    viewLabels,
    setViewLabels,
    isShowViewLabels,
    setIsShowViewLabels,
    setCurrentLabels,
    getCurrentLabels,
    updateObjectColorsAndOpacity,

    copyLabelMap,
    changeLabelMapMask,
    updateLabelMapMaskFromFillBetweenSlices,
    saveLabelMap,
    loadLabelMap,
    notifyWorkingLabelMapChanged,

    workingMetadata,
    setWorkingMetadata,
    viewMetadata,
    setViewMetadata,

    volume3dVisibility,
    setVolume3dVisibility,
    slices3dVisibility,
    setSlices3dVisibility,
    label3dVisibility,
    setLabel3dVisibility,

    currentLayout,
    setCurrentLayout,
    windowsDataMap,
    setWindowsDataMap,
    swapWindow,

    measurements,
    setMeasurements,
  };

  return (
    <ThreeDEditorContext.Provider value={value}>
      <div className="flex w-full h-full bg-background-800" ref={containerRef}>
        <div
          className="self-center p-1"
          style={{
            display: "grid",
            gridTemplateColumns: currentLayout.gridTemplateColumns,
            gridTemplateRows: currentLayout.gridTemplateRows,
            width: `calc(100% - ${editorNavWidth})`, // minus nav width
            height: `${editorHeight}`,
            gridGap: "1px",
          }}
        >
          {windowsDataRef.current.map(({ id }) => {
            const visible = windowsDataMap[id].order <= currentLayout.numWindow;

            return (
              <div
                key={id}
                style={{
                  order: windowsDataMap[id].order,
                  opacity: visible ? "100" : "0",
                  position: visible ? "relative" : "fixed",
                  zIndex: visible ? 50 : -10,
                }}
                className={classNames({
                  "col-span-3 row-span-3":
                    windowsDataMap[id].order === 1 &&
                    currentLayout.id === EDITOR_LAYOUT_4_1.id,
                })}
              >
                {id < 3 && (
                  <WindowSlicer
                    ref={sliceRefs.current[id]}
                    axis={id}
                    windowId={id}
                    onError={handleLoadError}
                  />
                )}
                {id === 3 && (
                  <WindowVolume
                    windowId={id}
                    onError={handleLoadError}
                    // we only need to check if the last component is loaded
                    onLoaded={() => onLoaded && onLoaded()}
                  />
                )}
              </div>
            );
          })}
        </div>
        <ThreeDEditorNav width={editorNavWidth} />
      </div>
    </ThreeDEditorContext.Provider>
  );
};
