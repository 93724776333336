/*
 * File: project-api-endpoints-settings.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 10th September 2021 3:37:13 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Checkbox, FormControlLabel } from "@material-ui/core";

export const ProjectAPIEndpointsSettings = () => {
  return (
    <div className="p-4 w-full" style={{maxWidth: "800px"}}>
      <div className="grid grid-cols-4 gap-4">
        <EndpointTitle label="Data Cleaning" />
        <EndpointForm />
        <EndpointTitle label="ML Endpoints" />
        <EndpointForm />
        <EndpointTitle label="Logging" />
        <EndpointForm />
        <div className="flex items-center">Severity</div>
        <div className="flex items-center">
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="Info"
          />
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="Warning"
          />
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="Error"
          />
        </div>
      </div>
    </div>
  );
};

const EndpointTitle = ({ label }: { label: string }) => {
  return (
    <>
      <div className="font-bold text-lg">{label}</div>
      <div className="col-span-3"></div>
    </>
  );
};

const EndpointForm = () => {
  return (
    <>
      <div className="flex items-center">URI</div>
      <div className="col-span-3">
        <input type="text" className="w-full p-2 border border-gray-500" />
      </div>
      <div className="flex items-center">Authentication Token</div>
      <div className="col-span-3">
        <input type="text" className="w-full p-2 border border-gray-500" />
      </div>
    </>
  );
};
