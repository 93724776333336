/*
 * File: job.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 11th July 2022 9:59:36 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Project } from "domain/labeling/project";
import { ProjectDTO } from "../dtos";
import { LabelerProject } from "domain/image-labeling";

function fromDTO(dto: ProjectDTO): Project {
  return {
    id: dto.id,
    name: dto.name,
    description: dto.description,
    type: dto.type,
    subType: dto.subType,
    pollStrategy: dto.settings.pollStrategy,
    autoSaveInSecond: dto.settings.autoSaveInSecond,
    workspaceId: dto.workspaceId,
    historyPermission: dto.settings.historyPermission,
    dto,
  };
}

function toLabelerProject(entity: Project): LabelerProject {
  return {
    id: entity.id,
    name: entity.name,
    description: entity.description,
    type: entity.type,
    available: true,
    historyPermission: entity.historyPermission,
  };
}

function toDTO(entity: Project): ProjectDTO {
  throw new Error("Method not implemented");
}

export const projectMapper = {
  fromDTO,
  toDTO,
  toLabelerProject
};
