/*
 * File: create-dataset-dialog.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 2nd August 2021 2:47:02 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { ChangeEvent, useMemo, useRef, useState } from "react";
import { Logger } from "utilities/logger";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { selectCurrentUser } from "store/auth/auth.selectors";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { useKeyPress, useLocalStorageState } from "ahooks";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { SelectableInput } from "components/common/vb-input/selectable-input.component";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { selectSupportedDatasourceTypes } from "store/common/app-setting/app-setting.selectors";
import { localStorageKey } from "constants/storage.constant";
import validator from "validator";
import { DataType } from "domain/customer";
import * as Sentry from "@sentry/react";
import useMoveFilesOptions from "pages/customer/datasets/file-browse/hooks/use-move-options";
import { StorageApi } from "data-access/impl/storage";

interface Props {
  open: boolean;
  fileIds: number[];
  fromDatasetId?: number;
  onClose: () => void;
}

export const MoveFilesToNewDatasetModal = ({
  fileIds,
  fromDatasetId = -1,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const [name, setName] = useState("");
  const inputDescriptionRef = useRef<HTMLTextAreaElement>(null);
  const datasourceTypes = useAppSelector(selectSupportedDatasourceTypes(t));
  const [datasourceType, setDatasourceType] = useLocalStorageState<DataType>(
    localStorageKey.PREFERED_DATASOURCE_TYPE,
    DataType.IMAGE
  );
  const [processing, setProcessing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { options, option, onChange } = useMoveFilesOptions("copy");

  const isValidInput = useMemo(() => {
    if (validator.isEmpty(name)) return false;
    if (validator.isEmpty(datasourceType)) return false;
    return true;
  }, [datasourceType, name]);

  function handleDataTypeChanged(newValue: any) {
    if (newValue && newValue.value) {
      setDatasourceType(newValue.value);
    }
  }

  function handleNameChange(event: ChangeEvent<any>) {
    setName(event.target.value);
  }

  async function handleSubmit() {
    if (!currentUser) return;
    if (!isValidInput) return;
    if (processing) return;

    try {
      setProcessing(true);
      const description = inputDescriptionRef.current?.value || "";
      const formData = new FormData();
      formData.append("createdBy", currentUser.uid);
      formData.append("name", name.trim());
      formData.append("category", datasourceType);
      formData.append("description", description.trim());
      const { data: dataset } = await StorageApi.createDataset(formData);
      if (!dataset) throw new Error("Failed to create dataset");
      await StorageApi.moveFiles(
        fileIds,
        fromDatasetId,
        dataset.id,
        option?.value === "cut"
      );
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      onClose && onClose();
    } catch (err) {
      Sentry.captureException(err);
      Logger.log("err", err);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setName("");
      setProcessing(false);
    }
  }

  useKeyPress(KeyboardKey.Enter, handleSubmit, {
    target: containerRef.current,
  });

  return (
    <VBModal
      title="Move files to a new datasource"
      open={open}
      onClose={onClose}
      textSubmit={t("common:buttonSubmit")}
      onSubmit={handleSubmit}
      disableSubmit={processing || !isValidInput}
      blockUI={processing}
      processingIndicator={processing}
    >
      <div className="flex flex-col gap-3" ref={containerRef}>
        <SelectableInput
          required
          value={option}
          onChange={onChange}
          header="Move option"
          options={options}
          menuPortalTarget={document.body}
        />
        <div className="flex flex-col gap-2">
          <div>{t("dataset:createDialog.textDatasourceName")}</div>
          <input
            autoFocus
            type="text"
            className="h-12 px-4 bg-white border rounded border-background-300 focus:border-primary"
            required
            onChange={handleNameChange}
            placeholder={t("dataset:createDialog.placeholder")}
          />
        </div>

        <SelectableInput
          value={datasourceTypes.find((item) => item.value === datasourceType)}
          onChange={handleDataTypeChanged}
          header={t("dataset:createDialog.textDatasourceType")}
          options={datasourceTypes}
          menuPortalTarget={document.body}
        />

        <div className="flex flex-col gap-2">
          <div> {t("dataset:createDialog.textDatasourceDescription")}</div>
          <textarea
            className="h-24 px-4 py-2 bg-white border rounded border-background-300 focus:border-primary"
            ref={inputDescriptionRef}
            placeholder={t("dataset:createDialog.labelDescription")}
          />
        </div>
      </div>
    </VBModal>
  );
};

export default MoveFilesToNewDatasetModal;
