import { IconNote, IconTrash, IconVoteUpActive } from "components/common/vb-icon.component";
import { useEffect, useState } from "react";
import { FabricOverlayEvents } from "./fabric/fabric.models";
import { usePathologyEditorContext } from "./pathology-editor.provider";
import OpenSeadragon from "openseadragon";


export const PathologyEditorAnnotationUtilsPopOverComponent = () => {

  const {
    fabricOverlay,
    syncAnnotationsFromFabricObjects,
  } = usePathologyEditorContext();

  const [displayPos, setDisplayPos] = useState({x: 0, y: 0});
  const [visible, setVisible] = useState(false);
  const [currentObject, setCurrentObject] = useState<fabric.Object>();

  useEffect(() => {
    if (!fabricOverlay) return;

    const handleMouseOverUtilButton = (e: any) => {
      const {object, fabricAbsPos} = e.detail as any;
      const imagePos = fabricOverlay.fabricAbsoluteToImage(fabricAbsPos.x, fabricAbsPos.y);
      const windowPos = fabricOverlay.viewer.viewport.imageToWindowCoordinates(new OpenSeadragon.Point(imagePos.x, imagePos.y));
      setDisplayPos({
        x: windowPos.x,
        y: windowPos.y,
      });
      setVisible(true);
      setCurrentObject(object);
    }

    const handleMouseOutUtilButton = (e: any) => {
      setVisible(false);
      setCurrentObject(undefined);
    }

    document.addEventListener(FabricOverlayEvents.MOUSE_OVER_UTIL_BUTTON, handleMouseOverUtilButton);
    document.addEventListener(FabricOverlayEvents.MOUSE_OUT_UTIL_BUTTON, handleMouseOutUtilButton);

    return () => {
      document.removeEventListener(FabricOverlayEvents.MOUSE_OVER_UTIL_BUTTON, handleMouseOverUtilButton);
      document.removeEventListener(FabricOverlayEvents.MOUSE_OUT_UTIL_BUTTON, handleMouseOutUtilButton);
    }

  }, [fabricOverlay]);

  const handleMouseLeave = () => {
    setVisible(false);
  }

  const handleClickDelete = () => {
    if (!fabricOverlay || !currentObject) return;
    fabricOverlay.removeObject(currentObject);
    syncAnnotationsFromFabricObjects({});
    setCurrentObject(undefined);
    setVisible(false);
  }

  return (
    <div 
      className="transform -translate-x-1/2 -translate-y-6 fixed flex items-center gap-1 bg-white rounded border text-black px-1"
      style={{
        left: displayPos.x,
        top: displayPos.y,
        zIndex: visible ? 100 : -1,
        opacity: visible ? 1: 0,
        transition: "opacity 1s ease-out",
      }}
      onMouseLeave={handleMouseLeave}
    >
      <IconVoteUpActive className="w-5 h-5 text-green cursor-pointer opacity-50" />
      <IconNote className="w-5 h-5 cursor-pointer opacity-50" />
      <IconTrash className="w-5 h-5 cursor-pointer" onClick={handleClickDelete} />
    </div>
  )
}
