/*
 * File: app.state.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 4:31:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export interface AppAction {
  setLoading(loading: boolean): void;
  setError(error: Error | null): void;
}

const defaultAppAction: AppAction = {
  setLoading: (_) => {},
  setError: (_) => {},
};

export interface AppState extends AppAction {
  loading: boolean;
  appConfig?: any;
  error: Error | null;
}

export const defaultAppState: AppState = {
  loading: true,
  error: null,
  ...defaultAppAction,
};
