/*
 * File: mat-light-tooltip.component.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 3rd July 2021 5:28:39 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { withStyles, Theme, Tooltip } from "@material-ui/core";

export const LightTooltip = withStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
