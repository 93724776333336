import { PayloadAction } from "@reduxjs/toolkit";
import { Annotation, AnnotationAttribute, Label } from "domain/image-labeling";
import { AnnotatedLabel } from "domain/image-labeling/annotated-label";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";
import { AtLeast } from "types/common";
import { ImageWorkspaceState } from "../image-workspace.state";
import {
  ActiveAnnotation,
  INITIAL_IMAGE_ANNOTATIONS_STATE,
} from "./image-annotations.state";

export const imageAnnotationsActions = {
  resetImageAnnotationsState({ imageAnnotations }: ImageWorkspaceState) {
    Object.assign(imageAnnotations, INITIAL_IMAGE_ANNOTATIONS_STATE);
  },
  imageAnnotationsLoaded(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<Annotation[]>
  ) {
    imageAnnotations.annotations = action.payload;
  },
  imageAnnotationsAdded(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<Annotation[]>
  ) {
    action.payload.forEach((anno) => {
      if (
        !imageAnnotations.annotations.find((item) => item.uuid === anno.uuid)
      ) {
        imageAnnotations.annotations.push(anno);
      }
    });
  },
  imageRelationAnnotationCompleted(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<RelationAnnotation>
  ) {
    const existedAnnotation = imageAnnotations.relationAnnotations.find(
      (anno) => anno.uuid === action.payload.uuid
    );
    if (existedAnnotation) return;
    imageAnnotations.relationAnnotations.push(action.payload);
  },
  imageRelationAnnotationUpdated(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<RelationAnnotation>
  ) {
    imageAnnotations.relationAnnotations =
      imageAnnotations.relationAnnotations.map((anno) => {
        if (anno.uuid === action.payload.uuid) return action.payload;
        return anno;
      });
  },
  imageRelationAnnotationRemoved(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<RelationAnnotation>
  ) {
    imageAnnotations.relationAnnotations =
      imageAnnotations.relationAnnotations.filter(
        (anno) => anno.uuid !== action.payload.uuid
      );
  },

  imageRelationAnnotationsLoaded(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<RelationAnnotation[]>
  ) {
    imageAnnotations.relationAnnotations = action.payload;
  },
  imageAnnotationCompleted(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<Annotation>
  ) {
    const existedAnnotation = imageAnnotations.annotations.find(
      (anno) => anno.uuid === action.payload.uuid
    );
    if (existedAnnotation) return;
    imageAnnotations.annotations.push(action.payload);
  },
  imageAnnotationUpdated(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<AtLeast<Annotation, "uuid">>
  ) {
    imageAnnotations.annotations = imageAnnotations.annotations.map((anno) => {
      if (anno.uuid === action.payload.uuid)
        return {
          ...anno,
          ...action.payload,
        };
      return anno;
    });
  },
  imageAnnotationRemoved(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<AtLeast<Annotation, "uuid">>
  ) {
    imageAnnotations.annotations = imageAnnotations.annotations.filter(
      (anno) => anno.uuid !== action.payload.uuid
    );
  },
  imageAnnotationSelected(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<Annotation>
  ) {
    imageAnnotations.selectedAnnotation = {
      annotationUUID: action.payload.uuid,
      attributeMenu: false,
      contextMenu: false,
      classMenu: false,
    };
  },
  imageAnnotationAttributeMenuOpened(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<string>
  ) {
    imageAnnotations.selectedAnnotation = {
      annotationUUID: action.payload,
      attributeMenu: true,
      contextMenu: false,
      classMenu: false,
    };
  },

  annotationAttributeMenuClosed({ imageAnnotations }: ImageWorkspaceState) {
    imageAnnotations.selectedAnnotation = {
      ...imageAnnotations.selectedAnnotation,
      attributeMenu: false,
    };
  },

  imageAnnotationContextMenuOpened(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<string>
  ) {
    imageAnnotations.selectedAnnotation = {
      annotationUUID: action.payload,
      attributeMenu: false,
      contextMenu: true,
      classMenu: false,
    };
  },

  imageAnnotationContextMenuClosed({ imageAnnotations }: ImageWorkspaceState) {
    imageAnnotations.selectedAnnotation = {
      ...imageAnnotations.selectedAnnotation,
      contextMenu: false,
    };
  },

  imageAnnotationClassMenuOpened(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<string>
  ) {
    imageAnnotations.selectedAnnotation = {
      annotationUUID: action.payload,
      attributeMenu: false,
      contextMenu: false,
      classMenu: true,
    };
  },

  imageAnnotationClassMenuClosed({ imageAnnotations }: ImageWorkspaceState) {
    imageAnnotations.selectedAnnotation = {
      annotationUUID: "",
      attributeMenu: false,
      contextMenu: false,
      classMenu: false,
    };
  },

  imageAnnotationClassChanged(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<{
      annotationUUID: string;
      fromLabel: Label;
      toLabel: Label;
    }>
  ) {
    imageAnnotations.annotations = imageAnnotations.annotations.map((anno) => {
      if (anno.uuid === action.payload.annotationUUID) {
        const { fromLabel, toLabel } = action.payload;
        const attributes: AnnotationAttribute[] = [];
        if (
          toLabel.attributes &&
          toLabel.attributes.length > 0 &&
          anno.annotationData[0].attributes
        ) {
          for (const attr of anno.annotationData[0].attributes) {
            const fromLabelAttribute = fromLabel.attributes?.find(
              (a) => a.id === attr.id
            );
            if (!fromLabelAttribute) continue;
            const toLabelAttribute = toLabel.attributes.find((lb) => {
              return (
                lb.name === fromLabelAttribute.name &&
                lb.type === fromLabelAttribute.type &&
                JSON.stringify(lb.options) ===
                  JSON.stringify(fromLabelAttribute.options)
              );
            });
            if (toLabelAttribute) {
              attributes.push({
                id: toLabelAttribute.id,
                value: attr.value,
              });
            }
          }
        }

        return {
          ...anno,
          labelId: toLabel.id,
          annotationData: anno.annotationData.map((item, index) => {
            if (index === 0)
              return {
                ...item,
                attributes,
              };
            return item;
          }),
        };
      }
      return anno;
    });
    imageAnnotations.selectedAnnotation = {
      annotationUUID: "",
      attributeMenu: false,
      contextMenu: false,
      classMenu: false,
    };
  },

  imageAnnotationAttributeUpdated(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<{
      annotationId: string;
      attributes: AnnotationAttribute[];
    }>
  ) {
    imageAnnotations.annotations = imageAnnotations.annotations.map((anno) => {
      if (anno.uuid === action.payload.annotationId) {
        return {
          ...anno,
          annotationData: anno.annotationData.map((item, index) => {
            if (index === 0)
              return {
                ...item,
                attributes: action.payload.attributes,
              };
            return item;
          }),
        };
      }
      return anno;
    });
  },

  imageAnnotationJobCompleted(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<number>
  ) {
    imageAnnotations.annotations = imageAnnotations.annotations.filter(
      (anno) => anno.jobId !== action.payload
    );
    imageAnnotations.selectedAnnotation = {
      ...imageAnnotations.selectedAnnotation,
      attributeMenu: false,
    };
  },
  annotateLabelActivated(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<{
      annotatedLabel: AnnotatedLabel;
      active?: boolean;
    }>
  ) {
    if (action.payload.active) {
      imageAnnotations.activeAnnotatedLabels = [action.payload.annotatedLabel];
    } else {
      imageAnnotations.activeAnnotatedLabels = [];
    }
  },
  removeAllAnnotations({ imageAnnotations }: ImageWorkspaceState) {
    imageAnnotations.annotations = [];
    imageAnnotations.relationAnnotations = [];
    imageAnnotations.selectedAnnotation = {
      annotationUUID: "",
      attributeMenu: false,
      contextMenu: false,
      classMenu: false,
    };
  },
  setActiveAnnotations(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<ActiveAnnotation[]>
  ) {
    imageAnnotations.activeAnnotations = action.payload;
  },
  updateActiveAnnotation(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<ActiveAnnotation>
  ) {
    const newActiveAnnotation = action.payload;
    imageAnnotations.activeAnnotations = imageAnnotations.activeAnnotations.map(
      (activeAnnotation) => {
        if (
          activeAnnotation.annotationId === newActiveAnnotation.annotationId
        ) {
          return {
            ...activeAnnotation,
            ...newActiveAnnotation,
          };
        }
        return activeAnnotation;
      }
    );
  },
  // HARD CODE for Breast cancer here >.<
  setImageAnnotationForLabelIdFromAttributeValue(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<{
      jobIds: number[];
      labelId: number;
      annotation?: Annotation;
    }>
  ) {
    const { jobIds, labelId, annotation } = action.payload;
    // clear annotations for this labelId first
    imageAnnotations.annotations = imageAnnotations.annotations.filter(
      (anno) => anno.labelId !== labelId || !jobIds.includes(anno.jobId)
    );
    // undefined value which means no annotations for this labelId
    if (!annotation) return;
    // create annotations for all jobIds
    for (const jobId of jobIds) {
      imageAnnotations.annotations.push({ ...annotation, jobId });
    }
  },
  invalidPolygonModalClosed({ imageAnnotations }: ImageWorkspaceState) {
    imageAnnotations.invalidAnnotation = null;
  },
  imageLabelAnnotationsRemoved(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<{ labelId: number; jobId: number }>
  ) {
    const { labelId, jobId } = action.payload;
    imageAnnotations.annotations = imageAnnotations.annotations.filter(
      (anno) => anno.labelId !== labelId || anno.jobId !== jobId
    );
  },
  imageAnnotationBringToBack(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<{ uuid: string }>
  ) {
    const { uuid } = action.payload;
    const annotation = imageAnnotations.annotations.find(
      (anno) => anno.uuid === uuid
    );
    if (annotation) {
      imageAnnotations.annotations = imageAnnotations.annotations.filter(
        (anno) => anno.uuid !== uuid
      );
      imageAnnotations.annotations = [
        annotation,
        ...imageAnnotations.annotations,
      ];
    }
  },
  imageAnnotationBringToFront(
    { imageAnnotations }: ImageWorkspaceState,
    action: PayloadAction<{ uuid: string }>
  ) {
    const { uuid } = action.payload;
    const annotation = imageAnnotations.annotations.find(
      (anno) => anno.uuid === uuid
    );
    if (annotation) {
      imageAnnotations.annotations = imageAnnotations.annotations.filter(
        (anno) => anno.uuid !== uuid
      );
      imageAnnotations.annotations.push(annotation);
    }
  },
};
