/*
 * File: button-sort.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 13th July 2023 10:03:14 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import {
  VscChevronDown,
  VscFileSymlinkDirectory,
  VscFolderOpened,
  VscNewFolder,
} from "react-icons/vsc";
import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Option } from "domain/common";
import { Icon } from "iconsax-react";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";
import { useFileBrowseContext } from "../../context/file-browse.context";

export function ButtonMove() {
  const { selectedFileIds } = useFileBrowseContext();
  const { openModal, closeModal } = useModalContext();
  const [moveOptions] = useState<Option[]>([
    {
      label: "Move to a new folder",
      value: "move_to_new_folder",
      metadata: VscNewFolder,
    },
    {
      label: "Move to folder",
      value: "move_to_folder",
      metadata: VscFolderOpened,
    },
  ]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function selectOption(option: Option) {
    if (!option) return;
    if (option.value === "move_to_new_folder") {
      openModal(ModalTypes.MOVE_FILES_TO_NEW_FOLDER, {
        open: true,
        fileIds: selectedFileIds,
        onClose: (reload?: boolean) => {
          closeModal();
        },
      });
    } else if (option.value === "move_to_folder") {
      openModal(ModalTypes.MOVE_FILES_TO_FOLDER, {
        open: true,
        fileIds: selectedFileIds,
        onClose: (reload?: boolean) => {
          closeModal();
        },
      });
    }
    handleClose();
  }

  return (
    <div className="flex-none">
      <button
        className="px-3 py-1.5 flex items-center gap-1 rounded hover:bg-background-200"
        onClick={handleClick}
      >
        <VscFileSymlinkDirectory size={18} />
        <span className="hidden px-1 text-sm md:block">Move</span>
        <VscChevronDown size={16} />
      </button>

      <Menu
        id="move-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "sort-menu" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        getContentAnchorEl={null}
      >
        {moveOptions.map((option) => {
          const ItemIcon = option.metadata as Icon;
          return (
            <MenuItem key={option.value} onClick={() => selectOption(option)}>
              <div className="w-8 text-center">
                {option.metadata && <ItemIcon size={18} />}
              </div>
              <ListItemText className="pr-4">{option.label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

export default ButtonMove;
