/*
 * File: cornerstone.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";
import { RootState } from "store";

export const selectColorInvert = (state: RootState) => {
  return state.cornerstone.colorInvert;
};
export const selectHeartRuler = (state: RootState) => {
  return state.cornerstone.heartRuler;
};
export const selectFlipHorizontal = (state: RootState) => {
  return state.cornerstone.flipHorizontal;
};
export const selectFlipVertical = (state: RootState) => {
  return state.cornerstone.flipVertical;
};
export const selectFullScreen = (state: RootState) => {
  return state.cornerstone.fullScreen;
};
export const selectAnnotationVisible = (state: RootState) => {
  return state.cornerstone.annotationVisible;
};
export const selectRelationAnnotationVisible = (state: RootState) => {
  return state.cornerstone.relationAnnotationVisible;
};
export const selectAnnotationFill = (state: RootState) => {
  return state.cornerstone.annotationFill;
};

export const selectDisplayTextValue = (state: RootState) => {
  return state.cornerstone.displayTextValue;
};

export const selectIsSynchronizing = (state: RootState) => {
  return state.cornerstone.synchronize;
};

export const selectOtherTools = (state: RootState) => {
  return state.cornerstone.otherTools.map((t) => {
    if (t.type === ToolName.Invert) {
      return { ...t, active: !state.cornerstone.colorInvert };
    } else if (t.type === ToolName.FlipHorizontal) {
      return { ...t, active: state.cornerstone.flipHorizontal };
    } else if (t.type === ToolName.FlipVertical) {
      return { ...t, active: state.cornerstone.flipVertical };
    } else if (t.type === ToolName.HeartRuler) {
      return { ...t, active: state.cornerstone.heartRuler };
    } else if (t.type === ToolName.FullScreen) {
      return { ...t, active: state.cornerstone.fullScreen };
    }
    return t;
  });
};

export const selectBaseTools = (state: RootState) => {
  return state.cornerstone.baseTools.map((t) => {
    if (t.type === ToolName.Synchronizer) {
      return { ...t, active: state.cornerstone.synchronize };
    }
    if (t.type === ToolName.HeartRuler) {
      return { ...t, active: state.cornerstone.heartRuler };
    }
    return t;
  });
};
