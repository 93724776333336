import "./number-text-input.component.css";
import { useEffect, useMemo, useState } from "react";
import { classnames } from "utilities/classes";
import Select from "react-select";
import { Tooltip } from "@material-ui/core";
import DOMPurify from "dompurify";

const customSelectStyles = (height: string) => {
  return {
    control: (provided: any) => ({
      ...provided,
      height: height,
      fontFamily: "Mulish",
      boxShadow: "none",
      border: "none",
      backgroundColor: "#F3F7F9",
      padding: 0,
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      paddingLeft: 8,
      paddingRight: 0,
    }),
    container: (provided: any) => ({
      ...provided,
      paddingRight: 8,
      backgroundColor: "#F3F7F9",
      borderRadius: "0 4px 4px 0",
    }),
  };
};

export interface UnitOption {
  label: string;
  value: any;
}
export interface Props {
  label?: string;
  defaultValue?: number | string | undefined;
  unitOptions?: UnitOption[];
  selectedUnit?: UnitOption | undefined;
  onSelectedUnitChanged?: (v: UnitOption) => void;
  onValueChanged?: (v: number | string) => void;
  height?: string;
  disabled?: boolean;
  type?: string;
  min?: number | undefined;
  max?: number | undefined;
  selectTarget?: HTMLElement;
  displayToolTip?: boolean;
  listenChangedDefault?: boolean;
  borderClass?: string;
  borderColor?: string;
  placeholder?: string;
  autoFocus?: boolean;
  error?: string;
}

export const VBNumberTextInputComponent = ({
  label = "",
  defaultValue = undefined,
  unitOptions = [],
  selectedUnit = undefined,
  onSelectedUnitChanged,
  onValueChanged,
  height = "40px",
  disabled = false,
  type = "number",
  min = undefined,
  max = undefined,
  selectTarget,
  displayToolTip,
  listenChangedDefault,
  borderClass = "border",
  borderColor = "border-primary",
  placeholder = "",
  autoFocus = false,
  error = "",
}: Props) => {
  const [value, setValue] = useState<number | string | undefined>(defaultValue);
  const isNumberValue = useMemo(() => typeof value === "number", [value]);
  const [valueStr, setValueStr] = useState("");
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (listenChangedDefault) {
      setValue(defaultValue);
    }
  }, [defaultValue, listenChangedDefault]);

  useEffect(() => {
    const type = typeof value;
    if (type === "number") {
      if (isNaN(value as number) || value === undefined) {
        setValueStr("");
      } else {
        setValueStr((value as number).toString());
      }
    } else {
      setValueStr(value ? value.toString() : "");
    }
  }, [value]);

  const handleValueChanged = (e: any) => {
    if (disabled) return;
    const newValueStr = DOMPurify.sanitize(e.target.value as string);
    if (isNumberValue) {
      let numberValue = parseFloat(newValueStr);
      if (min !== undefined && numberValue < min) numberValue = min;
      if (max !== undefined && numberValue > max) numberValue = max;
      setValue(numberValue);
      onValueChanged && onValueChanged(numberValue);
    } else {
      setValue(newValueStr);
      onValueChanged && onValueChanged(newValueStr);
    }
  };

  return (
    <div className="flex flex-col w-full gap-2">
      {label && <div>{label}</div>}
      <div
        className={classnames(
          "flex rounded w-full",
          borderClass,
          { [borderColor]: isFocus && !!!error && !disabled },
          { "border-error-500": !!error },
          { "border-background-300": !!!error }
        )}
      >
        <Tooltip title={displayToolTip ? valueStr : ""} placement="top">
          <div className="flex-1 flex gap-1 items-center relative">
            <input
              className={classnames("flex-1 px-2 rounded truncate", {
                "pointer-events-none": disabled,
              })}
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                height: height,
                width: "100%",
              }}
              type={type}
              value={valueStr}
              onChange={handleValueChanged}
              onFocus={(_) => setIsFocus(true)}
              onBlur={(_) => setIsFocus(false)}
              placeholder={placeholder}
              autoFocus={autoFocus}
            />
            {!!error && (
              <span className="text-error-500 text-sm absolute -right-2 transform translate-x-full">
                {error}
              </span>
            )}
          </div>
        </Tooltip>
        {unitOptions.length > 0 && (
          <Select
            styles={customSelectStyles(height)}
            options={unitOptions}
            value={selectedUnit}
            onChange={(option: any) =>
              onSelectedUnitChanged &&
              onSelectedUnitChanged(option as UnitOption)
            }
            components={{
              IndicatorSeparator: () => null,
            }}
            menuPortalTarget={selectTarget}
            isDisabled={disabled}
          />
        )}
      </div>
    </div>
  );
};
