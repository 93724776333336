import { usePrevious } from "ahooks";
import { useAppDispatch } from "hooks/use-redux";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StorageService } from "services/storage";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { Sentence } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { useDatasetDetailContext } from "../../../dataset-detail.context";
import { extractFileDetail } from "./file-info-utils";
import * as Sentry from "@sentry/react";
import { FileInfoHeader } from "./file-info-header";

export interface TextFileInfo {
  sampleTokens: string[];
  numSentence: number;
  numToken: number;
}

interface FileInfoTextPanelProps {
  file: StorageFileDTO;
}
export const FileInfoTextPanel = ({ file }: FileInfoTextPanelProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [textInfo, setTextInfo] = useState<TextFileInfo>();
  const fileInfos = useMemo(() => extractFileDetail(file), [file]);
  const [errorGetTextInfo, setErrorGetTextInfo] = useState("");
  const { getTextInfosFromCache, putTextInfoToCache, toggleShowFileInfo } =
    useDatasetDetailContext();
  const previousFile = usePrevious(file);

  const getTextFileInfoCallBack = useCallback(async () => {
    if (!file) return;
    if (file.id === previousFile?.id) return;
    setTextInfo(undefined);
    setErrorGetTextInfo("");
    try {
      const textInfoCache =
        getTextInfosFromCache && getTextInfosFromCache(file.id);
      if (textInfoCache) {
        setTextInfo(textInfoCache as TextFileInfo);
        return;
      }

      const textFileInfo = await StorageService.getTextFileInfo(
        file.id.toString()
      );
      const data: {
        infoSentenceMap: { [key: string]: Sentence };
        numSentence: number;
        fileSize: number;
      } = textFileInfo.data.addition;
      const sentences: Sentence[] = [];
      if (data && data.infoSentenceMap) {
        for (let key of Object.keys(data.infoSentenceMap)) {
          sentences.push(data.infoSentenceMap[key]);
        }
      }

      let numSentence = sentences.length;
      let numToken = sentences.reduce((total, sentence) => {
        return total + sentence.sentence.split(" ").length;
      }, 0);
      let sampleTokens: string[] = [];
      for (let i = 0; i < Math.min(3, sentences.length); i++) {
        sentences[i].sentence.split(" ").forEach((token) => {
          if (sampleTokens.length < 100) {
            sampleTokens.push(token);
          }
        });
      }

      const newTextInfo = {
        numSentence,
        numToken,
        sampleTokens,
      };
      setTextInfo(newTextInfo);
      putTextInfoToCache && putTextInfoToCache(file.id, newTextInfo);
    } catch (error: any) {
      Sentry.captureException(error);
      setErrorGetTextInfo(error.message);
      dispatch(enqueueErrorNotification(error.message));
    }
  }, [file, dispatch, getTextInfosFromCache, putTextInfoToCache, previousFile]);

  useEffect(() => {
    if (!file) return;
    getTextFileInfoCallBack();
  }, [file, getTextFileInfoCallBack]);

  return (
    <div className="flex flex-col h-full p-4 overflow-auto bg-white border rounded border-gray">
      {file && (
        <div className="flex flex-col h-full gap-4">
          <FileInfoHeader
            fileName={file.fileName}
            onClick={toggleShowFileInfo}
          />
          {textInfo && (
            <div className="w-full p-3 overflow-x-hidden overflow-y-auto border max-height-3/6">
              {textInfo.sampleTokens.map((token, index) => (
                <span key={index}>{token} </span>
              ))}
            </div>
          )}
          {textInfo && (
            <>
              <p>
                {t("dataset:fileInfoTextPanel.numSentences", {
                  num: textInfo.numSentence,
                })}
              </p>
              <p>
                {t("dataset:fileInfoTextPanel.numToken", {
                  num: textInfo.numToken,
                })}
              </p>
            </>
          )}
          {errorGetTextInfo && (
            <p className="text-red-900">
              Error get text file info: {errorGetTextInfo}
            </p>
          )}
          <div>
            {fileInfos.map(({ id, label, value }) => {
              if (!value) return null;
              return (
                <div key={id} className="px-1 py-2">
                  <label className="block mb-1 text-sm text-gray-400">
                    {t(label)}
                  </label>
                  {id !== "createdDate" && (
                    <div className="overflow-hidden whitespace-normal">
                      {value}
                    </div>
                  )}
                  {id === "createdDate" && (
                    <div className="overflow-hidden whitespace-normal">
                      {t("common:formattedDate", { date: value })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};


