/*
 * File: batch-performance.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 20th August 2021 2:07:46 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useState } from "react";
import {
  DistributionTabs,
  DistributionView,
} from "./distribution-tabs.component";
import { LabelDistribution } from "./label-distribution/label-distribution.component";
import { LabelerDistribution } from "./labeler-distribution/labeler-distribution.component";

export const PerformanceTabContent = () => {
  const [view, setView] = useState(DistributionView.LABEL);
  return (
    <div className="p-4">
      <DistributionTabs selectedView={view} onChange={setView} />
      {view === DistributionView.LABELER && <LabelerDistribution />}
      {view === DistributionView.LABEL && <LabelDistribution />}
    </div>
  );
};
