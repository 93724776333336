/*
 * File: labeler-header.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 19th November 2021 4:07:44 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useClickAway } from "ahooks";
import classNames from "classnames";
import {
  IconArrowDown,
  IconLogoRounded,
  IconTicket,
  IconNotification,
} from "components/common/vb-icon.component";
import { UserAvatar } from "components/common/vb-user-avatar.component";
import { RouteData } from "configs/router.config";
import { useKeycloakContext } from "contexts/keycloak/keycloak.context";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";
import { useAppSelector } from "hooks/use-redux";
import { CreateWorkspaceDialog } from "pages/customer/workspace/components/create-workspace-dialog.component";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "routers/config/routes";
import {
  selectCustomerMenuRoutes,
  selectUserRole,
} from "store/auth/auth.selectors";
import { UserRole } from "store/auth/auth.state";
import GlobalCreate from "./global-create.component";
import WorkspaceSelect from "./workspace-select.component";
import { useDebouncedClickHandler } from "hooks/use-navigation-debounce";

interface Props {
  userName: string;
  hasNewNotifications?: boolean;
  openUserMenu?: boolean;
  onSelectNotification?(): void;
  onViewProfile?(): void;
  onSwitchRole?(): void;
  onLogout?(): void;
}

export const HeaderComponent = ({
  hasNewNotifications = false,
  openUserMenu = false,
  userName,
  onSelectNotification,
  onLogout,
  onViewProfile,
  onSwitchRole,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(openUserMenu);
  const useMenuContainerRef = useRef<HTMLDivElement>(null);
  const userMenuRef = useRef<HTMLButtonElement>(null);
  const currentRole = useAppSelector(selectUserRole);
  const { selectWorkspace } = useKeycloakContext();
  const { openModal } = useModalContext();
  const [showCreateWorkspace, setShowCreateWorkspace] = useState(false);
  const routes: RouteData[] = useAppSelector(selectCustomerMenuRoutes);

  const handleClickProfile = () => {
    setOpen(false);
    onViewProfile && onViewProfile();
  };
  function onClickCreateWorkspace() {
    setShowCreateWorkspace(true);
  }
  useClickAway(() => {
    if (open) setOpen(false);
  }, [useMenuContainerRef, userMenuRef]);

  const handleClickUserMenu = () => {
    setOpen(!open);
  };

  async function handleSelectWorkspace(workspaceId: string) {
    await selectWorkspace(workspaceId);
    window.location.replace(Routes.ROOT);
  }

  useEffect(() => {
    setOpen(openUserMenu);
  }, [openUserMenu]);

  const handleClickLogo = () => {
    history.push(Routes.ROOT);
  };

  return (
    <div className="relative flex-shrink-0 text-background-700">
      <div className="flex items-center w-full h-16 gap-8 px-6 bg-white">
        <div className="flex items-center justify-between gap-2">
          <button onClick={handleClickLogo}>
            <IconLogoRounded className="w-10 h-10" />
          </button>
          <WorkspaceSelect
            onCreateWorkspace={onClickCreateWorkspace}
            onSelectWorkspace={handleSelectWorkspace}
          />
          <div className="w-0.5 h-8 bg-background-300 rounded" />
        </div>

        {routes.map((route) => {
          return (
            <NavItem
              key={route.path}
              href={route.path}
              title={route.displayName || ""}
              active={location.pathname.startsWith(route.path)}
              icon={route.icon}
              activeIcon={route.activeIcon}
              {...route}
            />
          );
        })}
        <GlobalCreate />
        <div className="flex-auto h-12"></div>
        <div className="flex items-center flex-shrink-0 h-12 gap-4">
          <button
            className="relative flex items-center justify-center w-12 h-12"
            onClick={() => openModal(ModalTypes.TICKET_LIST)}
          >
            <IconTicket className="flex-none w-6 h-6" />
          </button>
          <button
            className="relative flex items-center justify-center w-12 h-12"
            onClick={onSelectNotification}
          >
            <IconNotification className="flex-none w-6 h-6" />
            {hasNewNotifications && (
              <span className="absolute flex items-center justify-center w-3 h-3 text-xs font-bold text-white border border-white rounded-full top-2.5 right-3 bg-error-500"></span>
            )}
          </button>
          <button
            className="flex items-center gap-2 h-9"
            onClick={handleClickUserMenu}
            ref={userMenuRef}
          >
            <UserAvatar className="text-sm w-9 h-9" name={userName} />
            <div
              className="hidden overflow-hidden font-bold capitalize md:block text-background-700 overflow-ellipsis whitespace-nowrap"
              style={{ maxWidth: "10rem" }}
            >
              {userName}
            </div>
            <div className="items-center justify-center hidden w-6 h-6 md:flex">
              <IconArrowDown className="w-6 h-6" />
            </div>
          </button>
        </div>
      </div>

      {open && (
        <div
          className="absolute mt-1 bg-white rounded shadow-lg top-full right-6 animate-fade-in-up"
          ref={useMenuContainerRef}
        >
          <div style={{ maxWidth: "20rem", minWidth: "10rem" }}>
            <div className="flex items-center px-4 py-3">
              <UserAvatar
                name={userName}
                className="flex-shrink-0 w-10 h-10 text-sm"
              />
              <div className="px-4 overflow-hidden font-bold capitalize overflow-ellipsis whitespace-nowrap">
                {userName}
              </div>
            </div>
            <hr />
            <div
              onClick={handleClickProfile}
              className="flex items-center gap-4 px-6 py-3 cursor-pointer hover:text-primary"
            >
              <i className="uir-user"></i>
              <span>{t("usermenu:buttonProfile")}</span>
            </div>
            <div className="border-b border-dashed"></div>
            <div
              onClick={onSwitchRole}
              className="flex items-center px-4 py-3 cursor-pointer hover:text-primary"
            >
              <i className="mx-2 uir-mode-portrait"></i>
              <span className="mx-2">
                {currentRole === UserRole.CUSTOMER
                  ? t("common:mode.labeler")
                  : t("common:mode.admin")}
              </span>
            </div>
            <div className="border-b border-dashed"></div>

            <div
              onClick={onLogout}
              className="flex items-center px-4 py-3 cursor-pointer hover:text-primary"
            >
              <i className="mx-2 uir-sign-out"></i>
              <span className="mx-2">{t("usermenu:buttonSignOut")}</span>
            </div>
          </div>
        </div>
      )}

      {showCreateWorkspace && (
        <CreateWorkspaceDialog
          open
          onClose={() => setShowCreateWorkspace(false)}
        />
      )}
    </div>
  );
};

interface NavItemProps {
  href: string;
  active?: boolean;
  title: string;
  activeIcon?: JSX.Element;
  icon?: JSX.Element;
  disabled?: boolean;
}

function NavItem({
  href,
  active,
  title,
  activeIcon,
  icon,
  disabled,
}: NavItemProps) {
  const handleItemClick = useDebouncedClickHandler(2, 2000);

  const handleClick = () => {
    handleItemClick(href, active);
  };

  if (disabled) return null;

  return (
    <button
      className="relative h-16 button-text-tertiary hover:text-primary"
      onClick={handleClick}
    >
      <span className="flex-none w-6 h-6 md:hidden">
        {!active && icon}
        {active && activeIcon}
      </span>
      <span
        className={classNames("hidden md:inline-block font-medium", {
          "text-primary": active,
        })}
      >
        {title}
      </span>
      {active && (
        <div className="absolute bottom-0 left-0 w-full h-0.5 bg-primary"></div>
      )}
    </button>
  );
}
