/*
 * File: vb-page-title.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 8th July 2021 3:48:43 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export const VBPageTitle = ({ text }: { text: string }) => {
  return <div className="text-lg font-bold text-primary">{text}</div>;
};
