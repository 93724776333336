/*
 * File: create-project-template.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 25th July 2022 2:58:49 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CreateProjectTemplateState,
  ProjectAdvancedData,
  ProjectInfoData,
  ProjectLabelData,
} from "./create-project-template.state";
import { CreateProjectTemplateContext } from "./create-project-template.context";
import useProjectTemplateTabs from "../hooks/use-project-template-tabs";
import { useHistory, useParams } from "react-router-dom";
import useProjectTemplate from "../hooks/use-project-template.hook";
import { ObservationDTO, StepType } from "services/label-service/dtos";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { VBMaskRequesting } from "components/common/vb-mask-requesting/vb-mask-requesting.component";
import { StorageService } from "services/storage";
import {
  ProjectLabelDTO,
  ProjectTemplateDTO,
  ProjectWorkflowDTO,
  ProjectWorkflowInstructionDTO,
} from "../service/project-template.dto";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { projectTemplateService } from "../service/project-template.service";
import { useWorkflow } from "hooks/workflow/use-workflow.hook";
import { StepAssignee, StepData } from "pages/customer/projects/project.type";
import { DEFAULT_AUTOSAVE_IN_SECOND } from "constants/setting.constant";
import * as Sentry from "@sentry/react";

interface Props {
  children: React.ReactNode | React.ReactNode[] | null;
}

export const CreateProjectTemplateProvider = ({ children }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [loading] = useState(true);
  const { workflows } = useWorkflow();
  const { tabs, activeTab, selectTab } = useProjectTemplateTabs();
  const { templateId } = useParams<{ templateId: string }>();
  const { template } = useProjectTemplate(parseInt(templateId));
  const [infoData, setInfoData] = useState<ProjectInfoData | null>(null);
  const [labelData, setLabelData] = useState<ProjectLabelData | null>(null);
  const [advancedData, setAdvancedData] = useState<ProjectAdvancedData | null>(
    null
  );
  const [isCreating, setIsCreating] = useState(false);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(
    null
  );

  const isValidInput = useMemo(() => {
    return !!infoData && !!labelData && !!advancedData;
  }, [infoData, labelData, advancedData]);
  const currentUser = useAppSelector(selectCurrentUser);

  // TODO: apply form to check dirty in the future
  const [defaultSteps, setDefaultSteps] = useState<StepData[]>([]);

  const workspaceId = useMemo(() => {
    if (!template) return;
    return template.dto?.workflowInfo.workspaceId;
  }, [template]);

  const getWorkflowInfoPayload = useCallback(
    (isDirty: boolean, data: ProjectInfoData | null) => {
      const selectedWorkflow = workflows.find(
        (workflow) => workflow.id === selectedWorkflowId
      );
      if (selectedWorkflow && selectedWorkflowId && !isDirty) {
        return { id: selectedWorkflowId };
      }
      let name = data
        ? `${data.name} workflow`
        : `${selectedWorkflow?.name} (New)` || "Default Workflow (New)";
      return {
        name: name,
        description: selectedWorkflow?.description || "",
      } as ProjectWorkflowDTO;
    },
    [selectedWorkflowId, workflows]
  );

  const getWorkInstructionInfoPayload = useCallback(
    (isDirty: boolean) => {
      if (!advancedData || !advancedData.steps || !isDirty) {
        return [];
      }
      return advancedData.steps.map(
        (step) =>
          ({
            canViewPreviousStepResult: step.canViewPreviousStepResult || false,
            condition: step.condition,
            name: step.name,
            description: step.description,
            step: step.position,
            stepType: step.type,
            roundNumber: step.numberOfRounds,
            scoreThreshold: step.scoreThreshold,
            workspaceId,
          } as ProjectWorkflowInstructionDTO)
      );
    },
    [advancedData, workspaceId]
  );

  const getWorkManagementInfoPayload = useCallback(() => {
    if (!advancedData || !advancedData.steps || !currentUser) {
      return [];
    }

    const workManagementInfo: StepAssignee[] = [];
    advancedData.steps.forEach((step: StepData) => {
      if (step.assignees && step.assignees.length > 0) {
        step.assignees.forEach((assignee) => {
          workManagementInfo.push(assignee);
        });
      } else {
        workManagementInfo.push({
          userId: currentUser.email,
          workInstructionStep: step.position,
        });
      }
    });
    return workManagementInfo;
  }, [advancedData, currentUser]);

  const isDirty = useMemo(() => {
    if (!advancedData) return false;
    const advandeSteps = advancedData.steps.map(
      ({ assignees, ...remainInfo }) => {
        return remainInfo;
      }
    );
    return JSON.stringify(advandeSteps) !== JSON.stringify(defaultSteps);
  }, [advancedData, defaultSteps]);

  async function createProject() {
    if (
      isCreating ||
      !template?.dto ||
      !infoData ||
      !labelData ||
      !advancedData ||
      !currentUser
    )
      return;
    let data: ProjectTemplateDTO = { ...template.dto };

    try {
      setIsCreating(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // 1. upload file if needed
      if (infoData.file) {
        const url = await StorageService.uploadFile(infoData.file);
        data.projectInfo.thumbnailUrl = url;
      } else {
        data.projectInfo.thumbnailUrl = data.thumbnailUrl;
      }
      if (infoData.name) data.projectInfo.name = infoData.name;
      if (infoData.description)
        data.projectInfo.description = infoData.description;
      if (infoData.instruction)
        data.projectInfo.instruction = infoData.instruction;
      data.projectInfo.settings.autoSaveInSecond = DEFAULT_AUTOSAVE_IN_SECOND;

      data.observationInfo = labelData.labels as ProjectLabelDTO[];

      data.workflowInfo = getWorkflowInfoPayload(isDirty, infoData);
      data.workInstructionInfo = getWorkInstructionInfoPayload(isDirty);
      data.workManagementInfo = getWorkManagementInfoPayload();
      const response = await projectTemplateService.createProjectUsingTemplate(
        data
      );
      setIsCreating(false);
      dispatch(enqueueSuccessNotification("Success!"));
      if (response.data?.project?.id) {
        history.push(`/projects/${response.data?.project?.id}`);
      }
    } catch (error: any) {
      Sentry.captureException(error);
      setIsCreating(false);
      const errMessage = error.message || "Failed to create the project";
      dispatch(enqueueErrorNotification(errMessage));
    }
  }

  useEffect(() => {
    if (!template) return;
    setInfoData({
      name: template.title || "",
      thumbnailUrl: template.imageUrl || "",
      description: template.description || "",
      instruction: "",
      pdfLink: "",
      externalLink: "",
    });

    if (template.dto) {
      const observations: any = template.dto.observationInfo;
      if (observations && (observations as Partial<ObservationDTO>[])) {
        const bindObs = observations.map(
          (obs: Partial<ObservationDTO>, index: number) => {
            return {
              ...obs,
              id: index,
              attributes: obs.attributes
                ? obs.attributes.map((att) => ({
                    ...att,
                    values: att.values || [],
                    defaultValue: "",
                    ranges: [],
                    required: false,
                  }))
                : [],
            };
          }
        );
        setLabelData({ labels: bindObs });
      }

      const defaultSteps = template.dto.workInstructionInfo.map(
        (instruction) => {
          return {
            position: instruction.step,
            type: instruction.step > 1 ? StepType.ACCEPTANCE : StepType.NORMAL,
            numberOfRounds: instruction.roundNumber,
            readonly: instruction.step > 1,
            name: instruction.name,
            description: instruction.description || "",
            canViewPreviousStepResult:
              instruction.canViewPreviousStepResult || false,
            condition: "none",
            scoreThreshold: instruction.scoreThreshold || 0
          };
        }
      );
      setSelectedWorkflowId(template.dto.workflowInfo.id);
      setDefaultSteps(defaultSteps);
      setAdvancedData({ steps: defaultSteps });
    }
  }, [template]);

  const steps = useMemo(() => {
    return advancedData?.steps || [];
  }, [advancedData?.steps]);

  const value: CreateProjectTemplateState = {
    loading,
    template,
    isValidInput,
    isCreating,
    tabs,
    activeTab,
    selectTab,
    infoData,
    labelData,
    advancedData,
    steps,
    onProjectInfoChanged: setInfoData,
    onProjectLabelChanged: setLabelData,
    onProjectAdvancedChanged: setAdvancedData,
    createProject,
    selectedWorkflowId,
    setSelectedWorkflowId,
    setDefaultSteps,
    isDirty,
  };

  return (
    <CreateProjectTemplateContext.Provider value={value}>
      {children}
      {isCreating && <VBMaskRequesting />}
    </CreateProjectTemplateContext.Provider>
  );
};
