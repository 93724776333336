import { createSlice } from "@reduxjs/toolkit";
import { imageAnnotationsActions } from "./image-annotations/image-annotations.actions";
import { imageAnnotationsReducerBuilder } from "./image-annotations/image-annotations.thunk";
import { imageIouActions } from "./image-iou/image-iou.actions";
import { imageIouReducerBuilder } from "./image-iou/image-iou.thunk";
import { imageLabelingActions } from "./image-labeling/image-labeling.actions";
import { imageLabelingReducerBuilder } from "./image-labeling/image-labeling.thunk";
import { imageReviewActions } from "./image-review/image-review.actions";
import { imageReviewReducerBuilder } from "./image-review/image-review.thunk";
import {
  defaultImageWorkspaceState,
  ImageWorkspaceState,
} from "./image-workspace.state";

const SLICE_NAME: string = "imageWorkspace";
const slice = createSlice({
  name: SLICE_NAME,
  initialState: defaultImageWorkspaceState,
  reducers: {
    resetImageWorkspaceState(state: ImageWorkspaceState) {
      Object.assign(state, defaultImageWorkspaceState);
    },
    ...imageLabelingActions,
    ...imageReviewActions,
    ...imageAnnotationsActions,
    ...imageIouActions,
  },
  extraReducers: (builder) => {
    imageLabelingReducerBuilder(builder);
    imageReviewReducerBuilder(builder);
    imageAnnotationsReducerBuilder(builder);
    imageIouReducerBuilder(builder);
  },
});

export const {
  resetImageWorkspaceState,
  // Image Labeling Actions
  resetImageLabelingState,
  ocrAnnotationSelected,
  setWorkingFile,
  setSelectedSystemObservationId,
  setSelectedLabels,
  autoAnnotatedLabels,
  setFindingLabelerSelected,
  setSelectedObservationId,
  labelIndexSelected,
  observationAnnotationSelected,
  toggleObservationVisible,
  setObservationVisible,
  setObservationSelected,
  fetchNewJob,
  setJobObservationSelected,
  setViewMediaAgreement,
  resetSelectedUsers,
  setImageLabelingError,
  annotationAdded,
  allAnnotationsRemoved,
  // Image Review Actions
  resetImageReviewState,
  reopenDialogClosed,
  // Image Annotations
  resetImageAnnotationsState,
  imageAnnotationsLoaded,
  imageAnnotationsAdded,
  imageRelationAnnotationCompleted,
  imageRelationAnnotationUpdated,
  imageRelationAnnotationRemoved,
  imageRelationAnnotationsLoaded,
  imageAnnotationCompleted,
  imageAnnotationUpdated,
  imageAnnotationRemoved,
  imageAnnotationSelected,
  imageAnnotationAttributeMenuOpened,
  annotationAttributeMenuClosed,
  imageAnnotationContextMenuOpened,
  imageAnnotationContextMenuClosed,
  imageAnnotationClassMenuOpened,
  imageAnnotationClassMenuClosed,
  imageAnnotationClassChanged,
  imageAnnotationAttributeUpdated,
  imageAnnotationJobCompleted,
  imageLabelAnnotationsRemoved,
  imageAnnotationBringToBack,
  imageAnnotationBringToFront,
  annotateLabelActivated,
  removeAllAnnotations,
  setImageAnnotationForLabelIdFromAttributeValue,
  invalidPolygonModalClosed,
  setActiveAnnotations,
  updateActiveAnnotation,
  // Image Iou
  setImageIouAnnotations,
  setImageIouActiveScore,
  observationUpdated
} = slice.actions;

export const imageWorkspaceReducer = slice.reducer;
