/*
 * File: predict-on-image.dto.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 25th February 2022 9:44:04 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AnnotateType } from "constants/annotation.constant";
import { Point } from "utilities/math/point";

export interface PredictOnImageResponseDTO {
  succeeded: boolean;
  payload: AnnotationOnImage[];
}

export interface AnnotationOnImage {
  className: string;
  classId: number;
  annotations: { type: AnnotateType; points: Point[] }[];
}

export type PredictOnImageAIResponseDTO = {
  className: string;
  class_id: number;
  score: number;
  type: string;
  points: Point[];
}[];

export const predictOnImageResponseMapper = (
  data: PredictOnImageAIResponseDTO
): PredictOnImageResponseDTO => {
  let annotationOnImages: AnnotationOnImage[] = [];
  for (let item of data) {
    const { class_id: classId } = item;
    let annotationOnImage = annotationOnImages.find(
      (anno) => anno.classId === classId
    );
    if (!annotationOnImage) {
      annotationOnImage = {
        classId,
        className: item.className,
        annotations: [],
      };
      annotationOnImages.push(annotationOnImage);
    }
    annotationOnImage.annotations.push({
      type: item.type as AnnotateType,
      points: item.points,
    });
  }
  return {
    succeeded: true,
    payload: annotationOnImages,
  };
};
