import { KeyboardKey } from "./keyboard-keys";


export class InputManager {

  keysDown: Record<string, boolean>;

  constructor() {
    this.keysDown = {};
  }

  setKeyDown(key: string) {
    this.keysDown[key] = true;
  }

  setKeyUp(key: string) {
    this.keysDown[key] = false;
  }

  reset() {
    this.keysDown = {};
  }

  isKeysDown(...keys: string[]) {
    for (const key of keys) {
      if (!this.keysDown[key]) {
        return false;
      }
    }
    return true;
  }

  isSpaceDown() {
    return this.isKeysDown(KeyboardKey.Space);
  }

  isActionSaveDown() {
    return this.isKeysDown(KeyboardKey.Control, KeyboardKey.S) ||
           this.isKeysDown(KeyboardKey.Meta, KeyboardKey.S);
  }

  isActionSubmitDown() {
    return this.isKeysDown(KeyboardKey.Control, KeyboardKey.Enter) ||
           this.isKeysDown(KeyboardKey.Meta, KeyboardKey.Enter);
  }
}

export const inputManager = new InputManager();