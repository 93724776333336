/*
 * File: remove-file-metadata.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 12th June 2023 9:47:07 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { QueryApi } from "data-access/impl/query";
import { StorageApi } from "data-access/impl/storage";
import { useAppDispatch } from "hooks/use-redux";
import { useState } from "react";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";

interface Props {
  files: QueryApi.IFile[];
  metadatas: QueryApi.IMetadata[];
  open: boolean;
  onClose: (reload?: boolean) => void;
}

export function RemoveFileMetadataModal({
  files,
  open,
  metadatas,
  onClose,
}: Props) {
  const dispatch = useAppDispatch();
  const [requesting, setRequesting] = useState(false);

  async function handleSubmit() {
    try {
      setRequesting(true);
      const action = "DELETE";
      const payload: StorageApi.IManageFileMetadataPayload[] = [];
      for (const file of files) {
        const fileId = file.id;
        for (const metadata of metadatas) {
          if (!metadata.key_id || !metadata.value_id) continue;
          const metadataKeyId = parseInt(metadata.key_id);
          const metadataValueId = parseInt(metadata.value_id);
          const item = { fileId, action, metadataKeyId, metadataValueId };
          payload.push(item);
        }
      }
      await StorageApi.manageFileMetadatas(payload);
      setRequesting(false);
      dispatch(enqueueSuccessNotification("Successed"));
      onClose(true);
    } catch (error) {
      dispatch(enqueueErrorNotification("Failed"));
      setRequesting(false);
    }
  }
  return (
    <VBModal
      title="Remove metadata"
      open={open}
      onClose={onClose}
      textSubmit="Apply"
      onSubmit={handleSubmit}
      disableSubmit={requesting}
      processingIndicator={requesting}
      blockUI={requesting}
    >
      <div>Are you sure you want to remove the metadata?</div>
    </VBModal>
  );
}

export default RemoveFileMetadataModal;
