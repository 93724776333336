/*
 * File: save-text-job.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 12th July 2022 5:14:51 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { STEP_REVIEW_OBSERVATION_CODES } from "services/label-service/dtos";
import { RootState } from "store";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { LabelingBatchState } from "../text-labeling-batch.state";
import i18n from "i18n";

const THUNK_NAME = "textLabelingBatch/validateTextJobAsync";

interface ValidateTextJobPayload {
  jobId: number;
}
export const validateTextJobAsync = createAsyncThunk(
  THUNK_NAME,
  async ({ jobId }: ValidateTextJobPayload, { getState }) => {
    const state = getState() as RootState;
    const { allIds } = state.textLabelingBatch.jobs;
    const isValidJob = jobId && allIds.includes(jobId);
    if (!isValidJob) return { message: "Invalid Job ID: " + jobId };
    const { observations, relations, selectedSystemObservationId } =
      state.textWorkspace.textEditor;
    const { labels } = state.textWorkspace.textLabeling;
    const systemLabel = labels.find(
      (lb) =>
        lb.observation.id === selectedSystemObservationId && lb.isSystemLabel
    );

    const mode = state.textWorkspace.textLabeling.mode;
    if (mode === TextLabelingMode.ACCEPTANCE) {
      if (
        !systemLabel ||
        !STEP_REVIEW_OBSERVATION_CODES.includes(systemLabel.observation.code)
      ) {
        const errMessage = i18n.t("error:labeling.requireReviewLabel");
        return {
          message: errMessage,
        };
      }

      return;
    }

    const hasAnnotations = observations.length > 0 || relations.length > 0;
    const hasSystemAnnotations = !!systemLabel;
    if (!hasAnnotations && !hasSystemAnnotations) {
      return { message: "At least one annotation is required" };
    }
  }
);

export function validateTextJobBuilder(
  builder: ActionReducerMapBuilder<LabelingBatchState>
) {
  return builder.addCase(validateTextJobAsync.fulfilled, (state, action) => {});
}
