import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "routers/config/routes";
import {
  selectComplexJobsBatchJobs,
  selectComplexJobsCurrentBatchJob,
  selectComplexJobsError,
} from "store/labeler/complex-jobs/complex-jobs.selectors";
import { resetComplexJobs } from "store/labeler/complex-jobs/complex-jobs.slice";
import { pollComplexBatchJobsAsync } from "store/labeler/complex-jobs/complex-jobs.thunk";
import { ErrorDialog } from "../batch-labeling/components/error-dialog.component";
import { WorkspaceEmpty } from "../image-labeling/components/workspace-empty/workspace-empty.component";
import { PathologyLabelingProvider } from "./context/pathology-labeling.provider";
import { PathologyLabelingDetails } from "./pathology-labeling.details";

export const PathologyLabelingPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { projectId } = useParams<{ projectId: string }>();
  const batchError = useAppSelector(selectComplexJobsError);
  const currentJobInBatch = useAppSelector(selectComplexJobsCurrentBatchJob);
  const batchJobs = useAppSelector(selectComplexJobsBatchJobs);

  const handleErrorDialogClose = () => {
    history.push(Routes.LABELER_HOME);
  };

  useEffect(() => {
    const shouldLoad = batchJobs.length === 0 && projectId;

    if (!shouldLoad) return;

    dispatch(
      pollComplexBatchJobsAsync({
        projectId,
        limit: 10,
        loadBatchObservation: true,
      })
    );
  }, [batchJobs, dispatch, projectId]);

  useEffect(() => {
    return () => {
      dispatch(resetComplexJobs());
    };
  }, [dispatch]);

  if (batchError) {
    const errorType = batchError.type;
    if (errorType === "no_job") return <WorkspaceEmpty />;
    if (errorType === "unknow")
      return (
        <ErrorDialog
          visible
          onClose={handleErrorDialogClose}
          message={batchError.message}
        />
      );
  }

  return (
    <div className="w-full h-screen">
      <PathologyLabelingProvider isLoadFromBatch jobInBatch={currentJobInBatch}>
        <PathologyLabelingDetails showJobNav />
      </PathologyLabelingProvider>
    </div>
  );
};
