/*
 * File: preview-annotations.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 31st August 2022 10:25:19 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AnnotateType } from "constants/annotation.constant";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  BatchObservationDTO,
  TaskAnnotationDTO,
  TaskDataDTO,
} from "services/label-service/dtos";
import { Rectangle } from "utilities/math/rectangle";

interface Props {
  data?: TaskDataDTO;
  labels: BatchObservationDTO[];
}
export default function PreviewAnnotationsComponent({ data, labels }: Props) {
  const annotations = useMemo(() => {
    return (
      data?.annotations?.map((anno) => {
        let color = anno.color;
        const label = labels.find(
          (label) => label.observation.id === anno.labelId
        );
        if (label) color = label.observation.observationSetting.color;
        if (anno.source && anno.source === "Predict") color = "#00FF00";
        return { ...anno, color };
      }) || []
    );
  }, [data, labels]);
  if (!data || !data.image) return null;
  return (
    <svg height="100%" viewBox={`0 0 ${data.image.width} ${data.image.height}`}>
      {annotations
        .filter((anno) => !anno.hidden)
        .map((anno, index) => {
          return <PreviewAnnotation annotation={anno} key={anno.id + index} />;
        })}
      {/* {(relations || []).map((relation) => {
        return (
          <PreviewRelation
            relation={relation}
            points={relation.annotationData.measurementIds.map(
              (measurementId) => {
                const anno = collection[measurementId];
                const bbox = annotationUtils.annotationBoundingBox(anno);
                return {
                  x: bbox.x + bbox.width / 2,
                  y: bbox.y + bbox.height / 2,
                };
              }
            )}
            key={relation.id}
          />
        );
      })} */}
    </svg>
  );
}

function PreviewAnnotation({ annotation }: { annotation: TaskAnnotationDTO }) {
  const annotateType = annotation.type;

  const isBBox = annotateType === AnnotateType.BOUNDING_BOX;
  if (isBBox) {
    const [start, end] = annotation.points;
    const x = Math.min(start.x, end.x);
    const y = Math.min(start.y, end.y);
    const width = Math.abs(end.x - start.x);
    const height = Math.abs(end.y - start.y);

    return (
      <rect
        key={annotation.id}
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: annotation.color,
          fillOpacity: 0.4,
          stroke: annotation.color,
          strokeWidth: 2,
        }}
      />
    );
  }

  const isPolygon = annotateType === AnnotateType.POLYGON;
  if (isPolygon) {
    return (
      <polygon
        points={annotation.points.map((p) => `${p.x},${p.y}`).join(" ")}
        style={{
          fill: annotation.color,
          fillOpacity: 0.4,
          stroke: annotation.color,
          strokeWidth: 2,
        }}
      />
    );
  }

  const isSystem = annotateType === AnnotateType.SYSTEM;

  if (isSystem) {
    return <ClassificationPreview annotation={annotation} />;
  }

  return null;
}

function ClassificationPreview({
  annotation,
}: {
  annotation: TaskAnnotationDTO;
}) {
  const textRef = useRef<SVGTextElement>(null);
  const [bbox, setBBox] = useState<Rectangle | undefined>(undefined);
  useEffect(() => {
    const bbox = textRef.current?.getBBox();
    if (!bbox) return;
    setBBox({
      x: bbox.x - 16,
      y: bbox.y - 8,
      width: bbox.width + 32,
      height: bbox.height + 16,
    });
  }, [textRef]);
  return (
    <>
      {bbox && (
        <rect
          x={bbox.x}
          y={bbox.y}
          width={bbox.width}
          height={bbox.height}
          fill="red"
          rx={6}
          ry={6}
        />
      )}
      <text ref={textRef} x={32} y={32} fill="white">
        {annotation.label}
      </text>
    </>
  );
}

// function PreviewRelation({
//   relation,
//   points,
// }: {
//   relation: RelationAnnotation;
//   points: Point[];
// }) {
//   return (
//     <polyline
//       key={relation.uuid}
//       points={points.map((p) => `${p.x},${p.y}`).join(" ")}
//       style={{
//         fill: "none",
//         stroke: "#ff00ff",
//         strokeWidth: 4,
//       }}
//     />
//   );
// }
