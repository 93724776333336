/*
 * File: query.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 10:10:06 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { ENV_CONFIG } from "configs/env.config";
import {
  SearchApi,
  Configuration,
  FileInfoCriteria,
  FileInfoAggregatedCriteria,
  FileInfoResponseDTO,
  IssueResponseDTO,
  AnnotationResponseDTO,
  AnnotationRelationResponseDTO,
  TaskResponseDTO,
  JobResponseDTO,
  FileInfoAggregatedResponseDTO,
  FileInfoCriteriaDatasetCategoryEnum,
} from "data-access/generated/query";
import {
  Entity,
  Filter,
  IPagination,
  IPaginationResponse,
} from "domain/common";
import { AuthService } from "services/auth";
import { BaseFilter } from "./types";

export module QueryApi {
  function getConfiguration() {
    const Scope = AuthService.getUserScope();
    const Workspace = AuthService.getUserWorkspace();
    const Authorization = `Bearer ${AuthService.getAccessToken()}`;
    return new Configuration({
      basePath: ENV_CONFIG.QUERY_SERVICE_URL,
      baseOptions: { headers: { Authorization, Scope, Workspace } },
    });
  }

  export class FileFilter extends BaseFilter {
    setDatasetId(datasetId?: number) {
      return this.setQuery("datasetId", datasetId?.toString());
    }
    setIds(fileIds?: number[]) {
      return this.setQuery("ids", fileIds?.join(","));
    }
    setLabelIds(labelIds?: number[]) {
      return this.setQuery("labelIds", labelIds?.join(","));
    }
    setFileName(fileName?: string) {
      return this.setQuery("fileName", fileName);
    }
    setLabelAttribute(labelAttribute?: string) {
      return this.setQuery("labelAttribute", labelAttribute);
    }
    setDataType(dataType?: string) {
      return this.setQuery("dataType", dataType);
    }
    setCreatedDate(createdDate?: string) {
      return this.setQuery("createdDate", createdDate);
    }
    setCreatedBy(createdBy?: string) {
      return this.setQuery("createdBy", createdBy);
    }
    setMetadataKey(metadataKey?: string) {
      return this.setQuery("metadataKey", metadataKey);
    }
    setMetadataValue(metadataValue?: string) {
      return this.setQuery("metadataValue", metadataValue);
    }
    setStoreType(storeType?: string) {
      return this.setQuery("storeType", storeType);
    }
    setScopeId(scopeId: string) {
      return this.setQuery("scopeId", scopeId);
    }

    getDatasetId() {
      return this.getNumberField("datasetId");
    }
    getIds() {
      return this.getArrayNumberField("ids");
    }
    getFileName() {
      return this.getStringField("fileName");
    }
    getLabelIds() {
      return this.getArrayNumberField("labelIds");
    }
    getLabelAttribute() {
      return this.getStringField("labelAttribute");
    }
    getDataType() {
      return this.getStringField("dataType");
    }
    getCreatedDate() {
      return this.getNumberField("createdDate");
    }
    getCreatedBy() {
      return this.getStringField("createdBy");
    }
    getMetadataKey() {
      return this.getStringField("metadataKey");
    }
    getMetadataValue() {
      return this.getStringField("metadataValue");
    }
    getStoreType() {
      return this.getStringField("storeType");
    }
    getScopeId() {
      return this.getStringField("scopeId");
    }

    toCriteria(): FileInfoCriteria {
      let datasetCategory = undefined;
      let metadata = undefined;
      try {
        const dataType = this.getDataType();
        if (dataType) {
          datasetCategory = dataType as FileInfoCriteriaDatasetCategoryEnum;
        }

        const metadataQueries = (this.queries ?? [])
          .filter((q) => q.field.startsWith("metadata"))
          .map((query) => {
            const key = query.field.replace("metadata.", "").trim();
            const value = query.value ?? "";
            return { key, value };
          })
          .filter((item) => !!item.value);
        metadata = metadataQueries.length === 0 ? undefined : metadataQueries;
      } catch (error) {}

      const criteria: FileInfoCriteria = {
        datasetId: this.getDatasetId(),
        ids: this.getIds(),
        fileName: this.getFileName(),
        createdDate: this.getCreatedDate(),
        createdBy: this.getCreatedBy(),
        storeType: this.getStoreType(),
        scopeId: this.getScopeId(),
        observationId: this.getLabelIds(),
        datasetCategory: datasetCategory,
        metadata: metadata,
      };
      return criteria;
    }
  }

  export class FileAggreegatedFilter extends BaseFilter {
    setBatchId(batchId?: number) {
      return this.setQuery("batchId", batchId?.toString());
    }
    setTaskId(taskId?: number) {
      return this.setQuery("taskId", taskId?.toString());
    }
    setFileName(fileName?: string) {
      return this.setQuery("fileName", fileName?.toString());
    }
    setLabelingStatus(labelingStatus?: string) {
      return this.setQuery("labelingStatus", labelingStatus?.toString());
    }
    setMetadataKey(metadataKey?: string) {
      return this.setQuery("metadataKey", metadataKey?.toString());
    }
    setMetadataValue(metadataValue?: string) {
      return this.setQuery("metadataValue", metadataValue?.toString());
    }
    setLabelers(labelers?: Array<string>) {
      return this.setQuery("labelers", labelers?.join(",").toString());
    }
    setDatasourceName(datasourceName?: string) {
      return this.setQuery("datasourceName", datasourceName?.toString());
    }
    setObservationId(observationId?: number) {
      return this.setQuery("observationId", observationId?.toString());
    }
    setCreatedDate(createdDate?: number) {
      return this.setQuery("createdDate", createdDate?.toString());
    }
    setIssueStatus(issueStatus?: string) {
      return this.setQuery("issueStatus", issueStatus?.toString());
    }

    getBatchId(): number | undefined {
      return this.getNumberField("batchId");
    }
    getTaskId(): number | undefined {
      return this.getNumberField("taskId");
    }
    getFileName(): string | undefined {
      return this.getStringField("fileName");
    }
    getLabelingStatus(): string | undefined {
      return this.getStringField("labelingStatus");
    }
    getMetadataKey(): string | undefined {
      return this.getStringField("metadataKey");
    }
    getMetadataValue(): string | undefined {
      return this.getStringField("metadataValue");
    }
    getLabelers(): Array<string> | undefined {
      return this.getArrayStringField("labelers");
    }
    getDatasourceName(): string | undefined {
      return this.getStringField("datasourceName");
    }
    getObservationId(): number | undefined {
      return this.getNumberField("observationId");
    }
    getCreatedDate(): number | undefined {
      return this.getNumberField("createdDate");
    }
    getIssueStatus(): string | undefined {
      return this.getStringField("issueStatus");
    }

    toCriteria(): FileInfoAggregatedCriteria {
      return {
        batchId: this.getBatchId() ?? -1,
        taskId: this.getTaskId(),
        fileName: this.getFileName(),
        labelingStatus: this.getLabelingStatus(),
        metadataKey: this.getMetadataKey(),
        metadataValue: this.getMetadataValue(),
        labelers: this.getLabelers(),
        datasourceName: this.getDatasourceName(),
        observationId: this.getObservationId(),
        createdDate: this.getCreatedDate(),
        issueStatus: this.getIssueStatus(),
      };
    }
  }

  export interface IFile extends Entity {
    id: number;
    fileName: string;
    status?: string;
    hash?: string;
    url?: string;
    fileSize?: number;
    processStatus?: string;
    parentId?: number;
    storeType?: string;
    createdBy?: string;
    createdDate?: string;
    metadata?: Array<IMetadata>;
    referenceUrl?: string;
    additionalFileInfo?: IExtraMetadata;
  }

  export interface IMetadata {
    id?: number;
    key_id?: string;
    key_name?: string;
    key_code?: string;
    value_id?: string;
    value_name?: string;
    value_code?: string;
    deleted?: boolean;
  }

  export interface IExtraMetadata {
    id?: number;
    fileInfoId?: number;
    extend?: string;
    contentType?: string;
    addition?: { [key: string]: any | undefined };
    deleted?: boolean;
  }

  export interface IFileMapper {
    fromDTO(dto: FileInfoResponseDTO): IFile;
  }
  export class FileMapper implements IFileMapper {
    fromDTO(dto: FileInfoResponseDTO): IFile {
      if (!dto.id) throw new Error("Invalid DTO Object");
      return {
        id: dto.id,
        fileName: dto.fileName ?? "",
        status: dto.status,
        hash: dto.hash,
        url: dto.url,
        fileSize: dto.fileSize,
        processStatus: dto.processStatus,
        parentId: dto.parentId,
        storeType: dto.storeType,
        createdBy: dto.createdBy,
        createdDate: dto.createdDate,
        metadata: dto.metadata,
        referenceUrl: dto.referenceUrl,
        additionalFileInfo: dto.additionalFileInfo,
      };
    }
  }

  export async function searchFileInfo(
    filter: Filter
  ): Promise<IPaginationResponse<IFile>> {
    const fileFilter = new FileFilter(filter);
    const fileInfoCriteria = fileFilter.toCriteria();
    const pageable = fileFilter.toPageable();
    const configuration = getConfiguration();
    const api = new SearchApi(configuration);
    const response = await api.searchFileInfoUsingPost({
      fileInfoCriteria,
      pageable,
    });
    const mapper = new FileMapper();
    const items = (response.data.payload ?? []).map(mapper.fromDTO);
    const pagination: IPagination = {
      page: response.data.pagination?.pageNumber ?? filter.page,
      size: response.data.pagination?.pageSize ?? filter.size,
      totalCount: response.data.pagination?.totalItem ?? 0,
    };
    return { data: items, pagination };
  }

  export interface IFileData extends Entity {
    id: number;
    fileName: string;
    status?: string;
    url?: string;
    processStatus?: string;
    createdBy?: string;
    createdDate?: string;
    deleted?: boolean;
    referenceUrl?: string;
    metadata?: Array<IMetadata>;
    additionalFileInfo?: IExtraMetadata;
    issues?: Array<IIssue>;
    annotations?: Array<IAnnotation>;
    annotationRelations?: Array<IAnnotationRelation>;
    tasks?: Array<ITask>;
    jobs?: Array<IJob>;
  }

  export interface IIssue extends Entity {
    id: number;
    status?: string;
    taskId?: string;
    jobId?: string;
    description?: string;
    data?: string;
    referenceId?: string;
    deleted?: boolean;
  }
  export interface IIssueMapper {
    fromDTO(dto: IssueResponseDTO): IIssue;
  }
  export class IssueMapper implements IIssueMapper {
    fromDTO(dto: IssueResponseDTO): IIssue {
      if (!dto.id) throw new Error("Invalid DTO Object");
      return {
        id: dto.id,
        status: dto.status,
        taskId: dto.taskId,
        jobId: dto.jobId,
        description: dto.description,
        data: dto.data,
        referenceId: dto.referenceId,
        deleted: dto.deleted,
      };
    }
  }

  export interface IAnnotation extends Entity {
    id: number;
    latest?: boolean;
    mediaId?: number;
    categoryId?: number;
    batchId?: number;
    assignee?: string;
    jobId?: number;
    taskObservationId?: number;
    annotationType?: string;
    annotation?: { [key: string]: any | undefined };
    source?: string;
    createdBy?: string;
    createdDate?: string;
    conflictInfos?: Array<{ [key: string]: any | undefined }>;
    deleted?: boolean;
  }

  export interface IAnnotationMapper {
    fromDTO(dto: AnnotationResponseDTO): IAnnotation;
  }
  export class AnnotationMapper implements IAnnotationMapper {
    fromDTO(dto: AnnotationResponseDTO): IAnnotation {
      if (!dto.id) throw new Error("Invalid DTO Object");
      return {
        id: dto.id,
        latest: dto.latest,
        mediaId: dto.mediaId,
        categoryId: dto.categoryId,
        batchId: dto.batchId,
        assignee: dto.assignee,
        jobId: dto.jobId,
        taskObservationId: dto.taskObservationId,
        annotationType: dto.annotationType,
        annotation: dto.annotation,
        source: dto.source,
        createdBy: dto.createdBy,
        createdDate: dto.createdDate,
        conflictInfos: dto.conflictInfos,
        deleted: dto.deleted,
      };
    }
  }

  export interface IAnnotationRelation extends Entity {
    id: number;
    directed?: boolean;
    first?: number;
    second?: number;
    localGroup?: number;
    deleted?: boolean;
  }
  export interface IAnnotationRelationMapper {
    fromDTO(dto: AnnotationRelationResponseDTO): IAnnotationRelation;
  }
  export class AnnotationRelationMapper implements IAnnotationRelationMapper {
    fromDTO(dto: AnnotationRelationResponseDTO): IAnnotationRelation {
      if (!dto.id) throw new Error("Invalid DTO Object");
      return {
        id: dto.id,
        directed: dto.directed,
        first: dto.first,
        second: dto.second,
        localGroup: dto.localGroup,
        deleted: dto.deleted,
      };
    }
  }

  export interface ITask extends Entity {
    id: number;
    fileName?: string;
    taskReference?: string;
    status?: string;
    batchId?: number;
    reviewStatus?: string;
    issueStatus?: string;
    createdBy?: string;
    createdDate?: string;
    assignee?: Array<string>;
    issueStat?: ITaskIssueStatistic;
    deleted?: boolean;
  }

  export interface ITaskIssueStatistic {
    opened?: number;
    fixed?: number;
    resolved?: number;
  }

  export interface ITaskMapper {
    fromDTO(dto: TaskResponseDTO): ITask;
  }
  export class TaskMapper implements ITaskMapper {
    fromDTO(dto: TaskResponseDTO): ITask {
      if (!dto.id) throw new Error("Invalid DTO Object");
      return {
        id: dto.id,
        fileName: dto.fileName,
        taskReference: dto.taskReference,
        status: dto.status,
        batchId: dto.batchId,
        reviewStatus: dto.reviewStatus,
        issueStatus: dto.issueStatus,
        createdBy: dto.createdBy,
        createdDate: dto.createdDate,
        assignee: dto.assignee,
        issueStat: dto.issueStat,
        deleted: dto.deleted,
      };
    }
  }

  export interface IJob extends Entity {
    id: number;
    assignee?: string;
    status?: string;
    task_id?: number;
    batch_id?: number;
    deleted?: boolean;
  }
  export interface IJobMapper {
    fromDTO(dto: JobResponseDTO): IJob;
  }
  export class JobMapper implements IJobMapper {
    fromDTO(dto: JobResponseDTO): IJob {
      if (!dto.id) throw new Error("Invalid DTO Object");
      return {
        id: dto.id,
        assignee: dto.assignee,
        status: dto.status,
        task_id: dto.task_id,
        batch_id: dto.batch_id,
        deleted: dto.deleted,
      };
    }
  }

  export interface IFileDataMapper {
    fromDTO(dto: FileInfoAggregatedResponseDTO): IFileData;
  }
  export class FileDataMapper implements IFileDataMapper {
    fromDTO(dto: FileInfoAggregatedResponseDTO): IFileData {
      if (!dto.id) throw new Error("Invalid DTO Object");
      const issueMapper = new IssueMapper();
      const annotationMapper = new AnnotationMapper();
      const annotationRelationMapper = new AnnotationRelationMapper();
      const taskMapper = new TaskMapper();
      const jobMapper = new JobMapper();
      return {
        id: dto.id,
        fileName: dto.fileName ?? "",
        status: dto.status,
        url: dto.url,
        processStatus: dto.processStatus,
        createdBy: dto.createdBy,
        createdDate: dto.createdDate,
        deleted: dto.deleted,
        referenceUrl: dto.referenceUrl,
        metadata: dto.metadata,
        additionalFileInfo: dto.additionalFileInfo,
        issues: dto.issues?.map(issueMapper.fromDTO),
        annotations: dto.annotations?.map(annotationMapper.fromDTO),
        annotationRelations: dto.annotationRelations?.map(
          annotationRelationMapper.fromDTO
        ),
        tasks: dto.tasks?.map(taskMapper.fromDTO),
        jobs: dto.jobs?.map(jobMapper.fromDTO),
      };
    }
  }

  export async function searchFileInfoAggregated(
    filter: Filter
  ): Promise<IPaginationResponse<IFileData>> {
    const fileFilter = new FileAggreegatedFilter(filter);
    const criteria = fileFilter.toCriteria();
    const pageable = fileFilter.toPageable();
    const configuration = getConfiguration();
    const api = new SearchApi(configuration);
    const response = await api.searchFileInfoAggregated({ criteria, pageable });
    const mapper = new FileDataMapper();
    const items = (response.data.payload ?? []).map(mapper.fromDTO);
    const pagination: IPagination = {
      page: response.data.pagination?.pageNumber ?? filter.page,
      size: response.data.pagination?.pageSize ?? filter.size,
      totalCount: response.data.pagination?.totalItem ?? 0,
    };
    return { data: items, pagination };
  }
}
