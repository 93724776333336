/*
 * File: get-file-info.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { AxiosResponse } from "axios";
import { RequestOptions } from "../../common/request-options";
import { storageAPIget } from "../storage-httpclient";

export async function getAdditionalFileInfo(
  fileId: string | number,
  options: RequestOptions = {}
): Promise<AxiosResponse<any>> {
  const params = new URLSearchParams(options);
  return storageAPIget(`/api/v1/additional-file-infos/from-file/${fileId}?${params.toString()}`);
}

export async function getAdditionalFileInfosFromIds(
  ids: string[] | number[],
  options: RequestOptions = {}
): Promise<AxiosResponse<any>> {
  options = {
    ...options,
    fileIds: ids.join(","),
  }
  const params = new URLSearchParams(options);
  return storageAPIget(`/api/v1/additional-file-infos/from-file?${params.toString()}`);
}

export function getThumbnailUrlFromFileInfoQueryService(info: any) {
  console.log(info);
  return info?.additionalFileInfo?.addition?.thumbnailUrl || "";
}

export function getThumbnailUrlFromFileInfoStorageService(info: any) {
  return info.addition.thumbnailUrl || "";
}
