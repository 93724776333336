/*
 * File: use-ai-models.hooks.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 23rd February 2022 3:18:10 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useMemo } from "react";
import { aiModelsMapper } from "services/label-service/mappers/ai-model.mapper";
import { RequestStatus } from "store/base/base.state";
import { selectAIModelVersions } from "store/customer/ai-model-versions/ai-model-versions.selectors";
import { loadAIModelVersionsAsync } from "store/customer/ai-model-versions/ai-model-versions.thunk";
import {
  selectAIModels,
  selectAIModelsRequestStatus,
} from "store/customer/ai-models/ai-models.selectors";
import { loadAIModelsAsync } from "store/customer/ai-models/ai-models.thunk";

export const useAIModels = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectAIModelsRequestStatus);
  const models = useAppSelector(selectAIModels);
  const modelVersions = useAppSelector(selectAIModelVersions);
  const aiModels = useMemo(() => {
    return aiModelsMapper(models, modelVersions);
  }, [models, modelVersions]);

  useEffect(() => {
    dispatch(loadAIModelsAsync());
    dispatch(loadAIModelVersionsAsync());
  }, [dispatch]);

  return {
    aiModels,
    loaded: status === RequestStatus.SUCCESS,
  };
};
