import vtkImageData from "@kitware/vtk.js/Common/DataModel/ImageData";
import {
  ComplexJobUIModel,
  ComplexLabelingState,
} from "pages/labeler/speech-to-text-labeling/context/speech-to-text-labeling.state";
import { MutableRefObject } from "react";
import { StorageResource } from "services/storage/dto/resource.dto";
import {
  EditorLabel,
  EditorLabelOption,
  EditorMeasurementData,
  EditorMetadataRowModel,
  LabelMapOption,
} from "../components/three-d-editor.models";
import { BatchObservationDTO } from "services/label-service/dtos";

export interface IoUModel {
  annotations: {
    id: number;
    annotator: string;
    jobId: number;
  }[];
  labelId: number;
  metric: { name: string; value: number };
}

export interface ThreeDJobUIModel extends ComplexJobUIModel {
  imageData: vtkImageData | undefined;
  editorLabelOptions: EditorLabelOption[];
  resource: StorageResource | undefined;
  workingSegments: EditorLabel[];
  workingLabelMap: vtkImageData | undefined;
  labelMapOptions: LabelMapOption[];
  workingMetadata: EditorMetadataRowModel[];
  vtkImageDataTag: string;
  dicomTagsData: string[];
  iouData?: IoUModel[];
  // measurements data like distance, angle, label text, ...
  // might save to db in the future
  workingMeasurements: EditorMeasurementData[];
  batchObservations: BatchObservationDTO[];
}

export interface ThreeDLabelingState extends ComplexLabelingState {
  uiJobRef: MutableRefObject<ThreeDJobUIModel | undefined>;
  setIsEditorReady: (v: boolean) => void;
}

export const DEFAULT_UI_JOB: ThreeDJobUIModel = {
  imageData: undefined,
  editorLabelOptions: [],
  workingSegments: [],
  workingLabelMap: undefined,
  resource: undefined,
  labelMapOptions: [],
  workingMetadata: [],
  vtkImageDataTag: "",
  workingMeasurements: [],

  probability: 1,
  isTaskReview: false,
  job: undefined,
  isStepReviewJob: false,
  canViewPreviousStepResult: false,
  acceptBatchObservation: undefined,
  rejectBatchObservation: undefined,
  task: undefined,
  batch: undefined,
  project: undefined,
  batchObservations: [],
  countDownSecond: 0,
  previousJobsTaskObservations: [],
  previousJobsAnnotations: [],
  jobOptions: [],
  dicomTagsData: [],
};

export const defaultThreeDLabelingState = {} as ThreeDLabelingState;
