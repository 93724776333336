/*
 * File: create-project-template.context.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 25th July 2022 2:56:59 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { defaultCreateProjectTemplateState } from "./create-project-template.state";

export const CreateProjectTemplateContext = createContext(
  defaultCreateProjectTemplateState
);

export const useCreateProjectTemplateContext = () => {
  return useContext(CreateProjectTemplateContext);
};
