/*
 * File: text-labeling-task.state.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 15th July 2022 2:49:15 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Batch } from "domain/labeling/batch";
import { LabelingFile } from "domain/labeling/file";
import { Job } from "domain/labeling/job";
import { TextLabelingData } from "domain/labeling/labeling-data";
import { Task } from "domain/labeling/task";
import { Label } from "domain/text-labeling";
import { RequestStatus } from "store/base/base.state";

export interface TextLabelingTaskState {
  loadingStatus: RequestStatus;
  labelingDatas: TextLabelingData[];
  batch: Batch | null;
  task: Task | null;
  file: LabelingFile | null;
  labels: Label[];
  jobs: Job[];
  annotators: string[];
  selectedAssignees: string[];
}

export const defaultTextLabelingTaskState: TextLabelingTaskState = {
  loadingStatus: RequestStatus.IDLE,
  labelingDatas: [],
  labels: [],
  batch: null,
  task: null,
  file: null,
  jobs: [],
  selectedAssignees: [],
  annotators: [],
};
