/*
 * File: user-menu.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:12:52 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useState, useRef, MouseEvent } from "react";
import { useAppSelector } from "../../../hooks/use-redux";
import { Routes } from "../../../routers/config/routes";
import {
  selectCurrentUser,
  selectUserInfo,
  selectUserRole,
} from "../../../store/auth/auth.selectors";
import { Popover } from "@material-ui/core";
import { Link } from "react-router-dom";

import { useKeycloakContext } from "../../../contexts/keycloak/keycloak.context";
import { UserAvatar } from "components/common/vb-user-avatar.component";
import { UserRole } from "store/auth/auth.state";

interface Props {
  displayUsername?: boolean;
}
export function UserMenu({ displayUsername = false }: Props) {
  const { t } = useTranslation();
  const { signOut, switchRole } = useKeycloakContext();
  const currentUser = useAppSelector(selectCurrentUser);
  const userInfo = useAppSelector(selectUserInfo);
  const currentRole = useAppSelector(selectUserRole);
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<Element | null>(null);
  function toggleOpen(event: MouseEvent) {
    anchorEl.current = event.target as Element;
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSignOut() {
    signOut();
  }

  function handleSwitchRole() {
    switchRole(
      currentRole === UserRole.CUSTOMER ? UserRole.LABELER : UserRole.CUSTOMER
    );
  }

  if (!currentUser) return null;
  return (
    <div>
      <div className="flex items-center gap-4">
        {displayUsername && <div>{currentUser?.email}</div>}
        <div
          className="p-1 rounded-full cursor-pointer hover:bg-blue-500"
          title="Account"
          onClick={toggleOpen}
        >
          <UserAvatar name={currentUser.email} className="flex-none w-9 h-9" />
        </div>
      </div>

      <Popover
        className="mt-4 shadow"
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ maxWidth: 320, minWidth: 200 }}>
          <div className="flex items-center px-4 py-3">
            <UserAvatar
              name={currentUser.email}
              className="w-10 h-10 text-sm"
            />
            <div className="px-4 font-bold">{userInfo?.name}</div>
          </div>
          <hr />
          <Link to={Routes.PROFILE} onClick={() => setOpen(false)}>
            <div className="flex items-center px-4 py-3 cursor-pointer hover:text-primary">
              <i className="mx-2 uir-user"></i>
              <span className="mx-2">{t("usermenu:buttonProfile")}</span>
            </div>
          </Link>
          <div className="border-b border-dashed"></div>
          <div
            className="flex items-center px-4 py-3 cursor-pointer hover:text-primary"
            onClick={handleSwitchRole}
          >
            <i className="mx-2 uir-mode-portrait"></i>
            <span className="mx-2">{currentRole === UserRole.CUSTOMER ? t("common:mode.labeler") : t("common:mode.admin")}</span>
          </div>
          <div className="border-b border-dashed"></div>

          <div
            className="flex items-center px-4 py-3 cursor-pointer hover:text-primary"
            onClick={handleSignOut}
          >
            <i className="mx-2 uir-sign-out"></i>
            <span className="mx-2">{t("usermenu:buttonSignOut")}</span>
          </div>
        </div>
      </Popover>
    </div>
  );
}
