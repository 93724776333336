import { useCallback, useEffect, useState } from "react";
import { JobService } from "services/label-service";
import { JobDTO } from "services/label-service/dtos";

interface UseJobsProps {
  taskId: number;
}

export const useJobs = ({ taskId }: UseJobsProps) => {
  const [jobs, setJobs] = useState<JobDTO[]>();
  const [labelerAssignToJobs, setLabelerAssignToJobs] = useState<
    Record<number, string>
  >({});

  useEffect(() => {
    const labelerObj: Record<number, string> = {};
    if (!jobs || !jobs.length) {
      return;
    }

    jobs.forEach(({ id, assignee }) => {
      labelerObj[id] = assignee;
    });

    setLabelerAssignToJobs(labelerObj);
  }, [jobs]);

  const getJobsByTaskId = useCallback(async (taskId: number) => {
    const response = await JobService.getItems({
      taskId: taskId.toString(),
    });
    return response.data ? response.data : [];
  }, []);

  useEffect(() => {
    if (!taskId) return;
    getJobsByTaskId(taskId)
      .then((response) => {
        setJobs(response);
      })
      .catch(console.error);
  }, [getJobsByTaskId, taskId]);

  return {
    jobs,
    setJobs,
    labelerAssignToJobs,
    setLabelerAssignToJobs,
  };
};
