import { DownloadDatasetPayload } from "services/label-service/apis/batch.api";
import { BatchDTO } from "services/label-service/dtos";

export interface DownloadDatasetState {
  downloadingBatch: BatchDTO | null;
  startDownload(
    batch: BatchDTO,
    payload: DownloadDatasetPayload
  ): Promise<void>;

  startDownloadAsync(
    batch: BatchDTO,
    payload: DownloadDatasetPayload
  ): Promise<void>;
}

export const defaultDownloadDatasetState: DownloadDatasetState = {
  downloadingBatch: null,
  startDownload: async (_, __) => {},
  startDownloadAsync: async (_, __) => {},
};
