/*
 * File: training-jobs.slice.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 6th September 2021 2:54:32 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice } from "@reduxjs/toolkit";
import {
  INITIAL_TRAINING_JOB_STATE,
  TrainingJobState,
} from "./training-jobs.state";
import { trainingJobReducerBuilder } from "./training-jobs.thunk";

const SLICE_NAME: string = "trainingJobs";
const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_TRAINING_JOB_STATE,
  reducers: {
    resetTrainingJob(state: TrainingJobState) {
      Object.assign(state, INITIAL_TRAINING_JOB_STATE);
    },
  },
  extraReducers: (builder) => {
    trainingJobReducerBuilder(builder);
  },
});

export const { resetTrainingJob } = slice.actions;

export const trainingJobReducer = slice.reducer;
