/*
 * File: ai-audio-assistance.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 10th July 2023 2:48:59 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import axios from "axios";
import { ENV_CONFIG } from "configs/env.config";
import {
  Configuration,
  SAMImageEncoderApi,
} from "data-access/generated/ai-assistance";
import { Tensor } from "onnxruntime-web";
import { AuthService } from "services/auth";

export module AIAudioAssistanceApi {
  function getConfiguration() {
    const Scope = AuthService.getUserScope();
    const Workspace = AuthService.getUserWorkspace();
    const Authorization = `Bearer ${AuthService.getAccessToken()}`;
    return new Configuration({
      basePath: ENV_CONFIG.AI_AUDIO_ASSISTANCE_SERVICE_URL,
      baseOptions: { headers: { Authorization, Scope, Workspace } },
    });
  }

  export interface ISAMImageEmbeddingPayload {
    inputUrl?: string;
    sasToken?: string;
    base64Image?: string;
    embeddingUrl?: string;
  }
  const BASE64_PREFIX = "data:image/octet-stream;base64,";
  export async function extractEmbeddingImage(
    payload: ISAMImageEmbeddingPayload
  ) {
    try {
      if (payload.embeddingUrl) {
        return await base64EmbeddingImage2Tesor(payload.embeddingUrl);
      }
      const configuration = getConfiguration();
      const api = new SAMImageEncoderApi(configuration);
      const params = {
        body: {
          inputUrl: payload.inputUrl,
          sasToken: payload.sasToken,
          base64Image: payload.base64Image?.replace(BASE64_PREFIX, ""),
        },
      };
      const response = await api.sAMImageEncoderGetImageEmbedding(params);
      const embeddingImages = response.data.base64_embedding;
      if (embeddingImages && embeddingImages.length > 0) {
        const base64ImageData = BASE64_PREFIX + embeddingImages[0];
        const image = await base64EmbeddingImage2Tesor(base64ImageData);
        return image;
      }
    } catch (error) {
      console.log("Error", error);
    }
    return undefined;
  }

  async function base64EmbeddingImage2Tesor(base64ImageData: string) {
    try {
      const res = await axios.get(base64ImageData, { responseType: "blob" });
      const blob = res.data;
      const arrayBuffer = await blob.arrayBuffer();
      const uint8arr = new Uint8Array(arrayBuffer);
      const float32Arr = new Float32Array(uint8arr.buffer);
      return new Tensor("float32", float32Arr, [1, 256, 64, 64]);
    } catch (error) {
      console.log("ERROR", error);
    }
  }
}
