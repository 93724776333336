import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { Annotation } from "domain/image-labeling";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";
import { ImageLabelingData } from "domain/labeling/labeling-data";
import { RootState } from "store";
import { selectImageLabelingJobById } from "../../batch-labeling/batch-labeling.selectors";
import { ImageWorkspaceState } from "../../image-workspace.state";

const THUNK_NAME = "imageWorkspace/imageViewPreviousJobAsync";

interface Payload {
  jobId: number;
}

export const imageViewPreviousJobAsync = createAsyncThunk(
  THUNK_NAME,
  async ({ jobId }: Payload, { getState }) => {
    const state = getState() as RootState;
    const imageLabelingJob = selectImageLabelingJobById(jobId)(state);
    let annotations: Annotation[] = [];
    let relations: RelationAnnotation[] = [];

    if (imageLabelingJob?.data?.previousJobData) {
      const imageJobDatas = imageLabelingJob.data
        .previousJobData as ImageLabelingData[];
      for (const jobData of imageJobDatas) {
        annotations = annotations.concat(jobData.annotations);
        relations = relations.concat(jobData.relationAnnotations);
      }
    }

    return { jobId, annotations, relations };
  }
);

export function imageViewPreviousJobBuilder(
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) {
  return builder.addCase(
    imageViewPreviousJobAsync.fulfilled,
    ({ imageLabeling, imageAnnotations }, action) => {
      imageLabeling.viewMediaAgreement = true;

      for (const anno of imageAnnotations.annotations) {
        anno.visible = false;
      }

      for (const anno of imageAnnotations.relationAnnotations) {
        anno.visible = false;
      }

      imageAnnotations.annotations = imageAnnotations.annotations.filter(
        (anno) => {
          return !anno.nextJobId;
        }
      );

      imageAnnotations.relationAnnotations =
        imageAnnotations.relationAnnotations.filter((anno) => {
          return !anno.nextJobId;
        });

      for (const anno of action.payload.annotations) {
        imageAnnotations.annotations.push(anno);
      }

      for (const anno of action.payload.relations) {
        imageAnnotations.relationAnnotations.push(anno);
      }
    }
  );
}
