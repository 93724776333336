/*
 * File: exported-datasets.state.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 1st September 2021 3:00:30 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BaseState, RequestStatus } from "store/base/base.state";
import { ExportedDatasetDTO } from "services/storage-service/dto/exported-dataset.dto";

export interface ExportedDatasetState extends BaseState<ExportedDatasetDTO> {}

export const INITIAL_EXPORTED_DATASETS_STATE = {
  entities: {},
  ids: [],
  id: -1,
  status: RequestStatus.IDLE,
  error: null,
};
