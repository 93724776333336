import { PathologyEditorMiscComponent } from "./pathology-editor-misc.component";
import { PathologyEditorToolsComponent } from "./pathology-editor-tools.component";
import { PathologyEditorAnnotationsComponent } from "./pathology-editor-annotations.component";
import { isProduction } from "configs/env.config";
import { usePathologyEditorContext } from "./pathology-editor.provider";
import { EDITOR_TABS, TAB_ANNOTATIONS, TAB_HIERARCHY, TAB_IMAGE } from "./pathology-editor.models";
import { classnames } from "utilities/classes";
import { PathologyEditorHierarchyComponent } from "./pathology-editor-hierarchy.component";
import { PathologyEditorImageComponent } from "./pathology-editor-image.component";


export const PathologyEditorNavComponent = () => {

  const {
    activeTab,
    setActiveTab,
  } = usePathologyEditorContext();

  return (
    <div 
      className="z-50 flex flex-col gap-2 p-2 overflow-y-auto text-white border-l border-gray-600 bg-background-900"
      style={{
        width: "300px",
      }}
    >
      <PathologyEditorToolsComponent />
      <div className="flex items-center justify-between mt-4 overflow-x-auto">
        {
          EDITOR_TABS.map(tab => {
            return (
              <button
                key={tab.id}
                className={classnames(
                  "border-b-2 py-1 flex-auto text-left pl-1 font-bold",
                  {"border-warning-500": activeTab.id === tab.id},
                  {"border-white": activeTab.id !== tab.id},
                )}
                onClick={_ => setActiveTab(tab)}
              >
                {tab.name}
              </button>
            )
          })
        }
      </div>

      {
        activeTab.id === TAB_ANNOTATIONS.id &&
        <PathologyEditorAnnotationsComponent />
      }
      {
        activeTab.id === TAB_HIERARCHY.id &&
        <PathologyEditorHierarchyComponent />
      }
      {
        activeTab.id === TAB_IMAGE.id &&
        <PathologyEditorImageComponent />
      }
      
      {
        !isProduction() && <PathologyEditorMiscComponent />
      }
    </div>
  )
}
