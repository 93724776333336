/*
 * File: text-ai-modal.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 11th May 2022 2:00:16 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Backdrop, Grow } from "@material-ui/core";
import {
  IconInformationCircleOutline,
  IconAddItem,
  IconCheckbox,
  IconCheckboxChecked,
  IconTrash,
  IconArrowForward,
  IconCloseCircle,
} from "components/common/vb-icon.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { MatModal } from "components/material/mat-modal.component";
import { useAppDispatch } from "hooks/use-redux";
import { useState, useRef, useEffect } from "react";
import { SingleValue, OptionProps } from "react-select";
import { textLabelPairsEdited } from "store/labeler/text-workspace/text-ai/text-ai.slice";
import {
  TextLabel,
  TextLabelPair,
} from "store/labeler/text-workspace/text-ai/text-ai.state";
import { classnames } from "utilities/classes";
import { v4 } from "uuid";

interface Props {
  labelPairs: TextLabelPair[];
  aiLabels: { [key: number]: TextLabel };
  textLabels: { [key: number]: TextLabel };
  processing?: boolean;
  onSubmit?(pairs: TextLabelPair[]): void;
  onClose?(): void;
}
export const TextAIModal = ({
  aiLabels,
  textLabels,
  labelPairs,
  onSubmit,
  processing,
}: Props) => {
  const dispatch = useAppDispatch();
  const [addPair, setAddPair] = useState(false);
  const [pairs, setPairs] = useState(labelPairs);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleAddPair = (labelId: number, classId: number) => {
    setPairs([
      ...pairs,
      {
        id: v4(),
        labelId,
        classId,
        selected: true,
      },
    ]);
    setAddPair(false);
  };

  const handlePairChange = (pair: TextLabelPair) => {
    const newPairs = pairs.map((p) => {
      if (pair.id === p.id) {
        return { ...pair };
      }
      return p;
    });
    setPairs(newPairs);
  };

  const handleDeleteSelection = () => {
    setPairs(pairs.filter((p) => !p.selected));
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(pairs);
  };

  const hasSelectedPair = !!pairs.find((p) => p.selected);

  useEffect(() => {
    return () => {
      dispatch(textLabelPairsEdited(pairs));
    };
  }, [pairs, dispatch]);

  return (
    <div
      style={{ width: "32rem", backgroundColor: "#393736" }}
      className="flex flex-col text-white rounded"
      ref={containerRef}
    >
      <div className="grid flex-shrink-0 h-12 grid-cols-3 text-background-500">
        <button className="flex items-center justify-center font-semibold text-warning-500 gap-1.5 border-b-2 border-warning-500">
          <span className="text-lg">Auto detect</span>
          <IconInformationCircleOutline className="w-4 h-4" />
        </button>
        <button className="flex items-center justify-center gap-1.5 border-b-2 border-gray-600"></button>
        <button className="flex items-center justify-center gap-1.5 border-b-2 border-gray-600"></button>
      </div>
      <div className="flex flex-col gap-4 px-4 my-6 overflow-y-auto max-h-80 dark-scrollbar">
        {pairs.length > 0 && (
          <div className="flex items-center justify-between">
            <h2>List pair label</h2>
          </div>
        )}

        {pairs.map((pair) => {
          return (
            <PairComponent
              labelPairs={pairs}
              textLabels={textLabels}
              aiLabels={aiLabels}
              labelPair={pair}
              key={`${pair.classId}-${pair.labelId}`}
              onChange={handlePairChange}
            />
          );
        })}

        <button
          className="flex items-center justify-center flex-shrink-0 gap-2 px-4 border border-dashed rounded h-14 border-background-700 hover:bg-gray-800"
          onClick={() => setAddPair(true)}
        >
          <IconAddItem className="w-6 h-6 text-warning-500" />
          <span>Add labels</span>
        </button>
      </div>

      <div className="flex justify-between px-4 mb-4">
        {hasSelectedPair && (
          <button
            onClick={handleDeleteSelection}
            className="flex items-center justify-center gap-1.5 px-3 h-9 rounded border border-warning-500 text-warning-500 bg-none"
          >
            <IconTrash />
            <span>Delete selection</span>
          </button>
        )}
        {!hasSelectedPair && <div></div>}
        <button
          disabled={processing}
          className={classnames(
            "text-white h-9 rounded w-40 flex items-center justify-center",
            {
              "bg-background-300 cursor-not-allowed": !hasSelectedPair,
              "bg-warning-500": hasSelectedPair,
            }
          )}
          onClick={handleSubmit}
        >
          {processing ? "Please wait.." : "Annotate"}
        </button>
      </div>

      {addPair && (
        <AddPairModal
          labelPairs={pairs}
          aiLabels={aiLabels}
          textLabels={textLabels}
          onClose={() => setAddPair(false)}
          onSubmit={handleAddPair}
        />
      )}
    </div>
  );
};

interface PairProps {
  labelPair: TextLabelPair;
  aiLabels: { [key: number]: TextLabel };
  textLabels: { [key: number]: TextLabel };
  labelPairs: TextLabelPair[];
  onChange(pair: TextLabelPair): void;
}
const PairComponent = ({
  labelPair,
  aiLabels,
  textLabels,
  labelPairs,
  onChange,
}: PairProps) => {
  const classOptions = Object.keys(aiLabels).map((key) => {
    const item = aiLabels[parseInt(key)];
    return {
      value: item.id,
      label: item.name,
    };
  });
  const labelOptions = Object.keys(textLabels).map((key) => {
    const item = textLabels[parseInt(key)];
    return {
      value: item.id,
      label: item.name,
    };
  });

  const handleToggleSelect = () => {
    onChange({
      ...labelPair,
      selected: !labelPair.selected,
    });
  };

  const handleClassChange = (val: SingleValue<any>) => {
    const value = val?.value || "0";
    const payload = {
      ...labelPair,
      classId: parseInt(value),
    };
    onChange(payload);
  };

  const handleLabelChange = (val: SingleValue<any>) => {
    const value = val?.value || "0";
    const payload = { ...labelPair, labelId: parseInt(value) };
    onChange(payload);
  };

  return (
    <div
      className={classnames("flex items-center gap-2 p-3 rounded", {
        "border border-warning-500 rounded text-warning-500":
          !!labelPair.selected,
        "border border-gray-700 rounded text-warning-500": !labelPair.selected,
      })}
    >
      <button onClick={handleToggleSelect}>
        {!labelPair.selected && (
          <IconCheckbox className="flex-shrink-0 w-4 h-4" />
        )}
        {labelPair.selected && (
          <IconCheckboxChecked className="flex-shrink-0 w-4 h-4" />
        )}
      </button>
      <VBSelectComponent
        containerClassName="flex-1 text-sm bg-white rounded text-blueGray-700"
        defaultValue={classOptions.find(
          (option) => option.value === labelPair.classId
        )}
        isOptionDisabled={(option) => {
          if (labelPairs.find((p) => p.classId === (option as any).value)) {
            return true;
          }
          return false;
        }}
        options={classOptions}
        onChange={handleClassChange}
        menuPortalTarget={document.body}
        components={{
          Option: AIModelOption,
        }}
      />
      <IconArrowForward className="flex-shrink-0 w-6 h-6" />
      <VBSelectComponent
        containerClassName="flex-1 text-sm bg-gray-300 rounded text-blueGray-700"
        defaultValue={labelOptions.find(
          (option) => option.value === labelPair.labelId
        )}
        onChange={handleLabelChange}
        options={labelOptions}
        menuPortalTarget={document.body}
        components={{
          Option: LabelOption,
        }}
      />
    </div>
  );
};

interface AddPairModalProps {
  onClose(): void;
  onSubmit(labelId: number, classId: number): void;
  aiLabels: { [key: number]: TextLabel };
  textLabels: { [key: number]: TextLabel };
  labelPairs: TextLabelPair[];
}
const AddPairModal = ({
  onClose,
  aiLabels,
  textLabels,
  labelPairs,
  onSubmit,
}: AddPairModalProps) => {
  const [labelId, setLabelId] = useState(-1);
  const [classId, setClassId] = useState(-1);
  const classOptions = Object.keys(aiLabels).map((key) => {
    const item = aiLabels[parseInt(key)];
    return {
      value: item.id,
      label: item.name,
    };
  });
  const labelOptions = Object.keys(textLabels).map((key) => {
    const item = textLabels[parseInt(key)];
    return {
      value: item.id,
      label: item.name,
    };
  });

  const handleClassChange = (val: SingleValue<any>) => {
    const value = val?.value || "0";
    setClassId(parseInt(value));
  };

  const handleLabelChange = (val: SingleValue<any>) => {
    const value = val?.value || "0";
    setLabelId(parseInt(value));
  };
  
  const handleSubmit = () => {
    onSubmit(labelId, classId);
  };

  const disable = labelId === -1 || classId === -1;
  return (
    <MatModal
      open
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={onClose}
      disableBackdropClick
      className="flex items-center justify-center outline-none"
    >
      <Grow in>
        <div
          className="rounded"
          style={{ width: "32rem", backgroundColor: "#393736" }}
        >
          <div className="relative flex items-center justify-center h-12 text-white border-b border-gray-700">
            <span>Add pair labeling</span>
            <button
              onClick={onClose}
              className="absolute transform -translate-y-1/2 top-1/2 right-4"
            >
              <IconCloseCircle className="w-4 h-4" />
            </button>
          </div>
          <main className="flex flex-col flex-1 gap-6 p-4">
            <div className="flex items-center gap-5">
              <VBSelectComponent
                containerClassName="flex-1 text-sm text-white"
                className="text-gray-700"
                header="AI Label"
                options={classOptions}
                onChange={handleClassChange}
                isOptionDisabled={(option) => {
                  if (
                    labelPairs.find((p) => p.classId === (option as any).value)
                  ) {
                    return true;
                  }
                  return false;
                }}
                menuPortalTarget={document.body}
                components={{
                  Option: AIModelOption,
                }}
              />
              <div>
                <div className="h-6" />
                <IconArrowForward className="w-6 h-6 text-warning-500" />
              </div>
              <VBSelectComponent
                containerClassName="flex-1 text-sm text-white"
                className="text-gray-700"
                header="Your Label"
                onChange={handleLabelChange}
                options={labelOptions}
                menuPortalTarget={document.body}
                components={{
                  Option: LabelOption,
                }}
              />
            </div>

            <div className="flex items-center justify-end">
              <button
                disabled={disable}
                className={classnames(
                  "flex items-center justify-center w-40 rounded h-9 ",
                  {
                    "text-white bg-warning-500": !disable,
                    "text-white bg-background-300": disable,
                  }
                )}
                onClick={handleSubmit}
              >
                Add pair
              </button>
            </div>
          </main>
        </div>
      </Grow>
    </MatModal>
  );
};

const AIModelOption = ({
  innerRef,
  innerProps,
  label,
  isDisabled,
  isFocused,
  isSelected,
  data,
}: OptionProps) => {
  return (
    <div
      key={label}
      ref={innerRef}
      {...innerProps}
      className={classnames("flex flex-col px-4 capitalize gap-.5 py-1.5", {
        "bg-warning-500 text-white": !isDisabled && isSelected,
        "bg-warning-50 text-blueGray-700":
          !isDisabled && isFocused && !isSelected,
        "text-gray-400 pointer-events-none": isDisabled,
      })}
    >
      <div className="truncate text whitespace-nowrap">{label}</div>
      <div className="flex items-center gap-2 text-xs flex-nowrap opacity-80">
        <span>{(data as any).model}</span>
      </div>
    </div>
  );
};

const LabelOption = ({
  innerRef,
  innerProps,
  label,
  isDisabled,
  isFocused,
  isSelected,
  data,
}: OptionProps) => {
  return (
    <div
      key={label}
      ref={innerRef}
      {...innerProps}
      className={classnames("flex items-center px-4 h-9 capitalize gap-2", {
        "bg-warning-500 text-white": !isDisabled && isSelected,
        "bg-warning-50 text-blueGray-700":
          !isDisabled && isFocused && !isSelected,
        "text-gray-400 pointer-events-none": isDisabled,
      })}
    >
      <span className="flex-1 text-sm truncate whitespace-nowrap">{label}</span>
    </div>
  );
};
