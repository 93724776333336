import { itkHelper } from "./itk_import";

export const ITK_FILTER_MCI = "mci";
export const ITK_FILTER_SMOOTH_MEDIAN = "smooth_median";

export async function runMciFilter(
  vtkImage,
  label = 0,
  axis = -1,
  startSlice = -1,
  endSlice = -1
) {
  const start = new Date().getTime();

  const wasmURL = new URL("/itk_wasm/filters/Filters", document.location);

  const itkImage = await itkHelper.convertVtkToItkImage(vtkImage, {});

  const args = [
    "0",
    "0",
    "--memory-io",
    "--filter-name",
    ITK_FILTER_MCI,
    "--label-interpolate",
    label.toString(),
    "--axis-interpolate",
    axis.toString(),
  ];
  if (axis !== -1 && startSlice !== -1 && endSlice !== -1) {
    args.push(
      ...[
        "--start-slice",
        startSlice.toString(),
        "--end-slice",
        endSlice.toString(),
      ]
    );
  }
  const inputs = [{ type: itk.InterfaceTypes.Image, data: itkImage }];
  const desiredOutputs = [{ type: itk.InterfaceTypes.Image }];
  const { stdout, stderr, outputs, webWorker } = await itk.runPipeline(
    null,
    wasmURL,
    args,
    desiredOutputs,
    inputs
  );
  webWorker.terminate();
  const outItkImage = outputs[0].data;

  const end = new Date().getTime();
  const time = end - start;
  console.log(
    "Filter time: " + time / 1000 + " seconds",
    " in axis",
    axis,
    startSlice,
    endSlice
  );

  return await itkHelper.convertItkToVtkImage(outItkImage, {});
}

export async function runMedianFilterFast(
  vtkImage,
  radius = 1,
  poolSize = 8,
  maxTotalSplits = 8
) {
  const start = new Date().getTime();

  const progressUpdates = [];
  let reportedTotalSplits = null;
  function progressLogger(split, totalSplits) {
    progressUpdates.push(split);
    reportedTotalSplits = totalSplits;
  }

  const workerPool = new itk.WorkerPool(poolSize, itk.runPipeline);
  const wasmURL = new URL("/itk_wasm/filters/Filters", document.location);
  const itkImage = await itkHelper.convertVtkToItkImage(vtkImage, {});

  const taskArgsArray = [];
  for (let index = 0; index < maxTotalSplits; index++) {
    const args = [
      "0",
      "0",
      "--memory-io",
      "--filter-name",
      ITK_FILTER_SMOOTH_MEDIAN,
      "--radius",
      radius.toString(),
      "--max-splits",
      maxTotalSplits.toString(),
      "--split",
      index.toString(),
    ];
    const data = itk.imageSharedBufferOrCopy(itkImage);
    const inputs = [{ type: itk.InterfaceTypes.Image, data }];
    const desiredOutputs = [{ type: itk.InterfaceTypes.Image }];
    taskArgsArray.push([wasmURL, args, desiredOutputs, inputs]);
  }

  const results = await workerPool.runTasks(taskArgsArray, progressLogger)
    .promise;
  workerPool.terminateWorkers();

  const imageSplits = results.map(({ outputs }) => outputs[0].data);
  const stackedImage = itk.stackImages(imageSplits);

  let end = new Date().getTime();
  let time = end - start;
  console.log("Filter time: " + time / 1000 + " seconds");

  return await itkHelper.convertItkToVtkImage(stackedImage, {});
}
