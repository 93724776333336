/*
 * File: use-skip-task.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 2nd June 2023 10:47:56 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useAppDispatch } from "hooks/use-redux";
import { useBatchTaskContext } from "pages/customer/projects/project-batch/batch-detail/pages/tasks/batch-tasks.context";
import { useCallback, useState } from "react";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { skipTaskAsync } from "store/customer/batch/batch.thunk";
import { getErrMessage } from "utilities/errors/errors";
import { handleThunkRejected } from "utilities/redux/redux.utils";

interface TaskActionPayload {
  taskId: number;
  jobIds: number[];
  reason: string;
}

export function useSkipTask() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { reloadTaskAsync } = useBatchTaskContext();

  const skipTask = useCallback(
    async (payload: TaskActionPayload) => {
      try {
        if (loading) return;
        setLoading(true);
        const response = await dispatch(skipTaskAsync(payload));
        handleThunkRejected(response);
        setLoading(false);
        dispatch(enqueueSuccessNotification("Success"));
        reloadTaskAsync();
      } catch (err: any) {
        const message = getErrMessage(err, "Failed to skip task");
        dispatch(enqueueErrorNotification(message));
        setLoading(false);
      }
    },
    [dispatch, loading, reloadTaskAsync]
  );

  return { loading, skipTask };
}

export default useSkipTask;
