/*
 * File: text-annotators.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 9th August 2022 9:24:53 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Tooltip } from "@material-ui/core";
import classnames from "classnames";
import { UserAvatar } from "components/common/vb-user-avatar.component";

interface Props {
  annotators: string[];
  selectedAnnotators: string[];
  onSelectAnnotator?(annotator: string): void;
}

export const TextAnnotators = ({
  annotators,
  selectedAnnotators,
  onSelectAnnotator,
}: Props) => {
  return (
    <div className="flex items-center gap-4">
      {annotators.map((annotator) => {
        const isActive = selectedAnnotators.includes(annotator);
        return (
          <Tooltip key={annotator} title={annotator}>
            <button
              key={annotator}
              onClick={() => onSelectAnnotator && onSelectAnnotator(annotator)}
              className={classnames("rounded-full p-0.5", {
                "ring-2 ring-primary": isActive,
              })}
            >
              <UserAvatar
                className="flex-none text-xs w-7 h-7"
                name={annotator}
              />
            </button>
          </Tooltip>
        );
      })}
    </div>
  );
};
