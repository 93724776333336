/*
 * File: user-notification.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 16th September 2021 9:35:52 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestOptions } from "services/common/request-options";
import { NotificationServiceImpl } from "services/notification-service";
import {
  requestPending,
  requestRejected,
  requestFulfilled,
  setEntites,
} from "store/base/base.reducer";
import { UserNotificationState } from "./user-notification.state";

export enum NotificationThunk {
  LOAD_USER_NOTIFICATIONS = "userNotification/loadUserNotificationsAsync",
  LOAD_USER_NOTIFICATION_UNREAD_COUNT = "userNotification/loadUserNotificationUnreadCount",
  LOAD_USER_NOTIFICATION_SETTINGS = "userNotification/loadUserNotificationSettings",
  UPDATE_USER_NOTIFICATION_SETTINGS = "userNotification/updateUserNotificationSettings",
}

export const loadUserNotificationUnreadCountAsync = createAsyncThunk(
  NotificationThunk.LOAD_USER_NOTIFICATION_UNREAD_COUNT,
  async () => {
    const unreadNotification =
      await NotificationServiceImpl.getEventsService().countUnread();
    return unreadNotification.data;
  }
);

export const loadUserNotificationsAsync = createAsyncThunk(
  NotificationThunk.LOAD_USER_NOTIFICATIONS,
  async (options: RequestOptions) => {
    const res = await NotificationServiceImpl.getEventsService().getItems(
      options
    );
    return {
      data: res.data,
      totalItem: res.headers["x-total-count"],
    };
  }
);

export const loadUserNotificationSettings = createAsyncThunk(
  NotificationThunk.LOAD_USER_NOTIFICATION_SETTINGS,
  async (projectId?: string) => {
    const settings =
      await NotificationServiceImpl.getSettingsService().getSettings(projectId);
    return settings.data;
  }
);

export const updateUserNotificationSettings = createAsyncThunk(
  NotificationThunk.UPDATE_USER_NOTIFICATION_SETTINGS,
  async (payload: object) => {
    const settings =
      await NotificationServiceImpl.getSettingsService().createSettings(
        payload
      );
    return settings.data;
  }
);

export const userNotificationReducerBuilder = (
  builder: ActionReducerMapBuilder<UserNotificationState>
): ActionReducerMapBuilder<UserNotificationState> => {
  return builder
    .addCase(loadUserNotificationUnreadCountAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(loadUserNotificationUnreadCountAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(
      loadUserNotificationUnreadCountAsync.fulfilled,
      (state, action) => {
        state.numberOfUnreadNotifications = action.payload;
        requestFulfilled(state);
      }
    )
    .addCase(loadUserNotificationsAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(loadUserNotificationsAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(loadUserNotificationsAsync.fulfilled, (state, action) => {
      const { data } = action.payload;
      setEntites(state, data);
      requestFulfilled(state);
    })
    .addCase(loadUserNotificationSettings.pending, (state) => {
      requestPending(state);
    })
    .addCase(loadUserNotificationSettings.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(loadUserNotificationSettings.fulfilled, (state, action) => {
      state.settings = action.payload;
      requestFulfilled(state);
    })
    .addCase(updateUserNotificationSettings.pending, (state) => {
      requestPending(state);
    })
    .addCase(updateUserNotificationSettings.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(updateUserNotificationSettings.fulfilled, (state, action) => {
      state.settings = action.payload;
      requestFulfilled(state);
    });
};
