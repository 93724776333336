import { useTranslation } from "react-i18next";
import {
  IconArrowLeftBold,
  IconEye,
  IconHideBold,
  IconKeyboard,
  IconSave,
  IconSetting2,
} from "components/common/vb-icon.component";
import { IconArrowRightCircle } from "components/common/vb-icon.component";
import { useEffect, useMemo, useRef, useState } from "react";
import { useCountDown } from "ahooks";
import { classnames } from "utilities/classes";
import { BatchDTO, ProjectDTO, TaskDTO } from "services/label-service/dtos";
import { BatchInfoButton } from "components/dicom/dicom-tools/batch-info-button.component";
import { AppEvents } from "constants/annotation.constant";
import { inputManager } from "utilities/keyboard/input.manager";
import { Tooltip } from "@material-ui/core";
import { Fragment } from "react";
import { ThreeDEditorEvents } from "./components/three-d-editor.models";
import { ThreeDEditorShortcutModal } from "./components/three-d-editor-shortcut.component";
import { ThreeDUserAvatar } from "./three-d-user-avatar.component";
import { ThreeDEditorSettingModal } from "./components/three-d-editor-settings.modal";
import { UseAutoSaveReturnValue } from "hooks/use-auto-save.hook";

export interface Props {
  autoSave: UseAutoSaveReturnValue;
  batch: BatchDTO;
  task: TaskDTO;
  project?: ProjectDTO;
  disabledComplete: boolean;
  disabledSave: boolean;
  countDownSecond: number;
  isReady: boolean;
  isReviewStepJob?: boolean;
  onClickBack?: () => void;
  onClickSave?: () => void;
  onClickComplete?: (accept?: boolean, reject?: boolean) => Promise<void>;
  containerClasses?: string;
  showButtons?: boolean;
  showInfo?: boolean;
  jobNavVisibility?: boolean;
  showJobNav?: boolean;
  onClickToggleJobNav?: () => void;
  dicomTagsData?: string[];
}

export const ThreeDHeaderBar = ({
  autoSave,
  batch,
  task,
  project,
  disabledComplete,
  disabledSave,
  countDownSecond,
  isReady,
  isReviewStepJob = false,
  onClickBack,
  onClickSave,
  onClickComplete,
  containerClasses = "py-2 px-2 border-b border-gray-600",
  showButtons = true,
  showInfo = true,
  jobNavVisibility = false,
  showJobNav = true,
  onClickToggleJobNav,
  dicomTagsData = [],
}: Props) => {
  const { t } = useTranslation();
  const [isEnabled, setEnabled] = useState(false);
  const [settingModalVisible, setSettingModalVisible] = useState(false);
  const [shortcutModalVisibility, setShortcutModalVisibility] = useState(false);

  const buttonSaveRef = useRef<any>();
  const buttonCompleteRef = useRef<any>();

  const [countdown, setTargetDate] = useCountDown({
    onEnd: () => setEnabled(true),
  });

  const batchTaskInfoStr = useMemo(() => {
    return `Batch: ${batch.name} - Task: ${task.id}`;
  }, [batch, task]);

  const dicomTagsDataStr = useMemo(() => {
    return dicomTagsData.join(", ");
  }, [dicomTagsData]);

  useEffect(() => {
    if (isReady && !isEnabled) {
      if (countDownSecond === 0) {
        setEnabled(true);
        return;
      }
      const timeLimitMs = countDownSecond * 1000;
      setTargetDate(new Date(Date.now() + timeLimitMs));
    }
  }, [isEnabled, isReady, setTargetDate, countDownSecond]);

  const handleComplete = async (accept = false, reject = false) => {
    if (onClickComplete) {
      await onClickComplete(accept, reject);
    }
    setEnabled(false);
  };

  useEffect(() => {
    const hanldeShortCutKeyDown = () => {
      if (inputManager.isActionSaveDown() && !!buttonSaveRef.current) {
        buttonSaveRef.current.click();
      }
      // if (inputManager.isActionSubmitDown() && !!buttonCompleteRef.current) {
      //   buttonCompleteRef.current.click();
      // }
    };

    document.addEventListener(
      AppEvents.SHORT_CUT_KEY_DOWN,
      hanldeShortCutKeyDown
    );

    return () => {
      document.removeEventListener(
        AppEvents.SHORT_CUT_KEY_DOWN,
        hanldeShortCutKeyDown
      );
    };
  }, []);

  const handleKeyboardClick = () => {
    setShortcutModalVisibility(true);
  };

  const handleCloseModal = () => {
    setShortcutModalVisibility(false);
    // Send self check active event to windows
    document.dispatchEvent(
      new CustomEvent(ThreeDEditorEvents.COMMAND_CHECK_SELF_ACTIVE_WINDOW, {})
    );
  };

  return (
    <div className={classnames("grid grid-cols-12 w-full", containerClasses)}>
      <div className="flex items-center col-span-3 gap-4 text-white">
        {showButtons && (
          <Fragment>
            <button
              onClick={onClickBack}
              className="flex-none flex items-center justify-center focus:outline-none gap-1.5"
            >
              <IconArrowLeftBold className="w-6 h-6" />
              <span>{t("labelerworkspace:menu.buttonExit")}</span>
            </button>
            {jobNavVisibility && (
              <button
                onClick={onClickToggleJobNav}
                className="flex-none flex items-center justify-center focus:outline-none gap-1.5"
              >
                <Tooltip title="Hide jobs bar" placement="top">
                  <IconHideBold className="w-6 h-6" />
                </Tooltip>
              </button>
            )}
            {!jobNavVisibility && showJobNav && (
              <button
                onClick={onClickToggleJobNav}
                className="flex-none flex items-center justify-center focus:outline-none gap-1.5"
                style={{
                  zIndex: "100",
                }}
              >
                <Tooltip title="Show jobs bar" placement="top">
                  <IconEye className="z-50 w-6 h-6" />
                </Tooltip>
              </button>
            )}
            <Tooltip title="Editor settings">
              <IconSetting2
                className="flex-none w-6 h-6 cursor-pointer"
                onClick={() => setSettingModalVisible(true)}
              />
            </Tooltip>

            <Tooltip title="Show shortcuts" placement="top">
              <IconKeyboard
                className="flex-none w-6 h-6 cursor-pointer"
                onClick={handleKeyboardClick}
              />
            </Tooltip>
            <ThreeDEditorShortcutModal
              open={shortcutModalVisibility}
              onClose={handleCloseModal}
            />
          </Fragment>
        )}

        <div className="flex items-center gap-2 truncate">
          {project && <BatchInfoButton batch={batch} project={project} />}
          <Tooltip title={batchTaskInfoStr} placement="bottom">
            <span className="truncate">{batchTaskInfoStr}</span>
          </Tooltip>
        </div>
      </div>
      <div className="flex items-center col-span-3 px-2 text-white">
        <Tooltip title={dicomTagsDataStr} placement="bottom">
          <span className="truncate">{dicomTagsDataStr}</span>
        </Tooltip>
      </div>
      <div className="flex items-center col-span-6">
        {showButtons && (
          <div className="flex flex-row-reverse w-full gap-3">
            <ThreeDUserAvatar />
            {!isReviewStepJob && (
              <>
                <button
                  className="px-6 py-2 button-warn disabled:opacity-50"
                  disabled={disabledComplete || !isEnabled}
                  onClick={(_) => handleComplete()}
                  ref={buttonCompleteRef}
                >
                  {isEnabled && t("common:buttonComplete")}
                  {!isEnabled && `Complete (${Math.round(countdown / 1000)}s)`}
                  <IconArrowRightCircle />
                </button>
                <button
                  id="three-d-btn-save"
                  className="px-6 py-2 button-warn disabled:opacity-50"
                  disabled={disabledSave}
                  onClick={(_) => onClickSave && onClickSave()}
                  ref={buttonSaveRef}
                >
                  {t("common:buttonSave")}
                  <IconSave />
                </button>
              </>
            )}
            {isReviewStepJob && (
              <>
                <button
                  className="px-6 py-2 button-warn disabled:opacity-50"
                  disabled={disabledComplete}
                  onClick={(_) => handleComplete(true, false)}
                >
                  {t("common:buttonAccept")}
                </button>
                <button
                  className="px-6 py-2 button-error disabled:opacity-50"
                  disabled={disabledComplete}
                  onClick={(_) => handleComplete(false, true)}
                >
                  {t("common:buttonReject")}
                </button>
              </>
            )}
          </div>
        )}
      </div>
      {settingModalVisible && (
        <ThreeDEditorSettingModal
          open
          onClose={() => setSettingModalVisible(false)}
        />
      )}
    </div>
  );
};
