import {
  VBModal,
  VBModalProps,
} from "components/common/vb-modal/vb-modal.component";
import { useAppDispatch } from "hooks/use-redux";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { JobService } from "services/label-service";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { useJobs } from "../hooks/use-jobs";
import { AssignJobTable } from "./assign-job-table.component";
import * as Sentry from "@sentry/react";

interface AssignJobModalProps extends Omit<VBModalProps, "children"> {
  taskId: number;
  reload?: () => void;
}

export const AssignJobModal = ({
  taskId,
  open,
  title,
  onClose,
  reload,
  ...modalProps
}: AssignJobModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [ processing, setProcessing ] = useState(false);

  const { jobs, labelerAssignToJobs, setLabelerAssignToJobs } = useJobs({
    taskId,
  });

  const handleSubmitAssignJobs = useCallback(async () => {
    const normalizedLabelersToAssign = Object.entries(labelerAssignToJobs).map(
      ([jobId, userId]) => {
        return {
          jobId: parseInt(jobId),
          userId,
        };
      }
    );

    if (!normalizedLabelersToAssign.length) return;
    setProcessing(true);
    try {
      await Promise.all(
        normalizedLabelersToAssign.map(({ jobId, userId }) =>
          JobService.assignJob(jobId, userId)
        )
      );
    
      onClose && onClose();
      reload && reload()
      dispatch(enqueueSuccessNotification(t("common:textUpdatedSuccess")));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      dispatch(enqueueErrorNotification(t("common:textUpdatedFailed")));
    } finally {
      setProcessing(false);
    }
  }, [labelerAssignToJobs, onClose, reload, dispatch, t]);

  return (
    <VBModal
      open={open}
      title={title}
      onSubmit={handleSubmitAssignJobs}
      onClose={onClose}
      textSubmit={t("common:buttonUpdate")}
      processingIndicator={processing}
      {...modalProps}
    >
      <AssignJobTable
        jobs={jobs}
        selectedLabelersByJob={labelerAssignToJobs}
        setSelectedLabelersByJob={setLabelerAssignToJobs}
      />
    </VBModal>
  );
};
