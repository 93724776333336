/*
 * File: workflow-list.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 13th August 2021 9:26:46 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { WorkflowInstructionDTO } from "services/label-service/dtos";
import {
  GridCellParams,
  GridColumns,
  useGridSlotComponentProps,
} from "@material-ui/data-grid";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { VBButton } from "components/common/vb-button.component";
import { Checkbox } from "@material-ui/core";
import { VBDatagridNoBorder } from "components/common/vb-datagrid/vb-datagrid-no-border.component";

function CustomPagination() {
  const { state, apiRef } = useGridSlotComponentProps();

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={state.pagination.page}
      count={state.pagination.pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value)}
    />
  );
}

interface Props {
  workflowInstructions: WorkflowInstructionDTO[];
  hideActions?: boolean;
  onSelect?: (
    workflowInstruction: WorkflowInstructionDTO,
    action?: string
  ) => void;
}
export const WorkflowInstructionTable = ({
  workflowInstructions,
  onSelect,
  hideActions = true,
}: Props) => {
  const { t } = useTranslation();
  const columns: GridColumns = [
    {
      field: "step",
      headerName: t("workflow:instruction.headerStep"),
      headerClassName: "uppercase",
      flex: 0.6,
    },
    {
      field: "description",
      headerName: t("workflow:instruction.headerDescription"),
      type: "string",
      headerClassName: "uppercase",
      flex: 2,
      renderCell: (params: GridCellParams) => {
        return (
          <div className="ck-content">
            <div
              className="ck-content"
              dangerouslySetInnerHTML={{
                __html: (params.value as string) || "",
              }}
            />
          </div>
        );
      },
    },
    {
      field: "canViewPreviousStepResult",
      headerName: t("workflow:instruction.headerShowPrevious"),
      type: "string",
      headerClassName: "uppercase",
      flex: 2,
      renderCell: (params: GridCellParams) => {
        return (
          <Checkbox
            readOnly
            color="primary"
            checked={params.value as boolean}
          />
        );
      },
    },
    {
      field: "roundNumber",
      headerName: t("workflow:instruction.headerNumOfRounds"),
      headerClassName: "uppercase",
      flex: 1,
    },
    {
      field: "version",
      headerName: t("workflow:instruction.headerStatus"),
      headerClassName: "uppercase",
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return <div>Active</div>;
      },
    },
    {
      field: "id",
      headerName: t("workflow:instruction.headerActions"),
      headerClassName: "uppercase",
      hide: hideActions,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return (
          <div className="flex items-center justify-end w-full">
            <VBButton
              onClick={() =>
                onSelect &&
                onSelect(params.row as WorkflowInstructionDTO, "edit")
              }
              className="text-primary"
            >
              <i className="uir-edit"></i>
            </VBButton>
            <VBButton
              onClick={() =>
                onSelect &&
                onSelect(params.row as WorkflowInstructionDTO, "delete")
              }
              className="text-red-500"
            >
              <i className="uir-trash"></i>
            </VBButton>
          </div>
        );
      },
    },
  ];

  return (
    <VBDatagridNoBorder
      className="bg-white"
      checkboxSelection={!hideActions}
      pageSize={10}
      autoHeight
      columns={columns}
      rows={workflowInstructions}
      disableSelectionOnClick
      components={{
        Pagination: CustomPagination,
      }}
    />
  );
};
