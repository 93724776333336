import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AnnotationData } from "domain/image-labeling";
import { RootState } from "store";
import { selectBatchLabelingJobIdsInBatch } from "../../batch-labeling/batch-labeling.selectors";
import { ImageWorkspaceState } from "../../image-workspace.state";
import { selectImageAnnotationById } from "../image-annotations.selectors";

const THUNK_NAME = "imageWorkspace/imageAnnotationRemovedAsync";

interface ImageAnotationPayload<T> {
  jobId: number;
  data: T;
}

export const imageAnnotationRemovedAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: ImageAnotationPayload<AnnotationData[]>, { getState }) => {
    const state = getState() as RootState;
    const uuids = payload.data.map((item) => item.uuid);
    const annotations = [];
    const trackingIds: { objectTrackingId: string; jobId: number }[] = [];
    for (const uuid of uuids) {
      const annotation = selectImageAnnotationById(uuid)(state);
      if (!annotation) continue;
      annotations.push(annotation);
      if (annotation.objectTrackingId) {
        trackingIds.push({
          objectTrackingId: annotation.objectTrackingId,
          jobId: annotation.jobId,
        });
      }
    }

    if (trackingIds.length > 0) {
      const jobIds = selectBatchLabelingJobIdsInBatch(state);
      for (const trackingItem of trackingIds) {
        const jobIndex = jobIds.indexOf(trackingItem.jobId);
        if (jobIndex === -1) continue;
        const annos = state.imageWorkspace.imageAnnotations.annotations.filter(
          (anno) => {
            if (trackingItem.objectTrackingId !== anno.objectTrackingId) {
              return false;
            }
            const annoJobIndex = jobIds.indexOf(anno.jobId);
            if (annoJobIndex === -1) return false;
            return jobIndex < annoJobIndex;
          }
        );
        for (const anno of annos) {
          annotations.push(anno);
        }
      }
    }
    return annotations;
  }
);

export const imageAnnotationRemovedBuilder = (
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) => {
  return builder.addCase(
    imageAnnotationRemovedAsync.fulfilled,
    ({ imageAnnotations }, action) => {
      const annotations = action.payload;
      if (annotations.length === 0) return;
      const uuids = annotations.map((anno) => anno.uuid);
      imageAnnotations.annotations = imageAnnotations.annotations.filter(
        (anno) => !uuids.includes(anno.uuid)
      );
      imageAnnotations.relationAnnotations =
        imageAnnotations.relationAnnotations.filter((anno) => {
          const { measurementIds } = anno.annotationData;
          for (const uuid of uuids) {
            if (measurementIds.includes(uuid)) return false;
          }
          return true;
        });
    }
  );
};
