/*
 * File: project-drawer.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Drawer } from "@material-ui/core";
import { ProjectDTO } from "services/label-service/dtos";
import { VBButton } from "../../../../../components/common/vb-button.component";
import { ProjectInfo } from "./project-info.component";
interface Props {
  project: ProjectDTO | null;
  open: boolean;
  handleClose?: (data?: any) => void;
}
export const ProjectDrawer = ({ open, project, handleClose }: Props) => {
  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={() => handleClose && handleClose()}
    >
      <div className="py-4 w-96">
        <div className="flex items-center">
          <div className="flex-1 px-4 text-2xl font-bold">{project?.name}</div>
          <VBButton
            className="mx-4"
            onClick={() => handleClose && handleClose()}
          >
            <i className="uir-cross-small"></i>
          </VBButton>
        </div>

        {project && <ProjectInfo project={project} onUpdated={handleClose} />}
      </div>
    </Drawer>
  );
};
