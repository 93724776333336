import React from "react";
import List from "@material-ui/core/List";
import { IconArrowLeftBold } from "components/common/vb-icon.component";
import { SidebarItem } from "./sidebar-item.component";
import { RouteData } from "configs/router.config";

interface SidebarProps {
  collapsed: boolean;
  header?: React.ReactNode;
  items?: RouteData[];
  titleBack?: string;
  onBack?: () => void;
  onCollapsed?: (collapsed: boolean) => void;
}

export const Sidebar = ({
  collapsed,
  header,
  items = [],
  titleBack,
  onBack,
}: SidebarProps) => {
  if (!items || !items.length) return null;

  return (
    <>
      {header || (
        <div
          className="flex items-center justify-start gap-2 px-4 mt-1 border-b cursor-pointer h-14 text-background-700 hover:text-primary"
          onClick={onBack}
        >
          <IconArrowLeftBold className="flex-none w-5 h-5" />
          <span className="text-sm">{titleBack}</span>
        </div>
      )}

      <List className="space-y-2 list-none" component="nav">
        {items.map((item: RouteData, index: number) => {
          if (!item) return null;
          return (
            <SidebarItem key={index} item={item} collapsedSidebar={collapsed} />
          );
        })}
      </List>
    </>
  );
};
