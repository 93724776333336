/*
 * File: dataset.slice.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchParams } from "models/common/search-params";
import { Tag } from "models/dataset/tag.model";
import { DatasourceFilter } from "pages/customer/datasets/hooks/use-datasource-filter.hook";
import { QueryService } from "services/query/query.service";
import { StorageService as StorageServiceInj } from "services/storage-service";
import { DatasetDTO } from "services/storage-service/dto/dataset.dto";
import { snakeCase } from "snake-case";
import { RootState } from "store";
import { selectIsUseElasticSearch } from "store/common/app-setting/app-setting.selectors";
import { StorageFileDTO } from "../../../models/dataset/storage-file.model";
import { HTTPStatusCode } from "../../../services/common/http-status";
import { RequestOptions } from "../../../services/common/request-options";
import { StorageService } from "../../../services/storage";
import { DatasetState, INITIAL_DATASET_STATE } from "./dataset.state";
import * as Sentry from "@sentry/react";
import { QueryApi } from "data-access/impl/query";
import { DatasetFilter } from "pages/customer/datasets/context/dataset-context";
import {CloudType} from "pages/customer/datasets/dataset-detail/components/sync-cloud/sync-cloud-form.component"
const SLICE_NAME: string = "dataset";

export const updateDatasetAsync = createAsyncThunk(
  `${SLICE_NAME}/updateDatasetAsync`,
  async (payload: DatasetDTO) => {
    const response = await StorageServiceInj.getDatasetService().updateItem(
      payload
    );
    return {
      ...payload,
      name: response.data.name,
      description: response.data.description,
      version: response.data.version,
    };
  }
);

export const setDatasetAsync = createAsyncThunk(
  `${SLICE_NAME}/setDatasetAsync`,
  async (datasetId: number) => {
    const response = await StorageServiceInj.getDatasetService().getItem(
      datasetId
    );
    return response.data;
  }
);

export const loadDatasetAsync = createAsyncThunk(
  `${SLICE_NAME}/loadDatasetAsync`,
  async (options: RequestOptions = {}, { getState }) => {
    const state = getState() as RootState;
    const isUseESRelatedApis = selectIsUseElasticSearch(state);
    if (isUseESRelatedApis) {
      return await QueryService.getAllFiles(options);
    } else {
      return await StorageService.getAllFiles(options);
    }
  }
);

export const loadDatasetByFilterAsync = createAsyncThunk(
  `${SLICE_NAME}/loadDatasetByFilterAsync`,
  async (options: DatasetFilter, { getState }) => {
    const fileFilter = new QueryApi.FileFilter();
    fileFilter.setPage(options.page ?? 0);
    fileFilter.setPageSize(options.size ?? 20);
    if (options.datasetId) {
      fileFilter.setDatasetId(parseInt(options.datasetId));
    }
    if (options.fileName) {
      fileFilter.setFileName(options.fileName);
    }

    if (options.createdBy) {
      fileFilter.setCreatedBy(options.createdBy);
    }

    if (options.createdDate) {
      fileFilter.setCreatedDate(options.createdDate);
    }

    if (options.search) {
      fileFilter.setFileName(options.search);
    }

    const filter = fileFilter.toFilter();
    const response = await QueryApi.searchFileInfo(filter);
    return {
      files: response.data,
      totalCount: response.pagination.totalCount,
    };
    // const isUseESRelatedApis = selectIsUseElasticSearch(state);
    // if (isUseESRelatedApis) {
    //   return await QueryService.getAllFiles(options);
    // } else {
    //   return await StorageService.getAllFiles(options);
    // }
  }
);

export const loadUserDatasetsAsync = createAsyncThunk(
  `${SLICE_NAME}/loadUserDatasetsAsync`,
  async (filter: DatasourceFilter) => {
    const payload: Record<string, string> = {
      "type.equals": filter.type || "normal",
      sort: "createdDate,desc",
    };
    if (filter.name) {
      payload["name.contains"] = filter.name;
    }
    if (filter.category) {
      payload["category.equals"] = filter.category;
    }
    if (filter.createdBy) {
      payload["createdBy.contains"] = filter.createdBy;
    }
    if (filter.createdDate) {
      payload["createdDate.equals"] = filter.createdDate;
    }
    if (filter.page) {
      payload[SearchParams.PAGE] = (parseInt(filter.page) - 1).toString();
    }
    if (filter.size) {
      payload[SearchParams.SIZE] = filter.size;
    }
    const response = await StorageServiceInj.getDatasetService().getItems(
      payload
    );
    let totalDataset = 0;
    if (response.headers && response.headers["x-total-count"]) {
      totalDataset = parseInt(response.headers["x-total-count"]);
    }
    return {
      totalDataset,
      datasets: response.data,
    };
  }
);

export const createNewDatasetAsync = createAsyncThunk(
  `${SLICE_NAME}/createNewDatasetAsync`,
  async (payload: Partial<DatasetDTO>) => {
    const response = await StorageServiceInj.getDatasetService().createItem(
      payload
    );
    return response.data;
  }
);

export const deleteDatasetAsync = createAsyncThunk(
  `${SLICE_NAME}/deleteDatasetAsync`,
  async (datasetID: number, { rejectWithValue }) => {
    try {
      await StorageServiceInj.getDatasetService().deleteItem(datasetID);
      return datasetID;
    } catch (err: any) {
      Sentry.captureException(err);
      return rejectWithValue(err.response.value);
    }
  }
);

export const deleteStorageFileAsync = createAsyncThunk(
  `${SLICE_NAME}/deleteStorageFileAsync`,
  async (params: { itemId: number; option: string }) => {
    const response = await StorageService.deleteFile(
      params.itemId,
      params.option
    );
    if (
      response.status === HTTPStatusCode.OK ||
      response.status === HTTPStatusCode.NoContent
    ) {
      return params.itemId;
    }
    return -1;
  }
);

export const loadDatasetCloudsAsync = createAsyncThunk(
  `${SLICE_NAME}/loadDatasetCloudsAsync`,
  async (datasetId: string) => {
    const response = await StorageService.getCloudsByDataset(datasetId);
    if (response.status === HTTPStatusCode.OK) {
      return response.data.sort((a, b) => b.id - a.id);
    }
    return [];
  }
);

export const importCloudAsync = createAsyncThunk(
  `${SLICE_NAME}/importCloudAsync`,
  async (params: {
    cloudType: string;
    accessKey?: string;
    secretKey?: string;
    bucket?: string;
    prefix?: string;
    sasUrl?: string;
    datasetId: number;
    tags: { name: string; id: number }[];
    storeType: string;
  }) => {
    const { cloudType, datasetId, tags, storeType } = params;
    let response;

    if (cloudType === CloudType.AWS_S3) {
      const { accessKey, secretKey, bucket, prefix } = params;
      const newTags = await createTagsIfNotExist(tags);
      response = await StorageService.importAwsCloudData(
        cloudType,
        accessKey?? '',
        secretKey?? '',
        bucket?? '',
        prefix?? '',
        datasetId,
        newTags.map((tag) => tag.id),
        storeType
      );
    } else {
      const { sasUrl } = params;
      const newTags = await createTagsIfNotExist(tags);
      response = await StorageService.importAzureCloudData(
        cloudType,
        sasUrl?? '',
        datasetId,
        newTags.map((tag) => tag.id),
        storeType
      );
    }
    return response.data;
  }
);


const createTagsIfNotExist = async (tags: { id: number; name: string }[]) => {
  const items = [];
  for (let tag of tags) {
    if (tag.id !== -1) {
      items.push(tag);
      continue;
    } else if (!items.find((item) => item.name.toLowerCase() === tag.name)) {
      const newCreatedTag = await StorageService.createTag({
        name: tag.name,
        code: snakeCase(tag.name),
      });
      const newTag = newCreatedTag.data as Tag;
      items.push({
        id: newTag.id,
        name: newTag.name,
      });
    }
  }
  return items;
};

export const loadTagsAsync = createAsyncThunk(
  `${SLICE_NAME}/loadTagsAsync`,
  async () => {
    const response = await StorageService.getAllTags();
    return response.data;
  }
);

export const addTagToFilesAsync = createAsyncThunk(
  `${SLICE_NAME}/addTagToFilesAsync`,
  async (params: { tag: Tag; fileIds: number[] }) => {
    const { tag, fileIds } = params;
    for (let id of fileIds) {
      await StorageService.manageTag({
        action: "ADD",
        fileInfoId: id,
        tagId: tag.id,
      });
    }
    return params;
  }
);

export const removeTagFromFilesAsync = createAsyncThunk(
  `${SLICE_NAME}/removeTagFromFilesAsync`,
  async (params: { tag: Tag; fileIds: number[] }) => {
    const { tag, fileIds } = params;
    for (let id of fileIds) {
      await StorageService.manageTag({
        action: "DELETE",
        fileInfoId: id,
        tagId: tag.id,
      });
    }
    return params;
  }
);

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_DATASET_STATE,
  reducers: {
    setFiles: (
      state: DatasetState,
      action: PayloadAction<StorageFileDTO[]>
    ) => {
      state.files = action.payload;
    },
    removeCurrentDatasetData: (state: DatasetState) => {
      state.dataset = null;
      state.files = [];
    },
    setCurrentDataset: (
      state: DatasetState,
      action: PayloadAction<DatasetDTO>
    ) => {
      state.dataset = action.payload;
      state.clouds = [];
      state.files = [];
    },
    addFile: (state: DatasetState, action: PayloadAction<StorageFileDTO>) => {
      state.files.push(action.payload);
    },
    removeFile: (state: DatasetState, action: PayloadAction<number>) => {
      state.files = state.files.filter((f) => f.id !== action.payload);
    },
    updateFile: (
      state: DatasetState,
      action: PayloadAction<StorageFileDTO>
    ) => {
      state.files = state.files.map((f) => {
        if (f.id === action.payload.id) {
          return {
            ...f,
            ...action.payload,
          };
        }
        return f;
      });
    },
    addTag: (state: DatasetState, action: PayloadAction<Tag>) => {
      state.tags = [...state.tags, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setDatasetAsync.pending, (state, action) => {
        if (state.dataset && state.dataset.id !== action.meta.arg) {
          state.dataset = null;
          state.files = [];
        }
      })
      .addCase(setDatasetAsync.fulfilled, (state, action) => {
        state.dataset = action.payload;
      })
      .addCase(updateDatasetAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(updateDatasetAsync.rejected, (state) => {
        state.requesting = false;
      })
      .addCase(updateDatasetAsync.fulfilled, (state, action) => {
        state.requesting = false;
        state.datasets = state.datasets.map((set) => {
          if (set.id === action.payload.id) {
            return action.payload;
          }
          return set;
        });
        if (state.dataset && state.dataset.id === action.payload.id) {
          state.dataset = action.payload;
        }
      })
      .addCase(deleteDatasetAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(deleteDatasetAsync.fulfilled, (state, action) => {
        state.requesting = false;
        state.datasets = state.datasets.filter(
          (set) => set.id !== action.payload
        );
        state.totalDataset -= 0;
      })
      .addCase(deleteDatasetAsync.rejected, (state) => {
        state.requesting = false;
      })
      .addCase(loadUserDatasetsAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(loadUserDatasetsAsync.rejected, (state) => {
        state.requesting = false;
      })
      .addCase(loadUserDatasetsAsync.fulfilled, (state, action) => {
        state.requesting = false;
        const { totalDataset, datasets } = action.payload;
        state.datasets = datasets;
        state.totalDataset = totalDataset;
      })
      .addCase(createNewDatasetAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(createNewDatasetAsync.fulfilled, (state, action) => {
        state.requesting = false;
        state.datasets = [action.payload, ...state.datasets];
        state.totalDataset += 1;
      })
      .addCase(createNewDatasetAsync.rejected, (state) => {
        state.requesting = false;
      })
      .addCase(loadDatasetAsync.pending, (state) => {
        state.requesting = true;
        state.files = [];
      })
      .addCase(loadDatasetAsync.fulfilled, (state, action) => {
        state.requesting = false;
        state.files = action.payload.items;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(loadDatasetAsync.rejected, (state) => {
        state.requesting = false;
      })
      .addCase(loadDatasetByFilterAsync.pending, (state) => {
        state.requesting = true;
        state.files = [];
      })
      .addCase(loadDatasetByFilterAsync.fulfilled, (state, action) => {
        state.requesting = false;
        state.files = action.payload.files as StorageFileDTO[];
        state.totalCount = action.payload.totalCount;
      })
      .addCase(loadDatasetByFilterAsync.rejected, (state) => {
        state.requesting = false;
      })
      .addCase(deleteStorageFileAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(deleteStorageFileAsync.fulfilled, (state, action) => {
        state.requesting = false;
      })
      .addCase(deleteStorageFileAsync.rejected, (state, action) => {
        state.requesting = false;
      })
      .addCase(loadDatasetCloudsAsync.fulfilled, (state, action) => {
        state.clouds = action.payload;
      })
      .addCase(importCloudAsync.fulfilled, (state, action) => {
        state.clouds.push(action.payload);
        state.syncingCloud = action.payload;
      })
      .addCase(loadTagsAsync.fulfilled, (state, action) => {
        state.tags = action.payload;
      })
      .addCase(addTagToFilesAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(addTagToFilesAsync.rejected, (state) => {
        state.requesting = false;
      })
      .addCase(addTagToFilesAsync.fulfilled, (state, action) => {
        state.requesting = false;
        const { tag, fileIds } = action.payload;
        state.files = state.files.map((f) => {
          if (!fileIds.includes(f.id)) return f;
          if ((f.tags ?? []).find((t) => t.id === tag.id)) return f;
          return {
            ...f,
            tags: [...(f.tags ?? []), tag],
          };
        });
      })
      .addCase(removeTagFromFilesAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(removeTagFromFilesAsync.rejected, (state) => {
        state.requesting = false;
      })
      .addCase(removeTagFromFilesAsync.fulfilled, (state, action) => {
        state.requesting = false;
        const { tag, fileIds } = action.payload;
        state.files = state.files.map((f) => {
          if (!fileIds.includes(f.id)) return f;
          if (!(f.tags ?? []).find((t) => t.id === tag.id)) return f;
          return {
            ...f,
            tags: (f.tags ?? []).filter((t) => t.id !== tag.id),
          };
        });
      });
  },
});

export const {
  removeCurrentDatasetData,
  setCurrentDataset,
  setFiles,
  addFile,
  removeFile,
  updateFile,
  addTag,
} = slice.actions;

export const datasetReducer = slice.reducer;
