/*
 * File: theme-selector.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 21st February 2022 10:20:44 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { classnames } from "utilities/classes";
import { Option } from "domain/common";

interface ThemeSelectorProps {
  options: Option[];
  value: string;
  onChange?(value: string): void;
}
export const ThemeSelector = ({
  options,
  value,
  onChange,
}: ThemeSelectorProps) => {
  return (
    <div className="flex items-center justify-center">
      <div className="overflow-hidden bg-white border rounded border-primary">
        {options.map((option) => {
          return (
            <button
              key={option.value}
              onClick={() => onChange && onChange(option.value)}
              className={classnames("w-20 py-1", {
                "text-white bg-primary": value === option.value,
              })}
            >
              {option.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};
