/*
 * File: ai-models.context.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 2nd November 2022 4:16:04 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { defaultAIModelsState } from "./ai-models.state";

export const AIModelsContext = createContext(defaultAIModelsState);

export function useAIModelsContext() {
  return useContext(AIModelsContext);
}
