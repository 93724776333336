/*
 * File: use-project-breadcrumbs.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 26th July 2021 11:22:15 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Breadcrumb } from "pages/customer/projects/project.context";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { selectBatchInfo } from "store/customer/batch/batch.selectors";
import { selectCurrentProject } from "store/customer/project/project.selectors";
import { selectCurrentWorkflow } from "store/customer/workflow/workflow.selectors";
import { useAppSelector } from "../use-redux";
import { useWorkspaces } from "../workspace/use-workspace.hook";

export const useProjectBreadcrumbs = () => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [idMappers, setIdMappers] = useState<{ [key: string]: string }>({});
  const project = useAppSelector(selectCurrentProject);
  const batch = useAppSelector(selectBatchInfo);
  const workflow = useAppSelector(selectCurrentWorkflow);
  const { workspace } = useWorkspaces();

  useEffect(() => {
    const currentPath = location.pathname;
    const items = currentPath
      .split("/")
      .filter((p) => p.trim().length > 0)
      .map((path, index) => {
        let text = index === 0 ? "Projects" || path : idMappers[path] || path;
        if (index === 2 && text === "create-batch") {
          text = "New batch";
        }
        return {
          url: `${currentPath.substring(
            0,
            currentPath.indexOf(path) + path.length
          )}`,
          text,
          disabled: false,
        };
      });
    if (items.length > 0) items[items.length - 1].disabled = true;
    setBreadcrumbs(items);
  }, [location.pathname, idMappers, workspace]);

  useEffect(() => {
    if (project && project.id) {
      addIdMapper(project.id.toString(), project.name.toLowerCase());
    }
  }, [project]);

  useEffect(() => {
    if (batch) addIdMapper(batch.id.toString(), batch.name.toLowerCase());
  }, [batch]);

  useEffect(() => {
    if (workflow)
      addIdMapper(workflow.id.toString(), workflow.name.toLowerCase());
  }, [workflow]);

  function addIdMapper(key: string, value: string) {
    setIdMappers((pre) => ({
      ...pre,
      [key]: value,
    }));
  }
  return { breadcrumbs, setBreadcrumbs };
};
