/*
 * File: use-image-stacks.hook.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 17th April 2023 4:17:12 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { ImageFrameHandler } from "components/dicom/cornerstone/cornerstone-handler/cornerstone-handler";
import { useMemo, useState, MutableRefObject, useEffect } from "react";

export function useImageStacks(
  frames: number | undefined,
  handler: MutableRefObject<ImageFrameHandler | undefined>
) {
  const isMultipleFrames = useMemo(() => !!frames && frames > 0, [frames]);
  const numFrames = useMemo(() => frames ?? 1, [frames]);
  const [frame, setFrame] = useState<number>(0);

  function onScroll(toFrame: number) {
    setFrame(toFrame);
  }

  function onChange(newFrame: number) {
    handler.current?.setFrameIdx(newFrame);
  }

  function onNext() {
    const nextFrameIndex = (frame + 1) % numFrames;
    handler.current?.setFrameIdx(nextFrameIndex);
  }

  function onPrevious() {
    const nextFrameIndex = (frame - 1 + numFrames) % numFrames;
    handler.current?.setFrameIdx(nextFrameIndex);
  }

  useEffect(() => {
    const handleUIButtonClick = (clickEvent: any) => {
      const { event, action } = clickEvent.detail;
      event?.preventDefault();
      if (action === "IMAGE_GO_TO_FRAME") {
        handler.current?.setFrameIdx(clickEvent.detail.frame);
      }
    };
    window.addEventListener("UI_EVENT", handleUIButtonClick);
    return () => {
      window.removeEventListener("UI_EVENT", handleUIButtonClick);
    };
  }, [handler]);

  return {
    isMultipleFrames,
    frame,
    numFrames,
    onScroll,
    onChange,
    onNext,
    onPrevious,
  };
}
