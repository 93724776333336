import { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { enqueueWarningNotification } from "store/common/notification/notification.actions";
import { useAppDispatch } from "./use-redux";
import { debounce } from "lodash";

export const useDebouncedClickHandler = (
  maxClicks = 2,
  debounceDelay = 2000
) => {
  const history = useHistory();
  const clickCount = useRef(0);
  const [isBlocked, setIsBlocked] = useState(false);
  const dispatch = useAppDispatch();

  const debounceCheckClick = useRef(
    debounce(() => {
      clickCount.current = 0;
    }, debounceDelay)
  );

  const handleClick = useCallback(
    (itemPath: string, active?: boolean, callback?: () => void) => {
      if (isBlocked) {
        return;
      }
      if (active && clickCount.current === 0) {
        return;
      }

      clickCount.current += 1;
      if (clickCount.current > maxClicks) {
        dispatch(
          enqueueWarningNotification("You are switching tabs too many times!")
        );
        setIsBlocked(true);
        setTimeout(() => {
          setIsBlocked(false);
        }, debounceDelay);
        return;
      }

      if (!active) {
        if (callback) {
          callback();
        } else {
          history.push(itemPath);
        }
      }

      debounceCheckClick.current();
    },
    [isBlocked, maxClicks, dispatch, history, debounceDelay]
  );

  return handleClick;
};
