/*
 * File: three-d-editor-label-item.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 26th October 2022 10:37:16 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Tooltip } from "@material-ui/core";
import {
  IconEdit,
  IconEye,
  IconEyeSlash,
  IconTarget,
  IconTrash,
} from "components/common/vb-icon.component";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { EditorLabelAttributeMenu } from "./three-d-editor-label-attribute-menu.component";
import {
  EditorLabel,
  EditorLabelOption,
  EditorLabelStatus,
} from "./three-d-editor.models";

export enum EditorLabelAction {
  SELECT = "select",
  SELECT_OPTION = "select_option",
  EDIT = "edit",
  DELETE = "delete",
  TOGGLE_VISIBILITY = "toggle_visibility",
  CLOSE_EDIT = "close_edit",
  UPDATE_OPACITY = "update_opacity",
  UPDATE_COLOR = "update_color",
  UPDATE = "update",
  GO_TO_SLICES = "GO_TO_SLICES",
}

export interface LabelOption {
  label: string;
  value: EditorLabelOption;
}

interface EditorLabelItemProps {
  label: EditorLabel;
  isActive?: boolean;
  index?: number;
  labelOptions: LabelOption[];
  onSelect(label: EditorLabel, action: EditorLabelAction, payload?: any): void;
}

export default function EditorLabelItem({
  label,
  isActive,
  index,
  labelOptions,
  onSelect,
}: EditorLabelItemProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isNone, id, name, status, color, opacity, labelOptionId } = label;
  const [isHover, setHover] = useState(false);
  const shouldHideEdit = isNone;
  const isEditing = status === EditorLabelStatus.EDIT;
  const onClickSelectWithAction = useCallback(
    (action: EditorLabelAction, value?: any) => onSelect(label, action, value),
    [label, onSelect]
  );

  const actions = useMemo(() => {
    if (!isHover && !label.visibility) {
      return [
        {
          title: "Toggle visibility",
          onClick: () =>
            onClickSelectWithAction(EditorLabelAction.TOGGLE_VISIBILITY),
          icon: <IconEyeSlash className="w-4 h-4" />,
        },
      ];
    }
    if (isHover) {
      return [
        {
          title: "Go to slices",
          onClick: () =>
            onClickSelectWithAction(EditorLabelAction.GO_TO_SLICES),
          icon: <IconTarget className="w-4 h-4" />,
        },
        {
          title: "Edit label",
          onClick: () => onClickSelectWithAction(EditorLabelAction.EDIT),
          icon: <IconEdit className="w-4 h-4" />,
        },
        {
          title: "Delete label",
          onClick: () => onClickSelectWithAction(EditorLabelAction.DELETE),
          icon: <IconTrash className="w-4 h-4" />,
        },
        {
          title: "Toggle visibility",
          onClick: () =>
            onClickSelectWithAction(EditorLabelAction.TOGGLE_VISIBILITY),
          icon: label.visibility ? (
            <IconEye className="w-4 h-4" />
          ) : (
            <IconEyeSlash className="w-4 h-4" />
          ),
        },
      ];
    }
    return [];
  }, [label, isHover, onClickSelectWithAction]);

  return (
    <div key={id} className="relative py-1">
      <div
        ref={containerRef}
        className="relative flex items-center gap-2 truncate parent"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <input
          type="radio"
          checked={isActive}
          onChange={() => {}}
          onClick={() => onClickSelectWithAction(EditorLabelAction.SELECT)}
          disabled={isNone}
        />
        {!shouldHideEdit && (
          <div
            className="flex-none w-6 h-4 rounded"
            style={{ background: color, opacity }}
          />
        )}

        <div className="truncate" style={{ width: "70%" }}>
          <Tooltip title={`${name} (${index})`} placement="top-start">
            <span className="w-1/2 truncate max-w-1/2">{name}</span>
          </Tooltip>
        </div>

        {label.status === EditorLabelStatus.FIXED && !shouldHideEdit && (
          <Fragment>
            <div className="absolute right-0 flex items-center gap-2 bg-background-900">
              {actions.map((action) => {
                return (
                  <Tooltip key={action.title} title={action.title}>
                    <button className="flex-none" onClick={action.onClick}>
                      {action.icon}
                    </button>
                  </Tooltip>
                );
              })}
            </div>
          </Fragment>
        )}
      </div>
      <div className="pl-5 text-xs truncate text-background-500">
        {!!label.estimatedSize && (
          <span>{`${label.estimatedSize.toFixed(2)} mm, `}</span>
        )}
        <span>Opacity: {label.opacity}</span>
        {label.attributes?.map((attr) => {
          const name = labelOptions
            .find((lb) => lb.value.id === label.labelOptionId)
            ?.value.attributes?.find(
              (attribute) => attribute.id === attr.id
            )?.name;
          const value = attr.value;
          const str = `; ${name}: ${value}`;
          return <span key={attr.id}>{str}</span>;
        })}
      </div>

      {isEditing && !shouldHideEdit && (
        <EditorLabelAttributeMenu
          anchorElement={containerRef.current}
          item={label}
          labelOptions={labelOptions}
          labelOption={
            labelOptions.find(({ value }) => value.id === labelOptionId)
              ?.value as EditorLabelOption
          }
          onClose={() => onClickSelectWithAction(EditorLabelAction.CLOSE_EDIT)}
          onOpacityChange={(value) =>
            onClickSelectWithAction(EditorLabelAction.UPDATE_OPACITY, value)
          }
          onColorChange={(value) =>
            onClickSelectWithAction(EditorLabelAction.UPDATE_COLOR, value)
          }
          onSubmit={(value: Partial<EditorLabel>) =>
            onClickSelectWithAction(EditorLabelAction.UPDATE, value)
          }
        />
      )}
    </div>
  );
}
