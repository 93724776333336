/*
 * File: getDeep.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 8th July 2022 11:55:39 am
 * Author: Huynh Trong Thoai (v.thoaihuynh@vinbrain.net)
 * 
 * Copyright 2022 VinBrain JSC
 */

export const getDeep = (obj: Record<string | number, any>, query: string | string[], defaultValue: any = ""): any => {
  query = Array.isArray(query) ? query : query.replace(/(\[(\d)\])/g, '.$2').replace(/^\./, '').split('.');
  if (!(query[0] in obj)) {
    return defaultValue;
  }

  obj = obj[query[0] as keyof typeof obj];
  if (obj && query.length > 1) {
    return getDeep(obj, query.slice(1), defaultValue);
  }
  return obj;
}