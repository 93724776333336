import { MouseEvent, useState } from "react";
import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import { IconBox, IconEye } from "components/common/vb-icon.component";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useMLModelContext } from "../ml-models.context";
import { MlModelsRunInfo } from "../ml-models.models";
import classNames from "classnames";
import { Tooltip } from "@material-ui/core";

interface Props {
  rows: MlModelsRunInfo[];
  isLoading?: boolean;
  onRowAction?: (row: MlModelsRunInfo, action: string) => void;
}
export const RunInfosTable = ({
  rows,
  isLoading = false,
  onRowAction,
}: Props) => {
  const { t } = useTranslation();
  const { experimentId } = useParams<{ experimentId: string }>();
  const { setCurrentRunInfo } = useMLModelContext();
  const columns: GridColDef[] = [
    {
      field: "runId",
      filterable: false,
      renderHeader: () => <GridColumnHeader header="ID" />,
      renderCell: (params: GridCellParams) => {
        return (
          <div className="px-2 truncate" title={params.row.runId}>
            <Link
              to={`/models/experiments/${experimentId}/${params.row.runId}`}
              onClick={() =>
                setCurrentRunInfo({ ...params.row } as MlModelsRunInfo)
              }
            >
              <span className="text-blue-500 cursor-pointer">
                {params.row.runId}
              </span>
            </Link>
          </div>
        );
      },
      minWidth: 240,
    },
    {
      field: "pipeline",
      filterable: false,
      flex: 1,
      renderHeader: () => <GridColumnHeader header="Pipeline" />,
      renderCell: (params: GridCellParams) => {
        return <GridRowItem params={params} />;
      },
    },
    {
      field: "description",
      filterable: false,
      flex: 1,
      renderHeader: () => <GridColumnHeader header="Description" />,
      renderCell: (params: GridCellParams) => {
        return <GridRowItem params={params} />;
      },
    },

    {
      field: "status",
      filterable: false,
      flex: 1,
      renderHeader: () => <GridColumnHeader header="status" />,
      renderCell: (params: GridCellParams) => {
        return <GridRowStatus params={params} />;
      },
    },
    {
      field: "updatedAt",
      filterable: false,
      flex: 1,
      renderHeader: () => <GridColumnHeader header="Updated Date" />,
      renderCell: (params: GridCellParams) => {
        if (!params.value) return "";
        const value = `${t("common:formattedDateTime", {
          date: params.row.updatedAt,
        })} - ${params.row.updatedAt.toLocaleTimeString()}`;
        return (
          <div className="w-full px-2 truncate" title={value}>
            {value}
          </div>
        );
      },
    },

    {
      field: "id",
      filterable: false,
      minWidth: 120,
      renderHeader: () => <GridColumnHeader header="Actions" />,
      renderCell: (params: GridCellParams) => {
        function handleClick(event: MouseEvent, action: string) {
          event.preventDefault();
          event.stopPropagation();
          onRowAction && onRowAction(params.row as MlModelsRunInfo, action);
        }
        return (
          <div className="flex items-center w-full gap-2 px-2 truncate">
            <Tooltip title="View detail">
              <button onClick={(event) => handleClick(event, "view")}>
                <IconEye className="flex-none w-4 h-4" />
              </button>
            </Tooltip>

            {params.row.status.toLowerCase() === "running" && (
              <Tooltip title="Terminate">
                <button
                  className="w-3 h-3 bg-red-500 hover:bg-red-600"
                  onClick={(event) => handleClick(event, "terminate")}
                />
              </Tooltip>
            )}
            {params.row.status.toLowerCase() === "success" && (
              <Tooltip title="Register model">
                <button onClick={(event) => handleClick(event, "register")}>
                  <IconBox className="w-4 h-4"/>
                </button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  return (
    <div className="w-full h-full">
      <DataGrid
        className="bg-white"
        loading={isLoading}
        rows={rows.map((row) => {
          return { ...row, id: row.runId, uuid: row.runId };
        })}
        columns={columns}
        selectionModel={selectedIds}
        checkboxSelection
        onSelectionModelChange={(model) => setSelectedIds(model as number[])}
        headerHeight={60}
      />
    </div>
  );
};

interface GridColumnHeaderProps {
  header: string;
  className?: string;
}

function GridColumnHeader({
  header,
  className = "py-2 px-0.5 font-semibold capitalize",
}: GridColumnHeaderProps) {
  return <div className={className}>{header}</div>;
}

function GridRowItem({ params }: { params: GridCellParams }) {
  return (
    <div className="px-2 truncate" title={params.value?.toString()}>
      {params.value}
    </div>
  );
}

function GridRowStatus({ params }: { params: GridCellParams }) {
  const statusClassNames = {
    initial: "bg-background-100 text-background-500",
    importing: "bg-primary-100 text-primary-500",
    running: "bg-primary-100 text-primary-500",
    exporting: "bg-primary-100 text-primary-500",
    terminating: "bg-yellow-100 text-yellow-500",
    success: "bg-green-100 text-green-500",
    successed: "bg-green-100 text-green-500",
    exported: "bg-green-100 text-green-500",
    failed: "bg-red-100 text-red-500",
    fail: "bg-red-100 text-red-500",
    error: "bg-red-100 text-red-500",
    terminated: "bg-red-100 text-red-500",
  };
  const value = params.value?.toString() || "";
  const key = value.toLowerCase();
  const statusClass = statusClassNames.hasOwnProperty(key)
    ? (statusClassNames as any)[key]
    : "";
  return (
    <div className="px-2 truncate">
      <span
        className={classNames(
          "rounded-sm text-caption-small px-1.5 py-0.5 uppercase",
          statusClass
        )}
      >
        {params.value}
      </span>
    </div>
  );
}
