/*
 * File: annotation.api.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 19th October 2021 3:50:40 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AxiosResponse } from "axios";
import { TaskRunnerType } from "domain/customer/task-runner";
import { uploadResource } from "services/storage/apis/resource.api";
import { AnnotationAPI } from "./annotation.api";

export class AnnotationV2API extends AnnotationAPI {
  async generateAnnotationFromFile(
    file: File,
    batchId: number,
    format: string
  ): Promise<AxiosResponse<any>> {
    const resource = await uploadResource(file);
    const payload = {
      batchId: batchId,
      type: TaskRunnerType.ANNOTATION_IMPORT,
      item: {
        format: format,
        storageResourceId: resource.resourceId,
      },
    };
    const url = `${this.getPath()}/job`;
    return this.post(url, payload);
  }
}
