/*
 * File: vb-section-message.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 30th August 2021 2:40:11 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useState } from "react";
import { classnames } from "utilities/classes";
import {
  IconCheckedCircle,
  IconError,
  IconInformationCircle,
  IconRefresh,
  IconWarning,
} from "../vb-icon.component";

type Appearance = "success" | "warning" | "info" | "error";

interface Props extends MessageProps {
  appearance?: Appearance;
}

export const VBSectionMessage = ({
  appearance = "success",
  ...rest
}: Props) => {
  if (appearance === "success") return <SuccessMessage {...rest} />;
  if (appearance === "error") return <ErrorMessage {...rest} />;
  if (appearance === "warning") return <WarningMessage {...rest} />;
  if (appearance === "info") return <InfoMessage {...rest} />;
  return null;
};

interface MessageProps {
  title?: string;
  description?: string;
  actions?: { text: string; onClick?(): void }[];
  showRefresh?: boolean;
  refreshAction?: () => Promise<any>;
}

export const SuccessMessage = ({
  title = "",
  description = "",
  actions = [],
}: MessageProps) => {
  return (
    <div className="flex p-4 bg-green-500 rounded bg-opacity-20">
      <IconCheckedCircle className="w-6 h-6 mx-4 text-green-600" />
      <div className="flex-1">
        <div className="text-lg font-bold">{title}</div>
        <div className="mt-2 mb-4 text-gray-800">{description}</div>
        <div className="flex items-center gap-4">
          {actions.map((action) => {
            return (
              <ActionButton
                key={action.text}
                text={action.text}
                onClick={action.onClick}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const InfoMessage = ({
  title = "",
  description = "",
  actions = [],
  showRefresh = false,
  refreshAction,
}: MessageProps) => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    if (showRefresh && refreshAction && !isRefreshing) {
      setIsRefreshing(true);
      await refreshAction();
      setIsRefreshing(false);
    }
  }

  return (
    <div className="flex p-4 bg-blue-500 rounded bg-opacity-20">
      <IconInformationCircle className="w-6 h-6 mx-4 text-blue-600" />
      <div className="flex-1">
        <div className="text-lg font-bold">{title}</div>
        <div className="mt-2 mb-4 text-gray-800">{description}</div>
        <div className="flex items-center gap-4">
          {actions.map((action) => {
            return (
              <ActionButton
                key={action.text}
                text={action.text}
                onClick={action.onClick}
              />
            );
          })}
        </div>
      </div>
      {
        showRefresh &&
        <IconRefresh
          className={classnames(
            "w-5 h-5 text-primary cursor-pointer",
            {"opacity-50": isRefreshing},
          )}
          onClick={handleRefresh}
        />
      }
    </div>
  );
};

export const WarningMessage = ({
  title = "",
  description = "",
  actions = [],
}: MessageProps) => {
  return (
    <div className="flex p-4 bg-yellow-500 rounded bg-opacity-20">
      <IconWarning className="w-6 h-6 mx-4 text-yellow-600" />
      <div className="flex-1">
        <div className="text-lg font-bold">{title}</div>
        <div className="mt-2 mb-4 text-gray-800">{description}</div>
        <div className="flex items-center gap-4">
          {actions.map((action) => {
            return (
              <ActionButton
                key={action.text}
                text={action.text}
                onClick={action.onClick}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const ErrorMessage = ({
  title = "",
  description = "",
  actions = [],
}: MessageProps) => {
  return (
    <div className="flex p-4 bg-red-500 rounded bg-opacity-20">
      <IconError className="w-6 h-6 mx-4 text-red-600" />
      <div className="flex-1">
        <div className="text-lg font-bold">{title}</div>
        <div className="mt-2 mb-4 text-gray-800">{description}</div>
        <div className="flex items-center gap-4">
          {actions.map((action) => {
            return (
              <ActionButton
                key={action.text}
                text={action.text}
                onClick={action.onClick}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

interface ActionButtonProps {
  text: string;
  onClick?(): void;
}

const ActionButton = ({ text, onClick }: ActionButtonProps) => {
  return (
    <button className="text-primary" onClick={onClick}>
      {text}
    </button>
  );
};
