import { StorageKeys } from "hooks/storage/storage-keys";
import { useAppPrevious } from "hooks/use-app-previous";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AIAssistanceSTTService } from "services/ai-assistance-service/stt.api";
import { RequestStatus } from "store/base/base.state";
import * as Sentry from "@sentry/react";


export const useTranscribeAudio = () => {
  const [useAITranscribe, setUseAITranscribe] = useState(() => {
    let value = localStorage.getItem(StorageKeys._USE_AI_TRANSCRIBE_);
    if (value !== null){
      return value.toLocaleLowerCase() === "true" ? true : false;
    }
    return false;
  });
  const prevUseAITranscribeValue = useAppPrevious(useAITranscribe);

  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const isTranscribing = useMemo(() => requestStatus === RequestStatus.LOADING, [requestStatus]);

  const transcribeAudio = useCallback(async (
    audioUrl: string,
    cachedValue?: string | undefined,
  ) => {
    if (requestStatus === RequestStatus.LOADING) return "";
    setRequestStatus(RequestStatus.LOADING);
    try {
      if (cachedValue !== undefined) {
        setRequestStatus(RequestStatus.SUCCESS);
        return cachedValue;
      }
      const res = await AIAssistanceSTTService.transcribeAudio(audioUrl);
      setRequestStatus(RequestStatus.SUCCESS);
      return res.data.text || "";
    } catch (error: any) {
      Sentry.captureException(error);
      setRequestStatus(RequestStatus.FAILURE);
      console.log(error);
      return "";
    }
  }, [requestStatus]);

  useEffect(() => {
    if (prevUseAITranscribeValue !== undefined && useAITranscribe !== prevUseAITranscribeValue){
      localStorage.setItem(StorageKeys._USE_AI_TRANSCRIBE_, useAITranscribe ? "true" : "false");
    }
  }, [useAITranscribe, prevUseAITranscribeValue]);

  return {
    prevUseAITranscribeValue,
    useAITranscribe,
    setUseAITranscribe,
    transcribeAudio,
    isTranscribing,
  }
}