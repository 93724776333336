/*
 * File: storage.constant.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 4th April 2022 11:11:24 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export const localStorageKey = {
  PREFERED_DATASOURCE_TYPE: "PREFERED_DATASOURCE_TYPE",
};
