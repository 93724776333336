export interface IssueModel {
  id: number;
  jobId?: number;
  taskId: number;

  status: string;
  posX: number;
  posY: number;
  frameId?: number;

  comments: IssueCommentModel[];
}

export interface IssueCommentModel {
  id: number;
  issueId: number;
  user: string;
  content: string;
  createdTime: Date;
}

export enum IssueStatus {
  OPENED = "Opened",
  FIXED = "Fixed",
  RESOLVED = "Resolved",
}
