/*
 * File: image-annotations-viewer.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 18th October 2022 4:34:39 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */
import * as cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import { CornerstoneHandler } from "components/dicom/cornerstone/cornerstone-handler/cornerstone-handler";
import { CornerstoneViewportEditor } from "components/dicom/cornerstone/viewport-editor/viewport-editor";
import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";
import { CornerstoneImageViewer } from "components/dicom/image-viewer/cornerstone-image-viewer";
import { useAppSelector } from "hooks/use-redux";
import { useRef } from "react";
import { selectBaseTools } from "store/labeler/image-workspace/cornerstone/cornerstone.selectors";
import {
  selectActiveCornerstoneTool,
  selectAnnotationTools,
} from "store/labeler/image-workspace/dicom-editor/dicom-editor.selectors";
import { useImageAnnotationSourcesContext } from "./context/image-annotation-sources.context";
import initCornerstone from "components/dicom/cornerstone/cornerstone.config";
import EraserTool from "components/dicom/cornerstone/tools/EraserToolExtend";
import FreehandRoiToolExtend from "components/dicom/cornerstone/tools/FreehandRoiExtend";
import HeartRuler from "components/dicom/cornerstone/tools/HeartRuler";
import MaskTool from "components/dicom/cornerstone/tools/MaskTool";
import MultiArrowConnectionTool from "components/dicom/cornerstone/tools/MultiArrowConnectionTool";
import RectangleRoiExtendTool from "components/dicom/cornerstone/tools/RectangleRoiExtend";
import useDeepCompareEffect from "use-deep-compare-effect";
import SAMTool from "components/dicom/cornerstone/tools/SAMTool";


initCornerstone();

export function ImageAnnotationsViewer() {
  const elementRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const viewportWrapperRef = useRef<HTMLDivElement>(null);
  const baseTools = useAppSelector(selectBaseTools);
  const annotateTools = useAppSelector(selectAnnotationTools);
  const activeTool = useAppSelector(selectActiveCornerstoneTool);
  const initialized = useRef(false);

  const {
    imageUrl,
    viewportEditor,
    cornerstoneHandler,
    imageAnnotations,
    setImageLoaded,
  } = useImageAnnotationSourcesContext();

  function handleImageLoaded(imageId?: string) {
    cornerstoneHandler.current?.setCurrentImageId(imageId);
    initCornerstoneTools();
    setImageLoaded(true);
  }

  function initCornerstoneTools() {
    if (!elementRef.current) return;
    clearAllToolState();
    if (initialized.current) return;
    cornerstoneTools.init({ showSVGCursors: true });
    addToolsForElement();
    const container = elementRef.current;
    const viewport = cornerstone.getViewport(elementRef.current);

    viewportEditor.current = new CornerstoneViewportEditor(container, viewport);
    cornerstoneHandler.current = new CornerstoneHandler(container, viewport);
    cornerstoneHandler.current.init({
      userId: "",
      jobId: "",
    });
    initialized.current = true;
    initToolState();
    cornerstoneHandler.current.setToolActive(activeTool);
  }

  function initToolState() {
    cornerstoneHandler.current?.setImageAnnotations(
      imageAnnotations.annotations
    );
    cornerstoneHandler.current?.setImageRelationAnnotations(
      imageAnnotations.relationAnnotations
    );
  }

  function clearAllToolState() {
    const container = elementRef.current;
    if (!container) return;
    for (let tool of baseTools) {
      const csTool = cornerstoneTools[`${tool.type}Tool`];
      cornerstoneTools.clearToolState(container, csTool);
    }
    cornerstoneTools.clearToolState(container, ToolName.FreehandRoiExtend);
    cornerstoneTools.clearToolState(container, ToolName.RectangleRoiExtend);
    cornerstoneTools.clearToolState(container, ToolName.MultiArrowConnection);
    cornerstoneTools.clearToolState(container, ToolName.Mask);
  }

  function addToolsForElement() {
    const container = elementRef.current;
    if (!container) return;
    const tools = baseTools.concat(annotateTools);
    cornerstoneTools.addToolForElement(container, MaskTool);
    cornerstoneTools.addToolForElement(
      container,
      cornerstoneTools["ZoomMouseWheelTool"],
      {
        name: "ZoomMouseWheel",
        supportedInteractionTypes: ["MouseWheel"],
        configuration: {
          minScale: 0.1,
          maxScale: 20.0,
          invert: false,
        },
      }
    );
    for (let tool of tools) {
      if (tool.type === ToolName.MultiArrowConnection) {
        cornerstoneTools.addToolForElement(container, MultiArrowConnectionTool);
      } else if (tool.type === ToolName.FreehandRoiExtend) {
        cornerstoneTools.addToolForElement(container, FreehandRoiToolExtend);
      } else if (tool.type === ToolName.RectangleRoiExtend) {
        cornerstoneTools.addToolForElement(container, RectangleRoiExtendTool);
      } else if (tool.type === ToolName.SAM) {
        cornerstoneTools.addToolForElement(container, SAMTool);
      } else if (tool.type === ToolName.HeartRuler) {
        cornerstoneTools.addToolForElement(container, HeartRuler);
      } else if (tool.type === ToolName.Eraser) {
        cornerstoneTools.addToolForElement(container, EraserTool);
      } else if (tool.type === ToolName.StackScrollMouseWheel) {
        cornerstoneTools.addToolForElement(
          container,
          cornerstoneTools.StackScrollMouseWheelTool,
          {
            configuration: {
              loop: true,
              allowSkipping: true,
            },
          }
        );
      } else {
        const csTool = cornerstoneTools[`${tool.type}Tool`];
        cornerstoneTools.addToolForElement(container, csTool);
      }
    }
  }

  function handleImageLoadError(error: any) {
    setImageLoaded(true);
  }

  useDeepCompareEffect(() => {
    cornerstoneHandler.current?.syncImageAnnotations(
      imageAnnotations.annotations
    );
    cornerstoneHandler.current?.syncImageRelationAnnotations(
      imageAnnotations.relationAnnotations
    );
  }, [imageAnnotations, cornerstoneHandler]);

  return (
    <div className="w-full h-full">
      <div
        ref={containerRef}
        className="relative flex-auto w-full h-full"
      >
        <div
          ref={viewportWrapperRef}
          className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
        >
          <CornerstoneImageViewer
            ref={elementRef}
            imageUrl={imageUrl}
            onLoaded={handleImageLoaded}
            onError={handleImageLoadError}
          />
        </div>
      </div>
    </div>
  );
}
