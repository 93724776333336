/*
 * File: selectable-column-header.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 24th November 2021 5:17:35 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { IconFilterList } from "../vb-icon.component";
import Select, { Props as SelectProps } from "react-select";
import { SortType } from "./searchable-column-header.component";

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: "36px",
    height: 36,
    fontFamily: "Mulish",
    fontSize: "14px",
    boxShadow: "none",
  }),
};

interface Props extends SelectProps {
  header: string;
  sortable?: boolean;
  containerWidth: number | string;
  sortType?: SortType;
  onSortChange?(value: SortType): void;
  showSelect?: boolean;
}
export const SelectableColumnHeader = ({
  header,
  sortable,
  containerWidth,
  sortType,
  onSortChange,
  showSelect = true,
  ...rest
}: Props) => {
  const handleClickSort = () => {
    if (sortType === "") {
      onSortChange && onSortChange("asc");
      return;
    }
    if (sortType === "asc") {
      onSortChange && onSortChange("desc");
      return;
    }
    if (sortType === "desc") {
      onSortChange && onSortChange("");
      return;
    }
  };
  return (
    <div
      className="flex flex-col flex-shrink-0 gap-2"
      style={{ width: containerWidth }}
    >
      <div className="flex items-center justify-between gap-2 text-sm">
        <span>{header}</span>
        {sortable && (
          <button
            onClick={handleClickSort}
            className="flex items-center justify-center w-5 h-5"
          >
            {sortType === "" && <IconFilterList className="w-4 h-4" />}
            {sortType === "asc" && (
              <IconFilterList className="w-4 h-4 text-primary" />
            )}
            {sortType === "desc" && (
              <IconFilterList className="w-4 h-4 transform rotate-180 text-primary" />
            )}
          </button>
        )}
      </div>
      {showSelect && <Select classNamePrefix="vb-select" styles={customStyles} {...rest} />}
    </div>
  );
};
