/*
 * File: upload-dialog.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 4th October 2021 11:47:55 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { CircularProgress, Switch } from "@material-ui/core";
import {
  ChangeEvent,
  FormEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDatasetTag } from "hooks/datasets/use-dataset-tag.hook";
import fileSize from "filesize";
import { Tag } from "models/dataset/tag.model";
import { StorageService } from "services/storage";
import { snakeCase } from "snake-case";
import {
  selectCurrentDataset,
  selectCurrentDatasetType,
  selectSupportedDatasetFileTypes,
} from "store/customer/dataset/dataset.selectors";
import { useAppSelector } from "hooks/use-redux";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import {
  IconTrash,
  IconTask,
  IconGallary,
  IconMusicFilter,
} from "components/common/vb-icon.component";
import { TYPE_OF_TIME } from "services/storage/apis/upload-files.api";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { classnames } from "utilities/classes";
import { DataType } from "domain/customer";
import { VBTabs } from "components/common/vb-tabs/vb-tabs.component";
import CreatableSelect from "react-select/creatable";
import { OnChangeValue } from "react-select";
import { Option } from "domain/common";
import { fromBuffer } from "file-type";
import { useAppContext } from "contexts/app/app.context";
import ConfigService from "configs/app-config";
enum UploadType {
  FILE = "file",
  FOLDER = "folder",
  ZIP = "zip",
}
interface SelectableTag extends Option {
  __isNew__?: boolean;
}

export interface UploadFileOptions {
  numberOfTime: number;
  typeOfTime: string;
  numberOfSentence: number;
}

interface UploadFilesDialogProps {
  visible: boolean;
  onClose(): void;
  onSubmit?(payload: { files: File[]; tagIds: number[] }): void;
  onUploadOptionsChanged?: (options: UploadFileOptions) => void;
  disabled?: boolean;
}

export const UploadFilesDialog = ({
  visible,
  onClose,
  onSubmit,
  onUploadOptionsChanged,
  disabled = false,
}: UploadFilesDialogProps) => {
  const { appConfig } = useAppContext();
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const { tags } = useDatasetTag();
  const [files, setFiles] = useState<File[]>([]);
  const acceptFileTypes = useAppSelector(selectSupportedDatasetFileTypes);
  const datasetType = useAppSelector(selectCurrentDatasetType);
  const [selectedTags, setSelectedTags] = useState<SelectableTag[]>([]);
  const currentDataset = useAppSelector(selectCurrentDataset);
  const [validating, setValidating] = useState(false);
  const FileIcon = useMemo(() => {
    if (datasetType === DataType.TEXT) {
      return IconTask;
    } else if (datasetType === DataType.AUDIO) {
      return IconMusicFilter;
    } else {
      return IconGallary;
    }
  }, [datasetType]);

  const [uploadOptions, setUploadOptions] = useState<UploadFileOptions>(() => ({
    numberOfTime: 0,
    typeOfTime: TYPE_OF_TIME.MILLISECOND,
    numberOfSentence: 0,
  }));

  const [useSplitFileAudio, setUseSplitFileAudio] = useState(false);
  const [isUploadSeries, setIsUploadSeries] = useState(false);
  const [useSplitFileText, setUseSplitFileText] = useState(false);
  const [isDraggingOver] = useState(false);
  const [uploadType, setUploadType] = useState<UploadType>(UploadType.FILE);

  // useEffect(() => {
  //   handleFilesChange(files, false);
  // }, [isUploadSeries]);

  const TYPE_OF_TIME_OPTIONS = [
    {
      label: "Millisecond",
      value: TYPE_OF_TIME.MILLISECOND,
    },
    {
      label: "Second",
      value: TYPE_OF_TIME.SECOND,
    },
    {
      label: "Minute",
      value: TYPE_OF_TIME.MINUTE,
    },
  ];

  const setOptionField = (field: string, value: any) => {
    const newValue = {
      ...uploadOptions,
      [field]: value,
    };
    setUploadOptions(newValue);
    onUploadOptionsChanged && onUploadOptionsChanged(newValue);
  };

  const handleTypeOfTimeChanged = (newValue: any) => {
    if (newValue && newValue.value) {
      setOptionField("typeOfTime", newValue.value);
    }
  };

  const handleUseSplitAudioFile = (newValue: boolean) => {
    setUseSplitFileAudio(newValue);
    if (!newValue) {
      setOptionField("numberOfTime", 0);
    }
  };

  const handleIsUploadSeries = (newValue: boolean) => {
    setIsUploadSeries(newValue);
  };

  const handleUseSplitTextFile = (newValue: boolean) => {
    setUseSplitFileText(newValue);
    if (!newValue) {
      setOptionField("numberOfSentence", 0);
    } else {
      setOptionField("numberOfSentence", 1);
    }
  };

  function handleTagsChange(newValue: OnChangeValue<SelectableTag, true>) {
    const items = newValue.flat();
    setSelectedTags(items);
  }

  function removeFile(file: File) {
    setFiles([...files.filter((f) => f.name !== file.name)]);
  }

  async function handleSubmit(event: FormEvent) {
    if (!currentDataset) return;
    event.preventDefault();
    if (processing) return;
    setProcessing(true);
    const newFileTags = await createTagsIfNotExist();
    const payload = {
      datasetId: currentDataset.id,
      files,
      tagIds: newFileTags.map((tag) => tag.id),
      isUploadZip: uploadType === "zip",
      isUploadSeries
    };
    onSubmit && onSubmit(payload);
    setProcessing(false);
  }

  async function createTagsIfNotExist() {
    const items = [];
    for (let tag of selectedTags) {
      if (tag.__isNew__) {
        const name = tag.label.trim();
        const payload = { name, code: snakeCase(name) };
        const newCreatedTag = await StorageService.createTag(payload);
        const newTag = newCreatedTag.data as Tag;
        items.push({ id: newTag.id, name: newTag.name });
      } else {
        items.push({ id: parseInt(tag.value), name: tag.label });
      }
    }
    return items;
  }

  async function handleFilesChange(selectedFiles: File[], keepOldFiles: boolean) {
    setValidating(true);
    const blockedFiles: File[] = [];
    if (!selectedFiles) return;
    let newFiles = keepOldFiles ? [...files] : [];
    const isUploadZip = uploadType === "zip";

    let maxFileSize = ConfigService.getFileUploadLimit(appConfig, datasetType);

    if (isUploadZip) {
      newFiles = [];
      maxFileSize = ConfigService.getZipFileUploadLimit(appConfig, datasetType);
    }

    if (datasetType === DataType.WSI) {
      maxFileSize = Infinity;
    }

    for (let file of selectedFiles) {
      const fileType = await getFileMimeType(file);
      if (!isValidFileType(fileType, file)) continue;
      if (file && newFiles.findIndex((f) => f.name === file.name) === -1) {
        if (file.size > maxFileSize) {
          blockedFiles.push(file);
        } else {
          newFiles.push(file);
        }
      }
    }
    setValidating(false);
    setFiles(newFiles);
  }

  async function getFileMimeType(file: File) {
    try {
      if (file.size >= 2e9) {
        return file.type;
      }
      const fileType = await fromBuffer(await file.arrayBuffer());
      return fileType?.mime;
    } catch (error) {
      return file.type;
    }
  }

  function isValidFileType(mimeType: string | undefined, file?: File) {
    if (uploadType === UploadType.ZIP) {
      return mimeType && mimeType === "application/zip";
    }

    if (datasetType === DataType.IMAGE) {
      return mimeType && mimeType.includes("image");
    } else if (datasetType === DataType.DICOM) {
      return (
        mimeType &&
        (mimeType.includes("image") ||
          mimeType.includes("dicom") ||
          mimeType.includes("dcm"))
      );
    } else if (datasetType === DataType.AUDIO) {
      return true;
    } else if (datasetType === DataType.TEXT) {
      return !mimeType;
    } else if (datasetType === DataType.MDI) {
      if (!isUploadSeries && file) {
        return file.name.includes("nrrd") || file.name.includes("nii");
      }
      return (
        mimeType &&
        (mimeType.includes("dicom") ||
          mimeType.includes("dcm") ||
          mimeType.includes("gzip"))
      );
    } else if (datasetType === DataType.WSI) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    setFiles([]);
  }, [uploadType]);
  return (
    <VBModal
      open={visible}
      title={t("dataset:upload.uploadTitle")}
      onClose={onClose}
      onSubmit={handleSubmit}
      textSubmit={t("common:buttonOk")}
      disableSubmit={processing || files.length === 0 || disabled}
      disableCloseButton={disabled}
      width="32rem"
    >
      <div className="space-y-4">
        <VBTabs
          className="text-sm"
          activeTab={uploadType}
          onSelect={(val) => setUploadType(val as UploadType)}
          tabs={[
            { name: "File", id: UploadType.FILE },
            { name: "Folder", id: UploadType.FOLDER },
            { name: "Zip", id: UploadType.ZIP },
          ]}
        />

        <div className="space-y-2">
          <label>{t("dataset:upload.tags")}</label>
          <CreatableSelect
            className="text-sm"
            classNamePrefix="vb-select"
            isMulti
            placeholder={t("dataset:upload.placeholderSelectTag")}
            onChange={handleTagsChange}
            menuPortalTarget={document.body}
            options={tags.map((tag) => ({
              label: tag.name,
              value: tag.id.toString(),
            }))}
          />
        </div>
        <div className="relative">
          {files.length === 0 && (
            <UploadForm
              validating={validating}
              uploadType={uploadType}
              datasetType={datasetType}
              acceptFileTypes={acceptFileTypes}
              onFilesChange={handleFilesChange}
              isUploadSeries={isUploadSeries}
            />
          )}
        </div>

        {files.length > 0 && (
          <div className="flex items-center gap-4">
            <div className="flex-auto">{files.length} files were selected </div>
            <UploadInput
              uploadType={uploadType}
              acceptFileTypes={acceptFileTypes}
              onFilesChange={handleFilesChange}
            />
          </div>
        )}
        <div className="relative">
          {files.length > 0 && (
            <div
              className={classnames(
                "border border-dashed rounded-lg px-2 max-h-60 overflow-auto light-scrollbar",
                {
                  "border-gray-200": !isDraggingOver,
                  "border-primary-500": isDraggingOver,
                }
              )}
            >
              <div className="py-2">
                {files.map((file) => {
                  return (
                    <div
                      className="flex items-center px-3 py-1 text-sm border-b border-background-100 hover:bg-secondary-100 group"
                      key={file.name}
                    >
                      <div className="flex flex-row items-center flex-1 gap-2 overflow-x-hidden text-background-500">
                        <FileIcon className="flex-none w-6 h-6" />
                        <div className="flex flex-col items-start justify-start overflow-hidden">
                          <h3 className="overflow-x-hidden truncate whitespace-nowrap overflow-ellipsis">
                            {file.name}
                          </h3>
                          <div className="flex items-center text-xs">
                            {fileSize(file.size)}
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => removeFile(file)}
                        className="flex items-center justify-center flex-none w-6 h-6 opacity-0 group-hover:text-error-500 group-hover:opacity-100"
                      >
                        <IconTrash className="flex-none w-4 h-4" />
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {
          (datasetType === DataType.MDI && uploadType !== UploadType.ZIP) && (
            <div className="flex items-center justify-between w-full gap-2 my-4">
              <div className="flex items-center text-sm">
                <Switch
                  value={isUploadSeries}
                  onChange={(_, v) => handleIsUploadSeries(v)}
                  color="primary"
                />
                <p>{t("dataset:upload.uploadMdiOption")}</p>
              </div>
            </div>
          )
        }
        {datasetType === DataType.AUDIO && (
          <div className="flex items-center justify-between w-full gap-2 my-4">
            <div className="flex items-center text-sm">
              <Switch
                value={useSplitFileAudio}
                onChange={(_, v) => handleUseSplitAudioFile(v)}
                color="primary"
              />
              <p>{t("dataset:upload.switchTextSplitFile")}</p>
            </div>
            <div className="flex items-center justify-between rounded-lg">
              <input
                className="self-center block text-center border-t border-b border-l rounded rounded-r-none outline-none w-14 h-9 border-background-300 input-clean"
                type="number"
                min={0}
                value={uploadOptions.numberOfTime}
                onChange={(e) =>
                  setOptionField("numberOfTime", parseInt(e.target.value))
                }
              />
              <VBSelectComponent
                className="w-32"
                value={TYPE_OF_TIME_OPTIONS.find(
                  (item) => item.value === uploadOptions.typeOfTime
                )}
                onChange={handleTypeOfTimeChanged}
                header={""}
                options={TYPE_OF_TIME_OPTIONS}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
        )}

        {datasetType === DataType.TEXT && (
          <div className="flex items-center justify-between w-full gap-4 my-4">
            <div className="flex items-center text-sm">
              <Switch
                value={useSplitFileText}
                onChange={(_, v) => handleUseSplitTextFile(v)}
                color="primary"
              />
              <p>{t("dataset:upload.switchTextSplitSentence")}</p>
            </div>
            {useSplitFileText && (
              <input
                className="self-center block w-20 px-3 py-1 border rounded input-clean"
                type="number"
                min={0}
                value={uploadOptions.numberOfSentence}
                onChange={(e) =>
                  setOptionField("numberOfSentence", parseInt(e.target.value))
                }
              />
            )}
          </div>
        )}
      </div>
    </VBModal>
  );
};

interface UploadFormProps {
  datasetType: DataType;
  acceptFileTypes: string;
  uploadType: "file" | "folder" | "zip";
  onFilesChange(files: File[], keepOldFile: boolean): void;
  validating?: boolean;
  isUploadSeries?: boolean;
}

export const UploadForm = ({
  datasetType,
  uploadType,
  acceptFileTypes,
  onFilesChange,
  validating,
  isUploadSeries
}: UploadFormProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const files: File[] = [];
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles.item(i);
        if (file) files.push(file);
      }
    }
    onFilesChange(files, true);
    setIsDraggingOver(false);
  }

  return (
    <div className="space-y-2">
      <div
        className={classnames(
          "relative flex items-center justify-center h-40 bg-white border border-dashed rounded-lg hover:cursor-pointer",
          {
            "border-gray-200": !isDraggingOver,
            "border-primary-500": isDraggingOver,
          }
        )}
      >
        {!validating && (
          <div className="absolute">
            <div className="flex flex-col items-center">
              {!isDraggingOver && (
                <>
                  {uploadType === "file" && (
                    <>
                      <span>{t("dataset:upload.attachFiles")}</span>
                      <span>{t("dataset:upload.or")}</span>
                      <span className="text-primary">
                        {t("dataset:upload.browseFiles")}
                      </span>
                    </>
                  )}
                  {uploadType === "folder" && (
                    <>
                      <span>Select folder</span>
                    </>
                  )}
                  {uploadType === "zip" && (
                    <>
                      <span>Drop zip file here</span>
                      <span>{t("dataset:upload.or")}</span>
                      <span className="text-primary">Browse</span>
                    </>
                  )}
                </>
              )}
              {isDraggingOver && (
                <span className="block font-normal text-primary">
                  {t("dataset:upload.dropFiles")}
                </span>
              )}
            </div>
          </div>
        )}
        {uploadType === "file" && (
          <input
            ref={inputRef}
            type="file"
            onChange={handleChange}
            multiple
            accept={acceptFileTypes}
            onDragEnter={() => setIsDraggingOver(true)}
            onDragExit={() => setIsDraggingOver(false)}
            className="w-full h-full opacity-0"
          />
        )}
        {uploadType === "folder" && (
          <input
            ref={inputRef}
            type="file"
            onChange={handleChange}
            multiple
            directory=""
            webkitdirectory=""
            accept={acceptFileTypes}
            onDragEnter={() => setIsDraggingOver(true)}
            onDragExit={() => setIsDraggingOver(false)}
            className="w-full h-full opacity-0"
          />
        )}
        {uploadType === "zip" && (
          <input
            ref={inputRef}
            type="file"
            onChange={handleChange}
            accept=".zip"
            onDragEnter={() => setIsDraggingOver(true)}
            onDragExit={() => setIsDraggingOver(false)}
            className="w-full h-full opacity-0"
          />
        )}

        {validating && (
          <div className="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full bg-black text-primary bg-opacity-5">
            <CircularProgress color="inherit" size={20} />
          </div>
        )}
      </div>

      <p className="text-xs text-background-500">
        {datasetType === DataType.IMAGE && (
          <span>PNG, JPEG, and JPG files are allowed. Max File Size: 50MB</span>
        )}
        {datasetType === DataType.DICOM && (
          <span>
            PNG, JPEG, JPG, BMP and DICOM files are allowed. Max File Size: 50MB
          </span>
        )}
        {
          (datasetType === DataType.MDI && uploadType !== UploadType.ZIP) && (
            <>
              {isUploadSeries
                ? <span>Dicom files are allowed.</span>
                : <span>Nifti, nrrd files are allowed.</span>
              }
            </>
          )
        }
        {datasetType === DataType.TEXT && <span>.txt file is allowed.</span>}
        {datasetType === DataType.AUDIO && (
          <span>MP3 and WAV files are allowed. Max File Size: 50MB</span>
        )}
      </p>
      {(uploadType !== UploadType.FILE || datasetType === DataType.MDI) && (
        <p className="text-xs text-background-500">
          Note: unsupported files will be ignored.
        </p>
      )}
    </div>
  );
};

interface UploadInputProps {
  acceptFileTypes: string;
  uploadType: "file" | "folder" | "zip";
  onFilesChange(files: File[], keepOldFiles: boolean): void;
}

export const UploadInput = ({
  acceptFileTypes,
  uploadType,
  onFilesChange,
}: UploadInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const files: File[] = [];
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles.item(i);
        if (file) files.push(file);
      }
    }
    onFilesChange(files, true);
  }

  return (
    <div className="relative button-tertiary hover:underline">
      <span className="px-2">{t("dataset:upload.addMore")}</span>
      <div className="absolute">
        {uploadType === "file" && (
          <input
            ref={inputRef}
            type="file"
            onChange={handleChange}
            multiple
            accept={acceptFileTypes}
            className="w-full h-full opacity-0"
          />
        )}
        {uploadType === "folder" && (
          <input
            ref={inputRef}
            type="file"
            onChange={handleChange}
            multiple
            directory=""
            webkitdirectory=""
            accept={acceptFileTypes}
            className="w-full h-full opacity-0"
          />
        )}
        {uploadType === "zip" && (
          <input
            ref={inputRef}
            type="file"
            onChange={handleChange}
            accept=".zip"
            className="w-full h-full opacity-0"
          />
        )}
      </div>
    </div>
  );
};
