import { Tooltip } from "@material-ui/core";
import { ColumnHeaderWrapper } from "components/common/vb-grid/column-wrapper.component";
import { SearchableColumnHeader } from "components/common/vb-grid/searchable-column-header.component";
import { IconTrash } from "components/common/vb-icon.component";
import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { useTranslation } from "react-i18next";
import { MlModelsRegisteredModelVersionUIModel } from "./registered-models.models";

interface Props {
  rows: MlModelsRegisteredModelVersionUIModel[];
  isLoading?: boolean;
  onRowAction?: (row: MlModelsRegisteredModelVersionUIModel, action: "delete") => void;
}
export const RegisteredModelsTable = ({
  rows,
  isLoading = false,
  onRowAction,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="min-w-full">
      <table className="min-w-full text-sm vb-table">
        <thead>
          <tr>
            <ColumnHeaderWrapper first>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={false}
                placeholder="Enter name"
                header="Name"
                containerWidth="100%"
                type="text"
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={false}
                placeholder="Enter version id"
                header="Version id"
                containerWidth="100%"
                type="text"
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper last>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={false}
                placeholder="Enter date"
                header="Version created date"
                containerWidth="100%"
                type="text"
              />
            </ColumnHeaderWrapper>
          </tr>
        </thead>
        <tbody className="relative">
          {
            !isLoading && rows.length === 0 &&
            <tr>
              <td colSpan={3}>
                <div className="flex justify-center items-center">No row</div>
              </td>
            </tr>
          }
          {
            !isLoading && rows.length > 0 &&
            rows.map((row) => {
              return (
                <tr
                  key={`${row.modelName}_${row.versionId}_${row.versionCreatedAt.toISOString()}`}
                  className="relative h-10 hover:bg-secondary-50 parent"
                >
                  <td className="pl-4 pr-4" style={{ minWidth: 100 }}>
                    {row.modelName}
                  </td>
                  <td className="pr-4" style={{ minWidth: 100 }}>
                    {row.versionId}
                  </td>
                  <td className="pr-4" style={{ minWidth: 200 }}>
                    {`${t("common:formattedDateTime", { date: row.versionCreatedAt })} - ${row.versionCreatedAt.toLocaleTimeString()}`}
                  </td>
                  <td
                    className="absolute h-full transform -translate-x-full -translate-y-1/2 top-1/2 child-on-hover"
                    style={{ zIndex: "999" }}
                  >
                    <div className="flex flex-row items-center h-full gap-3 px-3 text-primary bg-secondary-50">
                      <Tooltip
                        title="Delete"
                        placement="top"
                      >
                        <button>
                          <IconTrash
                            onClick={(_) =>
                              onRowAction && onRowAction(row, "delete")
                            }
                            className="w-4 h-4 text-error-500"
                          />
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              )
            })
          }
          {isLoading && (
            <tr>
              <td colSpan={3}>
                <VBInlineLoading />
              </td>
            </tr>
          )}
          <tr className="absolute top-0 left-0 w-full h-full border rounded pointer-events-none border-background-300"></tr>
        </tbody>
      </table>
    </div>
  );
};
