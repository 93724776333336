/*
 * File: step-transitions.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 17th August 2021 3:28:21 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useStepConditions } from "hooks/workflow/use-step-conditions.hook";
import { useEffect } from "react";
import { useState } from "react";
import { WorkflowInstructionDTO } from "services/label-service/dtos";
import { VBCheckbox } from "components/design-system/checkbox/checkbox.component";

interface Props {
  size?: "sm" | "md" | "lg" | "xl";
  instructions: WorkflowInstructionDTO[];
  onSelect?(instruction: WorkflowInstructionDTO): void;
}

export const StepTransitions = ({
  instructions,
  onSelect,
  size = "lg",
}: Props) => {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<WorkflowInstructionDTO[]>([]);
  const { bindConditions } = useStepConditions();

  function handleSelectInstruction(instruction: WorkflowInstructionDTO) {
    onSelect && onSelect(instruction);
  }

  useEffect(() => {
    const items = [...instructions];
    setSteps(items.sort((a, b) => a.step - b.step));
  }, [instructions]);

  const classSize = {
    sm: "w-48 h-40",
    md: "w-64 h-48",
    lg: "w-72 h-56",
    xl: "w-80 h-60",
  };

  return (
    <div className="flex items-center w-full gap-4 py-4">
      {steps.map((step, index) => {
        return (
          <div key={step.id} className="flex items-center">
            {index > 0 && (
              <div className="flex flex-col items-center mx-8">
                <div>
                  <i className="text-3xl uir-arrow-right text-background-500"></i>
                </div>
                <div className="whitespace-nowrap text-background-500">
                  {t("workflow:textTransitionCondition")}:
                </div>
                <div
                  className="px-4 py-4 text-center capitalize"
                  style={{ maxWidth: "16rem" }}
                >
                  {bindConditions(step.condition)}
                </div>
              </div>
            )}

            <div
              className={`flex flex-col overflow-hidden bg-white border rounded border-background-300 ${classSize[size]}`}
              onClick={() => handleSelectInstruction(step)}
            >
              <div className="flex-shrink-0 px-4 py-2 overflow-hidden border-b text-primary border-background-300 whitespace-nowrap overflow-ellipsis bg-secondary-50">
                {step.name}
              </div>
              <div className="flex-grow px-4 py-4 overflow-y-auto">
                <p className="text-sm text-background-500">
                  <span>Round(s): </span> {step.roundNumber}
                </p>
                {
                  (step?.scoreThreshold || 0) > 0 &&
                  <p className="text-sm text-background-500">
                    <span>Score threshold: </span> {step.scoreThreshold}
                  </p>
                }
                {step.canViewPreviousStepResult && (
                  <div className="flex items-center gap-1 mt-2 text-sm text-background-500">
                    <VBCheckbox value={true} className="w-4 h-4" />
                    <span>{t("workflow:instruction.headerShowPrevious")}</span>
                  </div>
                )}
                <div
                  className="ck-content"
                  dangerouslySetInnerHTML={{ __html: step.description }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
