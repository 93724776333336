import { useEffect, useState } from "react";
import classnames from "classnames";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  IconArrowDown,
  IconArrowUp,
} from "components/common/vb-icon.component";
import { RouteData } from "configs/router.config";
import { useHistory } from "hooks/use-history";
import { useDebouncedClickHandler } from "hooks/use-navigation-debounce";

interface SidebarItemProps {
  item: RouteData;
  collapsedSidebar: boolean;
}

export const SidebarItem = ({ item, collapsedSidebar }: SidebarItemProps) => {
  const history = useHistory();
  const { pathname } = history;

  const [isOpen, setIsOpen] = useState(false);
  const hasNestestItems = item?.children && item.children.length > 0;
  const active = !hasNestestItems && item.path === pathname;

  const handleClickItem = useDebouncedClickHandler(2, 2000);

  const handleClick = () => {
    if (hasNestestItems) {
      setIsOpen(!isOpen);
    } else {
      handleClickItem(item.path, active);
    }
  };

  useEffect(() => {
    if (pathname.includes(item.path)) {
      setIsOpen(true);
    }
  }, [pathname, item.path]);

  return (
    <div
      className={classnames(
        "w-full overflow-hidden text-background-600",
        hasNestestItems && isOpen && "text-primary-500",
        active && "bg-primary-100 text-primary-500"
      )}
    >
      <ListItem
        button
        className="flex flex-row items-center justify-start gap-0"
        onClick={handleClick}
        disableGutters
      >
        {item.icon || item.activeIcon ? (
          <ListItemIcon className="justify-center">
            {active || (hasNestestItems && isOpen)
              ? item.activeIcon
              : item.icon}
          </ListItemIcon>
        ) : (
          <div className="w-7" />
        )}

        <ListItemText className="text-base font-bold">
          {item.displayName}
        </ListItemText>
        {hasNestestItems &&
          (!isOpen ? (
            <IconArrowDown className="flex-none w-6 h-6 mr-2" />
          ) : (
            <IconArrowUp className="flex-none w-6 h-6 mr-2" />
          ))}
      </ListItem>

      {hasNestestItems && (
        <Collapse
          in={isOpen}
          className="ml-5 border-l border-background-300"
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {item.children &&
              item.children.map((nestedItem) => {
                if (!nestedItem) return null;
                return (
                  <div key={nestedItem.path} className="pl-2">
                    <SidebarItem
                      item={nestedItem}
                      collapsedSidebar={collapsedSidebar}
                    />
                  </div>
                );
              })}
          </List>
        </Collapse>
      )}
    </div>
  );
};
