/*
 * File: smart-labeling.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th December 2021 4:42:32 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "store";

export const selectSmartLabelingEnabled = (state: RootState) => {
  return state.smartLabeling.smartLabelingEnable;
};

export const selectAutoAnnotateEnabled = (state: RootState) => {
  return state.smartLabeling.autoAnnotateEnable;
};

export const selectSmartLabelingStatus = (state: RootState) => {
  return state.smartLabeling.status;
};

export const selectSmartLabelingJobId = (state: RootState) => {
  return state.smartLabeling.jobId;
};

export const selectSmartLabelingData = (state: RootState) => {
  return state.smartLabeling;
};
