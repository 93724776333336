import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useExportedDatasets } from "hooks/datasets/use-exported-datasets.hook";
import { useEffect, useMemo, useState } from "react";
import { useMLModelContext } from "../ml-models.context";
import { MlModelsTuningParams } from "../ml-models.models";
import { TuningParamsComponent } from "./tuning-params.component";
import { useAppDispatch } from "../../../../hooks/use-redux";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "../../../../store/common/notification/notification.actions";
import { useTranslation } from "react-i18next";
import {
  MlRunRequest,
  MlRunType,
} from "../../../../services/ml-service/ml-service.dto";
import { mlServiceApis } from "../../../../services/ml-service/ml.service";
import * as Sentry from "@sentry/react";

interface Props {
  open: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
  experimentId: string;
}
export const RunNewExperimentModal = ({
  open,
  onClose,
  onSubmitted,
  experimentId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { pipelineOptions } = useMLModelContext();
  // dataset
  const { exportedDatasets } = useExportedDatasets(true);
  const datasetOptions = useMemo(() => {
    return exportedDatasets.map((d) => ({
      label: d.name,
      value: d,
    }));
  }, [exportedDatasets]);
  const [selectedDataset, setSelectedDataset] = useState(() => {
    if (exportedDatasets.length > 0) return exportedDatasets[0];
    return undefined;
  });
  // dataset versions
  const versionOptions = useMemo(() => {
    const versions = selectedDataset?.versions || [];
    return versions.map((v) => ({
      label: v.datasetVersion,
      value: v,
    }));
  }, [selectedDataset]);
  const [selectedVersion, setSelectedVersion] = useState(() => {
    if (versionOptions.length > 0)
      return versionOptions[versionOptions.length - 1].value;
    return undefined;
  });

  const [selectedPipelineOption, setSelectedPipelineOption] = useState(() => {
    if (pipelineOptions.length > 0) return pipelineOptions[0];
    return undefined;
  });

  // tuning parameters
  const [tuningParams, setTuningParams] = useState<MlModelsTuningParams>(() => {
    return {
      epochs: 20,
      batchSize: 4,
      imageSize: 512,
      learningRate: 1e-5,
      learningRateBackbone: 1e-5,
    };
  });

  const [isProcessing, setIsProcessing] = useState(false);

  const isValid = useMemo(() => {
    return (
      !!selectedPipelineOption &&
      !!selectedVersion &&
      tuningParams.epochs > 0 &&
      tuningParams.batchSize > 0 &&
      tuningParams.imageSize > 0
    );
  }, [selectedPipelineOption, selectedVersion, tuningParams]);

  const handleSubmit = async () => {
    try {
      setIsProcessing(true);
      if (!selectedPipelineOption || !selectedVersion) {
        setIsProcessing(false);
        return;
      }
      const payload: MlRunRequest = {
        pipeline_id: selectedPipelineOption.value.id,
        experiment_id: experimentId,
        run_type: MlRunType.TRAIN_EVAL,
        run_inputs: [
          {
            name: "coco_dataset",
            value: selectedVersion.location,
          },
          {
            name: "dataset_id",
            value: selectedVersion.datasetId.toString(),
          },
          {
            name: "dataset_version_id",
            value: selectedVersion.id.toString(),
          },
          {
            name: "epochs",
            value: tuningParams.epochs.toString(),
          },
          {
            name: "learning_rate",
            value: tuningParams.learningRate.toString(),
          },
          {
            name: "learning_rate_backbone",
            value: tuningParams.learningRateBackbone.toString(),
          },
        ],
      };
      await mlServiceApis.createRunForExperiment(payload);

      dispatch(enqueueSuccessNotification(t("common:textSuccess")));

      onSubmitted && onSubmitted();
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (versionOptions.length === 0) {
      setSelectedVersion(undefined);
      return;
    }
    setSelectedVersion(versionOptions[0].value);
  }, [selectedDataset, versionOptions]);

  return (
    <VBModal
      title="Run new experiment"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      disableSubmit={isProcessing || !isValid}
      width="30rem"
    >
      <div className="flex flex-col gap-6">
        <p className="truncate">Experiment: {experimentId}</p>
        <div className="space-y-2">
          <label>Pipeline</label>
          <VBSelectComponent
            options={pipelineOptions}
            value={pipelineOptions.find(
              (op) => op.value.id === selectedPipelineOption?.value?.id
            )}
            onChange={(op: any) => setSelectedPipelineOption(op)}
            menuPortalTarget={document.body}
          />
        </div>
        <div className="space-y-2">
          <label>Dataset</label>
          <VBSelectComponent
            options={datasetOptions}
            value={datasetOptions.find(
              (op) => op.value.id === selectedDataset?.id
            )}
            onChange={(op: any) => setSelectedDataset(op.value)}
            menuPortalTarget={document.body}
          />
        </div>
        <div className="space-y-2">
          <label>Dataset version</label>
          <VBSelectComponent
            options={versionOptions}
            value={versionOptions.find(
              (op) => op.value.id === selectedVersion?.id
            )}
            onChange={(op: any) => setSelectedVersion(op.value)}
            menuPortalTarget={document.body}
          />
        </div>
        <TuningParamsComponent
          value={tuningParams}
          onChanged={setTuningParams}
        />
      </div>
    </VBModal>
  );
};
