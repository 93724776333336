import { IconArrowDown } from "components/common/vb-icon.component";
import { useMemo } from "react";
import { classnames } from "utilities/classes";
import { getAllChildren } from "./fabric/fabric-object.utils";
import { ICONS_MAP_ANNOTATION } from "./pathology-editor-annotations.component";
import { PathologyAnnotation, PathologyAnnotationAdditionalData } from "./pathology-editor.models";
import { usePathologyEditorContext } from "./pathology-editor.provider"
import { fabricObjectToAnnotation } from "./pathology-editor.utils";



export const PathologyEditorHierarchyComponent = () => {
  const {
    deselectAllObjects,
    annotations,
    selectObjects,
    syncAnnotationsFromFabricObjects,
  } = usePathologyEditorContext();

  const sortedAnnotations = useMemo(() => {
    const sortedNodes: fabric.Object[] = [];

    // Just use DFS to get the order that we wanted
    // Because we don't have the root node so just get the first level nodes :))
    const stack = annotations.map(anno => anno.fabricObjectRef)
                             .filter(o => !!o && !!!o.data?.parent )
                             .reverse();
    // Here we don't need to mark visited because
    // we have a tree not a graph so, we guarantee each node visit only once
    while (stack.length > 0) {
      const node = stack.pop();
      if (!node) continue;
      if (!node.data.level) node.data.level = 1;

      if (node.data.children && node.data.children.length > 0) {
        for (let i=node.data.children.length - 1; i>=0; i--) {
          const childNode = node.data.children[i];
          childNode.data.level = node.data.level + 1;
          stack.push(childNode);
        }
      }

      sortedNodes.push(node);
    }

    return sortedNodes.map(fabricObjectToAnnotation);

  }, [annotations]);

  const handleSelectAnnotation = (annotation: PathologyAnnotation) => {
    if (!annotation.fabricObjectRef) return;

    deselectAllObjects();
    selectObjects([annotation.fabricObjectRef]);
  }

  const handleClickToggleShowChildren = (anno: PathologyAnnotation, showChildren: boolean) => {
    const annoData = anno.fabricObjectRef?.data as PathologyAnnotationAdditionalData;
    if (!annoData) return;

    annoData.childrenVisibility = showChildren;

    const children = getAllChildren(anno.fabricObjectRef);
    for (const child of children) {
      child.data.inHierarchyVisibility = showChildren;
    }

    syncAnnotationsFromFabricObjects({});
  }

  return (
    <div className="flex flex-col gap-2">
      <div>
        <span>Total: {annotations.length}</span>
      </div>

      <div
        className="overflow-y-auto"
        style={{maxHeight: 300}}
      >
        {
          sortedAnnotations.map(anno => {
            const annoData = anno.fabricObjectRef?.data as PathologyAnnotationAdditionalData;
            if (!anno.fabricObjectRef?.visible || annoData.inHierarchyVisibility === false) return null;
            const selected = !!annoData.selected;
            const hasChildren = annoData.children && annoData.children.length > 0;
            const showChildren = annoData.childrenVisibility === undefined || annoData.childrenVisibility;

            return (
              <div 
                key={anno.id}
                className={classnames(
                  "flex items-center",
                  {"bg-background-600": selected}
                )}
                style={{
                  paddingLeft: `${((annoData.level || 1) - 1) * 24}px`
                }}
              >
                {
                  hasChildren &&
                  <IconArrowDown
                    className={classnames(
                      "w-4 h-4 cursor-pointer transform",
                      {"-rotate-90": showChildren}
                    )}
                    onClick={_ => handleClickToggleShowChildren(anno, !showChildren)}
                  />
                }

                <div 
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={_ => handleSelectAnnotation(anno)}
                >
                  <div
                    style={{
                      color: annoData.labelColor
                    }}
                  >
                    {ICONS_MAP_ANNOTATION[anno.type as string]}
                  </div>
                  <span>{annoData.labelName}</span>
                </div>
              </div>
            )
          })
        }
      </div>

      <div>
        <button
          className="button-warn-secondary"
        >
          Resolve hierarchy
        </button>
      </div>
    </div>
  )
}
