/*
 * File: date-input.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 6th December 2021 1:35:56 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { IconCalendar, IconClose } from "components/common/vb-icon.component";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

interface Props extends ReactDatePickerProps {
  header?: string;
  clearable?: boolean;
  icon?: JSX.Element;
  containerClassName?: string;
  defaultValue?: Date | null;
}

export const DateInputComponent = ({
  containerClassName = "",
  header,
  defaultValue,
  clearable,
  icon = <IconCalendar className="flex-shrink-0 w-4 h-4" />,
  onChange,
  ...rest
}: Props) => {
  return (
    <div className={`flex flex-col flex-shrink-0 gap-2 ${containerClassName}`}>
      {header && (
        <div className="flex items-center justify-between gap-2 text-sm font-semibold">
          {header}
        </div>
      )}
      <div className="flex items-center w-full gap-2 px-4 overflow-hidden bg-white border border-gray-300 rounded h-9 focus-within:border-primary">
        {icon}
        <div className="flex-1">
          <DatePicker
            className="text-sm focus:outline-none"
            selected={defaultValue}
            onChange={onChange}
            dateFormat="dd/LLL/yyyy"
            {...rest}
          />
        </div>
        {clearable && defaultValue && (
          <button
            onClick={() => onChange && onChange(null, undefined)}
            className="flex-shrink-0 w-4 h-4"
          >
            <IconClose className="w-4 h-4" />
          </button>
        )}
      </div>
    </div>
  );
};
