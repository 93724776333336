import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { IOUPair, JobOption } from "domain/common/models";
import { Annotation } from "domain/image-labeling/annotation";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";
import { AnnotationSource } from "domain/labeling/annotation-source";
import { Job } from "domain/labeling/job";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { AnnotationsService } from "services/label-service";
import {
  BatchObservationDTO,
  ObservationDTO,
  TaskDTO,
} from "services/label-service/dtos";
import { AnnotationJobResponseDTO } from "services/label-service/dtos/annotations.dto";
import {
  MODEL_ASSIGNEE,
  SYSTEM_ASSIGNEE,
} from "services/label-service/dtos/task-observation-v3.dto";
import { imageLabelMapper } from "services/label-service/mappers/image-label.mapper";
import { StorageService } from "services/storage";
import { RequestStatus } from "store/base/base.state";
import { annotationUtils } from "utilities/annotations/annotation-util";
import { AppError } from "utilities/errors/errors";
import { truncateEmail } from "utilities/string/truncate-email";
import {
  TasksReviewState,
  TaskReviewBatchObservationModel,
} from "./tasks-review.state";
import * as Sentry from "@sentry/react";
import { ImageLabelingJobMetadata } from "store/labeler/image-workspace/batch-labeling/batch-labeling.state";
import { labelingUtils } from "utilities/annotations/labeling-utils";

const SLICE_NAME = "tasks-review";

interface LoadTaskReviewResponse {
  error: AppError | undefined;
  file: StorageFileDTO | undefined;
  task: TaskDTO | undefined;
  jobOptions: JobOption[];
  taskReviewBatchObservations: TaskReviewBatchObservationModel[];
  annotations: Annotation[];
  relationAnnotations: RelationAnnotation[];
  metadata?: ImageLabelingJobMetadata;
}

const getEmptyResponse = () => {
  const res: LoadTaskReviewResponse = {
    error: undefined,
    file: undefined,
    task: undefined,
    jobOptions: [],
    taskReviewBatchObservations: [],
    annotations: [],
    relationAnnotations: [],
    metadata: undefined,
  };
  return res;
};
interface LoadTasksReviewPayload {
  task: TaskDTO;
  batchObservations: BatchObservationDTO[];
  // include only annotations/taskObservations that have jobId in this array
  // use for show review job
  jobIdsIncludedOnly?: number[];
}
export const loadTasksReviewDataAsync = createAsyncThunk(
  `${SLICE_NAME}/loadTasksReviewDataAsync`,
  async ({
    task,
    batchObservations = [],
    jobIdsIncludedOnly,
  }: LoadTasksReviewPayload) => {
    const taskReviewRes = getEmptyResponse();
    taskReviewRes.task = task;

    try {
      const p1 = StorageService.getFileInfoDetails(task.taskReference);
      const p2 = AnnotationsService.getAnnotationsByTaskId(task.id);
      const p3 = AnnotationsService.getAnnotations({
        "batchId.equals": task.batchId.toString(),
        "mediaId.equals": task.taskReference.toString(),
        "source.in": "Import,Model,GroundTruth",
      });
      const results: any = await Promise.all([p1, p2, p3]);
      // get file
      taskReviewRes.file =
        await StorageService.cacheFileToLocalUrlAndAddMineType(
          results[0].data,
          task.workspaceId
        );

      taskReviewRes.metadata = await labelingUtils.parseUltrasoundData(
        taskReviewRes.file
      );
      // build observation map to set color, text label for annotations
      const obsMap: Record<number, ObservationDTO> = {};
      for (const bo of batchObservations) {
        obsMap[bo.observation.id] = bo.observation;
      }

      // convert taskObservation to annotation model
      let annos: Annotation[] = [];
      let relations: RelationAnnotation[] = [];

      // jobs
      const jobOptions: JobOption[] = [];

      const labels = imageLabelMapper.toFlatLabels(batchObservations);
      const labelEntities = imageLabelMapper.toEntities(labels);

      const annoJobRes = results[1].data as AnnotationJobResponseDTO;
      const { annotations, relationAnnotations } =
        annotationUtils.fromAnnotationJobResponseDTO(annoJobRes, labelEntities);

      // change color
      for (const anno of annotations) {
        const obs = obsMap[anno.labelId];
        anno.color = obs.observationSetting.color;
        anno.label = `${obs.name} (${truncateEmail(anno.annotator)})`;
      }
      annos = annotations.filter(
        (anno) => !jobIdsIncludedOnly || jobIdsIncludedOnly.includes(anno.jobId)
      );
      relations = relationAnnotations.filter(
        (anno) => !jobIdsIncludedOnly || jobIdsIncludedOnly.includes(anno.jobId)
      );

      // Build job options
      for (const anno of [...annos, ...relations]) {
        const op = jobOptions.find(
          (jobOption) =>
            jobOption.jobId === anno.jobId &&
            jobOption.assignee === anno.annotator
        );
        if (!op) {
          jobOptions.push({
            jobId: anno.jobId,
            assignee: anno.annotator,
          });
        }
      }

      for (const item of annoJobRes.annotations) {
        if (!item.observationId) continue;
        if (item.observationId <= 0) continue;
        const isExisted = jobOptions.find((op) => op.jobId === item.jobId);
        if (isExisted) continue;
        jobOptions.push({ jobId: item.jobId, assignee: item.assignee });
      }

      // TODO: build annotationAgreementDTOS later
      // because we use IOU by annotaiton later
      const labelToIOUPairs: Record<number, IOUPair[]> = {};

      try {
        const annoJobRes = results[2].data as AnnotationJobResponseDTO;
        const jobAnnotations =
          annotationUtils.annotationJobResponseDTOToJobAnnotation(
            annoJobRes,
            labelEntities,
            {
              id: -1,
              assignee: SYSTEM_ASSIGNEE,
              batchId: task.batchId,
              taskId: task.id,
            } as Job
          );

        if (jobAnnotations) {
          for (const annoId of jobAnnotations.annotations.allIds) {
            const anno = (jobAnnotations.annotations.entities as any)[annoId];
            if (anno.source === AnnotationSource.MODEL) {
              anno.annotator = MODEL_ASSIGNEE;
            }
            annos.push(anno);
          }
          for (const annoId of jobAnnotations.relationAnnotations.allIds) {
            const anno = (jobAnnotations.relationAnnotations.entities as any)[
              annoId
            ];
            if (anno.source === AnnotationSource.MODEL) {
              anno.annotator = MODEL_ASSIGNEE;
            }
            relations.push(anno);
          }
        }
      } catch (error) {
        Sentry.captureException(error);
      }

      taskReviewRes.annotations = annos;
      taskReviewRes.relationAnnotations = relations;
      taskReviewRes.jobOptions = jobOptions;

      // build batch observation later
      const labelAssigneesMap: Record<number, string[]> = {};
      for (const anno of taskReviewRes.annotations) {
        if (!labelAssigneesMap[anno.labelId]) {
          labelAssigneesMap[anno.labelId] = [];
        }
        if (!labelAssigneesMap[anno.labelId].includes(anno.annotator)) {
          labelAssigneesMap[anno.labelId].push(anno.annotator);
        }
      }
      taskReviewRes.taskReviewBatchObservations = batchObservations.map(
        (bo) => {
          const assignees = labelAssigneesMap[bo.observation.id];
          const iouPairs = labelToIOUPairs[bo.observation.id] || [];
          return {
            batchObservation: bo,
            selected: true,
            assignees: assignees ? assignees : [],
            iouPairs: iouPairs,
            avgIoU:
              iouPairs.length > 0
                ? iouPairs.map((p) => p.score).reduce((s, n) => n + s, 0) /
                  iouPairs.length
                : 0,
          };
        }
      );
    } catch (error: any) {
      Sentry.captureException(error);
      taskReviewRes.error = new AppError("unknow", error.message);
      console.log(error);
    }
    return taskReviewRes;
  }
);

export const tasksReviewReducerBuilder = (
  builder: ActionReducerMapBuilder<TasksReviewState>
): ActionReducerMapBuilder<TasksReviewState> => {
  return builder
    .addCase(loadTasksReviewDataAsync.pending, (state) => {
      state.requestStatus = RequestStatus.LOADING;
    })
    .addCase(loadTasksReviewDataAsync.rejected, (state) => {
      state.requestStatus = RequestStatus.FAILURE;
    })
    .addCase(loadTasksReviewDataAsync.fulfilled, (state, action) => {
      const taskReviewRes = action.payload;
      state.requestStatus = RequestStatus.SUCCESS;
      state.error = taskReviewRes.error;
      state.file = taskReviewRes.file;
      state.jobOptions = taskReviewRes.jobOptions;
      state.taskReviewBatchObservations =
        taskReviewRes.taskReviewBatchObservations;
      state.annotations = taskReviewRes.annotations;
      state.relationAnnotations = taskReviewRes.relationAnnotations;
      state.metadata = taskReviewRes.metadata;

      state.activeAnnotations = [
        ...taskReviewRes.annotations,
        ...taskReviewRes.relationAnnotations,
      ].map((annotation) => ({
        annotationId: annotation.id,
        selected:
          annotation.annotator !== SYSTEM_ASSIGNEE &&
          annotation.annotator !== MODEL_ASSIGNEE,
        hovering: false,
      }));
    });
};
