import { useRef, useState } from "react";
import RecordRTC, {StereoAudioRecorder} from "recordrtc";
import { SpeechToTextAudioPlayer } from "pages/labeler/speech-to-text-labeling/components/speech-to-text-audio-player.component";
import { IconMic, IconSave, IconStop } from "components/common/vb-icon.component";
import { convertSecondToString } from "utilities/date-time/second-to-string";

export interface TextToSpeechRecorderProps {
  url: string | undefined;
  onMicStopped?: (blob: any, localUrl: string) => void;
  onClickDelete?: () => void;
  disabledRecord?: boolean;
}

export const TextToSpeechRecorder = ({
  url,
  onMicStopped,
  onClickDelete,
  disabledRecord = false,
}: TextToSpeechRecorderProps) => {
  const [isMicOn, setIsMicOn] = useState(false);
  const [recorder, setRecorder] = useState<any>(undefined);
  const [localAudioStream, setLocalAudioStream] = useState<any>(undefined);
  const [duration, setDuration] = useState(0);
  const startedTime = useRef<number>(0);

  const handleStarMic = (event: any) => {
    navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true
    }).then(async (stream) => {
        setLocalAudioStream(stream);
        let recorder = new RecordRTC(stream, {
            type: "audio",
            mimeType: "audio/wav",
            recorderType: StereoAudioRecorder,
            disableLogs: true,
            timeSlice: 1000,
            desiredSampRate: 16000,
            bitsPerSecond: 128000,
            numberOfAudioChannels: 1,
            ondataavailable: (blob: any) => {
              const seconds = ((new Date()).getTime() - startedTime.current) / 1000;
              setDuration(seconds);
            }
        });
        setDuration(0);
        recorder.setRecordingDuration(30*60*1000); // Max 30 minutes
        recorder.startRecording();
        startedTime.current = new Date().getTime();
        setRecorder(recorder);
    });
    setIsMicOn(true);
  }

  const handleStopMic = (event: any) => {
    cleanUpMic();
    setIsMicOn(false);
  }

  const handleAudioReady = (duration: number | undefined) => {
    if (duration !== undefined){
      setDuration(duration);
    }
  }

  const cleanUpMic = () => {
    console.log("clean mic")
    if (recorder){
      recorder.stopRecording(() => {
        console.log("stoped")
        let blob = recorder.getBlob();
        let url = URL.createObjectURL(blob);
        onMicStopped && onMicStopped(blob, url);
      });
    }
    if (localAudioStream){
      console.log("remove local audio stream")
      // turn off the mic stream
      localAudioStream.getAudioTracks().forEach(function(track: any){track.stop();});
      setLocalAudioStream(undefined);
    }
  }
  return (
    <div className="w-full flex flex-col gap-2">
      { !disabledRecord && 
        <div className="w-full flex flex-col justify-center items-center gap-4 mb-6">
          <div
            className="flex items-center justify-center border rounded p-4"
            style={{
              width: "21.75rem",
              maxWidth: "100%",
              minHeight: "8.25rem",
              borderStyle: !isMicOn ? "dotted" : "",
              borderColor: !isMicOn ? "" : "red",
            }}
          >
            {
              !isMicOn &&
              <div className="w-full flex flex-col items-center justify-center gap-4">
                <div
                  className="text-warning-500 flex items-center justify-center w-12 h-12 bg-error-50 rounded-full cursor-pointer"
                  onClick={handleStarMic}
                >
                  <IconMic />
                </div>
                {
                  !url &&
                  <p>
                    Click on mic and speak the text above
                  </p>
                }
                {
                  url &&
                  <div className="flex flex-col items-center justify-center w-full gap-2">
                    <p className="text-warning-500">
                      {convertSecondToString(duration)}
                    </p>
                    <p>
                      Click on the mic to continue recording
                    </p>
                    <div className="flex items-center justify-center py-1 gap-2 text-red-500  border border-red-500 w-full rounded cursor-pointer"
                      onClick={_ => onClickDelete && onClickDelete()}
                    >
                      <span>Delete this record</span>
                      <IconSave />
                    </div>
                  </div>
                }
              </div>
            }
            {
              isMicOn &&
              <div className="flex flex-col items-center justify-center gap-4">
                <div
                  className="text-red-500 flex items-center justify-center w-12 h-12 bg-error-50 rounded-full cursor-pointer"
                  onClick={handleStopMic}
                >
                  <IconStop />
                </div>
                <p>
                  {convertSecondToString(duration)}
                </p>
              </div>
            }
          </div>
        </div>
      }
      {
        (url && !isMicOn) &&
        <SpeechToTextAudioPlayer
          url={url}
          containerClasses="bg-background-800"
          toolClasses=""
          onAudioReady={handleAudioReady}
        />
      }
    </div>
  )
}