import { RowDataTableBatch } from "pages/customer/dashboard/components/batch-table.component";
import { RowDataTableLabel } from "pages/customer/dashboard/components/label-table.component";
import { RowDataTableLabeler } from "pages/customer/dashboard/components/labeler-table.component";
import { RowDataTableProject } from "pages/customer/dashboard/components/project-table.component";
import { CardRowDataModel } from "pages/customer/dashboard/context/dashboard.state";

import { DEFAULT_DATA as DEFAULT_DATA_CARD_DATASOURCE } from "pages/customer/dashboard/mapper/card-row-datasource.mapper";
import { DEFAULT_DATA as DEFAULT_DATA_CARD_LABELER } from "pages/customer/dashboard/mapper/card-row-labeler.mapper";
import { DEFAULT_DATA as DEFAULT_DATA_CARD_PROJECT } from "pages/customer/dashboard/mapper/card-row-project.mapper";
import { DEFAULT_DATA as DEFAULT_DATA_CARD_MODEL } from "pages/customer/dashboard/mapper/card-row-model.mapper";
import { DEFAULT_DATA as DEFAULT_DATA_CARD_STORAGE_USAGE } from "pages/customer/dashboard/mapper/card-row-storage-usage.mapper";
import { DEFAULT_DATA as DEFAULT_DATA_TASK_PROGRESS } from "pages/customer/dashboard/mapper/task-progress.mapper";
import { RequestStatus } from "store/base/base.state";
import { TaskCompletedItemDataModel, TaskProgressItemDataModel } from "pages/customer/dashboard/components/task-statistic-chart.component";
import { CancelTokenSource } from "axios";
import { ProjectTypes } from "services/label-service/dtos";
import moment from "moment";
import { DateRange } from "utilities/date-time/date-range";


export interface DashBoardStateCommon {
  cardRowsDatasources: CardRowDataModel[];
  cardRowsProjects: CardRowDataModel[];
  cardRowsLabelers: CardRowDataModel[];
  cardRowsModels: CardRowDataModel[];
  cardRowsStorageUsage: CardRowDataModel[];

  taskProgressData: TaskProgressItemDataModel[];
  taskCompletedData: TaskCompletedItemDataModel[];

  tableRowsLabels: RowDataTableLabel[];
  tableRowsLabelers: RowDataTableLabeler[];

  tableRowsProjects: RowDataTableProject[];
  tableRowsBatches: RowDataTableBatch[];
}

export interface DashBoardStoreState extends DashBoardStateCommon {
  loadDataStatus: RequestStatus,
  cache: Record<string, boolean>,
  cancelTokensMap: Record<string, CancelTokenSource | undefined>,
  queryNamesRequestStatusMap: Record<string, RequestStatus>,

  fromDate: Date;
  toDate: Date;
  dateRange: DateRange;
  taskCompletedFilter: string;
  tableProjectType: ProjectTypes | undefined;
  latestTimeLoaded: undefined | string;
}

export const INITIAL_DASH_BOARD_STATE: DashBoardStoreState = {
  cardRowsDatasources: DEFAULT_DATA_CARD_DATASOURCE,
  cardRowsProjects: DEFAULT_DATA_CARD_PROJECT,
  cardRowsLabelers: DEFAULT_DATA_CARD_LABELER,
  cardRowsModels: DEFAULT_DATA_CARD_MODEL,
  cardRowsStorageUsage: DEFAULT_DATA_CARD_STORAGE_USAGE,

  taskProgressData: DEFAULT_DATA_TASK_PROGRESS,
  taskCompletedData: [],

  tableRowsLabels: [],
  tableRowsLabelers: [],

  tableRowsProjects: [],
  tableRowsBatches: [],

  loadDataStatus: RequestStatus.IDLE,
  cache: {},
  cancelTokensMap: {},
  queryNamesRequestStatusMap: {},

  fromDate: moment(new Date()).startOf("month").toDate(),
  toDate: new Date(),
  dateRange: DateRange.THIS_MONTH,
  taskCompletedFilter: "day",
  tableProjectType: undefined,
  latestTimeLoaded: undefined,
}