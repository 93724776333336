/*
 * File: public-route.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 18th June 2021 12:21:54 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { Route, RouteProps } from "react-router-dom";
export const PublicRoute = (props: RouteProps) => {
  return <Route {...props} />;
};
