import { JobOption } from "domain/common/models";
import { Annotation } from "domain/image-labeling";
import { useAppDispatch } from "hooks/use-redux";
import { useCallback, useState } from "react";
import {
  MODEL_ASSIGNEE,
  SYSTEM_ASSIGNEE,
} from "services/label-service/dtos/task-observation-v3.dto";
import { updateActiveAnnotation } from "store/customer/tasks-review/tasks-review.slice";
import { TaskReviewActionPanelFindingAnnotations } from "./finding-annotations.component";
import { TaskReviewActionPanelFindingIou } from "./finding-iou.component";
import { TaskReviewActionPanelFindingLabeler } from "./finding-labeler.component";

interface TaskReviewActionPanelProps {
  taskId: number;
  jobs: JobOption[];
  annotations?: Annotation[];
}

export const TaskReviewActionPanel = ({
  taskId,
  jobs,
  annotations,
}: TaskReviewActionPanelProps) => {
  const dispatch = useAppDispatch();
  const [labeler, setLabeler] = useState("All");

  const handleChangeLabeler = useCallback(
    (labeler: string) => {
      if (!annotations || !annotations.length) return;
      setLabeler(labeler);
      annotations.forEach((annotation) => {
        const newAnnotation = {
          annotationId: annotation.id,
          selected:
            labeler === "All"
              ? annotation.annotator !== SYSTEM_ASSIGNEE &&
                annotation.annotator !== MODEL_ASSIGNEE
              : labeler === annotation.annotator,
        };
        dispatch(updateActiveAnnotation(newAnnotation));
      });
    },
    [annotations, dispatch]
  );

  return (
    <div className="bg-background-100">
      <div className="bg-primary-50 text-primary-500 px-4 py-3 h-full">
        Task Review
      </div>
      <div className="p-4 space-y-4 bg-white">
        <TaskReviewActionPanelFindingLabeler
          jobs={jobs}
          onChange={handleChangeLabeler}
        />
        <TaskReviewActionPanelFindingIou taskId={taskId} />
      </div>

      {annotations && annotations.length ? (
        <TaskReviewActionPanelFindingAnnotations
          annotations={annotations}
          labeler={labeler}
        />
      ) : null}
    </div>
  );
};
