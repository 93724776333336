import { useAppSelector } from "hooks/use-redux";
import moment from "moment";
import { useCallback, useMemo, useRef, useState } from "react";
import { RequestOptions } from "services/common/request-options";
import { PaymentService } from "services/payment";
import { RequestStatus } from "store/base/base.state";
import { selectPaymentCurrencySelectOptions } from "store/common/payment/payment.selectors";
import useDeepCompareEffect from "use-deep-compare-effect";
import { mapperProfileBillRow } from "./profile-bills.mappers";
import { ProfileBillFilter, ProfileBillRow } from "./profile-bills.models"
import * as Sentry from "@sentry/react";


export const useProfileBills = () => {
  const [rows, setRows] = useState<ProfileBillRow[]>([]);
  const [totalRow, setTotalRow] = useState(0);
  const [filter, setFilter] = useState<ProfileBillFilter>({
    page: 1,
    pageSize: 10,
    fromDate: moment(new Date()).startOf("month").toDate(),
    toDate: new Date(),
  });
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const isLoading = useMemo(() => requestStatus === RequestStatus.LOADING, [requestStatus]);
  const forceRequest = useRef(false);
  const currencyOptions = useAppSelector(selectPaymentCurrencySelectOptions);

  const setFilterField = (field: keyof ProfileBillFilter, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
    forceRequest.current = true;
  }

  const setFilterFields = (values: Partial<ProfileBillFilter>) => {
    setFilter({
      ...filter,
      ...values,
    });
    forceRequest.current = true;
  }

  const requestData = useCallback(async (options: RequestOptions) => {
    try {
      if (requestStatus !== RequestStatus.IDLE && !forceRequest.current ) return;
      forceRequest.current = false;
      setRequestStatus(RequestStatus.LOADING);

      const res = await PaymentService.getBillings(options);
      const rows = res.data.map(resRow => mapperProfileBillRow.fromEntity(resRow, currencyOptions));
      setRows(rows);
      setTotalRow(res.headers["x-total-count"]);

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      Sentry.captureException(error);
      setRequestStatus(RequestStatus.FAILURE);
    }
  }, [currencyOptions, requestStatus]);

  useDeepCompareEffect(() => {
    if (!filter || !filter.fromDate || !filter.toDate) return;
    requestData(ProfileBillFilter.toRequestOption(filter));

  }, [filter, requestData]);

  return {
    isLoading,
    rows,
    totalRow,
    filter,
    setFilterField,
    setFilterFields,
  }
}