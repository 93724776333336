/*
 * File: app.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:12 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AppRouter } from "../routers";
import { BrowserRouter } from "react-router-dom";
import { LayoutProvider } from "../contexts/layout";
import { AppProvider } from "../contexts/app/app.provider";
import { KeycloakProvider } from "../contexts/keycloak/keycloak.provider";
import { SnackbarProvider } from "notistack";
import { NotificationProvider } from "contexts/notification/notification.provider";
import { QueryClient, QueryClientProvider } from "react-query";
import "../styles/index.css";
import "../i18n";
import { DownloadDatasetProvider } from "contexts/download-dataset/download-dataset.provider";
import { useEffect } from "react";
import { inputManager } from "utilities/keyboard/input.manager";
import { AppEvents } from "constants/annotation.constant";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { FileUploadProvider } from "contexts/file-upload/file-upload.provider";
import { ModalProvider } from "contexts/modal";
import { WorkspaceProvider } from "contexts/workspace";
import { ErrorBoundaryProvider } from "contexts/error-boundary/error-boundary.provider";

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    const handleKeyDown = (e: any) => {
      inputManager.setKeyDown(e.key);
      const isInput =
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement;
      if (
        !isInput ||
        (isInput &&
          (inputManager.isActionSaveDown() ||
            inputManager.isActionSubmitDown()))
      ) {
        if (
          (e.key === KeyboardKey.Space && e.target === document.body) ||
          inputManager.isActionSaveDown() ||
          e.key === KeyboardKey.Meta
        ) {
          e.preventDefault();
        }
        document.dispatchEvent(
          new CustomEvent(AppEvents.SHORT_CUT_KEY_DOWN, { detail: e })
        );
      }
    };

    const handleKeyUp = (e: any) => {
      inputManager.setKeyUp(e.key);
      if (e.key === KeyboardKey.Meta) {
        inputManager.reset();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider maxSnack={3} preventDuplicate>
        <BrowserRouter
          getUserConfirmation={(message, callback) => {
            const allowTransition = window.confirm(message);
            callback(allowTransition);
          }}
        >
          <KeycloakProvider>
            <ModalProvider>
              <WorkspaceProvider>
                <NotificationProvider>
                  <ErrorBoundaryProvider>
                    <AppProvider>
                      <FileUploadProvider>
                        <DownloadDatasetProvider>
                          <LayoutProvider>
                            <AppRouter />
                          </LayoutProvider>
                        </DownloadDatasetProvider>
                      </FileUploadProvider>
                    </AppProvider>
                  </ErrorBoundaryProvider>
                </NotificationProvider>
              </WorkspaceProvider>
            </ModalProvider>
          </KeycloakProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;
