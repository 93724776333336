/*
 * File: batch.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 11th July 2022 4:48:41 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Batch } from "domain/labeling/batch";
import { BatchDTO } from "../dtos";

function fromDTO(dto: BatchDTO): Batch {
  return {
    id: dto.id,
    name: dto.name,
    projectId: dto.project.id,
    dto: dto,
  };
}

function toDTO(entity: Batch): BatchDTO {
  throw new Error("Method not implemented");
}

export const batchMapper = {
  fromDTO,
  toDTO,
};
