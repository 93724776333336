/*
 * File: create-batch.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Backdrop, CircularProgress, Grow } from "@material-ui/core";
import { useKeyPress } from "ahooks";
import { IconCloseCircle } from "components/common/vb-icon.component";
import { MatModal } from "components/material/mat-modal.component";
import { isDevelopment } from "configs/env.config";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { classnames } from "utilities/classes";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { useCreateBatchContext } from "../create-batch.context";
import { BatchSteps, StepStatus } from "../create-batch.model";
import { CreateBatchStep } from "./create-step.component";
import { BatchInformationStep } from "./step-0-batch-information.component";
import { AddLabelsStep } from "./step-1-add-labels.component";
import { SelectDatasourceStep } from "./step-2-select-datasource.component";
import { ConfigureWorkflowStep } from "./step-3-configure-workflow.component";
import { AddMembersStep } from "./step-4-add-members.component";
import { PriceUnitStep } from "./step-5-price-unit.component";

export const CreateBatchModal = () => {
  const { t } = useTranslation();
  const {
    visible,
    steps,
    stepIndex,
    processing,
    dismiss,
    previousStep,
    nextStep,
    createLabelVisibile,
    handleSubmit,
    isCurrentStepCompleted,
    hasError,
  } = useCreateBatchContext();
  const [skip, setSkip] = useState(false);
  const isStepCompleted = useMemo(() => {
    return isCurrentStepCompleted();
  }, [isCurrentStepCompleted]);

  function getStatus(index: number) {
    return stepIndex === index
      ? StepStatus.CURRENT
      : stepIndex > index
        ? StepStatus.COMPLETED
        : StepStatus.DEFAULT;
  }

  function onClickSubmit(skip: boolean) {
    setSkip(skip);
    handleSubmit(skip);
  }

  useKeyPress(KeyboardKey.Enter, (e) => {
    if (processing || createLabelVisibile) return;
    e.preventDefault();
    e.stopImmediatePropagation();
    if (isCurrentStepCompleted()) {
      const submitStep = !isDevelopment()
        ? BatchSteps.UNIT_PRICE
        : BatchSteps.MEMBERS;
      stepIndex === submitStep ? onClickSubmit(false) : nextStep();
    }
  });

  return (
    <MatModal
      disableBackdropClick
      open={visible}
      onClose={dismiss}
      closeAfterTransition
      BackdropComponent={Backdrop}
      className="flex items-center justify-center"
    >
      <Grow in={visible}>
        <div
          className={`relative flex flex-col w-full h-full overflow-hidden bg-white rounded shadow focus:outline-none ${processing ? "pointer-events-none" : ""
            }`}
          style={{ maxWidth: "75rem", maxHeight: "48rem" }}
        >
          <div className="py-4 border-b">
            <h2 className="px-4 text-2xl font-semibold">
              {t("project:batch.create.title")}
            </h2>
            <p className="px-4 my-2 text-gray-400">
              {t("project:batch.create.textCompleteStepBelow")}
            </p>
          </div>
          <div className="relative flex flex-grow h-1 gap-8 overflow-hidden">
            <div className="flex flex-col h-full">
              <div className="relative grid flex-none grid-cols-1 gap-10 pl-6 my-8 border-r">
                {steps.map((step) => {
                  return (
                    <CreateBatchStep
                      key={step.type}
                      step={step}
                      status={getStatus(step.type)}
                    />
                  );
                })}
              </div>
            </div>
            <div className="flex-auto overflow-y-auto">
              {stepIndex === BatchSteps.GENERAL && <BatchInformationStep />}
              {stepIndex === BatchSteps.LABELS && <AddLabelsStep />}
              {stepIndex === BatchSteps.DATASOURCE && <SelectDatasourceStep />}
              {stepIndex === BatchSteps.WORKFLOW && <ConfigureWorkflowStep />}
              {stepIndex === BatchSteps.MEMBERS && <AddMembersStep />}
              {isDevelopment() && stepIndex === BatchSteps.UNIT_PRICE && (
                <PriceUnitStep />
              )}
            </div>
          </div>
          <div className="z-10 flex items-center justify-end flex-shrink-0 gap-4 p-4 bg-white">
            {stepIndex > 0 && (
              <button
                className="flex items-center justify-center gap-2 px-4 py-2 bg-white rounded text-primary focus:outline-none"
                onClick={previousStep}
              >
                <span>{t("common:textPrevious")}</span>
              </button>
            )}

            {stepIndex < steps.length - 1 && (
              <button
                disabled={!isStepCompleted || !!hasError}
                onClick={nextStep}
                className={classnames("button-text-primary", {
                  "bg-opacity-20 cursor-not-allowed opacity-50":
                    !isStepCompleted || !!hasError,
                  "cursor-pointer": isStepCompleted,
                })}
              >
                <span>{t("common:textNext")}</span>
              </button>
            )}

            {stepIndex === steps.length - 1 && (
              <>
                {isDevelopment() && (
                  <button
                    onClick={(_) => onClickSubmit(true)}
                    className={classnames("button-text-secondary", {
                      "bg-opacity-20 cursor-not-allowed": !isStepCompleted,
                    })}
                    disabled={processing || !isStepCompleted}
                  >
                    <span>
                      {skip && processing ? (
                        <CircularProgress size={18} color="inherit" />
                      ) : (
                        t("project:batch.create.buttonSkipAndCreate")
                      )}
                    </span>
                  </button>
                )}
                <button
                  onClick={(_) => onClickSubmit(false)}
                  className={classnames(
                    "px-4 py-2 w-24 text-white flex items-center justify-center bg-primary rounded focus:outline-none",
                    {
                      "bg-opacity-20 cursor-not-allowed": !isStepCompleted,
                    }
                  )}
                  disabled={processing || !isStepCompleted}
                >
                  <span>
                    {!skip && processing ? (
                      <CircularProgress size={18} color="inherit" />
                    ) : (
                      t("project:batch.create.buttonCreate")
                    )}
                  </span>
                </button>
              </>
            )}
          </div>
          <button
            onClick={dismiss}
            className="absolute z-10 flex-none right-4 top-4"
          >
            <IconCloseCircle className="w-6 h-6" />
          </button>
        </div>
      </Grow>
    </MatModal>
  );
};
