/*
 * File: app-setting.state.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 1st April 2022 4:25:24 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */
import { ENV_CONFIG } from "configs/env.config";
import { DataType } from "domain/customer";
import { defaultSupportedProjectTypes } from "./app-setting-default";

export interface SupportedProjectType {
  code: string;
  name: string;
  subTypes: {
    code: string;
    name: string;
    dataSourceTypes: string[];
  }[];
}

export interface AppSettingState {
  supportedDatasourceTypes: DataType[];
  supportedFileTypes: Record<DataType, string>;
  supportedProjectTypes: SupportedProjectType[];
  supportedSystemColors: string[];
  trackingTime: number;
  useElasticSearch: boolean;
  appConfig?: any;
  version: string;
}

export const INITIAL_APP_SETTING_STATE: AppSettingState = {
  supportedDatasourceTypes: [
    DataType.IMAGE,
    DataType.DICOM,
    DataType.TEXT,
    DataType.AUDIO,
    DataType.MDI,
    DataType.WSI,
  ],
  supportedFileTypes: {
    [DataType.IMAGE]: "image/png, image/jpeg, image/bmp",
    [DataType.DICOM]:
      "image/png, image/jpeg, image/bmp, */dicom, .dcm, image/dcm, */dcm, .dicom",
    [DataType.TEXT]: "text/plain",
    [DataType.AUDIO]: ".mp3,audio/*",
    // [DataType.MDI]: "*/dicom, .dcm, image/dcm, */dcm, .dicom",
    [DataType.MDI]: "*/**",
    [DataType.WSI]: "*/**",
  },

  supportedProjectTypes: defaultSupportedProjectTypes,
  supportedSystemColors: [],
  trackingTime: 30000,
  useElasticSearch: ENV_CONFIG.ENABLED_RELATED_ES_APIS || false,
  version: "1.0.0",
};
