/*
 * File: reopen-dialog.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 27th January 2022 9:25:59 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { VBModal } from "components/common/vb-modal/vb-modal.component";

interface Props {
  open: boolean;
  onClose?(): void;
  onSubmit?(): void;
  message?: string;
  title?: string;
}

export const ReopenDialog = ({
  title = "Reopen note",
  message = "",
  open,
  onClose,
  onSubmit,
}: Props) => {
  return (
    <VBModal
      open={open}
      disableCloseButton
      onClose={onClose}
      onSubmit={onSubmit}
      title={title}
      textSubmit="Ok"
    >
      <div className="leading-7">{message}</div>
    </VBModal>
  );
};
