/*
 * File: project-statistic.state.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 11:35:06 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { BaseState, RequestStatus } from "store/base/base.state";

export interface ProjectStatisticDTO {
  /**BatchId */
  id: number;
  batchStatistic: Record<string, number>;
  totalLabelers: number;
  totalBatches: number;
  startDate: string | null;
}

export interface ProjectStatisticState extends BaseState<ProjectStatisticDTO> {}

export const INITIAL_PROJECT_STATISTIC_STATE: ProjectStatisticState = {
  entities: {},
  ids: [],
  id: -1,
  status: RequestStatus.IDLE,
  error: null,
};
