/*
 * File: image-labeling.page.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 5th August 2021 10:19:15 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { ImageLabelingProvider } from "pages/labeler/image-labeling/context/image-labeling.provider";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import {
  selectIsNoAvailableJob,
  selectWorkingJob,
  selectImageLabelingError,
} from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { WorkspaceEmpty } from "./components/workspace-empty/workspace-empty.component";
import { ImageLabelingErrorDialog } from "./components/error-dialog/error-dialog.component";
import { Routes } from "routers/config/routes";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { useUnmount } from "ahooks";
import {
  resetCornerstoneTool,
  resetDicomEditorState,
} from "store/labeler/image-workspace/dicom-editor/dicom-editor.slice";
import { jobCompleted } from "store/labeler/image-workspace/batch-labeling/batch-labeling.slice";
import { resetCornerstoneState } from "store/labeler/image-workspace/cornerstone/cornerstone.slice";
import ImageLabeling from "./components/image-labeling/image-labeling.component";
import { selectInvalidAnnotation } from "store/labeler/image-workspace/image-annotations/image-annotations.selectors";
import { InvalidPolygonModal } from "./components/invalid-polygon/invalid-polygon.modal";
import {
  invalidPolygonModalClosed,
  resetImageLabelingState,
} from "store/labeler/image-workspace/image-workspace.slice";
import { skipTaskObservationsAsync } from "store/labeler/image-workspace/image-labeling/thunks/skip-image-labeling.thunk";
import * as Sentry from "@sentry/react";

export const ImageLabelingPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const workingJob = useAppSelector(selectWorkingJob);
  const isNoAvailableJob = useAppSelector(selectIsNoAvailableJob);
  const error = useAppSelector(selectImageLabelingError);
  const invalidAnnotation = useAppSelector(selectInvalidAnnotation);

  const [isSkipping, setSkipping] = useState(false);

  async function handleSkip() {
    if (!workingJob || isSkipping) return;
    try {
      setSkipping(true);
      const payload = { ...workingJob };
      const response = await dispatch(skipTaskObservationsAsync(payload));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textSkippedSuccess")));
      dispatch(jobCompleted(workingJob.id));
      dispatch(resetImageLabelingState());
      dispatch(resetCornerstoneTool());
      dispatch(resetCornerstoneState());
    } catch (err: any) {
      Sentry.captureException(err);
      const errMessage = err.message || t("common:textSkippedFailed");
      dispatch(enqueueErrorNotification(errMessage));
    } finally {
      setSkipping(false);
    }
  }

  function handleExit() {
    history.push(Routes.LABELER_HOME);
  }

  function closeInvalidPolygonModal() {
    dispatch(invalidPolygonModalClosed());
  }

  useUnmount(() => {
    dispatch(resetImageLabelingState());
    dispatch(resetCornerstoneState());
    dispatch(resetDicomEditorState());
  });

  if (isNoAvailableJob) return <WorkspaceEmpty />;

  return (
    <ImageLabelingProvider>
      <div className="relative w-full h-full overflow-hidden text-white bg-blueGray-900">
        <ImageLabeling />
        {error && (
          <ImageLabelingErrorDialog
            visible
            error={error}
            onClose={handleExit}
            onSubmit={handleSkip}
          />
        )}

        {invalidAnnotation && (
          <InvalidPolygonModal
            data={invalidAnnotation}
            onClose={closeInvalidPolygonModal}
          />
        )}

        {/* {isWorkspaceLoading && <VBComponentRequesting />} */}

        {!error && (
          <Prompt message={t("labelerworkspace:promptChangeNotSave")} />
        )}
      </div>
    </ImageLabelingProvider>
  );
};

document.oncontextmenu = () => false;
