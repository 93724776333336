import { IconButton, Tooltip } from "@material-ui/core";
import { useLocalStorageState } from "ahooks";
import { IconFullScreen } from "components/common/vb-icon.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { ThreeDLabelingProvider } from "pages/labeler/three-d-labeling/context/three-d-labeling.provider";
import { ThreeDLabelingDetails } from "pages/labeler/three-d-labeling/three-d-labeling.details";
import { useTranslation } from "react-i18next";
import { BatchDTO, TaskDTO } from "services/label-service/dtos";
import { classnames } from "utilities/classes";
import { TaskReviewReopenButtons } from "../task-review-reopen-buttons.component";
const LOCAL_STORAGE_KEY = "THREE_D_MODAL_FULLSCREEN";
interface Props {
  open: boolean;
  batch: BatchDTO;
  task: TaskDTO;
  jobIdsIncludedOnly?: number[];
  onClose?: () => void;
  onNext?: () => void;
  title?: string;
  showReopenButtons?: boolean;
  showIssues?: boolean;
}

export const TaskReviewThreeD = ({
  open,
  batch,
  task,
  jobIdsIncludedOnly,
  onClose,
  onNext,
  title,
  showReopenButtons,
  showIssues = false,
}: Props) => {
  const { t } = useTranslation();
  const [isFullScreen, setFullScreen] = useLocalStorageState(
    LOCAL_STORAGE_KEY,
    true
  );

  return (
    <VBModal
      width={isFullScreen ? "100vw" : "1440px"}
      title={title ? title : `Task review #${task.id}`}
      open={open}
      onClose={onClose}
      footerHidden
      containerClassName={classnames({
        "w-screen h-screen rounded-none": isFullScreen,
        rounded: !isFullScreen,
      })}
      headerActions={
        <Tooltip title="Toggle fullscreen">
          <IconButton onClick={() => setFullScreen((val) => !val)}>
            <IconFullScreen width={20} height={20} />
          </IconButton>
        </Tooltip>
      }
    >
      <div className="flex flex-col h-full gap-6">
        <div
          className="relative flex-auto w-full h-full"
          style={{ height: "80vh" }}
        >
          <ThreeDLabelingProvider
            isTaskReview
            taskToReview={task}
            jobIdsIncludedOnly={jobIdsIncludedOnly}
          >
            <ThreeDLabelingDetails
              showMenuIssue={false}
              showIssues={showIssues}
              editorHeight={"calc(80vh - 60px)"}
            />
          </ThreeDLabelingProvider>
        </div>
        <div className="z-10 flex items-center flex-none w-full gap-4">
          {showReopenButtons && (
            <TaskReviewReopenButtons
              batch={batch}
              task={task}
              onApproved={onClose}
              onRejected={onClose}
            />
          )}
          <div className="flex-1"></div>

          <button
            onClick={onNext}
            className="button-text-primary disabled:opacity-60"
          >
            <span>{t("common:buttonNext")}</span>
          </button>
        </div>
      </div>
    </VBModal>
  );
};
