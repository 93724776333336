/*
 * File: dataset.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { DataType } from "domain/customer";
import {
  DatasetCategories,
  ProjectAudioSubTypes,
  ProjectDTO,
  ProjectTypes,
} from "services/label-service/dtos";
import { RootState } from "../..";

export const selectDatasetFiles = (state: RootState) => {
  return state.dataset.files;
};

export const selectTotalDatasetFiles = (state: RootState) => {
  return state.dataset.totalCount;
};

export const selectDatasetLoading = (state: RootState) => {
  return state.dataset.requesting;
};

export const selectCurrentDataset = (state: RootState) => {
  return state.dataset.dataset;
};

export const selectUserDatasets = (state: RootState) => {
  return state.dataset.datasets;
};

export const selectUserTotalDatasets = (state: RootState) => {
  return state.dataset.totalDataset;
};

export const selectUserDatasetsByType =
  (project: ProjectDTO) => (state: RootState) => {
    const { type, subType } = project;
    return state.dataset.datasets.filter((dataset) => {
      if (type === ProjectTypes.GENERAL_IMAGE) {
        return dataset.type === DatasetCategories.GENERAL_IMAGE;
      } else if (type === ProjectTypes.MEDICAL_IMAGE) {
        return dataset.type === DatasetCategories.MEDICAL_IMAGE;
      } else if (type === ProjectTypes.TEXT) {
        return dataset.type === DatasetCategories.NLP_COR;
      } else if (type === ProjectTypes.AUDIO) {
        if (subType === ProjectAudioSubTypes.SPEECH_TO_TEXT)
          return dataset.type === DatasetCategories.SPEECH_TO_TEXT;
        else if (subType === ProjectAudioSubTypes.TEXT_TO_SPEECH)
          return dataset.type === DatasetCategories.TEXT_TO_SPEECH;
      }
      return false;
    });
  };

export const selectDatasetClouds = (state: RootState) => {
  return state.dataset.clouds;
};

export const selectIsSyncingCloud = (state: RootState) => {
  return state.dataset.clouds.find((c) => c.status === "processing") !== null;
};

export const selectSyncingCloud = (state: RootState) => {
  return state.dataset.syncingCloud;
};

export const selectNumberSyncingClouds = (state: RootState) => {
  return state.dataset.clouds.filter((c) => c.status === "processing").length;
};

export const selectTags = (state: RootState) => {
  return state.dataset.tags.filter(tag => !!tag.name);
};

export const selectSupportedDatasetFileTypes = (state: RootState) => {
  const supportedFiles = state.appSetting.supportedFileTypes;
  const type = state.dataset.dataset?.category;
  if (type && (type as DataType)) {
    return supportedFiles[type as DataType];
  }
  return "";
};

export const selectCurrentDatasetType = (state: RootState) => {
  return state.dataset.dataset?.category as DataType;
};
