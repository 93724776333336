/*
 * File: text-labeling.page.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 14th October 2021 10:11:55 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Backdrop } from "@material-ui/core";
import { useUnmount } from "ahooks";
import { MatModal } from "components/material/mat-modal.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { TextWorkspace } from "pages/labeler/text-labeling/components/text-workspace/text-workspace.component";
import { TextWorkspaceProvider } from "pages/labeler/text-labeling/context/text-workspace/text-workspace.provider";
import { useEffect } from "react";
import { RequestStatus } from "store/base/base.state";
import {
  selectTextLabelingTaskLoadingStatus,
  selectTextLabelingTaskSelectedAnnotator,
} from "store/labeler/text-labeling-task/text-labeling-task.selectors";
import { resetTextLabelingTaskState } from "store/labeler/text-labeling-task/text-labeling-task.slice";
import { textLoadTaskAsync } from "store/labeler/text-labeling-task/text-labeling-task.thunk";
import { setTextLabelingTaskAsync } from "store/labeler/text-workspace/text-labeling/text-labeling.thunk";
import { startTextTaskReviewAsync } from "store/labeler/text-workspace/text-review/text-review.thunk";
import { resetTextLabelingWorkspace } from "store/labeler/text-workspace/text-workspace.slice";
import { classnames } from "utilities/classes";
import { useTextTaskContext } from "../text-task-context/text-task.context";

interface Props {
  taskId: number;
  allTaskIds: number[];
  onNext(): void;
  onPrevious(): void;
  onClose(): void;
}

export const TextTaskReviewComponent = ({
  taskId,
  allTaskIds,
  onNext,
  onPrevious,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const loadingStatus = useAppSelector(selectTextLabelingTaskLoadingStatus);
  const annotators = useAppSelector(selectTextLabelingTaskSelectedAnnotator);
  const isLoadingTextJob =
    loadingStatus === RequestStatus.IDLE ||
    loadingStatus === RequestStatus.LOADING;
  const { isFullScreen } = useTextTaskContext();
  useEffect(() => {
    dispatch(resetTextLabelingWorkspace());
    dispatch(resetTextLabelingTaskState());
  }, [dispatch, taskId]);

  useEffect(() => {
    if (loadingStatus !== RequestStatus.IDLE) return;
    async function loadData() {
      await dispatch(textLoadTaskAsync({ taskId }));
      dispatch(startTextTaskReviewAsync({ taskId }));
    }
    loadData();
  }, [dispatch, loadingStatus, taskId]);

  useEffect(() => {
    if (loadingStatus !== RequestStatus.SUCCESS) return;
    dispatch(setTextLabelingTaskAsync({ assignees: annotators }));
  }, [dispatch, loadingStatus, annotators]);

  useUnmount(() => {
    dispatch(resetTextLabelingWorkspace());
    dispatch(resetTextLabelingTaskState());
  });
  const containerStyle = isFullScreen
    ? {}
    : { maxWidth: "90vw", maxHeight: "90vh" };
  return (
    <MatModal
      open
      disableBackdropClick
      disableEscapeKeyDown
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={onClose}
    >
      <div className="relative flex items-center justify-center w-full h-full">
        <div
          className={classnames(
            "relative w-full h-full overflow-hidden bg-white",
            {
              rounded: !isFullScreen,
            }
          )}
          style={containerStyle}
        >
          <TextWorkspaceProvider isLoading={isLoadingTextJob} onClose={onClose}>
            <TextWorkspace
              onNext={onNext}
              onPrevious={onPrevious}
              allTaskIds={allTaskIds}
            />
          </TextWorkspaceProvider>
        </div>
      </div>
    </MatModal>
  );
};
