/*
 * File: create-workflow.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Backdrop, Grow } from "@material-ui/core";
import { VBRichTextEditor } from "components/common/vb-rich-text/vb-rich-text.component";
import { MatModal } from "components/material/mat-modal.component";
import { useAppDispatch } from "hooks/use-redux";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { WorkflowInstructionDTO } from "services/label-service/dtos";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { createWorkflowInstructionsAsync } from "store/customer/workflow/workflow.thunk";
import { classnames } from "utilities/classes";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { StepTransitions } from "../instructions/step-transitions.component";
import { WorkflowInstructionModal } from "../instructions/workflow-instruction.modal";
import * as Sentry from "@sentry/react";

interface Props {
  visible: boolean;
  onClose(): void;
}

type Instruction = Partial<WorkflowInstructionDTO>;

export const CreateWorkflowModal = ({ visible, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionDataRef = useRef("");
  const [open, setOpen] = useState(false);
  const [instructions, setInstructions] = useState<Instruction[]>([]);
  const [instruction, setInstruction] = useState<Instruction | null>(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (visible) {
      setProcessing(false);
    }
  }, [visible]);

  async function handleCreate() {
    if (processing) return;
    setProcessing(true);
    try {
      const workflow = {
        name: nameRef.current?.value || "",
        description: descriptionDataRef.current || "",
      };
      const workflowInstructions = instructions.map((ins) => {
        const instruction = { ...ins };
        delete instruction.id;
        return instruction;
      });
      inputValidation(workflow);
      const payload = {
        workflow: workflow,
        instructions: workflowInstructions,
      };
      const response = await dispatch(createWorkflowInstructionsAsync(payload));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textCreatedSuccess")));
      onClose();
    } catch (err: any) {
      Sentry.captureException(err);
      const errMessage = err.message || t("common:textCreatedFailed");
      dispatch(enqueueErrorNotification(errMessage));
      setProcessing(false);
    }
  }

  function inputValidation(data: { name: string; description: string }) {
    if (!data.name.trim())
      throw new Error(t("workflow:create.textNameRequired"));
  }

  function handleDescriptionChange(data: string) {
    descriptionDataRef.current = data;
  }

  function onSelectInstruction(instruction: Instruction) {
    setInstruction(instruction);
  }

  function handleEditInstruction(instruction: Instruction) {
    setInstructions([
      ...instructions.map((ins) => {
        if (ins.step === instruction.step) {
          return instruction;
        }
        return ins;
      }),
    ]);
    handleCloseInstructionModal();
  }

  function handleCloseInstructionModal() {
    setOpen(false);
    setInstruction(null);
  }

  function handleAddInstruction(instruction: Instruction) {
    setInstructions([
      ...instructions,
      { ...instruction, id: instructions.length + 1 },
    ]);
    setOpen(false);
  }

  return (
    <MatModal
      disableBackdropClick
      open={visible}
      closeAfterTransition
      BackdropComponent={Backdrop}
      className="flex items-center justify-center"
    >
      <div>
        <Grow in={visible}>
          <div
            className="relative flex flex-col w-full h-full overflow-hidden bg-white rounded shadow"
            style={{ maxWidth: "48em", maxHeight: "46em" }}
          >
            <div className="flex items-center flex-shrink-0 h-16 px-4 bg-gray-100 border-b">
              <div className="flex-1 px-4 text-xl font-bold">
                {t("workflow:create.title")}
              </div>

              <button
                className="flex items-center justify-center w-10 h-10 rounded focus:outline-none"
                onClick={onClose}
              >
                <i className="text-sm uir-cross" />
              </button>
            </div>
            <div className="px-8 my-4 text-lg font-bold">
              1. {t("workflow:createPage.textGeneralInfo")}
            </div>
            <div className="flex-grow px-8 py-8 overflow-y-auto">
              <form>
                <div className="grid items-center grid-cols-12">
                  <div className="col-span-3 px-4 my-4 text-right">
                    {t("workflow:create.textName")}{" "}
                    <span className="text-red-500">*</span>
                  </div>
                  <div className="col-span-9">
                    <input
                      autoFocus
                      required
                      ref={nameRef}
                      type="text"
                      id="name"
                      placeholder={t("workflow:create.placeholderName")}
                      className="w-full px-2 py-2 border border-gray-200 rounded"
                    />
                  </div>

                  <div className="h-full col-span-3 px-4 my-4 text-right">
                    {t("workflow:create.textDescription")}
                  </div>
                  <div className="col-span-9">
                    <VBRichTextEditor
                      defaultValue=""
                      onChange={handleDescriptionChange}
                    />
                  </div>
                </div>
              </form>
            </div>

            <div className="flex items-center px-8 my-4">
              <div className="flex-1 text-lg font-bold">
                2. {t("workflow:createPage.textSteps")}
              </div>
              <button
                className="px-4 py-2 text-primary"
                onClick={() => setOpen(true)}
              >
                {t("workflow:createPage.buttonAddStep")}
              </button>
            </div>
            <div className="py-4 mx-8 overflow-hidden overflow-x-auto">
              <StepTransitions
                instructions={instructions as WorkflowInstructionDTO[]}
                onSelect={onSelectInstruction}
              />
            </div>

            <div className="flex justify-end px-8 py-4 bg-gray-100">
              <button
                className="px-4 py-2 mr-4 bg-white border rounded text-primary border-primary focus:outline-none"
                onClick={onClose}
              >
                {t("common:buttonCancel")}
              </button>
              <button
                className={classnames(
                  "px-4 py-2 text-white rounded focus:outline-none",
                  { "bg-gray-200": processing, "bg-primary": !processing }
                )}
                disabled={processing}
                onClick={handleCreate}
              >
                {t("common:buttonCreate")}
              </button>
            </div>
          </div>
        </Grow>
        {(open || instruction) && (
          <WorkflowInstructionModal
            visible
            step={instruction?.step || instructions.length + 1}
            instructions={instructions as WorkflowInstructionDTO[]}
            instruction={instruction as WorkflowInstructionDTO}
            onClose={handleCloseInstructionModal}
            onSubmit={handleAddInstruction}
            onEdit={handleEditInstruction}
          />
        )}
      </div>
    </MatModal>
  );
};
