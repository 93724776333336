/*
 * File: batch-labeling.state.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 10th December 2021 11:37:59 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { CornerstoneViewportEditor } from "components/dicom/cornerstone/viewport-editor/viewport-editor";
import { LabelingMode } from "domain/labeling/labeling-mode";
import { MutableRefObject } from "react";
import { LoadingStatus, RequestStatus } from "store/base/base.state";

export interface BatchLabelingState {
  mode: LabelingMode
  requestStatus: RequestStatus;
  loadingStatus: LoadingStatus;
  hideNavigation: boolean;
  toggleNavigation(): void;
  selectJob(jobIndex: number): void;
  selectNextJob(): void;
  selectPreviousJob(): void;
  handleExit(): void;
  currentViewportEditor: MutableRefObject<
    CornerstoneViewportEditor | undefined
  >;
}

export const defaultBatchLabelingState = {} as BatchLabelingState;
