/*
 * File: image-job-annotations-reseted.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 14th October 2022 11:32:33 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ImageWorkspaceState } from "../../image-workspace.state";

const THUNK_NAME = "imageWorkspace/imageJobAnnotationsResetedAsync";

interface Payload {
  jobId: number;
}

export const imageJobAnnotationsResetedAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: Payload) => {
    return payload;
  }
);

export function imageJobAnnotationsResetedBuilder(
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) {
  return builder.addCase(
    imageJobAnnotationsResetedAsync.fulfilled,
    ({ imageAnnotations }, action) => {
      const { jobId } = action.payload;

      imageAnnotations.annotations = imageAnnotations.annotations.filter(
        (anno) => anno.jobId !== jobId
      );
      imageAnnotations.relationAnnotations =
        imageAnnotations.relationAnnotations.filter(
          (anno) => anno.jobId !== jobId
        );
    }
  );
}
