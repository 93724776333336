/*
 * File: annotation-issue.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 4th July 2022 4:47:15 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { CircularProgress, Tooltip } from "@material-ui/core";
import {
  IconCheckedCircle,
  IconClose,
  IconTickCircle,
} from "components/common/vb-icon.component";
import { UserAvatar } from "components/common/vb-user-avatar.component";
import { Issue, IssueStatus } from "domain/common/issue";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { KeyboardEvent, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { enqueueSuccessNotification } from "store/common/notification/notification.actions";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { addCommentToIssueAsync } from "store/labeler/text-workspace/text-issues/thunks/add-comment.thunk";
import { resolveTextIssueAsync } from "store/labeler/text-workspace/text-issues/thunks/resolve-issue.thunk";
import { classnames } from "utilities/classes";
import { useDateToAgoString } from "utilities/date-time/date-to-ago-string";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { Point } from "utilities/math/point";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import classNames from "classnames";
import * as Sentry from "@sentry/react";
import useTextJobOptions from "pages/labeler/text-labeling/hooks/use-text-job-options";
import { addTextIssueAsync } from "store/labeler/text-workspace/text-issues/thunks/add-issue.thunk";

interface Props {
  tokenId: string;
  issue?: Issue;
  position: Point;
  labelingMode: TextLabelingMode;
  onClose(): void;
}
export const TokenIssueMenu = ({
  tokenId,
  issue,
  labelingMode,
  position,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const [editing, setEditing] = useState(!issue);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [processing, setProcessing] = useState(false);
  const { option, options, onChange } = useTextJobOptions();

  const { t } = useTranslation();

  async function handleClickResolve() {
    if (!issue) return;
    try {
      await dispatch(resolveTextIssueAsync({ issue }));
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
    } catch (error) {
      Sentry.captureException(error);
      console.log("ERROR", error);
    }
  }

  async function handleSubmit() {
    if (!inputRef.current || processing || !option || !tokenId) return;
    const content = inputRef.current.value.trim() || "";
    try {
      setProcessing(true);
      if (!issue) {
        await dispatch(
          addTextIssueAsync({
            tokenId,
            content,
            annotator: option.label,
          })
        );
        onClose();
      } else {
        await dispatch(addCommentToIssueAsync({ issue, content }));
        inputRef.current.value = "";
      }
      setProcessing(false);
    } catch (error) {
      Sentry.captureException(error);
      setProcessing(false);
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (KeyboardKey.Escape === event.key) {
      onClose();
    }
  }
  const placeholder = issue ? "Add a comment" : "Add an issue";

  useLayoutEffect(() => {
    function dragElement(element: HTMLElement) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;

      element.onmousedown = dragMouseDown;

      function dragMouseDown(e: any) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.body.style.cursor = "move";
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e: any) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        element.style.top = element.offsetTop - pos2 + "px";
        element.style.left = element.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.body.style.cursor = "default";
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
    if (containerRef.current) {
      dragElement(containerRef.current);
    }
  }, [containerRef]);

  useLayoutEffect(() => {
    const element = containerRef.current;
    if (!element || !element.parentElement) return;
    const bbox = element.getBoundingClientRect();
    const parentBbox = element.parentElement?.getBoundingClientRect();
    const padding = 24;
    const positionOffset = {
      x: 0,
      y: 0,
    };
    if (bbox.x + bbox.width > parentBbox.width) {
      positionOffset.x = parentBbox.width - padding - bbox.width;
    }
    if (bbox.x <= padding) positionOffset.x = padding;
    if (bbox.y <= padding) positionOffset.y = padding;
    setOffset(positionOffset);
  }, [containerRef]);
  return (
    <div
      ref={containerRef}
      id="issue-container-id"
      style={{
        left: offset.x ? offset.x : position.x,
        top: offset.y ? offset.y : position.y,
      }}
      className="absolute z-10"
    >
      <div className="overflow-y-auto bg-white divide-y rounded shadow w-80">
        {issue && (
          <>
            <div
              className={classnames("flex items-center  h-12 gap-2 px-4", {
                "bg-green-100":
                  IssueStatus.RESOLVED === issue.status ||
                  IssueStatus.FIXED === issue.status,
              })}
            >
              <div
                className={classnames(
                  "text-sm font-semibold uppercase flex-auto"
                )}
              >
                {issue.status}
              </div>

              {labelingMode !== TextLabelingMode.LABELER &&
                issue.status === IssueStatus.OPENED && (
                  <Tooltip title="Resolve this issue">
                    <button
                      className="flex items-center justify-center w-6 h-6"
                      onClick={handleClickResolve}
                    >
                      <IconTickCircle className="flex-none w-5 h-5" />
                    </button>
                  </Tooltip>
                )}
              {labelingMode !== TextLabelingMode.LABELER &&
                issue.status === IssueStatus.RESOLVED && (
                  <div className="flex items-center justify-center w-6 h-6 text-green-700 rounded">
                    <IconCheckedCircle className="flex-none w-5 h-5" />
                  </div>
                )}

              <button onClick={onClose}>
                <IconClose className="flex-none w-5 h-5" />
              </button>
            </div>
            <div className="py-2 space-y-1 overflow-y-auto max-h-96">
              <IssueComment
                user={issue.creator.name}
                content={issue.content}
                createdDate={issue.createdDate}
              />
              {issue.comments.map((comment) => {
                return (
                  <IssueComment
                    key={comment.id}
                    content={comment.content}
                    createdDate={comment.createdDate}
                    user={comment.creator.name}
                  />
                );
              })}
            </div>
          </>
        )}
        <div className="flex items-start gap-2 px-4 py-4">
          {currentUser && (
            <UserAvatar
              name={currentUser.displayName}
              className="flex-none w-8 h-8 text-xs"
            />
          )}
          <div className="flex-auto space-y-2">
            <div className="px-2 py-2 rounded bg-background-100">
              {!editing && (
                <div
                  className="flex items-center flex-auto h-6 text-background-500"
                  onClick={() => setEditing(true)}
                >
                  {placeholder}
                </div>
              )}
              {editing && (
                <textarea
                  className="bg-background-100"
                  autoFocus
                  placeholder={placeholder}
                  ref={inputRef}
                  onKeyDown={handleKeyDown}
                />
              )}
            </div>

            {!issue && (
              <div>
                <div>Assign to:</div>
                <VBSelectComponent
                  value={option}
                  options={options}
                  isClearable
                  menuPortalTarget={document.body}
                  onChange={onChange}
                />
              </div>
            )}
          </div>
          <button
            disabled={processing || !(issue || option)}
            className={classNames("relative", {
              "cursor-not-allowed opacity-30": processing || !(issue || option),
            })}
            onClick={handleSubmit}
          >
            <span className={classnames({ "opacity-0": processing })}>Add</span>
            {processing && (
              <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-full">
                <CircularProgress color="inherit" size={12} />
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

interface IssueCommentProps {
  user: string;
  content: string;
  createdDate: string;
}
const IssueComment = ({ user, createdDate, content }: IssueCommentProps) => {
  const timeAgo = useDateToAgoString(new Date(createdDate), new Date());
  return (
    <div className="py-2 space-y-1">
      <div className="flex items-center gap-2 px-4">
        <UserAvatar name={user} className="w-8 h-8 text-xs" />
        <strong>{user}</strong>
        <span className="text-sm text-background-500">{timeAgo}</span>
      </div>
      <p className="pr-4 pl-14">{content}</p>
    </div>
  );
};
