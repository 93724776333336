import axios, { AxiosInstance, AxiosResponse } from "axios";
import { ENV_CONFIG } from "configs/env.config";
import { setupLabelInterceptor } from "services/common/label-interceptor";
import { ParamsKey } from "services/common/params-key";
import { RequestOptions } from "services/common/request-options";
import {
  PredictOnImageResponseDTO,
  predictOnImageResponseMapper,
} from "../dtos/predict-on-image.dto";
import {
  PublishWebResponseDTO,
  ServingWebHealthResponseDTO,
  ServingWebRequestDTO,
  ServingWebResponseDTO,
} from "../dtos/serving-web.dto";

let _axiosInstance: AxiosInstance | undefined = undefined;

function getAxiosInstance() {
  if (_axiosInstance) return _axiosInstance;
  _axiosInstance = setupLabelInterceptor(
    axios.create({
      baseURL: `${ENV_CONFIG.LABEL_SERVICE_URL}/api/v1/serving-webs`,
    })
  );
  return _axiosInstance;
}

export const getServingWebs = (
  options: RequestOptions
): Promise<AxiosResponse<ServingWebResponseDTO[]>> => {
  const params = new URLSearchParams(options);
  return getAxiosInstance().get(`?${params.toString()}`);
};

export const getServingWebModels = (type: string) => {
  const params = new URLSearchParams({ type });
  return getAxiosInstance().get(`/model?${params.toString()}`);
};

export const getServingWebById = (
  servingWebId: string
): Promise<AxiosResponse<ServingWebResponseDTO>> => {
  return getAxiosInstance().get(`/${servingWebId}`);
};
export const getServingWebInfo = (
  servingWebId: string
): Promise<AxiosResponse<ServingWebResponseDTO>> => {
  return getAxiosInstance().get(`/${servingWebId}/info`);
};

export const getServingWebStatus = (
  servingWebId: string
): Promise<AxiosResponse<ServingWebHealthResponseDTO>> => {
  return getAxiosInstance().get(`/${servingWebId}/health`);
};

export const createServingWeb = (
  payload: Partial<ServingWebRequestDTO>
): Promise<AxiosResponse<ServingWebResponseDTO>> => {
  return getAxiosInstance().post("", payload);
};

export const patchServingWeb = (
  id: string,
  payload: Partial<ServingWebRequestDTO>
): Promise<AxiosResponse<ServingWebResponseDTO>> => {
  return getAxiosInstance().patch(`/${id}`, payload);
};

export const publishServingWeb = (
  id: string
): Promise<AxiosResponse<PublishWebResponseDTO>> => {
  return getAxiosInstance().post(`/${id}/publish`, { action: "Publish" });
};

export const depublishServingWeb = (
  id: string
): Promise<AxiosResponse<PublishWebResponseDTO>> => {
  return getAxiosInstance().post(`/${id}/publish`, { action: "Depublish" });
};

export const predictOnImage = async (
  id: string,
  file: File
): Promise<AxiosResponse<PredictOnImageResponseDTO>> => {
  const formData = new FormData();
  formData.append(ParamsKey.FILE, file);
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const config = {
    headers: headers,
    data: formData,
  };
  const response = await getAxiosInstance().post(
    `/${id}/predict`,
    formData,
    config
  );
  return {
    ...response,
    data: predictOnImageResponseMapper(response.data),
  };
};

export const ServingWebService = {
  getServingWebs,
  getServingWebInfo,
  getServingWebById,
  getServingWebModels,
  createServingWeb,
  patchServingWeb,
  publishServingWeb,
  depublishServingWeb,
  predictOnImage,
  getServingWebStatus,
};
