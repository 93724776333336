/*
 * File: batch-detail.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 6th January 2022 10:08:11 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useUnmount } from "ahooks";
import { VBMaskRequesting } from "components/common/vb-mask-requesting/vb-mask-requesting.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { DatasetProvider } from "pages/customer/datasets/context/dataset-provider";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BatchDTO, BatchStatus } from "services/label-service/dtos";
import {
  enqueueSuccessNotification,
  enqueueErrorNotification,
} from "store/common/notification/notification.actions";
import { resetBatchData } from "store/customer/batch-data/batch-data.slice";
import {
  selectBatchInstructions,
  selectBatchLabelers,
  selectBatchLabels,
  selectBatchPricing,
  selectBatchStepLabelers,
  selectBatchWorkflow,
  selectBatchWorkManagements,
  selectCanStartBatch,
  selectDoesBatchHasStepReview,
} from "store/customer/batch/batch.selectors";
import { resetBatchState } from "store/customer/batch/batch.slice";
import {
  loadBatchDataAsync,
  loadTasksAsync,
} from "store/customer/batch/batch.thunk";
import { selectCurrentProject } from "store/customer/project/project.selectors";
import { updateStatusBatchAsync } from "store/customer/project/project.slice";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { UpdateBatchModal } from "../pages/overview/components/update-batch/update-batch.modal";
import { BatchTasksProvider } from "../pages/tasks/batch-tasks.provider";
import { BatchDetailContext } from "./batch-detail.context";
import * as Sentry from "@sentry/react";

interface Props {
  children: JSX.Element;
  batch: BatchDTO;
}

export const BatchDetailProvider = ({ batch, children }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [requesting, setRequesting] = useState(false);
  const hasStepReview = useAppSelector(selectDoesBatchHasStepReview);
  const canStartBatch = useAppSelector(selectCanStartBatch);
  const project = useAppSelector(selectCurrentProject);
  const labels = useAppSelector(selectBatchLabels);
  const [editing, setEditing] = useState(false);

  const workflow = useAppSelector(selectBatchWorkflow);
  const workManagements = useAppSelector(selectBatchWorkManagements);
  const pricing = useAppSelector(selectBatchPricing);
  const labelers = useAppSelector(selectBatchLabelers);
  const batchInstructions = useAppSelector(selectBatchInstructions);
  const stepLabelers = useAppSelector(selectBatchStepLabelers);

  async function startBatch() {
    if (!canStartBatch) return;
    if (requesting) return;
    setRequesting(true);
    try {
      const batchId = batch.id.toString();
      const payload = { ...batch, status: BatchStatus.WORKING };
      const response = await dispatch(updateStatusBatchAsync(payload));
      await dispatch(loadBatchDataAsync(batchId));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textStratedSuccess")));
      const options = { "batchId.equals": batchId, page: "0", size: "10" };
      dispatch(loadTasksAsync({ options }));
    } catch (err: any) {
      Sentry.captureException(err);
      const message = err.message || t("common:textStratedFailed");
      dispatch(enqueueErrorNotification(message));
    } finally {
      setRequesting(false);
    }
  }

  function onClickEditBatch() {
    setEditing(true);
  }

  useUnmount(() => {
    dispatch(resetBatchState());
    dispatch(resetBatchData());
  });

  const state = {
    labels,
    project,
    batch,
    canStartBatch: !!canStartBatch,
    startBatch,
    requesting,
    setRequesting,
    hasStepReview,
    onClickEditBatch,
    workflow,
    workManagements,
    pricing,
    labelers,
    batchInstructions,
    stepLabelers,
  };

  return (
    <BatchDetailContext.Provider value={state}>
      <DatasetProvider>
        <BatchTasksProvider>{children}</BatchTasksProvider>
      </DatasetProvider>
      {requesting && <VBMaskRequesting />}
      {editing && (
        <UpdateBatchModal onClose={() => setEditing(false)} batch={batch} />
      )}
    </BatchDetailContext.Provider>
  );
};
