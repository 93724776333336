import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentService } from "services/payment";
import { CurrencyResponseDTO } from "services/payment/payment.dto";
import { RootState } from "store";
import { INITIAL_PAYMENT_STORE_STATE, PaymentStoreState } from "./payment.state";


const SLICE_NAME: string = "payment";

export const loadPaymentCurrenciesAync = createAsyncThunk(
  `${SLICE_NAME}/loadPaymentCurrencies`,
  async (_, {getState}) => {
    const rootState: RootState = getState() as RootState;
    if (rootState.payment.currencies.length > 0) {
      return {
        needUpdate: false,
        data: [],
      }
    }
    const res = await PaymentService.getAllCurrency();
    return {
      needUpdate: true,
      data: res.data,
    }
  }
);

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_PAYMENT_STORE_STATE,
  reducers: {
    setPaymentCurrencies: (
      state: PaymentStoreState,
      action: PayloadAction<CurrencyResponseDTO[]>
    ) => {
      state.currencies = [...action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadPaymentCurrenciesAync.fulfilled, (state, action) => {
        const {needUpdate, data} = action.payload;
        if (!needUpdate) return;
        state.currencies = [...data];
      });
  }
});

export const {
  setPaymentCurrencies,
} = slice.actions;

export const paymentReducer = slice.reducer;
