/*
 * File: index.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import ApplicationLoader from "components/application-loader";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Provider } from "react-redux";
import App from "./app/app.component";
import { store } from "./store";
import { ENV_CONFIG, isLocal } from "configs/env.config";

if (!isLocal()) {
  Sentry.init({
    dsn: ENV_CONFIG.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
ReactDOM.render(
  <ApplicationLoader>
    <Provider store={store}>
      <App />
    </Provider>
  </ApplicationLoader>,
  document.getElementById("root")
);
