/*
 * File: work-management.api.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 18th August 2021 5:30:14 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { WorkManagementDTO } from "../dtos/work-management.dto";
import { BaseAPI } from "./base.api";

export class WorkManagementAPI extends BaseAPI<WorkManagementDTO> {}
