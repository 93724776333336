import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import { IconEye } from "components/common/vb-icon.component";
import { useTranslation } from "react-i18next";
import { MlModelsExperimentUIModel } from "./experiments.models";
import { useState } from "react";
import { Link } from "react-router-dom";
interface Props {
  rows: MlModelsExperimentUIModel[];
  isLoading?: boolean;
  onRowAction?: (
    row: MlModelsExperimentUIModel,
    action: "delete" | "view"
  ) => void;
}
export const ExperimentsTable = ({
  rows,
  isLoading = false,
  onRowAction,
}: Props) => {
  const { t } = useTranslation();
  const columns: GridColDef[] = [
    {
      field: "id",
      filterable: false,
      renderHeader: () => <GridColumnHeader header="ID" />,
      renderCell: (params: GridCellParams) => {
        return <GridRowLink params={params} />;
      },
      flex: 1,
    },
    {
      field: "name",
      filterable: false,
      flex: 1,
      renderHeader: () => <GridColumnHeader header="Name" />,
      renderCell: (params: GridCellParams) => {
        return <GridRowItem params={params} />;
      },
    },

    {
      field: "createdAt",
      filterable: false,
      flex: 1,
      renderHeader: () => <GridColumnHeader header="Created Date" />,
      renderCell: (params: GridCellParams) => {
        if (!params.value) return "";
        const value = `${t("common:formattedDateTime", {
          date: params.row.createdAt,
        })} - ${params.row.createdAt.toLocaleTimeString()}`;
        return (
          <div className="w-full px-2 truncate" title={value}>
            {value}
          </div>
        );
      },
    },

    {
      field: "uuid",
      filterable: false,
      minWidth: 120,
      renderHeader: () => <GridColumnHeader header="Actions" />,
      renderCell: (params: GridCellParams) => {
        return (
          <div className="flex items-center w-full gap-2 px-2 truncate">
            <Link to={`/models/experiments/${params.row.id}`}>
              <IconEye className="flex-none w-4 h-4" />
            </Link>
          </div>
        );
      },
    },
  ];
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  return (
    <div className="w-full h-full">
      <DataGrid
        className="bg-white"
        loading={isLoading}
        rows={rows.map((row) => {
          return { ...row, uuid: row.id };
        })}
        columns={columns}
        selectionModel={selectedIds}
        checkboxSelection
        onSelectionModelChange={(model) => setSelectedIds(model as number[])}
        headerHeight={60}
      />
    </div>
  );
};

interface GridColumnHeaderProps {
  header: string;
  className?: string;
}

function GridColumnHeader({
  header,
  className = "py-2 px-0.5 font-semibold capitalize",
}: GridColumnHeaderProps) {
  return <div className={className}>{header}</div>;
}

function GridRowItem({ params }: { params: GridCellParams }) {
  return (
    <div className="px-2 truncate" title={params.value?.toString()}>
      {params.value}
    </div>
  );
}

function GridRowLink({ params }: { params: GridCellParams }) {
  return (
    <div
      className="px-2 truncate text-primary"
      title={params.value?.toString()}
    >
      <Link to={`/models/experiments/${params.row.id}`}>{params.value}</Link>
    </div>
  );
}
