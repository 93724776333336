/*
 * File: project-page-actions.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 1st February 2023 2:01:48 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useAppSelector } from "hooks/use-redux";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { selectBatchInfo } from "store/customer/batch/batch.selectors";
import BatchDetailActions from "../project-batch/batch-detail/components/batch-detail-actions.component";

export function ProjectPageActions() {
  const location = useLocation();
  const currentBatch = useAppSelector(selectBatchInfo);

  const isBatchDetailPage = useMemo(() => {
    if (!currentBatch) return false;
    const path = `${Routes.BATCHES}/${currentBatch.id}`;
    return location.pathname.includes(path);
  }, [location.pathname, currentBatch]);

  return (
    <div>
      {isBatchDetailPage && currentBatch && (
        <BatchDetailActions batch={currentBatch} />
      )}
    </div>
  );
}

export default ProjectPageActions;
