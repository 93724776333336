/*
 * File: annotation-context-menu.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 30th December 2021 9:24:11 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useKeyPress } from "ahooks";
import VBRadioInputComponent from "components/design-system/radio/radio.component";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { Annotation, AnnotationAttribute } from "domain/image-labeling";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { selectIsShowAnnotationToolbar } from "store/labeler/image-workspace/editor-setting/editor-setting.selectors";
import { selectImageLabelById } from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import {
  annotationAttributeMenuClosed,
  imageAnnotationAttributeUpdated,
} from "store/labeler/image-workspace/image-workspace.slice";
import { annotationUtils } from "utilities/annotations/annotation-util";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { Logger } from "utilities/logger";
import { Rectangle } from "utilities/math/rectangle";
import { useImageEditorContext } from "../../image-editor-context/image-editor.context";
import * as Sentry from "@sentry/react";

interface Props {
  annotation: Annotation;
}
export const AnnotationAttributeMenu = ({ annotation }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { cornerstoneHandler } = useImageEditorContext();
  const label = useAppSelector(selectImageLabelById(annotation.labelId));
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [values, setValues] = useState<AnnotationAttribute[]>(() => {
    if (!annotation.annotationData || annotation.annotationData.length === 0) {
      return [];
    }
    return annotation?.annotationData[0].attributes || [];
  });
  const isShowAnnotationTollbar = useAppSelector(selectIsShowAnnotationToolbar);
  const autoFocusIndex = useMemo(() => {
    if (label?.attributes) {
      return label.attributes.find(
        (attr) => attr.type === "number" || attr.type === "string"
      );
    }
    return -1;
  }, [label]);
  const rect = useMemo<null | Rectangle>(() => {
    try {
      if (annotation && cornerstoneHandler.current) {
        const bounding = annotationUtils.annotationBoundingBox(annotation);
        return cornerstoneHandler.current?.canvasRectToPage(bounding);
      }
    } catch (err) {
      Sentry.captureException(err);
      Logger.log(err);
    }
    return null;
  }, [annotation, cornerstoneHandler]);

  function onClose() {
    dispatch(annotationAttributeMenuClosed());
  }

  useKeyPress(KeyboardKey.Escape, onClose, {
    target: containerRef.current,
  });

  function handleClose() {
    if (!cornerstoneHandler.current) return;
    if (!containerRef.current) return;
    if (!rect) return;
    const annotationId = annotation.uuid;
    const payload = { annotationId, attributes: values };
    const error = validateAttributeValues(values);
    if (error?.message) {
      dispatch(enqueueErrorNotification(error.message));
      return;
    }
    dispatch(imageAnnotationAttributeUpdated(payload));
    onClose();
  }

  function validateAttributeValues(values: AnnotationAttribute[]) {
    if (!label?.attributes || label.attributes.length === 0) return;
    for (const attribute of label.attributes) {
      if (!attribute.required) continue;
      const hasValue = values.some((val) => val.id === attribute.id);
      if (!hasValue) {
        const params = { name: label.name, attribute: attribute.name };
        const message = t("error:labeling.labelAttributeRequired", params);
        return { message };
      }
    }
  }

  function handleValueChanges(id: number, newValues: string[], submit = false) {
    let newVals = [];
    if (values.find((val) => val.id === id)) {
      newVals = [
        ...values.map((value) => {
          if (value.id === id) {
            return {
              ...value,
              value: newValues,
            };
          }
          return value;
        }),
      ];
    } else {
      newVals = [...values, { id, value: newValues }];
    }
    setValues(newVals);
    if (submit) {
      const annotationId = annotation.uuid;
      const payload = { annotationId, attributes: newVals };
      dispatch(imageAnnotationAttributeUpdated(payload));
      onClose();
    }
  }

  function getDefaultValues(attributeId: number): string[] {
    return (
      annotation.annotationData[0].attributes?.find(
        (attr) => attr.id === attributeId
      )?.value || []
    );
  }

  function getDefaultValue(attributeId: number): string | undefined {
    const values =
      annotation.annotationData[0].attributes?.find(
        (attr) => attr.id === attributeId
      )?.value || [];

    if (!values || values.length === 0) return undefined;
    return values[0];
  }

  useEffect(() => {
    if (!rect || !contentRef.current || !contentRef.current.parentElement)
      return;
    const top = parseInt(contentRef.current.style.top);
    const paddingTop = isShowAnnotationTollbar ? 68 : 20;
    const maxTop =
      contentRef.current.parentElement?.clientHeight -
      contentRef.current.clientHeight -
      paddingTop;
    if (top > maxTop) contentRef.current.style.top = `${maxTop}px`;
    if (top < paddingTop) contentRef.current.style.top = `${paddingTop}px`;

    const left = parseInt(contentRef.current.style.left);
    const maxLeft =
      contentRef.current.parentElement?.clientWidth -
      contentRef.current.clientWidth -
      20;
    if (left > maxLeft) contentRef.current.style.left = `${maxLeft}px`;
    if (left < 20) contentRef.current.style.left = `20px`;
  }, [contentRef, rect, isShowAnnotationTollbar]);

  if (!rect || !label || !label.attributes || label.attributes.length === 0)
    return null;

  return (
    <div
      className="absolute top-0 left-0 z-50 w-full h-full"
      ref={containerRef}
    >
      <div className="relative w-full h-full">
        <div
          className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-5 animate-fade-in"
          onClick={handleClose}
        />

        <div
          ref={contentRef}
          style={{
            left: rect.x,
            top: rect.y + rect.height + 4,
            minWidth: "12rem",
          }}
          className="absolute z-50 text-sm bg-white rounded shadow-lg outline-none resize text-background-700 animate-zoom-in-up"
        >
          <div className="p-4 space-y-4 overflow-y-auto max-h-60">
            {label.attributes?.map((attribute, idx) => {
              return (
                <div key={attribute.name}>
                  {attribute.type.toLowerCase() === "text" && (
                    <VBTextInputComponent
                      autoFocus={autoFocusIndex === idx}
                      header={attribute.name}
                      require={attribute.required}
                      type="text"
                      className="w-full"
                      hideInputIcon
                      defaultValue={getDefaultValue(attribute.id)}
                      onInputSubmit={(val: string) =>
                        handleValueChanges(attribute.id, [val], true)
                      }
                      onInputChange={(val: string) =>
                        handleValueChanges(attribute.id, [val])
                      }
                    />
                  )}
                  {attribute.type.toLowerCase() === "number" && (
                    <VBTextInputComponent
                      header={attribute.name}
                      require={attribute.required}
                      type="number"
                      className="w-full"
                      hideInputIcon
                      autoFocus={autoFocusIndex === idx}
                      defaultValue={getDefaultValue(attribute.id)}
                      min={
                        attribute.ranges && attribute.ranges.length > 0
                          ? parseInt(attribute.ranges[0].toString())
                          : undefined
                      }
                      max={
                        attribute.ranges && attribute.ranges.length > 1
                          ? parseInt(attribute.ranges[1].toString())
                          : undefined
                      }
                      onInputChange={(val: string) =>
                        handleValueChanges(attribute.id, [val])
                      }
                    />
                  )}

                  {attribute.type.toLowerCase() === "select" && (
                    <VBRadioInputComponent
                      multiple
                      require={attribute.required}
                      header={attribute.name}
                      options={attribute.options || []}
                      optionClassName="whitespace-nowrap"
                      defaultValues={getDefaultValues(attribute.id)}
                      onValueChange={(values) =>
                        handleValueChanges(attribute.id, values)
                      }
                    />
                  )}

                  {attribute.type.toLowerCase() === "radio" && (
                    <VBRadioInputComponent
                      require={attribute.required}
                      header={attribute.name}
                      options={attribute.options || []}
                      optionClassName="whitespace-nowrap"
                      defaultValues={getDefaultValues(attribute.id)}
                      onValueChange={(values) =>
                        handleValueChanges(attribute.id, values)
                      }
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
