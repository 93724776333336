import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBaseCell from "./table-base-cell.component";
import { TableBaseHeaderProps } from "./types";

export const TableBaseHeader = ({
  columns,
  render,
  classNames,
  ...restProps
}: TableBaseHeaderProps) => {
  return (
    <TableHead className={classNames?.header} {...restProps}>
      <TableRow className={classNames?.row}>
        {render
          ? render(columns)
          : columns.map((column) => (
              <TableBaseCell key={column.key} {...column.cellProps}>
                <span className="text-sm font-bold text-background-700">{column.title}</span>
              </TableBaseCell>
            ))}
      </TableRow>
    </TableHead>
  );
};
