/*
 * File: batch-labeler-performance.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 5:56:15 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppSelector } from "hooks/use-redux";
import { UserDTO } from "services/user-service/dtos/user.dto";
import { selectBatchDistributionData } from "store/customer/batch-data/labeler-distribution/labeler-distribution.selectors";
import { selectBatchLabels } from "store/customer/batch/batch.selectors";
import { AtLeast } from "types/common";
import { LabelerPerformance } from "../../../../../../../project-overview/performance/labeler-performance.component";

interface Props {
  batchId: number;
  labeler: AtLeast<UserDTO, "email">;
  expanded?: boolean;
  onExpandChange?(expand: boolean): void;
}

export const BatchLabelerPerformance = ({
  batchId,
  labeler,
  expanded,
  onExpandChange,
}: Props) => {
  const labels = useAppSelector(selectBatchLabels);
  const distributionData = useAppSelector(
    selectBatchDistributionData(batchId, labeler.email)
  );
  return (
    <LabelerPerformance
      distributionData={distributionData}
      labeler={labeler}
      labels={labels.map((label) => label.observation)}
      expanded={expanded}
      onExpandChange={onExpandChange}
    />
  );
};
