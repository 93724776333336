/*
 * File: workspace.page.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 4th August 2021 2:35:55 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useState } from "react";
import { useMount } from "ahooks";
import { useAppDispatch } from "hooks/use-redux";
import { CreateWorkspaceDialog } from "pages/customer/workspace/components/create-workspace-dialog.component";
import { loadWorkspacesAsync } from "store/common/user-workspace/user-workspace.thunk";
import { AllWorkspaceSection } from "./sections/all-workspace-section.component";
import { ManageWorkspaceHeader } from "./sections/manage-workspace-header.component";
import { OwnWorkspaceSection } from "./sections/own-workspace-section.component";

export const ManageWorkspacePage = () => {
  const dispatch = useAppDispatch();
  const [creatingWorkspace, setCreatingWorkspace] = useState(false);

  function handleCreateWorkspace() {
    setCreatingWorkspace(true);
  }

  function closeCreateWorkspaceDialog() {
    setCreatingWorkspace(false);
  }

  useMount(() => {
    dispatch(loadWorkspacesAsync());
  });

  return (
    <div className="flex justify-center bg-background-100">
      <div className="w-full p-4" style={{ maxWidth: 1400 }}>
        <ManageWorkspaceHeader handleCreateWorkspace={handleCreateWorkspace} />
        <OwnWorkspaceSection handleCreateWorkspace={handleCreateWorkspace} />
        <AllWorkspaceSection />
        {creatingWorkspace && (
          <CreateWorkspaceDialog
            open={creatingWorkspace}
            onClose={closeCreateWorkspaceDialog}
          />
        )}
      </div>
    </div>
  );
};
