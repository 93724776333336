/*
 * File: annotation-toobar.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 7th March 2022 2:01:24 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

/*
 * File: annotation-pane.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 4th August 2021 10:39:57 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  IconTag,
  IconPaintBucket,
  IconDocumentText,
} from "components/common/vb-icon.component";
import { LightTooltip } from "components/material/mat-light-tooltip.component";
import { useWebBuilderPreviewContext } from "pages/customer/web-builder-preview/context/web-builder-preview.context";

import { classnames } from "utilities/classes";

export const AnnotationToolbar = () => {
  const {
    showTextValue,
    showAnnotationLabel,
    fillAnnotationShape,
    toggleTextValue,
    toggleAnnotationLabel,
    toggleFillAnnotationShape,
  } = useWebBuilderPreviewContext();

  return (
    <div className="absolute right-4 bottom-6">
      <div className="flex items-center flex-none gap-2 px-4">
        <ToggleButton
          active={showAnnotationLabel}
          onClick={toggleAnnotationLabel}
        >
          <IconTag className="w-5 h-5" />
        </ToggleButton>

        <ToggleButton
          active={fillAnnotationShape}
          onClick={toggleFillAnnotationShape}
        >
          <IconPaintBucket className="w-5 h-5" />
        </ToggleButton>

        <ToggleButton
          tooltip={showTextValue ? "Hide text value" : "Show text value"}
          active={showTextValue}
          onClick={toggleTextValue}
        >
          <IconDocumentText className="w-5 h-5" />
        </ToggleButton>
      </div>
    </div>
  );
};

interface ToggleButtonProps {
  tooltip?: string;
  active?: boolean;
  children: JSX.Element;
  onClick?(): void;
}
const ToggleButton = (props: ToggleButtonProps) => {
  const { tooltip, active, children, onClick } = props;
  return (
    <LightTooltip title={tooltip || ""} hidden={!tooltip}>
      <button
        className={classnames(
          "w-8 h-8 rounded text-white flex items-center justify-center flex-none",
          {
            "bg-warning-500": !!active,
            "text-white": !active,
          }
        )}
        onClick={onClick}
      >
        {children}
      </button>
    </LightTooltip>
  );
};
