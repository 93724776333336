/*
 * File: manage-workspace-card.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { WorkspaceAvatar } from "components/common/vb-workspace-avatar.component";
import { GroupDTO } from "services/user-service/dtos/group.dto";
import { useAppSelector } from "hooks/use-redux";
import { selectIsGlobalAdmin } from "store/auth/auth.selectors";
import { useModalContext } from "contexts/modal";
import { useMemo, MouseEvent } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { ModalTypes } from "contexts/modal/modal.state";
import { Trash } from "iconsax-react";

interface CardProps {
  workspace: GroupDTO;
  isCurrent: boolean;
  onSelect?: (workspace: GroupDTO) => void;
}

export const WorkspaceCard = ({
  workspace,
  isCurrent,
  onSelect,
}: CardProps) => {
  const { t } = useTranslation();
  const isGlobalAdmin = useAppSelector(selectIsGlobalAdmin);
  const { openModal, closeModal } = useModalContext();
  const isWorkspaceAdmin = useMemo(() => {
    return (
      (workspace as any).hasOwnProperty("customer") &&
      (workspace as any)["customer"] === true
    );
  }, [workspace]);

  function onClickDelelte(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    openModal(ModalTypes.DELETE_WORKSPACE, {
      workspaceId: workspace.groupId,
      workspaceName: workspace.groupName,
      onClose: closeModal,
    });
  }

  return (
    <div
      className="relative w-full h-40 px-8 py-2 overflow-hidden duration-200 bg-white rounded-lg shadow cursor-pointer group hover:shadow-lg"
      onClick={() => onSelect && onSelect(workspace)}
    >
      <div className="flex justify-between w-full py-4">
        <WorkspaceAvatar
          name={workspace.groupName}
          className="w-16 h-16 text-xl rounded"
        />
        {isCurrent && (
          <div>
            <div className="px-3 py-1 mt-2 text-white rounded-full bg-secondary-300">
              {t("workspace:card.textCurrent")}
            </div>
          </div>
        )}
      </div>
      <h3 className="w-full text-lg truncate">{workspace.groupName}</h3>
      {isGlobalAdmin && isWorkspaceAdmin && (
        <Tooltip title="Delete">
          <IconButton
            style={{ position: "absolute" }}
            className="absolute opacity-0 group-hover:opacity-100 top-2 right-2"
            onClick={onClickDelelte}
          >
            <Trash size={20} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};
