/*
 * File: open-project.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 26th April 2022 11:15:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { LabelerProject } from "domain/image-labeling";
import { Link } from "react-router-dom";
import { Routes } from "routers/config/routes";
import GeneralImageThumbnail from "assets/images/labeling/general_image.jpg";
import TextThumbnail from "assets/images/labeling/text.jpg";
import AudioThumbnail from "assets/images/labeling/audio.jpg";
import MdiThumbnail from "assets/images/labeling/mdi.png";
import PathologyThumbnail from "assets/images/labeling/pathology.jpeg";
import { LabelingType } from "constants/labeling.constant";
import { classnames } from "utilities/classes";
import { useMemo } from "react";
import { HistoryPermission } from "services/label-service/dtos";

const images: Record<string, string> = {
  [LabelingType.IMAGE_SEGMENTATION]: GeneralImageThumbnail,
  [LabelingType.IMAGE_DETECTION]: GeneralImageThumbnail,
  [LabelingType.IMAGE_CLASSIFICATION]: GeneralImageThumbnail,
  [LabelingType.IMAGE_OCR]: GeneralImageThumbnail,
  [LabelingType.MED_XRAY_CLASSIFICATION]: GeneralImageThumbnail,
  [LabelingType.MED_XRAY_DETECTION]: GeneralImageThumbnail,
  [LabelingType.MED_XRAY_SEGMENTATION]: GeneralImageThumbnail,
  [LabelingType.MED_MAM_SEGMENTATION]: GeneralImageThumbnail,
  [LabelingType.TEXT_NER_RE]: TextThumbnail,
  [LabelingType.TEXT_NER]: TextThumbnail,
  [LabelingType.TEXT_COR]: TextThumbnail,
  [LabelingType.AUDIO_STT]: AudioThumbnail,
  [LabelingType.TEXT_TTS]: AudioThumbnail,
  [LabelingType.MDI_SEGMENTATION]: MdiThumbnail,
  [LabelingType.MED_MDI_SEGMENTATION]: MdiThumbnail,
  [LabelingType.WSI_SEGMENTATION]: PathologyThumbnail,
  [LabelingType.WSI_DETECTION]: PathologyThumbnail,
};

export const routesMap: Record<string, string> = {
  [LabelingType.IMAGE_SEGMENTATION]: Routes.IMAGE_LABELING_PROJECT,
  [LabelingType.IMAGE_DETECTION]: Routes.IMAGE_LABELING_PROJECT,
  [LabelingType.IMAGE_CLASSIFICATION]: Routes.IMAGE_LABELING_PROJECT,
  [LabelingType.IMAGE_OCR]: Routes.IMAGE_LABELING_PROJECT,
  [LabelingType.MED_XRAY_CLASSIFICATION]: Routes.IMAGE_LABELING_PROJECT,
  [LabelingType.MED_XRAY_DETECTION]: Routes.IMAGE_LABELING_PROJECT,
  [LabelingType.MED_XRAY_SEGMENTATION]: Routes.IMAGE_LABELING_PROJECT,
  [LabelingType.MED_MAM_SEGMENTATION]: Routes.IMAGE_LABELING_PROJECT,
  [LabelingType.TEXT_NER_RE]: Routes.TEXT_LABELING,
  [LabelingType.TEXT_NER]: Routes.TEXT_LABELING,
  [LabelingType.TEXT_COR]: Routes.TEXT_LABELING,
  [LabelingType.AUDIO_STT]: Routes.SPEECH_TO_TEXT_LABELING,
  [LabelingType.TEXT_TTS]: Routes.TEXT_TO_SPEECH_LABELING,
  [LabelingType.MDI_SEGMENTATION]: Routes.THREE_D_LABELING,
  [LabelingType.MED_MDI_SEGMENTATION]: Routes.THREE_D_LABELING,
  [LabelingType.WSI_SEGMENTATION]: Routes.PATHOLOGY_LABELING,
  [LabelingType.WSI_DETECTION]: Routes.PATHOLOGY_LABELING,
};

interface Props {
  data: LabelerProject;
}
export function OpenProject({ data }: Props) {
  const allowViewHistory = useMemo(() => {
    return HistoryPermission.EDIT === data.historyPermission;
  }, [data]);

  const projectHistoryUrl = useMemo(() => {
    return Routes.LABELER_PROJECT_HISTORY.replace(
      ":projectId",
      data.id.toString()
    );
  }, [data]);

  const projectUrl = useMemo(() => {
    const projectType = data.type;
    if (routesMap.hasOwnProperty(projectType)) {
      return routesMap[projectType].replace(":projectId", data.id.toString());
    }
    return Routes.LABELER_HOME;
  }, [data]);

  return (
    <div className="flex flex-col w-full h-full max-w-lg overflow-hidden bg-white rounded-lg shadow">
      <div className="bg-black aspect-w-4 aspect-h-2">
        <img src={data.imageUrl ?? images[data.type]} alt={data.name} className="object-cover w-full h-full"/>
      </div>
      <h3 className="mx-4 my-4 overflow-hidden text-lg font-semibold text-background-700 line-clamp-3">
        {data.name}
      </h3>
      <p className="flex-1 mx-4 text-sm text-background-500 line-clamp-3">
        {data.description}
      </p>
      <div className="flex flex-row justify-end w-full">
        {allowViewHistory && data.available && (
          <Link to={projectHistoryUrl}>
            <button
              className={classnames(
                "flex items-center h-8 gap-1.5 px-3 mx-4 my-4 rounded-full border border-white hover:border-warning",
                {
                  "text-warning-500 bg-warning-50": !!data.available,
                  "pointer-events-none text-background-500": !data.available,
                }
              )}
            >
              <span>History</span>
            </button>
          </Link>
        )}
        <span className="flex-auto"></span>
        <Link to={projectUrl}>
          <button
            className={classnames(
              "flex items-center h-8 gap-1.5 px-3 mx-4 my-4 rounded-full border border-white hover:border-warning",
              {
                "text-warning-500 bg-warning-50": !!data.available,
                "pointer-events-none text-background-500": !data.available,
              }
            )}
          >
            <span>{data.available ? "Start labeling" : "Comming soon"}</span>
            <i className="text-xl uir-arrow-small-right"></i>
          </button>
        </Link>
      </div>
    </div>
  );
}
