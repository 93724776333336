/*
 * File: index.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 22nd July 2022 10:21:37 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { CircularProgress } from "@material-ui/core";
import { IconArrowRightLinear } from "components/common/vb-icon.component";
import { Link } from "react-router-dom";
import useProjectTemplates from "../project-templates/hooks/use-project-templates.hook";

interface Props {
  onSelectTemplate?(projectTemplateId?: string): void;
  onCreate?(): void;
}

export default function NoProject({ onCreate }: Props) {
  const { templates, isLoading } = useProjectTemplates();
  return (
    <div className="w-full max-w-screen-lg p-6 mx-auto my-10 space-y-6 bg-white">
      <div className="space-y-1">
        <h3 className="text-xl font-bold">Projects</h3>
        <p className="text-sm font-normal text-background-600">
          You haven't created a project yet. Get started by selecting a template
          or start from an empty project.
        </p>
      </div>
      <hr />

      {isLoading && (
        <div className="flex items-center justify-center p-10">
          <CircularProgress color="primary" size={32} />
        </div>
      )}
      {!isLoading && (
        <div className="grid grid-cols-1 overflow-y-auto md:grid-cols-2 gap-x-6 gap-y-8 max-h-96">
          {templates?.slice(0, 6).map((template) => {
            return (
              <ProjectTemplateItem
                id={template.id}
                key={template.id}
                name={template.title}
                type={template.type}
                description={template.description}
                imageUrl={template.imageUrl}
              />
            );
          })}
        </div>
      )}
      <hr />
      <div className="flex items-center justify-between gap-4">
        <Link to={"/projects/template"}>
          <button
            className="flex items-center gap-2 text-primary hover:underline"
            onClick={onCreate}
          >
            <span>Browse all templates</span>
          </button>
        </Link>
        <button
          className="flex items-center gap-2 text-primary hover:underline"
          onClick={onCreate}
        >
          <span>Or start from an empty project</span>
          <IconArrowRightLinear className="w-5" />
        </button>
      </div>
    </div>
  );
}

interface ProjectTemplateItemProps {
  id: number;
  name: string;
  description: string;
  type: string;
  imageUrl: string;
}
function ProjectTemplateItem({
  id,
  name,
  description,
  imageUrl,
}: ProjectTemplateItemProps) {
  return (
    <Link to={`/projects/template/${id}`}>
      <div className="flex items-start gap-4">
        <div className="flex-none w-32 h-24 overflow-hidden xl:w-48 xl:h-32 bg-background-100">
          <img
            src={imageUrl}
            className="object-cover w-full h-full"
            alt={name}
          />
        </div>
        <div className="pr-2 space-y-1">
          <h3 className="font-semibold">{name}</h3>
          <div>
            <p className="text-sm text-background-600">{description}</p>
          </div>
          <div className="flex items-center gap-2 text-sm cursor-pointer text-primary hover:underline">
            <span>Use this template</span>
            <IconArrowRightLinear className="w-4" />
          </div>
        </div>
      </div>
    </Link>
  );
}
