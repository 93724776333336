/*
 * File: text-issue-button.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 7th July 2022 3:26:36 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  selectIsShowTextIssues,
  selectTextIssues,
} from "store/labeler/text-workspace/text-issues/text-issues.selector";
import { toggleDisplayTextIssues } from "store/labeler/text-workspace/text-workspace.slice";

export const TextIssueButton = () => {
  const dispatch = useAppDispatch();
  const isShowTextIssues = useAppSelector(selectIsShowTextIssues);
  const textIssues = useAppSelector(selectTextIssues);

  function toggleDisplayIssues() {
    dispatch(toggleDisplayTextIssues());
  }
  return (
    <button
      className={isShowTextIssues ? "text-primary" : "text-background-700"}
      onClick={toggleDisplayIssues}
    >
      Issues ({textIssues.length})
    </button>
  );
};
