/*
 * File: project-statistic.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 11:38:52 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ProjectService } from "services/label-service";
import { RootState } from "store";
import {
  addEntity,
  requestFulfilled,
  requestPending,
  requestRejected,
} from "store/base/base.reducer";
import {
  ProjectStatisticDTO,
  ProjectStatisticState,
} from "./project-statistic.state";

export enum ProjectStatisticThunk {
  LOAD_PROJECT_STATISTIC = "projectStatistic/loadProjectStatisticAsync",
}

export const loadProjectStatisticAsync = createAsyncThunk(
  ProjectStatisticThunk.LOAD_PROJECT_STATISTIC,
  async (projectId: number, { getState }) => {
    const state = getState() as RootState;
    if (state.projectData.statistic.ids.includes(projectId)) {
      return state.projectData.statistic.entities[projectId];
    }
    const response = await ProjectService.getStatistic(projectId);
    const statisticData: ProjectStatisticDTO = {
      id: projectId,
      ...response.data,
    };
    return statisticData;
  }
);

export const projectStatisticReducerBuilder = <
  S extends { statistic: ProjectStatisticState }
>(
  builder: ActionReducerMapBuilder<S>
): ActionReducerMapBuilder<S> => {
  return builder
    .addCase(loadProjectStatisticAsync.pending, (state) => {
      requestPending(state.statistic);
    })
    .addCase(loadProjectStatisticAsync.rejected, (state, action) => {
      requestRejected(state.statistic, action.error);
    })
    .addCase(loadProjectStatisticAsync.fulfilled, (state, action) => {
      requestFulfilled(state.statistic);
      if (action.payload) {
        addEntity(state.statistic, action.payload);
      }
    });
};
