import { useState, useEffect, useCallback } from "react";
import { WorkspaceEmpty } from "../image-labeling/components/workspace-empty/workspace-empty.component";
import { ErrorDialog } from "../batch-labeling/components/error-dialog.component";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { ImageLabelingErrorDialog } from "../image-labeling/components/error-dialog/error-dialog.component";
import { ReopenDialog } from "../image-review/components/reopen-dialog.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  deleteComplexJobsJobInBatch,
  setComplexJobsJobData,
  setComplexJobWorkingStatus,
  updateComplexJobsJobData,
} from "store/labeler/complex-jobs/complex-jobs.slice";
import { useThreeDLabelingContext } from "./context/three-d-labeling.context";
import { ThreeDEditorProvider } from "./components/three-d-editor.provider";
import { AppError } from "utilities/errors/errors";
import {
  EditorEventSaveMeasurements,
  EditorLabel,
  EditorMetadataRowModel,
  ThreeDEditorEvents,
} from "./components/three-d-editor.models";
import { ThreeDJobUIModel } from "./context/three-d-labeling.state";
import { ThreeDJobsNav } from "./three-d-jobs-nav.component";
import { classnames } from "utilities/classes";
import { delay } from "services/common/common.apis";
import { ThreeDHeaderBar } from "./three-d-header-bar";
import { WorkingStatus } from "store/labeler/complex-jobs/complex-jobs.state";
import { selectComplexJobIdsByStatus } from "store/labeler/complex-jobs/complex-jobs.selectors";
import { selectComplexJobSettings } from "store/labeler/complex-jobs/complex-jobs-editor-setting/complex-jobs-editor-setting.selector";
import { useAutoSaveJob } from "hooks/use-auto-save-job";

interface Props {
  showMenuIssue?: boolean;
  fixedMenuIssue?: boolean;
  showIssues?: boolean;
  editorHeight?: string;
  showJobNav?: boolean;
  jobNavWidth?: string;
}
export const ThreeDLabelingDetails = ({
  editorHeight,
  showJobNav = false,
  jobNavWidth = "160px",
}: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [showReopenReason, setShowReopenReason] = useState(true);
  const [jobNavVisibility, setJobNavVisibility] = useState(false);

  const workingJobIds = useAppSelector(
    selectComplexJobIdsByStatus(WorkingStatus.WORKING)
  );
  const config = useAppSelector(selectComplexJobSettings);

  const {
    error,
    setError,
    uiJobRef,
    isLoadingJob,
    isSavingJob,
    saveJob,
    skipJob,
    handleConfirmExit,
    hasChanged,
    setHasChanged,
    setIsEditorReady,
  } = useThreeDLabelingContext();

  const autoSave = useAutoSaveJob({
    enabled: config.autoSave && workingJobIds.length > 0,
    duration: config.autoSaveDuration
      ? config.autoSaveDuration * 1000
      : undefined,
  });

  useEffect(() => {
    const uiJob = uiJobRef.current;
    if (uiJob && uiJob.job && uiJob.isFromBatch) {
      dispatch(
        setComplexJobsJobData({
          id: uiJob.job.id,
          data: uiJob,
        })
      );
    }
  }, [uiJobRef, uiJobRef.current?.job, dispatch]);

  const handleWorkingSegmentsChanged = useCallback(
    (labels: EditorLabel[]) => {
      const uiJob = uiJobRef.current;
      if (!uiJob || !uiJob.job) return;
      if (uiJob.workingSegments !== labels) {
        const newValue: ThreeDJobUIModel = {
          ...uiJob,
          workingSegments: labels,
        };
        dispatch(
          setComplexJobsJobData({
            id: uiJob.job.id,
            data: newValue,
          })
        );
        uiJobRef.current = newValue;
        setHasChanged(true);
        dispatch(setComplexJobWorkingStatus(WorkingStatus.WORKING));
      }
    },
    [dispatch, uiJobRef, setHasChanged]
  );

  const handleWorkingMetadataChanged = useCallback(
    (metadata: EditorMetadataRowModel[]) => {
      const uiJob = uiJobRef.current;
      if (!uiJob || !uiJob.job) return;
      if (uiJob.workingMetadata !== metadata) {
        const newValue: ThreeDJobUIModel = {
          ...uiJob,
          workingMetadata: metadata,
        };
        dispatch(
          setComplexJobsJobData({
            id: uiJob.job.id,
            data: newValue,
          })
        );
        uiJobRef.current = newValue;
        setHasChanged(true);
        dispatch(setComplexJobWorkingStatus(WorkingStatus.WORKING));
      }
    },
    [dispatch, uiJobRef, setHasChanged]
  );

  const handleErrorDialogClose = () => {
    history.push(Routes.LABELER_HOME);
  };

  const handleSkip = async () => {
    if (!error || !error.data) return;
    await skipJob(error.data as number);
    setError(undefined);
    const uiJob = uiJobRef.current;
    if (uiJob && uiJob.job && uiJob.isFromBatch) {
      dispatch(deleteComplexJobsJobInBatch(uiJob.job.id));
    }
  };

  const handleEditorLoaded = useCallback(() => {
    setIsEditorReady(true);
  }, [setIsEditorReady]);

  const handleEditorLoadError = useCallback(
    (error: any) => {
      setError(new AppError("unknow", `Failed to load 3D editor!.\n${error}`));
    },
    [setError]
  );

  // save measurements when needed
  useEffect(() => {
    const handleCommandSaveMeasurements = (e: any) => {
      const eventData = e.detail as EditorEventSaveMeasurements;
      dispatch(
        updateComplexJobsJobData({
          id: eventData.contextId as number,
          field: "workingMeasurements",
          fieldData: eventData.measurementsData,
        })
      );
    };

    document.addEventListener(
      ThreeDEditorEvents.COMMAND_SAVE_MEASUREMENTS,
      handleCommandSaveMeasurements
    );

    return () => {
      document.removeEventListener(
        ThreeDEditorEvents.COMMAND_SAVE_MEASUREMENTS,
        handleCommandSaveMeasurements
      );
    };
  }, [dispatch]);

  if (error) {
    const errorType = error.type;
    if (errorType === "no_job") return <WorkspaceEmpty />;
    if (errorType === "unknow")
      return (
        <ErrorDialog
          visible
          onClose={handleErrorDialogClose}
          message={error.message}
        />
      );
    if (errorType === "skippable")
      return (
        <ImageLabelingErrorDialog
          visible
          error={error.message}
          onClose={handleErrorDialogClose}
          onSubmit={handleSkip}
        />
      );
  }

  if (!uiJobRef.current) return null;

  const handleSave = async () => {
    if (!uiJobRef.current || !uiJobRef.current.job) return;
    const applyButton = document.getElementById("three-d-apply-button");
    if (applyButton) {
      applyButton.setAttribute("data-is-saving", "true");
      applyButton.click();
    } else {
      await saveJob(true);
    }
  };

  const handleComplete = async (accept = false, reject = false) => {
    if (!uiJobRef.current || !uiJobRef.current.job) return;
    const applyButton = document.getElementById("three-d-apply-button");
    if (applyButton) {
      applyButton.setAttribute("data-is-completing", "true");
      applyButton.click();
    } else {
      await saveJob(true, true, accept, reject);
    }
  };

  const handleClickBack = () => {
    handleConfirmExit();
  };

  const handleLabelMapChanged = () => {
    setHasChanged(true);
    dispatch(setComplexJobWorkingStatus(WorkingStatus.WORKING));
  };

  const toggleNavigation = async () => {
    setJobNavVisibility(!jobNavVisibility);
    // HACKING HERE: we wait for the 3d windows to update
    // then we can resize them,
    // we will find a better way to do this
    await delay(0.5);
    document.dispatchEvent(
      new CustomEvent(ThreeDEditorEvents.LAYOUT_CHANGE_SIZE, {})
    );
  };

  const uiJob = uiJobRef.current;

  return (
    <div className="relative flex flex-col h-full bg-background-900">
      {uiJob && uiJob.batch && uiJob.task && (
        <ThreeDHeaderBar
          autoSave={autoSave}
          isReviewStepJob={uiJob.isStepReviewJob}
          batch={uiJob.batch}
          task={uiJob.task}
          project={uiJob.project}
          disabledComplete={isLoadingJob || isSavingJob}
          disabledSave={isLoadingJob || isSavingJob || !hasChanged}
          countDownSecond={uiJob.countDownSecond}
          isReady={!isLoadingJob}
          onClickBack={handleClickBack}
          onClickSave={handleSave}
          onClickComplete={handleComplete}
          showButtons={!uiJob.isTaskReview}
          showInfo={false}
          jobNavVisibility={jobNavVisibility}
          showJobNav={showJobNav}
          onClickToggleJobNav={toggleNavigation}
          dicomTagsData={uiJob.dicomTagsData}
        />
      )}

      <div
        className="flex flex-grow w-full"
        style={{
          height: "0px", // prevent the parent's height bigger than the grand parent
        }}
      >
        <div
          className={classnames("relative transition-all h-full flex-grow")}
          style={{
            width: jobNavVisibility ? jobNavWidth : "0px",
          }}
        >
          <ThreeDJobsNav />
        </div>

        <div
          className="transition-all"
          style={{
            width: jobNavVisibility ? `calc(100% - ${jobNavWidth})` : "100%",
          }}
        >
          <ThreeDEditorProvider
            annotator={uiJob.job?.assignee}
            contextId={uiJob.job?.id}
            imageData={uiJob.imageData}
            editorLabelOptions={uiJob.editorLabelOptions}
            editorLabels={uiJob.workingSegments}
            initMeasurementsData={uiJob.workingMeasurements}
            workingLabelMap={uiJob.workingLabelMap}
            labelMapOptions={uiJob.labelMapOptions}
            editorMetadata={uiJob.workingMetadata}
            batchObservations={uiJob.batchObservations}
            editorHeight={editorHeight}
            onLabelMapChanged={handleLabelMapChanged}
            onWorkingLabelsChanged={handleWorkingSegmentsChanged}
            onWorkingMetadataChanged={handleWorkingMetadataChanged}
            onLoaded={handleEditorLoaded}
            onError={handleEditorLoadError}
          />
        </div>
      </div>

      {uiJob.reopenReason && showReopenReason && (
        <ReopenDialog
          open={showReopenReason}
          message={uiJob.reopenReason}
          onClose={() => setShowReopenReason(false)}
          onSubmit={() => setShowReopenReason(false)}
        />
      )}
    </div>
  );
};
