/*
 * File: text-labeling-header.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 15th October 2021 11:03:57 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Tooltip } from "@material-ui/core";
import {
  IconBook,
  IconInformationCircleOutline,
  IconKeyboard,
  IconSetting2,
} from "components/common/vb-icon.component";
import { UserMenu } from "components/layout/components/user-menu.component";
import { UseAutoSaveReturnValue } from "hooks/use-auto-save.hook";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { selectTextJob } from "store/labeler/text-labeling-batch/text-labeling-batch.selectors";
import {
  editorSettingsSelected,
  keyboardShortcutsSelected,
} from "store/labeler/text-workspace/text-editor-setting/text-editor-setting.slice";
import { selectShowLabelInstruction } from "store/labeler/text-workspace/text-editor/text-editor.selector";
import { selectTextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import {
  setShowBatchInstruction,
  setShowLabelInstruction,
} from "store/labeler/text-workspace/text-workspace.slice";
import { useTextWorkspaceContext } from "../../context/text-workspace/text-workspace.context";
import { TextAIComponent } from "../text-ai/text-ai.component";
import { ButtonCompleteJob } from "./components/button-complete-job.component";
import { ButtonSaveJob } from "./components/button-save-job.component";
import { TextIssueButton } from "./components/text-issue-button.component";
import { TextViewerMenu } from "./components/text-viewer-menu.component";

interface TextLabelingHeaderProps {
  autoSave: UseAutoSaveReturnValue;
}

export const TextLabelingHeader = ({ autoSave }: TextLabelingHeaderProps) => {
  const dispatch = useAppDispatch();
  const { handleConfirmExit } = useTextWorkspaceContext();

  const labelingJob = useAppSelector(selectTextJob);
  const showLabelInstruction = useAppSelector(selectShowLabelInstruction);
  const mode = useAppSelector(selectTextLabelingMode);

  function handleClickBack() {
    handleConfirmExit();
  }

  function onClickShowBatchInstruction() {
    dispatch(setShowBatchInstruction(true));
  }

  function onClickKeyboardShortcuts() {
    dispatch(keyboardShortcutsSelected());
  }

  function onClickEditorSettings() {
    dispatch(editorSettingsSelected());
  }

  function toggleLabelInstruction() {
    dispatch(setShowLabelInstruction(!showLabelInstruction));
  }

  return (
    <div className="z-50 flex items-center h-16 gap-4 px-4 bg-white shadow">
      <button onClick={handleClickBack}>
        <i className="uir-arrow-left"></i>
      </button>
      <Tooltip title={labelingJob?.batch?.name || ""}>
        <span
          className="overflow-hidden text-lg font-bold overflow-ellipsis whitespace-nowrap"
          style={{ maxWidth: 200 }}
        >
          {labelingJob?.batch?.name}
        </span>
      </Tooltip>
      <Spacer />
      <div className="flex items-center gap-2">
        <Tooltip title="View instruction">
          <button
            className="flex items-center justify-center flex-none w-8 h-8 rounded text-background-700 hover:bg-background-200"
            onClick={onClickShowBatchInstruction}
          >
            <IconInformationCircleOutline className="w-5 h-5" />
          </button>
        </Tooltip>

        <Tooltip title="Label definitions">
          <button
            className="flex items-center justify-center flex-none w-8 h-8 rounded text-background-700 hover:bg-background-200"
            onClick={toggleLabelInstruction}
          >
            <IconBook className="w-5 h-5" />
          </button>
        </Tooltip>

        <Tooltip title="Keyboard shortcuts">
          <button
            className="flex items-center justify-center flex-none w-8 h-8 rounded text-background-700 hover:bg-background-200"
            onClick={onClickKeyboardShortcuts}
          >
            <IconKeyboard className="w-5 h-5" />
          </button>
        </Tooltip>

        <Tooltip title="Editor settings">
          <button
            className="flex items-center justify-center flex-none w-8 h-8 rounded text-background-700 hover:bg-background-200"
            onClick={onClickEditorSettings}
          >
            <IconSetting2 className="w-5 h-5" />
          </button>
        </Tooltip>
      </div>

      <AutoSpacing />
      <TextIssueButton />
      <TextAIComponent />
      {mode === TextLabelingMode.LABELER && <TextViewerMenu />}
      <Spacer />
      <ButtonSaveJob autoSave={autoSave} />
      <ButtonCompleteJob />
      <Spacer />
      <UserMenu />
    </div>
  );
};

const AutoSpacing = () => <div className="flex-auto" />;
const Spacer = () => <div className="h-6 border-r-2" />;
