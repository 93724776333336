/*
 * File: training-jobs.api.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 6th September 2021 2:49:05 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { TrainingJobDTO } from "../dtos/training-job.dto";
import { BaseAPI } from "./base.api";

export class TrainingJobAPI extends BaseAPI<TrainingJobDTO> {
  getTrainingJobs(
    payload = {
      sort: "createdDate,desc",
    }
  ): Promise<TrainingJobDTO[]> {
    return this.getItems(payload).then((res) => res.data);
  }

  createTrainingJob(payload: CreateTrainingJobPayload) {
    return this.post(`${this.getPath()}?`, JSON.stringify(payload));
  }

  downloadModel(modelId: number, fileName?: string) {
    return this.get(`${this.getPath()}/${modelId}/model-download`, {
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName ? fileName : "model.tgz");
      document.body.appendChild(link);
      link.click();
    });
  }
}

export interface CreateTrainingJobPayload {
  dataGenerationId?: number;
  datasetVersionId: number;
  description: string;
  name: string;
  parameter: {
    epochs: number;
    modelName: string;
    modelVersion: string;
    pipelineId: string;
  };
  workspaceId: string;
}
