/*
 * File: text-conflict.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 9th August 2022 5:40:48 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import ConfigService from "configs/app-config";
import { Collection, collectionUtils } from "domain/common";
import {
  Token,
  NERAnnotationData,
  CORAnnotationData,
} from "domain/text-labeling";
import { SystemAnnotationData } from "domain/text-labeling/system-annotation";
import {
  buildTokens,
  buildAnnotationTokenIds,
} from "pages/labeler/text-labeling/components/text-viewer/text-viewer.utils";
import { RootState } from "store";
import { selectAppConfig } from "store/common/app-setting/app-setting.selectors";
import { TextWorkspaceState } from "../text-workspace.state";
import { textUtils } from "../utils/text-labeling.utils";

enum TextConflictThunk {
  SET_TEXT_CONFLICT_ASYNC = "textConflict/setTextLabelingConflictAsync",
  UPDATE_CONFLICT_ANNOTATION_ASYNC = "textConflict/updateConflictAnnotationAsync",
}

export const setTextLabelingConflictAsync = createAsyncThunk(
  TextConflictThunk.SET_TEXT_CONFLICT_ASYNC,
  async (_, { getState }) => {
    const state = getState() as RootState;
    const { labelingDatas } = state.textWorkspace.textConflict;
    const { sentences, batch } = state.textWorkspace.textLabeling;
    const textContent = sentences.map((s) => s.sentence).join(" ");
    const appConfig = selectAppConfig(state);
    const tokenizer = ConfigService.getTextTokenizer(
      appConfig,
      batch?.project?.type
    );
    const { tokenEntities, tokenIds } = buildTokens(sentences, tokenizer);
    const tokenCollection: Collection<Token> = {
      entities: tokenEntities,
      allIds: tokenIds,
    };
    const annotations: Collection<NERAnnotationData> = {
      allIds: [],
      entities: {},
    };
    const relationAnnotations: Collection<CORAnnotationData> = {
      allIds: [],
      entities: {},
    };
    const systemAnnotations: Collection<SystemAnnotationData> = {
      allIds: [],
      entities: {},
    };
    for (const data of labelingDatas) {
      if (!data.annotator) continue;
      for (const annotation of data.annotations) {
        const annotationId = `review-${annotation.id}`;
        const existedAnnotation = collectionUtils.getOne(
          annotations,
          annotationId
        );
        if (existedAnnotation) {
          existedAnnotation.annotators?.push(data.annotator);
          existedAnnotation.selected = true;
        } else {
          const { startIndex, endIndex } = annotation;
          const content = textContent.substring(startIndex, endIndex + 1);
          collectionUtils.addOne(annotations, {
            ...annotation,
            id: annotationId,
            annotators: [data.annotator],
            content: content,
            tokenIds: buildAnnotationTokenIds(annotation, tokenCollection),
          });
        }
      }

      for (const relation of data.relationAnnotations) {
        const relationId = `review-${relation.id}`;
        const existedAnnotation = collectionUtils.getOne(
          relationAnnotations,
          relationId
        );
        if (existedAnnotation) {
          existedAnnotation.annotators?.push(data.annotator);
          existedAnnotation.selected = true;
        } else {
          collectionUtils.addOne(relationAnnotations, {
            ...relation,
            id: relationId,
            from: `review-${relation.from}`,
            to: `review-${relation.to}`,
            annotators: [data.annotator],
          });
        }
      }

      const observationId = data.systemObservationId?.toString();
      if (observationId) {
        const existedAnnotation = collectionUtils.getOne(
          systemAnnotations,
          observationId
        );

        if (existedAnnotation) {
          existedAnnotation.annotators?.push(data.annotator);
          existedAnnotation.selected = true;
        } else {
          collectionUtils.addOne(systemAnnotations, {
            id: observationId,
            observationId,
            active: false,
            annotators: [data.annotator],
          });
        }
      }
    }

    for (let annotationId of annotations.allIds) {
      const annotation = annotations.entities[annotationId];
      annotation.isConflict = textUtils.isConflict(
        annotation.annotators || [],
        state.textWorkspace.textConflict.selectedAnnotators
      );
    }
    for (let annotationId of relationAnnotations.allIds) {
      const annotation = relationAnnotations.entities[annotationId];
      annotation.isConflict = textUtils.isConflict(
        annotation.annotators || [],
        state.textWorkspace.textConflict.selectedAnnotators
      );
      if (annotation.isConflict) {
        for (let annoId of annotations.allIds) {
          const anno = annotations.entities[annoId];
          if (anno.id === annotation.from || anno.id === annotation.to) {
            anno.isConflict = true;
          }
        }
      }
    }

    for (const relationId of relationAnnotations.allIds) {
      const relation = relationAnnotations.entities[relationId];
      const from = collectionUtils.getOne(annotations, relation.from);
      const to = collectionUtils.getOne(annotations, relation.to);
      if (from && to) {
        relation.content =
          (from.content || from.id) + " -> " + (to.content || to.id);
      }
    }

    return { annotations, relationAnnotations, systemAnnotations };
  }
);

export interface UpdateConflictAnnotationPayload {
  annotationId: string;
  reviewStatus?: "rejected" | "accepted";
}
export const updateConflictAnnotationAsync = createAsyncThunk(
  TextConflictThunk.UPDATE_CONFLICT_ANNOTATION_ASYNC,
  async ({ annotationId, reviewStatus }: UpdateConflictAnnotationPayload) => {
    return { annotationId, reviewStatus };
  }
);

export const textConflictReducerBuilder = (
  builder: ActionReducerMapBuilder<TextWorkspaceState>
): ActionReducerMapBuilder<TextWorkspaceState> => {
  return builder
    .addCase(setTextLabelingConflictAsync.fulfilled, (state, action) => {
      state.textConflict.annotations = action.payload.annotations;
      state.textConflict.relationAnnotations =
        action.payload.relationAnnotations;
      state.textConflict.systemAnnotations = action.payload.systemAnnotations;
    })
    .addCase(updateConflictAnnotationAsync.fulfilled, (state, action) => {
      const { annotationId, reviewStatus } = action.payload;
      const acceptAnnotations = [];
      const { annotations, relationAnnotations } = state.textConflict;
      const relation = collectionUtils.getOne(
        relationAnnotations,
        annotationId
      );
      if (relation) {
        relation.reviewStatus = reviewStatus;
        const from = collectionUtils.getOne(annotations, relation.from);
        const to = collectionUtils.getOne(annotations, relation.to);
        if (from) acceptAnnotations.push(from);
        if (to) acceptAnnotations.push(to);
      }
      const anno = collectionUtils.getOne(annotations, annotationId);
      if (anno) {
        anno.reviewStatus = reviewStatus;
        acceptAnnotations.push(anno);
      }

      if (reviewStatus === "accepted") {
        for (const annotation of acceptAnnotations) {
          annotation.reviewStatus = reviewStatus;
          const id = annotation.id.replace("review-", "");
          if (state.textEditor.observations.find((ob) => ob.id === id)) {
            continue;
          }
          state.textEditor.observations.push({
            id: id,
            observationId: annotation.observationId,
            startIndex: annotation.startIndex,
            endIndex: annotation.endIndex,
            source: annotation.id,
          });
        }

        if (relation) {
          state.textEditor.relations.push({
            id: relation.id.replace("review-", ""),
            text: relation.text,
            from: relation.from.replace("review-", ""),
            to: relation.to.replace("review-", ""),
            observationId: relation.observationId,
            color: relation.color,
            source: relation.id,
          });
        }
      } else {
        const annotationIds = acceptAnnotations.map((anno) => anno.id);
        if (relation) annotationIds.push(relation.id);
        state.textEditor.observations = state.textEditor.observations.filter(
          (anno) => !anno.source || !annotationIds.includes(anno.source)
        );
        state.textEditor.relations = state.textEditor.relations.filter(
          (anno) => !anno.source || !annotationIds.includes(anno.source)
        );
      }
    });
};
