import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBNumberTextInputComponent } from "components/design-system/number-text-input/number-text-input.component";
import { VBTextAreaComponent } from "components/design-system/text-area/text-area.component";
import { useAppDispatch } from "hooks/use-redux";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MlCreateModelVersionRequest } from "services/ml-service/ml-service.dto";
import { mlServiceApis } from "services/ml-service/ml.service";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { MlModelsRunInfo } from "../ml-models.models";
import * as Sentry from "@sentry/react";

interface Props {
  runInfo: MlModelsRunInfo;
  open: boolean;
  onClose?: () => void;
}
export const RegisterRunModal = ({ runInfo, open, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [modelName, setModelName] = useState("");
  const [description, setDescription] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const isValid = useMemo(() => {
    return !!modelName;
  }, [modelName]);

  const handleSubmit = async () => {
    try {
      setIsProcessing(true);
      const payload: MlCreateModelVersionRequest = {
        model_name: modelName.trim().replace(/\s+/g, "_"),
        run_id: runInfo.runId,
        description,
      };
      await mlServiceApis.createModelVersion(payload);
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));

      onClose && onClose();
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <VBModal
      title="Register model"
      open={open}
      textSubmit="Register"
      onClose={onClose}
      onSubmit={handleSubmit}
      disableSubmit={isProcessing || !isValid}
    >
      <div className="flex flex-col gap-4">
        <span className="truncate">{`Instance id: ${runInfo.runId}`}</span>
        <div>
          <VBNumberTextInputComponent
            type="text"
            label="Model name"
            placeholder="Enter model name"
            autoFocus
            defaultValue={modelName}
            listenChangedDefault
            onValueChanged={(v) => setModelName(v.toString())}
          />
        </div>
        <VBTextAreaComponent
          type="text"
          header="Description"
          placeholder="Enter description"
          defaultValue={description}
          onInputChange={(v) => setDescription(v.toString())}
        />
      </div>
    </VBModal>
  );
};
