/*
 * File: batch-info-button.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 25th October 2021 1:39:42 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import React from "react";
import Popover from "@material-ui/core/Popover";
import { IconInformationCircleOutline } from "components/common/vb-icon.component";
import { BatchDetailContent } from "pages/labeler/image-labeling/components/batch-detail-dialog/batch-detail-dialog.component";
import {
  BatchDTO,
  ProjectDTO,
  ProjectTypes,
} from "services/label-service/dtos";

interface Props {
  batch: BatchDTO;
  project: ProjectDTO;
}
export const BatchInfoButton = ({ batch, project }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const isTextProject = ProjectTypes.TEXT === project?.type;
  const isAudioProject = ProjectTypes.AUDIO === project?.type;
  return (
    <div className="flex items-center justify-center">
      <button
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        className="w-6 h-6"
        onMouseEnter={handlePopoverOpen}
      >
        <IconInformationCircleOutline
          className={`w-6 h-6 ${isTextProject ? "text-background-700" : isAudioProject ? "text-warning-500" : "text-white"}`}
        />
      </button>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: isTextProject ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: isTextProject ? "left" : "right",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div onMouseLeave={handlePopoverClose}>
          <BatchDetailContent
            batch={batch}
            project={project}
            onClose={handlePopoverClose}
          />
        </div>
      </Popover>
    </div>
  );
};
