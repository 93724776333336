/*
 * File: text-workspace.slice.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 5:13:07 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice } from "@reduxjs/toolkit";
import { textConflictActions } from "./text-conflict/text-conflict.actions";
import { textConflictReducerBuilder } from "./text-conflict/text-conflict.thunk";
import { textEditorActions } from "./text-editor/text-editor.actions";
import { textIssuesActions } from "./text-issues/text-issues.actions";
import { textIssuesReducerBuilder } from "./text-issues/text-issues.thunk";
import { textLabelingActions } from "./text-labeling/text-labeling.actions";
import { textLabelingReducerBuilder } from "./text-labeling/text-labeling.thunk";
import { textReviewActions } from "./text-review/text-review.actions";
import { taskReviewReducerBuilder } from "./text-review/text-review.thunk";
import {
  INITIAL_TEXT_WORKSAPCE_STATE,
  TextWorkspaceState,
} from "./text-workspace.state";

const SLICE_NAME: string = "textLabeling";
const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_TEXT_WORKSAPCE_STATE,
  reducers: {
    resetTextLabelingWorkspace(state: TextWorkspaceState) {
      Object.assign(state, INITIAL_TEXT_WORKSAPCE_STATE);
    },
    fetchNewTextLabelingJob: (state: TextWorkspaceState) => {
      Object.assign(state, INITIAL_TEXT_WORKSAPCE_STATE);
    },
    ...textLabelingActions,
    ...textEditorActions,
    ...textReviewActions,
    ...textIssuesActions,
    ...textConflictActions,
  },
  extraReducers: (builder) => {
    textIssuesReducerBuilder(builder);
    textLabelingReducerBuilder(builder);
    taskReviewReducerBuilder(builder);
    textConflictReducerBuilder(builder);
  },
});

export const {
  resetTextLabeingState,
  resetTextLabelingWorkspace,
  fetchNewTextLabelingJob,
  resetTextEditorState,
  setSelectedSystemObservationId,
  setSelectedTokenIds,
  textAnnotationEdited,
  setActiveTokenObservationId,
  setActiveLabelId,
  setShowLabelInstruction,
  setLabelVisible,
  setContextMenuTokenId,
  setContextMenuAnnotationId,
  setSelectedLabelId,
  setShowPreviousJob,
  startReview,
  setReviewMode,
  setTextObservations,
  relationAdded,
  relationEdited,
  relationRemoved,
  relationHovered,
  relationsCleared,
  textAnnotationLabelSelected,
  textObservationAdded,
  textObservationEdited,
  textObservationRemoved,
  textObservationsCleared,
  observationDragging,
  aiAnnotationsGenerated,
  showLabelerWork,
  setShowBatchInstruction,
  resetTextIssuesState,
  toggleDisplayTextIssues,
  textIssueIdSelected,
  addTextIssue,
  textAnnotationContextMenuOpened,
  textAnnotationContextMenuClosed,
  textAnnotationIssueMenuOpened,
  textAnnotationIssueMenuClosed,
  textTokenContextMenuOpened,
  textTokenContextMenuClosed,
  textTokenIssueMenuOpened,
  textTokenIssueMenuClosed,
  setTextLabelingMode,
  textRelationNameDisplayTogged,
  textNavBarToggled,
  textSideBarToggled,
  textRelationDisplayTogged,
  textEntityDisplayTogged,
  textAutoSaveTogged,
  textReviewAnnotatorSelected,
  textReviewActiveTabChanged,
  textReviewAnnotationSelected,
  textReviewLabelSelected,
  resetTextConflictState,
  textConflictAnnotatorSelected,
  textConflictAnnotationSelected,
  textConflictAcceptAll,
  textConflictAcceptAllEntities,
  textConflictAcceptAllRelations,
  textConflictAcceptAgreeAnnotations,
  textConflictAcceptConflictAnnotations,
  textAnnotationsCleared,
  textAnnotationsReseted,
  textAnnotationEditStarted,
  textApplyAIAnnotations,
  textApplyImportedAnnotations,
  textApplyAnnotations,
} = slice.actions;

export const textWorkspaceReducer = slice.reducer;
