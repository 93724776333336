/*
 * File: ml-models-menu.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 30th August 2021 5:16:36 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useRouteMatch, useLocation, Link } from "react-router-dom";
import { ProjectRoutes } from "routers/config/routes";
import { classnames } from "utilities/classes";
import { MLModelsRoutes } from "./ml-models.route";
import { IconBox, IconBoxTick } from "components/common/vb-icon.component";

export const MODELS_MENU_ITEMS: MenuItemModel[] = [
  {
    link: MLModelsRoutes.MODELS,
    icon: <i className="text-xl uir-resources" />,
    name: "mlmodel:menu.tabPublishedModels",
  },
  {
    link: MLModelsRoutes.EXPORTED_DATASETS,
    icon: <IconBoxTick className="w-5 h-5" />,
    name: "mlmodel:menu.tabExportDataset",
  },
  {
    link: MLModelsRoutes.EXPERIMENTS,
    icon: <i className="text-xl uir-resources" />,
    name: "mlmodel:menu.tabMLBuilds",
  },
  // {
  //   link: MLModelsRoutes.PUBLISHED_MODELS,
  //   icon: <i className="text-xl uir-resources" />,
  //   name: "mlmodel:menu.tabPublishedModels",
  // },
  // {
  //   link: MLModelsRoutes.ML_WEB,
  //   icon: <IconBoxTick className="w-5 h-5" />,
  //   name: "mlmodel:menu.tabMLWebs",
  // },
];

export const MLModelsMenu = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(MODELS_MENU_ITEMS[0].link);

  useEffect(() => {
    const currentPath = location.pathname.replace(url, "");
    setCurrentPath(currentPath as ProjectRoutes);
  }, [location.pathname, url]);

  return (
    <div className="h-full py-4 border-r">
      <div className="flex items-center p-4">
        <IconBox className="w-8 h-8 text-primary" />
        <div className="flex-1 px-2 overflow-hidden text-xl truncate whitespace-nowrap ">
          {t("mlmodel:menu.textModels")}
        </div>
      </div>
      <div className="px-4">
        {MODELS_MENU_ITEMS.map((menuItem) => {
          return (
            <MenuItem
              key={menuItem.link}
              menuItem={menuItem}
              parentUrl={url}
              active={menuItem.link === currentPath}
            />
          );
        })}
      </div>
    </div>
  );
};

interface MenuItemModel {
  link: string;
  icon: JSX.Element;
  name: string;
}

const MenuItem = ({
  menuItem,
  parentUrl,
  active,
}: {
  menuItem: MenuItemModel;
  parentUrl: string;
  active?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Link to={`${parentUrl}${menuItem.link}`} key={menuItem.link}>
      <div
        className={classnames(
          "px-4 py-2 my-4 rounded w-full cursor-pointer  select-none flex items-center justify-start focus:outline-none overflow-hidden",
          {
            "hover:bg-gray-50": !active,
            "bg-gray-100 text-primary": !!active,
          }
        )}
      >
        {menuItem.icon}
        <span className="px-2 overflow-hidden whitespace-nowrap overflow-ellipsis">
          {t(menuItem.name)}
        </span>
      </div>
    </Link>
  );
};
