import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useAppSelector } from "hooks/use-redux";
import { useEffect, useState } from "react";
import { PriceByType, PricingCalculationStep, PricingRequestDTO, PricingResponseDTO } from "services/label-service/dtos/batch-pricing.dto";
import { selectPaymentCurrencySelectOptions } from "store/common/payment/payment.selectors";
import { CurrencySelectOption } from "store/common/payment/payment.state";
import { classnames } from "utilities/classes";
import { handleInvalidNumber } from "utilities/number/handle-invalid-number";
import { PriceByJobComponent, PriceByJobStepRoundUIModel, PriceByJobStepUIModel } from "./price-by-job.component";
import { PriceByTaskComponent } from "./price-by-task.component";

export interface BatchPricingUIModel {
  priceBy: PriceByType;
  selectedCurrencyOption: CurrencySelectOption;
  byJobSteps: PriceByJobStepUIModel[];
  perTask: number;
  totalTask?: number;
}

export const convertBatchPricingUIModelToPricingRequestDTO = (
  uiConfig: BatchPricingUIModel):
PricingRequestDTO => {  
  const payload: PricingRequestDTO = {
    type: uiConfig.priceBy,
    currencyId: uiConfig.selectedCurrencyOption.value as number,
    perTask: uiConfig.perTask,
    steps: uiConfig.byJobSteps.map((byJobStep, index) => {
      const apiStep: PricingCalculationStep = {
        rounds: [],
        stepNumber: index + 1,
      }
      for (const byJobRound of byJobStep.rounds) {
        apiStep.rounds.push({
          roundNumber: byJobRound.index,
          perJob: handleInvalidNumber(byJobRound.pricePerJob, 0),
        });
      }
      return apiStep;
    }),
  }
  return payload;
}

export const convertBatchPricingUIModelToPricingCalculationRequestDTO = (
  uiConfig: BatchPricingUIModel
): PricingRequestDTO => {  
  const payload: PricingRequestDTO = {
    type: uiConfig.priceBy,
    currencyId: uiConfig.selectedCurrencyOption.value as number,
    perTask: uiConfig.perTask,
    mediaCount: uiConfig.totalTask ? uiConfig.totalTask : 0,
    steps: uiConfig.byJobSteps.map((byJobStep, index) => {
      const apiStep: PricingCalculationStep = {
        rounds: [],
        required: byJobStep.required,
        stepNumber: index + 1,
      }
      for (const byJobRound of byJobStep.rounds) {
        apiStep.rounds.push({
          roundNumber: byJobRound.index,
          perJob: handleInvalidNumber(byJobRound.pricePerJob, 0),
        });
      }
      return apiStep;
    }),
  }
  return payload;
}

export const convertPricingResponseDTOToBatchPricingUIModel = (
  pricing: PricingResponseDTO,
): BatchPricingUIModel => {
  const payload: PricingRequestDTO = pricing.payload as PricingRequestDTO;

  const res: BatchPricingUIModel = {
    priceBy: payload.type,
    perTask: payload.perTask,
    selectedCurrencyOption: {
      label: pricing.currency.code,
      value: pricing.currency.id,
    },
    byJobSteps: [],
    totalTask: 0,
  };

  for (const stepPrice of payload.steps) {
    const byJobStep: PriceByJobStepUIModel = {
      stepTitle: "",
      id: 0,
      rounds: [],
    }
    for (const round of stepPrice.rounds) {
      const roundUI: PriceByJobStepRoundUIModel = {
        index: round.roundNumber,
        totalJob: 0,
        pricePerJob: round.perJob,
      }
      byJobStep.rounds.push(roundUI);
    }
    res.byJobSteps.push(byJobStep);
  }

  return res;
}

export interface BatchPriceTabsComponentProps {
  value?: Partial<BatchPricingUIModel>;
  onValueChanged?: (v: BatchPricingUIModel) => void;
  selectTarget?: HTMLElement;
  disabled?: boolean;
  containerClass?: string;
}
export const BatchPriceTabsComponent = ({
  value = undefined,
  onValueChanged,
  selectTarget,
  disabled = false,
  containerClass = "mt-6",
}: BatchPriceTabsComponentProps) => {
  const [byJobSteps, setByJobSteps] = useState<PriceByJobStepUIModel[]>([]);
  const [priceBy, setPriceBy] = useState(PriceByType.BY_JOB);
  const currencyOptions = useAppSelector(selectPaymentCurrencySelectOptions);
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState(() => {
    if (currencyOptions.length <= 0) return undefined;
    return currencyOptions[0];
  });
  const [perTask, setPerTask] = useState(0);

  useEffect(() => {
    if (!value) return;
    if (value.byJobSteps) setByJobSteps(value.byJobSteps);
    if (value.priceBy) setPriceBy(value.priceBy);
    if (value.selectedCurrencyOption) setSelectedCurrencyOption(value.selectedCurrencyOption);
    if (value.perTask) setPerTask(value.perTask);
  }, [value]);

  const priceByOptions = [
    {label: "Per Job", value: PriceByType.BY_JOB},
    {label: "Per Task", value: PriceByType.BY_TASK},
  ];

  if (!selectedCurrencyOption) return null;

  const callOnValueChanged = (field: keyof BatchPricingUIModel, newValue: any) => {
    if (onValueChanged) {
      let resValue: BatchPricingUIModel = {
        priceBy,
        selectedCurrencyOption,
        byJobSteps,
        perTask,
        totalTask: value ? value.totalTask : 0,
      }
      resValue = {
        ...resValue,
        [field]: newValue,
      }
      onValueChanged(resValue);
    }
  }

  const handlePriceByChanged = (v: PriceByType) => {
    setPriceBy(v);
    callOnValueChanged("priceBy", v);
  }

  const handleCurrencyChanged = (v: CurrencySelectOption) => {
    setSelectedCurrencyOption(v);
    callOnValueChanged("selectedCurrencyOption", v);
  }

  const handlePerTaskChanged = (v: number) => {
    setPerTask(v);
    callOnValueChanged("perTask", v);
  }

  const handleJobStepsChanged = (newItems: PriceByJobStepUIModel[]) => {
    setByJobSteps(newItems);
    callOnValueChanged("byJobSteps", newItems);
  }

  return (
    <div className={classnames(
      "flex flex-col gap-4",
      containerClass,
    )}>
      <VBSelectComponent
        options={priceByOptions}
        value={priceByOptions.find(option => option.value === priceBy)}
        onChange={(option: any) => handlePriceByChanged(option.value as PriceByType)}
        isDisabled={disabled}
      />
      <div>
        {
          priceBy === PriceByType.BY_JOB &&
          <PriceByJobComponent
            items={byJobSteps}
            currencyOptions={currencyOptions}
            selectedCurrencyOption={selectedCurrencyOption}
            onCurrencyOptionChanged={v => handleCurrencyChanged(v)}
            onItemsChanged={handleJobStepsChanged}
            selectTarget={selectTarget}
            disabled={disabled}
          />
        }
        {
          priceBy === PriceByType.BY_TASK &&
          <PriceByTaskComponent
            currencyOptions={currencyOptions}
            selectedCurrencyOption={selectedCurrencyOption}
            onCurrencyOptionChanged={v => handleCurrencyChanged(v)}
            defaultPerTask={perTask}
            onPerTaskChanged={handlePerTaskChanged}
            selectTarget={selectTarget}
            disabled={disabled}
          />
        } 
      </div>
    </div>
  );
}