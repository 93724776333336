/*
 * File: statistic-table.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 28th December 2021 4:45:00 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { VBTabs } from "components/common/vb-tabs/vb-tabs.component";
import { useAppSelector } from "hooks/use-redux";
import { useState } from "react";
import { selectDashBoardIsQueryNameLoading } from "store/customer/dashboard/dashboard.selectors";
import { useDashboardContext } from "../context/dashboard.context";
import { QueryNameStatistic } from "../context/dashboard.state";
import { BatchStatisticTable } from "./batch-table.component";
import { ProjectStatisticTable } from "./project-table.component";


export const ProjectBatchStatistic = () => {
  const {
    tableRowsProjects,
    tableRowsBatches,
  } = useDashboardContext();
  const [activeTab, setActiveTab] = useState("project");
  const isProjectLoading = useAppSelector(selectDashBoardIsQueryNameLoading(QueryNameStatistic.ProjectProgressStatistic));
  const isBatchLoading = useAppSelector(selectDashBoardIsQueryNameLoading(QueryNameStatistic.BatchProgressStatistic));

  return (
    <div className="w-full overflow-hidden bg-white rounded shadow">
      <VBTabs
        className="w-full"
        activeTab={activeTab}
        onSelect={setActiveTab}
        tabs={[
          { id: "project", name: `Projects (${tableRowsProjects.length})`, isLoading: isProjectLoading },
          { id: "batch", name: `Batches (${tableRowsBatches.length})`, isLoading: isBatchLoading },
        ]}
      />

      {activeTab === "project" && <ProjectStatisticTable data={tableRowsProjects} />}
      {activeTab === "batch" && <BatchStatisticTable data={tableRowsBatches} />}
    </div>
  );
};
