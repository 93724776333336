/*
 * File: create-issue.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 10th April 2023 5:52:42 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { VBTextAreaComponent } from "components/design-system/text-area/text-area.component";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { Option } from "domain/common";
import { useMemo, useState } from "react";

interface Props {
  assigneeOptions: Option[];
  axisOptions: Option[];
  onClose(): void;
  onSubmit(data: {
    annotator: string;
    slice: number;
    axis: number;
    comment: string;
  }): void;
}

export function CreateIssueModal({
  assigneeOptions,
  axisOptions,
  onSubmit,
  onClose,
}: Props) {
  const [selectedAxis, setSelectedAxis] = useState(() => {
    return axisOptions.length > 0 ? axisOptions[0].value : "";
  });
  const [selectedAnnotator, setSelectedAnnotator] = useState(() => {
    return assigneeOptions.length > 0 ? assigneeOptions[0].value : "";
  });

  const axis = useMemo(() => {
    return axisOptions.find((op) => op.value === selectedAxis);
  }, [selectedAxis, axisOptions]);

  const annotator = useMemo(() => {
    return assigneeOptions.find((op) => op.value === selectedAnnotator);
  }, [selectedAnnotator, assigneeOptions]);

  const [slice, setSlice] = useState(1);

  const [comment, setComment] = useState("");

  const isValid = useMemo(() => {
    const value = !!selectedAxis && !!selectedAnnotator && !!slice && !!comment;
    return value;
  }, [selectedAxis, selectedAnnotator, slice, comment]);

  function onAxisChange(option: any) {
    setSelectedAxis(option.value);
  }

  function onAnnotatorChange(option: any) {
    setSelectedAnnotator(option.value);
  }

  return (
    <VBModal
      title="Create a new issue"
      open
      onClose={onClose}
      disableSubmit={!isValid}
      onSubmit={() => {
        onSubmit({
          comment,
          axis: parseInt(selectedAxis),
          annotator: selectedAnnotator,
          slice,
        });
      }}
    >
      <section className="space-y-6">
        <VBSelectComponent
          header="Select annotator"
          options={assigneeOptions}
          value={annotator}
          onChange={onAnnotatorChange}
        />
        <VBSelectComponent
          header="Select axis"
          options={axisOptions}
          value={axis}
          onChange={onAxisChange}
        />
        <VBTextInputComponent
          onInputChange={(value) => setSlice(parseInt(value))}
          className="text-black"
          hideInputIcon
          header="Slice"
          type="number"
        />
        <VBTextAreaComponent
          onInputChange={setComment}
          className="text-black"
          header="Description"
        />
      </section>
    </VBModal>
  );
}
