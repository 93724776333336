/*
 * File: use-project-label-distribution.hook.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 4:03:37 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { usePrevious } from "ahooks";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect } from "react";
import { RequestStatus } from "store/base/base.state";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import {
  selectProjectLabelDistributionRequestError,
  selectProjectLabelDistributionRequestStatus,
} from "store/customer/project-data/label-distribution/label-distribution.selectors";
import { loadLabelDistributionAsync } from "store/customer/project-data/label-distribution/label-distribution.thunk";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import * as Sentry from "@sentry/react";

export const useProjectLabelDistribution = (
  projectId: number,
  reloadIfLoaded = false
) => {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(
    selectProjectLabelDistributionRequestStatus
  );
  const requestError = useAppSelector(
    selectProjectLabelDistributionRequestError
  );
  const previousProjectId = usePrevious(projectId);

  const requestData = useCallback(
    async (force = false) => {
      if (!force && requestStatus === RequestStatus.LOADING) return;
      if (!reloadIfLoaded && !force && requestStatus !== RequestStatus.IDLE) return;
      try {
        const response = await dispatch(loadLabelDistributionAsync(projectId));
        handleThunkRejected(response);
      } catch (error: any) {
        Sentry.captureException(error);
        const errMessage =
          error.message || "Failed to load label distribution data";
        dispatch(enqueueErrorNotification(errMessage));
        Logger.log(error);
      }
    },
    [dispatch, projectId, requestStatus, reloadIfLoaded]
  );

  useEffect(() => {
    if (projectId && projectId >= 0 && previousProjectId !== projectId){
      requestData(true);
    } else {
      requestData();
    }
  }, [dispatch, projectId, previousProjectId, requestData]);

  return { requestData, requestStatus, requestError };
};
