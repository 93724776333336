/*
 * File: storage-table.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 16th August 2021 9:34:53 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridSortModel,
} from "@material-ui/data-grid";
import { useMemo } from "react";
import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { useDatasetDetailContext } from "../../../dataset-detail.context";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { columns as defaultColumns } from "./storage-col-def";
import { formatDate } from "utilities/formatter/date-formatter.utils";

interface Props {
  columns?: GridColDef[];
  actions?: string[];
  onAction?: (action: string, row: any) => void;
}
export const StorageGrid = ({
  columns = defaultColumns,
  actions = [],
  onAction,
}: Props) => {
  const { t } = useTranslation();
  const {
    files,
    selecting,
    loading,
    selectedFiles,
    setSelectedFiles,
    setHoveredFile,
  } = useDatasetDetailContext();
  const { setSort, setFileName, setCreatedBy } = useDatasetContext();

  const handleSortChanged = (model: GridSortModel) => {
    if (model.length === 0 || !model[0]) {
      setSort("");
      return;
    }
    const { field, sort } = model[0];
    setSort(field, sort?.toString());
  };

  const handleFilterChanged = (model: GridFilterModel) => {
    if (!model?.items?.[0]) return;
    const { columnField, operatorValue, value } = model.items[0];
    if (columnField === "fileName" && operatorValue === "contains")
      setFileName(value);
    else if (columnField === "createdBy" && operatorValue === "contains")
      setCreatedBy(value);
  };

  const rows = useMemo(
    () =>
      files.map((f) => ({
        ...f,
        duplicateSrc: f.duplicatedRef?.fileName,
        createdDate: t("common:formattedDate", {
          date: formatDate(new Date(f.createdDate)),
        }),
        storeType: f.duplicatedRef ? f.duplicatedRef.storeType : f.storeType,
        tags: (f.tags ?? []).map((tag) => tag.name).join(", "),
        actions: actions,
        onAction: onAction,
      })),
    [files, t, actions, onAction]
  );

  return (
    <div className="w-full h-full">
      <DataGrid
        className="bg-white"
        loading={loading}
        rows={rows}
        columns={columns}
        selectionModel={selectedFiles}
        onSelectionModelChange={(model) => setSelectedFiles(model as number[])}
        sortingMode="server"
        onSortModelChange={handleSortChanged}
        filterMode="server"
        onFilterModelChange={handleFilterChanged}
        onRowEnter={(params) => setHoveredFile(params.row as StorageFileDTO)}
        checkboxSelection={selecting}
        headerHeight={80}
        hideFooter
        hideFooterPagination
      />
    </div>
  );
};
