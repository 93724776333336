/*
 * File: notification.state.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 28th June 2021 2:49:55 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { OptionsObject, SnackbarMessage } from "notistack";

export interface Notification {
  key: string;
  message: SnackbarMessage;
  options?: OptionsObject;
}

export interface NotificationState {
  notifications: Notification[];
}

export const INITIAL_NOTIFICATION_STATE: NotificationState = {
  notifications: [],
};
