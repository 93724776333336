import { AxiosResponse } from "axios";
import { storageAPIpost } from "../storage-httpclient";


export interface FileInfoInitResponseDTO {
  servingUrl: string;
}

// Use this for wsi project to get deepzoom file url
export async function initFileInfo(
  fileInfoId: string | number,
): Promise<AxiosResponse<FileInfoInitResponseDTO>> {
  const res = await storageAPIpost(`/api/v1/file-infos/${fileInfoId}/init`);

  if (res.data.downloadUrls && res.data.downloadUrls.length > 0) {
    res.data.url = res.data.downloadUrls[0];
  }

  return res;
}
