import { useParams } from "react-router-dom";
import { TextToSpeechLabelingProvider } from "./context/text-to-speech-labeling.provider"
import { TextToSpeechLabelingDetails } from "./text-to-speech-labeling.details"

export const TextToSpeechLabelingReview = () => {
  const { jobId } = useParams<{ jobId: string }>();

  if (!jobId) return null;

  return (
    <div className="h-screen overflow-scroll hide-scrollbar bg-background-900">
      <TextToSpeechLabelingProvider
        isFromJobId
        jobIdParam={jobId}
      >
        <TextToSpeechLabelingDetails fixedMenuIssue />
      </TextToSpeechLabelingProvider>
    </div> 
  )
}