/*
 * File: batch-statistic.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 23rd August 2021 11:45:49 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { BatchService } from "services/label-service";
import { RootState } from "store";
import {
  addEntity,
  requestFulfilled,
  requestPending,
  requestRejected,
} from "store/base/base.reducer";
import {
  BatchStatisticDTO,
  BatchStatisticState,
} from "./batch-statistic.state";

export enum BatchStatisticThunk {
  LOAD_BATCH_STATISTIC = "batchStatistic/loadBatchStatisticAsync",
}

export const loadBatchStatisticAsync = createAsyncThunk(
  BatchStatisticThunk.LOAD_BATCH_STATISTIC,
  async (batchId: number, { getState }) => {
    const state = getState() as RootState;
    if (state.batchData.statistic.ids.includes(batchId)) {
      return state.batchData.statistic.entities[batchId];
    }
    const response = await BatchService.getStatistic(batchId);
    const statisticData: BatchStatisticDTO = {
      id: batchId,
      ...response.data,
    };
    return statisticData;
  }
);

export const batchStatisticReducerBuilder = <
  S extends { statistic: BatchStatisticState }
>(
  builder: ActionReducerMapBuilder<S>
): ActionReducerMapBuilder<S> => {
  return builder
    .addCase(loadBatchStatisticAsync.pending, (state) => {
      requestPending(state.statistic);
    })
    .addCase(loadBatchStatisticAsync.rejected, (state, action) => {
      requestRejected(state.statistic, action.error);
    })
    .addCase(loadBatchStatisticAsync.fulfilled, (state, action) => {
      requestFulfilled(state.statistic);
      if (action.payload) {
        addEntity(state.statistic, action.payload);
      }
    });
};
