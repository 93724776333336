/*
 * File: image-labeling.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 9th August 2021 9:17:26 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AnnotateType } from "constants/annotation.constant";
import { collectionUtils } from "domain/common";
import { Job } from "domain/labeling/job";
import { ImageLabelingData } from "domain/labeling/labeling-data";
import { Task } from "domain/labeling/task";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { BatchDTO, ProjectDTO, StepType } from "services/label-service/dtos";
import { imageLabelMapper } from "services/label-service/mappers/image-label.mapper";
import { RootState } from "store";
import { RequestStatus } from "store/base/base.state";
import {
  selectImageLabelingActiveJobId,
  selectImageLabelingJobById,
} from "../batch-labeling/batch-labeling.selectors";
import { batchLabelingUtils } from "../batch-labeling/batch-labeling.util";
import { FindingAgreement, FindingLabeler } from "./image-labeling.state";

export const selectImageLabelingMode = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.mode;
};

export const selectImageLabelingRequestStatus = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.requestStatus;
};

export const selectIsWorkspaceLoading = (state: RootState) => {
  return (
    state.imageWorkspace.imageLabeling.requestStatus === RequestStatus.LOADING
  );
};

export const selectIsLoadedWorkspaceData = (state: RootState) => {
  return (
    state.imageWorkspace.imageLabeling.requestStatus ===
      RequestStatus.SUCCESS ||
    state.imageWorkspace.imageLabeling.requestStatus === RequestStatus.FAILURE
  );
};

export const selectImageLabelingError = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.error;
};

export const selectIsNoAvailableJob = (state: RootState) => {
  const { requestStatus, job } = state.imageWorkspace.imageLabeling;
  return requestStatus === RequestStatus.FAILURE && job === null;
};

export const selectWorkingJob = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.job as Job;
};

export const selectWorkingProject = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.project as ProjectDTO;
};

export const selectWorkingBatch = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.batch as BatchDTO;
};

export const selectCurrentTask = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.task as Task;
};

export const selectWorkingFile = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.file as StorageFileDTO;
};

export const selectBatchObservations = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.labels;
};

export const selectImageLabelingLabels = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.labels.filter(
    (lb) => !lb.isSystemLabel
  );
};

export const selectImageLabelingLabelOptions = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.labels
    .filter(
      (lb) =>
        lb.annotateType === AnnotateType.BOUNDING_BOX ||
        lb.annotateType === AnnotateType.POLYGON
    )
    .map((lb) => {
      return {
        label: lb.name,
        value: lb.id.toString(),
        color: lb.color ?? "",
        type: lb.annotateType as AnnotateType,
      };
    });
};

export const selectImageLabelingAllLabels = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.labels;
};

export const selectImageLabelById = (labelId: number) => (state: RootState) => {
  const labels = imageLabelMapper.flattenLabels(
    state.imageWorkspace.imageLabeling.labels
  );
  return labels.find((lb) => lb.id === labelId);
};

export const selectSelectedAnnotation = (state: RootState) => {
  const { selectedAnnotation, labels } = state.imageWorkspace.imageLabeling;
  if (!selectedAnnotation) return null;
  const lbId = parseInt(selectedAnnotation.labelId);
  const label = labels.find((lb) => lb.id === lbId);
  return {
    annotationId: selectedAnnotation.annotationId,
    label: label,
    text: selectedAnnotation.text || "",
  };
};

export const selectImageSystemLabels = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.labels.filter(
    (lb) => lb.isSystemLabel
  );
};

export const selectSelectedObservationId = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.selectedObservationId;
};

export const selectSelectedSystemObservationId = (state: RootState) => {
  const activeJobId = selectImageLabelingActiveJobId(state);
  const { imageAnnotations, imageLabeling } = state.imageWorkspace;
  const labelCollection = collectionUtils.fromEntities(imageLabeling.labels);
  const systemObservation = imageAnnotations.annotations.find((anno) => {
    if (anno.jobId !== activeJobId) return false;
    const label = collectionUtils.getOne(labelCollection, anno.labelId);
    return label && label.isSystemLabel;
  });
  return systemObservation?.labelId || -1;
};

export const selectSelectedSystemObservation = (state: RootState) => {
  const { imageAnnotations, imageLabeling } = state.imageWorkspace;
  const labelCollection = collectionUtils.fromEntities(imageLabeling.labels);
  const activeJobId = selectImageLabelingActiveJobId(state);
  const annotation = imageAnnotations.annotations.find((anno) => {
    if (anno.jobId !== activeJobId) return false;
    const label = collectionUtils.getOne(labelCollection, anno.labelId);
    return label && label.isSystemLabel;
  });
  if (!annotation) return undefined;
  return collectionUtils.getOne(labelCollection, annotation.labelId);
};

export const selectSelectedSystemObservationByJobId =
  (jobId: number) => (state: RootState) => {
    const { imageAnnotations, imageLabeling } = state.imageWorkspace;
    const labelCollection = collectionUtils.fromEntities(imageLabeling.labels);
    const annotation = imageAnnotations.annotations.find((anno) => {
      if (anno.jobId !== jobId) return false;
      const label = collectionUtils.getOne(labelCollection, anno.labelId);
      return label && label.isSystemLabel;
    });
    if (!annotation) return undefined;
    return collectionUtils.getOne(labelCollection, annotation.labelId);
  };

export const selectHasSelectedSystemLabel = (state: RootState) => {
  return !!selectSelectedSystemObservation(state);
};

export const selectCurrentObservation = (state: RootState) => {
  const { labels, selectedObservationId } = state.imageWorkspace.imageLabeling;
  return labels.find((bo) => bo.id === selectedObservationId);
};

export const selectHasSegmentationObservations = (state: RootState) => {
  const { labels } = state.imageWorkspace.imageLabeling;
  return labels.find(
    (bo) =>
      bo.annotateType === AnnotateType.BOUNDING_BOX ||
      bo.annotateType === AnnotateType.POLYGON
  );
};

export const selectSelectedObservation = (state: RootState) => {
  const { labels, selectedObservationId } = state.imageWorkspace.imageLabeling;
  const label = labels.find((bo) => bo.id === selectedObservationId);
  if (!label) return labels.find((lb) => !lb.isSystemLabel);
  return label;
};

export const selectTaskObservations = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.currentJobObservations;
};

export const selectCurrentObservations = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.currentJobObservations.filter(
    (t) => t.imageUrl === state.imageWorkspace.imageLabeling.file?.url
  );
};

export const selectPreviousJobAnnotations = (state: RootState) => {
  const jobId = selectImageLabelingActiveJobId(state);
  const data = selectImageLabelingJobById(jobId)(state);
  if (!data) return [];
  return (data.data?.previousJobData ?? []).map((item) =>
    batchLabelingUtils.labelingDataToJobAnnotation(item as ImageLabelingData)
  );
};

export const selectCanViewPreviousSteps = (state: RootState): boolean => {
  if (!state.imageWorkspace.imageLabeling.job) return false;
  const { step, canViewPreviousJob } = state.imageWorkspace.imageLabeling.job;
  return step > 1 && !!canViewPreviousJob;
};

export const selectFindingAgreements = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.agreements;
};

export const selectShowIoU = (state: RootState) => {
  return (
    state.imageWorkspace.imageLabeling.previousJobObservations.filter(
      (j) => j.selected
    ).length === 2
  );
};

export const selectIsShowPreviousJobs = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.viewMediaAgreement;
};

export const selectFindingLabelers = (state: RootState) => {
  const labelIds: { [key: number]: boolean } = {};
  const { labels } = state.imageWorkspace.imageLabeling;
  for (const label of labels) {
    const child = labels.find((b) => b.parentId && b.parentId === label.id);
    if (!child) labelIds[label.id] = true;
  }

  return state.imageWorkspace.imageLabeling.findingLabelers.filter(
    (labeler) =>
      labeler.users.length > 0 && labelIds.hasOwnProperty(labeler.labelId)
  );
};

export const selectFindingAgreement =
  (data: FindingLabeler) => (state: RootState) => {
    const selectedUsers = data.users.filter((u) => u.selected);
    if (!selectedUsers || selectedUsers.length !== 2) return -1;
    const user1 = selectedUsers[0].userId;
    const user2 = selectedUsers[1].userId;

    const agreement = state.imageWorkspace.imageLabeling.agreements.find(
      (a) => {
        if (a.labelId !== data.labelId) return false;
        return (
          (a.user1 === user1 && a.user2 === user2) ||
          (a.user1 === user2 && a.user2 === user1)
        );
      }
    );
    if (!agreement) return -1;
    return agreement.score;
  };

export const selectLabelAgreements = (state: RootState) => {
  const data: Record<string, FindingAgreement> = {};
  const selectedJobs =
    state.imageWorkspace.imageLabeling.previousJobObservations.filter(
      (j) => j.selected
    );
  if (!selectedJobs || selectedJobs.length !== 2) {
    return data;
  }
  const labelIds = state.imageWorkspace.imageLabeling.labels.map((b) => b.id);
  labelIds.forEach((lbId) => {
    const user1 = selectedJobs[0].assignee;
    const user2 = selectedJobs[1].assignee;
    const agreement = state.imageWorkspace.imageLabeling.agreements.find(
      (a) => {
        if (a.labelId !== lbId.toString()) return false;
        return (
          (a.user1 === user1 && a.user2 === user2) ||
          (a.user1 === user2 && a.user2 === user1)
        );
      }
    );
    if (agreement) {
      data[agreement.labelId] = agreement;
    } else {
      data[lbId.toString()] = {
        labelId: lbId.toString(),
        user1: user1,
        user2: user2,
        score: 0,
      };
    }
  });
  return data;
};

export const selectSelectedJobObservations = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.agreements;
};
export const selectObservationById =
  (labelId: string) => (state: RootState) => {
    return state.imageWorkspace.imageLabeling.labels.find(
      (b) => b.id.toString() === labelId
    );
  };

export const selectMaskedAnnotations = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.maskAnnotations;
};

export const selectIsReviewJob = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.job?.stepType === StepType.REVIEW;
};

export const selectIsAcceptanceJob = (state: RootState) => {
  return (
    state.imageWorkspace.imageLabeling.job?.stepType === StepType.ACCEPTANCE
  );
};

export const selectIsImageLabelingReadonly = (state: RootState) => {
  return state.imageWorkspace.imageLabeling.readonly;
};
