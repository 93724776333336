/*
 * File: users.slice.ts
 * Project: app-aiscaler-web
 * File Created: Sunday, 2nd January 2022 5:10:33 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserFilter } from "pages/customer/users/context/workspace-users.state";

import { INITIAL_USERS_STATE, UsersState } from "./users.state";
import { usersReducerBuilder } from "./users.thunk";

const SLICE_NAME: string = "users";
const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_USERS_STATE,
  reducers: {
    resetUsersState(state: UsersState) {
      Object.assign(state, INITIAL_USERS_STATE);
    },
    userFilterChanged(state, action: PayloadAction<UserFilter>) {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    usersReducerBuilder(builder);
  },
});

export const { resetUsersState, userFilterChanged } = slice.actions;

export const usersReducer = slice.reducer;
