/*
 * File: annotation.constant.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 4th August 2021 10:39:57 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";

export enum AnnotateType {
  SYSTEM = "SYSTEM",
  POLYGON = "POLYGON",
  BOUNDING_BOX = "BBOX",
  CLASSIFICATION = "CLASSIFICATION",
}

export enum ValueType {
  FREE_TEXT = "free_text",
  NONE = "none",
}

export const ANNOTATE_OPTIONS = [
  { label: "Polygon", value: AnnotateType.POLYGON },
  { label: "Bounding Box", value: AnnotateType.BOUNDING_BOX },
];

export const VALUE_OPTIONS = [
  {
    label: "None",
    value: ValueType.NONE,
  },
  {
    label: "Free text",
    value: ValueType.FREE_TEXT,
  },
];

export function annotateTypeMapper(type?: string) {
  if (type === AnnotateType.POLYGON) {
    return ToolName.FreehandRoiExtend;
  } else if (type === AnnotateType.BOUNDING_BOX) {
    return ToolName.RectangleRoiExtend;
  } else if (type === ToolName.MultiArrowConnection) {
    return ToolName.MultiArrowConnection;
  } else if (type === AnnotateType.CLASSIFICATION) {
    return null;
  }
  return null;
}

export enum AppEvents {
  ANNOTAIION_ADDED = "APP_ANNOTAIION_ADDED",
  ANNOTATION_REMOVED = "APP_ANNOTATION_REMOVED",
  ANNOTATION_UPDATED = "APP_ANNOTATION_UPDATED",
  ANNOTATION_COMPELETED = "APP_ANNOTATION_COMPELETED",
  ANNOTATION_TEXT_BOX_RIGHT_CLICKED = "APP_ANNOTATION_TEXT_BOX_RIGHT_CLICKED",
  SHORT_CUT_KEY_DOWN = "APP_SORT_CUT_KEY_DOWN",
  IMAGE_RENDERED_BY_FILE = "APP_IMAGE_RENDERED_BY_FILE",
  DATASET_FILES_UPLOADED = "DATASET_FILES_UPLOADED",
}
