/*
 * File: use-ai-models.hook.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 3rd November 2022 5:09:47 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */
import { AIModel } from "domain/customer/ai-model";
import { useAppSelector } from "hooks/use-redux";
import { useQuery } from "react-query";
import { mlServiceApis } from "services/ml-service/ml.service";
import { selectCurrentWorkspaceId } from "store/common/user-workspace/user-workspace.selectors";

export default function useAIModels(modelFilter = (model: AIModel) => true) {
  const workspace = useAppSelector(selectCurrentWorkspaceId);
  const requestOptions = {
    refetchOnWindowFocus: false,
  };
  const { data: aiModels, isLoading } = useQuery(
    "ai_models",
    () => mlServiceApis.getAIModels(workspace),
    requestOptions
  );
  return { aiModels: (aiModels ?? []).filter(modelFilter), isLoading };
}
