/*
 * File: system-label-list.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 22nd December 2021 3:57:21 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Radio } from "@material-ui/core";
import { Label } from "domain/text-labeling";

interface Props {
  labels: Label[];
  onSelect?(labelId: string): void;
  selectedLabelId: string;
  isReadOnly?: boolean;
}
export const SystemLabelList = ({
  labels,
  selectedLabelId,
  onSelect,
  isReadOnly,
}: Props) => {
  return (
    <div
      className={`flex flex-wrap items-center w-full gap-x-2 ${
        isReadOnly ? "pointer-events-none" : ""
      }`}
    >
      {labels.map((label) => {
        return (
          <button
            key={label.id}
            className="flex items-center px-1"
            onClick={() => onSelect && onSelect(label.id)}
          >
            <Radio checked={label.id === selectedLabelId} size="small" />
            <span>{label.name}</span>
          </button>
        );
      })}
    </div>
  );
};
