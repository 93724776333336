/*
 * File: image-review.state.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 26th January 2022 3:10:07 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { TaskObservationDTO } from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import { ImageLabelingJob } from "../batch-labeling/batch-labeling.state";
import { JobObservationModel } from "../image-labeling/image-labeling.state";

export interface ReopenReason {
  reason: string;
  reopenBy?: string;
  reopenAt?: Date;
}
export interface ImageReviewState {
  requestStatus: RequestStatus;
  jobData: ImageLabelingJob | null;
  jobObservations: TaskObservationDTO[];
  previousJobObservations: JobObservationModel[];
  reopenReason: ReopenReason | null;
}

export const INITIAL_IMAGE_REVIEW_STATE: ImageReviewState = {
  jobData: null,
  requestStatus: RequestStatus.IDLE,
  jobObservations: [],
  previousJobObservations: [],
  reopenReason: null,
};
