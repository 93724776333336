/*
 * File: text-labeling-editor.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 27th October 2021 3:03:50 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useKeyPress, useSize, useUnmount } from "ahooks";
import { CORAnnotation } from "domain/text-labeling";
import { Label, LabelType } from "domain/text-labeling";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import LeaderLine from "leader-line-new";
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  useLayoutEffect,
} from "react";
import {
  selectActiveRelationId,
  selectActiveTokenObservationId,
  selectSelectedTokenIds,
} from "store/labeler/text-workspace/text-editor/text-editor.selector";
import {
  selectTextIsReadonly,
  selectTextLabelingJob,
  selectTextLabelingMode,
  selectTextProjectType,
} from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { loadTextIssuesAsync } from "store/labeler/text-workspace/text-issues/thunks/load-text-issues.thunk";
import {
  observationDragging,
  relationAdded,
  relationEdited,
  relationHovered,
  relationRemoved,
  resetTextIssuesState,
  setActiveTokenObservationId,
  setSelectedTokenIds,
  textAnnotationLabelSelected,
  textObservationRemoved,
  textRelationNameDisplayTogged,
} from "store/labeler/text-workspace/text-workspace.slice";
import { classnames } from "utilities/classes";
import { v4 } from "uuid";
import { useTextWorkspaceContext } from "../../context/text-workspace/text-workspace.context";
import { useAnnotationIssueMenu } from "../../hooks/use-annotation-issue-menu.hook";
import { useAnnotationContextMenu } from "../../hooks/use-annotation-menu.hook";
import { useLabelMenu } from "../../hooks/use-label-menu.hook";
import { TextAnnotationMenu } from "../annotation-menu/annotation-menu.component";
import { LabelMenu } from "../text-editor/label-menu.component";
import { LabelToolTip } from "../text-editor/label-tooltip.component";
import { TextAnnotationIssueMenu } from "../text-issues/annotation-issue/annotation-issue.component";
import { TextViewer } from "./text-viewer.component";
import { useTextLabelingBatchContext } from "../../context/text-labeling-batch/text-labeling-batch.context";
import { textSelectJobAsync } from "store/labeler/text-labeling-batch/thunks/text-select-job.thunk";
import { TokenData } from "../../models/text-viewer.models";
import {
  updateConflictAnnotationAsync,
  UpdateConflictAnnotationPayload,
} from "store/labeler/text-workspace/text-conflict/text-conflict.thunk";
import { LabelingType } from "constants/labeling.constant";
import { selectTextLabelingTask } from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { textUtils } from "store/labeler/text-workspace/utils/text-labeling.utils";
import useTokenContextMenu from "../../hooks/use-token-menu.hook";
import { TextTokenMenu } from "../token-menu/token-menu.component";
import { useTokenIssueMenu } from "../../hooks/use-token-issue-menu.hook";
import { TokenIssueMenu } from "../text-issues/token-issue/token-issue.component";
interface DragConnectionState {
  from: string;
  to: string;
  isDragging: boolean;
  line: LeaderLine | null;
}

const DEFAULT_DRAG_CONNECTION_STATE: DragConnectionState = {
  from: "",
  to: "",
  isDragging: false,
  line: null,
};

interface Props {
  conflictTokenIds?: Record<string, string>;
  readonly?: boolean;
}
export const TextLabelingEditor = ({
  conflictTokenIds,
  readonly = false,
}: Props) => {
  const root = useRef({ x: 0, y: 0 });
  const dispatch = useAppDispatch();
  const activeTokenOvservationId = useAppSelector(
    selectActiveTokenObservationId
  );
  const selectedTokenIds = useAppSelector(selectSelectedTokenIds);
  const { tokenizer, labels, sentences, observations, connections } =
    useTextWorkspaceContext();
  const { saveWorkingJob, completeWorkingJob } = useTextLabelingBatchContext();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tokenContainer = useRef<HTMLDivElement>(null);
  const containerSize = useSize(containerRef);
  const labelingMode = useAppSelector(selectTextLabelingMode);
  const labelingJob = useAppSelector(selectTextLabelingJob);
  const labelingTask = useAppSelector(selectTextLabelingTask);
  const projectType = useAppSelector(selectTextProjectType);
  const { contextMenuState, showContextMenu, hideContextMenu } = useLabelMenu();
  const {
    annotationContextMenuState,
    showAnnotationContextMenu,
    hideAnnotationContextMenu,
    handleAnnotationContextMenuAction,
  } = useAnnotationContextMenu(containerRef);

  const {
    tokenContextMenuState,
    showTokenContextMenu,
    hideTokenContextMenu,
    handleTokenContextMenuAction,
  } = useTokenContextMenu(containerRef);

  const { annotationIssueMenuState, hideAnnotationIssueMenu } =
    useAnnotationIssueMenu(containerRef);

  const { tokenIssueMenuState, hideTokenIssueMenu } =
    useTokenIssueMenu(containerRef);

  const [activeRelationId, setActiveRelationId] = useState("");
  const activeRelation = useAppSelector(selectActiveRelationId);
  const isReadOnlyStep = useAppSelector(selectTextIsReadonly);
  const isReadOnly = isReadOnlyStep || readonly;
  const [dragConnectionState, setDragConnectionState] = useState(
    DEFAULT_DRAG_CONNECTION_STATE
  );
  const [dragEnable] = useState(!isReadOnly);

  const getElementBoundingBox = useCallback(
    (elementId: string) => {
      const element = document.getElementById(elementId);
      if (!element) return null;
      const scrollTop = containerRef.current?.scrollTop || 0;
      const x = parseInt(element.getAttribute("x") || "0");
      const y = parseInt(element.getAttribute("y") || "0") - scrollTop;
      const width = parseInt(element.getAttribute("textLength") || "0");
      const height = element.clientHeight;
      return { x, y, width, height };
    },
    [containerRef]
  );

  const handleContainerScroll = () => {
    if (selectedTokenIds.length > 0) dispatch(setSelectedTokenIds([]));
    if (activeTokenOvservationId) dispatch(setActiveTokenObservationId(""));
    if (annotationContextMenuState) hideAnnotationContextMenu();
    if (annotationIssueMenuState) hideAnnotationIssueMenu();
    if (tokenContextMenuState) hideTokenContextMenu();
    if (tokenIssueMenuState) hideTokenIssueMenu();
    window.getSelection()?.empty();
    window.getSelection()?.removeAllRanges();
    // hideAnnotationIssueMenu();
  };

  const handleSelectLabel = (label: Label) => {
    if (activeRelationId) {
      const payload = {
        id: activeRelationId,
        text: label.name,
        observationId: label.id,
        color: label.color,
      };
      dispatch(relationEdited(payload));
      hideContextMenu();
      setActiveRelationId("");
      return;
    }
    return handleSelectLabelId(label.id);
  };

  const handleSelectLabelId = (labelId: string) => {
    if (isReadOnly) return;
    if (selectedTokenIds.length === 0) return;
    dispatch(textAnnotationLabelSelected(labelId));
    dispatch(setSelectedTokenIds([]));
  };

  function handleSelectRelation(relationId: string) {
    if (isReadOnly) return;
    const element = document.getElementById(relationId + "-text");
    if (!element) return;
    const bbox = element.getBoundingClientRect();
    const position = {
      x: bbox.x - root.current.x + bbox.width / 2,
      y: bbox.y - root.current.y + bbox.height,
    };
    setActiveRelationId(relationId);
    showContextMenu(position, LabelType.CONNECTION);
  }

  function handleSelectToken(token: TokenData) {
    if (isReadOnly) return;
    dispatch(setSelectedTokenIds([token.model.id]));
  }

  function handleMouseEnterRelation(relationId: string) {
    dispatch(relationHovered(relationId));
  }

  function handleMouseLeaveRelation(relationId: string) {
    if (activeRelation === relationId) {
      dispatch(relationHovered(""));
    }
  }

  function handleClick(event: MouseEvent<HTMLDivElement>) {
    hideAnnotationContextMenu();
    hideAnnotationIssueMenu();
    hideTokenContextMenu();
    hideTokenIssueMenu();
    if (isReadOnly) return;
    const element = event.target;
    const elementId = (element as any).id;
    if (elementId) return;
    if (dragConnectionState.line) {
      dragConnectionState.line.remove();
      dragConnectionState.line = null;
    }
    setDragConnectionState({ ...DEFAULT_DRAG_CONNECTION_STATE });
    setActiveRelationId("");
  }

  function handleMouseDown(event: MouseEvent<HTMLDivElement>) {
    const element = event.target;
    const elementId: string = (element as any).id;
    if (!elementId || !dragEnable || !activeTokenOvservationId) {
      setActiveRelationId("");
      return;
    }
    if (!(element as HTMLElement).hasAttribute("data-observation-id")) {
      return;
    }
    if (!containerRef.current) return;
    if (!/\d+:\d+:\d+/gm.test(elementId)) return;
    const boundingbox = containerRef.current.getBoundingClientRect();
    root.current.x = boundingbox.x;
    root.current.y = boundingbox.y;

    const scrollTop = containerRef.current?.scrollTop || 0;
    const position = {
      x: event.clientX - root.current.x,
      y: event.clientY - root.current.y + scrollTop,
    };

    const start = document.getElementById(elementId);
    const end = document.getElementById("drag-cursor-end");
    if (!start || !end) return;
    end.style.left = `${position.x - 1}px`;
    end.style.top = `${position.y - 1}px`;

    const dragLine = new LeaderLine(start, end, {
      size: 1,
      color: "#FF00FF",
      path: "arc",
    });

    setDragConnectionState({
      from: elementId,
      to: "",
      line: dragLine,
      isDragging: true,
    });

    dispatch(observationDragging(elementId));
  }

  function handleMouseMove(event: MouseEvent<HTMLDivElement>) {
    if (isReadOnly) return;
    if (!dragConnectionState.isDragging || !dragConnectionState.line) return;
    const scrollTop = containerRef.current?.scrollTop || 0;
    const position = {
      x: event.clientX - root.current.x,
      y: event.clientY - root.current.y + scrollTop,
    };

    const end = document.getElementById("drag-cursor-end");
    if (end) {
      end.style.left = `${position.x}px`;
      end.style.top = `${position.y}px`;
      dragConnectionState.line.position();
    }
  }

  function handleMouseUp(event: MouseEvent<HTMLDivElement>) {
    if (isReadOnly) return;
    const isDragging = dragConnectionState.isDragging;
    if (isDragging) {
      const element = event.target;
      const elementId = (element as any).id;
      if (
        elementId &&
        /\d+:\d+:\d+/gm.test(elementId) &&
        elementId !== dragConnectionState.from &&
        (element as HTMLElement).hasAttribute("data-observation-id")
      ) {
        // TODO: change later for new observation api
        // const labelId = parseInt(elementId.split(":")[0]);
        // const observation = observationEntities[labelId];
        // if (observation && observation.observationSetting.noConnection) return;
        // const fromLabelId = parseInt(dragConnectionState.from.split(":")[0]);
        // if (fromLabelId === labelId) return;
        const connection: CORAnnotation = {
          from: dragConnectionState.from,
          to: elementId,
          text: projectType === LabelingType.TEXT_COR ? "" : "Select relation",
          id: v4(),
          observationId: "",
          color: "#8b91A3",
        };
        setActiveRelationId(connection.id);
        dispatch(relationAdded(connection));
        setTimeout(() => handleSelectRelation(connection.id), 100);
      }
    }

    if (dragConnectionState.line) {
      dragConnectionState.line.remove();
      dragConnectionState.line = null;
    }
    setDragConnectionState({ ...DEFAULT_DRAG_CONNECTION_STATE });
    if (isDragging) dispatch(observationDragging(""));
  }

  function handleContextMenu(event: MouseEvent) {
    const target = event.target as Element;
    const id = target.id;
    if (textUtils.isTokenId(id)) {
      showTokenContextMenu(id);
    } else if (textUtils.isAnnotationId(id) || textUtils.isRelationId(id)) {
      showAnnotationContextMenu(id);
    } else {
      hideAnnotationContextMenu();
    }
  }

  function hideTooltip() {
    if (activeTokenOvservationId || activeRelation) {
      dispatch(setActiveTokenObservationId(""));
      dispatch(relationHovered(""));
    }
  }

  function deleteActiveAnnotation() {
    if (isReadOnly) return;
    if (activeTokenOvservationId) {
      dispatch(textObservationRemoved(activeTokenOvservationId));
    }
    if (activeRelation) {
      dispatch(relationRemoved(activeRelation));
    }

    hideContextMenu();
    hideTooltip();
  }

  function textSelectNextJob() {
    dispatch(textSelectJobAsync({ offset: 1 }));
  }

  function textSelectPreviousJob() {
    dispatch(textSelectJobAsync({ offset: -1 }));
  }

  function toggleRelationName() {
    dispatch(textRelationNameDisplayTogged());
  }

  function acceptCurrentAnnotation() {
    const annotationId = activeRelation || activeTokenOvservationId;
    if (!annotationId?.startsWith("review-")) return;
    const payload: UpdateConflictAnnotationPayload = {
      annotationId,
      reviewStatus: "accepted",
    };
    dispatch(updateConflictAnnotationAsync(payload));
  }

  function rejectCurrentAnnotation() {
    const annotationId = activeRelation || activeTokenOvservationId;
    if (!annotationId?.startsWith("review-")) return;
    const payload: UpdateConflictAnnotationPayload = {
      annotationId,
      reviewStatus: "rejected",
    };
    dispatch(updateConflictAnnotationAsync(payload));
  }

  useKeyPress("delete", deleteActiveAnnotation);
  useKeyPress(["ctrl.s"], saveWorkingJob);
  useKeyPress(["ctrl.shift.space", "meta.shift.space"], completeWorkingJob);
  useKeyPress("shift.ArrowUp", textSelectPreviousJob);
  useKeyPress("shift.ArrowDown", textSelectNextJob);
  useKeyPress("N", toggleRelationName);
  useKeyPress("A", acceptCurrentAnnotation);
  useKeyPress("R", rejectCurrentAnnotation);

  useEffect(() => {
    if (selectedTokenIds.length === 0) return hideContextMenu();
    const selectedTokenId = selectedTokenIds[selectedTokenIds.length - 1];
    const bbox = getElementBoundingBox(selectedTokenId);
    if (!bbox) return;
    const position = { x: bbox.x + bbox.width - 10, y: bbox.y + 20 };
    showContextMenu(position);
  }, [
    selectedTokenIds,
    containerRef,
    hideContextMenu,
    showContextMenu,
    getElementBoundingBox,
  ]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const boundingbox = containerRef.current.getBoundingClientRect();
      root.current.x = boundingbox.x;
      root.current.y = boundingbox.y;
    }
  }, [containerRef]);

  useEffect(() => {
    if (labelingJob || labelingTask) {
      dispatch(loadTextIssuesAsync());
    } else {
      dispatch(resetTextIssuesState());
    }
  }, [dispatch, labelingJob, labelingTask]);

  useUnmount(() => {
    dragConnectionState?.line?.remove();
  });

  return (
    <div className="h-full">
      <div
        className={classnames(
          "flex flex-col h-full bg-white rounded relative overflow-hidden"
        )}
      >
        <div
          ref={containerRef}
          className={`relative w-full flex-auto overflow-y-auto`}
          style={{ scrollPaddingTop: "160px" }}
          onClick={handleClick}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onScroll={handleContainerScroll}
        >
          {dragEnable && (
            <div
              className="absolute w-1 h-1 top-80 right-6"
              id="drag-cursor-end"
              style={{ zIndex: -1 }}
            />
          )}
          <div
            className={classnames(
              "relative flex flex-wrap items-end leading-loose gap-x-2 gap-y-8",
              {
                "select-none": dragConnectionState.isDragging,
              }
            )}
            ref={tokenContainer}
            onContextMenu={handleContextMenu}
          >
            <TextViewer
              tokenizer={tokenizer}
              readonly={isReadOnly}
              labels={labels}
              sentences={sentences}
              observations={observations}
              connections={connections}
              conflictTokenIds={conflictTokenIds}
              onSelectToken={handleSelectToken}
              onSelectRelation={handleSelectRelation}
              onMouseEnterRelation={handleMouseEnterRelation}
              onMouseLeaveRelation={handleMouseLeaveRelation}
            />
          </div>
        </div>
        {contextMenuState && (
          <LabelMenu
            position={contextMenuState.position}
            labels={labels}
            type={contextMenuState.type}
            containerSize={containerSize}
            onClose={hideContextMenu}
            onSelect={handleSelectLabel}
          />
        )}

        {annotationContextMenuState && (
          <TextAnnotationMenu
            labelingMode={labelingMode}
            position={annotationContextMenuState.position}
            annotation={annotationContextMenuState.annotation}
            onSelect={handleAnnotationContextMenuAction}
            onClose={hideAnnotationContextMenu}
          />
        )}

        {annotationIssueMenuState && (
          <TextAnnotationIssueMenu
            labelingMode={labelingMode}
            issue={annotationIssueMenuState.issue}
            position={annotationIssueMenuState.position}
            annotation={annotationIssueMenuState.annotation}
            onClose={hideAnnotationIssueMenu}
          />
        )}

        {tokenContextMenuState && (
          <TextTokenMenu
            labelingMode={labelingMode}
            position={tokenContextMenuState.position}
            onSelect={handleTokenContextMenuAction}
            onClose={hideTokenContextMenu}
          />
        )}

        {tokenIssueMenuState && (
          <TokenIssueMenu
            tokenId={tokenIssueMenuState.tokenId}
            labelingMode={labelingMode}
            issue={tokenIssueMenuState.issue}
            position={tokenIssueMenuState.position}
            onClose={hideTokenIssueMenu}
          />
        )}
      </div>
      {(activeTokenOvservationId || activeRelation) && (
        <LabelToolTip
          labels={labels}
          annotationId={activeTokenOvservationId || activeRelation}
          onClose={hideTooltip}
        />
      )}
    </div>
  );
};
