/*
 * File: upload-button.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";

import { IconDocumentUpload } from "components/common/vb-icon.component";
import { useFileUploadContext } from "contexts/file-upload/file-upload.context";

export const UploadFilesButton = () => {
  const { t } = useTranslation();
  const { onClickUpload } = useFileUploadContext();

  return (
    <div>
      <button 
        onClick={onClickUpload}
        className="button-secondary"
      >
        <span className="hidden xl:block">{t("common:buttonUpload")}</span>
        <IconDocumentUpload className="w-5 h-5" />
      </button>
    </div>
  );
};
