/*
 * File: text-labeling-sidebar.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 15th October 2021 11:18:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useMemo, useRef } from "react";
import {
  selectSelectedObservation,
  selectSelectedSystemObservationId,
  selectShowLabelInstruction,
} from "store/labeler/text-workspace/text-editor/text-editor.selector";
import {
  selectTextIsReadonly,
  selectTextIsStepReviewJob,
  selectTextLabels,
} from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import {
  setSelectedSystemObservationId,
  setShowLabelInstruction,
} from "store/labeler/text-workspace/text-workspace.slice";
import { SystemLabelList } from "./components/system-label-list.component";
import { STEP_REVIEW_OBSERVATION_CODES } from "services/label-service/dtos";
import { selectIsShowTextIssues } from "store/labeler/text-workspace/text-issues/text-issues.selector";
import { TextIssuesComponent } from "../text-issues/text-issues.component";
import { LabelInformation } from "pages/labeler/components/label-info/label-info.component";
import { useTextWorkspaceContext } from "../../context/text-workspace/text-workspace.context";
import { TextPreviousAnnotations } from "./text-previous-annotations.component";

export const AcceptanceSidebar = () => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const { isLoading, isLoadingJob } = useTextWorkspaceContext();
  const isStepReviewJob = useAppSelector(selectTextIsStepReviewJob);
  const isReadOnly = useAppSelector(selectTextIsReadonly);
  const selectedSystemObservationId = useAppSelector(
    selectSelectedSystemObservationId
  );
  const showLabelInstruction = useAppSelector(selectShowLabelInstruction);
  const showTextIssues = useAppSelector(selectIsShowTextIssues);
  const selectedObservation = useAppSelector(selectSelectedObservation);
  const handleSelectSystemLabel = (labelId: string) => {
    const observationId = parseInt(labelId);
    if (selectedSystemObservationId === observationId) {
      dispatch(setSelectedSystemObservationId(-1));
    } else {
      dispatch(setSelectedSystemObservationId(observationId));
    }
  };
  const labels = useAppSelector(selectTextLabels);
  const systemLabels = useMemo(() => {
    return labels.filter((lb) => {
      if (!lb.isSystemLabel) return false;
      if (isStepReviewJob) {
        return !!STEP_REVIEW_OBSERVATION_CODES.includes(lb.observation.code);
      } else {
        return !!!STEP_REVIEW_OBSERVATION_CODES.includes(lb.observation.code);
      }
    });
  }, [labels, isStepReviewJob]);

  const onCloseLabelInformation = () => {
    dispatch(setShowLabelInstruction(false));
  };

  useEffect(() => {
    if (!showLabelInstruction && containerRef.current) {
      containerRef.current.style.width = "auto";
    }
  }, [containerRef, showLabelInstruction]);

  if (isLoading || isLoadingJob) return <TextLabelingSidebarSkeleton />;

  return (
    <div className="relative flex flex-shrink-0 h-full" ref={containerRef}>
      <div className="relative flex-1 h-full py-4 overflow-y-auto bg-white">
        {!isReadOnly && (
          <>
            <div className="px-4 py-2 text-sm font-bold uppercase">Actions</div>
            <SystemLabelList
              isReadOnly={isReadOnly}
              labels={systemLabels}
              selectedLabelId={selectedSystemObservationId.toString()}
              onSelect={handleSelectSystemLabel}
            />
            <div className="mt-4 border-b" />
          </>
        )}

        <div className="w-full">
          <TextPreviousAnnotations />
        </div>
      </div>

      {showLabelInstruction && (
        <LabelInformation
          label={selectedObservation}
          onClose={onCloseLabelInformation}
        />
      )}

      {showTextIssues && <TextIssuesComponent />}
    </div>
  );
};

function TextLabelingSidebarSkeleton() {
  return (
    <div className="relative flex flex-shrink-0 h-full w-60">
      <div className="relative flex-1 h-full py-4 overflow-y-auto bg-white">
        <div className="px-4 py-4 space-y-6">
          <div className="w-3/6 h-4 bg-background-300 animate-pulse"></div>
          <div className="flex items-center gap-4">
            <div className="w-12 h-3 bg-background-300 animate-pulse"></div>
            <div className="w-12 h-3 bg-background-300 animate-pulse"></div>
            <div className="w-12 h-3 bg-background-300 animate-pulse"></div>
          </div>
          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <div className="w-5 h-5 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
            <div className="px-4 space-y-4">
              <div className="flex items-center gap-3">
                <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
                <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
              </div>
              <div className="flex items-center gap-3">
                <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
                <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
              </div>
              <div className="flex items-center gap-3">
                <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
                <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
              </div>
              <div className="flex items-center gap-3">
                <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
                <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="p-4 space-y-4">
          <div className="flex items-center gap-3">
            <div className="w-5 h-5 rounded bg-background-300 animate-pulse"></div>
            <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
          </div>
          <div className="px-4 space-y-4">
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
          </div>
        </div>
        <hr />
        <div className="p-4 space-y-4">
          <div className="flex items-center gap-3">
            <div className="w-5 h-5 rounded bg-background-300 animate-pulse"></div>
            <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
          </div>
          <div className="px-4 space-y-4">
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
