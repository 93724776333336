/*
 * File: use-text-job-options.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 6th June 2023 9:13:04 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Option } from "domain/common";
import { useAppSelector } from "hooks/use-redux";
import { useMemo, useState } from "react";
import { selectTextLabelingJobs } from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";

export function useTextJobOptions() {
  const jobs = useAppSelector(selectTextLabelingJobs);
  const options = useMemo<Option[]>(() => {
    return jobs.map((job) => ({
      label: job.assignee,
      value: job.id.toString(),
      metadata: job,
    }));
  }, [jobs]);
  const [value, setValue] = useState(() => {
    if (options.length > 0) return options[0].value;
    return "";
  });

  const option = useMemo(() => {
    return options.find((op) => op.value === value);
  }, [options, value]);

  function onChange(option: any) {
    setValue(option.value);
  }

  return { options, option, value, onChange };
}

export default useTextJobOptions;
