/*
 * File: dataset-drawer.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from 'react-i18next';
import { Drawer } from "@material-ui/core";
import { useState } from "react";
import { DatasetDTO } from "services/label-service/dtos";
import { VBButton } from "../../../../../components/common/vb-button.component";
import { classnames } from "../../../../../utilities/classes";
import { DatasetActivity } from "./dataset-activity.component";
import { DatasetInfo } from "./dataset-info.component";
interface Props {
  dataset: DatasetDTO | null;
  open: boolean;
  handleClose?: () => void;
}
export const DatasetDrawer = ({ open, dataset, handleClose }: Props) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<"detail" | "activity">("detail");
  return (
    <Drawer open={open} anchor="right" onClose={handleClose}>
      <div className="py-4 w-96">
        <div className="flex items-center">
          <div className="flex-1 px-4 text-2xl font-bold">{dataset?.name}</div>
          <VBButton className="mx-4" onClick={handleClose}>
            <i className="uir-cross-small"></i>
          </VBButton>
        </div>
        <div className="grid grid-cols-2 gap-4 mx-4 mt-4">
          <div
            onClick={() => setMode("detail")}
            className={classnames(
              "cursor-pointer text-center py-2 border-b-2",
              {
                "text-primary border-primary": mode === "detail",
                "text-black  border-none": mode !== "detail",
              }
            )}
          >
            {t('dataset:drawer.detailsTitle')}
          </div>
          <div
            onClick={() => setMode("activity")}
            className={classnames(
              "cursor-pointer text-center py-2 border-b-2",
              {
                "text-primary border-primary": mode === "activity",
                "text-black  border-none": mode !== "activity",
              }
            )}
          >
            {t('dataset:drawer.activityTitle')}
          </div>
        </div>
        <hr />
        {mode === "detail" && dataset && <DatasetInfo dataset={dataset} />}
        {mode === "activity" && dataset && (
          <DatasetActivity dataset={dataset} />
        )}
      </div>
    </Drawer>
  );
};
