/*
 * File: use-label-menu.hook.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 25th December 2021 11:04:53 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { LabelType } from "domain/text-labeling";
import { useCallback, useState } from "react";
import { Point } from "utilities/math/point";

interface ContextMenuState {
  position: Point;
  type: LabelType;
}
export const useLabelMenu = () => {
  const [
    contextMenuState,
    setContextMenuState,
  ] = useState<ContextMenuState | null>(null);

  const hideContextMenu = useCallback(() => {
    setContextMenuState(null);
  }, []);

  const showContextMenu = useCallback(
    (position: Point, type = LabelType.OBJECT) => {
      setContextMenuState({
        position: position,
        type: type,
      });
    },
    []
  );

  return { contextMenuState, hideContextMenu, showContextMenu };
};
