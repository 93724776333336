import { Tooltip } from "@material-ui/core";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { Fragment, useEffect, useRef, useState } from "react";
import { shouldIgnoreEventKeyup } from "../vtk/utils";
import {
  EditorEventForceWindowLeave,
  EDITOR_DEFAULT_LAYOUT_ORDERS,
  EDITOR_LAYOUT_1,
  EDITOR_LAYOUT_4,
  ThreeDEditorEvents,
} from "./three-d-editor.models";
import { useThreeDEditorContext } from "./three-d-editor.provider";

interface Props {
  id: number;
}
export const ThreeDEditorWindowSelectComponent = ({ id }: Props) => {
  const {
    currentLayout,
    windowsDataMap,
    setWindowsDataMap,
    swapWindow,
    setCurrentLayout,
    activeWindow,
    setActiveWindow,
  } = useThreeDEditorContext();

  const [isProcessing, setIsProcessing] = useState(false);

  const handleClickOneWindowLayout = () => {
    setIsProcessing(true);
    for (const key of Object.keys(windowsDataMap)) {
      const windowData = windowsDataMap[parseInt(key)];
      // Find the window which has order = 1 and swap with the current window
      // no need to sent swap event because this window is always activated
      if (windowData.order === 1 && windowData.id !== id) {
        swapWindow(windowsDataMap[id], windowData, false);
      }
    }
    setCurrentLayout(EDITOR_LAYOUT_1);
    setIsProcessing(false);
  };

  const handleClickFourWindowsLayout = () => {
    setIsProcessing(true);

    // Reset to default windows order
    for (const key of Object.keys(windowsDataMap)) {
      const windowData = windowsDataMap[parseInt(key)];
      windowData.order = EDITOR_DEFAULT_LAYOUT_ORDERS[windowData.id];
    }

    // Force the current active window to leave
    const leaveEvent: EditorEventForceWindowLeave = {
      windowToLeave: windowsDataMap[id],
    };
    document.dispatchEvent(
      new CustomEvent(ThreeDEditorEvents.COMMAND_FORCE_WINDOW_LEAVE, {
        detail: leaveEvent,
      })
    );

    // Send self check active event to windows
    document.dispatchEvent(
      new CustomEvent(ThreeDEditorEvents.COMMAND_CHECK_SELF_ACTIVE_WINDOW, {})
    );

    setWindowsDataMap({ ...windowsDataMap });
    setCurrentLayout(EDITOR_LAYOUT_4);

    setIsProcessing(false);
  };

  const idRef = useRef(id);
  const activeWindowRef = useRef(activeWindow);
  const currentLayoutRef = useRef(currentLayout);
  const buttonClickOneRef = useRef<HTMLButtonElement>(null);
  const buttonClickFourRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    idRef.current = id;
  }, [id]);

  useEffect(() => {
    activeWindowRef.current = activeWindow;
  }, [activeWindow]);

  useEffect(() => {
    currentLayoutRef.current = currentLayout;
  }, [currentLayout]);

  // Key bind for labels
  useEffect(() => {
    const handleKeyUp = (e: any) => {
      if (shouldIgnoreEventKeyup(e)) return;
      if (idRef.current !== activeWindowRef.current) return;

      const key = e.key;
      if (key.toLowerCase() === "f") {
        if (currentLayoutRef.current.id === EDITOR_LAYOUT_1.id) {
          buttonClickFourRef.current?.click();
          setActiveWindow(idRef.current);
        } else {
          buttonClickOneRef.current?.click();
        }
      }
    };

    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [setActiveWindow]);

  return (
    <Fragment>
      <div
        className="absolute right-1 top-1 opacity-60"
        ref={windowsDataMap[id].otherWindowOptionsRef}
      >
        <VBSelectComponent
          containerClassName="text-sm bg-black"
          value={{ label: windowsDataMap[id].name, value: windowsDataMap[id] }}
          options={windowsDataMap[id].otherWindowOptions}
          menuPortalTarget={document.body}
          onChange={(option: any) =>
            swapWindow(windowsDataMap[id], option.value)
          }
          menuPlacement={"bottom"}
          loseFocusAfterSelected
          backgroundColor="#1e1f20"
          textColor="white"
        />
      </div>

      <div className="absolute bottom-1 left-1 opacity-60 text-sm text-white bg-black rounded">
        {currentLayout.id !== EDITOR_LAYOUT_1.id && (
          <Tooltip title="One window (F)" placement="top">
            <button
              ref={buttonClickOneRef}
              className="px-2 py-1"
              disabled={isProcessing}
              onClick={handleClickOneWindowLayout}
            >
              1
            </button>
          </Tooltip>
        )}
        {currentLayout.id === EDITOR_LAYOUT_1.id && (
          <Tooltip title="Four windows (F)" placement="top">
            <button
              ref={buttonClickFourRef}
              className="px-2 py-1"
              disabled={isProcessing}
              onClick={handleClickFourWindowsLayout}
            >
              4
            </button>
          </Tooltip>
        )}
      </div>
    </Fragment>
  );
};
