import { Tooltip } from "@material-ui/core";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { shouldIgnoreEventKeyup } from "../vtk/utils";
import {
  DEFAULT_CL,
  DEFAULT_WL,
  WINDOW_PRESETS,
} from "./three-d-editor.models";
import { useThreeDEditorContext } from "./three-d-editor.provider";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";

const MIN_COLOR_LEVEL = -1000;
const MAX_COLOR_LEVEL = 1000;
const MIN_WINDOW_LEVEL = 0;
const MAX_WINDOW_LEVEL = 2000;

export const ThreeDEditorColorsComponent = () => {
  const {
    editorContext,
    sliceWindowLevel,
    sliceColorLevel,
    setSliceColorLevel,
    setSliceWindowLevel,
  } = useThreeDEditorContext();
  const colorLevelInputRef = useRef<HTMLInputElement>(null);
  const windowLevelInputRef = useRef<HTMLInputElement>(null);

  const [selectedWindowPreset, setSelectedWindowPreset] =
    useState<any>(undefined);
  const windowPresetOptions = WINDOW_PRESETS.map((preset, index) => ({
    label: `${preset.label} (Ctrl + ${index + 1})`,
    value: preset,
  }));

  const handleSliceColorChanged = (v: number, type: string) => {
    if (!editorContext) return;
    const { windowsSliceArray } = editorContext;
    if (type === "window") {
      setSliceWindowLevel(v);
    } else {
      setSliceColorLevel(v);
    }
    for (const sliceData of windowsSliceArray) {
      const property = sliceData.imageSlice.image.actor.getProperty();
      if (type === "window") {
        property.setColorWindow(v);
      } else {
        property.setColorLevel(v);
      }
      sliceData.windowSlice.renderWindow.render();
    }
  };

  const handleColorLevelBlur = () => {
    if (!colorLevelInputRef.current) return;
    try {
      const value = parseFloat(colorLevelInputRef.current.value);
      const trimValue = Math.max(
        Math.min(value, MAX_COLOR_LEVEL),
        MIN_COLOR_LEVEL
      );
      handleSliceColorChanged(trimValue, "color");
    } catch (error) {}
  };

  const handleWindowLevelBlur = () => {
    if (!windowLevelInputRef.current) return;
    try {
      const value = parseFloat(windowLevelInputRef.current.value);
      const trimValue = Math.max(
        Math.min(value, MAX_WINDOW_LEVEL),
        MIN_WINDOW_LEVEL
      );
      handleSliceColorChanged(trimValue, "window");
    } catch (error) {}
  };

  const handleWindowPresetChanged = useCallback(
    (option: any) => {
      if (!editorContext) return;
      let wl = DEFAULT_WL;
      let cl = DEFAULT_CL;
      if (option) {
        wl = option.value.windowWidth;
        cl = option.value.windowLevel;
        setSelectedWindowPreset(option.value);
      } else {
        setSelectedWindowPreset(undefined);
      }
      const { windowsSliceArray } = editorContext;
      for (const sliceData of windowsSliceArray) {
        const property = sliceData.imageSlice.image.actor.getProperty();
        property.setColorWindow(wl);
        property.setColorLevel(cl);
        sliceData.windowSlice.renderWindow.render();
        setSliceWindowLevel(wl);
        setSliceColorLevel(cl);
      }
    },
    [editorContext, setSliceWindowLevel, setSliceColorLevel]
  );

  const windowPresetOptionsRef = useRef(windowPresetOptions);

  useEffect(() => {
    windowPresetOptionsRef.current = windowPresetOptions;
  }, [windowPresetOptions]);

  useEffect(() => {
    const handleKeyup = (e: any) => {
      if (shouldIgnoreEventKeyup(e)) return;
      if (!e.ctrlKey) return;
      const key = e.key;
      let index = 1;
      for (const option of windowPresetOptionsRef.current) {
        if (index.toString() === key) {
          handleWindowPresetChanged(option);
          break;
        }
        index++;
      }
    };
    window.addEventListener("keyup", handleKeyup);

    return () => {
      window.removeEventListener("keyup", handleKeyup);
    };
  }, [handleWindowPresetChanged]);

  useEffect(() => {
    if (colorLevelInputRef.current) {
      colorLevelInputRef.current.value = sliceColorLevel.toFixed(1);
    }
  }, [sliceColorLevel, colorLevelInputRef]);

  useEffect(() => {
    if (windowLevelInputRef.current) {
      windowLevelInputRef.current.value = sliceWindowLevel.toFixed(1);
    }
  }, [sliceWindowLevel, windowLevelInputRef]);

  return (
    <div className="flex flex-col gap-2">
      <p className="font-bold">Colors</p>
      <VBSelectComponent
        className="text-black"
        options={windowPresetOptions}
        value={windowPresetOptions.find(
          (p) => p.value === selectedWindowPreset
        )}
        isClearable
        placeholder="Preset"
        onChange={handleWindowPresetChanged}
        menuPortalTarget={document.body}
        loseFocusAfterSelected
      />
      <div className="flex items-center justify-between">
        <Tooltip title="Window level" placement="top">
          <Fragment>
            <span>WL:</span>
            <input
              step={0.5}
              min={MIN_WINDOW_LEVEL}
              max={MAX_WINDOW_LEVEL}
              ref={windowLevelInputRef}
              onBlur={handleWindowLevelBlur}
              className="w-16 mx-1 flex-none text-white bg-gray-900 hover:outline-blue px-1"
              type="number"
              onKeyDown={(event) => {
                console.log(event.key);
                if (event.key === KeyboardKey.Enter) {
                  handleWindowLevelBlur();
                }
              }}
            />
          </Fragment>
        </Tooltip>
        <input
          type="range"
          value={sliceWindowLevel.toFixed(1)}
          step={0.5}
          min={MIN_WINDOW_LEVEL}
          max={MAX_WINDOW_LEVEL}
          onChange={(e) =>
            handleSliceColorChanged(parseFloat(e.target.value), "window")
          }
        />
      </div>
      <div className="flex items-center justify-between">
        <Tooltip title="Color level" placement="top">
          <Fragment>
            <span>CL:</span>
            <input
              step={0.5}
              min={MIN_COLOR_LEVEL}
              max={MAX_COLOR_LEVEL}
              ref={colorLevelInputRef}
              onBlur={handleColorLevelBlur}
              onKeyDown={(event) => {
                console.log(event.key);
                if (event.key === KeyboardKey.Enter) {
                  handleColorLevelBlur();
                }
              }}
              className="w-16 mx-1 flex-none text-white bg-gray-900 hover:outline-blue px-1"
              type="number"
              // value={sliceColorLevel.toFixed(1)}
            />
          </Fragment>
        </Tooltip>
        <input
          type="range"
          value={sliceColorLevel.toFixed(1)}
          step={0.5}
          min={MIN_COLOR_LEVEL}
          max={MAX_COLOR_LEVEL}
          onChange={(e) =>
            handleSliceColorChanged(parseFloat(e.target.value), "level")
          }
        />
      </div>
    </div>
  );
};
