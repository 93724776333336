/*
 * File: batch.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 18th August 2021 5:38:01 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { StorageKeys } from "hooks/storage/storage-keys";
import { HTTPStatusCode } from "services/common/http-status";
import { RequestOptions } from "services/common/request-options";
import { BatchService, JobService, TaskService } from "services/label-service";
import { CreateBatchPayload } from "services/label-service/apis/batch.api";
import {
  CreateTaskPayload,
  ReopenTasksRequest,
} from "services/label-service/apis/task.api";
import {
  isImageProject,
  TaskCustomerReviewStatus,
  TaskDTO,
  TaskStatus,
} from "services/label-service/dtos";
import { QueryService } from "services/query/query.service";
import { StorageService } from "services/storage";
import { getThumbnailUrlFromFileInfoStorageService } from "services/storage/apis/get-additional-file-info.api";
import { RootState } from "store";
import { RequestStatus } from "store/base/base.state";
import { BatchState } from "./batch.state";
import { selectIsUseElasticSearch } from "store/common/app-setting/app-setting.selectors";
import * as Sentry from "@sentry/react";
import { updateBatch } from "../project/project.slice";

const SLICE_NAME: string = "batch";

export enum BatchThunk {
  LOAD_BATCHES = "batch/loadBatchsAsync",
  LOAD_USERS = "batch/loadUsersAsync",
  LOAD_BATCH_MANAGEMENT = "batch/loadBatchManagementAsync",
  UPDATE_BATCH_MANAGEMENT = "batch/updateBatchManagementAsync",
  CREATE_WORKLOW = "batch/createBatchAsync",
  UPDATE_BATCH = "batch/updateBatchAsync",
  DELETE_BATCH = "batch/deleteBatchAsync",
  LOAD_GEN_ANNO_ASYNC_JOB_STATUS = "batch/loadBatchGenAnnoAsyncJobStatusAsync",
}

export const loadBatchDataAsync = createAsyncThunk(
  BatchThunk.LOAD_BATCH_MANAGEMENT,
  async (batchId: string) => {
    const response = await BatchService.getItem(parseInt(batchId));
    return response.data;
  }
);

export interface UpdateBatchPayload {
  batchId: number;
  payload: Partial<CreateBatchPayload>;
  updateState?: boolean;
}

export const updateBatchManagementAsync = createAsyncThunk(
  BatchThunk.UPDATE_BATCH_MANAGEMENT,
  async ({ batchId, payload, updateState = true }: UpdateBatchPayload) => {
    const response = await BatchService.updateBatchManagement(batchId, payload);
    return {
      updateState,
      data: response.data,
    };
  }
);

export const updateBatchAsync = createAsyncThunk(
  BatchThunk.UPDATE_BATCH,
  async (
    payload: {
      id: number;
      name: string;
      description: string;
      instruction: string;
    },
    { dispatch }
  ) => {
    const params = {
      name: payload.name,
      description: payload.description,
      instruction: payload.instruction,
    };
    const response = await BatchService.updateBatch(payload.id, params);
    const batchData = response.data;
    dispatch(updateBatch(batchData));
    return response.data;
  }
);

export const loadBatchGenAnnoAsyncJobStatusAsync = createAsyncThunk(
  BatchThunk.LOAD_GEN_ANNO_ASYNC_JOB_STATUS,
  async (batchId: string | number) => {
    const storageKey = `${StorageKeys.DISMISS_GEN_ANNO_BATCH_PREFIX}${batchId}`;
    if (localStorage.getItem(storageKey)) {
      return {
        status: undefined,
        percentComplete: 0,
      };
    }
    // const response = await AnnotationService.getGenerateAnnotationJobStatus(
    //   batchId
    // );
    return {
      status: undefined,
      percentComplete: 100,
    };
  }
);

export const createTaskAsync = createAsyncThunk(
  `${SLICE_NAME}/createTaskAsync`,
  async (payload: Partial<TaskDTO>) => {
    const response = await TaskService.createItem(payload);
    return response.data;
  }
);

export const createTasksAsync = createAsyncThunk(
  `${SLICE_NAME}/createTasksAsync`,
  async (payload: CreateTaskPayload[]) => {
    const response = await TaskService.createTasks(payload);
    return response.data;
  }
);

export const deleteTaskAsync = createAsyncThunk(
  `${SLICE_NAME}/deleteTaskAsync`,
  async (taskId: number) => {
    const response = await TaskService.deleteItem(taskId);
    if (
      response.status === HTTPStatusCode.OK ||
      response.status === HTTPStatusCode.NoContent
    ) {
      return taskId;
    }
    return -1;
  }
);

interface LoadTasksAsyncProps {
  options: RequestOptions;
  isReload?: boolean;
}
export const loadTasksAsync = createAsyncThunk(
  `${SLICE_NAME}/loadTasksAsync`,
  async ({ options, isReload }: LoadTasksAsyncProps, { getState }) => {
    const state = getState() as RootState;
    const isUseESRelatedApis = selectIsUseElasticSearch(state);
    if (!("sort" in options)) options["sort"] = "createdDate,desc";
    const isProjectSupportThumbnail = isImageProject(
      state.project.project?.type
    );
    if (isUseESRelatedApis) {
      const res = await QueryService.getTasksData(options);
      return { ...res, isReload };
    }

    let response = await TaskService.getItems(options);

    let totalCount = 0;
    if (response.headers && response.headers["x-total-count"]) {
      totalCount = parseInt(response.headers["x-total-count"]);
    }

    if (isProjectSupportThumbnail) {
      try {
        const fileIdMapper = (task: TaskDTO) => task.taskReference;
        const fileIds = response.data.map(fileIdMapper);

        const updateTasksUsingStorageService = async () => {
          const res = await StorageService.getAdditionalFileInfosFromIds(
            fileIds
          );
          const thumbnailUrlsMap: Record<string, string> = {};
          for (const info of res.data) {
            thumbnailUrlsMap[info.fileInfoId.toString()] =
              getThumbnailUrlFromFileInfoStorageService(info);
          }
          response.data = response.data.map((task: TaskDTO) => ({
            ...task,
            thumbnailUrl: thumbnailUrlsMap[task.taskReference] || undefined,
          }));
        };

        await updateTasksUsingStorageService();
      } catch (error: any) {
        Sentry.captureException(error);
        console.log(error);
      }
    }
    return { items: response.data, total: totalCount, isReload };
  }
);

export const reopenTaskAndUpdateTaskAsync = createAsyncThunk(
  `${SLICE_NAME}/reopenTaskAndUpdateTaskAsync`,
  async ({ taskId, reason }: { taskId: number; reason: string }) => {
    const payload: ReopenTasksRequest = {
      taskIds: [taskId],
      reason,
    };
    await TaskService.reopenTasks(payload);
    return { taskId };
  }
);

export const reopenJobsAndUpdateTaskAsync = createAsyncThunk(
  `${SLICE_NAME}/reopenJobsAndUpdateTaskAsync`,
  async ({
    batchId,
    taskId,
    jobIds,
    reason,
  }: {
    batchId: number | string;
    taskId: number;
    jobIds: number[];
    reason: string;
  }) => {
    // for (let jobId of jobIds) {
    //   await BatchService.reopen(batchId, {
    //     description: reason,
    //     jobId,
    //   });
    // }
    return { taskId };
  }
);

interface TaskPayload {
  taskId: number;
  jobIds: number[];
  reason: string;
}

export const reopenTaskAsync = createAsyncThunk(
  `${SLICE_NAME}/reopenTaskAsync`,
  async (params: TaskPayload) => {
    if (params.jobIds.length !== 0) {
      const res = await JobService.reopenJobs(params.jobIds, params.reason);
      console.log(res.data);
      return undefined;
    }

    const payload: ReopenTasksRequest = {
      taskIds: [params.taskId],
      reason: params.reason,
    };
    const response = await TaskService.reopenTasks(payload);
    return response.data;
  }
);

export const skipTaskAsync = createAsyncThunk(
  `${SLICE_NAME}/skipTaskAsync`,
  async (params: TaskPayload) => {
    if (params.jobIds.length !== 0) {
      const res = await JobService.skipJob({ id: params.jobIds[0] });
      console.log(res.data);
      return undefined;
    }
    const payload = { taskIds: [params.taskId] };
    const response = await TaskService.skipTasks(payload);
    return response.data;
  }
);

export const patchTask = createAsyncThunk(
  `${SLICE_NAME}/patchTask`,
  async ({
    taskId,
    payload,
  }: {
    taskId: number | string;
    payload: Partial<TaskDTO>;
  }) => {
    await TaskService.patch(`${TaskService.getPath()}/${taskId}`, payload);
    return { taskId, payload };
  }
);

export const batchReducerBuilder = (
  builder: ActionReducerMapBuilder<BatchState>
): ActionReducerMapBuilder<BatchState> => {
  return builder
    .addCase(updateBatchManagementAsync.pending, (state) => {})
    .addCase(updateBatchManagementAsync.rejected, () => {})
    .addCase(updateBatchManagementAsync.fulfilled, (state, action) => {
      const { updateState, data } = action.payload;
      if (!updateState) return;

      state.batch = data.batch;
      state.workManagements = data.workManagement;
      state.labels = data.batchObservation;
      state.labels.sort((a, b) => a.priority - b.priority);
      state.pricing = data.pricing;
      state.tasks =
        data?.tasks?.map((task: any) => {
          return { ...task, selected: false };
        }) || [];
      state.instructions = [];
      state.stepUsers = {};

      for (const workManagement of state.workManagements) {
        const instructionId = workManagement.workInstruction.id;
        if (!state.instructions.find((ins) => ins.id === instructionId)) {
          state.instructions.push(workManagement.workInstruction);
        }

        const step = workManagement.workInstruction.step;
        if (!state.stepUsers.hasOwnProperty(step)) {
          state.stepUsers[step] = [];
        }
        state.stepUsers[step].push({
          email: workManagement.userId,
          dailyLimit: workManagement.dailyLimit,
          limit: workManagement.limit,
        });
      }
      state.instructions.sort((a, b) => a.step - b.step);
    })
    .addCase(loadBatchDataAsync.pending, (state) => {
      state.workManagements = [];
      state.labels = [];
      state.batch = null;
      state.workflow = null;
      state.instructions = [];
      state.stepUsers = {};
      state.tasks = [];
      state.selectedTaskAction = { taskId: -1, action: "" };
      state.requestStatus = RequestStatus.LOADING;
      state.requestError = null;
    })
    .addCase(loadBatchDataAsync.rejected, (state, action) => {
      state.requestError = action.error.message || null;
      state.requestStatus = RequestStatus.FAILURE;
    })
    .addCase(loadBatchDataAsync.fulfilled, (state, action) => {
      state.workManagements = action.payload.workManagement;
      state.labels = action.payload.batchObservation;
      state.labels.sort((a, b) => a.priority - b.priority);
      state.batch = action.payload.batch;
      state.workflow = action.payload.workflowBatch;
      state.pricing = action.payload.pricing;
      state.instructions = [];
      state.stepUsers = {};
      state.requestStatus = RequestStatus.SUCCESS;
      state.requestError = null;

      for (const workManagement of state.workManagements) {
        const instructionId = workManagement.workInstruction.id;
        if (!state.instructions.find((ins) => ins.id === instructionId)) {
          state.instructions.push(workManagement.workInstruction);
        }

        const step = workManagement.workInstruction.step;
        if (!state.stepUsers.hasOwnProperty(step)) {
          state.stepUsers[step] = [];
        }
        state.stepUsers[step].push({
          ...workManagement,
          email: workManagement.userId,
          workInstructionId: instructionId,
        });
      }
      state.instructions.sort((a, b) => a.step - b.step);
    })
    .addCase(loadBatchGenAnnoAsyncJobStatusAsync.fulfilled, (state, action) => {
      const { status, percentComplete } = action.payload;
      state.genAnnoAsyncJobStatus = status;
      state.genAnnoAsyncJobPercentComplete = percentComplete;
    })

    .addCase(updateBatchAsync.pending, (state) => {})
    .addCase(updateBatchAsync.rejected, () => {})
    .addCase(updateBatchAsync.fulfilled, (state, action) => {
      if (action.payload && action.payload) {
        state.batch = action.payload;
      }
    })

    .addCase(createTaskAsync.pending, (state) => {})
    .addCase(createTaskAsync.fulfilled, (state, action) => {
      state.tasks.unshift(action.payload);
    })

    .addCase(loadTasksAsync.pending, (state) => {
      state.isLoadingBatchTasks = true;
    })
    .addCase(loadTasksAsync.fulfilled, (state, action) => {
      state.tasks = action.payload.items.map((item: TaskDTO) => ({
        ...item,
        selected: false,
      }));
      state.totalTask = action.payload.total;
      state.isLoadingBatchTasks = false;
      if (!action.payload.isReload) {
        state.selectedTaskAction = {
          taskId: -1,
          action: "",
        };
      }
    })
    .addCase(loadTasksAsync.rejected, (state) => {
      state.isLoadingBatchTasks = false;
    })
    .addCase(reopenTaskAsync.fulfilled, (state, action) => {
      if (!action.payload) return;
      if (!Array.isArray(action.payload)) return;
      if (action.payload.length === 0) return;
      const updatedTask = action.payload[0];
      state.tasks = state.tasks.map((task) => {
        if (task.id === updatedTask.id) {
          return { ...task, ...updatedTask };
        }
        return task;
      });
    })
    .addCase(skipTaskAsync.fulfilled, (state, action) => {
      if (!action.payload) return;
      if (!Array.isArray(action.payload)) return;
      if (action.payload.length === 0) return;
      const updatedTask = action.payload[0];
      state.tasks = state.tasks.map((task) => {
        if (task.id === updatedTask.id) {
          return { ...task, ...updatedTask };
        }
        return task;
      });
    })
    .addCase(reopenTaskAndUpdateTaskAsync.fulfilled, (state, action) => {
      const { taskId } = action.payload;
      state.tasks = state.tasks.map((task) => {
        if (task.id === taskId) {
          return {
            ...task,
            status: TaskStatus.WORKING,
            customerReviewStatus: TaskCustomerReviewStatus.REOPENED,
          };
        }
        return task;
      });
    })
    .addCase(reopenTaskAndUpdateTaskAsync.rejected, (state, action) => {})
    .addCase(reopenJobsAndUpdateTaskAsync.fulfilled, (state, action) => {
      const { taskId } = action.payload;
      state.tasks = state.tasks.map((task) => {
        if (task.id === taskId) {
          return {
            ...task,
            status: TaskStatus.WORKING,
            customerReviewStatus: TaskCustomerReviewStatus.REOPENED,
          };
        }
        return task;
      });
    })
    .addCase(reopenJobsAndUpdateTaskAsync.rejected, (state, action) => {})
    .addCase(patchTask.fulfilled, (state, action) => {
      const { taskId, payload } = action.payload;
      state.tasks = state.tasks.map((task) => {
        if (task.id === taskId) {
          return {
            ...task,
            ...payload,
          };
        }
        return task;
      });
    })

    .addCase(deleteTaskAsync.fulfilled, (state, action) => {
      state.totalTask = Math.max(state.totalTask - 1, 0);
      state.tasks = state.tasks.filter((task) => {
        return task.id !== action.payload;
      });
    });
};
