/*
 * File: annotation-util.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 9th February 2022 10:41:13 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  MeasurementData,
  MeasurementRelationData,
} from "components/dicom/cornerstone/models/measurement.model";
import {
  calculateBoundingBox,
  pointsToHandle,
} from "components/dicom/cornerstone/utils/cornerstone-utils";
import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";
import { AnnotateType } from "constants/annotation.constant";
import { collectionUtils } from "domain/common";
import { Annotation, AnnotationData, Label } from "domain/image-labeling";
import { AnnotationAgreement } from "domain/image-labeling/agreement";
import { JobAnnotation } from "domain/image-labeling/job-annotation";
import {
  RelationAnnotation,
  RelationAnnotationData,
} from "domain/image-labeling/relation-annotation";
import { ObservationDTO } from "services/label-service/dtos";
import {
  AnnotationRequest,
  AnnotationType,
} from "services/label-service/dtos/task-observation-v2.dto";
import { Point } from "utilities/math/point";
import { Rectangle } from "utilities/math/rectangle";
import { newUniqueId } from "utilities/number/id-generator";
import { v4 } from "uuid";
import { measurementUtils } from "./measurement-util";
import cornerstoneTools from "cornerstone-tools";
import { imageAnnotationUtils } from "store/labeler/image-workspace/image-annotations/image-annotations.util";
import { AnnotationSource } from "domain/labeling/annotation-source";
import {
  AnnotationJobRequestDTO,
  AnnotationJobResponseDTO,
  AnnotationRequestDTO,
  AnnotationResponseDTO,
  RawImageAnnotationItem,
} from "services/label-service/dtos/annotations.dto";
import { Job } from "domain/labeling/job";
import { AnnotationVoteStatus } from "services/label-service/dtos/annotation.dto";
const freehandUtils = cornerstoneTools.importInternal("util/freehandUtils");

interface GroupItem {
  id: number;
  first: number;
  second: number;
  localGroup: number;
  observationId?: number;
}
interface Group {
  observationId?: number;
  localGroup: number;
  ids: number[];
}
function groupRelations(items: GroupItem[]): Group[] {
  const groups: Group[] = [];
  const groupIds: number[] = [];

  for (let item of items) {
    if (!groupIds.includes(item.localGroup)) groupIds.push(item.localGroup);
  }

  for (let groupId of groupIds) {
    const secondItemIds: Record<number, number> = {};
    const firstItemIds: Record<number, number> = {};
    const groupItems = items.filter((item) => item.localGroup === groupId);
    for (let groupItem of groupItems) {
      secondItemIds[groupItem.second] = groupItem.first;
      firstItemIds[groupItem.first] = groupItem.second;
    }
    let startItem = groupItems.find(
      (item) => !secondItemIds.hasOwnProperty(item.first)
    );
    if (!startItem) continue;
    const ids = [startItem.first, startItem.second];
    while (firstItemIds.hasOwnProperty(ids[ids.length - 1])) {
      ids.push(firstItemIds[ids[ids.length - 1]]);
    }
    groups.push({
      localGroup: groupId,
      ids: ids,
      observationId: startItem.observationId,
    });
  }

  return groups;
}

export function isSystemObservation(observation: ObservationDTO) {
  return observation?.observationSetting?.systemAttribute;
}

function measurementBoundingBox(measurement: MeasurementData) {
  if (!measurement?.handles) return null;
  if (
    measurement.handles.hasOwnProperty("start") &&
    measurement.handles.hasOwnProperty("end")
  ) {
    const { start, end } = measurement.handles;
    const x = Math.min(start.x, end.x);
    const y = Math.min(start.y, end.y);
    const width = Math.abs(end.x - start.x);
    const height = Math.abs(end.y - start.y);
    return { x, y, width, height };
  }

  if (
    measurement.handles.hasOwnProperty("points") &&
    measurement.handles.points.length > 0
  ) {
    const points: Point[] = measurement.handles.points.map((point: any) => {
      return { x: point.x, y: point.y };
    });
    const minX = Math.min(...points.map(({ x }) => x));
    const maxX = Math.max(...points.map(({ x }) => x));
    const minY = Math.min(...points.map(({ y }) => y));
    const maxY = Math.max(...points.map(({ y }) => y));
    return { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
  }
  return null;
}

function measurementToAnnotationData(
  measurement: MeasurementData
): AnnotationData[] {
  const type = measurement.handles.hasOwnProperty("points")
    ? AnnotateType.POLYGON
    : AnnotateType.BOUNDING_BOX;
  const annotationData = {
    type: type,
    uuid: measurement.uuid,
    points: measurementToPoints(measurement),
  };

  const keys = Object.keys(measurement.handles).filter((key) =>
    key.includes("layer")
  );

  const otherItems = keys.map((key, index) => {
    return {
      layer: index,
      layerType: key.includes("add") ? 0 : -1,
      type: type,
      uuid: measurement.uuid,
      points: measurement.handles[key].map((p: any) => ({ x: p.x, y: p.y })),
    };
  });

  return [annotationData, ...otherItems];
}

function measurementToRelationAnnotationData(
  measurement: MeasurementRelationData
): RelationAnnotationData | null {
  if (!measurement) return null;
  const uuids: string[] = measurement.handles.points.map(
    (p: any) => p.measurementUUID
  );
  if (uuids.length < 2) return null;
  const annotationData = {
    type: measurement.type,
    uuid: measurement.uuid,
    measurementIds: uuids,
  };

  return annotationData;
}

function measurementToPoints(measurement: MeasurementData): Point[] {
  let points = [];
  if (measurement.handles.hasOwnProperty("points")) {
    points = measurement.handles.points.map((p: any) => ({
      x: p.x,
      y: p.y,
    }));
  } else if (
    measurement.handles.hasOwnProperty("start") &&
    measurement.handles.hasOwnProperty("end")
  ) {
    const { start, end } = measurement.handles;
    points = [
      { x: Math.min(start.x, end.x), y: Math.min(start.y, end.y) },
      { x: Math.max(start.x, end.x), y: Math.max(start.y, end.y) },
    ];
  }
  return points;
}

function toMeasurement(annotation: Annotation): MeasurementData | undefined {
  let measurement;
  if (annotation.annotationData[0].type === AnnotateType.BOUNDING_BOX) {
    measurement = bboxMeasurement(annotation);
  } else if (annotation.annotationData[0].type === AnnotateType.POLYGON) {
    measurement = polygonMeasurement(annotation);
  } else {
    return undefined;
  }
  updateMeasurement(measurement, annotation);
  return measurement;
}

function toRelationMeasurement(
  relationAnnotation: RelationAnnotation,
  measurementsMap: Record<string, MeasurementData>
): MeasurementRelationData | undefined {
  const measurements = relationAnnotation.annotationData.measurementIds.map(
    (measurementId) => measurementsMap[measurementId]
  );
  const handlePoints = measurements.map((measurement) => {
    const point = measurementUtils.measurementCenterPoint(measurement);
    const handlePoint = new freehandUtils.FreehandHandleData({
      x: point?.x,
      y: point?.y,
    });
    handlePoint.measurementUUID = measurement.uuid;
    return handlePoint;
  });

  const measurementData = {
    uuid: relationAnnotation.uuid,
    visible: relationAnnotation.visible,
    active: false,
    invalidated: true,
    color: "#FF00FF",
    type: ToolName.MultiArrowConnection,
    handles: {
      points: handlePoints,
    },
  };

  return measurementData;
}

function updateMeasurement(
  measurement: MeasurementData,
  annotation: Annotation
) {
  if (!measurement || !annotation || measurement.uuid !== annotation.uuid)
    return;
  measurement.annotationId = annotation.id;
  measurement.visible = annotation.visible;
  measurement.color = annotation.color;
  measurement.fillPolygon = annotation.fillShape;
  measurement.showLabel = annotation.showLabel;
  measurement.label = annotation.label;
  measurement.labelId = annotation.labelId;
  measurement.displayTextValue = annotation.displayTextValue;
  measurement.numberOfPoint = annotation.numberOfPoint;
  measurement.text = annotation.annotationData[0].attributes
    ?.map((attr) => attr.value)
    .toString();
  measurement.type = annotation.annotationData[0].type;
  measurement.locked = annotation.locked;
  measurement.vote = annotation.vote;
  measurement.showActionVote = annotation.showActionVote;
  measurement.iou = annotation.iou;
  measurement.iouHidden = annotation.iouHidden;
  measurement.frameId = annotation.frameId;
  measurement.opacity = annotation.opacity;
}

function updateRelationMeasurement(
  measurement: MeasurementRelationData,
  annotation: RelationAnnotation
) {
  if (!measurement || !annotation || measurement.uuid !== annotation.uuid)
    return;
  const measurementIds = annotation.annotationData.measurementIds;
  measurement.handles?.points?.forEach((point: any, index: number) => {
    if (point && index < measurementIds.length) {
      point.measurementUUID = measurementIds[index];
    }
  });
  measurement.annotationId = annotation.id;
  measurement.visible = annotation.visible;
  measurement.type = annotation.annotationData.type;
}

function polygonMeasurement(annotation: Annotation): MeasurementData {
  let measurementData = {
    annotationId: annotation.id,
    visible: true,
    active: false,
    invalidated: false,
    color: annotation.color,
    handles: pointsToHandle(annotation.annotationData[0].points),
    polyBoundingBox: calculateBoundingBox(annotation.annotationData[0].points),
    uuid: annotation.uuid,
    cachedStats: null,
    label: annotation.label,
    showLabel: true,
    displayTextValue: true,
    objectTrackingId: annotation.objectTrackingId,
    numberOfPoint: annotation.numberOfPoint,
    text: annotation.annotationData[0].attributes
      ?.map((attr) => attr.value)
      .toString(),
    unit: "",
    renderDashed: false,
    vote: annotation.vote,
    showActionVote: annotation.showActionVote,
    iou: annotation.iou,
    type: annotation.type,
  };

  for (let i = 1; i < annotation.annotationData.length; i++) {
    const layerData = annotation.annotationData[i];
    const key = `layer-${i - 1}-${
      layerData.layerType === -1 ? "subtract" : "add"
    }`;
    const points = annotation.annotationData[i].points.map(
      (point) =>
        new freehandUtils.FreehandHandleData({ x: point.x, y: point.y })
    );
    for (let idx = 0; idx < points.length; idx++) {
      points[idx].lines.push(points[(idx + 1) % points.length]);
    }
    (measurementData.handles as any)[key] = points;
  }
  return measurementData;
}

function bboxMeasurement(annotation: Annotation): MeasurementData {
  let measurementData = createBoundingBoxMeasurement(
    annotation.annotationData[0].points
  );
  return {
    ...measurementData,
    ...annotation,
    annotationId: annotation.id,
    visible: true,
    active: false,
    invalidated: false,
    color: annotation.color,
    uuid: annotation.uuid,
    cachedStats: null,
    unit: "",
    showLabel: true,
    displayTextValue: true,
    objectTrackingId: annotation.objectTrackingId,
    text: annotation.annotationData[0].attributes
      ?.map((attr) => attr.value)
      .toString(),
    renderDashed: false,
    type: annotation.type,
  };
}

function createBoundingBoxMeasurement(points: Point[]) {
  const startIndex = 0;
  const endIndex = points.length === 1 ? 0 : 1;
  const left = points[startIndex].x;
  const top = points[startIndex].y;
  const width = points[endIndex].x - points[startIndex].x;
  const height = points[endIndex].y - points[startIndex].y;

  return {
    visible: true,
    active: true,
    color: undefined,
    invalidated: true,
    handles: {
      start: {
        x: points[startIndex].x,
        y: points[startIndex].y,
        highlight: true,
        active: false,
      },
      end: {
        x: points[endIndex].x,
        y: points[endIndex].y,
        highlight: true,
        active: true,
      },

      left: {
        x: left,
        y: top + height / 2,
        highlight: true,
        active: true,
      },
      top: {
        x: left + width / 2,
        y: top,
        highlight: true,
        active: true,
      },
      right: {
        x: left + width,
        y: top + height / 2,
        highlight: true,
        active: true,
      },
      bottom: {
        x: left + width / 2,
        y: top + height,
        highlight: true,
        active: true,
      },
      topRight: {
        x: left + width,
        y: top,
        highlight: true,
        active: true,
      },
      bottomLeft: {
        x: left,
        y: top + height,
        highlight: true,
        active: true,
      },

      initialRotation: 0,
      textBox: {
        active: false,
        hasMoved: false,
        movesIndependently: false,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
      },
    },
  };
}

function getAnnotationAttribute(annotation: Annotation, attributeId?: number) {
  const { attributes } = annotation.annotationData[0];
  return attributes?.find((attr) => attr.id === attributeId)?.value;
}

export function annotationBoundingBox(annotation: Annotation): Rectangle {
  const points: Point[] = annotation.annotationData[0].points;
  const minX = Math.min(...points.map(({ x }) => x));
  const maxX = Math.max(...points.map(({ x }) => x));
  const minY = Math.min(...points.map(({ y }) => y));
  const maxY = Math.max(...points.map(({ y }) => y));
  return { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
}

function toAnnotationRequest(annotation: Annotation): AnnotationRequest {
  let item = {
    observationId: annotation.labelId,
  };
  if (!annotation.annotationData || !annotation.annotationData[0].points) {
    return {
      jobId: annotation.jobId,
      annotations: [item],
    };
  }
  item = {
    ...annotation.annotationData,
    ...item,
  };
  return {
    jobId: annotation.jobId,
    annotationType: AnnotationType.IMAGE,
    annotations: [item],
  };
}

function toAnnotationJobRequestDTO(
  jobId: number,
  annotations: Annotation[],
  relationAnnotations: RelationAnnotation[]
): AnnotationJobRequestDTO {
  const annoJobRequest: AnnotationJobRequestDTO = {
    annotations: [],
    annotationRelations: [],
  };
  const jobAnnotations = annotations.filter((anno) => anno.jobId === jobId);
  const annotationEntities = imageAnnotationUtils.toEntities(annotations);
  for (const annotation of jobAnnotations) {
    if (annotation.annotationData[0].points.length === 0) {
      const annoRequest: AnnotationRequestDTO = {
        observationId: annotation.labelId,
      };
      if (annotation.annotationData[0].type === AnnotateType.CLASSIFICATION) {
        annoRequest.annotation = {
          attributes: annotation.annotationData[0].attributes || [],
        };
      }
      annoJobRequest.annotations.push(annoRequest);
    } else {
      const annoItem: RawImageAnnotationItem = {
        attributes: annotation.annotationData[0].attributes || [],
        frameId: annotation.frameId,
      };
      if (
        annoItem &&
        annotation.annotationData[0].type === AnnotateType.BOUNDING_BOX
      ) {
        const [start, end] = annotation.annotationData[0].points;
        annoItem.bbox = {
          x: Math.min(start.x),
          y: Math.min(start.y),
          width: Math.abs(end.x - start.x),
          height: Math.abs(end.y - start.y),
        };
      } else if (
        annoItem &&
        annotation.annotationData[0].type === AnnotateType.POLYGON
      ) {
        annoItem.polygons = annotation.annotationData.map((item) => {
          const poly: any = { points: item.points };
          if (item.layer !== undefined && item.layerType !== undefined) {
            poly["layer"] = item.layer;
            poly["type"] = item.layerType;
          }
          return poly;
        });
      }
      const annoRequest: AnnotationRequestDTO = {
        localId: annotation.id,
        observationId: annotation.labelId,
        annotation: annoItem,
      };

      annoJobRequest.annotations.push(annoRequest);
    }
  }
  for (const relationAnnotation of relationAnnotations) {
    const { measurementIds } = relationAnnotation.annotationData;
    const localGroup = newUniqueId();
    for (let idx = 1; idx < measurementIds.length; idx++) {
      const startId = measurementIds[idx - 1];
      const endId = measurementIds[idx];
      const startAnnotation = annotationEntities[startId];
      const endAnnotation = annotationEntities[endId];
      if (!startAnnotation || !endAnnotation) continue;
      const annotationRelation = {
        directed: true,
        firstLocalId: startAnnotation.id,
        secondLocalId: endAnnotation.id,
        localGroup,
      };
      if (annoJobRequest.annotationRelations) {
        annoJobRequest.annotationRelations.push(annotationRelation);
      }
    }
  }
  return annoJobRequest;
}
function getAnnotationResponseDTOPoints(
  dto: AnnotationResponseDTO,
  label: Label
): Point[] {
  let points: Point[] = [];
  const annoItem = dto.annotation as RawImageAnnotationItem;

  if (AnnotateType.BOUNDING_BOX === label.annotateType && annoItem?.bbox) {
    const { x, y, width, height } = annoItem?.bbox;
    points = [
      { x, y },
      { x: x + width, y: y + height },
    ];
  } else if (
    AnnotateType.POLYGON === label.annotateType &&
    annoItem?.polygons &&
    annoItem?.polygons?.length > 0
  ) {
    points = annoItem.polygons[0].points;
  }
  return points;
}

function getFrameId(anno: AnnotationResponseDTO) {
  if (!anno || !anno.annotation) return undefined;
  if ((anno.annotation as any).hasOwnProperty("frameId")) {
    return (anno.annotation as any).frameId;
  }
  return undefined;
}

function fromAnnotationResponseDTO(
  anno: AnnotationResponseDTO,
  labels: Record<number, Label>
): Annotation | undefined {
  if (!anno.observationId) return undefined;
  if (!labels.hasOwnProperty(anno.observationId)) return undefined;
  const label = labels[anno.observationId];
  const uuid = v4();
  const frameId = getFrameId(anno);
  const annotation: Annotation = {
    id: anno.id || newUniqueId(),
    jobId: anno.jobId || -1,
    labelId: anno.observationId || label.id,
    annotator: anno.assignee || "",
    uuid: uuid,
    source: anno.source || AnnotationSource.CLIENT,
    annotationData: [
      {
        uuid,
        type: label.annotateType || AnnotateType.BOUNDING_BOX,
        attributes: anno.annotation?.attributes,
        points: getAnnotationResponseDTOPoints(anno, label),
      },
    ],
    color: label.color || "",
    label: label.name,
    visible: true,
    fillShape: true,
    showLabel: true,
    displayTextValue: true,
    locked: false,
    isSystemLabel: label.isSystemLabel,
    vote: anno?.vote,
    mediaId: anno.mediaId,
    frameId,
  };
  const annoItem = anno.annotation as RawImageAnnotationItem;
  const isBBox =
    AnnotateType.BOUNDING_BOX === label.annotateType && annoItem?.bbox;
  if (isBBox) {
    annotation.annotationData = [
      {
        uuid,
        type: label.annotateType || AnnotateType.BOUNDING_BOX,
        attributes: annoItem?.attributes,
        points: getAnnotationResponseDTOPoints(anno, label),
      },
    ];
  }

  const isPoly = AnnotateType.POLYGON === label.annotateType;

  if (isPoly && annoItem?.polygons && annoItem?.polygons?.length > 0) {
    annotation.annotationData = annoItem.polygons.map((item, index) => {
      const polyData: AnnotationData = {
        uuid: uuid,
        type: label.annotateType || AnnotateType.POLYGON,
        points: item.points,
      };
      if (index === 0) {
        polyData.attributes = anno.annotation?.attributes;
      }
      if (index > 0) {
        polyData.layer = index;
        polyData.layerType = item.type;
      }
      return polyData;
    });
  }
  return annotation;
}

function annotationJobResponseDTOToJobAnnotation(
  dto: AnnotationJobResponseDTO,
  labels: Record<number, Label>,
  job: Job
): JobAnnotation {
  const { annotations, relationAnnotations, agreements } =
    annotationJobResponseDTOToAnnotations(dto, labels);

  const jobAnnotation: JobAnnotation = {
    id: job.id,
    jobId: job.id,
    annotator: job.assignee || "",
    agrements: agreements,
    taskId: job.taskId || -1,
    annotations: collectionUtils.fromEntities(annotations),
    relationAnnotations: collectionUtils.fromEntities(relationAnnotations),
  };

  return jobAnnotation;
}

function annotationJobResponseDTOToAnnotations(
  dto: AnnotationJobResponseDTO,
  labels: Record<number, Label>
) {
  const annotations: Annotation[] = [];

  for (let anno of dto.annotations) {
    const annotation = fromAnnotationResponseDTO(anno, labels);
    if (!annotation) continue;
    annotations.push(annotation);
  }
  const annotationEntities =
    imageAnnotationUtils.toAnnotationEntities(annotations);
  const relationAnnotations: RelationAnnotation[] = [];
  const groupItems: GroupItem[] = (dto.annotationRelations || []).map(
    (anno) => {
      return {
        id: anno.id,
        first: anno.first,
        second: anno.second,
        localGroup: anno.localGroup,
        observationId: anno.observationId,
      } as GroupItem;
    }
  );
  const groups = groupRelations(groupItems);
  for (let group of groups) {
    const measurementIds = group.ids
      .map((id) => annotationEntities[id].uuid)
      .filter((id) => !!id);
    const startAnnotation = annotationEntities[group.ids[0]];
    if (measurementIds.length < 2 || !startAnnotation) continue;
    const uuid = v4();
    const relationAnnotation: RelationAnnotation = {
      id: group.localGroup || newUniqueId(),
      jobId: startAnnotation.jobId,
      labelId: group.observationId || -1,
      annotator: startAnnotation.annotator,
      uuid: uuid,
      source: startAnnotation.source,
      annotationData: {
        uuid,
        type: ToolName.MultiArrowConnection,
        measurementIds: measurementIds,
      },
      visible: true,
    };
    relationAnnotations.push(relationAnnotation);
  }

  const agreements: AnnotationAgreement[] = [];

  // TODO: Convert later because we use agreements by annotations later
  if (dto.annotationAgreementDTOS) {
  }

  return {
    annotations,
    relationAnnotations,
    agreements,
  };
}

function fromAnnotationJobResponseDTO(
  dto: AnnotationJobResponseDTO,
  labels: Record<number, Label>
): { annotations: Annotation[]; relationAnnotations: RelationAnnotation[] } {
  const annotations: Annotation[] = [];

  for (let anno of dto.annotations) {
    const annotation = fromAnnotationResponseDTO(anno, labels);
    if (!annotation) continue;

    const annotationVote = dto.annotationVotes?.find(
      (annotationVote) => annotationVote.annotationId === annotation.id
    );
    const vote = fromAnnotationVoteStatusToVote(
      annotationVote ? annotationVote.status : ""
    );
    annotations.push({ ...annotation, vote });
  }

  const annotationEntities =
    imageAnnotationUtils.toAnnotationEntities(annotations);
  const relationAnnotations: RelationAnnotation[] = [];
  const groupItems: GroupItem[] = (dto.annotationRelations || []).map(
    (anno) => {
      return {
        id: anno.id,
        first: anno.first,
        second: anno.second,
        localGroup: anno.localGroup,
        observationId: anno.observationId,
      } as GroupItem;
    }
  );
  const groups = groupRelations(groupItems);
  for (let group of groups) {
    const measurementIds = group.ids
      .map((id) => annotationEntities[id].uuid)
      .filter((id) => !!id);
    const startAnnotation = annotationEntities[group.ids[0]];
    if (measurementIds.length < 2 || !startAnnotation) continue;
    const uuid = v4();
    const relationAnnotation: RelationAnnotation = {
      id: group.localGroup || newUniqueId(),
      jobId: startAnnotation.jobId,
      labelId: group.observationId || -1,
      annotator: startAnnotation.annotator,
      uuid: uuid,
      source: startAnnotation.source,
      annotationData: {
        uuid,
        type: ToolName.MultiArrowConnection,
        measurementIds: measurementIds,
      },
      visible: true,
    };
    relationAnnotations.push(relationAnnotation);
  }

  return {
    annotations,
    relationAnnotations,
  };
}

export const fromSystemObservation = (
  jobId: number,
  labelId: number,
  label: string,
  annotator: string,
  color: string
) => {
  const uuid = v4();
  const anno: Annotation = {
    id: -1,
    jobId: jobId,
    labelId,
    annotator,
    uuid: uuid,
    source: AnnotationSource.CLIENT,
    annotationData: [
      {
        type: "",
        points: [],
        uuid: uuid,
      },
    ],
    color,
    label,
    visible: false,
    fillShape: false,
    showLabel: false,
    displayTextValue: false,
    locked: false,
  };
  return anno;
};

export const fromAnnotationVoteStatusToVote = (
  status: AnnotationVoteStatus | string
) => {
  return status === AnnotationVoteStatus.UP
    ? 1
    : status === AnnotationVoteStatus.DOWN
    ? -1
    : 0;
};

export const annotationUtils = {
  measurementToAnnotationData,
  measurementToPoints,
  toMeasurement,
  toRelationMeasurement,
  updateMeasurement,
  annotationBoundingBox,
  getAnnotationAttribute,
  toAnnotationRequest,
  measurementBoundingBox,
  toAnnotationJobRequestDTO,
  fromAnnotationJobResponseDTO,
  measurementToRelationAnnotationData,
  annotationJobResponseDTOToJobAnnotation,
  updateRelationMeasurement,
  groupRelations,
  annotationJobResponseDTOToAnnotations,
};
