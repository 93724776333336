import moment from "moment";
import { MlExperimentResponse } from "services/ml-service/ml-service.dto";
import { MlModelsExperimentUIModel } from "./experiments.models";

function fromEntity(dto: MlExperimentResponse): MlModelsExperimentUIModel {
  return {
    id: dto.id,
    name: dto.name,
    createdAt: moment(new Date(dto.created_at)).local().toDate(),
  };
}

export const experimentsMappers = {
  fromEntity,
};
