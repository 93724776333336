/*
 * File: cornerstone.slice.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CornerstoneState,
  INITIAL_CORNERSTONE_STATE,
} from "./cornerstone.state";

const SLICE_NAME: string = "conerstone";

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_CORNERSTONE_STATE,
  reducers: {
    resetCornerstoneState: (state: CornerstoneState) => {
      Object.assign(state, INITIAL_CORNERSTONE_STATE);
    },
    setFlipHorizontal: (
      state: CornerstoneState,
      action: PayloadAction<boolean>
    ) => {
      state.flipHorizontal = action.payload;
    },
    toggleFlipHorizontal: (state: CornerstoneState) => {
      state.flipHorizontal = !state.flipHorizontal;
    },
    setFlipVertical: (
      state: CornerstoneState,
      action: PayloadAction<boolean>
    ) => {
      state.flipVertical = action.payload;
    },
    toggleFlipVertical: (state: CornerstoneState) => {
      state.flipVertical = !state.flipVertical;
    },
    setColorInvert: (
      state: CornerstoneState,
      action: PayloadAction<boolean>
    ) => {
      state.colorInvert = action.payload;
    },
    toggleColorInvert: (state: CornerstoneState) => {
      state.colorInvert = !state.colorInvert;
    },
    setFullScreen: (
      state: CornerstoneState,
      action: PayloadAction<boolean>
    ) => {
      state.fullScreen = action.payload;
    },
    toggleFullScreen: (state: CornerstoneState) => {
      state.fullScreen = !state.fullScreen;
    },
    setShowHeartRuler: (
      state: CornerstoneState,
      action: PayloadAction<boolean>
    ) => {
      state.heartRuler = action.payload;
    },
    toggleHeartRuler: (state: CornerstoneState) => {
      state.heartRuler = !state.heartRuler;
    },
    setAnnotationLabelVisible: (
      state: CornerstoneState,
      action: PayloadAction<boolean>
    ) => {
      state.annotationVisible = action.payload;
    },
    toggleAnnotationLabel: (state: CornerstoneState) => {
      state.annotationVisible = !state.annotationVisible;
    },
    toggleRelationAnnotationVisibility: (state: CornerstoneState) => {
      state.relationAnnotationVisible = !state.relationAnnotationVisible;
    },
    setAnnotationFill: (
      state: CornerstoneState,
      action: PayloadAction<boolean>
    ) => {
      state.annotationFill = action.payload;
    },
    toggleAnnotationFill: (state: CornerstoneState) => {
      state.annotationFill = !state.annotationFill;
    },
    toggleDisplayTextValue: (state: CornerstoneState) => {
      state.displayTextValue = !state.displayTextValue;
    },
    toggleSynchronizerTool: (state: CornerstoneState) => {
      state.synchronize = !state.synchronize;
    },
  },
});

export const {
  resetCornerstoneState,
  setFlipHorizontal,
  toggleFlipHorizontal,
  setFlipVertical,
  toggleFlipVertical,
  setFullScreen,
  toggleFullScreen,
  setAnnotationFill,
  toggleAnnotationFill,
  setAnnotationLabelVisible,
  toggleAnnotationLabel,
  setColorInvert,
  toggleColorInvert,
  setShowHeartRuler,
  toggleHeartRuler,
  toggleDisplayTextValue,
  toggleRelationAnnotationVisibility,
  toggleSynchronizerTool
} = slice.actions;

export const conerstoneReducer = slice.reducer;
