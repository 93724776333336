import { useLocalStorageState } from "ahooks";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { StorageKeys } from "hooks/storage/storage-keys";

interface TicketSupportSettingModalProps {
  open: boolean;
  onClose: () => void;
}

export const TicketSupportSettingModal = ({
  open,
  onClose,
}: TicketSupportSettingModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ _, setTrackingBug ] = useLocalStorageState<boolean>(StorageKeys.TRACKING_BUG, true)
  
  const handleTurnOff = () => {
    setTrackingBug(false)
    onClose()
  }

  const handleTurnOn = () => {
    setTrackingBug(true)
    onClose()
  }

  return (
    <VBModal
      open={open}
      title="Warning"
      textSubmit="Ok"
      textClose="Cancel"
      onSubmit={handleTurnOff}
      onClose={handleTurnOn}
      width="40%"
    >
      <p>Do you want to off using Bug-tracking?</p>
    </VBModal>
  );
};
