/*
 * File: selectable-column-header.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 24th November 2021 5:17:35 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import Select, { Props as SelectProps } from "react-select";

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: "48px",
    height: 48,
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};

interface Props extends SelectProps {
  header: string;
}
export const SelectableInput = ({ header, ...rest }: Props) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="overflow-hidden whitespace-nowrap overflow-ellipsis">
        {header}
      </div>
      <Select styles={customStyles} {...rest} />
    </div>
  );
};
