/*
 * File: editor-settings.component.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 16th April 2022 11:58:52 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Switch } from "@material-ui/core";
import { useLocalStorageState } from "ahooks";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  selectIsAutoSaveConfig,
  selectShowPreviousJob,
  selectTextIsShowNavBar,
  selectTextIsShowRelationName,
  selectTextIsShowSideBar,
} from "store/labeler/text-workspace/text-editor/text-editor.selector";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import {
  setShowPreviousJob,
  textAutoSaveTogged,
  textNavBarToggled,
  textRelationNameDisplayTogged,
  textSideBarToggled,
} from "store/labeler/text-workspace/text-workspace.slice";
interface Props {
  open: boolean;
  mode?: TextLabelingMode;
  onClose(): void;
}
export function TextEditorSettingModal({
  onClose,
  mode = TextLabelingMode.LABELER,
}: Props) {
  const dispatch = useAppDispatch();
  const [jobsPerBatch, setJobsPerBatch] = useLocalStorageState(
    "JOB_PER_TEXT_BATCH",
    "1"
  );
  const showRelationName = useAppSelector(selectTextIsShowRelationName);
  const showNavBar = useAppSelector(selectTextIsShowNavBar);
  const showSideBar = useAppSelector(selectTextIsShowSideBar);
  const showPreviousJobs = useAppSelector(selectShowPreviousJob);
  const isAutoSaveConfig = useAppSelector(selectIsAutoSaveConfig)
  const isCustomer = mode === TextLabelingMode.CUSTOMER;
  const options = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "5", value: "5" },
    { label: "10", value: "10" },
    { label: "15", value: "15" },
    { label: "20", value: "20" },
  ];

  function handleJobsPerBatchChanged(option: any) {
    setJobsPerBatch(option.value as string);
  }

  function toggleShowRelationName() {
    dispatch(textRelationNameDisplayTogged());
  }

  function toggleNavBar() {
    dispatch(textNavBarToggled());
  }

  function toggleSideBar() {
    dispatch(textSideBarToggled());
  }

  function toggleShowPreviousJobs() {
    dispatch(setShowPreviousJob(!showPreviousJobs));
  }

  function toggleAutoSaveConfig() {
    dispatch(textAutoSaveTogged())
  }

  return (
    <VBModal
      open
      onClose={onClose}
      title={"Editor settings"}
      footerHidden={true}
      width="40rem"
    >
      <div style={{ maxHeight: "60vh" }}>
      <div className="flex items-center gap-4">
          <div className="flex-auto py-2">Auto Save</div>
          <Switch
            color="primary"
            checked={!!isAutoSaveConfig}
            onClick={toggleAutoSaveConfig}
          />
        </div>
        {/*<div className="flex items-center gap-4">
          <div className="flex-auto py-2">Dark theme</div>
          <Switch color="primary" checked={false} />
        </div> */}

        <div className="flex items-center gap-4">
          <div className="flex-auto py-2">Show relation name</div>
          <Switch
            color="primary"
            checked={!!showRelationName}
            onClick={toggleShowRelationName}
          />
        </div>
        {!isCustomer && (
          <div className="flex items-center gap-4">
            <div className="flex-auto py-2">Show previous job</div>
            <Switch
              color="primary"
              checked={!!showPreviousJobs}
              onClick={toggleShowPreviousJobs}
            />
          </div>
        )}
        <div className="flex items-center gap-4">
          <div className="flex-auto py-2">Show navigation bar</div>
          <Switch
            color="primary"
            checked={!!showNavBar}
            onClick={toggleNavBar}
          />
        </div>
        <div className="flex items-center gap-4">
          <div className="flex-auto py-2">Show side bar</div>
          <Switch
            color="primary"
            checked={!!showSideBar}
            onClick={toggleSideBar}
          />
        </div>

        {!isCustomer && (
          <div className="flex items-center gap-4">
            <div className="flex-auto py-2 space-y-1">
              <div>Number of jobs per microbatch.</div>
              <p className="text-sm text-background-500">
                Note: this setting will be applied on the next microbatch.
              </p>
            </div>
            <VBSelectComponent
              defaultValue={options.find((opt) => opt.value === jobsPerBatch)}
              options={options}
              onChange={handleJobsPerBatchChanged}
              menuPortalTarget={document.body}
            />
          </div>
        )}
      </div>
    </VBModal>
  );
}
