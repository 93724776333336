/*
 * File: file-info-panel.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 16th August 2021 9:35:06 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { DicomService } from "services/dicom/dicom-service";
import {
  extractFileDetail,
  getDicomMetadata,
  isDicomFile,
} from "./file-info-utils";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { useDatasetDetailContext } from "../../../dataset-detail.context";
import { formatDateTime } from "utilities/formatter/date-formatter.utils";
import { FileInfoHeader } from "./file-info-header";
import { FileInfoImagePreview } from "./file-info-image-preview";
import {
  VscAdd,
  VscChevronDown,
  VscChevronRight,
  VscTrash,
} from "react-icons/vsc";
import { IconButton, Tooltip } from "@material-ui/core";
import { QueryApi } from "data-access/impl/query";

interface FileInfoPanelProps {
  file: StorageFileDTO;
}
export const FileInfoPanel = ({ file }: FileInfoPanelProps) => {
  const { t } = useTranslation();
  const isDicom = isDicomFile(file);
  const infos = useMemo(() => extractFileDetail(file), [file]);
  const [dicomData, setDicomData] = useState<
    { id: string; label: string; value: string }[]
  >([]);
  const { toggleShowFileInfo } = useDatasetDetailContext();
  const [showDicomData, setShowDicomData] = useState(false);

  const handleRemoveFileMetadata = (
    file: StorageFileDTO,
    metadata: QueryApi.IMetadata
  ) => {};

  useEffect(() => {
    const loadDicomInfoAsync = async () => {
      if (!file || !file.dicomMetaData) return;
      const response =
        await DicomService.retrieveMetadataOfASingleInstanceWithinASeriesOfAStudy(
          file.dicomMetaData.studyId,
          file.dicomMetaData.seriesId,
          file.dicomMetaData.instanceId
        );
      const metadata = response.data[0];
      setDicomData(getDicomMetadata(metadata, file));
    };
    if (file?.dicomMetaData) loadDicomInfoAsync();
  }, [file]);

  if (!file) {
    return (
      <div className="h-full p-4 ml-4 border rounded border-gray">
        <div className="flex items-center justify-center w-full h-full italic text-gray-400">
          Select a file
        </div>
      </div>
    );
  }

  let { url, dicomMetaData, status, duplicatedRef, additionalFileInfo } = file;
  const duplicated = status === "duplicated";
  if (duplicated) {
    url = duplicatedRef.url;
    dicomMetaData = duplicatedRef.dicomMetaData;
  }

  if (additionalFileInfo && additionalFileInfo?.addition?.thumbnailUrl) {
    url = additionalFileInfo.addition.thumbnailUrl;
  }

  return (
    <div className="h-full py-2 overflow-auto bg-white border rounded border-gray">
      <FileInfoHeader fileName={file.fileName} onClick={toggleShowFileInfo} />
      <FileInfoImagePreview previewUrl={url} dicomMetadata={dicomMetaData} />
      <div className="w-full h-1 bg-background-100" />
      <FileInfoSection title="Information">
        <div className="px-4 pb-4">
          {infos.map(({ id, label, value }) => {
            if (!value) return null;
            return (
              <div key={id} className="py-1">
                <label className="block mb-0.5 text-sm text-gray-400">
                  {t(label)}
                </label>
                {id !== "createdDate" && (
                  <div className="overflow-hidden whitespace-normal">
                    {value}
                  </div>
                )}
                {id === "createdDate" && (
                  <div className="overflow-hidden whitespace-normal">
                    {t("common:formattedDate", {
                      date: formatDateTime(new Date(value)),
                    })}
                  </div>
                )}
              </div>
            );
          })}
          {isDicom && (
            <div
              className="pt-4 pb-1 cursor-pointer"
              onClick={() => setShowDicomData((show) => !show)}
            >
              <span className="text-lg font-bold">Dicom Metadata:</span>
              <span className="mx-4 text-primary">
                {showDicomData ? "Hide" : "Show"}
              </span>
            </div>
          )}
          {showDicomData &&
            isDicom &&
            dicomData.map(({ id, label, value }) => {
              return (
                <div key={id} className="py-1">
                  <label className="block mb-0.5 text-sm text-gray-400">
                    {t(label)}
                  </label>
                  <div className="overflow-hidden whitespace-normal">
                    {value}
                  </div>
                </div>
              );
            })}
        </div>
      </FileInfoSection>
      <div className="w-full h-1 bg-background-100" />
      <FileInfoSection
        title="Metadata"
        actions={
          <IconButton size="small">
            <VscAdd size={18} />
          </IconButton>
        }
      >
        <FileInfoMetadata
          file={file}
          onRemoveMetadata={(metadata) => {
            handleRemoveFileMetadata(file, metadata);
          }}
        />
      </FileInfoSection>
    </div>
  );
};

interface Props {
  title: string;
  children?: ReactNode;
  defaultExpanded?: boolean;
  actions?: ReactNode;
}

function FileInfoSection({
  title,
  defaultExpanded = true,
  children,
  actions,
}: Props) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <Fragment>
      <div className="flex items-center w-full px-4 py-4">
        <span className="flex-auto font-semibold text-left truncate text-warmGray-900">
          {title}
        </span>
        {actions}
        <IconButton
          size="small"
          className="flex-none"
          onClick={() => setExpanded(!expanded)}
        >
          {!expanded && <VscChevronRight size={18} />}
          {expanded && <VscChevronDown size={18} />}
        </IconButton>
      </div>
      {expanded && children}
    </Fragment>
  );
}

interface FileInfoMetadataProps {
  file: StorageFileDTO;
  onRemoveMetadata(metadata: QueryApi.IMetadata): void;
}
function FileInfoMetadata({ file, onRemoveMetadata }: FileInfoMetadataProps) {
  return (
    <div className="px-4">
      {file.metadata?.map((metadata: any) => {
        return (
          <div
            className="relative flex items-center gap-2 py-1 group"
            key={metadata.id}
          >
            <span className="flex-none">{metadata.key_name}:</span>
            <span className="flex-auto">{metadata.value_name}</span>
            <Tooltip title="Remove tag" arrow>
              <button
                className="absolute z-10 hidden transform -translate-y-1/2 top-1/2 right-2 group-hover:block"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onRemoveMetadata(metadata);
                }}
              >
                <VscTrash size={16} />
              </button>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
}
