/*
 * File: image-context-menu.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 1st June 2022 10:07:48 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useImageEditorContext } from "../../image-editor-context/image-editor.context";
import { useRef, MouseEvent } from "react";
import { useKeyPress } from "ahooks";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { ContextMenu } from "components/common/context-menu/context-menu-list.component";
import { ContextMenuItemData } from "components/common/context-menu/context-menu-item.component";
import { Point } from "utilities/math/point";

interface Props {
  position: Point;
  options?: ContextMenuItemData[];
  onSelect?(menuOption: ContextMenuItemData): void;
  onClose?(): void;
}
export const ImageContextMenu = ({
  options = [],
  position,
  onSelect,
  onClose,
}: Props) => {
  const { cornerstoneHandler } = useImageEditorContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useKeyPress(KeyboardKey.Escape, onClose, {
    target: containerRef.current,
  });

  function handleClose() {
    if (!cornerstoneHandler.current) return;
    if (!containerRef.current) return;
    onClose && onClose();
  }

  function handleMouseMove(event: MouseEvent) {
    if (event?.buttons === 2) handleClose();
  }

  return (
    <div
      className="absolute top-0 left-0 z-50 w-full h-full"
      ref={containerRef}
      onMouseMove={handleMouseMove}
    >
      <div className="relative w-full h-full">
        <div
          className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-5 animate-fade-in"
          onClick={handleClose}
        />

        <div
          ref={contentRef}
          style={{
            left: position.x,
            top: position.y + 4,
            minWidth: "12rem",
          }}
          className="absolute z-50 text-sm bg-white rounded shadow-lg outline-none resize text-background-700 animate-zoom-in-up"
        >
          <ContextMenu data={options || []} onSelect={onSelect} />
        </div>
      </div>
    </div>
  );
};
