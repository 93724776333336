import { PayloadAction } from "@reduxjs/toolkit";
import { ComplexJobsStoreState } from "../complex-jobs.state";
import { INITIAL_COMPLEX_JOBS_EDITOR_SETTING_STATE } from "./complex-jobs-editor-setting.state";

export const complexJobsEditorSettingActions = {
  resetComplexJobsEditorSettingState({ settings }: ComplexJobsStoreState) {
    Object.assign(settings, INITIAL_COMPLEX_JOBS_EDITOR_SETTING_STATE);
  },
  toggleComplexJobsAutoSave({ settings }: ComplexJobsStoreState) {
    settings.autoSave = !settings.autoSave;
  },
  setComplexJobsAutoSave(
    { settings }: ComplexJobsStoreState,
    action: PayloadAction<{
      enable: boolean;
      duration?: number;
    }>
  ) {
    settings.autoSave = action.payload.enable;
    settings.autoSaveDuration = action.payload.duration;
  },
};
