/*
 * File: annotation-context-menu.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 1st June 2022 10:07:48 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Annotation } from "domain/image-labeling";
import { useImageEditorContext } from "../../image-editor-context/image-editor.context";
import { useEffect, useMemo, useRef, useState } from "react";
import { Rectangle } from "utilities/math/rectangle";
import { annotationUtils } from "utilities/annotations/annotation-util";
import { Logger } from "utilities/logger";
import { useKeyPress } from "ahooks";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { useAppSelector } from "hooks/use-redux";
import { selectIsShowAnnotationToolbar } from "store/labeler/image-workspace/editor-setting/editor-setting.selectors";
import * as Sentry from "@sentry/react";

export interface LabelOption {
  name: string;
  id: number;
  disabled?: boolean;
  variant?: "danger" | "warning" | "default";
}

interface Props {
  annotation: Annotation;
  labels: LabelOption[];
  onSelect?(option: LabelOption): void;
  onClose?(): void;
}
export const AnnotationClassMenu = ({
  annotation,
  labels,
  onSelect,
  onClose,
}: Props) => {
  const { cornerstoneHandler } = useImageEditorContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState("");
  const isShowAnnotationTollbar = useAppSelector(selectIsShowAnnotationToolbar);

  function handleInput() {
    setSearch(inputRef?.current?.value || "");
  }

  const options = useMemo(() => {
    const query = search.trim().toLowerCase();
    if (!query) return labels;
    return labels.filter((lb) => lb.name.toLowerCase().includes(query));
  }, [labels, search]);

  const rect = useMemo<null | Rectangle>(() => {
    try {
      if (annotation && cornerstoneHandler.current) {
        const bounding = annotationUtils.annotationBoundingBox(annotation);
        return cornerstoneHandler.current?.canvasRectToPage(bounding);
      }
    } catch (err) {
      Sentry.captureException(err);
      Logger.log(err);
    }
    return null;
  }, [annotation, cornerstoneHandler]);

  useKeyPress(KeyboardKey.Escape, onClose, {
    target: containerRef.current,
  });

  function handleClose() {
    if (!cornerstoneHandler.current) return;
    if (!containerRef.current) return;
    if (!rect) return;
    onClose && onClose();
  }

  useEffect(() => {
    if (!rect || !contentRef.current || !contentRef.current.parentElement)
      return;
    const top = parseInt(contentRef.current.style.top);
    const paddingTop = isShowAnnotationTollbar ? 68 : 20;
    const maxTop =
      contentRef.current.parentElement?.clientHeight -
      contentRef.current.clientHeight -
      paddingTop;
    if (top > maxTop) contentRef.current.style.top = `${maxTop}px`;
    if (top < paddingTop) contentRef.current.style.top = `${paddingTop}px`;

    const left = parseInt(contentRef.current.style.left);
    const maxLeft =
      contentRef.current.parentElement?.clientWidth -
      contentRef.current.clientWidth -
      20;
    if (left > maxLeft) contentRef.current.style.left = `${maxLeft}px`;
    if (left < 20) contentRef.current.style.left = `20px`;
  }, [contentRef, rect, isShowAnnotationTollbar]);

  if (!rect) return null;

  return (
    <div
      className="absolute top-0 left-0 z-50 w-full h-full"
      ref={containerRef}
    >
      <div className="relative w-full h-full">
        <div
          className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-5 animate-fade-in"
          onClick={handleClose}
        />

        <div
          ref={contentRef}
          style={{
            left: rect.x,
            top: rect.y + rect.height + 4,
            minWidth: "12rem",
          }}
          className="absolute z-50 text-sm bg-white rounded shadow-lg outline-none resize text-background-700 animate-zoom-in-up"
        >
          <div className="py-2 overflow-y-auto max-h-80">
            <h3 className="px-4 py-2 font-bold">Change class</h3>
            <div className="px-4 py-2">
              <input
                ref={inputRef}
                type="text"
                placeholder="Search class"
                className="w-full px-4 py-2 border rounded border-background-500"
                onInput={handleInput}
              />
            </div>
            {options.map((option) => {
              const { id, name, disabled, variant } = option;
              if (disabled) {
                return (
                  <div
                    key={id}
                    className="flex items-center justify-between w-full gap-4 px-4 py-2 cursor-default flex-nowrap text-background-400"
                  >
                    <span
                      className={
                        "danger" === variant ? "text-red-500 opacity-50" : ""
                      }
                    >
                      {name}
                    </span>
                  </div>
                );
              }
              return (
                <button
                  disabled={disabled}
                  key={id}
                  className="flex items-center justify-between w-full gap-4 px-4 py-2 flex-nowrap hover:bg-primary-50 disabled:opacity-50"
                  onClick={() => onSelect && onSelect(option)}
                >
                  <span className={"danger" === variant ? "text-red-500" : ""}>
                    {name}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
