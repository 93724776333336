/*
 * File: project-table.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 10th September 2021 10:57:24 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { GridColDef } from "@material-ui/data-grid";
import { VBDatagridNoBorder } from "components/common/vb-datagrid/vb-datagrid-no-border.component";
import { getProjectTypeName, ProjectDTO } from "services/label-service/dtos";
import { formatDate } from "utilities/formatter/date-formatter.utils";
import {
  IconEdit,
  IconEye,
  IconTrash,
} from "components/common/vb-icon.component";
import { Tooltip } from "@material-ui/core";
import { useAppSelector } from "hooks/use-redux";
import { selectProjectTypeNames } from "store/common/app-setting/app-setting.selectors";
export enum ProjectAction {
  VIEW = "view",
  EDIT = "edit",
  DELETE = "delete",
}

interface Props {
  projects: ProjectDTO[];
  onSelect?(project: ProjectDTO, action?: ProjectAction): void;
}

export const ProjectTable = ({ projects, onSelect }: Props) => {
  const { t } = useTranslation();
  const projectNames = useAppSelector(selectProjectTypeNames);
  const rows = projects.map((project) => ({
    ...project,
    project,
    type: getProjectTypeName(project.type),
    createdDate: t("common:formattedDate", {
      date: formatDate(new Date(project.createdDate)),
    }),
  }));
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "id",
      minWidth: 40,
      renderCell: (params) => (
        <div
          className="cursor-pointer text-primary"
          onClick={() => onSelect && onSelect(params.row.project)}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "name",
      headerName: t("project:table.headerName"),
      flex: 1,
      minWidth: 160,
    },
    {
      field: "description",
      headerName: t("project:table.headerDescription"),
      minWidth: 160,
      flex: 1,
    },
    {
      flex: 1,
      field: "type",
      headerName: t("project:table.headerType"),
      minWidth: 200,
      renderCell: (param) => {
        const type = param?.row?.project?.type;
        if (type && projectNames.hasOwnProperty(type)) {
          return <div>{projectNames[param.row.project.type]}</div>;
        }
        return null;
      },
    },
    {
      field: "createdDate",
      headerName: t("project:table.headerCreatedDate"),
      minWidth: 200,
    },
    {
      minWidth: 160,
      field: "actions",
      headerName: t("project:table.headerActions"),
      renderCell: (params) => {
        return (
          <ProjectItemAction
            onClick={(action) =>
              onSelect && onSelect(params.row.project, action)
            }
          />
        );
      },
    },
  ];
  return (
    <VBDatagridNoBorder
      className="m-4 bg-white"
      rows={rows}
      columns={columns}
      autoPageSize
      onRowDoubleClick={(params) => onSelect && onSelect(params.row.project)}
    />
  );
};

interface ProjectItemActionProps {
  onClick?(action?: ProjectAction): void;
}

export const ProjectItemAction = ({ onClick }: ProjectItemActionProps) => {
  const actions = [
    {
      onClick: () => onClick && onClick(ProjectAction.VIEW),
      title: "View project",
      icon: <IconEye className="flex-none w-4 h-4 hover:text-primary" />,
    },
    {
      onClick: () => onClick && onClick(ProjectAction.EDIT),
      title: "Edit project",
      icon: <IconEdit className="flex-none w-4 h-4 hover:text-green-500" />,
    },
    {
      onClick: () => onClick && onClick(ProjectAction.DELETE),
      title: "Delete project",
      icon: <IconTrash className="flex-none w-4 h-4 hover:text-red-500" />,
    },
  ];
  return (
    <div className="flex items-center justify-end gap-4 px-1">
      {actions.map((action) => {
        return (
          <Tooltip key={action.title} title={action.title}>
            <button onClick={action.onClick}>{action.icon}</button>
          </Tooltip>
        );
      })}
    </div>
  );
};
