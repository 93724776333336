/*
 * File: select-users.dialog.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 24th August 2021 5:10:35 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Checkbox } from "@material-ui/core";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { UserAvatar } from "components/common/vb-user-avatar.component";
import { useWorkspaceUsers } from "hooks/workspace/use-workspace-users.hook";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserDTO } from "services/user-service/dtos/user.dto";
import { AtLeast } from "types/common";

interface Props {
  visible: boolean;
  onClose(): void;
  selectedUsers?: AtLeast<UserDTO, "email">[];
  onChange?(users: UserDTO[]): void;
}

export const SelectUsersDialog = ({
  visible,
  onClose,
  selectedUsers = [],
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const { labelers } = useWorkspaceUsers();
  const [filteredUsers, setFilteredUsers] = useState(labelers);
  const [selectedIds, setSelectedIds] = useState<string[]>(
    selectedUsers.map((user) => user.email)
  );

  const noResult = useMemo(() => {
    return labelers.length > 0 && filteredUsers.length === 0;
  }, [filteredUsers, labelers]);

  const inputRef = useRef<HTMLInputElement>(null);

  function handleInputChange(_: any) {
    if (!inputRef.current) return;
    const query = inputRef.current.value.trim().toLowerCase();
    setFilteredUsers(
      labelers.filter(
        (user) => user.email.toLocaleLowerCase().indexOf(query) !== -1
      )
    );
  }

  function toggleSelect(user: UserDTO) {
    const idx = selectedIds.indexOf(user.email);
    if (idx === -1) {
      setSelectedIds([...selectedIds, user.email]);
    } else {
      setSelectedIds([...selectedIds.filter((email) => email !== user.email)]);
    }
  }

  function handleSaveChanges() {
    const updatedUsers = labelers.filter((user) =>
      selectedIds.includes(user.email)
    );
    onChange && onChange(updatedUsers);
  }

  useEffect(() => {
    setFilteredUsers(labelers);
  }, [labelers]);

  return (
    <VBModal
      title={t("project:batchDetails.batchWorkflow.textSelectLabelers")}
      open={visible}
      onClose={onClose}
      onSubmit={handleSaveChanges}
      textSubmit={t("common:buttonOk")}
      width="32rem"
    >
      <div
        className="flex-grow overflow-y-auto"
        style={{ minHeight: "20rem", maxHeight: "80vh" }}
      >
        <div>
          <input
            type="text"
            placeholder={t(
              "project:batchDetails.batchWorkflow.placeholderSearchLabeler"
            )}
            autoFocus
            ref={inputRef}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded focus:border-primary"
          />
        </div>

        {noResult && (
          <p className="p-6 text-center">
            {t("project:batchDetails.batchWorkflow.noSearchResult")}
          </p>
        )}

        <div className="my-4">
          {filteredUsers.map((user) => {
            const selected = selectedIds.indexOf(user.email) !== -1;
            return (
              <button
                key={user.email}
                className="flex items-center w-full py-2 border-b"
                onClick={() => toggleSelect(user)}
              >
                <Checkbox color="primary" checked={selected} />
                <UserAvatar
                  name={user.email || ""}
                  className="flex-none w-10 h-10 mx-2"
                />
                <div className="flex-auto mx-2 text-left">
                  <div>{user.email}</div>
                  <div className="text-sm text-gray-400">
                    {user.role
                      .replaceAll("[", "")
                      .split("]")
                      .filter((s) => s.trim())
                      .join(",")}
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </VBModal>
  );
};
