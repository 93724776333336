/*
 * File: workflows.page.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBCreateButton } from "components/common/vb-create-button.component";
import { VBEmptyContent } from "components/common/vb-empty-content.component";
import { VBPageTitle } from "components/common/vb-page-title.component";
import { VBSpacer } from "components/common/vb-spacer.component";
import { useAppDispatch } from "hooks/use-redux";
import { useWorkflow } from "hooks/workflow/use-workflow.hook";
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { WorkflowDTO } from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { deleteWorkflowAsync } from "store/customer/workflow/workflow.thunk";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { EditWorkflowModal } from "./components/edit-workflow.modal";
import { LinearProgress } from "@material-ui/core";
import { WorkspaceWorflowsTable } from "./components/workflows-table/workflows-table.component";
import { Workflow, WorkflowStatus } from "domain/customer";
import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import * as Sentry from "@sentry/react";

export const WorkflowsPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { workflows, requestStatus } = useWorkflow();
  const [deletingtItem, setDeletingItem] = useState<Workflow | null>(null);
  const [processing, setProcessing] = useState(false);
  const [currentWorkflow, setCurrentWorkflow] = useState<WorkflowDTO | null>(
    null
  );
  function handleCreateWorkflow() {
    history.push(Routes.WORKFLOWS_CREATE_NEW);
  }

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  function handleSelect(action: string, workflow: Workflow) {
    if (action === "delete") {
      return setDeletingItem(workflow);
    }
    if (action === "edit") {
      const item = workflows.find((w) => w.id === workflow.id);
      if (item) setCurrentWorkflow(item);
      return;
    }
    history.push(`${url}/${workflow.id}`);
  }

  async function handleDeleteItem() {
    if (!deletingtItem) return;
    if (processing) return;
    try {
      setProcessing(true);
      const response = await dispatch(deleteWorkflowAsync(deletingtItem.id));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textDeletedSuccess")));
      setDeletingItem(null);
      setProcessing(false);
    } catch (error: any) {
      Sentry.captureException(error);
      const message = error.message || t("common:textDeletedFailed");
      dispatch(enqueueErrorNotification(message));
    }
  }
  const requesting = requestStatus === RequestStatus.LOADING;
  const isLoaded = requestStatus === RequestStatus.SUCCESS;
  const isEmpty = isLoaded && workflows.length === 0;

  return (
    <div className="relative flex flex-col h-full p-4">
      {requesting && (
        <div className="absolute top-0 left-0 right-0 z-10">
          <LinearProgress />
        </div>
      )}

      <div className="flex items-center flex-shrink-0 h-16 p-4">
        <VBPageTitle text={t("workflow:pageTitle")} />
        <VBSpacer />
        {!isEmpty && (
          <VBCreateButton
            text={t("workflow:buttonCreateWorkflow")}
            onClick={handleCreateWorkflow}
          />
        )}
      </div>
      {!isEmpty && (
        <div className="flex flex-col flex-grow gap-6">
          {/* <WorkflowTable
            loading={requestStatus === RequestStatus.LOADING}
            workflows={workflows}
            onSelect={handleSelect}
          /> */}

          <div className="flex-grow">
            <WorkspaceWorflowsTable
              onRowAction={handleSelect}
              rows={workflows.map((workflow) => {
                return {
                  id: workflow.id,
                  name: workflow.name,
                  description: workflow.description,
                  createdAt: new Date(workflow.createdDate),
                  numberOfSteps: 0,
                  status: WorkflowStatus.FREE,
                  createdBy: workflow.createdBy,
                };
              })}
            />
          </div>
          <div className="flex-none">
            <GridPagination
              page={page}
              pageSize={pageSize}
              pageSizeOptions={[10, 20, 25, 50, 75, 100]}
              onPageSizeChange={setPageSize}
              onPageChange={setPage}
              totalItems={workflows.length}
            />
          </div>
        </div>
      )}

      <EditWorkflowModal
        visible={currentWorkflow !== null}
        workflow={currentWorkflow}
        onClose={() => setCurrentWorkflow(null)}
      />

      {deletingtItem && (
        <VBModal
          open={!!deletingtItem}
          onSubmit={handleDeleteItem}
          onClose={() => setDeletingItem(null)}
          textSubmit={t("common:buttonDelete")}
          title={t("workflow:deleteDialog.title")}
          processingIndicator={processing}
          disableSubmit={processing}
          blockUI={processing}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t("workflow:deleteDialog.description", {
                name: deletingtItem.name,
              }),
            }}
          ></div>
        </VBModal>
      )}

      <VBEmptyContent
        visible={isEmpty}
        iconClass="uir-document-signed"
        title={t("workflow:empty.title")}
        message={t("workflow:empty.message")}
        ctaText={t("workflow:empty.textCreate")}
        createIfNotExist
        onCTAClick={handleCreateWorkflow}
      />
    </div>
  );
};
