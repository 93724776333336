/**
 * WARNING:
 * This is a patched version of https://github.com/cornerstonejs/cornerstoneTools/blob/master/src/manipulators/moveAllHandles.js
 * Only work with HeartRulerTool
 * Proceed with cautious!
 */
import csTools from 'cornerstone-tools';
import { removeToolState } from 'cornerstone-tools';
import HeartRulerTool from '../HeartRuler';
import getActiveTool from './getActiveTool';

const EVENTS = csTools.EVENTS;
const anyHandlesOutsideImage = csTools.importInternal('manipulators/anyHandlesOutsideImage');
const triggerEvent = csTools.importInternal('util/triggerEvent');
const clipToBox = csTools.importInternal('util/clipToBox');
const { state } = csTools.store;
const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const { modules, getModule } = csTools.store;
const manipulatorStateModule = modules.manipulatorState;

const _dragEvents = {
  mouse: [EVENTS.MOUSE_DRAG],
  touch: [EVENTS.TOUCH_DRAG]
};

const _upOrEndEvents = {
  mouse: [EVENTS.MOUSE_UP, EVENTS.MOUSE_CLICK],
  touch: [
    EVENTS.TOUCH_END,
    EVENTS.TOUCH_DRAG_END,
    EVENTS.TOUCH_PINCH,
    EVENTS.TOUCH_PRESS,
    EVENTS.TAP
  ]
};

/**
 * Manipulator to move all provided handles at the same time
 * @public
 * @function moveAllHandles
 * @memberof Manipulators
 *
 * @param {*}        evtDetail
 * @param {*}        evtDetail.element
 * @param {String}   toolName
 * @param {*}        annotation
 * @param {*}        [handle=null] - not needed by moveAllHandles, but keeps call signature the same as `moveHandle`
 * @param {Object}   [options={}]
 * @param {Boolean}  [options.deleteIfHandleOutsideImage]
 * @param {Boolean}  [options.preventHandleOutsideImage]
 * @param {string}   [interactionType=mouse]
 * @param {function} [doneMovingCallback]
 * @returns {undefined}
 */
export default function(
  { element },
  toolName,
  annotation,
  handle,
  options = {},
  interactionType = 'mouse',
  doneMovingCallback
) {
  // Use global defaults, unless overidden by provided options
  options = Object.assign(
    {
      deleteIfHandleOutsideImage: state.deleteIfHandleOutsideImage,
      preventHandleOutsideImage: state.preventHandleOutsideImage
    },
    options
  );

  const dragHandler = _dragHandler.bind(this, toolName, annotation, options, interactionType);
  // So we don't need to inline the entire `upOrEndHandler` function
  const upOrEndHandler = evt => {
    _upOrEndHandler(
      toolName,
      annotation,
      options,
      interactionType,
      {
        dragHandler,
        upOrEndHandler
      },
      evt,
      doneMovingCallback
    );
  };

  manipulatorStateModule.setters.addActiveManipulatorForElement(
    element,
    _cancelEventHandler.bind(
      null,
      annotation,
      options,
      interactionType,
      {
        dragHandler,
        upOrEndHandler
      },
      element,
      doneMovingCallback
    )
  );

  annotation.active = true;
  state.isToolLocked = true;

  // Add Event Listeners
  _dragEvents[interactionType].forEach(eventType => {
    element.addEventListener(eventType, dragHandler);
  });
  _upOrEndEvents[interactionType].forEach(eventType => {
    element.addEventListener(eventType, upOrEndHandler);
  });
}

function _dragHandler(toolName, annotation, options = {}, interactionType, evt) {
  const { element, image, buttons } = evt.detail;
  const { x, y } = evt.detail.deltaPoints.canvas;
  const { width } = evt.target.firstChild;

  // check if mouse outside of canvas
  const xImage = evt.detail.currentPoints.canvas.x;
  const mouseOutsideCanvas = xImage < 0 || xImage > width;

  annotation.active = true;
  annotation.invalidated = true;

  const handleKeys = Object.keys(annotation.handles);

  for (let i = 0; i < handleKeys.length; i++) {
    const key = handleKeys[i];
    const handle = annotation.handles[key];

    if (
      // Don't move this part of the annotation
      handle.movesIndependently === true ||
      // Not a true handle
      !handle.hasOwnProperty('x') ||
      !handle.hasOwnProperty('y')
    ) {
      continue;
    }

    if (
      !mouseOutsideCanvas &&
      annotation.type === 'ruler' &&
      handle.x + x < 3 * width &&
      handle.x + x > -2 * width
    ) {
      handle.x += x;
    }

    if (options.preventHandleOutsideImage) {
      clipToBox(handle, image);
    }
  }

  csTools.external.cornerstone.updateImage(element);

  const activeTool = getActiveTool(element, buttons, interactionType);

  if (activeTool instanceof BaseAnnotationTool) {
    activeTool.updateCachedStats(image, element, annotation);
  }

  const eventType = EVENTS.MEASUREMENT_MODIFIED;
  const modifiedEventData = {
    toolName,
    toolType: toolName, // Deprecation notice: toolType will be replaced by toolName
    element,
    measurementData: annotation
  };

  triggerEvent(element, eventType, modifiedEventData);

  evt.preventDefault();
  evt.stopPropagation();
}

function _cancelEventHandler(
  annotation,
  options = {},
  interactionType,
  { dragHandler, upOrEndHandler },
  element,
  doneMovingCallback
) {
  _endHandler(
    annotation,
    options,
    interactionType,
    {
      dragHandler,
      upOrEndHandler
    },
    element,
    doneMovingCallback,
    false
  );
}

function _upOrEndHandler(
  toolName,
  annotation,
  options = {},
  interactionType,
  { dragHandler, upOrEndHandler },
  evt,
  doneMovingCallback
) {
  const eventData = evt.detail;
  const { element } = eventData;

  manipulatorStateModule.setters.removeActiveManipulatorForElement(element);

  // If any handle is outside the image, delete the tool data
  if (options.deleteIfHandleOutsideImage
    && anyHandlesOutsideImage(eventData, annotation.handles)
    && toolName !== 'HeartRuler') {
    removeToolState(element, toolName, annotation);
  }

  _endHandler(
    annotation,
    options,
    interactionType,
    {
      dragHandler,
      upOrEndHandler
    },
    element,
    doneMovingCallback,
    true
  );
}

function _endHandler(
  annotation,
  options = {},
  interactionType,
  { dragHandler, upOrEndHandler },
  element,
  doneMovingCallback,
  success = true
) {
  annotation.active = false;
  annotation.invalidated = true;
  state.isToolLocked = false;

  // Remove Event Listeners
  _dragEvents[interactionType].forEach(eventType => {
    element.removeEventListener(eventType, dragHandler);
  });
  _upOrEndEvents[interactionType].forEach(eventType => {
    element.removeEventListener(eventType, upOrEndHandler);
  });

  if (typeof options.doneMovingCallback === 'function') {
    options.doneMovingCallback(success);
  }

  if (typeof doneMovingCallback === 'function') {
    doneMovingCallback(success);
  }

  csTools.external.cornerstone.updateImage(element);
}
