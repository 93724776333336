/*
 * File: batch.dto.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 22nd July 2021 9:19:29 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BaseDTO } from "./base.dto";
import { ProjectDTO } from "./project.dto";

export interface ActiveLearningSetting {
  enable: boolean;
  ratio?: number;
  enableGrowTest?: boolean;
  pickedDataMode?: string;
}

export interface BatchSetting {
  activeLearning?: ActiveLearningSetting;
  balanceJobAssign?: boolean;
  timeLimitSecond: number;
  consensusRate: number;
  consensusEntity?: number;
  consensusRelationship?: number;
}
export interface BatchDTO extends BaseDTO {
  description: string;
  instruction: string;
  type: BatchType;
  name: string;
  status: string;
  datasetId: number;
  project: ProjectDTO;
  batchSetting?: BatchSetting;
  dueDate?: string;
  embeddedType?: string;
  embeddedContent?: string;
  preAssignedTask?: number;
  workspaceId: string;
}

export enum BatchStatus {
  INITIAL = "initial",
  WORKING = "working",
  ON_HOLD = "on-hold",
  COMPLETED = "completed",
  APPROVED = "approved",
  REOPEN = "re-open",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  INREVIEW = "in-review",
  DELIVERED = "delivered",
  LOCKED = "locked",
}

export enum BatchType {
  NORMAL = "NORMAL",
  EVALUATION = "EVALUATION",
}

export enum EmbeddedType {
  PDF = "PDF",
  HTML = "HTML",
}

export const DEFAULT_CONSENSUS_RATE = 100;
export const DEFAULT_TIME_LIMIT_SECOND = 10;
export const DEFAULT_CONSENSUS_ENTITY = 1;
export const DEFAULT_CONSENSUS_RELATION = 1;
export const MIN_TIME_LIMIT_SECOND = 0;
export const MAX_TIME_LIMIT_SECOND = 60;
