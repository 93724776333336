/*
 * File: text-conflict.actions.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 9th August 2022 10:15:25 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { collectionUtils } from "domain/common";
import { TextWorkspaceState } from "../text-workspace.state";
import { textUtils } from "../utils/text-labeling.utils";
import { INITIAL_TEXT_CONFLICT_STATE } from "./text-conflict.state";

export const textConflictActions = {
  resetTextConflictState({ textConflict }: TextWorkspaceState) {
    Object.assign(textConflict, INITIAL_TEXT_CONFLICT_STATE);
  },
  textConflictAnnotatorSelected(
    { textConflict }: TextWorkspaceState,
    action: PayloadAction<string>
  ) {
    const annotator = action.payload;
    if (textConflict.selectedAnnotators.includes(annotator)) {
      textConflict.selectedAnnotators = textConflict.selectedAnnotators.filter(
        (anno) => anno !== annotator
      );
    } else {
      textConflict.selectedAnnotators.push(annotator);
    }
  },
  textConflictAnnotationSelected(
    { textConflict }: TextWorkspaceState,
    action: PayloadAction<string>
  ) {
    textConflict.selectedAnnotationId = action.payload;
  },

  textConflictAcceptAll({ textConflict, textEditor }: TextWorkspaceState) {
    const { annotations, relationAnnotations, selectedAnnotators } =
      textConflict;
    for (const annotationId of annotations.allIds) {
      const annotation = collectionUtils.getOne(annotations, annotationId);
      if (!annotation?.annotators?.find((a) => selectedAnnotators?.includes(a)))
        continue;
      const id = (annotationId as string).replace("review-", "");
      if (!annotation || textEditor.observations.find((ob) => ob.id === id)) {
        continue;
      }
      annotation.reviewStatus = "accepted";
      textEditor.observations.push({
        id: id,
        observationId: annotation.observationId,
        startIndex: annotation.startIndex,
        endIndex: annotation.endIndex,
        source: annotation.id,
      });
    }
    for (const annotationId of relationAnnotations.allIds) {
      const relation = collectionUtils.getOne(
        relationAnnotations,
        annotationId
      );
      const id = (annotationId as string).replace("review-", "");
      if (!relation?.annotators?.find((a) => selectedAnnotators?.includes(a)))
        continue;
      if (!relation || textEditor.relations.find((ob) => ob.id === id)) {
        continue;
      }
      relation.reviewStatus = "accepted";
      textEditor.relations.push({
        id: id,
        text: relation.text,
        from: relation.from.replace("review-", ""),
        to: relation.to.replace("review-", ""),
        observationId: relation.observationId,
        color: relation.color,
        source: relation.id,
      });
    }
  },
  textConflictAcceptAllEntities({
    textConflict,
    textEditor,
  }: TextWorkspaceState) {
    const { annotations, selectedAnnotators } = textConflict;
    for (const annotationId of annotations.allIds) {
      const annotation = collectionUtils.getOne(annotations, annotationId);
      if (!annotation?.annotators?.find((a) => selectedAnnotators?.includes(a)))
        continue;
      const id = (annotationId as string).replace("review-", "");
      if (!annotation || textEditor.observations.find((ob) => ob.id === id)) {
        continue;
      }
      annotation.reviewStatus = "accepted";
      textEditor.observations.push({
        id: id,
        observationId: annotation.observationId,
        startIndex: annotation.startIndex,
        endIndex: annotation.endIndex,
        source: annotation.id,
      });
    }
  },
  textConflictAcceptAllRelations({
    textConflict,
    textEditor,
  }: TextWorkspaceState) {
    const { annotations, relationAnnotations, selectedAnnotators } =
      textConflict;
    for (const annotationId of relationAnnotations.allIds) {
      const relation = collectionUtils.getOne(
        relationAnnotations,
        annotationId
      );
      if (!relation?.annotators?.find((a) => selectedAnnotators?.includes(a)))
        continue;
      const id = (annotationId as string).replace("review-", "");
      if (!relation || textEditor.relations.find((ob) => ob.id === id)) {
        continue;
      }
      relation.reviewStatus = "accepted";
      textEditor.relations.push({
        id: id,
        text: relation.text,
        from: relation.from.replace("review-", ""),
        to: relation.to.replace("review-", ""),
        observationId: relation.observationId,
        color: relation.color,
        source: relation.id,
      });
      const fromId = relation.from.replace("review-", "");
      const toId = relation.to.replace("review-", "");
      const from = collectionUtils.getOne(annotations, relation.from);
      const to = collectionUtils.getOne(annotations, relation.to);
      if (from && !textEditor.observations.find((ob) => ob.id === fromId)) {
        from.reviewStatus = "accepted";
        textEditor.observations.push({
          id: fromId,
          observationId: from.observationId,
          startIndex: from.startIndex,
          endIndex: from.endIndex,
          source: relation.id,
        });
      }
      if (to && !textEditor.observations.find((ob) => ob.id === toId)) {
        to.reviewStatus = "accepted";
        textEditor.observations.push({
          id: toId,
          observationId: to.observationId,
          startIndex: to.startIndex,
          endIndex: to.endIndex,
          source: relation.id,
        });
      }
    }
  },
  textConflictAcceptAgreeAnnotations({
    textConflict,
    textEditor,
  }: TextWorkspaceState) {
    const { annotations, relationAnnotations, selectedAnnotators } =
      textConflict;
    const annoIds: string[] = [];
    for (const annotationId of relationAnnotations.allIds) {
      const relation = collectionUtils.getOne(
        relationAnnotations,
        annotationId
      );
      if (!relation?.annotators?.find((a) => selectedAnnotators?.includes(a)))
        continue;
      if (textUtils.isConflict(relation?.annotators || [], selectedAnnotators))
        continue;
      const id = (annotationId as string).replace("review-", "");
      if (!relation || textEditor.relations.find((ob) => ob.id === id)) {
        continue;
      }
      relation.reviewStatus = "accepted";
      annoIds.push(relation.from, relation.to);
      textEditor.relations.push({
        id: id,
        text: relation.text,
        from: relation.from.replace("review-", ""),
        to: relation.to.replace("review-", ""),
        observationId: relation.observationId,
        color: relation.color,
        source: relation.id,
      });
    }

    for (const annotationId of annotations.allIds) {
      const annotation = collectionUtils.getOne(annotations, annotationId);
      if (!annotation?.annotators?.find((a) => selectedAnnotators?.includes(a)))
        continue;
      const id = (annotationId as string).replace("review-", "");
      if (!annotation || textEditor.observations.find((ob) => ob.id === id)) {
        continue;
      }
      if (
        !annoIds.includes(annotationId as string) &&
        textUtils.isConflict(annotation?.annotators || [], selectedAnnotators)
      )
        continue;
      annotation.reviewStatus = "accepted";
      textEditor.observations.push({
        id: id,
        observationId: annotation.observationId,
        startIndex: annotation.startIndex,
        endIndex: annotation.endIndex,
        source: annotation.id,
      });
    }
  },
  textConflictAcceptConflictAnnotations({
    textConflict,
    textEditor,
  }: TextWorkspaceState) {
    const { annotations, relationAnnotations, selectedAnnotators } =
      textConflict;
    const annoIds: string[] = [];
    for (const annotationId of relationAnnotations.allIds) {
      const relation = collectionUtils.getOne(
        relationAnnotations,
        annotationId
      );
      if (!relation?.annotators?.find((a) => selectedAnnotators?.includes(a)))
        continue;
      if (!textUtils.isConflict(relation?.annotators || [], selectedAnnotators))
        continue;
      const id = (annotationId as string).replace("review-", "");
      if (!relation || textEditor.relations.find((ob) => ob.id === id)) {
        continue;
      }
      relation.reviewStatus = "accepted";
      annoIds.push(relation.from, relation.to);
      textEditor.relations.push({
        id: id,
        text: relation.text,
        from: relation.from.replace("review-", ""),
        to: relation.to.replace("review-", ""),
        observationId: relation.observationId,
        color: relation.color,
        source: relation.id,
      });
    }

    for (const annotationId of annotations.allIds) {
      const annotation = collectionUtils.getOne(annotations, annotationId);
      if (!annotation?.annotators?.find((a) => selectedAnnotators?.includes(a)))
        continue;
      const id = (annotationId as string).replace("review-", "");
      if (!annotation || textEditor.observations.find((ob) => ob.id === id)) {
        continue;
      }
      if (
        !annoIds.includes(annotationId as string) &&
        !textUtils.isConflict(annotation?.annotators || [], selectedAnnotators)
      )
        continue;
      annotation.reviewStatus = "accepted";
      textEditor.observations.push({
        id: id,
        observationId: annotation.observationId,
        startIndex: annotation.startIndex,
        endIndex: annotation.endIndex,
        source: annotation.id,
      });
    }
  },
};
