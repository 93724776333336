/*
 * File: create-task-button.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 24th August 2021 1:53:00 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useDatasetDetailContext } from "pages/customer/datasets/dataset-detail/dataset-detail.context";
import { useState, useMemo } from "react";
import { CreateTaskPayload } from "services/label-service/apis/task.api";
import { BatchStatus, TaskStatus } from "services/label-service/dtos";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { classnames } from "utilities/classes";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { selectBatchInfo } from "store/customer/batch/batch.selectors";
import {
  createTasksAsync,
  loadTasksAsync,
} from "store/customer/batch/batch.thunk";
import * as Sentry from "@sentry/react";

export const CreateTaskButton = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedFiles, setSelectedFiles, files } = useDatasetDetailContext();
  const currentBatch = useAppSelector(selectBatchInfo);
  const [processing, setProcessing] = useState(false);
  const active = useMemo(() => {
    return (
      !processing &&
      !!selectedFiles.length &&
      !!currentBatch &&
      (currentBatch.status === BatchStatus.INITIAL ||
        currentBatch.status === BatchStatus.WORKING ||
        currentBatch.status === BatchStatus.ON_HOLD)
    );
  }, [processing, selectedFiles, currentBatch]);

  async function buttonPressed() {
    if (processing) return;
    setProcessing(true);
    try {
      if (!currentBatch) return;
      if (!selectedFiles.length) return;
      const taskFiles = files
        .filter((file) => selectedFiles.indexOf(file.id) !== -1)
        .map((file) => ({
          storeType: file.storeType,
          url: file.url,
          taskReference: file.id.toString(),
        }));
      const payload: CreateTaskPayload[] = taskFiles.map((file) => {
        return {
          batch: currentBatch,
          status: TaskStatus.INITIAL,
          ...file,
        };
      });
      const response = await dispatch(createTasksAsync(payload));
      handleThunkRejected(response);
      const options = {
        "batchId.equals": currentBatch.id.toString(),
      };
      await dispatch(loadTasksAsync({ options }));
      dispatch(enqueueSuccessNotification(t("common:textCreatedSuccess")));
      setSelectedFiles([]);
    } catch (err) {
      Sentry.captureException(err);
      Logger.log("err", err);
      dispatch(enqueueErrorNotification(t("common:textCreatedFailed")));
    } finally {
      setProcessing(false);
    }
  }

  return (
    <button
      className={classnames("button-primary", {
        "opacity-60 cursor-not-allowed": !active,
      })}
      disabled={processing || !active}
      onClick={buttonPressed}
    >
      <i className="uir-list" />
      <div className="">{t("project:batchDetails.buttonCreateTask")}</div>
    </button>
  );
};
