/*
 * File: customer-router.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 13th August 2021 10:52:42 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Switch } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { PrivateRoute } from "routers/common/private-route";
import { PublicRoute } from "routers/common/public-route";
import { NotFoundPage } from "pages/authorization/not-found/not-found.page";
import { ProfilePage } from "pages/authorization/profile/profile.page";
import { SignInPage } from "pages/authorization/sign-in/sign-in.page";
import { UnAuthorizedPage } from "pages/authorization/unauthorized/unauthorized.page";
import { DashboardPage } from "pages/customer/dashboard/dashboard.page";
import { DatasetsRouter } from "pages/customer/datasets/router/datasets.router";
import { WorkspaceUsersPage } from "pages/customer/users/workspace-users.page";
import { ProjectRouter } from "pages/customer/projects/project.router";
import { WorkflowRouter } from "pages/customer/workflows/workflow.router";
import { ManageWorkspacePage } from "pages/customer/workspace/workspace.page";
import { MLModelsPage } from "pages/customer/ml-models/ml-models.page";
import { NotificationsPage } from "pages/customer/notifications/notifications.page";
import { WorkspaceUsersProvider } from "pages/customer/users/context/workspace-users.provider";
import { DashboardProvider } from "pages/customer/dashboard/context/dashboard.provider";
import { WebBuilderProvider } from "pages/customer/web-builder/context/web-builder.provider";
import { WebBuilderPage } from "pages/customer/web-builder/web-builder.page";
import { WebBuilderPreviewProvider } from "pages/customer/web-builder-preview/context/web-builder-preview.provider";
import { MLWebBuilderPreviewPage } from "pages/customer/web-builder-preview/web-builder-preview.page";

export function CustomerRouter() {
  return (
    <>
      <Switch>
        <PrivateRoute
          exact
          path={Routes.ROOT}
          component={() => (
            <DashboardProvider>
              <DashboardPage />
            </DashboardProvider>
          )}
        />
        <PrivateRoute path={Routes.PROFILE} component={ProfilePage} />
        <PrivateRoute path={Routes.PROJECTS} component={ProjectRouter} />
        <PrivateRoute path={Routes.DATASETS} component={DatasetsRouter} />
        <PrivateRoute
          path={Routes.MANAGE_WORKSPACE}
          component={ManageWorkspacePage}
        />
        <PrivateRoute path={Routes.WORKFLOWS} component={WorkflowRouter} />
        <PrivateRoute
          path={Routes.MEMBERS}
          component={() => (
            <WorkspaceUsersProvider>
              <WorkspaceUsersPage />
            </WorkspaceUsersProvider>
          )}
        />
        <PrivateRoute path={Routes.MLMODELS} component={MLModelsPage} />
        <PrivateRoute
          exact
          path={Routes.WEB_BUILDER}
          component={() => (
            <WebBuilderProvider>
              <WebBuilderPage />
            </WebBuilderProvider>
          )}
        />
        <PrivateRoute
          exact
          path={Routes.WEB_BUILDER_PREVIEW}
          component={() => (
            <WebBuilderPreviewProvider>
              <MLWebBuilderPreviewPage />
            </WebBuilderPreviewProvider>
          )}
        />
        <PrivateRoute
          path={Routes.NOTIFICATIONS}
          component={NotificationsPage}
        />
        <PublicRoute path={Routes.UNAUTHORIZED} component={UnAuthorizedPage} />
        <PublicRoute path={Routes.SIGN_IN} component={SignInPage} />
        <PublicRoute path={Routes.NOT_FOUND} component={NotFoundPage} />
        <PublicRoute path="*" component={NotFoundPage} />
      </Switch>
    </>
  );
}
