/*
 * File: object-item.tsx
 * Project: aiscaler-web-monorepo
 * File Created: Wednesday, 16th November 2022 9:00:08 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */
import { MouseEvent, useState } from "react";
import clsx from "clsx";
import { TreeItem } from "./use-object-tree";
import {
  IconArrowRight,
  IconRouting,
} from "components/common/vb-icon.component";
import { Eye, EyeSlash } from "iconsax-react";
import { BsBoundingBox } from "react-icons/bs";
import { TbPolygon } from "react-icons/tb";
import { MdMoreVert } from "react-icons/md";

interface ObjectItemProps extends TreeItem {
  level?: number;
}
export default function ObjectItem(props: ObjectItemProps) {
  const {
    id,
    type,
    name,
    description,
    color = "white",
    selected,
    children,
    checked,
    hidden,
    level = 0,
  } = props;
  const hasChildren = children && children.length > 0;
  const isClassification = type === "classification";
  const isBBox = type === "bbox";
  const isPolygon = type === "polygon";
  const isRelation = type === "relation";
  const [expanded, setExpanded] = useState(false);

  function dispatchEvent(event: MouseEvent, action: string) {
    const payload = { detail: { event, action, id, item: props } };
    const customEvent = new CustomEvent("UI_BUTTON_CLICK", payload);
    window.dispatchEvent(customEvent);
  }

  function dispatchUIEvent(event: MouseEvent, action: string) {
    const payload = { detail: { event, action, id, item: props } };
    const customEvent = new CustomEvent("UI_EVENT", payload);
    window.dispatchEvent(customEvent);
  }

  function handleToggleExpand(event: MouseEvent) {
    setExpanded(!expanded);
    event.preventDefault();
  }

  function handleSelect(event: MouseEvent) {
    dispatchEvent(event, "TREE_ITEM_SELECT");
  }

  function handleToggleVisible(event: MouseEvent) {
    dispatchEvent(event, "TREE_ITEM_TOGGLE_VISIBLE");
  }

  function handleSelectMenu(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    dispatchEvent(event, "TREE_ITEM_SELECT_MENU");
    if (!isClassification) handleSelect(event);
  }

  function handleMouseEnter(event: MouseEvent) {
    if (hidden) return;
    dispatchUIEvent(event, "TREE_ITEM_MOUSE_ENTER");
  }

  function handleMouseLeave(event: MouseEvent) {
    if (hidden) return;
    dispatchUIEvent(event, "TREE_ITEM_MOUSE_LEAVE");
  }

  function handleOnChange() {}

  return (
    <li>
      <div
        onClick={handleSelect}
        className={clsx(
          "flex items-center gap-2 px-2 py-2 text-white relative group hover:outline hover:outline-blue cursor-pointer",
          {
            "bg-black bg-opacity-40": selected,
          }
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onContextMenu={handleSelectMenu}
      >
        <span style={{ width: level * 16 }} />
        {hasChildren && (
          <button
            onClick={handleToggleExpand}
            className="flex items-center justify-center w-4 h-4"
          >
            <IconArrowRight
              className={clsx("w-3.5 h-3.5", {
                "transform rotate-90": expanded,
              })}
            />
          </button>
        )}
        {isClassification && (
          <input
            type="checkbox"
            className="w-4 h-4 rounded pointer-events-none form-checkbox"
            checked={!!checked}
            onChange={handleOnChange}
          />
        )}

        {isPolygon && (
          <div style={{ color: color }}>
            <TbPolygon className="w-6 h-6" />
          </div>
        )}

        {isBBox && (
          <div style={{ color: color }}>
            <BsBoundingBox size={18} />
          </div>
        )}

        {isRelation && (
          <div style={{ color: color }}>
            <IconRouting className="w-4 h-4" />
          </div>
        )}

        <div className="flex-auto overflow-hidden text-sm truncate overflow-ellipsis">
          <div>{name}</div>
          {description && (
            <div className="pt-1 text-xs text-gray-400">{description}</div>
          )}
        </div>

        <div className="flex items-center h-full">
          <button
            onClick={handleToggleVisible}
            className="flex items-center justify-center w-6 h-6"
          >
            {hidden && <EyeSlash size={14} />}
            {!hidden && <Eye size={14} className="hidden group-hover:block" />}
          </button>
          <button
            onClick={handleSelectMenu}
            className="flex items-center justify-center w-6 h-6"
          >
            <MdMoreVert size={14} />
          </button>
        </div>
      </div>

      {expanded && children && children.length > 0 && (
        <ul
          className={clsx("space-y-1 py-1", {
            "bg-black bg-opacity-20": selected,
          })}
        >
          {children.map((item) => {
            return <ObjectItem key={item.id} {...item} level={level + 1} />;
          })}
        </ul>
      )}
    </li>
  );
}
