import { VBNumberTextInputComponent } from "components/design-system/number-text-input/number-text-input.component";
import { MlModelsTuningParams } from "../ml-models.models";
interface Props {
  value: MlModelsTuningParams;
  onChanged: (v: MlModelsTuningParams) => void;
}
export const TuningParamsComponent = ({ value, onChanged }: Props) => {
  const handleFieldChanged = (field: keyof MlModelsTuningParams, v: any) => {
    onChanged({ ...value, [field]: v });
  };

  return (
    <div className="flex gap-2 flex-wrap">
      <div className="flex gap-2 items-center flex-grow">
        <div className="flex-grow">
          <VBNumberTextInputComponent
            height="32px"
            label="Epochs"
            type="number"
            listenChangedDefault
            defaultValue={value.epochs}
            onValueChanged={(v) => handleFieldChanged("epochs", v)}
          />
        </div>
        <div className="flex-grow">
          <VBNumberTextInputComponent
            height="32px"
            label="Learning rate"
            type="number"
            listenChangedDefault
            defaultValue={value.learningRate}
            onValueChanged={(v) => handleFieldChanged("learningRate", v)}
          />
        </div>
      </div>
      <div className="w-full">
        <VBNumberTextInputComponent
          height="32px"
          label="Learning rate backbone"
          type="number"
          listenChangedDefault
          defaultValue={value.learningRateBackbone}
          onValueChanged={(v) => handleFieldChanged("learningRateBackbone", v)}
        />
      </div>
    </div>
  );
};
