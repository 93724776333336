import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useAppDispatch } from "hooks/use-redux";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { useThreeDLabelingContext } from "../context/three-d-labeling.context";
import { blobToVTKImage } from "../utils";
import { ThreeDApplyConfirmModal } from "./three-d-apply-confirm-modal";
import {
  EDITOR_LABEL_NONE,
  EditorLabel,
  LabelMapOption,
} from "./three-d-editor.models";
import { useThreeDEditorContext } from "./three-d-editor.provider";
import {
  addToVTKObjects,
  releaseVTKObjects,
  VTK_OBJECT_TAG_IMAGE_DATA,
} from "./vtk-objects-manager";
import * as Sentry from "@sentry/react";

export const ThreeDEditorLabelMapOptionsComponent = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const {
    annotator,
    editorContext,
    loadLabelMap,
    copyLabelMap,
    setIsShowViewLabels,
    setViewLabels,
    setActiveLabel,
    setViewMetadata,
    viewLabels,
    setActiveTool,
    setLabels,
  } = useThreeDEditorContext();
  const { saveJob } = useThreeDLabelingContext();

  const [options, setOptions] = useState<
    { label: string; value: LabelMapOption }[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [isProcessing, setIsProcessing] = useState(false);
  const canCopyResult = useMemo(() => {
    return !isProcessing && selectedOption && selectedOption.value;
  }, [isProcessing, selectedOption]);

  const vtkImageDataTagRef = useRef(
    `${VTK_OBJECT_TAG_IMAGE_DATA}_label_options`
  );

  useEffect(() => {
    releaseVTKObjects(vtkImageDataTagRef.current);
    if (!editorContext) return;

    const { labelMapOptions } = editorContext;
    if (labelMapOptions) {
      setOptions(
        labelMapOptions.map((lmOption: LabelMapOption) => ({
          label: lmOption.displayName,
          value: lmOption,
          disabled: true,
        }))
      );
    }
    setSelectedOption(undefined);
  }, [editorContext]);

  useEffect(() => {
    setIsShowViewLabels(false);
    setViewLabels([]);
    setViewMetadata([]);
  }, [setIsShowViewLabels, setViewLabels, setViewMetadata]);

  const handleOptionChanged = async (option: any) => {
    // releaseVTKObjects(vtkImageDataTagRef.current);
    if (!editorContext) return;
    const { workingLabelMap, painter } = editorContext;
    setIsProcessing(true);
    try {
      if (option) {
        setSelectedOption(option);
        setActiveTool(undefined);
        if (option.value && option.value.labelMapBlob) {
          const vtkImage = await blobToVTKImage(option.value.labelMapBlob);
          addToVTKObjects(vtkImage, false, vtkImageDataTagRef.current);
          await loadLabelMap(vtkImage);
          setIsShowViewLabels(true);
          setViewLabels(
            option.value.labels.map((lb: EditorLabel) => {
              let opacity = lb.opacity;
              let color = lb.color;
              try {
                const key = "3D_LABEL_OPACITY_" + lb.labelOptionId;
                const val = localStorage.getItem(key);
                if (val) opacity = parseInt(val);
              } catch (error) {
                Sentry.captureException(error);
              }
              try {
                const key = "3D_LABEL_COLOR_" + lb.labelOptionId;
                const val = localStorage.getItem(key);
                if (val) color = val;
              } catch (error) {
                Sentry.captureException(error);
              }
              return { ...lb, opacity, color };
            })
          );
          setActiveLabel(EDITOR_LABEL_NONE);
          setViewMetadata(option.value.metadata);
        }
      } else {
        setSelectedOption(undefined);
        if (workingLabelMap) {
          await loadLabelMap(workingLabelMap);
        }
        painter.setLabelMap(workingLabelMap);
        setIsShowViewLabels(false);
        setViewLabels([]);
        setViewMetadata([]);
      }
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsProcessing(false);
    }
  };

  const onClickApply = () => {
    setShowConfirm(true);
  };

  const handleApply = async () => {
    if (!editorContext || isProcessing) return;
    const { painter, workingLabelMap } = editorContext;
    if (!workingLabelMap) return;

    // copy the current label map which is in side the painter to the workingLabelMap
    try {
      setIsProcessing(true);
      copyLabelMap(painter.getLabelMap(), workingLabelMap);
      setLabels([...viewLabels]);
      setSelectedOption(undefined);
      setIsProcessing(false);
      setShowConfirm(false);
      const buttonApply = document.getElementById("three-d-apply-button");
      if (buttonApply) {
        const isSaving = buttonApply.getAttribute("data-is-saving");
        const isCompleting = buttonApply.getAttribute("data-is-completing");
        if (isSaving === "true") {
          saveJob(true, false);
        } else if (isCompleting === "true") {
          saveJob(true, true);
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
      setIsProcessing(false);
    }
  };

  const handleClose = async () => {
    if (!editorContext || isProcessing) return;
    setSelectedOption(undefined);
    handleOptionChanged(undefined);
  };

  if (options.length < 0) return null;

  return (
    <Fragment>
      {options && options.length > 0 && (
        <div className="flex flex-col gap-2">
          <p className="font-bold">Previous results</p>
          <VBSelectComponent
            placeholder={annotator}
            containerClassName="text-sm text-black"
            options={options}
            value={selectedOption || ""}
            onChange={handleOptionChanged}
            isClearable
            isDisabled={isProcessing}
            loseFocusAfterSelected
          />
          {selectedOption && (
            <div className="grid grid-cols-2 gap-4">
              <button
                className="button-warn-secondary disabled:opacity-50"
                disabled={isProcessing}
                onClick={handleClose}
              >
                Close
              </button>
              <button
                id="three-d-apply-button"
                className="button-warn-secondary disabled:opacity-50"
                disabled={isProcessing || !canCopyResult}
                onClick={onClickApply}
              >
                Apply
              </button>
            </div>
          )}
        </div>
      )}

      {showConfirm && (
        <ThreeDApplyConfirmModal
          onClose={() => setShowConfirm(false)}
          onSubmit={handleApply}
        />
      )}
    </Fragment>
  );
};
