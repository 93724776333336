/*
 * File: labeler-distribution.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 10:50:48 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "store";

export const selectBatchDistributionData =
  (batchId: number, email: string) => (state: RootState) => {
    const data = state.batchData.labelerDistribution.entities[batchId];
    if (data && data.labelers.hasOwnProperty(email)) {
      return data.labelers[email];
    }
    return null;
  };

export const selectBatchDistributionRequestStatus = (state: RootState) => {
  return state.batchData.labelerDistribution.status;
};

export const selectBatchDistributionRequestError = (state: RootState) => {
  return state.batchData.labelerDistribution.error;
};
