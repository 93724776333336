/*
 * File: project-data.state.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 9:30:49 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import {
  INITIAL_LABEL_DISTRIBUTION_STATE,
  LabelDistributionState,
} from "./label-distribution/label-distribution.state";
import {
  LabelerDistributionState,
  INITIAL_LABELER_DISTRIBUTION_STATE,
} from "./labeler-distribution/labeler-distribution.state";
import {
  ProjectStatisticState,
  INITIAL_PROJECT_STATISTIC_STATE,
} from "./statistic/project-statistic.state";

export interface ProjectDataState {
  statistic: ProjectStatisticState;
  labelerDistribution: LabelerDistributionState;
  labelDistribution: LabelDistributionState;
}

export const INITIAL_PROJECT_DATA_STATE = {
  statistic: INITIAL_PROJECT_STATISTIC_STATE,
  labelerDistribution: INITIAL_LABELER_DISTRIBUTION_STATE,
  labelDistribution: INITIAL_LABEL_DISTRIBUTION_STATE,
};
