/*
 * File: app-config.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 28th October 2022 3:26:51 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

const DEFAUL_FILE_UPLOAD_LIMIT = 52428800;
const DEFAULT_ZIP_FILE_UPLOAD_LIMIT = 1048576000;

function getFileUploadLimit(appConfig?: any, datasourceType?: string) {
  if (!datasourceType || !appConfig) return DEFAUL_FILE_UPLOAD_LIMIT;
  return (
    appConfig?.datasources?.[datasourceType]?.fileSizeLimit ??
    DEFAUL_FILE_UPLOAD_LIMIT
  );
}

function getZipFileUploadLimit(appConfig?: any, datasourceType?: string) {
  if (!datasourceType || !appConfig) return DEFAULT_ZIP_FILE_UPLOAD_LIMIT;
  return (
    appConfig?.datasources?.[datasourceType]?.zipFileSizeLimit ??
    DEFAULT_ZIP_FILE_UPLOAD_LIMIT
  );
}

const defaultInferenceOptions = [
  { label: "Image Detection", value: "IMAGE_DETECTION" },
  { label: "Image Segmentation", value: "IMAGE_SEGMENTATION" },
];

function getInferenceOptions(appConfig?: any) {
  if (!appConfig) return defaultInferenceOptions;
  if (!appConfig?.projects) return defaultInferenceOptions;
  const values: string[] = [];
  for (const key of Object.keys(appConfig.projects)) {
    const projectConfig = appConfig.projects[key];
    if (!projectConfig || !projectConfig.subTypes) continue;
    for (const subType of projectConfig.subTypes) {
      const modelConfig = subType.models;
      if (!modelConfig || modelConfig.disabled || !modelConfig.inferenceTypes)
        continue;
      for (const type of modelConfig.inferenceTypes) {
        if (!values.includes(type)) {
          values.push(type);
        }
      }
    }
  }
  return values.map((item) => {
    return { label: item, value: item };
  });
}

function getSupportedInferenceTypes(appConfig?: any, projectType?: string) {
  const inferenceTypes: string[] = [];
  if (!appConfig || !projectType) return inferenceTypes;
  if (!appConfig?.projects) return inferenceTypes;
  let config;
  for (const key of Object.keys(appConfig.projects)) {
    const projectConfig = appConfig.projects[key];
    if (!projectConfig || !projectConfig.subTypes) continue;
    for (const subType of projectConfig.subTypes) {
      if (subType.code === projectType) {
        config = subType;
        break;
      }
    }
  }
  if (
    config &&
    config.models &&
    !config.models.disabled &&
    config.models.inferenceTypes
  ) {
    return config.models.inferenceTypes;
  }

  return inferenceTypes;
}

function getProjectConfig(appConfig?: any, projectType?: string) {
  if (!appConfig || !projectType) return undefined;
  if (!appConfig?.projects) return undefined;
  let config = undefined;
  for (const key of Object.keys(appConfig.projects)) {
    const projectConfig = appConfig.projects[key];
    if (!projectConfig || !projectConfig.subTypes) continue;
    for (const subType of projectConfig.subTypes) {
      if (subType.code === projectType) {
        config = subType;
        break;
      }
    }
  }
  return config;
}

function getTextTokenizer(appConfig?: any, projectType?: string) {
  const defaultTokenizer = "([ ,.:])";
  const projectConfig = getProjectConfig(appConfig, projectType);
  if (!projectConfig || !projectConfig?.tokenizer) return defaultTokenizer;
  return projectConfig.tokenizer ?? defaultTokenizer;
}

const ConfigService = {
  getInferenceOptions,
  getFileUploadLimit,
  getZipFileUploadLimit,
  getSupportedInferenceTypes,
  getProjectConfig,
  getTextTokenizer,
};

export default ConfigService;
