import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBRichTextEditor } from "components/common/vb-rich-text/vb-rich-text.component";
import { VBInput } from "pages/labeler/labeler-profile/components/vb-input.component";
import { TicketInfoEntity } from "./types";

interface TicketDetailModalProps {
  ticket?: TicketInfoEntity;
  open: boolean;
  onClose: () => void;
}

export const TicketDetailModal = ({
  ticket,
  open,
  onClose,
}: TicketDetailModalProps) => {
  return (
    <VBModal
      open={open}
      title={`Ticket #${ticket?.id}`}
      onClose={onClose}
      width="50%"
      footerHidden
    >
      <section className="space-y-6">
        <section className="flex flex-col gap-y-2">
          <label className="text-base">Title</label>

          <VBInput
            label=""
            placeholder="Title"
            className="focus:ring-primary focus:border-primary"
            value={ticket?.title}
            readOnly={true}
          />
        </section>

        <section className="flex flex-col gap-y-2">
          <label className="text-base">Description</label>

          <VBRichTextEditor
            containerClass="w-full h-64 text-sm text-background-500 px-2 py-1 border rounded bg-background-50 border-background-300"
            defaultValue={ticket?.description || ""}
            disabled
          />
        </section>
      </section>
    </VBModal>
  );
};
