import { Tooltip } from "@material-ui/core";
import { IconArchiveBook, IconCloseCircle, IconTickCircle } from "components/common/vb-icon.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { issueCustomerService, issueLabelerService } from "services/label-service";
import { IssueCommentResponseDTO } from "services/label-service/dtos/issue.dto";
import { selectCurrentUser, selectUserRole } from "store/auth/auth.selectors";
import { UserRole } from "store/auth/auth.state";
import { mapperIssueComment } from "store/common/issue-management/issue-management.mappers";
import { selectIssueManagementIsReviewer } from "store/common/issue-management/issue-management.selectors";
import { updateIssue } from "store/common/issue-management/issue-management.slice";
import { deleteIssueAndUpdate, openIssueAndUpdate, resolveIssueAndUpdate } from "store/common/issue-management/issue-management.thunk";
import { enqueueErrorNotification, enqueueSuccessNotification } from "store/common/notification/notification.actions";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { Point } from "utilities/math/point";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { useBatchTaskContext } from "../../batch-tasks.context";
import { IssueAvatar } from "./issue-avatar.component";
import { IssueComment } from "./issue-comment.component";
import { IssueCommentModel, IssueModel, IssueStatus } from "./issue-management.models"
import { IssueSubmitButton } from "./issue-submit.button";
import * as Sentry from "@sentry/react";


interface Props {
  issue: IssueModel;
  onClose?: () => void;
  containerRef?: any;
}
export const IssueDetail = ({
  issue,
  onClose,
  containerRef,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectCurrentUser);
  const userRole = useAppSelector(selectUserRole);
  const isReviewer = useAppSelector(selectIssueManagementIsReviewer);

  const {reloadTaskAsync} = useBatchTaskContext();

  const [newComment, setNewComment] = useState<IssueCommentModel>({
    id: -1,
    issueId: issue.id,
    user: currentUser?.email || "",
    content: "",
    createdTime: new Date(),
  });
  const [isAddingNewComment, setIsAddingNewComment] = useState(false);

  const listCommentRef = useRef<HTMLDivElement>(null);

  // drag able
  const isMouseDownToolBar = useRef(false);
  const prevMousePos = useRef<Point>();

  // resolve
  const [isResolving, setIsResolving] = useState(false);

  // open
  const [isOpening, setIsOpening] = useState(false);

  // delete
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleNewCommentChanged = (v: string) => {
    setNewComment({
      ...newComment,
      content: v,
      createdTime: new Date(),
    });
  }

  const submitNewComment = async () => {
    try {
      scrollCommentsBottom();
      if (isAddingNewComment) return;
      setIsAddingNewComment(true);

      let res;
      const commentDTO = mapperIssueComment.toIssueCommentDTO(newComment);
      if (userRole === UserRole.CUSTOMER) {
        res = await issueCustomerService.createIssueComment(commentDTO);
      } else {
        res = await issueLabelerService.createIssueComment(commentDTO);
      }
      const newCommentModel = mapperIssueComment.fromIssueCommentDTO(res.data as IssueCommentResponseDTO);

      const newIssue: IssueModel = {
        ...issue,
        comments: [...issue.comments, newCommentModel],
      };
      dispatch(updateIssue(newIssue));
      scrollCommentsBottom();
      setNewComment({...newComment, content: ""});
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsAddingNewComment(false);
    }
  }

  const scrollCommentsBottom = () => {
    if (listCommentRef.current) {
      listCommentRef.current.scrollTop = listCommentRef.current.scrollHeight;
    }
  }

  const handleNewCommentKeydown = (e: any) => {
    if (e.key === KeyboardKey.Enter && !e.shiftKey) {
      submitNewComment();
    }
  }

  // drag able
  const handleToolBarDragableMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (containerRef) {
      isMouseDownToolBar.current = true;
      prevMousePos.current = {x: e.clientX, y: e.clientY};
    }
  }
  const handleToolBarDragableMouseUp = (e: any) => {
    isMouseDownToolBar.current = false;
  }
  const handleToolBarDragableMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isMouseDownToolBar.current && prevMousePos.current && containerRef) {
      const dx = e.clientX - prevMousePos.current.x;
      const dy = e.clientY - prevMousePos.current.y;

      containerRef.current.style.left = `${containerRef.current.offsetLeft + dx}px`;
      containerRef.current.style.top = `${containerRef.current.offsetTop + dy}px`;

      prevMousePos.current = {x: e.clientX, y: e.clientY};
    }
  }

  // resolve
  const  handleResolveIssue = async () => {
    try {
      if (isResolving) return;
      setIsResolving(true);
      const res = await dispatch(resolveIssueAndUpdate(issue));
      handleThunkRejected(res);
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      reloadTaskAsync && reloadTaskAsync();
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsResolving(false);
    }
  }

  // reopen fixed or resolved issue
  const handleReopenIssue = async () => {
    try {
      if (isOpening) return;
      setIsOpening(true);
      const res = await dispatch(openIssueAndUpdate(issue));
      handleThunkRejected(res);
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      reloadTaskAsync();
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsOpening(false);
    }
  }

  // delete
  const handleDeleteIssue = async () => {
    try {
      if (isDeleting) return;
      setIsDeleting(true);
      const res = await dispatch(deleteIssueAndUpdate(issue));
      handleThunkRejected(res);
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <Fragment>
      <div 
        className="flex flex-col p-2 gap-2 rounded bg-white text-black"
        style={{
          minWidth: "200px",
        }}
      >
        <div className="flex gap-2 items-center">
          <span className="text-sm pl-2">Status: {issue.status}</span>
          <div
            className="flex-1 self-stretch"
            onMouseDown={handleToolBarDragableMouseDown}
            onMouseUp={handleToolBarDragableMouseUp}
            onMouseMove={handleToolBarDragableMouseMove}
          >
          </div>
          {
            (issue.status !== IssueStatus.RESOLVED &&
             (userRole === UserRole.CUSTOMER)) &&
            <Tooltip title="Resolve this issue" placement="top">
              <button onClick={handleResolveIssue} disabled={isResolving}>
                <IconTickCircle className="w-4 h-4 text-green-500" />
              </button>
            </Tooltip>
          }
          {
            ((issue.status === IssueStatus.FIXED || issue.status === IssueStatus.RESOLVED) &&
              (userRole === UserRole.CUSTOMER || isReviewer)) &&
            <Tooltip title="Reopen this issue" placement="top">
              <button onClick={handleReopenIssue} disabled={isOpening}>
                <IconArchiveBook className="w-4 h-4 text-red-300" />
              </button>
            </Tooltip>
          }
          <button onClick={onClose}>
            <IconCloseCircle className="w-4 h-4" />
          </button>
        </div>
        <hr />
        <div
          ref={listCommentRef}
          className="overflow-y-auto"
          style={{
            maxHeight: "300px",
          }}
        >
          {
            issue.comments.map((comment, index) => {
              return (
                <IssueComment
                  key={index}
                  comment={comment}
                  showMenu={index !== 0}
                />
              )
            })
          }
          {
            isAddingNewComment &&
            <IssueComment  comment={newComment} isLoading/>
          }
        </div>
        <div className="w-full pl-2 flex gap-1 mt-2 relative">
          <IssueAvatar
            name={currentUser?.email || ""}
            width={"30px"}
          />
          <textarea
            className="border-0 rounded p-2 text-sm bg-background-100 flex-1 resize-none"
            placeholder="Add a comment"
            rows={newComment.content ? 3 : 1}
            value={newComment.content}
            onChange={(e) => handleNewCommentChanged(e.target.value as string)}
            onKeyDown={handleNewCommentKeydown}
            disabled={isAddingNewComment}
          />
          <IssueSubmitButton
            isActive={!!newComment.content}
            onSubmit={submitNewComment}
          />
        </div>
      </div>
      <VBModal
        title="Delete issue"
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onSubmit={handleDeleteIssue}
        disableSubmit={isDeleting}
      >
        <span>Are you sure? You want to delete this issue?</span>
      </VBModal>
    </Fragment>
  );
}

