import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_IOU } from "services/label-service/dtos";
import { enqueueErrorNotification, enqueueSuccessNotification } from "store/common/notification/notification.actions";
import { selectBatchInfo, selectBatchLabels } from "store/customer/batch/batch.selectors";
import { updateBatchManagementAsync } from "store/customer/batch/batch.thunk";
import { selectCurrentProject, selectProjectObservations } from "store/customer/project/project.selectors";
import { loadProjectObservationsAsync } from "store/customer/project/project.slice";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { ImportLabelTreeModal, ObservationImportModel } from "./import-label-tree.modal";
import * as Sentry from "@sentry/react";


interface BatchLabelAddTreeDialogProps{
  open: boolean;
  handleClose: () => void;
}
export const BatchLabelAddTreeDialog = ({
  open,
  handleClose,
}: BatchLabelAddTreeDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const project = useAppSelector(selectCurrentProject);
  const batch = useAppSelector(selectBatchInfo);
  const currentBatchObservations = useAppSelector(selectBatchLabels);
  const projectObservations = useAppSelector(selectProjectObservations);


  const closeDialog = () => {
    handleClose();
  };

  const handleAdd = async (observationOptions: ObservationImportModel[]) => {
    if (!batch) return;

    const batchObservations = [];
    let countNew = 0;
    for (let observationImport of observationOptions){
      if (observationImport.selected){
        const existedBatchObservation = currentBatchObservations.find(bo => bo.observation.id === observationImport.observation.id);
        const defaultIoU = observationImport.labelRequired ? DEFAULT_IOU : 0;
        if (existedBatchObservation){
          batchObservations.push({
            ...existedBatchObservation,
            labelRequired: observationImport.labelRequired,
            probabilityRequired: observationImport.probabilityRequired,
            iou: observationImport.labelRequired === existedBatchObservation.labelRequired ?
                 existedBatchObservation.iou : defaultIoU,
          });
        } else {
          countNew = countNew + 1;
          batchObservations.push({
            batch: batch,
            observation: observationImport.observation,
            labelRequired: observationImport.labelRequired,
            probabilityRequired: observationImport.probabilityRequired,
            iou: defaultIoU,
          });
        }
      }
    }

    try {
      const payload = {
        batchId: batch.id,
        payload: {
          batchObservation: batchObservations.map(
            (bo) => {
              return {
                iou: bo.iou,
                labelRequired: bo.labelRequired,
                probabilityRequired: bo.probabilityRequired,
                observationId: bo.observation.id,
              };
            }
          ),
        },
      };

      const res = await dispatch(updateBatchManagementAsync(payload));
      handleThunkRejected(res);
      dispatch(
        enqueueSuccessNotification(
          t("project:batchDetails.batchLabels.textAdded", {
            count: countNew,
          })
        )
      );
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textAddedFailed")));
    } finally {
      closeDialog();
    }
  }

  useEffect(() => {
    if (project) dispatch(loadProjectObservationsAsync(project.id));
  }, [dispatch, project]);

  return (
    <ImportLabelTreeModal
      open={open}
      projectObservations={projectObservations}
      currentBatchObservations={currentBatchObservations}
      onClose={() => handleClose && handleClose()}
      onAdd={handleAdd}
    />
  )
}
