/*
 * File: batch.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 18th August 2021 5:44:42 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BatchStatus, StepType } from "services/label-service/dtos";
import { RootState } from "store";
import { selectLabelRequireIoUConfig } from "store/customer/workflow/workflow.selectors";
import { truncateEmail } from "utilities/string/truncate-email";

export const selectWorkManagements = (state: RootState) => {
  return state.batch.workManagements;
};

export const selectBatchWorkManagements = (state: RootState) => {
  return state.batch.workManagements;
};

export const selectBatchLabels = (state: RootState) => {
  return state.batch.labels;
};

export const selectLabelConfigChanged = (state: RootState) => {
  return state.batch.labelConfigChanged;
};

export const selectBatchWorkflow = (state: RootState) => {
  return state.batch.workflow;
};

export const selectBatchTasks = (state: RootState) => {
  return state.batch.tasks;
};

export const selectBatchInstructions = (state: RootState) => {
  return state.batch.instructions;
};

export const selectBatchInstructionRounds = (state: RootState) => {
  return state.batch.instructions.reduce(
    (total, instruction) => total + instruction.roundNumber,
    0
  );
};

export const selectIsBatchRequireIoUConfig = (state: RootState) => {
  const stepConditionId = selectLabelRequireIoUConfig(state);
  const instructions = state.batch.instructions;
  if (!stepConditionId) return false;
  for (let i = 1; i < instructions.length; i++) {
    const condition = instructions[i].condition;
    if (condition && condition.indexOf(stepConditionId) !== -1) {
      return true;
    }
  }
  return false;
};

export const selectBatchLabelers = (state: RootState) => {
  return state.batch.workManagements.map((workManagement) => ({
    email: workManagement.userId,
    dailyLimit: workManagement.dailyLimit,
  }));
};

export const selectDoesBatchHasStepReview = (state: RootState) => {
  for (let wm of state.batch.workManagements) {
    if (wm.workInstruction.stepType === StepType.ACCEPTANCE) {
      return true;
    }
  }
  return false;
};

export const selectBatchCanReopenTasks = (state: RootState) => {
  if (!state.batch.batch) return false;
  const batchStatus = state.batch.batch.status as BatchStatus;
  return ![BatchStatus.INITIAL, BatchStatus.INREVIEW].includes(batchStatus);
};

export const selectBatchStepLabelers = (state: RootState) => {
  return state.batch.stepUsers;
};

export const selectBatchStepLabelerOptions =
  (excludeReviewStep = false, excludeNormalStep = false, truncate = true) =>
  (state: RootState) => {
    const labelers: string[] = [];
    const workManagements = [...state.batch.workManagements];
    workManagements.sort((a, b) => {
      const aIndex = a.workInstruction.step;
      const bIndex = b.workInstruction.step;
      const diff = aIndex - bIndex;
      if (diff === 0) {
        return a.workInstruction.roundNumber - b.workInstruction.roundNumber;
      }
      return diff;
    });
    for (const workManagement of workManagements) {
      const isStepReview =
        workManagement.workInstruction.stepType === StepType.ACCEPTANCE;
      if (excludeReviewStep && isStepReview) continue;
      if (excludeNormalStep && !isStepReview) continue;
      let name = workManagement.userId;
      if (!labelers.includes(name)) {
        labelers.push(name);
      }
    }

    const options = labelers.map((labeler) => ({
      label: truncate ? truncateEmail(labeler) : labeler,
      value: labeler,
    }));
    return options;
  };

export const selectBatchInfo = (state: RootState) => {
  return state.batch.batch;
};

export const selectBatchRequestStatus = (state: RootState) => {
  return state.batch.requestStatus;
};

export const selectBatchRequestError = (state: RootState) => {
  return state.batch.requestError;
};

export const selectCanStartBatch = (state: RootState) => {
  const { batch, totalTask } = state.batch;
  return batch && batch.status === BatchStatus.INITIAL && totalTask > 0;
};

export const selectBatchPricing = (state: RootState) => {
  return state.batch.pricing;
};

export const selectBatchCurrency = (state: RootState) => {
  return state.batch.pricing?.currency;
};

export const selectBatchGenAnnoAsyncJobStatus = (state: RootState) => {
  return state.batch.genAnnoAsyncJobStatus;
};

export const selectBatchGenAnnoAsyncJobPercentComplete = (state: RootState) => {
  return state.batch.genAnnoAsyncJobPercentComplete;
};

export const selectProjectTasks = (state: RootState) => {
  return state.batch.tasks;
};
export const selectProjectTotalTasks = (state: RootState) => {
  return state.batch.totalTask;
};

export const selectIsLoadingBatchTasks = (state: RootState) => {
  return state.batch.isLoadingBatchTasks;
};

export const selectBatchIsSelectedAllTasks = (state: RootState) => {
  const { tasks } = state.batch;
  return tasks.length > 0 && !tasks.some((task) => !task.selected);
};

export const selectBatchHasSelectedTasks = (state: RootState) => {
  return state.batch.tasks.some((task) => task.selected);
};

export const selectBatchSelectedTaskIds = (state: RootState) => {
  return state.batch.tasks
    .filter((task) => task.selected)
    .map((task) => task.id);
};

export const selectBatchTaskAction = (state: RootState) => {
  const { taskId, action } = state.batch.selectedTaskAction;
  const task = state.batch.tasks.find((task) => task.id === taskId);
  return { task, action };
};
