/*
 * File: intersection-of-union.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:12:52 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { LightCheckbox } from "components/common/vb-light-checkbox.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect } from "react";
import {
  selectFindingAgreement,
  selectFindingLabelers,
} from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { FindingLabeler } from "store/labeler/image-workspace/image-labeling/image-labeling.state";
import { getUserNameFromEmail } from "store/labeler/image-workspace/image-labeling/image-labeling.utils";
import { selectJobs } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import {
  setViewMediaAgreement,
  setFindingLabelerSelected,
  annotateLabelActivated,
} from "store/labeler/image-workspace/image-workspace.slice";
import { acceptVisibleAnnotationsAsync } from "store/labeler/image-workspace/image-annotations/thunks/accept-visible-annotations.thunk";
import { FilterByIoU } from "./filter-by-iou.component";

interface Props {
  jobId: number;
}
export const IntersectionOfUnion = ({ jobId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const findingLabelers = useAppSelector(selectFindingLabelers);
  const jobs = useAppSelector(selectJobs);

  useEffect(() => {
    const visibleItems = [];
    for (const finding of findingLabelers) {
      const selectedUsers = finding.users.filter((user) => user.selected);
      for (const user of selectedUsers) {
        visibleItems.push({ labelId: finding.labelId, jobId: user.jobId });
      }
    }
  }, [findingLabelers]);

  async function handleClose() {
    for (const job of jobs) {
      await dispatch(acceptVisibleAnnotationsAsync({ jobId: job.id }));
    }
    dispatch(setViewMediaAgreement(false));
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex items-center px-4 py-2 font-bold text-white uppercase border-b border-gray-600 bg-blueGray-900">
        <button className="py-2 focus:outline-none" onClick={handleClose}>
          <i className="uir-arrow-left"></i>
        </button>
        <span className="mx-4">
          {t("labelerworkspace:panel.textMediaAgree")}
        </span>
      </div>

      <div className="flex-auto overflow-y-auto">
        <FilterByIoU jobId={jobId} />
      </div>
    </div>
  );
};

interface FindingLabelProps {
  data: FindingLabeler;
}

export const FindingLabel = ({ data }: FindingLabelProps) => {
  const dispatch = useAppDispatch();
  const score = useAppSelector(selectFindingAgreement(data));

  function handleMouseEnterLabel(labelId: string) {
    const annotatedLabel = {
      jobId: -1,
      labelId: parseInt(labelId),
      annotator: "",
    };
    const payload = { annotatedLabel, active: true };
    dispatch(annotateLabelActivated(payload));
  }

  function handleMouseLeaveLabel(labelId: string) {
    const annotatedLabel = {
      jobId: -1,
      labelId: parseInt(labelId),
      annotator: "",
    };
    const payload = { annotatedLabel, active: false };
    dispatch(annotateLabelActivated(payload));
  }

  function handleMouseEnterJob(
    labelId: string,
    jobId: string,
    annotator: string
  ) {
    const annotatedLabel = {
      jobId: -1,
      labelId: parseInt(labelId),
      annotator: annotator,
    };
    const payload = { annotatedLabel, active: true };
    dispatch(annotateLabelActivated(payload));
  }

  function handleMouseLeaveJob(
    labelId: string,
    jobId: string,
    annotator: string
  ) {
    const annotatedLabel = {
      jobId: -1,
      labelId: parseInt(labelId),
      annotator: annotator,
    };
    const payload = { annotatedLabel, active: false };
    dispatch(annotateLabelActivated(payload));
  }

  function handleToggleSelected(user: {
    userId: string;
    jobId: string;
    selected?: boolean;
  }) {
    dispatch(
      setFindingLabelerSelected({
        labelId: data.labelId,
        userId: user.userId,
        selected: !user.selected,
      })
    );
  }

  return (
    <div className="w-full py-2 border-b border-dashed" key={data.labelId}>
      <div
        className="flex items-center justify-between px-4 py-2"
        onMouseEnter={() => handleMouseEnterLabel(data.labelId)}
        onMouseLeave={() => handleMouseLeaveLabel(data.labelId)}
      >
        <div>{data.labelName}</div>
        {score !== -1 && (
          <div>
            <span className="text-gray-400">IoU </span>
            <span>{score.toFixed(2)}</span>
          </div>
        )}
      </div>

      <div>
        {data.users.map((user) => {
          return (
            <div
              className="flex items-center px-4 overflow-hidden text-sm"
              key={user.userId}
              onMouseEnter={() =>
                handleMouseEnterJob(data.labelId, user.jobId, user.userId)
              }
              onMouseLeave={() =>
                handleMouseLeaveJob(data.labelId, user.jobId, user.userId)
              }
            >
              <LightCheckbox
                onClick={() => handleToggleSelected(user)}
                checked={!!user.selected}
              />
              <span
                className="overflow-hidden cursor-pointer whitespace-nowrap overflow-ellipsis"
                onClick={() => handleToggleSelected(user)}
              >
                {getUserNameFromEmail(user.userId)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
