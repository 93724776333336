/*
 * File: user-notification.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 16th September 2021 9:40:51 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "store";

export const selectNumberOfUnreadNotifications = (state: RootState) => {
  return state.userNotification.numberOfUnreadNotifications;
};

export const selectUserNotifications = (state: RootState) => {
  const { entities, ids } = state.userNotification;
  return ids.map((id) => entities[id]);
};

export const selectUserNotificationSettings = (state: RootState) => {
  return state.userNotification.settings;
}
