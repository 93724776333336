import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useThreeDLabelingContext } from "../context/three-d-labeling.context";
import { useThreeDSmoothing } from "../hooks/use-threed-smoothing";
import { blobToVTKImage, vtkImageMaskToBlob } from "../utils";
import { TOOL_SEGMETATION_SMOOTH_EFFECT } from "./three-d-editor.models";
import { useThreeDEditorContext } from "./three-d-editor.provider";
import * as Sentry from "@sentry/react";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { selectCurrentWorkspaceId } from "store/common/user-workspace/user-workspace.selectors";

export const ThreeDEditorFilterSmoothing = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    editorContext,
    activeLabel,
    activeTool,
    smeIsInitialized,
    setSmeIsInitialized,
    smeIsProcessing,
    setSmeIsProcessing,
    getCurrentLabels,
    renderAllWindows,
    updateLabelMapMaskFromFillBetweenSlices,
    notifyWorkingLabelMapChanged,
  } = useThreeDEditorContext();

  const { uiJobRef } = useThreeDLabelingContext();
  const workspaceId = useAppSelector(selectCurrentWorkspaceId);
  const currentLabelsRef = useRef(getCurrentLabels());
  const activeLabelRef = useRef(activeLabel);
  const smeIsInitializedRef = useRef(smeIsInitialized);
  const smeIsProcessingRef = useRef(smeIsProcessing);
  const formRef = useRef<HTMLFormElement>(null);
  const [methodOptions] = useState([
    {
      label: "Median",
      value: "MEDIAN",
      params: {
        label: "Kernel size",
        value: "KernelSizeMm",
        unit: "mm",
        minimum: 0.0,
        maximum: 1000,
        step: 1.0,
        default: 3.0,
      },
    },
    {
      label: "Gaussian",
      value: "GAUSSIAN",
      params: {
        label: "Standard deviation",
        value: "GaussianStandardDeviationMm",
        unit: "mm",
        minimum: 0.0,
        maximum: 1000,
        step: 1.0,
        default: 3.0,
      },
    },
    {
      label: "Joint smoothing",
      value: "JOINT_TAUBIN",
      params: {
        label: "Smoothing factor",
        value: "JointTaubinSmoothingFactor",
        unit: "",
        minimum: 0.01,
        maximum: 1.0,
        step: 0.01,
        default: 0.5,
      },
    },
  ]);

  const [method, setMethod] = useState(methodOptions[0].value);

  const selectedMethod = useMemo(() => {
    return methodOptions.find((option) => option.value === method);
  }, [methodOptions, method]);

  const inputRef = useRef<HTMLInputElement>(null);

  const {
    running,
    resetSmoothing,
    request,
    outputBlob,
    runSmoothing,
    isApplied,
    setApplied,
  } = useThreeDSmoothing();

  useEffect(() => {
    currentLabelsRef.current = getCurrentLabels();
  }, [getCurrentLabels]);

  useEffect(() => {
    activeLabelRef.current = activeLabel;
  }, [activeLabel]);

  useEffect(() => {
    smeIsInitializedRef.current = smeIsInitialized;
  }, [smeIsInitialized]);

  useEffect(() => {
    smeIsProcessingRef.current = smeIsProcessing;
  }, [smeIsProcessing]);

  useDeepCompareEffect(() => {
    async function apply() {
      if (!request || !outputBlob || isApplied) return;
      const outVtkImage = await blobToVTKImage(outputBlob);
      const { painter } = editorContext;
      const fbtsData = outVtkImage.getPointData().getScalars().getData();
      painter.getLabelMap().getPointData().getScalars().setData(fbtsData);
      painter.modified();
      notifyWorkingLabelMapChanged();
      renderAllWindows();
      setApplied(true);
    }
    apply();
  }, [
    editorContext,
    request,
    outputBlob,
    renderAllWindows(),
    updateLabelMapMaskFromFillBetweenSlices,
    notifyWorkingLabelMapChanged,
    isApplied,
    setApplied,
  ]);

  useEffect(() => {
    if (inputRef.current && selectedMethod) {
      inputRef.current.value = selectedMethod.params.default.toString();
    }
  }, [inputRef, selectedMethod]);

  if (activeTool?.id !== TOOL_SEGMETATION_SMOOTH_EFFECT.id) return null;

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    const formData = new FormData(event.currentTarget);
    event.preventDefault();

    try {
      if (!workspaceId) return;
      if (!selectedMethod) return;
      if (!uiJobRef.current) return;
      if (!editorContext || smeIsProcessingRef.current) return;
      const paramValue: number = parseFloat(
        (
          formData.get(selectedMethod.params.value) ??
          selectedMethod.params.default.toString()
        ).toString()
      );
      setSmeIsProcessing(true);

      const uiJob = uiJobRef.current;
      if (!uiJob.job) return;
      const mask = activeLabelRef.current.maskValue;
      const blob = await vtkImageMaskToBlob(uiJob.workingLabelMap, mask);
      const jobId = uiJob.job.id;
      const payload = {
        mask,
        jobId,
        method,
        parameters: {
          [selectedMethod.params.value]: paramValue,
        },
        blob,
        workspaceId,
      };
      await runSmoothing(payload);
      setSmeIsInitialized(true);
    } catch (error: any) {
      Sentry.captureException(error);
      console.log("error", error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setSmeIsProcessing(false);
    }
  };

  const handleClickCancel = async () => {
    if (!request) {
      setSmeIsInitialized(false);
      setSmeIsProcessing(false);
      resetSmoothing();
      return;
    }
    try {
      const outVtkImage = await blobToVTKImage(request?.blob);
      const { painter } = editorContext;
      const data = outVtkImage.getPointData().getScalars().getData();
      painter.getLabelMap().getPointData().getScalars().setData(data);
      painter.modified();
      renderAllWindows();
      setSmeIsInitialized(false);
      setSmeIsProcessing(false);
      resetSmoothing();
    } catch (error) {
      Sentry.captureException(error);
      setSmeIsInitialized(false);
      setSmeIsProcessing(false);
      resetSmoothing();
    }
  };

  const handleClickApply = () => {
    setSmeIsInitialized(false);
    setSmeIsProcessing(false);
    resetSmoothing();
  };

  function handleSmoothingMethodChange(option: any) {
    if (option === null || option === undefined) {
      setMethod(methodOptions[0].value);
    } else {
      setMethod(option.value);
    }
  }

  return (
    <div className="flex flex-col gap-2 mt-2">
      <hr />
      <form onSubmit={handleSubmit} ref={formRef} className="space-y-2">
        <div className="w-full">
          <div className="flex items-center gap-1 pb-2 text-sm font-bold">
            Smoothing method:
          </div>
          <VBSelectComponent
            className="text-black"
            options={methodOptions}
            value={selectedMethod}
            isClearable
            placeholder="Preset"
            loseFocusAfterSelected
            onChange={handleSmoothingMethodChange}
            isDisabled={smeIsProcessing || running}
          />
        </div>
        {selectedMethod && (
          <div className="w-full">
            <div className="flex items-center gap-1 pb-2 text-sm font-bold">
              {selectedMethod.params.label}
              {selectedMethod.params.unit && (
                <span>({selectedMethod.params.unit})</span>
              )}
              <span>:</span>
            </div>
            <input
              ref={inputRef}
              className="w-full px-2 py-1 text-blue-900 rounded"
              type="number"
              name={selectedMethod.params.value}
              min={selectedMethod.params.minimum}
              defaultValue={selectedMethod.params.default}
              step={selectedMethod.params.step}
              max={selectedMethod.params.maximum}
              placeholder={selectedMethod.params.value}
              disabled={smeIsProcessing || running}
            />
          </div>
        )}
        <div className="flex items-center gap-2">
          {!smeIsInitialized && (
            <button
              type="submit"
              className="button-secondary disabled:opacity-50"
              disabled={smeIsProcessing || running}
            >
              Initialize
            </button>
          )}
          {smeIsInitialized && (
            <button
              className="button-secondary disabled:opacity-50"
              // onClick={handleClickUpdate}
              type="submit"
              disabled={!smeIsInitialized || smeIsProcessing || running}
              name="autoUpdate"
            >
              Update
            </button>
          )}
        </div>
      </form>

      <div className="flex items-center w-full gap-4">
        <button
          className="button-text-secondary disabled:opacity-50"
          onClick={handleClickCancel}
          disabled={!smeIsInitialized || smeIsProcessing || running}
        >
          Cancel
        </button>

        <button
          className="button-text-secondary disabled:opacity-50"
          onClick={handleClickApply}
          disabled={!smeIsInitialized || smeIsProcessing || running}
        >
          Apply
        </button>
      </div>
    </div>
  );
};
