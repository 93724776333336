/*
 * File: cloud-storage.config.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 28th June 2021 2:49:55 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

export interface CloudProvider {
  id: string;
  name: string;
  selected?: boolean;
  disabled?: boolean;
}

export const CLOUD_PROVIDERS: CloudProvider[] = [
  {
    id: "azure_blob_storage",
    name: "Azure Blob",
    selected: true,
  },
  {
    id: "aws_s3",
    name: "Amazon S3",
    disabled: false,
  },
  {
    id: "google_cloud_storage",
    name: "Google Cloud Storage",
    disabled: true,
  },
];

export const DEFAULT_CLOUD_STORAGE_PROVIDER = CLOUD_PROVIDERS[0].id;
