/*
 * File: user-api.config.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 29th July 2021 3:34:21 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

export const UserAPIConfig = {
  paths: {
    group: "/api/v1/group",
    userInfo: "/api/v1/user-infos",
    userGroup: "/api/v1/user/group",
  },
};
