import { useTranslation } from "react-i18next";
import { SliderWithInput } from "./slider-with-input-.component";

interface AgreementRateSliderProps {
  label?: string;
  onChange?(value: number): void;
  defaultValue?: number;
}

export const AgreementRateSlider = ({
  label,
  onChange,
  defaultValue = 100,
}: AgreementRateSliderProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <SliderWithInput
        name={label || t("project:batch.instruction.step3.textAgreementRate")}
        defaultValue={defaultValue}
        minValue={0}
        maxValue={100}
        stepSize={1}
        numLength={3}
        onChange={onChange}
      />
    </div>
  );
};
