import { roundVector, clampVector } from "@kitware/vtk.js/Common/Core/Math";
import { SlicingMode, vtkBoundingBox } from "../vtk_import";


function fitImageBoundToCamera(axis, renderer, bounds) {
  // scale camera to fit the image
  const wI = Math.abs(bounds[0] - bounds[1]);
  const wJ = Math.abs(bounds[2] - bounds[3]);
  const wK = Math.abs(bounds[4] - bounds[5]);

  let ps = renderer.getActiveCamera().getParallelScale();
  const view = renderer.getRenderWindow()?.getViews()[0];
  const dim = view.getViewportSize(renderer);
  const aspect = dim[0] / dim[1];
  let imageW = 0;
  
  switch (axis) {
    case SlicingMode.I:
      ps = wK; imageW = wJ;
      break;
    case SlicingMode.J:
      ps = wK; imageW = wI;
      break;
    case SlicingMode.K:
      ps = wJ; imageW = wI;
      break;
    default:
  }
  // check if camera width is less than image width
  // if so we scale the camera projection height to fit all the image
  const cw = ps * aspect;
  if (cw <= imageW) {
    ps *= imageW / cw;
  }
  ps /= 2;
  
  renderer.getActiveCamera().setParallelScale(ps);
}

// see the computeHistogram function in the ImageData class
// for reference
function getScalarsImageInBound(imageData, worldBounds) {
  const bounds = [0, 0, 0, 0, 0, 0];
  imageData.worldToIndexBounds(worldBounds, bounds);
  const point1 = [0, 0, 0];
  const point2 = [0, 0, 0];
  vtkBoundingBox.computeCornerPoints(bounds, point1, point2);
  roundVector(point1, point1);
  roundVector(point2, point2);
  const dimensions = imageData.getDimensions();
  clampVector(point1, [0, 0, 0], [dimensions[0] - 1, dimensions[1] - 1, dimensions[2] - 1], point1);
  clampVector(point2, [0, 0, 0], [dimensions[0] - 1, dimensions[1] - 1, dimensions[2] - 1], point2);
  const yStride = dimensions[0];
  const zStride = dimensions[0] * dimensions[1];
  const pixels = imageData.getPointData().getScalars().getData();
  let maximum = -Infinity;
  let minimum = Infinity;
  const regionScalars = [];

  for (let z = point1[2]; z <= point2[2]; z++) {
    for (let y = point1[1]; y <= point2[1]; y++) {
      let index = point1[0] + y * yStride + z * zStride;

      for (let x = point1[0]; x <= point2[0]; x++) {
        let pixel = pixels[index];
        if (pixel > maximum) maximum = pixel;
        if (pixel < minimum) minimum = pixel;
        regionScalars.push(pixel);
      }
    }
  }

  return {
    maximum,
    minimum,
    regionScalars,
  }
}

// see ComputeHistogram.worker.js in VTK JS for reference
function computeHistogramForArrayScalars({
  array,
  numberOfBins,
  min,
  max,
  offset = 0,
  step = 1,
}) {
  const histogram = new Float32Array(numberOfBins);
  histogram.fill(0);
  const delta = max - min;
  const len = array.length;

  for (let i = offset; i < len; i += step) {
    const idx = Math.floor((numberOfBins - 1) * (Number(array[i]) - min) / delta)
    histogram[idx] += 1;
  }

  return histogram;
}

// compute auto range
// see vtkImageHistogramStatistics::RequestData in VTK C++ for reference
function computeAutoRange({
  histogram,
  numberOfBins,
  min,
  max,
  total,
}) {
  /**
  * Set the percentiles to use for automatic view range computation.
  * This allows one to compute a range that does not include outliers
  * that are significantly darker or significantly brighter than the
  * rest of the pixels in the image.  The default is to use the first
  * percentile and the 99th percentile.
  */
  const lowPercentile = 1 * 0.01;
  const highPercentile = 99 * 0.01;
  /**
  * Set lower and upper expansion factors to apply to the auto range
  * that was computed from the AutoRangePercentiles.  Any outliers that
  * are within this expanded range will be included, even if they are
  * beyond the percentile.  This allows inclusion of values that are
  * just slightly outside of the percentile, while rejecting values
  * that are far beyond the percentile.  The default is to expand the
  * range by a factor of 0.1 at each end.  The range will never be
  * expanded beyond the Minimum or Maximum pixel values.
  */
  const lowEF = 0.1;
  const highEF = 0.1;

  let sum = 0;
  const lowSum = total * lowPercentile;
  const highSum = total * highPercentile;
  let lowVal = 0;
  let highVal = 0;

  for (let ix = 0; ix < numberOfBins; ix++) {
    const c = histogram[ix];
    sum += c;
    lowVal = sum > lowSum ? lowVal : ix;
    highVal = sum > highSum ? highVal : ix;
  }

  const binSpacing = 1;
  const binOrigin = min;

  const lowExpansion = Math.floor(lowEF * (highVal - lowVal));
  const highExpansion = Math.floor(highEF * (highVal - lowVal));
  lowVal -= lowExpansion;
  highVal += highExpansion;

  const autoRange = [0, 0];
  autoRange[0] = lowVal * binSpacing + binOrigin;
  autoRange[1] = highVal * binSpacing + binOrigin;

  if (autoRange[0] < min) autoRange[0] = min;
  if (autoRange[1] > max) autoRange[1] = max;

  return autoRange;
}

function shouldIgnoreEventKeyup(e) {
  const isInputTarget = e.target instanceof HTMLInputElement || 
                            e.target instanceof HTMLTextAreaElement;
  return isInputTarget;
}

export {
  fitImageBoundToCamera,
  getScalarsImageInBound,
  computeHistogramForArrayScalars,
  computeAutoRange,
  shouldIgnoreEventKeyup,
}
