/*
 * File: task-statistic-chart.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 28th December 2021 3:20:12 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */
import { useAppSelector } from "hooks/use-redux";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useState, useMemo } from "react";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  Bar,
  LabelList,
  Pie,
  PieChart,
  Cell,
} from "recharts";
import { VBTabs } from "components/common/vb-tabs/vb-tabs.component";
import {
  formatNumber,
} from "utilities/formatter/number-formatter.utils";
import { LabelLegend } from "./label-legend.component";
import { useDashboardContext } from "../context/dashboard.context";
import moment from "moment";
import { LinearProgress } from "@material-ui/core";
import { QueryNameStatistic } from "../context/dashboard.state";
import { selectDashBoardIsQueryNameLoading } from "store/customer/dashboard/dashboard.selectors";


export const TaskStatisticChart = () => {
  const [activeTab, setActiveTab] = useState("progress");
  const {
    taskCompletedData,
  } = useDashboardContext();
  const numCompletedTask = useMemo(() => {
    return taskCompletedData.reduce((s, item) => s + item.completed, 0);
  }, [taskCompletedData]);
  const isTaskProgressLoading = useAppSelector(selectDashBoardIsQueryNameLoading(QueryNameStatistic.TaskProgressStatistic));
  const isTaskCompltedLoading = useAppSelector(selectDashBoardIsQueryNameLoading(QueryNameStatistic.TaskCompleteStatistic));

  return (
    <div
      className="relative flex-auto bg-white rounded shadow"
      style={{ width: "30rem" }}
    >
      <VBTabs
        className="grid w-full grid-cols-2 text-center"
        activeTab={activeTab}
        onSelect={setActiveTab}
        tabs={[
          { id: "progress", name: "Task Progress", isLoading: isTaskProgressLoading },
          { id: "completed", name: `Completed tasks (${numCompletedTask})`, isLoading: isTaskCompltedLoading },
        ]}
      />
      {
        activeTab === "progress" && 
        <TaskProgressChart />
      }
      {
        activeTab === "completed" &&
        <TaskCompletedChart />
      }
    </div>
  );
};

export interface TaskProgressItemDataModel {
  name: string;
  value: number;
  color: string;
}
export interface TaskProgressChartProps {
  isLoading?: boolean;
}
const TaskProgressChart = ({ isLoading = false }: TaskProgressChartProps) => {
  const {
    taskProgressData,
  } = useDashboardContext();

  const total = useMemo(
    () => taskProgressData.reduce((total, current) => total + current.value, 0),
    [taskProgressData]
  );
  const items = useMemo(() => {
    const total = taskProgressData.reduce((total, current) => total + current.value, 0);
    return taskProgressData.map((item) => {
      return {
        ...item,
        percent: total === 0 ? total : Math.round((100 * item.value) / total),
      };
    });
  }, [taskProgressData]);

  if (isLoading) {
    return <div className="w-full h-2 z-0 py-20 px-2">
            <LinearProgress />
          </div>
  }

  return (
    <div className="relative w-full py-4">
      <div className="relative h-48 my-4">
        <ResponsiveContainer
          className="relative z-10"
          width="100%"
          height="100%"
        >
          <PieChart>
            <Pie
              data={items}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={90}
              fill="#82ca9d"
              animationDuration={500}
            >
              {items.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>

        <div className="absolute left-0 z-0 w-full text-center transform -translate-y-1/2 top-1/2">
          <div className="text-2xl font-bold text-center text-background-700">
            {formatNumber(total)}
          </div>
          <div className="text-center">Items</div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center gap-x-4 gap-y-2">
        {items.map((item) => {
          return (
            <LabelLegend
              name={item.name}
              color={item.color}
              percent={item.percent}
              value={item.value}
              key={item.name}
            />
          );
        })}
      </div>
    </div>
  );
};

export interface TaskCompletedItemDataModel {
  name: string;
  completed: number;
}
export interface TaskCompletedChartProps {
  isLoading?: boolean;
}
const TaskCompletedChart = ({ isLoading = false }: TaskCompletedChartProps) => {
  const {
    taskCompletedData,
    taskCompletedFilter,
    setTaskCompletedFilter,
  } = useDashboardContext();
  const filterOptions = [
    { label: "Daily", value: "day" },
    { label: "Monthly", value: "month" },
  ];

  const rows = useMemo(() => {
    return taskCompletedData.map(row => {
      const formatDate = (dateStr: string) => {
        const local = moment(new Date(dateStr)).local();
        if (taskCompletedFilter === "month") {
          return local.format("MMM");
        }
        return local.format("DD/MM");
      }

      return {
        ...row,
        name: formatDate(row.name),
      }
    });
  }, [taskCompletedData, taskCompletedFilter]);

  return (
    <div>
      <div className="flex items-center gap-4 px-4 py-2 mt-4">
        <span className="text-sm text-background-700">Date filter</span>
        <div className="flex-none">
          <VBSelectComponent
            defaultValue={filterOptions.find((p) => p.value === taskCompletedFilter)}
            options={filterOptions}
            onChange={(option: any) => setTaskCompletedFilter(option.value as string)}
          />
        </div>
      </div>

      {
        isLoading &&
        <div className="w-full h-2 z-0 py-20 px-2">
          <LinearProgress />
        </div>
      }
      {
        !isLoading &&
        <div className="relative w-full h-52">
          <div className="absolute bottom-0 top-4 left-4 right-4">
            <div className="w-full h-full overflow-hidden overflow-x-auto">
              <ResponsiveContainer
                width="100%"
                height="100%"
                minWidth={(24 + 6) * taskCompletedData.length}
              >
                <BarChart
                  barGap={6}
                  height={300}
                  data={rows}
                  maxBarSize={40}
                  barSize={24}
                  margin={{
                    top: 16,
                    right: 16,
                    left: 16,
                    bottom: 16,
                  }}
                >
                  <XAxis
                    dataKey="name"
                    fontSize={11}
                    tickLine={false}
                    axisLine={false}
                  />
                  <Bar dataKey="completed" fill="#3990FF">
                    <LabelList
                      dataKey="completed"
                      position="top"
                      fontSize={11}
                      fill="#53616D"
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      }
    </div>
  );
};
