/*
 * File: label-distribution.state.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 2:38:23 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */


import { BaseState, RequestStatus } from "store/base/base.state";

export interface StepDistributionDTO {
  agreeClassification: Record<string, number>;
  agreeSegmentation: Record<string, number> | null;
  avgIou: Record<string, number> | null;
  oneLabeler: Record<string, number> | null;
  avgProbability: Record<string, number> | null;
  step: number;
}

export interface LabelDistributionDTO {
  id: number;
  steps: Record<number, StepDistributionDTO>;
}

export interface LabelDistributionState
  extends BaseState<LabelDistributionDTO> {}

export const INITIAL_LABEL_DISTRIBUTION_STATE: LabelDistributionState = {
  entities: {},
  ids: [],
  id: -1,
  status: RequestStatus.IDLE,
  error: null,
};
