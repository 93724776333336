import { PayloadAction } from "@reduxjs/toolkit";
import { ImageWorkspaceState } from "../image-workspace.state";
import { IouAnnotation } from "./image-iou.state";

export const imageIouActions = {
  setImageIouAnnotations(
    { imageIou }: ImageWorkspaceState,
    action: PayloadAction<IouAnnotation[]>
  ) {
    imageIou.iouAnnotations = action.payload;
  },
  setImageIouActiveScore(
    { imageIou }: ImageWorkspaceState,
    action: PayloadAction<number>
  ) {
    imageIou.iouActiveScore = action.payload
  }
};
