/*
 * File: user-notification.state.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 16th September 2021 9:34:24 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { NotificationSettingsDTO } from "services/notification-service/dto/notification-settings.dto";
import { NotificationDTO } from "services/notification-service/dto/notification.dto";
import { BaseState, RequestStatus } from "store/base/base.state";

export interface UserNotificationState extends BaseState<NotificationDTO> {
  numberOfUnreadNotifications: number;
  settings: NotificationSettingsDTO | null;
}

export const INITIAL_USER_NOTIFICATION_STATE: UserNotificationState = {
  entities: {},
  ids: [],
  id: -1,
  status: RequestStatus.IDLE,
  error: null,
  numberOfUnreadNotifications: 0,
  settings: null
};
