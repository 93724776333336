/*
 * File: model-ais-api.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 21st February 2022 11:36:26 am
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AIModelDTO } from "services/label-service/dtos/ai-model.dto";
import { RequestOptions } from "services/common/request-options";
import { BaseAPI } from "./base.api";

export class ModelAIsAPI extends BaseAPI<AIModelDTO> {
  getModelAIs(payload = { size: "500" }): Promise<AIModelDTO[]> {
    return this.getItems(payload).then((res) => res.data);
  }

  deleteModelAI(id: number) {
    return this.deleteItem(id);
  }

  createModelAI(payload: Partial<AIModelDTO>) {
    return this.createItem(payload);
  }

  getModelAIsFake(options: RequestOptions = { maxResult: "500" }) {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const result = [
          {
            id: 0,
            name: "Pet detection",
            version: "1.4",
            createdDate: new Date().toUTCString(),
          },
          {
            id: 1,
            name: "Weapon detection",
            version: "2.0",
            createdDate: new Date().toUTCString(),
          },
          {
            id: 2,
            name: "Kidnapping detection",
            version: "2.1",
            createdDate: new Date().toUTCString(),
          },
          {
            id: 3,
            name: "Vehicle detection",
            version: "2.2",
            createdDate: new Date().toUTCString(),
          },
          {
            id: 4,
            name: "Face detection",
            version: "2.3",
            createdDate: new Date().toUTCString(),
          },
        ];
        resolve(result);
      }, 200)
    );
  }
}
