/*
 * File: jobs-thumbnail.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 10th December 2021 5:08:01 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Job } from "domain/labeling/job";
import { useState } from "react";
import {
  ImageLabelingJob,
  JobStatus,
} from "store/labeler/image-workspace/batch-labeling/batch-labeling.state";
import { JobThumbnail } from "./job-thumbnail.component";

interface JobsThumbnailProps {
  currentJobId: number;
  jobDatas: ImageLabelingJob[];
  jobStatuses: { [key: number]: JobStatus };
  onSelectJob(job: Job): void;
}

export const JobsThumbnail = ({
  currentJobId,
  jobDatas,
  jobStatuses,
  onSelectJob,
}: JobsThumbnailProps) => {
  const [loadingIndex, setLoadingIndex] = useState(0);
  const handleImageLoaded = (index: number) => {
    setLoadingIndex(Math.max(index, loadingIndex) + 1);
  };
  const handleImageFailed = (index: number) => {
    setLoadingIndex(Math.max(index, loadingIndex) + 1);
  };

  return (
    <div className="flex flex-col gap-4 p-4">
      {jobDatas.map(({ job, file }, index) => {
        const status = jobStatuses.hasOwnProperty(job.id)
          ? jobStatuses[job.id]
          : JobStatus.NEW;
        return (
          <JobThumbnail
            key={job.id}
            selected={job.id === currentJobId}
            index={index + 1}
            job={job}
            file={file}
            load={index <= loadingIndex}
            status={status}
            onSelect={onSelectJob}
            onLoaded={() => handleImageLoaded(index)}
            onLoadError={() => handleImageFailed(index)}
          />
        );
      })}
    </div>
  );
};
