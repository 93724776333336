// common used for label, labeler distribution tree table

import { useBuildObservationStatsRowModelTree } from "hooks/use-observation-tree";
import { ObservationStatsRowModel } from "models/common/observations-stats";
import { ObservationDTO } from "services/label-service/dtos";
import { ObservationTreeNodeModel } from "services/label-service/model/observation-tree-node.model";
import { classnames } from "utilities/classes";
import { TreeTableNameCell, TreeTableShowChildrenCell } from "./tree-table-layout.component";

export const LABEL_DIST_COLUMNS: ObservationDistTreeColDef[] = [
  {
    field: "oneLabeler",
    header: "1 Labeler",
    flex: 1,
  },
  {
    field: "agreeClassification",
    header: "Agreed classification",
    flex: 1,
  },
  {
    field: "agreeSegmentation",
    header: "Agreed segmentation",
    flex: 1,
  },
  {
    field: "avgIou",
    header: "Average IoU",
    flex: 1,
  },
]

export const LABEL_DIST_COLUMNS_TEXT: ObservationDistTreeColDef[] = [
  ...LABEL_DIST_COLUMNS.slice(0, LABEL_DIST_COLUMNS.length - 1)
]

export const LABELER_DIST_COLUMNS: ObservationDistTreeColDef[] = [
  {
    field: "classificationAgree",
    header: "Agreed classification",
    flex: 2,
  },
  {
    field: "segmentationAgree",
    header: "Agreed segmentation",
    flex: 2,
  },
  {
    field: "avgIou",
    header: "Average IoU",
    flex: 2,
  },
  {
    field: "f1score",
    header: "F1 Score",
    flex: 1,
  },
  {
    field: "sensitivity",
    header: "Sensitivity",
    flex: 1.5,
  },
  {
    field: "specificity",
    header: "Specificity",
    flex: 1.5,
  },
  {
    field: "precision",
    header: "Precision",
    flex: 1.5,
  },
  {
    field: "fp",
    header: "FP",
    flex: 1,
  },
  {
    field: "fn",
    header: "FN",
    flex: 1,
  },
  {
    field: "tp",
    header: "TP",
    flex: 1,
  },
  {
    field: "tn",
    header: "TN",
    flex: 1,
  },
]

export const LABELER_DIST_COLUMNS_TEXT: ObservationDistTreeColDef[] = [
  ...LABELER_DIST_COLUMNS.slice(0, 3),
  ...LABELER_DIST_COLUMNS.slice(4),
]
export interface ObservationDistTreeColDef {
  field: string;
  header: string;
  flex: number;
}

export interface ObservationDistTreeProps{
  rows: ObservationStatsRowModel[],
  columns: ObservationDistTreeColDef[],
  nameFlex?: number;
}
export const ObservationDistTree = ({
  rows,
  columns,
  nameFlex = 1.5,
}: ObservationDistTreeProps) => {
  const {
    setShowChildren,
    nodesList,
  } = useBuildObservationStatsRowModelTree(rows);
  const cellDataClass = `p-2 self-center h-18 truncate`;
  const headerCellClass = "p-2 self-center h-18 break-words overflow-hidden";

  const formatDataCell = (value: number | string) => {
    const type = typeof value;
    if (type === "number") {
      return (value as number).toFixed(2);
    }
    return value;
  }

  return (
    <div className="flex flex-col w-full bg-white">
      <div className="flex w-full font-semibold border-b">
        <div className={headerCellClass} style={{flex: nameFlex}}>
          Name
        </div>
        <div className={headerCellClass} style={{flex: 0.5}}>
        </div>
        {
          columns.map(col => (
            <div key={col.field} className={headerCellClass} style={{flex: col.flex}}>
              <TreeTableNameCell
                name={col.header}
                nodeHeight={0}
                paddingScaler={0}
              />
            </div>
          ))
        }
      </div>
      {
        nodesList.map(node => {
          const rowData: any = node.value as any;
          const isLeaf = ObservationTreeNodeModel.isLeaf(node);
          const isActive = (rowData["observation"] as ObservationDTO).active;
          return (
            <div key={node.id}>
              {
                node.visible &&
                <div key={node.id}
                     className={classnames(
                        "flex w-full border-b",
                        {"text-gray-400": !isActive}
                      )}>
                  <div className={cellDataClass} style={{flex: nameFlex}}>
                    <TreeTableNameCell
                      name={node.value?.observation.name}
                      nodeHeight={node.height}
                    />
                  </div>
                  <div className={cellDataClass} style={{flex: 0.5}}>
                    <TreeTableShowChildrenCell
                      isLeaf={isLeaf}
                      showChildren={node.showChildren}
                      setShowChildren={setShowChildren}
                      nodeId={node.id}
                    />
                  </div>
                  {
                    columns.map(col => (
                      <div  key={col.field}
                            style={{flex: col.flex}}
                            className={cellDataClass} >
                        {rowData.hasOwnProperty(col.field) ? formatDataCell(rowData[col.field]) : ""}
                      </div>
                    ))
                  }
                </div>
              }
            </div>
          )
        })
      }
    </div>
  )
}
