/*
 * File: cornerstone-handler.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 1:36:44 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import * as csTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";
import { Annotation as ImageAnnotation } from "domain/image-labeling/annotation";
import { BaseEditor } from "../base-editor/base-editor";
import {
  MeasurementData,
  MeasurementRelationData,
} from "../models/measurement.model";
import {
  AnnotateType,
  annotateTypeMapper,
} from "constants/annotation.constant";
import { Logger } from "utilities/logger";
import { Rectangle } from "utilities/math/rectangle";
import { Point } from "utilities/math/point";
import { annotationUtils } from "utilities/annotations/annotation-util";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";
import { imageAnnotationUtils } from "store/labeler/image-workspace/image-annotations/image-annotations.util";
import { measurementUtils } from "utilities/annotations/measurement-util";
import { Label } from "domain/image-labeling";
import * as Sentry from "@sentry/react";
import { SAMData } from "modules/onnix/context/onnix-state";
import { OnnxLabel } from "modules/onnix/helpers/Interfaces";

const scrollToIndex = csTools.importInternal("util/scrollToIndex");

export interface ImageFrameHandler {
  setFrameIdx(idx: number): void;
}

export interface SAMHandler {
  removeSAMMeasurements(ignoreIds: string[]): void;
  setSAMMode(strategy: string): void;
  updateSAMAnnotations(toolData: SAMData, label: OnnxLabel): void;
}

export interface ImageEditorData {
  observation: Label | undefined;
  tool: ToolName | "";
  showHeartRuler: boolean;
  userId: string;
  jobId: string;
  imageAnnotations: ImageAnnotation[];
  measurements: Record<string, MeasurementData>;
  imageIds?: string[];
}

export class CornerstoneHandler
  extends BaseEditor
  implements ImageEditorData, ImageFrameHandler, SAMHandler
{
  measurements: Record<string, MeasurementData> = {};
  relationMeasurements: Record<string, MeasurementRelationData> = {};
  imageAnnotations: ImageAnnotation[] = [];
  relationAnnotations: RelationAnnotation[] = [];
  observation: Label | undefined = undefined;
  tool: ToolName | "" = "";
  showHeartRuler: boolean = false;
  userId: string = "";
  jobId: string = "";
  imageIds?: string[] = [];

  init(options: Partial<ImageEditorData>): void {
    Object.assign(this, options);
  }

  setHeartRuler(showHeartRuler: boolean): void {
    this.showHeartRuler = showHeartRuler;
    const toolState = this.getToolState(ToolName.HeartRuler);
    toolState.visible = showHeartRuler;
    this.setToolPassive(ToolName.HeartRuler);
    this.updateImage();
  }

  setImageAnnotations(annotations: ImageAnnotation[]): void {
    return this.syncImageAnnotations(annotations);
  }

  setImageRelationAnnotations(annotations: RelationAnnotation[]): void {
    return this.syncImageRelationAnnotations(annotations);
  }

  syncToolStates(annotations: ImageAnnotation[], tool: ToolName) {
    const container = this.getContainer();
    const enableTool = csTools.getToolForElement(container, tool);
    if (!enableTool) return;
    const annotationEntities = imageAnnotationUtils.toEntities(annotations);
    const measurementsToRemove: MeasurementData[] = [];
    this.setToolActive(tool);
    const toolStates = this.getToolStates(tool);
    for (const toolState of toolStates) {
      if (!toolState || !toolState.data || !toolState.data.length) continue;
      for (let idx = 0; idx < toolState.data.length; idx++) {
        const data = toolState.data[idx];
        if (!data) continue;
        if (!annotationEntities.hasOwnProperty(data.uuid)) {
          measurementsToRemove.push(data);
        } else {
          const annotation = annotationEntities[data.uuid];
          annotationUtils.updateMeasurement(data, annotation);
          this.measurements[data.uuid] = data;
          delete annotationEntities[data.uuid];
        }
      }
    }

    for (const measurement of measurementsToRemove) {
      csTools.removeToolState(container, tool, measurement);
    }
    for (const key of Object.keys(annotationEntities)) {
      const annotation = annotationEntities[key];
      const measurement = annotationUtils.toMeasurement(annotation);
      if (!measurement) continue;
      this.measurements[measurement.uuid] = measurement;
      if (!this.imageIds || this.imageIds.length === 0) {
        csTools.addToolState(container, tool, measurement);
      } else if (!measurement.frameId) {
        const imageId = this.imageIds[0];
        csTools.globalImageIdSpecificToolStateManager.addImageIdToolState(
          imageId,
          tool,
          measurement
        );
      } else {
        const imageId = this.imageIds[measurement.frameId];
        csTools.globalImageIdSpecificToolStateManager.addImageIdToolState(
          imageId,
          tool,
          measurement
        );
      }
    }
  }

  syncImageAnnotations(annotations: ImageAnnotation[]): void {
    const container = this.getContainer();
    const currentTool = this.tool;
    this.measurements = {};
    const bboxAnnotations = annotations.filter(
      (anno) => anno.annotationData[0].type === AnnotateType.BOUNDING_BOX
    );
    const polyAnnotations = annotations.filter(
      (anno) => anno.annotationData[0].type === AnnotateType.POLYGON
    );

    if (bboxAnnotations.length === 0) {
      csTools.clearToolState(container, ToolName.RectangleRoiExtend);
    }

    if (polyAnnotations.length === 0) {
      csTools.clearToolState(container, ToolName.FreehandRoiExtend);
    }

    this.syncToolStates(bboxAnnotations, ToolName.RectangleRoiExtend);
    this.syncToolStates(polyAnnotations, ToolName.FreehandRoiExtend);
    if (currentTool) this.setToolActive(currentTool);
    this.updateImage();
  }

  syncImageRelationAnnotations(annotations: RelationAnnotation[]): void {
    const container = this.getContainer();
    const currentTool = this.tool;
    const measurementsToRemove: any[] = [];
    const annotationEntities =
      imageAnnotationUtils.toRelationEntities(annotations);
    const toolStates = this.getToolState(ToolName.MultiArrowConnection);
    this.relationMeasurements = {};
    this.setToolActive(ToolName.MultiArrowConnection);

    if (toolStates?.data?.length) {
      for (let d = 0; d < toolStates.data.length; d++) {
        const data = toolStates.data[d];
        if (data?.locked) continue;
        if (!data) continue;
        if (!annotationEntities.hasOwnProperty(data.uuid)) {
          measurementsToRemove.push(data);
        } else {
          const annotation = annotationEntities[data.uuid];
          annotationUtils.updateRelationMeasurement(data, annotation);
          this.relationMeasurements[data.uuid] = data;
          delete annotationEntities[data.uuid];
        }
      }
    }

    for (const measurement of measurementsToRemove) {
      csTools.removeToolState(
        container,
        ToolName.MultiArrowConnection,
        measurement
      );
    }

    for (const key of Object.keys(annotationEntities)) {
      const annotation = annotationEntities[key];
      const measurement = annotationUtils.toRelationMeasurement(
        annotation,
        this.measurements
      );
      if (!measurement) continue;
      this.relationMeasurements[measurement.uuid] = measurement;
      csTools.addToolState(
        this.getContainer(),
        ToolName.MultiArrowConnection,
        measurement
      );
    }

    if (currentTool) this.setToolActive(currentTool);
    this.updateImage();
  }

  setMaskedAnnotations(maskedAnnotation: Rectangle[]): void {
    const container = this.getContainer();
    const tool = ToolName.Mask;
    csTools.clearToolState(container, tool);
    const measurementDatas = maskedAnnotation.map((item) => {
      return {
        visible: true,
        active: true,
        color: undefined,
        invalidated: true,
        handles: {
          start: {
            x: item.x,
            y: item.y,
            highlight: true,
            active: false,
          },
          end: {
            x: item.x + item.width,
            y: item.y + item.height,
            highlight: true,
            active: true,
          },
          initialRotation: 0,
          textBox: {
            active: false,
            hasMoved: false,
            movesIndependently: false,
            drawnIndependently: true,
            allowedOutsideImage: true,
            hasBoundingBox: true,
          },
        },
      };
    });
    const toolOption = { mouseButtonMask: 1 };
    csTools.setToolActiveForElement(container, tool, toolOption);
    measurementDatas.forEach((measurement) => {
      csTools.addToolState(container, tool, measurement);
    });
    this.updateImage();
  }

  setToolPassive(tool: ToolName): void {
    return csTools.setToolPassiveForElement(this.getContainer(), tool);
  }

  setToolActive(type: ToolName): void {
    if (type === this.tool) return;
    this.tool = type;
    if (type === ToolName.StackScrollMouseWheel) {
      csTools.setToolActiveForElement(this.getContainer(), ToolName.Pan, {
        mouseButtonMask: 1,
      });
      csTools.setToolActiveForElement(
        this.getContainer(),
        ToolName.StackScrollMouseWheel,
        {
          mouseButtonMask: 4,
        }
      );
      csTools.setToolActiveForElement(this.getContainer(), ToolName.Zoom, {
        mouseButtonMask: 2,
      });
      return;
    }

    const isPan = type === ToolName.Pan;

    csTools.setToolActiveForElement(this.getContainer(), type, {
      mouseButtonMask: 1,
    });
    csTools.setToolActiveForElement(this.getContainer(), "ZoomMouseWheel", {
      mouseButtonMask: 4,
    });
    csTools.setToolActiveForElement(
      this.getContainer(),
      isPan ? ToolName.Zoom : ToolName.Pan,
      {
        mouseButtonMask: 2,
      }
    );
  }

  updateImage(): void {
    return cornerstone.updateImage(this.getContainer());
  }

  setCurrentObservation(label: Label | undefined) {
    this.observation = label;
  }

  getTool(name: ToolName) {
    return csTools.getToolForElement(this.getContainer(), name);
  }

  getToolState(name: ToolName) {
    return csTools.getToolState(this.getContainer(), name);
  }

  getToolStates(name: ToolName) {
    if (this.imageIds && this.imageIds.length > 0) {
      return this.imageIds.map((imageId) => {
        return csTools.globalImageIdSpecificToolStateManager.getImageIdToolState(
          imageId,
          name
        );
      });
    }
    return [csTools.getToolState(this.getContainer(), name)];
  }

  cancelDrawing(): void {
    const element = this.getContainer();
    const tool = this.getTool(this.tool as ToolName);
    if (!tool) return;
    if (tool["cancelDrawing"]) {
      tool.cancelDrawing(element);
    }
  }

  completeDrawing(): void {
    const element = this.getContainer();
    const tool = this.getTool(this.tool as ToolName);
    if (!tool) return;
    if (tool["completeDrawing"]) {
      tool.completeDrawing(element);
    }
  }

  deleteActiveAnnotation(): void {
    const data = this.getActiveMeasurement();
    if (data) this.removeMeasurement(data);
  }

  modifyPolygon(uuid: string, strategy: string): void {
    const tool = csTools.getToolForElement(
      this.getContainer(),
      ToolName.FreehandRoiExtend
    );
    tool?.startModifyMeasurement(uuid, strategy);
  }

  getActiveMeasurement(): MeasurementData | null {
    const arrowToolState = this.getToolState(ToolName.MultiArrowConnection);
    if (arrowToolState?.data?.length) {
      for (let d = 0; d < arrowToolState.data.length; d++) {
        const data = arrowToolState.data[d];
        if ((data?.active || data?.hovering) && data?.visible) {
          return data;
        }
      }
    }

    const bboxToolState = this.getToolState(ToolName.RectangleRoiExtend);
    if (bboxToolState?.data?.length) {
      for (let d = 0; d < bboxToolState.data.length; d++) {
        const data = bboxToolState.data[d];
        if ((data?.active || data?.hovering) && data?.visible) {
          return data;
        }
      }
    }

    const polyToolState = this.getToolState(ToolName.FreehandRoiExtend);
    if (polyToolState?.data?.length) {
      for (let d = 0; d < polyToolState.data.length; d++) {
        const data = polyToolState.data[d];
        if ((data?.active || data?.hovering) && data?.visible) {
          return data;
        }
      }
    }

    return null;
  }

  getCurrentColor() {
    if (this.observation) {
      return this.observation.color;
    }
    return "#FFFFFF";
  }

  addMeasurement(measurement: MeasurementData) {
    // this.measurements[measurement.uuid] = measurement;
  }

  onRelationAnnotationUpdated(measurement: MeasurementRelationData) {
    if (!measurement) return;
    this.relationMeasurements[measurement.uuid] = measurement;
  }

  onMeasurementUpdated(measurement: MeasurementData) {
    if (!measurement) return;
    const uuid = measurement.uuid;
    for (let key of Object.keys(this.relationMeasurements)) {
      const relationMeasurement = this.relationMeasurements[key];
      const handlePoint = relationMeasurement.handles.points.find(
        (p: any) => p.measurementUUID === uuid
      );
      if (!handlePoint) continue;
      const point = measurementUtils.measurementCenterPoint(measurement);
      if (!point) continue;
      handlePoint.x = point.x;
      handlePoint.y = point.y;
    }
  }

  onMeasurementModified(measurement: MeasurementData) {
    if (!measurement) return;
    const uuid = measurement.uuid;
    for (let key of Object.keys(this.relationMeasurements)) {
      const relationMeasurement = this.relationMeasurements[key];
      const handlePoint = relationMeasurement.handles.points.find(
        (p: any) => p.measurementUUID === uuid
      );
      if (!handlePoint) continue;
      const point = measurementUtils.measurementCenterPoint(measurement);
      if (!point) continue;
      handlePoint.x = point.x;
      handlePoint.y = point.y;
    }
  }

  onMeasurementRemoved(measurement: MeasurementData) {
    if (!measurement) return;
    for (let key of Object.keys(this.relationMeasurements)) {
      const relationMeasurement = this.relationMeasurements[key];
      if (
        relationMeasurement.handles.points.find(
          (p: any) => measurement.uuid === p?.measurementUUID
        )
      ) {
        csTools.removeToolState(
          this.getContainer(),
          ToolName.MultiArrowConnection,
          relationMeasurement
        );
        delete this.relationMeasurements[key];
        this.updateImage();
      }
    }
  }

  removeMeasurement(measurement: MeasurementData) {
    if (!measurement?.type) return;
    const tool = annotateTypeMapper(measurement.type);
    if (!tool) return;
    const container = this.getContainer();
    csTools.removeToolState(container, tool, measurement);
    this.updateImage();
  }

  addAutoAnnotation(annotateType: AnnotateType, measurement: MeasurementData) {
    this.imageAnnotations = this.imageAnnotations.filter(
      (anno) => anno.uuid !== measurement.uuid
    );
    const currentTool = this.tool;
    const cornerstoneToolName = annotateTypeMapper(annotateType);
    if (!cornerstoneToolName) return;
    csTools.setToolActiveForElement(this.getContainer(), cornerstoneToolName, {
      mouseButtonMask: 1,
    });
    csTools.addToolState(this.getContainer(), cornerstoneToolName, measurement);
    if (currentTool) this.setToolActive(currentTool);
    this.updateImage();
  }

  resize(forceFitWindow: boolean = true): void {
    return cornerstone.resize(this.getContainer(), forceFitWindow);
  }

  clearToolStates() {
    const element = this.getContainer();
    csTools.clearToolState(element, ToolName.Angle);
    csTools.clearToolState(element, ToolName.CobbAngle);
    csTools.clearToolState(element, ToolName.Eraser);
    csTools.clearToolState(element, ToolName.FreehandRoi);
    csTools.clearToolState(element, ToolName.FreehandRoiExtend);
    csTools.clearToolState(element, ToolName.HeartRuler);
    csTools.clearToolState(element, ToolName.RectangleRoi);
    csTools.clearToolState(element, ToolName.RectangleRoiExtend);
    csTools.clearToolState(element, ToolName.MultiArrowConnection);
    csTools.clearToolState(element, ToolName.Wwwc);
    csTools.clearToolState(element, ToolName.Magnify);
    csTools.clearToolState(element, ToolName.Zoom);
    csTools.clearToolState(element, ToolName.Pan);
    csTools.clearToolState(element, ToolName.Invert);
    csTools.clearToolState(element, ToolName.Rotate);
    this.measurements = {};
    this.imageAnnotations = [];
    this.relationAnnotations = [];
    this.relationMeasurements = {};
  }

  currentImageId = "";
  setCurrentImageId(imageId?: string) {
    this.currentImageId = imageId || "";
  }
  getCurrentImageId() {
    return this.currentImageId;
  }

  frameIdx = 0;
  setFrameIdx(idx: number) {
    this.frameIdx = idx;
    scrollToIndex(this.getContainer(), idx);
  }

  setAnnotationTextValue(annotationId: string, text: string): void {
    if (this.measurements.hasOwnProperty(annotationId)) {
      this.measurements[annotationId].text = text;
    }
  }

  activeUUID = "";
  onMeasurementHovered(uuid: string) {
    try {
      if (uuid && this.measurements.hasOwnProperty(uuid)) {
        this.measurements[uuid].hovering = true;
        this.measurements[uuid].active = true;
        this.activeUUID = uuid;
        this.updateImage();
      }
    } catch (error) {}
  }

  onMeasurementHoverLeave(uuid: string) {
    try {
      if (uuid && this.measurements.hasOwnProperty(uuid)) {
        this.measurements[uuid].hovering = false;
        this.measurements[uuid].active = false;
        this.updateImage();
      }
      if (uuid === this.activeUUID) {
        this.activeUUID = "";
      }
    } catch (error) {}
  }

  canvasPointToPage(position: Point): Point {
    const root = { x: 0, y: 0 };
    const viewport = cornerstone.getViewport(this.getContainer());
    const imagePos = cornerstone.canvasToPixel(this.getContainer(), root);
    return {
      x: (position.x - imagePos.x) * viewport.scale,
      y: (position.y - imagePos.y) * viewport.scale,
    };
  }

  canvasRectToPage(rect: Rectangle): Rectangle {
    const p1 = this.canvasPointToPage({ x: rect.x, y: rect.y });
    const p2 = this.canvasPointToPage({
      x: rect.x + rect.width,
      y: rect.y + rect.height,
    });
    return {
      x: p1.x,
      y: p1.y,
      width: p2.x - p1.x,
      height: p2.y - p1.y,
    };
  }

  dispose() {
    this.clearToolStates();
    cornerstone.disable(this.getContainer());
    this.imageAnnotations = [];
    this.measurements = {};
    try {
      if (this.currentImageId) {
        cornerstone.imageCache.removeImageLoadObject(this.currentImageId);
      } else {
        cornerstone.imageCache.purgeCache();
      }
    } catch (error) {
      Sentry.captureException(error);
      Logger.log(error);
    }
  }

  removeSAMMeasurements(ignoreIds: string[]): void {
    const toolState = this.getToolState(ToolName.SAM);
    const measurementsToRemove = [];
    if (!toolState || !toolState.data || !toolState.data.length) return;
    for (let idx = 0; idx < toolState.data.length; idx++) {
      const data = toolState.data[idx];
      if (!data) continue;
      if (!ignoreIds.includes(data["uuid"])) {
        measurementsToRemove.push(data);
      }
    }
    const container = this.getContainer();

    for (const measurement of measurementsToRemove) {
      csTools.removeToolState(container, ToolName.SAM, measurement);
    }
    this.updateImage();
  }

  setSAMMode(mode: string): void {
    const tool = csTools.getToolForElement(this.getContainer(), ToolName.SAM);
    tool?.setMode(mode);
  }

  getImageData() {
    const enabledElement = cornerstone.getEnabledElement(this.getContainer());
    const data = enabledElement.image
      .getCanvas()
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const { width, height } = enabledElement.image;
    return { width, height, data };
  }

  updateSAMAnnotations(toolData: SAMData, label: OnnxLabel) {
    const { uuid, bboxes, polygons } = toolData;
    const toolState = this.getToolState(ToolName.SAM);
    if (!toolState || !toolState.data || !toolState.data.length) return;
    for (let idx = 0; idx < toolState.data.length; idx++) {
      const data = toolState.data[idx];
      if (!data) continue;
      if (data.uuid !== uuid) continue;
      data.polygons = label.type === AnnotateType.POLYGON ? polygons : [];
      data.bboxes = label.type === AnnotateType.BOUNDING_BOX ? bboxes : [];
    }
    this.updateImage();
  }
}
