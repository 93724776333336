import { FilterCommon } from "domain/common/filter";
import moment from "moment";
import { RequestOptions } from "services/common/request-options";


export class ProfileBillFilter extends FilterCommon {
  id?: number;
  issueDate?: Date;
  serviceName?: string;
  description?: string;
  totalPrice?: number;
  currencyId?: string;
  fromDate?: Date;
  toDate?: Date;

  static toRequestOption(filter: ProfileBillFilter): RequestOptions {
    const options = FilterCommon.toRequestOption(filter);

    if (filter.id) options["id.equals"] = filter.id.toString();
    if (filter.serviceName) options["serviceName.contains"] = filter.serviceName;
    if (filter.description) options["description.contains"] = filter.description;
    if (filter.totalPrice) options["totalValue.equals"] = filter.totalPrice.toString();
    if (filter.currencyId) options["currencyId.equals"] = filter.currencyId.toString();
    if (filter.fromDate) options["issuedDate.greaterThanOrEqualTo"] = moment(filter.fromDate).utc().toDate().toISOString();
    if (filter.toDate) options["issuedDate.lessThanOrEqualTo"] = moment(filter.toDate).utc().toDate().toISOString();

    return options;
  }
}

export enum BillType {
  MANUAL_LABELING = "manual_labeling",
  FEE = "fee",
}
export interface ProfileBillRow {
  id: number;
  type: BillType;
  issueDate: Date;
  serviceName: string;
  description: string;
  totalPrice: number;
  currencyCode: string | undefined;
  currencyId: number;
  detail: BillDetailBatchCommon;
}

export interface BillDetailBatchCommon {
  batchId: number;
  batchName: string;
  startDate: Date;
  endDate: Date;
  // manual stuff
  priceRows?: BillDetailManualLabelingPriceRow[];
  // fee stuff
  fee?: number;
}
export interface BillDetailManualLabelingPriceRow {
  numUnit: number;
  perUnit: number;
}
