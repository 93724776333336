/*
 * File: models.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 24th September 2021 11:54:37 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { RequestOptions } from "services/common/request-options";
import { LabelAPI } from "../http/label-service.httpclient";

export class MLModelsAPI {
  private _PATH_: string;
  constructor(path: string) {
    this._PATH_ = path;
  }
  
  getMLModelsTraining(options: RequestOptions = { maxResult: "500" }) {
    const params = new URLSearchParams(options);
    return LabelAPI.get(`${this._PATH_}?${params.toString()}`);
  }
}
