/*
 * File: ai-models-actions.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 3rd November 2022 5:33:49 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { IconDocumentUpload } from "components/common/vb-icon.component";
import { useAIModelsContext } from "../context/ai-models.context";

export default function AIModelsActions() {
  const { onClickUpload } = useAIModelsContext();
  return (
    <div className="flex items-center gap-4">
      <button className="button-primary" onClick={onClickUpload}>
        <IconDocumentUpload className="flex-none w-5 h-5 text-white" />
        <span>Upload model</span>
      </button>
    </div>
  );
}
