import React, { useCallback, useMemo } from "react";
import Table from "@material-ui/core/Table";

import { TableBaseBody } from "./table-base-body.component";
import { TableBaseHeader } from "./table-base-header.component";
import { TableWrapper } from "./table-wrapper.component";
import { TableBaseProps } from "./types";

export const TableBase = <T extends object>(props: TableBaseProps<T>) => {
  const {
    body,
    bodyProps,
    classNames,
    children,
    columns,
    data,
    expandable,
    loading,
    header,
    headerProps,
    tableProps,
    wrapperProps,
    skeCount,
    skeHeight
  } = props;

  const renderComponent = useCallback(
    (defaultComponent: React.ReactNode, component?: React.ReactNode | null) => {
      return component === null ? null : component || defaultComponent;
    },
    []
  );

  const normalizeColumns = useMemo(() => {
    return expandable ? [{key: 'expand'}, ...columns] : columns
  }, [expandable, columns])

  return (
    <TableWrapper {...classNames} {...wrapperProps}>
      <Table className={classNames?.table} {...tableProps}>
        {children || (
          <>
            {renderComponent(
              <TableBaseHeader
                classNames={classNames}
                columns={normalizeColumns}
                {...headerProps}
              />,
              header
            )}
            {renderComponent(
              <TableBaseBody
                classNames={classNames}
                columns={normalizeColumns}
                expandable={expandable}
                data={data}
                loading={loading}
                skeCount={skeCount}
                skeHeight={skeHeight}
                {...bodyProps}
              />,
              body
            )}
          </>
        )}
      </Table>
    </TableWrapper>
  );
};
