import { VBNumberTextInputComponent } from "components/design-system/number-text-input/number-text-input.component"
import { CurrencySelectOption } from "store/common/payment/payment.state"

export interface PriceByTaskComponentProps {
  defaultPerTask?: number;
  selectedCurrencyOption: CurrencySelectOption;
  currencyOptions: CurrencySelectOption[];
  onCurrencyOptionChanged?: (v: CurrencySelectOption) => void;
  onPerTaskChanged?: (v: number) => void;
  selectTarget?: HTMLElement;
  disabled?: boolean;
}

export const PriceByTaskComponent = ({
  defaultPerTask = 0,
  selectedCurrencyOption,
  currencyOptions,
  onCurrencyOptionChanged,
  onPerTaskChanged,
  selectTarget,
  disabled = false,
}: PriceByTaskComponentProps) => {

  return (
    <div className="max-w-xs p-4 rounded bg-background-50">
      <VBNumberTextInputComponent
        label="Price per task"
        defaultValue={defaultPerTask}
        unitOptions={currencyOptions}
        selectedUnit={selectedCurrencyOption}
        onSelectedUnitChanged={onCurrencyOptionChanged}
        onValueChanged={v => onPerTaskChanged && onPerTaskChanged(v as number)}
        min={0}
        selectTarget={selectTarget}
        disabled={disabled}
      />
    </div>
  )
}