import { LabelingMode } from "domain/labeling/labeling-mode";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect } from "react";
import {
  selectActiveJobId,
  selectIsLoadedBatchData,
  selectWaitingJobId,
} from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { imageLoadJobAsync } from "store/labeler/image-workspace/batch-labeling/thunks/image-load-job.thunk";
import { imageLabelingJobLoadedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-labeling-job-loaded.thunk";
import { initImageLabelingAsync } from "store/labeler/image-workspace/image-labeling/thunks/init-image-labeling.thunk";

interface Props {
  mode?: LabelingMode;
}
export default function useLoadJob({ mode }: Props) {
  const dispatch = useAppDispatch();
  const isLoaded = useAppSelector(selectIsLoadedBatchData);
  const waitingJobId = useAppSelector(selectWaitingJobId);
  const activeJobId = useAppSelector(selectActiveJobId);

  const loadImageJobAsync = useCallback(async () => {
    await dispatch(imageLoadJobAsync({ jobId: waitingJobId, mode }));
    await dispatch(imageLabelingJobLoadedAsync({ jobId: waitingJobId }));
    if (waitingJobId === activeJobId) {
      await dispatch(initImageLabelingAsync({ jobId: waitingJobId }));
    }
  }, [mode, waitingJobId, activeJobId, dispatch]);

  useEffect(() => {
    if (!isLoaded) return;
    if (waitingJobId !== -1) loadImageJobAsync();
  }, [dispatch, isLoaded, waitingJobId, activeJobId, loadImageJobAsync]);
}
