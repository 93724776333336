/*
 * File: batch.state.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 18th August 2021 5:33:09 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { TaskRow } from "pages/customer/projects/project-batch/batch-detail/pages/tasks/batch-tasks.context";
import {
  BatchDTO,
  BatchObservationDTO,
  BatchWorkflowDTO,
  WorkflowInstructionDTO,
} from "services/label-service/dtos";
import { GenerateAnnotationStatus } from "services/label-service/dtos/annotation.dto";
import { PricingResponseDTO } from "services/label-service/dtos/batch-pricing.dto";
import { WorkManagementDTO } from "services/label-service/dtos/work-management.dto";
import { UserBatchModel } from "services/user-service/dtos/user.dto";
import { RequestStatus } from "store/base/base.state";
import { AtLeast } from "types/common";

export interface BatchState {
  batch: BatchDTO | null;
  workManagements: WorkManagementDTO[];
  labels: BatchObservationDTO[];
  labelConfigChanged: boolean;
  workflow: BatchWorkflowDTO | null;
  instructions: WorkflowInstructionDTO[];
  pricing: PricingResponseDTO | null;
  stepUsers: Record<number, AtLeast<UserBatchModel, "email">[]>;
  requestStatus: RequestStatus;
  requestError: string | null;
  genAnnoAsyncJobStatus: GenerateAnnotationStatus | undefined;
  genAnnoAsyncJobPercentComplete: number;

  isLoadingBatchTasks: boolean;
  tasks: TaskRow[];
  totalTask: number;
  selectedTaskAction: { taskId: number; action: string };
}

export const INITIAL_BATCH_STATE: BatchState = {
  batch: null,
  workManagements: [],
  labels: [],
  labelConfigChanged: false,
  workflow: null,
  instructions: [],
  pricing: null,
  stepUsers: {},
  requestStatus: RequestStatus.IDLE,
  requestError: null,
  genAnnoAsyncJobStatus: undefined,
  genAnnoAsyncJobPercentComplete: 0,
  isLoadingBatchTasks: false,
  tasks: [],
  totalTask: 0,
  selectedTaskAction: {
    taskId: -1,
    action: "",
  },
};
