import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createTicketAsync } from "store/common/customer-support/customer-support.slice";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { TicketSupportForm } from "./ticket-support-form.component";
import { TicketSupportEntity } from "./types";
import * as Sentry from "@sentry/react";

interface TicketSupportModalProps {
  defaultValues?: TicketSupportEntity;
  open: boolean;
  onClose: () => void;
  readOnly?: boolean;
  title?: string;
  titleContent?: string;
}

export const TicketSupportModal = ({
  defaultValues = {},
  open,
  onClose,
  title = "",
  titleContent = "",
}: TicketSupportModalProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState<TicketSupportEntity>(defaultValues);

  const handleSubmit = async () => {
    if (!values.title || !values.description) {
      dispatch(
        enqueueErrorNotification(
          !values.title ? "Title is empty" : "Description is empty"
        )
      );
      return;
    }

    try {
      setIsLoading(true);
      await dispatch(
        createTicketAsync({
          title: values.title,
          description: values.description,
        })
      );
      dispatch(enqueueSuccessNotification("Report is sent successfully"));
      onClose();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      dispatch(enqueueErrorNotification("Report is sent failed"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VBModal
      open={open}
      title={title}
      textSubmit="Send Report"
      onSubmit={handleSubmit}
      onClose={onClose}
      width="40%"
      processingIndicator={isLoading}
      disableSubmit={isLoading}
      blockUI={isLoading}
    >
      <TicketSupportForm
        values={values}
        setValues={setValues}
        setLoading={setIsLoading}
        title={titleContent}
      />
    </VBModal>
  );
};
