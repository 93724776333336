import { useMemo } from "react";
import { ObservationDTO } from "services/label-service/dtos";
import { selectSystemColors } from "store/common/app-setting/app-setting.selectors";
import { useAppSelector } from "hooks/use-redux";


export const useRemainLabelColors = (observations: ObservationDTO[]) => {
  const projectLabelColors = useMemo(() => {
    return observations.map(obs => obs.observationSetting.color);
  }, [observations]);

  const supportedColors = useAppSelector(selectSystemColors);
  
  const remainLabelColors = useMemo(() => {
    return supportedColors.filter((color) => {
      return projectLabelColors.findIndex(labelColor => labelColor.toLowerCase() === color.toLowerCase()) === -1;
    });
  }, [supportedColors, projectLabelColors]);

  return remainLabelColors;
}
