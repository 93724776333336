/*
 * File: annotation.api.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 19th October 2021 3:50:40 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AxiosResponse } from "axios";
import { TaskObservationDTO } from "../dtos";
import { LabelerAnnotationDTO } from "../dtos/annotation.dto";
import { labelerAnnotationMapper } from "../mappers/labeler-annotation.mapper";
import { BaseAPI } from "./base.api";

export class LabelerAnnotationV2API extends BaseAPI<LabelerAnnotationDTO> {
  getAnnotations(
    batchId: number,
    mediaId: number | string,
  ): Promise<AxiosResponse<LabelerAnnotationDTO[]>> {
    return this.getItems({
      batchId: batchId.toString(),
      mediaId: mediaId.toString(),
      size: "1000",
    });
  }
  async getLabelerAnnotations(
    batchId: number,
    mediaId: number
  ): Promise<AxiosResponse<TaskObservationDTO[]>> {
    const response = await this.getItems({
      batchId: batchId.toString(),
      mediaId: mediaId.toString(),
      size: "1000",
    });
    const items = response.data;
    return items.map(labelerAnnotationMapper.toTaskObservation);
  }
}
