
export class ObservationTreeNodeModel<T extends any> {
  value: T | null;
  children: ObservationTreeNodeModel<T>[];
  parent: ObservationTreeNodeModel<T> | null;
  height: number;
  visible: boolean;
  showChildren: boolean;
  id: number;

  constructor(
    value: T | null,
    parent: ObservationTreeNodeModel<T> | null,
    height: number,
    defaultVisible: boolean = false,
  ){
    this.value = value;
    this.children = [];
    this.parent = parent;
    this.height = height;
    this.visible = true;
    this.showChildren = true;
    if (height >= 1) this.showChildren = defaultVisible;
    if (height > 1) this.visible = defaultVisible;
    this.id = -1;
  }

  static isRoot<T>(node: ObservationTreeNodeModel<T>){
    return node.height === 0 || node.parent === null;
  }

  static isLeaf<T>(node: ObservationTreeNodeModel<T>){
    return node.children.length === 0;
  }
}

export interface TreeNodeVisibleData {
  visible: boolean;
  showChildren: boolean;
}

export interface TreeNodeVisibleDataMap {
  [key: number]: TreeNodeVisibleData;
  [key: string]: TreeNodeVisibleData;
}
