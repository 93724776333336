import { Tooltip } from "@material-ui/core";
import { IconArrowForward } from "components/common/vb-icon.component";
import { classnames } from "utilities/classes";


interface Props {
  isActive?: boolean,
  onSubmit?: () => void,
}
export const IssueSubmitButton = ({
  isActive = false,
  onSubmit,
}: Props) => {

  return (
    <div 
      className={classnames(
        "absolute flex items-center justify-center p-1 rounded-full text-white",
        {"bottom-1 right-1 bg-primary": isActive},
        {"bottom-1/2 right-1 transform translate-y-1/2 bg-background-300": !isActive},
      )}
    >
      <Tooltip title="Submit" placement="top">
        <IconArrowForward
          className="w-4 h-4 rounded-full transform -rotate-90 font-bold text-lg"
          onClick={() => onSubmit && isActive && onSubmit()}
        />
      </Tooltip>
    </div>
  );
}