/*
 * File: dataset-activity.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 22nd July 2021 9:19:29 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { CloudDTO } from "models/dataset/cloud.model";
import { useState } from "react";
import { useEffect } from "react";
import { DatasetDTO } from "services/label-service/dtos";
import { selectDatasetClouds } from "store/customer/dataset/dataset.selectors";
import { loadDatasetCloudsAsync } from "store/customer/dataset/dataset.slice";
import * as Sentry from "@sentry/react";

import "./dataset-activity.style.css";

interface Props {
  dataset: DatasetDTO;
}
export const DatasetActivity = ({ dataset }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const clouds = useAppSelector(selectDatasetClouds);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, [clouds]);

  useEffect(() => {
    const loadDataAsync = async () => {
      try {
        await dispatch(loadDatasetCloudsAsync(dataset.id.toString()));
      } catch (error) {Sentry.captureException(error);}
    };
    loadDataAsync();
  }, [dispatch, dataset]);

  return (
    <div className="overflow-y-auto">
      {loading && (
        <div className="p-4 text-center">{t("common:textLoading")}</div>
      )}
      {clouds.map((cloud) => {
        return <SyncCloudActivityCard key={cloud.id} cloud={cloud} />;
      })}
    </div>
  );
};

interface SyncCloudActivityCardProps {
  cloud: CloudDTO;
}

export const SyncCloudActivityCard = ({
  cloud,
}: SyncCloudActivityCardProps) => {
  const { t } = useTranslation();
  const isFinished = cloud.status === "finished";
  const [seeMore, setSeeMore] = useState(false);

  return (
    <div className="p-4 border-b">
      <div className="col-span-full">
        {t("dataset:activity.syncText")} {cloud.cloudType}.
      </div>
      <div className="relative flex items-center font-bold">
        {cloud.status}
        {!isFinished && <div className="ml-8 dot-flashing"></div>}
      </div>
      <div className="col-span-full">
        {isFinished ? cloud.importStatistic?.success : cloud.totalFile}
        {isFinished ? " file(s) were imported" : " file(s) will be imported"}
      </div>
      <div>
        {t("common:formattedDate", { date: new Date(cloud.createdDate) })}
      </div>
      {seeMore && (
        <ul className="px-4 ml-4 list-disc">
          <li>
            {t("dataset:activity.details.countSuccess", {
              count: cloud.importStatistic?.success,
            })}
          </li>
          <li>
            {t("dataset:activity.details.countFailure", {
              count: cloud.importStatistic?.failure,
            })}
          </li>
        </ul>
      )}
      {isFinished && (
        <div
          className="cursor-pointer text-primary"
          onClick={() => setSeeMore(!seeMore)}
        >
          {seeMore
            ? t("dataset:activity.textSeeLess")
            : t("dataset:activity.textSeeMore")}
        </div>
      )}
    </div>
  );
};
