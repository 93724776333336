/*
 * File: step-4-add-members.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useDetectChange } from "hooks/use-detect-change";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useWorkspaces } from "hooks/workspace/use-workspace.hook";
import { useMemo, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { StepType, WorkflowInstructionDTO } from "services/label-service/dtos";
import {
  DEFAULT_DAILY_LIMIT,
  UserBatchModel,
  UserDTO,
} from "services/user-service/dtos/user.dto";
import { useCreateBatchContext } from "../create-batch.context";
import { BatchSteps } from "../create-batch.model";
import { InstructionUsersSelector } from "./instruction-user-selector.component";
import { selectWorkspaceLabelers } from "store/customer/users/users.selectors";
import { loadUsersAsync } from "store/customer/users/users.thunk";
import {
  DEFAULT_REVIEW_STEP_CONFIG,
  ReviewStepConfigsModel,
} from "./review-step-configs.component";
import { SelectUsersDialog } from "components/label-web/select-users.dialog";
import { IconEdit } from "components/common/vb-icon.component";
import { LabelersList } from "../../batch-detail/pages/workflow/components/labelers-list.component";

export const AddMembersStep = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { workspaceId } = useWorkspaces();
  const { steps, submitData } = useCreateBatchContext();
  const users = useAppSelector(selectWorkspaceLabelers);
  const usersBatch = useMemo<UserBatchModel[]>(() => {
    let usersBatch: UserBatchModel[] = [];
    users.forEach((u) => {
      usersBatch.push({ ...u, dailyLimit: DEFAULT_DAILY_LIMIT });
    });
    return usersBatch;
  }, [users]);
  const [instructions] = useState<WorkflowInstructionDTO[]>(() => {
    const data = steps[BatchSteps.WORKFLOW].data;
    return data ? data.instructions : [];
  });

  const [stepUsers, setStepUsers] = useState<
    {
      instruction: WorkflowInstructionDTO;
      users: UserDTO[];
    }[]
  >(() => {
    const data = steps[BatchSteps.WORKFLOW].data;
    const instructions = data.instructions as WorkflowInstructionDTO[];
    if (instructions) {
      return instructions.map((instruction) => {
        return {
          instruction: instruction,
          users: [],
        };
      });
    }
    return [];
  });
  const [reviewConfigs, setReviewConfigs] = useState<
    Record<number, ReviewStepConfigsModel>
  >(() => {
    const data = steps[BatchSteps.WORKFLOW].data;
    const instructions = data.instructions as WorkflowInstructionDTO[];
    const configs: Record<number, ReviewStepConfigsModel> = {};
    if (instructions) {
      instructions.forEach((instruction) => {
        if (instruction.stepType === StepType.ACCEPTANCE) {
          configs[instruction.step] = { ...DEFAULT_REVIEW_STEP_CONFIG };
        }
      });
    }
    return configs;
  });

  const shouldSubmit = useDetectChange([stepUsers, reviewConfigs]);

  function handleInstructionUsersChange(
    instruction: WorkflowInstructionDTO,
    users: UserBatchModel[]
  ) {
    if (!stepUsers.find((s) => s.instruction.id === instruction.id)) {
      return setStepUsers([{ instruction, users }]);
    }
    setStepUsers([
      ...stepUsers.map((step) => {
        if (step.instruction.id === instruction.id) {
          return {
            ...step,
            users: users,
          };
        }
        return step;
      }),
    ]);
  }

  const handleReviewConfigsChanged = (
    step: number,
    config: ReviewStepConfigsModel
  ) => {
    setReviewConfigs({
      ...reviewConfigs,
      [step]: { ...config },
    });
  };

  const isLoaded = useRef(false);

  useEffect(() => {
    if (isLoaded.current) return;
    if (workspaceId) {
      dispatch(loadUsersAsync());
      isLoaded.current = true;
    }
  }, [dispatch, workspaceId]);

  useEffect(() => {
    if (!shouldSubmit) return;
    if (stepUsers.length === 0 || stepUsers.find((s) => s.users.length === 0)) {
      submitData(BatchSteps.MEMBERS, null);
      return;
    }
    submitData(BatchSteps.MEMBERS, { stepUsers, reviewConfigs });
  }, [shouldSubmit, reviewConfigs, stepUsers, submitData]);

  return (
    <div className="p-4">
      <div className="py-4 text-xl font-bold">
        {t("project:batch.instruction.step4.title")}
      </div>

      <div className="my-4">
        <InstructionUsersSelector
          title={t("project:batch.textInstructions")}
          instructions={instructions}
          users={usersBatch}
          hideCondition
          reviewConfigs={reviewConfigs}
          stepUsers={stepUsers}
          onChange={handleInstructionUsersChange}
          onReviewConfigsChanged={handleReviewConfigsChanged}
        />
      </div>
    </div>
  );
};

interface UserSelectorOptions {
  text: string;
  instruction: WorkflowInstructionDTO;
  onChange?(users: UserBatchModel[]): void;
}

export const UserSelector = ({
  text,
  instruction,
  onChange,
}: UserSelectorOptions) => {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<UserBatchModel[]>([]);
  const shouldInvokeOnChange = useDetectChange([selectedItems]);
  const [isShowWarnText, setIsShowWarnText] = useState(false);

  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const numSelectedItem = selectedItems.length;
    if (numSelectedItem > 0 && numSelectedItem < instruction.roundNumber) {
      setIsShowWarnText(true);
    } else {
      setIsShowWarnText(false);
    }
  }, [selectedItems, instruction]);

  function handleSelectLabelers() {
    setEditing(true);
  }

  function handleSelectedUsers(users: UserDTO[]) {
    setSelectedItems([
      ...users.map((user) => {
        let dailyLimit = DEFAULT_DAILY_LIMIT;
        const selected = selectedItems.find(
          (item) => item.email === user.email
        );
        if (selected) dailyLimit = selected.dailyLimit;
        return {
          ...user,
          dailyLimit,
        };
      }),
    ]);
    setEditing(false);
  }

  function handleClickDeleteLabeler(email: string) {
    const newLabelers = selectedItems.filter(
      (labeler) => labeler.email !== email
    );
    setSelectedItems(newLabelers);
  }

  useEffect(() => {
    if (!shouldInvokeOnChange) return;
    onChange && onChange(selectedItems);
  }, [shouldInvokeOnChange, selectedItems, onChange]);

  return (
    <div className="py-4 space-y-2">
      <div className="flex items-center gap-2 mt-2">
        <div className="flex-auto font-semibold text-background-700">
          {text} {selectedItems.length > 0 ? `(${selectedItems.length})` : ""}
        </div>
        <button
          onClick={handleSelectLabelers}
          className="flex-none button-tertiary"
        >
          <IconEdit className="flex-none w-4 h-4 mr-2" /> <span>Edit</span>
        </button>
      </div>
      {selectedItems.length === 0 && (
        <div className="p-4 text-sm text-center">
          There is no labelers selected.
        </div>
      )}
      {editing && (
        <SelectUsersDialog
          visible
          onClose={() => setEditing(false)}
          selectedUsers={selectedItems}
          onChange={handleSelectedUsers}
        />
      )}

      {isShowWarnText && (
        <p className="mt-2 text-sm text-red-600">
          {t("project:batch.instruction.step4.warnText", {
            numLabelers: selectedItems.length,
            numRound: instruction.roundNumber,
          })}
        </p>
      )}
      <LabelersList
        rows={selectedItems.map((labeler) => labeler.email)}
        onClickDelete={handleClickDeleteLabeler}
        containerClass="mt-2 text-sm bg-white"
      />
    </div>
  );
};
