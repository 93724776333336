/*
 * File: batch-observation.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { BatchObservationDTO } from "../dtos/batch-observation.dto";
import { BaseAPI } from "./base.api";

export class BatchObservationAPI extends BaseAPI<BatchObservationDTO> {}
