/*
 * File: metadata-filter.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd July 2023 2:50:08 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Filter } from "domain/common";
import { useState } from "react";
import useMetadataKeyOptions from "../../../hooks/use-metadata-key-options";
import { MetadataPairFilter } from "./metadata-pair-filter";

interface Props {
  filter: Filter;
  onFilterChange(filter: Filter): void;
}

export function MetadataFilter({ filter, onFilterChange }: Props) {
  const [metadataKeyFilter] = useState({ page: 0, size: 10000 });

  const { options: metadataKeyOptions } =
    useMetadataKeyOptions(metadataKeyFilter);

  return (
    <>
      <h2 className="px-4 text-xs font-semibold text-background-700">
        METADATA
      </h2>
      <div>
        {metadataKeyOptions.map((keyOption) => {
          return (
            <MetadataPairFilter
              key={keyOption.value}
              filter={filter}
              onFilterChange={onFilterChange}
              metadataKey={keyOption}
            />
          );
        })}
      </div>
    </>
  );
}
