/*
 * File: text-editor.selector.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 5:05:43 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Issue } from "domain/common/issue";
import { RootState } from "store";
import { selectTextLabelingTaskJobs } from "store/labeler/text-labeling-task/text-labeling-task.selectors";
import { selectTextLabelingMode } from "../text-labeling/text-labeling.selectors";
import { TextLabelingMode } from "../text-labeling/text-labeling.state";
import { selectTextReviewSelectedAnnotators } from "store/labeler/text-workspace/text-review/text-review.selectors";

export const selectIsShowTextIssues = (state: RootState) => {
  return state.textWorkspace.textIssues.showIssues;
};

export const selectTextIssues = (state: RootState) => {
  const mode = selectTextLabelingMode(state);
  if (mode === TextLabelingMode.CUSTOMER) {
    const jobs = selectTextLabelingTaskJobs(state);
    const annotators = selectTextReviewSelectedAnnotators(state);
    const jobIds = jobs
      .filter((job) => annotators.includes(job.assignee))
      .map((job) => job.id);
    return state.textWorkspace.textIssues.issues.filter((issue) =>
      jobIds.includes(issue.jobId)
    );
  }
  return state.textWorkspace.textIssues.issues;
};

export const selectTextIssueByAnnotationId =
  (annotationId: string) => (state: RootState) => {
    return state.textWorkspace.textIssues.issues.find(
      (annotation) => annotationId === annotation.metadata?.annotationId
    );
  };
export const selectSelectedTextIssue = (state: RootState) => {
  const { selectedAnnotation } = state.textWorkspace.textEditor;
  if (
    !selectedAnnotation ||
    !selectedAnnotation.issueMenu ||
    !selectedAnnotation.annotationId
  )
    return;
  return state.textWorkspace.textIssues.issues.find(
    (issue) => issue.metadata?.annotationId === selectedAnnotation.annotationId
  );
};

export const selectTextIssueEntities = (state: RootState) => {
  const { issues } = state.textWorkspace.textIssues;
  const entities: { [key: string]: Issue } = {};
  for (let issue of issues) {
    if (issue.metadata && issue.metadata.annotationId) {
      entities[issue.metadata.annotationId] = issue;
    }
  }
  return entities;
};

export const selectSelectedTextIssueId = (state: RootState) => {
  return state.textWorkspace.textIssues.selectedTextIssueId;
};
