/*
 * File: labeler-annotation.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 10th February 2022 1:52:15 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AnnotationV1 } from "domain/image-labeling";
import { TaskObservationDTO } from "../dtos";
import { LabelerAnnotationDTO } from "../dtos/annotation.dto";

export const labelerAnnotationMapper = {
  toEntity: (dto: LabelerAnnotationDTO): AnnotationV1 => {
    throw new Error("Method not implemented");
  },
  fromEntity: (entity: AnnotationV1): LabelerAnnotationDTO => {
    throw new Error("Method not implemented");
  },
  toTaskObservation: (dto: LabelerAnnotationDTO): TaskObservationDTO => {
    const taskObservation: any = {
      annotationData: "",
      annotations: [],
      imageUrl: "",
      observationId: 1,
      jobId: -1,
      probability: null,
      annotationAgreementDTOS: [],
      assignee: "",
    };
    return taskObservation as TaskObservationDTO;
  },
};
