/*
 * File: file-info-utils.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 17th August 2021 11:51:59 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { StorageFileDTO } from "models/dataset/storage-file.model";
import { DicomDataTag, getDicomData } from "utilities/dicom/dicom.utils";
import { getUserName } from "utilities/user/user-cache";

export function isDicomFile(file: StorageFileDTO | null) {
  return file?.dicomMetaData;
}

export function extractFileDetail(file: StorageFileDTO | null) {
  if (!file) return [];
  return [
    {
      id: "name",
      label: "dataset:fileInfoPanel.labelName",
      value: file.fileName,
    },
    {
      id: "createdBy",
      label: "dataset:fileInfoPanel.labelCreatedBy",
      value: getUserName(file.createdBy),
    },
    {
      id: "createdDate",
      label: "dataset:fileInfoPanel.labelCreatedDate",
      value: new Date(file.createdDate),
    },
  ];
}
export function getDicomMetadata(metadata: any, file: StorageFileDTO | null) {
  if (!file) return [];
  const patientNameValue = getDicomData(metadata, DicomDataTag.PatientName);
  const defaultValue: string = "";
  const datas = [
    {
      id: "PatientName",
      label: "dataset:fileInfoPanel.dicomMetadata.labelPatientName",
      value:
        patientNameValue && patientNameValue["Alphabetic"]
          ? patientNameValue["Alphabetic"]
          : defaultValue,
    },
    {
      id: "PatientID",
      label: "dataset:fileInfoPanel.dicomMetadata.labelPatientID",
      value: getDicomData(metadata, DicomDataTag.PatientID, defaultValue),
    },
    {
      id: "StudyInstanceUID",
      label: "dataset:fileInfoPanel.dicomMetadata.labelStudyInstanceUID",
      value: getDicomData(
        metadata,
        DicomDataTag.StudyInstanceUID,
        defaultValue
      ),
    },
    {
      id: "StudyDate",
      label: "dataset:fileInfoPanel.dicomMetadata.labelStudyDate",
      value: getDicomData(metadata, DicomDataTag.StudyDate, defaultValue),
    },
    {
      id: "StudyTime",
      label: "dataset:fileInfoPanel.dicomMetadata.labelStudyTime",
      value: getDicomData(metadata, DicomDataTag.StudyTime, defaultValue),
    },
    {
      id: "SeriesInstanceUID",
      label: "dataset:fileInfoPanel.dicomMetadata.labelSeriesInstanceUID",
      value: getDicomData(
        metadata,
        DicomDataTag.SeriesInstanceUID,
        defaultValue
      ),
    },
    {
      id: "SeriesDate",
      label: "dataset:fileInfoPanel.dicomMetadata.labelSeriesDate",
      value: getDicomData(metadata, DicomDataTag.SeriesDate, defaultValue),
    },
    {
      id: "SeriesTime",
      label: "dataset:fileInfoPanel.dicomMetadata.labelSeriesTime",
      value: getDicomData(metadata, DicomDataTag.SeriesTime, defaultValue),
    },
    {
      id: "SOPInstanceUID",
      label: "dataset:fileInfoPanel.dicomMetadata.labelSOPInstanceUID",
      value: getDicomData(metadata, DicomDataTag.SOPInstanceUID, defaultValue),
    },
    {
      id: "Modality",
      label: "dataset:fileInfoPanel.dicomMetadata.labelModality",
      value: getDicomData(metadata, DicomDataTag.Modality, defaultValue),
    },
    {
      id: "InstitutionName",
      label: "dataset:fileInfoPanel.dicomMetadata.labelInstitutionName",
      value: getDicomData(metadata, DicomDataTag.InstitutionName, defaultValue),
    },
  ];
  return datas;
}
