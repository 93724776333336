import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { TaskRow } from "../batch-tasks.context";
import { TaskGridItem } from "./task-grid-item.component";

interface Props {
  rows: TaskRow[];
  onRowAction?(action: string, row: TaskRow): void;
  isLoading?: boolean;
}
export const TasksGrid = ({ rows, onRowAction, isLoading = false }: Props) => {
  // if (isLoading) {
  //   return (
  //     <div className="items-center justify-center w-full h-full">
  //       <VBInlineLoading className="flex items-center justify-center p-20" />
  //     </div>
  //   );
  // }

  if (rows.length === 0) {
    return (
      <div className="flex items-center justify-center">
        <span>No task found</span>
      </div>
    );
  }

  return (
    <div className="relative grid grid-cols-2 gap-3 lg:grid-cols-5 md:grid-cols-3">
      {rows.map((row) => {
        return (
          <div key={row.id} className="aspect-w-4 aspect-h-3">
            <div className="absolute left-0 top-0 w-full h-full">
              <TaskGridItem item={row} onRowAction={onRowAction} />
            </div>
          </div>
        );
      })}

      {isLoading && (
        <div className="absolute top-0 left-0 items-center justify-center w-full h-full bg-black bg-opacity-20">
          <VBInlineLoading className="flex items-center justify-center p-20" />
        </div>
      )}
    </div>
  );
};
