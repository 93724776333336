/*
 * File: index.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 22nd July 2022 10:45:53 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { CircularProgress } from "@material-ui/core";
import classNames from "classnames";
import { VBBreadcrumb } from "components/common/vb-breadcrumb/vb-breadcrumb.component";
import { useRouteMatch } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { useCreateProjectTemplateContext } from "../context/create-project-template.context";
import ProjectInfoTab from "./project-info-tab.component";
import ProjectLabelTab from "./project-label-tab.component";
import ProjectWorkflowTab from "./project-workflow-tab.component";

export default function CreateProjectFromTemplatePage() {
  const { path } = useRouteMatch();
  const { isValidInput, createProject, isCreating } =
    useCreateProjectTemplateContext();
  const breadcrumbs = [
    { url: Routes.PROJECTS, text: "Projects" },
    { url: `/projects/template`, text: "Template" },
    { url: path, text: "Create project", disabled: true },
  ];

  const { tabs, activeTab, selectTab } = useCreateProjectTemplateContext();

  return (
    <div className="flex flex-col p-6 overflow-hidden" style={{ height: 'calc(-64px + 100vh)' }}>
      <div className="flex-initial">
        <VBBreadcrumb breadcrumbs={breadcrumbs} />
        <div className="flex items-center justify-between flex-none gap-4 py-4">
          <div className="flex items-center">
            {tabs.map((tab) => {
              const isActive = tab.value === activeTab;
              return (
                <button
                  key={tab.value}
                  onClick={() => selectTab(tab.value)}
                  className={classNames(
                    "flex items-center gap-2 px-4 py-2 border-b-2",
                    {
                      "text-primary border-primary": isActive,
                    }
                  )}
                >
                  {tab.step && (
                    <span
                      className={classNames(
                        "flex items-center justify-center w-5 h-5 text-xs text-white rounded-full",
                        {
                          "bg-primary": isActive,
                          "bg-background-500": !isActive,
                        }
                      )}
                    >
                      {tab.step}
                    </span>
                  )}
                  <span>{tab.label}</span>
                </button>
              );
            })}
          </div>
          <button
            className={classNames("button-text-primary relative", {
              "filter grayscale cursor-not-allowed": !isValidInput,
            })}
            onClick={createProject}
            disabled={!isValidInput}
          >
            <span className={classNames({ "opacity-0": isCreating })}>
              Create project
            </span>

            {isCreating && (
              <div className="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                <CircularProgress
                  className="text-white"
                  size={20}
                  color="inherit"
                />
              </div>
            )}
          </button>
        </div>
      </div>

      <div className="flex-auto overflow-auto">
        {activeTab === "info" && <ProjectInfoTab />}
        {activeTab === "label" && <ProjectLabelTab />}
        {activeTab === "setting" && <ProjectWorkflowTab />}
      </div>
    </div>
  );
}
