/*
 * File: project.dto.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { LabelingType } from "constants/labeling.constant";
import { BaseDTO } from "./base.dto";

export interface ProjectDTO extends BaseDTO {
  name: string;
  type: string;
  subType: string;
  description: string;
  settings: ProjectSetting;
  exportTypes?: { code: string; name: string }[];
  workspaceId: string;
}

export interface ProjectSetting {
  pollStrategy: PollStrategy;
  autoSaveInSecond: number;
  historyPermission: HistoryPermission;
}

export enum PollStrategy {
  SINGLE = "Single",
  GROUP = "Group",
}

export enum HistoryPermission {
  READ_ONLY = "ReadOnly",
  EDIT = "Edit",
}

export enum ProjectTypes {
  MEDICAL_IMAGE = "medical_image",
  GENERAL_IMAGE = "image",
  TEXT = "text",
  AUDIO = "audio",
  VIDEO = "video",
}

export const ProjectTypeNames = {
  [ProjectTypes.MEDICAL_IMAGE]: "Medical Image",
  [ProjectTypes.GENERAL_IMAGE]: "General Image",
  [ProjectTypes.TEXT]: "Text",
  [ProjectTypes.AUDIO]: "Audio",
  [ProjectTypes.VIDEO]: "Video",
};

export enum ProjectImageSubTypes {
  OBJECT_SEGMENTATION = "object_segmentation",
  IMAGE_CLASSIFICATION = "image_classification",
}

export enum ProjectTextSubTypes {
  COR = "text_cor",
  NER = "text_ner",
  OCR = "text_ocr",
}

export enum ProjectAudioSubTypes {
  SPEECH_TO_TEXT = "speech2text",
  TEXT_TO_SPEECH = "text2speech",
}

export const ProjectSubTypeNames = {
  [ProjectImageSubTypes.OBJECT_SEGMENTATION]: "Object Segmentation",
  [ProjectImageSubTypes.IMAGE_CLASSIFICATION]: "Image Classification",
  [ProjectTextSubTypes.COR]: "COR",
  [ProjectTextSubTypes.NER]: "NER",
  [ProjectTextSubTypes.OCR]: "OCR",
  [ProjectAudioSubTypes.SPEECH_TO_TEXT]: "Speech to text",
  [ProjectAudioSubTypes.TEXT_TO_SPEECH]: "Text to speech",
};

export const getProjectTypeName = (name: string) => {
  if (name as ProjectTypes) {
    return ProjectTypeNames[name as ProjectTypes];
  }
  return name;
};

export const DEFAULT_PROJECT_TYPE = ProjectTypes.GENERAL_IMAGE;
export const DEFAULT_PROJECT_SUB_TYPE =
  ProjectImageSubTypes.IMAGE_CLASSIFICATION;

export const PROJECT_TYPE_OPTIONS = [
  {
    label: ProjectTypeNames[ProjectTypes.GENERAL_IMAGE],
    value: ProjectTypes.GENERAL_IMAGE,
  },
  {
    label: ProjectTypeNames[ProjectTypes.MEDICAL_IMAGE],
    value: ProjectTypes.MEDICAL_IMAGE,
  },

  {
    label: ProjectTypeNames[ProjectTypes.TEXT],
    value: ProjectTypes.TEXT,
  },

  {
    label: ProjectTypeNames[ProjectTypes.AUDIO],
    value: ProjectTypes.AUDIO,
  },
];

export type ProjectSubType = ProjectImageSubTypes &
  ProjectTextSubTypes &
  ProjectAudioSubTypes;

export const PROJECT_SUB_TYPE_OPTIONS: {
  [key: string]: { label: string; value: string }[];
} = {
  [ProjectTypes.GENERAL_IMAGE]: [
    {
      label: ProjectSubTypeNames[ProjectImageSubTypes.OBJECT_SEGMENTATION],
      value: ProjectImageSubTypes.OBJECT_SEGMENTATION,
    },
    {
      label: ProjectSubTypeNames[ProjectImageSubTypes.IMAGE_CLASSIFICATION],
      value: ProjectImageSubTypes.IMAGE_CLASSIFICATION,
    },
  ],
  [ProjectTypes.MEDICAL_IMAGE]: [
    {
      label: ProjectSubTypeNames[ProjectImageSubTypes.OBJECT_SEGMENTATION],
      value: ProjectImageSubTypes.OBJECT_SEGMENTATION,
    },
    {
      label: ProjectSubTypeNames[ProjectImageSubTypes.IMAGE_CLASSIFICATION],
      value: ProjectImageSubTypes.IMAGE_CLASSIFICATION,
    },
  ],

  [ProjectTypes.TEXT]: [
    {
      label: ProjectSubTypeNames[ProjectTextSubTypes.NER],
      value: ProjectTextSubTypes.NER,
    },
    {
      label: ProjectSubTypeNames[ProjectTextSubTypes.COR],
      value: ProjectTextSubTypes.COR,
    },
  ],

  [ProjectTypes.AUDIO]: [
    {
      label: ProjectSubTypeNames[ProjectAudioSubTypes.SPEECH_TO_TEXT],
      value: ProjectAudioSubTypes.SPEECH_TO_TEXT,
    },
    {
      label: ProjectSubTypeNames[ProjectAudioSubTypes.TEXT_TO_SPEECH],
      value: ProjectAudioSubTypes.TEXT_TO_SPEECH,
    },
  ],
};

export const isGeneralImageProject = (type?: string) => {
  return (
    type &&
    [
      LabelingType.IMAGE_DETECTION,
      LabelingType.IMAGE_SEGMENTATION,
      LabelingType.IMAGE_OCR,
    ].includes(type as LabelingType)
  );
};

export const isImageProject = (type: string | undefined) => {
  return (
    type &&
    [
      LabelingType.IMAGE_DETECTION,
      LabelingType.IMAGE_SEGMENTATION,
      LabelingType.IMAGE_OCR,
      LabelingType.IMAGE_CLASSIFICATION,
      LabelingType.MED_MAM_SEGMENTATION, // breasts
      LabelingType.MED_XRAY_DETECTION,
      LabelingType.MED_XRAY_CLASSIFICATION,
      LabelingType.MED_XRAY_SEGMENTATION,
    ].includes(type as LabelingType)
  );
};

export const isAudioProject = (type: string | undefined) => {
  return type && type.startsWith(ProjectTypes.AUDIO);
};

export const isTextProject = (type: string | undefined) => {
  return type && type.startsWith(ProjectTypes.TEXT);
};

export const isTextLabelingProject = (type: string | undefined) => {
  return (
    !!type &&
    (type === LabelingType.TEXT_NER_RE || type === LabelingType.TEXT_NER)
  );
};

export const isMedicalMamoProject = (
  type?: string,
  pollStrategy?: PollStrategy | string
) => {
  if (!type) return false;
  return (
    type === LabelingType.MED_MAM_SEGMENTATION ||
    // TODO: remove later we keep this for back word compatible
    (isImageProject(type) && pollStrategy === PollStrategy.GROUP)
  );
};

export const isWsiProject = (type: string | undefined) => {
  return (
    type &&
    [LabelingType.WSI_DETECTION, LabelingType.WSI_SEGMENTATION].includes(
      type as LabelingType
    )
  );
};

export const isMDIProject = (type: string | undefined) => {
  return (
    type &&
    [LabelingType.MDI_SEGMENTATION, LabelingType.MED_MDI_SEGMENTATION].includes(
      type as LabelingType
    )
  );
};
