/*
 * File: task-runner.slice.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 23rd March 2022 9:54:47 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TaskRunner, TaskRunnerStatus } from "domain/customer/task-runner";
import {
  INITIAL_TASK_RUNNER_STATE,
  TaskRunnersState,
} from "./task-runner.state";
import { mathUtils } from "utilities/math";

const SLICE_NAME = "task-runner";

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_TASK_RUNNER_STATE,
  reducers: {
    taskRunnerUpdated(
      state: TaskRunnersState,
      action: PayloadAction<TaskRunner>
    ) {
      const task: TaskRunner = structuredClone(action.payload);
      if (task.status === TaskRunnerStatus.INITIAL) {
        task.status = TaskRunnerStatus.RUNNING;
        task.percentComplete = -1;
      } else if (task.status === TaskRunnerStatus.SUCCEEDED) {
        task.percentComplete = 100;
      } else {
        task.percentComplete = mathUtils.clamp(
          Math.floor(task.percentComplete * 100),
          0,
          100
        );
      }
      const { tasks } = state;
      const existTask = tasks.find((item) => item.id === task.id);
      if (existTask) {
        state.tasks = state.tasks.map((item) =>
          item.id === task.id ? task : item
        );
      } else {
        state.tasks.push(task);
      }
    },
    taskRunnerRemoved(
      state: TaskRunnersState,
      action: PayloadAction<TaskRunner>
    ) {
      state.tasks = state.tasks.filter((task) => task.id !== action.payload.id);
    },
  },
});

export const { taskRunnerRemoved, taskRunnerUpdated } = slice.actions;

export const taskRunnerReducer = slice.reducer;
