/*
 * File: label-api.config.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 22nd July 2021 9:19:29 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export const LabelAPIConfig = {
  paths: {
    batchObservation: "/api/v1/batch-observations",
    batch: "/api/v1/batches",
    batchV2: "/api/v2/batches",
    dataset: "/api/v1/data-sets",
    observation: "/api/v1/observations",
    project: "/api/v1/projects",
    projectV2: "/api/v2/projects",
    taskObservation: "/api/v1/task-observations",
    taskObservationV2: "/api/v2/task-observations",
    taskObservationV3: "/api/v3/task-observations",
    task: "/api/v1/tasks",
    batchWorkflow: "/api/v1/workflow-batches",
    workflowInstruction: "/api/v1/work-instructions",
    job: "/api/v1/jobs",
    jobV2: "/api/v2/jobs",
    jobV3: "/api/v3/jobs",
    labelerJobV3: "/api/v3/labeler/jobs",
    stepConditions: "/api/v1/step-conditions",
    workManagements: "/api/v1/work-managements",
    batchManagements: "/api/v1/batches",
    pipelines: "/api/v1/pipelines",
    trainingJobs: "/api/v1/training-jobs",
    exportedDatasetVersions: "/api/v1/exported-dataset-versions",
    mlModels: "/api/v1/models",
    individual: "/api/v1/individual",
    annotations: "/api/v1/annotations",
    annotationsV2: "/api/v2/annotations",
    annotationsV3: "/api/v3/annotations",
    labelerAnnotations: "/api/v1/labeler/annotations",
    labelerAnnotationsV2: "/api/v2/labeler/annotations",
    labelerAnnotationsV3: "/api/v3/labeler/annotations",
    reopenRequests: "/api/v1/reopen-requests",
    modelAIs: "/api/v1/model-ais",
    modelAIVerions: "/api/v1/model-ai-versions",
    mlWeb: "/api/v2/ml-web",
    issues: "/api/v1/issues",
    issues_labeler: "/api/v1/labeler/issues",
    issues_labeler_review: "/api/v1/labeler/issues/review",
    workflow: "/api/v1/workflows",
    workflowV2: "/api/v2/workflows"
  },
};
