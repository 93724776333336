/*
 * File: use-task-issues.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 10th April 2023 5:31:12 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { issueCustomerService } from "services/label-service";
import {
  IssueCustomerRequestDTO,
  IssueCustomerResponseDTO,
  IssueResponseDTO,
} from "services/label-service/dtos/issue.dto";
import { issueMapepr } from "./issue-mapper";
import { Issue, IssueStatus } from "domain/common/issue";
import { ThreeDEditorEvents } from "pages/labeler/three-d-labeling/components/three-d-editor.models";
import { useAppDispatch } from "hooks/use-redux";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { getErrMessage } from "utilities/errors/errors";

async function getTaskIssues(taskId: number) {
  const response = await issueCustomerService.getIssues(taskId);
  const resIssues = response.data as IssueCustomerResponseDTO[];
  const issues = resIssues.map(issueMapepr.fromThreeDDto);
  return issues.filter((issue) => issue.status === IssueStatus.OPENED);
}

export interface CreateTaskIssueInput {
  taskId: number;
  jobId: number;
  slice: number;
  axis: number;
  comment: string;
}

async function createTaskIssue(data: CreateTaskIssueInput) {
  const payload: IssueCustomerRequestDTO = {
    jobId: data.jobId,
    data: {
      x: data.axis,
      y: data.slice,
    },
    description: data.comment,
  };
  const issueResponse = await issueCustomerService.createIssue(payload);
  const newIssueDTO = issueResponse.data as IssueResponseDTO;
  return { issue: newIssueDTO };
}

async function resolveTaskIssue(data: Issue) {
  return issueCustomerService.resolveIssue({ issueId: data.id });
}

export function useTaskIssues(taskId: number) {
  const dispatch = useAppDispatch();
  const client = useQueryClient();

  function requestData() {
    return getTaskIssues(taskId);
  }

  const { data, isLoading } = useQuery(["taskIssues", taskId], requestData, {});

  const createNewIssue = useCallback(
    async (payload: CreateTaskIssueInput) => {
      await createTaskIssue(payload);
      client.invalidateQueries(["taskIssues"]);
    },
    [client]
  );

  const resolveIssue = useCallback(
    async (issue: Issue) => {
      try {
        await resolveTaskIssue(issue);
        client.invalidateQueries(["taskIssues"]);
        dispatch(enqueueSuccessNotification("Success"));
      } catch (error) {
        dispatch(enqueueErrorNotification(getErrMessage(error, "Failed")));
      }
    },
    [dispatch, client]
  );

  const selectIssue = useCallback((issue: Issue) => {
    try {
      const axis = parseInt(issue.metadata.x);
      const slice = parseInt(issue.metadata.y);
      if (axis === -1) return;
      const eventData = { axis, slice };
      const eventDetail = { detail: eventData };
      const event = new CustomEvent(
        ThreeDEditorEvents.COMMAND_GO_TO_SLICE,
        eventDetail
      );
      document.dispatchEvent(event);
    } catch (error) {}
  }, []);

  return { createNewIssue, selectIssue, resolveIssue, data, isLoading };
}
