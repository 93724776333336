/*
 * File: model-preview.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 21st February 2022 10:56:54 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { IconGallary, IconTrash } from "components/common/vb-icon.component";
import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { ChangeEvent, useRef, useState } from "react";
import { StorageService } from "services/storage";
import { classnames } from "utilities/classes";
import { AppError } from "utilities/errors/errors";
import * as Sentry from "@sentry/react";

interface Props {
  onUploaded?: (url: string) => void;
  showDelete?: boolean;
  disabled?: boolean;
  imageUrl?: string;
}

export const ModelPreview = ({
  onUploaded,
  showDelete = false,
  disabled = false,
  imageUrl = "",
}: Props) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const [url, setUrl] = useState(imageUrl);
  const [isUploading, setIsUploading] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [error, setError] = useState<AppError>();

  const onClickBrowse = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleFileChanged = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setIsUploading(true);
      setError(undefined);
      const fileList = e.target.files;
      if (!fileList) return;
      const newFiles: File[] = [];
      for (let i = 0; i < fileList.length; i++) {
        const newFile = fileList.item(i);
        if (!newFile || newFile?.size > 1024 * 1024 * 5) continue;
        newFiles.push(newFile);
      }

      if (newFiles.length <= 0) return;
      const fileToUpload = newFiles[0];
      const previewUrl = URL.createObjectURL(fileToUpload);
      setUrl(previewUrl);
      const url = await StorageService.uploadFile(fileToUpload);
      setUrl(url);
      setIsUploading(false);
      onUploaded && onUploaded(url);
    } catch (error: any) {
      Sentry.captureException(error);
      setError(new AppError("unknow", "Error!"));
      setIsUploading(false);
    }
  };

  const handleClickRetry = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  return (
    <div
      className={classnames(
        "relative bg-white flex flex-col items-center justify-center flex-none w-48 h-48 gap-4 rounded lg:w-60 lg:h-60 border-background-300 dark:bg-white text-background-700",
        {
          "pointer-events-none": disabled,
          "border border-dashed": !url,
        }
      )}
      onMouseEnter={(_) => setIsHover(true)}
      onMouseLeave={(_) => setIsHover(false)}
    >
      {url && !!!error && (
        <div
          className="relative w-full h-full"
          style={{
            backgroundImage: `url(${url})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            opacity: `${isUploading ? "10%" : "100%"}`,
          }}
        ></div>
      )}
      {!isUploading && !url && !!!error && (
        <>
          <IconGallary className="flex-none w-6 h-6 bg-white text-primary" />
          <div className="px-4 space-x-1 text-sm text-center">
            <span>Drop your images here, or select</span>
            <span className="text-primary" onClick={onClickBrowse}>
              click to browse
            </span>
          </div>
        </>
      )}
      {isUploading && (
        <div className="absolute" style={{ zIndex: "99", opacity: "100%" }}>
          <VBInlineLoading spinnerClass="text-primary" />
        </div>
      )}
      {url && !isUploading && isHover && showDelete && !!!error && (
        <div className="absolute cursor-pointer top-1 right-1">
          <IconTrash
            onClick={(_) => setUrl("")}
            className="w-4 h-4 text-primary"
          />
        </div>
      )}
      {!!error && (
        <div>
          <p className="text-lg font-bold text-error-500">{error.message}</p>
          <p className="cursor-pointer text-primary" onClick={handleClickRetry}>
            Retry
          </p>
        </div>
      )}
      <input
        style={{ display: "none" }}
        type="file"
        ref={inputFile}
        accept="image/png, image/jpeg"
        onChange={handleFileChanged}
        onClick={(e: any) => {
          e.target.value = null;
        }}
      />
    </div>
  );
};
