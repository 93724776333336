/*
 * File: tasks-review.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 16th May 2023 3:59:09 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Annotation } from "domain/image-labeling";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { RootState } from "store";
import {
  selectTaskReviewIouActiveScore,
  selectTaskReviewIoUPairs,
  selectVisibleAnnotationIdsByIou,
} from "./task-review-iou/task-review-iou.selectors";

export const selectTasksReviewTask = (state: RootState) => {
  return state.tasksReview.task;
};

export const selectLoadTasksReviewRequestStatus = (state: RootState) => {
  return state.tasksReview.requestStatus;
};

export const selectLoadTasksReviewError = (state: RootState) => {
  return state.tasksReview.error;
};

export const selectTasksReviewFile = (state: RootState) => {
  return state.tasksReview.file as StorageFileDTO;
};

export const selectTasksReviewMetadata = (state: RootState) => {
  return state.tasksReview.metadata;
};

export const selectTasksReviewJobOptions = (state: RootState) => {
  return state.tasksReview.jobOptions;
};

export const selectTasksReviewBatchObservations = (state: RootState) => {
  return state.tasksReview.taskReviewBatchObservations;
};

export const selectTasksReviewLabel = (state: RootState) => {
  return state.tasksReview.taskReviewBatchObservations.map((item) => {
    return item.batchObservation.observation;
  });
};

export const selectTasksReviewAnnotations = (
  state: RootState
): Annotation[] => {
  const activeAnnotations = selectedTaskReviewActiveAnnotations(state);
  const iouAnnotationIds = selectVisibleAnnotationIdsByIou(state);
  const iouPairs = selectTaskReviewIoUPairs(state);
  const activeIoUScore = selectTaskReviewIouActiveScore(state);

  const mapActiveAnnotations = new Map<
    number,
    { selected: boolean; hovering: boolean }
  >();
  activeAnnotations.forEach((activeAnnotation) => {
    mapActiveAnnotations.set(activeAnnotation.annotationId, {
      selected: activeAnnotation.selected || false,
      hovering: activeAnnotation.hovering || false,
    });
  });

  const selectedObs = state.tasksReview.taskReviewBatchObservations
    .filter((item) => item.selected)
    .map((item) => item.batchObservation.observation.id);

  const allAnnotations = state.tasksReview.annotations.map((anno) => {
    const selectedAnnotation = mapActiveAnnotations.get(anno.id);

    const visibleForLabel = selectedObs.includes(anno.labelId);
    const visibleForActive = selectedAnnotation
      ? selectedAnnotation.selected || selectedAnnotation.hovering
      : false;
    const visibleForIou = iouAnnotationIds.includes(anno.id);
    const visible = visibleForLabel && visibleForIou && visibleForActive;
    return {
      ...anno,
      showActionVote: false,
      visible,
    };
  });

  for (const pair of iouPairs) {
    if (!pair.intersection) continue;
    let iouHidden = false;
    if (activeIoUScore !== -1 && activeIoUScore > pair.iouScore)
      iouHidden = true;

    allAnnotations.push({
      ...pair.intersection,
      type: "text",
      locked: true,
      iou: pair.iouScore,
      label: pair.iouScore.toFixed(2),
      iouHidden: iouHidden,
      showActionVote: false,
      visible: false,
    });
  }

  return allAnnotations;
};

export const selectTasksReviewRelationAnnotations = (state: RootState) => {
  const visible = state.cornerstone.relationAnnotationVisible;
  const activeAnnotations = selectedTaskReviewActiveAnnotations(state);
  const mapActiveAnnotations = new Map<
    number,
    { selected: boolean; hovering: boolean }
  >();

  activeAnnotations.forEach((activeAnnotation) => {
    mapActiveAnnotations.set(activeAnnotation.annotationId, {
      selected: activeAnnotation.selected || false,
      hovering: activeAnnotation.hovering || false,
    });
  });

  return state.tasksReview.relationAnnotations.map((anno) => {
    const selectedAnnotation = mapActiveAnnotations.get(anno.id);
    const visibleForActive = selectedAnnotation
      ? selectedAnnotation.selected || selectedAnnotation.hovering
      : false;

    return {
      ...anno,
      visible: visible && visibleForActive,
    };
  });
};

export const selectedTaskReviewActiveAnnotations = (state: RootState) => {
  return state.tasksReview.activeAnnotations;
};

export const selectedTaskReviewLabelById =
  (labelId: number) => (state: RootState) => {
    return state.tasksReview.taskReviewBatchObservations.find(
      (item) => item.batchObservation.observation.id === labelId
    )?.batchObservation.observation;
  };

export const selectSelectedAnnotation = (state: RootState) => {
  return null;
  // const { selectedAnnotation, tasksReview } = state.tasksReview;
  // let observation: ObservationDTO | null = null;
  // for (let taskReview of tasksReview) {
  //   for (let taskReviewObservation of taskReview.taskReviewBatchObservations) {
  //     if (
  //       taskReviewObservation.batchObservation.observation.id.toString() ===
  //       selectedAnnotation?.labelId
  //     ) {
  //       observation = taskReviewObservation.batchObservation.observation;
  //     }
  //   }
  // }

  // if (!selectedAnnotation) return null;
  // return {
  //   annotationId: selectedAnnotation.annotationId,
  //   label: observation,
  //   text: selectedAnnotation.text || "",
  // };
};
