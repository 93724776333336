/*
 * File: text-issue.utils.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 5th July 2022 3:20:41 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Issue, IssueComment, IssueStatus } from "domain/common/issue";
import { TextAnnotation } from "domain/text-labeling/text-annotation";
import { User } from "store/auth/auth.state";
import { newUniqueId } from "utilities/number/id-generator";
import { truncateEmail } from "utilities/string/truncate-email";

function createNewTextIssue(
  user: User,
  jobId: number,
  taskId: number,
  content: string,
  annotation: TextAnnotation
): Issue {
  const issue: Issue = {
    id: newUniqueId(),
    jobId: jobId,
    taskId: taskId,
    status: IssueStatus.OPENED,
    creator: {
      email: user.email,
      name: user.displayName,
    },
    content: content,
    comments: [],
    createdDate: new Date().toUTCString(),
    metadata: {
      annotationId: annotation.id,
    },
  };
  return issue;
}

function commentFromDTO(dto: any): IssueComment {
  return {
    id: dto.id || newUniqueId(),
    content: dto.content,
    issueId: dto.issueId,
    creator: {
      name: truncateEmail(dto.createdBy),
      email: dto.createdBy,
    },
    createdDate: dto.createdDate,
  };
}

function fromDTO(dto: any): Issue {
  const createdBy = dto.createdBy ? dto.createdBy : "noname@gmail.com";
  return {
    id: dto.id,
    jobId: dto.jobId,
    taskId: dto.taskId,
    status: dto.status,
    creator: {
      name: truncateEmail(createdBy),
      email: createdBy,
    },
    content: dto.description,
    comments: (dto.comments || []).map(commentFromDTO),
    createdDate: dto.createdDate || new Date().toISOString(),
    metadata: {
      startIndex: dto.data.startIndex,
      endIndex: dto.data.endIndex,
      annotationId: `${dto.data.annotationId}:${dto.data.startIndex}:${dto.data.endIndex}`,
    },
  };
}

export const textIssueUtils = {
  createNewTextIssue,
  fromDTO,
  commentFromDTO,
};
