import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { Label } from "domain/image-labeling";
import { ChangeEvent, useRef, useState } from "react";
import { mathUtils } from "utilities/math";

interface Props {
  label: Label;
  open: boolean;
  onClose: () => void;
  onSubmit(newLabel: Label): void;
}

export function UpdateLabelModal({ onSubmit, label, open, onClose }: Props) {
  const [opacity, setOpacity] = useState(label.opacity ?? 20);
  const [color, setColor] = useState(label.color);

  const colorInputRef = useRef<HTMLInputElement>(null);

  const handleChangeInputColor = (e: ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;
    setColor(value);
  };

  function handleSumit() {
    onSubmit({
      ...label,
      opacity,
      color,
    });
  }

  return (
    <VBModal
      title="Update Label"
      open={open}
      onClose={onClose}
      textSubmit="Apply"
      onSubmit={handleSumit}
    >
      <div>
        <div className="flex items-center gap-2">
          <div className="w-16">Color: </div>
          <div
            style={{
              width: 24,
              height: 24,
              backgroundColor: color,
              opacity: parseFloat(opacity.toString()) / 100,
              border: `solid 2px ${color}`,
            }}
          >
            <input
              ref={colorInputRef}
              type="color"
              className="w-6 h-6 p-0 border-none opacity-0 text-black"
              value={label.color}
              onChange={handleChangeInputColor}
            />
          </div>
          <span>{color}</span>
        </div>

        <div className="flex items-center gap-2 pt-4">
          <div className="flex items-center justify-between gap-4">
            <span className="w-16">Opacity: </span>
          </div>
          <div className="flex items-center gap-2 mt-1 text-sm">
            <input
              className="px-2 border rounded border-background-500 focus:border-primary"
              type="number"
              value={opacity}
              min={0}
              max={100}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                const clampValue = mathUtils.clamp(value, 0, 100);
                setOpacity(clampValue);
              }}
            />

            <input
              className="flex-auto"
              type="range"
              value={opacity}
              min={0}
              max={100}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                setOpacity(value);
              }}
            />
          </div>
        </div>
      </div>
    </VBModal>
  );
}
