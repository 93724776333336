import { Tooltip } from "@material-ui/core";
import { useCountDown } from "ahooks";
import {
  IconArrowLeftBold,
  IconArrowRightCircle,
  IconEye,
  IconHideBold,
  IconKeyboard,
  IconSave,
  IconSetting2,
  IconTrash,
} from "components/common/vb-icon.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { BatchInfoButton } from "components/dicom/dicom-tools/batch-info-button.component";
import { isProduction } from "configs/env.config";
import { AppEvents } from "constants/annotation.constant";
import { UseAutoSaveReturnValue } from "hooks/use-auto-save.hook";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BatchDTO, ProjectDTO, TaskDTO } from "services/label-service/dtos";
import { classnames } from "utilities/classes";
import { inputManager } from "utilities/keyboard/input.manager";
import { ThreeDEditorSettingModal } from "../three-d-labeling/components/three-d-editor-settings.modal";
import { ThreeDUserAvatar } from "../three-d-labeling/three-d-user-avatar.component";
import { PathologyEditorShortcutModal } from "./components/pathology-shortcut.component";

interface Props {
  autoSave: UseAutoSaveReturnValue;
  batch: BatchDTO;
  task: TaskDTO;
  project?: ProjectDTO;
  disabledComplete: boolean;
  disabledSave: boolean;
  countDownSecond: number;
  isReady: boolean;
  isReviewStepJob?: boolean;
  onClickBack?: () => void;
  onClickSave?: () => void;
  onClickComplete?: (accept?: boolean, reject?: boolean) => Promise<void>;
  containerClasses?: string;
  showButtons?: boolean;
  showInfo?: boolean;
  jobNavVisibility?: boolean;
  showJobNav?: boolean;
  onClickToggleJobNav?: () => void;
  onClickFromUrlGo?: (v: string, v2: boolean) => void;
  onClickDeleteFromUrl?: () => void;
}
export const PathologyHeaderBar = ({
  autoSave,
  batch,
  task,
  project,
  disabledComplete,
  disabledSave,
  countDownSecond,
  isReady,
  isReviewStepJob = false,
  onClickBack,
  onClickSave,
  onClickComplete,
  containerClasses = "py-2 px-2 border-b border-gray-600",
  showButtons = true,
  showInfo = true,
  jobNavVisibility = false,
  showJobNav = true,
  onClickToggleJobNav,
  onClickFromUrlGo,
  onClickDeleteFromUrl,
}: Props) => {
  const { t } = useTranslation();
  const [isEnabled, setEnabled] = useState(false);
  const [settingModalVisible, setSettingModalVisible] = useState(false);
  const [shortcutModalVisibility, setShortcutModalVisibility] = useState(false);

  const [fromUrl, setFromUrl] = useState("");
  const [useAjaxHeadersFromUrl, setUseAjaxHeadersFromUrl] = useState(false);
  const [showInputFromUrl, setShowInputUrl] = useState(false);
  const fromUrlExamples = [
    "https://microdraw.pasteur.fr/bigbrain/bigbrain.dzi",
    "https://openseadragon.github.io/example-images/duomo/duomo.dzi",
  ];

  const buttonSaveRef = useRef<any>();
  const buttonCompleteRef = useRef<any>();

  const [countdown, setTargetDate] = useCountDown({
    onEnd: () => setEnabled(true),
  });

  const batchTaskInfoStr = useMemo(() => {
    return `Batch: ${batch.name} - Task: ${task.id}`;
  }, [batch, task]);

  useEffect(() => {
    if (isReady && !isEnabled) {
      if (countDownSecond === 0) {
        setEnabled(true);
        return;
      }
      const timeLimitMs = countDownSecond * 1000;
      setTargetDate(new Date(Date.now() + timeLimitMs));
    }
  }, [isEnabled, isReady, setTargetDate, countDownSecond]);

  const handleComplete = async (accept = false, reject = false) => {
    if (onClickComplete) {
      await onClickComplete(accept, reject);
    }
    setEnabled(false);
  };

  useEffect(() => {
    const hanldeShortCutKeyDown = () => {
      if (inputManager.isActionSaveDown() && !!buttonSaveRef.current) {
        buttonSaveRef.current.click();
      }
      // if (inputManager.isActionSubmitDown() && !!buttonCompleteRef.current) {
      //   buttonCompleteRef.current.click();
      // }
    };

    document.addEventListener(
      AppEvents.SHORT_CUT_KEY_DOWN,
      hanldeShortCutKeyDown
    );

    return () => {
      document.removeEventListener(
        AppEvents.SHORT_CUT_KEY_DOWN,
        hanldeShortCutKeyDown
      );
    };
  }, []);

  const handleKeyboardClick = () => {
    setShortcutModalVisibility(true);
  };

  const handleCloseModal = () => {
    setShortcutModalVisibility(false);
  };

  const handleCloseInputFromUrl = () => {
    setShowInputUrl(false);
    if (!!!fromUrl) {
      onClickDeleteFromUrl && onClickDeleteFromUrl();
    }
  };

  const handleClickDeleteFromUrl = () => {
    setFromUrl("");
    onClickDeleteFromUrl && onClickDeleteFromUrl();
  };

  const handleCloseInputSubmit = () => {
    setShowInputUrl(false);
    onClickFromUrlGo && onClickFromUrlGo(fromUrl, useAjaxHeadersFromUrl);
  };

  return (
    <Fragment>
      <div
        className={classnames(
          "grid grid-cols-12 w-full bg-background-900",
          containerClasses
        )}
      >
        <div className="flex items-center col-span-3 gap-4 text-white">
          {showButtons && (
            <Fragment>
              <button
                onClick={onClickBack}
                className="flex items-center justify-center focus:outline-none gap-1.5"
              >
                <IconArrowLeftBold className="w-6 h-6" />
                <span>{t("labelerworkspace:menu.buttonExit")}</span>
              </button>
              {jobNavVisibility && (
                <button
                  onClick={onClickToggleJobNav}
                  className="flex-none flex items-center justify-center focus:outline-none gap-1.5"
                >
                  <Tooltip title="Hide jobs bar" placement="top">
                    <IconHideBold className="w-6 h-6" />
                  </Tooltip>
                </button>
              )}
              {!jobNavVisibility && showJobNav && (
                <button
                  onClick={onClickToggleJobNav}
                  className="flex-none flex items-center justify-center focus:outline-none gap-1.5"
                  style={{
                    zIndex: "100",
                  }}
                >
                  <Tooltip title="Show jobs bar" placement="top">
                    <IconEye className="z-50 w-6 h-6" />
                  </Tooltip>
                </button>
              )}
              <Tooltip title="Editor settings">
                <IconSetting2
                  className="flex-none w-6 h-6 cursor-pointer"
                  onClick={() => setSettingModalVisible(true)}
                />
              </Tooltip>

              <Tooltip title="Show shortcuts" placement="top">
                <IconKeyboard
                  className="flex-none w-6 h-6 cursor-pointer"
                  onClick={handleKeyboardClick}
                />
              </Tooltip>
              <PathologyEditorShortcutModal
                open={shortcutModalVisibility}
                onClose={handleCloseModal}
              />
            </Fragment>
          )}

          <div className="flex items-center gap-2 truncate">
            {project && <BatchInfoButton batch={batch} project={project} />}
            <Tooltip title={batchTaskInfoStr} placement="bottom">
              <span className="truncate">{batchTaskInfoStr}</span>
            </Tooltip>
          </div>
        </div>

        <div className="flex items-center col-span-3 gap-2 px-4 text-white truncate">
          {!isProduction() && (
            <Fragment>
              <span
                className="cursor-pointer"
                onClick={(_) => setShowInputUrl(true)}
              >
                From URL:
              </span>
              {fromUrl && (
                <Fragment>
                  <span
                    className="flex-auto truncate cursor-pointer"
                    onClick={(_) => setShowInputUrl(true)}
                  >
                    {fromUrl}
                  </span>
                  <IconTrash
                    className="w-6 h-6 cursor-pointer"
                    onClick={handleClickDeleteFromUrl}
                  />
                </Fragment>
              )}
            </Fragment>
          )}
        </div>

        <div className="flex items-center col-span-6">
          {showButtons && (
            <div className="flex flex-row-reverse w-full gap-3">
              <ThreeDUserAvatar />
              {!isReviewStepJob && (
                <>
                  <button
                    className="px-6 py-2 button-warn disabled:opacity-50"
                    disabled={disabledComplete || !isEnabled}
                    onClick={(_) => handleComplete()}
                    ref={buttonCompleteRef}
                  >
                    {isEnabled && t("common:buttonComplete")}
                    {!isEnabled &&
                      `Complete (${Math.round(countdown / 1000)}s)`}
                    <IconArrowRightCircle />
                  </button>
                  <button
                    className="px-6 py-2 button-warn disabled:opacity-50"
                    disabled={disabledSave || autoSave.enabled}
                    onClick={(_) => onClickSave && onClickSave()}
                    ref={buttonSaveRef}
                  >
                    {t("common:buttonSave")}
                    {autoSave.seconds > 0 && `(${autoSave.seconds}s)`}
                    <IconSave />
                  </button>
                </>
              )}
              {isReviewStepJob && (
                <>
                  <button
                    className="px-6 py-2 button-warn disabled:opacity-50"
                    disabled={disabledComplete}
                    onClick={(_) => handleComplete(true, false)}
                  >
                    {t("common:buttonAccept")}
                  </button>
                  <button
                    className="px-6 py-2 button-error disabled:opacity-50"
                    disabled={disabledComplete}
                    onClick={(_) => handleComplete(false, true)}
                  >
                    {t("common:buttonReject")}
                  </button>
                </>
              )}
            </div>
          )}
        </div>
        {settingModalVisible && (
          <ThreeDEditorSettingModal
            open
            onClose={() => setSettingModalVisible(false)}
          />
        )}
      </div>
      <VBModal
        title="URL"
        open={showInputFromUrl}
        onClose={handleCloseInputFromUrl}
        onSubmit={handleCloseInputSubmit}
        disableCloseButton
        textSubmit="Go"
        disableSubmit={!!!fromUrl}
      >
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <input
              type={"checkbox"}
              checked={useAjaxHeadersFromUrl}
              onChange={(e) => setUseAjaxHeadersFromUrl(e.target.checked)}
            />
            <span>Use Ajax headers</span>
          </div>
          <input
            className="p-2 border rounded"
            value={fromUrl}
            onChange={(e) => setFromUrl(e.target.value)}
          />
          <div className="flex flex-col gap-2">
            {fromUrlExamples.map((url) => {
              return (
                <span
                  key={url}
                  className="truncate cursor-pointer"
                  onClick={(_) => setFromUrl(url)}
                >
                  {url}
                </span>
              );
            })}
          </div>
        </div>
      </VBModal>
    </Fragment>
  );
};
