
export enum FabricObjectToolType {
  NONE = "NONE",
  TEXT_LABEL = "TEXT_LABEL",
  CIRCLE_UTIL_LABEL = "CIRCLE_UTIL_LABEL",
  UTIL_LABEL_GROUP = "UTIL_LABEL_GROUP",
  DISTANCE_TEXT = "DISTANCE_TEXT",
  DRAWN_POLYGON = "DRAWN_POLYGON",
  DRAWN_RECTANGLE = "DRAWN_RECTANGLE",
  DRAWN_LINE = "DRAWN_LINE",
  DRAWN_ELLIPSE = "DRAWN_ELLIPSE",
}

export const ANNOTATION_TYPES: FabricObjectToolType[] = [
  FabricObjectToolType.DRAWN_POLYGON,
  FabricObjectToolType.DRAWN_RECTANGLE,
  FabricObjectToolType.DRAWN_LINE,
  FabricObjectToolType.DRAWN_ELLIPSE,
];

export enum FabricOverlayEvents {
  CANVAS_RESIZE = "CANVAS_RESIZE",
  DIMENSION_CHANGED = "DIMENSION_CHANGED",
  OBJECT_ADDED = "OBJECT_ADDED",
  TRANSFORM_OBJECTS_TO_FABRIC_ABSOLUTE = "TRANSFORM_OBJECTS_TO_FABRIC_ABSOLUTE",
  OPEN = "OPEN",
  BEFORE_DESTROY = "BEFORE_DESTROY",
  SELECTED_OBJECTS_UPDATED = "SELECTED_OBJECTS_UPDATED",

  MOUSE_OVER_UTIL_BUTTON = "MOUSE_OVER_UTIL_BUTTON",
  MOUSE_OUT_UTIL_BUTTON = "MOUSE_OUT_UTIL_BUTTON",
}

export enum ObjectOperationType {
  UNION,
  INTERSECTION,
  DIFFERENCE_A,
  DIFFERENCE_B,
}

export const DEFAULT_TEXT_LABEL_VISIBILITY = true;
export const DEFAULT_TEXT_LABELER_VISIBILITY = true;
export const DEFAULT_UTIL_BUTTON_VISIBILITY = true;
export const DEFAULT_FILL_OBJECTS = true;
