/*
 * File: ai-models.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 18th February 2022 2:25:35 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { RootState } from "store";

export const selectAIModels = (state: RootState) => {
  return state.aiModels.ids.map((id) => state.aiModels.entities[id]);
};

export const selectAIModelsRequestStatus = (state: RootState) => {
  return state.aiModels.status;
};

export const selectAIModelsError = (state: RootState) => {
  return state.aiModels.error;
};
