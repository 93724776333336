/*
 * File: export-dataset.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 5:55:09 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useKeyPress } from "ahooks";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBSelect } from "components/common/vb-select.component";
import { useExportedDatasets } from "hooks/datasets/use-exported-datasets.hook";
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ExportDatasetPayload } from "services/label-service/apis/batch.api";
import { ScopeDTO } from "services/storage-service/dto/scope.dto";
import { RequestStatus } from "store/base/base.state";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { DatasetModels } from "./dataset-models.component";

interface Props {
  datasetId: string;
  visible?: boolean;
  scopes: ScopeDTO[];
  scopeFiles: Record<string, number>;
  requestStatus?: RequestStatus;
  onClose(): void;
  onSubmit?(payload: ExportDatasetPayload): void;
}
export const ExportDatasetModal = ({
  datasetId,
  visible = true,
  scopes,
  scopeFiles,
  requestStatus = RequestStatus.IDLE,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const [action, setAction] = useState("OVERWRITE");
  const { datasetVersions } = useExportedDatasets(true);
  const [override, setOverride] = useState(false);
  const [selectedDatasetVersionId, setSelectedDatasetVersionId] = useState(-1);
  const [format] = useState("COCO");

  const formRef = useRef<HTMLFormElement>(null);
  const [isValid, setValid] = useState(false);
  useKeyPress(KeyboardKey.Enter, (event) => {
    event.preventDefault();
    formRef.current?.requestSubmit();
  });

  function handleOverrideChange(_: any, checked: boolean) {
    setOverride(checked);
  }

  function handleActionChange(event: ChangeEvent<HTMLInputElement>) {
    setAction((event.target as HTMLInputElement).value);
  }

  const validateInput = useCallback(() => {
    if (override) return selectedDatasetVersionId !== -1;
    const name = nameRef.current?.value || "";
    return !!name;
  }, [override, selectedDatasetVersionId, nameRef]);

  function handleInput() {
    setValid(validateInput());
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (requestStatus === RequestStatus.LOADING) return;
    if (!onSubmit) return;
    const name = nameRef.current?.value || "";
    const description = descriptionRef.current?.value || "";
    if (!override)
      return onSubmit({
        sourceDatasetId: parseInt(datasetId),
        name,
        description,
        format,
      });
    return onSubmit({
      sourceDatasetId: parseInt(datasetId),
      name,
      description,
      targetDatasetVersionId: selectedDatasetVersionId,
      action,
      format,
    });
  }

  function handleSelectDatasetVersion(event: ChangeEvent<{ value: unknown }>) {
    setSelectedDatasetVersionId(event.target.value as number);
  }

  const isRequesting = requestStatus === RequestStatus.LOADING;

  useEffect(() => {
    setValid(validateInput());
  }, [selectedDatasetVersionId, override, validateInput]);

  return (
    <VBModal
      title={t("project:batchDetails.exportModal.title")}
      open={visible}
      onClose={onClose}
      textSubmit={t("project:batchDetails.exportModal.buttonExport")}
      onSubmit={handleSubmit}
      disableSubmit={isRequesting || !isValid}
      blockUI={isRequesting}
      width="32rem"
    >
      <div>
        <div>{t("project:batchDetails.exportModal.subTitle")}:</div>
        <DatasetModels
          className="flex items-center mt-2 text-sm"
          scopes={scopes}
          scopeFiles={scopeFiles}
        />
        <div className="flex items-center mt-6">
          <div className="w-24 mr-4">
            {t("project:batchDetails.exportModal.textDatasetId")}
          </div>
          <input
            type="text"
            readOnly
            defaultValue={datasetId}
            className="px-4 py-1.5 border rounded flex-1 text-gray-400 focus:outline-none"
          />
        </div>
        <div className="flex items-center mt-4">
          <div className="flex-none w-24 mr-4">
            {t("project:batchDetails.exportModal.textName")}
          </div>
          <div className="flex-auto overflow-hidden">
            {!override && (
              <input
                required
                ref={nameRef}
                type="text"
                autoFocus
                onInput={handleInput}
                className="px-4 py-1.5 border rounded w-full"
              />
            )}
            {override && (
              <VBSelect
                variant="outlined"
                className="w-full"
                value={selectedDatasetVersionId}
                onChange={handleSelectDatasetVersion}
                options={datasetVersions.map((version) => {
                  return {
                    label: `${version.datasetName} - ${version.datasetVersion}`,
                    value: version.id,
                  };
                })}
              />
            )}
          </div>
        </div>

        <div className="flex items-center mt-4">
          <div className="w-24 mr-4">
            {t("project:batchDetails.exportModal.textDescription")}
          </div>
          <input
            ref={descriptionRef}
            onInput={handleInput}
            type="text"
            className="px-4 py-1.5 border rounded flex-1"
          />
        </div>

        <div className="flex items-center mt-6">
          <div className="w-24 mr-4">
            {t("project:batchDetails.exportModal.format")}
          </div>
          <input
            type="text"
            readOnly
            defaultValue={format}
            className="px-4 py-1.5 border rounded flex-1 text-gray-400 focus:outline-none"
          />
        </div>

        <div className="mt-4">
          <Checkbox
            color="primary"
            checked={override}
            onChange={handleOverrideChange}
          />{" "}
          {t("project:batchDetails.exportModal.textExportExisting")}
        </div>
        {override && (
          <div className="flex items-center mt-4">
            <div className="flex-none w-24 mr-4">
              {t("project:batchDetails.exportModal.texAction")}
            </div>
            <RadioGroup
              row
              defaultValue="merge"
              value={action}
              onChange={handleActionChange}
            >
              <FormControlLabel
                value="MERGE"
                control={<Radio color="primary" />}
                label="Merge"
                labelPlacement="start"
              />
              <FormControlLabel
                value="OVERWRITE"
                control={<Radio color="primary" />}
                label="Overwrite"
                labelPlacement="start"
              />
            </RadioGroup>
          </div>
        )}
      </div>
    </VBModal>
  );
};
