/*
 * File: editor-setting.slice.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 30th March 2022 5:20:46 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  defaultTextEditorSettingState,
  TextEditorSettingState,
} from "./text-editor-setting.state";

const SLICE_NAME: string = "editor-setting";

const slice = createSlice({
  name: SLICE_NAME,
  initialState: defaultTextEditorSettingState,
  reducers: {
    resetTextEditorSettingState: (state: TextEditorSettingState) => {
      Object.assign(state, defaultTextEditorSettingState);
    },

    keyboardShortcutsSelected: (state: TextEditorSettingState) => {
      state.showKeyboardShortcuts = !state.showKeyboardShortcuts;
    },
    keyboardShortcutsClosed: (state: TextEditorSettingState) => {
      state.showKeyboardShortcuts = false;
    },
    editorSettingsSelected: (state: TextEditorSettingState) => {
      state.showEditorSettings = !state.showEditorSettings;
    },
    editorSettingsClosed: (state: TextEditorSettingState) => {
      state.showEditorSettings = false;
    },

    jobPerTaskEdited: (
      state: TextEditorSettingState,
      action: PayloadAction<number>
    ) => {
      state.jobPerTask = action.payload;
    },
  },
});

export const {
  keyboardShortcutsSelected,
  keyboardShortcutsClosed,
  editorSettingsSelected,
  editorSettingsClosed,
  jobPerTaskEdited,
} = slice.actions;

export const textEditorSettingReducer = slice.reducer;
