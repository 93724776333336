/*
 * File: file-thumbnail.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 10th December 2021 5:08:28 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import VBImage from "components/common/vb-image/vb-image.component";
import { AppEvents } from "constants/annotation.constant";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { useEffect, useMemo, useState } from "react";

interface FileThumbnailProps {
  file?: StorageFileDTO;
  load: boolean;
  onLoaded?(): void;
  onError?(): void;
}
export const FileThumbnail = ({
  file,
  onLoaded,
  onError,
}: FileThumbnailProps) => {
  const thumbnailUrl = useMemo(() => {
    return file?.additionalFileInfo?.addition?.thumbnailUrl || "";
  }, [file]);

  const imageUrl = useMemo(() => {
    if (file?.additionalFileInfo?.addition?.thumbnailUrl) {
      return file.additionalFileInfo.addition.thumbnailUrl;
    }
    // if (file?.url && isFileDicom(file)) return "";
    return file?.url || "";
  }, [file]);

  const [blob, setBLob] = useState<Blob>();

  const localImageUrl = useMemo(() => {
    if (blob) return URL.createObjectURL(blob);
    if (imageUrl) return imageUrl;
    return imageUrl;
  }, [blob, imageUrl]);

  useEffect(() => {
    const handleImageRendered = (event: any) => {
      if (!file) return;
      if (event.detail.fileId !== file.id) return;
      if (!event.detail.canvasElement) return;
      const canvasEl = event.detail.canvasElement as HTMLCanvasElement;
      canvasEl.toBlob((canvasBlob) => canvasBlob && setBLob(canvasBlob));
    };

    if (!blob) {
      document.addEventListener(
        AppEvents.IMAGE_RENDERED_BY_FILE,
        handleImageRendered
      );
    }

    return () => {
      document.removeEventListener(
        AppEvents.IMAGE_RENDERED_BY_FILE,
        handleImageRendered
      );
    };
  }, [file, blob]);

  const [, setLoaded] = useState(false);

  const handleImageLoadStart = () => {
    setLoaded(false);
  };

  const handleImageLoaded = () => {
    setLoaded(true);
    onLoaded && onLoaded();
  };

  const handleImageLoadError = () => {
    setLoaded(true);
    onError && onError();
  };

  return (
    <div className="bg-black select-none aspect-w-4 aspect-h-3">
      <div>
        <div className="relative flex items-center justify-center w-full h-full">
          <VBImage
            onLoadStarted={handleImageLoadStart}
            src={thumbnailUrl || localImageUrl}
            alt={file?.fileName}
            loadStyle="skeleton"
            onLoaded={handleImageLoaded}
            onLoadFailed={handleImageLoadError}
          />
        </div>
      </div>
    </div>
  );
};
