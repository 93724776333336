/*
 * File: use-datasources.hook.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 2nd March 2022 3:30:34 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useMount } from "ahooks";
import { useAppSelector } from "hooks/use-redux";
import { useState } from "react";
import { DatasetDTO } from "services/label-service/dtos";
import { StorageService } from "services/storage-service";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { Logger } from "utilities/logger";
import * as Sentry from "@sentry/react";

export const useDatasources = (params: Record<string, string> = {}) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const [datasources, setDatasources] = useState<DatasetDTO[]>([]);
  const [requesting, setRequesting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useMount(async () => {
    if (!currentUser) return;
    if (requesting) return;
    try {
      setRequesting(true);
      const response =
        await StorageService.getDatasetService().getAllDatasources(params);
      setDatasources(response);
      setLoaded(true);
    } catch (error) {
      Sentry.captureException(error);
      Logger.log(error);
    } finally {
      setRequesting(false);
    }
  });
  return {
    loaded,
    requesting,
    datasources,
  };
};
