/*
 * File: archive-label.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/use-redux";
import { useState } from "react";
import { ObservationDTO, UpdateObservationStrategy } from "services/label-service/dtos";
import {
  updateProjectObservationAsync,
} from "store/customer/project/project.slice";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { ObservationTreeNodeModel } from "services/label-service/model/observation-tree-node.model";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import * as Sentry from "@sentry/react";

interface Props {
  archivingNode: ObservationTreeNodeModel<ObservationDTO> | null;
  childrenValues: (ObservationDTO | null)[];
  onClose: () => void;
  onArchived?: () => void;
}

export const ArchiveLabelModal = ({
  archivingNode,
  childrenValues,
  onClose,
  onArchived,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);

  async function archiveObservation(
    observation: ObservationDTO | null | undefined
  ) {
    if (!observation) return;
    try {
      const payload = {
        ...observation,
        active: false,
      }
      const res = await dispatch(
        updateProjectObservationAsync({ payload, strategy: UpdateObservationStrategy.OVERRIDE })
      );
      handleThunkRejected(res);
      dispatch(enqueueSuccessNotification(t("common:textArchivedSuccess")));
      onArchived && onArchived();
    } catch (err: any) {
      Sentry.captureException(err);
      const message = err.message || t("common:textArchivedFailed");
      dispatch(enqueueErrorNotification(message));
    }
  }

  async function handleSubmit() {
    if (processing) return;
    try {
      setProcessing(true);
      const toArchivedObservations = [archivingNode?.value, ...childrenValues];
      for (let archivingObs of toArchivedObservations) {
        await archiveObservation(archivingObs);
      }
      setProcessing(false);
      onClose && onClose();
    } catch (error) {
      Sentry.captureException(error);
      Logger.log(error);
      setProcessing(false);
    }
  }

  return (
    <VBModal
      title={t("project:label.deleteDialog.title")}
      onClose={onClose}
      open={archivingNode !== null}
      disableSubmit={processing}
      processingIndicator={processing}
      textSubmit={t("common:buttonArchive")}
      onSubmit={handleSubmit}
    >
      <p>{t("project:label.deleteDialog.message")}</p>
    </VBModal>
  );
};
