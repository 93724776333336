/*
 * File: file-info-item.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th June 2023 3:59:05 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Tooltip } from "@material-ui/core";
import { useState } from "react";

interface Props {
  label: string;
  value: string;
}

export function FileInfoItem({ label, value }: Props) {
  const [tooltip, setTooltip] = useState("Click to copy");
  return (
    <div className="py-1">
      <label className="block mb-0.5 text-sm text-gray-400 capitalize">{label}</label>
      <div className="overflow-hidden whitespace-normal">
        <Tooltip arrow title={tooltip}>
          <span
            onMouseLeave={() => setTooltip("Click to copy")}
            onClick={() => {
              navigator.clipboard.writeText(value);
              setTooltip("Copied!");
            }}
          >
            {value}
          </span>
        </Tooltip>
      </div>
    </div>
  );
}

export default FileInfoItem;
