import { AnalyticsQueryResponse } from "services/analytics-service";
import { ProjectTypeNames, ProjectTypes } from "services/label-service/dtos";
import { CardRowDataModel } from "../context/dashboard.state";

export const DEFAULT_DATA: CardRowDataModel[] = [
  {
    name: ProjectTypeNames[ProjectTypes.MEDICAL_IMAGE],
    total: 0,
  },
  {
    name: ProjectTypeNames[ProjectTypes.GENERAL_IMAGE],
    total: 0,
  },
  {
    name: ProjectTypeNames[ProjectTypes.TEXT],
    total: 0,
  },
  {
    name: ProjectTypeNames[ProjectTypes.AUDIO],
    total: 0,
  },
];

export const cardRowModelMapper = (response: AnalyticsQueryResponse): CardRowDataModel[] => {
  return DEFAULT_DATA;
}