/*
 * File: project-label-distribution.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 13th September 2021 4:29:54 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { useProjectLabelDistribution } from "hooks/project/use-project-label-distribution.hook";
import { useAppSelector } from "hooks/use-redux";
import { convertStepDistributionToRowsModel } from "models/common/observations-stats";
import { useMemo } from "react";
import { RequestStatus } from "store/base/base.state";
import { selectProjectStepDistributionData } from "store/customer/project-data/label-distribution/label-distribution.selectors";
import {
  selectCurrentProject,
  selectIsTextProject,
  selectProjectObservations,
} from "store/customer/project/project.selectors";
import { LABEL_DIST_COLUMNS, LABEL_DIST_COLUMNS_TEXT, ObservationDistTree } from "../../components/observation-dist-tree.component";


export const ProjectLabelDistribution = () => {
  const isTextProject = useAppSelector(selectIsTextProject);
  const project = useAppSelector(selectCurrentProject);
  const observations = useAppSelector(selectProjectObservations);
  const { requestStatus } = useProjectLabelDistribution(project?.id || -1);
  const data = useAppSelector(
    selectProjectStepDistributionData(project?.id || -1, 0)
  );
  const labelDistRows = useMemo(() => {
    return convertStepDistributionToRowsModel(data, observations);
  }, [data, observations]);
  if (requestStatus === RequestStatus.LOADING) return <VBInlineLoading />;
  if (!data) return null;

  return (
    <div className="p-4">
      <div className="font-bold mb-4">General</div>
      <ObservationDistTree
        rows={labelDistRows}
        columns={isTextProject ? LABEL_DIST_COLUMNS_TEXT : LABEL_DIST_COLUMNS}
      />
    </div>
  );
};
