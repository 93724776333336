import {
  CreateBatchPayload,
  CreateBatchPayloadWorkManagement,
} from "services/label-service/apis/batch.api";
import { DEFAULT_IOU, ObservationDTO } from "services/label-service/dtos";
import { ProjectInfoDTOV2 } from "services/label-service/dtos/project-v2.dto";
import { WorkManagementDTO } from "services/label-service/dtos/work-management.dto";
import { DEFAULT_DAILY_LIMIT } from "services/user-service/dtos/user.dto";
import { CreateBatchV2PageInfoModel } from "./create-batch-v2.states";

export const toCreateBatchPayload = (
  projectForCreatingBatch: ProjectInfoDTOV2,
  batchInfo: CreateBatchV2PageInfoModel,
  batchDatasource: any
): CreateBatchPayload => {
  const {
    project,
    observation: observations,
    workflow,
    workManagement: workManagements,
  } = projectForCreatingBatch;

  const toCreateBatchWorkManagements = (
    workManagementsDTO: WorkManagementDTO[]
  ): CreateBatchPayloadWorkManagement[] => {
    const res: CreateBatchPayloadWorkManagement[] = [];
    for (const dto of workManagementsDTO) {
      if (!dto.workInstruction) continue;
      const workManagementCreate: CreateBatchPayloadWorkManagement = {
        userId: dto.userId,
        workInstructionId: dto.workInstruction.id,
        dailyLimit: dto.dailyLimit || DEFAULT_DAILY_LIMIT,
        // TODO: add more configs for step review, percentage, limit ... later
        acceptPercentage: dto.acceptPercentage,
        reviewPickType: dto.reviewPickType,
        limit: dto.limit,
        percentage: dto.percentage,
      };
      res.push(workManagementCreate);
    }
    return res;
  };

  const toBatchObservations = (observations: ObservationDTO[]) => {
    // TODO: add more configs later
    return observations.map((obs) => ({
      observationId: obs.id,
      iou: DEFAULT_IOU,
      probabilityRequired: false,
      labelRequired: false,
      // attributeAgreement: [],
    }));
  };

  const toBatchCreate = (batchInfo: CreateBatchV2PageInfoModel) => {
    return {
      description: batchInfo.description,
      instruction: batchInfo.instruction,
      embeddedType: batchInfo.embeddedType,
      embeddedContent: batchInfo.embeddedContent,
      name: batchInfo.name,
      projectId: project.id,
      // TODO: add more configs later
      batchSetting: {
        consensusRate: 1,
        timeLimitSecond: 0,
        // consensusEntity: 0,
        // consensusRelationship: 0,
        activeLearning: batchInfo.activeLearning,
        balanceJobAssing: batchInfo.balanceJobAssign,
      },
    };
  };

  const payload: CreateBatchPayload = {
    workManagement: toCreateBatchWorkManagements(workManagements),
    workflowId: workflow.id,
    batchObservation: toBatchObservations(observations),
    autoCreateTask: batchDatasource.autoCreateTasks || false,
    datasetCriteria: batchDatasource.datasetCriteria,
    batch: toBatchCreate(batchInfo),
  };

  return payload;
};
