/*
 * File: step-1-add-labels.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useDetectChange } from "hooks/use-detect-change";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  BatchObservationCreationModel,
  ObservationDTO,
} from "services/label-service/dtos";
import { useCreateBatchContext } from "../create-batch.context";
import { BatchSteps } from "../create-batch.model";
import { useBuildBatchObservationCreationModelTree } from "hooks/use-observation-tree";
import { ObservationTreeNodeModel } from "services/label-service/model/observation-tree-node.model";
import { usePrevious } from "ahooks";
import {
  TreeTableLayout,
  TreeTableLayoutColDef,
  TreeTableNameCell,
  TreeTableShowChildrenCell,
} from "pages/customer/projects/components/tree-table-layout.component";
import {
  ImportLabelTreeModal,
  ObservationImportModel,
} from "../../batch-detail/pages/labels/components/import-label-tree.modal";
import { IconTrash } from "components/common/vb-icon.component";
import { useAppSelector } from "hooks/use-redux";
import { selectIsMdiSegmentation } from "store/customer/project/project.selectors";

export const AddLabelsStep = () => {
  const { t } = useTranslation();
  const { currentStep, observations, submitData, showCreateLabelDialog } =
    useCreateBatchContext();

  const activeLabels = useMemo(
    () => observations.filter((observation) => observation.active),
    [observations]
  );

  const [labels, setLabels] = useState<BatchObservationCreationModel[]>(() => {
    if (!currentStep) return [];
    if (currentStep.data) return currentStep.data;
    return observations
      .filter((observation) => observation.active)
      .map((observation) => {
        return {
          observation: observation,
          labelRequired: false,
          probabilityRequired: false,
          visible: true,
          showChildren: true,
        };
      });
  });
  const [showSelectLabels, setShowSelectLabels] = useState(false);

  const {
    setShowChildren,
    updatedVisibleNodes,
    findAllNodeChildrenValues,
    nodesList,
  } = useBuildBatchObservationCreationModelTree(labels, false);
  const previousUpdatedVisibleNodes = usePrevious(updatedVisibleNodes);

  const shouldSubmit = useDetectChange([labels]);

  function handleSelectLabelField(field: string, observation?: ObservationDTO) {
    if (!observation) return;
    const newLabels = [
      ...labels.map((b) => {
        if (b.observation?.id === observation.id) {
          return {
            ...b,
            [field]: !(b as any)[field],
          };
        }
        return b;
      }),
    ];
    setLabels(newLabels);
  }

  function handleRemoveLabel(
    node: ObservationTreeNodeModel<BatchObservationCreationModel>
  ) {
    if (!node || !node.value) return;
    const toRemoveValues = findAllNodeChildrenValues(node.id);
    toRemoveValues.push(node.value);
    const newLabels = [
      ...labels.filter(
        (b) =>
          !toRemoveValues.find((v) => v?.observation.id === b.observation.id)
      ),
    ];
    setLabels(newLabels);
  }


  function handleSelectLabels() {
    setShowSelectLabels(true);
  }

  function handleSelectedLabels(observationOptions: ObservationImportModel[]) {
    const newLabels: BatchObservationCreationModel[] = [];
    let countNew = 0;
    for (let observationImport of observationOptions) {
      if (observationImport.selected) {
        const existedBatchObservation = labels.find(
          (l) => l.observation.id === observationImport.observation.id
        );
        if (existedBatchObservation) {
          newLabels.push({
            ...existedBatchObservation,
            labelRequired: observationImport.labelRequired,
            probabilityRequired: observationImport.probabilityRequired,
          });
        } else {
          countNew = countNew + 1;
          newLabels.push({
            observation: observationImport.observation,
            labelRequired: observationImport.labelRequired,
            probabilityRequired: observationImport.probabilityRequired,
            visible: true,
            showChildren: true,
          });
        }
      }
    }

    setLabels(newLabels);
    setShowSelectLabels(false);
  }

  useEffect(() => {
    if (
      updatedVisibleNodes.length <= 0 ||
      updatedVisibleNodes === previousUpdatedVisibleNodes
    )
      return;
    const newLabels = labels.map((label) => {
      const node = updatedVisibleNodes.find(
        (n) => n.value?.observation.id === label.observation.id
      );
      if (node) {
        return {
          ...label,
          visible: node.visible,
          showChildren: node.showChildren,
        };
      } else {
        return label;
      }
    });
    setLabels(newLabels);
  }, [updatedVisibleNodes, previousUpdatedVisibleNodes, labels]);

  useEffect(() => {
    if (shouldSubmit)
      if (labels.length > 0) {
        submitData(BatchSteps.LABELS, labels);
      } else {
        submitData(BatchSteps.LABELS, null);
      }
  }, [shouldSubmit, labels, submitData]);

  return (
    <div className="h-full p-4">
      <div className="flex items-center gap-4">
        <div className="flex-auto py-4 text-xl font-bold">
          {t("project:batch.instruction.step1.title")}
        </div>

        {activeLabels.length > 0 && (
          <button className="button-tertiary" onClick={handleSelectLabels}>
            {t("project:batchDetails.batchLabels.toolbar.buttonImport")}
          </button>
        )}
        {activeLabels.length > 0 && (
          <button className="button-secondary" onClick={showCreateLabelDialog}>
            {t("project:batch.instruction.step1.buttonCreateLabel")}
          </button>
        )}
      </div>

      {activeLabels.length === 0 && (
        <div className="p-4 py-8 text-center">
          <div className="py-4 mx-4">
            {t("project:batch.instruction.step1.messageNoLabel")}
          </div>
          <button
            className="focus:outline-none text-primary"
            onClick={showCreateLabelDialog}
          >
            {t("project:batch.instruction.step1.buttonCreateLabel")}
          </button>
        </div>
      )}

      {labels.length > 0 && (
        <div className="pr-6 text-sm">
          <BatchObservationsListTree
            nodes={nodesList}
            onSelect={handleSelectLabelField}
            onRemove={handleRemoveLabel}
            setShowChildren={setShowChildren}
          />
        </div>
      )}
      <ImportLabelTreeModal
        open={showSelectLabels}
        projectObservations={observations}
        currentBatchObservations={labels}
        onClose={() => setShowSelectLabels(false)}
        onAdd={handleSelectedLabels}
      />
    </div>
  );
};

interface BatchObservationListTreeProps {
  nodes: ObservationTreeNodeModel<BatchObservationCreationModel>[];
  setShowChildren: (nodeId: number, showChildren: boolean) => void;
  onSelect?: (field: string, observation?: ObservationDTO) => void;
  onRemove?: (
    node: ObservationTreeNodeModel<BatchObservationCreationModel>
  ) => void;
}
export const BatchObservationsListTree = ({
  nodes,
  onSelect,
  onRemove,
  setShowChildren,
}: BatchObservationListTreeProps) => {
  const { t } = useTranslation();
  const isMdiProject = useAppSelector(selectIsMdiSegmentation);

  const nodesWithoutSystemNodes = useMemo(() => {
    return nodes.filter(
      (node) => !node.value?.observation.observationSetting.systemAttribute
    );
  }, [nodes]);

  const COLUMNS: TreeTableLayoutColDef[] = [
    {
      header: t("project:batch.instruction.step1.textLabel"),
      flex: 1,
      render: (
        node: ObservationTreeNodeModel<BatchObservationCreationModel>
      ) => {
        return (
          <div className="truncate">
            {node.value && (
              <TreeTableNameCell
                name={node.value.observation?.name}
                nodeHeight={node.height}
                paddingScaler={4}
              />
            )}
          </div>
        );
      },
    },
    {
      header: "",
      width: "3rem",
      render: (
        node: ObservationTreeNodeModel<BatchObservationCreationModel>
      ) => {
        const isLeaf = ObservationTreeNodeModel.isLeaf(node);
        return (
          <TreeTableShowChildrenCell
            isLeaf={isLeaf}
            showChildren={node.showChildren}
            nodeId={node.id}
            setShowChildren={setShowChildren}
          />
        );
      },
    },
    {
      header: t("project:batchDetails.batchLabels.table.headerMaskId"),
      flex: isMdiProject ? 0.3 : 0,
      render: (node: ObservationTreeNodeModel<ObservationImportModel>) => {
        return (
          <div>{node.value?.observation.observationSetting.maskId || "-"}</div>
        );
      },
    },
    {
      header: t("project:batchDetails.batchLabels.table.headerType"),
      flex: 0.3,
      render: (node: ObservationTreeNodeModel<ObservationImportModel>) => {
        return (
          <span className="truncate">
            {node?.value?.observation?.observationSetting.annotationType}
          </span>
        );
      },
    },
    {
      header: t("project:batchDetails.batchLabels.table.headerColor"),
      flex: 0.3,
      render: (node: ObservationTreeNodeModel<ObservationImportModel>) => {
        return (
          <div
            className="w-6 h-4"
            style={{ backgroundColor: node?.value?.observation?.observationSetting.color }}
          />
        );
      },
    },
    {
      header: t("project:batch.instruction.step1.textAction"),
      width: "4rem",
      render: (
        node: ObservationTreeNodeModel<BatchObservationCreationModel>
      ) => {
        const isSystem =
          node.value?.observation.observationSetting?.systemAttribute;
        return (
          <div>
            {!isSystem && (
              <div className="flex items-center">
                <button
                  disabled={isSystem}
                  className="py-2 focus:outline-none disabled:opacity-50"
                  onClick={() => onRemove && onRemove(node)}
                >
                  <IconTrash className="flex-none w-4 h-4" />
                  {/* <i className="uir-trash"></i> */}
                </button>
              </div>
            )}
          </div>
        );
      },
    },
  ];



  return (
    <TreeTableLayout columns={COLUMNS} nodes={nodesWithoutSystemNodes} />
  );
};
