import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { classnames } from "utilities/classes";
import { nameToAvatarColor } from "utilities/string/name-to-avatar-color";


interface Props {
  name: string,
  className?: string;
  width?: string;
  isLoading?: boolean;
}
export const IssueAvatar = ({
  name,
  className = "",
  width = "30px",
  isLoading = false,
}: Props) => {

  return (
    <div className={classnames(
      className,
      "rounded-full items-center justify-center flex text-white cursor-default" 
    )}
      style={{
        backgroundColor: !isLoading ? nameToAvatarColor(name) : "white",
        minWidth: width,
        minHeight: width,
        width: width,
        height: width,
      }}
    >
      {
        !isLoading &&
        <span>{name.charAt(0).toUpperCase()}</span>
      }
      {
        isLoading &&
        <VBInlineLoading spinnerClass="text-primary" />
      }
    </div>
  );
}