/*
 * File: text-input.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 6th December 2021 11:28:46 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Tooltip } from "@material-ui/core";
import { ChangeEvent, HTMLProps, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props extends HTMLProps<HTMLInputElement> {
  header?: string;
  accept: string;
  containerClassName?: string;
  hint?: string;
  require?: boolean;
  onFileChange(file: File | null): void;
}
export const VBFileInputComponent = ({
  containerClassName = "",
  header,
  hint,
  require = false,
  accept,
  onFileChange,
}: Props) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const eventFiles = event.target.files;
    if (!eventFiles || eventFiles.length === 0) return;
    const file = eventFiles.item(0);
    onFileChange(file);
    setIsDraggingOver(false);
  }
  return (
    <div className={`flex flex-col flex-shrink-0 gap-2 ${containerClassName}`}>
      {header && (
        <div className="flex items-center gap-2 text-sm font-semibold">
          <span>{header}</span>
          {require && (
            <Tooltip title="This field is required">
              <span className="text-red-500">*</span>
            </Tooltip>
          )}
        </div>
      )}
      <div className="relative flex items-center justify-center h-40 bg-white border border-gray-200 border-dashed rounded-lg hover:cursor-pointer">
        {!isDraggingOver && (
          <div className="absolute">
            <div className="flex flex-col items-center ">
              <span className="block font-normal">
                {t("dataset:upload.attachFiles")}
              </span>
              <span className="block font-normal">
                {t("dataset:upload.or")}
              </span>
              <span className="block font-normal text-primary">
                {t("dataset:upload.browseFiles")}
              </span>
            </div>
          </div>
        )}
        {isDraggingOver && (
          <div className="absolute flex items-center justify-center w-full h-full p-4">
            <span className="text-primary">
              {t("dataset:upload.dropFiles")}
            </span>
          </div>
        )}
        <input
          ref={inputRef}
          type="file"
          onChange={handleChange}
          onDragEnter={() => setIsDraggingOver(true)}
          onDragExit={() => setIsDraggingOver(false)}
          accept={accept}
          className="w-full h-full opacity-0"
        />
      </div>
      {hint && <div className="text-sm text-error-500">{hint}</div>}
    </div>
  );
};
