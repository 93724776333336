/*
 * File: batch-label-remove-dialog.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 30th August 2021 10:45:33 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useState } from "react";
import { BatchObservationDTO } from "services/label-service/dtos";
import {
  selectBatchInfo,
  selectBatchLabels,
} from "store/customer/batch/batch.selectors";
import { updateBatchManagementAsync } from "store/customer/batch/batch.thunk";
import {
  enqueueSuccessNotification,
  enqueueErrorNotification,
} from "store/common/notification/notification.actions";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { MatDialog } from "components/material/mat-dialog.component";
import * as Sentry from "@sentry/react";

interface Props {
  labels: BatchObservationDTO[];
  handleClose: () => void;
}

export const BatchLabelRemoveDialog = ({ labels, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const batch = useAppSelector(selectBatchInfo);
  const currentBatchLabels = useAppSelector(selectBatchLabels);
  const [processing, setProcessing] = useState(false);

  const handleRemove = async () => {
    if (processing) return;
    if (!labels.length || !batch) return;

    setProcessing(true);

    try {
      const payload = {
        batchId: batch.id,
        payload: {
          batchObservation: currentBatchLabels
            .filter((l) => !labels.find(rl => rl.id === l.id))
            .map((label) => {
              return {
                iou: label.iou,
                labelRequired: label.labelRequired,
                probabilityRequired: label.probabilityRequired,
                observationId: label.observation.id,
              };
            }),
        },
      };
      const res = await dispatch(updateBatchManagementAsync(payload));
      handleThunkRejected(res);
      dispatch(
        enqueueSuccessNotification(
          t("project:batchDetails.batchLabels.remove.textSuccess", {
            name: labels[0].observation.name,
          })
        )
      );
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textRemovedFailed")));
      Logger.log(error);
    } finally {
      setProcessing(false);
      handleClose();
    }
  };

  return (
    <MatDialog disableBackdropClick open={labels.length > 0} onClose={handleClose}>
      <DialogTitle>
        {t("project:batchDetails.batchLabels.remove.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("project:batchDetails.batchLabels.remove.content")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("common:buttonCancel")}
        </Button>
        <Button
          onClick={handleRemove}
          color="primary"
          variant="contained"
          autoFocus
          disabled={processing}
        >
          {t("common:buttonRemove")}
        </Button>
      </DialogActions>
    </MatDialog>
  );
};
