/*
 * File: upload-zip.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { storageAPIpost } from "../storage-httpclient";

export function uploadZip(
  datasetId: number,
  storageResourceId: string
): Promise<any> {
  const data = {
    storageResourceId,
    datasetId: datasetId.toString(),
  };
  return storageAPIpost("/api/v1/files/upload-archive", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
