/*
 * File: dicom-tools.model.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export enum ToolName {
  SAM = "SmartLabeling",
  Grid = "Grid",
  Wwwc = "Wwwc",
  Pan = "Pan",
  Synchronizer = "Synchronizer",
  Zoom = "Zoom",
  StackScrollMouseWheel = "StackScrollMouseWheel",
  Magnify = "Magnify",
  RectangleRoi = "RectangleRoi",
  FreehandRoi = "FreehandRoi",
  FreehandRoiExtend = "FreehandRoiExtend",
  RectangleRoiExtend = "RectangleRoiExtend",
  MultiArrowConnection = "MultiArrowConnection",
  Mask = "Mask",
  Rotate = "Rotate",
  Angle = "Angle",
  CobbAngle = "CobbAngle",
  HeartRuler = "HeartRuler",
  Ruler = "Length",
  Eraser = "Eraser",

  ResetWwwc = "ResetWwwc",
  ResetPan = "ResetPan",
  ResetZoom = "ResetZoom",
  ResetRotate = "ResetRotate",
  ResetAll = "ResetAll",

  Invert = "Invert",
  FlipHorizontal = "FlipHorizontal",
  FlipVertical = "FlipVertical",
  FullScreen = "FullScreen",
}

export interface CornerstoneTool {
  name: string;
  type: ToolName;
  configuration?: Object;
  value?: any;
  disabled?: boolean;
  active?: boolean;
  toolClass?: any;
  subType?: SubType;
  icon?: JSX.Element;
}

export enum SubType {
  DEFAULT = "",
  SHAPE = "shape",
  TRACK = "track",
}
