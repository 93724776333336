/*
 * File: labeler-header.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 19th November 2021 4:07:44 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useClickAway } from "ahooks";
import {
  IconArrowDown,
  IconNotification,
  IconTicket,
} from "components/common/vb-icon.component";
import { UserAvatar } from "components/common/vb-user-avatar.component";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";
import { useAppSelector } from "hooks/use-redux";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectUserRole } from "store/auth/auth.selectors";
import { UserRole } from "store/auth/auth.state";

interface Props {
  userName: string;
  hasNewNotifications?: boolean;
  openUserMenu?: boolean;
  onSelectNotification?(): void;
  onViewProfile?(): void;
  onSwitchRole?(): void;
  onLogout?(): void;
}
export const LabelerHeaderComponent = ({
  hasNewNotifications = false,
  openUserMenu = false,
  userName,
  onSelectNotification,
  onLogout,
  onViewProfile,
  onSwitchRole,
}: Props) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(openUserMenu);

  const { openModal } = useModalContext();
  const useMenuContainerRef = useRef<HTMLDivElement>(null);
  const userMenuRef = useRef<HTMLButtonElement>(null);
  const currentRole = useAppSelector(selectUserRole);

  const handleClickProfile = () => {
    setOpen(false);
    onViewProfile && onViewProfile();
  };

  useClickAway(() => {
    if (open) setOpen(false);
  }, [useMenuContainerRef, userMenuRef]);

  const handleClickUserMenu = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(openUserMenu);
  }, [openUserMenu]);

  return (
    <header className="relative z-50 flex-shrink-0 border-b border-background-300">
      <div className="flex items-center w-full h-20 gap-6 px-6 bg-white">
        <div className="flex-1 h-12"></div>
        <div className="flex items-center flex-shrink-0 h-12 gap-4">
          <button
            className="relative flex items-center justify-center w-12 h-12"
            onClick={() => openModal(ModalTypes.TICKET_LIST)}
          >
            <IconTicket className="flex-none w-6 h-6" />
          </button>
          <button
            className="relative flex items-center justify-center w-12 h-12"
            onClick={onSelectNotification}
          >
            <IconNotification className="w-6 h-6" />
            {hasNewNotifications && (
              <span className="absolute flex items-center justify-center w-3 h-3 text-xs font-bold text-white border border-white rounded-full top-2.5 right-3 bg-error-500"></span>
            )}
          </button>
          <button
            className="flex items-center h-12 gap-2"
            onClick={handleClickUserMenu}
            ref={userMenuRef}
          >
            <UserAvatar className="flex-none w-9 h-9" name={userName} />
            <div
              className="hidden overflow-hidden font-bold capitalize md:block text-background-700 overflow-ellipsis whitespace-nowrap"
              style={{ maxWidth: "10rem" }}
            >
              {userName}
            </div>
            <div className="items-center justify-center hidden w-6 h-6 md:flex">
              <IconArrowDown className="w-6 h-6" />
            </div>
          </button>
        </div>
      </div>

      {open && (
        <div
          className="absolute mt-1 bg-white rounded shadow-lg top-full right-6 animate-fade-in-up"
          ref={useMenuContainerRef}
        >
          <div style={{ maxWidth: "20rem", minWidth: "10rem" }}>
            <div className="flex items-center px-4 py-3">
              <UserAvatar
                name={userName}
                className="flex-shrink-0 w-10 h-10 text-sm"
              />
              <div className="px-4 overflow-hidden font-bold capitalize overflow-ellipsis whitespace-nowrap">
                {userName}
              </div>
            </div>
            <hr />
            <div
              onClick={handleClickProfile}
              className="flex items-center gap-4 px-6 py-3 cursor-pointer hover:text-primary"
            >
              <i className="uir-user"></i>
              <span>{t("usermenu:buttonProfile")}</span>
            </div>
            <div className="border-b border-dashed"></div>
            <div
              onClick={onSwitchRole}
              className="flex items-center px-4 py-3 cursor-pointer hover:text-primary"
            >
              <i className="mx-2 uir-mode-portrait"></i>
              <span className="mx-2">
                {currentRole === UserRole.CUSTOMER
                  ? t("common:mode.labeler")
                  : t("common:mode.admin")}
              </span>
            </div>
            <div className="border-b border-dashed"></div>

            <div
              onClick={onLogout}
              className="flex items-center px-4 py-3 cursor-pointer hover:text-primary"
            >
              <i className="mx-2 uir-sign-out"></i>
              <span className="mx-2">{t("usermenu:buttonSignOut")}</span>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};
