import { fabric } from "fabric";
import {
  ILineOptions,
  IPolylineOptions,
  IRectOptions,
  TextOptions,
} from "fabric/fabric-impl";
import { hexToRGBA } from "utilities/color/color.utils";
import { pxToCm } from "utilities/dimention/pxToCm";
import { truncateEmail } from "utilities/string/truncate-email";
import { v4 } from "uuid";
import {
  actionHandler,
  anchorWrapper,
  polygonPositionHandler,
} from "./fabric-polygon-edit.util";
import {
  DEFAULT_FILL_OBJECTS,
  DEFAULT_TEXT_LABELER_VISIBILITY,
  DEFAULT_TEXT_LABEL_VISIBILITY,
  DEFAULT_UTIL_BUTTON_VISIBILITY,
  FabricObjectToolType,
  FabricOverlayEvents,
} from "./fabric.models";

export const DEFAULT_FILL = "rgba(0,0,0,0.01)";
export const DEFAULT_OBJECT_STROKE_WIDTH = 2;
export const DEFAULT_TEXT_LABEL_FONT_SIZE = 15;
export const DEFAULT_GROUPL_UTIL_CIRCLE_RADIUS = 8;

export function createRectangle(props: IRectOptions, createUtilLabel = true) {
  const rectangle = new fabric.Rect({
    type: FabricObjectToolType.DRAWN_RECTANGLE,
    data: {
      uuid: v4(),
    },
    opacity: 1,
    fill: DEFAULT_FILL,
    // fill: "",
    stroke: "green",
    strokeWidth: 0,
    originX: "left",
    originY: "top",
    width: 0,
    height: 0,
    cornerStyle: "circle",
    cornerColor: "green",
    hoverCursor: "default",
    selectable: false,
    hasBorders: false,
    transparentCorners: false,
    objectCaching: false,
    perPixelTargetFind: true,
    strokeUniform: true, // keep the stroke size when scaling
    paintFirst: "stroke",
    ...props,
  });
  rectangle.setControlsVisibility({ mtr: false });
  rectangle.set({
    strokeWidth: props.strokeWidth || DEFAULT_OBJECT_STROKE_WIDTH,
  });

  if (createUtilLabel) {
    createUtilLabelGroupForObject(rectangle);
  }

  updateMouseEventsOnObject(rectangle);

  return rectangle;
}
export function isValidRectangle(rect: fabric.Rect) {
  return (
    rect.width !== undefined &&
    rect.height !== undefined &&
    rect.left !== undefined &&
    rect.top !== undefined
  );
}

interface createPolygonFromPointsProps {
  points: fabric.Point[];
  strokeWidth?: number;
  updateControls?: boolean;
  createUtilLabel?: boolean;
  otherProps?: IPolylineOptions;
}
export function createPolygonFromPoints({
  points,
  strokeWidth = DEFAULT_OBJECT_STROKE_WIDTH,
  updateControls = true,
  createUtilLabel = true,
  otherProps = {},
}: createPolygonFromPointsProps) {
  const polygon = new fabric.Polygon(points, {
    type: FabricObjectToolType.DRAWN_POLYGON,
    fill: DEFAULT_FILL,
    // fill: "",
    opacity: 1,
    // we set stroke width to 0 for rendering correctly
    // and set the strokeWidth later
    strokeWidth: 0,
    stroke: "green",
    objectCaching: false,
    selectable: false,
    hoverCursor: "default",
    transparentCorners: false,
    // cornerSize: 30,
    cornerStyle: "circle",
    cornerColor: "green",
    hasBorders: false,
    perPixelTargetFind: true,
    strokeUniform: true, // keep the stroke size when scaling
    originX: "center",
    originY: "center",
    // TODO: support later
    // not support yet if support we need to convert points coordinates correspondding to the new position
    lockMovementX: true,
    lockMovementY: true,
    paintFirst: "fill",
    data: {
      uuid: v4(),
    },
    ...otherProps,
  });
  polygon.set({ strokeWidth });

  if (createUtilLabel) {
    createUtilLabelGroupForObject(polygon);
  }

  if (updateControls) {
    updatePolygonControls(polygon);
  }

  updateMouseEventsOnObject(polygon);

  return polygon;
}

interface createLineFromPointsProps {
  points: { x: number; y: number }[];
  props?: ILineOptions;
  isCreateDistance?: boolean;
  createUtilLabel?: boolean;
}
export function createLineFromPoints({
  points,
  props,
  isCreateDistance = true,
  createUtilLabel = false,
}: createLineFromPointsProps) {
  const line = new fabric.Line(
    [points[0].x, points[0].y, points[1].x, points[1].y],
    {
      type: FabricObjectToolType.DRAWN_LINE,
      strokeWidth: 5,
      stroke: "green",
      originX: "center",
      originY: "center",
      selectable: false,
      hasBorders: true,
      hasControls: false,
      hasRotatingPoint: false,
      padding: 2,
      data: {
        uuid: v4(),
      },
    }
  );

  line.set({
    strokeWidth: props?.strokeWidth || DEFAULT_OBJECT_STROKE_WIDTH,
  });

  if (isCreateDistance) createDistanceTextForObject(line);
  if (createUtilLabel) {
    createUtilLabelGroupForObject(line);
  }

  updateMouseEventsOnObject(line);
  return line;
}

export function createDistanceTextForObject(
  object: fabric.Object,
  props?: fabric.TextOptions
) {
  const distanceText = new fabric.Text("", {
    type: FabricObjectToolType.DISTANCE_TEXT,
    selectable: false,
    fontSize: DEFAULT_TEXT_LABEL_FONT_SIZE,
    fontStyle: "normal",
    strokeWidth: 1,
    fill: "white",
    data: {
      parentRef: object,
    },
    ...props,
  });

  object.set({
    data: {
      ...object.data,
      distanceTextRef: distanceText,
    },
  });

  object.canvas?.add(distanceText);
  updateDistanceTextPosForObject(object);

  object.on("moving", (e: any) => {
    updateDistanceTextPosForObject(e.transform.target);
  });

  object.on("scaling", (e: any) => {
    updateDistanceTextPosForObject(e.transform.target);
  });

  return distanceText;
}

export function createUtilLabelGroupForObject(
  object: fabric.Object,
  otherProps?: TextOptions
) {
  const radius = DEFAULT_GROUPL_UTIL_CIRCLE_RADIUS;

  const circle = new fabric.Circle({
    radius,
    fill: "white",
    stroke: "green",
    visible: true,
    strokeWidth: 0,
    data: {},
  });
  circle.set({ strokeWidth: 1 });

  const text = new fabric.Text("", {
    type: FabricObjectToolType.TEXT_LABEL,
    selectable: false,
    perPixelTargetFind: true,
    fontSize: DEFAULT_TEXT_LABEL_FONT_SIZE,
    fontStyle: "normal",
    fill: "white",
    textBackgroundColor: "black",
    hoverCursor: "pointer",
    left: radius * 2 + 2,
    data: {},
  });

  const utilGroup = new fabric.Group([circle, text], {
    type: FabricObjectToolType.UTIL_LABEL_GROUP,
    subTargetCheck: true,
    selectable: false,
    perPixelTargetFind: true,
    originX: "left",
    originY: "center",
    hoverCursor: "pointer",
    fill: "blue",
    backgroundColor: "blue",
    hasBorders: true,
    hasControls: false,
    data: {
      textObjectRef: text,
      circleObjectRef: circle,
      parentRef: object,
    },
    ...otherProps,
  });

  text.data.parentRef = utilGroup;
  circle.data.parentRef = utilGroup;

  object.set({
    data: {
      ...object.data,
      utilGroupRef: utilGroup,
    },
  });

  circle.on("mouseover", () => {
    const fabricAbsPos = objectLocalToFabricAbsolute(
      new fabric.Point(radius / 2, radius / 2),
      circle
    );
    document.dispatchEvent(
      new CustomEvent(FabricOverlayEvents.MOUSE_OVER_UTIL_BUTTON, {
        detail: {
          // we dont use object because it might be changed
          object: utilGroup.data.parentRef,
          circle,
          fabricAbsPos,
        },
      })
    );
  });

  circle.on("mouseout", () => {
    const fabricAbsPos = objectLocalToFabricAbsolute(
      new fabric.Point(radius / 2, radius / 2),
      circle
    );
    document.dispatchEvent(
      new CustomEvent(FabricOverlayEvents.MOUSE_OUT_UTIL_BUTTON, {
        detail: {
          // we dont use object because it might be changed
          object: utilGroup.data.parentRef,
          circle,
          fabricAbsPos,
        },
      })
    );
  });

  object.canvas?.add(utilGroup);

  updateUtilGroupPositionForObject(object);

  object.on("moving", (e: any) => {
    updateUtilGroupPositionForObject(e.transform.target);
  });

  object.on("scaling", (e: any) => {
    updateUtilGroupPositionForObject(e.transform.target);
  });
}

export function updateMouseEventsOnObject(object: fabric.Object) {
  updateMouseOverObject(object);
  updateMouseLeaveObject(object);
}

export function updateMouseOverObject(object: fabric.Object) {
  object.on("mouseover", () => {});
}

export function updateMouseLeaveObject(object: fabric.Object) {
  object.on("mouseout", () => {});
}

export function updateUtilGroupPositionForObject(
  object: fabric.Object,
  x?: number,
  y?: number
) {
  if (object.data?.utilGroupRef) {
    const utilGroup = object.data?.utilGroupRef as fabric.Group;
    const circle = utilGroup.data?.circleObjectRef as fabric.Circle;
    const circleRadius = circle.radius || 0;
    // Transform point from object space to canvas space
    // We use this to handle that case in which the object is in group
    const center = objectLocalToFabricAbsolute(new fabric.Point(0, 0), object);
    const zoom = object.canvas?.getZoom() || 1;

    utilGroup.set({
      left:
        x ||
        center.x -
          (circle.visible ? circleRadius / zoom : (circleRadius / zoom) * 2), // to keep the circle at the center
      top: y || center.y,
    });

    // We need first set scale to 1
    // then call addWithUpdate to get the real dimension and size of the group
    utilGroup.set({ scaleX: 1, scaleY: 1 });
    utilGroup.addWithUpdate();
    // Rescale to the current zoom level
    utilGroup.set({ scaleX: 1 / zoom, scaleY: 1 / zoom });

    utilGroup.setCoords();
    object?.canvas?.bringToFront(utilGroup);
  }
}

export function updateDistanceTextPosForObject(
  object: fabric.Object,
  zoom: number = 1
) {
  if (!object.data?.distanceTextRef) return;

  const distanceText = object.data.distanceTextRef as fabric.Text;
  const line = object as fabric.Line;

  // Apply for line
  const { end } = calcLineCoords(object as fabric.Line);
  const currentZoom = object.canvas?.getZoom() || zoom;

  distanceText.set({
    text: `${pxToCm(line.width || 0, 2)} cm`,
    left: end.x || 0,
    top: end.y || 0,
    fill: object.data.labelColor || "black",
  });
  distanceText.setCoords();

  // Rescale to the current zoom level
  distanceText.set({ scaleX: 1 / currentZoom, scaleY: 1 / currentZoom });

  object?.canvas?.bringToFront(distanceText);
}

export function updatePolygonControls(polygon: fabric.Polygon | undefined) {
  if (!polygon) return;
  if (polygon && polygon.points) {
    const points = polygon.points;
    const lastControl = points.length - 1;
    polygon.controls = points.reduce(function (
      acc: any,
      point: any,
      index: any
    ) {
      acc["p" + index] = new fabric.Control({
        positionHandler: polygonPositionHandler,
        actionHandler: anchorWrapper(
          index > 0 ? index - 1 : lastControl,
          actionHandler
        ),
        actionName: "modifyPolygon",
        pointIndex: index,
      } as any);
      return acc;
    },
    {});
  }
}

// Additional Data

export function updateObjectAdditionalData(
  object: fabric.Object,
  addData: any,
  utilGroupVisibilities: updateObjectUtilGroupVisibilitiesProps = {}
) {
  if (addData) {
    const fill =
      utilGroupVisibilities.fill === undefined
        ? DEFAULT_FILL_OBJECTS
        : utilGroupVisibilities.fill;
    object.set({
      stroke: addData.labelColor || object.stroke,
      fill:
        addData.labelColor && fill
          ? hexToRGBAFabric(addData.labelColor)
          : DEFAULT_FILL,
      data: {
        ...object.data,
        ...addData,
      },
    });
    updateObjectUtilGroupVisibilities(object, utilGroupVisibilities);
    updateObjectDistanceTextVisibilities(object);
  }
}

interface updateObjectUtilGroupVisibilitiesProps {
  labelVisibility?: boolean;
  labelerVisibility?: boolean;
  utilButtonVisibility?: boolean;
  fill?: boolean;
}
export function updateObjectUtilGroupVisibilities(
  object: fabric.Object,
  {
    labelVisibility = DEFAULT_TEXT_LABEL_VISIBILITY,
    labelerVisibility = DEFAULT_TEXT_LABELER_VISIBILITY,
    utilButtonVisibility = DEFAULT_UTIL_BUTTON_VISIBILITY,
  }: updateObjectUtilGroupVisibilitiesProps
) {
  if (object.data?.utilGroupRef) {
    const addData = object.data as any;
    const utilGroup = object.data?.utilGroupRef as fabric.Group;

    if (utilGroup.data?.textObjectRef) {
      const textObject = utilGroup.data.textObjectRef as fabric.Text;
      let text = "";
      if (labelVisibility) text = addData.labelName || "";
      if (labelerVisibility)
        text = `${text} (${truncateEmail(addData.labeler)})`;
      textObject.set({
        text,
        textBackgroundColor: addData.labelColor || "black",
        visible: labelVisibility || labelerVisibility,
      });
    }

    if (utilGroup.data?.circleObjectRef) {
      const circle = utilGroup.data.circleObjectRef as fabric.Circle;
      circle.set({
        stroke: addData.labelColor || "green",
        visible: utilButtonVisibility,
      });
    }

    updateUtilGroupPositionForObject(object);
  }
}

export function updateObjectDistanceTextVisibilities(object: fabric.Object) {
  if (object.data?.distanceTextRef) {
    updateDistanceTextPosForObject(object);
  }
}

export function hideObjectControls(object: fabric.Object) {
  object.set({
    hasControls: false,
    hasBorders: false,
    hoverCursor: "default",
    selectable: false,
    lockMovementX: true,
    lockMovementY: true,
    visible: false,
  });
}

export function getAllChildren(
  object: fabric.Object | undefined,
  includedSelf = false
) {
  if (!object) return [];

  const resOjbects: fabric.Object[] = [];
  const stack = [object];

  while (stack.length > 0) {
    const node = stack.pop();
    if (!node) continue;

    resOjbects.push(node);

    if (!node.data.children) continue;
    for (let i = node.data.children.length - 1; i >= 0; i--) {
      const childNode = node.data.children[i];
      stack.push(childNode);
    }
  }

  if (!includedSelf) {
    resOjbects.shift();
  }

  return resOjbects;
}

export function objectLocalToFabricAbsolute(
  pos: fabric.Point,
  object: fabric.Object
) {
  return fabric.util.transformPoint(pos, object.calcTransformMatrix(false));
}

export function hexToRGBAFabric(hex: string) {
  const rgba = hexToRGBA(hex, { format: "array" }) as number[];
  return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, 0.2)`;
}

export function getDistanceFromTwoPoints(
  point1: { x: number; y: number },
  point2: { x: number; y: number }
) {
  const xDiff: number = point1.x - point2.x;
  const yDiff: number = point1.y - point2.y;

  return +Math.sqrt(xDiff * xDiff + yDiff * yDiff).toFixed(2);
}

export function calcLineCoords(line: fabric.Line) {
  const linePoints = line.calcLinePoints();
  const scaleX = line.scaleX || 1;
  const scaleY = line.scaleY || 1;
  const left = line.left || 0;
  const top = line.top || 0;

  let startCoords, endCoords;
  if ((line.flipY && line.flipX) || (!line.flipY && !line.flipX)) {
    startCoords = {
      x: left + linePoints.x1 * scaleX,
      y: top + linePoints.y1 * scaleY,
    };
    endCoords = {
      x: left + linePoints.x2 * scaleX,
      y: top + linePoints.y2 * scaleY,
    };
  } else {
    startCoords = {
      x: left + linePoints.x1 * scaleX,
      y: top + linePoints.y2 * scaleY,
    };
    endCoords = {
      x: left + linePoints.x2 * scaleX,
      y: top + linePoints.y1 * scaleY,
    };
  }

  return {
    start: startCoords,
    end: endCoords,
  };
}
