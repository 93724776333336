import { LinearProgress } from "@material-ui/core";
import { IconFilterList } from "components/common/vb-icon.component";
import { useMemo, useState } from "react";
import { classnames } from "utilities/classes";
import { DashBoardTablePaging } from "./dash-board-table-paging.component";


export interface DashboardTableCommonProps {
  rows: any[],
  columns: any[],
  sort: any,
  handleSelectSort?: (field: any) => void;
  pageSize?: number;
  defaultPageIndex?: number;
  isLoading?: boolean;
}
export const DashboardTableCommon = ({
  rows,
  columns,
  sort,
  handleSelectSort,
  pageSize = 5,
  defaultPageIndex = 1,
  isLoading = false,
}: DashboardTableCommonProps) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(defaultPageIndex);
  const totalItem = useMemo(() => rows.length, [rows]);
  const rowsToDisplay: any[] = useMemo(() => {
    const startIndex = (currentPageIndex - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize, totalItem);
    return rows.slice(startIndex, endIndex);
  }, [rows, currentPageIndex, pageSize, totalItem]);

  return (
    <div className="p-4 overflow-hidden">
      {
        isLoading &&
        <div className="w-full h-2 z-0">
          <LinearProgress />
        </div>
      }
      <div className="w-full overflow-x-auto">
        <table className="min-w-full vb-table">
          <thead>
            <tr>
              {columns.map((column: any, index: number) => {
                return (
                  <th
                    className={classnames(
                      "px-2 whitespace-nowrap text-background-700 bg-background-200 h-10",
                      {
                        "rounded-l": index === 0,
                        "rounded-r": index === columns.length - 1,
                      }
                    )}
                    key={column.field}
                  >
                    <button
                      onClick={() => handleSelectSort && handleSelectSort(column.field)}
                      className="flex items-center justify-between w-full gap-4 select-none"
                    >
                      <span className="text-sm font-semibold whitespace-nowrap">
                        {column.header}
                      </span>

                      {sort.field !== column.field && (
                        <IconFilterList className="w-4 h-4" />
                      )}
                      {sort.field === column.field && sort.mode === "asc" && (
                        <IconFilterList className="w-4 h-4 text-primary" />
                      )}
                      {sort.field === column.field && sort.mode === "desc" && (
                        <IconFilterList className="w-4 h-4 transform rotate-180 text-primary" />
                      )}
                    </button>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="relative mt-4 text-xs">
            {rowsToDisplay.map((row: any, index: number) => {
              return (
                <tr key={index} className="hover:bg-secondary-50">
                  {columns.map((column: any) => {
                    return (
                      <td className="h-10 px-2" key={column.field}>
                        {column.renderCell(row)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr className="absolute top-0 left-0 w-full h-full border rounded pointer-events-none border-background-300" />
          </tbody>
        </table>

        <DashBoardTablePaging
          totalItem={rows.length || 0}
          pageSize={pageSize}
          defaultPageIndex={defaultPageIndex}
          onPageIndexChanged={setCurrentPageIndex}
        />
      </div>
    </div>
  )
}