import TableRow from "@material-ui/core/TableRow";
import classnames from "classnames";
import { getDeep } from "utilities/object/getDeep";

import TableBaseCell from "./table-base-cell.component";
import { TableBaseRowProps, TableColumn } from "./types";

export const TableBaseRow = <T extends object>({
  columns,
  classNames,
  dataIndex,
  record,
  render,
  ...tableRowProps
}: TableBaseRowProps<T>) => {
  const renderCell = (column: TableColumn) => {
    if (typeof column.render === "function") {
      return column.render(record, dataIndex);
    }

    return (
      <span className="text-background-700">
        {column.dataIndex
          ? getDeep(record, column.dataIndex)
          : column.emptyValue}
      </span>
    );
  };

  return (
    <TableRow
      {...tableRowProps}
      className={classnames(classNames?.row, tableRowProps.className)}
    >
      {render
        ? render(columns)
        : columns.map((column) => (
            <TableBaseCell key={column.key} {...column.cellProps}>
              {renderCell(column)}
            </TableBaseCell>
          ))}
    </TableRow>
  );
};
