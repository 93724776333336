/*
 * File: table-loading.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 23rd May 2023 9:28:47 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { TableCell, TableRow } from "@material-ui/core";

interface TableLoadingProps {
  open?: boolean;
  text: string;
  colSpan: number;
}
export function TableLoading({ open, colSpan, text }: TableLoadingProps) {
  if (!open) return null;
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <div className="p-4 text-center">{text}</div>
      </TableCell>
    </TableRow>
  );
}

export default TableLoading;
