/*
 * File: edit-workflow.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Backdrop, Grow } from "@material-ui/core";
import { VBRichTextEditor } from "components/common/vb-rich-text/vb-rich-text.component";
import { MatModal } from "components/material/mat-modal.component";
import { useAppDispatch } from "hooks/use-redux";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { WorkflowDTO } from "services/label-service/dtos";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { updateWorkflowAsync } from "store/customer/workflow/workflow.thunk";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import * as Sentry from "@sentry/react";
import DOMPurify from "dompurify";

interface Props {
  visible: boolean;
  workflow: WorkflowDTO | null;
  onClose(): void;
}

export const EditWorkflowModal = ({ visible, workflow, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionDataRef = useRef("");
  const [processing, setProcessing] = useState(false);

  function handleDescriptionChange(data: string) {
    descriptionDataRef.current = data;
  }

  function escapeHtml(text: string) {
    return text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  async function handleSaveChanges() {
    if (!workflow) return;
    if (processing) return;
    setProcessing(true);
    const data = {
      name: DOMPurify.sanitize(nameRef.current?.value || ""),
      description: DOMPurify.sanitize(descriptionDataRef.current || ""),
    };
    try {
      inputValidation(data);
    } catch (err: any) {
      Sentry.captureException(err);
      if (err) {
        dispatch(enqueueErrorNotification(err.message));
      }
      return;
    }
    const item: WorkflowDTO = {
      ...workflow,
      name: escapeHtml(DOMPurify.sanitize(data.name)),
      description: escapeHtml(DOMPurify.sanitize(data.description)),
    };
    try {
      const response = await dispatch(updateWorkflowAsync(item));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textSaved")));
      onClose();
    } catch (err) {
      Sentry.captureException(err);
      dispatch(enqueueErrorNotification(t("common:textUpdatedFailed")));
    }
    setProcessing(false);
  }

  function inputValidation(data: { name: string; description: string }) {
    if (!data.name.trim()) throw new Error(t("workflow:edit.textNameRequired"));
  }

  return (
    <MatModal
      disableBackdropClick
      open={visible}
      closeAfterTransition
      BackdropComponent={Backdrop}
      className="flex items-center justify-center"
    >
      <Grow in={visible}>
        <div
          className="relative flex flex-col w-full h-full overflow-hidden bg-white rounded shadow"
          style={{ maxWidth: "48em", maxHeight: "36em" }}
        >
          <div className="flex items-center flex-shrink-0 h-16 px-4 bg-gray-100 border-b">
            <div className="flex-1 px-4 text-xl font-bold">
              {t("workflow:edit.title")}
            </div>
            <button
              className="flex items-center justify-center w-10 h-10 rounded focus:outline-none"
              onClick={onClose}
            >
              <i className="text-sm uir-cross" />
            </button>
          </div>

          <div className="flex-grow px-8 py-8 overflow-y-auto">
            <form>
              <div className="grid items-center grid-cols-12">
                <div className="col-span-3 px-4 my-4 text-right">
                  {t("workflow:edit.textName")}{" "}
                  <span className="text-red-500">*</span>
                </div>
                <div className="col-span-9">
                  <input
                    autoFocus
                    required
                    ref={nameRef}
                    defaultValue={workflow?.name || ""}
                    type="text"
                    id="name"
                    placeholder={t("workflow:edit.placeholderName")}
                    className="w-full px-4 py-2 border border-gray-200 rounded"
                  />
                </div>

                <div className="h-full col-span-3 px-4 my-4 text-right">
                  {t("workflow:edit.textDescription")}
                </div>
                <div className="col-span-9">
                  <VBRichTextEditor
                    defaultValue={workflow?.description || ""}
                    onChange={handleDescriptionChange}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="flex justify-end px-8 py-4 bg-gray-100">
            <button
              className="px-4 py-2 mr-4 bg-white border rounded text-primary border-primary focus:outline-none"
              onClick={onClose}
            >
              {t("common:buttonCancel")}
            </button>
            <button
              className="px-4 py-2 text-white rounded bg-primary focus:outline-none disabled:opacity-50"
              onClick={handleSaveChanges}
              disabled={processing}
            >
              {t("common:buttonSaveChanges")}
            </button>
          </div>
        </div>
      </Grow>
    </MatModal>
  );
};
