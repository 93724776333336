/*
 * File: batch-workflow.api.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 17th July 2021 9:15:32 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { BatchWorkflowDTO } from "../dtos/batch-workflow.dto";
import { BaseAPI } from "./base.api";

export class BatchWorkflowAPI extends BaseAPI<BatchWorkflowDTO> {}
