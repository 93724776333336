/*
 * File: text-labeler-list.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 22nd December 2021 4:31:40 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { selectPreviousLabelingData } from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { startReview } from "store/labeler/text-workspace/text-workspace.slice";

export const TextLabelerList = () => {
  const dispatch = useAppDispatch();

  const previousLabelingData = useAppSelector(selectPreviousLabelingData);

  const handleEditMediaAgreement = () => {
    dispatch(startReview());
  };

  return (
    <div className="mt-4">
      <div className="flex items-center px-4">
        <div className="flex-1 py-2 text-sm font-bold uppercase">
          Previous Findings
        </div>
        <button
          className="flex items-center justify-center w-6 h-6 focus:outline-none"
          onClick={handleEditMediaAgreement}
        >
          <i className="uir-edit"></i>
        </button>
      </div>

      <div className="px-4 overflow-hidden">
        {previousLabelingData.map((labelingData) => {
          return (
            <div
              key={labelingData.jobId}
              className="py-1 overflow-hidden text-gray-600 whitespace-nowrap overflow-ellipsis"
            >
              {labelingData.annotator}
            </div>
          );
        })}
      </div>
    </div>
  );
};
