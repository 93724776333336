/*
 * File: FreehandRoiExtend copy.js
 * Project: app-aiscaler-web
 * File Created: Saturday, 21st May 2022 9:05:59 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import * as csTools from "cornerstone-tools";
import {
  addToolState,
  getToolState,
  removeToolState,
  toolStyle,
  toolColors,
} from "cornerstone-tools";
import getActiveTool from "./HeartRulerTool_utils/getActiveTool";
import * as Sentry from "@sentry/react";

const BaseAnnotationTool = csTools.importInternal("base/BaseAnnotationTool");

const triggerEvent = csTools.importInternal("util/triggerEvent");
const moveHandleNearImagePoint = csTools.importInternal(
  "manipulators/moveHandleNearImagePoint"
);
const isPointInPolygon = csTools.importInternal("util/isPointInPolygon");
const MouseCursor = csTools.importInternal("tools/cursors/MouseCursor");
const getNewContext = csTools.importInternal("drawing/getNewContext");
const draw = csTools.importInternal("drawing/draw");
const drawLines = csTools.importInternal("drawing/drawLines");
const drawHandles = csTools.importInternal("drawing/drawHandles");
const drawArrow = csTools.importInternal("drawing/drawArrow");
const clipToBox = csTools.importInternal("util/clipToBox");
const freehandUtils = csTools.importInternal("util/freehandUtils");
const throttle = csTools.importInternal("util/throttle");
const lineSegDistance = csTools.importInternal("util/lineSegDistance");
const EVENTS = csTools.EVENTS;
const getModule = csTools.getModule;
const external = csTools.external;
const { state } = csTools.store;

const { FreehandHandleData } = freehandUtils;

export const freehandRoiCursor = new MouseCursor(
  `<g id="arrowAnnotate-group" fill="none" stroke-width="1" stroke="ACTIVE_COLOR" stroke-linecap="round" stroke-linejoin="round">
  <path id="arrowAnnotate-arrow" d="M23,7 l-15,15 M7,17 l0,6 6,0" stroke-width="2" />
</g>`,
  {
    viewBox: {
      x: 24,
      y: 24,
    },
  }
);

/**
 * @public
 * @class FreehandRoiTool
 * @memberof Tools.Annotation
 * @classdesc Tool for drawing arbitrary polygonal regions of interest, and
 * measuring the statistics of the enclosed pixels.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class MultiArrowConnectionTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: "MultiArrowConnection",
      supportedInteractionTypes: ["Mouse", "Touch"],
      configuration: defaultFreehandConfiguration(),
      svgCursor: freehandRoiCursor,
    };

    super(props, defaultProps);

    this.isMultiPartTool = true;

    this._drawing = false;
    this._dragging = false;
    this._modifying = false;

    // Create bound callback functions for private event loops
    this._mouseMoveCallback = this._mouseMoveCallback.bind(this);
    this._drawingMouseDownCallback = this._drawingMouseDownCallback.bind(this);
    this._drawingMouseMoveCallback = this._drawingMouseMoveCallback.bind(this);
    this._drawingMouseDragCallback = this._drawingMouseDragCallback.bind(this);
    this._drawingMouseUpCallback = this._drawingMouseUpCallback.bind(this);
    this._drawingMouseDoubleClickCallback =
      this._drawingMouseDoubleClickCallback.bind(this);
    this._editMouseUpCallback = this._editMouseUpCallback.bind(this);
    this._editMouseDragCallback = this._editMouseDragCallback.bind(this);

    this._drawingTouchStartCallback =
      this._drawingTouchStartCallback.bind(this);
    this._drawingTouchDragCallback = this._drawingTouchDragCallback.bind(this);
    this._drawingDoubleTapClickCallback =
      this._drawingDoubleTapClickCallback.bind(this);
    this._editTouchDragCallback = this._editTouchDragCallback.bind(this);
  }

  createNewMeasurement(eventData) {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      logger.error(
        `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      );

      return;
    }

    const measurementData = {
      visible: true,
      active: true,
      invalidated: true,
      color: "#FF00FF",
      type: this.name,
      handles: {
        points: [],
      },
    };

    measurementData.handles.textBox = {
      active: false,
      hasMoved: false,
      movesIndependently: false,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
    };

    return measurementData;
  }

  isMouseEnable(element) {
    const activeTool = getActiveTool(element, 1);
    if ("MultiArrowConnection" === activeTool?.name) return true;
    return false;
  }

  /**
   *
   *
   * @param {*} element element
   * @param {*} data data
   * @param {*} coords coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    const validParameters = data && data.handles && data.handles.points;

    if (!validParameters) {
      throw new Error(
        `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      );
    }

    if (!validParameters || data.visible === false) {
      return false;
    }

    const isPointNearTool = this._pointNearToolData(element, data, coords);

    if (isPointNearTool !== undefined) {
      return true;
    }

    return false;
  }

  /**
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {number} the distance in px from the provided coordinates to the
   * closest rendered portion of the annotation. -1 if the distance cannot be
   * calculated.
   */
  distanceFromPoint(element, data, coords) {
    let distance = Infinity;

    for (let i = 0; i < data.handles.points.length; i++) {
      const distanceI = external.cornerstoneMath.point.distance(
        data.handles.points[i],
        coords
      );

      distance = Math.min(distance, distanceI);
    }

    // If an error caused distance not to be calculated, return -1.
    if (distance === Infinity) {
      return -1;
    }

    return distance;
  }

  /**
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {number} the distance in canvas units from the provided coordinates to the
   * closest rendered portion of the annotation. -1 if the distance cannot be
   * calculated.
   */
  distanceFromPointCanvas(element, data, coords) {
    let distance = Infinity;

    if (!data) {
      return -1;
    }

    const canvasCoords = external.cornerstone.pixelToCanvas(element, coords);

    const points = data.handles.points;

    for (let i = 0; i < points.length; i++) {
      const handleCanvas = external.cornerstone.pixelToCanvas(
        element,
        points[i]
      );

      const distanceI = external.cornerstoneMath.point.distance(
        handleCanvas,
        canvasCoords
      );

      distance = Math.min(distance, distanceI);
    }

    // If an error caused distance not to be calculated, return -1.
    if (distance === Infinity) {
      return -1;
    }

    return distance;
  }

  /**
   *
   *
   *
   * @param {Object} image image
   * @param {Object} element element
   * @param {Object} data data
   *
   * @returns {void}  void
   */
  updateCachedStats(image, element, data) {
    const points = data.handles.points;
    // If the data has been invalidated, and the tool is not currently active,
    // We need to calculate it again.

    if (!points || points.length < 1) return;

    // Retrieve the bounds of the ROI in image coordinates
    const bounds = {
      left: points[0].x,
      right: points[0].x,
      bottom: points[0].y,
      top: points[0].x,
    };

    for (let i = 0; i < points.length; i++) {
      bounds.left = Math.min(bounds.left, points[i].x);
      bounds.right = Math.max(bounds.right, points[i].x);
      bounds.bottom = Math.min(bounds.bottom, points[i].y);
      bounds.top = Math.max(bounds.top, points[i].y);
    }

    const polyBoundingBox = {
      left: bounds.left,
      top: bounds.top,
      width: Math.abs(bounds.right - bounds.left),
      height: Math.abs(bounds.top - bounds.bottom),
    };

    // Store the bounding box information for the text box
    data.polyBoundingBox = polyBoundingBox;

    // Set the invalidated flag to false so that this data won't automatically be recalculated
    data.invalidated = false;
  }

  /**
   *
   *
   * @param {*} evt
   * @returns {undefined}
   */
  renderToolData(evt) {
    const eventData = evt.detail;

    // If we have no toolState for this element, return immediately as there is nothing to do
    const toolState = getToolState(evt.currentTarget, this.name);

    if (!toolState) {
      return;
    }
    const { image, element } = eventData;
    const config = this.configuration;
    const seriesModule = external.cornerstone.metaData.get(
      "generalSeriesModule",
      image.imageId
    );
    const modality = seriesModule ? seriesModule.modality : null;

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    const lineWidth = toolStyle.getToolWidth();
    const { renderDashed, currentMeasurementId } = config;
    const lineDash = getModule("globalConfiguration").configuration.lineDash;

    for (let i = 0; i < toolState.data.length; i++) {
      const data = toolState.data[i];

      if (data.visible === false) {
        continue;
      }

      draw(context, (context) => {
        let color = toolColors.getColorIfActive(data);
        let fillColor;

        if (data.active) {
          color = "greenyellow";
          fillColor = toolColors.getFillColor();
        } else {
          fillColor = toolColors.getToolColor();
        }

        let options = { color };

        if (renderDashed) {
          options.lineDash = lineDash;
        }
        const isDrawing = this._drawing && data.uuid === currentMeasurementId;
        if (data.handles.points.length) {
          const points = data.handles.points;
          const lines = [];
          for (let idx = 1; idx < points.length; idx++) {
            if (!isDrawing && idx === points.length - 1) continue;
            lines.push({
              start: points[idx - 1],
              end: points[idx],
            });
          }

          if (!isDrawing) {
            const handleStartCanvas = external.cornerstone.pixelToCanvas(
              element,
              points[points.length - 2]
            );
            const handleEndCanvas = external.cornerstone.pixelToCanvas(
              element,
              points[points.length - 1]
            );

            drawArrow(context, handleStartCanvas, handleEndCanvas, color, 2);
          }
          drawLines(context, element, lines, options);

          if (isDrawing && data.active && !data.polyBoundingBox) {
            const handleStartCanvas = external.cornerstone.pixelToCanvas(
              element,
              points[points.length - 1]
            );
            const handleEndCanvas = external.cornerstone.pixelToCanvas(
              element,
              config.mouseLocation.handles.start
            );

            drawArrow(context, handleStartCanvas, handleEndCanvas, color, 2);
          }
        }

        // Draw handles

        options = {
          color,
          fill: fillColor,
        };
        if (config.alwaysShowHandles || data.active) {
          // Render all handles
          options.handleRadius = config.activeHandleRadius;

          if (this.configuration.drawHandles) {
            drawHandles(context, eventData, data.handles.points, options);
          }
        }

        if (data.active && !data.polyBoundingBox && this._drawing) {
          // Draw handle at origin and at mouse if actively drawing
          options.handleRadius = config.activeHandleRadius;

          if (this.configuration.drawHandles) {
            drawHandles(
              context,
              eventData,
              config.mouseLocation.handles,
              options
            );
          }
        }
      });
    }
  }

  addNewMeasurement(evt) {
    const eventData = evt.detail;
    const { element, currentPoints } = eventData;
    const measurement = this._getMeasurementNearPoint(
      element,
      currentPoints.image
    );
    if (!measurement) return;
    this._startDrawing(evt);
    this._addPoint(eventData);

    preventPropagation(evt);
  }

  handleSelectedCallback(evt, toolData, handle, interactionType = "mouse") {
    const { element } = evt.detail;
    const toolState = getToolState(element, this.name);
    if (handle.hasBoundingBox) {
      // Use default move handler.
      moveHandleNearImagePoint(evt, this, toolData, handle, interactionType);

      return;
    }

    const config = this.configuration;

    config.dragOrigin = {
      x: handle.x,
      y: handle.y,
    };

    // Iterating over handles of all toolData instances to find the indices of the selected handle
    for (let toolIndex = 0; toolIndex < toolState.data.length; toolIndex++) {
      const points = toolState.data[toolIndex].handles.points;

      for (let p = 0; p < points.length; p++) {
        if (points[p] === handle) {
          config.currentHandle = p;
          config.currentTool = toolIndex;
        }
      }
    }

    this._modifying = true;

    this._activateModify(element);
    this.triggerEventMeasurementSelected(evt, toolData);

    // Interupt eventDispatchers
    preventPropagation(evt);
  }

  triggerEventMeasurementSelected(evt, data) {
    const eventType = "cornerstonetoolsmeasurementselected";
    const element = evt.detail.element;
    const eventData = {
      toolName: this.name,
      toolType: this.name,
      element,
      measurementData: data,
    };

    triggerEvent(element, eventType, eventData);
  }

  _hoveringMeasurement = undefined;
  _activeMeasurements = [];
  /**
   * Event handler for MOUSE_MOVE during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _mouseMoveCallback(evt) {
    this._updateActiveMeasurementOnHover(evt);
    this._updateActiveMeasurements(evt);
  }

  _updateActiveMeasurementOnHover(evt) {
    const eventData = evt.detail;
    const { currentPoints, element } = eventData;
    const mouseLocation = currentPoints.image;
    if (this._hoveringMeasurement) this._hoveringMeasurement.active = false;
    const measurement = this._getMeasurementNearPoint(element, mouseLocation);
    if (measurement) {
      measurement.active = true;
      this._hoveringMeasurement = measurement;
    }
  }

  _updateActiveMeasurements(evt, isEditing = false) {
    const eventData = evt.detail;
    const { element } = eventData;

    for (const measurement of this._activeMeasurements) {
      measurement.active = false;
    }
    const measurementIds = [];
    this._activeMeasurements = [];

    if (isEditing) return;

    const toolStates = getToolState(element, this.name);
    if (!toolStates?.data?.length) return;
    for (let d = 0; d < toolStates.data.length; d++) {
      const data = toolStates.data[d];
      if (data?.active && data?.handles?.points) {
        for (const point of data.handles.points) {
          if (!point?.measurementUUID) continue;
          if (measurementIds.includes(point.measurementUUID)) continue;
          measurementIds.push(point.measurementUUID);
        }
      }
    }

    this._activeMeasurements = this._getMeasurementByIds(
      element,
      measurementIds
    );
    for (const measurement of this._activeMeasurements) {
      measurement.active = true;
    }
    return measurementIds;
  }
  /**
   * Event handler for MOUSE_MOVE during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingMouseMoveCallback(evt) {
    const eventData = evt.detail;
    const { currentPoints, element } = eventData;
    const toolState = getToolState(element, this.name);

    const config = this.configuration;
    const currentTool = config.currentTool;

    const data = toolState.data[currentTool];
    const coords = currentPoints.canvas;

    // Set the mouseLocation handle
    this._getMouseLocation(eventData);

    // Mouse move -> Polygon Mode
    const handleNearby = this._pointNearHandle(element, data, coords);
    const points = data.handles.points;
    // If there is a handle nearby to snap to
    // (and it's not the actual mouse handle)

    if (
      handleNearby !== undefined &&
      !handleNearby.hasBoundingBox &&
      handleNearby < points.length - 1
    ) {
      config.mouseLocation.handles.start.x = points[handleNearby].x;
      config.mouseLocation.handles.start.y = points[handleNearby].y;
    }

    // Force onImageRendered
    external.cornerstone.updateImage(element);
  }

  /**
   * Event handler for MOUSE_DRAG during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingMouseDragCallback(evt) {
    if (!this.options.mouseButtonMask.includes(evt.detail.buttons)) {
      return;
    }

    this._drawingDrag(evt);
  }

  /**
   * Event handler for TOUCH_DRAG during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingTouchDragCallback(evt) {
    this._drawingDrag(evt);
  }

  _drawingDrag(evt) {
    const eventData = evt.detail;
    const { element } = eventData;

    const toolState = getToolState(element, this.name);

    const config = this.configuration;
    const currentTool = config.currentTool;

    const data = toolState.data[currentTool];

    // Set the mouseLocation handle
    this._getMouseLocation(eventData);
    this._addPointPencilMode(eventData, data.handles.points);
    this._dragging = true;

    // Force onImageRendered
    external.cornerstone.updateImage(element);
  }

  /**
   * Event handler for MOUSE_UP during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingMouseUpCallback(evt) {
    if (!this._dragging) {
      return;
    }

    this._dragging = false;

    const config = this.configuration;
    const currentTool = config.currentTool;
    const toolState = getToolState(element, this.name);
    const data = toolState.data[currentTool];

    // if (data.handles.points.length >= 2) {
    //   const lastHandlePlaced = config.currentHandle;

    //   this._endDrawing(element, lastHandlePlaced);
    // }

    preventPropagation(evt);

    return;
  }

  /**
   * Event handler for MOUSE_DOWN during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingMouseDownCallback(evt) {
    const eventData = evt.detail;
    const { buttons, currentPoints, element } = eventData;

    if (!this.options.mouseButtonMask.includes(buttons)) {
      return;
    }

    const coords = currentPoints.canvas;

    const config = this.configuration;
    const currentTool = config.currentTool;
    const toolState = getToolState(element, this.name);
    const data = toolState.data[currentTool];

    const handleNearby = this._pointNearHandle(element, data, coords);

    // if (data.handles.points.length >= 2) {
    //   const lastHandlePlaced = config.currentHandle;

    //   this._endDrawing(element, lastHandlePlaced);
    // } else if (handleNearby === undefined) {
    //   this._addPoint(eventData);
    // }

    this._addPoint(eventData);

    preventPropagation(evt);

    return;
  }

  /**
   * Event handler for TOUCH_START during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingTouchStartCallback(evt) {
    const eventData = evt.detail;
    const { currentPoints, element } = eventData;

    const coords = currentPoints.canvas;

    const config = this.configuration;
    const currentTool = config.currentTool;
    const toolState = getToolState(element, this.name);
    const data = toolState.data[currentTool];

    const handleNearby = this._pointNearHandle(element, data, coords);

    // if (data.handles.points.length >= 2) {
    //   const lastHandlePlaced = config.currentHandle;

    //   this._endDrawing(element, lastHandlePlaced);
    // } else if (handleNearby === undefined) {
    //   this._addPoint(eventData);
    // }

    this._addPoint(eventData);

    preventPropagation(evt);

    return;
  }

  /** Ends the active drawing loop and completes the polygon.
   *
   * @public
   * @param {Object} element - The element on which the roi is being drawn.
   * @returns {null}
   */
  completeDrawing(element) {
    if (!this._drawing) {
      return;
    }
    const toolState = getToolState(element, this.name);
    const config = this.configuration;
    const data = toolState.data[config.currentTool];

    if (data.handles.points.length >= 2) {
      const lastHandlePlaced = config.currentHandle;

      data.polyBoundingBox = {};
      this._endDrawing(element, lastHandlePlaced);
    }
  }

  /**
   * Event handler for MOUSE_DOUBLE_CLICK during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingMouseDoubleClickCallback(evt) {
    const { element } = evt.detail;

    this.completeDrawing(element);

    preventPropagation(evt);
  }

  /**
   * Event handler for DOUBLE_TAP during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingDoubleTapClickCallback(evt) {
    const { element } = evt.detail;

    this.completeDrawing(element);

    preventPropagation(evt);
  }

  /**
   * Event handler for MOUSE_DRAG during handle drag event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _editMouseDragCallback(evt) {
    this._updateActiveMeasurementOnHover(evt);
    this._updateActiveMeasurements(evt, true);
    const eventData = evt.detail;
    const { element, buttons } = eventData;

    if (!this.options.mouseButtonMask.includes(buttons)) {
      return;
    }

    const toolState = getToolState(element, this.name);

    const config = this.configuration;
    const data = toolState.data[config.currentTool];
    const currentHandle = config.currentHandle;
    const points = data.handles.points;
    let handleIndex = -1;

    // Set the mouseLocation handle
    this._getMouseLocation(eventData);

    data.active = true;
    data.highlight = true;
    points[currentHandle].x = config.mouseLocation.handles.start.x;
    points[currentHandle].y = config.mouseLocation.handles.start.y;

    handleIndex = this._getPrevHandleIndex(currentHandle, points);

    // Update the image
    external.cornerstone.updateImage(element);
  }

  /**
   * Event handler for TOUCH_DRAG during handle drag event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {void}
   */
  _editTouchDragCallback(evt) {
    const eventData = evt.detail;
    const { element } = eventData;

    const toolState = getToolState(element, this.name);

    const config = this.configuration;
    const data = toolState.data[config.currentTool];
    const currentHandle = config.currentHandle;
    const points = data.handles.points;
    let handleIndex = -1;

    // Set the mouseLocation handle
    this._getMouseLocation(eventData);
    data.active = true;
    data.highlight = true;
    points[currentHandle].x = config.mouseLocation.handles.start.x;
    points[currentHandle].y = config.mouseLocation.handles.start.y;

    handleIndex = this._getPrevHandleIndex(currentHandle, points);

    if (currentHandle >= 0) {
      const lastLineIndex = points[handleIndex].lines.length - 1;
      const lastLine = points[handleIndex].lines[lastLineIndex];

      lastLine.x = config.mouseLocation.handles.start.x;
      lastLine.y = config.mouseLocation.handles.start.y;
    }

    // Update the image
    external.cornerstone.updateImage(element);
  }

  /**
   * Returns the previous handle to the current one.
   * @param {Number} currentHandle - the current handle index
   * @param {Array} points - the handles Array of the freehand data
   * @returns {Number} - The index of the previos handle
   */
  _getPrevHandleIndex(currentHandle, points) {
    if (currentHandle === 0) {
      return points.length - 1;
    }

    return currentHandle - 1;
  }

  /**
   * Event handler for MOUSE_UP during handle drag event loop.
   *
   * @private
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _editMouseUpCallback(evt) {
    const eventData = evt.detail;
    const { element } = eventData;
    const toolState = getToolState(element, this.name);
    try {
      const config = this.configuration;
      const currentTool = config.currentTool;
      const data = toolState.data[currentTool];
      if (config.currentHandle >= 0) {
        const handle = data.handles.points[config.currentHandle];
        const newMeasurement = this._getMeasurementNearPoint(element, handle);
        if (
          !newMeasurement ||
          !!data.handles.points.find((p, idx) => {
            return (
              idx !== config.currentHandle &&
              p.measurementUUID === newMeasurement.uuid
            );
          })
        ) {
          data.handles.points.splice(config.currentHandle, 1);
        } else {
          const centerPoint = this._measurementCenterPoint(newMeasurement);
          handle.x = centerPoint.x;
          handle.y = centerPoint.y;
          handle.measurementUUID = newMeasurement.uuid;
        }
      }
      if (data) this.fireUpdatedEvent(element, data);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }

    this._deactivateModify(element);

    this._dropHandle(eventData, toolState);
    this._endDrawing(element);

    external.cornerstone.updateImage(element);
  }

  fireUpdatedEvent(element, measurementData) {
    const eventType = "cornerstonetoolsmeasurementupdated";
    const eventData = {
      toolName: this.name,
      toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
      element,
      measurementData,
    };

    triggerEvent(element, eventType, eventData);
  }

  /**
   * Places a handle of the freehand tool if the new location is valid.
   * If the new location is invalid the handle snaps back to its previous position.
   *
   * @private
   * @param {Object} eventData - Data object associated with the event.
   * @param {Object} toolState - The data associated with the freehand tool.
   * @modifies {toolState}
   * @returns {undefined}
   */
  _dropHandle(eventData, toolState) {
    const config = this.configuration;
    const currentTool = config.currentTool;
    const handles = toolState.data[currentTool].handles;
    const points = handles.points;
  }

  /**
   * Begining of drawing loop when tool is active and a click event happens far
   * from existing handles.
   *
   * @private
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _startDrawing(evt) {
    const eventData = evt.detail;
    const measurementData = this.createNewMeasurement(eventData);
    const { element } = eventData;
    const config = this.configuration;

    let interactionType;

    if (evt.type === EVENTS.MOUSE_DOWN_ACTIVATE) {
      interactionType = "Mouse";
    } else if (evt.type === EVENTS.TOUCH_START_ACTIVE) {
      interactionType = "Touch";
    }
    this._activateDraw(element, interactionType);
    this._getMouseLocation(eventData);

    addToolState(element, this.name, measurementData);

    const toolState = getToolState(element, this.name);

    config.currentTool = toolState.data.length - 1;

    this._activeDrawingToolReference = toolState.data[config.currentTool];

    config.currentMeasurementId = measurementData.uuid;
  }

  /**
   * Adds a point on mouse click in polygon mode.
   *
   * @private
   * @param {Object} eventData - data object associated with an event.
   * @returns {undefined}
   */
  _addPoint(eventData) {
    const { element, currentPoints } = eventData;
    const toolState = getToolState(element, this.name);
    // Get the toolState from the last-drawn polygon
    const config = this.configuration;
    const data = toolState.data[config.currentTool];

    const measurement = this._getMeasurementNearPoint(
      element,
      currentPoints.image
    );
    const measurementPoint = this._measurementCenterPoint(measurement);
    if (!measurementPoint) {
      const lastHandlePlaced = config.currentHandle;
      this._endDrawing(element, lastHandlePlaced);
      external.cornerstone.updateImage(element);
      return;
    }

    if (
      data.handles.points.find((p) => p.measurementUUID === measurement.uuid)
    ) {
      return;
    }

    const newHandleData = new FreehandHandleData(measurementPoint);
    newHandleData.measurementUUID = measurement.uuid;

    // If this is not the first handle
    if (data.handles.points.length) {
      // Add the line from the current handle to the new handle
      data.handles.points[config.currentHandle - 1].lines.push(
        measurementPoint
      );
    }

    // Add the new handle
    data.handles.points.push(newHandleData);

    // Increment the current handle value
    config.currentHandle += 1;

    // Force onImageRendered to fire
    external.cornerstone.updateImage(element);
    this.fireModifiedEvent(element, data);
  }

  _getMeasurementNearPoint(element, coords) {
    const polyToolState = getToolState(element, "FreehandRoiExtend");
    const bboxToolState = getToolState(element, "RectangleRoiExtend");
    if (polyToolState?.data?.length) {
      for (let d = 0; d < polyToolState.data.length; d++) {
        const data = polyToolState.data[d];
        const points = data.handles.points.map((p) => [p.x, p.y]);
        if (isPointInPolygon([coords.x, coords.y], points)) {
          return data;
        }
      }
    }

    if (bboxToolState?.data?.length) {
      for (let d = 0; d < bboxToolState.data.length; d++) {
        const data = bboxToolState.data[d];
        const bbox = this._measurementBoundingBox(data);
        if (pointInRectangle(bbox, coords)) return data;
      }
    }

    return null;
  }
  _getMeasurementByIds(element, measurementIds) {
    const polyToolState = getToolState(element, "FreehandRoiExtend");
    const bboxToolState = getToolState(element, "RectangleRoiExtend");
    const activeMeasurements = [];
    if (polyToolState?.data?.length) {
      for (let d = 0; d < polyToolState.data.length; d++) {
        const data = polyToolState.data[d];
        if (measurementIds.includes(data.uuid)) {
          activeMeasurements.push(data);
        }
      }
    }

    if (bboxToolState?.data?.length) {
      for (let d = 0; d < bboxToolState.data.length; d++) {
        const data = bboxToolState.data[d];
        if (measurementIds.includes(data.uuid)) {
          activeMeasurements.push(data);
        }
      }
    }

    return activeMeasurements;
  }

  _measurementBoundingBox(measurement) {
    if (!measurement?.handles) return null;
    if (
      measurement.handles.hasOwnProperty("start") &&
      measurement.handles.hasOwnProperty("end")
    ) {
      const { start, end } = measurement.handles;
      const x = Math.min(start.x, end.x);
      const y = Math.min(start.y, end.y);
      const width = Math.abs(end.x - start.x);
      const height = Math.abs(end.y - start.y);
      return { x, y, width, height };
    }

    if (
      measurement.handles.hasOwnProperty("points") &&
      measurement.handles.points.length > 0
    ) {
      const points = measurement.handles.points.map((point) => {
        return { x: point.x, y: point.y };
      });
      const minX = Math.min(...points.map(({ x }) => x));
      const maxX = Math.max(...points.map(({ x }) => x));
      const minY = Math.min(...points.map(({ y }) => y));
      const maxY = Math.max(...points.map(({ y }) => y));
      return { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
    }
    return null;
  }

  _measurementCenterPoint(measurement) {
    if (!measurement) return null;
    const bbox = this._measurementBoundingBox(measurement);
    if (!bbox) return null;
    return { x: bbox.x + bbox.width / 2, y: bbox.y + bbox.height / 2 };
  }

  /**
   * If in pencilMode, check the mouse position is farther than the minimum
   * distance between points, then add a point.
   *
   * @private
   * @param {Object} eventData - Data object associated with an event.
   * @param {Object} points - Data object associated with the tool.
   * @returns {undefined}
   */
  _addPointPencilMode(eventData, points) {
    const config = this.configuration;
    const { element } = eventData;
    const mousePoint = config.mouseLocation.handles.start;

    const handleFurtherThanMinimumSpacing = (handle) =>
      this._isDistanceLargerThanSpacing(element, handle, mousePoint);

    if (points.every(handleFurtherThanMinimumSpacing)) {
      this._addPoint(eventData);
    }
  }

  /**
   * Ends the active drawing loop and completes the polygon.
   *
   * @private
   * @param {Object} element - The element on which the roi is being drawn.
   * @param {Object} handleNearby - the handle nearest to the mouse cursor.
   * @returns {undefined}
   */
  _endDrawing(element, handleNearby) {
    const toolState = getToolState(element, this.name);
    const config = this.configuration;
    const data = toolState.data[config.currentTool];
    const modifying = this._modifying;
    data.active = false;
    data.highlight = false;

    if (handleNearby !== undefined) {
      const points = data.handles.points;
      points[config.currentHandle - 1].lines = [];
    }

    if (this._modifying) {
      this._modifying = false;
      data.invalidated = true;
    }

    // Reset the current handle
    config.currentHandle = 0;
    config.currentTool = -1;
    config.currentMeasurementId = "";
    data.canComplete = false;

    if (this._drawing) {
      this._deactivateDraw(element);
    }

    if (data.handles.points.length < 2) {
      removeToolState(element, this.name, data);
      external.cornerstone.updateImage(element);
      return;
    }

    external.cornerstone.updateImage(element);

    this.fireModifiedEvent(element, data);
    if (!modifying) this.fireCompletedEvent(element, data);
  }

  /**
   * Returns a handle of a particular tool if it is close to the mouse cursor
   *
   * @private
   * @param {Object} element - The element on which the roi is being drawn.
   * @param {Object} data      Data object associated with the tool.
   * @param {*} coords
   * @returns {Number|Object|Boolean}
   */
  _pointNearHandle(element, data, coords) {
    if (!data) return;
    if (data.handles === undefined || data.handles.points === undefined) {
      return;
    }

    if (data.visible === false) {
      return;
    }

    for (let i = 0; i < data.handles.points.length; i++) {
      const handleCanvas = external.cornerstone.pixelToCanvas(
        element,
        data.handles.points[i]
      );

      if (external.cornerstoneMath.point.distance(handleCanvas, coords) < 25) {
        return i;
      }
    }
  }

  _pointNearToolData(element, data, coords) {
    if (!data) return;
    if (data.handles === undefined || data.handles.points === undefined) {
      return;
    }

    if (data.visible === false) {
      return;
    }

    for (let i = 1; i < data.handles.points.length; i++) {
      const start = data.handles.points[i - 1];
      const end = data.handles.points[i];
      const distance = lineSegDistance(element, start, end, coords);
      if (distance < 3) {
        return i;
      }
    }
    return;
  }

  preMouseDownCallback(evt) {
    const eventData = evt.detail;
    const nearby = this._pointNearHandleAllTools(eventData);

    if (eventData.event.ctrlKey) {
      if (nearby !== undefined && nearby.handleNearby.hasBoundingBox) {
        // Ctrl + clicked textBox, do nothing but still consume event.
      } else {
        insertOrDelete.call(this, evt, nearby);
      }

      preventPropagation(evt);

      return true;
    }

    return false;
  }

  /**
   * Returns a handle if it is close to the mouse cursor (all tools)
   *
   * @private
   * @param {Object} eventData - data object associated with an event.
   * @returns {Object}
   */
  _pointNearHandleAllTools(eventData) {
    const { currentPoints, element } = eventData;
    const coords = currentPoints.canvas;
    const toolState = getToolState(element, this.name);

    if (!toolState) {
      return;
    }

    let handleNearby;

    for (let toolIndex = 0; toolIndex < toolState.data.length; toolIndex++) {
      handleNearby = this._pointNearHandle(
        element,
        toolState.data[toolIndex],
        coords
      );
      if (handleNearby !== undefined) {
        return {
          handleNearby,
          toolIndex,
        };
      }
    }
  }

  /**
   * Gets the current mouse location and stores it in the configuration object.
   *
   * @private
   * @param {Object} eventData The data assoicated with the event.
   * @returns {undefined}
   */
  _getMouseLocation(eventData) {
    const { currentPoints, image } = eventData;
    // Set the mouseLocation handle
    const config = this.configuration;

    config.mouseLocation.handles.start.x = currentPoints.image.x;
    config.mouseLocation.handles.start.y = currentPoints.image.y;
    clipToBox(config.mouseLocation.handles.start, image);
  }

  /**
   * Returns true if two points are farther than this.configuration.spacing.
   *
   * @private
   * @param  {Object} element     The element on which the roi is being drawn.
   * @param  {Object} p1          The first point, in pixel space.
   * @param  {Object} p2          The second point, in pixel space.
   * @returns {boolean}            True if the distance is smaller than the
   *                              allowed canvas spacing.
   */
  _isDistanceLargerThanSpacing(element, p1, p2) {
    return this._compareDistanceToSpacing(element, p1, p2, ">");
  }

  /**
   * Compares the distance between two points to this.configuration.spacing.
   *
   * @private
   * @param  {Object} element     The element on which the roi is being drawn.
   * @param  {Object} p1          The first point, in pixel space.
   * @param  {Object} p2          The second point, in pixel space.
   * @param  {string} comparison  The comparison to make.
   * @param  {number} spacing     The allowed canvas spacing
   * @returns {boolean}           True if the distance is smaller than the
   *                              allowed canvas spacing.
   */
  _compareDistanceToSpacing(
    element,
    p1,
    p2,
    comparison = ">",
    spacing = this.configuration.spacing
  ) {
    if (comparison === ">") {
      return external.cornerstoneMath.point.distance(p1, p2) > spacing;
    }

    return external.cornerstoneMath.point.distance(p1, p2) < spacing;
  }

  /**
   * Adds drawing loop event listeners.
   *
   * @private
   * @param {Object} element - The viewport element to add event listeners to.
   * @param {string} interactionType - The interactionType used for the loop.
   * @modifies {element}
   * @returns {undefined}
   */
  _activateDraw(element, interactionType = "Mouse") {
    this._drawing = true;
    this._drawingInteractionType = interactionType;

    state.isMultiPartToolActive = true;
    // hideToolCursor(this.element);

    // Polygonal Mode
    element.addEventListener(EVENTS.MOUSE_DOWN, this._drawingMouseDownCallback);
    element.addEventListener(EVENTS.MOUSE_MOVE, this._drawingMouseMoveCallback);
    element.addEventListener(
      EVENTS.MOUSE_DOUBLE_CLICK,
      this._drawingMouseDoubleClickCallback
    );

    // Drag/Pencil Mode
    // element.addEventListener(EVENTS.MOUSE_DRAG, this._drawingMouseDragCallback);
    // element.addEventListener(EVENTS.MOUSE_UP, this._drawingMouseUpCallback);

    // Touch
    element.addEventListener(
      EVENTS.TOUCH_START,
      this._drawingMouseMoveCallback
    );
    element.addEventListener(
      EVENTS.TOUCH_START,
      this._drawingTouchStartCallback
    );

    element.addEventListener(EVENTS.TOUCH_DRAG, this._drawingTouchDragCallback);
    element.addEventListener(EVENTS.TOUCH_END, this._drawingMouseUpCallback);
    element.addEventListener(
      EVENTS.DOUBLE_TAP,
      this._drawingDoubleTapClickCallback
    );

    external.cornerstone.updateImage(element);
  }

  /**
   * Removes drawing loop event listeners.
   *
   * @private
   * @param {Object} element - The viewport element to add event listeners to.
   * @modifies {element}
   * @returns {undefined}
   */
  _deactivateDraw(element) {
    this._drawing = false;
    state.isMultiPartToolActive = false;
    this._activeDrawingToolReference = null;
    this._drawingInteractionType = null;
    // setToolCursor(this.element, this.svgCursor);

    element.removeEventListener(
      EVENTS.MOUSE_DOWN,
      this._drawingMouseDownCallback
    );
    element.removeEventListener(
      EVENTS.MOUSE_MOVE,
      this._drawingMouseMoveCallback
    );
    element.removeEventListener(
      EVENTS.MOUSE_DOUBLE_CLICK,
      this._drawingMouseDoubleClickCallback
    );
    element.removeEventListener(
      EVENTS.MOUSE_DRAG,
      this._drawingMouseDragCallback
    );
    element.removeEventListener(EVENTS.MOUSE_UP, this._drawingMouseUpCallback);

    // Touch
    element.removeEventListener(
      EVENTS.TOUCH_START,
      this._drawingTouchStartCallback
    );
    element.removeEventListener(
      EVENTS.TOUCH_DRAG,
      this._drawingTouchDragCallback
    );
    element.removeEventListener(
      EVENTS.TOUCH_START,
      this._drawingMouseMoveCallback
    );
    element.removeEventListener(EVENTS.TOUCH_END, this._drawingMouseUpCallback);

    external.cornerstone.updateImage(element);
  }

  /**
   * Adds modify loop event listeners.
   *
   * @private
   * @param {Object} element - The viewport element to add event listeners to.
   * @modifies {element}
   * @returns {undefined}
   */
  _activateModify(element) {
    state.isToolLocked = true;

    element.addEventListener(EVENTS.MOUSE_UP, this._editMouseUpCallback);
    element.addEventListener(EVENTS.MOUSE_DRAG, this._editMouseDragCallback);
    element.addEventListener(EVENTS.MOUSE_CLICK, this._editMouseUpCallback);

    element.addEventListener(EVENTS.TOUCH_END, this._editMouseUpCallback);
    element.addEventListener(EVENTS.TOUCH_DRAG, this._editTouchDragCallback);

    external.cornerstone.updateImage(element);
  }

  /**
   * Removes modify loop event listeners.
   *
   * @private
   * @param {Object} element - The viewport element to add event listeners to.
   * @modifies {element}
   * @returns {undefined}
   */
  _deactivateModify(element) {
    state.isToolLocked = false;

    element.removeEventListener(EVENTS.MOUSE_UP, this._editMouseUpCallback);
    element.removeEventListener(EVENTS.MOUSE_DRAG, this._editMouseDragCallback);
    element.removeEventListener(EVENTS.MOUSE_CLICK, this._editMouseUpCallback);

    element.removeEventListener(EVENTS.TOUCH_END, this._editMouseUpCallback);
    element.removeEventListener(EVENTS.TOUCH_DRAG, this._editTouchDragCallback);

    external.cornerstone.updateImage(element);
  }

  activeCallback(element) {
    element.addEventListener(EVENTS.MOUSE_MOVE, this._mouseMoveCallback);
  }

  passiveCallback(element) {
    this._closeToolIfDrawing(element);
    // element.removeEventListener(EVENTS.MOUSE_MOVE, this._mouseMoveCallback);
  }

  enabledCallback(element) {
    this._closeToolIfDrawing(element);
  }

  disabledCallback(element) {
    this._closeToolIfDrawing(element);
  }

  _closeToolIfDrawing(element) {
    if (this._drawing) {
      // Actively drawing but changed mode.
      const config = this.configuration;
      const lastHandlePlaced = config.currentHandle;

      this._endDrawing(element, lastHandlePlaced);
      external.cornerstone.updateImage(element);
    }
  }

  /**
   * Fire MEASUREMENT_MODIFIED event on provided element
   * @param {any} element which freehand data has been modified
   * @param {any} measurementData the measurment data
   * @returns {void}
   */
  fireModifiedEvent(element, measurementData) {
    const eventType = EVENTS.MEASUREMENT_MODIFIED;
    const eventData = {
      toolName: this.name,
      toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
      element,
      measurementData,
    };

    triggerEvent(element, eventType, eventData);
  }

  fireCompletedEvent(element, measurementData) {
    const eventType = EVENTS.MEASUREMENT_COMPLETED;
    const eventData = {
      toolName: this.name,
      toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
      element,
      measurementData,
    };

    triggerEvent(element, eventType, eventData);
  }

  // ===================================================================
  // Public Configuration API. .
  // ===================================================================

  get spacing() {
    return this.configuration.spacing;
  }

  set spacing(value) {
    if (typeof value !== "number") {
      throw new Error(
        "Attempting to set freehand spacing to a value other than a number."
      );
    }

    this.configuration.spacing = value;
    external.cornerstone.updateImage(this.element);
  }

  get activeHandleRadius() {
    return this.configuration.activeHandleRadius;
  }

  set activeHandleRadius(value) {
    if (typeof value !== "number") {
      throw new Error(
        "Attempting to set freehand activeHandleRadius to a value other than a number."
      );
    }

    this.configuration.activeHandleRadius = value;
    external.cornerstone.updateImage(this.element);
  }

  get completeHandleRadius() {
    return this.configuration.completeHandleRadius;
  }

  set completeHandleRadius(value) {
    if (typeof value !== "number") {
      throw new Error(
        "Attempting to set freehand completeHandleRadius to a value other than a number."
      );
    }

    this.configuration.completeHandleRadius = value;
    external.cornerstone.updateImage(this.element);
  }

  get alwaysShowHandles() {
    return this.configuration.alwaysShowHandles;
  }

  set alwaysShowHandles(value) {
    if (typeof value !== "boolean") {
      throw new Error(
        "Attempting to set freehand alwaysShowHandles to a value other than a boolean."
      );
    }

    this.configuration.alwaysShowHandles = value;
    external.cornerstone.updateImage(this.element);
  }

  get invalidColor() {
    return this.configuration.invalidColor;
  }

  set invalidColor(value) {
    /*
      It'd be easy to check if the color was e.g. a valid rgba color. However
      it'd be difficult to check if the color was a named CSS color without
      bloating the library, so we don't. If the canvas can't intepret the color
      it'll show up grey.
    */

    this.configuration.invalidColor = value;
    external.cornerstone.updateImage(this.element);
  }

  /**
   * Ends the active drawing loop and removes the polygon.
   *
   * @public
   * @param {Object} element - The element on which the roi is being drawn.
   * @returns {null}
   */
  cancelDrawing(element) {
    if (!this._drawing) {
      return;
    }
    const toolState = getToolState(element, this.name);

    const config = this.configuration;

    const data = toolState.data[config.currentTool];

    data.active = false;
    data.highlight = false;

    // Reset the current handle
    config.currentHandle = 0;
    config.currentTool = -1;
    config.currentMeasurementId = "";
    data.canComplete = false;

    removeToolState(element, this.name, data);

    this._deactivateDraw(element);

    external.cornerstone.updateImage(element);
  }

  /**
   * New image event handler.
   *
   * @public
   * @param  {Object} evt The event.
   * @returns {null}
   */
  newImageCallback(evt) {
    const config = this.configuration;

    if (!(this._drawing && this._activeDrawingToolReference)) {
      return;
    }

    // Actively drawing but scrolled to different image.

    const element = evt.detail.element;
    const data = this._activeDrawingToolReference;

    data.active = false;
    data.highlight = false;

    // Reset the current handle
    config.currentHandle = 0;
    config.currentTool = -1;
    config.currentMeasurementId;
    data.canComplete = false;

    this._deactivateDraw(element);

    external.cornerstone.updateImage(element);
  }
}

function pointInRectangle(rect, point) {
  if (!rect || !point) return false;
  if (
    point.x < rect.x ||
    point.y < rect.y ||
    point.x > rect.x + rect.width ||
    point.y > rect.y + rect.height
  ) {
    return false;
  }
  return true;
}

function defaultFreehandConfiguration() {
  return {
    mouseLocation: {
      handles: {
        start: {
          highlight: true,
          active: true,
        },
      },
    },
    spacing: 1,
    activeHandleRadius: 3,
    completeHandleRadius: 5,
    completeHandleRadiusTouch: 28,
    alwaysShowHandles: false,
    invalidColor: "crimson",
    currentHandle: 0,
    currentTool: -1,
    currentMeasurementId: "",
    drawHandles: true,
    renderDashed: false,
  };
}

function preventPropagation(evt) {
  evt.stopImmediatePropagation();
  evt.stopPropagation();
  evt.preventDefault();
}
