import { useEffect, useState } from "react";
import { StepType } from "services/label-service/dtos";
import { ReviewPickType } from "services/label-service/dtos/work-management.dto";
import { StepData, StepReviewConfig } from "../project.type";

const DEFAULT_REVIEW_STEP_CONFIG: StepReviewConfig = {
  percentage: 80,
  reviewPickType: ReviewPickType.ALL,
  acceptPercentage: 80,
};

export const useStepConfigReview = (step?: StepData) => {
  const [config, setConfig] = useState<StepReviewConfig>(DEFAULT_REVIEW_STEP_CONFIG);


  useEffect(() => {
    if (!step || !step.assignees) return;

    if (step.type === StepType.ACCEPTANCE && step.assignees.length > 0) {
      setConfig({
        acceptPercentage: step.assignees[0].acceptPercentage,
        percentage: step.assignees[0].percentage,
        reviewPickType: step.assignees[0].reviewPickType,
      })
    }
  }, [step]);

  return {
    config,
    onChange: setConfig,
  };
};
