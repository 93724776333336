import { ENV_CONFIG } from "./env.config";


const isUseESRelatedApis = () => {
  return ENV_CONFIG.ENABLED_RELATED_ES_APIS;
}


export const featureConfigs = {
  isUseESRelatedApis,
}
