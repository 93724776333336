import TableCell from "@material-ui/core/TableCell";
import classnames from "classnames";

import { CellProps } from "./types";

interface TableBaseCellProps extends CellProps {}

const TableBaseCell = (props: TableBaseCellProps) => {
  const { children, className, ...tableCellProps } = props;

  return (
    <TableCell
      {...tableCellProps}
      className={classnames(
        "relative",
        className,
      )}
    >
      {children}
    </TableCell>
  );
};

export default TableBaseCell;
