import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RelationAnnotationData } from "domain/image-labeling/relation-annotation";
import { ImageWorkspaceState } from "../../image-workspace.state";

const THUNK_NAME = "image-annotations/imageRelationAnnotationRemovedAsync";

interface ImageAnotationPayload<T> {
  jobId: number;
  data: T;
}

export const imageRelationAnnotationRemovedAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: ImageAnotationPayload<RelationAnnotationData>) => {
    return payload;
  }
);

export const imageRelationAnnotationRemovedBuilder = (
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) => {
  return builder.addCase(
    imageRelationAnnotationRemovedAsync.fulfilled,
    ({ imageAnnotations }, action) => {
      imageAnnotations.relationAnnotations =
        imageAnnotations.relationAnnotations.filter(
          (relationAnno) => relationAnno.uuid !== action.payload.data.uuid
        );
    }
  );
};
