/*
 * File: router.config.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  Icon3dcube,
  Icon3dcubeFill,
  Icon3dCubeScan,
  IconData,
  IconDataFill,
  IconExport,
  IconFolderOpen,
  IconFolderOpenFill,
  IconGrid5,
  IconHome,
  IconHomeFill,
  IconLayer,
  IconLayerFill,
  IconPeople,
  IconPeopleFill,
  IconSave2,
  IconSetting2,
  IconTag,
} from "components/common/vb-icon.component";
import { Routes } from "../routers/config/routes";
import { UserRole } from "../store/auth/auth.state";
import { isDevelopment } from "./env.config";

export enum PageLayout {
  FullPage = "full-page-layout",
  MainLayout = "main-layout",
  LabelerLayout = "labeler-layout",
}

export interface RouteData {
  path: string;
  roles?: string[];
  private?: boolean;
  layout?: PageLayout;
  icon?: JSX.Element;
  activeIcon?: JSX.Element;
  displayName?: string;
  children?: RouteData[];
  disabled?: boolean;
  disabledChildren?: boolean;
}

export const ROUTES_CONFIG: { [key: string]: RouteData } = {
  [Routes.ROOT]: {
    path: Routes.ROOT,
    private: true,
    layout: PageLayout.MainLayout,
    roles: [UserRole.CUSTOMER, UserRole.LABELER],
    displayName: "Dashboard",
    icon: <IconHome className="w-6 h-6" />,
    activeIcon: <IconHomeFill className="w-6 h-6" />,
    disabled: true,
  },
  [Routes.PROJECTS]: {
    path: Routes.PROJECTS,
    private: true,
    layout: PageLayout.MainLayout,
    roles: [UserRole.CUSTOMER],
    displayName: "Projects",
    icon: <IconFolderOpen className="w-6 h-6" />,
    activeIcon: <IconFolderOpenFill className="w-6 h-6" />,
    children: [
      {
        displayName: "Overview",
        path: "/projects/:projectId",
        icon: <IconGrid5 className="w-6 h-6 text-background-300" />,
        activeIcon: <IconGrid5 className="w-6 h-6 text-primary" />,
        children: [
          {
            displayName: "Progress",
            path: "/projects/:projectId/overview/progress",
          },
          {
            displayName: "Performance",
            path: "/projects/:projectId/overview/performance",
          },
        ],
      },
      {
        displayName: "Batches",
        path: "/projects/:projectId/batches",
        icon: <IconSave2 className="w-6 h-6 text-background-300" />,
        activeIcon: <IconSave2 className="w-6 h-6 text-primary" />,
        disabledChildren: true,
        children: [
          {
            displayName: "Overview",
            path: "/projects/:projectId/batches/:batchId",
            icon: <IconGrid5 className="w-6 h-6 text-background-300" />,
            activeIcon: <IconGrid5 className="w-6 h-6 text-primary" />,
          },
          {
            displayName: "Data",
            path: "/projects/:projectId/batches/:batchId/data",
            icon: <IconData className="w-6 h-6 text-background-300" />,
            activeIcon: <IconDataFill className="w-6 h-6 text-primary" />,
          },
          {
            displayName: "Export",
            path: "/projects/:projectId/batches/:batchId/data/export",
            icon: <IconData className="w-6 h-6 text-background-300" />,
            activeIcon: <IconDataFill className="w-6 h-6 text-primary" />,
          },
          // {
          //   displayName: "Data",
          //   path: "/projects/:projectId/batches/:batchId/data",
          //   icon: <IconData className="w-6 h-6 text-background-300" />,
          //   activeIcon: <IconDataFill className="w-6 h-6 text-primary" />,
          //   children: [
          //     {
          //       displayName: "Data Source",
          //       path: "/projects/:projectId/batches/:batchId/data/datasource",
          //     },
          //     {
          //       displayName: "Tasks",
          //       path: "/projects/:projectId/batches/:batchId/data/tasks",
          //     },
          //     {
          //       displayName: "Import/Export",
          //       path: "/projects/:projectId/batches/:batchId/data/export",
          //     }
          //   ]
          // },
          {
            displayName: "Setting",
            path: "/projects/:projectId/batches/:batchId/setting",
            icon: <IconSetting2 className="w-6 h-6 text-background-300" />,
            activeIcon: <IconSetting2 className="w-6 h-6 text-primary" />,
            children: [
              {
                displayName: "General Setting",
                path: "/projects/:projectId/batches/:batchId/setting",
              },
              {
                displayName: "Labels",
                path: "/projects/:projectId/batches/:batchId/setting/labels",
              },
              {
                displayName: "Workflow",
                path: "/projects/:projectId/batches/:batchId/setting/workflow",
              },
              {
                displayName: "Data cleaning",
                path: "/projects/:projectId/batches/:batchId/setting/data-cleaning",
              },
              {
                displayName: "Price/Payment",
                path: "/projects/:projectId/batches/:batchId/setting/price",
                disabled: !isDevelopment(),
              },
            ],
          },
        ],
      },
      {
        displayName: "Labels",
        path: "/projects/:projectId/labels",
        icon: <IconTag className="w-6 h-6 text-background-300" />,
        activeIcon: <IconTag className="w-6 h-6 text-primary" />,
      },
      {
        displayName: "Settings",
        path: "/projects/:projectId/settings",
        icon: <IconSetting2 className="w-6 h-6 text-background-300" />,
        activeIcon: <IconSetting2 className="w-6 h-6 text-primary" />,
        children: [
          {
            displayName: "General",
            path: "/projects/:projectId/settings/general",
          },
          {
            displayName: "Notification",
            path: "/projects/:projectId/settings/notification",
          },
          {
            displayName: "API Endpoints",
            path: "/projects/:projectId/settings/api-endpoints",
            disabled: true,
          },
        ],
      },
    ],
  },
  [Routes.DATASETS]: {
    path: Routes.DATASETS,
    private: true,
    layout: PageLayout.MainLayout,
    roles: [UserRole.CUSTOMER],
    displayName: "Datasources",
    icon: <IconLayer className="w-6 h-6" />,
    activeIcon: <IconLayerFill className="w-6 h-6" />,
  },
  [Routes.MEMBERS]: {
    path: Routes.MEMBERS,
    private: true,
    layout: PageLayout.MainLayout,
    roles: [UserRole.CUSTOMER],
    displayName: "Members",
    icon: <IconPeople className="w-6 h-6" />,
    activeIcon: <IconPeopleFill className="w-6 h-6" />,
  },
  [Routes.MLMODELS]: {
    path: Routes.MLMODELS,
    private: true,
    layout: PageLayout.MainLayout,
    roles: [UserRole.CUSTOMER],
    displayName: "Models",
    icon: <Icon3dcube className="w-6 h-6" />,
    activeIcon: <Icon3dcubeFill className="w-6 h-6" />,
    disabled: false,
    children: [
      {
        path: "/models",
        icon: <Icon3dcube className="w-6 h-6 text-background-300" />,
        activeIcon: <Icon3dcubeFill className="w-6 h-6 text-primary" />,
        displayName: "Models",
      },
      {
        path: "/models/exported-datasets",
        icon: <IconExport className="w-6 h-6 text-background-300" />,
        activeIcon: <IconExport className="w-6 h-6 text-primary" />,
        displayName: "Exported datasets",
      },
      {
        displayName: "Experiments",
        path: "/models/experiments",
        icon: <Icon3dCubeScan className="w-6 h-6 text-background-300" />,
        activeIcon: <Icon3dCubeScan className="w-6 h-6 text-primary" />,
      },
    ],
  },
  [Routes.WEB_BUILDER]: {
    path: Routes.WEB_BUILDER,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.CUSTOMER],
    displayName: "Web Builder",
  },
  [Routes.WEB_BUILDER_PREVIEW]: {
    path: Routes.WEB_BUILDER,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.CUSTOMER],
    displayName: "Web Builder Preview",
  },
  [Routes.WORKFLOWS]: {
    path: Routes.WORKFLOWS,
    private: true,
    layout: PageLayout.MainLayout,
    roles: [UserRole.CUSTOMER],
    displayName: "Workflows",
    icon: <IconData className="w-6 h-6" />,
    activeIcon: <IconDataFill className="w-6 h-6" />,
  },
  [Routes.PROFILE]: {
    path: Routes.PROFILE,
    private: true,
    layout: PageLayout.MainLayout,
    roles: [UserRole.CUSTOMER],
    icon: <IconHome className="w-6 h-6" />,
    activeIcon: <IconHomeFill className="w-6 h-6" />,
  },
  [Routes.MANAGE_WORKSPACE]: {
    path: Routes.MANAGE_WORKSPACE,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.CUSTOMER],
    icon: <i className="text-xl uir-grid-alt" />,
  },
  [Routes.NOTIFICATIONS]: {
    path: Routes.NOTIFICATIONS,
    private: true,
    layout: PageLayout.MainLayout,
    roles: [UserRole.CUSTOMER],
  },
  [Routes.LABELER_HOME]: {
    path: Routes.LABELER_HOME,
    private: true,
    layout: PageLayout.LabelerLayout,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-glasses" />,
  },
  [Routes.LABELER_REPORT]: {
    path: Routes.LABELER_REPORT,
    private: true,
    layout: PageLayout.LabelerLayout,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-glasses" />,
  },
  [Routes.LABELER_FAQ]: {
    path: Routes.LABELER_FAQ,
    private: true,
    layout: PageLayout.LabelerLayout,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-glasses" />,
  },
  [Routes.LABELER_PROFILE]: {
    path: Routes.LABELER_PROFILE,
    private: true,
    layout: PageLayout.LabelerLayout,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-glasses" />,
  },
  [Routes.LABELER_PROJECTS]: {
    path: Routes.LABELER_PROJECTS,
    private: true,
    layout: PageLayout.LabelerLayout,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-glasses" />,
    children: [
      {
        path: Routes.LABELER_PROJECT_HISTORY,
        private: true,
        layout: PageLayout.LabelerLayout,
        roles: [UserRole.LABELER],
        icon: <i className="text-xl uir-glasses" />,
      },
    ],
  },
  [Routes.LABELER_PROJECT_HISTORY]: {
    path: Routes.LABELER_PROJECT_HISTORY,
    private: true,
    layout: PageLayout.LabelerLayout,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-glasses" />,
  },
  [Routes.IMAGE_LABELING]: {
    path: Routes.IMAGE_LABELING,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-flag" />,
  },
  [Routes.IMAGE_LABELING_PROJECT]: {
    path: Routes.IMAGE_LABELING_PROJECT,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-flag" />,
  },
  [Routes.LABELER_REVIEW]: {
    path: Routes.LABELER_REVIEW,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-flag" />,
  },
  [Routes.IMAGE_REVIEW]: {
    path: Routes.IMAGE_REVIEW,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-flag" />,
  },
  [Routes.TEXT_LABELING]: {
    path: Routes.TEXT_LABELING,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-flag" />,
  },
  [Routes.TEXT_REVIEW]: {
    path: Routes.TEXT_REVIEW,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-flag" />,
  },
  [Routes.SPEECH_TO_TEXT_REVIEW]: {
    path: Routes.SPEECH_TO_TEXT_REVIEW,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-flag" />,
  },
  [Routes.TEXT_TO_SPEECH_REVIEW]: {
    path: Routes.TEXT_TO_SPEECH_REVIEW,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-flag" />,
  },
  [Routes.THREE_D_REVIEW]: {
    path: Routes.THREE_D_REVIEW,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-flag" />,
  },
  [Routes.PATHOLOGY_REVIEW]: {
    path: Routes.PATHOLOGY_REVIEW,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-flag" />,
  },
  [Routes.SPEECH_TO_TEXT_LABELING]: {
    path: Routes.SPEECH_TO_TEXT_LABELING,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-flag" />,
  },
  [Routes.TEXT_TO_SPEECH_LABELING]: {
    path: Routes.TEXT_TO_SPEECH_LABELING,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-flag" />,
  },
  [Routes.THREE_D_LABELING]: {
    path: Routes.THREE_D_LABELING,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-flag" />,
  },
  [Routes.PATHOLOGY_LABELING]: {
    path: Routes.PATHOLOGY_LABELING,
    private: true,
    layout: PageLayout.FullPage,
    roles: [UserRole.LABELER],
    icon: <i className="text-xl uir-flag" />,
  },
};

export const getRootRouteForRoles = (role: UserRole | undefined) => {
  if (!role) return null;
  if (role === UserRole.CUSTOMER) return Routes.ROOT;
  if (role === UserRole.LABELER) return Routes.LABELER_HOME;
  return null;
};
