import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import {
  IssueModel,
  IssueStatus,
} from "pages/customer/projects/project-batch/batch-detail/pages/tasks/components/issue/issue-management.models";
import {
  issueCustomerService,
  issueLabelerReviewService,
  issueLabelerService,
} from "services/label-service";
import {
  IssueCommentResponseDTO,
  IssueCustomerResponseDTO,
  IssueResponseDTO,
} from "services/label-service/dtos/issue.dto";
import { fakeApiCall } from "services/mock";
import { RootState } from "store";
import { UserRole } from "store/auth/auth.state";
import {
  mapperIssueComment,
  mapperIssueModel,
} from "./issue-management.mappers";
import { IssueManagementStoreState } from "./issue-management.state";

const SLICE_NAME = "issueManagement";

export const loadIssuesAsync = createAsyncThunk(
  `${SLICE_NAME}/loadIssuesAsync`,
  async (
    {
      taskId,
      jobId,
      isReviewer,
    }: {
      taskId: number | string;
      jobId?: number | string;
      isReviewer?: boolean;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    let resData: any = [];

    if (state.auth.userRole === UserRole.CUSTOMER) {
      resData = (await issueCustomerService.getIssues(taskId)).data;
    } else {
      if (isReviewer) {
        resData = (await issueLabelerReviewService.getIssues(taskId)).data;
      } else if (jobId) {
        resData = (await issueLabelerService.getIssues(jobId)).data;
      }
    }
    return resData;
  }
);

export const updateIssueAsync = createAsyncThunk(
  `${SLICE_NAME}/updateIssueAsync`,
  async (issue: IssueModel, { getState }) => {
    const state = getState() as RootState;
    const payload = mapperIssueModel.toIssueUpdateRequestDTO(issue);

    if (state.auth.userRole === UserRole.CUSTOMER) {
      issueCustomerService.patchIssue(issue.id, payload);
    } else {
      issueLabelerReviewService.patchIssue(issue.id, payload);
    }

    return issue;
  }
);

export const createIssueAsync = createAsyncThunk(
  `${SLICE_NAME}/createIssueAsync`,
  async (issue: Partial<IssueModel>, { getState }) => {
    const state = getState() as RootState;
    const issueDTO = mapperIssueModel.toIssueRequestDTO(issue);
    let res;
    // create comment later BE should create first comment.
    if (state.auth.userRole === UserRole.CUSTOMER) {
      res = await issueCustomerService.createIssue(issueDTO);
      const newIssueDTO = res.data as IssueResponseDTO;
      res = await issueCustomerService.createIssueComment({
        issueId: newIssueDTO.id,
        content:
          issue.comments && issue.comments.length > 0
            ? issue.comments[0].content
            : "",
      });
      const newIssueModel = mapperIssueModel.fromIssueResonseDTO(newIssueDTO);
      const newCommentDTO = res.data as IssueCommentResponseDTO;
      newIssueModel.comments.push(
        mapperIssueComment.fromIssueCommentDTO(newCommentDTO)
      );
      return newIssueModel;
    } else {
      res = await issueLabelerReviewService.createIssue(issueDTO);
      const newIssueDTO = res.data as IssueResponseDTO;
      res = await issueLabelerService.createIssueComment({
        issueId: newIssueDTO.id,
        content:
          issue.comments && issue.comments.length > 0
            ? issue.comments[0].content
            : "",
      });
      const newIssueModel = mapperIssueModel.fromIssueResonseDTO(newIssueDTO);
      const newCommentDTO = res.data as IssueCommentResponseDTO;
      newIssueModel.comments.push(
        mapperIssueComment.fromIssueCommentDTO(newCommentDTO)
      );
      return newIssueModel;
    }
  }
);

export const resolveIssueAndUpdate = createAsyncThunk(
  `${SLICE_NAME}/resolveIssueAndUpdate`,
  async (issue: IssueModel, { getState }) => {
    const state = getState() as RootState;
    if (state.auth.userRole === UserRole.CUSTOMER) {
      issueCustomerService.resolveIssue({ issueId: issue.id });
    }
    return issue;
  }
);

export const openIssueAndUpdate = createAsyncThunk(
  `${SLICE_NAME}/openIssueAndUpdate`,
  async (issue: IssueModel, { getState }) => {
    const state = getState() as RootState;
    if (state.auth.userRole === UserRole.CUSTOMER) {
      issueCustomerService.openIssue({ issueId: issue.id });
    } else {
      issueLabelerService.openIssue({ issueId: issue.id });
    }
    return issue;
  }
);

export const deleteIssueAndUpdate = createAsyncThunk(
  `${SLICE_NAME}/deleteIssueAndUpdate`,
  async (issue: IssueModel) => {
    // TODO: call api later
    await fakeApiCall(0.2);
    return issue;
  }
);

export const issueManagementReducerBuilder = (
  builder: ActionReducerMapBuilder<IssueManagementStoreState>
): ActionReducerMapBuilder<IssueManagementStoreState> => {
  return builder
    .addCase(loadIssuesAsync.fulfilled, (state, action) => {
      const resIssues = action.payload as IssueCustomerResponseDTO[];
      const issues = resIssues.map(mapperIssueModel.fromIssueResonseDTO);
      const currentIssues = state.issues;
      for (const issue of issues) {
        if (currentIssues.find((is) => is.id === issue.id)) continue;
        currentIssues.push(issue);
      }
      state.issues = currentIssues;
    })
    .addCase(updateIssueAsync.fulfilled, (state, action) => {
      const updatedIssue = action.payload;
      state.issues = state.issues.map((issue) => {
        if (issue.id === updatedIssue.id) {
          return updatedIssue;
        }
        return issue;
      });
    })
    .addCase(createIssueAsync.fulfilled, (state, action) => {
      const newIssueModel = action.payload;
      state.issues.push(newIssueModel);
    })
    .addCase(resolveIssueAndUpdate.fulfilled, (state, action) => {
      const updatedIssue = action.payload;
      state.issues = state.issues.map((issue) => {
        if (issue.id === updatedIssue.id) {
          return {
            ...issue,
            status: IssueStatus.RESOLVED,
          };
        }
        return issue;
      });
    })
    .addCase(openIssueAndUpdate.fulfilled, (state, action) => {
      const updatedIssue = action.payload;
      state.issues = state.issues.map((issue) => {
        if (issue.id === updatedIssue.id) {
          return {
            ...issue,
            status: IssueStatus.OPENED,
          };
        }
        return issue;
      });
    })
    .addCase(deleteIssueAndUpdate.fulfilled, (state, action) => {
      const updatedIssue = action.payload;
      state.issues = state.issues.filter(
        (issue) => issue.id !== updatedIssue.id
      );
    });
};
