/*
 * File: image-annotation-sources.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 19th October 2022 5:47:22 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { IconArrowForward } from "components/common/vb-icon.component";
import { useImageAnnotationSourcesContext } from "./context/image-annotation-sources.context";
import ImageAnnotationsList from "./image-annotations-list.component";
import ImageAnnotationsPanel from "./image-annotations-panel.component";
import { ImageAnnotationsViewer } from "./image-annotations-viewer.component";

export default function ImageAnnotationSources() {
  const { labelingDatas, onClose, onSubmit } =
    useImageAnnotationSourcesContext();
  return (
    <div className="relative flex flex-col w-screen h-screen overflow-hidden">
      <div className="flex items-center flex-none gap-4 px-4 text-white shadow h-14 bg-background-900">
        <button onClick={onClose}>
          <IconArrowForward className="flex-none w-6 transform rotate-180" />
        </button>
        <span className="text-lg font-semibold">Select annotations</span>
        <span className="flex-auto"></span>
        {labelingDatas?.length > 0 && (
          <button className="button-text-primary" onClick={onSubmit}>
            Accept
          </button>
        )}
      </div>
      <div className="flex flex-auto w-full">
        <div className="flex-auto h-full overflow-hidden">
          <ImageAnnotationsViewer />
        </div>
        <div className="flex flex-col h-full w-80 bg-background-800">
          <ImageAnnotationsPanel />
          <ImageAnnotationsList />
        </div>
      </div>
    </div>
  );
}
