/*
 * File: project.context.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 20th July 2021 10:34:05 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { useProjectBreadcrumbs } from "hooks/project/use-project-breadcrumbs";
import { createContext, useContext, useState } from "react";

export interface Breadcrumb {
  url: string;
  text: string;
  disabled?: boolean;
}
interface ProjectContextState {
  loading: boolean;
  setLoading(loading: boolean): void;
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs(breadcrumbs: Breadcrumb[]): void;
}

export const ProjectContext = createContext({} as ProjectContextState);

export const useProjectContext = () => {
  return useContext(ProjectContext);
};

interface ProjectProviderProps {
  children: React.ReactNode | React.ReactNode[] | null;
}

export const ProjectProvider = ({ children }: ProjectProviderProps) => {
  const [loading, setLoading] = useState(true);
  const { breadcrumbs, setBreadcrumbs } = useProjectBreadcrumbs();
  const value: ProjectContextState = {
    loading,
    breadcrumbs,
    setBreadcrumbs,
    setLoading,
  };
  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};
