import { useAppSelector } from "hooks/use-redux";
import { useWorkspaceUsers } from "hooks/workspace/use-workspace-users.hook";
import { StepData } from "pages/customer/projects/project.type";
import { useCallback, useMemo, useState } from "react";
import { StepType, WorkflowInstructionDTO } from "services/label-service/dtos";
import { UserBatchModel } from "services/user-service/dtos/user.dto";
import { selectProjectWorkManagements } from "store/customer/project/project.selectors";
import { AtLeast } from "types/common";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useBatchDetailContext } from "../project-batch/batch-detail/context/batch-detail.context";

export const useBatchWorkflow = () => {
  const { labelers } = useWorkspaceUsers();
  const projectWorkManagements = useAppSelector(selectProjectWorkManagements);

  const { batchInstructions, stepLabelers: batchLabelers } =
    useBatchDetailContext();

  const [selectedStepIndex, setSelectedStepIndex] = useState(1);
  const [steps, setSteps] = useState<StepData[]>([]);

  useDeepCompareEffect(() => {
    if (!batchInstructions || !batchInstructions.length || !batchLabelers)
      return;

    const steps = batchInstructions.map(
      (instruction) =>
        ({
          ...mapInstructionToStep(instruction),
          ...mapBatchLabelersToStep(batchLabelers[instruction.step]),
        } as StepData)
    );

    setSteps(steps);
  }, [batchInstructions, batchLabelers]);

  const selectedStep = useMemo(() => {
    return steps.find((item) => item.position === selectedStepIndex);
  }, [selectedStepIndex, steps]);

  const assignedLabelers = useMemo(() => {
    if (!projectWorkManagements || !projectWorkManagements.length)
      return labelers.map((labeler) => labeler.email);
    return Array.from(
      new Set<string>(projectWorkManagements.map((labeler) => labeler.userId))
    );
  }, [labelers, projectWorkManagements]);

  const handleChangeStep = useCallback(
    (item: Partial<StepData>) => {
      if (!selectedStep) return;
      const newStep = { ...selectedStep, ...item };
      const newSteps = steps.map((step) =>
        step.position === selectedStep.position ? newStep : step
      );

      setSteps && setSteps(newSteps);
    },
    [selectedStep, steps]
  );

  return {
    assignedLabelers,
    selectedStep,
    selectedStepIndex,
    setSelectedStepIndex,
    steps,
    onChangeStep: handleChangeStep,
    onChangeSteps: setSteps,
  };
};

const mapInstructionToStep = (
  instruction: WorkflowInstructionDTO
): Partial<StepData> => ({
  id: instruction.id,
  condition: instruction.condition,
  description: instruction.description,
  name: instruction.name,
  numberOfRounds: instruction.roundNumber,
  position: instruction.step,
  type: instruction.stepType || StepType.NORMAL,
  canViewPreviousStepResult: instruction.canViewPreviousStepResult,
  readonly: instruction?.readonly || false,
});

const mapBatchLabelersToStep = (
  batchLabelers: AtLeast<UserBatchModel, "email">[]
): Partial<StepData> => {
  if (!batchLabelers || !batchLabelers.length) return { assignees: [] };
  const assignees = batchLabelers.map((assignee) => ({
    ...assignee,
    userId: assignee.email,
  }));

  return {
    assignees,
  };
};
