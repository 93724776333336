import { IconArrowDown } from "components/common/vb-icon.component";
import { useMemo, useState } from "react";
import { classnames } from "utilities/classes";
import { DEFAULT_PATHOLOGY_ANNOTATION_FILTER, PathologyAnnotationFilter } from "../pathology-editor.models";
import { usePathologyEditorContext } from "../pathology-editor.provider"


interface Props {
  onClose?: () => void;
}
export const PathologyEditorAnnotationFilterComponent = ({
  onClose,
}: Props) => {
  const {
    labels,
    annotationFilter,
    setAnnotationFilter,
    currentLabeler,
    otherLabelers,
    syncAnnotationsFromFabricObjects,
  } = usePathologyEditorContext();

  const labelersOptions = useMemo(() => {
    let options = otherLabelers.map(l => ({
      label: l.id,
      value: l.id,
      selected: annotationFilter.labelers.includes(l.id),
    }));

    if (currentLabeler) {
      options.unshift({
        label: "@me",
        value: currentLabeler.id,
        selected: annotationFilter.labelers.includes(currentLabeler.id),
      })
    }

    return options;
  }, [currentLabeler, otherLabelers, annotationFilter]);

  const labelOptions = useMemo(() => {
    return labels.map(label => {
      return {
        label: label.name,
        value: label.id,
        selected: annotationFilter.labelIds.includes(label.id),
      }
    });
  }, [labels, annotationFilter]);

  const handleFilterFieldOptionsChanged = (
    field: keyof PathologyAnnotationFilter,
    newOptions: PathologyEditorAnnotationFilterOptionModel[]
  ) => {
    const values = newOptions.filter(o => o.selected).map(o => o.value);
    const newFilter = {
      ...annotationFilter,
      [field]: values,
    };
    setAnnotationFilter(newFilter);
    syncAnnotationsFromFabricObjects({filter: newFilter});
  }

  const handleClickClear = () => {
    setAnnotationFilter(DEFAULT_PATHOLOGY_ANNOTATION_FILTER);
    syncAnnotationsFromFabricObjects({filter: DEFAULT_PATHOLOGY_ANNOTATION_FILTER});
    onClose && onClose();
  }

  return (
    <div 
      className="absolute bottom-0 right-0 rounded transform translate-y-full p-2 flex flex-col gap-2 bg-white text-black"
      style={{
        width: "250px",
      }}
    >
      <PathologyEditorAnnotationFilterOptionComponent
        title={"Labelers"}
        options={labelersOptions}
        onChanged={newOptions => handleFilterFieldOptionsChanged("labelers", newOptions)}
      />
      <PathologyEditorAnnotationFilterOptionComponent
        title={"Labels"}
        options={labelOptions}
        onChanged={newOptions => handleFilterFieldOptionsChanged("labelIds", newOptions)}
      />
      
      <span
        className="cursor-pointer self-end"
        onClick={handleClickClear}
      >
        Clear
      </span>
    </div>
  )
}


interface PathologyEditorAnnotationFilterOptionModel {
  label: string;
  value: any;
  selected: boolean;
}
interface PathologyEditorAnnotationFilterOptionProps {
  title: string;
  options: PathologyEditorAnnotationFilterOptionModel[],
  onChanged?: (v: PathologyEditorAnnotationFilterOptionModel[]) => void;
}
export const PathologyEditorAnnotationFilterOptionComponent = ({
  title,
  options,
  onChanged,
}: PathologyEditorAnnotationFilterOptionProps) => {

  const [showOptions, setShowOptions] = useState(true);

  const handleOptionChanged = (
    option: PathologyEditorAnnotationFilterOptionModel,
    newSelected: boolean,
  ) => {
    const newOptions = options.map(op => {
      if (op.value === option.value) {
        return {
          ...op,
          selected: newSelected,
        }
      }
      return op;
    });

    onChanged && onChanged(newOptions);
  }

  return (
    <div className="flex flex-col gap-2">
      <div 
        className="flex items-center gap-1 cursor-pointer font-bold"
        onClick={_ => setShowOptions(!showOptions)}
      >
        <span>{title}</span>
        <IconArrowDown 
          className={classnames(
            "w-4 h-4",
            {"transform -rotate-90": !showOptions}
          )}
        />
      </div>
      {
        showOptions &&
        <div 
          className="w-full max-h-40 overflow-y-auto flex flex-col gap-2"
        >
          {
            options.map(option => {
              return (
                <div 
                  key={option.value}
                  className="flex items-center gap-2"
                >
                  <input
                    type="checkbox"
                    checked={option.selected}
                    onChange={e => handleOptionChanged(option, e.target.checked)}
                  />
                  <span>{option.label}</span>
                </div>
              )
            })
          }
        </div>
      }
    </div>
  )
}
