/*
 * File: text-labeling-sidebar.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 15th October 2021 11:18:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  selectActiveLabelIds,
  selectHasSelectedSystemLabel,
  selectSelectedLabelId,
  selectSelectedObservation,
  selectSelectedSystemObservationId,
  selectShowLabelInstruction,
} from "store/labeler/text-workspace/text-editor/text-editor.selector";
import {
  selectCanViewPreviousTextLabels,
  selectTextIsReadonly,
  selectTextIsStepReviewJob,
  selectTextLabels,
} from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { selectIsReviewMode } from "store/labeler/text-workspace/text-review/text-review.selectors";
import {
  setSelectedLabelId,
  setSelectedSystemObservationId,
  setShowLabelInstruction,
} from "store/labeler/text-workspace/text-workspace.slice";
import { SystemLabelList } from "./components/system-label-list.component";
import { TextLabelerList } from "./components/text-labeler-list.component";
import { TextLabelList } from "./components/label-list/text-label-list.component";
import { TextReview } from "./text-review.component";
import { STEP_REVIEW_OBSERVATION_CODES } from "services/label-service/dtos";
import { TextLabelingJobs } from "./components/text-labeling-jobs.component";
import { selectIsShowTextIssues } from "store/labeler/text-workspace/text-issues/text-issues.selector";
import { TextIssuesComponent } from "../text-issues/text-issues.component";
import { LabelInformation } from "pages/labeler/components/label-info/label-info.component";
import { useTextWorkspaceContext } from "../../context/text-workspace/text-workspace.context";

export const TextLabelingSidebar = () => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const { isLoading, isLoadingJob } = useTextWorkspaceContext();
  const isStepReviewJob = useAppSelector(selectTextIsStepReviewJob);
  const isReadOnly = useAppSelector(selectTextIsReadonly);
  const selectedSystemObservationId = useAppSelector(
    selectSelectedSystemObservationId
  );
  const selectedSystemLabel = useAppSelector(selectHasSelectedSystemLabel);
  const showLabelInstruction = useAppSelector(selectShowLabelInstruction);
  const reviewMode = useAppSelector(selectIsReviewMode);
  const canViewPrevious = useAppSelector(selectCanViewPreviousTextLabels);
  const showTextIssues = useAppSelector(selectIsShowTextIssues);
  const selectedObservation = useAppSelector(selectSelectedObservation);
  const handleSelectSystemLabel = (labelId: string) => {
    const observationId = parseInt(labelId);
    if (selectedSystemObservationId === observationId) {
      dispatch(setSelectedSystemObservationId(-1));
    } else {
      dispatch(setSelectedSystemObservationId(observationId));
    }
  };
  const selectedLabelId = useAppSelector(selectSelectedLabelId);
  const activeLabelIds = useAppSelector(selectActiveLabelIds);
  const labels = useAppSelector(selectTextLabels);
  const systemLabels = useMemo(() => {
    return labels.filter((lb) => {
      if (!lb.isSystemLabel) return false;
      if (isStepReviewJob) {
        return !!STEP_REVIEW_OBSERVATION_CODES.includes(lb.observation.code);
      } else {
        return !!!STEP_REVIEW_OBSERVATION_CODES.includes(lb.observation.code);
      }
    });
  }, [labels, isStepReviewJob]);
  const [search, setSearch] = useState("");
  const filteredLabels = useMemo(() => {
    return labels
      .filter((lb) => {
        if (lb.isSystemLabel) return false;
        if (!search) return true;
        return lb.name.toLowerCase().includes(search);
      })
      .sort((a, b) => {
        const aValue = a.disableSelection ? 1 : 0;
        const bValue = b.disableSelection ? 1 : 0;
        return aValue - bValue;
      });
  }, [labels, search]);
  const inputRef = useRef<HTMLInputElement>(null);

  function handleInputChange() {
    setSearch(inputRef.current?.value.trim().toLowerCase() ?? "");
  }

  const handleSelect = (labelId: string) => {
    const isCurrentLabel = labelId === selectedLabelId;
    dispatch(setSelectedLabelId(isCurrentLabel ? "" : labelId));
  };

  const onCloseLabelInformation = () => {
    dispatch(setShowLabelInstruction(false));
  };

  useEffect(() => {
    if (!showLabelInstruction && containerRef.current) {
      containerRef.current.style.width = "auto";
    }
  }, [containerRef, showLabelInstruction]);

  if (isLoading || isLoadingJob) return <TextLabelingSidebarSkeleton />;

  return (
    <div className="relative flex flex-shrink-0 h-full" ref={containerRef}>
      <div className="relative flex-1 h-full py-4 overflow-y-auto bg-white">
        {!isReadOnly && (
          <>
            <div className="px-4 py-2 text-sm font-bold uppercase">Actions</div>
            <SystemLabelList
              isReadOnly={isReadOnly}
              labels={systemLabels}
              selectedLabelId={selectedSystemObservationId.toString()}
              onSelect={handleSelectSystemLabel}
            />
            <div className="mt-4 border-b" />
          </>
        )}

        {isReadOnly && (
          <>
            <TextLabelingJobs />
            <div className="my-4 border-b" />
            <SystemLabelList
              isReadOnly={isReadOnly}
              labels={systemLabels}
              selectedLabelId={selectedSystemObservationId.toString()}
            />
          </>
        )}

        <div
          className={
            selectedSystemLabel ? "filter grayscale pointer-events-none" : ""
          }
        >
          {!isReadOnly && canViewPrevious && <TextLabelerList />}
          {!isStepReviewJob && (
            <>
              <div className="px-4 py-2 mt-4 text-sm font-bold uppercase">
                Findings
              </div>
              <div className="px-4">
                <input
                  defaultValue={search}
                  ref={inputRef}
                  disabled={selectedSystemLabel}
                  type="text"
                  className="w-full px-4 py-2 border rounded"
                  placeholder="Search observation"
                  onChange={handleInputChange}
                />
              </div>
              <div className="h-4" />
              <TextLabelList
                onSelect={handleSelect}
                selectedLabelId={selectedLabelId}
                activeLabelIds={activeLabelIds}
                labels={filteredLabels}
              />
            </>
          )}
        </div>

        {reviewMode && (
          <div className="absolute top-0 bottom-0 left-0 right-0 z-10 w-full h-full bg-white">
            <div className="relative w-full h-full overflow-hidden">
              <TextReview />
            </div>
          </div>
        )}
      </div>

      {showLabelInstruction && (
        <LabelInformation
          label={selectedObservation}
          onClose={onCloseLabelInformation}
        />
      )}

      {showTextIssues && <TextIssuesComponent />}
    </div>
  );
};

function TextLabelingSidebarSkeleton() {
  return (
    <div className="relative flex flex-shrink-0 h-full w-60">
      <div className="relative flex-1 h-full py-4 overflow-y-auto bg-white">
        <div className="px-4 py-4 space-y-6">
          <div className="w-3/6 h-4 bg-background-300 animate-pulse"></div>
          <div className="flex items-center gap-4">
            <div className="w-12 h-3 bg-background-300 animate-pulse"></div>
            <div className="w-12 h-3 bg-background-300 animate-pulse"></div>
            <div className="w-12 h-3 bg-background-300 animate-pulse"></div>
          </div>
          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <div className="w-5 h-5 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
            <div className="px-4 space-y-4">
              <div className="flex items-center gap-3">
                <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
                <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
              </div>
              <div className="flex items-center gap-3">
                <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
                <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
              </div>
              <div className="flex items-center gap-3">
                <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
                <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
              </div>
              <div className="flex items-center gap-3">
                <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
                <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="p-4 space-y-4">
          <div className="flex items-center gap-3">
            <div className="w-5 h-5 rounded bg-background-300 animate-pulse"></div>
            <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
          </div>
          <div className="px-4 space-y-4">
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
          </div>
        </div>
        <hr />
        <div className="p-4 space-y-4">
          <div className="flex items-center gap-3">
            <div className="w-5 h-5 rounded bg-background-300 animate-pulse"></div>
            <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
          </div>
          <div className="px-4 space-y-4">
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded bg-background-300 animate-pulse"></div>
              <div className="w-4/6 h-3 bg-background-300 animate-pulse"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
