import { useMemo, useState, useEffect } from "react";
import { SpeechToTextAudioPlayer } from "./components/speech-to-text-audio-player.component";
import { SpeechToTextText } from "./components/speech-to-text-text.component";
import { useSpeechToTextLabelingContext } from "./context/speech-to-text-labeling.context";
import { SpeechToTextLabels } from "./components/speech-to-text-labels.component";
import { SpeechToTextHeaderBar } from "./components/speech-to-text-header-bar.component";
import { WorkspaceEmpty } from "../image-labeling/components/workspace-empty/workspace-empty.component";
import { ErrorDialog } from "../batch-labeling/components/error-dialog.component";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { ImageLabelingErrorDialog } from "../image-labeling/components/error-dialog/error-dialog.component";
import { classnames } from "utilities/classes";
import { SpeechToTextListAnnotations } from "./components/speech-to-text-list-annotations.component";
import { ReopenDialog } from "../image-review/components/reopen-dialog.component";
import { IssueManagementProvider } from "pages/customer/projects/project-batch/batch-detail/pages/tasks/components/issue/issue-management.provider";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { deleteComplexJobsJobInBatch, setComplexJobsJobData, setComplexJobWorkingStatus } from "store/labeler/complex-jobs/complex-jobs.slice";
import { WorkingStatus } from "store/labeler/complex-jobs/complex-jobs.state";
import { selectComplexJobIdsByStatus, selectComplexJobsAutoSaveInSecond } from "store/labeler/complex-jobs/complex-jobs.selectors";
import { useAutoSave } from "hooks/use-auto-save.hook";
import { selectIsAutoSaveConfig } from "store/labeler/complex-jobs/complex-jobs-editor-setting/complex-jobs-editor-setting.selector";

interface Props {
  showMenuIssue?: boolean;
  fixedMenuIssue?: boolean;
  showIssues?: boolean;
}
export const SpeechToTextLabelingDetails = ({
  showMenuIssue = true,
  fixedMenuIssue = false,
  showIssues = true,
}: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [showReopenReason, setShowReopenReason] = useState(true);

  const workingJobIds = useAppSelector(selectComplexJobIdsByStatus(WorkingStatus.WORKING))
  const isAutoSaveConfig = useAppSelector(selectIsAutoSaveConfig)
  const autoSaveInSecond = useAppSelector(selectComplexJobsAutoSaveInSecond);

  const {
    error,
    setError,
    uiJob,
    setUIJob,
    isLoadingJob,
    isSavingJob,
    saveJob,
    skipJob,
    handleConfirmExit,
    useAITranscribe,
    setUseAITranscribe,
    isTranscribing,
    hasChanged,
    setHasChanged,
    setIsAudioReady,
    overwriteUIJob,
  } = useSpeechToTextLabelingContext();

  const autoSave = useAutoSave({
    enabled: isAutoSaveConfig && workingJobIds.length > 0,
    onSave: async () => {
      const promises = workingJobIds.map(id => saveJob(true, false, false, false, id));
      await Promise.all(promises)
    },
    duration: autoSaveInSecond && autoSaveInSecond * 1000
  })

  const hasLabels = useMemo(() => {
    return (uiJob && uiJob.labels && uiJob.labels.length > 0) as boolean
  }, [uiJob]);
  

  useEffect(() => {
    if (uiJob && uiJob.job && uiJob.isFromBatch) {
      dispatch(setComplexJobsJobData({
        id: uiJob.job.id,
        data: uiJob,
      }))
    }
  }, [uiJob, dispatch]);

  const handleErrorDialogClose = () => {
    history.push(Routes.LABELER_HOME);
  }

  const handleSkip = async () => {
    if (!error || !error.data) return;
    await skipJob(error.data as number);
    setError(undefined);
    if (uiJob && uiJob.job && uiJob.isFromBatch) {
      dispatch(deleteComplexJobsJobInBatch(uiJob.job.id));
    }
  }

  if (error){
    const errorType = error.type;
    if (errorType === "no_job") return <WorkspaceEmpty />
    if (errorType === "unknow") return (
      <ErrorDialog 
        visible
        onClose={handleErrorDialogClose}
        message={error.message}
      />
    )
    if (errorType === "skippable") return (
      <ImageLabelingErrorDialog
        visible
        error={error.message}
        onClose={handleErrorDialogClose}
        onSubmit={handleSkip}
      />
    )
  }

  if (!uiJob) return null;

  const handleSave = async () => {
    await saveJob(true);
  }

  const handleComplete = async (
    accept = false,
    reject = false,
  ) => {
    await saveJob(true, true, accept, reject);
  }

  const handleClickBack = () => {
    handleConfirmExit();
  }

  const setUIJobField = (field: string, value: any) => {
    const newValue = {...uiJob, [field]: value};
    setUIJob(newValue);
    setHasChanged(true);
    dispatch(setComplexJobWorkingStatus(WorkingStatus.WORKING))
  }

  const handleAudioReady = () => {
    setIsAudioReady(true);
  }

  return (
    <div className="relative bg-background-900">
      {
        (uiJob && uiJob.batch && uiJob.task) &&
        <SpeechToTextHeaderBar
          isReviewStepJob={uiJob.isStepReviewJob}
          batch={uiJob.batch}
          task={uiJob.task}
          project={uiJob.project}
          disabledComplete={isLoadingJob || isSavingJob}
          disabledSave={isLoadingJob || isSavingJob || !hasChanged}
          countDownSecond={uiJob.countDownSecond}
          isReady={!isLoadingJob}
          onClickBack={handleClickBack}
          onClickSave={handleSave}
          onClickComplete={handleComplete}
          showButtons={!uiJob.isTaskReview} 
          autoSave={autoSave}
        />
      }
      <div className="grid grid-cols-12 w-full sticky top-0" style={{zIndex: "10"}}>
        <div className="col-span-12">
          <SpeechToTextAudioPlayer
            url={uiJob.audioUrl}
            onAudioReady={handleAudioReady}
          />
        </div>
      </div>

      <div className="grid grid-cols-12 w-full">
        {
          hasLabels &&
          <div className="col-span-3">
            <SpeechToTextLabels
              disabled={uiJob.isStepReviewJob || uiJob.isTaskReview}
              labels={uiJob.labels}
              onChanged={(newLabels) => setUIJobField("labels", newLabels)}
            />
          </div>
        }
        <div className={classnames(
          {"col-span-9": hasLabels},
          {"col-span-10 col-start-2": !hasLabels}
        )}>
          <SpeechToTextText
            text={uiJob.text}
            note={uiJob.note}
            useAITranscribe={useAITranscribe}
            disabled={isTranscribing || uiJob.isStepReviewJob || uiJob.isTaskReview}
            onUseAITranscribeChanged={v => setUseAITranscribe(v)}
            onTextChanged={v => setUIJobField("text", v)}
            onNoteChanged={v => setUIJobField("note", v)}
          />
          {
            uiJob.canViewPreviousStepResult &&
            <SpeechToTextListAnnotations
              annotations={uiJob.previousJobsAnnotations}
              onSelected={overwriteUIJob}
              acceptBatchObservation={uiJob.acceptBatchObservation}
              rejectBatchObservation={uiJob.rejectBatchObservation}
            />
          }
        </div>
      </div>

      {
        uiJob.reopenReason && showReopenReason &&
        <ReopenDialog
          open={showReopenReason}
          message={uiJob.reopenReason}
          onClose={() => setShowReopenReason(false)}
          onSubmit={() => setShowReopenReason(false)}
        />
      }
      {
        uiJob.task && showIssues &&
        <IssueManagementProvider
          menuPosition="bottomLeft"
          taskId={uiJob.task.id}
          jobId={uiJob.job?.id}
          isReviewer={!!uiJob.job && uiJob.isStepReviewJob}
          jobOptions={uiJob.jobOptions}
          fixedMenu={fixedMenuIssue}
          showMenu={showMenuIssue}
        />
      }
    </div>
  )  
}