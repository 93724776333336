/*
 * File: sync-cloud-form.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {Select, MenuItem, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {AxiosResponse} from "axios";
import {
  DEFAULT_CLOUD_STORAGE_PROVIDER,
  CLOUD_PROVIDERS,
} from "configs/cloud-storage.config";
import {useDatasetTag} from "hooks/datasets/use-dataset-tag.hook";
import {useAppDispatch} from "hooks/use-redux";
import {useState, useRef, ChangeEvent, FormEvent} from "react";
import {useTranslation} from "react-i18next";
import {HTTPStatusCode, isHTTPStatusOK} from "services/common/http-status";
import {DatasetDTO} from "services/label-service/dtos";
import {StorageService} from "services/storage";
import {importCloudAsync} from "store/customer/dataset/dataset.slice";
import {
  enqueueSuccessNotification,
  enqueueErrorNotification,
} from "store/common/notification/notification.actions";
import * as Sentry from "@sentry/react";

enum FormAction {
  DEFAULT = "",
  IMPORT_DATA = "IMPORT_DATA",
  TEST_CONNECTION = "TEST_CONNECTION",
}

export enum CloudType {
  AZURE_BLOB_STORAGE = "azure_blob_storage",
  AWS_S3 = "aws_s3",
}

interface SyncCloudProps {
  dataset: DatasetDTO;

  onClose?(): void;
}

const useInputRefs = () => {
  return {
    azureSasUrlRef: useRef<HTMLTextAreaElement | null>(null),
    awsAccessKeyRef: useRef<HTMLTextAreaElement | null>(null),
    awsSecretKeyRef: useRef<HTMLTextAreaElement | null>(null),
    awsBucketRef: useRef<HTMLTextAreaElement | null>(null),
    awsPrefixRef: useRef<HTMLTextAreaElement | null>(null),
  };
};

const useConnectionTesting = (cloudType: string, refs: ReturnType<typeof useInputRefs>) => {
  const dispatch = useAppDispatch();
  const [testingConnection, setTestingConnection] = useState(false);

  const handleTestConnectionError = (response: AxiosResponse<{ title: string }>) => {
    if (response.status === HTTPStatusCode.BadRequest
        || response.status === HTTPStatusCode.InternalServerError) {
      dispatch(enqueueErrorNotification("Invalid cloud storage credential"));
    }
  };

  const testConnection = async (serviceCall: () => Promise<AxiosResponse<any>>) => {
    if (testingConnection) return;
    setTestingConnection(true);
    try {
      const response = await serviceCall();
      if (isHTTPStatusOK(response.status)) {
        dispatch(enqueueSuccessNotification("Valid cloud storage credential"));
      }
    } catch (err: any) {
      if (err.response) {
        handleTestConnectionError(err.response);
      } else if (err.message) {
        Sentry.captureException(err);
        dispatch(enqueueErrorNotification(err.message));
      }
    }
    setTestingConnection(false);
  };

  const handleAwsTestConnection = () => {
    const {
      awsAccessKeyRef,
      awsSecretKeyRef,
      awsBucketRef,
      awsPrefixRef } = refs;
    if (awsAccessKeyRef.current && awsSecretKeyRef.current && awsBucketRef.current && awsPrefixRef.current) {
      testConnection(() =>
        StorageService.testAwsCloudConnection(
          cloudType,
          awsAccessKeyRef.current?.value?? '',
          awsSecretKeyRef.current?.value?? '',
          awsBucketRef.current?.value?? '',
          awsPrefixRef.current?.value?? ''
        )
      );
    }
  };

  const handleAzureTestConnection = () => {
    const { azureSasUrlRef } = refs;
    if (azureSasUrlRef.current) {
      testConnection(() => StorageService.testAzureCloudConnection(cloudType, azureSasUrlRef.current?.value ?? ''));
    }
  };

  return {
    testingConnection,
    handleAwsTestConnection,
    handleAzureTestConnection,
  };
};


export const SyncCloudForm = ({dataset, onClose}: SyncCloudProps) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const [cloudType, setCloudType] = useState(DEFAULT_CLOUD_STORAGE_PROVIDER);
  const formAction = useRef<FormAction>(FormAction.DEFAULT);
  const refs = useInputRefs();
  const { testingConnection, handleAwsTestConnection, handleAzureTestConnection } = useConnectionTesting(cloudType, refs);
  const {tags} = useDatasetTag();
  const [tagTextValue, setTagTextValue] = useState<string>("");

  const storeTypeOptions = [
    {label: "Copy", value: "copy"},
    {label: "Refer", value: "refer"},
  ];
  const [storeType, setStoreType] = useState("copy");


  const onTagInput = (e: any) => {
    setTagTextValue(e.target.value);
  };

  const onTagSelectChange = (_: any, data: any) => {
    setTagTextValue(data?.name);
  };

  function handleCloudTypeChanged(event: ChangeEvent<{ value: unknown }>) {
    setCloudType(event.target.value as string);
  }

  function setFormAction(action: FormAction) {
    formAction.current = action;
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (formAction.current === FormAction.TEST_CONNECTION) {
      if (cloudType === CloudType.AWS_S3.toLowerCase()) {
        return handleAwsTestConnection();
      } else if (cloudType === CloudType.AZURE_BLOB_STORAGE.toLowerCase()) {
        return handleAzureTestConnection();
      }

    } else if (formAction.current === FormAction.IMPORT_DATA) {
      return handleImportData();
    }
    setFormAction(FormAction.DEFAULT);
  }


  async function handleImportData() {
    if (cloudType === CloudType.AWS_S3) {
      const awsAccessKeyRef = refs.awsAccessKeyRef.current;
      const awsSecretKeyRef = refs.awsSecretKeyRef.current;
      const awsBucketRef = refs.awsBucketRef.current;
      const awsPrefixRef = refs.awsPrefixRef.current;

      if (!awsAccessKeyRef || !awsSecretKeyRef || !awsBucketRef || !awsPrefixRef || !dataset) return;

      try {
        const awsAccessKey = awsAccessKeyRef.value;
        const awsSecretKey = awsSecretKeyRef.value;
        const awsBucket = awsBucketRef.value;
        const awsPrefix = awsPrefixRef.value;
        const cloudTags = getTags();
        const params = {
          cloudType,
          accessKey: awsAccessKey,
          secretKey: awsSecretKey,
          bucket: awsBucket,
          prefix: awsPrefix,
          datasetId: dataset.id,
          tags: cloudTags,
          storeType,
        };

        await dispatch(importCloudAsync(params));
        dispatch(enqueueSuccessNotification("Sync cloud started!"));
        onClose && onClose();
      } catch (err: any) {
        handleError(err);
      }
    } else if (cloudType === CloudType.AZURE_BLOB_STORAGE) {
      const azureSasUrlRef = refs.azureSasUrlRef.current;

      if (!azureSasUrlRef || !dataset) return;

      try {
        const sasUrl = azureSasUrlRef.value;
        const cloudTags = getTags();
        const params = {
          cloudType,
          sasUrl,
          datasetId: dataset.id,
          tags: cloudTags,
          storeType,
        };

        await dispatch(importCloudAsync(params));
        dispatch(enqueueSuccessNotification("Sync cloud started!"));
        onClose && onClose();
      } catch (err: any) {
        handleError(err);
      }
    }
  }

  function handleError(err: any) {
    if (err.response) {
      handleTestConnectionError(err.response);
    } else if (err.message) {
      Sentry.captureException(err);
      dispatch(enqueueErrorNotification(err.message));
    }
  }


  function getTags() {
    return tagTextValue
      .trim()
      .split(",")
      .map((text) => text.trim())
      .filter((text) => text.length > 0)
      .map((text) => {
        const tag = tags.find(
          (item) => item.name.toLowerCase() === text.toLowerCase()
        );
        if (tag) {
          return {
            name: tag.name,
            id: tag.id,
          };
        }
        return {
          name: text,
          id: -1,
        };
      });
  }

  function handleTestConnectionError(
    response: AxiosResponse<{
      entityName: string;
      errorKey: string;
      message: string;
      params: string;
      status: number;
      title: string;
      type: string;
    }>
  ) {
    if (response.status === HTTPStatusCode.BadRequest) {
      dispatch(enqueueErrorNotification(response.data.title));
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="px-4 py-2">
        <label className="block mb-1 text-sm text-gray-400" htmlFor="cloudType">
          {t("dataset:details.labelCoudType")}
        </label>
        <Select
          variant="outlined"
          classes={{root: "text-primary"}}
          name="cloudType"
          id="cloudType"
          className="w-full border rounded"
          value={cloudType}
          onChange={handleCloudTypeChanged}
        >
          {CLOUD_PROVIDERS.map(({id, name, disabled, selected}) => {
            return (
              <MenuItem
                key={id}
                value={id}
                disabled={disabled}
                selected={selected}
              >
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </div>

      <div className="px-4 py-2">
        {cloudType === CLOUD_PROVIDERS[0].id && (
          <div>
            <label className="block mb-1 text-sm text-gray-400" htmlFor="sasUrl">
              Blob SAS URL <span className="text-red-400">*</span>
            </label>
            <textarea
              className="w-full px-4 py-2 border rounded focus:border-primary focus:outline-none"
              name="sasUrl"
              id="sasUrl"
              rows={3}
              required
              ref={refs.azureSasUrlRef}
            />
          </div>
        )}
        {cloudType === CLOUD_PROVIDERS[1].id && (
          <div>
            {["Access Key", "Secret Key", "Bucket", "Prefix"].map((label, index) => (
              <div key={index}>
                <label className="block mb-1 text-sm text-gray-400" htmlFor={label.toLowerCase().replace(" ", "")}>
                  {label} <span className="text-red-400">*</span>
                </label>
                <textarea
                  className="w-full px-4 py-2 border rounded focus:border-primary focus:outline-none"
                  name={label.toLowerCase().replace(" ", "")}
                  id={label.toLowerCase().replace(" ", "")}
                  rows={1}
                  required
                  ref={[
                    refs.awsAccessKeyRef,
                    refs.awsSecretKeyRef,
                    refs.awsBucketRef,
                    refs.awsPrefixRef,
                  ][index]}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="px-4 py-2">
        <label className="block mb-1 text-sm text-gray-400">Assign Tag</label>
        <Autocomplete
          size="small"
          options={tags}
          onChange={onTagSelectChange}
          getOptionLabel={(tag) => tag.name}
          className="w-full text-sm"
          freeSolo
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t("dataset:toolbar.placeholderSelectTag")}
              variant="outlined"
              onInput={onTagInput}
            />
          )}
        />
      </div>
      <div className="px-4 py-2 flex flex-col gap-2">
        <p className="text-sm text-gray-400">Store type</p>
        <fieldset className="flex items-center gap-2">
          {
            storeTypeOptions.map(option => (
              <div
                key={option.value}
                className="flex items-center gap-2"
              >
                <input
                  type="radio"
                  checked={option.value === storeType}
                  value={option.value}
                  onChange={(e) => setStoreType(e.target.value)}
                />
                <label>{option.label}</label>
              </div>
            ))
          }
        </fieldset>
      </div>
      <div className="p-4 flex items-center gap-2">
        <button
          type="submit"
          formAction="test"
          name="test_connection"
          disabled={testingConnection}
          onClick={() => setFormAction(FormAction.TEST_CONNECTION)}
          className="button-secondary"
        >
          {t("dataset:details.buttonTestConnection")}
        </button>

        <button
          type="submit"
          name="sync_cloud"
          onClick={() => setFormAction(FormAction.IMPORT_DATA)}
          className="button-primary"
        >
          {t("dataset:details.buttonImportData")}
        </button>
      </div>
    </form>
  );
};
