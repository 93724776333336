/*
 * File: labeler-table.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 29th December 2021 11:03:37 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useState } from "react";
import { classnames } from "utilities/classes";
import { formatNumber } from "utilities/formatter/number-formatter.utils";
import { getPercentage } from "utilities/misc/misc.utils";
import { useTableSort } from "../hooks/use-table-sort.hook";
import { DashboardTableCommon } from "./dashboard-table-common.component";

export interface RowDataTableLabeler {
  id: number;
  email: string;
  completedJob: number;
  labelingHours: number;
  averageHourPerJob: number;
  score: number;
}

interface LabelerStatisticTableProps {
  data: RowDataTableLabeler[];
}
export const LabelerStatisticTable = ({ data }: LabelerStatisticTableProps) => {
  const {
    rows,
    sort,
    handleSelectSort,
  } = useTableSort<RowDataTableLabeler>(data);

  const maxData = {
    id: -1,
    email: "",
    completedJob: 0,
    labelingHours: 0,
    averageHourPerJob: 0,
    score: 0,
  };
  data.forEach((itemData) => {
    maxData.completedJob = Math.max(
      maxData.completedJob,
      itemData.completedJob
    );
    maxData.labelingHours = Math.max(
      maxData.labelingHours,
      itemData.labelingHours
    );
    maxData.averageHourPerJob = Math.max(
      maxData.averageHourPerJob,
      itemData.averageHourPerJob
    );
    maxData.score = Math.max(maxData.score, itemData.score);
  });

  const [columns] = useState<
    {
      field: keyof RowDataTableLabeler;
      header: string;
      color?: string;
      renderCell: (row: RowDataTableLabeler) => JSX.Element;
    }[]
  >([
    {
      field: "id",
      header: "ID",
      renderCell: (row: RowDataTableLabeler) => {
        return <span className="text-xs text-primary">{row.id}</span>;
      },
    },
    {
      field: "email",
      header: "Email",
      renderCell: (row: RowDataTableLabeler) => {
        return <span className="text-xs text-background-700">{row.email}</span>;
      },
    },
    {
      field: "completedJob",
      header: "Completed Job",
      renderCell: (row: RowDataTableLabeler) => {
        const percent = getPercentage(row.completedJob, maxData.completedJob);
        return (
          <div className="relative flex items-center w-full h-4 gap-1">
            <span
              className="h-4"
              style={{ width: `${percent}%`, backgroundColor: "#3241FF" }}
            />
            <span
              className={classnames({
                "absolute left-1.5 top-0 bottom-0 text-white": percent > 20,
              })}
            >
              {formatNumber(row.completedJob)}
            </span>
          </div>
        );
      },
    },
    {
      field: "labelingHours",
      header: "Labeling hrs",
      renderCell: (row: RowDataTableLabeler) => {
        const percent = getPercentage(row.labelingHours, maxData.labelingHours);
        return (
          <div className="relative flex items-center w-full h-4 gap-1">
            <span
              className="h-4"
              style={{ width: `${percent}%`, backgroundColor: "#3990FF" }}
            />
            <span
              className={classnames({
                "absolute left-1.5 top-0 bottom-0 text-white": percent > 20,
              })}
            >
              {formatNumber(row.labelingHours)}
            </span>
          </div>
        );
      },
    },
    {
      field: "averageHourPerJob",
      header: "Ave. hrs per job",
      renderCell: (row: RowDataTableLabeler) => {
        const percent = getPercentage(
          row.averageHourPerJob,
          maxData.averageHourPerJob
        );
        return (
          <div className="relative flex items-center w-full h-4 gap-1">
            <span
              className="h-4"
              style={{ width: `${percent}%`, backgroundColor: "#47E1FF" }}
            />
            <span
              className={classnames({
                "absolute left-1.5 top-0 bottom-0 text-white": percent > 20,
              })}
            >
              {formatNumber(row.averageHourPerJob)}
            </span>
          </div>
        );
      },
    },
    {
      field: "score",
      header: "Score Job",
      renderCell: (row: RowDataTableLabeler) => {
        const percent = getPercentage(row.score, maxData.score);
        return (
          <div className="relative flex items-center w-full h-4 gap-1">
            <span
              className="h-4"
              style={{ width: `${percent}%`, backgroundColor: "#FFBE6F" }}
            />
            <span
              className={classnames({
                "absolute left-1.5 top-0 bottom-0 text-white": percent > 20,
              })}
            >
              {formatNumber(row.score)}
            </span>
          </div>
        );
      },
    },
  ]);

  return (
    <DashboardTableCommon
      rows={rows}
      columns={columns}
      sort={sort}
      handleSelectSort={handleSelectSort}
    />
  );
};
