/*
 * File: page-header.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 21st February 2022 11:05:29 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { CircularProgress } from "@material-ui/core";
import {
  IconChevronLeft,
  IconEye,
  IconGlobal,
  IconSave,
} from "components/common/vb-icon.component";
import { ServingWebStatus } from "domain/web-builder";
import { useAppDispatch } from "hooks/use-redux";
import { useState } from "react";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { classnames } from "utilities/classes";
import { Logger } from "utilities/logger";
import { useWebBuilderContext } from "../../context/web-builder.context";
import * as Sentry from "@sentry/react";

export const PageHeader = () => {
  const dispatch = useAppDispatch();
  const {
    web,
    model,
    onClickPreview,
    onClickPublish,
    onClickDeactive,
    onClickSave,
  } = useWebBuilderContext();

  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isDeactivating, setDeactivating] = useState(false);

  async function handleClickDeactive() {
    if (isDeactivating) return;
    try {
      setDeactivating(true);
      await onClickDeactive();
    } catch (error: any) {
      Sentry.captureException(error);
      Logger.log(error);
    } finally {
      setDeactivating(false);
    }
  }

  async function handleClickPublish() {
    if (isPublishing) return;
    try {
      setIsPublishing(true);
      await onClickPublish();
      dispatch(enqueueSuccessNotification("Success!"));
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessaage = error.message || "Failed to publish your web page!";
      dispatch(enqueueErrorNotification(errMessaage));
      Logger.log(error);
    } finally {
      setIsPublishing(false);
    }
  }

  async function handleClickSaving() {
    if (isSaving) return;
    try {
      setIsSaving(true);
      await onClickSave();
      dispatch(enqueueSuccessNotification("Saved!"));
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessaage = error.message || "Failed to save web data!";
      dispatch(enqueueErrorNotification(errMessaage));
      Logger.log(error);
    } finally {
      setIsSaving(false);
    }
  }
  function handleNavigateBack() {
    window.location.replace("/models/web-builder");
  }

  return (
    <div className="flex items-center flex-none gap-4">
      <div className="flex items-center flex-none gap-2">
        <button className="flex-none w-6 h-6" onClick={handleNavigateBack}>
          <IconChevronLeft className="flex-none w-6 h-6" />
        </button>
        <div
          className="flex-auto text-lg font-semibold focus:outline-none"
          contentEditable
          suppressContentEditableWarning
        >
          {model?.name || "Untitled name"}
        </div>
      </div>
      <div className="flex-auto" />
      <div className="flex items-center flex-none gap-4">
        <button
          className={classnames("button-secondary", {
            "cursor-not-allowed": isSaving,
          })}
          onClick={handleClickSaving}
          disabled={isSaving}
        >
          {isSaving && <CircularProgress size={16} />}
          <span>{isSaving ? "Saving.." : "Save"}</span>
          <IconSave className="w-4 h-4" />
        </button>

        <button className="button-secondary" onClick={onClickPreview}>
          <span>Preview</span>
          <IconEye className="w-4 h-4" />
        </button>

        {web && web.status === ServingWebStatus.Activated && (
          <button
            className={classnames("button-secondary", {
              "cursor-not-allowed": isDeactivating,
            })}
            onClick={handleClickDeactive}
            disabled={isDeactivating}
          >
            {isDeactivating && <CircularProgress size={16} />}
            <span>{isDeactivating ? "Deactivating.." : "Deactive"}</span>
          </button>
        )}

        <button
          className={classnames("button-primary", {
            "cursor-not-allowed": isPublishing,
          })}
          onClick={handleClickPublish}
          disabled={isPublishing}
        >
          {isPublishing && <CircularProgress color="inherit" size={16} />}
          <span>{isPublishing ? "Publishing.." : "Publish"}</span>
          <IconGlobal className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};
