/*
 * File: tool-button.component.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 3rd July 2021 5:28:39 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ButtonHTMLAttributes } from "react";
import { classnames } from "utilities/classes";
import { LightTooltip } from "components/material/mat-light-tooltip.component";

export interface ToolButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: JSX.Element | React.Component;
  active?: boolean;
  toolTip?: string;
  inactiveClass?: string;
  activeClass?: string;
  size?: "md" | "sm" | "lg";
}

export const ToolButton = ({
  icon,
  active = false,
  toolTip = "",
  className = "",
  inactiveClass = "bg-gray-600 hover:bg-gray-400 text-white",
  activeClass = "",
  children,
  disabled,
  size = "md",
  ...rest
}: ToolButtonProps) => {
  return (
    <LightTooltip
      title={toolTip}
      arrow
      placement="right"
      disableFocusListener={disabled}
    >
      <div className="relative parent">
        <button
          disabled={disabled}
          className={classnames(
            "rounded flex justify-center items-center cursor-pointer focus:outline-none relative",
            {
              "w-12 h-12": size === "md",
              "w-6 h-6": size === "sm",
              [inactiveClass]: !active,
              "text-white bg-warning-500": active,
              "pointer-events-none cursor-not-allowed opacity-50": !!disabled,
              [activeClass]: !!active,
            },
            className
          )}
          {...rest}
        >
          {icon}
        </button>
        {children}
      </div>
    </LightTooltip>
  );
};
