import { useMemo, useState } from "react";


export function useTableSort<T extends any>(data: T[]){
  const [sort, setSort] = useState<{
    field?: keyof T;
    mode: "asc" | "desc" | "";
  }>({
    field: undefined,
    mode: "asc",
  });
  const rows = useMemo(() => {
    const rowsData = [...data];
    if (sort.field !== undefined) {
      rowsData.sort((a: any, b: any) => {
        if (sort.field !== undefined) {
          const valueA = a[sort.field];
          const valueB = b[sort.field];
          const sortDirection =
            sort.mode === "asc" ? -1 : sort.mode === "desc" ? 1 : 0;
          if (typeof valueA === "number" && typeof valueB === "number") {
            return sortDirection * (valueA - valueB);
          }
          if (typeof valueA === "string" && typeof valueB === "string") {
            return sortDirection * valueA.localeCompare(valueB);
          }
        }
        return 0;
      });
    }
    return rowsData;
  }, [data, sort]);

  const handleSelectSort = (field: keyof T) => {
    if (field !== sort.field) {
      setSort({
        field: field,
        mode: "asc",
      });
    } else if (sort.mode === "") {
      setSort({
        field: field,
        mode: "asc",
      });
    } else if (sort.mode === "asc") {
      setSort({
        field: field,
        mode: "desc",
      });
    } else if (sort.mode === "desc") {
      setSort({
        field: undefined,
        mode: "",
      });
    }
  };

  return {
    rows,
    sort,
    setSort,
    handleSelectSort,
  }
}