import { Checkbox } from "@material-ui/core";
import { useBuildSTTLabelTree } from "hooks/use-observation-tree"
import { TreeTableLayout, TreeTableLayoutColDef, TreeTableNameCell, TreeTableShowChildrenCell } from "pages/customer/projects/components/tree-table-layout.component";
import { useTranslation } from "react-i18next";
import { ObservationTreeNodeModel } from "services/label-service/model/observation-tree-node.model";
import { classnames } from "utilities/classes";
import { SpeechToTextLabelUIModel } from "../context/speech-to-text-labeling.state"

export interface SpeechToTextLabelsProps {
  labels: SpeechToTextLabelUIModel[];
  onChanged?: (values: SpeechToTextLabelUIModel[]) => void;
  disabled?: boolean;
}

export const SpeechToTextLabels = ({
  labels,
  onChanged,
  disabled = false,
}: SpeechToTextLabelsProps) => {
  const { t } = useTranslation();

  const {
    nodesList,
    setShowChildren,
  } = useBuildSTTLabelTree(labels);

  const handleOnSelect = (v: boolean, observationId: number | undefined) => {
    if (!observationId) return;

    const newLabels: SpeechToTextLabelUIModel[] = []
    
    for (const node of nodesList){
      if (node && node.value){
        let newLabel: SpeechToTextLabelUIModel = {
          ...node.value,
          showChildren: node.showChildren,
          visible: node.visible,
        }
        if (node.value.observation.id === observationId){
          newLabel = {
            ...newLabel,
            selected: v,
          }
        }
        newLabels.push(newLabel);
      }
    }

    onChanged && onChanged(newLabels);
  }

  const COLUMNS: TreeTableLayoutColDef[] = [
    {
      header: t("labelerworkspace:stt.labelsTable.headerLabel"),
      flex: 1.5,
      render: (
        node: ObservationTreeNodeModel<SpeechToTextLabelUIModel>
      ) => {
        return (
          <div className={classnames("truncate", node.value?.selected ? "text-warning-500" : "")}>
            {node.value && (
              <TreeTableNameCell
                name={node.value.observation?.name}
                nodeHeight={node.height}
              />
            )}
          </div>
        );
      },
    },
    {
      header: "",
      flex: 0.5,
      render: (
        node: ObservationTreeNodeModel<SpeechToTextLabelUIModel>
      ) => {
        const isLeaf = ObservationTreeNodeModel.isLeaf(node);
        return (
          <TreeTableShowChildrenCell
            isLeaf={isLeaf}
            showChildren={node.showChildren}
            nodeId={node.id}
            setShowChildren={setShowChildren}
          />
        );
      },
    },
    {
      header: t("labelerworkspace:stt.labelsTable.headerSelect"),
      flex: 1,
      render: (
        node: ObservationTreeNodeModel<SpeechToTextLabelUIModel>
      ) => {
        if (node.value?.observation.observationSetting?.systemAttribute) {
          return null;
        }
        const isLeaf = ObservationTreeNodeModel.isLeaf(node);
        const isSystem = node.value?.observation.observationSetting?.systemAttribute;
        return (
          <div className="flex justify-start items-top">
            {
              (!isSystem && isLeaf && node.value) &&
              <Checkbox
                color="primary"
                style={{
                  color: node.value.selected ? "#EA7E00" : "white"
                }}
                disabled={isSystem || !isLeaf || disabled}
                checked={node.value.selected}
                onChange={(_, v) => handleOnSelect(v, node.value?.observation.id)}
              />
            }
          </div>
        );
      },
    },
  ]

  return (
    <div className="h-screen border-r border-gray-600 overflow-y-auto">
      <TreeTableLayout
        containerClasses="text-white bg-background-800"
        rowClasses="p-4 hover:bg-background-900"
        columns={COLUMNS}
        nodes={nodesList}
        rowWidth="100%"
        headerBg=""
        rowsContainerClass=""
      />
    </div>
  )
}