/*
 * File: vb-language-selector.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import VietnameseFlag from "../../assets/images/country_flags/vietnam.svg";
import UnitedStatesFlag from "../../assets/images/country_flags/united-states.svg";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { Popover } from "@material-ui/core";
import { useRef } from "react";

export const SUPPORTED_LANGUAGES = [
  { text: "English", locale: "en", flag: UnitedStatesFlag },
  { text: "Tiếng Việt", locale: "vi", flag: VietnameseFlag },
];

export const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const selectedLanguage = useMemo(() => {
    const selected = SUPPORTED_LANGUAGES.find(
      (e) => e.locale === i18n.language
    );
    if (selected) return selected;
    return SUPPORTED_LANGUAGES[0];
  }, [i18n.language]);
  const anchorEl = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);

  function toggleVisibility() {
    setOpen((pre) => !pre);
  }

  function selectLocale(locale: string) {
    if (locale !== selectedLanguage.locale) {
      i18n.changeLanguage(locale);
    }
    setOpen(false);
  }

  return (
    <div className="relative z-10">
      <button
        ref={anchorEl}
        className="flex items-center focus:outline-none"
        onClick={toggleVisibility}
      >
        <img
          src={selectedLanguage.flag}
          alt={selectedLanguage.text}
          className="w-4 h-4"
        />
        <div className="mx-1 text-sm text-gray-600">{selectedLanguage.text} </div>
        <i className="text-gray-600 uir-angle-small-down"></i>
      </button>

      <Popover
        open={open}
        anchorEl={anchorEl.current}
        onClose={toggleVisibility}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="min-w-full py-2 mt-2 bg-white rounded">
          {SUPPORTED_LANGUAGES.map((language) => {
            return (
              <div
                className="flex items-center px-4 py-2 cursor-pointer hover:text-primary"
                key={language.locale}
                onClick={() => selectLocale(language.locale)}
              >
                <img
                  src={language.flag}
                  alt={language.text}
                  className="w-4 h-4"
                />
                <div className="ml-2 mr-4 whitespace-nowrap">
                  {language.text}
                </div>
              </div>
            );
          })}
        </div>
      </Popover>
    </div>
  );
};
