/*
 * File: get-all-cloud.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {AxiosResponse} from "axios";
import {CloudDTO} from "models/dataset/cloud.model";
import {RequestOptions} from "../../common/request-options";
import {storageAPIget, storageAPIpost} from "../storage-httpclient";

export async function getAllCloud(
  options: RequestOptions = {}
): Promise<AxiosResponse<CloudDTO[]>> {
  const params = new URLSearchParams(options);
  return storageAPIget(`/api/v1/clouds?${params.toString()}`);
}

export async function getCloudsByUser(
  userId: string
): Promise<AxiosResponse<CloudDTO[]>> {
  return getAllCloud({"createdBy.equals": userId});
}

export async function getCloudsByDataset(
  datasetId: string,
  options: RequestOptions = {}
): Promise<AxiosResponse<CloudDTO[]>> {
  return getAllCloud({...options, "datasetId.equals": datasetId});
}


async function testCloudConnection(
  cloudType: string,
  cloudInfo: Record<string, string>,
): Promise<AxiosResponse<CloudDTO>> {
  const data = JSON.stringify({
    cloudType,
    cloudInfo,
    action: "check_connection",
    tagIds: [],
  });
  return storageAPIpost("/api/v1/clouds", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function testAzureCloudConnection(
  cloudType: string,
  sasUrl: string
): Promise<AxiosResponse<CloudDTO>> {
  return testCloudConnection(cloudType, { sasUrl });
}

export async function testAwsCloudConnection(
  cloudType: string,
  accessKey: string,
  secretKey: string,
  bucket: string,
  prefix: string,
): Promise<AxiosResponse<CloudDTO>> {
  return testCloudConnection(cloudType, { accessKey, secretKey, bucket, prefix });
}


export async function importAwsCloudData(
  cloudType: string,
  accessKey: string,
  secretKey: string,
  bucket: string,
  prefix: string,
  datasetId: number,
  tags: number[] = [],
  storeType: string = "copy"
): Promise<AxiosResponse<CloudDTO>> {
  return importCloudData(cloudType, { accessKey, secretKey, bucket, prefix }, datasetId, tags, storeType);
}

export async function importAzureCloudData(
  cloudType: string,
  sasUrl: string,
  datasetId: number,
  tags: number[] = [],
  storeType: string = "copy"
): Promise<AxiosResponse<CloudDTO>> {
  return importCloudData(cloudType, { sasUrl }, datasetId, tags, storeType);
}

export async function importCloudData(
  cloudType: string,
  cloudInfo: Record<string, string>,
  datasetId: number,
  tags: number[] = [],
  storeType: string = "copy"
): Promise<AxiosResponse<CloudDTO>> {
  const data = JSON.stringify({
    cloudType,
    cloudInfo,
    datasetId,
    action: "sync",
    tagIds: tags,
    storeType,
  });
  return storageAPIpost("/api/v1/clouds", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getCloudInfo(cloudId: number) {
  return storageAPIget(`/api/v1/clouds/${cloudId}`);
}

export async function countCloudFiles(
  cloudId: number,
  query: { [key: string]: string } = {}
) {
  query["cloudId.equals"] = `${cloudId}`;
  const params = new URLSearchParams(query);
  return storageAPIget(`api/v1/file-infos/count?${params.toString()}`);
}
