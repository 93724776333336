/*
 * File: mammography-labeling-task.slice.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 22nd September 2022 2:42:54 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  defaultMammographyLabelingTaskState,
  MammographyLabelingTaskState,
} from "./mammography-labeling-task.state";
import { mammographyLabelingTaskReducerBuilder } from "./mammography-labeling-task.thunk";

const SLICE_NAME: string = "mammography-labeling-task";

const slice = createSlice({
  name: SLICE_NAME,
  initialState: defaultMammographyLabelingTaskState,
  reducers: {
    resetMammographyLabelingTaskState(state: MammographyLabelingTaskState) {
      Object.assign(state, defaultMammographyLabelingTaskState);
    },
    mammographyAnnotatorSelected(
      state: MammographyLabelingTaskState,
      action: PayloadAction<string[]>
    ) {
      state.selectedAnnotators = action.payload;
    },
  },
  extraReducers: (builder) => {
    mammographyLabelingTaskReducerBuilder(builder);
  },
});

export const {
  resetMammographyLabelingTaskState,
  mammographyAnnotatorSelected,
} = slice.actions;

export const mammographyLabelingTaskReducer = slice.reducer;
