/*
 * File: use-project-labeler-distribution.hook.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 5:01:08 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { usePrevious } from "ahooks";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect } from "react";
import { RequestStatus } from "store/base/base.state";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { selectProjectLabelerDistributionRequestError, selectProjectLabelerDistributionRequestStatus } from "store/customer/project-data/labeler-distribution/labeler-distribution.selectors";
import { loadProjectLabelerDistributionAsyc } from "store/customer/project-data/labeler-distribution/labeler-distribution.thunk";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import * as Sentry from "@sentry/react";

export const useProjectLabelerDistribution = (
  projectId: number,
  reloadIfLoaded = false
) => {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(selectProjectLabelerDistributionRequestStatus);
  const requestError = useAppSelector(selectProjectLabelerDistributionRequestError);
  const previousProjectId = usePrevious(projectId);

  const requestData = useCallback(
    async (force = false) => {
      if (!force && requestStatus === RequestStatus.LOADING) return;
      if (!reloadIfLoaded && !force && requestStatus !== RequestStatus.IDLE) return;
      try {
        const response = await dispatch(loadProjectLabelerDistributionAsyc(projectId));
        handleThunkRejected(response);
      } catch (error: any) {
        Sentry.captureException(error);
        const errMessage =
          error.message || "Failed to load labeler distribution data";
        dispatch(enqueueErrorNotification(errMessage));
        Logger.log(error);
      }
    },
    [dispatch, projectId, requestStatus, reloadIfLoaded]
  );

  useEffect(() => {
    if (projectId && projectId >= 0 && previousProjectId !== projectId){
      requestData(true);
    } else {
      requestData();
    }
  }, [dispatch, projectId, previousProjectId, requestData]);

  return { requestData, requestStatus, requestError };
};
