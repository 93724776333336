/*
 * File: update-batch.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 24th March 2022 10:08:42 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBTextAreaComponent } from "components/design-system/text-area/text-area.component";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { useAppDispatch } from "hooks/use-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BatchDTO } from "services/label-service/dtos";
import {
  enqueueSuccessNotification,
  enqueueErrorNotification,
} from "store/common/notification/notification.actions";
import { updateBatchAsync } from "store/customer/batch/batch.thunk";
import * as Sentry from "@sentry/react";
import DOMPurify from "dompurify";

interface Props {
  batch: BatchDTO;
  onClose(): void;
}
export const UpdateBatchModal = ({ batch, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);
  const [name, setName] = useState(batch?.name || "");
  const [description, setDescription] = useState(batch?.description || "");
  const [instruction, setInstruction] = useState(batch?.instruction || "");

  async function handleSubmit() {
    if (processing || !batch) return;
    try {
      setProcessing(true);
      const payload = {
        id: batch.id,
        name: DOMPurify.sanitize(name.trim()),
        description: DOMPurify.sanitize(description.trim()),
        instruction: DOMPurify.sanitize(instruction.trim()),
      };
      await dispatch(updateBatchAsync(payload));
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      setProcessing(false);
      onClose();
    } catch (err: any) {
      Sentry.captureException(err);
      const errMessage = err.message || t("common:textFailed");
      dispatch(enqueueErrorNotification(errMessage));
      setProcessing(false);
    }
  }

  return (
    <VBModal
      open
      title="Update batch information"
      onClose={onClose}
      disableSubmit={processing}
      blockUI={processing}
      processingIndicator={processing}
      onSubmit={handleSubmit}
      textSubmit="Update"
    >
      <div className="space-y-4">
        <VBTextInputComponent
          onInputChange={(val) => setName(DOMPurify.sanitize(val))}
          header="Name"
          maxLength={256}
          inputIcon={<></>}
          defaultValue={batch.name}
        />

        <VBTextAreaComponent
          onInputChange={(val) => setDescription(DOMPurify.sanitize(val))}
          header="Description"
          maxLength={500}
          defaultValue={batch.description}
          placeholder="Description"
        />

        <VBTextAreaComponent
          onInputChange={(val) => setInstruction(DOMPurify.sanitize(val))}
          header="Instruction"
          maxLength={500}
          defaultValue={batch.instruction}
          placeholder="Instruction"
        />
      </div>
    </VBModal>
  );
};
