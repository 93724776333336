/*
 * File: ml-web.page.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 17th February 2022 2:59:13 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import { MLWeb } from "domain/web-builder";
import {
  MLWebItemAction,
  MLWebTable,
} from "./components/ml-web-table.component";
import { useMLWebContext } from "./context/ml-web.context";

export const MLWebPage = () => {
  const {
    items,
    totalItem,
    isLoading,
    filter,
    setFilterField,
    onClickSelect,
    onClickCreateNew,
    onClickRename,
    onClickOpenInNewTab,
    onClickDelete,
    onClickCopyPublishUrl,
  } = useMLWebContext();

  function onRowAction(item: MLWeb, action: MLWebItemAction) {
    if (action === MLWebItemAction.SELECT) {
      return onClickSelect(item);
    }
    if (action === MLWebItemAction.RENAME) {
      return onClickRename(item);
    }
    if (action === MLWebItemAction.OPEN_IN_A_NEW_TAB) {
      return onClickOpenInNewTab(item);
    }
    if (action === MLWebItemAction.DELETE) {
      return onClickDelete(item);
    }
    if (action === MLWebItemAction.COPY_PUBLISH_URL) {
      return onClickCopyPublishUrl(item);
    }
  }

  return (
    <div className="px-4 h-full flex flex-col">
      <div className="flex items-center gap-4 mb-6">
        <div className="flex-auto text-xl font-semibold">Web Builder</div>
        <button className="button-primary" onClick={onClickCreateNew}>Create new</button>
      </div>
      {
        (totalItem > 0) &&
        <div className="flex-1">
          <MLWebTable rows={items} onRowAction={onRowAction} />
        </div>
      }
      
      {(filter && totalItem > 0) && (
        <div className="flex-none py-4">
          <GridPagination
            page={filter.page}
            pageSize={filter.pageSize}
            pageSizeOptions={[10, 15, 20, 25, 50, 75, 100]}
            onPageSizeChange={v => setFilterField("pageSize", v)}
            onPageChange={v => setFilterField("page", v)}
            totalItems={totalItem}
          />
        </div>
      )}

      {
        (totalItem <= 0 && !isLoading) &&
        <div className="flex w-full items-center justify-center py-4 text-lg">
          <span>There are no web builders yet!</span>
        </div>
      }
    </div>
  );
};
