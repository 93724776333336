/*
 * File: use-exported-datasets.hook.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 1st September 2021 2:59:44 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  selecteExportedDatasets,
  selectExportedDatasetsRequestError,
  selectExportedDatasetsRequestStatus,
  selectExportedDatasetVersions,
} from "store/customer/exported-datasets/exported-datasets.selectors";
import {
  deleteExportedDatasetAsync,
  loadExportedDatasetsAsync,
} from "store/customer/exported-datasets/exported-datasets.thunk";
import { RequestStatus } from "store/base/base.state";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import * as Sentry from "@sentry/react";

export const useExportedDatasets = (forceReload = false) => {
  const dispatch = useAppDispatch();
  const exportedDatasets = useAppSelector(selecteExportedDatasets);
  const requestStatus = useAppSelector(selectExportedDatasetsRequestStatus);
  const requestError = useAppSelector(selectExportedDatasetsRequestError);
  const datasetVersions = useAppSelector(selectExportedDatasetVersions);

  const deleteExportedDataset = async (datasetId: number) => {
    try {
      const response = await dispatch(deleteExportedDatasetAsync(datasetId));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification("Deleted!"));
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || "Failed to delete this dataset!";
      dispatch(enqueueErrorNotification(errMessage));
    }
  };

  const requestData = useCallback(async () => {
    return dispatch(loadExportedDatasetsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (requestStatus === RequestStatus.IDLE && !forceReload) {
      requestData();
    }
  }, [requestStatus, forceReload, requestData]);

  useEffect(() => {
    if (forceReload) requestData();
  }, [forceReload, requestData]);

  return {
    exportedDatasets,
    datasetVersions,
    loading:
      requestStatus === RequestStatus.IDLE ||
      requestStatus === RequestStatus.LOADING,
    requestError,
    requestData,
    deleteExportedDataset,
  };
};
