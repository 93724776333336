import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { Job } from "domain/labeling/job";
import {
  JobService,
  BatchService,
  BatchObservationService,
  TaskObservationService,
} from "services/label-service";
import {
  JobDTO,
  ObservationDTO,
  BatchObservationDTO,
  TaskObservationDTO,
} from "services/label-service/dtos";
import { imageLabelMapper } from "services/label-service/mappers/image-label.mapper";
import { jobMapper } from "services/label-service/mappers/job.mapper";
import { StorageService } from "services/storage";
import { RequestStatus } from "store/base/base.state";
import { ImageLabelingJob } from "../../batch-labeling/batch-labeling.state";
import { batchLabelingUtils } from "../../batch-labeling/batch-labeling.util";
import { JobObservationModel } from "../../image-labeling/image-labeling.state";
import { ImageWorkspaceState } from "../../image-workspace.state";

const THUNK_NAME = "imageWorkspace/loadImageReviewAsync";
export const loadImageReviewAsync = createAsyncThunk(
  THUNK_NAME,
  async ({ jobId }: { jobId: number }) => {
    if (isNaN(jobId) || !jobId) throw new Error();
    const job: JobDTO = (await JobService.getReviewJob(jobId)).data;
    if (!job) throw new Error();
    const jobData = await _fetchJobData(jobMapper.fromDTO(job));
    const data = await _fetchJobAnnotations(job);
    const { observations: jobObservations, previousJobObservations } = data;
    let reopenReason = "";

    return {
      jobData,
      jobObservations,
      previousJobObservations,
      reopenReason,
    };
  }
);

const _fetchJobData = async (job: Job): Promise<ImageLabelingJob> => {
  const task = await batchLabelingUtils.getTaskById(job.taskId);
  const batch = await BatchService.getBatchById(task.batchId);
  let { data: file } = await StorageService.getFileInfoDetails(
    task.fileId.toString()
  );
  file = await StorageService.cacheFileToLocalUrlAndAddMineType(file, batch.workspaceId);
  const data = await _fetchBatchObservations(task.batchId);
  const { allBatchObservations } = data;

  return {
    id: job.id,
    job: job,
    task: task,
    file: file,
    batch: batch,
    project: batch.project,
    labels: imageLabelMapper.toLabels(allBatchObservations),
  };
};

const _fetchBatchObservations = async (batchId: number) => {
  const observationEntities: { [key: number]: ObservationDTO } = {};
  const batchObservations: BatchObservationDTO[] = [];
  const systemObservations: BatchObservationDTO[] = [];
  const payload = { batchId: batchId.toString(), size: "500" };
  let response = await BatchObservationService.getItems(payload);

  const allBatchObservations: BatchObservationDTO[] = response.data;
  for (let batchObservation of allBatchObservations) {
    const { observation } = batchObservation;
    observationEntities[observation.id] = observation;
    if (observation.observationSetting?.systemAttribute) {
      systemObservations.push(batchObservation);
    }
    batchObservations.push(batchObservation);
  }
  return {
    allBatchObservations,
    observationEntities,
    batchObservations,
    systemObservations,
  };
};

const _fetchJobAnnotations = async (job: JobDTO) => {
  let currentJobObservations: TaskObservationDTO[] = [];
  let previousJobObservations: JobObservationModel[] = [];

  const response = await TaskObservationService.getJobAnnotations(job.id);
  if (response && response.data) {
    for (let taskObservation of response.data) {
      if (taskObservation.jobId === job.id) {
        currentJobObservations.push(taskObservation);
      } else {
        let jobObservation = previousJobObservations.find(
          (j) => j.jobId === taskObservation.jobId
        );
        if (!jobObservation) {
          jobObservation = {
            assignee: taskObservation.assignee || "",
            jobId: taskObservation.jobId,
            observations: [],
            selected: false,
          };
          previousJobObservations.push(jobObservation);
        }
        jobObservation.observations.push(taskObservation);
      }
    }
  }
  return {
    observations: currentJobObservations,
    previousJobObservations,
  };
};

export function loadImageReviewBuilder(
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) {
  return builder
    .addCase(loadImageReviewAsync.pending, ({ imageReview }) => {
      imageReview.requestStatus = RequestStatus.LOADING;
    })
    .addCase(loadImageReviewAsync.fulfilled, ({ imageReview }, action) => {
      imageReview.requestStatus = RequestStatus.SUCCESS;
      imageReview.jobData = action.payload.jobData;
      imageReview.jobObservations = action.payload.jobObservations;
      imageReview.previousJobObservations =
        action.payload.previousJobObservations;
      if (action.payload.reopenReason) {
        imageReview.reopenReason = {
          reason: action.payload.reopenReason,
        };
      }
    })
    .addCase(loadImageReviewAsync.rejected, ({ imageReview }) => {
      imageReview.requestStatus = RequestStatus.FAILURE;
    });
}
