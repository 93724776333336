/*
 * File: button-sort.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 13th July 2023 10:03:14 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VscCheck, VscChevronDown } from "react-icons/vsc";
import { BsSortDown } from "react-icons/bs";
import { useState } from "react";
import { SortEnum } from "domain/common";
import { useFileBrowseContext } from "../../context/file-browse.context";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

export function ButtonSort() {
  const { filter, updateFilter } = useFileBrowseContext();

  const [sortableFields] = useState([
    { label: "Type", value: "type" },
    { label: "Name", value: "name" },
    { label: "Upload Date", value: "createdAt" },
    { label: "Upload By", value: "createdBy" },
    { label: "File Size", value: "fileSize" },
  ]);

  const [sortOrders] = useState([
    { label: "Ascending", value: SortEnum.ASC },
    { label: "Descending", value: SortEnum.DESC },
  ]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function selectSortOrder(order: string) {
    if (!filter) return;
    const sort = {
      order,
      field: filter?.sort?.field ?? sortableFields[0].value,
    };
    updateFilter({ ...filter, sort });
    handleClose();
  }

  function selectSortField(field: string) {
    if (!filter) return;
    const sort = { field, order: filter?.sort?.order ?? sortOrders[0].value };
    updateFilter({ ...filter, sort });
    handleClose();
  }

  return (
    <div className="flex-none">
      <button
        className="px-3 py-1.5 flex items-center gap-1 rounded hover:bg-background-200"
        onClick={handleClick}
      >
        <BsSortDown size={18} />
        <span className="hidden px-1 text-sm md:block">Sort</span>
        <VscChevronDown size={16} />
      </button>

      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "sort-menu" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
      >
        {sortableFields.map((sortableField) => {
          return (
            <MenuItem
              key={sortableField.value}
              onClick={() => selectSortField(sortableField.value)}
            >
              <div className="w-8 text-center">
                {filter?.sort?.field === sortableField.value && (
                  <VscCheck size={18} />
                )}
              </div>
              <ListItemText className="pr-4">
                {sortableField.label}
              </ListItemText>
            </MenuItem>
          );
        })}
        <Divider />
        {sortOrders.map((sortOrder) => {
          return (
            <MenuItem
              key={sortOrder.value}
              onClick={() => selectSortOrder(sortOrder.value)}
            >
              <div className="w-8 text-center">
                {filter?.sort?.order === sortOrder.value && (
                  <VscCheck size={18} />
                )}
              </div>
              <ListItemText className="pr-4">{sortOrder.label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

export default ButtonSort;
