/*
 * File: draw-handles.js
 * Project: app-aiscaler-web
 * File Created: Friday, 3rd December 2021 11:04:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import * as csTools from "cornerstone-tools";
import { toolStyle, toolColors } from "cornerstone-tools";
const external = csTools.external;

/**
 * Draws proivded handles to the provided context
 * @public
 * @method drawHandles
 * @memberof Drawing
 *
 * @param {CanvasRenderingContext2D} context - Target context
 * @param {*} evtDetail - Cornerstone's 'cornerstoneimagerendered' event's `detail`
 * @param {Object} rect - Bounding of the measurement
 * @param {Object} [options={}] - Options object
 * @param {string} [options.color]
 * @param {Boolean} [options.drawHandlesIfActive=false] - Whether the handles should only be drawn if Active (hovered/selected)
 * @param {string} [options.fill]
 * @param {Number} [options.handleRadius=6]
 * @returns {undefined}
 */
export default function (context, evtDetail, points, color, opacity = 1) {
  if (!points || points.length === 0) return;
  const element = evtDetail.element;
  context.globalAlpha = opacity;
  context.fillStyle = color;
  points.map((point) => {
    context.beginPath();
    const canvasPoint = external.cornerstone.pixelToCanvas(element, point);
    context.arc(canvasPoint.x, canvasPoint.y, 5, 0, 2 * Math.PI);
    context.closePath();
    context.fill();
  });
  context.globalAlpha = 1;
}
