/*
 * File: label-toolbar.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 8th March 2022 1:57:39 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { IconEye, IconHideBold } from "components/common/vb-icon.component";
import { Label } from "domain/image-labeling";
import { classnames } from "utilities/classes";

interface Props {
  labels?: Label[];
  selectedLabelIds?: number[];
  onSelect?(labelId: number): void;
}
export const LabelToolbar = ({
  labels = [],
  selectedLabelIds = [],
  onSelect,
}: Props) => {
  function handleSelectLabel(label: Label) {
    onSelect && onSelect(label.id);
  }

  return (
    <div className="z-10 flex-none h-full bg-white w-60">
      <div className="w-full h-full overflow-hidden border rounded border-background-300">
        <div className="flex items-center w-full h-12 px-4 text-lg font-semibold text-white bg-primary">
          Labels
        </div>

        <div className="py-4">
          {labels
            .filter((label) => !label.isSystemLabel)
            .map((label) => {
              const active = selectedLabelIds.includes(label.id);
              return (
                <div
                  className={classnames(
                    "flex items-center h-10 gap-2 px-4 cursor-pointer parent"
                  )}
                  key={label.id}
                  onClick={() => handleSelectLabel(label)}
                >
                  <span className="flex-auto overflow-hidden truncate whitespace-nowrap">
                    {label.name}
                  </span>
                  <button
                    className={classnames("flex-none w-4 h-4", {
                      "child-on-hover": !active,
                    })}
                  >
                    {!active && <IconEye className="flex-none w-4 h-4" />}
                    {active && <IconHideBold className="flex-none w-4 h-4" />}
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
