/*
 * File: image-label.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 15th April 2022 11:37:51 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AnnotateType } from "constants/annotation.constant";
import { Label } from "domain/image-labeling";
import { BatchObservationDTO } from "../dtos";

function toLabel(batchObservation: BatchObservationDTO): Label {
  const { observationSetting } = batchObservation.observation;
  const isSystemLabel = !!observationSetting?.systemAttribute;
  let annotateType =
    (batchObservation.observation?.observationSetting
      ?.annotationType as AnnotateType) || AnnotateType.SYSTEM;
  if (isSystemLabel) {
    annotateType = AnnotateType.SYSTEM;
  }
  return {
    batchObservationId: batchObservation.id,
    id: batchObservation.observation.id,
    name: batchObservation.observation.name,
    code: batchObservation.observation.code,
    color: batchObservation.observation?.observationSetting?.color || "#FF00FF",
    isSystemLabel,
    annotateType,
    requireProbability: batchObservation.probabilityRequired,
    requireBorder: batchObservation.labelRequired,
    priority: batchObservation.priority || 0,
    parentId: batchObservation.observation.parent?.id,
    attributes:
      batchObservation.observation.attributes?.map((attr) => {
        return {
          type: attr.type,
          id: attr.id,
          name: attr.name,
          defaultValue: attr.defaultValue,
          options: attr.values,
          ranges: attr.ranges,
          required: attr.required,
        };
      }) || [],
    isOCR: true, // TODO: change later for new observation api //observationSetting?.value === ValueType.FREE_TEXT,
    numberOfPoint:
      batchObservation.observation.observationSetting.numberOfPoint,
    dto: batchObservation,
  };
}

function toFlatLabels(batchObservations: BatchObservationDTO[]) {
  return batchObservations.map(toLabel);
}

function toLabels(batchObservations: BatchObservationDTO[]) {
  const labels = batchObservations.map(toLabel);
  return _buildTree(labels);
}

function _buildTree(labels: Label[]) {
  const nodes = labels.filter((label) => !label.parentId);
  return nodes.map((node) => _buildNode(labels, node));
}

function _buildNode(labels: Label[], node: Label) {
  const children = labels.filter(
    (label) => label.parentId && label.parentId === node.id
  );
  if (children.length > 0) {
    node.children = children.map((child) => _buildNode(labels, child));
  }
  return node;
}

function flattenLabels(labels: Label[], items: Label[] = []): Label[] {
  for (let label of labels) {
    if (!label.children || label.children.length === 0) {
      items.push(label);
    } else {
      flattenLabels(label.children, items);
    }
  }
  return items;
}

function toEntities(labels: Label[]): Record<number, Label> {
  const records: Record<number, Label> = {};
  for (let label of labels) {
    records[label.id] = label;
  }
  return records;
}
export const imageLabelMapper = {
  toLabel,
  toLabels,
  toFlatLabels,
  toEntities,
  flattenLabels,
};
