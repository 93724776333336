/*
 * File: ai-image.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 23rd February 2022 3:05:17 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AnnotationV1, Label } from "domain/image-labeling";

export enum AIResultStatus {
  INITIAL = "initial",
  PROCESSING = "processing",
  COMPLETED = "completed",
  NOTFOUND = "notfound",
}

export interface AIImage {
  file?: File;
  imageUrl: string;
  annotations: AnnotationV1[];
  labels?: Label[];
  status?: AIResultStatus;
}
