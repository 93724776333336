/*
 * File: get-file-info.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AxiosResponse } from "axios";
import { TextFileDTO } from "../../../models/dataset/storage-file.model";
import { RequestOptions } from "../../common/request-options";
import { storageAPIget } from "../storage-httpclient";

export async function getTextFileInfo(
  fileId: string,
  options: RequestOptions = {}
): Promise<AxiosResponse<TextFileDTO>> {
  const params = new URLSearchParams(options);
  return storageAPIget(
    `/api/v1/additional-file-infos/from-file/${fileId}?${params.toString()}`
  );
}
