/*
 * File: labeler-tasks.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 22nd May 2023 1:51:57 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { getFilterQuery } from "domain/common";
import { ILabelerTaskEntity, ILabelerTaskFilter } from "../types";

function fromDto(dto: any): ILabelerTaskEntity {
  return {
    id: dto.id,
    jobId: dto.id,
    taskId: dto.task.id,
    batchId: dto.batchId,
    fileId: dto.task.taskReference,
    fileName: dto.task.fileName,
    assignee: dto.assignee,
    jobStatus: dto.status,
    taskStatus: dto.task.status,
    projectType: dto.projectType,
    projectId: -1,
    dto,
  };
}

function toListRequestParams(filter: ILabelerTaskFilter): URLSearchParams {
  const params = new URLSearchParams();
  params.append("page", filter.page.toString());
  params.append("size", filter.size.toString());
  const jobStatus = getFilterQuery(filter, "jobStatus")?.value ?? "completed";
  if (filter.projectId) params.append("projectId", filter.projectId);
  if (jobStatus) params.append("jobStatus", jobStatus);
  return params;
}

export const labelerTasksMapper = { fromDto, toListRequestParams };
