/*
 * File: builder-panel.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 21st February 2022 11:06:27 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { MLModelType } from "domain/web-builder";
import { useWebBuilderContext } from "../../context/web-builder.context";
import { ColorSelector } from "../color-selector/color-selector.component";
import { ModelSelector } from "../model-selector/model-selector.component";
import { ModelTypeSelector } from "../model-type-selector/model-type-selector.component";
import { TemplateSelector } from "../template-selector/template-selector.component";
import { ThemeSelector } from "../theme-selector/theme-selector.component";

export const BuilderPanel = () => {
  const {
    themeOptions,
    theme,
    setTheme,

    modelTypeOptions,
    modelType,
    setModelType,

    model,
    modelVersion,
    setModel,
    setModelVersion,

    color,
    colorOptions,
    setColor,

    templateOptions,
    template,
    setTemplate,
  } = useWebBuilderContext();

  return (
    <div className="flex-none h-full py-4 space-y-4 w-60 bg-background-200">
      <ThemeSelector options={themeOptions} value={theme} onChange={setTheme} />

      <ModelTypeSelector
        options={modelTypeOptions}
        value={modelType}
        onChange={setModelType}
      />

      <ModelSelector
        type={modelType as MLModelType}
        model={model}
        modelVersion={modelVersion}
        setModel={setModel}
        setModelVersion={setModelVersion}
      />

      <TemplateSelector
        options={templateOptions}
        value={template}
        onChange={setTemplate}
      />

      <ColorSelector options={colorOptions} value={color} onChange={setColor} />
    </div>
  );
};
