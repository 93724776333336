/*
 * File: use-scope.hook.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 31st August 2021 5:21:45 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  selectScopeRequestError,
  selectScopeRequestStatus,
  selectScopes,
} from "store/customer/scope/scope.selectors";
import { loadScopesAsync } from "store/customer/scope/scope.thunk";
import { RequestStatus } from "store/base/base.state";

export const useScope = () => {
  const dispatch = useAppDispatch();
  const scopes = useAppSelector(selectScopes);
  const requestStatus = useAppSelector(selectScopeRequestStatus);
  const requestError = useAppSelector(selectScopeRequestError);
  const scopeTabs = useMemo(() => {
    return [
      ...scopes.map((scope) => ({ name: scope.name, id: scope.id.toString() })),
    ];
  }, [scopes]);
  const scopeOptions = useMemo(() => {
    return scopes.map((scope) => ({
      label: scope.name,
      value: scope.id.toString(),
    }));
  }, [scopes]);

  const requestData = useCallback(async () => {
    return dispatch(loadScopesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (requestStatus === RequestStatus.IDLE) {
      requestData();
    }
  }, [requestStatus, requestData]);

  return {
    scopes,
    scopeOptions,
    scopeTabs,
    requestStatus,
    requestError,
    requestData,
  };
};
