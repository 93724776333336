/*
 * File: cornerstone-image-viewer.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 9th August 2021 11:04:40 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { forwardRef, useEffect, useRef, useState } from "react";
import { useDetectChange } from "hooks/use-detect-change";
import * as cornerstone from "cornerstone-core";
import { Logger } from "utilities/logger";
import * as Sentry from "@sentry/react";

interface Props {
  imageUrl?: string;
  onLoaded?: (imageId?: string) => void;
  onError?: (error: Error) => void;
}

export const CornerstoneImageViewer = forwardRef<HTMLDivElement, Props>(
  ({ imageUrl, onLoaded, onError }: Props, containerRef) => {
    const [loaded, setLoaded] = useState(false);
    const fileChanged = useDetectChange([imageUrl]);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
      if (!imageUrl || !fileChanged || loaded) return;
      const loadData = async function () {
        try {
          setLoaded(false);
          const imageId = imageUrl;
          const element = (containerRef as any).current;
          cornerstone.enable(element);
          const image = await cornerstone.loadAndCacheImage(imageId);
          cornerstone.displayImage(element, image);
          cornerstone.resize(element, true);
          cornerstone.reset(element);
          onLoaded && onLoaded();
          setLoaded(true);
        } catch (err: any) {
          Sentry.captureException(err);
          Logger.log(err);
          const errMessage = err?.message || "Failed to load image";
          onError && onError(errMessage);
          setLoaded(true);
        }
      };
      if (containerRef && imageUrl) loadData();
    }, [loaded, fileChanged, imageUrl, onError, onLoaded, containerRef]);

    useEffect(() => {
      canvasRef.current?.getContext("2d", { willReadFrequently: true });
    }, [canvasRef]);

    return (
      <div className="relative w-full h-full">
        <div
          className="relative w-full h-full viewport-element"
          ref={containerRef}
        >
          <canvas ref={canvasRef} className="cornerstone-canvas" />
        </div>

        {!loaded && (
          <div className="absolute top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full text-background-500">
            Loading...
          </div>
        )}
      </div>
    );
  }
);
