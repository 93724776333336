/*
 * File: file-grid.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 5:27:53 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { QueryApi } from "data-access/impl/query";
import { FileCard } from "../file-card/file-card";

interface FileGridProps {
  files: QueryApi.IFile[];
  selectedFileIds: number[];
  onSelect(fileId: number, action: string): void;
}
export function FileGrid({ files, selectedFileIds, onSelect }: FileGridProps) {
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 p-0.5 pb-12">
      {files.map((file) => {
        return (
          <FileCard
            key={file.id}
            file={file}
            onSelect={onSelect}
            selected={selectedFileIds.includes(file.id)}
          />
        );
      })}
    </div>
  );
}

export default FileGrid;
