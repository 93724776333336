/*
 * File: text-labeling-header.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 15th October 2021 11:03:57 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Tooltip } from "@material-ui/core";
import {
  IconArrowRightLinear,
  IconBook,
  IconCloseCircle,
  IconFullScreen,
  IconInformationCircleOutline,
  IconSetting2,
} from "components/common/vb-icon.component";
import { UserAvatar } from "components/common/vb-user-avatar.component";
import { useTextTaskContext } from "components/text-labeling/text-task-context/text-task.context";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { selectTextLabelingTaskAnnotatorsByStep } from "store/labeler/text-labeling-task/text-labeling-task.selectors";
import { editorSettingsSelected } from "store/labeler/text-workspace/text-editor-setting/text-editor-setting.slice";
import { selectShowLabelInstruction } from "store/labeler/text-workspace/text-editor/text-editor.selector";
import { selectTextLabelingTask } from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { selectTextReviewSelectedAnnotators } from "store/labeler/text-workspace/text-review/text-review.selectors";
import { startTextTaskReviewAsync } from "store/labeler/text-workspace/text-review/text-review.thunk";
import {
  setShowBatchInstruction,
  setShowLabelInstruction,
  textReviewAnnotatorSelected,
} from "store/labeler/text-workspace/text-workspace.slice";
import { classnames } from "utilities/classes";
import { useTextWorkspaceContext } from "../../context/text-workspace/text-workspace.context";
import { TextIssueButton } from "./components/text-issue-button.component";
interface Props {
  allTaskIds?: number[];
  onNext?(): void;
  onPrevious?(): void;
}
export const TextLabelingHeaderReview = ({
  onNext,
  onPrevious,
  allTaskIds,
}: Props) => {
  const dispatch = useAppDispatch();
  const { handleConfirmExit } = useTextWorkspaceContext();
  const showLabelInstruction = useAppSelector(selectShowLabelInstruction);
  const task = useAppSelector(selectTextLabelingTask);
  const { isFullScreen, toggleDisplayFullScreen } = useTextTaskContext();
  const stepDatas = useAppSelector(selectTextLabelingTaskAnnotatorsByStep);
  const selectedAnnotators = useAppSelector(selectTextReviewSelectedAnnotators);

  function handleClickBack() {
    handleConfirmExit();
  }

  function onClickShowBatchInstruction() {
    dispatch(setShowBatchInstruction(true));
  }

  function toggleLabelInstruction() {
    dispatch(setShowLabelInstruction(!showLabelInstruction));
  }

  function onClickEditorSettings() {
    dispatch(editorSettingsSelected());
  }

  function handleClickAnnotator(annotator: string) {
    dispatch(textReviewAnnotatorSelected(annotator));
    if (task) {
      dispatch(startTextTaskReviewAsync({ taskId: task.id, update: true }));
    }
  }

  return (
    <div className="z-50 flex items-center h-16 gap-4 px-4 bg-white shadow">
      <button onClick={handleClickBack}>
        <IconCloseCircle className="w-6 h-6 text-primary" />
      </button>
      <span className="text-lg font-bold">#{task?.id}</span>
      <Spacer />
      <div className="flex items-center gap-2">
        <Tooltip title="View instruction">
          <button
            className="flex items-center justify-center flex-none w-8 h-8 rounded text-background-700 hover:bg-background-200"
            onClick={onClickShowBatchInstruction}
          >
            <IconInformationCircleOutline className="flex-none w-5 h-5" />
          </button>
        </Tooltip>

        <Tooltip title="Label definitions">
          <button
            className="flex items-center justify-center flex-none w-8 h-8 rounded text-background-700 hover:bg-background-200"
            onClick={toggleLabelInstruction}
          >
            <IconBook className="flex-none w-5 h-5" />
          </button>
        </Tooltip>

        <Tooltip title={isFullScreen ? "Exit full screen" : "Open full screen"}>
          <button
            className="flex items-center justify-center flex-none w-8 h-8 rounded text-background-700 hover:bg-background-200"
            onClick={toggleDisplayFullScreen}
          >
            <IconFullScreen className="flex-none w-5 h-5" />
          </button>
        </Tooltip>

        <Tooltip title="Editor settings">
          <button
            className="flex items-center justify-center flex-none w-8 h-8 rounded text-background-700 hover:bg-background-200"
            onClick={onClickEditorSettings}
          >
            <IconSetting2 className="flex-none w-5 h-5" />
          </button>
        </Tooltip>
      </div>

      <TextIssueButton />

      <AutoSpacing />

      <Spacer />
      {stepDatas.length === 0 && <div>Unassigned</div>}

      {stepDatas.map((stepAnnotators, index) => {
        return (
          <div className="flex items-center gap-2" key={stepAnnotators.step}>
            {index > 0 && (
              <IconArrowRightLinear className="w-5 h-5 mr-2 text-background-500" />
            )}
            {stepAnnotators.annotators.map((annotator) => {
              const isActive = selectedAnnotators.includes(annotator);
              return (
                <Tooltip
                  title={`Step ${stepAnnotators.step}: ${annotator}`}
                  key={annotator}
                >
                  <button
                    onClick={() => handleClickAnnotator(annotator)}
                    className={classnames("rounded-full p-0.5", {
                      "ring-2 ring-primary": isActive,
                    })}
                  >
                    <UserAvatar
                      className="flex-none text-xs w-7 h-7"
                      name={annotator}
                    />
                  </button>
                </Tooltip>
              );
            })}
          </div>
        );
      })}
      <Spacer />

      {allTaskIds && allTaskIds.length > 1 && (
        <>
          <button className="button-text-secondary" onClick={onPrevious}>
            Back
          </button>
          <button className="button-text-secondary" onClick={onNext}>
            Next
          </button>
        </>
      )}
    </div>
  );
};

const Spacer = () => <div className="h-6 border-r-2" />;
const AutoSpacing = () => <div className="flex-auto" />;
