import { useTranslation } from "react-i18next";
import {
  IconArrowLeftCircle,
  IconSave,
} from "components/common/vb-icon.component";
import { IconArrowRightCircle } from "components/common/vb-icon.component";
import { useEffect, useRef, useState } from "react";
import { useCountDown } from "ahooks";
import { classnames } from "utilities/classes";
import { BatchDTO, ProjectDTO, TaskDTO } from "services/label-service/dtos";
import { BatchInfoButton } from "components/dicom/dicom-tools/batch-info-button.component";
import { Switch } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { selectIsAutoSaveConfig } from "store/labeler/complex-jobs/complex-jobs-editor-setting/complex-jobs-editor-setting.selector";
import { toggleComplexJobsAutoSave } from "store/labeler/complex-jobs/complex-jobs.slice";
import { UseAutoSaveReturnValue } from "hooks/use-auto-save.hook";

export interface SpeechToTextHeaderBarProps {
  autoSave: UseAutoSaveReturnValue
  batch: BatchDTO;
  task: TaskDTO;
  project?: ProjectDTO;
  disabledComplete: boolean;
  disabledSave: boolean;
  countDownSecond: number;
  isReady: boolean;
  isReviewStepJob?: boolean;
  onClickBack?: () => void;
  onClickSave?: () => Promise<void>;
  onClickComplete?: (accept?: boolean, reject?: boolean) => Promise<void>;
  containerClasses?: string;
  showButtons?: boolean;
  showInfo?: boolean;
}

export const SpeechToTextHeaderBar = ({
  autoSave,
  batch,
  task,
  project,
  disabledComplete,
  disabledSave,
  countDownSecond,
  isReady,
  isReviewStepJob = false,
  onClickBack,
  onClickSave,
  onClickComplete,
  containerClasses = "p-6",
  showButtons = true,
  showInfo = true,
}: SpeechToTextHeaderBarProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isEnabled, setEnabled] = useState(false);
  const buttonSaveRef = useRef<any>();
  const buttonCompleteRef = useRef<any>();
  
  const isAutoSaveConfig = useAppSelector(selectIsAutoSaveConfig)
  
  const [countdown, setTargetDate] = useCountDown({
    onEnd: () => setEnabled(true),
  });

  useEffect(() => {
    if (isReady && !isEnabled) {
      if (countDownSecond === 0) {
        setEnabled(true);
        return;
      }
      const timeLimitMs = countDownSecond * 1000;
      setTargetDate(new Date(Date.now() + timeLimitMs));
    }
  }, [isEnabled, isReady, setTargetDate, countDownSecond]);

  const handleComplete = async (accept = false, reject = false) => {
    if (onClickComplete) {
      await onClickComplete(accept, reject);
    }
    setEnabled(false);
  };

  const getInfos = () => {
    return (
      <div className="flex items-center gap-6 text-white">
        <div>Project ID: {project?.id}</div>
        <div>Batch ID: {batch.id}</div>
        <div>Task ID: {task.id}</div>
      </div>
    );
  };

  const toggleAutoSaveConfig = () => {
    dispatch(toggleComplexJobsAutoSave())
  }

  return (
    <div className={classnames("grid grid-cols-12 w-full", containerClasses)}>
      <div className="col-span-6 text-white">
        <div className="flex gap-6">
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-start gap-4">
              <div className="flex items-center gap-2">
                <span>{batch.name}</span>
                {project && <BatchInfoButton batch={batch} project={project} />}
              </div>
              <div className="flex items-center gap-2">
                <div className="flex-auto py-2">
                  {t("labelerworkspace:editorSetting.autoSave")}
                </div>
                <Switch
                  color="primary"
                  checked={!!isAutoSaveConfig}
                  onClick={toggleAutoSaveConfig}
                />
              </div>
            </div>

            {showButtons && showInfo && getInfos()}
          </div>
        </div>
      </div>
      <div className="flex items-center col-span-6">
        {!showButtons && showInfo && getInfos()}
        {showButtons && (
          <div className="flex flex-row-reverse w-full gap-3">
            {!isReviewStepJob && (
              <>
                <button
                  className="px-6 py-2 button-warn disabled:opacity-50"
                  disabled={disabledComplete || !isEnabled}
                  onClick={(_) => handleComplete()}
                  ref={buttonCompleteRef}
                >
                  {isEnabled && t("common:buttonComplete")}
                  {!isEnabled && `Complete (${Math.round(countdown / 1000)}s)`}
                  <IconArrowRightCircle />
                </button>
                <button
                  className="px-6 py-2 button-warn disabled:opacity-50"
                  disabled={disabledSave || autoSave.enabled}
                  onClick={(_) => onClickSave && onClickSave()}
                  ref={buttonSaveRef}
                >
                  {t("common:buttonSave")}
                  {autoSave && autoSave.seconds > 0 && `(${autoSave.seconds}s)`}
                  <IconSave />
                </button>
              </>
            )}
            {isReviewStepJob && (
              <>
                <button
                  className="px-6 py-2 button-warn disabled:opacity-50"
                  disabled={disabledComplete}
                  onClick={(_) => handleComplete(true, false)}
                >
                  {t("common:buttonAccept")}
                </button>
                <button
                  className="px-6 py-2 button-error disabled:opacity-50"
                  disabled={disabledComplete}
                  onClick={(_) => handleComplete(false, true)}
                >
                  {t("common:buttonReject")}
                </button>
              </>
            )}
            <button
              className="button-warn-secondary"
              onClick={(_) => onClickBack && onClickBack()}
            >
              {t("common:buttonExit")}
              <IconArrowLeftCircle />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
