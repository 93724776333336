/*
 * File: text-labeling-task.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 15th July 2022 2:51:20 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

/*
 * File: text-labeling.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 5:05:30 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { Job } from "domain/labeling/job";
import { JobService } from "services/label-service";
import { jobMapper } from "services/label-service/mappers/job.mapper";
import { RequestStatus } from "store/base/base.state";
import { textUtils } from "../text-workspace/utils/text-labeling.utils";
import { TextLabelingTaskState } from "./text-labeling-task.state";

export enum TextLabelingTaskThunk {
  TEXT_LOAD_TASK_ASYNC = "textLabelingTask/textLoadTaskAsync",
}

export const textLoadTaskAsync = createAsyncThunk(
  TextLabelingTaskThunk.TEXT_LOAD_TASK_ASYNC,
  async ({ taskId }: { taskId: number }) => {
    const task = await textUtils.getTaskById(taskId);
    const jobs: Job[] = await getJobsByTaskId(taskId);
    const { batchId, fileId } = task;
    const batch = await textUtils.getBatchById(batchId);
    const labels = await textUtils.getLabels(batchId);
    const file = await textUtils.getFileById(fileId);
    const sentences = await textUtils.getSentencesFromFile(fileId);
    const labelingDatas = await textUtils.customerGetTextAnnotationsByFile(
      batchId,
      taskId,
      fileId,
      labels
    );

    return {
      batch,
      task,
      jobs,
      file: { id: file.id, name: file.name, url: file.url, sentences },
      labels,
      labelingDatas,
    };
  }
);

async function getJobsByTaskId(taskId: number) {
  const payload = { taskId: taskId.toString(), size: "1000" };
  const response = await JobService.getItems(payload);
  if (response.data) return response.data.map(jobMapper.fromDTO);
  return [];
}

export function textLabelingTaskReducerBuilder(
  builder: ActionReducerMapBuilder<TextLabelingTaskState>
) {
  return builder
    .addCase(textLoadTaskAsync.pending, (state) => {
      state.loadingStatus = RequestStatus.LOADING;
    })
    .addCase(textLoadTaskAsync.fulfilled, (state, action) => {
      state.loadingStatus = RequestStatus.SUCCESS;
      state.batch = action.payload.batch;
      state.task = action.payload.task;
      state.jobs = action.payload.jobs;
      state.labelingDatas = action.payload.labelingDatas;
      state.file = action.payload.file;
      state.labels = action.payload.labels;
      state.annotators = action.payload.labelingDatas.map(
        (data) => data.annotator
      );
      state.selectedAssignees = action.payload.labelingDatas
        .filter(
          (data) =>
            data.annotator &&
            state.jobs.find((job) => job.id === data.jobId)?.step === 1
        )
        .map((data) => data.annotator);
    })
    .addCase(textLoadTaskAsync.rejected, (state) => {
      state.loadingStatus = RequestStatus.FAILURE;
    });
}
