import { FilterCommon } from "domain/common/filter";
import moment from "moment";
import { RequestOptions } from "services/common/request-options";


export class NotificationFilter extends FilterCommon {
  level?: string;
  type?: string;
  time?: Date;

  static toRequestOption(filter: NotificationFilter): RequestOptions {
    const options: RequestOptions = FilterCommon.toRequestOption(filter);

    if (filter.level) options["level.equals"] = filter.level;
    if (filter.type) options["type.equals"] = filter.type;
    if (filter.time) options["created_date.equals"] = moment(filter.time).utc().toDate().toISOString();

    return options;
  }

}

export interface NotificationRow {
  id: number;
  type: string;
  level: string;
  time: Date;
  info: string;
  content: string;
}