/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 13th July 2021 11:43:29 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { getAllFiles, getCloudFiles } from "./apis/get-all-files.api";
import { uploadFiles, uploadDicomSeries } from "./apis/upload-files.api";
import { uploadZip } from "./apis/upload-zip.api";
import { deleteFile } from "./apis/delete-file.api";
import { getFileInfo, getFileInfoDetails } from "./apis/get-file-info.api";
import {
  countDatasetFiles,
  countExportedDatasetFiles,
} from "./apis/count-dataset-files";
import {
  createTag,
  getAllTags,
  manageTag,
  getAllTagsMatches,
} from "./tags/tags.api";

import {
  getAllCloud,
  testAzureCloudConnection,
  testAwsCloudConnection,
  getCloudInfo,
  countCloudFiles,
  getCloudsByDataset,
  getCloudsByUser, importAzureCloudData, importAwsCloudData,
} from "./cloud/get-all-cloud.api";
import { countScopeFiles } from "./apis/count-scope-files";
import { getTextFileInfo } from "./apis/get-text-file-info.api";
import { uploadFilesId } from "./apis/upload-files-id";
import { getTextFileContent } from "./apis/get-text-file-content";
import {
  getResourceCredential,
  createStorageResource,
  getStorageResource,
  processStorageResource,
  uploadFile,
  getPublicFileContentAsBlob,
  cacheFileToLocalUrlAndAddMineType,
  getWorkspaceSasToken,
  getUrlWithWorkspaceSasToken,
  getSasUrl,
  uploadToAzureBlob,
} from "./apis/resource.api";
import { getAdditionalFileInfo, getAdditionalFileInfosFromIds } from "./apis/get-additional-file-info.api";
import { initFileInfo } from "./apis/init-file-info.api";

export const StorageService = {
  getFileInfo,
  getFileInfoDetails,
  getAdditionalFileInfo,
  getAdditionalFileInfosFromIds,
  getAllFiles,
  uploadFiles,
  uploadDicomSeries,
  uploadZip,
  deleteFile,
  getAllCloud,
  testAzureCloudConnection,
  testAwsCloudConnection,
  importAzureCloudData,
  importAwsCloudData,
  getCloudInfo,
  getCloudFiles,
  countCloudFiles,
  getCloudsByDataset,
  getCloudsByUser,
  countDatasetFiles,
  createTag,
  getAllTags,
  getAllTagsMatches,
  manageTag,
  countScopeFiles,
  countExportedDatasetFiles,
  getTextFileInfo,
  uploadFilesId,
  getTextFileContent,
  getResourceCredential,
  createStorageResource,
  getStorageResource,
  processStorageResource,
  uploadFile,
  uploadToAzureBlob,
  getPublicFileContentAsBlob,
  cacheFileToLocalUrlAndAddMineType,
  getWorkspaceSasToken,
  getUrlWithWorkspaceSasToken,
  getSasUrl,
  initFileInfo,
};
