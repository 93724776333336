/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 31st August 2021 4:33:43 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ENV_CONFIG } from "configs/env.config";
import { StorageAPIPaths } from "./config/storage-api.config";
import { ScopeAPI } from "./api/scope.api";
import { DatasetAPI } from "./api/dataset.api";
import { ExportedDatasetVersionsAPI } from "./api/exported-dataset-versions.api";
import { DatasetVersionAPI } from "./api/dataset-version.api";

let _scopeService: ScopeAPI | undefined = undefined;

let _datasetService: DatasetAPI | undefined = undefined;

let _exportedDatasetVersionService: ExportedDatasetVersionsAPI | undefined =
  undefined;

let _datasetVersionService: DatasetVersionAPI | undefined = undefined;

function getScopeService() {
  if (!_scopeService) {
    _scopeService = new ScopeAPI(
      ENV_CONFIG.STORAGE_SERVICE_URL,
      StorageAPIPaths.scopes
    );
  }
  return _scopeService;
}

function getDatasetService() {
  if (!_datasetService) {
    _datasetService = new DatasetAPI(
      ENV_CONFIG.STORAGE_SERVICE_URL,
      StorageAPIPaths.datasets
    );
  }
  return _datasetService;
}

function getExportedDatasetVersionService() {
  if (!_exportedDatasetVersionService) {
    _exportedDatasetVersionService = new ExportedDatasetVersionsAPI(
      ENV_CONFIG.STORAGE_SERVICE_URL,
      StorageAPIPaths.exportedDatasetVersions
    );
  }
  return _exportedDatasetVersionService;
}

function getDatasetVersionService() {
  if (!_datasetVersionService) {
    _datasetVersionService = new DatasetVersionAPI(
      ENV_CONFIG.STORAGE_SERVICE_URL,
      StorageAPIPaths.datasetVersion
    );
  }
  return _datasetVersionService;
}

export const StorageService = {
  getScopeService,
  getDatasetService,
  getExportedDatasetVersionService,
  getDatasetVersionService,
};
