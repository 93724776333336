/*
 * File: task-observation-v2.dto copy.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 16th May 2022 11:22:36 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Point } from "utilities/math/point";
import { AnnotationVoteStatus } from "./annotation.dto";
import {
  TextAnnotationConnection,
  TextAnnotationLabel,
} from "./text-observation.dto";

export interface TaskObservationRequestDTOV3 {
  jobId: number;
  annotations?: AnnotationRequestDTOV3[];
  annotationRelations?: ImageRelationAnnotationDTO[];
}

export interface AnnotationRequestDTOV3 {
  localId?: number;
  observationId?: number;
  annotation?: RawImageAnnotationItem &
    SttAnnotationItem &
    TtsAnnotationItem &
    SliceAnnotationItem;
}
export interface BaseTaskObservationDTO<A, R> {
  id: number;
  jobId: number;
  annotationAgreementDTOS?: AgreementDTO[];
  annotations: A[];
  annotationRelations: R[];
  taskId?: number;
  assignee?: string;
}
export interface TaskObservationV3DTO
  extends BaseTaskObservationDTO<
    ImageAnnotationDTO &
      SttAnnotationDTO &
      TtsAnnotationDTO &
      SliceAnnotationDTO,
    ImageRelationAnnotationDTO
  > {}



export interface JobAnnotations {
  annotations: ImageAnnotationDTO[];
  annotationRelations: SttAnnotationDTO[];
  annotationVotes: ImageAnnotationVoteDTO[]
}

export interface TextAnnotationDTO {
  id: number;
  annotation: {
    labels: TextAnnotationLabel[];
    relations: TextAnnotationConnection[];
  };
  observationId?: number;
  mediaId: number;
  assignee: string;
  jobId: number;
  source: string;
}

export interface TextObservationV3DTO
  extends BaseTaskObservationDTO<TextAnnotationDTO, any> {}

export interface AgreementDTO {
  id: number;
  jobId: number;
  name: string;
  score: number;
  entityScore?: number;
  relationScore?: number;
  taskObservationId: number;
  observationId: number;
}

export interface AnnotationDTOV3 {
  id?: number;
  localId?: number;
  observationId?: number;
  mediaId?: number;
  assignee?: string;
  source?: string;
  jobId?: number;
}
export interface ImageAnnotationDTO extends AnnotationDTOV3 {
  annotation?: RawImageAnnotationItem;
}
export interface ImageAnnotationVoteDTO {
  annotationId: number;
  status: AnnotationVoteStatus
}
export interface RawImageAnnotationItem {
  polygons?: AnnotationPolygonDTO[];
  bbox?: AnnotationBoundingBoxDTO;
  attributes?: AnnotationAttributeDTO[];
}
export interface AnnotationPolygonDTO {
  points: Point[];
  layer?: number;
  type?: number;
}

export interface AnnotationBoundingBoxDTO {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface AnnotationAttributeDTO {
  id: number;
  value: string[];
}

export interface ImageRelationAnnotationDTO {
  id?: number;
  directed: boolean;
  firstLocalId: number;
  first?: number;
  secondLocalId: number;
  second?: number;
  observationId?: number;
  localGroup?: number;
}

// for audio annotations
export interface SttAnnotationDTO extends AnnotationDTOV3 {
  annotation?: SttAnnotationItem;
}

export interface TtsAnnotationDTO extends AnnotationDTOV3 {
  annotation?: TtsAnnotationItem;
}
export interface SttAnnotationItem {
  text?: string;
  note?: string;
  textLabels?: SttTextLabel[];
  probability?: number;
  observationId?: number;
}
export interface SttTextLabel {
  start: number;
  end: number;
  observationId: number;
}
export interface TtsAnnotationItem {
  audioResourceId?: string;
  text?: string;
  note?: string;
  probability?: number;
  observationId?: number;
}

// 3d segmentation
export interface SliceAnnotationDTO extends AnnotationDTOV3 {
  annotation?: SliceAnnotationItem;
}
export interface SliceAnnotationItem {
  attributes?: AnnotationAttributeItem[];
  note?: string;
  resourceId?: string;
  observationId?: number;
  mask?: SliceAnnotationItemMask[];
}
export interface SliceAnnotationItemMask {
  maskValue: number;
  observationId: number;
}

export const SYSTEM_ASSIGNEE = "System";
export const IMPORT_ASSIGNEE = "Import";
export const MODEL_ASSIGNEE = "Model";

// Attributes
export interface AnnotationAttributeItem {
  id: number;
  value: string[];
}
