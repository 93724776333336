/*
 * File: label-segmentation-slider.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 24th August 2021 3:18:30 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo } from "react";
import { BatchObservationCreationModel } from "services/label-service/dtos";
import { SliderWithInput } from "./slider-with-input-.component";
import { useBuildBatchObservationCreationModelTree } from "hooks/use-observation-tree";
import { usePrevious } from "ahooks";

import { VBCheckbox } from "components/design-system/checkbox/checkbox.component";
import { TableColumn } from "components/design-system/table/types";
import { TableBase } from "components/design-system/table/table-base.component";
import { compact } from "utilities/array/compact";

interface LabelSegmentationSliderProps {
  labels: BatchObservationCreationModel[];
  isMdi?: boolean;
  requireIoU?: boolean;
  requireConsensus?: boolean;
  onChange?(labels: BatchObservationCreationModel[]): void;
}

export const LabelSegmentationSlider = ({
  labels,
  isMdi = false,
  requireIoU = true,
  requireConsensus = true,
  onChange,
}: LabelSegmentationSliderProps) => {
  const { t } = useTranslation();
  const { nodesList, updatedVisibleNodes } =
    useBuildBatchObservationCreationModelTree(labels, false);
  const previousUpdatedVisibleNodes = usePrevious(updatedVisibleNodes);

  useEffect(() => {
    if (
      updatedVisibleNodes.length <= 0 ||
      previousUpdatedVisibleNodes === updatedVisibleNodes
    )
      return;
    const newLabels = labels.map((label) => {
      const node = updatedVisibleNodes.find(
        (n) => n.value?.observation.id === label.observation.id
      );
      if (node) {
        return {
          ...label,
          visible: node.visible,
          showChildren: node.showChildren,
        };
      } else {
        return label;
      }
    });
    onChange && onChange(newLabels);
  }, [updatedVisibleNodes, previousUpdatedVisibleNodes, labels, onChange]);

  function handleIoUChange(label: BatchObservationCreationModel, iou: number) {
    const newLabels = [
      ...labels.map((lb) => {
        if (
          lb.observation &&
          label.observation &&
          lb.observation.id === label.observation.id
        ) {
          return {
            ...lb,
            iou: iou,
          };
        }
        return lb;
      }),
    ];
    onChange && onChange(newLabels);
  }

  const handleAttributeChange = useCallback(
    (attributeId: number, checked: boolean) => {
      const newLabels = labels.map((label) => {
        const existedAttribute = label.observation.attributes.find(
          (attribute) => attribute.id === attributeId
        );
        if (!existedAttribute) {
          return label;
        }

        const attributeAgreement = checked
          ? [...(label.attributeAgreement || []), attributeId]
          : label.attributeAgreement?.filter((id) => id !== attributeId);

        return {
          ...label,
          attributeAgreement,
        };
      });

      onChange && onChange(newLabels);
    },
    [labels, onChange]
  );

  const labelColumns: TableColumn[] = compact([
    {
      key: "textLabel",
      title: t("project:batch.instruction.step1.textLabel"),
      dataIndex: "observation.name",
    },
    !isMdi && requireIoU && {
      key: "textIoUThreshold",
      title: t("project:batch.textIoUThreshold"),
      render: (record: BatchObservationCreationModel) => {
        return (
          <div className="flex items-center x-full">
            <LabelIoUSlider
              onChange={(value) => handleIoUChange(record, value)}
              name={""}
              iou={record.iou}
            />
          </div>
        );
      },
    },
    isMdi && requireIoU && {
      key: "textDiceScoreThreshold",
      title: t("project:batch.textDiceScoreThreshold"),
      render: (record: BatchObservationCreationModel) => {
        return (
          <div className="flex items-center x-full">
            <LabelIoUSlider
              onChange={(value) => handleIoUChange(record, value)}
              name={""}
              iou={record.iou}
            />
          </div>
        );
      },
    },
  ]);

  const attributeColumns: TableColumn[] = [
    {
      key: "expanded-icon",
      cellProps: {
        width: "64px",
      },
    },
    {
      key: "name",
      render: (attribute) => {
        return (
          <VBCheckbox
            value={attribute.agree}
            onChange={(checked: boolean) =>
              handleAttributeChange(attribute.id, checked)
            }
          >
            <span className="text-sm text-background-700">
              {attribute.name}
            </span>
          </VBCheckbox>
        );
      },
    },
  ];

  const labelRecords = useMemo(
    () =>
      nodesList
        .filter(
          (node) => !node.value?.observation.observationSetting.systemAttribute
        )
        .map((node) => ({ ...node.value })),
    [nodesList]
  ) as BatchObservationCreationModel[];

  const getAttributeRecords = useCallback(
    (record: BatchObservationCreationModel) => {
      if (!record) return [];

      const {
        attributeAgreement,
        observation: { attributes },
      } = record;
      const newAttributes = attributes.map((attribute) => ({
        ...attribute,
        agree:
          attributeAgreement &&
          attributeAgreement.findIndex(
            (attributeId) => attribute.id === attributeId
          ) !== -1,
      }));

      return newAttributes;
    },
    []
  );

  const expandableTableProps = {
    expandedRowRender: (record: BatchObservationCreationModel) => {
      const attributeRecords = getAttributeRecords(record);
      return (
        <TableBase
          columns={attributeColumns}
          data={attributeRecords}
          header={null}
        />
      );
    },
    rowExpandable: (record: BatchObservationCreationModel) =>
      Boolean(
        record.observation?.attributes &&
          record.observation?.attributes.length > 0
      ),
  };

  return (
    <TableBase
      columns={labelColumns}
      data={labelRecords}
      expandable={requireConsensus ? expandableTableProps : null}
    />
  );
};

interface LabelIoUSliderProps {
  name: string;
  iou?: number;
  onChange?(value: number): void;
}
export const LabelIoUSlider = ({
  name,
  iou = 0,
  onChange,
}: LabelIoUSliderProps) => {
  return (
    <SliderWithInput
      name={name}
      defaultValue={iou === null || iou === undefined || isNaN(iou) ? 0 : iou}
      onChange={onChange}
    />
  );
};
