import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { usePathologyEditorContext } from "./pathology-editor.provider"


export const PathologyEditorDeleteChildrenPopupComponent = () => {
  const {
    deleteChildrenPopupVisibility,
    setDeleteChildrenPopupVisibility,
    deleteSelectedAnnotations,
  } = usePathologyEditorContext();

  const handleClickYes = () => {
    deleteSelectedAnnotations(true);
    setDeleteChildrenPopupVisibility(false);
  }

  const handleClickNo = () => {
    deleteSelectedAnnotations();
    setDeleteChildrenPopupVisibility(false);
  }

  return (
    <VBModal
      title="Delete children"
      open={deleteChildrenPopupVisibility}
      onClose={() => setDeleteChildrenPopupVisibility(false)}
      onSubmit={handleClickNo}
      additionalButtons={[{
        label: "Yes",
        value: "yes"
      }]}
      onAdditionalButtonClick={handleClickYes}
      textSubmit="No"
      disableCloseButton
    >
      <div>
        Delete all children?
      </div>
    </VBModal>
  )
}
