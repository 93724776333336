/*
 * File: auth.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { RouteData, ROUTES_CONFIG } from "../../configs/router.config";
import { RootState } from "..";
import { Routes } from "routers/config/routes";
import { GlobalRole, UserRole } from "./auth.state";

export const selectCurrentUser = (state: RootState) => state.auth.currentUser;

export const selectIsGlobalAdmin = (state: RootState) => {
  const currentUser = selectCurrentUser(state);
  return currentUser?.globalRoles?.includes(GlobalRole.GLOBAL_ADMIN);
};

export const selectUserInfo = (state: RootState) => state.auth.userInfo;

export const selectError = (state: RootState) => state.auth.error;

export const selectRoutes = (state: RootState): RouteData[] => {
  const routes: RouteData[] = [];
  const currentUser = state.auth.currentUser;
  const role = state.auth.userRole;
  if (currentUser && role) {
    for (let routePath of Object.keys(ROUTES_CONFIG)) {
      const route = ROUTES_CONFIG[routePath];
      if (route.roles && route.roles.includes(role)) {
        routes.push({
          ...route,
          path: routePath,
        });
      }
    }
  }
  return routes;
};

export const selectCustomerMenuRoutes = (state: RootState): RouteData[] => {
  const routePaths = [
    Routes.ROOT,
    Routes.PROJECTS,
    Routes.DATASETS,
    Routes.WORKFLOWS,
    Routes.MEMBERS,
    Routes.MLMODELS,
  ];
  const routes: RouteData[] = [];
  routePaths.forEach((path) => routes.push({ ...ROUTES_CONFIG[path], path }));
  return routes;
};

export const selectDefaultWorkspace = (state: RootState) => {
  const { currentUser, userRole } = state.auth;
  if (!currentUser || !currentUser.workspace || !userRole) return null;
  const workspaces = currentUser.workspace[userRole];
  if (workspaces && workspaces.length > 0) return workspaces[0];
  return null;
};

export const selectUserRole = (state: RootState) => {
  return state.auth.userRole;
};

export const selectUserWorkspace = (state: RootState) => {
  return state.auth.workspace;
};

export const selectUserHasWorkspace = (state: RootState) => {
  const { currentUser } = state.auth;
  if (!currentUser) return false;
  const roles = [UserRole.CUSTOMER, UserRole.LABELER];
  for (const role of roles) {
    if (currentUser.workspace && currentUser.workspace.hasOwnProperty(role)) {
      const workspaces = currentUser.workspace[role];
      if (workspaces.length > 0) return true;
    }
  }
  return false;
};

export const selectUserWorkspaceByRole =
  (userRole: UserRole) => (state: RootState) => {
    const workspace = state.auth.currentUser?.workspace;
    if (workspace && workspace.hasOwnProperty(userRole)) {
      return workspace[userRole];
    }
    return [];
  };
