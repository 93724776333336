import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store";
import { RelationAnnotationData } from "domain/image-labeling/relation-annotation";
import { imageAnnotationUtils } from "../image-annotations.util";
import { ImageWorkspaceState } from "../../image-workspace.state";

const THUNK_NAME = "imageWorkspace/imageRelationAnnotationCompletedAsync";

interface ImageAnotationPayload<T> {
  jobId: number;
  data: T;
}

export const imageRelationAnnotationCompletedAsync = createAsyncThunk(
  THUNK_NAME,
  async (
    payload: ImageAnotationPayload<RelationAnnotationData>,
    { getState }
  ) => {
    const state = getState() as RootState;
    const { jobId, data: annotationData } = payload;
    return imageAnnotationUtils.createNewRelationAnnotation(
      jobId,
      annotationData,
      state
    );
  }
);

export const imageRelationAnnotationCompletedBuilder = (
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) => {
  return builder.addCase(
    imageRelationAnnotationCompletedAsync.fulfilled,
    ({ imageAnnotations }, action) => {
      if (!action || !action.payload) return;
      const uuid = action.payload.uuid;
      const existedAnnotation = imageAnnotations.relationAnnotations.find(
        (anno) => anno.uuid === uuid
      );
      if (existedAnnotation) return;
      imageAnnotations.relationAnnotations.push(action.payload);
    }
  );
};
