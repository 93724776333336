/*
 * File: download-dataset.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 6th January 2022 11:30:38 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  IconCheckbox,
  IconCheckboxChecked,
} from "components/common/vb-icon.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { isImageProject } from "services/label-service/dtos";
import { classnames } from "utilities/classes";
import { useBatchDetailContext } from "../../../context/batch-detail.context";
import validator from "validator";
import { LabelingType } from "constants/labeling.constant";
import { DownloadDataSetProps } from "../../data/batch-data.provider";
import { AnnotationSource } from "domain/labeling/annotation-source";
import { useAppSelector } from "hooks/use-redux";
import {
  selectBatchSelectedTaskIds,
  selectBatchStepLabelerOptions,
} from "store/customer/batch/batch.selectors";

export const EXPORT_IMAGE_OPTIONS = [
  { label: "Scaler", value: "SCALER" },
  { label: "Yolo 1.1", value: "YOLO" },
  { label: "Coco 1.0", value: "COCO" },
];

export const EXPORT_TEXT_OPTIONS = [
  { label: "TSV", value: "TEXT_TSV" },
  { label: "JSON", value: "TEXT_JSON" },
];
export const EXPORT_TTS_OPTIONS = [{ label: "Audio and text", value: "TTS" }];
export const EXPORT_STT_OPTIONS = [{ label: "Audio and text", value: "STT" }];
export const EXPORT_THREE_D_OPTIONS = [
  { label: "Nifti with metadata", value: "MDI" },
];

const EXPORT_OPTIONS = {
  [LabelingType.IMAGE_DETECTION]: EXPORT_IMAGE_OPTIONS,
  [LabelingType.IMAGE_SEGMENTATION]: EXPORT_IMAGE_OPTIONS,
  [LabelingType.IMAGE_CLASSIFICATION]: EXPORT_IMAGE_OPTIONS,
  [LabelingType.IMAGE_OCR]: EXPORT_IMAGE_OPTIONS,
  [LabelingType.MED_XRAY_CLASSIFICATION]: EXPORT_IMAGE_OPTIONS,
  [LabelingType.MED_XRAY_DETECTION]: EXPORT_IMAGE_OPTIONS,
  [LabelingType.MED_XRAY_SEGMENTATION]: EXPORT_IMAGE_OPTIONS,
  [LabelingType.MED_MAM_SEGMENTATION]: EXPORT_IMAGE_OPTIONS,
  [LabelingType.TEXT_NER_RE]: EXPORT_TEXT_OPTIONS,
  [LabelingType.TEXT_NER]: EXPORT_TEXT_OPTIONS,
  [LabelingType.TEXT_COR]: EXPORT_TEXT_OPTIONS,
  [LabelingType.TEXT_TTS]: EXPORT_TTS_OPTIONS,
  [LabelingType.AUDIO_STT]: EXPORT_STT_OPTIONS,
  [LabelingType.MDI_SEGMENTATION]: EXPORT_THREE_D_OPTIONS,
  [LabelingType.MED_MDI_SEGMENTATION]: EXPORT_THREE_D_OPTIONS,
  [LabelingType.WSI_SEGMENTATION]: EXPORT_IMAGE_OPTIONS,
  [LabelingType.WSI_DETECTION]: EXPORT_IMAGE_OPTIONS,
};

enum ExportFilterType {
  Completed = "COMPLETED",
  Agreed = "AGREED",
  Vote = "VOTE",
}

interface Props {
  onClose(): void;
  onSubmit(payload: DownloadDataSetProps): void;
}
export const DownloadDatasetModal = ({ onClose, onSubmit }: Props) => {
  const { t } = useTranslation();
  const { project, batch } = useBatchDetailContext();
  const [isSaveImages, setIsSavesImages] = useState(false);
  const [fileName, setFileName] = useState(batch?.name || "");
  const [filterType, setExportFilter] = useState(ExportFilterType.Completed);
  const taskIds = useAppSelector(selectBatchSelectedTaskIds);
  const options = useMemo(() => {
    if (!project) return [];
    if (project?.exportTypes && project?.exportTypes.length > 0) {
      return project.exportTypes.map((option) => {
        return {
          label: option.name,
          value: option.code,
        };
      });
    }
    return EXPORT_OPTIONS.hasOwnProperty(project?.type)
      ? EXPORT_OPTIONS[project.type as LabelingType]
      : [];
  }, [project]);

  const filterOptions = useMemo(() => {
    return [
      {
        label: t("project:batchDetails.batchexport.download.filterType.all"),
        value: ExportFilterType.Completed,
      },
      {
        label: t(
          "project:batchDetails.batchexport.download.filterType.consensus"
        ),
        value: ExportFilterType.Agreed,
      },
      {
        label: t("project:batchDetails.batchexport.download.filterType.vote"),
        value: ExportFilterType.Vote,
      },
    ];
  }, [t]);

  const labelersOptions = useAppSelector(
    selectBatchStepLabelerOptions(true, false)
  );

  const labelerOptions = useMemo(() => {
    return [{ label: "All", value: "" }, ...labelersOptions];
  }, [labelersOptions]);

  const [labeler, setLabeler] = useState("");

  const sourceOptions = useMemo(() => {
    return [
      { label: "Labelers", value: AnnotationSource.CLIENT },
      { label: AnnotationSource.IMPORT, value: AnnotationSource.IMPORT },
      { label: AnnotationSource.MODEL, value: AnnotationSource.MODEL },
    ];
  }, []);

  const [source, setSource] = useState(AnnotationSource.CLIENT);

  const taskOptions = useMemo(() => {
    return [
      { label: "All tasks", value: "" },
      {
        label: "Selected tasks",
        value: "selected",
        disabled: taskIds.length === 0,
      },
    ];
  }, [taskIds]);

  const [task, setTask] = useState("");

  const [formatType, setFormatType] = useState(() => {
    if (options && options.length > 0) {
      return options[0].value;
    }
    return "";
  });

  const isValidInput = useMemo(() => {
    return !validator.isEmpty(fileName) && !validator.isEmpty(formatType);
  }, [formatType, fileName]);

  function handleSubmit() {
    if (!isValidInput) return;

    const selectedTaskIds = task === "selected" ? taskIds : [];
    const selectedLabeler =
      source === AnnotationSource.CLIENT
        ? [labeler].filter((lb) => lb.trim().length > 0)
        : [];

    onSubmit({
      formatType,
      filterType,
      name: fileName,
      isSaveImages,
      labelers: selectedLabeler,
      taskIds: selectedTaskIds,
      sources: [source],
    });
  }

  return (
    <VBModal
      title={t("project:batchDetails.batchexport.download.title", {
        name: batch?.name || "",
      })}
      textSubmit={t("common:buttonOk")}
      open
      onClose={onClose}
      onSubmit={handleSubmit}
      disableSubmit={!isValidInput}
    >
      <div className="flex flex-col gap-6">
        <div className="space-y-4">
          <VBSelectComponent
            header={t("project:batchDetails.batchexport.download.formatLabel")}
            options={options}
            menuPortalTarget={document.body}
            defaultValue={options.find((option) => option.value === formatType)}
            onChange={(val: SingleValue<any>) => setFormatType(val.value)}
          />
          <VBSelectComponent
            header={t("project:batchDetails.batchexport.download.filterText")}
            options={filterOptions}
            menuPortalTarget={document.body}
            defaultValue={filterOptions.find(
              (option) => option.value === filterType
            )}
            onChange={(val: SingleValue<any>) => setExportFilter(val.value)}
          />

          <VBSelectComponent
            header="Export source"
            options={sourceOptions}
            menuPortalTarget={document.body}
            defaultValue={sourceOptions.find(
              (option) => option.value === source
            )}
            onChange={(val: SingleValue<any>) => setSource(val.value)}
          />

          {source === AnnotationSource.CLIENT && (
            <VBSelectComponent
              header={t(
                "project:batchDetails.batchexport.download.labelerText"
              )}
              options={labelerOptions}
              menuPortalTarget={document.body}
              defaultValue={labelerOptions.find(
                (option) => option.value === labeler
              )}
              onChange={(val: SingleValue<any>) => setLabeler(val.value)}
            />
          )}

          <VBSelectComponent
            header="Export tasks"
            options={taskOptions}
            menuPortalTarget={document.body}
            defaultValue={taskOptions.find((option) => option.value === task)}
            onChange={(val: SingleValue<any>) => setTask(val.value)}
            isOptionDisabled={(option: SingleValue<any>) => option.disabled}
          />

          {isImageProject(project?.type) && (
            <button
              className={classnames("flex items-center gap-1", {
                "text-background-500": !isSaveImages,
                "text-primary": isSaveImages,
              })}
              onClick={() => setIsSavesImages(!isSaveImages)}
            >
              {isSaveImages && (
                <IconCheckboxChecked className="flex-none w-4 h-4" />
              )}
              {!isSaveImages && <IconCheckbox className="flex-none w-4 h-4" />}
              <span>
                {t("project:batchDetails.batchexport.download.txtSaveImage")}
              </span>
            </button>
          )}
        </div>

        <VBTextInputComponent
          header={t("project:batchDetails.batchexport.download.textFileName")}
          placeholder={t(
            "project:batchDetails.batchexport.download.fileNamePlaceholder"
          )}
          inputIcon={<></>}
          defaultValue={fileName}
          onChange={(event: any) => setFileName(event.target.value as string)}
          trailingElement={
            <span className="text-sm text-background-500">.zip</span>
          }
        />
      </div>
    </VBModal>
  );
};
