/*
 * File: ai-models.page.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 2nd November 2022 4:25:01 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import classNames from "classnames";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { AIModel, AIModelMetadata } from "domain/customer/ai-model";
import { MouseEvent, useState } from "react";
import { formatDateTime } from "utilities/formatter/date-formatter.utils";
import useAIModels from "./hooks/use-ai-models.hook";
import { mlServiceApis } from "services/ml-service/ml.service";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { useAppDispatch } from "hooks/use-redux";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { IconDocumentDownload } from "components/common/vb-icon.component";
import AIModelsActions from "./components/ai-models-actions.component";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
export default function AIModelsPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { aiModels, isLoading } = useAIModels();

  const [downloadingModel, setDownloadingModel] = useState<AIModel | undefined>(
    undefined
  );
  const [isDownloading, setIsDownloading] = useState(false);

  function handleSelectModel(model: AIModel, action?: string) {
    if (action === "download") {
      setDownloadingModel(model);
    }
  }

  async function handleDownloadModel() {
    if (isDownloading || !downloadingModel) return;
    try {
      setIsDownloading(true);
      const response = await mlServiceApis.downloadModel({
        model_id: downloadingModel.id,
      });
      handleThunkRejected(response);
      setIsDownloading(false);
      setDownloadingModel(undefined);
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || "Failed to generate download file.";
      dispatch(enqueueErrorNotification(errMessage));
      setIsDownloading(false);
    }
  }

  if (isLoading) {
    return <div className="p-10 mt-20 text-center">Loading...</div>;
  }

  return (
    <>
      <div className="flex flex-col h-full gap-4 animate-fade-in-up">
        <div className="flex items-center justify-between gap-4">
          <h2 className="text-2xl font-bold">{t("mlmodel:menu.textModels")}</h2>
          <AIModelsActions />
        </div>

        <AIModelsGrid
          items={aiModels || []}
          isLoading={isLoading}
          onSelect={handleSelectModel}
        />
      </div>
      {downloadingModel && (
        <VBModal
          open
          width="32rem"
          title={`Download model: ${downloadingModel.name}`}
          textSubmit="Download"
          onClose={() => setDownloadingModel(undefined)}
          onSubmit={handleDownloadModel}
          disableSubmit={isDownloading}
          processingIndicator={isDownloading}
        >
          <section className="pb-4 space-y-4">
            <div>
              This will download a zip file contains scripts to run selected
              model at your machine. please follow the instructions in README
              file
            </div>
          </section>
        </VBModal>
      )}
    </>
  );
}
interface AIModelsGridProps {
  items: AIModel[];
  isLoading?: boolean;
  onSelect(model: AIModel, action?: string): void;
}
function AIModelsGrid({ items, isLoading, onSelect }: AIModelsGridProps) {
  const columns: GridColDef[] = [
    {
      field: "id",
      filterable: false,
      renderHeader: () => <GridColumnHeader header="ID" />,
      renderCell: (params: GridCellParams) => {
        return <GridRowItem params={params} />;
      },
      minWidth: 60,
    },
    {
      field: "name",
      filterable: false,
      flex: 1,
      renderHeader: () => <GridColumnHeader header="Model name" />,
      renderCell: (params: GridCellParams) => {
        return <GridRowItem params={params} />;
      },
    },
    {
      field: "description",
      filterable: false,
      flex: 2,
      renderHeader: () => <GridColumnHeader header="Description" />,
      renderCell: (params: GridCellParams) => {
        return <GridRowItem params={params} />;
      },
    },
    {
      field: "status",
      filterable: false,
      minWidth: 120,
      renderHeader: () => <GridColumnHeader header="status" />,
      renderCell: (params: GridCellParams) => {
        return <GridRowStatus params={params} />;
      },
    },
    {
      field: "createdDate",
      filterable: false,
      flex: 1,
      renderHeader: () => <GridColumnHeader header="Created Date" />,
      renderCell: (params: GridCellParams) => {
        if (!params.value) return "";
        const value = formatDateTime(params.value as Date);
        return (
          <div className="w-full px-2 truncate" title={value}>
            {value}
          </div>
        );
      },
    },
    {
      field: "inferenceType",
      filterable: false,
      flex: 1,
      renderHeader: () => <GridColumnHeader header="Inference Type" />,
      renderCell: (params: GridCellParams) => {
        return <GridRowItem params={params} />;
      },
    },
    {
      field: "metadata",
      filterable: false,
      flex: 2,
      renderHeader: () => <GridColumnHeader header="Classes" />,
      renderCell: (params: GridCellParams) => {
        if (!params.value || !params.value.hasOwnProperty("classes")) return "";

        const value = (params.value as AIModelMetadata)?.classes
          .map((item) => item.name)
          .join(", ");

        return (
          <div className="w-full px-2 truncate" title={value}>
            {value}
          </div>
        );
      },
    },

    {
      field: "workspaceId",
      filterable: false,
      width: 120,
      renderHeader: () => <GridColumnHeader header="Actions" />,
      renderCell: (params: GridCellParams) => {
        function onClickDownload(event: MouseEvent) {
          event.preventDefault();
          event.stopPropagation();
          onSelect && onSelect(params.row as AIModel, "download");
        }
        return (
          <div
            className="flex items-center w-full h-full px-2 truncate"
            title="Actions"
          >
            <button onClick={onClickDownload}>
              <IconDocumentDownload className="flex-none w-6 h-6 text-primary" />
            </button>
          </div>
        );
      },
    },
  ];
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  return (
    <div className="w-full h-full">
      <DataGrid
        className="bg-white"
        loading={isLoading}
        rows={items}
        columns={columns}
        selectionModel={selectedIds}
        checkboxSelection
        onSelectionModelChange={(model) => setSelectedIds(model as number[])}
        headerHeight={60}
      />
    </div>
  );
}

interface GridColumnHeaderProps {
  header: string;
  className?: string;
}

function GridColumnHeader({
  header,
  className = "py-2 px-0.5 font-semibold capitalize",
}: GridColumnHeaderProps) {
  return <div className={className}>{header}</div>;
}

function GridRowItem({ params }: { params: GridCellParams }) {
  return (
    <div className="px-2 truncate" title={params.value?.toString()}>
      {params.value}
    </div>
  );
}

function GridRowStatus({ params }: { params: GridCellParams }) {
  const statusClassNames = {
    initial: "bg-background-100 text-background-500",
    importing: "bg-primary-100 text-primary-500",
    success: "bg-green-100 text-green-500",
    successed: "bg-green-100 text-green-500",
    failed: "bg-red-100 text-red-500",
    fail: "bg-red-100 text-red-500",
    error: "bg-red-100 text-red-500",
  };
  const value = params.value?.toString() || "";
  const key = value.toLowerCase();
  const statusClass = statusClassNames.hasOwnProperty(key)
    ? (statusClassNames as any)[key]
    : "";
  return (
    <div className="px-2 truncate">
      <span
        className={classNames(
          "rounded-sm text-caption-small px-1.5 py-0.5 uppercase",
          statusClass
        )}
      >
        {params.value}
      </span>
    </div>
  );
}
