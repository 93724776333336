/*
 * File: annotation-source.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 19th July 2022 10:15:53 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export enum AnnotationSource {
  CLIENT = "Client",
  IMPORT = "Import",
  MODEL = "Model",
}
