import { useState } from "react";
import { IconAdd } from "components/common/vb-icon.component";
import { useTranslation } from "react-i18next";
import { classnames } from "utilities/classes";

export interface SpeechToTextNoteProps {
  note: string;
  onNoteChanged?: (v: string) => void;
  disabled?: boolean;
}

export const SpeechToTextNote = ({
  note,
  onNoteChanged,
  disabled = false,
}: SpeechToTextNoteProps) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleAddNoteClick = () => {
    setShow(!show);
  }

  return (
    <div className="flex flex-col gap-4 w-full">
      <div>
        <button
          className={show ? "button-warn" : "button-warn-outlined"}
          onClick={handleAddNoteClick}
        >
          {t("labelerworkspace:stt.buttonAddNote")}
          <IconAdd />
        </button>
      </div>
      {
        show &&
        <textarea
          disabled={disabled}
          className={classnames(
            "w-full border rounded p-2",
            {"text-white": disabled},
          )}
          rows={4}
          value={note}
          onChange={(e) => onNoteChanged && onNoteChanged(e.target.value as string)}
        />
      }
    </div>
  )
}