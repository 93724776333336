/*
 * File: text-annotation-sources.state.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 21st October 2022 4:42:51 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useMount } from "ahooks";
import { Collection, collectionUtils } from "domain/common";
import { TextLabelingData } from "domain/labeling/labeling-data";
import { CORAnnotation, Label, NERAnnotation } from "domain/text-labeling";
import { useReducer, useMemo } from "react";
import { Sentence } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import textAnnotationsReducer, {
  AnnotationsActionType,
  defaultTextAnnotationSourcesState,
  TextAnnotationMenuItem,
} from "../reducer/text-annotations-reducer.hook";
import { TextAnnotationSourcesContext } from "./text-annotation-sources.context";

interface Props {
  children: JSX.Element;
  onClose?(): void;
  onSubmit?(annotations: NERAnnotation[], realtions: CORAnnotation[]): void;
  labels?: Label[];
  sentences: Sentence[];
  labelingDatas?: TextLabelingData[];
}

export const TextAnnotationSourcesProvider = ({
  children,
  labels = [],
  sentences = [],
  labelingDatas = [],
  onClose,
  onSubmit,
}: Props) => {
  const [annotationsState, dispatch] = useReducer(
    textAnnotationsReducer,
    defaultTextAnnotationSourcesState
  );

  useMount(() => {
    dispatch({
      type: AnnotationsActionType.INIT,
      payload: { sentences, labels, labelingDatas },
    });
  });

  const textAnnotations = useMemo(() => {
    const annotations: NERAnnotation[] = [];
    const relationAnnotations: CORAnnotation[] = [];

    for (const annotationId of annotationsState.annotations.allIds) {
      const annotation = collectionUtils.getOne(
        annotationsState.annotations,
        annotationId
      );
      if (!annotation || !annotation.selected) continue;
      annotations.push(annotation);
    }
    for (const relationId of annotationsState.relations.allIds) {
      const relation = collectionUtils.getOne(
        annotationsState.relations,
        relationId
      );
      if (!relation || !relation.selected) continue;
      relationAnnotations.push(relation);
    }

    return { annotations, relationAnnotations };
  }, [annotationsState]);

  const annotationMenuItems: TextAnnotationMenuItem[] = useMemo(() => {
    const itemsCollection: Collection<TextAnnotationMenuItem> = {
      allIds: [],
      entities: {},
    };
    const labels = collectionUtils.values(annotationsState.labels);
    for (const label of labels) {
      const item: TextAnnotationMenuItem = {
        id: label.id,
        name: label.name,
        selected: label.selected,
        type: "LABEL",
        children: [],
      };
      collectionUtils.addOne(itemsCollection, item);
    }

    for (const annotationId of annotationsState.annotations.allIds) {
      const annotation = collectionUtils.getOne(
        annotationsState.annotations,
        annotationId
      );
      if (!annotation) continue;
      const label = collectionUtils.getOne(
        itemsCollection,
        annotation.observationId
      );
      if (!label) continue;
      label.children?.push({
        id: annotation.id,
        name: annotation.id,
        selected: annotation.selected,
        hidden: annotation.hidden,
        children: [],
        type: "ANNOTATION",
      });
    }

    for (const relationId of annotationsState.relations.allIds) {
      const relation = collectionUtils.getOne(
        annotationsState.relations,
        relationId
      );
      if (!relation) continue;
      const label = collectionUtils.getOne(
        itemsCollection,
        relation.observationId
      );
      if (!label) continue;
      label.children?.push({
        id: relation.id,
        name: relation.id,
        selected: relation.selected,
        hidden: relation.hidden,
        children: [],
        type: "RELATION",
      });
    }

    return collectionUtils
      .values(itemsCollection)
      .filter((item) => item.children.length > 0);
  }, [annotationsState]);

  function selectAnnotator(annotator: string) {
    dispatch({
      type: AnnotationsActionType.SELECT_ANNOTATOR,
      payload: annotator,
    });
  }

  function selectLabel(labelId: string, action?: string) {
    dispatch({
      type: AnnotationsActionType.SELECT_LABEL,
      payload: { id: labelId, action },
    });
  }

  function selectAnnotation(annotationId: string, action?: string) {
    dispatch({
      type: AnnotationsActionType.SELECT_ANNOTATION,
      payload: { id: annotationId, action },
    });
  }

  function handleSubmit() {
    const annotations = [];
    const relations = [];
    for (const id of annotationsState.annotations.allIds) {
      const entity = collectionUtils.getOne(annotationsState.annotations, id);
      if (entity?.selected) annotations.push(entity);
    }
    for (const id of annotationsState.relations.allIds) {
      const entity = collectionUtils.getOne(annotationsState.relations, id);
      if (entity?.selected) relations.push(entity);
    }
    onSubmit && onSubmit(annotations, relations);
  }

  const state = {
    labels,
    labelingDatas,
    annotationMenuItems,
    annotators: collectionUtils.values(annotationsState.annotators),
    textAnnotations,
    sentences,
    onClose,
    selectAnnotator,
    selectLabel,
    selectAnnotation,
    onSubmit: handleSubmit,
  };

  return (
    <TextAnnotationSourcesContext.Provider value={state}>
      {children}
    </TextAnnotationSourcesContext.Provider>
  );
};
