

export interface UserInfoDTO {
    id: number;
    preferredUsername: string;
    name: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    address: string;
    companyName: string;
    website: string;
    companyAddress: string;
    companySize: string;
    language: string;
}

export enum CompanySize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}