import { useTranslation } from "react-i18next";

export const useDateToAgoString = (from: Date | undefined, to: Date | undefined) => {
  const { t } = useTranslation();
  if (!to || !from) return "";
  
  const diffSeconds = Math.floor((to.getTime() - from.getTime()) / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);

  if (diffDays > 0) return t("common:diffTimeAgo", {unit: diffDays > 1 ? "d" : "d", num: diffDays});
  if (diffHours > 0) return t("common:diffTimeAgo", {unit: diffHours > 1 ? "h" : "h", num: diffHours});
  if (diffMinutes > 0) return t("common:diffTimeAgo", {unit: diffMinutes > 1 ? "m" : "m", num: diffMinutes});
  if (diffSeconds >= 0) return t("common:diffTimeAgo", {unit: diffSeconds > 1 ? "s" : "s", num: diffSeconds});

  return "";
}