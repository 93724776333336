/*
 * File: workflow-instruction.reducer.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 17th July 2021 11:53:54 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { WorkflowInstructionDTO } from "services/label-service/dtos";
import { AtLeast } from "types/common";
import { ProjectState } from "../project.state";

export const workflowInstructionReducer = {
  resetWorkflowInstructions: (state: ProjectState) => {
    state.workflowInstructions = [];
  },
  setWorkflowInstructions: (
    state: ProjectState,
    action: PayloadAction<WorkflowInstructionDTO[]>
  ) => {
    state.workflowInstructions = action.payload;
  },
  addWorkflowInstruction: (
    state: ProjectState,
    action: PayloadAction<WorkflowInstructionDTO>
  ) => {
    state.workflowInstructions.push(action.payload);
  },
  updateWorkflowInstruction: (
    state: ProjectState,
    action: PayloadAction<AtLeast<WorkflowInstructionDTO, "id">>
  ) => {
    state.workflowInstructions = state.workflowInstructions.map(
      (workflowInstruction) => {
        if (workflowInstruction.id === action.payload.id) {
          return {
            ...workflowInstruction,
            ...action.payload,
          };
        }
        return workflowInstruction;
      }
    );
  },
  removeWorkflowInstruction: (
    state: ProjectState,
    action: PayloadAction<number>
  ) => {
    state.workflowInstructions = state.workflowInstructions.filter(
      (p) => p.id !== action.payload
    );
  },
};
