/*
 * File: use-dataset-files-count.hook.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 23rd September 2021 3:37:05 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useScope } from "hooks/scope/use-scope.hook";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StorageService } from "services/storage";
import { RequestStatus } from "store/base/base.state";
import { Logger } from "utilities/logger";
import * as Sentry from "@sentry/react";

export const useDatasetFilesCount = (datasetId: number) => {
  const { t } = useTranslation();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [totalFiles, setTotalFiles] = useState(0);
  const { scopes } = useScope();
  useEffect(() => {
    const requestData = async (id: number, scopeIds: number[]) => {
      if (requestStatus === RequestStatus.LOADING) return;
      try {
        setRequestStatus(RequestStatus.LOADING);
        setRequestError(null);
        setTotalFiles(0);
        const response = await StorageService.countExportedDatasetFiles(
          id,
          scopeIds
        );
        setTotalFiles(response.data);
        setRequestStatus(RequestStatus.SUCCESS);
      } catch (err: any) {
        Sentry.captureException(err);
        Logger.error(err);
        const errMessage = err.message || t("dataset:countFilesErrorMessage");
        setRequestError(errMessage);
      }
    };
    if (
      datasetId > 0 &&
      requestStatus === RequestStatus.IDLE &&
      scopes.length > 0
    )
      requestData(
        datasetId,
        scopes.map((scope) => scope.id)
      );
  }, [datasetId, requestStatus, t, scopes]);

  return { totalFiles: totalFiles, requestStatus, requestError };
};
