/*
 * File: image-toolbar.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 16th December 2021 11:10:39 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { LightTooltip } from "components/material/mat-light-tooltip.component";
import { classnames } from "utilities/classes";

interface ImageTool {
  activeIcon?: JSX.Element;
  icon?: JSX.Element;
  options?: {
    id: string;
    name: string;
    icon?: JSX.Element;
  }[];
  activeOptionId?: string;
  tooltip?: string;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  inactiveClass?: string;
}

interface Props {
  tool: ImageTool;
  onSelect(): void;
}
export const ImageToolbar = ({ tool, onSelect }: Props) => {
  const className = tool.className || "";
  const inactiveClass = tool.inactiveClass || "bg-gray-600 hover:bg-gray-400";
  return (
    <LightTooltip
      title={tool.tooltip || ""}
      arrow
      placement="right"
      disableFocusListener={tool.disabled}
    >
      <button
        disabled={tool.disabled}
        onClick={onSelect}
        className={classnames(
          "w-12 h-12 rounded flex justify-center items-center cursor-pointer focus:outline-none relative",
          {
            [inactiveClass]: !tool.selected,
            "text-white bg-warning-500": !!tool.selected,
            "pointer-events-none cursor-not-allowed opacity-50": !!tool.disabled,
          },
          className
        )}
      >
        {tool.icon}
      </button>
    </LightTooltip>
  );
};
