/*
 * File: vb-keyboard-key.component.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 16th April 2022 3:11:41 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

interface Props {
  text: string;
  className?: string;
}
export function VBKeyboardKey({
  text,
  className = "text-white bg-coolGray-700",
}: Props) {
  return (
    <div
      className={`flex items-center justify-center flex-none w-6 h-6 text-sm font-semibold rounded ${className}`}
    >
      {text}
    </div>
  );
}
