export enum ActivityStatus {
  Active = "ACTIVE",
}

export interface CreateActivityPayload {
  id?: string;
  code: ActivityStatus,
  batchId?: number,
  jobId?: number
}

export type CreateActivityResponseDTO = CreateActivityPayload;
