import { useMemo } from "react";
import { useThreeDLabelingContext } from "../context/three-d-labeling.context";
import { useThreeDDiceScores } from "../hooks/use-threed-dicescore";
import { truncateEmail } from "utilities/string/truncate-email";

export const ThreeDEditorDiceScoresComponent = () => {
  const { uiJobRef } = useThreeDLabelingContext();

  const taskId = useMemo(() => {
    if (!uiJobRef.current) return -1;
    if (uiJobRef.current.isTaskReview && uiJobRef.current.task) {
      return uiJobRef.current.task.id;
    }
    return -1;
  }, [uiJobRef]);

  const { data } = useThreeDDiceScores(taskId);

  if (!data || data.length === 0) return null;

  return (
    <section>
      <h3 className="font-bold">Dice Scores</h3>
      <div className="text-sm">
        {data.map((item) => {
          const ids = item.annotations.map((anno) => anno.id).join(",");

          return (
            <div key={ids}>
              <div>
                <span>{item.metric.value} | </span>
                <span>
                  {item.annotations
                    .map((anno) => truncateEmail(anno.annotator))
                    .join(" & ")}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
