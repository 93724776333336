import { JobDTO } from "../dtos/job.dto";
import { AnnotationRequest } from "../dtos/task-observation-v2.dto";
import { BaseAPI } from "./base.api";

export class JobV2API extends BaseAPI<JobDTO> {
  finishJobBulk(payload: AnnotationRequest[]) {
    return this.post(`${this.getPath()}/finish`, payload);
  }
  finishTextJobBulk(payload: any) {
    return this.post(`${this.getPath()}/finish`, payload);
  }
}
