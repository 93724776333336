/*
 * File: use-consensus-options.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 14th July 2023 1:55:35 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Option } from "domain/common";
import { useEffect, useMemo, useState } from "react";
import { isMDIProject } from "services/label-service/dtos";

export function useConsensusOptions(projectType = "", defaultValue = "") {
  const options = useMemo<Option[]>(() => {
    if (isMDIProject(projectType)) {
      return [
        { label: "Dice score greater than", value: "$gt" },
        { label: "Dice score less than", value: "$lt" },
      ];
    }
    return [
      { label: "IoU greater than", value: "$gt" },
      { label: "IoU less than", value: "$lt" },
    ];
  }, [projectType]);

  const [value, setValue] = useState(defaultValue);

  const option = useMemo(() => {
    return options.find((item) => item.value === value);
  }, [options, value]);

  function onChange(newOption?: any) {
    if (newOption && newOption.hasOwnProperty("value")) {
      setValue(newOption["value"]);
    } else {
      setValue("");
    }
  }

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return { options, value, setValue, option, onChange };
}

export default useConsensusOptions;
