/*
 * File: vb-breadcrumb.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 18th August 2021 11:49:06 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Breadcrumb } from "./breadcrumb.model";
import { BreadcrumbItem } from "./vb-breadcrumb-item.component";

interface VBBreadcrumbProps {
  breadcrumbs: Breadcrumb[];
}

export const VBBreadcrumb = ({ breadcrumbs }: VBBreadcrumbProps) => {
  if (breadcrumbs.length === 0) return null;
  return (
    <div className="flex items-center gap-1 py-1">
      {breadcrumbs.map((breadcrumb, index) => {
        return <BreadcrumbItem breadcrumb={breadcrumb} key={index} />;
      })}
    </div>
  );
};
