import { useState } from "react";
import { ModalContext } from "./modal.context";
import { ModalTypes } from "./modal.state";
import { CreateDatasetDialog } from "pages/customer/datasets/components/create-dataset/create-dataset-dialog.component";
import { CreateWorkspaceDialog } from "pages/customer/workspace/components/create-workspace-dialog.component";
import { TicketSupportModal } from "components/ticket-management/ticket-support.modal";
import { TicketSupportSettingModal } from "components/ticket-management/ticket-support-setting.modal";
import { TicketDetailModal } from "components/ticket-management/ticket-detail.modal";
import { TicketListModal } from "components/ticket-management/ticket-list.modal";
import { DeleteWorkspaceModal } from "components/modals";
import { UpdateLabelModal } from "./components/update-label.modal";
import { ThreeDSelectLayout } from "./components/three-d-select-layout.modal";
import ReopenTaskModal from "./components/reopen-task.modal";
import SkipTaskModal from "./components/skip-task.modal";
import ManageFileMetadataModal from "./components/manage-file-metadata.modal";
import { DeleteFilesModal } from "./components/delete-files.modal";
import RemoveFileMetadataModal from "./components/remove-file-metadata.modal";
import MoveFilesToNewDatasetModal from "./components/move-files-to-new-folder.modal.component";
import MoveFilesToFolder from "./components/move-files-to-folder.modal.component";
import { MergeDatasourcesModal } from "./components/merge-datasources.modal";
import { DeleteDatasourcesModal } from "./components/delete-datasources.modal";
import { DuplicateDatasourceModal } from "./components/duplicate-datasource.modal";

interface ModalProviderProps {
  children: JSX.Element;
}

const MODAL_COMPONENTS: any = {
  [ModalTypes.UPDATE_LABEL]: UpdateLabelModal,
  [ModalTypes.CREATE_WORKSPACE]: CreateWorkspaceDialog,
  [ModalTypes.CREATE_DATASOURCE]: CreateDatasetDialog,
  [ModalTypes.MERGE_DATASOURCES]: MergeDatasourcesModal,
  [ModalTypes.DELETE_DATASOURCES]: DeleteDatasourcesModal,
  [ModalTypes.DUPLICATE_DATASOURCE]: DuplicateDatasourceModal,
  [ModalTypes.TICKET_SUPPORT]: TicketSupportModal,
  [ModalTypes.TICKET_SUPPORT_SETTING]: TicketSupportSettingModal,
  [ModalTypes.TICKET_LIST]: TicketListModal,
  [ModalTypes.TICKET_DETAIL]: TicketDetailModal,
  [ModalTypes.DELETE_WORKSPACE]: DeleteWorkspaceModal,
  [ModalTypes.THREE_D_SELECT_LAYOUT]: ThreeDSelectLayout,
  [ModalTypes.REOPEN_TASK]: ReopenTaskModal,
  [ModalTypes.SKIP_TASK]: SkipTaskModal,
  [ModalTypes.MANAGE_FILE_METADATA]: ManageFileMetadataModal,
  [ModalTypes.DELETE_FILES]: DeleteFilesModal,
  [ModalTypes.MOVE_FILES_TO_NEW_FOLDER]: MoveFilesToNewDatasetModal,
  [ModalTypes.MOVE_FILES_TO_FOLDER]: MoveFilesToFolder,
  [ModalTypes.REMOVE_FILE_METADATA]: RemoveFileMetadataModal,
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modalType, setModalType] = useState<ModalTypes>();
  const [modalProps, setModalProps] = useState({});

  const openModal = (modalType: ModalTypes, modalProps: any = {}) => {
    setModalType(modalType);
    setModalProps(modalProps);
  };

  const closeModal = () => {
    setModalType(undefined);
    setModalProps({});
  };

  const state = {
    openModal,
    closeModal,
  };

  const renderComponent = () => {
    if (!modalType) return null;
    const ModalComponent = MODAL_COMPONENTS[modalType];

    if (!ModalComponent) return null;
    return (
      <ModalComponent
        id="global-modal"
        open
        onClose={closeModal}
        {...modalProps}
      />
    );
  };

  return (
    <ModalContext.Provider value={state}>
      {renderComponent()}
      {children}
    </ModalContext.Provider>
  );
};
