/*
 * File: text-annotation-sources.context.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 21st October 2022 4:45:50 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { TEXT_ANNOTATION_SOURCES_INITIAL_STATE } from "./text-annotation-sources.state";

export const TextAnnotationSourcesContext = createContext(
  TEXT_ANNOTATION_SOURCES_INITIAL_STATE
);

export function useTextAnnotationSourcesContext() {
  return useContext(TextAnnotationSourcesContext);
}
