/*
 * File: vb-table-paging.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 16th February 2022 4:27:41 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  IconChevronLeft,
  IconChevronRight,
} from "components/common/vb-icon.component";
import { useMemo, useState } from "react";
import { classnames } from "utilities/classes";

export interface VBTablePagingProps {
  defaultPageIndex?: number;
  totalItem?: number;
  pageSize?: number;
  onPageIndexChanged?: (value: number) => void;
}

export const VBTablePaging = ({
  defaultPageIndex = 1,
  totalItem = 0,
  pageSize = 5,
  onPageIndexChanged,
}: VBTablePagingProps) => {
  const [currentPage, setCurrentPage] = useState(defaultPageIndex);
  const MAX_PAGES_TO_DISPLAY = 4;

  const numPage = useMemo(() => {
    let res = Math.floor(totalItem / pageSize);
    if (totalItem % pageSize > 0 || !totalItem) res++;
    return res;
  }, [totalItem, pageSize]);

  const pagesToDisplay: number[] = useMemo(() => {
    const pages: number[] = [1];
    let startPage = 2;
    let maxStart = numPage - (MAX_PAGES_TO_DISPLAY - 2);
    if (maxStart < 2) maxStart = 2;
    if (currentPage > 1 && currentPage <= maxStart) {
      startPage = currentPage;
    } else if (currentPage > maxStart) {
      startPage = maxStart;
    }
    for (let pageIndex = startPage; pageIndex < numPage; pageIndex++) {
      if (pages.length < MAX_PAGES_TO_DISPLAY - 1) {
        pages.push(pageIndex);
      } else {
        break;
      }
    }
    if (numPage > 1) {
      pages.push(numPage);
    }
    return pages;
  }, [currentPage, numPage]);

  const pagesToDisplayStrs: string[] = useMemo(() => {
    const pagesStrs: string[] = pagesToDisplay.map((pageIndex) =>
      pageIndex.toString()
    );
    if (pagesStrs.length === MAX_PAGES_TO_DISPLAY) {
      pagesStrs.splice(MAX_PAGES_TO_DISPLAY - 1, 0, "...");
    }
    return pagesStrs;
  }, [pagesToDisplay]);

  const handleClickPreviousNext = (direction: number) => {
    const newCurrentpage = currentPage + direction;
    if (
      newCurrentpage > 0 &&
      newCurrentpage !== currentPage &&
      newCurrentpage <= numPage
    ) {
      setCurrentPage(newCurrentpage);
      onPageIndexChanged && onPageIndexChanged(newCurrentpage);
    }
  };

  const handleClickPageStr = (pageIndexStr: string) => {
    if (pageIndexStr !== "...") {
      const newCurrentpage = parseInt(pageIndexStr);
      setCurrentPage(newCurrentpage);
      onPageIndexChanged && onPageIndexChanged(newCurrentpage);
    }
  };

  return (
    <div className="flex w-full items-center py-4">
      <div className="flex-1"></div>
      <div className="flex px-1 gap-4 text-sm">
        <div className="flex items-center gap-1">
          <IconChevronLeft className="w-4 h-4" />
          <span
            className="cursor-pointer"
            onClick={(_) => handleClickPreviousNext(-1)}
          >
            Prev
          </span>
        </div>

        <div className="flex">
          {pagesToDisplayStrs.map((pageIndexStr, index) => {
            return (
              <div
                className={classnames(
                  "flex items-center justify-center w-8 h-8",
                  {
                    "bg-background-100":
                      pageIndexStr === currentPage.toString(),
                  }
                )}
                key={index}
              >
                <span
                  className="cursor-pointer hover:text-blue-700"
                  onClick={(_) => handleClickPageStr(pageIndexStr)}
                >
                  {pageIndexStr}
                </span>
              </div>
            );
          })}
        </div>

        <div className="flex items-center gap-1">
          <span
            className="cursor-pointer"
            onClick={(_) => handleClickPreviousNext(1)}
          >
            Next
          </span>
          <IconChevronRight className="w-4 h-4" />
        </div>
      </div>
    </div>
  );
};
