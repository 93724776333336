import { createAsyncThunk } from "@reduxjs/toolkit";
import { AnnotateType } from "constants/annotation.constant";
import { RootState } from "store";
import { Point } from "utilities/math/point";
import { v4 } from "uuid";
import { selectImageLabelingActiveJobId } from "../../batch-labeling/batch-labeling.selectors";
import { imageAnnotationUtils } from "../../image-annotations/image-annotations.util";

const THUNK_NAME = "imageWorkspace/createAnnotationAsync";
interface CreateAnnotationPayload {
  type: AnnotateType;
  labelId: string;
  points: Point[];
  probability: number;
}
export const createAnnotationAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: CreateAnnotationPayload, { getState }) => {
    const state = getState() as RootState;
    const jobId = selectImageLabelingActiveJobId(state);
    const annotationData = {
      uuid: v4(),
      type: payload.type,
      points: payload.points,
    };
    return imageAnnotationUtils.createNewAnnotation(
      jobId,
      [annotationData],
      state
    );
  }
);
