import { AnalyticsQueryResponse } from "services/analytics-service";
import { CardRowDataModel } from "../context/dashboard.state";

const COL_ZERO_TIME = "zerotime";
const COL_ONE_TIME = "onetime";
const COL_TWO_TIME = "twotime";

export const DEFAULT_DATA: CardRowDataModel[] = [
  {
    name: "By 1 labeler",
    total: 0,
  },
  {
    name: "By 2 labeler, or more",
    total: 0,
  },
  {
    name: "Not yet labeled",
    total: 0,
  },
]

export const cardRowDatasourceMapper = (response: AnalyticsQueryResponse): CardRowDataModel[] => {
  const rows = response.data.rows;
  if (rows && rows.length > 0){
    const firstRowData: any = rows[0];
    const resRows = DEFAULT_DATA.map(item => ({...item}));

    resRows[0].total = firstRowData[COL_ONE_TIME] || 0;
    resRows[1].total = firstRowData[COL_TWO_TIME] || 0;
    resRows[2].total = firstRowData[COL_ZERO_TIME] || 0;

    return resRows;
  }
  return DEFAULT_DATA;
}