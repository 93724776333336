import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { LabelingType } from "constants/labeling.constant";
import { SpeechToTextLabelingProvider } from "pages/labeler/speech-to-text-labeling/context/speech-to-text-labeling.provider";
import { SpeechToTextLabelingDetails } from "pages/labeler/speech-to-text-labeling/speech-to-text-labeling.details";
import { TextToSpeechLabelingProvider } from "pages/labeler/text-to-speech-labeling/context/text-to-speech-labeling.provider";
import { TextToSpeechLabelingDetails } from "pages/labeler/text-to-speech-labeling/text-to-speech-labeling.details";
import { useTranslation } from "react-i18next";
import { BatchDTO, TaskDTO } from "services/label-service/dtos";
import { IssueButtons } from "../issue/issue-buttons.component";
import { TaskReviewReopenButtons } from "../task-review-reopen-buttons.component";

interface Props {
  open: boolean;
  task: TaskDTO;
  batch: BatchDTO;
  jobIdsIncludedOnly?: number[];
  onClose?: () => void;
  onNext?: () => void;
  title?: string;
  showReopenButtons?: boolean;
  showIssues?: boolean;
}

export const TaskReviewAudio = ({
  open,
  task,
  batch,
  jobIdsIncludedOnly,
  onClose,
  onNext,
  title,
  showReopenButtons,
  showIssues = true,
}: Props) => {
  const { t } = useTranslation();

  return (
    <VBModal
      width={"1400px"}
      title={title ? title : `Task review #${task.id}`}
      open={open}
      onClose={onClose}
      footerHidden
    >
      <div className="flex flex-col gap-6">
        <div className="relative w-full rounded">
          <div
            className="relative w-full overflow-y-auto bg-background-900 hide-scrollbar"
            style={{
              height: "80vh",
            }}
          >
            {batch.project.type === LabelingType.AUDIO_STT && (
              <SpeechToTextLabelingProvider
                isTaskReview
                taskToReview={task}
                jobIdsIncludedOnly={jobIdsIncludedOnly}
              >
                <SpeechToTextLabelingDetails
                  showMenuIssue={false}
                  showIssues={showIssues}
                />
              </SpeechToTextLabelingProvider>
            )}
            {batch.project.type === LabelingType.TEXT_TTS && (
              <TextToSpeechLabelingProvider
                isTaskReview
                taskToReview={task}
                jobIdsIncludedOnly={jobIdsIncludedOnly}
              >
                <TextToSpeechLabelingDetails
                  showMenuIssue={false}
                  showIssues={showIssues}
                />
              </TextToSpeechLabelingProvider>
            )}
          </div>
          {showIssues && <IssueButtons position="bottomLeft" />}
        </div>
        <div className="z-10 flex items-center flex-none w-full gap-4">
          {showReopenButtons && (
            <TaskReviewReopenButtons
              batch={batch}
              task={task}
              onApproved={onClose}
              onRejected={onClose}
            />
          )}

          {!showReopenButtons && <div className="flex-1"></div>}

          <button
            onClick={onNext}
            className="button-text-primary disabled:opacity-60"
          >
            <span>{t("common:buttonNext")}</span>
          </button>
        </div>
      </div>
    </VBModal>
  );
};
