import { AnalyticsQueryResponse } from "services/analytics-service";
import { CardRowDataModel } from "../context/dashboard.state";

const COL_INVITED = "invited";
const COL_ACTIVE = "active";
const COL_INACTIVE = "inactive";
const COL_AVAILABLE = "available";

export const DEFAULT_DATA: CardRowDataModel[] = [
  {
    name: "Invited",
    total: 0,
  },
  {
    name: "Active",
    total: 0,
  },
  {
    name: "Inactive",
    total: 0,
  },
  {
    name: "Available",
    total: 0,
  },
];

export const cardRowLabelerMapper = (response: AnalyticsQueryResponse): CardRowDataModel[] => {
  const rows = response.data.rows;
  if (rows && rows.length > 0){
    const firstRowData: any = rows[0];
    const resRows = DEFAULT_DATA.map(item => ({...item}));

    resRows[0].total = firstRowData[COL_INVITED] || 0;
    resRows[1].total = firstRowData[COL_ACTIVE] || 0;
    resRows[2].total = firstRowData[COL_INACTIVE] || 0;
    resRows[3].total = firstRowData[COL_AVAILABLE] || 0;

    return resRows;
  }
  return DEFAULT_DATA;
}