/*
 * File: private-route.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 2nd August 2021 11:27:32 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { MainLayout } from "components/layout/main-layout/main-layout.component";
import { ROUTES_CONFIG, PageLayout } from "configs/router.config";
import { useAppSelector } from "hooks/use-redux";
import { UnAuthorizedPage } from "pages/authorization/unauthorized/unauthorized.page";
import { LabelerLayoutComponent } from "pages/labeler/components/labeler-layout/labeler-layout.component";
import { RouteProps, Route } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { selectUserRole } from "store/auth/auth.selectors";
import { UserRole } from "store/auth/auth.state";

function hasPermission(path: Routes, userRole?: UserRole) {
  if (!userRole) return false;
  const routeConfig = ROUTES_CONFIG[path];
  return (
    routeConfig && routeConfig.roles && routeConfig.roles.includes(userRole)
  );
}

function getLayout(path: Routes) {
  return ROUTES_CONFIG[path].layout;
}

export const PrivateRoute = (props: RouteProps) => {
  const userRole = useAppSelector(selectUserRole);
  const hasAccess = hasPermission(props.path as Routes, userRole);
  if (!hasAccess) return <UnAuthorizedPage />;
  const layout = getLayout(props.path as Routes);
  switch (layout) {
    case PageLayout.MainLayout:
      return (
        <MainLayout>
          <Route {...props} />
        </MainLayout>
      );
    case PageLayout.LabelerLayout:
      return (
        <LabelerLayoutComponent>
          <Route {...props} />
        </LabelerLayoutComponent>
      );
    case PageLayout.FullPage:
      return <Route {...props} />;
    default:
      return (
        <div className="p-4">
          <h1 className="text-3xl font-bold text-red-500">
            Invalid layout for path {props.path}
          </h1>
        </div>
      );
  }
};
