/*
 * File: text-labeling-batch.state.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 12th July 2022 10:47:12 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export interface TextLabelingBatchState {
  isSavingJob: boolean;
  isCompletingJob: boolean;
  saveWorkingJob(): Promise<any>;
  completeWorkingJob(): Promise<any>;
}

export const defaultTextLabelingBatchState = {} as TextLabelingBatchState;
