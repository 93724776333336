/*
 * File: image-annotation-sources.context.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 18th October 2022 2:24:15 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { IMAGE_ANNOTATION_SOURCES_INITIAL_STATE } from "./image-annotation-sources.state";

export const ImageAnnotationSourcesContext = createContext(
  IMAGE_ANNOTATION_SOURCES_INITIAL_STATE
);

export function useImageAnnotationSourcesContext() {
  return useContext(ImageAnnotationSourcesContext);
}
