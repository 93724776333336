/*
 * File: text-label-list.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 28th October 2021 2:56:57 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Label } from "domain/text-labeling";
import { TextLabelItem } from "./text-label-item.component";

interface Props {
  labels: Label[];
  selectedLabelId: string;
  activeLabelIds: string[];
  onSelect(labelId: string): void;
}

export const TextLabelList = (props: Props) => {
  const { labels, selectedLabelId, activeLabelIds, onSelect } = props;
  return (
    <div>
      {labels.map((label) => {
        return (
          <TextLabelItem
            selected={label.id === selectedLabelId}
            key={label.id}
            label={label}
            isLeafNodeItem={true}
            paddingLeft={0}
            onSelect={onSelect}
            disabled={label.disableSelection}
            checked={activeLabelIds.includes(label.id)}
          />
        );
      })}
    </div>
  );
};
