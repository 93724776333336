/*
 * File: job.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 11th July 2022 9:29:32 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { JobDTO, StepType } from "services/label-service/dtos";

export interface Job {
  id: number;
  assignee: string;
  batchId: number;
  status: string;
  step: number;
  taskId: number;
  workspaceId: string;
  type: JobType;
  canViewPreviousJob?: boolean;
  stepType: StepType;
  dto?: JobDTO;
}

export enum JobType {
  LABELER = "labeler",
  SUPERVISOR = "supervisor",
  REVIEWER = "reviewer",
  CUSTOMER = "customer",
}
