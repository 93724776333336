/*
 * File: batch-tasks.view.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  selectIsAudioSpeechToTextProject,
  selectIsImageProject,
  selectIsMdiSegmentation,
  selectIsMedicalMamoProject,
  selectIsTextLabelingProject,
  selectIsTextTextToSpeechProject,
  selectIsWsiProject,
} from "store/customer/project/project.selectors";
import { DeleteTaskDialog } from "./components/delete-task.dialog";
import { TasksReviewComponent } from "./components/tasks-review.component";
import { TaskAction } from "services/label-service/dtos";
import { BatchTasksTable } from "./components/tasks-table.component";
import { useBatchTaskContext } from "./batch-tasks.context";
import { TaskReviewAudio } from "./components/audio/task-review-audio.component";
import { TextTaskReviewComponent } from "components/text-labeling/text-task-review/text-task-review.component";
import { TaskReviewThreeD } from "./components/3d/task-review-three-d.component";
import { AssignJobModal } from "./components/assign-job.modal";
import { useTranslation } from "react-i18next";
import { TextTaskProvider } from "components/text-labeling/text-task-context/text-task.provider";
import { MammographyTaskReviewComponent } from "components/mammography-labeling/mammography-task-review/mammography-task-review.component";
import { MammographyTaskProvider } from "components/mammography-labeling/mammography-task-context/mammography-task.provider";
import { useBatchDetailContext } from "../../context/batch-detail.context";
import {
  selectBatchTaskAction,
  selectIsLoadingBatchTasks,
  selectProjectTotalTasks,
} from "store/customer/batch/batch.selectors";
import {
  batchNextTaskSelected,
  batchPreviousTaskSelected,
  batchTaskActionSelected,
} from "store/customer/batch/batch.slice";
import { TaskReviewPathology } from "./components/pathology/task-review-pathology.component";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";

export const BatchTasksPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMammographyProject = useAppSelector(selectIsMedicalMamoProject);
  const isImageProject = useAppSelector(selectIsImageProject);
  const isSTTProject = useAppSelector(selectIsAudioSpeechToTextProject);
  const isTTSProject = useAppSelector(selectIsTextTextToSpeechProject);
  const isTextLabelingProject = useAppSelector(selectIsTextLabelingProject);
  const isMdiSegmentation = useAppSelector(selectIsMdiSegmentation);
  const isWsiProject = useAppSelector(selectIsWsiProject);
  const isLoadingTasks = useAppSelector(selectIsLoadingBatchTasks);
  const { batch, labels } = useBatchDetailContext();
  const { taskRows, reloadTaskAsync } = useBatchTaskContext();
  const totalTask = useAppSelector(selectProjectTotalTasks);
  const taskAction = useAppSelector(selectBatchTaskAction);
  const { openModal, closeModal } = useModalContext();

  const handleSelect = (taskId: number, action?: string) => {
    switch (action) {
      case TaskAction.REOPEN:
        const task = taskRows.find((item) => item.id === taskId);
        if (task) {
          openModal(ModalTypes.REOPEN_TASK, {
            open: true,
            task: task,
            onClose: (reload?: boolean) => {
              if (reload) reloadTaskAsync();
              closeModal();
            },
          });
        }
        break;
      case TaskAction.SKIP:
        const taskItem = taskRows.find((item) => item.id === taskId);
        if (taskItem) {
          openModal(ModalTypes.SKIP_TASK, {
            open: true,
            task: taskItem,
            onClose: (reload?: boolean) => {
              if (reload) reloadTaskAsync();
              closeModal();
            },
          });
        }
        break;
      case TaskAction.DELETE:
        dispatch(batchTaskActionSelected({ taskId, action }));
        break;
      case TaskAction.REVIEW:
        dispatch(batchTaskActionSelected({ taskId, action }));
        break;
      case TaskAction.ASSIGN:
        dispatch(batchTaskActionSelected({ taskId, action }));
        break;
      default:
        alert("invalid action");
    }
  };

  const handleClose = () => {
    dispatch(batchTaskActionSelected({ taskId: -1, action: "" }));
  };

  const handleReviewPrevious = () => {
    dispatch(batchPreviousTaskSelected());
  };

  const handleReviewNext = () => {
    dispatch(batchNextTaskSelected());
  };

  return (
    <div className="flex flex-auto w-full h-full">
      <BatchTasksTable
        loading={isLoadingTasks}
        totalTask={totalTask}
        onSelect={handleSelect}
      />
      {taskAction.action === TaskAction.DELETE && taskAction.task && (
        <DeleteTaskDialog task={taskAction.task} handleClose={handleClose} />
      )}

      {taskAction.action === TaskAction.REVIEW &&
        taskAction.task &&
        isMammographyProject && (
          <MammographyTaskProvider onClose={handleClose}>
            <MammographyTaskReviewComponent
              allTaskIds={taskRows.map((task) => task.id)}
              taskId={taskAction.task.id}
            />
          </MammographyTaskProvider>
        )}

      {taskAction.action === TaskAction.REVIEW &&
        taskAction.task &&
        isImageProject &&
        !isMammographyProject && (
          <TasksReviewComponent
            visible
            batch={batch}
            task={taskAction.task}
            batchObservations={labels}
            onClose={handleClose}
            onNext={handleReviewNext}
            showReopenButtons
          />
        )}

      {taskAction.action === TaskAction.REVIEW &&
        taskAction.task &&
        (isSTTProject || isTTSProject) && (
          <TaskReviewAudio
            batch={batch}
            open
            task={taskAction.task}
            onClose={handleClose}
            onNext={handleReviewNext}
            showReopenButtons
          />
        )}

      {taskAction.action === TaskAction.REVIEW &&
        taskAction.task &&
        isTextLabelingProject && (
          <TextTaskProvider>
            <TextTaskReviewComponent
              allTaskIds={taskRows.map((task) => task.id)}
              taskId={taskAction.task.id}
              onClose={handleClose}
              onNext={handleReviewNext}
              onPrevious={handleReviewPrevious}
            />
          </TextTaskProvider>
        )}
      {taskAction.action === TaskAction.REVIEW &&
        taskAction.task &&
        isMdiSegmentation && (
          <TaskReviewThreeD
            open
            batch={batch}
            task={taskAction.task}
            onClose={handleClose}
            onNext={handleReviewNext}
            showReopenButtons
            showIssues
          />
        )}
      {taskAction.action === TaskAction.REVIEW &&
        taskAction.task &&
        isWsiProject && (
          <TaskReviewPathology
            open
            batch={batch}
            task={taskAction.task}
            onClose={handleClose}
            onNext={handleReviewNext}
            showReopenButtons
          />
        )}
      {taskAction.action === TaskAction.ASSIGN && taskAction.task && (
        <AssignJobModal
          width="40rem"
          open
          title={t("project:assignJobs.title")}
          reload={reloadTaskAsync}
          onClose={handleClose}
          taskId={taskAction.task.id}
        />
      )}
    </div>
  );
};
