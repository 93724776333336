/*
 * File: vb-light-radio.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 1st November 2021 5:39:07 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { CheckboxProps, makeStyles, Radio } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    color: "gray",
  },
  checked: {
    color: "white",
  },
}));

export const LightRadio = ({ ...rest }: CheckboxProps) => {
  const classes = useStyles();
  return (
    <Radio
      size="small"
      color="default"
      {...rest}
      classes={{
        root: classes.root,
        checked: classes.checked,
      }}
    />
  );
};
