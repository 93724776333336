/*
 * File: text-workspace.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 14th October 2021 3:49:52 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import classNames from "classnames";
import { IconArrowRightLinear } from "components/common/vb-icon.component";
import { VBResizableLayout } from "components/common/vb-resizable-layout/vb-resizable-layout.component";
import { useAutoSave } from "hooks/use-auto-save.hook";
import { useAppSelector } from "hooks/use-redux";
import { selectedTextJobIdsByWorkingStatus, selectTextAutoSaveInSecond } from "store/labeler/text-labeling-batch/text-labeling-batch.selectors";
import { WorkingStatus } from "store/labeler/text-labeling-batch/text-labeling-batch.state";
import {
  selectIsAutoSaveConfig,
  selectSelectedSystemObservationId,
  selectShowPreviousJob,
  selectTextIsShowNavBar,
  selectTextIsShowSideBar,
} from "store/labeler/text-workspace/text-editor/text-editor.selector";
import {
  selectTextIsReadonly,
  selectTextLabelingJob,
  selectTextLabelingMode,
} from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { selectTextReviewConflictTokenIds } from "store/labeler/text-workspace/text-review/text-review.selectors";
import { useTextLabelingBatchContext } from "../../context/text-labeling-batch/text-labeling-batch.context";
import { useTextWorkspaceContext } from "../../context/text-workspace/text-workspace.context";
import useTextConflict from "../../hooks/use-text-conflict.hook";
import { TextAnnotators } from "../text-review/text-annotators.component";
import { TextConflictDetail } from "../text-review/text-conflict-detail.component";
import { TextLabelingReview } from "../text-review/text-labeling-review.component";
import { TextLabelingEditor } from "../text-viewer/text-labeling-editor.component";
import { AcceptanceSidebar } from "./acceptance-sidebar.component";
import { TextConflictMenu } from "./components/text-conflict-menu.component";
import { TextViewerMenu } from "./components/text-viewer-menu.component";
import { TextLabelingHeaderReview } from "./text-labeling-header-review.component";
import { TextLabelingHeader } from "./text-labeling-header.component";
import { TextLabelingNavigation } from "./text-labeling-navigation.component";
import { TextLabelingSidebar } from "./text-labeling-sidebar.component";
interface Props {
  allTaskIds?: number[];
  onNext?(): void;
  onPrevious?(): void;
}
export const TextWorkspace = ({ onNext, onPrevious, allTaskIds }: Props) => {
  const { saveWorkingJob } = useTextLabelingBatchContext();

  const autoSaveInSecond = useAppSelector(selectTextAutoSaveInSecond);
  const labelingJob = useAppSelector(selectTextLabelingJob);
  const isAutoSaveConfig = useAppSelector(selectIsAutoSaveConfig);
  const isReadOnly = useAppSelector(selectTextIsReadonly);
  const mode = useAppSelector(selectTextLabelingMode);
  const selectedSystemObservationId = useAppSelector(selectSelectedSystemObservationId)
  const showNavBar = useAppSelector(selectTextIsShowNavBar);
  const showSideBar = useAppSelector(selectTextIsShowSideBar);
  const showPreviousJobs = useAppSelector(selectShowPreviousJob);
  const workingJobIds = useAppSelector(
    selectedTextJobIdsByWorkingStatus(WorkingStatus.WORKING)
  );

  const isLabeler = mode === TextLabelingMode.LABELER;
  const isCustomer = mode === TextLabelingMode.CUSTOMER;
  const isSupervisorOrReview =
    mode === TextLabelingMode.SUPERVISOR || mode === TextLabelingMode.REVIEWER;
  const isAcceptance = mode === TextLabelingMode.ACCEPTANCE;
  const { isLoading, isLoadingJob } = useTextWorkspaceContext();

  const loading = isLoading || isLoadingJob;

  const { annotators, selectedAnnotators, handleSelectAnnotator } =
    useTextConflict();

  const autoSave = useAutoSave({
    enabled: isAutoSaveConfig && (workingJobIds.length > 0 || selectedSystemObservationId !== -1),
    onSave: saveWorkingJob,
    duration: autoSaveInSecond && autoSaveInSecond * 1000
  });

  return (
    <VBResizableLayout
      className="bg-blueGray-100"
      left={showNavBar ? <TextLabelingNavigation /> : <></>}
      header={
        loading ? (
          <TextLabelingHeaderSkeleton />
        ) : isReadOnly ? (
          <TextLabelingHeaderReview
            onNext={onNext}
            onPrevious={onPrevious}
            allTaskIds={allTaskIds}
          />
        ) : (
          <TextLabelingHeader autoSave={autoSave} />
        )
      }
      right={
        showSideBar ? (
          <>
            {isAcceptance && <AcceptanceSidebar />}
            {!isAcceptance && <TextLabelingSidebar />}
          </>
        ) : (
          <></>
        )
      }
    >
      {isLabeler && <LabelerEditor />}
      {isCustomer && <CustomerEditor />}
      {isSupervisorOrReview && (
        <div className="relative flex flex-col w-full h-full">
          <div className="flex-auto w-full p-4 overflow-y-auto">
            <div
              className={classNames({
                "grid grid-cols-2 gap-6": showPreviousJobs,
              })}
            >
              {!loading && (
                <div>
                  {showPreviousJobs && (
                    <div className="flex items-center justify-between h-10 gap-6">
                      <TextAnnotators
                        annotators={
                          labelingJob?.assignee ? [labelingJob.assignee] : []
                        }
                        selectedAnnotators={
                          labelingJob?.assignee ? [labelingJob.assignee] : []
                        }
                      />
                      <TextViewerMenu />
                    </div>
                  )}
                  <TextLabelingEditor />
                </div>
              )}
              {!loading && showPreviousJobs && (
                <div>
                  <div className="flex items-center justify-between h-10 gap-6">
                    <TextAnnotators
                      annotators={annotators}
                      selectedAnnotators={selectedAnnotators}
                      onSelectAnnotator={handleSelectAnnotator}
                    />
                    <TextConflictMenu annotators={selectedAnnotators} />
                  </div>
                  <TextLabelingReview />
                </div>
              )}
            </div>
          </div>

          {showPreviousJobs && (
            <div className="p-4">
              <TextConflictDetail />
            </div>
          )}
        </div>
      )}
      {isAcceptance && <AcceptanceEditor />}
    </VBResizableLayout>
  );
};

const AcceptanceEditor = () => {
  const { isLoading, isLoadingJob } = useTextWorkspaceContext();
  const loading = isLoading || isLoadingJob;
  return (
    <div className="w-full h-full p-4">
      {loading && (
        <div className="relative h-full overflow-hidden bg-white rounded">
          <TextLoadingSkeleton />
        </div>
      )}
      {!loading && <TextLabelingEditor readonly />}
    </div>
  );
};

const LabelerEditor = () => {
  const { isLoading, isLoadingJob } = useTextWorkspaceContext();
  const loading = isLoading || isLoadingJob;
  return (
    <div className="w-full h-full p-4">
      {loading && (
        <div className="relative h-full overflow-hidden bg-white rounded">
          <TextLoadingSkeleton />
        </div>
      )}
      {!loading && <TextLabelingEditor />}
    </div>
  );
};
const CustomerEditor = () => {
  const { isLoading, isLoadingJob } = useTextWorkspaceContext();
  const conflictTokenIds = useAppSelector(selectTextReviewConflictTokenIds);
  const loading = isLoading || isLoadingJob;
  return (
    <div className="w-full h-full p-4">
      {loading && (
        <div className="relative h-full overflow-hidden bg-white rounded">
          <TextLoadingSkeleton />
        </div>
      )}
      {!loading && <TextLabelingEditor conflictTokenIds={conflictTokenIds} />}
    </div>
  );
};

export function TextLabelingHeaderSkeleton() {
  return (
    <div className="z-50 flex items-center h-16 gap-4 px-4 bg-white shadow">
      <div className="w-6 h-6 rounded-full bg-background-300 animate-pulse"></div>
      <div className="w-32 h-4 bg-background-300 animate-pulse"></div>
      <div className="h-6 border-r-2" />
      <div className="w-6 h-6 rounded bg-background-300 animate-pulse"></div>
      <div className="w-6 h-6 rounded bg-background-300 animate-pulse"></div>
      <div className="w-6 h-6 rounded bg-background-300 animate-pulse"></div>
      <div className="w-6 h-6 rounded bg-background-300 animate-pulse"></div>
      <div className="flex-auto" />
      <div className="h-6 border-r-2" />
      <div className="w-8 h-8 rounded-full bg-background-300 animate-pulse"></div>
      <div className="w-8 h-8 rounded-full bg-background-300 animate-pulse"></div>
      <IconArrowRightLinear className="w-5 h-5 mr-2 text-background-500" />
      <div className="w-8 h-8 rounded-full bg-background-300 animate-pulse"></div>
      <div className="h-6 border-r-2" />
      <div className="w-24 h-8 rounded bg-background-300 animate-pulse"></div>
      <div className="w-24 h-8 rounded bg-background-300 animate-pulse"></div>
    </div>
  );
}

function TextLoadingSkeleton() {
  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-white">
      <div className="overflow-hidden">
        <div className="flex items-center h-20">
          <div className="flex items-center justify-center w-10 h-20 bg-gray-200">
            <div className="w-5 h-5 bg-gray-300"></div>
          </div>
          <div className="flex items-center flex-auto h-20 px-4 bg-gray-100 animate-pulse">
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
          </div>
        </div>

        <div className="flex items-center h-20">
          <div className="flex items-center justify-center w-10 h-20 bg-gray-200">
            <div className="w-5 h-5 bg-gray-300"></div>
          </div>
          <div className="flex items-center flex-auto h-20 px-4 bg-white">
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
          </div>
        </div>

        <div className="flex items-center h-20">
          <div className="flex items-center justify-center w-10 h-20 bg-gray-200">
            <div className="w-5 h-5 bg-gray-300"></div>
          </div>
          <div className="flex items-center flex-auto h-20 px-4 bg-gray-100 animate-pulse">
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
          </div>
        </div>

        <div className="flex items-center h-20">
          <div className="flex items-center justify-center w-10 h-20 bg-gray-200">
            <div className="w-5 h-5 bg-gray-300"></div>
          </div>
          <div className="flex items-center flex-auto h-20 px-4 bg-white">
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
          </div>
        </div>

        <div className="flex items-center h-20">
          <div className="flex items-center justify-center w-10 h-20 bg-gray-200">
            <div className="w-5 h-5 bg-gray-300"></div>
          </div>
          <div className="flex items-center flex-auto h-20 px-4 bg-gray-100 animate-pulse">
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
          </div>
        </div>

        <div className="flex items-center h-20">
          <div className="flex items-center justify-center w-10 h-20 bg-gray-200">
            <div className="w-5 h-5 bg-gray-300"></div>
          </div>
          <div className="flex items-center flex-auto h-20 px-4 bg-white">
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
          </div>
        </div>

        <div className="flex items-center h-20">
          <div className="flex items-center justify-center w-10 h-20 bg-gray-200">
            <div className="w-5 h-5 bg-gray-300"></div>
          </div>
          <div className="flex items-center flex-auto h-20 px-4 bg-gray-100 animate-pulse">
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
          </div>
        </div>

        <div className="flex items-center h-20">
          <div className="flex items-center justify-center w-10 h-20 bg-gray-200">
            <div className="w-5 h-5 bg-gray-300"></div>
          </div>
          <div className="flex items-center flex-auto h-20 px-4 bg-white">
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
          </div>
        </div>

        <div className="flex items-center h-20">
          <div className="flex items-center justify-center w-10 h-20 bg-gray-200">
            <div className="w-5 h-5 bg-gray-300"></div>
          </div>
          <div className="flex items-center flex-auto h-20 px-4 bg-gray-100 animate-pulse">
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
          </div>
        </div>

        <div className="flex items-center h-20">
          <div className="flex items-center justify-center w-10 h-20 bg-gray-200">
            <div className="w-5 h-5 bg-gray-300"></div>
          </div>
          <div className="flex items-center flex-auto h-20 px-4 bg-white">
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
          </div>
        </div>

        <div className="flex items-center h-20">
          <div className="flex items-center justify-center w-10 h-20 bg-gray-200">
            <div className="w-5 h-5 bg-gray-300"></div>
          </div>
          <div className="flex items-center flex-auto h-20 px-4 bg-gray-100 animate-pulse">
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
          </div>
        </div>

        <div className="flex items-center h-20">
          <div className="flex items-center justify-center w-10 h-20 bg-gray-200">
            <div className="w-5 h-5 bg-gray-300"></div>
          </div>
          <div className="flex items-center flex-auto h-20 px-4 bg-white">
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
