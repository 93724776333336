import { IEntityContextState } from "domain/common/entity-context";
import { ILabelerTaskEntity, ILabelerTaskFilter } from "../types";
import { LabelerProject } from "domain/image-labeling";

export interface LabelerTaskState
  extends IEntityContextState<ILabelerTaskEntity, ILabelerTaskFilter> {
  project?: LabelerProject;
}

export const defaultLabelerTaskState = {} as LabelerTaskState;
