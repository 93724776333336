/*
 * File: labeler-job-v3.api.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 21st September 2022 2:48:16 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { JobDTO } from "../dtos";
import { BaseAPI } from "./base.api";

export class LabelerJobV3API extends BaseAPI<JobDTO> {
  finishJob(jobId: number | string) {
    return this.post(`${this.getPath()}/finish/${jobId}`);
  }
}
