/*
 * File: dataset-detail.context.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 16th August 2021 9:34:53 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppSelector } from "hooks/use-redux";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import {
  useContext,
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { DatasetDTO } from "services/label-service/dtos";
import {
  selectCurrentDataset,
  selectDatasetFiles,
  selectDatasetLoading,
} from "store/customer/dataset/dataset.selectors";

interface DatasetDetailContextState {
  loading: boolean;
  files: StorageFileDTO[];
  currentDataset: DatasetDTO | null;
  hoveredFile: StorageFileDTO | null;
  selecting: boolean;
  showFileInfo: boolean;
  selectedFiles: number[];
  deletingItemIds: number[];
  toggleSelecting: () => void;
  toggleShowFileInfo: () => void;
  setDeletingItemIds: (ids: number[]) => void;
  setSelectedFiles: (fileIds: number[]) => void;
  setHoveredFile: (file: StorageFileDTO) => void;
  getTextInfosFromCache?: (id: number) => any;
  putTextInfoToCache?: (id: number, data: any) => void;
}

export const DatasetDetailContext = createContext(
  {} as DatasetDetailContextState
);
export const useDatasetDetailContext = () => useContext(DatasetDetailContext);

interface ProviderProps {
  children: React.ReactNode | React.ReactNode[] | null;
  defaultSelectable?: boolean;
  defaultSelectAll?: boolean;
}

export const DatasetDetailProvider = ({
  children,
  defaultSelectable = true,
  defaultSelectAll = false,
}: ProviderProps) => {
  const loading = useAppSelector(selectDatasetLoading);
  const files = useAppSelector(selectDatasetFiles);
  const currentDataset = useAppSelector(selectCurrentDataset);
  const [selecting, setSelecting] = useState(defaultSelectable);
  const [hoveredFile, setHoveredFile] = useState<StorageFileDTO | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
  const [showFileInfo, setShowFileInfo] = useState(false);
  const [deletingItemIds, setDeletingItemIds] = useState<number[]>([]);
  const [textInfosCache, setTextInfosCache] = useState<any>({});

  const toggleShowFileInfo = () => {
    setShowFileInfo((state) => !state);
  };

  const toggleSelecting = () => {
    setSelecting((state) => !state);
  };

  useEffect(() => {
    defaultSelectAll
      ? setSelectedFiles(files.map((file) => file.id))
      : setSelectedFiles(selectedFiles);
  }, [selectedFiles, files, defaultSelectAll]);

  const getTextInfosFromCache = (id: number) => {
    return textInfosCache[id];
  };

  const putTextInfoToCache = (id: number, data: any) => {
    setTextInfosCache({
      ...textInfosCache,
      [id]: data,
    });
  };

  const value = {
    loading,
    files,
    currentDataset,
    selecting,
    showFileInfo,
    hoveredFile,
    selectedFiles,
    deletingItemIds,
    toggleSelecting,
    toggleShowFileInfo,
    setDeletingItemIds,
    setSelectedFiles,
    setHoveredFile,
    getTextInfosFromCache,
    putTextInfoToCache,
  };

  return (
    <DatasetDetailContext.Provider value={value}>
      {children}
    </DatasetDetailContext.Provider>
  );
};

interface DeselectProviderProps {
  children: React.ReactNode | React.ReactNode[] | null;
  ignoreFiles: number[];
  setIgnoredFiles: React.Dispatch<React.SetStateAction<number[]>>;
  onIgnoreFilesChange?(ignoreFiles: number[]): void;
}

export const DatasetDetailDeselectProvider = ({
  children,
  ignoreFiles,
  setIgnoredFiles,
  onIgnoreFilesChange,
}: DeselectProviderProps) => {
  const loading = useAppSelector(selectDatasetLoading);
  const files = useAppSelector(selectDatasetFiles);
  const currentDataset = useAppSelector(selectCurrentDataset);
  const [selecting, setSelecting] = useState(true);
  const [hoveredFile, setHoveredFile] = useState<StorageFileDTO | null>(null);
  const [showFileInfo, setShowFileInfo] = useState(false);
  const [deletingItemIds, setDeletingItemIds] = useState<number[]>([]);
  const selectedFiles = useMemo(() => {
    return files
      .map((f) => f.id)
      .filter((fileId) => !ignoreFiles.includes(fileId));
  }, [ignoreFiles, files]);

  const setSelectedFiles = useCallback(
    (selectedFileIds: number[]) => {
      setIgnoredFiles((prevIgnores) => {
        const currentFileIds = files.map((f) => f.id);
        const hiddenIgnores = prevIgnores.filter(
          (fileId) => !currentFileIds.includes(fileId)
        );
        const newIgnores = currentFileIds.filter(
          (fileId) => !selectedFileIds.includes(fileId)
        );
        return hiddenIgnores.concat(newIgnores);
      });
    },
    [files, setIgnoredFiles]
  );

  const toggleShowFileInfo = () => {
    setShowFileInfo((state) => !state);
  };

  const toggleSelecting = () => {
    setSelecting((state) => !state);
  };

  useEffect(() => {
    onIgnoreFilesChange && onIgnoreFilesChange(ignoreFiles);
  }, [ignoreFiles, onIgnoreFilesChange]);

  const value = {
    loading,
    files,
    currentDataset,
    selecting,
    showFileInfo,
    hoveredFile,
    selectedFiles,
    deletingItemIds,
    toggleSelecting,
    toggleShowFileInfo,
    setDeletingItemIds,
    setSelectedFiles,
    setHoveredFile,
  };

  return (
    <DatasetDetailContext.Provider value={value}>
      {children}
    </DatasetDetailContext.Provider>
  );
};
