/*
 * File: annotation.api.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 19th October 2021 3:50:40 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AxiosResponse } from "axios";
import { ParamsKey } from "services/common/params-key";
import { IouAnnotation } from "store/customer/tasks-review/task-review-iou/task-review-iou.state";
import {
  GenerateAnnotationRequest,
  GenerateAnnotationResponse,
  GenerateAnnotationStatusResponse,
} from "../dtos/annotation.dto";
import { TextObservationDTO } from "../dtos/text-observation.dto";
import { BaseAPI } from "./base.api";

interface Smoothing3DPayload {
  jobId: number;
  storageResourceId: string;
  method: string;
  parameters: {
    [key: string]: number;
  };
}

export class AnnotationAPI extends BaseAPI<TextObservationDTO> {
  getAnnotations(jobId: string): Promise<AxiosResponse<TextObservationDTO[]>> {
    return this.getItems({
      jobId: jobId,
      size: "1000",
    });
  }
  getTaskAnnotations(
    taskId: string
  ): Promise<AxiosResponse<TextObservationDTO[]>> {
    return this.get(`${this.getPath()}/by-task/${taskId}`);
  }

  importAnnotationFromFile(
    file: File,
    batchId: string | number,
    format: string,
    onProgress?: (event: any) => void
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    const params = new URLSearchParams({
      batchId: batchId.toString(),
      format,
    });
    formData.append(ParamsKey.UPLOADED_FILE, file);
    const headers = { "Content-Type": "multipart/form-data" };
    const config = {
      headers: headers,
      data: formData,
      onUploadProgress: onProgress,
    };
    const url = `${this.getPath()}/import?${params.toString()}`;
    return this.post(url, formData, config);
  }

  generateAnnotationFromModel(
    batchId: number | string,
    payload: GenerateAnnotationRequest
  ): Promise<AxiosResponse<GenerateAnnotationResponse>> {
    return this.post(`${this.getPath()}/${batchId}/annotating`, payload);
  }

  getGenerateAnnotationJobStatus(
    batchId: number | string
  ): Promise<AxiosResponse<GenerateAnnotationStatusResponse>> {
    return this.get(`${this.getPath()}/generate/status?batchId=${batchId}`);
  }

  getIouAnnotationsByTask(
    taskId: number
  ): Promise<AxiosResponse<IouAnnotation[]>> {
    return this.getItem(`metrics/task/${taskId}`);
  }

  smoothing3D(payload: Smoothing3DPayload) {
    return this.post(`${this.getPath()}/threed/smoothing`, payload);
  }

  getSmoothing3D(jobId: number) {
    return this.get(`${this.getPath()}/threed/smoothing?jobId=${jobId}`);
  }
}
