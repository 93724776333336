import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { selectUserNotificationSettings } from "store/common/user-notification/user-notification.selectors";
import {
  loadUserNotificationSettings,
  updateUserNotificationSettings,
} from "store/common/user-notification/user-notification.thunk";
import { selectCurrentProject } from "store/customer/project/project.selectors";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import * as Sentry from "@sentry/react";

export const useNotificationSetting = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState<any>(null);

  const currentProject = useAppSelector(selectCurrentProject);
  const defaultSetting = useAppSelector(selectUserNotificationSettings);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        if (!currentProject) return;
        const projectId = currentProject.id.toString();
        await dispatch(loadUserNotificationSettings(projectId));
      } catch (err) {
        Sentry.captureException(err);
        console.log(err);
      }
    };

    loadSettings();
  }, [dispatch, currentProject]);

  useEffect(() => {
    if (!defaultSetting || !currentProject) return;

    const application = defaultSetting.notifications.find(
      (n: any) => n.name === "Application"
    );
    setSettings({
      workspaceId: defaultSetting.workspaceId,
      projectId: currentProject.id || defaultSetting.projectId,
      notifications: application?.items.map((item: any) => ({
        id: item.id,
        name: item.name,
        checked: defaultSetting.projectId ? !!item.users.length : false,
        advancedRange: item.advancedRange,
        advancedUnit: item.advancedUnit,
      })),
      users: (
        application?.items.find((o: any) => !!o.users.length)?.users || []
      ).join("; "),
    });
  }, [defaultSetting, currentProject]);

  const handleSave = useCallback(() => {
    if (isLoading) return;

    const saveAsync = async () => {
      setIsLoading(true);

      const payload = {
        workspaceId: settings.workspaceId,
        projectId: settings.projectId,
        notifications: settings.notifications.map((option: any) => ({
          id: option.id,
          name: option.name,
          advancedRange: option.advancedRange,
          advancedUnit: option.advancedUnit,
          users: option.checked ? convertTextToEmails(settings.users) : [],
        })),
      };

      try {
        const res = await dispatch(updateUserNotificationSettings(payload));
        handleThunkRejected(res);
        dispatch(enqueueSuccessNotification(t("common:textSavedSuccess")));
        setIsChanged(false);
      } catch (err: any) {
        Sentry.captureException(err);
        dispatch(enqueueErrorNotification(t("common:textSavedFailed")));
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    saveAsync();
  }, [dispatch, isLoading, settings, t]);

  return {
    isChanged,
    isLoading,
    settings,
    setIsChanged,
    setIsLoading,
    setSettings,
    onSave: handleSave,
  };
};

const convertTextToEmails = (value: string) => {
  return (
    value
      .split(";")
      .filter((email) => !!email)
      .map((email) => email.trim()) || []
  );
};
