/*
 * File: notification-api.config.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 16th September 2021 8:42:17 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export const NotificationAPIPaths = {
  notification: "/api/v1/notification",
  notificationEvents: "/api/v1/notification-events",
  notificationSettings: "/api/v1/notification-settings",
};
