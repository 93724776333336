/*
 * File: dicom-service.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ENV_CONFIG } from "configs/env.config";
import { RequestOptions } from "../common/request-options";
import { dicomAPIGet } from "./dicom-httpclient";

function searchForStudies(options: RequestOptions = {}) {
  const params = new URLSearchParams(options);
  const url = `/studies?${params.toString()}`;
  return dicomAPIGet(url);
}

function searchForSeries(options: { SeriesInstanceUID?: string }) {
  const params = new URLSearchParams(options);
  const url = `/series?${params.toString()}`;
  return dicomAPIGet(url);
}

function searchForSeriesWithinAStudy(
  StudyInstanceUID: string,
  options: { SeriesInstanceUID?: string }
): Promise<any> {
  const params = new URLSearchParams(options);
  const url = `/studies/${StudyInstanceUID}/series?${params.toString()}`;
  return dicomAPIGet(url);
}

function searchForInstances(options: {
  SOPInstanceUID?: string;
}): Promise<any> {
  const params = new URLSearchParams(options);
  const url = `/instances/?${params.toString()}`;
  return dicomAPIGet(url);
}

function searchForInstancesWithinAStudy(
  StudyInstanceUID: string,
  options: RequestOptions = {}
): Promise<any> {
  const params = new URLSearchParams(options);
  const url = `/studies/${StudyInstanceUID}/instances?${params.toString()}`;
  return dicomAPIGet(url);
}
function searchForInstancesWithinAStudyAndSeries(
  StudyInstanceUID: string,
  options: {
    SOPInstanceUID?: string;
  }
): Promise<any> {
  const params = new URLSearchParams(options);
  const url = `/studies/${StudyInstanceUID}/instances?${params.toString()}`;
  return dicomAPIGet(url);
}
function retrieveOneOrMoreFramesFromASingleInstance(
  StudyInstanceUID: string,
  SeriesInstanceUID: string,
  SOPInstanceUID: string,
  frameNumbers: string = "1"
): Promise<any> {
  const url = `studies/${StudyInstanceUID}/series/${SeriesInstanceUID}/instances/${SOPInstanceUID}/frames/${frameNumbers}`;
  return dicomAPIGet(url);
}
function retrieveMetadataOfAllInstancesInStudy(
  StudyInstanceUID: string
): Promise<any> {
  const url = `studies/${StudyInstanceUID}/metadata`;
  return dicomAPIGet(url);
}

function retrieveMetadataOfAllInstanceWithinASeries(
  StudyInstanceUID: string,
  SeriesInstanceUID: string
): Promise<any> {
  const url = `studies/${StudyInstanceUID}/series/${SeriesInstanceUID}/metadata`;
  return dicomAPIGet(url);
}
function retrieveMetadataOfASingleInstanceWithinASeriesOfAStudy(
  StudyInstanceUID: string,
  SeriesInstanceUID: string,
  SOPInstanceUID: string
): Promise<any> {
  const url = `/studies/${StudyInstanceUID}/series/${SeriesInstanceUID}/instances/${SOPInstanceUID}/metadata`;
  return dicomAPIGet(url);
}

function retrieveAllInstancesWithinAStudy(
  StudyInstanceUID: string
): Promise<any> {
  const params = new URLSearchParams({});
  const url = `studies/${StudyInstanceUID}?${params.toString()}`;
  const config = {
    headers: {
      Accept: 'multipart/related; type="application/dicom"; transfer-syntax=*',
    },
  };
  return dicomAPIGet(url, config);
}

function retrieveAllInstancesWithinASeries(
  StudyInstanceUID: string,
  SeriesInstanceUID: string
): Promise<any> {
  const url = `studies/${StudyInstanceUID}/series/${SeriesInstanceUID}`;
  return dicomAPIGet(url);
}

function retrieveASingleInstanceWithinASeriesOfAStudy(
  StudyInstanceUID: string,
  SeriesInstanceUID: string,
  SOPInstanceUID: string
): Promise<any> {
  const url = `studies/${StudyInstanceUID}/series/${SeriesInstanceUID}/instances/${SOPInstanceUID}`;
  return dicomAPIGet(url);
}

function getImageUrl(
  StudyInstanceUID: string,
  SeriesInstanceUID: string,
  SOPInstanceUID: string
): string {
  return `${ENV_CONFIG.DICOM_SERVICE_URL}/studies/${StudyInstanceUID}/series/${SeriesInstanceUID}/instances/${SOPInstanceUID}/frames/1/rendered?accept=image/jpeg`;
}

export const DicomService = {
  searchForStudies,
  searchForSeries,
  searchForSeriesWithinAStudy,
  searchForInstances,
  searchForInstancesWithinAStudy,
  searchForInstancesWithinAStudyAndSeries,
  retrieveOneOrMoreFramesFromASingleInstance,
  retrieveMetadataOfAllInstancesInStudy,
  retrieveMetadataOfAllInstanceWithinASeries,
  retrieveMetadataOfASingleInstanceWithinASeriesOfAStudy,
  retrieveAllInstancesWithinAStudy,
  retrieveAllInstancesWithinASeries,
  retrieveASingleInstanceWithinASeriesOfAStudy,
  getImageUrl,
};
