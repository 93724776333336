import { VBTabs } from "components/common/vb-tabs/vb-tabs.component";
import { Annotation } from "domain/image-labeling";
import { useAppSelector } from "hooks/use-redux";
import { useCallback, useMemo, useState } from "react";
import { StepType } from "services/label-service/dtos";
import {
  MODEL_ASSIGNEE,
  SYSTEM_ASSIGNEE,
} from "services/label-service/dtos/task-observation-v3.dto";
import { selectTasksReviewLabel } from "store/customer/tasks-review/tasks-review.selectors";
import { useBatchDetailContext } from "../../../../context/batch-detail.context";
import { TaskReviewActionPanelGroupLabel } from "./group-label.component";

enum ActionPanelTab {
  All = "all",
  Upvote = "upvote",
  DownVote = "downvote",
  None = "none",
}

interface TaskReviewActionPanelFindingAnnotationsProps {
  annotations: Annotation[];
  className?: string;
  labeler?: string;
}

export const TaskReviewActionPanelFindingAnnotations = ({
  annotations,
  className,
  labeler,
}: TaskReviewActionPanelFindingAnnotationsProps) => {

  const [activeTab, setActiveTab] = useState<ActionPanelTab>(
    ActionPanelTab.All
  );
  const taskReviewLabel = useAppSelector(selectTasksReviewLabel);
  const { batchInstructions } = useBatchDetailContext();

  const hasReviewStep = useMemo(() => {
    if (!batchInstructions || !batchInstructions.length) return false;
    return (
      batchInstructions.findIndex(
        (instruction) => instruction.stepType === StepType.REVIEW
      ) !== -1
    );
  }, [batchInstructions]);

  const getAnnotationsByTab = useCallback(
    (annotations: Annotation[], tab: ActionPanelTab) => {
      if (tab === ActionPanelTab.All) {
        return annotations;
      }
      return annotations.filter((annotation) => {
        if (tab === ActionPanelTab.Upvote)
          return annotation.vote && annotation.vote > 0;
        if (tab === ActionPanelTab.DownVote)
          return annotation.vote && annotation.vote < 0;
        if (tab === ActionPanelTab.None)
          return !annotation.vote || annotation.vote === 0;
        return false;
      });
    },
    []
  );

  const getAnnotationsByLabeler = useCallback(
    (annotations: Annotation[], labeler?: string) => {
      if (!labeler || labeler === "All")
        return annotations.filter(
          (annotation) =>
            annotation.annotator !== SYSTEM_ASSIGNEE &&
            annotation.annotator !== MODEL_ASSIGNEE
        );
      return annotations.filter(
        (previousAnnotation) => previousAnnotation.annotator === labeler
      );
    },
    []
  );

  const getLabelNameById = useCallback(
    (id: number) => {
      return taskReviewLabel.find((label) => label.id === id)?.name || "";
    },
    [taskReviewLabel]
  );

  const filteredAnnotations = useMemo(() => {
    const annotationsByLabeler = getAnnotationsByLabeler(annotations, labeler);
    const annotationsByTab = getAnnotationsByTab(
      annotationsByLabeler,
      activeTab
    );

    return annotationsByTab;
  }, [
    getAnnotationsByLabeler,
    annotations,
    labeler,
    getAnnotationsByTab,
    activeTab,
  ]);

  const groupedAnnotationsByLabel = useMemo(() => {
    const labelMap = new Map<number, Annotation[]>();

    filteredAnnotations.forEach((annotation: Annotation) => {
      labelMap.set(annotation.labelId, [
        ...(labelMap.get(annotation.labelId) || []),
        annotation,
      ]);
    });
    return Object.fromEntries(labelMap);
  }, [filteredAnnotations]);

  const handleSelectTab = useCallback((tabId: string) => {
    setActiveTab(tabId as ActionPanelTab);
  }, []);

  return (
    <div className={className}>
      <div className="p-4 space-y-3">
        <div className="text-sm font-bold text-background-700">
          Annotation Findings
        </div>
        {hasReviewStep && (
          <VBTabs
            activeClass="font-bold border-none pl-0 pr-6"
            inActiveClass="opacity-60 font-normal border-none pl-0 pr-6"
            className="w-full text-sm border-none"
            activeTab={activeTab}
            onSelect={handleSelectTab}
            tabs={[
              { id: ActionPanelTab.All, name: "All" },
              {
                id: ActionPanelTab.Upvote,
                name: `Upvote`,
              },
              {
                id: ActionPanelTab.DownVote,
                name: `Downvote`,
              },
              {
                id: ActionPanelTab.None,
                name: `None`,
              },
            ]}
          />
        )}
      </div>

      <div className="space-y-2">
        {Object.entries(groupedAnnotationsByLabel).map(([key, value]) => (
          <TaskReviewActionPanelGroupLabel
            annotations={value}
            key={key}
            className="bg-white"
            labelId={+key}
            labelName={getLabelNameById(+key)}
          />
        ))}
      </div>
    </div>
  );
};
