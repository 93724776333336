import { Checkbox, Tooltip } from "@material-ui/core";
import { IconTrash } from "components/common/vb-icon.component";
import { VBNumberTextInputComponent } from "components/design-system/number-text-input/number-text-input.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useState } from "react";
import {
  ObservationAttribute,
  ObservationAttributeType,
} from "services/label-service/dtos";
import { classnames } from "utilities/classes";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";

interface Props {
  attributes: ObservationAttribute[];
  onChanged?: (values: ObservationAttribute[]) => void;
}
export const ProjectObservationAttributes = ({
  attributes,
  onChanged,
}: Props) => {
  const [isInputFocus, setIsInputFocus] = useState<any>({});
  const [isHoverValues, setIsHoverValues] = useState<any>({});

  const attributeTypeOptions = [
    { label: "Text", value: ObservationAttributeType.TEXT },
    { label: "Number", value: ObservationAttributeType.NUMBER },
    { label: "Single select", value: ObservationAttributeType.RADIO },
    { label: "Multi select", value: ObservationAttributeType.SELECT },
  ];

  const handleClickDelete = (index: number) => {
    const newAttributes = attributes.filter((_, i) => i !== index);
    onChanged && onChanged(newAttributes);
  };

  const handleFieldChanged = (
    index: number,
    field: keyof ObservationAttribute,
    value: any
  ) => {
    const newValue = attributes.map((attribute, i) => {
      if (index === i) {
        return {
          ...attribute,
          [field]: value,
        };
      }
      return attribute;
    });
    onChanged && onChanged(newValue);
  };

  const handleFieldsChanged = (
    index: number,
    fields: (keyof ObservationAttribute)[],
    values: any[]
  ) => {
    const newValue = attributes.map((attribute, i) => {
      if (index === i) {
        const newAttribute: any = { ...attribute };
        for (let i = 0; i < fields.length; i++) {
          newAttribute[fields[i]] = values[i];
        }
        return newAttribute as ObservationAttribute;
      }
      return attribute;
    });
    onChanged && onChanged(newValue);
  };

  const handleAttributeValueInputKeydown = (index: number, e: any) => {
    if (e.key === KeyboardKey.Enter) {
      const value = e.target.value as string;
      if (value) {
        const currentValues = attributes[index].values;
        if (!currentValues.includes(value)) {
          handleFieldChanged(index, "values", [...currentValues, value]);
          e.target.value = "";
          e.target.focus();
        }
      }
    }
  };

  const handleClickDeleteAttributeValue = (index: number, value: string) => {
    const currentValues = attributes[index].values;
    handleFieldChanged(
      index,
      "values",
      currentValues.filter((v) => v !== value)
    );
  };

  return (
    <div className="flex flex-col text-sm">
      {attributes.map((attribute, index) => {
        return (
          <div
            className="flex items-start gap-2 px-2 pt-2 rounded bg-background-100"
            key={index}
          >
            <div className="w-28">
              <VBNumberTextInputComponent
                height="33px"
                placeholder="Name"
                type="text"
                defaultValue={attribute.name}
                listenChangedDefault
                onValueChanged={(v) => handleFieldChanged(index, "name", v)}
              />
            </div>
            <VBSelectComponent
              containerClassName="w-28"
              options={attributeTypeOptions}
              value={attributeTypeOptions.find(
                (option) => option.value === attribute.type
              )}
              onChange={(option: any) => {
                if (
                  option.value === ObservationAttributeType.NUMBER &&
                  attribute.ranges.length < 2
                ) {
                  handleFieldsChanged(
                    index,
                    ["type", "ranges"],
                    [option.value, [0, 100]]
                  );
                } else {
                  handleFieldChanged(index, "type", option.value);
                }
              }}
              menuPortalTarget={document.body}
            />
            {(attribute.type === ObservationAttributeType.RADIO ||
              attribute.type === ObservationAttributeType.SELECT) && (
              <div
                className={classnames(
                  "w-52 p-1 gap-1 flex flex-wrap border border-background-300 rounded bg-white",
                  { "border-primary": isInputFocus[index] }
                )}
              >
                {attribute.values.map((value, valueIndex) => {
                  if (valueIndex === attribute.values.length - 1) return null;
                  return (
                    <div className="flex items-center gap-1" key={value}>
                      <div className="flex items-center gap-2 px-2 py-1 truncate rounded bg-background-200">
                        <span className="truncate">{value}</span>
                        <span
                          className="cursor-pointer"
                          onClick={() =>
                            handleClickDeleteAttributeValue(index, value)
                          }
                        >
                          x
                        </span>
                      </div>
                    </div>
                  );
                })}
                <Tooltip
                  title="Please enter to add a new value"
                  placement="top"
                  open={attribute.values.length <= 0 && !!isHoverValues[index]}
                >
                  <div
                    className="flex items-center flex-1 gap-1"
                    onMouseEnter={() =>
                      setIsHoverValues({ ...isHoverValues, [index]: true })
                    }
                    onMouseLeave={() =>
                      setIsHoverValues({ ...isHoverValues, [index]: false })
                    }
                  >
                    {attribute.values.length > 0 && (
                      <div className="flex items-center gap-2 px-2 py-1 truncate rounded bg-background-200">
                        <span className="truncate">
                          {attribute.values[attribute.values.length - 1]}
                        </span>
                        <span
                          className="cursor-pointer"
                          onClick={() =>
                            handleClickDeleteAttributeValue(
                              index,
                              attribute.values[attribute.values.length - 1]
                            )
                          }
                        >
                          x
                        </span>
                      </div>
                    )}
                    <input
                      placeholder={
                        attribute.values.length === 0 ? "Attribute values" : ""
                      }
                      type="text"
                      className="flex-1 p-1 outline-none focus:outline-none"
                      style={{
                        width: "20px",
                        maxWidth: "100%",
                        height: "26px",
                      }}
                      onKeyDown={(e) =>
                        handleAttributeValueInputKeydown(index, e)
                      }
                      onFocus={() =>
                        setIsInputFocus({ ...isInputFocus, [index]: true })
                      }
                      onBlur={() =>
                        setIsInputFocus({ ...isInputFocus, [index]: false })
                      }
                    />
                  </div>
                </Tooltip>
              </div>
            )}
            <div className="flex-1" />
            <div className="flex items-center gap-1">
              <Checkbox
                checked={attribute.required}
                color="primary"
                onChange={(_, v) => handleFieldChanged(index, "required", v)}
              />
              <span>Required</span>
              <IconTrash
                className="w-4 h-4 ml-2 cursor-pointer text-error-300"
                onClick={() => handleClickDelete(index)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
