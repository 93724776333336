/*
 * File: workflow.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 14th August 2021 2:54:56 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { WorkflowInstructionDTO } from "services/label-service/dtos";
import { RootState } from "store";
import i18n from "i18n";
import * as Sentry from "@sentry/react";

export const selectWorkflows = (state: RootState) => {
  return state.workflow.ids.map((id) => state.workflow.entities[id]);
};

export const selectWorkflowRequestStatus = (state: RootState) => {
  return state.workflow.status;
};

export const selectWorkflowRequestError = (state: RootState) => {
  return state.workflow.error;
};

export const selectWorkflow = (workflowId: number) => (state: RootState) => {
  return state.workflow.entities[workflowId];
};

export const selectCurrentWorkflow = (state: RootState) => {
  return state.workflow.entities[state.workflow.id];
};

export const selectStepConditions = (state: RootState) => {
  return state.workflow.stepConditions.map((s) => {
    return { label: s.name, value: s.id.toString() };
  });
};
export const selectAllStepConditions = (state: RootState) => {
  return state.workflow.stepConditions;
};

export const selectStepConditionOptions = (state: RootState) => {
  const { stepConditions } = state.workflow;
  const operationOptions = [
    { value: "&", label: i18n.t("workflow:textAnd") },
    { value: "||", label: i18n.t("workflow:textOr") },
  ];
  const options = [
    { label: i18n.t("workflow:textNoTransitionCondition"), value: "" },
  ];

  for (let i = 0; i < stepConditions.length; i++) {
    const conditionId = stepConditions[i].id.toString();
    if (!options.find((step) => step.value === conditionId)) {
      options.push({ label: stepConditions[i].name, value: conditionId });
    }

    for (let j = i + 1; j < stepConditions.length; j++) {
      if (stepConditions[i].projectType === stepConditions[j].projectType) {
        options.push({
          label: stepConditions[j].name,
          value: stepConditions[j].id.toString(),
        });
        for (let operation of operationOptions) {
          const label = `${stepConditions[i].name} ${operation.label} ${stepConditions[j].name}`;
          const value = `${stepConditions[i].id} ${operation.value} ${stepConditions[j].id}`;
          options.push({ label, value });
        }
      }
    }
  }
  return options;
};

export const selectLabelRequireIoUConfig = (state: RootState): string => {
  return (
    state.workflow.stepConditions
      .find((step) => step.requireConfig === "label")
      ?.id.toString() || ""
  );
};

export const selectRequireTextConsensus =
  (instructions: WorkflowInstructionDTO[]) =>
  (
    state: RootState
  ): {
    entity: boolean;
    relation: boolean;
  } => {
    const entityCondition = state.workflow.stepConditions.find(
      (s) => s.code === "ED"
    );

    const relationCondition = state.workflow.stepConditions.find(
      (s) => s.code === "RD"
    );

    const requireEntity = !!instructions.find((ins) => {
      const ids = findConditionIds(ins.condition);
      return entityCondition && ids.includes(entityCondition?.id);
    });

    const requireRelation = !!instructions.find((ins) => {
      const ids = findConditionIds(ins.condition);
      return relationCondition && ids.includes(relationCondition?.id);
    });

    return { entity: requireEntity, relation: requireRelation };
  };

function findConditionIds(condition: string) {
  if (!condition) return [];
  let ids: number[] = [];
  const items = condition.split(" ");
  for (let item of items) {
    try {
      let value = parseInt(item);
      if (value) ids.push(value);
    } catch (error) {Sentry.captureException(error);}
  }
  return ids;
}
