/*
 * File: text-labeling-batch.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Sunday, 10th July 2022 10:06:07 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { LabelingBatchState } from "./text-labeling-batch.state";
import { textCompleteJobBuilder } from "./thunks/text-complete-job.thunk";
import { textLoadJobBuilder } from "./thunks/text-load-job.thunk";
import { textPollJobsBuilder } from "./thunks/text-poll-jobs.thunk";
import { saveTextJobBuilder } from "./thunks/text-save-job.thunk";
import { textSelectJobBuilder } from "./thunks/text-select-job.thunk";
import { validateTextJobBuilder } from "./thunks/text-validate-job.thunk";

export const labeingBatchReducerBuidler = (
  builder: ActionReducerMapBuilder<LabelingBatchState>
) => {
  textPollJobsBuilder(builder);
  textLoadJobBuilder(builder);
  textSelectJobBuilder(builder);
  saveTextJobBuilder(builder);
  textCompleteJobBuilder(builder);
  validateTextJobBuilder(builder);
};
