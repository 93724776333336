import * as csTools from "cornerstone-tools";
import {
  getToolState,
  toolStyle,
  toolColors,
  textStyle,
} from "cornerstone-tools";
import drawFillRect from "./drawing/draw-fill-rect";
import drawHandles from "./drawing/draw-handles";
import getROITextBoxCoords from "./util/getROITextBoxCoords";
import drawLinkedTextBox from "./drawing/drawLinkedTextBox";
import drawTextValue from "./drawing/drawTextValue";
import drawIoUValue from "./drawing/drawIoUValue";
import {
  moveHandleNearImagePoint,
  moveAnnotation,
} from "./rectangleroi/findAndMoveHelpers";
import getActiveTool from "./HeartRulerTool_utils/getActiveTool";
import drawActionVote from "./drawing/drawActionVote";
const BaseAnnotationTool = csTools.importInternal("base/BaseAnnotationTool");
const external = csTools.external;
const triggerEvent = csTools.importInternal("util/triggerEvent");
const calculateSUV = csTools.importInternal("util/calculateSUV");
const numbersWithCommas = csTools.importInternal("util/numbersWithCommas");
const getPixelSpacing = csTools.importInternal("util/getPixelSpacing");
const MouseCursor = csTools.importInternal("tools/cursors/MouseCursor");
const getNewContext = csTools.importInternal("drawing/getNewContext");
const draw = csTools.importInternal("drawing/draw");
const drawRect = csTools.importInternal("drawing/drawRect");
const setShadow = csTools.importInternal("drawing/setShadow");
const throttle = csTools.importInternal("util/throttle");
const getModule = csTools.getModule;

export const rectangleRoiCursor = new MouseCursor(
  `<path fill="ACTIVE_COLOR" d="M1312 256h-832q-66 0-113 47t-47 113v832q0 66 47
      113t113 47h832q66 0 113-47t47-113v-832q0-66-47-113t-113-47zm288 160v832q0
      119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119
      84.5-203.5t203.5-84.5h832q119 0 203.5 84.5t84.5 203.5z"
    />`,
  {
    viewBox: {
      x: 1792,
      y: 1792,
    },
  }
);

/**
 * @public
 * @class RectangleRoiTool
 * @memberof Tools.Annotation
 * @classdesc Tool for drawing rectangular regions of interest, and measuring
 * the statistics of the enclosed pixels.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class RectangleRoiExtendTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: "RectangleRoiExtend",
      supportedInteractionTypes: ["Mouse", "Touch"],
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
        // showMinMax: false,
        // showHounsfieldUnits: true
      },
      svgCursor: rectangleRoiCursor,
    };

    super(props, defaultProps);

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);

    textStyle.setBackgroundColor("#000000");
  }

  doneMovingCallback(evt, data, isMoveAll) {
    if (!isMoveAll && data) this._validateHandles(data);
    if (data) this.triggerEventMeasurementUpdated(evt, data);
  }

  handleSelectedCallback(evt, toolData, handle, interactionType = "mouse") {
    if (!toolData?.visible) return;
    if (toolData?.locked && !handle.hasBoundingBox) return;
    this.triggerEventMeasurementSelected(evt, toolData);
    moveHandleNearImagePoint(evt, this, toolData, handle, interactionType, () =>
      this.doneMovingCallback(evt, toolData, false)
    );
  }

  toolSelectedCallback(evt, annotation, interactionType = "mouse") {
    this.triggerEventMeasurementSelected(evt, annotation);
    moveAnnotation(evt, this, annotation, interactionType, () =>
      this.doneMovingCallback(evt, annotation, true)
    );
  }

  triggerEventMeasurementUpdated(evt, data) {
    const eventType = "cornerstonetoolsmeasurementupdated";
    const element = evt.detail.element;
    const eventData = {
      toolName: this.name,
      toolType: this.name,
      element,
      measurementData: data,
    };

    triggerEvent(element, eventType, eventData);
  }

  triggerEventMeasurementSelected(evt, data) {
    const eventType = "cornerstonetoolsmeasurementselected";
    const element = evt.detail.element;
    const eventData = {
      toolName: this.name,
      toolType: this.name,
      element,
      measurementData: data,
    };

    triggerEvent(element, eventType, eventData);
  }

  createNewMeasurement(eventData) {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      console.error(
        `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      );

      return;
    }

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        topRight: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        bottomLeft: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        left: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        top: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        right: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        bottom: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        initialRotation: eventData.viewport.rotation,
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  isMouseEnable(element) {
    const activeTool = getActiveTool(element, 1);
    if ("MultiArrowConnection" === activeTool?.name) return false;
    return true;
  }

  pointNearTool(element, data, coords, interactionType) {
    if (!this.isMouseEnable(element)) return;
    const isPointNearTool = this._pointNearTool(
      element,
      data,
      coords,
      interactionType
    );
    data.hovering = isPointNearTool;

    if (data && data.hasOwnProperty("locked") && data.locked) {
      return false;
    }

    return isPointNearTool;
  }

  _pointNearTool(element, data, coords, interactionType) {
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters) {
      console.warn(
        `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      );
    }

    if (!validParameters || data.visible === false) {
      return false;
    }

    const distance = interactionType === "mouse" ? 15 : 25;
    const startCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.start
    );
    const endCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.end
    );

    const rect = {
      left: Math.min(startCanvas.x, endCanvas.x) - 5,
      top: Math.min(startCanvas.y, endCanvas.y) - 5,
      width: Math.abs(startCanvas.x - endCanvas.x) + 10,
      height: Math.abs(startCanvas.y - endCanvas.y) + 10,
    };

    if (data.fillPolygon) return this._pointInRectangle(rect, coords);

    const distanceToPoint = external.cornerstoneMath.rect.distanceToPoint(
      rect,
      coords
    );

    return distanceToPoint < distance;
  }

  _pointInRectangle(rect, point) {
    if (!rect || !point) return false;
    if (
      point.x < rect.left ||
      point.y < rect.top ||
      point.x > rect.left + rect.width ||
      point.y > rect.top + rect.height
    ) {
      return false;
    }
    return true;
  }

  updateCachedStats(image, element, data) {
    const seriesModule =
      external.cornerstone.metaData.get("generalSeriesModule", image.imageId) ||
      {};
    const modality = seriesModule.modality;
    const pixelSpacing = getPixelSpacing(image);

    const stats = _calculateStats(
      image,
      element,
      data.handles,
      modality,
      pixelSpacing
    );

    data.cachedStats = stats;
    data.invalidated = false;

    this._updateHandles(data);
  }

  _validateHandles(data) {
    if (!data || !data.handles || !data.handles.start || !data.handles.end)
      return;
    const xArray = [data.handles.start.x, data.handles.end.x];
    const yArray = [data.handles.start.y, data.handles.end.y];
    data.handles.start.x = Math.min(...xArray);
    data.handles.start.y = Math.min(...yArray);
    data.handles.end.x = Math.max(...xArray);
    data.handles.end.y = Math.max(...yArray);
  }

  _updateHandles(data) {
    if (!data || !data.handles || !data.handles.start || !data.handles.end)
      return;
    if (data.handles.top.moving) {
      data.handles.start.y = data.handles.top.y;
    } else if (data.handles.right.moving) {
      data.handles.end.x = data.handles.right.x;
    } else if (data.handles.bottom.moving) {
      data.handles.end.y = data.handles.bottom.y;
    } else if (data.handles.left.moving) {
      data.handles.start.x = data.handles.left.x;
    } else if (data.handles.topRight.moving) {
      data.handles.end.x = data.handles.topRight.x;
      data.handles.start.y = data.handles.topRight.y;
    } else if (data.handles.bottomLeft.moving) {
      data.handles.start.x = data.handles.bottomLeft.x;
      data.handles.end.y = data.handles.bottomLeft.y;
    }
  }

  _updateHandlesPosition(data) {
    if (!data || !data.handles || !data.handles.start || !data.handles.end)
      return;
    const rect = _getRectangleImageCoordinates(
      data.handles.start,
      data.handles.end
    );
    if (!rect) return;
    data.handles.top.x = rect.left + rect.width / 2;
    data.handles.top.y = rect.top;
    data.handles.right.x = rect.left + rect.width;
    data.handles.right.y = rect.top + rect.height / 2;
    data.handles.bottom.x = rect.left + rect.width / 2;
    data.handles.bottom.y = rect.top + rect.height;
    data.handles.left.x = rect.left;
    data.handles.left.y = rect.top + rect.height / 2;
    data.handles.topRight.x = rect.left + rect.width;
    data.handles.topRight.y = rect.top;
    data.handles.bottomLeft.x = rect.left;
    data.handles.bottomLeft.y = rect.top + rect.height;
  }
  renderToolData(evt) {
    const toolData = getToolState(evt.currentTarget, this.name);
    if (!toolData) {
      return;
    }

    const eventData = evt.detail;
    const { image, element } = eventData;
    const lineWidth = toolStyle.getToolWidth();
    const lineDash = getModule("globalConfiguration").configuration.lineDash;
    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
    } = this.configuration;

    const context = getNewContext(eventData.canvasContext.canvas);
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    // Meta
    const seriesModule =
      external.cornerstone.metaData.get("generalSeriesModule", image.imageId) ||
      {};

    // Pixel Spacing
    const modality = seriesModule.modality;
    const hasPixelSpacing = rowPixelSpacing && colPixelSpacing;

    draw(context, (context) => {
      // If we have tool data for this element - iterate over each set and draw it
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        drawIoUValue(context, element, data);

        if (data.type === "text") continue;

        if (data.visible === false) continue;

        // Configure
        const color = toolColors.getColorIfActive(data);
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        setShadow(context, this.configuration);

        const rectOptions = { color };

        if (renderDashed || data.renderDashed) {
          rectOptions.lineDash = lineDash;
        }

        drawRect(
          context,
          element,
          data.handles.start,
          data.handles.end,
          rectOptions,
          "pixel",
          data.handles.initialRotation
        );

        if (data.fillPolygon) {
          drawFillRect(
            context,
            element,
            data.handles.start,
            data.handles.end,
            rectOptions,
            "pixel",
            data.handles.initialRotation,
            data.opacity ? data.opacity / 100 : 0.2
          );
        }

        if (this.configuration.drawHandles && data.active === true) {
          this._updateHandlesPosition(data);
          drawHandles(context, eventData, data.handles, handleOptions);
        }

        // Update textbox stats
        if (data.invalidated === true) {
          if (data.cachedStats) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        // Default to textbox on right side of ROI
        if (!data.handles.textBox.hasMoved) {
          const defaultCoords = getROITextBoxCoords(
            eventData.viewport,
            data.handles
          );

          Object.assign(data.handles.textBox, defaultCoords);
        }

        const textBoxAnchorPoints = (handles) =>
          _findTextBoxAnchorPoints(handles.start, handles.end);

        data.unit = _getUnit(modality, this.configuration.showHounsfieldUnits);

        drawTextValue(context, element, data);
        drawActionVote(context, element, data);

        const text = data.label;

        if (!data.iou && data.showLabel && data.label) {
          drawLinkedTextBox(
            context,
            element,
            data.handles.textBox,
            text || "",
            data.handles,
            textBoxAnchorPoints,
            "#FFFFFF",
            lineWidth,
            0,
            false,
            data.text,
            data.vote
          );
        } else {
          // This is a workaround for ignore handle when it is not visible
          if (data?.handles?.textBox?.boundingBox) {
            data.handles.textBox.boundingBox.width = 0;
            data.handles.textBox.boundingBox.height = 0;
          }
        }
      }
    });
  }
}

/**
 * TODO: This is the same method (+ GetPixels) for the other ROIs
 * TODO: The pixel filtering is the unique bit
 *
 * @param {*} startHandle
 * @param {*} endHandle
 * @returns {{ left: number, top: number, width: number, height: number}}
 */
function _getRectangleImageCoordinates(startHandle, endHandle) {
  if (!startHandle || !endHandle) return null;
  return {
    left: Math.min(startHandle.x, endHandle.x),
    top: Math.min(startHandle.y, endHandle.y),
    width: Math.abs(startHandle.x - endHandle.x),
    height: Math.abs(startHandle.y - endHandle.y),
  };
}

/**
 *
 *
 * @param {*} image
 * @param {*} element
 * @param {*} handles
 * @param {*} modality
 * @param {*} pixelSpacing
 * @returns {Object} The Stats object
 */
function _calculateStats(image, element, handles, modality, pixelSpacing) {
  // Retrieve the bounds of the rectangle in image coordinates
  const roiCoordinates = _getRectangleImageCoordinates(
    handles.start,
    handles.end
  );
  if (!roiCoordinates) {
    return {
      area: 0,
      count: 0,
      mean: 0,
      variance: 0,
      stdDev: 0,
      min: 0,
      max: 0,
      meanStdDevSUV: "",
    };
  }

  // Retrieve the array of pixels that the rectangle bounds cover
  const pixels = external.cornerstone.getPixels(
    element,
    roiCoordinates.left,
    roiCoordinates.top,
    roiCoordinates.width,
    roiCoordinates.height
  );

  // Calculate the mean & standard deviation from the pixels and the rectangle details
  const roiMeanStdDev = _calculateRectangleStats(pixels, roiCoordinates);

  let meanStdDevSUV;

  if (modality === "PT") {
    meanStdDevSUV = {
      mean: calculateSUV(image, roiMeanStdDev.mean, true) || 0,
      stdDev: calculateSUV(image, roiMeanStdDev.stdDev, true) || 0,
    };
  }

  // Calculate the image area from the rectangle dimensions and pixel spacing
  const area =
    roiCoordinates.width *
    (pixelSpacing.colPixelSpacing || 1) *
    (roiCoordinates.height * (pixelSpacing.rowPixelSpacing || 1));

  return {
    area: area || 0,
    count: roiMeanStdDev.count || 0,
    mean: roiMeanStdDev.mean || 0,
    variance: roiMeanStdDev.variance || 0,
    stdDev: roiMeanStdDev.stdDev || 0,
    min: roiMeanStdDev.min || 0,
    max: roiMeanStdDev.max || 0,
    meanStdDevSUV,
  };
}

/**
 *
 *
 * @param {*} sp
 * @param {*} rectangle
 * @returns {{ count, number, mean: number,  variance: number,  stdDev: number,  min: number,  max: number }}
 */
function _calculateRectangleStats(sp, rectangle) {
  let sum = 0;
  let sumSquared = 0;
  let count = 0;
  let index = 0;
  let min = sp ? sp[0] : null;
  let max = sp ? sp[0] : null;

  for (let y = rectangle.top; y < rectangle.top + rectangle.height; y++) {
    for (let x = rectangle.left; x < rectangle.left + rectangle.width; x++) {
      sum += sp[index];
      sumSquared += sp[index] * sp[index];
      min = Math.min(min, sp[index]);
      max = Math.max(max, sp[index]);
      count++; // TODO: Wouldn't this just be sp.length?
      index++;
    }
  }

  if (count === 0) {
    return {
      count,
      mean: 0.0,
      variance: 0.0,
      stdDev: 0.0,
      min: 0.0,
      max: 0.0,
    };
  }

  const mean = sum / count;
  const variance = sumSquared / count - mean * mean;

  return {
    count,
    mean,
    variance,
    stdDev: Math.sqrt(variance),
    min,
    max,
  };
}

/**
 *
 *
 * @param {*} startHandle
 * @param {*} endHandle
 * @returns {Array.<{x: number, y: number}>}
 */
function _findTextBoxAnchorPoints(startHandle, endHandle) {
  const rect = _getRectangleImageCoordinates(startHandle, endHandle);
  if (!rect) return [];
  const { left, top, width, height } = rect;

  return [
    {
      // Top middle point of rectangle
      x: left + width / 2,
      y: top,
    },
    {
      // Left middle point of rectangle
      x: left,
      y: top + height / 2,
    },
    {
      // Bottom middle point of rectangle
      x: left + width / 2,
      y: top + height,
    },
    {
      // Right middle point of rectangle
      x: left + width,
      y: top + height / 2,
    },
  ];
}

/**
 *
 *
 * @param {*} area
 * @param {*} hasPixelSpacing
 * @returns {string} The formatted label for showing area
 */
function _formatArea(area, hasPixelSpacing) {
  // This uses Char code 178 for a superscript 2
  const suffix = hasPixelSpacing
    ? ` mm${String.fromCharCode(178)}`
    : ` px${String.fromCharCode(178)}`;

  return `Area: ${numbersWithCommas(area.toFixed(2))}${suffix}`;
}

function _getUnit(modality, showHounsfieldUnits) {
  return modality === "CT" && showHounsfieldUnits !== false ? "HU" : "";
}

/**
 * TODO: This is identical to EllipticalROI's same fn
 * TODO: We may want to make this a utility for ROIs with these values?
 *
 * @param {*} context
 * @param {*} isColorImage
 * @param {*} { area, mean, stdDev, min, max, meanStdDevSUV }
 * @param {*} modality
 * @param {*} hasPixelSpacing
 * @param {*} [options={}]
 * @returns {string[]}
 */
function _createTextBoxContent(
  context,
  isColorImage,
  { area, mean, stdDev, min, max, meanStdDevSUV },
  modality,
  hasPixelSpacing,
  options = {}
) {
  const showMinMax = options.showMinMax || false;
  const textLines = [];

  const otherLines = [];

  if (!isColorImage) {
    const hasStandardUptakeValues = meanStdDevSUV && meanStdDevSUV.mean !== 0;
    const unit = _getUnit(modality, options.showHounsfieldUnits);

    let meanString = `Mean: ${numbersWithCommas(mean.toFixed(2))} ${unit}`;
    const stdDevString = `Std Dev: ${numbersWithCommas(
      stdDev.toFixed(2)
    )} ${unit}`;

    // If this image has SUV values to display, concatenate them to the text line
    if (hasStandardUptakeValues) {
      const SUVtext = " SUV: ";

      const meanSuvString = `${SUVtext}${numbersWithCommas(
        meanStdDevSUV.mean.toFixed(2)
      )}`;
      const stdDevSuvString = `${SUVtext}${numbersWithCommas(
        meanStdDevSUV.stdDev.toFixed(2)
      )}`;

      const targetStringLength = Math.floor(
        context.measureText(`${stdDevString}     `).width
      );

      while (context.measureText(meanString).width < targetStringLength) {
        meanString += " ";
      }

      otherLines.push(`${meanString}${meanSuvString}`);
      otherLines.push(`${stdDevString}     ${stdDevSuvString}`);
    } else {
      otherLines.push(`${meanString}`);
      otherLines.push(`${stdDevString}`);
    }

    if (showMinMax) {
      let minString = `Min: ${min} ${unit}`;
      const maxString = `Max: ${max} ${unit}`;
      const targetStringLength = hasStandardUptakeValues
        ? Math.floor(context.measureText(`${stdDevString}     `).width)
        : Math.floor(context.measureText(`${meanString}     `).width);

      while (context.measureText(minString).width < targetStringLength) {
        minString += " ";
      }

      otherLines.push(`${minString}${maxString}`);
    }
  }

  textLines.push(_formatArea(area, hasPixelSpacing));
  otherLines.forEach((x) => textLines.push(x));

  return textLines;
}
