/*
 * File: observation.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { ObservationDTO, UpdateObservationStrategy } from "../dtos/observation.dto";
import { BaseAPI } from "./base.api";

export class ObservationAPI extends BaseAPI<ObservationDTO> {

  createObservation(payload: Partial<ObservationDTO>){
    return this.post(`${this.getPath()}`, payload);
  }

  updateObservation(
    payload: Partial<ObservationDTO>,
    strategy: string = UpdateObservationStrategy.CREATE,
  ){
    return this.put(`${this.getPath()}/${payload.id}?strategy=${strategy}`, payload);
  }

  partialUpdateBulk(payload: Partial<ObservationDTO>[]){
    return this.patch(`${this.getPath()}/bulk`, payload);
  }

  partialUpdateBulkWithGenPriority(observations: ObservationDTO[]){
    // update order to the server
    const payload: Partial<ObservationDTO>[] = [];

    let crrPriority = 0;
    for (let obs of observations){
      let priority = crrPriority;
      if (obs.observationSetting.systemAttribute){
        priority = 0;
      } else {
        crrPriority++;
        priority = crrPriority
      }
      payload.push({
        id: obs.id,
        priority: priority,
      });
    }
  
    // async to the server, no need to await
    if (payload){
      return this.partialUpdateBulk(payload);
    }
  }

  getObservationAnnotationTypesByProjectId(projectId: number | string) {
    return this.get(`${this.getPath()}/annotationType?projectId=${projectId}`);
  }

  exportObservations(projectId: number | string) {
    return this.get(`${this.getPath()}/export?projectId=${projectId}`);
  }

  importObservations(projectId: number | string, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return this.post(`${this.getPath()}/import?projectId=${projectId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }
    )
  }
}
