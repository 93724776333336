/*
 * File: three-d-labeling.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 24th April 2023 9:01:27 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Backdrop, LinearProgress } from "@material-ui/core";
import { MatModal } from "components/material/mat-modal.component";
import { useAppDispatch } from "hooks/use-redux";
import { BatchDetailDialog } from "pages/labeler/image-labeling/components/batch-detail-dialog/batch-detail-dialog.component";
import { usePreviousBatch } from "pages/labeler/image-labeling/hooks/use-previous-batch.hook";
import { ComplexJobProviderProps } from "pages/labeler/speech-to-text-labeling/context/speech-to-text-labeling.provider";
import { useLoadComplexJob } from "pages/labeler/speech-to-text-labeling/hooks/use-load-complex-job.hook";
import { ConfirmExitDialog } from "pages/labeler/text-labeling/components/confirm-exit-dialog.component";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { AnnotationsService } from "services/label-service";
import { StorageService } from "services/storage";
import { RequestStatus } from "store/base/base.state";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import {
  deleteComplexJobsJobInBatch,
  setComplexJobsJobData,
  setComplexJobWorkingStatus,
  setComplexJobWorkingStatusByJobId,
} from "store/labeler/complex-jobs/complex-jobs.slice";
import { LoadCombplexJobResponse } from "store/labeler/complex-jobs/complex-jobs.thunk";
import { AppError } from "utilities/errors/errors";
import { ThreeDLabelingContext } from "./three-d-labeling.context";
import { DEFAULT_UI_JOB, ThreeDJobUIModel } from "./three-d-labeling.state";
import {
  blobToVTKImage,
  createNewLabelMapFromImageData,
  vtkImageToBlob,
} from "../utils";
import {
  addTagToReleaseQueue,
  addToVTKObjects,
  releaseAllVTKObjects,
  VTK_OBJECT_TAG_IMAGE_DATA,
} from "../components/vtk-objects-manager";
import {
  annotationsToLabelMapOptions,
  batchObservationsToEditorLabelOptions,
  iouAnnotationsMapper,
  sliceAnnoItemAndObsToWorkingMetadata,
  sliceAnnoItemMasksToEditorLabels,
  sliceAnnoItemToLabelMapAndMask,
} from "./three-d-labeling.mappers";
import { StorageResource } from "services/storage/dto/resource.dto";
import {
  EDITOR_LABEL_NONE,
  LabelMapOption,
  PREVIEW_MASK,
  ThreeDEditorEvents,
} from "../components/three-d-editor.models";
import { delaySecond } from "services/mock";
import { useAppPrevious } from "hooks/use-app-previous";
import { jobMapper } from "services/label-service/mappers/job.mapper";
import {
  AnnotationAttributeItem,
  AnnotationJobRequestDTO,
  SliceAnnotationItem,
  SliceAnnotationItemMask,
} from "services/label-service/dtos/annotations.dto";
import { get3DCTTagsData } from "utilities/dicom/dicom.utils";
import { WorkingStatus } from "store/labeler/complex-jobs/complex-jobs.state";
import { changeLabelMapMask } from "../components/three-d-editor.utils";
import { computeMaskSize } from "../opencv.utils";
import * as Sentry from "@sentry/react";

interface Props extends ComplexJobProviderProps {}

export const ThreeDLabelingProvider = ({
  children,
  isLoadFromBatch = false,
  jobInBatch,
  isTaskReview,
  taskToReview,
  jobIdsIncludedOnly,
  isFromJobId,
  jobIdParam,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const history = useHistory();
  const [confirmExit, setShowConfirmExit] = useState(false);
  const handleConfirmExit = () => setShowConfirmExit(true);

  const [isEditorReady, setIsEditorReady] = useState(false);

  const uiJobRef = useRef<ThreeDJobUIModel>();
  const { showBatchInstruction, setShowBatchInstruction } = usePreviousBatch(
    uiJobRef.current?.job ? jobMapper.fromDTO(uiJobRef.current.job) : undefined
  );
  const [hasChanged, setHasChanged] = useState(false);

  const hasWorkingIssues = false;

  const loadJobFromComplexRes = useCallback(
    async (jobComplexRes: LoadCombplexJobResponse, uiJobData?: any) => {
      setIsEditorReady(false);
      if (uiJobData) {
        await delaySecond(0.5); // Tricky stuff: delay a bit for displaying loading
        uiJobRef.current = uiJobData;
        setHasChanged(true);
        dispatch(setComplexJobWorkingStatus(WorkingStatus.WORKING));
        return;
      }

      const {
        job,
        isStepReviewJob,
        canViewPreviousStepResult,
        file,
        task,
        batch,
        annotations,
        previousJobsAnnotations,
        jobOptions,
        batchObservations,
        acceptBatchObservation,
        rejectBatchObservation,
        reopenReason,
        isTaskReview,
        isFromJobId,
        isFromBatch,
        error,
      } = jobComplexRes;

      if (error) {
        throw error;
      }

      if (!file || !task || !batch) {
        throw new AppError(
          "unknow",
          "Unknow error after calling loadJobComplex function"
        );
      }
      const observations = batchObservations.map((bo) => bo.observation);

      // image data tag
      let vtkImageDataTag = "";
      if (job) {
        vtkImageDataTag = `${VTK_OBJECT_TAG_IMAGE_DATA}_${job.id}`;
      } else {
        vtkImageDataTag = `${VTK_OBJECT_TAG_IMAGE_DATA}_${task.id}`;
      }

      // convert blob to vtkImageData
      // the url is already cached to localhost so no need to concat sas token
      const res = await StorageService.getPublicFileContentAsBlob(file.url);
      const blob = res.data as Blob;
      const imageData = await blobToVTKImage(blob);
      addToVTKObjects(imageData, false, vtkImageDataTag);

      // convert batchObservations to editorLabelOptions
      const editorLabelOptions =
        batchObservationsToEditorLabelOptions(batchObservations);

      // convert previous task obsersvations to label map options
      const labelMapOptions: LabelMapOption[] =
        await annotationsToLabelMapOptions(
          observations,
          previousJobsAnnotations,
          acceptBatchObservation?.observation,
          rejectBatchObservation?.observation,
          batch.project.workspaceId
        );

      // check resource if have saved data
      const hasSavedData = annotations.length > 0;

      // get dicom data from file
      const dicomTagsData = get3DCTTagsData(
        file?.additionalProperties?.metadata?.metadata
      );

      const iouData = iouAnnotationsMapper(jobComplexRes);

      let uiJobCommon: ThreeDJobUIModel = {
        ...DEFAULT_UI_JOB,
        imageData,
        editorLabelOptions,
        labelMapOptions,
        vtkImageDataTag,
        job,
        isStepReviewJob,
        canViewPreviousStepResult,
        task,
        batch,
        batchObservations,
        project: batch.project,
        countDownSecond: 0,
        jobOptions,
        acceptBatchObservation,
        rejectBatchObservation,
        reopenReason,
        isTaskReview,
        isFromJobId,
        isFromBatch,
        dicomTagsData,
        iouData,
      };

      if (hasSavedData) {
        // convert saved annotations to label map (vtkImageData)
        const savedAnno: SliceAnnotationItem = annotations[0]
          .annotation as SliceAnnotationItem;
        if (savedAnno.resourceId && !isStepReviewJob) {
          const { resource, labelMap, masks } =
            await sliceAnnoItemToLabelMapAndMask(
              savedAnno,
              false,
              batch.project.workspaceId
            );

          if (!resource || !labelMap) {
            throw new AppError("unknow", "Unknow resource or labelMap");
          }
          uiJobCommon = {
            ...uiJobCommon,
            resource,
            workingLabelMap: labelMap,
            workingSegments: sliceAnnoItemMasksToEditorLabels(
              masks,
              observations
            ),
            workingMetadata: sliceAnnoItemAndObsToWorkingMetadata(
              observations,
              savedAnno
            ),
          };
          addToVTKObjects(labelMap, false, vtkImageDataTag);
        }
      } else {
        // create empty label map from imageData
        const workingLabelMap = createNewLabelMapFromImageData(imageData);
        uiJobCommon = {
          ...uiJobCommon,
          workingLabelMap,
          workingSegments: [EDITOR_LABEL_NONE],
          workingMetadata: sliceAnnoItemAndObsToWorkingMetadata(
            observations,
            undefined
          ),
        };
        addToVTKObjects(workingLabelMap, false, vtkImageDataTag);
      }
      uiJobRef.current = uiJobCommon;
    },
    [dispatch]
  );

  const {
    loadJobStatus,
    savingJobStatus,
    setSavingJobStatus,
    isSavingJob,
    isJobLoaded,
    error,
    setError,
    skipJob,
  } = useLoadComplexJob({
    providerPayload: {
      isLoadFromBatch,
      jobInBatch,
      isTaskReview,
      taskToReview,
      jobIdsIncludedOnly,
      isFromJobId,
      jobIdParam,
    },
    loadJobFromComplexResCallback: loadJobFromComplexRes,
  });
  const isLoadingJob = useMemo(
    () => loadJobStatus === RequestStatus.LOADING || !isEditorReady,
    [loadJobStatus, isEditorReady]
  );
  const previousIsLoadingJob = useAppPrevious(isLoadingJob);
  const previousIsSavingJob = useAppPrevious(isSavingJob);

  useEffect(() => {
    if (!isLoadingJob && previousIsLoadingJob) {
      document.dispatchEvent(
        new CustomEvent(ThreeDEditorEvents.COMMAND_CHECK_SELF_ACTIVE_WINDOW, {})
      );
    }
  }, [isLoadingJob, previousIsLoadingJob]);

  useEffect(() => {
    if (!isSavingJob && previousIsSavingJob) {
      document.dispatchEvent(
        new CustomEvent(ThreeDEditorEvents.COMMAND_CHECK_SELF_ACTIVE_WINDOW, {})
      );
    }
  }, [previousIsSavingJob, isSavingJob]);

  useEffect(() => {
    return () => {
      releaseAllVTKObjects();
    };
  }, []);

  const serializeUIJob = useCallback(
    (
      uiJob: ThreeDJobUIModel,
      resourceId: string | undefined,
      accept = false,
      reject = false
    ): AnnotationJobRequestDTO => {
      const annoItem: SliceAnnotationItem = {};

      if (resourceId && !uiJob.isStepReviewJob) {
        annoItem.resourceId = resourceId;
      }

      if (accept && uiJob.acceptBatchObservation) {
        annoItem.observationId = uiJob.acceptBatchObservation.observation.id;
      } else if (reject && uiJob.rejectBatchObservation) {
        annoItem.observationId = uiJob.rejectBatchObservation.observation.id;
      }

      // convert working labels/segments to item masks
      // each mask corresponds to a observation
      const mask: SliceAnnotationItemMask[] = uiJob.workingSegments
        .filter((s) => s.maskValue > 0 && !!!s.isPreview)
        .map((segment) => {
          return {
            maskValue: segment.maskValue,
            observationId: segment.labelOptionId,
            attributes: segment.attributes,
            estimatedSize: segment.estimatedSize,
          };
        });
      annoItem.mask = mask;

      // Metadata => attributes
      const attributes: AnnotationAttributeItem[] = [];
      for (const metadataRow of uiJob.workingMetadata) {
        for (const attr of metadataRow.attributes) {
          if (attr.values.length > 0) {
            attributes.push({
              id: attr.id,
              value: attr.values,
            });
          }
        }
      }
      annoItem.attributes = attributes;

      const requestPayload: AnnotationJobRequestDTO = {
        annotations: [
          {
            observationId:
              !!annoItem.observationId && annoItem.observationId > 0
                ? annoItem.observationId
                : undefined,
            annotation: annoItem,
          },
        ],
      };

      return requestPayload;
    },
    []
  );

  const updateMaskSizes = useCallback(
    (finish: boolean) => {
      if (!uiJobRef.current || !uiJobRef.current.job) return undefined;

      const maskValues = uiJobRef.current.workingSegments.map(
        (s) => s.maskValue
      );
      const maskSizes: Record<number, number> = {};
      for (const maskValue of maskValues) {
        try {
          maskSizes[maskValue] = computeMaskSize(
            uiJobRef.current.workingLabelMap,
            maskValue
          );
        } catch (e) {
          Sentry.captureException(e);
          console.log(e);
          maskSizes[maskValue] = 0;
        }
      }

      const newValue: ThreeDJobUIModel = {
        ...uiJobRef.current,
        workingSegments: uiJobRef.current.workingSegments.map((s) => ({
          ...s,
          estimatedSize: maskSizes[s.maskValue],
        })),
      };

      if (!finish) {
        dispatch(
          setComplexJobsJobData({
            id: uiJobRef.current.job.id,
            data: newValue,
          })
        );
      }
      uiJobRef.current = newValue;

      return newValue;
    },
    [dispatch]
  );

  const saveJob = useCallback(
    async (
      force = false,
      finish = false,
      accept = false,
      reject = false,
      jobId = 0
    ) => {
      if (savingJobStatus === RequestStatus.LOADING) return;
      if (savingJobStatus !== RequestStatus.IDLE && !force) return;
      if (!uiJobRef.current) return;

      const uiJob = uiJobRef.current;
      const id = jobId || uiJob.job?.id;

      setSavingJobStatus(RequestStatus.LOADING);

      try {
        let resource = uiJob.resource;
        if (uiJob.workingLabelMap && !uiJob.isStepReviewJob) {
          // get new resouce
          let res = await StorageService.createStorageResource(
            "Labeling",
            "nii.gz",
            uiJob.project?.workspaceId
          );
          resource = res.data as StorageResource;

          // change all preview masks to 0 incase press save while using
          // fill between slices
          changeLabelMapMask(uiJob.workingLabelMap, PREVIEW_MASK, 0);

          // convert label map to blob
          const labelMapBlob = await vtkImageToBlob(uiJob.workingLabelMap);
          await StorageService.uploadToAzureBlob(labelMapBlob, resource.path);

          // process resouce
          await StorageService.processStorageResource(resource.resourceId);
        }

        if ((!resource && !uiJob.isStepReviewJob) || !uiJob.job) {
          dispatch(enqueueErrorNotification(t("common:textSavedFailed")));
          return;
        }

        updateMaskSizes(finish);

        // save job
        const payload: AnnotationJobRequestDTO = serializeUIJob(
          uiJobRef.current, // need to use the current because we updated the sizes
          resource?.resourceId,
          accept,
          reject
        );

        if (finish) {
          if (accept && uiJob.isStepReviewJob && hasWorkingIssues) {
            throw new AppError(
              "have_working_issues",
              t("labelerworkspace:stt.errorHaveWorkingIssues")
            );
          }

          await AnnotationsService.saveAnnotationsForJobId(id, payload);
          await AnnotationsService.finishJob(id);

          if (uiJob.job && uiJob.isFromBatch) {
            addTagToReleaseQueue(uiJob.vtkImageDataTag);
            dispatch(deleteComplexJobsJobInBatch(id));
          } else if (uiJob.isFromJobId) {
            history.push(Routes.LABELER_HOME);
          }
        } else {
          await AnnotationsService.saveAnnotationsForJobId(id, payload);
        }

        setSavingJobStatus(RequestStatus.SUCCESS);
        setHasChanged(false);
        dispatch(
          setComplexJobWorkingStatusByJobId({
            jobId: id,
            status: WorkingStatus.SAVED,
          })
        );

        if (finish) {
          dispatch(enqueueSuccessNotification(t("common:textCompleted")));
        } else {
          dispatch(enqueueSuccessNotification(t("common:textSaved")));
        }
        const event = new CustomEvent("THREE_D_SAVE_JOB");
        window.dispatchEvent(event);
        try {
          Sentry.captureEvent({ event_id: "THREE_D_SAVE_JOB" });
        } catch (error) {}
      } catch (err: any) {
        Sentry.captureException(err);
        setSavingJobStatus(RequestStatus.FAILURE);
        if (err instanceof AppError) {
          dispatch(enqueueErrorNotification(err.message));
        } else {
          if (finish) {
            dispatch(enqueueErrorNotification(t("common:textCompletedFailed")));
          } else {
            dispatch(enqueueErrorNotification(t("common:textSavedFailed")));
          }
        }
      }
    },
    [
      uiJobRef,
      savingJobStatus,
      dispatch,
      t,
      updateMaskSizes,
      serializeUIJob,
      hasWorkingIssues,
      setSavingJobStatus,
      history,
    ]
  );

  const state = {
    uiJobRef,
    isLoadingJob,
    isSavingJob,
    saveJob,
    skipJob,
    handleConfirmExit,
    error,
    setError,
    hasChanged,
    setHasChanged,
    setIsEditorReady,
  };

  return (
    <ThreeDLabelingContext.Provider value={state}>
      {children}
      {(isLoadingJob || isSavingJob || !isEditorReady) && <MaskLoading />}
      {confirmExit && (
        <ConfirmExitDialog
          visible
          onClose={() => setShowConfirmExit(false)}
          onSubmit={() => history.push(Routes.LABELER_HOME)}
        />
      )}
      {isJobLoaded &&
        showBatchInstruction &&
        !isTaskReview &&
        uiJobRef.current?.batch &&
        uiJobRef.current.project && (
          <BatchDetailDialog
            batch={uiJobRef.current.batch}
            project={uiJobRef.current.project}
            onClose={() => setShowBatchInstruction(false)}
          />
        )}
    </ThreeDLabelingContext.Provider>
  );
};

const MaskLoading = () => {
  return (
    <MatModal
      open
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      className="flex items-start justify-center"
    >
      <div className="w-full h-full">
        <LinearProgress />
        <div className="flex items-center justify-center w-full h-full"></div>
      </div>
    </MatModal>
  );
};
