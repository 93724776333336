/*
 * File: smart-labeling.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 17th December 2021 11:41:26 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Backdrop, Grow } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import {
  IconAddItem,
  IconArrowForward,
  IconCheckbox,
  IconCheckboxChecked,
  IconCloseCircle,
  IconInformationCircleOutline,
  IconTrash,
} from "components/common/vb-icon.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import {
  AutoAnnotatePair,
  ObjectDetectionClass,
} from "store/labeler/image-workspace/smart-labeling/smart-labeling.state";
import { classnames } from "utilities/classes";
import { OptionProps, SingleValue } from "react-select";
import { v4 } from "uuid";
import { AnnotateType } from "constants/annotation.constant";
import { ReactComponent as FreehandRoiIcon } from "assets/cornerstone/icons/pen.svg";
import { ReactComponent as RectangleRoiIcon } from "assets/cornerstone/icons/rectangle.svg";
import { useAppDispatch } from "hooks/use-redux";
import { autoAnnotatePairsEdited } from "store/labeler/image-workspace/smart-labeling/smart-labeling.slice";
import { MatModal } from "components/material/mat-modal.component";

interface Props {
  autoAnnotatePairs: AutoAnnotatePair[];
  avaiableClasses: { [key: string]: ObjectDetectionClass };
  labels: { [key: string]: ObjectDetectionClass };
  processing?: boolean;
  onSubmit?(pairs: AutoAnnotatePair[], applyForAllImage: boolean): void;
  onClose?(): void;
}
export const SmartLabelingComponent = ({
  labels,
  avaiableClasses,
  autoAnnotatePairs,
  onSubmit,
  processing,
}: Props) => {
  const dispatch = useAppDispatch();
  const [addPair, setAddPair] = useState(false);
  const [pairs, setPairs] = useState(autoAnnotatePairs);
  const containerRef = useRef<HTMLDivElement>(null);
  const [onlyCurrentImage, setOnlyCurrentImage] = useState(true);

  const handleAddPair = (labelId: string, classId: string) => {
    const modelClass = avaiableClasses[classId];
    setPairs([
      ...pairs,
      {
        id: v4(),
        labelId,
        classId,
        selected: true,
        model: modelClass.model,
      },
    ]);
    setAddPair(false);
  };

  const handlePairChange = (pair: AutoAnnotatePair) => {
    const newPairs = pairs.map((p) => {
      if (pair.id === p.id) {
        return { ...pair };
      }
      return p;
    });
    setPairs(newPairs);
  };

  const handleDeleteSelection = () => {
    setPairs(pairs.filter((p) => !p.selected));
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(pairs, !onlyCurrentImage);
  };

  const hasSelectedPair = !!pairs.find((p) => p.selected);

  useEffect(() => {
    return () => {
      dispatch(autoAnnotatePairsEdited(pairs));
    };
  }, [pairs, dispatch]);

  return (
    <div
      style={{ width: "32rem", backgroundColor: "#393736" }}
      className="flex flex-col text-white rounded"
      ref={containerRef}
    >
      <div className="grid flex-shrink-0 h-12 grid-cols-3 text-background-500">
        <button className="flex items-center justify-center font-semibold text-warning-500 gap-1.5 border-b-2 border-warning-500">
          <span>Auto detect</span>
          <IconInformationCircleOutline className="w-4 h-4" />
        </button>
        <button className="flex items-center justify-center gap-1.5 border-b-2 border-gray-600">
          Auto tracking
        </button>
        <button className="flex items-center justify-center gap-1.5 border-b-2 border-gray-600">
          Quick selection
        </button>
      </div>
      <div className="flex flex-col gap-4 px-4 my-6 overflow-y-auto max-h-80 dark-scrollbar">
        {pairs.length > 0 && (
          <div className="flex items-center justify-between text-lg">
            <h2>List pair label</h2>
          </div>
        )}

        {pairs.map((pair) => {
          return (
            <PairComponent
              pairs={pairs}
              labels={labels}
              avaiableClasses={avaiableClasses}
              autoAnnotatePair={pair}
              key={pair.classId + pair.labelId}
              onChange={handlePairChange}
            />
          );
        })}

        <button
          className="flex items-center justify-center flex-shrink-0 gap-2 px-4 border border-dashed rounded h-14 border-background-700 hover:bg-gray-800"
          onClick={() => setAddPair(true)}
        >
          <IconAddItem className="w-6 h-6 text-warning-500" />
          <span>Add labels</span>
        </button>
      </div>
      <div className="mb-4">
        <button
          onClick={() => setOnlyCurrentImage(!onlyCurrentImage)}
          className="flex items-center gap-2 px-4"
        >
          {onlyCurrentImage && (
            <IconCheckbox className="w-5 h-5 text-background-500" />
          )}
          {!onlyCurrentImage && (
            <IconCheckboxChecked className="w-5 h-5 text-warning-500" />
          )}
          <span className="text-background-500">
            Apply for all other images
          </span>
        </button>
      </div>
      <div className="flex justify-between px-4 mb-4">
        {hasSelectedPair && (
          <button
            onClick={handleDeleteSelection}
            className="flex items-center justify-center gap-1.5 px-3 h-9 rounded border border-warning-500 text-warning-500 bg-none"
          >
            <IconTrash />
            <span>Delete selection</span>
          </button>
        )}
        {!hasSelectedPair && <div></div>}
        <button
          disabled={processing}
          className={classnames(
            "text-white h-9 rounded w-40 flex items-center justify-center",
            {
              "bg-background-300 cursor-not-allowed": !hasSelectedPair,
              "bg-warning-500": hasSelectedPair,
            }
          )}
          onClick={handleSubmit}
        >
          {processing ? "Please wait.." : "Annotate"}
        </button>
      </div>

      {addPair && (
        <AddPairModal
          pairs={pairs}
          avaiableClasses={avaiableClasses}
          labels={labels}
          onClose={() => setAddPair(false)}
          onSubmit={handleAddPair}
        />
      )}
    </div>
  );
};

interface PairProps {
  autoAnnotatePair: AutoAnnotatePair;
  avaiableClasses: { [key: string]: ObjectDetectionClass };
  labels: { [key: string]: ObjectDetectionClass };
  pairs: AutoAnnotatePair[];
  onChange(pair: AutoAnnotatePair): void;
}
const PairComponent = ({
  autoAnnotatePair,
  labels,
  avaiableClasses,
  pairs,
  onChange,
}: PairProps) => {
  const classOptions = Object.keys(avaiableClasses).map((key) => {
    const item = avaiableClasses[key];
    return {
      value: item.id,
      label: item.name,
      types: item.supportedAnnotationTypes,
      model: item.model,
    };
  });
  const labelOptions = Object.keys(labels).map((key) => {
    const item = labels[key];
    return {
      value: item.id,
      label: item.name,
      types: item.supportedAnnotationTypes,
    };
  });

  const handleToggleSelect = () => {
    onChange({
      ...autoAnnotatePair,
      selected: !autoAnnotatePair.selected,
    });
  };

  const handleClassChange = (val: SingleValue<any>) => {
    const value = val?.value || "";
    const modelClass = avaiableClasses[value];
    const payload = {
      ...autoAnnotatePair,
      classId: value,
      model: modelClass.model,
    };
    onChange(payload);
  };

  const handleLabelChange = (val: SingleValue<any>) => {
    const value = val?.value || "";
    const payload = { ...autoAnnotatePair, labelId: value };
    onChange(payload);
  };

  return (
    <div
      className={classnames("flex items-center gap-2 p-3 rounded", {
        "border border-warning-500 rounded text-warning-500":
          !!autoAnnotatePair.selected,
        "border border-gray-700 rounded text-warning-500":
          !autoAnnotatePair.selected,
      })}
    >
      <button onClick={handleToggleSelect}>
        {!autoAnnotatePair.selected && (
          <IconCheckbox className="flex-shrink-0 w-4 h-4" />
        )}
        {autoAnnotatePair.selected && (
          <IconCheckboxChecked className="flex-shrink-0 w-4 h-4" />
        )}
      </button>
      <VBSelectComponent
        containerClassName="flex-1 text-sm bg-white rounded text-blueGray-700"
        defaultValue={classOptions.find(
          (option) => option.value === autoAnnotatePair.classId
        )}
        isOptionDisabled={(option) => {
          if (pairs.find((p) => p.classId === (option as any).value)) {
            return true;
          }
          return false;
        }}
        options={classOptions}
        onChange={handleClassChange}
        menuPortalTarget={document.body}
        components={{
          Option: AIModelOption,
        }}
      />
      <IconArrowForward className="flex-shrink-0 w-6 h-6" />
      <VBSelectComponent
        containerClassName="flex-1 text-sm bg-gray-300 rounded text-blueGray-700"
        defaultValue={labelOptions.find(
          (option) => option.value === autoAnnotatePair.labelId
        )}
        onChange={handleLabelChange}
        options={labelOptions}
        menuPortalTarget={document.body}
        isOptionDisabled={(option) => {
          const labelOptionId = (option as any).value as string;
          const label = labels[labelOptionId];
          const classModel = avaiableClasses[autoAnnotatePair.classId];
          return !isIntersect(
            label.supportedAnnotationTypes,
            classModel.supportedAnnotationTypes
          );
        }}
        components={{
          Option: LabelOption,
        }}
      />
    </div>
  );
};

function isIntersect(types: AnnotateType[], otherTypes: AnnotateType[]) {
  for (let type of types) {
    if (otherTypes.includes(type)) return true;
  }
  return false;
}
interface AddPairModalProps {
  onClose(): void;
  onSubmit(labelId: string, classId: string): void;
  avaiableClasses: { [key: string]: ObjectDetectionClass };
  labels: { [key: string]: ObjectDetectionClass };
  pairs: AutoAnnotatePair[];
}
const AddPairModal = ({
  onClose,
  avaiableClasses,
  labels,
  pairs,
  onSubmit,
}: AddPairModalProps) => {
  const [labelId, setLabelId] = useState("");
  const [classId, setClassId] = useState("");
  const classOptions = Object.keys(avaiableClasses).map((key) => {
    const item = avaiableClasses[key];
    return {
      value: item.id,
      label: item.name,
      types: item.supportedAnnotationTypes,
      model: item.model,
    };
  });
  const labelOptions = Object.keys(labels).map((key) => {
    const item = labels[key];
    return {
      value: item.id,
      label: item.name,
      types: item.supportedAnnotationTypes,
    };
  });

  const handleClassChange = (val: SingleValue<any>) => {
    const value = val?.value || "";
    setClassId(value);
  };

  const handleLabelChange = (val: SingleValue<any>) => {
    const value = val?.value || "";
    setLabelId(value);
  };
  const handleSubmit = () => {
    onSubmit(labelId, classId);
  };

  return (
    <MatModal
      open
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={onClose}
      disableBackdropClick
      className="flex items-center justify-center outline-none"
    >
      <Grow in>
        <div
          className="rounded"
          style={{ width: "32rem", backgroundColor: "#393736" }}
        >
          <div className="relative flex items-center justify-center h-12 text-white border-b border-gray-700">
            <span>Add pair labeling</span>
            <button
              onClick={onClose}
              className="absolute transform -translate-y-1/2 top-1/2 right-4"
            >
              <IconCloseCircle className="w-4 h-4" />
            </button>
          </div>
          <main className="flex flex-col flex-1 gap-6 p-4">
            <div className="flex items-center gap-5">
              <VBSelectComponent
                containerClassName="flex-1 text-sm text-white"
                className="text-gray-700"
                header="AI Label"
                options={classOptions}
                onChange={handleClassChange}
                isOptionDisabled={(option) => {
                  if (pairs.find((p) => p.classId === (option as any).value)) {
                    return true;
                  }
                  return false;
                }}
                menuPortalTarget={document.body}
                components={{
                  Option: AIModelOption,
                }}
              />
              <div>
                <div className="h-6" />
                <IconArrowForward className="w-6 h-6 text-warning-500" />
              </div>
              <VBSelectComponent
                containerClassName="flex-1 text-sm text-white"
                className="text-gray-700"
                header="Your Label"
                onChange={handleLabelChange}
                options={labelOptions}
                isOptionDisabled={(option) => {
                  const optionLabelId = (option as any).value;
                  if (classId) {
                    const pair = pairs.find(
                      (p) =>
                        p.classId === classId && p.labelId === optionLabelId
                    );
                    if (pair) return true;
                  }
                  const optionClass = avaiableClasses[classId];
                  const optionLabel = labels[optionLabelId];
                  return !isIntersect(
                    optionClass.supportedAnnotationTypes,
                    optionLabel.supportedAnnotationTypes
                  );
                }}
                menuPortalTarget={document.body}
                components={{
                  Option: LabelOption,
                }}
              />
            </div>

            <div className="flex items-center justify-end">
              <button
                disabled={!classId || !labelId}
                className={classnames(
                  "flex items-center justify-center w-40 rounded h-9 ",
                  {
                    "text-white bg-warning-500": !!labelId && !!classId,
                    "text-white bg-background-300": !labelId || !classId,
                  }
                )}
                onClick={handleSubmit}
              >
                Add pair
              </button>
            </div>
          </main>
        </div>
      </Grow>
    </MatModal>
  );
};

const AIModelOption = ({
  innerRef,
  innerProps,
  label,
  isDisabled,
  isFocused,
  isSelected,
  data,
}: OptionProps) => {
  return (
    <div
      key={label}
      ref={innerRef}
      {...innerProps}
      className={classnames("flex flex-col px-4 capitalize gap-.5 py-1.5", {
        "bg-warning-500 text-white": !isDisabled && isSelected,
        "bg-warning-50 text-blueGray-700":
          !isDisabled && isFocused && !isSelected,
        "text-gray-400 pointer-events-none": isDisabled,
      })}
    >
      <div className="truncate text whitespace-nowrap">{label}</div>
      <div className="flex items-center gap-2 text-xs flex-nowrap opacity-80">
        {(data as any).types.map((type: AnnotateType) => {
          if (type === AnnotateType.BOUNDING_BOX)
            return (
              <RectangleRoiIcon className="flex-none w-4 h-4" key={type} />
            );
          if (type === AnnotateType.POLYGON)
            return <FreehandRoiIcon className="flex-none w-4 h-4" key={type} />;
          return null;
        })}
        <span>{(data as any).model}</span>
      </div>
    </div>
  );
};

const LabelOption = ({
  innerRef,
  innerProps,
  label,
  isDisabled,
  isFocused,
  isSelected,
  data,
}: OptionProps) => {
  return (
    <div
      key={label}
      ref={innerRef}
      {...innerProps}
      className={classnames("flex items-center px-4 h-9 capitalize gap-2", {
        "bg-warning-500 text-white": !isDisabled && isSelected,
        "bg-warning-50 text-blueGray-700":
          !isDisabled && isFocused && !isSelected,
        "text-gray-400 pointer-events-none": isDisabled,
      })}
    >
      {(data as any).types.map((type: AnnotateType) => {
        if (type === AnnotateType.BOUNDING_BOX)
          return (
            <RectangleRoiIcon className="flex-shrink-0 w-5 h-5" key={type} />
          );
        if (type === AnnotateType.POLYGON)
          return (
            <FreehandRoiIcon className="flex-shrink-0 w-5 h-5" key={type} />
          );
        return null;
      })}
      <span className="flex-1 text-sm truncate whitespace-nowrap">{label}</span>
    </div>
  );
};
