/*
 * File: text-labeling-jobs.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 11th May 2022 3:29:28 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useMemo, useState } from "react";
import {
  selectTextLabelingTaskJobs,
  selectTextLabelingTaskSelectedAnnotator,
} from "store/labeler/text-labeling-task/text-labeling-task.selectors";
import { setTextLabelingTaskAsync } from "store/labeler/text-workspace/text-labeling/text-labeling.thunk";

export const TextLabelingJobs = () => {
  const dispatch = useAppDispatch();
  const jobs = useAppSelector(selectTextLabelingTaskJobs);
  const annotators = useAppSelector(selectTextLabelingTaskSelectedAnnotator);
  const [value, setValue] = useState(annotators);
  const options = useMemo(() => {
    const steps: {
      step: number;
      annotators: string[];
    }[] = [];
    for (const job of jobs) {
      const item = steps.find((step) => step.step === job.step);
      if (!item) {
        steps.push({ step: job.step, annotators: [job.assignee] });
      } else {
        item.annotators.push(job.assignee);
      }
    }
    if (steps.length === 1 && steps[0].annotators.length === 0) {
      steps.pop();
    }
    return [
      ...steps.map((step) => {
        return {
          label: `Step ${step.step} (${step.annotators.length} labelers)`,
          value: `step-${step.annotators.join(",")}`,
        };
      }),
      ...jobs.map((job) => ({ label: job.assignee, value: job.assignee })),
    ];
  }, [jobs]);

  function handleChange(option: any) {
    const assignee = option.value as string;
    setValue([assignee]);
    dispatch(setTextLabelingTaskAsync({ assignees: [assignee] }));
  }

  return (
    <div className="py-4 space-y-4">
      <div className="px-4">
        <VBSelectComponent
          header="Show labeling work"
          onChange={handleChange}
          value={options.find((option) => option.value === value[0])}
          options={options}
        />
      </div>
    </div>
  );
};
