/*
 * File: use-dataset-scope.hook.ts
 * Project: app-aiscaler-web
 * File Created: Sunday, 5th September 2021 10:31:24 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useCallback, useEffect, useState } from "react";
import { StorageService } from "services/storage";
import { RequestStatus } from "store/base/base.state";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import * as Sentry from "@sentry/react";

export const useDatasetScope = (datasetId: number) => {
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [scopeFiles, setScopeFiles] = useState<Record<string, number>>({});

  const requestData = useCallback(async (id: number) => {
    if (id <= 0) return;
    try {
      setRequestStatus(RequestStatus.LOADING);
      setRequestError(null);
      setScopeFiles({});
      const response = await StorageService.countScopeFiles(id);
      handleThunkRejected(response);
      setScopeFiles(response.data);
      setRequestStatus(RequestStatus.SUCCESS);
    } catch (err: any) {
      Sentry.captureException(err);
      Logger.error(err);
      const errMessage = err.message || "Failed to get dataset scopes files";
      setRequestError(errMessage);
    }
  }, []);

  useEffect(() => {
    if (datasetId) requestData(datasetId);
  }, [datasetId, requestData]);

  return {
    scopeFiles,
    requestStatus,
    requestError,
    requestData,
  };
};
