/*
 * File: batch-labeling.context.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 10th December 2021 11:38:49 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { defaultDashboardState } from "./dashboard.state";

export const DashboardContext = createContext(defaultDashboardState);

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};
