/*
 * File: editor-setting.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 30th March 2022 5:23:49 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { RootState } from "store";

export const selectIsAutoSaveConfig = (state: RootState) => {
  return state.editorSetting.autoSave;
}

export const selectIsShowBBoxRuer = (state: RootState) => {
  return state.editorSetting.showBBoxRuler;
};

export const selectIsShowKeyboardShortcuts = (state: RootState) => {
  return state.editorSetting.showKeyboardShortcuts;
};

export const selectIsShowEditorSettings = (state: RootState) => {
  return state.editorSetting.showEditorSettings;
};

export const selectIsShowTaskInfo = (state: RootState) => {
  return state.editorSetting.showTaskInfo;
};

export const selectIsShowAnnotationToolbar = (state: RootState) => {
  return state.editorSetting.showAnnotationToolbar;
};

export const selectIsShowWarningPopup = (state: RootState) => {
  return state.editorSetting.showWarningPopup;
};

export const selectIsCompleteJobsInView = (state: RootState) => {
  return state.editorSetting.completeJobsInView;
};

export const selectIsCompleteJobsInBatch = (state: RootState) => {
  return state.editorSetting.completeJobsInBatch;
};
