/*
 * File: scope.api.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 31st August 2021 4:46:49 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BaseAPI } from "services/base-service/api/base.api";
import { ScopeDTO } from "../dto/scope.dto";

export class ScopeAPI extends BaseAPI<ScopeDTO> {
  getScopes(): Promise<ScopeDTO[]> {
    return this.getItems({}).then((res) => res.data);
  }
}
