/*
 * File: created-by-header.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 19th August 2021 2:55:55 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { VBDatagridInputFilter } from "components/common/vb-datagrid/vb-datagrid-input-filter.component";

export const CreatedByHeader = () => {
  const { t } = useTranslation();
  const { filter, setCreatedBy } = useDatasetContext();
  return (
    <div className="w-full leading-4 mr-2">
      <p className="h-6">{t("dataset:header.textCreatedBy")}</p>
      <VBDatagridInputFilter
        onInput={(value) => setCreatedBy && setCreatedBy(value)}
        defaultValue={filter?.createdBy || ""}
      />
    </div>
  );
};
