/*
 * File: base.state.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 14th August 2021 2:41:08 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export enum RequestStatus {
  IDLE = "idle",
  LOADING = "loading",
  SUCCESS = "success",
  FAILURE = "failure",
  NONE = "none",
}

export enum LoadingStatus {
  IDLE = "idle",
  LOADING = "loading",
  LOADED = "loaded",
}

export interface BaseEntity {
  id: number;
}

export interface BaseState<T extends BaseEntity> {
  entities: Record<number, T>;
  ids: number[];
  id: number;
  status: RequestStatus;
  error: string | null;
}

export const DefaultBaseState = {
  entities: {},
  ids: [],
  id: -1,
  status: RequestStatus.IDLE,
  error: null,
};
