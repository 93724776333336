/*
 * File: use-image-annotations.hook copy.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 4th October 2022 3:24:07 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useImageEditorContext } from "pages/labeler/image-labeling/image-editor-context/image-editor.context";
import { useAppSelector } from "hooks/use-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  selectMammographyTask,
  selectMammographyTaskAnnotations,
  selectMammographyTaskRelations,
} from "store/labeler/mammography-labeling-task/mammography-labeling-task.selectors";

export default function useImageTaskAnnotations(taskId: number) {
  const { cornerstoneHandler } = useImageEditorContext();
  const labelingTask = useAppSelector(selectMammographyTask(taskId));
  const annotations = useAppSelector(selectMammographyTaskAnnotations(taskId));
  const relations = useAppSelector(selectMammographyTaskRelations(taskId));

  useDeepCompareEffect(() => {
    cornerstoneHandler.current?.syncImageAnnotations(annotations);
  }, [annotations, cornerstoneHandler]);

  useDeepCompareEffect(() => {
    cornerstoneHandler.current?.syncImageRelationAnnotations(relations);
  }, [relations, cornerstoneHandler]);

  return { labelingTask, annotations, relations };
}
