import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RequestOptions } from "services/common/request-options";
import { NotificationServiceImpl } from "services/notification-service";
import { RequestStatus } from "store/base/base.state";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { selectUserNotifications } from "store/common/user-notification/user-notification.selectors";
import {
  loadUserNotificationsAsync,
  loadUserNotificationUnreadCountAsync,
} from "store/common/user-notification/user-notification.thunk";
import useDeepCompareEffect from "use-deep-compare-effect";
import { mapperNotificationRow } from "./notifications.mappers";
import { NotificationFilter, NotificationRow } from "./notifications.models";
import * as Sentry from "@sentry/react";

export const useNotifications = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [totalRow, setTotalRow] = useState(0);
  const notifications = useAppSelector(selectUserNotifications);
  const [rows, setRows] = useState<NotificationRow[]>([]);

  useEffect(() => {
    return setRows(notifications.map(mapperNotificationRow.fromEntity));
  }, [notifications]);

  const [filter, setFilter] = useState<NotificationFilter>({
    page: 1,
    pageSize: 10,
    sort: "created_date,desc",
  });
  const requestStatus = useRef(RequestStatus.IDLE);

  const setFilterField = (field: keyof NotificationFilter, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });

    requestStatus.current = RequestStatus.IDLE;
  };

  const requestData = useCallback(
    async (options: RequestOptions) => {
      try {
        if (requestStatus.current === RequestStatus.LOADING) return;
        requestStatus.current = RequestStatus.LOADING;

        const { payload } = await dispatch(loadUserNotificationsAsync(options));
        setTotalRow((payload as any).totalItem);

        dispatch(loadUserNotificationUnreadCountAsync());

        requestStatus.current = RequestStatus.SUCCESS;
      } catch (error: any) {
        Sentry.captureException(error);
        requestStatus.current = RequestStatus.FAILURE;
      }
    },
    [dispatch]
  );

  const deleteNotification = async (row: NotificationRow) => {
    if (requestStatus.current === RequestStatus.LOADING) return;
    try {
      setRows(rows.filter((r) => r.id !== row.id));

      await NotificationServiceImpl.getEventsService().removeById(row.id);
      await requestData(NotificationFilter.toRequestOption(filter));

      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    }
  };

  useDeepCompareEffect(() => {
    if (!filter) return;
    requestData(NotificationFilter.toRequestOption(filter));
  }, [filter]);

  return {
    totalRow,
    rows,
    filter,
    setFilterField,
    deleteNotification,
  };
};
