import { VBSubMenu } from "components/common/vb-sub-menu/vb-sub-menu.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useMemo, useState } from "react";
import {
  selectIssueManagementDoesHaveEditPermission,
  selectIssueManagementIsCreatingIssueMode,
  selectIssueManagementIssueByFilter,
  selectIssueManagementShouldShowIssuesButton,
  selectIssueManagementTotalIssue,
} from "store/common/issue-management/issue-management.selectors";
import {
  setIsCreatingIssueMode,
  setIssueFilter,
} from "store/common/issue-management/issue-management.slice";
import { classnames } from "utilities/classes";
import { IssueStatus } from "./issue-management.models";
import { useIssueManagementContext } from "./issue-management.provider";

interface Props {
  position?: "topRight" | "bottomLeft";
  isFixed?: boolean;
  paddingTop?: number;
}
export const IssueButtons = ({
  position = "topRight",
  isFixed = false,
  paddingTop = 0,
}: Props) => {
  const { taskId, jobIds, jobId } = useIssueManagementContext();
  const dispatch = useAppDispatch();
  const hasEditPermision = useAppSelector(
    selectIssueManagementDoesHaveEditPermission
  );
  const isCreatingIssueMode = useAppSelector(
    selectIssueManagementIsCreatingIssueMode
  );
  const totalIssue = useAppSelector(
    selectIssueManagementTotalIssue(taskId, jobIds, jobId)
  );
  const totalOpened = useAppSelector(
    selectIssueManagementIssueByFilter(
      IssueStatus.OPENED,
      taskId,
      jobIds,
      jobId
    )
  ).length;
  const totalFixed = useAppSelector(
    selectIssueManagementIssueByFilter(IssueStatus.FIXED, taskId, jobIds, jobId)
  ).length;
  const totalResolved = useAppSelector(
    selectIssueManagementIssueByFilter(
      IssueStatus.RESOLVED,
      taskId,
      jobIds,
      jobId
    )
  ).length;
  const shouldShow = useAppSelector(
    selectIssueManagementShouldShowIssuesButton(taskId, jobIds, jobId)
  );

  const [isShowMenu, setIsShowMenu] = useState(false);

  const handleIssueButtonClicked = () => {
    if (!hasEditPermision) return;
    dispatch(setIsCreatingIssueMode(!isCreatingIssueMode));
    setIsShowMenu(false);
  };

  const menuOptions = useMemo(() => {
    let menuOptions = [
      {
        label: "Show all",
        value: "all",
      },
      {
        label: "Hide all",
        value: "none",
      },
      {
        label: `Opened (${totalOpened})`,
        value: IssueStatus.OPENED.toLocaleLowerCase(),
      },
      {
        label: `Fixed (${totalFixed})`,
        value: IssueStatus.FIXED.toLocaleLowerCase(),
      },
      {
        label: `Resolved (${totalResolved})`,
        value: IssueStatus.RESOLVED.toLocaleLowerCase(),
      },
    ];
    if (hasEditPermision) {
      menuOptions.unshift({
        label: !isCreatingIssueMode ? "Create" : "Off",
        value: !isCreatingIssueMode ? "create" : "off",
      });
    }
    return menuOptions;
  }, [
    hasEditPermision,
    isCreatingIssueMode,
    totalOpened,
    totalFixed,
    totalResolved,
  ]);

  const handleOptionClicked = (optionValue: string) => {
    if (optionValue === "create") {
      dispatch(setIsCreatingIssueMode(true));
    } else if (optionValue === "off") {
      dispatch(setIsCreatingIssueMode(false));
    } else {
      dispatch(setIssueFilter(optionValue));
    }
    setIsShowMenu(false);
  };

  return (
    <div
      className={classnames(
        "p-2",
        { absolute: !isFixed },
        { fixed: isFixed },
        { "right-0 top-0": position === "topRight" },
        { "left-0 bottom-0 ": position === "bottomLeft" },
        { hidden: !shouldShow }
      )}
      style={{
        zIndex: "101",
        top: `${paddingTop}px`,
      }}
    >
      <div
        className={classnames("flex items-start", {
          "flex-row-reverse items-end": position === "bottomLeft",
        })}
      >
        <div
          onMouseLeave={() => isShowMenu && setIsShowMenu(false)}
          onMouseEnter={() => setIsShowMenu(true)}
          className={classnames({ hidden: !isShowMenu })}
        >
          <VBSubMenu
            items={menuOptions}
            onItemClicked={(option) => handleOptionClicked(option.value)}
          />
        </div>
        <button
          className={classnames(
            "py-1 px-2 rounded-full text-sm cursor-pointer",
            { "bg-white text-black opacity-80": !isCreatingIssueMode },
            { "bg-primary text-white": isCreatingIssueMode }
          )}
          onClick={handleIssueButtonClicked}
          onMouseEnter={() => !isShowMenu && setIsShowMenu(true)}
          onMouseLeave={() => isShowMenu && setIsShowMenu(false)}
        >
          <span>
            {`Issues`}
            {totalIssue ? ` (${totalIssue})` : ""}
          </span>
        </button>
      </div>
    </div>
  );
};
