/*
 * File: text-viewer.component.tsx
 * Project: aiscaler-web
 * File Created: Friday, 22nd October 2021 5:46:35 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useMemo, useState } from "react";
import { TextViewerLoader } from "./text-viewer-loader.component";
import {
  SentenceModel,
  BoundingBox,
  TokenData,
} from "../../models/text-viewer.models";
import { buildTokens } from "./text-viewer.utils";
import { TextEditor } from "./text-editor.component";
import "./text-viewer.style.scss";
import { Label } from "domain/text-labeling";
import { CORAnnotation, NERAnnotation } from "domain/text-labeling";
import useDeepCompareEffect from "use-deep-compare-effect";

interface Props {
  tokenizer: string;
  labels: Label[];
  sentences: SentenceModel[];
  observations: NERAnnotation[];
  connections: CORAnnotation[];
  conflictTokenIds?: Record<string, string>;
  onSelectToken?(token: TokenData): void;
  onSelectAnnotation?(annotationId: string): void;
  onSelectRelation?(relationId: string): void;
  onMouseEnterRelation?(relationId: string): void;
  onMouseLeaveRelation?(relationId: string): void;
  readonly?: boolean;
}

export const TextViewer = ({
  tokenizer,
  labels,
  sentences,
  observations,
  connections,
  conflictTokenIds,
  onSelectToken,
  onSelectAnnotation,
  onSelectRelation,
  onMouseEnterRelation,
  onMouseLeaveRelation,
  readonly = false,
}: Props) => {
  const { tokenEntities, tokenIds } = useMemo(
    () => buildTokens(sentences, tokenizer),
    [sentences, tokenizer]
  );
  const [boxes, setBoundingBoxes] = useState<Record<string, BoundingBox>>({});
  const [loaded, setLoaded] = useState(false);
  const onLoaded = (tokenBoxes: Record<string, BoundingBox>) => {
    if (tokenBoxes && Object.keys(tokenBoxes).length > 0) {
      setBoundingBoxes(tokenBoxes);
      setLoaded(true);
    }
  };

  useDeepCompareEffect(() => {
    setLoaded(false);
  }, [sentences]);

  if (loaded) {
    return (
      <TextEditor
        readonly={readonly}
        tokenIds={tokenIds}
        tokenEntities={tokenEntities}
        boundingBoxes={boxes}
        labels={labels}
        observations={observations}
        connections={connections}
        conflictTokenIds={conflictTokenIds}
        onSelectToken={onSelectToken}
        onSelectAnnotation={onSelectAnnotation}
        onSelectRelation={onSelectRelation}
        onMouseEnterRelation={onMouseEnterRelation}
        onMouseLeaveRelation={onMouseLeaveRelation}
      />
    );
  }
  return (
    <TextViewerLoader
      labels={labels}
      tokens={tokenIds.map((tokenId) => tokenEntities[tokenId])}
      onLoaded={onLoaded}
    />
  );
};
