/*
 * File: notification-settings.api.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 16th September 2021 8:51:24 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BaseAPI } from "services/base-service/api/base.api";
import { setupLabelInterceptor } from "services/common/label-interceptor";
import { NotificationDTO } from "../dto/notification.dto";

export class NotificationSettingsAPI extends BaseAPI<NotificationDTO> {
  constructor(serviceUrl: string, path: string) {
    super(serviceUrl, path);
    this.axiosInstance = setupLabelInterceptor(this.axiosInstance);
  }

  getSettings(projectId?: string) {
    if (projectId) {
      const searchParams = new URLSearchParams({ projectId: `${projectId}` });
      return this.get(`${this.getPath()}?${searchParams.toString()}`);
    }
    return this.get(`${this.getPath()}`);
  }

  createSettings(payload: object) {
    return this.post(`${this.getPath()}`, payload);
  }
}
