/*
 * File: issue.ts
 * Project: design-system
 * File Created: Friday, 1st July 2022 11:00:32 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { User } from "./user";

export interface Issue {
  id: number;
  jobId: number;
  taskId: number;
  status: IssueStatus;
  creator: User;
  content: string;
  comments: IssueComment[];
  createdDate: string;
  metadata?: any;
}

export interface IssueComment {
  id: number;
  issueId: number;
  content: string;
  creator: User;
  createdDate: string;
}

export enum IssueStatus {
  OPENED = "Opened",
  FIXED = "Fixed",
  RESOLVED = "Resolved",
}
