/*
 * File: layout.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:12 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useLocalStorageState } from "ahooks";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { TextEditorSettingModal } from "pages/labeler/text-labeling/components/text-editor-settings/text-editor-settings.component";
import { TextKeyboardShortcutsModal } from "pages/labeler/text-labeling/components/text-keyboard-shortcuts/text-keyboard-shortcuts.component";
import {
  selectIsShowKeyboardShortcuts,
  selectIsShowEditorSettings,
} from "store/labeler/image-workspace/editor-setting/editor-setting.selectors";
import {
  keyboardShortcutsClosed,
  editorSettingsClosed,
} from "store/labeler/image-workspace/editor-setting/editor-setting.slice";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { TextTaskContext } from "./text-task.context";

interface Props {
  children: JSX.Element;
}

export const TextTaskProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [isFullScreen, setIsFullScreen] = useLocalStorageState(
    "TEXT_TASK_REVIEW_FULLSCREEN",
    false
  );

  const showKeyboardShortcuts = useAppSelector(selectIsShowKeyboardShortcuts);
  const showEditorSettings = useAppSelector(selectIsShowEditorSettings);

  function closeKeyboardShortcuts() {
    dispatch(keyboardShortcutsClosed());
  }

  function closeEditorSettings() {
    dispatch(editorSettingsClosed());
  }

  function toggleDisplayFullScreen() {
    setIsFullScreen(!isFullScreen);
  }

  const state = {
    isFullScreen,
    toggleDisplayFullScreen,
  };

  return (
    <TextTaskContext.Provider value={state}>
      {children}
      {showKeyboardShortcuts && (
        <TextKeyboardShortcutsModal open onClose={closeKeyboardShortcuts} />
      )}
      {showEditorSettings && (
        <TextEditorSettingModal
          mode={TextLabelingMode.CUSTOMER}
          open
          onClose={closeEditorSettings}
        />
      )}
    </TextTaskContext.Provider>
  );
};
