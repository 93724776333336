/*
 * File: use-cloud-tracking.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { useEffect, useRef } from "react";
import {
  selectCurrentDataset,
  selectNumberSyncingClouds,
} from "store/customer/dataset/dataset.selectors";
import { loadDatasetCloudsAsync } from "store/customer/dataset/dataset.slice";
import { enqueueSuccessNotification } from "store/common/notification/notification.actions";
import { useDetectChange } from "./use-detect-change";
import { useAppDispatch, useAppSelector } from "./use-redux";

export const useCloudTracking = () => {
  const dispatch = useAppDispatch();
  const currentDataset = useAppSelector(selectCurrentDataset);
  const numberSyncingClouds = useAppSelector(selectNumberSyncingClouds);
  const { requestData } = useDatasetContext();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const previosSyncingRef = useRef(0);
  const datasetIdRef = useRef(-1);
  const shouldRequestData = useDetectChange([dispatch, numberSyncingClouds]);

  useEffect(() => {
    datasetIdRef.current = currentDataset ? currentDataset.id : -1;
    previosSyncingRef.current = 0;
    const _loadData = async (datasetId: number) => {
      await dispatch(loadDatasetCloudsAsync(datasetId + ""));
    };
    if (currentDataset) _loadData(currentDataset.id);
  }, [dispatch, currentDataset]);

  useEffect(() => {
    if (timerRef.current) clearInterval(timerRef.current);
    if (numberSyncingClouds && currentDataset) {
      timerRef.current = setInterval(() => {
        dispatch(loadDatasetCloudsAsync(currentDataset.id + ""));
      }, 5000);
    }
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [dispatch, numberSyncingClouds, currentDataset]);

  useEffect(() => {
    if (!shouldRequestData) return;
    if (numberSyncingClouds === 0 && previosSyncingRef.current > 0) {
      dispatch(
        enqueueSuccessNotification("Your cloud syncing has been completed")
      );
      requestData();
    }
    previosSyncingRef.current = numberSyncingClouds;
  }, [shouldRequestData, dispatch, numberSyncingClouds, requestData]);
};
