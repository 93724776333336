import { ColumnHeaderWrapper } from "components/common/vb-grid/column-wrapper.component";
import { SearchableColumnHeader } from "components/common/vb-grid/searchable-column-header.component";
import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { FilterCommon } from "domain/common/filter"
import { useTranslation } from "react-i18next";
import { classnames } from "utilities/classes";
import { LabelerReportByDateRow } from "../models/labeler-report.models"

interface Props {
  rows: LabelerReportByDateRow[];
  filter: FilterCommon;
  setFilterField: (field: keyof FilterCommon, value: any) => void;
  isLoading?: boolean;
}

export const LabelerReportByDateTable = ({
  rows,
  filter,
  setFilterField,
  isLoading = false,
}: Props) => {
  const { t } = useTranslation();
  const columnNamesMap: Record<string, string> = {
    date: t("labelerhome:individualReport.table.headerDate"),
    total: t("labelerhome:individualReport.table.headerTotal"),
    completed: t("labelerhome:individualReport.table.headerFinished"),
    totalCase: t("labelerhome:individualReport.table.headerTotalCase"),
    skipped: t("labelerhome:individualReport.table.headerNoResult"),
    startTime: t("labelerhome:individualReport.table.headerStartingTime"),
    endTime: t("labelerhome:individualReport.table.headerEndingTime"),
    group: t("labelerhome:individualReport.table.headerGroup"),
  };
  const columns: string[] = Object.keys(columnNamesMap);

  return (
    <div className="w-full">
      <table className="min-w-full text-sm vb-table">
        <thead>
          <tr>
            {
              columns.map(col => {
                return (
                  <ColumnHeaderWrapper
                    key={col}
                    first={col === "date"}
                    last={col === "endTime"}
                  >
                    <SearchableColumnHeader
                      containerClassName={classnames(
                        "w-full py-2 text-sm",
                        { "pl-4": col !== "projectType" }
                      )}
                      clearInput
                      searchIcon={false}
                      placeholder={""}
                      header={columnNamesMap[col]}
                      containerWidth={"100%"}
                      type="text"
                      showSearch={false}
                      sortable
                      onSortChange={(sortType) =>
                        setFilterField(
                          "sort",
                          FilterCommon.getSortValue(sortType, col)
                        )
                      }
                      sortType={FilterCommon.getSortType(filter.sort, col)}
                    />
                  </ColumnHeaderWrapper>
                )
              })
            }
          </tr>
        </thead>
        <tbody className="relative">
          {!isLoading && rows.map((row) => {
            return (
              <tr
                key={row.id}
                className="relative h-10 hover:bg-secondary-50 parent"
              >
                {
                  columns.map(col => {
                    const format = (field: string, value: any) => {
                      const type = typeof value;
                      if (type === "number") return value.toFixed(0);
                      if (["date"].includes(field)) {
                        return t("common:formattedDate", { date: value as Date });
                      }
                      return value;
                    }

                    return (
                      <td key={col} className="pl-4 pr-4" style={{ minWidth: 150 }}>{format(col, (row as any)[col])}</td>
                    )
                  })
                }
              </tr>
            );
          })}
          {
            isLoading &&
            <tr>
              <td colSpan={columns.length}>
                <VBInlineLoading />
              </td>
            </tr>
          }

          <tr className="absolute top-0 left-0 w-full h-full border rounded pointer-events-none border-background-300"></tr>
        </tbody>
      </table>
    </div>
  )
}