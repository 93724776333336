/*
 * File: image-labeling.context.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 22nd July 2021 9:19:29 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { defaultImageEditorState } from "./image-editor.state";

export const ImageEditorContext = createContext(defaultImageEditorState);

export const useImageEditorContext = () => {
  return useContext(ImageEditorContext);
};
