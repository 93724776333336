/*
 * File: dicom-editor.state.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 6th August 2021 9:32:28 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { MeasurementData } from "components/dicom/cornerstone/models/measurement.model";
import {
  CornerstoneTool,
  ToolName,
} from "components/dicom/dicom-tools/dicom-tools.model";
import { Point } from "utilities/math/point";
import {
  mockBaseTools,
  mockOtherTools,
  mockResetTools,
  mockAnnotateTools,
} from "./dicom-editor.mock";

export interface Label {
  annotationTools: ToolName[];
  uuid: string;
  name: string;
  description?: string;
  visible: boolean;
  data?: any;
  color: string;
  isLocked?: boolean;
}

export interface Annotation {
  labelId: string;
  userId: string;
  uuid: string;
  annotateType: string;
  measurement: MeasurementData;
  jobId: string;
  probability: number;
  hide?: boolean;
  objectTrackingId?: string;
}

export interface Finding {
  labelId: string;
  userId: string;
  uuid: string;
  measurement: FindingMeasurement;
  annotateType: string;
  jobId: string;
}

export interface FindingMeasurement extends AnnotationModel {
  id: string;
}

export interface AnnotationModel {
  type: string;
  points: Point[];
  probability: number;
  objectTrackingId: string;
  freeTextValue?: string;
  source?: string;
  observationId: number;
  attributes?: { id: number; value: string[] }[];
}

export interface DicomEditorState {
  activeTool: ToolName;
  annotateTools: CornerstoneTool[];
  baseTools: CornerstoneTool[];
  resetTools: CornerstoneTool[];
  otherTools: CornerstoneTool[];
  freehandStrategy: string;
}

export const INITIAL_DICOM_EDITOR_STATE: DicomEditorState = {
  activeTool: ToolName.Wwwc,
  annotateTools: mockAnnotateTools,
  baseTools: mockBaseTools,
  otherTools: mockOtherTools,
  resetTools: mockResetTools,
  freehandStrategy: "",
};
