import * as cornerstone from "cornerstone-core";
import { CornerstoneEvents } from "components/dicom/cornerstone/models/cornerstone-events.model";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { selectIssueManagementSelectedIssueId } from "store/common/issue-management/issue-management.selectors";
import { setSelectedIssueId, updateIssue } from "store/common/issue-management/issue-management.slice";
import { updateIssueAsync } from "store/common/issue-management/issue-management.thunk";
import { classnames } from "utilities/classes";
import { Point } from "utilities/math/point";
import { IssueComment } from "./issue-comment.component";
import { IssueDetail } from "./issue-detail.component";
import { IssueModel } from "./issue-management.models";


interface Props {
  issue: IssueModel,
  onChanged?: (newValue: IssueModel, needUpdateServer?: boolean) => void;
  cornerstoneElement?: HTMLElement | null;
}
export const Issue = ({
  issue,
  onChanged,
  cornerstoneElement,
}: Props) => {
  const dispatch = useAppDispatch();

  const ref = useRef<any>();
  const isMouseDown = useRef(false);
  const prevMousePos = useRef<Point>();
  const hasMoved = useRef(false);

  const [isHovered, setIsHovered] = useState(false);

  const selectedIssueId = useAppSelector(selectIssueManagementSelectedIssueId);
  const showDetail = useMemo(() => issue.id === selectedIssueId, [selectedIssueId, issue.id]);
  const detailRef = useRef(null);

  const posRef = useRef<Point>({x: 0, y: 0});
  const [displayPos, setDisplayPos] = useState<Point>({x: 0, y: 0});
  const detailsDisplayPos = useMemo(() => {
    if (!ref.current) {
      return {x: 0, y: 0};
    }

    let directionX = 50 - displayPos.x;
    let directionY = 25 - displayPos.y;

    directionX /= Math.abs(directionX);
    directionY /= Math.abs(directionY);

    const parent = ref.current.parentElement;
    const rect = parent.getBoundingClientRect();
    const offsetX = 70 * directionX / rect.width * 100;
    const offsetY = 70 * directionY / rect.height * 100;

    let x = displayPos.x + offsetX;
    let y = displayPos.y + offsetY;

    const maxX = (rect.width - 300) / rect.width * 100;
    const maxY = (rect.height - 300) / rect.height * 100;
    if (x > maxX) x = maxX;
    if (y > maxY) y = maxY;

    return {x, y};
  }, [displayPos]);

  const setDisplayPosFromCanvasPos = useCallback((canvasPos: any) => {
    const parent = ref.current.parentElement;
    const rect = parent.getBoundingClientRect();
    const displayPosX = canvasPos.x / rect.width * 100;
    const displayPosY = canvasPos.y / rect.height * 100;
    setDisplayPos({x: displayPosX, y: displayPosY});
  }, []);

  useEffect(() => {
    if (cornerstoneElement && ref.current) {
      const canvasPos = cornerstone.pixelToCanvas(
        cornerstoneElement, {x: issue.posX, y: issue.posY}
      );
      setDisplayPosFromCanvasPos(canvasPos);
    } else {
      setDisplayPos({x: issue.posX, y: issue.posY});
    }

    posRef.current = {x: issue.posX, y: issue.posY};
  }, [
    issue.posX,
    issue.posY,
    cornerstoneElement,
    setDisplayPosFromCanvasPos,
  ]);

  useEffect(() => {
    const onImageRendered = (e: any) => {
      const canvasPos = cornerstone.pixelToCanvas(
        cornerstoneElement,
        {x: posRef.current.x, y: posRef.current.y},
      );
      setDisplayPosFromCanvasPos(canvasPos);
    }

    if (cornerstoneElement) {
      cornerstoneElement.addEventListener(
        CornerstoneEvents.IMAGE_RENDERED, onImageRendered,
      );
    }

    return () => {
      if (cornerstoneElement) {
        cornerstoneElement.removeEventListener(
          CornerstoneEvents.IMAGE_RENDERED, onImageRendered,
        );
      }
    }
  }, [
    cornerstoneElement,
    setDisplayPosFromCanvasPos,
  ]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    isMouseDown.current = true;
    prevMousePos.current = {x: e.clientX, y: e.clientY};
  }

  const handleMouseUp = () => {
    if (isMouseDown.current) {
      isMouseDown.current = false;

      if (hasMoved.current) {
        let newX = 0;
        let newY = 0;

        if (cornerstoneElement) {
          const imagePos = cornerstone.canvasToPixel(
            cornerstoneElement,
            {
              x: ref.current.offsetLeft,
              y: ref.current.offsetTop,
            },
          );
          newX = imagePos.x;
          newY = imagePos.y;
        } else {
          const parent = ref.current.parentElement;
          const rect = parent.getBoundingClientRect();
          newX = ref.current.offsetLeft / rect.width * 100;
          newY = ref.current.offsetTop / rect.height * 100;
        }

        hasMoved.current = false;
        handleIssueChanged({...issue, posX: newX, posY: newY}, true);
      } else {
        dispatch(setSelectedIssueId(issue.id));
        setIsHovered(false);
      }
    }
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isMouseDown.current && prevMousePos.current && !showDetail) {
      const dx = e.clientX - prevMousePos.current.x;
      const dy = e.clientY - prevMousePos.current.y;

      ref.current.style.left = `${ref.current.offsetLeft + dx}px`;
      ref.current.style.top = `${ref.current.offsetTop + dy}px`;

      prevMousePos.current = {x: e.clientX, y: e.clientY};
      hasMoved.current = true;
    }
  }

  const handleDetailClose = () => {
    dispatch(setSelectedIssueId(undefined));
  }

  const handleIssueChanged = (newValue: IssueModel, needUpdateServer: boolean = false) => {
    if (needUpdateServer) {
      dispatch(updateIssueAsync(newValue));
    } else {
      dispatch(updateIssue(newValue));
    }
    onChanged && onChanged(newValue, needUpdateServer);
  }

  return (
    <Fragment>
      <div
        ref={ref}
        className={classnames(
          "absolute bg-white shadow-lg cursor-default transform -translate-y-full",
          {"border-primary border-4": showDetail},
        )}
        style={{
          zIndex: "102",
          left: `${displayPos.x}%`,
          top: `${displayPos.y}%`,
          borderRadius: !isHovered || showDetail ? "100% 100% 100% 0" : "20px 20px 20px 0",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {
          issue.comments.length > 0 &&
          <IssueComment
            comment={issue.comments[0]}
            showAvatarOnly={!isHovered || showDetail}
            showMenu={false}
          />
        }
        {
          (!issue.comments || issue.comments.length <= 0) &&
          <IssueComment
            comment={{
              id: 0,
              user: "unknow@gmail.com",
              content: "No comment",
              issueId: issue.id,
              createdTime: new Date(),
            }}
            showAvatarOnly={!isHovered || showDetail}
            showMenu={false}
          />
        }
      </div>
      {
        showDetail &&
          <div
            ref={detailRef}
            className="absolute"
            style={{
              left: `${detailsDisplayPos.x}%`,
              top: `${detailsDisplayPos.y}%`,
              zIndex: "102",
            }}
          >
            <IssueDetail
              issue={issue}
              onClose={handleDetailClose}
              containerRef={detailRef}
            />
          </div>
      }
    </Fragment>
  );
}