/*
 * File: annotation.dto.ts
 * Project: app-aiscaler-web
 * File Created: Sunday, 23rd January 2022 2:24:51 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AnnotationAttribute } from "domain/image-labeling";
import { Point } from "utilities/math/point";

export interface BaseAnnotation {
  categoryId: number;
}

export interface LabelerAnnotationDTO {
  annotation: LabelerAnnotation;
  annotationType: string;
  assignee: string;
  id: number;
  jobId: number;
  mediaId: number;
  type: string;
  workspaceId: string;
  categoryId?: number;
}

export interface LabelerAnnotation extends BaseAnnotation {
  type: string;
  points: Point[];
  probability: number;
  freeTextValue?: string;
  attributes?: AnnotationAttribute[];
}

export interface AIAnnotationDTO {
  annotation: AIAnnotation;
  assignee: string;
  source: string;
  id: number;
  mediaId: number;
  annotationType: string;
  jobId: number;
  workspaceId: string;
  categoryId?: number;
}

export interface AIAnnotation {
  category_id: number;
  score: number;
  job_id: number;
  bbox: number[];
  segmentation: number[];
}

export type AnnotationDTO = LabelerAnnotationDTO | AIAnnotationDTO;

export interface ObservationAnnotation {
  id: string;
  mediaId: number;
  source: string;
  observationId: number;
  annotationType: string;
  points: Point[];
  assignee: string;
  score: number;
}

// generate annotations from import file stuff
export enum ImportFormat {
  scaler = "scaler",
  coco = "coco",
  yolo = "yolo",
  ocr = "ocr",
  tts = "tts",
  stt = "stt",
  text_json = "text_json",
  text_tsv = "text_tsv",
}

// generate annotations from model stuff
export enum GenerateAnnotationStatus {
  RUNNING = "RUNNING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
  CANCELED = "CANCELED",
}
export interface GenerateAnnotationRequest {
  modelId: string;
  batchId: number;
  labelMapping: LabelPairItem[];
}

export interface LabelPairItem {
  name: string;
  label: {
    id: number;
    name: string;
  };
}
export interface GenerateAnnotationResponse {
  jobId: number;
  status: GenerateAnnotationStatus;
}
export interface GenerateAnnotationStatusResponse {
  status: GenerateAnnotationStatus;
  percentComplete: number;
}

export enum AnnotationVoteStatus {
  UP = "UP",
  DOWN = "DOWN",
}
export interface AnnotationVoteRequest {
  annotationId: number;
  status: AnnotationVoteStatus | string;
}
