/*
 * File: work-management.dto.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 22nd July 2021 9:19:29 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BaseDTO } from "./base.dto";
import { WorkflowInstructionDTO } from "./workflow-instruction.dto";

export interface WorkManagementDTO extends BaseDTO {
  active: boolean;
  priority: number;
  startDate: string;
  userId: string;
  workInstruction: WorkflowInstructionDTO;
  batchId: number;
  workspaceId: string;
  dailyLimit: number;
  limit?: number;
  assignTask?: number;
  percentage?: number;
  reviewPickType?: string;
  acceptPercentage?: number;
}

export enum ReviewPickType {
  ALL = "ALL",
  LABELER = "LABELER",
}