export interface ComlexJobsEditorSettingState {
  autoSave: boolean;
  autoSaveDuration?: number;
}

function getIsAutoSaved() {
  const key = "THREE_D_AUTO_SAVE";
  try {
    const item = localStorage.getItem(key);
    if (item && item.toLowerCase() === "true") {
      return true;
    }
  } catch (error) {}

  return false;
}

function getAutoSaveDuration() {
  const key = "THREE_D_AUTO_SAVE_DURATION";
  try {
    const item = localStorage.getItem(key);
    if (item) return parseInt(item);
  } catch (error) {}
  return undefined;
}

export const INITIAL_COMPLEX_JOBS_EDITOR_SETTING_STATE: ComlexJobsEditorSettingState =
  {
    autoSave: getIsAutoSaved(),
    autoSaveDuration: getAutoSaveDuration(),
  };
