import { Tooltip } from "@material-ui/core";
import { IconKeyboard } from "components/common/vb-icon.component"
import { VBModal } from "components/common/vb-modal/vb-modal.component"
import { Fragment, useState } from "react";


export const PathologyEditorShortcutComponent = () => {

  const [modalVisibility, setModalVisibility] = useState(false);

  const handleKeyboardClick = () => {
    setModalVisibility(true);
  }

  const handleCloseModal = () => {
    setModalVisibility(false)
  }

  return (
    <Fragment>
      <div className="flex gap-2 items-center">
        <p className="font-bold">Shortcuts</p>
        <Tooltip title="Show shortcuts" placement="top">
          <IconKeyboard
            className="w-5 h-5 cursor-pointer"
            onClick={handleKeyboardClick}
          />
        </Tooltip>
      </div>

      <PathologyEditorShortcutModal
        open={modalVisibility}
        onClose={handleCloseModal}
      />
    </Fragment>
  )
}

interface PathologyEditorShortcutModalProps {
  open: boolean;
  onClose(): void;
}
export const PathologyEditorShortcutModal = ({
  open,
  onClose,
}: PathologyEditorShortcutModalProps) => {
  const keyboardShortcuts = [
    {
      name: "Navigation",
      shortcuts: [
        { keys: ["Left mouse"], name: "Pan" },
        { keys: ["Midle mouse"], name: "Zoom to cursor" },
        { keys: ["Double left mouse"], name: "Zoom to clicked point" },
      ],
    },
  ];
  return (
    <VBModal
      open={open}
      onClose={onClose}
      title={"Keyboard shortcuts"}
      footerHidden={true}
      width="40rem"
    >
      <div className="space-y-6" style={{ maxHeight: "60vh" }}>
        {keyboardShortcuts.map(({ name, shortcuts }) => {
          return (
            <div key={name} className="space-y-2">
              <h2 className="text-background-500">{name}</h2>
              <div className="space-y-2">
                {shortcuts.map((shortcut) => {
                  return (
                    <div
                      key={shortcut.name}
                      className="flex items-center gap-4"
                    >
                      <div className="flex-auto text-sm">{shortcut.name}</div>
                      <div
                        className="flex items-center flex-none gap-2"
                        style={{ width: "12rem" }}
                      >
                        {shortcut.keys.map((keyboardKey) => {
                          return (
                            <div
                              key={keyboardKey}
                              className="px-2 py-1 text-xs font-semibold uppercase rounded bg-background-200 text-background-700"
                            >
                              {keyboardKey}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </VBModal>
  );
}
