import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TaskService } from "services/label-service";
import { TaskDTO } from "services/label-service/dtos";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import {
  selectIsAudioSpeechToTextProject,
  selectIsImageProject,
  selectIsMdiSegmentation,
  selectIsTextTextToSpeechProject,
} from "store/customer/project/project.selectors";
import { useBatchDetailContext } from "../../context/batch-detail.context";
import { TaskReviewThreeD } from "../tasks/components/3d/task-review-three-d.component";
import { TaskReviewAudio } from "../tasks/components/audio/task-review-audio.component";
import { TasksReviewComponent } from "../tasks/components/tasks-review.component";
import { BatchPaymentTable } from "./components/batch-payment-table.component";
import { useBatchPaymentHook } from "./hook/batch-payment.hook";
import { BatchPaymentTableRow } from "./model/batch-payment.models";
import * as Sentry from "@sentry/react";

export const BatchPaymentPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isImageProject = useAppSelector(selectIsImageProject);
  const isSTTProject = useAppSelector(selectIsAudioSpeechToTextProject);
  const isTTSProject = useAppSelector(selectIsTextTextToSpeechProject);
  const isMdiSegmentation = useAppSelector(selectIsMdiSegmentation);
  const { pricing, batch, labels } = useBatchDetailContext();
  const {
    isLoading,
    totalCost,
    totalJob,
    totalPaymentRow,
    paymentRows,
    filter,
    setFilterField,
  } = useBatchPaymentHook(batch.id);
  // review stuff
  const [showReview, setShowReview] = useState(false);
  const currentTaskReview = useRef<TaskDTO>();
  const [currentJobId, setCurrentJobId] = useState<number>();

  const showReviewRow = async (row: BatchPaymentTableRow) => {
    try {
      // get task
      if (row.taskId !== currentTaskReview.current?.id) {
        const res = await TaskService.getItem(row.taskId);
        currentTaskReview.current = res.data as TaskDTO;
      }
      setCurrentJobId(row.jobId);
      setShowReview(true);
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    }
  };

  const handleRowAction = (row: BatchPaymentTableRow, action: string) => {
    if (action === "view") {
      showReviewRow(row);
    }
  };

  const handleReviewClose = () => {
    setShowReview(false);
    setCurrentJobId(undefined);
  };

  const handleReviewNext = () => {
    if (!currentTaskReview) return;
    for (let i = 0; i < paymentRows.length; i++) {
      if (paymentRows[i].jobId === currentJobId) {
        const nextRow = paymentRows[(i + 1) % paymentRows.length];
        showReviewRow(nextRow);
        break;
      }
    }
  };

  if (!pricing && !isLoading)
    return (
      <div className="justify-center w-full py-4">
        <span>No pricing config!</span>
      </div>
    );

  const getReviewComponentByProject = () => {
    if (!currentTaskReview.current || !batch || !currentJobId) return null;

    if (isImageProject)
      return (
        <TasksReviewComponent
          visible={showReview}
          batch={batch}
          task={currentTaskReview.current}
          batchObservations={labels}
          jobIdsIncludedOnly={[currentJobId]}
          onClose={handleReviewClose}
          onNext={handleReviewNext}
          title={`Job #${currentJobId}`}
          showIssues={false}
        />
      );

    if (isSTTProject || isTTSProject)
      return (
        <TaskReviewAudio
          open={showReview}
          batch={batch}
          task={currentTaskReview.current}
          jobIdsIncludedOnly={[currentJobId]}
          onClose={handleReviewClose}
          onNext={handleReviewNext}
          title={`Job #${currentJobId}`}
          showIssues={false}
        />
      );

    if (isMdiSegmentation)
      return (
        <TaskReviewThreeD
          open={showReview}
          batch={batch}
          task={currentTaskReview.current}
          jobIdsIncludedOnly={[currentJobId]}
          onClose={handleReviewClose}
          onNext={handleReviewNext}
          title={`Job #${currentJobId}`}
          showIssues={false}
        />
      );

    return null;
  };

  return (
    <div className="flex flex-col w-full h-full gap-4 py-4">
      <div className="flex items-center gap-4">
        <span>
          Total cost: {totalCost} ({pricing?.currency.code})
        </span>
        <span>Total job: {totalJob}</span>
      </div>

      <div className="flex-auto">
        <BatchPaymentTable
          rows={paymentRows}
          filter={filter}
          setFilterField={setFilterField}
          onRowAction={handleRowAction}
          currencyCode={pricing?.currency.code}
        />
      </div>

      {filter && (
        <div className="flex-none">
          <GridPagination
            page={filter.page}
            pageSize={filter.pageSize}
            pageSizeOptions={[10, 15, 20, 25, 50, 75, 100]}
            onPageSizeChange={(v) => setFilterField("pageSize", v)}
            onPageChange={(v) => setFilterField("page", v)}
            totalItems={totalPaymentRow}
          />
        </div>
      )}

      {getReviewComponentByProject()}
    </div>
  );
};
