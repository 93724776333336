/*
 * File: use-labeler-task-columns.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 23rd May 2023 9:10:20 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { ITableColumn } from "domain/common/table";
import { ILabelerTaskEntity } from "../types";
import { useMemo } from "react";
import { useLabelerTasksContext } from "../context";
import { Checkbox, IconButton, Tooltip } from "@material-ui/core";
import { EntityAction } from "domain/common/entity-action";
import { VscEye } from "react-icons/vsc";

export function useLabelerTaskColumns() {
  const {
    onSelectEntities,
    toggleSelectEntity,
    toggleSelectEntities,
    pagination,
    selectedIds,
  } = useLabelerTasksContext();

  const columns = useMemo<ITableColumn<ILabelerTaskEntity>[]>(() => {
    const hasSelected = selectedIds.length > 0;
    const isSelectedAll = selectedIds.length === pagination.totalCount;
    const isChecked = hasSelected && isSelectedAll;
    const isIndeterminate = hasSelected && !isSelectedAll;
    return [
      {
        id: "checkbox",
        field: "checkbox",
        className: "flex-none",
        width: 48,
        label: (
          <Checkbox
            onClick={toggleSelectEntities}
            checked={isChecked}
            indeterminate={isIndeterminate}
          />
        ),
        render: (row: ILabelerTaskEntity) => {
          const isChecked = selectedIds.includes(row.id);
          return (
            <Checkbox
              checked={!!isChecked}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                toggleSelectEntity(row.id);
              }}
            />
          );
        },
      },
      {
        id: "fileId",
        field: "fileId",
        label: "File ID",
        sortable: true,
        render: (row: ILabelerTaskEntity) => {
          return <span>{row.fileId}</span>;
        },
      },
      {
        id: "fileName",
        field: "fileName",
        label: "File Name",
        sortable: true,
        render: (row: ILabelerTaskEntity) => {
          return <span>{row.fileName}</span>;
        },
      },
      {
        id: "taskId",
        field: "taskId",
        label: "Task ID",
        hidden: true,
        sortable: true,
        render: (row: ILabelerTaskEntity) => {
          return <span>{row.taskId}</span>;
        },
      },
      {
        id: "taskStatus",
        field: "taskStatus",
        label: "Task Status",
        sortable: true,
        hidden: true,
        render: (row: ILabelerTaskEntity) => {
          return <span>{row.taskStatus}</span>;
        },
      },
      {
        id: "jobId",
        field: "jobId",
        label: "Job ID",
        sortable: true,
        render: (row: ILabelerTaskEntity) => {
          return <span>{row.jobId}</span>;
        },
      },
      {
        id: "jobStatus",
        field: "jobStatus",
        label: "Job Status",
        sortable: true,
        render: (row: ILabelerTaskEntity) => {
          return <span>{row.jobStatus}</span>;
        },
      },

      {
        id: "id",
        field: "id",
        label: "Actions",
        render: (row: ILabelerTaskEntity) => {
          return (
            <div className="flex items-center gap-1">
              <Tooltip title="View detail">
                <IconButton
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onSelectEntities([row], EntityAction.VIEW);
                  }}
                >
                  <VscEye size={18} />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    ];
  }, [
    pagination,
    selectedIds,
    toggleSelectEntities,
    toggleSelectEntity,
    onSelectEntities,
  ]);

  return { columns };
}
