/*
 * File: smart-labeling.state.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th December 2021 4:22:56 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { RequestStatus } from "store/base/base.state";

export interface TextLabel {
  id: number;
  name: string;
}

export interface TextLabelPair {
  id: string;
  labelId: number;
  classId: number;
  selected?: boolean;
}

export interface TextAIState {
  jobId: number;
  enable: boolean;
  aiLabels: { [key: number]: TextLabel };
  textLabels: { [key: number]: TextLabel };
  labelPairs: TextLabelPair[];
  status: RequestStatus;
}

export const INITIAL_TEXT_AI_STATE: TextAIState = {
  jobId: -1,
  status: RequestStatus.IDLE,
  enable: false,
  aiLabels: {},
  labelPairs: [],
  textLabels: {},
};
