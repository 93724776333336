import { createAsyncThunk } from "@reduxjs/toolkit";
import { AnnotationData } from "domain/image-labeling";
import { RootState } from "store";
import { imageAnnotationUtils } from "../image-annotations.util";

const THUNK_NAME = "imageWorkspace/imageAnnotationAddedAsync";

interface ImageAnotationPayload<T> {
  jobId: number;
  data: T;
  frameId?: number
}

export const imageAnnotationAddedAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: ImageAnotationPayload<AnnotationData[]>, { getState }) => {
    const { jobId, data: annotationData, frameId } = payload;
    const state = getState() as RootState;
    return imageAnnotationUtils.createNewAnnotation(
      jobId,
      annotationData,
      state,
      frameId
    );
  }
);
