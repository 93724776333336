import { LabelAPI } from "../http/label-service.httpclient";

export class IndividualAPI {
  private _PATH_: string;
  constructor(path: string) {
    this._PATH_ = path;
  }

  getLabelerReport(fromDate: Date, toDate: Date, page = 0, size = 1000) {
    const params = new URLSearchParams({
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
      page: page.toString(),
      size: size.toString(),
      sort: "daily,asc",
    });
    return LabelAPI.get(`${this._PATH_}/labeler-report?${params.toString()}`);
  }

  getBatchReport(fromDate: Date, toDate: Date) {
    const params = new URLSearchParams({
      startTime: fromDate.toISOString(),
      endTime: toDate.toISOString(),
      searchAllTime: "false",
    });
    return LabelAPI.get(`${this._PATH_}/batch-report?${params.toString()}`);
  }

  getAnnotationReport(fromDate: Date, toDate: Date, batchId: number) {
    const params = new URLSearchParams({
      batchId: batchId.toString(),
      startTime: fromDate.toISOString(),
      endTime: toDate.toISOString(),
      searchAllTime: "false",
    });
    return LabelAPI.get(
      `${this._PATH_}/annotation-report?${params.toString()}`
    );
  }
}
