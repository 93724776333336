/*
 * File: image-validate-job.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 5th August 2022 9:46:46 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  selectImageLabelingAllLabels,
  selectImageLabelingLabels,
  selectSelectedSystemObservationByJobId,
} from "../../image-labeling/image-labeling.selectors";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { selectJobAnnotations } from "../../image-annotations/image-annotations.selectors";
import {
  isMedicalMamoProject,
  StepType,
  SystemObservationCode,
} from "services/label-service/dtos";
import { commonError, CommonErrorCode } from "domain/common/common-error";
import i18n from "i18n";
import {
  selectBatchLabelingJobIdsInBatch,
  selectBatchLabelingProject,
  selectImageLabelingJobById,
  selectImageLabelingJobIdsSameLaterality,
} from "../batch-labeling.selectors";
import { getLaterality } from "utilities/dicom/dicom.utils";
import { collectionUtils } from "domain/common";
import { AnnotateType } from "constants/annotation.constant";
import { Annotation } from "domain/image-labeling";

const THUNK_NAME = "batch-labeling/imageValidateJobAsync";

export const imageValidateJobAsync = createAsyncThunk(
  THUNK_NAME,
  async ({ jobId }: { jobId: number | undefined }, { getState }) => {
    const state = getState() as RootState;
    const project = selectBatchLabelingProject(state);
    if (!jobId || !project) throw new Error("");
    const isMammography = isMedicalMamoProject(project.type);
    if (isMammography) {
      return validateMammographyProject(state, jobId);
    }

    const error =
      validateSystemObservation(state, jobId) ||
      validateEmptyAnnotations(state, jobId) ||
      validateRequireLabels(state, jobId) ||
      validateRequireLabelAttributes(state, jobId) ||
      validateAcceptanceJob(state, jobId);
    if (error) return error;

    // if (requireLabelMsg) {
    //   return requireLabelMsg;
    // }

    // return (
    //   validateSystemLabel(selectedSystemObservation, {
    //     hasAnnotations: annotations && annotations.length > 0,
    //     isShowPopup: isShowWarningPopup,
    //   }) ||
    //   validateClassificationLabel(annotations, {
    //     classificationLabel: checkEmptyAnnotationForClassificationLabel,
    //   }) ||
    //   (isAcceptanceJob &&
    //     validateReviewingJob(selectedSystemObservation, {
    //       issues: checkIssues && hasWorkingIssues,
    //     }))
    // );
  }
);

function validateMammographyProject(state: RootState, jobId: number) {
  return (
    validateBiradsScore(state, jobId) ||
    validateSystemObservation(state, jobId) ||
    validateEmptyAnnotations(state, jobId) ||
    validateRequireLabelAttributes(state, jobId)
  );
}

function validateBiradsScore(state: RootState, jobId: number) {
  const allJobIds = selectBatchLabelingJobIdsInBatch(state);
  const imageLabelingJob = selectImageLabelingJobById(jobId)(state);
  const systemLabel = selectSelectedSystemObservationByJobId(jobId)(state);
  if (systemLabel && isValidCode(systemLabel?.code)) {
    return;
  }
  const laterality = getLaterality(imageLabelingJob.dicomData);
  const labels = selectImageLabelingAllLabels(state);
  const labelCollection = collectionUtils.fromEntities(labels);
  const jobIds = allJobIds.filter((id) => {
    const labelingJob = selectImageLabelingJobById(id)(state);
    if (!labelingJob || !labelingJob.dicomData) return false;
    const jobLaterality = getLaterality(labelingJob.dicomData);
    return jobLaterality === laterality;
  });
  if (jobIds.length === 0) return;
  const { annotations } = state.imageWorkspace.imageAnnotations;
  const hasAnnotation = annotations.some((anno) => {
    const label = collectionUtils.getOne(labelCollection, anno.labelId);
    const isBBoxOrPolygon =
      label?.annotateType === AnnotateType.BOUNDING_BOX ||
      label?.annotateType === AnnotateType.POLYGON;
    return jobIds.includes(anno.jobId) && isBBoxOrPolygon;
  });

  if (!hasAnnotation) {
    const message = i18n.t("error:labeling.atLeastOneLabelRequired");
    return commonError(message, CommonErrorCode.ERROR);
  }

  const biradLabel = labels.find(
    (lb) =>
      lb?.name?.toLowerCase() === laterality.toLowerCase() ||
      lb?.code?.toLowerCase() === laterality.toLowerCase()
  );

  if (biradLabel) {
    const hasBiradAnnotations = annotations.some((anno) => {
      return jobIds.includes(anno.jobId) && anno.labelId === biradLabel.id;
    });
    if (!hasBiradAnnotations) {
      const params = { name: biradLabel.name };
      const message = i18n.t("error:labeling.labelRequired", params);
      return commonError(message, CommonErrorCode.ERROR);
    }
  }
}

function isValidCode(code: string) {
  return (
    SystemObservationCode.INVALID === code ||
    SystemObservationCode.NOT_FOUND === code
  );
}

function validateSystemObservation(state: RootState, jobId: number) {
  const annotator = selectCurrentUser(state);
  const label = selectSelectedSystemObservationByJobId(jobId)(state);
  const labels = selectImageLabelingAllLabels(state);
  const labelCollection = collectionUtils.fromEntities(labels);
  if (!annotator || !label) return;
  const { code, name } = label;
  const annotations = selectJobAnnotations(jobId, [annotator.email])(state);
  const hasAnnotation = annotations.some((anno) => {
    const annoLabel = collectionUtils.getOne(labelCollection, anno.labelId);
    return (
      annoLabel?.annotateType === AnnotateType.BOUNDING_BOX ||
      annoLabel?.annotateType === AnnotateType.POLYGON
    );
  });
  if (!isValidCode(code) || !hasAnnotation) return;
  const message = i18n.t("error:labeling.systemLabelWarning", { name });
  return commonError(message, CommonErrorCode.WARNING);
}

function validateEmptyAnnotations(state: RootState, jobId: number) {
  const annotator = selectCurrentUser(state);
  const label = selectSelectedSystemObservationByJobId(jobId)(state);
  const labels = selectImageLabelingAllLabels(state);
  const labelCollection = collectionUtils.fromEntities(labels);
  if (!annotator || (label && isValidCode(label.code))) return;
  const jobIds = selectImageLabelingJobIdsSameLaterality(jobId)(state);
  let annotations: Annotation[] = [];
  for (const currentJobId of jobIds) {
    const currentAnnotations = selectJobAnnotations(currentJobId, [
      annotator.email,
    ])(state);
    annotations = annotations.concat(currentAnnotations);
  }
  const hasAnnotation = annotations.some((anno) => {
    const annoLabel = collectionUtils.getOne(labelCollection, anno.labelId);
    return (
      annoLabel?.annotateType === AnnotateType.BOUNDING_BOX ||
      annoLabel?.annotateType === AnnotateType.POLYGON ||
      annoLabel?.annotateType === AnnotateType.CLASSIFICATION
    );
  });
  if (hasAnnotation) return;
  const message = i18n.t("error:labeling.atLeastOneLabelRequired");
  return commonError(message, CommonErrorCode.ERROR);
}

function validateRequireLabels(state: RootState, jobId: number) {
  const annotator = selectCurrentUser(state);
  const labels = selectImageLabelingLabels(state);
  const requiredLabels = labels.filter((lb) => lb.require);
  if (!annotator) return;
  const annotations = selectJobAnnotations(jobId, [annotator.email])(state);
  for (const label of requiredLabels) {
    const hasAnnotation = annotations.some((anno) => anno.labelId === label.id);
    if (!hasAnnotation) {
      const params = { name: label.name };
      const message = i18n.t("error:labeling.labelRequired", params);
      return commonError(message, CommonErrorCode.ERROR);
    }
  }
}

function validateRequireLabelAttributes(state: RootState, jobId: number) {
  const annotator = selectCurrentUser(state);
  const labels = selectImageLabelingLabels(state);

  if (!annotator) return;
  const annotations = selectJobAnnotations(jobId, [annotator.email])(state);
  const labelIds: number[] = [];
  for (const anno of annotations) {
    if (!labelIds.includes(anno.labelId)) {
      labelIds.push(anno.labelId);
    }
  }
  const labelRequireAttributes = labels.filter(
    (lb) =>
      labelIds.includes(lb.id) && lb.attributes?.some((attr) => attr.required)
  );

  for (const label of labelRequireAttributes) {
    if (!label.attributes) continue;
    for (const attr of label.attributes) {
      if (!attr.required) continue;
      const hasAttribute = annotations.some(
        (anno) =>
          anno.labelId === label.id &&
          anno.annotationData &&
          anno.annotationData[0].attributes &&
          anno.annotationData[0].attributes.find((at) => at.id === attr.id)
      );
      if (!hasAttribute) {
        const params = { name: label.name, attribute: attr.name };
        const message = i18n.t("error:labeling.labelAttributeRequired", params);
        return commonError(message, CommonErrorCode.ERROR);
      }
    }
  }
}

function isReviewCode(code: string) {
  return (
    SystemObservationCode.ACCEPT === code ||
    SystemObservationCode.REJECT === code
  );
}
function validateAcceptanceJob(state: RootState, jobId: number) {
  const annotator = selectCurrentUser(state);
  const label = selectSelectedSystemObservationByJobId(jobId)(state);
  const job = selectImageLabelingJobById(jobId)(state);
  if (!label || !annotator || job?.job?.stepType !== StepType.ACCEPTANCE)
    return;
  const { code } = label;
  if (isReviewCode(code)) return;
  const message = i18n.t("error:labeling.requireReviewLabel");
  return commonError(message, CommonErrorCode.WARNING);
}
