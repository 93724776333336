/*
 * File: workspace.state.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:12 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export interface WorkspaceState {
  workspaceId: string;
  sasToken: string;
  getUrlWithSasToken(url: string): string;
}

export const defaultWorkspaceState: WorkspaceState = {
  workspaceId: "",
  sasToken: "",
  getUrlWithSasToken(url) {
    return url;
  },
};
