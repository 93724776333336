import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { PathologyLabelingProvider } from "pages/labeler/pathology-labeling/context/pathology-labeling.provider";
import { PathologyLabelingDetails } from "pages/labeler/pathology-labeling/pathology-labeling.details";
import { useTranslation } from "react-i18next";
import { BatchDTO, TaskDTO } from "services/label-service/dtos";
import { TaskReviewReopenButtons } from "../task-review-reopen-buttons.component";

interface Props {
  open: boolean;
  batch: BatchDTO;
  task: TaskDTO;
  jobIdsIncludedOnly?: number[];
  onClose?: () => void;
  onNext?: () => void;
  title?: string;
  showReopenButtons?: boolean;
  showIssues?: boolean;
}

export const TaskReviewPathology = ({
  open,
  batch,
  task,
  jobIdsIncludedOnly,
  onClose,
  onNext,
  title,
  showReopenButtons,
  showIssues = false,
}: Props) => {
  const { t } = useTranslation();

  return (
    <VBModal
      width={"1400px"}
      title={title ? title : `Task review #${task.id}`}
      open={open}
      onClose={onClose}
      footerHidden
    >
      <div className="flex flex-col gap-6">
        <div className="relative w-full rounded">
          <div
            className="relative w-full"
            style={{
              height: "80vh",
            }}
          >
            <PathologyLabelingProvider
              isTaskReview
              taskToReview={task}
              jobIdsIncludedOnly={jobIdsIncludedOnly}
            >
              <PathologyLabelingDetails
                showMenuIssue={false}
                showIssues={showIssues}
              />
            </PathologyLabelingProvider>
          </div>
        </div>
        <div className="z-10 flex items-center flex-none w-full gap-4">
          {showReopenButtons && (
            <TaskReviewReopenButtons
              batch={batch}
              task={task}
              onApproved={onClose}
              onRejected={onClose}
            />
          )}

          {!showReopenButtons && <div className="flex-1"></div>}

          <button
            onClick={onNext}
            className="button-text-primary disabled:opacity-60"
          >
            <span>{t("common:buttonNext")}</span>
          </button>
        </div>
      </div>
    </VBModal>
  );
};
