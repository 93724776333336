/*
 * File: color-selector.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 21st February 2022 10:21:50 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { classnames } from "utilities/classes";
import { Option } from "domain/common";

interface Props {
  options: Option[];
  value: string;
  onChange?(value: string): void;
}
export const ColorSelector = ({ options, value, onChange }: Props) => {
  return (
    <div className="px-4">
      <div className="text-sm font-semibold">Main Color</div>
      <div className="grid items-center justify-between grid-flow-row grid-cols-4 gap-4 my-4">
        {options.map((option) => {
          return (
            <div
              className="relative flex items-center justify-center"
              key={option.value}
            >
              <button
                onClick={() => onChange && onChange(option.value)}
                style={{ backgroundColor: option.value }}
                className={classnames("w-8 h-8 rounded-full", {})}
              />
              {value === option.value && (
                <div className="absolute flex items-center justify-center w-10 h-10 border rounded-full border-secondary-500" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
