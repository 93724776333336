/*
 * File: use-annotation-menu.hook.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 2nd July 2022 8:08:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  AnnotationContextMenuAction,
  ContextMenuItemData,
} from "components/common/context-menu/context-menu-item.component";
import { NERAnnotation } from "domain/text-labeling";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { MutableRefObject, useCallback, useMemo } from "react";
import { selectSelectedTextAnnotation } from "store/labeler/text-workspace/text-editor/text-editor.selector";
import {
  relationRemoved,
  textAnnotationContextMenuClosed,
  textAnnotationContextMenuOpened,
  textAnnotationEditStarted,
  textAnnotationIssueMenuOpened,
  textObservationRemoved,
} from "store/labeler/text-workspace/text-workspace.slice";
import { useTextWorkspaceContext } from "../context/text-workspace/text-workspace.context";

export const useAnnotationContextMenu = (
  containerRef: MutableRefObject<HTMLDivElement | null>
) => {
  const dispatch = useAppDispatch();
  const { observations, connections } = useTextWorkspaceContext();
  const selectedAnnotation = useAppSelector(selectSelectedTextAnnotation);

  const annotation = useMemo(() => {
    const { annotationId, contextMenu } = selectedAnnotation;
    if (!annotationId || !contextMenu) return null;
    let anno = observations.find((ob) => ob.id === annotationId);
    if (anno) return anno;
    let relation = connections.find((c) => c.id === annotationId);
    if (relation) return relation;
    return null;
  }, [selectedAnnotation, observations, connections]);

  const position = useMemo(() => {
    const { annotationId, contextMenu } = selectedAnnotation;
    if (!annotationId || !contextMenu) return null;
    const element = document.getElementById(annotationId);
    if (element && containerRef.current) {
      const bounding = element.getBoundingClientRect();
      const parentBounding = containerRef.current.getBoundingClientRect();
      return {
        x: bounding.x - parentBounding.x,
        y: bounding.y + bounding.height + 20 - parentBounding.y,
      };
    }
    return null;
  }, [selectedAnnotation, containerRef]);

  const hideAnnotationContextMenu = useCallback(() => {
    if (!annotation) return;
    dispatch(textAnnotationContextMenuClosed());
  }, [dispatch, annotation]);

  const showAnnotationContextMenu = useCallback(
    (id: string) => {
      dispatch(textAnnotationContextMenuOpened(id));
    },
    [dispatch]
  );

  function handleAnnotationContextMenuAction(action: ContextMenuItemData) {
    if (!annotation) return;
    if (action.value === AnnotationContextMenuAction.DELETE) {
      deleteAnnotation();
    } else if (action.value === AnnotationContextMenuAction.ISSUE) {
      addAnnotationComment();
    } else if (action.value === AnnotationContextMenuAction.EDIT) {
      editAnnotation();
    }
    hideAnnotationContextMenu();
  }

  function deleteAnnotation() {
    if (!annotation) return;
    const isAnnotation = annotation.hasOwnProperty("startIndex");
    if (isAnnotation) {
      dispatch(textObservationRemoved(annotation.id));
    } else {
      dispatch(relationRemoved(annotation.id));
    }
  }
  function editAnnotation() {
    if (!annotation) return;
    if (annotation && !annotation.hasOwnProperty("from")) {
      dispatch(
        textAnnotationEditStarted({ annotation: annotation as NERAnnotation })
      );
    }
  }

  function addAnnotationComment() {
    if (!annotation) return;
    dispatch(textAnnotationIssueMenuOpened(annotation.id));
  }

  const state = position && annotation ? { position, annotation } : null;
  return {
    annotationContextMenuState: state,
    showAnnotationContextMenu,
    hideAnnotationContextMenu,
    handleAnnotationContextMenuAction,
  };
};
