/*
 * File: dicom-httpclient.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 3rd July 2021 5:28:39 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { ENV_CONFIG } from "../../configs/env.config";

const _defaultHeaderConfig: AxiosRequestConfig = {
  headers: {
    Accept: "application/dicom+json",
  },
  withCredentials: false,
};

let _axiosInstance: AxiosInstance | undefined = undefined;

function getAxiosInstance() {
  if (_axiosInstance) return _axiosInstance;
  _axiosInstance = axios.create({
    baseURL: ENV_CONFIG.DICOM_SERVICE_URL,
  });
  return _axiosInstance;
}

export function dicomAPIGet(url: string, config = _defaultHeaderConfig) {
  return getAxiosInstance().get(url, config);
}

export function dicomAPIPost(url: string, config = _defaultHeaderConfig) {
  return getAxiosInstance().post(url, config);
}

export function dicomAPIPut(url: string, config = _defaultHeaderConfig) {
  return getAxiosInstance().put(url, config);
}
