/*
 * File: image-review.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th December 2021 4:25:01 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ImageWorkspaceState } from "../image-workspace.state";
import { loadImageReviewBuilder } from "./thunks/load-image-review.thunk";

export function imageReviewReducerBuilder(
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) {
  loadImageReviewBuilder(builder);
}
