/*
 * File: modify-tags.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 30th November 2021 2:15:54 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ChangeEvent, useMemo, useState, useRef, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { selectTags } from "store/customer/dataset/dataset.selectors";
import { useDatasetDetailContext } from "../../dataset-detail.context";
import { IconSearch, IconTrash } from "components/common/vb-icon.component";
import { Tag } from "models/dataset/tag.model";
import {
  addTag,
  addTagToFilesAsync,
  removeTagFromFilesAsync,
} from "store/customer/dataset/dataset.slice";
import { Logger } from "utilities/logger";
import { StorageService } from "services/storage";
import { snakeCase } from "snake-case";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { useClickAway, useKeyPress } from "ahooks";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { RequestStatus } from "store/base/base.state";
import * as Sentry from "@sentry/react";

interface Props {
  onClose(): void;
}
export const ModifyTags = ({ onClose }: Props) => {
  const dispatch = useAppDispatch();
  const { files, selectedFiles } = useDatasetDetailContext();
  const tags = useAppSelector(selectTags);
  const [filteredTags, setFilteredTags] = useState<Tag[]>([]);
  const selectedTags = useMemo(() => {
    let selectedTagIds: number[] = [];
    for (let fileId of selectedFiles) {
      const file = files.find((f) => f.id === fileId);
      if (!file) continue;
      const tagIds = (file.tags ?? []).map((tag) => tag.id);
      for (let tagId of tagIds) {
        if (!selectedTagIds.includes(tagId)) {
          selectedTagIds.push(tagId);
        }
      }
    }
    return selectedTagIds;
  }, [files, selectedFiles]);

  const [search, setSearch] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLInputElement>(null);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);

  useKeyPress(KeyboardKey.Escape, onClose, { target: inputRef.current });
  useKeyPress(KeyboardKey.Enter, handleCreateTag, { target: inputRef.current });
  useKeyPress(KeyboardKey.Escape, onClose, { target: containerRef.current });
  useClickAway(onClose, containerRef.current);

  async function handleCreateTag() {
    if (requestStatus === RequestStatus.LOADING) return;
    try {
      let tagValue = tags.find((t) => t.name === search);
      if (!tagValue) {
        setRequestStatus(RequestStatus.LOADING);
        const createTagPayload = { name: search, code: snakeCase(search) };
        const response = await StorageService.createTag(createTagPayload);
        handleThunkRejected(response);
        dispatch(addTag(response.data));
        tagValue = response.data as Tag;
      }
      const payload = { tag: tagValue, fileIds: selectedFiles };
      await dispatch(addTagToFilesAsync(payload));
      setSearch("");
      if (inputRef.current) inputRef.current.value = "";
      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error) {
      Sentry.captureException(error);
      Logger.log(error);
      setRequestStatus(RequestStatus.FAILURE);
    }
  }

  async function handleApplyTag(tag: Tag) {
    if (requestStatus === RequestStatus.LOADING) return;
    try {
      setRequestStatus(RequestStatus.LOADING);
      const payload = { tag: tag, fileIds: selectedFiles };
      await dispatch(addTagToFilesAsync(payload));
      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error) {
      Sentry.captureException(error);
      Logger.log(error);
      setRequestStatus(RequestStatus.FAILURE);
    }
  }

  async function handleRemoveTag(tag: Tag) {
    if (requestStatus === RequestStatus.LOADING) return;
    try {
      setRequestStatus(RequestStatus.LOADING);
      const payload = { tag: tag, fileIds: selectedFiles };
      await dispatch(removeTagFromFilesAsync(payload));
      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error) {
      Sentry.captureException(error);
      Logger.log(error);
      setRequestStatus(RequestStatus.FAILURE);
    }
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    if (!inputRef.current) return;
    setSearch(inputRef.current.value.trim());
    event.preventDefault();
  }

  useEffect(() => {
    const normalizedSearch = search.trim().toLowerCase();
    setFilteredTags([
      ...tags.filter(
        (tag) => tag.name.toLowerCase().indexOf(normalizedSearch) !== -1
      ),
    ]);
  }, [tags, search]);

  return (
    <div
      className="absolute left-0 mt-2 bg-white rounded shadow-lg top-full animate-fade-in-up"
      ref={containerRef}
    >
      <div style={{ width: "17rem" }} className="flex flex-col gap-2 py-4">
        <div className="flex flex-col gap-2 px-4">
          <div className="text-sm">Select tag</div>
          <div className="flex items-center gap-2 px-4 bg-white border rounded h-9 border-background-300 focus-within:border-primary-500">
            <IconSearch className="flex-shrink-0 w-4 h-4" />
            <input
              ref={inputRef}
              onChange={handleInputChange}
              type="text"
              placeholder="Search"
              className="flex-1 focus:outline-none"
            />
          </div>
        </div>
        <div className="px-4 overflow-y-auto max-h-60">
          {filteredTags.map((tag) => {
            const added = selectedTags.includes(tag.id);
            return (
              <div
                key={tag.id}
                className="flex items-center gap-2 px-4 rounded h-9 hover:bg-primary-50 parent"
              >
                <span className={`flex-1 ${added ? "text-success-500" : ""}`}>
                  {tag.name}
                </span>
                {added && (
                  <button
                    className="text-error-500"
                    onClick={() => handleRemoveTag(tag)}
                  >
                    <IconTrash className="w-4 h-4" />
                  </button>
                )}

                {!added && (
                  <button
                    className="text-sm child-on-hover text-primary"
                    onClick={() => handleApplyTag(tag)}
                  >
                    Apply
                  </button>
                )}
              </div>
            );
          })}
        </div>

        {search && (
          <button className="w-full text-center" onClick={handleCreateTag}>
            Create <span className="font-bold text-primary">{search}</span> as a
            new tag
          </button>
        )}
      </div>
    </div>
  );
};
