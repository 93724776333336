/*
 * File: use-threed-smoothing.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 17th March 2023 1:47:46 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useInterval } from "ahooks";
import { useCallback, useState } from "react";
import { AnnotationService } from "services/label-service";
import { StorageService } from "services/storage";
import { StorageResource } from "services/storage/dto/resource.dto";
import * as Sentry from "@sentry/react";
import { useAppDispatch } from "hooks/use-redux";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";

interface SmoothingRequest {
  jobId: number;
  mask: number;
  method: string;
  parameters: { [key: string]: number };
  blob: Blob;
  workspaceId: string;
  callback?(blob: Blob): void;
}

interface SmoothingResponse {
  id?: string;
  asyncJobId: string;
  entityId?: string;
  entityName?: string;
  payload?: {
    method: string;
    parameters: {
      sigma: string;
    };
    sourceStorageResourceId: string;
    outputUrl: string;
    blobName: string;
  };
  status: string;
}
export function useThreeDSmoothing() {
  const dispatch = useAppDispatch();
  const [running, setIsRunning] = useState(false);
  const [data, setData] = useState<SmoothingResponse>();
  const [request, setRequest] = useState<SmoothingRequest>();
  const [error, setError] = useState<Error | string>();
  const [interval, setInterval] = useState<number | undefined>(undefined);
  const [outputBlob, setOutputBlob] = useState<Blob>();
  const [isApplied, setApplied] = useState(false);

  useInterval(async () => {
    if (!request) {
      setInterval(undefined);
      setIsRunning(false);
      return;
    }
    try {
      const response = await AnnotationService.getSmoothing3D(request?.jobId);
      const runningData: SmoothingResponse = response.data;
      if (runningData.status === "SUCCEEDED") {
        setData(runningData);
        setInterval(undefined);
        setError(undefined);
        setIsRunning(false);

        const outputUrl = runningData.payload?.outputUrl;

        if (outputUrl) {
          const res = await StorageService.getPublicFileContentAsBlob(
            outputUrl,
            request.workspaceId
          );
          const blob = res.data;
          setOutputBlob(blob);
        }
      } else if (runningData.status === "FAILED") {
        setData(undefined);
        setInterval(undefined);
        setError(undefined);
        setIsRunning(false);
        setOutputBlob(undefined);
        dispatch(enqueueErrorNotification("Failed"));
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log("Error", error);
    }
  }, interval);

  const runSmoothing = useCallback(
    async (payload: SmoothingRequest) => {
      if (running) return;
      try {
        setOutputBlob(undefined);
        setIsRunning(true);
        setRequest(payload);
        setApplied(false);
        const res = await StorageService.createStorageResource(
          "Labeling",
          "nii.gz",
          payload.workspaceId
        );
        const resource = res.data as StorageResource;
        await StorageService.uploadToAzureBlob(payload.blob, resource.path);
        await StorageService.processStorageResource(resource.resourceId);
        const response = await AnnotationService.smoothing3D({
          jobId: payload.jobId,
          storageResourceId: resource.resourceId,
          method: payload.method,
          parameters: payload.parameters,
        });
        setData(response.data);
        setInterval(1000);
      } catch (err) {
        setInterval(undefined);
        setIsRunning(false);
        Sentry.captureException(err);
        if (typeof err === "string") {
          setError(err);
        } else if (err instanceof Error) {
          setError(err);
        } else {
          setError("Failed to run smoothing on this segment!");
        }
      }
    },
    [running]
  );

  const resetSmoothing = useCallback(() => {
    setData(undefined);
    setRequest(undefined);
    setError(undefined);
    setInterval(undefined);
    setOutputBlob(undefined);
    setApplied(false);
    setIsRunning(false);
  }, []);

  return {
    running,
    isApplied,
    setApplied,
    request,
    data,
    outputBlob,
    error,
    runSmoothing,
    resetSmoothing,
  };
}
