/*
 * File: storage-cell-hover-display.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 21st September 2021 3:07:08 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { GridCellParams } from "@material-ui/data-grid";

export const VBDatagridCellHoverDisplay = (params: GridCellParams) => (
  <div className="truncate" title={params.value?.toString()}>
    {params.value}
  </div>
);
