/*
 * File: job-labeler-list.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 9th August 2021 9:17:26 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { getUserNameFromEmail } from "store/labeler/image-workspace/image-labeling/image-labeling.utils";
import { classnames } from "utilities/classes";
import { imageViewPreviousJobAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-view-previous-jobs.thunk";
import { selectImageLabelingActiveJobId } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { useImageLabelingContext } from "../../context/image-labeling.context";

interface Labeler {
  email: string;
  jobId: number;
  selected?: boolean;
}
interface Props {
  labelers: Labeler[];
}

export const JobLabelerList = ({ labelers }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeJobId = useAppSelector(selectImageLabelingActiveJobId);
  const { selectLabelByIndex } = useImageLabelingContext();

  function handleMouseEnter(_: Labeler) {
    // TODO
  }

  function handleMouseLeave(_: Labeler) {
    // TODO
  }

  function handleEditMediaAgreement() {
    selectLabelByIndex(-1);
    dispatch(imageViewPreviousJobAsync({ jobId: activeJobId }));
  }

  return (
    <div>
      <div className="flex items-center justify-between p-4">
        <div className="text-xs font-bold text-gray-400 uppercase">
          {t("labelerworkspace:panel.textPreviousFindings")} ({labelers.length})
        </div>
        <button
          className="flex items-center justify-center w-6 h-6 focus:outline-none"
          onClick={handleEditMediaAgreement}
        >
          <i className="uir-edit"></i>
        </button>
      </div>
      <div className="px-4 pb-4">
        {labelers.map((labeler) => {
          return (
            <div
              onMouseEnter={() => handleMouseEnter(labeler)}
              onMouseLeave={() => handleMouseLeave(labeler)}
              key={labeler.jobId}
              className={classnames(
                "inline-block py-1 px-2 m-1 text-sm rounded-full cursor-pointer bg-blueGray-600 focus:outline-none",
                {
                  "border border-white": !!labeler.selected,
                }
              )}
            >
              {getUserNameFromEmail(labeler.email)}
            </div>
          );
        })}
      </div>
    </div>
  );
};
