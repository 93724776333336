/*
 * File: delete-dataset-dialog.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/use-redux";
import { useState } from "react";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { DatasetDTO } from "services/label-service/dtos";
import { deleteDatasetAsync } from "../../../../../store/customer/dataset/dataset.slice";
import * as Sentry from "@sentry/react";

interface Props {
  dataset: DatasetDTO;
  handleClose: () => void;
}

export const DeleteDatasetDialog = ({ dataset, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);

  async function handleDelete() {
    if (!dataset) return;
    if (processing) return;
    setProcessing(true);
    try {
      const response = await dispatch(deleteDatasetAsync(dataset.id));
      if (response.meta.requestStatus === "rejected") throw Error();
      dispatch(enqueueSuccessNotification(t("common:textDeletedSuccess")));
    } catch (err: any) {
      Sentry.captureException(err);
      const message = err.message || t("common:textDeletedFailed");
      dispatch(enqueueErrorNotification(message));
    } finally {
      handleClose();
      setProcessing(false);
    }
  }

  return (
    <VBModal
      title={t("dataset:delete.title")}
      open={!!dataset}
      onClose={handleClose}
      textSubmit={t("common:buttonDelete")}
      onSubmit={handleDelete}
      disableSubmit={processing}
      blockUI={processing}
      processingIndicator={processing}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: t("dataset:delete.textWarn", { name: dataset.name }),
        }}
      />
    </VBModal>
  );
};
