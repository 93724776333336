/*
 * File: ai-models.state.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 2nd November 2022 4:15:26 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export interface UploadAIModelPayload {
  name: string;
  description: string;
  inferenceType: string;
  file: File | null;
}
export interface AIModelsState {
  uploadProgress: number;
  isLoading: boolean;
  isImporting: boolean;
  onClickUpload(): void;
  startImportAIModel(payload: UploadAIModelPayload): Promise<void>;
}

export const defaultAIModelsState = {} as AIModelsState;
