/*
 * File: annotation.api.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 19th October 2021 3:50:40 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AxiosResponse } from "axios";
import { TaskRunnerType } from "domain/customer/task-runner";
import { AnnotationSource } from "domain/labeling/annotation-source";
import { uploadResource } from "services/storage/apis/resource.api";
import { AnnotationJobResponseDTO } from "../dtos/annotations.dto";
import { TextObservationV3DTO } from "../dtos/task-observation-v3.dto";
import { AnnotationAPI } from "./annotation.api";

export class AnnotationV3API extends AnnotationAPI {
  async generateAnnotationFromFile(
    file: File,
    batchId: number,
    format: string
  ): Promise<AxiosResponse<any>> {
    const resource = await uploadResource(file);
    const payload = {
      batchId: batchId,
      type: TaskRunnerType.ANNOTATION_IMPORT,
      item: {
        format: format,
        storageResourceId: resource.resourceId,
      },
    };
    const url = `${this.getPath()}/job`;
    return this.post(url, payload);
  }

  getTextAnnotationsByFile(
    batchId: number,
    mediaId: number | string
  ): Promise<AxiosResponse<TextObservationV3DTO>> {
    const payload: Record<string, string> = {
      "batchId.equals": batchId.toString(),
      "mediaId.equals": mediaId.toString(),
      "source.in": [
        AnnotationSource.CLIENT,
        AnnotationSource.IMPORT,
        AnnotationSource.MODEL,
      ].join(","),
      size: "100000",
    };
    return this.getItems(payload);
  }

  getImageAnnotationsByFile(batchId: number, mediaId: number | string) {
    const payload: Record<string, string> = {
      "batchId.equals": batchId.toString(),
      "mediaId.equals": mediaId.toString(),
      "source.in": [
        AnnotationSource.CLIENT,
        AnnotationSource.IMPORT,
        AnnotationSource.MODEL,
      ].join(","),
      size: "100000",
    };
    return this.getItems(payload);
  }

  getAnnotationsByTaskId(
    taskId: string | number
  ): Promise<AxiosResponse<AnnotationJobResponseDTO>> {
    return this.get(`${this.getPath()}/task/${taskId}`);
  }
}
