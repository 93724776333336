import "@kitware/vtk.js/Rendering/Profiles/All";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { vtkColorMaps } from "../vtk_import";
import { useThreeDEditorContext } from "./three-d-editor.provider";
import { VolumeColorComponent } from "./volume-color.component";
import { classnames } from "utilities/classes";
import { useAppPrevious } from "hooks/use-app-previous";
import { ThreeDEditorWindowSelectComponent } from "./three-d-editor-window-select.component";
import {
  EditorEventForceWindowLeave,
  EditorEventSwapWindow,
  ThreeDEditorEvents,
} from "./three-d-editor.models";
import * as Sentry from "@sentry/react";

interface Props {
  windowId: number;
  onLoaded?: () => void;
  onError?: (error: any) => void;
}
export const WindowVolume = ({ windowId, onLoaded, onError }: Props) => {
  const {
    activeWindow,
    setActiveWindow,
    editorContext,
    volume3dVisibility,
    slices3dVisibility,
    label3dVisibility,
    labels,
    viewLabels,
    isShowViewLabels,
    updateObjectColorsAndOpacity,
    presetName,
    currentLayout,
    windowsDataMap,
  } = useThreeDEditorContext();
  const previousEditorContext = useAppPrevious(editorContext);
  const isWindowActive = useMemo(
    () => activeWindow === windowId,
    [activeWindow, windowId]
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const [realTimeUpdate] = useState<boolean>(false);
  const realTimeUpdateRef = useRef<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [colorPanelVisibility, setColorPanelVisibility] = useState(false);

  const isSelfCheckActiveWindow = useRef(false);

  const label3dVisibilityRef = useRef(true);

  useEffect(() => {
    label3dVisibilityRef.current = label3dVisibility;
  }, [label3dVisibility]);

  useEffect(() => {
    realTimeUpdateRef.current = realTimeUpdate;
  }, [realTimeUpdate]);

  const updatePreset = useCallback((colorMap, imageVolume, imageData) => {
    imageVolume.cfunc.applyColorMap(colorMap);
    const range = imageData.getPointData().getScalars().getRange();
    imageVolume.cfunc.setMappingRange(...range);
    imageVolume.cfunc.updateRange();
  }, []);

  useEffect(() => {
    if (!editorContext || !containerRef.current) return;
    if (previousEditorContext === editorContext) return;

    try {
      console.log("Init window volume...");

      const {
        imageData,
        painter,
        windowVolume,
        imageVolume,
        labelFilterVolume,
      } = editorContext;
      const { genericRenderWindow, renderer, renderWindow } = windowVolume;
      genericRenderWindow.setContainer(containerRef.current as HTMLDivElement);
      genericRenderWindow.resize();

      imageVolume.mapper.setInputData(imageData);
      imageVolume.actor.setMapper(imageVolume.mapper);

      imageVolume.actor
        .getProperty()
        .setRGBTransferFunction(0, imageVolume.cfunc);
      imageVolume.actor.getProperty().setScalarOpacity(0, imageVolume.ofunc);
      imageVolume.actor.setVisibility(false);

      // set up filter label map
      // NOTE: use marching cubes later
      // labelFilterVolume.marchingCubes.setInputData(painter.getOutputData());
      // labelFilterVolume.mapper.setInputData(labelFilterVolume.marchingCubes.getOutputData());
      // labelFilterVolume.actor.setMapper(labelFilterVolume.mapper);
      // labelFilterVolume.actor.getProperty().setColor(1, 1, 0);
      // labelFilterVolume.actor.getProperty().setOpacity(0.5);

      labelFilterVolume.mapper.setInputConnection(painter.getOutputPort());
      labelFilterVolume.actor.setMapper(labelFilterVolume.mapper);
      labelFilterVolume.actor
        .getProperty()
        .setRGBTransferFunction(0, labelFilterVolume.cfunc);
      labelFilterVolume.actor
        .getProperty()
        .setScalarOpacity(0, labelFilterVolume.ofunc);
      labelFilterVolume.actor.getProperty().setInterpolationTypeToNearest();

      // NOTE: use marching cubes later
      // renderer.addActor(labelFilterVolume.actor);

      renderer.addVolume(labelFilterVolume.actor);
      renderer.addVolume(imageVolume.actor);

      renderer.resetCamera();
      renderWindow.render();

      const loop = setInterval(() => {
        if (!realTimeUpdateRef.current) return;
        renderWindow.render();
      }, (1 / 30) * 1000);

      console.log("Done init window volume");

      onLoaded && onLoaded();

      return () => {
        clearInterval(loop);
      };
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
      onError && onError(error);
    }
  }, [previousEditorContext, editorContext, updatePreset, onLoaded, onError]);

  useEffect(() => {
    if (!editorContext || !labels) return;
    const {
      labelFilterVolume,
      // windowVolume,
    } = editorContext;

    if (isShowViewLabels) {
      updateObjectColorsAndOpacity(labelFilterVolume, viewLabels, true);
    } else {
      updateObjectColorsAndOpacity(labelFilterVolume, labels, true);
    }

    if (label3dVisibilityRef.current) {
      // windowVolume.renderWindow.getInteractor().render();
    }
  }, [
    labels,
    viewLabels,
    isShowViewLabels,
    editorContext,
    updateObjectColorsAndOpacity,
  ]);

  useEffect(() => {
    if (!editorContext) return;
    const { imageVolume, windowVolume } = editorContext;

    imageVolume.actor.setVisibility(volume3dVisibility);
    windowVolume.renderWindow.getInteractor().render();
  }, [editorContext, volume3dVisibility]);

  useEffect(() => {
    if (!editorContext) return;
    const { windowVolume, windowsSliceArray } = editorContext;

    for (const windowSliceData of windowsSliceArray) {
      if (slices3dVisibility) {
        windowVolume.renderer.addActor(windowSliceData.imageSlice.image.actor);
      } else {
        windowVolume.renderer.removeActor(
          windowSliceData.imageSlice.image.actor
        );
      }
    }
    windowVolume.renderWindow.getInteractor().render();
  }, [editorContext, slices3dVisibility]);

  useEffect(() => {
    if (!editorContext) return;
    const { labelFilterVolume, windowVolume } = editorContext;

    labelFilterVolume.actor.setVisibility(label3dVisibility);
    windowVolume.renderWindow.getInteractor().render();
  }, [editorContext, label3dVisibility]);

  useEffect(() => {
    if (!editorContext) return;
    const { imageData, imageVolume, windowVolume } = editorContext;
    const colorMap = vtkColorMaps.getPresetByName(presetName);
    updatePreset(colorMap, imageVolume, imageData);
    windowVolume.renderWindow.getInteractor().render();
  }, [editorContext, presetName, updatePreset]);

  useEffect(() => {
    if (!editorContext) return;
    const { windowVolume } = editorContext;
    windowVolume.genericRenderWindow.resize();
  }, [currentLayout, editorContext, windowsDataMap]);

  const doWorksOnWindowActive = useCallback(
    (windowId) => {
      setActiveWindow(windowId);
    },
    [setActiveWindow]
  );

  const doWorksOnWindowLeave = useCallback(() => {
    setActiveWindow(-1);
  }, [setActiveWindow]);

  useEffect(() => {
    const handleCommandAfterSwapWindowLeave = (e: any) => {
      const swapEvent = e.detail as EditorEventSwapWindow;
      if (swapEvent.fromWindow.id === windowId) {
        doWorksOnWindowLeave();
        document.dispatchEvent(
          new CustomEvent(ThreeDEditorEvents.COMMAND_AFTER_SWAP_WINDOW_ENTER, {
            detail: swapEvent,
          })
        );
      }
    };

    const handleCommandAfterSwapWindowEnter = (e: any) => {
      const swapEvent = e.detail as EditorEventSwapWindow;
      if (swapEvent.toWindow.id === windowId) {
        doWorksOnWindowActive(windowId);
      }
    };

    const handleCommandCheckSelfActiveWindow = (e: any) => {
      isSelfCheckActiveWindow.current = true;
    };

    const handleCommandForceWindowLeave = (e: any) => {
      const leaveEvent = e.detail as EditorEventForceWindowLeave;
      if (leaveEvent.windowToLeave.id === windowId) {
        doWorksOnWindowLeave();
      }
    };

    document.addEventListener(
      ThreeDEditorEvents.COMMAND_AFTER_SWAP_WINDOW_LEAVE,
      handleCommandAfterSwapWindowLeave
    );
    document.addEventListener(
      ThreeDEditorEvents.COMMAND_AFTER_SWAP_WINDOW_ENTER,
      handleCommandAfterSwapWindowEnter
    );
    document.addEventListener(
      ThreeDEditorEvents.COMMAND_CHECK_SELF_ACTIVE_WINDOW,
      handleCommandCheckSelfActiveWindow
    );
    document.addEventListener(
      ThreeDEditorEvents.COMMAND_FORCE_WINDOW_LEAVE,
      handleCommandForceWindowLeave
    );

    return () => {
      document.removeEventListener(
        ThreeDEditorEvents.COMMAND_AFTER_SWAP_WINDOW_LEAVE,
        handleCommandAfterSwapWindowLeave
      );
      document.removeEventListener(
        ThreeDEditorEvents.COMMAND_AFTER_SWAP_WINDOW_ENTER,
        handleCommandAfterSwapWindowEnter
      );
      document.removeEventListener(
        ThreeDEditorEvents.COMMAND_CHECK_SELF_ACTIVE_WINDOW,
        handleCommandCheckSelfActiveWindow
      );
      document.removeEventListener(
        ThreeDEditorEvents.COMMAND_FORCE_WINDOW_LEAVE,
        handleCommandForceWindowLeave
      );
    };
  }, [windowId, doWorksOnWindowLeave, doWorksOnWindowActive]);

  const handleContainerOnMouseEnter = () => {
    doWorksOnWindowActive(windowId);
  };

  const handleContainerOnMouseLeave = () => {
    doWorksOnWindowLeave();
  };

  const handleRefreshClick = () => {
    if (!editorContext || isRefreshing) return;
    setIsRefreshing(true);
    const { windowVolume } = editorContext;

    // NOTE: use marching cubes later
    // labelFilterVolume.marchingCubes.update();
    // labelFilterVolume.mapper.setInputData(labelFilterVolume.marchingCubes.getOutputData());

    windowVolume.renderWindow.render();
    setIsRefreshing(false);
  };

  const handleContainerOnMouseMove = (e: any) => {
    if (isSelfCheckActiveWindow.current) {
      if (!isWindowActive) {
        doWorksOnWindowActive(windowId);
      }
      isSelfCheckActiveWindow.current = false;
    }
  };

  return (
    <div
      className={classnames(
        "w-full h-full flex items-center justify-center relative",
        { "border-2 border-background-800": !isWindowActive },
        { "border-2 border-warning-500": isWindowActive }
      )}
      onMouseEnter={handleContainerOnMouseEnter}
      onMouseLeave={handleContainerOnMouseLeave}
      onMouseMove={handleContainerOnMouseMove}
    >
      <div className="w-full h-full" ref={containerRef}></div>
      <div className="absolute flex items-center gap-2 px-2 py-1 text-sm text-white rounded right-1 bottom-1 opacity-60 bg-background-900">
        <div className="flex items-center gap-1">
          <input
            type="checkbox"
            checked={colorPanelVisibility}
            onChange={(e) => setColorPanelVisibility(e.target.checked)}
          />
          <span>Color panel</span>
        </div>
        <button
          className="px-4 py-1 border rounded disabled:opacity-50"
          onClick={handleRefreshClick}
          disabled={isRefreshing}
        >
          Refresh
        </button>
      </div>

      <div
        className={classnames("fixed left-2 bottom-2 z-50", {
          hidden: !colorPanelVisibility,
        })}
      >
        <VolumeColorComponent onClose={() => setColorPanelVisibility(false)} />
      </div>

      <ThreeDEditorWindowSelectComponent id={windowId} />
    </div>
  );
};
