/*
 * File: task-progress.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 29th May 2023 8:50:02 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Box, LinearProgress, Typography } from "@material-ui/core";

interface Props {
  value: number;
}
export function TaskProgresss(props: Props) {
  if (props.value < 0 || props.value > 100) return null;
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
