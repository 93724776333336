

export const downloadTextAsFile = (
  fileName: string,
  content: string,
  fileType: string = "data:text/plain;charset=utf-8,",
) => {
  let element = document.createElement("a");
  element.setAttribute("href", fileType + encodeURIComponent(content));
  element.setAttribute("download", fileName);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export const downloadTextAsCSV = (
  fileName: string,
  content: string,
) => {
  downloadTextAsFile(fileName, content, "data:text/csv;charset=utf-8,");
}

export const downloadObjectAsJsonFile = (
  fileName: string,
  contentObject: object,
) => {
  const content = JSON.stringify(contentObject, null, 2);
  downloadTextAsFile(fileName, content, "data:application/json;charset=utf-8,");
}

export const downloadUrlAsFile = (url: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const delay = (delay: number = 1) => {
  return new Promise<number>(res => setTimeout(_ => res(delay), delay*1000));
}
