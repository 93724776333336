import moment from "moment";
import { MlRegisteredModelResponse } from "services/ml-service/ml-service.dto";
import { MlModelsRegisteredModelVersionUIModel } from "./registered-models.models";


export const registeredModelMapper = {
  dtoToVersions: (dto: MlRegisteredModelResponse): MlModelsRegisteredModelVersionUIModel[] => {
    const versions: MlModelsRegisteredModelVersionUIModel[] = [];

    for (const version of dto.modelInfo) {
      versions.push({
        modelName: dto.name,
        versionId: version.id,
        versionCreatedAt: moment(new Date(version.created_at)).local().toDate(),
      });
    }

    return versions;
  }
}
