/*
 * File: dataset.dto.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BaseDTO } from "services/base-service/dto/base.dto";

export const DATASET_CATEGORY_COR = "cor";

export interface DatasetDTO extends BaseDTO {
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  version: number;
  workspaceId: string | null;
  name: string;
  description: string;
  type: string;
  versions: any;
  totalFile: number;
  category?: string;
  deleted?: boolean;
  parentId?: number;
  thumbnailUrl: string;
}

export enum DatasetCategories {
  NLP_COR = "cor",
  GENERAL_IMAGE = "general_image",
  MEDICAL_IMAGE = "medical_image",
  SPEECH_TO_TEXT = "speech2text",
  TEXT_TO_SPEECH = "text2speech",
}
