import {
  SelectOption,
  VBSelectComponent,
} from "components/design-system/select-input/select.component";
import { JobOption } from "domain/common/models";
import { useCallback, useMemo, useState } from "react";
import { BatchType } from "services/label-service/dtos";
import {
  MODEL_ASSIGNEE,
  SYSTEM_ASSIGNEE,
} from "services/label-service/dtos/task-observation-v3.dto";
import { useBatchDetailContext } from "../../../../context/batch-detail.context";

interface TaskReviewActionPanelLabelerProps {
  className?: string;
  jobs: JobOption[];
  onChange?: (value: SelectOption["value"]) => void;
}

export const TaskReviewActionPanelFindingLabeler = ({
  className,
  jobs,
  onChange,
}: TaskReviewActionPanelLabelerProps) => {
  const [selectedValue, setSelectedValue] =
    useState<SelectOption["value"]>("All");
  const { batch } = useBatchDetailContext();

  const options = useMemo(() => {
    const items = [
      { label: "All Labelers", value: "All" },
      { label: `Manual Import${batch.type === BatchType.EVALUATION ? " (Ground truth)" : ""}`, value: SYSTEM_ASSIGNEE },
      { label: "AI Model", value: MODEL_ASSIGNEE },
    ];
    for (let job of jobs) {
      if (job.assignee === SYSTEM_ASSIGNEE || job.assignee === MODEL_ASSIGNEE)
        continue;
      items.push({
        label: job.assignee,
        value: job.assignee,
      });
    }
    return items;
  }, [jobs, batch]);

  const handleChangeLabeler = useCallback(
    (option: any) => {
      setSelectedValue(option.value);
      onChange && onChange(option.value);
    },
    [onChange]
  );

  return (
    <div className={className}>
      <div className="text-sm font-semibold mb-4">Show Labeling Work</div>

      <VBSelectComponent
        onChange={handleChangeLabeler}
        value={options.find((option) => option.value === selectedValue)}
        options={options}
      />
    </div>
  );
};
