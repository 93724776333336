/*
 * File: observation-card.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 9th August 2021 9:17:26 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { VBKeyboardKey } from "components/common/vb-keyboard-key.component";
import { LightCheckbox } from "components/common/vb-light-checkbox.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  selectImageLabelingLabels,
  selectSelectedSystemObservation,
} from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { classnames } from "utilities/classes";
import { Fragment } from "react";
import {
  selectImageLabelingActiveJobId,
  selectImageLabelingActiveLaterality,
} from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { selectJobAnnotationsByLabelId } from "store/labeler/image-workspace/image-annotations/image-annotations.selectors";
import {
  Annotation,
  AnnotationAttribute,
} from "domain/image-labeling/annotation";
import { AnnotateType } from "constants/annotation.constant";
import { Label } from "domain/image-labeling";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { imageClassificationAttributeUpdatedAsync } from "store/labeler/image-workspace/image-annotations/thunks/image-classification-attribute-updated.thunk";
import VBRadioInputComponent from "components/design-system/radio/radio.component";
import { SystemObservationCode } from "services/label-service/dtos";
import { selectIsMedicalMamoProject } from "store/customer/project/project.selectors";
import { Edit2 } from "iconsax-react";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";
import { jobObservationEdited } from "store/labeler/image-workspace/batch-labeling/batch-labeling.slice";
import { VBTextAreaComponent } from "components/design-system/text-area/text-area.component";

interface ObservationCardProps {
  readonly?: boolean;
  label: Label;
  active: boolean;
  deep?: number;
  onRemove?: (label: Label) => void;
  onSelect?: (label: Label) => void;
  onChange?: (updatedLabel: Label) => void;
  onVisibleChange?: (label: Label, visible: boolean) => void;
  onSelectedChange?: (label: Label, selected: boolean) => void;
}
export const ObservationCard = ({
  label,
  active = false,
  deep = 0,
  onSelect,
  onVisibleChange,
  onSelectedChange,
  onChange,
}: ObservationCardProps) => {
  const [isHover, setIsHover] = useState(false);
  const labels = useAppSelector(selectImageLabelingLabels);
  const activeLaterality = useAppSelector(selectImageLabelingActiveLaterality);
  const systemObservation = useAppSelector(selectSelectedSystemObservation);
  const isMammography = useAppSelector(selectIsMedicalMamoProject);
  const { openModal, closeModal } = useModalContext();
  const keyboardShortcut = useMemo(() => {
    let idx = labels.findIndex((lb) => label.id === lb.id);
    return idx === -1 ? "" : `${idx + 1}`;
  }, [labels, label]);

  const isClassificationLabel = useMemo(() => {
    return label.annotateType === AnnotateType.CLASSIFICATION;
  }, [label]);

  const hidden = useMemo(() => {
    if (isMammography && activeLaterality) {
      if (
        systemObservation &&
        (systemObservation.code === SystemObservationCode.NOT_FOUND ||
          systemObservation.code === SystemObservationCode.INVALID)
      ) {
        return true;
      }
      return !(
        label?.name?.toLowerCase() === activeLaterality?.toLowerCase() ||
        label?.code?.toLowerCase() === activeLaterality?.toLowerCase()
      );
    }

    return false;
  }, [activeLaterality, label, systemObservation, isMammography]);

  function handleSelect() {
    onSelect && onSelect(label);
  }

  function handleToggleVisible(event: FormEvent) {
    event.preventDefault();
    event.stopPropagation();
    onVisibleChange && onVisibleChange(label, !!label.hidden);
  }

  function handleToggleSelected(event: FormEvent) {
    event.preventDefault();
    event.stopPropagation();
    onSelectedChange && onSelectedChange(label, !!label.selected);
  }

  function handleEdit(event: FormEvent) {
    event.preventDefault();
    event.stopPropagation();

    openModal(ModalTypes.UPDATE_LABEL, {
      label: label,
      onSubmit: (newLabel: Label) => {
        onChange && onChange(newLabel);
        closeModal();
      },
    });
  }

  function handleMouseOver(_: any) {
    setIsHover(true);
  }

  function handleMouseLeave(_: any) {
    setIsHover(false);
  }

  if (isClassificationLabel && hidden) return <></>;

  return (
    <div className="relative">
      <div
        className={classnames({
          "bg-blueGray-700": active,
          "hover:bg-blueGray-800": !active,
        })}
        style={{ paddingLeft: `${deep}rem` }}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {/* HARDE CODE for Breast cancer here >.< */}
        {isClassificationLabel && (
          <div className="flex flex-col gap-2 px-4 py-2 text-black">
            <div className="flex items-center gap-2">
              {(!label.attributes || label.attributes.length === 0) && (
                <LightCheckbox
                  onClick={handleToggleSelected}
                  checked={!!label.selected}
                />
              )}
              <span className="text-white">{label.name}</span>{" "}
              {label.require && <span className="text-red-500">*</span>}
            </div>
            {label.attributes && label.attributes.length > 0 && (
              <ClassificationAttributes label={label} />
            )}
          </div>
        )}
        {!isClassificationLabel && (
          <Fragment>
            <div className="flex items-center text-white">
              <LightCheckbox
                onClick={handleToggleSelected}
                checked={!!label.selected}
              />
              <div
                className="flex-1 py-3 cursor-pointer"
                onClick={handleSelect}
              >
                <div>
                  {label.name}
                  {label.requireBorder && (
                    <span className="text-red-500">*</span>
                  )}
                </div>
                <div className="space-x-2 text-xs text-gray-300">
                  <span>Color: {label.color}</span>
                  <span>Opacity: {label.opacity ?? 20}</span>
                </div>
              </div>

              {isHover && (
                <button className="flex-none pr-4 btn" onClick={handleEdit}>
                  <Edit2 size={16} />
                </button>
              )}

              {(label.hidden || isHover) && (
                <button
                  className="flex-none pr-4 btn"
                  onClick={handleToggleVisible}
                >
                  <i
                    className={`${
                      !label.hidden ? "uir-eye" : "uir-eye-crossed"
                    }`}
                  ></i>
                </button>
              )}

              {keyboardShortcut && (
                <VBKeyboardKey
                  text={keyboardShortcut}
                  className={
                    active
                      ? "mr-4 text-white bg-warning-500"
                      : "mr-4 text-white bg-coolGray-700"
                  }
                />
              )}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};
interface ClassificationAttributeProps {
  label: Label;
}
function ClassificationAttributes({ label }: ClassificationAttributeProps) {
  const jobId = useAppSelector(selectImageLabelingActiveJobId);
  const annotations = useAppSelector(
    selectJobAnnotationsByLabelId(jobId, label.id)
  );
  const annotation: Annotation | undefined = useMemo(() => {
    if (annotations.length > 0) {
      return annotations[0];
    }
    return undefined;
  }, [annotations]);
  const dispatch = useAppDispatch();
  const [values, setValues] = useState<AnnotationAttribute[]>([]);
  useEffect(() => {
    if (
      annotation &&
      annotation.annotationData.length > 0 &&
      annotation.annotationData[0].attributes
    ) {
      setValues(annotation?.annotationData[0].attributes);
    }
  }, [annotation]);

  function handleValueChanges(id: number, newValues: string[], submit = false) {
    let newVals = [];
    if (values.find((val) => val.id === id)) {
      newVals = [
        ...values.map((value) => {
          if (value.id === id) {
            return {
              ...value,
              value: newValues,
            };
          }
          return value;
        }),
      ];
    } else {
      newVals = [...values, { id, value: newValues }];
    }
    newVals = newVals.filter((val) => val.value.length);
    setValues(newVals);
    if (submit) {
      const labelId = label.id;
      const payload = {
        jobId,
        labelId,
        attributes: newVals,
      };
      dispatch(imageClassificationAttributeUpdatedAsync(payload));
      dispatch(jobObservationEdited(jobId));
    }
  }

  const getDefaultValues = useCallback(
    (attributeId: number) => {
      return (
        annotation?.annotationData[0].attributes?.find(
          (attr) => attr.id === attributeId
        )?.value || []
      );
    },
    [annotation]
  );

  const getDefaultValue = useCallback(
    (attributeId: number) => {
      const values =
        annotation?.annotationData[0].attributes?.find(
          (attr) => attr.id === attributeId
        )?.value || [];

      if (!values || values.length === 0) return undefined;
      return values[0];
    },
    [annotation]
  );

  return (
    <div className="pb-4 pl-4 space-y-2">
      {label.attributes?.map((attribute) => {
        return (
          <div key={attribute.name}>
            {attribute.type.toLowerCase() === "text" && (
              <VBTextAreaComponent
                require={attribute.required}
                header={attribute.name}
                headerClassName="text-white"
                type="text"
                className="w-full bg-gray-200"
                defaultValue={getDefaultValue(attribute.id)}
                onInputSubmit={(val: string) =>
                  handleValueChanges(attribute.id, [val], true)
                }
              />
            )}
            {attribute.type.toLowerCase() === "number" && (
              <VBTextInputComponent
                require={attribute.required}
                headerClassName="text-white"
                header={attribute.name}
                type="number"
                className="w-full"
                hideInputIcon
                defaultValue={getDefaultValue(attribute.id)}
                min={
                  attribute.ranges && attribute.ranges.length > 0
                    ? parseInt(attribute.ranges[0].toString())
                    : undefined
                }
                max={
                  attribute.ranges && attribute.ranges.length > 1
                    ? parseInt(attribute.ranges[1].toString())
                    : undefined
                }
                onInputSubmit={(val: string) =>
                  handleValueChanges(attribute.id, [val], true)
                }
              />
            )}

            {attribute.type.toLowerCase() === "select" && (
              <VBRadioInputComponent
                multiple
                require={attribute.required}
                header={attribute.name}
                options={attribute.options || []}
                defaultValues={getDefaultValues(attribute.id)}
                headerClassName="text-white"
                optionClassName="text-white"
                onValueChange={(values) => {
                  handleValueChanges(attribute.id, values, true);
                }}
              />
            )}

            {/* {attribute.type.toLowerCase() === "radio" && (
              <VBSelectComponent
                isClearable
                placeholder={`Select ${attribute.name}`}
                required={attribute.required}
                containerClassName="text-sm"
                headerClassName="text-white"
                header={attribute.name}
                options={
                  attribute.options?.map((val) => ({
                    label: val,
                    value: val,
                  })) || []
                }
                value={
                  getDefaultValue(attribute.id)
                    ? {
                        label: getDefaultValue(attribute.id),
                        value: getDefaultValue(attribute.id),
                      }
                    : undefined
                }
                onChange={(val: SingleValue<any>) => {
                  console.log("onChange", val);
                  handleValueChanges(
                    attribute.id,
                    val?.value ? [val.value] : [],
                    true
                  );
                }}
              />
            )} */}
            {attribute.type.toLowerCase() === "radio" && (
              <VBRadioInputComponent
                require={attribute.required}
                header={attribute.name}
                options={attribute.options || []}
                defaultValues={getDefaultValues(attribute.id)}
                headerClassName="text-white"
                optionClassName="text-white"
                onValueChange={(values) => {
                  handleValueChanges(attribute.id, values, true);
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
