/*
 * File: save-text-job.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 12th July 2022 5:14:51 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { LabelingBatchState } from "../text-labeling-batch.state";
import { LabelerJobServiceV3 } from "services/label-service";
import { RequestStatus } from "store/base/base.state";

const THUNK_NAME = "textLabelingBatch/textCompleteJobAsync";

interface CompleteTextJobPayload {
  jobId: number;
}
export const textCompleteJobAsync = createAsyncThunk(
  THUNK_NAME,
  async ({ jobId }: CompleteTextJobPayload, { getState }) => {
    await LabelerJobServiceV3.finishJob(jobId);
    return jobId;
  }
);

export function textCompleteJobBuilder(
  builder: ActionReducerMapBuilder<LabelingBatchState>
) {
  return builder.addCase(textCompleteJobAsync.fulfilled, (state, action) => {
    if (!action.payload) return;
    const jobId = action.payload;
    state.jobs.allIds = state.jobs.allIds.filter((id) => id !== jobId);
    delete state.jobs.entities[jobId];
    delete state.jobLoadingStatus[jobId];
    if (state.jobs.allIds.length > 0) {
      state.selectedJobId = state.jobs.allIds[0] as number;
    } else {
      state.jobLoadingStatus = {};
      state.jobLoadingStatus = {};
      state.pollJobStatus = RequestStatus.IDLE;
    }
  });
}
