export interface IouAnnotation {
  annotationIds: number[];
  metric: { name: string; value: number };
}

export interface TaskReviewIouState {
  iouAnnotations: IouAnnotation[];
  activeScore: number;
}

export const INITIAL_TASK_REVIEW_IOU_STATE: TaskReviewIouState = {
  iouAnnotations: [],
  activeScore: -1, // -1: display all annotations
};
