import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TicketSupportEntity } from "components/ticket-management/types";
import { CustomerService } from "services/customer-service/customer.service";
import { CustomerSupportState, INITIAL_CUSTOMER_SUPPORT_STATE } from "./customer-support.state";

const SLICE_NAME: string = "customer_support";

export enum CustomerSupportThunk {
  LOAD_TICKETS = "customerSupport/loadTickets",
  CREATE_TICKET = "customerSupport/createTicket",
}

export const loadTicketsAsync = createAsyncThunk(
  CustomerSupportThunk.LOAD_TICKETS,
  async () => {
    const response = await CustomerService.getAllTickets();
    return response.data;
  }
);


export const createTicketAsync = createAsyncThunk(
  CustomerSupportThunk.CREATE_TICKET,
  async (data: TicketSupportEntity) => {
    const title = data.title || "";
    const description = data.description || "";

    const response = await CustomerService.createTicket({
      title,
      description,
    });

    return response.data
  }
)

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_CUSTOMER_SUPPORT_STATE,
  reducers: {
    resetCustomerSupport(state: CustomerSupportState) {
      Object.assign(state, INITIAL_CUSTOMER_SUPPORT_STATE);
    },
    increaseBugOnDisplay(state: CustomerSupportState) {
      state.amountBugOnDisplay = state.amountBugOnDisplay + 1
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadTicketsAsync.fulfilled,
      (state, action) => {
        if (action.payload) state.tickets = action.payload;
      }
    );
    builder.addCase(
      loadTicketsAsync.rejected,
      (state, action) => {
        if (action.error) {
          state.tickets = []
        }
      }
    );
  },
});

export const { increaseBugOnDisplay, resetCustomerSupport } = slice.actions;

export const customerSupportReducer = slice.reducer;
