/*
 * File: export-actions.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 6th January 2022 10:02:55 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useClickAway } from "ahooks";
import {
  IconBackwardItem,
  IconExport,
  IconDocumentDownload,
  IconArrowDown,
  IconChevronRight,
} from "components/common/vb-icon.component";
import { useAppSelector } from "hooks/use-redux";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BatchStatus } from "services/label-service/dtos";
import { selectProjectExportTypes } from "store/customer/project/project.selectors";

interface ExportActionsProps {
  batchStatus?: BatchStatus;
  onAutoSplit?(): void;
  onDownloadDataset?(): void;
  onExportDataset?(): void;
  onImportAnnotation?(option: string): void;
  disableDownload?: boolean;
  disabledExport?: boolean;
  hideDownload?: boolean;
  hideGenerateAnnotation?: boolean;
}
export const ExportActions = ({
  disableDownload,
  batchStatus,
  onAutoSplit,
  onDownloadDataset,
  onExportDataset,
  onImportAnnotation,
  disabledExport = false,
  hideDownload = false,
  hideGenerateAnnotation = false,
}: ExportActionsProps) => {
  const { t } = useTranslation();

  const isBatchCompleted = useMemo(() => {
    if (!batchStatus) return false;
    if (batchStatus === BatchStatus.INITIAL) return false;
    if (batchStatus === BatchStatus.WORKING) return false;
    if (batchStatus === BatchStatus.ON_HOLD) return false;
    return true;
  }, [batchStatus]);

  return (
    <div className="flex items-center gap-4">
      {isBatchCompleted && !disabledExport && (
        <button className="button-secondary" onClick={onAutoSplit}>
          <span className="hidden text-sm font-semibold lg:inline-block">
            {t("project:batchDetails.batchexport.actions.buttonAutoSplit")}
          </span>
          <IconBackwardItem className="flex-none w-5 h-5" />
        </button>
      )}
      {isBatchCompleted && !disabledExport && (
        <button className="button-secondary" onClick={onExportDataset}>
          <span className="hidden text-sm font-semibold lg:inline-block">
            {t("project:batchDetails.batchexport.actions.buttonExportDataset")}
          </span>
          <IconExport className="flex-none w-5 h-5" />
        </button>
      )}

      {!hideDownload && (
        <button
          disabled={disableDownload}
          className="button-primary disabled:opacity-60"
          onClick={onDownloadDataset}
        >
          <span className="hidden text-sm font-semibold lg:inline-block">
            {t(
              "project:batchDetails.batchexport.actions.buttonDownloadDataset"
            )}
          </span>
          <IconDocumentDownload className="flex-none w-5 h-5 text-white" />
        </button>
      )}

      {!hideGenerateAnnotation && (
        <GenerateAnnotationButton onSelect={onImportAnnotation} />
      )}
    </div>
  );
};

interface GenerateAnnotationButtonProps {
  onSelect?(option: string): void;
}
const GenerateAnnotationButton = ({
  onSelect,
}: GenerateAnnotationButtonProps) => {
  const { t } = useTranslation();
  const exportTypes = useAppSelector(selectProjectExportTypes);
  const btnRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  function toggleMenu() {
    setOpen(!open);
  }

  function handleClick(option: any) {
    if (onSelect && option.value) {
      onSelect(option.value);
      setOpen(false);
    }
  }

  useClickAway(() => {
    if (open) setOpen(false);
  }, [btnRef, menuRef]);

  const options = useMemo(() => {
    return [
      { label: "Model", value: "MODEL" },
      {
        label: "From file",
        children: exportTypes.map((exportType) => {
          return { label: exportType.name, value: exportType.code };
        }),
      },
    ];
  }, [exportTypes]);

  return (
    <div className="relative z-50">
      <button
        ref={btnRef}
        onClick={toggleMenu}
        className="button-secondary disabled:opacity-60"
      >
        <span className="hidden text-sm font-semibold lg:inline-block">
          {t(
            "project:batchDetails.batchexport.actions.buttonGenerateAnnotation"
          )}
        </span>
        <IconArrowDown className="flex-none w-5 h-5" />
      </button>

      {open && (
        <div
          className="absolute w-full mt-1 bg-white rounded shadow-lg top-full animate-fade-in-up"
          ref={menuRef}
        >
          <div className="py-2">
            {options.map((option) => {
              return (
                <button
                  className="relative flex items-center justify-between w-full px-4 h-9 hover:bg-secondary-50 whitespace-nowrap group"
                  key={option.label}
                  onClick={() => handleClick(option)}
                >
                  {option.label}
                  {option.children && (
                    <IconChevronRight className="flex-none w-5 h-5" />
                  )}
                  {option.children && (
                    <div className="absolute top-0 hidden w-full group-hover:block left-full bg-none animate-fade-in-up">
                      <div className="w-full h-full py-2 overflow-hidden transform translate-x-1 bg-white rounded shadow-lg">
                        {option.children.map((child: any) => {
                          return (
                            <div
                              className="flex items-center w-full px-4 cursor-pointer h-9 hover:bg-secondary-50 whitespace-nowrap"
                              key={child.value}
                              onClick={() => handleClick(child)}
                            >
                              {child.label}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
