/*
 * File: export-dataset.component.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 5:36:05 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBSelect } from "components/common/vb-select.component";
import { VBSpinner } from "components/common/vb-spinner/vb-spinner.component";
import { useScope } from "hooks/scope/use-scope.hook";
import { useAppDispatch } from "hooks/use-redux";
import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { DatasetFilesExplorer } from "pages/customer/datasets/dataset-detail/components/explorer/dataset-files-explorer.component";
import { columns as exportDatasetColumns } from "pages/customer/datasets/dataset-detail/components/explorer/storage-table/storage-col-def-batch-export";
import { ViewFileInfoSwitch } from "pages/customer/datasets/dataset-detail/components/toolbar/view-file-info-switch.component";
import { useDatasetDetailContext } from "pages/customer/datasets/dataset-detail/dataset-detail.context";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { BatchManagementService } from "services/label-service";
import { RequestStatus } from "store/base/base.state";
import { setDatasetAsync } from "store/customer/dataset/dataset.slice";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { useBatchDetailContext } from "../../../context/batch-detail.context";
import * as Sentry from "@sentry/react";
interface Props {
  onMovedFiles?(): void;
}
export const ExportDataset = ({ onMovedFiles }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setCurrentDataset, filter } = useDatasetContext();
  const { selecting, selectedFiles } = useDatasetDetailContext();
  const { batch: currentBatch } = useBatchDetailContext();
  const { scopeOptions } = useScope();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);

  const options = useMemo(() => {
    return scopeOptions.filter((scope) => scope.value !== filter?.scope);
  }, [scopeOptions, filter]);
  const [selectedOption, setSelectedOption] = useState("1");
  const canMoveFiles = useMemo(
    () => selecting && selectedFiles.length > 0,
    [selecting, selectedFiles]
  );

  async function handleMoveFiles() {
    if (!canMoveFiles || !currentBatch) return;
    try {
      setRequestStatus(RequestStatus.LOADING);
      const response = await BatchManagementService.moveFilesScope(
        currentBatch,
        selectedFiles,
        parseInt(selectedOption)
      );
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      setRequestStatus(RequestStatus.SUCCESS);
      onMovedFiles && onMovedFiles();
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || t("common:textFailed");
      dispatch(enqueueErrorNotification(errMessage));
      setRequestStatus(RequestStatus.FAILURE);
      Logger.log(error);
    }
  }

  function handleSelectOption(event: ChangeEvent<{ value: unknown }>) {
    setSelectedOption(event.target.value as string);
  }

  useEffect(() => {
    setSelectedOption(options.length > 0 ? options[0].value : "1");
  }, [options]);

  useEffect(() => {
    if (!currentBatch) return;
    const _setDataAsync = async (datasetId: number) => {
      try {
        dispatch(setDatasetAsync(datasetId));
        setCurrentDataset(datasetId.toString());
      } catch (err) {
        Sentry.captureException(err);
        Logger.log(err);
      }
    };
    _setDataAsync(currentBatch.datasetId);
  }, [dispatch, currentBatch, setCurrentDataset]);

  const isRequesting = requestStatus === RequestStatus.LOADING;

  return (
    <div className={isRequesting ? "select-none pointer-events-none" : ""}>
      <div className="flex items-center gap-4 px-4 py-2 mt-4">
        <ViewFileInfoSwitch />
        <div className="flex-1"></div>
        <div
          className={`flex items-center ${
            canMoveFiles
              ? ""
              : "filter grayscale pointer-events-none hover:cursor-not-allowed opacity-50"
          }`}
        >
          <div className="px-2">
            {t("project:batchDetails.exportDataset.textMoveTo")}
          </div>
          {options && (
            <VBSelect
              options={options}
              value={selectedOption}
              onChange={handleSelectOption}
              variant="outlined"
              className="w-32"
            />
          )}
          <div className="h-4 mx-4 border-r"></div>
          <button
            disabled={isRequesting}
            className="flex items-center justify-center px-4 py-1 text-white rounded bg-primary"
            onClick={handleMoveFiles}
            style={{ minWidth: "6rem" }}
          >
            {isRequesting && <VBSpinner className="text-white" />}
            {!isRequesting && "Move"}
          </button>
        </div>
      </div>
      <DatasetFilesExplorer
        columns={exportDatasetColumns}
        height="480px"
        actions={["review"]}
      />
    </div>
  );
};
