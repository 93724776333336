/*
 * File: use-merge-datasource-options.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 14th July 2023 11:36:05 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Option } from "domain/common";
import { useEffect, useMemo, useState } from "react";

export function useMergeDatasourceOptions(defaultValue = "") {
  const options = useMemo<Option[]>(() => {
    return [
      {
        label: "Merge without delete sources",
        value: "merge",
      },
      {
        label: "Merge and delete sources",
        value: "merge_and_delete",
      },
    ];
  }, []);

  const [value, setValue] = useState(defaultValue);

  const option = useMemo(() => {
    return options.find((item) => item.value === value);
  }, [options, value]);

  function onChange(newOption?: any) {
    if (newOption && newOption.hasOwnProperty("value")) {
      setValue(newOption["value"]);
    } else {
      setValue("");
    }
  }

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return { options, value, setValue, option, onChange };
}

export default useMergeDatasourceOptions;
