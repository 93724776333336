export interface IouAnnotation {
  annotationIds: number[];
  metric: {
    name: string;
    value: number;
  };
}

export interface ImageIouState {
  iouAnnotations: IouAnnotation[];
  iouActiveScore: number;
}

export const INITIAL_IMAGE_IOU_STATE: ImageIouState = {
  iouAnnotations: [],
  iouActiveScore: -1, // -1: display all annotations
};
