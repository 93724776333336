/*
 * File: utils.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 23rd May 2023 9:35:56 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Entity } from "domain/common";
import { ITableColumn } from "domain/common/table";

export function renderCell<T extends Entity>(row: T, column: ITableColumn<T>) {
  if (column.render) return column.render(row);
  return (row as any)[column.field] ?? "";
}
