import cornerstone from "cornerstone-core";
import csTools from "cornerstone-tools";

const BaseTool = csTools.importInternal("base/BaseTool");
const { getToolState, removeToolState } = csTools;
const { state } = csTools.store;

/**
 * @public
 * @class EraserTool
 * @memberof Tools
 *
 * @classdesc Tool for deleting the data of other Annotation Tools.
 * @extends Tools.Base.BaseTool
 */

const IMMUTABLE_TOOLS = ["HeartRuler"];
const { eraserCursor } = csTools.importInternal("tools/cursors");

export default class EraserTool extends BaseTool {
  constructor(props = {}) {
    const defaultProps = {
      name: "Eraser",
      supportedInteractionTypes: ["Mouse", "Touch"],
      svgCursor: eraserCursor,
    };

    super(props, defaultProps);

    this.preMouseDownCallback = this._deleteAllNearbyTools.bind(this);
    this.preTouchStartCallback = this._deleteAllNearbyTools.bind(this);
  }

  _deleteAllNearbyTools(evt) {
    const coords = evt.detail.currentPoints.canvas;
    const element = evt.detail.element;
    state.tools.forEach(function (tool) {
      const toolState = getToolState(element, tool.name);
      if (IMMUTABLE_TOOLS.includes(tool.name)) {
        return;
      }

      if (toolState) {
        toolState.data.forEach(function (data) {
          if (
            typeof tool.pointNearTool === "function" &&
            tool.pointNearTool(element, data, coords)
          ) {
            removeToolState(element, tool.name, data);
            cornerstone.updateImage(element);
          }
        });
      }
    });

    return true;
  }
}
