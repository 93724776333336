/*
 * File: three-d-keyboard.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 20th February 2023 11:43:45 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useKeyPress } from "ahooks";
import { useCallback, useMemo } from "react";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { SlicingMode } from "../vtk_import";
import { ThreeDEditorEvents } from "./three-d-editor.models";
import { useThreeDEditorContext } from "./three-d-editor.provider";

export function ThreeDKeyboardHandle() {
  const { activeWindow, editorContext, currentLayout, windowsDataMap } =
    useThreeDEditorContext();
  const selectedAxis = useMemo(() => {
    if (activeWindow !== -1 && activeWindow !== 3) return activeWindow;
    if (currentLayout.numWindow !== 1) return SlicingMode.NONE;
    let id = SlicingMode.NONE;
    const keys = Object.keys(windowsDataMap);
    for (const key of keys) {
      const data = windowsDataMap[parseInt(key)];
      if (data && data.order === 1) {
        id = data.id;
        break;
      }
    }
    return id;
  }, [windowsDataMap, currentLayout.numWindow, activeWindow]);

  const goToNextSlice = useCallback(() => {
    if (selectedAxis === -1) return;
    const { windowsSliceArray, imageData } = editorContext;
    const sliceArray = windowsSliceArray.find(
      (item: any) => item.axis === selectedAxis
    );
    if (!sliceArray) return;
    const { imageSlice } = sliceArray;
    const currentSlice = imageSlice.image.mapper.getSlice();
    const extent: any = imageData.getExtent();
    const maxSlice = extent[selectedAxis * 2 + 1];
    const slice = (currentSlice + 1) % maxSlice;
    const eventData = { axis: selectedAxis, slice };
    const eventDetail = { detail: eventData };
    const event = new CustomEvent(
      ThreeDEditorEvents.COMMAND_GO_TO_SLICE,
      eventDetail
    );
    document.dispatchEvent(event);
  }, [editorContext, selectedAxis]);
  const goToPreviousSlice = useCallback(() => {
    if (selectedAxis === -1) return;
    const { windowsSliceArray, imageData } = editorContext;
    const sliceArray = windowsSliceArray.find(
      (item: any) => item.axis === selectedAxis
    );
    if (!sliceArray) return;
    const { imageSlice } = sliceArray;
    const currentSlice = imageSlice.image.mapper.getSlice();
    const extent: any = imageData.getExtent();
    const maxSlice = extent[selectedAxis * 2 + 1];
    const slice = (currentSlice - 1 + maxSlice) % maxSlice;
    const eventData = { axis: selectedAxis, slice };
    const eventDetail = { detail: eventData };
    const event = new CustomEvent(
      ThreeDEditorEvents.COMMAND_GO_TO_SLICE,
      eventDetail
    );
    document.dispatchEvent(event);
  }, [editorContext, selectedAxis]);

  useKeyPress(KeyboardKey.ArrowUp, goToNextSlice);
  useKeyPress(KeyboardKey.ArrowDown, goToPreviousSlice);

  return null;
}

export default ThreeDKeyboardHandle;
