/*
 * File: batch-statistic.state.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 23rd August 2021 11:38:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BaseState, RequestStatus } from "store/base/base.state";

export interface LabelerStatisticDTO {
  totalAnnotationTime: number;
  totalIssue: number;
  avgTimePerAnnotation: number;
  avgTimePerJob: number;
  avgAnnotationPerJob: number;
  projectType: string;
  lastModifiedDate: string;
  startDate: string;
  email: string;
  totalJob: number;
  groups: {
    group: string;
    totalCase: number;
    totalRelation?: number;
  }[];
}

export interface BatchStatisticDTO {
  /**BatchId */
  id: number;
  taskStatistic: Record<string, number>;
  reviewStatistic: Record<string, number>;
  totalLabeler: number;
  totalTask: number;
  startDate: string | null;
  lastModifiedDate: string | null;
  userStatistic: LabelerStatisticDTO[];
}

export interface BatchStatisticState extends BaseState<BatchStatisticDTO> {}

export const INITIAL_BATCH_STATISTIC_STATE: BatchStatisticState = {
  entities: {},
  ids: [],
  id: -1,
  status: RequestStatus.IDLE,
  error: null,
};
