/*
 * File: dicom-thumbnail.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 8th July 2021 3:48:43 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { HTMLProps, useState } from "react";
import { useEffect } from "react";
import {
  DicomServiceUtil,
  ThumbnailType,
} from "services/dicom/dicom-service.util";

interface Props extends HTMLProps<HTMLDivElement> {
  StudyInstanceUID: string;
  SeriesInstanceUID: string;
  SOPInstanceUID: string;
  type: ThumbnailType;
}

export const DicomThumbnail = ({
  StudyInstanceUID,
  SeriesInstanceUID,
  SOPInstanceUID,
  type = ThumbnailType.INSTANCE,
  className,
  ...rest
}: Props) => {
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const url = DicomServiceUtil.buildDicomThumbnail(
      type,
      StudyInstanceUID,
      SeriesInstanceUID,
      SOPInstanceUID
    );
    setThumbnailUrl(url);
    setLoading(false);
  }, [StudyInstanceUID, SeriesInstanceUID, SOPInstanceUID, type]);

  return (
    <div
      className={`w-full h-full flex items-center justify-center ${className}`}
      {...rest}
    >
      {thumbnailUrl && (
        <img
          src={thumbnailUrl}
          alt="dicom thumbnail"
          className="h-full max-w-full max-h-full"
        />
      )}
      {loading && (
        <div className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-black">
          <div className="flex items-center justify-center w-full h-full text-white">
            Loading....
          </div>
        </div>
      )}
    </div>
  );
};
