/*
 * File: dataset-info.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/use-redux";
import { useState, useRef, FormEvent } from "react";
import { DatasetDTO } from "services/label-service/dtos";
import { updateDatasetAsync } from "store/customer/dataset/dataset.slice";
import { enqueueSuccessNotification } from "store/common/notification/notification.actions";
import { classnames } from "utilities/classes";
import { Logger } from "utilities/logger";
import { getUserName } from "utilities/user/user-cache";
import { formatDateTime } from "utilities/formatter/date-formatter.utils";
import * as Sentry from "@sentry/react";

interface DatasetInfoProps {
  dataset: DatasetDTO;
}
export const DatasetInfo = ({ dataset }: DatasetInfoProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [hasChanges, setHasChanges] = useState(false);
  const [processing, setProcessing] = useState(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  function handleChanged() {
    const name = nameRef.current?.value.trim() || "";
    if (name.length === 0) return setHasChanges(false);
    const description = descriptionRef.current?.value.trim() || "";
    const originDescription = dataset?.description || "";
    setHasChanges(name !== dataset?.name || description !== originDescription);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (processing) return;
    setProcessing(true);
    const name = nameRef.current?.value.trim() || "";
    const description = descriptionRef.current?.value.trim() || "";
    await saveChanged(name, description);
    setProcessing(false);
  }

  async function saveChanged(name: string, description: string) {
    if (!dataset) return;
    try {
      const payload = {
        ...dataset,
        name: name,
        description: description,
      };
      await dispatch(updateDatasetAsync(payload));
      dispatch(enqueueSuccessNotification(t("common:textSaved")));
      setHasChanges(false);
    } catch (err) {
      Sentry.captureException(err);
      Logger.log(err);
    }
  }

  return (
    <div className="my-4">
      <form onSubmit={handleSubmit}>
        <div className="px-4 py-2">
          <label className="block mb-1 text-sm text-gray-400" htmlFor="name">
            {t("dataset:info.labelName")}
          </label>
          <input
            className="w-full px-4 py-2 border rounded focus:border-primary focus:outline-none"
            name="name"
            id="name"
            type="text"
            ref={nameRef}
            onChange={handleChanged}
            defaultValue={dataset.name}
          />
        </div>

        <div className="px-4 py-2">
          <label
            className="block mb-1 text-sm text-gray-400"
            htmlFor="description"
          >
            {t("dataset:info.labelDescription")}
          </label>
          <textarea
            className="w-full px-4 py-2 border rounded focus:border-primary focus:outline-none"
            name="description"
            id="description"
            placeholder={t("dataset:info.placeholderAddDescription")}
            rows={3}
            ref={descriptionRef}
            onChange={handleChanged}
            defaultValue={dataset.description || ""}
          />
        </div>
        <div className="px-4 py-2">
          <div className="block mb-1 text-sm text-gray-400">
            {t("dataset:info.textCreatedBy")}
          </div>
          <div>{getUserName(dataset.createdBy)}</div>
        </div>

        <div className="px-4 py-2">
          <div className="block mb-1 text-sm text-gray-400">
            {t("dataset:info.textCreatedDate")}
          </div>
          <div>
            {" "}
            {dataset.createdDate
              ? t("common:formattedDate", {
                  date: formatDateTime(new Date(dataset.createdDate)),
                })
              : ""}
          </div>
        </div>

        <div className="px-4 py-2">
          <div className="block mb-1 text-sm text-gray-400">
            {t("dataset:info.textLastModifiedBy")}
          </div>
          <div>{getUserName(dataset.lastModifiedBy || "")}</div>
        </div>

        <div className="px-4 py-2">
          <div className="block mb-1 text-sm text-gray-400">
            {t("dataset:info.textLastModifiedDate")}
          </div>
          <div>
            {dataset.lastModifiedDate
              ? t("common:formattedDate", {
                  date: formatDateTime(new Date(dataset.lastModifiedDate)),
                })
              : ""}
          </div>
        </div>

        <div className="px-4 py-2 mt-4">
          <button
            disabled={!hasChanges || processing}
            type="submit"
            className={classnames(
              "bg-primary text-white px-4 py-2 rounded focus:outline-none disabled:opacity-50",
              {
                "opacity-20 cursor-not-allowed": !hasChanges,
              }
            )}
          >
            {t("common:buttonSaveChanges")}
          </button>
        </div>
      </form>
    </div>
  );
};
