/*
 * File: users-table-column.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd January 2022 8:14:20 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useMemo } from "react";
import { classnames } from "utilities/classes";
import { ColumnHeader } from "./column.component";
import { ColDef, RowData } from "./types";

export const useColumns = () => {
  const columns = useMemo<ColDef<RowData>[]>(() => {
    return [
      {
        field: "name",
        header: "Name",
        renderColumnHeader: (column: ColDef<RowData>, columnIndex: number) => {
          return (
            <ColumnHeaderWrapper
              key={column.field}
              first={columnIndex === 0}
              last={columnIndex === columns.length - 1}
            >
              <ColumnHeader header={column.header} field={column.field} />
            </ColumnHeaderWrapper>
          );
        },
        renderCell: (row: RowData) => {
          return <span className="text-sm">{row.name}</span>;
        },
      },

      {
        field: "description",
        header: "Description",
        renderColumnHeader: (column: ColDef<RowData>, columnIndex: number) => {
          return (
            <ColumnHeaderWrapper
              key={column.field}
              first={columnIndex === 0}
              last={columnIndex === columns.length - 1}
            >
              <ColumnHeader header={column.header} field={column.field} />
            </ColumnHeaderWrapper>
          );
        },
        renderCell: (row: RowData) => {
          return (
            <span
              className="text-sm"
              dangerouslySetInnerHTML={{ __html: row.description }}
            />
          );
        },
      },

      {
        field: "numberOfSteps",
        header: "Steps",
        renderColumnHeader: (column: ColDef<RowData>, columnIndex: number) => {
          return (
            <ColumnHeaderWrapper
              key={column.field}
              first={columnIndex === 0}
              last={columnIndex === columns.length - 1}
            >
              <ColumnHeader header={column.header} field={column.field} />
            </ColumnHeaderWrapper>
          );
        },
        renderCell: (row: RowData) => {
          return <span className="text-sm">{row.numberOfSteps}</span>;
        },
      },

      {
        field: "createdAt",
        header: "Creation Date",
        renderColumnHeader: (column: ColDef<RowData>, columnIndex: number) => {
          return (
            <ColumnHeaderWrapper
              key={column.field}
              first={columnIndex === 0}
              last={columnIndex === columns.length - 1}
            >
              <ColumnHeader header={column.header} field={column.field} />
            </ColumnHeaderWrapper>
          );
        },
        renderCell: (row: RowData) => {
          return (
            <span className="text-sm">{row.createdAt.toDateString()}</span>
          );
        },
      },
      {
        field: "status",
        header: "Status",
        renderColumnHeader: (column: ColDef<RowData>, columnIndex: number) => {
          return (
            <ColumnHeaderWrapper
              key={column.field}
              first={columnIndex === 0}
              last={columnIndex === columns.length - 1}
            >
              <ColumnHeader header={column.header} field={column.field} />
            </ColumnHeaderWrapper>
          );
        },
        renderCell: (row: RowData) => {
          return <span className="text-sm">{row.status}</span>;
        },
      },
    ];
  }, []);
  return { columns };
};

interface ColumnHeaderWrapperProps {
  first?: boolean;
  last?: boolean;
  children?: JSX.Element;
}
const ColumnHeaderWrapper = ({
  first,
  last,
  children,
}: ColumnHeaderWrapperProps) => {
  return (
    <th
      className={classnames(
        "whitespace-nowrap text-background-700 bg-background-200 h-10 text-center",
        { "rounded-l": !!first, "rounded-r": !!last }
      )}
    >
      {children}
    </th>
  );
};
