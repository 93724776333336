/*
 * File: model-selector-table.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 24th February 2022 4:37:19 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { SearchableColumnHeader } from "components/common/vb-grid/searchable-column-header.component";
import { VBTablePaging } from "components/common/vb-table/vb-table-paging.component";
import { MLModel, MLModelVersion } from "domain/web-builder";
import { useEffect, useMemo, useState } from "react";
import { classnames } from "utilities/classes";

interface Props {
  rows: ModelSelectorRow[];
  setSelectedRow(row: ModelSelectorRow | null): void;
  selectedRow: ModelSelectorRow | null;
  pageSize?: number;
}

export interface ModelSelectorRow {
  model: MLModel;
  version: MLModelVersion;
}

const TableHeader = ({ first, last, maxWidth, children, width }: any) => {
  return (
    <th
      className={classnames(
        "whitespace-nowrap text-background-700 text-left font-normal",
        {
          "rounded-l": !!first,
          "pr-4": !last,
          "rounded-r": !!last,
        }
      )}
      style={{ maxWidth: maxWidth, width: width }}
    >
      {children}
    </th>
  );
};

export const ModelSelectorTable = ({
  rows,
  pageSize = 5,
  setSelectedRow,
  selectedRow,
}: Props) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [filter, setFilter] = useState<any>({
    name: "",
    version: "",
    description: "",
  });
  const defaultPageIndex = 1;
  const filteredRows = useMemo(() => {
    const { name, version, description } = filter;
    return rows.filter((row: ModelSelectorRow) => {
      const { model, version: modelVersion } = row;
      const { name: modelName, description: modelDescription } = model;
      const { name: modelVersionName } = modelVersion;
      return (
        (name === "" ||
          modelName?.toLowerCase?.().includes(name.toLowerCase())) &&
        (version === "" ||
          modelVersionName?.toLowerCase?.().includes(version.toLowerCase())) &&
        (description === "" ||
          modelDescription?.toLowerCase?.().includes(description.toLowerCase()))
      );
    });
  }, [rows, filter]);
  const totalItem = useMemo(() => filteredRows.length, [filteredRows]);
  const rowsToDisplay: any[] = useMemo(() => {
    const startIndex = (currentPageIndex - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize, totalItem);
    return filteredRows.slice(startIndex, endIndex);
  }, [filteredRows, currentPageIndex, pageSize, totalItem]);

  useEffect(() => {
    setSelectedRow(null);
  }, [filteredRows, setSelectedRow]);

  return (
    <div className="min-w-full overflow-x-auto h-96">
      <table className="min-w-full overflow-visible text-sm vb-table">
        <thead className="min-w-full">
          <tr>
            <TableHeader first>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={true}
                placeholder={""}
                header={"Model name"}
                containerWidth={"100%"}
                onInput={(e) => {
                  setFilter({ ...filter, name: e.currentTarget.value });
                }}
              />
            </TableHeader>
            <TableHeader>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={true}
                placeholder={""}
                header={"Model version"}
                containerWidth={"100%"}
                onInput={(e) => {
                  setFilter({ ...filter, version: e.currentTarget.value });
                }}
              />
            </TableHeader>
            <TableHeader last>
              <SearchableColumnHeader
                containerClassName="leading-normal"
                clearInput
                searchIcon={true}
                placeholder={""}
                header={"Model description"}
                containerWidth={"100%"}
                onInput={(e) => {
                  setFilter({ ...filter, description: e.currentTarget.value });
                }}
              />
            </TableHeader>
          </tr>
        </thead>
        <tbody className="relative">
          {rowsToDisplay.map((row) => (
            <tr
              key={row.model.id + row.version.id}
              className={classnames(
                "relative hover:bg-secondary-50 parent h-10 w-full overflow-visible",
                {
                  "bg-secondary-100 hover:bg-secondary-100":
                    selectedRow?.model.id === row.model.id &&
                    selectedRow?.version.id === row.version.id,
                }
              )}
              onClick={() => setSelectedRow(row)}
            >
              <td className="pl-4 pr-4" style={{ minWidth: 200, width: 400 }}>
                {row.model.name}
              </td>
              <td className="pl-4 pr-4" style={{ minWidth: 200, width: 134 }}>
                {row.version.name}
              </td>
              <td className="pl-4" style={{ minWidth: 200 }}>
                {row.model.description}
              </td>
            </tr>
          ))}
          {rowsToDisplay.length === 0 && (
            <tr className="relative w-full h-10 text-center parent">
              <td
                className="pl-4 pr-4"
                style={{ minWidth: 200, width: 400 }}
              ></td>
              <td
                className="pl-4 pr-4"
                style={{ minWidth: 200, width: 134 }}
              ></td>
              <td className="pl-4" style={{ minWidth: 200 }}></td>
              <td className="absolute inset-0 flex items-center justify-center w-full h-full">
                (No result)
              </td>
            </tr>
          )}
          <tr className="absolute top-0 left-0 w-full h-full border rounded pointer-events-none border-background-300"></tr>
        </tbody>
      </table>
      <VBTablePaging
        totalItem={filteredRows.length || 0}
        pageSize={pageSize}
        defaultPageIndex={defaultPageIndex}
        onPageIndexChanged={setCurrentPageIndex}
      />
    </div>
  );
};
