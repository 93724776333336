/*
 * File: project-option-group.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 10th September 2021 4:39:07 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useState } from "react";
import { classnames } from "utilities/classes";

interface OptionGroupProps {
  children: any;
  groupLabel: string;
  defaultActive?: boolean;
  locked?: boolean;
}

export const OptionGroup = ({
  children,
  groupLabel,
  locked = false,
  defaultActive = true,
}: OptionGroupProps) => {
  const [active, setActive] = useState(defaultActive);
  return (
    <div>
      <div className="flex items-center truncate">
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={active}
              onChange={(_, checked) => !locked && setActive(checked)}
            />
          }
          label={groupLabel}
        />
        <hr className="flex-1"></hr>
      </div>
      <div
        className={classnames("p-2", {
          "opacity-30 pointer-events-none": !active,
        })}
      >
        {children}
      </div>
    </div>
  );
};
