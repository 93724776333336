/*
 * File: job-info.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 14th January 2022 3:28:56 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
interface Props {
  projectId: number;
  batchId: number;
  taskId: number;
}

export const JobInfo = ({ projectId, batchId, taskId }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="absolute z-10 text-sm pointer-events-none select-none left-4 top-4 text-background-300">
      <div>{t("labelerworkspace:overlay.projectId", { projectId })}</div>
      <div>{t("labelerworkspace:overlay.batchId", { batchId })}</div>
      <div>{t("labelerworkspace:overlay.taskId", { taskId })}</div>
    </div>
  );
};
