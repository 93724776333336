/*
 * File: use-datasources.hook.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 2nd March 2022 1:53:28 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useAppSelector } from "hooks/use-redux";
import {
  selectUserDatasets,
  selectUserTotalDatasets,
} from "store/customer/dataset/dataset.selectors";

export const useDatasources = () => {
  const datasets = useAppSelector(selectUserDatasets);
  const totalDataset = useAppSelector(selectUserTotalDatasets);
  return { datasets, totalDataset };
};
