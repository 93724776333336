/*
 * File: project-template-card.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 25th July 2022 10:17:26 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Link } from "react-router-dom";

interface Props {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
}
export default function ProjectTemplateCard({
  id,
  title,
  description,
  imageUrl,
}: Props) {
  return (
    <Link to={`/projects/template/${id}`}>
      <div className="h-full overflow-hidden bg-white rounded shadow hover:shadow-lg">
        <div className="bg-gray-100 aspect-w-4 aspect-h-3 border-b border-background-200">
          <img
            src={imageUrl}
            alt={title}
            className="object-cover w-full h-full"
          />
        </div>
        <div className="p-4 space-y-2">
          <h3 className="text-base font-semibold">{title}</h3>
          <p className="text-sm text-background-500">{description}</p>
        </div>
      </div>
    </Link>
  );
}
