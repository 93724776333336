/*
 * File: image-viewer.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 9th August 2021 11:04:40 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { forwardRef, useEffect, useState } from "react";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { useDetectChange } from "hooks/use-detect-change";
import { DicomService } from "services/dicom/dicom-service";
import * as cornerstone from "cornerstone-core";
import { Logger } from "utilities/logger";
import { MouseRuler } from "./mouse-ruler.component";
import { isFileDicom } from "utilities/dicom/dicom.utils";

import * as Sentry from "@sentry/react";

interface Props {
  file?: StorageFileDTO;
  tags?: string[];
  active?: boolean;
  onLoaded?: (imageId?: string) => void;
  onError?: (error: Error) => void;
}

const getImageUrl = async (file: StorageFileDTO) => {
  if (file.dicomMetaData) {
    const { seriesId, studyId, instanceId } = file.dicomMetaData;
    return DicomService.getImageUrl(studyId, seriesId, instanceId);
  }
  if (file.url && isFileDicom(file)) return `wadouri:${file.url}`;
  return file.url;
};

export const ImageViewer = forwardRef<HTMLDivElement, Props>(
  ({ file, tags, active, onLoaded, onError }: Props, ref) => {
    const [loaded, setLoaded] = useState(false);
    const fileChanged = useDetectChange([file]);

    useEffect(() => {
      if (!file || !fileChanged) return;
      const loadData = async function () {
        try {
          setLoaded(false);
          const imageUrl = await getImageUrl(file);
          const element = (ref as any).current;
          cornerstone.enable(element);
          const image = await cornerstone.loadAndCacheImage(imageUrl);
          cornerstone.displayImage(element, image);
          cornerstone.resize(element, true);
          cornerstone.reset(element);
          onLoaded && onLoaded(imageUrl);
          setLoaded(true);
        } catch (err: any) {
          Sentry.captureException(err);
          Logger.log(err);
          const errMessage = err?.message || "Failed to load image";
          onError && onError(errMessage);
        }
      };
      if (ref && file) loadData();
    }, [fileChanged, file, onError, onLoaded, ref]);

    return (
      <div className="relative w-full h-full">
        <div
          className="relative w-full h-full viewport-element"
          ref={ref}
          id={file?.id.toString()}
          data-tags={tags}
          data-active={active}
        >
          <canvas className="cornerstone-canvas" />
          <MouseRuler />
        </div>

        {!loaded && <ImageViewerLoading />}
      </div>
    );
  }
);

function ImageViewerLoading() {
  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full text-background-500">
      Loading...
    </div>
  );
}
