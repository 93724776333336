/*
 * File: task-grid-item.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 28th September 2022 12:00:22 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Tooltip } from "@material-ui/core";
import classNames from "classnames";
import {
  IconAdd,
  IconTickCircle,
  IconTickSelected,
} from "components/common/vb-icon.component";
import VBImage from "components/common/vb-image/vb-image.component";
import PreviewAnnotationsComponent from "components/preview-annotations/preview-annotations.component";
import { useState, useMemo, MouseEvent } from "react";
import { TaskAction } from "services/label-service/dtos";
import { useBatchDetailContext } from "../../../context/batch-detail.context";
import { TaskRow, useBatchTaskContext } from "../batch-tasks.context";
import { VscReport, VscStopCircle, VscEye, VscTrash } from "react-icons/vsc";

interface TaskGridItemProps {
  item: TaskRow;
  onRowAction?(action: string, row: TaskRow): void;
}
export const TaskGridItem = ({ item, onRowAction }: TaskGridItemProps) => {
  const { batch, labels } = useBatchDetailContext();
  const [isHover, setIsHover] = useState(false);

  const thumbnailUrl = useMemo(() => {
    return item.data?.image?.thumbnailUrl || "";
  }, [item]);

  const { setTaskRowSelect, isBatchCompleted, canAssignJobsToTask } =
    useBatchTaskContext();

  const canAssignJobs = useMemo(() => {
    return canAssignJobsToTask(batch, item);
  }, [canAssignJobsToTask, batch, item]);

  function handleOnClick() {
    handleAction(TaskAction.REVIEW);
  }

  function handleSelect(event: MouseEvent) {
    preventDefaultEvent(event);
    setTaskRowSelect(item, !item.selected);
  }

  function handleAction(action: TaskAction, event?: MouseEvent) {
    preventDefaultEvent(event);
    onRowAction && onRowAction(action, item);
  }

  function preventDefaultEvent(event?: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();
  }

  const actions = [
    {
      title: "Assign jobs",
      onClick: (event: MouseEvent) => handleAction(TaskAction.ASSIGN, event),
      icon: <IconAdd className="flex-none w-4 h-4" />,
      disabled: !canAssignJobs,
    },
    {
      title: "Reopen",
      onClick: (event: MouseEvent) => handleAction(TaskAction.REOPEN, event),
      icon: <VscReport size={16} className="flex-none w-4 h-4" />,
      disabled: false,
    },
    {
      title: "Skip",
      onClick: (event: MouseEvent) => handleAction(TaskAction.SKIP, event),
      icon: <VscStopCircle size={16} className="flex-none w-4 h-4" />,
      disabled: false,
    },
    {
      title: "Review",
      onClick: (event: MouseEvent) => handleAction(TaskAction.REVIEW, event),
      icon: <VscEye size={16} className="flex-none w-4 h-4" />,
      disabled: false,
    },
    {
      title: "Delete",
      onClick: (event: MouseEvent) => handleAction(TaskAction.DELETE, event),
      icon: <VscTrash size={16} className="flex-none w-4 h-4" />,
      disabled: isBatchCompleted,
    },
  ];
  return (
    <div
      style={{ lineHeight: 0 }}
      className={classNames(
        "relative overflow-hidden bg-black rounded w-full h-full border",
        { "ring-4 ring-primary": item.selected }
      )}
      onMouseEnter={(_) => setIsHover(true)}
      onMouseLeave={(_) => setIsHover(false)}
      onClick={handleOnClick}
    >
      {!!thumbnailUrl && (
        <VBImage
          requireToken
          loadStyle="skeleton"
          src={thumbnailUrl}
          mask={
            <PreviewAnnotationsComponent labels={labels} data={item.data} />
          }
        />
      )}
      {!!!thumbnailUrl && (
        <div className="flex items-center justify-center w-full h-full p-4 bg-white text-background-700">
          <span className="py-8 truncate pointer-cursor">{item.fileName}</span>
        </div>
      )}

      <div
        className={`absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-black pointer-events-none transition-opacity ${
          isHover ? "opacity-20" : "opacity-0"
        }`}
      />

      {(isHover || item.selected) && (
        <Tooltip title="Select task">
          <button
            className="absolute overflow-hidden text-primary top-2 left-2"
            onClick={handleSelect}
          >
            {!item.selected && <IconTickCircle className="flex-none w-6 h-6" />}
            {item.selected && (
              <IconTickSelected className="flex-none w-6 h-6" />
            )}
          </button>
        </Tooltip>
      )}

      {isHover && (
        <div className="absolute z-50 flex items-center gap-1 top-2 right-2">
          {actions.map((action) => {
            if (action.disabled) return null;
            return (
              <Tooltip title={action.title} key={action.title}>
                <button
                  key={action.title}
                  className="flex items-center justify-center w-6 h-6 text-white bg-black rounded bg-opacity-40 hover:bg-opacity-80"
                  onClick={action.onClick}
                >
                  {action.icon}
                </button>
              </Tooltip>
            );
          })}
        </div>
      )}
    </div>
  );
};
