import { useWorkflow } from "hooks/workflow/use-workflow.hook";
import { StepData } from "pages/customer/projects/project.type";
import { useCallback, useMemo, useState } from "react";
import { useCreateProjectTemplateContext } from "../context/create-project-template.context";

export const useProjectWorkflowTab = () => {
  const {
    selectedWorkflowId,
    steps,
    setSelectedWorkflowId,
    onProjectAdvancedChanged,
  } = useCreateProjectTemplateContext();

  const { workflows } = useWorkflow();
  const [selectedStepIndex, setSelectedStepIndex] = useState(1);

  const isDisabledWorkflow = useMemo(() => {
    const selectedWorkflow = workflows.find(workflow => workflow.id === selectedWorkflowId);
    if (!selectedWorkflow) return false;
    return selectedWorkflow.createdBy !== 'system'
  }, [selectedWorkflowId, workflows])

  const selectedStep = useMemo(() => {
    return steps.find((item) => item.position === selectedStepIndex);
  }, [selectedStepIndex, steps]);

  const workflowOptions = useMemo(() => {
    return workflows.map((workflow) => ({
      label: workflow.name,
      value: workflow.id,
    }));
  }, [workflows]);

  const handleChangeWorkflow = useCallback(
    (option: any) => {
      const id = Number(option.value) || -1;
      const selectedWorkflow = workflows.find((workflow) => workflow.id === id);

      selectedWorkflow && setSelectedWorkflowId(selectedWorkflow.id);
    },
    [setSelectedWorkflowId, workflows]
  );

  const handleChangeStep = useCallback((item: Partial<StepData>) => {
    if (!selectedStep) return; 
    const newStep = { ...selectedStep, ...item };
    const newSteps = steps.map((step) =>
      step.position === selectedStep.position ? newStep : step
    ); 

    onProjectAdvancedChanged && onProjectAdvancedChanged({ steps: newSteps });
  }, [onProjectAdvancedChanged, selectedStep, steps]);

  return {
    isDisabledWorkflow,
    selectedStep,
    setSelectedStepIndex,
    onProjectAdvancedChanged,
    selectedWorkflowId,
    selectedStepIndex,
    workflowOptions,
    handleChangeWorkflow,
    onChangeStep: handleChangeStep
  };
};
