/*
 * File: base.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AxiosRequestConfig } from "axios";
import { RequestOptions } from "services/common/request-options";
import { AtLeast } from "types/common";
import { LabelAPI } from "../http/label-service.httpclient";

export class BaseAPI<T extends { id: string | number }> {
  private _PATH_: string;
  constructor(path: string) {
    this._PATH_ = path;
  }
  getPath() {
    return this._PATH_;
  }
  get(url: string, config?: AxiosRequestConfig) {
    return LabelAPI.get(url, config);
  }

  post(url: string, data = {}, config?: AxiosRequestConfig) {
    return LabelAPI.post(url, data, config);
  }

  delete(url: string, config?: AxiosRequestConfig) {
    return LabelAPI.delete(url, config);
  }

  upload(url: string, data: FormData, config?: AxiosRequestConfig) {
    return LabelAPI.upload(url, data, config);
  }

  put(url: string, data = {}, config?: AxiosRequestConfig) {
    return LabelAPI.put(url, data, config);
  }

  patch(url: string, data = {}, config?: AxiosRequestConfig) {
    return LabelAPI.patch(url, data, config);
  }

  getItems(options: RequestOptions) {
    const params = new URLSearchParams(options);
    return LabelAPI.get(`${this._PATH_}?${params.toString()}`);
  }

  createItem(item: Partial<T>) {
    const data = JSON.stringify(item);
    return LabelAPI.post(`${this._PATH_}?`, data);
  }

  getItem(itemId: number | string) {
    return LabelAPI.get(`${this._PATH_}/${itemId}`);
  }

  updateItem(item: T) {
    const data = JSON.stringify(item);
    return LabelAPI.put(`${this._PATH_}/${item.id}`, data);
  }

  partialUpdateItem(item: AtLeast<T, "id">) {
    const data = JSON.stringify(item);
    return LabelAPI.patch(`${this._PATH_}/${item.id}`, data);
  }

  partialUpdateWithPayload(itemId: string, payload: any) {
    const data = JSON.stringify(payload);
    return LabelAPI.patch(`${this._PATH_}/${itemId}`, data);
  }

  deleteItem(itemId: number | string) {
    return LabelAPI.delete(`${this._PATH_}/${itemId}`);
  }
}
