/*
 * File: vb-tabs.component.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 5:04:38 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { LinearProgress } from "@material-ui/core";
import classNames from "classnames";
import { useMemo } from "react";
export interface TabModel {
  id: string;
  name: string;
  isLoading?: boolean;
}

interface Props {
  tabs?: TabModel[];
  activeTab: string;
  onSelect?(tabId: string): void;
  activeClass?: string;
  inActiveClass?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const VBTabs = ({
  tabs = [],
  activeTab,
  onSelect,
  activeClass,
  inActiveClass,
  className = "",
  style,
}: Props) => {
  const activeTabModel = useMemo(() => {
    return tabs.find((tab) => tab.id === activeTab);
  }, [tabs, activeTab]);

  return (
    <div style={style ? { ...style } : {}}>
      {activeTabModel?.isLoading && (
        <div className="z-0 w-full h-1">
          <LinearProgress />
        </div>
      )}
      <div className={`flex items-center border-b ${className}`}>
        {tabs.map(({ id, name }) => {
          return (
            <VBTab
              key={id}
              id={id}
              name={name}
              active={id === activeTab}
              onSelect={onSelect}
              activeClass={activeClass}
              inActiveClass={inActiveClass}
            />
          );
        })}
      </div>
    </div>
  );
};

interface TabProps {
  id: string;
  name: string;
  active: boolean;
  activeClass?: string;
  inActiveClass?: string;
  onSelect?(id: string): void;
}

export const VBTab = ({
  id,
  name,
  active,
  onSelect,
  activeClass = "px-4 py-2 border-primary text-primary",
  inActiveClass = "px-4 py-2 border-gray-100 border-opacity-0",
}: TabProps) => {
  return (
    <div
      onClick={() => onSelect && onSelect(id)}
      className={classNames(
        `cursor-pointer border-b select-none whitespace-nowrap overflow-ellipsis overflow-hidden`,
        active ? activeClass : inActiveClass
      )}
    >
      {name}
    </div>
  );
};
