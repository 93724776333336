/*
 * File: app.context.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 18th June 2021 12:21:54 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { defaultAppState } from "./app.state";

export const AppContext = createContext(defaultAppState);

export const useAppContext = () => {
  return useContext(AppContext);
};
