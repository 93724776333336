/*
 * File: vb-button.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 8th July 2021 3:48:43 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { HTMLProps } from "react";

interface Props extends HTMLProps<HTMLDivElement> {
  children: JSX.Element | JSX.Element[];
}

export function VBButton({ children, className = "", ...rest }: Props) {
  return (
    <div
      className={`px-4 py-2 cursor-pointer hover:bg-gray-200 rounded flex items-center select-none ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
}
