/*
 * File: file-name-header.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 18th August 2021 11:52:08 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { VBDatagridInputFilter } from "components/common/vb-datagrid/vb-datagrid-input-filter.component";

export const FileNameHeader = () => {
  const { t } = useTranslation();
  const { filter, setFileName } = useDatasetContext();
  return (
    <div className="w-full leading-4 mr-2">
      <p className="h-6">{t("dataset:header.textFileName")}</p>
      <VBDatagridInputFilter
        onInput={(value) => setFileName && setFileName(value)}
        defaultValue={filter?.fileName || ""}
      />
    </div>
  );
};
