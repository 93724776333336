/*
 * File: annotation-menu.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 4th July 2022 2:57:13 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  AnnotationContextMenuAction,
  ContextMenuItemData,
} from "components/common/context-menu/context-menu-item.component";
import { ContextMenu } from "components/common/context-menu/context-menu-list.component";
import { TextAnnotation } from "domain/text-labeling/text-annotation";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { Point } from "utilities/math/point";

interface Props {
  position: Point;
  annotation: TextAnnotation;
  labelingMode: TextLabelingMode;
  onClose(): void;
  onSelect(action: ContextMenuItemData): void;
}

export const TextAnnotationMenu = ({
  labelingMode,
  position,
  onClose,
  onSelect,
}: Props) => {
  return (
    <div
      style={{ left: position.x, top: position.y }}
      className="absolute z-10"
    >
      <ContextMenu
        classname="py-2 overflow-y-auto bg-white divide-y rounded shadow max-h-56"
        onSelect={onSelect}
        onClose={onClose}
        data={[
          {
            label: "Edit",
            value: AnnotationContextMenuAction.EDIT,
            shortcut: "E",
            disabled:
              labelingMode === TextLabelingMode.CUSTOMER ||
              labelingMode === TextLabelingMode.ACCEPTANCE,
          },
          {
            label: "Issue",
            value: AnnotationContextMenuAction.ISSUE,
            shortcut: "I",
            disabled: labelingMode === TextLabelingMode.LABELER,
          },
          {
            label: "Delete",
            value: AnnotationContextMenuAction.DELETE,
            shortcut: "Del",
            variant: "danger",
            disabled: labelingMode !== TextLabelingMode.LABELER,
          },
        ]}
      />
    </div>
  );
};
