/*
 * File: use-ml-web.hook.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 17th February 2022 3:09:46 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { MLWeb } from "domain/web-builder";
import { useCallback, useMemo, useRef, useState } from "react";
import { RequestOptions } from "services/common/request-options";
import { ServingWebService } from "services/label-service/apis/serving-web.api";
import { RequestStatus } from "store/base/base.state";
import { AppError } from "utilities/errors/errors";
import { mlWebMapper } from "../mapper/ml-web.mapper";
import * as Sentry from "@sentry/react";

export const useMLWeb = () => {
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.IDLE);
  const isLoading = useMemo(() => requestStatus === RequestStatus.LOADING, [requestStatus]);
  const [error, setError] = useState<AppError>();
  const forceRequestRef = useRef(false);

  const [items, setItems] = useState<MLWeb[]>([]);
  const [totalItem, setTotalItem] = useState(0);

  const loadServingWebs = useCallback(async (
    options: RequestOptions,
    force = false,
  ) => {
    if (requestStatus === RequestStatus.LOADING) return;
    if (requestStatus !== RequestStatus.IDLE && !force && !forceRequestRef.current) return;

    try {
      setRequestStatus(RequestStatus.LOADING);
      const res = await ServingWebService.getServingWebs(options);
      setItems(res.data.map(mlWebMapper.toEntity));
      setTotalItem(res.headers["x-total-count"] || 0);
      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      Sentry.captureException(error);
      setError(new AppError("unknow", error.message));
      setRequestStatus(RequestStatus.FAILURE);
    } finally {
      forceRequestRef.current = false;
    }

  }, [requestStatus]);

  const setForceRequest = (v: boolean) => {
    forceRequestRef.current = v;
  }

  return {
    items,
    totalItem,
    isLoading,
    loadServingWebs,
    error,
    setForceRequest,
  };
};
