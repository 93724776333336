/*
 * File: issue-list.component.tsx
 * Project: design-system
 * File Created: Friday, 1st July 2022 11:34:38 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */
import { Issue } from "domain/common/issue";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { IssueListItem } from "./issue-list-item.component";
import { Option } from "domain/common";

interface Props {
  data: Issue[];
  jobOptions: Option[];
  mode: TextLabelingMode;
  selectedIssueId?: number;
  onSelectItem?(issue: Issue): void;
  onClickResolve?(issue: Issue): void;
}
export const IssueList = (props: Props) => {
  const {
    data,
    jobOptions,
    mode,
    selectedIssueId,
    onSelectItem,
    onClickResolve,
  } = props;
  return (
    <div className="bg-gray-800 divide-y divide-gray-900">
      {data.map((item) => {
        const job = jobOptions.find(
          (job) => job.value === item.jobId.toString()
        );
        const assignee = job?.label ?? "";
        return (
          <IssueListItem
            key={item.id}
            mode={mode}
            data={item}
            assignee={assignee}
            selected={item.id === selectedIssueId}
            onSelect={onSelectItem}
            onClickResolve={onClickResolve}
          />
        );
      })}
    </div>
  );
};
