/*
 * File: delete-files.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th June 2023 11:41:54 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { ChangeEvent, useState } from "react";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { StorageApi } from "data-access/impl/storage";
import { useAppDispatch } from "hooks/use-redux";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";

interface Props {
  open: boolean;
  datasetId: number;
  onClose: (reload?: boolean) => void;
}

export const DuplicateDatasourceModal = ({
  open,
  datasetId,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const [name, setName] = useState("");

  function handleNameChange(event: ChangeEvent<any>) {
    setName(event.target.value);
  }

  async function handleSubmit() {
    if (processing) return;
    try {
      setProcessing(true);
      await StorageApi.duplicateDataset(datasetId, name);
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      setProcessing(false);
      onClose(true);
    } catch (error) {
      dispatch(enqueueErrorNotification(t("common:textFailed")));
      setProcessing(false);
    }
  }
  return (
    <VBModal
      open={open}
      title="Duplicate datasource"
      onClose={() => onClose(false)}
      textSubmit={t("common:buttonSubmit")}
      onSubmit={handleSubmit}
      disableSubmit={processing || !name}
    >
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <div>New datasource name</div>
          <input
            autoFocus
            type="text"
            className="h-12 px-4 bg-white border rounded border-background-300 focus:border-primary"
            required
            onChange={handleNameChange}
            placeholder="Enter datasource name"
          />
        </div>
      </div>
    </VBModal>
  );
};
