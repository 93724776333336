/*
 * File: expand-button.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 27th August 2021 11:26:20 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import classNames from "classnames";
import { IconArrowDown } from "components/common/vb-icon.component";

interface ExpandButtonProps {
  expanded?: boolean;
}

export const ExpandButton = ({ expanded }: ExpandButtonProps) => {
  return (
    <span>
      {
        <IconArrowDown
          className={classNames("w-5 h-5 flex-none transform transition-all", {
            "-rotate-90": !expanded,
          })}
        />
      }
    </span>
  );
};
