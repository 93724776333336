import {
  CornerstoneState,
  INITIAL_CORNERSTONE_STATE,
} from "./cornerstone/cornerstone.state";
import {
  DicomEditorState,
  INITIAL_DICOM_EDITOR_STATE,
} from "./dicom-editor/dicom-editor.state";
import {
  defaultEditorSettingState,
  EditorSettingState,
} from "./editor-setting/editor-setting.state";
import {
  ImageAnnotationsState,
  INITIAL_IMAGE_ANNOTATIONS_STATE,
} from "./image-annotations/image-annotations.state";
import { ImageIouState, INITIAL_IMAGE_IOU_STATE } from "./image-iou/image-iou.state";
import {
  ImageLabelingState,
  INITIAL_IMAGE_LABELING_STATE,
} from "./image-labeling/image-labeling.state";
import {
  ImageReviewState,
  INITIAL_IMAGE_REVIEW_STATE,
} from "./image-review/image-review.state";
import {
  INITIAL_SMART_LABELING_STATE,
  SmartLabelingState,
} from "./smart-labeling/smart-labeling.state";

export interface ImageWorkspaceState {
  imageLabeling: ImageLabelingState;
  imageReview: ImageReviewState;
  imageSmartLabeling: SmartLabelingState;
  imageAnnotations: ImageAnnotationsState;
  imageEditorSetting: EditorSettingState;
  imageTools: DicomEditorState;
  imageViewport: CornerstoneState;
  imageIou: ImageIouState
}

export const defaultImageWorkspaceState: ImageWorkspaceState = {
  imageLabeling: INITIAL_IMAGE_LABELING_STATE,
  imageReview: INITIAL_IMAGE_REVIEW_STATE,
  imageSmartLabeling: INITIAL_SMART_LABELING_STATE,
  imageAnnotations: INITIAL_IMAGE_ANNOTATIONS_STATE,
  imageEditorSetting: defaultEditorSettingState,
  imageTools: INITIAL_DICOM_EDITOR_STATE,
  imageViewport: INITIAL_CORNERSTONE_STATE,
  imageIou: INITIAL_IMAGE_IOU_STATE
};
