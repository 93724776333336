/*
 * File: batch-labeling.page.tsx
 * Project: app-aiscaler-web
 * File Created: Sunday, 12th December 2021 7:24:03 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BatchLabelingNavigation } from "./components/navigation/batch-labeling-navigation.component";
import { ImageLabelingPage } from "../image-labeling/image-labeling.page";
import { NavigationExpander } from "./components/navigation-expander.component";
import { useBatchLabelingContext } from "./context/batch-labeling.context";
import { useTracking } from "hooks/use-tracking";
import { useThrottleFn } from "ahooks";
import { ActivityService } from "services/activity-service/activity.service";
import { ActivityStatus } from "services/activity-service/activity.dto";
import { useAppSelector } from "hooks/use-redux";
import { selectCurrentJob } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { selectSystemTrackingTime } from "store/common/app-setting/app-setting.selectors";
import { LabelingMode } from "domain/labeling/labeling-mode";

export const BatchLabelingPage = () => {
  const { mode, hideNavigation, toggleNavigation } = useBatchLabelingContext();
  const job = useAppSelector(selectCurrentJob);
  const trackingTime = useAppSelector(selectSystemTrackingTime);

  const { run: onActive } = useThrottleFn(
    async () => {
      if (!job) return;
      await ActivityService.createActivity({
        code: ActivityStatus.Active,
        jobId: job?.id,
        batchId: job?.batch?.id,
      });
    },
    { wait: trackingTime }
  );

  useTracking({ enabled: mode !== LabelingMode.CUSTOMER, onActive });

  return (
    <div className="relative flex w-screen h-screen overflow-hidden bg-blueGray-900">
      <BatchLabelingNavigation />
      <NavigationExpander
        showNavigation={!hideNavigation}
        onToggle={toggleNavigation}
      />
      <div className="relative flex-auto h-full">
        <ImageLabelingPage />
      </div>
    </div>
  );
};
