import { useMemo } from "react";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";

import TableBaseCell from "./table-base-cell.component";
import { TableSkeProps } from "./types";

const defaultMapper = [1];



export const TableSkeleton = <T extends object>({
  columns,
  skeCount = 1,
  skeHeight = 20,
}: TableSkeProps<T>) => {

  const mapper = useMemo(
    () => (skeCount > 1 ? new Array(skeCount).fill(1) : defaultMapper),
    [skeCount]
  );

  return (
    <>
      {mapper.map((_, index) => {
        return (
          <TableRow key={index}>
            {columns.map(({ key }) => (
              <TableBaseCell key={key}>
                <Skeleton height={skeHeight} width="100%" />
              </TableBaseCell>
            ))}
          </TableRow>
        );
      })}
    </>
  );
};
