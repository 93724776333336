/*
 * File: workflow.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 7th January 2022 10:56:30 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export interface Workflow {
  id: number;
  name: string;
  description: string;
  createdAt: Date;
  numberOfSteps: number;
  status: WorkflowStatus;
  createdBy: string;
}

export enum WorkflowStatus {
  FREE = "free",
  IN_USE = "in-use",
}
