/*
 * File: use-text-selection.hook.ts
 * Project: aiscaler-web
 * File Created: Wednesday, 27th October 2021 1:56:57 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useMemo, useState } from "react";
import { selectSelectedTokenIds } from "store/labeler/text-workspace/text-editor/text-editor.selector";
import { setSelectedTokenIds } from "store/labeler/text-workspace/text-workspace.slice";
import { TextViewerController } from "../components/text-viewer/text-viewer-handle";
import { BoundingBox } from "../models/text-viewer.models";
import * as Sentry from "@sentry/react";

export const useTextSelection = (
  controller: TextViewerController,
  element: HTMLElement | null,
  readonly = false
) => {
  const dispatch = useAppDispatch();
  const selectedTokenIds = useAppSelector(selectSelectedTokenIds);
  const [boundingBoxes, setBoundingBoxes] = useState<BoundingBox[]>([]);
  const leftPoint = useMemo(() => {
    if (boundingBoxes.length === 0) return null;
    const bbox = boundingBoxes[0];
    return {
      x: bbox.x,
      y: bbox.y,
    };
  }, [boundingBoxes]);
  const rightPoint = useMemo(() => {
    if (boundingBoxes.length === 0) return null;
    const bbox = boundingBoxes[boundingBoxes.length - 1];
    return {
      x: bbox.x + bbox.width,
      y: bbox.y,
    };
  }, [boundingBoxes]);
  useEffect(() => {
    if (readonly) return;
    let isMouseDown = false;
    const getTokenIdFromNode = (node: Node) => {
      try {
        if (node && node.nodeType === Node.TEXT_NODE) {
          const dataTokenIndex =
            node.parentElement?.getAttribute("data-token-index");
          if (dataTokenIndex) return dataTokenIndex;
        } else if (node && node.nodeType === Node.ELEMENT_NODE) {
          const element: Element = node as Element;
          const dataTokenIndex = element.getAttribute("data-token-index");
          return dataTokenIndex;
        }
        return null;
      } catch (error) {
        Sentry.captureException(error);
        return null;
      }
    };

    const getTokenIds = () => {
      const tokens = { startTokenId: null, endTokenId: null };
      const windowSelection = window.getSelection();
      if (windowSelection?.type !== "Range") return tokens;
      const anchorNode = windowSelection?.anchorNode;
      const focusNode = windowSelection?.focusNode;
      if (!anchorNode || !focusNode) return tokens;
      return {
        startTokenId: getTokenIdFromNode(anchorNode),
        endTokenId: getTokenIdFromNode(focusNode),
      };
    };
    const onMouseDown = () => {
      isMouseDown = true;
    };
    const onMouseMove = () => {
      if (!isMouseDown) return;
    };
    const onMouseUp = () => {
      if (!isMouseDown) return;
      const { startTokenId, endTokenId } = getTokenIds();
      if (startTokenId && endTokenId) {
        const startIndex = parseInt(startTokenId);
        const endIndex = parseInt(endTokenId);
        const tokenIds = controller.getTokenIds(
          Math.min(startIndex, endIndex),
          Math.max(startIndex, endIndex)
        );
        dispatch(setSelectedTokenIds(tokenIds));
      } else {
        dispatch(setSelectedTokenIds([]));
      }
    };

    if (element) {
      element.addEventListener("mousedown", onMouseDown);
      element.addEventListener("mousemove", onMouseMove);
      element.addEventListener("mouseup", onMouseUp);
    }
    return () => {
      if (element) {
        element.removeEventListener("mousedown", onMouseDown);
        element.removeEventListener("mouseup", onMouseUp);
      }
    };
  }, [element, controller, dispatch, readonly]);

  useEffect(() => {
    if (readonly) return;
    setBoundingBoxes(controller.getSelectedBoundingBoxes(selectedTokenIds));
  }, [controller, selectedTokenIds, readonly]);

  return {
    boundingBoxes: boundingBoxes,
    selectedTokenIds,
    leftPoint,
    rightPoint,
  };
};
