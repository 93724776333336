import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RelationAnnotationData } from "domain/image-labeling/relation-annotation";
import { ImageWorkspaceState } from "../../image-workspace.state";

const THUNK_NAME = "image-annotations/imageRelationAnnotationUpdatedAsync";

interface ImageAnotationPayload<T> {
  jobId: number;
  data: T;
}

export const imageRelationAnnotationUpdatedAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: ImageAnotationPayload<RelationAnnotationData>) => {
    return payload;
  }
);

export const imageRelationAnnotationUpdatedBuilder = (
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) => {
  return builder.addCase(
    imageRelationAnnotationUpdatedAsync.fulfilled,
    ({ imageAnnotations }, action) => {
      imageAnnotations.relationAnnotations =
        imageAnnotations.relationAnnotations.map((relationAnno) => {
          if (relationAnno.uuid === action.payload.data.uuid) {
            return {
              ...relationAnno,
              annotationData: {
                ...relationAnno.annotationData,
                ...action.payload.data,
              },
            };
          }
          return relationAnno;
        });
    }
  );
};
