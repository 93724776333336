import { RootState } from "store";
import { WorkingStatus } from "./complex-jobs.state";

export const selectComplexJobsCurrentJobId = (state: RootState) => {
  return state.complexJobs.currentJobId;
};

export const selectComplexJobsCurrentBatchJob = (state: RootState) => {
  return state.complexJobs.batchJobs.find(
    (j) => j.id === state.complexJobs.currentJobId
  );
};

export const selectComplexJobsBatchJobs = (state: RootState) => {
  return state.complexJobs.batchJobs;
};

export const selectComplexJobsError = (state: RootState) => {
  return state.complexJobs.error;
};

export const selectComplexJobsWorkingStatus = (state: RootState) => {
  return state.complexJobs.jobWorkingStatus;
};

export const selectComplexJobIdsByStatus =
  (status: WorkingStatus) => (state: RootState) => {
    const jobWorkingStatus = selectComplexJobsWorkingStatus(state);
    const jobs = selectComplexJobsBatchJobs(state);

    return jobs
      .filter((job) => {
        return jobWorkingStatus[job.id] === status;
      })
      .map((job) => job.id);
  };

export const selectComplexJobsProject = (state: RootState) => {
  return state.complexJobs.project;
};

export const selectComplexJobsAutoSaveInSecond = (state: RootState) => {
  return (
    state.complexJobs.project?.autoSaveInSecond
  );
};
