import { Checkbox, makeStyles, Slider } from "@material-ui/core";
import { useDebounceFn } from "ahooks";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useState } from "react";
import { selectMinIouScore } from "store/labeler/image-workspace/image-iou/image-iou.selectors";
import { imageIouAnnotationsLoadedAsync } from "store/labeler/image-workspace/image-iou/image-iou.thunk";
import { setImageIouActiveScore } from "store/labeler/image-workspace/image-workspace.slice";

interface ActionPanelIouProps {
  jobId: number;
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      color: "#F79D28",
      "&.Mui-disabled": {
        cursor: "not-allow",
      },
    },
  };
});

export const ActionPanelIou = ({ jobId }: ActionPanelIouProps) => {
  const minIouScore = useAppSelector(selectMinIouScore);
  const dispatch = useAppDispatch();

  const [score, setScore] = useState(0.4);
  const [checked, setChecked] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    dispatch(imageIouAnnotationsLoadedAsync({ jobId }));
  }, [dispatch, jobId]);

  useEffect(() => {
    const score = minIouScore;
    setScore(score);
  }, [minIouScore, dispatch]);

  const { run: debouncedSlider } = useDebounceFn(
    (score: number) => {
      dispatch(setImageIouActiveScore(score));
    },
    {
      wait: 300,
    }
  );

  const handleChangeIoU = (event: any, newValue: number | number[]) => {
    setScore(newValue as number);
    debouncedSlider(newValue as number);
  };

  const handleChangeCheckbox = (checked: boolean) => {
    setChecked(checked);
    dispatch(setImageIouActiveScore(checked ? score : -1));
  };

  return (
    <div className="flex justify-start items-center gap-4">
      <div className="flex justify-start items-center gap-1">
        <Checkbox
          color="primary"
          size="small"
          checked={checked}
          onChange={(_, selected) => handleChangeCheckbox(selected)}
          style={{
            color: checked ? "#F79D28" : "white",
            padding: 0,
          }}
        />
        <div className=" text-white text-sm font-semibold">IoU</div>
      </div>

      <Slider
        track={false}
        aria-labelledby="track-false-slider"
        value={score}
        min={0}
        step={0.01}
        max={1}
        onChange={handleChangeIoU}
        disabled={!checked}
        classes={classes}
      />
      <span className="text-sm">{score}</span>
    </div>
  );
};
