/*
 * File: notification.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 15th September 2021 2:56:10 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useNotificationToken } from "hooks/notification/use-notification-token.hook";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { ReactNode, useEffect, useRef, useState } from "react";
import {
  getFirebaseMessagingToken,
  isFirebaseMessagingSupported,
  removeFirebaseMessagingToken,
} from "services/firebase/firebase-app";
import { FIREBASE_CONFIG } from "services/firebase/firebase.config";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { enqueueSuccessNotification } from "store/common/notification/notification.actions";
import { loadUserNotificationUnreadCountAsync } from "store/common/user-notification/user-notification.thunk";
import { selectCurrentWorkspaceId } from "store/common/user-workspace/user-workspace.selectors";
import { Logger } from "utilities/logger";
import { NotificationState } from "./notificaiton.state";
import { NotificationContext } from "./notification.context";
import * as Sentry from "@sentry/react";

interface NotificationProviderProps {
  children: ReactNode;
}
export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
  const [registrationToken, setRegistrationToken] = useState("");
  const isGettingToken = useRef(false);
  useNotificationToken(registrationToken);

  function registerBackgroundServiceWorker() {
    const firebaseConfig = new URLSearchParams({ ...FIREBASE_CONFIG });
    const swUrl = `firebase-messaging-sw.js?${firebaseConfig}`;
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register(swUrl).catch((error) => {
        Logger.log("Registration error", error);
      });
    }
  }

  useEffect(() => {
    const handleMessage = (payload: any) => {
      if (payload && payload.data && payload.data.notification) {
        const notification = payload.data.notification;
        const { body } = notification;
        dispatch(enqueueSuccessNotification(body));
        dispatch(loadUserNotificationUnreadCountAsync());
        Logger.log(notification);
      }
    };
    registerBackgroundServiceWorker();

    navigator.serviceWorker.addEventListener("message", handleMessage);

    return () => {
      navigator.serviceWorker.removeEventListener("message", handleMessage);
    };
  }, [dispatch]);

  async function getToken() {
    if (isGettingToken.current) return;
    try {
      isGettingToken.current = true;
      const token = await getFirebaseMessagingToken();
      setRegistrationToken(token);
      isGettingToken.current = false;
    } catch (error) {
      Sentry.captureException(error);
      Logger.log("OnFirebaseMessagingToken Error", error);
      setRegistrationToken("");
      isGettingToken.current = false;
    }
  }

  async function removeToken() {
    try {
      await removeFirebaseMessagingToken();
      setRegistrationToken("");
    } catch (error) {
      Sentry.captureException(error);
      Logger.log(error);
    }
  }

  useEffect(() => {
    if (!isFirebaseMessagingSupported) return;
    if (currentUser && currentWorkspaceId) {
      getToken();
      dispatch(loadUserNotificationUnreadCountAsync());
    } else {
      removeToken();
    }
  }, [currentUser, currentWorkspaceId, dispatch]);

  const state: NotificationState = {
    registrationToken,
  };

  return (
    <NotificationContext.Provider value={state}>
      {children}
    </NotificationContext.Provider>
  );
};
