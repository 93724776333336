/*
 * File: use-datasource-filter.hook.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 6th December 2021 2:13:23 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useQuery } from "hooks/use-query";
import { useQueryParams } from "hooks/use-query-params.hook";
import { SearchParams } from "models/common/search-params";

export interface DatasourceFilter {
  name?: string;
  createdDate?: string;
  createdBy?: string;
  category?: string;
  type?: string;
  page?: string;
  size?: string;
}

export const useDatasourceFilters = () => {
  const { navigateByQuery, navigateByQueries, navigateByParams } = useQuery();
  const filter = useQueryParams({
    name: "",
    createdDate: "",
    createdBy: "",
    category: "",
    page: "1",
    size: "12",
  });
  const setFilter = (filter: DatasourceFilter) => {
    navigateByParams({ ...filter });
  };
  const setName = (name: string) => {
    navigateByQuery(SearchParams.NAME, name);
  };
  const setOwner = (owner: string) => {
    navigateByQuery(SearchParams.CREATED_BY, owner);
  };
  const setCreatedDate = (name: string) => {
    navigateByQuery(SearchParams.CREATED_DATE, name);
  };
  const setCategory = (name: string) => {
    navigateByQuery(SearchParams.CATEGORY, name);
  };
  const setPage = (page: number) => {
    navigateByQuery(SearchParams.PAGE, page.toString());
  };

  const setPageSize = (pageSize: number) => {
    navigateByQueries([
      { query: SearchParams.PAGE, value: "1" },
      { query: SearchParams.SIZE, value: pageSize.toString() },
    ]);
  };

  return {
    filter,
    setFilter,
    setName,
    setOwner,
    setCreatedDate,
    setCategory,
    setPageSize,
    setPage,
  };
};
