import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import { VBTabs } from "components/common/vb-tabs/vb-tabs.component";
import { Annotation } from "domain/image-labeling";
import { SearchParams } from "models/common/search-params";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { RunInfoImageItems } from "../../experiment-detail/run-info-image-items.component";
import useExportedDatasetAnnotations from "./use-exported-dataset-annotations.hook";
import useExportedDataset from "./use-exported-dataset.hook";

export const ExportedDatasetDetailPage = () => {
  const { datasetId } = useParams<{ datasetId: string }>();
  const { loading, items, totalItem, filter, setFilterParam, setFilterParams } =
    useExportedDataset(parseInt(datasetId));
  const { labels, annotations } = useExportedDatasetAnnotations();
  const annotationItems = useMemo(() => {
    return items.map((item) => {
      let annos: Annotation[] = [];
      if (annotations.hasOwnProperty(item.mediaId)) {
        annos = annotations[item.mediaId];
      }
      return {
        ...item,
        taskData: {
          ...item.taskData,
          annotations: annos.map((anno) => {
            return {
              id: anno.id,
              labelId: anno.labelId,
              label: anno.label,
              type: anno.annotationData[0].type,
              points: anno.annotationData[0].points,
              color: anno.color,
              source: "GroundTruth",
            };
          }),
        },
      };
    });
  }, [items, annotations]);
  const tabs = [
    { id: "-1", name: "All files" },
    { id: "1", name: "Train" },
    { id: "2", name: "Test" },
    { id: "3", name: "Validation" },
  ];
  const [activeTab, setActiveTab] = useState("-1");

  function selectTab(tab: string) {
    const scope = parseInt(tab);
    setFilterParams([
      {
        query: SearchParams.SCOPE,
        value: scope <= 0 ? "" : tab,
      },
      { query: SearchParams.PAGE, value: "1" },
    ]);
    setActiveTab(tab);
  }

  return (
    <div className="relative flex flex-col w-full h-full gap-4 bg-white rounded shadow">
      <div className="flex-none mt-2">
        <VBTabs tabs={tabs} activeTab={activeTab} onSelect={selectTab} />
      </div>
      <div className="flex-auto h-full px-4 overflow-y-auto">
        {loading && <Loading />}
        {!loading && (
          <RunInfoImageItems
            hideFilter
            labels={labels}
            items={annotationItems}
          />
        )}
      </div>
      <div className="flex-none w-full px-4 pb-4">
        <GridPagination
          page={filter.page}
          pageSize={filter.size}
          pageSizeOptions={[10, 15, 20, 25, 50, 75, 100]}
          onPageSizeChange={(v) => {
            setFilterParams([
              {
                query: SearchParams.SIZE,
                value: v.toString(),
              },
              {
                query: SearchParams.PAGE,
                value: "1",
              },
            ]);
          }}
          onPageChange={(v) => {
            setFilterParam(SearchParams.PAGE, v);
          }}
          totalItems={totalItem}
        />
      </div>
    </div>
  );
};

function Loading() {
  return <div className="p-10 text-center">Loading...</div>;
}
