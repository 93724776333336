/*
 * File: number-formatter.utils.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 28th December 2021 1:33:43 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

const formatter = Intl.NumberFormat(navigator.language);

export function formatNumber(number: number) {
  return formatter.format(number);
}

export function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
