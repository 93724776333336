import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { collectionUtils } from "domain/common";
import {
  LabelerAnnotationService,
  LabelerJobServiceV3,
} from "services/label-service";
import { AnnotationVoteStatus } from "services/label-service/dtos/annotation.dto";
import { RootState } from "store";
import { BatchLabelingState, JobStatus } from "../batch-labeling.state";

const THUNK_NAME = "batch-labeling/imageVoteAnnotationAsync";

export const imageVoteAnnotationAsync = createAsyncThunk(
  THUNK_NAME,
  async (
    {
      jobIds,
      finish = false,
    }: {
      jobIds: number[];
      finish?: boolean;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const promises: Promise<AxiosResponse<any>>[] = [];
    const annotations = state.imageWorkspace.imageAnnotations.annotations;
    jobIds.forEach((jobId) => {
      const annotationVotes = annotations
        .filter((annotation) => annotation.vote)
        .map((annotation) => {
          if (!annotation.vote)
            return { annotationId: annotation.id, status: "" };
          const status =
            annotation.vote > 0
              ? AnnotationVoteStatus.UP
              : annotation.vote < 0
              ? AnnotationVoteStatus.DOWN
              : "";

          return {
            annotationId: annotation.id,
            status,
          };
        });
      const request = LabelerAnnotationService.voteAnnotations(
        jobId,
        annotationVotes
      );
      promises.push(request);
    });

    await Promise.all(promises);
    for (const jobId of jobIds) {
      await LabelerJobServiceV3.finishJob(jobId);
    }
    return { jobIds, annotations };
  }
);

export const imageVoteAnnotationReducerBuilder = (
  builder: ActionReducerMapBuilder<BatchLabelingState>
) => {
  return builder.addCase(
    imageVoteAnnotationAsync.fulfilled,
    (state, action) => {
      const { jobIds, annotations } = action.payload;
      const { imageLabelingJobs } = state;

      jobIds.forEach((jobId) => {
        state.jobStatuses[jobId] = JobStatus.SAVED;

        const labelingJob = collectionUtils.getOne(imageLabelingJobs, jobId);
        if (labelingJob) {
          const newPreviousJobData = labelingJob.data?.previousJobData?.map(
            (jobData) => ({
              ...jobData,
              annotations: annotations.filter(
                (annotation) => annotation.annotator === jobData.annotator
              ),
            })
          );
          labelingJob.data = {
            ...labelingJob.data,
            previousJobData: newPreviousJobData,
          };
        }
      });
    }
  );
};
