import { VBNumberTextInputComponent } from "components/design-system/number-text-input/number-text-input.component";
import { CurrencySelectOption } from "store/common/payment/payment.state";

export interface PriceByJobStepRoundUIModel {
  index: number;
  totalJob: number;
  pricePerJob: number;
}

export interface PriceByJobStepUIModel {
  id: number;
  stepTitle: string;
  rounds: PriceByJobStepRoundUIModel[];
  required?: boolean;
}

export interface PriceByJobCompbonentProps {
  items: PriceByJobStepUIModel[];
  selectedCurrencyOption: CurrencySelectOption;
  currencyOptions: CurrencySelectOption[];
  onCurrencyOptionChanged?: (v: CurrencySelectOption) => void;
  onItemsChanged?: (newItems: PriceByJobStepUIModel[]) => void;
  selectTarget?: HTMLElement;
  disabled?: boolean;
}

export const PriceByJobComponent = ({
  items,
  selectedCurrencyOption,
  currencyOptions,
  onCurrencyOptionChanged,
  onItemsChanged,
  selectTarget,
  disabled = false,
}: PriceByJobCompbonentProps) => {
  const handlePricePerJobChanged = (
    itemId: number,
    roundIndex: number,
    v: number
  ) => {
    if (onItemsChanged) {
      const newItems = items.map((item) => {
        if (item.id === itemId) {
          const newRounds = item.rounds.map((round) => {
            if (round.index === roundIndex) {
              return {
                ...round,
                pricePerJob: v,
              };
            }
            return round;
          });
          return {
            ...item,
            rounds: newRounds,
          };
        }
        return item;
      });
      onItemsChanged(newItems);
    }
  };

  return (
    <div className="flex flex-col max-w-4xl gap-4">
      {items.map((item) => {
        return (
          <div
            className="flex gap-4 p-4 rounded bg-background-50"
            key={item.id}
          >
            <div className="flex-auto">
              <VBNumberTextInputComponent
                label="Step"
                type="text"
                defaultValue={item.stepTitle}
                disabled
              />
            </div>
            <div className="flex flex-col flex-none gap-4">
              {item.rounds.map((round) => {
                return (
                  <div className="flex gap-4" key={round.index}>
                    <div className="flex-none w-20">
                      <VBNumberTextInputComponent
                        label="Round"
                        defaultValue={round.index}
                        disabled
                      />
                    </div>
                    <div className="flex-none w-20">
                      <VBNumberTextInputComponent
                        label="Total job"
                        defaultValue={round.totalJob}
                        disabled
                      />
                    </div>
                    <div className="flex-none w-52">
                      <VBNumberTextInputComponent
                        label="Price per job"
                        defaultValue={round.pricePerJob}
                        selectedUnit={selectedCurrencyOption}
                        unitOptions={currencyOptions}
                        onSelectedUnitChanged={onCurrencyOptionChanged}
                        onValueChanged={(v) =>
                          handlePricePerJobChanged(
                            item.id,
                            round.index,
                            v as number
                          )
                        }
                        min={0}
                        selectTarget={selectTarget}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
