/*
 * File: create-batch.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 8th July 2021 3:48:43 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from 'react-i18next';
import { VBCreateButton } from "components/common/vb-create-button.component";
import { CreateBatchModal } from "./create-batch/create-batch.modal";
import { useCreateBatchContext } from "./create-batch.context";

export const CreateBatch = () => {
  const { t } = useTranslation();
  const { showCreateBatch } = useCreateBatchContext();

  return (
    <>
      <VBCreateButton
        text={t('project:batch.buttonCreate')}
        onClick={showCreateBatch}
        className="ml-4"
      />
      <CreateBatchModal />
    </>
  );
};
