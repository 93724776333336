/*
 * File: context-menu-list.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 1st July 2022 4:20:13 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useKeyPress } from "ahooks";
import { useRef } from "react";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import {
  ContextMenuItem,
  ContextMenuItemData,
} from "./context-menu-item.component";

export interface Props {
  classname?: string;
  data: ContextMenuItemData[];
  onSelect?(data: ContextMenuItemData): void;
  onClose?(): void;
}

export const ContextMenu = ({
  classname = "py-2 overflow-y-auto max-h-56",
  data,
  onSelect,
  onClose,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useKeyPress(KeyboardKey.Escape, onClose);
  return (
    <div className={classname} ref={containerRef}>
      {data?.map((option) => {
        return (
          <ContextMenuItem
            key={option.value}
            data={option}
            onSelect={onSelect}
          />
        );
      })}
    </div>
  );
};
