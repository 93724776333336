/*
 * File: pipeline.state.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 6th September 2021 2:14:22 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Pipeline } from "domain/image-labeling/pipeline";
import { RequestStatus } from "store/base/base.state";

export interface PipelineState {
  entities: Record<string, Pipeline>;
  ids: string[];
  status: RequestStatus;
  error: string | null;
}

export const INITIAL_PIPELINE_STATE = {
  entities: {},
  ids: [],
  status: RequestStatus.IDLE,
  error: null,
};
