/*
 * File: label-distribution.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 2:37:02 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "store";

export const selectProjectLabelDistributionRequestStatus = (state: RootState) => {
  return state.projectData.labelDistribution.status;
};

export const selectProjectLabelDistributionRequestError = (state: RootState) => {
  return state.projectData.labelDistribution.error;
};

export const selectProjectStepDistributionData =
  (projectId: number, step: number) => (state: RootState) => {
    const data = state.projectData.labelDistribution.entities[projectId];
    if (data && data.steps.hasOwnProperty(step)) {
      return data.steps[step];
    }
    return null;
  };
