/*
 * File: pipeline.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 16th April 2022 4:52:48 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Pipeline } from "domain/image-labeling/pipeline";
import { PipelineDTO } from "../dtos";

export const pipelineMapper = {
  toEntity: (data: PipelineDTO): Pipeline => {
    return {
      id: data.id,
      name: data.name,
      version: data.version,
      inputs: data.pipeline_inputs.map((input: any) => {
        return {
          name: input.name,
          inputType: input.input_type,
        };
      }),
    };
  },
};
