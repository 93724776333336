import { MLModel } from "domain/web-builder";
import {
  AIModelDTO,
  AIModelVersionDTO,
} from "services/label-service/dtos/ai-model.dto";
/*
 * File: ai-model.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 23rd February 2022 2:21:53 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export const aiModelsMapper = (
  aiModelDtos: AIModelDTO[],
  aiModelVersionDto: AIModelVersionDTO[]
): MLModel[] => {
  const versions = aiModelVersionDto.map((aiModelVersion) => {
    return {
      version: {
        id: aiModelVersion.id,
        name: aiModelVersion.version?.toString(),
      },
      modelId: aiModelVersion.modelId,
    };
  });
  return aiModelDtos.map((aiModel) => {
    var thisModelVersions = versions
      .filter((version) => version.modelId === aiModel.id)
      .map((version) => version.version);
    thisModelVersions = thisModelVersions.sort((a, b) => b.id - a.id);
    var latestVersion = thisModelVersions[0];
    return {
      id: aiModel.id,
      name: aiModel.name,
      description: aiModel.description,
      versions: thisModelVersions,
      latestVersion: latestVersion,
      createdDate: aiModel.createdDate,
    };
  });
};
