import axios, { AxiosInstance, AxiosResponse } from "axios";
import { ENV_CONFIG } from "configs/env.config";
import { setupLabelInterceptor } from "services/common/label-interceptor";
import { SupportColosResponseDTO } from "./setting.dto";

let _axiosInstance: AxiosInstance | undefined = undefined;

function getAxiosInstance() {
  if (_axiosInstance) return _axiosInstance;
  _axiosInstance = setupLabelInterceptor(
    axios.create({
      baseURL: ENV_CONFIG.SETTING_SERVICE_URL,
    })
  );
  return _axiosInstance;
}

const getAllSupportColor = (): Promise<
  AxiosResponse<SupportColosResponseDTO>
> => {
  return getAxiosInstance().get(`/api/v1/observation/color`);
};

const getAppSetting = (version: string = "1.0.0") => {
  const url = `api/v1/app/config?version=${version}`;
  return getAxiosInstance().get(url);
};

export const SettingService = {
  getAllSupportColor,
  getAppSetting,
};
