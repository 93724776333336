/*
 * File: file-info-section.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th June 2023 3:55:32 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { IconButton } from "@material-ui/core";
import { Fragment, ReactNode, useState } from "react";
import { VscChevronDown, VscChevronRight } from "react-icons/vsc";

interface Props {
  title: string;
  children?: ReactNode;
  defaultExpanded?: boolean;
  actions?: ReactNode;
}

export function FileInfoSection({
  title,
  defaultExpanded = true,
  children,
  actions,
}: Props) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <Fragment>
      <div className="flex items-center w-full px-4 py-4">
        <span className="flex-auto font-semibold text-left truncate text-warmGray-900">
          {title}
        </span>
        {actions}
        <IconButton
          size="small"
          className="flex-none"
          onClick={() => setExpanded(!expanded)}
        >
          {!expanded && <VscChevronRight size={18} />}
          {expanded && <VscChevronDown size={18} />}
        </IconButton>
      </div>
      {expanded && children}
    </Fragment>
  );
}
