/*
 * File: project-progress-chart.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 13th September 2021 2:02:28 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useMemo } from "react";
import { Cell, Legend, Pie, PieChart } from "recharts";
import { BatchStatus } from "services/label-service/dtos";

interface Props {
  statistic: Record<BatchStatus, number>;
}

export const ProjectProgressChart = ({ statistic }: Props) => {
  const hasData = Object.keys(statistic).length > 0;
  const data = useMemo(() => {
    function getValue(status: BatchStatus, defaultValue = 0) {
      if (statistic.hasOwnProperty(status)) return statistic[status];
      return defaultValue;
    }

    function getValues(statuses: BatchStatus[]) {
      return statuses.reduce((total, status) => total + getValue(status), 0);
    }

    const blockedOrCanceled = getValues([BatchStatus.LOCKED]);
    const done = getValues([BatchStatus.COMPLETED, BatchStatus.DELIVERED]);
    const left = getValues([BatchStatus.INITIAL, BatchStatus.WORKING]);
    const onHold = getValues([BatchStatus.ON_HOLD]);

    return [
      { name: "Blocked/Canceled", value: blockedOrCanceled, color: "#FF0000" },
      { name: "Done", value: done, color: "#34D399" },
      { name: "On Hold", value: onHold, color: "#fbbf24" },
      { name: "Left", value: left, color: "#8B5CF6" },
    ];
  }, [statistic]);

  return (
    <PieChart width={450} height={300}>
      <Pie
        data={data}
        dataKey="value"
        cx={200}
        cy={150}
        label={({ value, name }) => (value ? `${name}: ${value}` : "")}
        startAngle={90}
        endAngle={-270}
        innerRadius={0}
        outerRadius={60}
        paddingAngle={1}
        labelLine={false}
        isAnimationActive={false}
      >
        {data.map((item, index) => (
          <Cell key={`slice-${index}`} fill={item.color} />
        ))}

        {!hasData && (
          <text x={200} y={150}>
            No Data To Display
          </text>
        )}
      </Pie>
      <Legend />
    </PieChart>
  );
};
