/*
 * File: use-metadata-key-options.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th June 2023 10:14:38 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { StorageApi } from "data-access/impl/storage";
import { Filter, Option } from "domain/common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { normalizeVietnamese } from "utilities/string/string.normalize";

const QUERY_KEY = "METADATA_KEY_OPTIONS";

export function useMetadataKeyOptions(filter: Filter, defaultValue = "") {
  const client = useQueryClient();

  function requestData() {
    return StorageApi.getAllMetadataKeys(filter);
  }

  const { data } = useQuery([QUERY_KEY, filter], requestData, {
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: 0,
  });

  const options = useMemo<Option[]>(() => {
    if (!data?.data) return [];
    return data.data.map((item) => {
      return {
        label: item.name ?? "",
        value: item.id.toString(),
        metadata: item,
      };
    });
  }, [data]);

  const [value, setValue] = useState(defaultValue);

  const option = useMemo(() => {
    return options.find((item) => item.value === value);
  }, [options, value]);

  function onChange(newOption?: any) {
    if (newOption && newOption.hasOwnProperty("value")) {
      setValue(newOption["value"]);
    } else {
      setValue("");
    }
  }

  function reloadData() {
    client.refetchQueries([QUERY_KEY]);
  }

  const createNewKey = useCallback(
    async (value: string) => {
      try {
        const response = await StorageApi.createMetadataKeys([
          { id: -1, code: normalizeVietnamese(value), name: value },
        ]);

        const items = response.data;
        if (items.length > 0) {
          setValue(items[0].id.toString());
        }

        client.invalidateQueries([QUERY_KEY]);
      } catch (error) {}
    },
    [client]
  );
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return {
    options,
    value,
    setValue,
    option,
    onChange,
    reloadData,
    createNewKey,
  };
}

export default useMetadataKeyOptions;
