/*
 * File: text-conflict-menu.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 11th August 2022 1:40:51 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { IconMenu } from "components/common/vb-icon.component";
import { useState, useRef } from "react";
import { useClickAway } from "ahooks";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import {
  setShowPreviousJob,
  textConflictAcceptAgreeAnnotations,
  textConflictAcceptAll,
  textConflictAcceptAllEntities,
  textConflictAcceptAllRelations,
  textConflictAcceptConflictAnnotations,
} from "store/labeler/text-workspace/text-workspace.slice";
import { selectShowPreviousJob } from "store/labeler/text-workspace/text-editor/text-editor.selector";
interface Props {
  annotators: string[];
}
export const TextConflictMenu = ({ annotators }: Props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const popoverContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const showPreviousJobs = useAppSelector(selectShowPreviousJob);
  function toggleOpen() {
    setOpen(!open);
  }
  function acceptAll() {
    dispatch(textConflictAcceptAll());
  }
  function acceptAllEntities() {
    dispatch(textConflictAcceptAllEntities());
  }
  function acceptAllRelations() {
    dispatch(textConflictAcceptAllRelations());
  }
  function acceptAgreeAnnotations() {
    dispatch(textConflictAcceptAgreeAnnotations());
  }
  function acceptConflictAnnotations() {
    dispatch(textConflictAcceptConflictAnnotations());
  }
  function hideTextConflict() {
    dispatch(setShowPreviousJob(!showPreviousJobs));
  }

  let options = [
    { text: "Accept all", onClick: acceptAll },
    { text: "Accept all entities", onClick: acceptAllEntities },
    { text: "Accept all relations", onClick: acceptAllRelations },
    { text: "Accept agree annotations", onClick: acceptAgreeAnnotations },
    {
      text: "Accept conflict annotations",
      onClick: acceptConflictAnnotations,
    },
    { text: "Close", onClick: hideTextConflict },
  ];

  if (annotators.length === 1) {
    options = [
      { text: "Accept all", onClick: acceptAll },
      { text: "Accept all entities", onClick: acceptAllEntities },
      { text: "Accept all relations", onClick: acceptAllRelations },
      { text: "Close", onClick: hideTextConflict },
    ];
  } else if (annotators.length === 0) {
    options = [];
  }

  useClickAway(() => setOpen(false), [containerRef]);

  return (
    <div>
      <div className="relative z-50">
        <div ref={containerRef}>
          <button onClick={toggleOpen}>
            <IconMenu />
          </button>
        </div>

        {open && (
          <div
            className={`absolute bg-white rounded shadow-lg top-full animate-fade-in-up right-0`}
            ref={popoverContainerRef}
          >
            <div className="py-2">
              {options.length === 0 && (
                <div className="px-6 py-2 text-center whitespace-nowrap text-background-500">
                  No options
                </div>
              )}
              {options.map((option) => {
                return (
                  <button
                    key={option.text}
                    className="w-full px-4 py-2 text-left hover:bg-background-100 whitespace-nowrap"
                    onClick={option.onClick}
                  >
                    {option.text}
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
