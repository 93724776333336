/*
 * File: save-text-job.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 12th July 2022 5:14:51 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { RootState } from "store";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { LabelerAnnotationServiceV3 } from "services/label-service";
import { selectTextAnnotationsForSaving } from "../text-labeling-batch.selectors";
import {
  LabelingBatchState,
  WorkingStatus,
} from "../text-labeling-batch.state";

const THUNK_NAME = "textLabelingBatch/saveTextJobAsync";

interface SaveTextJobPayload {
  jobId: number;
}

export const saveTextJobAsync = createAsyncThunk(
  THUNK_NAME,
  async ({ jobId }: SaveTextJobPayload, { getState }) => {
    const state = getState() as RootState;
    // const data = selectTextAnnotationsForSaving(jobId)(state);
    // const payload = [data as any];
    // await TaskObservationServiceV3.saveTextObservations(payload);
    const data = selectTextAnnotationsForSaving(jobId)(state);
    const payload = data as any;
    await LabelerAnnotationServiceV3.saveTextAnnotations(jobId, payload);
  }
);

export function saveTextJobBuilder(
  builder: ActionReducerMapBuilder<LabelingBatchState>
) {
  return builder.addCase(saveTextJobAsync.fulfilled, (state, action) => {
    const jobId = action.meta.arg.jobId;
    state.jobWorkingStatus[jobId] = WorkingStatus.SAVED;
  });
}
