/*
 * File: layout-wrapper.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 21st February 2022 11:04:39 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

interface Props {
  header: JSX.Element;
  panel: JSX.Element;
  children: JSX.Element;
}

export const LayoutWrapper = ({ header, panel, children }: Props) => {
  return (
    <div className="flex flex-col w-screen h-screen gap-4 p-6 overflow-hidden">
      {header}
      <div className="flex flex-row flex-auto h-full overflow-hidden border border-background-300">
        {panel}
        {children}
      </div>
    </div>
  );
};
