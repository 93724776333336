import { useCallback, useEffect, useRef, useState } from "react";
import { useLocalStorageState } from "ahooks";
import { StorageKeys } from "./storage/storage-keys";

enum TrackingEventType {
  Click = "click",
  KeyDown = "keydown",
  MouseMove = "mousemove",
  MouseWheel = "mousewheel",
  MouseDown = "mousedown",
  Focus = "focus",
}

interface UseTrackingProps {
  events?: TrackingEventType[] | string[];
  timeout?: number;
  enabled?: boolean;
  onIdle?: () => void;
  onActive?: () => void;
}

const SECOND_MINUTES = 1000 * 2 * 60;
const getCurrentTime = () => new Date().getTime();

export const useTracking = ({
  events = Object.values(TrackingEventType),
  timeout = SECOND_MINUTES,
  enabled = false,
  onActive,
  onIdle,
}: UseTrackingProps) => {
  const idleTimeoutRef  = useRef<NodeJS.Timeout | null>(null);
  const activityEventRef = useRef<NodeJS.Timeout | null>(null);

  const [timeoutScheduled, setTimeoutScheduled] = useState(false);
  const [lastActivity, setLastActivity] = useLocalStorageState<number>(
    StorageKeys.LAST_ACTIVITY_TIME
  );

  const idleHandler = useCallback(
    (timeout: number) => {
      idleTimeoutRef.current && clearTimeout(idleTimeoutRef.current);

      idleTimeoutRef.current = setTimeout(() => {
        if (!lastActivity) return;

        const currentTime = getCurrentTime();
        if (currentTime >= lastActivity) {
          onIdle && onIdle();
        }
      }, timeout);
    },
    [lastActivity, onIdle]
  );

  const handleUserActivityEvent = useCallback(() => {
    activityEventRef.current && clearTimeout(activityEventRef.current);
    activityEventRef.current = setTimeout(() => setTimeoutScheduled(false), 200);
    onActive && onActive();
  }, [onActive]);

  const handleStorageChangeEvent = (event: any) => {
    const { key, newValue } = event;
    if (key === StorageKeys.LAST_ACTIVITY_TIME) {
      idleHandler(newValue - getCurrentTime());
    }
  };

  const attachListeners = () => {
    events.forEach((eventName) =>
      window.addEventListener(eventName, handleUserActivityEvent)
    );
    window.addEventListener("storage", handleStorageChangeEvent);
  };

  const detachListeners = () => {
    events.forEach((eventName) =>
      window.removeEventListener(eventName, handleUserActivityEvent)
    );
    window.removeEventListener("storage", handleStorageChangeEvent);
  };

  useEffect(() => {
    if (enabled) {
      attachListeners();
      setTimeoutScheduled(false);
    }
    return () => {
      detachListeners();
      idleTimeoutRef.current && clearTimeout(idleTimeoutRef.current);
      activityEventRef.current && clearTimeout(activityEventRef.current);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  useEffect(() => {
    if (!timeoutScheduled) {
      idleHandler(timeout);
      setLastActivity(getCurrentTime() + timeout);
    }
    setTimeoutScheduled(true);
  }, [timeoutScheduled, timeout, idleHandler, setLastActivity]);

  return null;
};
