/*
 * File: viewport-editor.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import * as cornerstone from "cornerstone-core";
import { BaseEditor, BaseEditorInterface } from "../base-editor/base-editor";
import { Viewport } from "./viewport-editor.models";

export interface DicomEditorInterface extends BaseEditorInterface {
  getViewport(): Viewport;
  setViewport(viewport: Viewport): void;
  setContrast(contrast: { windowWidth: number; windowCenter: number }): void;
  increaseContrast(): void;
  decreaseContrast(): void;
  setZoom(zoom: number): void;
  zoomIn(): void;
  zoomOut(): void;
  setRotation(rotation: number): void;
  setInvert(invert: boolean): void;
  setFlipHorizontal(isFlipHorizontal: boolean): void;
  setFlipVertical(isFlipVertical: boolean): void;
  setTranslation(translation: { x: number; y: number }): void;

  resetAll(): void;
  resetViewport(): void;
  resetContrast(): void;
  resetZoom(): void;
  resetRotation(): void;
  toggleColorInvert(): void;
  resetColorInvert(): void;
  toggleFlipHorizontal(): void;
  resetFlipHorizontal(): void;
  toggleFlipVertical(): void;
  resetFlipVertical(): void;
  resetTranslation(): void;
}

export class CornerstoneViewportEditor
  extends BaseEditor
  implements DicomEditorInterface
{
  resetAll(): void {
    return cornerstone.reset(this.getContainer());
  }

  resetViewport(): void {
    return this.setViewport(this.getDefaultViewport());
  }

  zoomIn(): void {
    return this.setZoom(this.getViewport().scale * 0.9);
  }

  zoomOut(): void {
    return this.setZoom(this.getViewport().scale * 1.1);
  }

  resetZoom(): void {
    return this.setZoom(this.getDefaultViewport().scale);
  }

  setContrast(contrast: { windowWidth: number; windowCenter: number }): void {
    const viewport = this.getViewport();
    viewport.voi.windowWidth = contrast.windowWidth;
    viewport.voi.windowCenter = contrast.windowCenter;
    return this.setViewport(viewport);
  }

  increaseContrast(): void {
    const viewport = this.getViewport();
    viewport.voi.windowWidth *= 1.1;
    return this.setViewport(viewport);
  }

  decreaseContrast(): void {
    const viewport = this.getViewport();
    viewport.voi.windowWidth *= 0.9;
    return this.setViewport(viewport);
  }

  toggleColorInvert(): void {
    const viewport = this.getViewport();
    viewport.invert = !viewport.invert;
    return this.setViewport(viewport);
  }

  resetContrast(): void {
    return this.setContrast(this.getDefaultViewport().voi);
  }

  resetRotation(): void {
    return this.setRotation(this.getDefaultViewport().rotation);
  }

  resetColorInvert(): void {
    return this.setInvert(this.getDefaultViewport().invert);
  }

  toggleFlipHorizontal(): void {
    const viewport = this.getViewport();
    viewport.hflip = !viewport.hflip;
    return this.setViewport(viewport);
  }

  resetFlipHorizontal(): void {
    return this.setFlipHorizontal(this.getDefaultViewport().hflip);
  }

  toggleFlipVertical(): void {
    const viewport = this.getViewport();
    viewport.vflip = !viewport.vflip;
    return this.setViewport(viewport);
  }

  resetFlipVertical(): void {
    return this.setFlipHorizontal(this.getDefaultViewport().vflip);
  }

  resetTranslation(): void {
    return this.setTranslation(this.getDefaultViewport().translation);
  }

  getViewport(): Viewport {
    return cornerstone.getViewport(this.getContainer());
  }

  setViewport(viewport: Viewport): void {
    return cornerstone.setViewport(this.getContainer(), viewport, viewport);
  }

  setZoom(zoom: number): void {
    const viewport = this.getViewport();
    viewport.scale = zoom;
    return this.setViewport(viewport);
  }

  setRotation(rotation: number): void {
    const viewport = this.getViewport();
    viewport.rotation = rotation;
    return this.setViewport(viewport);
  }

  setInvert(invert: boolean): void {
    const viewport = this.getViewport();
    viewport.invert = invert;
    return this.setViewport(viewport);
  }

  setFlipHorizontal(isFlipHorizontal: boolean): void {
    const viewport = this.getViewport();
    viewport.hflip = isFlipHorizontal;
    return this.setViewport(viewport);
  }

  setFlipVertical(isFlipVertical: boolean): void {
    const viewport = this.getViewport();
    viewport.vflip = isFlipVertical;
    return this.setViewport(viewport);
  }

  setTranslation(translation: { x: number; y: number }): void {
    const viewport = this.getViewport();
    viewport.translation.x = translation.x;
    viewport.translation.y = translation.y;
    return this.setViewport(viewport);
  }
}
