/*
 * File: draw-handles.js
 * Project: app-aiscaler-web
 * File Created: Friday, 3rd December 2021 11:04:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import * as csTools from "cornerstone-tools";
const drawRect = csTools.importInternal("drawing/drawRect");
import drawFillRect from "./draw-fill-rect";

const external = csTools.external;

/**
 * Draws proivded handles to the provided context
 * @public
 * @method drawHandles
 * @memberof Drawing
 *
 * @param {CanvasRenderingContext2D} context - Target context
 * @param {*} evtDetail - Cornerstone's 'cornerstoneimagerendered' event's `detail`
 * @param {Object} rect - Bounding of the measurement
 * @param {Object} [options={}] - Options object
 * @param {string} [options.color]
 * @param {Boolean} [options.drawHandlesIfActive=false] - Whether the handles should only be drawn if Active (hovered/selected)
 * @param {string} [options.fill]
 * @param {Number} [options.handleRadius=6]
 * @returns {undefined}
 */
export default function (context, evtDetail, bboxes, options = {}) {
  const { element } = evtDetail;
  for (const bbox of bboxes) {
    const { color, data } = bbox;
    const rectOptions = { color: color };
    const start = { x: data.x, y: data.y };
    const end = { x: data.x + data.width, y: data.y + data.height };
    drawRect(context, element, start, end, rectOptions, "pixel");
    drawFillRect(context, element, start, end, rectOptions, "pixel");
  }
}
