/*
 * File: ml.service.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 30th June 2022 2:57:36 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import axios, { AxiosInstance } from "axios";
import { ENV_CONFIG } from "configs/env.config";
import { AIModel } from "domain/customer/ai-model";
import { Model } from "domain/customer/ml-model";
import { setupLabelInterceptor } from "services/common/label-interceptor";
import { RequestOptions } from "services/common/request-options";
import aiModelsMapper from "./ai-models.mapper";
import {
  MlCreateModelVersionRequest,
  MlExperimentRequest,
  MlRunRequest,
} from "./ml-service.dto";



let _axiosInstance: AxiosInstance | undefined = undefined;

function getAxiosInstance() {
  if (_axiosInstance) return _axiosInstance;
  _axiosInstance = setupLabelInterceptor(
    axios.create({
      baseURL: ENV_CONFIG.ML_SERVICE_URL,
      timeout: 10000,
    })
  );
  return _axiosInstance;
}

async function getModels(
  workspaceId?: string,
  options: RequestOptions = {}
): Promise<Model[]> {
  if (!workspaceId) return Promise.resolve([]);
  const params = new URLSearchParams({ ...options, workspaceId });
  const urlPath = "api/v1/inference-engine/info";
  const url = `${urlPath}?${params.toString()}`;
  const response = await getAxiosInstance().get(url);
  const items = response.data as Model[];
  return items;
}

async function getAIModels(workspaceId?: string) {
  if (!workspaceId) return [];
  const params = new URLSearchParams({ workspaceId });
  const url = `api/v1/custom-model?${params.toString()}`;
  const response = await getAxiosInstance().get(url);
  const items: AIModel[] = response.data.map(aiModelsMapper.fromDTO);
  return items.sort((a, b) => parseInt(b.id) - parseInt(a.id));
}

async function registerCustomModel(params: {
  storageResourceId: string;
  name: string;
  description: string;
  workspaceId: string;
  inferenceType: string;
}) {
  const url = `api/v1/custom-model`;
  const response = await getAxiosInstance().post(url, params);
  return response;
}

async function getExperiments(workspaceId: number | string) {
  const params = new URLSearchParams({
    workspace_id: workspaceId.toString(),
  });
  const res = await getAxiosInstance().get(
    `api/v1/experiments?${params.toString()}`
  );
  return res.data;
}

async function createExperiment(payload: MlExperimentRequest) {
  const res = await getAxiosInstance().post(`api/v1/experiments`, payload);
  return res.data;
}

async function getPipelines() {
  const res = await getAxiosInstance().get(`api/v1/pipelines`);
  return res.data;
}

async function getRunInfosById(id: string | number) {
  const params = new URLSearchParams({
    run_id: id.toString(),
  });
  const res = await getAxiosInstance().get(`api/v1/runs?${params.toString()}`);
  return res.data;
}

async function getRunInfosByExperimentId(experimentId: string) {
  const params = new URLSearchParams({
    experiment_id: experimentId,
  });
  const res = await getAxiosInstance().get(`api/v1/runs/list?${params.toString()}`);
  return res.data;
}

async function createRunForExperiment(payload: MlRunRequest) {
  const res = await getAxiosInstance().post(`api/v1/runs`, payload);
  return res.data;
}

async function terminateRun(runId: string) {
  const params = new URLSearchParams({
    run_id: runId,
  });
  const res = await getAxiosInstance().post(
    `api/v1/runs/terminate?${params.toString()}`
  );
  return res.data;
}

async function downloadModel(payload: Record<string, string>) {
  const res = await getAxiosInstance().post(
    `api/v1/models/download?${new URLSearchParams(payload).toString()}`,
    payload
  );
  return res.data;
}

async function createModelVersion(payload: MlCreateModelVersionRequest) {
  const res = await getAxiosInstance().post(`api/v1/models`, payload);
  return res.data;
}

async function getModelsByWorkspaceId(workspaceId: string) {
  const params = new URLSearchParams({
    workspace_id: workspaceId.toString(),
  });
  const res = await getAxiosInstance().get(`api/v1/models?${params.toString()}`);
  return res.data;
}

export const mlServiceApis = {
  getModels,
  getAIModels,
  registerCustomModel,
  getExperiments,
  createExperiment,
  getPipelines,
  getRunInfosByExperimentId,
  getRunInfosById,
  createRunForExperiment,
  terminateRun,
  downloadModel,
  createModelVersion,
  getModelsByWorkspaceId,
};
