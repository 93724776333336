/*
 * File: exported-dataset-versions.api.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 22nd September 2021 5:12:18 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BaseAPI } from "services/base-service/api/base.api";
import { ExportedDatasetVersionDTO } from "../dto/exported-dataset.dto";

export class ExportedDatasetVersionsAPI extends BaseAPI<ExportedDatasetVersionDTO> {}
