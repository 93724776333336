import { useTranslation } from "react-i18next";
import { classnames } from "utilities/classes";
import { useDatasetDetailContext } from "pages/customer/datasets/dataset-detail/dataset-detail.context";

export const DeleteFilesBatch = () => {
  const { t } = useTranslation();
  const { selecting, selectedFiles, setDeletingItemIds } =
    useDatasetDetailContext();

  return (
    <div>
      {selecting && (
        <button
          className={classnames("button-error", {
            "opacity-60 cursor-not-allowed": selectedFiles.length === 0,
          })}
          disabled={selectedFiles.length === 0}
          onClick={() => setDeletingItemIds(selectedFiles)}
        >
          <i className="uir-trash"></i>
          <div className="">{t("common:buttonDelete")}</div>
        </button>
      )}
    </div>
  );
};
