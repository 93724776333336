import { Tooltip } from "@material-ui/core";
import {
  IconNote,
  IconTarget,
  IconTickCircle,
  IconToolAngle,
  IconToolDistance,
  IconTrash,
} from "components/common/vb-icon.component";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  EditorEventGoToSlice,
  EditorMeasurement,
  EditorMeasurementType,
  ThreeDEditorEvents,
} from "./three-d-editor.models";
import { useThreeDEditorContext } from "./three-d-editor.provider";
import { getMeasurementWidgetText } from "./three-d-editor.utils";

export const ThreeDEditorMeasurementsComponent = () => {
  const { measurements, setMeasurements } = useThreeDEditorContext();
  const [editItemId, setEditItemId] = useState<string>();

  const edittingItem = useMemo(() => {
    return measurements.find((m) => m.uuid === editItemId);
  }, [measurements, editItemId]);

  const measurementsRef = useRef(measurements);

  useEffect(() => {
    measurementsRef.current = measurements;
  }, [measurements]);

  useEffect(() => {
    const handleMeasurementChanged = (e: any) => {
      const measurement = e.detail as EditorMeasurement;
      setMeasurements(
        measurementsRef.current.map((m) => {
          if (m.uuid === measurement.uuid) {
            return measurement;
          }
          return m;
        })
      );
    };

    document.addEventListener(
      ThreeDEditorEvents.MEASUREMENT_CHANGED,
      handleMeasurementChanged
    );

    return () => {
      document.removeEventListener(
        ThreeDEditorEvents.MEASUREMENT_CHANGED,
        handleMeasurementChanged
      );
    };
  }, [setMeasurements]);

  const handleClickGoToSlice = (item: EditorMeasurement) => {
    const eventData: EditorEventGoToSlice = {
      windowId: item.windowId,
      slice: item.slice,
    };
    document.dispatchEvent(
      new CustomEvent(ThreeDEditorEvents.COMMAND_GO_TO_SLICE, {
        detail: eventData,
      })
    );
  };

  const handleClickDelete = (item: EditorMeasurement) => {
    document.dispatchEvent(
      new CustomEvent(ThreeDEditorEvents.COMMAND_DELETE_MEASUREMENT, {
        detail: item,
      })
    );
  };

  const handleNoteChanged = (uuid: string, newNote: string) => {
    setMeasurements(
      measurements.map((m) => {
        if (uuid === m.uuid) {
          return {
            ...m,
            note: newNote,
          };
        }
        return m;
      })
    );
  };

  if (measurements.length <= 0) return null;

  return (
    <div className="flex flex-col gap-2 relative">
      <h1 className="font-bold">Measurements</h1>
      <div className="flex flex-col gap-2 max-h-40 overflow-y-auto text-sm">
        {measurements.map((m) => (
          <ThreeDEditorMeasurementItem
            key={m.uuid}
            item={m}
            onClickGoToSlice={handleClickGoToSlice}
            onClickDelete={handleClickDelete}
            onClickEdit={(item) => setEditItemId(item.uuid)}
          />
        ))}
      </div>

      {edittingItem && (
        <div className="z-50 p-2 flex flex-col gap-1 absolute right-0 border rounded bg-white text-black text-sm">
          <div className="flex items-center justify-between">
            <span>Note</span>
            <IconTickCircle
              className="w-4 h-4 cursor-pointer"
              onClick={() => setEditItemId(undefined)}
            />
          </div>
          <textarea
            className="w-40 border rounded"
            rows={2}
            value={edittingItem.note}
            onChange={(e) =>
              handleNoteChanged(edittingItem.uuid, e.target.value)
            }
          />
        </div>
      )}
    </div>
  );
};

const TYPE_ICON_MAP = {
  [EditorMeasurementType.distance]: <IconToolDistance className="w-4 h-4" />,
  [EditorMeasurementType.angle]: <IconToolAngle className="w-4 h-4" />,
};
interface ThreeDEditorMeasurementItemProps {
  item: EditorMeasurement;
  onClickGoToSlice: (item: EditorMeasurement) => void;
  onClickDelete: (item: EditorMeasurement) => void;
  onClickEdit: (item: EditorMeasurement) => void;
}
export const ThreeDEditorMeasurementItem = ({
  item,
  onClickGoToSlice,
  onClickDelete,
  onClickEdit,
}: ThreeDEditorMeasurementItemProps) => {
  const [isHover, setIsHover] = useState(false);

  const text = getMeasurementWidgetText(item.widget, item.type);

  return (
    <div
      className="flex items-center flex-shrink-0 gap-2 relative truncate"
      onMouseEnter={(_) => setIsHover(true)}
      onMouseLeave={(_) => setIsHover(false)}
    >
      {TYPE_ICON_MAP[item.type]}

      <Tooltip title={item.note} placement="top">
        <span className="truncate">{`${text}${item.note ? " - " + item.note : ""
          }`}</span>
      </Tooltip>

      {isHover && (
        <div className="absolute right-0 bottom-1/2 transform translate-y-1/2 flex items-center gap-2 bg-background-900">
          <IconTarget
            className="w-4 h-4 cursor-pointer"
            onClick={() => onClickGoToSlice(item)}
          />
          <IconNote
            className="w-4 h-4 cursor-pointer"
            onClick={() => onClickEdit(item)}
          />
          <IconTrash
            className="w-4 h-4 cursor-pointer"
            onClick={() => onClickDelete(item)}
          />
        </div>
      )}
    </div>
  );
};
