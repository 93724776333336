import { IconAddCircle } from "components/common/vb-icon.component";
import { VBNumberTextInputComponent } from "components/design-system/number-text-input/number-text-input.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { useAppDispatch } from "hooks/use-redux";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useMemo, useRef } from "react";
import {
  annotationTypeMapName,
  ObservationAnnotationType,
  ObservationAttribute,
  ObservationAttributeType,
  ObservationDTO,
} from "services/label-service/dtos";
import { ProjectObservationAttributes } from "./label-attributes.component";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { useRemainLabelColors } from "./remain-label-colors.hook";
import DOMPurify from "dompurify";

interface Props {
  value: Partial<ObservationDTO>;
  onChanged?: (newValue: Partial<ObservationDTO>) => void;
  onHasErrorChanged?: (v: boolean) => void;
  projectObsAnnotationTypes?: string[];
  observations?: ObservationDTO[];
  showAttributes?: boolean;
}
export const LabelEditMinimized = ({
  value,
  onChanged,
  onHasErrorChanged,
  projectObsAnnotationTypes = [],
  observations = [],
  showAttributes = true,
}: Props) => {
  const dispatch = useAppDispatch();
  const projectLabelColors = useMemo(() => {
    return observations.map((obs) => obs.observationSetting.color);
  }, [observations]);
  const remainLabelColors = useRemainLabelColors(observations);

  const annotationTypeOptions = useMemo(() => {
    if (projectObsAnnotationTypes.length <= 0) {
      return [
        {
          label: annotationTypeMapName[ObservationAnnotationType.GENERAL],
          value: ObservationAnnotationType.GENERAL,
        },
      ];
    }
    return projectObsAnnotationTypes.map((t) => ({
      label: annotationTypeMapName[t],
      value: t,
    }));
  }, [projectObsAnnotationTypes]);

  const colorInputRef = useRef<any>();

  const [maskIdError, setMaskIdError] = useState("");

  const setValueField = (field: keyof ObservationDTO, v: any) => {
    const newValue = { ...value, [field]: v };
    onChanged && onChanged(newValue);
  };

  const setSettingField = (field: string, v: string | number) => {
    const newValue: any = {
      ...value,
      observationSetting: {
        ...value.observationSetting,
        [field]: v,
      },
    };
    onChanged && onChanged(newValue);
  };

  const handleAddAttribute = () => {
    const newAttribute: Partial<ObservationAttribute> = {
      type: ObservationAttributeType.RADIO,
      name: "",
      defaultValue: "",
      values: [],
      ranges: [],
      required: false,
    };
    setValueField("attributes", [newAttribute, ...(value.attributes || [])]);
  };

  useEffect(() => {
    if (
      value.observationSetting?.annotationType !==
      ObservationAnnotationType.MASK
    ) {
      setMaskIdError("");
      onHasErrorChanged && onHasErrorChanged(false);
      return;
    }

    const existedMasks = observations
      .filter(
        (obs) =>
          obs.observationSetting.annotationType ===
            ObservationAnnotationType.MASK && obs.id !== value.id
      )
      .map((obs) => obs.observationSetting.maskId || 0);
    const maskId = value.observationSetting?.maskId || 0;
    if (maskId <= 0) {
      setMaskIdError("MaskId is not valid");
      onHasErrorChanged && onHasErrorChanged(true);
      return;
    }

    if (existedMasks.includes(maskId)) {
      setMaskIdError("MaskId already existed!");
      onHasErrorChanged && onHasErrorChanged(true);
      return;
    }
    setMaskIdError("");
    onHasErrorChanged && onHasErrorChanged(false);
  }, [value, onHasErrorChanged, observations, setMaskIdError]);

  const handleMaskIdChanged = (v: number) => {
    setSettingField("maskId", parseInt(v.toString()));
  };

  const handleChangeInputColor = (e: ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;
    const canChangeColor =
      projectLabelColors.findIndex(
        (c) => c.toLowerCase() === value.toLowerCase()
      ) === -1;

    if (canChangeColor) {
      setSettingField("color", value);
    } else {
      dispatch(enqueueErrorNotification("Color is already used!"));
    }
  };

  return (
    <Fragment>
      <div className="flex items-center gap-2">
        <div className="relative flex items-center justify-center">
          <div
            className="absolute w-8 h-8 transform -translate-x-1/2 -translate-y-1/2 border rounded-full left-1/2 top-1/2"
            style={{
              backgroundColor: `${value.observationSetting?.color}`,
            }}
            onClick={() =>
              colorInputRef.current && colorInputRef.current.click()
            }
          ></div>
          <input
            ref={colorInputRef}
            type="color"
            className="w-8 h-8 p-0 border-none opacity-0"
            list="presetColors"
            value={value.observationSetting?.color}
            onChange={handleChangeInputColor}
          />
          <datalist id="presetColors" className="absolute">
            {remainLabelColors.map((c) => (
              <option key={c}>{c}</option>
            ))}
          </datalist>
        </div>
        <div className="flex-1">
          <VBNumberTextInputComponent
            placeholder="Label name"
            height="32px"
            type="text"
            defaultValue={value.name}
            listenChangedDefault
            onValueChanged={(value) =>
              setValueField("name", DOMPurify.sanitize(value as string))
            }
            autoFocus
          />
        </div>
        <VBSelectComponent
          containerClassName="w-48"
          placeholder="Annotation type"
          options={annotationTypeOptions}
          value={annotationTypeOptions.find(
            (option) =>
              option.value === value.observationSetting?.annotationType
          )}
          onChange={(option: any) =>
            setSettingField("annotationType", option.value as string)
          }
          menuPortalTarget={document.body}
        />
      </div>

      <div className="flex flex-col gap-2">
        {value.observationSetting?.annotationType ===
          ObservationAnnotationType.MASK && (
          <div className="flex items-center gap-2">
            <span>Mask id</span>
            <div>
              <VBNumberTextInputComponent
                placeholder=""
                height="32px"
                type="number"
                defaultValue={value.observationSetting.maskId}
                listenChangedDefault
                onValueChanged={(v) => handleMaskIdChanged(v as number)}
                error={maskIdError}
              />
            </div>
          </div>
        )}
        {showAttributes && (
          <div className="flex items-center gap-2">
            <IconAddCircle
              className="w-8 h-8 cursor-pointer text-primary"
              onClick={handleAddAttribute}
            />
            <span>Add attribute</span>
          </div>
        )}
        {value.attributes && showAttributes && (
          <ProjectObservationAttributes
            attributes={value.attributes}
            onChanged={(values) => setValueField("attributes", values)}
          />
        )}
      </div>
    </Fragment>
  );
};
