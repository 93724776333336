import { useAppSelector } from "hooks/use-redux";
import { useState } from "react";
import { selectCurrentJob } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import {
  selectJobAnnotations,
  selectJobRelationAnnotations,
} from "store/labeler/image-workspace/image-annotations/image-annotations.selectors";
import { selectImageLabelingLabels } from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import useDeepCompareEffect from "use-deep-compare-effect";
import { buildAnnotationTree } from "./annotations-tree.helper";
import { buildEntities } from "./object-tree.helper";
import { TreeItem } from "./use-object-tree";
export default function useJobAnnotationTree() {
  const labelingJob = useAppSelector(selectCurrentJob);
  const user = labelingJob?.job.assignee ?? "";
  const jobId = labelingJob?.job.id ?? -1;
  const annotations = useAppSelector(selectJobAnnotations(jobId, [user]));
  const relations = useAppSelector(selectJobRelationAnnotations(jobId, [user]));
  const labels = useAppSelector(selectImageLabelingLabels);
  const [entities, setEntities] = useState<Record<string, TreeItem>>(() => {
    return buildEntities(buildAnnotationTree(annotations, labels));
  });

  useDeepCompareEffect(() => {
    setEntities(buildEntities(buildAnnotationTree(annotations, labels)));
  }, [annotations, labels]);

  return { labelingJob, annotations, relations, entities, setEntities };
}
