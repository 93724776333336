/*
 * File: user.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd January 2022 10:12:26 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { User } from "domain/customer";
import { UserDTO } from "../dtos/user.dto";

export const userMapper = {
  toEntity: (dto: UserDTO): User => {
    return {
      id: dto.userId,
      email: dto.email,
      name: dto.userName,
      role: dto.role,
      userId: dto.userId,
    };
  },
  toDTO: (entity: User): UserDTO => {
    return {
      email: entity.email,
      userName: entity.name,
      role: entity.role,
      userId: entity.userId,
    };
  },
};
