import { Project } from "domain/labeling/project";
import { AppError } from "utilities/errors/errors";
import { ComlexJobsEditorSettingState, INITIAL_COMPLEX_JOBS_EDITOR_SETTING_STATE } from "./complex-jobs-editor-setting/complex-jobs-editor-setting.state";
import { LoadCombplexJobResponse } from "./complex-jobs.thunk";


export enum ComplexJobInBatchStatus {
  LOADING = "LOADING",
  READY = "READY",
  SAVED = "SAVED",
}

export enum WorkingStatus {
  NONE = "none",
  WORKING = "working",
  SAVED = "saved",
}

export interface ComplexJobInBatch {
  id: number;
  status: ComplexJobInBatchStatus,
  loadJobComplexRes: LoadCombplexJobResponse | undefined,
  data?: any;
}

export interface ComplexJobsStoreState {
  project?: Project
  currentJobId: number;
  batchJobs: ComplexJobInBatch[];
  jobWorkingStatus: { [key: number]: WorkingStatus };
  error: AppError | undefined;
  settings: ComlexJobsEditorSettingState
}

export const INITIAL_COMPLEX_JOBS_STORE_STATE: ComplexJobsStoreState = {
  project: undefined,
  currentJobId:  -1,
  batchJobs: [],
  error: undefined,
  jobWorkingStatus: {},
  settings: INITIAL_COMPLEX_JOBS_EDITOR_SETTING_STATE
};

