/*
 * File: created-by-filter.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd July 2023 2:50:08 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { Filter } from "domain/common";
import { useState } from "react";
import { QueryApi } from "data-access/impl/query";
import useCreatedByOptions from "../../../hooks/use-created-by-options";
import { useWorkspaceContext } from "contexts/workspace";

interface Props {
  filter: Filter;
  onFilterChange(filter: Filter): void;
}

export function CreatedByFilter({ filter, onFilterChange }: Props) {
  const { workspaceId } = useWorkspaceContext();
  const [createdByFilter] = useState({
    page: 0,
    size: 1000,
    queries: [{ field: "workspaceId", operator: "$eq", value: workspaceId }],
  });

  const {
    options: createdByOptions,
    option: selectedCreatedBy,
    onChange: onCreatedByChange,
  } = useCreatedByOptions(createdByFilter);

  return (
    <div className="px-4">
      <VBSelectComponent
        header="Created By"
        placeholder="Created By"
        value={selectedCreatedBy}
        options={createdByOptions}
        isClearable
        menuPortalTarget={document.body}
        onChange={(newOption) => {
          onCreatedByChange(newOption);
          const fileFilter = new QueryApi.FileFilter(filter);
          let value: string | undefined = undefined;
          if (newOption) {
            value = (newOption as any)?.value ?? undefined;
          }
          fileFilter.setCreatedBy(value);
          onFilterChange(fileFilter.toFilter());
        }}
      />
    </div>
  );
}
