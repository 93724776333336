/*
 * File: add-issue.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 5th July 2022 3:10:57 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store";
import { TextWorkspaceState } from "../../text-workspace.state";
import { textIssueUtils } from "../../utils/text-issue.utils";
import {
  selectTextLabelingMode,
  selectTextLabelingTask,
} from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { TextAnnotation } from "domain/text-labeling/text-annotation";
import { TextLabelingMode } from "../../text-labeling/text-labeling.state";
import {
  issueCustomerService,
  issueLabelerReviewService,
} from "services/label-service";
import { NERAnnotation } from "domain/text-labeling";
import { selectTextLabelingTaskJobs } from "store/labeler/text-labeling-task/text-labeling-task.selectors";
import { selectTextReviewLabelingDatas } from "../../text-review/text-review.selectors";
const ADD_TEXT_ISSUE_ASYNC = "textLabeling/addTextIssueAsync";

export interface AddTextIssuePayload {
  content: string;
  annotation?: TextAnnotation;
  tokenId?: string;
  annotator: string;
}

export const addTextIssueAsync = createAsyncThunk(
  ADD_TEXT_ISSUE_ASYNC,
  async (payload: AddTextIssuePayload, { getState }) => {
    const state = getState() as RootState;
    const jobs = selectTextLabelingTaskJobs(state);
    const user = selectCurrentUser(state);
    const mode = selectTextLabelingMode(state);
    const task = selectTextLabelingTask(state);
    const labelingDatas = selectTextReviewLabelingDatas(state);
    const isCustomer = TextLabelingMode.CUSTOMER === mode;
    const isAcceptance = TextLabelingMode.ACCEPTANCE === mode;
    if (!(isCustomer || isAcceptance)) {
      return;
    }

    const { content, annotation, annotator, tokenId } = payload;
    let jobId;

    if (isCustomer) {
      jobId = jobs.find((job) => job.assignee === annotator)?.id;
    }

    if (isAcceptance) {
      jobId = labelingDatas.find((data) => data.annotator === annotator)?.jobId;
    }

    if (!jobId || !user) return;

    let data;
    if (annotation && (annotation as NERAnnotation)) {
      const entity = annotation as NERAnnotation;
      data = {
        startIndex: entity.startIndex,
        endIndex: entity.endIndex,
        annotationId: parseInt(entity.observationId),
      };
    } else if (tokenId) {
      const items = tokenId.split(":");
      if (items.length === 2) {
        data = {
          startIndex: parseInt(items[0]),
          endIndex: parseInt(items[1]),
          annotationId: -1,
        };
      }
    }

    if (!data) return;

    const issuePayload = {
      taskId: task?.id,
      description: content,
      jobId: jobId,
      data,
    };
    if (isCustomer) {
      const response = await issueCustomerService.createIssue(issuePayload);
      return textIssueUtils.fromDTO(response.data);
    }
    if (isAcceptance) {
      const response = await issueLabelerReviewService.createIssue(
        issuePayload
      );
      return textIssueUtils.fromDTO(response.data);
    }
  }
);

export function addTextIssueReducerBuidler(
  builder: ActionReducerMapBuilder<TextWorkspaceState>
) {
  return builder
    .addCase(addTextIssueAsync.pending, (_state, _action) => {})
    .addCase(addTextIssueAsync.fulfilled, (state, action) => {
      const issue = action.payload;
      if (!issue) return;
      state.textIssues.issues.push(issue);
    })
    .addCase(addTextIssueAsync.rejected, (_state, _action) => {});
}
