import { Collection, collectionUtils } from "domain/common";
import { AnnotationSource } from "domain/labeling/annotation-source";
import { TextLabelingData } from "domain/labeling/labeling-data";
import { CORAnnotation, Label, NERAnnotation } from "domain/text-labeling";

interface SelecableEntity {
  id: string;
  selected?: boolean;
  hidden?: boolean;
}

export interface SelectableAnnotator extends SelecableEntity {
  label: string;
  value: string;
}

export interface SelectableLabel extends Label, SelecableEntity {}

export interface SelectableAnnotation extends NERAnnotation, SelecableEntity {}

export interface SelectableRelation extends CORAnnotation, SelecableEntity {}

export interface TextAnnotationMenuItem {
  id: string;
  name: string;
  hidden?: boolean;
  selected?: boolean;
  children: TextAnnotationMenuItem[];
  data?: any;
  type: string;
}

interface State {
  annotations: Collection<SelectableAnnotation>;
  relations: Collection<SelectableRelation>;
  labels: Collection<SelectableLabel>;
  labelingDatas: Collection<TextLabelingData>;
  annotators: Collection<SelectableAnnotator>;
}

export const defaultTextAnnotationSourcesState: State = {
  annotations: { allIds: [], entities: {} },
  relations: { allIds: [], entities: {} },
  labels: { allIds: [], entities: {} },
  labelingDatas: { allIds: [], entities: {} },
  annotators: { allIds: [], entities: {} },
};

export enum AnnotationsActionType {
  INIT = "INIT",
  SELECT_ANNOTATOR = "SELECT_ANNOTATOR",
  SELECT_ANNOTATION = "SELECT_ANNOTATION",
  SELECT_LABEL = "SELECT_LABEL",
}

interface AnnotationsAction<T = any> {
  type: AnnotationsActionType;
  payload: T;
}

export default function textAnnotationsReducer(
  state: State,
  action: AnnotationsAction
) {
  switch (action.type) {
    case AnnotationsActionType.INIT:
      return initState(state, action);
    case AnnotationsActionType.SELECT_ANNOTATOR:
      return selectAnnotator(state, action);
    case AnnotationsActionType.SELECT_ANNOTATION:
      return selectAnnotation(state, action);
    case AnnotationsActionType.SELECT_LABEL:
      return selectLabel(state, action);
    default:
      return state;
  }
}

function initState(
  state: State,
  action: AnnotationsAction<{
    labelingDatas: TextLabelingData[];
    labels: Label[];
  }>
) {
  const { labels, labelingDatas } = action.payload;
  const datas = labelingDatas.map((item) => {
    if (item.source === AnnotationSource.IMPORT) {
      return {
        ...item,
        id: -1,
        jobId: -1,
      };
    }
    if (item.source === AnnotationSource.MODEL) {
      return {
        ...item,
        id: -2,
        jobId: -2,
      };
    }
    return item;
  });
  const annotators: SelectableAnnotator[] = datas.map((lb) => ({
    id: lb.annotator,
    value: lb.annotator,
    label: lb.annotator,
    selected: true,
  }));
  const annotations: SelectableAnnotation[] = datas
    .reduce((current: NERAnnotation[], data) => {
      return current.concat(data.annotations);
    }, [])
    .map((anno) => ({ ...anno, selected: true, hidden: false }));
  const relations: SelectableRelation[] = datas
    .reduce((current: CORAnnotation[], data) => {
      return current.concat(data.relationAnnotations);
    }, [])
    .map((relation) => ({ ...relation, selected: true, hidden: false }));
  const newState: State = {
    ...state,
    annotators: collectionUtils.fromEntities(annotators),
    labels: collectionUtils.fromEntities(
      labels.map((lb) => ({ ...lb, selected: true }))
    ),
    labelingDatas: collectionUtils.fromEntities(datas),
    annotations: collectionUtils.fromEntities(annotations),
    relations: collectionUtils.fromEntities(relations),
  };
  return newState;
}
function selectAnnotator(state: State, action: AnnotationsAction<number>) {
  const annotator = collectionUtils.getOne(state.annotators, action.payload);
  if (!annotator) return state;
  annotator.selected = !annotator.selected;
  for (const id of state.annotations.allIds) {
    const entity = collectionUtils.getOne(state.annotations, id);
    if (!entity?.annotator || annotator.value !== entity.annotator) continue;
    entity.selected = annotator.selected;
  }
  for (const id of state.relations.allIds) {
    const entity = collectionUtils.getOne(state.relations, id);
    if (!entity?.annotator || annotator.value !== entity.annotator) continue;
    entity.selected = annotator.selected;
  }
  return { ...state };
}
function selectAnnotation(
  state: State,
  action: AnnotationsAction<{ id: number; type?: string }>
) {
  const { annotations, relations } = state;
  const id = action.payload.id;
  const annotation = collectionUtils.getOne(annotations, id);
  if (annotation) {
    annotation.selected = !annotation.selected;
    if (!annotation.selected) {
      for (const relationId of relations.allIds) {
        const relation = collectionUtils.getOne(relations, relationId);
        if (
          annotation.id === relation?.from ||
          annotation.id === relation?.to
        ) {
          relation.selected = false;
        }
      }
    }
    return { ...state };
  }
  const relation = collectionUtils.getOne(relations, id);
  if (relation) {
    relation.selected = !relation.selected;
    if (relation.selected) {
      const from = collectionUtils.getOne(annotations, relation.from);
      const to = collectionUtils.getOne(annotations, relation.to);
      if (from && to) {
        from.selected = true;
        to.selected = true;
      }
    }
    return { ...state };
  }
  return state;
}
function selectLabel(
  state: State,
  action: AnnotationsAction<{ id: number; type?: string }>
) {
  const labelId = action.payload.id;
  const label = collectionUtils.getOne(state.labels, labelId);
  if (!label) return state;
  label.selected = !label.selected;
  for (const annoId of state.annotations.allIds) {
    const entity = collectionUtils.getOne(state.annotations, annoId);
    if (label.id === entity?.observationId) entity.selected = label.selected;
  }
  for (const relationId of state.relations.allIds) {
    const entity = collectionUtils.getOne(state.relations, relationId);
    if (label.id === entity?.observationId) entity.selected = label.selected;
    if (entity?.selected) {
      const from = collectionUtils.getOne(state.annotations, entity.from);
      const to = collectionUtils.getOne(state.annotations, entity.to);
      if (from) from.selected = true;
      if (to) to.selected = true;
    }
  }
  return { ...state };
}
