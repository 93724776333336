/*
 * File: collection.ts
 * Project: app-aiscaler-web
 * File Created: Sunday, 2nd January 2022 4:51:42 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AtLeast } from "types/common";

export type UniqueId = string | number;

export interface Entity {
  id: UniqueId;
}

export interface Collection<T extends Entity> {
  entities: { [key: UniqueId]: T };
  allIds: UniqueId[];
  currentId?: UniqueId;
}

export const defaultCollection = {
  allIds: [],
  entities: {},
};

function hasOne<T extends Entity>(collection: Collection<T>, id: UniqueId) {
  return collection.entities.hasOwnProperty(id);
}

function getOne<T extends Entity>(collection: Collection<T>, id: UniqueId) {
  if (!hasOne(collection, id)) return undefined;
  return collection.entities[id];
}

function removeOne<T extends Entity>(collection: Collection<T>, id: UniqueId) {
  if (!hasOne(collection, id)) return;
  collection.allIds = collection.allIds.filter((entityId) => entityId !== id);
  delete collection.entities[id];
}

function updateOne<T extends Entity>(
  collection: Collection<T>,
  entity: AtLeast<T, "id">
) {
  if (!hasOne(collection, entity.id)) return;
  collection.entities[entity.id] = {
    ...collection.entities[entity.id],
    ...entity,
  };
}

function addOne<T extends Entity>(collection: Collection<T>, entity: T) {
  if (hasOne(collection, entity.id)) return updateOne(collection, entity);
  collection.entities[entity.id] = entity;
  collection.allIds.push(entity.id);
}

function removeAll<T extends Entity>(collection: Collection<T>, id: UniqueId) {
  collection.entities = {};
  collection.allIds = [];
}

function getAll<T extends Entity>(collection: Collection<T>) {
  return collection.allIds.map((id) => collection.entities[id]);
}

function setCurrentOne<T extends Entity>(
  collection: Collection<T>,
  id: UniqueId
) {
  collection.currentId = id;
}

function getCurrentOne<T extends Entity>(collection: Collection<T>) {
  if (!collection.currentId) return undefined;
  return getOne(collection, collection.currentId);
}

function fromEntities<T extends Entity>(entities: T[]) {
  const collection: Collection<T> = { entities: {}, allIds: [] };
  for (const entity of entities) {
    addOne(collection, entity);
  }
  return collection;
}

function values<T extends Entity>(collection: Collection<T>) {
  return collection.allIds.map((id) => collection.entities[id]);
}

export const collectionUtils = {
  hasOne,
  getOne,
  removeOne,
  updateOne,
  addOne,
  getAll,
  removeAll,
  setCurrentOne,
  getCurrentOne,
  fromEntities,
  values,
};
