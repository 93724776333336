import { CurrencyResponseDTO } from "services/payment/payment.dto";

export interface CurrencySelectOption {
  label: string;
  value: string | number;
}

export interface PaymentStoreState {
  currencies: CurrencyResponseDTO[],
}

export const INITIAL_PAYMENT_STORE_STATE: PaymentStoreState = {
  currencies: [],
}