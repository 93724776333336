/*
 * File: ai-models.slice.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 22nd February 2022 3:23:53 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 * 
 * Copyright 2022 VinBrain JSC
 */


import { AIModelVersionsState, INITIAL_AI_MODEL_VERIONS_STATE } from "./ai-model-versions.state";
import { createSlice } from "@reduxjs/toolkit";
import { aiModelVersionsReducerBuilder } from "./ai-model-versions.thunk";
const SLICE_NAME: string = "aiModelVersions";
const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_AI_MODEL_VERIONS_STATE,
  reducers: {
    resetAIModels(state: AIModelVersionsState) {
      Object.assign(state, INITIAL_AI_MODEL_VERIONS_STATE);
    },
  },
  extraReducers: (builder) => {
    aiModelVersionsReducerBuilder(builder);
  },
});

export const { resetAIModels } = slice.actions;

export const aiModelVersionsReducer = slice.reducer;
