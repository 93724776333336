/*
 * File: text-review.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 29th October 2021 11:12:03 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { collectionUtils } from "domain/common";
import { RootState } from "store";
import { textUtils } from "../utils/text-labeling.utils";

export const selectIsReviewMode = (state: RootState) => {
  return state.textWorkspace.textReview.reviewMode;
};

export const selectTextReviewAnnotations = (state: RootState) => {
  const {
    annotations,
    relationAnnotations,
    systemAnnotations,
    selectedAnnotators,
    labelingDatas,
  } = state.textWorkspace.textReview;
  if (selectedAnnotators.length === labelingDatas.length) {
    return {
      annotations,
      relationAnnotations,
      systemAnnotations,
    };
  }

  const annotatorFilter = ({ annotators }: { annotators?: string[] }) => {
    const annotator = (annotators || []).find((user) =>
      selectedAnnotators.includes(user)
    );
    return !!annotator;
  };

  return {
    annotations: annotations.filter(annotatorFilter),
    relationAnnotations: relationAnnotations.filter(annotatorFilter),
    systemAnnotations: systemAnnotations.filter(annotatorFilter),
  };
};

export const selectTextReviewLabelingDatas = (state: RootState) => {
  return state.textWorkspace.textReview.labelingDatas;
};

export const selectTextReviewSelectedAnnotators = (state: RootState) => {
  return state.textWorkspace.textReview.selectedAnnotators;
};

export const selectTextReviewActiveTab = (state: RootState) => {
  return state.textWorkspace.textReview.activeTab;
};

export const selectTextReviewSelectedAnnotationIds = (state: RootState) => {
  return state.textWorkspace.textReview.selectedAnnotationIds;
};

export const selectTextReviewSelectedAnnotations = (state: RootState) => {
  const {
    annotations,
    relationAnnotations,
    systemAnnotations,
    selectedAnnotationIds,
  } = state.textWorkspace.textReview;

  const { selectedAnnotators, activeTab } = state.textWorkspace.textReview;
  const isShowConflicts = selectedAnnotators.length > 1;
  const isFilterByAgree = isShowConflicts && activeTab === "agree";
  const isFilterByConflict = isShowConflicts && activeTab === "conflict";
  const relations = relationAnnotations.filter((anno) => {
    if (isFilterByAgree) {
      return !textUtils.isConflict(anno.annotators || [], selectedAnnotators);
    }
    if (isFilterByConflict) {
      return textUtils.isConflict(anno.annotators || [], selectedAnnotators);
    }
    return selectedAnnotationIds.includes(anno.id);
  });
  const annotationIds: string[] = [];
  for (const relation of relations) {
    if (!annotationIds.includes(relation.from)) {
      annotationIds.push(relation.from);
    }
    if (!annotationIds.includes(relation.to)) {
      annotationIds.push(relation.to);
    }
  }

  return {
    annotations: annotations.filter((anno) => {
      if (annotationIds.includes(anno.id)) {
        return true;
      }
      if (isFilterByAgree) {
        return !textUtils.isConflict(anno.annotators || [], selectedAnnotators);
      }
      if (isFilterByConflict) {
        return textUtils.isConflict(anno.annotators || [], selectedAnnotators);
      }
      return selectedAnnotationIds.includes(anno.id);
    }),
    relationAnnotations: relations,
    systemAnnotations: systemAnnotations.filter((anno) => {
      if (isFilterByAgree) {
        return !textUtils.isConflict(anno.annotators || [], selectedAnnotators);
      }
      if (isFilterByConflict) {
        return textUtils.isConflict(anno.annotators || [], selectedAnnotators);
      }
      return selectedAnnotationIds.includes(anno.id);
    }),
  };
};

export const selectTextReviewAnnotationInfo =
  (annotationId: string) => (state: RootState) => {
    if (annotationId.startsWith("review-")) {
      const { annotations, relationAnnotations, selectedAnnotators } =
        state.textWorkspace.textConflict;
      const annotation = collectionUtils.getOne(annotations, annotationId);
      const relation = collectionUtils.getOne(
        relationAnnotations,
        annotationId
      );
      const annotators = annotation?.annotators || relation?.annotators || [];
      return {
        annotation: annotation || relation,
        annotators,
        selectedAnnotators,
      };
    }
    const { annotations, relationAnnotations, reviewMode, selectedAnnotators } =
      state.textWorkspace.textReview;
    if (!reviewMode) return null;
    const annotation = annotations.find((anno) => anno.id === annotationId);
    const relation = relationAnnotations.find(
      (anno) => anno.id === annotationId
    );

    const annotators = annotation?.annotators || relation?.annotators || [];

    return {
      annotation: annotation || relation,
      annotators,
      selectedAnnotators,
    };
  };

export const selectTextConflictTokenIds = (state: RootState) => {
  const { annotations, relationAnnotations, selectedAnnotators } =
    state.textWorkspace.textConflict;
  const numAnnotators = selectedAnnotators.length;
  if (numAnnotators < 2) return {};
  const annotationIds: string[] = [];
  let tokenIds: Record<string, string> = {};
  for (const annotationId of relationAnnotations.allIds) {
    const annotation = relationAnnotations.entities[annotationId];
    if (!!annotation.reviewStatus) continue;
    const { annotators } = annotation;
    if (!annotators || annotators.length === 0) continue;
    if (annotators?.length !== numAnnotators) {
      annotationIds.push(annotation.from);
      annotationIds.push(annotation.to);
    }
  }
  for (const annotationId of annotations.allIds) {
    const annotation = annotations.entities[annotationId];
    if (!!annotation.reviewStatus) continue;
    const { id, annotators } = annotation;
    if (!annotators || annotators.length === 0) continue;
    if (annotators?.length !== numAnnotators || annotationIds.includes(id)) {
      if (annotation.tokenIds) {
        for (const tokenId of annotation.tokenIds) {
          if (!tokenIds.hasOwnProperty(tokenId)) {
            tokenIds[tokenId] = id;
          }
        }
      }
    }
  }
  return tokenIds;
};

export const selectTextReviewConflictTokenIds = (state: RootState) => {
  const { annotations, relationAnnotations, selectedAnnotators, activeTab } =
    state.textWorkspace.textReview;
  const isFilterByAgree = activeTab === "agree";
  if (isFilterByAgree) return {};
  const numAnnotators = selectedAnnotators.length;
  if (numAnnotators < 2) return {};
  const annotationIds: string[] = [];
  let tokenIds: Record<string, string> = {};
  for (const annotation of relationAnnotations) {
    const { annotators } = annotation;
    if (!annotators || annotators.length === 0) continue;
    if (annotators?.length !== numAnnotators) {
      annotationIds.push(annotation.from);
      annotationIds.push(annotation.to);
    }
  }
  for (const annotation of annotations) {
    const { id, annotators } = annotation;
    if (!annotators || annotators.length === 0) continue;
    if (annotators?.length !== numAnnotators || annotationIds.includes(id)) {
      if (annotation.tokenIds) {
        for (const tokenId of annotation.tokenIds) {
          if (!tokenIds.hasOwnProperty(tokenId)) {
            tokenIds[tokenId] = id;
          }
        }
      }
    }
  }
  return tokenIds;
};
