import { createAsyncThunk } from "@reduxjs/toolkit";
import { LabelingType } from "constants/labeling.constant";
import { JobOption } from "domain/common/models";
import { StorageFileDTO } from "models/dataset/storage-file.model";
import { AIAssistanceSTTService } from "services/ai-assistance-service/stt.api";
import {
  AnnotationService,
  AnnotationsService,
  BatchObservationService,
  BatchService,
  JobService,
  LabelerAnnotationService,
  ProjectServiceV2,
  TaskService,
} from "services/label-service";
import {
  BatchDTO,
  BatchObservationDTO,
  isWsiProject,
  JobDTO,
  StepType,
  SystemObservationCode,
  TaskDTO,
} from "services/label-service/dtos";
import {
  AnnotationRelationResponseDTO,
  AnnotationResponseDTO,
} from "services/label-service/dtos/annotations.dto";
import { projectMapper } from "services/label-service/mappers/project.mapper";
import { StorageService } from "services/storage";
import { AppError } from "utilities/errors/errors";
import { persistenceImageLoader } from "utilities/image-loader/indexeddb-cache";
import {
  setComplexJobsBatchJobFromComplexRes,
  setComplexJobsBatchJobs,
  setComplexJobsCurrentJobId,
  setComplexJobsError,
  setComplexJobsProject,
  SLICE_NAME_COMPLEX_JOBS,
} from "./complex-jobs.slice";
import {
  ComplexJobInBatch,
  ComplexJobInBatchStatus,
} from "./complex-jobs.state";
import * as Sentry from "@sentry/react";
import { IouAnnotation } from "../image-workspace/image-iou/image-iou.state";
import { Logger } from "utilities/logger";

export interface LoadCombplexJobResponse {
  job: JobDTO | undefined;
  isStepReviewJob: boolean;
  canViewPreviousStepResult: boolean;
  file: StorageFileDTO | undefined;
  wsiUrl: string | undefined;
  task: TaskDTO | undefined;
  batch: BatchDTO | undefined;
  batchObservations: BatchObservationDTO[];
  acceptBatchObservation: BatchObservationDTO | undefined;
  rejectBatchObservation: BatchObservationDTO | undefined;
  annotations: AnnotationResponseDTO[];
  previousJobsAnnotations: AnnotationResponseDTO[];
  annotationRelations: AnnotationRelationResponseDTO[];
  iouAnnotations?: IouAnnotation[];
  jobOptions: JobOption[];
  isTaskReview: boolean;
  isFromBatch: boolean;
  isFromJobId: boolean;
  reopenReason: string | undefined;
  cachedAITranscribedText: string | undefined;
  error: AppError | undefined;
}

interface PollComplexJobComplexAsyncPayload {
  loadBatchObservation: boolean;
  jobId?: number | string;
  job?: JobDTO;
  isFromBatch?: boolean;
}
export const pollComplexJobComplexAsync = createAsyncThunk(
  `${SLICE_NAME_COMPLEX_JOBS}/pollComplexJobComplexAsync`,
  async (
    {
      loadBatchObservation = false,
      jobId,
      job,
      isFromBatch = false,
    }: PollComplexJobComplexAsyncPayload,
    { dispatch }
  ) => {
    const loadJobRes = getEmptyResponse();
    loadJobRes.isFromBatch = isFromBatch;

    try {
      let res;
      if (job) {
        loadJobRes.job = job;
      } else if (jobId) {
        // get job from jobId
        res = await JobService.getItem(jobId);
        loadJobRes.job = res.data;
        loadJobRes.isFromJobId = true;
      }

      if (!loadJobRes.job) {
        throw new AppError("no_job", "No job found!");
      }

      loadJobRes.isStepReviewJob =
        loadJobRes.job.workInstruction.stepType === StepType.ACCEPTANCE;
      loadJobRes.canViewPreviousStepResult =
        loadJobRes.job.workInstruction.canViewPreviousStepResult;

      const doWorksThatDependOnTask = async (
        loadJobRes: LoadCombplexJobResponse
      ) => {
        if (!loadJobRes.job)
          throw new AppError("unknow", "null job in doWorkThatDependOnTask");
        // get task
        let res = await TaskService.getItem(loadJobRes.job.task.id);
        loadJobRes.task = res.data;
        if (!loadJobRes.task) {
          throw new AppError(
            "unknow",
            `Failed to load task for job ${loadJobRes.job.id}`
          );
        }
        // get batch
        loadJobRes.batch = await BatchService.getBatchById(
          loadJobRes.task.batchId
        );

        const doWorksThatDependOnFile = async (
          loadJobRes: LoadCombplexJobResponse
        ) => {
          if (!loadJobRes.task)
            throw new AppError(
              "unknow",
              "null task inside doWorksThatDependOnFile"
            );
          if (!loadJobRes.job)
            throw new AppError(
              "unknow",
              "null job inside doWorksThatDependOnFile"
            );

          // get FileInfo
          const fileId = loadJobRes.task.taskReference;
          await _getFileInfo(loadJobRes, fileId, loadJobRes.job.id);

          // cache file content
          const p2 = _cacheFileContentToLocalUrl(loadJobRes);

          // cache ai transcribed text if stt project
          const p1 = _cacheAITranscribe(loadJobRes);

          await Promise.all([p1, p2]);
        };

        const p1 = doWorksThatDependOnFile(loadJobRes);
        // get batch observations
        const p2 = _getBatchObservations(loadJobRes);
        // get system annotation (which is imported from file or generated from models)
        const p3 = _getSystemAnnotations(loadJobRes);

        await Promise.all([p1, p2, p3]);
      };

      const doWorksThatDependOnJob = async (
        loadJobRes: LoadCombplexJobResponse
      ) => {
        if (!loadJobRes.job)
          throw new AppError("unknow", "null job in doWorksThatDependOnJob");

        // Get job and previous jobs annotations
        const res = await AnnotationsService.getAnnotationsByJobId(
          loadJobRes.job.id
        );
        const allAnnotations = res.data.annotations.sort((a, b) =>
          a.jobId < b.jobId ? -1 : 1
        );
        loadJobRes.previousJobsAnnotations = allAnnotations.filter(
          (anno) => loadJobRes.job && anno.jobId !== loadJobRes.job.id
        );
        loadJobRes.jobOptions = loadJobRes.previousJobsAnnotations
          .map((anno) => ({
            jobId: anno.jobId,
            assignee: anno.assignee || "",
          }))
          .filter((e, index, self) => {
            // remove duplicate
            return index === self.findIndex((o) => o.assignee === e.assignee);
          });
        loadJobRes.annotations = allAnnotations.filter(
          (anno) => loadJobRes.job && anno.jobId === loadJobRes.job.id
        );
        // relations
        loadJobRes.annotationRelations = res.data.annotationRelations || [];
        await _getJobIoU(loadJobRes);
      };

      const p1 = doWorksThatDependOnTask(loadJobRes);
      const p2 = doWorksThatDependOnJob(loadJobRes);

      await Promise.all([p1, p2]);
    } catch (err: any) {
      Sentry.captureException(err);
      if (err instanceof AppError) {
        loadJobRes.error = err;
      } else {
        loadJobRes.error = new AppError("unknow", err.message);
      }
    }

    if (loadJobRes.isFromBatch) {
      dispatch(setComplexJobsBatchJobFromComplexRes(loadJobRes));
    }

    return loadJobRes;
  }
);

interface LoadComplexTaskToReviewAsyncPayload {
  taskToReview?: TaskDTO;
  jobIdsIncludedOnly?: number[];
}
export const loadComplexTaskToReviewAsync = createAsyncThunk(
  `${SLICE_NAME_COMPLEX_JOBS}/loadComplexTaskToReviewAsync`,
  async ({
    taskToReview,
    jobIdsIncludedOnly,
  }: LoadComplexTaskToReviewAsyncPayload) => {
    const loadJobRes = getEmptyResponse();
    loadJobRes.isTaskReview = true;

    try {
      loadJobRes.isStepReviewJob = false;
      loadJobRes.canViewPreviousStepResult = true;

      // get task
      loadJobRes.task = taskToReview;
      if (!loadJobRes.task)
        throw new AppError("unknow", "null taskToReview in loadTaskToReview");
      // get batch
      loadJobRes.batch = await BatchService.getBatchById(
        loadJobRes.task.batchId
      );

      const doWorksThatDependOnFile = async (
        loadJobRes: LoadCombplexJobResponse
      ) => {
        if (!loadJobRes.task)
          throw new AppError(
            "unknow",
            "null task inside doWorksThatDependOnFile"
          );
        // get FileInfo
        const fileId = loadJobRes.task.taskReference;
        await _getFileInfo(loadJobRes, fileId);

        // cache file content
        await _cacheFileContentToLocalUrl(loadJobRes);
      };

      const getTaskObservationsByTaskId = async (
        loadJobRes: LoadCombplexJobResponse
      ) => {
        if (!loadJobRes.task)
          throw new AppError(
            "unknow",
            "null task inside getTaskObservationsByTaskId"
          );
        const jobsResponse = await JobService.getItems({
          taskId: loadJobRes.task.id.toString(),
        });
        const jobs: JobDTO[] = jobsResponse.data;
        const jobIds = jobs
          .sort((a, b) => {
            const aIndex = a.workInstruction.step;
            const bIndex = b.workInstruction.step;
            const diff = aIndex - bIndex;
            if (diff === 0) {
              return (
                a.workInstruction.roundNumber - b.workInstruction.roundNumber
              );
            }
            return diff;
          })
          .map((job) => job.id);

        // get all annotations
        const res = await AnnotationsService.getAnnotationsByTaskId(
          loadJobRes.task.id
        );
        const allAnnotations = res.data.annotations.sort((a, b) => {
          const aIndex = a.jobId ? jobIds.indexOf(a.jobId) : 1000;
          const bIndex = b.jobId ? jobIds.indexOf(b.jobId) : 1000;
          const diff = aIndex - bIndex;
          if (diff === 0) return a.jobId - b.jobId;
          return diff;
        });
        loadJobRes.previousJobsAnnotations = allAnnotations;
        loadJobRes.jobOptions = loadJobRes.previousJobsAnnotations
          .map((anno) => ({
            jobId: anno.jobId,
            assignee: anno.assignee || "",
          }))
          .filter((e, index, self) => {
            // remove duplicate
            return index === self.findIndex((o) => o.assignee === e.assignee);
          });
        if (jobIdsIncludedOnly) {
          loadJobRes.previousJobsAnnotations = allAnnotations.filter(
            (anno) => !!jobIdsIncludedOnly.includes(anno.jobId)
          );
        }
        // relations
        loadJobRes.annotationRelations = res.data.annotationRelations || [];
      };

      const p1 = doWorksThatDependOnFile(loadJobRes);
      const p2 = getTaskObservationsByTaskId(loadJobRes);
      // get system annotation (which is imported from file or generated from model)
      const p3 = _getSystemAnnotations(loadJobRes);
      // get batch observations
      const p4 = _getBatchObservations(loadJobRes);
      const p5 = _getTaskIoU(loadJobRes);

      await Promise.all([p1, p2, p3, p4, p5]);
    } catch (err: any) {
      Sentry.captureException(err);
      if (err instanceof AppError) {
        loadJobRes.error = err;
      } else {
        loadJobRes.error = new AppError("unknow", err.message);
      }
    }

    return loadJobRes;
  }
);

interface PollComplexBatchJobsPayload {
  projectId: string | number;
  limit?: number;
  loadBatchObservation?: boolean;
}
export const pollComplexBatchJobsAsync = createAsyncThunk(
  `${SLICE_NAME_COMPLEX_JOBS}/pollComplexBatchJobsAsync`,
  async (
    {
      projectId,
      limit = 10,
      loadBatchObservation = false,
    }: PollComplexBatchJobsPayload,
    { dispatch }
  ) => {
    try {
      const project = projectMapper.fromDTO(
        (await ProjectServiceV2.getItem(projectId)).data.project
      );
      if (project) {
        dispatch(setComplexJobsProject(project));
      }

      let res = await JobService.pollJobByProject(projectId, limit);
      const jobs = res.data as JobDTO[];
      if (jobs.length <= 0) {
        const error = new AppError("no_job", "No job found!");
        dispatch(setComplexJobsError(error));
        return;
      }

      const batchJobs: ComplexJobInBatch[] = [];
      for (const job of jobs) {
        batchJobs.push({
          id: job.id,
          status: ComplexJobInBatchStatus.LOADING,
          loadJobComplexRes: undefined,
        });
      }
      dispatch(setComplexJobsBatchJobs(batchJobs));
      dispatch(setComplexJobsCurrentJobId(jobs[0].id));
      for (const job of jobs) {
        // if we want to poll in parallel just remove await.
        await dispatch(
          pollComplexJobComplexAsync({
            loadBatchObservation,
            job,
            isFromBatch: true,
          })
        );
      }
    } catch (e: any) {
      Sentry.captureException(e);
      const error = new AppError("unknow", e.message);
      dispatch(setComplexJobsError(error));
    }
  }
);

const getEmptyResponse = (): LoadCombplexJobResponse => {
  return {
    job: undefined,
    isStepReviewJob: false,
    canViewPreviousStepResult: false,
    file: undefined,
    wsiUrl: undefined,
    task: undefined,
    batch: undefined,
    batchObservations: [],
    acceptBatchObservation: undefined,
    rejectBatchObservation: undefined,
    annotations: [],
    previousJobsAnnotations: [],
    annotationRelations: [],
    jobOptions: [],
    isTaskReview: false,
    isFromBatch: false,
    isFromJobId: false,
    reopenReason: undefined,
    cachedAITranscribedText: undefined,
    error: undefined,
  };
};

const _cacheFileContentToLocalUrl = async (
  loadJobRes: LoadCombplexJobResponse
) => {
  if (!loadJobRes.file) return;
  // No need to download for wsi project
  // because we get deepzoom url later
  if (!loadJobRes.batch || isWsiProject(loadJobRes.batch.project.type)) return;

  const urlWithSas = await StorageService.getSasUrl(loadJobRes.file, loadJobRes.batch?.project.workspaceId);
  let blob: Blob;
  try {
    blob = await persistenceImageLoader(urlWithSas);
  } catch (error) {
    Sentry.captureException(error);
    const res = await StorageService.getPublicFileContentAsBlob(urlWithSas);
    blob = res.data as Blob;
  }
  if (blob) {
    const localUrl = URL.createObjectURL(blob);
    loadJobRes.file = {
      ...loadJobRes.file,
      url: localUrl,
      originalUrl: urlWithSas,
    };
  }
};

const _cacheAITranscribe = async (loadJobRes: LoadCombplexJobResponse) => {
  if (!loadJobRes.file || !loadJobRes.batch?.project) return;
  if (loadJobRes.batch.project.type !== LabelingType.AUDIO_STT) return;
  try {
    const sasUrl = await StorageService.getSasUrl(
      loadJobRes.file,
      loadJobRes.batch.workspaceId
    );
    const res = await AIAssistanceSTTService.transcribeAudio(sasUrl);
    loadJobRes.cachedAITranscribedText = res.data.text || "";
  } catch (error: any) {
    Sentry.captureException(error);
    console.log(error);
    loadJobRes.cachedAITranscribedText = "";
  }
};

const _getFileInfo = async (
  loadJobRes: LoadCombplexJobResponse,
  fileId: string,
  jobId?: number
) => {
  if (!loadJobRes.batch) return;

  try {
    const res = await StorageService.getFileInfoDetails(fileId);
    loadJobRes.file = res.data;

    // Get wsi url like deepzoom link file
    if (isWsiProject(loadJobRes.batch.project.type)) {
      const res = await StorageService.initFileInfo(loadJobRes.file.id);
      loadJobRes.wsiUrl = res.data.servingUrl;
    }
  } catch (err: any) {
    Sentry.captureException(err);
    if (err.response.status === 404) {
      throw new AppError("skippable", `File ${fileId} not found!`, jobId);
    } else {
      throw err;
    }
  }
  if (!loadJobRes.file) {
    throw new AppError(
      "unknow",
      `Failed to load FileInfo ${fileId} for job ${jobId}`
    );
  }
  if (!loadJobRes.file.url) {
    throw new AppError("skippable", `File ${fileId} has null url`, jobId);
  }
};

const _getSystemAnnotations = async (loadJobRes: LoadCombplexJobResponse) => {
  if (!loadJobRes.batch || !loadJobRes.task)
    throw new AppError(
      "unknow",
      "null batch, file inside _getSystemAnnotations"
    );

  const res = await AnnotationsService.getAnnotations({
    "batchId.equals": loadJobRes.batch.id.toString(),
    "mediaId.equals": loadJobRes.task.taskReference.toString(),
    "source.equals": "Import",
  });
  if (res.data) {
    loadJobRes.previousJobsAnnotations = res.data.annotations.concat(
      loadJobRes.previousJobsAnnotations
    );
    if (loadJobRes.previousJobsAnnotations.length > 0) {
      loadJobRes.canViewPreviousStepResult = true;
    }
  }
};

const _getBatchObservations = async (loadJobRes: LoadCombplexJobResponse) => {
  if (!loadJobRes.batch)
    throw new AppError("unknow", "null batch inside _getBatchObservations");

  const res = await BatchObservationService.getItems({
    batchId: loadJobRes.batch.id.toString(),
    size: "500",
  });
  const data = res.data as BatchObservationDTO[];
  loadJobRes.batchObservations = data.filter(
    (bo) => !bo.observation.observationSetting.systemAttribute
  );
  loadJobRes.acceptBatchObservation = data.filter(
    (bo) => SystemObservationCode.ACCEPT === bo.observation.code
  )[0];
  loadJobRes.rejectBatchObservation = data.filter(
    (bo) => SystemObservationCode.REJECT === bo.observation.code
  )[0];
};

const _getTaskIoU = async (loadJobRes: LoadCombplexJobResponse) => {
  try {
    if (!loadJobRes.batch || !loadJobRes.task)
      throw new AppError(
        "unknow",
        "null batch, file inside _getSystemAnnotations"
      );

    const res = await AnnotationService.getIouAnnotationsByTask(
      loadJobRes.task.id
    );

    loadJobRes.iouAnnotations = res.data;
  } catch (error) {
    Logger.log(error);
  }
};

const _getJobIoU = async (loadJobRes: LoadCombplexJobResponse) => {
  try {
    if (!loadJobRes.job)
      throw new AppError(
        "unknow",
        "null batch, file inside _getSystemAnnotations"
      );

    const res = await LabelerAnnotationService.getIoULabeler(loadJobRes.job.id);

    loadJobRes.iouAnnotations = res.data;
  } catch (error) {
    Logger.log(error);
  }
};
