/*
 * File: batch-data.slice.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 10:53:49 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice } from "@reduxjs/toolkit";
import { BatchDataState, INITIAL_BATCH_DATA_STATE } from "./batch-data.state";
import { labelDistributionReducerBuilder } from "./label-distribution/label-distribution.thunk";
import { labelerDistributionReducerBuilder } from "./labeler-distribution/labeler-distribution.thunk";
import { batchStatisticReducers } from "./statistic/batch-statistic.slice";
import { batchStatisticReducerBuilder } from "./statistic/batch-statistic.thunk";

const SLICE_NAME: string = "batchData";
const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_BATCH_DATA_STATE,
  reducers: {
    resetBatchData(state: BatchDataState) {
      Object.assign(state, INITIAL_BATCH_DATA_STATE);
    },
    ...batchStatisticReducers,
  },
  extraReducers: (builder) => {
    batchStatisticReducerBuilder(builder);
    labelDistributionReducerBuilder(builder);
    labelerDistributionReducerBuilder(builder);
  },
});

export const {
  resetBatchData,
  removeStatistic,
  setBatchStatistics,
  addStatistic,
} = slice.actions;

export const batchDataReducer = slice.reducer;
