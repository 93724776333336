import { JobOption } from "domain/common/models";
import {
  BatchDTO,
  BatchObservationDTO,
  JobDTO,
  ObservationDTO,
  ProjectDTO,
  TaskDTO,
} from "services/label-service/dtos";
import { AnnotationResponseDTO } from "services/label-service/dtos/annotations.dto";
import {
  SttTextLabel,
  TaskObservationV3DTO,
} from "services/label-service/dtos/task-observation-v3.dto";
import { AppError } from "utilities/errors/errors";

export interface SpeechToTextLabelUIModel {
  label: SttTextLabel | undefined;
  observation: ObservationDTO;
  selected: boolean;
  showChildren: boolean;
  visible: boolean;
}

// common ui job model for speech to text and text to speech
export interface ComplexJobUIModel {
  isTaskReview: boolean;
  job: JobDTO | undefined;
  isStepReviewJob: boolean;
  canViewPreviousStepResult: boolean;
  acceptBatchObservation: BatchObservationDTO | undefined;
  rejectBatchObservation: BatchObservationDTO | undefined;
  task: TaskDTO | undefined;
  project: ProjectDTO | undefined;
  batch: BatchDTO | undefined;
  countDownSecond: number;
  previousJobsTaskObservations: TaskObservationV3DTO[];
  previousJobsAnnotations: AnnotationResponseDTO[];
  jobOptions: JobOption[];
  probability?: number;
  observationId?: number;
  reopenReason?: string;
  isFromBatch?: boolean;
  isFromJobId?: boolean;
}
export interface SpeechToTextJobUIModel extends ComplexJobUIModel {
  audioUrl: string;
  fileSasUrl: string;
  text: string;
  note: string;
  labels: SpeechToTextLabelUIModel[];
  batchObservations: BatchObservationDTO[];
  cachedAITranscribedText: undefined | string;
}

// common sate for sppech to text and text to speech
export interface ComplexLabelingState {
  isLoadingJob: boolean;
  isSavingJob: boolean;
  saveJob: (
    force?: boolean,
    finish?: boolean,
    accept?: boolean,
    reject?: boolean,
    jobId?: number
  ) => Promise<void>;
  skipJob: (jobId: number) => void;
  handleConfirmExit: () => void;
  error: AppError | undefined;
  setError: (error: AppError | undefined) => void;
  hasChanged: boolean;
  setHasChanged: (v: boolean) => void;
}
export interface SpeechToTextLabelingState extends ComplexLabelingState {
  uiJob: SpeechToTextJobUIModel | undefined;
  setUIJob: (job: SpeechToTextJobUIModel) => void;
  useAITranscribe: boolean;
  setUseAITranscribe: (v: boolean) => void;
  isTranscribing: boolean;
  isAudioReady: boolean;
  setIsAudioReady: (v: boolean) => void;
  overwriteUIJob: (annotation: AnnotationResponseDTO | undefined) => void;
}

export const DEFAULT_UI_JOB: SpeechToTextJobUIModel = {
  audioUrl: "",
  fileSasUrl: "",
  text: "",
  note: "",
  probability: 1,
  labels: [],
  batchObservations: [],
  isTaskReview: false,
  job: undefined,
  isStepReviewJob: false,
  canViewPreviousStepResult: false,
  acceptBatchObservation: undefined,
  rejectBatchObservation: undefined,
  task: undefined,
  batch: undefined,
  project: undefined,
  countDownSecond: 0,
  previousJobsTaskObservations: [],
  previousJobsAnnotations: [],
  jobOptions: [],
  cachedAITranscribedText: undefined,
};

export const defaultSpeechToTextLabelingState = {} as SpeechToTextLabelingState;