/*
 * File: labeler-projects.page.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 4th August 2021 10:39:57 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import EntityTable from "components/common/table/entity-table/entity-table";
import { useLabelerTaskColumns } from "./hooks/use-labeler-task-columns";
import { useLabelerTasksContext } from "./context";
import EntityActions from "components/common/table/entity-actions/entity-actions";
import { EntityAction } from "domain/common/entity-action";
import { VscEye } from "react-icons/vsc";
import classnames from "classnames";
import { useMemo } from "react";
import { routesMap } from "../labeler-projects/components/open-project/open-project.component";
import { Link } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { isMDIProject } from "services/label-service/dtos";

export const LabelerProjectHistoryPage = () => {
  const {
    project,
    filter,
    loading,
    entities,
    selectedIds,
    onSelect,
    onSelectEntities,
    updateFilter,
  } = useLabelerTasksContext();
  const { columns } = useLabelerTaskColumns();

  const projectUrl = useMemo(() => {
    try {
      if (!project) return undefined;
      const projectType = project.type;
      if (routesMap.hasOwnProperty(projectType)) {
        return routesMap[projectType].replace(
          ":projectId",
          project.id.toString()
        );
      }
    } catch (error) {
      return undefined;
    }
  }, [project]);

  return (
    <div className="flex flex-col items-center w-full mb-8 bg-background-50">
      <div className="w-full px-6 py-2">
        <div className="flex items-center gap-2">
          <Link to={Routes.LABELER_PROJECTS}>
            <span className="text-lg font-bold text-warning-500">
              My Projects
            </span>
          </Link>
          <span className="text-gray-500">/</span>
          <span className="text-gray-500">{project?.name}</span>
          <span className="flex-auto" />
          {projectUrl && (
            <Link to={projectUrl}>
              <button
                className={classnames(
                  "flex items-center h-8 gap-1.5 px-3 mx-4 my-4 rounded-full border border-white hover:border-warning",
                  {
                    "text-warning-500 bg-warning-50": !!project?.available,
                    "pointer-events-none text-background-500":
                      !project?.available,
                  }
                )}
              >
                <span>
                  {project?.available ? "Start labeling" : "Comming soon"}
                </span>
                <i className="text-xl uir-arrow-small-right"></i>
              </button>
            </Link>
          )}
        </div>
        {!isMDIProject(project?.type) && (
          <EntityActions
            actions={[
              {
                id: EntityAction.VIEW,
                text: "View Detail",
                icon: VscEye,
                onClick: () => {
                  const selectedEntities = entities.filter((entity) =>
                    selectedIds.includes(entity.id)
                  );
                  onSelectEntities(selectedEntities, EntityAction.VIEW);
                },
              },
            ]}
            entities={entities}
            selectedIds={selectedIds}
          />
        )}

        <EntityTable
          loading={loading}
          entities={entities}
          columns={columns}
          filter={filter}
          onSelect={onSelect}
          onUpdateFilter={updateFilter}
        />
        {/* <Pagination
          pagination={pagination}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
        /> */}
      </div>
    </div>
  );
};
