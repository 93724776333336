/*
 * File: firebase-app.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 15th September 2021 2:37:51 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import firebase from "firebase/app";
import "firebase/messaging";

import {
  FIREBASE_CONFIG,
  FIREBASE_MESSAGING_CERTIFICATE,
} from "./firebase.config";

const app = firebase.initializeApp(FIREBASE_CONFIG);

export const isFirebaseMessagingSupported = firebase.messaging.isSupported();

export const getFirebaseMessagingToken = () => {
  return app.messaging().getToken({
    vapidKey: FIREBASE_MESSAGING_CERTIFICATE,
  });
};

export const removeFirebaseMessagingToken = () => {
  return Promise.resolve(true);
  // return app.messaging().deleteToken();
};

export default app;
