/*
 * File: use-exported-dataset-filter.hook.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 1st December 2022 10:38:12 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { SearchParams } from "models/common/search-params";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

const DEFAULT_PAGE = 1;
const DEFAULT_SIZE = 10;
const DEFAULT_SCOPE = -1;

interface ExportedDatasetFilter {
  page: number;
  size: number;
  scope: number;
}
export default function useExportedDatasetFilter() {
  const history = useHistory();
  const location = useLocation();

  const filter = useMemo<ExportedDatasetFilter>(() => {
    const urlSearchParams = new URLSearchParams(location.search ?? "");
    function getQueryParam(key: string, defaultValue: string) {
      const value = urlSearchParams.get(key);
      return value ?? defaultValue;
    }
    const page = getQueryParam(SearchParams.PAGE, DEFAULT_PAGE.toString());
    const size = getQueryParam(SearchParams.SIZE, DEFAULT_SIZE.toString());
    const scope = getQueryParam(SearchParams.SCOPE, DEFAULT_SCOPE.toString());
    return {
      page: parseInt(page),
      size: parseInt(size),
      scope: parseInt(scope),
    };
  }, [location.search]);

  function setFilterParams(
    items: { query: string; value: string }[],
    exact = false
  ) {
    const searchParams = exact ? "" : location.search;
    const urlSearchParams = new URLSearchParams(searchParams);

    for (let pair of items) {
      const { query, value } = pair;
      if (typeof value === "object" && Array.isArray(value)) {
        urlSearchParams.delete(query);
        for (const val of value as any) {
          urlSearchParams.append(query, val);
        }
        continue;
      }

      if (value) {
        if (urlSearchParams.has(query)) {
          urlSearchParams.set(query, value);
        } else {
          urlSearchParams.append(query, value);
        }
      } else {
        urlSearchParams.delete(query);
      }
    }

    const newLocation = {
      ...location,
      search: urlSearchParams.toString(),
      hash: "",
    };
    history.push(newLocation);
  }

  function setFilterParam(
    key: string,
    value: any,
    exact: boolean = false,
    addIfExists: boolean = false
  ) {
    const searchParams = exact ? "" : location.search;
    const urlSearchParams = new URLSearchParams(searchParams);
    if (value) {
      if (addIfExists) {
        urlSearchParams.append(key, value);
      } else {
        urlSearchParams.set(key, value);
      }
    } else {
      urlSearchParams.delete(key);
    }
    const newLocation = { ...location, search: urlSearchParams.toString() };
    history.push(newLocation);
  }
  return { filter, setFilterParam, setFilterParams };
}
