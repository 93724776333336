/*
 * File: text-smart-labeling.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 11th May 2022 11:37:25 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Popover, Tooltip } from "@material-ui/core";
import { IconAndroid } from "components/common/vb-icon.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useRef, useState } from "react";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { selectTextAIData } from "store/labeler/text-workspace/text-ai/text-ai.selectors";
import { TextLabelPair } from "store/labeler/text-workspace/text-ai/text-ai.state";
import {
  autoAnnotateTextAsync,
  initTextAIAsync,
} from "store/labeler/text-workspace/text-ai/text-ai.thunk";
import { selectTextLabelingJob } from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { aiAnnotationsGenerated } from "store/labeler/text-workspace/text-workspace.slice";
import { TextAIModal } from "./text-ai-modal.component";
import * as Sentry from "@sentry/react";

export function TextAIComponent() {
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const workingJob = useAppSelector(selectTextLabelingJob);
  const textAIData = useAppSelector(selectTextAIData);
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  function toggleTextAI() {
    setOpen(!open);
  }

  async function handleSubmit(pairs: TextLabelPair[]) {
    try {
      setProcessing(true);
      const response = await dispatch(autoAnnotateTextAsync(pairs));
      const data = response.payload as {
        observationId: number;
        startIndex: number;
        endIndex: number;
      }[];
      if (data && data.length > 0) {
        dispatch(aiAnnotationsGenerated(data));
        dispatch(
          enqueueSuccessNotification("Generate annotations successfully!")
        );
      } else {
        dispatch(enqueueErrorNotification("No annotations found!"));
      }
      setOpen(false);
      setProcessing(false);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      setOpen(false);
      setProcessing(false);
    } finally {
    }
  }

  useEffect(() => {
    if (workingJob && workingJob.id !== -1) {
      dispatch(initTextAIAsync(workingJob?.id || -1));
    }
  }, [workingJob, dispatch]);

  return (
    <div className="relative" ref={containerRef}>
      <Tooltip title="AI Assistance">
        <button
          className="flex items-center justify-center rounded w-9 h-9 hover:bg-primary-100 text-primary"
          onClick={toggleTextAI}
        >
          <IconAndroid className="w-6 h-6" />
        </button>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={containerRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <TextAIModal
          textLabels={textAIData.textLabels}
          aiLabels={textAIData.aiLabels}
          labelPairs={textAIData.labelPairs}
          onClose={() => setOpen(false)}
          onSubmit={handleSubmit}
          processing={processing}
        />
      </Popover>
    </div>
  );
}
