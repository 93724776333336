/*
 * File: batch.api.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 20th July 2021 3:14:35 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AxiosResponse } from "axios";
import {
  GenerateAnnotationRequest,
  GenerateAnnotationResponse,
} from "../dtos/annotation.dto";
import { PricingRequestDTO } from "../dtos/batch-pricing.dto";
import { BatchWorkflowDTO } from "../dtos/batch-workflow.dto";
import { BatchDTO, BatchSetting, BatchType } from "../dtos/batch.dto";
import { BaseAPI } from "./base.api";

export interface CreateBatchPayload {
  workflowBatch?: Partial<BatchWorkflowDTO>;
  autoCreateTask?: boolean | null;
  batch?: {
    code?: string;
    description?: string;
    dueDate?: string | null;
    instruction?: string;
    embeddedType?: string;
    embeddedContent?: string;
    name?: string;
    type?: BatchType;
    priority?: number;
    projectId?: number;
    batchSetting?: BatchSetting;
  };
  batchObservation?: {
    iou?: number;
    labelRequired?: boolean;
    probabilityRequired?: boolean;
    observationId?: number;
  }[];
  datasetCriteria?: {
    action?: string;
    createdBy?: string;
    datasetIds?: number[];
    fileName?: string;
    fromTimeMs?: number;
    mediaIds?: number[];
    scopeId?: number;
    tagIds?: number[];
    toTimeMs?: number;
    type?: string;
  };
  workManagement?: CreateBatchPayloadWorkManagement[];
  workflowId?: number;
  pricing?: PricingRequestDTO;
}

export interface CreateBatchPayloadWorkManagement {
  userId?: string;
  workInstructionId?: number;
  dailyLimit?: number;
  limit?: number;
  percentage?: number;
  reviewPickType?: string;
  acceptPercentage?: number;
}

export interface ExportDatasetPayload {
  name: string;
  description: string;
  sourceDatasetId: number;
  targetDatasetVersionId?: number;
  action?: string;
  format?: string;
}
export interface DownloadDatasetPayload {
  jobDownloadType: string;
  format?: string;
  fileName: string;
  saveImage?: boolean;
  taskId?: number[];
  annotationId?: number[];
  assignee?: string[];
  source?: string[];
}
export class BatchAPI extends BaseAPI<BatchDTO> {
  getStatistic(itemId: number) {
    return this.get(`${this.getPath()}/${itemId}/statistic`);
  }
  getDistribution(itemId: number) {
    return this.get(`${this.getPath()}/${itemId}/label-distribution`);
  }

  getF1Distribution(itemId: number) {
    return this.get(`${this.getPath()}/${itemId}/f1`);
  }

  distributeDataset(
    itemId: number,
    payload: { distributions: Record<string, number> }
  ) {
    return this.post(`${this.getPath()}/${itemId}/distribute`, payload);
  }

  createExportedDataset(itemId: number, payload: ExportDatasetPayload) {
    return this.post(
      `${this.getPath()}/export-dataset?batchId=${itemId}`,
      payload
    );
  }

  downloadDataset(itemId: number, payload: DownloadDatasetPayload) {
    return this.post(`${this.getPath()}/${itemId}/download-dataset`, payload);
  }

  createBatch(item: CreateBatchPayload) {
    const data = JSON.stringify(item);
    return this.post(this.getPath(), data);
  }

  updateBatchManagement(batchId: number, item: Partial<CreateBatchPayload>) {
    const data = JSON.stringify(item);
    return this.patch(`${this.getPath()}/${batchId}/update`, data);
  }

  updateBatch(batchId: number, item: Partial<BatchDTO>) {
    const data = JSON.stringify(item);
    return this.patch(`${this.getPath()}/${batchId}`, data);
  }

  updateStatus(batchId: number | string, status: string) {
    return this.post(`${this.getPath()}/${batchId}/update-status`, { status });
  }

  annotateBatch(
    batchId: number | string,
    payload: GenerateAnnotationRequest
  ): Promise<AxiosResponse<GenerateAnnotationResponse>> {
    return this.post(`${this.getPath()}/${batchId}/annotating`, payload);
  }

  async getBatchById(batchId: number): Promise<BatchDTO> {
    const response = await this.getItem(batchId);
    return response?.data?.batch;
  }
}
