/*
 * File: use-dataset-options.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th June 2023 9:05:22 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Option } from "domain/common";
import { useEffect, useMemo, useState } from "react";

export function useMoveFilesOptions(defaultValue = "") {
  const options = useMemo<Option[]>(() => {
    return [
      { label: "Copy", value: "copy" },
      { label: "Cut", value: "cut" },
    ];
  }, []);

  const [value, setValue] = useState(defaultValue);

  const option = useMemo(() => {
    return options.find((item) => item.value === value);
  }, [options, value]);

  function onChange(newOption?: any) {
    if (newOption && newOption.hasOwnProperty("value")) {
      setValue(newOption["value"]);
    } else {
      setValue("");
    }
  }

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return { options, value, setValue, option, onChange };
}

export default useMoveFilesOptions;
