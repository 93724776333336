import { useTranslation } from "react-i18next";
import { classnames } from "utilities/classes";

export interface BatchEstimationPriceUIModel {
  totalJob: string;
  estimateCost: string;
  fees: string;
  feeRatio: string;
  total: string;
  currencyCode: string;
}

export interface BatchEstimationPriceComponentProps {
  title: string;
  data: BatchEstimationPriceUIModel;
}

export const BatchEstimationPriceComponent = ({
  title,
  data,
}: BatchEstimationPriceComponentProps) => {
  const { t } = useTranslation();
  const { totalJob, estimateCost, feeRatio, fees, total } = data;

  return (
    <div className="flex flex-col w-full gap-4 p-4 bg-white border rounded">
      <p className="text-primary">{title}</p>
      <BatchEstimationPriceItem
        label={t("project:batchDetails.price.estimationResult.labelTotalJob")}
        value={totalJob}
      />
      <BatchEstimationPriceItem
        label={t(
          "project:batchDetails.price.estimationResult.labelEstimateCost"
        )}
        value={estimateCost}
      />
      <BatchEstimationPriceItem
        label={t("project:batchDetails.price.estimationResult.labelFees")}
        hint={`${feeRatio}% per job completed`}
        value={fees}
      />
      <BatchEstimationPriceItem
        label={t("project:batchDetails.price.estimationResult.labelTotal")}
        value={total}
        valueClasseName="bg-secondary-100 text-primary"
      />
    </div>
  );
};

interface BatchEstimationPriceItemProps {
  label: string;
  hint?: string;
  value: string;
  valueClasseName?: string;
}
export const BatchEstimationPriceItem = ({
  label,
  hint = "",
  value,
  valueClasseName = "bg-background-100",
}: BatchEstimationPriceItemProps) => {
  return (
    <div className="flex items-center w-full">
      <div style={{ width: "40%" }}>
        <p>{label}</p>
        {hint && <p className="text-sm text-background-500">{hint}</p>}
      </div>
      <div
        className={classnames(
          "flex flex-row-reverse flex-1 py-2 pr-2",
          valueClasseName
        )}
      >
        <span>{value}</span>
      </div>
    </div>
  );
};
