/*
 * File: batch-label-grid.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 9:22:15 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import {
  BatchDTO,
  BatchObservationDTO,
  BatchStatus,
} from "services/label-service/dtos";
import { classnames } from "utilities/classes";

import { ReactSortable, ReactSortableProps } from "react-sortablejs";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { IconArrange } from "components/common/vb-icon.component";
import "./batch-label-tree.style.scss";
import { ObservationTreeNodeModel } from "services/label-service/model/observation-tree-node.model";
import {
  TreeTableNameCell,
  TreeTableShowChildrenCell,
} from "pages/customer/projects/components/tree-table-layout.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { updateBatchManagementAsync } from "store/customer/batch/batch.thunk";
import { selectIsMdiSegmentation } from "store/customer/project/project.selectors";

export class FixedReactSortable<
  T extends ObservationTreeNodeModel<BatchObservationDTO>
> extends ReactSortable<T> {
  componentDidUpdate(prevProps: ReactSortableProps<T>): void {
    if (this["sortable"] && prevProps.disabled !== this.props.disabled) {
      this["sortable"].option("disabled", this.props.disabled);
    }
  }
}

interface ColDef {
  field: string;
  headerName: string;
  headerClassName?: string;
  flex: number;
  renderCell: (
    params: BatchObservationDTO,
    node: ObservationTreeNodeModel<BatchObservationDTO>
  ) => ReactNode;
}

interface Props {
  rootNode: ObservationTreeNodeModel<BatchObservationDTO>;
  batch: BatchDTO | null;
  setShowChildren?: (nodeId: number, showChildren: boolean) => void;
  updateNodeChildrenOrder?: (nodeId: number, orderedItemIds: number[]) => any;
  onSelect?: (
    node: ObservationTreeNodeModel<BatchObservationDTO>,
    action?: string
  ) => void;
}

export const BatchLabelTree = ({
  rootNode,
  batch,
  setShowChildren,
  updateNodeChildrenOrder,
  onSelect,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isBatchCompleted = useMemo(() => {
    return batch?.status === BatchStatus.COMPLETED;
  }, [batch]);
  const isMdiProject = useAppSelector(selectIsMdiSegmentation);
  if (!batch) return null;

  const columns: ColDef[] = [
    {
      field: "name",
      headerName: t("project:batchDetails.batchLabels.table.headerName"),
      headerClassName: "pl-2",
      flex: 1,
      renderCell: (
        params: BatchObservationDTO,
        node: ObservationTreeNodeModel<BatchObservationDTO>
      ) => (
        <TreeTableNameCell
          name={params.observation.name}
          nodeHeight={node.height}
          paddingScaler={4}
        />
      ),
    },
    {
      field: "expand",
      headerName: "",
      flex: 0.2,
      renderCell: (
        params: BatchObservationDTO,
        node: ObservationTreeNodeModel<BatchObservationDTO>
      ) => (
        <TreeTableShowChildrenCell
          isLeaf={ObservationTreeNodeModel.isLeaf(node)}
          showChildren={node.showChildren}
          nodeId={node.id}
          setShowChildren={setShowChildren}
        />
      ),
    },
    {
      field: "maskId",
      headerName: t("project:batchDetails.batchLabels.table.headerMaskId"),
      flex: isMdiProject ? 0.2 : 0,
      renderCell: (
        params: BatchObservationDTO,
        node: ObservationTreeNodeModel<BatchObservationDTO>
      ) => <div>{params.observation.observationSetting.maskId || "-"}</div>,
    },
    {
      field: "color",
      headerName: t("project:batchDetails.batchLabels.table.headerColor"),
      flex: 0.2,
      renderCell: (params: BatchObservationDTO) => (
        <input
          disabled
          value={params.observation.observationSetting.color?.toString()}
          type="color"
          className="w-8 h-8 border-none"
        />
      ),
    },

    {
      field: "type",
      headerName: t(
        "project:batchDetails.batchLabels.table.headerAnnotationType"
      ),
      flex: 0.5,
      renderCell: (params: BatchObservationDTO) => (
        <div>{params.observation.observationSetting.annotationType}</div>
      ),
    },
    {
      field: "action",
      headerName: t("project:batchDetails.batchLabels.table.headerAction"),
      flex: 0.2,
      headerClassName: "text-right pr-4",
      renderCell: (
        params: BatchObservationDTO,
        node: ObservationTreeNodeModel<BatchObservationDTO>
      ) => {
        if (
          params.observation.observationSetting?.systemAttribute ||
          isBatchCompleted
        )
          return null;
        return (
          <div className="flex items-center justify-end gap-2 pr-2">
            <IconArrange className="w-6 h-6 cursor-move handle" />
            <button
              onClick={() => onSelect && onSelect(node, "remove")}
              className="px-2 text-lg hover:text-red-500"
            >
              <i className="uir-trash"></i>
            </button>
          </div>
        );
      },
    },
  ];

  const handleOnDragEnd = async (
    node: ObservationTreeNodeModel<BatchObservationDTO>,
    sortedItemIds: number[]
  ) => {
    if (updateNodeChildrenOrder) {
      const newNodesList: ObservationTreeNodeModel<BatchObservationDTO>[] =
        updateNodeChildrenOrder(node.id, sortedItemIds);
      const newBatchObs = [];
      for (let node of newNodesList) {
        if (!node.value) continue;
        newBatchObs.push(node.value);
      }
      const payload = {
        batchId: batch.id,
        payload: {
          batchObservation: newBatchObs.map((bo) => ({
            probabilityRequired: bo.probabilityRequired,
            observationId: bo.observation.id,
            labelRequired: bo.labelRequired,
            iou: bo.iou,
          })),
        },
        updateState: true,
      };
      await dispatch(updateBatchManagementAsync(payload));
    }
  };

  return (
    <div className="w-full text-sm bg-white">
      <div
        className="flex items-center w-full gap-2 bg-background-200 mb-3 rounded"
        style={{ minHeight: "3rem" }}
      >
        {columns.map((column) => {
          return (
            <div
              key={column.field}
              style={{ flex: column.flex }}
              className={classnames(
                "break-words overflow-hidden h-full font-bold",
                column.headerClassName || "",
                { hidden: column.flex === 0 }
              )}
            >
              {column.headerName}
            </div>
          );
        })}
      </div>
      <div className="border-2 rounded-sm">
      <BatchLabelNode
        node={rootNode}
        onDragEnd={handleOnDragEnd}
        columns={columns}
      />
      </div>

    </div>
  );
};

interface BatchLabelNodeProps {
  node: ObservationTreeNodeModel<BatchObservationDTO>;
  columns: ColDef[];
  onDragEnd: (
    node: ObservationTreeNodeModel<BatchObservationDTO>,
    sortedItemIds: number[]
  ) => void;
}
export const BatchLabelNode = ({
  node,
  onDragEnd,
  columns,
}: BatchLabelNodeProps) => {
  const [sortedItems, setSortedItems] = useState(node.children);

  useEffect(() => {
    setSortedItems(node.children);
  }, [node]);

  const handleDragEnd = (_: any) => {
    onDragEnd &&
      onDragEnd(
        node,
        sortedItems.map((i) => i.id)
      );
  };

  return (
    <FixedReactSortable
      animation={150}
      handle=".handle"
      list={sortedItems}
      setList={setSortedItems}
      ghostClass="bg-blue-100"
      onEnd={handleDragEnd}
    >
      {sortedItems.map((node) => {
        if (
          node.value &&
          node.value.observation.observationSetting.systemAttribute
        )
          return <div key={node?.id || ""}></div>;
        return (
          <div key={node?.id || ""}>
            {node && (
              <div>
                {node.visible && node.value && (
                  <LabelRow
                    label={node.value}
                    node={node}
                    key={node.id}
                    columns={columns}
                  />
                )}
                {node.visible && (
                  <BatchLabelNode
                    node={node}
                    onDragEnd={onDragEnd}
                    columns={columns}
                  />
                )}
              </div>
            )}
          </div>
        );
      })}
    </FixedReactSortable>
  );
};

interface LabelRowProps {
  columns: ColDef[];
  label: BatchObservationDTO;
  node: ObservationTreeNodeModel<BatchObservationDTO>;
}
export const LabelRow = ({ columns, label, node }: LabelRowProps) => {
  return (
    <div
      className="flex items-center w-full gap-2 border-b hover:bg-secondary-50 label-row"
      style={{ minHeight: 48 }}
    >
      {columns.map((column) => {
        return (
          <div
            key={column.field}
            style={{ flex: column.flex }}
            className={classnames(
              {
                "text-gray-400":
                  !!node.value && !!!node.value.observation.active,
              },
              "truncate"
            )}
          >
            {column.renderCell(label, node)}
          </div>
        );
      })}
    </div>
  );
};
