/*
 * File: ai-models.state.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 18th February 2022 2:27:38 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { BaseState, RequestStatus } from "store/base/base.state";
import { AIModelDTO } from "./../../../services/label-service/dtos/ai-model.dto";

export interface AIModelsState extends BaseState<AIModelDTO> {}

export const INITIAL_AI_MODELS_STATE: AIModelsState = {
  entities: {},
  ids: [],
  id: -1,
  status: RequestStatus.IDLE,
  error: null,
};
