/*
 * File: filter-by-iou.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 8th November 2022 5:10:33 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Slider } from "@material-ui/core";
import { useDebounceFn } from "ahooks";
import { LightCheckbox } from "components/common/vb-light-checkbox.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect, useState } from "react";
import { selectMinIouScore } from "store/labeler/image-workspace/image-iou/image-iou.selectors";
import { imageIouAnnotationsLoadedAsync } from "store/labeler/image-workspace/image-iou/image-iou.thunk";
import { setImageIouActiveScore } from "store/labeler/image-workspace/image-workspace.slice";
import { IoUPairs } from "../image-iou/iou-pairs";
import { PreviousJobAnnotationTree } from "./previous-job-annotation-tree";

interface Props {
  jobId: number;
}

export const FilterByIoU = ({ jobId }: Props) => {
  const dispatch = useAppDispatch();
  const minIouScore = useAppSelector(selectMinIouScore);
  const [score, setScore] = useState(0);
  const [checked, setChecked] = useState(false);

  const { run: debouncedSlider } = useDebounceFn(
    (score: number) => {
      dispatch(setImageIouActiveScore(score));
    },
    { wait: 300 }
  );

  const handleChangeCheckbox = useCallback(
    (checked: boolean) => {
      setChecked(checked);
      dispatch(setImageIouActiveScore(checked ? score : -1));
    },
    [dispatch, score]
  );

  const handleChangeIoU = useCallback(
    (event: any, newValue: number | number[]) => {
      setScore(newValue as number);
      debouncedSlider(newValue as number);
    },
    [debouncedSlider]
  );

  useEffect(() => {
    dispatch(imageIouAnnotationsLoadedAsync({ jobId }));
    dispatch(setImageIouActiveScore(-1));
  }, [dispatch, jobId]);

  useEffect(() => {
    const score = minIouScore;
    setScore(score);
  }, [minIouScore, dispatch]);

  return (
    <div>
      <div className="flex items-center justify-start gap-4 p-4 text-white">
        <div className="flex items-center justify-start gap-1 h-10">
          <LightCheckbox
            size="small"
            checked={checked}
            onChange={(_, selected) => handleChangeCheckbox(selected)}
            style={{ padding: 0 }}
          />
          <div className="text-sm font-semibold">IoU</div>
        </div>

        {checked && (
          <>
            <Slider
              track={false}
              aria-labelledby="track-false-slider"
              value={score}
              min={0}
              step={0.01}
              max={1}
              onChange={handleChangeIoU}
              disabled={!checked}
            />
            <span className="text-sm">{score}</span>
          </>
        )}
      </div>

      {checked && <IoUPairs />}
      {!checked && <PreviousJobAnnotationTree />}
    </div>
  );
};
