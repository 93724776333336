/*
 * File: batch-labels.view.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useState } from "react";
import { BatchObservationDTO, BatchStatus } from "services/label-service/dtos";
import { selectBatchLabels } from "store/customer/batch/batch.selectors";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { BatchLabelRemoveDialog } from "./components/batch-label-remove-dialog.component";
import { BatchLabelToolbar } from "./components/batch-label-toolbar.component";
import { BatchLabelTree } from "./components/batch-label-tree.component";
import { useBuildBatchObservationTreeWithVisibleData } from "hooks/use-observation-tree";
import {
  ObservationTreeNodeModel,
  TreeNodeVisibleDataMap,
} from "services/label-service/model/observation-tree-node.model";
import { usePrevious } from "ahooks";
import { useBatchDetailContext } from "../../context/batch-detail.context";

export const BatchLabelsPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { batch } = useBatchDetailContext();
  const batchLabels = useAppSelector(selectBatchLabels);
  const [removingLabels, setRemovingLabels] = useState<BatchObservationDTO[]>(
    []
  );
  const [visibleData, setVisibleData] = useState<TreeNodeVisibleDataMap>({});

  const {
    rootNode,
    nodesList,
    setShowChildren,
    updateNodeChildrenOrder,
    findAllNodeChildrenValues,
    updatedVisibleNodes,
  } = useBuildBatchObservationTreeWithVisibleData(batchLabels, visibleData);
  const previousUpdatedVisibleNodes = usePrevious(updatedVisibleNodes);

  const handleSelect = (
    node: ObservationTreeNodeModel<BatchObservationDTO>,
    action?: string
  ) => {
    if (!node || !node.value) return;
    if (action === "remove") {
      if (batchLabels.length === 1) {
        dispatch(
          enqueueErrorNotification(
            t("project:batchDetails.batchLabels.textWarnAtLeastOne")
          )
        );
        return;
      }
      const toRemoveValues = findAllNodeChildrenValues(node.id);
      toRemoveValues.push(node.value);
      setRemovingLabels(toRemoveValues as BatchObservationDTO[]);
    }
  };

  useEffect(() => {
    if (
      updatedVisibleNodes.length <= 0 ||
      updatedVisibleNodes === previousUpdatedVisibleNodes
    )
      return;
    const newVisibleData: TreeNodeVisibleDataMap = {};

    for (const key in visibleData) {
      newVisibleData[key] = { ...visibleData[key] };
    }

    if (Object.keys(visibleData).length === 0 && nodesList.length > 0) {
      nodesList.forEach((node) => {
        if (node.value) {
          newVisibleData[node.value.observation.id] = {
            visible: node.visible,
            showChildren: node.showChildren,
          };
        }
      });
    }

    updatedVisibleNodes.forEach((node) => {
      if (node.value) {
        newVisibleData[node.value.observation.id] = {
          visible: node.visible,
          showChildren: node.showChildren,
        };
      }
    });

    setVisibleData(newVisibleData);
  }, [
    updatedVisibleNodes,
    previousUpdatedVisibleNodes,
    visibleData,
    nodesList,
  ]);

  return (
    <div className="grid grid-cols-1 gap-4 p-4">
      <BatchLabelToolbar
        batch={batch}
        disabled={batch?.status === BatchStatus.COMPLETED}
      />
      <BatchLabelTree
        rootNode={rootNode}
        batch={batch}
        setShowChildren={setShowChildren}
        updateNodeChildrenOrder={updateNodeChildrenOrder}
        onSelect={handleSelect}
      />
      <BatchLabelRemoveDialog
        labels={removingLabels}
        handleClose={() => setRemovingLabels([])}
      />
    </div>
  );
};
