/*
 * File: workflow.api.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 17th July 2021 9:13:27 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { WorkflowDTO } from "../dtos/workflow.dto";
import { BaseAPI } from "./base.api";

export class WorkflowAPI extends BaseAPI<WorkflowDTO> {}
