/*
 * File: editor-setting.state.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 30th March 2022 5:19:49 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export interface EditorSettingState {
  autoSave: boolean;
  showBBoxRuler: boolean;
  showKeyboardShortcuts: boolean;
  showEditorSettings: boolean;
  showTaskInfo: boolean;
  showAnnotationToolbar: boolean;
  showWarningPopup: boolean;
  completeJobsInView: boolean;
  completeJobsInBatch: boolean;
  theme: string;
}

export const defaultEditorSettingState: EditorSettingState = {
  autoSave: false,
  showBBoxRuler: false,
  showKeyboardShortcuts: false,
  showEditorSettings: false,
  showTaskInfo: false,
  showAnnotationToolbar: true,
  showWarningPopup: true,
  completeJobsInView: false,
  completeJobsInBatch: false,
  theme: "dark",
};
