/*
 * File: sam-tool-header.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 24th April 2023 11:09:08 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { IconButton, Tooltip } from "@material-ui/core";
import {
  VscCheck,
  VscCircleLargeFilled,
  VscClose,
} from "react-icons/vsc";
import { BsBoundingBox } from "react-icons/bs";
import classNames from "classnames";
import ReactSelect, { SingleValue, components } from "react-select";
import { AnnotateType } from "constants/annotation.constant";
import { TbPolygon } from "react-icons/tb";
import { useOnnixContext } from "modules/onnix/context/onnix-context";
import { SAMMode } from "modules/onnix/context/onnix-state";
import { reactSelectStyles } from "./react-select.styles";
import { useMemo } from "react";

const { Option } = components;

function IconOption(props: any) {
  const {
    data: { label, type, color },
  } = props;

  return (
    <Option {...props}>
      <div className="flex items-center gap-2">
        {type === AnnotateType.POLYGON && (
          <span className="flex items-center justify-center flex-none w-6 h-6">
            <TbPolygon size={18} color={color} />
          </span>
        )}
        {type === AnnotateType.BOUNDING_BOX && (
          <span className="flex items-center justify-center flex-none w-6 h-6">
            <BsBoundingBox size={14} color={color} />
          </span>
        )}
        <span>{label}</span>
      </div>
    </Option>
  );
}

export function SAMToolHeader() {
  const {
    data,
    mode,
    selectMode,
    selectLabel,
    selectedLabel,
    labelOptions,
    handleApply,
    handleClose,
  } = useOnnixContext();

  const enable = useMemo(() => {
    return data && (data.polygons.length > 0 || data.bboxes.length > 0);
  }, [data]);

  function handleOptionChange(option: SingleValue<any>) {
    selectLabel(option.value);
  }

  return (
    <div className="absolute top-0 left-0 z-50 flex items-center w-full h-12 px-4 bg-blueGray-900">
      <ReactSelect
        styles={reactSelectStyles}
        className="w-40 text-sm"
        value={selectedLabel}
        options={labelOptions}
        onChange={handleOptionChange}
        components={{ Option: IconOption }}
      />

      <Tooltip title="Draw Box">
        <button
          className={classNames(
            "flex items-center justify-center w-8 h-8 rounded mx-2 flex-none",
            {
              "border border-warning-500": mode === SAMMode.DRAW,
            }
          )}
          onClick={() => selectMode(SAMMode.DRAW)}
        >
          <BsBoundingBox size={16} color="#FFF" />
        </button>
      </Tooltip>

      <Tooltip title="Add Mask">
        <button
          className={classNames(
            "flex items-center justify-center w-8 h-8 rounded mx-2 flex-none",
            {
              "border border-warning-500": mode === SAMMode.ADD_MASK,
            }
          )}
          onClick={() => selectMode(SAMMode.ADD_MASK)}
        >
          <VscCircleLargeFilled size={18} color="#00FFFF" />
        </button>
      </Tooltip>

      <Tooltip title="Remove Area">
        <button
          className={classNames(
            "flex items-center justify-center w-8 h-8 rounded mx-2 flex-none",
            {
              "border border-warning-500": mode === SAMMode.REMOVE_AREA,
            }
          )}
          onClick={() => selectMode(SAMMode.REMOVE_AREA)}
        >
          <VscCircleLargeFilled size={18} color="#ffc0cb" />
        </button>
      </Tooltip>

      <span className="flex-auto" />

      <Tooltip title="Apply">
        <span>
          <IconButton
            className={!enable ? "cursor-not-allowed" : ""}
            disabled={!enable}
            onClick={handleApply}
          >
            <VscCheck size={18} color={enable ? "#fff" : "#ccc"} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Close">
        <IconButton onClick={handleClose}>
          <VscClose size={18} color="#fff" />
        </IconButton>
      </Tooltip>
    </div>
  );
}
