/*
 * File: index.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 28th June 2023 10:22:52 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Command } from "domain/common";

export enum FileBrowseCommandEnum {
  DELETE_SELECTION = "DELETE_SELECTION",
  ADD_METADATA_SELECTION = "ADD_METADATA_SELECTION",
  OPEN_FILE = "OPEN_FILE",
  SELECT_FILE = "SELECT_FILE",
  CLOSE_FILE = "CLOSE_FILE",
  OPEN_NEXT_FILE = "OPEN_NEXT_FILE",
  OPEN_PREVIOUS_FILE = "OPEN_PREVIOUS_FILE",
  RELOAD_FILES = "RELOAD_FILES",
}

export class DeleteSelectionCommand extends Command<{}> {
  readonly id: string = FileBrowseCommandEnum.DELETE_SELECTION;
}

export class AddMetadataSelectionCommand extends Command<{}> {
  readonly id: string = FileBrowseCommandEnum.ADD_METADATA_SELECTION;
}

export class OpenFileCommand extends Command<{ fileId: number }> {
  readonly id: string = FileBrowseCommandEnum.OPEN_FILE;
}

export class SelectFileCommand extends Command<{ fileId: number }> {
  readonly id: string = FileBrowseCommandEnum.SELECT_FILE;
}

export class CloseFileCommand extends Command<{ fileId: number }> {
  readonly id: string = FileBrowseCommandEnum.CLOSE_FILE;
}

export class OpenNextFileCommand extends Command<{}> {
  readonly id: string = FileBrowseCommandEnum.OPEN_NEXT_FILE;
}

export class OpenPreviousFileCommand extends Command<{}> {
  readonly id: string = FileBrowseCommandEnum.OPEN_PREVIOUS_FILE;
}

export class ReloadFilesCommand extends Command<{}> {
  readonly id: string = FileBrowseCommandEnum.RELOAD_FILES;
}
