/*
 * File: workflow-selector.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 19th August 2021 4:39:12 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { WorkflowDTO } from "services/label-service/dtos";
import { VBSelectComponent } from "components/design-system/select-input/select.component";

interface WorkflowSelectorProps {
  selectedWorkflowId?: number;
  workflows: WorkflowDTO[];
  disabled?: boolean;
  onChange?(workflow: WorkflowDTO | null): void;
}

export const WorkflowSelector = ({
  workflows,
  selectedWorkflowId = -1,
  disabled,
  onChange,
}: WorkflowSelectorProps) => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return workflows.map((workflow) => {
      return {
        value: workflow.id,
        label: workflow.name,
      };
    });
  }, [workflows]);

  function handleWorkflowChange(option: any) {
    const workflowId = parseInt(option.value as string);
    const workflow = workflows.find((w) => w.id === workflowId);
    onChange && onChange(workflow || null);
  }

  return (
    <VBSelectComponent
      isDisabled={disabled}
      className="w-60"
      placeholder={t("project:batchDetails.placeholderSelectWorkflow")}
      value={options.find((option) => selectedWorkflowId === option.value)}
      menuPortalTarget={document.body}
      onChange={handleWorkflowChange}
      options={options}
    />
  );
};
