/*
 * File: use-synchronize-tool.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 7th June 2022 6:11:35 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useAppSelector } from "hooks/use-redux";
import { useEffect, useRef } from "react";
import { selectIsSynchronizing } from "store/labeler/image-workspace/cornerstone/cornerstone.selectors";
import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import * as Sentry from "@sentry/react";

function isSameSide(sourceTags: string[], targetTags: string[]) {
  if (targetTags.length === 0) return false;
  return sourceTags?.includes(targetTags[0]);
}

export const customPanZoomSynchronizer = (
  synchronizer: any,
  sourceElement: any,
  targetElement: any
) => {
  // Get the source and target viewports
  const sourceViewport = cornerstone.getViewport(sourceElement);
  const targetViewport = cornerstone.getViewport(targetElement);
  const sourceTags = sourceElement.getAttribute("data-tags") || [];
  const targetTags = targetElement.getAttribute("data-tags") || [];
  const sourceId = sourceElement.getAttribute("id") || "";
  const targetId = targetElement.getAttribute("id") || "";
  if (!isSameSide(sourceTags, targetTags)) return;
  if (sourceId === targetId) return;

  if (
    targetViewport.rotation === sourceViewport.rotation &&
    targetViewport.scale === sourceViewport.scale &&
    targetViewport.translation.x === sourceViewport.translation.x * -1 &&
    targetViewport.translation.y === sourceViewport.translation.y
  ) {
    return;
  }

  targetViewport.rotation = sourceViewport.rotation;
  targetViewport.scale = sourceViewport.scale;
  targetViewport.translation.x = sourceViewport.translation.x * -1;
  targetViewport.translation.y = sourceViewport.translation.y;
  synchronizer.setViewport(targetElement, targetViewport);
};

export function useSynchronizingTool(enable?: boolean) {
  const isSync = useAppSelector(selectIsSynchronizing);
  const wwwcTool = useRef(
    new cornerstoneTools.Synchronizer(
      "cornerstoneimagerendered",
      cornerstoneTools.wwwcSynchronizer
    )
  );

  const panZoomSynchronizer = useRef(
    new cornerstoneTools.Synchronizer(
      "cornerstoneimagerendered",
      customPanZoomSynchronizer
    )
  );

  useEffect(() => {
    try {
      wwwcTool.current.destroy();
      panZoomSynchronizer.current.destroy();

      if (!enable || !isSync) {
        wwwcTool.current.enabled = false;
        panZoomSynchronizer.current.enabled = false;
        return;
      }
      wwwcTool.current.enabled = true;
      panZoomSynchronizer.current.enabled = true;

      const domElements = document.getElementsByClassName("viewport-element");
      const elements = Array.from(domElements);
      elements.forEach((element) => {
        const isActive = element.getAttribute("data-active");
        if (isActive === "true") {
          wwwcTool.current.addSource(element);
          panZoomSynchronizer.current.addSource(element);
        } else {
          wwwcTool.current.addTarget(element);
          panZoomSynchronizer.current.addTarget(element);
        }
      });
    } catch (error) {
      Sentry.captureException(error);
      console.log("Error", error);
    }
  }, [enable, isSync]);
}
