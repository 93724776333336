import { UserAPI } from "../http/user-service.httpclient";


export function exportWorkspaceUsersToCSV() {
  return UserAPI.get("/api/v1/user/export");
}

export function importUsersToWorkspaceFromCSV(file: File) {
  const formData = new FormData();
  formData.append("file", file);
  return UserAPI.post("/api/v1/user/import", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}