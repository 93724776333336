import { IssueManagementState } from "pages/customer/projects/project-batch/batch-detail/pages/tasks/components/issue/issue-management.provider";

export interface IssueManagementStoreState extends IssueManagementState {
  selectedIssueId: number | undefined;
  issueFilter: string;
}

export const INITIAL_ISSUE_MANAGEMENT_STORE_STATE: IssueManagementStoreState = {
  isCreatingIssueMode: false,
  issues: [],
  isReviewer: false,
  selectedIssueId: undefined,
  issueFilter: "all",
  taskId: -1,
};
