/*
 * File: mammography-task.context.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 21st September 2022 4:21:48 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { defaultMammographyTaskState } from "./mammography-task.state";

export const MammographyTaskContext = createContext(
  defaultMammographyTaskState
);

export const useMammographyTaskContext = () => {
  return useContext(MammographyTaskContext);
};
