/*
 * File: create-dataset-dialog.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 2nd August 2021 2:47:02 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { Logger } from "utilities/logger";
import { useAppDispatch } from "hooks/use-redux";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { SelectableInput } from "components/common/vb-input/selectable-input.component";
import * as Sentry from "@sentry/react";
import useMoveFilesOptions from "pages/customer/datasets/file-browse/hooks/use-move-options";
import { StorageApi } from "data-access/impl/storage";
import useDatasetOptions from "pages/customer/datasets/file-browse/hooks/use-dataset-options";

interface Props {
  open: boolean;
  fileIds: number[];
  fromDatasetId?: number;
  onClose: () => void;
}

export const MoveFilesToFolder = ({
  fileIds,
  fromDatasetId = -1,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [processing, setProcessing] = useState(false);
  const { options, option, onChange } = useMoveFilesOptions("copy");
  const {
    options: datasetOptions,
    option: datasetOption,
    onChange: onDatasetChange,
  } = useDatasetOptions({ page: 0, size: 10000 });

  const filteredDatasetOptions = useMemo(() => {
    const dataset = datasetOptions.find(
      (opt) => opt.value === fromDatasetId.toString()
    );
    if (!dataset) return [];
    return datasetOptions.filter(
      (opt) =>
        opt.value !== fromDatasetId.toString() &&
        opt.metadata.category === dataset.metadata.category
    );
  }, [datasetOptions, fromDatasetId]);

  async function handleSubmit() {
    if (processing) return;
    if (!datasetOption) return;

    try {
      setProcessing(true);
      const datasetId = parseInt(datasetOption.value);
      await StorageApi.moveFiles(
        fileIds,
        fromDatasetId,
        datasetId,
        option?.value === "cut"
      );
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      onClose && onClose();
    } catch (err) {
      Sentry.captureException(err);
      Logger.log("err", err);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setProcessing(false);
    }
  }

  return (
    <VBModal
      title="Move files to a new datasource"
      open={open}
      onClose={onClose}
      textSubmit={t("common:buttonSubmit")}
      onSubmit={handleSubmit}
      disableSubmit={processing || !datasetOption}
      blockUI={processing}
      processingIndicator={processing}
    >
      <div className="flex flex-col gap-3">
        <SelectableInput
          required
          value={option}
          onChange={onChange}
          header="Move option"
          options={options}
          menuPortalTarget={document.body}
        />

        <SelectableInput
          value={datasetOption}
          onChange={onDatasetChange}
          header="Select Datasource"
          options={filteredDatasetOptions}
          menuPortalTarget={document.body}
        />
      </div>
    </VBModal>
  );
};

export default MoveFilesToFolder;
