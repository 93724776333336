import { Tooltip } from "@material-ui/core";
import {
  IconEye,
  IconEyeSlash,
  IconVoteDown,
  IconVoteDownActive,
  IconVoteUp,
  IconVoteUpActive,
} from "components/common/vb-icon.component";
import { Annotation } from "domain/image-labeling";
import { useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { AnnotationVoteStatus } from "services/label-service/dtos/annotation.dto";
import { selectActiveAnnotations } from "store/labeler/image-workspace/image-annotations/image-annotations.selectors";
import { ActiveAnnotation } from "store/labeler/image-workspace/image-annotations/image-annotations.state";
import {
  imageAnnotationUpdated,
  updateActiveAnnotation,
} from "store/labeler/image-workspace/image-workspace.slice";
import { fromAnnotationVoteStatusToVote } from "utilities/annotations/annotation-util";
import { truncateEmail } from "utilities/string/truncate-email";

interface ActionPanelAnnotationProps {
  annotation: Annotation;
  onChange?: (annotation: Annotation) => void;
}

export const ActionPanelAnnotation = ({
  annotation,
  onChange,
}: ActionPanelAnnotationProps) => {
  const dispatch = useDispatch();
  const activeAnnotations = useAppSelector(selectActiveAnnotations);

  const [isHovering, setIsHovering] = useState(false);
  const [isSelectedAnnotation, setIsSelectedAnnotation] =
    useState<boolean>(true);
  const [isVisibleIcons, setIsVisibleIcons] = useState<boolean>(false);

  useEffect(() => {
    const currentActiveAnnotation = activeAnnotations.find(
      (activeAnnotation) => activeAnnotation.annotationId === annotation.id
    );
    if (!currentActiveAnnotation) return setIsSelectedAnnotation(false);
    setIsSelectedAnnotation(currentActiveAnnotation.selected || false);
  }, [activeAnnotations, annotation.id]);

  useEffect(() => {
    if (isHovering) {
      setIsVisibleIcons(true);
    } else {
      if (annotation.vote !== 0 || !isSelectedAnnotation) {
        setIsVisibleIcons(true);
      } else {
        setIsVisibleIcons(false);
      }
    }
  }, [annotation.vote, isHovering, isSelectedAnnotation]);

  const vote = useMemo(() => annotation.vote || 0, [annotation]);

  const handleVote = useCallback(
    (status: AnnotationVoteStatus) => {
      const vote = fromAnnotationVoteStatusToVote(status);
      const updatedAnnotation: Annotation = {
        ...annotation,
        vote,
      };
      dispatch(imageAnnotationUpdated(updatedAnnotation));
      onChange && onChange(updatedAnnotation);
    },
    [annotation, dispatch, onChange]
  );

  const handleChangeAnnotation = useCallback(
    ({ selected, hovering }: { selected?: boolean; hovering?: boolean }) => {
      const activeAnnotation: ActiveAnnotation = {
        annotationId: annotation.id,
        ...(selected !== undefined && { selected }),
        ...(hovering !== undefined && { hovering }),
      };
      dispatch(updateActiveAnnotation(activeAnnotation));
    },
    [annotation.id, dispatch]
  );

  const handleDisplayAnnotation = useCallback(() => {
    setIsSelectedAnnotation(!isSelectedAnnotation);
    handleChangeAnnotation({
      selected: !isSelectedAnnotation,
      hovering: false,
    });
  }, [handleChangeAnnotation, isSelectedAnnotation]);

  const handleHoveringAnnotation = useCallback(
    (isHovering: boolean) => {
      handleChangeAnnotation({ hovering: isHovering });
      setIsHovering(isHovering);
    },
    [handleChangeAnnotation]
  );

  return (
    <div
      className="flex items-center justify-between"
      onMouseEnter={() => handleHoveringAnnotation(true)}
      onMouseLeave={() => handleHoveringAnnotation(false)}
    >
      <div className="space-y-2">
        <p className="text-base font-medium">{annotation.id}</p>
        <p className="flex justify-between w-full gap-1 text-xs font-normal">
          <Tooltip title={truncateEmail(annotation.annotator)}>
            <span className="overflow-hidden whitespace-nowrap overflow-ellipsis">
              {truncateEmail(annotation.annotator)}
            </span>
          </Tooltip>
        </p>
      </div>
      {isVisibleIcons && (
        <div className="flex items-center justify-end text-xs gap-4">
          <div className="flex items-center justify-start gap-2">
            {vote > 0 ? (
              <IconVoteUpActive className="cursor-not-allowed" />
            ) : (
              <IconVoteUp onClick={() => handleVote(AnnotationVoteStatus.UP)} />
            )}
            {vote < 0 ? (
              <IconVoteDownActive className="cursor-not-allowed" />
            ) : (
              <IconVoteDown
                onClick={() => handleVote(AnnotationVoteStatus.DOWN)}
              />
            )}
          </div>

          {isSelectedAnnotation ? (
            <IconEye
              width={20}
              height={20}
              stroke="#fff"
              onClick={handleDisplayAnnotation}
            />
          ) : (
            <IconEyeSlash
              width={20}
              height={20}
              stroke="#fff"
              onClick={handleDisplayAnnotation}
            />
          )}
        </div>
      )}
    </div>
  );
};
