/*
 * File: training-job.dto.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 6th September 2021 2:46:08 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { DatasetDTO } from ".";
import { BaseDTO } from "./base.dto";

export interface TrainingJobDTO extends BaseDTO {
  workspaceId: string | null;
  name: string;
  description: string;
  status: string;
  visualization?: string;
  dataset: DatasetDTO;
  parameter: {
    dataGenerationId: any;
    pipelineId: string;
    modelName: string;
    modelVersion: string;
    epochs: number;
  };
  metrics: {
    format: string;
    name: string;
    node_id: string;
    number_value: number;
  }[];

  exportedDatasetVersion: {
    createdBy: string;
    createdDate: string;
    version: number;
    workspaceId: string;
    id: number;
    datasetVersion: string;
    location: string;
    datasetName: string;
    datasetId: number;
    status: string;
  };
}

export enum TrainingJobStatus {
  RUNNING = "Running",
  SUCCEEDED = "Succeeded",
  FAILED = "Failed",
}
