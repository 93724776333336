import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { LabelerAnnotationService } from "services/label-service";
import { ImageWorkspaceState } from "../image-workspace.state";
import { IouAnnotation } from "./image-iou.state";

enum ImageIouThunk {
  IMAGE_IOU_ANNOTATIONS_LOADED = "imageWorkspace/imageIouAnnotationsLoadedAsync",
}

interface Payload {
  jobId: number;
}

export const imageIouAnnotationsLoadedAsync = createAsyncThunk(
  ImageIouThunk.IMAGE_IOU_ANNOTATIONS_LOADED,
  async (payload: Payload) => {
    const { jobId } = payload;
    const response = await LabelerAnnotationService.getIoULabeler(jobId);
    const iouAnnotations: IouAnnotation[] = response.data;
    return { iouAnnotations };
  }
);

export function imageIouReducerBuilder(
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) {
  return builder.addCase(
    imageIouAnnotationsLoadedAsync.fulfilled,
    ({ imageIou }, action) => {
      imageIou.iouAnnotations = action.payload.iouAnnotations;
    }
  );
}
