import { Radio, RadioProps, Switch, withStyles } from "@material-ui/core";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBNumberTextInputComponent } from "components/design-system/number-text-input/number-text-input.component";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_DAILY_LIMIT } from "services/user-service/dtos/user.dto";
import { handleInvalidNumber, isValidNumber } from "utilities/number/handle-invalid-number";


export const CONFIG_TYPE_PER_LABELER = "per_labeler";
export const CONFIG_TYPE_MANUAL = "manual";

export interface AdvancedLabelerConfigUIModel {
  isUseTaskDistConfig: boolean;
  taskDistConfigType: string;
  numTaskPerLabeler: number;

  isUseLimitPerdayConfig: boolean;
  limitPerdayConfigType: string;
  numLimitPerdayPerLabeler: number;

  configRows: AdvancedLabelerConfigRowUIModel[];
}

export interface AdvancedLabelerConfigRowUIModel {
  user: string; // email, id, ...
  dailyLimit?: number;
  numTaskInBatch?: number;
}

const StyledSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: "#3e7df8",
    },
    '&$checked + $track': {
      backgroundColor: "#3e7df8",
    },
  },
  checked: {},
})(Switch);
const StyledRadio = withStyles({
  root: {
    '&$checked': {
      color: "#3e7df8",
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);
interface Props {
  open: boolean;
  title?: string;
  onClose?: () => void;
  value: AdvancedLabelerConfigUIModel;
  onValueChanged?: (value: AdvancedLabelerConfigUIModel) => void;
  numTaskInBatchDefault?: number;
  disabledTaskDist?: boolean;
}

export const AdvancedLabelerConfig = ({
  open,
  title = "Config labelers",
  onClose,
  value,
  onValueChanged,
  numTaskInBatchDefault = NaN,
  disabledTaskDist,
}: Props) => {
  const { t } = useTranslation();

  const [isUseTaskDistConfig, setIsUseTaskDistConfig] = useState(() => value.isUseTaskDistConfig);
  const [taskDistConfigType, setTaskDistConfigType] = useState(() => value.taskDistConfigType);
  const [numTaskPerLabeler, setNumTaskPerLabeler] = useState(() => value.numTaskPerLabeler);

  const [isUseLimitPerdayConfig, setIsUseLimitPerdayConfig] = useState(() => value.isUseLimitPerdayConfig);
  const [limitPerdayConfigType, setLimitPerdayConfigType] = useState(() => value.limitPerdayConfigType);
  const [numLimitPerdayPerLabeler, setNumLimitPerdayPerLabeler] = useState(() => value.numLimitPerdayPerLabeler);

  const [configRows, setConfigRows] = useState(() => value.configRows);

  const enabledSubmit = useMemo(() => {
    if (isUseTaskDistConfig) {
      for (const configRow of configRows){
        if (!isValidNumber(configRow.numTaskInBatch)) return false;
      }
      return true;
    }
    return true;
  }, [configRows, isUseTaskDistConfig]);

  useEffect(() => {
    if (!value) return;
    setIsUseTaskDistConfig(value.isUseTaskDistConfig);
    setTaskDistConfigType(value.taskDistConfigType);
    setNumTaskPerLabeler(value.numTaskPerLabeler);
    setIsUseLimitPerdayConfig(value.isUseLimitPerdayConfig);
    setLimitPerdayConfigType(value.limitPerdayConfigType);
    setNumLimitPerdayPerLabeler(value.numLimitPerdayPerLabeler);
    setConfigRows(value.configRows);
  }, [value]);

  const handleSubmit = () => {
    const newValue = {
      isUseTaskDistConfig,
      taskDistConfigType,
      numTaskPerLabeler,
      isUseLimitPerdayConfig,
      limitPerdayConfigType,
      numLimitPerdayPerLabeler,
      configRows,
    }
    onValueChanged && onValueChanged(newValue);
    onClose && onClose();
  }

  const updateConfigRows = (field: keyof AdvancedLabelerConfigRowUIModel, value: number) => {
    const newRows = configRows.map(row => {
      return {
        ...row,
        [field]: value,
      };
    })
    setConfigRows(newRows);
  }

  // task dist
  const handleIsUseTaskDistConfigChanged = (v: boolean) => {
    setIsUseTaskDistConfig(v);
    if (v) {
      setTaskDistConfigType(CONFIG_TYPE_PER_LABELER);
      setNumTaskPerLabeler(numTaskInBatchDefault);
      updateConfigRows("numTaskInBatch", numTaskInBatchDefault);
    } else {
      setTaskDistConfigType(CONFIG_TYPE_PER_LABELER);
      setNumTaskPerLabeler(NaN);
      updateConfigRows("numTaskInBatch", NaN);
    }
  }

  const handleTaskDistConfigTypeChanged = (v: string) => {
    setTaskDistConfigType(v);
  }

  const handleNumTaskPerLabelerChanged = (v: number) => {
    setNumTaskPerLabeler(v);
    updateConfigRows("numTaskInBatch", v);
  }

  // per day limit
  const handleIsUseLimitPerdayConfigChanged = (v: boolean) => {
    setIsUseLimitPerdayConfig(v);
  }

  const handleLimitPerdayConfigTypeChanged = (v: string) => {
    setLimitPerdayConfigType(v);
    if (v === CONFIG_TYPE_PER_LABELER) {
      updateConfigRows("dailyLimit", numLimitPerdayPerLabeler);
    }
  }

  const handleNumLimitPerdayPerLabelerChanged = (v: number) => {
    setNumLimitPerdayPerLabeler(v);
    updateConfigRows("dailyLimit", handleInvalidNumber(v, DEFAULT_DAILY_LIMIT));
  }

  const handleRowChanged = (newRow: AdvancedLabelerConfigRowUIModel) => {
    const newRows = configRows.map(row => {
      if (row.user === newRow.user) return newRow;
      return row;
    });
    setConfigRows(newRows);
  }

  return (
    <VBModal
      width="40rem"
      title={title}
      open={open}
      onClose={onClose}
      textSubmit={t("common:buttonSave")}
      onSubmit={handleSubmit}
      disableSubmit={!enabledSubmit}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <StyledSwitch
              color="primary"
              checked={isUseTaskDistConfig}
              onChange={(_, v) => handleIsUseTaskDistConfigChanged(v)}
              disabled={disabledTaskDist}
            />
            <span>Task distribution</span>
          </div>
          {
            isUseTaskDistConfig && 
            <div className="ml-4">
              <div className="flex items-center gap-2">
                <StyledRadio
                  checked={taskDistConfigType === CONFIG_TYPE_PER_LABELER}
                  onChange={(e: any) => handleTaskDistConfigTypeChanged(e.target.value as string)}
                  value={CONFIG_TYPE_PER_LABELER}
                />
                <span>Maximum task for each labeler</span>
                <div className="w-20 ml-2">
                  <VBNumberTextInputComponent
                    min={0}
                    height="36px"
                    type="number"
                    defaultValue={numTaskPerLabeler}
                    onValueChanged={v => handleNumTaskPerLabelerChanged(v as number)}
                    disabled={taskDistConfigType !== CONFIG_TYPE_PER_LABELER || disabledTaskDist}
                  />
                </div>
                <span>tasks</span>
              </div>
              <div className="flex items-center gap-2">
                <StyledRadio
                  checked={taskDistConfigType === CONFIG_TYPE_MANUAL}
                  onChange={(e: any) => handleTaskDistConfigTypeChanged(e.target.value as string)}
                  value={CONFIG_TYPE_MANUAL}
                />
                <span>Manual set maximum</span>
              </div>
            </div>
          }
          <div className="flex items-center gap-2">
            <StyledSwitch
              color="primary"
              checked={isUseLimitPerdayConfig}
              onChange={(_, v) => handleIsUseLimitPerdayConfigChanged(v)}
              // disabled
            />
            <span>Limit record per day</span>
          </div>
          {
            isUseLimitPerdayConfig && 
            <div className="ml-4">
              <div className="flex items-center gap-2">
                <StyledRadio
                  checked={limitPerdayConfigType === CONFIG_TYPE_PER_LABELER}
                  onChange={(e: any) => handleLimitPerdayConfigTypeChanged(e.target.value as string)}
                  value={CONFIG_TYPE_PER_LABELER}
                />
                <span>Maximum task for each labeler per day</span>
                <div className="w-20 ml-2">
                  <VBNumberTextInputComponent
                    min={0}
                    height="36px"
                    type="number"
                    defaultValue={numLimitPerdayPerLabeler}
                    onValueChanged={v => handleNumLimitPerdayPerLabelerChanged(v as number)}
                  />
                </div>
                <span>tasks</span>
              </div>
              <div className="flex items-center gap-2">
                <StyledRadio
                  checked={limitPerdayConfigType === CONFIG_TYPE_MANUAL}
                  onChange={(e: any) => handleLimitPerdayConfigTypeChanged(e.target.value as string)}
                  value={CONFIG_TYPE_MANUAL}
                  disabled={limitPerdayConfigType !== CONFIG_TYPE_PER_LABELER}
                />
                <span>Manual set maximum</span>
              </div>
            </div>
          }
        </div>

        <AdvancedLabelerConfigRowList
          rows={configRows}
          disabledLimitPerDay={limitPerdayConfigType !== CONFIG_TYPE_MANUAL}
          disabledNumTask={taskDistConfigType !== CONFIG_TYPE_MANUAL || disabledTaskDist}
          onRowChanged={handleRowChanged}
        />
      </div>
    </VBModal>
  )
}

interface AdvancedLabelerConfigRowListProps {
  rows: AdvancedLabelerConfigRowUIModel[];
  disabledLimitPerDay?: boolean;
  disabledNumTask?: boolean;
  onRowChanged?: (row: AdvancedLabelerConfigRowUIModel) => void;
}
export const AdvancedLabelerConfigRowList = ({
  rows,
  disabledLimitPerDay,
  disabledNumTask,
  onRowChanged,
}: AdvancedLabelerConfigRowListProps) => {

  return (
    <div className="flex flex-col gap-2 p-2 overflow-y-auto bg-background-100 max-h-80">
      {
        rows.map((row, index) => {

          return (
            <div className="flex items-center gap-2" key={row.user}>
              <div className="truncate">
                <VBNumberTextInputComponent
                  label={index === 0 ? "Labeler" : ""}
                  type="text"
                  defaultValue={row.user}
                  disabled
                  displayToolTip
                />
              </div>
              <div className="">
                <VBNumberTextInputComponent
                  label={index === 0 ? "Record per day" : ""}
                  type="number"
                  defaultValue={row.dailyLimit}
                  disabled={disabledLimitPerDay}
                  listenChangedDefault={disabledLimitPerDay}
                  onValueChanged={(v) => onRowChanged && onRowChanged({...row, dailyLimit: v as number})}
                />
              </div>
              <div className="">
                <VBNumberTextInputComponent
                  label={index === 0 ? "Num task" : ""}
                  type="number"
                  defaultValue={row.numTaskInBatch}
                  disabled={disabledNumTask}
                  listenChangedDefault={disabledNumTask}
                  onValueChanged={(v) => onRowChanged && onRowChanged({...row, numTaskInBatch: v as number})}
                />
              </div>
            </div>
          )
        })
      }
    </div>
  )
}