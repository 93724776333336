/*
 * File: cornerstone.config.js
 * Project: app-aiscaler-web
 * File Created: Monday, 9th August 2021 11:04:40 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import dicomParser from "dicom-parser";
import cornerstone from "cornerstone-core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import cornerstoneMath from "cornerstone-math";
import cornerstoneTools from "cornerstone-tools";

import Hammer from "hammerjs";

let __INITIALIZED__ = false;
export default function initCornerstone() {
  if (__INITIALIZED__) return;
  __INITIALIZED__ = true;
  // Cornerstone Tools
  cornerstoneTools.external.cornerstone = cornerstone;
  cornerstoneTools.external.Hammer = Hammer;
  cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
  cornerstoneTools.init();
  cornerstoneTools.toolStyle.setToolWidth(2);
  cornerstoneTools.textStyle.setFont("15px Helvetica");
  cornerstoneTools.store.state.clickProximity = 8;

  // Image Loader
  cornerstoneWebImageLoader.external.cornerstone = cornerstone;
  cornerstone.registerImageLoader("blob", cornerstoneWebImageLoader.loadImage);
  cornerstoneWebImageLoader.configure({
    beforeSend: function (xhr) {
      // Add custom headers here (e.g. auth tokens)
      //xhr.setRequestHeader('x-auth-token', 'my auth token');
    },
  });
  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
  cornerstoneWADOImageLoader.webWorkerManager.initialize({
    maxWebWorkers: navigator.hardwareConcurrency || 1,
    startWebWorkersOnDemand: true,
    taskConfiguration: {
      decodeTask: {
        initializeCodecsOnStartup: false,
        usePDFJS: false,
        strict: false,
      },
    },
  });
}
