import { AnnotationAgreementDTO } from ".";
import {
  TextAnnotation,
  TextAnnotationConnection,
  TextAnnotationLabel,
} from "./text-observation.dto";

// annotation
export interface SystemAnnotation {
  annotation:
    | AudioSTTAnnotationItem
    | AudioTTSAnnotationItem
    | TextAnnotation
    | ImageAnnotationItem;
  annotationType: AnnotationType;
  assignee: string;
  id: number;
  mediaId: number;
  source: string;
}

// image
export interface ImageAnnotationItem {}

// audio
export interface STTTextLabel {
  start: number;
  end: number;
  observationId: number;
}
export interface AudioSTTAnnotationItem {
  text: string;
  note: string;
  textLabels: STTTextLabel[];
  probability?: number;
  observationId?: number;
}
export interface AudioTTSAnnotationItem {
  audioResourceId?: string;
  text: string;
  note: string;
  probability?: number;
  observationId?: number;
}

// types
export enum AnnotationType {
  IMAGE = "image",
  TEXT = "text",
  SPEECH_TO_TEXT = "audio_stt",
  TEXT_TO_SPEECH = "text_tts",
}

// response, request
export interface TaskObservationDTOV2 {
  annotationAgreementDTOS: AnnotationAgreementDTO[];
  annotations:
    | AudioSTTAnnotationItem[]
    | AudioTTSAnnotationItem[]
    | TextAnnotation[]
    | ImageAnnotationItem[];
  annotationType: AnnotationType;
  jobId: number;
  taskId: number;
  assignee: string;
}
export interface AnnotationRequest {
  annotations:
    | AudioSTTAnnotationItem[]
    | AudioTTSAnnotationItem[]
    | TextAnnotation[]
    | ImageAnnotationItem[];
  annotationType?: AnnotationType;
  jobId: number;
}

export interface TextAnnotationsResponse {
  jobId: number;
  taskId: number;
  assignee: string;
  annotationAgreementDTOS: any[];
  annotations: {
    mediaId: number;
    annotationType: string;
    annotation: {
      labels: TextAnnotationLabel[];
      relations: TextAnnotationConnection[];
    }[];
  };
}
