/* tslint:disable */
/* eslint-disable */
/**
 * user
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const Constants = {
    Labeler: 'labeler',
    Customer: 'customer'
} as const;

export type Constants = typeof Constants[keyof typeof Constants];


/**
 * 
 * @export
 * @interface GroupDTO
 */
export interface GroupDTO {
    /**
     * 
     * @type {string}
     * @memberof GroupDTO
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupDTO
     */
    'groupId'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupDTO
     */
    'lastSelect'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GroupDTO
     */
    'labeler'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroupDTO
     */
    'customer'?: boolean;
}
/**
 * 
 * @export
 * @interface GroupRequestDTO
 */
export interface GroupRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof GroupRequestDTO
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupRequestDTO
     */
    'groupId'?: string;
    /**
     * list of users to added as customer when created group
     * @type {Array<string>}
     * @memberof GroupRequestDTO
     */
    'customers'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GroupResponseDTO
 */
export interface GroupResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof GroupResponseDTO
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupResponseDTO
     */
    'groupId'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupResponseDTO
     */
    'lastSelect'?: number;
    /**
     * list of users to added as customer when created group
     * @type {Array<string>}
     * @memberof GroupResponseDTO
     */
    'customers'?: Array<string>;
}
/**
 * 
 * @export
 * @interface HealthGetResponse
 */
export interface HealthGetResponse {
    /**
     * 
     * @type {string}
     * @memberof HealthGetResponse
     */
    'status'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HealthGetResponse
     */
    'groups'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'totalItem'?: number;
}
/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'currentPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'newPassword'?: string;
}
/**
 * 
 * @export
 * @interface RestResponse
 */
export interface RestResponse {
    /**
     * 
     * @type {string}
     * @memberof RestResponse
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponse
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponse
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseGroup
 */
export interface RestResponseGroup {
    /**
     * 
     * @type {Array<GroupDTO>}
     * @memberof RestResponseGroup
     */
    'payload'?: Array<GroupDTO>;
    /**
     * 
     * @type {string}
     * @memberof RestResponseGroup
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseGroup
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseGroup
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseGroup
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseOfGroup
 */
export interface RestResponseOfGroup {
    /**
     * 
     * @type {GroupDTO}
     * @memberof RestResponseOfGroup
     */
    'payload'?: GroupDTO;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfGroup
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfGroup
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfGroup
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfGroup
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseOfGroupResponseDTO
 */
export interface RestResponseOfGroupResponseDTO {
    /**
     * 
     * @type {GroupResponseDTO}
     * @memberof RestResponseOfGroupResponseDTO
     */
    'payload'?: GroupResponseDTO;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfGroupResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfGroupResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfGroupResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfGroupResponseDTO
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseUser
 */
export interface RestResponseUser {
    /**
     * 
     * @type {Array<UserDTO>}
     * @memberof RestResponseUser
     */
    'payload'?: Array<UserDTO>;
    /**
     * 
     * @type {string}
     * @memberof RestResponseUser
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseUser
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseUser
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseUser
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseUserInfo
 */
export interface RestResponseUserInfo {
    /**
     * 
     * @type {UserInfoDTO}
     * @memberof RestResponseUserInfo
     */
    'payload'?: UserInfoDTO;
    /**
     * 
     * @type {string}
     * @memberof RestResponseUserInfo
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseUserInfo
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseUserInfo
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseUserInfo
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface SelectGroupDTO
 */
export interface SelectGroupDTO {
    /**
     * 
     * @type {string}
     * @memberof SelectGroupDTO
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'tags'?: string;
}
/**
 * 
 * @export
 * @interface UserInfoDTO
 */
export interface UserInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof UserInfoDTO
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDTO
     */
    'companyAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDTO
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDTO
     */
    'companySize'?: UserInfoDTOCompanySizeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserInfoDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDTO
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDTO
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDTO
     */
    'preferredUsername'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDTO
     */
    'website'?: string;
}

export const UserInfoDTOCompanySizeEnum = {
    Large: 'LARGE',
    Medium: 'MEDIUM',
    Small: 'SMALL'
} as const;

export type UserInfoDTOCompanySizeEnum = typeof UserInfoDTOCompanySizeEnum[keyof typeof UserInfoDTOCompanySizeEnum];

/**
 * 
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'action'?: UserRequestActionEnum;
    /**
     * 
     * @type {UserDTO}
     * @memberof UserRequest
     */
    'user'?: UserDTO;
    /**
     * 
     * @type {GroupDTO}
     * @memberof UserRequest
     */
    'group'?: GroupDTO;
}

export const UserRequestActionEnum = {
    Join: 'join',
    Leave: 'leave'
} as const;

export type UserRequestActionEnum = typeof UserRequestActionEnum[keyof typeof UserRequestActionEnum];


/**
 * ActuatorApi - axios parameter creator
 * @export
 */
export const ActuatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get health status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get liveness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthLivenessGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/health/liveness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get readiness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthReadinessGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/health/readiness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActuatorApi - functional programming interface
 * @export
 */
export const ActuatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActuatorApiAxiosParamCreator(configuration)
    return {
        /**
         * Get health status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementHealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementHealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get liveness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementHealthLivenessGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementHealthLivenessGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get readiness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementHealthReadinessGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementHealthReadinessGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActuatorApi - factory interface
 * @export
 */
export const ActuatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActuatorApiFp(configuration)
    return {
        /**
         * Get health status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthGet(options?: any): AxiosPromise<object> {
            return localVarFp.managementHealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get liveness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthLivenessGet(options?: any): AxiosPromise<object> {
            return localVarFp.managementHealthLivenessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get readiness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthReadinessGet(options?: any): AxiosPromise<object> {
            return localVarFp.managementHealthReadinessGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActuatorApi - object-oriented interface
 * @export
 * @class ActuatorApi
 * @extends {BaseAPI}
 */
export class ActuatorApi extends BaseAPI {
    /**
     * Get health status of backend service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public managementHealthGet(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).managementHealthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get liveness status of backend service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public managementHealthLivenessGet(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).managementHealthLivenessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get readiness status of backend service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public managementHealthReadinessGet(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).managementHealthReadinessGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add new group
         * @param {GroupDTO} [groupDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        addNewGroup: async (groupDTO?: GroupDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GroupRequestDTO} [groupRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewGroupV2: async (groupRequestDTO?: GroupRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a group
         * @param {GroupRequestDTO} [groupRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup: async (groupRequestDTO?: GroupRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list available group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableGroup: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary select group
         * @param {SelectGroupDTO} [selectGroupDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectGroup: async (selectGroupDTO?: SelectGroupDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/group/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectGroupDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add new group
         * @param {GroupDTO} [groupDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async addNewGroup(groupDTO?: GroupDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNewGroup(groupDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GroupRequestDTO} [groupRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNewGroupV2(groupRequestDTO?: GroupRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfGroupResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNewGroupV2(groupRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a group
         * @param {GroupRequestDTO} [groupRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroup(groupRequestDTO?: GroupRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroup(groupRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list available group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAvailableGroup(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAvailableGroup(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary select group
         * @param {SelectGroupDTO} [selectGroupDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectGroup(selectGroupDTO?: SelectGroupDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectGroup(selectGroupDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupApiFp(configuration)
    return {
        /**
         * 
         * @summary add new group
         * @param {GroupDTO} [groupDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        addNewGroup(groupDTO?: GroupDTO, options?: any): AxiosPromise<RestResponseOfGroup> {
            return localVarFp.addNewGroup(groupDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GroupRequestDTO} [groupRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewGroupV2(groupRequestDTO?: GroupRequestDTO, options?: any): AxiosPromise<RestResponseOfGroupResponseDTO> {
            return localVarFp.addNewGroupV2(groupRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a group
         * @param {GroupRequestDTO} [groupRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(groupRequestDTO?: GroupRequestDTO, options?: any): AxiosPromise<RestResponse> {
            return localVarFp.deleteGroup(groupRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list available group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableGroup(options?: any): AxiosPromise<RestResponseGroup> {
            return localVarFp.listAvailableGroup(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary select group
         * @param {SelectGroupDTO} [selectGroupDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectGroup(selectGroupDTO?: SelectGroupDTO, options?: any): AxiosPromise<void> {
            return localVarFp.selectGroup(selectGroupDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addNewGroup operation in GroupApi.
 * @export
 * @interface GroupApiAddNewGroupRequest
 */
export interface GroupApiAddNewGroupRequest {
    /**
     * 
     * @type {GroupDTO}
     * @memberof GroupApiAddNewGroup
     */
    readonly groupDTO?: GroupDTO
}

/**
 * Request parameters for addNewGroupV2 operation in GroupApi.
 * @export
 * @interface GroupApiAddNewGroupV2Request
 */
export interface GroupApiAddNewGroupV2Request {
    /**
     * 
     * @type {GroupRequestDTO}
     * @memberof GroupApiAddNewGroupV2
     */
    readonly groupRequestDTO?: GroupRequestDTO
}

/**
 * Request parameters for deleteGroup operation in GroupApi.
 * @export
 * @interface GroupApiDeleteGroupRequest
 */
export interface GroupApiDeleteGroupRequest {
    /**
     * 
     * @type {GroupRequestDTO}
     * @memberof GroupApiDeleteGroup
     */
    readonly groupRequestDTO?: GroupRequestDTO
}

/**
 * Request parameters for selectGroup operation in GroupApi.
 * @export
 * @interface GroupApiSelectGroupRequest
 */
export interface GroupApiSelectGroupRequest {
    /**
     * 
     * @type {SelectGroupDTO}
     * @memberof GroupApiSelectGroup
     */
    readonly selectGroupDTO?: SelectGroupDTO
}

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 
     * @summary add new group
     * @param {GroupApiAddNewGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public addNewGroup(requestParameters: GroupApiAddNewGroupRequest = {}, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).addNewGroup(requestParameters.groupDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GroupApiAddNewGroupV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public addNewGroupV2(requestParameters: GroupApiAddNewGroupV2Request = {}, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).addNewGroupV2(requestParameters.groupRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a group
     * @param {GroupApiDeleteGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public deleteGroup(requestParameters: GroupApiDeleteGroupRequest = {}, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).deleteGroup(requestParameters.groupRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list available group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public listAvailableGroup(options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).listAvailableGroup(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary select group
     * @param {GroupApiSelectGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public selectGroup(requestParameters: GroupApiSelectGroupRequest = {}, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).selectGroup(requestParameters.selectGroupDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Export list of user to csv file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUserList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get All member in group by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMemberGroupById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAllMemberGroupById', 'id', id)
            const localVarPath = `/api/v1/group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Import list of user from csv file
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importUserList: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('importUserList', 'file', file)
            const localVarPath = `/api/v1/user/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary join/leave user to group
         * @param {UserRequest} [userRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manageUserToGroup: async (userRequest?: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Export list of user to csv file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportUserList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportUserList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get All member in group by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMemberGroupById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMemberGroupById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Import list of user from csv file
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importUserList(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importUserList(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary join/leave user to group
         * @param {UserRequest} [userRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manageUserToGroup(userRequest?: UserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manageUserToGroup(userRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Export list of user to csv file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUserList(options?: any): AxiosPromise<object> {
            return localVarFp.exportUserList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get All member in group by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMemberGroupById(id: string, options?: any): AxiosPromise<RestResponseUser> {
            return localVarFp.getAllMemberGroupById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Import list of user from csv file
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importUserList(file: File, options?: any): AxiosPromise<void> {
            return localVarFp.importUserList(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary join/leave user to group
         * @param {UserRequest} [userRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manageUserToGroup(userRequest?: UserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.manageUserToGroup(userRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAllMemberGroupById operation in UserApi.
 * @export
 * @interface UserApiGetAllMemberGroupByIdRequest
 */
export interface UserApiGetAllMemberGroupByIdRequest {
    /**
     * id
     * @type {string}
     * @memberof UserApiGetAllMemberGroupById
     */
    readonly id: string
}

/**
 * Request parameters for importUserList operation in UserApi.
 * @export
 * @interface UserApiImportUserListRequest
 */
export interface UserApiImportUserListRequest {
    /**
     * 
     * @type {File}
     * @memberof UserApiImportUserList
     */
    readonly file: File
}

/**
 * Request parameters for manageUserToGroup operation in UserApi.
 * @export
 * @interface UserApiManageUserToGroupRequest
 */
export interface UserApiManageUserToGroupRequest {
    /**
     * 
     * @type {UserRequest}
     * @memberof UserApiManageUserToGroup
     */
    readonly userRequest?: UserRequest
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Export list of user to csv file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public exportUserList(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).exportUserList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get All member in group by id
     * @param {UserApiGetAllMemberGroupByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAllMemberGroupById(requestParameters: UserApiGetAllMemberGroupByIdRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAllMemberGroupById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Import list of user from csv file
     * @param {UserApiImportUserListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public importUserList(requestParameters: UserApiImportUserListRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).importUserList(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary join/leave user to group
     * @param {UserApiManageUserToGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public manageUserToGroup(requestParameters: UserApiManageUserToGroupRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).manageUserToGroup(requestParameters.userRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserInfoResourceApi - axios parameter creator
 * @export
 */
export const UserInfoResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary changePassword
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST: async (resetPasswordRequest?: ResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-infos/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getUserInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-infos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateUserInfo
         * @param {number} id id
         * @param {UserInfoDTO} [userInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInfoUsingPUT: async (id: number, userInfoDTO?: UserInfoDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserInfoUsingPUT', 'id', id)
            const localVarPath = `/api/v1/user-infos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInfoDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInfoResourceApi - functional programming interface
 * @export
 */
export const UserInfoResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserInfoResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary changePassword
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePasswordUsingPOST(resetPasswordRequest?: ResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePasswordUsingPOST(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getUserInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfoUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseUserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfoUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateUserInfo
         * @param {number} id id
         * @param {UserInfoDTO} [userInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserInfoUsingPUT(id: number, userInfoDTO?: UserInfoDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseUserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserInfoUsingPUT(id, userInfoDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserInfoResourceApi - factory interface
 * @export
 */
export const UserInfoResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserInfoResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary changePassword
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST(resetPasswordRequest?: ResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.changePasswordUsingPOST(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getUserInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoUsingGET(options?: any): AxiosPromise<RestResponseUserInfo> {
            return localVarFp.getUserInfoUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateUserInfo
         * @param {number} id id
         * @param {UserInfoDTO} [userInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInfoUsingPUT(id: number, userInfoDTO?: UserInfoDTO, options?: any): AxiosPromise<RestResponseUserInfo> {
            return localVarFp.updateUserInfoUsingPUT(id, userInfoDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for changePasswordUsingPOST operation in UserInfoResourceApi.
 * @export
 * @interface UserInfoResourceApiChangePasswordUsingPOSTRequest
 */
export interface UserInfoResourceApiChangePasswordUsingPOSTRequest {
    /**
     * 
     * @type {ResetPasswordRequest}
     * @memberof UserInfoResourceApiChangePasswordUsingPOST
     */
    readonly resetPasswordRequest?: ResetPasswordRequest
}

/**
 * Request parameters for updateUserInfoUsingPUT operation in UserInfoResourceApi.
 * @export
 * @interface UserInfoResourceApiUpdateUserInfoUsingPUTRequest
 */
export interface UserInfoResourceApiUpdateUserInfoUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof UserInfoResourceApiUpdateUserInfoUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {UserInfoDTO}
     * @memberof UserInfoResourceApiUpdateUserInfoUsingPUT
     */
    readonly userInfoDTO?: UserInfoDTO
}

/**
 * UserInfoResourceApi - object-oriented interface
 * @export
 * @class UserInfoResourceApi
 * @extends {BaseAPI}
 */
export class UserInfoResourceApi extends BaseAPI {
    /**
     * 
     * @summary changePassword
     * @param {UserInfoResourceApiChangePasswordUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoResourceApi
     */
    public changePasswordUsingPOST(requestParameters: UserInfoResourceApiChangePasswordUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return UserInfoResourceApiFp(this.configuration).changePasswordUsingPOST(requestParameters.resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getUserInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoResourceApi
     */
    public getUserInfoUsingGET(options?: AxiosRequestConfig) {
        return UserInfoResourceApiFp(this.configuration).getUserInfoUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateUserInfo
     * @param {UserInfoResourceApiUpdateUserInfoUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoResourceApi
     */
    public updateUserInfoUsingPUT(requestParameters: UserInfoResourceApiUpdateUserInfoUsingPUTRequest, options?: AxiosRequestConfig) {
        return UserInfoResourceApiFp(this.configuration).updateUserInfoUsingPUT(requestParameters.id, requestParameters.userInfoDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


