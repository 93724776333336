import { Entity } from "./collection";
import { IconType } from "react-icons";

export interface IEntityAction<T extends Entity> {
  entities: T[];
  action?: string;
}

export enum EntityAction {
  SELECT = "SELECT",
  VIEW = "view",
  EDIT = "edit",
  DELETE = "delete",
  UPDATE_STATUS = "update_status",
}

export interface IAction {
  id: string;
  text: string;
  icon?: IconType;
  onClick?(): void;
  disabled?: boolean;
}
