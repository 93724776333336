/*
 * File: labeler-dashboard.page.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 22nd November 2021 11:42:04 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { IconArrowDropdown } from "components/common/vb-icon.component";
import { OpenProjectList } from "../labeler-projects/components/open-project/open-project-list.component";
import { CompletedTaskChart } from "./components/completed-task-chart.component";

export const LabelerDashboardPage = () => {
  const score = 2.5;
  const maxScore = 5;

  return (
    <div className="flex flex-col items-center w-full mb-2 bg-background-50">
      <div className="w-full p-6">
        <h2 className="flex-1 text-lg font-bold text-left text-warning-500">
          Dashboard
        </h2>
      </div>
      <div className="flex flex-row w-full gap-6 px-6 py-1 overflow-hidden">
        <div className="flex-none bg-white rounded shadow h-80 w-80">
          <h3 className="p-4 font-semibold text-background-700">Your score</h3>

          <div className="px-4">
            <div className="relative w-full h-4 mb-1">
              <span
                className="absolute top-0 text-sm font-bold transform -translate-x-1/2 text-warning-500"
                style={{ left: `${(100 * score) / maxScore}%` }}
              >
                <IconArrowDropdown className="w-6 h-6" />
              </span>
            </div>
            <div className="relative h-3 rounded-full bg-background-50">
              <span
                className="absolute top-0 left-0 h-full rounded-l bg-warning-500"
                style={{ width: `${(100 * score) / maxScore}%` }}
              />
            </div>

            <div className="relative h-4 mt-1 text-sm text-background-500">
              <span className="absolute top-0 left-0 transform -translate-x-1/2">
                0
              </span>
              <span className="absolute top-0 transform -translate-x-1/2 left-full">
                {maxScore}
              </span>
              <span
                className="absolute top-0 text-sm font-bold transform -translate-x-1/2 text-warning-500"
                style={{ left: `${(100 * score) / maxScore}%` }}
              >
                {score}
              </span>
            </div>
          </div>

          <div className="px-4 mt-4">
            <div className="py-4 text-center rounded bg-background-50">
              <div className="text-sm text-background-500">
                Average time labeling
              </div>
              <p className="mt-1">
                <span className="text-lg font-semibold text-warning-500">
                  9
                </span>
                <span className="text-background-700">/8.5 hours</span>
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-3 px-4 mt-3">
            <div className="py-4 text-center rounded bg-background-50">
              <div className="text-sm text-background-500">Accuracy</div>
              <p className="mt-1 text-lg font-semibold text-warning-500">90%</p>
            </div>
            <div className="py-4 text-center rounded bg-background-50">
              <div className="text-sm text-background-500">Missed dealine</div>
              <p className="mt-1">
                <span className="text-lg font-semibold text-warning-500">
                  3
                </span>
                <span className="text-background-700">/30</span>
              </p>
            </div>
          </div>
        </div>
        <CompletedTaskChart />
      </div>
      <OpenProjectList />
    </div>
  );
};
