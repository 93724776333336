import { AxiosResponse } from "axios";
import { DatasetDTO } from "services/label-service/dtos";
import { storageAPIpost } from "../storage-httpclient";

export function uploadFilesId(
  files: File[],
  datasetId: number = -1,
): Promise<AxiosResponse<DatasetDTO>> {
  const formData = new FormData();
  for (let file of files) {
    formData.append("uploadedFile", file, file.name);
  }
  let url = `/api/v1/files/upload-id`
  if (datasetId !== -1){
    url = `${url}?datasetId=${datasetId}`
  }

  return storageAPIpost(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}
