/*
 * File: dataset.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */
import { BaseAPI } from "../../base-service/api/base.api";
import { DatasetDTO } from "../dto/dataset.dto";
import { ExportedDatasetDTO } from "../dto/exported-dataset.dto";

export class DatasetAPI extends BaseAPI<DatasetDTO> {
  getExportedDatasets(): Promise<ExportedDatasetDTO[]> {
    return this.getItems({
      "type.equals": "exported",
      sort: "createdDate,desc",
    }).then((res) => res.data);
  }

  getAllDatasources(params: Record<string, string>): Promise<DatasetDTO[]> {
    return this.getItems({
      "type.equals": "normal",
      sort: "createdDate,desc",
      size: "10000",
      ...params,
    }).then((res) => res.data);
  }
}
