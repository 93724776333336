/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * File: workspace.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useState } from "react";
import { VBMaskRequesting } from "components/common/vb-mask-requesting/vb-mask-requesting.component";
import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { setActiveCornerstoneTool } from "store/labeler/image-workspace/dicom-editor/dicom-editor.slice";
import {
  selectImageLabelingError,
  selectWorkingBatch,
  selectWorkingJob,
  selectWorkingProject,
} from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { BatchDetailDialog } from "../components/batch-detail-dialog/batch-detail-dialog.component";
import { usePreviousBatch } from "../hooks/use-previous-batch.hook";
import { ALERT_ACTION_TYPE, useSavingJob } from "../hooks/use-saving-job.hook";
import { useSelectObservation } from "../hooks/use-select-observation.hook";
import { ImageLabelingContext } from "./image-labeling.context";
import { WarningModal } from "../components/warning-modal/warning-modal.component";

interface Props {
  children: JSX.Element;
}

export const ImageLabelingProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showLabelDetail, setShowLabelDetail] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const failedToLoadJobData = useAppSelector(selectImageLabelingError);
  const workingJob = useAppSelector(selectWorkingJob);
  const batch = useAppSelector(selectWorkingBatch);
  const project = useAppSelector(selectWorkingProject);
  const [showPreviousJobAnnotations, setShowPreviousJobAnnotations] =
    useState(false);
  const { showBatchInstruction, setShowBatchInstruction } =
    usePreviousBatch(workingJob);

  const {
    error,
    setError,
    saving,
    submitting,
    onSaveJobs,
    onCompleteJobs,
    onClickSaveJob,
    onClickCompleteJob,
  } = useSavingJob({ imageLoaded, setRequesting });

  const {
    selectLabelById,
    selectLabelByIndex,
    selectNextLabel,
    selectPreviousLabel,
  } = useSelectObservation();

  function selectCornerstoneTool(type: ToolName) {
    dispatch(setActiveCornerstoneTool(type));
  }

  async function saveCurrentJob() {
    return onClickSaveJob();
  }

  async function submitCurrentJob() {
    return onClickCompleteJob();
  }

  const state = {
    saving,
    submitting,
    selectCornerstoneTool,
    imageLoaded,
    setImageLoaded,
    requesting,
    setRequesting,
    showLabelDetail,
    setShowLabelDetail,
    selectLabelById,
    selectLabelByIndex,
    selectNextLabel,
    selectPreviousLabel,
    saveCurrentJob,
    submitCurrentJob,
    showPreviousJobAnnotations,
    setShowPreviousJobAnnotations,
  };

  async function handleSubmitWarningModal() {
    setError(undefined);

    if (ALERT_ACTION_TYPE.SAVE === error?.type) {
      return onSaveJobs();
    }

    if (ALERT_ACTION_TYPE.COMPLETE === error?.type) {
      return onCompleteJobs();
    }
  }

  function handleCancelWarningModal() {
    setError(undefined);
  }

  return (
    <ImageLabelingContext.Provider value={state}>
      {children}
      {/* {!imageLoaded && !failedToLoadJobData && <VBMaskRequesting />} */}
      {imageLoaded && showBatchInstruction && (
        <BatchDetailDialog
          batch={batch}
          project={project}
          onClose={() => setShowBatchInstruction(false)}
        />
      )}
      {requesting && !failedToLoadJobData && (
        <VBMaskRequesting
          showProgress={false}
          hideBackdrop
          className="bg-blueGray-700"
        />
      )}
      {saving && <VBMaskRequesting showProgress={false} hideBackdrop />}
      {submitting && <VBMaskRequesting showProgress={false} hideBackdrop />}
      {error && (
        <WarningModal
          open={true}
          content={error.message}
          onSubmit={handleSubmitWarningModal}
          onCancel={handleCancelWarningModal}
        />
      )}
    </ImageLabelingContext.Provider>
  );
};
