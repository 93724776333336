/*
 * File: task.dto.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 4th August 2021 2:35:55 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Point } from "utilities/math/point";
import { BaseDTO } from "./base.dto";
import { JobDTO } from "./job.dto";

export interface TaskDTO extends BaseDTO {
  batchId: number;
  jobs: JobDTO[];
  status: string;
  reviewStatus: string;
  customerReviewStatus: string;
  storeType: string;
  taskReference: string;
  fileName: string;
  assignee: string[];
  issueStat?: Record<string, number>;
  thumbnailUrl?: string;
  workspaceId?: string;
  data?: TaskDataDTO;
}

export interface TaskDataDTO {
  annotations?: TaskAnnotationDTO[];
  image?: {
    width: number;
    height: number;
    thumbnailUrl?: string;
    url?: string;
  };
  source?: string;
}

export interface TaskAnnotationDTO {
  id: number;
  labelId: number;
  label?: string;
  type: string;
  points: Point[];
  color: string;
  source?: string;
  hidden?: boolean;
}

export enum TaskStatus {
  INITIAL = "initial",
  WAITING = "waiting",
  AVAILABLE = "available",
  WORKING = "working",
  COMPLETED = "completed",
  DELIVERED = "delivered",
  LOCKED = "locked",
  REMOVED = "removed",
}

export enum TaskReviewStatus {
  NONE = "none",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export enum TaskCustomerReviewStatus {
  NONE = "none",
  APPROVED = "approved",
  REJECTED = "rejected",
  REOPENED = "reopened",
  RESOLVED = "resolved",
}

export enum TaskAction {
  REOPEN = "reopen",
  SKIP = "skip",
  REVIEW = "review",
  DELETE = "delete",
  ASSIGN = "assign",
}
