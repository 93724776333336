/*
 * File: smart-labeling.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 18th December 2021 8:53:08 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AIAssistanceService } from "services/ai-assistance-service";
import { RootState } from "store";
import { RequestStatus } from "store/base/base.state";
import { TextLabelPair, TextLabel, TextAIState } from "./text-ai.state";
import {
  selectTextLabelingFile,
  selectTextLabels,
} from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { selectTextAIJobId, selectTextAIStatus } from "./text-ai.selectors";
import { ObservationAnnotationType } from "services/label-service/dtos";

export enum TextAIThunk {
  INIT_SMART_LABELING_ASYNC = "text-ai/initTextAIAsync",
  AUTO_ANNOTATE_TEXT_ASYNC = "text-ai/autoAnnotateTextAsync",
}

export const initTextAIAsync = createAsyncThunk(
  TextAIThunk.INIT_SMART_LABELING_ASYNC,
  async (jobId: number, { getState }) => {
    if (jobId === -1) return null;
    const root = getState() as RootState;
    const status = selectTextAIStatus(root);
    const textAIJobId = selectTextAIJobId(root);
    if (jobId === textAIJobId && status !== RequestStatus.IDLE) return null;

    const labels = selectTextLabels(root);

    const textLabels: { [key: number]: TextLabel } = {};
    labels.forEach(({ observation }) => {
      const id = observation.id;
      if (
        observation?.observationSetting?.annotationType ===
        ObservationAnnotationType.ENTITY
      ) {
        textLabels[id] = { id: id, name: observation.name };
      }
    });

    const aiLabels = await _loadTextClasses();

    return {
      jobId,
      textLabels,
      aiLabels,
    };
  }
);

let cachedClasses: {
  [key: number]: TextLabel;
};
const _loadTextClasses = async (): Promise<{
  [key: number]: TextLabel;
}> => {
  if (cachedClasses && Object.keys(cachedClasses).length > 0)
    return cachedClasses;
  const classes: { [key: number]: TextLabel } = {};
  const classListData = await AIAssistanceService.getTextClasses();
  if (!classListData.data || classListData.data.length === 0) return {};
  for (const textClass of classListData.data) {
    const id = parseInt(textClass.id);
    classes[id] = { id, name: textClass.name };
  }
  if (!cachedClasses || Object.keys(cachedClasses).length === 0) {
    cachedClasses = classes;
  }
  return classes;
};

export const autoAnnotateTextAsync = createAsyncThunk(
  TextAIThunk.AUTO_ANNOTATE_TEXT_ASYNC,
  async (pairs: TextLabelPair[], { getState }) => {
    const root = getState() as RootState;
    const selectedPairs = pairs.filter((p) => p.selected);
    const file = selectTextLabelingFile(root);
    if (!file) return [];
    const foundAnnotations = await AIAssistanceService.autoDetectTextOnFile(
      file.url,
      selectedPairs
    );
    return foundAnnotations;
  }
);

export const textAIReducerBuilder = (
  builder: ActionReducerMapBuilder<TextAIState>
) => {
  return builder
    .addCase(initTextAIAsync.pending, (state) => {
      state.status = RequestStatus.LOADING;
    })
    .addCase(initTextAIAsync.rejected, (state, action) => {
      state.status = RequestStatus.FAILURE;
    })
    .addCase(initTextAIAsync.fulfilled, (state, action) => {
      if (action.payload) {
        let isSameBatch = true;
        for (let key of Object.keys(state.textLabels)) {
          if (!action.payload.textLabels.hasOwnProperty(key)) {
            isSameBatch = false;
            break;
          }
        }

        state.jobId = action.payload.jobId;
        state.textLabels = action.payload.textLabels;
        state.aiLabels = action.payload.aiLabels;
        state.status = RequestStatus.SUCCESS;
        if (!isSameBatch) state.labelPairs = [];
        state.enable = false;
      }
    });
};
