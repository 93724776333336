/*
 * File: vb-breadcrumb-item.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 18th August 2021 11:51:33 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Link } from "react-router-dom";
import { IconArrowRight } from "../vb-icon.component";
import { Breadcrumb } from "./breadcrumb.model";

export const BreadcrumbItem = ({
  breadcrumb: { url, text, disabled },
  truncate = false,
}: {
  breadcrumb: Breadcrumb;
  truncate?: boolean;
}) => {
  const truncateText = () => {
    if (truncate && text.length > 24) {
      return (
        text.substring(0, text.indexOf(" ")) +
        " ... " +
        text.substring(text.lastIndexOf(" "), text.length)
      );
    }
    return text;
  };
  if (disabled || !url)
    return (
      <div className="text-lg font-bold text-primary whitespace-nowrap">
        {truncateText()}
      </div>
    );
  return (
    <>
      <Link to={url}>
        <button className="text-sm capitalize cursor-pointer hover:underline whitespace-nowrap">
          {truncateText()}
        </button>
      </Link>
      <IconArrowRight className="flex-shrink-0 w-4 h-4 text-background-500" />
    </>
  );
};
