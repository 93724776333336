/*
 * File: text-workspace.state.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 5:12:04 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  INITIAL_TEXT_CONFLICT_STATE,
  TextConflictState,
} from "./text-conflict/text-conflict.state";
import {
  INITIAL_TEXT_EDITOR_STATE,
  TextEditorState,
} from "./text-editor/text-editor.state";
import {
  INITIAL_TEXT_ISSUES_STATE,
  TextIssuesState,
} from "./text-issues/text-issues.state";
import {
  INITIAL_TEXT_LABELING_STATE,
  TextLabelingState,
} from "./text-labeling/text-labeling.state";
import {
  INITIAL_TEXT_REVIEW_STATE,
  TextReviewState,
} from "./text-review/text-review.state";

export interface TextWorkspaceState {
  textLabeling: TextLabelingState;
  textEditor: TextEditorState;
  textReview: TextReviewState;
  textIssues: TextIssuesState;
  textConflict: TextConflictState;
}

export const INITIAL_TEXT_WORKSAPCE_STATE: TextWorkspaceState = {
  textLabeling: INITIAL_TEXT_LABELING_STATE,
  textEditor: INITIAL_TEXT_EDITOR_STATE,
  textReview: INITIAL_TEXT_REVIEW_STATE,
  textIssues: INITIAL_TEXT_ISSUES_STATE,
  textConflict: INITIAL_TEXT_CONFLICT_STATE,
};
