/*
 * File: labeler-report-by-date.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 16th November 2021 4:08:29 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */
import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import { useLabelerReportByDate } from "../hooks/labeler-report-by-date.hook";
import { LabelerReportByDateTable } from "./labeler-report-by-date-table.component";


interface Props {
  fromDate: Date;
  toDate: Date;
}

export const LabelerReportByDate = ({ fromDate, toDate }: Props) => {
  const {
    isLoading,
    totalRow,
    displayRows,
    filter,
    setFilterField,
  } = useLabelerReportByDate(fromDate, toDate);

  return (
    <div className="w-full h-full flex flex-col gap-4">
      <div className="flex-auto">
        <LabelerReportByDateTable 
          rows={displayRows}
          filter={filter}
          setFilterField={setFilterField}
          isLoading={isLoading}
        />
      </div>

      {filter && (
        <div className="flex-none">
          <GridPagination
            page={filter.page}
            pageSize={filter.pageSize}
            pageSizeOptions={[10, 15, 20, 25, 50, 75, 100]}
            onPageSizeChange={v => setFilterField("pageSize", v)}
            onPageChange={v => setFilterField("page", v)}
            totalItems={totalRow}
          />
        </div>
      )}
    </div>
  );
};
