import { MLModelType } from "domain/web-builder";
import { MLWeb, ServingWebStatus } from "domain/web-builder/ml-web";
import moment from "moment";
import { ServingWebResponseDTO } from "services/label-service/dtos/serving-web.dto";

export const mlWebMapper = {
  toEntity: (dto: ServingWebResponseDTO): MLWeb => {
    const item: MLWeb = {
      id: dto.id,
      name: dto.name,
      description: dto.description,
      createdBy: dto.createdBy,
      lastOpened: moment(new Date(dto.lastActivity)).local().toDate(),
      status: dto.status as ServingWebStatus,
      type: dto.type as MLModelType,
      data: dto.data || {},
    };

    return item;
  },
  fromEntity: (entity: MLWeb): ServingWebResponseDTO => {
    throw new Error("Method not implemented");
  },
};
