/*
 * File: labeler-distribution.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 4:56:02 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ProjectService } from "services/label-service";
import { RootState } from "store";
import {
  addEntity,
  requestFulfilled,
  requestPending,
  requestRejected,
} from "store/base/base.reducer";
import {
  LabelerDistributionState,
  LabelDistributionDTO,
} from "./labeler-distribution.state";

export enum LabelerDistributionThunk {
  LOAD_LABELER_DISTRIBUTION = "projectData/loadLabelerDistributionAsyc",
}

export const loadProjectLabelerDistributionAsyc = createAsyncThunk(
  LabelerDistributionThunk.LOAD_LABELER_DISTRIBUTION,
  async (projectId: number, { getState }) => {
    const state = getState() as RootState;
    if (state.projectData.labelerDistribution.ids.includes(projectId)) {
      return state.projectData.labelerDistribution.entities[projectId];
    }
    const response = await ProjectService.getF1Distribution(projectId);
    const labelerData: Record<string, LabelDistributionDTO> = {};
    const items: LabelDistributionDTO[] = response.data;
    for (let item of items) {
      labelerData[item.email] = item;
    }
    return { id: projectId, labelers: labelerData };
  }
);

export const labelerDistributionReducerBuilder = <
  S extends { labelerDistribution: LabelerDistributionState }
>(
  builder: ActionReducerMapBuilder<S>
): ActionReducerMapBuilder<S> => {
  return builder
    .addCase(loadProjectLabelerDistributionAsyc.pending, (state) => {
      requestPending(state.labelerDistribution);
    })
    .addCase(loadProjectLabelerDistributionAsyc.rejected, (state, action) => {
      requestRejected(state.labelerDistribution, action.error);
    })
    .addCase(loadProjectLabelerDistributionAsyc.fulfilled, (state, action) => {
      requestFulfilled(state.labelerDistribution);
      if (action.payload) {
        addEntity(state.labelerDistribution, action.payload);
      }
    });
};
