/*
 * File: storage.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 10:10:06 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { ENV_CONFIG } from "configs/env.config";

import {
  Entity,
  Filter,
  IPagination,
  IPaginationResponse,
} from "domain/common";
import { AuthService } from "services/auth";
import { BaseFilter } from "./types";
import {
  Configuration,
  Pagination,
  UserApi,
  UserDTO,
} from "data-access/generated/user";
import { newUniqueId } from "utilities/number/id-generator";

export module UsersApi {
  function getConfiguration() {
    const Scope = AuthService.getUserScope();
    const Workspace = AuthService.getUserWorkspace();
    const Authorization = `Bearer ${AuthService.getAccessToken()}`;
    return new Configuration({
      basePath: ENV_CONFIG.USER_SERVICE_URL,
      baseOptions: { headers: { Authorization, Scope, Workspace } },
    });
  }

  function getPagination(
    pagination?: Pagination,
    filter?: Filter
  ): IPagination {
    return {
      page: pagination?.pageNumber ?? filter?.page ?? 0,
      size: pagination?.pageSize ?? filter?.size ?? 20,
      totalCount: pagination?.totalItem ?? 0,
    };
  }

  export interface IUser extends Entity {
    id: number;
    userName?: string;
    email: string;
    role?: string;
    userId?: string;
    tags?: string;
  }

  class UserFilter extends BaseFilter {
    getWorkspaceId() {
      return this.getStringField("workspaceId");
    }

    setWorkspaceId(workspaceId?: string) {
      return this.setQuery("workspaceId", workspaceId);
    }
  }

  export interface IUserMapper {
    fromDTO(dto: UserDTO): IUser;
  }

  export class UserMapper implements IUserMapper {
    fromDTO(dto: UserDTO): IUser {
      return {
        id: newUniqueId(),
        userName: dto.userName,
        email: dto.email ?? "",
        role: dto.role,
        userId: dto.userId,
        tags: dto.tags,
      };
    }
  }

  export async function getAllUsers(
    filter: Filter
  ): Promise<IPaginationResponse<IUser>> {
    const configuration = getConfiguration();
    const api = new UserApi(configuration);
    const userFilter = new UserFilter(filter);
    const params = { id: userFilter.getWorkspaceId() ?? "" };
    const response = await api.getAllMemberGroupById(params);
    const mapper = new UserMapper();
    const items = (response.data.payload ?? []).map(mapper.fromDTO);
    const pagination = getPagination(response.data.pagination, filter);
    return { data: items, pagination };
  }
}
