/*
 * File: image-set-tasks.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 22nd September 2022 3:48:43 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestStatus } from "store/base/base.state";
import { BatchLabelingState, ImageLabelingJob } from "../batch-labeling.state";
import { collectionUtils } from "domain/common";
import { RootState } from "store";
import { selectMammographyLabelingTasks } from "store/labeler/mammography-labeling-task/mammography-labeling-task.selectors";

const THUNK_NAME = "batch-labeling/imageSetTasksAsync";

export const imageSetTasksAsync = createAsyncThunk(
  THUNK_NAME,
  async (_, { getState }) => {
    const state = getState() as RootState;
    const labelingTasks = selectMammographyLabelingTasks(state);
    return { labelingTasks };
  }
);

export const imageSetTasksReducerBuilder = (
  builder: ActionReducerMapBuilder<BatchLabelingState>
) => {
  return builder
    .addCase(imageSetTasksAsync.pending, (state) => {
      state.requestStatus = RequestStatus.LOADING;
    })
    .addCase(imageSetTasksAsync.fulfilled, (state, action) => {
      const { labelingTasks } = action.payload;
      state.requestStatus = RequestStatus.SUCCESS;
      if (labelingTasks.length === 0) return;
      state.project = labelingTasks[0].project;
      state.pollStrategy = labelingTasks[0].project?.pollStrategy;

      state.imageLabelingJobs = {
        allIds: [],
        entities: {},
      };

      for (const labelingTask of labelingTasks) {
        const { jobs } = labelingTask;
        if (!jobs || jobs.length === 0) continue;
        const job = jobs[0];
        const labelingJob: ImageLabelingJob = {
          id: job.id,
          job: job,
          labels: labelingTask.labels,
          batch: labelingTask.batch?.dto,
          project: labelingTask.project,
          task: labelingTask.task,
        };
        collectionUtils.addOne(state.imageLabelingJobs, labelingJob);
      }

      // state.imageLabelingJobs = collectionUtils.fromEntities(labelingTasks);
      const allIds = state.imageLabelingJobs.allIds as number[];
      state.currentJobId = allIds[0];

      state.jobRequestStatuses = {};
      state.labels = collectionUtils.fromEntities(labelingTasks[0].labels);
      for (let jobId of state.imageLabelingJobs.allIds) {
        const labelingJob = collectionUtils.getOne(
          state.imageLabelingJobs,
          jobId
        );
        state.jobRequestStatuses[jobId as number] = RequestStatus.NONE;
        if (labelingJob?.data) {
          state.jobRequestStatuses[jobId as number] = RequestStatus.SUCCESS;
        }
      }

      const imageViews = allIds.slice(0, 4) as number[];
      state.imageViews = imageViews;
    })
    .addCase(imageSetTasksAsync.rejected, (state) => {
      state.requestStatus = RequestStatus.FAILURE;
    });
};
