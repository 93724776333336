/*
 * File: text-label.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 22nd December 2021 4:03:34 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Label, LabelType } from "domain/text-labeling";
import { BatchObservationDTO } from "../dtos";

export const textLabelMapper = (
  batchObservation: BatchObservationDTO
): Label => {
  const { observationSetting } = batchObservation.observation;
  const disableSelection =
    observationSetting?.hasOwnProperty("selectable") &&
    !observationSetting.selectable;

  const isSystemLabel = !!observationSetting?.systemAttribute;
  return {
    id: batchObservation.observation.id.toString(),
    name: batchObservation.observation.name,
    color: batchObservation.observation.observationSetting.color,
    isSystemLabel,
    allowConnection: true, // always allow connection
    probability: batchObservation.observation.probability
      ? batchObservation.observation.priority
      : 1,
    type: batchObservation.observation.observationSetting
      .annotationType as LabelType,
    observation: batchObservation.observation,
    disableSelection: disableSelection,
  };
};
