/*
 * File: poll-text-jobs.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 11th July 2022 4:26:46 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { collectionUtils } from "domain/common";
import { LabelingFile } from "domain/labeling/file";
import { Job } from "domain/labeling/job";
import { LabelingJob } from "domain/labeling/labeling-job";
import { Project } from "domain/labeling/project";
import { JobService, ProjectServiceV2 } from "services/label-service";
import { jobMapper } from "services/label-service/mappers/job.mapper";
import { projectMapper } from "services/label-service/mappers/project.mapper";
import { RequestStatus } from "store/base/base.state";
import { textUtils } from "store/labeler/text-workspace/utils/text-labeling.utils";
import { LabelingBatchState } from "../text-labeling-batch.state";

const THUNK_NAME = "textLabelingBatch/textPollJobsAsync";

export const textPollJobsAsync = createAsyncThunk(
  THUNK_NAME,
  async ({ projectId, jobId }: { projectId?: string; jobId?: string }) => {
    const labelingJobs: LabelingJob[] = [];
    let jobs: Job[] = [];
    let project: Project | undefined = undefined;

    if (projectId) {
      project = await _loadProjectInfoById(parseInt(projectId));
      jobs = await _pollJobsByProjectId(parseInt(projectId));
    } else if (jobId) {
      jobs = await _pollJobByIds(jobId);
    }
    if (jobs.length === 0) return { project, labelingJobs };

    const batchId = jobs[0].batchId;
    const batch = await textUtils.getBatchById(batchId);
    if (!project) project = projectMapper.fromDTO(batch.dto.project);

    const labels = await textUtils.getLabels(batchId);
    for (const job of jobs) {
      const id = job.id;
      const task = await textUtils.getTaskById(job.taskId);
      // const storageFile = await textUtils.getFileById(task.fileId);
      const file: LabelingFile = {
        id: task.fileId,
        name: task.fileName,
        url: "",
        // url: storageFile.url,
      };
      const labelingJob = { id, job, task, batch, labels, file };
      labelingJobs.push(labelingJob);
    }
    return { project, labelingJobs };
  }
);

async function _loadProjectInfoById(
  projectId: number
): Promise<Project | undefined> {
  const projectInfo = await ProjectServiceV2.getItem(projectId);
  if (!projectInfo) return;
  return projectMapper.fromDTO(projectInfo.data.project);
}

async function _pollJobsByProjectId(projectId: number): Promise<Job[]> {
  const storedValue = localStorage.getItem("JOB_PER_TEXT_BATCH");
  const jobPerBatch = storedValue ? parseInt(storedValue.replace(/"/g, "")) : 1;
  const response = await JobService.pollJobByProject(projectId, jobPerBatch);
  const jobs: Job[] = response.data.map(jobMapper.fromDTO);
  return jobs;
}
async function _pollJobByIds(jobIds: string): Promise<Job[]> {
  const ids: number[] = jobIds.split(",").map((item) => parseInt(item.trim()));
  const promises = ids.map((jobId) => JobService.getReviewJob(jobId));
  const response = await Promise.all(promises);
  return response.map((res) => jobMapper.fromDTO(res.data));
}

export function textPollJobsBuilder(
  builder: ActionReducerMapBuilder<LabelingBatchState>
) {
  return builder
    .addCase(textPollJobsAsync.pending, (state) => {
      state.pollJobStatus = RequestStatus.LOADING;
    })
    .addCase(textPollJobsAsync.fulfilled, (state, action) => {
      const { project, labelingJobs } = action.payload;
      state.pollJobStatus = RequestStatus.SUCCESS;
      state.project = project;
      state.jobs = collectionUtils.fromEntities(labelingJobs);
      state.selectedJobId = -1;
      if (state.jobs.allIds.length > 0) {
        state.selectedJobId = state.jobs.allIds[0] as number;
        state.jobLoadingStatus[state.selectedJobId] = RequestStatus.IDLE;
      }
    })
    .addCase(textPollJobsAsync.rejected, (state) => {
      state.pollJobStatus = RequestStatus.FAILURE;
    });
}
