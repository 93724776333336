import { useParams } from "react-router-dom";
import { ThreeDLabelingProvider } from "./context/three-d-labeling.provider";
import { ThreeDLabelingDetails } from "./three-d-labeling.details";

export const ThreeDLabelingReview = () => {
  const { jobId } = useParams<{ jobId: string }>();

  if (!jobId) return null;

  return (
    <div className="h-screen overflow-scroll hide-scrollbar bg-background-900">
      <ThreeDLabelingProvider
        isFromJobId
        jobIdParam={jobId}
      >
        <ThreeDLabelingDetails />
      </ThreeDLabelingProvider>
    </div> 
  )
}
