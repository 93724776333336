/*
 * File: labeler-projects.page.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 4th August 2021 10:39:57 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { useProjectsByTaskCompleted } from "../labeler-dashboard/use-projects-by-task-completed";
import { OpenProject } from "./components/open-project/open-project.component";

export const LabelerProjectsPage = () => {
  const { data, isLoading } = useProjectsByTaskCompleted();
  if (isLoading) return <VBInlineLoading />;
  if (!data) return <div className="p-10 text-center">Not found</div>;

  return (
    <div className="flex flex-col items-center w-full mb-8 bg-background-50">
      <div className="w-full px-6">
        <h2 className="py-6 text-lg font-bold text-warning-500">My Projects</h2>
        <div className="grid items-center grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {data.map((project) => {
            return <OpenProject key={project.name} data={project} />;
          })}
        </div>
      </div>
    </div>
  );
};
