/*
 * File: file-thumbnail.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th June 2023 3:25:41 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */
import { useWorkspaceContext } from "contexts/workspace";
import { QueryApi } from "data-access/impl/query";
import { useMemo } from "react";
import { VscFile } from "react-icons/vsc";

interface Props {
  file: QueryApi.IFile;
}
export function FilePreview({ file }: Props) {
  const { getUrlWithSasToken } = useWorkspaceContext();
  const previewUrl = useMemo(() => {
    if (!file.referenceUrl) return "";
    return getUrlWithSasToken(file.referenceUrl);
  }, [file, getUrlWithSasToken]);

  const extension = useMemo(() => {
    return file.additionalFileInfo?.extend ?? "";
  }, [file]);

  return (
    <div className="flex items-center justify-center w-full h-full bg-warmGray-100">
      {previewUrl && (
        <img
          alt="thumbnail"
          src={previewUrl}
          className="object-contain w-full h-full"
        />
      )}
      {extension === "txt" && (
        <div className="flex items-center justify-center w-full h-full">
          <VscFile size={32} />
        </div>
      )}
    </div>
  );
}
