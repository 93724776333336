import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { getDeep } from "utilities/object/getDeep";
import TableBaseCell from "./table-base-cell.component";
import { TableBaseRowCollapse } from "./table-base-row-collapse.component";
import { TableBaseRow } from "./table-base-row.component";
import { TableSkeleton } from "./table-skeleton.component";
import { TableBaseBodyProps } from "./types";

const DEFAULT_COUNT_PER_PAGE = 10;

export const TableBaseBody = <T extends object>({
  classNames,
  columns,
  data,
  expandable,
  loading,
  skeCount,
  rowKey,
  ...tableBodyProps
}: TableBaseBodyProps<T>) => {
  const { t } = useTranslation();

  if (loading) {
    const count = skeCount || data.length || DEFAULT_COUNT_PER_PAGE;
    return (
      <TableBody className={classnames(classNames?.body)} {...tableBodyProps}>
        <TableSkeleton columns={columns} skeCount={count} />
      </TableBody>
    );
  }

  if (!data || !data.length) {
    return (
      <TableBody className={classnames("text-center", classNames?.body)}>
        <TableRow>
          <TableBaseCell colSpan={6}>
            {t("dataset:noDataFound")}
          </TableBaseCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody className={classnames(classNames?.body)}>
      {data.map((record: T, dataIndex) => {
        const key = rowKey ? getDeep(record, rowKey) : dataIndex;

        if (expandable) {
          return (
            <TableBaseRowCollapse
              key={key}
              columns={columns.slice(1)}
              dataIndex={dataIndex}
              record={record}
              allowToExpand={expandable.rowExpandable(record)}
              render={() => <div>{expandable.expandedRowRender(record)}</div>}
            />
          );
        }

        return (
          <TableBaseRow
            key={key}
            columns={columns}
            dataIndex={dataIndex}
            record={record}
          />
        );
      })}
    </TableBody>
  );
};
