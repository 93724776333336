import { itkHelper } from "./itk_import";
import { vtkImageData, vtkDataArray } from "./vtk_import";

export function hexToRgb(hex, normalize = true) {
  hex = hex.replace("#", "");
  const bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  if (normalize) {
    r = r / 255;
    g = g / 255;
    b = b / 255;
  }
  return [r, g, b];
}

export function downloadBlob(blob, fileName) {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a"); // Or maybe get it from the current document
  link.href = blobUrl;
  link.download = fileName;
  link.click();
}

export async function dicomFilesToVTKImage(files) {
  const res = await itk.readImageDICOMFileSeries(files);
  const { image: itkImage } = res;
  const vtkImage = itkHelper.convertItkToVtkImage(itkImage);
  return vtkImage;
}

export async function fileToVTKImage(file) {
  const arrayBuffer = await file.arrayBuffer();
  const { image: itkImage, webWorker } = await itk.readImageArrayBuffer(
    null,
    arrayBuffer,
    file.name,
    ""
  );
  webWorker.terminate();
  const vtkImage = itkHelper.convertItkToVtkImage(itkImage);
  return vtkImage;
}

export async function blobToVTKImage(blob, fileName = "file.nii.gz") {
  const file = new File([blob], fileName);
  return await fileToVTKImage(file);
}

export async function itkImageToArrayBuffer(
  itkImage,
  fileName = "labelMap.nii.gz"
) {
  const { arrayBuffer: buffer, webWorker } = await itk.writeImageArrayBuffer(
    null,
    itkImage,
    fileName,
    "",
    true
  );
  webWorker.terminate();
  return buffer;
}

export async function vtkImageToBlob(vtkImage, fileName = "labelMap.nii.gz") {
  const itkImage = itkHelper.convertVtkToItkImage(vtkImage, {}); // we pass empty object in order to copy data so no dettach error.
  const buffer = await itkImageToArrayBuffer(itkImage, fileName);
  const blob = new Blob([buffer]);
  return blob;
}

export async function vtkImageMaskToBlob(
  vtkImage,
  mask,
  fileName = "labelMap.nii.gz"
) {

  // const scalars = vtkImage.getPointData().getScalars();
  // const data = scalars.getData();
  // for (let i = 0; i < data.length; i++) {
  //   if (data[i] !== mask) {
  //     data[i] = 0;
  //   }
  // }
  // scalars.setData(data);
  const itkImageAfter = itkHelper.convertVtkToItkImage(vtkImage, {}); // we pass empty object in order to copy data so no dettach error.
  const bufferAfter = await itkImageToArrayBuffer(itkImageAfter, fileName);
  const blobAfter = new Blob([bufferAfter]);
  return blobAfter;
}

export function createNewLabelMapFromImageData(imageData) {
  // clone background image properties
  const labelMap = vtkImageData.newInstance(
    imageData.get("spacing", "origin", "direction")
  );
  labelMap.setDimensions(imageData.getDimensions());
  labelMap.computeTransforms(); // right now only support 256 labels

  const values = new Uint8Array(imageData.getNumberOfPoints());
  const dataArray = vtkDataArray.newInstance({
    numberOfComponents: 1,
    // labelmap with single component
    values: values,
  });
  labelMap.getPointData().setScalars(dataArray);

  return labelMap;
}
