/*
 * File: use-workflow.hook.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 24th August 2021 11:40:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect } from "react";
import { RequestStatus } from "store/base/base.state";
import { selectCurrentWorkspaceId } from "store/common/user-workspace/user-workspace.selectors";
import {
  selectWorkflowRequestError,
  selectWorkflowRequestStatus,
  selectWorkflows,
} from "store/customer/workflow/workflow.selectors";
import { resetWorkflows } from "store/customer/workflow/workflow.slice";
import { loadWorkflowsAsync } from "store/customer/workflow/workflow.thunk";

export const useWorkflow = () => {
  const dispatch = useAppDispatch();
  const workflows = useAppSelector(selectWorkflows);
  const requestStatus = useAppSelector(selectWorkflowRequestStatus);
  const requestError = useAppSelector(selectWorkflowRequestError);
  const workspaceId = useAppSelector(selectCurrentWorkspaceId);
  const requestData = useCallback(async () => {
    return dispatch(loadWorkflowsAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetWorkflows());
  }, [workspaceId, dispatch]);

  useEffect(() => {
    if (requestStatus === RequestStatus.IDLE) {
      requestData();
    }
  }, [requestStatus, requestData]);

  return { workflows, requestStatus, requestError, requestData };
};
