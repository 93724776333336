/*
 * File: no-result.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 16th March 2022 10:04:52 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

interface Props {
  onAction(): void;
}

export const NoSearchResult = ({ onAction }: Props) => {
  return (
    <div className="p-10 animate-fade-in-up">
      <div className="flex flex-col items-center space-y-2 text-center">
        <h3 className="text-lg font-semibold">No results found</h3>
        <p className="py-4">There is no results base on current filter</p>
        <button className="button-text-secondary" onClick={onAction}>
          Clear filter
        </button>
      </div>
    </div>
  );
};
