import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import classNames from "classnames";
import { Entity, Filter, getSortOrder } from "domain/common";
import { ITableColumn } from "domain/common/table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ColumnHeader from "../column-header/column-header";
import TableLoading from "../table-loading/table-loading";
import { renderCell } from "../utils";

interface IProps<T extends Entity, F extends Filter> {
  loading: boolean;
  entities: T[];
  columns: ITableColumn<T>[];
  filter: F;
  onSelect?(item: T, action?: string): void;
  onUpdateFilter?(filter: Partial<F>): void;
}

export function EntityTable<T extends Entity, F extends Filter>({
  loading,
  filter,
  entities,
  columns,
  onSelect,
  onUpdateFilter,
}: IProps<T, F>) {
  const { t } = useTranslation();
  const isLoading = useMemo(
    () => loading && entities.length === 0,
    [loading, entities]
  );

  const isEmpty = useMemo(
    () => !loading && entities.length === 0,
    [loading, entities]
  );

  const numColumns = useMemo(
    () => columns.filter((c) => !c.hidden).length,
    [columns]
  );

  function toggleSort(column: ITableColumn<T>) {
    const order = getSortOrder(filter, column.field.toString());
    let nextOrder = undefined;
    if (order === undefined) nextOrder = "asc";
    if (order === "asc") nextOrder = "desc";
    if (order === "desc") nextOrder = "asc";
    onUpdateFilter &&
      onUpdateFilter({
        ...filter,
        sort: { field: column.field, order: nextOrder },
      });
  }

  return (
    <TableContainer className="bg-white border">
      <Table
        className={classNames({
          "opacity-60 pointer-events-none cursor-default": loading,
        })}
      >
        <TableHead>
          <TableRow>
            {columns
              .filter((c) => !c.hidden)
              .map((col) => {
                const order = getSortOrder(filter, col.field.toString());
                return (
                  <TableCell key={col.id} style={{ width: col.width }}>
                    <ColumnHeader
                      column={col}
                      onClick={toggleSort}
                      sortOrder={order}
                    />
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading
            open={isLoading}
            colSpan={numColumns}
            text={t("common:txtLoading")}
          />
          <TableLoading
            open={isEmpty}
            colSpan={numColumns}
            text={t("common:txtNoResult")}
          />
          {entities.map((row) => {
            return (
              <TableRow
                key={row.id}
                className="border-bottom hover:bg-slate-50"
              >
                {columns
                  .filter((c) => !c.hidden)
                  .map((column) => {
                    return (
                      <TableCell
                        style={{ width: column.width }}
                        onClick={() => onSelect && onSelect(row)}
                        key={column.id}
                      >
                        {renderCell(row, column)}
                      </TableCell>
                    );
                  })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EntityTable;
