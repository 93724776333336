/*
 * File: select.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 6th December 2021 11:11:25 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Tooltip } from "@material-ui/core";
import { useRef } from "react";
import Select, { Props as SelectProps } from "react-select";
import Creatable from "react-select/creatable";

const customStyles = ({
  height,
  background = "white",
  color = "black",
  bordered = false,
}: {
  height: number;
  background?: string;
  color?: string;
  bordered?: boolean;
}) => {
  return {
    control: (provided: any) => {
      let props = {
        ...provided,
        fontFamily: "Mulish",
        boxShadow: "none",
        background,
        color,
        border: !bordered && 0,
      };
      if (height > 0) {
        // less than 0 for auto height
        props = { ...props, height };
      }
      return props;
    },
    singleValue: (provided: any) => ({
      ...provided,
      background,
      color,
    }),
  };
};
const inputSizes = {
  sm: 32,
  md: 36,
  lg: 40,
  xl: 48,
  none: -1,
};

export interface SelectOption {
  label: string;
  value: string;
}

interface Props extends SelectProps {
  containerClassName?: string;
  headerClassName?: string;
  header?: string;
  required?: boolean;
  size?: "sm" | "md" | "lg" | "xl" | "none";
  loseFocusAfterSelected?: boolean;
  onChanged?: (newValue: any, actionMeta: any) => void;
  backgroundColor?: string;
  textColor?: string;
  bordered?: boolean;
  hint?: string;
  hintClass?: string;
  onCreateOption?(value: string): void;
}

export const VBSelectComponent = ({
  containerClassName = "text-sm",
  header,
  size = "md",
  loseFocusAfterSelected = false,
  onChange,
  backgroundColor = "white",
  textColor = "black",
  bordered = true,
  required = false,
  headerClassName = "",
  hint,
  hintClass = "",
  ...rest
}: Props) => {
  const selectRef = useRef<any>(null);

  const handleOnChanged = (newValue: any, actionMeta: any) => {
    onChange && onChange(newValue, actionMeta);
    if (loseFocusAfterSelected) {
      selectRef.current.blur();
    }
  };

  return (
    <div className={`flex flex-col gap-2 ${containerClassName}`}>
      {header && (
        <div className="overflow-hidden font-semibold whitespace-nowrap overflow-ellipsis">
          <span className={headerClassName}>{header}</span>{" "}
          {required && (
            <Tooltip title="This field is required">
              <span className="text-red-500">*</span>
            </Tooltip>
          )}
        </div>
      )}
      <Select
        ref={selectRef}
        classNamePrefix="vb-select"
        styles={customStyles({
          height: inputSizes[size],
          background: backgroundColor,
          color: textColor,
          bordered,
        })}
        onChange={handleOnChanged}
        {...rest}
      />
      {hint && (
        <div className={`text-xs text-red-500 ${hintClass}`}>{hint}</div>
      )}
    </div>
  );
};

export const VBSelectCreatableComponent = ({
  containerClassName = "text-sm",
  header,
  size = "md",
  backgroundColor = "white",
  textColor = "black",
  bordered = true,
  onCreateOption,
  ...rest
}: Props) => {
  return (
    <div className={`flex flex-col gap-2 ${containerClassName}`}>
      {header && (
        <div className="overflow-hidden font-semibold whitespace-nowrap overflow-ellipsis">
          {header}
        </div>
      )}
      <Creatable
        classNamePrefix="vb-select"
        styles={customStyles({
          height: inputSizes[size],
          background: backgroundColor,
          color: textColor,
          bordered,
        })}
        onCreateOption={onCreateOption}
        {...rest}
      />
    </div>
  );
};
