import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { JobAnnotation } from "domain/image-labeling/job-annotation";
import { ImageWorkspaceState } from "../../image-workspace.state";

const THUNK_NAME = "imageWorkspace/imageJobAnnotationsLoadedAsync";

interface Payload {
  jobAnnotations: Record<number, JobAnnotation>;
  previousJobAnnotations: Record<number, JobAnnotation[]>;
}

export const imageJobAnnotationsLoadedAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: Payload) => {
    return payload;
  }
);

export function imageJobAnnotationsLoadedBuilder(
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) {
  return builder.addCase(
    imageJobAnnotationsLoadedAsync.fulfilled,
    ({ imageAnnotations }, action) => {
      const { jobAnnotations: allJobAnotations } = action.payload;
      imageAnnotations.jobAnnotations = allJobAnotations;
      imageAnnotations.annotations = [];
      imageAnnotations.jobAnnotations = [];
      for (const key of Object.keys(allJobAnotations)) {
        const jobId = parseInt(key);
        const jobAnnotations = allJobAnotations[jobId];
        for (const annoId of jobAnnotations.annotations.allIds) {
          imageAnnotations.annotations.push(
            jobAnnotations.annotations.entities[annoId]
          );
        }
        for (const annoId of jobAnnotations.relationAnnotations.allIds) {
          imageAnnotations.relationAnnotations.push(
            jobAnnotations.relationAnnotations.entities[annoId]
          );
        }
      }
      imageAnnotations.isLoaded = true;
    }
  );
}
