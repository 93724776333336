import { Token } from "domain/text-labeling";
import { BoundingBox } from "../models/text-viewer.models";

interface Props {
  model: Token;
  box: BoundingBox;
  background?: string;
  color?: string;
  onClick?(): void;
}

export const TokenComponent = ({
  model,
  box,
  color = "#000000",
  background,
  onClick,
}: Props) => {
  return (
    <>
      {background && (
        <rect
          x={box.x - 2}
          y={box.y - box.height / 2 - 4}
          width={box.width + 4}
          height={box.height}
          fill={background}
          opacity={0.5}
        />
      )}
      <text
        onClick={onClick}
        id={model.id}
        data-token-id={model.id}
        data-token-index={model.index}
        x={box.x}
        y={box.y}
        textLength={box.width}
        fill={color}
      >
        {model.text}
      </text>
    </>
  );
};
