/*
 * File: entity-actions.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 23rd May 2023 9:56:17 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { IconButton, Tooltip } from "@material-ui/core";
import { Entity, UniqueId } from "domain/common";
import { IAction } from "domain/common/entity-action";
import { useTranslation } from "react-i18next";

interface Props<T extends Entity> {
  actions: IAction[];
  entities: T[];
  selectedIds: UniqueId[];
}
export function EntityActions<T extends Entity>({
  actions,
  selectedIds,
}: Props<T>) {
  const { t } = useTranslation();

  if (selectedIds.length === 0) return null;

  return (
    <div className="flex items-center gap-4 py-2">
      <span>
        {t("common:txtRecordsSelected", { total: selectedIds.length })}
      </span>

      {actions.map((action) => {
        const ActionIcon = action.icon;
        return (
          <Tooltip key={action.id} title={action.text}>
            <span>
              <IconButton onClick={action.onClick} disabled={action.disabled}>
                {ActionIcon && <ActionIcon size={16} />}
              </IconButton>
            </span>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default EntityActions;
