/*
 * File: text-labeling-task.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 15th July 2022 3:03:38 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AnnotationSource } from "domain/labeling/annotation-source";
import { RootState } from "store";

export const selectTextLabelingTaskLoadingStatus = (state: RootState) => {
  return state.textLabelingTask.loadingStatus;
};

export const selectTextLabelingTaskSelectedAnnotator = (state: RootState) => {
  return state.textLabelingTask.selectedAssignees;
};

export const selectTextLabelingTaskAnnotators = (state: RootState) => {
  return state.textLabelingTask.annotators;
};

export const selectTextLabelingTaskJobs = (state: RootState) => {
  return state.textLabelingTask.jobs;
};

export const selectTextLabelingTaskFile = (state: RootState) => {
  return state.textLabelingTask.file;
};

interface StepAnnotators {
  step: number;
  annotators: string[];
}
export const selectTextLabelingTaskAnnotatorsByStep = (state: RootState) => {
  const items: StepAnnotators[] = [];
  const step0: StepAnnotators = { step: 0, annotators: [] };
  for (const labelingData of state.textLabelingTask.labelingDatas) {
    if (
      labelingData?.source === AnnotationSource.IMPORT ||
      labelingData?.source === AnnotationSource.MODEL
    ) {
      step0.annotators.push(labelingData.annotator);
    }
  }
  if (step0.annotators.length > 0) items.push(step0);

  for (const job of state.textLabelingTask.jobs) {
    if (!job.assignee) continue;
    const stepAnnotators = items.find((item) => item.step === job.step);
    if (!stepAnnotators) {
      items.push({ step: job.step, annotators: [job.assignee] });
    } else {
      stepAnnotators.annotators.push(job.assignee);
    }
  }
  items.sort((a, b) => a.step - b.step);
  return items;
};
