import { IconAddCircle, IconSearchZoomIn, IconTrash } from "components/common/vb-icon.component"
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { Fragment } from "react";
import { useRef, useState } from "react";
import { IllustrationItem } from "services/label-service/dtos"
import { classnames } from "utilities/classes";
import { AppError } from "utilities/errors/errors";
import { v4 } from "uuid";


export interface IllustrationItemUIModel extends IllustrationItem {
  id: string;
  file?: File;
  error?: AppError;
}

interface Props {
  items: IllustrationItemUIModel[];
  onChanged?: (items: IllustrationItemUIModel[]) => void;
}
export const LabelIllustrations = ({
  items,
  onChanged,
}: Props) => {
  const inputFileRef = useRef<any>(null);
  const [viewItem, setViewItem] = useState<IllustrationItemUIModel>();

  const handleClickAddIllustration = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  }

  const handleFileChanged = (e: any) => {
    const fileList = e.target.files;
    if (!fileList) {
      return;
    };

    if (items.length > 20) return;

    const newItems: IllustrationItemUIModel[] = [];
    for (let i = 0; i < fileList.length; i++) {
      if (i === 5) break;
      const newFile = fileList.item(i);
      let error: AppError | undefined = undefined;
      if (newFile?.size > 1024 * 1024 * 5) {
        error = new AppError("file_large", "File too large!");
      };
      const newItem: IllustrationItemUIModel = {
        id: v4(),
        active: "false",
        note: "",
        resourceId: "",
        url: error ? "" : URL.createObjectURL(newFile),
        file: newFile,
      };
      newItems.push(newItem);
    }

    if (newItems.length > 0) {
      onChanged && onChanged([...items, ...newItems]);
    }
  }

  const onItemChanged = (changedItem: IllustrationItemUIModel) => {
    const newItems = items.map(item => {
      if (item.id === changedItem.id){
        return {...changedItem};
      }
      return item;
    });
    onChanged && onChanged(newItems);
  }

  const onItemClickedDelete = (clickedItem: IllustrationItemUIModel) => {
    const newItems = items.filter(item => item.id !== clickedItem.id);
    onChanged && onChanged(newItems);
  }

  return (
    <Fragment>
      <div className="flex flex-wrap gap-2 items-center">
        {
          items.map(item => (
            <LabelIllustrationItem
              key={item.id}
              item={item}
              onChanged={onItemChanged}
              onClickedDelete={onItemClickedDelete}
              onCLickedView={item => setViewItem(item)}
            />
          ))
        }
        <div className={classnames(
          "flex items-center justify-center",
          {"w-8 h-8": items.length <= 0},
          {"w-24 h-24 ": items.length > 0},
        )}>
          <IconAddCircle
            className="w-8 h-8 cursor-pointer text-primary"
            onClick={handleClickAddIllustration}
          />
        </div>
        <input
          className="hidden"
          type="file"
          multiple
          ref={inputFileRef}
          accept="image/png, image/jpeg"
          onChange={handleFileChanged}
          onClick={(e: any) => {
            e.target.value = null;
          }}
        />
      </div>
      {
        !!viewItem &&
        <LabelIllustrationItemView
          open={!!viewItem}
          item={viewItem}
          onClose={(item) => {
            setViewItem(undefined);
            onItemChanged(item);
          }}
        />
      }
    </Fragment>
  )
}


interface LabelIllustrationItemProps {
  item: IllustrationItemUIModel;
  onChanged?: (item: IllustrationItemUIModel) => void;
  onClickedDelete?: (item: IllustrationItemUIModel) => void;
  onCLickedView?: (item: IllustrationItemUIModel) => void;
}
export const LabelIllustrationItem = ({
  item,
  onChanged,
  onClickedDelete,
  onCLickedView,
}: LabelIllustrationItemProps) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className="w-32 h-32 rounded cursor-pointer border broder-background-300 relative flex items-center justify-center"
      style={{
        backgroundImage: `url(${item.url})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      
      {
        isHover &&
        <div className="top-1 right-1 absolute flex items-center gap-1">
          <IconSearchZoomIn
            className="w-4 h-4 text-primary"
            onClick={() => onCLickedView && onCLickedView(item)}
          />
          <IconTrash
            className="w-4 h-4 text-error-300"
            onClick={() => onClickedDelete && onClickedDelete(item)}
          />
        </div>
      }
      
      {
        (item.active !== "true" || isHover) &&
        <button
          className={classnames(
            "px-2 py-1 border rounded bg-primary-100 opacity-60",
            {"text-error": item.active !== "true"},
            {"text-primary": item.active === "true"},
          )}
          onClick={() => onChanged && onChanged({...item, active: item.active === "true" ? "false" : "true"})}
        >
          {
            item.active === "true" && <span>Activated</span>
          }
          {
            item.active !== "true" && <span>Deactivated</span>
          }
        </button>
      }
    </div>
  )
}

interface LabelIllustrationItemViewProps {
  open: boolean;
  item: IllustrationItemUIModel;
  onClose?: (item: IllustrationItemUIModel) => void;
}
export const LabelIllustrationItemView = ({
  open,
  item,
  onClose,
}: LabelIllustrationItemViewProps) => {
  const [localItem, setLocalItem] = useState({...item});

  const handleCLose = () => {
    onClose && onClose({...localItem});
  }

  return (
    <VBModal
      width="30rem"
      open={open}
      title="View note"
      onClose={handleCLose}
      footerHidden
    >
      <div className="flex flex-col gap-2">
        <div
          className="h-80 border rounded"
          style={{
            backgroundImage: `url(${localItem.url})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
        </div>
        <textarea
          className="border rounded p-4"
          placeholder={"Add note"}
          value={localItem.note}
          onChange={(e) => setLocalItem({...localItem, note: e.target.value as string})}
          rows={4}
        />
      </div>
    </VBModal>
  )
}
