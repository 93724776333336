/*
 * File: ai-preview-cornerstone-handler.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 22nd February 2022 3:44:42 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";
import { annotateTypeMapper } from "constants/annotation.constant";
import { MeasurementData } from "components/dicom/cornerstone/models/measurement.model";
import FreehandRoiToolExtend from "components/dicom/cornerstone/tools/FreehandRoiExtend";
import RectangleRoiExtendTool from "components/dicom/cornerstone/tools/RectangleRoiExtend";
import { AnnotationV1, Label } from "domain/image-labeling";
import * as Sentry from "@sentry/react";
// import { observationToAnnotation } from "components/dicom/cornerstone/utils/cornerstone-utils";

interface InitOption {
  showAnnotationLabel: boolean;
  showTextValue: boolean;
  fillAnnotationShape: boolean;
}

interface ResultAnnotation extends AnnotationV1 {
  measurement: MeasurementData;
}

export class AIResultCornerstoneHandler {
  usedToolNames: ToolName[] = [
    ToolName.FreehandRoiExtend,
    ToolName.RectangleRoiExtend,
    ToolName.Zoom,
    ToolName.Pan,
    ToolName.Wwwc,
  ];

  annotations: ResultAnnotation[] = [];
  labels: Label[] = [];

  tool: ToolName | "" = "";
  showLabel: boolean = true;
  fillPolygon: boolean = true;
  displayTextValue: boolean = true;
  activeJobId: string = "";
  activeLabelId: string = "";
  hiddenLabelIds: number[] = [];
  protected _container: HTMLElement;
  constructor(containert: HTMLElement) {
    this._container = containert;
  }

  getContainer() {
    return this._container;
  }

  init({
    showAnnotationLabel,
    showTextValue,
    fillAnnotationShape,
  }: InitOption) {
    this.showLabel = showAnnotationLabel;
    this.fillPolygon = fillAnnotationShape;
    this.displayTextValue = showTextValue;
    cornerstoneTools.init();

    for (let toolName of this.usedToolNames) {
      cornerstoneTools.clearToolState(this.getContainer(), toolName);
    }
    this.addTools();
  }

  addTools() {
    for (let toolName of this.usedToolNames) {
      if (toolName === ToolName.FreehandRoiExtend) {
        cornerstoneTools.addToolForElement(
          this.getContainer(),
          FreehandRoiToolExtend,
          { mouseButtonMask: -1 }
        );
      } else if (toolName === ToolName.RectangleRoiExtend) {
        cornerstoneTools.addToolForElement(
          this.getContainer(),
          RectangleRoiExtendTool,
          { mouseButtonMask: -1 }
        );
      } else {
        cornerstoneTools.addToolForElement(
          this.getContainer(),
          cornerstoneTools[`${toolName}Tool`]
        );
      }
    }
  }

  setFillPolygon(fillPolygon: boolean): void {
    this.fillPolygon = fillPolygon;
    for (let annotation of this.annotations) {
      annotation.measurement.fillPolygon = this.fillPolygon;
    }
    this.updateImage();
  }

  setShowLabel(visible: boolean): void {
    this.showLabel = visible;
    for (let annotation of this.annotations) {
      annotation.measurement.showLabel = this.showLabel;
    }
    this.updateImage();
  }

  setHiddenLabelIds(labelIds: number[]): void {
    this.hiddenLabelIds = labelIds;
    this.updateVisiblility();
  }

  updateVisiblility() {
    for (let annotation of this.annotations) {
      annotation.measurement.visible = !this.hiddenLabelIds.includes(
        annotation.categoryId
      );
    }
    this.updateImage();
  }

  resetAnnotations(): void {
    this.annotations = [];
  }

  setAnnotations(annotations: AnnotationV1[], labels: Label[]): void {
    this.labels = labels;
    this.annotations = [];
    // this.annotations = annotations.map((anno) => {
    //   const annotation = observationToAnnotation({
    //     type: anno.annotation.type,
    //     points: anno.annotation.points,
    //     probability: anno.annotation.probability || -1,
    //     objectTrackingId: "",
    //     freeTextValue: anno.annotation.textValue,
    //     source: anno.assignee,
    //   });
    //   const measurement = annotation.measurement;
    //   const label = this.labels.find((lb) => lb.id === anno.categoryId);
    //   if (label) {
    //     measurement.label = label.name;
    //     measurement.color = label.color;
    //     measurement.locked = true;
    //     measurement.showLabel = this.showLabel;
    //     measurement.displayTextValue = this.displayTextValue;
    //     measurement.fillPolygon = this.fillPolygon;
    //   }
    //   return {
    //     ...anno,
    //     annotationType: anno.annotation.type,
    //     measurement: annotation.measurement,
    //   };
    // });
    cornerstoneTools.clearToolState(
      this.getContainer(),
      ToolName.FreehandRoiExtend
    );
    cornerstoneTools.clearToolState(
      this.getContainer(),
      ToolName.RectangleRoiExtend
    );
    for (let annotation of this.annotations) {
      const cornerstoneToolName = annotateTypeMapper(annotation.annotationType);
      if (!cornerstoneToolName) continue;
      cornerstoneTools.setToolActiveForElement(
        this.getContainer(),
        cornerstoneToolName,
        { mouseButtonMask: 1 }
      );
      cornerstoneTools.addToolState(
        this.getContainer(),
        cornerstoneToolName,
        annotation.measurement
      );
    }
    this.setToolActive(ToolName.Pan);
    this.updateImage();
  }

  setToolPassive(tool: ToolName): void {
    return cornerstoneTools.setToolPassiveForElement(this.getContainer(), tool);
  }

  setToolActive(type: ToolName): void {
    if (this.tool) this.setToolPassive(this.tool);
    this.tool = type;
    cornerstoneTools.setToolActiveForElement(this.getContainer(), type, {
      mouseButtonMask: 1,
    });
    cornerstoneTools.setToolActiveForElement(
      this.getContainer(),
      ToolName.Zoom,
      {
        mouseButtonMask: 2,
      }
    );
    cornerstoneTools.setToolActiveForElement(
      this.getContainer(),
      ToolName.Pan,
      {
        mouseButtonMask: 4,
      }
    );
    if (type === ToolName.ResetAll) {
      cornerstone.reset(this.getContainer());
    }
  }

  updateImage(): void {
    return cornerstone.updateImage(this.getContainer());
  }

  getTool(name: ToolName) {
    return cornerstoneTools.getToolForElement(this.getContainer(), name);
  }

  getToolState(name: ToolName) {
    return cornerstoneTools.getToolState(this.getContainer(), name);
  }

  cancelDrawing(): void {
    const element = this.getContainer();
    const tool = this.getTool(this.tool as ToolName);
    if (!tool) return;
    tool["cancelDrawing"] && tool.cancelDrawing(element);
  }

  resize(forceFitWindow: boolean = true): void {
    return cornerstone.resize(this.getContainer(), forceFitWindow);
  }

  clearToolStates() {
    const element = this.getContainer();
    cornerstoneTools.clearToolState(element, ToolName.Angle);
    cornerstoneTools.clearToolState(element, ToolName.CobbAngle);
    cornerstoneTools.clearToolState(element, ToolName.Eraser);
    cornerstoneTools.clearToolState(element, ToolName.FreehandRoi);
    cornerstoneTools.clearToolState(element, ToolName.FreehandRoiExtend);
    cornerstoneTools.clearToolState(element, ToolName.HeartRuler);
    cornerstoneTools.clearToolState(element, ToolName.RectangleRoi);
    cornerstoneTools.clearToolState(element, ToolName.RectangleRoiExtend);
    cornerstoneTools.clearToolState(element, ToolName.Wwwc);
    cornerstoneTools.clearToolState(element, ToolName.Magnify);
    cornerstoneTools.clearToolState(element, ToolName.Zoom);
    cornerstoneTools.clearToolState(element, ToolName.Pan);
    cornerstoneTools.clearToolState(element, ToolName.Invert);
    cornerstoneTools.clearToolState(element, ToolName.Rotate);
  }

  currentImageId = "";
  setCurrentImageId(imageId?: string) {
    this.currentImageId = imageId || "";
  }

  setDisplayTextValue(display: boolean, refresh = true): void {
    this.displayTextValue = display;
    this.updateDisplayTextValue();
    if (refresh) this.updateImage();
  }

  updateDisplayTextValue() {
    for (let annotation of this.annotations) {
      annotation.measurement.displayTextValue =
        this._getDisplayTextValue(annotation);
    }
    this.updateImage();
  }

  private _getDisplayTextValue(annotation: AnnotationV1) {
    return this.displayTextValue;
  }

  dispose() {
    this.clearToolStates();
    cornerstone.disable(this.getContainer());
    try {
      if (this.currentImageId) {
        cornerstone.imageCache.removeImageLoadObject(this.currentImageId);
      } else cornerstone.imageCache.purgeCache();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }
}
