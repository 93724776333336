/*
 * File: labeler-report.page.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 22nd November 2021 10:25:26 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { IndividualReport } from "./components/individual-report.component";

export const LabelerReportPage = () => {
  return (
    <div className="flex flex-col items-center w-full h-full bg-background-50">
      <IndividualReport />
    </div>
  );
};
