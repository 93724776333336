/*
 * File: workspace.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { CornerstoneHandler } from "components/dicom/cornerstone/cornerstone-handler/cornerstone-handler";
import { CornerstoneViewportEditor } from "components/dicom/cornerstone/viewport-editor/viewport-editor";
import { useAppSelector } from "hooks/use-redux";
import { useRef, useState } from "react";
import { selectIsLoadedWorkspaceData } from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { ImageEditorContext } from "./image-editor.context";
import { ImageEditorState } from "./image-editor.state";

interface Props {
  children: JSX.Element;
}

export const ImageEditorProvider = ({ children }: Props) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const viewportEditor = useRef<CornerstoneViewportEditor>();
  const cornerstoneHandler = useRef<CornerstoneHandler>();
  const [requesting, setRequesting] = useState(false);
  const isLoaded = useAppSelector(selectIsLoadedWorkspaceData);

  const state: ImageEditorState = {
    viewportEditor,
    cornerstoneHandler,
    imageLoaded,
    setImageLoaded,
    requesting,
    setRequesting,
  };

  return (
    <ImageEditorContext.Provider value={state}>
      {isLoaded && children}
      {!isLoaded && (
        <div className="flex items-center justify-center w-full h-full">
          <span className="text-background-500">Loading...</span>
        </div>
      )}
    </ImageEditorContext.Provider>
  );
};
