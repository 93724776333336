import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Project } from "domain/labeling/project";
import { AppError } from "utilities/errors/errors";
import { complexJobsEditorSettingActions } from "./complex-jobs-editor-setting/complex-jobs-editor-setting.actions";
import { ComplexJobInBatch, ComplexJobInBatchStatus, ComplexJobsStoreState, INITIAL_COMPLEX_JOBS_STORE_STATE, WorkingStatus } from "./complex-jobs.state";
import { LoadCombplexJobResponse } from "./complex-jobs.thunk";

export const SLICE_NAME_COMPLEX_JOBS = "complexJobs";

const releaseBatchJob = (batchJob: ComplexJobInBatch) => {
  
}

const slice = createSlice({
  name: SLICE_NAME_COMPLEX_JOBS,
  initialState: INITIAL_COMPLEX_JOBS_STORE_STATE,
  reducers: {
    ...complexJobsEditorSettingActions,
    resetComplexJobs: (
      state: ComplexJobsStoreState,
    ) => {
      for (const batchJob of state.batchJobs) {
        releaseBatchJob(batchJob);
      }
      Object.assign(state, INITIAL_COMPLEX_JOBS_STORE_STATE);
    },
    setComplexJobsProject: (
      state: ComplexJobsStoreState,
      action: PayloadAction<Project>,
    ) => {
      state.project = action.payload;
    },
    setComplexJobsCurrentJobId: (
      state: ComplexJobsStoreState,
      action: PayloadAction<number>,
    ) => {
      state.currentJobId = action.payload;
    },
    setComplexJobsBatchJobs: (
      state: ComplexJobsStoreState,
      action: PayloadAction<ComplexJobInBatch[]>,
    ) => {
      state.batchJobs = [...action.payload];
    },
    setComplexJobsError: (
      state: ComplexJobsStoreState,
      action: PayloadAction<AppError>,
    ) => {
      state.error = action.payload;
    },
    updateComplexJobsJobData: (
      state: ComplexJobsStoreState,
      action: PayloadAction<{
        id: number;
        field: any;
        fieldData: any;
      }>,
    ) => {
      const {id, field, fieldData} = action.payload;
      state.batchJobs = state.batchJobs.map(batchJob => {
        if (batchJob.id === id) {
          return {
            ...batchJob,
            data: {
              ...batchJob.data,
              [field]: fieldData,
            }
          }
        }
        return batchJob;
      });
    },
    setComplexJobsJobData: (
      state: ComplexJobsStoreState,
      action: PayloadAction<{
        id: number;
        data: any;
      }>,
    ) => {
      const {id, data} = action.payload;
      state.batchJobs = state.batchJobs.map(batchJob => {
        if (batchJob.id === id) {
          return {
            ...batchJob,
            data,
          }
        }
        return batchJob;
      });
    },
    setComplexJobsBatchJobFromComplexRes: (
      state: ComplexJobsStoreState,
      action: PayloadAction<LoadCombplexJobResponse>,
    ) => {
      const res = action.payload;
      if (!res.isFromBatch) return;
      state.batchJobs = state.batchJobs.map(batchJob => {
        if (batchJob.id === res.job?.id) {
          return {
            ...batchJob,
            status: ComplexJobInBatchStatus.READY,
            loadJobComplexRes: res,
          }
        }
        return batchJob;
      });
    },
    deleteComplexJobsJobInBatch: (
      state: ComplexJobsStoreState,
      action: PayloadAction<number>,
    ) => {
      const id = action.payload;
      let nextIndex = -1;
      for (let i=0; i<state.batchJobs.length; i++) {
        if (state.batchJobs[i].id === id) {
          releaseBatchJob(state.batchJobs[i]);
          if (state.batchJobs.length > 1) {
            nextIndex = i + 1;
          }
          break;
        }
      }
      if (nextIndex >= 0) {
        state.currentJobId = state.batchJobs[nextIndex % state.batchJobs.length].id;
      } else {
        state.currentJobId = -1;
      }
      state.batchJobs = state.batchJobs.filter(bj => bj.id !== id);
    },
    setComplexJobWorkingStatus: (
      state: ComplexJobsStoreState,
      action: PayloadAction<WorkingStatus>
    ) => {
      const currentJobId = state.currentJobId;
      state.jobWorkingStatus[currentJobId] = action.payload;
    },
    setComplexJobWorkingStatusByJobId: (state: ComplexJobsStoreState, action: PayloadAction<{
      jobId: number,
      status: WorkingStatus
    }>) => {
      const { jobId, status } = action.payload
      state.jobWorkingStatus[jobId] = status
    }
  },
});

export const {
  updateComplexJobsJobData,
  setComplexJobsJobData,
  resetComplexJobs,
  setComplexJobsBatchJobs,
  setComplexJobsError,
  setComplexJobsCurrentJobId,
  setComplexJobsBatchJobFromComplexRes,
  deleteComplexJobsJobInBatch,
  setComplexJobsProject,
  setComplexJobWorkingStatus,
  setComplexJobWorkingStatusByJobId,
  toggleComplexJobsAutoSave,
  setComplexJobsAutoSave
} = slice.actions;

export const complexJobsReducer = slice.reducer;
