/*
 * File: text-labeling.actions.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 5:06:00 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { PayloadAction } from "@reduxjs/toolkit";
import {
  INITIAL_TEXT_LABELING_STATE,
  TextLabelingMode,
  TextLabelingState,
} from "./text-labeling.state";

interface State {
  textLabeling: TextLabelingState;
}

export const textLabelingActions = {
  resetTextLabeingState({ textLabeling }: State) {
    Object.assign(textLabeling, INITIAL_TEXT_LABELING_STATE);
  },
  setShowBatchInstruction(
    { textLabeling }: State,
    action: PayloadAction<boolean>
  ) {
    textLabeling.showBatchInstruction = action.payload;
  },
  setSelectedObservationId: (
    { textLabeling }: State,
    action: PayloadAction<number>
  ) => {
    textLabeling.selectedObservationId = action.payload;
  },
  setTextLabelingMode(
    { textLabeling }: State,
    action: PayloadAction<TextLabelingMode>
  ) {
    textLabeling.mode = action.payload;
  },
};
