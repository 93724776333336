/*
 * File: issue-list-item.component.tsx
 * Project: design-system
 * File Created: Friday, 1st July 2022 11:27:06 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Tooltip } from "@material-ui/core";
import {
  IconCheckedCircle,
  IconTickCircle,
} from "components/common/vb-icon.component";
import { UserAvatar } from "components/common/vb-user-avatar.component";
import { Issue, IssueStatus } from "domain/common/issue";
import { useMemo, MouseEvent } from "react";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import { useDateToAgoString } from "utilities/date-time/date-to-ago-string";
import { SlicingMode } from "../../vtk_import";
import { truncateEmail } from "utilities/string/truncate-email";
const AXIS_OPTIONS = [
  { label: "All axises", value: SlicingMode.NONE },
  {
    value: SlicingMode.I,
    label: "Sagittal (LR)",
  },
  {
    value: SlicingMode.J,
    label: "Coronal (AP)",
  },
  {
    value: SlicingMode.K,
    label: "Axial (SI)",
  },
];

interface Props {
  data: Issue;
  assignee: string;
  selected?: boolean;
  mode: TextLabelingMode;
  onSelect?(issue: Issue): void;
  onClickResolve?(issue: Issue): void;
}

export const IssueListItem = ({
  data,
  assignee,
  mode,
  selected,
  onSelect,
  onClickResolve,
}: Props) => {
  const { creator, content, comments, createdDate, status } = data;
  const timeAgo = useDateToAgoString(new Date(createdDate), new Date());
  const users = useMemo(() => {
    const { creator, comments } = data;
    const items = [creator];
    for (let comment of comments) {
      if (!items.find((item) => item.email === comment.creator.email)) {
        items.push(comment.creator);
      }
    }
    if (!items.find((item) => item.email === assignee)) {
      items.push({
        email: assignee,
        name: truncateEmail(assignee),
      });
    }
    return items;
  }, [data, assignee]);

  function handleClickResolve(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    onClickResolve && onClickResolve(data);
  }

  const hasComments = comments.length > 0;

  return (
    <div
      className={`py-4 space-y-2 cursor-pointer ${
        selected ? "bg-primary-50" : "bg-none"
      }`}
      onClick={() => onSelect && onSelect(data)}
    >
      <div className="flex items-center justify-between gap-4 px-4">
        <div className="flex items-center flex-auto gap-2">
          {users.map((user) => (
            <UserAvatar
              key={user.email}
              name={user.name}
              className="w-6 h-6 text-xs"
            />
          ))}
        </div>
        {mode !== TextLabelingMode.LABELER && status === IssueStatus.OPENED && (
          <Tooltip title="Resolve this issue">
            <button
              className="flex items-center justify-center w-6 h-6"
              onClick={handleClickResolve}
            >
              <IconTickCircle className="flex-none w-5 h-5" />
            </button>
          </Tooltip>
        )}
        {mode !== TextLabelingMode.LABELER &&
          (status === IssueStatus.RESOLVED || status === IssueStatus.FIXED) && (
            <div className="flex items-center justify-center w-6 h-6 text-green-700 rounded">
              <IconCheckedCircle className="flex-none w-5 h-5" />
            </div>
          )}
      </div>
      <div className="px-4 space-x-2 text-sm">
        <strong>{truncateEmail(assignee ?? creator.name)}</strong>
        <span className="text-background-500">{timeAgo}</span>
      </div>
      <p className="px-4">{content}</p>
      <div className="px-4 text-xs text-gray-400">
        <span>
          {AXIS_OPTIONS.find((opt) => opt.value === data.metadata.x)?.label ??
            ""}
        </span>
        {"; "}
        <span>Slice: {data.metadata.y}</span>
      </div>
      {hasComments && (
        <div
          className={`px-4 text-sm ${
            selected ? "text-primary" : "text-background-500"
          }`}
        >
          {comments.length} replies
        </div>
      )}
    </div>
  );
};
