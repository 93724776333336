import { DataCleaningDTO } from "../dto/data-cleaning.dto";
import { storageAPIget, storageAPIpost } from "../storage-httpclient";


export function getDataCleanerTypes() {
  return storageAPIget("api/v1/data-cleaner-types");
}

export function getDataCleaningJobs(options: any) {
  const params = new URLSearchParams(options);
  return storageAPIget(`/api/v1/data-cleaning-job?${params.toString()}`);
}

export function getDataCleaningJobById(id: number) {
  return storageAPIget(`/api/v1/data-cleaning-job/${id}`);
}

export function createDataCleaningJob(dataCleaningJob: Partial<DataCleaningDTO>) {
  return storageAPIpost("/api/v1/data-cleaning-job", dataCleaningJob);
}

