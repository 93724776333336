/*
 * File: help-topic.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 22nd November 2021 2:42:28 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  IconDocument,
  IconFolderFill,
} from "components/common/vb-icon.component";

export interface TopicContent {
  id: string;
  name: string;
}
interface Props {
  topic: string;
  contents: TopicContent[];
  onSelect?(content: TopicContent): void;
}
export const HelpTopic = ({ topic, contents, onSelect }: Props) => {
  return (
    <div
      className="overflow-hidden bg-white border rounded border-background-300"
      style={{ height: "fit-content" }}
    >
      <div
        className="flex items-center gap-1 px-4 bg-background-200"
        style={{ height: "3.25rem" }}
      >
        <IconFolderFill className="flex-shrink-0 w-5 h-5 text-background-700" />
        <span className="flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis">
          {topic}
        </span>
      </div>
      <div className="py-2">
        {contents.map((item) => {
          return (
            <div
              key={item.id}
              onClick={() => onSelect && onSelect(item)}
              className="flex items-center gap-1 px-4 cursor-pointer h-9 hover:bg-background-100"
            >
              <IconDocument className="flex-shrink-0 w-5 h-5 text-background-700" />
              <span className="flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis">
                {item.name}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
