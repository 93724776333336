/*
 * File: project-labeler-distribution.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 13th September 2021 4:32:56 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { VBSelect } from "components/common/vb-select.component";
import { useProjectLabelerDistribution } from "hooks/project/use-project-labeler-distribution.hook";
import { useAppSelector } from "hooks/use-redux";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { RequestStatus } from "store/base/base.state";
import { selectCurrentProject } from "store/customer/project/project.selectors";
import { selectWorkspaceLabelers } from "store/customer/users/users.selectors";
import { ProjectLabelerPerformance } from "./project-labeler-performance.component";

export const ProjectLabelerDistribution = () => {
  const project = useAppSelector(selectCurrentProject);
  const { requestStatus } = useProjectLabelerDistribution(project?.id || -1);
  const projectLabelers = useAppSelector(selectWorkspaceLabelers);
  const [selectedOption, setSelectedOption] = useState("");
  const labelerOptions = useMemo(() => {
    const labelers = [];
    for (let labeler of projectLabelers) {
      if (labelers.indexOf(labeler.email) === -1) {
        labelers.push(labeler.email);
      }
    }
    return [
      { value: "", label: "All" },
      ...labelers.map((email) => ({ value: email, label: email })),
    ];
  }, [projectLabelers]);
  const [labelers, setLabelers] = useState<string[]>([]);
  const [expandedLabeler, setExpandedLabeler] = useState("");

  function handleExpandChange(labeler: string, expand: boolean) {
    if (expand) setExpandedLabeler(labeler);
    else setExpandedLabeler("");
  }

  function handleChange(event: ChangeEvent<{ value: unknown }>) {
    setSelectedOption(event.target.value as string);
  }

  useEffect(() => {
    const selectedLabelers = [
      ...labelerOptions
        .filter((option) => option.value)
        .filter((option) => !selectedOption || selectedOption === option.value)
        .map((option) => option.value),
    ];
    setLabelers(selectedLabelers);
    if (selectedLabelers.length > 0) setExpandedLabeler(selectedLabelers[0]);
  }, [selectedOption, labelerOptions]);

  if (!project?.id) return null;
  if (requestStatus === RequestStatus.LOADING) return <VBInlineLoading />;

  return (
    <div>
      <div className="flex flex-wrap items-center gap-4 mt-4">
        <div>
          <span>Labeler(s): </span>
          <VBSelect
            className="w-48"
            defaultValue=""
            options={labelerOptions}
            onChange={handleChange}
            displayEmpty
            variant="outlined"
          />
        </div>
        <div className="h-6 mx-4 border-r"></div>
        <div className="flex items-center gap-4">
          <span>Order(s): </span>
          <VBSelect
            className="w-32"
            defaultValue=""
            options={[{ value: "", label: "F1 Score" }]}
            displayEmpty
            variant="outlined"
          />
          <VBSelect
            className="w-32"
            defaultValue=""
            options={[{ value: "", label: "Ascending" }]}
            displayEmpty
            variant="outlined"
          />
        </div>
        <button>
          <i className="mr-2 text-lg uir-document text-primary"></i>
          <span className="underline text-primary">
            Download reporting data
          </span>
        </button>
      </div>

      <div>
        {labelers.map((labeler) => {
          if (!labeler) return null;
          return (
            <ProjectLabelerPerformance
              projectId={project?.id}
              key={labeler}
              labeler={{ email: labeler }}
              expanded={labeler === expandedLabeler}
              onExpandChange={(expand: boolean) =>
                handleExpandChange(labeler, expand)
              }
            />
          );
        })}
      </div>
    </div>
  );
};
