import { isObject } from "utilities/object/isObject";

export const uniq = (array: any[], key?: string) => {
  if (!array || !array.length) return [];

  if (isObject(array[0])) {
    const map = new Map(array.map(item =>
      [item[key as string], item]));
    return Array.from(map.values())
  }
  return Array.from(new Set(array))
}