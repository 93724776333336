/*
 * File: batch-labeling.util.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 3rd August 2022 11:13:25 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { collectionUtils } from "domain/common";
import { Annotation, Label } from "domain/image-labeling";
import { JobAnnotation } from "domain/image-labeling/job-annotation";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";
import { AnnotationSource } from "domain/labeling/annotation-source";
import { Batch } from "domain/labeling/batch";
import { LabelingFile } from "domain/labeling/file";
import { Job } from "domain/labeling/job";
import { ImageLabelingData } from "domain/labeling/labeling-data";
import { LabelingJobData } from "domain/labeling/labeling-job";
import { Project } from "domain/labeling/project";
import { Task } from "domain/labeling/task";
import {
  AnnotationServiceV3,
  AnnotationsService,
  BatchObservationService,
  BatchService,
  JobService,
  ProjectServiceV2,
  TaskService,
} from "services/label-service";
import { AnnotationVoteStatus } from "services/label-service/dtos/annotation.dto";
import {
  IMPORT_ASSIGNEE,
  MODEL_ASSIGNEE,
} from "services/label-service/dtos/task-observation-v3.dto";
import { batchMapper } from "services/label-service/mappers/batch.mapper";
import { fileMapper } from "services/label-service/mappers/file.mapper";
import { imageLabelMapper } from "services/label-service/mappers/image-label.mapper";
import { jobMapper } from "services/label-service/mappers/job.mapper";
import { projectMapper } from "services/label-service/mappers/project.mapper";
import { taskMapper } from "services/label-service/mappers/task.mapper";
import { StorageService } from "services/storage";
import { annotationUtils } from "utilities/annotations/annotation-util";

async function pollJobByProject(projectId: string): Promise<Job[]> {
  const response = await JobService.pollJobByProject(parseInt(projectId), 10);
  return response.data.map(jobMapper.fromDTO);
}

async function pollJobById(jobIds: string): Promise<Job[]> {
  const ids: number[] = jobIds.split(",").map((item) => parseInt(item.trim()));
  const promises = ids.map((jobId) => JobService.getReviewJob(jobId));
  const response = await Promise.all(promises);
  return response.map((res) => jobMapper.fromDTO(res.data));
}

async function pollJobs(projectId: string, jobIds: string) {
  if (jobIds) return pollJobById(jobIds);
  if (projectId) return pollJobByProject(projectId);
  return [];
}

async function getLabels(batchId: number): Promise<Label[]> {
  const payload = { batchId: batchId.toString(), size: "500" };
  const response = await BatchObservationService.getItems(payload);
  const labels: Label[] = response.data.map(imageLabelMapper.toLabel);
  labels.sort((a, b) => {
    const aPriority = a.priority || 0;
    const bPriority = b.priority || 0;
    return aPriority - bPriority;
  });
  return labels;
}

async function getBatchById(batchId: number): Promise<Batch> {
  const response = await BatchService.getItem(batchId);
  const entity = batchMapper.fromDTO(response.data.batch);
  return entity;
}

async function getTaskById(taskId: number): Promise<Task> {
  const response = await TaskService.getItem(taskId);
  const entity = taskMapper.fromDTO(response.data);
  return entity;
}

async function getFileById(fileId: number): Promise<LabelingFile> {
  const response = await StorageService.getFileInfoDetails(fileId.toString());
  const entity = fileMapper.fromDTO(response.data);
  return entity;
}

async function getProjectById(projectId: number): Promise<Project> {
  const response = await ProjectServiceV2.getItem(projectId);
  const dto = response.data.project;
  const entity = projectMapper.fromDTO(dto);
  return entity;
}

async function getLabelingJobData(
  job: Job,
  file: LabelingFile,
  labels: Label[]
): Promise<LabelingJobData> {
  const labelingDatas = await labelerGetImageAnnotations(
    job.id,
    job.batchId,
    job.taskId,
    file.id,
    labels
  );

  const systemLabelingData = labelingDatas.find(
    (data) => data.source === AnnotationSource.IMPORT
  );

  const aiLabelingData = labelingDatas.find(
    (data) => data.source === AnnotationSource.MODEL
  );

  const data = labelingDatas.find(
    (data) => data.source === AnnotationSource.CLIENT && data.jobId === job.id
  );

  const previousData = labelingDatas.filter(
    (data) => data.source === AnnotationSource.CLIENT && data.jobId !== job.id
  );

  previousData.forEach((data) => {
    data.annotations.forEach((anno) => {
      anno.nextJobId = job.id;
    });
    data.relationAnnotations.forEach((anno) => {
      anno.nextJobId = job.id;
    });
  });

  return {
    jobData: data,
    previousJobData: previousData,
    importedData: systemLabelingData,
    generatedData: aiLabelingData,
  };
}

async function labelerGetImageAnnotations(
  currentJobId: number,
  batchId: number,
  taskId: number,
  fileId: number,
  labels: Label[]
): Promise<ImageLabelingData[]> {
  const imageLabelingDatas: ImageLabelingData[] = [];
  const labelRecord: Record<string, Label> = {};
  for (const label of labels) {
    labelRecord[label.id] = label;
  }

  // get annotations for import and model sources
  // then we create ImageLabelingData for each source
  // jobId will be -1, -2 for Import and Model cases
  let response = await AnnotationsService.getAnnotationsBySourceImportOrModel(
    batchId,
    fileId
  );
  let { annotations, relationAnnotations } =
    annotationUtils.fromAnnotationJobResponseDTO(response.data, labelRecord);

  const createImageLabelingDataFromAnnos = (
    annos: Annotation[],
    relAnnos: RelationAnnotation[],
    source: string,
    jobId: number = -1,
    annotator?: string
  ): ImageLabelingData => {
    return {
      id: jobId,
      jobId: jobId,
      taskId: taskId,
      fileId: fileId,
      annotator: annotator || "",
      source: source,
      annotations: annos,
      relationAnnotations: relAnnos,
    };
  };

  // add ImageLabelingData for Import source
  const importAnnos = annotations.filter(
    (anno) => anno.source === AnnotationSource.IMPORT
  );
  const importRelAnnos = relationAnnotations.filter(
    (anno) => anno.source === AnnotationSource.IMPORT
  );
  if (importAnnos.length > 0) {
    imageLabelingDatas.push(
      createImageLabelingDataFromAnnos(
        importAnnos,
        importRelAnnos,
        AnnotationSource.IMPORT,
        -1,
        IMPORT_ASSIGNEE
      )
    );
  }

  // add ImageLabelingData for Model source
  const modelAnnos = annotations.filter(
    (anno) => anno.source === AnnotationSource.MODEL
  );
  const modelRelAnnos = relationAnnotations.filter(
    (anno) => anno.source === AnnotationSource.MODEL
  );
  if (modelAnnos.length > 0) {
    imageLabelingDatas.push(
      createImageLabelingDataFromAnnos(
        modelAnnos,
        modelRelAnnos,
        AnnotationSource.MODEL,
        -2,
        MODEL_ASSIGNEE
      )
    );
  }

  // get annotations by jobId, this also response
  // annotations from previous jobIds in the same taskId
  response = await AnnotationsService.getAnnotationsByJobId(currentJobId);
  const annotationVotes = response.data.annotationVotes || [];
  let { annotations: clientAnnos, relationAnnotations: clientRelAnnos } =
    annotationUtils.fromAnnotationJobResponseDTO(response.data, labelRecord);

  // group annotations by jobId
  if (clientAnnos.length > 0) {
    const jobIdToAnnosMap: Record<
      string,
      { annotator: string; annos: Annotation[]; relAnnos: RelationAnnotation[] }
    > = {};
    for (const anno of clientAnnos) {
      const jobId = anno.jobId;
      if (!jobIdToAnnosMap[jobId]) {
        jobIdToAnnosMap[jobId] = {
          annotator: anno.annotator,
          annos: [],
          relAnnos: [],
        };
      }
      jobIdToAnnosMap[jobId].annos.push({ ...anno });
    }
    for (const relAnno of clientRelAnnos) {
      const jobId = relAnno.jobId;
      if (!jobIdToAnnosMap[jobId]) {
        jobIdToAnnosMap[jobId] = {
          annotator: relAnno.annotator,
          annos: [],
          relAnnos: [],
        };
      }
      jobIdToAnnosMap[jobId].relAnnos.push({ ...relAnno });
    }

    // create ImageLabelingData for each jobId
    for (let key of Object.keys(jobIdToAnnosMap)) {
      const jobId = parseInt(key);
      let { annotator, annos, relAnnos } = jobIdToAnnosMap[jobId];

      // we update vote for previous jobIds
      if (jobId !== currentJobId) {
        annos = annos.map((anno) => {
          const annotationVote = annotationVotes.find(
            (annotationVote) => annotationVote.annotationId === anno.id
          );
          const vote =
            annotationVote?.status === AnnotationVoteStatus.UP
              ? 1
              : annotationVote?.status === AnnotationVoteStatus.DOWN
              ? -1
              : 0;
          return {
            ...anno,
            vote,
          };
        });
      }

      imageLabelingDatas.push(
        createImageLabelingDataFromAnnos(
          annos,
          relAnnos,
          AnnotationSource.CLIENT,
          jobId,
          annotator
        )
      );
    }
  }

  return imageLabelingDatas;
}
async function customerGetImageAnnotations(
  batchId: number,
  fileId: number,
  labels: Label[]
) {
  const response = await AnnotationServiceV3.getImageAnnotationsByFile(
    batchId,
    fileId
  );
  const items: Record<number, Label> = {};
  for (const label of labels) {
    items[label.id] = label;
  }
  return annotationUtils.fromAnnotationJobResponseDTO(response.data, items);
}

function labelingDataToJobAnnotation(data: ImageLabelingData): JobAnnotation {
  return {
    id: data.id,
    agrements: data.agreements || [],
    annotations: collectionUtils.fromEntities(data.annotations),
    relationAnnotations: collectionUtils.fromEntities(data.relationAnnotations),
    jobId: data.jobId,
    taskId: data.taskId,
    annotator: data.annotator,
  };
}

export const batchLabelingUtils = {
  pollJobByProject,
  pollJobById,
  pollJobs,
  getLabels,
  getBatchById,
  getTaskById,
  getFileById,
  getLabelingJobData,
  labelingDataToJobAnnotation,
  customerGetImageAnnotations,
  getProjectById,
};
