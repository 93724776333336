// please check the ml/api.yml for dto and field names
// some of the enum names are changed for convenience

// request dtos
export interface MlExperimentRequest {
  name: string;
  workspace_id: string;
}
export interface MlRunRequest {
  run_name?: string;
  description?: string;
  pipeline_id: string;
  run_inputs: MlInputItem[];
  platform?: MlPlatform;
  experiment_id: string;
  run_type: MlRunType;
}
export interface MlCreateModelVersionRequest {
  run_id: string;
  model_name: string;
  description?: string;
}

// response dtos
export interface MlExperimentResponse {
  id: string;
  name: string;
  workspace_id: string;
  created_at: string;
}
export interface MlRunResponse {
  run_id: string;
}
export interface MlRunInfoResponse {
  name?: string;
  description?: string;
  run_id: string;
  run_inputs: MlInputItem[];
  metrics: MlMetricItem[];
  status: MlRunStatus;
  updated_at: string;
  pipeline?: {
    id: string;
    name: string;
  };
}
export interface MlPipelineResponse {
  id: string;
  name: string;
  created_at: string;
  version: string;
  platforms: MlPlatform[];
  pipeline_inputs: Record<MlPipelineType, MlInputItem[]>;
}
export interface MlCreateModelVersionResponse {
  name: string;
  version: string;
}
export interface MlRegisteredModelResponse {
  name: string;
  modelInfo: MlRegisteredModelInfoItem[];
}
export interface MlRegisteredModelInfoItem {
  id: number;
  version: number;
  created_at: string;
}

export interface MlMetricItem {
  name: string;
  color: string;
  type: string;
  x: number[];
  y: number[];
}
export interface MlInputItem {
  name: string;
  value: string | number;
  inrange?: string;
}

export enum MlPlatform {
  azure_ml = "azure_ml",
  kubeflow = "kubeflow",
  onprem = "onprem",
}

export enum MlAnnotationType {
  coco = "coco",
  stt = "stt",
  tts = "tts",
}

export enum MlPipelineType {
  TRAIN_EVAL = "TRAIN_EVAL",
  TEST = "TEST",
  SERVING = "SERVING",
}

export enum MlRunType {
  TRAIN_EVAL = "TRAIN_EVAL",
  TEST = "TEST",
  SERVING = "SERVING",
}

export enum MlRunStatus {
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  TERMINATED = "TERMINATED",
  TERMINATING = "TERMINATING",
}
