/*
 * File: use-web-builder.hook.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 25th February 2022 11:21:13 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useMount } from "ahooks";
import { useState } from "react";
import { ServingWebService } from "services/label-service/apis/serving-web.api";
import { ServingWebStatus } from "services/label-service/dtos/serving-web.dto";
import * as Sentry from "@sentry/react";

export enum WebStatus {
  CHECKING = "",
  DRAFT = "draft",
  PUBLISHING = "publishing",
  DEPUBLISHED = "depublished",
  PUBLISHED = "published",
}
export const useWebHealthCheck = (pageId: string) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(WebStatus.CHECKING);
  async function loadDataAsync() {
    if (loading) return;
    try {
      setLoading(true);
      const response = await ServingWebService.getServingWebStatus(pageId);
      if (response && response.data.status === ServingWebStatus.UP) {
        setStatus(WebStatus.PUBLISHED);
      } else {
        setStatus(WebStatus.DEPUBLISHED);
      }
    } catch (error) {
      Sentry.captureException(error);
      setStatus(WebStatus.PUBLISHING);
    } finally {
      setLoading(false);
    }
  }

  useMount(loadDataAsync);

  return {
    status,
    loading,
    loadDataAsync,
  };
};
