import {
  IconCheckedCircle,
  IconMedalStar,
} from "components/common/vb-icon.component";
import { UserAvatar } from "components/common/vb-user-avatar.component";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

interface Props {
  className?: string;
  emails?: string[];
  max?: number;
  selectedEmails?: string[];
  onSelect?: (emails: string[]) => void;
}

export const ProjectWorkflowAssignLabeler = ({
  className,
  emails = [],
  selectedEmails = [],
  max = Number.MAX_VALUE,
  onSelect,
}: Props) => {
  const { t } = useTranslation();
  const [isChanged, setIsChanged] = useState(false);
  const [filteredEmails, setFilteredEmails] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!emails || !emails.length) return;
    setFilteredEmails(emails);
  }, [emails]);

  const noResult = useMemo(() => {
    return emails.length > 0 && filteredEmails.length === 0;
  }, [filteredEmails, emails]);

  const handleInputChange = (_: any) => {
    if (!inputRef.current) return;
    const query = inputRef.current.value.trim().toLowerCase();
    const filteredEmails = emails.filter(
      (email) => email.toLocaleLowerCase().indexOf(query) !== -1
    );
    setFilteredEmails(filteredEmails);
  };

  const handleSelectLabeler = (email: string) => {
    const idx = selectedEmails.indexOf(email);
    const emails =
      idx === -1
        ? [...selectedEmails, email]
        : [
            ...selectedEmails.filter(
              (selectedEmail) => selectedEmail !== email
            ),
          ];

    onSelect && onSelect(emails);
    setIsChanged(true);
  };

  const handleSelectAll = () => {
    const emails = new Set<string>();

    filteredEmails.forEach((email) => {
      emails.add(email);
    });

    onSelect && onSelect(Array.from(emails));
    setIsChanged(true);
  };

  return (
    <>
      <div
        className={classnames(
          "w-full p-4 bg-background-100 rounded-md overflow-y-auto",
          className
        )}
      >
        <input
          type="text"
          placeholder={t(
            "project:batchDetails.batchWorkflow.placeholderSearchLabeler"
          )}
          autoFocus
          ref={inputRef}
          onChange={handleInputChange}
          className="w-full h-10 px-4 py-2 text-sm border rounded-md bg-background-white focus:border-primary"
        />

        {noResult && (
          <p className="p-6 text-base text-center">
            {t("project:batchDetails.batchWorkflow.noSearchResult")}
          </p>
        )}

        {!noResult && (
          <div className="m-4">
            <button
              className={classnames(
                "flex justify-between items-center w-full rounded-full mb-2 bg-background-200 border border-background-300"
              )}
              onClick={handleSelectAll}
            >
              <div className="flex items-center justify-start gap-2 px-4 py-2">
                <IconMedalStar className="w-6 h-6" />
                <p className="text-base font-medium">All team members</p>
              </div>
            </button>
            {filteredEmails.map((email) => {
              const selected = selectedEmails.indexOf(email) !== -1;
              return (
                <button
                  key={email}
                  className="flex items-center justify-between w-full px-4 py-2 mb-2 rounded-full hover:bg-background-300"
                  onClick={() => handleSelectLabeler(email)}
                >
                  <div className="flex items-center justify-start gap-2">
                    <UserAvatar name={email || ""} className="w-8 h-8" />
                    <p className="text-sm text-background-500">{email}</p>
                  </div>
                  <div
                    className={classnames(selected ? "visible" : "invisible")}
                  >
                    <IconCheckedCircle className="w-6 h-6 text-primary-500" />
                  </div>
                </button>
              );
            })}

            {isChanged && selectedEmails.length < max && (
              <div className="p-2 text-sm">
                {`Warning: The number of labelers(${selectedEmails.length}) is less than the number of round (${max}).`}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
