/*
 * File: import-images.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 23rd February 2022 2:38:04 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

interface Props {
  onFilesSelected(files: File[]): void;
}
export const ImportFilesButton = ({ onFilesSelected }: Props) => {
  function handleFilesChange(event: any) {
    const selectedFiles = event.target.files;
    const files: File[] = [];
    for (let i = 0; i < selectedFiles?.length; i++) {
      const file = selectedFiles.item(i);
      files.push(file);
    }
    onFilesSelected(files);
  }
  return (
    <input
      className="absolute top-0 bottom-0 left-0 right-0 z-10 w-full h-full p-0 m-0 opacity-0 cursor-pointer"
      type="file"
      accept="image/png, image/jpeg"
      multiple
      onChange={handleFilesChange}
    />
  );
};
