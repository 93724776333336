import { useThrottleFn } from "ahooks";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useTracking } from "hooks/use-tracking";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { ActivityStatus } from "services/activity-service/activity.dto";
import { ActivityService } from "services/activity-service/activity.service";
import { selectSystemTrackingTime } from "store/common/app-setting/app-setting.selectors";
import {
  selectComplexJobsCurrentBatchJob,
  selectComplexJobsError,
} from "store/labeler/complex-jobs/complex-jobs.selectors";
import { resetComplexJobs } from "store/labeler/complex-jobs/complex-jobs.slice";
import { ErrorDialog } from "../batch-labeling/components/error-dialog.component";
import { WorkspaceEmpty } from "../image-labeling/components/workspace-empty/workspace-empty.component";
import { AudioJobsNav } from "./audio-jobs-nav.component";
import { SpeechToTextLabelingProvider } from "./context/speech-to-text-labeling.provider";
import { SpeechToTextLabelingDetails } from "./speech-to-text-labeling.details";

export const SpeechToTextLabelingPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const batchError = useAppSelector(selectComplexJobsError);
  const currentJobInBatch = useAppSelector(selectComplexJobsCurrentBatchJob);
  const trackingTime = useAppSelector(selectSystemTrackingTime);
  const jobId = currentJobInBatch?.id;
  const batchId = currentJobInBatch?.data?.batch?.id;

  const { projectId } = useParams<{ projectId: string }>();

  const handleErrorDialogClose = () => {
    history.push(Routes.LABELER_HOME);
  };

  const { run: onActive } = useThrottleFn(
    async () => {
      await ActivityService.createActivity({
        code: ActivityStatus.Active,
        jobId,
        batchId,
      });
    },
    { wait: trackingTime }
  );
  useTracking({ enabled: true, onActive });

  useEffect(() => {
    return () => {
      dispatch(resetComplexJobs());
    };
  }, [dispatch]);

  if (batchError) {
    const errorType = batchError.type;
    if (errorType === "no_job") return <WorkspaceEmpty />;
    if (errorType === "unknow")
      return (
        <ErrorDialog
          visible
          onClose={handleErrorDialogClose}
          message={batchError.message}
        />
      );
  }

  return (
    <div className="flex">
      {projectId && <AudioJobsNav projectId={projectId} />}
      <div className="flex-1 h-screen overflow-scroll hide-scrollbar bg-background-900">
        <SpeechToTextLabelingProvider
          isLoadFromBatch
          jobInBatch={currentJobInBatch}
        >
          <SpeechToTextLabelingDetails fixedMenuIssue />
        </SpeechToTextLabelingProvider>
      </div>
    </div>
  );
};
