/*
 * File: confirm-exit-dialog.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 15th October 2021 10:44:15 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Backdrop, Grow } from "@material-ui/core";
import { MatModal } from "components/material/mat-modal.component";

interface Props {
  visible: boolean;
  onClose(): void;
  onSubmit?(): void;
}

export const ConfirmExitDialog = ({ visible, onClose, onSubmit }: Props) => {
  return (
    <MatModal
      disableBackdropClick
      open={visible}
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={onClose}
      className="flex items-start justify-center"
    >
      <Grow in={visible}>
        <div
          className="relative flex flex-col w-full p-4 mt-40 overflow-hidden bg-white rounded shadow"
          style={{ maxHeight: "40rem", maxWidth: "32rem" }}
        >
          <div className="px-4 py-2 text-xl font-bold">Confirm Exit?</div>
          <p className="p-4 leading-normal text-gray-600">
            All pending changes will be lost, this action can't be undo. Are you
            sure you want to exit
          </p>
          <div className="flex items-center justify-end gap-4">
            <button
              style={{ minWidth: "6rem" }}
              onClick={onClose}
              className="px-4 py-2 rounded hover:bg-blueGray-100"
            >
              No
            </button>
            <button
              onClick={onSubmit}
              style={{ minWidth: "6rem" }}
              className="px-4 py-2 text-white rounded bg-primary hover:bg-opacity-90"
            >
              Yes
            </button>
          </div>
        </div>
      </Grow>
    </MatModal>
  );
};
