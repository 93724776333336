import { fabric } from "fabric";
import { IEvent } from "fabric/fabric-impl";
import { createRectangle } from "./fabric-object.utils";
import { FabricTool } from "./fabric-tool";

export class FabricToolRectangle extends FabricTool {
  isMouseDown: boolean = false;

  currentRectangle: fabric.Rect | undefined;
  originX: number = 0;
  originY: number = 0;

  handleMouseDown(fabricAbsX: number, fabricAbsY: number, e: IEvent) {
    this.isMouseDown = true;
    const pos = new fabric.Point(fabricAbsX, fabricAbsY);

    const rect = createRectangle(
      {
        left: pos.x,
        top: pos.y,
        selectable: false,
      },
      false
    );
    rect.set({
      strokeWidth:
        this.fabricOverlay.objectStrokeWidth / this.fabricCanvas.getZoom(),
    });

    this.fabricCanvas.add(rect);
    this.currentRectangle = rect;

    this.updateObjectAdditionalData(this.currentRectangle);

    this.originX = pos.x;
    this.originY = pos.y;
  }

  handleMouseMove(fabricAbsX: number, fabricAbsY: number, e: IEvent) {
    if (!this.isMouseDown) return;
    if (!this.currentRectangle) return;

    const pos = new fabric.Point(fabricAbsX, fabricAbsY);

    if (pos.x < this.originX) {
      this.currentRectangle.set({ left: pos.x });
    }
    if (pos.y < this.originY) {
      this.currentRectangle.set({ top: pos.y });
    }

    this.currentRectangle.set({
      width: Math.abs(pos.x - this.originX),
      height: Math.abs(pos.y - this.originY),
    });
    this.currentRectangle.setCoords();

    this.fabricCanvas.requestRenderAll();
  }

  handleMouseUp(fabricAbsX: number, fabricAbsY: number, e: IEvent) {
    if (this.currentRectangle) {
      if (
        this.currentRectangle.width === 0 ||
        this.currentRectangle.height === 0
      ) {
        this.fabricCanvas.remove(this.currentRectangle);
      } else {
        this.currentRectangle.bringToFront();
        this.addUtilLabel(this.currentRectangle);
        this.sendObjectAddedEvent(this.currentRectangle);
      }
    }

    this.isMouseDown = false;
    this.currentRectangle = undefined;
  }

  cancelDrawing() {
    if (this.currentRectangle) {
      this.fabricOverlay.removeObject(this.currentRectangle);
    }
    this.isMouseDown = false;
    this.currentRectangle = undefined;
  }

  endDrawing() {
    this.isMouseDown = false;
    this.currentRectangle = undefined;
  }
}
