import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import { LabelingType } from "constants/labeling.constant";
import { useAppDispatch } from "hooks/use-redux";
import { TaskReviewThreeD } from "pages/customer/projects/project-batch/batch-detail/pages/tasks/components/3d/task-review-three-d.component";
import { TaskReviewAudio } from "pages/customer/projects/project-batch/batch-detail/pages/tasks/components/audio/task-review-audio.component";
import { TasksReviewComponent } from "pages/customer/projects/project-batch/batch-detail/pages/tasks/components/tasks-review.component";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BatchObservationService,
  BatchService,
  TaskService,
} from "services/label-service";
import {
  BatchDTO,
  BatchObservationDTO,
  isImageProject,
  TaskDTO,
} from "services/label-service/dtos";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useLabelerReportByJob } from "../hooks/labeler-report-by-job.hook";
import { LabelerReportByJobRow } from "../models/labeler-report.models";
import { LabelerReportByJobTable } from "./labeler-report-by-job-table.component";
import * as Sentry from "@sentry/react";

interface Props {
  dateFilter: {
    fromDate: Date;
    toDate: Date;
  };
}

export const LabelerReportByJob = ({ dateFilter }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isLoading, totalRow, rows, filter, setFilterField, setFilterFields } =
    useLabelerReportByJob(dateFilter.fromDate, dateFilter.toDate);

  useDeepCompareEffect(() => {
    setFilterFields({
      startDate: dateFilter.fromDate,
      endDate: dateFilter.toDate,
      page: 1,
    });
  }, [dateFilter]);

  // review stuff
  const [showReview, setShowReview] = useState(false);
  const currentBatchObservations = useRef<BatchObservationDTO[]>([]);
  const currentTaskReview = useRef<TaskDTO>();
  const currentBatch = useRef<BatchDTO>();
  const [currentJobId, setCurrentJobId] = useState<number>();

  const showReviewRow = async (row: LabelerReportByJobRow) => {
    try {
      console.log("Show task review", row);
      if (row.taskId !== currentTaskReview.current?.id) {
        // get task
        let res: any = await TaskService.getTaskById(row.taskId);
        const task = res.data as TaskDTO;
        currentTaskReview.current = task;
        // get batch
        res = await BatchObservationService.getItems({
          batchId: task.batchId.toString(),
        });
        const batchObservations = res.data as BatchObservationDTO[];
        currentBatchObservations.current = batchObservations;
        currentBatch.current = await BatchService.getBatchById(task.batchId);
      }
      setCurrentJobId(row.jobId);
      setShowReview(true);
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    }
  };

  const handleRowAction = (row: LabelerReportByJobRow, action: string) => {
    if (action === "view") {
      showReviewRow(row);
    }
  };

  const handleReviewClose = () => {
    setShowReview(false);
    setCurrentJobId(undefined);
  };

  const handleReviewNext = () => {
    if (!currentTaskReview) return;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].jobId === currentJobId) {
        const nextRow = rows[(i + 1) % rows.length];
        showReviewRow(nextRow);
        break;
      }
    }
  };

  const getReviewComponentByProject = () => {
    if (
      !currentTaskReview.current ||
      !currentJobId ||
      !currentBatchObservations ||
      !currentBatch.current
    )
      return null;

    const projectType = currentBatch.current.project.type;

    if (isImageProject(projectType))
      return (
        <TasksReviewComponent
          visible={showReview}
          task={currentTaskReview.current}
          batch={currentBatch.current}
          batchObservations={currentBatchObservations.current}
          jobIdsIncludedOnly={[currentJobId]}
          onClose={handleReviewClose}
          onNext={handleReviewNext}
          title={`Job #${currentJobId}`}
          showIssues={false}
        />
      );

    if (
      projectType === LabelingType.AUDIO_STT ||
      projectType === LabelingType.TEXT_TTS
    )
      return (
        <TaskReviewAudio
          open={showReview}
          task={currentTaskReview.current}
          batch={currentBatch.current}
          jobIdsIncludedOnly={[currentJobId]}
          onClose={handleReviewClose}
          onNext={handleReviewNext}
          title={`Job #${currentJobId}`}
          showIssues={false}
        />
      );

    if (
      projectType === LabelingType.MDI_SEGMENTATION ||
      projectType === LabelingType.MED_MDI_SEGMENTATION
    )
      return (
        <TaskReviewThreeD
          open={showReview}
          task={currentTaskReview.current}
          batch={currentBatch.current}
          jobIdsIncludedOnly={[currentJobId]}
          onClose={handleReviewClose}
          onNext={handleReviewNext}
          title={`Job #${currentJobId}`}
          showIssues={false}
        />
      );

    return null;
  };

  return (
    <div className="flex flex-col w-full h-full gap-4">
      <div className="flex items-center gap-4">
        <span>Total job: {totalRow}</span>
      </div>

      <div className="flex-auto">
        <LabelerReportByJobTable
          rows={rows}
          filter={filter}
          setFilterField={(field, value) => {
            setFilterFields({ [field]: value, page: 1 });
          }}
          onRowAction={handleRowAction}
          isLoading={isLoading}
        />
      </div>

      {filter && (
        <div className="flex-none">
          <GridPagination
            page={filter.page}
            pageSize={filter.pageSize}
            pageSizeOptions={[10, 15, 20, 25, 50, 75, 100]}
            onPageSizeChange={(v) => setFilterField("pageSize", v)}
            onPageChange={(v) => setFilterField("page", v)}
            totalItems={totalRow}
          />
        </div>
      )}

      {getReviewComponentByProject()}
    </div>
  );
};
