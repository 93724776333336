import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor.js";

export const getCursorPluginConfigs = () => {
  return CursorPlugin.create({
    showTime: true,
    opacity: "1",
    width: "1px",
    color: "#EA7E00",
    customShowTimeStyle: {
      "background-color": "#EA7E00",
      color: "#FFFFFF",
      padding: "4px",
      "font-size": "12px",
      "border-radius": "1px",
    }
  });
};