/*
 * File: projects.slice.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HTTPStatusCode } from "services/common/http-status";
import { RequestOptions } from "services/common/request-options";
import { ProjectService } from "services/label-service";
import { ProjectDTO } from "services/label-service/dtos";
import { AtLeast } from "types/common";
import { INITIAL_PROJECTS_STATE, ProjectsState } from "./projects.state";

const SLICE_NAME: string = "projects";

export const loadProjectsAsync = createAsyncThunk(
  `${SLICE_NAME}/loadProjectsAsync`,
  async (options: RequestOptions) => {
    const response = await ProjectService.getItems(options);
    return response.data;
  }
);

export const createProjectAsync = createAsyncThunk(
  `${SLICE_NAME}/createProjectAsync`,
  async (data: Partial<ProjectDTO>) => {
    const response = await ProjectService.createItem(data);
    return response.data;
  }
);

export const updateProjectAsync = createAsyncThunk(
  `${SLICE_NAME}/updateProjectAsync`,
  async (project: AtLeast<ProjectDTO, "id">) => {
    const response = await ProjectService.partialUpdateItem(project);
    return response.data;
  }
);

export const deleteProjectAsync = createAsyncThunk(
  `${SLICE_NAME}/deleteProjectAsync`,
  async (projectId: number) => {
    const response = await ProjectService.deleteItem(projectId);
    if (
      response.status === HTTPStatusCode.OK ||
      response.status === HTTPStatusCode.NoContent
    ) {
      return projectId;
    }
    return -1;
  }
);

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_PROJECTS_STATE,
  reducers: {
    setProjects: (
      state: ProjectsState,
      action: PayloadAction<ProjectDTO[]>
    ) => {
      state.projects = action.payload;
    },
    addProject: (state: ProjectsState, action: PayloadAction<ProjectDTO>) => {
      state.projects.unshift(action.payload);
    },
    updateProject: (
      state: ProjectsState,
      action: PayloadAction<AtLeast<ProjectDTO, "id">>
    ) => {
      state.projects = state.projects.map((project) => {
        if (project.id === action.payload.id) {
          return {
            ...project,
            ...action.payload,
          };
        }
        return project;
      });
    },
    removeProject: (state: ProjectsState, action: PayloadAction<number>) => {
      state.projects = state.projects.filter((p) => p.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadProjectsAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(loadProjectsAsync.rejected, (state) => {
        state.requesting = false;
      })
      .addCase(loadProjectsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.requesting = false;
        state.isLoaded = true;
        state.projects = action.payload;
      })
      .addCase(createProjectAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(createProjectAsync.rejected, (state) => {
        state.requesting = false;
      })
      .addCase(createProjectAsync.fulfilled, (state, action) => {
        state.requesting = false;
        state.projects.unshift(action.payload);
      })

      .addCase(updateProjectAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(updateProjectAsync.rejected, (state) => {
        state.requesting = false;
      })
      .addCase(updateProjectAsync.fulfilled, (state, action) => {
        state.requesting = false;
        state.projects = [
          ...state.projects.map((p) => {
            if (p.id === action.payload.id) {
              return action.payload;
            }
            return p;
          }),
        ];
      })
      .addCase(deleteProjectAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(deleteProjectAsync.rejected, (state) => {
        state.requesting = false;
      })
      .addCase(deleteProjectAsync.fulfilled, (state, action) => {
        state.requesting = false;
        state.projects = state.projects.filter((project) => {
          return project.id !== action.payload;
        });
      });
  },
});

export const { setProjects, addProject, updateProject, removeProject } =
  slice.actions;

export const projectsReducer = slice.reducer;
