import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "store/base/base.state";
import { SelectedAnnotation } from "store/labeler/image-workspace/image-labeling/image-labeling.state";
import { taskReviewIouActions } from "./task-review-iou/task-review-iou.actions";
import { taskReviewIouReducerBuilder } from "./task-review-iou/task-review-iou.thunk";
import {
  ActiveAnnotation,
  INITIAL_TASKS_REVIEW_STATE,
  TasksReviewState,
} from "./tasks-review.state";
import { tasksReviewReducerBuilder } from "./tasks-review.thunk";

const SLICE_NAME = "tasks-review";

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_TASKS_REVIEW_STATE,
  reducers: {
    ...taskReviewIouActions,
    setTasksReviewRequestStatus(
      state: TasksReviewState,
      action: PayloadAction<RequestStatus>
    ) {
      state.requestStatus = action.payload;
    },

    ocrAnnotationSelected: (
      state: TasksReviewState,
      action: PayloadAction<SelectedAnnotation | null>
    ) => {
      state.selectedAnnotation = action.payload;
    },
    resetTasksReview(state: TasksReviewState) {
      Object.assign(state, INITIAL_TASKS_REVIEW_STATE);
    },
    taskReviewReopened(state: TasksReviewState, action: PayloadAction<number>) {
      // state.tasksReview = state.tasksReview.map((taskReview) => {
      //   if (taskReview.task.id === action.payload) {
      //     return {
      //       ...taskReview,
      //       task: {
      //         ...taskReview.task,
      //         status: TaskStatus.WORKING,
      //         customerReviewStatus: TaskCustomerReviewStatus.REOPENED,
      //       },
      //     };
      //   }
      //   return taskReview;
      // });
    },
    setActiveAnnotations(state: TasksReviewState, action: PayloadAction<ActiveAnnotation[]>) {
      state.activeAnnotations = action.payload
    },
    updateActiveAnnotation(state: TasksReviewState, action: PayloadAction<ActiveAnnotation>) {
      const newActiveAnnotation = action.payload;
      state.activeAnnotations = state.activeAnnotations.map((activeAnnotation) => {
        if (activeAnnotation.annotationId === newActiveAnnotation.annotationId) {
          return {
            ...activeAnnotation,
            ...newActiveAnnotation
          }
        }
        return activeAnnotation
      })
    },
    taskReviewUpdateLabelSelected(
      state: TasksReviewState,
      action: PayloadAction<{
        observationId: number;
        selected: boolean;
      }>,
    ) {
      const {observationId, selected} = action.payload;
      state.taskReviewBatchObservations = state.taskReviewBatchObservations.map(bo => {
        if (bo.batchObservation.observation.id === observationId) {
          return {
            ...bo,
            selected: selected,
          };
        }
        return bo;
      });
    },
    taskReviewSetFilterAssignee(
      state: TasksReviewState,
      action: PayloadAction<string>,
    ) {
      state.filterAssignee = action.payload;
    },
  },
  extraReducers: (builder) => {
    tasksReviewReducerBuilder(builder);
    taskReviewIouReducerBuilder(builder)
  },
});

export const {
  ocrAnnotationSelected,
  setTasksReviewRequestStatus,
  resetTasksReview,
  taskReviewReopened,
  taskReviewUpdateLabelSelected,
  taskReviewSetFilterAssignee,
  // active annotations
  setActiveAnnotations,
  updateActiveAnnotation,
  // Iou
  setTaskReviewIouAnnotations,
  setTaskReviewIouActiveScore
} = slice.actions;

export const tasksReviewReducer = slice.reducer;
