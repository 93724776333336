import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useDatasetContext } from "pages/customer/datasets/context/dataset-context";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { DatasetCategories, ProjectTypes } from "services/label-service/dtos";
import { StorageService } from "services/storage";
import { selectCurrentUser } from "store/auth/auth.selectors";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { createNewDatasetAsync } from "store/customer/dataset/dataset.slice";
import { selectCurrentProject } from "store/customer/project/project.selectors";
import * as Sentry from "@sentry/react";

export interface ImportIdsProps {
  datasetId: number;
  disabled?: boolean;
  onStartImport?: () => void;
  onEndImport?: (datasetId: number | null) => void;
}

export const ImportIds = ({
  datasetId,
  disabled = false,
  onStartImport,
  onEndImport,
}: ImportIdsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { requestData } = useDatasetContext();
  const currentUser = useAppSelector(selectCurrentUser);
  const project = useAppSelector(selectCurrentProject);

  if (!project || !currentUser) return null;

  const getCreateDatasetCategoryFromProjectType = () => {
    const projectType = project.type;
    if (projectType === ProjectTypes.GENERAL_IMAGE) {
      return DatasetCategories.GENERAL_IMAGE;
    }
    if (projectType === ProjectTypes.MEDICAL_IMAGE) {
      return DatasetCategories.MEDICAL_IMAGE;
    }
    if (projectType === ProjectTypes.TEXT) {
      return DatasetCategories.NLP_COR;
    }
    return DatasetCategories.GENERAL_IMAGE;
  };

  const handleFilesChange = async (e: ChangeEvent<HTMLInputElement>) => {
    onStartImport && onStartImport();

    try {
      const fileList = e.target.files;
      if (!fileList) return;
      const files: File[] = [];
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        if (!file || file?.size > 10 * 1048576) continue;
        files.push(file);
      }

      let importDatasetId = datasetId;
      if (datasetId === -1) {
        // create new dataset
        const name = `Import ${new Date().toUTCString()}`;
        const description = `Import ${new Date().toUTCString()}`;
        const category = getCreateDatasetCategoryFromProjectType();
        const payload = {
          name,
          description,
          category,
          createdBy: currentUser.uid,
        };
        const res = await dispatch(createNewDatasetAsync(payload));
        importDatasetId = res.payload.id;
      }

      await StorageService.uploadFilesId(files, importDatasetId);

      if (datasetId !== -1) {
        // reload current dataset files
        requestData();
      }
      dispatch(enqueueSuccessNotification(t("common:textImportedSuccess")));
      onEndImport && onEndImport(importDatasetId);
    } catch (err: any) {
      Sentry.captureException(err);
      dispatch(enqueueErrorNotification(t("common:textImportedError")));
      onEndImport && onEndImport(null);
    }
  };

  return (
    <label htmlFor="upload" className="button-text-secondary">
      <span>{t("common:buttonImport")}</span>
      <input
        disabled={disabled}
        id="upload"
        type="file"
        className="hidden"
        accept="text/plain"
        onInput={handleFilesChange}
        onClick={(event: any) => {
          // allow to reload the files
          event.target.value = null;
        }}
      />
    </label>
  );
};
