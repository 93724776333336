import { VBModal } from "components/common/vb-modal/vb-modal.component";
import {
  EDITOR_LAYOUT_1,
  EDITOR_LAYOUT_2_LR,
  EDITOR_LAYOUT_2_UD,
  EDITOR_LAYOUT_4,
  EDITOR_LAYOUT_4_1,
} from "pages/labeler/three-d-labeling/components/three-d-editor.models";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit(type: string): void;
}

export function ThreeDSelectLayout({ onSubmit, open, onClose }: Props) {
  function handleSubmit(type: string) {
    onSubmit(type);
    onClose();
  }
  return (
    <VBModal
      title="Select Layout"
      open={open}
      onClose={onClose}
      textSubmit="Apply"
      onSubmit={onClose}
      width="40rem"
      footerHidden
    >
      <div className="grid grid-cols-3 gap-4 p-4">
        <div
          className="bg-gray-50 border hover:border-primary cursor-pointer"
          onClick={() => handleSubmit(EDITOR_LAYOUT_1.id.toString())}
        >
          <div className="aspect-w-4 aspect-h-3 bg-gray-300">
            <div className="w-full h-full p-4">
              <div className="w-full h-full bg-primary" />
            </div>
          </div>
          <h3 className="p-4">One window</h3>
        </div>

        <div
          className="bg-gray-50 border hover:border-primary cursor-pointer"
          onClick={() => handleSubmit(EDITOR_LAYOUT_2_LR.id.toString())}
        >
          <div className="aspect-w-4 aspect-h-3 bg-gray-300">
            <div className="w-full h-full p-4 grid grid-cols-2 gap-2">
              <div className="bg-primary"></div>
              <div className="bg-primary"></div>
            </div>
          </div>
          <h3 className="p-4">Two window (Left/Right)</h3>
        </div>
        <div
          className="bg-gray-50 border hover:border-primary cursor-pointer"
          onClick={() => handleSubmit(EDITOR_LAYOUT_2_UD.id.toString())}
        >
          <div className="aspect-w-4 aspect-h-3 bg-gray-300">
            <div className="w-full h-full p-4 grid grid-rows-2 gap-2">
              <div className="bg-primary"></div>
              <div className="bg-primary"></div>
            </div>
          </div>
          <h3 className="p-4">Two window (Top/Down)</h3>
        </div>
        <div
          className="bg-gray-50 border hover:border-primary cursor-pointer"
          onClick={() => handleSubmit(EDITOR_LAYOUT_4.id.toString())}
        >
          <div className="aspect-w-4 aspect-h-3 bg-gray-300">
            <div className="w-full h-full p-4 grid grid-cols-2 gap-2">
              <div className="bg-primary"></div>
              <div className="bg-primary"></div>
              <div className="bg-primary"></div>
              <div className="bg-primary"></div>
            </div>
          </div>
          <h3 className="p-4">Four window</h3>
        </div>

        <div
          className="bg-gray-50 border hover:border-primary cursor-pointer"
          onClick={() => handleSubmit(EDITOR_LAYOUT_4_1.id.toString())}
        >
          <div className="aspect-w-4 aspect-h-3 bg-gray-300">
            <div className="w-full h-full p-4 grid grid-cols-3 grid-rows-3 gap-2">
              <div className="col-span-3 row-span-2 bg-primary"></div>
              <div className="bg-primary"></div>
              <div className="bg-primary"></div>
              <div className="bg-primary"></div>
            </div>
          </div>
          <h3 className="p-4">Gallery</h3>
        </div>
      </div>
    </VBModal>
  );
}
