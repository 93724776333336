/*
 * File: dataset.state.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { CloudDTO } from "models/dataset/cloud.model";
import { Tag } from "models/dataset/tag.model";
import { DatasetDTO } from "services/storage-service/dto/dataset.dto";
import { StorageFileDTO } from "../../../models/dataset/storage-file.model";

export interface DatasetState {
  files: StorageFileDTO[];
  totalCount: number;
  requesting: boolean;
  dataset: DatasetDTO | null;
  datasets: DatasetDTO[];
  totalDataset: number;
  clouds: CloudDTO[];
  syncingCloud: CloudDTO | null;
  tags: Tag[];
}

export const INITIAL_DATASET_STATE: DatasetState = {
  files: [],
  totalCount: 0,
  requesting: true,
  dataset: null,
  datasets: [],
  totalDataset: 0,
  clouds: [],
  syncingCloud: null,
  tags: [],
};
