import { EntityAction } from "domain/common/entity-action";
import { VscChevronDown, VscClose, VscNote, VscTrash } from "react-icons/vsc";
import { formatNumber } from "utilities/formatter/number-formatter.utils";
import { IconButton } from "@material-ui/core";
import ButtonMove from "./button-move";
import { Props } from "./file-page-header";

export function ToolbarActions({ totalSelected = 0, onSelect }: Props) {
  return (
    <div className="absolute top-0 left-0 z-10 flex items-center w-full h-full gap-4 px-4 bg-white">
      <div>
        <strong>{formatNumber(totalSelected)}</strong>
        <span className="pl-2">files selected</span>
      </div>
      <button
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onSelect(EntityAction.DELETE);
        }}
        className="px-3 py-1.5 flex items-center gap-1 rounded hover:bg-background-200 bg-white"
      >
        <VscTrash size={16} />
        <span className="hidden px-1 text-sm md:block">Delete</span>
        <VscChevronDown size={16} />
      </button>

      <ButtonMove />

      <button
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onSelect("manage_metadata");
        }}
        className="px-3 py-1.5 flex items-center gap-1 rounded hover:bg-background-200 bg-white"
      >
        <VscNote size={16} />
        <span className="hidden px-1 text-sm md:block">Add metadata</span>
        <VscChevronDown size={16} />
      </button>

      <span className="flex-auto" />
      <IconButton
        size="small"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onSelect("clear_selection");
        }}
      >
        <VscClose size={20} />
      </IconButton>
    </div>
  );
}
