/*
 * File: import-annotation-modal.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 9th February 2022 6:02:09 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { ChangeEvent, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnnotationServiceV2 } from "services/label-service";
import { ImportFormat } from "services/label-service/dtos/annotation.dto";
import fileSize from "filesize";
import { useAppDispatch } from "hooks/use-redux";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { useBatchDetailContext } from "../../../context/batch-detail.context";
import * as Sentry from "@sentry/react";

interface Props {
  onClose(): void;
  option: string;
}
export const ImportAnnotationModal = ({ onClose, option }: Props) => {
  const dispatch = useAppDispatch();
  const { batch } = useBatchDetailContext();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [importing, setImporting] = useState(false);
  const acceptFileTypes = useMemo(() => {
    if (option === ImportFormat.ocr.toString()) {
      return "application/JSON, .zip";
    } else if (option === ImportFormat.yolo.toString()) {
      return "application/JSON, .zip";
    } else if (option === ImportFormat.coco.toString()) {
      return ".zip";
    } else if (option === ImportFormat.scaler.toString()) {
      return "application/JSON, .zip";
    } else if (
      [ImportFormat.stt.toString(), ImportFormat.tts.toString()].includes(
        option
      )
    ) {
      return ".zip";
    }
  }, [option]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const selectedFiles = event.target.files;
    if (!selectedFiles) return;
    let newFiles = [...files];
    for (let i = 0; i < selectedFiles?.length; i++) {
      const file = selectedFiles.item(i);
      if (file && newFiles.findIndex((f) => f.name === file.name) === -1) {
        newFiles.push(file);
      }
    }

    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setFiles(newFiles);
  }

  async function handleSubmit() {
    if (files.length === 0) return;
    if (importing) return;
    try {
      setImporting(true);
      await AnnotationServiceV2.generateAnnotationFromFile(
        files[0],
        batch.id,
        option
      );

      setImporting(false);
      onClose();
    } catch (error: any) {
      Sentry.captureException(error);
      const message = error.message || "Failed to import annotations";
      dispatch(enqueueErrorNotification(message));
      setImporting(false);
    }
  }

  return (
    <VBModal
      open
      title={t("project:batchDetails.batchexport.upload.title")}
      textSubmit={
        importing
          ? "Importing..."
          : t("project:batchDetails.batchexport.upload.txtImport")
      }
      disableSubmit={importing}
      footerHidden={files.length === 0}
      onClose={onClose}
      onSubmit={handleSubmit}
      blockUI={importing}
      processingIndicator={importing}
    >
      <div className="flex flex-col gap-6">
        {files.length === 0 && (
          <div className="relative flex items-center justify-center h-40 bg-white border border-gray-200 border-dashed rounded-lg hover:cursor-pointer">
            <div className="absolute">
              <div className="flex flex-col items-center ">
                <span className="block font-normal">
                  {t("dataset:upload.attachFiles")}
                </span>
                <span className="block font-normal">
                  {t("dataset:upload.or")}
                </span>
                <span className="block font-normal text-primary">
                  {t("dataset:upload.browseFiles")}
                </span>
              </div>
            </div>
            <input
              ref={inputRef}
              type="file"
              onChange={handleChange}
              accept={acceptFileTypes}
              className="w-full h-full opacity-0"
            />
          </div>
        )}

        {files.length > 0 && (
          <div className="space-y-4">
            <div>
              <div>
                <div>{files[0].name}</div>
                <div className="text-sm text-background-500">
                  {fileSize(files[0].size)}
                </div>
              </div>
            </div>
            <div className="text-sm text-background-500">
              Note: Previous imported annotations will be overwritten by new
              content.
            </div>
          </div>
        )}
      </div>
    </VBModal>
  );
};
