export type SupportColosResponseDTO = { code: string }[];

export enum TicketPriority {
  Low = 0,
  Medium = 1,
  High = 2,
  Urgent = 3,
}

export type TicketDetailDTO = {
  id: string;
  title: string;
  description: string;
  environment: string;
  productBase: string;
  commentCount: number;
  createdDate: Date;
  lastModifiedDate: Date;
  state: string;
  priority: TicketPriority;
};

export type TicketCommentDTO = {
  lastModifiedDate: Date;
  lastModifiedBy: string;
  text: string;
};

export type TicketResponseDTO = {
  info: TicketDetailDTO;
  comments: TicketCommentDTO[];
};

export type TicketRequestDTO = {
  attachment?: string[];
  title: string;
  description: string;
  state?: string;
  productBase?: string;
};
