import { ThreeDEditorLabelsComponent } from "./three-d-editor-labels.component";
import { ThreeDEditorMetadataComponent } from "./three-d-editor-metadata.component";
import { ThreeDEditorLabelMapOptionsComponent } from "./three-d-editor-label-map-options.component";
import { ThreeDEditorColorsComponent } from "./three-d-editor-colors.component";
import { ThreeDEditorToolsComponent } from "./three-d-editor-tools.component";
import { ThreeDEditor3DVisibilityComponent } from "./three-d-editor-3d-visibility.component";
import { ThreeDEditorIOComponent } from "./three-d-editor-io.component";
import { ThreeDEditorMeasurementsComponent } from "./three-d-editor-measurements.component";
import ThreeDKeyboardHandle from "./three-d-keyboard.component";
import { ThreeDEditorDiceScoresComponent } from "./three-d-editor-dice-scores.component";
import { ThreeDTaskIssues } from "./three-d-issues/three-d-task-issues";
import { useThreeDLabelingContext } from "../context/three-d-labeling.context";
import ThreeDJobIssues from "./three-d-issues/three-d-job-issues";

interface Props {
  width?: string;
}
export const ThreeDEditorNav = ({ width = "280px" }: Props) => {
  const { uiJobRef } = useThreeDLabelingContext();

  return (
    <div
      className="flex flex-col h-full gap-4 p-4 overflow-y-auto text-white border-l border-gray-600 bg-background-900"
      style={{
        width: width,
      }}
    >
      <ThreeDEditorLabelMapOptionsComponent />
      {uiJobRef.current?.isTaskReview && uiJobRef.current?.task && (
        <ThreeDTaskIssues taskId={uiJobRef.current.task.id} />
      )}
      {!uiJobRef.current?.isTaskReview && uiJobRef.current?.job && (
        <ThreeDJobIssues jobId={uiJobRef.current.job.id} />
      )}
      <ThreeDEditorDiceScoresComponent />
      <ThreeDEditorColorsComponent />
      <ThreeDEditorLabelsComponent />
      <ThreeDEditorToolsComponent />
      <ThreeDEditorMeasurementsComponent />
      <ThreeDEditorMetadataComponent />
      <ThreeDEditor3DVisibilityComponent />
      <ThreeDEditorIOComponent />
      <ThreeDKeyboardHandle />
    </div>
  );
};
