/*
 * File: batch-labeling-navigation.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 10th December 2021 5:09:36 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  IconArrowLeftBold,
  IconHideBold,
  IconKeyboard,
  IconSetting2,
} from "components/common/vb-icon.component";
import { LightTooltip } from "components/material/mat-light-tooltip.component";
import { Job } from "domain/labeling/job";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useTranslation } from "react-i18next";
import {
  selectImageLabelingJobs,
  selectCurrentJobId,
  selectJobStatuses,
} from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { jobSelected } from "store/labeler/image-workspace/batch-labeling/batch-labeling.slice";
import {
  editorSettingsSelected,
  keyboardShortcutsSelected,
} from "store/labeler/image-workspace/editor-setting/editor-setting.slice";
import { useBatchLabelingContext } from "../../context/batch-labeling.context";
import { JobsThumbnail } from "../jobs-thumbnail/jobs-thumbnail.component";

export const BatchLabelingNavigation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const jobDatas = useAppSelector(selectImageLabelingJobs);
  const jobStatuses = useAppSelector(selectJobStatuses);
  const currentJobId = useAppSelector(selectCurrentJobId);
  const { hideNavigation, toggleNavigation, handleExit } = useBatchLabelingContext();

  const handleSelectJob = async (job: Job) => {
    dispatch(jobSelected(job.id));
  };

  function onClickKeyboardShortcuts() {
    dispatch(keyboardShortcutsSelected());
  }

  function onClickEditorSettings() {
    dispatch(editorSettingsSelected());
  }

  return (
    <div
      className="relative flex-none h-full overflow-hidden transition-all"
      style={{ width: hideNavigation ? "0px" : "13.4375rem" }}
    >
      <div
        className="absolute top-0 left-0 flex flex-col h-full border-r border-blueGray-600 bg-blueGray-900"
        style={{ width: "13.4375rem" }}
      >
        <div className="flex items-center w-full border-b border-warning-500">
          <div className="flex-auto px-4 py-3 font-semibold text-warning-500">
            Images
          </div>
          <LightTooltip title="Keyboard shortcuts">
            <button
              className="flex items-center justify-center flex-none w-8 h-8 mr-2 text-white rounded bg-coolGray-800"
              onClick={onClickKeyboardShortcuts}
            >
              <IconKeyboard className="w-5 h-5" />
            </button>
          </LightTooltip>

          <LightTooltip title="Editor settings">
            <button
              className="flex items-center justify-center flex-none w-8 h-8 mr-2 text-white rounded bg-coolGray-800"
              onClick={onClickEditorSettings}
            >
              <IconSetting2 className="w-5 h-5" />
            </button>
          </LightTooltip>
        </div>

        <div className="flex-1 overflow-y-auto dark-scrollbar">
          <JobsThumbnail
            currentJobId={currentJobId}
            jobDatas={jobDatas.allIds.map((jobId) => jobDatas.entities[jobId])}
            jobStatuses={jobStatuses}
            onSelectJob={handleSelectJob}
          />
        </div>

        <div className="grid grid-cols-2 border-t border-blueGray-500">
          <button
            onClick={handleExit}
            className="flex items-center justify-center text-white focus:outline-none bg-background-900 gap-1.5"
            style={{ height: "3.25rem" }}
          >
            <IconArrowLeftBold className="w-5 h-5" />
            <span>{t("labelerworkspace:menu.buttonExit")}</span>
          </button>
          <button
            onClick={toggleNavigation}
            className="flex items-center justify-center text-white focus:outline-none bg-background-800 gap-1.5"
            style={{ height: "3.25rem" }}
          >
            <IconHideBold className="w-5 h-5" />
            <span>Hide</span>
          </button>
        </div>
      </div>
    </div>
  );
};
