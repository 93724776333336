import { VBModal } from "components/common/vb-modal/vb-modal.component"
import { VBCheckbox } from "components/design-system/checkbox/checkbox.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { CornerstoneEvents } from "components/dicom/cornerstone/models/cornerstone-events.model";
import { useBuildTaskReviewBatchObservationTree } from "hooks/use-observation-tree";
import { useAppDispatch } from "hooks/use-redux";
import { OverwriteLabelDialog } from "pages/customer/projects/project-label/components/overwrite-label.dialog";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IllustrationItem } from "services/label-service/dtos";
import { ObservationTreeNodeModel } from "services/label-service/model/observation-tree-node.model";
import { StorageService } from "services/storage";
import { enqueueErrorNotification, enqueueSuccessNotification } from "store/common/notification/notification.actions";
import { updateProjectObservationAsync } from "store/customer/project/project.slice";
import { TaskReviewBatchObservationModel } from "store/customer/tasks-review/tasks-review.state";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { truncateEmail } from "utilities/string/truncate-email";
import { TasksReviewCornerstoneHandler } from "./tasks-review-cornerstone-handler";
import * as Sentry from "@sentry/react";

interface LabelerOptionByLabel {
  assigne: string;
  selected: boolean;
}

interface LabelOption {
  observationId: number;
  selectedAll: boolean;
  labelerOptions: LabelerOptionByLabel[];
}

interface LabelerOptionByLabelMap {
  [index: number]: LabelOption;
}

interface Props {
  taskReviewBatchObservations: TaskReviewBatchObservationModel[],
  handler: TasksReviewCornerstoneHandler,
  open: boolean;
  handleClose?: () => void;
  handleSubmit?: () => void;
  originalAnnoVisibles?: Record<string, boolean>;
}
export const AddLabelDefinitionDialog = ({
  taskReviewBatchObservations,
  handler,
  open,
  handleClose,
  handleSubmit,
  originalAnnoVisibles = {},
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [blob, setBLob] = useState<Blob>();
  const imageUrl = useMemo(() => {
    if (blob) {
      return URL.createObjectURL(blob);
    }
    return "";
  }, [blob]);
  const [note, setNote] = useState("");

  const { nodesList } = useBuildTaskReviewBatchObservationTree(taskReviewBatchObservations);
  const labelOptions = useMemo(() => {
    return nodesList.filter(
      node => !node.value?.batchObservation.observation.observationSetting.systemAttribute &&
      ObservationTreeNodeModel.isLeaf(node)
    )
      .map(node => ({
        label: node.value?.batchObservation.observation.name,
        value: node.value?.batchObservation.observation.id,
      }));
  }, [nodesList]);
  const [selectedLabel, setSelectedLabel] = useState<number>();
  const [labelerOptionsMap, setLabelerOptionsMap] = useState<LabelerOptionByLabelMap>();

  const [showOverwriteOption, setShowOverwriteOption] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleOverwriteSubmit = async (strategy: string) => {
    try {
      if (!selectedLabel || !labelerOptionsMap || !blob) return;
      setIsUpdating(true);

      const observation = taskReviewBatchObservations.find(
        (to: any) => to.batchObservation.observation.id === selectedLabel
      )?.batchObservation.observation;
      
      if (!observation || !observation.observationSetting) return;

      const file = new File([blob], "illustration_image.png");
      const url = await StorageService.uploadFile(file);

      const newIllustrationItem: IllustrationItem = {
        active: "true",
        resourceId: "0",
        url,
        note,
      }

      const payload = {
        ...observation,
        observationSetting: {
          ...observation.observationSetting,
          illustrations: [...observation.observationSetting.illustrations, newIllustrationItem],
        }
      };
      const response = await dispatch(
        updateProjectObservationAsync({ payload, strategy })
      );
      handleThunkRejected(response);

      dispatch(enqueueSuccessNotification(t("common:textUpdatedSuccess")));

      handleSubmit && handleSubmit();
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsUpdating(false);
    }
  }

  const handleSelectedAllChanged = (labelOption: LabelOption, newValue: boolean) => {
    if (!labelerOptionsMap) return;

    setLabelerOptionsMap({
      ...labelerOptionsMap,
      [labelOption.observationId]: {
        ...labelOption,
        selectedAll: newValue,
        labelerOptions:
          labelerOptionsMap[labelOption.observationId].labelerOptions.map(op => ({
            ...op,
            selected: newValue,
          }))
      }
    });
  }

  const handleSelectedLabelerChanged = (labelOption: LabelOption, assignee: string, newValue: boolean) => {
    if (!labelerOptionsMap) return;
    setLabelerOptionsMap({
      ...labelerOptionsMap,
      [labelOption.observationId]: {
        ...labelOption,
        labelerOptions:
          labelerOptionsMap[labelOption.observationId].labelerOptions.map(option => {
            if (option.assigne === assignee) return {
              ...option,
              selected: newValue,
            }
            return option;
          })
      }
    });
  }

  useEffect(() => {
    if (!open) return;
    if (!selectedLabel || !labelerOptionsMap) {
      handler.setAllAnnotationVisible(false);
      return;
    }

    handler.setAllAnnotationVisible(false);
    const option = labelerOptionsMap[selectedLabel];
    for (const labelerOption of option.labelerOptions) {
      handler.setAnnotationVisibleByLabelIdAndAssignee(
        selectedLabel,
        labelerOption.assigne,
        labelerOption.selected,
      );
    }

  }, [selectedLabel, labelerOptionsMap, handler, open]);

  useEffect(() => {
    if (!nodesList) return;
    const optionsMap: LabelerOptionByLabelMap = {};
    const showNodes = nodesList.filter(
      node => !node.value?.batchObservation.observation.observationSetting.systemAttribute
    );
    for (const node of showNodes) {
      if (!node.value) continue;
      const key = node.value.batchObservation.observation.id;
      optionsMap[key] = {
        observationId: key,
        selectedAll: false,
        labelerOptions: [],
      };
      for (const assignee of node.value.assignees) {
        optionsMap[key].labelerOptions.push({
          selected: false,
          assigne: assignee,
        });
      }
    }
    setLabelerOptionsMap(optionsMap);
  }, [nodesList]);

  useEffect(() => {
    const onImageRendered = () => {
      const canvas: HTMLCanvasElement = handler.element.firstChild as HTMLCanvasElement;
      canvas.toBlob(blob => {
        if (blob) {
          setBLob(blob);
        }
      });
    }

    handler.element.addEventListener(
      CornerstoneEvents.IMAGE_RENDERED,
      onImageRendered,
    )

    return () => {
      handler.element.removeEventListener(
        CornerstoneEvents.IMAGE_RENDERED,
        onImageRendered,
      );
    }

  }, [handler]);

  function restoreOriginalAnnoVisibles() {
    handler.setAnnotationVisibleFromMap(originalAnnoVisibles);
  }

  function handleClickSubmit() {
    setShowOverwriteOption(true);
  }

  function handleClickClose() {
    
    restoreOriginalAnnoVisibles();
    handleClose && handleClose();
  }

  function handleSelectLabel(option: any) {
    if (option) {
      setSelectedLabel(option.value);
    } else {
      setSelectedLabel(undefined);
    }
  }

  return (
    <VBModal
      width="50rem"
      title={"Update label illustration"}
      open={open}
      textSubmit={t("common:buttonAdd")}
      disableSubmit={!!!selectedLabel}
      onClose={handleClickClose}
      onSubmit={handleClickSubmit}
    >
      <div className="flex flex-col gap-6 relative">
        <div className="flex items-center gap-2">
          <span className="font-bold">Label: </span>
          <VBSelectComponent
            containerClassName="w-40"
            onChange={handleSelectLabel}
            defaultValue={labelOptions.find((option) => option.value === selectedLabel)}
            options={labelOptions}
            isClearable
            menuPortalTarget={document.body}
          />
        </div>
        <div className="w-full flex gap-4 justify-between">
          <img src={imageUrl} alt="illustration" className="w-80 h-80 object-contain bg-background-100 border rounded" />

          {
            (selectedLabel && labelerOptionsMap) &&
            <div className="flex flex-col w-1/2 gap-4">
              <div className="flex items-center gap-2">
                <span>Select labeler result: </span>
                <VBCheckbox
                  checkedLabel={"Selected all"}
                  uncheckedLabel={"All"}
                  value={labelerOptionsMap[selectedLabel].selectedAll}
                  onChange={(v) => handleSelectedAllChanged(labelerOptionsMap[selectedLabel], v)}
                />
              </div>
              <div className="flex items-center flex-grap gap-4 overflow-y-auto">
                {
                  labelerOptionsMap[selectedLabel].labelerOptions.map(labelerOption => {
                    return (
                      <VBCheckbox
                        key={labelerOption.assigne}
                        checkedLabel={truncateEmail(labelerOption.assigne)}
                        uncheckedLabel={truncateEmail(labelerOption.assigne)}
                        value={labelerOption.selected}
                        onChange={(v) => handleSelectedLabelerChanged(labelerOptionsMap[selectedLabel], labelerOption.assigne, v)}
                      />
                    )
                  })
                }
              </div>
              <div className="flex-1"></div>
              <div className="flex flex-col gap-2 w-full">
                <span>Note on image: </span>
                <textarea
                  className="w-full self-scretch border border-background-300 p-4 rounded"
                  rows={4}
                  value={note}
                  onChange={(e) => setNote(e.target.value as string)}
                />
              </div>
              
            </div>
          }
        </div>
        <OverwriteLabelDialog
          open={showOverwriteOption}
          onClose={() => setShowOverwriteOption(false)}
          onSubmit={handleOverwriteSubmit}
          disabledSubmit={isUpdating}
        />
      </div>
    </VBModal>
  )
}