/*
 * File: reopen-request.dto.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 28th January 2022 9:51:01 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { BaseDTO } from "./base.dto";

export enum ReopenRequestContext {
  BATCH = "Batch",
  TASK = "Task",
  JOB = "Job",
}

export interface ReopenRequest {
  description: string;
  context: ReopenRequestContext;
  targetId: number;
}

export interface ReopenRequestDTO extends BaseDTO, ReopenRequest {}
