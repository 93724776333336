/*
 * File: vb-segment-tabs.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 28th December 2021 2:12:07 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { classnames } from "utilities/classes";

interface Props {
  options: { value: string; label: string }[];
  selectedOptionId: string;
  onChange?(optionId: string): void;
}
export const VBSegmentTabs = ({
  options,
  selectedOptionId,
  onChange,
}: Props) => {
  const handleSelect = (optionId: string) => {
    onChange && onChange(optionId);
  };
  return (
    <div className="flex items-center bg-white border divide-x rounded text-primary border-primary divide-primary h-9">
      {options.map((option) => {
        return (
          <button
            key={option.value}
            onClick={() => handleSelect(option.value)}
            className={classnames(
              "flex items-center justify-center h-full px-3 py-2",
              {
                "text-white bg-primary": option.value === selectedOptionId,
              }
            )}
          >
            {option.label}
          </button>
        );
      })}
    </div>
  );
};
