/*
 * File: vb-light-checkbox.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 9th August 2021 9:17:26 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { Checkbox, CheckboxProps, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    color: "gray",
  },
  checked: {
    color: "white",
  },
}));

export const LightCheckbox = ({ ...rest }: CheckboxProps) => {
  const classes = useStyles();
  return (
    <Checkbox
      size="small"
      color="default"
      {...rest}
      classes={{
        root: classes.root,
        checked: classes.checked,
      }}
    />
  );
};
