/*
 * File: ai-models.slice.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 18th February 2022 2:37:36 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AIModelsState, INITIAL_AI_MODELS_STATE } from "./ai-models.state";
import { createSlice } from "@reduxjs/toolkit";
import { aiModelsReducerBuilder } from "./ai-models.thunk";
const SLICE_NAME: string = "aiModels";
const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_AI_MODELS_STATE,
  reducers: {
    resetAIModels(state: AIModelsState) {
      Object.assign(state, INITIAL_AI_MODELS_STATE);
    },
  },
  extraReducers: (builder) => {
    aiModelsReducerBuilder(builder);
  },
});

export const { resetAIModels } = slice.actions;

export const aiModelsReducer = slice.reducer;
