/*
 * File: text-labeling.selector.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 5:05:43 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { TextLabelingData } from "domain/labeling/labeling-data";
import { BatchDTO, ProjectDTO, StepType } from "services/label-service/dtos";
import { RootState } from "store";
import { RequestStatus } from "store/base/base.state";
import { TextLabelingMode } from "./text-labeling.state";
import { selectTextLabelingTaskJobs } from "store/labeler/text-labeling-task/text-labeling-task.selectors";

export const selectTextLabelingRequestStatus = (state: RootState) => {
  return state.textWorkspace.textLabeling.requestStatus;
};
export const selectIsNoTextJobAvailable = (state: RootState) => {
  const { job, requestStatus } = state.textWorkspace.textLabeling;
  return (
    (requestStatus === RequestStatus.SUCCESS ||
      requestStatus === RequestStatus.FAILURE) &&
    !job
  );
};

export const selectIsLoadingTextJob = (state: RootState) => {
  const { requestStatus } = state.textWorkspace.textLabeling;
  return (
    requestStatus === RequestStatus.IDLE ||
    requestStatus === RequestStatus.LOADING
  );
};

export const selectTextLabelingJob = (state: RootState) => {
  return state.textWorkspace.textLabeling.job;
};

export const selectTextLabelingMode = (state: RootState) => {
  return state.textWorkspace.textLabeling.mode;
};

export const selectTextLabelingJobId = (state: RootState) => {
  return state.textWorkspace.textLabeling.job?.id;
};

export const selectTextLabelingBatch = (state: RootState) => {
  return state.textWorkspace.textLabeling.batch as BatchDTO;
};

export const selectTextLabelingProject = (state: RootState) => {
  return state.textWorkspace.textLabeling.batch?.project as ProjectDTO;
};

export const selectTextLabelingTask = (state: RootState) => {
  return state.textWorkspace.textLabeling.task;
};

export const selectTextLabels = (state: RootState) => {
  return state.textWorkspace.textLabeling.labels;
};

export const selectTokenEntities = (state: RootState) => {
  return state.textWorkspace.textLabeling.tokenEntities;
};

export const selectTextLabelingFile = (state: RootState) => {
  return state.textWorkspace.textLabeling.labelingJob?.file;
};

export const selectTextLabelingSentences = (state: RootState) => {
  return state.textWorkspace.textLabeling.sentences;
};

export const selectCurrentTaskObservations = (state: RootState) => {
  return state.textWorkspace.textLabeling.taskObservations;
};

export const selectPreviousLabelingData = (state: RootState) => {
  const { labelingJob } = state.textWorkspace.textLabeling;
  if (!labelingJob?.data?.previousJobData) return [];
  return labelingJob.data.previousJobData || [];
};

export const selectTextLabelingSources = (state: RootState) => {
  const { labelingJob } = state.textWorkspace.textLabeling;
  const sources: TextLabelingData[] = [];
  if (labelingJob?.data?.generatedData) {
    sources.push(labelingJob.data.generatedData as TextLabelingData);
  }
  if (labelingJob?.data?.importedData) {
    sources.push(labelingJob.data.importedData as TextLabelingData);
  }

  if (labelingJob?.data?.previousJobData) {
    for (const data of labelingJob.data.previousJobData) {
      sources.push(data as TextLabelingData);
    }
  }
  return sources;
};

export const selectTextLabelingJobData = (state: RootState) => {
  const { labelingJob } = state.textWorkspace.textLabeling;
  return labelingJob;
};

export const selectTextLabelingJobs = (state: RootState) => {
  const mode = selectTextLabelingMode(state);
  if (mode === TextLabelingMode.CUSTOMER) {
    return selectTextLabelingTaskJobs(state);
  }

  const { jobEntities } = state.textWorkspace.textLabeling;
  const jobs = [];
  for (const key of Object.keys(jobEntities)) {
    jobs.push(jobEntities[parseInt(key)]);
  }
  return jobs.sort((a, b) => a.id - b.id);
};

export const selectCanViewPreviousTextLabels = (state: RootState) => {
  return state.textWorkspace.textLabeling.job?.canViewPreviousJob;
};

export const selectTextIsStepReviewJob = (state: RootState) => {
  return state.textWorkspace.textLabeling.job?.stepType === StepType.ACCEPTANCE;
};

export const selectTextIsReadonly = (state: RootState) => {
  return state.textWorkspace.textLabeling.readonly;
};

export const selectTextShowBatchObservation = (state: RootState) => {
  return state.textWorkspace.textLabeling.showBatchInstruction;
};

export const selectTextProjectType = (state: RootState) => {
  const project = selectTextLabelingProject(state);
  return project?.type;
};
