import { useAppDispatch } from "hooks/use-redux";
import { useImageLabelingContext } from "pages/labeler/image-labeling/context/image-labeling.context";
import { useCallback, useEffect } from "react";
import {
  imageAnnotationUpdated,
  toggleObservationVisible,
} from "store/labeler/image-workspace/image-workspace.slice";
import { buildTree } from "./object-tree.helper";
import useJobAnnotationTree from "./use-annotations-tree";
export interface TreeItem {
  id: string;
  name: string;
  description?: string;
  color?: string;
  type?: string;
  parentId?: string;
  children?: TreeItem[];
  selected?: boolean;
  checked?: boolean;
  hidden?: boolean;
  locked?: boolean;
  objectType?: string;
}
export default function useObjectTree() {
  const dispatch = useAppDispatch();
  const { entities, setEntities } = useJobAnnotationTree();
  const { selectLabelById } = useImageLabelingContext();

  const selectItem = useCallback(
    (id: string) => {
      if (!entities.hasOwnProperty(id)) return;
      const newEntities: Record<string, TreeItem> = {};
      for (const key of Object.keys(entities)) {
        const entity = entities[key];
        if (entity.type === "classification") {
          if (entity.id === id) {
            entity.checked = !entity.checked;
          }
        } else {
          entity.selected = key === id;
        }
        newEntities[key] = entity;
      }
      setEntities(newEntities);
      selectLabelById(parseInt(id));
    },
    [entities, setEntities, selectLabelById]
  );

  const toggleVisible = useCallback(
    (id: string) => {
      if (!entities.hasOwnProperty(id)) return;
      const entity = entities[id];
      if (entity.objectType === "annotation") {
        const payload = { uuid: entity.name, visible: !!entity.hidden };
        dispatch(imageAnnotationUpdated(payload));
      } else if (entity.objectType === "label") {
        const payload = {
          labelId: parseInt(entity.id),
        };
        dispatch(toggleObservationVisible(payload));
      }
    },
    [entities, dispatch]
  );

  const toggleLock = useCallback(
    (id: string) => {
      if (!entities.hasOwnProperty(id)) return;
      const entity = entities[id];
      const payload = { uuid: entity.name, locked: !!!entity.locked };
      dispatch(imageAnnotationUpdated(payload));
    },
    [entities, dispatch]
  );

  useEffect(() => {
    const handleUIButtonClick = (clickEvent: any) => {
      const { event, action, id } = clickEvent.detail;
      event?.preventDefault();
      if (action === "TREE_ITEM_SELECT") {
        selectItem(id);
      } else if (action === "TREE_ITEM_TOGGLE_VISIBLE") {
        toggleVisible(id);
      }
    };
    window.addEventListener("UI_BUTTON_CLICK", handleUIButtonClick);

    return () => {
      window.removeEventListener("UI_BUTTON_CLICK", handleUIButtonClick);
    };
  }, [selectItem, toggleVisible, toggleLock]);

  return {
    items: buildTree(entities),
    selectItem,
    toggleVisible,
    toggleLock,
  };
}
