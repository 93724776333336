/*
 * File: use-dataset-options.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th June 2023 9:05:22 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { LabelApi } from "data-access/impl/label";
import { Filter, Option } from "domain/common";
import { useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import useProjectOptions from "./use-project-options";

const QUERY_KEY = "LABEL_OPTIONS";

export function useLabelOptions(filter: Filter, defaultValue = []) {
  const { options: projectOptions } = useProjectOptions({
    page: 0,
    size: 10000,
  });

  const client = useQueryClient();

  function requestData() {
    return LabelApi.getLabels(filter);
  }

  const { data } = useQuery([QUERY_KEY, filter], requestData, {});

  const options = useMemo<Option[]>(() => {
    if (!data?.data) return [];
    return data.data.map((item) => {
      return {
        label: item.name,
        value: item.id.toString(),
        metadata: item,
      };
    });
  }, [data]);

  const groupOptions = useMemo(() => {
    return projectOptions
      .sort((a, b) => a.label.localeCompare(b.label))
      .map((projectOption) => {
        return {
          label: projectOption.label,
          options: options
            .filter((option) => {
              if (!option?.metadata?.projectId) return false;
              return (
                projectOption.value === option.metadata.projectId.toString()
              );
            })
            .sort((a, b) => a.label.localeCompare(b.label)),
        };
      });
  }, [projectOptions, options]);

  const [value, setValue] = useState<number[]>(defaultValue);

  const option = useMemo(() => {
    return options.filter((item) => value.includes(parseInt(item.value)));
  }, [options, value]);

  function onSelectChange(newOptions?: any) {
    if (!newOptions) {
      setValue([]);
      return;
    }
    const values = newOptions.map((option: Option) => parseInt(option.value));
    setValue(values);
  }

  function onValueChange(newValues: number[]) {
    setValue(newValues);
  }

  function reloadData() {
    client.refetchQueries([QUERY_KEY]);
  }

  return {
    groupOptions,
    options,
    value,
    setValue,
    option,
    onSelectChange,
    onValueChange,
    reloadData,
  };
}

export default useLabelOptions;
