/*
 * File: users.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd January 2022 10:18:43 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { User } from "domain/customer";
import { userMapper } from "services/user-service/mappers/user.mapper";
import { RootState } from "store";
import { UserRole } from "store/auth/auth.state";

export const selectUsersRequestStatus = (state: RootState) => {
  return state.users.status;
};

export const selectTotalUsers = (state: RootState) => {
  return state.users.total;
};

export const selectUsers = (state: RootState) => {
  const { users, filter } = state.users;
  const { entities, allIds } = users;
  const allUsers = allIds.map((id) => entities[id]);
  if (!filter) return allUsers;

  const items = filter.sort.split(",");
  if (items.length > 0) {
    const sortField = items[0];
    const sortMode = items.length > 1 ? items[1] : "";
    allUsers.sort((a: User, b: User) => {
      if (sortField !== undefined && (sortField as keyof User)) {
        const valueA = a[sortField as keyof User];
        const valueB = b[sortField as keyof User];
        const sortDirection =
          sortMode === "asc" ? -1 : sortMode === "desc" ? 1 : 0;
        if (typeof valueA === "number" && typeof valueB === "number") {
          return sortDirection * (valueA - valueB);
        }
        if (typeof valueA === "string" && typeof valueB === "string") {
          return sortDirection * valueA.localeCompare(valueB);
        }
      }
      return 0;
    });
  }
  const page = parseInt(filter.page) - 1;
  const pageSize = parseInt(filter.pageSize);
  const start = page * pageSize;
  const end = start + pageSize;
  const filteredUsers = [];
  let idx = start;
  while (idx < allUsers.length && idx < end) {
    filteredUsers.push(allUsers[idx]);
    idx++;
  }
  return filteredUsers;
  // apply filter

  //   const userIds = ids.filter((id) => {
  //     const user = entities[id];
  //     let match = true;
  //     if (filter?.email) match = match && user.email.includes(filter.role);
  //     if (filter?.name) match = match && user.userName.includes(filter.name);
  //     if (filter?.role) match = match && user.role.includes(filter.role);

  //   });
};

export const selectWorkspaceUsers = (state: RootState) => {
  const { entities, allIds } = state.users.users;
  return allIds.map((id) => entities[id]).map(userMapper.toDTO);
};

export const selectWorkspaceLabelers = (state: RootState) => {
  const { entities, allIds } = state.users.users;
  return allIds
    .map((id) => entities[id])
    .filter((u) => u.role.includes(UserRole.LABELER))
    .map(userMapper.toDTO);
};
