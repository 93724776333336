/*
 * File: ai-models.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 22nd February 2022 3:23:45 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { RootState } from "store";

export const selectAIModelVersions = (state: RootState) => {
  return state.aiModelVersions.ids.map(
    (id) => state.aiModelVersions.entities[id]
  );
};

export const selectAIModelVersionsRequestStatus = (state: RootState) => {
  return state.aiModelVersions.status;
};

export const selectAIModelVersionsError = (state: RootState) => {
  return state.aiModelVersions.error;
};
