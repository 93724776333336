/*
 * File: cornerstone.state.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 3rd August 2021 10:47:16 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  CornerstoneTool,
  ToolName,
} from "components/dicom/dicom-tools/dicom-tools.model";

export interface CornerstoneState {
  colorInvert: boolean;
  heartRuler: boolean;
  flipHorizontal: boolean;
  flipVertical: boolean;
  fullScreen: boolean;
  otherTools: CornerstoneTool[];
  baseTools: CornerstoneTool[];
  users: string[];
  annotationVisible: boolean;
  annotationFill: boolean;
  displayTextValue: boolean;
  relationAnnotationVisible: boolean;
  synchronize: boolean;
}

export const INITIAL_CORNERSTONE_STATE: CornerstoneState = {
  colorInvert: true,
  heartRuler: false,
  flipHorizontal: false,
  flipVertical: false,
  fullScreen: false,
  annotationVisible: true,
  annotationFill: true,
  displayTextValue: true,
  relationAnnotationVisible: true,
  synchronize: false,
  baseTools: [
    {
      type: ToolName.Wwwc,
      name: "Wwwc",
    },
    {
      type: ToolName.Pan,
      name: "Pan",
    },
    {
      type: ToolName.Zoom,
      name: "Zoom",
    },
    {
      type: ToolName.StackScrollMouseWheel,
      name: "StackScrollMouseWheel",
    },
    {
      type: ToolName.Rotate,
      name: "Rotate",
    },
    {
      type: ToolName.Magnify,
      name: "Magnify",
    },
    {
      type: ToolName.Angle,
      name: "Angle",
    },
    {
      type: ToolName.CobbAngle,
      name: "CobbAngle",
    },
    {
      type: ToolName.HeartRuler,
      name: "HeartRuler",
    },
    {
      type: ToolName.Ruler,
      name: "Length",
    },
    {
      type: ToolName.Eraser,
      name: "Eraser",
    },
  ],
  otherTools: [
    {
      type: ToolName.Invert,
      name: "Color Invert",
      active: false,
    },
    {
      type: ToolName.FlipHorizontal,
      name: "Flip Horizontal",
      active: false,
    },
    {
      name: "Flip Vertical",
      type: ToolName.FlipVertical,
      active: false,
    },
    {
      type: ToolName.FullScreen,
      name: "Full Screen",
      active: false,
    },
  ],
  users: [],
};
