import { IconCloseCircle } from "components/common/vb-icon.component";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  PathologyAnnotationAdditionalData,
  PathologyEditorEvents,
  PathologyEditorLabel,
} from "./pathology-editor.models";
import { usePathologyEditorContext } from "./pathology-editor.provider";
import { ObjectOperationType } from "./fabric/fabric.models";
import { classnames } from "utilities/classes";

export const PathologyEditorContextMenuComponent = () => {
  const {
    fabricOverlay,
    hasAnySelectedAnnotation,
    deleteSelectedAnnotations,
    syncAnnotationsFromFabricObjects,
    currentLabeler,
    deselectAllObjects,
    setDeleteChildrenPopupVisibility,
  } = usePathologyEditorContext();

  const [displayPos, setDisplayPos] = useState({ x: 0, y: 0 });
  const [visible, setVisible] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const isHoverRef = useRef(false);

  useEffect(() => {
    isHoverRef.current = isHover;
  }, [isHover]);

  useEffect(() => {
    if (!hasAnySelectedAnnotation) {
      setVisible(false);
    }
  }, [hasAnySelectedAnnotation]);

  useEffect(() => {
    if (!fabricOverlay) return;

    const handleContextMenu = (e: any) => {
      const event = e.detail as MouseEvent;
      setDisplayPos({
        x: event.clientX,
        y: event.clientY,
      });
      setVisible(true);
    };

    const handleDocumentMouseDown = () => {
      if (!isHoverRef.current) {
        setVisible(false);
      }
    };

    document.addEventListener(
      PathologyEditorEvents.CONTEXT_MENU,
      handleContextMenu
    );
    document.addEventListener("mousedown", handleDocumentMouseDown);

    return () => {
      document.removeEventListener(
        PathologyEditorEvents.CONTEXT_MENU,
        handleContextMenu
      );
      document.removeEventListener("mousedown", handleDocumentMouseDown);
    };
  }, [fabricOverlay]);

  const handleClickDeleteSelected = () => {
    if (!fabricOverlay) return;
    const selectedObjects = fabricOverlay.fabricCanvas.getActiveObjects();

    let hasChildren = false;
    for (const object of selectedObjects) {
      if (object.data?.children && object.data.children.length > 0) {
        hasChildren = true;
        break;
      }
    }

    if (hasChildren) {
      setDeleteChildrenPopupVisibility(true);
    } else {
      deleteSelectedAnnotations();
    }
  };

  const handleDoObjectsOperation = (opType: ObjectOperationType) => {
    if (!fabricOverlay) return;
    const selectedObjects = fabricOverlay.fabricCanvas.getActiveObjects();
    if (selectedObjects.length < 2) return;
    fabricOverlay.fabricCanvas.discardActiveObject();

    fabricOverlay.doObjectsOperation(selectedObjects, opType);

    syncAnnotationsFromFabricObjects({});
  };

  const handleChangeLabel = (label: PathologyEditorLabel) => {
    if (!fabricOverlay) return;

    const selectedObjects = fabricOverlay.fabricCanvas.getActiveObjects();
    const addData: Partial<PathologyAnnotationAdditionalData> = {
      labelId: label.id,
      labelName: label.name,
      labelColor: label.color,
    };

    if (currentLabeler) {
      addData.labeler = currentLabeler.id;
    }

    fabricOverlay.changeObjectsAdditionalData(selectedObjects, addData);

    syncAnnotationsFromFabricObjects({});
    setVisible(false);
  };

  const handleInsertInHierarchy = () => {
    if (!fabricOverlay) return;

    const selectedObjects = fabricOverlay.fabricCanvas.getActiveObjects();
    // we need to deselect first to not getting the positions in group
    deselectAllObjects();
    fabricOverlay.insertObjectsInHierarchy(selectedObjects);

    syncAnnotationsFromFabricObjects({});
    setVisible(false);
  };

  return (
    <div
      className="fixed rounded border bg-white flex flex-col gap-2 transform -translate-y-4"
      style={{
        left: displayPos.x,
        top: displayPos.y,
        zIndex: visible ? 100 : -1,
        opacity: visible ? 1 : 0,
        transition: "opacity 0.2s ease-out",
        minWidth: "100px",
      }}
      onMouseEnter={(_) => setIsHover(true)}
      onMouseLeave={(_) => setIsHover(false)}
    >
      <div className="flex items-center justify-between px-2">
        <span className="font-bold">Menu</span>
        <IconCloseCircle
          className="w-4 h-4 cursor-pointer"
          onClick={(_) => setVisible(false)}
        />
      </div>
      {hasAnySelectedAnnotation && (
        <Fragment>
          <ContextMenuItem title="Delete" onClick={handleClickDeleteSelected} />
          <ContextMenuItem
            title="Union"
            onClick={() => handleDoObjectsOperation(ObjectOperationType.UNION)}
          />
          <ContextMenuItem
            title="Intersect"
            onClick={() =>
              handleDoObjectsOperation(ObjectOperationType.INTERSECTION)
            }
          />
          <ContextMenuItem
            title="Difference A"
            onClick={() =>
              handleDoObjectsOperation(ObjectOperationType.DIFFERENCE_A)
            }
          />
          <ContextMenuItem
            title="Difference B"
            onClick={() =>
              handleDoObjectsOperation(ObjectOperationType.DIFFERENCE_B)
            }
          />
          <ContextMenuItem
            title="Change label"
            subComponent={
              <PathologyLabelMenu onLabelClick={handleChangeLabel} />
            }
          />
          <ContextMenuItem
            title="Insert in hierarchy"
            onClick={() => handleInsertInHierarchy()}
          />
        </Fragment>
      )}
    </div>
  );
};

interface ContextMenuItemProps {
  title: string;
  onClick?: () => void;
  subComponent?: any;
}
export const ContextMenuItem = ({
  title,
  onClick,
  subComponent,
}: ContextMenuItemProps) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={classnames("cursor-pointer px-2 relative", {
        "bg-gray-100": isHover,
      })}
      onClick={onClick}
      onMouseEnter={(_) => setIsHover(true)}
      onMouseLeave={(_) => setIsHover(false)}
    >
      {title}
      {subComponent && isHover && (
        <div className="absolute right-0 bottom-0 transform translate-x-full translate-y-1/2">
          {subComponent}
        </div>
      )}
    </div>
  );
};

interface PathologyLabelMenuProps {
  onLabelClick?: (v: PathologyEditorLabel) => void;
}
export const PathologyLabelMenu = ({
  onLabelClick,
}: PathologyLabelMenuProps) => {
  const { labels } = usePathologyEditorContext();

  return (
    <div className="max-h-40 overflow-y-auto bg-white p-2 border rounded">
      {labels.map((label) => {
        return (
          <div
            key={label.id}
            className="cursor-pointer"
            onClick={(_) => onLabelClick && onLabelClick(label)}
            style={{
              color: label.color,
            }}
          >
            {label.name}
          </div>
        );
      })}
    </div>
  );
};
