/*
 * File: ai-model-versions.state.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 22nd February 2022 3:24:20 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 * 
 * Copyright 2022 VinBrain JSC
 */

import { BaseState, RequestStatus } from "store/base/base.state";
import { AIModelVersionDTO } from "./../../../services/label-service/dtos/ai-model.dto";

export interface AIModelVersionsState extends BaseState<AIModelVersionDTO> {}

export const INITIAL_AI_MODEL_VERIONS_STATE: AIModelVersionsState = {
  entities: {},
  ids: [],
  id: -1,
  status: RequestStatus.IDLE,
  error: null,
};
