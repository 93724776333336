/*
 * File: image-auto-annotate.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 5th August 2022 10:06:48 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

/*
 * File: batch-labeling.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th December 2021 4:25:01 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AIAssistanceService,
  AutoAnnotateType,
} from "services/ai-assistance-service";
import { RootState } from "store";
import { AutoAnnotatePair } from "../../smart-labeling/smart-labeling.state";
import { selectSmartLabelingData } from "../../smart-labeling/smart-labeling.selectors";
import { Point } from "utilities/math/point";
import { selectImageLabelingJobs } from "../batch-labeling.selectors";

const THUNK_NAME = "batch-labeling/imageAutoAnnotateAsync";

interface ImageAutoAnnotatePayload {
  jobIds: number[];
  pairs: AutoAnnotatePair[];
}

interface AnnotationMap {
  type: AutoAnnotateType;
  labelId: string;
  annotations: { points: Point[] }[];
}

export const imageAutoAnnotateAsync = createAsyncThunk(
  THUNK_NAME,
  async ({ jobIds, pairs }: ImageAutoAnnotatePayload, { getState }) => {
    const root = getState() as RootState;
    const smartLabelingData = selectSmartLabelingData(root);
    const selectedPairs = pairs.filter((p) => p.selected);
    const imageLabelingJobs = selectImageLabelingJobs(root);
    const foundAnnotationsMap: { [key: number]: AnnotationMap[] } = {};

    for (let jobId of jobIds) {
      const jobData = imageLabelingJobs.entities[jobId];
      const imageUrl = jobData.file?.originalUrl || jobData.file?.url || "";
      if (!imageUrl) continue;
      const foundAnnotations =
        await AIAssistanceService.autoDetectObjectsOnImage(
          imageUrl,
          selectedPairs,
          smartLabelingData.avaiableClasses,
          smartLabelingData.labels
        );
      foundAnnotationsMap[jobId] = foundAnnotations;
    }
    return foundAnnotationsMap;
  }
);
