/*
 * File: use-workflow.hook.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 24th August 2021 11:03:02 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { WorkflowInstructionService } from "services/label-service";
import { WorkflowInstructionDTO as InstructionDTO } from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import {
  selectLabelRequireIoUConfig,
  selectWorkflow,
} from "store/customer/workflow/workflow.selectors";
import { Logger } from "utilities/logger";
import * as Sentry from "@sentry/react";

export const useWorkflowInstructions = (workflowId: number) => {
  const workflow = useAppSelector(selectWorkflow(workflowId));
  const [instructions, setInstructions] = useState<InstructionDTO[]>([]);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const [requestError, setRequestError] = useState<string | null>(null);
  const stepConditionId = useAppSelector(selectLabelRequireIoUConfig);
  const isRequireIoUConfig = useMemo(
    () => hasSegmentationDisagree(instructions, stepConditionId),
    [instructions, stepConditionId]
  );

  const isRequireImageConsensus = useMemo(() => {
    return instructions.length > 1 && instructions.some((instruction) => instruction.condition)
  }, [instructions])

  const requestData = useCallback(async (id: number) => {
    if (id <= 0) return;
    try {
      setRequestStatus(RequestStatus.LOADING);
      setRequestError(null);
      setInstructions([]);
      const requestOptions = { workflowId: id.toString() };
      const response = await WorkflowInstructionService.getItems(
        requestOptions
      );
      const _instructions: InstructionDTO[] = response.data;
      setInstructions(_instructions.sort((a, b) => a.step - b.step));
      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      Sentry.captureException(error);
      Logger.error(error);
      const errMessage = error.message || "Failed to get instruction";
      setRequestError(errMessage);
    }
  }, []);

  useEffect(() => {
    if (workflowId) requestData(workflowId);
  }, [workflowId, requestData]);

  return {
    workflow,
    instructions,
    isRequireIoUConfig,
    isRequireImageConsensus,
    requestStatus,
    requestError,
  };
};

function hasSegmentationDisagree(
  instructions: InstructionDTO[],
  stepConditionId: string
) {
  if (!stepConditionId) return false;
  for (let i = 1; i < instructions.length; i++) {
    const condition = instructions[i].condition;
    if (condition && condition.indexOf(stepConditionId) !== -1) {
      return true;
    }
  }
  return false;
}
