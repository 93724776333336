
const COLORS = [
  "#00AA55",
  "#009FD4",
  "#B381B3",
  "#939393",
  "#E3BC00",
  "#D47500",
  "#DC2A2A",
];

function numberFromText(text: string) {
  const charCodes = text
    .split("")
    .map((char) => char.charCodeAt(0))
    .join("");
  return parseInt(charCodes, 10);
}

export const nameToAvatarColor = (name: string) => {
  return COLORS[numberFromText(name) % COLORS.length];;
}
