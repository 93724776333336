import { IconPlayArrow } from "components/common/vb-icon.component";
import { AppEvents } from "constants/annotation.constant";
import { useAppPrevious } from "hooks/use-app-previous";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertSecondToString } from "utilities/date-time/second-to-string";
import { inputManager } from "utilities/keyboard/input.manager";
import WaveSurfer from "wavesurfer.js";
import { getCursorPluginConfigs } from "./wavesurffer-plugins";


export interface SpeechToTextAudioPlayerProps {
  url: string;
  onAudioReady?: (duration?: number) => void;
  onLoadAudioError?: (error: any) => void;
  containerClasses?: string;
  toolClasses?: string;
}

export const SpeechToTextAudioPlayer = ({
  url,
  onAudioReady,
  onLoadAudioError,
  containerClasses = "border-t border-b border-gray-600 bg-background-800",
  toolClasses = "border-r border-gray-600",
}: SpeechToTextAudioPlayerProps) => {
  const { t } = useTranslation();
  const wavesurfer = useRef<any>(null);
  const wavesurferRef = useRef(null);
  const [isAudioReady, setIsAudioReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const previousURL = useAppPrevious(url);
  const [durationStr, setDurationStr] = useState("");
  const [currentSecond, setCurrentSecond] = useState(0);

  const handleAudioReady = useCallback(() => {
    setIsAudioReady(true);
    setDurationStr(convertSecondToString(wavesurfer.current.getDuration()));

    onAudioReady && onAudioReady(wavesurfer.current.getDuration());
  }, [onAudioReady]);

  const handleLoadAudioError = useCallback((error: any) => {
    console.log(error);
    onLoadAudioError && onLoadAudioError(error);
  }, [onLoadAudioError]);

  const handleAudioProccess = useCallback(() => {
    setCurrentSecond(wavesurfer.current.getCurrentTime());
  }, []);

  const handleSeek = useCallback(() => {
    setCurrentSecond(wavesurfer.current.getCurrentTime());
  }, []);

  const handleFinish = useCallback(() => {
    setIsPlaying(false);
    wavesurfer.current.seekTo(0);
    setCurrentSecond(0);
  }, []);

  useEffect(() => {
    if (!url) return;
    if (!!previousURL && previousURL === url) {
      handleAudioReady();
      return;
    }

    // remove resource from previous state
    if (wavesurfer.current){
      wavesurfer.current.destroy();
      wavesurfer.current = undefined;
    }

    if (!wavesurfer.current && wavesurferRef.current){
      const options: any = {
        container: wavesurferRef.current,
        cursorWidth: 1,
        cursorColor: "#EA7E00",
        progressColor: "#EA7E00",
        responsive: true,
        plugins: [
          getCursorPluginConfigs(),
        ]
      }
      wavesurfer.current = WaveSurfer.create(options);
      wavesurfer.current.load(url);
    }

    if (wavesurfer.current){
      wavesurfer.current.on("ready", handleAudioReady);
      wavesurfer.current.on("error", handleLoadAudioError);
      wavesurfer.current.on("finish", handleFinish);
      wavesurfer.current.on("audioprocess", handleAudioProccess);
      wavesurfer.current.on("seek", handleSeek);
    }
  }, [
    url,
    previousURL,
    onAudioReady,
    onLoadAudioError,
    handleAudioReady,
    handleLoadAudioError,
    handleFinish,
    handleAudioProccess,
    handleSeek,
  ]);

  useEffect(() => {
    if (!wavesurfer.current) return;
    if (isPlaying){
      wavesurfer.current.play();
    } else {
      wavesurfer.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    const hanldeShortCutKeyDown = () => {
      if (inputManager.isSpaceDown()) {
        if (wavesurfer.current) {
          if (wavesurfer.current.isPlaying()){
            wavesurfer.current.pause();
          } else {
            wavesurfer.current.play();
          }
        }
      }
    }

    document.addEventListener(AppEvents.SHORT_CUT_KEY_DOWN, hanldeShortCutKeyDown);

    return () => {
      document.removeEventListener(AppEvents.SHORT_CUT_KEY_DOWN, hanldeShortCutKeyDown);
    }
  }, []);

  return (
    <div className={`w-full flex ${containerClasses}`}>
      <div className={`flex flex-col items-center px-6 py-7 justify-center ${toolClasses}`}
        style={{
          width: "14rem",
          maxWidth: "14rem"
        }}
      >
        <div className="relative w-40">
          {
            !isPlaying &&
            <button className="button-warn disabled:opacity-50 w-full"
              onClick={_ => setIsPlaying(true)}
              disabled={!isAudioReady}
            >
              {t("common:buttonPlay")}
              <IconPlayArrow />
            </button>
          }
          {
            isPlaying &&
            <button className="button-warn disabled:opacity-50 w-full"
              onClick={_ => setIsPlaying(false)}
              disabled={!isAudioReady}
            >
              {t("common:buttonPause")}
            </button>
          }
          <div className="absolute text-center w-full pt-1">
            {convertSecondToString(currentSecond)}/{durationStr}
          </div>
        </div>
      </div>
      <div className="flex-grow relative">
        <div ref={wavesurferRef} className="">
        </div>
      </div>
    </div>
  )
}