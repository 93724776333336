/*
 * File: delete-project.dialog.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/use-redux";
import {
  enqueueSuccessNotification,
  enqueueErrorNotification,
} from "store/common/notification/notification.actions";
import { deleteProjectAsync } from "store/customer/projects/projects.slice";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { useState } from "react";
import { ProjectDTO } from "services/label-service/dtos";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import * as Sentry from "@sentry/react";

interface Props {
  project: ProjectDTO;
  handleClose: () => void;
}

export const DeleteProjectDialog = ({ project, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);

  async function handleDelete() {
    if (!project) return;
    if (processing) return;
    try {
      setProcessing(true);
      const response = await dispatch(deleteProjectAsync(project.id));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textDeletedSuccess")));
    } catch (err: any) {
      Sentry.captureException(err);
      const message = err.message || t("common:textDeletedFailed")!;
      dispatch(enqueueErrorNotification(message));
    } finally {
      setProcessing(false);
      handleClose();
    }
  }

  return (
    <VBModal
      title={t("project:deleteDialog.title")}
      open={!!project}
      onClose={handleClose}
      textSubmit={t("common:buttonDelete")}
      onSubmit={handleDelete}
      disableSubmit={processing}
      blockUI={processing}
      processingIndicator={processing}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: t("project:deleteDialog.message", { name: project.name }),
        }}
      />
    </VBModal>
  );
};
