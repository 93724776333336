/* tslint:disable */
/* eslint-disable */
/**
 * storage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdditionalFileInfoDTO
 */
export interface AdditionalFileInfoDTO {
    /**
     * 
     * @type {number}
     * @memberof AdditionalFileInfoDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdditionalFileInfoDTO
     */
    'fileInfoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdditionalFileInfoDTO
     */
    'extend'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalFileInfoDTO
     */
    'contentType'?: string;
    /**
     * 
     * @type {{ [key: string]: any | undefined; }}
     * @memberof AdditionalFileInfoDTO
     */
    'addition'?: { [key: string]: any | undefined; };
}
/**
 * 
 * @export
 * @interface AdditionalFileInfoDetailsDTO
 */
export interface AdditionalFileInfoDetailsDTO {
    /**
     * 
     * @type {{ [key: string]: any | undefined; }}
     * @memberof AdditionalFileInfoDetailsDTO
     */
    'metadata'?: { [key: string]: any | undefined; };
    /**
     * 
     * @type {string}
     * @memberof AdditionalFileInfoDetailsDTO
     */
    'contentType'?: string;
}
/**
 * 
 * @export
 * @interface BaseDTO
 */
export interface BaseDTO {
    /**
     * 
     * @type {string}
     * @memberof BaseDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseDTO
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface CloudCriteria
 */
export interface CloudCriteria {
    /**
     * 
     * @type {number}
     * @memberof CloudCriteria
     */
    'datasetId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof CloudCriteria
     */
    'datasetId.in'?: string;
}
/**
 * 
 * @export
 * @interface CloudDTO
 */
export interface CloudDTO {
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CloudDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'cloudType'?: string;
    /**
     * check FileConstants.storeType
     * @type {string}
     * @memberof CloudDTO
     */
    'storeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'sasUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof CloudDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CloudDTO
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof CloudDTO
     */
    'totalFile'?: number;
    /**
     * 
     * @type {FileImportStatistic}
     * @memberof CloudDTO
     */
    'importStatistic'?: FileImportStatistic;
    /**
     * 
     * @type {Array<number>}
     * @memberof CloudDTO
     */
    'tagIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CloudDTOAllOf
 */
export interface CloudDTOAllOf {
    /**
     * 
     * @type {number}
     * @memberof CloudDTOAllOf
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CloudDTOAllOf
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudDTOAllOf
     */
    'cloudType'?: string;
    /**
     * check FileConstants.storeType
     * @type {string}
     * @memberof CloudDTOAllOf
     */
    'storeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudDTOAllOf
     */
    'sasUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof CloudDTOAllOf
     */
    'datasetId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CloudDTOAllOf
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof CloudDTOAllOf
     */
    'totalFile'?: number;
    /**
     * 
     * @type {FileImportStatistic}
     * @memberof CloudDTOAllOf
     */
    'importStatistic'?: FileImportStatistic;
    /**
     * 
     * @type {Array<number>}
     * @memberof CloudDTOAllOf
     */
    'tagIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CloudRequestDTO
 */
export interface CloudRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CloudRequestDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudRequestDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudRequestDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudRequestDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudRequestDTO
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudRequestDTO
     */
    'cloudType'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudRequestDTO
     */
    'sasUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudRequestDTO
     */
    'storeType'?: string;
    /**
     * 
     * @type {number}
     * @memberof CloudRequestDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {Array<MetadataRequestDTO>}
     * @memberof CloudRequestDTO
     */
    'metadata'?: Array<MetadataRequestDTO>;
}
/**
 * 
 * @export
 * @interface CloudRequestDTOAllOf
 */
export interface CloudRequestDTOAllOf {
    /**
     * 
     * @type {string}
     * @memberof CloudRequestDTOAllOf
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudRequestDTOAllOf
     */
    'cloudType'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudRequestDTOAllOf
     */
    'sasUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudRequestDTOAllOf
     */
    'storeType'?: string;
    /**
     * 
     * @type {number}
     * @memberof CloudRequestDTOAllOf
     */
    'datasetId'?: number;
    /**
     * 
     * @type {Array<MetadataRequestDTO>}
     * @memberof CloudRequestDTOAllOf
     */
    'metadata'?: Array<MetadataRequestDTO>;
}
/**
 * 
 * @export
 * @interface CopyDatasetRequestDTO
 */
export interface CopyDatasetRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof CopyDatasetRequestDTO
     */
    'fromDatasetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CopyDatasetRequestDTO
     */
    'toDatasetId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CopyDatasetRequestDTO
     */
    'fileInfoIds'?: Array<number>;
    /**
     * when this is true, original file infos are removed from original dataset 
     * @type {boolean}
     * @memberof CopyDatasetRequestDTO
     */
    'move'?: boolean;
}
/**
 * 
 * @export
 * @interface CopyDatasetResponseDTO
 */
export interface CopyDatasetResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof CopyDatasetResponseDTO
     */
    'toDatasetId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CopyDatasetResponseDTO
     */
    'addedFileInfoIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DataCleaningJobCriteria
 */
export interface DataCleaningJobCriteria {
    /**
     * 
     * @type {number}
     * @memberof DataCleaningJobCriteria
     */
    'batchId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataCleaningJobCriteria
     */
    'batchId.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof DataCleaningJobCriteria
     */
    'datasetId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataCleaningJobCriteria
     */
    'datasetId.in'?: string;
}
/**
 * 
 * @export
 * @interface DataCleaningJobDTO
 */
export interface DataCleaningJobDTO {
    /**
     * 
     * @type {number}
     * @memberof DataCleaningJobDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataCleaningJobDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataCleaningJobDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataCleaningJobDTO
     */
    'datasetVersionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataCleaningJobDTO
     */
    'runId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataCleaningJobDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataCleaningJobDTO
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataCleaningJobDTO
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DataCleaningJobRequestDTO
 */
export interface DataCleaningJobRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof DataCleaningJobRequestDTO
     */
    'batchId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataCleaningJobRequestDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataCleaningJobRequestDTO
     */
    'datasetVersionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataCleaningJobRequestDTO
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataCleaningJobRequestDTO
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DataCleaningResultDTO
 */
export interface DataCleaningResultDTO {
    /**
     * 
     * @type {string}
     * @memberof DataCleaningResultDTO
     */
    'jobType'?: string;
    /**
     * 
     * @type {object}
     * @memberof DataCleaningResultDTO
     */
    'cleaningResult'?: object;
}
/**
 * 
 * @export
 * @interface DatasetAttributeDTO
 */
export interface DatasetAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof DatasetAttributeDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DatasetAttributeDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTO
     */
    'attribute'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTO
     */
    'value'?: string;
    /**
     * 
     * @type {DatasetAttributeType}
     * @memberof DatasetAttributeDTO
     */
    'type'?: DatasetAttributeType;
}


/**
 * 
 * @export
 * @interface DatasetAttributeDTOAllOf
 */
export interface DatasetAttributeDTOAllOf {
    /**
     * 
     * @type {number}
     * @memberof DatasetAttributeDTOAllOf
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DatasetAttributeDTOAllOf
     */
    'datasetId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTOAllOf
     */
    'attribute'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetAttributeDTOAllOf
     */
    'value'?: string;
    /**
     * 
     * @type {DatasetAttributeType}
     * @memberof DatasetAttributeDTOAllOf
     */
    'type'?: DatasetAttributeType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DatasetAttributeType = {
    Long: 'LONG',
    Text: 'TEXT',
    Double: 'DOUBLE'
} as const;

export type DatasetAttributeType = typeof DatasetAttributeType[keyof typeof DatasetAttributeType];


/**
 * 
 * @export
 * @interface DatasetCriteria
 */
export interface DatasetCriteria {
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'type.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'type.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'type.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'name.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'name.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'name.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'category.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'category.in'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'category.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'createdDate.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'createdDate.greaterThan'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetCriteria
     */
    'createdDate.lessThan'?: string;
}
/**
 * 
 * @export
 * @interface DatasetDTO
 */
export interface DatasetDTO {
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof DatasetDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'category'?: string;
    /**
     * 
     * @type {Array<DatasetVersionDTO>}
     * @memberof DatasetDTO
     */
    'versions'?: Array<DatasetVersionDTO>;
    /**
     * 
     * @type {Array<DatasetAttributeDTO>}
     * @memberof DatasetDTO
     */
    'attributes'?: Array<DatasetAttributeDTO>;
    /**
     * 
     * @type {number}
     * @memberof DatasetDTO
     */
    'totalFile'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTO
     */
    'thumbnailUrl'?: string;
}
/**
 * 
 * @export
 * @interface DatasetDTOAllOf
 */
export interface DatasetDTOAllOf {
    /**
     * 
     * @type {number}
     * @memberof DatasetDTOAllOf
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTOAllOf
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTOAllOf
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTOAllOf
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTOAllOf
     */
    'category'?: string;
    /**
     * 
     * @type {Array<DatasetVersionDTO>}
     * @memberof DatasetDTOAllOf
     */
    'versions'?: Array<DatasetVersionDTO>;
    /**
     * 
     * @type {Array<DatasetAttributeDTO>}
     * @memberof DatasetDTOAllOf
     */
    'attributes'?: Array<DatasetAttributeDTO>;
    /**
     * 
     * @type {number}
     * @memberof DatasetDTOAllOf
     */
    'totalFile'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetDTOAllOf
     */
    'thumbnailUrl'?: string;
}
/**
 * 
 * @export
 * @interface DatasetTypeResult
 */
export interface DatasetTypeResult {
    /**
     * 
     * @type {string}
     * @memberof DatasetTypeResult
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetTypeResult
     */
    'code'?: DatasetTypeResultCodeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetTypeResult
     */
    'dataTypes'?: Array<string>;
}

export const DatasetTypeResultCodeEnum = {
    Image: 'image',
    Dicom: 'dicom',
    Text: 'text',
    Audio: 'audio',
    Mdi: 'mdi',
    Video: 'video',
    Wsi: 'wsi'
} as const;

export type DatasetTypeResultCodeEnum = typeof DatasetTypeResultCodeEnum[keyof typeof DatasetTypeResultCodeEnum];

/**
 * 
 * @export
 * @interface DatasetVersionCriteria
 */
export interface DatasetVersionCriteria {
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionCriteria
     */
    'datasetId.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionCriteria
     */
    'datasetId.in'?: string;
}
/**
 * 
 * @export
 * @interface DatasetVersionDTO
 */
export interface DatasetVersionDTO {
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof DatasetVersionDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DatasetVersionDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'datasetVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'datasetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTO
     */
    'databricksDatasetVersion'?: string;
}
/**
 * 
 * @export
 * @interface DatasetVersionDTOAllOf
 */
export interface DatasetVersionDTOAllOf {
    /**
     * 
     * @type {number}
     * @memberof DatasetVersionDTOAllOf
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DatasetVersionDTOAllOf
     */
    'datasetId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTOAllOf
     */
    'datasetVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTOAllOf
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTOAllOf
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTOAllOf
     */
    'datasetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTOAllOf
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVersionDTOAllOf
     */
    'databricksDatasetVersion'?: string;
}
/**
 * 
 * @export
 * @interface DicomMetaDataDTO
 */
export interface DicomMetaDataDTO {
    /**
     * 
     * @type {number}
     * @memberof DicomMetaDataDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DicomMetaDataDTO
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof DicomMetaDataDTO
     */
    'studyId': string;
    /**
     * 
     * @type {string}
     * @memberof DicomMetaDataDTO
     */
    'seriesId': string;
    /**
     * 
     * @type {string}
     * @memberof DicomMetaDataDTO
     */
    'instanceId': string;
    /**
     * 
     * @type {string}
     * @memberof DicomMetaDataDTO
     */
    'retrieveId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DicomMetaDataDTO
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DicomServerCredentialType = {
    None: 'NONE',
    Cookie: 'COOKIE',
    Header: 'HEADER',
    QueryString: 'QUERY_STRING'
} as const;

export type DicomServerCredentialType = typeof DicomServerCredentialType[keyof typeof DicomServerCredentialType];


/**
 * 
 * @export
 * @interface DicomServerRequestDTO
 */
export interface DicomServerRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof DicomServerRequestDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DicomServerRequestDTO
     */
    'endpoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof DicomServerRequestDTO
     */
    'credentialKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof DicomServerRequestDTO
     */
    'credentialValue'?: string;
    /**
     * 
     * @type {DicomServerCredentialType}
     * @memberof DicomServerRequestDTO
     */
    'credentialType'?: DicomServerCredentialType;
    /**
     * 
     * @type {boolean}
     * @memberof DicomServerRequestDTO
     */
    'uploadEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DicomServerRequestDTO
     */
    'serveEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DicomServerRequestDTO
     */
    'directServing'?: boolean;
}


/**
 * 
 * @export
 * @interface DicomServerResponseDTO
 */
export interface DicomServerResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof DicomServerResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DicomServerResponseDTO
     */
    'endpoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof DicomServerResponseDTO
     */
    'credentialKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof DicomServerResponseDTO
     */
    'credentialValue'?: string;
    /**
     * 
     * @type {DicomServerCredentialType}
     * @memberof DicomServerResponseDTO
     */
    'credentialType'?: DicomServerCredentialType;
    /**
     * 
     * @type {boolean}
     * @memberof DicomServerResponseDTO
     */
    'uploadEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DicomServerResponseDTO
     */
    'serveEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DicomServerResponseDTO
     */
    'directServing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DicomServerResponseDTO
     */
    'id'?: string;
}


/**
 * 
 * @export
 * @interface DicomServerResponseDTOAllOf
 */
export interface DicomServerResponseDTOAllOf {
    /**
     * 
     * @type {string}
     * @memberof DicomServerResponseDTOAllOf
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DuplicateDatasetResponseDTO
 */
export interface DuplicateDatasetResponseDTO {
    /**
     * Id of newly created dataset
     * @type {number}
     * @memberof DuplicateDatasetResponseDTO
     */
    'datasetId'?: number;
}
/**
 * 
 * @export
 * @interface FileConstants
 */
export interface FileConstants {
    /**
     * process Status
     * @type {string}
     * @memberof FileConstants
     */
    'status'?: FileConstantsStatusEnum;
    /**
     * cloud type
     * @type {string}
     * @memberof FileConstants
     */
    'cloudType'?: FileConstantsCloudTypeEnum;
    /**
     * cloud store type
     * @type {string}
     * @memberof FileConstants
     */
    'storeType'?: FileConstantsStoreTypeEnum;
    /**
     * cloud tao lao
     * @type {string}
     * @memberof FileConstants
     */
    'cloudMethod'?: FileConstantsCloudMethodEnum;
    /**
     * delete file
     * @type {string}
     * @memberof FileConstants
     */
    'deleteType'?: FileConstantsDeleteTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FileConstants
     */
    'cleaningJobStatus'?: FileConstantsCleaningJobStatusEnum;
}

export const FileConstantsStatusEnum = {
    Processing: 'processing',
    Finished: 'finished',
    Duplicated: 'duplicated',
    CloudExisted: 'cloud_existed',
    Existed: 'existed'
} as const;

export type FileConstantsStatusEnum = typeof FileConstantsStatusEnum[keyof typeof FileConstantsStatusEnum];
export const FileConstantsCloudTypeEnum = {
    AzureBlobStorage: 'azure_blob_storage',
    Dcm4chee: 'dcm4chee'
} as const;

export type FileConstantsCloudTypeEnum = typeof FileConstantsCloudTypeEnum[keyof typeof FileConstantsCloudTypeEnum];
export const FileConstantsStoreTypeEnum = {
    Copy: 'copy',
    Refer: 'refer'
} as const;

export type FileConstantsStoreTypeEnum = typeof FileConstantsStoreTypeEnum[keyof typeof FileConstantsStoreTypeEnum];
export const FileConstantsCloudMethodEnum = {
    CheckConnection: 'check_connection',
    Sync: 'sync'
} as const;

export type FileConstantsCloudMethodEnum = typeof FileConstantsCloudMethodEnum[keyof typeof FileConstantsCloudMethodEnum];
export const FileConstantsDeleteTypeEnum = {
    Archive: 'archive',
    DeleteFile: 'delete_file',
    DeleteAll: 'delete_all'
} as const;

export type FileConstantsDeleteTypeEnum = typeof FileConstantsDeleteTypeEnum[keyof typeof FileConstantsDeleteTypeEnum];
export const FileConstantsCleaningJobStatusEnum = {
    Created: 'created',
    Running: 'running',
    Completed: 'completed'
} as const;

export type FileConstantsCleaningJobStatusEnum = typeof FileConstantsCleaningJobStatusEnum[keyof typeof FileConstantsCleaningJobStatusEnum];

/**
 * 
 * @export
 * @interface FileImportStatistic
 */
export interface FileImportStatistic {
    /**
     * 
     * @type {number}
     * @memberof FileImportStatistic
     */
    'success'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileImportStatistic
     */
    'failure'?: number;
}
/**
 * 
 * @export
 * @interface FileInfoCriteria
 */
export interface FileInfoCriteria {
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'type.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'type.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'fileName.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'fileName.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'createdDate.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'createdDate.greaterThan'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'createdDate.lessThan'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoCriteria
     */
    'cloudId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'cloudId.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoCriteria
     */
    'datasetId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'datasetId.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoCriteria
     */
    'datasetVersionId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'datasetVersionId.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoCriteria
     */
    'scopeId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'scopeId.in'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoCriteria
     */
    'tagId.equals'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCriteria
     */
    'tagId.in'?: string;
}
/**
 * 
 * @export
 * @interface FileInfoDTO
 */
export interface FileInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'storeType'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTO
     */
    'scopeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'referenceUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'hash'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTO
     */
    'cloudId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTO
     */
    'duplicatedRef'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTO
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTO
     */
    'processStatus'?: string;
    /**
     * 
     * @type {Array<FileInfoMetadataDTO>}
     * @memberof FileInfoDTO
     */
    'fileInfoMetadata'?: Array<FileInfoMetadataDTO>;
    /**
     * 
     * @type {AdditionalFileInfoDTO}
     * @memberof FileInfoDTO
     */
    'additionalFileInfo'?: AdditionalFileInfoDTO;
}
/**
 * 
 * @export
 * @interface FileInfoDTOAllOf
 */
export interface FileInfoDTOAllOf {
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTOAllOf
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTOAllOf
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTOAllOf
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTOAllOf
     */
    'storeType'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTOAllOf
     */
    'datasetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTOAllOf
     */
    'scopeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTOAllOf
     */
    'referenceUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTOAllOf
     */
    'hash'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTOAllOf
     */
    'cloudId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTOAllOf
     */
    'duplicatedRef'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDTOAllOf
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDTOAllOf
     */
    'processStatus'?: string;
    /**
     * 
     * @type {Array<FileInfoMetadataDTO>}
     * @memberof FileInfoDTOAllOf
     */
    'fileInfoMetadata'?: Array<FileInfoMetadataDTO>;
    /**
     * 
     * @type {AdditionalFileInfoDTO}
     * @memberof FileInfoDTOAllOf
     */
    'additionalFileInfo'?: AdditionalFileInfoDTO;
}
/**
 * 
 * @export
 * @interface FileInfoDatasetDTO
 */
export interface FileInfoDatasetDTO {
    /**
     * 
     * @type {number}
     * @memberof FileInfoDatasetDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDatasetDTO
     */
    'scopeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDatasetDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDatasetDTO
     */
    'fileInfoId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDatasetDTO
     */
    'datasetVersionId'?: number;
}
/**
 * 
 * @export
 * @interface FileInfoDetailsDTO
 */
export interface FileInfoDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTO
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTO
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDetailsDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTO
     */
    'storeType'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDetailsDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDetailsDTO
     */
    'scopeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTO
     */
    'hash'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDetailsDTO
     */
    'cloudId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDetailsDTO
     */
    'duplicatedRef'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDetailsDTO
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTO
     */
    'processStatus'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileInfoDetailsDTO
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Array<DataCleaningResultDTO>}
     * @memberof FileInfoDetailsDTO
     */
    'dataCleaningResult'?: Array<DataCleaningResultDTO>;
    /**
     * 
     * @type {FileInfoDetailsDTOAllOfAdditionalProperties}
     * @memberof FileInfoDetailsDTO
     */
    'additionalProperties'?: FileInfoDetailsDTOAllOfAdditionalProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof FileInfoDetailsDTO
     */
    'downloadUrls'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FileInfoDetailsDTOAllOf
 */
export interface FileInfoDetailsDTOAllOf {
    /**
     * 
     * @type {number}
     * @memberof FileInfoDetailsDTOAllOf
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTOAllOf
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTOAllOf
     */
    'storeType'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDetailsDTOAllOf
     */
    'datasetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDetailsDTOAllOf
     */
    'scopeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTOAllOf
     */
    'hash'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDetailsDTOAllOf
     */
    'cloudId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDetailsDTOAllOf
     */
    'duplicatedRef'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDetailsDTOAllOf
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTOAllOf
     */
    'processStatus'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileInfoDetailsDTOAllOf
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Array<DataCleaningResultDTO>}
     * @memberof FileInfoDetailsDTOAllOf
     */
    'dataCleaningResult'?: Array<DataCleaningResultDTO>;
    /**
     * 
     * @type {FileInfoDetailsDTOAllOfAdditionalProperties}
     * @memberof FileInfoDetailsDTOAllOf
     */
    'additionalProperties'?: FileInfoDetailsDTOAllOfAdditionalProperties;
    /**
     * 
     * @type {Array<string>}
     * @memberof FileInfoDetailsDTOAllOf
     */
    'downloadUrls'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FileInfoDetailsDTOAllOfAdditionalProperties
 */
export interface FileInfoDetailsDTOAllOfAdditionalProperties {
    /**
     * 
     * @type {{ [key: string]: any | undefined; }}
     * @memberof FileInfoDetailsDTOAllOfAdditionalProperties
     */
    'metadata'?: { [key: string]: any | undefined; };
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTOAllOfAdditionalProperties
     */
    'contentType'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTOAllOfAdditionalProperties
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDetailsDTOAllOfAdditionalProperties
     */
    'fileSize'?: number;
}
/**
 * 
 * @export
 * @interface FileInfoDetailsDTOAllOfAdditionalPropertiesAllOf
 */
export interface FileInfoDetailsDTOAllOfAdditionalPropertiesAllOf {
    /**
     * 
     * @type {string}
     * @memberof FileInfoDetailsDTOAllOfAdditionalPropertiesAllOf
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDetailsDTOAllOfAdditionalPropertiesAllOf
     */
    'fileSize'?: number;
}
/**
 * 
 * @export
 * @interface FileInfoInitResponseDTO
 */
export interface FileInfoInitResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof FileInfoInitResponseDTO
     */
    'servingUrl'?: string;
}
/**
 * 
 * @export
 * @interface FileInfoMetadataDTO
 */
export interface FileInfoMetadataDTO {
    /**
     * 
     * @type {number}
     * @memberof FileInfoMetadataDTO
     */
    'id'?: number;
    /**
     * 
     * @type {MetadataKeyDTO}
     * @memberof FileInfoMetadataDTO
     */
    'metadataKey'?: MetadataKeyDTO;
    /**
     * 
     * @type {MetadataValueDTO}
     * @memberof FileInfoMetadataDTO
     */
    'metadataValue'?: MetadataValueDTO;
}
/**
 * 
 * @export
 * @interface FileInfoMetadataRequestDTO
 */
export interface FileInfoMetadataRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof FileInfoMetadataRequestDTO
     */
    'fileInfoId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoMetadataRequestDTO
     */
    'keyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInfoMetadataRequestDTO
     */
    'valueId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoMetadataRequestDTO
     */
    'action'?: FileInfoMetadataRequestDTOActionEnum;
}

export const FileInfoMetadataRequestDTOActionEnum = {
    Add: 'ADD',
    Delete: 'DELETE'
} as const;

export type FileInfoMetadataRequestDTOActionEnum = typeof FileInfoMetadataRequestDTOActionEnum[keyof typeof FileInfoMetadataRequestDTOActionEnum];

/**
 * 
 * @export
 * @interface HealthGetResponse
 */
export interface HealthGetResponse {
    /**
     * 
     * @type {string}
     * @memberof HealthGetResponse
     */
    'status'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HealthGetResponse
     */
    'groups'?: Array<string>;
}
/**
 * 
 * @export
 * @interface InformationGetResponse
 */
export interface InformationGetResponse {
    /**
     * 
     * @type {string}
     * @memberof InformationGetResponse
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationGetResponse
     */
    'releaseDate'?: string;
}
/**
 * 
 * @export
 * @interface ListResourceCredentialResponse
 */
export interface ListResourceCredentialResponse {
    /**
     * 
     * @type {Array<ResourceCredentialResponse>}
     * @memberof ListResourceCredentialResponse
     */
    'payload'?: Array<ResourceCredentialResponse>;
}
/**
 * 
 * @export
 * @interface MedicalCloudDTO
 */
export interface MedicalCloudDTO {
    /**
     * id of DicomServer
     * @type {string}
     * @memberof MedicalCloudDTO
     */
    'dicomServerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalCloudDTO
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalCloudDTO
     */
    'cloudType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalCloudDTO
     */
    'sasUrl'?: string;
}
/**
 * 
 * @export
 * @interface MedicalDatasetCreateRequestDTO
 */
export interface MedicalDatasetCreateRequestDTO {
    /**
     * id of DicomServer
     * @type {string}
     * @memberof MedicalDatasetCreateRequestDTO
     */
    'dicomServerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalDatasetCreateRequestDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalDatasetCreateRequestDTO
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalDatasetCreateRequestDTO
     */
    'uids'?: Array<string>;
    /**
     * 
     * @type {MedicalViewType}
     * @memberof MedicalDatasetCreateRequestDTO
     */
    'viewType'?: MedicalViewType;
}


/**
 * 
 * @export
 * @interface MedicalMetadataRequestDTO
 */
export interface MedicalMetadataRequestDTO {
    /**
     * id of DicomServer
     * @type {string}
     * @memberof MedicalMetadataRequestDTO
     */
    'dicomServerId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalMetadataRequestDTO
     */
    'uids'?: Array<string>;
    /**
     * 
     * @type {MedicalViewType}
     * @memberof MedicalMetadataRequestDTO
     */
    'viewType'?: MedicalViewType;
}


/**
 * 
 * @export
 * @interface MedicalSearchCriteria
 */
export interface MedicalSearchCriteria {
    /**
     * 
     * @type {string}
     * @memberof MedicalSearchCriteria
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalSearchCriteria
     */
    'instanceUids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalSearchCriteria
     */
    'studyUids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalSearchCriteria
     */
    'seriesUids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalSearchCriteria
     */
    'patientIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface MedicalSearchRequestDTO
 */
export interface MedicalSearchRequestDTO {
    /**
     * id of DicomServer
     * @type {string}
     * @memberof MedicalSearchRequestDTO
     */
    'dicomServerId'?: string;
    /**
     * 
     * @type {MedicalSearchCriteria}
     * @memberof MedicalSearchRequestDTO
     */
    'criteria'?: MedicalSearchCriteria;
    /**
     * 
     * @type {MedicalViewType}
     * @memberof MedicalSearchRequestDTO
     */
    'viewType'?: MedicalViewType;
}


/**
 * 
 * @export
 * @interface MedicalSearchResponseDTO
 */
export interface MedicalSearchResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof MedicalSearchResponseDTO
     */
    'patientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalSearchResponseDTO
     */
    'studyUid'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalSearchResponseDTO
     */
    'seriesUid'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalSearchResponseDTO
     */
    'instanceUid'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MedicalViewType = {
    Study: 'Study',
    Series: 'Series',
    Instance: 'Instance'
} as const;

export type MedicalViewType = typeof MedicalViewType[keyof typeof MedicalViewType];


/**
 * 
 * @export
 * @interface MergeDatasetRequestDTO
 */
export interface MergeDatasetRequestDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof MergeDatasetRequestDTO
     */
    'datasetIds'?: Array<number>;
    /**
     * new dataset name
     * @type {string}
     * @memberof MergeDatasetRequestDTO
     */
    'name'?: string;
    /**
     * 
     * @type {MergeDatasetRequestDTOOptions}
     * @memberof MergeDatasetRequestDTO
     */
    'options'?: MergeDatasetRequestDTOOptions;
}
/**
 * 
 * @export
 * @interface MergeDatasetRequestDTOOptions
 */
export interface MergeDatasetRequestDTOOptions {
    /**
     * 
     * @type {boolean}
     * @memberof MergeDatasetRequestDTOOptions
     */
    'deleteSource'?: boolean;
}
/**
 * 
 * @export
 * @interface MergeDatasetResponseDTO
 */
export interface MergeDatasetResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof MergeDatasetResponseDTO
     */
    'datasetId'?: number;
}
/**
 * 
 * @export
 * @interface MetadataKeyCriteria
 */
export interface MetadataKeyCriteria {
    /**
     * 
     * @type {string}
     * @memberof MetadataKeyCriteria
     */
    'name.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataKeyCriteria
     */
    'name.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataKeyCriteria
     */
    'name.in'?: string;
}
/**
 * 
 * @export
 * @interface MetadataKeyDTO
 */
export interface MetadataKeyDTO {
    /**
     * 
     * @type {number}
     * @memberof MetadataKeyDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MetadataKeyDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataKeyDTO
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface MetadataRequestDTO
 */
export interface MetadataRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof MetadataRequestDTO
     */
    'keyId': number;
    /**
     * 
     * @type {number}
     * @memberof MetadataRequestDTO
     */
    'valueId': number;
}
/**
 * 
 * @export
 * @interface MetadataValueCriteria
 */
export interface MetadataValueCriteria {
    /**
     * 
     * @type {string}
     * @memberof MetadataValueCriteria
     */
    'name.equals'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataValueCriteria
     */
    'name.contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataValueCriteria
     */
    'name.in'?: string;
}
/**
 * 
 * @export
 * @interface MetadataValueDTO
 */
export interface MetadataValueDTO {
    /**
     * 
     * @type {number}
     * @memberof MetadataValueDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MetadataValueDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataValueDTO
     */
    'code'?: string;
}
/**
 * minimal Pageable query parameters
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'totalItem'?: number;
}
/**
 * 
 * @export
 * @interface PublishResult
 */
export interface PublishResult {
    /**
     * 
     * @type {string}
     * @memberof PublishResult
     */
    'topic'?: string;
    /**
     * 
     * @type {number}
     * @memberof PublishResult
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof PublishResult
     */
    'offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof PublishResult
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface ResourceCredentialResponse
 */
export interface ResourceCredentialResponse {
    /**
     * 
     * @type {string}
     * @memberof ResourceCredentialResponse
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceCredentialResponse
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceCredentialResponse
     */
    'tokenType'?: ResourceCredentialResponseTokenTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ResourceCredentialResponse
     */
    'provider'?: string;
    /**
     * unix timestamp of expired time (GMT+0)
     * @type {number}
     * @memberof ResourceCredentialResponse
     */
    'expiredTime'?: number;
}

export const ResourceCredentialResponseTokenTypeEnum = {
    SasToken: 'SasToken'
} as const;

export type ResourceCredentialResponseTokenTypeEnum = typeof ResourceCredentialResponseTokenTypeEnum[keyof typeof ResourceCredentialResponseTokenTypeEnum];

/**
 * 
 * @export
 * @interface RestResponse
 */
export interface RestResponse {
    /**
     * 
     * @type {string}
     * @memberof RestResponse
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponse
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponse
     */
    'succeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseOfAdditionalFileInfoDTO
 */
export interface RestResponseOfAdditionalFileInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfAdditionalFileInfoDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfAdditionalFileInfoDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfAdditionalFileInfoDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfAdditionalFileInfoDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {AdditionalFileInfoDTO}
     * @memberof RestResponseOfAdditionalFileInfoDTO
     */
    'payload'?: AdditionalFileInfoDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfAdditionalFileInfoDTOAllOf
 */
export interface RestResponseOfAdditionalFileInfoDTOAllOf {
    /**
     * 
     * @type {AdditionalFileInfoDTO}
     * @memberof RestResponseOfAdditionalFileInfoDTOAllOf
     */
    'payload'?: AdditionalFileInfoDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfAdditionalFileInfoDTOS
 */
export interface RestResponseOfAdditionalFileInfoDTOS {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfAdditionalFileInfoDTOS
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfAdditionalFileInfoDTOS
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfAdditionalFileInfoDTOS
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfAdditionalFileInfoDTOS
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<AdditionalFileInfoDTO>}
     * @memberof RestResponseOfAdditionalFileInfoDTOS
     */
    'payload'?: Array<AdditionalFileInfoDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfBoolean
 */
export interface RestResponseOfBoolean {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfBoolean
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfBoolean
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfBoolean
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfBoolean
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfBoolean
     */
    'payload'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseOfBooleanAllOf
 */
export interface RestResponseOfBooleanAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfBooleanAllOf
     */
    'payload'?: boolean;
}
/**
 * 
 * @export
 * @interface RestResponseOfCloudDTO
 */
export interface RestResponseOfCloudDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfCloudDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfCloudDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfCloudDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfCloudDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {CloudDTO}
     * @memberof RestResponseOfCloudDTO
     */
    'payload'?: CloudDTO;
    /**
     * 
     * @type {UploadCloudResponseDTO}
     * @memberof RestResponseOfCloudDTO
     */
    'job'?: UploadCloudResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfCloudDTOAllOf
 */
export interface RestResponseOfCloudDTOAllOf {
    /**
     * 
     * @type {CloudDTO}
     * @memberof RestResponseOfCloudDTOAllOf
     */
    'payload'?: CloudDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfCloudDTOAllOf1
 */
export interface RestResponseOfCloudDTOAllOf1 {
    /**
     * 
     * @type {UploadCloudResponseDTO}
     * @memberof RestResponseOfCloudDTOAllOf1
     */
    'job'?: UploadCloudResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfCopyDatasetResponseDTO
 */
export interface RestResponseOfCopyDatasetResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfCopyDatasetResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfCopyDatasetResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfCopyDatasetResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfCopyDatasetResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {CopyDatasetResponseDTO}
     * @memberof RestResponseOfCopyDatasetResponseDTO
     */
    'payload'?: CopyDatasetResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfCopyDatasetResponseDTOAllOf
 */
export interface RestResponseOfCopyDatasetResponseDTOAllOf {
    /**
     * 
     * @type {CopyDatasetResponseDTO}
     * @memberof RestResponseOfCopyDatasetResponseDTOAllOf
     */
    'payload'?: CopyDatasetResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfCountByScope
 */
export interface RestResponseOfCountByScope {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfCountByScope
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfCountByScope
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfCountByScope
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfCountByScope
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof RestResponseOfCountByScope
     */
    'payload'?: { [key: string]: number | undefined; };
}
/**
 * 
 * @export
 * @interface RestResponseOfCountByScopeAllOf
 */
export interface RestResponseOfCountByScopeAllOf {
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof RestResponseOfCountByScopeAllOf
     */
    'payload'?: { [key: string]: number | undefined; };
}
/**
 * 
 * @export
 * @interface RestResponseOfDataCleaningJobDTO
 */
export interface RestResponseOfDataCleaningJobDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDataCleaningJobDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDataCleaningJobDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfDataCleaningJobDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfDataCleaningJobDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {DataCleaningJobDTO}
     * @memberof RestResponseOfDataCleaningJobDTO
     */
    'payload'?: DataCleaningJobDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfDataCleaningJobDTOAllOf
 */
export interface RestResponseOfDataCleaningJobDTOAllOf {
    /**
     * 
     * @type {DataCleaningJobDTO}
     * @memberof RestResponseOfDataCleaningJobDTOAllOf
     */
    'payload'?: DataCleaningJobDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfDatasetAttributeDTO
 */
export interface RestResponseOfDatasetAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDatasetAttributeDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDatasetAttributeDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfDatasetAttributeDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfDatasetAttributeDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {DatasetAttributeDTO}
     * @memberof RestResponseOfDatasetAttributeDTO
     */
    'payload'?: DatasetAttributeDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfDatasetAttributeDTOAllOf
 */
export interface RestResponseOfDatasetAttributeDTOAllOf {
    /**
     * 
     * @type {DatasetAttributeDTO}
     * @memberof RestResponseOfDatasetAttributeDTOAllOf
     */
    'payload'?: DatasetAttributeDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfDatasetDTO
 */
export interface RestResponseOfDatasetDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDatasetDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDatasetDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfDatasetDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfDatasetDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {DatasetDTO}
     * @memberof RestResponseOfDatasetDTO
     */
    'payload'?: DatasetDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfDatasetDTOAllOf
 */
export interface RestResponseOfDatasetDTOAllOf {
    /**
     * 
     * @type {DatasetDTO}
     * @memberof RestResponseOfDatasetDTOAllOf
     */
    'payload'?: DatasetDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfDatasetType
 */
export interface RestResponseOfDatasetType {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDatasetType
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDatasetType
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfDatasetType
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfDatasetType
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<DatasetTypeResult>}
     * @memberof RestResponseOfDatasetType
     */
    'payload'?: Array<DatasetTypeResult>;
}
/**
 * 
 * @export
 * @interface RestResponseOfDatasetTypeAllOf
 */
export interface RestResponseOfDatasetTypeAllOf {
    /**
     * 
     * @type {Array<DatasetTypeResult>}
     * @memberof RestResponseOfDatasetTypeAllOf
     */
    'payload'?: Array<DatasetTypeResult>;
}
/**
 * 
 * @export
 * @interface RestResponseOfDatasetVersionDTO
 */
export interface RestResponseOfDatasetVersionDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDatasetVersionDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDatasetVersionDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfDatasetVersionDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfDatasetVersionDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {DatasetVersionDTO}
     * @memberof RestResponseOfDatasetVersionDTO
     */
    'payload'?: DatasetVersionDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfDatasetVersionDTOAllOf
 */
export interface RestResponseOfDatasetVersionDTOAllOf {
    /**
     * 
     * @type {DatasetVersionDTO}
     * @memberof RestResponseOfDatasetVersionDTOAllOf
     */
    'payload'?: DatasetVersionDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfDicomMetaDataDTO
 */
export interface RestResponseOfDicomMetaDataDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDicomMetaDataDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDicomMetaDataDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfDicomMetaDataDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfDicomMetaDataDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {DicomMetaDataDTO}
     * @memberof RestResponseOfDicomMetaDataDTO
     */
    'payload'?: DicomMetaDataDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfDicomMetaDataDTOAllOf
 */
export interface RestResponseOfDicomMetaDataDTOAllOf {
    /**
     * 
     * @type {DicomMetaDataDTO}
     * @memberof RestResponseOfDicomMetaDataDTOAllOf
     */
    'payload'?: DicomMetaDataDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfDicomServerResponseDTO
 */
export interface RestResponseOfDicomServerResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDicomServerResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDicomServerResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfDicomServerResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfDicomServerResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {DicomServerResponseDTO}
     * @memberof RestResponseOfDicomServerResponseDTO
     */
    'payload'?: DicomServerResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfDicomServerResponseDTOAllOf
 */
export interface RestResponseOfDicomServerResponseDTOAllOf {
    /**
     * 
     * @type {DicomServerResponseDTO}
     * @memberof RestResponseOfDicomServerResponseDTOAllOf
     */
    'payload'?: DicomServerResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfDuplicateDatasetResponseDTO
 */
export interface RestResponseOfDuplicateDatasetResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDuplicateDatasetResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfDuplicateDatasetResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfDuplicateDatasetResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfDuplicateDatasetResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {DuplicateDatasetResponseDTO}
     * @memberof RestResponseOfDuplicateDatasetResponseDTO
     */
    'payload'?: DuplicateDatasetResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfDuplicateDatasetResponseDTOAllOf
 */
export interface RestResponseOfDuplicateDatasetResponseDTOAllOf {
    /**
     * 
     * @type {DuplicateDatasetResponseDTO}
     * @memberof RestResponseOfDuplicateDatasetResponseDTOAllOf
     */
    'payload'?: DuplicateDatasetResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfFileInfoDTO
 */
export interface RestResponseOfFileInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfFileInfoDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfFileInfoDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfFileInfoDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfFileInfoDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {FileInfoDTO}
     * @memberof RestResponseOfFileInfoDTO
     */
    'payload'?: FileInfoDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfFileInfoDTOAllOf
 */
export interface RestResponseOfFileInfoDTOAllOf {
    /**
     * 
     * @type {FileInfoDTO}
     * @memberof RestResponseOfFileInfoDTOAllOf
     */
    'payload'?: FileInfoDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfFileInfoDatasetDTO
 */
export interface RestResponseOfFileInfoDatasetDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfFileInfoDatasetDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfFileInfoDatasetDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfFileInfoDatasetDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfFileInfoDatasetDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {FileInfoDatasetDTO}
     * @memberof RestResponseOfFileInfoDatasetDTO
     */
    'payload'?: FileInfoDatasetDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfFileInfoDatasetDTOAllOf
 */
export interface RestResponseOfFileInfoDatasetDTOAllOf {
    /**
     * 
     * @type {FileInfoDatasetDTO}
     * @memberof RestResponseOfFileInfoDatasetDTOAllOf
     */
    'payload'?: FileInfoDatasetDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfFileInfoDetailsDTO
 */
export interface RestResponseOfFileInfoDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfFileInfoDetailsDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfFileInfoDetailsDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfFileInfoDetailsDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfFileInfoDetailsDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {FileInfoDetailsDTO}
     * @memberof RestResponseOfFileInfoDetailsDTO
     */
    'payload'?: FileInfoDetailsDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfFileInfoDetailsDTOAllOf
 */
export interface RestResponseOfFileInfoDetailsDTOAllOf {
    /**
     * 
     * @type {FileInfoDetailsDTO}
     * @memberof RestResponseOfFileInfoDetailsDTOAllOf
     */
    'payload'?: FileInfoDetailsDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfFileInfoInitResponseDTO
 */
export interface RestResponseOfFileInfoInitResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfFileInfoInitResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfFileInfoInitResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfFileInfoInitResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfFileInfoInitResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {FileInfoInitResponseDTO}
     * @memberof RestResponseOfFileInfoInitResponseDTO
     */
    'payload'?: FileInfoInitResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfFileInfoInitResponseDTOAllOf
 */
export interface RestResponseOfFileInfoInitResponseDTOAllOf {
    /**
     * 
     * @type {FileInfoInitResponseDTO}
     * @memberof RestResponseOfFileInfoInitResponseDTOAllOf
     */
    'payload'?: FileInfoInitResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfAdditionalFileInfoDTO
 */
export interface RestResponseOfListOfAdditionalFileInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfAdditionalFileInfoDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfAdditionalFileInfoDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfAdditionalFileInfoDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfAdditionalFileInfoDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<AdditionalFileInfoDTO>}
     * @memberof RestResponseOfListOfAdditionalFileInfoDTO
     */
    'payload'?: Array<AdditionalFileInfoDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfAdditionalFileInfoDTOAllOf
 */
export interface RestResponseOfListOfAdditionalFileInfoDTOAllOf {
    /**
     * 
     * @type {Array<AdditionalFileInfoDTO>}
     * @memberof RestResponseOfListOfAdditionalFileInfoDTOAllOf
     */
    'payload'?: Array<AdditionalFileInfoDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfCloudDTO
 */
export interface RestResponseOfListOfCloudDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfCloudDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfCloudDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfCloudDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfCloudDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<CloudDTO>}
     * @memberof RestResponseOfListOfCloudDTO
     */
    'payload'?: Array<CloudDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfCloudDTOAllOf
 */
export interface RestResponseOfListOfCloudDTOAllOf {
    /**
     * 
     * @type {Array<CloudDTO>}
     * @memberof RestResponseOfListOfCloudDTOAllOf
     */
    'payload'?: Array<CloudDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfDataCleaningJobDTO
 */
export interface RestResponseOfListOfDataCleaningJobDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfDataCleaningJobDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfDataCleaningJobDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfDataCleaningJobDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfDataCleaningJobDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<DataCleaningJobDTO>}
     * @memberof RestResponseOfListOfDataCleaningJobDTO
     */
    'payload'?: Array<DataCleaningJobDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfDataCleaningJobDTOAllOf
 */
export interface RestResponseOfListOfDataCleaningJobDTOAllOf {
    /**
     * 
     * @type {Array<DataCleaningJobDTO>}
     * @memberof RestResponseOfListOfDataCleaningJobDTOAllOf
     */
    'payload'?: Array<DataCleaningJobDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfDatasetAttributeDTO
 */
export interface RestResponseOfListOfDatasetAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfDatasetAttributeDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfDatasetAttributeDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfDatasetAttributeDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfDatasetAttributeDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<DatasetAttributeDTO>}
     * @memberof RestResponseOfListOfDatasetAttributeDTO
     */
    'payload'?: Array<DatasetAttributeDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfDatasetAttributeDTOAllOf
 */
export interface RestResponseOfListOfDatasetAttributeDTOAllOf {
    /**
     * 
     * @type {Array<DatasetAttributeDTO>}
     * @memberof RestResponseOfListOfDatasetAttributeDTOAllOf
     */
    'payload'?: Array<DatasetAttributeDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfDatasetDTO
 */
export interface RestResponseOfListOfDatasetDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfDatasetDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfDatasetDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfDatasetDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfDatasetDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<DatasetDTO>}
     * @memberof RestResponseOfListOfDatasetDTO
     */
    'payload'?: Array<DatasetDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfDatasetDTOAllOf
 */
export interface RestResponseOfListOfDatasetDTOAllOf {
    /**
     * 
     * @type {Array<DatasetDTO>}
     * @memberof RestResponseOfListOfDatasetDTOAllOf
     */
    'payload'?: Array<DatasetDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfDatasetVersionDTO
 */
export interface RestResponseOfListOfDatasetVersionDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfDatasetVersionDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfDatasetVersionDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfDatasetVersionDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfDatasetVersionDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<DatasetVersionDTO>}
     * @memberof RestResponseOfListOfDatasetVersionDTO
     */
    'payload'?: Array<DatasetVersionDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfDatasetVersionDTOAllOf
 */
export interface RestResponseOfListOfDatasetVersionDTOAllOf {
    /**
     * 
     * @type {Array<DatasetVersionDTO>}
     * @memberof RestResponseOfListOfDatasetVersionDTOAllOf
     */
    'payload'?: Array<DatasetVersionDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfDicomMetaDataDTO
 */
export interface RestResponseOfListOfDicomMetaDataDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfDicomMetaDataDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfDicomMetaDataDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfDicomMetaDataDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfDicomMetaDataDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<DicomMetaDataDTO>}
     * @memberof RestResponseOfListOfDicomMetaDataDTO
     */
    'payload'?: Array<DicomMetaDataDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfDicomMetaDataDTOAllOf
 */
export interface RestResponseOfListOfDicomMetaDataDTOAllOf {
    /**
     * 
     * @type {Array<DicomMetaDataDTO>}
     * @memberof RestResponseOfListOfDicomMetaDataDTOAllOf
     */
    'payload'?: Array<DicomMetaDataDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfDicomServerResponseDTO
 */
export interface RestResponseOfListOfDicomServerResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfDicomServerResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfDicomServerResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfDicomServerResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfDicomServerResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<DicomServerResponseDTO>}
     * @memberof RestResponseOfListOfDicomServerResponseDTO
     */
    'payload'?: Array<DicomServerResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfDicomServerResponseDTOAllOf
 */
export interface RestResponseOfListOfDicomServerResponseDTOAllOf {
    /**
     * 
     * @type {Array<DicomServerResponseDTO>}
     * @memberof RestResponseOfListOfDicomServerResponseDTOAllOf
     */
    'payload'?: Array<DicomServerResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfFileInfoDTO
 */
export interface RestResponseOfListOfFileInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfFileInfoDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfFileInfoDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfFileInfoDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfFileInfoDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<FileInfoDTO>}
     * @memberof RestResponseOfListOfFileInfoDTO
     */
    'payload'?: Array<FileInfoDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfFileInfoDTOAllOf
 */
export interface RestResponseOfListOfFileInfoDTOAllOf {
    /**
     * 
     * @type {Array<FileInfoDTO>}
     * @memberof RestResponseOfListOfFileInfoDTOAllOf
     */
    'payload'?: Array<FileInfoDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfFileInfoDatasetDTO
 */
export interface RestResponseOfListOfFileInfoDatasetDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfFileInfoDatasetDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfFileInfoDatasetDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfFileInfoDatasetDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfFileInfoDatasetDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<FileInfoDatasetDTO>}
     * @memberof RestResponseOfListOfFileInfoDatasetDTO
     */
    'payload'?: Array<FileInfoDatasetDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfFileInfoDatasetDTOAllOf
 */
export interface RestResponseOfListOfFileInfoDatasetDTOAllOf {
    /**
     * 
     * @type {Array<FileInfoDatasetDTO>}
     * @memberof RestResponseOfListOfFileInfoDatasetDTOAllOf
     */
    'payload'?: Array<FileInfoDatasetDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfMedicalMetadataResponseDTO
 */
export interface RestResponseOfListOfMedicalMetadataResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfMedicalMetadataResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfMedicalMetadataResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfMedicalMetadataResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfMedicalMetadataResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof RestResponseOfListOfMedicalMetadataResponseDTO
     */
    'payload'?: Array<object>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfMedicalMetadataResponseDTOAllOf
 */
export interface RestResponseOfListOfMedicalMetadataResponseDTOAllOf {
    /**
     * 
     * @type {Array<object>}
     * @memberof RestResponseOfListOfMedicalMetadataResponseDTOAllOf
     */
    'payload'?: Array<object>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfMedicalSearchResponseDTO
 */
export interface RestResponseOfListOfMedicalSearchResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfMedicalSearchResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfMedicalSearchResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfMedicalSearchResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfMedicalSearchResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<MedicalSearchResponseDTO>}
     * @memberof RestResponseOfListOfMedicalSearchResponseDTO
     */
    'payload'?: Array<MedicalSearchResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfMedicalSearchResponseDTOAllOf
 */
export interface RestResponseOfListOfMedicalSearchResponseDTOAllOf {
    /**
     * 
     * @type {Array<MedicalSearchResponseDTO>}
     * @memberof RestResponseOfListOfMedicalSearchResponseDTOAllOf
     */
    'payload'?: Array<MedicalSearchResponseDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfMetadataKeyDTO
 */
export interface RestResponseOfListOfMetadataKeyDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfMetadataKeyDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfMetadataKeyDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfMetadataKeyDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfMetadataKeyDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<MetadataKeyDTO>}
     * @memberof RestResponseOfListOfMetadataKeyDTO
     */
    'payload'?: Array<MetadataKeyDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfMetadataKeyDTOAllOf
 */
export interface RestResponseOfListOfMetadataKeyDTOAllOf {
    /**
     * 
     * @type {Array<MetadataKeyDTO>}
     * @memberof RestResponseOfListOfMetadataKeyDTOAllOf
     */
    'payload'?: Array<MetadataKeyDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfMetadataValueDTO
 */
export interface RestResponseOfListOfMetadataValueDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfMetadataValueDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfMetadataValueDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfMetadataValueDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfMetadataValueDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<MetadataValueDTO>}
     * @memberof RestResponseOfListOfMetadataValueDTO
     */
    'payload'?: Array<MetadataValueDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfMetadataValueDTOAllOf
 */
export interface RestResponseOfListOfMetadataValueDTOAllOf {
    /**
     * 
     * @type {Array<MetadataValueDTO>}
     * @memberof RestResponseOfListOfMetadataValueDTOAllOf
     */
    'payload'?: Array<MetadataValueDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfScopeDTO
 */
export interface RestResponseOfListOfScopeDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfScopeDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfScopeDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfScopeDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfScopeDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<ScopeDTO>}
     * @memberof RestResponseOfListOfScopeDTO
     */
    'payload'?: Array<ScopeDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfScopeDTOAllOf
 */
export interface RestResponseOfListOfScopeDTOAllOf {
    /**
     * 
     * @type {Array<ScopeDTO>}
     * @memberof RestResponseOfListOfScopeDTOAllOf
     */
    'payload'?: Array<ScopeDTO>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfString
 */
export interface RestResponseOfListOfString {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfString
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfListOfString
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfListOfString
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfListOfString
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RestResponseOfListOfString
     */
    'payload'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RestResponseOfListOfStringAllOf
 */
export interface RestResponseOfListOfStringAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof RestResponseOfListOfStringAllOf
     */
    'payload'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RestResponseOfLong
 */
export interface RestResponseOfLong {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfLong
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfLong
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfLong
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfLong
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RestResponseOfLong
     */
    'payload'?: number;
}
/**
 * 
 * @export
 * @interface RestResponseOfLongAllOf
 */
export interface RestResponseOfLongAllOf {
    /**
     * 
     * @type {number}
     * @memberof RestResponseOfLongAllOf
     */
    'payload'?: number;
}
/**
 * 
 * @export
 * @interface RestResponseOfMergeDatasetResponseDTO
 */
export interface RestResponseOfMergeDatasetResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfMergeDatasetResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfMergeDatasetResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfMergeDatasetResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfMergeDatasetResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {MergeDatasetResponseDTO}
     * @memberof RestResponseOfMergeDatasetResponseDTO
     */
    'payload'?: MergeDatasetResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfMergeDatasetResponseDTOAllOf
 */
export interface RestResponseOfMergeDatasetResponseDTOAllOf {
    /**
     * 
     * @type {MergeDatasetResponseDTO}
     * @memberof RestResponseOfMergeDatasetResponseDTOAllOf
     */
    'payload'?: MergeDatasetResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfMetadataKeyDTO
 */
export interface RestResponseOfMetadataKeyDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfMetadataKeyDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfMetadataKeyDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfMetadataKeyDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfMetadataKeyDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {MetadataKeyDTO}
     * @memberof RestResponseOfMetadataKeyDTO
     */
    'payload'?: MetadataKeyDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfMetadataKeyDTOAllOf
 */
export interface RestResponseOfMetadataKeyDTOAllOf {
    /**
     * 
     * @type {MetadataKeyDTO}
     * @memberof RestResponseOfMetadataKeyDTOAllOf
     */
    'payload'?: MetadataKeyDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfMetadataValueDTO
 */
export interface RestResponseOfMetadataValueDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfMetadataValueDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfMetadataValueDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfMetadataValueDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfMetadataValueDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {MetadataValueDTO}
     * @memberof RestResponseOfMetadataValueDTO
     */
    'payload'?: MetadataValueDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfMetadataValueDTOAllOf
 */
export interface RestResponseOfMetadataValueDTOAllOf {
    /**
     * 
     * @type {MetadataValueDTO}
     * @memberof RestResponseOfMetadataValueDTOAllOf
     */
    'payload'?: MetadataValueDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfObject
 */
export interface RestResponseOfObject {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfObject
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfObject
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfObject
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfObject
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof RestResponseOfObject
     */
    'payload'?: object;
}
/**
 * 
 * @export
 * @interface RestResponseOfObjectAllOf
 */
export interface RestResponseOfObjectAllOf {
    /**
     * 
     * @type {object}
     * @memberof RestResponseOfObjectAllOf
     */
    'payload'?: object;
}
/**
 * 
 * @export
 * @interface RestResponseOfPublishResult
 */
export interface RestResponseOfPublishResult {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfPublishResult
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfPublishResult
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfPublishResult
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfPublishResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {PublishResult}
     * @memberof RestResponseOfPublishResult
     */
    'payload'?: PublishResult;
}
/**
 * 
 * @export
 * @interface RestResponseOfPublishResultAllOf
 */
export interface RestResponseOfPublishResultAllOf {
    /**
     * 
     * @type {PublishResult}
     * @memberof RestResponseOfPublishResultAllOf
     */
    'payload'?: PublishResult;
}
/**
 * 
 * @export
 * @interface RestResponseOfScopeDTO
 */
export interface RestResponseOfScopeDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfScopeDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfScopeDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfScopeDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfScopeDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {ScopeDTO}
     * @memberof RestResponseOfScopeDTO
     */
    'payload'?: ScopeDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfScopeDTOAllOf
 */
export interface RestResponseOfScopeDTOAllOf {
    /**
     * 
     * @type {ScopeDTO}
     * @memberof RestResponseOfScopeDTOAllOf
     */
    'payload'?: ScopeDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfSseEmitter
 */
export interface RestResponseOfSseEmitter {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfSseEmitter
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfSseEmitter
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfSseEmitter
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfSseEmitter
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof RestResponseOfSseEmitter
     */
    'payload'?: object;
}
/**
 * 
 * @export
 * @interface RestResponseOfSseEmitterAllOf
 */
export interface RestResponseOfSseEmitterAllOf {
    /**
     * 
     * @type {object}
     * @memberof RestResponseOfSseEmitterAllOf
     */
    'payload'?: object;
}
/**
 * 
 * @export
 * @interface RestResponseOfUploadArchiveResponseDTO
 */
export interface RestResponseOfUploadArchiveResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfUploadArchiveResponseDTO
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestResponseOfUploadArchiveResponseDTO
     */
    'errorCode'?: string;
    /**
     * 
     * @type {Pagination}
     * @memberof RestResponseOfUploadArchiveResponseDTO
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {boolean}
     * @memberof RestResponseOfUploadArchiveResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {UploadArchiveResponseDTO}
     * @memberof RestResponseOfUploadArchiveResponseDTO
     */
    'payload'?: UploadArchiveResponseDTO;
}
/**
 * 
 * @export
 * @interface RestResponseOfUploadArchiveResponseDTOAllOf
 */
export interface RestResponseOfUploadArchiveResponseDTOAllOf {
    /**
     * 
     * @type {UploadArchiveResponseDTO}
     * @memberof RestResponseOfUploadArchiveResponseDTOAllOf
     */
    'payload'?: UploadArchiveResponseDTO;
}
/**
 * 
 * @export
 * @interface SasRequestDTO
 */
export interface SasRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof SasRequestDTO
     */
    'duration'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SasRequestDTO
     */
    'workspaceIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ScopeDTO
 */
export interface ScopeDTO {
    /**
     * 
     * @type {number}
     * @memberof ScopeDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScopeDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScopeDTO
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface StorageResource
 */
export interface StorageResource {
    /**
     * 
     * @type {string}
     * @memberof StorageResource
     */
    'resourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageResource
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageResource
     */
    'downloadUrl'?: string;
    /**
     * 
     * @type {StorageResourceStatus}
     * @memberof StorageResource
     */
    'status'?: StorageResourceStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const StorageResourceStatus = {
    Initial: 'Initial',
    Ok: 'OK',
    Ng: 'NG'
} as const;

export type StorageResourceStatus = typeof StorageResourceStatus[keyof typeof StorageResourceStatus];


/**
 * 
 * @export
 * @interface TagDTO
 */
export interface TagDTO {
    /**
     * 
     * @type {number}
     * @memberof TagDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagDTO
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface UploadArchiveMedicalRequestDTO
 */
export interface UploadArchiveMedicalRequestDTO {
    /**
     * id of DicomServer
     * @type {string}
     * @memberof UploadArchiveMedicalRequestDTO
     */
    'dicomServerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadArchiveMedicalRequestDTO
     */
    'storageResourceId'?: string;
}
/**
 * 
 * @export
 * @interface UploadArchiveRequestDTO
 */
export interface UploadArchiveRequestDTO {
    /**
     * id of DicomServer
     * @type {string}
     * @memberof UploadArchiveRequestDTO
     */
    'dicomServerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadArchiveRequestDTO
     */
    'storageResourceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof UploadArchiveRequestDTO
     */
    'datasetId'?: number;
    /**
     * 
     * @type {Array<MetadataRequestDTO>}
     * @memberof UploadArchiveRequestDTO
     */
    'metadata'?: Array<MetadataRequestDTO>;
}
/**
 * 
 * @export
 * @interface UploadArchiveResponseDTO
 */
export interface UploadArchiveResponseDTO {
    /**
     * 
     * @type {boolean}
     * @memberof UploadArchiveResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UploadArchiveResponseDTO
     */
    'jobId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadArchiveResponseDTO
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface UploadCloudResponseDTO
 */
export interface UploadCloudResponseDTO {
    /**
     * 
     * @type {boolean}
     * @memberof UploadCloudResponseDTO
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UploadCloudResponseDTO
     */
    'jobId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadCloudResponseDTO
     */
    'status'?: string;
}

/**
 * ActuatorApi - axios parameter creator
 * @export
 */
export const ActuatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get health status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get liveness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthLivenessGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/health/liveness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get readiness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthReadinessGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/health/readiness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActuatorApi - functional programming interface
 * @export
 */
export const ActuatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActuatorApiAxiosParamCreator(configuration)
    return {
        /**
         * Get health status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementHealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementHealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get liveness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementHealthLivenessGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementHealthLivenessGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get readiness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementHealthReadinessGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementHealthReadinessGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActuatorApi - factory interface
 * @export
 */
export const ActuatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActuatorApiFp(configuration)
    return {
        /**
         * Get health status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthGet(options?: any): AxiosPromise<object> {
            return localVarFp.managementHealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get liveness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthLivenessGet(options?: any): AxiosPromise<object> {
            return localVarFp.managementHealthLivenessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get readiness status of backend service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementHealthReadinessGet(options?: any): AxiosPromise<object> {
            return localVarFp.managementHealthReadinessGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActuatorApi - object-oriented interface
 * @export
 * @class ActuatorApi
 * @extends {BaseAPI}
 */
export class ActuatorApi extends BaseAPI {
    /**
     * Get health status of backend service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public managementHealthGet(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).managementHealthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get liveness status of backend service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public managementHealthLivenessGet(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).managementHealthLivenessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get readiness status of backend service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public managementHealthReadinessGet(options?: AxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).managementHealthReadinessGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdditionalFileInfoResourceApi - axios parameter creator
 * @export
 */
export const AdditionalFileInfoResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createAdditionalFileInfo
         * @param {AdditionalFileInfoDTO} [additionalFileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdditionalFileInfoUsingPOST: async (additionalFileInfoDTO?: AdditionalFileInfoDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/additional-file-infos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(additionalFileInfoDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAdditionalFileInfo
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdditionalFileInfoUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdditionalFileInfoUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/additional-file-infos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAdditionalFileInfoFromFile
         * @param {number} fileId fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalFileInfoFromFileUsingGET: async (fileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getAdditionalFileInfoFromFileUsingGET', 'fileId', fileId)
            const localVarPath = `/api/v1/additional-file-infos/from-file/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAdditionalFileInfo
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalFileInfoUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdditionalFileInfoUsingGET', 'id', id)
            const localVarPath = `/api/v1/additional-file-infos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAdditionalFileInfosFromFilesUsingGET
         * @param {Array<number>} fileIds fileIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalFileInfosFromFilesUsingGET: async (fileIds: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileIds' is not null or undefined
            assertParamExists('getAdditionalFileInfosFromFilesUsingGET', 'fileIds', fileIds)
            const localVarPath = `/api/v1/additional-file-infos/from-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fileIds) {
                localVarQueryParameter['fileIds'] = fileIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllAdditionalFileInfos
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdditionalFileInfosUsingGET: async (pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/additional-file-infos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateAdditionalFileInfo
         * @param {number} id id
         * @param {AdditionalFileInfoDTO} [additionalFileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateAdditionalFileInfoUsingPATCH: async (id: number, additionalFileInfoDTO?: AdditionalFileInfoDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateAdditionalFileInfoUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/additional-file-infos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(additionalFileInfoDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateAdditionalFileInfo
         * @param {number} id id
         * @param {AdditionalFileInfoDTO} [additionalFileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdditionalFileInfoUsingPUT: async (id: number, additionalFileInfoDTO?: AdditionalFileInfoDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdditionalFileInfoUsingPUT', 'id', id)
            const localVarPath = `/api/v1/additional-file-infos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(additionalFileInfoDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdditionalFileInfoResourceApi - functional programming interface
 * @export
 */
export const AdditionalFileInfoResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdditionalFileInfoResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createAdditionalFileInfo
         * @param {AdditionalFileInfoDTO} [additionalFileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdditionalFileInfoUsingPOST(additionalFileInfoDTO?: AdditionalFileInfoDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfAdditionalFileInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdditionalFileInfoUsingPOST(additionalFileInfoDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteAdditionalFileInfo
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdditionalFileInfoUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdditionalFileInfoUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAdditionalFileInfoFromFile
         * @param {number} fileId fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdditionalFileInfoFromFileUsingGET(fileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfAdditionalFileInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdditionalFileInfoFromFileUsingGET(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAdditionalFileInfo
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdditionalFileInfoUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfAdditionalFileInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdditionalFileInfoUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAdditionalFileInfosFromFilesUsingGET
         * @param {Array<number>} fileIds fileIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdditionalFileInfosFromFilesUsingGET(fileIds: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfAdditionalFileInfoDTOS>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdditionalFileInfosFromFilesUsingGET(fileIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllAdditionalFileInfos
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAdditionalFileInfosUsingGET(pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfAdditionalFileInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAdditionalFileInfosUsingGET(pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateAdditionalFileInfo
         * @param {number} id id
         * @param {AdditionalFileInfoDTO} [additionalFileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateAdditionalFileInfoUsingPATCH(id: number, additionalFileInfoDTO?: AdditionalFileInfoDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfAdditionalFileInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateAdditionalFileInfoUsingPATCH(id, additionalFileInfoDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateAdditionalFileInfo
         * @param {number} id id
         * @param {AdditionalFileInfoDTO} [additionalFileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdditionalFileInfoUsingPUT(id: number, additionalFileInfoDTO?: AdditionalFileInfoDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfAdditionalFileInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdditionalFileInfoUsingPUT(id, additionalFileInfoDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdditionalFileInfoResourceApi - factory interface
 * @export
 */
export const AdditionalFileInfoResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdditionalFileInfoResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary createAdditionalFileInfo
         * @param {AdditionalFileInfoDTO} [additionalFileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdditionalFileInfoUsingPOST(additionalFileInfoDTO?: AdditionalFileInfoDTO, options?: any): AxiosPromise<RestResponseOfAdditionalFileInfoDTO> {
            return localVarFp.createAdditionalFileInfoUsingPOST(additionalFileInfoDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteAdditionalFileInfo
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdditionalFileInfoUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteAdditionalFileInfoUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAdditionalFileInfoFromFile
         * @param {number} fileId fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalFileInfoFromFileUsingGET(fileId: number, options?: any): AxiosPromise<RestResponseOfAdditionalFileInfoDTO> {
            return localVarFp.getAdditionalFileInfoFromFileUsingGET(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAdditionalFileInfo
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalFileInfoUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfAdditionalFileInfoDTO> {
            return localVarFp.getAdditionalFileInfoUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAdditionalFileInfosFromFilesUsingGET
         * @param {Array<number>} fileIds fileIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalFileInfosFromFilesUsingGET(fileIds: Array<number>, options?: any): AxiosPromise<RestResponseOfAdditionalFileInfoDTOS> {
            return localVarFp.getAdditionalFileInfosFromFilesUsingGET(fileIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllAdditionalFileInfos
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdditionalFileInfosUsingGET(pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfAdditionalFileInfoDTO> {
            return localVarFp.getAllAdditionalFileInfosUsingGET(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateAdditionalFileInfo
         * @param {number} id id
         * @param {AdditionalFileInfoDTO} [additionalFileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateAdditionalFileInfoUsingPATCH(id: number, additionalFileInfoDTO?: AdditionalFileInfoDTO, options?: any): AxiosPromise<RestResponseOfAdditionalFileInfoDTO> {
            return localVarFp.partialUpdateAdditionalFileInfoUsingPATCH(id, additionalFileInfoDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateAdditionalFileInfo
         * @param {number} id id
         * @param {AdditionalFileInfoDTO} [additionalFileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdditionalFileInfoUsingPUT(id: number, additionalFileInfoDTO?: AdditionalFileInfoDTO, options?: any): AxiosPromise<RestResponseOfAdditionalFileInfoDTO> {
            return localVarFp.updateAdditionalFileInfoUsingPUT(id, additionalFileInfoDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAdditionalFileInfoUsingPOST operation in AdditionalFileInfoResourceApi.
 * @export
 * @interface AdditionalFileInfoResourceApiCreateAdditionalFileInfoUsingPOSTRequest
 */
export interface AdditionalFileInfoResourceApiCreateAdditionalFileInfoUsingPOSTRequest {
    /**
     * 
     * @type {AdditionalFileInfoDTO}
     * @memberof AdditionalFileInfoResourceApiCreateAdditionalFileInfoUsingPOST
     */
    readonly additionalFileInfoDTO?: AdditionalFileInfoDTO
}

/**
 * Request parameters for deleteAdditionalFileInfoUsingDELETE operation in AdditionalFileInfoResourceApi.
 * @export
 * @interface AdditionalFileInfoResourceApiDeleteAdditionalFileInfoUsingDELETERequest
 */
export interface AdditionalFileInfoResourceApiDeleteAdditionalFileInfoUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof AdditionalFileInfoResourceApiDeleteAdditionalFileInfoUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for getAdditionalFileInfoFromFileUsingGET operation in AdditionalFileInfoResourceApi.
 * @export
 * @interface AdditionalFileInfoResourceApiGetAdditionalFileInfoFromFileUsingGETRequest
 */
export interface AdditionalFileInfoResourceApiGetAdditionalFileInfoFromFileUsingGETRequest {
    /**
     * fileId
     * @type {number}
     * @memberof AdditionalFileInfoResourceApiGetAdditionalFileInfoFromFileUsingGET
     */
    readonly fileId: number
}

/**
 * Request parameters for getAdditionalFileInfoUsingGET operation in AdditionalFileInfoResourceApi.
 * @export
 * @interface AdditionalFileInfoResourceApiGetAdditionalFileInfoUsingGETRequest
 */
export interface AdditionalFileInfoResourceApiGetAdditionalFileInfoUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof AdditionalFileInfoResourceApiGetAdditionalFileInfoUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for getAdditionalFileInfosFromFilesUsingGET operation in AdditionalFileInfoResourceApi.
 * @export
 * @interface AdditionalFileInfoResourceApiGetAdditionalFileInfosFromFilesUsingGETRequest
 */
export interface AdditionalFileInfoResourceApiGetAdditionalFileInfosFromFilesUsingGETRequest {
    /**
     * fileIds
     * @type {Array<number>}
     * @memberof AdditionalFileInfoResourceApiGetAdditionalFileInfosFromFilesUsingGET
     */
    readonly fileIds: Array<number>
}

/**
 * Request parameters for getAllAdditionalFileInfosUsingGET operation in AdditionalFileInfoResourceApi.
 * @export
 * @interface AdditionalFileInfoResourceApiGetAllAdditionalFileInfosUsingGETRequest
 */
export interface AdditionalFileInfoResourceApiGetAllAdditionalFileInfosUsingGETRequest {
    /**
     * pageable
     * @type {Pageable}
     * @memberof AdditionalFileInfoResourceApiGetAllAdditionalFileInfosUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for partialUpdateAdditionalFileInfoUsingPATCH operation in AdditionalFileInfoResourceApi.
 * @export
 * @interface AdditionalFileInfoResourceApiPartialUpdateAdditionalFileInfoUsingPATCHRequest
 */
export interface AdditionalFileInfoResourceApiPartialUpdateAdditionalFileInfoUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof AdditionalFileInfoResourceApiPartialUpdateAdditionalFileInfoUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {AdditionalFileInfoDTO}
     * @memberof AdditionalFileInfoResourceApiPartialUpdateAdditionalFileInfoUsingPATCH
     */
    readonly additionalFileInfoDTO?: AdditionalFileInfoDTO
}

/**
 * Request parameters for updateAdditionalFileInfoUsingPUT operation in AdditionalFileInfoResourceApi.
 * @export
 * @interface AdditionalFileInfoResourceApiUpdateAdditionalFileInfoUsingPUTRequest
 */
export interface AdditionalFileInfoResourceApiUpdateAdditionalFileInfoUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof AdditionalFileInfoResourceApiUpdateAdditionalFileInfoUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {AdditionalFileInfoDTO}
     * @memberof AdditionalFileInfoResourceApiUpdateAdditionalFileInfoUsingPUT
     */
    readonly additionalFileInfoDTO?: AdditionalFileInfoDTO
}

/**
 * AdditionalFileInfoResourceApi - object-oriented interface
 * @export
 * @class AdditionalFileInfoResourceApi
 * @extends {BaseAPI}
 */
export class AdditionalFileInfoResourceApi extends BaseAPI {
    /**
     * 
     * @summary createAdditionalFileInfo
     * @param {AdditionalFileInfoResourceApiCreateAdditionalFileInfoUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalFileInfoResourceApi
     */
    public createAdditionalFileInfoUsingPOST(requestParameters: AdditionalFileInfoResourceApiCreateAdditionalFileInfoUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return AdditionalFileInfoResourceApiFp(this.configuration).createAdditionalFileInfoUsingPOST(requestParameters.additionalFileInfoDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteAdditionalFileInfo
     * @param {AdditionalFileInfoResourceApiDeleteAdditionalFileInfoUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalFileInfoResourceApi
     */
    public deleteAdditionalFileInfoUsingDELETE(requestParameters: AdditionalFileInfoResourceApiDeleteAdditionalFileInfoUsingDELETERequest, options?: AxiosRequestConfig) {
        return AdditionalFileInfoResourceApiFp(this.configuration).deleteAdditionalFileInfoUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAdditionalFileInfoFromFile
     * @param {AdditionalFileInfoResourceApiGetAdditionalFileInfoFromFileUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalFileInfoResourceApi
     */
    public getAdditionalFileInfoFromFileUsingGET(requestParameters: AdditionalFileInfoResourceApiGetAdditionalFileInfoFromFileUsingGETRequest, options?: AxiosRequestConfig) {
        return AdditionalFileInfoResourceApiFp(this.configuration).getAdditionalFileInfoFromFileUsingGET(requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAdditionalFileInfo
     * @param {AdditionalFileInfoResourceApiGetAdditionalFileInfoUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalFileInfoResourceApi
     */
    public getAdditionalFileInfoUsingGET(requestParameters: AdditionalFileInfoResourceApiGetAdditionalFileInfoUsingGETRequest, options?: AxiosRequestConfig) {
        return AdditionalFileInfoResourceApiFp(this.configuration).getAdditionalFileInfoUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAdditionalFileInfosFromFilesUsingGET
     * @param {AdditionalFileInfoResourceApiGetAdditionalFileInfosFromFilesUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalFileInfoResourceApi
     */
    public getAdditionalFileInfosFromFilesUsingGET(requestParameters: AdditionalFileInfoResourceApiGetAdditionalFileInfosFromFilesUsingGETRequest, options?: AxiosRequestConfig) {
        return AdditionalFileInfoResourceApiFp(this.configuration).getAdditionalFileInfosFromFilesUsingGET(requestParameters.fileIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllAdditionalFileInfos
     * @param {AdditionalFileInfoResourceApiGetAllAdditionalFileInfosUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalFileInfoResourceApi
     */
    public getAllAdditionalFileInfosUsingGET(requestParameters: AdditionalFileInfoResourceApiGetAllAdditionalFileInfosUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return AdditionalFileInfoResourceApiFp(this.configuration).getAllAdditionalFileInfosUsingGET(requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateAdditionalFileInfo
     * @param {AdditionalFileInfoResourceApiPartialUpdateAdditionalFileInfoUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalFileInfoResourceApi
     */
    public partialUpdateAdditionalFileInfoUsingPATCH(requestParameters: AdditionalFileInfoResourceApiPartialUpdateAdditionalFileInfoUsingPATCHRequest, options?: AxiosRequestConfig) {
        return AdditionalFileInfoResourceApiFp(this.configuration).partialUpdateAdditionalFileInfoUsingPATCH(requestParameters.id, requestParameters.additionalFileInfoDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateAdditionalFileInfo
     * @param {AdditionalFileInfoResourceApiUpdateAdditionalFileInfoUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalFileInfoResourceApi
     */
    public updateAdditionalFileInfoUsingPUT(requestParameters: AdditionalFileInfoResourceApiUpdateAdditionalFileInfoUsingPUTRequest, options?: AxiosRequestConfig) {
        return AdditionalFileInfoResourceApiFp(this.configuration).updateAdditionalFileInfoUsingPUT(requestParameters.id, requestParameters.additionalFileInfoDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CloudResourceApi - axios parameter creator
 * @export
 */
export const CloudResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary countClouds
         * @param {CloudCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countCloudsUsingGET: async (criteria?: CloudCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clouds/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CloudRequestDTO} [cloudRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCloudUsingPOST: async (cloudRequestDTO?: CloudRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clouds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cloudRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllClouds
         * @param {CloudCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCloudsUsingGET: async (criteria?: CloudCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clouds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCloud
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCloudUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCloudUsingGET', 'id', id)
            const localVarPath = `/api/v1/clouds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CloudResourceApi - functional programming interface
 * @export
 */
export const CloudResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CloudResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary countClouds
         * @param {CloudCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countCloudsUsingGET(criteria?: CloudCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfLong>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countCloudsUsingGET(criteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CloudRequestDTO} [cloudRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCloudUsingPOST(cloudRequestDTO?: CloudRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfCloudDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCloudUsingPOST(cloudRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllClouds
         * @param {CloudCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCloudsUsingGET(criteria?: CloudCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfCloudDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCloudsUsingGET(criteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getCloud
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCloudUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfCloudDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCloudUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CloudResourceApi - factory interface
 * @export
 */
export const CloudResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CloudResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary countClouds
         * @param {CloudCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countCloudsUsingGET(criteria?: CloudCriteria, options?: any): AxiosPromise<RestResponseOfLong> {
            return localVarFp.countCloudsUsingGET(criteria, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CloudRequestDTO} [cloudRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCloudUsingPOST(cloudRequestDTO?: CloudRequestDTO, options?: any): AxiosPromise<RestResponseOfCloudDTO> {
            return localVarFp.createCloudUsingPOST(cloudRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllClouds
         * @param {CloudCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCloudsUsingGET(criteria?: CloudCriteria, options?: any): AxiosPromise<RestResponseOfListOfCloudDTO> {
            return localVarFp.getAllCloudsUsingGET(criteria, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getCloud
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCloudUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfCloudDTO> {
            return localVarFp.getCloudUsingGET(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countCloudsUsingGET operation in CloudResourceApi.
 * @export
 * @interface CloudResourceApiCountCloudsUsingGETRequest
 */
export interface CloudResourceApiCountCloudsUsingGETRequest {
    /**
     * criteria
     * @type {CloudCriteria}
     * @memberof CloudResourceApiCountCloudsUsingGET
     */
    readonly criteria?: CloudCriteria
}

/**
 * Request parameters for createCloudUsingPOST operation in CloudResourceApi.
 * @export
 * @interface CloudResourceApiCreateCloudUsingPOSTRequest
 */
export interface CloudResourceApiCreateCloudUsingPOSTRequest {
    /**
     * 
     * @type {CloudRequestDTO}
     * @memberof CloudResourceApiCreateCloudUsingPOST
     */
    readonly cloudRequestDTO?: CloudRequestDTO
}

/**
 * Request parameters for getAllCloudsUsingGET operation in CloudResourceApi.
 * @export
 * @interface CloudResourceApiGetAllCloudsUsingGETRequest
 */
export interface CloudResourceApiGetAllCloudsUsingGETRequest {
    /**
     * criteria
     * @type {CloudCriteria}
     * @memberof CloudResourceApiGetAllCloudsUsingGET
     */
    readonly criteria?: CloudCriteria
}

/**
 * Request parameters for getCloudUsingGET operation in CloudResourceApi.
 * @export
 * @interface CloudResourceApiGetCloudUsingGETRequest
 */
export interface CloudResourceApiGetCloudUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof CloudResourceApiGetCloudUsingGET
     */
    readonly id: number
}

/**
 * CloudResourceApi - object-oriented interface
 * @export
 * @class CloudResourceApi
 * @extends {BaseAPI}
 */
export class CloudResourceApi extends BaseAPI {
    /**
     * 
     * @summary countClouds
     * @param {CloudResourceApiCountCloudsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CloudResourceApi
     */
    public countCloudsUsingGET(requestParameters: CloudResourceApiCountCloudsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return CloudResourceApiFp(this.configuration).countCloudsUsingGET(requestParameters.criteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CloudResourceApiCreateCloudUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CloudResourceApi
     */
    public createCloudUsingPOST(requestParameters: CloudResourceApiCreateCloudUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return CloudResourceApiFp(this.configuration).createCloudUsingPOST(requestParameters.cloudRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllClouds
     * @param {CloudResourceApiGetAllCloudsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CloudResourceApi
     */
    public getAllCloudsUsingGET(requestParameters: CloudResourceApiGetAllCloudsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return CloudResourceApiFp(this.configuration).getAllCloudsUsingGET(requestParameters.criteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getCloud
     * @param {CloudResourceApiGetCloudUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CloudResourceApi
     */
    public getCloudUsingGET(requestParameters: CloudResourceApiGetCloudUsingGETRequest, options?: AxiosRequestConfig) {
        return CloudResourceApiFp(this.configuration).getCloudUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataCleaningResourceApi - axios parameter creator
 * @export
 */
export const DataCleaningResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createDataCleaningJob
         * @param {DataCleaningJobRequestDTO} [dataCleaningJobRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataCleaningJobUsingPOST: async (dataCleaningJobRequestDTO?: DataCleaningJobRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/data-cleaning-job`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataCleaningJobRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllDataCleaningJobs
         * @param {DataCleaningJobCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDataCleaningJobsUsingGET: async (criteria?: DataCleaningJobCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/data-cleaning-job`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDataCleanerTypes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataCleanerTypesUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/data-cleaner-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDataCleaningJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataCleaningJobUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDataCleaningJobUsingGET', 'id', id)
            const localVarPath = `/api/v1/data-cleaning-job/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataCleaningResourceApi - functional programming interface
 * @export
 */
export const DataCleaningResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataCleaningResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createDataCleaningJob
         * @param {DataCleaningJobRequestDTO} [dataCleaningJobRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDataCleaningJobUsingPOST(dataCleaningJobRequestDTO?: DataCleaningJobRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDataCleaningJobDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDataCleaningJobUsingPOST(dataCleaningJobRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllDataCleaningJobs
         * @param {DataCleaningJobCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDataCleaningJobsUsingGET(criteria?: DataCleaningJobCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfDataCleaningJobDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDataCleaningJobsUsingGET(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getDataCleanerTypes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataCleanerTypesUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataCleanerTypesUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getDataCleaningJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataCleaningJobUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDataCleaningJobDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataCleaningJobUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataCleaningResourceApi - factory interface
 * @export
 */
export const DataCleaningResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataCleaningResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary createDataCleaningJob
         * @param {DataCleaningJobRequestDTO} [dataCleaningJobRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataCleaningJobUsingPOST(dataCleaningJobRequestDTO?: DataCleaningJobRequestDTO, options?: any): AxiosPromise<RestResponseOfDataCleaningJobDTO> {
            return localVarFp.createDataCleaningJobUsingPOST(dataCleaningJobRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllDataCleaningJobs
         * @param {DataCleaningJobCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDataCleaningJobsUsingGET(criteria?: DataCleaningJobCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfDataCleaningJobDTO> {
            return localVarFp.getAllDataCleaningJobsUsingGET(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDataCleanerTypes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataCleanerTypesUsingGET(options?: any): AxiosPromise<RestResponseOfListOfString> {
            return localVarFp.getDataCleanerTypesUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDataCleaningJob
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataCleaningJobUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfDataCleaningJobDTO> {
            return localVarFp.getDataCleaningJobUsingGET(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDataCleaningJobUsingPOST operation in DataCleaningResourceApi.
 * @export
 * @interface DataCleaningResourceApiCreateDataCleaningJobUsingPOSTRequest
 */
export interface DataCleaningResourceApiCreateDataCleaningJobUsingPOSTRequest {
    /**
     * 
     * @type {DataCleaningJobRequestDTO}
     * @memberof DataCleaningResourceApiCreateDataCleaningJobUsingPOST
     */
    readonly dataCleaningJobRequestDTO?: DataCleaningJobRequestDTO
}

/**
 * Request parameters for getAllDataCleaningJobsUsingGET operation in DataCleaningResourceApi.
 * @export
 * @interface DataCleaningResourceApiGetAllDataCleaningJobsUsingGETRequest
 */
export interface DataCleaningResourceApiGetAllDataCleaningJobsUsingGETRequest {
    /**
     * criteria
     * @type {DataCleaningJobCriteria}
     * @memberof DataCleaningResourceApiGetAllDataCleaningJobsUsingGET
     */
    readonly criteria?: DataCleaningJobCriteria

    /**
     * pageable
     * @type {Pageable}
     * @memberof DataCleaningResourceApiGetAllDataCleaningJobsUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getDataCleaningJobUsingGET operation in DataCleaningResourceApi.
 * @export
 * @interface DataCleaningResourceApiGetDataCleaningJobUsingGETRequest
 */
export interface DataCleaningResourceApiGetDataCleaningJobUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof DataCleaningResourceApiGetDataCleaningJobUsingGET
     */
    readonly id: number
}

/**
 * DataCleaningResourceApi - object-oriented interface
 * @export
 * @class DataCleaningResourceApi
 * @extends {BaseAPI}
 */
export class DataCleaningResourceApi extends BaseAPI {
    /**
     * 
     * @summary createDataCleaningJob
     * @param {DataCleaningResourceApiCreateDataCleaningJobUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataCleaningResourceApi
     */
    public createDataCleaningJobUsingPOST(requestParameters: DataCleaningResourceApiCreateDataCleaningJobUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return DataCleaningResourceApiFp(this.configuration).createDataCleaningJobUsingPOST(requestParameters.dataCleaningJobRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllDataCleaningJobs
     * @param {DataCleaningResourceApiGetAllDataCleaningJobsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataCleaningResourceApi
     */
    public getAllDataCleaningJobsUsingGET(requestParameters: DataCleaningResourceApiGetAllDataCleaningJobsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return DataCleaningResourceApiFp(this.configuration).getAllDataCleaningJobsUsingGET(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDataCleanerTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataCleaningResourceApi
     */
    public getDataCleanerTypesUsingGET(options?: AxiosRequestConfig) {
        return DataCleaningResourceApiFp(this.configuration).getDataCleanerTypesUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDataCleaningJob
     * @param {DataCleaningResourceApiGetDataCleaningJobUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataCleaningResourceApi
     */
    public getDataCleaningJobUsingGET(requestParameters: DataCleaningResourceApiGetDataCleaningJobUsingGETRequest, options?: AxiosRequestConfig) {
        return DataCleaningResourceApiFp(this.configuration).getDataCleaningJobUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataSetResourceApi - axios parameter creator
 * @export
 */
export const DataSetResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CopyDatasetRequestDTO} copyDatasetRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyDatasetFileInfos: async (copyDatasetRequestDTO: CopyDatasetRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyDatasetRequestDTO' is not null or undefined
            assertParamExists('copyDatasetFileInfos', 'copyDatasetRequestDTO', copyDatasetRequestDTO)
            const localVarPath = `/api/v1/data-sets/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyDatasetRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary countDatasets
         * @param {DatasetCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDatasetsUsingGET: async (criteria?: DatasetCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/data-sets/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createDataset
         * @param {DatasetDTO} datasetDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDatasetUsingPOST: async (datasetDTO: DatasetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetDTO' is not null or undefined
            assertParamExists('createDatasetUsingPOST', 'datasetDTO', datasetDTO)
            const localVarPath = `/api/v1/data-sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteDataset
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDatasetUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDatasetUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/data-sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id dataset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateDatasetsUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('duplicateDatasetsUsingPOST', 'id', id)
            const localVarPath = `/api/v1/data-sets/{id}/duplicate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllDatasets
         * @param {DatasetCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDatasetsUsingGET: async (criteria?: DatasetCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/data-sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get dataset types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/data-sets/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDataset
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDatasetUsingGET', 'id', id)
            const localVarPath = `/api/v1/data-sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MergeDatasetRequestDTO} mergeDatasetRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeDatasets: async (mergeDatasetRequestDTO: MergeDatasetRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mergeDatasetRequestDTO' is not null or undefined
            assertParamExists('mergeDatasets', 'mergeDatasetRequestDTO', mergeDatasetRequestDTO)
            const localVarPath = `/api/v1/data-sets/merge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mergeDatasetRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateDataset
         * @param {number} id id
         * @param {DatasetDTO} [datasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateDatasetUsingPATCH: async (id: number, datasetDTO?: DatasetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateDatasetUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/data-sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDataset
         * @param {number} id id
         * @param {DatasetDTO} [datasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDatasetUsingPUT: async (id: number, datasetDTO?: DatasetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDatasetUsingPUT', 'id', id)
            const localVarPath = `/api/v1/data-sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataSetResourceApi - functional programming interface
 * @export
 */
export const DataSetResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataSetResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CopyDatasetRequestDTO} copyDatasetRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyDatasetFileInfos(copyDatasetRequestDTO: CopyDatasetRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfCopyDatasetResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyDatasetFileInfos(copyDatasetRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary countDatasets
         * @param {DatasetCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countDatasetsUsingGET(criteria?: DatasetCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfLong>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countDatasetsUsingGET(criteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createDataset
         * @param {DatasetDTO} datasetDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDatasetUsingPOST(datasetDTO: DatasetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDatasetUsingPOST(datasetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteDataset
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDatasetUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDatasetUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id dataset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateDatasetsUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDuplicateDatasetResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateDatasetsUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllDatasets
         * @param {DatasetCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDatasetsUsingGET(criteria?: DatasetCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDatasetsUsingGET(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get dataset types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getDataset
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MergeDatasetRequestDTO} mergeDatasetRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mergeDatasets(mergeDatasetRequestDTO: MergeDatasetRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfMergeDatasetResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mergeDatasets(mergeDatasetRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateDataset
         * @param {number} id id
         * @param {DatasetDTO} [datasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateDatasetUsingPATCH(id: number, datasetDTO?: DatasetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateDatasetUsingPATCH(id, datasetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateDataset
         * @param {number} id id
         * @param {DatasetDTO} [datasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDatasetUsingPUT(id: number, datasetDTO?: DatasetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDatasetUsingPUT(id, datasetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataSetResourceApi - factory interface
 * @export
 */
export const DataSetResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataSetResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {CopyDatasetRequestDTO} copyDatasetRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyDatasetFileInfos(copyDatasetRequestDTO: CopyDatasetRequestDTO, options?: any): AxiosPromise<RestResponseOfCopyDatasetResponseDTO> {
            return localVarFp.copyDatasetFileInfos(copyDatasetRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary countDatasets
         * @param {DatasetCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDatasetsUsingGET(criteria?: DatasetCriteria, options?: any): AxiosPromise<RestResponseOfLong> {
            return localVarFp.countDatasetsUsingGET(criteria, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createDataset
         * @param {DatasetDTO} datasetDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDatasetUsingPOST(datasetDTO: DatasetDTO, options?: any): AxiosPromise<RestResponseOfDatasetDTO> {
            return localVarFp.createDatasetUsingPOST(datasetDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteDataset
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDatasetUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteDatasetUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id dataset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateDatasetsUsingPOST(id: number, options?: any): AxiosPromise<RestResponseOfDuplicateDatasetResponseDTO> {
            return localVarFp.duplicateDatasetsUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllDatasets
         * @param {DatasetCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDatasetsUsingGET(criteria?: DatasetCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfDatasetDTO> {
            return localVarFp.getAllDatasetsUsingGET(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get dataset types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetTypes(options?: any): AxiosPromise<RestResponseOfDatasetType> {
            return localVarFp.getDatasetTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDataset
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfDatasetDTO> {
            return localVarFp.getDatasetUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MergeDatasetRequestDTO} mergeDatasetRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeDatasets(mergeDatasetRequestDTO: MergeDatasetRequestDTO, options?: any): AxiosPromise<RestResponseOfMergeDatasetResponseDTO> {
            return localVarFp.mergeDatasets(mergeDatasetRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateDataset
         * @param {number} id id
         * @param {DatasetDTO} [datasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateDatasetUsingPATCH(id: number, datasetDTO?: DatasetDTO, options?: any): AxiosPromise<RestResponseOfDatasetDTO> {
            return localVarFp.partialUpdateDatasetUsingPATCH(id, datasetDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateDataset
         * @param {number} id id
         * @param {DatasetDTO} [datasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDatasetUsingPUT(id: number, datasetDTO?: DatasetDTO, options?: any): AxiosPromise<RestResponseOfDatasetDTO> {
            return localVarFp.updateDatasetUsingPUT(id, datasetDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for copyDatasetFileInfos operation in DataSetResourceApi.
 * @export
 * @interface DataSetResourceApiCopyDatasetFileInfosRequest
 */
export interface DataSetResourceApiCopyDatasetFileInfosRequest {
    /**
     * 
     * @type {CopyDatasetRequestDTO}
     * @memberof DataSetResourceApiCopyDatasetFileInfos
     */
    readonly copyDatasetRequestDTO: CopyDatasetRequestDTO
}

/**
 * Request parameters for countDatasetsUsingGET operation in DataSetResourceApi.
 * @export
 * @interface DataSetResourceApiCountDatasetsUsingGETRequest
 */
export interface DataSetResourceApiCountDatasetsUsingGETRequest {
    /**
     * criteria
     * @type {DatasetCriteria}
     * @memberof DataSetResourceApiCountDatasetsUsingGET
     */
    readonly criteria?: DatasetCriteria
}

/**
 * Request parameters for createDatasetUsingPOST operation in DataSetResourceApi.
 * @export
 * @interface DataSetResourceApiCreateDatasetUsingPOSTRequest
 */
export interface DataSetResourceApiCreateDatasetUsingPOSTRequest {
    /**
     * 
     * @type {DatasetDTO}
     * @memberof DataSetResourceApiCreateDatasetUsingPOST
     */
    readonly datasetDTO: DatasetDTO
}

/**
 * Request parameters for deleteDatasetUsingDELETE operation in DataSetResourceApi.
 * @export
 * @interface DataSetResourceApiDeleteDatasetUsingDELETERequest
 */
export interface DataSetResourceApiDeleteDatasetUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof DataSetResourceApiDeleteDatasetUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for duplicateDatasetsUsingPOST operation in DataSetResourceApi.
 * @export
 * @interface DataSetResourceApiDuplicateDatasetsUsingPOSTRequest
 */
export interface DataSetResourceApiDuplicateDatasetsUsingPOSTRequest {
    /**
     * dataset id
     * @type {number}
     * @memberof DataSetResourceApiDuplicateDatasetsUsingPOST
     */
    readonly id: number
}

/**
 * Request parameters for getAllDatasetsUsingGET operation in DataSetResourceApi.
 * @export
 * @interface DataSetResourceApiGetAllDatasetsUsingGETRequest
 */
export interface DataSetResourceApiGetAllDatasetsUsingGETRequest {
    /**
     * criteria
     * @type {DatasetCriteria}
     * @memberof DataSetResourceApiGetAllDatasetsUsingGET
     */
    readonly criteria?: DatasetCriteria

    /**
     * pageable
     * @type {Pageable}
     * @memberof DataSetResourceApiGetAllDatasetsUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getDatasetUsingGET operation in DataSetResourceApi.
 * @export
 * @interface DataSetResourceApiGetDatasetUsingGETRequest
 */
export interface DataSetResourceApiGetDatasetUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof DataSetResourceApiGetDatasetUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for mergeDatasets operation in DataSetResourceApi.
 * @export
 * @interface DataSetResourceApiMergeDatasetsRequest
 */
export interface DataSetResourceApiMergeDatasetsRequest {
    /**
     * 
     * @type {MergeDatasetRequestDTO}
     * @memberof DataSetResourceApiMergeDatasets
     */
    readonly mergeDatasetRequestDTO: MergeDatasetRequestDTO
}

/**
 * Request parameters for partialUpdateDatasetUsingPATCH operation in DataSetResourceApi.
 * @export
 * @interface DataSetResourceApiPartialUpdateDatasetUsingPATCHRequest
 */
export interface DataSetResourceApiPartialUpdateDatasetUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof DataSetResourceApiPartialUpdateDatasetUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {DatasetDTO}
     * @memberof DataSetResourceApiPartialUpdateDatasetUsingPATCH
     */
    readonly datasetDTO?: DatasetDTO
}

/**
 * Request parameters for updateDatasetUsingPUT operation in DataSetResourceApi.
 * @export
 * @interface DataSetResourceApiUpdateDatasetUsingPUTRequest
 */
export interface DataSetResourceApiUpdateDatasetUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof DataSetResourceApiUpdateDatasetUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {DatasetDTO}
     * @memberof DataSetResourceApiUpdateDatasetUsingPUT
     */
    readonly datasetDTO?: DatasetDTO
}

/**
 * DataSetResourceApi - object-oriented interface
 * @export
 * @class DataSetResourceApi
 * @extends {BaseAPI}
 */
export class DataSetResourceApi extends BaseAPI {
    /**
     * 
     * @param {DataSetResourceApiCopyDatasetFileInfosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSetResourceApi
     */
    public copyDatasetFileInfos(requestParameters: DataSetResourceApiCopyDatasetFileInfosRequest, options?: AxiosRequestConfig) {
        return DataSetResourceApiFp(this.configuration).copyDatasetFileInfos(requestParameters.copyDatasetRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary countDatasets
     * @param {DataSetResourceApiCountDatasetsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSetResourceApi
     */
    public countDatasetsUsingGET(requestParameters: DataSetResourceApiCountDatasetsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return DataSetResourceApiFp(this.configuration).countDatasetsUsingGET(requestParameters.criteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createDataset
     * @param {DataSetResourceApiCreateDatasetUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSetResourceApi
     */
    public createDatasetUsingPOST(requestParameters: DataSetResourceApiCreateDatasetUsingPOSTRequest, options?: AxiosRequestConfig) {
        return DataSetResourceApiFp(this.configuration).createDatasetUsingPOST(requestParameters.datasetDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteDataset
     * @param {DataSetResourceApiDeleteDatasetUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSetResourceApi
     */
    public deleteDatasetUsingDELETE(requestParameters: DataSetResourceApiDeleteDatasetUsingDELETERequest, options?: AxiosRequestConfig) {
        return DataSetResourceApiFp(this.configuration).deleteDatasetUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataSetResourceApiDuplicateDatasetsUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSetResourceApi
     */
    public duplicateDatasetsUsingPOST(requestParameters: DataSetResourceApiDuplicateDatasetsUsingPOSTRequest, options?: AxiosRequestConfig) {
        return DataSetResourceApiFp(this.configuration).duplicateDatasetsUsingPOST(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllDatasets
     * @param {DataSetResourceApiGetAllDatasetsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSetResourceApi
     */
    public getAllDatasetsUsingGET(requestParameters: DataSetResourceApiGetAllDatasetsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return DataSetResourceApiFp(this.configuration).getAllDatasetsUsingGET(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get dataset types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSetResourceApi
     */
    public getDatasetTypes(options?: AxiosRequestConfig) {
        return DataSetResourceApiFp(this.configuration).getDatasetTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDataset
     * @param {DataSetResourceApiGetDatasetUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSetResourceApi
     */
    public getDatasetUsingGET(requestParameters: DataSetResourceApiGetDatasetUsingGETRequest, options?: AxiosRequestConfig) {
        return DataSetResourceApiFp(this.configuration).getDatasetUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataSetResourceApiMergeDatasetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSetResourceApi
     */
    public mergeDatasets(requestParameters: DataSetResourceApiMergeDatasetsRequest, options?: AxiosRequestConfig) {
        return DataSetResourceApiFp(this.configuration).mergeDatasets(requestParameters.mergeDatasetRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateDataset
     * @param {DataSetResourceApiPartialUpdateDatasetUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSetResourceApi
     */
    public partialUpdateDatasetUsingPATCH(requestParameters: DataSetResourceApiPartialUpdateDatasetUsingPATCHRequest, options?: AxiosRequestConfig) {
        return DataSetResourceApiFp(this.configuration).partialUpdateDatasetUsingPATCH(requestParameters.id, requestParameters.datasetDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateDataset
     * @param {DataSetResourceApiUpdateDatasetUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSetResourceApi
     */
    public updateDatasetUsingPUT(requestParameters: DataSetResourceApiUpdateDatasetUsingPUTRequest, options?: AxiosRequestConfig) {
        return DataSetResourceApiFp(this.configuration).updateDatasetUsingPUT(requestParameters.id, requestParameters.datasetDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DatasetAttributeResourceApi - axios parameter creator
 * @export
 */
export const DatasetAttributeResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createDatasetAttribute
         * @param {DatasetAttributeDTO} [datasetAttributeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDatasetAttributeUsingPOST: async (datasetAttributeDTO?: DatasetAttributeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dataset-attributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetAttributeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteDatasetAttribute
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDatasetAttributeUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDatasetAttributeUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/dataset-attributes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllDatasetAttributes
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDatasetAttributesUsingGET: async (pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dataset-attributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDatasetAttribute
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetAttributeUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDatasetAttributeUsingGET', 'id', id)
            const localVarPath = `/api/v1/dataset-attributes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateDatasetAttribute
         * @param {number} id id
         * @param {DatasetAttributeDTO} [datasetAttributeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateDatasetAttributeUsingPATCH: async (id: number, datasetAttributeDTO?: DatasetAttributeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateDatasetAttributeUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/dataset-attributes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetAttributeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDatasetAttribute
         * @param {number} id id
         * @param {DatasetAttributeDTO} [datasetAttributeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDatasetAttributeUsingPUT: async (id: number, datasetAttributeDTO?: DatasetAttributeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDatasetAttributeUsingPUT', 'id', id)
            const localVarPath = `/api/v1/dataset-attributes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetAttributeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetAttributeResourceApi - functional programming interface
 * @export
 */
export const DatasetAttributeResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetAttributeResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createDatasetAttribute
         * @param {DatasetAttributeDTO} [datasetAttributeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDatasetAttributeUsingPOST(datasetAttributeDTO?: DatasetAttributeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetAttributeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDatasetAttributeUsingPOST(datasetAttributeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteDatasetAttribute
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDatasetAttributeUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDatasetAttributeUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllDatasetAttributes
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDatasetAttributesUsingGET(pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfDatasetAttributeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDatasetAttributesUsingGET(pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getDatasetAttribute
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetAttributeUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetAttributeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetAttributeUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateDatasetAttribute
         * @param {number} id id
         * @param {DatasetAttributeDTO} [datasetAttributeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateDatasetAttributeUsingPATCH(id: number, datasetAttributeDTO?: DatasetAttributeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetAttributeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateDatasetAttributeUsingPATCH(id, datasetAttributeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateDatasetAttribute
         * @param {number} id id
         * @param {DatasetAttributeDTO} [datasetAttributeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDatasetAttributeUsingPUT(id: number, datasetAttributeDTO?: DatasetAttributeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetAttributeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDatasetAttributeUsingPUT(id, datasetAttributeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DatasetAttributeResourceApi - factory interface
 * @export
 */
export const DatasetAttributeResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetAttributeResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary createDatasetAttribute
         * @param {DatasetAttributeDTO} [datasetAttributeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDatasetAttributeUsingPOST(datasetAttributeDTO?: DatasetAttributeDTO, options?: any): AxiosPromise<RestResponseOfDatasetAttributeDTO> {
            return localVarFp.createDatasetAttributeUsingPOST(datasetAttributeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteDatasetAttribute
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDatasetAttributeUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteDatasetAttributeUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllDatasetAttributes
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDatasetAttributesUsingGET(pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfDatasetAttributeDTO> {
            return localVarFp.getAllDatasetAttributesUsingGET(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDatasetAttribute
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetAttributeUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfDatasetAttributeDTO> {
            return localVarFp.getDatasetAttributeUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateDatasetAttribute
         * @param {number} id id
         * @param {DatasetAttributeDTO} [datasetAttributeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateDatasetAttributeUsingPATCH(id: number, datasetAttributeDTO?: DatasetAttributeDTO, options?: any): AxiosPromise<RestResponseOfDatasetAttributeDTO> {
            return localVarFp.partialUpdateDatasetAttributeUsingPATCH(id, datasetAttributeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateDatasetAttribute
         * @param {number} id id
         * @param {DatasetAttributeDTO} [datasetAttributeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDatasetAttributeUsingPUT(id: number, datasetAttributeDTO?: DatasetAttributeDTO, options?: any): AxiosPromise<RestResponseOfDatasetAttributeDTO> {
            return localVarFp.updateDatasetAttributeUsingPUT(id, datasetAttributeDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDatasetAttributeUsingPOST operation in DatasetAttributeResourceApi.
 * @export
 * @interface DatasetAttributeResourceApiCreateDatasetAttributeUsingPOSTRequest
 */
export interface DatasetAttributeResourceApiCreateDatasetAttributeUsingPOSTRequest {
    /**
     * 
     * @type {DatasetAttributeDTO}
     * @memberof DatasetAttributeResourceApiCreateDatasetAttributeUsingPOST
     */
    readonly datasetAttributeDTO?: DatasetAttributeDTO
}

/**
 * Request parameters for deleteDatasetAttributeUsingDELETE operation in DatasetAttributeResourceApi.
 * @export
 * @interface DatasetAttributeResourceApiDeleteDatasetAttributeUsingDELETERequest
 */
export interface DatasetAttributeResourceApiDeleteDatasetAttributeUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof DatasetAttributeResourceApiDeleteDatasetAttributeUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for getAllDatasetAttributesUsingGET operation in DatasetAttributeResourceApi.
 * @export
 * @interface DatasetAttributeResourceApiGetAllDatasetAttributesUsingGETRequest
 */
export interface DatasetAttributeResourceApiGetAllDatasetAttributesUsingGETRequest {
    /**
     * pageable
     * @type {Pageable}
     * @memberof DatasetAttributeResourceApiGetAllDatasetAttributesUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getDatasetAttributeUsingGET operation in DatasetAttributeResourceApi.
 * @export
 * @interface DatasetAttributeResourceApiGetDatasetAttributeUsingGETRequest
 */
export interface DatasetAttributeResourceApiGetDatasetAttributeUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof DatasetAttributeResourceApiGetDatasetAttributeUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for partialUpdateDatasetAttributeUsingPATCH operation in DatasetAttributeResourceApi.
 * @export
 * @interface DatasetAttributeResourceApiPartialUpdateDatasetAttributeUsingPATCHRequest
 */
export interface DatasetAttributeResourceApiPartialUpdateDatasetAttributeUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof DatasetAttributeResourceApiPartialUpdateDatasetAttributeUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {DatasetAttributeDTO}
     * @memberof DatasetAttributeResourceApiPartialUpdateDatasetAttributeUsingPATCH
     */
    readonly datasetAttributeDTO?: DatasetAttributeDTO
}

/**
 * Request parameters for updateDatasetAttributeUsingPUT operation in DatasetAttributeResourceApi.
 * @export
 * @interface DatasetAttributeResourceApiUpdateDatasetAttributeUsingPUTRequest
 */
export interface DatasetAttributeResourceApiUpdateDatasetAttributeUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof DatasetAttributeResourceApiUpdateDatasetAttributeUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {DatasetAttributeDTO}
     * @memberof DatasetAttributeResourceApiUpdateDatasetAttributeUsingPUT
     */
    readonly datasetAttributeDTO?: DatasetAttributeDTO
}

/**
 * DatasetAttributeResourceApi - object-oriented interface
 * @export
 * @class DatasetAttributeResourceApi
 * @extends {BaseAPI}
 */
export class DatasetAttributeResourceApi extends BaseAPI {
    /**
     * 
     * @summary createDatasetAttribute
     * @param {DatasetAttributeResourceApiCreateDatasetAttributeUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAttributeResourceApi
     */
    public createDatasetAttributeUsingPOST(requestParameters: DatasetAttributeResourceApiCreateDatasetAttributeUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return DatasetAttributeResourceApiFp(this.configuration).createDatasetAttributeUsingPOST(requestParameters.datasetAttributeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteDatasetAttribute
     * @param {DatasetAttributeResourceApiDeleteDatasetAttributeUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAttributeResourceApi
     */
    public deleteDatasetAttributeUsingDELETE(requestParameters: DatasetAttributeResourceApiDeleteDatasetAttributeUsingDELETERequest, options?: AxiosRequestConfig) {
        return DatasetAttributeResourceApiFp(this.configuration).deleteDatasetAttributeUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllDatasetAttributes
     * @param {DatasetAttributeResourceApiGetAllDatasetAttributesUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAttributeResourceApi
     */
    public getAllDatasetAttributesUsingGET(requestParameters: DatasetAttributeResourceApiGetAllDatasetAttributesUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return DatasetAttributeResourceApiFp(this.configuration).getAllDatasetAttributesUsingGET(requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDatasetAttribute
     * @param {DatasetAttributeResourceApiGetDatasetAttributeUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAttributeResourceApi
     */
    public getDatasetAttributeUsingGET(requestParameters: DatasetAttributeResourceApiGetDatasetAttributeUsingGETRequest, options?: AxiosRequestConfig) {
        return DatasetAttributeResourceApiFp(this.configuration).getDatasetAttributeUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateDatasetAttribute
     * @param {DatasetAttributeResourceApiPartialUpdateDatasetAttributeUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAttributeResourceApi
     */
    public partialUpdateDatasetAttributeUsingPATCH(requestParameters: DatasetAttributeResourceApiPartialUpdateDatasetAttributeUsingPATCHRequest, options?: AxiosRequestConfig) {
        return DatasetAttributeResourceApiFp(this.configuration).partialUpdateDatasetAttributeUsingPATCH(requestParameters.id, requestParameters.datasetAttributeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateDatasetAttribute
     * @param {DatasetAttributeResourceApiUpdateDatasetAttributeUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAttributeResourceApi
     */
    public updateDatasetAttributeUsingPUT(requestParameters: DatasetAttributeResourceApiUpdateDatasetAttributeUsingPUTRequest, options?: AxiosRequestConfig) {
        return DatasetAttributeResourceApiFp(this.configuration).updateDatasetAttributeUsingPUT(requestParameters.id, requestParameters.datasetAttributeDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DatasetVersionResourceApi - axios parameter creator
 * @export
 */
export const DatasetVersionResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary countDatasetVersions
         * @param {DatasetVersionCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDatasetVersionsUsingGET: async (criteria?: DatasetVersionCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dataset-versions/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createDatasetVersion
         * @param {DatasetVersionDTO} [datasetVersionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDatasetVersionUsingPOST: async (datasetVersionDTO?: DatasetVersionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dataset-versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetVersionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteDatasetVersion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDatasetVersionUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDatasetVersionUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/dataset-versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllDatasetVersions
         * @param {DatasetVersionCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDatasetVersionsUsingGET: async (criteria?: DatasetVersionCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dataset-versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDatasetVersion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetVersionUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDatasetVersionUsingGET', 'id', id)
            const localVarPath = `/api/v1/dataset-versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateDatasetVersion
         * @param {number} id id
         * @param {DatasetVersionDTO} [datasetVersionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateDatasetVersionUsingPATCH: async (id: number, datasetVersionDTO?: DatasetVersionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateDatasetVersionUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/dataset-versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetVersionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDatasetVersion
         * @param {number} id id
         * @param {DatasetVersionDTO} [datasetVersionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDatasetVersionUsingPUT: async (id: number, datasetVersionDTO?: DatasetVersionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDatasetVersionUsingPUT', 'id', id)
            const localVarPath = `/api/v1/dataset-versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetVersionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetVersionResourceApi - functional programming interface
 * @export
 */
export const DatasetVersionResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetVersionResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary countDatasetVersions
         * @param {DatasetVersionCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countDatasetVersionsUsingGET(criteria?: DatasetVersionCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfLong>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countDatasetVersionsUsingGET(criteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createDatasetVersion
         * @param {DatasetVersionDTO} [datasetVersionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDatasetVersionUsingPOST(datasetVersionDTO?: DatasetVersionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetVersionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDatasetVersionUsingPOST(datasetVersionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteDatasetVersion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDatasetVersionUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDatasetVersionUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllDatasetVersions
         * @param {DatasetVersionCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDatasetVersionsUsingGET(criteria?: DatasetVersionCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfDatasetVersionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDatasetVersionsUsingGET(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getDatasetVersion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetVersionUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetVersionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetVersionUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateDatasetVersion
         * @param {number} id id
         * @param {DatasetVersionDTO} [datasetVersionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateDatasetVersionUsingPATCH(id: number, datasetVersionDTO?: DatasetVersionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetVersionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateDatasetVersionUsingPATCH(id, datasetVersionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateDatasetVersion
         * @param {number} id id
         * @param {DatasetVersionDTO} [datasetVersionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDatasetVersionUsingPUT(id: number, datasetVersionDTO?: DatasetVersionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetVersionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDatasetVersionUsingPUT(id, datasetVersionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DatasetVersionResourceApi - factory interface
 * @export
 */
export const DatasetVersionResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetVersionResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary countDatasetVersions
         * @param {DatasetVersionCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDatasetVersionsUsingGET(criteria?: DatasetVersionCriteria, options?: any): AxiosPromise<RestResponseOfLong> {
            return localVarFp.countDatasetVersionsUsingGET(criteria, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createDatasetVersion
         * @param {DatasetVersionDTO} [datasetVersionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDatasetVersionUsingPOST(datasetVersionDTO?: DatasetVersionDTO, options?: any): AxiosPromise<RestResponseOfDatasetVersionDTO> {
            return localVarFp.createDatasetVersionUsingPOST(datasetVersionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteDatasetVersion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDatasetVersionUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteDatasetVersionUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllDatasetVersions
         * @param {DatasetVersionCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDatasetVersionsUsingGET(criteria?: DatasetVersionCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfDatasetVersionDTO> {
            return localVarFp.getAllDatasetVersionsUsingGET(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDatasetVersion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetVersionUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfDatasetVersionDTO> {
            return localVarFp.getDatasetVersionUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateDatasetVersion
         * @param {number} id id
         * @param {DatasetVersionDTO} [datasetVersionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateDatasetVersionUsingPATCH(id: number, datasetVersionDTO?: DatasetVersionDTO, options?: any): AxiosPromise<RestResponseOfDatasetVersionDTO> {
            return localVarFp.partialUpdateDatasetVersionUsingPATCH(id, datasetVersionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateDatasetVersion
         * @param {number} id id
         * @param {DatasetVersionDTO} [datasetVersionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDatasetVersionUsingPUT(id: number, datasetVersionDTO?: DatasetVersionDTO, options?: any): AxiosPromise<RestResponseOfDatasetVersionDTO> {
            return localVarFp.updateDatasetVersionUsingPUT(id, datasetVersionDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countDatasetVersionsUsingGET operation in DatasetVersionResourceApi.
 * @export
 * @interface DatasetVersionResourceApiCountDatasetVersionsUsingGETRequest
 */
export interface DatasetVersionResourceApiCountDatasetVersionsUsingGETRequest {
    /**
     * criteria
     * @type {DatasetVersionCriteria}
     * @memberof DatasetVersionResourceApiCountDatasetVersionsUsingGET
     */
    readonly criteria?: DatasetVersionCriteria
}

/**
 * Request parameters for createDatasetVersionUsingPOST operation in DatasetVersionResourceApi.
 * @export
 * @interface DatasetVersionResourceApiCreateDatasetVersionUsingPOSTRequest
 */
export interface DatasetVersionResourceApiCreateDatasetVersionUsingPOSTRequest {
    /**
     * 
     * @type {DatasetVersionDTO}
     * @memberof DatasetVersionResourceApiCreateDatasetVersionUsingPOST
     */
    readonly datasetVersionDTO?: DatasetVersionDTO
}

/**
 * Request parameters for deleteDatasetVersionUsingDELETE operation in DatasetVersionResourceApi.
 * @export
 * @interface DatasetVersionResourceApiDeleteDatasetVersionUsingDELETERequest
 */
export interface DatasetVersionResourceApiDeleteDatasetVersionUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof DatasetVersionResourceApiDeleteDatasetVersionUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for getAllDatasetVersionsUsingGET operation in DatasetVersionResourceApi.
 * @export
 * @interface DatasetVersionResourceApiGetAllDatasetVersionsUsingGETRequest
 */
export interface DatasetVersionResourceApiGetAllDatasetVersionsUsingGETRequest {
    /**
     * criteria
     * @type {DatasetVersionCriteria}
     * @memberof DatasetVersionResourceApiGetAllDatasetVersionsUsingGET
     */
    readonly criteria?: DatasetVersionCriteria

    /**
     * pageable
     * @type {Pageable}
     * @memberof DatasetVersionResourceApiGetAllDatasetVersionsUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getDatasetVersionUsingGET operation in DatasetVersionResourceApi.
 * @export
 * @interface DatasetVersionResourceApiGetDatasetVersionUsingGETRequest
 */
export interface DatasetVersionResourceApiGetDatasetVersionUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof DatasetVersionResourceApiGetDatasetVersionUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for partialUpdateDatasetVersionUsingPATCH operation in DatasetVersionResourceApi.
 * @export
 * @interface DatasetVersionResourceApiPartialUpdateDatasetVersionUsingPATCHRequest
 */
export interface DatasetVersionResourceApiPartialUpdateDatasetVersionUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof DatasetVersionResourceApiPartialUpdateDatasetVersionUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {DatasetVersionDTO}
     * @memberof DatasetVersionResourceApiPartialUpdateDatasetVersionUsingPATCH
     */
    readonly datasetVersionDTO?: DatasetVersionDTO
}

/**
 * Request parameters for updateDatasetVersionUsingPUT operation in DatasetVersionResourceApi.
 * @export
 * @interface DatasetVersionResourceApiUpdateDatasetVersionUsingPUTRequest
 */
export interface DatasetVersionResourceApiUpdateDatasetVersionUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof DatasetVersionResourceApiUpdateDatasetVersionUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {DatasetVersionDTO}
     * @memberof DatasetVersionResourceApiUpdateDatasetVersionUsingPUT
     */
    readonly datasetVersionDTO?: DatasetVersionDTO
}

/**
 * DatasetVersionResourceApi - object-oriented interface
 * @export
 * @class DatasetVersionResourceApi
 * @extends {BaseAPI}
 */
export class DatasetVersionResourceApi extends BaseAPI {
    /**
     * 
     * @summary countDatasetVersions
     * @param {DatasetVersionResourceApiCountDatasetVersionsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetVersionResourceApi
     */
    public countDatasetVersionsUsingGET(requestParameters: DatasetVersionResourceApiCountDatasetVersionsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return DatasetVersionResourceApiFp(this.configuration).countDatasetVersionsUsingGET(requestParameters.criteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createDatasetVersion
     * @param {DatasetVersionResourceApiCreateDatasetVersionUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetVersionResourceApi
     */
    public createDatasetVersionUsingPOST(requestParameters: DatasetVersionResourceApiCreateDatasetVersionUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return DatasetVersionResourceApiFp(this.configuration).createDatasetVersionUsingPOST(requestParameters.datasetVersionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteDatasetVersion
     * @param {DatasetVersionResourceApiDeleteDatasetVersionUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetVersionResourceApi
     */
    public deleteDatasetVersionUsingDELETE(requestParameters: DatasetVersionResourceApiDeleteDatasetVersionUsingDELETERequest, options?: AxiosRequestConfig) {
        return DatasetVersionResourceApiFp(this.configuration).deleteDatasetVersionUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllDatasetVersions
     * @param {DatasetVersionResourceApiGetAllDatasetVersionsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetVersionResourceApi
     */
    public getAllDatasetVersionsUsingGET(requestParameters: DatasetVersionResourceApiGetAllDatasetVersionsUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return DatasetVersionResourceApiFp(this.configuration).getAllDatasetVersionsUsingGET(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDatasetVersion
     * @param {DatasetVersionResourceApiGetDatasetVersionUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetVersionResourceApi
     */
    public getDatasetVersionUsingGET(requestParameters: DatasetVersionResourceApiGetDatasetVersionUsingGETRequest, options?: AxiosRequestConfig) {
        return DatasetVersionResourceApiFp(this.configuration).getDatasetVersionUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateDatasetVersion
     * @param {DatasetVersionResourceApiPartialUpdateDatasetVersionUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetVersionResourceApi
     */
    public partialUpdateDatasetVersionUsingPATCH(requestParameters: DatasetVersionResourceApiPartialUpdateDatasetVersionUsingPATCHRequest, options?: AxiosRequestConfig) {
        return DatasetVersionResourceApiFp(this.configuration).partialUpdateDatasetVersionUsingPATCH(requestParameters.id, requestParameters.datasetVersionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateDatasetVersion
     * @param {DatasetVersionResourceApiUpdateDatasetVersionUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetVersionResourceApi
     */
    public updateDatasetVersionUsingPUT(requestParameters: DatasetVersionResourceApiUpdateDatasetVersionUsingPUTRequest, options?: AxiosRequestConfig) {
        return DatasetVersionResourceApiFp(this.configuration).updateDatasetVersionUsingPUT(requestParameters.id, requestParameters.datasetVersionDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DicomMetaDataResourceApi - axios parameter creator
 * @export
 */
export const DicomMetaDataResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary countDicomMetaData
         * @param {object} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDicomMetaDataUsingGET: async (criteria?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dicom-meta-data/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createDicomMetaData
         * @param {DicomMetaDataDTO} [dicomMetaDataDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDicomMetaDataUsingPOST: async (dicomMetaDataDTO?: DicomMetaDataDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dicom-meta-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dicomMetaDataDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteDicomMetaData
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDicomMetaDataUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDicomMetaDataUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/dicom-meta-data/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllDicomMetaData
         * @param {object} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDicomMetaDataUsingGET: async (criteria?: object, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dicom-meta-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDicomMetaData
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicomMetaDataUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDicomMetaDataUsingGET', 'id', id)
            const localVarPath = `/api/v1/dicom-meta-data/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateDicomMetaData
         * @param {number} id id
         * @param {DicomMetaDataDTO} [dicomMetaDataDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateDicomMetaDataUsingPATCH: async (id: number, dicomMetaDataDTO?: DicomMetaDataDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateDicomMetaDataUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/dicom-meta-data/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dicomMetaDataDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDicomMetaData
         * @param {number} id id
         * @param {DicomMetaDataDTO} [dicomMetaDataDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDicomMetaDataUsingPUT: async (id: number, dicomMetaDataDTO?: DicomMetaDataDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDicomMetaDataUsingPUT', 'id', id)
            const localVarPath = `/api/v1/dicom-meta-data/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dicomMetaDataDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DicomMetaDataResourceApi - functional programming interface
 * @export
 */
export const DicomMetaDataResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DicomMetaDataResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary countDicomMetaData
         * @param {object} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countDicomMetaDataUsingGET(criteria?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfLong>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countDicomMetaDataUsingGET(criteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createDicomMetaData
         * @param {DicomMetaDataDTO} [dicomMetaDataDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDicomMetaDataUsingPOST(dicomMetaDataDTO?: DicomMetaDataDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDicomMetaDataDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDicomMetaDataUsingPOST(dicomMetaDataDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteDicomMetaData
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDicomMetaDataUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDicomMetaDataUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllDicomMetaData
         * @param {object} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDicomMetaDataUsingGET(criteria?: object, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfDicomMetaDataDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDicomMetaDataUsingGET(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getDicomMetaData
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDicomMetaDataUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDicomMetaDataDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDicomMetaDataUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateDicomMetaData
         * @param {number} id id
         * @param {DicomMetaDataDTO} [dicomMetaDataDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateDicomMetaDataUsingPATCH(id: number, dicomMetaDataDTO?: DicomMetaDataDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDicomMetaDataDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateDicomMetaDataUsingPATCH(id, dicomMetaDataDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateDicomMetaData
         * @param {number} id id
         * @param {DicomMetaDataDTO} [dicomMetaDataDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDicomMetaDataUsingPUT(id: number, dicomMetaDataDTO?: DicomMetaDataDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDicomMetaDataDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDicomMetaDataUsingPUT(id, dicomMetaDataDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DicomMetaDataResourceApi - factory interface
 * @export
 */
export const DicomMetaDataResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DicomMetaDataResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary countDicomMetaData
         * @param {object} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDicomMetaDataUsingGET(criteria?: object, options?: any): AxiosPromise<RestResponseOfLong> {
            return localVarFp.countDicomMetaDataUsingGET(criteria, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createDicomMetaData
         * @param {DicomMetaDataDTO} [dicomMetaDataDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDicomMetaDataUsingPOST(dicomMetaDataDTO?: DicomMetaDataDTO, options?: any): AxiosPromise<RestResponseOfDicomMetaDataDTO> {
            return localVarFp.createDicomMetaDataUsingPOST(dicomMetaDataDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteDicomMetaData
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDicomMetaDataUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteDicomMetaDataUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllDicomMetaData
         * @param {object} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDicomMetaDataUsingGET(criteria?: object, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfDicomMetaDataDTO> {
            return localVarFp.getAllDicomMetaDataUsingGET(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDicomMetaData
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicomMetaDataUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfDicomMetaDataDTO> {
            return localVarFp.getDicomMetaDataUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateDicomMetaData
         * @param {number} id id
         * @param {DicomMetaDataDTO} [dicomMetaDataDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateDicomMetaDataUsingPATCH(id: number, dicomMetaDataDTO?: DicomMetaDataDTO, options?: any): AxiosPromise<RestResponseOfDicomMetaDataDTO> {
            return localVarFp.partialUpdateDicomMetaDataUsingPATCH(id, dicomMetaDataDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateDicomMetaData
         * @param {number} id id
         * @param {DicomMetaDataDTO} [dicomMetaDataDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDicomMetaDataUsingPUT(id: number, dicomMetaDataDTO?: DicomMetaDataDTO, options?: any): AxiosPromise<RestResponseOfDicomMetaDataDTO> {
            return localVarFp.updateDicomMetaDataUsingPUT(id, dicomMetaDataDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countDicomMetaDataUsingGET operation in DicomMetaDataResourceApi.
 * @export
 * @interface DicomMetaDataResourceApiCountDicomMetaDataUsingGETRequest
 */
export interface DicomMetaDataResourceApiCountDicomMetaDataUsingGETRequest {
    /**
     * criteria
     * @type {object}
     * @memberof DicomMetaDataResourceApiCountDicomMetaDataUsingGET
     */
    readonly criteria?: object
}

/**
 * Request parameters for createDicomMetaDataUsingPOST operation in DicomMetaDataResourceApi.
 * @export
 * @interface DicomMetaDataResourceApiCreateDicomMetaDataUsingPOSTRequest
 */
export interface DicomMetaDataResourceApiCreateDicomMetaDataUsingPOSTRequest {
    /**
     * 
     * @type {DicomMetaDataDTO}
     * @memberof DicomMetaDataResourceApiCreateDicomMetaDataUsingPOST
     */
    readonly dicomMetaDataDTO?: DicomMetaDataDTO
}

/**
 * Request parameters for deleteDicomMetaDataUsingDELETE operation in DicomMetaDataResourceApi.
 * @export
 * @interface DicomMetaDataResourceApiDeleteDicomMetaDataUsingDELETERequest
 */
export interface DicomMetaDataResourceApiDeleteDicomMetaDataUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof DicomMetaDataResourceApiDeleteDicomMetaDataUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for getAllDicomMetaDataUsingGET operation in DicomMetaDataResourceApi.
 * @export
 * @interface DicomMetaDataResourceApiGetAllDicomMetaDataUsingGETRequest
 */
export interface DicomMetaDataResourceApiGetAllDicomMetaDataUsingGETRequest {
    /**
     * criteria
     * @type {object}
     * @memberof DicomMetaDataResourceApiGetAllDicomMetaDataUsingGET
     */
    readonly criteria?: object

    /**
     * pageable
     * @type {Pageable}
     * @memberof DicomMetaDataResourceApiGetAllDicomMetaDataUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getDicomMetaDataUsingGET operation in DicomMetaDataResourceApi.
 * @export
 * @interface DicomMetaDataResourceApiGetDicomMetaDataUsingGETRequest
 */
export interface DicomMetaDataResourceApiGetDicomMetaDataUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof DicomMetaDataResourceApiGetDicomMetaDataUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for partialUpdateDicomMetaDataUsingPATCH operation in DicomMetaDataResourceApi.
 * @export
 * @interface DicomMetaDataResourceApiPartialUpdateDicomMetaDataUsingPATCHRequest
 */
export interface DicomMetaDataResourceApiPartialUpdateDicomMetaDataUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof DicomMetaDataResourceApiPartialUpdateDicomMetaDataUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {DicomMetaDataDTO}
     * @memberof DicomMetaDataResourceApiPartialUpdateDicomMetaDataUsingPATCH
     */
    readonly dicomMetaDataDTO?: DicomMetaDataDTO
}

/**
 * Request parameters for updateDicomMetaDataUsingPUT operation in DicomMetaDataResourceApi.
 * @export
 * @interface DicomMetaDataResourceApiUpdateDicomMetaDataUsingPUTRequest
 */
export interface DicomMetaDataResourceApiUpdateDicomMetaDataUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof DicomMetaDataResourceApiUpdateDicomMetaDataUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {DicomMetaDataDTO}
     * @memberof DicomMetaDataResourceApiUpdateDicomMetaDataUsingPUT
     */
    readonly dicomMetaDataDTO?: DicomMetaDataDTO
}

/**
 * DicomMetaDataResourceApi - object-oriented interface
 * @export
 * @class DicomMetaDataResourceApi
 * @extends {BaseAPI}
 */
export class DicomMetaDataResourceApi extends BaseAPI {
    /**
     * 
     * @summary countDicomMetaData
     * @param {DicomMetaDataResourceApiCountDicomMetaDataUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicomMetaDataResourceApi
     */
    public countDicomMetaDataUsingGET(requestParameters: DicomMetaDataResourceApiCountDicomMetaDataUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return DicomMetaDataResourceApiFp(this.configuration).countDicomMetaDataUsingGET(requestParameters.criteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createDicomMetaData
     * @param {DicomMetaDataResourceApiCreateDicomMetaDataUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicomMetaDataResourceApi
     */
    public createDicomMetaDataUsingPOST(requestParameters: DicomMetaDataResourceApiCreateDicomMetaDataUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return DicomMetaDataResourceApiFp(this.configuration).createDicomMetaDataUsingPOST(requestParameters.dicomMetaDataDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteDicomMetaData
     * @param {DicomMetaDataResourceApiDeleteDicomMetaDataUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicomMetaDataResourceApi
     */
    public deleteDicomMetaDataUsingDELETE(requestParameters: DicomMetaDataResourceApiDeleteDicomMetaDataUsingDELETERequest, options?: AxiosRequestConfig) {
        return DicomMetaDataResourceApiFp(this.configuration).deleteDicomMetaDataUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllDicomMetaData
     * @param {DicomMetaDataResourceApiGetAllDicomMetaDataUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicomMetaDataResourceApi
     */
    public getAllDicomMetaDataUsingGET(requestParameters: DicomMetaDataResourceApiGetAllDicomMetaDataUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return DicomMetaDataResourceApiFp(this.configuration).getAllDicomMetaDataUsingGET(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDicomMetaData
     * @param {DicomMetaDataResourceApiGetDicomMetaDataUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicomMetaDataResourceApi
     */
    public getDicomMetaDataUsingGET(requestParameters: DicomMetaDataResourceApiGetDicomMetaDataUsingGETRequest, options?: AxiosRequestConfig) {
        return DicomMetaDataResourceApiFp(this.configuration).getDicomMetaDataUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateDicomMetaData
     * @param {DicomMetaDataResourceApiPartialUpdateDicomMetaDataUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicomMetaDataResourceApi
     */
    public partialUpdateDicomMetaDataUsingPATCH(requestParameters: DicomMetaDataResourceApiPartialUpdateDicomMetaDataUsingPATCHRequest, options?: AxiosRequestConfig) {
        return DicomMetaDataResourceApiFp(this.configuration).partialUpdateDicomMetaDataUsingPATCH(requestParameters.id, requestParameters.dicomMetaDataDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateDicomMetaData
     * @param {DicomMetaDataResourceApiUpdateDicomMetaDataUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicomMetaDataResourceApi
     */
    public updateDicomMetaDataUsingPUT(requestParameters: DicomMetaDataResourceApiUpdateDicomMetaDataUsingPUTRequest, options?: AxiosRequestConfig) {
        return DicomMetaDataResourceApiFp(this.configuration).updateDicomMetaDataUsingPUT(requestParameters.id, requestParameters.dicomMetaDataDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileInfoDatasetResourceApi - axios parameter creator
 * @export
 */
export const FileInfoDatasetResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createFileInfoDataset
         * @param {FileInfoDatasetDTO} [fileInfoDatasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileInfoDatasetUsingPOST: async (fileInfoDatasetDTO?: FileInfoDatasetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/file-info-datasets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileInfoDatasetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteFileInfoDataset
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileInfoDatasetUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFileInfoDatasetUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/file-info-datasets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllFileInfoDatasets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFileInfoDatasetsUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/file-info-datasets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getFileInfoDataset
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileInfoDatasetUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFileInfoDatasetUsingGET', 'id', id)
            const localVarPath = `/api/v1/file-info-datasets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateFileInfoDataset
         * @param {number} id id
         * @param {FileInfoDatasetDTO} [fileInfoDatasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateFileInfoDatasetUsingPATCH: async (id: number, fileInfoDatasetDTO?: FileInfoDatasetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateFileInfoDatasetUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/file-info-datasets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileInfoDatasetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateFileInfoDataset
         * @param {number} id id
         * @param {FileInfoDatasetDTO} [fileInfoDatasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFileInfoDatasetUsingPUT: async (id: number, fileInfoDatasetDTO?: FileInfoDatasetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFileInfoDatasetUsingPUT', 'id', id)
            const localVarPath = `/api/v1/file-info-datasets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileInfoDatasetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileInfoDatasetResourceApi - functional programming interface
 * @export
 */
export const FileInfoDatasetResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileInfoDatasetResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createFileInfoDataset
         * @param {FileInfoDatasetDTO} [fileInfoDatasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileInfoDatasetUsingPOST(fileInfoDatasetDTO?: FileInfoDatasetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfFileInfoDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileInfoDatasetUsingPOST(fileInfoDatasetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteFileInfoDataset
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFileInfoDatasetUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFileInfoDatasetUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllFileInfoDatasets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllFileInfoDatasetsUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfFileInfoDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFileInfoDatasetsUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getFileInfoDataset
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileInfoDatasetUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfFileInfoDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileInfoDatasetUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateFileInfoDataset
         * @param {number} id id
         * @param {FileInfoDatasetDTO} [fileInfoDatasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateFileInfoDatasetUsingPATCH(id: number, fileInfoDatasetDTO?: FileInfoDatasetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfFileInfoDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateFileInfoDatasetUsingPATCH(id, fileInfoDatasetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateFileInfoDataset
         * @param {number} id id
         * @param {FileInfoDatasetDTO} [fileInfoDatasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFileInfoDatasetUsingPUT(id: number, fileInfoDatasetDTO?: FileInfoDatasetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfFileInfoDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFileInfoDatasetUsingPUT(id, fileInfoDatasetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileInfoDatasetResourceApi - factory interface
 * @export
 */
export const FileInfoDatasetResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileInfoDatasetResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary createFileInfoDataset
         * @param {FileInfoDatasetDTO} [fileInfoDatasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileInfoDatasetUsingPOST(fileInfoDatasetDTO?: FileInfoDatasetDTO, options?: any): AxiosPromise<RestResponseOfFileInfoDatasetDTO> {
            return localVarFp.createFileInfoDatasetUsingPOST(fileInfoDatasetDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteFileInfoDataset
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileInfoDatasetUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteFileInfoDatasetUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllFileInfoDatasets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFileInfoDatasetsUsingGET(options?: any): AxiosPromise<RestResponseOfListOfFileInfoDatasetDTO> {
            return localVarFp.getAllFileInfoDatasetsUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getFileInfoDataset
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileInfoDatasetUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfFileInfoDatasetDTO> {
            return localVarFp.getFileInfoDatasetUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateFileInfoDataset
         * @param {number} id id
         * @param {FileInfoDatasetDTO} [fileInfoDatasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateFileInfoDatasetUsingPATCH(id: number, fileInfoDatasetDTO?: FileInfoDatasetDTO, options?: any): AxiosPromise<RestResponseOfFileInfoDatasetDTO> {
            return localVarFp.partialUpdateFileInfoDatasetUsingPATCH(id, fileInfoDatasetDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateFileInfoDataset
         * @param {number} id id
         * @param {FileInfoDatasetDTO} [fileInfoDatasetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFileInfoDatasetUsingPUT(id: number, fileInfoDatasetDTO?: FileInfoDatasetDTO, options?: any): AxiosPromise<RestResponseOfFileInfoDatasetDTO> {
            return localVarFp.updateFileInfoDatasetUsingPUT(id, fileInfoDatasetDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createFileInfoDatasetUsingPOST operation in FileInfoDatasetResourceApi.
 * @export
 * @interface FileInfoDatasetResourceApiCreateFileInfoDatasetUsingPOSTRequest
 */
export interface FileInfoDatasetResourceApiCreateFileInfoDatasetUsingPOSTRequest {
    /**
     * 
     * @type {FileInfoDatasetDTO}
     * @memberof FileInfoDatasetResourceApiCreateFileInfoDatasetUsingPOST
     */
    readonly fileInfoDatasetDTO?: FileInfoDatasetDTO
}

/**
 * Request parameters for deleteFileInfoDatasetUsingDELETE operation in FileInfoDatasetResourceApi.
 * @export
 * @interface FileInfoDatasetResourceApiDeleteFileInfoDatasetUsingDELETERequest
 */
export interface FileInfoDatasetResourceApiDeleteFileInfoDatasetUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof FileInfoDatasetResourceApiDeleteFileInfoDatasetUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for getFileInfoDatasetUsingGET operation in FileInfoDatasetResourceApi.
 * @export
 * @interface FileInfoDatasetResourceApiGetFileInfoDatasetUsingGETRequest
 */
export interface FileInfoDatasetResourceApiGetFileInfoDatasetUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof FileInfoDatasetResourceApiGetFileInfoDatasetUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for partialUpdateFileInfoDatasetUsingPATCH operation in FileInfoDatasetResourceApi.
 * @export
 * @interface FileInfoDatasetResourceApiPartialUpdateFileInfoDatasetUsingPATCHRequest
 */
export interface FileInfoDatasetResourceApiPartialUpdateFileInfoDatasetUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof FileInfoDatasetResourceApiPartialUpdateFileInfoDatasetUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {FileInfoDatasetDTO}
     * @memberof FileInfoDatasetResourceApiPartialUpdateFileInfoDatasetUsingPATCH
     */
    readonly fileInfoDatasetDTO?: FileInfoDatasetDTO
}

/**
 * Request parameters for updateFileInfoDatasetUsingPUT operation in FileInfoDatasetResourceApi.
 * @export
 * @interface FileInfoDatasetResourceApiUpdateFileInfoDatasetUsingPUTRequest
 */
export interface FileInfoDatasetResourceApiUpdateFileInfoDatasetUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof FileInfoDatasetResourceApiUpdateFileInfoDatasetUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {FileInfoDatasetDTO}
     * @memberof FileInfoDatasetResourceApiUpdateFileInfoDatasetUsingPUT
     */
    readonly fileInfoDatasetDTO?: FileInfoDatasetDTO
}

/**
 * FileInfoDatasetResourceApi - object-oriented interface
 * @export
 * @class FileInfoDatasetResourceApi
 * @extends {BaseAPI}
 */
export class FileInfoDatasetResourceApi extends BaseAPI {
    /**
     * 
     * @summary createFileInfoDataset
     * @param {FileInfoDatasetResourceApiCreateFileInfoDatasetUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoDatasetResourceApi
     */
    public createFileInfoDatasetUsingPOST(requestParameters: FileInfoDatasetResourceApiCreateFileInfoDatasetUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return FileInfoDatasetResourceApiFp(this.configuration).createFileInfoDatasetUsingPOST(requestParameters.fileInfoDatasetDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteFileInfoDataset
     * @param {FileInfoDatasetResourceApiDeleteFileInfoDatasetUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoDatasetResourceApi
     */
    public deleteFileInfoDatasetUsingDELETE(requestParameters: FileInfoDatasetResourceApiDeleteFileInfoDatasetUsingDELETERequest, options?: AxiosRequestConfig) {
        return FileInfoDatasetResourceApiFp(this.configuration).deleteFileInfoDatasetUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllFileInfoDatasets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoDatasetResourceApi
     */
    public getAllFileInfoDatasetsUsingGET(options?: AxiosRequestConfig) {
        return FileInfoDatasetResourceApiFp(this.configuration).getAllFileInfoDatasetsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getFileInfoDataset
     * @param {FileInfoDatasetResourceApiGetFileInfoDatasetUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoDatasetResourceApi
     */
    public getFileInfoDatasetUsingGET(requestParameters: FileInfoDatasetResourceApiGetFileInfoDatasetUsingGETRequest, options?: AxiosRequestConfig) {
        return FileInfoDatasetResourceApiFp(this.configuration).getFileInfoDatasetUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateFileInfoDataset
     * @param {FileInfoDatasetResourceApiPartialUpdateFileInfoDatasetUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoDatasetResourceApi
     */
    public partialUpdateFileInfoDatasetUsingPATCH(requestParameters: FileInfoDatasetResourceApiPartialUpdateFileInfoDatasetUsingPATCHRequest, options?: AxiosRequestConfig) {
        return FileInfoDatasetResourceApiFp(this.configuration).partialUpdateFileInfoDatasetUsingPATCH(requestParameters.id, requestParameters.fileInfoDatasetDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateFileInfoDataset
     * @param {FileInfoDatasetResourceApiUpdateFileInfoDatasetUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoDatasetResourceApi
     */
    public updateFileInfoDatasetUsingPUT(requestParameters: FileInfoDatasetResourceApiUpdateFileInfoDatasetUsingPUTRequest, options?: AxiosRequestConfig) {
        return FileInfoDatasetResourceApiFp(this.configuration).updateFileInfoDatasetUsingPUT(requestParameters.id, requestParameters.fileInfoDatasetDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileInfoResourceApi - axios parameter creator
 * @export
 */
export const FileInfoResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary countFileInfos
         * @param {FileInfoCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countFileInfosUsingGET: async (criteria?: FileInfoCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/file-infos/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary countGroupByScope
         * @param {FileInfoCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countGroupByScopeUsingGET: async (criteria?: FileInfoCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/file-infos/countByScope`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createFileInfo
         * @param {FileInfoDTO} [fileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileInfoUsingPOST: async (fileInfoDTO?: FileInfoDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/file-infos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileInfoDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteFileInfo
         * @param {number} id id
         * @param {'archive' | 'delete_file' | 'delete_all'} [option] option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileInfoUsingDELETE: async (id: number, option?: 'archive' | 'delete_file' | 'delete_all', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFileInfoUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/file-infos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (option !== undefined) {
                localVarQueryParameter['option'] = option;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllFileInfos
         * @param {FileInfoCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFileInfosUsingGET: async (criteria?: FileInfoCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/file-infos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fileInfoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileInfoDetailsUsingGET: async (fileInfoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileInfoId' is not null or undefined
            assertParamExists('getFileInfoDetailsUsingGET', 'fileInfoId', fileInfoId)
            const localVarPath = `/api/v1/file-infos/{fileInfoId}/details`
                .replace(`{${"fileInfoId"}}`, encodeURIComponent(String(fileInfoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getFileInfo
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileInfoUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFileInfoUsingGET', 'id', id)
            const localVarPath = `/api/v1/file-infos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fileInfoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initFileInfosUsingPOST: async (fileInfoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileInfoId' is not null or undefined
            assertParamExists('initFileInfosUsingPOST', 'fileInfoId', fileInfoId)
            const localVarPath = `/api/v1/file-infos/{fileInfoId}/init`
                .replace(`{${"fileInfoId"}}`, encodeURIComponent(String(fileInfoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateFileInfo
         * @param {number} id id
         * @param {FileInfoDTO} [fileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateFileInfoUsingPATCH: async (id: number, fileInfoDTO?: FileInfoDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateFileInfoUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/file-infos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileInfoDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateFileInfo
         * @param {number} id id
         * @param {FileInfoDTO} [fileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFileInfoUsingPUT: async (id: number, fileInfoDTO?: FileInfoDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFileInfoUsingPUT', 'id', id)
            const localVarPath = `/api/v1/file-infos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileInfoDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadArchiveFile
         * @param {UploadArchiveRequestDTO} [uploadArchiveRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadArchiveFileUsingPOST: async (uploadArchiveRequestDTO?: UploadArchiveRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/files/upload-archive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadArchiveRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} datasetId datasetId
         * @param {Array<File>} [files] 
         * @param {Array<MetadataRequestDTO>} [metadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileSeriesUsingPOST: async (datasetId: number, files?: Array<File>, metadata?: Array<MetadataRequestDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('uploadFileSeriesUsingPOST', 'datasetId', datasetId)
            const localVarPath = `/api/v1/files/upload-series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (datasetId !== undefined) {
                localVarQueryParameter['datasetId'] = datasetId;
            }

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

                if (metadata) {
                localVarFormParams.append('metadata', metadata.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadMultipleFiles
         * @param {number} datasetId datasetId
         * @param {boolean} [byLine] byLine
         * @param {number} [numberOfTime] numberOfTime
         * @param {'millisecond' | 'second' | 'minute'} [typeOfTime] User can choose auto split file when no sound in above: [number of time]
         * @param {number} [numberOfSentence] User can choose auto split sentence per file: [number of sentence].
         * @param {Array<File>} [uploadedFile] 
         * @param {Array<MetadataRequestDTO>} [metadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMultipleFilesUsingPOST: async (datasetId: number, byLine?: boolean, numberOfTime?: number, typeOfTime?: 'millisecond' | 'second' | 'minute', numberOfSentence?: number, uploadedFile?: Array<File>, metadata?: Array<MetadataRequestDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('uploadMultipleFilesUsingPOST', 'datasetId', datasetId)
            const localVarPath = `/api/v1/files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (byLine !== undefined) {
                localVarQueryParameter['byLine'] = byLine;
            }

            if (numberOfTime !== undefined) {
                localVarQueryParameter['numberOfTime'] = numberOfTime;
            }

            if (typeOfTime !== undefined) {
                localVarQueryParameter['typeOfTime'] = typeOfTime;
            }

            if (numberOfSentence !== undefined) {
                localVarQueryParameter['numberOfSentence'] = numberOfSentence;
            }

            if (datasetId !== undefined) {
                localVarQueryParameter['datasetId'] = datasetId;
            }

            if (uploadedFile) {
                uploadedFile.forEach((element) => {
                    localVarFormParams.append('uploadedFile', element as any);
                })
            }

                if (metadata) {
                localVarFormParams.append('metadata', metadata.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadTextFileContainIds
         * @param {number} [datasetId] datasetId
         * @param {Array<File>} [uploadedFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTextFileContainIdsUsingPOST: async (datasetId?: number, uploadedFile?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/files/upload-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (datasetId !== undefined) {
                localVarQueryParameter['datasetId'] = datasetId;
            }

            if (uploadedFile) {
                uploadedFile.forEach((element) => {
                    localVarFormParams.append('uploadedFile', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileInfoResourceApi - functional programming interface
 * @export
 */
export const FileInfoResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileInfoResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary countFileInfos
         * @param {FileInfoCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countFileInfosUsingGET(criteria?: FileInfoCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfLong>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countFileInfosUsingGET(criteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary countGroupByScope
         * @param {FileInfoCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countGroupByScopeUsingGET(criteria?: FileInfoCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfCountByScope>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countGroupByScopeUsingGET(criteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createFileInfo
         * @param {FileInfoDTO} [fileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileInfoUsingPOST(fileInfoDTO?: FileInfoDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfFileInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileInfoUsingPOST(fileInfoDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteFileInfo
         * @param {number} id id
         * @param {'archive' | 'delete_file' | 'delete_all'} [option] option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFileInfoUsingDELETE(id: number, option?: 'archive' | 'delete_file' | 'delete_all', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFileInfoUsingDELETE(id, option, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllFileInfos
         * @param {FileInfoCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllFileInfosUsingGET(criteria?: FileInfoCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfFileInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFileInfosUsingGET(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fileInfoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileInfoDetailsUsingGET(fileInfoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfFileInfoDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileInfoDetailsUsingGET(fileInfoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getFileInfo
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileInfoUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfFileInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileInfoUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fileInfoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initFileInfosUsingPOST(fileInfoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfFileInfoInitResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initFileInfosUsingPOST(fileInfoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateFileInfo
         * @param {number} id id
         * @param {FileInfoDTO} [fileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateFileInfoUsingPATCH(id: number, fileInfoDTO?: FileInfoDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfFileInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateFileInfoUsingPATCH(id, fileInfoDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateFileInfo
         * @param {number} id id
         * @param {FileInfoDTO} [fileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFileInfoUsingPUT(id: number, fileInfoDTO?: FileInfoDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfFileInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFileInfoUsingPUT(id, fileInfoDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary uploadArchiveFile
         * @param {UploadArchiveRequestDTO} [uploadArchiveRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadArchiveFileUsingPOST(uploadArchiveRequestDTO?: UploadArchiveRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfUploadArchiveResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadArchiveFileUsingPOST(uploadArchiveRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} datasetId datasetId
         * @param {Array<File>} [files] 
         * @param {Array<MetadataRequestDTO>} [metadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFileSeriesUsingPOST(datasetId: number, files?: Array<File>, metadata?: Array<MetadataRequestDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfFileInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFileSeriesUsingPOST(datasetId, files, metadata, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary uploadMultipleFiles
         * @param {number} datasetId datasetId
         * @param {boolean} [byLine] byLine
         * @param {number} [numberOfTime] numberOfTime
         * @param {'millisecond' | 'second' | 'minute'} [typeOfTime] User can choose auto split file when no sound in above: [number of time]
         * @param {number} [numberOfSentence] User can choose auto split sentence per file: [number of sentence].
         * @param {Array<File>} [uploadedFile] 
         * @param {Array<MetadataRequestDTO>} [metadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMultipleFilesUsingPOST(datasetId: number, byLine?: boolean, numberOfTime?: number, typeOfTime?: 'millisecond' | 'second' | 'minute', numberOfSentence?: number, uploadedFile?: Array<File>, metadata?: Array<MetadataRequestDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfFileInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMultipleFilesUsingPOST(datasetId, byLine, numberOfTime, typeOfTime, numberOfSentence, uploadedFile, metadata, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary uploadTextFileContainIds
         * @param {number} [datasetId] datasetId
         * @param {Array<File>} [uploadedFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTextFileContainIdsUsingPOST(datasetId?: number, uploadedFile?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadTextFileContainIdsUsingPOST(datasetId, uploadedFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileInfoResourceApi - factory interface
 * @export
 */
export const FileInfoResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileInfoResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary countFileInfos
         * @param {FileInfoCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countFileInfosUsingGET(criteria?: FileInfoCriteria, options?: any): AxiosPromise<RestResponseOfLong> {
            return localVarFp.countFileInfosUsingGET(criteria, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary countGroupByScope
         * @param {FileInfoCriteria} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countGroupByScopeUsingGET(criteria?: FileInfoCriteria, options?: any): AxiosPromise<RestResponseOfCountByScope> {
            return localVarFp.countGroupByScopeUsingGET(criteria, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createFileInfo
         * @param {FileInfoDTO} [fileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileInfoUsingPOST(fileInfoDTO?: FileInfoDTO, options?: any): AxiosPromise<RestResponseOfFileInfoDTO> {
            return localVarFp.createFileInfoUsingPOST(fileInfoDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteFileInfo
         * @param {number} id id
         * @param {'archive' | 'delete_file' | 'delete_all'} [option] option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileInfoUsingDELETE(id: number, option?: 'archive' | 'delete_file' | 'delete_all', options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteFileInfoUsingDELETE(id, option, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllFileInfos
         * @param {FileInfoCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFileInfosUsingGET(criteria?: FileInfoCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfFileInfoDTO> {
            return localVarFp.getAllFileInfosUsingGET(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fileInfoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileInfoDetailsUsingGET(fileInfoId: number, options?: any): AxiosPromise<RestResponseOfFileInfoDetailsDTO> {
            return localVarFp.getFileInfoDetailsUsingGET(fileInfoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getFileInfo
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileInfoUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfFileInfoDTO> {
            return localVarFp.getFileInfoUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fileInfoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initFileInfosUsingPOST(fileInfoId: number, options?: any): AxiosPromise<RestResponseOfFileInfoInitResponseDTO> {
            return localVarFp.initFileInfosUsingPOST(fileInfoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateFileInfo
         * @param {number} id id
         * @param {FileInfoDTO} [fileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateFileInfoUsingPATCH(id: number, fileInfoDTO?: FileInfoDTO, options?: any): AxiosPromise<RestResponseOfFileInfoDTO> {
            return localVarFp.partialUpdateFileInfoUsingPATCH(id, fileInfoDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateFileInfo
         * @param {number} id id
         * @param {FileInfoDTO} [fileInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFileInfoUsingPUT(id: number, fileInfoDTO?: FileInfoDTO, options?: any): AxiosPromise<RestResponseOfFileInfoDTO> {
            return localVarFp.updateFileInfoUsingPUT(id, fileInfoDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploadArchiveFile
         * @param {UploadArchiveRequestDTO} [uploadArchiveRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadArchiveFileUsingPOST(uploadArchiveRequestDTO?: UploadArchiveRequestDTO, options?: any): AxiosPromise<RestResponseOfUploadArchiveResponseDTO> {
            return localVarFp.uploadArchiveFileUsingPOST(uploadArchiveRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} datasetId datasetId
         * @param {Array<File>} [files] 
         * @param {Array<MetadataRequestDTO>} [metadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileSeriesUsingPOST(datasetId: number, files?: Array<File>, metadata?: Array<MetadataRequestDTO>, options?: any): AxiosPromise<RestResponseOfFileInfoDTO> {
            return localVarFp.uploadFileSeriesUsingPOST(datasetId, files, metadata, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploadMultipleFiles
         * @param {number} datasetId datasetId
         * @param {boolean} [byLine] byLine
         * @param {number} [numberOfTime] numberOfTime
         * @param {'millisecond' | 'second' | 'minute'} [typeOfTime] User can choose auto split file when no sound in above: [number of time]
         * @param {number} [numberOfSentence] User can choose auto split sentence per file: [number of sentence].
         * @param {Array<File>} [uploadedFile] 
         * @param {Array<MetadataRequestDTO>} [metadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMultipleFilesUsingPOST(datasetId: number, byLine?: boolean, numberOfTime?: number, typeOfTime?: 'millisecond' | 'second' | 'minute', numberOfSentence?: number, uploadedFile?: Array<File>, metadata?: Array<MetadataRequestDTO>, options?: any): AxiosPromise<RestResponseOfListOfFileInfoDTO> {
            return localVarFp.uploadMultipleFilesUsingPOST(datasetId, byLine, numberOfTime, typeOfTime, numberOfSentence, uploadedFile, metadata, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploadTextFileContainIds
         * @param {number} [datasetId] datasetId
         * @param {Array<File>} [uploadedFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTextFileContainIdsUsingPOST(datasetId?: number, uploadedFile?: Array<File>, options?: any): AxiosPromise<RestResponseOfDatasetDTO> {
            return localVarFp.uploadTextFileContainIdsUsingPOST(datasetId, uploadedFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countFileInfosUsingGET operation in FileInfoResourceApi.
 * @export
 * @interface FileInfoResourceApiCountFileInfosUsingGETRequest
 */
export interface FileInfoResourceApiCountFileInfosUsingGETRequest {
    /**
     * criteria
     * @type {FileInfoCriteria}
     * @memberof FileInfoResourceApiCountFileInfosUsingGET
     */
    readonly criteria?: FileInfoCriteria
}

/**
 * Request parameters for countGroupByScopeUsingGET operation in FileInfoResourceApi.
 * @export
 * @interface FileInfoResourceApiCountGroupByScopeUsingGETRequest
 */
export interface FileInfoResourceApiCountGroupByScopeUsingGETRequest {
    /**
     * criteria
     * @type {FileInfoCriteria}
     * @memberof FileInfoResourceApiCountGroupByScopeUsingGET
     */
    readonly criteria?: FileInfoCriteria
}

/**
 * Request parameters for createFileInfoUsingPOST operation in FileInfoResourceApi.
 * @export
 * @interface FileInfoResourceApiCreateFileInfoUsingPOSTRequest
 */
export interface FileInfoResourceApiCreateFileInfoUsingPOSTRequest {
    /**
     * 
     * @type {FileInfoDTO}
     * @memberof FileInfoResourceApiCreateFileInfoUsingPOST
     */
    readonly fileInfoDTO?: FileInfoDTO
}

/**
 * Request parameters for deleteFileInfoUsingDELETE operation in FileInfoResourceApi.
 * @export
 * @interface FileInfoResourceApiDeleteFileInfoUsingDELETERequest
 */
export interface FileInfoResourceApiDeleteFileInfoUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof FileInfoResourceApiDeleteFileInfoUsingDELETE
     */
    readonly id: number

    /**
     * option
     * @type {'archive' | 'delete_file' | 'delete_all'}
     * @memberof FileInfoResourceApiDeleteFileInfoUsingDELETE
     */
    readonly option?: 'archive' | 'delete_file' | 'delete_all'
}

/**
 * Request parameters for getAllFileInfosUsingGET operation in FileInfoResourceApi.
 * @export
 * @interface FileInfoResourceApiGetAllFileInfosUsingGETRequest
 */
export interface FileInfoResourceApiGetAllFileInfosUsingGETRequest {
    /**
     * criteria
     * @type {FileInfoCriteria}
     * @memberof FileInfoResourceApiGetAllFileInfosUsingGET
     */
    readonly criteria?: FileInfoCriteria

    /**
     * pageable
     * @type {Pageable}
     * @memberof FileInfoResourceApiGetAllFileInfosUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getFileInfoDetailsUsingGET operation in FileInfoResourceApi.
 * @export
 * @interface FileInfoResourceApiGetFileInfoDetailsUsingGETRequest
 */
export interface FileInfoResourceApiGetFileInfoDetailsUsingGETRequest {
    /**
     * 
     * @type {number}
     * @memberof FileInfoResourceApiGetFileInfoDetailsUsingGET
     */
    readonly fileInfoId: number
}

/**
 * Request parameters for getFileInfoUsingGET operation in FileInfoResourceApi.
 * @export
 * @interface FileInfoResourceApiGetFileInfoUsingGETRequest
 */
export interface FileInfoResourceApiGetFileInfoUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof FileInfoResourceApiGetFileInfoUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for initFileInfosUsingPOST operation in FileInfoResourceApi.
 * @export
 * @interface FileInfoResourceApiInitFileInfosUsingPOSTRequest
 */
export interface FileInfoResourceApiInitFileInfosUsingPOSTRequest {
    /**
     * 
     * @type {number}
     * @memberof FileInfoResourceApiInitFileInfosUsingPOST
     */
    readonly fileInfoId: number
}

/**
 * Request parameters for partialUpdateFileInfoUsingPATCH operation in FileInfoResourceApi.
 * @export
 * @interface FileInfoResourceApiPartialUpdateFileInfoUsingPATCHRequest
 */
export interface FileInfoResourceApiPartialUpdateFileInfoUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof FileInfoResourceApiPartialUpdateFileInfoUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {FileInfoDTO}
     * @memberof FileInfoResourceApiPartialUpdateFileInfoUsingPATCH
     */
    readonly fileInfoDTO?: FileInfoDTO
}

/**
 * Request parameters for updateFileInfoUsingPUT operation in FileInfoResourceApi.
 * @export
 * @interface FileInfoResourceApiUpdateFileInfoUsingPUTRequest
 */
export interface FileInfoResourceApiUpdateFileInfoUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof FileInfoResourceApiUpdateFileInfoUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {FileInfoDTO}
     * @memberof FileInfoResourceApiUpdateFileInfoUsingPUT
     */
    readonly fileInfoDTO?: FileInfoDTO
}

/**
 * Request parameters for uploadArchiveFileUsingPOST operation in FileInfoResourceApi.
 * @export
 * @interface FileInfoResourceApiUploadArchiveFileUsingPOSTRequest
 */
export interface FileInfoResourceApiUploadArchiveFileUsingPOSTRequest {
    /**
     * 
     * @type {UploadArchiveRequestDTO}
     * @memberof FileInfoResourceApiUploadArchiveFileUsingPOST
     */
    readonly uploadArchiveRequestDTO?: UploadArchiveRequestDTO
}

/**
 * Request parameters for uploadFileSeriesUsingPOST operation in FileInfoResourceApi.
 * @export
 * @interface FileInfoResourceApiUploadFileSeriesUsingPOSTRequest
 */
export interface FileInfoResourceApiUploadFileSeriesUsingPOSTRequest {
    /**
     * datasetId
     * @type {number}
     * @memberof FileInfoResourceApiUploadFileSeriesUsingPOST
     */
    readonly datasetId: number

    /**
     * 
     * @type {Array<File>}
     * @memberof FileInfoResourceApiUploadFileSeriesUsingPOST
     */
    readonly files?: Array<File>

    /**
     * 
     * @type {Array<MetadataRequestDTO>}
     * @memberof FileInfoResourceApiUploadFileSeriesUsingPOST
     */
    readonly metadata?: Array<MetadataRequestDTO>
}

/**
 * Request parameters for uploadMultipleFilesUsingPOST operation in FileInfoResourceApi.
 * @export
 * @interface FileInfoResourceApiUploadMultipleFilesUsingPOSTRequest
 */
export interface FileInfoResourceApiUploadMultipleFilesUsingPOSTRequest {
    /**
     * datasetId
     * @type {number}
     * @memberof FileInfoResourceApiUploadMultipleFilesUsingPOST
     */
    readonly datasetId: number

    /**
     * byLine
     * @type {boolean}
     * @memberof FileInfoResourceApiUploadMultipleFilesUsingPOST
     */
    readonly byLine?: boolean

    /**
     * numberOfTime
     * @type {number}
     * @memberof FileInfoResourceApiUploadMultipleFilesUsingPOST
     */
    readonly numberOfTime?: number

    /**
     * User can choose auto split file when no sound in above: [number of time]
     * @type {'millisecond' | 'second' | 'minute'}
     * @memberof FileInfoResourceApiUploadMultipleFilesUsingPOST
     */
    readonly typeOfTime?: 'millisecond' | 'second' | 'minute'

    /**
     * User can choose auto split sentence per file: [number of sentence].
     * @type {number}
     * @memberof FileInfoResourceApiUploadMultipleFilesUsingPOST
     */
    readonly numberOfSentence?: number

    /**
     * 
     * @type {Array<File>}
     * @memberof FileInfoResourceApiUploadMultipleFilesUsingPOST
     */
    readonly uploadedFile?: Array<File>

    /**
     * 
     * @type {Array<MetadataRequestDTO>}
     * @memberof FileInfoResourceApiUploadMultipleFilesUsingPOST
     */
    readonly metadata?: Array<MetadataRequestDTO>
}

/**
 * Request parameters for uploadTextFileContainIdsUsingPOST operation in FileInfoResourceApi.
 * @export
 * @interface FileInfoResourceApiUploadTextFileContainIdsUsingPOSTRequest
 */
export interface FileInfoResourceApiUploadTextFileContainIdsUsingPOSTRequest {
    /**
     * datasetId
     * @type {number}
     * @memberof FileInfoResourceApiUploadTextFileContainIdsUsingPOST
     */
    readonly datasetId?: number

    /**
     * 
     * @type {Array<File>}
     * @memberof FileInfoResourceApiUploadTextFileContainIdsUsingPOST
     */
    readonly uploadedFile?: Array<File>
}

/**
 * FileInfoResourceApi - object-oriented interface
 * @export
 * @class FileInfoResourceApi
 * @extends {BaseAPI}
 */
export class FileInfoResourceApi extends BaseAPI {
    /**
     * 
     * @summary countFileInfos
     * @param {FileInfoResourceApiCountFileInfosUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoResourceApi
     */
    public countFileInfosUsingGET(requestParameters: FileInfoResourceApiCountFileInfosUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return FileInfoResourceApiFp(this.configuration).countFileInfosUsingGET(requestParameters.criteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary countGroupByScope
     * @param {FileInfoResourceApiCountGroupByScopeUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoResourceApi
     */
    public countGroupByScopeUsingGET(requestParameters: FileInfoResourceApiCountGroupByScopeUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return FileInfoResourceApiFp(this.configuration).countGroupByScopeUsingGET(requestParameters.criteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createFileInfo
     * @param {FileInfoResourceApiCreateFileInfoUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoResourceApi
     */
    public createFileInfoUsingPOST(requestParameters: FileInfoResourceApiCreateFileInfoUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return FileInfoResourceApiFp(this.configuration).createFileInfoUsingPOST(requestParameters.fileInfoDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteFileInfo
     * @param {FileInfoResourceApiDeleteFileInfoUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoResourceApi
     */
    public deleteFileInfoUsingDELETE(requestParameters: FileInfoResourceApiDeleteFileInfoUsingDELETERequest, options?: AxiosRequestConfig) {
        return FileInfoResourceApiFp(this.configuration).deleteFileInfoUsingDELETE(requestParameters.id, requestParameters.option, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllFileInfos
     * @param {FileInfoResourceApiGetAllFileInfosUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoResourceApi
     */
    public getAllFileInfosUsingGET(requestParameters: FileInfoResourceApiGetAllFileInfosUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return FileInfoResourceApiFp(this.configuration).getAllFileInfosUsingGET(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileInfoResourceApiGetFileInfoDetailsUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoResourceApi
     */
    public getFileInfoDetailsUsingGET(requestParameters: FileInfoResourceApiGetFileInfoDetailsUsingGETRequest, options?: AxiosRequestConfig) {
        return FileInfoResourceApiFp(this.configuration).getFileInfoDetailsUsingGET(requestParameters.fileInfoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getFileInfo
     * @param {FileInfoResourceApiGetFileInfoUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoResourceApi
     */
    public getFileInfoUsingGET(requestParameters: FileInfoResourceApiGetFileInfoUsingGETRequest, options?: AxiosRequestConfig) {
        return FileInfoResourceApiFp(this.configuration).getFileInfoUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileInfoResourceApiInitFileInfosUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoResourceApi
     */
    public initFileInfosUsingPOST(requestParameters: FileInfoResourceApiInitFileInfosUsingPOSTRequest, options?: AxiosRequestConfig) {
        return FileInfoResourceApiFp(this.configuration).initFileInfosUsingPOST(requestParameters.fileInfoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateFileInfo
     * @param {FileInfoResourceApiPartialUpdateFileInfoUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoResourceApi
     */
    public partialUpdateFileInfoUsingPATCH(requestParameters: FileInfoResourceApiPartialUpdateFileInfoUsingPATCHRequest, options?: AxiosRequestConfig) {
        return FileInfoResourceApiFp(this.configuration).partialUpdateFileInfoUsingPATCH(requestParameters.id, requestParameters.fileInfoDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateFileInfo
     * @param {FileInfoResourceApiUpdateFileInfoUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoResourceApi
     */
    public updateFileInfoUsingPUT(requestParameters: FileInfoResourceApiUpdateFileInfoUsingPUTRequest, options?: AxiosRequestConfig) {
        return FileInfoResourceApiFp(this.configuration).updateFileInfoUsingPUT(requestParameters.id, requestParameters.fileInfoDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploadArchiveFile
     * @param {FileInfoResourceApiUploadArchiveFileUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoResourceApi
     */
    public uploadArchiveFileUsingPOST(requestParameters: FileInfoResourceApiUploadArchiveFileUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return FileInfoResourceApiFp(this.configuration).uploadArchiveFileUsingPOST(requestParameters.uploadArchiveRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileInfoResourceApiUploadFileSeriesUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoResourceApi
     */
    public uploadFileSeriesUsingPOST(requestParameters: FileInfoResourceApiUploadFileSeriesUsingPOSTRequest, options?: AxiosRequestConfig) {
        return FileInfoResourceApiFp(this.configuration).uploadFileSeriesUsingPOST(requestParameters.datasetId, requestParameters.files, requestParameters.metadata, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploadMultipleFiles
     * @param {FileInfoResourceApiUploadMultipleFilesUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoResourceApi
     */
    public uploadMultipleFilesUsingPOST(requestParameters: FileInfoResourceApiUploadMultipleFilesUsingPOSTRequest, options?: AxiosRequestConfig) {
        return FileInfoResourceApiFp(this.configuration).uploadMultipleFilesUsingPOST(requestParameters.datasetId, requestParameters.byLine, requestParameters.numberOfTime, requestParameters.typeOfTime, requestParameters.numberOfSentence, requestParameters.uploadedFile, requestParameters.metadata, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploadTextFileContainIds
     * @param {FileInfoResourceApiUploadTextFileContainIdsUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileInfoResourceApi
     */
    public uploadTextFileContainIdsUsingPOST(requestParameters: FileInfoResourceApiUploadTextFileContainIdsUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return FileInfoResourceApiFp(this.configuration).uploadTextFileContainIdsUsingPOST(requestParameters.datasetId, requestParameters.uploadedFile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MedicalDicomServerApi - axios parameter creator
 * @export
 */
export const MedicalDicomServerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} [acceptType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicomStudies: async (dicomServerId: string, study: string, acceptType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dicomServerId' is not null or undefined
            assertParamExists('getDicomStudies', 'dicomServerId', dicomServerId)
            // verify required parameter 'study' is not null or undefined
            assertParamExists('getDicomStudies', 'study', study)
            const localVarPath = `/api/v1/medical/dicom-server/{dicomServerId}/studies/{study}`
                .replace(`{${"dicomServerId"}}`, encodeURIComponent(String(dicomServerId)))
                .replace(`{${"study"}}`, encodeURIComponent(String(study)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptType !== undefined) {
                localVarQueryParameter['acceptType'] = acceptType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesMetadata: async (dicomServerId: string, study: string, series: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dicomServerId' is not null or undefined
            assertParamExists('getSeriesMetadata', 'dicomServerId', dicomServerId)
            // verify required parameter 'study' is not null or undefined
            assertParamExists('getSeriesMetadata', 'study', study)
            // verify required parameter 'series' is not null or undefined
            assertParamExists('getSeriesMetadata', 'series', series)
            const localVarPath = `/api/v1/medical/dicom-server/{dicomServerId}/studies/{study}/series/{series}/metadata`
                .replace(`{${"dicomServerId"}}`, encodeURIComponent(String(dicomServerId)))
                .replace(`{${"study"}}`, encodeURIComponent(String(study)))
                .replace(`{${"series"}}`, encodeURIComponent(String(series)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyMetadata: async (dicomServerId: string, study: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dicomServerId' is not null or undefined
            assertParamExists('getStudyMetadata', 'dicomServerId', dicomServerId)
            // verify required parameter 'study' is not null or undefined
            assertParamExists('getStudyMetadata', 'study', study)
            const localVarPath = `/api/v1/medical/dicom-server/{dicomServerId}/studies/{study}/metadata`
                .replace(`{${"dicomServerId"}}`, encodeURIComponent(String(dicomServerId)))
                .replace(`{${"study"}}`, encodeURIComponent(String(study)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleSearchSeries: async (dicomServerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dicomServerId' is not null or undefined
            assertParamExists('handleSearchSeries', 'dicomServerId', dicomServerId)
            const localVarPath = `/api/v1/medical/dicom-server/{dicomServerId}/series`
                .replace(`{${"dicomServerId"}}`, encodeURIComponent(String(dicomServerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleSearchSeriesByStudyId: async (dicomServerId: string, study: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dicomServerId' is not null or undefined
            assertParamExists('handleSearchSeriesByStudyId', 'dicomServerId', dicomServerId)
            // verify required parameter 'study' is not null or undefined
            assertParamExists('handleSearchSeriesByStudyId', 'study', study)
            const localVarPath = `/api/v1/medical/dicom-server/{dicomServerId}/studies/{study}/series`
                .replace(`{${"dicomServerId"}}`, encodeURIComponent(String(dicomServerId)))
                .replace(`{${"study"}}`, encodeURIComponent(String(study)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {number} [limit] 
         * @param {boolean} [fuzzymatching] 
         * @param {string} [studyDate] 
         * @param {string} [patientName] 
         * @param {string} [patientID] 
         * @param {string} [accessionNumber] 
         * @param {string} [studyDescription] 
         * @param {string} [modalitiesStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleSearchStudies: async (dicomServerId: string, limit?: number, fuzzymatching?: boolean, studyDate?: string, patientName?: string, patientID?: string, accessionNumber?: string, studyDescription?: string, modalitiesStudy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dicomServerId' is not null or undefined
            assertParamExists('handleSearchStudies', 'dicomServerId', dicomServerId)
            const localVarPath = `/api/v1/medical/dicom-server/{dicomServerId}/studies`
                .replace(`{${"dicomServerId"}}`, encodeURIComponent(String(dicomServerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fuzzymatching !== undefined) {
                localVarQueryParameter['fuzzymatching'] = fuzzymatching;
            }

            if (studyDate !== undefined) {
                localVarQueryParameter['StudyDate'] = studyDate;
            }

            if (patientName !== undefined) {
                localVarQueryParameter['PatientName'] = patientName;
            }

            if (patientID !== undefined) {
                localVarQueryParameter['PatientID'] = patientID;
            }

            if (accessionNumber !== undefined) {
                localVarQueryParameter['AccessionNumber'] = accessionNumber;
            }

            if (studyDescription !== undefined) {
                localVarQueryParameter['StudyDescription'] = studyDescription;
            }

            if (modalitiesStudy !== undefined) {
                localVarQueryParameter['ModalitiesStudy'] = modalitiesStudy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {string} instance 
         * @param {number} frames 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFrames: async (dicomServerId: string, study: string, series: string, instance: string, frames: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dicomServerId' is not null or undefined
            assertParamExists('retrieveFrames', 'dicomServerId', dicomServerId)
            // verify required parameter 'study' is not null or undefined
            assertParamExists('retrieveFrames', 'study', study)
            // verify required parameter 'series' is not null or undefined
            assertParamExists('retrieveFrames', 'series', series)
            // verify required parameter 'instance' is not null or undefined
            assertParamExists('retrieveFrames', 'instance', instance)
            // verify required parameter 'frames' is not null or undefined
            assertParamExists('retrieveFrames', 'frames', frames)
            const localVarPath = `/api/v1/medical/dicom-server/{dicomServerId}/studies/{study}/series/{series}/instances/{instance}/frames/{frames}`
                .replace(`{${"dicomServerId"}}`, encodeURIComponent(String(dicomServerId)))
                .replace(`{${"study"}}`, encodeURIComponent(String(study)))
                .replace(`{${"series"}}`, encodeURIComponent(String(series)))
                .replace(`{${"instance"}}`, encodeURIComponent(String(instance)))
                .replace(`{${"frames"}}`, encodeURIComponent(String(frames)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {string} instance 
         * @param {number} frames 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFramesThumbnail: async (dicomServerId: string, study: string, series: string, instance: string, frames: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dicomServerId' is not null or undefined
            assertParamExists('retrieveFramesThumbnail', 'dicomServerId', dicomServerId)
            // verify required parameter 'study' is not null or undefined
            assertParamExists('retrieveFramesThumbnail', 'study', study)
            // verify required parameter 'series' is not null or undefined
            assertParamExists('retrieveFramesThumbnail', 'series', series)
            // verify required parameter 'instance' is not null or undefined
            assertParamExists('retrieveFramesThumbnail', 'instance', instance)
            // verify required parameter 'frames' is not null or undefined
            assertParamExists('retrieveFramesThumbnail', 'frames', frames)
            const localVarPath = `/api/v1/medical/dicom-server/{dicomServerId}/studies/{study}/series/{series}/instances/{instance}/frames/{frames}/thumbnail`
                .replace(`{${"dicomServerId"}}`, encodeURIComponent(String(dicomServerId)))
                .replace(`{${"study"}}`, encodeURIComponent(String(study)))
                .replace(`{${"series"}}`, encodeURIComponent(String(series)))
                .replace(`{${"instance"}}`, encodeURIComponent(String(instance)))
                .replace(`{${"frames"}}`, encodeURIComponent(String(frames)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {string} instance 
         * @param {number} frames 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveRenderedFrames: async (dicomServerId: string, study: string, series: string, instance: string, frames: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dicomServerId' is not null or undefined
            assertParamExists('retrieveRenderedFrames', 'dicomServerId', dicomServerId)
            // verify required parameter 'study' is not null or undefined
            assertParamExists('retrieveRenderedFrames', 'study', study)
            // verify required parameter 'series' is not null or undefined
            assertParamExists('retrieveRenderedFrames', 'series', series)
            // verify required parameter 'instance' is not null or undefined
            assertParamExists('retrieveRenderedFrames', 'instance', instance)
            // verify required parameter 'frames' is not null or undefined
            assertParamExists('retrieveRenderedFrames', 'frames', frames)
            const localVarPath = `/api/v1/medical/dicom-server/{dicomServerId}/studies/{study}/series/{series}/instances/{instance}/frames/{frames}/rendered`
                .replace(`{${"dicomServerId"}}`, encodeURIComponent(String(dicomServerId)))
                .replace(`{${"study"}}`, encodeURIComponent(String(study)))
                .replace(`{${"series"}}`, encodeURIComponent(String(series)))
                .replace(`{${"instance"}}`, encodeURIComponent(String(instance)))
                .replace(`{${"frames"}}`, encodeURIComponent(String(frames)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSeriesThumbnail: async (dicomServerId: string, study: string, series: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dicomServerId' is not null or undefined
            assertParamExists('retrieveSeriesThumbnail', 'dicomServerId', dicomServerId)
            // verify required parameter 'study' is not null or undefined
            assertParamExists('retrieveSeriesThumbnail', 'study', study)
            // verify required parameter 'series' is not null or undefined
            assertParamExists('retrieveSeriesThumbnail', 'series', series)
            const localVarPath = `/api/v1/medical/dicom-server/{dicomServerId}/studies/{study}/series/{series}/thumbnail`
                .replace(`{${"dicomServerId"}}`, encodeURIComponent(String(dicomServerId)))
                .replace(`{${"study"}}`, encodeURIComponent(String(study)))
                .replace(`{${"series"}}`, encodeURIComponent(String(series)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInstances: async (dicomServerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dicomServerId' is not null or undefined
            assertParamExists('searchInstances', 'dicomServerId', dicomServerId)
            const localVarPath = `/api/v1/medical/dicom-server/{dicomServerId}/instances`
                .replace(`{${"dicomServerId"}}`, encodeURIComponent(String(dicomServerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInstancesByStudy: async (dicomServerId: string, study: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dicomServerId' is not null or undefined
            assertParamExists('searchInstancesByStudy', 'dicomServerId', dicomServerId)
            // verify required parameter 'study' is not null or undefined
            assertParamExists('searchInstancesByStudy', 'study', study)
            const localVarPath = `/api/v1/medical/dicom-server/{dicomServerId}/studies/{study}/instances`
                .replace(`{${"dicomServerId"}}`, encodeURIComponent(String(dicomServerId)))
                .replace(`{${"study"}}`, encodeURIComponent(String(study)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInstancesByStudyAndSeries: async (dicomServerId: string, study: string, series: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dicomServerId' is not null or undefined
            assertParamExists('searchInstancesByStudyAndSeries', 'dicomServerId', dicomServerId)
            // verify required parameter 'study' is not null or undefined
            assertParamExists('searchInstancesByStudyAndSeries', 'study', study)
            // verify required parameter 'series' is not null or undefined
            assertParamExists('searchInstancesByStudyAndSeries', 'series', series)
            const localVarPath = `/api/v1/medical/dicom-server/{dicomServerId}/studies/{study}/series/{series}/instances`
                .replace(`{${"dicomServerId"}}`, encodeURIComponent(String(dicomServerId)))
                .replace(`{${"study"}}`, encodeURIComponent(String(study)))
                .replace(`{${"series"}}`, encodeURIComponent(String(series)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicalDicomServerApi - functional programming interface
 * @export
 */
export const MedicalDicomServerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MedicalDicomServerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} [acceptType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDicomStudies(dicomServerId: string, study: string, acceptType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDicomStudies(dicomServerId, study, acceptType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeriesMetadata(dicomServerId: string, study: string, series: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeriesMetadata(dicomServerId, study, series, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudyMetadata(dicomServerId: string, study: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyMetadata(dicomServerId, study, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleSearchSeries(dicomServerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleSearchSeries(dicomServerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleSearchSeriesByStudyId(dicomServerId: string, study: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleSearchSeriesByStudyId(dicomServerId, study, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {number} [limit] 
         * @param {boolean} [fuzzymatching] 
         * @param {string} [studyDate] 
         * @param {string} [patientName] 
         * @param {string} [patientID] 
         * @param {string} [accessionNumber] 
         * @param {string} [studyDescription] 
         * @param {string} [modalitiesStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleSearchStudies(dicomServerId: string, limit?: number, fuzzymatching?: boolean, studyDate?: string, patientName?: string, patientID?: string, accessionNumber?: string, studyDescription?: string, modalitiesStudy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleSearchStudies(dicomServerId, limit, fuzzymatching, studyDate, patientName, patientID, accessionNumber, studyDescription, modalitiesStudy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {string} instance 
         * @param {number} frames 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveFrames(dicomServerId: string, study: string, series: string, instance: string, frames: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveFrames(dicomServerId, study, series, instance, frames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {string} instance 
         * @param {number} frames 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveFramesThumbnail(dicomServerId: string, study: string, series: string, instance: string, frames: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveFramesThumbnail(dicomServerId, study, series, instance, frames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {string} instance 
         * @param {number} frames 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveRenderedFrames(dicomServerId: string, study: string, series: string, instance: string, frames: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveRenderedFrames(dicomServerId, study, series, instance, frames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSeriesThumbnail(dicomServerId: string, study: string, series: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSeriesThumbnail(dicomServerId, study, series, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInstances(dicomServerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInstances(dicomServerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInstancesByStudy(dicomServerId: string, study: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInstancesByStudy(dicomServerId, study, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInstancesByStudyAndSeries(dicomServerId: string, study: string, series: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInstancesByStudyAndSeries(dicomServerId, study, series, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MedicalDicomServerApi - factory interface
 * @export
 */
export const MedicalDicomServerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MedicalDicomServerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} [acceptType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicomStudies(dicomServerId: string, study: string, acceptType?: string, options?: any): AxiosPromise<object> {
            return localVarFp.getDicomStudies(dicomServerId, study, acceptType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesMetadata(dicomServerId: string, study: string, series: string, options?: any): AxiosPromise<object> {
            return localVarFp.getSeriesMetadata(dicomServerId, study, series, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyMetadata(dicomServerId: string, study: string, options?: any): AxiosPromise<object> {
            return localVarFp.getStudyMetadata(dicomServerId, study, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleSearchSeries(dicomServerId: string, options?: any): AxiosPromise<object> {
            return localVarFp.handleSearchSeries(dicomServerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleSearchSeriesByStudyId(dicomServerId: string, study: string, options?: any): AxiosPromise<object> {
            return localVarFp.handleSearchSeriesByStudyId(dicomServerId, study, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {number} [limit] 
         * @param {boolean} [fuzzymatching] 
         * @param {string} [studyDate] 
         * @param {string} [patientName] 
         * @param {string} [patientID] 
         * @param {string} [accessionNumber] 
         * @param {string} [studyDescription] 
         * @param {string} [modalitiesStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleSearchStudies(dicomServerId: string, limit?: number, fuzzymatching?: boolean, studyDate?: string, patientName?: string, patientID?: string, accessionNumber?: string, studyDescription?: string, modalitiesStudy?: string, options?: any): AxiosPromise<object> {
            return localVarFp.handleSearchStudies(dicomServerId, limit, fuzzymatching, studyDate, patientName, patientID, accessionNumber, studyDescription, modalitiesStudy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {string} instance 
         * @param {number} frames 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFrames(dicomServerId: string, study: string, series: string, instance: string, frames: number, options?: any): AxiosPromise<object> {
            return localVarFp.retrieveFrames(dicomServerId, study, series, instance, frames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {string} instance 
         * @param {number} frames 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFramesThumbnail(dicomServerId: string, study: string, series: string, instance: string, frames: number, options?: any): AxiosPromise<object> {
            return localVarFp.retrieveFramesThumbnail(dicomServerId, study, series, instance, frames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {string} instance 
         * @param {number} frames 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveRenderedFrames(dicomServerId: string, study: string, series: string, instance: string, frames: number, options?: any): AxiosPromise<object> {
            return localVarFp.retrieveRenderedFrames(dicomServerId, study, series, instance, frames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSeriesThumbnail(dicomServerId: string, study: string, series: string, options?: any): AxiosPromise<object> {
            return localVarFp.retrieveSeriesThumbnail(dicomServerId, study, series, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInstances(dicomServerId: string, options?: any): AxiosPromise<object> {
            return localVarFp.searchInstances(dicomServerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInstancesByStudy(dicomServerId: string, study: string, options?: any): AxiosPromise<object> {
            return localVarFp.searchInstancesByStudy(dicomServerId, study, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dicomServerId id of dicom server to search
         * @param {string} study 
         * @param {string} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInstancesByStudyAndSeries(dicomServerId: string, study: string, series: string, options?: any): AxiosPromise<object> {
            return localVarFp.searchInstancesByStudyAndSeries(dicomServerId, study, series, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDicomStudies operation in MedicalDicomServerApi.
 * @export
 * @interface MedicalDicomServerApiGetDicomStudiesRequest
 */
export interface MedicalDicomServerApiGetDicomStudiesRequest {
    /**
     * id of dicom server to search
     * @type {string}
     * @memberof MedicalDicomServerApiGetDicomStudies
     */
    readonly dicomServerId: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiGetDicomStudies
     */
    readonly study: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiGetDicomStudies
     */
    readonly acceptType?: string
}

/**
 * Request parameters for getSeriesMetadata operation in MedicalDicomServerApi.
 * @export
 * @interface MedicalDicomServerApiGetSeriesMetadataRequest
 */
export interface MedicalDicomServerApiGetSeriesMetadataRequest {
    /**
     * id of dicom server to search
     * @type {string}
     * @memberof MedicalDicomServerApiGetSeriesMetadata
     */
    readonly dicomServerId: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiGetSeriesMetadata
     */
    readonly study: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiGetSeriesMetadata
     */
    readonly series: string
}

/**
 * Request parameters for getStudyMetadata operation in MedicalDicomServerApi.
 * @export
 * @interface MedicalDicomServerApiGetStudyMetadataRequest
 */
export interface MedicalDicomServerApiGetStudyMetadataRequest {
    /**
     * id of dicom server to search
     * @type {string}
     * @memberof MedicalDicomServerApiGetStudyMetadata
     */
    readonly dicomServerId: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiGetStudyMetadata
     */
    readonly study: string
}

/**
 * Request parameters for handleSearchSeries operation in MedicalDicomServerApi.
 * @export
 * @interface MedicalDicomServerApiHandleSearchSeriesRequest
 */
export interface MedicalDicomServerApiHandleSearchSeriesRequest {
    /**
     * id of dicom server to search
     * @type {string}
     * @memberof MedicalDicomServerApiHandleSearchSeries
     */
    readonly dicomServerId: string
}

/**
 * Request parameters for handleSearchSeriesByStudyId operation in MedicalDicomServerApi.
 * @export
 * @interface MedicalDicomServerApiHandleSearchSeriesByStudyIdRequest
 */
export interface MedicalDicomServerApiHandleSearchSeriesByStudyIdRequest {
    /**
     * id of dicom server to search
     * @type {string}
     * @memberof MedicalDicomServerApiHandleSearchSeriesByStudyId
     */
    readonly dicomServerId: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiHandleSearchSeriesByStudyId
     */
    readonly study: string
}

/**
 * Request parameters for handleSearchStudies operation in MedicalDicomServerApi.
 * @export
 * @interface MedicalDicomServerApiHandleSearchStudiesRequest
 */
export interface MedicalDicomServerApiHandleSearchStudiesRequest {
    /**
     * id of dicom server to search
     * @type {string}
     * @memberof MedicalDicomServerApiHandleSearchStudies
     */
    readonly dicomServerId: string

    /**
     * 
     * @type {number}
     * @memberof MedicalDicomServerApiHandleSearchStudies
     */
    readonly limit?: number

    /**
     * 
     * @type {boolean}
     * @memberof MedicalDicomServerApiHandleSearchStudies
     */
    readonly fuzzymatching?: boolean

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiHandleSearchStudies
     */
    readonly studyDate?: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiHandleSearchStudies
     */
    readonly patientName?: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiHandleSearchStudies
     */
    readonly patientID?: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiHandleSearchStudies
     */
    readonly accessionNumber?: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiHandleSearchStudies
     */
    readonly studyDescription?: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiHandleSearchStudies
     */
    readonly modalitiesStudy?: string
}

/**
 * Request parameters for retrieveFrames operation in MedicalDicomServerApi.
 * @export
 * @interface MedicalDicomServerApiRetrieveFramesRequest
 */
export interface MedicalDicomServerApiRetrieveFramesRequest {
    /**
     * id of dicom server to search
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveFrames
     */
    readonly dicomServerId: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveFrames
     */
    readonly study: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveFrames
     */
    readonly series: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveFrames
     */
    readonly instance: string

    /**
     * 
     * @type {number}
     * @memberof MedicalDicomServerApiRetrieveFrames
     */
    readonly frames: number
}

/**
 * Request parameters for retrieveFramesThumbnail operation in MedicalDicomServerApi.
 * @export
 * @interface MedicalDicomServerApiRetrieveFramesThumbnailRequest
 */
export interface MedicalDicomServerApiRetrieveFramesThumbnailRequest {
    /**
     * id of dicom server to search
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveFramesThumbnail
     */
    readonly dicomServerId: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveFramesThumbnail
     */
    readonly study: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveFramesThumbnail
     */
    readonly series: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveFramesThumbnail
     */
    readonly instance: string

    /**
     * 
     * @type {number}
     * @memberof MedicalDicomServerApiRetrieveFramesThumbnail
     */
    readonly frames: number
}

/**
 * Request parameters for retrieveRenderedFrames operation in MedicalDicomServerApi.
 * @export
 * @interface MedicalDicomServerApiRetrieveRenderedFramesRequest
 */
export interface MedicalDicomServerApiRetrieveRenderedFramesRequest {
    /**
     * id of dicom server to search
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveRenderedFrames
     */
    readonly dicomServerId: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveRenderedFrames
     */
    readonly study: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveRenderedFrames
     */
    readonly series: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveRenderedFrames
     */
    readonly instance: string

    /**
     * 
     * @type {number}
     * @memberof MedicalDicomServerApiRetrieveRenderedFrames
     */
    readonly frames: number
}

/**
 * Request parameters for retrieveSeriesThumbnail operation in MedicalDicomServerApi.
 * @export
 * @interface MedicalDicomServerApiRetrieveSeriesThumbnailRequest
 */
export interface MedicalDicomServerApiRetrieveSeriesThumbnailRequest {
    /**
     * id of dicom server to search
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveSeriesThumbnail
     */
    readonly dicomServerId: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveSeriesThumbnail
     */
    readonly study: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiRetrieveSeriesThumbnail
     */
    readonly series: string
}

/**
 * Request parameters for searchInstances operation in MedicalDicomServerApi.
 * @export
 * @interface MedicalDicomServerApiSearchInstancesRequest
 */
export interface MedicalDicomServerApiSearchInstancesRequest {
    /**
     * id of dicom server to search
     * @type {string}
     * @memberof MedicalDicomServerApiSearchInstances
     */
    readonly dicomServerId: string
}

/**
 * Request parameters for searchInstancesByStudy operation in MedicalDicomServerApi.
 * @export
 * @interface MedicalDicomServerApiSearchInstancesByStudyRequest
 */
export interface MedicalDicomServerApiSearchInstancesByStudyRequest {
    /**
     * id of dicom server to search
     * @type {string}
     * @memberof MedicalDicomServerApiSearchInstancesByStudy
     */
    readonly dicomServerId: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiSearchInstancesByStudy
     */
    readonly study: string
}

/**
 * Request parameters for searchInstancesByStudyAndSeries operation in MedicalDicomServerApi.
 * @export
 * @interface MedicalDicomServerApiSearchInstancesByStudyAndSeriesRequest
 */
export interface MedicalDicomServerApiSearchInstancesByStudyAndSeriesRequest {
    /**
     * id of dicom server to search
     * @type {string}
     * @memberof MedicalDicomServerApiSearchInstancesByStudyAndSeries
     */
    readonly dicomServerId: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiSearchInstancesByStudyAndSeries
     */
    readonly study: string

    /**
     * 
     * @type {string}
     * @memberof MedicalDicomServerApiSearchInstancesByStudyAndSeries
     */
    readonly series: string
}

/**
 * MedicalDicomServerApi - object-oriented interface
 * @export
 * @class MedicalDicomServerApi
 * @extends {BaseAPI}
 */
export class MedicalDicomServerApi extends BaseAPI {
    /**
     * 
     * @param {MedicalDicomServerApiGetDicomStudiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDicomServerApi
     */
    public getDicomStudies(requestParameters: MedicalDicomServerApiGetDicomStudiesRequest, options?: AxiosRequestConfig) {
        return MedicalDicomServerApiFp(this.configuration).getDicomStudies(requestParameters.dicomServerId, requestParameters.study, requestParameters.acceptType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDicomServerApiGetSeriesMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDicomServerApi
     */
    public getSeriesMetadata(requestParameters: MedicalDicomServerApiGetSeriesMetadataRequest, options?: AxiosRequestConfig) {
        return MedicalDicomServerApiFp(this.configuration).getSeriesMetadata(requestParameters.dicomServerId, requestParameters.study, requestParameters.series, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDicomServerApiGetStudyMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDicomServerApi
     */
    public getStudyMetadata(requestParameters: MedicalDicomServerApiGetStudyMetadataRequest, options?: AxiosRequestConfig) {
        return MedicalDicomServerApiFp(this.configuration).getStudyMetadata(requestParameters.dicomServerId, requestParameters.study, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDicomServerApiHandleSearchSeriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDicomServerApi
     */
    public handleSearchSeries(requestParameters: MedicalDicomServerApiHandleSearchSeriesRequest, options?: AxiosRequestConfig) {
        return MedicalDicomServerApiFp(this.configuration).handleSearchSeries(requestParameters.dicomServerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDicomServerApiHandleSearchSeriesByStudyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDicomServerApi
     */
    public handleSearchSeriesByStudyId(requestParameters: MedicalDicomServerApiHandleSearchSeriesByStudyIdRequest, options?: AxiosRequestConfig) {
        return MedicalDicomServerApiFp(this.configuration).handleSearchSeriesByStudyId(requestParameters.dicomServerId, requestParameters.study, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDicomServerApiHandleSearchStudiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDicomServerApi
     */
    public handleSearchStudies(requestParameters: MedicalDicomServerApiHandleSearchStudiesRequest, options?: AxiosRequestConfig) {
        return MedicalDicomServerApiFp(this.configuration).handleSearchStudies(requestParameters.dicomServerId, requestParameters.limit, requestParameters.fuzzymatching, requestParameters.studyDate, requestParameters.patientName, requestParameters.patientID, requestParameters.accessionNumber, requestParameters.studyDescription, requestParameters.modalitiesStudy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDicomServerApiRetrieveFramesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDicomServerApi
     */
    public retrieveFrames(requestParameters: MedicalDicomServerApiRetrieveFramesRequest, options?: AxiosRequestConfig) {
        return MedicalDicomServerApiFp(this.configuration).retrieveFrames(requestParameters.dicomServerId, requestParameters.study, requestParameters.series, requestParameters.instance, requestParameters.frames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDicomServerApiRetrieveFramesThumbnailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDicomServerApi
     */
    public retrieveFramesThumbnail(requestParameters: MedicalDicomServerApiRetrieveFramesThumbnailRequest, options?: AxiosRequestConfig) {
        return MedicalDicomServerApiFp(this.configuration).retrieveFramesThumbnail(requestParameters.dicomServerId, requestParameters.study, requestParameters.series, requestParameters.instance, requestParameters.frames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDicomServerApiRetrieveRenderedFramesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDicomServerApi
     */
    public retrieveRenderedFrames(requestParameters: MedicalDicomServerApiRetrieveRenderedFramesRequest, options?: AxiosRequestConfig) {
        return MedicalDicomServerApiFp(this.configuration).retrieveRenderedFrames(requestParameters.dicomServerId, requestParameters.study, requestParameters.series, requestParameters.instance, requestParameters.frames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDicomServerApiRetrieveSeriesThumbnailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDicomServerApi
     */
    public retrieveSeriesThumbnail(requestParameters: MedicalDicomServerApiRetrieveSeriesThumbnailRequest, options?: AxiosRequestConfig) {
        return MedicalDicomServerApiFp(this.configuration).retrieveSeriesThumbnail(requestParameters.dicomServerId, requestParameters.study, requestParameters.series, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDicomServerApiSearchInstancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDicomServerApi
     */
    public searchInstances(requestParameters: MedicalDicomServerApiSearchInstancesRequest, options?: AxiosRequestConfig) {
        return MedicalDicomServerApiFp(this.configuration).searchInstances(requestParameters.dicomServerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDicomServerApiSearchInstancesByStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDicomServerApi
     */
    public searchInstancesByStudy(requestParameters: MedicalDicomServerApiSearchInstancesByStudyRequest, options?: AxiosRequestConfig) {
        return MedicalDicomServerApiFp(this.configuration).searchInstancesByStudy(requestParameters.dicomServerId, requestParameters.study, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDicomServerApiSearchInstancesByStudyAndSeriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDicomServerApi
     */
    public searchInstancesByStudyAndSeries(requestParameters: MedicalDicomServerApiSearchInstancesByStudyAndSeriesRequest, options?: AxiosRequestConfig) {
        return MedicalDicomServerApiFp(this.configuration).searchInstancesByStudyAndSeries(requestParameters.dicomServerId, requestParameters.study, requestParameters.series, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MedicalResourceApi - axios parameter creator
 * @export
 */
export const MedicalResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedicalDatasetCreateRequestDTO} [medicalDatasetCreateRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDatasetMedicalUsingPOST: async (medicalDatasetCreateRequestDTO?: MedicalDatasetCreateRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/medical/data-sets/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medicalDatasetCreateRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DicomServerRequestDTO} [dicomServerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDicomServerUsingPOST: async (dicomServerRequestDTO?: DicomServerRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/medical/dicom-servers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dicomServerRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedicalCloudDTO} [medicalCloudDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMedicalCloudUsingPOST: async (medicalCloudDTO?: MedicalCloudDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/medical/clouds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medicalCloudDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDicomServerUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/medical/dicom-servers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceUid 
         * @param {string} [dicomServerId] 
         * @param {number} [fileInfoId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileContentMedicalUsingPOST: async (instanceUid: string, dicomServerId?: string, fileInfoId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceUid' is not null or undefined
            assertParamExists('getFileContentMedicalUsingPOST', 'instanceUid', instanceUid)
            const localVarPath = `/api/v1/medical/files/{instanceUid}`
                .replace(`{${"instanceUid"}}`, encodeURIComponent(String(instanceUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dicomServerId !== undefined) {
                localVarQueryParameter['dicomServerId'] = dicomServerId;
            }

            if (fileInfoId !== undefined) {
                localVarQueryParameter['fileInfoId'] = fileInfoId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedicalMetadataRequestDTO} [medicalMetadataRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesMetadataMedicalUsingPOST: async (medicalMetadataRequestDTO?: MedicalMetadataRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/medical/files/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medicalMetadataRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedicalSearchRequestDTO} [medicalSearchRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFilesMedicalUsingPOST: async (medicalSearchRequestDTO?: MedicalSearchRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/medical/files/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medicalSearchRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadArchiveMedicalRequestDTO} [uploadArchiveMedicalRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadArchiveFileMedicalUsingPOST: async (uploadArchiveMedicalRequestDTO?: UploadArchiveMedicalRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/medical/files/upload-archive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadArchiveMedicalRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dicomServerId] 
         * @param {Array<string>} [storageResourceIds] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFilesMedicalUsingPOST: async (dicomServerId?: string, storageResourceIds?: Array<string>, files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/medical/files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dicomServerId !== undefined) {
                localVarQueryParameter['dicomServerId'] = dicomServerId;
            }

            if (storageResourceIds) {
                localVarQueryParameter['storageResourceIds'] = storageResourceIds;
            }

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicalResourceApi - functional programming interface
 * @export
 */
export const MedicalResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MedicalResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedicalDatasetCreateRequestDTO} [medicalDatasetCreateRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDatasetMedicalUsingPOST(medicalDatasetCreateRequestDTO?: MedicalDatasetCreateRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDatasetMedicalUsingPOST(medicalDatasetCreateRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DicomServerRequestDTO} [dicomServerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDicomServerUsingPOST(dicomServerRequestDTO?: DicomServerRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfDicomServerResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDicomServerUsingPOST(dicomServerRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedicalCloudDTO} [medicalCloudDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMedicalCloudUsingPOST(medicalCloudDTO?: MedicalCloudDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfCloudDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMedicalCloudUsingPOST(medicalCloudDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDicomServerUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfDicomServerResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDicomServerUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} instanceUid 
         * @param {string} [dicomServerId] 
         * @param {number} [fileInfoId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileContentMedicalUsingPOST(instanceUid: string, dicomServerId?: string, fileInfoId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileContentMedicalUsingPOST(instanceUid, dicomServerId, fileInfoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedicalMetadataRequestDTO} [medicalMetadataRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilesMetadataMedicalUsingPOST(medicalMetadataRequestDTO?: MedicalMetadataRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfMedicalMetadataResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesMetadataMedicalUsingPOST(medicalMetadataRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedicalSearchRequestDTO} [medicalSearchRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFilesMedicalUsingPOST(medicalSearchRequestDTO?: MedicalSearchRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfMedicalSearchResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFilesMedicalUsingPOST(medicalSearchRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadArchiveMedicalRequestDTO} [uploadArchiveMedicalRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadArchiveFileMedicalUsingPOST(uploadArchiveMedicalRequestDTO?: UploadArchiveMedicalRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfUploadArchiveResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadArchiveFileMedicalUsingPOST(uploadArchiveMedicalRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dicomServerId] 
         * @param {Array<string>} [storageResourceIds] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFilesMedicalUsingPOST(dicomServerId?: string, storageResourceIds?: Array<string>, files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfFileInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFilesMedicalUsingPOST(dicomServerId, storageResourceIds, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MedicalResourceApi - factory interface
 * @export
 */
export const MedicalResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MedicalResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {MedicalDatasetCreateRequestDTO} [medicalDatasetCreateRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDatasetMedicalUsingPOST(medicalDatasetCreateRequestDTO?: MedicalDatasetCreateRequestDTO, options?: any): AxiosPromise<RestResponseOfDatasetDTO> {
            return localVarFp.createDatasetMedicalUsingPOST(medicalDatasetCreateRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DicomServerRequestDTO} [dicomServerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDicomServerUsingPOST(dicomServerRequestDTO?: DicomServerRequestDTO, options?: any): AxiosPromise<RestResponseOfDicomServerResponseDTO> {
            return localVarFp.createDicomServerUsingPOST(dicomServerRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedicalCloudDTO} [medicalCloudDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMedicalCloudUsingPOST(medicalCloudDTO?: MedicalCloudDTO, options?: any): AxiosPromise<RestResponseOfCloudDTO> {
            return localVarFp.createMedicalCloudUsingPOST(medicalCloudDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDicomServerUsingGET(options?: any): AxiosPromise<RestResponseOfListOfDicomServerResponseDTO> {
            return localVarFp.getAllDicomServerUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceUid 
         * @param {string} [dicomServerId] 
         * @param {number} [fileInfoId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileContentMedicalUsingPOST(instanceUid: string, dicomServerId?: string, fileInfoId?: number, options?: any): AxiosPromise<File> {
            return localVarFp.getFileContentMedicalUsingPOST(instanceUid, dicomServerId, fileInfoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedicalMetadataRequestDTO} [medicalMetadataRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesMetadataMedicalUsingPOST(medicalMetadataRequestDTO?: MedicalMetadataRequestDTO, options?: any): AxiosPromise<RestResponseOfListOfMedicalMetadataResponseDTO> {
            return localVarFp.getFilesMetadataMedicalUsingPOST(medicalMetadataRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedicalSearchRequestDTO} [medicalSearchRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFilesMedicalUsingPOST(medicalSearchRequestDTO?: MedicalSearchRequestDTO, options?: any): AxiosPromise<RestResponseOfListOfMedicalSearchResponseDTO> {
            return localVarFp.searchFilesMedicalUsingPOST(medicalSearchRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadArchiveMedicalRequestDTO} [uploadArchiveMedicalRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadArchiveFileMedicalUsingPOST(uploadArchiveMedicalRequestDTO?: UploadArchiveMedicalRequestDTO, options?: any): AxiosPromise<RestResponseOfUploadArchiveResponseDTO> {
            return localVarFp.uploadArchiveFileMedicalUsingPOST(uploadArchiveMedicalRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dicomServerId] 
         * @param {Array<string>} [storageResourceIds] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFilesMedicalUsingPOST(dicomServerId?: string, storageResourceIds?: Array<string>, files?: Array<File>, options?: any): AxiosPromise<RestResponseOfListOfFileInfoDTO> {
            return localVarFp.uploadFilesMedicalUsingPOST(dicomServerId, storageResourceIds, files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDatasetMedicalUsingPOST operation in MedicalResourceApi.
 * @export
 * @interface MedicalResourceApiCreateDatasetMedicalUsingPOSTRequest
 */
export interface MedicalResourceApiCreateDatasetMedicalUsingPOSTRequest {
    /**
     * 
     * @type {MedicalDatasetCreateRequestDTO}
     * @memberof MedicalResourceApiCreateDatasetMedicalUsingPOST
     */
    readonly medicalDatasetCreateRequestDTO?: MedicalDatasetCreateRequestDTO
}

/**
 * Request parameters for createDicomServerUsingPOST operation in MedicalResourceApi.
 * @export
 * @interface MedicalResourceApiCreateDicomServerUsingPOSTRequest
 */
export interface MedicalResourceApiCreateDicomServerUsingPOSTRequest {
    /**
     * 
     * @type {DicomServerRequestDTO}
     * @memberof MedicalResourceApiCreateDicomServerUsingPOST
     */
    readonly dicomServerRequestDTO?: DicomServerRequestDTO
}

/**
 * Request parameters for createMedicalCloudUsingPOST operation in MedicalResourceApi.
 * @export
 * @interface MedicalResourceApiCreateMedicalCloudUsingPOSTRequest
 */
export interface MedicalResourceApiCreateMedicalCloudUsingPOSTRequest {
    /**
     * 
     * @type {MedicalCloudDTO}
     * @memberof MedicalResourceApiCreateMedicalCloudUsingPOST
     */
    readonly medicalCloudDTO?: MedicalCloudDTO
}

/**
 * Request parameters for getFileContentMedicalUsingPOST operation in MedicalResourceApi.
 * @export
 * @interface MedicalResourceApiGetFileContentMedicalUsingPOSTRequest
 */
export interface MedicalResourceApiGetFileContentMedicalUsingPOSTRequest {
    /**
     * 
     * @type {string}
     * @memberof MedicalResourceApiGetFileContentMedicalUsingPOST
     */
    readonly instanceUid: string

    /**
     * 
     * @type {string}
     * @memberof MedicalResourceApiGetFileContentMedicalUsingPOST
     */
    readonly dicomServerId?: string

    /**
     * 
     * @type {number}
     * @memberof MedicalResourceApiGetFileContentMedicalUsingPOST
     */
    readonly fileInfoId?: number
}

/**
 * Request parameters for getFilesMetadataMedicalUsingPOST operation in MedicalResourceApi.
 * @export
 * @interface MedicalResourceApiGetFilesMetadataMedicalUsingPOSTRequest
 */
export interface MedicalResourceApiGetFilesMetadataMedicalUsingPOSTRequest {
    /**
     * 
     * @type {MedicalMetadataRequestDTO}
     * @memberof MedicalResourceApiGetFilesMetadataMedicalUsingPOST
     */
    readonly medicalMetadataRequestDTO?: MedicalMetadataRequestDTO
}

/**
 * Request parameters for searchFilesMedicalUsingPOST operation in MedicalResourceApi.
 * @export
 * @interface MedicalResourceApiSearchFilesMedicalUsingPOSTRequest
 */
export interface MedicalResourceApiSearchFilesMedicalUsingPOSTRequest {
    /**
     * 
     * @type {MedicalSearchRequestDTO}
     * @memberof MedicalResourceApiSearchFilesMedicalUsingPOST
     */
    readonly medicalSearchRequestDTO?: MedicalSearchRequestDTO
}

/**
 * Request parameters for uploadArchiveFileMedicalUsingPOST operation in MedicalResourceApi.
 * @export
 * @interface MedicalResourceApiUploadArchiveFileMedicalUsingPOSTRequest
 */
export interface MedicalResourceApiUploadArchiveFileMedicalUsingPOSTRequest {
    /**
     * 
     * @type {UploadArchiveMedicalRequestDTO}
     * @memberof MedicalResourceApiUploadArchiveFileMedicalUsingPOST
     */
    readonly uploadArchiveMedicalRequestDTO?: UploadArchiveMedicalRequestDTO
}

/**
 * Request parameters for uploadFilesMedicalUsingPOST operation in MedicalResourceApi.
 * @export
 * @interface MedicalResourceApiUploadFilesMedicalUsingPOSTRequest
 */
export interface MedicalResourceApiUploadFilesMedicalUsingPOSTRequest {
    /**
     * 
     * @type {string}
     * @memberof MedicalResourceApiUploadFilesMedicalUsingPOST
     */
    readonly dicomServerId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalResourceApiUploadFilesMedicalUsingPOST
     */
    readonly storageResourceIds?: Array<string>

    /**
     * 
     * @type {Array<File>}
     * @memberof MedicalResourceApiUploadFilesMedicalUsingPOST
     */
    readonly files?: Array<File>
}

/**
 * MedicalResourceApi - object-oriented interface
 * @export
 * @class MedicalResourceApi
 * @extends {BaseAPI}
 */
export class MedicalResourceApi extends BaseAPI {
    /**
     * 
     * @param {MedicalResourceApiCreateDatasetMedicalUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalResourceApi
     */
    public createDatasetMedicalUsingPOST(requestParameters: MedicalResourceApiCreateDatasetMedicalUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return MedicalResourceApiFp(this.configuration).createDatasetMedicalUsingPOST(requestParameters.medicalDatasetCreateRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalResourceApiCreateDicomServerUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalResourceApi
     */
    public createDicomServerUsingPOST(requestParameters: MedicalResourceApiCreateDicomServerUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return MedicalResourceApiFp(this.configuration).createDicomServerUsingPOST(requestParameters.dicomServerRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalResourceApiCreateMedicalCloudUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalResourceApi
     */
    public createMedicalCloudUsingPOST(requestParameters: MedicalResourceApiCreateMedicalCloudUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return MedicalResourceApiFp(this.configuration).createMedicalCloudUsingPOST(requestParameters.medicalCloudDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalResourceApi
     */
    public getAllDicomServerUsingGET(options?: AxiosRequestConfig) {
        return MedicalResourceApiFp(this.configuration).getAllDicomServerUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalResourceApiGetFileContentMedicalUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalResourceApi
     */
    public getFileContentMedicalUsingPOST(requestParameters: MedicalResourceApiGetFileContentMedicalUsingPOSTRequest, options?: AxiosRequestConfig) {
        return MedicalResourceApiFp(this.configuration).getFileContentMedicalUsingPOST(requestParameters.instanceUid, requestParameters.dicomServerId, requestParameters.fileInfoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalResourceApiGetFilesMetadataMedicalUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalResourceApi
     */
    public getFilesMetadataMedicalUsingPOST(requestParameters: MedicalResourceApiGetFilesMetadataMedicalUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return MedicalResourceApiFp(this.configuration).getFilesMetadataMedicalUsingPOST(requestParameters.medicalMetadataRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalResourceApiSearchFilesMedicalUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalResourceApi
     */
    public searchFilesMedicalUsingPOST(requestParameters: MedicalResourceApiSearchFilesMedicalUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return MedicalResourceApiFp(this.configuration).searchFilesMedicalUsingPOST(requestParameters.medicalSearchRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalResourceApiUploadArchiveFileMedicalUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalResourceApi
     */
    public uploadArchiveFileMedicalUsingPOST(requestParameters: MedicalResourceApiUploadArchiveFileMedicalUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return MedicalResourceApiFp(this.configuration).uploadArchiveFileMedicalUsingPOST(requestParameters.uploadArchiveMedicalRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalResourceApiUploadFilesMedicalUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalResourceApi
     */
    public uploadFilesMedicalUsingPOST(requestParameters: MedicalResourceApiUploadFilesMedicalUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return MedicalResourceApiFp(this.configuration).uploadFilesMedicalUsingPOST(requestParameters.dicomServerId, requestParameters.storageResourceIds, requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetadataResourceApi - axios parameter creator
 * @export
 */
export const MetadataResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createMetadataKey
         * @param {Array<MetadataKeyDTO>} [metadataKeyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMetadataKeyPOST: async (metadataKeyDTO?: Array<MetadataKeyDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/metadata-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metadataKeyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createMetadataValue
         * @param {Array<MetadataValueDTO>} [metadataValueDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMetadataValuePOST: async (metadataValueDTO?: Array<MetadataValueDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/metadata-values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metadataValueDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteMetadataKey
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMetadataKeyUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMetadataKeyUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/metadata-key/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteMetadataValue
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMetadataValueUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMetadataValueUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/metadata-value/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllMetadataKeys
         * @param {MetadataKeyCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMetadataKeysUsingGET: async (criteria?: MetadataKeyCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/metadata-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllMetadataValuesByKey
         * @param {number} keyId keyId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMetadataValuesByKeyUsingGET: async (keyId: number, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyId' is not null or undefined
            assertParamExists('getAllMetadataValuesByKeyUsingGET', 'keyId', keyId)
            const localVarPath = `/api/v1/metadata-values-by-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyId !== undefined) {
                localVarQueryParameter['keyId'] = keyId;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllMetadataValues
         * @param {MetadataValueCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMetadataValuesUsingGET: async (criteria?: MetadataValueCriteria, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/metadata-values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getMetadataKey
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetadataKeyUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMetadataKeyUsingGET', 'id', id)
            const localVarPath = `/api/v1/metadata-key/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getMetadataValue
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetadataValueUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMetadataValueUsingGET', 'id', id)
            const localVarPath = `/api/v1/metadata-value/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary manageMetadata
         * @param {Array<FileInfoMetadataRequestDTO>} [fileInfoMetadataRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manageMetadataUsingPOST: async (fileInfoMetadataRequestDTO?: Array<FileInfoMetadataRequestDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/metadata/manage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileInfoMetadataRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateMetadataKey
         * @param {number} id id
         * @param {MetadataKeyDTO} [metadataKeyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateMetadataKeyUsingPATCH: async (id: number, metadataKeyDTO?: MetadataKeyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateMetadataKeyUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/metadata-key/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metadataKeyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateMetadataValue
         * @param {number} id id
         * @param {MetadataValueDTO} [metadataValueDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateMetadataValueUsingPATCH: async (id: number, metadataValueDTO?: MetadataValueDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateMetadataValueUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/metadata-value/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metadataValueDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateMetadataKey
         * @param {number} id id
         * @param {MetadataKeyDTO} [metadataKeyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetadataKeyUsingPUT: async (id: number, metadataKeyDTO?: MetadataKeyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMetadataKeyUsingPUT', 'id', id)
            const localVarPath = `/api/v1/metadata-key/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metadataKeyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateMetadataValue
         * @param {number} id id
         * @param {MetadataValueDTO} [metadataValueDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetadataValueUsingPUT: async (id: number, metadataValueDTO?: MetadataValueDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMetadataValueUsingPUT', 'id', id)
            const localVarPath = `/api/v1/metadata-value/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metadataValueDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetadataResourceApi - functional programming interface
 * @export
 */
export const MetadataResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetadataResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createMetadataKey
         * @param {Array<MetadataKeyDTO>} [metadataKeyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMetadataKeyPOST(metadataKeyDTO?: Array<MetadataKeyDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfMetadataKeyDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMetadataKeyPOST(metadataKeyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createMetadataValue
         * @param {Array<MetadataValueDTO>} [metadataValueDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMetadataValuePOST(metadataValueDTO?: Array<MetadataValueDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfMetadataValueDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMetadataValuePOST(metadataValueDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteMetadataKey
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMetadataKeyUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMetadataKeyUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteMetadataValue
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMetadataValueUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMetadataValueUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllMetadataKeys
         * @param {MetadataKeyCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMetadataKeysUsingGET(criteria?: MetadataKeyCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfMetadataKeyDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMetadataKeysUsingGET(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllMetadataValuesByKey
         * @param {number} keyId keyId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMetadataValuesByKeyUsingGET(keyId: number, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfMetadataValueDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMetadataValuesByKeyUsingGET(keyId, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllMetadataValues
         * @param {MetadataValueCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMetadataValuesUsingGET(criteria?: MetadataValueCriteria, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfMetadataValueDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMetadataValuesUsingGET(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getMetadataKey
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetadataKeyUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfMetadataKeyDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetadataKeyUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getMetadataValue
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetadataValueUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfMetadataValueDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetadataValueUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary manageMetadata
         * @param {Array<FileInfoMetadataRequestDTO>} [fileInfoMetadataRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manageMetadataUsingPOST(fileInfoMetadataRequestDTO?: Array<FileInfoMetadataRequestDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manageMetadataUsingPOST(fileInfoMetadataRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateMetadataKey
         * @param {number} id id
         * @param {MetadataKeyDTO} [metadataKeyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateMetadataKeyUsingPATCH(id: number, metadataKeyDTO?: MetadataKeyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfMetadataKeyDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateMetadataKeyUsingPATCH(id, metadataKeyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateMetadataValue
         * @param {number} id id
         * @param {MetadataValueDTO} [metadataValueDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateMetadataValueUsingPATCH(id: number, metadataValueDTO?: MetadataValueDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfMetadataValueDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateMetadataValueUsingPATCH(id, metadataValueDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateMetadataKey
         * @param {number} id id
         * @param {MetadataKeyDTO} [metadataKeyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMetadataKeyUsingPUT(id: number, metadataKeyDTO?: MetadataKeyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfMetadataKeyDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMetadataKeyUsingPUT(id, metadataKeyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateMetadataValue
         * @param {number} id id
         * @param {MetadataValueDTO} [metadataValueDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMetadataValueUsingPUT(id: number, metadataValueDTO?: MetadataValueDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfMetadataValueDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMetadataValueUsingPUT(id, metadataValueDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetadataResourceApi - factory interface
 * @export
 */
export const MetadataResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetadataResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary createMetadataKey
         * @param {Array<MetadataKeyDTO>} [metadataKeyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMetadataKeyPOST(metadataKeyDTO?: Array<MetadataKeyDTO>, options?: any): AxiosPromise<RestResponseOfListOfMetadataKeyDTO> {
            return localVarFp.createMetadataKeyPOST(metadataKeyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createMetadataValue
         * @param {Array<MetadataValueDTO>} [metadataValueDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMetadataValuePOST(metadataValueDTO?: Array<MetadataValueDTO>, options?: any): AxiosPromise<RestResponseOfListOfMetadataValueDTO> {
            return localVarFp.createMetadataValuePOST(metadataValueDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteMetadataKey
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMetadataKeyUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteMetadataKeyUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteMetadataValue
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMetadataValueUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteMetadataValueUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllMetadataKeys
         * @param {MetadataKeyCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMetadataKeysUsingGET(criteria?: MetadataKeyCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfMetadataKeyDTO> {
            return localVarFp.getAllMetadataKeysUsingGET(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllMetadataValuesByKey
         * @param {number} keyId keyId
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMetadataValuesByKeyUsingGET(keyId: number, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfMetadataValueDTO> {
            return localVarFp.getAllMetadataValuesByKeyUsingGET(keyId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllMetadataValues
         * @param {MetadataValueCriteria} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMetadataValuesUsingGET(criteria?: MetadataValueCriteria, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfMetadataValueDTO> {
            return localVarFp.getAllMetadataValuesUsingGET(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getMetadataKey
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetadataKeyUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfMetadataKeyDTO> {
            return localVarFp.getMetadataKeyUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getMetadataValue
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetadataValueUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfMetadataValueDTO> {
            return localVarFp.getMetadataValueUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary manageMetadata
         * @param {Array<FileInfoMetadataRequestDTO>} [fileInfoMetadataRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manageMetadataUsingPOST(fileInfoMetadataRequestDTO?: Array<FileInfoMetadataRequestDTO>, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.manageMetadataUsingPOST(fileInfoMetadataRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateMetadataKey
         * @param {number} id id
         * @param {MetadataKeyDTO} [metadataKeyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateMetadataKeyUsingPATCH(id: number, metadataKeyDTO?: MetadataKeyDTO, options?: any): AxiosPromise<RestResponseOfMetadataKeyDTO> {
            return localVarFp.partialUpdateMetadataKeyUsingPATCH(id, metadataKeyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateMetadataValue
         * @param {number} id id
         * @param {MetadataValueDTO} [metadataValueDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateMetadataValueUsingPATCH(id: number, metadataValueDTO?: MetadataValueDTO, options?: any): AxiosPromise<RestResponseOfMetadataValueDTO> {
            return localVarFp.partialUpdateMetadataValueUsingPATCH(id, metadataValueDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateMetadataKey
         * @param {number} id id
         * @param {MetadataKeyDTO} [metadataKeyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetadataKeyUsingPUT(id: number, metadataKeyDTO?: MetadataKeyDTO, options?: any): AxiosPromise<RestResponseOfMetadataKeyDTO> {
            return localVarFp.updateMetadataKeyUsingPUT(id, metadataKeyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateMetadataValue
         * @param {number} id id
         * @param {MetadataValueDTO} [metadataValueDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetadataValueUsingPUT(id: number, metadataValueDTO?: MetadataValueDTO, options?: any): AxiosPromise<RestResponseOfMetadataValueDTO> {
            return localVarFp.updateMetadataValueUsingPUT(id, metadataValueDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createMetadataKeyPOST operation in MetadataResourceApi.
 * @export
 * @interface MetadataResourceApiCreateMetadataKeyPOSTRequest
 */
export interface MetadataResourceApiCreateMetadataKeyPOSTRequest {
    /**
     * 
     * @type {Array<MetadataKeyDTO>}
     * @memberof MetadataResourceApiCreateMetadataKeyPOST
     */
    readonly metadataKeyDTO?: Array<MetadataKeyDTO>
}

/**
 * Request parameters for createMetadataValuePOST operation in MetadataResourceApi.
 * @export
 * @interface MetadataResourceApiCreateMetadataValuePOSTRequest
 */
export interface MetadataResourceApiCreateMetadataValuePOSTRequest {
    /**
     * 
     * @type {Array<MetadataValueDTO>}
     * @memberof MetadataResourceApiCreateMetadataValuePOST
     */
    readonly metadataValueDTO?: Array<MetadataValueDTO>
}

/**
 * Request parameters for deleteMetadataKeyUsingDELETE operation in MetadataResourceApi.
 * @export
 * @interface MetadataResourceApiDeleteMetadataKeyUsingDELETERequest
 */
export interface MetadataResourceApiDeleteMetadataKeyUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof MetadataResourceApiDeleteMetadataKeyUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for deleteMetadataValueUsingDELETE operation in MetadataResourceApi.
 * @export
 * @interface MetadataResourceApiDeleteMetadataValueUsingDELETERequest
 */
export interface MetadataResourceApiDeleteMetadataValueUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof MetadataResourceApiDeleteMetadataValueUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for getAllMetadataKeysUsingGET operation in MetadataResourceApi.
 * @export
 * @interface MetadataResourceApiGetAllMetadataKeysUsingGETRequest
 */
export interface MetadataResourceApiGetAllMetadataKeysUsingGETRequest {
    /**
     * criteria
     * @type {MetadataKeyCriteria}
     * @memberof MetadataResourceApiGetAllMetadataKeysUsingGET
     */
    readonly criteria?: MetadataKeyCriteria

    /**
     * pageable
     * @type {Pageable}
     * @memberof MetadataResourceApiGetAllMetadataKeysUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getAllMetadataValuesByKeyUsingGET operation in MetadataResourceApi.
 * @export
 * @interface MetadataResourceApiGetAllMetadataValuesByKeyUsingGETRequest
 */
export interface MetadataResourceApiGetAllMetadataValuesByKeyUsingGETRequest {
    /**
     * keyId
     * @type {number}
     * @memberof MetadataResourceApiGetAllMetadataValuesByKeyUsingGET
     */
    readonly keyId: number

    /**
     * pageable
     * @type {Pageable}
     * @memberof MetadataResourceApiGetAllMetadataValuesByKeyUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getAllMetadataValuesUsingGET operation in MetadataResourceApi.
 * @export
 * @interface MetadataResourceApiGetAllMetadataValuesUsingGETRequest
 */
export interface MetadataResourceApiGetAllMetadataValuesUsingGETRequest {
    /**
     * criteria
     * @type {MetadataValueCriteria}
     * @memberof MetadataResourceApiGetAllMetadataValuesUsingGET
     */
    readonly criteria?: MetadataValueCriteria

    /**
     * pageable
     * @type {Pageable}
     * @memberof MetadataResourceApiGetAllMetadataValuesUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getMetadataKeyUsingGET operation in MetadataResourceApi.
 * @export
 * @interface MetadataResourceApiGetMetadataKeyUsingGETRequest
 */
export interface MetadataResourceApiGetMetadataKeyUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof MetadataResourceApiGetMetadataKeyUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for getMetadataValueUsingGET operation in MetadataResourceApi.
 * @export
 * @interface MetadataResourceApiGetMetadataValueUsingGETRequest
 */
export interface MetadataResourceApiGetMetadataValueUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof MetadataResourceApiGetMetadataValueUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for manageMetadataUsingPOST operation in MetadataResourceApi.
 * @export
 * @interface MetadataResourceApiManageMetadataUsingPOSTRequest
 */
export interface MetadataResourceApiManageMetadataUsingPOSTRequest {
    /**
     * 
     * @type {Array<FileInfoMetadataRequestDTO>}
     * @memberof MetadataResourceApiManageMetadataUsingPOST
     */
    readonly fileInfoMetadataRequestDTO?: Array<FileInfoMetadataRequestDTO>
}

/**
 * Request parameters for partialUpdateMetadataKeyUsingPATCH operation in MetadataResourceApi.
 * @export
 * @interface MetadataResourceApiPartialUpdateMetadataKeyUsingPATCHRequest
 */
export interface MetadataResourceApiPartialUpdateMetadataKeyUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof MetadataResourceApiPartialUpdateMetadataKeyUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {MetadataKeyDTO}
     * @memberof MetadataResourceApiPartialUpdateMetadataKeyUsingPATCH
     */
    readonly metadataKeyDTO?: MetadataKeyDTO
}

/**
 * Request parameters for partialUpdateMetadataValueUsingPATCH operation in MetadataResourceApi.
 * @export
 * @interface MetadataResourceApiPartialUpdateMetadataValueUsingPATCHRequest
 */
export interface MetadataResourceApiPartialUpdateMetadataValueUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof MetadataResourceApiPartialUpdateMetadataValueUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {MetadataValueDTO}
     * @memberof MetadataResourceApiPartialUpdateMetadataValueUsingPATCH
     */
    readonly metadataValueDTO?: MetadataValueDTO
}

/**
 * Request parameters for updateMetadataKeyUsingPUT operation in MetadataResourceApi.
 * @export
 * @interface MetadataResourceApiUpdateMetadataKeyUsingPUTRequest
 */
export interface MetadataResourceApiUpdateMetadataKeyUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof MetadataResourceApiUpdateMetadataKeyUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {MetadataKeyDTO}
     * @memberof MetadataResourceApiUpdateMetadataKeyUsingPUT
     */
    readonly metadataKeyDTO?: MetadataKeyDTO
}

/**
 * Request parameters for updateMetadataValueUsingPUT operation in MetadataResourceApi.
 * @export
 * @interface MetadataResourceApiUpdateMetadataValueUsingPUTRequest
 */
export interface MetadataResourceApiUpdateMetadataValueUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof MetadataResourceApiUpdateMetadataValueUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {MetadataValueDTO}
     * @memberof MetadataResourceApiUpdateMetadataValueUsingPUT
     */
    readonly metadataValueDTO?: MetadataValueDTO
}

/**
 * MetadataResourceApi - object-oriented interface
 * @export
 * @class MetadataResourceApi
 * @extends {BaseAPI}
 */
export class MetadataResourceApi extends BaseAPI {
    /**
     * 
     * @summary createMetadataKey
     * @param {MetadataResourceApiCreateMetadataKeyPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataResourceApi
     */
    public createMetadataKeyPOST(requestParameters: MetadataResourceApiCreateMetadataKeyPOSTRequest = {}, options?: AxiosRequestConfig) {
        return MetadataResourceApiFp(this.configuration).createMetadataKeyPOST(requestParameters.metadataKeyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createMetadataValue
     * @param {MetadataResourceApiCreateMetadataValuePOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataResourceApi
     */
    public createMetadataValuePOST(requestParameters: MetadataResourceApiCreateMetadataValuePOSTRequest = {}, options?: AxiosRequestConfig) {
        return MetadataResourceApiFp(this.configuration).createMetadataValuePOST(requestParameters.metadataValueDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteMetadataKey
     * @param {MetadataResourceApiDeleteMetadataKeyUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataResourceApi
     */
    public deleteMetadataKeyUsingDELETE(requestParameters: MetadataResourceApiDeleteMetadataKeyUsingDELETERequest, options?: AxiosRequestConfig) {
        return MetadataResourceApiFp(this.configuration).deleteMetadataKeyUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteMetadataValue
     * @param {MetadataResourceApiDeleteMetadataValueUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataResourceApi
     */
    public deleteMetadataValueUsingDELETE(requestParameters: MetadataResourceApiDeleteMetadataValueUsingDELETERequest, options?: AxiosRequestConfig) {
        return MetadataResourceApiFp(this.configuration).deleteMetadataValueUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllMetadataKeys
     * @param {MetadataResourceApiGetAllMetadataKeysUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataResourceApi
     */
    public getAllMetadataKeysUsingGET(requestParameters: MetadataResourceApiGetAllMetadataKeysUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return MetadataResourceApiFp(this.configuration).getAllMetadataKeysUsingGET(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllMetadataValuesByKey
     * @param {MetadataResourceApiGetAllMetadataValuesByKeyUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataResourceApi
     */
    public getAllMetadataValuesByKeyUsingGET(requestParameters: MetadataResourceApiGetAllMetadataValuesByKeyUsingGETRequest, options?: AxiosRequestConfig) {
        return MetadataResourceApiFp(this.configuration).getAllMetadataValuesByKeyUsingGET(requestParameters.keyId, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllMetadataValues
     * @param {MetadataResourceApiGetAllMetadataValuesUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataResourceApi
     */
    public getAllMetadataValuesUsingGET(requestParameters: MetadataResourceApiGetAllMetadataValuesUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return MetadataResourceApiFp(this.configuration).getAllMetadataValuesUsingGET(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getMetadataKey
     * @param {MetadataResourceApiGetMetadataKeyUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataResourceApi
     */
    public getMetadataKeyUsingGET(requestParameters: MetadataResourceApiGetMetadataKeyUsingGETRequest, options?: AxiosRequestConfig) {
        return MetadataResourceApiFp(this.configuration).getMetadataKeyUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getMetadataValue
     * @param {MetadataResourceApiGetMetadataValueUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataResourceApi
     */
    public getMetadataValueUsingGET(requestParameters: MetadataResourceApiGetMetadataValueUsingGETRequest, options?: AxiosRequestConfig) {
        return MetadataResourceApiFp(this.configuration).getMetadataValueUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary manageMetadata
     * @param {MetadataResourceApiManageMetadataUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataResourceApi
     */
    public manageMetadataUsingPOST(requestParameters: MetadataResourceApiManageMetadataUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return MetadataResourceApiFp(this.configuration).manageMetadataUsingPOST(requestParameters.fileInfoMetadataRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateMetadataKey
     * @param {MetadataResourceApiPartialUpdateMetadataKeyUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataResourceApi
     */
    public partialUpdateMetadataKeyUsingPATCH(requestParameters: MetadataResourceApiPartialUpdateMetadataKeyUsingPATCHRequest, options?: AxiosRequestConfig) {
        return MetadataResourceApiFp(this.configuration).partialUpdateMetadataKeyUsingPATCH(requestParameters.id, requestParameters.metadataKeyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateMetadataValue
     * @param {MetadataResourceApiPartialUpdateMetadataValueUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataResourceApi
     */
    public partialUpdateMetadataValueUsingPATCH(requestParameters: MetadataResourceApiPartialUpdateMetadataValueUsingPATCHRequest, options?: AxiosRequestConfig) {
        return MetadataResourceApiFp(this.configuration).partialUpdateMetadataValueUsingPATCH(requestParameters.id, requestParameters.metadataValueDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateMetadataKey
     * @param {MetadataResourceApiUpdateMetadataKeyUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataResourceApi
     */
    public updateMetadataKeyUsingPUT(requestParameters: MetadataResourceApiUpdateMetadataKeyUsingPUTRequest, options?: AxiosRequestConfig) {
        return MetadataResourceApiFp(this.configuration).updateMetadataKeyUsingPUT(requestParameters.id, requestParameters.metadataKeyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateMetadataValue
     * @param {MetadataResourceApiUpdateMetadataValueUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataResourceApi
     */
    public updateMetadataValueUsingPUT(requestParameters: MetadataResourceApiUpdateMetadataValueUsingPUTRequest, options?: AxiosRequestConfig) {
        return MetadataResourceApiFp(this.configuration).updateMetadataValueUsingPUT(requestParameters.id, requestParameters.metadataValueDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScopeResourceApi - axios parameter creator
 * @export
 */
export const ScopeResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary countScopes
         * @param {object} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countScopesUsingGET: async (criteria?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/scopes/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createScope
         * @param {ScopeDTO} [scopeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScopeUsingPOST: async (scopeDTO?: ScopeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/scopes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scopeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteScope
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScopeUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteScopeUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/scopes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllScopes
         * @param {object} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllScopesUsingGET: async (criteria?: object, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/scopes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (criteria !== undefined) {
                localVarQueryParameter['criteria'] = criteria;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getScope
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopeUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScopeUsingGET', 'id', id)
            const localVarPath = `/api/v1/scopes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateScope
         * @param {number} id id
         * @param {ScopeDTO} [scopeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateScopeUsingPATCH: async (id: number, scopeDTO?: ScopeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateScopeUsingPATCH', 'id', id)
            const localVarPath = `/api/v1/scopes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scopeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateScope
         * @param {number} id id
         * @param {ScopeDTO} [scopeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScopeUsingPUT: async (id: number, scopeDTO?: ScopeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateScopeUsingPUT', 'id', id)
            const localVarPath = `/api/v1/scopes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scopeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScopeResourceApi - functional programming interface
 * @export
 */
export const ScopeResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScopeResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary countScopes
         * @param {object} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countScopesUsingGET(criteria?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfLong>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countScopesUsingGET(criteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createScope
         * @param {ScopeDTO} [scopeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScopeUsingPOST(scopeDTO?: ScopeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfScopeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScopeUsingPOST(scopeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteScope
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScopeUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScopeUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllScopes
         * @param {object} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllScopesUsingGET(criteria?: object, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfListOfScopeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllScopesUsingGET(criteria, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getScope
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScopeUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfScopeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScopeUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partialUpdateScope
         * @param {number} id id
         * @param {ScopeDTO} [scopeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateScopeUsingPATCH(id: number, scopeDTO?: ScopeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfScopeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateScopeUsingPATCH(id, scopeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateScope
         * @param {number} id id
         * @param {ScopeDTO} [scopeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateScopeUsingPUT(id: number, scopeDTO?: ScopeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfScopeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateScopeUsingPUT(id, scopeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScopeResourceApi - factory interface
 * @export
 */
export const ScopeResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScopeResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary countScopes
         * @param {object} [criteria] criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countScopesUsingGET(criteria?: object, options?: any): AxiosPromise<RestResponseOfLong> {
            return localVarFp.countScopesUsingGET(criteria, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createScope
         * @param {ScopeDTO} [scopeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScopeUsingPOST(scopeDTO?: ScopeDTO, options?: any): AxiosPromise<RestResponseOfScopeDTO> {
            return localVarFp.createScopeUsingPOST(scopeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteScope
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScopeUsingDELETE(id: number, options?: any): AxiosPromise<RestResponseOfObject> {
            return localVarFp.deleteScopeUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllScopes
         * @param {object} [criteria] criteria
         * @param {Pageable} [pageable] pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllScopesUsingGET(criteria?: object, pageable?: Pageable, options?: any): AxiosPromise<RestResponseOfListOfScopeDTO> {
            return localVarFp.getAllScopesUsingGET(criteria, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getScope
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopeUsingGET(id: number, options?: any): AxiosPromise<RestResponseOfScopeDTO> {
            return localVarFp.getScopeUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partialUpdateScope
         * @param {number} id id
         * @param {ScopeDTO} [scopeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateScopeUsingPATCH(id: number, scopeDTO?: ScopeDTO, options?: any): AxiosPromise<RestResponseOfScopeDTO> {
            return localVarFp.partialUpdateScopeUsingPATCH(id, scopeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateScope
         * @param {number} id id
         * @param {ScopeDTO} [scopeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScopeUsingPUT(id: number, scopeDTO?: ScopeDTO, options?: any): AxiosPromise<RestResponseOfScopeDTO> {
            return localVarFp.updateScopeUsingPUT(id, scopeDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countScopesUsingGET operation in ScopeResourceApi.
 * @export
 * @interface ScopeResourceApiCountScopesUsingGETRequest
 */
export interface ScopeResourceApiCountScopesUsingGETRequest {
    /**
     * criteria
     * @type {object}
     * @memberof ScopeResourceApiCountScopesUsingGET
     */
    readonly criteria?: object
}

/**
 * Request parameters for createScopeUsingPOST operation in ScopeResourceApi.
 * @export
 * @interface ScopeResourceApiCreateScopeUsingPOSTRequest
 */
export interface ScopeResourceApiCreateScopeUsingPOSTRequest {
    /**
     * 
     * @type {ScopeDTO}
     * @memberof ScopeResourceApiCreateScopeUsingPOST
     */
    readonly scopeDTO?: ScopeDTO
}

/**
 * Request parameters for deleteScopeUsingDELETE operation in ScopeResourceApi.
 * @export
 * @interface ScopeResourceApiDeleteScopeUsingDELETERequest
 */
export interface ScopeResourceApiDeleteScopeUsingDELETERequest {
    /**
     * id
     * @type {number}
     * @memberof ScopeResourceApiDeleteScopeUsingDELETE
     */
    readonly id: number
}

/**
 * Request parameters for getAllScopesUsingGET operation in ScopeResourceApi.
 * @export
 * @interface ScopeResourceApiGetAllScopesUsingGETRequest
 */
export interface ScopeResourceApiGetAllScopesUsingGETRequest {
    /**
     * criteria
     * @type {object}
     * @memberof ScopeResourceApiGetAllScopesUsingGET
     */
    readonly criteria?: object

    /**
     * pageable
     * @type {Pageable}
     * @memberof ScopeResourceApiGetAllScopesUsingGET
     */
    readonly pageable?: Pageable
}

/**
 * Request parameters for getScopeUsingGET operation in ScopeResourceApi.
 * @export
 * @interface ScopeResourceApiGetScopeUsingGETRequest
 */
export interface ScopeResourceApiGetScopeUsingGETRequest {
    /**
     * id
     * @type {number}
     * @memberof ScopeResourceApiGetScopeUsingGET
     */
    readonly id: number
}

/**
 * Request parameters for partialUpdateScopeUsingPATCH operation in ScopeResourceApi.
 * @export
 * @interface ScopeResourceApiPartialUpdateScopeUsingPATCHRequest
 */
export interface ScopeResourceApiPartialUpdateScopeUsingPATCHRequest {
    /**
     * id
     * @type {number}
     * @memberof ScopeResourceApiPartialUpdateScopeUsingPATCH
     */
    readonly id: number

    /**
     * 
     * @type {ScopeDTO}
     * @memberof ScopeResourceApiPartialUpdateScopeUsingPATCH
     */
    readonly scopeDTO?: ScopeDTO
}

/**
 * Request parameters for updateScopeUsingPUT operation in ScopeResourceApi.
 * @export
 * @interface ScopeResourceApiUpdateScopeUsingPUTRequest
 */
export interface ScopeResourceApiUpdateScopeUsingPUTRequest {
    /**
     * id
     * @type {number}
     * @memberof ScopeResourceApiUpdateScopeUsingPUT
     */
    readonly id: number

    /**
     * 
     * @type {ScopeDTO}
     * @memberof ScopeResourceApiUpdateScopeUsingPUT
     */
    readonly scopeDTO?: ScopeDTO
}

/**
 * ScopeResourceApi - object-oriented interface
 * @export
 * @class ScopeResourceApi
 * @extends {BaseAPI}
 */
export class ScopeResourceApi extends BaseAPI {
    /**
     * 
     * @summary countScopes
     * @param {ScopeResourceApiCountScopesUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopeResourceApi
     */
    public countScopesUsingGET(requestParameters: ScopeResourceApiCountScopesUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return ScopeResourceApiFp(this.configuration).countScopesUsingGET(requestParameters.criteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createScope
     * @param {ScopeResourceApiCreateScopeUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopeResourceApi
     */
    public createScopeUsingPOST(requestParameters: ScopeResourceApiCreateScopeUsingPOSTRequest = {}, options?: AxiosRequestConfig) {
        return ScopeResourceApiFp(this.configuration).createScopeUsingPOST(requestParameters.scopeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteScope
     * @param {ScopeResourceApiDeleteScopeUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopeResourceApi
     */
    public deleteScopeUsingDELETE(requestParameters: ScopeResourceApiDeleteScopeUsingDELETERequest, options?: AxiosRequestConfig) {
        return ScopeResourceApiFp(this.configuration).deleteScopeUsingDELETE(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllScopes
     * @param {ScopeResourceApiGetAllScopesUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopeResourceApi
     */
    public getAllScopesUsingGET(requestParameters: ScopeResourceApiGetAllScopesUsingGETRequest = {}, options?: AxiosRequestConfig) {
        return ScopeResourceApiFp(this.configuration).getAllScopesUsingGET(requestParameters.criteria, requestParameters.pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getScope
     * @param {ScopeResourceApiGetScopeUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopeResourceApi
     */
    public getScopeUsingGET(requestParameters: ScopeResourceApiGetScopeUsingGETRequest, options?: AxiosRequestConfig) {
        return ScopeResourceApiFp(this.configuration).getScopeUsingGET(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partialUpdateScope
     * @param {ScopeResourceApiPartialUpdateScopeUsingPATCHRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopeResourceApi
     */
    public partialUpdateScopeUsingPATCH(requestParameters: ScopeResourceApiPartialUpdateScopeUsingPATCHRequest, options?: AxiosRequestConfig) {
        return ScopeResourceApiFp(this.configuration).partialUpdateScopeUsingPATCH(requestParameters.id, requestParameters.scopeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateScope
     * @param {ScopeResourceApiUpdateScopeUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopeResourceApi
     */
    public updateScopeUsingPUT(requestParameters: ScopeResourceApiUpdateScopeUsingPUTRequest, options?: AxiosRequestConfig) {
        return ScopeResourceApiFp(this.configuration).updateScopeUsingPUT(requestParameters.id, requestParameters.scopeDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageKafkaResourceApi - axios parameter creator
 * @export
 */
export const StorageKafkaResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary consume
         * @param {Array<string>} topic topic
         * @param {{ [key: string]: string; }} consumerParams consumerParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeUsingGET: async (topic: Array<string>, consumerParams: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topic' is not null or undefined
            assertParamExists('consumeUsingGET', 'topic', topic)
            // verify required parameter 'consumerParams' is not null or undefined
            assertParamExists('consumeUsingGET', 'consumerParams', consumerParams)
            const localVarPath = `/api/v1/consume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (topic) {
                localVarQueryParameter['topic'] = topic;
            }

            if (consumerParams !== undefined) {
                localVarQueryParameter['consumerParams'] = consumerParams;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary publish
         * @param {string} topic topic
         * @param {string} message message
         * @param {string} [key] key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishUsingPOST: async (topic: string, message: string, key?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topic' is not null or undefined
            assertParamExists('publishUsingPOST', 'topic', topic)
            // verify required parameter 'message' is not null or undefined
            assertParamExists('publishUsingPOST', 'message', message)
            const localVarPath = `/api/v1/publish/{topic}`
                .replace(`{${"topic"}}`, encodeURIComponent(String(topic)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageKafkaResourceApi - functional programming interface
 * @export
 */
export const StorageKafkaResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageKafkaResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary consume
         * @param {Array<string>} topic topic
         * @param {{ [key: string]: string; }} consumerParams consumerParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumeUsingGET(topic: Array<string>, consumerParams: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfSseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumeUsingGET(topic, consumerParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary publish
         * @param {string} topic topic
         * @param {string} message message
         * @param {string} [key] key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishUsingPOST(topic: string, message: string, key?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseOfPublishResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishUsingPOST(topic, message, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageKafkaResourceApi - factory interface
 * @export
 */
export const StorageKafkaResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageKafkaResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary consume
         * @param {Array<string>} topic topic
         * @param {{ [key: string]: string; }} consumerParams consumerParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeUsingGET(topic: Array<string>, consumerParams: { [key: string]: string; }, options?: any): AxiosPromise<RestResponseOfSseEmitter> {
            return localVarFp.consumeUsingGET(topic, consumerParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary publish
         * @param {string} topic topic
         * @param {string} message message
         * @param {string} [key] key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishUsingPOST(topic: string, message: string, key?: string, options?: any): AxiosPromise<RestResponseOfPublishResult> {
            return localVarFp.publishUsingPOST(topic, message, key, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for consumeUsingGET operation in StorageKafkaResourceApi.
 * @export
 * @interface StorageKafkaResourceApiConsumeUsingGETRequest
 */
export interface StorageKafkaResourceApiConsumeUsingGETRequest {
    /**
     * topic
     * @type {Array<string>}
     * @memberof StorageKafkaResourceApiConsumeUsingGET
     */
    readonly topic: Array<string>

    /**
     * consumerParams
     * @type {{ [key: string]: string; }}
     * @memberof StorageKafkaResourceApiConsumeUsingGET
     */
    readonly consumerParams: { [key: string]: string; }
}

/**
 * Request parameters for publishUsingPOST operation in StorageKafkaResourceApi.
 * @export
 * @interface StorageKafkaResourceApiPublishUsingPOSTRequest
 */
export interface StorageKafkaResourceApiPublishUsingPOSTRequest {
    /**
     * topic
     * @type {string}
     * @memberof StorageKafkaResourceApiPublishUsingPOST
     */
    readonly topic: string

    /**
     * message
     * @type {string}
     * @memberof StorageKafkaResourceApiPublishUsingPOST
     */
    readonly message: string

    /**
     * key
     * @type {string}
     * @memberof StorageKafkaResourceApiPublishUsingPOST
     */
    readonly key?: string
}

/**
 * StorageKafkaResourceApi - object-oriented interface
 * @export
 * @class StorageKafkaResourceApi
 * @extends {BaseAPI}
 */
export class StorageKafkaResourceApi extends BaseAPI {
    /**
     * 
     * @summary consume
     * @param {StorageKafkaResourceApiConsumeUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageKafkaResourceApi
     */
    public consumeUsingGET(requestParameters: StorageKafkaResourceApiConsumeUsingGETRequest, options?: AxiosRequestConfig) {
        return StorageKafkaResourceApiFp(this.configuration).consumeUsingGET(requestParameters.topic, requestParameters.consumerParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary publish
     * @param {StorageKafkaResourceApiPublishUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageKafkaResourceApi
     */
    public publishUsingPOST(requestParameters: StorageKafkaResourceApiPublishUsingPOSTRequest, options?: AxiosRequestConfig) {
        return StorageKafkaResourceApiFp(this.configuration).publishUsingPOST(requestParameters.topic, requestParameters.message, requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageResourceApi - axios parameter creator
 * @export
 */
export const StorageResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createStorageResource
         * @param {'Labeling' | 'Uploading' | 'Cache'} source resource source
         * @param {'png' | 'jpg' | 'jpeg' | 'dcm' | 'bmp' | 'wav' | 'mp3' | 'txt' | 'json' | 'yaml' | 'zip' | 'tar' | 'gzip' | 'tgz' | 'gz' | 'nii.gz'} [extension] extension of file if needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStorageResourceUsingPOST: async (source: 'Labeling' | 'Uploading' | 'Cache', extension?: 'png' | 'jpg' | 'jpeg' | 'dcm' | 'bmp' | 'wav' | 'mp3' | 'txt' | 'json' | 'yaml' | 'zip' | 'tar' | 'gzip' | 'tgz' | 'gz' | 'nii.gz', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('createStorageResourceUsingPOST', 'source', source)
            const localVarPath = `/api/v1/storage/resource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (extension !== undefined) {
                localVarQueryParameter['extension'] = extension;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getResourceCredential
         * @param {'Labeling' | 'Uploading' | 'Cache'} source resource source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceCredentialUsingGET: async (source: 'Labeling' | 'Uploading' | 'Cache', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('getResourceCredentialUsingGET', 'source', source)
            const localVarPath = `/api/v1/storage/resource/credential`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getStorageResource
         * @param {string} resourceId id of resource to verify
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceUsingGET: async (resourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getResourceUsingGET', 'resourceId', resourceId)
            const localVarPath = `/api/v1/storage/resource/{resourceId}`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get sas token for current workspace
         * @param {SasRequestDTO} [sasRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSasUsingPost: async (sasRequestDTO?: SasRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/storage/resource/sas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sasRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary processStorageResource
         * @param {string} resourceId id of resource to verify
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processResourceUsingPOST: async (resourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('processResourceUsingPOST', 'resourceId', resourceId)
            const localVarPath = `/api/v1/storage/resource/{resourceId}/process`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageResourceApi - functional programming interface
 * @export
 */
export const StorageResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createStorageResource
         * @param {'Labeling' | 'Uploading' | 'Cache'} source resource source
         * @param {'png' | 'jpg' | 'jpeg' | 'dcm' | 'bmp' | 'wav' | 'mp3' | 'txt' | 'json' | 'yaml' | 'zip' | 'tar' | 'gzip' | 'tgz' | 'gz' | 'nii.gz'} [extension] extension of file if needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStorageResourceUsingPOST(source: 'Labeling' | 'Uploading' | 'Cache', extension?: 'png' | 'jpg' | 'jpeg' | 'dcm' | 'bmp' | 'wav' | 'mp3' | 'txt' | 'json' | 'yaml' | 'zip' | 'tar' | 'gzip' | 'tgz' | 'gz' | 'nii.gz', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStorageResourceUsingPOST(source, extension, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getResourceCredential
         * @param {'Labeling' | 'Uploading' | 'Cache'} source resource source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourceCredentialUsingGET(source: 'Labeling' | 'Uploading' | 'Cache', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceCredentialResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourceCredentialUsingGET(source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getStorageResource
         * @param {string} resourceId id of resource to verify
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourceUsingGET(resourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourceUsingGET(resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get sas token for current workspace
         * @param {SasRequestDTO} [sasRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSasUsingPost(sasRequestDTO?: SasRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResourceCredentialResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSasUsingPost(sasRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary processStorageResource
         * @param {string} resourceId id of resource to verify
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processResourceUsingPOST(resourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processResourceUsingPOST(resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageResourceApi - factory interface
 * @export
 */
export const StorageResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary createStorageResource
         * @param {'Labeling' | 'Uploading' | 'Cache'} source resource source
         * @param {'png' | 'jpg' | 'jpeg' | 'dcm' | 'bmp' | 'wav' | 'mp3' | 'txt' | 'json' | 'yaml' | 'zip' | 'tar' | 'gzip' | 'tgz' | 'gz' | 'nii.gz'} [extension] extension of file if needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStorageResourceUsingPOST(source: 'Labeling' | 'Uploading' | 'Cache', extension?: 'png' | 'jpg' | 'jpeg' | 'dcm' | 'bmp' | 'wav' | 'mp3' | 'txt' | 'json' | 'yaml' | 'zip' | 'tar' | 'gzip' | 'tgz' | 'gz' | 'nii.gz', options?: any): AxiosPromise<StorageResource> {
            return localVarFp.createStorageResourceUsingPOST(source, extension, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getResourceCredential
         * @param {'Labeling' | 'Uploading' | 'Cache'} source resource source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceCredentialUsingGET(source: 'Labeling' | 'Uploading' | 'Cache', options?: any): AxiosPromise<ResourceCredentialResponse> {
            return localVarFp.getResourceCredentialUsingGET(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getStorageResource
         * @param {string} resourceId id of resource to verify
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceUsingGET(resourceId: string, options?: any): AxiosPromise<StorageResource> {
            return localVarFp.getResourceUsingGET(resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get sas token for current workspace
         * @param {SasRequestDTO} [sasRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSasUsingPost(sasRequestDTO?: SasRequestDTO, options?: any): AxiosPromise<ListResourceCredentialResponse> {
            return localVarFp.getSasUsingPost(sasRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary processStorageResource
         * @param {string} resourceId id of resource to verify
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processResourceUsingPOST(resourceId: string, options?: any): AxiosPromise<StorageResource> {
            return localVarFp.processResourceUsingPOST(resourceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createStorageResourceUsingPOST operation in StorageResourceApi.
 * @export
 * @interface StorageResourceApiCreateStorageResourceUsingPOSTRequest
 */
export interface StorageResourceApiCreateStorageResourceUsingPOSTRequest {
    /**
     * resource source
     * @type {'Labeling' | 'Uploading' | 'Cache'}
     * @memberof StorageResourceApiCreateStorageResourceUsingPOST
     */
    readonly source: 'Labeling' | 'Uploading' | 'Cache'

    /**
     * extension of file if needed
     * @type {'png' | 'jpg' | 'jpeg' | 'dcm' | 'bmp' | 'wav' | 'mp3' | 'txt' | 'json' | 'yaml' | 'zip' | 'tar' | 'gzip' | 'tgz' | 'gz' | 'nii.gz'}
     * @memberof StorageResourceApiCreateStorageResourceUsingPOST
     */
    readonly extension?: 'png' | 'jpg' | 'jpeg' | 'dcm' | 'bmp' | 'wav' | 'mp3' | 'txt' | 'json' | 'yaml' | 'zip' | 'tar' | 'gzip' | 'tgz' | 'gz' | 'nii.gz'
}

/**
 * Request parameters for getResourceCredentialUsingGET operation in StorageResourceApi.
 * @export
 * @interface StorageResourceApiGetResourceCredentialUsingGETRequest
 */
export interface StorageResourceApiGetResourceCredentialUsingGETRequest {
    /**
     * resource source
     * @type {'Labeling' | 'Uploading' | 'Cache'}
     * @memberof StorageResourceApiGetResourceCredentialUsingGET
     */
    readonly source: 'Labeling' | 'Uploading' | 'Cache'
}

/**
 * Request parameters for getResourceUsingGET operation in StorageResourceApi.
 * @export
 * @interface StorageResourceApiGetResourceUsingGETRequest
 */
export interface StorageResourceApiGetResourceUsingGETRequest {
    /**
     * id of resource to verify
     * @type {string}
     * @memberof StorageResourceApiGetResourceUsingGET
     */
    readonly resourceId: string
}

/**
 * Request parameters for getSasUsingPost operation in StorageResourceApi.
 * @export
 * @interface StorageResourceApiGetSasUsingPostRequest
 */
export interface StorageResourceApiGetSasUsingPostRequest {
    /**
     * 
     * @type {SasRequestDTO}
     * @memberof StorageResourceApiGetSasUsingPost
     */
    readonly sasRequestDTO?: SasRequestDTO
}

/**
 * Request parameters for processResourceUsingPOST operation in StorageResourceApi.
 * @export
 * @interface StorageResourceApiProcessResourceUsingPOSTRequest
 */
export interface StorageResourceApiProcessResourceUsingPOSTRequest {
    /**
     * id of resource to verify
     * @type {string}
     * @memberof StorageResourceApiProcessResourceUsingPOST
     */
    readonly resourceId: string
}

/**
 * StorageResourceApi - object-oriented interface
 * @export
 * @class StorageResourceApi
 * @extends {BaseAPI}
 */
export class StorageResourceApi extends BaseAPI {
    /**
     * 
     * @summary createStorageResource
     * @param {StorageResourceApiCreateStorageResourceUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageResourceApi
     */
    public createStorageResourceUsingPOST(requestParameters: StorageResourceApiCreateStorageResourceUsingPOSTRequest, options?: AxiosRequestConfig) {
        return StorageResourceApiFp(this.configuration).createStorageResourceUsingPOST(requestParameters.source, requestParameters.extension, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getResourceCredential
     * @param {StorageResourceApiGetResourceCredentialUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageResourceApi
     */
    public getResourceCredentialUsingGET(requestParameters: StorageResourceApiGetResourceCredentialUsingGETRequest, options?: AxiosRequestConfig) {
        return StorageResourceApiFp(this.configuration).getResourceCredentialUsingGET(requestParameters.source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getStorageResource
     * @param {StorageResourceApiGetResourceUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageResourceApi
     */
    public getResourceUsingGET(requestParameters: StorageResourceApiGetResourceUsingGETRequest, options?: AxiosRequestConfig) {
        return StorageResourceApiFp(this.configuration).getResourceUsingGET(requestParameters.resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get sas token for current workspace
     * @param {StorageResourceApiGetSasUsingPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageResourceApi
     */
    public getSasUsingPost(requestParameters: StorageResourceApiGetSasUsingPostRequest = {}, options?: AxiosRequestConfig) {
        return StorageResourceApiFp(this.configuration).getSasUsingPost(requestParameters.sasRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary processStorageResource
     * @param {StorageResourceApiProcessResourceUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageResourceApi
     */
    public processResourceUsingPOST(requestParameters: StorageResourceApiProcessResourceUsingPOSTRequest, options?: AxiosRequestConfig) {
        return StorageResourceApiFp(this.configuration).processResourceUsingPOST(requestParameters.resourceId, options).then((request) => request(this.axios, this.basePath));
    }
}


