import { Tooltip } from "@material-ui/core";
import {
  IconCheckboxChecked,
  IconCheckbox,
  IconRadioChecked,
  IconRadio,
} from "components/common/vb-icon.component";
import { useEffect, useMemo, useState } from "react";
import { VBSelectComponent } from "../select-input/select.component";

interface VBRadioInputComponentProps {
  header: string;
  require?: boolean;
  options: string[];
  defaultValues?: string[];
  multiple?: boolean;
  useDropdown?: boolean;
  optionClassName?: string;
  headerClassName?: string;
  onValueChange?(values: string[]): void;
}
export default function VBRadioInputComponent({
  header,
  require,
  options,
  multiple,
  useDropdown = false,
  defaultValues = [],
  optionClassName = "",
  headerClassName="",
  onValueChange,
}: VBRadioInputComponentProps) {
  const [selectedValues, setSelectedValues] = useState<string[]>(defaultValues);
  const allOptions = useMemo(() => {
    return options.map((val) => ({ label: val, value: val }));
  }, [options]);
  const values = useMemo(() => {
    return allOptions.filter((option) => selectedValues.includes(option.value));
  }, [selectedValues, allOptions]);

  function selectValue(val: string) {
    let newValues = [...selectedValues];
    if (newValues.includes(val)) {
      if (multiple) {
        newValues = [...newValues.filter((value) => value !== val)];
      } else {
        newValues = [];
      }
    } else {
      if (multiple) {
        newValues = [...selectedValues, val];
      } else {
        newValues = [val];
      }
    }
    setSelectedValues(newValues);
    onValueChange && onValueChange(newValues);
  }

  function handleOnChange(e: any) {
    if (multiple) {
      const values = e?.map((item: any) => item.value);
      setSelectedValues(values);
      onValueChange && onValueChange(values);
      return;
    }
    const value = e.value;
    setSelectedValues([value]);
    onValueChange && onValueChange([value]);
  }

  useEffect(() => {
    setSelectedValues(defaultValues);
  }, [defaultValues]);

  return (
    <div className={`flex flex-col flex-shrink-0 gap-2 `}>
      {header && (
        <div className="flex items-center gap-1 text-sm font-semibold">
          <span className={headerClassName}>{header}</span>
          {require && (
            <Tooltip title="This field is required">
              <span className="text-red-500">*</span>
            </Tooltip>
          )}
        </div>
      )}
      {!useDropdown && (
        <div className="space-y-2">
          {options.map((option) => {
            const checked = selectedValues.includes(option);
            return (
              <div
                className="flex items-center gap-2 px-3"
                key={option}
              >
                <span
                  className="flex-none cursor-pointer text-warning-500"
                  onClick={() => selectValue(option)}
                >
                  {multiple && checked && (
                    <IconCheckboxChecked className="w-5 h-5" />
                  )}
                  {multiple && !checked && <IconCheckbox className="w-5 h-5" />}

                  {!multiple && checked && (
                    <IconRadioChecked className="w-5 h-5" />
                  )}
                  {!multiple && !checked && <IconRadio className="w-5 h-5" />}
                </span>
                <button
                  className={optionClassName}
                  onClick={() => selectValue(option)}
                >
                  {option}
                </button>
              </div>
            );
          })}
        </div>
      )}

      {useDropdown && (
        <VBSelectComponent
          onChange={handleOnChange}
          isMulti={multiple}
          size={multiple ? "none" : "md"}
          value={multiple ? values : values[0]}
          options={options.map((val) => ({ label: val, value: val }))}
          menuPortalTarget={document.body}
        />
      )}
    </div>
  );
}
