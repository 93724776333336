import { IconCalendar } from "components/common/vb-icon.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import moment from "moment";
import { useState, useEffect } from "react";
import { ResponsiveContainer, BarChart, XAxis, Bar, LabelList } from "recharts";
import { classnames } from "utilities/classes";
import DatePicker from "react-datepicker";
import { createPortal } from "react-dom";

/*
 * File: completed-task-chart.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 28th December 2021 11:52:57 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */
function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
function generateData(totals: number, maxValue: number) {
  let values = [];
  for (let i = 0; i < totals; i++) {
    values.push({
      name: i + 1 + "",
      completed: randomInt(0, maxValue),
    });
  }
  return values;
}

enum FilterMode {
  CUSTOM = "",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}
export const CompletedTaskChart = () => {
  const [data, setData] = useState(() => {
    return generateData(30, 120);
  });
  const [projectId, setProjectId] = useState("");
  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.DAY);
  const projects = [
    { label: "All projects", value: "" },
    { label: "Project 1", value: "proj1" },
    { label: "Project 2", value: "proj2" },
  ];
  const [fromDate, setFromDate] = useState(
    moment(new Date()).startOf("month").toDate()
  );
  const [toDate, setToDate] = useState(new Date());
  const handleDatesChanged = (dates: any) => {
    if (dates && dates.length === 2) {
      const start = dates[0];
      const end = dates[1];
      if (start) setFromDate(start as Date);
      if (end) setToDate(end as Date);
    }
  };
  useEffect(() => {
    setData(generateData(30, 120));
  }, [projectId, filterMode, fromDate, toDate]);

  return (
    <div className="relative flex-auto w-full bg-white rounded shadow h-80">
      <div>
        <h3 className="p-4 font-semibold text-background-700">
          Completed task Analytics (1000 tasks)
        </h3>
        <div className="flex items-center gap-4 px-4 py-2">
          <span className="hidden text-sm text-background-700 xl:block">
            Select project
          </span>
          <div className="flex-none">
            <VBSelectComponent
              defaultValue={projects.find((p) => p.value === projectId)}
              options={projects}
              onChange={(option: any) => setProjectId(option.value as string)}
            />
          </div>
          <div className="flex-1"></div>
          <div className="items-center hidden bg-white border divide-x rounded xl:flex text-warning-500 border-warning-500 divide-warning-500 h-9">
            <button
              onClick={() => setFilterMode(FilterMode.DAY)}
              className={classnames(
                "flex items-center justify-center h-full px-3 py-2",
                {
                  "text-white bg-warning-500": filterMode === FilterMode.DAY,
                }
              )}
            >
              Day
            </button>
            <button
              onClick={() => setFilterMode(FilterMode.WEEK)}
              className={classnames(
                "flex items-center justify-center h-full px-3 py-2",
                {
                  "text-white bg-warning-500": filterMode === FilterMode.WEEK,
                }
              )}
            >
              Week
            </button>
            <button
              onClick={() => setFilterMode(FilterMode.MONTH)}
              className={classnames(
                "flex items-center justify-center h-full px-3 py-2",
                {
                  "text-white bg-warning-500": filterMode === FilterMode.MONTH,
                }
              )}
            >
              Month
            </button>
          </div>

          <div className="flex items-center justify-center flex-none bg-white border rounded h-9 text-warning-500 border-warning-500 px-3 gap-1.5 relative">
            <span className="hidden lg:block">
              {moment(fromDate).format("DD/MM/YY")} -{" "}
              {moment(toDate).format("DD/MM/YY")}
            </span>
            <IconCalendar className="w-5 h-5" />
            <div className="absolute top-0 bottom-0 left-0 right-0 z-10 w-full h-full opacity-0">
              <DatePicker
                className="w-full h-full cursor-pointer"
                dateFormat="dd/LLL/yyyy"
                onChange={handleDatesChanged}
                popperContainer={({ children }) =>
                  createPortal(children, document.body)
                }
                closeOnScroll
                selectsRange
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full h-52">
        <div className="absolute bottom-0 top-4 left-4 right-4">
          <div className="w-full h-full overflow-hidden overflow-x-auto">
            <ResponsiveContainer
              width="100%"
              height="100%"
              minWidth={(24 + 6) * data.length}
            >
              <BarChart
                barGap={6}
                height={300}
                width={800}
                data={data}
                maxBarSize={40}
                margin={{
                  top: 16,
                  right: 16,
                  left: 16,
                  bottom: 16,
                }}
              >
                <XAxis
                  dataKey="name"
                  fontSize={11}
                  tickLine={false}
                  axisLine={false}
                />
                <Bar dataKey="completed" fill="#FFCE7A">
                  <LabelList
                    dataKey="completed"
                    position="top"
                    fontSize={11}
                    fill="#53616D"
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
