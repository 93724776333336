import { useInterval } from "ahooks";
import { useThreeDLabelingContext } from "pages/labeler/three-d-labeling/context/three-d-labeling.context";
import { useEffect, useRef } from "react";

const DEFAULT_TIMEOUT = 10000;

interface UseAutoSaveProps {
  enabled: boolean;
  duration?: number;
}

export const useAutoSaveJob = ({
  enabled = false,
  duration = DEFAULT_TIMEOUT,
}: UseAutoSaveProps) => {
  const { hasChanged, saveJob } = useThreeDLabelingContext();
  const lastSavedRef = useRef(-1);

  useEffect(() => {
    if (enabled && hasChanged) {
      lastSavedRef.current = Date.now();
    } else {
      lastSavedRef.current = -1;
    }
  }, [enabled, hasChanged, lastSavedRef]);

  useInterval(() => {
    if (!enabled) return;
    if (!hasChanged) return;
    if (lastSavedRef.current === -1) return;
    const diff = Date.now() - lastSavedRef.current;
    if (diff < duration) return;
    lastSavedRef.current = -1;
    saveJob(true);
  }, 1000);

  useEffect(() => {
    const handleOnSaveJob = () => {
      lastSavedRef.current = Date.now();
    };
    window.addEventListener("THREE_D_SAVE_JOB", handleOnSaveJob);
    return () => {
      window.removeEventListener("THREE_D_SAVE_JOB", handleOnSaveJob);
    };
  }, [lastSavedRef]);

  return {
    enabled,
    countdown: 5000,
    seconds: 1000,
  };
};
