/*
 * File: batch-table.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 10th September 2021 9:56:27 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { GridColDef } from "@material-ui/data-grid";
import { VBDatagridNoBorder } from "components/common/vb-datagrid/vb-datagrid-no-border.component";
import { BatchDTO } from "services/label-service/dtos";
import {
  IconEdit,
  IconEye,
  IconTrash,
} from "components/common/vb-icon.component";
import { EntityAction } from "domain/common/entity-action";
import { Tooltip } from "@material-ui/core";

interface Props {
  batches: BatchDTO[];
  onSelect?(batch: BatchDTO, action?: EntityAction): void;
}

export const BatchTable = ({ batches, onSelect }: Props) => {
  const { t } = useTranslation();
  const rows = batches.map((batch) => ({
    ...batch,
    batch,
  }));
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "id",
      flex: 1,
      renderCell: (params) => (
        <div
          className="cursor-pointer text-primary"
          onClick={() => onSelect && onSelect(params.row.batch)}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "name",
      headerName: t("project:batch.table.headerName"),
      flex: 1,
    },
    {
      field: "description",
      headerName: t("project:batch.table.headerDescription"),
      flex: 2,
    },
    {
      field: "status",
      headerName: t("project:batch.table.headerStatus"),
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            className={"text-primary cursor-pointer"}
            onClick={() => {
              onSelect &&
                onSelect(params.row.batch, EntityAction.UPDATE_STATUS);
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: t("project:batch.table.headerActions"),
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <BatchItemAction
          onClick={(action) => onSelect && onSelect(params.row.batch, action)}
        />
      ),
    },
  ];
  return (
    <VBDatagridNoBorder
      rows={rows}
      columns={columns}
      autoPageSize
      autoHeight
      disableSelectionOnClick
      onRowDoubleClick={(params) => onSelect && onSelect(params.row.batch)}
    />
  );
};

interface BatchItemActionProps {
  onClick?(action?: EntityAction): void;
}

const BatchItemAction = ({ onClick }: BatchItemActionProps) => {
  const actions = [
    {
      onClick: () => onClick && onClick(EntityAction.VIEW),
      title: "View batch",
      icon: <IconEye className="w-4 h-4 flex-none hover:text-primary" />,
    },
    {
      onClick: () => onClick && onClick(EntityAction.EDIT),
      title: "Edit batch",
      icon: <IconEdit className="w-4 h-4 flex-none hover:text-green-500" />,
    },
    {
      onClick: () => onClick && onClick(EntityAction.DELETE),
      title: "Delete batch",
      icon: <IconTrash className="w-4 h-4 flex-none hover:text-red-500" />,
    },
  ];
  return (
    <div className="flex items-center justify-end gap-4 px-1">
      {actions.map((action) => {
        return (
          <Tooltip key={action.title} title={action.title}>
            <button onClick={action.onClick}>{action.icon}</button>
          </Tooltip>
        );
      })}
    </div>
  );
};
