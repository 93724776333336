/*
 * File: ai-result-gallery.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 22nd February 2022 4:23:11 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useWebBuilderPreviewContext } from "pages/customer/web-builder-preview/context/web-builder-preview.context";
import { AIResultPreview } from "../ai-result-preview/ai-result-preview.component";

export const AIResultGallery = () => {
  const { images, removeFile, selectFile } = useWebBuilderPreviewContext();
  return (
    <div className="flex flex-wrap gap-4">
      {images.map((item) => {
        return (
          <AIResultPreview
            key={item.imageUrl}
            imageUrl={item.imageUrl}
            status={item.status}
            labels={item.labels}
            annotations={item.annotations}
            onRemove={() => removeFile(item.imageUrl)}
            onExpand={() => selectFile(item.imageUrl)}
          />
        );
      })}
    </div>
  );
};
