/*
 * File: label-legend.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 28th December 2021 4:11:38 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { classnames } from "utilities/classes";
import { formatNumber } from "utilities/formatter/number-formatter.utils";

interface Props {
  name: string;
  color: string;
  value: number;
  percent: number;
  postFix?: string;
  active?: boolean;
  onMouseEnter?(name: string): void;
  onMouseLeave?(name: string): void;
}
export const LabelLegend = ({
  active,
  name,
  color,
  value,
  percent,
  postFix,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  function handleMouseEnter() {
    onMouseEnter && onMouseEnter(name);
  }
  function handleMouseLeave() {
    onMouseLeave && onMouseLeave(name);
  }
  return (
    <div
      className="flex items-center flex-none gap-2"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex-none w-4 h-4" style={{ backgroundColor: color }} />
      <span className="flex-auto text-sm">
        <span
          className={classnames({
            "text-background-500": !active,
            "text-primary": !!active,
          })}
        >
          {name}:
        </span>{" "}
        <span
          className={classnames("font-semibold", {
            "text-background-700": !active,
            "text-primary": !!active,
          })}
        >
          {formatNumber(value)} {postFix ? postFix : ""} ({percent}%)
        </span>
      </span>
    </div>
  );
};
