import { useState } from "react";
import { downloadObjectAsJsonFile } from "services/common/common.apis";
import { classnames } from "utilities/classes";
import { usePathologyEditorContext } from "./pathology-editor.provider"


export const PathologyEditorMiscComponent = () => {
  const {
    fabricOverlay,
    osdViewer,
  } = usePathologyEditorContext();

  const [isDebugMode, setIsDebugMode] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);

  const handleClickDownload = () => {
    if (!fabricOverlay || isProcessing) return;
    setIsProcessing(true);
    const objectsToSave = fabricOverlay.fabricCanvas.toObject(["data"]);
    downloadObjectAsJsonFile("annotations.json", objectsToSave);
    setIsProcessing(false);
  }

  const handleClickToggleDebugMode = () => {
    setIsDebugMode(!isDebugMode);
    osdViewer?.setDebugMode(!isDebugMode);
  }

  return (
    <div className="flex flex-col gap-2">
      <h1 className="font-bold">Misc</h1>
      <div className="flex items-center gap-2">
        <button
          className="button-warn-secondary disabled:opacity-50"
          onClick={handleClickDownload}
          disabled={isProcessing}
        >
          Download
        </button>
        <button
          className={classnames(
            "disabled:opacity-50",
            {"button-warn-secondary": !isDebugMode},
            {"button-warn": isDebugMode},
          )} 
          onClick={handleClickToggleDebugMode}
        >
          Debug mode
        </button>
      </div>
    </div>
  )
}
