/*
 * File: batch-labeling.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 10th December 2021 11:39:24 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { AnalyticsQueryParams, AnalyticsQueryPayload } from "services/analytics-service";
import { RequestStatus } from "store/base/base.state";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { selectCurrentWorkspaceId } from "store/common/user-workspace/user-workspace.selectors";
import { Logger } from "utilities/logger";
import { DashboardContext } from "./dashboard.context";
import { QueryNameStatistic } from "./dashboard.state";
import { 
  selectDashBoardCardRowsDatasources,
  selectDashBoardCardRowsLabelers,
  selectDashBoardCardRowsModels,
  selectDashBoardCardRowsProjects,
  selectDashBoardCardRowsStorageUsage,
  selectDashBoardLoadDataStatus,
  selectDashBoardTableProjectType,
  selectDashBoardTableRowsBatches,
  selectDashBoardTableRowsLabelers,
  selectDashBoardTableRowsLabels,
  selectDashBoardTableRowsProjects,
  selectDashBoardTaskCompletedData,
  selectDashBoardTaskCompletedFilter,
  selectDashBoardTaskProgressData,
} from "store/customer/dashboard/dashboard.selectors";
import { loadDashBoardDataAsync } from "store/customer/dashboard/dashboard.thunk";
import { LinearProgress } from "@material-ui/core";
import { ProjectTypes } from "services/label-service/dtos";
import { useAppPrevious } from "hooks/use-app-previous";
import { useDashboardDateFilters } from "../hooks/use-dashboard-filter.hook";
import { setDashBoardTableProjectType, setDashBoardTaskCompletedFilter } from "store/customer/dashboard/dashboard.slice";
import * as Sentry from "@sentry/react";

interface Props {
  children: JSX.Element;
}

export const DashboardProvider = ({ children }: Props) => {
  const workspaceId = useAppSelector(selectCurrentWorkspaceId);
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(selectDashBoardLoadDataStatus);
  const isLoading = useMemo(() => requestStatus === RequestStatus.LOADING, [requestStatus]);
  const useCache = useRef(true);

  const cardRowsDatasources = useAppSelector(selectDashBoardCardRowsDatasources);
  const cardRowsProjects = useAppSelector(selectDashBoardCardRowsProjects);
  const cardRowsLabelers = useAppSelector(selectDashBoardCardRowsLabelers);
  const cardRowsModels = useAppSelector(selectDashBoardCardRowsModels);
  const cardRowsStorageUsage = useAppSelector(selectDashBoardCardRowsStorageUsage);

  const taskProgressData = useAppSelector(selectDashBoardTaskProgressData);
  const taskCompletedData = useAppSelector(selectDashBoardTaskCompletedData);

  const tableRowsLabels = useAppSelector(selectDashBoardTableRowsLabels);
  const tableRowsLabelers = useAppSelector(selectDashBoardTableRowsLabelers);
  
  const tableRowsProjects = useAppSelector(selectDashBoardTableRowsProjects);
  const tableRowsBatches = useAppSelector(selectDashBoardTableRowsBatches);

  // filters
  const {
    fromDate,
    toDate,
    dateRanges,
    dateRange,
    setFromDate,
    setToDate,
    setDateRange,
  } = useDashboardDateFilters();
  const previousFromDate: Date | undefined = useAppPrevious(fromDate);
  const previousToDate: Date | undefined = useAppPrevious(toDate);

  const taskCompletedFilter = useAppSelector(selectDashBoardTaskCompletedFilter);
  const previousTaskCompletedFilter = useAppPrevious(taskCompletedFilter);
  const tableProjectType = useAppSelector(selectDashBoardTableProjectType);

  const setTaskCompletedFilter = (value: string) => {
    dispatch(setDashBoardTaskCompletedFilter(value));
  }

  const setTableProjectType = (value: ProjectTypes | undefined) => {
    dispatch(setDashBoardTableProjectType(value));
  }

  const requestData = useCallback(
    async (
      fromDate: Date,
      toDate: Date,
      force: boolean = false,
      requestQueryNames: QueryNameStatistic[] | undefined = undefined,
      separator: string | undefined = undefined,
      projectTypes: ProjectTypes[] | undefined = undefined,
    ) => {
      const DEFAULT_REQUEST_QUERY_NAMES: QueryNameStatistic[] = [
        QueryNameStatistic.DatasourceStatistic,
        QueryNameStatistic.ProjectStatistic,
        QueryNameStatistic.LabelerStatistic,
        QueryNameStatistic.StorageStatistic,
        QueryNameStatistic.TaskProgressStatistic,
        QueryNameStatistic.TaskCompleteStatistic,
        QueryNameStatistic.LabelProgressStatistic,
        QueryNameStatistic.LabelerProgressStatistic,
        QueryNameStatistic.ProjectProgressStatistic,
        QueryNameStatistic.BatchProgressStatistic,
      ];
      const DEFAULT_REQUEST_SEPARATOR = "day";
      const DEFAULT_REQUEST_PROJECT_TYPES: ProjectTypes[] = [
        ProjectTypes.MEDICAL_IMAGE,
        ProjectTypes.GENERAL_IMAGE,
        ProjectTypes.TEXT,
        ProjectTypes.AUDIO,
      ];

      if (!requestQueryNames) {
        requestQueryNames = DEFAULT_REQUEST_QUERY_NAMES;
      }
      if (!separator) {
        separator = DEFAULT_REQUEST_SEPARATOR;
      }
      if (!projectTypes) {
        projectTypes = DEFAULT_REQUEST_PROJECT_TYPES;
      }

      if (requestStatus === RequestStatus.LOADING && useCache.current) return;
      if (!workspaceId || !fromDate || !toDate || 
          (requestStatus !== RequestStatus.IDLE && !force))
        return;
      
      const convertToUTCTime = (d: Date, endHour = false) => {
        const utcDate = moment(d).utc().toDate();
        if (endHour) {
          utcDate.setHours(23);
          utcDate.setMinutes(59);
          utcDate.setSeconds(59);
        }
        return moment(utcDate).format("YYYY-MM-DD HH:mm:ss");
      }
      const params: AnalyticsQueryParams = {
        workspaceId: workspaceId,
        startDate: convertToUTCTime(fromDate),
        endDate: convertToUTCTime(toDate, true),
      };

      try {
        const queryPayloads: AnalyticsQueryPayload[] = [];
        for (const requestQueryName of requestQueryNames) {
          let payloadParams: AnalyticsQueryParams = params;
          if (requestQueryName === QueryNameStatistic.TaskCompleteStatistic) {
            payloadParams = {
              ...params,
              separator,
            }
          }
          if (requestQueryName === QueryNameStatistic.ProjectProgressStatistic) {
            payloadParams= {
              ...params,
              projectTypes,
            }
          }
          queryPayloads.push({
            queryName: requestQueryName,
            params: payloadParams,
          });
        }

        dispatch(loadDashBoardDataAsync({
          queryPayloads,
          useCache: useCache.current,
        }));
        setUseCache(true);
      } catch (error: any) {
        Sentry.captureException(error);
        const errMessage = error.message || "Failed to get dashboard data";
        dispatch(enqueueErrorNotification(errMessage));
        Logger.log(error);
      } finally {
      }
    },
    [
      dispatch,
      workspaceId,
      requestStatus,
    ]
  );

  const setUseCache = (value: boolean) => {
    useCache.current = value;
  }

  useEffect(() => {
    if (!previousFromDate && !previousToDate) return;
    if (previousFromDate && (previousFromDate as Date).getTime() === fromDate.getTime() &&
      previousToDate && (previousToDate as Date).getTime() === toDate.getTime())
      return;
    if (fromDate && toDate) {
      requestData(
        fromDate,
        toDate,
        true,
        undefined,
        taskCompletedFilter,
        tableProjectType ? [tableProjectType] : undefined,
      );
    }
  }, [
    fromDate,
    toDate,
    previousFromDate,
    previousToDate,
    requestData,
    taskCompletedFilter,
    tableProjectType,
  ]);

  useEffect(() => {
    if (previousTaskCompletedFilter && 
        previousTaskCompletedFilter !== taskCompletedFilter) {
      if (fromDate && toDate) {
        setUseCache(false);
        requestData(
          fromDate,
          toDate,
          true,
          [QueryNameStatistic.TaskCompleteStatistic],
          taskCompletedFilter,
          tableProjectType ? [tableProjectType] : undefined,
        );
      }
    }
  }, [
    taskCompletedFilter,
    tableProjectType,
    previousTaskCompletedFilter,
    fromDate,
    toDate,
    requestData,
  ]);

  const state = {
    isLoading,
    requestData,
    useCache: useCache.current,
    setUseCache,
    fromDate,
    toDate,
    dateRanges,
    dateRange,
    setFromDate,
    setToDate,
    setDateRange,
    taskCompletedFilter,
    setTaskCompletedFilter,
    tableProjectType,
    setTableProjectType,
    cardRowsDatasources,
    cardRowsProjects,
    cardRowsLabelers,
    cardRowsModels,
    cardRowsStorageUsage,
    taskProgressData,
    taskCompletedData,
    tableRowsLabels,
    tableRowsLabelers,
    tableRowsProjects,
    tableRowsBatches,
  };

  return (
    <DashboardContext.Provider value={state}>
      {children}
    </DashboardContext.Provider>
  );
};

export const LoadingProgress = () => {
  return (
    <div className="absolute w-full h-20 z-0">
      <LinearProgress />
    </div>
  );
}
