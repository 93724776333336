/*
 * File: keycloak.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 9th August 2021 12:15:08 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useEffect, useRef, useState } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import {
  AuthClientTokens,
  AuthClientEvent,
  AuthClientError,
} from "@react-keycloak/core";
import { KeycloakInstance } from "keycloak-js";
import { createKeycloak } from "../../services/keycloak";
import { KeycloakContext } from "./keycloak.context";
import { useAppDispatch, useAppSelector } from "../../hooks/use-redux";
import { userTokenMapper } from "./keycloak.utils";
import {
  selectCurrentUser,
  selectUserRole,
} from "../../store/auth/auth.selectors";
import { User, UserRole } from "../../store/auth/auth.state";
import { AuthService } from "../../services/auth";
import { UserCache } from "../../utilities/user/user-cache";
import { Routes } from "routers/config/routes";
import {
  resetAuthState,
  setCurrentUser,
  setUserRole,
  setUserWorkspace,
  syncUserInfoFromTokenAsync,
} from "store/auth/auth.slice";
import { useHistory } from "react-router";
import { StorageKeys } from "hooks/storage/storage-keys";
import { UserService } from "services/user-service";
import { LoadingPage } from "pages/authorization/loading/loading.page";
import { selectCurrentWorkspaceId } from "store/common/user-workspace/user-workspace.selectors";
import { SelectOrCreateWorkspace } from "pages/authorization/select-or-create-workspace/select-or-create-workspace.component";
import { GroupDTO } from "services/user-service/dtos/group.dto";
import { v4 } from "uuid";
import { createWorkspaceAsync } from "store/common/user-workspace/user-workspace.thunk";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { Logger } from "utilities/logger";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { useTranslation } from "react-i18next";
import { isPublishRoute } from "routers";
import { loadSupportedProjectTypesAsync } from "store/common/app-setting/app-setting.slice";
import { ENV_CONFIG } from "configs/env.config";
import * as Sentry from "@sentry/react";

interface KeycloakProviderProps {
  children: React.ReactNode | React.ReactNode[] | null;
}

export const KeycloakProvider = ({ children }: KeycloakProviderProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [keycloakReady, setKeycloakReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const currentUser = useAppSelector(selectCurrentUser);
  const currentScope = useAppSelector(selectUserRole);
  const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
  const [openWorkspaceMenu, setOpenWorkspaceMenu] = useState(false);
  const keycloakEventRef = useRef<AuthClientEvent | undefined>(undefined);

  const { t } = useTranslation();
  const [keycloak] = useState<KeycloakInstance>(() =>
    createKeycloak({
      realm: ENV_CONFIG.KEYCLOAK_REALM,
      clientId: ENV_CONFIG.KEYCLOAK_RESOURCE,
      url: ENV_CONFIG.KEYCLOAK_AUTH_SERVICE_URL,
    })
  );

  async function handleTokenChanged(event: AuthClientTokens) {
    const { token } = event;
    const sameToken = token && token === AuthService.getAccessToken();
    AuthService.setAccessToken(token);
    if (token) {
      if (
        keycloakEventRef.current === "onAuthRefreshSuccess" ||
        keycloakEventRef.current === "onTokenExpired"
      ) {
        // Ignore event
      } else if (!sameToken) {
        const [loggedInUser, decodedToken] = userTokenMapper(token);
        dispatch(syncUserInfoFromTokenAsync(decodedToken));
        _onSignedIn(loggedInUser);
      }
    } else if (currentUser) {
      _onSignedOut();
    }
    setLoading(false);
  }

  function _onSignedIn(user: User) {
    dispatch(setCurrentUser(user));
    UserCache.addUser(user);
    const savedRole = getSavedRole(user);
    if (savedRole && user.workspace.hasOwnProperty(savedRole)) {
      dispatch(setUserRole(savedRole));
      const pathName = window.location.pathname;
      if (
        pathName.startsWith(Routes.LABELER_HOME) &&
        savedRole === UserRole.CUSTOMER
      ) {
        history.replace(Routes.ROOT);
      } else if (
        savedRole === UserRole.LABELER &&
        !pathName.startsWith(Routes.LABELER_HOME) &&
        !pathName.startsWith("/review")
      ) {
        history.replace(Routes.LABELER_HOME);
      }
    }
  }

  function selectRole(role: UserRole) {
    dispatch(setUserRole(role));
    if (currentUser) {
      localStorage.setItem(currentUser.email + StorageKeys.USER_SCOPE, role);
    }
  }

  function onCreatedWorkspace() {
    if (currentUser) {
      const storageKey = currentUser.email + StorageKeys.USER_SCOPE;
      localStorage.setItem(storageKey, UserRole.CUSTOMER);
    }
    window.location.replace(Routes.ROOT);
  }

  function getSavedRole(user: User): UserRole | null {
    const savedScope = localStorage.getItem(
      user.email + StorageKeys.USER_SCOPE
    );
    if (savedScope && (savedScope as UserRole)) {
      return savedScope as UserRole;
    }
    return null;
  }

  function _onSignedOut() {
    dispatch(setCurrentUser(null));
    UserCache.clear();
  }

  function handleEvent(eventType: AuthClientEvent, error?: AuthClientError) {
    keycloakEventRef.current = eventType;
    if (eventType === "onReady") {
      setKeycloakReady(true);
    } else if (eventType === "onInitError") {
      keycloak.login();
    } else if (error) {
      keycloak.login();
    }
  }

  function _getCurrentHost() {
    return `${window.location.protocol}//${window.location.host}`;
  }

  async function signOut() {
    await keycloak?.logout({ redirectUri: _getCurrentHost() });
    dispatch(resetAuthState());
  }

  function refreshToken() {
    const isCustomer = currentScope === UserRole.CUSTOMER;
    const redirectPath = isCustomer ? Routes.ROOT : Routes.LABELER_HOME;
    const redirectUri = `${_getCurrentHost()}${redirectPath}`;
    keycloak?.login({ redirectUri: redirectUri });
  }

  function switchRole(role: UserRole) {
    if (!currentUser || !currentWorkspaceId) return;
    const userRoleWorkspaces = currentUser.workspace[role];
    if (userRoleWorkspaces && userRoleWorkspaces.includes(currentWorkspaceId)) {
      selectRole(role);
      const isCustomer = role === UserRole.CUSTOMER;
      history.replace(isCustomer ? Routes.ROOT : Routes.LABELER_HOME);
    } else {
      setOpenWorkspaceMenu(true);
    }
  }

  async function selectWorkspace(workspaceId: string) {
    await UserService.selectWorkspace(workspaceId);
    dispatch(setUserWorkspace(workspaceId));
  }

  async function handleSelectWorkspace(workspaceId: string) {
    const nextRole =
      currentScope === UserRole.CUSTOMER ? UserRole.LABELER : UserRole.CUSTOMER;
    await selectWorkspace(workspaceId);
    selectRole(nextRole);
    setOpenWorkspaceMenu(false);
  }

  async function handleCreateWorkspace(workspaceName: string) {
    try {
      const workspacePayload: GroupDTO = {
        groupId: v4(),
        groupName: workspaceName,
        lastSelect: Date.now(),
      };
      const response = await dispatch(createWorkspaceAsync(workspacePayload));
      handleThunkRejected(response);
      try {
        const newCreatedWorkspace: GroupDTO = response.payload;
        if (newCreatedWorkspace) selectWorkspace(newCreatedWorkspace.groupId);
      } catch (error) {
        Sentry.captureException(error);
        Logger.log(error);
      }
      setOpenWorkspaceMenu(false);
      onCreatedWorkspace();
      dispatch(enqueueSuccessNotification(t("common:textCreatedSuccess")));
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(error));
    }
  }

  const value = {
    signOut,
    refreshToken,
    switchRole,
    selectRole,
    selectWorkspace,
  };

  useEffect(() => {
    if (
      !currentUser &&
      keycloakReady &&
      keycloak &&
      !loading &&
      !isPublishRoute(window.location.pathname)
    ) {
      keycloak.login();
    }
  }, [currentUser, keycloakReady, keycloak, loading]);

  useEffect(() => {
    AuthService.setUserScope(currentScope || "");
    AuthService.setUserWorkspace(currentWorkspaceId || "");
    if (currentScope && currentWorkspaceId) {
      dispatch(loadSupportedProjectTypesAsync());
    }
  }, [currentScope, currentWorkspaceId, dispatch]);

  useEffect(() => {
    if (!keycloak || !currentUser) return;
    // Every 30 seconds, check for token, if it invalid after next 15 mins then update token
    const intervalDuration = 30 * 1000;
    async function autoUpdateToken() {
      const validTime = 15 * 60;
      const isExpired = keycloak.isTokenExpired(validTime);
      if (isExpired) {
        await keycloak.updateToken(validTime);
      }
    }
    const intervalId = setInterval(autoUpdateToken, intervalDuration);
    return () => {
      window.clearInterval(intervalId);
    };
  }, [keycloak, currentUser]);

  return (
    <KeycloakContext.Provider value={value}>
      <ReactKeycloakProvider
        autoRefreshToken
        authClient={keycloak}
        onTokens={handleTokenChanged}
        onEvent={handleEvent}
      >
        {!loading && children}
        {loading && <LoadingPage />}
        {openWorkspaceMenu && currentScope && (
          <SelectOrCreateWorkspace
            role={currentScope}
            onClose={() => setOpenWorkspaceMenu(false)}
            onSubmit={handleSelectWorkspace}
            onCreate={handleCreateWorkspace}
          />
        )}
      </ReactKeycloakProvider>
    </KeycloakContext.Provider>
  );
};
