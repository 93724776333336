import OpenSeadragon from "openseadragon";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { createContext } from "react";
import { AuthService } from "services/auth";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { FabricOverlay } from "./fabric/fabric-overlay";
import { FabricToolBrush } from "./fabric/fabric-tool-brush";
import { FabricToolEllipse } from "./fabric/fabric-tool-ellipse";
import { FabricToolLine } from "./fabric/fabric-tool-line";
import { FabricToolMagicWand } from "./fabric/fabric-tool-magic-wand";
import { FabricToolPolygon } from "./fabric/fabric-tool-polygon";
import { FabricToolRectangle } from "./fabric/fabric-tool-rectangle";
import {
  ANNOTATION_TYPES,
  DEFAULT_FILL_OBJECTS,
  DEFAULT_TEXT_LABELER_VISIBILITY,
  DEFAULT_TEXT_LABEL_VISIBILITY,
  DEFAULT_UTIL_BUTTON_VISIBILITY,
  FabricObjectToolType,
  FabricOverlayEvents,
} from "./fabric/fabric.models";
import { PathologyEditorAnnotationUtilsPopOverComponent } from "./pathology-editor-annotation-utils-pop-over.component";
import { PathologyEditorContextMenuComponent } from "./pathology-editor-context-menu.component";
import { PathologyEditorDeleteChildrenPopupComponent } from "./pathology-editor-delete-children-popup.component";
import { PathologyEditorNavComponent } from "./pathology-editor-nav.component";
import {
  PathologyAnnotation,
  PathologyAnnotationAdditionalData,
  PathologyEditorLabel,
  PathologyEditorTool,
  TOOL_POLYGON,
  TOOL_MOVE,
  TOOL_RECTANGLE,
  PathologyAnnotationFilter,
  DEFAULT_PATHOLOGY_ANNOTATION_FILTER,
  PathologyLabeler,
  TOOL_SELECT,
  EDITOR_TOOLS,
  PathologyEditorEvents,
  TOOL_LINE,
  TOOL_ELLIPSE,
  TOOL_BRUSH,
  TOOL_MAGIC_WAND,
  PathologyEditorTab,
  TAB_HIERARCHY,
  PathologyAnnotationEdge,
} from "./pathology-editor.models";
import { fabricObjectToAnnotation } from "./pathology-editor.utils";

export interface PathologyEditorState {
  osdViewer: OpenSeadragon.Viewer | undefined;
  fabricOverlay: FabricOverlay | undefined;

  // Tools
  activeTool: PathologyEditorTool;
  setActiveTool: (v: PathologyEditorTool) => void;

  // Labels
  labels: PathologyEditorLabel[];
  selectedLabel: PathologyEditorLabel | undefined;
  setSelectedLabel: (v: PathologyEditorLabel | undefined) => void;

  // Annotations
  annotations: PathologyAnnotation[];
  setAnnotations: (v: PathologyAnnotation[]) => void;
  syncAnnotationsFromFabricObjects: (options: any) => void;
  removeAnnotation: (v: any) => void;
  annotationFilter: PathologyAnnotationFilter;
  setAnnotationFilter: (v: PathologyAnnotationFilter) => void;
  deselectAllObjects: () => void;
  selectObjects: (objects: fabric.Object[]) => void;
  deleteSelectedAnnotations: (deleteChildren?: boolean) => void;
  hasAnySelectedAnnotation: boolean;
  textLabelVisibility: boolean;
  setTextLabelVisibility: (v: boolean) => void;
  textLabelerVisibility: boolean;
  setTextLabelerVisibility: (v: boolean) => void;
  utilButtonVisibility: boolean;
  setUtilButtonVisibility: (v: boolean) => void;
  fillObjects: boolean;
  setFillObjects: (v: boolean) => void;

  // Labelers
  currentLabeler: PathologyLabeler | undefined;
  setCurrentLabeler: (v: PathologyLabeler | undefined) => void;
  otherLabelers: PathologyLabeler[];
  setOtherLabelers: (v: PathologyLabeler[]) => void;

  // Tabs
  activeTab: PathologyEditorTab;
  setActiveTab: (v: PathologyEditorTab) => void;

  // Delete children pop-up
  deleteChildrenPopupVisibility: boolean;
  setDeleteChildrenPopupVisibility: (v: boolean) => void;
}

export const PathologyEditorContext = createContext({} as PathologyEditorState);

export const usePathologyEditorContext = () => {
  return useContext(PathologyEditorContext);
};

interface Props {
  wsiUrl: string;
  useAjaxHeaders: boolean;
  onLoaded?: (fabricOverlay: FabricOverlay) => void;
  inputLabels: PathologyEditorLabel[];
  initFabricObjects: fabric.Object[];
  initEdges?: PathologyAnnotationEdge[];
  onAnnotationsChanged: (objects: fabric.Object[]) => void;
  inputCurrentLabeler?: PathologyLabeler;
  inputOtherLabelers: PathologyLabeler[];
}
export const PathologyEditorProvider = ({
  wsiUrl,
  useAjaxHeaders = true,
  onLoaded,
  inputLabels,
  initFabricObjects,
  initEdges = [],
  onAnnotationsChanged,
  inputCurrentLabeler,
  inputOtherLabelers = [],
}: Props) => {
  // Viewers
  const [osdViewer, setOsdViewer] = useState<OpenSeadragon.Viewer>();
  const [fabricOverlay, setFabricOverlay] = useState<FabricOverlay>();

  // Tools
  const [activeTool, setActiveTool] = useState<PathologyEditorTool>(TOOL_MOVE);
  const activeToolRef = useRef<PathologyEditorTool>(TOOL_MOVE);
  const previousActiveToolRef = useRef<PathologyEditorTool>(TOOL_MOVE);

  // Labels
  const [labels, setLabels] = useState<PathologyEditorLabel[]>([]);
  const [selectedLabel, setSelectedLabel] = useState<PathologyEditorLabel>();

  // Annotations
  const [annotations, setAnnotations] = useState<PathologyAnnotation[]>([]);
  const [annotationFilter, setAnnotationFilter] =
    useState<PathologyAnnotationFilter>(DEFAULT_PATHOLOGY_ANNOTATION_FILTER);
  const hasAnySelectedAnnotation = useMemo(() => {
    const selectedObjects = annotations
      .filter((anno) => anno.fabricObjectRef?.data?.selected)
      .map((anno) => anno.fabricObjectRef as fabric.Object);
    return selectedObjects.length > 0;
  }, [annotations]);
  const [textLabelVisibility, setTextLabelVisibility] = useState(
    DEFAULT_TEXT_LABEL_VISIBILITY
  );
  const [textLabelerVisibility, setTextLabelerVisibility] = useState(
    DEFAULT_TEXT_LABELER_VISIBILITY
  );
  const [utilButtonVisibility, setUtilButtonVisibility] = useState(
    DEFAULT_UTIL_BUTTON_VISIBILITY
  );
  const [fillObjects, setFillObjects] = useState(DEFAULT_FILL_OBJECTS);

  // Labelers
  const [currentLabeler, setCurrentLabeler] = useState<PathologyLabeler>();
  const [otherLabelers, setOtherLabelers] = useState<PathologyLabeler[]>([]);

  // Tabs
  const [activeTab, setActiveTab] = useState<PathologyEditorTab>(TAB_HIERARCHY);

  // Delete children pop-up
  const [deleteChildrenPopupVisibility, setDeleteChildrenPopupVisibility] =
    useState(false);

  // Refs
  const fabricOverlayRef = useRef(fabricOverlay);
  const onLoadedRef = useRef(onLoaded);
  const onAnnotationsChangedRef = useRef(onAnnotationsChanged);

  useEffect(() => {
    fabricOverlayRef.current = fabricOverlay;
  }, [fabricOverlay]);
  useEffect(() => {
    activeToolRef.current = activeTool;
  }, [activeTool]);

  const syncAnnotationsFromFabricObjects = ({
    overlay,
    filter,
  }: {
    overlay?: FabricOverlay;
    filter?: PathologyAnnotationFilter;
  }) => {
    if (!overlay) overlay = fabricOverlayRef.current;
    if (!overlay) return;

    const objects = overlay.fabricCanvas.getObjects();
    const annotations: PathologyAnnotation[] = [];

    for (const object of objects) {
      if (!ANNOTATION_TYPES.includes(object.type as FabricObjectToolType))
        continue;

      // Apply filter
      if (filter) {
        const objectData = object.data as PathologyAnnotationAdditionalData;
        if (objectData) {
          // TODO: might add more filter logic later
          let visible = true;
          if (
            filter.labelIds.length > 0 &&
            !filter.labelIds.includes(objectData.labelId)
          ) {
            visible = visible && false;
          }
          if (
            filter.labelers.length > 0 &&
            !filter.labelers.includes(objectData.labeler)
          ) {
            visible = visible && false;
          }

          object.set({ visible });
          if (object.data?.utilGroupRef) {
            object.data.utilGroupRef.set({ visible });
          }
          if (object.data?.distanceTextRef) {
            object.data.distanceTextRef.set({ visible });
          }
        }
      }

      annotations.push(fabricObjectToAnnotation(object));
    }

    setAnnotations(annotations);
    onAnnotationsChangedRef.current(objects);
    overlay.fabricCanvas.requestRenderAll();
  };
  const syncAnnotationsFromFabricObjectsRef = useRef(
    syncAnnotationsFromFabricObjects
  );

  // Init stuff
  useEffect(() => {
    let viewerOption: OpenSeadragon.Options = {
      id: "openseadragon-viewer",
      tileSources: wsiUrl,
      minZoomLevel: 0.01,
      defaultZoomLevel: 0.5,
      loadTilesWithAjax: true,
      showNavigator: true,
      showNavigationControl: false,
      navigatorPosition: "BOTTOM_LEFT",
      navigatorAutoResize: true,
      navigatorAutoFade: false,
      rotateLeftButton: "/",
      rotateRightButton: "*",
      animationTime: 0.2,
      blendTime: 0.2,
      maxZoomPixelRatio: 2,
      minZoomImageRatio: 1,
      zoomPerScroll: 2,
    };

    if (useAjaxHeaders) {
      viewerOption = {
        ...viewerOption,
        ajaxWithCredentials: false,
        ajaxHeaders: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${AuthService.getAccessToken()}`,
          Scope: `${AuthService.getUserScope()}`,
          Workspace: `${AuthService.getUserWorkspace()}`,
        },
      };
    }

    const viewer = OpenSeadragon(viewerOption);

    const overlay = new FabricOverlay(viewer);

    // Init tools
    overlay.toolPolygon = new FabricToolPolygon(overlay, viewer);
    overlay.toolRectangle = new FabricToolRectangle(overlay, viewer);
    overlay.toolLine = new FabricToolLine(overlay, viewer);
    overlay.toolEllipse = new FabricToolEllipse(overlay, viewer);
    overlay.toolBrush = new FabricToolBrush(overlay, viewer);
    overlay.toolMagicWand = new FabricToolMagicWand(overlay, viewer);
    overlay.tools = [
      overlay.toolPolygon,
      overlay.toolRectangle,
      overlay.toolLine,
      overlay.toolEllipse,
      overlay.toolBrush,
      overlay.toolMagicWand,
    ];

    setFabricOverlay(overlay);

    viewer.addHandler("canvas-drag", (event) => {
      if (activeToolRef.current.isDrawing) {
        event.preventDefaultAction = true;
      }
    });

    viewer.addHandler("canvas-click", (event) => {
      if (activeToolRef.current.isDrawing) {
        event.preventDefaultAction = true;
      }
    });

    setOsdViewer(viewer);

    const handleFabricObjectAdded = () => {
      syncAnnotationsFromFabricObjectsRef.current({});
    };

    const handleOverlayOpen = () => {
      // Init objects
      for (const object of initFabricObjects) {
        overlay.fabricCanvas.add(object);
        if (object.data?.utilGroupRef) {
          overlay.fabricCanvas.add(object.data.utilGroupRef);
        }
        if (object.data?.distanceTextRef) {
          overlay.fabricCanvas.add(object.data.distanceTextRef);
        }
      }

      // initFabricObjects are always in osd image coordinate system for consistency
      // so we need to transform to fabric absolute coordinate system
      overlay.transformObjectsCoordinatesToFabricAbsolute();

      // build the hierarychy from initEdges
      overlay.buildHierarchyTreeFromEdges(initEdges);

      syncAnnotationsFromFabricObjectsRef.current({ overlay });
    };

    const handleSelectedObjectsUpdated = () => {
      syncAnnotationsFromFabricObjectsRef.current({ overlay });
    };

    const handleKeyDown = (e: any) => {
      if (
        e.key === KeyboardKey.Space &&
        activeToolRef.current.id !== TOOL_MOVE.id
      ) {
        if (!overlay.enabledPan) {
          previousActiveToolRef.current = activeToolRef.current;
          setActiveTool(TOOL_MOVE);
        }
      }

      for (const tool of EDITOR_TOOLS) {
        if (tool.shortcut === e.key) {
          if (activeToolRef.current.id === tool.id) {
            setActiveTool(TOOL_MOVE);
          } else {
            setActiveTool(tool);
          }
        }
      }

      if (e.key === KeyboardKey.Escape) {
        if (activeToolRef.current.id === TOOL_POLYGON.id) {
          overlay.toolPolygon?.cancelDrawing();
        } else if (activeToolRef.current.id === TOOL_RECTANGLE.id) {
          overlay.toolRectangle?.cancelDrawing();
        }
      }

      if (e.key === KeyboardKey.Enter) {
        if (activeToolRef.current.id === TOOL_POLYGON.id) {
          overlay.toolPolygon?.endDrawing(false);
        } else if (activeToolRef.current.id === TOOL_RECTANGLE.id) {
          overlay.toolRectangle?.endDrawing();
        }
      }
    };

    const handleKeyUp = (e: any) => {
      if (e.key === KeyboardKey.Space) {
        setActiveTool(previousActiveToolRef.current);
      }
    };

    document.addEventListener(
      FabricOverlayEvents.OBJECT_ADDED,
      handleFabricObjectAdded
    );
    overlay.on(FabricOverlayEvents.OPEN, handleOverlayOpen);
    overlay.on(
      FabricOverlayEvents.SELECTED_OBJECTS_UPDATED,
      handleSelectedObjectsUpdated
    );
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    // Labels
    setLabels(inputLabels);

    // Lablers
    setCurrentLabeler(inputCurrentLabeler);
    setOtherLabelers(inputOtherLabelers);

    overlay.updateUtilGroupLabelVisibilities();
    overlay.updateObjectsDistancePosForObjects();

    console.log("Init");
    onLoadedRef.current && onLoadedRef.current(overlay);

    return () => {
      setOsdViewer(undefined);
      setFabricOverlay(undefined);
      setActiveTool(TOOL_MOVE);
      setCurrentLabeler(undefined);
      setOtherLabelers([]);
      setTextLabelVisibility(false);
      setTextLabelerVisibility(false);
      setUtilButtonVisibility(false);
      setFillObjects(false);

      document.removeEventListener(
        FabricOverlayEvents.OBJECT_ADDED,
        handleFabricObjectAdded
      );
      overlay.off(FabricOverlayEvents.OPEN, handleOverlayOpen);
      overlay.off(
        FabricOverlayEvents.SELECTED_OBJECTS_UPDATED,
        handleSelectedObjectsUpdated
      );
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);

      overlay.destroy();
      viewer.destroy();

      console.log("Release");
    };
  }, [
    wsiUrl,
    useAjaxHeaders,
    inputLabels,
    initFabricObjects,
    initEdges,
    inputCurrentLabeler,
    inputOtherLabelers,
  ]);

  // Enabled/disabled fabric tools based on active tool and label
  useEffect(() => {
    if (!fabricOverlay) return;

    fabricOverlay.disableAllTools();
    if (selectedLabel) {
      if (activeTool.id === TOOL_POLYGON.id) {
        fabricOverlay.toolPolygon?.setEnabled(true);
      }
      if (activeTool.id === TOOL_RECTANGLE.id) {
        fabricOverlay.toolRectangle?.setEnabled(true);
      }
      if (activeTool.id === TOOL_LINE.id) {
        fabricOverlay.toolLine?.setEnabled(true);
      }
      if (activeTool.id === TOOL_ELLIPSE.id) {
        fabricOverlay.toolEllipse?.setEnabled(true);
      }
      if (activeTool.id === TOOL_BRUSH.id) {
        fabricOverlay.toolBrush?.setEnabled(true);
      }
      if (activeTool.id === TOOL_MAGIC_WAND.id) {
        fabricOverlay.toolMagicWand?.setEnabled(true);
      }
    }

    if (activeTool.id === TOOL_MOVE.id) {
      fabricOverlay.enabledPan = true;
    } else {
      fabricOverlay.enabledPan = false;
    }

    if (activeTool.id === TOOL_SELECT.id) {
      fabricOverlay.setAllObjectsSelectable();
      fabricOverlay.fabricCanvas.selection = true;
    } else {
      fabricOverlay.setAllObjectsUnSelectable();
      fabricOverlay.fabricCanvas.selection = false;
      fabricOverlay.fabricCanvas.discardActiveObject();
      fabricOverlay.fabricCanvas.requestRenderAll();
    }
  }, [fabricOverlay, activeTool, selectedLabel]);

  // Set fabric overlay object additional data when
  // selected label changed
  useEffect(() => {
    if (!fabricOverlay) return;

    if (selectedLabel) {
      const data: PathologyAnnotationAdditionalData = {
        labelId: selectedLabel.id,
        labelName: selectedLabel.name,
        labelColor: selectedLabel.color,
        labeler: currentLabeler?.id || "",
      };
      fabricOverlay.objectAdditionalData = data;
    } else {
      fabricOverlay.objectAdditionalData = undefined;
    }
  }, [selectedLabel, fabricOverlay, currentLabeler]);

  // Remove a annotation. we directly remove the fabric object
  // then call syncAnnotationsFromFabricObjects to trigger React render
  const removeAnnotation = (fabricObjectRef: any) => {
    if (!fabricOverlayRef.current) return;

    fabricOverlayRef.current.removeObject(fabricObjectRef);

    syncAnnotationsFromFabricObjectsRef.current({});
  };

  const deselectAllObjects = () => {
    if (!fabricOverlayRef.current) return;
    fabricOverlayRef.current.deselectAllObjects();
  };

  const selectObjects = (objects: fabric.Object[]) => {
    if (!fabricOverlayRef.current) return;
    fabricOverlayRef.current.selectObjects(objects);
  };

  const deleteSelectedAnnotations = (deleteChildren = false) => {
    if (!fabricOverlayRef.current) return;
    fabricOverlayRef.current.deleteSelectedObjects(deleteChildren);
    syncAnnotationsFromFabricObjectsRef.current({});
  };

  // Update object util group label visibilities
  useEffect(() => {
    if (!fabricOverlay) return;
    fabricOverlay.setUtilGroupLabelVisibilities(
      textLabelVisibility,
      textLabelerVisibility,
      utilButtonVisibility
    );
  }, [
    fabricOverlay,
    textLabelVisibility,
    textLabelerVisibility,
    utilButtonVisibility,
  ]);

  // Fill
  useEffect(() => {
    if (!fabricOverlay) return;
    fabricOverlay.setObjectsFill(fillObjects);
  }, [fabricOverlay, fillObjects]);

  const handleContextMenu = (e: any) => {
    document.dispatchEvent(
      new CustomEvent(PathologyEditorEvents.CONTEXT_MENU, {
        detail: e,
      })
    );
  };

  const value: PathologyEditorState = {
    osdViewer,
    fabricOverlay,

    activeTool,
    setActiveTool,

    labels,
    selectedLabel,
    setSelectedLabel,

    annotations,
    setAnnotations,
    syncAnnotationsFromFabricObjects,
    removeAnnotation,
    annotationFilter,
    setAnnotationFilter,
    deselectAllObjects,
    selectObjects,
    deleteSelectedAnnotations,
    hasAnySelectedAnnotation,
    textLabelVisibility,
    setTextLabelVisibility,
    textLabelerVisibility,
    setTextLabelerVisibility,
    utilButtonVisibility,
    setUtilButtonVisibility,
    fillObjects,
    setFillObjects,

    currentLabeler,
    setCurrentLabeler,
    otherLabelers,
    setOtherLabelers,

    activeTab,
    setActiveTab,

    deleteChildrenPopupVisibility,
    setDeleteChildrenPopupVisibility,
  };

  return (
    <PathologyEditorContext.Provider value={value}>
      <div
        className="relative flex w-full h-full"
        onContextMenu={handleContextMenu}
      >
        <div className="flex-auto h-full" id="openseadragon-viewer"></div>
        <PathologyEditorNavComponent />
        <PathologyEditorAnnotationUtilsPopOverComponent />
        <PathologyEditorContextMenuComponent />
        <PathologyEditorDeleteChildrenPopupComponent />
      </div>
    </PathologyEditorContext.Provider>
  );
};
