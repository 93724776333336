/*
 * File: workspace-users.context.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 15th November 2021 6:49:36 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { defaultWorkspaceUsersState } from "./workspace-users.state";

export const WorkspaceUsersContext = createContext(defaultWorkspaceUsersState);

export const useWorkspaceUsersContext = () => {
  return useContext(WorkspaceUsersContext);
};
