/*
 * File: progress-chart.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 23rd August 2021 11:27:04 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from 'react-i18next';
import { useMemo, useState } from "react";
import { Pie, PieChart, Cell, Legend } from "recharts";
import { TaskStatus } from "services/label-service/dtos";

interface BatchProgressChartProps {
  statistic: Record<TaskStatus, number>;
}

export const BatchProgressChart = ({ statistic }: BatchProgressChartProps) => {
  const { t } = useTranslation();
  const pieNames = useState<String[]>([
    t('project:batchDetails.labelerChart.labelBlocked'),
    t('project:batchDetails.labelerChart.labelDone'),
    t('project:batchDetails.labelerChart.labelLeft')
  ])[0];

  const hasData = Object.keys(statistic).length > 0;
  const data = useMemo(() => {
    function getValue(status: TaskStatus, defaultValue = 0) {
      if (statistic.hasOwnProperty(status)) return statistic[status];
      return defaultValue;
    }

    function getValues(statuses: TaskStatus[]) {
      return statuses.reduce((total, status) => total + getValue(status), 0);
    }

    const blockedOrCanceled = getValues([
      TaskStatus.LOCKED,
      TaskStatus.REMOVED,
    ]);
    const done = getValues([TaskStatus.COMPLETED, TaskStatus.DELIVERED]);
    const left = getValues([
      TaskStatus.INITIAL,
      TaskStatus.AVAILABLE,
      TaskStatus.WAITING,
      TaskStatus.WORKING,
    ]);

    // Calculate percentages and check if there are two of them that are closed
    // for rendering labels
    function getTwoClosed(arr: number[]): any[] {
      const sum = arr.reduce((s, n) => s + n, 0);
      const percentages = arr.map((n) => (n / sum) * 100);

      for (let i = 0; i < percentages.length; i++) {
        let j = (i + 1) % percentages.length;
        let percentageI = percentages[i];
        let percentageJ = percentages[j];
        let percentageDiff = Math.abs(percentageI - percentageJ);

        // We can change the threshold here to see if the labels still overlay
        const threshold = 20;
        if (
          percentageI <= threshold &&
          percentageJ <= threshold &&
          percentageDiff <= threshold
        ) {
          return [pieNames[i], "end", pieNames[j], "start"];
        }
      }

      return ["", "", "", ""];
    }
    const closeData = getTwoClosed([blockedOrCanceled, done, left]);

    return [
      {
        name: pieNames[0],
        value: blockedOrCanceled,
        color: "#FF0000",
        closeData: closeData,
      },
      {
        name: pieNames[1],
        value: done,
        color: "#34D399",
        closeData: closeData,
      },
      {
        name: pieNames[2],
        value: left,
        color: "#8B5CF6",
        closeData: closeData,
      },
    ];
  }, [statistic, pieNames]);

  const renderLabelContent = (props: any) => {
    const {
      value,
      x,
      y,
      midAngle,
      name,
      payload: { closeData },
    } = props;
    const [close1Name, close1TextAnchor, close2Name, close2TextAnchor] =
      closeData;

    let textAnchor = "start";
    if (name !== pieNames[0] && (midAngle < -90 || midAngle >= 90))
      textAnchor = "end";

    if (name === close1Name) {
      textAnchor = close1TextAnchor;
    }
    if (name === close2Name) {
      textAnchor = close2TextAnchor;
    }

    return (
      <g transform={`translate(${x}, ${y})`} textAnchor={textAnchor}>
        <text
          x={textAnchor === "start" ? 2 : -2}
          y={y > 150 ? 16 : -8}
        >{`${name}: ${value}`}</text>
      </g>
    );
  };

  return (
    <PieChart width={450} height={300}>
      <Pie
        data={data}
        dataKey="value"
        cx={200}
        cy={150}
        startAngle={90}
        endAngle={-270}
        innerRadius={0}
        outerRadius={60}
        label={renderLabelContent}
        paddingAngle={1}
        isAnimationActive={false}
      >
        {data.map((item, index) => (
          <Cell key={`slice-${index}`} fill={item.color} />
        ))}

        {!hasData && (
          <text x={200} y={150}>
            {t('project:batchDetails.labelerChart.textNoData')}
          </text>
        )}
      </Pie>
      <Legend />
    </PieChart>
  );
};
