/*
 * File: labeler-statistic.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 23rd August 2021 2:33:08 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { DataGrid, GridCellParams, GridColumns } from "@material-ui/data-grid";
import { formatDateTime } from "utilities/formatter/date-formatter.utils";
import { DocumentDownload } from "iconsax-react";
import { useState } from "react";
import { BatchDTO } from "services/label-service/dtos";
import { BatchService } from "services/label-service";
import { useAppDispatch } from "hooks/use-redux";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { TaskRunnerType } from "domain/customer/task-runner";
import classNames from "classnames";
import { CircularProgress } from "@material-ui/core";
import * as Sentry from "@sentry/react";

export interface LabelerStatisticModel {
  id: string;
  account: string;
  startWorkingTime: string;
  lastWorkingTime: string;
  finished: number;
  rejected: number;
  totalCase: number;

  totalAnnotationTime: number;
  totalIssue: number;
  avgTimePerAnnotation: number;
  avgTimePerJob: number;
  avgAnnotationPerJob: number;
}

interface LabelerStatisticProps {
  batch: BatchDTO;
  data: LabelerStatisticModel[];
  isTextProject?: boolean;
}
export const LabelerStatistic = ({
  isTextProject,
  data,
  batch,
}: LabelerStatisticProps) => {
  const { t } = useTranslation();
  const columns: GridColumns = [
    {
      field: "account",
      headerName: t("project:batchDetails.labelerStat.headerAccount"),
      description: t("project:batchDetails.labelerStat.headerAccount"),
      type: "string",
      headerClassName: "capitalize",
      minWidth: 200,
    },
    {
      field: "totalAnnotationTime",
      headerName: t("project:batchDetails.labelerStat.totalAnnotationTime"),
      type: "number",
      headerClassName: "capitalize",
      minWidth: 220,
    },
    {
      field: "finished",
      headerName: t("project:batchDetails.labelerStat.totalCompletedJobs"),
      type: "number",
      headerClassName: "capitalize",
      minWidth: 220,
    },
    {
      field: "totalCase",
      headerName: t("project:batchDetails.labelerStat.totalAnnotations"),
      type: "number",
      headerClassName: "capitalize",
      minWidth: 200,
    },
    {
      field: "totalIssue",
      headerName: t("project:batchDetails.labelerStat.totalIssues"),
      type: "number",
      headerClassName: "capitalize",
      minWidth: 160,
    },
    {
      field: "avgTimePerAnnotation",
      headerName: t("project:batchDetails.labelerStat.avgTimePerAnnotation"),
      type: "number",
      headerClassName: "capitalize",
      minWidth: 250,
    },

    {
      field: "avgTimePerJob",
      headerName: t("project:batchDetails.labelerStat.avgTimePerJob"),
      type: "number",
      headerClassName: "capitalize",
      minWidth: 200,
    },

    {
      field: "avgAnnotationPerJob",
      headerName: t("project:batchDetails.labelerStat.avgAnnotationPerJob"),
      type: "number",
      headerClassName: "capitalize",
      minWidth: 240,
    },
    {
      field: "reviewPassRate",
      headerName: t("project:batchDetails.labelerStat.reviewPassRate"),
      type: "number",
      headerClassName: "capitalize",
      minWidth: 200,
    },

    {
      field: "totalRelation",
      headerName: t("project:batchDetails.labelerStat.headerTotalRelation"),
      type: "number",
      headerClassName: "capitalize",
      hide: !isTextProject,
      minWidth: 200,
    },

    {
      field: "startWorkingTime",
      headerName: t("project:batchDetails.labelerStat.headerFirstWorkingTime"),
      type: "string",
      headerClassName: "capitalize",
      minWidth: 220,
      renderCell: (params: GridCellParams) => {
        return (
          <span>{formatDateTime(new Date(params.row.startWorkingTime))}</span>
        );
      },
    },
    {
      field: "lastWorkingTime",
      headerName: t("project:batchDetails.labelerStat.headerLastWorkingTime"),
      type: "string",
      headerClassName: "capitalize",
      minWidth: 220,
      renderCell: (params: GridCellParams) => {
        return (
          <span>{formatDateTime(new Date(params.row.lastWorkingTime))}</span>
        );
      },
    },
  ];
  const dispatch = useAppDispatch();
  const [isDownloading, setDownloading] = useState(false);
  async function downloadReportingData() {
    if (isDownloading) return;
    try {
      setDownloading(true);
      const res = await BatchService.downloadDataset(batch.id, {
        jobDownloadType: "COMPLETED",
        fileName: batch.name,
        format: TaskRunnerType.BATCH_STATISTIC_REPORT,
        saveImage: false,
      });
      const url: string = res.data;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${batch.name}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setDownloading(false);
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || "Failed to download reporting data";
      dispatch(enqueueErrorNotification(errMessage));
      setDownloading(false);
    }
    console.log(batch);
  }
  return (
    <div className="w-full">
      <div className="flex items-center justify-between w-full gap-4 py-4">
        <h2 className="text-heading3s">Labelers</h2>
        <button
          className={classNames("button-secondary text-body-small relative", {
            "opacity-80 pointer-events-none cursor-default": isDownloading,
          })}
          disabled={isDownloading}
          onClick={downloadReportingData}
        >
          {isDownloading && <CircularProgress size={18} />}
          {!isDownloading && <DocumentDownload size={18} />}
          <span>Download report</span>
        </button>
      </div>
      <div className="overflow-x-auto" style={{ minWidth: 1440 }}>
        <DataGrid
          className="bg-white whitespace-nowrap"
          pageSize={10}
          autoHeight
          columns={columns}
          rows={data}
          rowsPerPageOptions={[10, 20, 25, 50]}
          disableSelectionOnClick
          sortingMode="client"
        />
      </div>
    </div>
  );
};
