/*
 * File: update-batch.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/use-redux";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { BatchDTO, BatchStatus } from "services/label-service/dtos";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { updateStatusBatchAsync } from "store/customer/project/project.slice";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBSelectComponent } from "components/design-system/select-input/select.component";
import * as Sentry from "@sentry/react";

interface Props {
  batch: BatchDTO | null;
  handleClose: () => void;
}

export const UpdateBatchDialog = ({ batch, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [updating, setUpdating] = useState(false);
  const options = useMemo(() => {
    if (!batch) return [];

    if(batch.status === BatchStatus.INITIAL){
      return [
        { value: BatchStatus.WORKING, label: t("project:batch.update.textWorking") },
        { value: BatchStatus.COMPLETED, label: t("project:batch.update.textCompleted") }
      ]
    }

    if(batch.status === BatchStatus.WORKING){
      return [
        { value: BatchStatus.ON_HOLD, label: t("project:batch.update.textOnHold") },
        { value: BatchStatus.COMPLETED, label: t("project:batch.update.textCompleted") },
        { value: BatchStatus.APPROVED, label: t("project:batch.update.textApproved") },
      ]
    }

    if(batch.status === BatchStatus.ON_HOLD){
      return [
        { value: BatchStatus.WORKING, label: t("project:batch.update.textWorking") }
      ]
    }

    if(batch.status === BatchStatus.DELIVERED){
      return [
      ]
    }

    if(batch.status === BatchStatus.COMPLETED ||
       batch.status === BatchStatus.ACCEPTED ||
       batch.status === BatchStatus.REJECTED){
      return [
        { value: BatchStatus.APPROVED, label: t("project:batch.update.textApproved") },
      ]
    }

    return [];
  }, [batch, t]);

  const [status, setStatus] = useState(batch?.status || BatchStatus.INITIAL);
  const [reopenReason, setReopenReason] = useState("");

  useEffect(() => {
    if (batch) setStatus(batch.status);
  }, [batch]);

  async function handleUpdate() {
    if (!batch) return;
    if (updating) return;
    setUpdating(true);
    try {
      const response = await dispatch(
        updateStatusBatchAsync({
          ...batch,
          status: status,
        })
      );
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textUpdatedSuccess")));
    } catch (err: any) {
      Sentry.captureException(err);
      const message = err.message || t("common:textUpdatedFailed");
      dispatch(enqueueErrorNotification(message));
    } finally {
      handleClose();
      setUpdating(false);
    }
  }

  return (
    <VBModal
      title={"Update status"}
      open={!!batch}
      onClose={handleClose}
      textSubmit={"Update"}
      disableSubmit={updating}
      onSubmit={handleUpdate}
    >
      <>
        <VBSelectComponent
          header={"New status"}
          size="xl"
          containerClassName=""
          value={options.find(o => o.value === status)}
          options={options}
          onChange={(option: any) => setStatus(option.value as string)}
          menuPortalTarget={document.body}
        />
        {
          status === BatchStatus.REOPEN &&
          <div className="flex flex-col gap-2 mt-4">
            <p>Reason</p>
            <textarea
              className="w-full p-2 rounded border-2 border-background-300"
              rows={4}
              value={reopenReason}
              onChange={(e) => setReopenReason(e.target.value as string)}
            />
          </div>
        }
      </>
    </VBModal>
  );
};
