/*
 * File: project.slice.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTPStatusCode, isHTTPStatusOK } from "services/common/http-status";
import { RequestOptions } from "services/common/request-options";
import {
  BatchService,
  BatchServiceV2,
  ObservationService,
  ProjectServiceV2,
} from "services/label-service";
import { CreateBatchPayload } from "services/label-service/apis/batch.api";
import {
  BatchDTO,
  BatchStatus,
  ObservationDTO,
  ProjectDTO,
  UpdateObservationStrategy,
} from "services/label-service/dtos";
import { INITIAL_PROJECT_STATE, ProjectState } from "./project.state";
import { batchWorkflowReducer } from "./reducers/batch-workflow.reducer";
import { workflowInstructionReducer } from "./reducers/workflow-instruction.reducer";
import { batchWorkflowReducerBuilder } from "./thunks/batch-workflow.thunk";
import { workflowInstructionReducerBuilder } from "./thunks/workflow-instruction.thunk";

const SLICE_NAME: string = "project";

export const setProjectAsync = createAsyncThunk(
  `${SLICE_NAME}/setProjectAsync`,
  async (projectId: number) => {
    const response = await ProjectServiceV2.getItem(projectId);
    return response.data;
  }
);

export const setProjectForCreatingBatchAsync = createAsyncThunk(
  `${SLICE_NAME}/setProjectForCreatingBatchAsync`,
  async (projectId: number) => {
    const response = await ProjectServiceV2.getItem(projectId);
    return response.data;
  }
);

export const createBatchAsync = createAsyncThunk(
  `${SLICE_NAME}/createBatchAsync`,
  async (payload: CreateBatchPayload) => {
    const response = await BatchService.createBatch(payload);
    if (response.data && response.data.batch) return response.data.batch;
    return null;
  }
);

export const createBatchAsyncV2 = createAsyncThunk(
  `${SLICE_NAME}/createBatchAsyncV2`,
  async (payload: CreateBatchPayload) => {
    const response = await BatchServiceV2.createBatch(payload);
    if (response.data && response.data.batch) return response.data.batch;
    return null;
  }
);

export const createNewBatchAsync = createAsyncThunk(
  `${SLICE_NAME}/createNewBatchAsync`,
  async (payload: Partial<BatchDTO>) => {
    const response = await BatchService.createItem(payload);
    return response.data;
  }
);

export const loadBatchesAsync = createAsyncThunk(
  `${SLICE_NAME}/loadBatchesAsync`,
  async (options: RequestOptions) => {
    const response = await BatchService.getItems(options);
    return response.data;
  }
);

export const updateStatusBatchAsync = createAsyncThunk(
  `${SLICE_NAME}/updateStatusBatchAsync`,
  async (batch: BatchDTO) => {
    await BatchService.updateStatus(batch.id, batch.status);
    return batch;
  }
);
export const reopenBatchAndUpdateStatusAsync = createAsyncThunk(
  `${SLICE_NAME}/reopenBatchAndUpdateStatusAsync`,
  async ({ batchId, reason }: { batchId: number; reason: string }) => {
    // await BatchService.reopen(batchId, {
    //   description: reason,
    // });
    return { batchId };
  }
);
export const deleteBatchAsync = createAsyncThunk(
  `${SLICE_NAME}/deleteBatchAsync`,
  async (batchId: number) => {
    const response = await BatchService.deleteItem(batchId);
    if (
      response.status === HTTPStatusCode.OK ||
      response.status === HTTPStatusCode.NoContent
    ) {
      return batchId;
    }
    return -1;
  }
);

export const loadProjectObservationsAsync = createAsyncThunk(
  `${SLICE_NAME}/loadProjectObservationsAsync`,
  async (projectId: number) => {
    const response = await ObservationService.getItems({
      projectId: projectId.toString(),
      sort: "priority,asc",
      size: "500",
    });
    return response.data;
  }
);

export const deleteProjectObservationAsync = createAsyncThunk(
  `${SLICE_NAME}/deleteProjectObservationAsync`,
  async (ovservationId: number) => {
    const response = await ObservationService.deleteItem(ovservationId);
    if (isHTTPStatusOK(response.status)) {
      return ovservationId;
    }
    return -1;
  }
);

export const loadProjectObservationAsync = createAsyncThunk(
  `${SLICE_NAME}/loadProjectObservationAsync`,
  async (observationId: string) => {
    const response = await ObservationService.getItem(observationId);
    return response.data;
  }
);

export const createProjectLabelAsync = createAsyncThunk(
  `${SLICE_NAME}/createProjectLabelAsync`,
  async (payload: Partial<ObservationDTO>) => {
    const response = await ObservationService.createObservation(payload);
    return response.data;
  }
);

export const updateProjectObservationAsync = createAsyncThunk(
  `${SLICE_NAME}/updateProjectObservationAsync`,
  async ({
    payload,
    strategy = UpdateObservationStrategy.OVERRIDE,
  }: {
    payload: Partial<ObservationDTO>;
    strategy?: string;
  }) => {
    const response = await ObservationService.updateObservation(
      payload,
      strategy
    );
    return response.data;
  }
);

export const activeProjectObservationAsync = createAsyncThunk(
  `${SLICE_NAME}/activeProjectObservationAsync`,
  async (observation: ObservationDTO) => {
    const response = await ObservationService.partialUpdateItem(observation);
    return response.data;
  }
);

export const getProjectObservationAnnotationTypesAsync = createAsyncThunk(
  `${SLICE_NAME}/getProjectObservationAnnotationTypesAsync`,
  async (projectId: number | string) => {
    const res =
      await ObservationService.getObservationAnnotationTypesByProjectId(
        projectId
      );
    return res.data;
  }
);

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_PROJECT_STATE,
  reducers: {
    ...batchWorkflowReducer,
    ...workflowInstructionReducer,
    updateBatch(state: ProjectState, action: PayloadAction<BatchDTO>) {
      state.batches = state.batches.map((batch) => {
        if (batch.id === action.payload.id) {
          return action.payload;
        }
        return batch;
      });
    },
    updateProjectDetail(
      state: ProjectState,
      action: PayloadAction<ProjectDTO>
    ) {
      state.project = {
        ...state.project,
        ...action.payload,
      };
    },
    resetProjectState(state: ProjectState) {
      Object.assign(state, INITIAL_PROJECT_STATE);
    },
  },

  extraReducers: (builder) => {
    batchWorkflowReducerBuilder(builder);
    workflowInstructionReducerBuilder(builder);
    builder
      .addCase(setProjectAsync.pending, (state) => {
        state.project = null;
        state.requesting = true;
      })
      .addCase(setProjectAsync.fulfilled, (state, action) => {
        state.project = action.payload.project;
        state.workflow = action.payload.workflow;
        state.workManagements = action.payload.workManagement;
        state.projectForCreatingBatch = action.payload;
        state.observations = action.payload.observation || [];
        state.requesting = false;
      })
      .addCase(setProjectForCreatingBatchAsync.pending, (state) => {
        state.projectForCreatingBatch = null;
        state.requesting = true;
      })
      .addCase(setProjectForCreatingBatchAsync.fulfilled, (state, action) => {
        state.projectForCreatingBatch = action.payload;
        state.requesting = false;
      })
      .addCase(setProjectForCreatingBatchAsync.rejected, (state) => {
        state.projectForCreatingBatch = null;
        state.requesting = false;
      })

      .addCase(createNewBatchAsync.fulfilled, (state, action) => {
        state.batches.unshift(action.payload);
      })
      .addCase(createBatchAsync.fulfilled, (state, action) => {
        state.batches.unshift(action.payload);
      })
      .addCase(createBatchAsyncV2.fulfilled, (state, action) => {
        state.batches.unshift(action.payload);
      })
      .addCase(updateStatusBatchAsync.fulfilled, (state, action) => {
        state.batches = state.batches.map((batch) => {
          if (batch.id === action.payload.id) {
            return {
              ...batch,
              ...action.payload,
            };
          }
          return batch;
        });
      })
      .addCase(reopenBatchAndUpdateStatusAsync.fulfilled, (state, action) => {
        const { batchId } = action.payload;
        state.batches = state.batches.map((batch) => {
          if (batch.id === batchId) {
            return {
              ...batch,
              status: BatchStatus.WORKING,
            };
          }
          return batch;
        });
      })

      .addCase(loadBatchesAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(loadBatchesAsync.fulfilled, (state, action) => {
        state.requesting = false;
        state.batches = action.payload;
      })
      .addCase(deleteBatchAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(deleteBatchAsync.fulfilled, (state, action) => {
        state.requesting = false;
        state.batches = state.batches.filter((batch) => {
          return batch.id !== action.payload;
        });
      })

      .addCase(createProjectLabelAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(createProjectLabelAsync.fulfilled, (state, action) => {
        state.requesting = false;
        state.observations.push(action.payload);
      })
      .addCase(deleteProjectObservationAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(deleteProjectObservationAsync.fulfilled, (state, action) => {
        state.requesting = false;
        state.observations = state.observations.filter((batch) => {
          return batch.id !== action.payload;
        });
      })

      .addCase(updateProjectObservationAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(updateProjectObservationAsync.fulfilled, (state, action) => {
        state.requesting = false;
      })
      .addCase(activeProjectObservationAsync.pending, (state) => {
        state.requesting = true;
      })
      .addCase(activeProjectObservationAsync.fulfilled, (state, action) => {
        state.requesting = false;
        state.observations = state.observations.map((p) => {
          if (p.id === action.payload.id) {
            return {
              ...p,
              ...action.payload,
            };
          }
          return p;
        });
      })
      .addCase(loadProjectObservationsAsync.fulfilled, (state, action) => {
        state.observations = action.payload;
      })
      .addCase(
        getProjectObservationAnnotationTypesAsync.fulfilled,
        (state, action) => {
          state.observationAnnotationTypes = action.payload;
        }
      );
  },
});

export const {
  updateBatch,
  setBatchWorkflows,
  addBatchWorkflow,
  updateBatchWorkflow,
  removeBatchWorkflow,
  setWorkflowInstructions,
  addWorkflowInstruction,
  updateWorkflowInstruction,
  removeWorkflowInstruction,
  resetWorkflowInstructions,
  resetProjectState,
  updateProjectDetail
} = slice.actions;

export const projectReducer = slice.reducer;
