/*
 * File: workspace.context.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:12 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { defaultWorkspaceState } from "./workspace.state";

export const WorkspaceContext = createContext(defaultWorkspaceState);

export const useWorkspaceContext = () => {
  return useContext(WorkspaceContext);
};
