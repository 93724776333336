/*
 * File: generate-annotations-message.component.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 26th March 2022 9:37:16 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useMount } from "ahooks";
import {
  IconInformationCircle,
  IconCheckedCircle,
  IconError,
  IconClose,
} from "components/common/vb-icon.component";
import { TaskRunner, TaskRunnerStatus } from "domain/customer/task-runner";
import { useAppDispatch } from "hooks/use-redux";
import { CloudDTO } from "models/dataset/cloud.model";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import { DatasetDTO } from "services/label-service/dtos";
import { StorageService } from "services/storage-service";
import { getCloudInfo } from "services/storage/cloud/get-all-cloud.api";
import { taskRunnerRemoved } from "store/customer/task-runner/task-runner.slice";
import { classnames } from "utilities/classes";
import { TaskProgresss } from "../task-progress/task-progress";

interface Props {
  index?: number;
  task: TaskRunner;
  onClose(): void;
}
export const SyncCloudMessage = ({ index = 0, task, onClose }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [mounted, setMounted] = useState(false);
  const [entity, setEntity] = useState<DatasetDTO | null>(null);
  const [cloud, setCloud] = useState<CloudDTO | null>(null);
  useMount(() => setTimeout(() => setMounted(true), 500));

  const isCompleted = task.status === TaskRunnerStatus.SUCCEEDED;
  const isFailed = task.status === TaskRunnerStatus.FAILED;
  const isRunning = task.status === TaskRunnerStatus.RUNNING;
  const isInitialized = task.status === TaskRunnerStatus.INITIAL;
  const isDone =
    task.status === TaskRunnerStatus.SUCCEEDED ||
    task.status === TaskRunnerStatus.FAILED;

  function handleViewDetail() {
    if (entity) {
      const url = `/datasets/${entity.id}`;
      history.push(url);
    }
    onClose();
  }

  function handleDismiss() {
    dispatch(taskRunnerRemoved(task));
  }

  useEffect(() => {
    async function loadData() {
      const data = JSON.parse(task.data);
      if (data && data.hasOwnProperty("entityId")) {
        const entityId: number = data["entityId"];
        const datasetResponse =
          await StorageService.getDatasetService().getItem(entityId);
        if (datasetResponse && datasetResponse.data) {
          setEntity(datasetResponse.data);
        }
      }

      const payload = JSON.parse(task.payload);
      if (payload && payload.hasOwnProperty("entityId")) {
        const entityId: number = payload["entityId"];
        const cloudInfo = await getCloudInfo(entityId);
        if (cloudInfo && cloudInfo.data) {
          setCloud(cloudInfo.data);
        }
      }
    }

    loadData();
  }, [task]);

  const title = {
    [TaskRunnerStatus.INITIAL]: "Sync cloud",
    [TaskRunnerStatus.RUNNING]: "Syncing your files..",
    [TaskRunnerStatus.SUCCEEDED]: "Succeeded",
    [TaskRunnerStatus.FAILED]: "Failed",
    [TaskRunnerStatus.CANCELED]: "Canceled",
  };
  const description = {
    [TaskRunnerStatus.INITIAL]: "",
    [TaskRunnerStatus.RUNNING]:
      "Large storage might take long time. Please wait...",
    [TaskRunnerStatus.SUCCEEDED]: `<strong>${cloud?.importStatistic?.success}</strong> files was imported to dataset <strong>${entity?.name}</strong>`,
    [TaskRunnerStatus.FAILED]: task.error,
    [TaskRunnerStatus.CANCELED]: "Syncing files was canceled.",
  };

  return createPortal(
    <div
      className={classnames(
        "fixed p-4  rounded shadow-2xl bottom-10 right-8 text-background-700 transform transition-all",
        {
          "bg-white": isRunning || isInitialized,
          "bg-success-50": isCompleted,
          "bg-red-50": isFailed,
          "opacity-100": mounted,
          "translate-y-4 opacity-0 pointer-events-none": !mounted,
        }
      )}
      style={{
        zIndex: 99999 - index,
        width: 400,
        transform: mounted ? `translateY(-${16 + 180 * index}px)` : "",
      }}
    >
      <div className="flex gap-4 py-2">
        <div className="p-0.5">
          {task.status === TaskRunnerStatus.RUNNING && (
            <IconInformationCircle className="w-6 h-6 text-primary" />
          )}
          {task.status === TaskRunnerStatus.SUCCEEDED && (
            <IconCheckedCircle className="w-6 h-6 text-success-500" />
          )}
          {task.status === TaskRunnerStatus.FAILED && (
            <IconError className="w-6 h-6 text-red-500" />
          )}
        </div>
        <div className="flex-auto space-y-2">
          <div className="text-lg font-semibold">
            <div dangerouslySetInnerHTML={{ __html: title[task.status] }} />
          </div>
          <div className="w-full">
            <div
              dangerouslySetInnerHTML={{ __html: description[task.status] }}
            />
          </div>

          <div className="w-full">
            <TaskProgresss value={task.percentComplete} />
          </div>

          <div className="flex items-center gap-2">
            {isDone && (
              <>
                <button
                  className="text-primary hover:underline"
                  onClick={handleViewDetail}
                >
                  View detail
                </button>
                <div className="w-1 h-1 rounded-full bg-background-300" />
              </>
            )}
            <button
              className="text-primary hover:underline"
              onClick={handleDismiss}
            >
              Dismiss
            </button>
          </div>
        </div>
        <button
          onClick={onClose}
          className="flex items-center justify-center flex-none w-8 h-8 rounded-full"
        >
          <IconClose className="flex-none w-6 h-6" />
        </button>
      </div>
    </div>,
    document.body
  );
};
