/*
 * File: use-projects-by-task-completed.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 19th May 2023 9:12:57 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { LabelerProject } from "domain/image-labeling";
import useProjectTemplates from "pages/customer/projects/components/project-templates/hooks/use-project-templates.hook";
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { ProjectService } from "services/label-service";
import { projectMapper } from "services/label-service/mappers/project.mapper";

async function getProjectsByTaskCompleted(
  page: number
): Promise<LabelerProject[]> {
  const response = await ProjectService.getProjectsByTaskCompleted(page);
  return response.data
    .map(projectMapper.fromDTO)
    .map(projectMapper.toLabelerProject);
}

export function useProjectsByTaskCompleted() {
  function requestData() {
    return getProjectsByTaskCompleted(20);
  }
  const { data, isLoading } = useQuery(
    ["projectsByTaskCompleted"],
    requestData,
    {}
  );

  const { templates } = useProjectTemplates();

  const getProjectById = useCallback(
    (projectId: number) => {
      return data?.find((item) => item.id === projectId);
    },
    [data]
  );

  const projects = useMemo(() => {
    if (!data) return [];
    const imageMaps: Record<string, string> = {};
    for (const template of templates ?? []) {
      imageMaps[template.type] = template.imageUrl;
    }
    return data.map((item) => {
      if (imageMaps.hasOwnProperty(item.type)) {
        return {
          ...item,
          imageUrl: imageMaps[item.type],
        };
      }
      return { ...item };
    });
  }, [data, templates]);

  return { data: projects, isLoading, getProjectById };
}
