import { AxiosResponse } from "axios";
import { IouAnnotation } from "store/labeler/image-workspace/image-iou/image-iou.state";
import { AnnotationVoteRequest, LabelerAnnotationDTO } from "../dtos/annotation.dto";
import { BaseAPI } from "./base.api";

export class LabelerAnnotationAPI extends BaseAPI<LabelerAnnotationDTO> {
  async voteAnnotations(jobId: number, annotationVotes: AnnotationVoteRequest[]) {
    const payload = {
      jobId,
      annotationVotes
    }
    return this.post(`${this.getPath()}/vote`, JSON.stringify(payload));
  }
  async getIoULabeler(jobId: number): Promise<AxiosResponse<IouAnnotation[]>> {
    return this.getItem(`metrics/${jobId}`);
  }
}
