/*
 * File: vb-select.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 16th August 2021 6:25:56 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  createStyles,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      padding: ".5rem 1rem",
    },
  })
);

export interface VBSelectOption {
  label: string;
  value: any;
}

interface VBSelectProps extends SelectProps {
  options: VBSelectOption[];
}

export const VBSelect = ({ options, placeholder, ...rest }: VBSelectProps) => {
  const classes = useStyles();
  return (
    <Select classes={classes} {...rest}>
      {placeholder && (
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
      )}
      {options.map((option) => {
        return (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        );
      })}
    </Select>
  );
};
