/*
 * File: vb-image.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 12th August 2022 4:26:20 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { CircularProgress } from "@material-ui/core";
import { useWorkspaceContext } from "contexts/workspace";
import { ImgHTMLAttributes, useMemo, useRef, useState } from "react";

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  requireToken?: boolean;
  loadStyle?: "spinner" | "skeleton";
  skeletonClassName?: string;
  maskClassName?: string;
  mask?: JSX.Element;
  onLoaded?(): void;
  onLoadFailed?(): void;
  onLoadStarted?(): void;
}
export default function VBImage({
  src,
  requireToken = false,
  alt = "image",
  loadStyle = "spinner",
  skeletonClassName = "bg-blueGray-800",
  maskClassName = "pointer-events-none",
  className = "object-contain w-full h-full",
  mask,
  onLoadFailed,
  onLoadStarted,
  onLoaded,
  ...rest
}: Props) {
  const { getUrlWithSasToken } = useWorkspaceContext();
  const url = useMemo(() => {
    return requireToken ? getUrlWithSasToken(src || "") : src;
  }, [requireToken, src, getUrlWithSasToken]);
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  function handleImageLoadStart() {
    onLoadStarted && onLoadStarted();
  }

  function handleImageLoaded() {
    if (imgRef.current?.complete) {
      setLoaded(true);
      onLoaded && onLoaded();
    }
  }

  function handleImageLoadFailed() {
    setLoaded(true);
    onLoadFailed && onLoadFailed();
  }

  return (
    <div
      className="relative flex items-center justify-center w-full h-full overflow-hidden"
      style={{ lineHeight: 0 }}
    >
      {!loaded && loadStyle === "spinner" && (
        <div className="absolute top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center w-full h-full bg-black bg-opacity-20">
          <CircularProgress size={24} />
        </div>
      )}
      {!loaded && loadStyle === "skeleton" && (
        <div
          className={`absolute top-0 left-0 w-full h-full skeleton-box ${skeletonClassName}`}
        />
      )}
      <div className="relative w-full h-full">
        {url && (
          <img
            ref={imgRef}
            onLoadStart={handleImageLoadStart}
            onLoad={handleImageLoaded}
            onError={handleImageLoadFailed}
            src={url}
            alt={alt}
            className={className}
            {...rest}
          />
        )}
        {loaded && mask && (
          <div
            className={`z-10 absolute top-0 left-0 w-full h-full flex items-center justify-center ${maskClassName}`}
          >
            {mask}
          </div>
        )}
      </div>
    </div>
  );
}
