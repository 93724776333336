import { useMount, useUnmount } from "ahooks";
import * as cornerstone from "cornerstone-core";
import { JobOption } from "domain/common/models";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import React, { Fragment } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { RequestStatus } from "store/base/base.state";
import {
  selectIssueManagementIsCreatingIssueMode,
  selectIssueManagementIssues,
  selectIssueManagementIssueByFilter,
  selectIssueManagementIssueFilter,
} from "store/common/issue-management/issue-management.selectors";
import {
  issueManagementReset,
  setIssueManagementIsReviewer,
} from "store/common/issue-management/issue-management.slice";
import { loadIssuesAsync } from "store/common/issue-management/issue-management.thunk";
import {
  getMousePositionRelative,
  getMousePositionRelativePercentage,
} from "utilities/mouse/get-mouse-pos-relative";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { IssueButtons } from "./issue-buttons.component";
import { IssueCreate } from "./issue-create.component";
import { IssueCommentModel, IssueModel } from "./issue-management.models";
import { Issue } from "./issue.component";
import * as Sentry from "@sentry/react";

export interface IssueManagementState {
  isCreatingIssueMode: boolean;
  issues: IssueModel[];
  isReviewer: boolean;
  taskId: number;
  jobId?: number;
  jobIds?: number[];
}

export interface IssueManagementContextState extends IssueManagementState {}

export const IssueManagementContext = createContext(
  {} as IssueManagementContextState
);

export const useIssueManagementContext = () => {
  return useContext(IssueManagementContext);
};

interface Props {
  children?: React.ReactNode | React.ReactNode[] | null;
  taskId: number;
  jobId?: number; // labeler screens
  isReviewer?: boolean;
  jobOptions?: JobOption[]; // labeler in the current task
  jobIds?: number[];
  showMenu?: boolean;
  menuPosition?: "topRight" | "bottomLeft";
  menuPaddingTop?: number;
  fixedMenu?: boolean;
  cornerstoneElement?: HTMLElement | null;
  frameId?: number;
}
export const IssueManagementProvider = ({
  frameId,
  taskId,
  jobId,
  isReviewer = false,
  jobOptions = [],
  jobIds,
  children,
  showMenu = true,
  menuPosition = "topRight",
  menuPaddingTop = 0,
  fixedMenu,
  cornerstoneElement,
}: Props) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const isCreatingIssueMode = useAppSelector(
    selectIssueManagementIsCreatingIssueMode
  );
  const issues = useAppSelector(
    selectIssueManagementIssues(taskId, jobIds, jobId)
  );
  const issueFilter = useAppSelector(selectIssueManagementIssueFilter);
  const issuesToDisplay = useAppSelector(
    selectIssueManagementIssueByFilter(issueFilter, taskId, jobIds, jobId)
  );

  const [newIssue, setNewIssue] = useState<Partial<IssueModel>>();
  const newIssueRef = useRef();

  const requestStatus = useRef<RequestStatus>(RequestStatus.IDLE);

  const loadIssues = useCallback(
    async (
      taskId: string | number,
      jobId?: string | number,
      isReviewer?: boolean
    ) => {
      try {
        if (requestStatus.current === RequestStatus.LOADING) return;
        requestStatus.current = RequestStatus.LOADING;

        const res = await dispatch(
          loadIssuesAsync({ taskId, jobId, isReviewer })
        );
        handleThunkRejected(res);
        requestStatus.current = RequestStatus.SUCCESS;
      } catch (error: any) {
        Sentry.captureException(error);
        requestStatus.current = RequestStatus.FAILURE;
      }
    },
    [dispatch]
  );

  useEffect(() => {
    loadIssues(taskId, jobId, isReviewer);
  }, [taskId, jobId, isReviewer, loadIssues]);

  const handleCreatingLayerClicked = (e: any) => {
    if (!currentUser) return;
    let pos = getMousePositionRelativePercentage(e);
    if (cornerstoneElement) {
      const posCanvas = getMousePositionRelative(e);
      pos = cornerstone.canvasToPixel(cornerstoneElement, posCanvas);
    }

    if (newIssue) {
      if (
        newIssue.comments &&
        newIssue.comments.length > 0 &&
        newIssue.comments[0].content
      ) {
        if (newIssueRef.current) {
          const el = newIssueRef.current as any;
          el.style.animation = "shake 0.5s";
          el.firstChild.focus();
        }
        return;
      }
      setNewIssue(undefined);
    } else {
      const newComment: IssueCommentModel = {
        id: -1,
        issueId: -1,
        user: currentUser.email || "",
        content: "",
        createdTime: new Date(),
      };
      setNewIssue({
        taskId: taskId,
        jobId: jobId,
        posX: pos.x,
        posY: pos.y,
        frameId: frameId,
        comments: [newComment],
      });
    }
  };

  const handleNewIssueChanged = (newValue: Partial<IssueModel>) => {
    setNewIssue(newValue);
  };

  const handleNewIssueCreated = () => {
    setNewIssue(undefined);
  };

  useMount(() => {
    dispatch(setIssueManagementIsReviewer(isReviewer));
  });

  useUnmount(() => {
    dispatch(issueManagementReset());
  });

  const value: IssueManagementContextState = {
    isCreatingIssueMode,
    issues,
    isReviewer,
    taskId,
    jobId,
    jobIds,
  };

  return (
    <IssueManagementContext.Provider value={value}>
      <Fragment>
        {isCreatingIssueMode && (
          <div
            className="absolute top-0 left-0 w-full h-full opacity-50"
            style={{
              zIndex: "100",
              cursor: "cell",
            }}
            onClick={handleCreatingLayerClicked}
          ></div>
        )}
        {issuesToDisplay.map((issue) => {
          if (frameId !== undefined && frameId !== (issue.frameId ?? 0))
            return null;
          return (
            <Issue
              key={issue.id}
              issue={issue}
              cornerstoneElement={cornerstoneElement}
            />
          );
        })}
        {newIssue && isCreatingIssueMode && (
          <IssueCreate
            taskId={taskId}
            isReviewer={isReviewer}
            jobOptions={jobOptions}
            ref={newIssueRef}
            newIssue={newIssue}
            width={36}
            onValueChanged={handleNewIssueChanged}
            onCreated={handleNewIssueCreated}
            cornerstoneElement={cornerstoneElement}
          />
        )}
        {showMenu && (
          <IssueButtons
            paddingTop={menuPaddingTop}
            position={menuPosition}
            isFixed={fixedMenu}
          />
        )}
      </Fragment>
      {children}
    </IssueManagementContext.Provider>
  );
};
