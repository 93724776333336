import { AnnotationAttributeItem } from "services/label-service/dtos/annotations.dto";
import { TextToSpeechJobUIModel } from "../context/text-to-speech-labeling.state";
import { ObservationAttribute } from "services/label-service/dtos";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import VBRadioInputComponent from "components/design-system/radio/radio.component";
import { useCallback } from "react";
import { VBTextAreaComponent } from "components/design-system/text-area/text-area.component";

interface TextToSpeechLabelsProps {
  uiJob: TextToSpeechJobUIModel;
  onChange?(attributes?: AnnotationAttributeItem[]): void;
}

export function TextToSpeechLabels({
  uiJob,
  onChange,
}: TextToSpeechLabelsProps) {
  const getDefaultValue = useCallback(
    (observationAttribute: ObservationAttribute) => {
      let defaltValues: string[] = [];
      try {
        const key = `${uiJob.job?.assignee}:attribute:${observationAttribute.id}`;
        const str = localStorage.getItem(key);
        if (
          observationAttribute.type.toLowerCase() === "select" ||
          observationAttribute.type.toLowerCase() === "radio"
        ) {
          defaltValues = str?.split(",").map((item) => item.trim()) ?? [];
        }
      } catch (error) {}

      const { id: attributeId, type } = observationAttribute;
      const attribute = uiJob.attributes?.find(({ id }) => id === attributeId);
      const values =
        attribute && attribute.value && attribute.value.length > 0
          ? attribute.value
          : defaltValues;
      if (type === "text" || type === "number") {
        return values.length > 0 ? values[0] : undefined;
      }
      return values;
    },
    [uiJob]
  );

  function handleOnChange(attributeId: number, value: string[]) {
    try {
      const key = `${uiJob.job?.assignee}:attribute:${attributeId}`;
      localStorage.setItem(key, value.join(","));
    } catch (error) {}

    const newAttributes: AnnotationAttributeItem[] =
      uiJob.attributes?.filter((item) => item.id !== attributeId) ?? [];
    newAttributes.push({ id: attributeId, value });
    onChange && onChange(newAttributes);
  }
  return (
    <div className="text-white">
      <section>
        {uiJob.batchObservations.map((batchObservation) => {
          return (
            <div key={batchObservation.id}>
              <h2 className="py-4 text-lg font-semibold">
                {batchObservation.observation.name}
              </h2>
              <ul className="grid grid-cols-3 gap-6">
                {batchObservation.observation.attributes.map((attr) => {
                  return (
                    <TextToSpeechAnnotationAttributeItem
                      key={attr.id}
                      attribute={attr}
                      defaultValue={getDefaultValue(attr)}
                      onChange={handleOnChange}
                    />
                  );
                })}
              </ul>
            </div>
          );
        })}
      </section>
    </div>
  );
}

export default TextToSpeechLabels;

interface TextToSpeechAnnotationAttributeItemProps {
  attribute: ObservationAttribute;
  defaultValue?: any;
  onChange?(attributeId: number, value: string[], submit?: boolean): void;
}

function TextToSpeechAnnotationAttributeItem({
  attribute,
  defaultValue,
  onChange,
}: TextToSpeechAnnotationAttributeItemProps) {
  return (
    <div>
      {attribute.type.toLowerCase() === "text" && (
        <VBTextAreaComponent
          header={attribute.name}
          require={attribute.required}
          className="w-full bg-background-800"
          defaultValue={defaultValue}
          onInputSubmit={(val: string) =>
            onChange && onChange(attribute.id, [val])
          }
        />
      )}
      {attribute.type.toLowerCase() === "number" && (
        <VBTextInputComponent
          header={attribute.name}
          require={attribute.required}
          type="number"
          className="w-full"
          hideInputIcon
          defaultValue={defaultValue}
          min={
            attribute.ranges && attribute.ranges.length > 0
              ? parseInt(attribute.ranges[0].toString())
              : undefined
          }
          max={
            attribute.ranges && attribute.ranges.length > 1
              ? parseInt(attribute.ranges[1].toString())
              : undefined
          }
          onInputChange={(val: string) =>
            onChange && onChange(attribute.id, [val])
          }
        />
      )}

      {attribute.type.toLowerCase() === "select" && (
        <VBRadioInputComponent
          multiple
          require={attribute.required}
          header={attribute.name}
          options={attribute.values || []}
          optionClassName="whitespace-nowrap"
          defaultValues={defaultValue}
          onValueChange={(values) => onChange && onChange(attribute.id, values)}
        />
      )}
      {attribute.type.toLowerCase() === "radio" && (
        <VBRadioInputComponent
          require={attribute.required}
          header={attribute.name}
          options={attribute.values || []}
          optionClassName="whitespace-nowrap"
          defaultValues={defaultValue}
          onValueChange={(values) => onChange && onChange(attribute.id, values)}
        />
      )}
    </div>
  );
}
