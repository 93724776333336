import { RequestStatus } from "store/base/base.state";
import { RootState } from "../..";

export const selectDashBoardCardRowsDatasources = (state: RootState) => state.dashBoard.cardRowsDatasources;
export const selectDashBoardCardRowsProjects = (state: RootState) => state.dashBoard.cardRowsProjects;
export const selectDashBoardCardRowsLabelers = (state: RootState) => state.dashBoard.cardRowsLabelers;
export const selectDashBoardCardRowsModels = (state: RootState) => state.dashBoard.cardRowsModels;
export const selectDashBoardCardRowsStorageUsage = (state: RootState) => state.dashBoard.cardRowsStorageUsage;

export const selectDashBoardTaskProgressData = (state: RootState) => state.dashBoard.taskProgressData;
export const selectDashBoardTaskCompletedData = (state: RootState) => state.dashBoard.taskCompletedData;

export const selectDashBoardTableRowsLabels = (state: RootState) => state.dashBoard.tableRowsLabels;
export const selectDashBoardTableRowsLabelers = (state: RootState) => state.dashBoard.tableRowsLabelers;

export const selectDashBoardTableRowsProjects = (state: RootState) => state.dashBoard.tableRowsProjects;
export const selectDashBoardTableRowsBatches = (state: RootState) => state.dashBoard.tableRowsBatches;

export const selectDashBoardLoadDataStatus = (state: RootState) => state.dashBoard.loadDataStatus;
export const selectDashBoardCacheData = (state: RootState) => state.dashBoard.cache;

export const selectDashBoardQueryNameRequestStatus = (queryName: string) => 
  (state: RootState) => {
  const status = state.dashBoard.queryNamesRequestStatusMap[queryName];
  if (!status) return RequestStatus.IDLE;
  return status;
}

export const selectDashBoardIsQueryNameLoading = (queryName: string) =>
  (state: RootState) => {
  return state.dashBoard.queryNamesRequestStatusMap[queryName] === RequestStatus.LOADING;
}

export const selectDashBoardFromDate = (state: RootState) => state.dashBoard.fromDate;
export const selectDashBoardToDate = (state: RootState) => state.dashBoard.toDate;
export const selectDashBoardDateRange = (state: RootState) => state.dashBoard.dateRange;
export const selectDashBoardTaskCompletedFilter = (state: RootState) => state.dashBoard.taskCompletedFilter;
export const selectDashBoardTableProjectType = (state: RootState) => state.dashBoard.tableProjectType;
export const selectDashBoardLatestTimeLoaded = (state: RootState) => state.dashBoard.latestTimeLoaded;