import { Backdrop } from "@material-ui/core";
import { IconCloseCircle } from "components/common/vb-icon.component";
import { MatModal } from "components/material/mat-modal.component";
import { useAppDispatch } from "hooks/use-redux";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserInfoService } from "services/user-service";
import { ChangePasswordPayload } from "services/user-service/apis/user-info.api";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification
} from "store/common/notification/notification.actions";
import { VBInput } from "./vb-input.component";
import * as Sentry from "@sentry/react";

interface ChangePasswordModel {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface Props {
  visible: boolean;
  onClose?: () => void;
  textColor?: string;
  borderColor?: string;
  bgColor?: string;
  inputClassName?: string;
}

const INITIAL_DATA = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export const ChangePasswordModal = ({
  visible,
  onClose,
  textColor="text-warning-500",
  borderColor="border-warning-500",
  bgColor="bg-warning-500",
  inputClassName="focus:ring-warning-500 focus:border-warning-500",
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ChangePasswordModel>(INITIAL_DATA);
  const [errors, setErrors] = useState<any>({});
  const hasErrors = useMemo(() => {
    return Object.keys(errors).length > 0;
  }, [errors]);
  const [changed, setChanged] = useState(false);

  const setDataField = (field: string, value: string) => {
    validateField(field, value);
    setData({
      ...data,
      [field]: value,
    });
    setChanged(true);
  };

  const validateField = (field: string, value: string) => {
    if (!value) {
      setErrors({
        ...errors,
        [field]: t("common:changePasswordModal.errors.empty"),
      });
      return;
    }
    const length = value.length;
    if (length < 3 || length > 20) {
      setErrors({
        ...errors,
        [field]: t("common:changePasswordModal.errors.length"),
      });
      return;
    }
    if (field === "newPassword") {
      if (value === data.currentPassword) {
        setErrors({
          ...errors,
          [field]: t("common:changePasswordModal.errors.sameCurrent"),
        });
        return;
      }
    }
    if (field === "confirmPassword") {
      if (value !== data.newPassword) {
        setErrors({
          ...errors,
          [field]: t("common:changePasswordModal.errors.notSaveNew"),
        });
        return;
      }
    }

    if (errors.hasOwnProperty(field)) {
      const newErrors = { ...errors };
      delete newErrors[field];
      setErrors(newErrors);
    }
  };

  const getInitialErrors = () => {
    const initErros: any = {};
    for (let key of Object.keys(INITIAL_DATA)) {
      initErros[key] = t("common:changePasswordModal.errors.empty");
    }
    return initErros;
  };

  const reset = () => {
    setErrors(getInitialErrors());
    setData(INITIAL_DATA);
  };

  const handleClose = () => {
    reset();
    onClose && onClose();
  };

  const handleSaveChanges = async () => {
    try {
      setChanged(false);
      const payload: ChangePasswordPayload = {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      };
      await UserInfoService.changePassword(payload);
      dispatch(
        enqueueSuccessNotification(t("common:textChangePasswordSuccess"))
      );
      handleClose();
    } catch (err: any) {
      Sentry.captureException(err);
      dispatch(enqueueErrorNotification(t("common:textChangePasswordFailed")));
    }
  };

  useEffect(() => {
    const initErros: any = {};
    for (let key of Object.keys(INITIAL_DATA)) {
      initErros[key] = t("common:changePasswordModal.errors.empty");
    }
    setErrors(initErros);
  }, [setErrors, t]);

  return (
    <MatModal
      disableBackdropClick
      open={visible}
      BackdropComponent={Backdrop}
      closeAfterTransition
      onClose={handleClose}
      className="flex items-center justify-center"
    >
      <div
        className="grid w-1 grid-cols-1 gap-6 p-6 bg-white rounded"
        style={{ width: "26.25rem" }}
      >
        <div className={`flex justify-between font-bold ${textColor}`}>
          <p>{t("common:changePasswordModal.title")}</p>
          <button
            onClick={handleClose}
            className="hover:bg-white hover:text-red-500"
          >
            <IconCloseCircle className="w-6 h-6 text-background-500" />
          </button>
        </div>
        <VBInput
          required
          label="Current password"
          placeholder="Current password"
          type="password"
          value={data.currentPassword}
          onValueChanged={(value) => setDataField("currentPassword", value)}
          hint={errors["currentPassword"]}
          className={inputClassName}
        />
        <VBInput
          required
          label="New password"
          placeholder="New password"
          type="password"
          value={data.newPassword}
          onValueChanged={(value) => setDataField("newPassword", value)}
          hint={errors["newPassword"]}
          className={inputClassName}
        />
        <VBInput
          required
          label="Confirm password"
          placeholder="Confirm password"
          type="password"
          value={data.confirmPassword}
          onValueChanged={(value) => setDataField("confirmPassword", value)}
          hint={errors["confirmPassword"]}
          className={inputClassName}
        />
        <div className="flex flex-row-reverse gap-4 full">
          <button
            className={`flex items-center px-2 text-white rounded h-9 ${bgColor} disabled:opacity-50`}
            disabled={!changed || hasErrors}
            onClick={handleSaveChanges}
          >
            <span>{t("common:buttonSaveChanges")}</span>
          </button>
          <button
            className={`flex items-center px-8 bg-white border rounded h-9 ${textColor} ${borderColor}`}
            onClick={handleClose}
          >
            <span>{t("common:buttonCancel")}</span>
          </button>
        </div>
      </div>
    </MatModal>
  );
};
