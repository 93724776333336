/*
 * File: file-browse.provider.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 5:04:54 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { ReactNode, useCallback, useMemo, useState } from "react";
import { FileBrowseContext } from "./file-browse.context";
import { useFileFilter } from "../hooks/use-file-filter";
import { useFilesQuery } from "../hooks/use-files-query";
import { ICommand, ViewModeEnum } from "domain/common";
import { FileBrowseCommandEnum } from "../types";

interface FileBrowseProviderProps {
  children?: ReactNode;
}

export function FileBrowseProvider({ children }: FileBrowseProviderProps) {
  const { filter, setFilter } = useFileFilter();
  const { data, isLoading, reloadData } = useFilesQuery(filter);
  const [selectedFileIds, setSelectedFileIds] = useState<number[]>([]);
  const [fileId, setFileId] = useState(-1);
  const [viewMode, setViewMode] = useState(ViewModeEnum.GRID);

  const executeCommand = useCallback(async (command: ICommand<any>) => {
    if (command.id === FileBrowseCommandEnum.RELOAD_FILES) {
    } else if (command.id === FileBrowseCommandEnum.ADD_METADATA_SELECTION) {
    } else if (command.id === FileBrowseCommandEnum.DELETE_SELECTION) {
    } else if (command.id === FileBrowseCommandEnum.OPEN_NEXT_FILE) {
    } else if (command.id === FileBrowseCommandEnum.OPEN_PREVIOUS_FILE) {
    } else if (command.id === FileBrowseCommandEnum.OPEN_FILE) {
    } else if (command.id === FileBrowseCommandEnum.SELECT_FILE) {
    } else if (command.id === FileBrowseCommandEnum.CLOSE_FILE) {
    }
  }, []);

  const state = useMemo(() => {
    const pagination = data?.pagination ?? {
      page: filter.page,
      size: filter.size,
      totalCount: 0,
    };

    return {
      files: data?.data ?? [],
      pagination,
      loading: isLoading,
      filter,
      selectedFileIds,
      fileId,
      updateFilter: setFilter,
      selectFile: setSelectedFileIds,
      openFile: setFileId,
      reloadFiles: reloadData,
      executeCommand,
      viewMode,
      updateViewMode: setViewMode,
    };
  }, [
    data,
    filter,
    isLoading,
    selectedFileIds,
    fileId,
    setFilter,
    setSelectedFileIds,
    setFileId,
    reloadData,
    executeCommand,
    viewMode,
    setViewMode,
  ]);
  return (
    <FileBrowseContext.Provider value={state}>
      {children}
    </FileBrowseContext.Provider>
  );
}

export default FileBrowseProvider;
