/*
 * File: use-cornerstone-handler.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 9th August 2021 9:17:26 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */
import { useImageLabelingContext } from "pages/labeler/image-labeling/context/image-labeling.context";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect } from "react";
import {
  selectFullScreen,
  selectHeartRuler,
} from "store/labeler/image-workspace/cornerstone/cornerstone.selectors";
import screenfull from "screenfull";
import { selectActiveCornerstoneTool } from "store/labeler/image-workspace/dicom-editor/dicom-editor.selectors";
import { useImageEditorContext } from "../image-editor-context/image-editor.context";
import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";
import { selectSelectedObservationId } from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { freehandStrategyChanged } from "store/labeler/image-workspace/dicom-editor/dicom-editor.slice";
export const useCornerstoneHandler = (active?: boolean) => {
  const dispatch = useAppDispatch();
  const { showLabelDetail, imageLoaded } = useImageLabelingContext();
  const { cornerstoneHandler } = useImageEditorContext();

  const fullscreen = useAppSelector(selectFullScreen);
  const heartRuler = useAppSelector(selectHeartRuler);
  const activeTool = useAppSelector(selectActiveCornerstoneTool);
  const selectedObservationId = useAppSelector(selectSelectedObservationId);
  useEffect(() => {
    if (!screenfull.isEnabled) return;
    if (fullscreen) screenfull.request(document.body);
    else screenfull.exit();
  }, [fullscreen]);

  useEffect(() => {
    cornerstoneHandler.current?.setHeartRuler(heartRuler);
  }, [heartRuler, cornerstoneHandler]);

  useEffect(() => {
    cornerstoneHandler.current?.resize(true);
  }, [showLabelDetail, cornerstoneHandler]);

  useEffect(() => {
    if (!imageLoaded || !activeTool) return;
    if (activeTool !== ToolName.FreehandRoiExtend) {
      cornerstoneHandler.current?.modifyPolygon("", "");
    }
    if (!active) {
      cornerstoneHandler.current?.completeDrawing();
      cornerstoneHandler.current?.cancelDrawing();
      return;
    }
    cornerstoneHandler.current?.setToolActive(activeTool);
  }, [active, activeTool, cornerstoneHandler, imageLoaded]);

  useEffect(() => {
    if (!active || !imageLoaded) return;
    dispatch(freehandStrategyChanged(""));
    cornerstoneHandler.current?.modifyPolygon("", "");
  }, [
    dispatch,
    selectedObservationId,
    active,
    cornerstoneHandler,
    imageLoaded,
  ]);
};
