/*
 * File: viewport-editor.models.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 26th July 2021 11:22:15 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

export interface Viewport {
  hflip: boolean;
  invert: boolean;
  rotation: number;
  scale: number;
  translation: { x: number; y: number };
  vflip: boolean;
  voi: { windowWidth: number; windowCenter: number };
}

export function deepCloneViewport(viewport: Viewport): Viewport {
  return {
    ...viewport,
    translation: {
      ...viewport.translation,
    },
    voi: {
      ...viewport.voi,
    },
  };
}
