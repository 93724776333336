import { useWorkflowInstructions } from "hooks/workflow/use-workflow-instructions.hook";
import { useEffect, useMemo } from "react";
import classnames from "classnames";
import { StepType } from "services/label-service/dtos";
import { ProjectWorkflowStepView } from "./project-workflow-step-view.component";
import { StepData } from "../../project.type";
import { Tooltip } from "@material-ui/core";
import { ProjectWorkflowStepIcon } from "./project-workflow-step-icon.component";

interface ProjectWorkflowStepsProps {
  disabled?: boolean;
  selectedStepIndex: number;
  setSelectedStepIndex: (index: number) => void;
  steps?: StepData[];
  onChange?: (steps: StepData[]) => void;
  onChangeStep?: (step: Partial<StepData>) => void;
  onInit?: (steps: StepData[]) => void;
  onSelect?: () => void;
  workflowId: number;
}

export const ProjectWorkflowSteps = ({
  disabled = false,
  selectedStepIndex,
  steps = [],
  setSelectedStepIndex,
  onChange,
  onChangeStep,
  onInit,
  onSelect,
  workflowId,
}: ProjectWorkflowStepsProps) => {
  const { instructions } = useWorkflowInstructions(workflowId);

  const hasReviewStep = useMemo(() => {
    return steps.findIndex((step) => step.type === StepType.REVIEW) !== -1;
  }, [steps]);

  const hasAcceptanceStep = useMemo(() => {
    return steps.findIndex((step) => step.type === StepType.ACCEPTANCE) !== -1;
  }, [steps]);

  useEffect(() => {
    const steps = instructions.map(
      (instruction) =>
        ({
          position: instruction.step,
          type: instruction.stepType || StepType.NORMAL,
          numberOfRounds: instruction.roundNumber,
          readonly: false,
          name: instruction.name,
          description: instruction.description || "",
          canViewPreviousStepResult:
            instruction.canViewPreviousStepResult || false,
          condition: instruction.condition || "",
          scoreThreshold: instruction.scoreThreshold || 0
        } as StepData)
    );
    onInit && onInit(steps);
  }, [instructions, onInit]);

  const handleSelectStep = (data: Partial<StepData>) => {
    onSelect && onSelect();
    data.position && setSelectedStepIndex(data.position);
  };

  const handleRemoveStep = (data: Partial<StepData>) => {
    const newSteps = steps
      .filter((step) => step.position !== data.position)
      .map((item, idx) => {
        return { ...item, position: idx + 1 };
      });

    onChange && onChange(newSteps);
  };

  const handleAddLabelingStep = () => {
    const newPosition =
      steps.filter((step) => step.type === StepType.NORMAL).length + 1;

    const newLabelStep: StepData = {
      position: newPosition,
      name: "Labeling Stage",
      description: "",
      numberOfRounds: 1,
      type: StepType.NORMAL,
      condition: "",
    };

    const newSteps = [];
    let isAdded = false;
    for (const step of steps) {
      if (!step.type || step.type === StepType.NORMAL) {
        newSteps.push(step);
      } else {
        if (!isAdded) {
          newSteps.push(newLabelStep);
          isAdded = true;
        }
        newSteps.push({
          ...step,
          position: step.position + (isAdded ? 1 : 0),
        });
      }
    }
    if (!isAdded) newSteps.push(newLabelStep);
    onChange && onChange(newSteps);
    setSelectedStepIndex(newLabelStep.position);
  };

  const handleAddVerificationStep = (stepType: StepType) => {
    const newPosition = Math.max(
      steps.length + 1,
      steps.filter((step) => step.type === stepType).length + 2
    );
    const newStep: StepData = {
      position: newPosition,
      numberOfRounds: 1,
      name:
        stepType === StepType.ACCEPTANCE ? "Acceptance Step" : "Review Step",
      description: "",
      readonly: true,
      type: stepType,
      condition: "",
      canViewPreviousStepResult: true,
    };
    onChange && onChange([...steps, newStep]);
    setSelectedStepIndex(newPosition);
  };

  return (
    <div className="flex flex-col items-center w-full">
      {steps.map((step) => (
        <ProjectWorkflowStepView
          key={step.position}
          disabled={disabled}
          step={step}
          selected={step.position === selectedStepIndex}
          onSelect={handleSelectStep}
          onRemove={handleRemoveStep}
          onChange={onChangeStep}
        />
      ))}

      {!disabled && !hasAcceptanceStep && (
        <div className="w-full pr-10">
          <div
            className="relative w-full text-center"
            style={{ lineHeight: 0 }}
          >
            <div className="inline-block w-0.5 h-20 bg-primary-500"></div>
          </div>

          <div
            className={classnames("flex items-center justify-center w-full", {
              "cursor-not-allowed pointer-events-none": disabled,
            })}
          >
            <div className="px-8 py-4 space-y-2 rounded-lg bg-background-200">
              <div className="text-primary">+ Add step</div>
              <div className="flex items-center justify-center gap-2">
                {
                  <Tooltip title="Labeling">
                    <button onClick={handleAddLabelingStep}>
                      <ProjectWorkflowStepIcon type={StepType.NORMAL} />
                    </button>
                  </Tooltip>
                }
                {!hasReviewStep && (
                  <Tooltip title="Review">
                    <button
                      onClick={() => handleAddVerificationStep(StepType.REVIEW)}
                    >
                      <ProjectWorkflowStepIcon type={StepType.REVIEW} />
                    </button>
                  </Tooltip>
                )}

                <Tooltip title="Acceptance">
                  <button
                    onClick={() =>
                      handleAddVerificationStep(StepType.ACCEPTANCE)
                    }
                  >
                    <ProjectWorkflowStepIcon type={StepType.ACCEPTANCE} />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
