/*
 * File: batch-detail.page.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { VBSpacer } from "components/common/vb-spacer.component";

import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { BatchTasksPage } from "./pages/tasks/batch-tasks.page";
import { BatchWorkflowPage } from "./pages/workflow/batch-workflow.page";
import { BatchDatasourcePage } from "./pages/datasource/batch-datasource.page";
import { BatchLabelsPage } from "./pages/labels/batch-labels.page";
import { BatchOverviewPage } from "./pages/overview/batch-overview.page";
import { VBCreateButton } from "components/common/vb-create-button.component";
import { BatchStatus } from "services/label-service/dtos";
import { BatchExportPage } from "./pages/export/batch-export.page";
import { DatasetProvider } from "pages/customer/datasets/context/dataset-provider";
import { BatchSettingsPage } from "./pages/settings/batch-settings.page";
import { BatchDataCleaningPage } from "./pages/data-cleaning/batch-data-cleaning.page";
import { BatchDetailProvider } from "./context/batch-detail.provider";
import { useBatchDetailContext } from "./context/batch-detail.context";
import { BatchPricePage } from "./pages/price/batch-price.page";
import { BatchPaymentPage } from "./pages/payment/batch-payment.page";
import { isDevelopment } from "configs/env.config";
import { BatchDataPage } from "./pages/data/batch-data.page";
import { BatchDataProvider } from "./pages/data/batch-data.provider";
import { useBatch } from "hooks/batch/use-batch.hook";
import { VBComponentRequesting } from "components/common/vb-component-requesting/vb-component-requesting.component";

export enum BatchRoutes {
  OVERVIEW = "",
  DATA = "/data",
  DATASOURCE = "/data/datasource",
  TASKS = "/data/tasks",
  EXPORT = "/data/export",
  LABELS = "/setting/labels",
  SETTINGS = "/setting",
  WORKFLOW = "/setting/workflow",
  DATA_CLEANING = "/setting/data-cleaning",
  PRICE = "/setting/price",
  PAYMENT = "/setting/payment",
}

export const BatchDetailPage = () => {
  const { path } = useRouteMatch();
  const { batchId } = useParams<{ batchId: string }>();
  const { batch } = useBatch(batchId);
  if (!batch) return <VBComponentRequesting />;

  return (
    <BatchDetailProvider batch={batch}>
      <DatasetProvider>
        <Switch>
          <Route
            exact
            path={`${path}${BatchRoutes.OVERVIEW}`}
            component={BatchOverviewPage}
          />
          <Route
            exact
            path={`${path}${BatchRoutes.DATA}`}
            component={() => (
              <BatchDataProvider>
                <BatchDataPage />
              </BatchDataProvider>
            )}
          />
          <Route
            exact
            path={`${path}${BatchRoutes.DATASOURCE}`}
            component={BatchDatasourcePage}
          />
          <Route
            exact
            path={`${path}${BatchRoutes.LABELS}`}
            component={BatchLabelsPage}
          />
          <Route
            exact
            path={`${path}${BatchRoutes.WORKFLOW}`}
            component={BatchWorkflowPage}
          />
          <Route
            exact
            path={`${path}${BatchRoutes.TASKS}`}
            component={BatchTasksPage}
          />
          <Route
            exact
            path={`${path}${BatchRoutes.EXPORT}`}
            component={BatchExportPage}
          />
          <Route
            exact
            path={`${path}${BatchRoutes.DATA_CLEANING}`}
            component={BatchDataCleaningPage}
          />
          {isDevelopment() && (
            <Route
              exact
              path={`${path}${BatchRoutes.PRICE}`}
              component={BatchPricePage}
            />
          )}
          {isDevelopment() && (
            <Route
              exact
              path={`${path}${BatchRoutes.PAYMENT}`}
              component={BatchPaymentPage}
            />
          )}
          <Route
            exact
            path={`${path}${BatchRoutes.SETTINGS}`}
            component={BatchSettingsPage}
          />
        </Switch>
      </DatasetProvider>
    </BatchDetailProvider>
  );
};

export const BatchDetailHeader = () => {
  const { t } = useTranslation();
  const { batch, startBatch, canStartBatch, requesting } =
    useBatchDetailContext();
  return (
    <div className="flex items-center px-4 my-4">
      <VBSpacer />
      {batch?.status === BatchStatus.INITIAL && (
        <VBCreateButton
          text={t("project:batchDetails.buttonStartBatch")}
          onClick={startBatch}
          disabled={!canStartBatch || requesting}
        />
      )}
    </div>
  );
};
