/*
 * File: annotations-tree.helper.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 18th April 2023 10:05:01 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Annotation, Label } from "domain/image-labeling";
import { TreeItem } from "./use-object-tree";

export function buildAnnotationTree(
  annotations: Annotation[],
  labels: Label[]
): TreeItem[] {
  const labelTreeItems: TreeItem[] = labels
    .map((item) => {
      return {
        id: item.id.toString(),
        name: item.name,
        color: item.color,
        type: item.annotateType?.toString()?.toLowerCase(),
        hidden: item.hidden,
        objectType: "label",
      };
    })
    .filter((item) => item.type !== "system");
  const annos: TreeItem[] = annotations.map((anno) => {
    return {
      id: anno.id.toString(),
      name: anno.uuid,
      parentId: anno.labelId.toString(),
      hidden: !anno.visible,
      locked: anno.locked,
      objectType: "annotation",
      description: anno.frameId ? `Frame: ${anno.frameId+1}` : undefined,
    };
  });
  return [...labelTreeItems, ...annos];
}
