/*
 * File: grid-pagination.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 1st December 2021 2:52:38 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useMemo } from "react";
import Select, { SingleValue } from "react-select";
import { IconPrevious, IconNext } from "../vb-icon.component";

interface Props {
  totalItems?: number;
  page?: number;
  pageSize?: number;
  pageSizeOptions?: number[];
  onPageSizeChange?(pageSize: number): void;
  onPageChange?(page: number): void;
}
export const GridPagination = ({
  totalItems = 0,
  page = 1,
  pageSize = 10,
  pageSizeOptions = [5, 10, 15, 20, 25, 50, 75, 100],
  onPageChange,
  onPageSizeChange,
}: Props) => {
  const options = useMemo(() => {
    return pageSizeOptions.map((option) => ({
      label: option.toString(),
      value: option,
    }));
  }, [pageSizeOptions]);

  const option = useMemo(() => {
    return options.find((opt) => opt.value === pageSize);
  }, [pageSize, options]);

  const maxPage = useMemo(() => {
    if (totalItems === 0) return 1;
    return (
      Math.floor(totalItems / pageSize) + (totalItems % pageSize === 0 ? 0 : 1)
    );
  }, [totalItems, pageSize]);

  const pageOptions = useMemo(() => {
    const items = [];
    for (let i = 1; i <= maxPage; i++) {
      items.push({ label: i.toString(), value: i });
    }
    return items;
  }, [maxPage]);

  const pageOption = useMemo(() => {
    return pageOptions.find((opt) => opt.value === page);
  }, [page, pageOptions]);

  const handlePageSizeChange = (
    newValue: SingleValue<{ label: string; value: number }>
  ) => {
    if (!newValue) return;
    onPageSizeChange && onPageSizeChange(newValue?.value);
  };

  const handlePageChange = (
    newValue: SingleValue<{ label: string; value: number }>
  ) => {
    if (!newValue) return;
    onPageChange && onPageChange(newValue?.value);
  };

  const handleNext = () => {
    if (page < maxPage) {
      onPageChange && onPageChange(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      onPageChange && onPageChange(page - 1);
    }
  };

  const hasNext = page < maxPage;
  const hasPrevious = page > 1;
  const offset = totalItems > 0 ? 1 : 0;
  const from = (page - 1) * pageSize + offset;
  const to = Math.min(pageSize * page, totalItems);

  return (
    <div className="flex items-center justify-between flex-shrink-0">
      <div className="flex items-center gap-2">
        <span className="hidden sm:block">Display</span>
        <Select
          classNamePrefix="vb-select-pagination"
          value={option}
          options={options}
          menuPlacement="top"
          onChange={handlePageSizeChange}
        />
        <span className="hidden sm:block">
          {from}-{to} of {totalItems} results
        </span>
      </div>

      <div className="flex items-center gap-3">
        <button
          className={`flex items-center h-8 gap-2 px-3 ${
            hasPrevious ? "text-primary" : "text-background-300 cursor-default"
          }`}
          disabled={!hasPrevious}
          onClick={handlePrevious}
        >
          <IconPrevious className="w-4 h-4" />
          <span className="hidden sm:block">Prev</span>
        </button>
        <Select
          classNamePrefix="vb-select-pagination"
          value={pageOption}
          options={pageOptions}
          menuPlacement="top"
          onChange={handlePageChange}
        />
        <span>of {maxPage}</span>
        <button
          className={`flex items-center h-8 gap-2 px-3 ${
            hasNext ? "text-primary" : "text-background-300 cursor-default"
          }`}
          disabled={!hasNext}
          onClick={handleNext}
        >
          <span className="hidden sm:block">Next</span>
          <IconNext className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};
