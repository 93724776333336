/*
 * File: tags.api.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 18th August 2021 2:00:33 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { storageAPIget, storageAPIpost } from "../storage-httpclient";

export function createTag(data = {}) {
  return storageAPIpost(`api/v1/tags`, data);
}

export function getAllTags() {
  return storageAPIget(`api/v1/tags?size=500`);
}

export function getAllTagsMatches(tagNames: string) {
  const params = new URLSearchParams({ "name.contains": tagNames });
  return storageAPIget(`api/v1/tags?${params.toString()}`);
}

export function manageTag(data = {}) {
  return storageAPIpost(`api/v1/tags/manage`, data);
}
