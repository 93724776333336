import { useMount } from "ahooks";
import {
  IconInformationCircle,
  IconCheckedCircle,
  IconError,
  IconClose,
} from "components/common/vb-icon.component";
import { useWorkspaceContext } from "contexts/workspace";
import { TaskRunner, TaskRunnerStatus } from "domain/customer/task-runner";
import { useAppPrevious } from "hooks/use-app-previous";
import { useAppDispatch } from "hooks/use-redux";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { downloadUrlAsFile } from "services/common/common.apis";
import { taskRunnerRemoved } from "store/customer/task-runner/task-runner.slice";
import { classnames } from "utilities/classes";
import { TaskProgresss } from "../task-progress/task-progress";

interface Props {
  index?: number;
  task: TaskRunner;
  onClose(): void;
}
export const DownloadDatasetMessage = ({ index = 0, task, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [mounted, setMounted] = useState(false);

  const [fileName, setFileName] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");

  const { getUrlWithSasToken } = useWorkspaceContext();

  useMount(() => setTimeout(() => setMounted(true), 500));

  const isCompleted = task.status === TaskRunnerStatus.SUCCEEDED;
  const isFailed = task.status === TaskRunnerStatus.FAILED;
  const isRunning = task.status === TaskRunnerStatus.RUNNING;
  const isInitialized = task.status === TaskRunnerStatus.INITIAL;

  const previousTask = useAppPrevious(task);

  function handleDismiss() {
    dispatch(taskRunnerRemoved(task));
  }

  useEffect(() => {
    if (
      previousTask &&
      task &&
      previousTask.id === task.id &&
      previousTask.status === TaskRunnerStatus.SUCCEEDED
    )
      return;
    if (task.status !== TaskRunnerStatus.SUCCEEDED) return;
    const item = JSON.parse(task.payload);
    if (item && item.hasOwnProperty("batchId")) {
      const data = JSON.parse(task.data);
      const url = data["downloadUrl"] || "";
      const sasUrl = getUrlWithSasToken(url);
      const fileName = item["fileName"] || "";
      setDownloadUrl(sasUrl);
      setFileName(fileName);
      if (sasUrl && fileName) {
        downloadUrlAsFile(sasUrl, fileName);
      }
    }
  }, [task, getUrlWithSasToken, previousTask]);

  const title = {
    [TaskRunnerStatus.INITIAL]: "Start download dataset",
    [TaskRunnerStatus.RUNNING]:
      "Download will start automatically as soon as the dataset is ready ...",
    [TaskRunnerStatus.SUCCEEDED]: "Generated download url successfully",
    [TaskRunnerStatus.FAILED]: "Generated download url failed",
    [TaskRunnerStatus.CANCELED]: "Generated download url canceled",
  };
  const description = {
    [TaskRunnerStatus.INITIAL]: "",
    [TaskRunnerStatus.RUNNING]:
      "Large batch might take long time. Please wait...",
    [TaskRunnerStatus.SUCCEEDED]: "",
    [TaskRunnerStatus.FAILED]: task.error,
    [TaskRunnerStatus.CANCELED]: "",
  };

  return createPortal(
    <div
      className={classnames(
        "fixed p-4  rounded shadow-2xl bottom-10 right-8 text-background-700 transform transition-all",
        {
          "bg-white": isRunning || isInitialized,
          "bg-success-50": isCompleted,
          "bg-red-50": isFailed,
          "opacity-100": mounted,
          "translate-y-4 opacity-0 pointer-events-none": !mounted,
        }
      )}
      style={{
        zIndex: 99999 - index,
        width: 400,
        transform: mounted ? `translateY(-${16 + 180 * index}px)` : "",
      }}
    >
      <div className="flex gap-4 py-2">
        <div className="p-0.5">
          {task.status === TaskRunnerStatus.RUNNING && (
            <IconInformationCircle className="w-6 h-6 text-primary" />
          )}
          {task.status === TaskRunnerStatus.SUCCEEDED && (
            <IconCheckedCircle className="w-6 h-6 text-success-500" />
          )}
          {task.status === TaskRunnerStatus.FAILED && (
            <IconError className="w-6 h-6 text-red-500" />
          )}
        </div>
        <div className="flex-auto space-y-2">
          <div className="text-lg font-semibold">
            <div dangerouslySetInnerHTML={{ __html: title[task.status] }} />
          </div>
          <div className="w-full">
            <div
              dangerouslySetInnerHTML={{ __html: description[task.status] }}
            />
          </div>
          <div className="w-full">
            <TaskProgresss value={task.percentComplete} />
          </div>
          <div className="flex items-center gap-2">
            {isCompleted && (
              <>
                <a
                  className="underline text-primary"
                  href={downloadUrl}
                  download={fileName || "File.zip"}
                >
                  Download url
                </a>
                <div className="w-1 h-1 rounded-full bg-background-300" />
              </>
            )}
            <button
              className="text-primary hover:underline"
              onClick={handleDismiss}
            >
              Dismiss
            </button>
          </div>
        </div>
        <button
          onClick={onClose}
          className="flex items-center justify-center flex-none w-8 h-8 rounded-full"
        >
          <IconClose className="flex-none w-6 h-6" />
        </button>
      </div>
    </div>,
    document.body
  );
};
