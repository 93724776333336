/*
 * File: distribution-tabs.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 26th August 2021 6:23:18 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from 'react-i18next';
import { Radio } from "@material-ui/core";

export enum DistributionView {
  LABEL = "label",
  LABELER = "labeler",
}

const ViewModeOptions = [
  { view: DistributionView.LABEL, text: "Label distribution" },
  { view: DistributionView.LABELER, text: "Labeler distribution" },
];

interface Props {
  options?: { view: DistributionView; text: string }[];
  selectedView: DistributionView;
  onChange?(view: DistributionView): void;
}

export const DistributionTabs = ({
  selectedView,
  options = ViewModeOptions,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center gap-4">
      {options.map((tab) => {
        const isActive = tab.view === selectedView;
        return (
          <button key={tab.view} onClick={() => onChange && onChange(tab.view)}>
            <Radio checked={isActive} color="primary" />
            <span className={isActive ? "text-primary" : ""}>{t(tab.text)}</span>
          </button>
        );
      })}
    </div>
  );
};
