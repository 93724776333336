/*
 * File: task.api.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 20th July 2021 3:14:35 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { StepConditionDTO } from "../dtos/step-condition.dto";
import { BaseAPI } from "./base.api";

export class StepConditionAPI extends BaseAPI<StepConditionDTO> {}
