import { ReactNode, useCallback, useMemo, useState } from "react";
import { useLabelerTasksFilter } from "../hooks/use-labeler-tasks-filter";
import { useLabelerTasks } from "../hooks/use-labeler-tasks";
import { UniqueId } from "domain/common";
import { EntityAction } from "domain/common/entity-action";
import { ILabelerTaskEntity } from "../types";
import { LabelerTasksContext } from "./labeler-tasks-context";
import { useHistory } from "react-router-dom";
import { useProjectsByTaskCompleted } from "pages/labeler/labeler-dashboard/use-projects-by-task-completed";
import { getReviewRoute } from "constants/labeling.constant";

export interface Props {
  children?: ReactNode;
}

export function LabelerTasksProvider({ children }: Props) {
  const { filter, updateFilter } = useLabelerTasksFilter();
  const history = useHistory();
  const { data, loading, pagination, error, reloadData } =
    useLabelerTasks(filter);
  const [selectedIds, setSelectedIds] = useState<UniqueId[]>([]);
  const { getProjectById } = useProjectsByTaskCompleted();

  const project = useMemo(() => {
    if (!filter.projectId) return undefined;
    try {
      const projectId = parseInt(filter.projectId);
      const project = getProjectById(projectId);
      return project;
    } catch (error) {
      return undefined;
    }
  }, [filter, getProjectById]);

  const toggleSelectEntity = useCallback(
    (entityId: UniqueId) => {
      if (selectedIds.includes(entityId)) {
        setSelectedIds(selectedIds.filter((id) => id !== entityId));
      } else {
        setSelectedIds([...selectedIds, entityId]);
      }
    },
    [selectedIds]
  );

  const toggleSelectEntities = useCallback(() => {
    if (data.length === 0) return;
    if (selectedIds.length === data.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(data.map((entity) => entity.id));
    }
  }, [selectedIds, data]);

  function onSelect(_item: ILabelerTaskEntity, action?: string) {
    if (!action || action === EntityAction.SELECT) {
      // const id = item.id.toString();
      // const url = `${ROUTES.TOPICS}/${SUB_ROUTES.ID}`.replace(":id", id);
      // navigate(url);
      return;
    }
    // setEntityAction({ entities: [item], action });
  }

  function onSelectEntities(items: ILabelerTaskEntity[], action?: string) {
    if (!project) return;
    if (EntityAction.VIEW === action) {
      const jobId = items.map((item) => item.jobId).join(",");
      const route = getReviewRoute(project?.type);
      const url = route.replace(":jobId", jobId.toString());
      history.push(url);
      return;
    }
    if (EntityAction.DELETE === action) {
      // setEntityAction({ entities: items, action });
      return;
    }
  }

  const state = {
    loading,
    project,
    entities: data,
    pagination,
    error,
    selectedIds,
    reloadData,
    filter,
    onSelect,
    onSelectEntities,
    toggleSelectEntity,
    toggleSelectEntities,
    updateFilter,
  };

  return (
    <LabelerTasksContext.Provider value={state}>
      {children}
    </LabelerTasksContext.Provider>
  );
}

export default LabelerTasksProvider;
