/*
 * File: ai-image-modal.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 23rd February 2022 3:02:16 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Backdrop, Grow } from "@material-ui/core";
import { useKeyPress } from "ahooks";
import { IconClose } from "components/common/vb-icon.component";
import { MatModal } from "components/material/mat-modal.component";
import { AIImage } from "domain/web-builder";
import { useRef } from "react";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { AIResultPreview } from "../ai-result-preview/ai-result-preview.component";

interface AIImageModalProps {
  onClose(): void;
  onNext(): void;
  onPrevious(): void;
  image: AIImage;
}

export const AIImageModal = ({
  onClose,
  image,
  onPrevious,
  onNext,
}: AIImageModalProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useKeyPress(KeyboardKey.ArrowLeft, onPrevious, {
    target: containerRef.current,
  });

  useKeyPress(KeyboardKey.ArrowRight, onNext, {
    target: containerRef.current,
  });

  return (
    <MatModal
      open
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={onClose}
      className="flex items-center justify-center outline-none"
    >
      <Grow in>
        <div
          style={{ width: "80vw", maxHeight: "80vh", maxWidth: 1200 }}
          className="relative flex items-center justify-center w-full h-full bg-white rounded outline-none"
          ref={containerRef}
        >
          <AIResultPreview
            className="flex items-center justify-center w-full h-full overflow-hidden rounded"
            key={image.imageUrl}
            imageUrl={image.imageUrl}
            status={image.status}
            labels={image.labels}
            annotations={image.annotations}
            active
          />

          <ButtonClose onClick={onClose} />
        </div>
      </Grow>
    </MatModal>
  );
};

const ButtonClose = ({ onClick }: { onClick?(): void }) => {
  return (
    <div className="absolute top-0 right-0 z-50 p-4 child-on-hover">
      <button
        onClick={onClick}
        className="flex items-center justify-center w-6 h-6 overflow-hidden bg-white rounded-full"
      >
        <IconClose className="flex-none w-4 h-4 text-background-700" />
      </button>
    </div>
  );
};
