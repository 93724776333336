/*
 * File: image-annotations.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 21st April 2022 3:37:18 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ImageWorkspaceState } from "../image-workspace.state";
import { acceptVisibleAnnotationsBuilder } from "./thunks/accept-visible-annotations.thunk";
import { imageAnnotationCompletedBuilder } from "./thunks/image-annotation-completed.thunk";
import { imageAnnotationRemovedBuilder } from "./thunks/image-annotation-removed.thunk";
import { imageAnnotationUpdatedBuilder } from "./thunks/image-annotation-updated.thunk";
import { imageAnnotationDownVotedBuilder } from "./thunks/image-annotation-downvoted.thunk";
import { imageAnnotationVotedBuilder } from "./thunks/image-annotation-voted.thunk";
import { imageJobAnnotationsLoadedBuilder } from "./thunks/image-job-annotations-loaded.thunk";
import { imageLabelingJobLoadedBuilder } from "./thunks/image-labeling-job-loaded.thunk";
import { imageRelationAnnotationCompletedBuilder } from "./thunks/image-relation-annotation-completed.thunk";
import { imageRelationAnnotationRemovedBuilder } from "./thunks/image-relation-annotation-removed.thunk";
import { imageRelationAnnotationUpdatedBuilder } from "./thunks/image-relation-annotation-updated.thunk";
import { imageSystemLabelSelectedBuilder } from "./thunks/image-system-label-selected.thunk";
import { imageClassificationAttributeUpdatedBuilder } from "./thunks/image-classification-attribute-updated.thunk";
import { imageJobAnnotationsResetedBuilder } from "./thunks/image-job-annotations-reseted.thunk";
import { imageAnnotationsAddedBuidler } from "./thunks/image-annotations-added.thunk";
import { imageClassificationLabelSelectedBuilder } from "./thunks/image-classification-label-selected.thunk";
import { imageViewPreviousJobBuilder } from "./thunks/image-view-previous-jobs.thunk";

export const imageAnnotationsReducerBuilder = (
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) => {
  acceptVisibleAnnotationsBuilder(builder);
  imageAnnotationCompletedBuilder(builder);
  imageAnnotationRemovedBuilder(builder);
  imageAnnotationUpdatedBuilder(builder);
  imageAnnotationVotedBuilder(builder);
  imageAnnotationDownVotedBuilder(builder);
  imageAnnotationsAddedBuidler(builder);
  imageJobAnnotationsLoadedBuilder(builder);
  imageJobAnnotationsResetedBuilder(builder);
  imageLabelingJobLoadedBuilder(builder);
  imageRelationAnnotationCompletedBuilder(builder);
  imageRelationAnnotationRemovedBuilder(builder);
  imageRelationAnnotationUpdatedBuilder(builder);
  imageSystemLabelSelectedBuilder(builder);
  imageClassificationLabelSelectedBuilder(builder);
  imageClassificationAttributeUpdatedBuilder(builder);
  imageViewPreviousJobBuilder(builder);
};
