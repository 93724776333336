/*
 * File: image-annotations.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 21st April 2022 3:37:18 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AnnotationData } from "domain/image-labeling";
import { ImageWorkspaceState } from "../../image-workspace.state";

const THUNK_NAME = "imageWorkspace/imageAnnotationUpdatedAsync";

interface ImageAnotationPayload<T> {
  jobId: number;
  data: T;
}

export const imageAnnotationUpdatedAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: ImageAnotationPayload<AnnotationData[]>) => {
    return payload;
  }
);

export const imageAnnotationUpdatedBuilder = (
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) => {
  return builder.addCase(
    imageAnnotationUpdatedAsync.fulfilled,
    ({ imageAnnotations }, action) => {
      const { jobId, data: annotationData } = action.payload;
      if (action && action.payload) {
        imageAnnotations.annotations = imageAnnotations.annotations.map(
          (anno) => {
            if (anno.jobId === jobId && anno.uuid === annotationData[0].uuid)
              return {
                ...anno,
                annotationData: annotationData.map((item, index) => {
                  if (index === 0) {
                    return {
                      ...item,
                      attributes: anno.annotationData[0].attributes,
                    };
                  }
                  return item;
                }),
              };
            return anno;
          }
        );
      }
    }
  );
};
