/*
 * File: instruction-select-user.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 18th August 2021 9:42:23 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useStepConditions } from "hooks/workflow/use-step-conditions.hook";
import { useEffect } from "react";
import { useState } from "react";
import { StepType, WorkflowInstructionDTO } from "services/label-service/dtos";
import { UserBatchModel, UserDTO } from "services/user-service/dtos/user.dto";
import { UserSelector } from "./step-4-add-members.component";
import {
  ReviewStepConfigs,
  ReviewStepConfigsModel,
} from "./review-step-configs.component";
import { VBCheckbox } from "components/design-system/checkbox/checkbox.component";

interface Props {
  title?: string;
  instructions: WorkflowInstructionDTO[];
  users: UserBatchModel[];
  hideCondition?: boolean;
  reviewConfigs?: Record<number, ReviewStepConfigsModel>;
  stepUsers?: {
    instruction: WorkflowInstructionDTO;
    users: UserDTO[];
  }[];
  onChange(instruction: WorkflowInstructionDTO, users: UserBatchModel[]): void;
  onReviewConfigsChanged?: (
    step: number,
    config: ReviewStepConfigsModel
  ) => void;
}

export const InstructionUsersSelector = ({
  title = "Instructions",
  instructions,
  users,
  hideCondition = false,
  reviewConfigs,
  stepUsers,
  onChange,
  onReviewConfigsChanged,
}: Props) => {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<WorkflowInstructionDTO[]>([]);

  const { bindConditions } = useStepConditions();

  useEffect(() => {
    const items = [...instructions];
    setSteps(items.sort((a, b) => a.step - b.step));
  }, [instructions]);

  const shouldShowReviewConfig = (instruction: WorkflowInstructionDTO) => {
    if (!stepUsers || !reviewConfigs) return false;
    const stepUser = stepUsers.find(
      (su) => su.instruction.step === instruction.step
    );
    return (
      instruction.stepType === StepType.ACCEPTANCE &&
      stepUser &&
      stepUser.users.length > 0
    );
  };

  return (
    <div>
      <div className="text-lg font-medium">{title}</div>

      <div className="flex w-full gap-4 py-4">
        {steps.map((step, index) => {
          return (
            <div key={step.id}>
              <div className="flex items-center">
                {index > 0 && (
                  <div className="flex flex-col items-center mx-8">
                    <div>
                      <i className="text-3xl uir-arrow-right text-background-500"></i>
                    </div>
                    {!hideCondition && (
                      <>
                        <div className="whitespace-nowrap text-background-500">
                          {t("project:batch.textTransitionCondition")}:
                        </div>
                        <div
                          className="px-4 py-4 text-center capitalize"
                          style={{ maxWidth: "16rem" }}
                        >
                          {bindConditions(step.condition)}
                        </div>
                      </>
                    )}
                  </div>
                )}
                <div className="flex flex-col h-56 overflow-hidden bg-white border rounded w-72 border-background-300">
                  <div className="flex-shrink-0 px-4 py-3 overflow-hidden border-b border-background-300 text-primary whitespace-nowrap overflow-ellipsis bg-secondary-50">
                    {step.name}
                  </div>
                  <div className="flex-grow px-4 py-4 overflow-y-auto">
                    <p className="text-sm text-background-500">
                      <span>Round(s): </span> {step.roundNumber}
                    </p>
                    {step.canViewPreviousStepResult && (
                      <div className="flex items-center gap-1 mt-2 text-sm text-background-500">
                        <VBCheckbox value={true} className="w-4 h-4" />
                        <span>
                          {t("workflow:instruction.headerShowPrevious")}
                        </span>
                      </div>
                    )}
                    <div
                      className="ck-content"
                      dangerouslySetInnerHTML={{ __html: step.description }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end w-full">
                <div className="w-72">
                  <UserSelector
                    onChange={(users) => onChange(step, users)}
                    text={t("project:batch.textAddLabelers")}
                    instruction={instructions[index]}
                  />
                </div>
              </div>
              {reviewConfigs && shouldShowReviewConfig(step) && (
                <div className="flex justify-end w-full">
                  <div className="w-72">
                    <ReviewStepConfigs
                      config={reviewConfigs[step.step]}
                      onConfigChanged={(newConfig) =>
                        onReviewConfigsChanged &&
                        onReviewConfigsChanged(step.step, newConfig)
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
