/*
 * File: image-annotations.util.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 16th May 2022 4:31:20 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AnnotateType } from "constants/annotation.constant";
import {
  Annotation,
  AnnotationAttribute,
  AnnotationData,
  Label,
} from "domain/image-labeling";
import { JobAnnotation } from "domain/image-labeling/job-annotation";
import {
  RelationAnnotation,
  RelationAnnotationData,
} from "domain/image-labeling/relation-annotation";
import { AnnotationSource } from "domain/labeling/annotation-source";
import { RootState } from "store";
import { Point } from "utilities/math/point";
import { newUniqueId } from "utilities/number/id-generator";
import { v4 } from "uuid";
import {
  selectAnnotationVisible,
  selectAnnotationFill,
  selectDisplayTextValue,
} from "../cornerstone/cornerstone.selectors";
import {
  selectCurrentObservation,
  selectObservationById,
} from "../image-labeling/image-labeling.selectors";
import {
  FindingAgreement,
  FindingLabeler,
} from "../image-labeling/image-labeling.state";
import Flatten from "@flatten-js/core";

function toEntities(annotations: Annotation[]) {
  const entities: Record<string, Annotation> = {};
  for (const annotation of annotations) {
    entities[annotation.uuid] = annotation;
  }
  return entities;
}

function toAnnotationEntities(annotations: Annotation[]) {
  const entities: Record<number, Annotation> = {};
  for (const annotation of annotations) {
    entities[annotation.id] = annotation;
  }
  return entities;
}

function toRelationEntities(annotations: RelationAnnotation[]) {
  const entities: Record<string, RelationAnnotation> = {};
  for (const annotation of annotations) {
    entities[annotation.uuid] = annotation;
  }
  return entities;
}

function createNewAnnotation(
  jobId: number,
  annotationData: AnnotationData[],
  state: RootState,
  frameId?: number,
  labelId?: number
) {
  const job = state.batchLabeling.imageLabelingJobs.entities[jobId].job;
  const observation = labelId
    ? selectObservationById(labelId.toString())(state)
    : selectCurrentObservation(state);
  const showLabel = selectAnnotationVisible(state);
  const fillShape = selectAnnotationFill(state);
  const displayTextValue = selectDisplayTextValue(state);
  if (!job || !observation) return null;
  const annotation: Annotation = {
    id: newUniqueId(),
    jobId: job.id,
    labelId: observation.id,
    uuid: annotationData[0].uuid,
    annotator: job.assignee,
    source: AnnotationSource.CLIENT,
    annotationData: annotationData,
    objectTrackingId: "",
    color: observation.color || "",
    label: observation.name,
    showLabel: showLabel,
    fillShape: fillShape,
    displayTextValue: displayTextValue,
    visible: true,
    locked: false,
    numberOfPoint: observation.numberOfPoint,
    frameId,
  };
  return annotation;
}

function createNewRelationAnnotation(
  jobId: number,
  annotationData: RelationAnnotationData,
  state: RootState
) {
  const job = state.batchLabeling.imageLabelingJobs.entities[jobId].job;
  const annotation: RelationAnnotation = {
    id: newUniqueId(),
    jobId: jobId,
    labelId: -1,
    uuid: annotationData.uuid,
    annotator: job.assignee,
    source: AnnotationSource.CLIENT,
    annotationData: annotationData,
    visible: true,
  };
  return annotation;
}

export const createNewSystemAnnotation = (
  jobId: number,
  labelId: number,
  annotator: string
) => {
  const uuid = v4();
  const anno: Annotation = {
    id: -1,
    jobId: jobId,
    labelId,
    annotator,
    uuid: uuid,
    source: AnnotationSource.CLIENT,
    annotationData: [],
    color: "",
    label: "",
    visible: false,
    fillShape: false,
    showLabel: false,
    displayTextValue: false,
    locked: false,
  };
  return anno;
};
export const createNewClassificationAnnotation = (
  jobId: number,
  labelId: number,
  annotator: string,
  attributes: AnnotationAttribute[]
) => {
  const uuid = v4();
  const anno: Annotation = {
    id: -1,
    jobId: jobId,
    labelId,
    annotator,
    uuid: uuid,
    source: AnnotationSource.CLIENT,
    annotationData: [
      {
        uuid,
        type: AnnotateType.CLASSIFICATION,
        points: [],
        attributes,
      },
    ],
    color: "",
    label: "",
    visible: false,
    fillShape: false,
    showLabel: false,
    displayTextValue: false,
    locked: false,
  };
  return anno;
};

function createNewJobAnnotation(
  jobId: number,
  taskId: number,
  annotator: string
): JobAnnotation {
  return {
    id: newUniqueId(),
    agrements: [],
    annotations: { entities: {}, allIds: [] },
    relationAnnotations: { entities: {}, allIds: [] },
    jobId,
    taskId,
    annotator,
  };
}

function createNewJobAnnotationByJob(job: {
  id: number;
  taskId: number;
  assignee: string;
}) {
  const { id: jobId, taskId, assignee: annotator } = job;
  return createNewJobAnnotation(jobId, taskId, annotator);
}

function getFindingLabelers(jobAnnotations: JobAnnotation[], labels: Label[]) {
  const findingLabelers: FindingLabeler[] = [];
  for (const label of labels) {
    const users: {
      jobId: string;
      userId: string;
      selected: boolean;
      annotations?: Annotation[];
    }[] = [];
    for (const jobData of jobAnnotations) {
      const annotations = [];
      for (const annotationId of jobData.annotations.allIds) {
        const annotation = jobData.annotations.entities[annotationId];
        if (label.id !== annotation.labelId) continue;
        annotations.push(annotation);
      }

      if (annotations.length > 0) {
        users.push({
          jobId: "-1",
          userId: jobData.annotator,
          selected: false,
          annotations,
        });
      }
    }
    if (users.length > 0) {
      findingLabelers.push({
        labelId: label.id.toString(),
        labelName: label.name,
        users,
      });
    }
  }
  return findingLabelers;
}

function getFindingAgreements(jobAnnotations: JobAnnotation[]) {
  const findingAgreements: FindingAgreement[] = [];
  for (const jobAnnotation of jobAnnotations) {
    for (const agreement of jobAnnotation.agrements) {
      findingAgreements.push({
        labelId: agreement.observationId.toString(),
        user1: agreement.from.annotator,
        user2: agreement.to.annotator,
        score: agreement.score,
      });
    }
  }
  return findingAgreements;
}

function isValidPolygon(annotation: Annotation) {
  if (!annotation) return false;
  const { numberOfPoint, annotationData } = annotation;
  if (!annotationData || annotationData.length === 0) return true;
  if (annotationData[0].type !== AnnotateType.POLYGON) return true;
  if (!numberOfPoint) return annotationData[0].points.length > 2;
  return annotationData[0].points.length === numberOfPoint;
}

function pointsToPolygon(points: Point[]) {
  if (points.length === 2) {
    return new Flatten.Polygon([
      [points[0].x, points[0].y],
      [points[1].x, points[0].y],
      [points[1].x, points[1].y],
      [points[0].x, points[1].y],
    ]);
  }
  return new Flatten.Polygon(points.map((point) => [point.x, point.y]));
}

function intersectPolygons(
  a: Annotation,
  b: Annotation
): Annotation | undefined {
  const uuid = `${a.id}_${b.id}`;
  const aPoly = pointsToPolygon(a.annotationData[0].points);
  const bPoly = pointsToPolygon(b.annotationData[0].points);
  const intersection = Flatten.BooleanOperations.intersect(aPoly, bPoly);
  let points: Point[] = intersection.vertices.map((vertice) => {
    return {
      x: vertice.x,
      y: vertice.y,
    };
  });

  if (a.annotationData[0].type === AnnotateType.BOUNDING_BOX) {
    const bbox = intersection.box;
    points = [
      { x: bbox.xmin, y: bbox.ymin },
      { x: bbox.xmax, y: bbox.ymax },
    ];
  }
  return {
    ...a,
    id: a.id + b.id,
    uuid,
    annotationData: [
      {
        uuid,
        type: a.annotationData[0].type,
        points: points,
      },
    ],
  };
}

export const imageAnnotationUtils = {
  createNewAnnotation,
  createNewRelationAnnotation,
  createNewJobAnnotation,
  createNewJobAnnotationByJob,
  createNewSystemAnnotation,
  createNewClassificationAnnotation,
  getFindingLabelers,
  getFindingAgreements,
  toEntities,
  toAnnotationEntities,
  toRelationEntities,
  isValidPolygon,
  intersectPolygons,
};
