/*
 * File: workspace-select.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 25th July 2022 10:04:36 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useClickAway } from "ahooks";
import classnames from "classnames";
import { IconArrowDown } from "components/common/vb-icon.component";
import { WorkspaceAvatar } from "components/common/vb-workspace-avatar.component";
import { useAppSelector } from "hooks/use-redux";
import { useWorkspaces } from "hooks/workspace/use-workspace.hook";
import { useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { GroupDTO } from "services/user-service/dtos/group.dto";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { GlobalRole, UserRole } from "store/auth/auth.state";

interface WorkspaceSelectProps {
  onSelectWorkspace(workspaceId: string): void;
  onCreateWorkspace(): void;
}
export default function WorkspaceSelect({
  onSelectWorkspace,
  onCreateWorkspace,
}: WorkspaceSelectProps) {
  const { t } = useTranslation();
  const workspaceRef = useRef<HTMLButtonElement>(null);
  const [showWorkspaces, setShowWorkspaces] = useState(false);
  const { workspace, workspaces } = useWorkspaces();
  const currentUser = useAppSelector(selectCurrentUser);
  const isGlobalAdmin = useMemo(() => {
    return currentUser?.globalRoles?.includes(GlobalRole.GLOBAL_ADMIN);
  }, [currentUser]);

  const clientWorkspaces = useMemo(() => {
    if (!currentUser) return [];
    if (!currentUser.workspace.hasOwnProperty(UserRole.CUSTOMER)) {
      return [];
    }
    const userWorkspaces = currentUser.workspace.customer;
    return workspaces
      .filter((w) => userWorkspaces.includes(w.groupId))
      .slice(0, 4);
  }, [workspaces, currentUser]);

  function handleSelectWorkspace(workspace: GroupDTO) {
    setShowWorkspaces(false);
    onSelectWorkspace(workspace.groupId);
  }

  useClickAway(() => setShowWorkspaces(false), [workspaceRef]);

  return (
    <div className="relative">
      <button
        ref={workspaceRef}
        onClick={() => setShowWorkspaces(!showWorkspaces)}
        className="flex items-center justify-center flex-none gap-1.5 px-3 rounded text-primary h-9"
      >
        <span>{workspace?.groupName}</span>
        <IconArrowDown />
      </button>

      <div
        className={classnames(
          "absolute left-0 top-full z-10 bg-white rounded shadow-lg transition-all transform",
          {
            "opacity-0 pointer-events-none h-0": !showWorkspaces,
            "translate-y-4 opacity-1": !showWorkspaces,
          }
        )}
      >
        <div className="w-60">
          <div className="py-4 space-y-4">
            <div className="space-y-2">
              <div className="px-4 text-xs text-gray-400">
                {t("dashboard:workspaceSelect.textCurrent")}
              </div>
              <div className="flex items-center px-4">
                <WorkspaceAvatar name={workspace?.groupName || ""} />
                <div className="flex-1 ml-4 truncate">
                  {workspace?.groupName}
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <div className="px-4 text-xs text-gray-400">
                {t("dashboard:workspaceSelect.textRecent")}
              </div>

              {clientWorkspaces.map((w) => {
                return (
                  <div
                    key={w.groupId}
                    className="flex items-center gap-4 px-4 py-2 cursor-pointer hover:bg-primary-100"
                    onClick={() => handleSelectWorkspace(w)}
                  >
                    <WorkspaceAvatar name={w.groupName} />
                    <div className="flex-auto truncate">{w.groupName}</div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="py-2 border-t border-gray-200">
            <Link to={Routes.MANAGE_WORKSPACE}>
              <div
                className="flex items-center px-4 py-2 cursor-pointer hover:bg-primary-100"
                onClick={() => setShowWorkspaces(false)}
              >
                {t("dashboard:workspaceSelect.buttonAllWorkspaces")}
              </div>
            </Link>

            {isGlobalAdmin && (
              <button
                className="flex items-center w-full px-4 py-2 cursor-pointer hover:bg-primary-100"
                onClick={onCreateWorkspace}
              >
                {t("dashboard:workspaceSelect.buttonCreateWorkspace")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
