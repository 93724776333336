/*
 * File: add-comment.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 6th July 2022 2:25:52 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store";
import { TextWorkspaceState } from "../../text-workspace.state";
import { textIssueUtils } from "../../utils/text-issue.utils";
import { selectTextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { Issue } from "domain/common/issue";
import { TextLabelingMode } from "../../text-labeling/text-labeling.state";
import {
  issueCustomerService,
  issueLabelerService,
} from "services/label-service";

const ADD_COMMENT_TO_ISSUE_ASYNC = "textLabeling/addCommentToIssueAsync";

export interface AddCommentToIssuePayload {
  content: string;
  issue: Issue;
}

export const addCommentToIssueAsync = createAsyncThunk(
  ADD_COMMENT_TO_ISSUE_ASYNC,
  async (payload: AddCommentToIssuePayload, { getState }) => {
    const state = getState() as RootState;
    const user = selectCurrentUser(state);
    const mode = selectTextLabelingMode(state);
    const { content, issue } = payload;
    const jobId = issue.jobId;
    if (!jobId || !user) return;
    if (mode === TextLabelingMode.CUSTOMER) {
      const response = await issueCustomerService.createIssueComment({
        issueId: issue.id,
        content: content,
      });
      const comment = textIssueUtils.commentFromDTO(response.data);
      return comment;
    } else if (mode === TextLabelingMode.LABELER) {
      const response = await issueLabelerService.createIssueComment({
        issueId: issue.id,
        content: content,
      });
      const comment = textIssueUtils.commentFromDTO(response.data);
      return comment;
    }
    return null;
  }
);

export function addTextIssueCommentBuilder(
  builder: ActionReducerMapBuilder<TextWorkspaceState>
) {
  return builder
    .addCase(addCommentToIssueAsync.pending, (_state, _action) => {})
    .addCase(addCommentToIssueAsync.fulfilled, (state, action) => {
      if (!action.payload) return;
      const comment = action.payload;
      const { issues } = state.textIssues;
      issues.find((is) => is.id === comment.issueId)?.comments.push(comment);
    })
    .addCase(addCommentToIssueAsync.rejected, (_state, _action) => {});
}
