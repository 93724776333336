import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { MLWeb } from "domain/web-builder";
import { useAppDispatch } from "hooks/use-redux";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServingWebService } from "services/label-service/apis/serving-web.api";
import { ServingWebRequestDTO } from "services/label-service/dtos/serving-web.dto";
import { enqueueErrorNotification, enqueueSuccessNotification } from "store/common/notification/notification.actions";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { useMLWebContext } from "../context/ml-web.context";
import { MlWebFilter } from "../context/ml-web.state";
import * as Sentry from "@sentry/react";

interface Props {
  open: boolean;
  item: Partial<MLWeb> | undefined;
  textTitle?: string;
  textSubmit?: string;
  onClose?: () => void;
}

export const MLWebCreateEditModal = ({
  open,
  item,
  textTitle = "Create web builder",
  textSubmit = "Submit",
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const {loadServingWebs, filter} = useMLWebContext();
  const { t } = useTranslation();
  const [name, setName] = useState(() => {
    if (item && item.name) return item.name;
    return "";
  });
  const isEdit = useMemo(() => !!item, [item]);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async () => {
    try {
      if (isProcessing) return;
      setIsProcessing(true);
      const payload: Partial<ServingWebRequestDTO> = {
        name,
      }
      let res;
      if (isEdit) {
        if (item && item.id) {
          res = await ServingWebService.patchServingWeb(item.id, payload);
        }
      } else {
        res = await ServingWebService.createServingWeb(payload);
      }
      handleThunkRejected(res);
      loadServingWebs(MlWebFilter.toRequestOption(filter), true);
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      onClose && onClose();
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error)
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsProcessing(false);
    }
  }

  return (
    <VBModal
      open={open}
      title={textTitle}
      onClose={onClose}
      textSubmit={textSubmit}
      onSubmit={handleSubmit}
      disableSubmit={isProcessing || !!!name}
    >
      <div className="flex flex-col gap-4">
        <div className="w-full flex flex-col gap-2">
          <p>Web's name</p>
          <input
            value={name}
            autoFocus
            type="text"
            className="h-12 px-4 w-full bg-white border rounded border-background-300"
            required
            onChange={e => setName(e.target.value as string)}
            placeholder={"Name"}
          />
        </div>
      </div>
    </VBModal>
  );
}