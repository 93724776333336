/*
 * File: batch-tasks.context.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 3:36:02 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { featureConfigs } from "configs/feature.config";
import { SearchParams } from "models/common/search-params";
import { useContext, createContext } from "react";
import { RequestOptions } from "services/common/request-options";
import { BatchDTO, TaskDTO } from "services/label-service/dtos";

export interface TaskFilter {
  batchId: number;
  taskId?: number;
  fileName?: string;
  labelingStatus?: string;
  tags?: string[];
  labelers?: string[];
  observationId?: number;
  createdDate?: number;
  issueStatus?: string;
  size?: number;
  page?: number;
  sort?: string;
  consensusScore?: number;
  consensusOperator?: "$lt" | "$gt";
}

export interface TaskRow extends TaskDTO {
  selected: boolean;
}

export enum TaskViewMode {
  LIST,
  GRID,
}
export const TASK_VIEW_MODES: TaskViewMode[] = [
  TaskViewMode.GRID,
  TaskViewMode.LIST,
];

export interface BatchTaskContextState {
  taskRows: TaskRow[];
  hasSelectedTasks: boolean;
  selectedAll: boolean;
  setTaskRowSelectedAll: (value: boolean) => void;
  setTaskRowSelect: (row: TaskRow, value: boolean) => void;
  showSelect: boolean;
  setShowSelect: (value: boolean) => void;
  reopenTasks: (reason: string) => void;
  releaseTasks: (batchId: number | string, selectedLabelers?: string[]) => void;
  changeStatusRow: TaskRow | undefined;
  setChangeStatusRow: (value: TaskRow | undefined) => void;
  filter: TaskFilter | null;
  setPage(page: number): void;
  setPageSize(size: number): void;
  setSort(field: string, sortType: string): void;
  setId(id: string): void;
  setFileName(fileName: string): void;
  setStatus(status: string): void;
  setReviewStatus(reviewStatus: string): void;
  setIssueStatus(issueStatus: string): void;
  setCustomerReviewStatus(reviewStatus: string): void;
  setNumAccepted(num: string): void;
  setAssignee(assignee: string): void;
  reopenJobs(taskId: number, jobIds: number[], reason: string): Promise<void>;
  approveTask(taskId: number): Promise<void>;
  rejectTask(taskId: number): Promise<void>;
  reloadTaskAsync(): void;
  isReviewStep: boolean;
  allowReopen: boolean;
  isLoadingTasks: boolean;
  openReopenDialog: boolean;
  setOpenReopenDialog: (v: boolean) => void;
  canReopenTasks: boolean;
  openReleaseDialog: boolean;
  setOpenReleaseDialog: (v: boolean) => void;
  applyTaskFilters: (v: TaskFilter) => void;
  taskFiltersVisible: boolean;
  setTaskFiltersVisible: (v: boolean) => void;
  isShowHasFilter: boolean;
  viewMode: TaskViewMode;
  setViewMode: (v: TaskViewMode) => void;
  isBatchCompleted: boolean;
  canAssignJobsToTask: (b: BatchDTO | undefined | null, t: TaskDTO) => boolean;
}

export const BatchTaskContext = createContext({} as BatchTaskContextState);

export const useBatchTaskContext = () => {
  return useContext(BatchTaskContext);
};

export function buildRequestOptions(filter: TaskFilter | null) {
  if (featureConfigs.isUseESRelatedApis()) {
    return buildRequestOptionsQueryService(filter);
  } else {
    return buildRequestOptionsLabelService(filter);
  }
}

function buildRequestOptionsQueryService(filter: TaskFilter | null) {
  if (!filter || !filter.batchId) return null;
  const options: RequestOptions = {};
  for (const key of Object.keys(filter)) {
    if (key === "consensusScore" || key === "consensusOperator") {
      continue;
    }
    if (key === SearchParams.PAGE && filter.page) {
      options[SearchParams.PAGE] = `${Math.max(filter.page, 1) - 1}`;
      continue;
    }
    options[key] = (filter as any)[key];
  }

  if (filter.consensusScore && filter.consensusOperator) {
    if (filter.consensusOperator === "$gt") {
      options["consensusScoreGt"] = filter.consensusScore.toString();
    } else if (filter.consensusOperator === "$lt") {
      options["consensusScoreLt"] = filter.consensusScore.toString();
    }
  }
  return options;
}

// Old version in LabelSerivce
function buildRequestOptionsLabelService(filter: TaskFilter | null) {
  if (!filter || !filter.batchId) return null;
  // const options: RequestOptions = {
  //   "batchId.equals": filter.batchId,
  // };
  // if (filter.page !== undefined)
  //   options[SearchParams.PAGE] = `${Math.max(filter.page, 1) - 1}`;
  // if (filter.size) options[SearchParams.SIZE] = filter.size.toString();
  // if (filter.sort) options[SearchParams.SORT] = filter.sort;
  // if (filter.taskId) options[`${SearchParams.ID}.equals`] = filter.taskId;
  // if (filter.fileName)
  //   options[`${SearchParams.FILE_NAME}.contains`] = filter.fileName;
  // if (filter.labelingStatus)
  //   options[`${SearchParams.STATUS}.contains`] = filter.labelingStatus;
  // if (filter.issueStatus)
  //   options[`${SearchParams.ISSUE_STATUS}.contains`] = filter.issueStatus;
  // if (filter.labelers)
  //   options[`${SearchParams.ASSIGNEE}.contains`] = filter.labelers;

  // return options;
  return {};
}
