import { AxiosResponse } from "axios";
import { GridPagination } from "components/common/vb-grid/grid-pagination.component";
import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AnnotationsService } from "services/label-service";
import {
  AnnotationJobResponseDTO,
  AnnotationResponseDTO,
  Point,
  RawImageAnnotationItem,
} from "services/label-service/dtos/annotations.dto";
import { mlServiceApis } from "services/ml-service/ml.service";
import { QueryService } from "services/query/query.service";
import { useMLModelContext } from "../ml-models.context";
import { runInfoMapper } from "../ml-models.mappers";
import { MlModelsRunInfo } from "../ml-models.models";
import { BatchObservationDTO } from "services/label-service/dtos";
import { BatchObservationService } from "../../../../services/label-service/index";
import { TaskAnnotationDTO } from "../../../../services/label-service/dtos/task.dto";
import {
  ImageWithAnnotationItem,
  RunInfoImageItems,
} from "./run-info-image-items.component";
import classNames from "classnames";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import * as Sentry from "@sentry/react";

export const RunInfoDetailPage = () => {
  const { runInfoId } = useParams<{ runInfoId: string }>();
  const [view, setView] = useState("metrics");
  const [isProcessing, setIsProcessing] = useState(false);
  const [runInfo, setRunInfo] = useState<MlModelsRunInfo>();
  const { currentRunInfo } = useMLModelContext();

  const [items, setItems] = useState<ImageWithAnnotationItem[]>([]);
  const [totalItem, setTotalItem] = useState(0);

  // filters
  const [datasetId, setDatasetId] = useState<string>();
  const [datasetVersionId, setDatasetVersionId] = useState<string>();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // refs
  const datasetIdRef = useRef(datasetId);
  const datasetVersionIdRef = useRef(datasetVersionId);
  const pageRef = useRef(page);
  const pageSizeRef = useRef(pageSize);
  useEffect(() => {
    datasetIdRef.current = datasetId;
  }, [datasetId]);
  useEffect(() => {
    datasetVersionIdRef.current = datasetVersionId;
  }, [datasetVersionId]);
  useEffect(() => {
    pageRef.current = page;
  }, [page]);
  useEffect(() => {
    pageSizeRef.current = pageSize;
  }, [pageSize]);

  // annotations
  const groundTruthAnnotationsMapRef = useRef<
    Record<number, TaskAnnotationDTO[]>
  >({});
  const inferenceAnnotationsMapRef = useRef<
    Record<number, TaskAnnotationDTO[]>
  >({});
  const labelsRef = useRef<BatchObservationDTO[]>([]);

  const loadFilesAndMapAnnotations = useCallback(async () => {
    if (!datasetIdRef.current) return;

    const res = await QueryService.getAllFiles({
      datasetId: datasetIdRef.current.toString(),
      page: (pageRef.current - 1).toString(),
      size: pageSizeRef.current.toString(),
      sort: "createdDate,desc",
      scopeId: "2",
    });
    setTotalItem(res.totalCount);

    const items: ImageWithAnnotationItem[] = [];
    for (const fileInfo of res.items) {
      if (fileInfo.additionalFileInfo && fileInfo.additionalFileInfo.addition) {
        const addition = fileInfo.additionalFileInfo.addition;
        const image = {
          width: addition.width,
          height: addition.height,
          thumbnailUrl: addition.thumbnailUrl,
          url: fileInfo.url,
        };
        const item: ImageWithAnnotationItem = {
          name: fileInfo.fileName,
          mediaId: fileInfo.id,
          taskData: {
            image,
            annotations: [],
          },
        };
        // check ground truth
        if (groundTruthAnnotationsMapRef.current[fileInfo.id]) {
          item.taskData.annotations = [
            ...groundTruthAnnotationsMapRef.current[fileInfo.id],
          ];
        }

        // check model inference
        if (inferenceAnnotationsMapRef.current[fileInfo.id]) {
          if (!item.taskData.annotations) {
            item.taskData.annotations = [];
          }
          item.taskData.annotations = [
            ...item.taskData.annotations,
            ...inferenceAnnotationsMapRef.current[fileInfo.id],
          ];
        }

        items.push(item);
      }
    }
    setItems(items);
  }, []);

  const loadAnnotations = useCallback(
    async (datasetVersionId: number | string, runId: number | string) => {
      const promises: Promise<AxiosResponse<AnnotationJobResponseDTO>>[] = [
        AnnotationsService.getAnnotationsByDatasetVersionId(datasetVersionId),
        AnnotationsService.getAnnotationsByRunId(runId),
      ];
      const results = await Promise.all(promises);
      let batchId: number = 0;

      const annoToTaskAnno = (
        anno: AnnotationResponseDTO,
        source: string
      ): TaskAnnotationDTO => {
        const points: Point[] = [];

        if (anno.annotation) {
          const annoItem = anno.annotation as RawImageAnnotationItem;
          if (annoItem.polygons && annoItem.polygons.length > 0) {
            const polygon = annoItem.polygons[0];
            for (const point of polygon.points) {
              points.push(point);
            }
          } else if (annoItem.bbox) {
            const { x, y, width, height } = annoItem.bbox;
            points.push({ x, y });
            points.push({ x: x + width, y: y + height });
          }
        }

        return {
          id: anno.id,
          labelId: anno.observationId || 0,
          type: anno.annotationType,
          points,
          color: "",
          hidden: false,
          source,
        };
      };

      const updateAnnosMap = (
        map: Record<number, TaskAnnotationDTO[]>,
        annos: AnnotationResponseDTO[],
        source: string
      ) => {
        for (const anno of annos) {
          if (!map[anno.mediaId]) {
            map[anno.mediaId] = [];
          }
          map[anno.mediaId].push(annoToTaskAnno(anno, source));
          if (!!!batchId && anno.batchId) {
            batchId = anno.batchId;
          }
        }
      };

      updateAnnosMap(
        groundTruthAnnotationsMapRef.current,
        results[0].data.annotations,
        "GroundTruth"
      );
      updateAnnosMap(
        inferenceAnnotationsMapRef.current,
        results[1].data.annotations,
        "Predict"
      );

      if (batchId) {
        const res = await BatchObservationService.getItems({
          batchId: batchId.toString(),
        });
        labelsRef.current = res.data;
      }
    },
    []
  );

  const loadData = useCallback(async () => {
    try {
      setIsProcessing(true);
      let newRunInfo;

      if (currentRunInfo) {
        newRunInfo = { ...currentRunInfo };
      } else {
        const res = await mlServiceApis.getRunInfosById(runInfoId);
        newRunInfo = runInfoMapper.fromEntity(res);
      }

      if (newRunInfo) {
        setRunInfo(newRunInfo);
        if (newRunInfo.datasetId && newRunInfo.datasetVersionId) {
          setDatasetId(newRunInfo.datasetId);
          setDatasetVersionId(newRunInfo.datasetVersionId);

          datasetIdRef.current = newRunInfo.datasetId;
          datasetVersionIdRef.current = newRunInfo.datasetVersionId;

          await loadAnnotations(newRunInfo.datasetVersionId, newRunInfo.runId);
          await loadFilesAndMapAnnotations();
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
    } finally {
      setIsProcessing(false);
    }
  }, [runInfoId, currentRunInfo, loadFilesAndMapAnnotations, loadAnnotations]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (!runInfo) return <div>null run</div>;

  if (isProcessing) {
    return <VBInlineLoading />;
  }

  return (
    <div className="flex flex-col h-full gap-4 px-6 py-4 overflow-y-auto bg-white rounded animate-fade-in-up">
      <h1 className="text-lg font-semibold">
        Instance: {runInfo?.runId} (test results)
      </h1>
      <div className="flex items-center flex-none gap-4 border-b">
        <button
          className={classNames("pb-2", { "text-primary": view === "metrics" })}
          onClick={() => setView("metrics")}
        >
          Metrics
        </button>
        <button
          className={classNames("pb-2", {
            "text-primary": view === "annotations",
          })}
          onClick={() => setView("annotations")}
        >
          Annotations
        </button>
      </div>
      {view === "metrics" && (
        <div className="grid grid-cols-1 gap-8 py-8 md:grid-cols-2">
          {runInfo.metrics &&
            Array.isArray(runInfo.metrics) &&
            runInfo.metrics.map((metric) => {
              if (
                !metric.name ||
                !metric.type ||
                !metric.color ||
                !metric.x ||
                !metric.y
              )
                return null;
              return <RunMetric key={metric.name} metric={metric} />;
            })}
        </div>
      )}
      {view === "annotations" && (
        <>
          <RunInfoImageItems labels={labelsRef.current} items={items} />
          <div className="flex-auto"></div>
          <div className="mt-4">
            <GridPagination
              page={page}
              pageSize={pageSize}
              pageSizeOptions={[10, 15, 20, 25, 50, 75, 100]}
              onPageSizeChange={(v) => {
                setPageSize(v);
                pageSizeRef.current = v;
                loadFilesAndMapAnnotations();
              }}
              onPageChange={(v) => {
                setPage(v);
                pageRef.current = v;
                loadFilesAndMapAnnotations();
              }}
              totalItems={totalItem}
            />
          </div>
        </>
      )}
    </div>
  );
};

interface Metric {
  name: string;
  color: string;
  type: string;
  x: number[];
  y: number[];
}
interface RunMetricProps {
  metric: Metric;
}
function RunMetric({ metric }: RunMetricProps) {
  const data = metric.x.map((xValue, index) => {
    const yValue = metric.y[index];
    return {
      xValue,
      [metric.name]: yValue,
      name: metric.name,
    };
  });
  return (
    <div className="h-80">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 8,
            right: 32,
            left: 8,
            bottom: 8,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="xValue" />
          <YAxis />
          <Tooltip />
          <Legend name={metric.name} />
          <Line
            type="monotone"
            dataKey={metric.name}
            stroke={metric.color}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
