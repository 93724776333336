import { useStepConditions } from "hooks/workflow/use-step-conditions.hook";
import { useRef, useState } from "react";
import { BatchPriceTabsComponent, BatchPricingUIModel } from "../billing/batch-price-tabs.component";
import { PriceByJobStepUIModel } from "../billing/price-by-job.component";
import { useCreateBatchContext } from "../create-batch.context";
import { BatchSteps } from "../create-batch.model";

export const  PriceUnitStep = () => {
  const {
    steps,
    currentStep,
    submitData,
  } = useCreateBatchContext();
  const containerRef = useRef<any>();
  const { bindConditions } = useStepConditions();
  const [batchPriceUIModel, setBatchPricingUIModel] = useState<BatchPricingUIModel>(() => {
    if (currentStep && currentStep.data) return currentStep.data;
    const workflowStepData = steps[BatchSteps.WORKFLOW].data;
    const datasourceStepData = steps[BatchSteps.DATASOURCE].data;
    const {instructions} = workflowStepData;
    const {selectedFileCount} = datasourceStepData;

    const defaultBatchPrice: Partial<BatchPricingUIModel> = {
      byJobSteps: [],
    };
    let i = 0;
    for (const instruction of instructions) {
      const { id, name, condition, roundNumber } = instruction;
      let previousInstruction = undefined;
      if (i > 0) previousInstruction = instructions[i - 1];
      if (roundNumber < 1) continue;
      const byJobStep: PriceByJobStepUIModel = {
        stepTitle: (condition && previousInstruction) ? 
              `${name} ${bindConditions(condition)} ${previousInstruction.name}` : name,
        id: id,
        rounds: [],
        required: !!instruction.condition,
      }
      for (let roundIndex = 1; roundIndex <= roundNumber; roundIndex++) {
        byJobStep.rounds.push({
          index: roundIndex,
          pricePerJob: 0,
          totalJob: selectedFileCount,
        });
      }
      if (defaultBatchPrice.byJobSteps) {
        defaultBatchPrice.byJobSteps.push(byJobStep);
      }
      i++;
    }
    return defaultBatchPrice;
  });

  const handleBatchPricingChanged = (v: BatchPricingUIModel) => {
    setBatchPricingUIModel(v);
    submitData(BatchSteps.UNIT_PRICE, v);
  }
  
  return (
    <div className="pr-4" ref={containerRef}>
      <BatchPriceTabsComponent
        value={batchPriceUIModel}
        onValueChanged={handleBatchPricingChanged}
        // selectTarget={containerRef.current}
      />
    </div>
  );
}