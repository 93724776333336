import common from "./common.json";

import authorization from "./authorization.json";
import dashboard from "./dashboard.json";
import dataset from "./dataset.json";
import project from "./project.json";
import workspace from "./workspace.json";
import workflow from "./workflow.json";
import notification from "./notification.json";
import mlmodel from "./mlmodel.json";
import member from "./member.json";
import usermenu from "./usermenu.json";
import _enum from "./enum.json";
import _error from "./error.json";

import labelerhome from "./labelerhome.json";
import labelerworkspace from "./labelerworkspace.json";
import textLabeling from "./text-labeling.json";
const translation = {
  common,

  authorization,
  dashboard,
  dataset,
  project,
  workspace,
  workflow,
  notification,
  mlmodel,
  member,
  usermenu,

  labelerhome,
  labelerworkspace,
  textLabeling,
  enum: _enum,
  error: _error,
};

export default translation;
