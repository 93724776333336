/*
 * File: batch-labeling.state.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 10th December 2021 11:37:59 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ProjectTypes } from "services/label-service/dtos";
import { DashBoardStateCommon } from "store/customer/dashboard/dashboard.state";
import { DateRange } from "utilities/date-time/date-range";
import { DateRangeOption } from "../hooks/use-dashboard-filter.hook";

export enum QueryNameStatistic {
  DatasourceStatistic = "DatasourceStatistic",
  ProjectStatistic = "ProjectStatistic",
  LabelerStatistic = "LabelerStatistic",
  StorageStatistic = "StorageStatistic",

  TaskProgressStatistic = "TaskProgressStatistic",
  TaskCompleteStatistic = "TaskCompleteStatistic",

  LabelProgressStatistic = "LabelProgressStatistic",
  LabelerProgressStatistic = "LabelerProgressStatistic",

  ProjectProgressStatistic = "ProjectProgressStatistic",
  BatchProgressStatistic = "BatchProgressStatistic",
}

export interface CardRowDataModel {
  name: string;
  total: number;
  color?: string;
  postFix?: string;
}
export interface DashboardState extends DashBoardStateCommon {
  isLoading: boolean;
  requestData(
    fromDate: Date,
    toDate: Date,
    force?: boolean,
    requestQueryNames?: QueryNameStatistic[] | undefined,
    separator?: string | undefined,
    projectTypes?: ProjectTypes[] | undefined,
  ): Promise<void>;
  useCache: boolean;
  setUseCache: (value: boolean) => void;

  fromDate: Date;
  setFromDate: (v: Date) => void;
  toDate: Date;
  setToDate: (v: Date) => void;
  dateRange: DateRange;
  setDateRange: (v: DateRange) => void;
  dateRanges: DateRangeOption[];

  taskCompletedFilter: string;
  setTaskCompletedFilter: (value: string) => void;
  tableProjectType: ProjectTypes | undefined;
  setTableProjectType: (value: ProjectTypes | undefined) => void;
}

export const defaultDashboardState = {} as DashboardState;
