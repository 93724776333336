/*
 * File: drawTextValue.js
 * Project: app-aiscaler-web
 * File Created: Monday, 7th February 2022 5:26:34 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import * as csTools from "cornerstone-tools";
const external = csTools.external;


function measurementBoundingBox(measurement) {
  if (!measurement.handles) return null;
  if (
    measurement.handles.hasOwnProperty("start") &&
    measurement.handles.hasOwnProperty("end")
  ) {
    const { start, end } = measurement.handles;
    const x = Math.min(start.x, end.x);
    const y = Math.min(start.y, end.y);
    const width = Math.abs(end.x - start.x);
    const height = Math.abs(end.y - start.y);
    return { x, y, width, height };
  }

  if (
    measurement.handles.hasOwnProperty("points") &&
    measurement.handles.points.length > 0
  ) {
    const points = measurement.handles.points.map((point) => {
      return { x: point.x, y: point.y };
    });
    const minX = Math.min(...points.map(({ x }) => x));
    const maxX = Math.max(...points.map(({ x }) => x));
    const minY = Math.min(...points.map(({ y }) => y));
    const maxY = Math.max(...points.map(({ y }) => y));
    return { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
  }
  return null;
}

export default function (context, element, data) {
  if (!data || !data.active || data.vote === undefined) return;
  // const boundingbox = measurementBoundingBox(data);
  // if (!boundingbox) return;
  // const cornerstone = external.cornerstone;
  // const textCoords = cornerstone.pixelToCanvas(element, {
  //   x: boundingbox.x + boundingbox.width,
  //   y: boundingbox.y,
  // });
  // const x = textCoords.x;
  // const y = textCoords.y;

  // const viewport = cornerstone.getViewport(element);
  // // Calculate the center of the image
  // // const { clientWidth: width, clientHeight: height } = element;
  // // const { scale, translation } = viewport;
  // // const rotation = viewport.rotation - 0.0;
  // const start = cornerstone.pixelToCanvas(element, data.handles.start);
  // const end = cornerstone.pixelToCanvas(element, data.handles.end);
  // const gap = SPACING;

  // if (data.vote > 0) {
  //   drawIconDownVote(
  //     context,
  //     start.x + (end.x - start.x) / 2 - WIDTH / 2,
  //     start.y + (end.y - start.y) / 2 - HEIGHT / 2
  //   );
  // } else if (data.vote < 0) {
  //   drawIconVote(
  //     context,
  //     start.x + (end.x - start.x) / 2 - WIDTH / 2,
  //     start.y + (end.y - start.y) / 2 - HEIGHT / 2
  //   );
  // } else {
  //   drawIconVote(
  //     context,
  //     start.x + (end.x - start.x) / 2 - gap - WIDTH,
  //     start.y + (end.y - start.y) / 2 - HEIGHT / 2
  //   );
  //   drawIconDownVote(
  //     context,
  //     start.x + (end.x - start.x) / 2 + gap,
  //     start.y + (end.y - start.y) / 2 - HEIGHT / 2
  //   );
  // }
}
