/*
 * File: step-3-configure-workflow.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useDetectChange } from "hooks/use-detect-change";
import { useStepConditions } from "hooks/workflow/use-step-conditions.hook";
import { useWorkflowInstructions } from "hooks/workflow/use-workflow-instructions.hook";
import { useWorkflow } from "hooks/workflow/use-workflow.hook";
import { StepTransitions } from "pages/customer/workflows/instructions/step-transitions.component";
import { useEffect, useMemo, useState } from "react";
import {
  BatchObservationCreationModel,
  DEFAULT_CONSENSUS_ENTITY,
  DEFAULT_CONSENSUS_RELATION,
  DEFAULT_IOU,
  WorkflowDTO,
} from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import { LabelSegmentationSlider } from "../../batch-detail/pages/workflow/components/label-segmentation-slider.component";
import { WorkflowSelector } from "../../batch-detail/pages/workflow/components/workflow-selector.component";
import { useCreateBatchContext } from "../create-batch.context";
import { BatchSteps } from "../create-batch.model";
import { AgreementRateSlider } from "../../batch-detail/pages/workflow/components/agreement-rate-slider.component";
import { usePrevious } from "ahooks";
import { useAppSelector } from "hooks/use-redux";
import {
  selectIsImageProject,
  selectIsMdiSegmentation,
  selectIsTextProject,
  selectProjectWorkflow,
} from "store/customer/project/project.selectors";
import { selectRequireTextConsensus } from "store/customer/workflow/workflow.selectors";

export const ConfigureWorkflowStep = () => {
  const { t } = useTranslation();
  const isImageProject = useAppSelector(selectIsImageProject);
  const isMdiProject = useAppSelector(selectIsMdiSegmentation);
  const isTextProject = useAppSelector(selectIsTextProject);
  const projectWorkflow = useAppSelector(selectProjectWorkflow);
  const { steps, currentStep, submitData, showCreateWorkflow, setHasError } =
    useCreateBatchContext();
  const { workflows, requestStatus } = useWorkflow();

  useStepConditions(true);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState(() => {
    if (!currentStep) {
      return -1;
    } else if (projectWorkflow?.id) {
      return projectWorkflow.id;
    } else if (currentStep.data) {
      return currentStep.data.workflow.id;
    }
    return -1;
  });

  const {
    workflow,
    instructions,
    isRequireImageConsensus,
    requestStatus: workflowRequestStatus,
  } = useWorkflowInstructions(selectedWorkflowId);

  const requireTextConsensusRules = useAppSelector(
    selectRequireTextConsensus(instructions)
  );

  const [consensusRate, setConsensusRate] = useState(() => {
    if (!currentStep) return -1;
    if (currentStep.data) return currentStep.data.consensusRate;
    return 100;
  });

  const [consensusEntity, setConsensusEntity] = useState(() => {
    if (!currentStep) return 0;
    if (currentStep.data) return currentStep.data.consensusEntity;
    return DEFAULT_CONSENSUS_ENTITY;
  });

  const [consensusRelationship, setConsensusRelationship] = useState(() => {
    if (!currentStep) return 0;
    if (currentStep.data) return currentStep.data.consensusRelationship;
    return DEFAULT_CONSENSUS_RELATION;
  });

  const previousWorkflows = usePrevious(workflows);

  const [labels, setLabels] = useState<BatchObservationCreationModel[]>(() => {
    if (!currentStep) return [];
    if (currentStep.data) return currentStep.data.labels;
    return steps[BatchSteps.LABELS].data.map(
      (lb: BatchObservationCreationModel) => {
        return {
          ...lb,
          iou: DEFAULT_IOU,
        };
      }
    );
  });

  const shouldSubmit = useDetectChange([
    workflow,
    labels,
    instructions,
    isRequireImageConsensus,
    consensusRate,
    consensusEntity,
    consensusRelationship,
  ]);

  function handleWorkflowChange(workflow: WorkflowDTO | null) {
    if (workflow) setSelectedWorkflowId(workflow.id);
  }

  function handleCreateNewWorkflow() {
    showCreateWorkflow();
  }

  function handleConsensusRateChange(newValue: number) {
    setConsensusRate(newValue);
  }

  useEffect(() => {
    if (
      previousWorkflows &&
      workflows.length > 0 &&
      workflows.length > previousWorkflows?.length
    ) {
      setSelectedWorkflowId(workflows[0].id);
    }
  }, [workflows, previousWorkflows]);

  useEffect(() => {
    if (!requireTextConsensusRules.entity) setConsensusEntity(0);
    if (!requireTextConsensusRules.relation) setConsensusRelationship(0);
  }, [requireTextConsensusRules]);

  useEffect(() => {
    if (selectedWorkflowId === -1 && workflows.length > 0) {
      setSelectedWorkflowId(workflows[0].id);
    }
  }, [workflows, selectedWorkflowId]);

  useEffect(() => {
    if (!shouldSubmit) return;
    if (!workflow || workflows.length === 0 || instructions.length === 0) {
      submitData(BatchSteps.WORKFLOW, null);
      return;
    }
    const data = {
      workflow: workflow,
      labels: labels,
      instructions: instructions,
      consensusRate: consensusRate,
      timeLimitSecond: 0,
      consensusEntity: consensusEntity,
      consensusRelationship: consensusRelationship,
    };
    if (!requireTextConsensusRules.entity) {
      delete data.consensusEntity;
    }
    if (!requireTextConsensusRules.relation) {
      delete data.consensusRelationship;
    }

    submitData(BatchSteps.WORKFLOW, data);
  }, [
    shouldSubmit,
    workflow,
    labels,
    instructions,
    isRequireImageConsensus,
    workflows.length,
    consensusRate,
    consensusEntity,
    consensusRelationship,
    requireTextConsensusRules,
    submitData,
  ]);

  // check number of steps for evaluation batch
  const errorMessage = useMemo(() => {
    const isEvaluationBatch = !!steps[BatchSteps.GENERAL].data?.isEvaluation;

    if (isEvaluationBatch && instructions.length > 1) {
      setHasError(true);
      return "Workflow in the evaluation batch must have only one step!";
    }

    setHasError(false);
    return "";
  }, [instructions, steps, setHasError]);

  const hasNoWorkflow =
    requestStatus === RequestStatus.SUCCESS && workflows.length === 0;
  return (
    <div className="p-4">
      <div className="flex items-center justify-between">
        <div className="py-4 text-xl font-bold">
          {t("project:batch.instruction.step3.title")}
        </div>
        {!hasNoWorkflow && (
          <button
            className="focus:outline-none text-primary"
            onClick={handleCreateNewWorkflow}
          >
            {t("project:batch.instruction.step3.buttonCreateWorkflow")}
          </button>
        )}
      </div>

      {workflows.length > 0 && (
        <div className="flex flex-col gap-2 mb-4">
          <div>{t("project:batch.instruction.step3.textSelectWorkflow")}</div>
          <WorkflowSelector
            workflows={workflows}
            selectedWorkflowId={selectedWorkflowId}
            onChange={handleWorkflowChange}
          />
        </div>
      )}

      {
        errorMessage &&
        <p className="text-error">{errorMessage}</p>
      }

      {hasNoWorkflow && (
        <div className="p-4 py-8 text-center text-gray-500">
          <div className="py-4">
            {t("project:batch.instruction.step3.textNoWorkflow")}.
            <br />
            {t("project:batch.instruction.step3.textWorkflowRequire")}.
          </div>
          <button
            className="focus:outline-none text-primary"
            onClick={handleCreateNewWorkflow}
          >
            {t("project:batch.instruction.step3.buttonCreateWorkflow")}
          </button>
        </div>
      )}

      <div className="mt-4">
        <div className="">
          {t("project:batch.instruction.step3.textInstructions")}
        </div>
        <div>
          <StepTransitions instructions={instructions} />
          {workflowRequestStatus === RequestStatus.SUCCESS &&
            workflow &&
            instructions.length === 0 && (
              <div className="px-4 text-center">
                {t("project:batch.instruction.step3.textNoStep")}
              </div>
            )}
        </div>
        <div className="flex">
          {(isImageProject || isMdiProject) && isRequireImageConsensus && (
            <div className="w-full">
              <div className="mb-2 font-semibold text-gray-500">
                {t("project:consensusRules")}
              </div>
              <div className="w-1/2">
                <AgreementRateSlider
                  onChange={handleConsensusRateChange}
                  defaultValue={consensusRate}
                />
              </div>

              <div className="w-3/4">
                <LabelSegmentationSlider
                  isMdi={isMdiProject}
                  labels={labels}
                  onChange={setLabels}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {isTextProject &&
        (requireTextConsensusRules.entity ||
          requireTextConsensusRules.relation) && (
          <div className="w-3/4">
            <div className="font-semibold text-gray-500">
              {t("project:consensusRules")}
            </div>
            <div className="flex justify-between gap-8 pt-2 pb-4">
              {requireTextConsensusRules.entity && (
                <div className="w-1/2 text-sm">
                  <AgreementRateSlider
                    label={t(
                      "project:batchDetails.batchSettings.consensusEntity"
                    )}
                    defaultValue={consensusEntity}
                    onChange={(value) => setConsensusEntity(value)}
                  />
                </div>
              )}

              {requireTextConsensusRules.relation && (
                <div className="w-1/2 text-sm">
                  <AgreementRateSlider
                    label={t(
                      "project:batchDetails.batchSettings.consensusRelationship"
                    )}
                    defaultValue={consensusRelationship}
                    onChange={(value) => setConsensusRelationship(value)}
                  />
                </div>
              )}
            </div>
          </div>
        )}
    </div>
  );
};
