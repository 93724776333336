import { useAppSelector } from "hooks/use-redux";
import { useEffect, useMemo, useState } from "react";
import { selectProjectTotalTasks } from "store/customer/batch/batch.selectors";
import { isValidNumber } from "utilities/number/handle-invalid-number";
import { StepAssigneeConfig, StepData } from "../project.type";

export const CONFIG_TYPE_PER_LABELER = "per_labeler";
export const CONFIG_TYPE_MANUAL = "manual";
export const DEFAULT_DAILY_LIMIT = 2000;

export const useStepConfigAssignees = (selectedStep?: StepData) => {
  const [config, setConfig] = useState<StepAssigneeConfig>();
  const totalTask = useAppSelector(selectProjectTotalTasks);

  useEffect(() => {
    if (!selectedStep || !selectedStep.assignees) return;

    const { assignees } = selectedStep;
    const isUseTaskDistConfig =
      assignees.length > 0 && isValidNumber(assignees[0].limit);
    const taskDistConfigType = isUseTaskDistConfig
      ? CONFIG_TYPE_MANUAL
      : CONFIG_TYPE_PER_LABELER;
    const configRows = assignees.map((assignee) => ({
      user: assignee.userId,
      dailyLimit: assignee.dailyLimit || DEFAULT_DAILY_LIMIT,
      numTaskInBatch: isUseTaskDistConfig
        ? isValidNumber(assignee.limit)
          ? assignee.limit
          : NaN
        : NaN,
    }));

    const config = {
      isUseTaskDistConfig,
      taskDistConfigType,
      numTaskPerLabeler: 0,
      isUseLimitPerdayConfig: true,
      limitPerdayConfigType: CONFIG_TYPE_MANUAL,
      numLimitPerdayPerLabeler: DEFAULT_DAILY_LIMIT,
      configRows,
    };

    setConfig(config);
  }, [selectedStep]);

  const title = useMemo(() => {
    if (
      !selectedStep ||
      !selectedStep.assignees ||
      !selectedStep.assignees.length
    )
      return;
    return (
      `Step ${selectedStep.position}: Config labelers ` +
      `(${selectedStep.numberOfRounds} rounds, ${selectedStep.assignees.length} labelers, ${totalTask} tasks)`
    );
  }, [selectedStep, totalTask]);

  const numTaskInBatchDefault = useMemo(() => {
    if (
      !selectedStep ||
      !selectedStep.assignees ||
      !selectedStep.assignees.length
    )
      return;
    return Math.floor(
      (totalTask * selectedStep.numberOfRounds) / selectedStep.assignees.length
    );
  }, [totalTask, selectedStep]);

  return {
    config,
    numTaskInBatchDefault,
    onChange: setConfig,
    title,
  };
};
