/*
 * File: job.dto.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 9th August 2021 9:17:26 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { BaseDTO } from "./base.dto";
import { TaskDTO } from "./task.dto";
import { WorkflowInstructionDTO } from "./workflow-instruction.dto";

export enum WorkType {
  LABELER = "labeler",
  SUPERVISOR = "supervisor",
}

export interface JobDTO extends BaseDTO {
  assignee: string;
  batchId: number;
  status: string;
  step: number;
  projectType: string;
  task: TaskDTO;
  version: number;
  workInstruction: WorkflowInstructionDTO;
  workType: WorkType | null;
  workspaceId: string;
}

export enum JobStatus {
  AVAILABLE = "available",
  WAITING = "waiting",
  WORKING = "working",
  COMPLETED = "completed",
  LOCKED = "locked",
}
