/*
 * File: no-job-available.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 14th October 2021 10:32:05 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { Grow } from "@material-ui/core";
import { Routes } from "routers/config/routes";
import { Link } from "react-router-dom";
import EmptyJobIllustration from "assets/images/illustration/empty_job.svg";

export const NoJobAvailable = () => {
  const { t } = useTranslation();

  return (
    <div className="relative flex items-center justify-center w-screen h-screen bg-blueGray-100">
      <Grow in={true}>
        <div
          className="px-4 py-8 text-center bg-white rounded shadow"
          style={{ maxWidth: "24em" }}
        >
          <div className="flex justify-center p-4">
            <img src={EmptyJobIllustration} alt="no job" className="w-20" />
          </div>
          <div className="p-4 leading-6 text-center">
            {t("labelerworkspace:empty.noJobMessage")}
          </div>

          <div className="my-4">
            <Link
              to={Routes.LABELER_HOME}
              className="px-4 py-2 text-white rounded bg-primary focus:outline-none"
            >
              {t("labelerworkspace:empty.buttonBackHome")}
            </Link>
          </div>
        </div>
      </Grow>
    </div>
  );
};
