import {
  ComplexJobUIModel,
  ComplexLabelingState,
} from "pages/labeler/speech-to-text-labeling/context/speech-to-text-labeling.state";
import { MutableRefObject } from "react";
import { FabricOverlay } from "../components/fabric/fabric-overlay";
import {
  PathologyAnnotationEdge,
  PathologyEditorLabel,
  PathologyLabeler,
} from "../components/pathology-editor.models";

export interface PathologyJobUIModel extends ComplexJobUIModel {
  wsiUrl: string | undefined;
  originalWsiUrl: string | undefined;
  useAjaxHeaders: boolean;
  fabricOverlay: FabricOverlay | undefined;
  labels: PathologyEditorLabel[];
  // Init fabric objects (load from database) (objects coordinates must be in osd image coordinate system)
  initFabricObjects: fabric.Object[];
  // Init edges when build the hierarchy the first time from db
  initEdges: PathologyAnnotationEdge[];
  isFirstLoad: boolean;
  // Labelers
  editorCurrentLabeler: PathologyLabeler | undefined;
  editorOtherLabelers: PathologyLabeler[];
}

export interface PathologyLabelingState extends ComplexLabelingState {
  uiJobRef: MutableRefObject<PathologyJobUIModel | undefined>;
  setIsEditorReady: (v: boolean) => void;
}

export const DEFAULT_UI_JOB: PathologyJobUIModel = {
  wsiUrl: undefined,
  originalWsiUrl: undefined,
  useAjaxHeaders: true,
  fabricOverlay: undefined,
  labels: [],
  initFabricObjects: [],
  initEdges: [],
  isFirstLoad: true,
  editorCurrentLabeler: undefined,
  editorOtherLabelers: [],

  probability: 1,
  isTaskReview: false,
  job: undefined,
  isStepReviewJob: false,
  canViewPreviousStepResult: false,
  acceptBatchObservation: undefined,
  rejectBatchObservation: undefined,
  task: undefined,
  batch: undefined,
  project: undefined,
  countDownSecond: 0,
  previousJobsTaskObservations: [],
  previousJobsAnnotations: [],
  jobOptions: [],
};

export const defaultPathologyLabelingState = {} as PathologyLabelingState;
