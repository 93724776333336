/*
 * File: mammography-task-labels.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd October 2022 5:08:12 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import classNames from "classnames";
import { Annotation, Label, LabelAttribute } from "domain/image-labeling";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { selectImageLabelingAllLabels } from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { setObservationVisible } from "store/labeler/image-workspace/image-workspace.slice";
import { AnnotateType } from "constants/annotation.constant";
import { selectMammographyTaskClassification } from "store/labeler/mammography-labeling-task/mammography-labeling-task.selectors";
import { useMemo } from "react";

export const MammographyTaskLabels = () => {
  const dispatch = useAppDispatch();
  const labels = useAppSelector(selectImageLabelingAllLabels);
  const labelAnnotations = useAppSelector(selectMammographyTaskClassification);
  function handleToggleVisible(label: Label) {
    const payload = { label, visible: !!label.hidden };
    dispatch(setObservationVisible(payload));
  }
  return (
    <div className="flex-1">
      <div className="p-4 text-xs font-bold text-gray-400">LABELS</div>
      <div className="divide-y divide-dashed divide-background-700">
        {labels.map((label) => {
          const annotations = labelAnnotations[label.id];
          if (
            label.isSystemLabel ||
            label.annotateType === AnnotateType.SYSTEM ||
            label.annotateType === AnnotateType.CLASSIFICATION
          ) {
            if (!annotations || annotations.length === 0) return null;
            return (
              <ClassificationLabelItem
                key={label.id}
                label={label}
                annotations={annotations}
              />
            );
          }
          return (
            <div key={label.id}>
              <div className="flex items-center px-4 text-white parent">
                <div className="flex-1 py-3 cursor-pointer">
                  {label.name}
                  {label.requireBorder && (
                    <span className="text-red-500">*</span>
                  )}
                </div>

                <button
                  className={classNames("flex-none pr-4 btn", {
                    "child-on-hover": !label.hidden,
                  })}
                  onClick={() => handleToggleVisible(label)}
                >
                  <i
                    className={`${
                      !label.hidden ? "uir-eye" : "uir-eye-crossed"
                    }`}
                  ></i>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

function ClassificationLabelItem({
  label,
  annotations,
}: {
  label: Label;
  annotations: Annotation[];
}) {
  const labelAttributes = useMemo(() => {
    const record: Record<number, LabelAttribute> = {};
    if (!label.attributes) return record;
    for (const attr of label.attributes) {
      record[attr.id] = attr;
    }
    return record;
  }, [label]);
  return (
    <div key={label.id} className="py-3">
      <div className="flex items-center px-4 text-white parent">
        <div className="flex-1 cursor-pointer">
          {label.name}
          {label.require && <span className="text-red-500">*</span>}
        </div>
      </div>

      {(label.isSystemLabel ||
        label.annotateType === AnnotateType.SYSTEM ||
        label.annotateType === AnnotateType.CLASSIFICATION) && (
        <div className="px-4">
          {annotations?.map((anno) => {
            return (
              <div className="p-2" key={anno.id}>
                <div>{anno.annotator}</div>
                <div className="text-sm">
                  {anno.annotationData
                    ?.filter((data) => data.attributes?.length)
                    .map((item) =>
                      item.attributes
                        ?.map(
                          (attr) =>
                            `${labelAttributes[attr.id].name} ${attr.value}`
                        )
                        .join("; ")
                    )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
