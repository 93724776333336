/*
 * File: web-builder-preview.context.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 17th February 2022 4:15:42 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { ModelWebContextState } from "./model-web.state";

export const ModelWebContext = createContext({} as ModelWebContextState);

export const useModelWebContext = () => {
  return useContext(ModelWebContext);
};
