import { Switch } from "@material-ui/core";
import { useLocalStorageState } from "ahooks";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useRef } from "react";
import { selectIsAutoSaveConfig } from "store/labeler/complex-jobs/complex-jobs-editor-setting/complex-jobs-editor-setting.selector";
import { setComplexJobsAutoSave } from "store/labeler/complex-jobs/complex-jobs.slice";

interface ThreeDEditorSettingModalProps {
  open: boolean;
  onClose(): void;
}
export const ThreeDEditorSettingModal = ({
  onClose,
}: ThreeDEditorSettingModalProps) => {
  const dispatch = useAppDispatch();
  const [isAutoSave, setAutoSave] = useLocalStorageState(
    "THREE_D_AUTO_SAVE",
    false
  );
  const [duration, setAutoSaveDuration] = useLocalStorageState(
    "THREE_D_AUTO_SAVE_DURATION",
    60
  );
  const isAutoSaveConfig = useAppSelector(selectIsAutoSaveConfig);
  const durationRef = useRef<HTMLInputElement>(null);

  function toggleAutoSaveConfig() {
    setAutoSave(!isAutoSave);
  }

  function onDurationChange() {
    setAutoSaveDuration(parseInt(durationRef.current?.value ?? "60"));
  }

  useEffect(() => {
    dispatch(
      setComplexJobsAutoSave({
        enable: isAutoSave,
        duration: duration,
      })
    );
  }, [dispatch, isAutoSave, duration]);

  return (
    <VBModal
      open
      onClose={onClose}
      title={"Editor settings"}
      footerHidden={true}
      width="40rem"
    >
      <div style={{ maxHeight: "60vh" }}>
        <div className="py-2 font-semibold">AutoSave</div>
        <div className="flex items-center gap-4">
          <div className="flex-auto">Enable</div>
          <Switch
            color="primary"
            checked={isAutoSaveConfig}
            onClick={toggleAutoSaveConfig}
          />
        </div>
        {isAutoSaveConfig && (
          <div className="flex items-center gap-4">
            <div className="flex-auto">Duration (s)</div>
            <input
              className="w-16 px-2 py-1 border rounded"
              ref={durationRef}
              type="number"
              min={0}
              max={60000}
              defaultValue={duration}
              onChange={onDurationChange}
            />
          </div>
        )}
      </div>
    </VBModal>
  );
};
