/*
 * File: search-params.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 8th July 2021 3:48:43 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export enum SearchParams {
  PAGE = "page",
  SIZE = "size",
  PAGE_SIZE = "pageSize",
  TYPE = "type",
  CATEGORY = "category",
  STATUS = "status",
  LABELING_STATUS = "labelingStatus",
  REVIEW_STATUS = "reviewStatus",
  ISSUE_STATUS = "issueStatus",
  CUSTOMER_REVIEW_STATUS = "customerReviewStatus",
  NUM_ACCEPTED = "numAccepted",
  SORT = "sort",
  CLOUD = "cloud",
  SEARCH = "search",
  FILE_NAME = "fileName",
  CREATED_DATE = "createdDate",
  CREATED_BY = "createdBy",
  TAGS = "tags",
  LABELERS = "labelers",
  STORE_TYPE = "storeType",
  SCOPE = "scopeId",
  NAME = "name",
  EMAIL = "email",
  ROLE = "role",
  ID = "id",
  ASSIGNEE = "assignee",
  STEP_TYPE = "stepType",
  LAST_UPDATE = "last_update",
  LAST_LOGIN = "last_login",
  TASK_ID = "taskId",
}
