import { useState } from "react";
import { classnames } from "utilities/classes";

interface Item {
  label: string,
  value: string,
}

interface Props {
  items: Item[],
  onItemClicked?: (item: Item) => void;
  onMouseLeave?: () => void;
}
export const VBSubMenu = ({
  items,
  onItemClicked,
  onMouseLeave,
}: Props) => {
  const [hoveredValue, setHoveredValue] = useState<string>();

  const handleItemMouseEnter = (item: Item) => {
    setHoveredValue(item.value);
  }

  const handleItemMouseLeave = (item: Item) => {
    setHoveredValue(undefined);
  }

  return (
    <div
      className="flex flex-col py-1 bg-background-900 text-sm"
      style={{
        zIndex: "1000"
      }}
      onMouseLeave={onMouseLeave}
    >
      {
        items.map(item => {
          return (
            <div
              key={item.value}
              className={classnames(
                "text-white px-4 py-2 cursor-pointer",
                {"bg-primary": hoveredValue === item.value}
              )}
              onClick={() => onItemClicked && onItemClicked(item)}
              onMouseEnter={() => handleItemMouseEnter(item)}
              onMouseLeave={() => handleItemMouseLeave(item)}
            >
              {item.label}
            </div>
          )
        })
      }
    </div>
  );
}