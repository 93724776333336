/*
 * File: index.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 29th May 2023 4:50:51 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { IconLogoFull } from "components/common/vb-icon.component";
import { getReviewRoute } from "constants/labeling.constant";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { JobService } from "services/label-service";

export function LabelerReviewPage() {
  const { jobId } = useParams<{ jobId: string }>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  useEffect(() => {
    async function loadData() {
      if (loading) return;
      try {
        setLoading(true);
        const id = parseInt(jobId.split(",")[0]);
        const response = await JobService.getReviewJob(id);
        const projectType = response.data.projectType;
        const newRoute = getReviewRoute(projectType);
        setUrl(newRoute.replace(":jobId", jobId.toString()));
        setLoading(false);
      } catch (error) {}
    }
    if (jobId) loadData();
  }, [jobId, loading]);

  if (url) {
    history.replace(url);
    return null;
  }
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <IconLogoFull />
    </div>
  );
}
export default LabelerReviewPage;
