/*
 * File: project-route.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 20th July 2021 10:34:05 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Route, RouteProps } from "react-router-dom";
import { Breadcrumb } from "../project.context";
import { ProjectBreadcrumb } from "./project-breadcrumb.component";
import ProjectPageActions from "./project-page-actions.component";
interface ProjectRouteProps extends RouteProps {
  breadcrumbs?: Breadcrumb[];
}
export const ProjectRoute = ({
  breadcrumbs = [],
  ...props
}: ProjectRouteProps) => {
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between gap-6">
        <ProjectBreadcrumb />
        <ProjectPageActions />
      </div>
      <div className="h-full p-4 mb-6 overflow-auto bg-white rounded shadow">
        <Route {...props} />
      </div>
    </div>
  );
};
