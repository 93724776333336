import { IconCloseCircle } from "components/common/vb-icon.component";

interface Props {
  fileName: string;
  onClick?(): void;
}
export function FileInfoHeader({ fileName, onClick }: Props) {
  return (
    <div className="flex items-start flex-shrink-0 gap-2.5 px-4">
      <h2 className="flex-1 overflow-hidden text-lg overflow-ellipsis">
        {fileName}
      </h2>
      <button className="flex items-center flex-shrink-0 h-7" onClick={onClick}>
        <IconCloseCircle className="w-5 h-5" />
      </button>
    </div>
  );
}
