import * as csTools from "cornerstone-tools";
import { getToolState, toolColors } from "cornerstone-tools";

import drawHandles from "./drawing/draw-handles";
import drawMask from "./drawing/draw-mask";
import drawPoints from "./drawing/draw-points";
import drawBBoxes from "./drawing/draw-bboxes";
import drawPolygons from "./drawing/draw-polygons";
import {
  moveHandleNearImagePoint,
  moveAnnotation,
} from "./rectangleroi/findAndMoveHelpers";

const BaseAnnotationTool = csTools.importInternal("base/BaseAnnotationTool");
const external = csTools.external;
const getNewContext = csTools.importInternal("drawing/getNewContext");
const draw = csTools.importInternal("drawing/draw");
const drawRect = csTools.importInternal("drawing/drawRect");
const setShadow = csTools.importInternal("drawing/setShadow");
const MouseCursor = csTools.importInternal("tools/cursors/MouseCursor");
const triggerEvent = csTools.importInternal("util/triggerEvent");
const getModule = csTools.getModule;
const EVENTS = csTools.EVENTS;

export const rectangleRoiCursor = new MouseCursor(
  `<path fill="ACTIVE_COLOR" d="M1312 256h-832q-66 0-113 47t-47 113v832q0 66 47
      113t113 47h832q66 0 113-47t47-113v-832q0-66-47-113t-113-47zm288 160v832q0
      119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119
      84.5-203.5t203.5-84.5h832q119 0 203.5 84.5t84.5 203.5z"
    />`,
  {
    viewBox: {
      x: 1792,
      y: 1792,
    },
  }
);

/**
 * @public
 * @class SAMTool
 * @memberof Tools.Annotation
 * @classdesc Tool for drawing rectangular regions of interest, and measuring
 * the statistics of the enclosed pixels.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class SAMTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: "SmartLabeling",
      supportedInteractionTypes: ["Mouse", "Touch"],
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: true,
        renderMask: true,
        mode: "DRAW",
        // showMinMax: false,
        // showHounsfieldUnits: true
      },
      svgCursor: rectangleRoiCursor,
    };

    super(props, defaultProps);
  }

  setMode(mode) {
    this.configuration.mode = mode;
  }

  getActiveMeasurement(toolState) {
    if (!toolState || toolState.data.length === 0) return undefined;
    for (let i = 0; i < toolState.data.length; i++) {
      if (toolState.data[i].active) {
        return toolState.data[i];
      }
    }
    return undefined;
  }

  preMouseDownCallback(evt) {
    const { element } = evt.detail;
    const mode = this.configuration.mode;
    const eventData = evt.detail;
    const { currentPoints } = eventData;
    const toolState = getToolState(element, this.name);
    const activeMeasurement = this.getActiveMeasurement(toolState);
    if (activeMeasurement) {
      return false;
    } else if (mode === "ADD_MASK") {
      this.preventPropagation(evt);
      const { x, y } = currentPoints.image;
      const { width, height } = eventData.image;
      if (x >= 0 && x <= width && y >= 0 && y <= height) {
        if (toolState && toolState.data && toolState.data.length > 0) {
          const measurementData = toolState.data[0];
          measurementData.includePoints.push({ x, y });
          external.cornerstone.updateImage(element);
          this.triggerEventMeasurementUpdated(evt, measurementData);
        }
      }
      return true;
    } else if (mode === "REMOVE_AREA") {
      this.preventPropagation(evt);
      const { x, y } = currentPoints.image;
      const { width, height } = eventData.image;
      if (x >= 0 && x <= width && y >= 0 && y <= height) {
        if (toolState && toolState.data && toolState.data.length > 0) {
          const measurementData = toolState.data[0];
          measurementData.excludePoints.push({ x, y });
          external.cornerstone.updateImage(element);
          this.triggerEventMeasurementUpdated(evt, measurementData);
        }
      }
      return true;
    }
  }

  doneMovingCallback(evt, data, isMoveAll) {
    if (!isMoveAll && data) this._validateHandles(data);
    if (data) this.triggerEventMeasurementUpdated(evt, data);
  }

  handleSelectedCallback(evt, toolData, handle, interactionType = "mouse") {
    if (toolData?.locked && !handle.hasBoundingBox) return;
    this.triggerEventMeasurementSelected(evt, toolData);
    moveHandleNearImagePoint(evt, this, toolData, handle, interactionType, () =>
      this.doneMovingCallback(evt, toolData, false)
    );
  }

  toolSelectedCallback(evt, annotation, interactionType = "mouse") {
    this.triggerEventMeasurementSelected(evt, annotation);
    moveAnnotation(evt, this, annotation, interactionType, () =>
      this.doneMovingCallback(evt, annotation, true)
    );
  }

  triggerEventMeasurementUpdated(evt, data) {
    const eventType = "cornerstonetoolsmeasurementupdated";
    const element = evt.detail.element;
    const eventData = {
      toolName: this.name,
      toolType: this.name,
      element,
      measurementData: data,
    };

    triggerEvent(element, eventType, eventData);
  }

  triggerEventMeasurementSelected(evt, data) {
    const eventType = "cornerstonetoolsmeasurementselected";
    const element = evt.detail.element;
    const eventData = {
      toolName: this.name,
      toolType: this.name,
      element,
      measurementData: data,
    };

    triggerEvent(element, eventType, eventData);
  }

  fireModifiedEvent(element, measurementData) {
    const eventType = EVENTS.MEASUREMENT_MODIFIED;
    const eventData = {
      toolName: this.name,
      toolType: this.name,
      element,
      measurementData,
    };

    triggerEvent(element, eventType, eventData);
  }

  preventPropagation(evt) {
    evt.stopImmediatePropagation();
    evt.stopPropagation();
    evt.preventDefault();
  }

  createNewMeasurement(eventData) {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      console.error(
        `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      );

      return;
    }

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      type: this.name,
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        topRight: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        bottomLeft: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        left: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        top: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        right: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        bottom: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        initialRotation: eventData.viewport.rotation,
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
      includePoints: [],
      excludePoints: [],
      bboxes: [],
      polygons: [],
    };
  }

  pointNearTool(element, data, coords, interactionType) {
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters) {
      console.warn(
        `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      );
    }

    if (!validParameters || data.visible === false) {
      return false;
    }

    const distance = interactionType === "mouse" ? 15 : 25;
    const startCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.start
    );
    const endCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.end
    );

    const rect = {
      left: Math.min(startCanvas.x, endCanvas.x),
      top: Math.min(startCanvas.y, endCanvas.y),
      width: Math.abs(startCanvas.x - endCanvas.x),
      height: Math.abs(startCanvas.y - endCanvas.y),
    };

    const distanceToPoint = external.cornerstoneMath.rect.distanceToPoint(
      rect,
      coords
    );

    return distanceToPoint < distance;
  }

  renderToolData(evt) {
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const eventData = evt.detail;
    const { element } = eventData;
    const lineDash = getModule("globalConfiguration").configuration.lineDash;
    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
    } = this.configuration;
    const context = getNewContext(eventData.canvasContext.canvas);

    draw(context, (context) => {
      // If we have tool data for this element - iterate over each set and draw it
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        if (data.visible === false) {
          continue;
        }

        // Configure
        const color = toolColors.getColorIfActive(data);
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        setShadow(context, this.configuration);

        const rectOptions = { color };

        if (renderDashed) {
          rectOptions.lineDash = lineDash;
        }

        if (data.includePoints && data.includePoints.length > 0) {
          drawPoints(context, eventData, data.includePoints, "#00FFFF");
        }

        if (data.excludePoints && data.excludePoints.length > 0) {
          drawPoints(context, eventData, data.excludePoints, "#ffc0cb");
        }

        if (data.bboxes && data.bboxes.length > 0) {
          // draw bboxes
          drawBBoxes(context, eventData, data.bboxes);
        }

        if (data.polygons && data.polygons.length > 0) {
          // draw polygons
          drawPolygons(context, eventData, data.polygons);
        }

        const rect = this._getMeasurementBBox(data);

        if (this.configuration.renderMask) {
          drawMask(context, eventData, rect, handleOptions);
        }

        // Draw
        drawRect(
          context,
          element,
          data.handles.start,
          data.handles.end,
          rectOptions,
          "pixel",
          data.handles.initialRotation
        );

        if (this.configuration.drawHandles) {
          this._updateHandles(data);
          this._updateHandlesPosition(data, rect);
          drawHandles(context, eventData, data.handles, handleOptions);
        }
      }
    });
  }

  _validateHandles(data) {
    if (!data || !data.handles || !data.handles.start || !data.handles.end)
      return;
    const xArray = [data.handles.start.x, data.handles.end.x];
    const yArray = [data.handles.start.y, data.handles.end.y];
    data.handles.start.x = Math.min(...xArray);
    data.handles.start.y = Math.min(...yArray);
    data.handles.end.x = Math.max(...xArray);
    data.handles.end.y = Math.max(...yArray);
  }

  _updateHandles(data) {
    if (!data || !data.handles || !data.handles.start || !data.handles.end)
      return;
    if (data.handles.top.moving) {
      data.handles.start.y = data.handles.top.y;
    } else if (data.handles.right.moving) {
      data.handles.end.x = data.handles.right.x;
    } else if (data.handles.bottom.moving) {
      data.handles.end.y = data.handles.bottom.y;
    } else if (data.handles.left.moving) {
      data.handles.start.x = data.handles.left.x;
    } else if (data.handles.topRight.moving) {
      data.handles.end.x = data.handles.topRight.x;
      data.handles.start.y = data.handles.topRight.y;
    } else if (data.handles.bottomLeft.moving) {
      data.handles.start.x = data.handles.bottomLeft.x;
      data.handles.end.y = data.handles.bottomLeft.y;
    }
  }

  _getMeasurementBBox(data) {
    if (!data || !data.handles || !data.handles.start || !data.handles.end)
      return undefined;
    return _getRectangleImageCoordinates(data.handles.start, data.handles.end);
  }

  _updateHandlesPosition(data, rect) {
    if (!data || !rect) return;
    data.handles.top.x = rect.left + rect.width / 2;
    data.handles.top.y = rect.top;
    data.handles.right.x = rect.left + rect.width;
    data.handles.right.y = rect.top + rect.height / 2;
    data.handles.bottom.x = rect.left + rect.width / 2;
    data.handles.bottom.y = rect.top + rect.height;
    data.handles.left.x = rect.left;
    data.handles.left.y = rect.top + rect.height / 2;
    data.handles.topRight.x = rect.left + rect.width;
    data.handles.topRight.y = rect.top;
    data.handles.bottomLeft.x = rect.left;
    data.handles.bottomLeft.y = rect.top + rect.height;
  }

  /**
   * Updates cached statistics for the tool's annotation data on the element
   *
   * @param {*} image
   * @param {*} element
   * @param {*} data
   * @returns {void}
   */
  updateCachedStats(_image, _element, _data) {}
}

/**
 * TODO: This is the same method (+ GetPixels) for the other ROIs
 * TODO: The pixel filtering is the unique bit
 *
 * @param {*} startHandle
 * @param {*} endHandle
 * @returns {{ left: number, top: number, width: number, height: number}}
 */
function _getRectangleImageCoordinates(startHandle, endHandle) {
  return {
    left: Math.min(startHandle.x, endHandle.x),
    top: Math.min(startHandle.y, endHandle.y),
    width: Math.abs(startHandle.x - endHandle.x),
    height: Math.abs(startHandle.y - endHandle.y),
  };
}
