/*
 * File: text-viewer.models.ts
 * Project: aiscaler-web
 * File Created: Tuesday, 26th October 2021 10:56:03 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { NERAnnotation, Token } from "domain/text-labeling";

export interface AnnotationData {
  annotationId: string;
  observationId: string;
  tokenIds: string[];
  relationIds: string[];
  lines: Line[];
  boundingBox: BoundingBox;
  annotation?: NERAnnotation;
  lineIndex?: number;
}

export interface TokenData {
  id: string;
  model: Token;
  box: BoundingBox;
}

export interface Point {
  x: number;
  y: number;
}

export interface Line {
  start: Point;
  end: Point;
}

export interface BoundingBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const DEFAULT_BOUNDING_BOX = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

export interface SentenceModel {
  startIndex: number;
  endIndex: number;
  sentenceIndex: number;
  sentence: string;
}

export interface SentenceData {
  model: SentenceModel;
  tokenIds: string[];
  tokenEntities: Record<string, Token>;
  tokenBoxes: Record<string, BoundingBox>;
}
