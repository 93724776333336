import { AxiosResponse } from "axios";
import { AnnotationSource } from "domain/labeling/annotation-source";
import { RequestOptions } from "services/common/request-options";
import {
  AnnotationJobRequestDTO,
  AnnotationJobResponseDTO,
} from "../dtos/annotations.dto";
import { LabelAPI } from "../http/label-service.httpclient";
import { BaseAPI } from "./base.api";

export class AnnotationsAPI extends BaseAPI<any> {
  saveAnnotationsForJobId(
    jobId: string | number,
    payload: AnnotationJobRequestDTO
  ) {
    return LabelAPI.post(
      `${this.getPath()}/labeler/annotations/job/${jobId}`,
      payload
    );
  }

  finishJob(jobId: string | number) {
    return LabelAPI.post(`${this.getPath()}/labeler/jobs/finish/${jobId}`);
  }

  getAnnotations(
    options: RequestOptions
  ): Promise<AxiosResponse<AnnotationJobResponseDTO>> {
    const params = new URLSearchParams(options);
    return LabelAPI.get(`${this.getPath()}/annotations?${params.toString()}`);
  }

  getAnnotationsByFile(
    batchId: string | number,
    mediaId: string | number
  ): Promise<AxiosResponse<AnnotationJobResponseDTO>> {
    return this.getAnnotations({
      "batchId.equals": batchId.toString(),
      "mediaId.equals": mediaId.toString(),
      "source.in": [
        AnnotationSource.CLIENT,
        AnnotationSource.IMPORT,
        AnnotationSource.MODEL,
      ].join(","),
    });
  }

  getAnnotationsBySourceImportOrModel(
    batchId: string | number,
    mediaId: string | number
  ): Promise<AxiosResponse<AnnotationJobResponseDTO>> {
    return this.getAnnotations({
      "batchId.equals": batchId.toString(),
      "mediaId.equals": mediaId.toString(),
      "source.in": [AnnotationSource.IMPORT, AnnotationSource.MODEL].join(","),
    });
  }

  getAnnotationsSystem(
    batchId: string | number,
    mediaId: string | number
  ): Promise<AxiosResponse<AnnotationJobResponseDTO>> {
    return this.getAnnotations({
      "batchId.equals": batchId.toString(),
      "mediaId.equals": mediaId.toString(),
      "source.in": [AnnotationSource.IMPORT, AnnotationSource.MODEL].join(","),
    });
  }

  getAnnotationsByTaskId(
    taskId: string | number
  ): Promise<AxiosResponse<AnnotationJobResponseDTO>> {
    return LabelAPI.get(`${this.getPath()}/annotations/task/${taskId}`);
  }

  // NOTE: this api also response previous jobs annotations
  getAnnotationsByJobId(
    jobId: string | number
  ): Promise<AxiosResponse<AnnotationJobResponseDTO>> {
    return LabelAPI.get(`${this.getPath()}/labeler/annotations/job/${jobId}`);
  }

  // training stuff, get by exported datasetId, get by runId
  getAnnotationsByDatasetVersionId(
    datasetVersionId: string | number
  ): Promise<AxiosResponse<AnnotationJobResponseDTO>> {
    return LabelAPI.get(
      `${this.getPath()}/annotations/dataset/${datasetVersionId}`
    );
  }

  getAnnotationsByRunId(
    runId: string | number
  ): Promise<AxiosResponse<AnnotationJobResponseDTO>> {
    const params = new URLSearchParams({
      runId: runId.toString(),
    });
    return LabelAPI.get(
      `${this.getPath()}/annotations/dataset/run?${params.toString()}`
    );
  }
}
