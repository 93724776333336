/*
 * File: react-select.styles.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 26th April 2023 10:07:10 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 * 
 * Copyright 2023 VinBrain JSC
 */


export const reactSelectStyles = {
    control: (provided: any) => {
      let props = {
        ...provided,
        background: "#020617",
        color: "#f1f5f9",
        borderColor: "#334155",
        minWidth: 120,
      };
      return props;
    },
    singleValue: (provided: any) => ({
      ...provided,
      background: "#020617",
      color: "#f1f5f9",
    }),
    menu: (provided: any) => {
      return {
        ...provided,
        background: "#020617",
        color: "#f1f5f9",
      };
    },
    indicatorSeparator: (provided: any) => {
      return {
        ...provided,
        display: "none",
      };
    },
    option: (provided: any, state: any) => {
      const { isFocused, isSelected } = state;
      return {
        ...provided,
        background: isFocused || isSelected ? "#111827" : undefined,
        color: isFocused || isSelected ? "#fff" : "#f1f5f9",
        padding: "8px 10px",
        ":active": {
          backgroundColor: "#1e293b",
        },
      };
    },
    input: (provided: any) => {
      return {
        ...provided,
        border: "none",
        outline: "none",
        color: "white",
        boxShadow: "none !important",
      };
    },
  };