/*
 * File: project-template.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 29th July 2022 10:52:08 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ProjectTemplate } from "domain/labeling/project-template";
import { ProjectTemplateDTO } from "./project-template.dto";

function toEntity(dto: ProjectTemplateDTO): ProjectTemplate {
  return {
    id: dto.projectInfo.id,
    title: dto.projectInfo.name,
    description: dto.projectInfo.description,
    imageUrl: dto.thumbnailUrl,
    type: dto.projectInfo.type,
    group: dto.projectInfo.group,
    suggestedAnnotationTypes: dto.suggestedAnnotationTypes,
    dto: dto,
    order: dto.projectInfo.order ?? 0,
  };
}

export const projectTemplateMapper = {
  toEntity,
};
