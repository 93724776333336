/*
 * File: project-labeler-performance.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 6:10:39 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useAppSelector } from "hooks/use-redux";
import { UserDTO } from "services/user-service/dtos/user.dto";
import { selectProjectLabelerDistributionData } from "store/customer/project-data/labeler-distribution/labeler-distribution.selectors";
import { selectProjectObservations } from "store/customer/project/project.selectors";
import { AtLeast } from "types/common";
import { LabelerPerformance } from "./labeler-performance.component";

interface Props {
  projectId: number;
  labeler: AtLeast<UserDTO, "email">;
  expanded?: boolean;
  onExpandChange?(expand: boolean): void;
}

export const ProjectLabelerPerformance = ({
  projectId,
  labeler,
  expanded,
  onExpandChange,
}: Props) => {
  const labels = useAppSelector(selectProjectObservations);
  const distributionData = useAppSelector(
    selectProjectLabelerDistributionData(projectId, labeler.email)
  );
  return (
    <LabelerPerformance
      distributionData={distributionData}
      labeler={labeler}
      labels={labels}
      expanded={expanded}
      onExpandChange={onExpandChange}
    />
  );
};
