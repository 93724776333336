/*
 * File: app-setting.slice.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 1st April 2022 5:06:14 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProjectService } from "services/label-service";
import {
  AppSettingState,
  INITIAL_APP_SETTING_STATE,
} from "./app-setting.state";
import { SettingService } from "services/setting-service/setting.service";
import { COLOR_OPTIONS } from "constants/common.constant";
import { RootState } from "store";
import { selectAppVersion } from "./app-setting.selectors";

const SLICE_NAME: string = "app_setting";

export enum AppSettingThunk {
  LOAD_PROJECT_TYPES_ASYNC = "app_setting/loadSupportedProjectTypesAsync",
  LOAD_SYSTEM_COLOR_ASYNC = "app_setting/loadSystemColorAsync",
  LOAD_APP_SETTING_ASYNC = "app_setting/loadAppSettingAsync",
}

export const loadSupportedProjectTypesAsync = createAsyncThunk(
  AppSettingThunk.LOAD_PROJECT_TYPES_ASYNC,
  async () => {
    const response = await ProjectService.getTypes();
    return response.data;
  }
);

export const loadSupportedSystemColorsAsync = createAsyncThunk(
  AppSettingThunk.LOAD_SYSTEM_COLOR_ASYNC,
  async () => {
    const responses = await SettingService.getAllSupportColor();
    return responses.data;
  }
);

export const loadAppSettingAsync = createAsyncThunk(
  AppSettingThunk.LOAD_APP_SETTING_ASYNC,
  async (_, { getState }) => {
    const root = getState() as RootState;
    if (root.appSetting.appConfig) return null;
    const appVersion = selectAppVersion(root);
    const responses = await SettingService.getAppSetting(appVersion);
    return responses.data;
  }
);

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_APP_SETTING_STATE,
  reducers: {
    resetAppSettingState(state: AppSettingState) {
      Object.assign(state, INITIAL_APP_SETTING_STATE);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadSupportedProjectTypesAsync.fulfilled,
      (state, action) => {
        if (action.payload) state.supportedProjectTypes = action.payload;
      }
    );
    builder.addCase(
      loadSupportedSystemColorsAsync.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.supportedSystemColors = action.payload.map(({ code }) => code);
        }
      }
    );
    builder.addCase(
      loadSupportedSystemColorsAsync.rejected,
      (state, action) => {
        if (action.error) state.supportedSystemColors = COLOR_OPTIONS;
      }
    );

    builder.addCase(loadAppSettingAsync.fulfilled, (state, action) => {
      if (action.payload) state.appConfig = action.payload;
    });
  },
});

export const { resetAppSettingState } = slice.actions;

export const appSettingReducer = slice.reducer;
