/*
 * File: notification-table.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th September 2021 9:52:37 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ColumnHeaderWrapper } from "components/common/vb-grid/column-wrapper.component";
import { DateColumnHeader } from "components/common/vb-grid/date-column-header.component";
import { SearchableColumnHeader } from "components/common/vb-grid/searchable-column-header.component";
import { SelectableColumnHeader } from "components/common/vb-grid/selectable-column-header.component";
import { IconTrash } from "components/common/vb-icon.component";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { classnames } from "utilities/classes";
import { capitalizeFirstLetter } from "utilities/string/capitalize";
import { NotificationFilter, NotificationRow } from "../notifications.models";

interface Props {
  rows: NotificationRow[];
  filter: NotificationFilter;
  setFilterField: (field: keyof NotificationFilter, value: any) => void;
  onRowAction?: (row: NotificationRow, action: string) => void;
}

export const NotificationTable = ({
  rows,
  filter,
  setFilterField,
  onRowAction,
}: Props) => {
  const { t } = useTranslation();
  const levelOptions = [
    { label: "Info", value: "info" },
    { label: "Warning", value: "warning" },
    { label: "Error", value: "error" },
  ];
  const typeOptions = useMemo(() => {
    const options: any = [];
    for (const row of rows) {
      if (!options.find((op: any) => op.value === row.type)) {
        options.push({
          label: capitalizeFirstLetter(row.type),
          value: row.type,
        })
      }
    }
    return options;
  }, [rows]);

  return (
    <div className="min-w-full">
      <table className="min-w-full text-sm vb-table">
        <thead>
          <tr>
            <ColumnHeaderWrapper first>
              <SelectableColumnHeader
                className="leading-normal"
                placeholder="All"
                header="Level"
                containerWidth="100%"
                menuPortalTarget={document.body}
                defaultValue={levelOptions.find(
                  (option) => option.value === filter?.level
                )}
                options={levelOptions}
                onChange={(option: any) => {
                  if (option) {
                    setFilterField("level", option.value);
                  } else {
                    setFilterField("level", undefined);
                  }
                }}
                isClearable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <SelectableColumnHeader
                className="leading-normal"
                placeholder="All"
                header="Type"
                containerWidth="100%"
                menuPortalTarget={document.body}
                defaultValue={typeOptions.find(
                  (option: any) => option.value === filter?.type
                )}
                options={typeOptions}
                onChange={(option: any) => {
                  if (option) {
                    setFilterField("type", option.value);
                  } else {
                    setFilterField("type", undefined);
                  }
                }}
                isClearable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper>
              <DateColumnHeader
                containerClassName="w-full py-2"
                header={"Time"}
                placeholderText={t("common:formattedDate", {
                  date: new Date(),
                })}
                containerWidth="100%"
                defaultValue={filter?.time}
                onChange={(date) =>
                  date
                    ? setFilterField("time", date)
                    : setFilterField("time", undefined)
                }
                clearable
              />
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper last>
              <SearchableColumnHeader
                containerClassName="w-full py-2 text-sm"
                clearInput
                searchIcon={false}
                placeholder={""}
                header={"Content"}
                containerWidth={"100%"}
              />
            </ColumnHeaderWrapper>
          </tr>
        </thead>
        <tbody className="relative">
          {rows.map((row) => {
            return (
              <tr
                key={row.id}
                className="relative h-10 hover:bg-secondary-50 parent"
              >
                <td className={classnames(
                  "pl-4 pr-4",
                  {"text-yellow-500": row.level === "warning"},
                  {"text-green-500": row.level === "info"},
                  {"text-error-500": row.level === "error"},
                )}
                    style={{ minWidth: 100 }}>
                  {capitalizeFirstLetter(row.level)}
                </td>
                <td className="pr-4" style={{ minWidth: 100 }}>{row.type}</td>
                <td className="pr-4" style={{ minWidth: 200 }}>{t("common:formattedDate", {date: row.time})}</td>
                <td className="pr-4" style={{ minWidth: 200 }}>{row.content}</td>
                <td
                  className="absolute h-full transform -translate-x-full -translate-y-1/2 top-1/2 child-on-hover"
                  style={{ zIndex: "999" }}
                >
                  <div className="flex flex-row items-center h-full gap-3 px-3 text-error-600 bg-secondary-50">
                    <button>
                      <IconTrash
                        onClick={(_) =>
                          onRowAction &&
                          onRowAction(row, "delete")
                        }
                        className="w-4 h-4"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
          <tr className="absolute top-0 left-0 w-full h-full border rounded pointer-events-none border-background-300"></tr>
        </tbody>
      </table>
    </div>
  )
};
