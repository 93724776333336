import { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { RunNewExperimentModal } from "./run-new-experiment.modal";
import { MlModelsRunInfo } from "../ml-models.models";
import { mlServiceApis } from "../../../../services/ml-service/ml.service";
import { runInfoMapper } from "../ml-models.mappers";
import { RunInfosTable } from "./run-infos.table";
import { RegisterRunModal } from "./register-run.modal";
import { useMLModelContext } from "../ml-models.context";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { useAppDispatch } from "hooks/use-redux";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { MlRunStatus } from "services/ml-service/ml-service.dto";
import * as Sentry from "@sentry/react";

export const ExperimentDetailPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { experimentId } = useParams<{ experimentId: string }>();
  const { setCurrentRunInfo } = useMLModelContext();
  const [runNewExperimentModalVisibility, setRunNewExperimentModalVisibility] =
    useState(false);
  const [registerModalVisibility, setRegisterModalVisibility] = useState(false);

  const [runInfos, setRunInfos] = useState<MlModelsRunInfo[]>([]);
  const [selectedRunInfo, setSelectedRunInfo] = useState<MlModelsRunInfo>();

  const [isLoading, setIsLoading] = useState(false);

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await mlServiceApis.getRunInfosByExperimentId(experimentId);
      const items: MlModelsRunInfo[] = res.map(runInfoMapper.fromEntity);
      items.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime());
      setRunInfos(items);
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [experimentId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const hanldeSubmittedNewRun = () => {
    loadData();
    setRunNewExperimentModalVisibility(false);
  };

  const handleRowAction = async (row: MlModelsRunInfo, action: string) => {
    if (action === "register") {
      setSelectedRunInfo(row);
      setRegisterModalVisibility(true);
    } else if (action === "terminate") {
      return terminateRun(row);
    } else if (action === "view") {
      setCurrentRunInfo(row);
      const url = `/models/experiments/${experimentId}/${row.runId}`;
      history.push(url);
    }
  };

  async function terminateRun(row: MlModelsRunInfo) {
    try {
      setRunInfos(
        runInfos.map((run) => {
          if (run.runId === row.runId) {
            return {
              ...run,
              status: MlRunStatus.TERMINATING,
            };
          }
          return run;
        })
      );
      const response = await mlServiceApis.terminateRun(row.runId);
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification("Success"));
      loadData();
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error?.message || "Failed";
      dispatch(enqueueErrorNotification(errMessage));
      setRunInfos(
        runInfos.map((run) => {
          if (run.runId === row.runId) {
            return {
              ...run,
              status: MlRunStatus.RUNNING,
            };
          }
          return run;
        })
      );
    }
  }

  return (
    <Fragment>
      <div className="flex flex-col h-full gap-4">
        <div className="flex items-center justify-between flex-none">
          <p className="flex-shrink-0 text-lg font-bold">Running instances</p>
          <button
            className="button-primary"
            onClick={() => setRunNewExperimentModalVisibility(true)}
          >
            Run new experiment
          </button>
        </div>

        <RunInfosTable
          rows={runInfos}
          isLoading={isLoading}
          onRowAction={handleRowAction}
        />
      </div>

      {runNewExperimentModalVisibility && (
        <RunNewExperimentModal
          open={runNewExperimentModalVisibility}
          experimentId={experimentId}
          onClose={() => setRunNewExperimentModalVisibility(false)}
          onSubmitted={hanldeSubmittedNewRun}
        />
      )}

      {registerModalVisibility && selectedRunInfo && (
        <RegisterRunModal
          runInfo={selectedRunInfo}
          open={registerModalVisibility}
          onClose={() => setRegisterModalVisibility(false)}
        />
      )}
    </Fragment>
  );
};
