import { DicomThumbnail } from "components/dicom/dicom-thumbnail/dicom-thumbnail.component";
import { ThumbnailType } from "services/dicom/dicom-service.util";
import { DicomMetadata } from "models/dataset/storage-file.model";
import VBImage from "components/common/vb-image/vb-image.component";

interface Props {
  previewUrl: string;
  dicomMetadata?: DicomMetadata;
}
export function FileInfoImagePreview({ previewUrl, dicomMetadata }: Props) {
  return (
    <div className="flex justify-center w-full">
      <div className="relative w-full h-64 my-2 bg-black">
        <div className="flex items-center justify-center w-full h-full">
          {previewUrl && !dicomMetadata && (
            <VBImage requireToken src={previewUrl} alt="" />
          )}
          {dicomMetadata && (
            <DicomThumbnail
              type={ThumbnailType.INSTANCE}
              StudyInstanceUID={dicomMetadata.studyId}
              SeriesInstanceUID={dicomMetadata.seriesId}
              SOPInstanceUID={dicomMetadata.instanceId}
            />
          )}
        </div>
      </div>
    </div>
  );
}
