import { AvatarGroup } from "@material-ui/lab";
import { UserAvatar } from "components/common/vb-user-avatar.component";
import {
  SelectOption,
  VBSelectComponent,
} from "components/design-system/select-input/select.component";
import { JobAnnotation } from "domain/image-labeling/job-annotation";
import { useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StylesConfig } from "react-select";
import { selectPreviousJobAnnotations } from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { truncateEmail } from "utilities/string/truncate-email";

const colourStyles: StylesConfig<any, true> = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "rgba(255, 255, 255, 0.06)",
  }),
  option: (styles) => {
    return {
      ...styles,
      color: "#fff",
      backgroundColor: "#23262F",
      fontSize: "14px",
      cursor: "pointer",
    };
  },
  multiValue: (styles) => ({
    ...styles,
    padding: 0,
    backgroundColor: "#23262F",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "white",
    backgroundColor: "#23262F",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    backgroundColor: "#23262F",
    borderRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    ":hover": {
      backgroundColor: "#1E1F20",
      color: "#fff",
    },
  }),
  menuList: (styles) => ({
    ...styles,
    backgroundColor: "#23262F",
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    backgroundColor: "#23262F",
    color: "#fff",
    fontSize: "14px",
  }),
};

interface ActionPanelLabelerProps {
  onChange?: (emails: string[]) => void;
}

export const ActionPanelLabeler = ({ onChange }: ActionPanelLabelerProps) => {
  const jobAnnotations = useAppSelector(selectPreviousJobAnnotations);
  const [values, setValues] = useState<SelectOption[]>([]);

  const options = useMemo(() => {
    return jobAnnotations.map((jobAnnotation: JobAnnotation) => ({
      label: truncateEmail(jobAnnotation.annotator),
      value: jobAnnotation.annotator,
    }));
  }, [jobAnnotations]);

  const selectedEmails = useMemo(() => {
    return values.map((option: SelectOption) => option.value);
  }, [values]);

  useEffect(() => {
    const emails = options.map((option: SelectOption) => option.value);
    onChange && onChange(emails);
    setValues(options);
  }, [onChange, options]);

  const handleChangeLabeler = useCallback(
    (options: SelectOption[]) => {
      const emails = options.map((option) => option.value);
      onChange && onChange(emails);
      setValues(options);
    },
    [onChange]
  );

  return (
    <>
      <div className=" text-white text-sm font-semibold mb-4">
        Show Labeling Work
      </div>
      <AvatarGroup className="mb-4">
        {selectedEmails.map((email) => (
          <UserAvatar
            key={email}
            name={email}
            className="flex-shrink-0 w-10 h-10 text-sm"
          />
        ))}
      </AvatarGroup>
      <VBSelectComponent
        hideSelectedOptions
        isMulti
        menuPortalTarget={document.body}
        options={options}
        onChange={handleChangeLabeler as any}
        size="none"
        textColor="white"
        value={values}
        bordered={false}
        isClearable={false}
        styles={colourStyles as any}
      />
    </>
  );
};
