import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { collectionUtils } from "domain/common";
import { ImageWorkspaceState } from "../../image-workspace.state";
import { imageAnnotationUtils } from "../image-annotations.util";

const THUNK_NAME = "image-annotations/imageSystemLabelSelectedAsync";

interface ImageSystemLabelSelectedPayload {
  jobId: number;
  labelId: number;
}

export const imageSystemLabelSelectedAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: ImageSystemLabelSelectedPayload) => {
    return payload;
  }
);

export const imageSystemLabelSelectedBuilder = (
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) => {
  return builder.addCase(
    imageSystemLabelSelectedAsync.fulfilled,
    ({ imageAnnotations, imageLabeling }, action) => {
      const { labelId, jobId } = action.payload;
      const { labels } = imageLabeling;
      const labelCollection = collectionUtils.fromEntities(labels);
      // remove all existing system label for current job.
      imageAnnotations.annotations = imageAnnotations.annotations.filter(
        (anno) => {
          if (anno.jobId !== jobId) return true;
          const label = collectionUtils.getOne(labelCollection, anno.labelId);
          if (label && label.isSystemLabel) return false;
          return true;
        }
      );
      if (labelId === -1) return;
      // add new system label
      const annotator = imageLabeling.job?.assignee || "";
      const annotation = imageAnnotationUtils.createNewSystemAnnotation(
        jobId,
        labelId,
        annotator
      );
      imageAnnotations.annotations.push(annotation);
    }
  );
};
