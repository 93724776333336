/*
 * File: loading.page.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 8th July 2021 3:48:43 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { IconLogoFull } from "components/common/vb-icon.component";

export const LoadingPage = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <IconLogoFull />
    </div>
  );
};
