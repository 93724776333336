/*
 * File: storage-col-def.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 17th August 2021 11:50:03 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { GridColDef } from "@material-ui/data-grid";
import { VBDatagridCellHoverDisplay } from "../../../../../../../components/common/vb-datagrid/vb-datagrid-cell-hover-display";
import { CreatedByHeader } from "./headers/created-by-header";
import { CreatedDateHeader } from "./headers/created-date-header";
import { FileNameHeader } from "./headers/file-name-header";
import { StoreTypeHeader } from "./headers/store-type-header";
import { TagsHeader } from "./headers/tags-header";

export const columns: GridColDef[] = [
  {
    field: "fileName",
    filterable: false,
    flex: 2,
    renderHeader: FileNameHeader,
    renderCell: VBDatagridCellHoverDisplay,
    minWidth: 120,
  },
  {
    field: "createdDate",
    filterable: false,
    flex: 2,
    renderHeader: CreatedDateHeader,
    renderCell: VBDatagridCellHoverDisplay,
  },
  {
    field: "createdBy",
    filterable: false,
    flex: 2,
    renderHeader: CreatedByHeader,
    renderCell: VBDatagridCellHoverDisplay,
  },
  {
    field: "tags",
    filterable: false,
    sortable: false,
    flex: 2,
    renderHeader: TagsHeader,
    renderCell: VBDatagridCellHoverDisplay,
  },
  {
    field: "storeType",
    filterable: false,
    sortable: false,
    flex: 2,
    renderHeader: StoreTypeHeader,
    renderCell: VBDatagridCellHoverDisplay,
  },
];
