import { Checkbox, Slider } from "@material-ui/core";
import { useDebounceFn } from "ahooks";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect, useState } from "react";
import { selectTaskReviewMinIouScore } from "store/customer/tasks-review/task-review-iou/task-review-iou.selectors";
import { taskReviewIouAnnotationsLoadedAsync } from "store/customer/tasks-review/task-review-iou/task-review-iou.thunk";
import { setTaskReviewIouActiveScore } from "store/customer/tasks-review/tasks-review.slice";

interface TaskReviewActionPanelFindingIouProps {
  taskId: number;
}

export const TaskReviewActionPanelFindingIou = ({
  taskId,
}: TaskReviewActionPanelFindingIouProps) => {
  const dispatch = useAppDispatch();
  const minIouScore = useAppSelector(selectTaskReviewMinIouScore);

  const [score, setScore] = useState(0);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    dispatch(taskReviewIouAnnotationsLoadedAsync({ taskId }));
  }, [dispatch, taskId]);

  useEffect(() => {
    const score = minIouScore;
    setScore(score);
  }, [minIouScore, dispatch]);

  const { run: debouncedSlider } = useDebounceFn(
    (score: number) => {
      dispatch(setTaskReviewIouActiveScore(score));
    },
    {
      wait: 300,
    }
  );

  const handleChangeCheckbox = useCallback(
    (checked: boolean) => {
      setChecked(checked);
      dispatch(setTaskReviewIouActiveScore(checked ? score : -1));
    },
    [dispatch, score]
  );

  const handleChangeIoU = useCallback(
    (event: any, newValue: number | number[]) => {
      setScore(newValue as number);
      debouncedSlider(newValue as number);
    },
    [debouncedSlider]
  );

  return (
    <div className="flex justify-start items-center gap-4">
      <div className="flex justify-start items-center gap-1">
        <Checkbox
          color="primary"
          size="small"
          checked={checked}
          onChange={(_, selected) => handleChangeCheckbox(selected)}
          style={{
            padding: 0,
          }}
        />
        <div className="text-sm font-semibold">IoU</div>
      </div>

      <Slider
        track={false}
        aria-labelledby="track-false-slider"
        value={score}
        min={0}
        step={0.01}
        max={1}
        onChange={handleChangeIoU}
        disabled={!checked}
      />
      <span className="text-sm">{score}</span>
    </div>
  );
};
