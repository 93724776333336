/*
 * File: delete-task.dialog.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/use-redux";
import { useState } from "react";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { TaskDTO } from "services/label-service/dtos";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { deleteTaskAsync } from "store/customer/batch/batch.thunk";
import * as Sentry from "@sentry/react";

interface Props {
  task: TaskDTO;
  handleClose: () => void;
}

export const DeleteTaskDialog = ({ task, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);
  async function handleDelete() {
    if (!task) return;
    if (processing) return;
    setProcessing(true);
    try {
      const response = await dispatch(deleteTaskAsync(task.id));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textDeletedSuccess")));
    } catch (err: any) {
      Sentry.captureException(err);
      const message = err.message || t("common:textDeletedFailed");
      dispatch(enqueueErrorNotification(message));
    } finally {
      handleClose();
      setProcessing(false);
    }
  }

  return (
    <VBModal
      title={t("project:batchDetails.deleteTask.title")}
      open={!!task}
      onClose={handleClose}
      textSubmit={t("common:buttonDelete")}
      onSubmit={handleDelete}
      disableSubmit={processing}
      blockUI={processing}
      processingIndicator={processing}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: t("project:batchDetails.deleteTask.message", {
            name: task.id,
          }),
        }}
      />
    </VBModal>
  );
};
