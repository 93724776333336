/*
 * File: batch-statistic.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 23rd August 2021 11:51:25 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "store";

export const selectBatchStatisticRequestStatus = (state: RootState) => {
  return state.batchData.statistic.status;
};

export const selectBatchStatisticRequestError = (state: RootState) => {
  return state.batchData.statistic.error;
};

export const selectBatchStatistic = (batchId: number) => (state: RootState) => {
  return state.batchData.statistic.entities[batchId] || null;
};
