/*
 * File: datasets.router.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 8th July 2021 3:48:43 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Switch, Route, useRouteMatch } from "react-router-dom";
import { DatasetProvider } from "../context/dataset-provider";
import { DatasetDetailPage } from "../dataset-detail/dataset-detail.page";
import { DatasetsPage } from "../datasets.page";
import FileBrowsePage from "../file-browse/file-browse.page";
import FileBrowseProvider from "../file-browse/context/file-browse.provider";
export const DatasetsRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={path}
        component={() => {
          return (
            <DatasetProvider>
              <DatasetsPage />
            </DatasetProvider>
          );
        }}
      />
      <Route
        path={`${path}/browse`}
        component={() => {
          return (
            <FileBrowseProvider>
              <FileBrowsePage />
            </FileBrowseProvider>
          );
        }}
      />
      <Route
        path={`${path}/:datasetId`}
        component={() => {
          return (
            <DatasetProvider>
              <DatasetDetailPage />
            </DatasetProvider>
          );
        }}
      />
    </Switch>
  );
};
