/*
 * File: image-annotations-panel.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 14th October 2022 2:55:21 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { VBCheckbox } from "components/design-system/checkbox/checkbox.component";
import { useImageAnnotationSourcesContext } from "./context/image-annotation-sources.context";

export default function ImageAnnotationsPanel() {
  const { annotators, selectAnnotator } = useImageAnnotationSourcesContext();

  return (
    <div className="flex-none py-4 text-white">
      <h3 className="px-4 text-sm font-semibold text-background-500">
        AVAILABLE SOURCES
      </h3>

      {annotators.length === 0 && (
        <div className="p-4">There is no available sources!.</div>
      )}
      {annotators.length > 0 && (
        <ul className="px-4 py-4 space-y-2">
          {annotators.map((option) => {
            return (
              <li key={option.value}>
                <div
                  className="flex items-center gap-2"
                  onClick={() => selectAnnotator(option.id)}
                >
                  <VBCheckbox
                    className="flex-none w-5 h-5"
                    activeClassName="text-white"
                    value={!!option.selected}
                  />
                  <span>{option.label}</span>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
