/*
 * File: auth.slice.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 19th October 2021 1:58:29 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInfoService } from "services/user-service";
import {
  CompanySize,
  UserInfoDTO,
} from "services/user-service/dtos/user-info.dto";
import { AuthState, INITIAL_AUTH_STATE, User, UserRole } from "./auth.state";
import * as Sentry from "@sentry/react";

const SLICE_NAME: string = "auth";

export const syncUserInfoFromTokenAsync = createAsyncThunk(
  `${SLICE_NAME}/syncUserInfoFromTokenAsync`,
  async (decodedToken: any) => {
    const { sub, name, family_name, given_name } = decodedToken;
    let userInfo: Partial<UserInfoDTO> = {
      id: sub,
      name: name,
      firstName: given_name,
      lastName: family_name,
      address: "",
      companyName: "",
      companyAddress: "",
      companySize: CompanySize.LARGE,
      language: "",
      phoneNumber: "",
      website: "",
    };
    try {
      const response = await UserInfoService.getUserInfos();
      userInfo = response.data as Partial<UserInfoDTO>;
      if (!userInfo.name) {
        userInfo = {
          id: userInfo.id,
          name: name,
          firstName: given_name,
          lastName: family_name,
          address: "",
          companyName: "",
          companyAddress: "",
          companySize: CompanySize.LARGE,
          language: "",
          phoneNumber: "",
          website: "",
        };
        const res = await UserInfoService.updateUserInfo(userInfo);
        userInfo = res.data;
      }
    } catch (error) {Sentry.captureException(error);}
    return userInfo;
  }
);

export const updateUserInfoAsync = createAsyncThunk(
  `${SLICE_NAME}/updateUserInfoAsync`,
  async (payload: Partial<UserInfoDTO>) => {
    const res = await UserInfoService.updateUserInfo(payload);
    return res.data;
  }
);

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_AUTH_STATE,
  reducers: {
    setUserRole: (
      state: AuthState,
      action: PayloadAction<UserRole | undefined>
    ) => {
      state.userRole = action.payload;
    },
    setCurrentUser: (state: AuthState, action: PayloadAction<User | null>) => {
      state.currentUser = action.payload;
    },
    setUserWorkspace: (state: AuthState, action: PayloadAction<string>) => {
      state.workspace = action.payload;
    },
    resetAuthState: (state: AuthState) => {
      Object.assign(state, INITIAL_AUTH_STATE);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncUserInfoFromTokenAsync.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.userInfo = action.payload;
      })
      .addCase(updateUserInfoAsync.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.userInfo = action.payload;
      });
  },
});

export const { setUserRole, setUserWorkspace, setCurrentUser, resetAuthState } =
  slice.actions;

export const authReducer = slice.reducer;
