/*
 * File: use-conerstone-tools.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */
import {
  CornerstoneTool,
  ToolName,
} from "components/dicom/dicom-tools/dicom-tools.model";
import { useAppDispatch } from "hooks/use-redux";
import {
  toggleFullScreen,
  toggleHeartRuler,
  toggleSynchronizerTool,
} from "store/labeler/image-workspace/cornerstone/cornerstone.slice";
import { smartLabelingEnabled } from "store/labeler/image-workspace/smart-labeling/smart-labeling.slice";
import { setActiveCornerstoneTool } from "store/labeler/image-workspace/dicom-editor/dicom-editor.slice";
import { useBatchLabelingContext } from "pages/labeler/batch-labeling/context/batch-labeling.context";
export const useCornerstoneTools = () => {
  const dispatch = useAppDispatch();
  const { currentViewportEditor: viewportEditor } = useBatchLabelingContext();

  function selectCornerstoneTool(tool: ToolName) {
    dispatch(setActiveCornerstoneTool(tool));
  }

  function toggleSynchronizer() {
    dispatch(toggleSynchronizerTool());
  }

  function selectGridTool() {
    console.log("Select Grid Tool");
  }

  function handleToggleHeartRuler() {
    dispatch(toggleHeartRuler());
  }

  function handleToggleFullScreen() {
    dispatch(toggleFullScreen());
  }

  function handleResetContrast() {
    viewportEditor.current?.resetContrast();
  }

  function handleResetPan() {
    viewportEditor.current?.resetTranslation();
  }

  function handleResetZoom() {
    viewportEditor.current?.resetZoom();
    viewportEditor.current?.resetTranslation();
  }

  function handleResetRotation() {
    viewportEditor.current?.resetRotation();
  }

  function handleResetAll() {
    viewportEditor.current?.resetAll();
  }

  function handleToggleColorInvert() {
    viewportEditor.current?.toggleColorInvert();
  }

  function handleToggleFlipHorizontal() {
    viewportEditor.current?.toggleFlipHorizontal();
  }

  function handleToggleFlipVertical() {
    viewportEditor.current?.toggleFlipVertical();
  }

  const eventHandlers = {
    [ToolName.Grid]: selectGridTool,
    [ToolName.RectangleRoi]: selectCornerstoneTool,
    [ToolName.RectangleRoiExtend]: selectCornerstoneTool,
    [ToolName.FreehandRoi]: selectCornerstoneTool,
    [ToolName.FreehandRoiExtend]: selectCornerstoneTool,
    [ToolName.MultiArrowConnection]: selectCornerstoneTool,
    [ToolName.Mask]: () => {},
    [ToolName.SAM]: selectCornerstoneTool,
    [ToolName.Wwwc]: selectCornerstoneTool,
    [ToolName.Pan]: selectCornerstoneTool,
    [ToolName.StackScrollMouseWheel]: selectCornerstoneTool,
    [ToolName.Synchronizer]: toggleSynchronizer,
    [ToolName.Zoom]: selectCornerstoneTool,
    [ToolName.Rotate]: selectCornerstoneTool,
    [ToolName.Magnify]: selectCornerstoneTool,
    [ToolName.Angle]: selectCornerstoneTool,
    [ToolName.CobbAngle]: selectCornerstoneTool,
    [ToolName.HeartRuler]: handleToggleHeartRuler,
    [ToolName.Ruler]: selectCornerstoneTool,
    [ToolName.Eraser]: selectCornerstoneTool,
    [ToolName.ResetWwwc]: handleResetContrast,
    [ToolName.ResetPan]: handleResetPan,
    [ToolName.ResetZoom]: handleResetZoom,
    [ToolName.ResetRotate]: handleResetRotation,
    [ToolName.ResetAll]: handleResetAll,
    [ToolName.Invert]: handleToggleColorInvert,
    [ToolName.FlipHorizontal]: handleToggleFlipHorizontal,
    [ToolName.FlipVertical]: handleToggleFlipVertical,
    [ToolName.FullScreen]: handleToggleFullScreen,
  };

  function handleSelectCornerstoneTool(tool: CornerstoneTool) {
    dispatch(smartLabelingEnabled(false));
    const handler = eventHandlers[tool.type];
    handler && handler(tool.type);
  }

  return { handleSelectCornerstoneTool };
};
