/*
 * File: notification.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "../..";

export const selectNotifications = (state: RootState) =>
  state.notification.notifications;
