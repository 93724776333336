/*
 * File: image-annotations-list.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 14th October 2022 3:11:57 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import classNames from "classnames";
import { IconArrowDown } from "components/common/vb-icon.component";
import { VBCheckbox } from "components/design-system/checkbox/checkbox.component";
import { useState } from "react";
import { useImageAnnotationSourcesContext } from "./context/image-annotation-sources.context";
import { AnnotationMenuItem } from "./context/image-annotation-sources.state";

export default function ImageAnnotationsList() {
  const { annotationMenuItems, selectAnnotation, selectLabel } =
    useImageAnnotationSourcesContext();

  function handleSelect(id: number, type: string) {
    if (type === "LABEL") {
      selectLabel(id);
    } else {
      selectAnnotation(id);
    }
  }
  return (
    <div className="flex-auto py-4 overflow-y-auto text-sm text-white dark-scrollbar">
      <h3 className="px-4 text-sm font-semibold text-background-500">
        ANNOTATIONS
      </h3>
      <div className="">
        {annotationMenuItems.map((item) => {
          return (
            <ImageAnnotationItem
              item={item}
              key={item.id}
              onSelect={handleSelect}
            />
          );
        })}
      </div>
    </div>
  );
}

interface ImageAnnotationItemProps {
  item: AnnotationMenuItem;
  onSelect(annotationId: number, action?: string): void;
}
export function ImageAnnotationItem({
  item,
  onSelect,
}: ImageAnnotationItemProps) {
  const [expanded, setExpanded] = useState(item.children.length > 0);
  return (
    <div className="relative">
      <div
        className={classNames("flex items-center gap-2 h-10 parent px-4", {
          "bg-blueGray-700": item.children.length > 0 && expanded,
        })}
      >
        {item.children.length > 0 && (
          <button onClick={() => setExpanded(!expanded)}>
            <IconArrowDown
              className={classNames("flex-none w-5 h-5", {
                "transform -rotate-90": !expanded,
              })}
            />
          </button>
        )}
        <VBCheckbox
          onChange={() => onSelect(item.id, item.type)}
          className="flex-none w-5 h-5"
          activeClassName="text-white"
          value={!!item.selected}
        />
        <div
          className="flex-auto overflow-hidden truncate whitespace-nowrap overflow-ellipsis"
          onClick={() => onSelect(item.id, item.type)}
        >
          {item.name}
        </div>
        {/* {!item.hidden && (
          <button
            className="child-on-hover"
            onClick={() => onSelect(item.id, item.type)}
          >
            <IconEye className="flex-none w-4 h-4 text-white" />
          </button>
        )}
        {item.hidden && (
          <button onClick={() => onSelect(item.id, "toggle_visible")}>
            <IconEyeSlash className="flex-none w-4 h-4 text-white" />
          </button>
        )} */}
      </div>

      {expanded && item.children.length > 0 && (
        <div className="pl-7">
          {item.children.map((child) => (
            <ImageAnnotationItem
              key={child.id}
              item={child}
              onSelect={onSelect}
            />
          ))}
        </div>
      )}
    </div>
  );
}
