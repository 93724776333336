/*
 * File: text-review.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 29th October 2021 11:17:49 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Checkbox, Tooltip } from "@material-ui/core";
import classNames from "classnames";
import { UserAvatar } from "components/common/vb-user-avatar.component";
import {
  CORAnnotationData,
  Label,
  NERAnnotationData,
} from "domain/text-labeling";
import { SystemAnnotationData } from "domain/text-labeling/system-annotation";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  selectTextLabelingMode,
  selectTextLabels,
} from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { TextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.state";
import {
  selectTextReviewActiveTab,
  selectTextReviewAnnotations,
  selectTextReviewLabelingDatas,
  selectTextReviewSelectedAnnotationIds,
  selectTextReviewSelectedAnnotations,
  selectTextReviewSelectedAnnotators,
} from "store/labeler/text-workspace/text-review/text-review.selectors";
import {
  setActiveTokenObservationId,
  setReviewMode,
  setTextObservations,
  textReviewActiveTabChanged,
  textReviewAnnotationSelected,
  textReviewAnnotatorSelected,
  textReviewLabelSelected,
} from "store/labeler/text-workspace/text-workspace.slice";
import { textUtils } from "store/labeler/text-workspace/utils/text-labeling.utils";
import useDeepCompareEffect from "use-deep-compare-effect";
import { truncateEmail } from "utilities/string/truncate-email";

export const TextReview = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const textReviewAnnotations = useAppSelector(selectTextReviewAnnotations);
  const labels = useAppSelector(selectTextLabels);
  const labelingDatas = useAppSelector(selectTextReviewLabelingDatas);
  const selectedAnnotators = useAppSelector(selectTextReviewSelectedAnnotators);
  const mode = useAppSelector(selectTextLabelingMode);
  const isCustomerMode = mode === TextLabelingMode.CUSTOMER;
  const selectedAnnotationIds = useAppSelector(
    selectTextReviewSelectedAnnotationIds
  );
  const selectedAnnotations = useAppSelector(
    selectTextReviewSelectedAnnotations
  );

  const activeTab = useAppSelector(selectTextReviewActiveTab);

  const labelAnnotations = useMemo(() => {
    const isShowConflicts = selectedAnnotators.length > 1;
    const isFilterByAgree = isShowConflicts && activeTab === "agree";
    const isFilterByConflict = isShowConflicts && activeTab === "conflict";

    const items = labels.map((label) => {
      const item: {
        label: Label;
        textAnnotationDatas: (
          | CORAnnotationData
          | NERAnnotationData
          | SystemAnnotationData
        )[];
      } = { label, textAnnotationDatas: [] };

      for (let anno of textReviewAnnotations.annotations) {
        if (anno.observationId !== label.observation.id.toString()) continue;
        const isConflict = textUtils.isConflict(
          anno.annotators || [],
          selectedAnnotators
        );
        if (isFilterByAgree && isConflict) continue;
        if (isFilterByConflict && !isConflict) continue;
        item.textAnnotationDatas.push({ ...anno, isConflict });
      }

      for (let anno of textReviewAnnotations.relationAnnotations) {
        if (anno.observationId !== label.observation.id.toString()) continue;
        const isConflict = textUtils.isConflict(
          anno.annotators || [],
          selectedAnnotators
        );
        if (isFilterByAgree && isConflict) continue;
        if (isFilterByConflict && !isConflict) continue;
        item.textAnnotationDatas.push({ ...anno, isConflict });
      }

      for (let anno of textReviewAnnotations.systemAnnotations) {
        if (anno.observationId !== label.observation.id.toString()) continue;
        const isConflict = textUtils.isConflict(
          anno.annotators || [],
          selectedAnnotators
        );
        if (isFilterByAgree && isConflict) continue;
        if (isFilterByConflict && !isConflict) continue;
        item.textAnnotationDatas.push({ ...anno, isConflict });
      }

      return item;
    });
    return items.filter((item) => item.textAnnotationDatas.length > 0);
  }, [activeTab, selectedAnnotators, labels, textReviewAnnotations]);

  const conflictStatistic = useMemo(() => {
    let totalAgree = 0;
    let totalConflict = 0;

    for (let anno of textReviewAnnotations.annotations) {
      const annotators = anno.annotators || [];
      const isConflict = textUtils.isConflict(annotators, selectedAnnotators);
      if (isConflict) totalConflict++;
      else totalAgree++;
    }

    for (let anno of textReviewAnnotations.relationAnnotations) {
      const annotators = anno.annotators || [];
      const isConflict = textUtils.isConflict(annotators, selectedAnnotators);
      if (isConflict) totalConflict++;
      else totalAgree++;
    }

    for (let anno of textReviewAnnotations.systemAnnotations) {
      const annotators = anno.annotators || [];
      const isConflict = textUtils.isConflict(annotators, selectedAnnotators);
      if (isConflict) totalConflict++;
      else totalAgree++;
    }

    return { totalAgree, totalConflict };
  }, [selectedAnnotators, textReviewAnnotations]);

  const tabs = useMemo(() => {
    const { totalAgree, totalConflict } = conflictStatistic;
    return [
      { id: "all", name: "All" },
      {
        id: "agree",
        name: `Agree(${totalAgree})`,
      },
      {
        id: "conflict",
        name: `Conflict(${totalConflict})`,
      },
    ];
  }, [conflictStatistic]);

  const isEmpty = useMemo(() => {
    return (
      selectedAnnotators.length > 0 &&
      textReviewAnnotations.annotations.length === 0 &&
      textReviewAnnotations.relationAnnotations.length === 0 &&
      textReviewAnnotations.systemAnnotations.length === 0
    );
  }, [selectedAnnotators, textReviewAnnotations]);

  useDeepCompareEffect(() => {
    dispatch(setTextObservations(selectedAnnotations));
  }, [selectedAnnotations, dispatch]);

  function handleClose() {
    dispatch(setReviewMode(false));
  }

  function handleSelectAnnotator(annotator: string) {
    dispatch(textReviewAnnotatorSelected(annotator));
  }

  function handleSelectAnnotation(annotationId: string) {
    dispatch(textReviewAnnotationSelected(annotationId));
  }

  function handleClickAnnotation(annotationId: string) {
    const element = document.getElementById(annotationId);
    if (element) element.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => dispatch(setActiveTokenObservationId(annotationId)), 600);
  }

  function handleSelectLabel(labelId: string) {
    dispatch(textReviewLabelSelected(labelId));
  }

  function handleSelectTab(tabId: string) {
    dispatch(textReviewActiveTabChanged(tabId));
  }
  const isShowConflicts = selectedAnnotators.length > 1;

  return (
    <div className="flex flex-col w-full h-full">
      {!isCustomerMode && (
        <>
          <div className="flex items-center px-4 py-2">
            <button className="py-2 focus:outline-none" onClick={handleClose}>
              <i className="uir-arrow-left"></i>
            </button>
            <span className="mx-4 font-bold">
              {t("textLabeling:textReview.previousFinding")}
            </span>
          </div>

          <hr />
        </>
      )}

      <div className="flex-grow py-2 overflow-y-auto">
        {!isCustomerMode && (
          <>
            <div className="p-4">
              <div className="text-heading5">
                {t("textLabeling:textReview.labelers")}
              </div>
              <div className="flex items-center gap-2 py-2">
                {labelingDatas.map((data) => {
                  const isActive = selectedAnnotators.includes(data.annotator);
                  return (
                    <div
                      onClick={() => handleSelectAnnotator(data.annotator)}
                      key={data.annotator}
                      className={classNames({
                        "cursor-pointer border-2 border-white rounded-full":
                          !isActive,
                        "cursor-pointer border-2 border-primary rounded-full":
                          isActive,
                      })}
                    >
                      <Tooltip title={data.annotator}>
                        <div className="border border-white rounded-full">
                          <UserAvatar
                            name={data.annotator}
                            className="flex-none text-xs select-none w-7 h-7"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
            </div>
            <hr />
          </>
        )}

        <div className="py-4">
          <div className="px-4 text-heading5">
            {t("textLabeling:textReview.annotations")}
          </div>
          {isEmpty && (
            <div className="p-4 text-center">There is no annotations yet!</div>
          )}

          {selectedAnnotators.length === 0 && (
            <div className="px-6 py-4 text-center text-red-500">
              {t("textLabeling:textReview.selectLabelerMessage")}
            </div>
          )}

          {isShowConflicts && (
            <div className="flex flex-wrap items-center gap-4 px-4 pt-2 text-sm">
              {tabs.map((tab) => {
                return (
                  <button
                    key={tab.id}
                    onClick={() => handleSelectTab(tab.id)}
                    className={classNames({
                      "text-primary": tab.id === activeTab,
                    })}
                  >
                    {tab.name}
                  </button>
                );
              })}
            </div>
          )}

          {labelAnnotations.map((data) => {
            const isSelected = !data.textAnnotationDatas.find(
              (anno) => !selectedAnnotationIds.includes(anno.id)
            );
            return (
              <div className="py-4 border-b border-dashed" key={data.label.id}>
                <div className="flex items-center gap-1 px-1">
                  <Checkbox
                    checked={isSelected}
                    color="primary"
                    onClick={() => handleSelectLabel(data.label.id)}
                  />
                  <span className="text-base">{data.label.name}</span>
                </div>

                <div className="py-1">
                  {data.textAnnotationDatas.map((anno) => {
                    const selected = selectedAnnotationIds.includes(anno.id);
                    return (
                      <div
                        key={anno.id}
                        className="flex items-center w-full gap-1 px-6 py-1"
                      >
                        <Checkbox
                          checked={selected}
                          size="small"
                          color="primary"
                          onClick={() => handleSelectAnnotation(anno.id)}
                        />
                        <div onClick={() => handleClickAnnotation(anno.id)}>
                          <div
                            className={classNames("text-sm select-none", {
                              "text-red-500": anno.isConflict,
                            })}
                          >
                            {anno.content || anno.id}
                          </div>
                          {isShowConflicts && (
                            <div className="space-x-1 text-xs text-background-500">
                              <span>
                                {anno.annotators
                                  ?.map(truncateEmail)
                                  .join(", ") || ""}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="p-4"></div>
    </div>
  );
};
