import { FabricObjectToolType } from "./fabric/fabric.models"
import { PathologyAnnotation } from "./pathology-editor.models"

export const fabricObjectToAnnotation = (object: fabric.Object): PathologyAnnotation => {
  return {
    id: object.data.uuid,
    type: object.type as FabricObjectToolType,
    fabricObjectRef: object,
  }
}
