/*
 * File: id-generator.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 16th May 2022 1:52:52 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

let uniqueId = 1;
export function newUniqueId() {
  return uniqueId++;
}
