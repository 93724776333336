import { useAutoSave } from "hooks/use-auto-save.hook";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { selectIsAutoSaveConfig } from "store/labeler/complex-jobs/complex-jobs-editor-setting/complex-jobs-editor-setting.selector";
import {
  selectComplexJobIdsByStatus,
  selectComplexJobsAutoSaveInSecond,
} from "store/labeler/complex-jobs/complex-jobs.selectors";
import {
  deleteComplexJobsJobInBatch,
  setComplexJobsJobData,
  setComplexJobWorkingStatus,
} from "store/labeler/complex-jobs/complex-jobs.slice";
import { WorkingStatus } from "store/labeler/complex-jobs/complex-jobs.state";
import { classnames } from "utilities/classes";
import { ErrorDialog } from "../batch-labeling/components/error-dialog.component";
import { ImageLabelingErrorDialog } from "../image-labeling/components/error-dialog/error-dialog.component";
import { WorkspaceEmpty } from "../image-labeling/components/workspace-empty/workspace-empty.component";
import { APP_EVENT_CLICK_OTHER_JOB } from "../speech-to-text-labeling/components/job-in-batch.component";
import { FabricOverlay } from "./components/fabric/fabric-overlay";
import { PathologyEditorProvider } from "./components/pathology-editor.provider";
import { usePathologyLabelingContext } from "./context/pathology-labeling.context";
import { PathologyJobUIModel } from "./context/pathology-labeling.state";
import { PathologyHeaderBar } from "./pathology-header-bar";
import { PathologyJobsNav } from "./pathology-jobs-nav.component";

interface Props {
  showMenuIssue?: boolean;
  fixedMenuIssue?: boolean;
  showIssues?: boolean;
  editorHeight?: string;
  showJobNav?: boolean;
  jobNavWidth?: string;
}
export const PathologyLabelingDetails = ({
  showMenuIssue = true,
  fixedMenuIssue = false,
  showIssues = false,
  editorHeight,
  showJobNav = false,
  jobNavWidth = "160px",
}: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [jobNavVisibility, setJobNavVisibility] = useState(false);

  const isAutoSaveConfig = useAppSelector(selectIsAutoSaveConfig);
  const workingJobIds = useAppSelector(
    selectComplexJobIdsByStatus(WorkingStatus.WORKING)
  );
  const autoSaveInSecond = useAppSelector(selectComplexJobsAutoSaveInSecond);

  const {
    uiJobRef,
    error,
    setError,
    isLoadingJob,
    isSavingJob,
    saveJob,
    skipJob,
    handleConfirmExit,
    hasChanged,
    setHasChanged,
    setIsEditorReady,
  } = usePathologyLabelingContext();
  // Use for trigger rendering
  const [dummyTrigger, setDummyTrigger] = useState(false);

  const autoSave = useAutoSave({
    enabled: isAutoSaveConfig && workingJobIds.length > 0,
    onSave: async () => {
      const promises = workingJobIds.map((id) =>
        saveJob(true, false, false, false, id)
      );
      await Promise.all(promises);
    },
    duration: autoSaveInSecond && autoSaveInSecond * 1000,
  });

  useEffect(() => {
    const uiJob = uiJobRef.current;
    if (uiJob && uiJob.job && uiJob.isFromBatch) {
      dispatch(
        setComplexJobsJobData({
          id: uiJob.job.id,
          data: uiJob,
        })
      );
    }
  }, [uiJobRef, uiJobRef.current?.job, dispatch]);

  const handleErrorDialogClose = () => {
    history.push(Routes.LABELER_HOME);
  };

  const handleSkip = async () => {
    if (!error || !error.data) return;
    await skipJob(error.data as number);
    setError(undefined);
    const uiJob = uiJobRef.current;
    if (uiJob && uiJob.job && uiJob.isFromBatch) {
      dispatch(deleteComplexJobsJobInBatch(uiJob.job.id));
    }
  };

  useEffect(() => {
    // Save current objects for the current job
    // before load the new one
    const handleNewJobClick = () => {
      const uiJob = uiJobRef.current;
      if (!uiJob || !uiJob.job || !uiJob.fabricOverlay) return;

      uiJob.fabricOverlay.transformObjectsCoordinatesToImage();

      const newValue: PathologyJobUIModel = {
        ...uiJob,
        initFabricObjects: uiJob.fabricOverlay.fabricCanvas.getObjects(),
      };
      dispatch(
        setComplexJobsJobData({
          id: uiJob.job.id,
          data: newValue,
        })
      );
    };

    document.addEventListener(APP_EVENT_CLICK_OTHER_JOB, handleNewJobClick);

    return () => {
      document.removeEventListener(
        APP_EVENT_CLICK_OTHER_JOB,
        handleNewJobClick
      );
    };
  }, [uiJobRef, dispatch]);

  if (error) {
    const errorType = error.type;
    if (errorType === "no_job") return <WorkspaceEmpty />;
    if (errorType === "unknow")
      return (
        <ErrorDialog
          visible
          onClose={handleErrorDialogClose}
          message={error.message}
        />
      );
    if (errorType === "skippable")
      return (
        <ImageLabelingErrorDialog
          visible
          error={error.message}
          onClose={handleErrorDialogClose}
          onSubmit={handleSkip}
        />
      );
  }

  const handleSave = async () => {
    await saveJob(true);
  };

  const handleComplete = async (accept = false, reject = false) => {
    await saveJob(true, true, accept, reject);
  };

  const handleClickBack = () => {
    handleConfirmExit();
  };

  const toggleNavigation = async () => {
    setJobNavVisibility(!jobNavVisibility);
  };

  const uiJob = uiJobRef.current;

  const handleEditorLoaded = (overlay: FabricOverlay) => {
    setIsEditorReady(true);
    if (uiJobRef.current) {
      uiJobRef.current = {
        ...uiJobRef.current,
        fabricOverlay: overlay,
      };
    }
    overlay.fabricCanvas.requestRenderAll();
  };

  const handleAnnotationsChanged = (objects: fabric.Object[]) => {
    setHasChanged(true);
    dispatch(setComplexJobWorkingStatus(WorkingStatus.WORKING));
  };

  const handleClickFromUrlGo = (url: string, useAjaxHeaders: boolean) => {
    if (!uiJobRef.current) return;
    uiJobRef.current = {
      ...uiJobRef.current,
      wsiUrl: url,
      useAjaxHeaders,
    };
    setDummyTrigger(!dummyTrigger);
  };

  const handleClickDeleteFromUrl = () => {
    if (!uiJobRef.current) return;
    uiJobRef.current = {
      ...uiJobRef.current,
      wsiUrl: uiJobRef.current.originalWsiUrl,
      useAjaxHeaders: true,
    };
    setDummyTrigger(!dummyTrigger);
  };

  return (
    <div className="flex flex-col w-full h-full">
      {uiJob && uiJob.batch && uiJob.task && (
        <PathologyHeaderBar
          autoSave={autoSave}
          isReviewStepJob={uiJob.isStepReviewJob}
          batch={uiJob.batch}
          task={uiJob.task}
          project={uiJob.project}
          disabledComplete={isLoadingJob || isSavingJob}
          disabledSave={isLoadingJob || isSavingJob || !hasChanged}
          countDownSecond={uiJob.countDownSecond}
          isReady={!isLoadingJob}
          onClickBack={handleClickBack}
          onClickSave={handleSave}
          onClickComplete={handleComplete}
          showButtons={!uiJob.isTaskReview}
          showInfo={false}
          jobNavVisibility={jobNavVisibility}
          showJobNav={showJobNav}
          onClickToggleJobNav={toggleNavigation}
          onClickFromUrlGo={handleClickFromUrlGo}
          onClickDeleteFromUrl={handleClickDeleteFromUrl}
        />
      )}
      <div className="flex w-full flex-auto">
        <div
          className={classnames("transition-all h-full")}
          style={{
            width: jobNavVisibility ? jobNavWidth : "0px",
          }}
        >
          <PathologyJobsNav />
        </div>
        <div className="transition-all flex-auto">
          {uiJob && uiJob.wsiUrl && (
            <PathologyEditorProvider
              wsiUrl={uiJob.wsiUrl}
              useAjaxHeaders={uiJob.useAjaxHeaders}
              inputLabels={uiJob.labels}
              onLoaded={handleEditorLoaded}
              initFabricObjects={uiJob.initFabricObjects}
              initEdges={uiJob.initEdges}
              onAnnotationsChanged={handleAnnotationsChanged}
              inputCurrentLabeler={uiJob.editorCurrentLabeler}
              inputOtherLabelers={uiJob.editorOtherLabelers}
            />
          )}
        </div>
      </div>
    </div>
  );
};
