/*
 * File: dicom-editor.mock.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ToolName } from "components/dicom/dicom-tools/dicom-tools.model";
import { v4 } from "uuid";

export const mockLabels = [
  {
    annotationTools: [ToolName.FreehandRoi],
    uuid: v4(),
    name: "Pulmonary Scarring",
    color: "#FF00FF",
    visible: true,
  },
  {
    annotationTools: [ToolName.FreehandRoi],
    uuid: v4(),
    name: "Atelectasis",
    color: "#ff7b00",
    visible: true,
  },
  {
    annotationTools: [ToolName.FreehandRoi],
    uuid: v4(),
    name: "Consolidation",
    color: "#01c608",
    visible: true,
  },
  {
    annotationTools: [ToolName.FreehandRoi],
    uuid: v4(),
    name: "Cardiomegaly",
    color: "#FF0000",
    visible: true,
  },
];

export const mockBaseTools = [
  {
    type: ToolName.Grid,
    name: "Grid",
  },
  {
    type: ToolName.Wwwc,
    name: "Wwwc",
  },
  {
    type: ToolName.Pan,
    name: "Pan",
  },
  {
    type: ToolName.Zoom,
    name: "Zoom",
  },
  {
    type: ToolName.StackScrollMouseWheel,
    name: "StackScrollMouseWheel",
  },
  {
    type: ToolName.Synchronizer,
    name: "Synchronizer",
  },
  {
    type: ToolName.Rotate,
    name: "Rotate",
  },
  {
    type: ToolName.Magnify,
    name: "Magnify",
  },
  {
    type: ToolName.Angle,
    name: "Angle",
  },
  {
    type: ToolName.CobbAngle,
    name: "CobbAngle",
  },
  {
    type: ToolName.HeartRuler,
    name: "HeartRuler",
  },
  {
    type: ToolName.Eraser,
    name: "Eraser",
  },
];
export const mockResetTools = [
  {
    type: ToolName.ResetWwwc,
    name: "Reset Wwwc",
  },
  {
    type: ToolName.ResetPan,
    name: "Reset Pan",
  },
  {
    type: ToolName.ResetZoom,
    name: "Reset Zoom",
  },
  {
    type: ToolName.ResetRotate,
    name: "Reset Rotate",
  },
  {
    type: ToolName.ResetAll,
    name: "Reset All",
  },
];

export const mockOtherTools = [
  {
    type: ToolName.Invert,
    name: "Color Invert",
  },
  {
    type: ToolName.FlipHorizontal,
    name: "Flip Horizontal",
  },
  {
    name: "Flip Vertical",
    type: ToolName.FlipVertical,
  },
  {
    type: ToolName.FullScreen,
    name: "Full Screen",
  },
];
export const mockAnnotateTools = [
  {
    type: ToolName.SAM,
    name: "SmartLabeling",
  },
  {
    type: ToolName.FreehandRoiExtend,
    name: "FreehandRoiExtend",
  },
  {
    type: ToolName.RectangleRoiExtend,
    name: "RectangleRoiExtend",
  },
  {
    type: ToolName.MultiArrowConnection,
    name: "MultiArrowConnection",
  },
];
