import { useWorkspaceUsers } from "hooks/workspace/use-workspace-users.hook";
import { useMemo } from "react";
import { UserDTO } from "services/user-service/dtos/user.dto";
import { StepData } from "../project.type";

export const useStepAssigness = (selectedStep?: StepData) => {
  const { labelers } = useWorkspaceUsers();

  const allEmails = useMemo(
    () => labelers.map((label: UserDTO) => label.email),
    [labelers]
  );
  const selectedEmails = useMemo(() => {
    if (
      !selectedStep ||
      !selectedStep.assignees ||
      !selectedStep.assignees.length
    )
      return [];
    return selectedStep.assignees?.map((label) => label.userId);
  }, [selectedStep]);

  /* TODO: unique labelers to assign
  const assignedLabelEmails = useMemo(() => {
    if (!currentStep) return [];
    const remainSteps = steps.filter(
      (step) => step.position !== currentStep.position
    );
    const remainSelectedEmails = remainSteps.reduce(
      (res: string[], step: StepData) => {
        return !step.assignees ? res : [...res, ...step.assignees];
      },
      []
    );

    return labelers
      .filter((label) => !remainSelectedEmails.includes(label.email))
      .map((label) => label.email);
  }, [currentStep, labelers, steps]);
  */

  return {
    allEmails,
    selectedEmails,
  };
};
