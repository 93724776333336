/*
 * File: observation.dto.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 4th August 2021 10:39:57 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export interface IllustrationItem {
  active: string;
  url: string;
  note: string;
  resourceId: string;
}
export interface ObservationSetting {
  annotationType: string;
  numberOfPoint: number;
  caveats: string;
  color: string;
  systemAttribute: boolean;
  illustrations: IllustrationItem[];
  maskId?: number;
  selectable?: boolean;
}

export interface ObservationAttribute {
  id: number;
  type: string;
  name: string;
  defaultValue: string;
  values: string[];
  ranges: number[];
  required: boolean;
}

export interface ObservationDTO {
  id: number;
  version?: number;
  lastModifiedDate: string;
  projectId: number;
  active: boolean;
  name: string;
  code: string;
  description: string;
  observationGroup: string;
  parent: ObservationDTO | null;
  priority: number;

  observationSetting: ObservationSetting;
  attributes: ObservationAttribute[];

  hide?: boolean; // client side only
  selected?: boolean; // client side only
  probability?: number; // client side only
}

export const findObservationParents = (
  obs: ObservationDTO
): ObservationDTO[] => {
  const parents: ObservationDTO[] = [];
  let currentParent = obs.parent;
  while (currentParent) {
    parents.push(currentParent);
    currentParent = currentParent.parent;
  }
  return parents;
};

export const hasChildren = (
  obs: ObservationDTO,
  list: ObservationDTO[]
): boolean => {
  for (let o of list) {
    if (o.parent && o.parent.id === obs.id) return true;
  }
  return false;
};

export enum SystemObservationCode {
  ACCEPT = "ACP",
  REJECT = "REJ",
  INVALID = "IVD",
  NOT_FOUND = "NFD",
}

export const STEP_REVIEW_OBSERVATION_CODES = [
  SystemObservationCode.ACCEPT.toString(),
  SystemObservationCode.REJECT.toString(),
];

export enum UpdateObservationStrategy {
  CREATE = "CREATE",
  OVERRIDE = "OVERRIDE",
  OVERRIDE_AND_REOPEN = "OVERRIDE_AND_REOPEN",
}

export enum ObservationAnnotationType {
  BBOX = "BBOX",
  POLYGON = "POLYGON",
  CLASSIFICATION = "CLASSIFICATION",
  POINT = "POINT",
  CUBOID = "CUBOID",
  ELLIPSE = "ELLIPSE",
  POLYLINE = "POLYLINE",
  ENTITY = "ENTITY",
  RELATION = "RELATION",
  MASK = "MASK",
  METADATA = "METADATA",
  GENERAL = "GENERAL",
  LINE = "LINE",
}

export enum ObservationAttributeType {
  RADIO = "RADIO",
  SELECT = "SELECT",
  TEXT = "TEXT",
  NUMBER = "NUMBER",
}

export const annotationTypeMapName: Record<string, string> = {
  [ObservationAnnotationType.BBOX]: "Bounding box",
  [ObservationAnnotationType.POLYGON]: "Polygon",
  [ObservationAnnotationType.CLASSIFICATION]: "Classification",
  [ObservationAnnotationType.POINT]: "Point",
  [ObservationAnnotationType.CUBOID]: "Cuboid",
  [ObservationAnnotationType.ELLIPSE]: "Ellipse",
  [ObservationAnnotationType.POLYLINE]: "Polyline",
  [ObservationAnnotationType.ENTITY]: "Entity",
  [ObservationAnnotationType.RELATION]: "Relation",
  [ObservationAnnotationType.MASK]: "Mask",
  [ObservationAnnotationType.METADATA]: "Metadata",
  [ObservationAnnotationType.GENERAL]: "General",
};
