import { FilterCommon } from "domain/common/filter";
import moment from "moment";
import { RequestOptions } from "services/common/request-options";

// by job
export class LabelerReportByJobFilter extends FilterCommon {
  jobId?: number;
  taskId?: number;
  fileName?: string;
  startDate?: Date;
  endDate?: Date;
  jobStatus?: string;
  payment?: number;

  static toRequestOption(filter: LabelerReportByJobFilter): RequestOptions {
    const options = FilterCommon.toRequestOption(filter);

    if (filter.jobId) options["jobId.equals"] = filter.jobId.toString();
    if (filter.taskId) options["taskId.equals"] = filter.taskId.toString();
    if (filter.fileName) options["fileName.contains"] = filter.fileName;
    if (filter.startDate)
      options["startDate"] = moment(filter.startDate)
        .utc()
        .toDate()
        .toISOString();
    if (filter.endDate)
      options["endDate"] = moment(filter.endDate).utc().toDate().toISOString();
    if (filter.jobStatus) options["statusList"] = filter.jobStatus;
    if (filter.payment) options["payment.equals"] = filter.payment.toString();

    return options;
  }
}

export interface LabelerReportByJobRow {
  jobId: number;
  taskId: number;
  fileName: string;
  jobStatus: string;
  payment: number;
  currencyCode: string;
}

// by project
export interface LabelerReportByProjectRow {
  id: string;
  batchId: number;
  projectType: string;
  projectName: string;
  batchName: string;
  startDate: Date;
  endDate: Date;
  completedTaskCount: number;
  completedAnnotationCount: number;
  finished: number;
  noResult: number;
  f1Score: number;
  specificity: number;
  sensitivity: number;
  iou: number;
}

// by date
export interface LabelerReportByDateRow {
  id: string;
  date: Date | string;
  total: number;
  completed: number;
  skipped: number;
  startTime: string;
  endTime: string;

  totalCase: number;
  group: string;
}
