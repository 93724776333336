/*
 * File: workspace.state.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { MutableRefObject } from "react";
import { CornerstoneViewportEditor } from "components/dicom/cornerstone/viewport-editor/viewport-editor";
import { CornerstoneHandler } from "components/dicom/cornerstone/cornerstone-handler/cornerstone-handler";

export interface ImageEditorState {
  requesting: boolean;
  imageLoaded: boolean;
  viewportEditor: MutableRefObject<CornerstoneViewportEditor | undefined>;
  cornerstoneHandler: MutableRefObject<CornerstoneHandler | undefined>;
  setImageLoaded(loaded: boolean): void;
  setRequesting(requesting: boolean): void;
}

export const defaultImageEditorState = {} as ImageEditorState;
