/*
 * File: project-info-tab.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 25th July 2022 2:46:29 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { FormEvent, useMemo, useRef } from "react";
import { useAppDispatch } from "hooks/use-redux";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { useCreateProjectTemplateContext } from "../context/create-project-template.context";
import * as Sentry from "@sentry/react";
import DOMPurify from "dompurify";

const MAX_THUMBNAIL_SIZE = 1024 * 1024 * 5;
export default function ProjectInfoTab() {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const { infoData, onProjectInfoChanged } = useCreateProjectTemplateContext();
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const instructionRef = useRef<HTMLTextAreaElement>(null);

  const imageUrl = useMemo(() => {
    if (infoData?.file) {
      return URL.createObjectURL(infoData.file);
    }
    return infoData?.thumbnailUrl;
  }, [infoData]);

  function resetInputFile() {
    if (inputRef.current) inputRef.current.value = "";
  }

  function validateFileSize(file: File) {
    if (file?.size > MAX_THUMBNAIL_SIZE) {
      const errMessage = "File is too large";
      throw new Error(errMessage);
    }
  }
  function handleFileChange(event: any) {
    if (!infoData) return;
    try {
      const selectedFiles = event.target.files;
      if (!selectedFiles || selectedFiles.length === 0) return;
      const file = selectedFiles.item(0);
      validateFileSize(file);
      onProjectInfoChanged({ ...infoData, file });
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || "File is too large";
      dispatch(enqueueErrorNotification(errMessage));
      resetInputFile();
    }
  }

  function handleNameChange(event: FormEvent<HTMLInputElement>) {
    if (!infoData) return;
    const value = DOMPurify.sanitize(event.currentTarget.value.trim());
    onProjectInfoChanged({ ...infoData, name: value });
  }

  function handleDescriptionChange(event: FormEvent<HTMLTextAreaElement>) {
    if (!infoData) return;
    const value = DOMPurify.sanitize(event.currentTarget.value.trim());
    onProjectInfoChanged({ ...infoData, description: value });
  }

  function handleInstructionChange(event: FormEvent<HTMLTextAreaElement>) {
    if (!infoData) return;
    const value = DOMPurify.sanitize(event.currentTarget.value.trim());
    onProjectInfoChanged({ ...infoData, instruction: value });
  }

  return (
    <div className="h-full p-6 bg-white rounded shadow">
      <div className="grid grid-cols-1 gap-20 md:grid-cols-5">
        <div className="space-y-6 md:col-span-3">
          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="name" className="py-2 font-semibold">
                Name
              </label>
              <span className="text-sm">{infoData?.name?.length || 0}/500</span>
            </div>
            <div className="flex items-center gap-4">
              <input
                id="name"
                name="name"
                ref={nameRef}
                onChange={handleNameChange}
                maxLength={500}
                className="w-full px-4 py-2 border rounded border-background-300 focus:text-background-800 focus:ring-1 focus:ring-primary"
                defaultValue={infoData?.name}
              />
            </div>
          </div>

          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <label htmlFor="description" className="py-2 font-semibold">
                Description
              </label>
              <span className="text-sm">
                {infoData?.description?.length || 0}/500
              </span>
            </div>
            <textarea
              ref={descriptionRef}
              maxLength={500}
              onChange={handleDescriptionChange}
              id="description"
              placeholder="Description"
              defaultValue={infoData?.description}
              style={{ minHeight: "8rem" }}
              className="w-full px-4 py-2 text-sm border rounded border-background-300 focus:text-background-800 focus:ring-1 focus:ring-primary"
            />
          </div>
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <label htmlFor="instruction" className="py-2 font-semibold">
                Instruction
              </label>
              <span className="text-sm">
                {infoData?.instruction?.length || 0}/500
              </span>
            </div>
            <textarea
              ref={instructionRef}
              maxLength={500}
              onChange={handleInstructionChange}
              id="instruction"
              placeholder="Instruction"
              defaultValue={infoData?.instruction}
              style={{ minHeight: "8rem" }}
              className="w-full px-4 py-2 text-sm border rounded border-background-300 focus:text-background-800 focus:ring-1 focus:ring-primary"
            />
          </div>
        </div>

        <div className="px-4 md:col-span-2">
          <div className="relative overflow-hidden border border-dashed rounded aspect-w-4 aspect-h-3 border-background-300">
            <img
              src={imageUrl}
              alt={infoData?.name}
              className="object-cover w-full h-full"
            />
            <input
              ref={inputRef}
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleFileChange}
              className="absolute top-0 left-0 w-full h-full opacity-0"
            />
          </div>
          <div className="py-4 space-y-1">
            <label className="font-semibold" htmlFor="file_input">
              Upload file
            </label>
            <input
              className="block w-full border rounded bg-background-100 border-background-500"
              aria-describedby="file_input_help"
              id="file_input"
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleFileChange}
              multiple={false}
            />
            <p
              className="mt-1 text-sm text-gray-500 dark:text-gray-300"
              id="file_input_help"
            >
              PNG, JPG or JPEG (MAX. 5Mb).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
