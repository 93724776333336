/*
 * File: project-statistic.slice.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 11:52:57 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { addEntity, removeEntity, setEntites } from "store/base/base.reducer";
import {
  ProjectStatisticDTO,
  ProjectStatisticState,
} from "./project-statistic.state";

interface State {
  statistic: ProjectStatisticState;
}

export const projectStatisticReducers = {
  setBatchStatistics(state: State, action: PayloadAction<ProjectStatisticDTO[]>) {
    setEntites(state.statistic, action.payload);
  },

  addStatistic(state: State, action: PayloadAction<ProjectStatisticDTO>) {
    addEntity(state.statistic, action.payload);
  },

  removeStatistic(state: State, action: PayloadAction<number>) {
    removeEntity(state.statistic, action.payload);
  },
};
