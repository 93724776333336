/*
 * File: drawTextValue.js
 * Project: app-aiscaler-web
 * File Created: Monday, 7th February 2022 5:26:34 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import * as csTools from "cornerstone-tools";
const external = csTools.external;
import drawTextBox from "./drawTextBox.js";

function measurementBoundingBox(measurement) {
  if (!measurement.handles) return null;
  if (
    measurement.handles.hasOwnProperty("start") &&
    measurement.handles.hasOwnProperty("end")
  ) {
    const { start, end } = measurement.handles;
    const x = Math.min(start.x, end.x);
    const y = Math.min(start.y, end.y);
    const width = Math.abs(end.x - start.x);
    const height = Math.abs(end.y - start.y);
    return { x, y, width, height };
  }

  if (
    measurement.handles.hasOwnProperty("points") &&
    measurement.handles.points.length > 0
  ) {
    const points = measurement.handles.points.map((point) => {
      return { x: point.x, y: point.y };
    });
    const minX = Math.min(...points.map(({ x }) => x));
    const maxX = Math.max(...points.map(({ x }) => x));
    const minY = Math.min(...points.map(({ y }) => y));
    const maxY = Math.max(...points.map(({ y }) => y));
    return { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
  }
  return null;
}

export default function (context, element, data) {
  if (!data || !data.text || !(data.hovering || data.displayTextValue)) return;
  const boundingbox = measurementBoundingBox(data);
  if (!boundingbox) return;
  const cornerstone = external.cornerstone;
  const textCoords = cornerstone.pixelToCanvas(element, {
    x: boundingbox.x,
    y: boundingbox.y + boundingbox.height,
  });

  drawTextBox(
    context,
    data.text,
    textCoords.x,
    textCoords.y + 4,
    "#FF00FF",
    {},
  );
}
