import { Slider } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import * as Sentry from "@sentry/react";

interface SliderWithInputProps {
  name: string;
  defaultValue: number;
  minValue?: number;
  maxValue?: number;
  stepSize?: number;
  numLength?: number;
  onChange?(value: number): void;
}

export const SliderWithInput = ({
  name,
  defaultValue,
  minValue = 0,
  maxValue = 1,
  stepSize = 0.01,
  numLength = 4,
  onChange,
}: SliderWithInputProps) => {
  const [value, setValue] = useState(defaultValue);
  const [valueStr, setValueStr] = useState(defaultValue.toString());
  const handleChange = (_: any, newValue: number | number[]) => {
    setValue(newValue as number);
    setValueStr(newValue.toString());
  };
  const handleChangeCommitted = (_: any, newValue: number | number[]) => {
    onChange && onChange(newValue as number);
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = minValue;
    let newValueStr = e.target.value;
    let isNormalStr = false;

    try {
      if (newValueStr.length > numLength) {
        newValueStr = newValueStr.substring(0, numLength);
      }
      newValue = parseFloat(newValueStr);

      if (isNaN(newValue)) {
        newValue = minValue;
      } else if (newValue < minValue) {
        newValue = minValue;
      } else if (newValue > maxValue) {
        newValue = maxValue;
      } else {
        isNormalStr = true;
      }
      newValue = parseFloat(newValue.toFixed(2));
    } catch (error: any) {
      Sentry.captureException(error);
      newValue = defaultValue;
    }
    setValue(newValue);
    if (isNormalStr) {
      setValueStr(newValueStr);
    } else {
      setValueStr(newValue.toString());
    }
  };
  const handleInputFocusOut = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(value);
  };

  return (
    <div className="flex items-center w-full gap-4">
      {name && <div className="flex-none text-sm">{name}</div>}
      <Slider
        className="flex-auto"
        key={`slider-${name}`}
        min={minValue}
        max={maxValue}
        step={stepSize}
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
      />
      <input
        className="flex-none w-12 px-2 py-1"
        type="number"
        min={minValue}
        max={maxValue}
        step={stepSize}
        value={valueStr}
        onChange={handleInputChange}
        onBlur={handleInputFocusOut}
      />
    </div>
  );
};
