import {
  BatchObservationDTO,
  ObservationAnnotationType,
  ObservationAttribute,
  ObservationDTO,
} from "services/label-service/dtos";
import {
  AnnotationAttributeItem,
  AnnotationResponseDTO,
  SliceAnnotationItem,
  SliceAnnotationItemMask,
} from "services/label-service/dtos/annotations.dto";
import { StorageService } from "services/storage";
import { StorageResource } from "services/storage/dto/resource.dto";
import {
  EditorLabel,
  EditorLabelOption,
  EditorLabelStatus,
  EditorMetadataRowAttributeModel,
  EditorMetadataRowModel,
  EDITOR_LABEL_NONE,
  LabelMapOption,
} from "../components/three-d-editor.models";
import { blobToVTKImage } from "../utils";
import * as Sentry from "@sentry/react";
import { IoUModel } from "./three-d-labeling.state";
import { LoadCombplexJobResponse } from "store/labeler/complex-jobs/complex-jobs.thunk";

export const batchObservationsToEditorLabelOptions = (
  batchObservations: BatchObservationDTO[]
): EditorLabelOption[] => {
  return batchObservations
    .filter(
      (bo) =>
        bo.observation.observationSetting.annotationType ===
        ObservationAnnotationType.MASK
    )
    .map((bo) => ({
      id: bo.observation.id,
      name: bo.observation.name,
      color: bo.observation.observationSetting.color,
      maskId: bo.observation.observationSetting.maskId,
      attributes:
        bo.observation.attributes?.map((attr) => {
          return {
            type: attr.type,
            id: attr.id,
            name: attr.name,
            defaultValue: attr.defaultValue,
            options: attr.values,
            ranges: attr.ranges,
            required: attr.required,
          };
        }) || [],
    }));
};

export const sliceAnnoItemToLabelMapAndMask = async (
  anno: SliceAnnotationItem | undefined,
  blobOnly = false,
  workspaceId?: string
): Promise<any> => {
  let labelMap = undefined;
  let resource = undefined;
  let masks: SliceAnnotationItemMask[] = [];

  if (anno && anno.resourceId) {
    let res = await StorageService.getStorageResource(anno.resourceId);
    resource = res.data as StorageResource;
    const urlToUse = resource.path ? resource.path : resource.downloadUrl;
    res = await StorageService.getPublicFileContentAsBlob(
      urlToUse,
      workspaceId
    );
    const blob = res.data;
    if (blobOnly) {
      labelMap = blob;
    } else {
      labelMap = await blobToVTKImage(blob);
    }
  }

  if (anno?.mask) {
    masks = anno.mask;
  }

  return { anno, labelMap, resource, masks };
};

export const sliceAnnoItemMaskToEditorLabel = (
  itemMask: SliceAnnotationItemMask,
  observations: ObservationDTO[]
): EditorLabel | undefined => {
  const obs = observations.find((o) => o.id === itemMask.observationId);
  if (!obs) return undefined;
  // In case BE response with string format for maskValue
  const maskNumber = parseInt(itemMask.maskValue.toString());
  let opacity = 80;
  let color = obs.observationSetting.color;
  try {
    const key = "3D_LABEL_OPACITY_" + obs.id;
    const val = localStorage.getItem(key);
    if (val) opacity = parseInt(val);
  } catch (error) {
    Sentry.captureException(error);
  }
  try {
    const key = "3D_LABEL_COLOR_" + obs.id;
    const val = localStorage.getItem(key);
    if (val) color = val;
  } catch (error) {
    Sentry.captureException(error);
  }

  const editorLabel: EditorLabel = {
    id: maskNumber,
    labelOptionId: obs.id,
    maskValue: maskNumber,
    name: obs.name,
    color: color,
    opacity: opacity,
    keyBind: "",
    status: EditorLabelStatus.FIXED,
    visibility: true,
    attributes: itemMask.attributes,
    estimatedSize: itemMask.estimatedSize,
  };

  return editorLabel;
};

export const sliceAnnoItemMasksToEditorLabels = (
  itemMasks: SliceAnnotationItemMask[],
  observations: ObservationDTO[]
): EditorLabel[] => {
  const editorLabels: EditorLabel[] = [EDITOR_LABEL_NONE];

  for (const itemMask of itemMasks) {
    const editorLabel = sliceAnnoItemMaskToEditorLabel(itemMask, observations);
    if (editorLabel && itemMask.maskValue > 0) {
      editorLabels.push(editorLabel);
    }
  }

  return editorLabels;
};

export const annotationsToLabelMapOptions = async (
  observations: ObservationDTO[],
  annotations: AnnotationResponseDTO[],
  acceptObservation?: ObservationDTO,
  rejectObservation?: ObservationDTO,
  workspaceId?: string
): Promise<LabelMapOption[]> => {
  const labelMapOptions: LabelMapOption[] = [];
  const items: {
    displayName: string;
    data: SliceAnnotationItem | undefined;
  }[] = [];

  for (const anno of annotations) {
    let displayName = anno.assignee || "####";
    let data: SliceAnnotationItem | undefined = undefined;

    if (anno.observationId === acceptObservation?.id) {
      displayName = `(Accept) ${displayName}`;
    } else if (anno.observationId === rejectObservation?.id) {
      displayName = `(Reject) ${displayName}`;
    } else if (anno.annotation) {
      const annoItem = anno.annotation as SliceAnnotationItem;
      if (annoItem.resourceId) {
        data = annoItem;
      } else {
        displayName = `Error - Job (${anno.jobId})`;
      }
    }

    items.push({ displayName, data });
  }

  const promises: Promise<any>[] = [];
  for (const item of items) {
    promises.push(sliceAnnoItemToLabelMapAndMask(item.data, true, workspaceId));
  }
  const results: any[] = await Promise.all(promises);

  for (let i = 0; i < results.length; i++) {
    labelMapOptions.push({
      displayName: items[i].displayName,
      labelMapBlob: results[i].labelMap,
      labels: sliceAnnoItemMasksToEditorLabels(results[i].masks, observations),
      metadata: sliceAnnoItemAndObsToWorkingMetadata(
        observations,
        items[i].data
      ),
    });
  }

  return labelMapOptions;
};

// Metadata stuff
export const getValuesFromAnnoAttributesByAttrId = (
  attrId: number,
  attributes: AnnotationAttributeItem[] | undefined
): string[] => {
  if (attributes) {
    for (const attr of attributes) {
      if (attr.id === attrId) {
        return attr.value;
      }
    }
  }
  return [];
};

export const sliceAnnoItemAndObsAttributesToRowAttributes = (
  attributes: ObservationAttribute[],
  anno: SliceAnnotationItem | undefined
): EditorMetadataRowAttributeModel[] => {
  const metadataAttributes: EditorMetadataRowAttributeModel[] = [];

  for (const attribute of attributes) {
    const metadataAttr: EditorMetadataRowAttributeModel = {
      id: attribute.id,
      type: attribute.type,
      name: attribute.name,
      values: getValuesFromAnnoAttributesByAttrId(
        attribute.id,
        anno?.attributes
      ),
      options: attribute.values,
      required: attribute.required,
    };
    metadataAttributes.push(metadataAttr);
  }

  return metadataAttributes;
};

export const sliceAnnoItemAndObsToWorkingMetadata = (
  observations: ObservationDTO[],
  anno: SliceAnnotationItem | undefined
): EditorMetadataRowModel[] => {
  const metadataRows: EditorMetadataRowModel[] = [];
  const metadataObs = observations.filter(
    (ob) =>
      ob.observationSetting.annotationType ===
      ObservationAnnotationType.METADATA
  );

  for (const obs of metadataObs) {
    const row: EditorMetadataRowModel = {
      id: obs.id,
      name: obs.name,
      attributesVisibility: false,
      attributes: sliceAnnoItemAndObsAttributesToRowAttributes(
        obs.attributes,
        anno
      ),
    };
    metadataRows.push(row);
  }

  return metadataRows;
};

export function iouAnnotationsMapper(
  data: LoadCombplexJobResponse
): IoUModel[] {
  const { iouAnnotations } = data;
  if (!iouAnnotations) return [];
  const models = [];
  const allAnnotations = [...data.annotations, ...data.previousJobsAnnotations];

  for (const iouAnnotation of iouAnnotations) {
    const { annotationIds, metric } = iouAnnotation;
    const annotations = allAnnotations.filter((anno) => {
      return annotationIds.includes(anno.id);
    });

    if (annotations.length === 0) continue;

    const annotation = annotations[0].annotation as SliceAnnotationItem;

    if (!annotation || !annotation.mask) continue;

    const iouModel: IoUModel = {
      labelId: -1,
      annotations: annotations.map((anno) => {
        return {
          id: anno.id,
          annotator: anno.assignee,
          jobId: anno.jobId,
        };
      }),
      metric,
    };

    models.push(iouModel);
  }

  return models;
}
