import { CircularProgress } from "@material-ui/core";
import { useKeyPress } from "ahooks";
import classNames from "classnames";
import { IconArrowRight } from "components/common/vb-icon.component";
import { useAppDispatch } from "hooks/use-redux";
import { createContext, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ProjectInfoDTOV2 } from "services/label-service/dtos/project-v2.dto";
import { enqueueErrorNotification, enqueueSuccessNotification } from "store/common/notification/notification.actions";
import { createBatchAsyncV2 } from "store/customer/project/project.slice";
import { KeyboardKey } from "utilities/keyboard/keyboard-keys";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { toCreateBatchPayload } from "./create-batch-v2.mappers";
import { CreateBatchV2StepDataModel, CreateBatchV2State, STEPS_DATA, STEP_DATASOURCE, STEP_GENERAL_INFO } from "./create-batch-v2.states";
import { CreateBatchV2PageDatasource } from "./pages/create-batch-v2-page-datasource.component";
import { CreateBatchV2PageInfo } from "./pages/create-batch-v2-page-info.component";
import * as Sentry from "@sentry/react";


export const CreateBatchV2Context = createContext({} as CreateBatchV2State);

export const useCreateBatchV2Context = () => {
  return useContext(CreateBatchV2Context);
};

export interface Props {
  projectForCreatingBatch: ProjectInfoDTOV2,
}
export const CreateBatchV2Provider = ({
  projectForCreatingBatch,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [batchInfo, setBatchInfo] = useState<any>({});
  const [batchDatasource, setBatchDatasource] = useState<any>({});

  const [currentStep, setCurrentStep] = useState<CreateBatchV2StepDataModel>(STEP_GENERAL_INFO);
  const [isProcessing, setIsProcessing] = useState(false);

  const stepsValidation = useMemo(() => {
    // Init all steps are valid
    const validations: boolean[] = STEPS_DATA.map(_ => true);

    // Validation batch info
    if (!batchInfo.name){
      validations[STEP_GENERAL_INFO.id - 1] = false;
    }

    // Validation batch datasource
    if (batchDatasource.id <= 0){
      validations[STEP_DATASOURCE.id - 1] = false;
    }

    return validations;
  }, [batchInfo, batchDatasource]);
  
  const isAllStepsValid = useMemo(() => {
    for (const valid of stepsValidation) {
      if (!valid) return false;
    }
    return true;
  }, [stepsValidation]);

  const handleClickBackToBatches = () => {
    history.goBack();
  }

  const handleClickPrevOrNextStep = (direction: number) => {
    const id = currentStep.id + direction;
    const step = STEPS_DATA.find(sd => sd.id === id);
    if (step) {
      setCurrentStep(step);
    }
  }

  const handleClickCreate = async () => {
    if (isProcessing) return;
      
    try {
      setIsProcessing(true);
      const payload = toCreateBatchPayload(
        projectForCreatingBatch, batchInfo, batchDatasource
      );
      
      const response = await dispatch(createBatchAsyncV2(payload));
      handleThunkRejected(response);
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));

      // relocate to the newly created batch page
      if (response && response.payload && response.payload.id) {
        const url = history.location.pathname;
        const parts = url.split("/");
        parts[parts.length - 1] = "batches";
        const redirectUrl = `${parts.join("/")}/${response.payload.id}`;
        history.push(redirectUrl);
      }
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsProcessing(false);
    }
  }

  useKeyPress(KeyboardKey.Enter, (e) => {
    if (e.target instanceof HTMLTextAreaElement) return;

    e.preventDefault();
    e.stopImmediatePropagation();

    if (currentStep.id < STEPS_DATA.length) {
      if (stepsValidation[currentStep.id - 1]) {
        handleClickPrevOrNextStep(1);
      }
    } else {
      if (isAllStepsValid) {
        handleClickCreate();
      }
    }
  });

  const value: CreateBatchV2State = {
    projectForCreatingBatch,
    batchInfo,
    setBatchInfo,
    batchDatasource,
    setBatchDatasource,
    currentStep,
    setCurrentStep,
  };

  return (
    <CreateBatchV2Context.Provider value={value}>
      {
        !projectForCreatingBatch &&
        <h1>There is an error, maybe creating batch v2 are not supported in old version projects</h1>
      }
      {
        projectForCreatingBatch &&
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between flex-none gap-4 pb-4">
            <div className="flex items-center">
              {STEPS_DATA.map(step => {
                const isActive = step.id === currentStep.id;
                return (
                  <button
                    key={step.id}
                    onClick={() => setCurrentStep(step)}
                    className={classNames(
                      "flex items-center gap-2 px-4 py-2 border-b-2",
                      {
                        "text-primary border-primary": isActive,
                      }
                    )}
                  >
                    <span
                      className={classNames(
                        "flex items-center justify-center w-5 h-5 text-xs text-white rounded-full",
                        {
                          "bg-primary": isActive,
                          "bg-background-500": !isActive,
                        }
                      )}
                    >
                      {step.id}
                    </span>
                    <span>{step.name}</span>
                  </button>
                );
              })}
            </div>
          </div>
          
          <div className="flex-auto flex flex-col bg-white">
            <div className="flex-auto">
              {currentStep.id === STEP_GENERAL_INFO.id && <CreateBatchV2PageInfo />}
              {currentStep.id === STEP_DATASOURCE.id && <CreateBatchV2PageDatasource />}
            </div>
            <div className="flex items-center gap-4">
              <button
                className="button-secondary"
                onClick={handleClickBackToBatches}
              >
                <IconArrowRight className="w-4 h-4 transform rotate-180" />
                <span>{t("project:batch.buttonBatchToBatches")}</span>
              </button>
              <div className="flex-auto"></div>
              {
                currentStep.id > 1 &&
                <button
                  className="button-secondary"
                  onClick={() => handleClickPrevOrNextStep(-1)}
                >
                  <IconArrowRight className="w-4 h-4 transform rotate-180" />
                  <span>{t("common:buttonPrevStep")}</span>
                </button>
              }
              {
                currentStep.id < STEPS_DATA.length &&
                <button
                  className="button-primary disabled:opacity-50"
                  disabled={!stepsValidation[currentStep.id - 1]}
                  onClick={() => handleClickPrevOrNextStep(1)}
                >
                  <span>{t("common:buttonNexStep")}</span>
                  <IconArrowRight className="w-4 h-4" />
                </button>
              }
              {
                currentStep.id === STEPS_DATA.length &&
                <button
                  className="button-primary px-8 disabled:opacity-50"
                  disabled={!isAllStepsValid || isProcessing}
                  onClick={handleClickCreate}
                >
                  <span>
                    {
                      isProcessing ? <CircularProgress size={18} color="inherit" /> : t("common:buttonCreate")
                    }
                  </span>
                </button>
              }
            </div>
          </div>
        </div>
      }
    </CreateBatchV2Context.Provider>
  )
}
