/*
 * File: label-distribution.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 3:57:25 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ProjectService } from "services/label-service";
import { RootState } from "store";
import {
  addEntity,
  requestFulfilled,
  requestPending,
  requestRejected,
} from "store/base/base.reducer";
import {
  LabelDistributionState,
  StepDistributionDTO,
} from "store/customer/project-data/label-distribution/label-distribution.state";
import { getStepDistributionData } from "utilities/label-distribution/get-step-distribution-data";

export enum ProjectDistributionThunk {
  LOAD_LABEL_DISTRIBUTION = "projectData/loadLabelDistributionAsync",
}

export const loadLabelDistributionAsync = createAsyncThunk(
  ProjectDistributionThunk.LOAD_LABEL_DISTRIBUTION,
  async (projectId: number, { getState }) => {
    const state = getState() as RootState;
    if (state.projectData.labelDistribution.ids.includes(projectId)) {
      return state.projectData.labelDistribution.entities[projectId];
    }
    const response = await ProjectService.getDistribution(projectId);
    const items: StepDistributionDTO[] = [response.data];
    const steps = getStepDistributionData(items);

    return { id: projectId, steps };
  }
);

export const labelDistributionReducerBuilder = <
  S extends { labelDistribution: LabelDistributionState }
>(
  builder: ActionReducerMapBuilder<S>
): ActionReducerMapBuilder<S> => {
  return builder
    .addCase(loadLabelDistributionAsync.pending, (state) => {
      requestPending(state.labelDistribution);
    })
    .addCase(loadLabelDistributionAsync.rejected, (state, action) => {
      requestRejected(state.labelDistribution, action.error);
    })
    .addCase(loadLabelDistributionAsync.fulfilled, (state, action) => {
      requestFulfilled(state.labelDistribution);
      if (action.payload) {
        addEntity(state.labelDistribution, action.payload);
      }
    });
};
