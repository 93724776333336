/*
 * File: text-editor.actions.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 5:06:00 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { collectionUtils } from "domain/common";
import { TextLabelingData } from "domain/labeling/labeling-data";
import { CORAnnotation, LabelType, NERAnnotation } from "domain/text-labeling";
import { SystemAnnotation } from "domain/text-labeling/system-annotation";
import { AtLeast } from "types/common";
import { TextWorkspaceState } from "../text-workspace.state";
import { INITIAL_TEXT_EDITOR_STATE } from "./text-editor.state";

export const textEditorActions = {
  resetTextEditorState({ textEditor }: TextWorkspaceState) {
    Object.assign(textEditor, INITIAL_TEXT_EDITOR_STATE);
  },
  textRelationNameDisplayTogged({ textEditor }: TextWorkspaceState) {
    textEditor.showRelationName = !textEditor.showRelationName;
  },
  textNavBarToggled({ textEditor }: TextWorkspaceState) {
    textEditor.showNavBar = !textEditor.showNavBar;
  },
  textSideBarToggled({ textEditor }: TextWorkspaceState) {
    textEditor.showSideBar = !textEditor.showSideBar;
  },
  textRelationDisplayTogged({ textEditor }: TextWorkspaceState) {
    textEditor.showRelation = !textEditor.showRelation;
  },
  textEntityDisplayTogged({ textEditor }: TextWorkspaceState) {
    textEditor.showEntity = !textEditor.showEntity;
  },
  textAutoSaveTogged({ textEditor }: TextWorkspaceState) {
    textEditor.autoSave = !textEditor.autoSave;
  },
  showLabelerWork: (
    { textLabeling, textEditor }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    // const assignee = action.payload;
    // const previousJobsTaskObservation =
    //   textLabeling.previousTaskObservations.find(
    //     (p) => !!!assignee || p.assignee === assignee
    //   );
    // textEditor.activeLabelId = "";
    // textEditor.activeRelationId = "";
    // textEditor.activeTokenObservationId = "";
    // textEditor.observations = [];
    // textEditor.relations = [];
    // const textLabels = textLabeling.labels;
    // if (previousJobsTaskObservation) {
    //   const { labels, relations } = previousJobsTaskObservation.annotation;
    //   textEditor.observations = labels.map((label) => {
    //     const { startIndex, endIndex, observationId } = label;
    //     return {
    //       id: `${observationId}:${startIndex}:${endIndex}`,
    //       observationId: observationId.toString(),
    //       startIndex: startIndex,
    //       endIndex: endIndex,
    //     };
    //   });
    //   for (let relation of relations) {
    //     const fromLabel = labels.find((label) => label.id === relation.fromId);
    //     const toLabel = labels.find((label) => label.id === relation.toId);
    //     if (!fromLabel || !toLabel) continue;
    //     const textLabel = textLabels.find(
    //       (lb) => lb.observation.id === relation.observationId
    //     );
    //     if (!textLabel) continue;
    //     const newRelation: CORAnnotation = {
    //       observationId: relation.observationId.toString(),
    //       color: textLabel.color,
    //       text: textLabel.name,
    //       from: `${fromLabel.observationId}:${fromLabel.startIndex}:${fromLabel.endIndex}`,
    //       to: `${toLabel.observationId}:${toLabel.startIndex}:${toLabel.endIndex}`,
    //       id: v4(),
    //     };
    //     textEditor.relations.push(newRelation);
    //   }
    //   if (previousJobsTaskObservation.annotation.observationId) {
    //     const { observationId } = previousJobsTaskObservation.annotation;
    //     const textLabel = textLabels.find(
    //       (lb) => lb.observation.id === observationId
    //     );
    //     if (textLabel) {
    //       const observation = textLabel.observation;
    //       if (observation?.observationSetting.systemAttribute) {
    //         textEditor.selectedSystemObservationId = observationId;
    //       }
    //     }
    //   }
    // }
  },

  aiAnnotationsGenerated(
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<
      {
        observationId: number;
        startIndex: number;
        endIndex: number;
      }[]
    >
  ) {
    for (let annotation of action.payload) {
      const { observationId, startIndex, endIndex } = annotation;
      const newObservation = {
        id: `${observationId}:${startIndex}:${endIndex}`,
        observationId: observationId.toString(),
        startIndex: startIndex,
        endIndex: endIndex,
      };
      if (!textEditor.observations.find(({ id }) => id === newObservation.id)) {
        textEditor.observations.push(newObservation);
      }
    }
  },

  setSelectedSystemObservationId: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<number>
  ) => {
    textEditor.selectedSystemObservationId = action.payload;
  },

  textAnnotationEdited: ({ textEditor, textLabeling }: TextWorkspaceState) => {
    const { observations, selectedTokenIds, editingAnnotationId } = textEditor;
    const { tokenEntities } = textLabeling;
    if (!editingAnnotationId || selectedTokenIds.length === 0) {
      textEditor.editingAnnotationId = "";
      return;
    }
    const annotation = observations.find(
      (anno) => anno.id === editingAnnotationId
    );
    if (annotation) {
      const startTokenId = selectedTokenIds[0];
      const endTokenId = selectedTokenIds[selectedTokenIds.length - 1];
      const startToken = tokenEntities[startTokenId];
      const endToken = tokenEntities[endTokenId];
      if (startToken && endToken) {
        const oldId = annotation.id;
        const id = `${annotation.observationId}:${annotation.startIndex}:${annotation.endIndex}`;
        textEditor.observations = observations.filter(
          (anno) => anno.id !== annotation.id && anno.id !== id
        );
        annotation.id = id;
        annotation.startIndex = startToken.startIndex;
        annotation.endIndex = endToken.endIndex;
        textEditor.observations.push(annotation);
        textEditor.relations = textEditor.relations.map((relation) => {
          if (relation.from === oldId) {
            return {
              ...relation,
              from: id,
            };
          }
          if (relation.to === oldId) {
            return {
              ...relation,
              to: id,
            };
          }
          return relation;
        });
      }
    }
    textEditor.editingAnnotationId = "";
    textEditor.selectedTokenIds = [];
    window.getSelection()?.empty();
    window.getSelection()?.removeAllRanges();
  },

  setSelectedTokenIds: (
    { textEditor, textLabeling }: TextWorkspaceState,
    action: PayloadAction<string[]>
  ) => {
    const { selectedLabelId, observations } = textEditor;
    const { tokenEntities, labels } = textLabeling;

    if (action.payload.length === 0 || !selectedLabelId) {
      textEditor.selectedTokenIds = action.payload;
      return;
    }
    if (!selectedLabelId) {
      return;
    }

    const label = labels.find(
      (lb) => lb.observation.id === parseInt(selectedLabelId)
    );
    if (
      LabelType.CONNECTION ===
      label?.observation?.observationSetting.annotationType
    ) {
      textEditor.selectedTokenIds = action.payload;
      return;
    }
    const selectedTokenIds = action.payload;
    const startTokenId = selectedTokenIds[0];
    const endTokenId = selectedTokenIds[selectedTokenIds.length - 1];
    const startIndex = tokenEntities[startTokenId].startIndex;
    const endIndex = tokenEntities[endTokenId].endIndex;
    const newObservation = {
      id: `${selectedLabelId}:${startIndex}:${endIndex}`,
      observationId: selectedLabelId,
      startIndex: startIndex,
      endIndex: endIndex,
    };
    if (!observations.find((o) => o.id === newObservation.id)) {
      observations.push(newObservation);
    }
    window.getSelection()?.empty();
    window.getSelection()?.removeAllRanges();
  },

  setActiveTokenObservationId: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    textEditor.activeTokenObservationId = action.payload;
  },

  relationHovered: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    textEditor.activeRelationId = action.payload;
  },

  setActiveLabelId: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    textEditor.activeLabelId = action.payload;
  },

  setShowLabelInstruction: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<boolean>
  ) => {
    textEditor.showLabelInstruction = action.payload;
  },

  setLabelVisible: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<{
      labelId: string;
      visible?: boolean;
    }>
  ) => {
    const { labelId, visible } = action.payload;
    if (visible && textEditor.hiddenLabelIds.hasOwnProperty(labelId)) {
      delete textEditor.hiddenLabelIds[labelId];
    } else if (!visible) {
      textEditor.hiddenLabelIds[labelId] = true;
    }
  },

  setContextMenuTokenId: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    textEditor.contextMenuTokenId = action.payload;
  },

  setContextMenuAnnotationId: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    textEditor.contextMenuAnnotationId = action.payload;
  },

  setSelectedLabelId: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    textEditor.selectedLabelId = action.payload;
  },

  setShowPreviousJob: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<boolean>
  ) => {
    textEditor.showPreviousJobs = action.payload;
  },

  setTextObservations: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<{
      annotations: NERAnnotation[];
      relationAnnotations: CORAnnotation[];
      systemAnnotations: SystemAnnotation[];
    }>
  ) => {
    textEditor.observations = action.payload.annotations;
    textEditor.relations = action.payload.relationAnnotations;
    if (action.payload.systemAnnotations.length > 0) {
      textEditor.selectedSystemObservationId = parseInt(
        action.payload.systemAnnotations[0].observationId
      );
    }
  },

  textObservationAdded: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<NERAnnotation>
  ) => {
    const { observations } = textEditor;
    if (observations.find(({ id }) => id === action.payload.id)) return;
    observations.push(action.payload);
  },
  textAnnotationLabelSelected: (
    { textEditor, textLabeling }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    const { tokenEntities } = textLabeling;
    const labelId = action.payload;
    const { observations, selectedTokenIds, editingAnnotationId } = textEditor;

    if (editingAnnotationId) {
      const anno = observations.find((a) => a.id === editingAnnotationId);
      if (anno) {
        anno.observationId = labelId;
      }
      textEditor.editingAnnotationId = "";
      return;
    }
    const startTokenId = selectedTokenIds[0];
    const endTokenId = selectedTokenIds[selectedTokenIds.length - 1];
    const startIndex = tokenEntities[startTokenId].startIndex;
    const endIndex = tokenEntities[endTokenId].endIndex;
    const id = `${labelId}:${startIndex}:${endIndex}`;
    const annotation: NERAnnotation = {
      id: id,
      observationId: labelId,
      startIndex: startIndex,
      endIndex: endIndex,
    };

    if (observations.find((anno) => anno.id === id)) return;
    observations.push(annotation);
  },

  textObservationRemoved: (
    { textEditor, textReview }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    textEditor.observations = textEditor.observations.filter(
      (observation) => observation.id !== action.payload
    );
    textEditor.relations = textEditor.relations.filter((relation) => {
      return relation.from !== action.payload && relation.to !== action.payload;
    });

    if (!textReview.reviewMode) return;
    const annotationId = action.payload;
    textReview.selectedAnnotationIds = textReview.selectedAnnotationIds.filter(
      (id) => id !== annotationId
    );
    const annoIds = [annotationId];
    for (const relation of textReview.relationAnnotations) {
      if (relation.from === annotationId || relation.to === annotationId) {
        annoIds.push(relation.id);
      }
    }

    textReview.annotations = textReview.annotations.filter(
      (anno) => !annoIds.includes(anno.id)
    );
    textReview.relationAnnotations = textReview.relationAnnotations.filter(
      (anno) => !annoIds.includes(anno.id)
    );
  },

  textObservationsCleared: ({ textEditor }: TextWorkspaceState) => {
    textEditor.observations = [];
  },

  textObservationEdited: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<AtLeast<NERAnnotation, "id">>
  ) => {
    textEditor.observations = textEditor.observations.map((observation) => {
      if (observation.id === action.payload.id) {
        return {
          ...observation,
          ...action.payload,
        };
      }
      return observation;
    });
  },

  relationAdded: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<CORAnnotation>
  ) => {
    textEditor.relations.push(action.payload);
  },

  relationRemoved: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    textEditor.relations = textEditor.relations.filter(
      (re) => re.id !== action.payload
    );
  },

  relationEdited: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<AtLeast<CORAnnotation, "id">>
  ) => {
    textEditor.relations = textEditor.relations.map((re) => {
      if (re.id === action.payload.id) {
        return {
          ...re,
          ...action.payload,
        };
      }
      return re;
    });
  },

  relationsCleared: ({ textEditor }: TextWorkspaceState) => {
    textEditor.relations = [];
  },

  textAnnotationsCleared: ({
    textEditor,
    textConflict,
  }: TextWorkspaceState) => {
    textEditor.relations = [];
    textEditor.observations = [];
    textEditor.selectedAnnotation = {
      annotationId: "",
      contextMenu: false,
      issueMenu: false,
    };
    textEditor.selectedTokenIds = [];
    textEditor.activeRelationId = "";
    textEditor.activeTokenObservationId = "";

    const { annotations, relationAnnotations } = textConflict;
    for (let annotationId of annotations.allIds) {
      const annotation = collectionUtils.getOne(annotations, annotationId);
      if (annotation) annotation.reviewStatus = undefined;
    }
    for (let annotationId of relationAnnotations.allIds) {
      const annotation = collectionUtils.getOne(
        relationAnnotations,
        annotationId
      );
      if (annotation) annotation.reviewStatus = undefined;
    }
  },
  textAnnotationsReseted: ({
    textEditor,
    textLabeling,
    textConflict,
  }: TextWorkspaceState) => {
    const data = textLabeling.labelingJob?.data?.jobData as TextLabelingData;
    textEditor.observations = data?.annotations || [];
    textEditor.relations = data?.relationAnnotations || [];
    textEditor.selectedAnnotation = {
      annotationId: "",
      contextMenu: false,
      issueMenu: false,
    };
    textEditor.selectedTokenIds = [];
    textEditor.activeRelationId = "";
    textEditor.activeTokenObservationId = "";

    const { annotations, relationAnnotations } = textConflict;
    for (let annotationId of annotations.allIds) {
      const annotation = collectionUtils.getOne(annotations, annotationId);
      if (annotation) annotation.reviewStatus = undefined;
    }
    for (let annotationId of relationAnnotations.allIds) {
      const annotation = collectionUtils.getOne(
        relationAnnotations,
        annotationId
      );
      if (annotation) annotation.reviewStatus = undefined;
    }
  },
  textAnnotationEditStarted: (
    { textEditor, textLabeling }: TextWorkspaceState,
    action: PayloadAction<{ annotation: NERAnnotation }>
  ) => {
    const { annotation } = action.payload;
    textEditor.editingAnnotationId = annotation.id;
    const { tokenIds, tokenEntities } = textLabeling;
    textEditor.selectedTokenIds = tokenIds.filter((tokenId) => {
      const token = tokenEntities[tokenId];
      return (
        token.startIndex >= annotation.startIndex &&
        token.endIndex <= annotation.endIndex
      );
    });
  },
  textApplyAIAnnotations: ({
    textEditor,
    textLabeling,
    textConflict,
  }: TextWorkspaceState) => {
    const data = textLabeling.labelingJob?.data
      ?.generatedData as TextLabelingData;
    textEditor.observations = data?.annotations || [];
    textEditor.relations = data?.relationAnnotations || [];
    textEditor.selectedAnnotation = {
      annotationId: "",
      contextMenu: false,
      issueMenu: false,
    };
    textEditor.selectedTokenIds = [];
    textEditor.activeRelationId = "";
    textEditor.activeTokenObservationId = "";

    const { annotations, relationAnnotations } = textConflict;
    for (let annotationId of annotations.allIds) {
      const annotation = collectionUtils.getOne(annotations, annotationId);
      if (annotation) annotation.reviewStatus = undefined;
    }
    for (let annotationId of relationAnnotations.allIds) {
      const annotation = collectionUtils.getOne(
        relationAnnotations,
        annotationId
      );
      if (annotation) annotation.reviewStatus = undefined;
    }
  },
  textApplyImportedAnnotations: ({
    textEditor,
    textLabeling,
    textConflict,
  }: TextWorkspaceState) => {
    const data = textLabeling.labelingJob?.data
      ?.importedData as TextLabelingData;
    textEditor.observations = data?.annotations || [];
    textEditor.relations = data?.relationAnnotations || [];
    textEditor.selectedAnnotation = {
      annotationId: "",
      contextMenu: false,
      issueMenu: false,
    };
    textEditor.selectedTokenIds = [];
    textEditor.activeRelationId = "";
    textEditor.activeTokenObservationId = "";

    const { annotations, relationAnnotations } = textConflict;
    for (let annotationId of annotations.allIds) {
      const annotation = collectionUtils.getOne(annotations, annotationId);
      if (annotation) annotation.reviewStatus = undefined;
    }
    for (let annotationId of relationAnnotations.allIds) {
      const annotation = collectionUtils.getOne(
        relationAnnotations,
        annotationId
      );
      if (annotation) annotation.reviewStatus = undefined;
    }
  },
  textApplyAnnotations: (
    { textEditor, textLabeling }: TextWorkspaceState,
    action: PayloadAction<{
      annotations: NERAnnotation[];
      relations: CORAnnotation[];
    }>
  ) => {
    for (const anno of action.payload.annotations) {
      if (textEditor.observations.find((item) => item.id === anno.id)) continue;
      textEditor.observations.push({
        ...anno,
        annotator: textLabeling.job?.assignee,
      });
    }
    for (const relation of action.payload.relations) {
      if (textEditor.relations.find((item) => item.id === relation.id))
        continue;
      textEditor.relations.push({
        ...relation,
        annotator: textLabeling.job?.assignee,
      });
    }

    textEditor.selectedAnnotation = {
      annotationId: "",
      contextMenu: false,
      issueMenu: false,
    };
    textEditor.selectedTokenIds = [];
    textEditor.activeRelationId = "";
    textEditor.activeTokenObservationId = "";
  },

  observationDragging: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    textEditor.draggingObservationId = action.payload;
  },

  textAnnotationContextMenuOpened: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    textEditor.selectedAnnotation = {
      annotationId: action.payload,
      contextMenu: true,
      issueMenu: false,
    };
  },

  textAnnotationContextMenuClosed: ({ textEditor }: TextWorkspaceState) => {
    textEditor.selectedAnnotation.contextMenu = false;
  },

  textAnnotationIssueMenuOpened: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    textEditor.selectedAnnotation = {
      annotationId: action.payload,
      contextMenu: false,
      issueMenu: true,
    };
  },

  textAnnotationIssueMenuClosed: ({ textEditor }: TextWorkspaceState) => {
    textEditor.selectedAnnotation.issueMenu = false;
  },

  textTokenContextMenuOpened: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    textEditor.selectedToken = {
      tokenId: action.payload,
      contextMenu: true,
      issueMenu: false,
    };
  },

  textTokenContextMenuClosed: ({ textEditor }: TextWorkspaceState) => {
    textEditor.selectedToken.contextMenu = false;
  },

  textTokenIssueMenuOpened: (
    { textEditor }: TextWorkspaceState,
    action: PayloadAction<string>
  ) => {
    textEditor.selectedToken = {
      tokenId: action.payload,
      contextMenu: false,
      issueMenu: true,
    };
  },

  textTokenIssueMenuClosed: ({ textEditor }: TextWorkspaceState) => {
    textEditor.selectedToken.issueMenu = false;
  },
};
