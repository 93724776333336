/*
 * File: use-notifications.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { useSnackbar } from "notistack";
import { useRef } from "react";
import { useEffect } from "react";
import { dismissAllNotifications } from "store/common/notification/notification.actions";
import { selectNotifications } from "../store/common/notification/notification.selectors";
import { useAppDispatch, useAppSelector } from "./use-redux";

export function useNotifications() {
  const dispatch = useAppDispatch();

  const displayedNotifications = useRef<string[]>([]);

  const notifications = useAppSelector(selectNotifications);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (notifications.length === 0) return;
    const handleRemoveNotification = (key: string) => {
      closeSnackbar(key);
      removeDisplayedNotification(key);
    };

    const removeDisplayedNotification = (key: string) => {
      displayedNotifications.current = displayedNotifications.current.filter(
        (noti) => noti !== key
      );
    };
    const newNotifications = notifications.filter(
      (noti) => !displayedNotifications.current.includes(noti.key)
    );
    for (const notification of notifications) {
      enqueueSnackbar(notification.message, {
        ...notification.options,
        action: (key: string) => {
          return (
            <div
              className="flex items-center h-4 mx-2 cursor-pointer w4 jsutify-center"
              onClick={() => handleRemoveNotification(key)}
            >
              <i className="uir-cross-small"></i>
            </div>
          );
        },
        onClose: () => {
          removeDisplayedNotification(notification.key);
        },
      });
    }
    displayedNotifications.current = [
      ...displayedNotifications.current,
      ...newNotifications.map((noti) => noti.key),
    ];
    dispatch(dismissAllNotifications());
  }, [dispatch, notifications, enqueueSnackbar, closeSnackbar]);
}
