/*
 * File: create-project-template.state.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 25th July 2022 2:56:24 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ProjectTemplate } from "domain/labeling/project-template";
import { StepData } from "pages/customer/projects/project.type";
import { ObservationDTO } from "services/label-service/dtos";

export interface ProjectInfoData {
  name: string;
  thumbnailUrl: string;
  file?: File;
  description?: string;
  instruction?: string;
  pdfLink?: string;
  externalLink?: string;
}

export interface ProjectLabelData {
  labels: Partial<ObservationDTO>[];
}
export interface ProjectAdvancedData {
  steps: StepData[];
}

export interface CreateProjectTemplateState {
  template?: ProjectTemplate;
  loading: boolean;
  isValidInput: boolean;
  isCreating?: boolean;
  tabs: { label: string; value: string; step?: number }[];
  activeTab: string;
  infoData: ProjectInfoData | null;
  labelData: ProjectLabelData | null;
  advancedData: ProjectAdvancedData | null;
  steps: StepData[];
  isDirty?: boolean;
  selectTab(tab: string): void;
  onProjectInfoChanged(data: ProjectInfoData): void;
  onProjectLabelChanged(data: ProjectLabelData): void;
  onProjectAdvancedChanged(data: ProjectAdvancedData): void;
  createProject(): void;
  selectedWorkflowId: number | null;
  setSelectedWorkflowId: (id: number) => void;
  setDefaultSteps: (steps: StepData[]) => void;
}

export const defaultCreateProjectTemplateState: CreateProjectTemplateState = {
  template: undefined,
  loading: true,
  isValidInput: false,
  isCreating: false,
  tabs: [],
  activeTab: "",
  selectTab: (_: string) => {},

  infoData: null,
  labelData: null,
  advancedData: null,
  steps: [],
  onProjectInfoChanged(_: ProjectInfoData) {},
  onProjectLabelChanged(_: ProjectLabelData) {},
  onProjectAdvancedChanged(_: ProjectAdvancedData) {},
  createProject() {},
  selectedWorkflowId: null,
  setSelectedWorkflowId: () => {},
  setDefaultSteps: () => {},
};
