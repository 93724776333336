import { collectionUtils } from "domain/common";
import { AnnotationPair } from "domain/image-labeling";
import { RootState } from "store";
import { imageAnnotationUtils } from "store/labeler/image-workspace/image-annotations/image-annotations.util";
import { selectTasksReviewBatchObservations } from "../tasks-review.selectors";

/* ------ Setter ------ */

export const selectTaskReviewIouAnnotations = (state: RootState) => {
  return state.tasksReview.taskReviewIou.iouAnnotations;
};

export const selectTaskReviewIouActiveScore = (state: RootState) => {
  return state.tasksReview.taskReviewIou.activeScore;
};

/* ------ Logic ------ */

export const selectVisibleAnnotationIdsByIou = (state: RootState) => {
  const annotations = state.tasksReview.annotations;
  const iouAnnotations = selectTaskReviewIouAnnotations(state);
  const activeScore = selectTaskReviewIouActiveScore(state);

  if (activeScore === -1) return annotations.map((annotation) => annotation.id);

  const sortedIous = [...iouAnnotations].sort(
    (a, b) => b.metric.value - a.metric.value
  );
  const displayedAnnotations: number[] = [];
  sortedIous.forEach((iouAnnotation) => {
    if (iouAnnotation.metric.value >= activeScore) {
      const isExistedAnnotation =
        displayedAnnotations.findIndex((displayedAnno) =>
          iouAnnotation.annotationIds.includes(displayedAnno)
        ) !== -1;
      if (isExistedAnnotation) return;
      displayedAnnotations.push(...iouAnnotation.annotationIds);
    }
  });

  return Array.from(new Set(displayedAnnotations));
};

export const selectTaskReviewMinIouScore = (state: RootState) => {
  const batchObservations = selectTasksReviewBatchObservations(state);
  const ious: number[] = [];

  batchObservations.forEach((item) => {
    if (item.batchObservation.iou) {
      ious.push(item.batchObservation.iou);
    }
  });

  return Math.min(...ious);
};

export const selectTaskReviewIoUPairs = (
  state: RootState
): AnnotationPair[] => {
  const iouAnnotations = selectTaskReviewIouAnnotations(state);
  const entities = collectionUtils.fromEntities(state.tasksReview.annotations);
  const pairs: AnnotationPair[] = [];
  for (const item of iouAnnotations) {
    if (item.annotationIds.length !== 2) continue;
    const a = collectionUtils.getOne(entities, item.annotationIds[0]);
    const b = collectionUtils.getOne(entities, item.annotationIds[1]);
    if (!a || !b) continue;
    const intersection = imageAnnotationUtils.intersectPolygons(a, b);
    const pair: AnnotationPair = {
      id: `${a.id}_${b.id}`,
      labelId: a.labelId,
      iouScore: item.metric.value,
      annotations: [a, b],
      intersection,
    };

    if (pair.intersection) {
      pair.intersection.type = "text";
      pair.intersection.iou = item.metric.value;
    }
    pairs.push(pair);
  }
  return pairs;
};
