import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { TableBase } from "components/design-system/table/table-base.component";
import { TableColumn } from "components/design-system/table/types";
import { useAppSelector } from "hooks/use-redux";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { JobDTO } from "services/label-service/dtos";
import { selectBatchWorkManagements } from "store/customer/batch/batch.selectors";

interface AssignJobTableProps {
  jobs?: JobDTO[];
  selectedLabelersByJob?: Record<number, string>;
  setSelectedLabelersByJob?: (labelersByJob: Record<number, string>) => void;
}

export const AssignJobTable = ({
  jobs,
  selectedLabelersByJob = {},
  setSelectedLabelersByJob,
}: AssignJobTableProps) => {
  const { t } = useTranslation();
  const workManagements = useAppSelector(selectBatchWorkManagements);

  const columns: TableColumn[] = [
    {
      key: "step",
      title: t("project:assignJobs.table.headerStep"),
      dataIndex: "workInstruction.name",
      cellProps: {
        width: "100px",
      },
    },
    {
      key: "round",
      title: t("project:assignJobs.table.headerRound"),
      dataIndex: "step",
      cellProps: {
        width: "100px",
      },
    },
    {
      key: "assign",
      title: t("project:assignJobs.table.headerAssignedLabeler"),
      render: (job: JobDTO) => {
        const labelersOptions = getLabelerOptions(job);
        const value = {
          label: selectedLabelersByJob[job.id],
          value: selectedLabelersByJob[job.id],
        };

        return (
          <VBSelectComponent
            value={value}
            options={labelersOptions}
            onChange={(option) => handleSelectLabeler(job.id, option)}
            isClearable
          />
        );
      },
      cellProps: {
        width: "300px",
      },
    },
  ];

  const records = useMemo(() => {
    if (!jobs || !jobs.length) return [];
    return jobs.sort((a, b) => {
      if (a.workInstruction.id < b.workInstruction.id) return -1;
      if (a.workInstruction.id > b.workInstruction.id) return 1;
      if (a.step < b.step) return -1;
      if (a.step > b.step) return 1;
      return 0;
    });
  }, [jobs]);

  const getWorkInstructionLabelers = useCallback(
    (workInstructionId: number) => {
      return workManagements
        .filter(
          (workManagement) =>
            workManagement.workInstruction.id === workInstructionId
        )
        .map((workManagement) => workManagement.userId);
    },
    [workManagements]
  );

  const getLabelerOptions = useCallback(
    (job: JobDTO) => {
      const workInstructionLabelers = getWorkInstructionLabelers(
        job.workInstruction.id
      );

      const remainLabelers = workInstructionLabelers.filter(
        (labeler) => !Object.values(selectedLabelersByJob).includes(labeler)
      );

      return remainLabelers.map((labeler) => {
        return {
          label: labeler,
          value: labeler,
        };
      });
    },
    [getWorkInstructionLabelers, selectedLabelersByJob]
  );

  const handleSelectLabeler = useCallback(
    (jobId, option: any) => {
      if (!setSelectedLabelersByJob) return;

      if (!option) {
        setSelectedLabelersByJob({ ...selectedLabelersByJob, [jobId]: "" });
        return;
      }

      const userId = option?.value as string;
      setSelectedLabelersByJob({ ...selectedLabelersByJob, [jobId]: userId });
    },
    [selectedLabelersByJob, setSelectedLabelersByJob]
  );
  return (
    <TableBase
      columns={columns}
      data={records}
      loading={!records.length}
      skeCount={5}
    />
  );
};
