/*
 * File: dataset-filter.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd July 2023 2:50:08 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { Filter } from "domain/common";
import useDatasetOptions from "../../../hooks/use-dataset-options";
import { useState } from "react";
import { QueryApi } from "data-access/impl/query";

interface Props {
  filter: Filter;
  onFilterChange(filter: Filter): void;
}

export function DatasetFilter({ filter, onFilterChange }: Props) {
  const [datasetFilter] = useState({
    page: 0,
    size: 1000,
    queries: [{ field: "type", operator: "$eq", value: "normal" }],
  });

  const {
    options: datasetOptions,
    option: selectedDataset,
    onChange: onDatasetChange,
  } = useDatasetOptions(datasetFilter);

  return (
    <div className="px-4">
      <VBSelectComponent
        header="Dataset"
        placeholder="Select dataset"
        value={selectedDataset}
        options={datasetOptions}
        isClearable
        menuPortalTarget={document.body}
        onChange={(newOption) => {
          onDatasetChange(newOption);
          const fileFilter = new QueryApi.FileFilter(filter);
          let datasetId: number | undefined = undefined;
          if (newOption) {
            const value = (newOption as any).value ?? undefined;
            datasetId = value ? parseInt(value) : undefined;
          }
          fileFilter.setDatasetId(datasetId);
          onFilterChange(fileFilter.toFilter());
        }}
      />
    </div>
  );
}
