/*
 * File: use-query.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 28th June 2021 2:49:55 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useHistory, useLocation } from "react-router";

export interface QueryValuePair {
  query: string;
  value: string;
}

export const useQuery = () => {
  const location = useLocation();
  const history = useHistory();

  function navigateByQuery(
    query: string,
    value: string,
    exact: boolean = false,
    addIfExists: boolean = false
  ) {
    const searchParams = exact ? "" : location.search;
    const urlSearchParams = new URLSearchParams(searchParams);
    if (value) {
      if (addIfExists) {
        urlSearchParams.append(query, value);
      } else {
        urlSearchParams.set(query, value);
      }
    } else {
      urlSearchParams.delete(query);
    }
    const newLocation = {
      ...location,
      search: urlSearchParams.toString(),
      hash: "",
    };
    history.push(newLocation);
  }

  function navigateByQueries(items: QueryValuePair[], exact = false) {
    const searchParams = exact ? "" : location.search;
    const urlSearchParams = new URLSearchParams(searchParams);

    for (let pair of items) {
      const { query, value } = pair;
      if (typeof value === "object" && Array.isArray(value)) {
        urlSearchParams.delete(query);
        for (const val of value as any) {
          urlSearchParams.append(query, val);
        }
        continue;
      }

      if (value) {
        if (urlSearchParams.has(query)) {
          urlSearchParams.set(query, value);
        } else {
          urlSearchParams.append(query, value);
        }
      } else {
        urlSearchParams.delete(query);
      }
    }

    const newLocation = {
      ...location,
      search: urlSearchParams.toString(),
      hash: "",
    };
    history.push(newLocation);
  }

  function navigateByParams(params: { [key: string]: string }) {
    const keys = Object.keys(params);
    const items: { [key: string]: string } = {};
    for (let key of keys) {
      if (params[key]) {
        items[key] = params[key];
      }
    }
    const urlSearchParams = new URLSearchParams(items);
    const newLocation = {
      ...location,
      search: urlSearchParams.toString(),
      hash: "",
    };
    history.push(newLocation);
  }

  return {
    navigateByQuery,
    navigateByQueries,
    navigateByParams,
  };
};
