import { CurrencyResponseDTO } from "services/payment/payment.dto";

// request stuff
export enum PriceByType {
  BY_JOB = "ByJob",
  BY_TASK = "ByTask",
}
export interface PricingRequestDTO {
  type: PriceByType,
  currencyId: number,
  steps: PricingCalculationStep[],
  perTask: number,
  mediaCount?: number,
}
export interface PricingCalculationStep {
  rounds: PricingCalculationRound[],
  required?: boolean,
  stepNumber?: number,
}
export interface PricingCalculationRound {
  roundNumber: number;
  perJob: number;
}

// response stuff
export interface PricingResponseDTO {
  id: number;
  type: string;
  payload: any;
  formulaVersion: string;
  serviceFeeRatio: number;
  currency: CurrencyResponseDTO;
}