/*
 * File: statistic-table.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 28th December 2021 4:45:00 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { VBTabs } from "components/common/vb-tabs/vb-tabs.component";
import { useAppSelector } from "hooks/use-redux";
import { useState } from "react";
import { selectDashBoardIsQueryNameLoading } from "store/customer/dashboard/dashboard.selectors";
import { useDashboardContext } from "../context/dashboard.context";
import { QueryNameStatistic } from "../context/dashboard.state";
import { LabelStatisticTable } from "./label-table.component";
import { LabelerStatisticTable } from "./labeler-table.component";


export const StatisticTable = () => {
  const [activeTab, setActiveTab] = useState("label");
  const {
    tableRowsLabels,
    tableRowsLabelers,
  } = useDashboardContext();
  const isLabelLoading = useAppSelector(selectDashBoardIsQueryNameLoading(QueryNameStatistic.LabelProgressStatistic));
  const isLabelerLoading = useAppSelector(selectDashBoardIsQueryNameLoading(QueryNameStatistic.LabelerProgressStatistic));

  return (
    <div className="w-full overflow-hidden bg-white rounded shadow">
      <VBTabs
        className="w-full"
        activeTab={activeTab}
        onSelect={setActiveTab}
        tabs={[
          { id: "label", name: `Labels (${tableRowsLabels.length})`, isLoading: isLabelLoading },
          { id: "labeler", name: `Labelers (${tableRowsLabelers.length})`, isLoading: isLabelerLoading },
        ]}
      />

      {activeTab === "label" && <LabelStatisticTable data={tableRowsLabels} />}
      {activeTab === "labeler" && <LabelerStatisticTable data={tableRowsLabelers} />}
    </div>
  );
};
