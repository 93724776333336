/*
 * File: routes.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export enum Routes {
  // ============PRIVATE ROUTES==============
  ROOT = "/",
  PROFILE = "/profile",
  PROJECTS = "/projects",
  PROJECT = "/projects/:projectId",
  BATCHES = "/batches",
  BATCH = "/batches/:batchId",
  DATASETS = "/datasets",
  DATASET = "/dataset",
  TASK = "/tasks/:taskId",
  MEMBERS = "/members",
  MLMODELS = "/models",
  AIMODELS = "/models",
  WEB_BUILDER = "/web-builder/:modelId",
  WEB_BUILDER_PREVIEW = "/web-builder/:modelId/preview",
  MODEL_WEB = "/model/:modelId",
  WORKFLOWS = "/workflows",
  WORKFLOWS_CREATE_NEW = "/workflows/create",
  WORKFLOWS_DETAIL = "/workflows/:workflowId",
  MANAGE_WORKSPACE = "/workspaces",
  NOTIFICATIONS = "/notifications",
  UNAUTHORIZED = "/unauthorized",
  LABELER_HOME = "/labeler",
  LABELER_PROJECTS = "/labeler/projects",
  LABELER_PROJECT_HISTORY = "/labeler/projects/:projectId",
  LABELER_REPORT = "/labeler/report",
  LABELER_FAQ = "/labeler/faq",
  LABELER_PROFILE = "/labeler/profile",
  TEXT_LABELING = "/labeler/text-labeling/:projectId",
  TEXT_REVIEW = "/labeler/review-text/:jobId",
  IMAGE_LABELING = "/labeler/image-labeling",
  IMAGES_LABELING = "/labeler/images-labeling",
  IMAGE_LABELING_PROJECT = "/labeler/image-labeling/:projectId",
  LABELER_REVIEW = "/review/:jobId",
  IMAGE_REVIEW = "/labeler/review-image/:jobId",
  SPEECH_TO_TEXT_REVIEW = "/labeler/review-stt/:jobId",
  TEXT_TO_SPEECH_REVIEW = "/labeler/review-tts/:jobId",
  THREE_D_REVIEW = "/labeler/review-three-d/:jobId",
  PATHOLOGY_REVIEW = "/labeler/review-pathology/:jobId",
  SPEECH_TO_TEXT_LABELING = "/labeler/speech-to-text-labeling/:projectId",
  TEXT_TO_SPEECH_LABELING = "/labeler/text-to-speech-labeling/:projectId",
  THREE_D_LABELING = "/labeler/three-d-labeling/:projectId",
  PATHOLOGY_LABELING = "/labeler/pathology-labeling/:projectId",

  // ============PUBLIC ROUTES==============
  SIGN_IN = "/signin",

  // ============PUBLIC ROUTES==============
  NOT_FOUND = "/not-found",
}

export enum ProjectRoutes {
  OVERVIEW = "/overview",
  BATCHES = "/batches",
  CREATE_BATCH = "/create-batch",
  LABELS = "/labels",
  USERS = "/users",
  WORKFLOWS = "/workflows",
  SETTINGS = "/settings",
}
