/**  
  The name of following interfaces are copied directly from the label/api.yml file (tag annotation-resource-v3 and job-resource-v3)
  for consistency between BE and FE, so please don't be confused.
**/

import { AnnotationVoteStatus } from "./annotation.dto";

// Some times, the request dtos and response dtos are not the same
// so we don't use the same object for both request, response for decoupling.

// Request DTOs
export interface AnnotationJobRequestDTO {
  annotations: AnnotationRequestDTO[];
  annotationRelations?: AnnotationRelationRequestDTO[];
}
export interface AnnotationRequestDTO {
  localId?: number;
  annotation?:
    | RawImageAnnotationItem
    | WsiAnnotationItem
    | SttAnnotationItem
    | TtsAnnotationItem
    | SliceAnnotationItem;
  observationId?: number;
}
export interface AnnotationRelationRequestDTO {
  directed: boolean;
  firstLocalId: number;
  first?: number;
  secondLocalId: number;
  second?: number;
  observationId?: number;
  localGroup?: number;
}

// Response DTOs
export interface AnnotationJobResponseDTO {
  annotations: AnnotationResponseDTO[];
  annotationRelations?: AnnotationRelationResponseDTO[];
  annotationVotes?: AnnotationVoteItem[];
  annotationVoteRelations?: AnnotationVoteRelations;
  annotationAgreementDTOS?: any;
}
export interface AnnotationResponseDTO {
  [x: string]: any;
  id: number;
  annotation:
    | RawImageAnnotationItem
    | WsiAnnotationItem
    | SttAnnotationItem
    | TtsAnnotationItem
    | SliceAnnotationItem;
  observationId?: number;
  mediaId: number;
  assignee: string;
  jobId: number;
  source: string;
  annotationType: string;
  batchId?: number;
}
export interface AnnotationRelationResponseDTO {
  id?: number;
  directed: boolean;
  first?: number;
  second?: number;
  localGroup?: number;
  observationId?: number;
}
export interface AnnotationVoteItem {
  annotationId: number;
  status: AnnotationVoteStatus | string;
}

export interface AnnotationVoteRelations {
  items: number[];
}

// attribute
export interface AnnotationAttribute {
  attributes?: AnnotationAttributeItem[];
}

export interface AnnotationAttributeItem {
  id: number;
  value: string[];
}

// Image
export interface RawImageAnnotationItem extends AnnotationAttribute {
  frameId?: number;
  referenceId?: string;
  bbox?: BBox;
  polygons?: Polygon[];
}
export interface BBox {
  x: number;
  y: number;
  width: number;
  height: number;
}
export interface Polygon {
  points: Point[];
  layer?: number;
  type?: number;
}
export interface Point {
  x: number;
  y: number;
}

// Whole slide image
export enum WsiAnnotationItemType {
  polygon = "polygon",
  bbox = "bbox",
  polyline = "polyline",
  line = "line",
  ellipse = "ellipse",
  points = "points",
}
export interface WsiAnnotationItem extends AnnotationAttribute {
  type: WsiAnnotationItemType;
  points: Point[];
}

// Speech to text
export interface SttAnnotationItem extends AnnotationAttribute {
  text?: string;
  note?: string;
  textLabels?: SttTextLabel[];
  probability?: number;
  observationId?: number;
}
export interface SttTextLabel extends AnnotationAttribute {
  start: number;
  end: number;
  observationId: number;
}

// Text to speech
export interface TtsAnnotationItem extends AnnotationAttribute {
  audioResourceId?: string;
  text?: string;
  note?: string;
  probability?: number;
  observationId?: number;
}

// 3D
export interface SliceAnnotationItem extends AnnotationAttribute {
  note?: string;
  resourceId?: string;
  observationId?: number;
  mask?: SliceAnnotationItemMask[];
}
export interface SliceAnnotationItemMask extends AnnotationAttribute {
  maskValue: number;
  observationId: number;
  estimatedSize?: number;
}
