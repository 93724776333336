/*
 * File: measurement-util.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 16th May 2022 5:56:31 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { MeasurementData } from "components/dicom/cornerstone/models/measurement.model";
import { Point } from "utilities/math/point";

function measurementCenterPoint(measurement: MeasurementData) {
  const bbox = measurementBoundingBox(measurement);
  if (!bbox) return null;
  return { x: bbox.x + bbox.width / 2, y: bbox.y + bbox.height / 2 };
}

function measurementBoundingBox(measurement: MeasurementData) {
  if (!measurement?.handles) return null;
  if (
    measurement.handles.hasOwnProperty("start") &&
    measurement.handles.hasOwnProperty("end")
  ) {
    const { start, end } = measurement.handles;
    const x = Math.min(start.x, end.x);
    const y = Math.min(start.y, end.y);
    const width = Math.abs(end.x - start.x);
    const height = Math.abs(end.y - start.y);
    return { x, y, width, height };
  }

  if (
    measurement.handles.hasOwnProperty("points") &&
    measurement.handles.points.length > 0
  ) {
    const points: Point[] = measurement.handles.points.map((point: any) => {
      return { x: point.x, y: point.y };
    });
    const minX = Math.min(...points.map(({ x }) => x));
    const maxX = Math.max(...points.map(({ x }) => x));
    const minY = Math.min(...points.map(({ y }) => y));
    const maxY = Math.max(...points.map(({ y }) => y));
    return { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
  }
  return null;
}

export const measurementUtils = {
  measurementBoundingBox,
  measurementCenterPoint,
};
