/*
 * File: task.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 11th July 2022 11:12:27 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Task } from "domain/labeling/task";
import { TaskDTO } from "../dtos";

function fromDTO(dto: TaskDTO): Task {
  return {
    id: dto.id,
    jobIds: dto.jobs?.map((job) => job.id) || [],
    batchId: dto.batchId || -1,
    fileId: parseInt(dto.taskReference),
    fileName: dto.fileName,
    assignee: dto.assignee || [],
    workspaceId: dto.workspaceId,
    dto,
  };
}

function toDTO(entity: Task): TaskDTO {
  throw new Error("Method not implemented");
}

export const taskMapper = {
  fromDTO,
  toDTO,
};
