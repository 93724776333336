/*
 * File: text-conflict.state.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 9th August 2022 10:13:59 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Collection } from "domain/common";
import { TextLabelingData } from "domain/labeling/labeling-data";
import { CORAnnotationData, NERAnnotationData } from "domain/text-labeling";
import { SystemAnnotationData } from "domain/text-labeling/system-annotation";

export interface TextConflictState {
  labelingDatas: TextLabelingData[];
  selectedAnnotators: string[];
  selectedAnnotationId: string;
  annotations: Collection<NERAnnotationData>;
  relationAnnotations: Collection<CORAnnotationData>;
  systemAnnotations: Collection<SystemAnnotationData>;
}

export const INITIAL_TEXT_CONFLICT_STATE: TextConflictState = {
  labelingDatas: [],
  selectedAnnotators: [],
  selectedAnnotationId: "",
  annotations: { allIds: [], entities: {} },
  relationAnnotations: { allIds: [], entities: {} },
  systemAnnotations: { allIds: [], entities: {} },
};
