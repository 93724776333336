/*
 * File: observation-list.component.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 12th October 2021 10:35:41 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Label } from "domain/image-labeling";
import { ObservationCard } from "./observation-card.component";

interface ObservationListProps {
  readonly?: boolean;
  deep: number;
  label: Label;
  activeObservationId: number;
  onRemove?: (label: Label) => void;
  onSelect?: (label: Label) => void;
  onChange?: (label: Label) => void;
  onVisibleChange?: (label: Label, visible: boolean) => void;
  onSelectedChange?: (label: Label, selected: boolean) => void;
}
export const ObservationList = ({
  readonly,
  deep = 0,
  label,
  activeObservationId,
  onSelect,
  onChange,
  onVisibleChange,
  onSelectedChange,
}: ObservationListProps) => {
  function toggleVisiblity() {
    onVisibleChange && onVisibleChange(label, !!label.hidden);
  }
  function toggleSelect() {
    onSelectedChange && onSelectedChange(label, !!label.selected);
  }
  return (
    <ObservationCard
      readonly={readonly}
      deep={deep}
      key={label.id}
      label={label}
      active={label.id === activeObservationId}
      onSelect={() => onSelect && onSelect(label)}
      onVisibleChange={toggleVisiblity}
      onSelectedChange={toggleSelect}
      onChange={onChange}
    />
  );
};
