import { IconTrash } from "components/common/vb-icon.component";
import { VBSpinner } from "components/common/vb-spinner/vb-spinner.component";
import { VBNumberTextInputComponent } from "components/design-system/number-text-input/number-text-input.component";
import { ChangeEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StorageService } from "services/storage";
import { classnames } from "utilities/classes";
import { AppError } from "utilities/errors/errors";
import * as Sentry from "@sentry/react";

export enum InstructionUrlType {
  UPLOADED_FILE = "uploaded_file",
  LINK = "link",
}

interface Props {
  onDataChanged?: (
    urlType: InstructionUrlType | undefined,
    url: string | undefined
  ) => void;
}
export const InstructionUrl = ({ onDataChanged }: Props) => {
  const { t } = useTranslation();
  const [urlType, setUrlType] = useState<InstructionUrlType>();

  const inputFile = useRef<HTMLInputElement>(null);
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string>();
  const [uploadedFileName, setUploadedFileName] = useState<string>();
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<AppError>();

  const [linkUrl, setLinkUrl] = useState<string>();

  const handleFileChanged = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setIsUploading(true);
      setError(undefined);
      const fileList = e.target.files;
      if (!fileList) {
        setUrlType(undefined);
        return;
      }
      const newFiles: File[] = [];
      for (let i = 0; i < fileList.length; i++) {
        const newFile = fileList.item(i);
        if (!newFile || newFile?.size > 1024 * 1024 * 5)
          throw new AppError("large_file", "Too large file!");
        newFiles.push(newFile);
      }

      if (newFiles.length <= 0) {
        setUrlType(undefined);
        return;
      }
      const fileToUpload = newFiles[0];

      const url = await StorageService.uploadFile(fileToUpload);
      setUploadedFileName(fileToUpload.name);
      setUploadedFileUrl(url);
      onDataChanged && onDataChanged(InstructionUrlType.UPLOADED_FILE, url);
    } catch (error: any) {
      Sentry.captureException(error);
      if (error instanceof AppError) {
        setError(error);
      } else {
        setError(new AppError("unknow", "Error!"));
      }
      onDataChanged &&
        onDataChanged(InstructionUrlType.UPLOADED_FILE, undefined);
      console.log(error);
    } finally {
      setIsUploading(false);
      setUrlType(InstructionUrlType.UPLOADED_FILE);
    }
  };

  const handleClickAttachButton = () => {
    if (inputFile.current && urlType !== InstructionUrlType.UPLOADED_FILE) {
      if (!!!uploadedFileName) {
        inputFile.current.click();
      } else {
        setUrlType(InstructionUrlType.UPLOADED_FILE);
        onDataChanged &&
          onDataChanged(InstructionUrlType.UPLOADED_FILE, uploadedFileUrl);
      }
    }
    if (urlType === InstructionUrlType.UPLOADED_FILE) {
      setUrlType(undefined);
      onDataChanged && onDataChanged(undefined, undefined);
    }
  };

  const handleClickLinkButton = () => {
    if (urlType !== InstructionUrlType.LINK) {
      setUrlType(InstructionUrlType.LINK);
      onDataChanged && onDataChanged(InstructionUrlType.LINK, linkUrl);
    } else {
      setUrlType(undefined);
      onDataChanged && onDataChanged(undefined, undefined);
    }
  };

  const handleLinkChanged = (value: string | number) => {
    const url = value as string;
    setLinkUrl(url);
    onDataChanged && onDataChanged(InstructionUrlType.LINK, url);
  };

  const handleClickDeleteFile = () => {
    setUploadedFileName(undefined);
    setUploadedFileUrl(undefined);
    setUrlType(undefined);
    setError(undefined);
    onDataChanged && onDataChanged(undefined, undefined);
  };

  return (
    <div className="flex flex-col gap-4">
      <h3 className="py-2 font-semibold">Instruction attachments</h3>
      <div className="flex items-center gap-2">
        <button
          className={classnames(
            "w-28",
            {
              "button-secondary-gray":
                urlType !== InstructionUrlType.UPLOADED_FILE,
            },
            { "button-primary": urlType === InstructionUrlType.UPLOADED_FILE }
          )}
          disabled={isUploading}
          onClick={handleClickAttachButton}
        >
          <span>
            {isUploading ? <VBSpinner /> : t("common:buttonAttachPDF")}
          </span>
        </button>
        {error && !isUploading && (
          <>
            <span className="text-error-300">{error.message}</span>
            <IconTrash
              className="text-error-300 cursor-pointer"
              onClick={handleClickDeleteFile}
            />
          </>
        )}
        {!error &&
          uploadedFileName &&
          !isUploading &&
          urlType === InstructionUrlType.UPLOADED_FILE && (
            <>
              <span className="text-primary">{uploadedFileName}</span>
              <IconTrash
                className="text-error-300 cursor-pointer"
                onClick={handleClickDeleteFile}
              />
            </>
          )}
      </div>
      <div className="flex items-center gap-2">
        <button
          className={classnames(
            "w-28",
            { "button-secondary-gray": urlType !== InstructionUrlType.LINK },
            { "button-primary": urlType === InstructionUrlType.LINK }
          )}
          onClick={handleClickLinkButton}
          disabled={isUploading}
        >
          {t("common:buttonLink")}
        </button>
        {urlType === InstructionUrlType.LINK && (
          <div className="flex-1">
            <VBNumberTextInputComponent
              type="text"
              height="35px"
              borderClass="border border-gray-400"
              onValueChanged={handleLinkChanged}
              defaultValue={linkUrl}
            />
          </div>
        )}
      </div>
      <input
        style={{ display: "none" }}
        type="file"
        ref={inputFile}
        accept="application/pdf"
        onChange={handleFileChanged}
        onClick={(e: any) => {
          e.target.value = null;
        }}
      />
    </div>
  );
};
