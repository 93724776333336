import { Collection, collectionUtils } from "domain/common";
import { Annotation, Label } from "domain/image-labeling";
import { RelationAnnotation } from "domain/image-labeling/relation-annotation";
import { ImageLabelingData } from "domain/labeling/labeling-data";

/*
 * File: annotations-reducer.hook.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 19th October 2022 10:33:30 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */
interface SelecableEntity {
  id: number;
  selected?: boolean;
  hidden?: boolean;
}

export interface SelectableAnnotator extends SelecableEntity {
  label: string;
  value: string;
}

export interface SelectableLabel extends Label, SelecableEntity {}

export interface SelectableAnnotation extends Annotation, SelecableEntity {}

export interface SelectableRelation
  extends RelationAnnotation,
    SelecableEntity {}

interface State {
  annotations: Collection<SelectableAnnotation>;
  relations: Collection<SelectableRelation>;
  labels: Collection<SelectableLabel>;
  labelingDatas: Collection<ImageLabelingData>;
  annotators: Collection<SelectableAnnotator>;
}

export const defaultAnnotationSourcesState: State = {
  annotations: { allIds: [], entities: {} },
  relations: { allIds: [], entities: {} },
  labels: { allIds: [], entities: {} },
  labelingDatas: { allIds: [], entities: {} },
  annotators: { allIds: [], entities: {} },
};

export enum AnnotationsActionType {
  INIT = "INIT",
  SELECT_ANNOTATOR = "SELECT_ANNOTATOR",
  SELECT_ANNOTATION = "SELECT_ANNOTATION",
  SELECT_LABEL = "SELECT_LABEL",
}

interface AnnotationsAction<T = any> {
  type: AnnotationsActionType;
  payload: T;
}

export default function annotationsReducer(
  state: State,
  action: AnnotationsAction
) {
  switch (action.type) {
    case AnnotationsActionType.INIT:
      return initState(state, action);
    case AnnotationsActionType.SELECT_ANNOTATOR:
      return selectAnnotator(state, action);
    case AnnotationsActionType.SELECT_ANNOTATION:
      return selectAnnotation(state, action);
    case AnnotationsActionType.SELECT_LABEL:
      return selectLabel(state, action);
    default:
      return state;
  }
}

function initState(
  state: State,
  action: AnnotationsAction<{
    labelingDatas: ImageLabelingData[];
    labels: Label[];
  }>
) {
  const { labels, labelingDatas } = action.payload;
  const annotators: SelectableAnnotator[] = labelingDatas.map((lb) => ({
    id: lb.id,
    value: lb.annotator,
    label: lb.annotator,
    selected: true,
  }));
  const annotations: SelectableAnnotation[] = labelingDatas
    .reduce((current: Annotation[], data) => {
      return current.concat(data.annotations);
    }, [])
    .map((anno) => ({ ...anno, selected: true, hidden: false }));
  const relations: SelectableRelation[] = labelingDatas
    .reduce((current: RelationAnnotation[], data) => {
      return current.concat(data.relationAnnotations);
    }, [])
    .map((relation) => ({ ...relation, selected: true, hidden: false }));
  return {
    ...state,
    annotators: collectionUtils.fromEntities(annotators),
    labels: collectionUtils.fromEntities(
      labels.map((lb) => ({ ...lb, selected: true }))
    ),
    labelingDatas: collectionUtils.fromEntities(labelingDatas),
    annotations: collectionUtils.fromEntities(annotations),
    relations: collectionUtils.fromEntities(relations),
  };
}
function selectAnnotator(state: State, action: AnnotationsAction<number>) {
  const annotator = collectionUtils.getOne(state.annotators, action.payload);
  if (!annotator) return state;
  annotator.selected = !annotator.selected;
  for (const id of state.annotations.allIds) {
    const entity = collectionUtils.getOne(state.annotations, id);
    if (!entity?.annotator || annotator.value !== entity.annotator) continue;
    entity.selected = annotator.selected;
  }
  for (const id of state.relations.allIds) {
    const entity = collectionUtils.getOne(state.relations, id);
    if (!entity?.annotator || annotator.value !== entity.annotator) continue;
    entity.selected = annotator.selected;
  }
  return { ...state };
}
function selectAnnotation(
  state: State,
  action: AnnotationsAction<{ id: number; type?: string }>
) {
  const { annotations, relations } = state;
  const id = action.payload.id;
  const entity =
    collectionUtils.getOne(annotations, id) ||
    collectionUtils.getOne(relations, id);
  if (!entity) return state;
  entity.selected = !entity.selected;
  return { ...state };
}
function selectLabel(
  state: State,
  action: AnnotationsAction<{ id: number; type?: string }>
) {
  const labelId = action.payload.id;
  const label = collectionUtils.getOne(state.labels, labelId);
  if (!label) return state;
  label.selected = !label.selected;
  for (const annoId of state.annotations.allIds) {
    const entity = collectionUtils.getOne(state.annotations, annoId);
    if (label.id === entity?.labelId) entity.selected = label.selected;
  }
  for (const relationId of state.relations.allIds) {
    const entity = collectionUtils.getOne(state.relations, relationId);
    if (label.id === entity?.labelId) entity.selected = label.selected;
  }
  return { ...state };
}
