/*
 * File: ml-model.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 17th February 2022 11:45:13 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

export interface MLModel {
  id: number;
  name: string;
  createdDate: string;
  description: string;
  versions: MLModelVersion[];
  latestVersion: MLModelVersion;
  type?: MLModelType;
}

export interface MLModelVersion extends Record<number, string> {
  id: number;
  name?: string;
}

export enum MLModelType {
  IMAGE_CLASSIFICATION = "image_classification",
  OBJECT_DETECTION = "image_detection",
  IMAGE_SEGMENTATION = "image_segmentation",
}

export const MLModelTypeName = {
  [MLModelType.IMAGE_CLASSIFICATION]: "Classification",
  [MLModelType.OBJECT_DETECTION]: "Object Detection",
  [MLModelType.IMAGE_SEGMENTATION]: "Classification",
};
