/*
 * File: annotation-toolbar.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 13th January 2022 2:34:12 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  IconDocumentText,
  IconPaintBucket,
  IconRouting2,
  IconTag,
} from "components/common/vb-icon.component";
import { LightTooltip } from "components/material/mat-light-tooltip.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { ObservationDTO } from "services/label-service/dtos";
import { selectAnnotatedObservations } from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import {
  selectAnnotationVisible,
  selectAnnotationFill,
  selectDisplayTextValue,
  selectRelationAnnotationVisible,
} from "store/labeler/image-workspace/cornerstone/cornerstone.selectors";
import {
  toggleAnnotationLabel,
  toggleAnnotationFill,
  toggleDisplayTextValue,
  toggleRelationAnnotationVisibility,
} from "store/labeler/image-workspace/cornerstone/cornerstone.slice";
import { classnames } from "utilities/classes";

export const AnnotationToolbar = () => {
  const dispatch = useAppDispatch();
  const annotationLabelVisible = useAppSelector(selectAnnotationVisible);
  const relationAnnotationVisible = useAppSelector(
    selectRelationAnnotationVisible
  );
  const annotationFill = useAppSelector(selectAnnotationFill);
  const displayTextValue = useAppSelector(selectDisplayTextValue);
  const observations = useAppSelector(selectAnnotatedObservations);

  function handleMouseEnter(observation: ObservationDTO) {}

  function handleMouseLeave(observation: ObservationDTO) {}

  function handleToggleVisible() {
    dispatch(toggleAnnotationLabel());
  }

  function handleToggleFillPolygon() {
    dispatch(toggleAnnotationFill());
  }

  function handleToggleDisplayTextValue() {
    dispatch(toggleDisplayTextValue());
  }

  function handleToggleRelationAnnotationVisibility() {
    dispatch(toggleRelationAnnotationVisibility());
  }

  return (
    <div className="flex items-center w-full h-12 bg-black bg-opacity-25">
      <div className="flex items-center flex-auto gap-3 px-4 overflow-x-auto">
        {observations.map((observation) => {
          return (
            <div
              key={observation.id}
              onMouseEnter={() => handleMouseEnter(observation)}
              onMouseLeave={() => handleMouseLeave(observation)}
              className="relative flex items-center h-6 px-2 overflow-hidden text-sm text-white border rounded cursor-pointer"
              style={{ borderColor: observation.observationSetting.color }}
            >
              <div
                className="absolute top-0 bottom-0 left-0 right-0 z-0 hover:opacity-80"
                style={{
                  backgroundColor: observation.observationSetting.color,
                }}
              />
              <div className="z-10 text-white">{observation.name}</div>
            </div>
          );
        })}
      </div>
      <div className="flex items-center flex-none gap-2 px-4">
        <ToggleButton
          tooltip={
            annotationLabelVisible
              ? "Hide annotation label"
              : "Show annotation label"
          }
          active={annotationLabelVisible}
          onClick={handleToggleVisible}
        >
          <IconTag className="w-5 h-5" />
        </ToggleButton>

        <ToggleButton
          tooltip={
            annotationFill ? "Hide annotation shape" : "Fill annotation shape"
          }
          active={annotationFill}
          onClick={handleToggleFillPolygon}
        >
          <IconPaintBucket className="w-5 h-5" />
        </ToggleButton>

        <ToggleButton
          tooltip={displayTextValue ? "Hide text value" : "Show text value"}
          active={displayTextValue}
          onClick={handleToggleDisplayTextValue}
        >
          <IconDocumentText className="w-5 h-5" />
        </ToggleButton>

        <ToggleButton
          tooltip={
            relationAnnotationVisible
              ? "Hide relation annotation"
              : "Show relation annotation"
          }
          active={relationAnnotationVisible}
          onClick={handleToggleRelationAnnotationVisibility}
        >
          <IconRouting2 className="w-5 h-5" />
        </ToggleButton>
      </div>
    </div>
  );
};

interface ToggleButtonProps {
  tooltip?: string;
  active?: boolean;
  children: JSX.Element;
  onClick?(): void;
}
const ToggleButton = (props: ToggleButtonProps) => {
  const { tooltip, active, children, onClick } = props;
  return (
    <LightTooltip title={tooltip || ""} hidden={!tooltip}>
      <button
        className={classnames(
          "w-8 h-8 rounded text-white flex items-center justify-center flex-none",
          {
            "bg-primary": !!active,
            "text-white": !active,
          }
        )}
        onClick={onClick}
      >
        {children}
      </button>
    </LightTooltip>
  );
};
