/*
 * File: use-project-template.hook.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 25th July 2022 10:25:45 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useQuery } from "react-query";
import { projectTemplateService } from "../service/project-template.service";

export default function useProjectTemplates() {
  const { data: templates, isLoading } = useQuery(
    "projectTemplates",
    projectTemplateService.getProjectTemplates,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  templates?.sort((a, b) => {
    const aOrder = a.order ?? 0;
    const bOrder = b.order ?? 0;
    return aOrder - bOrder;
  });
  return { templates, isLoading };
}
