import { Switch, withStyles } from "@material-ui/core";
import { classnames } from "utilities/classes";
import { SpeechToTextNote } from "./speech-to-text-note.component";

const StyledSwitch = withStyles({
  switchBase: {
    color: "#FFFFFF",
    '&$checked': {
      color: "#EA7E00",
    },
    '&$checked + $track': {
      backgroundColor: "#EA7E00",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#FFFFFF"
  },
})(Switch);
export interface SpeechToTextTextProps {
  text: string;
  note: string;
  useAITranscribe: boolean;
  disabled?: boolean;
  onTextChanged?: (value: string) => void;
  onNoteChanged?: (value: string) => void;
  onUseAITranscribeChanged?: (value: boolean) => void;
}

export const SpeechToTextText = ({
  text,
  note,
  useAITranscribe,
  disabled = false,
  onTextChanged,
  onNoteChanged,
  onUseAITranscribeChanged,
}: SpeechToTextTextProps) => {

  return (
    <div className="w-full flex flex-col gap-4 p-4">
      <div className="w-full flex justify-between text-white">
        <div>
          Type the text you hear
        </div>
        <div className="flex items-center gap-2">
          <StyledSwitch 
            checked={useAITranscribe}
            onChange={(_, v) => onUseAITranscribeChanged && onUseAITranscribeChanged(v)}
            disabled={disabled}
          />
          <div>
            Using AI Transcribe
          </div>
        </div>
      </div>
      <textarea
        className={classnames(
          "rounded p-2 focus:border-0 mb-2",
          {"text-white": disabled}
        )}
        placeholder="Text here..."
        rows={10}
        value={text}
        onChange={(e) => onTextChanged && onTextChanged(e.target.value as string)}
        disabled={disabled}
      />
      <SpeechToTextNote
        disabled={disabled}
        note={note}
        onNoteChanged={onNoteChanged}
      />
    </div>
  )
}