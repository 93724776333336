/*
 * File: ai-result-preview.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 21st February 2022 10:14:02 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import {
  IconArrowForward,
  IconLayer,
  IconTrash,
} from "components/common/vb-icon.component";
import initCornerstone from "components/dicom/cornerstone/cornerstone.config";
import { CornerstoneViewportEditor } from "components/dicom/cornerstone/viewport-editor/viewport-editor";
import { AnnotationV1, Label } from "domain/image-labeling";
import { MouseEvent, useEffect, useMemo, useRef } from "react";
import { ImageViewer } from "./image-viewer.component";
import * as cornerstone from "cornerstone-core";
import { AIResultCornerstoneHandler } from "./ai-preview-cornerstone-handler";
import { AnnotationToolbar } from "./annotation-toobar.component";
import { useWebBuilderPreviewContext } from "pages/customer/web-builder-preview/context/web-builder-preview.context";
import { LabelToolbar } from "./label-toolbar.component";
import { classnames } from "utilities/classes";

initCornerstone();

export enum AIResultStatus {
  INITIAL = "initial",
  PROCESSING = "processing",
  COMPLETED = "completed",
  NOTFOUND = "notfound",
}
interface Props {
  imageUrl?: string;
  annotations?: AnnotationV1[];
  labels?: Label[];
  status?: AIResultStatus;
  onRemove?(): void;
  onExpand?(): void;
  className?: string;
  active?: boolean;
}
export const AIResultPreview = ({
  imageUrl = "",
  status,
  onRemove,
  onExpand,
  annotations = [],
  labels = [],
  active = false,
  className = "relative flex flex-col items-center justify-center flex-none w-40 h-40 gap-4 overflow-hidden bg-black rounded lg:w-56 lg:h-56 xl:w-72 xl:h-72 parent",
}: Props) => {
  const {
    showAnnotationLabel,
    showTextValue,
    fillAnnotationShape,
    selectedLabelIds,
    selectLabel,
    selectNext,
    selectPrevious,
  } = useWebBuilderPreviewContext();
  const elementRef = useRef<HTMLDivElement>(null);
  const cornerstoneHandler = useRef<AIResultCornerstoneHandler>();
  const viewportEditor = useRef<CornerstoneViewportEditor>();
  const foundClasses = useMemo(() => {
    const foundLabels = labels.filter((label) => {
      return !!annotations.find((anno) => anno.categoryId === label.id);
    });
    return foundLabels;
  }, [labels, annotations]);

  function handleImageLoaded() {
    if (!elementRef.current) return;
    const container = elementRef.current;
    const viewport = cornerstone.getViewport(elementRef.current);
    viewportEditor.current = new CornerstoneViewportEditor(container, viewport);
    cornerstoneHandler.current = new AIResultCornerstoneHandler(container);
    cornerstoneHandler.current.init({
      showAnnotationLabel,
      showTextValue,
      fillAnnotationShape,
    });
    cornerstoneHandler.current.setAnnotations(annotations, labels);
  }

  function onContextMenu(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  const isFinished =
    status === AIResultStatus.COMPLETED || status === AIResultStatus.NOTFOUND;

  useEffect(() => {
    cornerstoneHandler.current?.setShowLabel(showAnnotationLabel);
  }, [showAnnotationLabel, cornerstoneHandler]);

  useEffect(() => {
    cornerstoneHandler.current?.setDisplayTextValue(showTextValue);
  }, [showTextValue, cornerstoneHandler]);

  useEffect(() => {
    cornerstoneHandler.current?.setFillPolygon(fillAnnotationShape);
  }, [fillAnnotationShape, cornerstoneHandler]);

  useEffect(() => {
    cornerstoneHandler.current?.setHiddenLabelIds(selectedLabelIds);
  }, [selectedLabelIds, cornerstoneHandler]);

  return (
    <div className={className} onContextMenu={onContextMenu}>
      <div
        className={classnames("flex flex-row w-full h-full gap-6", {
          "p-10": active,
        })}
      >
        <div className="relative flex-auto h-full overflow-hidden rounded">
          {isFinished && (
            <ImageViewer
              imageUrl={imageUrl}
              ref={elementRef}
              onLoaded={handleImageLoaded}
            />
          )}

          {!isFinished && (
            <div className="flex items-center justify-center w-full h-full bg-black">
              <img
                src={imageUrl}
                alt={imageUrl}
                className="h-full max-w-full max-h-full"
              />
            </div>
          )}

          {status === AIResultStatus.PROCESSING && <ProcessingMask />}
          {isFinished && active && <AnnotationToolbar />}
          {active && <ButtonNext onClick={selectNext} />}
          {active && <ButtonPrevious onClick={selectPrevious} />}
        </div>
        {active && (
          <LabelToolbar
            labels={labels}
            selectedLabelIds={selectedLabelIds}
            onSelect={selectLabel}
          />
        )}
      </div>

      {status === AIResultStatus.NOTFOUND && <NotfoundTag />}
      {!active && status === AIResultStatus.COMPLETED && (
        <FoundTag labels={foundClasses} />
      )}
      {!active && <ButtonRemove onClick={onRemove} />}
      {!active && <ButtonExpand onClick={onExpand} />}
    </div>
  );
};

const ProcessingMask = () => {
  return (
    <div className="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full bg-black bg-opacity-20">
      <span className="font-semibold text-white">Processing...</span>
    </div>
  );
};

const ButtonRemove = ({ onClick }: { onClick?(): void }) => {
  return (
    <div className="absolute top-0 right-0 z-50 p-2 child-on-hover">
      <button
        onClick={onClick}
        className="flex items-center justify-center w-6 h-6 overflow-hidden bg-white rounded-full"
      >
        <IconTrash className="flex-none w-4 h-4 text-error-500" />
      </button>
    </div>
  );
};
const ButtonExpand = ({ onClick }: { onClick?(): void }) => {
  return (
    <div className="absolute bottom-0 right-0 z-50 p-2 child-on-hover">
      <button
        onClick={onClick}
        className="flex items-center justify-center w-6 h-6 overflow-hidden rounded-full"
      >
        <IconLayer className="flex-none w-4 h-4 text-white" />
      </button>
    </div>
  );
};

const NotfoundTag = () => {
  return (
    <div className="absolute bottom-0 left-0 z-50 p-2">
      <span className="px-3 py-1 text-xs text-white bg-red-500 rounded-full">
        Not found
      </span>
    </div>
  );
};

interface FoundTagProps {
  labels: Label[];
}
const FoundTag = ({ labels }: FoundTagProps) => {
  return (
    <div className="absolute bottom-0 left-0 z-50 p-2">
      <span className="px-3 py-1 text-xs text-white rounded-full bg-success-500">
        Found: {labels.map((label) => label.name).join(",")}
      </span>
    </div>
  );
};

const ButtonPrevious = ({ onClick }: { onClick?(): void }) => {
  return (
    <div className="absolute left-0 z-50 p-4 transform -translate-y-1/2 top-1/2 child-on-hover">
      <button
        onClick={onClick}
        className="flex items-center justify-center w-6 h-6 overflow-hidden bg-white rounded-full shadow"
      >
        <IconArrowForward className="flex-none w-4 h-4 transform rotate-180 text-background-700" />
      </button>
    </div>
  );
};

const ButtonNext = ({ onClick }: { onClick?(): void }) => {
  return (
    <div className="absolute right-0 z-50 p-4 transform -translate-y-1/2 top-1/2 child-on-hover">
      <button
        onClick={onClick}
        className="flex items-center justify-center w-6 h-6 overflow-hidden bg-white rounded-full shadow"
      >
        <IconArrowForward className="flex-none w-4 h-4 text-background-700" />
      </button>
    </div>
  );
};
