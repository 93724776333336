import { MlRunInfoResponse } from "../../../services/ml-service/ml-service.dto";
import { MlModelsRunInfo } from "./ml-models.models";
import moment from "moment";

export const runInfoMapper = {
  fromEntity: (dto: MlRunInfoResponse): MlModelsRunInfo => {
    return {
      runId: dto.run_id,
      metrics: dto.metrics,
      status: dto.status,
      updatedAt: moment(new Date(dto.updated_at)).local().toDate(),
      description: dto.description || "",
      runInputs: dto.run_inputs,
      pipeline: dto.pipeline?.name ?? "",
      datasetId: dto.run_inputs
        .find((input) => input.name === "dataset_id")
        ?.value.toString(),
      datasetVersionId: dto.run_inputs
        .find((input) => input.name === "dataset_version_id")
        ?.value.toString(),
    };
  },
};
