/*
 * File: user-group.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { UserAPI } from "../http/user-service.httpclient";
import { UserAPIConfig } from "../config/user-api.config";
import { AxiosResponse } from "axios";
import { UserGroupDTO } from "../dtos/user-group.dto";
import { GroupDTO } from "../dtos/group.dto";
import { UserDTO } from "../dtos/user.dto";

export function getGroupUsers(
  groupId: string
): Promise<AxiosResponse<UserDTO[]>> {
  return UserAPI.get(`${UserAPIConfig.paths.group}/${groupId}`);
}
export interface AddUserToWorkspacePayload {
  group: GroupDTO;
  user: UserGroupDTO;
}
export function addUserToWorkspace(payload: AddUserToWorkspacePayload) {
  const data = JSON.stringify({
    ...payload,
    action: "join",
  });
  return UserAPI.post(UserAPIConfig.paths.userGroup, data);
}
export interface RemoveUserFromWorkspacePayload {
  group: GroupDTO;
  user: UserGroupDTO;
}
export function removeUserFromWorkspace(payload: RemoveUserFromWorkspacePayload) {
  const data = JSON.stringify({
    ...payload,
    action: "leave",
  });
  return UserAPI.post(UserAPIConfig.paths.userGroup, data);
}
