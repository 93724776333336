/*
 * File: batch-workflow.reducer.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 17th July 2021 11:52:16 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { BatchWorkflowDTO } from "services/label-service/dtos";
import { AtLeast } from "types/common";
import { ProjectState } from "../project.state";

export const batchWorkflowReducer = {
  setBatchWorkflows: (
    state: ProjectState,
    action: PayloadAction<BatchWorkflowDTO[]>
  ) => {
    state.batchWorkflows = action.payload;
  },
  addBatchWorkflow: (
    state: ProjectState,
    action: PayloadAction<BatchWorkflowDTO>
  ) => {
    state.batchWorkflows.push(action.payload);
  },
  updateBatchWorkflow: (
    state: ProjectState,
    action: PayloadAction<AtLeast<BatchWorkflowDTO, "id">>
  ) => {
    state.batchWorkflows = state.batchWorkflows.map((batchWorkflow) => {
      if (batchWorkflow.id === action.payload.id) {
        return {
          ...batchWorkflow,
          ...action.payload,
        };
      }
      return batchWorkflow;
    });
  },
  removeBatchWorkflow: (state: ProjectState, action: PayloadAction<number>) => {
    state.batchWorkflows = state.batchWorkflows.filter(
      (p) => p.id !== action.payload
    );
  },
};
