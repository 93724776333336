import { useParams } from "react-router-dom"
import { SpeechToTextLabelingProvider } from "./context/speech-to-text-labeling.provider"
import { SpeechToTextLabelingDetails } from "./speech-to-text-labeling.details"

export const SpeechToTextLabelingReview = () => {
  const { jobId } = useParams<{ jobId: string }>();

  if (!jobId) return null;

  return (
    <div className="h-screen overflow-scroll hide-scrollbar bg-background-900">
      <SpeechToTextLabelingProvider
        isFromJobId
        jobIdParam={jobId}
      >
        <SpeechToTextLabelingDetails fixedMenuIssue />
      </SpeechToTextLabelingProvider>
    </div> 
  )
}
