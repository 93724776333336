/*
 * File: vb-rich-text.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useRef } from "react";
import "./vb-rich-text.style.css";
import { classnames } from "utilities/classes";
import * as Sentry from "@sentry/react";

interface Props {
  defaultValue: string;
  onChange?(data: string): void;
  onUpload?(file: File): void;
  disabled?: boolean;
  config?: any;
  containerClass?: string;
  [key: string]: any;
}

export const VBRichTextEditor = ({
  defaultValue,
  onChange,
  disabled,
  config={
    toolbar: [
      "bold",
      "italic",
      "link",
      "undo",
      "redo",
      "numberedList",
      "bulletedList",
      "imageUpload", 
    ],
  },
  containerClass = "",
  onUpload,
  ...restProps
}: Props) => {
  function uploadAdapter(loader: any) {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file;
            const downloadUrl = onUpload ? await onUpload(file) : "";
            resolve({ default: downloadUrl })
          } catch (error) {
            Sentry.captureException(error);
            reject(error)
          }
        })
      }
    }
  }


  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader);
    };
  }

  const allConfigs = {
    ...config,
    extraPlugins: [uploadPlugin]
  }

  const dataRef = useRef(defaultValue);
  return (
    <div className={classnames(
      "vb-rich-text-editor",
      containerClass,
    )}>
      <CKEditor
        editor={ClassicEditor}
        data={defaultValue}
        disabled={disabled}
        config={allConfigs}
        onChange={(_: any, editor: any) => {
          const data = editor.getData();
          dataRef.current = data;
          onChange && onChange(data);
        }}
        {...restProps}
      />
    </div>
  );
};

