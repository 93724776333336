export function truncateEmail(email: string) {
  try {
    const parts = email.split("@");
    let name = email;
    if (parts.length > 1) {
      name = parts[0];
    }
    return name;
  } catch (error) {
    return email;
  }
}
