/*
 * File: label.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 22nd December 2021 3:58:42 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ObservationDTO } from "services/label-service/dtos";

export interface Label {
  id: string;
  name: string;
  color: string;
  type: LabelType;
  probability: number;
  isSystemLabel: boolean;
  allowConnection: boolean;
  observation: ObservationDTO;
  disableSelection?: boolean;
}

export enum LabelType {
  OBJECT = "ENTITY",
  CONNECTION = "RELATION",
}
