import { useTranslation } from "react-i18next";
import { ObservationDTO } from "services/label-service/dtos";
import { ReactSortable, ReactSortableProps } from "react-sortablejs";
import { ReactNode, useEffect, useState } from "react";
import { IconArrange } from "components/common/vb-icon.component";
import "./project-label-tree.style.scss";
import { useBuildObservationTree } from "hooks/use-observation-tree";
import { ObservationTreeNodeModel } from "services/label-service/model/observation-tree-node.model";
import { VBButton } from "components/common/vb-button.component";
import {
  TreeTableNameCell,
  TreeTableShowChildrenCell,
} from "../../components/tree-table-layout.component";
import { classnames } from "utilities/classes";
import { ObservationService } from "services/label-service";
import { useAppSelector } from "hooks/use-redux";
import { selectIsMdiSegmentation } from "store/customer/project/project.selectors";

export class FixedReactSortable<
  T extends ObservationTreeNodeModel<ObservationDTO>
> extends ReactSortable<T> {
  componentDidUpdate(prevProps: ReactSortableProps<T>): void {
    if (this["sortable"] && prevProps.disabled !== this.props.disabled) {
      this["sortable"].option("disabled", this.props.disabled);
    }
  }
}

interface ColDef {
  field: string;
  headerName: string;
  headerClassName?: string;
  flex: number;
  renderCell: (
    params: ObservationDTO,
    node: ObservationTreeNodeModel<ObservationDTO>
  ) => ReactNode;
}

interface Props {
  labels: ObservationDTO[];
  onSelect?: (
    observation: ObservationDTO,
    node: ObservationTreeNodeModel<ObservationDTO>,
    action?: string
  ) => void;
}

export const ProjectLabelTree = ({ labels, onSelect }: Props) => {
  const { t } = useTranslation();
  const {
    rootNode,
    setShowChildren,
    updateNodeChildrenOrder,
  } = useBuildObservationTree(labels);
  const isMdiProject = useAppSelector(selectIsMdiSegmentation);

  const columns: ColDef[] = [
    {
      field: "code",
      headerName: t("project:label.table.headerCode"),
      flex: 0.5,
      renderCell: (
        params: ObservationDTO,
        node: ObservationTreeNodeModel<ObservationDTO>
      ) => <TreeTableNameCell name={params.code} nodeHeight={node.height} />,
    },
    {
      field: "expand",
      headerName: "",
      flex: 0.2,
      renderCell: (
        params: ObservationDTO,
        node: ObservationTreeNodeModel<ObservationDTO>
      ) => (
        <TreeTableShowChildrenCell
          isLeaf={ObservationTreeNodeModel.isLeaf(node)}
          showChildren={node.showChildren}
          nodeId={node.id}
          setShowChildren={setShowChildren}
        />
      ),
    },
    {
      field: "maskId",
      headerName: t("project:label.table.headerMaskId"),
      flex: isMdiProject ? 0.5 : 0,
      renderCell: (
        params: ObservationDTO,
        node: ObservationTreeNodeModel<ObservationDTO>
      ) => <div>{params.observationSetting.maskId || "-"}</div>,
    },
    {
      field: "color",
      headerName: t("project:label.table.headerColor"),
      flex: 0.5,
      renderCell: (params: ObservationDTO) => (
        <input
          disabled
          value={params.observationSetting.color?.toString()}
          type="color"
          className="w-8 h-8 border-none"
        />
      ),
    },
    {
      field: "name",
      headerName: t("project:label.table.headerName"),
      flex: 0.5,
      renderCell: (
        params: ObservationDTO,
        node: ObservationTreeNodeModel<ObservationDTO>
      ) => (
        <TreeTableNameCell
          name={params.name}
          nodeHeight={node.height}
          paddingScaler={0}
        />
      ),
    },
    {
      field: "type",
      headerName: t("project:label.table.headerAnnotationType"),
      flex: 0.5,
      renderCell: (params: ObservationDTO) => (
        <div>
          {params.observationSetting?.annotationType}
        </div>
      ),
    },
    {
      field: "status",
      headerName: t("project:label.table.headerStatus"),
      flex: 0.5,
      renderCell: (params: ObservationDTO) => (
        <div>{params.active ? "Active" : "Archived"}</div>
      ),
    },
    {
      field: "action",
      headerName: t("project:label.table.headerAction"),
      flex: 0.5,
      headerClassName: "text-right",
      renderCell: (
        params: ObservationDTO,
        node: ObservationTreeNodeModel<ObservationDTO>
      ) => {
        if (params.observationSetting?.systemAttribute) {
          return null;
        }
        return (
          <div className="flex items-center justify-end gap-2">
            <IconArrange className="flex-none w-6 h-6 cursor-move handle" />
            <LabelActionsCell params={params} node={node} onClick={onSelect} />
          </div>
        );
      },
    },
  ];

  const handleOnDragEnd = (
    node: ObservationTreeNodeModel<ObservationDTO>,
    sortedItemIds: number[]
  ) => {
    const newNodeList = updateNodeChildrenOrder(
      node.id,
      sortedItemIds
    ) as ObservationTreeNodeModel<ObservationDTO>[];
    if (newNodeList) {
      // update order to the server
      const observations: ObservationDTO[] = [];
      for (let node of newNodeList) {
        if (node.value) {
          observations.push(node.value);
        }
      }
      // no need to await here, we async update priority
      ObservationService.partialUpdateBulkWithGenPriority(observations);
    }
  };

  return (
    <div className="w-full bg-white">
      <div
        className="flex items-center w-full gap-2 border-b hover:bg-gray-100"
        style={{ minHeight: "3rem" }}
      >
        {columns.map((column) => {
          return (
            <div
              key={column.field}
              style={{ flex: column.flex }}
              className={classnames(
                "h-full font-bold break-words overflow-hidden px-2",
                column.headerClassName || "",
                {"hidden": column.flex === 0}
              )}
            >
              {column.headerName}
            </div>
          );
        })}
      </div>
      <ProjectLabelNode
        node={rootNode}
        onDragEnd={handleOnDragEnd}
        columns={columns}
      />
    </div>
  );
};

interface ProjectLabelNodeProps {
  node: ObservationTreeNodeModel<ObservationDTO>;
  columns: ColDef[];
  onDragEnd: (
    node: ObservationTreeNodeModel<ObservationDTO>,
    sortedItemIds: number[]
  ) => void;
}
export const ProjectLabelNode = ({
  node,
  onDragEnd,
  columns,
}: ProjectLabelNodeProps) => {
  const [sortedItems, setSortedItems] = useState(node.children);

  useEffect(() => {
    setSortedItems(node.children);
  }, [node]);

  const handleDragEnd = (_: any) => {
    onDragEnd &&
      onDragEnd(
        node,
        sortedItems.map((i) => i.id)
      );
  };

  return (
    <FixedReactSortable
      animation={150}
      handle=".handle"
      list={sortedItems}
      setList={setSortedItems}
      ghostClass="bg-blue-100"
      onEnd={handleDragEnd}
    >
      {sortedItems.map((node) => {
        if (node.value && 
          node.value.observationSetting.systemAttribute)
          return <div key={node?.id || ""}></div>;
        return (
          <div key={node?.id || ""}>
            {node && (
              <div>
                {node.visible && node.value && (
                  <LabelRow
                    label={node.value}
                    node={node}
                    key={node.id}
                    columns={columns}
                  />
                )}
                {node.visible && (
                  <ProjectLabelNode
                    node={node}
                    onDragEnd={onDragEnd}
                    columns={columns}
                  />
                )}
              </div>
            )}
          </div>
        );
      })}
    </FixedReactSortable>
  );
};

interface LabelRowProps {
  columns: ColDef[];
  label: ObservationDTO;
  node: ObservationTreeNodeModel<ObservationDTO>;
}
export const LabelRow = ({ columns, label, node }: LabelRowProps) => {
  return (
    <div className="flex items-center w-full gap-2 py-1 border-b hover:bg-gray-100 label-row">
      {columns.map((column) => {
        return (
          <div
            key={column.field}
            style={{ flex: column.flex }}
            className={classnames(
              {
                "text-gray-400": !!node.value && !!!node.value.active,
              },
              "truncate px-2",
              {"hidden": column.flex === 0}
            )}
          >
            {column.renderCell(label, node)}
          </div>
        );
      })}
    </div>
  );
};

interface LabelActionsCellProps {
  params: ObservationDTO;
  node: ObservationTreeNodeModel<ObservationDTO>;
  onClick?(
    label: ObservationDTO,
    node: ObservationTreeNodeModel<ObservationDTO>,
    action: string
  ): void;
}

const LabelActionsCell = ({ params, node, onClick }: LabelActionsCellProps) => {
  if (!params.active)
    return (
      <div className="flex items-center justify-end">
        <VBButton
          className="hover:bg-white hover:text-primary"
          onClick={() => onClick && onClick(params, node, "active")}
        >
          <i className="uir-rotate-right"></i>
        </VBButton>
      </div>
    );
  return (
    <div className="flex items-center justify-end">
      <VBButton
        onClick={() => onClick && onClick(params, node, "edit")}
        className="hover:bg-white hover:text-primary"
      >
        <i className="uir-edit"></i>
      </VBButton>
      <VBButton
        onClick={() => onClick && onClick(params, node, "archive")}
        className="hover:bg-white hover:text-red-500"
      >
        <i className="uir-box"></i>
      </VBButton>
    </div>
  );
};
