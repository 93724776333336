import { PayloadAction } from "@reduxjs/toolkit";
import { TasksReviewState } from "../tasks-review.state";
import { IouAnnotation } from "./task-review-iou.state";

export const taskReviewIouActions = {
  setTaskReviewIouAnnotations(
    { taskReviewIou }: TasksReviewState,
    action: PayloadAction<IouAnnotation[]>
  ) {
    taskReviewIou.iouAnnotations = action.payload;
  },
  setTaskReviewIouActiveScore(
    { taskReviewIou }: TasksReviewState,
    action: PayloadAction<number>
  ) {
    taskReviewIou.activeScore = action.payload
  }
};
