/*
 * File: task-observation.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AxiosResponse } from "axios";
import { JobDTO } from "../dtos";
import { TaskObservationDTO } from "../dtos/task-observation.dto";
import { BaseAPI } from "./base.api";

export class TaskObservationAPI extends BaseAPI<TaskObservationDTO> {
  saveTaskAnnotations(payload: {
    job: JobDTO;
    observations: Partial<TaskObservationDTO>[];
  }) {
    return this.post(this.getPath(), payload);
  }

  getJobAnnotations(
    jobId: number
  ): Promise<AxiosResponse<TaskObservationDTO[]>> {
    return this.get(`${this.getPath()}/jobs/${jobId}`);
  }

  saveTextAnnotations(payload: any) {
    return this.post(`${this.getPath()}/text`, payload);
  }
}
