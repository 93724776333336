/*
 * File: batch-statistic.slice.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 23rd August 2021 11:43:47 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { addEntity, removeEntity, setEntites } from "store/base/base.reducer";
import {
  BatchStatisticDTO,
  BatchStatisticState,
} from "./batch-statistic.state";

interface State {
  statistic: BatchStatisticState;
}

export const batchStatisticReducers = {
  setBatchStatistics(state: State, action: PayloadAction<BatchStatisticDTO[]>) {
    setEntites(state.statistic, action.payload);
  },

  addStatistic(state: State, action: PayloadAction<BatchStatisticDTO>) {
    addEntity(state.statistic, action.payload);
  },

  removeStatistic(state: State, action: PayloadAction<number>) {
    removeEntity(state.statistic, action.payload);
  },
};
