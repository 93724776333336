/*
 * File: mat-modal.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 29th December 2021 11:25:05 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Modal, ModalProps } from "@material-ui/core";

interface Props extends ModalProps {
  disableBackdropClick?: boolean;
}
export const MatModal = ({
  children,
  disableBackdropClick,
  disableEscapeKeyDown,
  onClose,
  ...rest
}: Props) => {
  const handleClose = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return false;
    }

    if (disableEscapeKeyDown && reason === "escapeKeyDown") {
      return false;
    }

    if (typeof onClose === "function") {
      onClose(event, reason);
    }
  };

  return (
    <Modal onClose={handleClose} {...rest}>
      {children}
    </Modal>
  );
};
