/*
 * File: image-job-selected.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 9th November 2022 11:41:03 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ImageLabelingData } from "domain/labeling/labeling-data";
import { RootState } from "store";
import { selectImageLabelingJobById } from "../../batch-labeling/batch-labeling.selectors";
import { batchLabelingUtils } from "../../batch-labeling/batch-labeling.util";
import { imageAnnotationUtils } from "../../image-annotations/image-annotations.util";
import { ImageWorkspaceState } from "../../image-workspace.state";
import { convertFileCleaningDataToMaskAnnotation } from "../image-labeling.utils";

const THUNK_NAME = "imageWorkspace/imageJobSelectedAsync";

interface Payload {
  jobId: number;
}

export const imageJobSelectedAsync = createAsyncThunk(
  THUNK_NAME,
  async ({ jobId }: Payload, { getState }) => {
    const state = getState() as RootState;
    const imageLabelingJob = selectImageLabelingJobById(jobId)(state);
    return imageLabelingJob;
  }
);

export function imageJobSelectedBuilder(
  builder: ActionReducerMapBuilder<ImageWorkspaceState>
) {
  return builder.addCase(
    imageJobSelectedAsync.fulfilled,
    ({ imageLabeling, imageAnnotations }, action) => {
      if (!action.payload) return;
      const { id: jobId, file, labels, data } = action.payload;

      imageLabeling.agreements = [];
      imageLabeling.findingLabelers = [];
      imageLabeling.maskAnnotations = [];
      imageLabeling.labels = labels;

      if (file) {
        imageLabeling.maskAnnotations =
          convertFileCleaningDataToMaskAnnotation(file);
      }

      const imageLabelingDatas = data?.previousJobData || [];
      const previousJobAnnotations = imageLabelingDatas.map((data) =>
        batchLabelingUtils.labelingDataToJobAnnotation(
          data as ImageLabelingData
        )
      );

      imageLabeling.findingLabelers = imageAnnotationUtils.getFindingLabelers(
        previousJobAnnotations,
        imageLabeling.labels
      );

      imageLabeling.agreements = imageAnnotationUtils.getFindingAgreements(
        previousJobAnnotations
      );

      imageLabeling.labels = imageLabeling.labels.map((lb) => {
        const selected = !!imageAnnotations.annotations.find((anno) => {
          return anno.jobId === jobId && anno.labelId === lb.id;
        });
        return { ...lb, selected };
      });
    }
  );
}
