import clsx from "clsx";
import { MouseEvent, ReactNode, useRef, useState } from "react";
import { VBSpinner } from "./vb-spinner/vb-spinner.component";
import { debounce } from "lodash";
import { useAppDispatch } from "hooks/use-redux";
import { enqueueWarningNotification } from "store/common/notification/notification.actions";

interface ButtonWithLoadingProps {
  onClick?(event: MouseEvent): void;
  text?: string;
  textClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  variant?: "fill" | "outline" | "link" | "text";
  type?: "submit" | "reset" | "button" | undefined;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  form?: string;
  debounceDelay?: number;
  clickLimit?: number;
  children?: ReactNode;
}

export function ButtonWithLoading({
  text,
  onClick,
  loading = false,
  disabled = false,
  className,
  textClassName,
  variant = "fill",
  type = "button",
  startIcon,
  endIcon,
  form,
  debounceDelay = 300,
  clickLimit = 3,
  children,
}: ButtonWithLoadingProps) {
  const [isBlocked, setIsBlocked] = useState(false);
  const clickCount = useRef(0);
  const dispatch = useAppDispatch();

  const debounceCheckClick = debounce(() => {
    clickCount.current = 0;
    setIsBlocked(false);
  }, debounceDelay);

  const handleClick = (event: MouseEvent) => {
    if (isBlocked || disabled || loading) {
      return;
    }

    if (clickCount.current >= clickLimit) {
      dispatch(
        enqueueWarningNotification("You are doing this too many times!")
      );
      setIsBlocked(true);
      debounceCheckClick();
      return;
    }

    clickCount.current += 1;
    debounceCheckClick();

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      form={form}
      type={type}
      className={clsx(
        "relative min-h-9 flex items-center justify-center gap-1.5 font-medium px-4 py-2 rounded-md",
        {
          "pointer-events-none opacity-60 cursor-not-allowed":
            loading || disabled || isBlocked,
          "bg-primary text-white hover:opacity-80":
            variant === "fill" && !loading,
          "bg-warning-50 text-warning-500 hover:opacity-80":
            variant === "outline" && !loading,
          "bg-gray-400 text-gray-600 cursor-not-allowed": disabled && !loading,
        },
        className
      )}
      onClick={handleClick}
      disabled={loading || disabled}
    >
      {startIcon && <span>{startIcon}</span>}
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <VBSpinner className="text-white" />
        </div>
      )}
      {text && <span className={clsx(textClassName)}>{text}</span>}
      {children}
      {endIcon && <span>{endIcon}</span>}
    </button>
  );
}
