/*
 * File: file-browse.context.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 5:04:03 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { defaultFileBrowseState } from "./file-browse.state";

export const FileBrowseContext = createContext(defaultFileBrowseState);

export function useFileBrowseContext() {
  return useContext(FileBrowseContext);
}
