/*
 * File: vb-datagrid-input-date.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th September 2021 11:53:30 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { MouseEvent } from "react";

interface Props {
  date: string | undefined;
  onDateChange(date: string): void;
}

export const VBDatagridInputDate = ({ date, onDateChange }: Props) => {
  function handleCloseClick(event: MouseEvent) {
    event.stopPropagation();
    onDateChange("");
  }
  return (
    <div className="flex">
      <input
        type="date"
        className="w-full h-6 px-2 py-4 border rounded focus:border-primary"
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => onDateChange(e.target.value)}
        value={date}
      />
      {date && (
        <button className="mx-2" onClick={handleCloseClick}>
          <i className="uir-cross"></i>
        </button>
      )}
    </div>
  );
};
