/*
 * File: vb-spacer.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 8th July 2021 3:48:43 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

export const VBSpacer = () => {
  return <div className="flex-1"></div>;
};
