import VBImage from "components/common/vb-image/vb-image.component";
import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { Fragment, useMemo, useState } from "react";
import { TaskDataDTO } from "../../../../services/label-service/dtos/task.dto";
import { BatchObservationDTO } from "../../../../services/label-service/dtos/batch-observation.dto";
import PreviewAnnotationsComponent from "components/preview-annotations/preview-annotations.component";

export interface ImageWithAnnotationItem {
  name: string;
  mediaId: number;
  taskData: TaskDataDTO;
}

interface Props {
  labels: BatchObservationDTO[];
  items: ImageWithAnnotationItem[];
  hideFilter?: boolean;
}

export const RunInfoImageItems = ({ labels, items, hideFilter }: Props) => {
  const [viewItem, setViewItem] = useState<ImageWithAnnotationItem>();
  const [showGroundTruth, setShowGroundTruth] = useState(true);
  const [showPredict, setShowPredict] = useState(true);

  const showItems = useMemo(() => {
    return items.map((item) => {
      return {
        ...item,
        taskData: {
          ...item.taskData,
          annotations: (item.taskData.annotations || []).map((anno) => {
            const visible =
              (showGroundTruth && anno.source === "GroundTruth") ||
              (showPredict && anno.source === "Predict");
            return {
              ...anno,
              hidden: !visible,
            };
          }),
        },
      };
    });
  }, [items, showGroundTruth, showPredict]);

  return (
    <Fragment>
      {!hideFilter && (
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={showGroundTruth}
              onChange={(e) => setShowGroundTruth(e.target.checked)}
            />
            <span>Ground truth</span>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={showPredict}
              onChange={(e) => setShowPredict(e.target.checked)}
            />
            <span>Predict</span>
          </div>
        </div>
      )}
      <div className="relative grid grid-cols-2 gap-3 lg:grid-cols-5 md:grid-cols-3">
        {showItems.map((item) => (
          <div key={item.mediaId} className="aspect-w-4 aspect-h-3">
            <div className="absolute top-0 left-0 w-full h-full">
              <RunInfoImageItem
                item={item}
                onClick={(item) => setViewItem(item)}
                labels={labels}
              />
            </div>
          </div>
        ))}
      </div>
      {!!viewItem && (
        <VBModal
          width="80rem"
          open={!!viewItem}
          title={viewItem.name}
          onClose={() => setViewItem(undefined)}
          footerHidden
        >
          <RunInfoImageItemDetail
            hideFilter={hideFilter}
            item={viewItem}
            labels={labels}
          />
        </VBModal>
      )}
    </Fragment>
  );
};

interface RunInfoImageItemDetailProps {
  item: ImageWithAnnotationItem;
  labels: BatchObservationDTO[];
  hideFilter?: boolean;
}
function RunInfoImageItemDetail({
  labels,
  item,
  hideFilter,
}: RunInfoImageItemDetailProps) {
  const [showGroundTruth, setShowGroundTruth] = useState(true);
  const [showPredict, setShowPredict] = useState(true);

  const viewItem = useMemo(() => {
    return {
      ...item,
      taskData: {
        ...item.taskData,
        annotations: (item.taskData.annotations || []).map((anno) => {
          const visible =
            (showGroundTruth && anno.source === "GroundTruth") ||
            (showPredict && anno.source === "Predict");
          return {
            ...anno,
            hidden: !visible,
          };
        }),
      },
    };
  }, [item, showGroundTruth, showPredict]);
  return (
    <div style={{ maxHeight: "80vh" }}>
      {!hideFilter && (
        <div className="flex items-center gap-6 pb-4">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={showGroundTruth}
              onChange={(e) => setShowGroundTruth(e.target.checked)}
            />
            <span>Ground truth</span>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={showPredict}
              onChange={(e) => setShowPredict(e.target.checked)}
            />
            <span>Predict</span>
          </div>
        </div>
      )}
      <RunInfoImageItem type="full" labels={labels} item={viewItem} />
    </div>
  );
}

interface RunInfoImageItemProps {
  item: ImageWithAnnotationItem;
  labels: BatchObservationDTO[];
  type?: "thumbnail" | "full";
  onClick?: (v: ImageWithAnnotationItem) => void;
}
export const RunInfoImageItem = ({
  labels,
  item,
  onClick,
  type = "thumbnail",
}: RunInfoImageItemProps) => {
  let url = item.taskData.image?.thumbnailUrl;
  if (type === "full") {
    url = item.taskData.image?.url ?? item.taskData.image?.thumbnailUrl;
  }

  return (
    <div
      className="relative w-full h-full overflow-hidden bg-black border rounded cursor-pointer"
      onClick={() => onClick && onClick(item)}
    >
      <VBImage
        requireToken
        loadStyle="skeleton"
        src={url}
        mask={
          <PreviewAnnotationsComponent labels={labels} data={item.taskData} />
        }
      />
    </div>
  );
};
