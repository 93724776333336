/*
 * File: delete-workspace.modal.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 4th April 2023 10:18:20 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { useState } from "react";
import * as Sentry from "@sentry/react";
import { Logger } from "utilities/logger";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { getErrMessage } from "utilities/errors/errors";
import { useAppDispatch } from "hooks/use-redux";
import { deleteWorkspaceAsync } from "store/common/user-workspace/user-workspace.thunk";
import { useTranslation } from "react-i18next";

interface Props {
  workspaceId: string;
  workspaceName: string;
  onClose(): void;
}

export function DeleteWorkspaceModal({
  workspaceId,
  workspaceName,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [requesting, setRequesting] = useState(false);
  async function handleDeleteWorkspace() {
    try {
      if (requesting) return;
      setRequesting(true);
      await dispatch(deleteWorkspaceAsync(workspaceId));
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
      setRequesting(false);
      onClose();
    } catch (error) {
      setRequesting(false);
      const defaultMessage = "Failed to delete workspace";
      const errMessage = getErrMessage(error, defaultMessage);
      enqueueErrorNotification(errMessage);
      Sentry.captureException(error);
      Logger.log(error);
    }
  }
  return (
    <VBModal
      open
      title="Delete workspace"
      onClose={onClose}
      onSubmit={handleDeleteWorkspace}
      disableSubmit={requesting}
      processingIndicator={requesting}
      textSubmit={t("common:buttonSubmit")}
    >
      <div>
        This action can't be undo. Are you sure you want to delete{" "}
        <strong>{workspaceName}</strong>?.
      </div>
    </VBModal>
  );
}

export default DeleteWorkspaceModal;
