/*
 * File: image-save-job.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 5th August 2022 9:53:19 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import {
  AnnotationsService,
  LabelerAnnotationService,
  LabelerJobServiceV3,
} from "services/label-service";
import {
  AnnotationVoteRequest,
  AnnotationVoteStatus,
} from "services/label-service/dtos/annotation.dto";
import { AnnotationJobRequestDTO } from "services/label-service/dtos/annotations.dto";
import { RootState } from "store";
import { annotationUtils } from "utilities/annotations/annotation-util";
import {
  selectJobAnnotationsForSaving,
  selectJobRelationAnnotationsForSaving,
} from "../../image-annotations/image-annotations.selectors";
import { selectImageLabelingJobById } from "../batch-labeling.selectors";
import { BatchLabelingState, JobStatus } from "../batch-labeling.state";

const THUNK_NAME = "batch-labeling/imageSaveJobAsync";

export const imageSaveJobAsync = createAsyncThunk(
  THUNK_NAME,
  async (
    {
      jobIds,
      finish = false,
      isReviewJob = false,
    }: {
      jobIds: number[];
      finish?: boolean;
      isReviewJob?: boolean;
    },
    { getState }
  ) => {
    const state = getState() as RootState;

    let jobsAnnoRequest: {
      jobId: number;
      annoRequest: AnnotationJobRequestDTO;
    }[] = [];

    for (const jobId of jobIds) {
      const imageLabelingJob = selectImageLabelingJobById(jobId)(state);
      const { job } = imageLabelingJob;
      const annotations = selectJobAnnotationsForSaving(
        jobId,
        job.assignee
      )(state);
      const relationAnnotations = selectJobRelationAnnotationsForSaving(
        jobId,
        job.assignee
      )(state);
      const annoRequest = annotationUtils.toAnnotationJobRequestDTO(
        jobId,
        annotations,
        relationAnnotations
      );
      jobsAnnoRequest.push({
        jobId,
        annoRequest,
      });
    }

    const callVoteAnnotationsAPI = async () => {
      const promises: Promise<AxiosResponse<any>>[] = [];
      jobsAnnoRequest.forEach(({ jobId, annoRequest }) => {
        const annotaionVotes: AnnotationVoteRequest[] =
          annoRequest.annotations.map((annotation) => ({
            annotationId: annotation.localId || -1,
            status: AnnotationVoteStatus.UP,
          }));
        const request = LabelerAnnotationService.voteAnnotations(
          jobId,
          annotaionVotes
        );
        promises.push(request);
      });

      await Promise.all(promises);
    };

    const saveJobs = async () => {
      const promises: Promise<AxiosResponse<any>>[] = [];
      for (const jobAnnoReuqest of jobsAnnoRequest) {
        promises.push(
          AnnotationsService.saveAnnotationsForJobId(
            jobAnnoReuqest.jobId,
            jobAnnoReuqest.annoRequest
          )
        );
      }
      await Promise.all(promises);
    };

    const finishJobs = async () => {
      const promises: Promise<AxiosResponse<any>>[] = [];
      for (const jobAnnoReuqest of jobsAnnoRequest) {
        promises.push(LabelerJobServiceV3.finishJob(jobAnnoReuqest.jobId));
      }
      await Promise.all(promises);
    };

    if (isReviewJob) {
      await callVoteAnnotationsAPI();
      await saveJobs();
      if (finish) {
        await finishJobs();
      }
    } else {
      await saveJobs();
      if (finish) {
        await finishJobs();
      }
    }

    return jobIds;
  }
);

export const imageSaveJobReducerBuilder = (
  builder: ActionReducerMapBuilder<BatchLabelingState>
) => {
  return builder.addCase(imageSaveJobAsync.fulfilled, (state, action) => {
    action.payload.forEach((jobId) => {
      if (state.jobStatuses.hasOwnProperty(jobId)) {
        state.jobStatuses[jobId] = JobStatus.SAVED;
      }
    });
  });
};
