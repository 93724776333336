/*
 * File: metadata-filter.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd July 2023 2:50:08 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VBSelectComponent } from "components/design-system/select-input/select.component";
import { Filter, Option } from "domain/common";
import { QueryApi } from "data-access/impl/query";
import useMetadataKeyValueOptions from "../../../hooks/use-metadata-key-value-options";
import { capitalizeFirstLetter } from "utilities/string/capitalize";
import { useMemo } from "react";
interface Props {
  filter: Filter;
  onFilterChange(filter: Filter): void;
  metadataKey: Option;
}

export function MetadataPairFilter({
  filter,
  onFilterChange,
  metadataKey,
}: Props) {
  const metadataKeyId = useMemo(() => {
    return metadataKey?.value ? parseInt(metadataKey.value) : -1;
  }, [metadataKey]);
  const defaultValues = useMemo<string[]>(() => {
    const fileFilter = new QueryApi.FileFilter(filter);
    const metadatas = fileFilter.getArrayQuery(
      `metadata.${metadataKey.metadata.code}`
    );
    return metadatas
      .map((item) => item.value)
      .filter((val) => !!val) as string[];
  }, [metadataKey, filter]);
  const { options, selectedOptions, onChange } = useMetadataKeyValueOptions(
    metadataKeyId,
    defaultValues
  );

  return (
    <div className="px-4 pb-2">
      <VBSelectComponent
        header={capitalizeFirstLetter(metadataKey.label)}
        placeholder="Select option"
        value={selectedOptions}
        options={options}
        menuPortalTarget={document.body}
        isClearable
        isMulti
        size="none"
        onChange={(newOption) => {
          onChange(newOption);
          let values: string[] = [];
          if (newOption) {
            values = (newOption as Option[]).map((option) => option.value);
          }
          const fileFilter = new QueryApi.FileFilter(filter);
          fileFilter.setArrayQuery(
            `metadata.${metadataKey.metadata.code}`,
            values
          );
          onFilterChange(fileFilter.toFilter());
        }}
      />
    </div>
  );
}
