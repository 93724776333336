/*
 * File: global-create.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 25th July 2022 10:07:13 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useClickAway } from "ahooks";
import classnames from "classnames";
import { IconArrowDown } from "components/common/vb-icon.component";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";
import { useAppSelector } from "hooks/use-redux";
import { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { selectCurrentUser } from "store/auth/auth.selectors";
import { GlobalRole } from "store/auth/auth.state";

interface Props {
  open?: boolean;
}
interface CreationItem {
  key: string;
  title: string;
  onClick?: () => void;
}

export default function GlobalCreate({ open: defaultOpen = false }: Props) {
  const workspaceRef = useRef<HTMLButtonElement>(null);
  const history = useHistory();
  const [open, setOpen] = useState(defaultOpen);
  const { openModal } = useModalContext();
  const currentUser = useAppSelector(selectCurrentUser);

  const creationOptions = useMemo(() => {
    const isGlobalAdmin = currentUser?.globalRoles?.includes(
      GlobalRole.GLOBAL_ADMIN
    );
    const items: CreationItem[] = [
      {
        key: "datasource",
        title: "Datasource",
        onClick: () => openModal(ModalTypes.CREATE_DATASOURCE),
      },
      {
        key: "project",
        title: "Project",
        onClick: () => history.push("/projects/template"),
      },
      {
        key: "workflow",
        title: "Workflow",
        onClick: () => history.push("/workflows/create"),
      },
    ];
    if (isGlobalAdmin) {
      items.unshift({
        key: "workspace",
        title: "Workspace",
        onClick: () => openModal(ModalTypes.CREATE_WORKSPACE),
      });
    }
    return items;
  }, [history, currentUser, openModal]);

  useClickAway(() => setOpen(false), [workspaceRef]);

  return (
    <div className="relative">
      <button
        ref={workspaceRef}
        onClick={() => setOpen(!open)}
        className="button-primary"
      >
        <span className="hidden md:block">Create</span>
        <IconArrowDown className="flex-none w-6 h-6" />
      </button>

      <div
        className={classnames(
          "absolute left-0 top-full z-10 bg-white rounded shadow-lg transition-all transform",
          {
            "opacity-0 pointer-events-none h-0": !open,
            "translate-y-4 opacity-1": !open,
          }
        )}
      >
        <div className="w-48 py-2">
          {creationOptions.map((option) => (
            <button
              key={option.key}
              className="block w-full px-4 py-2 text-left hover:bg-background-100"
              onClick={option.onClick}
            >
              {option.title}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
