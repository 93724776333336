/*
 * File: project-performance-tab.component.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 13th September 2021 9:46:28 am
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { useState } from "react"
import { DistributionTabs, DistributionView } from "../../project-batch/batch-detail/pages/overview/components/performance/distribution-tabs.component"
import { ProjectLabelDistribution } from "./project-label-distribution.component"
import { ProjectLabelerDistribution } from "./project-labeler-distribution.component"

export const ProjectPerformance = () => {
  const [view, setView] = useState(DistributionView.LABEL)
  return (
    <div className="mt-6">
      <DistributionTabs selectedView={view} onChange={setView} />
      {view === DistributionView.LABELER && <ProjectLabelerDistribution />}
      {view === DistributionView.LABEL && <ProjectLabelDistribution />}
    </div>
  )
}