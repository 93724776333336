/*
 * File: batch-detail-actions.component.tsx
 * Project: app-aiscaler-web
 * File Created: Wednesday, 1st February 2023 2:40:59 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { BatchDTO, BatchStatus } from "services/label-service/dtos";
import UploadLabelersCSV from "./upload-labelers-csv.component";

interface BatchDetailActionsProps {
  batch: BatchDTO;
}
export function BatchDetailActions({ batch }: BatchDetailActionsProps) {
  const isIntial = batch.status === BatchStatus.INITIAL;
  if (isIntial) return <UploadLabelersCSV batch={batch} />;
  return null;
}

export default BatchDetailActions;
