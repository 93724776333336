/*
 * File: mouse-ruler.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 21st April 2022 10:19:13 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { useAppSelector } from "hooks/use-redux";
import { useRef, useState, useEffect, MouseEvent } from "react";
import { selectActiveCornerstoneTool } from "store/labeler/image-workspace/dicom-editor/dicom-editor.selectors";
import { selectIsShowBBoxRuer } from "store/labeler/image-workspace/editor-setting/editor-setting.selectors";
import { ToolName } from "../dicom-tools/dicom-tools.model";

export const MouseRuler = () => {
  const showBBoxRuler = useAppSelector(selectIsShowBBoxRuer);
  const currentTool = useAppSelector(selectActiveCornerstoneTool);
  const ref = useRef<HTMLCanvasElement>(null);
  const [size, setSize] = useState({ width: 900, height: 600 });

  function handleMouseMove(event: MouseEvent) {
    if (!ref.current) return;
    const rect = ref.current.getBoundingClientRect();
    const context = ref.current.getContext("2d");
    if (!context) return;
    const scaleX = context.canvas.width / rect.width;
    const scaleY = context.canvas.height / rect.height;
    const position = {
      x: (event.clientX - rect.left) * scaleX,
      y: (event.clientY - rect.top) * scaleY,
    };
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    context.lineWidth = 1;
    context.globalAlpha = 0.6;
    context.strokeStyle = "#FFFFFF";
    context.setLineDash([]);
    context.beginPath();
    context.moveTo(position.x, 0);
    context.lineTo(position.x, context.canvas.height);
    context.stroke();

    context.beginPath();
    context.moveTo(0, position.y);
    context.lineTo(context.canvas.width, position.y);
    context.stroke();

    context.strokeStyle = "#000000";
    context.beginPath();
    context.setLineDash([4, 4]);
    context.moveTo(position.x, 0);
    context.lineTo(position.x, context.canvas.height);
    context.stroke();

    context.beginPath();
    context.moveTo(0, position.y);
    context.lineTo(context.canvas.width, position.y);
    context.stroke();
    context.globalAlpha = 1;
  }
  useEffect(() => {
    if (ref.current?.parentElement) {
      const parent = ref.current.parentElement;
      if (parent) {
        const rect = parent.getBoundingClientRect();
        setSize({ width: rect.width, height: rect.height });
      }
    }
  }, [ref]);

  if (currentTool !== ToolName.RectangleRoiExtend) return null;
  if (!showBBoxRuler) return null;

  return (
    <canvas
      ref={ref}
      width={size.width}
      height={size.height}
      onMouseMove={handleMouseMove}
      className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
      style={{ background: "transparent", scale: 1 }}
    />
  );
};
