/*
 * File: smart-labeling.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th December 2021 4:42:32 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "store";

export const selectTextAIEnabled = (state: RootState) => {
  return state.textAI.enable;
};

export const selectTextAIStatus = (state: RootState) => {
  return state.textAI.status;
};

export const selectTextAIJobId = (state: RootState) => {
  return state.textAI.jobId;
};

export const selectTextAIData = (state: RootState) => {
  return state.textAI;
};
