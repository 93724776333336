/*
 * File: create-batch.model.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { ObservationDTO } from "services/label-service/dtos";

export enum StepStatus {
  COMPLETED = "completed",
  CURRENT = "current",
  DEFAULT = "default",
}

export enum BatchSteps {
  GENERAL = 0,
  LABELS = 1,
  DATASOURCE = 2,
  WORKFLOW = 3,
  MEMBERS = 4,
  UNIT_PRICE = 5,
}

export interface StepInfo {
  title: string;
  description?: string;
  type: BatchSteps;
  data: any;
  defaultData?: any;
  require?: boolean;
  isStepCompleted?: () => boolean;
}
export interface WorkflowOption {
  id: string;
  name: string;
  description?: string;
  selected: boolean;
}

export const DEFAULT_OBSERVATIONS: Partial<ObservationDTO>[] = [];

export const DEFAULT_STEPS: StepInfo[] = [
  {
    type: BatchSteps.GENERAL,
    title: "General information",
    data: null,
    require: true,
  },
  {
    type: BatchSteps.LABELS,
    title: "Configure batch labels",
    data: null,
    defaultData: [],
    require: true,
  },
  {
    type: BatchSteps.DATASOURCE,
    title: "Select datasource",
    data: null,
    require: true,
  },
  {
    type: BatchSteps.WORKFLOW,
    title: "Configure workflow",
    data: null,
    require: true,
  },
  {
    type: BatchSteps.MEMBERS,
    title: "Add members",
    data: null,
    require: true,
  },
  {
    type: BatchSteps.UNIT_PRICE,
    title: "Unit price",
    data: null,
    require: false,
  },
];
