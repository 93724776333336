import moment from "moment";
import {
  IssueCommentModel,
  IssueModel,
} from "pages/customer/projects/project-batch/batch-detail/pages/tasks/components/issue/issue-management.models";
import {
  ImageIssueData,
  IssueCommentRequestDTO,
  IssueCommentResponseDTO,
  IssueRequestDTO,
  IssueResponseDTO,
  UpdateIssueRequestDTO,
} from "services/label-service/dtos/issue.dto";

export const mapperIssueComment = {
  fromIssueCommentDTO(res: IssueCommentResponseDTO): IssueCommentModel {
    const comment: IssueCommentModel = {
      id: 0,
      issueId: res.issueId,
      user: res.createdBy,
      content: res.content,
      createdTime: moment(new Date(res.createdDate)).local().toDate(),
    };
    return comment;
  },

  toIssueCommentDTO(comment: IssueCommentModel): IssueCommentRequestDTO {
    const dto: IssueCommentRequestDTO = {
      issueId: comment.issueId,
      content: comment.content,
    };
    return dto;
  },
};

export const mapperIssueModel = {
  toIssueRequestDTO(issue: Partial<IssueModel>): IssueRequestDTO {
    const dto = {
      jobId: issue.jobId || 0,
      description: "",
      data: {
        x: issue.posX || 0,
        y: issue.posY || 0,
        frameId: issue.frameId,
      },
    };
    return dto;
  },

  toIssueUpdateRequestDTO(issue: IssueModel): UpdateIssueRequestDTO {
    const dto = {
      description: "",
      data: {
        x: issue.posX,
        y: issue.posY,
        frameId: issue.frameId,
      },
    };
    return dto;
  },

  fromIssueResonseDTO(res: IssueResponseDTO): IssueModel {
    const issue: IssueModel = {
      id: res.id,
      jobId: res.jobId,
      taskId: res.taskId,
      status: res.status,

      posX: (res.data as ImageIssueData).x,
      posY: (res.data as ImageIssueData).y,
      frameId: (res.data as ImageIssueData).frameId,

      comments: res.comments
        ? res.comments.map(mapperIssueComment.fromIssueCommentDTO)
        : [],
    };

    return issue;
  },
};
