/*
 * File: project-templates.page.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 25th July 2022 10:45:22 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import classNames from "classnames";
import { VBBreadcrumb } from "components/common/vb-breadcrumb/vb-breadcrumb.component";
import { VBInlineLoading } from "components/common/vb-inline-loading.component";
import { VBTextInputComponent } from "components/design-system/text-input/text-input.component";
import { useRouteMatch } from "react-router-dom";
import { Routes } from "routers/config/routes";
import ProjectTemplateCard from "./project-template-card/project-template-card.component";
import useProjectTemplates from "./hooks/use-project-templates.hook";
import useProjectTemplateFilter from "./use-template-filter.hook";
import { ChangeEvent, useState } from "react";

export default function ProjectTemplatesPage() {
  const { path } = useRouteMatch();
  const breadcrumbs = [
    { url: Routes.PROJECTS, text: "Projects" },
    { url: path, text: "Template", disabled: true },
  ];
  const { templates, isLoading } = useProjectTemplates();
  const [search, setSearch] = useState("");
  const { filteredTemplates, options, type, selectType } =
    useProjectTemplateFilter(templates, search);

  function handleInput(event: ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  return (
    <div className="p-6">
      <VBBreadcrumb breadcrumbs={breadcrumbs} />
      <div className="flex items-center gap-4 py-4">
        <VBTextInputComponent
          placeholder="Search template"
          onInput={handleInput}
        />
        {options.map((option) => {
          return (
            <button
              className={classNames("px-4 py-2", {
                "text-primary": type === option.value,
              })}
              onClick={() => selectType(option.value)}
              key={option.value}
            >
              {option.label}
            </button>
          );
        })}
      </div>
      {isLoading && <VBInlineLoading />}
      {!isLoading && search && filteredTemplates.length === 0 && (
        <div className="p-10 text-center">No results found.</div>
      )}
      {!isLoading && (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5">
          {filteredTemplates.map((template) => {
            return <ProjectTemplateCard key={template.id} {...template} />;
          })}
        </div>
      )}
    </div>
  );
}
