/*
 * File: group.api.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 29th July 2021 3:34:21 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { UserAPI } from "../http/user-service.httpclient";
import { UserAPIConfig } from "../config/user-api.config";
import { GroupDTO } from "../dtos/group.dto";

export function getAllGroups() {
  return UserAPI.get(UserAPIConfig.paths.group);
}

export function createGroup(group: GroupDTO) {
  const data = JSON.stringify(group);
  return UserAPI.post(UserAPIConfig.paths.group, data);
}

export function deleteGroup(groupId: string) {
  return UserAPI.delete(UserAPIConfig.paths.group, {
    data: { groupId },
  });
}

export function selectWorkspace(workspaceId: string) {
  const data = JSON.stringify({ id: workspaceId });
  return UserAPI.post(`${UserAPIConfig.paths.group}/select`, data);
}
