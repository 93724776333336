import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useState, useEffect, useMemo } from "react";
import {
  BatchObservationCreationModel,
  BatchSetting,
  BatchStatus,
  DEFAULT_TIME_LIMIT_SECOND,
  StepType,
  WorkflowDTO,
} from "services/label-service/dtos";
import { DEFAULT_DAILY_LIMIT } from "services/user-service/dtos/user.dto";
import {
  loadBatchDataAsync,
  updateBatchManagementAsync,
} from "store/customer/batch/batch.thunk";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import { BatchWorkflowLoading } from "./components/batch-workflow-loading.component";
import { WorkflowSelector } from "./components/workflow-selector.component";
import { LabelSegmentationSlider } from "./components/label-segmentation-slider.component";
import { useWorkflowInstructions } from "hooks/workflow/use-workflow-instructions.hook";
import { useWorkflow } from "hooks/workflow/use-workflow.hook";
import { useStepConditions } from "hooks/workflow/use-step-conditions.hook";
import { RequestStatus } from "store/base/base.state";
import { VBSpinner } from "components/common/vb-spinner/vb-spinner.component";
import { AgreementRateSlider } from "./components/agreement-rate-slider.component";
import {
  selectIsImageProject,
  selectIsMdiSegmentation,
  selectIsProjectFromTemplate,
  selectIsTextProject,
} from "store/customer/project/project.selectors";
import { selectRequireTextConsensus } from "store/customer/workflow/workflow.selectors";
import useDeepCompareEffect from "use-deep-compare-effect";
import { selectPaymentCurrencySelectOptions } from "store/common/payment/payment.selectors";
import {
  PriceByType,
  PricingCalculationStep,
  PricingRequestDTO,
} from "services/label-service/dtos/batch-pricing.dto";
import { useBatchTaskContext } from "../tasks/batch-tasks.context";
import { isValidNumber } from "utilities/number/handle-invalid-number";
import { ProjectWorkflowSteps } from "pages/customer/projects/components/project-workflow/project-workflow-steps.component";
import { ProjectWorkflowAssignLabeler } from "pages/customer/projects/components/project-workflow/project-workflow-assign-labeler.component";
import {
  StepAssignee,
  StepData,
  StepReviewConfig,
} from "pages/customer/projects/project.type";
import { useBatchWorkflow } from "../../../../hooks/use-batch-workflow";
import { ProjectWorkflowReviewConfig } from "pages/customer/projects/components/project-workflow/project-workflow-review-config.component";
import { useStepConfigReview } from "pages/customer/projects/hooks/use-step-config-review.hook";
import { useStepAssigness } from "pages/customer/projects/hooks/use-step-assignees.hook";
import { useStepConfigAssignees } from "pages/customer/projects/hooks/use-step-config-assignees.hook";
import classnames from "classnames";
import { useBatchDetailContext } from "../../context/batch-detail.context";
import * as Sentry from "@sentry/react";

export const BatchWorkflowPage = () => {
  const { t } = useTranslation();
  const isImageProject = useAppSelector(selectIsImageProject);
  const isMdiProject = useAppSelector(selectIsMdiSegmentation);
  const isTextProject = useAppSelector(selectIsTextProject);

  const dispatch = useAppDispatch();
  const {
    batch,
    workflow: batchWorkflow,
    labels: batchLabels,
  } = useBatchDetailContext();

  const { workflows } = useWorkflow();
  useStepConditions(true);

  const [selectedWorkflowId, setSelectedWorkflowId] = useState(
    batchWorkflow ? batchWorkflow.workflow.id : -1
  );
  const instructionHook = useWorkflowInstructions(selectedWorkflowId);
  const { workflow, instructions, isRequireImageConsensus } = instructionHook;
  const currencyOptions = useAppSelector(selectPaymentCurrencySelectOptions);
  const isProjectFromTemplate = useAppSelector(selectIsProjectFromTemplate);
  const [disableChangeWorkflow, setDisableChangeWorkflow] = useState(true);
  const [disableUpdate, setDisableUpdate] = useState(true);
  const [labels, setLabels] = useState<BatchObservationCreationModel[]>(() => {
    return batchLabels.map((bl) => ({
      observation: bl.observation,
      labelRequired: bl.labelRequired,
      probabilityRequired: bl.probabilityRequired,
      iou: bl.iou,
      visible: true,
      showChildren: true,
      attributeAgreement: bl.attributeAgreement,
    }));
  });

  useBatchTaskContext();
  const [consensusRate, setConsensusRate] = useState(-1);
  const [updateStatus, setUpdateStatus] = useState(RequestStatus.IDLE);

  const {
    steps,
    selectedStep,
    selectedStepIndex,
    setSelectedStepIndex,
    onChangeSteps,
    onChangeStep,
  } = useBatchWorkflow();

  const { config: reviewConfig, onChange: onChangeReviewConfig } =
    useStepConfigReview(selectedStep);
  const { config: assigneesConfig } = useStepConfigAssignees(selectedStep);
  const { allEmails, selectedEmails } = useStepAssigness(selectedStep);

  const [consensusEntity, setConsensusEntity] = useState(() => {
    if (!batch || !batch.batchSetting?.consensusEntity) return 0;
    return batch.batchSetting.consensusEntity;
  });

  const [consensusRelationship, setConsensusRelationship] = useState(() => {
    if (!batch || !batch.batchSetting?.consensusRelationship) return 0;
    return batch.batchSetting.consensusRelationship;
  });

  const requireTextConsensusRules = useAppSelector(
    selectRequireTextConsensus(instructions)
  );

  const disabledButtonUpdate = useMemo(() => {
    return (
      steps.findIndex(
        (step) => !step || !step.assignees || !step.assignees.length
      ) !== -1
    );
  }, [steps]);

  const isAcceptanceStep = useMemo(() => {
    return selectedStep?.type === StepType.ACCEPTANCE;
  }, [selectedStep]);

  function handleWorkflowChange(workflow: WorkflowDTO | null) {
    setSelectedWorkflowId(workflow ? workflow.id : -1);
  }

  function handleConsensusRateChange(newValue: number) {
    setConsensusRate(newValue);
  }

  // update pricing if change workflow
  function getDefaultPricing(): PricingRequestDTO | undefined {
    if (currencyOptions.length <= 0) return undefined;
    if (!instructions || instructions.length <= 0) return undefined;

    const steps: PricingCalculationStep[] = [];
    let stepIndex = 1;
    for (const instruction of instructions) {
      const step: PricingCalculationStep = {
        rounds: [],
        stepNumber: stepIndex,
      };
      for (
        let roundIndex = 1;
        roundIndex <= instruction.roundNumber;
        roundIndex++
      ) {
        step.rounds.push({
          roundNumber: roundIndex,
          perJob: 0,
        });
      }
      steps.push(step);
      stepIndex++;
    }

    const payload: PricingRequestDTO = {
      type: PriceByType.BY_JOB,
      currencyId: currencyOptions[0].value as number,
      perTask: 0,
      steps,
    };
    return payload;
  }

  async function handleUpdate() {
    if (!batch || updateStatus === RequestStatus.LOADING) return;
    try {
      setUpdateStatus(RequestStatus.LOADING);
      const userInstructions = [];

      for (let step of steps) {
        if (!step || !step.assignees) return;
        for (let labeler of step.assignees) {
          const dailyLimit =
            !labeler.dailyLimit ||
            labeler.dailyLimit < 0 ||
            isNaN(labeler.dailyLimit)
              ? DEFAULT_DAILY_LIMIT
              : labeler.dailyLimit;

          userInstructions.push({
            userId: labeler.userId,
            workInstructionId: labeler.workInstructionId,
            acceptPercentage: labeler.acceptPercentage,
            percentage: labeler.percentage,
            reviewPickType: labeler.reviewPickType,
            dailyLimit,
            limit: isValidNumber(labeler.limit) ? labeler.limit : undefined,
          });
        }
      }

      let updateBatchData;

      const serverConsensusRate = parseFloat((consensusRate / 100).toFixed(2));
      const batchSetting: BatchSetting = {
        consensusEntity: consensusEntity,
        consensusRelationship: consensusRelationship,
        consensusRate: serverConsensusRate,
        timeLimitSecond: batch.batchSetting
          ? batch.batchSetting.timeLimitSecond
          : DEFAULT_TIME_LIMIT_SECOND,
      };

      if (!requireTextConsensusRules.entity) {
        delete batchSetting.consensusEntity;
      }
      if (!requireTextConsensusRules.relation) {
        delete batchSetting.consensusRelationship;
      }

      if (workflow.id !== batchWorkflow?.workflow.id) {
        const pricing = getDefaultPricing();
        updateBatchData = {
          batchId: batch.id,
          payload: {
            workManagement: userInstructions,
            workflowId: workflow.id,
            batchObservation: labels.map((label) => {
              return {
                iou: label.iou,
                labelRequired: label.labelRequired,
                probabilityRequired: label.probabilityRequired,
                observationId: label.observation.id,
                attributeAgreement: label.attributeAgreement,
              };
            }),
            batch: {
              batchSetting: batchSetting,
            },
            pricing,
          },
        };
      } else {
        updateBatchData = {
          batchId: batch.id,
          payload: {
            workManagement: userInstructions,
            batchObservation: labels.map((label) => {
              return {
                iou: label.iou,
                labelRequired: label.labelRequired,
                probabilityRequired: label.probabilityRequired,
                observationId: label.observation.id,
                attributeAgreement: label.attributeAgreement,
              };
            }),
            batch: {
              batchSetting: batchSetting,
            },
          },
        };
      }

      const response = await dispatch(
        updateBatchManagementAsync(updateBatchData)
      );

      handleThunkRejected(response);
      const fetchResponse = await dispatch(
        loadBatchDataAsync(batch.id.toString())
      );
      handleThunkRejected(fetchResponse);
      dispatch(enqueueSuccessNotification(t("common:textUpdatedSuccess")));
      setUpdateStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      Sentry.captureException(error);
      const errMessage = error.message || t("common:textUpdatedFailed");
      dispatch(enqueueErrorNotification(errMessage));
      setUpdateStatus(RequestStatus.FAILURE);
    }
  }

  useEffect(() => {
    if (batch)
      setConsensusRate(
        batch.batchSetting ? batch.batchSetting.consensusRate * 100 : 100
      );
  }, [batch]);

  useEffect(() => {
    setSelectedWorkflowId(batchWorkflow ? batchWorkflow.workflow.id : -1);
  }, [batchWorkflow]);

  useEffect(() => {
    setLabels(
      batchLabels.map((bl) => ({
        observation: bl.observation,
        labelRequired: bl.labelRequired,
        probabilityRequired: bl.probabilityRequired,
        iou: bl.iou,
        visible: true,
        showChildren: true,
        attributeAgreement: bl.attributeAgreement,
      }))
    );
  }, [batchLabels]);

  useEffect(() => {
    const allowChangeWorkflow = batch && batch.status === BatchStatus.INITIAL;
    setDisableChangeWorkflow(!allowChangeWorkflow);
    setDisableUpdate(batch?.status === BatchStatus.COMPLETED);
  }, [batch]);

  useDeepCompareEffect(() => {
    if (!batch) {
      setConsensusEntity(0);
      setConsensusRelationship(0);
      return;
    }
    if (!requireTextConsensusRules.entity) {
      setConsensusEntity(0);
    } else if (batch.batchSetting?.consensusEntity) {
      setConsensusEntity(batch.batchSetting.consensusEntity);
    }

    if (!requireTextConsensusRules.relation) {
      setConsensusRelationship(0);
    } else if (batch.batchSetting?.consensusRelationship) {
      setConsensusRelationship(batch.batchSetting.consensusRelationship);
    }
  }, [requireTextConsensusRules, batch]);

  const handleChangeStep = (item: Partial<StepData>) => {
    if (!selectedStep) return;
    const newStep = { ...selectedStep, ...item };
    const newSteps = steps.map((step) =>
      step.position === selectedStep.position ? newStep : step
    );

    onChangeSteps && onChangeSteps(newSteps);
  };

  const handleChangeReviewConfig = (config: StepReviewConfig) => {
    if (!selectedStep || !selectedStep.assignees) return;
    const assignees: StepAssignee[] = selectedStep.assignees.map(
      (assignee) => ({
        ...assignee,
        ...config,
      })
    );

    onChangeReviewConfig(config);
    handleChangeStep({ assignees });
  };

  const handleChangeAssignees = (emails: string[]) => {
    if (!selectedStep) return;

    const assignees: StepAssignee[] = emails.map((email) => {
      const existedAssignee = selectedStep.assignees?.find(
        (assignee: StepAssignee) => assignee.userId === email
      );

      return (
        existedAssignee || {
          ...(isAcceptanceStep && { ...reviewConfig }),
          ...(selectedStep.assignees &&
            selectedStep.assignees?.length && { ...assigneesConfig }),
          userId: email,
          workInstructionId: selectedStep.id,
        }
      );
    });

    handleChangeStep({ assignees });
  };

  if (
    !batch ||
    !batchWorkflow ||
    selectedWorkflowId === -1 ||
    instructions.length === 0
  ) {
    return <BatchWorkflowLoading />;
  }

  return (
    <div
      className={`p-4 ${
        updateStatus === RequestStatus.LOADING || disableUpdate
          ? "pointer-events-none select-none"
          : ""
      }`}
    >
      <div className="flex items-center gap-4">
        <div className="flex flex-col gap-2">
          <div className="text-lg font-semibold">
            {t("project:batchDetails.batchWorkflow.title")}
          </div>
          <WorkflowSelector
            disabled={disableChangeWorkflow || !!isProjectFromTemplate}
            workflows={workflows}
            selectedWorkflowId={selectedWorkflowId}
            onChange={handleWorkflowChange}
          />
        </div>

        <div className="flex-1"></div>
        {!disableUpdate && (
          <div
            className={classnames(
              disabledButtonUpdate && "pointer-events-none"
            )}
          >
            <button
              disabled={
                updateStatus === RequestStatus.LOADING || disabledButtonUpdate
              }
              className={classnames(
                "button-text-secondary disabled:opacity-50"
              )}
              onClick={handleUpdate}
            >
              {updateStatus === RequestStatus.LOADING ? (
                <VBSpinner className="text-white" />
              ) : (
                t("project:batchDetails.batchWorkflow.buttonUpdate")
              )}
            </button>
          </div>
        )}
      </div>

      {isTextProject &&
        (requireTextConsensusRules.entity ||
          requireTextConsensusRules.relation) && (
          <div className="mt-6">
            <div className="text-lg font-bold">
              {t("project:consensusRules")}
            </div>
            <div className="flex w-1/2 gap-8 pt-2 pb-4 ">
              {requireTextConsensusRules.entity && (
                <div className="w-1/2 text-sm">
                  <AgreementRateSlider
                    label={t(
                      "project:batchDetails.batchSettings.consensusEntity"
                    )}
                    defaultValue={consensusEntity}
                    onChange={(value) => setConsensusEntity(value)}
                  />
                </div>
              )}
              {requireTextConsensusRules.relation && (
                <div className="w-1/2 text-sm">
                  <AgreementRateSlider
                    label={t(
                      "project:batchDetails.batchSettings.consensusRelationship"
                    )}
                    defaultValue={consensusRelationship}
                    onChange={(value) => setConsensusRelationship(value)}
                  />
                </div>
              )}
            </div>
          </div>
        )}

      <div className={disableUpdate ? "mt-2 pointer-events-none" : "mt-2"}>
        <div className="block gap-8 mt-6 xl:grid xl:grid-cols-12">
          {(isImageProject || isMdiProject) && isRequireImageConsensus && (
            <div className="w-full p-4 bg-white xl:col-span-4">
              <div className="mb-2 text-lg font-bold">
                {t("project:consensusRules")}
              </div>

              <div className="max-w-md">
                <AgreementRateSlider
                  onChange={handleConsensusRateChange}
                  defaultValue={consensusRate}
                />
              </div>
              <LabelSegmentationSlider
                isMdi={isMdiProject}
                labels={labels}
                onChange={setLabels}
              />
            </div>
          )}
          <div className="w-full mt-5 xl:col-span-8">
            <div className="lg:grid lg:grid-cols-12 ">
              {selectedWorkflowId && (
                <div className="col-span-7 mt-4">
                  <ProjectWorkflowSteps
                    steps={steps}
                    selectedStepIndex={selectedStepIndex}
                    setSelectedStepIndex={setSelectedStepIndex}
                    workflowId={selectedWorkflowId}
                    onChange={onChangeSteps}
                    onChangeStep={onChangeStep}
                    disabled={
                      !!isProjectFromTemplate ||
                      batch.status !== BatchStatus.INITIAL
                    }
                  />
                </div>
              )}
              <div className="w-full col-span-5 p-4 space-y-2">
                <ProjectWorkflowAssignLabeler
                  emails={allEmails}
                  selectedEmails={selectedEmails}
                  max={selectedStep && selectedStep.numberOfRounds}
                  onSelect={handleChangeAssignees}
                />
                {isAcceptanceStep && (
                  <ProjectWorkflowReviewConfig
                    config={reviewConfig}
                    onChange={handleChangeReviewConfig}
                    disabled={batch.status !== BatchStatus.INITIAL}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
