/*
 * File: update-issue.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 7th July 2022 10:23:45 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { TextWorkspaceState } from "../../text-workspace.state";
import { issueCustomerService } from "services/label-service";
import { RootState } from "store";
import { TextLabelingMode } from "../../text-labeling/text-labeling.state";
import { selectTextLabelingMode } from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { Issue } from "domain/common/issue";
import { textIssueUtils } from "../../utils/text-issue.utils";

const THUNK_NAME = "textLabeling/resolveTextIssueAsync";

interface ResolveIssuePayload {
  issue: Issue;
}

export const resolveTextIssueAsync = createAsyncThunk(
  THUNK_NAME,
  async (payload: ResolveIssuePayload, { getState }) => {
    const state = getState() as RootState;
    const mode = selectTextLabelingMode(state);
    if (mode === TextLabelingMode.CUSTOMER) {
      const response = await issueCustomerService.resolveIssue({
        issueId: payload.issue.id,
      });
      return textIssueUtils.fromDTO(response.data);
    }
  }
);

export function resolveTextIssueBuilder(
  builder: ActionReducerMapBuilder<TextWorkspaceState>
) {
  return builder
    .addCase(resolveTextIssueAsync.pending, (_state, _action) => {})
    .addCase(resolveTextIssueAsync.fulfilled, (state, action) => {
      if (!action.payload) return;
      const issue: Issue = action.payload;
      state.textIssues.issues = state.textIssues.issues.map((item) => {
        if (item.id === issue.id) {
          return issue;
        }
        return item;
      });
    })
    .addCase(resolveTextIssueAsync.rejected, (_state, _action) => {});
}
