import { ErrorBoundary } from "components/error-boundary/error-boundary.component";
import { TicketSupportModal } from "components/ticket-management/ticket-support.modal";
import { AppEvents } from "constants/event.constant";
import { useModalContext } from "contexts/modal";
import { ModalTypes } from "contexts/modal/modal.state";
import { StorageKeys } from "hooks/storage/storage-keys";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { selectCSAmountBugOnDisplay } from "store/common/customer-support/customer-support.selector";
import { increaseBugOnDisplay } from "store/common/customer-support/customer-support.slice";
import { ErrorBoundaryContext } from "./error-boundary.context";
import { ErrorBoundaryState } from "./error-boundary.state";

interface ErrorBoundaryProviderProps {
  children: React.ReactNode | React.ReactNode[] | null;
}

export const ErrorBoundaryProvider = ({
  children,
}: ErrorBoundaryProviderProps) => {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const amountBugOnDisplay = useAppSelector(selectCSAmountBugOnDisplay);
  const dispatch = useAppDispatch();
  const { openModal } = useModalContext()

  const allowDisplayTrackingModal = () => {
    const trackingBugItem = localStorage.getItem(StorageKeys.TRACKING_BUG);
    return trackingBugItem === null || trackingBugItem === "true"
  }

  const isTiketSupportModalVisible = useMemo(() => {
    return allowDisplayTrackingModal() && errorMessages.length > 0;
  }, [errorMessages.length]);

  useEffect(() => {
    if (!allowDisplayTrackingModal()) return;

    const handleShowGlobalError = (event: any) => {
      setErrorMessages([...errorMessages, event.detail.message]);
      dispatch(increaseBugOnDisplay());
    };

    document.addEventListener(
      AppEvents.GLOBAL_ERROR_ADDED,
      handleShowGlobalError
    );

    return () => {
      document.removeEventListener(
        AppEvents.GLOBAL_ERROR_ADDED,
        handleShowGlobalError
      );
    };
  }, [dispatch, errorMessages]);

  const handleCloseCrashReportModal = useCallback(() => {
    setErrorMessages([]);
    if (
      allowDisplayTrackingModal() &&
      amountBugOnDisplay > 0 &&
      amountBugOnDisplay % 3 === 0
    ) {
      openModal(ModalTypes.TICKET_SUPPORT_SETTING)
    }
  }, [amountBugOnDisplay, openModal])

  const valueProvider: ErrorBoundaryState = {
    errorMessages,
  };

  return (
    <ErrorBoundaryContext.Provider value={valueProvider}>
      <ErrorBoundary>{children}</ErrorBoundary>
      {isTiketSupportModalVisible && (
        <TicketSupportModal
          open={true}
          defaultValues={{ description: errorMessages[0] }}
          onClose={handleCloseCrashReportModal}
        />
      )}
    </ErrorBoundaryContext.Provider>
  );
};
