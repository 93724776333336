/*
 * File: types.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd January 2022 8:12:18 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { User } from "domain/customer";

export type UserStatus = "invited" | "active" | "inactive" | "available";

export interface RowData {
  photoUrl: string;
  email: string;
  name: string;
  role: string;
  numberOfBatches: number;
  totalTasksFinished: number;
  lastUpdate?: Date;
  lastLogin?: Date;
  status: UserStatus;
  data: User;
}

export const userRowDataMapper = (user: User): RowData => {
  return {
    photoUrl: "",
    email: user.email,
    name: user.name,
    role: user.role.replace(/[[\]]/g, "").replace(",", " & "),
    numberOfBatches: 0,
    totalTasksFinished: 0,
    lastUpdate: undefined,
    lastLogin: undefined,
    status: "active",
    data: user,
  };
};

export interface ColDef<T extends RowData> {
  field: keyof T;
  header: string;
  renderCell: (row: T, rowIndex: number, columnIndex: number) => JSX.Element;
  renderColumnHeader: (column: ColDef<T>, columnIndex: number) => JSX.Element;
}

export interface Sort<T> {
  field?: keyof T;
  mode: SortMode;
}

export type SortMode = "asc" | "desc" | "";
