/*
 * File: project-statistic.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 20th September 2021 12:33:38 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "store";

export const selectProjectStatisticRequestStatus = (state: RootState) => {
  return state.projectData.statistic.status;
};

export const selectProjectStatisticRequestError = (state: RootState) => {
  return state.projectData.statistic.error;
};

export const selectProjectStatistic = (projectId: number) => (state: RootState) => {
  return state.projectData.statistic.entities[projectId] || null;
};
