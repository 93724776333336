import { useAppPrevious } from "hooks/use-app-previous";
import { useAppDispatch } from "hooks/use-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { JobService } from "services/label-service";
import { TaskDTO } from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import { enqueueErrorNotification, enqueueSuccessNotification } from "store/common/notification/notification.actions";
import { loadComplexTaskToReviewAsync, LoadCombplexJobResponse, pollComplexJobComplexAsync } from "store/labeler/complex-jobs/complex-jobs.thunk";
import { compare2Array } from "utilities/array/compare";
import { AppError } from "utilities/errors/errors";
import { ComplexJobProviderProps } from "../context/speech-to-text-labeling.provider";
import * as Sentry from "@sentry/react";


interface Props {
  providerPayload: Partial<ComplexJobProviderProps>;
  loadJobFromComplexResCallback?: (
    jobComplexRes: LoadCombplexJobResponse,
    uiJobData?: any,
  ) => Promise<any>;
}
export const useLoadComplexJob = ({
  providerPayload,
  loadJobFromComplexResCallback,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    isLoadFromBatch,
    jobInBatch,
    isTaskReview,
    taskToReview,
    jobIdsIncludedOnly,
    isFromJobId,
    jobIdParam,
  } = providerPayload;

  const [loadJobStatus, setLoadJobStatus] = useState<RequestStatus>(
    RequestStatus.IDLE
  );
  const isLoadingJob = useMemo(
    () => loadJobStatus === RequestStatus.LOADING,
    [loadJobStatus]
  );
  const [savingJobStatus, setSavingJobStatus] = useState<RequestStatus>(
    RequestStatus.IDLE
  );
  const isSavingJob = useMemo(
    () => savingJobStatus === RequestStatus.LOADING,
    [savingJobStatus]
  );
  const isJobLoaded = useMemo(
    () => loadJobStatus === RequestStatus.SUCCESS,
    [loadJobStatus]
  );

  const isLoadingRef = useRef(false);

  const previousTaskToReview = useAppPrevious(taskToReview);
  const previousJobIdsIncludedOnly = useAppPrevious(jobIdsIncludedOnly);
  
  const previousJobInBatch = useAppPrevious(jobInBatch);

  const previousJobIdParam = useAppPrevious(jobIdParam);

  const [error, setError] = useState<AppError>();

  const loadJobComplex = useCallback(async (
    loadBatchObservation = false,
    isTaskReview?: boolean,
    taskToReview?: TaskDTO,
    jobIdsIncludedOnly?: number[],
    jobId?: number | string,
  ): Promise<LoadCombplexJobResponse> => {
    if (isTaskReview) {
      const res = await dispatch(loadComplexTaskToReviewAsync({
        taskToReview, 
        jobIdsIncludedOnly,
      }));
      return res.payload as LoadCombplexJobResponse;
    }
    const res = await dispatch(pollComplexJobComplexAsync({
      loadBatchObservation,
      jobId,
    }));
    return res.payload as LoadCombplexJobResponse;
  }, [dispatch]);

  const loadJob = useCallback(
    async (payload: Partial<ComplexJobProviderProps>) => {
      const {
        isLoadFromBatch,
        jobInBatch,
        isTaskReview,
        taskToReview,
        jobIdsIncludedOnly,
        jobIdParam,
      } = payload;
      if (isLoadingRef.current) return;
      isLoadingRef.current = true;
      setLoadJobStatus(RequestStatus.LOADING);
      
      try {
        let jobComplexRes: LoadCombplexJobResponse | undefined = undefined;
        let uiJobData: any = undefined;
        if (isLoadFromBatch) {
          if (jobInBatch) {
            jobComplexRes = jobInBatch.loadJobComplexRes;
            uiJobData = jobInBatch.data;
          }
        } else {
          jobComplexRes = await loadJobComplex(
            true,
            isTaskReview,
            taskToReview,
            jobIdsIncludedOnly,
            jobIdParam,
          );
        }

        if (!jobComplexRes) return;
        if (loadJobFromComplexResCallback) {
          await loadJobFromComplexResCallback(jobComplexRes, uiJobData);
        }
        setLoadJobStatus(RequestStatus.SUCCESS);
      } catch (error: any) {
        Sentry.captureException(error);
        console.log(error);
        if (error instanceof AppError) {
          setError(error);
        } else {
          setError(new AppError("unknow", error.message));
        }
        setLoadJobStatus(RequestStatus.FAILURE);
      } finally {
        isLoadingRef.current = false;
      }
    },
    [
      loadJobComplex,
      loadJobFromComplexResCallback,
    ]
  );

  // load from job in batch
  useEffect(() => {
    if (!isLoadFromBatch || !jobInBatch) return;

    const loadJobFromJobInBatch = (force = true) => {
      if (force) {
        isLoadingRef.current = false;
      }
      loadJob({
        isLoadFromBatch: true,
        jobInBatch,
      });
    }
    if (!previousJobInBatch || // first time
        previousJobInBatch.id !== jobInBatch.id || // change job
        previousJobInBatch.status !== jobInBatch.status) { // job in batch change status
      loadJobFromJobInBatch();
    }
  }, [isLoadFromBatch, jobInBatch, previousJobInBatch, loadJob]);

  // load for task review
  useEffect(() => {
    if (!isTaskReview || !taskToReview) return;
    let shouldLoadJob = false;
    if (!previousTaskToReview || taskToReview.id !== previousTaskToReview.id) {
      shouldLoadJob = true;
    } else {
      if (
        previousJobIdsIncludedOnly &&
        jobIdsIncludedOnly &&
        !compare2Array(previousJobIdsIncludedOnly, jobIdsIncludedOnly)
      ) {
        shouldLoadJob = true;
      }
    }
    if (shouldLoadJob) {
      loadJob({
        isTaskReview,
        taskToReview,
        jobIdsIncludedOnly,
      });
    }
  }, [
    isTaskReview,
    previousTaskToReview,
    taskToReview,
    jobIdsIncludedOnly,
    previousJobIdsIncludedOnly,
    loadJob,
  ]);

  // load for job label review issue by jobId
  useEffect(() => {
    if (!isFromJobId || !jobIdParam || previousJobIdParam === jobIdParam) return;
    loadJob({jobIdParam});
  }, [isFromJobId, previousJobIdParam, jobIdParam, loadJob])

  const skipJob = async (jobId: number) => {
    try {
      const payload = { id: jobId };
      await JobService.skipJob(payload);
      dispatch(enqueueSuccessNotification(t("common:textSkippedSuccess")));
    } catch (err: any) {
      const errMessage = err.message || t("common:textSkippedFailed");
      dispatch(enqueueErrorNotification(errMessage));
    }
  }

  return {
    loadJobStatus,
    isLoadingJob,
    savingJobStatus,
    setSavingJobStatus,
    isSavingJob,
    isJobLoaded,
    isLoadingRef,
    error,
    setError,
    skipJob,
    loadJobComplex,
  }
}
