/*
 * File: file-card.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 5:26:56 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Tooltip } from "@material-ui/core";
import classNames from "classnames";
import { useWorkspaceContext } from "contexts/workspace";
import { QueryApi } from "data-access/impl/query";
import { EntityAction } from "domain/common/entity-action";
import fileSize from "filesize";
import { useMemo } from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { VscFile, VscTrash } from "react-icons/vsc";
import { formatDate } from "utilities/formatter/date-formatter.utils";

interface Props {
  file: QueryApi.IFile;
  selected?: boolean;
  onSelect(fileId: number, action: string): void;
}
export function FileCard({ file, selected, onSelect }: Props) {
  const { getUrlWithSasToken } = useWorkspaceContext();
  const thumbnailUrl = useMemo(() => {
    const src = file.additionalFileInfo?.addition?.thumbnailUrl;
    if (!src) return "";
    return getUrlWithSasToken(src);
  }, [file, getUrlWithSasToken]);

  const extension = useMemo(() => {
    return file.additionalFileInfo?.extend ?? "";
  }, [file]);

  const fileSizeInKb = useMemo(() => {
    return file.additionalFileInfo?.addition?.fileSize ?? 0;
  }, [file]);
  return (
    <div
      className={classNames(
        "group relative overflow-hidden bg-white border rounded cursor-pointer hover:shadow",
        {
          "ring-2 ring-blue-500": selected,
        }
      )}
      onClick={() => onSelect(file.id, EntityAction.VIEW)}
    >
      <div className="relative bg-white aspect-w-5 aspect-h-3 group">
        {thumbnailUrl && (
          <img
            alt="thumbnail"
            src={thumbnailUrl}
            className="object-cover w-full h-full"
          />
        )}
        {extension === "txt" && (
          <div className="flex items-center justify-center w-full h-full">
            <VscFile size={32} />
          </div>
        )}
        <div className="absolute top-0 left-0 w-full h-full transition-all bg-black bg-opacity-0 hover:bg-opacity-20" />
      </div>
      <div className="p-2 border-t">
        <Tooltip title={file.fileName} arrow>
          <div className="truncate">{file.fileName}</div>
        </Tooltip>
        <div className="flex flex-wrap items-center gap-2 pt-1">
          <div className="text-xs text-background-500">
            {fileSizeInKb ? fileSize(fileSizeInKb) : ""}
          </div>
          {file.createdDate && (
            <div className="text-xs text-background-500">
              {formatDate(new Date(file.createdDate))}
            </div>
          )}
        </div>
      </div>

      <div
        className={classNames("absolute left-2 top-2 group-hover:block", {
          hidden: !selected,
        })}
      >
        <Tooltip title="Toggle select file" arrow>
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onSelect(file.id, EntityAction.SELECT);
            }}
            className={classNames({
              "bg-white rounded overflow-hidden": !!selected,
            })}
          >
            {!selected && <MdCheckBoxOutlineBlank color="#fff" size={24} />}
            {selected && <MdCheckBox size={24} color="blue" />}
          </button>
        </Tooltip>
      </div>

      <div className="absolute hidden right-2 top-2 group-hover:block">
        <Tooltip title="Delete file" arrow>
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onSelect(file.id, EntityAction.DELETE);
            }}
          >
            <VscTrash color="#fff" size={24} />
          </button>
        </Tooltip>
      </div>
    </div>
  );
}
