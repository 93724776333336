/*
 * File: labeler-distribution.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 10:05:19 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { BatchService } from "services/label-service";
import { RootState } from "store";
import {
  addEntity,
  requestFulfilled,
  requestPending,
  requestRejected,
} from "store/base/base.reducer";
import {
  LabelerDistributionState,
  LabelDistributionDTO,
} from "./labeler-distribution.state";

export enum LabelerDistributionThunk {
  LOAD_LABELER_DISTRIBUTION = "batchData/loadLabelerDistributionAsyc",
}

export const loadLabelerDistributionAsyc = createAsyncThunk(
  LabelerDistributionThunk.LOAD_LABELER_DISTRIBUTION,
  async (batchId: number, { getState }) => {
    const state = getState() as RootState;
    if (state.batchData.labelerDistribution.ids.includes(batchId)) {
      return state.batchData.labelerDistribution.entities[batchId];
    }
    const response = await BatchService.getF1Distribution(batchId);
    const labelerData: Record<string, LabelDistributionDTO> = {};
    const items: LabelDistributionDTO[] = response.data;
    for (let item of items) {
      labelerData[item.email] = item;
    }
    return { id: batchId, labelers: labelerData };
  }
);

export const labelerDistributionReducerBuilder = <
  S extends { labelerDistribution: LabelerDistributionState }
>(
  builder: ActionReducerMapBuilder<S>
): ActionReducerMapBuilder<S> => {
  return builder
    .addCase(loadLabelerDistributionAsyc.pending, (state) => {
      requestPending(state.labelerDistribution);
    })
    .addCase(loadLabelerDistributionAsyc.rejected, (state, action) => {
      requestRejected(state.labelerDistribution, action.error);
    })
    .addCase(loadLabelerDistributionAsyc.fulfilled, (state, action) => {
      requestFulfilled(state.labelerDistribution);
      if (action.payload) {
        addEntity(state.labelerDistribution, action.payload);
      }
    });
};
