/*
 * File: drawTextBox.js
 * Project: app-aiscaler-web
 * File Created: Saturday, 15th January 2022 8:26:12 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { textStyle } from "cornerstone-tools";
import * as csTools from "cornerstone-tools";
import drawIcon from "./drawIcon";
import ic_votedown_active from "../../../../../assets/icons/ic_votedown_active.svg";
import ic_voteup_active from "../../../../../assets/icons/ic_voteup_active.svg";

const draw = csTools.importInternal("drawing/draw");
const fillTextLines = csTools.importInternal("drawing/fillTextLines");
const fillBox = csTools.importInternal("drawing/fillBox");

/**
 * Compute the width of the box required to display the given `text` with a given `padding`.
 * @public
 * @function textBoxWidth
 * @memberof Drawing
 *
 * @param {CanvasRenderingContext2D} context - Target context
 * @param {String} text - The text to find the width of.
 * @param {Number} padding - The padding to apply on either end of the text.
 * @returns {Number} computed text box width
 */
export function textBoxWidth(context, text, padding) {
  const font = textStyle.getFont();
  const origFont = context.font;

  if (font && font !== origFont) {
    context.font = font;
  }
  const width = context.measureText(text).width;

  if (font && font !== origFont) {
    context.font = origFont;
  }

  return width + 2 * padding;
}

/**
 * Draws a textBox.
 * @public
 * @function drawTextBox
 * @memberof Drawing
 *
 * @param  {CanvasRenderingContext2D} context The canvas context.
 * @param  {string} textLines   The text to display.
 * @param  {number} x           The x position of the textBox.
 * @param  {number} y           The y position of the textBox.
 * @param  {string} color       The color of the textBox.
 * @param  {Object} options     Options for the textBox.
 * @returns {Object} {top, left, width, height} - Bounding box; can be used for pointNearTool
 */
export default function (
  context,
  textLines,
  x,
  y,
  color,
  options,
  textValue,
  vote = undefined
) {
  if (Object.prototype.toString.call(textLines) !== "[object Array]") {
    textLines = [textLines];
  }
  const padding = 5;
  const fontSize = textStyle.getFontSize();
  const backgroundColor = textStyle.getBackgroundColor();

  // Find the longest text width in the array of text data
  let maxWidth = 0;

  textLines.forEach(function (text) {
    // Get the text width in the current font
    const width = textBoxWidth(context, text, padding);

    // Find the maximum with for all the text rows;
    maxWidth = Math.max(maxWidth, width);
  });

  if (vote) maxWidth += 24;
  else if (textValue !== undefined && textValue.trim().length > 0) maxWidth += 24;

  // Calculate the bounding box for this text box
  const boundingBox = {
    width: maxWidth,
    height: padding + textLines.length * (fontSize + padding),
  };

  draw(context, (context) => {
    color = "#FFFFFF";
    context.strokeStyle = color;

    // Draw the background box with padding
    if (options && options.centering && options.centering.x === true) {
      x -= boundingBox.width / 2;
    }

    if (options && options.centering && options.centering.y === true) {
      y -= boundingBox.height / 2;
    }

    boundingBox.left = x;
    boundingBox.top = y;

    // const fillStyle =
    //   options && options.debug === true ? "#FF0000" : backgroundColor;
    const fillStyle = "#1E1F20";

    fillBox(context, boundingBox, fillStyle);

    const path = createRoundedPathString([
      { x: boundingBox.left, y: boundingBox.top },
      { x: boundingBox.left + boundingBox.width, y: boundingBox.top },
      {
        x: boundingBox.left + boundingBox.width,
        y: boundingBox.top + boundingBox.height,
      },
      { x: boundingBox.left, y: boundingBox.top + boundingBox.height },
    ]);
    context.strokeStyle = "#1E1F20";
    context.stroke(new Path2D(path));

    // Draw each of the text lines on top of the background box
    fillTextLines(context, boundingBox, textLines, color, padding);
  });

  if (textValue !== undefined && textValue.trim().length > 0) {
    draw(context, (context) => {
      context.fillStyle = "#FFFFFF";
      var x = boundingBox.left + boundingBox.width - 24;
      var y = boundingBox.top + 4;
      var path1 = new Path2D(
        `M${x + 10.5333} ${y + 1.47329}C${x + 10.26} ${y + 1.19995} ${
          x + 9.78662
        } ${y + 1.38662} ${x + 9.78662} ${y + 1.76662}V${y + 4.09329}C${
          x + 9.78662
        } ${y + 5.06662} ${x + 10.6133} ${y + 5.87329} ${x + 11.62} ${
          y + 5.87329
        }C${x + 12.2533} ${y + 5.87995} ${x + 13.1333} ${y + 5.87995} ${
          x + 13.8866
        } ${y + 5.87995}C${x + 14.2666} ${y + 5.87995} ${x + 14.4666} ${
          y + 5.43329
        } ${x + 14.2} ${y + 5.16662}C${x + 13.24} ${y + 4.19995} ${x + 11.52} ${
          y + 2.45995
        } ${x + 10.5333} ${y + 1.47329}Z`
      );
      var path2 = new Path2D(
        `M${x + 13.6665} ${y + 6.79337}H${x + 11.7398}C${x + 10.1598} ${
          y + 6.79337
        } ${x + 8.87317} ${y + 5.50671} ${x + 8.87317} ${y + 3.92671}V${
          y + 2.00004
        }C${x + 8.87317} ${y + 1.63337} ${x + 8.57317} ${y + 1.33337} ${
          x + 8.2065
        } ${y + 1.33337}H${x + 5.37984}C${x + 3.3265} ${y + 1.33337} ${
          x + 1.6665
        } ${y + 2.66671} ${x + 1.6665} ${y + 5.04671}V${y + 10.9534}C${
          x + 1.6665
        } ${y + 13.3334} ${x + 3.3265} ${y + 14.6667} ${x + 5.37984} ${
          y + 14.6667
        }H${x + 10.6198}C${x + 12.6732} ${y + 14.6667} ${x + 14.3332} ${
          y + 13.3334
        } ${x + 14.3332} ${y + 10.9534}V${y + 7.46004}C${x + 14.3332} ${
          y + 7.09337
        } ${x + 14.0332} ${y + 6.79337} ${x + 13.6665} ${y + 6.79337}ZM${
          x + 7.6665
        } ${y + 11.8334}H${x + 4.99984}C${x + 4.7265} ${y + 11.8334} ${
          x + 4.49984
        } ${y + 11.6067} ${x + 4.49984} ${y + 11.3334}C${x + 4.49984} ${
          y + 11.06
        } ${x + 4.7265} ${y + 10.8334} ${x + 4.99984} ${y + 10.8334}H${
          x + 7.6665
        }C${x + 7.93984} ${y + 10.8334} ${x + 8.1665} ${y + 11.06} ${
          x + 8.1665
        } ${y + 11.3334}C${x + 8.1665} ${y + 11.6067} ${x + 7.93984} ${
          y + 11.8334
        } ${x + 7.6665} ${y + 11.8334}ZM${x + 8.99984} ${y + 9.16671}H${
          x + 4.99984
        }C${x + 4.7265} ${y + 9.16671} ${x + 4.49984} ${y + 8.94004} ${
          x + 4.49984
        } ${y + 8.66671}C${x + 4.49984} ${y + 8.39337} ${x + 4.7265} ${
          y + 8.16671
        } ${x + 4.99984} ${y + 8.16671}H${x + 8.99984}C${x + 9.27317} ${
          y + 8.16671
        } ${x + 9.49984} ${y + 8.39337} ${x + 9.49984} ${y + 8.66671}C${
          x + 9.49984
        } ${y + 8.94004} ${x + 9.27317} ${y + 9.16671} ${x + 8.99984} ${
          y + 9.16671
        }Z`
      );
      context.moveTo(boundingBox.left, boundingBox.top);
      context.fill(path1);
      context.fill(path2);
    });
  }

  if (vote) {
    context.fillStyle = "#FFFFFF";
    var x = boundingBox.left + boundingBox.width - 24;
    var y = boundingBox.top + 4;
    if (vote > 0) {
      drawIcon(context, ic_voteup_active, x, y);
    } else if (vote < 0) {
      drawIcon(context, ic_votedown_active, x, y);
    }
  }

  // Return the bounding box so it can be used for pointNearHandle
  return boundingBox;
}

function createRoundedPathString(pathCoords) {
  const path = [];
  const curveRadius = 3;

  // Reset indexes, so there are no gaps
  pathCoords = pathCoords.slice();

  for (let i = 0; i < pathCoords.length; i++) {
    // 1. Get current coord and the next two (startpoint, cornerpoint, endpoint) to calculate rounded curve
    const c2Index =
      i + 1 > pathCoords.length - 1 ? (i + 1) % pathCoords.length : i + 1;
    const c3Index =
      i + 2 > pathCoords.length - 1 ? (i + 2) % pathCoords.length : i + 2;

    const c1 = pathCoords[i];
    const c2 = pathCoords[c2Index];
    const c3 = pathCoords[c3Index];

    // 2. For each 3 coords, enter two new path commands: Line to start of curve, bezier curve around corner.

    // Calculate curvePoint c1 -> c2
    const c1c2Distance = Math.sqrt(
      Math.pow(c1.x - c2.x, 2) + Math.pow(c1.y - c2.y, 2)
    );
    const c1c2DistanceRatio = (c1c2Distance - curveRadius) / c1c2Distance;
    const c1c2CurvePoint = [
      ((1 - c1c2DistanceRatio) * c1.x + c1c2DistanceRatio * c2.x).toFixed(1),
      ((1 - c1c2DistanceRatio) * c1.y + c1c2DistanceRatio * c2.y).toFixed(1),
    ];

    // Calculate curvePoint c2 -> c3
    const c2c3Distance = Math.sqrt(
      Math.pow(c2.x - c3.x, 2) + Math.pow(c2.y - c3.y, 2)
    );
    const c2c3DistanceRatio = curveRadius / c2c3Distance;
    const c2c3CurvePoint = [
      ((1 - c2c3DistanceRatio) * c2.x + c2c3DistanceRatio * c3.x).toFixed(1),
      ((1 - c2c3DistanceRatio) * c2.y + c2c3DistanceRatio * c3.y).toFixed(1),
    ];

    // If at last coord of polygon, also save that as starting point
    if (i === pathCoords.length - 1) {
      path.unshift("M" + c2c3CurvePoint.join(","));
    }

    // Line to start of curve (L endcoord)
    path.push("L" + c1c2CurvePoint.join(","));
    // Bezier line around curve (Q controlcoord endcoord)
    path.push("Q" + c2.x + "," + c2.y + "," + c2c3CurvePoint.join(","));
  }
  // Logically connect path to starting point again (shouldn't be necessary as path ends there anyway, but seems cleaner)
  path.push("Z");

  return path.join(" ");
}
