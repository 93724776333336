/*
 * File: text-sources.comopnent.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 21st October 2022 4:30:27 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { Backdrop } from "@material-ui/core";
import classNames from "classnames";
import {
  IconArrowDown,
  IconArrowForward,
} from "components/common/vb-icon.component";
import { VBResizableLayout } from "components/common/vb-resizable-layout/vb-resizable-layout.component";
import { VBCheckbox } from "components/design-system/checkbox/checkbox.component";
import { MatModal } from "components/material/mat-modal.component";
import { TextLabelingData } from "domain/labeling/labeling-data";
import { CORAnnotation, NERAnnotation } from "domain/text-labeling";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useState } from "react";
import {
  selectTextLabels,
  selectTextLabelingSentences,
  selectTextLabelingSources,
} from "store/labeler/text-workspace/text-labeling/text-labeling.selectors";
import { textApplyAnnotations } from "store/labeler/text-workspace/text-workspace.slice";
import { useTextWorkspaceContext } from "../../context/text-workspace/text-workspace.context";
import { TextViewer } from "../text-viewer/text-viewer.component";
import { useTextAnnotationSourcesContext } from "./context/text-annotation-sources.context";
import { TextAnnotationSourcesProvider } from "./context/text-annotation-sources.provider";
import { TextAnnotationMenuItem } from "./reducer/text-annotations-reducer.hook";

export default function TextAnnotationSources() {
  const dispatch = useAppDispatch();
  const labels = useAppSelector(selectTextLabels);
  const sentences = useAppSelector(selectTextLabelingSentences);
  const labelingDatas = useAppSelector(selectTextLabelingSources);
  const { isShowAnnotationSources, setShowAnnotationSources } =
    useTextWorkspaceContext();
  function handleClose() {
    setShowAnnotationSources(false);
  }
  function handleSubmit(
    annotations: NERAnnotation[],
    relations: CORAnnotation[]
  ) {
    dispatch(textApplyAnnotations({ annotations, relations }));
    setShowAnnotationSources(false);
  }
  if (!isShowAnnotationSources) return null;

  return (
    <TextAnnotationSourcesProvider
      labels={labels}
      sentences={sentences}
      labelingDatas={labelingDatas as TextLabelingData[]}
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      <MatModal
        open
        closeAfterTransition
        disableAutoFocus
        BackdropComponent={Backdrop}
        className="relative flex items-center justify-center outline-none"
      >
        <div className="relative flex items-center justify-center w-full h-full p-10">
          <div
            className="relative flex flex-col w-full h-full overflow-hidden bg-white rounded"
            style={{ maxWidth: "80vw", maxHeight: "90vh" }}
          >
            <TextAnnotationSourcesHeader />
            <div className="flex-auto w-full">
              <VBResizableLayout right={<TextAnnotationSourcesSidebar />}>
                <TextAnnotationSourcesViewer />
              </VBResizableLayout>
            </div>
          </div>
        </div>
      </MatModal>
    </TextAnnotationSourcesProvider>
  );
}

function TextAnnotationSourcesHeader() {
  const { onClose, onSubmit } = useTextAnnotationSourcesContext();

  return (
    <div className="flex items-center flex-none gap-4 px-4 bg-white shadow h-14">
      <button onClick={onClose}>
        <IconArrowForward className="flex-none w-6 transform rotate-180" />
      </button>
      <span className="text-lg font-semibold">Select annotations</span>
      <span className="flex-auto"></span>
      <button onClick={onSubmit} className="button-text-primary">
        Accept
      </button>
    </div>
  );
}

function TextAnnotationSourcesViewer() {
  const { labels, sentences, textAnnotations } =
    useTextAnnotationSourcesContext();
  const { tokenizer } = useTextWorkspaceContext();
  return (
    <div className="flex-auto h-full p-4 bg-background-100">
      <div className="w-full bg-white rounded shadow">
        <TextViewer
          tokenizer={tokenizer}
          readonly
          labels={labels}
          sentences={sentences}
          connections={textAnnotations.relationAnnotations}
          observations={textAnnotations.annotations}
        />
      </div>
    </div>
  );
}
function TextAnnotationSourcesSidebar() {
  return (
    <div className="flex flex-col h-full w-80">
      <TextAnnotationsPanel />
      <TextAnnotationsList />
    </div>
  );
}

function TextAnnotationsPanel() {
  const { annotators, selectAnnotator } = useTextAnnotationSourcesContext();

  return (
    <div className="flex-none py-4">
      <h3 className="px-4 text-sm font-semibold text-background-500">
        AVAILABLE SOURCES
      </h3>

      {annotators.length === 0 && (
        <div className="p-4">There is no available sources!.</div>
      )}
      {annotators.length > 0 && (
        <ul className="px-4 py-4 space-y-2">
          {annotators.map((option) => {
            return (
              <li key={option.value}>
                <div
                  className="flex items-center gap-2"
                  onClick={() => selectAnnotator(option.id)}
                >
                  <VBCheckbox
                    className="flex-none w-5 h-5"
                    value={!!option.selected}
                  />
                  <span>{option.label}</span>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

function TextAnnotationsList() {
  const { annotationMenuItems, selectAnnotation, selectLabel } =
    useTextAnnotationSourcesContext();

  function handleSelect(id: string, type: string) {
    if (type === "LABEL") {
      selectLabel(id);
    } else {
      selectAnnotation(id);
    }
  }
  return (
    <div className="flex-auto py-4 overflow-y-auto text-sm">
      <h3 className="px-4 text-sm font-semibold">ANNOTATIONS</h3>
      <div className="">
        {annotationMenuItems.map((item) => {
          return (
            <TextAnnotationItem
              item={item}
              key={item.id}
              onSelect={handleSelect}
            />
          );
        })}
      </div>
    </div>
  );
}

interface TextAnnotationItemProps {
  item: TextAnnotationMenuItem;
  onSelect(annotationId: string, action?: string): void;
}
export function TextAnnotationItem({
  item,
  onSelect,
}: TextAnnotationItemProps) {
  const [expanded, setExpanded] = useState(item.children.length > 0);
  return (
    <div className="relative">
      <div
        className={classNames("flex items-center gap-2 h-10 parent px-4", {
          "bg-primary-100": item.children.length > 0 && expanded,
        })}
      >
        {item.children.length > 0 && (
          <button onClick={() => setExpanded(!expanded)}>
            <IconArrowDown
              className={classNames("flex-none w-5 h-5", {
                "transform -rotate-90": !expanded,
              })}
            />
          </button>
        )}
        <VBCheckbox
          onChange={() => onSelect(item.id, item.type)}
          className="flex-none w-5 h-5"
          activeClassName="text-primary"
          value={!!item.selected}
        />
        <div
          className="flex-auto overflow-hidden truncate whitespace-nowrap overflow-ellipsis"
          onClick={() => onSelect(item.id, item.type)}
        >
          {item.name}
        </div>
      </div>

      {expanded && item.children.length > 0 && (
        <div className="pl-7">
          {item.children.map((child) => (
            <TextAnnotationItem
              key={child.id}
              item={child}
              onSelect={onSelect}
            />
          ))}
        </div>
      )}
    </div>
  );
}
