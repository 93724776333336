/*
 * File: user.dto.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 30th July 2021 2:37:47 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

export interface UserDTO {
  userName: string;
  email: string;
  role: string;
  userId: string;
}

// client only
export interface UserBatchModel extends UserDTO {
  dailyLimit: number;
  limit?: number;
  workInstructionId?: number
}

export const DEFAULT_DAILY_LIMIT = 2000;
