/*
 * File: smart-labeling.state.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th December 2021 4:22:56 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AnnotateType } from "constants/annotation.constant";
import { RequestStatus } from "store/base/base.state";

export interface ObjectDetectionClass {
  id: string;
  name: string;
  supportedAnnotationTypes: AnnotateType[];
  metadata?: any;
  model: string;
}

export interface AutoAnnotatePair {
  id: string;
  labelId: string;
  classId: string;
  model: string;
  selected?: boolean;
}

export interface SmartLabelingState {
  jobId: number;
  smartLabelingEnable: boolean;
  autoAnnotateEnable: boolean;
  avaiableClasses: { [key: string]: ObjectDetectionClass };
  labels: { [key: string]: ObjectDetectionClass };
  autoAnnotatePairs: AutoAnnotatePair[];
  status: RequestStatus;
}

export const INITIAL_SMART_LABELING_STATE: SmartLabelingState = {
  jobId: -1,
  status: RequestStatus.IDLE,
  smartLabelingEnable: false,
  autoAnnotateEnable: false,
  avaiableClasses: {},
  autoAnnotatePairs: [],
  labels: {},
};
