/*
 * File: unauthorized.page.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 31st July 2021 1:53:12 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppSelector } from "hooks/use-redux";
import { selectUserRole } from "store/auth/auth.selectors";
import { getRootRouteForRoles } from "configs/router.config";
import { Routes } from "routers/config/routes";

export const UnAuthorizedPage = () => {
  const { t } = useTranslation();
  const userRole = useAppSelector(selectUserRole);
  const rootRoute = getRootRouteForRoles(userRole) || Routes.ROOT;
  return (
    <div className="flex items-center justify-center w-screen h-screen bg-white md:bg-gray-100">
      <div className="relative w-full px-4 py-8 bg-white md:w-1/2 md:h-auto md:rounded-lg md:shadow-xl lg:w-1/3">
        <div className="text-center">
          <h1 className="my-4 text-3xl font-bold">
            {t("authorization:unauthorized.textSorry")}
          </h1>
          <p className="p-4 text-lg leading-8">
            {t("authorization:unauthorized.textNoAccess")}
          </p>

          <Link to={rootRoute}>
            <button className="px-8 py-2 my-4 text-lg text-white rounded outline-none bg-primary">
              {t("authorization:unauthorized.buttonReturnHome")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
