/*
 * File: index.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th March 2023 10:03:17 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { useMount } from "ahooks";
import { ENV_CONFIG, isUseRuntimeConfig } from "configs/env.config";
import { ReactNode, useState } from "react";
import * as Sentry from "@sentry/react";

interface Props {
  children?: ReactNode;
}

export function ApplicationLoader({ children }: Props) {
  const [loaded, setLoaded] = useState(!isUseRuntimeConfig());

  useMount(async () => {
    const useRunTime = isUseRuntimeConfig();
    if (!useRunTime) return;
    try {
      const response = await fetch(ENV_CONFIG.RUNTIME_CONFIG_URL);
      const config = await response.json();
      const keys = Object.keys(ENV_CONFIG);
      const prefix = "REACT_APP_";
      for (const key of keys) {
        const envKey = prefix + key.replace(prefix, "");
        if (envKey.includes("RUNTIME")) continue;
        if (config.hasOwnProperty(envKey)) {
          const configKey = envKey.replace(prefix, "");
          (ENV_CONFIG as any)[configKey] = config[envKey];
        }
      }
      setLoaded(true);
    } catch (error) {
      Sentry.captureException(error);
      console.log("ERROR", error);
      setLoaded(true);
    }
  });

  if (!loaded) return null;

  return <>{children}</>;
}

export default ApplicationLoader;
