/*
 * File: smart-labeling.slice.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th December 2021 4:27:35 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { smartLabelingReducerBuilder } from "./smart-labeling.thunk";
import {
  AutoAnnotatePair,
  INITIAL_SMART_LABELING_STATE,
  SmartLabelingState,
} from "./smart-labeling.state";

const SLICE_NAME: string = "smart-labeling";

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_SMART_LABELING_STATE,
  reducers: {
    resetSmartLabelingState(state: SmartLabelingState) {
      Object.assign(state, INITIAL_SMART_LABELING_STATE);
    },
    smartLabelingEnabled(
      state: SmartLabelingState,
      action: PayloadAction<boolean>
    ) {
      state.smartLabelingEnable = action.payload;
    },
    autoAnnotateEnabled(
      state: SmartLabelingState,
      action: PayloadAction<boolean>
    ) {
      state.autoAnnotateEnable = action.payload;
    },
    autoAnnotatePairsEdited(
      state: SmartLabelingState,
      action: PayloadAction<AutoAnnotatePair[]>
    ) {
      state.autoAnnotatePairs = action.payload;
    },
  },
  extraReducers: (builder) => {
    smartLabelingReducerBuilder(builder);
  },
});

export const {
  resetSmartLabelingState,
  smartLabelingEnabled,
  autoAnnotateEnabled,
  autoAnnotatePairsEdited,
} = slice.actions;

export const smartLabelingReducer = slice.reducer;
