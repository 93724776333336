import { classnames } from "utilities/classes";


export interface ColumnHeaderWrapperProps {
  first?: boolean;
  last?: boolean;
  children?: JSX.Element;
  maxWidth?: number;
  minWidth?: number;
}
export const ColumnHeaderWrapper = ({
  first,
  last,
  children,
  maxWidth,
  minWidth,
}: ColumnHeaderWrapperProps) => {
  return (
    <th
      className={classnames(
        "whitespace-nowrap text-background-700 text-left font-normal",
        {
          "rounded-l": !!first,
          "pr-4": !last,
          "rounded-r": !!last,
        }
      )}
      style={{ maxWidth: maxWidth, minWidth: minWidth, }}
    >
      {children}
    </th>
  );
};