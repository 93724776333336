/*
 * File: file-browse.state.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 23rd June 2023 4:57:46 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { Filter, ICommand, IPagination, ViewModeEnum } from "domain/common";
import { QueryApi } from "data-access/impl/query";

export interface FileBrowseState {
  files: QueryApi.IFile[];
  pagination: IPagination;
  loading?: boolean;
  filter: Filter;
  selectedFileIds: number[];
  fileId: number;
  viewMode?: ViewModeEnum;

  updateViewMode(viewMode: ViewModeEnum): void;
  updateFilter(filter: Filter): void;
  selectFile(fileIds: number[]): void;
  openFile(fileId: number): void;
  reloadFiles(): void;
  executeCommand(command: ICommand<any>): void;
}

export const defaultFileBrowseState = {} as FileBrowseState;
