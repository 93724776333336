/*
 * File: batch-detail.context.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 6th January 2022 10:06:00 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createContext, useContext } from "react";
import { defaultBatchDetailState } from "./batch-detail.state";

export const BatchDetailContext = createContext(defaultBatchDetailState);

export const useBatchDetailContext = () => {
  return useContext(BatchDetailContext);
};
