/*
 * File: project.api.ts
 * Project: app-aiscaler-web
 * File Created: Friday, 16th July 2021 9:33:41 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { ProjectDTO } from "../dtos/project.dto";
import { BaseAPI } from "./base.api";

export class ProjectAPI extends BaseAPI<ProjectDTO> {
  getTypes() {
    return this.get(`${this.getPath()}/types`);
  }
  getStatistic(itemId: number) {
    return this.get(`${this.getPath()}/${itemId}/statistic`);
  }
  getDistribution(itemId: number) {
    return this.get(`${this.getPath()}/${itemId}/label-distribution`);
  }
  getF1Distribution(itemId: number) {
    return this.get(`${this.getPath()}/${itemId}/f1`);
  }
  getProjectsByTaskCompleted(page: number) {
    return this.get(`${this.getPath()}/by-task-completed?size=${page}`);
  }
}
