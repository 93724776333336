/*
 * File: project.selectors.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { LabelingType } from "constants/labeling.constant";
import {
  isAudioProject,
  isGeneralImageProject,
  isImageProject,
  isMedicalMamoProject,
  isTextProject,
  isWsiProject,
} from "services/label-service/dtos";
import { selectSystemColors } from "store/common/app-setting/app-setting.selectors";
import { generateAnyColor } from "utilities/color/color.utils";
import { RootState } from "../..";

export const selectCurrentProject = (state: RootState) => {
  return state.project.project;
};

export const selectCurrentProjectForCreatingBatch = (state: RootState) => {
  return state.project.projectForCreatingBatch;
};

export const selectProjectBatches = (state: RootState) => {
  return state.project.batches;
};

export const selectProjectType = (state: RootState) => {
  return state.project.project?.type;
};
export const selectProjectSubType = (state: RootState) => {
  return state.project.project?.subType;
};
export const selectIsImageProject = (state: RootState) => {
  const type = state.project.project?.type;
  return isImageProject(type);
};
export const selectIsMedicalMamoProject = (state: RootState) => {
  const type = state.project.project?.type;
  return (
    type &&
    isMedicalMamoProject(type, state.project.project?.settings.pollStrategy)
  );
};
export const selectIsTextProject = (state: RootState) => {
  const type = state.project.project?.type;
  return isTextProject(type);
};
export const selectIsAudioProject = (state: RootState) => {
  const type = state.project.project?.type;
  return isAudioProject(type);
};
export const selectProjectExportTypes = (state: RootState) => {
  return state.project.project?.exportTypes || [];
};
export const selectIsAudioSpeechToTextProject = (state: RootState) => {
  const type = state.project.project?.type;
  return type === LabelingType.AUDIO_STT;
};

export const selectIsTextTextToSpeechProject = (state: RootState) => {
  const type = state.project.project?.type;
  return type === LabelingType.TEXT_TTS;
};

export const selectIsTextLabelingProject = (state: RootState) => {
  const type = state.project.project?.type;
  return type === LabelingType.TEXT_NER_RE || type === LabelingType.TEXT_NER;
};

export const selectIsMdiSegmentation = (state: RootState) => {
  const type = state.project.project?.type;
  return (
    type === LabelingType.MED_MDI_SEGMENTATION ||
    type === LabelingType.MDI_SEGMENTATION
  );
};

export const selectIsWsiProject = (state: RootState) => {
  const type = state.project.project?.type;
  return isWsiProject(type);
};

export const selectObservations =
  (excludeSystem = false) =>
  (state: RootState) => {
    const { observations } = state.project;
    if (!excludeSystem) return observations;
    return observations.filter(
      (observation) => !observation.observationSetting?.systemAttribute
    );
  };

export const selectProjectObservations = (state: RootState) => {
  return state.project.observations;
};

export const selectProjectRequesting = (state: RootState) => {
  return state.project.requesting;
};

export const selectProjectObservationAnnotationTypes = (state: RootState) => {
  return state.project.observationAnnotationTypes;
};

export const selectActiveLearningVisible = (state: RootState) => {
  const type = state.project.project?.type;
  return (
    type === LabelingType.IMAGE_DETECTION ||
    type === LabelingType.IMAGE_SEGMENTATION
  );
};

export const selectIsEvaluationBatchVisible = (state: RootState) => {
  const type = state.project.project?.type;
  return isGeneralImageProject(type);
};

export const selectProjectLabelColors = (state: RootState) => {
  const projectObservations = selectProjectObservations(state);
  return projectObservations
    .filter(
      (projectObservation) =>
        !projectObservation.observationSetting.systemAttribute
    )
    .map((projectObservation) => {
      return projectObservation.observationSetting.color;
    });
};

export const selectNextLabelColor = (state: RootState) => {
  const remainProjectLabelColors = selectRemainLabelColors(state);

  if (!remainProjectLabelColors || !remainProjectLabelColors.length) {
    return generateAnyColor();
  }

  return remainProjectLabelColors[0];
};

export const selectRemainLabelColors = (state: RootState) => {
  const projectLabelColors = selectProjectLabelColors(state);
  const systemColors = selectSystemColors(state);

  return systemColors.filter((color) => {
    return (
      projectLabelColors.findIndex(
        (labelColor) => labelColor.toLowerCase() === color.toLowerCase()
      ) === -1
    );
  });
};

export const selectProjectWorkflow = (state: RootState) => {
  return state.project.workflow;
};

export const selectProjectWorkManagements = (state: RootState) => {
  return state.project.workManagements;
};

export const selectIsProjectFromTemplate = (state: RootState) => {
  const projectForCreatingBatch = selectCurrentProjectForCreatingBatch(state);
  return (
    projectForCreatingBatch?.workflow && projectForCreatingBatch?.workflow.id
  );
};
