/*
 * File: vb-datagrid-no-border.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 9th September 2021 3:55:40 pm
 * Author: Thoai Ly (v.thoaily@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { DataGrid, DataGridProps } from "@material-ui/data-grid";
import { withStyles } from "@material-ui/styles";
import { VBDatagridCellHoverDisplay } from "components/common/vb-datagrid/vb-datagrid-cell-hover-display";

const StyledDataGrid = withStyles({
  root: {
    border: 0,
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
  columnHeader: {
    fontWeight: "bold",
    color: "gray",
    fontSize: "12px",
    textTransform: "uppercase",
  },
})(DataGrid);

export const VBDatagridNoBorder = ({ columns, ...params }: DataGridProps) => {
  const titledColumns = columns.map(column => ({
    ...column,
    renderCell: column.renderCell? column.renderCell : VBDatagridCellHoverDisplay
  }))
  return <StyledDataGrid columns={titledColumns} {...params} />;
};
