/*
 * File: vb-inline-loading.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 26th August 2021 2:41:45 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { VBSpinner } from "components/common/vb-spinner/vb-spinner.component";
import { HTMLProps } from "react";

interface VBInlineLoadingProps extends HTMLProps<HTMLDivElement> {
  spinnerClass?: string;
}

export const VBInlineLoading = ({
  spinnerClass,
  className = "flex items-center justify-center py-12",
  ...rest
}: VBInlineLoadingProps) => {
  return (
    <div {...rest} className={className}>
      <VBSpinner className={spinnerClass} />
    </div>
  );
};
