/*
 * File: text-labeling-batch.slice.ts
 * Project: app-aiscaler-web
 * File Created: Sunday, 10th July 2022 10:05:24 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  defaultTextLabelingTaskState,
  TextLabelingTaskState,
} from "./text-labeling-task.state";
import { textLabelingTaskReducerBuilder } from "./text-labeling-task.thunk";

const SLICE_NAME: string = "text-labeling-task";

const slice = createSlice({
  name: SLICE_NAME,
  initialState: defaultTextLabelingTaskState,
  reducers: {
    resetTextLabelingTaskState(state: TextLabelingTaskState) {
      Object.assign(state, defaultTextLabelingTaskState);
    },
    assigneeSelected(
      state: TextLabelingTaskState,
      action: PayloadAction<string>
    ) {
      state.selectedAssignees = [action.payload];
    },
    textLabelingTaskChanged(state: TextLabelingTaskState) {
      Object.assign(state, defaultTextLabelingTaskState);
    },
  },
  extraReducers: (builder) => {
    textLabelingTaskReducerBuilder(builder);
  },
});

export const {
  resetTextLabelingTaskState,
  assigneeSelected,
  textLabelingTaskChanged,
} = slice.actions;

export const textLabelingTaskReducer = slice.reducer;
