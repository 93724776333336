/*
 * File: user-cache.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 28th June 2021 2:49:55 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { User } from "../../store/auth/auth.state";

export class UserCache {
  private static data: { [key: string]: User } = {};

  public static clear() {
    this.data = {};
  }

  public static addUser(user: User) {
    this.data[user.uid] = user;
  }

  public static getUser(uid: string) {
    return this.data[uid];
  }
}

export function getUserName(id: string, defaultValue = id) {
  const user = UserCache.getUser(id);
  if (user) return user.displayName;
  return defaultValue;
}
