/*
 * File: use-batch-statistic.hook.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 23rd August 2021 11:33:04 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { enqueueErrorNotification } from "store/common/notification/notification.actions";
import { Logger } from "utilities/logger";
import { handleThunkRejected } from "utilities/redux/redux.utils";
import {
  selectBatchStatistic,
  selectBatchStatisticRequestError,
  selectBatchStatisticRequestStatus,
} from "store/customer/batch-data/statistic/batch-statistic.selectors";
import { loadBatchStatisticAsync } from "store/customer/batch-data/statistic/batch-statistic.thunk";
import { removeStatistic } from "store/customer/batch-data/batch-data.slice";
import * as Sentry from "@sentry/react";

export const useBatchStatistic = (batchId: number) => {
  const dispatch = useAppDispatch();
  const statistic = useAppSelector(selectBatchStatistic(batchId));
  const requestError = useAppSelector(selectBatchStatisticRequestError);
  const requestStatus = useAppSelector(selectBatchStatisticRequestStatus);
  const labelersStatistic = useMemo(() => {
    if (!statistic || !statistic.userStatistic) return [];
    return statistic.userStatistic.map((user) => {
      const totalCase = user.groups.reduce((s, group) => {
        return s + group.totalCase;
      }, 0);
      const totalRelation = user.groups.reduce((s, group) => {
        return s + (group?.totalRelation ?? 0);
      }, 0);

      return {
        id: user.email,
        account: user.email,
        startWorkingTime: user.startDate,
        lastWorkingTime: user.lastModifiedDate,
        finished: user.totalJob,
        rejected: 0,
        totalCase: totalCase || 0,
        totalRelation,
        totalAnnotationTime: user.totalAnnotationTime,
        totalIssue: user.totalIssue,
        avgTimePerAnnotation: user.avgTimePerAnnotation,
        avgTimePerJob: user.avgTimePerJob,
        avgAnnotationPerJob: user.avgAnnotationPerJob,
      };
    });
  }, [statistic]);

  const requestData = async (forceRefresh = false) => {
    if (forceRefresh) dispatch(removeStatistic(batchId));
    return dispatch(loadBatchStatisticAsync(batchId));
  };

  useEffect(() => {
    const _loadBatchAsync = async (id: number) => {
      try {
        dispatch(removeStatistic(id));
        const response = await dispatch(loadBatchStatisticAsync(id));
        handleThunkRejected(response);
      } catch (err: any) {
        Sentry.captureException(err);
        const errMessage = err.message || "Failed to get batch overview";
        dispatch(enqueueErrorNotification(errMessage));
        Logger.log(err);
      }
    };
    if (batchId) _loadBatchAsync(batchId);
  }, [dispatch, batchId]);

  return {
    requestData,
    statistic,
    labelersStatistic,
    requestError,
    requestStatus,
  };
};
