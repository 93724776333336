/*
 * File: batch-data.state.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 28th August 2021 10:51:28 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import {
  INITIAL_LABEL_DISTRIBUTION_STATE,
  LabelDistributionState,
} from "store/customer/project-data/label-distribution/label-distribution.state";
import {
  LabelerDistributionState,
  INITIAL_LABELER_DISTRIBUTION_STATE,
} from "./labeler-distribution/labeler-distribution.state";
import {
  BatchStatisticState,
  INITIAL_BATCH_STATISTIC_STATE,
} from "./statistic/batch-statistic.state";

export interface BatchDataState {
  statistic: BatchStatisticState;
  labelerDistribution: LabelerDistributionState;
  labelDistribution: LabelDistributionState;
}

export const INITIAL_BATCH_DATA_STATE = {
  statistic: INITIAL_BATCH_STATISTIC_STATE,
  labelerDistribution: INITIAL_LABELER_DISTRIBUTION_STATE,
  labelDistribution: INITIAL_LABEL_DISTRIBUTION_STATE,
};
