import { AnalyticsQueryResponse } from "services/analytics-service";
import { RowDataTableProject } from "../components/project-table.component";

const COL_ID = "id";
const COL_NAME = "name";
const COL_BATCH_COUNT_INITIAL = "batchcountinitial";
const COL_BATCH_COUNT_WORKING = "batchcountworking";
const COL_BATCH_COUNT_COMPLETED = "batchcountcompleted";
const COL_LABELING_HOURS = "labelinghours";

export const rowTableProjectMapper = (response: AnalyticsQueryResponse): RowDataTableProject[] => {
  const rows = response.data.rows;
  if (rows && rows.length > 0) {
    return rows.map((row: any) => {
      const numBatchInitial = row[COL_BATCH_COUNT_INITIAL] || 0;
      const numBatchWorking = row[COL_BATCH_COUNT_WORKING] || 0;
      const numBatchCompleted = row[COL_BATCH_COUNT_COMPLETED] || 0;
      const numBatch = numBatchInitial + numBatchWorking + numBatchCompleted;

      const tableRow: RowDataTableProject = {
        id: row[COL_ID],
        name: row[COL_NAME],
        numBatch,
        numBatchInitial,
        numBatchWorking,
        numBatchCompleted,
        numCompletedTask: 0,
        numLabelingHours: parseFloat((row[COL_LABELING_HOURS] || 0 as number).toFixed(2)),
      };
      return tableRow;
    });
  }
  return [];
}