import { Backdrop, LinearProgress } from "@material-ui/core";
import { MatModal } from "components/material/mat-modal.component";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { BatchDetailDialog } from "pages/labeler/image-labeling/components/batch-detail-dialog/batch-detail-dialog.component";
import { usePreviousBatch } from "pages/labeler/image-labeling/hooks/use-previous-batch.hook";
import { ConfirmExitDialog } from "pages/labeler/text-labeling/components/confirm-exit-dialog.component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Routes } from "routers/config/routes";
import { AnnotationsService } from "services/label-service";
import { BatchObservationDTO, TaskDTO } from "services/label-service/dtos";
import {
  AnnotationJobRequestDTO,
  AnnotationRequestDTO,
  SttAnnotationItem,
  SttTextLabel,
} from "services/label-service/dtos/annotations.dto";
import { jobMapper } from "services/label-service/mappers/job.mapper";
import { RequestStatus } from "store/base/base.state";
import { selectIssueManagementDoesHaveWorkingIssues } from "store/common/issue-management/issue-management.selectors";
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from "store/common/notification/notification.actions";

import {
  setComplexJobWorkingStatus,
  deleteComplexJobsJobInBatch,
  setComplexJobWorkingStatusByJobId,
} from "store/labeler/complex-jobs/complex-jobs.slice";
import {
  ComplexJobInBatch,
  WorkingStatus,
} from "store/labeler/complex-jobs/complex-jobs.state";
import { LoadCombplexJobResponse } from "store/labeler/complex-jobs/complex-jobs.thunk";
import { AppError } from "utilities/errors/errors";
import { useLoadComplexJob } from "../hooks/use-load-complex-job.hook";
import { useTranscribeAudio } from "../hooks/use-transcribe-audio.hook";
import { SpeechToTextLabelingContext } from "./speech-to-text-labeling.context";
import {
  DEFAULT_UI_JOB,
  SpeechToTextJobUIModel,
  SpeechToTextLabelUIModel,
} from "./speech-to-text-labeling.state";
import * as Sentry from "@sentry/react";

export interface ComplexJobProviderProps {
  children: JSX.Element;
  // for labler screen in batch
  isLoadFromBatch?: boolean;
  jobInBatch?: ComplexJobInBatch;
  // for task review
  isTaskReview?: boolean;
  taskToReview?: TaskDTO;
  jobIdsIncludedOnly?: number[];
  // for job review (issue reopen case)
  isFromJobId?: boolean;
  jobIdParam?: string;
}

interface Props extends ComplexJobProviderProps {}

export const SpeechToTextLabelingProvider = ({
  children,
  isLoadFromBatch = false,
  jobInBatch,
  isTaskReview,
  taskToReview,
  jobIdsIncludedOnly,
  isFromJobId,
  jobIdParam,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const history = useHistory();
  const [confirmExit, setShowConfirmExit] = useState(false);
  const handleConfirmExit = () => setShowConfirmExit(true);

  const [isAudioReady, setIsAudioReady] = useState(false);

  const [uiJob, setUIJob] = useState<SpeechToTextJobUIModel>();
  const { showBatchInstruction, setShowBatchInstruction } = usePreviousBatch(
    uiJob?.job ? jobMapper.fromDTO(uiJob?.job) : undefined
  );

  const {
    prevUseAITranscribeValue,
    useAITranscribe,
    setUseAITranscribe,
    transcribeAudio,
    isTranscribing,
  } = useTranscribeAudio();
  const [hasChanged, setHasChanged] = useState(false);

  const hasWorkingIssues = useAppSelector(
    selectIssueManagementDoesHaveWorkingIssues(
      uiJob?.task?.id || -1,
      undefined,
      uiJob?.job?.id
    )
  );

  useEffect(() => {
    if (
      useAITranscribe &&
      !prevUseAITranscribeValue &&
      prevUseAITranscribeValue !== undefined &&
      uiJob &&
      !uiJob.isStepReviewJob
    ) {
      const updateTranscribeText = async () => {
        const text = await transcribeAudio(uiJob.fileSasUrl);
        setUIJob({
          ...uiJob,
          text,
        });
      };
      updateTranscribeText();
    }
  }, [prevUseAITranscribeValue, useAITranscribe, uiJob, transcribeAudio]);

  const loadJobFromComplexRes = useCallback(
    async (jobComplexRes: LoadCombplexJobResponse, uiJobData?: any) => {
      setIsAudioReady(false);
      if (uiJobData) {
        setUIJob({ ...uiJobData });
        return;
      }

      const {
        job,
        isStepReviewJob,
        canViewPreviousStepResult,
        file,
        task,
        batch,
        annotations,
        previousJobsAnnotations,
        jobOptions,
        batchObservations,
        acceptBatchObservation,
        rejectBatchObservation,
        reopenReason,
        isTaskReview,
        isFromJobId,
        isFromBatch,
        cachedAITranscribedText,
        error,
      } = jobComplexRes;

      if (error) {
        throw error;
      }

      if (!file || !task || !batch) {
        throw new AppError(
          "unknow",
          "Unknow error after calling loadJobComplex function"
        );
      }
      const hasSavedData = annotations.length > 0;

      let uiJobCommon: SpeechToTextJobUIModel = {
        ...DEFAULT_UI_JOB,
        audioUrl: file.url, // this url is local cache url
        fileSasUrl: file.originalUrl || "",
        batchObservations,
        job,
        isStepReviewJob,
        canViewPreviousStepResult,
        task,
        batch,
        project: batch.project,
        countDownSecond: 0,
        previousJobsAnnotations,
        jobOptions,
        acceptBatchObservation,
        rejectBatchObservation,
        reopenReason,
        isTaskReview,
        isFromJobId,
        isFromBatch,
        cachedAITranscribedText,
      };
      if (hasSavedData) {
        const savedAnno = annotations[0].annotation as SttAnnotationItem;
        let text = savedAnno.text || "";

        if (useAITranscribe && !text && !isStepReviewJob) {
          text = await transcribeAudio(
            uiJobCommon.fileSasUrl,
            cachedAITranscribedText
          );
        }

        uiJobCommon = {
          ...uiJobCommon,
          text,
          note: savedAnno.note || "",
          labels: convertSTTSavedLabelsToUILabels(
            batchObservations,
            savedAnno.textLabels || []
          ),
          observationId: savedAnno.observationId,
          probability: savedAnno.probability,
        };
      } else {
        let text = "";
        if (useAITranscribe && !isStepReviewJob && !isTaskReview) {
          text = await transcribeAudio(
            uiJobCommon.fileSasUrl,
            cachedAITranscribedText
          );
        }
        uiJobCommon = {
          ...uiJobCommon,
          text,
          labels: convertSTTSavedLabelsToUILabels(batchObservations, []),
        };
      }

      setUIJob(uiJobCommon);
    },
    [useAITranscribe, transcribeAudio]
  );

  const {
    loadJobStatus,
    savingJobStatus,
    setSavingJobStatus,
    isSavingJob,
    isJobLoaded,
    error,
    setError,
    skipJob,
  } = useLoadComplexJob({
    providerPayload: {
      isLoadFromBatch,
      jobInBatch,
      isTaskReview,
      taskToReview,
      jobIdsIncludedOnly,
      isFromJobId,
      jobIdParam,
    },
    loadJobFromComplexResCallback: loadJobFromComplexRes,
  });
  const isLoadingJob = useMemo(
    () => loadJobStatus === RequestStatus.LOADING || !isAudioReady,
    [loadJobStatus, isAudioReady]
  );

  const convertSTTSavedLabelsToUILabels = (
    batchObservations: BatchObservationDTO[],
    savedSTTLabels: SttTextLabel[]
  ): SpeechToTextLabelUIModel[] => {
    const res: SpeechToTextLabelUIModel[] = [];

    for (const batchObservation of batchObservations) {
      const savedLabel = savedSTTLabels.find(
        (l) => l.observationId === batchObservation.observation.id
      );
      const model: SpeechToTextLabelUIModel = {
        label: savedLabel,
        observation: batchObservation.observation,
        selected: !!savedLabel,
        visible: true,
        showChildren: true,
      };
      res.push(model);
    }

    return res;
  };

  const convertSTTUILabelsToSavedLabels = (
    labels: SpeechToTextLabelUIModel[]
  ): SttTextLabel[] => {
    return labels
      .filter((l) => l.selected)
      .map((l) => ({
        start: 0,
        end: 0,
        observationId: l.observation.id,
      }));
  };

  const serializeUIJob = useCallback(
    (
      uiJob: SpeechToTextJobUIModel,
      accept = false,
      reject = false
    ): AnnotationJobRequestDTO => {
      const annoItem: SttAnnotationItem = {
        text: uiJob.isStepReviewJob ? "" : uiJob.text,
        note: uiJob.isStepReviewJob ? "" : uiJob.note,
        probability: uiJob.probability,
        textLabels: uiJob.isStepReviewJob
          ? []
          : convertSTTUILabelsToSavedLabels(uiJob.labels),
      };
      if (accept && uiJob.acceptBatchObservation) {
        annoItem.observationId = uiJob.acceptBatchObservation.observation.id;
      } else if (reject && uiJob.rejectBatchObservation) {
        annoItem.observationId = uiJob.rejectBatchObservation.observation.id;
      }
      const requestPayload: AnnotationJobRequestDTO = {
        annotations: [
          {
            observationId:
              !!annoItem.observationId && annoItem.observationId > 0
                ? annoItem.observationId
                : undefined,
            annotation: annoItem,
          },
        ],
      };

      return requestPayload;
    },
    []
  );

  const saveJob = useCallback(
    async (
      force = false,
      finish = false,
      accept = false,
      reject = false,
      jobId = 0
    ) => {
      if (savingJobStatus === RequestStatus.LOADING) return;
      if (savingJobStatus !== RequestStatus.IDLE && !force) return;
      if (!uiJob) return;

      const id = jobId || uiJob.job?.id;
      setSavingJobStatus(RequestStatus.LOADING);
      try {
        const validateUIJob = (uiJob: SpeechToTextJobUIModel) => {
          if (!uiJob.text && !uiJob.isStepReviewJob) {
            throw new AppError(
              "invalid_input",
              t("labelerworkspace:stt.errorEmptyText")
            );
          }
        };

        if (!uiJob.job) return;

        validateUIJob(uiJob);
        const payload: AnnotationJobRequestDTO = serializeUIJob(
          uiJob,
          accept,
          reject
        );
        if (finish) {
          if (accept && uiJob.isStepReviewJob && hasWorkingIssues) {
            throw new AppError(
              "have_working_issues",
              t("labelerworkspace:stt.errorHaveWorkingIssues")
            );
          }

          await AnnotationsService.saveAnnotationsForJobId(id, payload);
          await AnnotationsService.finishJob(id);

          if (uiJob.job && uiJob.isFromBatch) {
            dispatch(deleteComplexJobsJobInBatch(id));
          } else if (uiJob.isFromJobId) {
            history.push(Routes.LABELER_HOME);
          }
        } else {
          await AnnotationsService.saveAnnotationsForJobId(id, payload);
        }

        setSavingJobStatus(RequestStatus.SUCCESS);
        setHasChanged(false);
        dispatch(
          setComplexJobWorkingStatusByJobId({
            jobId: id,
            status: WorkingStatus.SAVED,
          })
        );

        if (finish) {
          dispatch(enqueueSuccessNotification(t("common:textCompleted")));
        } else {
          dispatch(enqueueSuccessNotification(t("common:textSaved")));
        }
      } catch (err: any) {
        Sentry.captureException(err);
        setSavingJobStatus(RequestStatus.FAILURE);
        if (err instanceof AppError) {
          dispatch(enqueueErrorNotification(err.message));
        } else {
          if (finish) {
            dispatch(enqueueErrorNotification(t("common:textCompletedFailed")));
          } else {
            dispatch(enqueueErrorNotification(t("common:textSavedFailed")));
          }
        }
      }
    },
    [
      dispatch,
      hasWorkingIssues,
      history,
      savingJobStatus,
      serializeUIJob,
      setSavingJobStatus,
      t,
      uiJob,
    ]
  );

  const overwriteUIJob = (annotation: AnnotationRequestDTO | undefined) => {
    if (!uiJob) return;

    if (annotation) {
      const annoItem = annotation.annotation as SttAnnotationItem;
      setUIJob({
        ...uiJob,
        text: annoItem.text || "",
        note: annoItem.note || "",
        labels: convertSTTSavedLabelsToUILabels(
          uiJob.batchObservations,
          annoItem.textLabels || []
        ),
      });
    } else {
      setUIJob({
        ...uiJob,
        text: "",
        note: "",
        labels: convertSTTSavedLabelsToUILabels(uiJob.batchObservations, []),
      });
    }
    setHasChanged(true);
    dispatch(setComplexJobWorkingStatus(WorkingStatus.WORKING));
  };

  const state = {
    uiJob,
    setUIJob,
    isLoadingJob,
    isSavingJob,
    saveJob,
    skipJob,
    handleConfirmExit,
    useAITranscribe,
    setUseAITranscribe,
    isTranscribing,
    error,
    setError,
    hasChanged,
    setHasChanged,
    isAudioReady,
    setIsAudioReady,
    overwriteUIJob,
  };

  return (
    <SpeechToTextLabelingContext.Provider value={state}>
      {children}
      {(isLoadingJob || isSavingJob) && <MaskLoading />}
      {confirmExit && (
        <ConfirmExitDialog
          visible
          onClose={() => setShowConfirmExit(false)}
          onSubmit={() => history.push(Routes.LABELER_HOME)}
        />
      )}
      {isJobLoaded &&
        showBatchInstruction &&
        !isTaskReview &&
        uiJob?.batch &&
        uiJob.project && (
          <BatchDetailDialog
            batch={uiJob.batch}
            project={uiJob.project}
            onClose={() => setShowBatchInstruction(false)}
          />
        )}
    </SpeechToTextLabelingContext.Provider>
  );
};

const MaskLoading = () => {
  return (
    <MatModal
      open
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      className="flex items-start justify-center"
    >
      <div className="w-full h-full">
        <LinearProgress />
        <div className="flex items-center justify-center w-full h-full"></div>
      </div>
    </MatModal>
  );
};
