import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IssueCommentModel, IssueModel } from "pages/customer/projects/project-batch/batch-detail/pages/tasks/components/issue/issue-management.models";
import { INITIAL_ISSUE_MANAGEMENT_STORE_STATE, IssueManagementStoreState } from "./issue-management.state";
import { issueManagementReducerBuilder } from "./issue-management.thunk";


const SLICE_NAME = "issueManagement";

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_ISSUE_MANAGEMENT_STORE_STATE,
  reducers: {
    issueManagementReset: (
      state: IssueManagementStoreState,
    ) => {
      Object.assign(state, INITIAL_ISSUE_MANAGEMENT_STORE_STATE);
    },
    setIsCreatingIssueMode: (
      state: IssueManagementStoreState,
      action: PayloadAction<boolean>
    ) => {
      state.isCreatingIssueMode = action.payload;
    },
    setIssueFilter: (
      state: IssueManagementStoreState,
      action: PayloadAction<string>
    ) => {
      state.issueFilter = action.payload;
    },
    updateIssue: (
      state: IssueManagementStoreState,
      action: PayloadAction<IssueModel>,
    ) => {
      const updatedIssue = action.payload;
      
      state.issues = state.issues.map(issue => {
        if (issue.id === updatedIssue.id) {
          return updatedIssue;
        }
        return issue;
      });
    },
    setSelectedIssueId: (
      state: IssueManagementStoreState,
      action: PayloadAction<number | undefined>,
    ) => {
      state.selectedIssueId = action.payload;
    },
    setIssueManagementIsReviewer: (
      state: IssueManagementStoreState,
      action: PayloadAction<boolean>,
    ) => {
      state.isReviewer = action.payload;
    },
    nextSelectedIssue: (state: IssueManagementStoreState) => {

    },
    previousSelectedIssue: (state: IssueManagementStoreState) => {
      
    },
    issueManagementResetState: (state: IssueManagementStoreState) => {
      state.issues = [];
      state.isCreatingIssueMode = false;
      state.selectedIssueId =  undefined;
    },
    updateIssueComment: (
      state: IssueManagementStoreState,
      action: PayloadAction<IssueCommentModel>,
    ) => {
      const updatedComment = action.payload;
      state.issues = state.issues.map(issue => {
        if (issue.id === updatedComment.issueId){
          return {
            ...issue,
            comments: issue.comments.map(comment => {
              if (comment.id === updatedComment.id) return updatedComment;
              return comment;
            })
          }
        }
        return issue;
      });
    },
    deleteIssueComment: (
      state: IssueManagementStoreState,
      action: PayloadAction<IssueCommentModel>,
    ) => {
      const updatedComment = action.payload;

      state.issues = state.issues.map(issue => {
        if (issue.id === updatedComment.issueId && issue.comments.length > 1) {
          return {
            ...issue,
            comments: issue.comments.filter(comment => comment.id !== updatedComment.id),
          }
        }
        return issue;
      });
    },
  },
  extraReducers: (builder) => {
    issueManagementReducerBuilder(builder);
  }
});

export const {
  issueManagementReset,
  setIsCreatingIssueMode,
  setIssueManagementIsReviewer,
  setIssueFilter,
  updateIssue,
  setSelectedIssueId,
  nextSelectedIssue,
  previousSelectedIssue,
  updateIssueComment,
  deleteIssueComment,
} = slice.actions;

export const issueManagementReducer = slice.reducer;