/*
 * File: label-menu.component.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 15th October 2021 11:32:05 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useKeyPress, useSize } from "ahooks";
import { Label, LabelType } from "domain/text-labeling";
import { MouseEvent, useMemo, useRef, useState } from "react";
import { Point } from "utilities/math/point";

export interface LabelMenuState {
  position: Point;
  open: boolean;
}

export const DEFAULT_CONTEXT_MENU_STATE: LabelMenuState = {
  open: false,
  position: { x: -999, y: 0 },
};

interface Size {
  width?: number;
  height?: number;
}

interface Props {
  position: Point;
  labels: Label[];
  onSelect?(label: Label): void;
  onClose?(): void;
  containerSize?: Size;
  type?: LabelType;
}

export const LabelMenu = ({
  position,
  labels,
  onSelect,
  onClose,
  containerSize,
  type = LabelType.OBJECT,
}: Props) => {
  const availableLabels = useMemo(() => {
    return labels.filter((lb) => {
      return (
        !lb.disableSelection &&
        !lb.observation.observationSetting.systemAttribute &&
        lb.type === type
      );
    });
  }, [labels, type]);
  const [items, setItems] = useState(availableLabels);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const size = useSize(containerRef);
  const stickedPosition = useMemo(() => {
    if (
      !containerSize?.width ||
      !containerSize?.height ||
      !size?.width ||
      !size?.height
    )
      return null;

    let x = position.x;
    let y = position.y;

    x = Math.min(position.x, containerSize.width - size.width - 16);
    if (y > containerSize.height - size.height - 16) {
      y = y - 16 - size.height - 40;
    }

    return { x, y };
  }, [containerSize, size, position]);

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const selectLabel = (label: Label) => {
    if (inputRef.current) inputRef.current.value = "";
    setItems([...availableLabels]);
    onSelect && onSelect(label);
  };

  const handleSelectLabel = (event: MouseEvent, label: Label) => {
    event.preventDefault();
    event.stopPropagation();
    selectLabel(label);
  };
  const handleInputChange = () => {
    const text = inputRef.current?.value as string;
    setItems(
      availableLabels.filter((label) => {
        return label.name.toLowerCase().indexOf(text.toLowerCase()) !== -1;
      })
    );
  };

  useKeyPress("1", () => {
    if (availableLabels.length < 0) return;
    selectLabel(availableLabels[0]);
  });
  useKeyPress("2", () => {
    if (availableLabels.length < 1) return;
    selectLabel(availableLabels[1]);
  });
  useKeyPress("3", () => {
    if (availableLabels.length < 2) return;
    selectLabel(availableLabels[2]);
  });
  useKeyPress("4", () => {
    if (availableLabels.length < 3) return;
    selectLabel(availableLabels[3]);
  });
  useKeyPress("5", () => {
    if (availableLabels.length < 4) return;
    selectLabel(availableLabels[4]);
  });
  useKeyPress("6", () => {
    if (availableLabels.length < 5) return;
    selectLabel(availableLabels[5]);
  });
  useKeyPress("7", () => {
    if (availableLabels.length < 6) return;
    selectLabel(availableLabels[6]);
  });
  useKeyPress("8", () => {
    if (availableLabels.length < 7) return;
    selectLabel(availableLabels[7]);
  });
  useKeyPress("9", () => {
    if (availableLabels.length < 8) return;
    selectLabel(availableLabels[8]);
  });
  useKeyPress("escape", () => {
    onClose && onClose();
  });

  return (
    <div
      ref={containerRef}
      style={{
        top: stickedPosition?.y || -999,
        left: stickedPosition?.x || -999,
        minWidth: "5rem",
        maxHeight: "20rem",
        opacity: stickedPosition ? 1 : 0,
      }}
      className="absolute z-10 flex flex-col py-2 overflow-hidden bg-white rounded shadow-lg"
      onClick={handleClick}
    >
      <div className="flex-none p-4">
        <input
          ref={inputRef}
          onChange={handleInputChange}
          type="text"
          className="w-full px-4 py-1 border rounded focus:outline-none"
          placeholder="Search annotation..."
        />
      </div>
      <ul className="flex-auto pb-4 overflow-y-auto">
        {items.map((label, index) => {
          return (
            <li
              className="flex items-center px-4 py-1 cursor-pointer hover:bg-coolGray-100 text-coolGray-800 hover:text-coolGray-900 flex-nowrap"
              key={index}
              onClick={(event) => handleSelectLabel(event, label)}
            >
              <span
                className="flex-shrink-0 w-2 h-2 rounded-full"
                style={{ backgroundColor: label.color ? label.color : "#000" }}
              ></span>
              <span className="flex-1 px-2 overflow-hidden whitespace-nowrap overflow-ellipsis">
                {label.name}
              </span>
              {index < 9 && (
                <span className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-sm border rounded border-coolGray-400">
                  {index + 1}
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
