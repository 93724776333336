/*
 * File: batch.slice.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 18th August 2021 5:36:41 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StorageKeys } from "hooks/storage/storage-keys";
import { BatchState, INITIAL_BATCH_STATE } from "./batch.state";
import { batchReducerBuilder } from "./batch.thunk";

const SLICE_NAME: string = "batch";
const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_BATCH_STATE,
  reducers: {
    resetBatchState(state: BatchState) {
      Object.assign(state, INITIAL_BATCH_STATE);
    },
    setLabelConfigChanged(state: BatchState, action: PayloadAction<boolean>) {
      state.labelConfigChanged = action.payload;
    },
    updateBatchLabelField(state: BatchState, action: PayloadAction<any>) {
      const { observationId, field, value } = action.payload;
      state.labels = state.labels.map((l) => {
        if (l.observation.id === observationId) {
          state.labelConfigChanged = true;
          return {
            ...l,
            [field]: value,
          };
        }
        return l;
      });
    },
    dismissBatchGenAnnoAsyncJobStatus(
      state: BatchState,
      action: PayloadAction<number | string>
    ) {
      const batchId = action.payload;
      const key = `${StorageKeys.DISMISS_GEN_ANNO_BATCH_PREFIX}${batchId}`;
      localStorage.setItem(key, "1");
      state.genAnnoAsyncJobStatus = undefined;
    },
    allBatchTaskSelected(state: BatchState, action: PayloadAction<boolean>) {
      state.tasks = state.tasks.map((task) => ({
        ...task,
        selected: action.payload,
      }));
    },
    batchTaskSelected(state: BatchState, action: PayloadAction<number>) {
      state.tasks = state.tasks.map((task) => {
        if (task.id === action.payload) {
          return {
            ...task,
            selected: !task.selected,
          };
        }
        return task;
      });
    },

    batchTaskActionSelected(
      state: BatchState,
      action: PayloadAction<{
        taskId: number;
        action: string;
      }>
    ) {
      state.selectedTaskAction = action.payload;
    },
    batchNextTaskSelected(state: BatchState) {
      const { taskId, action } = state.selectedTaskAction;
      const taskIds = state.tasks.map((task) => task.id);
      const index = taskIds.indexOf(taskId);
      state.selectedTaskAction = {
        action: action,
        taskId: taskIds[(index + 1) % taskIds.length],
      };
    },
    batchPreviousTaskSelected(state: BatchState) {
      const { taskId, action } = state.selectedTaskAction;
      const taskIds = state.tasks.map((task) => task.id);
      const index = taskIds.indexOf(taskId);
      state.selectedTaskAction = {
        action: action,
        taskId: taskIds[(index - 1 + taskIds.length) % taskIds.length],
      };
    },
  },
  extraReducers: (builder) => {
    batchReducerBuilder(builder);
  },
});
export const {
  resetBatchState,
  setLabelConfigChanged,
  updateBatchLabelField,
  dismissBatchGenAnnoAsyncJobStatus,
  allBatchTaskSelected,
  batchTaskSelected,
  batchTaskActionSelected,
  batchNextTaskSelected,
  batchPreviousTaskSelected,
} = slice.actions;
export const batchReducer = slice.reducer;
