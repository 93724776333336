import { AnalyticsQueryResponse } from "services/analytics-service";
import { RowDataTableLabeler } from "../components/labeler-table.component";

const COL_ASSIGNEE = "assignee";
const COL_JOB_COUNT = "job_count";
const COL_LABELING_HOURS = "labeling_hours";
const COL_SCORE = "score";

export const rowTableLabelerMapper = (response: AnalyticsQueryResponse): RowDataTableLabeler[] => {
  const rows = response.data.rows;
  if (rows && rows.length > 0) {
    return rows.map((row: any, index: number) => {
      const tableRow: RowDataTableLabeler = {
        id: index,
        email: row[COL_ASSIGNEE],
        completedJob: row[COL_JOB_COUNT],
        labelingHours: row[COL_LABELING_HOURS],
        averageHourPerJob: row[COL_JOB_COUNT] ? parseFloat((row[COL_LABELING_HOURS] / row[COL_JOB_COUNT]).toFixed(2)) : 0,
        score: row[COL_SCORE],
      };
      return tableRow;
    });
  }
  return [];
}