import { FilterCommon } from "domain/common/filter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IndividualService } from "services/label-service";
import { LabelerReportRecordDTO } from "services/label-service/dtos";
import { RequestStatus } from "store/base/base.state";
import { v4 } from "uuid";
import { mapperLabelerReportByDateRow } from "../mappers/labeler-report-by-job.mapper";
import { LabelerReportByDateRow } from "../models/labeler-report.models";
import * as Sentry from "@sentry/react";

export const useLabelerReportByDate = (fromDate: Date, toDate: Date) => {
  const [totalRow, setTotalRow] = useState(0);
  const [rows, setRows] = useState<LabelerReportByDateRow[]>([]);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const isLoading = useMemo(
    () => requestStatus === RequestStatus.LOADING,
    [requestStatus]
  );
  const [filter, setFilter] = useState<FilterCommon>({
    page: 1,
    pageSize: 10,
  });
  const displayRows = useMemo(() => {
    // apply client filter to rows
    return FilterCommon.applyFilterToRows(rows, filter);
  }, [rows, filter]);

  const setFilterField = (field: keyof FilterCommon, value: any) => {
    let newFilter = { ...filter, [field]: value };
    if (field !== "page" && field !== "pageSize") {
      newFilter = { ...newFilter, page: 1, pageSize: 10 };
    }
    setFilter(newFilter);
  };

  const requestData = useCallback(async (fromDate: Date, toDate: Date) => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      const res = await IndividualService.getLabelerReport(fromDate, toDate);
      let records: LabelerReportRecordDTO[] = res.data.records;
      let rows = records.map(mapperLabelerReportByDateRow.fromEntity);

      const sumRow: LabelerReportByDateRow = {
        id: v4(),
        total: 0,
        date: "",
        completed: 0,
        skipped: 0,
        startTime: "",
        endTime: "",

        totalCase: 0,
        group: "",
      };
      for (let record of records) {
        sumRow.total += record.total;
        sumRow.completed += record.completed;
        sumRow.skipped += record.skipped;
        sumRow.totalCase += record.totalCase;
      }
      rows = [sumRow, ...rows];

      setTotalRow(rows.length);
      setRows(rows);

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      Sentry.captureException(error);
      console.log(error);
      setRequestStatus(RequestStatus.FAILURE);
    }
  }, []);

  useEffect(() => {
    requestData(fromDate, toDate);
  }, [requestData, fromDate, toDate]);

  return {
    totalRow,
    rows,
    displayRows,
    isLoading,
    filter,
    setFilterField,
  };
};
