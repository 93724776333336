/*
 * File: date-formatter.utils.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 28th December 2021 3:31:55 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import moment from "moment";

type MonthFormat =
  | "long"
  | "numeric"
  | "2-digit"
  | "short"
  | "narrow"
  | undefined;
export function monthsForLocale(
  localeName = "en-GB",
  monthFormat: MonthFormat = "long"
) {
  const format = new Intl.DateTimeFormat(localeName, { month: monthFormat })
    .format;
  const months = [];
  for (let i = 0; i < 12; i++) {
    const text = format(new Date(Date.UTC(2021, i)));
    months.push(text.substring(0, 3));
  }
  return months;
}

export function formatDate(date: Date) {
  return moment(date).local().format("DD/MMM/yyyy");
}

export function formatDateTime(date: Date) {
  return moment(date).local().format("DD/MMM/yyyy HH:mm");
}
