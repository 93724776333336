/*
 * File: data-type-filter.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 3rd July 2023 2:47:41 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { VBSelectComponent } from "components/design-system/select-input/select.component";
import useDataTypeOptions from "../../../hooks/use-data-type-options";
import { QueryApi } from "data-access/impl/query";
import { Filter } from "domain/common";

interface Props {
  filter: Filter;
  onFilterChange(filter: Filter): void;
}

export function DataTypeFilter({ filter, onFilterChange }: Props) {
  const {
    options: dataTypeOptions,
    option: selectedDataType,
    onChange: onDataTypeChange,
  } = useDataTypeOptions("");

  return (
    <div className="px-4">
      <VBSelectComponent
        header="Data Type"
        placeholder="Data Type"
        value={selectedDataType}
        options={dataTypeOptions}
        isClearable
        menuPortalTarget={document.body}
        onChange={(newOption) => {
          onDataTypeChange(newOption);
          const fileFilter = new QueryApi.FileFilter(filter);
          let value: string | undefined = undefined;
          if (newOption) {
            value = (newOption as any)?.value ?? undefined;
          }
          fileFilter.setDataType(value);
          onFilterChange(fileFilter.toFilter());
        }}
      />
    </div>
  );
}
