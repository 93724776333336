/*
 * File: pipeline.slice.ts
 * Project: app-aiscaler-web
 * File Created: Monday, 6th September 2021 2:22:01 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_PIPELINE_STATE, PipelineState } from "./pipeline.state";
import { pipelineReducerBuilder } from "./pipeline.thunk";

const SLICE_NAME: string = "pipeline";
const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_PIPELINE_STATE,
  reducers: {
    resetPipelines(state: PipelineState) {
      Object.assign(state, INITIAL_PIPELINE_STATE);
    },
  },
  extraReducers: (builder) => {
    pipelineReducerBuilder(builder);
  },
});

export const { resetPipelines } = slice.actions;

export const pipelineReducer = slice.reducer;
