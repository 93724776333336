import { useMemo } from "react";
import classnames from "classnames";
import { IconSetting2, IconTrash } from "components/common/vb-icon.component";
import { VBCheckbox } from "components/design-system/checkbox/checkbox.component";
import { StepAssignee, StepAssigneeConfig, StepData } from "../../project.type";
import { useToggle } from "ahooks";
import { useStepConfigAssignees } from "../../hooks/use-step-config-assignees.hook";
import { AdvancedLabelerConfig } from "../../project-batch/batch-detail/pages/workflow/components/advanced-labeler-config.component";
import { ProjectWorkflowStepIcon } from "./project-workflow-step-icon.component";
import { VBRichTextEditor } from "components/common/vb-rich-text/vb-rich-text.component";

import "./project-workflow-step-view.style.scss";
import { useStepConditions } from "hooks/workflow/use-step-conditions.hook";

interface ProjectWorkflowStepViewProps {
  disabled?: boolean;
  step: StepData;
  selected?: boolean;
  onSelect?: (step: Partial<StepData>) => void;
  onRemove?: (step: Partial<StepData>) => void;
  onChange?: (step: Partial<StepData>) => void;
}

export const ProjectWorkflowStepView = ({
  disabled = false,
  step,
  selected,
  onSelect,
  onRemove,
  onChange,
}: ProjectWorkflowStepViewProps) => {
  const {
    config: assigneesConfig,
    numTaskInBatchDefault,
    onChange: onChangeAssigneesConfig,
    title: assigneesConfigModalTitle,
  } = useStepConfigAssignees(step);

  const { stepConditionOptions } = useStepConditions(true);

  const [
    assigneesConfigModalVisible,
    { setLeft: closeAssigneesConfigModal, setRight: openAssigneesConfigModal },
  ] = useToggle(false);

  const isFirstStep = useMemo(() => {
    return step.position === 1;
  }, [step.position]);

  const stepCondition = useMemo(() => {
    if (!step.condition || step.condition === "none")
      return stepConditionOptions[0];
    return stepConditionOptions.find(
      (option) => option.value === step.condition
    );
  }, [step.condition, stepConditionOptions]);

  const handleSelectStep = () => {
    onSelect && onSelect(step);
  };

  const handleRemoveStep = () => {
    onRemove && onRemove(step);
  };

  const handleChangeAssigneesConfig = (config: StepAssigneeConfig) => {
    if (!step || !step.assignees) return;

    const assignees: StepAssignee[] = step.assignees.map((assignee) => {
      const setting = config.configRows.find(
        (row) => row.user === assignee.userId
      );
      if (!setting) return assignee;
      return {
        ...assignee,
        dailyLimit: setting.dailyLimit,
        limit: setting.numTaskInBatch,
      };
    });

    onChangeAssigneesConfig(config);
    onChange && onChange({ assignees });
  };

  return (
    <div
      className={classnames(
        "w-full project-workflow-step-view",
        isFirstStep && "project-workflow-step-view--first"
      )}
      style={{ maxWidth: "40rem" }}
    >
      {!isFirstStep && (
        <div
          className="relative w-full pr-10 text-center"
          style={{ lineHeight: 0 }}
        >
          <div className="relative inline-block w-0.5 h-20 bg-primary-500"></div>
          {stepCondition && (
            <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
              {stepCondition.label}
            </div>
          )}
        </div>
      )}
      <div className="flex items-center w-full gap-4">
        <span className="flex items-center justify-center flex-none w-6 h-6 rounded bg-background-300">
          {step.position}
        </span>

        <div
          onClick={handleSelectStep}
          className={classnames(
            "flex items-center flex-auto gap-4 p-4 bg-white ring-2 ring-gray-300 rounded cursor-pointer",
            {
              "bg-white": !isFirstStep,
              "bg-background-200": isFirstStep,
              "ring-1 ring-primary": !!selected,
            }
          )}
        >
          <ProjectWorkflowStepIcon type={step.type} />
          <div className="flex-auto space-y-1">
            <h3 className="font-bold">{step.name}</h3>
            {step.description && (
              <VBRichTextEditor
                containerClass="border-none p-0 bg-red text-sm text-background-500 rounded bg-background-50"
                defaultValue={step?.description || ""}
                disabled
                config={{ toolbar: false, readOnly: true }}
              />
            )}
          </div>
          <div className="space-y-1 text-sm text-right">
            <div className="flex items-center justify-end gap-2">
              <span>Number of rounds:</span>
              <span>{step.numberOfRounds}</span>
            </div>

            <div className="flex items-center justify-end gap-2 text-gray-400">
              {!isFirstStep && (
                <>
                  <VBCheckbox disabled value={!!step.readonly} />{" "}
                  <span>Read-Only</span>
                </>
              )}
            </div>

            <div className="flex items-center justify-end gap-2 text-gray-400">
              {!isFirstStep && (
                <>
                  <VBCheckbox
                    disabled
                    value={!!step.canViewPreviousStepResult}
                  />
                  <span>Show result from previous steps</span>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex-none w-10 space-y-2">
          {selected && (
            <>
              {!isFirstStep && (
                <div
                  className={classnames({
                    "cursor-not-allowed": disabled,
                  })}
                >
                  <button
                    onClick={handleRemoveStep}
                    className={classnames("text-red-500", {
                      "pointer-events-none text-gray-400 opacity-50": disabled,
                    })}
                  >
                    <IconTrash className="w-6 h-6" />
                  </button>
                </div>
              )}

              {step.assignees?.length ? (
                <button
                  className="text-black cursor-pointer"
                  onClick={openAssigneesConfigModal}
                >
                  <IconSetting2 className="w-6 h-6" />
                </button>
              ) : null}
            </>
          )}
        </div>
      </div>

      {assigneesConfigModalVisible && assigneesConfig && (
        <AdvancedLabelerConfig
          open
          value={assigneesConfig}
          onClose={closeAssigneesConfigModal}
          numTaskInBatchDefault={numTaskInBatchDefault}
          title={assigneesConfigModalTitle}
          onValueChanged={handleChangeAssigneesConfig}
          disabledTaskDist={disabled}
        />
      )}
    </div>
  );
};
