/*
 * File: workflow-instruction.api.ts
 * Project: app-aiscaler-web
 * File Created: Saturday, 17th July 2021 9:19:04 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

import { WorkflowInstructionDTO } from "../dtos";
import { BaseAPI } from "./base.api";

export class WorkflowInstructionAPI extends BaseAPI<WorkflowInstructionDTO> {}
