/*
 * File: ai-model-versions.thunk.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 22nd February 2022 3:26:27 pm
 * Author: Lý Bảo Thoại (v.thoaily@vinbrain.net)
 * 
 * Copyright 2022 VinBrain JSC
 */

import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AIModelVersionsState } from "./ai-model-versions.state";
import {
  requestPending,
  requestRejected,
  requestFulfilled,
  setEntites,
  removeEntity,
} from "store/base/base.reducer";
import { ModelAIVersionService } from "./../../../services/label-service/index";
import { AIModelVersionDTO } from "./../../../services/label-service/dtos/ai-model.dto";

export enum AIModelsThunk {
  LOAD_AI_MODEL_VERIONS = "aiModels/loadAIModelVersionsAsync",
  DELETE_AI_MODEL_VERSION = "aiModels/deleteAIModelVersionAsync",
  CREATE_AI_MODEL_VERSION = "aiModels/createAIModelVersionAsync",
}

export const loadAIModelVersionsAsync = createAsyncThunk(
  AIModelsThunk.LOAD_AI_MODEL_VERIONS,
  async () => {
    const data: AIModelVersionDTO[] =
      (await ModelAIVersionService.getModelAIs()) as AIModelVersionDTO[];
    return data;
  }
);

export const createAIModelVersionAsync = createAsyncThunk(
  AIModelsThunk.CREATE_AI_MODEL_VERSION,
  async (payload: Partial<AIModelVersionDTO>) => {
    const res = await ModelAIVersionService.createModelAI(payload);
    return res.data;
  }
);

export const deleteAIModelVersionAsync = createAsyncThunk(
  AIModelsThunk.DELETE_AI_MODEL_VERSION,
  async (aiModelId: number) => {
    await ModelAIVersionService.deleteModelAI(aiModelId);
    return aiModelId;
  }
);

export const aiModelVersionsReducerBuilder = (
  builder: ActionReducerMapBuilder<AIModelVersionsState>
): ActionReducerMapBuilder<AIModelVersionsState> => {
  return builder
    .addCase(loadAIModelVersionsAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(loadAIModelVersionsAsync.fulfilled, (state, action) => {
      requestFulfilled(state);
      setEntites(state, action.payload);
    })
    .addCase(loadAIModelVersionsAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    })
    .addCase(deleteAIModelVersionAsync.pending, (state) => {
      requestPending(state);
    })
    .addCase(deleteAIModelVersionAsync.fulfilled, (state, action) => {
      removeEntity(state, action.payload);
    })
    .addCase(deleteAIModelVersionAsync.rejected, (state, action) => {
      requestRejected(state, action.error);
    });
};
