/*
 * File: step-0-batch-information.component.tsx
 * Project: app-aiscaler-web
 * File Created: Thursday, 12th August 2021 9:22:21 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { useCreateBatchContext } from "../create-batch.context";
import { BatchSteps } from "../create-batch.model";
import {
  InstructionUrl,
  InstructionUrlType,
} from "./instruction-url.component";
import {
  ActiveLearningSetting,
  BatchDTO,
  EmbeddedType,
} from "services/label-service/dtos";
import { VBSwitch } from "components/design-system/switch/switch.component";
import { useAppSelector } from "hooks/use-redux";
import { selectActiveLearningVisible, selectIsEvaluationBatchVisible } from "store/customer/project/project.selectors";

export interface InformationStepDataSubmit
  extends Pick<
  BatchDTO,
  "name" | "description" | "instruction" | "embeddedType" | "embeddedContent"
  > {
  activeLearning: ActiveLearningSetting;
  isEvaluation: boolean;
  balanceJobAssign?: boolean;
}

export const BatchInformationStep = () => {
  const { t } = useTranslation();
  const { submitData, currentStep } = useCreateBatchContext();
  const activeLearningVisible = useAppSelector(selectActiveLearningVisible);
  const isEvaluationBatchVisible = useAppSelector(selectIsEvaluationBatchVisible);

  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const instructionRef = useRef<HTMLTextAreaElement>(null);
  const urlTypeRef = useRef<InstructionUrlType | undefined>();
  const urlRef = useRef<string | undefined>();
  const activeLearningSwitchRef = useRef<HTMLInputElement>(null);
  const isEvaluationBatchSwitchRef = useRef<HTMLInputElement>(null);
  const balanceJobAssignSwitchRef = useRef<HTMLInputElement>(null);
  const [description, setDescription] = useState("");
  const [instruction, setInstruction] = useState("");

  function handleChange(_: any) {
    const name = nameRef.current?.value.trim() || "";
    const description = descriptionRef.current?.value.trim() || "";
    const instruction = instructionRef.current?.value.trim() || "";
    const urlType = urlTypeRef.current;
    const url = urlRef.current;

    const payload: InformationStepDataSubmit = {
      name,
      description,
      instruction,
      activeLearning: {
        enable: activeLearningSwitchRef.current?.checked || false,
      },
      isEvaluation: isEvaluationBatchSwitchRef.current?.checked || false,
      balanceJobAssign: balanceJobAssignSwitchRef.current?.checked || false,
    };
    if (urlType && url) {
      payload.embeddedType =
        urlType === InstructionUrlType.UPLOADED_FILE
          ? EmbeddedType.PDF
          : EmbeddedType.HTML;
      payload.embeddedContent = url;
    }
    submitData(BatchSteps.GENERAL, name ? payload : null);
  }

  function handleUrlChanged(
    urlType: InstructionUrlType | undefined,
    url: string | undefined
  ) {
    urlTypeRef.current = urlType;
    urlRef.current = url;
    handleChange(undefined);
  }

  function handleOnDescriptionInput() {
    setDescription((descriptionRef.current?.value as string).trim());
  }

  function handleOnInstructionInput() {
    setInstruction((instructionRef.current?.value as string).trim());
  }

  return (
    <div className="p-4">
      <div className="py-4 text-xl font-bold">
        {t("project:batch.instruction.step0.title")}
      </div>
      <div className="py-6 pr-4 space-y-6">
        <div className="space-y-2">
          <div className="text-sm text-background-500">{t("Name")}</div>
          <input
            type="text"
            required
            autoFocus
            onChange={handleChange}
            ref={nameRef}
            maxLength={256}
            placeholder={t(
              "project:batch.instruction.step0.placeholderBatchName"
            )}
            defaultValue={currentStep?.data?.name || ""}
            className="w-full px-4 py-2 border border-gray-400 rounded focus:border-primary focus:ring-1 focus:ring-primary"
          />
        </div>
        <div className="space-y-2">
          <div className="flex items-center justify-between text-sm text-background-500">
            <span>{t("project:batch.instruction.step0.description")}</span>
            <span>{description.length}/500</span>
          </div>
          <textarea
            ref={descriptionRef}
            cols={3}
            onInput={handleOnDescriptionInput}
            maxLength={500}
            onChange={handleChange}
            placeholder={t(
              "project:batch.instruction.step0.placeholderDescription"
            )}
            defaultValue={currentStep?.data?.description || ""}
            className="w-full px-4 py-2 border border-gray-400 rounded focus:border-primary focus:ring-1 focus:ring-primary"
          />
        </div>
        <div className="space-y-2">
          <div className="flex items-center justify-between text-sm text-background-500">
            <span>{t("project:batch.instruction.step0.instruction")}</span>
            <span>{instruction.length}/500</span>
          </div>
          <textarea
            cols={3}
            maxLength={500}
            onChange={handleChange}
            ref={instructionRef}
            onInput={handleOnInstructionInput}
            defaultValue={currentStep?.data?.instruction || ""}
            placeholder={t(
              "project:batch.instruction.step0.placeholderInstruction"
            )}
            className="w-full px-4 py-2 border border-gray-400 rounded focus:border-primary focus:ring-1 focus:ring-primary"
          />
        </div>
        <div className="flex items-center gap-2">
          {activeLearningVisible && (
            <div className="space-y-2">
              <div className="flex items-center">
                <span className="text-sm text-background-500">
                  {t("project:batch.instruction.step0.activeLearning")}
                </span>
                <VBSwitch
                  checked={!!currentStep?.data?.activeLearning?.enable}
                  ref={activeLearningSwitchRef}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}

          <div className="space-y-2">
            <div className="flex items-center">
              <span className="text-sm text-background-500">
                {t("project:batch.instruction.step0.balanceJobAssign")}
              </span>
              <VBSwitch ref={balanceJobAssignSwitchRef} onChange={handleChange} />
            </div>
          </div>

          {isEvaluationBatchVisible && (
            <div className="space-y-2">
              <div className="flex items-center">
                <span className="text-sm text-background-500">
                  {t("project:batch.instruction.step0.isEvaluation")}
                </span>
                <VBSwitch
                  checked={!!currentStep?.data?.isEvaluation}
                  ref={isEvaluationBatchSwitchRef}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
        </div>
        <div className="my-4">
          <InstructionUrl onDataChanged={handleUrlChanged} />
        </div>
      </div>
    </div>
  );
};
