import { VBModal } from "components/common/vb-modal/vb-modal.component";
import { VBNumberTextInputComponent } from "components/design-system/number-text-input/number-text-input.component";
import { useWorkspaceContext } from "contexts/workspace";
import { useAppDispatch } from "hooks/use-redux";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { mlServiceApis } from "services/ml-service/ml.service";
import { enqueueErrorNotification, enqueueSuccessNotification } from "store/common/notification/notification.actions";
import { experimentsMappers } from "./experiments.mappers";
import { MlModelsExperimentUIModel } from "./experiments.models";
import * as Sentry from "@sentry/react";

interface Props {
  open: boolean;
  onSubmit?: (v: MlModelsExperimentUIModel) => void;
  onClose?: () => void;
}
export const CreateExperimentModal = ({
  open,
  onSubmit,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { workspaceId } = useWorkspaceContext();
  const [name, setName] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const isValid = useMemo(() => {
    return !!name;
  }, [name]);

  const handleSubmit = async () => {
    try {
      setIsProcessing(true);

      const res = await mlServiceApis.createExperiment({
        name,
        workspace_id: workspaceId,
      });

      onSubmit && onSubmit(experimentsMappers.fromEntity(res));
      dispatch(enqueueSuccessNotification(t("common:textSuccess")));
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(enqueueErrorNotification(t("common:textFailed")));
    } finally {
      setIsProcessing(false);
    }
  }

  return (
    <VBModal
      title="Create experiment"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      disableSubmit={!isValid || isProcessing}
    >
      <VBNumberTextInputComponent
        label="Name"
        type="text"
        defaultValue={name}
        listenChangedDefault
        onValueChanged={v => setName(v.toString())}
      />
    </VBModal>
  )
}
