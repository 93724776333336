import axios, { AxiosInstance } from "axios";
import { ENV_CONFIG } from "configs/env.config";
import { setupAuthInterceptor } from "services/common/auth-interceptor";

let _axiosInstance: AxiosInstance | undefined = undefined;

function getAxiosInstance() {
  if (_axiosInstance) return _axiosInstance;
  _axiosInstance = setupAuthInterceptor(
    axios.create({
      baseURL: ENV_CONFIG.AI_ASSISTANCE_SERVICE_URL,
      timeout: 10000,
    })
  );
  return _axiosInstance;
}

const transcribeAudio = (audioUrl: string, lang: string = "vi") => {
  const payload = { audioUrl, lang };
  return getAxiosInstance().post("/audio/transcribe", payload);
};

export const AIAssistanceSTTService = {
  transcribeAudio,
};
