/*
 * File: base.api.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 31st August 2021 4:36:18 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { AxiosRequestConfig } from "axios";
import { setupLabelInterceptor } from "services/common/label-interceptor";
import { RequestOptions } from "services/common/request-options";
import { AtLeast } from "types/common";
import { BaseDTO } from "../dto/base.dto";
import { BaseHTTPClient } from "../http/base.httpclient";

export class BaseAPI<T extends BaseDTO> extends BaseHTTPClient {
  private _PATH_: string;

  constructor(serviceUrl: string, path: string) {
    super(serviceUrl);
    this._PATH_ = path;
    this.axiosInstance = setupLabelInterceptor(this.axiosInstance);
  }

  getPath() {
    return this._PATH_;
  }
  get(url: string, config?: AxiosRequestConfig) {
    return this.APIGet(url, config);
  }

  post(url: string, data = {}, config?: AxiosRequestConfig) {
    return this.APIPost(url, data, config);
  }

  delete(url: string, config?: AxiosRequestConfig) {
    return this.APIDelete(url, config);
  }

  upload(url: string, data: FormData, config?: AxiosRequestConfig) {
    return this.APIUpload(url, data, config);
  }

  put(url: string, data = {}, config?: AxiosRequestConfig) {
    return this.APIPut(url, data, config);
  }

  patch(url: string, data = {}, config?: AxiosRequestConfig) {
    return this.APIPatch(url, data, config);
  }

  getItems(options: RequestOptions) {
    const params = new URLSearchParams(options);
    return this.APIGet(`${this._PATH_}?${params.toString()}`);
  }

  createItem(item: Partial<T>) {
    const data = JSON.stringify(item);
    return this.APIPost(`${this._PATH_}?`, data);
  }

  getItem(itemId: number) {
    return this.APIGet(`${this._PATH_}/${itemId}`);
  }

  updateItem(item: T) {
    const data = JSON.stringify(item);
    return this.APIPut(`${this._PATH_}/${item.id}`, data);
  }

  partialUpdateItem(item: AtLeast<T, "id">) {
    const data = JSON.stringify(item);
    return this.APIPatch(`${this._PATH_}/${item.id}`, data);
  }

  deleteItem(itemId: number) {
    return this.APIDelete(`${this._PATH_}/${itemId}`);
  }
}
