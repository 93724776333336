import { IEvent } from "fabric/fabric-impl";
import {
  createUtilLabelGroupForObject,
  updateObjectAdditionalData,
} from "./fabric-object.utils";
import { FabricOverlay } from "./fabric-overlay";
import { FabricOverlayEvents } from "./fabric.models";

export class FabricTool {
  enabled: boolean = false;
  fabricOverlay: FabricOverlay;
  fabricCanvas: fabric.Canvas;
  osdViewer: OpenSeadragon.Viewer;

  constructor(fabricOverlay: FabricOverlay, osdViewer: OpenSeadragon.Viewer) {
    this.fabricOverlay = fabricOverlay;
    this.osdViewer = osdViewer;
    this.fabricCanvas = fabricOverlay.fabricCanvas;
  }

  onMouseDown(e: IEvent) {
    if (!this.enabled) return;
    const pos = this.getFabricAbsolutePosition(e);
    this.handleMouseDown(pos.x, pos.y, e);
  }

  onMouseMove(e: IEvent) {
    if (!this.enabled) return;
    const pos = this.getFabricAbsolutePosition(e);
    this.handleMouseMove(pos.x, pos.y, e);
  }

  onMouseUp(e: IEvent) {
    if (!this.enabled) return;
    const pos = this.getFabricAbsolutePosition(e);
    this.handleMouseUp(pos.x, pos.y, e);
  }

  handleMouseDown(fabricAbsX: number, fabricAbsY: number, e: IEvent) {
    throw new Error("Not Implemented");
  }

  handleMouseMove(fabricAbsX: number, fabricAbsY: number, e: IEvent) {
    throw new Error("Not Implemented");
  }

  handleMouseUp(fabricAbsX: number, fabricAbsY: number, e: IEvent) {
    throw new Error("Not Implemented");
  }

  getFabricAbsolutePosition(e: IEvent) {
    const nativeEvent = e.e as any;
    return this.fabricOverlay.windowToFabricAbsolute(
      nativeEvent.clientX,
      nativeEvent.clientY
    );
  }

  destroy() {}

  setEnabled(v: boolean) {
    this.enabled = v;
  }

  sendObjectAddedEvent(object: any) {
    document.dispatchEvent(
      new CustomEvent(FabricOverlayEvents.OBJECT_ADDED, {
        detail: object,
      })
    );
  }

  updateObjectAdditionalData(object: fabric.Object) {
    updateObjectAdditionalData(
      object,
      this.fabricOverlay.objectAdditionalData,
      {
        labelVisibility: this.fabricOverlay.labelVisibility,
        labelerVisibility: this.fabricOverlay.labelerVisibility,
        utilButtonVisibility: this.fabricOverlay.utilButtonVisibility,
        fill: this.fabricOverlay.fillObjects,
      }
    );
  }

  addUtilLabel(object: fabric.Object) {
    createUtilLabelGroupForObject(object);
    this.updateObjectAdditionalData(object);
  }

  addDistanceLabel(object: fabric.Object) {
    // createDistanceTextForObject(object);
    this.updateObjectAdditionalData(object);
  }
}
