/*
 * File: dicom-editor.slice.ts
 * Project: app-aiscaler-web
 * File Created: Tuesday, 27th July 2021 5:08:25 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CornerstoneTool,
  SubType,
  ToolName,
} from "components/dicom/dicom-tools/dicom-tools.model";
import {
  INITIAL_DICOM_EDITOR_STATE,
  DicomEditorState,
} from "./dicom-editor.state";

const SLICE_NAME: string = "dicomEditor";

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_DICOM_EDITOR_STATE,
  reducers: {
    setActiveCornerstoneTool: (
      state: DicomEditorState,
      action: PayloadAction<ToolName>
    ) => {
      state.activeTool = action.payload;
    },
    freehandStrategyChanged: (
      state: DicomEditorState,
      action: PayloadAction<string>
    ) => {
      state.freehandStrategy = action.payload;
    },
    resetCornerstoneTool: (state: DicomEditorState) => {
      state.activeTool = ToolName.Wwwc;
    },
    resetDicomEditorState: (state: DicomEditorState) => {
      Object.assign(state, INITIAL_DICOM_EDITOR_STATE);
    },
    cornerstoneSubTypeSelected: (
      state: DicomEditorState,
      action: PayloadAction<{ tool: CornerstoneTool; type: SubType }>
    ) => {
      const { tool, type } = action.payload;
      state.annotateTools.forEach((annotateTool) => {
        if (annotateTool.name === tool.name) {
          annotateTool.subType = type;
        }
      });
    },
  },
});

export const {
  setActiveCornerstoneTool,
  resetCornerstoneTool,
  cornerstoneSubTypeSelected,
  resetDicomEditorState,
  freehandStrategyChanged,
} = slice.actions;

export const dicomEditorReducer = slice.reducer;
