import { Fragment, useEffect, useRef, useState } from "react";
import { vtkColorMaps } from "../vtk_import";
import { useThreeDEditorContext } from "./three-d-editor.provider"


interface Props {
  onClose?: () => void;
}

export const VolumeColorComponent = ({
  onClose,
}: Props) => {

  const gaussianContainer = useRef<HTMLDivElement>(null);

  const {
    editorContext,
    presetName,
    setPresetName,
  } = useThreeDEditorContext();
  const [sampleDistance, setSampleDistance] = useState(0.9);

  const DEFAULT_DAS = 0.5;
  const [useShading, setUseShading] = useState(true);
  const [diffuse, setDiffuse] = useState(DEFAULT_DAS);
  const [ambient, setAmbient] = useState(DEFAULT_DAS);
  const [specular, setSpecular] = useState(DEFAULT_DAS);

  const useShadingRef = useRef(true);
  const diffuseRef = useRef(DEFAULT_DAS);
  const ambientRef = useRef(DEFAULT_DAS);
  const specularRef = useRef(DEFAULT_DAS);

  useEffect(() => {
    useShadingRef.current = useShading;
  }, [useShading]);

  useEffect(() => {
    diffuseRef.current = diffuse;
  }, [diffuse]);

  useEffect(() => {
    ambientRef.current = ambient;
  }, [ambient]);

  useEffect(() => {
    specularRef.current = specular;
  }, [specular]);

  useEffect(() => {
    if (!editorContext || !gaussianContainer.current) return;

    const {
      imageData,
      imageVolume,
      labelFilterVolume,
      windowVolume,
    } = editorContext;
    const {renderWindow} = windowVolume;
    const {gaussianWidget, cfunc, ofunc} = imageVolume;
  
    const setPropertyShading = (p: any) => {
      p.setShade(useShadingRef.current);
      p.setDiffuse(diffuseRef.current);
      p.setAmbient(ambientRef.current);
      p.setSpecular(specularRef.current);
    }

    setPropertyShading(imageVolume.actor.getProperty());
    setPropertyShading(labelFilterVolume.actor.getProperty());

    gaussianWidget.updateStyle({
      backgroundColor: "rgba(255, 255, 255, 0.6)",
      histogramColor: "rgba(100, 100, 100, 0.5)",
      strokeColor: "rgb(0, 0, 0)",
      activeColor: "rgb(255, 255, 255)",
      handleColor: "rgb(50, 150, 50)",
      buttonDisableFillColor: "rgba(255, 255, 255, 0.5)",
      buttonDisableStrokeColor: "rgba(0, 0, 0, 0.5)",
      buttonStrokeColor: "rgba(0, 0, 0, 1)",
      buttonFillColor: "rgba(255, 255, 255, 1)",
      strokeWidth: 2,
      activeStrokeWidth: 3,
      buttonStrokeWidth: 1.5,
      handleWidth: 3,
      iconSize: 20, // Can be 0 if you want to remove buttons (dblClick for (+) / rightClick for (-))
      padding: 10,
    });
    gaussianWidget.setDataArray(imageData.getPointData().getScalars().getData());
    gaussianWidget.setColorTransferFunction(cfunc);
    cfunc.onModified(() => {
      gaussianWidget.render();
    });

    // ----------------------------------------------------------------------------
    // Default setting Piecewise function widget
    // ----------------------------------------------------------------------------
    gaussianWidget.addGaussian(0.5, 1, 0.1, 0, 0);

    gaussianWidget.setContainer(gaussianContainer.current);
    gaussianWidget.bindMouseListeners();

    gaussianWidget.onAnimation((start: any) => {
      if (start) {
        renderWindow.getInteractor().requestAnimation(gaussianWidget);
      } else {
        renderWindow.getInteractor().cancelAnimation(gaussianWidget);
      }
    });

    gaussianWidget.onOpacityChange(() => {
      gaussianWidget.applyOpacity(ofunc);
      if (!renderWindow.getInteractor().isAnimating()) {
        renderWindow.render();
      }
    });

    gaussianWidget.applyOpacity(ofunc);
  }, [editorContext]);

  const handlePresetChanged = (v: string) => {
    setPresetName(v);
  }

  const handleSampleDistanceChanged = (v: number) => {
    if (!editorContext) return;
    const {imageVolume, labelFilterVolume, windowVolume} = editorContext;
    imageVolume.mapper.setSampleDistance(v);
    labelFilterVolume.mapper.setSampleDistance(v);
    setSampleDistance(v);
    windowVolume.renderWindow.render();
  }

  const handleUseShadingChanged = (v: boolean) => {
    if (!editorContext) return;
    const {imageVolume, labelFilterVolume, windowVolume} = editorContext;
    imageVolume.actor.getProperty().setShade(v);
    labelFilterVolume.actor.getProperty().setShade(v);
    windowVolume.renderWindow.render();
    setUseShading(v);
  }

  const handleDiffuseChanged = (v: number) => {
    if (!editorContext) return;
    const {imageVolume, labelFilterVolume, windowVolume} = editorContext;
    imageVolume.actor.getProperty().setDiffuse(v);
    labelFilterVolume.actor.getProperty().setDiffuse(v);
    windowVolume.renderWindow.render();
    setDiffuse(v);
  }

  const handleAmbientChanged = (v: number) => {
    if (!editorContext) return;
    const {imageVolume, labelFilterVolume, windowVolume} = editorContext;
    imageVolume.actor.getProperty().setAmbient(v);
    labelFilterVolume.actor.getProperty().setAmbient(v);
    windowVolume.renderWindow.render();
    setAmbient(v);
  }

  const handleSpecularChanged = (v: number) => {
    if (!editorContext) return;
    const {imageVolume, labelFilterVolume, windowVolume} = editorContext;
    imageVolume.actor.getProperty().setSpecular(v);
    labelFilterVolume.actor.getProperty().setSpecular(v);
    windowVolume.renderWindow.render();
    setSpecular(v);
  }

  return (
    <div className="flex flex-col gap-2 bg-background-900 text-white rounded border-2 border-gray-600">
      <div className="flex flex-col gap-2 rounded py-1 px-2">
        <div className="flex items-center gap-4 truncate">
          <div className="flex items-center gap-4">
            <button
              className="font-bold"
              onClick={_ => onClose && onClose()}
            >X</button>
            <span>Preset: </span>
            <div>
              <select
                className="text-black"
                name="presets"
                value={presetName}
                onChange={e => handlePresetChanged(e.target.value)}
              >
                {
                  vtkColorMaps.rgbPresetNames.map((name: string) => {
                    return <option key={name} value={name} className="truncate">{name}</option>
                  })
                }
              </select>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <span>Sample distance: {sampleDistance}</span>
            <input
              type="range"
              min={0.2}
              max={1}
              step={0.01}
              value={sampleDistance}
              onChange={e => handleSampleDistanceChanged(parseFloat(e.target.value))}
            />
          </div>
        </div>
        <div className="flex items-center gap-2 justify-between">
          <div className="flex items-center gap-1">
            <input
              type="checkbox"
              checked={useShading}
              onChange={e => handleUseShadingChanged(e.target.checked)}
            />
            <span>Use shading</span>
          </div>
          {
            useShading &&
            <Fragment>
              <div className="flex items-center gap-2">
                <span>Diffuse: {diffuse}</span>
                <input
                  type="range"
                  min={0}
                  max={1}
                  step={0.01}
                  value={diffuse}
                  onChange={e => handleDiffuseChanged(parseFloat(e.target.value))}
                />
              </div>
              <div className="flex items-center gap-2">
                <span>Ambient: {ambient}</span>
                <input
                  type="range"
                  min={0}
                  max={1}
                  step={0.01}
                  value={ambient}
                  onChange={e => handleAmbientChanged(parseFloat(e.target.value))}
                />
              </div>
              <div className="flex items-center gap-2">
                <span>Specular: {specular}</span>
                <input
                  type="range"
                  min={0}
                  max={1}
                  step={0.01}
                  value={specular}
                  onChange={e => handleSpecularChanged(parseFloat(e.target.value))}
                />
              </div>
            </Fragment>
          }
        </div>
      </div>
      <div ref={gaussianContainer}>
      </div>
    </div>
  )
}