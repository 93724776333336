/*
 * File: text-editor.selector.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 5:05:43 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { RootState } from "store";

export const selectSelectedObservation = (state: RootState) => {
  const { labels } = state.textWorkspace.textLabeling;
  const { selectedLabelId } = state.textWorkspace.textEditor;
  const observationId = parseInt(selectedLabelId);
  const observation = labels.find((o) => o.observation.id === observationId);
  return observation ? observation : labels[0];
};

export const selectSelectedSystemObservationId = (state: RootState) => {
  return state.textWorkspace.textEditor.selectedSystemObservationId;
};

export const selectHasSelectedSystemLabel = (state: RootState) => {
  return state.textWorkspace.textEditor.selectedSystemObservationId !== -1;
};

export const selectSelectedTokenIds = (state: RootState) => {
  return state.textWorkspace.textEditor.selectedTokenIds;
};

export const selectActiveTokenObservationId = (state: RootState) => {
  const { selectedAnnotation } = state.textWorkspace.textEditor;

  if (selectedAnnotation && selectedAnnotation.issueMenu) {
    return selectedAnnotation.annotationId;
  }

  return state.textWorkspace.textEditor.activeTokenObservationId;
};

export const selectActiveTokenId = (state: RootState) => {
  const { selectedToken } = state.textWorkspace.textEditor;
  if (selectedToken.contextMenu || selectedToken.issueMenu) {
    return selectedToken.tokenId;
  }
  return undefined;
};

export const selectActiveLabelId = (state: RootState) => {
  return state.textWorkspace.textEditor.activeLabelId;
};

export const selectActiveRelationId = (state: RootState) => {
  return state.textWorkspace.textEditor.activeRelationId;
};

export const selectShowLabelInstruction = (state: RootState) => {
  return state.textWorkspace.textEditor.showLabelInstruction;
};

export const selectHiddenLabelIds = (state: RootState) => {
  return state.textWorkspace.textEditor.hiddenLabelIds;
};

export const selectTextLabelVisible =
  (labelId: string) => (state: RootState) => {
    const { hiddenLabelIds } = state.textWorkspace.textEditor;
    if (hiddenLabelIds.hasOwnProperty(labelId)) return false;
    return true;
  };

export const selectContextMenuTokenId = (state: RootState) => {
  return state.textWorkspace.textEditor.contextMenuTokenId;
};

export const selectContextMenuState = (state: RootState) => {
  return state.textWorkspace.textEditor.contextMenuTokenId;
};

export const selectAnnotationContextMenu = (state: RootState) => {
  return state.textWorkspace.textEditor.contextMenuAnnotationId;
};

export const selectSelectedLabelId = (state: RootState) => {
  return state.textWorkspace.textEditor.selectedLabelId;
};
export const selectSelectedLabel = (state: RootState) => {
  const labelId = state.textWorkspace.textEditor.selectedLabelId;
  const batchObservation = state.textWorkspace.textLabeling.labels.find(
    (lb) => lb.observation.id.toString() === labelId
  );
  if (!batchObservation) return null;
  return {
    id: batchObservation.observation.id.toString(),
    text: `${
      batchObservation.observation.parent
        ? `${batchObservation.observation.parent.name}:`
        : ""
    }${batchObservation.observation.name}`,
    color: batchObservation.observation.observationSetting.color,
  };
};

export const selectShowPreviousJob = (state: RootState) => {
  return state.textWorkspace.textEditor.showPreviousJobs;
};

export const selectTextObservations = (state: RootState) => {
  return state.textWorkspace.textEditor.observations;
};

export const selectIsAutoSaveConfig = (state: RootState) => {
  return state.textWorkspace.textEditor.autoSave;
};

export const selectActiveLabelIds = (state: RootState) => {
  const { labels } = state.textWorkspace.textLabeling;
  const { observations } = state.textWorkspace.textEditor;
  const observationIds: string[] = [];
  for (let observation of observations) {
    if (!observationIds.includes(observation.observationId)) {
      observationIds.push(observation.observationId);
    }
  }
  return labels
    .filter((b) => observationIds.includes(b.observation.id.toString()))
    .map((b) => b.observation.id.toString());
};

export const selectActiveLabels = (state: RootState) => {
  const { labels } = state.textWorkspace.textLabeling;
  const { observations } = state.textWorkspace.textEditor;
  const observationIds: string[] = [];
  for (let observation of observations) {
    if (!observationIds.includes(observation.observationId)) {
      observationIds.push(observation.observationId);
    }
  }
  return labels.filter((b) =>
    observationIds.includes(b.observation.id.toString())
  );
};

export const selectTextRelations = (state: RootState) => {
  return state.textWorkspace.textEditor.relations;
};

export const selectDraggingObservationId = (state: RootState) => {
  return state.textWorkspace.textEditor.draggingObservationId;
};

export const selectSelectedTextAnnotation = (state: RootState) => {
  return state.textWorkspace.textEditor.selectedAnnotation;
};

export const selectSelectedTextToken = (state: RootState) => {
  return state.textWorkspace.textEditor.selectedToken;
};

export const selectTextIsShowRelation = (state: RootState) => {
  return state.textWorkspace.textEditor.showRelation;
};

export const selectTextIsShowRelationName = (state: RootState) => {
  return state.textWorkspace.textEditor.showRelationName;
};

export const selectTextIsShowNavBar = (state: RootState) => {
  return state.textWorkspace.textEditor.showNavBar;
};

export const selectTextIsShowSideBar = (state: RootState) => {
  return state.textWorkspace.textEditor.showSideBar;
};

export const selectTextIsShowEntity = (state: RootState) => {
  return state.textWorkspace.textEditor.showEntity;
};
