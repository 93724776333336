/*
 * File: ai-models.mapper.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 3rd November 2022 5:14:15 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { AIModel } from "domain/customer/ai-model";
import { AIModelDTO } from "./ai-models.dto";

function fromDTO(dto: AIModelDTO): AIModel {
  return {
    id: dto.id,
    status: dto.status || "",
    name: dto.name,
    description: dto.description || "",
    inferenceType: dto.inferenceType || "",
    workspaceId: "",
    metadata: {
      classes: dto.modelMetadata?.labelsMapping || [],
    },
    createdDate: dto.createdDate ? new Date(dto.createdDate) : undefined,
  };
}
const aiModelsMapper = { fromDTO };

export default aiModelsMapper;
