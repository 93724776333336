/*
 * File: user-workspace.state.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 29th July 2021 3:34:21 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { GroupDTO } from "services/user-service/dtos/group.dto";
import { RequestStatus } from "store/base/base.state";

export interface UserWorkspaceState {
  loading: boolean;
  isLoaded: boolean;
  workspaces: GroupDTO[];
  requestStatus: RequestStatus;
}

export const INITIAL_USER_WORKSPACE_STATE: UserWorkspaceState = {
  loading: false,
  isLoaded: false,
  requestStatus: RequestStatus.IDLE,
  workspaces: [],
};
