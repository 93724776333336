import { findObservationParents, ObservationDTO } from "services/label-service/dtos";
import { LabelDistributionDTO } from "store/customer/batch-data/labeler-distribution/labeler-distribution.state";
import { StepDistributionDTO } from "store/customer/project-data/label-distribution/label-distribution.state";


export interface ObservationStatsRowModel {
  observation: ObservationDTO;
}

export interface LabelDistributionRowModel extends ObservationStatsRowModel {
  oneLabeler: number | string;
  avgProbability: number | string;
  avgIou: number | string;
  agreeSegmentation: number | string;
  agreeClassification: number | string;
}

export interface LabelerDistributionRowModel extends ObservationStatsRowModel {
  f1score: number | string;
  classificationAgree: number | string;
  segmentationAgree: number | string;
  avgIou: number | string;
  avgProbability: number | string;
  fp: number | string;
  sensitivity: number | string;
  specificity: number | string;
  tn: number | string;
  fn: number | string;
  tp: number | string;
}


export const convertLabelerDistributionToRowsModel = (
  dist: LabelDistributionDTO | null,
  observations: ObservationDTO[],
): LabelerDistributionRowModel[] => {
  if (!dist || !observations) return [];
  const rows: LabelerDistributionRowModel[] = [];

  // build row data for each observation
  for (let observation of observations){
    const observationRowData: any = {
      observation,
    };
    const observationKey = observation.name; // NOTE: might change later to id

    const summary = dist.summary as any;
    const f1DetailData = dist.f1Detail.data as any || [];

    let doesHaveStatsData = false;

    const summaryStatsKeys = [
      "f1score",
      "classificationAgree",
      "segmentationAgree",
      "avgIou",
      "avgProbability",
    ]
    for (let statsKey of Object.keys(summary)){
      if (!summaryStatsKeys.includes(statsKey))
        continue;
      const statsData = summary[statsKey];
      if (statsData.hasOwnProperty(observationKey)){
        observationRowData[statsKey] = statsData[observationKey];
        doesHaveStatsData = true;
      }
    }

    for (let f1Data of f1DetailData){
      if (f1Data["observation"] === observationKey){
        for (let statsKey of Object.keys(f1Data)){
          if (["observation", "assignee"].includes(statsKey))
            continue;
          observationRowData[statsKey] = f1Data[statsKey];
          doesHaveStatsData = true;
        }
      }
    }

    if (doesHaveStatsData){
      rows.push(observationRowData as LabelerDistributionRowModel);
    }
  }

  // add missing parent rows
  if (rows.length > 0){
    const parentsNeedAdd = findNeedAddParents(rows);
    const keys = Object.keys(rows[0]);
    for (const parentObs of parentsNeedAdd){
      const data: any = {};
      for (const key of keys){
        if (key === "observation"){
          data[key] = parentObs;
        } else {
          data[key] = "-";
        }
      }
      rows.push(data as LabelerDistributionRowModel);
    }
  }

  return rows;
}

export const convertStepDistributionToRowsModel = (
  stepDist: StepDistributionDTO | null,
  observations: ObservationDTO[],
): LabelDistributionRowModel[] => {
  if (!stepDist || !observations) return [];
  const rows: LabelDistributionRowModel[] = [];
  
  // build row data for each observation
  for (let observation of observations){
    const observationRowData: any = {
      observation,
    };
    const observationKey = observation.name; // NOTE: might change later to id
    let doesHaveStatsData = false;

    // for each stats key
    for (let statsKey of Object.keys(stepDist)){
      if (statsKey === "step") continue;

      const statsData = (stepDist as any)[statsKey];
      observationRowData[statsKey] = "-"; // default value
      if (statsData.hasOwnProperty(observationKey)){
        observationRowData[statsKey] = statsData[observationKey];
        doesHaveStatsData = true;
      }
    }

    if (doesHaveStatsData){
      rows.push(observationRowData as LabelDistributionRowModel);
    }
  }

  // add missing parent rows
  if (rows.length > 0){
    const parentsNeedAdd = findNeedAddParents(rows);
    const keys = Object.keys(rows[0]);
    for (const parentObs of parentsNeedAdd){
      const data: any = {};
      for (const key of keys){
        if (key === "observation"){
          data[key] = parentObs;
        } else {
          data[key] = "-";
        }
      }
      rows.push(data as LabelDistributionRowModel);
    }
  }
  

  return rows;
}

const findNeedAddParents = (rows: ObservationStatsRowModel[]): ObservationDTO[] => {
  const parentsNeedAdd: ObservationDTO[] = [];
  for (const row of rows) {
    const parentObs = findObservationParents(row.observation);
    for (const parent of parentObs){
      if (!rows.find(r => r.observation.id === parent.id) &&
          !parentsNeedAdd.find(o => o.id === parent.id)){
        parentsNeedAdd.push(parent);
      }
    }
  }
  return parentsNeedAdd;
} 