/*
 * File: bbox-store.ts
 * Project: app-aiscaler-web
 * File Created: Sunday, 26th December 2021 3:07:42 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Rectangle } from "utilities/math/rectangle";

class BBoxStore {
  store: { [key: string]: Rectangle };
  constructor() {
    this.store = {};
  }
  getBoudingClientRect(text: string, fontSize: number = 14): Rectangle | null {
    const id = `${text}-${fontSize}`;
    if (this.store.hasOwnProperty(id)) {
      return this.store[id];
    }
    const root = document.getElementById("svg-root");
    if (!root) return null;
    const template = `<svg width="800" height="400"><text id="node-${id}" font-size="${fontSize}">${text}</text></svg>`;
    root.innerHTML = template;
    const element = document.getElementById(`node-${id}`);
    const bbox = element?.getBoundingClientRect();
    if (!bbox) return null;
    const rect = {
      x: bbox.x,
      y: bbox.y,
      width: bbox.width,
      height: bbox.height,
    };
    this.store[id] = rect;
    return rect;
  }
}

export const BBOXStore = new BBoxStore();
