/*
 * File: use-date-filters.hook.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 11th November 2021 10:48:38 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import moment from "moment";
import { useEffect, useState } from "react";

export enum DateRange {
  CUSTOM = "custom",
  TODAY = "today",
  YESTERDAY = "yesterday",
  THIS_WEEK = "this_week",
  LAST_WEEK = "last_week",
  THIS_MONTH = "this_month",
  LAST_MONTH = "last_month",
  THIS_QUARTER = "this_quarter",
  LAST_QUARTER = "last_quarter",
  THIS_YEAR = "this_year",
}

export const useDateFilters = () => {
  const [fromDate, setFromDate] = useState<Date>(() => {
    const d = new Date();
    d.setDate(d.getDate() - 7);
    return d;
  });
  const [toDate, setToDate] = useState<Date>(new Date());
  const [fromDateSearch, setFromDateSearch] = useState<Date>(new Date(fromDate));
  const [toDateSearch, setToDateSearch] = useState<Date>(new Date(toDate));

  const [dateRange, setDateRange] = useState<DateRange>(DateRange.CUSTOM);

  const [dateRanges] = useState([
    { label: "Custom", value: DateRange.CUSTOM },
    { label: "Today", value: DateRange.TODAY },
    { label: "Yesterday", value: DateRange.YESTERDAY },
    { label: "This week", value: DateRange.THIS_WEEK },
    { label: "Last week", value: DateRange.LAST_WEEK },
    { label: "This month", value: DateRange.THIS_MONTH },
    { label: "Last month", value: DateRange.LAST_MONTH },
    { label: "This quarter", value: DateRange.THIS_QUARTER },
    { label: "Last quarter", value: DateRange.LAST_QUARTER },
    { label: "This year", value: DateRange.THIS_YEAR },
  ]);

  useEffect(() => {
    if (dateRange === DateRange.CUSTOM) return;
    let fDate = new Date();
    let tDate = new Date();
    switch (dateRange) {
      case DateRange.TODAY:
        break;
      case DateRange.YESTERDAY:
        fDate = moment(new Date()).subtract(1, "d").toDate();
        tDate = moment(new Date()).subtract(1, "d").toDate();
        break;
      case DateRange.THIS_WEEK:
        fDate = moment(new Date()).startOf("week").toDate();
        break;
      case DateRange.LAST_WEEK:
        fDate = moment(new Date()).subtract(7, "d").startOf("week").toDate();
        tDate = moment(new Date()).subtract(7, "d").endOf("week").toDate();
        break;
      case DateRange.THIS_MONTH:
        fDate = moment(new Date()).startOf("month").toDate();
        break;
      case DateRange.LAST_MONTH:
        fDate = moment(new Date()).subtract(1, "M").startOf("month").toDate();
        tDate = moment(new Date()).subtract(1, "M").endOf("month").toDate();
        break;
      case DateRange.THIS_QUARTER:
        fDate = moment(new Date()).startOf("quarter").toDate();
        break;
      case DateRange.LAST_QUARTER:
        fDate = moment(new Date())
          .subtract(1, "quarter")
          .startOf("quarter")
          .toDate();
        tDate = moment(new Date())
          .subtract(1, "quarter")
          .endOf("quarter")
          .toDate();
        break;
      case DateRange.THIS_YEAR:
        fDate = moment(new Date()).startOf("year").toDate();
        break;
      default:
    }
    const newFromDate = moment(fDate).startOf("date").add(1, "second").toDate();
    const newToDate = moment(tDate).endOf("date").toDate();
    setFromDate(new Date(newFromDate));
    setToDate(new Date(newToDate));
    setFromDateSearch(new Date(newFromDate));
    setToDateSearch(new Date(newToDate));
  }, [dateRange]);

  return {
    fromDate,
    toDate,
    fromDateSearch,
    toDateSearch,
    dateRanges,
    dateRange,
    setFromDate,
    setToDate,
    setFromDateSearch,
    setToDateSearch,
    setDateRange,
  };
};
