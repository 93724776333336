/*
 * File: labeler-router.tsx
 * Project: app-aiscaler-web
 * File Created: Friday, 13th August 2021 10:51:25 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { NotFoundPage } from "pages/authorization/not-found/not-found.page";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "routers/common/private-route";
import { PublicRoute } from "routers/common/public-route";
import { Routes } from "routers/config/routes";
import { SignInPage } from "pages/authorization/sign-in/sign-in.page";
import { UnAuthorizedPage } from "pages/authorization/unauthorized/unauthorized.page";
import { TextLabelingPage } from "pages/labeler/text-labeling/text-labeling.page";
import { LabelerReportPage } from "pages/labeler/labeler-report/labeler-report.page";
import { LabelerDashboardPage } from "pages/labeler/labeler-dashboard/labeler-dashboard.page";
import { LabelerFAQPage } from "pages/labeler/labeler-faq/labeler-faq.page";
import { LabelerProfilePage } from "pages/labeler/labeler-profile/labeler-profile.page";
import { BatchLabelingProvider } from "pages/labeler/batch-labeling/context/batch-labeling.provider";
import { BatchLabelingPage } from "pages/labeler/batch-labeling/batch-labeling.page";
import { SpeechToTextLabelingPage } from "pages/labeler/speech-to-text-labeling/speech-to-text-labeling.page";
import { TextToSpeechLabelingPage } from "pages/labeler/text-to-speech-labeling/text-to-speech-labeling.page";
import { LabelerProjectsPage } from "pages/labeler/labeler-projects/labeler-projects.page";
import { SpeechToTextLabelingReview } from "pages/labeler/speech-to-text-labeling/speech-to-text-labeling.review";
import { TextToSpeechLabelingReview } from "pages/labeler/text-to-speech-labeling/text-to-speech-labeling.review";
import { ThreeDLabelingPage } from "pages/labeler/three-d-labeling/three-d-labeling.page";
import { ThreeDLabelingReview } from "pages/labeler/three-d-labeling/three-d-labeling.review";
import { TextLabelingBatchProvider } from "pages/labeler/text-labeling/context/text-labeling-batch/text-labeling-batch.provider";
import { PathologyLabelingPage } from "pages/labeler/pathology-labeling/pathology-labeling.page";
import { PathologyLabelingReview } from "pages/labeler/pathology-labeling/pathology-labeling.review";
import { LabelerProjectHistoryPage } from "pages/labeler/labeler-project-history/labeler-project-history.page";
import { LabelerTasksProvider } from "pages/labeler/labeler-project-history/context";
import LabelerReviewPage from "pages/labeler/labeler-review";

export function LabelerRouter() {
  return (
    <>
      <Switch>
        <PrivateRoute
          exact
          path={Routes.LABELER_HOME}
          component={LabelerDashboardPage}
        />
        <PrivateRoute
          exact
          path={Routes.LABELER_PROJECTS}
          component={LabelerProjectsPage}
        />
        <PrivateRoute
          exact
          path={Routes.LABELER_PROJECT_HISTORY}
          component={() => (
            <LabelerTasksProvider>
              <LabelerProjectHistoryPage />
            </LabelerTasksProvider>
          )}
        />
        <PrivateRoute
          exact
          path={Routes.LABELER_REPORT}
          component={LabelerReportPage}
        />
        <PrivateRoute
          exact
          path={Routes.LABELER_FAQ}
          component={LabelerFAQPage}
        />
        <PrivateRoute
          exact
          path={Routes.LABELER_PROFILE}
          component={LabelerProfilePage}
        />
        <PrivateRoute
          exact
          path={Routes.IMAGE_LABELING_PROJECT}
          component={() => {
            return (
              <BatchLabelingProvider>
                <BatchLabelingPage />
              </BatchLabelingProvider>
            );
          }}
        />

        <PrivateRoute
          exact
          path={Routes.LABELER_REVIEW}
          component={() => {
            return <LabelerReviewPage />;
          }}
        />
        <PrivateRoute
          exact
          path={Routes.IMAGE_REVIEW}
          component={() => {
            return (
              <BatchLabelingProvider>
                <BatchLabelingPage />
              </BatchLabelingProvider>
            );
          }}
        />
        <PrivateRoute
          exact
          path={Routes.TEXT_LABELING}
          component={() => {
            return (
              <TextLabelingBatchProvider>
                <TextLabelingPage />
              </TextLabelingBatchProvider>
            );
          }}
        />
        <PrivateRoute
          exact
          path={Routes.TEXT_REVIEW}
          component={() => {
            return (
              <TextLabelingBatchProvider>
                <TextLabelingPage />
              </TextLabelingBatchProvider>
            );
          }}
        />
        <PrivateRoute
          exact
          path={Routes.SPEECH_TO_TEXT_REVIEW}
          component={SpeechToTextLabelingReview}
        />
        <PrivateRoute
          exact
          path={Routes.TEXT_TO_SPEECH_REVIEW}
          component={TextToSpeechLabelingReview}
        />
        <PrivateRoute
          exact
          path={Routes.THREE_D_REVIEW}
          component={ThreeDLabelingReview}
        />
        <PrivateRoute
          exact
          path={Routes.PATHOLOGY_REVIEW}
          component={PathologyLabelingReview}
        />
        <PrivateRoute
          exact
          path={Routes.SPEECH_TO_TEXT_LABELING}
          component={SpeechToTextLabelingPage}
        />
        <PrivateRoute
          exact
          path={Routes.TEXT_TO_SPEECH_LABELING}
          component={TextToSpeechLabelingPage}
        />
        <PrivateRoute
          exact
          path={Routes.THREE_D_LABELING}
          component={ThreeDLabelingPage}
        />
        <PrivateRoute
          exact
          path={Routes.PATHOLOGY_LABELING}
          component={PathologyLabelingPage}
        />

        <PublicRoute path={Routes.UNAUTHORIZED} component={UnAuthorizedPage} />
        <PublicRoute path={Routes.SIGN_IN} component={SignInPage} />
        <PublicRoute path={Routes.NOT_FOUND} component={NotFoundPage} />
        <PublicRoute path="*" component={NotFoundPage} />
      </Switch>
    </>
  );
}
