/*
 * File: project-detail.page.tsx
 * Project: app-aiscaler-web
 * File Created: Tuesday, 10th August 2021 10:13:08 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { LinearProgress } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { useEffect } from "react";
import { useParams, Switch, useRouteMatch } from "react-router-dom";
import { ProjectRoutes } from "routers/config/routes";
import {
  selectCurrentProject,
  selectCurrentProjectForCreatingBatch,
} from "store/customer/project/project.selectors";
import {
  getProjectObservationAnnotationTypesAsync,
  resetProjectState,
  setProjectAsync,
  setProjectForCreatingBatchAsync,
} from "store/customer/project/project.slice";
import { Logger } from "utilities/logger";
import { ProjectBatchesPage } from "../project-batch/project-batch.page";
import { ProjectOverviewPage } from "../project-overview/project-overview.page";
import { ProjectSettingPage } from "../project-setting/project-setting.page";
import { BatchDetailPage } from "pages/customer/projects/project-batch/batch-detail/batch-detail.page";
import { ProjectLabelPage } from "../project-label/project-label.page";
import { ProjectRoute } from "./project-route";
import { resetProjectData } from "store/customer/project-data/project-data.slice";
import { CreateBatchV2Provider } from "../project-batch/components/create-batch-v2/create-batch-v2.provider";
import { useUnmount } from "ahooks";
import * as Sentry from "@sentry/react";

export const ProjectDetailPage = () => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const { path } = useRouteMatch();
  const project = useAppSelector(selectCurrentProject);
  const projectForCreatingBatch = useAppSelector(
    selectCurrentProjectForCreatingBatch
  );

  useEffect(() => {
    const loadProjectAsync = async (id: number) => {
      try {
        await dispatch(setProjectAsync(id));
        dispatch(setProjectForCreatingBatchAsync(id));
        dispatch(getProjectObservationAnnotationTypesAsync(id));
      } catch (err) {
        Sentry.captureException(err);
        Logger.log(err);
      }
    };
    loadProjectAsync(parseInt(projectId));
  }, [dispatch, projectId]);

  useUnmount(() => {
    dispatch(resetProjectData());
    dispatch(resetProjectState());
  });

  if (!project || !projectForCreatingBatch) return <LinearProgress />;

  return (
    <div className="h-full px-6 overflow-y-auto">
      <Switch>
        <ProjectRoute exact path={path} component={ProjectOverviewPage} />
        <ProjectRoute
          path={`${path}${ProjectRoutes.OVERVIEW}`}
          component={ProjectOverviewPage}
        />
        <ProjectRoute
          exact
          path={`${path}${ProjectRoutes.BATCHES}`}
          component={() => <ProjectBatchesPage project={project} />}
        />
        <ProjectRoute
          exact
          path={`${path}${ProjectRoutes.CREATE_BATCH}`}
          component={() => {
            if (!projectForCreatingBatch) return null;
            return (
              <CreateBatchV2Provider
                projectForCreatingBatch={projectForCreatingBatch}
              />
            );
          }}
        />
        <ProjectRoute
          path={`${path}${ProjectRoutes.BATCHES}/:batchId`}
          component={BatchDetailPage}
        />
        <ProjectRoute
          exact
          path={`${path}${ProjectRoutes.LABELS}`}
          component={ProjectLabelPage}
        />
        <ProjectRoute
          path={`${path}${ProjectRoutes.SETTINGS}`}
          component={ProjectSettingPage}
        />
      </Switch>
    </div>
  );
};
