/*
 * File: text-editor.actions.ts
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 5:06:00 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { Issue } from "domain/common/issue";
import { TextWorkspaceState } from "../text-workspace.state";
import { INITIAL_TEXT_ISSUES_STATE } from "./text-issues.state";

export const textIssuesActions = {
  resetTextIssuesState({ textIssues }: TextWorkspaceState) {
    Object.assign(textIssues, INITIAL_TEXT_ISSUES_STATE);
  },

  toggleDisplayTextIssues: ({ textIssues }: TextWorkspaceState) => {
    textIssues.showIssues = !textIssues.showIssues;
  },

  addTextIssue: (
    { textIssues }: TextWorkspaceState,
    action: PayloadAction<Issue>
  ) => {
    textIssues.issues.push(action.payload);
  },

  textIssueIdSelected: (
    { textIssues, textEditor }: TextWorkspaceState,
    action: PayloadAction<number>
  ) => {
    textIssues.selectedTextIssueId = action.payload;
    const issue = textIssues.issues.find((is) => action.payload === is.id);
    if (issue && issue.metadata?.annotationId) {
      textEditor.selectedAnnotation.annotationId = issue.metadata.annotationId;
      textEditor.selectedAnnotation.contextMenu = false;
      textEditor.selectedAnnotation.issueMenu = true;
    }
  },
};
