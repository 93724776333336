/*
 * File: ml-models.route.tsx
 * Project: app-aiscaler-web
 * File Created: Monday, 30th August 2021 5:12:03 pm
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { VBBreadcrumb } from "components/common/vb-breadcrumb/vb-breadcrumb.component";
import { useMLModelBreadcrumbs } from "hooks/ml-models/use-ml-models-breadcrumbs";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { DatasetProvider } from "../datasets/context/dataset-provider";
import { ExportedDatasetDetailPage } from "./exported-datasets/exported-dataset-detail/exported-dataset-detail.page";
import { MLWebPage } from "./ml-web/ml-web.page";
import { MLWebProvider } from "./ml-web/context/ml-web.provider";
import { ExperimentsPage } from "./experiments/experiments.page";
import { ExperimentDetailPage } from "./experiment-detail/experiment-detail.page";
import { RegisteredModelsPage } from "./registered-models/registered-models.page";
import { ExportedDatasetsV2Page } from "./exported-datasets/exported-datasets-v2.page";
import { RunInfoDetailPage } from "./experiment-detail/run-info-detail.page";
import AIModelsPage from "../ai-models/ai-models.page";
import AIModelsProvider from "../ai-models/context/ai-models.provider";

export enum MLModelsRoutes {
  MODELS = "/",
  EXPORTED_DATASETS = "/exported-datasets",
  EXPORTED_DATASET_DETAIL = "/exported-datasets/:datasetId",
  DATA_GENERATION = "/data-generation",
  EXPERIMENTS = "/experiments",
  EXPERIMENT_DETAIL = "/experiments/:experimentId",
  RUN_INFO_DETAIL = "/experiments/:experimentId/:runInfoId",
  CREATE_MODEL = "/experiments/create",
  TRAINING_JOB = "/experiments/:trainingJobId",
  ML_WEB = "/web-builder",
  PUBLISHED_MODELS = "/model-ai",
  REGISTERED_MODELS = "/registered-models",
}

export const MLModelRouter = () => {
  const { path } = useRouteMatch();
  const { breadcrumbs } = useMLModelBreadcrumbs();
  return (
    <div className="flex flex-col h-full px-6 py-4 overflow-hidden">
      <div className="mb-4">
        <VBBreadcrumb breadcrumbs={breadcrumbs} />
      </div>
      <div className="flex-grow h-1 overflow-hidden">
        <DatasetProvider>
          <Switch>
            <Route
              exact
              path={`${path}${MLModelsRoutes.MODELS}`}
              component={() => (
                <AIModelsProvider>
                  <AIModelsPage />
                </AIModelsProvider>
              )}
            />
            <Route
              exact
              path={`${path}${MLModelsRoutes.EXPORTED_DATASET_DETAIL}`}
              component={ExportedDatasetDetailPage}
            />
            <Route
              exact
              path={`${path}${MLModelsRoutes.EXPORTED_DATASETS}`}
              component={ExportedDatasetsV2Page}
            />

            {/* <Route
              exact
              path={`${path}${MLModelsRoutes.CREATE_MODEL}`}
              component={CreateExperimentPage}
            /> */}
            {/* <Route
              exact
              path={`${path}${MLModelsRoutes.TRAINING_JOB}`}
              component={TrainingJobDetailPage}
            /> */}
            <Route
              exact
              path={`${path}${MLModelsRoutes.EXPERIMENTS}`}
              component={ExperimentsPage}
            />
            <Route
              exact
              path={`${path}${MLModelsRoutes.RUN_INFO_DETAIL}`}
              component={RunInfoDetailPage}
            />
            <Route
              exact
              path={`${path}${MLModelsRoutes.EXPERIMENT_DETAIL}`}
              component={ExperimentDetailPage}
            />

            {/* <Route
              exact
              path={`${path}${MLModelsRoutes.PUBLISHED_MODELS}`}
              component={PublishedModelsPage}
            /> */}
            <Route
              exact
              path={`${path}${MLModelsRoutes.REGISTERED_MODELS}`}
              component={RegisteredModelsPage}
            />
            <MLWebProvider>
              <Route
                exact
                path={`${path}${MLModelsRoutes.ML_WEB}`}
                component={MLWebPage}
              />
            </MLWebProvider>
          </Switch>
        </DatasetProvider>
      </div>
    </div>
  );
};
