/*
 * File: ml-web.state.ts
 * Project: app-aiscaler-web
 * File Created: Thursday, 17th February 2022 3:29:38 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2022 VinBrain JSC
 */

import { FilterCommon } from "domain/common/filter";
import { MLWeb } from "domain/web-builder";
import moment from "moment";
import { RequestOptions } from "services/common/request-options";
import { AppError } from "utilities/errors/errors";

export class MlWebFilter extends FilterCommon {
  name?: string;
  createdBy?: string;
  lastActivity?: Date;

  static toRequestOption(filter: MlWebFilter): RequestOptions {
    const options = FilterCommon.toRequestOption(filter);
    
    if (filter.name) options["name.contains"] = filter.name;
    if (filter.createdBy) options["createdBy.equals"] = filter.createdBy;
    if (filter.lastActivity) options["lastActivity.contains"] = moment(filter.lastActivity).utc().format("YYYY-MM-DD");

    return options;
  }
}

export interface MLWebContextState {
  isLoading: boolean;
  items: MLWeb[];
  totalItem: number;
  loadServingWebs: (options: RequestOptions, force?: boolean) => any,
  error: AppError | undefined,
  onClickSelect(item: MLWeb): void;
  onClickRename(item: MLWeb): void;
  onClickDelete(item: MLWeb): void;
  onClickCopyPublishUrl(item: MLWeb): void;
  onClickOpenInNewTab(item: MLWeb): void;
  onClickCreateNew(): void;
  onClickFilter(): void;
  filter: MlWebFilter,
  setFilter: (v: MlWebFilter) => void;
  setFilterField: (key: keyof MlWebFilter, value: any) => void;
}
